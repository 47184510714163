// Definitions obtained from https://github.com/meetDeveloper/freeDictionaryAPI, with customisation

export const dictionary = {
  which: [
    {
      word: "which",
      phonetic: "/wɪt͡ʃ/",
      phonetics: [
        {
          text: "/wɪt͡ʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/which-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9028965",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/wɪt͡ʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/which-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=718831",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "pronoun",
          definitions: [
            {
              definition:
                "(interrogative) What one or ones (of those mentioned or implied).",
              synonyms: [],
              antonyms: [],
              example: "By now, you must surely know which is which.",
            },
            {
              definition: "The/Any ones that; whichever.",
              synonyms: [],
              antonyms: [],
              example: "Please take which you please.",
            },
            {
              definition:
                "(relative) Introduces a relative clause giving further information about something previously mentioned.",
              synonyms: [],
              antonyms: [],
              example: "He had to leave, which was very difficult.",
            },
            {
              definition:
                "(relative) Used of people (now generally who, whom or that).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/which"],
    },
  ],
  there: [
    {
      word: "there",
      phonetic: "/ðeː(ɹ)/",
      phonetics: [
        { text: "/ðeː(ɹ)/", audio: "" },
        {
          text: "/ðɛə(ɹ)/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/there-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9027607",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ðɛəɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/there-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=718789",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "That place.", synonyms: [], antonyms: [] },
            {
              definition: "That status; that position.",
              synonyms: [],
              antonyms: [],
              example:
                "You rinse and de-string the green beans; I'll take it from there.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition:
                "(location) In a place or location (stated, implied or otherwise indicated) at some distance from the speaker (compare here).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In that matter, relation, etc.; at that point, stage, etc., regarded as a distinct place.",
              synonyms: [],
              antonyms: [],
              example: "He did not stop there, but continued his speech.",
            },
            {
              definition: "(location) To or into that place; thither.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Where, there where, in which place.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In existence or in this world; see pronoun section below.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "away there",
            "over there",
            "thither",
            "yonder",
            "away there",
            "over there",
            "thither",
            "yonder",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "pronoun",
          definitions: [
            {
              definition:
                "Used as an expletive subject of be in its sense of “exist”, with the semantic, usually indefinite subject being postponed or (occasionally) implied.",
              synonyms: [],
              antonyms: [],
              example: "Is there an answer? [=Does an answer exist?]",
            },
            {
              definition:
                "Used with other intransitive verbs of existence, in the same sense, or with other intransitive verbs, adding a sense of existence.",
              synonyms: [],
              antonyms: [],
              example:
                "If x is a positive number, then there exists [=there is] a positive number y less than x.",
            },
            {
              definition: "Used with other verbs, when raised.",
              synonyms: [],
              antonyms: [],
              example:
                "I expected there to be a simpler solution. [=I expected that there would be a simpler solution.]",
            },
            {
              definition:
                "(in combination with certain prepositions, no longer productive) That.",
              synonyms: [],
              antonyms: [],
              example: "therefor, thereat, thereunder",
            },
            {
              definition:
                "Used to replace an unknown name, principally in greetings and farewells",
              synonyms: [],
              antonyms: [],
              example: "Hi there, young fellow.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "Used to offer encouragement or sympathy.",
              synonyms: [],
              antonyms: [],
              example:
                "There, there. Everything is going to turn out all right.",
            },
            {
              definition: "Used to express victory or completion.",
              synonyms: [],
              antonyms: [],
              example: "There! That knot should hold.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/there"],
    },
  ],
  their: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  about: [
    {
      word: "about",
      phonetic: "/əˈbɛʊt/",
      phonetics: [
        { text: "/əˈbɛʊt/", audio: "" },
        { text: "/əˈbaʊt/", audio: "" },
        { text: "/əˈbɛʊt/", audio: "" },
        { text: "/əˈbʌʊt/", audio: "" },
        {
          text: "/əˈbaʊt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/about-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=191960",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Moving around; astir.",
              synonyms: [],
              antonyms: [],
              example:
                "After my bout with Guillan-Barre Syndrome, it took me 6 months to be up and about again.",
            },
            {
              definition: "In existence; being in evidence; apparent.",
              synonyms: [],
              antonyms: [],
              example:
                "This idea has been about for a while but has only recently become fashionable.",
            },
            {
              definition: "Near; in the vicinity or neighbourhood.",
              synonyms: [],
              antonyms: [],
              example:
                "I had my keys just a minute ago, so they must be about somewhere.",
            },
          ],
          synonyms: ["around", "active", "around", "astir", "mobile"],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "On all sides; around.",
              synonyms: [],
              antonyms: [],
              example: "I looked about at the scenery that surrounded me.",
            },
            {
              definition:
                "Here and there; around; in one place and another; up and down.",
              synonyms: [],
              antonyms: [],
              example: "Bits of old machinery were lying about.",
            },
            {
              definition:
                "From one place or position to another in succession; indicating repeated movement or activity.",
              synonyms: [],
              antonyms: [],
              example:
                "walking about;  rushing about;  jumping about;  thrashing about",
            },
            {
              definition: "Indicating unproductive or unstructured activity.",
              synonyms: [],
              antonyms: [],
              example: "messing about;   fooling about;  loafing about",
            },
            {
              definition:
                "Nearly; approximately; with close correspondence in quality, manner, degree, quantity, or time; almost.",
              synonyms: [],
              antonyms: [],
              example: "Dinner's about ready.",
            },
            {
              definition: "Near; in the vicinity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To a reversed order; half round; facing in the opposite direction; from a contrary point of view.",
              synonyms: [],
              antonyms: [],
              example: "to face about;  to turn oneself about",
            },
            {
              definition:
                "In succession; one after another; in the course of events.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In circuit; circularly; by a circuitous way; around the outside; in circumference.",
              synonyms: [],
              antonyms: [],
              example: "a mile about, and a third of a mile across",
            },
          ],
          synonyms: ["around"],
          antonyms: [],
        },
        {
          partOfSpeech: "preposition",
          definitions: [
            {
              definition:
                "In a circle around; all round; on every side of; on the outside of.",
              synonyms: [],
              antonyms: [],
              example: "The snake was coiled about his ankle.",
            },
            {
              definition:
                "Over or upon different parts of; through or over in various directions; here and there in; to and fro in; throughout.",
              synonyms: [],
              antonyms: [],
              example: "He was well known about town.",
            },
            {
              definition:
                "Indicates that something will happen very soon; indicates a plan or intention to do something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Concerning; with regard to; on account of; on the subject of; to affect.",
              synonyms: ["apropos", "as for"],
              antonyms: [],
              example: "He talked a lot about his childhood.",
            },
            {
              definition: "Concerned with; engaged in; intent on.",
              synonyms: [],
              antonyms: [],
              example: "to be about one's business",
            },
            {
              definition:
                "Within or in the immediate neighborhood of; in contiguity or proximity to; near, as to place.",
              synonyms: [],
              antonyms: [],
              example:
                "I can't find my reading glasses, but they must be somewhere about the house.",
            },
            {
              definition: "On one's person; nearby the person.",
              synonyms: [],
              antonyms: [],
              example: "I had no weapon about me but a stick.",
            },
            {
              definition:
                "In or near, as in mental faculties or in the possession of; under the control of; at one's command; in one's makeup.",
              synonyms: [],
              antonyms: [],
              example: "He has his wits about him.",
            },
          ],
          synonyms: ["apropos", "as for"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/about"],
    },
  ],
  would: [
    {
      word: "would",
      phonetic: "/wʊd/",
      phonetics: [
        { text: "/wʊd/", audio: "" },
        { text: "/wəd/", audio: "" },
        { text: "/wʊd/", audio: "" },
        { text: "/wəd/", audio: "" },
        {
          text: "/wʊd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/would-us-stressed.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=718833",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
        { text: "/wəd/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Something that would happen, or would be the case, under different circumstances; a potentiality.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/would"],
    },
  ],
  these: [
    {
      word: "these",
      phonetic: "/ðiːz/",
      phonetics: [
        {
          text: "/ðiːz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/these-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=82367444",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ðiz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/these-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1221200",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "pronoun",
          definitions: [
            {
              definition: "The thing, item, etc. being indicated.",
              synonyms: [],
              antonyms: [],
              example: "This isn't the item that I ordered.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/these",
        "https://en.wiktionary.org/wiki/this",
      ],
    },
  ],
  other: [
    {
      word: "other",
      phonetic: "/ˈɐðə/",
      phonetics: [
        { text: "/ˈɐðə/", audio: "" },
        { text: "/ʊðə/", audio: "" },
        { text: "/ˈɐðə/", audio: "" },
        {
          text: "/ʊðə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/other-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9023292",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ˈʌðɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/other-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=711596",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An other, another (person, etc), more often rendered as another.",
              synonyms: [],
              antonyms: [],
              example:
                "I'm afraid little Robbie does not always play well with others.",
            },
            {
              definition: "The other one; the second of two.",
              synonyms: [],
              antonyms: [],
              example: "One boat is not better than the other.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                'To regard, label or treat as an "other", as not part of the same group; to view as different and alien.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To treat as different or separate; segregate; ostracise.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "See other (determiner) below",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Second.",
              synonyms: [],
              antonyms: [],
              example: "I get paid every other week.",
            },
            { definition: "Alien.", synonyms: [], antonyms: [] },
            { definition: "Different.", synonyms: [], antonyms: [] },
            {
              definition: "Left, as opposed to right.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "foreign",
            "different",
            "disparate",
            "dissimilar",
            "distinctive",
            "distinguishable",
            "diverse",
            "unalike",
            "unlike",
            "additional",
            "another",
            "alternate",
          ],
          antonyms: ["same"],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: 'Apart from; in the phrase "other than".',
              synonyms: [],
              antonyms: [],
              example: "Other than that, I'm fine.",
            },
            {
              definition: "Otherwise.",
              synonyms: [],
              antonyms: [],
              example: "If you think other. — Shakespeare.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/other"],
    },
  ],
  words: [
    {
      word: "words",
      phonetic: "/wɜːdz/",
      phonetics: [
        { text: "/wɜːdz/", audio: "" },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/words-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1225641",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The smallest unit of language that has a particular meaning and can be expressed by itself; the smallest discrete, meaningful unit of language. (contrast morpheme.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Something like such a unit of language:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The fact or act of speaking, as opposed to taking action. .",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something that someone said; a comment, utterance; speech.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A watchword or rallying cry, a verbal signal (even when consisting of multiple words).",
              synonyms: [],
              antonyms: [],
              example: "mum's the word",
            },
            { definition: "A proverb or motto.", synonyms: [], antonyms: [] },
            {
              definition: "News; tidings (used without an article).",
              synonyms: [],
              antonyms: [],
              example: "Have you had any word from John yet?",
            },
            {
              definition:
                "An order; a request or instruction; an expression of will.",
              synonyms: [],
              antonyms: [],
              example: "Don't fire till I give the word",
            },
            {
              definition: "A promise; an oath or guarantee.",
              synonyms: ["promise"],
              antonyms: [],
              example: "I give you my word that I will be there on time.",
            },
            {
              definition: "A brief discussion or conversation.",
              synonyms: [],
              antonyms: [],
              example: "Can I have a word with you?",
            },
            {
              definition: "(in the plural) See words.",
              synonyms: [],
              antonyms: [],
              example:
                "There had been words between him and the secretary about the outcome of the meeting.",
            },
            {
              definition:
                "(sometimes Word) Communication from God; the message of the Christian gospel; the Bible, Scripture.",
              synonyms: ["Bible", "word of God"],
              antonyms: [],
              example:
                "Her parents had lived in Botswana, spreading the word among the tribespeople.",
            },
            {
              definition: "(sometimes Word) Logos, Christ.",
              synonyms: ["God", "Logos"],
              antonyms: [],
            },
          ],
          synonyms: [
            "Bible",
            "word of God",
            "God",
            "Logos",
            "promise",
            "vocable",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To say or write (something) using particular words; to phrase (something).",
              synonyms: ["express", "phrase", "put into words", "state"],
              antonyms: [],
              example: "I’m not sure how to word this letter to the council.",
            },
            {
              definition: "To flatter with words, to cajole.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To ply or overpower with words.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To conjure with a word.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To speak, to use words; to converse, to discourse.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["express", "phrase", "put into words", "state"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Angry debate or conversation; argument.",
              synonyms: [],
              antonyms: [],
              example:
                "After she found out the truth, she had words with him, to tell him how she felt.",
            },
            {
              definition: "Lines in a script for a performance.",
              synonyms: [],
              antonyms: [],
              example:
                "You better get your words memorised before rehearsal next Saturday.",
            },
            {
              definition: "Lyrics.",
              synonyms: [],
              antonyms: [],
              example: "The composer set the words to music.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/word",
        "https://en.wiktionary.org/wiki/words",
      ],
    },
  ],
  could: [
    {
      word: "could",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/could.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1217929",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
        { text: "/kʊd/", audio: "" },
        { text: "/kəd/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(auxiliary verb, defective) To know how to; to be able to.",
              synonyms: ["be able to"],
              antonyms: ["can't", "cannot", "can’t"],
              example:
                "She can speak English, French, and German.   I can play football.   Can you remember your fifth birthday?",
            },
            {
              definition:
                "(modal auxiliary verb, defective) May; to be permitted or enabled to.",
              synonyms: ["may"],
              antonyms: [],
              example:
                "You can go outside and play when you're finished with your homework.   Can I use your pen?",
            },
            {
              definition:
                "(modal auxiliary verb, defective) To have the potential to; be possible.",
              synonyms: [],
              antonyms: [],
              example: "Animals can experience emotions.",
            },
            {
              definition:
                "(auxiliary verb, defective) Used with verbs of perception.",
              synonyms: [],
              antonyms: [],
              example: "Can you hear that?.",
            },
            {
              definition: "To know.",
              synonyms: ["cognize", "grok", "ken"],
              antonyms: [],
            },
          ],
          synonyms: ["be able to", "cognize", "grok", "ken", "may"],
          antonyms: ["can't", "cannot", "can’t"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Something that could happen, or could be the case, under different circumstances; a potentiality.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/can",
        "https://en.wiktionary.org/wiki/could",
      ],
    },
  ],
  write: [
    {
      word: "write",
      phonetic: "/ɹaɪt/",
      phonetics: [
        {
          text: "/ɹaɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/write-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9028838",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ɹaɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/write-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=718835",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act or style of writing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The operation of storing data, as in memory or onto disk.",
              synonyms: [],
              antonyms: [],
              example: "How many writes per second can this hard disk handle?",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To form letters, words or symbols on a surface in order to communicate.",
              synonyms: [],
              antonyms: [],
              example: "The pupil wrote his name on the paper.",
            },
            {
              definition: "To be the author of (a book, article, poem, etc.).",
              synonyms: [],
              antonyms: [],
              example: "My uncle writes newspaper articles for The Herald.",
            },
            {
              definition: "To send written information to.",
              synonyms: [],
              antonyms: [],
              example: "(UK) Please write to me when you get there.",
            },
            {
              definition: "To show (information, etc) in written form.",
              synonyms: [],
              antonyms: [],
              example:
                "The due day of the homework is written in the syllabus.",
            },
            {
              definition: "To be an author.",
              synonyms: [],
              antonyms: [],
              example: "I write for a living.",
            },
            {
              definition:
                "(with to) To record data mechanically or electronically.",
              synonyms: [],
              antonyms: [],
              example:
                "The computer writes to the disk faster than it reads from it.",
            },
            {
              definition:
                "(of an exam, a document, etc.) To fill in, to complete using words.",
              synonyms: [],
              antonyms: [],
              example:
                "I was very anxious to know my score after I wrote the test.",
            },
            {
              definition: "To impress durably; to imprint; to engrave.",
              synonyms: [],
              antonyms: [],
              example: "truth written on the heart",
            },
            {
              definition:
                "To make known by writing; to record; to prove by one's own written testimony; often used reflexively.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To sell (an option or other derivative).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "author",
            "pen",
            "save",
            "store",
            "sit",
            "inscribe",
            "scrawl",
            "scribble",
            "post",
            "display",
            "indicate",
            "mark",
            "show",
          ],
          antonyms: ["load", "read", "retrieve"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/write"],
    },
  ],
  first: [
    {
      word: "first",
      phonetic: "/fɪrst/",
      phonetics: [
        { text: "/fɪrst/", audio: "" },
        { text: "/fɜːst/", audio: "" },
        {
          text: "/fɝst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/first-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=195881",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The person or thing in the first position.",
              synonyms: [],
              antonyms: [],
              example: "He was the first to complete the course.",
            },
            {
              definition: "The first gear of an engine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something that has never happened before; a new occurrence.",
              synonyms: [],
              antonyms: [],
              example: "This is a first. For once he has nothing to say.",
            },
            {
              definition: "First base",
              synonyms: [],
              antonyms: [],
              example: "There was a close play at first.",
            },
            {
              definition: "A first-class honours degree.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A first-edition copy of some publication.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A fraction of an integer ending in one.",
              synonyms: [],
              antonyms: [],
              example: "one forty-first of the estate",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Preceding all others of a series or kind; the ordinal of one; earliest.",
              synonyms: [],
              antonyms: [],
              example:
                "I was the first runner to reach the finish line, and won the race.",
            },
            {
              definition:
                "Most eminent or exalted; most excellent; chief; highest.",
              synonyms: [],
              antonyms: [],
              example: "Demosthenes was the first orator of Greece.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Before anything else; firstly.",
              synonyms: [],
              antonyms: [],
              example:
                "Clean the sink first, before you even think of starting to cook.",
            },
            {
              definition: "For the first time;",
              synonyms: [],
              antonyms: [],
              example: "I first witnessed a death when I was nine years old.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/first"],
    },
    {
      word: "first",
      phonetic: "/fɪrst/",
      phonetics: [
        { text: "/fɪrst/", audio: "" },
        { text: "/fɜːst/", audio: "" },
        {
          text: "/fɝst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/first-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=195881",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Time; time granted; respite.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/first"],
    },
  ],
  water: [
    {
      word: "water",
      phonetic: "/ˈwoːtə/",
      phonetics: [
        { text: "/ˈwoːtə/", audio: "" },
        { text: "/ˈwɑtəɹ/", audio: "" },
        { text: "[woːʔɐ]", audio: "" },
        { text: "[ˈwaːʈər]", audio: "" },
        { text: "/ˈwoːtɘ/", audio: "" },
        {
          text: "/ˈwɔːtə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/water-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9028948",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ˈwɔtəɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/water-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=718817",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
        { text: "/ˈwɑtəɹ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A substance (of molecular formula H₂O) found at room temperature and pressure as a clear liquid; it is present naturally as rain, and found in rivers, lakes and seas; its solid form is ice and its gaseous form is steam.",
              synonyms: [],
              antonyms: [],
              example:
                "By the action of electricity, the water was resolved into its two parts, oxygen and hydrogen.",
            },
            {
              definition:
                "The aforementioned liquid, considered one of the Classical elements or basic elements of alchemy.",
              synonyms: [],
              antonyms: [],
              example:
                "And God said, Let the waters under the heaven be gathered together unto one place, and let the dry land appear: and it was so.",
            },
            {
              definition: "Water in a body; an area of open water.",
              synonyms: [],
              antonyms: [],
              example: "The boat was found within the territorial waters.",
            },
            {
              definition: "A body of water, almost always a river.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A combination of water and other substance(s).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in the plural or in the singular) A state of affairs; conditions; usually with an adjective indicating an adverse condition.",
              synonyms: [],
              antonyms: [],
              example:
                "The rough waters of change will bring about the calm after the storm.",
            },
            {
              definition: "A person's intuition.",
              synonyms: [],
              antonyms: [],
              example: "I know he'll succeed. I feel it in my waters.",
            },
            {
              definition: "Excess valuation of securities.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The limpidity and lustre of a precious stone, especially a diamond.",
              synonyms: [],
              antonyms: [],
              example:
                "a diamond of the first water is perfectly pure and transparent",
            },
            {
              definition:
                "A wavy, lustrous pattern or decoration such as is imparted to linen, silk, metals, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [
            "air",
            "earth",
            "ether",
            "fire",
            "metal",
            "void",
            "wind",
            "wood",
            "ice",
            "steam",
            "water vapor",
            "water vapour",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/water"],
    },
    {
      word: "water",
      phonetic: "/ˈwoːtə/",
      phonetics: [
        { text: "/ˈwoːtə/", audio: "" },
        { text: "/ˈwɑtəɹ/", audio: "" },
        { text: "[woːʔɐ]", audio: "" },
        { text: "[ˈwaːʈər]", audio: "" },
        { text: "/ˈwoːtɘ/", audio: "" },
        {
          text: "/ˈwɔːtə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/water-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9028948",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ˈwɔtəɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/water-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=718817",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
        { text: "/ˈwɑtəɹ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To pour water into the soil surrounding (plants).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To wet or supply with water; to moisten; to overflow with water; to irrigate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To provide (animals) with water for drinking.",
              synonyms: [],
              antonyms: [],
              example: "I need to go water the cattle.",
            },
            {
              definition: "To get or take in water.",
              synonyms: [],
              antonyms: [],
              example: "The ship put into port to water.",
            },
            { definition: "To urinate onto.", synonyms: [], antonyms: [] },
            {
              definition: "To dilute.",
              synonyms: [],
              antonyms: [],
              example: "Can you water the whisky, please?",
            },
            {
              definition:
                "To overvalue (securities), especially through deceptive accounting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fill with or secrete water.",
              synonyms: [],
              antonyms: [],
              example: "Chopping onions makes my eyes water.",
            },
            {
              definition:
                "To wet and calender, as cloth, so as to impart to it a lustrous appearance in wavy lines; to diversify with wavelike lines.",
              synonyms: [],
              antonyms: [],
              example: "to water silk",
            },
          ],
          synonyms: ["water down"],
          antonyms: ["refine"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/water"],
    },
  ],
  after: [
    {
      word: "after",
      phonetic: "/ˈæf.tə(ɹ)/",
      phonetics: [
        { text: "/ˈæf.tə(ɹ)/", audio: "" },
        {
          text: "/ˈæf.tɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/after-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=191972",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Later; second (of two); next, following, subsequent",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(where the frame of reference is within the ship) At or towards the stern of a ship.",
              synonyms: [],
              antonyms: [],
              example: "The after gun is abaft the forward gun.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Behind; later in time; following.",
              synonyms: [],
              antonyms: [],
              example: "I left the room, and the dog bounded after.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "preposition",
          definitions: [
            {
              definition: "Subsequently to; following in time; later than.",
              synonyms: [],
              antonyms: [],
              example: "The Cold War began shortly after the Second World War.",
            },
            {
              definition: "Behind.",
              synonyms: [],
              antonyms: [],
              example: "He will leave a trail of destruction after him.",
            },
            {
              definition: "In pursuit of, seeking.",
              synonyms: [],
              antonyms: [],
              example:
                "He's after a job; run after him; inquire after her health.",
            },
            {
              definition:
                "In allusion to, in imitation of; following or referencing.",
              synonyms: [],
              antonyms: [],
              example:
                "We named him after his grandfather; a painting after Leonardo da Vinci.",
            },
            {
              definition: "Next in importance or rank.",
              synonyms: [],
              antonyms: [],
              example:
                "The princess is next in line to the throne after the prince.",
            },
            {
              definition: "As a result of.",
              synonyms: [],
              antonyms: [],
              example: "After your bad behaviour, you will be punished.",
            },
            {
              definition: "In spite of.",
              synonyms: [],
              antonyms: [],
              example: "After all that has happened, he is still my friend.",
            },
            {
              definition:
                "(usually preceded by a form of be, followed by an -ing form of a verb) Used to indicate recent completion of an activity",
              synonyms: [],
              antonyms: [],
              example:
                "I was after finishing my dinner when there was a knock on the door.",
            },
            {
              definition: "According to an author or text.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Denoting the aim or object; concerning; in relation to.",
              synonyms: [],
              antonyms: [],
              example:
                "to look after workmen; to enquire after a friend; to thirst after righteousness",
            },
            {
              definition:
                "According to the direction and influence of; in proportion to; befitting.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["post"],
          antonyms: [],
        },
        {
          partOfSpeech: "conjunction",
          definitions: [
            {
              definition:
                "Signifies that the action of the clause it starts takes place before the action of the other clause.",
              synonyms: [],
              antonyms: [],
              example: "I went home after we had decided to call it a day.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/after"],
    },
  ],
  where: [
    {
      word: "where",
      phonetic: "/wɛə(ɹ)/",
      phonetics: [
        { text: "/wɛə(ɹ)/", audio: "" },
        {
          text: "/wɛəɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/where-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=37583027",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The place in which something happens.",
              synonyms: [],
              antonyms: [],
              example:
                "A good article will cover the who, the what, the when, the where, the why and the how.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition:
                "Interrogative adverb, used in either a direct or indirect question: at what place; to what place; what place.",
              synonyms: [],
              antonyms: [],
              example: "He asked where I grew up.",
            },
            {
              definition: "In what situation.",
              synonyms: [],
              antonyms: [],
              example: "Where would we be without our parents?",
            },
            {
              definition: "(relative) At which, on which.",
              synonyms: [],
              antonyms: [],
              example: "That is the place where we first met.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "pronoun",
          definitions: [
            {
              definition: "The place in which.",
              synonyms: [],
              antonyms: [],
              example: "He lives within five miles of where he was born.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "conjunction",
          definitions: [
            {
              definition: "While on the contrary; although; whereas.",
              synonyms: [],
              antonyms: [],
              example:
                "Where Susy has trouble coloring inside the lines, Johnny has already mastered shading.",
            },
            {
              definition: "At or in which place or situation.",
              synonyms: [],
              antonyms: [],
              example:
                "He is looking for a house where he can have a complete office.",
            },
            {
              definition: "To which place or situation.",
              synonyms: [],
              antonyms: [],
              example: "The snowbirds travel where it is warm.",
            },
            {
              definition: "Wherever.",
              synonyms: [],
              antonyms: [],
              example: "Their job is to go where they are called.",
            },
            {
              definition: "In a position, case, etc., in which.",
              synonyms: [],
              antonyms: [],
              example:
                "Where no provision under this Act is applicable, the case shall be decided in accordance with the customary practices.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/where"],
    },
  ],
  right: [
    {
      word: "right",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/right-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75649530",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈɹaɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/right-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9023338",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ˈɹaɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/right-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=711214",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Straight, not bent.",
              synonyms: [],
              antonyms: [],
              example: "a right line",
            },
            {
              definition:
                "Of an angle, having a size of 90 degrees, or one quarter of a complete rotation; the angle between two perpendicular lines.",
              synonyms: [],
              antonyms: [],
              example:
                "The kitchen counter formed a right angle with the back wall.",
            },
            {
              definition:
                "Of a geometric figure, incorporating a right angle between edges, faces, axes, etc.",
              synonyms: [],
              antonyms: [],
              example: "a right triangle, a right prism, a right cone",
            },
            {
              definition:
                "Complying with justice, correctness or reason; correct, just, true.",
              synonyms: [],
              antonyms: [],
              example:
                "I thought you'd made a mistake, but it seems you were right all along.",
            },
            {
              definition: "Appropriate, perfectly suitable; fit for purpose.",
              synonyms: [],
              antonyms: [],
              example: "Is this the right software for my computer?",
            },
            {
              definition: "Healthy, sane, competent.",
              synonyms: [],
              antonyms: [],
              example: "I'm afraid my father is no longer in his right mind.",
            },
            {
              definition: "Real; veritable (used emphatically).",
              synonyms: [],
              antonyms: [],
              example: "You've made a right mess of the kitchen!",
            },
            {
              definition: "All right; not requiring assistance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Most favourable or convenient; fortunate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Designating the side of the body which is positioned to the east if one is facing north. This arrow points to the reader's right: →",
              synonyms: [],
              antonyms: [],
              example:
                "After the accident, her right leg was slightly shorter than her left.",
            },
            {
              definition: "Designed to be placed or worn outward.",
              synonyms: [],
              antonyms: [],
              example: "the right side of a piece of cloth",
            },
            {
              definition: "Pertaining to the political right; conservative.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "correct",
            "just",
            "conservative",
            "right-wing",
            "dexter",
            "dextral",
            "right-hand",
          ],
          antonyms: ["wrong", "left", "bowed", "crooked", "curved"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/right"],
    },
    {
      word: "right",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/right-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75649530",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈɹaɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/right-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9023338",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ˈɹaɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/right-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=711214",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            { definition: "On the right side.", synonyms: [], antonyms: [] },
            {
              definition: "Towards the right side.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Exactly, precisely.",
              synonyms: [],
              antonyms: [],
              example: "Luckily we arrived right at the start of the film.",
            },
            {
              definition: "Immediately, directly.",
              synonyms: [],
              antonyms: [],
              example: "Can't you see it? It's right beside you!",
            },
            {
              definition: "Very, extremely, quite.",
              synonyms: [],
              antonyms: [],
              example: "I made a right stupid mistake there, didn't I?",
            },
            {
              definition:
                "According to fact or truth; actually; truly; really.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "In a correct manner.",
              synonyms: [],
              antonyms: [],
              example: "Do it right or don't do it at all.",
            },
            {
              definition:
                "(still used in some titles) To a great extent or degree.",
              synonyms: [],
              antonyms: [],
              example:
                "Members of the Queen's Privy Council are styled The Right Honourable for life.",
            },
          ],
          synonyms: [
            "in point of fact",
            "in truth",
            "correctly",
            "properly",
            "exactly",
            "just",
            "precisely",
            "smack-dab",
            "right smack",
            "slap-bang",
            "rightward",
            "rightwise",
            "rightward",
            "rightways",
            "ever so",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "Yes, that is correct; I agree.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "I agree with whatever you say; I have no opinion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Signpost word to change the subject in a discussion or discourse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Used to check agreement at the end of an utterance.",
              synonyms: [],
              antonyms: [],
              example: "You're going, right?",
            },
            {
              definition:
                "Used to add seriousness or decisiveness before a statement.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/right"],
    },
    {
      word: "right",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/right-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75649530",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈɹaɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/right-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9023338",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ˈɹaɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/right-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=711214",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "That which complies with justice, law or reason.",
              synonyms: [],
              antonyms: [],
              example: "We're on the side of right in this contest.",
            },
            {
              definition: "A legal, just or moral entitlement.",
              synonyms: [],
              antonyms: [],
              example: "You have no right to go through my personal diary.",
            },
            {
              definition: "The right side or direction.",
              synonyms: [],
              antonyms: [],
              example: "The pharmacy is just on the right past the bookshop.",
            },
            {
              definition: "The right hand or fist.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The ensemble of right-wing political parties; political conservatives as a group.",
              synonyms: [],
              antonyms: [],
              example: "The political right holds too much power.",
            },
            {
              definition:
                "The outward or most finished surface, as of a coin, piece of cloth, a carpet, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A wave breaking from right to left (viewed from the shore).",
              synonyms: [],
              antonyms: ["left"],
            },
          ],
          synonyms: ["3 o'clock", "starboard"],
          antonyms: ["duty", "obligation", "left"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/right"],
    },
    {
      word: "right",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/right-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75649530",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈɹaɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/right-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9023338",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ˈɹaɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/right-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=711214",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To correct.",
              synonyms: [],
              antonyms: [],
              example:
                "Righting all the wrongs of the war immediately will be impossible.",
            },
            {
              definition: "To set upright.",
              synonyms: [],
              antonyms: [],
              example: "The tow-truck righted what was left of the automobile.",
            },
            {
              definition: "To return to normal upright position.",
              synonyms: [],
              antonyms: [],
              example: "When the wind died down, the ship righted.",
            },
            {
              definition:
                "To do justice to; to relieve from wrong; to restore rights to; to assert or regain the rights of.",
              synonyms: [],
              antonyms: [],
              example: "to right the oppressed",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/right"],
    },
  ],
  think: [
    {
      word: "think",
      phonetic: "/θɪŋk/",
      phonetics: [
        {
          text: "/θɪŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/think-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=711339",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An act of thinking; consideration (of something).",
              synonyms: [],
              antonyms: [],
              example: "I'll have a think about that and let you know.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To ponder, to go over in one's head.",
              synonyms: [],
              antonyms: [],
              example:
                "Idly, the detective thought what his next move should be.",
            },
            {
              definition:
                "To communicate to oneself in one's mind, to try to find a solution to a problem.",
              synonyms: [],
              antonyms: [],
              example:
                "I thought for three hours about the problem and still couldn’t find the solution.",
            },
            {
              definition:
                "To conceive of something or someone (usually followed by of; infrequently, by on).",
              synonyms: [],
              antonyms: [],
              example: "I tend to think of her as rather ugly.",
            },
            {
              definition:
                "To be of opinion (that); to consider, judge, regard, or look upon (something) as.",
              synonyms: [],
              antonyms: [],
              example:
                "At the time I thought his adamant refusal to give in right.",
            },
            {
              definition: "To guess; to reckon.",
              synonyms: [],
              antonyms: [],
              example: "I think she’ll pass the examination.",
            },
            {
              definition:
                "To plan; to be considering; to be of a mind (to do something).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To presume; to venture.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["guess"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/think"],
    },
    {
      word: "think",
      phonetic: "/θɪŋk/",
      phonetics: [
        {
          text: "/θɪŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/think-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=711339",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To seem, to appear.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/think"],
    },
  ],
  three: [
    {
      word: "three",
      phonetic: "/θɹiː/",
      phonetics: [
        { text: "/θɹiː/", audio: "" },
        { text: "/fɹiː/", audio: "" },
        {
          text: "/θɹiː/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/three-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=711330",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The digit/figure 3.", synonyms: [], antonyms: [] },
            {
              definition: "Anything measuring three units, as length.",
              synonyms: [],
              antonyms: [],
              example: "Put all the threes in a separate container.",
            },
            {
              definition: "A person who is three years old.",
              synonyms: [],
              antonyms: [],
              example:
                "All the threes will go in Mrs. Smith's class, while I'll take the fours and fives.",
            },
            {
              definition: "The playing card featuring three pips.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "numeral",
          definitions: [
            {
              definition:
                "A numerical value after two and before four. Represented in Arabic digits as 3; this many dots (•••).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Describing a set or group with three elements.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["leash", "tether"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A goal or other means of scoring worth three points.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A three-point shot; a basket made from beyond the three-point line.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A three-point landing; an airplane landing in which all three wheels touch the ground simultaneously.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A deer or other animal whose antlers feature three points or tines.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/three",
        "https://en.wiktionary.org/wiki/three-pointer",
      ],
    },
  ],
  years: [
    {
      word: "years",
      phonetic: "/ji.ə(ɹ)z/",
      phonetics: [
        { text: "/ji.ə(ɹ)z/", audio: "" },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/years-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1222266",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A solar year, the time it takes the Earth to complete one revolution of the Sun (between 365.24 and 365.26 days depending on the point of reference).",
              synonyms: [],
              antonyms: [],
              example:
                "we moved to this town a year ago;  I quit smoking exactly one year ago",
            },
            {
              definition:
                "(by extension) The time it takes for any astronomical object (such as a planet, dwarf planet, small Solar System body, or comet) in direct orbit around a star (such as the Sun) to make one revolution around the star.",
              synonyms: [],
              antonyms: [],
              example:
                "Mars goes around the sun once in a Martian year, or 1.88 Earth years.",
            },
            {
              definition:
                "A period between set dates that mark a year, from January 1 to December 31 by the Gregorian calendar, from Tishiri 1 to Elul 29 by the Jewish calendar, and from Muharram 1 to Dhu al-Hijjah 29 or 30 by the Islamic calendar.",
              synonyms: [],
              antonyms: [],
              example:
                "A normal year has 365 full days, but there are 366 days in a leap year.",
            },
            {
              definition:
                "A scheduled part of a calendar year spent in a specific activity.",
              synonyms: [],
              antonyms: [],
              example:
                "During this school year I have to get up at 6:30 to catch the bus.",
            },
            {
              definition:
                'A Julian year, exactly 365.25 days, represented by "a".',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A level or grade in school or college.",
              synonyms: [],
              antonyms: [],
              example:
                "Every second-year student must select an area of specialization.",
            },
            {
              definition:
                "The proportion of a creature's lifespan equivalent to one year of an average human lifespan (see also dog year).",
              synonyms: [],
              antonyms: [],
              example:
                "Geneticists have created baker's yeast that can live to 800 in yeast years.",
            },
          ],
          synonyms: [
            "twelvemonth",
            "calendar year",
            "civil year",
            "legal year",
            "fiscal year",
            "liturgical year",
            "school year",
            "Gaussian year",
            "anomalistic year",
            "sidereal year",
            "tropical year",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A very long time.",
              synonyms: [],
              antonyms: [],
              example: "It took years for the bus to come.",
            },
          ],
          synonyms: ["ages", "donkey’s", "donkey’s years", "for ever", "yonks"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/year",
        "https://en.wiktionary.org/wiki/years",
      ],
    },
  ],
  place: [
    {
      word: "place",
      phonetic: "/pleɪs/",
      phonetics: [
        {
          text: "/pleɪs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/place-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9028082",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/pleɪs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/place-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=711208",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(physical) An area; somewhere within an area.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A location or position in space.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A particular location in a book or document, particularly the current location of a reader.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A passage or extract from a book or document.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A topic.", synonyms: [], antonyms: [] },
            {
              definition: "A frame of mind.",
              synonyms: [],
              antonyms: [],
              example: "I'm in a strange place at the moment.",
            },
            {
              definition: "A chess position; a square of the chessboard.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(social) A responsibility or position in an organization.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A fortified position: a fortress, citadel, or walled town.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Numerically, the column counting a certain quantity.",
              synonyms: [],
              antonyms: [],
              example: "three decimal places;  the hundreds place",
            },
            {
              definition:
                "Ordinal relation; position in the order of proceeding.",
              synonyms: [],
              antonyms: [],
              example: "That's what I said in the first place!",
            },
            {
              definition: "Reception; effect; implying the making room for.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "frame of mind",
            "mindset",
            "mood",
            "location",
            "position",
            "situation",
            "spot",
            "stead",
            "stell",
            "courtyard",
            "piazza",
            "plaza",
            "square",
            "seat",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/place"],
    },
    {
      word: "place",
      phonetic: "/pleɪs/",
      phonetics: [
        {
          text: "/pleɪs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/place-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9028082",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/pleɪs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/place-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=711208",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To put (an object or person) in a specific location.",
              synonyms: [],
              antonyms: [],
              example: "He placed the glass on the table.",
            },
            {
              definition: "To earn a given spot in a competition.",
              synonyms: [],
              antonyms: [],
              example: "The Cowboys placed third in the league.",
            },
            {
              definition:
                "To remember where and when (an object or person) has been previously encountered.",
              synonyms: [],
              antonyms: [],
              example: "I've seen him before, but I can't quite place where.",
            },
            {
              definition:
                "(in the passive) To achieve (a certain position, often followed by an ordinal) as in a horse race.",
              synonyms: [],
              antonyms: [],
              example: "Run Ragged was placed fourth in the race.",
            },
            {
              definition: "To sing (a note) with the correct pitch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To arrange for or to make (a bet).",
              synonyms: [],
              antonyms: [],
              example: "I placed ten dollars on the Lakers beating the Bulls.",
            },
            {
              definition:
                "To recruit or match an appropriate person for a job.",
              synonyms: [],
              antonyms: [],
              example:
                "They phoned hoping to place her in the management team.",
            },
            {
              definition: "To place-kick (a goal).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "achieve",
            "make",
            "deposit",
            "lay",
            "lay down",
            "put down",
            "reach",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/place"],
    },
  ],
  sound: [
    {
      word: "sound",
      phonetic: "/saʊnd/",
      phonetics: [
        { text: "/saʊnd/", audio: "" },
        {
          text: "/saʊnd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sound-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=283143",
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Healthy.",
              synonyms: [],
              antonyms: [],
              example: "He was safe and sound.",
            },
            {
              definition: "Complete, solid, or secure.",
              synonyms: [],
              antonyms: [],
              example: "Fred assured me the floorboards were sound.",
            },
            {
              definition: "Having the property of soundness.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Good; acceptable; decent.",
              synonyms: [],
              antonyms: [],
              example: '"How are you?" - "I\'m sound."',
            },
            {
              definition:
                "(of sleep) Quiet and deep. Sound asleep means sleeping peacefully, often deeply.",
              synonyms: [],
              antonyms: [],
              example: "Her sleep was sound.",
            },
            {
              definition: "Heavy; laid on with force.",
              synonyms: [],
              antonyms: [],
              example: "a sound beating",
            },
            {
              definition: "Founded in law; legal; valid; not defective.",
              synonyms: [],
              antonyms: [],
              example: "a sound title to land",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [{ definition: "Soundly.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition:
                "Yes; used to show agreement or understanding, generally without much enthusiasm.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sound"],
    },
    {
      word: "sound",
      phonetic: "/saʊnd/",
      phonetics: [
        { text: "/saʊnd/", audio: "" },
        {
          text: "/saʊnd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sound-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=283143",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sensation perceived by the ear caused by the vibration of air or some other medium.",
              synonyms: [],
              antonyms: [],
              example:
                "He turned when he heard the sound of footsteps behind him.  Nobody made a sound.",
            },
            {
              definition: "A vibration capable of causing such sensations.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A distinctive style and sonority of a particular musician, orchestra etc",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Noise without meaning; empty noise.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Earshot, distance within which a certain noise may be heard.",
              synonyms: [],
              antonyms: [],
              example: "Stay within the sound of my voice.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To produce a sound.",
              synonyms: [],
              antonyms: [],
              example: "When the horn sounds, take cover.",
            },
            {
              definition: "To convey an impression by one's sound.",
              synonyms: [],
              antonyms: [],
              example: "He sounded good when we last spoke.",
            },
            {
              definition:
                "To be conveyed in sound; to be spread or published; to convey intelligence by sound.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To resound.", synonyms: [], antonyms: [] },
            {
              definition:
                "(often with in) To arise or to be recognizable as arising in or from a particular area of law.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause to produce a sound.",
              synonyms: [],
              antonyms: [],
              example: "He sounds the instrument.",
            },
            {
              definition: "(of a vowel or consonant) To pronounce.",
              synonyms: [],
              antonyms: [],
              example: 'The "e" in "house" isn\'t sounded.',
            },
          ],
          synonyms: ["echo", "reecho", "resonate"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sound"],
    },
    {
      word: "sound",
      phonetic: "/saʊnd/",
      phonetics: [
        { text: "/saʊnd/", audio: "" },
        {
          text: "/saʊnd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sound-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=283143",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A long narrow inlet, or a strait between the mainland and an island; also, a strait connecting two seas, or connecting a sea or lake with the ocean.",
              synonyms: [],
              antonyms: [],
              example: "Puget Sound; Owen Sound",
            },
            {
              definition: "The air bladder of a fish.",
              synonyms: [],
              antonyms: [],
              example: "Cod sounds are an esteemed article of food.",
            },
            { definition: "A cuttlefish.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sound"],
    },
    {
      word: "sound",
      phonetic: "/saʊnd/",
      phonetics: [
        { text: "/saʊnd/", audio: "" },
        {
          text: "/saʊnd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sound-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=283143",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An instrument for probing or dilating; a sonde.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A long, thin probe for sounding body cavities or canals such as the urethra.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "Dive downwards, used of a whale.",
              synonyms: [],
              antonyms: [],
              example:
                "The whale sounded and eight hundred feet of heavy line streaked out of the line tub before he ended his dive.",
            },
            {
              definition:
                "To ascertain, or try to ascertain, the thoughts, motives, and purposes of (a person); to examine; to try; to test; to probe.",
              synonyms: [],
              antonyms: [],
              example:
                "When I sounded him, he appeared to favor the proposed deal.",
            },
            {
              definition:
                "Test; ascertain the depth of water with a sounding line or other device.",
              synonyms: [],
              antonyms: [],
              example:
                "Mariners on sailing ships would sound the depth of the water with a weighted rope.",
            },
            {
              definition:
                "To examine with the instrument called a sound or sonde, or by auscultation or percussion.",
              synonyms: [],
              antonyms: [],
              example: "to sound a patient, or the bladder or urethra",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sound"],
    },
  ],
  great: [
    {
      word: "great",
      phonetic: "/ɡɹeɪt/",
      phonetics: [
        {
          text: "/ɡɹeɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/great-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=268413",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person of major significance, accomplishment or acclaim.",
              synonyms: [],
              antonyms: [],
              example:
                "Newton and Einstein are two of the greats of the history of science.",
            },
            {
              definition:
                "The main division in a pipe organ, usually the loudest division.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                '(in combinations such as "two-greats", "three-greats" etc.) An instance of the word "great" signifying an additional generation in phrases expressing family relationships.',
              synonyms: [],
              antonyms: [],
              example: "My three-greats grandmother.",
            },
          ],
          synonyms: [],
          antonyms: ["mediocre"],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Relatively large in scale, size, extent, number (i.e. having many parts or members) or duration (i.e. relatively long); very big.",
              synonyms: [],
              antonyms: [],
              example: "A great storm is approaching our shores.",
            },
            {
              definition:
                "Of larger size or more importance than others of its kind.",
              synonyms: [],
              antonyms: [],
              example: "the great auk",
            },
            {
              definition:
                "(qualifying nouns of family relationship) Involving more generations than the qualified word implies — as many extra generations as repetitions of the word great (from 1510s). [see Derived terms]",
              synonyms: [],
              antonyms: [],
              example:
                "great-grandfather, great-great-grandfather, great-great-great-grandfather",
            },
            {
              definition:
                "(followed by 'with') Pregnant; large with young; full of.",
              synonyms: [],
              antonyms: [],
              example: "great with child",
            },
            {
              definition:
                "(except with 'friend' and similar words such as 'mate','buddy') Intimate; familiar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Extreme or more than usual.",
              synonyms: [],
              antonyms: [],
              example: "great worry",
            },
            {
              definition:
                "Of significant importance or consequence; important.",
              synonyms: [],
              antonyms: [],
              example: "a great decision",
            },
            {
              definition:
                "(applied to actions, thoughts and feelings) Arising from or possessing idealism; admirable; superior; commanding; heroic; illustrious; eminent.",
              synonyms: [],
              antonyms: [],
              example: "a great deed",
            },
            {
              definition: "Impressive or striking.",
              synonyms: [],
              antonyms: [],
              example: "a great show of wealth",
            },
            {
              definition: "Much in use; favoured.",
              synonyms: [],
              antonyms: [],
              example: "Poetry was a great convention of the Romantic era.",
            },
            {
              definition:
                "(applied to persons) Endowed with extraordinary powers; of exceptional talents or achievements; uncommonly gifted; able to accomplish vast results; remarkable; strong; powerful; mighty; noble.",
              synonyms: [],
              antonyms: [],
              example:
                "a great hero, scholar, genius, philosopher, writer etc.",
            },
            {
              definition: "Title referring to an important leader.",
              synonyms: [],
              antonyms: [],
              example: "Alexander the Great",
            },
            {
              definition:
                "Doing or exemplifying (a characteristic or pursuit) on a large scale; active or enthusiastic.",
              synonyms: [],
              antonyms: [],
              example: "He's not a great one for reading.",
            },
            {
              definition: "(often followed by 'at') Skilful or adroit.",
              synonyms: [],
              antonyms: [],
              example: "You are great at singing.",
            },
            {
              definition:
                "Very good; excellent; wonderful; fantastic (from 1848).",
              synonyms: [],
              antonyms: [],
              example: "Dinner was great.",
            },
            {
              definition:
                "Intensifying a word or expression, used in mild oaths.",
              synonyms: [],
              antonyms: [],
              example: "Great Scott!",
            },
          ],
          synonyms: ["gr8", "grt"],
          antonyms: ["mediocre", "ordinary", "tiny"],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Very well (in a very satisfactory manner).",
              synonyms: [],
              antonyms: [],
              example:
                "Those mechanical colored pencils work great because they don't have to be sharpened.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "Expression of gladness and content about something.",
              synonyms: [],
              antonyms: [],
              example: "Great! Thanks for the wonderful work.",
            },
            {
              definition: "Sarcastic inversion thereof.",
              synonyms: [],
              antonyms: [],
              example:
                "Oh, great! I just dumped all 500 sheets of the manuscript all over and now I have to put them back in order.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/great"],
    },
  ],
  again: [
    {
      word: "again",
      phonetic: "/əˈɡeɪn/",
      phonetics: [
        {
          text: "/əˈɡeɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/again-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9014240",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Another time; once more.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Over and above a factor of one.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Used metalinguistically, with the repetition being in the discussion, or in the linguistic or pragmatic context of the discussion, rather than in the subject of discussion.",
              synonyms: [],
              antonyms: [],
              example: "Great, thanks again!",
            },
            {
              definition:
                "Back in the reverse direction, or to an original starting point.",
              synonyms: [],
              antonyms: [],
              example: "Bring us word again.",
            },
            {
              definition: "Back (to a former place or state).",
              synonyms: [],
              antonyms: [],
              example: "The South will rise again.",
            },
            {
              definition: "In return, as a reciprocal action; back.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "In any other place.", synonyms: [], antonyms: [] },
            { definition: "On the other hand.", synonyms: [], antonyms: [] },
            {
              definition: "Moreover; besides; further.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "preposition",
          definitions: [
            {
              definition: "(obsolete or dialectal) Against.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/again"],
    },
  ],
  still: [
    {
      word: "still",
      phonetic: "/stɪl/",
      phonetics: [
        {
          text: "/stɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/still-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9027492",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/stɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/still-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=711299",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A period of calm or silence.",
              synonyms: [],
              antonyms: [],
              example: "the still of the night",
            },
            {
              definition: "A photograph, as opposed to movie footage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A resident of the Falkland Islands.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A steep hill or ascent.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "lull",
            "quiet",
            "respite",
            "rest",
            "tranquility",
            "Benny",
            "Falklander",
            "Kelper",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Not moving; calm.",
              synonyms: [],
              antonyms: [],
              example: "Still waters run deep.",
            },
            {
              definition: "Not effervescing; not sparkling.",
              synonyms: [],
              antonyms: [],
              example: "still water; still wines",
            },
            {
              definition: "Uttering no sound; silent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Having the same stated quality continuously from a past time",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Comparatively quiet or silent; soft; gentle; low.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Constant; continual.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "hushed",
            "tranquil",
            "incessant",
            "ongoing",
            "unremitting",
            "flat",
            "uneffervescent",
            "fixed",
            "inert",
            "stagnant",
            "static",
            "stationary",
            "unmoving",
            "noiseless",
            "soundless",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Without motion.",
              synonyms: [],
              antonyms: [],
              example: "They stood still until the guard was out of sight.",
            },
            {
              definition: "(aspect) Up to a time, as in the preceding time.",
              synonyms: [],
              antonyms: [],
              example:
                "Is it still raining?   It was still raining five minutes ago.",
            },
            {
              definition:
                "(degree) To an even greater degree. Used to modify comparative adjectives or adverbs.",
              synonyms: [],
              antonyms: [],
              example: "Tom is tall; Dick is taller; Harry is still taller.",
            },
            {
              definition: "Nevertheless.",
              synonyms: [],
              antonyms: [],
              example:
                "I’m not hungry, but I’ll still manage to find room for dessert.",
            },
            {
              definition: "Always; invariably; constantly; continuously.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(extensive) Even, yet.",
              synonyms: [],
              antonyms: [],
              example: "Some dogs howl, more yelp, still more bark.",
            },
          ],
          synonyms: [
            "consistently",
            "invariably",
            "uniformly",
            "nonetheless",
            "though",
            "yet",
            "even",
            "yet",
            "yet",
            "akinetically",
            "motionlessly",
            "stock still",
            "stockishly",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/still"],
    },
    {
      word: "still",
      phonetic: "/stɪl/",
      phonetics: [
        {
          text: "/stɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/still-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9027492",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/stɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/still-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=711299",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A device for distilling liquids.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(catering) a large water boiler used to make tea and coffee.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(catering) the area in a restaurant used to make tea and coffee, separate from the main kitchen.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A building where liquors are distilled; a distillery.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/still"],
    },
    {
      word: "still",
      phonetic: "/stɪl/",
      phonetics: [
        {
          text: "/stɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/still-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9027492",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/stɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/still-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=711299",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To calm down, to quiet",
              synonyms: [],
              antonyms: [],
              example: "to still the raging sea",
            },
          ],
          synonyms: ["becalm", "lull", "quell"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/still"],
    },
    {
      word: "still",
      phonetic: "/stɪl/",
      phonetics: [
        {
          text: "/stɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/still-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9027492",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/stɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/still-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=711299",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To trickle, drip.", synonyms: [], antonyms: [] },
            {
              definition: "To cause to fall by drops.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To expel spirit from by heat, or to evaporate and condense in a refrigeratory; to distill.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/still"],
    },
  ],
  every: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  small: [
    {
      word: "small",
      phonetic: "/smoːl/",
      phonetics: [
        { text: "/smoːl/", audio: "" },
        { text: "/smɑl/", audio: "" },
        { text: "/smoːl/", audio: "" },
        {
          text: "/smɔːl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/small-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9027436",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/smɔl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/small-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=711641",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
        { text: "/smɑl/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any part of something that is smaller or slimmer than the rest, now usually with anatomical reference to the back.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make little or less.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To become small; to dwindle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Not large or big; insignificant; few in number.",
              synonyms: [],
              antonyms: [],
              example: "A small group.",
            },
            {
              definition: "Young, as a child.",
              synonyms: [],
              antonyms: [],
              example: "Remember when the children were small?",
            },
            {
              definition:
                "(writing, incomparable) Minuscule or lowercase, referring to written or printed letters.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Envincing little worth or ability; not large-minded; paltry; mean.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Not prolonged in duration; not extended in time; short.",
              synonyms: [],
              antonyms: [],
              example: "a small space of time",
            },
            {
              definition: "Slender, gracefully slim.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "little",
            "microscopic",
            "minuscule",
            "minute",
            "tiny",
            "lowercase",
            "minuscule",
            "little",
            "wee",
            "young",
          ],
          antonyms: [
            "big",
            "capital",
            "generous",
            "large",
            "big",
            "capital",
            "majuscule",
            "uppercase",
            "adult",
            "grown-up",
            "old",
          ],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            { definition: "In a small fashion.", synonyms: [], antonyms: [] },
            {
              definition: "In or into small pieces.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To a small extent.", synonyms: [], antonyms: [] },
            {
              definition: "In a low tone; softly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/small"],
    },
  ],
  found: [
    {
      word: "found",
      phonetic: "/faʊnd/",
      phonetics: [
        {
          text: "/faʊnd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/found-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1224046",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To encounter or discover by accident; to happen upon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To encounter or discover something being searched for; to locate.",
              synonyms: [],
              antonyms: [],
              example: "I found my car keys. They were under the couch.",
            },
            {
              definition:
                "(ditransitive) To discover by study or experiment direct to an object or end.",
              synonyms: [],
              antonyms: [],
              example: "Water is found to be a compound substance.",
            },
            {
              definition: "To gain, as the object of desire or effort.",
              synonyms: [],
              antonyms: [],
              example: "to find leisure; to find means",
            },
            {
              definition: "To attain to; to arrive at; to acquire.",
              synonyms: [],
              antonyms: [],
              example: "Looks like he found a new vehicle for himself!",
            },
            {
              definition: "To point out.",
              synonyms: [],
              antonyms: [],
              example: "He kept finding faults with my work.",
            },
            {
              definition:
                "(ditransitive) To decide that, to discover that, to form the opinion that.",
              synonyms: [],
              antonyms: [],
              example: "I find your argument unsatisfactory.",
            },
            {
              definition:
                "To arrive at, as a conclusion; to determine as true; to establish.",
              synonyms: [],
              antonyms: [],
              example:
                "to find a verdict; to find a true bill (of indictment) against an accused person",
            },
            {
              definition: "To supply; to furnish.",
              synonyms: [],
              antonyms: [],
              example: "to find food for workmen",
            },
            {
              definition: "To provide for",
              synonyms: [],
              antonyms: [],
              example: "He finds his nephew in money.",
            },
            {
              definition: "To determine or judge.",
              synonyms: [],
              antonyms: [],
              example: "The jury finds for the defendant.",
            },
            { definition: "To discover game.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: ["lose"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Food and lodging; board.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/find",
        "https://en.wiktionary.org/wiki/found",
      ],
    },
    {
      word: "found",
      phonetic: "/faʊnd/",
      phonetics: [
        {
          text: "/faʊnd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/found-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1224046",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To start (an institution or organization).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To begin building.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["establish"],
          antonyms: ["ruin", "abolish", "dissolve"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/found"],
    },
    {
      word: "found",
      phonetic: "/faʊnd/",
      phonetics: [
        {
          text: "/faʊnd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/found-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1224046",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To melt, especially of metal in an industrial setting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To form by melting a metal and pouring it into a mould; to cast.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/found"],
    },
    {
      word: "found",
      phonetic: "/faʊnd/",
      phonetics: [
        {
          text: "/faʊnd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/found-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1224046",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A thin, single-cut file for comb-makers.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/found"],
    },
  ],
  those: [
    {
      word: "those",
      phonetic: "/ðəʊz/",
      phonetics: [
        { text: "/ðəʊz/", audio: "" },
        {
          text: "/ðoʊz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/those-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1221228",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "pronoun",
          definitions: [
            {
              definition:
                '(demonstrative) The thing, person, idea, quality, event, action or time indicated or understood from context, especially if more remote geographically, temporally or mentally than one designated as "this", or if expressing distinction.',
              synonyms: [],
              antonyms: [],
              example: "He went home, and after that I never saw him again.",
            },
            {
              definition:
                "The known (thing); used to refer to something just said.",
              synonyms: [],
              antonyms: [],
              example:
                "They're getting divorced. What do you think about that?",
            },
            {
              definition:
                "(demonstrative) The aforementioned quality or proposition; used to emphatically affirm or deny a previous statement or question.",
              synonyms: [],
              antonyms: [],
              example: "The water is so cold! — That it is.",
            },
            {
              definition:
                "(relative) (plural that) Which, who; representing a subject, direct object, indirect object, or object of a preposition.",
              synonyms: [],
              antonyms: [],
              example: "The CPR course that she took really came in handy.",
            },
            {
              definition:
                "Used in place of relative adverbs such as where or when; often omitted.",
              synonyms: [],
              antonyms: [],
              example: "the last time that [= when] I went to Europe",
            },
          ],
          synonyms: [],
          antonyms: ["here", "there", "this", "yon", "yonder"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/that",
        "https://en.wiktionary.org/wiki/those",
      ],
    },
  ],
  never: [
    {
      word: "never",
      phonetic: "/ˈnɛv.ə(ɹ)/",
      phonetics: [
        {
          text: "/ˈnɛv.ə(ɹ)/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/never-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9023273",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ˈnɛ.vɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/never-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=711164",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "At no time; on no occasion; in no circumstance.",
              synonyms: [],
              antonyms: [],
              example: "I finally finished, and I never want to do that again.",
            },
            {
              definition:
                "Not at any other time; not on any other occasion; not previously.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Negative particle (used to negate verbs in the simple past tense; also used absolutely).",
              synonyms: [],
              antonyms: [],
              example: "I never saw him today.",
            },
          ],
          synonyms: [],
          antonyms: [
            "all the time",
            "always",
            "consistently",
            "constantly",
            "continually",
            "continuously",
            "ever",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/never"],
    },
  ],
  under: [
    {
      word: "under",
      phonetic: "/ˈʊndə/",
      phonetics: [
        { text: "/ˈʊndə/", audio: "" },
        { text: "/ˈʌndə(ɹ)/", audio: "" },
        {
          text: "/ˈʌndɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/under-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=711354",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Being lower; being beneath something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Under anesthesia, especially general anesthesia; sedated.",
              synonyms: [],
              antonyms: [],
              example: "Ensure the patient is sufficiently under.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "In a way lower or less than.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "In a way inferior to.", synonyms: [], antonyms: [] },
            {
              definition: "In an unconscious state.",
              synonyms: [],
              antonyms: [],
              example:
                "It took the hypnotist several minutes to make his subject go under.",
            },
          ],
          synonyms: ["below", "beneath"],
          antonyms: ["above", "over"],
        },
        {
          partOfSpeech: "preposition",
          definitions: [
            {
              definition: "In or at a lower level than.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "As a subject of; subordinate to.",
              synonyms: [],
              antonyms: [],
              example: "He served in World War II under General Omar Bradley.",
            },
            { definition: "Less than.", synonyms: [], antonyms: [] },
            { definition: "Below the surface of.", synonyms: [], antonyms: [] },
            {
              definition:
                "In the face of; in response to (some attacking force).",
              synonyms: [],
              antonyms: [],
              example:
                "to collapse under stress; to give in under interrogation",
            },
            {
              definition: "As, in the character of.",
              synonyms: [],
              antonyms: [],
              example: "he writes books under the name John Smith",
            },
          ],
          synonyms: ["below", "beneath", "underneath"],
          antonyms: ["above", "over"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/under"],
    },
  ],
  might: [
    {
      word: "might",
      phonetic: "/maɪt/",
      phonetics: [
        {
          text: "/maɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/might-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=711156",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Power, strength, force or influence held by a person or group.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Physical strength or force.",
              synonyms: [],
              antonyms: [],
              example:
                "He pushed with all his might, but still it would not move.",
            },
            {
              definition: "The ability to do something.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Mighty; powerful.", synonyms: [], antonyms: [] },
            { definition: "Possible.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/might"],
    },
    {
      word: "might",
      phonetic: "/maɪt/",
      phonetics: [
        {
          text: "/maɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/might-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=711156",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To be strong; to have power (over).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(auxiliary) To be able; can.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To be able to go.", synonyms: [], antonyms: [] },
            {
              definition:
                "(modal auxiliary verb, defective) To have permission to, be allowed. Used in granting permission and in questions to make polite requests.",
              synonyms: ["can", "could", "might"],
              antonyms: [],
              example: "you may smoke outside;  may I sit there?",
            },
            {
              definition:
                "(modal auxiliary verb, defective) Expressing a present possibility; possibly.",
              synonyms: ["could", "might"],
              antonyms: [],
              example:
                "he may be lying;  Schrödinger's cat may or may not be in the box",
            },
            {
              definition:
                "(subjunctive present, defective) Expressing a wish (with present subjunctive effect).",
              synonyms: ["might"],
              antonyms: [],
              example: "may you win;  may the weather be sunny",
            },
            {
              definition:
                "Used in modesty, courtesy, or concession, or to soften a question or remark.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["can", "could", "might", "could", "might", "might"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(auxiliary) Used to indicate conditional or possible actions.",
              synonyms: [],
              antonyms: [],
              example: "I might go to the party, but I haven't decided yet.",
            },
            {
              definition:
                "Used to indicate a desired past action that was not done.",
              synonyms: [],
              antonyms: [],
              example:
                "Hey man, you might have warned me about the thunderstorm.",
            },
            {
              definition: "(auxiliary) Even though.",
              synonyms: [],
              antonyms: [],
              example:
                "I might be in a wheelchair, but I still want to be treated as a lady.",
            },
            {
              definition: "(auxiliary) Used in polite requests for permission",
              synonyms: [],
              antonyms: [],
              example: "Might I take the last biscuit?",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/may",
        "https://en.wiktionary.org/wiki/might",
      ],
    },
  ],
  while: [
    {
      word: "while",
      phonetic: "/waɪl/",
      phonetics: [
        {
          text: "/waɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/while-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=37605774",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An uncertain duration of time, a period of time.",
              synonyms: [],
              antonyms: [],
              example: "He lectured for quite a long while.",
            },
          ],
          synonyms: ["spell"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To pass (time) idly.",
              synonyms: ["idle", "laze", "lounge"],
              antonyms: [],
            },
            {
              definition:
                "To occupy or entertain (someone) in order to let time pass.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To loiter.",
              synonyms: ["hang around", "linger"],
              antonyms: [],
            },
          ],
          synonyms: ["hang around", "linger", "idle", "laze", "lounge"],
          antonyms: [],
        },
        {
          partOfSpeech: "preposition",
          definitions: [{ definition: "Until.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "conjunction",
          definitions: [
            {
              definition: "During the same time that.",
              synonyms: [],
              antonyms: [],
              example: "He was sleeping while I was singing.",
            },
            {
              definition: "Although.",
              synonyms: [],
              antonyms: [],
              example: "This case, while interesting, is a bit frustrating.",
            },
            {
              definition: "Until.",
              synonyms: [],
              antonyms: [],
              example: "I'll wait while you've finished painting.",
            },
            {
              definition: "As long as.",
              synonyms: [],
              antonyms: [],
              example: "While you're at school you may live at home.",
            },
            {
              definition:
                "(public policy) Used to denote an individual experiencing racial profiling when performing a seemingly benign activity.",
              synonyms: [],
              antonyms: [],
              example:
                "He was detained for four hours at the store yesterday. His crime? Shopping while black.",
            },
          ],
          synonyms: [
            "as much as",
            "provided that",
            "providing",
            "so long as",
            "whilst",
            "till",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/while"],
    },
  ],
  house: [
    {
      word: "house",
      phonetic: "/hʌʊs/",
      phonetics: [
        {
          text: "/hʌʊs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/house-1-ca.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=94251036",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/haʊs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/house-1-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9022017",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/hʌʊs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/house-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=711134",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A structure built or serving as an abode of human beings.",
              synonyms: [],
              antonyms: [],
              example: "This is my house and my family's ancestral home.",
            },
            {
              definition: "The people who live in a house; a household.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A building used for something other than a residence (typically with qualifying word).",
              synonyms: [],
              antonyms: [],
              example:
                "On arriving at the zoo, we immediately headed for the monkey house.",
            },
            {
              definition:
                "The audience for a live theatrical or similar performance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A theatre.",
              synonyms: [],
              antonyms: [],
              example:
                "After her swan-song, there wasn't a dry eye in the house.",
            },
            {
              definition:
                "A building where a deliberative assembly meets; whence the assembly itself, particularly a component of a legislature.",
              synonyms: [],
              antonyms: [],
              example:
                "The petition was so ridiculous that the house rejected it after minimal debate.",
            },
            {
              definition:
                "A dynasty; a family with its ancestors and descendants, especially a royal or noble one.",
              synonyms: [],
              antonyms: [],
              example: "A curse lay upon the House of Atreus.",
            },
            {
              definition: "A place of rest or repose.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A grouping of schoolchildren for the purposes of competition in sports and other activities.",
              synonyms: [],
              antonyms: [],
              example: "I was a member of Spenser house when I was at school.",
            },
            {
              definition:
                "An animal's shelter or den, or the shell of an animal such as a snail, used for protection.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the twelve divisions of an astrological chart.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The fourth Lenormand card.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A square on a chessboard, regarded as the proper place of a piece.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The four concentric circles where points are scored on the ice.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Lotto; bingo.", synonyms: [], antonyms: [] },
            {
              definition:
                "A children's game in which the players pretend to be members of a household.",
              synonyms: [],
              antonyms: [],
              example:
                "As the babysitter, Emma always acted as the mother whenever the kids demanded to play house.",
            },
            {
              definition: "A small stand of trees in a swamp.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(sudoku) A set of cells in a Sudoku puzzle which must contain each digit exactly once, such as a row, column, or 3×3 box in classic Sudoku.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["shop", "shop"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/house"],
    },
    {
      word: "house",
      phonetic: "/haʊz/",
      phonetics: [
        {
          text: "/haʊz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/house-2-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=711135",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To keep within a structure or container.",
              synonyms: [],
              antonyms: [],
              example: "The car is housed in the garage.",
            },
            {
              definition: "To admit to residence; to harbor/harbour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To take shelter or lodging; to abide; to lodge.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To dwell within one of the twelve astrological houses.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To contain or cover mechanical parts.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To drive to a shelter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To deposit and cover, as in the grave.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To stow in a safe place; to take down and make safe.",
              synonyms: [],
              antonyms: [],
              example: "to house the upper spars",
            },
            { definition: "To eat.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "accommodate",
            "harbor",
            "harbour",
            "host",
            "put up",
            "enclose",
            "store",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/house"],
    },
    {
      word: "house",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "House music.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/house"],
    },
  ],
  world: [
    {
      word: "world",
      phonetic: "/wɝld/",
      phonetics: [
        {
          text: "/wɝld/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/world-ca.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=238003",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
        { text: "/wɵːld/", audio: "" },
        { text: "/wɜːld/", audio: "" },
        {
          text: "/wɝld/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/world-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=67249678",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                '(with "the") Human collective existence; existence in general.',
              synonyms: [],
              antonyms: [],
              example: "There will always be lovers, till the world’s end.",
            },
            { definition: "The Universe.", synonyms: [], antonyms: [] },
            {
              definition: '(with "the") The Earth.',
              synonyms: [],
              antonyms: [],
              example: "People are dying of starvation all over the world.",
            },
            {
              definition:
                "A planet, especially one which is inhabited or inhabitable.",
              synonyms: [],
              antonyms: [],
              example:
                "Our mission is to travel the galaxy and find new worlds.",
            },
            {
              definition: "A very large extent of country.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(speculation) A realm, such as planet, containing one or multiple societies of beings, especially intelligent ones.",
              synonyms: [],
              antonyms: [],
              example:
                "the world of Narnia; the Wizarding World of Harry Potter; a zombie world",
            },
            {
              definition:
                "An individual or group perspective or social setting.",
              synonyms: [],
              antonyms: [],
              example: "In the world of boxing, good diet is all-important.",
            },
            {
              definition:
                "The part of an operating system distributed with the kernel, consisting of the shell and other programs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A subdivision of a game, consisting of a series of stages or levels that usually share a similar environment or theme.",
              synonyms: [],
              antonyms: [],
              example: "Have you reached the boss at the end of the ice world?",
            },
            {
              definition:
                "The twenty-second trump or major arcana card of the tarot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A great amount.",
              synonyms: [],
              antonyms: [],
              example:
                "Taking a break from work seems to have done her a world of good.",
            },
            { definition: "Age, era", synonyms: [], antonyms: [] },
          ],
          synonyms: ["planet", "circle", "Earth", "Sol III", "globe"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To consider or cause to be considered from a global perspective; to consider as a global whole, rather than making or focussing on national or other distinctions; compare globalise.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make real; to make worldly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/world"],
    },
  ],
  below: [
    {
      word: "below",
      phonetic: "/bɪˈləʊ/",
      phonetics: [
        { text: "/bɪˈləʊ/", audio: "" },
        {
          text: "/bəˈloʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/below-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=709486",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            { definition: "In a lower place.", synonyms: [], antonyms: [] },
            { definition: "On a lower storey.", synonyms: [], antonyms: [] },
            { definition: "Further down.", synonyms: [], antonyms: [] },
            {
              definition: "On a lower deck.",
              synonyms: [],
              antonyms: [],
              example: "the landlubbers lying down below",
            },
            {
              definition: "(of a temperature) Below zero.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "downwards",
            "beneath",
            "under",
            "underneath",
            "downstairs",
          ],
          antonyms: ["upwards", "aloft", "overhead", "up", "upstairs"],
        },
        {
          partOfSpeech: "preposition",
          definitions: [
            {
              definition: "Lower in spatial position than.",
              synonyms: [],
              antonyms: [],
              example: "The treasure is buried two meters below the surface.",
            },
            {
              definition:
                'Within the writing of a given document which follows a particular appearance of the word "below".',
              synonyms: [],
              antonyms: [],
              example:
                '"By their execution hereof, the Parties incur a legal obligation to pass consideration under this Loan Contract as is set forth below."',
            },
            {
              definition: "Lower in value, price, rank or concentration than.",
              synonyms: [],
              antonyms: [],
              example: "The temperature is below zero.",
            },
            { definition: "Downstream of.", synonyms: [], antonyms: [] },
            { definition: "South of.", synonyms: [], antonyms: [] },
            {
              definition: "Unsuitable to the rank or dignity of; beneath.",
              synonyms: [],
              antonyms: [],
              example: "Such petty behavior is below me.",
            },
            {
              definition: "(stage directions) Downstage of.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "downstream",
            "beneath",
            "under",
            "underneath",
            "under",
            "beneath",
          ],
          antonyms: ["upstream", "above", "over", "over"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/below"],
    },
  ],
  asked: [
    {
      word: "asked",
      phonetic: "/ˈɑːskt/",
      phonetics: [
        { text: "/ˈɑːskt/", audio: "" },
        {
          text: "/ˈæskt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/asked-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1225610",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To request (information, or an answer to a question).",
              synonyms: [],
              antonyms: [],
              example: "I asked her age.",
            },
            {
              definition: "To put forward (a question) to be answered.",
              synonyms: [],
              antonyms: [],
              example: "to ask a question",
            },
            {
              definition: "To interrogate or enquire of (a person).",
              synonyms: [],
              antonyms: [],
              example: "I'm going to ask this lady for directions.",
            },
            {
              definition: "To request or petition; usually with for.",
              synonyms: [],
              antonyms: [],
              example: "to ask for a second helping at dinner",
            },
            {
              definition: "To request permission to do something.",
              synonyms: [],
              antonyms: [],
              example: "Did you ask to use the car?",
            },
            {
              definition:
                "To require, demand, claim, or expect, whether by way of remuneration or return, or as a matter of necessity.",
              synonyms: [],
              antonyms: [],
              example: "What price are you asking for the house?",
            },
            {
              definition: "To invite.",
              synonyms: [],
              antonyms: [],
              example: "Don't ask them to the wedding.",
            },
            {
              definition:
                "To publish in church for marriage; said of both the banns and the persons.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To take (a person's situation) as an example.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ask",
        "https://en.wiktionary.org/wiki/asked",
      ],
    },
  ],
  going: [
    {
      word: "going",
      phonetic: "/ˈɡəʊɪŋ/",
      phonetics: [
        { text: "/ˈɡəʊɪŋ/", audio: "" },
        {
          text: "/ˈɡoʊɪŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/going-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1217858",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To move:",
              synonyms: [
                "cross",
                "draw",
                "drift",
                "fare",
                "move",
                "tread",
                "wend",
              ],
              antonyms: [
                "freeze",
                "halt",
                "remain",
                "stand still",
                "stay",
                "stop",
              ],
            },
            {
              definition:
                "(chiefly of a machine) To work or function (properly); to move or perform (as required).",
              synonyms: ["function", "operate", "work"],
              antonyms: [],
              example: "The engine just won't go anymore.",
            },
            {
              definition: "To start; to begin (an action or process).",
              synonyms: [],
              antonyms: [],
              example: "Get ready, get set, go!",
            },
            {
              definition: "To take a turn, especially in a game.",
              synonyms: ["make one's move", "move", "take one’s turn"],
              antonyms: [],
              example: "It’s your turn; go.",
            },
            {
              definition: "To attend.",
              synonyms: [],
              antonyms: [],
              example: "I go to school at the schoolhouse.",
            },
            { definition: "To proceed:", synonyms: [], antonyms: [] },
            {
              definition: "To follow or travel along (a path):",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To extend (from one point in time or space to another).",
              synonyms: [],
              antonyms: [],
              example: "This property goes all the way to the state line.",
            },
            {
              definition: "To lead (to a place); to give access to.",
              synonyms: [],
              antonyms: [],
              example: "Does this road go to Fort Smith?",
            },
            {
              definition:
                "To become. (The adjective that follows usually describes a negative state.)",
              synonyms: ["become", "change into", "turn"],
              antonyms: [],
              example:
                "After failing as a criminal, he decided to go straight.",
            },
            {
              definition:
                "To assume the obligation or function of; to be, to serve as.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To continuously or habitually be in a state.",
              synonyms: [],
              antonyms: [],
              example: "I don't want my children to go hungry.",
            },
            {
              definition: "To come to (a certain condition or state).",
              synonyms: [],
              antonyms: [],
              example:
                "They went into debt, she goes to sleep around 10 o'clock.",
            },
            {
              definition:
                "To change (from one value to another) in the meaning of wend.",
              synonyms: [],
              antonyms: [],
              example: "The traffic light went straight from green to red.",
            },
            {
              definition:
                "To turn out, to result; to come to (a certain result).",
              synonyms: [],
              antonyms: [],
              example: "How did your meeting with Smith go?",
            },
            {
              definition: "To tend (toward a result).",
              synonyms: [],
              antonyms: [],
              example: "These experiences go to make us stronger.",
            },
            {
              definition:
                "To contribute to a (specified) end product or result.",
              synonyms: [],
              antonyms: [],
              example:
                "qualities that go to make a lady / lip-reader / sharpshooter",
            },
            {
              definition: "To pass, to be used up:",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To die.", synonyms: [], antonyms: [] },
            {
              definition: "To be discarded.",
              synonyms: [],
              antonyms: [],
              example: "This chair has got to go.",
            },
            { definition: "To be lost or out:", synonyms: [], antonyms: [] },
            {
              definition: "To break down or apart:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be sold.",
              synonyms: [],
              antonyms: [],
              example: "Everything must go.",
            },
            {
              definition: "To be given, especially to be assigned or allotted.",
              synonyms: [],
              antonyms: [],
              example: "The award went to Steven Spielberg.",
            },
            {
              definition:
                "To survive or get by; to last or persist for a stated length of time.",
              synonyms: [],
              antonyms: [],
              example: "Can you two go twenty minutes without arguing?!",
            },
            {
              definition: "To have a certain record.",
              synonyms: [],
              antonyms: [],
              example: "The team is going five in a row.",
            },
            {
              definition: "To be authoritative, accepted, or valid:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To say (something), to make a sound:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be expressed or composed (a certain way).",
              synonyms: [],
              antonyms: [],
              example:
                "As the story goes, he got the idea for the song while sitting in traffic.",
            },
            {
              definition: "To resort (to).",
              synonyms: [],
              antonyms: [],
              example: "I'll go to court if I have to.",
            },
            {
              definition: "To apply or subject oneself to:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fit (in a place, or together with something):",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To date.",
              synonyms: ["date", "go out (with)", "see"],
              antonyms: [],
              example: "He's been going with her for two weeks.",
            },
            { definition: "To attack:", synonyms: [], antonyms: [] },
            {
              definition: "To be in general; to be usually.",
              synonyms: [],
              antonyms: [],
              example: "As sentences go, this one is pretty boring.",
            },
            {
              definition:
                "To take (a particular part or share); to participate in to the extent of.",
              synonyms: [],
              antonyms: [],
              example: "Let's go halves on this.",
            },
            {
              definition: "To yield or weigh.",
              synonyms: [],
              antonyms: [],
              example: "Those babies go five tons apiece.",
            },
            {
              definition: "To offer, bid or bet an amount; to pay.",
              synonyms: [],
              antonyms: [],
              example: "I'll go a ten-spot.",
            },
            {
              definition: "To enjoy. (Compare go for.)",
              synonyms: [],
              antonyms: [],
              example: "I could go a beer right about now.",
            },
            {
              definition: "To urinate or defecate.",
              synonyms: [],
              antonyms: [],
              example: "Have you managed to go today, Mrs. Miggins?",
            },
          ],
          synonyms: [
            "become",
            "change into",
            "turn",
            "cross",
            "draw",
            "drift",
            "fare",
            "move",
            "tread",
            "wend",
            "date",
            "go out (with)",
            "see",
            "function",
            "operate",
            "work",
            "make one's move",
            "move",
            "take one’s turn",
          ],
          antonyms: ["freeze", "halt", "remain", "stand still", "stay", "stop"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A departure.",
              synonyms: [],
              antonyms: [],
              example: "Thy going is not lonely, with thee goes thy Husband",
            },
            {
              definition: "The suitability of ground for riding, walking etc.",
              synonyms: [],
              antonyms: [],
              example: "The going was very difficult over the ice.",
            },
            {
              definition: "Progress.",
              synonyms: [],
              antonyms: [],
              example:
                "We made good going for a while, but then we came to the price.",
            },
            {
              definition: "Conditions for advancing in any way.",
              synonyms: [],
              antonyms: [],
              example:
                "Not only were the streets not paved with gold, but the going was difficult for an immigrant.",
            },
            {
              definition: "Pregnancy; gestation; childbearing",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in the plural) Course of life; behaviour; doings; ways.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                '(in the phrase "the going of") The whereabouts (of something).',
              synonyms: [],
              antonyms: [],
              example:
                "I can't find my sunglasses; you haven't seen the going of them, have you?",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Likely to continue; viable.",
              synonyms: [],
              antonyms: [],
              example:
                "He didn't want to make an unsecured loan to the business because it didn't look like a going concern.",
            },
            {
              definition: "Current, prevailing.",
              synonyms: [],
              antonyms: [],
              example:
                "The going rate for manual snow-shoveling is $25 an hour.",
            },
            {
              definition:
                "(especially after a noun phrase with a superlative) Available.",
              synonyms: [],
              antonyms: [],
              example: "He has the easiest job going.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/go",
        "https://en.wiktionary.org/wiki/going",
      ],
    },
  ],
  large: [
    {
      word: "large",
      phonetic: "/ˈlɑːd͡ʒ/",
      phonetics: [
        {
          text: "/ˈlɑːd͡ʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/large-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9023186",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ˈlɑɹd͡ʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/large-us.ogg",
          sourceUrl:
            "https://commons.wikimedia.org/w/index.php?curid=112437559",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An old musical note, equal to two longas, four breves, or eight semibreves.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Liberality, generosity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(plural: large) A thousand dollars/pounds.",
              synonyms: [],
              antonyms: [],
              example:
                "Getting a car tricked out like that will cost you 50 large.",
            },
            {
              definition: "A large serving of something.",
              synonyms: [],
              antonyms: [],
              example: "One small coffee and two larges, please.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of considerable or relatively great size or extent.",
              synonyms: [],
              antonyms: [],
              example:
                "Russia is a large country.   The fruit-fly has large eyes for its body size.   He has a large collection of stamps.",
            },
            { definition: "Abundant; ample.", synonyms: [], antonyms: [] },
            {
              definition: "Full in statement; diffuse; profuse.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Free; unencumbered.", synonyms: [], antonyms: [] },
            {
              definition: "Unrestrained by decorum; said of language.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Crossing the line of a ship's course in a favorable direction; said of the wind when it is abeam, or between the beam and the quarter.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "big",
            "enormous",
            "giant",
            "gigantic",
            "great",
            "huge",
            "largeish",
            "mickle",
            "stour",
          ],
          antonyms: ["minuscule", "small", "tiny"],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            { definition: "Before the wind.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/large"],
    },
  ],
  until: [
    {
      word: "until",
      phonetic: "/ənˈtɪl/",
      phonetics: [
        { text: "/ənˈtɪl/", audio: "" },
        {
          text: "/ənˈtɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/until-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=711356",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "preposition",
          definitions: [
            {
              definition: "Up to the time of (something happening).",
              synonyms: [],
              antonyms: [],
              example:
                "If you can wait until after my meeting with her, we'll talk then.",
            },
            {
              definition: "Up to (a certain place)",
              synonyms: [],
              antonyms: [],
              example:
                "Keep walking until the second set of traffic lights, then turn left.",
            },
            { definition: "Before (a time).", synonyms: [], antonyms: [] },
            {
              definition: "To; physically towards.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["'til", "till", "up to"],
          antonyms: ["since"],
        },
        {
          partOfSpeech: "conjunction",
          definitions: [
            {
              definition: "Up to the time that (a condition becomes true).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Before (a condition becoming true).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["afore", "before", "'til", "till"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/until"],
    },
  ],
  along: [
    {
      word: "along",
      phonetic: "/əˈlɑŋ/",
      phonetics: [
        { text: "/əˈlɑŋ/", audio: "" },
        { text: "/əˈlɒŋ/", audio: "" },
        {
          text: "/əˈlɔŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/along-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=709459",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
        { text: "/əˈlɑŋ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "In company; together.",
              synonyms: [],
              antonyms: [],
              example: "John played the piano and everyone sang along.",
            },
            {
              definition: "Onward, forward, with progressive action.",
              synonyms: [],
              antonyms: [],
              example: "Don't stop here. Just move along.",
            },
          ],
          synonyms: ["alongst"],
          antonyms: [],
        },
        {
          partOfSpeech: "preposition",
          definitions: [
            {
              definition:
                "By the length of; in a line with the length of; lengthwise next to.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In a line with, with a progressive motion on; onward on; forward on.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["alongst", "endlong"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/along"],
    },
  ],
  shall: [
    {
      word: "shall",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shall.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=77855772",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈʃæl/", audio: "" },
        { text: "/ʃ(ə)/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(modal, auxiliary verb, defective) Used before a verb to indicate the simple future tense in the first person singular or plural.",
              synonyms: [],
              antonyms: [],
              example: "I hope that we shall win the game.",
            },
            {
              definition:
                "Used similarly to indicate determination or obligation in the second and third persons singular or plural.",
              synonyms: [],
              antonyms: [],
              example: "(determination): You shall go to the ball!",
            },
            {
              definition:
                "Used in questions with the first person singular or plural to suggest a possible future action.",
              synonyms: [],
              antonyms: [],
              example: "Let us examine that, shall we?",
            },
            { definition: "To owe.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shall"],
    },
  ],
  being: [
    {
      word: "being",
      phonetic: "/ˈbiːɪŋ/",
      phonetics: [
        { text: "/ˈbiːɪŋ/", audio: "" },
        {
          text: "/ˈbiŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/being-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1221242",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A living creature.", synonyms: [], antonyms: [] },
            {
              definition:
                "The state or fact of existence, consciousness, or life, or something in such a state.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That which has actuality (materially or in concept).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One's basic nature, or the qualities thereof; essence or personality.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An abode; a cottage.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "conjunction",
          definitions: [
            { definition: "Given that; since.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["as", "because", "seeing that"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/being"],
    },
  ],
  often: [
    {
      word: "often",
      phonetic: "/ˈɑf(t)ən/",
      phonetics: [
        { text: "/ˈɑf(t)ən/", audio: "" },
        { text: "/ˈɒf(t)ən/", audio: "" },
        {
          text: "/ˈɔf(t)ən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/often-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1227447",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
        { text: "/ˈɑf(t)ən/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Frequent.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Frequently, many times.",
              synonyms: [],
              antonyms: [],
              example: "I often walk to work when the weather is nice.",
            },
          ],
          synonyms: ["a lot", "frequently", "usually"],
          antonyms: ["infrequently", "occasionally", "rarely", "seldom"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/often"],
    },
  ],
  earth: [
    {
      word: "earth",
      phonetic: "/ɜːθ/",
      phonetics: [
        {
          text: "/ɜːθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/earth-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9014381",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ɝθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/earth-us.ogg",
          sourceUrl:
            "https://commons.wikimedia.org/w/index.php?curid=112429238",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "proper noun",
          definitions: [
            {
              definition:
                "The third planet of the Solar System; the world upon which humans live.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The personification of the Earth or earth, as a fertile woman or goddess.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "Mother Earth",
            "Sol III",
            "Terra",
            "globe",
            "world",
            "Middle Earth",
            "Midgard",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Soil.",
              synonyms: [],
              antonyms: [],
              example: "This is good earth for growing potatoes.",
            },
            {
              definition: "Any general rock-based material.",
              synonyms: [],
              antonyms: [],
              example:
                "She sighed when the plane's wheels finally touched earth.",
            },
            {
              definition: "The ground, land (as opposed to the sky or sea).",
              synonyms: [],
              antonyms: [],
              example: "Birds are of the sky, not of the earth.",
            },
            {
              definition:
                "A connection electrically to the earth ((US) ground); on equipment: a terminal connected in that manner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The lair (as a hole on the ground) of an animal such as fox.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A region of the planet; a land or country.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Worldly things, as against spiritual ones.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The world of our current life (as opposed to heaven or an afterlife).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The people on the globe.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The human body.", synonyms: [], antonyms: [] },
            {
              definition:
                "The aforementioned soil- or rock-based material, considered one of the four or five classical elements.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of certain substances now known to be oxides of metal, which were distinguished by being infusible, and by insolubility in water.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To connect electrically to the earth.",
              synonyms: ["ground"],
              antonyms: [],
              example:
                "That noise is because the amplifier is not properly earthed.",
            },
            { definition: "To bury.", synonyms: [], antonyms: [] },
            { definition: "To burrow.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["ground"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/Earth",
        "https://en.wiktionary.org/wiki/earth",
      ],
    },
  ],
  began: [
    {
      word: "began",
      phonetic: "/bɪˈɡæn/",
      phonetics: [
        { text: "/bɪˈɡæn/", audio: "" },
        {
          text: "/bɪˈɡæn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/began-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1227415",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To start, to initiate or take the first step into something.",
              synonyms: [],
              antonyms: [],
              example:
                "I began playing the piano at the age of five.   Now that everyone is here, we should begin the presentation.",
            },
            {
              definition: "To be in the first stage of some situation",
              synonyms: [],
              antonyms: [],
              example:
                "The program begins at 9 o'clock on the dot.    I rushed to get to class on time, but the lesson had already begun.",
            },
            {
              definition: "To come into existence.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["commence", "initiate", "start"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/began",
        "https://en.wiktionary.org/wiki/begin",
      ],
    },
  ],
  since: [
    {
      word: "since",
      phonetic: "/sɪns/",
      phonetics: [
        {
          text: "/sɪns/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/since-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1224188",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "From a specified time in the past.",
              synonyms: [],
              antonyms: [],
              example: "A short/long time since",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "preposition",
          definitions: [
            {
              definition:
                "From: referring to a period of time ending in the present and defining it by the point in time at which it started, or the period in which its starting point occurred.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["until"],
        },
        {
          partOfSpeech: "conjunction",
          definitions: [
            {
              definition: "From the time that.",
              synonyms: [],
              antonyms: [],
              example: "I have loved you since I first met you.",
            },
            {
              definition: "Because.",
              synonyms: [],
              antonyms: [],
              example: "Since you didn't call, we left without you.",
            },
            { definition: "When or that.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["sith", "sithen"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/since"],
    },
  ],
  study: [
    {
      word: "study",
      phonetic: "/ˈstʌdi/",
      phonetics: [
        {
          text: "/ˈstʌdi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/study-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9028805",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ˈstʌdi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/study-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=589417",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(usually academic) To review materials already learned in order to make sure one does not forget them, usually in preparation for an examination.",
              synonyms: [],
              antonyms: [],
              example: "I need to study my biology notes.",
            },
            {
              definition:
                "(academic) To take a course or courses on a subject.",
              synonyms: [],
              antonyms: [],
              example: "I study medicine at the university.",
            },
            {
              definition:
                "To acquire knowledge on a subject with the intention of applying it in practice.",
              synonyms: [],
              antonyms: [],
              example: "Biologists study living things.",
            },
            {
              definition: "To look at minutely.",
              synonyms: [],
              antonyms: [],
              example: "He studied the map in preparation for the hike.",
            },
            {
              definition:
                "To fix the mind closely upon a subject; to dwell upon anything in thought; to muse; to ponder.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To endeavor diligently; to be zealous.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["con", "elucubrate", "research", "revise", "swot"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/study"],
    },
    {
      word: "study",
      phonetic: "/ˈstʌdi/",
      phonetics: [
        {
          text: "/ˈstʌdi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/study-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9028805",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ˈstʌdi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/study-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=589417",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Mental effort to acquire knowledge or learning.",
              synonyms: [],
              antonyms: [],
              example: "The study of languages is fascinating.",
            },
            {
              definition: "The act of studying or examining; examination.",
              synonyms: [],
              antonyms: [],
              example: "I made a careful study of his sister.",
            },
            {
              definition:
                "Any particular branch of learning that is studied; any object of attentive consideration.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A room in a house intended for reading and writing; traditionally the private room of the male head of household.",
              synonyms: [],
              antonyms: [],
              example:
                "Father spends all his time in the study poring over manuscripts.",
            },
            {
              definition:
                "An artwork made in order to practise or demonstrate a subject or technique.",
              synonyms: [],
              antonyms: [],
              example: "a study of heads or of hands for a figure picture",
            },
            {
              definition:
                "The human face, bearing an expression which the observer finds amusingly typical of a particular emotion or state of mind.",
              synonyms: [],
              antonyms: [],
              example:
                "Geoffrey's face was a study in amazement [or in bewilderment, irritation, distress etc.]",
            },
            {
              definition: "A piece for special practice; an étude.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(academic) An academic publication.",
              synonyms: [],
              antonyms: [],
              example:
                "That new study on noncommutative symmetries looks promising.",
            },
            {
              definition: "One who commits a theatrical part to memory.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A state of mental perplexity or worried thought.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Thought, as directed to a specific purpose; one's concern.",
              synonyms: [],
              antonyms: [],
              example: "My study was to avoid disturbing her.",
            },
          ],
          synonyms: ["cabinet", "closet"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/study"],
    },
  ],
  night: [
    {
      word: "night",
      phonetic: "/naɪt/",
      phonetics: [
        {
          text: "/naɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/night-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=77899722",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/naɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/night-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1113678",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The period between sunset and sunrise, when a location faces far away from the sun, thus when the sky is dark.",
              synonyms: [],
              antonyms: [],
              example:
                "How do you sleep at night when you attack your kids like that!?",
            },
            {
              definition:
                "(Astronomy) The period of darkness beginning at the end of evening astronomical twilight when the sun is 18 degrees below the horizon, and ending at the beginning of morning astronomical twilight.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Legal) Often defined in the legal system as beginning 30 minutes after sunset, and ending 30 minutes before sunrise.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An evening or night spent at a particular activity.",
              synonyms: [],
              antonyms: [],
              example: "a night on the town",
            },
            {
              definition:
                "A night (and part of the days before and after it) spent in a place away from home, e.g. a hotel.",
              synonyms: [],
              antonyms: [],
              example: "I stayed my friend's house for three nights.",
            },
            {
              definition: "Nightfall.",
              synonyms: [],
              antonyms: [],
              example: "from noon till night",
            },
            {
              definition: "Darkness (due to it being nighttime).",
              synonyms: [],
              antonyms: [],
              example: "The cat disappeared into the night.",
            },
            {
              definition: "A dark blue colour, midnight blue.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A night's worth of competitions, generally one game.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "blackness",
            "darkness",
            "gloom",
            "obscurity",
            "shadow",
            "evening",
            "dark",
            "dusk",
            "nightfall",
            "sundown",
            "sunset",
            "sleep",
          ],
          antonyms: ["brightness", "daylight", "light", "day"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To spend a night (in a place), to overnight.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "Ellipsis of good night",
              synonyms: [],
              antonyms: [],
              example: "Night, y'all! Thanks for a great evening!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/night"],
    },
  ],
  light: [
    {
      word: "light",
      phonetic: "/lʌɪt/",
      phonetics: [
        { text: "/lʌɪt/", audio: "" },
        {
          text: "/laɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/light-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9023196",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "[ɫɐɪ̯ʔ]",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/light-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=876704",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Visible electromagnetic radiation. The human eye can typically detect radiation (light) in the wavelength range of about 400 to 750 nanometers. Nearby shorter and longer wavelength ranges, although not visible, are commonly called ultraviolet and infrared light.",
              synonyms: [],
              antonyms: [],
              example:
                "As you can see, this spacious dining-room gets a lot of light in the mornings.",
            },
            {
              definition: "A source of illumination.",
              synonyms: [],
              antonyms: [],
              example: "Put that light out!",
            },
            {
              definition:
                "Spiritual or mental illumination; enlightenment, useful information.",
              synonyms: [],
              antonyms: [],
              example: "Can you throw any light on this problem?",
            },
            {
              definition:
                "(in the plural) Facts; pieces of information; ideas, concepts.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A notable person within a specific field or discipline.",
              synonyms: [],
              antonyms: [],
              example:
                "Picasso was one of the leading lights of the cubist movement.",
            },
            {
              definition:
                "The manner in which the light strikes a picture; that part of a picture which represents those objects upon which the light is supposed to fall; the more illuminated part of a landscape or other scene; opposed to shade.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A point of view, or aspect from which a concept, person or thing is regarded.",
              synonyms: [],
              antonyms: [],
              example: "I'm really seeing you in a different light today.",
            },
            {
              definition: "A flame or something used to create fire.",
              synonyms: [],
              antonyms: [],
              example: "Hey, buddy, you got a light?",
            },
            {
              definition:
                "A firework made by filling a case with a substance which burns brilliantly with a white or coloured flame.",
              synonyms: [],
              antonyms: [],
              example: "a Bengal light",
            },
            {
              definition: "A window, or space for a window in architecture.",
              synonyms: [],
              antonyms: [],
              example: "This facade has eight south-facing lights.",
            },
            {
              definition:
                "The series of squares reserved for the answer to a crossword clue.",
              synonyms: [],
              antonyms: [],
              example:
                "The average length of a light on a 15×15 grid is 7 or 8.",
            },
            {
              definition:
                "A cross-light in a double acrostic or triple acrostic.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Open view; a visible state or condition; public observation; publicity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The power of perception by vision.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The brightness of the eye or eyes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A traffic light, or, by extension, an intersection controlled by one or more that will face a traveler who is receiving instructions.",
              synonyms: [],
              antonyms: [],
              example: "To get to our house, turn right at the third light.",
            },
          ],
          synonyms: ["visible light"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/light"],
    },
    {
      word: "light",
      phonetic: "/lʌɪt/",
      phonetics: [
        { text: "/lʌɪt/", audio: "" },
        {
          text: "/laɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/light-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9023196",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "[ɫɐɪ̯ʔ]",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/light-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=876704",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To start (a fire).",
              synonyms: ["set"],
              antonyms: ["extinguish", "put out", "quench"],
              example: "We lit the fire to get some heat.",
            },
            {
              definition: "To set fire to; to set burning.",
              synonyms: ["conflagrate", "ignite", "kindle"],
              antonyms: ["extinguish", "put out", "quench"],
              example: "She lit her last match.",
            },
            {
              definition:
                "To illuminate; to provide light for when it is dark.",
              synonyms: ["illuminate", "light up"],
              antonyms: [],
              example:
                "I used my torch to light the way home through the woods in the night.",
            },
            {
              definition: "To become ignited; to take fire.",
              synonyms: ["catch fire", "conflagrate", "ignite"],
              antonyms: [],
              example: "This soggy match will not light.",
            },
            {
              definition:
                "To attend or conduct with a light; to show the way to by means of a light.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make (a bonus) available to be collected by hitting a target, and thus light up the feature light corresponding to that bonus to indicate its availability.",
              synonyms: [],
              antonyms: [],
              example:
                "Light the extra ball by amassing 500 million points in the wizard mode.",
            },
          ],
          synonyms: [
            "catch fire",
            "conflagrate",
            "ignite",
            "conflagrate",
            "ignite",
            "kindle",
            "illuminate",
            "light up",
            "set",
          ],
          antonyms: [
            "extinguish",
            "put out",
            "quench",
            "extinguish",
            "put out",
            "quench",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/light"],
    },
    {
      word: "light",
      phonetic: "/lʌɪt/",
      phonetics: [
        { text: "/lʌɪt/", audio: "" },
        {
          text: "/laɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/light-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9023196",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "[ɫɐɪ̯ʔ]",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/light-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=876704",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having light; bright; clear; not dark or obscure.",
              synonyms: [],
              antonyms: [],
              example:
                "The room is light when the Sun shines through the window.",
            },
            {
              definition:
                "Pale or whitish in color; highly luminous and more or less deficient in chroma.",
              synonyms: [],
              antonyms: [],
              example: "She had light skin.",
            },
            {
              definition: "(of coffee) Served with extra milk or cream.",
              synonyms: [],
              antonyms: [],
              example: "I like my coffee light.",
            },
          ],
          synonyms: ["bright", "lightful", "pale", "cream", "milk", "white"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/light"],
    },
    {
      word: "light",
      phonetic: "/lʌɪt/",
      phonetics: [
        { text: "/lʌɪt/", audio: "" },
        {
          text: "/laɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/light-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9023196",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "[ɫɐɪ̯ʔ]",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/light-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=876704",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A stone that is not thrown hard enough.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "See lights.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To unload a ship, or to jettison material to make it lighter",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To lighten; to ease of a burden; to take off.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Having little or relatively little actual weight; not cumbrous or unwieldy.",
              synonyms: [],
              antonyms: [],
              example:
                "a light load ; a lighter backpack after having removed the books ; light weapons",
            },
            {
              definition:
                "Having little weight as compared with bulk; of little density or specific gravity.",
              synonyms: [],
              antonyms: [],
              example:
                "feathers and cork are light ; oil is lighter than water",
            },
            {
              definition:
                "Of short or insufficient weight; weighing less than the legal, standard or proper amount; clipped or diminished.",
              synonyms: [],
              antonyms: [],
              example: "to issue light coin",
            },
            {
              definition: "Lacking that which burdens or makes heavy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Not heavy or soggy; spongy; well raised.",
              synonyms: [],
              antonyms: [],
              example: "a light bread ; sponge cake is a light cake",
            },
            {
              definition: "Gentle; having little force or momentum.",
              synonyms: [],
              antonyms: [],
              example: "This artist clearly had a light, flowing touch.",
            },
            {
              definition: "Easy to endure or perform.",
              synonyms: [],
              antonyms: [],
              example: "light duties around the house",
            },
            {
              definition: "Low in fat, calories, alcohol, salt, etc.",
              synonyms: [],
              antonyms: [],
              example:
                "This light beer still gets you drunk if you have enough of it.",
            },
            {
              definition:
                "Unimportant, trivial, having little value or significance.",
              synonyms: [],
              antonyms: [],
              example: "I made some light comment, and we moved on.",
            },
            { definition: "Unchaste, wanton.", synonyms: [], antonyms: [] },
            {
              definition:
                "Not encumbered; unembarrassed; clear of impediments; hence, active; nimble; swift.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Easily influenced by trifling considerations; unsteady; unsettled; volatile.",
              synonyms: [],
              antonyms: [],
              example: "a light, vain person; a light mind",
            },
            {
              definition:
                "Indulging in, or inclined to, levity; lacking dignity or solemnity; frivolous; airy.",
              synonyms: [],
              antonyms: [],
              example: "Ogden Nash was a writer of light verse.",
            },
            {
              definition:
                "Not quite sound or normal; somewhat impaired or deranged; dizzy; giddy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Easily interrupted by stimulation.",
              synonyms: [],
              antonyms: [],
              example: "light sleep; light anesthesia",
            },
          ],
          synonyms: [
            "delicate",
            "gentle",
            "soft",
            "inconsequential",
            "trivial",
            "unimportant",
            "lightweight",
            "lite",
            "lo-cal",
            "low-alcohol",
          ],
          antonyms: [
            "forceful",
            "heavy",
            "strong",
            "crucial",
            "important",
            "weighty",
            "cumbersome",
            "heavyweight",
            "massive",
            "calorific",
            "fatty",
            "strong",
            "burdensome",
            "heavy",
            "weighty",
          ],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Carrying little.",
              synonyms: [],
              antonyms: [],
              example: "I prefer to travel light.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/light"],
    },
    {
      word: "light",
      phonetic: "/lʌɪt/",
      phonetics: [
        { text: "/lʌɪt/", audio: "" },
        {
          text: "/laɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/light-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9023196",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "[ɫɐɪ̯ʔ]",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/light-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=876704",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To find by chance.",
              synonyms: [],
              antonyms: [],
              example: "I lit upon a rare book in a second-hand bookseller's.",
            },
            {
              definition: "To stop upon (of eyes or a glance); to notice",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To alight; to land or come down.",
              synonyms: [],
              antonyms: [],
              example:
                "She fell out of the window but luckily lit on her feet.",
            },
          ],
          synonyms: [
            "alight",
            "land",
            "chance upon",
            "come upon",
            "find",
            "happen upon",
            "hit upon",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/light"],
    },
  ],
  above: [
    {
      word: "above",
      phonetic: "/əˈbʌv/",
      phonetics: [
        { text: "/əˈbʌv/", audio: "" },
        {
          text: "/əˈbʌv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/above-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=619790",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Heaven.", synonyms: [], antonyms: [] },
            {
              definition:
                "Something, especially a person's name in legal documents, that appears higher on the same page or on a preceding page.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Higher authority.", synonyms: [], antonyms: [] },
            {
              definition: "Betterment, raised status or condition.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Of heaven; heavenly.", synonyms: [], antonyms: [] },
            {
              definition:
                "(by ellipsis) Being located higher on the same page or on a preceding page.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Directly overhead; vertically on top of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Higher in the same page; earlier in the order as far as writing products go.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Into or from heaven; in the sky.",
              synonyms: [],
              antonyms: [],
              example:
                "He's in a better place now, floating free as the clouds above.",
            },
            {
              definition: "In a higher place; upstairs; farther upstream.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Higher in rank, power, or position.",
              synonyms: [],
              antonyms: [],
              example: "He appealed to the court above.",
            },
            { definition: "In addition.", synonyms: [], antonyms: [] },
            { definition: "More in number.", synonyms: [], antonyms: [] },
            {
              definition: "Above zero; above freezing.",
              synonyms: [],
              antonyms: [],
              example: "It was a cold day at only 5 above.",
            },
            {
              definition:
                "On the upper half or the dorsal surface of an animal.",
              synonyms: [],
              antonyms: [],
              example:
                "The sparrow I saw was rufous above and off-white below.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "preposition",
          definitions: [
            {
              definition:
                "Physically over; on top of; worn on top of, said of clothing.",
              synonyms: [],
              antonyms: [],
              example: "He always put his coat on above his sweater.",
            },
            {
              definition:
                "In or to a higher place; higher than; on or over the upper surface.",
              synonyms: [],
              antonyms: ["below", "beneath"],
            },
            {
              definition: "Farther north than.",
              synonyms: [],
              antonyms: [],
              example: "Idaho is above Utah.",
            },
            {
              definition: "Rising; appearing out of reach height-wise.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Higher than; superior to in any respect; surpassing; higher in measure, degree, volume, or pitch, etc. than; out of reach; not exposed to; not likely to be affected by; incapable of negative actions or thoughts.",
              synonyms: [],
              antonyms: [],
              example: "Even the chief of police is not above suspicion.",
            },
            {
              definition: "Higher in rank, status, or position.",
              synonyms: [],
              antonyms: [],
              example: "to stand head and shoulders above the rest",
            },
            {
              definition: "In addition to; besides.",
              synonyms: [],
              antonyms: [],
              example: "above and beyond the call of duty",
            },
            {
              definition: "Surpassing in number or quantity; more than.",
              synonyms: [],
              antonyms: [],
              example: "That amount is way above our asking price.",
            },
            { definition: "In preference to.", synonyms: [], antonyms: [] },
            {
              definition:
                "Too proud to stoop to; averse to; disinclined towards;",
              synonyms: [],
              antonyms: [],
              example: "The owner was above taking more than a token salary.",
            },
            {
              definition: "Beyond; on the other side.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Upstage of.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: ["below", "beneath"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/above"],
    },
  ],
  paper: [
    {
      word: "paper",
      phonetic: "/ˈpeɪpə/",
      phonetics: [
        {
          text: "/ˈpeɪpə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/paper-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9023294",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ˈpeɪpɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/paper-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1113757",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sheet material used for writing on or printing on (or as a non-waterproof container), usually made by draining cellulose fibres from a suspension in water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A newspaper or anything used as such (such as a newsletter or listing magazine).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Wallpaper.", synonyms: [], antonyms: [] },
            { definition: "Wrapping paper.", synonyms: [], antonyms: [] },
            {
              definition:
                "(rock paper scissors) An open hand (a handshape resembling a sheet of paper), that beats rock and loses to scissors. It loses to lizard and beats Spock in rock-paper-scissors-lizard-Spock.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A written document, generally shorter than a book (white paper, term paper), in particular one written for the Government.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A written document that reports scientific or academic research and is usually subjected to peer review before publication in a scientific journal (as a journal article or the manuscript for one) or in the proceedings of a scientific or academic meeting (such as a conference, workshop, or symposium).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A scholastic essay.", synonyms: [], antonyms: [] },
            {
              definition:
                "A set of examination questions to be answered at one session.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Money.", synonyms: [], antonyms: [] },
            { definition: "A university course.", synonyms: [], antonyms: [] },
            {
              definition: "A paper packet containing a quantity of items.",
              synonyms: [],
              antonyms: [],
              example: "a paper of pins, tacks, opium, etc.",
            },
            {
              definition:
                "A medicinal preparation spread upon paper, intended for external application.",
              synonyms: [],
              antonyms: [],
              example: "cantharides paper",
            },
            {
              definition:
                "A substance resembling paper secreted by certain invertebrates as protection for their nests and eggs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Free passes of admission to a theatre, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) The people admitted by free passes.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bookfell"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To apply paper to.",
              synonyms: [],
              antonyms: [],
              example: "to paper the hallway walls",
            },
            {
              definition: "To document; to memorialize.",
              synonyms: [],
              antonyms: [],
              example:
                "After they reached an agreement, their staffs papered it up.",
            },
            {
              definition:
                "To fill (a theatre or other paid event) with complimentary seats.",
              synonyms: [],
              antonyms: [],
              example:
                "As the event has not sold well, we'll need to paper the house.",
            },
            {
              definition: "To submit official papers to (a law court, etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To give public notice (typically by displaying posters) that a person is wanted by the police or other authority.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To sandpaper.", synonyms: [], antonyms: [] },
            { definition: "To enfold in paper.", synonyms: [], antonyms: [] },
            {
              definition:
                "To paste the endpapers and flyleaves at the beginning and end of a book before fitting it into its covers.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Made of paper.",
              synonyms: [],
              antonyms: [],
              example: "paper bag; paper plane",
            },
            {
              definition: "Insubstantial (from the weakness of common paper)",
              synonyms: [],
              antonyms: [],
              example: "paper tiger; paper gangster",
            },
            {
              definition: "Planned (from plans being drawn up on paper)",
              synonyms: [],
              antonyms: [],
              example: "paper rocket; paper engine",
            },
            {
              definition:
                "Having a title that is merely official, or given by courtesy or convention.",
              synonyms: [],
              antonyms: [],
              example: "a paper baron; a paper lord",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/paper"],
    },
  ],
  parts: [
    {
      word: "parts",
      phonetic: "/pɑːts/",
      phonetics: [
        {
          text: "/pɑːts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/parts-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1227796",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A portion; a component.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Duty; responsibility.",
              synonyms: [],
              antonyms: [],
              example: "to do one’s part",
            },
            {
              definition:
                "The dividing line formed by combing the hair in different directions.",
              synonyms: [],
              antonyms: [],
              example: "The part of his hair was slightly to the left.",
            },
            {
              definition:
                "In the Hebrew lunisolar calendar, a unit of time equivalent to 3⅓ seconds.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A constituent of character or capacity; quality; faculty; talent; usually in the plural with a collective sense.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "chelek",
            "component",
            "element",
            "piece",
            "portion",
            "faction",
            "party",
            "parting",
            "shed",
            "shoad",
            "shode",
            "position",
            "role",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To leave the company of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cut hair with a parting; shed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To divide in two.",
              synonyms: [],
              antonyms: [],
              example: "to part the curtains",
            },
            {
              definition: "To be divided in two or separated; shed.",
              synonyms: [],
              antonyms: [],
              example: "A rope parts.  His hair parts in the middle.",
            },
            {
              definition: "To divide up; to share.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To have a part or share; to partake.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To separate or disunite; to remove from contact or contiguity; to sunder.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To hold apart; to stand or intervene between.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To separate by a process of extraction, elimination, or secretion.",
              synonyms: [],
              antonyms: [],
              example: "to part gold from silver",
            },
            { definition: "To leave; to quit.", synonyms: [], antonyms: [] },
            {
              definition: "To leave (an IRC channel).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Intellectual ability or learning.",
              synonyms: [],
              antonyms: [],
              example: "He was a man of great parts but little virtue.",
            },
            {
              definition: "(usually with “these”) Vicinity, region.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The genitals, short for private parts.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/part",
        "https://en.wiktionary.org/wiki/parts",
      ],
    },
  ],
  young: [
    {
      word: "young",
      phonetic: "/jʌŋ/",
      phonetics: [
        {
          text: "/jʌŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/young-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9029011",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/jʌŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/young-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1197739",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "People who are young; young people, collectively; youth.",
              synonyms: [],
              antonyms: [],
              example: "The young of today are well-educated.",
            },
            {
              definition:
                "Young or immature offspring (especially of an animal).",
              synonyms: [],
              antonyms: [],
              example: "The lion caught a gnu to feed its young.",
            },
            {
              definition:
                "(possibly nonstandard) An individual offspring; a single recently born or hatched organism.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To become or seem to become younger.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause to appear younger.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To exhibit younging.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "In the early part of growth or life; born not long ago.",
              synonyms: [],
              antonyms: [],
              example:
                "a lamb is a young sheep;  these picture books are for young readers",
            },
            {
              definition:
                "At an early stage of existence or development; having recently come into existence.",
              synonyms: [],
              antonyms: [],
              example:
                "the age of space travel is still young;   a young business",
            },
            {
              definition:
                "(Not) advanced in age; (far towards or) at a specified stage of existence or age.",
              synonyms: [],
              antonyms: [],
              example:
                "How young is your dog?   Her grandmother turned 70 years young last month.",
            },
            {
              definition: "Junior (of two related people with the same name).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of a decade of life) Early.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Youthful; having the look or qualities of a young person.",
              synonyms: [],
              antonyms: [],
              example:
                "My grandmother is a very active woman and is quite young for her age.",
            },
            {
              definition: "Of or belonging to the early part of life.",
              synonyms: [],
              antonyms: [],
              example: "The cynical world soon shattered my young dreams.",
            },
            {
              definition:
                "Having little experience; inexperienced; unpracticed; ignorant; weak.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "junior",
            "youthful",
            "juvenile",
            "youthful",
            "immature",
            "underdeveloped",
            "undeveloped",
            "juvenile",
          ],
          antonyms: [
            "aged",
            "elderly",
            "grown up",
            "old",
            "senior",
            "youthless",
            "aged",
            "elderly",
            "mature",
            "old",
            "youthless",
            "experienced",
            "mature",
            "veteran",
            "elderly",
            "mature",
            "senior",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/young"],
    },
  ],
  story: [
    {
      word: "story",
      phonetic: "/ˈstɔː.ɹi/",
      phonetics: [
        {
          text: "/ˈstɔː.ɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/story-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=816402",
        },
        {
          text: "/ˈstɔː.ɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/story-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=816404",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sequence of real or fictional events; or, an account of such a sequence.",
              synonyms: [],
              antonyms: [],
              example: "The book tells the story of two roommates.",
            },
            {
              definition: "A lie, fiction.",
              synonyms: [],
              antonyms: [],
              example: "You’ve been telling stories again, haven’t you?",
            },
            {
              definition: "(usually pluralized) A soap opera.",
              synonyms: [],
              antonyms: [],
              example:
                "What will she do without being able to watch her stories?",
            },
            { definition: "History.", synonyms: [], antonyms: [] },
            {
              definition:
                "A sequence of events, or a situation, such as might be related in an account.",
              synonyms: [],
              antonyms: [],
              example:
                "I tried it again; same story, no error message, nothing happened.",
            },
            {
              definition:
                "A chronological collection of pictures or short videos published by a user on an application or website that is typically only available for a short period.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["tome", "lie", "narrative", "serial", "soap opera"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To tell as a story; to relate or narrate about.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/story"],
    },
    {
      word: "story",
      phonetic: "/ˈstɔː.ɹi/",
      phonetics: [
        {
          text: "/ˈstɔː.ɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/story-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=816402",
        },
        {
          text: "/ˈstɔː.ɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/story-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=816404",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A building; an edifice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A floor or level of a building or ship.",
              synonyms: ["floor", "level", "story"],
              antonyms: [],
              example:
                "For superstitious reasons, many buildings number their 13th storey as 14, bypassing 13 entirely.",
            },
            {
              definition:
                "A vertical level in certain letters, such as a and g.",
              synonyms: [],
              antonyms: [],
              example:
                "The IPA symbol for a voiced velar stop is the single-storey , not the double-storey .",
            },
          ],
          synonyms: ["floor", "level", "story"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A building or edifice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A floor or level of a building; a storey.",
              synonyms: ["floor", "level"],
              antonyms: [],
              example:
                "Our shop was on the fourth story of the building, so we had to install an elevator.",
            },
          ],
          synonyms: ["floor", "level"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/storey",
        "https://en.wiktionary.org/wiki/story",
      ],
    },
  ],
  point: [
    {
      word: "point",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/point-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899624",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/pɔɪnt/", audio: "" },
        {
          text: "/pɔɪnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/point-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=179582",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A discrete division of something.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A sharp extremity.", synonyms: [], antonyms: [] },
            {
              definition:
                "One of the several different parts of the escutcheon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A short piece of cordage used in reefing sails.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A string or lace used to tie together certain garments.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Lace worked by the needle.",
              synonyms: [],
              antonyms: [],
              example: "point de Venise; Brussels point",
            },
            {
              definition:
                "An item of private information; a hint; a tip; a pointer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The attitude assumed by a pointer dog when he finds game.",
              synonyms: [],
              antonyms: [],
              example: "The dog came to a point.",
            },
            {
              definition:
                "The perpendicular rising of a hawk over the place where its prey has gone into cover.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The act of pointing, as of the foot downward in certain dance positions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The gesture of extending the index finger in a direction in order to indicate something.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A vaccine point.", synonyms: [], antonyms: [] },
            {
              definition:
                "In various sports, a position of a certain player, or, by extension, the player occupying that position.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "decimal point",
            "spot",
            "ord",
            "location",
            "place",
            "position",
            "spot",
            "opinion",
            "point of view",
            "view",
            "viewpoint",
            "moment",
            "ord",
            "time",
            "end",
            "ord",
            "tip",
            "mark",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/point"],
    },
    {
      word: "point",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/point-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899624",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/pɔɪnt/", audio: "" },
        {
          text: "/pɔɪnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/point-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=179582",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To extend the index finger in the direction of something in order to show where it is or to draw attention to it.",
              synonyms: [],
              antonyms: [],
              example: "It's rude to point at other people.",
            },
            {
              definition:
                "To draw attention to something or indicate a direction.",
              synonyms: [],
              antonyms: [],
              example: "The arrow of a compass points north",
            },
            {
              definition: "To face in a particular direction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(sometimes figurative) To direct toward an object; to aim.",
              synonyms: [],
              antonyms: [],
              example: "to point a gun at a wolf, or a cannon at a fort",
            },
            {
              definition:
                "To give a point to; to sharpen; to cut, forge, grind, or file to an acute end.",
              synonyms: [],
              antonyms: [],
              example: "to point a dart, a pencil, or (figuratively) a moral",
            },
            {
              definition: "To indicate a probability of something.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To repair mortar.", synonyms: [], antonyms: [] },
            {
              definition:
                "To fill up and finish the joints of (a wall), by introducing additional cement or mortar, and bringing it to a smooth surface.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(stone-cutting) To cut, as a surface, with a pointed tool.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To direct or encourage (someone) in a particular direction.",
              synonyms: [],
              antonyms: [],
              example:
                "If he asks for food, point him toward the refrigerator.",
            },
            {
              definition:
                "To separate an integer from a decimal with a decimal point.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To mark with diacritics.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To supply with punctuation marks; to punctuate.",
              synonyms: [],
              antonyms: [],
              example: "to point a composition",
            },
            {
              definition:
                "To direct the central processing unit to seek information at a certain location in memory.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To direct requests sent to a domain name to the IP address corresponding to that domain name.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To sail close to the wind.",
              synonyms: [],
              antonyms: [],
              example: "Bear off a little, we're pointing.",
            },
            {
              definition:
                "To indicate the presence of game by a fixed and steady look, as certain hunting dogs do.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of an abscess) To approximate to the surface; to head.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To give point to (something said or done); to give particular prominence or force to.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/point"],
    },
    {
      word: "point",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/point-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899624",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/pɔɪnt/", audio: "" },
        {
          text: "/pɔɪnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/point-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=179582",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To appoint.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/point"],
    },
  ],
  times: [
    {
      word: "times",
      phonetic: "/tɑemz/",
      phonetics: [
        { text: "/tɑemz/", audio: "" },
        { text: "/taɪmz/", audio: "" },
        { text: "/taɪmz/", audio: "" },
        {
          text: "/taɪmz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/times-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1227453",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The inevitable progression into the future with the passing of present and past events.",
              synonyms: [],
              antonyms: [],
              example: "Time stops for nobody.   the ebb and flow of time",
            },
            { definition: "A duration of time.", synonyms: [], antonyms: [] },
            { definition: "An instant of time.", synonyms: [], antonyms: [] },
            {
              definition:
                "The measurement under some system of region of day or moment.",
              synonyms: [],
              antonyms: [],
              example:
                "Let's synchronize our watches so we're not on different time.",
            },
            {
              definition: "Ratio of comparison.",
              synonyms: [],
              antonyms: [],
              example:
                "your car runs three times faster than mine;  that is four times as heavy as this",
            },
            {
              definition: "(grammar) Tense.",
              synonyms: [],
              antonyms: [],
              example: "the time of a verb",
            },
            {
              definition:
                "The measured duration of sounds; measure; tempo; rate of movement; rhythmical division.",
              synonyms: [],
              antonyms: [],
              example: "common or triple time;   the musician keeps good time.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To measure or record the time, duration, or rate of.",
              synonyms: [],
              antonyms: [],
              example:
                "I used a stopwatch to time myself running around the block.",
            },
            {
              definition:
                "To choose when something begins or how long it lasts.",
              synonyms: [],
              antonyms: [],
              example:
                "The President timed his speech badly, coinciding with the Super Bowl.",
            },
            {
              definition: "To keep or beat time; to proceed or move in time.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To pass time; to delay.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To regulate as to time; to accompany, or agree with, in time of movement.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To measure, as in music or harmony.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["set", "clock"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The circumstances of a certain time.",
              synonyms: [],
              antonyms: [],
              example: "Modern times are so very different from the past.",
            },
            {
              definition: "A person's experiences or biography.",
              synonyms: [],
              antonyms: [],
              example: "The Life and Times of Rosie the Riveter.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To multiply.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "preposition",
          definitions: [
            {
              definition: "Multiplied by",
              synonyms: [],
              antonyms: [],
              example: "Four times five is twenty.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/time",
        "https://en.wiktionary.org/wiki/times",
      ],
    },
  ],
  heard: [
    {
      word: "heard",
      phonetic: "/hiːɹd/",
      phonetics: [
        { text: "/hiːɹd/", audio: "" },
        { text: "/ˈhɜːd/", audio: "" },
        {
          text: "/hɜɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/heard-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=614796",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(stative) To perceive sounds through the ear.",
              synonyms: [],
              antonyms: [],
              example: "I was deaf, and now I can hear.",
            },
            {
              definition:
                "(stative) To perceive (a sound, or something producing a sound) with the ear, to recognize (something) in an auditory way.",
              synonyms: [],
              antonyms: [],
              example: "I heard a sound from outside the window.",
            },
            {
              definition:
                "To exercise this faculty intentionally; to listen to.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To listen favourably to; to grant (a request etc.).",
              synonyms: [],
              antonyms: [],
              example: "Eventually the king chose to hear her entreaties.",
            },
            {
              definition: "To receive information about; to come to learn of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(with from) To be contacted by.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To listen to (a person, case) in a court of law; to try.",
              synonyms: [],
              antonyms: [],
              example: "Your case will be heard at the end of the month.",
            },
            {
              definition:
                "To sympathize with; to understand the feelings or opinion of.",
              synonyms: [],
              antonyms: [],
              example: "You're tired of all the ads on TV? I hear ya.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "That has been heard or listened to; that has been aurally detected.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hear",
        "https://en.wiktionary.org/wiki/heard",
      ],
    },
  ],
  whole: [
    {
      word: "whole",
      phonetic: "/həʊl/",
      phonetics: [
        { text: "/həʊl/", audio: "" },
        {
          text: "/hoʊl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/whole-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1222250",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Something complete, without any parts missing.",
              synonyms: [],
              antonyms: [],
              example:
                "This variety of fascinating details didn't fall together into an enjoyable, coherent whole.",
            },
            { definition: "An entirety.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["entireness", "totality"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Entire, undivided.",
              synonyms: [],
              antonyms: [],
              example: "I ate a whole fish.",
            },
            {
              definition: "Sound, uninjured, healthy.",
              synonyms: [],
              antonyms: [],
              example:
                "He is of whole mind, but the same cannot be said about his physical state.",
            },
            {
              definition:
                "(of food) From which none of its constituents has been removed.",
              synonyms: [],
              antonyms: [],
              example: "whole wheat; whole milk",
            },
            { definition: "As yet unworked.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["total", "hale", "well"],
          antonyms: ["ground"],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "In entirety; entirely; wholly.",
              synonyms: [],
              antonyms: [],
              example: "I ate a fish whole!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/whole"],
    },
  ],
  white: [
    {
      word: "white",
      phonetic: "/waɪt/",
      phonetics: [
        {
          text: "/waɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/white-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9028966",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/waɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/white-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=872773",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The color/colour of snow or milk; the colour of light containing equal amounts of all visible wavelengths.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person of European descent with light-coloured skin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any butterfly of the family Pieridae.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "White wine.", synonyms: [], antonyms: [] },
            {
              definition: "Any object or substance that is of the color white.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The central part of the butt, which was formerly painted white; the centre of a mark at which a missile is shot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The enclosed part of a letter of the alphabet, especially when handwritten.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make white; to whiten; to bleach.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Bright and colourless; reflecting equal quantities of all frequencies of visible light.",
              synonyms: [],
              antonyms: ["black", "nonwhite", "unwhite"],
              example: "Write in black ink on white paper.",
            },
            {
              definition:
                "(sometimes capitalized) Of or relating to Caucasians, people of European descent with light-coloured skin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Designated for use by Caucasians.",
              synonyms: [],
              antonyms: [],
              example: "white drinking fountain",
            },
            {
              definition: "Relatively light or pale in colour.",
              synonyms: [],
              antonyms: [],
              example: "white birch",
            },
            {
              definition: "Pale or pallid, as from fear, illness, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a person or skin) Lacking coloration (tan) from ultraviolet light; not tanned.",
              synonyms: ["fair", "pale"],
              antonyms: ["tanned"],
            },
            {
              definition:
                "(of coffee or tea) Containing cream, milk, or creamer.",
              synonyms: [],
              antonyms: ["black"],
            },
            {
              definition:
                "The standard denomination of the playing pieces of a board game deemed to belong to the white set, no matter what the actual colour.",
              synonyms: [],
              antonyms: [],
              example:
                "The white pieces in this set are in fact made of light green glass.",
            },
            {
              definition:
                "Pertaining to an ecclesiastical order whose adherents dress in white habits; Cistercian.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Honourable, fair; decent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Grey, as from old age; having silvery hair; hoary.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Characterized by freedom from that which disturbs, and the like; fortunate; happy; favourable.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Regarded with especial favour; favourite; darling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Pertaining to constitutional or anti-revolutionary political parties or movements.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of tea) Made from immature leaves and shoots.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Not containing characters; see white space.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Said of a symbol or character outline, not solid, not filled with color. Compare black.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Characterised by the presence of snow.",
              synonyms: [],
              antonyms: [],
              example: "a white Christmas",
            },
          ],
          synonyms: ["fair", "pale"],
          antonyms: ["black", "black", "nonwhite", "unwhite", "tanned"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/white"],
    },
  ],
  given: [
    {
      word: "given",
      phonetic: "/ˈɡɪ.vən/",
      phonetics: [
        {
          text: "/ˈɡɪ.vən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/given-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1227391",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(ditransitive) To move, shift, provide something abstract or concrete to someone or something or somewhere.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(ditransitive) To estimate or predict (a duration or probability) for (something).",
              synonyms: [],
              antonyms: [],
              example: "I give it a 95% chance of success.",
            },
            {
              definition: "To yield slightly when a force is applied.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To collapse under pressure or force.",
              synonyms: [],
              antonyms: [],
              example:
                "One pillar gave, then more, and suddenly the whole floor pancaked onto the floor below.",
            },
            {
              definition: "To provide, as, a service or a broadcast.",
              synonyms: [],
              antonyms: [],
              example: "They're giving my favorite show!",
            },
            {
              definition: "To lead (onto or into).",
              synonyms: [],
              antonyms: [],
              example: "The master bedroom gives onto a spacious balcony.",
            },
            {
              definition: "To provide a view of.",
              synonyms: [],
              antonyms: [],
              example: "His window gave the park.",
            },
            {
              definition:
                "To exhibit as a product or result; to produce; to yield.",
              synonyms: [],
              antonyms: [],
              example:
                "The number of men, divided by the number of ships, gives four hundred to each ship.",
            },
            {
              definition: "To cause; to make; used with the infinitive.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause (someone) to have; produce in (someone); effectuate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To allow or admit by way of supposition; to concede.",
              synonyms: [],
              antonyms: [],
              example:
                "He can be bad-tempered, I'll give you that, but he's a hard worker.",
            },
            {
              definition: "To attribute; to assign; to adjudge.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To communicate or announce (advice, tidings, etc.); to pronounce or utter (an opinion, a judgment, a shout, etc.).",
              synonyms: [],
              antonyms: [],
              example:
                "The umpire finally gave his decision: the ball was out.",
            },
            {
              definition: "To grant power or permission to; to allow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To devote or apply (oneself).",
              synonyms: [],
              antonyms: [],
              example: "That boy is given to fits of bad temper.",
            },
            {
              definition: "To become soft or moist.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To shed tears; to weep.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To have a misgiving.", synonyms: [], antonyms: [] },
            {
              definition: "To be going on, to be occurring",
              synonyms: [],
              antonyms: [],
              example: "What gives?",
            },
          ],
          synonyms: [
            "bend",
            "cede",
            "flex",
            "move",
            "yield",
            "estimate",
            "guess",
            "predict",
          ],
          antonyms: ["resist", "get", "obtain", "receive", "take"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A condition that is assumed to be true without further evaluation.",
              synonyms: [],
              antonyms: [],
              example:
                "When evaluating this math problem, don't forget to read the givens.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Already arranged.", synonyms: [], antonyms: [] },
            { definition: "Currently discussed.", synonyms: [], antonyms: [] },
            {
              definition: "Particular, specific.",
              synonyms: [],
              antonyms: [],
              example:
                "No more than three people can be in that space at a given time.",
            },
            {
              definition: "Assumed as fact or hypothesis.",
              synonyms: [],
              antonyms: [],
              example:
                "Given that we will get the resources, what do we want to achieve?",
            },
            {
              definition: "(with to) Prone, disposed.",
              synonyms: [],
              antonyms: [],
              example:
                "He was given to taking a couple of glasses of port at his club.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "preposition",
          definitions: [
            {
              definition: "Considering; taking into account.",
              synonyms: [],
              antonyms: [],
              example:
                "Given the current situation, I don't think that's possible.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/give",
        "https://en.wiktionary.org/wiki/given",
      ],
    },
  ],
  means: [
    {
      word: "means",
      phonetic: "/miːnz/",
      phonetics: [
        {
          text: "/miːnz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/means-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1224279",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To lament.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To intend.", synonyms: [], antonyms: [] },
            {
              definition: "To convey (a meaning).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To have conviction in (something said or expressed); to be sincere in (what one says).",
              synonyms: [],
              antonyms: [],
              example: "Does she really mean what she said to him last night?",
            },
            {
              definition:
                "To cause or produce (a given result); to bring about (a given result).",
              synonyms: [],
              antonyms: [],
              example: "One faltering step means certain death.",
            },
            {
              definition:
                "(usually with to) To be of some level of importance.",
              synonyms: [],
              antonyms: [],
              example: "Formality and titles mean nothing in their circle.",
            },
          ],
          synonyms: [
            "bring about",
            "cause",
            "lead to",
            "result in",
            "convey",
            "indicate",
            "signify",
            "imply",
            "intend",
            "serious",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(now chiefly in the plural) A method or course of action used to achieve some result.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in the singular) An intermediate step or intermediate steps.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something which is intermediate or in the middle; an intermediate value or range of values; a medium.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The middle part of three-part polyphonic music; now specifically, the alto part in polyphonic music; an alto instrument.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The average of a set of values, calculated by summing them together and dividing by the number of terms; the arithmetic mean.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any function of multiple variables that satisfies certain properties and yields a number representative of its arguments; or, the number so yielded; a measure of central tendency.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Either of the two numbers in the middle of a conventionally presented proportion, as 2 and 3 in 1:2=3:6.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Resources; riches.",
              synonyms: [],
              antonyms: [],
              example: "He was living beyond his means.",
            },
          ],
          synonyms: ["wherewithal"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An instrument or condition for attaining a purpose.",
              synonyms: [],
              antonyms: [],
              example: "A car is a means of transport.",
            },
          ],
          synonyms: ["wherewithal"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mean",
        "https://en.wiktionary.org/wiki/means",
      ],
    },
  ],
  music: [
    {
      word: "music",
      phonetic: "/ˈmjuːzɪk/",
      phonetics: [
        {
          text: "/ˈmjuːzɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/music-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9023256",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ˈmjuzɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/music-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1113632",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A series of sounds organized in time, employing melody, harmony, tempo etc. usually to convey a mood.",
              synonyms: [],
              antonyms: [],
              example:
                "I keep listening to this music because it's a masterpiece.",
            },
            {
              definition: "Any pleasing or interesting sounds.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An art form, created by organizing of pitch, rhythm, and sounds made using musical instruments and sometimes singing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A guide to playing or singing a particular tune; sheet music.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Electronic signal jamming.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Heated argument.", synonyms: [], antonyms: [] },
            { definition: "Fun; amusement.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["melody", "vibe"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To seduce or entice with music.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/music"],
    },
  ],
  miles: [
    {
      word: "miles",
      phonetic: "/maɪlz/",
      phonetics: [
        {
          text: "/maɪlz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/miles-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1227558",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The international mile: a unit of length precisely equal to 1.609344 kilometers established by treaty among Anglophone nations in 1959, divided into 5,280 feet or 1,760 yards.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of several customary units of length derived from the 1593 English statute mile of 8 furlongs, equivalent to 5,280 feet or 1,760 yards of various precise values.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of many customary units of length derived from the Roman mile (mille passus) of 8 stades or 5,000 Roman feet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The Scandinavian mile: a unit of length precisely equal to 10 kilometers defined in 1889.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of many customary units of length from other measurement systems of roughly similar values, as the Chinese (里) or Arabic mile (al-mīl).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(travel) An airline mile in a frequent flier program.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any similarly large distance.",
              synonyms: [],
              antonyms: [],
              example: "The shot missed by a mile.",
            },
            {
              definition:
                "A race of 1 mile's length; a race of around 1 mile's length (usually 1500 or 1600 meters)",
              synonyms: [],
              antonyms: [],
              example: "The runners competed in the mile.",
            },
            {
              definition: "One mile per hour, as a measure of speed.",
              synonyms: [],
              antonyms: [],
              example: "five miles over the speed limit",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Much; a lot (used to emphasise a comparative)",
              synonyms: [],
              antonyms: [],
              example:
                "Her new paintings are miles better than her older ones.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mile",
        "https://en.wiktionary.org/wiki/miles",
      ],
    },
  ],
  thing: [
    {
      word: "thing",
      phonetic: "/θɪŋ/",
      phonetics: [
        {
          text: "/θɪŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/thing-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=252404",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "That which is considered to exist as a separate entity, object, quality or concept.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A word, symbol, sign, or other referent that can be used to refer to any entity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An individual object or distinct entity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A genuine concept, entity or phenomenon; something that actually exists (often contrary to expectation or belief).",
              synonyms: [],
              antonyms: [],
              example: "Bacon pie? Is that a thing?",
            },
            {
              definition: "Whatever can be owned.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Corporeal object.", synonyms: [], antonyms: [] },
            {
              definition:
                "(somewhat obsolete, with the) The latest fad or fashion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(in the plural) Clothes, possessions or equipment.",
              synonyms: [],
              antonyms: [],
              example: "Hold on, let me just grab my things.",
            },
            {
              definition:
                "A unit or container, usually containing edible goods.",
              synonyms: [],
              antonyms: [],
              example:
                "get me a thing of apple juice at the store;  I just ate a whole thing of jelly beans",
            },
            {
              definition: "A problem, dilemma, or complicating factor.",
              synonyms: [],
              antonyms: [],
              example:
                "The car looks cheap, but the thing is, I have doubts about its safety.",
            },
            { definition: "A penis.", synonyms: [], antonyms: [] },
            {
              definition: "A living being or creature.",
              synonyms: [],
              antonyms: [],
              example:
                "you poor thing;  she's a funny old thing, but her heart's in the right place;  I met a pretty blond thing at the bar",
            },
            {
              definition: "That which matters; the crux.",
              synonyms: [],
              antonyms: [],
              example:
                "that's the thing: we don't know where he went;  the thing is, I don't have any money",
            },
            {
              definition:
                "Used after a noun to refer dismissively to the situation surrounding the noun's referent.",
              synonyms: [],
              antonyms: [],
              example: "Oh yeah, I'm supposed to promote that vision thing.",
            },
            {
              definition:
                "That which is favoured; personal preference. (Used in possessive constructions.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A public assembly or judicial council in a Germanic country.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A romantic relationship.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["item", "stuff", "uncountable", "yoke"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To express as a thing; to reify.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/thing"],
    },
  ],
  today: [
    {
      word: "today",
      phonetic: "/təˈdeɪ/",
      phonetics: [
        {
          text: "/təˈdeɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/today-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9028857",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/təˈdeɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/today-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=823172",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A current day or date.",
              synonyms: ["current day", "this day"],
              antonyms: [],
              example: "Today is the day we'll fix this once and for all.",
            },
            {
              definition: "From 6am to 6pm on the current day.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["current day", "this day"],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "On the current day or date.",
              synonyms: [],
              antonyms: [],
              example: "I want this done today.",
            },
            {
              definition: "In the current era; nowadays.",
              synonyms: [],
              antonyms: [],
              example:
                "In the 1500s, people had to do things by hand, but today we have electric can openers.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/today"],
    },
  ],
  later: [
    {
      word: "later",
      phonetic: "/ˈleɪtə/",
      phonetics: [
        { text: "/ˈleɪtə/", audio: "" },
        {
          text: "/ˈleɪtɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/later-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1224242",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Near the end of a period of time.",
              synonyms: [],
              antonyms: [],
              example: "It was late in the evening when we finally arrived.",
            },
            {
              definition: "Specifically, near the end of the day.",
              synonyms: [],
              antonyms: [],
              example: "It was getting late and I was tired.",
            },
            {
              definition:
                "(usually not comparable) Associated with the end of a period.",
              synonyms: [],
              antonyms: [],
              example:
                "Late Latin is less fully inflected than classical Latin.",
            },
            {
              definition: "Not arriving until after an expected time.",
              synonyms: [],
              antonyms: [],
              example:
                "Even though we drove as fast as we could, we were still late.",
            },
            {
              definition: "Not having had an expected menstrual period.",
              synonyms: [],
              antonyms: [],
              example: "I'm late, honey. Could you buy a test?",
            },
            {
              definition:
                'Deceased, dead: used particularly when speaking of the dead person\'s actions while alive. (Often used with "the"; see usage notes.)',
              synonyms: [],
              antonyms: [],
              example: "Her late husband had left her well provided for.",
            },
            {
              definition:
                "Existing or holding some position not long ago, but not now; departed, or gone out of office.",
              synonyms: [],
              antonyms: [],
              example: "the late administration",
            },
            {
              definition: "Recent — relative to the noun it modifies.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of a star or class of stars, cooler than the sun.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition:
                "After a deadline has passed, past a designated time.",
              synonyms: [],
              antonyms: [],
              example:
                "We drove as fast as we could, but we still arrived late.",
            },
            {
              definition:
                "Formerly, especially in the context of service in a military unit.",
              synonyms: [],
              antonyms: [],
              example:
                "Colonel Easterwood, late of the 34th Carbines, was a guest at the dinner party.",
            },
            {
              definition: "Not long ago; just now.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["erenow", "freshly", "belatedly"],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition:
                "Afterward in time (used with than when comparing with another time).",
              synonyms: [],
              antonyms: [],
              example: "I arrived later than my roommate.",
            },
            {
              definition: "At some unspecified time in the future.",
              synonyms: [],
              antonyms: [],
              example: "I wanted to do it now, but I’ll have to do it later.",
            },
          ],
          synonyms: ["afterwards", "hereafter", "later on", "someday"],
          antonyms: ["earlier"],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "See you later; goodbye.",
              synonyms: [],
              antonyms: [],
              example: "Later, dude.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/late",
        "https://en.wiktionary.org/wiki/later",
      ],
    },
  ],
  using: [
    {
      word: "using",
      phonetic: "/ˈjuːzɪŋ/",
      phonetics: [
        {
          text: "/ˈjuːzɪŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/using-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1235963",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To utilize or employ.", synonyms: [], antonyms: [] },
            {
              definition:
                "To accustom; to habituate. (Now common only in participial form. Uses the same pronunciation as the noun; see usage notes.)",
              synonyms: [],
              antonyms: [],
              example: "soldiers who are used to hardships and danger",
            },
            {
              definition:
                "(except in past tense) To habitually do; to be wont to do. (Now chiefly in past-tense forms; see used to.)",
              synonyms: [],
              antonyms: [],
              example: "I used to get things done.",
            },
            {
              definition: "To behave toward; to act with regard to; to treat.",
              synonyms: [],
              antonyms: [],
              example: "to use an animal cruelly",
            },
            {
              definition: "To behave, act, comport oneself.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "apply",
            "employ",
            "engage",
            "utilise",
            "utilize",
            "exploit",
            "take advantage of",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Use; utilization", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/use",
        "https://en.wiktionary.org/wiki/using",
      ],
    },
  ],
  money: [
    {
      word: "money",
      phonetic: "/ˈmʌni/",
      phonetics: [
        {
          text: "/ˈmʌni/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/money-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9023233",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ˈmʌni/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/money-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1113567",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A legally or socially binding conceptual contract of entitlement to wealth, void of intrinsic value, payable for all debts and taxes, and regulated in supply.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A generally accepted means of exchange and measure of value.",
              synonyms: [],
              antonyms: [],
              example:
                "Before colonial times cowry shells imported from Mauritius were used as money in Western Africa.",
            },
            {
              definition:
                "A currency maintained by a state or other entity which can guarantee its value (such as a monetary union).",
              synonyms: [],
              antonyms: [],
              example: "money supply;  money market",
            },
            {
              definition:
                "Hard cash in the form of banknotes and coins, as opposed to cheques/checks, credit cards, or credit more generally.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The total value of liquid assets available for an individual or other economic unit, such as cash and bank deposits.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Wealth; a person, family or class that possesses wealth",
              synonyms: [],
              antonyms: [],
              example: "He married money.",
            },
            {
              definition:
                "An item of value between two or more parties used for the exchange of goods or services.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person who funds an operation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "beer ticket",
            "bread",
            "bucks",
            "cake",
            "cash",
            "cheddar",
            "coin",
            "cream",
            "currency",
            "dinars",
            "dosh",
            "dough",
            "ducats",
            "folding stuff",
            "funds",
            "geld",
            "gelt",
            "greenbacks",
            "jack",
            "legal tender",
            "lolly",
            "lucre",
            "means",
            "moolah",
            "paper",
            "pennies",
            "readies",
            "sheets",
            "shrapnel",
            "simoleons",
            "spends",
            "spondulicks",
            "sterling",
            "wonga",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/money"],
    },
  ],
  lines: [
    {
      word: "lines",
      phonetic: "/laɪnz/",
      phonetics: [
        {
          text: "/laɪnz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lines-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1239754",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A path through two or more points (compare ‘segment’); a continuous mark, including as made by a pen; any path, curved or straight.",
              synonyms: [],
              antonyms: [],
              example: "The arrow descended in a curved line.",
            },
            {
              definition: "A rope, cord, string, or thread, of any thickness.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A hose or pipe, of any size.",
              synonyms: [],
              antonyms: [],
              example: "a brake line",
            },
            {
              definition: "Direction, path.",
              synonyms: [],
              antonyms: [],
              example: "the line of sight",
            },
            {
              definition:
                "The wire connecting one telegraphic station with another, a telephone or internet cable between two points: a telephone or network connection.",
              synonyms: [],
              antonyms: [],
              example: "I tried to make a call, but the line was dead.",
            },
            { definition: "A clothesline.", synonyms: [], antonyms: [] },
            {
              definition: "A letter, a written form of communication.",
              synonyms: ["epistle", "letter", "note"],
              antonyms: [],
              example: "Drop me a line.",
            },
            {
              definition:
                "A connected series of public conveyances, as a roadbed or railway track; and hence, an established arrangement for forwarding merchandise, etc.",
              synonyms: [],
              antonyms: [],
              example: "a line of stages",
            },
            {
              definition:
                "A trench or rampart, or the non-physical demarcation of the extent of the territory occupied by specified forces.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The exterior limit of a figure or territory: a boundary, contour, or outline; a demarcation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A long tape or ribbon marked with units for measuring; a tape measure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A measuring line or cord.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That which was measured by a line, such as a field or any piece of land set apart; hence, allotted place of abode.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A threadlike crease or wrinkle marking the face, hand, or body; hence, a characteristic mark.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Lineament; feature; figure (of one's body).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A more-or-less straight sequence of people, objects, etc., either arranged as a queue or column and often waiting to be processed or dealt with, or arranged abreast of one another in a row (and contrasted with a column), as in a military formation.",
              synonyms: ["lineup", "queue"],
              antonyms: [],
              example: "The line forms on the right.",
            },
            {
              definition:
                "The regular infantry of an army, as distinguished from militia, guards, volunteer corps, cavalry, artillery etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A series or succession of ancestors or descendants of a given person; a family or race; compare lineage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A small amount of text. Specifically:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Course of conduct, thought, occupation, or policy; method of argument; department of industry, trade, or intellectual activity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The official, stated position (or set of positions) of an individual or group, particularly a political or religious faction.",
              synonyms: [],
              antonyms: [],
              example: "Remember, your answers must match the party line.",
            },
            {
              definition:
                "A set of products or services sold by a business, or by extension, the business itself.",
              synonyms: [],
              antonyms: [],
              example: "How many buses does the line have?",
            },
            {
              definition: "A number of shares taken by a jobber.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A measure of length:", synonyms: [], antonyms: [] },
            {
              definition: "A maxwell, a unit of magnetic flux.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: '(1800s, with "the") The batter’s box.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The position in which the fencers hold their swords.",
              synonyms: ["line of engagement"],
              antonyms: [],
            },
            {
              definition:
                "Proper relative position or adjustment (of parts, not as to design or proportion, but with reference to smooth working).",
              synonyms: [],
              antonyms: [],
              example: "the engine is in line / out of line",
            },
            {
              definition:
                "A small path-shaped portion or serving of a powdery illegal drug, especially cocaine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Instruction; doctrine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Population of cells derived from a single cell and containing the same genetic makeup.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(perfusion line) a set composed of a spike, a drip chamber, a clamp, a Y-injection site, a three-way stopcock and a catheter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A group of forwards that play together.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A vascular catheter.",
              synonyms: [],
              antonyms: [],
              example: "line sepsis",
            },
          ],
          synonyms: [
            "epistle",
            "letter",
            "note",
            "line of engagement",
            "lineup",
            "queue",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                'To place (objects) into a line (usually used with "up"); to form into a line; to align.',
              synonyms: [],
              antonyms: [],
              example: "to line troops",
            },
            {
              definition:
                "To place persons or things along the side of for security or defense; to strengthen by adding; to fortify.",
              synonyms: [],
              antonyms: [],
              example: "to line works with soldiers",
            },
            { definition: "To form a line along.", synonyms: [], antonyms: [] },
            {
              definition: "To mark with a line or lines, to cover with lines.",
              synonyms: [],
              antonyms: [],
              example: "to line a copy book",
            },
            {
              definition: "To represent by lines; to delineate; to portray.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To read or repeat line by line.",
              synonyms: [],
              antonyms: [],
              example: "to line out a hymn",
            },
            {
              definition:
                "To hit a line drive; to hit a line drive which is caught for an out. Compare fly and ground.",
              synonyms: [],
              antonyms: [],
              example: "Jones lined to left in his last at-bat.",
            },
            {
              definition:
                "To track (wild bees) to their nest by following their line of flight.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To measure.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cover the inner surface of (something), originally especially with linen.",
              synonyms: [],
              antonyms: [],
              example: "The bird lines its nest with soft grass.",
            },
            {
              definition:
                "To reinforce (the back of a book) with glue and glued scrap material such as fabric or paper.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To fill or supply (something), as a purse with money.",
              synonyms: [],
              antonyms: [],
              example: "to line the shelves",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(of a dog) To copulate with, to impregnate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Words spoken by the actors.",
              synonyms: [],
              antonyms: [],
              example: "I have yet to learn my lines.",
            },
            {
              definition:
                "(fortifications) Dispositions made to cover extended positions, and presenting a front in but one direction to an enemy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Form of a vessel as shown by the outlines of vertical, horizontal, and oblique sections.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'A school punishment in which a student must repeatedly write out a line of text related to the offence (e.g. "I must be quiet in class") a specified number of times; the lines of text so written out.',
              synonyms: [],
              antonyms: [],
              example: "I had to write out 200 lines",
            },
            {
              definition:
                "The reins with which a horse is guided by its driver.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/line",
        "https://en.wiktionary.org/wiki/lines",
      ],
    },
  ],
  order: [
    {
      word: "order",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/order-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75900278",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈɔːdə/", audio: "" },
        {
          text: "/ˈɔɹdɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/order-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1113725",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Arrangement, disposition, or sequence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A position in an arrangement, disposition, or sequence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The state of being well arranged.",
              synonyms: [],
              antonyms: [],
              example: "The house is in order; the machinery is out of order.",
            },
            {
              definition:
                "Conformity with law or decorum; freedom from disturbance; general tranquillity; public quiet.",
              synonyms: [],
              antonyms: [],
              example: "to preserve order in a community or an assembly",
            },
            { definition: "A command.", synonyms: [], antonyms: [] },
            {
              definition:
                "A request for some product or service; a commission to purchase, sell, or supply goods.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A group of religious adherents, especially monks or nuns, set apart within their religion by adherence to a particular rule or set of principles.",
              synonyms: [],
              antonyms: [],
              example: "St. Ignatius Loyola founded the Jesuit order in 1537.",
            },
            {
              definition: "An association of knights.",
              synonyms: [],
              antonyms: [],
              example: "the Order of the Garter, the Order of the Bath.",
            },
            {
              definition: "Any group of people with common interests.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A decoration, awarded by a government, a dynastic house, or a religious body to an individual, usually for distinguished service to a nation or to humanity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A rank in the classification of organisms, below class and above family; a taxon at that rank.",
              synonyms: [],
              antonyms: [],
              example: "Magnolias belong to the order Magnoliales.",
            },
            {
              definition:
                "A number of things or persons arranged in a fixed or suitable place, or relative position; a rank; a row; a grade; especially, a rank or class in society; a distinct character, kind, or sort.",
              synonyms: [],
              antonyms: [],
              example: "talent of a high order",
            },
            {
              definition:
                "(chiefly plural) An ecclesiastical grade or rank, as of deacon, priest, or bishop; the office of the Christian ministry.",
              synonyms: [],
              antonyms: [],
              example:
                "to take orders, or to take holy orders, that is, to enter some grade of the ministry",
            },
            {
              definition:
                "The disposition of a column and its component parts, and of the entablature resting upon it, in classical architecture; hence (since the column and entablature are the characteristic features of classical architecture) a style or manner of architectural design.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The sequence in which a side’s batsmen bat; the batting order.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A power of polynomial function in an electronic circuit’s block, such as a filter, an amplifier, etc.",
              synonyms: [],
              antonyms: [],
              example:
                "a 3-stage cascade of a 2nd-order bandpass Butterworth filter",
            },
            {
              definition:
                "The overall power of the rate law of a chemical reaction, expressed as a polynomial function of concentrations of reactants and products.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The cardinality, or number of elements in a set, group, or other structure regardable as a set.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of an element of a group) For given group G and element g ∈ G, the smallest positive natural number n, if it exists, such that (using multiplicative notation), gn = e, where e is the identity element of G; if no such number exists, the element is said to be of infinite order (or sometimes zero order).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The number of vertices in a graph.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A partially ordered set.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The relation on a partially ordered set that determines that it is, in fact, a partially ordered set.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The sum of the exponents on the variables in a monomial, or the highest such among all monomials in a polynomial.",
              synonyms: [],
              antonyms: [],
              example:
                "A quadratic polynomial, a x^2 + b x + c, is said to be of order (or degree) 2.",
            },
            {
              definition:
                "A written direction to furnish someone with money or property; compare money order, postal order.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["chaos"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To set in some sort of order.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To arrange, set in proper order.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To issue a command to.",
              synonyms: [],
              antonyms: [],
              example: "He ordered me to leave.",
            },
            {
              definition:
                "To request some product or service; to secure by placing an order.",
              synonyms: [],
              antonyms: [],
              example: "to order groceries",
            },
            {
              definition:
                "To admit to holy orders; to ordain; to receive into the ranks of the ministry.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["rank", "sort", "command"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/order"],
    },
  ],
  group: [
    {
      word: "group",
      phonetic: "/ɡɹuːp/",
      phonetics: [{ text: "/ɡɹuːp/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A number of things or persons being in some relation to one another.",
              synonyms: [],
              antonyms: [],
              example:
                "A group of people gathered in front of the Parliament to demonstrate against the Prime Minister's proposals.",
            },
            {
              definition:
                "A set with an associative binary operation, under which there exists an identity element, and such that each element has an inverse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An effective divisor on a curve.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A (usually small) group of people who perform music together.",
              synonyms: [],
              antonyms: [],
              example: "Did you see the new jazz group?",
            },
            {
              definition:
                "A small number (up to about fifty) of galaxies that are near each other.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A column in the periodic table of chemical elements.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A functional group.",
              synonyms: [],
              antonyms: [],
              example: "Nitro is an electron-withdrawing group.",
            },
            {
              definition: "A subset of a culture or of a society.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An air force formation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A collection of formations or rock strata.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A number of users with same rights with respect to accession, modification, and execution of files, computers and peripherals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An element of an espresso machine from which hot water pours into the portafilter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A number of eighth, sixteenth, etc., notes joined at the stems; sometimes rather indefinitely applied to any ornament made up of a few short notes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A set of teams playing each other in the same division, while not during the same period playing any teams that belong to other sets in the division.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A commercial organization.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["collection", "set", "band", "ensemble"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To put together to form a group.",
              synonyms: [],
              antonyms: [],
              example: "group the dogs by hair colour",
            },
            {
              definition: "To come together to form a group.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "assemble",
            "begather",
            "foregather",
            "throng",
            "amass",
            "categorise",
            "categorize",
            "classify",
            "collect",
            "collect up",
            "gather",
            "gather up",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/group"],
    },
  ],
  among: [
    {
      word: "among",
      phonetic: "/əˈmɒŋ/",
      phonetics: [
        {
          text: "/əˈmɒŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/among-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=718864",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "preposition",
          definitions: [
            {
              definition:
                "Denotes a mingling or intermixing with distinct or separable objects. (See Usage Note at amidst.)",
              synonyms: [],
              antonyms: [],
              example:
                "How can you speak with authority about their customs when you have never lived among them?",
            },
            {
              definition:
                "Denotes a belonging of a person or a thing to a group.",
              synonyms: [],
              antonyms: [],
              example:
                "He is among the few who completely understand the subject.",
            },
            {
              definition: "Denotes a sharing of a common feature in a group.",
              synonyms: [],
              antonyms: [],
              example:
                "Lactose intolerance is common among people of Asian heritage.",
            },
          ],
          synonyms: ["amid", "amidst", "amongst"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/among"],
    },
  ],
  learn: [
    {
      word: "learn",
      phonetic: "/lɜːn/",
      phonetics: [
        { text: "/lɜːn/", audio: "" },
        {
          text: "/lɝn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/learn-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=876680",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act of learning something",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To acquire, or attempt to acquire knowledge or an ability to do something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To attend a course or other educational activity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To gain knowledge from a bad experience so as to improve.",
              synonyms: [],
              antonyms: [],
              example: "learn from one's mistakes",
            },
            {
              definition: "To study.",
              synonyms: [],
              antonyms: [],
              example: "I learn medicine.",
            },
            {
              definition:
                "To come to know; to become informed of; to find out.",
              synonyms: [],
              antonyms: [],
              example: "He just learned that he will be sacked.",
            },
          ],
          synonyms: ["study"],
          antonyms: ["forget", "teach"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/learn"],
    },
    {
      word: "learn",
      phonetic: "/lɜːn/",
      phonetics: [
        { text: "/lɜːn/", audio: "" },
        {
          text: "/lɝn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/learn-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=876680",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To teach.",
              synonyms: [],
              antonyms: [],
              example: "Give him a clip round the ear. That'll learn him!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/learn"],
    },
  ],
  known: [
    {
      word: "known",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/known-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75622337",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/nəʊn/", audio: "" },
        {
          text: "/noʊn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/known-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1227452",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To perceive the truth or factuality of; to be certain of or that.",
              synonyms: [],
              antonyms: [],
              example: "He knew something terrible was going to happen.",
            },
            {
              definition: "To be aware of; to be cognizant of.",
              synonyms: [],
              antonyms: [],
              example:
                "Did you know Michelle and Jack were getting divorced? ― Yes, I knew.",
            },
            {
              definition:
                "To be acquainted or familiar with; to have encountered.",
              synonyms: [],
              antonyms: [],
              example: "I know your mother, but I’ve never met your father.",
            },
            {
              definition: "To experience.",
              synonyms: [],
              antonyms: [],
              example: "Their relationship knew ups and downs.",
            },
            {
              definition:
                "To be able to distinguish, to discern, particularly by contrast or comparison; to recognize the nature of.",
              synonyms: [],
              antonyms: [],
              example: "I wouldn't know one from the other.",
            },
            {
              definition:
                "To recognize as the same (as someone or something previously encountered) after an absence or change.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To understand or have a grasp of through experience or study.",
              synonyms: [],
              antonyms: [],
              example:
                "His mother tongue is Italian, but he also knows French and English.",
            },
            {
              definition:
                "To have sexual relations with. This meaning normally specified in modern English as e.g. to ’know someone in the biblical sense’ or to ‘know Biblically.’",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To have knowledge; to have information, be informed.",
              synonyms: [],
              antonyms: [],
              example: "He knows about 19th century politics.",
            },
            {
              definition: "To be or become aware or cognizant.",
              synonyms: [],
              antonyms: [],
              example:
                "Did you know Michelle and Jack were getting divorced? ― Yes, I knew.",
            },
            {
              definition: "To be acquainted (with another person).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be able to play or perform (a song or other piece of music).",
              synonyms: [],
              antonyms: [],
              example: 'Do you know "Blueberry Hill"?',
            },
          ],
          synonyms: ["coitize", "go to bed with", "sleep with"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Any fact or situation which is known or familiar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A constant or variable the value of which is already determined.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Identified as a specific type; famous, renowned.",
              synonyms: [],
              antonyms: ["unknown"],
              example: "He was a known pickpocket.",
            },
            {
              definition: "Accepted, familiar, researched.",
              synonyms: [],
              antonyms: ["unknown"],
            },
          ],
          synonyms: [],
          antonyms: ["unknown", "unknown"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/know",
        "https://en.wiktionary.org/wiki/known",
      ],
    },
  ],
  space: [
    {
      word: "space",
      phonetic: "/speɪs/",
      phonetics: [
        {
          text: "/speɪs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/space-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1157899",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "(heading) Of time.", synonyms: [], antonyms: [] },
            {
              definition:
                "(heading) Unlimited or generalized extent, physical or otherwise.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(heading) A bounded or specific extent, physical or otherwise.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "outer space",
            "place",
            "spot",
            "volume",
            "break",
            "gap",
            "leisure time",
            "spare time",
            "blank",
            "gap",
            "whitespace",
            "volume",
            "quad",
            "quadrat",
            "ether",
            "room",
            "volume",
            "duration",
            "span",
            "spell",
            "while",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To roam, walk, wander.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To set some distance apart.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To insert or utilise spaces in a written text.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To eject into outer space, usually without a space suit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To travel into and through outer space.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/space"],
    },
  ],
  table: [
    {
      word: "table",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/table-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75858606",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈteɪbəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/table-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9027508",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ˈteɪbəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/table-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1197404",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Furniture with a top surface to accommodate a variety of uses.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A two-dimensional presentation of data.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The top of a stringed instrument, particularly a member of the violin family: the side of the instrument against which the strings vibrate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One half of a backgammon board, which is divided into the inner and outer table.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The flat topmost facet of a cut diamond.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["grid", "vector"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To tabulate; to put into a table or grid.",
              synonyms: [],
              antonyms: [],
              example: "to table fines",
            },
            {
              definition:
                "To supply (a guest, client etc.) with food at a table; to feed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To delineate; to represent, as in a picture; to depict.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(non-US) To put on the table of a commission or legislative assembly; to propose for formal discussion or consideration, to put on the agenda.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To remove from the agenda, to postpone dealing with; to shelve (to indefinitely postpone consideration or discussion of something).",
              synonyms: [],
              antonyms: [],
              example:
                "The legislature tabled the amendment, so they will not be discussing it until later.",
            },
            {
              definition: "To join (pieces of timber) together using coaks.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To put on a table.", synonyms: [], antonyms: [] },
            {
              definition:
                "To make board hems in the skirts and bottoms of (sails) in order to strengthen them in the part attached to the bolt-rope.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/table"],
    },
  ],
  early: [
    {
      word: "early",
      phonetic: "/ˈɜː.li/",
      phonetics: [
        {
          text: "/ˈɜː.li/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/early-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9014378",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ˈɝli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/early-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=856994",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A shift (scheduled work period) that takes place early in the day.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["late"],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "At a time in advance of the usual or expected event.",
              synonyms: [],
              antonyms: [],
              example:
                "at eleven, we went for an early lunch;  she began reading at an early age;  his mother suffered an early death",
            },
            {
              definition:
                "Arriving a time before expected; sooner than on time.",
              synonyms: [],
              antonyms: [],
              example:
                "The early guests sipped their punch and avoided each other's eyes.",
            },
            {
              definition: "Near the start or beginning.",
              synonyms: [],
              antonyms: [],
              example:
                "Early results showed their winning 245 out of 300 seats in parliament. The main opponent locked up only 31 seats.",
            },
            {
              definition: "Having begun to occur; in its early stages.",
              synonyms: [],
              antonyms: [],
              example: "early cancer",
            },
            {
              definition: "Of a star or class of stars, hotter than the sun.",
              synonyms: [],
              antonyms: ["late"],
            },
          ],
          synonyms: ["first", "premature"],
          antonyms: ["late", "late", "terminal"],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "At a time before expected; sooner than usual.",
              synonyms: [],
              antonyms: [],
              example:
                "We finished the project an hour sooner than scheduled, so we left early.",
            },
            {
              definition: "Soon; in good time; seasonably.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["prematurely"],
          antonyms: ["late", "tardily"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/early"],
    },
  ],
  trees: [
    {
      word: "trees",
      phonetic: "/tɹiːz/",
      phonetics: [
        {
          text: "/tɹiːz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/trees-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1227708",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Fast growing function based on Kruskal's tree theorem.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A perennial woody plant, not exactly defined, but differentiated from a shrub by its larger size (typically over a few meters in height) or growth habit, usually having a single (or few) main axis or trunk unbranched for some distance above the ground and a head of branches and foliage.",
              synonyms: [],
              antonyms: [],
              example: "Birds have a nest in a tree in the garden.",
            },
            {
              definition:
                "Any plant that is reminiscent of the above but not classified as a tree (in any botanical sense).",
              synonyms: [],
              antonyms: [],
              example: "the banana tree",
            },
            {
              definition:
                "An object made from a tree trunk and having multiple hooks or storage platforms.",
              synonyms: [],
              antonyms: [],
              example:
                "He had the choice of buying a scratching post or a cat tree.",
            },
            {
              definition: "A device used to hold or stretch a shoe open.",
              synonyms: [],
              antonyms: [],
              example: "He put a shoe tree in each of his shoes.",
            },
            {
              definition: "The structural frame of a saddle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A connected graph with no cycles or, if the graph is finite, equivalently a connected graph with n vertices and n−1 edges.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A recursive data structure in which each node has zero or more nodes as children.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A display or listing of entries or elements such that there are primary and secondary entries shown, usually linked by drawn lines or by indenting to the right.",
              synonyms: [],
              antonyms: [],
              example: "We’ll show it as a tree list.",
            },
            {
              definition:
                "Any structure or construct having branches representing divergence or possible choices.",
              synonyms: [],
              antonyms: [],
              example: "family tree; skill tree",
            },
            {
              definition:
                "The structure or wooden frame used in the construction of a saddle used in horse riding.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(in the plural) Marijuana.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A cross or gallows.",
              synonyms: [],
              antonyms: [],
              example: "Tyburn tree",
            },
            { definition: "Wood; timber.", synonyms: [], antonyms: [] },
            {
              definition:
                "A mass of crystals, aggregated in arborescent forms, obtained by precipitation of a metal from solution.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The fifth Lenormand card.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To chase (an animal or person) up a tree.",
              synonyms: [],
              antonyms: [],
              example: "The dog treed the cat.",
            },
            {
              definition: "To place in a tree.",
              synonyms: [],
              antonyms: [],
              example:
                "Black bears can tree their cubs for protection, but grizzly bears cannot.",
            },
            {
              definition:
                "To place upon a tree; to fit with a tree; to stretch upon a tree.",
              synonyms: [],
              antonyms: [],
              example: "to tree a boot",
            },
            {
              definition: "To take refuge in a tree.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/TREE",
        "https://en.wiktionary.org/wiki/tree",
        "https://en.wiktionary.org/wiki/trees",
      ],
    },
  ],
  short: [
    {
      word: "short",
      phonetic: "/ʃoːt/",
      phonetics: [
        {
          text: "/ʃoːt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/short-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899930",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ʃoːt/", audio: "" },
        {
          text: "/ʃɔːt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/short-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=77606685",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ʃɔɹt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/short-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1157679",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A short circuit.", synonyms: [], antonyms: [] },
            { definition: "A short film.", synonyms: [], antonyms: [] },
            {
              definition: "A short version of a garment in a particular size.",
              synonyms: [],
              antonyms: [],
              example: "38 short suits fit me right off the rack.",
            },
            {
              definition: "A shortstop.",
              synonyms: [],
              antonyms: [],
              example: "Jones smashes a grounder between third and short.",
            },
            {
              definition: "A short seller.",
              synonyms: [],
              antonyms: [],
              example:
                "The market decline was terrible, but the shorts were buying champagne.",
            },
            {
              definition: "A short sale.",
              synonyms: [],
              antonyms: [],
              example:
                "He closed out his short at a modest loss after three months.",
            },
            { definition: "A summary account.", synonyms: [], antonyms: [] },
            {
              definition: "A short sound, syllable, or vowel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An integer variable having a smaller range than normal integers; usually two bytes long.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An automobile; especially in crack shorts, to break into automobiles.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cause a short circuit in (something).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of an electrical circuit, to short circuit.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To shortchange.", synonyms: [], antonyms: [] },
            {
              definition:
                "To provide with a smaller than agreed or labeled amount.",
              synonyms: [],
              antonyms: [],
              example: "This is the third time I’ve caught them shorting us.",
            },
            {
              definition:
                "To sell something, especially securities, that one does not own at the moment for delivery at a later date in hopes of profiting from a decline in the price; to sell short.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To shorten.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Having a small distance from one end or edge to another, either horizontally or vertically.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of a person) Of comparatively small height.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Having little duration.",
              synonyms: [],
              antonyms: ["long"],
              example:
                "Our meeting was a short six minutes today. Every day for the past month it’s been at least twenty minutes long.",
            },
            {
              definition:
                "(followed by for) Of a word or phrase, constituting an abbreviation (for another) or shortened form (of another).",
              synonyms: [],
              antonyms: [],
              example:
                '“Phone” is short for “telephone” and "asap" short for "as soon as possible".',
            },
            {
              definition:
                "(of a fielder or fielding position) that is relatively close to the batsman.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a ball) that bounced relatively far from the batsman.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of an approach shot or putt) that falls short of the green or the hole.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of pastries) Brittle, crumbly, especially due to the use of a large quantity of fat. (See shortbread, shortcake, shortcrust, shortening.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Abrupt; brief; pointed; petulant.",
              synonyms: [],
              antonyms: [],
              example: "He gave a short answer to the question.",
            },
            {
              definition:
                "Limited in quantity; inadequate; insufficient; scanty.",
              synonyms: [],
              antonyms: [],
              example: "a short supply of provisions",
            },
            {
              definition:
                "Insufficiently provided; inadequately supplied, especially with money; scantily furnished; lacking.",
              synonyms: [],
              antonyms: [],
              example:
                "I'd lend you the cash but I'm a little short at present.",
            },
            {
              definition:
                "Deficient; less; not coming up to a measure or standard.",
              synonyms: [],
              antonyms: [],
              example: "an account which is short of the truth",
            },
            { definition: "Undiluted; neat.", synonyms: [], antonyms: [] },
            {
              definition: "Not distant in time; near at hand.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Being in a financial investment position that is structured to be profitable if the price of the underlying security declines in the future.",
              synonyms: [],
              antonyms: [],
              example:
                "I'm short General Motors because I think their sales are plunging.",
            },
          ],
          synonyms: [
            "abbreviation",
            "short form",
            "low",
            "narrow",
            "shallow",
            "slim",
            "brief",
            "concise",
            "little",
            "petite",
            "pint-sized",
            "titchy",
          ],
          antonyms: [
            "long",
            "long",
            "broad",
            "deep",
            "high",
            "long",
            "tall",
            "wide",
            "long",
            "tall",
            "long",
          ],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Abruptly, curtly, briefly.",
              synonyms: [],
              antonyms: [],
              example: "He cut me short repeatedly in the meeting.",
            },
            {
              definition: "Unawares.",
              synonyms: [],
              antonyms: [],
              example: "The recent developments at work caught them short.",
            },
            {
              definition: "Without achieving a goal or requirement.",
              synonyms: [],
              antonyms: [],
              example: "His speech fell short of what was expected.",
            },
            {
              definition:
                "(of the manner of bounce of a cricket ball) Relatively far from the batsman and hence bouncing higher than normal; opposite of full.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "With a negative ownership position.",
              synonyms: [],
              antonyms: [],
              example: "We went short most finance companies in July.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "preposition",
          definitions: [
            {
              definition: "Deficient in.",
              synonyms: [],
              antonyms: [],
              example: "He's short common sense.",
            },
            {
              definition: "Having a negative position in.",
              synonyms: [],
              antonyms: [],
              example:
                "I don’t want to be short the market going into the weekend.",
            },
          ],
          synonyms: ["lacking", "on", "short"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/short"],
    },
  ],
  hands: [
    {
      word: "hands",
      phonetic: "/hæn(d)z/",
      phonetics: [
        {
          text: "/hæn(d)z/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hands-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1225645",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The part of the forelimb below the forearm or wrist in a human, and the corresponding part in many other animals.",
              synonyms: [],
              antonyms: [],
              example: "Her hands are really strong.",
            },
            {
              definition:
                "That which resembles, or to some extent performs the office of, a human hand.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "In linear measurement:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A side; part, camp; direction, either right or left.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Power of performance; means of execution; ability; skill; dexterity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An agent; a servant, or manual laborer, especially in compounds; a workman, trained or competent for special service or duty; a performer more or less skillful.",
              synonyms: [],
              antonyms: [],
              example:
                "an old hand at speaking;  large farms need many farm hands",
            },
            {
              definition: "An instance of helping.",
              synonyms: [],
              antonyms: [],
              example: "Bob gave Alice a hand to move the furniture.",
            },
            {
              definition: "Handwriting; style of penmanship.",
              synonyms: [],
              antonyms: [],
              example: "a good hand",
            },
            {
              definition: "A person's autograph or signature.",
              synonyms: [],
              antonyms: [],
              example:
                "Given under my Hand and Seal of the State this 1st Day of January, 2010.",
            },
            {
              definition: "Personal possession; ownership.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(usually in the plural, hands) Management, domain, control.",
              synonyms: [],
              antonyms: [],
              example:
                "in safe hands;  in good hands;  He lost his job when the factory changed hands.  With the business back in the founder's hands, there is new hope for the company.  With John in charge of the project, it's in good hands.",
            },
            {
              definition: "That which is, or may be, held in a hand at once.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Applause.",
              synonyms: [],
              antonyms: [],
              example: "Give him a hand.",
            },
            {
              definition:
                "A Native American gambling game, involving guessing the whereabouts of bits of ivory or similar, which are passed rapidly from hand to hand.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The small part of a gunstock near the lock, which is grasped by the hand in taking aim.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A whole rhizome of ginger.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The feel of a fabric; the impression or quality of the fabric as judged qualitatively by the sense of touch.",
              synonyms: [],
              antonyms: [],
              example: "This fabric has a smooth, soft hand.",
            },
            {
              definition:
                "Actual performance; deed; act; workmanship; agency; hence, manner of performance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Agency in transmission from one person to another.",
              synonyms: [],
              antonyms: [],
              example:
                "to buy at first hand (from the producer, or when new);  to buy at second hand (when no longer in the producer’s hand, or when not new);  It's not a rumor. I heard it at first hand.",
            },
            { definition: "Rate; price.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["manus", "paw"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To give, pass, or transmit with the hand, literally or figuratively.",
              synonyms: [],
              antonyms: [],
              example:
                "He handed them the letter.   She handed responsibility over to her deputy.",
            },
            {
              definition:
                "To lead, guide, or assist with the hand; to conduct.",
              synonyms: [],
              antonyms: [],
              example: "to hand a lady into a carriage",
            },
            { definition: "To manage.", synonyms: [], antonyms: [] },
            {
              definition: "To seize; to lay hands on.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To pledge by the hand; to handfast.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(said of a sail) To furl.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To cooperate.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hand",
        "https://en.wiktionary.org/wiki/hands",
      ],
    },
  ],
  state: [
    {
      word: "state",
      phonetic: "/steɪt/",
      phonetics: [
        {
          text: "/steɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/state-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9027490",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/steɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/state-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1227438",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A condition; a set of circumstances applying at any given time.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "High social standing or circumstance.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A polity.", synonyms: [], antonyms: [] },
            {
              definition:
                "(stochastic processes) An element of the range of the random variables that define a random process.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(grammar) The lexical aspect (aktionsart) of verbs or predicates that do not change over time.",
              synonyms: [],
              antonyms: ["occurrence"],
            },
          ],
          synonyms: [],
          antonyms: ["occurrence"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To declare to be a fact.",
              synonyms: [],
              antonyms: [],
              example: "He stated that he was willing to help.",
            },
            {
              definition: "To make known.",
              synonyms: [],
              antonyms: [],
              example: "State your intentions.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [{ definition: "Stately.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/state"],
    },
  ],
  black: [
    {
      word: "black",
      phonetic: "/blak/",
      phonetics: [
        {
          text: "/blak/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/black-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=877348",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The colour/color perceived in the absence of light, but also when no light is reflected, but rather absorbed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A black dye or pigment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A pen, pencil, crayon, etc., made of black pigment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(in the plural) Black cloth hung up at funerals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(sometimes capitalised) A person of African, Aborigine, or Maori descent; a dark-skinned person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Blackness, the condition of having dark skin.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The black ball.", synonyms: [], antonyms: [] },
            {
              definition: "The edge of home plate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A type of firecracker that is really more dark brown in colour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Blackcurrant syrup (in mixed drinks, e.g. snakebite and black, cider and black).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in chess and similar games) The person playing with the black set of pieces.",
              synonyms: [],
              antonyms: [],
              example: "At this point black makes a disastrous move.",
            },
            {
              definition: "Something, or a part of a thing, which is black.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A stain; a spot.", synonyms: [], antonyms: [] },
            {
              definition: "A dark smut fungus, harmful to wheat.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Marijuana.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: ["white"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make black; to blacken.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To apply blacking to (something).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To boycott, usually as part of an industrial dispute.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["blackball", "blacklist", "blacken", "darken", "swarten"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(of an object) Absorbing all light and reflecting none; dark and hueless.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of a place, etc) Without light.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(sometimes capitalized) Of or relating to any of various ethnic groups having dark pigmentation of the skin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Designated for use by those ethnic groups which have dark pigmentation of the skin.",
              synonyms: [],
              antonyms: [],
              example: "black drinking fountain; black hospital",
            },
            {
              definition:
                "(of a card) Of the spades or clubs suits. Compare red",
              synonyms: [],
              antonyms: [],
              example:
                "I was dealt two red queens, and he got one of the black queens.",
            },
            {
              definition: "Bad; evil; ill-omened.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Expressing menace, or discontent; threatening; sullen.",
              synonyms: [],
              antonyms: [],
              example: "He shot her a black look.",
            },
            {
              definition:
                "(of objects, markets, etc) Illegitimate, illegal or disgraced.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Overcrowded.", synonyms: [], antonyms: [] },
            {
              definition:
                "(of coffee or tea) Without any cream, milk or creamer.",
              synonyms: [],
              antonyms: [],
              example:
                "Jim drinks his coffee black, but Ellen prefers it with creamer.",
            },
            {
              definition:
                'Of or relating to the playing pieces of a board game deemed to belong to the "black" set (in chess the set used by the player who moves second) (often regardless of the pieces\' actual colour).',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Said of a symbol or character that is solid, filled with color. Compare white.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Related to the Christian Democratic Union of Germany.",
              synonyms: [],
              antonyms: [],
              example:
                "After the election, the parties united in a black-yellow alliance.",
            },
            {
              definition:
                "Clandestine; relating to a political, military, or espionage operation or site, the existence or details of which is withheld from the general public.",
              synonyms: [],
              antonyms: [],
              example:
                "5 percent of the Defense Department funding will go to black projects.",
            },
            {
              definition:
                "Occult; relating to something (such as mystical or magical knowledge) which is unknown to or kept secret from the general public.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Protestant, often with the implication of being militantly pro-British or anti-Catholic",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Having one or more features (hair, fur, armour, clothes, bark, etc) that is dark (or black); in taxonomy, especially: dark in comparison to another species with the same base name.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Foul; dirty.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["dark", "swart", "dark", "gloomy", "pitch-black"],
          antonyms: [
            "nonblack",
            "unblack",
            "white",
            "bright",
            "illuminated",
            "lit",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/black"],
    },
  ],
  shown: [
    {
      word: "shown",
      phonetic: "/ˈʃəʊn/",
      phonetics: [
        { text: "/ˈʃəʊn/", audio: "" },
        {
          text: "/ˈʃoʊn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shown-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1244025",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To display, to have somebody see (something).",
              synonyms: [],
              antonyms: [],
              example:
                "All he had to show for four years of attendance at college was a framed piece of paper.",
            },
            { definition: "To bestow; to confer.", synonyms: [], antonyms: [] },
            {
              definition: "To indicate (a fact) to be true; to demonstrate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To guide or escort.",
              synonyms: [],
              antonyms: [],
              example:
                "Could you please show him on his way. He has overstayed his welcome.",
            },
            {
              definition: "To be visible; to be seen; to appear.",
              synonyms: [],
              antonyms: [],
              example: "At length, his gloom showed.",
            },
            {
              definition: "To put in an appearance; show up.",
              synonyms: [],
              antonyms: [],
              example: "We waited for an hour, but they never showed.",
            },
            {
              definition:
                "To have an enlarged belly and thus be recognizable as pregnant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(racing) To finish third, especially of horses or dogs.",
              synonyms: [],
              antonyms: [],
              example:
                "In the third race: Aces Up won, paying eight dollars; Blarney Stone placed, paying three dollars; and Cinnamon showed, paying five dollars.",
            },
            {
              definition:
                "To have a certain appearance, such as well or ill, fit or unfit; to become or suit; to appear.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "display",
            "exhibit",
            "indicate",
            "point out",
            "reveal",
            "demonstrate",
            "prove",
            "arrive",
            "show up",
          ],
          antonyms: ["conceal", "cover up", "hide", "disprove", "refute"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/show",
        "https://en.wiktionary.org/wiki/shown",
      ],
    },
  ],
  stood: [
    {
      word: "stood",
      phonetic: "/stʊd/",
      phonetics: [
        {
          text: "/stʊd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stood-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1227425",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(heading) To position or be positioned physically.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(heading) To position or be positioned mentally.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(heading) To position or be positioned socially.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of a ship or its captain, to steer, sail (in a specified direction, for a specified destination etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To remain without ruin or injury.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To stop asking for more cards; to keep one's hand as it has been dealt so far.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/stand",
        "https://en.wiktionary.org/wiki/stood",
      ],
    },
  ],
  front: [
    {
      word: "front",
      phonetic: "/fɹʌnt/",
      phonetics: [
        {
          text: "/fɹʌnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/front-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75796959",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/fɹʌnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/front-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=857068",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The foremost side of something or the end that faces the direction it normally moves.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The side of a building with the main entrance.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A field of activity.", synonyms: [], antonyms: [] },
            {
              definition:
                "A person or institution acting as the public face of some other, covert group.",
              synonyms: [],
              antonyms: [],
              example:
                "Officially it's a dry-cleaning shop, but everyone knows it's a front for the mafia.",
            },
            {
              definition:
                "The interface or transition zone between two airmasses of different density, often resulting in precipitation. Since the temperature distribution is the most important regulator of atmospheric density, a front almost invariably separates airmasses of different temperature.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An area where armies are engaged in conflict, especially the line of contact.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The lateral space occupied by an element measured from the extremity of one flank to the extremity of the other flank.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The direction of the enemy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "When a combat situation does not exist or is not assumed, the direction toward which the command is faced.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A major military subdivision of the Soviet Army.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Cheek; boldness; impudence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An act, show, façade, persona: an intentional and false impression of oneself.",
              synonyms: [],
              antonyms: [],
              example:
                "He says he likes hip-hop, but I think it's just a front.",
            },
            {
              definition:
                "That which covers the foremost part of the head: a front piece of false hair worn by women.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The most conspicuous part.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The beginning.", synonyms: [], antonyms: [] },
            {
              definition: "A seafront or coastal promenade.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The forehead or brow, the part of the face above the eyes; sometimes, also, the whole face.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                '(hotels) The bellhop whose turn it is to answer a client\'s call, which is often the word "front" used as an exclamation.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in the plural) A grill (jewellery worn on front teeth).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["fore"],
          antonyms: ["back", "rear"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To face (on, to); to be pointed in a given direction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To face, be opposite to.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To face up to, to meet head-on, to confront.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To adorn the front of; to put on the front.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To pronounce with the tongue in a front position.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To move (a word or clause) to the start of a sentence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To act as a front (for); to cover (for).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To lead or be the spokesperson of (a campaign, organisation etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To provide money or financial assistance in advance to.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To assume false or disingenuous appearances.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To deceive or attempt to deceive someone with false or disingenuous appearances (on).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To appear before.",
              synonyms: [],
              antonyms: [],
              example: "to front court",
            },
          ],
          synonyms: ["feign", "put on airs"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Located at or near the front.",
              synonyms: [],
              antonyms: [],
              example:
                "The front runner was thirty meters ahead of her nearest competitor.",
            },
            {
              definition:
                "Pronounced with the highest part of the body of the tongue toward the front of the mouth, near the hard palate (most often describing a vowel).",
              synonyms: [],
              antonyms: [],
              example:
                "The English word dress has a front vowel in most dialects.",
            },
          ],
          synonyms: ["first", "fore", "lead"],
          antonyms: ["back", "last", "rear", "back"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/front"],
    },
  ],
  voice: [
    {
      word: "voice",
      phonetic: "/vɔɪs/",
      phonetics: [
        {
          text: "/vɔɪs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/voice-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1197683",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Sound uttered by the mouth, especially by human beings in speech or song; sound thus uttered considered as possessing some special quality or character",
              synonyms: [],
              antonyms: [],
              example:
                "His low voice allowed him to become a bass in the choir.",
            },
            {
              definition:
                "Sound made through vibration of the vocal cords; sonant, or intonated, utterance; tone; — distinguished from mere breath sound as heard in whispering and voiceless consonants.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The tone or sound emitted by an object",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The faculty or power of utterance",
              synonyms: [],
              antonyms: [],
              example: "to cultivate the voice",
            },
            {
              definition: "That which is communicated; message; meaning.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An expressed opinion, choice, will, desire, or wish; the right or ability to make such expression or to have it considered",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Command; precept.", synonyms: [], antonyms: [] },
            {
              definition: "One who speaks; a speaker.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A particular style or way of writing that expresses a certain tone or feeling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(grammar) A particular way of inflecting or conjugating verbs, or a particular form of a verb, by means of which is indicated the relation of the subject of the verb to the action which the verb expresses.",
              synonyms: [],
              antonyms: [],
              example:
                "The verbal system of Latin has two voices, active and passive.",
            },
            {
              definition:
                "In harmony, an independent vocal or instrumental part in a piece of composition.",
              synonyms: [],
              antonyms: [],
              example:
                "The theme of this piece constantly migrates between the three voice parts.",
            },
            {
              definition:
                "(IRC) A flag associated with a user on a channel, determining whether or not they can send messages to the channel.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "say-so",
            "steven",
            "vote",
            "reard",
            "steven",
            "diathesis",
            "gender",
            "grammatical voice",
            "verbal voice",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/voice"],
    },
    {
      word: "voice",
      phonetic: "/vɔɪs/",
      phonetics: [
        {
          text: "/vɔɪs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/voice-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1197683",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To give utterance or expression to; to utter; to publish; to announce",
              synonyms: [],
              antonyms: [],
              example: "He voiced the sentiments of the nation.",
            },
            {
              definition: "To utter audibly, with tone and not just breath.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To fit for producing the proper sounds; to regulate the tone of",
              synonyms: [],
              antonyms: [],
              example: "voice the pipes of an organ",
            },
            {
              definition: "To vote; to elect; to appoint",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To clamor; to cry out", synonyms: [], antonyms: [] },
            {
              definition:
                "(IRC) To assign the voice flag to a user on IRC, permitting them to send messages to the channel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To act as a voice actor to portray a character.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/voice"],
    },
  ],
  kinds: [
    {
      word: "kinds",
      phonetic: "/kaɪndz/",
      phonetics: [
        {
          text: "/kaɪndz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/kinds-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1676791",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A type, race or category; a group of entities that have common characteristics such that they may be grouped together.",
              synonyms: [],
              antonyms: [],
              example: "This is a strange kind of tobacco.",
            },
            {
              definition: "A makeshift or otherwise atypical specimen.",
              synonyms: [],
              antonyms: [],
              example: "The opening served as a kind of window.",
            },
            {
              definition:
                "One's inherent nature; character, natural disposition.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Family, lineage.", synonyms: [], antonyms: [] },
            { definition: "Manner.", synonyms: [], antonyms: [] },
            {
              definition:
                "Goods or services used as payment, as e.g. in barter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Equivalent means used as response to an action.",
              synonyms: [],
              antonyms: [],
              example: "I'll pay in kind for his insult.",
            },
            {
              definition:
                "Each of the two elements of the communion service, bread and wine.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "child",
            "derivative",
            "generation",
            "genre",
            "offspring",
            "sort",
            "type",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/kind",
        "https://en.wiktionary.org/wiki/kinds",
      ],
    },
  ],
  makes: [
    {
      word: "makes",
      phonetic: "/meɪks/",
      phonetics: [
        {
          text: "/meɪks/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/makes-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1224281",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Brand or kind; model.",
              synonyms: [],
              antonyms: [],
              example: "What make of car do you drive?",
            },
            {
              definition:
                "Manner or style of construction (style of how a thing is made); form.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Origin (of a manufactured article); manufacture; production.",
              synonyms: [],
              antonyms: [],
              example: "The camera was of German make.",
            },
            {
              definition: "A person's character or disposition.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The act or process of making something, especially in industrial manufacturing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Quantity produced, especially of materials.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A software utility for automatically building large applications, or an implementation of this utility.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Identification or recognition (of identity), especially from police records or evidence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                '(usually in phrase "easy make") Past, present or future target of seduction (usually female).',
              synonyms: [],
              antonyms: [],
            },
            { definition: "A promotion.", synonyms: [], antonyms: [] },
            { definition: "A home-made project", synonyms: [], antonyms: [] },
            {
              definition:
                "Turn to declare the trump for a hand (in bridge), or to shuffle the cards.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A made basket.", synonyms: [], antonyms: [] },
            {
              definition: "The closing of an electrical circuit.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "making",
            "manufacture",
            "manufacturing",
            "production",
            "brand",
            "manufacturer",
            "type",
            "actuation",
            "closing",
            "completion",
            "construction",
            "manufacture",
            "manufacture",
            "origin",
            "character",
            "disposition",
            "makeup",
            "type",
            "way",
            "output",
            "production",
            "ID",
            "identification",
            "lay",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To create.", synonyms: [], antonyms: [] },
            {
              definition: "To behave, to act.",
              synonyms: [],
              antonyms: [],
              example:
                "He made as if to punch him, but they both laughed and shook hands.",
            },
            {
              definition:
                "To tend; to contribute; to have effect; with for or against.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To constitute.",
              synonyms: [],
              antonyms: [],
              example: "One swallow does not a summer make.",
            },
            {
              definition: "To add up to, have a sum of.",
              synonyms: [],
              antonyms: [],
              example: "Two and four make six.",
            },
            {
              definition:
                "(construed with of, typically interrogative) To interpret.",
              synonyms: [],
              antonyms: [],
              example: "I don’t know what to make of it.",
            },
            {
              definition: "(usually stressed) To bring into success.",
              synonyms: [],
              antonyms: [],
              example: "She married into wealth and so has it made.",
            },
            {
              definition:
                "(ditransitive, second object is an adjective or participle) To cause to be.",
              synonyms: ["render"],
              antonyms: [],
              example: "Did I make myself heard?",
            },
            {
              definition: "To cause to appear to be; to represent as.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(ditransitive, second object is a verb) To cause (to do something); to compel (to do something).",
              synonyms: [],
              antonyms: [],
              example: "I was made to feel like a criminal.",
            },
            {
              definition:
                "(ditransitive, second object is a verb, can be stressed for emphasis or clarity) To force to do.",
              synonyms: [],
              antonyms: [],
              example: "Don’t let them make you suffer.",
            },
            {
              definition:
                "(ditransitive, of a fact) To indicate or suggest to be.",
              synonyms: [],
              antonyms: [],
              example: "His past mistakes don’t make him a bad person.",
            },
            {
              definition: "(of a bed) To cover neatly with bedclothes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(law enforcement) To recognise, identify, spot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To arrive at a destination, usually at or by a certain time.",
              synonyms: [],
              antonyms: [],
              example: "We should make Cincinnati by 7 tonight.",
            },
            {
              definition: "To proceed (in a direction).",
              synonyms: [],
              antonyms: [],
              example: "Make for the hills! It's a wildfire!",
            },
            {
              definition: "To cover (a given distance) by travelling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To move at (a speed).",
              synonyms: [],
              antonyms: [],
              example: "The ship could make 20 knots an hour in calm seas.",
            },
            { definition: "To appoint; to name.", synonyms: [], antonyms: [] },
            {
              definition:
                "To induct into the Mafia or a similar organization (as a made man).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To defecate or urinate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To earn, to gain (money, points, membership or status).",
              synonyms: [],
              antonyms: [],
              example: "He didn't make the choir after his voice changed.",
            },
            {
              definition:
                "To pay, to cover (an expense); chiefly used after expressions of inability.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To compose verses; to write poetry; to versify.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To enact; to establish.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To develop into; to prove to be.",
              synonyms: [],
              antonyms: [],
              example: "She'll make a fine president.",
            },
            {
              definition: "To form or formulate in the mind.",
              synonyms: [],
              antonyms: [],
              example: "made a questionable decision",
            },
            {
              definition: "To perform a feat.",
              synonyms: [],
              antonyms: [],
              example: "make a leap",
            },
            {
              definition:
                "To gain sufficient audience to warrant its existence.",
              synonyms: [],
              antonyms: [],
              example:
                "In the end, my class didn't make, which left me with a bit of free time.",
            },
            {
              definition:
                "To act in a certain manner; to have to do; to manage; to interfere; to be active; often in the phrase to meddle or make.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To increase; to augment; to accrue.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be engaged or concerned in.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause to be (in a specified place), used after a subjective what.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To take the virginity of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To have sexual intercourse with.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "fabricate",
            "notice",
            "twig",
            "coitize",
            "go to bed with",
            "sleep with",
            "render",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Mate; a spouse or companion; a match.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A halfpenny.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An agricultural tool resembling a scythe, used to cut (harvest) certain plants such as peas, reeds, or tares.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/make",
        "https://en.wiktionary.org/wiki/makes",
      ],
    },
  ],
  comes: [
    {
      word: "comes",
      phonetic: "/kʌmz/",
      phonetics: [
        {
          text: "/kʌmz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/comes-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1227594",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To move from further away to nearer to.",
              synonyms: [],
              antonyms: [],
              example:
                "She’ll be coming ’round the mountain when she comes [...]",
            },
            { definition: "To arrive.", synonyms: [], antonyms: [] },
            {
              definition: "To appear, to manifest itself.",
              synonyms: [],
              antonyms: [],
              example: "The pain in his leg comes and goes.",
            },
            {
              definition:
                "(with an infinitive) To begin to have an opinion or feeling.",
              synonyms: [],
              antonyms: [],
              example: "She came to think of that country as her home.",
            },
            {
              definition:
                "(with an infinitive) To do something by chance, without intending to do it.",
              synonyms: [],
              antonyms: [],
              example:
                "Could you tell me how the document came to be discovered?",
            },
            {
              definition:
                "To take a position relative to something else in a sequence.",
              synonyms: [],
              antonyms: [],
              example:
                "Which letter comes before Y?   Winter comes after autumn.",
            },
            {
              definition: "To achieve orgasm; to cum; to ejaculate.",
              synonyms: [],
              antonyms: [],
              example: "He came after a few minutes.",
            },
            {
              definition:
                "(with close) To approach a state of being or accomplishment.",
              synonyms: [],
              antonyms: [],
              example:
                "One of the screws came loose, and the skateboard fell apart.",
            },
            {
              definition:
                "(with to) To take a particular approach or point of view in regard to something.",
              synonyms: [],
              antonyms: [],
              example:
                "He came to SF literature a confirmed technophile, and nothing made him happier than to read a manuscript thick with imaginary gizmos and whatzits.",
            },
            {
              definition: "(fossil word) To become, to turn out to be.",
              synonyms: [],
              antonyms: [],
              example: "He was a dream come true.",
            },
            {
              definition: "To be supplied, or made available; to exist.",
              synonyms: [],
              antonyms: [],
              example: "A new sports car doesn't come cheap.",
            },
            {
              definition: "To carry through; to succeed in.",
              synonyms: [],
              antonyms: [],
              example: "You can't come any tricks here.",
            },
            {
              definition: "Happen.",
              synonyms: [],
              antonyms: [],
              example: "This kind of accident comes when you are careless.",
            },
            {
              definition:
                "(with from or sometimes of) To have as an origin, originate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of grain) To germinate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To pretend to be; to behave in the manner of.",
              synonyms: [],
              antonyms: [],
              example:
                "Don't come the innocent victim. We all know who's to blame here.",
            },
          ],
          synonyms: [],
          antonyms: [
            "depart",
            "exit",
            "flee",
            "go",
            "leave",
            "retreat",
            "withdraw",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/come",
        "https://en.wiktionary.org/wiki/comes",
      ],
    },
    {
      word: "comes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The answer to the theme, or dux, in a fugue.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/comes"],
    },
  ],
  close: [
    {
      word: "close",
      phonetic: "/kləʊz/",
      phonetics: [
        { text: "/kləʊz/", audio: "" },
        {
          text: "/kloʊz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/close-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=180112",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An end or conclusion.",
              synonyms: [],
              antonyms: [],
              example:
                "We owe them our thanks for bringing the project to a successful close.",
            },
            {
              definition:
                "The manner of shutting; the union of parts; junction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A grapple in wrestling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The conclusion of a strain of music; cadence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A double bar marking the end.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(travel) The time when checkin staff will no longer accept passengers for a flight.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["end", "finale"],
          antonyms: ["beginning", "start"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(physical) To remove a gap.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(social) To finish, to terminate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To come or gather around; to enclose; to encompass; to confine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To have a vector sum of 0; that is, to form a closed polygon.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "narrow",
            "shut",
            "close off",
            "close up",
            "cover",
            "shut",
            "shut off",
            "close down",
            "end",
            "finish",
            "terminate",
            "wind up",
            "close out",
            "exit",
          ],
          antonyms: [
            "extend",
            "widen",
            "open",
            "open",
            "begin",
            "commence",
            "initiate",
            "start",
            "open",
            "start",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/close"],
    },
    {
      word: "close",
      phonetic: "/kləʊs/",
      phonetics: [
        {
          text: "/kləʊs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/close-2-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9014335",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/kloʊs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/close-2-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=180111",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(chiefly Yorkshire) An enclosed field.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(chiefly British) A street that ends in a dead end.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A very narrow alley between two buildings, often overhung by one of the buildings above the ground floor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The common staircase in a tenement.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A cathedral close.", synonyms: [], antonyms: [] },
            {
              definition:
                "The interest which one may have in a piece of ground, even though it is not enclosed.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["cul-de-sac"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Closed, shut.", synonyms: [], antonyms: [] },
            {
              definition: "Narrow; confined.",
              synonyms: [],
              antonyms: [],
              example: "a close alley; close quarters",
            },
            {
              definition: "At a little distance; near.",
              synonyms: [],
              antonyms: [],
              example: "Is your house close?",
            },
            {
              definition: "Intimate; well-loved.",
              synonyms: [],
              antonyms: [],
              example: "He is a close friend.",
            },
            {
              definition:
                "Oppressive; without motion or ventilation; causing a feeling of lassitude.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Ireland, England, Scotland) Hot, humid, with no wind.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a vowel) Articulated with the tongue body relatively close to the hard palate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Strictly confined; carefully guarded.",
              synonyms: [],
              antonyms: [],
              example: "a close prisoner",
            },
            {
              definition:
                "Out of the way of observation; secluded; secret; hidden.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Nearly equal; almost evenly balanced.",
              synonyms: [],
              antonyms: [],
              example: "a close contest",
            },
            {
              definition: "Short.",
              synonyms: [],
              antonyms: [],
              example: "to cut grass or hair close",
            },
            {
              definition: "Dense; solid; compact.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Concise; to the point.",
              synonyms: [],
              antonyms: [],
              example: "close reasoning",
            },
            {
              definition: "Difficult to obtain.",
              synonyms: [],
              antonyms: [],
              example: "Money is close.",
            },
            { definition: "Parsimonious; stingy.", synonyms: [], antonyms: [] },
            {
              definition: "Adhering strictly to a standard or original; exact.",
              synonyms: [],
              antonyms: [],
              example: "a close translation",
            },
            {
              definition:
                "Accurate; careful; precise; also, attentive; undeviating; strict.",
              synonyms: [],
              antonyms: [],
              example: "The patient was kept under close observation.",
            },
            { definition: "Marked, evident.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "high",
            "close by",
            "near",
            "nearby",
            "muggy",
            "oppressive",
            "intimate",
          ],
          antonyms: [
            "open",
            "distant",
            "far",
            "far off",
            "faraway",
            "remote",
            "aloof",
            "cool",
            "distant",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/close"],
    },
  ],
  power: [
    {
      word: "power",
      phonetic: "/ˈpaʊ.ə(ɹ)/",
      phonetics: [
        { text: "/ˈpaʊ.ə(ɹ)/", audio: "" },
        {
          text: "/ˈpaʊ.ɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/power-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=830125",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Ability to do or undergo something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(social) Ability to coerce, influence or control.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(physical) Effectiveness.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A large amount or number.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of the elementary forms or parts of machines: three primary (the lever, inclined plane, and pulley) and three secondary (the wheel-and-axle, wedge, and screw).",
              synonyms: [],
              antonyms: [],
              example: "the mechanical powers",
            },
            {
              definition:
                "A measure of the effectiveness that a force producing a physical effect has over time. If linear, the quotient of: (force multiplied by the displacement of or in an object) ÷ time. If rotational, the quotient of: (force multiplied by the angle of displacement) ÷ time.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'A product of equal factors (and generalizations of this notion): x^n, read as "x to the power of n" or the like, is called a power and denotes the product x \\times x \\times \\cdots \\times x, where x appears n times in the product; x is called the base and n the exponent.',
              synonyms: [],
              antonyms: [],
            },
            { definition: "Cardinality.", synonyms: [], antonyms: [] },
            {
              definition:
                "The probability that a statistical test will reject the null hypothesis when the alternative hypothesis is true.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in plural) In Christian angelology, an intermediate level of angels, ranked above archangels, but exact position varies by classification scheme.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["impotence", "weakness"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To provide power for (a mechanical or electronic device).",
              synonyms: [],
              antonyms: [],
              example: "This CD player is powered by batteries.",
            },
            {
              definition: "To hit or kick something forcefully.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To enable or provide the impetus for.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Impressive.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/power"],
    },
  ],
  lived: [
    {
      word: "lived",
      phonetic: "/lɪvd/",
      phonetics: [
        {
          text: "/lɪvd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lived-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1227676",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To be alive; to have life.",
              synonyms: [],
              antonyms: [],
              example: "He's not expected to live for more than a few months.",
            },
            {
              definition:
                "To have permanent residence somewhere, to inhabit, to reside.",
              synonyms: [],
              antonyms: [],
              example:
                "I live at 2a Acacia Avenue.  He lives in LA, but he's staying here over the summer.",
            },
            {
              definition: "To survive; to persevere; to continue.",
              synonyms: [],
              antonyms: [],
              example: "Her memory lives in that song.",
            },
            {
              definition: "To cope.",
              synonyms: [],
              antonyms: [],
              example:
                "You'll just have to live with it!  I can't live in a world without you.",
            },
            {
              definition: "To pass life in a specified manner.",
              synonyms: [],
              antonyms: [],
              example:
                "It is difficult to live in poverty.   And they lived happily ever after.",
            },
            {
              definition:
                "To spend, as one's life; to pass; to maintain; to continue in, constantly or habitually.",
              synonyms: [],
              antonyms: [],
              example: "To live an idle or a useful life.",
            },
            {
              definition:
                "To act habitually in conformity with; to practice; to exemplify in one's way of life.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To outlast danger; to float (said of a ship, boat, etc).",
              synonyms: [],
              antonyms: [],
              example: "No ship could live in such a storm.",
            },
            {
              definition:
                '(followed by "on" or "upon") To maintain or support one\'s existence; to provide for oneself; to feed; to subsist.',
              synonyms: [],
              antonyms: [],
              example:
                "It is hard to live on the minimum wage.   They lived on stale bread.",
            },
            {
              definition:
                "To make the most of life; to experience a full, rich life.",
              synonyms: [],
              antonyms: [],
              example:
                "I'm sick of spending every day studying at home: I want to go out there and live!",
            },
          ],
          synonyms: ["dwell", "go on", "last", "remain"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(in combination) Having a specified duration of life.",
              synonyms: [],
              antonyms: [],
              example: "a short-lived recycling scheme",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/live",
        "https://en.wiktionary.org/wiki/lived",
      ],
    },
  ],
  vowel: [
    {
      word: "vowel",
      phonetic: "/vaʊl/",
      phonetics: [
        {
          text: "/vaʊl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/vowel-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=471098",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sound produced by the vocal cords with relatively little restriction of the oral cavity, forming the prominent sound of a syllable.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A letter representing the sound of vowel; in English, the vowels are a, e, i, o, u, and sometimes y.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["consonant", "consonant"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To add vowel points to a consonantal script (e.g. niqqud in Hebrew or harakat in Arabic)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["vocalize", "vowelize"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/vowel"],
    },
  ],
  taken: [
    {
      word: "taken",
      phonetic: "/ˈteɪkən/",
      phonetics: [
        {
          text: "/ˈteɪkən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/taken-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=91894759",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈteɪkən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/taken-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=631373",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To get into one's hands, possession or control, with or without force.",
              synonyms: [],
              antonyms: [],
              example: "I'll take that plate off the table.",
            },
            {
              definition:
                "To receive or accept (something) (especially something given or bestowed, awarded, etc).",
              synonyms: [],
              antonyms: [],
              example: "The camera takes 35mm film.",
            },
            {
              definition: "To remove.",
              synonyms: [],
              antonyms: [],
              example: "take two eggs from the carton",
            },
            { definition: "To have sex with.", synonyms: [], antonyms: [] },
            {
              definition: "To defeat (someone or something) in a fight.",
              synonyms: [],
              antonyms: [],
              example: "Don't try to take that guy. He's bigger than you.",
            },
            {
              definition: "To grasp or grip.",
              synonyms: [],
              antonyms: [],
              example: "He took her hand in his.",
            },
            {
              definition: "To select or choose; to pick.",
              synonyms: [],
              antonyms: [],
              example: "I'll take the blue plates.",
            },
            {
              definition: "To adopt (select) as one's own.",
              synonyms: [],
              antonyms: [],
              example: "She took his side in every argument.",
            },
            {
              definition: "To carry or lead (something or someone).",
              synonyms: [],
              antonyms: [],
              example: "I'll take the plate with me.",
            },
            {
              definition: "To use as a means of transportation.",
              synonyms: [],
              antonyms: [],
              example:
                "He took the bus to London, and then took a train to Manchester.",
            },
            {
              definition: "To visit; to include in a course of travel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To obtain for use by payment or lease.",
              synonyms: [],
              antonyms: [],
              example: "He took a full-page ad in the Times.",
            },
            { definition: "To consume.", synonyms: [], antonyms: [] },
            {
              definition: "To experience, undergo, or endure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause to change to a specified state or condition.",
              synonyms: [],
              antonyms: [],
              example: "He had to take it apart to fix it.",
            },
            {
              definition: "To regard in a specified way.",
              synonyms: [],
              antonyms: [],
              example: "He took the news badly.",
            },
            {
              definition:
                "To conclude or form (a decision or an opinion) in the mind.",
              synonyms: [],
              antonyms: [],
              example: "took a dim view of city officials",
            },
            {
              definition: "To understand (especially in a specified way).",
              synonyms: [],
              antonyms: [],
              example: "Don't take my comments as an insult.",
            },
            {
              definition:
                "To accept or be given (rightly or wrongly); assume (especially as if by right).",
              synonyms: [],
              antonyms: [],
              example:
                "He took all the credit for the project, although he had done almost none of the work.",
            },
            {
              definition: "To believe, to accept the statements of.",
              synonyms: [],
              antonyms: [],
              example: "take her word for it",
            },
            {
              definition:
                "To assume or suppose; to reckon; to regard or consider.",
              synonyms: [],
              antonyms: [],
              example: "Do you take me for a fool?",
            },
            {
              definition:
                "To draw, derive, or deduce (a meaning from something).",
              synonyms: [],
              antonyms: [],
              example: "I'm not sure what moral to take from that story.",
            },
            {
              definition: "To derive (as a title); to obtain from a source.",
              synonyms: [],
              antonyms: [],
              example:
                '"As I Lay Dying" takes its title from Book XI of Homer\'s "Odyssey"',
            },
            {
              definition: "To catch or contract (an illness, etc).",
              synonyms: [],
              antonyms: [],
              example: "took a chill",
            },
            {
              definition:
                "To come upon or catch (in a particular state or situation).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To captivate or charm; to gain or secure the interest or affection of.",
              synonyms: [],
              antonyms: [],
              example: "took her attention",
            },
            {
              definition:
                "(of a material) To absorb or be impregnated by (dye, ink, etc); to be susceptible to being treated by (polish, etc).",
              synonyms: [],
              antonyms: [],
              example: "cloth that takes dye well",
            },
            {
              definition: "(of a ship) To let in (water).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To require.",
              synonyms: [],
              antonyms: [],
              example:
                "Finishing this on schedule will take a lot of overtime.",
            },
            {
              definition: "To proceed to fill.",
              synonyms: [],
              antonyms: [],
              example: "He took a seat in the front row.",
            },
            {
              definition: "To fill, to use up (time or space).",
              synonyms: [],
              antonyms: [],
              example: "His collection takes a lot of space.",
            },
            {
              definition: "To avail oneself of.",
              synonyms: [],
              antonyms: [],
              example: "He took that opportunity to leave France.",
            },
            {
              definition: "To practice; perform; execute; carry out; do.",
              synonyms: [],
              antonyms: [],
              example: "Pirès ran in to take the kick.",
            },
            {
              definition: "To assume or perform (a form or role).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To bind oneself by.",
              synonyms: [],
              antonyms: [],
              example: "he took the oath of office last night",
            },
            {
              definition: "To move into.",
              synonyms: [],
              antonyms: [],
              example: "the next team took the field",
            },
            {
              definition: "To go into, through, or along.",
              synonyms: [],
              antonyms: [],
              example: "go down two blocks and take the next left",
            },
            {
              definition: "To have and use one's recourse to.",
              synonyms: [],
              antonyms: [],
              example: "take cover/shelter/refuge",
            },
            {
              definition:
                "To ascertain or determine by measurement, examination or inquiry.",
              synonyms: [],
              antonyms: [],
              example: "take a census",
            },
            {
              definition: "To write down; to get in, or as if in, writing.",
              synonyms: [],
              antonyms: [],
              example: "He took a mental inventory of his supplies.",
            },
            {
              definition:
                "To make (a photograph, film, or other reproduction of something).",
              synonyms: [],
              antonyms: [],
              example: "Could you take a picture of us?",
            },
            {
              definition:
                "To take a picture, photograph, etc of (a person, scene, etc).",
              synonyms: [],
              antonyms: [],
              example: "The photographer will take you sitting down.",
            },
            {
              definition: "To obtain money from, especially by swindling.",
              synonyms: [],
              antonyms: [],
              example: "took me for ten grand",
            },
            {
              definition:
                "(now chiefly by enrolling in a class or course) To apply oneself to the study of.",
              synonyms: [],
              antonyms: [],
              example: "As a child, she took ballet.",
            },
            {
              definition: "To deal with.",
              synonyms: [],
              antonyms: [],
              example: "take matters as they arise",
            },
            {
              definition:
                "To consider in a particular way, or to consider as an example.",
              synonyms: [],
              antonyms: [],
              example:
                "I've had a lot of problems recently: take last Monday, for example. My car broke down on the way to work. Then ... etc.",
            },
            {
              definition:
                "To decline to swing at (a pitched ball); to refrain from hitting at, and allow to pass.",
              synonyms: [],
              antonyms: [],
              example: "He'll probably take this one.",
            },
            {
              definition: "To accept as an input to a relation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To get or accept (something) into one's possession.",
              synonyms: [],
              antonyms: [],
              example:
                "My husband and I have a dysfunctional marriage. He just takes and takes; he never gives.",
            },
            {
              definition: "To engage, take hold or have effect.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To become; to be affected in a specified way.",
              synonyms: [],
              antonyms: [],
              example: "She took sick with the flu.",
            },
            {
              definition:
                "(possibly obsolete) To be able to be accurately or beautifully photographed.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An intensifier.", synonyms: [], antonyms: [] },
            {
              definition: "To deliver, bring, give (something) to (someone).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(obsolete outside dialectal and slang) To give or deliver (a blow, to someone); to strike or hit.",
              synonyms: [],
              antonyms: [],
              example: "He took me a blow on the head.",
            },
          ],
          synonyms: [
            "capture",
            "conquer",
            "seize",
            "ingest",
            "swallow",
            "beat",
            "confiscate",
            "seize",
            "grab",
            "grasp",
            "grip",
            "nim",
            "have",
            "sleep with",
            "do in",
            "off",
            "terminate",
            "garner",
            "get",
            "obtain",
            "win",
            "knock off",
            "subduct",
            "take away",
          ],
          antonyms: ["give", "bring", "drop"],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Infatuated; fond of or attracted to.",
              synonyms: [],
              antonyms: [],
              example: "He was very taken with the girl, I hear.",
            },
            {
              definition: "In a serious romantic relationship.",
              synonyms: [],
              antonyms: [],
              example: "I can't ask her out, she's taken.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/take",
        "https://en.wiktionary.org/wiki/taken",
      ],
    },
  ],
  built: [
    {
      word: "built",
      phonetic: "/ˈbɪlt/",
      phonetics: [
        {
          text: "/ˈbɪlt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/built-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1243861",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To form (something) by combining materials or parts.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To develop or give form to (something) according to a plan or process.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To increase or strengthen (something) by adding gradually to.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To establish a basis for (something).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To form by combining materials or parts.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To develop in magnitude or extent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To construct (software) by compiling its source code.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of source code) To be converted into software by compilation, usually with minimal human intervention.",
              synonyms: [],
              antonyms: [],
              example:
                "This code won't build any more. Have you made any changes?",
            },
          ],
          synonyms: [
            "create",
            "base",
            "found",
            "ground",
            "construct",
            "erect",
            "build up",
            "enlarge",
            "increase",
            "strengthen",
          ],
          antonyms: [
            "demolish",
            "destroy",
            "ruin",
            "wreck",
            "decrease",
            "dissipate",
            "weaken",
          ],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Shape; build; form of structure.",
              synonyms: [],
              antonyms: [],
              example: "the built of a ship",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Well-built, muscular or toned.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/build",
        "https://en.wiktionary.org/wiki/built",
      ],
    },
  ],
  heart: [
    {
      word: "heart",
      phonetic: "/hɑːt/",
      phonetics: [
        {
          text: "/hɑːt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/heart-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9021980",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        { text: "/hɑɹt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A muscular organ that pumps blood through the body, traditionally thought to be the seat of emotion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Emotions, kindness, moral effort, or spirit in general.",
              synonyms: [],
              antonyms: [],
              example: "The team lost, but they showed a lot of heart.",
            },
            {
              definition:
                "The seat of the affections or sensibilities, collectively or separately, as love, hate, joy, grief, courage, etc.; rarely, the seat of the understanding or will; usually in a good sense; personality.",
              synonyms: [],
              antonyms: [],
              example: "a good, tender, loving, bad, hard, or selfish heart",
            },
            {
              definition: "Courage; courageous purpose; spirit.",
              synonyms: ["bravery", "nerve"],
              antonyms: [],
            },
            {
              definition:
                "Vigorous and efficient activity; power of fertile production; condition of the soil, whether good or bad.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A term of affectionate or kindly and familiar address.",
              synonyms: ["honey", "sugar"],
              antonyms: [],
              example: "Listen, dear heart, we must go now.",
            },
            {
              definition: "Personality, disposition.",
              synonyms: [],
              antonyms: [],
              example: "a cold heart",
            },
            { definition: "A wight or being.", synonyms: [], antonyms: [] },
            {
              definition:
                "A conventional shape or symbol used to represent the heart, love, or emotion: ♥ or sometimes <3.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A playing card of the suit hearts featuring one or more heart-shaped symbols.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The twenty-fourth Lenormand card.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The centre, essence, or core.",
              synonyms: ["crux", "gist"],
              antonyms: [],
              example:
                "Buddhists believe that suffering is right at the heart of all life.",
            },
          ],
          synonyms: ["bravery", "nerve", "crux", "gist", "honey", "sugar"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To be fond of. Often bracketed or abbreviated with a heart symbol.",
              synonyms: ["less than three", "love"],
              antonyms: [],
              example:
                '2008 July 25, "The Media Hearts Obama?", On The Media, National Public Radio',
            },
            {
              definition: "To give heart to; to hearten; to encourage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To fill an interior with rubble, as a wall or a breakwater.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To form a dense cluster of leaves, a heart, especially of lettuce or cabbage.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["less than three", "love"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/heart"],
    },
  ],
  ready: [
    {
      word: "ready",
      phonetic: "/ˈɹɛdi/",
      phonetics: [
        {
          text: "/ˈɹɛdi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ready-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9023328",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ˈɹɛdi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ready-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=694350",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Ready money; cash", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To prepare; to make ready for action.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["yark"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Prepared for immediate action or use.",
              synonyms: [],
              antonyms: [],
              example: "The porridge is ready to serve.",
            },
            {
              definition: "Inclined; apt to happen.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Liable at any moment.",
              synonyms: [],
              antonyms: [],
              example: "The seed is ready to sprout.",
            },
            {
              definition:
                "Not slow or hesitating; quick in action or perception of any kind.",
              synonyms: ["dexterous", "easy", "expert", "prompt"],
              antonyms: [],
              example: "a ready apprehension",
            },
            {
              definition:
                "Offering itself at once; at hand; opportune; convenient.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dexterous", "easy", "expert", "prompt", "good to go"],
          antonyms: ["unready"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ready"],
    },
  ],
  quite: [
    {
      word: "quite",
      phonetic: "/kwaɪt/",
      phonetics: [
        { text: "/kwaɪt/", audio: "" },
        {
          text: "/kwaɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/quite-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=789672",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition:
                "(heading) To the greatest extent or degree; completely, entirely.",
              synonyms: [
                "absolutely",
                "fully",
                "thoroughly",
                "totally",
                "utterly",
              ],
              antonyms: [],
            },
            {
              definition:
                "(heading) In a fully justified sense; truly, perfectly, actually.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To a moderate extent or degree; somewhat, rather.",
              synonyms: [],
              antonyms: ["slightly"],
            },
          ],
          synonyms: ["absolutely", "fully", "thoroughly", "totally", "utterly"],
          antonyms: ["slightly"],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: 'Indicates agreement; "exactly so".',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/quite"],
    },
    {
      word: "quite",
      phonetic: "/ˈkiːteɪ/",
      phonetics: [
        {
          text: "/ˈkiːteɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/quite-2-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88882573",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A series of passes made with the cape to distract the bull.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/quite"],
    },
  ],
  class: [
    {
      word: "class",
      phonetic: "/klas/",
      phonetics: [
        { text: "/klas/", audio: "" },
        { text: "/klas/", audio: "" },
        { text: "/klas/", audio: "" },
        {
          text: "/klas/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/class-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9014330",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/kleəs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/class-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1230668",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A group, collection, category or set sharing characteristics or attributes.",
              synonyms: [],
              antonyms: [],
              example: "Often used to imply membership of a large class.",
            },
            {
              definition:
                "A social grouping, based on job, wealth, etc. In Britain, society is commonly split into three main classes; upper class, middle class and working class.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The division of society into classes.",
              synonyms: [],
              antonyms: [],
              example:
                "Jane Austen's works deal with class in 18th-century England.",
            },
            {
              definition: "Admirable behavior; elegance.",
              synonyms: [],
              antonyms: [],
              example:
                "Apologizing for losing your temper, even though you were badly provoked, showed real class.",
            },
            {
              definition:
                "A group of students in a regularly scheduled meeting with a teacher.",
              synonyms: [],
              antonyms: [],
              example:
                "The class was noisy, but the teacher was able to get their attention with a story.",
            },
            {
              definition: "A series of lessons covering a single subject.",
              synonyms: [],
              antonyms: [],
              example:
                "I took the cooking class for enjoyment, but I also learned a lot.",
            },
            {
              definition:
                "A group of students who commenced or completed their education during a particular year. A school class.",
              synonyms: [],
              antonyms: [],
              example: "The class of 1982 was particularly noteworthy.",
            },
            {
              definition:
                "A category of seats in an airplane, train or other means of mass transportation.",
              synonyms: [],
              antonyms: [],
              example:
                "I used to fly business class, but now my company can only afford economy.",
            },
            {
              definition:
                "A rank in the classification of organisms, below phylum and above order; a taxon of that rank.",
              synonyms: [],
              antonyms: [],
              example: "Magnolias belong to the class Magnoliopsida.",
            },
            {
              definition: "Best of its kind.",
              synonyms: [],
              antonyms: [],
              example: "It is the class of Italian bottled waters.",
            },
            {
              definition:
                "A grouping of data values in an interval, often used for computation of a frequency distribution.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A collection of sets definable by a shared property.",
              synonyms: [],
              antonyms: [],
              example:
                "Every set is a class, but classes are not generally sets. A class that is not a set is called a proper class.",
            },
            {
              definition:
                "A group of people subject to be conscripted in the same military draft, or more narrowly those persons actually conscripted in a particular draft.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A set of objects having the same behavior (but typically differing in state), or a template defining such a set.",
              synonyms: [],
              antonyms: [],
              example: "an abstract base class",
            },
            {
              definition:
                "One of the sections into which a Methodist church or congregation is divided, supervised by a class leader.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To assign to a class; to classify.",
              synonyms: [],
              antonyms: [],
              example:
                "I would class this with most of the other mediocre works of the period.",
            },
            {
              definition: "To be grouped or classed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To divide into classes, as students; to form into, or place in, a class or classes.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Great; fabulous", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/class"],
    },
  ],
  bring: [
    {
      word: "bring",
      phonetic: "/ˈbɹɪŋ/",
      phonetics: [
        {
          text: "/ˈbɹɪŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bring-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9027934",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ˈbɹɪŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bring-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1224258",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(ditransitive) To transport toward somebody/somewhere.",
              synonyms: [],
              antonyms: [],
              example: "Waiter, please bring me a single malt whiskey.",
            },
            {
              definition: "To supply or contribute.",
              synonyms: [],
              antonyms: [],
              example:
                "The new company director brought a fresh perspective on sales and marketing.",
            },
            {
              definition: "To occasion or bring about.",
              synonyms: [],
              antonyms: [],
              example:
                "The controversial TV broadcast brought a storm of complaints.",
            },
            {
              definition:
                "To raise (a lawsuit, charges, etc.) against somebody.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To persuade; to induce; to draw; to lead; to guide.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To produce in exchange; to sell for; to fetch.",
              synonyms: [],
              antonyms: [],
              example: "What does coal bring per ton?",
            },
            {
              definition:
                "To pitch, often referring to a particularly hard thrown fastball.",
              synonyms: [],
              antonyms: [],
              example: "The closer Jones can really bring it.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bring"],
    },
    {
      word: "bring",
      phonetic: "/ˈbɹɪŋ/",
      phonetics: [
        {
          text: "/ˈbɹɪŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bring-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9027934",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ˈbɹɪŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bring-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1224258",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "The sound of a telephone ringing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bring"],
    },
  ],
  round: [
    {
      word: "round",
      phonetic: "/ˈɹaʊnd/",
      phonetics: [
        { text: "/ˈɹaʊnd/", audio: "" },
        {
          text: "/ˈɹaʊnd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/round-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1157526",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition:
                "So as to form a circle or trace a circular path, or approximation thereof.",
              synonyms: [],
              antonyms: [],
              example: "High above, vultures circled around.",
            },
            {
              definition: "So as to surround or be near.",
              synonyms: [],
              antonyms: [],
              example: "Everybody please gather around.",
            },
            {
              definition: "Nearly; approximately; about.",
              synonyms: [],
              antonyms: [],
              example: "An adult elephant weighs around five tons.",
            },
            {
              definition: "From place to place.",
              synonyms: [],
              antonyms: [],
              example: "Look around and see what you find.",
            },
            {
              definition:
                "From one state or condition to an opposite or very different one; with a metaphorical change in direction; bringing about awareness or agreement.",
              synonyms: [],
              antonyms: [],
              example:
                "He used to stay up late but his new girlfriend changed that around.",
            },
            {
              definition:
                "(with turn, spin etc.) So as to partially or completely rotate; so as to face in the opposite direction.",
              synonyms: [],
              antonyms: [],
              example: "She spun around a few times.",
            },
            {
              definition:
                "Used with verbs to indicate repeated or continuous action, or in numerous locations or with numerous people.",
              synonyms: [],
              antonyms: [],
              example: "I asked around, and no-one really liked it.",
            },
            {
              definition:
                "Used with certain verbs to suggest unproductive activity.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "preposition",
          definitions: [
            {
              definition:
                "Defining a circle or closed curve containing a thing.",
              synonyms: [],
              antonyms: [],
              example: "I planted a row of lilies around the statue.",
            },
            {
              definition: "(of abstract things) Centred upon; surrounding.",
              synonyms: [],
              antonyms: [],
              example:
                "There has been a lot of controversy around the handling of personal information.",
            },
            {
              definition:
                "Following the perimeter of a specified area and returning to the starting point.",
              synonyms: [],
              antonyms: [],
              example: "She went around the track fifty times.",
            },
            {
              definition:
                "Following a path which curves near an object, with the object on the inside of the curve.",
              synonyms: [],
              antonyms: [],
              example:
                "The road took a brief detour around the large rock formation, then went straight on.",
            },
            {
              definition: "Near; in the vicinity of.",
              synonyms: [],
              antonyms: [],
              example: "I don't want you around me.",
            },
            {
              definition: "At or to various places within.",
              synonyms: [],
              antonyms: [],
              example:
                "She went around the office and got everyone to sign the card.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A circular or spherical object or part of an object.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A circular or repetitious route.",
              synonyms: [],
              antonyms: [],
              example:
                "The guards have started their rounds; the prisoner should be caught soon.",
            },
            {
              definition:
                "A general outburst from a group of people at an event.",
              synonyms: [],
              antonyms: [],
              example:
                "The candidate got a round of applause after every sentence or two.",
            },
            {
              definition:
                "A song that is sung by groups of people with each subset of people starting at a different time.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A serving of something; a portion of something to each person in a group.",
              synonyms: [],
              antonyms: [],
              example:
                "They brought us a round of drinks about every thirty minutes.",
            },
            {
              definition: "A single individual portion or dose of medicine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One sandwich (two full slices of bread with filling).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A long-bristled, circular-headed paintbrush used in oil and acrylic painting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A firearm cartridge, bullet, or any individual ammunition projectile. Originally referring to the spherical projectile ball of a smoothbore firearm. Compare round shot and solid shot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the specified pre-determined segments of the total time of a sport event, such as a boxing or wrestling match, during which contestants compete before being signaled to stop.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A stage, level, set of events in a game",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(drafting, CAD) A rounded relief or cut at an edge, especially an outside edge, added for a finished appearance and to soften sharp edges.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A strip of material with a circular face that covers an edge, gap, or crevice for decorative, sanitary, or security purposes.",
              synonyms: [],
              antonyms: [],
              example:
                "All furniture in the nursery had rounds on the edges and in the crevices.",
            },
            {
              definition: "(butchery) The hindquarters of a bovine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A rung, as of a ladder.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A crosspiece that joins and braces the legs of a chair.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A series of changes or events ending where it began; a series of like events recurring in continuance; a cycle; a periodical revolution.",
              synonyms: [],
              antonyms: [],
              example: "the round of the seasons    a round of pleasures",
            },
            {
              definition:
                "A course of action or conduct performed by a number of persons in turn, or one after another, as if seated in a circle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A series of duties or tasks which must be performed in turn, and then repeated.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A circular dance.", synonyms: [], antonyms: [] },
            {
              definition: "Rotation, as in office; succession.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A general discharge of firearms by a body of troops in which each soldier fires once.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An assembly; a group; a circle.",
              synonyms: [],
              antonyms: [],
              example: "a round of politicians",
            },
            {
              definition:
                "A brewer's vessel in which the fermentation is concluded, the yeast escaping through the bunghole.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A vessel filled, as for drinking.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A round-top.", synonyms: [], antonyms: [] },
            { definition: "A round of beef.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["rump", "canon"],
          antonyms: ["fillet"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To shape something into a curve.",
              synonyms: [],
              antonyms: [],
              example: "The carpenter rounded the edges of the table.",
            },
            {
              definition: "To become shaped into a curve.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: '(with "out") To finish; to complete; to fill out.',
              synonyms: [],
              antonyms: [],
              example:
                "She rounded out her education with only a single mathematics class.",
            },
            {
              definition:
                "To approximate a number, especially a decimal number by the closest whole number.",
              synonyms: [],
              antonyms: [],
              example: "Ninety-five rounds up to one hundred.",
            },
            {
              definition: "To turn past a boundary.",
              synonyms: [],
              antonyms: [],
              example: "Helen watched him until he rounded the corner.",
            },
            {
              definition:
                "To turn and attack someone or something (used with on).",
              synonyms: [],
              antonyms: [],
              example:
                "As a group of policemen went past him, one of them rounded on him, grabbing him by the arm.",
            },
            {
              definition: "To advance to home plate.",
              synonyms: [],
              antonyms: [],
              example:
                "And the runners round the bases on the double by Jones.",
            },
            {
              definition: "To go round, pass, go past.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To encircle; to encompass.",
              synonyms: ["surround"],
              antonyms: [],
            },
            {
              definition:
                "To grow round or full; hence, to attain to fullness, completeness, or perfection.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To do ward rounds.", synonyms: [], antonyms: [] },
            {
              definition: "To go round, as a guard; to make the rounds.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To go or turn round; to wheel about.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["surround"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "(physical) Shape.", synonyms: [], antonyms: [] },
            {
              definition: "Complete, whole, not lacking.",
              synonyms: [],
              antonyms: [],
              example: "The baker sold us a round dozen.",
            },
            {
              definition:
                "(of a number) Convenient for rounding other numbers to; for example, ending in a zero.",
              synonyms: [],
              antonyms: [],
              example: "One hundred is a nice round number.",
            },
            {
              definition: "Pronounced with the lips drawn together; rounded.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Outspoken; plain and direct; unreserved; not mincing.",
              synonyms: [],
              antonyms: [],
              example: "a round answer; a round oath",
            },
            {
              definition:
                "Finished; polished; not defective or abrupt; said of authors or their writing style.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Consistent; fair; just; applied to conduct.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Large in magnitude.",
              synonyms: [],
              antonyms: [],
              example: "a round sum",
            },
            {
              definition:
                "(authorship, of a fictional character) Well-written and well-characterized; complex and reminiscent of a real person.",
              synonyms: [],
              antonyms: ["flat"],
            },
            { definition: "Vaulted.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "circular",
            "cylindrical",
            "discoid",
            "complete",
            "entire",
            "whole",
            "rounded",
            "rounded",
            "plump",
            "rotund",
            "rounded",
            "spherical",
          ],
          antonyms: ["flat"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/around",
        "https://en.wiktionary.org/wiki/round",
      ],
    },
    {
      word: "round",
      phonetic: "/ˈɹaʊnd/",
      phonetics: [
        { text: "/ˈɹaʊnd/", audio: "" },
        {
          text: "/ˈɹaʊnd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/round-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1157526",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To speak in a low tone; whisper; speak secretly; take counsel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To address or speak to in a whisper, utter in a whisper.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/round"],
    },
    {
      word: "round",
      phonetic: "/ˈɹaʊnd/",
      phonetics: [
        { text: "/ˈɹaʊnd/", audio: "" },
        {
          text: "/ˈɹaʊnd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/round-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1157526",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A whisper; whispering.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Discourse; song.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/round"],
    },
  ],
  horse: [
    {
      word: "horse",
      phonetic: "/hɔːs/",
      phonetics: [
        { text: "/hɔːs/", audio: "" },
        {
          text: "/hɔɹs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/horse-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=589459",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of several animals related to Equus ferus caballus.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Equipment with legs.", synonyms: [], antonyms: [] },
            { definition: "Type of equipment.", synonyms: [], antonyms: [] },
            {
              definition:
                "A mass of earthy matter, or rock of the same character as the wall rock, occurring in the course of a vein, as of coal or ore; hence, to take horse (said of a vein) is to divide into branches for a distance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The sedative, antidepressant, and anxiolytic drug morphine, chiefly when used illicitly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'An informal variant of basketball in which players match shots made by their opponent(s), each miss adding a letter to the word "horse", with 5 misses spelling the whole word and eliminating a player, until only the winner is left. Also HORSE, H-O-R-S-E or H.O.R.S.E. (see Variations of basketball#H-O-R-S-E).',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(among students) A translation or other illegitimate aid in study or examination.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(among students) horseplay; tomfoolery",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "horsie",
            "nag",
            "prad",
            "steed",
            "knight",
            "pommel horse",
            "vaulting horse",
            "dobbin",
            "pony",
            "trot",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/horse"],
    },
    {
      word: "horse",
      phonetic: "/hɔːs/",
      phonetics: [
        { text: "/hɔːs/", audio: "" },
        {
          text: "/hɔɹs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/horse-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=589459",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                'To frolic, to act mischievously. (Usually followed by "around".)',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To provide with a horse; supply horses for.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To get on horseback.", synonyms: [], antonyms: [] },
            {
              definition: "To sit astride of; to bestride.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of a male horse) To copulate with (a mare).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To take or carry on the back.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To place (someone) on the back of another person, or on a wooden horse, etc., to be flogged; (hence) to flog.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To urge at work tyrannically.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To charge for work before it is finished.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/horse"],
    },
    {
      word: "horse",
      phonetic: "/hɔːs/",
      phonetics: [
        { text: "/hɔːs/", audio: "" },
        {
          text: "/hɔɹs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/horse-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=589459",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Heroin (drug).",
              synonyms: [],
              antonyms: [],
              example: "Alright, mate, got any horse?",
            },
          ],
          synonyms: ["H", "smack"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/horse"],
    },
  ],
  shows: [
    {
      word: "shows",
      phonetic: "/ʃəʊz/",
      phonetics: [
        { text: "/ʃəʊz/", audio: "" },
        {
          text: "/ʃoʊz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shows-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1669348",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A play, dance, or other entertainment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An exhibition of items.",
              synonyms: [],
              antonyms: [],
              example: "art show;  dog show",
            },
            {
              definition: "A broadcast program/programme.",
              synonyms: [],
              antonyms: [],
              example: "radio show;  television show",
            },
            {
              definition: "A movie.",
              synonyms: [],
              antonyms: [],
              example: "Let's catch a show.",
            },
            {
              definition: "An agricultural show.",
              synonyms: [],
              antonyms: [],
              example: "I'm taking the kids to the show on Tuesday.",
            },
            {
              definition: "A project or presentation.",
              synonyms: [],
              antonyms: [],
              example:
                "Let's get on with the show.   Let's get this show on the road.   They went on an international road show to sell the shares to investors.   It was Apple's usual dog and pony show.",
            },
            {
              definition: "A demonstration.",
              synonyms: [],
              antonyms: [],
              example: "show of force",
            },
            {
              definition:
                'Mere display or pomp with no substance. (Usually seen in the phrases "all show" and "for show".)',
              synonyms: [],
              antonyms: [],
              example: "The dog sounds ferocious but it's all show.",
            },
            {
              definition:
                "Outward appearance; wileful or deceptive appearance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: '(with "the") The major leagues.',
              synonyms: [],
              antonyms: [],
              example:
                "He played AA ball for years, but never made it to the show.",
            },
            {
              definition:
                "A pale blue flame at the top of a candle flame, indicating the presence of firedamp.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Pretence.", synonyms: [], antonyms: [] },
            {
              definition: "Sign, token, or indication.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Semblance; likeness; appearance.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Plausibility.", synonyms: [], antonyms: [] },
            {
              definition:
                "A discharge, from the vagina, of mucus streaked with blood, occurring a short time before labor.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "big leagues",
            "program(me)",
            "demonstration",
            "illustration",
            "proof",
            "exhibition",
            "exposition",
            "façade",
            "front",
            "superficiality",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To display, to have somebody see (something).",
              synonyms: [],
              antonyms: [],
              example:
                "All he had to show for four years of attendance at college was a framed piece of paper.",
            },
            { definition: "To bestow; to confer.", synonyms: [], antonyms: [] },
            {
              definition: "To indicate (a fact) to be true; to demonstrate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To guide or escort.",
              synonyms: [],
              antonyms: [],
              example:
                "Could you please show him on his way. He has overstayed his welcome.",
            },
            {
              definition: "To be visible; to be seen; to appear.",
              synonyms: [],
              antonyms: [],
              example: "At length, his gloom showed.",
            },
            {
              definition: "To put in an appearance; show up.",
              synonyms: [],
              antonyms: [],
              example: "We waited for an hour, but they never showed.",
            },
            {
              definition:
                "To have an enlarged belly and thus be recognizable as pregnant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(racing) To finish third, especially of horses or dogs.",
              synonyms: [],
              antonyms: [],
              example:
                "In the third race: Aces Up won, paying eight dollars; Blarney Stone placed, paying three dollars; and Cinnamon showed, paying five dollars.",
            },
            {
              definition:
                "To have a certain appearance, such as well or ill, fit or unfit; to become or suit; to appear.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "display",
            "exhibit",
            "indicate",
            "point out",
            "reveal",
            "demonstrate",
            "prove",
            "arrive",
            "show up",
          ],
          antonyms: ["conceal", "cover up", "hide", "disprove", "refute"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/show",
        "https://en.wiktionary.org/wiki/shows",
      ],
    },
  ],
  piece: [
    {
      word: "piece",
      phonetic: "/piːs/",
      phonetics: [
        {
          text: "/piːs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/piece-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=825494",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A part of a larger whole, usually in such a form that it is able to be separated from other parts.",
              synonyms: [],
              antonyms: [],
              example: "I’d like another piece of pie.",
            },
            {
              definition: "A single item belonging to a class of similar items",
              synonyms: [],
              antonyms: [],
              example: "a piece of machinery",
            },
            {
              definition:
                "One of the figures used in playing chess, specifically a higher-value figure as distinguished from a pawn; by extension, a similar counter etc. in other games.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A coin, especially one valued at less than the principal unit of currency.",
              synonyms: [],
              antonyms: [],
              example: "a sixpenny piece",
            },
            {
              definition:
                "An artistic creation, such as a painting, sculpture, musical composition, literary work, etc.",
              synonyms: [],
              antonyms: [],
              example: "She played two beautiful pieces on the piano.",
            },
            {
              definition: "An article published in the press.",
              synonyms: [],
              antonyms: [],
              example:
                "Today's paper has an interesting piece on medical research.",
            },
            { definition: "An artillery gun.", synonyms: [], antonyms: [] },
            {
              definition: "A gun.",
              synonyms: [],
              antonyms: [],
              example: "He's packin' a piece!",
            },
            {
              definition:
                "(short for hairpiece) A toupee or wig, especially when worn by a man.",
              synonyms: [],
              antonyms: [],
              example: "The announcer is wearing a new piece.",
            },
            {
              definition:
                "A slice or other quantity of bread, eaten on its own; a sandwich or light snack.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sexual encounter; from piece of ass or piece of tail",
              synonyms: [],
              antonyms: [],
              example: "I got a piece at lunchtime.",
            },
            {
              definition:
                "(mildly, short for piece of crap/piece of shit) A shoddy or worthless object (usually applied to consumer products like vehicles or appliances).",
              synonyms: [],
              antonyms: [],
              example:
                "Ugh, my new computer is such a piece. I'm taking it back to the store tomorrow.",
            },
            { definition: "A cannabis pipe.", synonyms: [], antonyms: [] },
            {
              definition:
                "Used to describe a pitch that has been hit but not well, usually either being caught by the opposing team or going foul. Usually used in the past tense with got.",
              synonyms: [],
              antonyms: [],
              example:
                "he got a piece of that one;  she got a piece of the ball [...] and it's going foul.",
            },
            {
              definition: "(sometimes derogatory) An individual; a person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A castle; a fortified building.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A pacifier; a dummy.", synonyms: [], antonyms: [] },
            {
              definition: "A distance.",
              synonyms: [],
              antonyms: [],
              example: "a fair piece off",
            },
            {
              definition:
                "A structured practice row, often used for performance evaluation.",
              synonyms: [],
              antonyms: [],
              example: "At practice we rowed four 5,000 meter pieces.",
            },
            {
              definition:
                "An amount of work to be done at one time; a unit of piece work.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(usually with together) To assemble (something real or figurative).",
              synonyms: [],
              antonyms: [],
              example:
                "These clues allowed us to piece together the solution to the mystery.",
            },
            {
              definition:
                "To make, enlarge, or repair, by the addition of a piece or pieces; to patch; often with out.",
              synonyms: [],
              antonyms: [],
              example: "to piece a garment",
            },
            {
              definition:
                "To produce a work of graffiti more complex than a tag.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/piece"],
    },
  ],
  green: [
    {
      word: "green",
      phonetic: "/ɡɹiːn/",
      phonetics: [
        {
          text: "/ɡɹiːn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/green-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899588",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ɡɹin/", audio: "" },
        {
          text: "/ɡɹiːn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/green-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9021890",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ɡɹin/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/green-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=862131",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having green as its color.",
              synonyms: [],
              antonyms: [],
              example: "The former flag of Libya is fully green.",
            },
            {
              definition: "(of people) Sickly, unwell.",
              synonyms: [],
              antonyms: [],
              example: "Sally looks pretty green — is she going to be sick?",
            },
            {
              definition:
                "Unripe, said of certain fruits that change color when they ripen.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of people) Inexperienced.",
              synonyms: [],
              antonyms: [],
              example:
                "John's kind of green, so take it easy on him this first week.",
            },
            {
              definition: "(of people) Naive or unaware of obvious facts.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of people) Overcome with envy.",
              synonyms: [],
              antonyms: [],
              example: "He was green with envy.",
            },
            {
              definition: "Environmentally friendly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Describing a pitch which, even if there is no visible grass, still contains a significant amount of moisture.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of bacon or similar smallgoods: unprocessed, raw, unsmoked; not smoked or spiced.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Not fully roasted; half raw.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of freshly cut wood or lumber that has not been dried: containing moisture and therefore relatively more flexible or springy.",
              synonyms: [],
              antonyms: [],
              example: "That timber is still too green to be used.",
            },
            {
              definition: "High or too high in acidity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Full of life and vigour; fresh and vigorous; new; recent.",
              synonyms: [],
              antonyms: [],
              example: "a green manhood;   a green wound",
            },
            {
              definition: "Having a sexual connotation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Having a color charge of green.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Being or relating to the green currencies of the European Union.",
              synonyms: [],
              antonyms: [],
              example: "the green pound; the green lira",
            },
          ],
          synonyms: [
            "verdant",
            "vert",
            "raw",
            "unprocessed",
            "unsmoked",
            "tart",
          ],
          antonyms: [
            "nongreen",
            "ungreen",
            "antigreen",
            "processed",
            "smoked",
            "spiced",
            "ripe",
            "cloy",
            "sweet",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/green"],
    },
    {
      word: "green",
      phonetic: "/ɡɹiːn/",
      phonetics: [
        {
          text: "/ɡɹiːn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/green-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899588",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ɡɹin/", audio: "" },
        {
          text: "/ɡɹiːn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/green-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9021890",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ɡɹin/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/green-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=862131",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The colour of growing foliage, as well as other plant cells containing chlorophyll; the colour between yellow and blue in the visible spectrum; one of the primary additive colour for transmitted light; the colour obtained by subtracting red and blue from white light using cyan and yellow filters.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(sometimes capitalised) A member of a green party; an environmentalist.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A putting green, the part of a golf course near the hole.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(bowls) The surface upon which bowls is played.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the colour balls used in snooker, with a value of 3 points.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A public patch of land in the middle of a settlement.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A grassy plain; a piece of ground covered with verdant herbage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(chiefly in plural) Fresh leaves or branches of trees or other plants; wreaths.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any substance or pigment of a green colour.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Marijuana.", synonyms: [], antonyms: [] },
            { definition: "Money.", synonyms: [], antonyms: [] },
            {
              definition: "One of the three color charges for quarks.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "environmentalist",
            "greenie",
            "tree hugger",
            "treehugger",
            "veg",
            "putting green",
            "bowling green",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/green"],
    },
    {
      word: "green",
      phonetic: "/ɡɹiːn/",
      phonetics: [
        {
          text: "/ɡɹiːn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/green-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899588",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ɡɹin/", audio: "" },
        {
          text: "/ɡɹiːn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/green-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9021890",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ɡɹin/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/green-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=862131",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make (something) green, to turn (something) green.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To become or grow green in colour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To add greenspaces to (a town, etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To become environmentally aware.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make (something) environmentally friendly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["engreen"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/green"],
    },
  ],
  stand: [
    {
      word: "stand",
      phonetic: "/stænd/",
      phonetics: [
        {
          text: "/stænd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stand-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75900099",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/stænd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stand-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1194267",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The act of standing.", synonyms: [], antonyms: [] },
            {
              definition: "A defensive position or effort.",
              synonyms: [],
              antonyms: [],
              example: "The Commander says we will make our stand here.",
            },
            {
              definition:
                "A resolute, unwavering position; firm opinion; action for a purpose in the face of opposition.",
              synonyms: [],
              antonyms: [],
              example: "They took a firm stand against copyright infringement.",
            },
            {
              definition:
                "A period of performance in a given location or venue.",
              synonyms: [],
              antonyms: [],
              example:
                "They have a four-game stand at home against the Yankees.  They spent the summer touring giving 4 one-night stands a week.",
            },
            {
              definition: "A device to hold something upright or aloft.",
              synonyms: [],
              antonyms: [],
              example:
                "He set the music upon the stand and began to play.  an umbrella stand;  a hat-stand",
            },
            {
              definition:
                "The platform on which a witness testifies in court; the witness stand or witness box.",
              synonyms: [],
              antonyms: [],
              example: "She took the stand and quietly answered questions.",
            },
            {
              definition:
                "A particular grove or other group of trees or shrubs.",
              synonyms: [],
              antonyms: [],
              example: "This stand of pines is older than the one next to it.",
            },
            {
              definition:
                "A contiguous group of trees sufficiently uniform in age-class distribution, composition, and structure, and growing on a site of sufficiently uniform quality, to be a distinguishable unit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A standstill, a motionless state, as of someone confused, or a hunting dog who has found game.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small building, booth, or stage, as in a bandstand or hamburger stand.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A designated spot where someone or something may stand or wait.",
              synonyms: [],
              antonyms: [],
              example: "a taxi stand",
            },
            {
              definition: "The situation of a shop, store, hotel, etc.",
              synonyms: [],
              antonyms: [],
              example: "a good, bad, or convenient stand for business",
            },
            {
              definition: "Grandstand. (often in the plural)",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A partnership.", synonyms: [], antonyms: [] },
            {
              definition: "(plural often stand) A single set, as of arms.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Rank; post; station; standing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A state of perplexity or embarrassment.",
              synonyms: [],
              antonyms: [],
              example: "to be at a stand what to do",
            },
            {
              definition:
                "A young tree, usually reserved when other trees are cut; also, a tree growing or standing upon its own root, in distinction from one produced from a scion set in a stock, either of the same or another kind of tree.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A weight of from two hundred and fifty to three hundred pounds, used in weighing pitch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A location or position where one may stand.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(heading) To position or be positioned physically.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(heading) To position or be positioned mentally.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(heading) To position or be positioned socially.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of a ship or its captain, to steer, sail (in a specified direction, for a specified destination etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To remain without ruin or injury.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To stop asking for more cards; to keep one's hand as it has been dealt so far.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stand"],
    },
  ],
  birds: [
    {
      word: "birds",
      phonetic: "/bɜːdz/",
      phonetics: [
        { text: "/bɜːdz/", audio: "" },
        {
          text: "/bɜɪdz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/birds-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1446806",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A member of the class of animals Aves in the phylum Chordata, characterized by being warm-blooded, having feathers and wings usually capable of flight, and laying eggs.",
              synonyms: [],
              antonyms: [],
              example: "Ducks and sparrows are birds.",
            },
            { definition: "A man, fellow.", synonyms: [], antonyms: [] },
            {
              definition:
                "A girl or woman, especially one considered sexually attractive.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Girlfriend.",
              synonyms: [],
              antonyms: [],
              example: "Mike went out with his bird last night.",
            },
            { definition: "An airplane.", synonyms: [], antonyms: [] },
            { definition: "A satellite.", synonyms: [], antonyms: [] },
            {
              definition:
                "A chicken; the young of a fowl; a young eaglet; a nestling.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "bloke",
            "chap",
            "guy",
            "avian",
            "fowl",
            "broad",
            "chick",
            "dame",
            "girl",
            "lass",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A prison sentence.",
              synonyms: [],
              antonyms: [],
              example: "He’s doing bird.",
            },
            { definition: "A yardbird", synonyms: [], antonyms: [] },
          ],
          synonyms: ["porridge", "stretch", "time"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bird",
        "https://en.wiktionary.org/wiki/birds",
      ],
    },
  ],
  start: [
    {
      word: "start",
      phonetic: "/stɑːt/",
      phonetics: [
        {
          text: "/stɑːt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/start-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9028735",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/stɑɹt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/start-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1158031",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The beginning of an activity.",
              synonyms: [],
              antonyms: [],
              example: "The movie was entertaining from start to finish.",
            },
            {
              definition: "A sudden involuntary movement.",
              synonyms: [],
              antonyms: [],
              example: "He woke with a start.",
            },
            {
              definition: "The beginning point of a race, a board game, etc.",
              synonyms: [],
              antonyms: [],
              example:
                "Captured pieces are returned to the start of the board.",
            },
            {
              definition:
                "An appearance in a sports game, horserace, etc., from the beginning of the event.",
              synonyms: [],
              antonyms: [],
              example:
                "Jones has been a substitute before, but made his first start for the team last Sunday.",
            },
            {
              definition:
                "A young plant germinated in a pot to be transplanted later.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An initial advantage over somebody else; a head start.",
              synonyms: [],
              antonyms: [],
              example: "to get, or have, the start",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/start"],
    },
    {
      word: "start",
      phonetic: "/stɑːt/",
      phonetics: [
        {
          text: "/stɑːt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/start-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9028735",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/stɑɹt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/start-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1158031",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To begin, commence, initiate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To begin an activity.",
              synonyms: [],
              antonyms: [],
              example: "The rain started at 9:00.",
            },
            {
              definition: "To have its origin (at), begin.",
              synonyms: [],
              antonyms: [],
              example: "The blue line starts one foot away from the wall.",
            },
            {
              definition:
                "To startle or be startled; to move or be moved suddenly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To break away, to come loose.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To put into play.", synonyms: [], antonyms: [] },
            {
              definition:
                "To pour out; to empty; to tap and begin drawing from.",
              synonyms: [],
              antonyms: [],
              example: "to start a water cask",
            },
            {
              definition: "To start one's periods (menstruation).",
              synonyms: [],
              antonyms: [],
              example: "Have you started yet?",
            },
          ],
          synonyms: [],
          antonyms: ["end", "stop"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/start"],
    },
    {
      word: "start",
      phonetic: "/stɑːt/",
      phonetics: [
        {
          text: "/stɑːt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/start-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9028735",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/stɑɹt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/start-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1158031",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A tail, or anything projecting like a tail.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A handle, especially that of a plough.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The curved or inclined front and bottom of a water wheel bucket.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The arm, or level, of a gin, drawn around by a horse.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/start"],
    },
  ],
  river: [
    {
      word: "river",
      phonetic: "/ˈɹɪvə/",
      phonetics: [
        {
          text: "/ˈɹɪvə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/river-1-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9023339",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ˈɹɪvɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/river-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1157512",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A large and often winding stream which drains a land mass, carrying water down from higher areas to a lower point, oftentimes ending in another body of water, such as an ocean or in an inland sea.",
              synonyms: [],
              antonyms: [],
              example:
                "Occasionally rivers overflow their banks and cause floods.",
            },
            {
              definition: "Any large flow of a liquid in a single body.",
              synonyms: [],
              antonyms: [],
              example: "a river of blood",
            },
            {
              definition: "The last card dealt in a hand.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A visually undesirable effect of white space running down a page, caused by spaces between words on consecutive lines happening to coincide.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To improve one’s hand to beat another player on the final card in a poker game.",
              synonyms: [],
              antonyms: [],
              example: "Johnny rivered me by drawing that ace of spades.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/river"],
    },
    {
      word: "river",
      phonetic: "/ˈɹaɪvə/",
      phonetics: [
        { text: "/ˈɹaɪvə/", audio: "" },
        { text: "/ˈɹaɪvɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One who rives or splits.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/river"],
    },
  ],
  tried: [
    {
      word: "tried",
      phonetic: "/tɹaɪd/",
      phonetics: [
        {
          text: "/tɹaɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tried-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1227663",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Tested, hence, proven to be firm or reliable.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Put on trial, taken before a lawcourt.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To attempt; to endeavour. Followed by infinitive.",
              synonyms: [],
              antonyms: [],
              example: "I tried to rollerblade, but I couldn’t.",
            },
            {
              definition: "To divide; to separate.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To test, to work out.", synonyms: [], antonyms: [] },
            {
              definition: "To experiment, to strive.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To lie to in heavy weather under just sufficient sail to head into the wind.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To strain; to subject to excessive tests.",
              synonyms: [],
              antonyms: [],
              example: "Repeated failures try one's patience.",
            },
            {
              definition: "(used with another verb) To want",
              synonyms: [],
              antonyms: [],
              example:
                "I am really not trying to hear you talk about my mama like that.",
            },
          ],
          synonyms: [
            "attempt",
            "endeavor",
            "fand",
            "mint",
            "take a run at",
            "take a stab at",
            "sample",
            "taste",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tried",
        "https://en.wiktionary.org/wiki/try",
      ],
    },
  ],
  least: [
    {
      word: "least",
      phonetic: "/list/",
      phonetics: [
        {
          text: "/list/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/least-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1224272",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Something of the smallest possible extent; an indivisible unit.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition:
                "Used for forming superlatives of adjectives, especially those that do not form the superlative by adding -est.",
              synonyms: [],
              antonyms: [],
              example: "It was the least surprising thing.",
            },
            {
              definition:
                "In the smallest or lowest degree; in a degree below all others.",
              synonyms: [],
              antonyms: [],
              example: "They travel the least of all.",
            },
          ],
          synonyms: [],
          antonyms: ["most"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/least"],
    },
  ],
  field: [
    {
      word: "field",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/field-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899660",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/fiːld/", audio: "" },
        {
          text: "/fild/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/field-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=857034",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A land area free of woodland, cities, and towns; open country.",
              synonyms: [],
              antonyms: [],
              example:
                "There are several species of wild flowers growing in this field.",
            },
            {
              definition:
                "A wide, open space that is usually used to grow crops or to hold farm animals.",
              synonyms: [],
              antonyms: [],
              example: "A crop circle was made in a corn field.",
            },
            {
              definition: "A place where competitive matches are carried out.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various figurative meanings, regularly dead metaphors.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "course",
            "court",
            "ground",
            "pitch",
            "area",
            "domain",
            "realm",
            "sphere",
            "box",
            "input field",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To intercept or catch (a ball) and play it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(and other batting sports) To be the team catching and throwing the ball, as opposed to hitting it.",
              synonyms: [],
              antonyms: [],
              example:
                "The blue team are fielding first, while the reds are batting.",
            },
            {
              definition: "To place (a team, its players, etc.) in a game.",
              synonyms: [],
              antonyms: [],
              example:
                "The away team fielded two new players and the second-choice goalkeeper.",
            },
            {
              definition: "To answer; to address.",
              synonyms: [],
              antonyms: [],
              example:
                "She will field questions immediately after her presentation.",
            },
            {
              definition: "To defeat.",
              synonyms: [],
              antonyms: [],
              example: "They fielded a fearsome army.",
            },
            {
              definition: "To execute research (in the field).",
              synonyms: [],
              antonyms: [],
              example:
                "He fielded the marketing survey about the upcoming product.",
            },
            {
              definition: "To deploy in the field.",
              synonyms: [],
              antonyms: [],
              example: "to field a new land-mine detector",
            },
          ],
          synonyms: ["address", "answer", "deal with", "respond"],
          antonyms: ["bat"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/field"],
    },
  ],
  whose: [
    {
      word: "whose",
      phonetic: "/huːz/",
      phonetics: [
        { text: "/huːz/", audio: "" },
        {
          text: "/huːz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/whose-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1227396",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "pronoun",
          definitions: [
            {
              definition:
                "(interrogative) That or those of whom or belonging to whom.",
              synonyms: [],
              antonyms: [],
              example:
                "Several people have lost their suitcases. Whose have you found?",
            },
            {
              definition:
                "(relative) That or those of whom or belonging to whom.",
              synonyms: [],
              antonyms: [],
              example:
                "This car is blocking the way, but Mr Smith, whose it is, will be here shortly.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/whose"],
    },
  ],
  girls: [
    {
      word: "girls",
      phonetic: "/ɡɜːlz/",
      phonetics: [
        { text: "/ɡɜːlz/", audio: "" },
        {
          text: "/ɡɝlz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/girls-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1230325",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A female child, adolescent, or a young woman.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A young female animal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(sometimes offensive) A woman, especially a young woman",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A female servant; a maid. (see usage notes)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A queen (the playing card.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A term of endearment. (see usage notes)",
              synonyms: [],
              antonyms: [],
            },
            { definition: "One's girlfriend.", synonyms: [], antonyms: [] },
            {
              definition: "One's daughter.",
              synonyms: [],
              antonyms: [],
              example: "Your girl turned up on our doorstep.",
            },
            {
              definition: "A roebuck two years old.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Cocaine, especially in powder form.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(somewhat childish) A female (tree, gene, etc).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A boy or man who is weak or sentimental.",
              synonyms: ["Jessie", "sissy", "wimp"],
              antonyms: [],
              example: "Don't be such a girl!",
            },
          ],
          synonyms: [
            "daughter",
            "girlie",
            "lass",
            "lassie",
            "char",
            "charlady",
            "charwoman",
            "maid",
            "maiden",
            "maidservant",
            "womanservant",
            "Jessie",
            "sissy",
            "wimp",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To feminize or girlify; to gender as a girl or as for girls.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(somewhat informal) To staff with or as a girl or girls.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A woman's breasts.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/girl",
        "https://en.wiktionary.org/wiki/girls",
      ],
    },
  ],
  leave: [
    {
      word: "leave",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/leave-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899812",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/liːv/", audio: "" },
        {
          text: "/liv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/leave-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=454030",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To have a consequence or remnant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To depart; to separate from.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To transfer something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To remain (behind); to stay.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'To stop, desist from; to "leave off" (+ noun / gerund).',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["depart", "forget", "leave behind"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/leave"],
    },
    {
      word: "leave",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/leave-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899812",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/liːv/", audio: "" },
        {
          text: "/liv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/leave-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=454030",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The action of the batsman not attempting to play at the ball.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The arrangement of balls in play that remains after a shot is made (which determines whether the next shooter — who may be either the same player, or an opponent — has good options, or only poor ones).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/leave"],
    },
    {
      word: "leave",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/leave-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899812",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/liːv/", audio: "" },
        {
          text: "/liv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/leave-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=454030",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Permission to be absent; time away from one's work.",
              synonyms: [],
              antonyms: [],
              example: "I've been given three weeks' leave by my boss.",
            },
            {
              definition: "Permission.",
              synonyms: [],
              antonyms: [],
              example: "Might I beg leave to accompany you?",
            },
            {
              definition: "Farewell, departure.",
              synonyms: [],
              antonyms: [],
              example:
                "I took my leave of the gentleman without a backward glance.",
            },
          ],
          synonyms: ["authorisation", "consent", "annual leave", "holiday"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/leave"],
    },
    {
      word: "leave",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/leave-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899812",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/liːv/", audio: "" },
        {
          text: "/liv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/leave-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=454030",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To give leave to; allow; permit; let; grant.",
              synonyms: [],
              antonyms: [],
              example:
                "We were not left go to the beach after school except on a weekend.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/leave"],
    },
    {
      word: "leave",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/leave-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899812",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/liːv/", audio: "" },
        {
          text: "/liv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/leave-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=454030",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To produce leaves or foliage.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["leaf"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/leave"],
    },
    {
      word: "leave",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/leave-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899812",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/liːv/", audio: "" },
        {
          text: "/liv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/leave-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=454030",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To raise; to levy.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/leave"],
    },
  ],
  added: [
    {
      word: "added",
      phonetic: "[ˈad.əd]",
      phonetics: [
        { text: "[ˈad.əd]", audio: "" },
        {
          text: "[ˈæɾ.əd]",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/added-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1227563",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To join or unite (e.g. one thing to another, or as several particulars) so as to increase the number, augment the quantity or enlarge the magnitude, or so as to form into one aggregate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To sum up; to put together mentally.",
              synonyms: [],
              antonyms: [],
              example: "to add numbers",
            },
            {
              definition:
                "To combine elements of (something) into one quantity.",
              synonyms: [],
              antonyms: [],
              example: "to add a column of numbers",
            },
            {
              definition:
                "To give by way of increased possession (to someone); to bestow (on).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To append (e,g, a statement); to say further information.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make an addition; to augment; to increase.",
              synonyms: [],
              antonyms: [],
              example: "It adds to our anxiety.",
            },
            {
              definition: "To perform the arithmetical operation of addition.",
              synonyms: [],
              antonyms: [],
              example: "He adds rapidly.",
            },
            {
              definition: "To summon minions or reinforcements.",
              synonyms: [],
              antonyms: [],
              example:
                "Typically, a hostile mob will add whenever it's within the aggro radius of a player.",
            },
          ],
          synonyms: [
            "coalesce",
            "join",
            "unite",
            "bestow",
            "give",
            "annex",
            "augment",
            "increase",
            "add up",
            "sum",
            "mention",
            "note",
          ],
          antonyms: ["remove", "subtract"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/add",
        "https://en.wiktionary.org/wiki/added",
      ],
    },
  ],
  color: [
    {
      word: "color",
      phonetic: "/ˈkʌl.ə(ɹ)/",
      phonetics: [
        { text: "/ˈkʌl.ə(ɹ)/", audio: "" },
        {
          text: "/ˈkʌl.ɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/color-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=631177",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The spectral composition of visible light",
              synonyms: ["blee"],
              antonyms: [],
              example: "Humans and birds can perceive color.",
            },
            { definition: "A subset thereof:", synonyms: [], antonyms: [] },
            {
              definition: "A paint.",
              synonyms: [],
              antonyms: [],
              example:
                "The artist took out her colors and began work on a landscape.",
            },
            {
              definition:
                "Human skin tone, especially as an indicator of race or ethnicity.",
              synonyms: ["complexion", "ethnicity", "race"],
              antonyms: [],
              example: "Color has been a sensitive issue in many societies.",
            },
            {
              definition:
                "Skin color, noted as normal, jaundiced, cyanotic, flush, mottled, pale, or ashen as part of the skin signs assessment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A flushed appearance of blood in the face; redness of complexion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Richness of expression; detail or flavour that is likely to generate interest or enjoyment.",
              synonyms: [],
              antonyms: [],
              example:
                "Could you give me some color with regards to which products made up the mix of revenue for this quarter?",
            },
            {
              definition: "A standard, flag, or insignia:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in the plural) An award for sporting achievement, particularly within a school or university.",
              synonyms: [],
              antonyms: [],
              example: "He was awarded colors for his football.",
            },
            {
              definition:
                "(in the plural) The morning ceremony of raising the flag.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A property of quarks, with three values called red, green, and blue, which they can exchange by passing gluons.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A third-order measure of derivative price sensitivity, expressed as the rate of change of gamma with respect to time, or equivalently the rate of change of charm with respect to changes in the underlying asset price.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The relative lightness or darkness of a mass of written or printed text on a page. (See type color.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any of the colored balls excluding the reds.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A front or facade; an ostensible truth actually false; pretext.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An appearance of right or authority; color of law.",
              synonyms: [],
              antonyms: [],
              example:
                "Under color of law, he managed to bilk taxpayers of millions of dollars.",
            },
          ],
          synonyms: [
            "DgammaDtime",
            "colour",
            "gamma decay",
            "blee",
            "complexion",
            "ethnicity",
            "race",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To give something color.",
              synonyms: ["dye", "paint", "shade", "stain", "tinge", "tint"],
              antonyms: [],
              example: "We could color the walls red.",
            },
            {
              definition:
                "To apply colors to the areas within the boundaries of a line drawing using colored markers or crayons.",
              synonyms: ["color in"],
              antonyms: [],
              example: "My kindergartener loves to color.",
            },
            {
              definition:
                "(of a person or their face) To become red through increased blood flow.",
              synonyms: ["blush"],
              antonyms: [],
              example: "Her face colored as she realized her mistake.",
            },
            {
              definition: "To affect without completely changing.",
              synonyms: ["affect", "influence"],
              antonyms: [],
              example:
                "That interpretation certainly colors my perception of the book.",
            },
            {
              definition: "To attribute a quality to; to portray (as).",
              synonyms: ["call"],
              antonyms: [],
              example: "Color me confused.",
            },
            {
              definition:
                "To assign colors to the vertices of a graph (or the regions of a map) so that no two vertices connected by an edge (regions sharing a border) have the same color.",
              synonyms: [],
              antonyms: [],
              example: "Can this graph be 2-colored?",
            },
          ],
          synonyms: [
            "affect",
            "influence",
            "blush",
            "call",
            "color in",
            "dye",
            "paint",
            "shade",
            "stain",
            "tinge",
            "tint",
          ],
          antonyms: ["decolor"],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Conveying color, as opposed to shades of gray.",
              synonyms: [],
              antonyms: [],
              example:
                "Color television and movies were considered a great improvement over black and white.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/color"],
    },
  ],
  third: [
    {
      word: "third",
      phonetic: "/θɜːd/",
      phonetics: [
        { text: "/θɜːd/", audio: "" },
        {
          text: "/tɜjd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/third-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1239698",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The person or thing in the third position.",
              synonyms: [],
              antonyms: [],
              example: "Jones came in third.",
            },
            {
              definition: "One of three equal parts of a whole.",
              synonyms: [],
              antonyms: [],
              example: "He ate a third of the pie. Divided by two-thirds.",
            },
            {
              definition: "The third gear of a gearbox.",
              synonyms: [],
              antonyms: [],
              example: "Now put it into third.",
            },
            {
              definition:
                "An interval consisting of the first and third notes in a scale.",
              synonyms: [],
              antonyms: [],
              example: "They sing in thirds.",
            },
            {
              definition: "Third base",
              synonyms: [],
              antonyms: [],
              example: "The play ended with Jones standing on third.",
            },
            {
              definition: "A handicap of one stroke every third hole.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A third-class degree, awarded to the lowest achievers in an honours degree programme",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One sixtieth of a second, i.e., the third in a series of fractional parts in a sexagesimal number system. Also formerly known as a tierce.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["⅓", "third gear"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To agree with a proposition or statement after it has already been seconded.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To divide into three equal parts.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "The ordinal form of the cardinal number three; Coming after the second.",
              synonyms: [],
              antonyms: [],
              example: "The third tree from the left is my favorite.",
            },
          ],
          synonyms: ["3d", "3rd", "III", "IIIrd"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/third"],
    },
  ],
  hours: [
    {
      word: "hours",
      phonetic: "/ˈaʊɚz/",
      phonetics: [
        { text: "/ˈaʊɚz/", audio: "" },
        { text: "/ˈaʊə(ɹ)z/", audio: "" },
        {
          text: "/ˈaʊɚz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hours-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1227556",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A time period of sixty minutes; one twenty-fourth of a day.",
              synonyms: [],
              antonyms: [],
              example: "I spent an hour at lunch.",
            },
            {
              definition: "A season, moment, or time.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The time.",
              synonyms: [],
              antonyms: [],
              example: "The hour grows late and I must go home.",
            },
            {
              definition:
                "(in the plural) Used after a two-digit hour and a two-digit minute to indicate time.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in the plural) The set times of prayer, the canonical hours, the offices or services prescribed for these, or a book containing them.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A distance that can be traveled in one hour.",
              synonyms: [],
              antonyms: [],
              example: "This place is an hour away from where I live.",
            },
          ],
          synonyms: ["stound", "h", "hr", "h", "hrs"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hour",
        "https://en.wiktionary.org/wiki/hours",
      ],
    },
  ],
  moved: [
    {
      word: "moved",
      phonetic: "/muːvd/",
      phonetics: [
        {
          text: "/muːvd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/moved-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1235993",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To change place or posture; to go, in any manner, from one place or position to another.",
              synonyms: ["stir"],
              antonyms: [],
              example: "A ship moves rapidly.",
            },
            {
              definition: "To act; to take action; to begin to act",
              synonyms: ["get moving", "stir"],
              antonyms: [],
              example: "Come on guys, let's move: there's work to do!",
            },
            {
              definition:
                "To change residence, for example from one house, town, or state, to another; to go and live at another place. See also move out and move in.",
              synonyms: [],
              antonyms: [],
              example:
                "I decided to move to the country for a more peaceful life.",
            },
            {
              definition:
                "(and other games) To change the place of a piece in accordance with the rules of the game.",
              synonyms: [],
              antonyms: [],
              example:
                "My opponent's counter was moving much quicker round the board than mine.",
            },
            {
              definition:
                "To cause to change place or posture in any manner; to set in motion; to carry, convey, draw, or push from one place to another",
              synonyms: ["impel", "stir"],
              antonyms: [],
              example: "The horse moves a carriage.",
            },
            {
              definition:
                "To transfer (a piece or man) from one space or position to another, according to the rules of the game",
              synonyms: [],
              antonyms: [],
              example: "She moved the queen closer to the centre of the board.",
            },
            {
              definition:
                "To excite to action by the presentation of motives; to rouse by representation, persuasion, or appeal; to influence.",
              synonyms: [],
              antonyms: [],
              example: "This song moves me to dance.",
            },
            {
              definition:
                "To arouse the feelings or passions of; especially, to excite to tenderness or compassion, to excite (for example, an emotion).",
              synonyms: ["affect", "trouble"],
              antonyms: [],
              example: "That book really moved me.",
            },
            {
              definition:
                "To propose; to recommend; specifically, to propose formally for consideration and determination, in a deliberative assembly; to submit",
              synonyms: [],
              antonyms: [],
              example:
                "I move to repeal the rule regarding obligatory school uniform.",
            },
            {
              definition:
                "To mention; to raise (a question); to suggest (a course of action); to lodge (a complaint).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To incite, urge (someone to do something); to solicit (someone for or of an issue); to make a proposal to.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To apply to, as for aid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To request an action from the court.",
              synonyms: [],
              antonyms: [],
              example:
                "An attorney moved the court to issue a restraining order.",
            },
            {
              definition: "To bow or salute upon meeting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To sell, to market (especially, but not exclusively, illegal products)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "actuate",
            "affect",
            "trouble",
            "agitate",
            "get moving",
            "stir",
            "impel",
            "impel",
            "stir",
            "incite",
            "incline",
            "induce",
            "influence",
            "instigate",
            "offer",
            "persuade",
            "prompt",
            "propose",
            "rouse",
            "stir",
            "stir",
            "transfer",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Emotionally affected; touched.",
              synonyms: [],
              antonyms: [],
              example:
                "What happened to that girl in the film was so awful - I was extremely moved.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/move",
        "https://en.wiktionary.org/wiki/moved",
      ],
    },
  ],
  plant: [
    {
      word: "plant",
      phonetic: "/plænt/",
      phonetics: [
        { text: "/plænt/", audio: "" },
        { text: "/plænt/", audio: "" },
        { text: "/plænt/", audio: "" },
        { text: "/plɑːnt/", audio: "" },
        {
          text: "/plɑːnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/plant-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80066664",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/plænt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/plant-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1118854",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An organism that is not an animal, especially an organism capable of photosynthesis. Typically a small or herbaceous organism of this kind, rather than a tree.",
              synonyms: [],
              antonyms: [],
              example:
                "The garden had a couple of trees, and a cluster of colourful plants around the border.",
            },
            {
              definition:
                "An organism of the kingdom Plantae; now specifically, a living organism of the Embryophyta (land plants) or of the Chlorophyta (green algae), a eukaryote that includes double-membraned chloroplasts in its cells containing chlorophyll a and b, or any organism closely related to such an organism.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Now specifically, a multicellular eukaryote that includes chloroplasts in its cells, which have a cell wall.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any creature that grows on soil or similar surfaces, including plants and fungi.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A factory or other industrial or institutional building or facility.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An object placed surreptitiously in order to cause suspicion to fall upon a person.",
              synonyms: [],
              antonyms: [],
              example:
                "That gun's not mine! It's a plant! I've never seen it before!",
            },
            {
              definition:
                "Anyone assigned to behave as a member of the public during a covert operation (as in a police investigation).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person, placed amongst an audience, whose role is to cause confusion, laughter etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A play in which the cue ball knocks one (usually red) ball onto another, in order to pot the second; a set.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Machinery, such as the kind used in earthmoving or construction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A young tree; a sapling; hence, a stick or staff.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The sole of the foot.", synonyms: [], antonyms: [] },
            {
              definition: "A plan; a swindle; a trick.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An oyster which has been bedded, in distinction from one of natural growth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A young oyster suitable for transplanting.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To place (a seed or plant) in soil or other substrate in order that it may live and grow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To place (an object, or sometimes a person), often with the implication of intending deceit.",
              synonyms: [],
              antonyms: [],
              example:
                "That gun's not mine! It was planted there by the real murderer!",
            },
            {
              definition:
                "To place or set something firmly or with conviction.",
              synonyms: [],
              antonyms: [],
              example: "Plant your feet firmly and give the rope a good tug.",
            },
            {
              definition: "To place in the ground.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To furnish or supply with plants.",
              synonyms: [],
              antonyms: [],
              example: "to plant a garden, an orchard, or a forest",
            },
            {
              definition: "To engender; to generate; to set the germ of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To furnish with a fixed and organized population; to settle; to establish.",
              synonyms: [],
              antonyms: [],
              example: "to plant a colony",
            },
            {
              definition:
                "To introduce and establish the principles or seeds of.",
              synonyms: [],
              antonyms: [],
              example: "to plant Christianity among the heathen",
            },
            {
              definition: "To set up; to install; to instate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/plant"],
    },
  ],
  doing: [
    {
      word: "doing",
      phonetic: "/ˈduːɪŋ/",
      phonetics: [
        {
          text: "/ˈduːɪŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/doing-1-ca.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=585351",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(auxiliary) A syntactic marker.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To perform; to execute.",
              synonyms: ["accomplish", "carry out", "functionate"],
              antonyms: [],
              example:
                "All you ever do is surf the Internet. What will you do this afternoon?",
            },
            {
              definition: "To cause, make (someone) (do something).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To suffice.",
              synonyms: [],
              antonyms: [],
              example:
                "it’s not the best broom, but it will have to do;  this will do me, thanks.",
            },
            {
              definition: "To be reasonable or acceptable.",
              synonyms: [],
              antonyms: [],
              example:
                "It simply will not do to have dozens of children running around such a quiet event.",
            },
            {
              definition: "(ditransitive) To have (as an effect).",
              synonyms: [],
              antonyms: [],
              example: "The fresh air did him some good.",
            },
            {
              definition: "To fare, perform (well or poorly).",
              synonyms: [],
              antonyms: [],
              example:
                "Our relationship isn't doing very well;  how do you do?",
            },
            {
              definition: "(chiefly in questions) To have as one's job.",
              synonyms: [],
              antonyms: [],
              example: "What does Bob do? — He's a plumber.",
            },
            {
              definition:
                "To perform the tasks or actions associated with (something).",
              synonyms: [],
              antonyms: [],
              example:
                "\"Don't forget to do your report\" means something quite different depending on whether you're a student or a programmer.",
            },
            {
              definition: "To cook.",
              synonyms: [],
              antonyms: [],
              example: "I'll just do some eggs.",
            },
            {
              definition: "To travel in, to tour, to make a circuit of.",
              synonyms: [],
              antonyms: [],
              example: "Let’s do New York also.",
            },
            {
              definition: "To treat in a certain way.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To work for or on, by way of caring for, looking after, preparing, cleaning, keeping in order, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To act or behave in a certain manner; to conduct oneself.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To spend (time) in jail. (See also do time)",
              synonyms: ["serve"],
              antonyms: [],
              example: "I did five years for armed robbery.",
            },
            {
              definition: "To impersonate or depict.",
              synonyms: ["imitate", "personate", "take off"],
              antonyms: [],
              example:
                "They really laughed when he did Clinton, with a perfect accent and a leer.",
            },
            {
              definition:
                "(with 'a' and the name of a person, place, event, etc.) To copy or emulate the actions or behaviour that is associated with the person or thing mentioned.",
              synonyms: [],
              antonyms: [],
              example: "He did a Henry VIII and got married six times.",
            },
            {
              definition: "To kill.",
              synonyms: ["do in", "murder", "off", "rub out"],
              antonyms: [],
            },
            {
              definition:
                "To deal with for good and all; to finish up; to undo; to ruin; to do for.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To punish for a misdemeanor.",
              synonyms: [],
              antonyms: [],
              example: "He got done for speeding.",
            },
            {
              definition: "To have sex with. (See also do it)",
              synonyms: ["go to bed with", "sleep with"],
              antonyms: [],
            },
            {
              definition: "To cheat or swindle.",
              synonyms: ["defraud", "diddle", "mug off", "rip off", "scam"],
              antonyms: [],
              example: "That guy just did me out of two hundred bucks!",
            },
            {
              definition:
                "To convert into a certain form; especially, to translate.",
              synonyms: [],
              antonyms: [],
              example:
                "the novel has just been done into English;  I'm going to do this play into a movie",
            },
            { definition: "To finish.", synonyms: [], antonyms: [] },
            {
              definition: "To work as a domestic servant (with for).",
              synonyms: ["attend", "serve", "wait on"],
              antonyms: [],
            },
            {
              definition:
                "(auxiliary) Used to form the present progressive of verbs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cash or to advance money for, as a bill or note.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(ditransitive) To make or provide.",
              synonyms: ["furnish", "give", "supply"],
              antonyms: [],
              example:
                "Could you do me a burger with mayonnaise instead of ketchup?",
            },
            {
              definition: "To injure (one's own body part).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To take drugs.",
              synonyms: [],
              antonyms: [],
              example: "I do cocaine.",
            },
            {
              definition:
                "(in the form be doing [somewhere]) To exist with a purpose or for a reason.",
              synonyms: [],
              antonyms: [],
              example: "What's that car doing in our swimming pool?",
            },
          ],
          synonyms: [
            "accomplish",
            "carry out",
            "functionate",
            "attend",
            "serve",
            "wait on",
            "defraud",
            "diddle",
            "mug off",
            "rip off",
            "scam",
            "do in",
            "murder",
            "off",
            "rub out",
            "furnish",
            "give",
            "supply",
            "go to bed with",
            "sleep with",
            "imitate",
            "personate",
            "take off",
            "serve",
          ],
          antonyms: ["don't"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A deed or action, especially when somebody is held responsible for it.",
              synonyms: [],
              antonyms: [],
              example: 'This is his doing. (= "He did it.")',
            },
          ],
          synonyms: ["act"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/do",
        "https://en.wiktionary.org/wiki/doing",
      ],
    },
    {
      word: "doing",
      phonetic: "/dɔɪŋ/",
      phonetics: [{ text: "/dɔɪŋ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition:
                "The sound made by an elastic object when struck by or striking a hard object.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["boing"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/doing"],
    },
  ],
  names: [
    {
      word: "names",
      phonetic: "/neɪmz/",
      phonetics: [
        {
          text: "/neɪmz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/names-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1243845",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any nounal word or phrase which indicates a particular person, place, class, or thing.",
              synonyms: ["proper name"],
              antonyms: [],
              example:
                "I've never liked the name my parents gave me so I changed it at the age of twenty.",
            },
            { definition: "Reputation.", synonyms: [], antonyms: [] },
            {
              definition: "An abusive or insulting epithet.",
              synonyms: [],
              antonyms: [],
              example: "Stop calling me names!",
            },
            {
              definition: "A person (or legal person).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Those of a certain name; a race; a family.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A unique identifier, generally a string of characters.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An investor in Lloyds of London bearing unlimited liability.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Authority.",
              synonyms: [],
              antonyms: [],
              example: "Halt in the name of the law!",
            },
          ],
          synonyms: ["proper name"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(ditransitive) To give a name to.",
              synonyms: [],
              antonyms: [],
              example:
                "One visitor named Hou Yugang said he was not too concerned about climate change and Baishui’s melting.",
            },
            {
              definition: "To mention, specify.",
              synonyms: [],
              antonyms: [],
              example: "He named his demands.",
            },
            {
              definition: "To identify as relevant or important",
              synonyms: [],
              antonyms: [],
              example: "naming the problem",
            },
            {
              definition: "To publicly implicate.",
              synonyms: [],
              antonyms: [],
              example: "The painter was named as an accomplice.",
            },
            {
              definition: "To designate for a role.",
              synonyms: [],
              antonyms: [],
              example: "My neighbor was named to the steering committee.",
            },
            {
              definition:
                "(Westminster system politics) To initiate a process to temporarily remove a member of parliament who is breaking the rules of conduct.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bename", "designate", "dub"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of several types of true yam (Dioscorea) used in Caribbean Spanish cooking.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["cush-cush"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/name",
        "https://en.wiktionary.org/wiki/names",
      ],
    },
  ],
  forms: [
    {
      word: "forms",
      phonetic: "/fɔːmz/",
      phonetics: [
        { text: "/fɔːmz/", audio: "" },
        {
          text: "/fɔɹmz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/forms-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1243963",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(heading, physical) To do with shape.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(social) To do with structure or procedure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A blank document or template to be filled in by the user.",
              synonyms: [],
              antonyms: [],
              example:
                "To apply for the position, complete the application form.",
            },
            {
              definition: "A specimen document to be copied or imitated.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Level of performance.",
              synonyms: [],
              antonyms: [],
              example: "The orchestra was on top form this evening.",
            },
            {
              definition:
                "(grammar) A grouping of words which maintain grammatical context in different usages; the particular shape or structure of a word or part of speech.",
              synonyms: [],
              antonyms: [],
              example: "participial forms;  verb forms",
            },
            {
              definition: "The den or home of a hare.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A window or dialogue box.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An infraspecific rank.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The type or other matter from which an impression is to be taken, arranged and secured in a chase.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A quantic.", synonyms: [], antonyms: [] },
            {
              definition: "(fitness) A specific way of performing a movement.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "f.",
            "formular",
            "configuration",
            "makeup",
            "grade",
            "cast",
            "cookie cutter",
            "mold",
            "pattern",
            "shape",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To assume (a certain shape or visible structure).",
              synonyms: [],
              antonyms: [],
              example:
                "When you kids form a straight line I'll hand out the lollies.",
            },
            {
              definition:
                "To give (a shape or visible structure) to a thing or person.",
              synonyms: [],
              antonyms: [],
              example: "Roll out the dough to form a thin sheet.",
            },
            {
              definition: "To take shape.",
              synonyms: [],
              antonyms: [],
              example:
                "When icicles start to form on the eaves you know the roads will be icy.",
            },
            {
              definition: "To put together or bring into being; assemble.",
              synonyms: [],
              antonyms: [],
              example:
                "Paul McCartney and John Lennon formed The Beatles in Liverpool in 1960.",
            },
            {
              definition: "To create (a word) by inflection or derivation.",
              synonyms: [],
              antonyms: [],
              example:
                'By adding "-ness", you can form a noun from an adjective.',
            },
            {
              definition: "To constitute, to compose, to make up.",
              synonyms: [],
              antonyms: [],
              example: "Teenagers form the bulk of extreme traffic offenders.",
            },
            {
              definition: "To mould or model by instruction or discipline.",
              synonyms: [],
              antonyms: [],
              example: "Singing in a choir helps to form a child's sociality.",
            },
            {
              definition: "To provide (a hare) with a form.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To treat (plates) to prepare them for introduction into a storage battery, causing one plate to be composed more or less of spongy lead, and the other of lead peroxide. This was formerly done by repeated slow alternations of the charging current, but later the plates or grids were coated or filled, one with a paste of red lead and the other with litharge, introduced into the cell, and formed by a direct charging current.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "compose",
            "make up",
            "beshape",
            "transmogrify",
            "take form",
            "take shape",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/form",
        "https://en.wiktionary.org/wiki/forms",
      ],
    },
  ],
  heavy: [
    {
      word: "heavy",
      phonetic: "/ˈhe.vi/",
      phonetics: [
        {
          text: "/ˈhe.vi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/heavy-1-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75271837",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈhe.vi/", audio: "" },
        { text: "/ˈhɛ.vi/", audio: "" },
        {
          text: "/ˈhɛ.vi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/heavy-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=454028",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A villain or bad guy; the one responsible for evil or aggressive acts.",
              synonyms: [],
              antonyms: [],
              example:
                "With his wrinkled, uneven face, the actor always seemed to play the heavy in films.",
            },
            {
              definition: "A doorman, bouncer or bodyguard.",
              synonyms: [],
              antonyms: [],
              example:
                "A fight started outside the bar but the heavies came out and stopped it.",
            },
            {
              definition:
                "A large multi-engined aircraft. (The term heavy normally follows the call-sign when used by air traffic controllers.)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: '(often with "up") To make heavier.',
              synonyms: [],
              antonyms: [],
            },
            { definition: "To sadden.", synonyms: [], antonyms: [] },
            {
              definition:
                "To use power and/or wealth to exert influence on, e.g., governments or corporations; to pressure.",
              synonyms: [],
              antonyms: [],
              example:
                "The union was well known for the methods it used to heavy many businesses.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "(of a physical object) Having great weight.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of a topic) Serious, somber.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Not easy to bear; burdensome; oppressive.",
              synonyms: [],
              antonyms: [],
              example:
                "heavy yokes, expenses, undertakings, trials, news, etc.",
            },
            {
              definition: "Good.",
              synonyms: [],
              antonyms: [],
              example: "This film is heavy.",
            },
            {
              definition: "(late 1960s, 1970s) Profound.",
              synonyms: [],
              antonyms: [],
              example: "The Moody Blues are, like, heavy.",
            },
            {
              definition: "(of a rate of flow) High, great.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Armed.",
              synonyms: [],
              antonyms: [],
              example: "Come heavy, or not at all.",
            },
            {
              definition: "Louder, more distorted.",
              synonyms: [],
              antonyms: [],
              example: "Metal is heavier than swing.",
            },
            {
              definition: "(of weather) Hot and humid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a person) Doing the specified activity more intensely than most other people.",
              synonyms: [],
              antonyms: [],
              example:
                "He was a heavy sleeper, a heavy eater and a heavy smoker – certainly not an ideal husband.",
            },
            {
              definition:
                "(of food) High in fat or protein; difficult to digest.",
              synonyms: [],
              antonyms: [],
              example:
                "Cheese-stuffed sausage is too heavy to eat before exercising.",
            },
            {
              definition:
                "Of great force, power, or intensity; deep or intense.",
              synonyms: [],
              antonyms: [],
              example:
                "it was a heavy storm;  a heavy slumber in bed;  a heavy punch",
            },
            {
              definition: "Laden to a great extent.",
              synonyms: [],
              antonyms: [],
              example:
                "his eyes were heavy with sleep;  she was heavy with child",
            },
            {
              definition:
                "Laden with that which is weighty; encumbered; burdened; bowed down, either with an actual burden, or with grief, pain, disappointment, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Slow; sluggish; inactive; or lifeless, dull, inanimate, stupid.",
              synonyms: [],
              antonyms: [],
              example: "a heavy gait, looks, manners, style, etc.",
            },
            {
              definition: "Impeding motion; cloggy; clayey.",
              synonyms: [],
              antonyms: [],
              example: "a heavy road; a heavy soil",
            },
            {
              definition: "Not raised or leavened.",
              synonyms: [],
              antonyms: [],
              example: "heavy bread",
            },
            {
              definition: "(of wines or spirits) Having much body or strength.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "With child; pregnant.", synonyms: [], antonyms: [] },
            {
              definition:
                "Containing one or more isotopes that are heavier than the normal one",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(petroleum) with high viscosity",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["swear", "sweer"],
          antonyms: ["light"],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "In a heavy manner; weightily; heavily; gravely.",
              synonyms: [],
              antonyms: [],
              example: "heavy laden with their sins",
            },
            {
              definition: "To a great degree; greatly.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Very", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/heavy"],
    },
    {
      word: "heavy",
      phonetic: "/ˈhiːvi/",
      phonetics: [
        {
          text: "/ˈhiːvi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/heavy-2-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88943406",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having the heaves.",
              synonyms: [],
              antonyms: [],
              example: "a heavy horse",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/heavy"],
    },
  ],
  ideas: [
    {
      word: "ideas",
      phonetic: "/aɪˈdɪəz/",
      phonetics: [
        { text: "/aɪˈdɪəz/", audio: "" },
        {
          text: "/aɪˈdi.əz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ideas-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1244141",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An abstract archetype of a given thing, compared to which real-life examples are seen as imperfect approximations; pure essence, as opposed to actual examples.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The conception of someone or something as representing a perfect example; an ideal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The form or shape of something; a quintessential aspect or characteristic.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An image of an object that is formed in the mind or recalled by the memory.",
              synonyms: [],
              antonyms: [],
              example: "The mere idea of you is enough to excite me.",
            },
            {
              definition:
                "More generally, any result of mental activity; a thought, a notion; a way of thinking.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A conception in the mind of something to be done; a plan for doing something, an intention.",
              synonyms: [],
              antonyms: [],
              example: "I have an idea of how we might escape.",
            },
            {
              definition: "A purposeful aim or goal; intent",
              synonyms: [],
              antonyms: [],
              example: "Yeah, that's the idea.",
            },
            {
              definition:
                "A vague or fanciful notion; a feeling or hunch; an impression.",
              synonyms: [],
              antonyms: [],
              example:
                "He had the wild idea that if he leant forward a little, he might be able to touch the mountain-top.",
            },
            {
              definition: "A musical theme or melodic subject.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["image"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/idea",
        "https://en.wiktionary.org/wiki/ideas",
      ],
    },
  ],
  cried: [
    {
      word: "cried",
      phonetic: "/kɹaɪd/",
      phonetics: [
        {
          text: "/kɹaɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cried-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1227477",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To shed tears; to weep.",
              synonyms: [],
              antonyms: [],
              example: "That sad movie always makes me cry.",
            },
            {
              definition: "To utter loudly; to call out; to declare publicly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To shout, scream, yell.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To utter inarticulate sounds, as animals do.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause to do something, or bring to some state, by crying or weeping.",
              synonyms: [],
              antonyms: [],
              example: "Tonight I'll cry myself to sleep.",
            },
            {
              definition:
                "To make oral and public proclamation of; to notify or advertise by outcry, especially things lost or found, goods to be sold, etc.",
              synonyms: [],
              antonyms: [],
              example: "to cry goods",
            },
            {
              definition: "Hence, to publish the banns of, as for marriage.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bawl", "blubber", "sob", "wail", "weep", "whimper"],
          antonyms: ["laugh"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cried",
        "https://en.wiktionary.org/wiki/cry",
      ],
    },
  ],
  check: [
    {
      word: "check",
      phonetic: "/t͡ʃɛk/",
      phonetics: [
        {
          text: "/t͡ʃɛk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/check-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1224273",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A situation in which the king is directly threatened by an opposing piece.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An inspection or examination.",
              synonyms: [],
              antonyms: [],
              example:
                "I don't know if she will be there, but it's worth a check.",
            },
            {
              definition: "A control; a limit or stop.",
              synonyms: [],
              antonyms: [],
              example: "The castle moat should hold the enemy in check.",
            },
            {
              definition:
                "A mark (especially a checkmark: ✓) used as an indicator.",
              synonyms: ["checkmark", "tick"],
              antonyms: [],
              example: "Place a check by the things you have done.",
            },
            {
              definition:
                "An order to a bank to pay money to a named person or entity.",
              synonyms: ["cheque"],
              antonyms: [],
              example:
                "I was not carrying cash, so I wrote a check for the amount.",
            },
            {
              definition: "A bill, particularly in a restaurant.",
              synonyms: ["bill", "cheque"],
              antonyms: [],
              example:
                "I summoned the waiter, paid the check, and hurried to leave.",
            },
            {
              definition:
                "A maneuver performed by a player to take another player out of the play.",
              synonyms: [],
              antonyms: [],
              example:
                "The hockey player gave a good hard check to obtain the puck.",
            },
            {
              definition:
                "A token used instead of cash in gaming machines, or in gambling generally.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A lengthwise separation through the growth rings in wood.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A mark, certificate or token by which errors may be prevented, or a thing or person may be identified.",
              synonyms: [],
              antonyms: [],
              example: "a check given for baggage",
            },
            {
              definition:
                "The forsaking by a hawk of its proper game to follow other birds.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A small chink or crack.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bill", "cheque", "checkmark", "tick", "cheque"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/check"],
    },
    {
      word: "check",
      phonetic: "/t͡ʃɛk/",
      phonetics: [
        {
          text: "/t͡ʃɛk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/check-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1224273",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To inspect; to examine.",
              synonyms: [],
              antonyms: [],
              example: "Check the oil in your car once a month.",
            },
            {
              definition:
                "To verify the accuracy of a text or translation, usually making some corrections (proofread) or many (copyedit).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                '(often used with "off") To mark items on a list (with a checkmark or by crossing them out) that have been chosen for keeping or removal or that have been dealt with (for example, completed or verified as correct or satisfactory).',
              synonyms: [
                "check off",
                "cross off",
                "strike off",
                "tick",
                "tick off",
              ],
              antonyms: ["uncheck"],
              example: "Check off the items that you've checked (inspected).",
            },
            {
              definition: "To control, limit, or halt.",
              synonyms: ["curtail", "restrain"],
              antonyms: [],
              example: "Check your enthusiasm during a negotiation.",
            },
            {
              definition: "To verify or compare with a source of information.",
              synonyms: [],
              antonyms: [],
              example: "Check your data against known values.",
            },
            {
              definition: "To leave in safekeeping.",
              synonyms: [],
              antonyms: [],
              example: "Check your hat and coat at the door.",
            },
            {
              definition: "To leave with a shipping agent for shipping.",
              synonyms: [],
              antonyms: [],
              example:
                "Check your bags at the ticket counter before the flight.",
            },
            {
              definition:
                "To pass or bounce the ball to an opponent from behind the three-point line and have the opponent pass or bounce it back to start play.",
              synonyms: [],
              antonyms: [],
              example:
                "He checked the ball and then proceeded to perform a perfect layup.",
            },
            {
              definition:
                "To disrupt another player with the stick or body to obtain possession of the ball or puck.",
              synonyms: ["attack", "tackle", "trap"],
              antonyms: [],
              example:
                "The hockey player checked the defenceman to obtain the puck.",
            },
            {
              definition:
                "To remain in a hand without betting. Only legal if no one has yet bet.",
              synonyms: [],
              antonyms: [],
              example: "Tom didn't think he could win, so he checked.",
            },
            {
              definition:
                "To make a move which puts an adversary's king in check; to put in check.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To chide, rebuke, or reprove.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To slack or ease off, as a brace which is too stiffly extended.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To crack or gape open, as wood in drying; or to crack in small checks, as varnish, paint, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make checks or chinks in; to cause to crack.",
              synonyms: [],
              antonyms: [],
              example: "The sun checks timber.",
            },
            {
              definition: "To make a stop; to pause; with at.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To clash or interfere.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To act as a curb or restraint.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To turn, when in pursuit of proper game, and fly after other birds.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "attack",
            "tackle",
            "trap",
            "check off",
            "cross off",
            "strike off",
            "tick",
            "tick off",
            "curtail",
            "restrain",
          ],
          antonyms: ["uncheck"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/check"],
    },
    {
      word: "check",
      phonetic: "/t͡ʃɛk/",
      phonetics: [
        {
          text: "/t͡ʃɛk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/check-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1224273",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(usually pluralized) A pattern made up of a grid of squares of alternating colors; a checkered pattern.",
              synonyms: [],
              antonyms: [],
              example: "The tablecloth had red and white checks.",
            },
            {
              definition: "Any fabric woven with such a pattern.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To mark with a check pattern.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Divided into small squares by transverse, perpendicular, and horizontal lines.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/check"],
    },
  ],
  floor: [
    {
      word: "floor",
      phonetic: "/flɔː/",
      phonetics: [
        {
          text: "/flɔː/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/floor-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9021803",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/flɔɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/floor-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1446728",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The interior bottom or surface of a house or building; the supporting surface of a room.",
              synonyms: [],
              antonyms: [],
              example: "The room has a wooden floor.",
            },
            {
              definition:
                "Ground (surface of the Earth, as opposed to the sky or water or underground).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The lower inside surface of a hollow space.",
              synonyms: [],
              antonyms: [],
              example: "Many sunken ships rest on the ocean floor.",
            },
            {
              definition:
                "A structure formed of beams, girders, etc, with proper covering, which divides a building horizontally into storeys/stories.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The supporting surface or platform of a structure such as a bridge.",
              synonyms: [],
              antonyms: [],
              example:
                "Wooden planks of the old bridge's floor were nearly rotten.",
            },
            {
              definition: "A storey/story of a building.",
              synonyms: [],
              antonyms: [],
              example: "For years we lived on the third floor.",
            },
            {
              definition:
                "In a parliament, the part of the house assigned to the members, as opposed to the viewing gallery.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Hence, the right to speak at a given time during a debate or other public event.",
              synonyms: [],
              antonyms: [],
              example: "The mayor often gives a lobbyist the floor.",
            },
            {
              definition:
                "That part of the bottom of a vessel on each side of the keelson which is most nearly horizontal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The rock underlying a stratified or nearly horizontal deposit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A horizontal, flat ore body.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The largest integer less than or equal to a given number.",
              synonyms: [],
              antonyms: [],
              example: "The floor of 4.5 is 4.",
            },
            {
              definition:
                "An event performed on a floor-like carpeted surface.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A floor-like carpeted surface for performing gymnastic movements.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A lower limit on the interest rate payable on an otherwise variable-rate loan, used by lenders to defend against falls in interest rates. Opposite of a cap.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A dance floor.", synonyms: [], antonyms: [] },
            {
              definition:
                "The area in which business is conducted at a convention or exhibition",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["possession"],
          antonyms: ["ceiling"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cover or furnish with a floor.",
              synonyms: [],
              antonyms: [],
              example: "floor a house with pine boards",
            },
            {
              definition:
                "To strike down or lay level with the floor; to knock down.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(driving) To accelerate rapidly.",
              synonyms: [],
              antonyms: [],
              example:
                "As soon as our driver saw an insurgent in a car holding a detonation device, he floored the pedal and was 2,000 feet away when that car bomb exploded. We escaped certain death in the nick of time!",
            },
            {
              definition: "To silence by a conclusive answer or retort.",
              synonyms: [],
              antonyms: [],
              example: "Floored or crushed by him. — Coleridge",
            },
            {
              definition: "To amaze or greatly surprise.",
              synonyms: [],
              antonyms: [],
              example: "We were floored by his confession.",
            },
            {
              definition: "To finish or make an end of.",
              synonyms: [],
              antonyms: [],
              example: "I've floored my little-go work — ed Hughes",
            },
            { definition: "To set a lower bound.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/floor"],
    },
  ],
  begin: [
    {
      word: "begin",
      phonetic: "/biˈɡɪn/",
      phonetics: [
        {
          text: "/biˈɡɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/begin-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9027932",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/biˈɡɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/begin-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1239801",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Beginning; start.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To start, to initiate or take the first step into something.",
              synonyms: [],
              antonyms: [],
              example:
                "I began playing the piano at the age of five.   Now that everyone is here, we should begin the presentation.",
            },
            {
              definition: "To be in the first stage of some situation",
              synonyms: [],
              antonyms: [],
              example:
                "The program begins at 9 o'clock on the dot.    I rushed to get to class on time, but the lesson had already begun.",
            },
            {
              definition: "To come into existence.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["commence", "initiate", "start"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/begin"],
    },
  ],
  woman: [
    {
      word: "woman",
      phonetic: "/ˈwʊmɘn/",
      phonetics: [
        { text: "/ˈwʊmɘn/", audio: "" },
        {
          text: "/ˈwʊmən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/woman-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9028980",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ˈwʊmən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/woman-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1197724",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An adult female human.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(collective) All females collectively; womankind.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A female person, usually an adult; a (generally adult) female sentient being, whether human, supernatural, elf, alien, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A wife (or sometimes a fiancée or girlfriend).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A female who is extremely fond of or devoted to a specified type of thing. (Used as the last element of a compound.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A female attendant or servant.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["female", "lady"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To staff with female labor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make effeminate or womanish.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To furnish with, or unite to, a woman.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'To call (a person) "woman" in a disrespectful fashion.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/woman"],
    },
  ],
  alone: [
    {
      word: "alone",
      phonetic: "/əˈləʊn/",
      phonetics: [
        {
          text: "/əˈləʊn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/alone-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9014246",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/əˈloʊn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/alone-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1224184",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "By oneself, solitary.",
              synonyms: [],
              antonyms: [],
              example: "I can't ask for help because I am alone.",
            },
            {
              definition: "Apart from, or exclusive of, others.",
              synonyms: [],
              antonyms: [],
              example: "Jones alone could do it.",
            },
            {
              definition: "Considered separately.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Without equal.", synonyms: [], antonyms: [] },
            {
              definition: "Unique; rare; matchless.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition:
                "By oneself; apart from, or exclusive of, others; solo.",
              synonyms: [
                "by one's lonesome",
                "lonelily",
                "on one's lonesome",
                "singlely",
                "solitarily",
                "solo",
              ],
              antonyms: [],
              example: "She walked home alone.",
            },
            {
              definition: "Without outside help.",
              synonyms: [
                "by one's lonesome",
                "by oneself",
                "on one's lonesome",
                "singlehanded",
                "singlehandedly",
              ],
              antonyms: [],
              example: "The job was too hard for me to do alone.",
            },
            {
              definition: "Exclusively.",
              synonyms: ["entirely", "solely"],
              antonyms: [],
              example: "The responsibility is theirs alone.",
            },
          ],
          synonyms: [
            "by one's lonesome",
            "by oneself",
            "on one's lonesome",
            "singlehanded",
            "singlehandedly",
            "by one's lonesome",
            "lonelily",
            "on one's lonesome",
            "singlely",
            "solitarily",
            "solo",
            "entirely",
            "solely",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/alone"],
    },
  ],
  plane: [
    {
      word: "plane",
      phonetic: "/pleɪn/",
      phonetics: [
        {
          text: "/pleɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/plane-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1118850",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A level or flat surface.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A flat surface extending infinitely in all directions (e.g. horizontal or vertical plane).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A level of existence or development. (eg, astral plane)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A roughly flat, thin, often moveable structure used to create lateral force by the flow of air or water over its surface, found on aircraft, submarines, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Unicode) Any of a number of designated ranges of sequential code points.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An imaginary plane which divides the body into two portions.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of a surface: flat or level.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/plane"],
    },
    {
      word: "plane",
      phonetic: "/pleɪn/",
      phonetics: [
        {
          text: "/pleɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/plane-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1118850",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A tool for smoothing wood by removing thin layers from the surface.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To smooth (wood) with a plane.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/plane"],
    },
    {
      word: "plane",
      phonetic: "/pleɪn/",
      phonetics: [
        {
          text: "/pleɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/plane-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1118850",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An airplane; an aeroplane.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various nymphalid butterflies, of various genera, having a slow gliding flight. Also called aeroplanes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The butterfly Bindahara phocides, family Lycaenidae, of Asia and Australasia.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To move in a way that lifts the bow of a boat out of the water.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To glide or soar.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/plane"],
    },
    {
      word: "plane",
      phonetic: "/pleɪn/",
      phonetics: [
        {
          text: "/pleɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/plane-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1118850",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A deciduous tree of the genus Platanus.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(Northern UK) A sycamore.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/plane"],
    },
  ],
  spell: [
    {
      word: "spell",
      phonetic: "/spɛl/",
      phonetics: [
        {
          text: "/spɛl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spell-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75900056",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/spɛl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spell-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762772",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Words or a formula supposed to have magical powers.",
              synonyms: ["cantrip", "incantation"],
              antonyms: [],
              example: "He cast a spell to cure warts.",
            },
            {
              definition:
                "A magical effect or influence induced by an incantation or formula.",
              synonyms: ["cantrip"],
              antonyms: [],
              example: "under a spell",
            },
            { definition: "Speech, discourse.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["cantrip", "cantrip", "incantation"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To put under the influence of a spell; to affect by a spell; to bewitch; to fascinate; to charm.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spell"],
    },
    {
      word: "spell",
      phonetic: "/spɛl/",
      phonetics: [
        {
          text: "/spɛl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spell-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75900056",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/spɛl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spell-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762772",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To read (something) as though letter by letter; to peruse slowly or with effort.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(sometimes with “out”) To write or say the letters that form a word or part of a word.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be able to write or say the letters that form words.",
              synonyms: [],
              antonyms: [],
              example: "I find it difficult to spell because I'm dyslexic.",
            },
            {
              definition: "Of letters: to compose (a word).",
              synonyms: [],
              antonyms: [],
              example: "The letters “a”, “n” and “d” spell “and”.",
            },
            {
              definition: "(with “out”) To clarify; to explain in detail.",
              synonyms: [],
              antonyms: [],
              example: "Please spell it out for me.",
            },
            {
              definition: "To indicate that (some event) will occur.",
              synonyms: [],
              antonyms: [],
              example: "This spells trouble.",
            },
            {
              definition: "To constitute; to measure.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To speak, to declaim.", synonyms: [], antonyms: [] },
            {
              definition: "To tell; to relate; to teach.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["comprise", "forebode", "mean", "signify", "relieve"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spell"],
    },
    {
      word: "spell",
      phonetic: "/spɛl/",
      phonetics: [
        {
          text: "/spɛl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spell-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75900056",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/spɛl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spell-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762772",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A shift (of work); a set of workers responsible for a specific turn of labour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A definite period (of work or other activity).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An indefinite period of time (usually with a qualifier); by extension, a relatively short distance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A period of rest; time off.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A period of illness, or sudden interval of bad spirits, disease etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An uninterrupted series of alternate overs bowled by a single bowler.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To work in place of (someone).",
              synonyms: [],
              antonyms: [],
              example: "to spell the helmsman",
            },
            {
              definition:
                "To rest (someone or something), to give someone or something a rest or break.",
              synonyms: [],
              antonyms: [],
              example:
                "They spelled the horses and rested in the shade of some trees near a brook.",
            },
            {
              definition: "To rest from work for a time.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spell"],
    },
    {
      word: "spell",
      phonetic: "/spɛl/",
      phonetics: [
        {
          text: "/spɛl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spell-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75900056",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/spɛl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spell-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762772",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A splinter, usually of wood; a spelk.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The wooden bat in the game of trap ball, or knurr and spell.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spell"],
    },
  ],
  watch: [
    {
      word: "watch",
      phonetic: "/wɒt͡ʃ/",
      phonetics: [
        {
          text: "/wɒt͡ʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/watch-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=22713045",
        },
        {
          text: "/wɑt͡ʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/watch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=825476",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A portable or wearable timepiece.",
              synonyms: [],
              antonyms: [],
              example:
                "More people today carry a watch on their wrists than in their pockets.",
            },
            {
              definition:
                "The act of guarding and observing someone or something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A particular time period when guarding is kept.",
              synonyms: [],
              antonyms: [],
              example: "The second watch of the night began at midnight.",
            },
            {
              definition: "A person or group of people who guard.",
              synonyms: [],
              antonyms: [],
              example: "The watch stopped the travelers at the city gates.",
            },
            {
              definition:
                "The post or office of a watchman; also, the place where a watchman is posted, or where a guard is kept.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A group of sailors and officers aboard a ship or shore station with a common period of duty: starboard watch, port watch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A period of time on duty, usually four hours in length; the officers and crew who tend the working of a vessel during the same watch. (FM 55–501).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The act of seeing, or viewing, for a period of time.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/watch"],
    },
    {
      word: "watch",
      phonetic: "/wɒt͡ʃ/",
      phonetics: [
        {
          text: "/wɒt͡ʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/watch-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=22713045",
        },
        {
          text: "/wɑt͡ʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/watch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=825476",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To look at, see, or view for a period of time.",
              synonyms: [],
              antonyms: [],
              example: "I'm tired of watching TV.",
            },
            {
              definition:
                "To observe over a period of time; to notice or pay attention.",
              synonyms: [],
              antonyms: [],
              example:
                "Put a little baking soda in some vinegar and watch what happens.",
            },
            {
              definition: "To mind, attend, or guard.",
              synonyms: [],
              antonyms: [],
              example: "He has to watch the kids that afternoon.",
            },
            {
              definition: "To be wary or cautious of.",
              synonyms: [],
              antonyms: [],
              example:
                "You should watch that guy. He has a reputation for lying.",
            },
            {
              definition: "To attend to dangers to or regarding.",
              synonyms: [],
              antonyms: [],
              example: "Watch what you say.",
            },
            {
              definition:
                "To remain awake with a sick or dying person; to maintain a vigil.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be vigilant or on one's guard.",
              synonyms: [],
              antonyms: [],
              example:
                "For some must watch, while some must sleep: So runs the world away.",
            },
            { definition: "To act as a lookout.", synonyms: [], antonyms: [] },
            {
              definition:
                "(of a buoy) To serve the purpose of a watchman by floating properly in its place.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To be awake.", synonyms: [], antonyms: [] },
            {
              definition: "To be on the lookout for; to wait for expectantly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["ignore"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/watch"],
    },
  ],
  carry: [
    {
      word: "carry",
      phonetic: "/ˈkæ.ɹi/",
      phonetics: [
        {
          text: "/ˈkæ.ɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/carry.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=247050",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A manner of transporting or lifting something; the grip or position in which something is carried.",
              synonyms: [],
              antonyms: [],
              example:
                "Adjust your carry from time to time so that you don't tire too quickly.",
            },
            {
              definition:
                "A tract of land over which boats or goods are carried between two bodies of navigable water; a portage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The bit or digit that is carried in an addition operation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The benefit or cost of owning an asset over time.",
              synonyms: [],
              antonyms: [],
              example: "The carry on this trade is 25 basis points per annum.",
            },
            {
              definition:
                "The distance travelled by the ball when struck, until it hits the ground.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Carried interest.", synonyms: [], antonyms: [] },
            { definition: "The sky; cloud-drift.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To lift (something) and take it to another place; to transport (something) by lifting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To transfer from one place (such as a country, book, or column) to another.",
              synonyms: [],
              antonyms: [],
              example: "to carry an account to the ledger",
            },
            {
              definition: "To convey by extension or continuance; to extend.",
              synonyms: [],
              antonyms: [],
              example:
                "The builders are going to carry the chimney through the roof.  They would have carried the road ten miles further, but ran out of materials.",
            },
            {
              definition: "To move; to convey using force",
              synonyms: ["conduct", "impel"],
              antonyms: [],
            },
            { definition: "To lead or guide.", synonyms: [], antonyms: [] },
            {
              definition: "To stock or supply (something); to have in store.",
              synonyms: [],
              antonyms: [],
              example:
                "The corner drugstore doesn't carry his favorite brand of aspirin.",
            },
            {
              definition: "To adopt (something); take (something) over.",
              synonyms: [],
              antonyms: [],
              example: "I think I can carry Smith's work while she is out.",
            },
            {
              definition:
                "To adopt or resolve on, especially in a deliberative assembly",
              synonyms: [],
              antonyms: [],
              example: "The court carries that motion.",
            },
            {
              definition:
                "In an addition, to transfer the quantity in excess of what is countable in the units in a column to the column immediately to the left in order to be added there.",
              synonyms: [],
              antonyms: [],
              example:
                "Five and nine are fourteen; carry the one to the tens place.",
            },
            {
              definition: "To have, hold, possess or maintain (something).",
              synonyms: [],
              antonyms: [],
              example:
                "Always carry sufficient insurance to protect against a loss.",
            },
            {
              definition: "To be transmitted; to travel.",
              synonyms: [],
              antonyms: [],
              example: "The sound of the bells carried for miles on the wind.",
            },
            { definition: "To insult, to diss.", synonyms: [], antonyms: [] },
            {
              definition: "To capture a ship by coming alongside and boarding.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To transport (the ball) whilst maintaining possession.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To have on one's person.",
              synonyms: [],
              antonyms: [],
              example:
                "she always carries a purse;  marsupials carry their young in a pouch",
            },
            {
              definition: "To be pregnant (with).",
              synonyms: [],
              antonyms: [],
              example: "The doctor said she's carrying twins.",
            },
            {
              definition: "To have propulsive power; to propel.",
              synonyms: [],
              antonyms: [],
              example: "A gun or mortar carries well.",
            },
            {
              definition: "To hold the head; said of a horse.",
              synonyms: [],
              antonyms: [],
              example:
                "to carry well, i.e. to hold the head high, with arching neck",
            },
            {
              definition:
                "To have earth or frost stick to the feet when running, as a hare.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To bear or uphold successfully through conflict, for example a leader or principle",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To succeed in (e.g. a contest); to succeed in; to win.",
              synonyms: [],
              antonyms: [],
              example: "The Tories carried the election.",
            },
            {
              definition: "To get possession of by force; to capture.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To contain; to comprise; have a particular aspect; to show or exhibit",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To bear (oneself); to behave or conduct.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To bear the charges or burden of holding or having, as stocks, merchandise, etc., from one time to another.",
              synonyms: [],
              antonyms: [],
              example:
                "A merchant is carrying a large stock;  a farm carries a mortgage;  a broker carries stock for a customer;  to carry a life insurance.",
            },
            {
              definition: "To have a weapon on one's person; to be armed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be disproportionately responsible for a team's success.",
              synonyms: [],
              antonyms: [],
              example:
                "He absolutely carried the game, to the point of killing the entire enemy team by himself.",
            },
          ],
          synonyms: [
            "adopt",
            "take on",
            "take over",
            "bear",
            "move",
            "transport",
            "conduct",
            "impel",
            "have",
            "keep",
            "stock",
            "supply",
            "have",
            "maintain",
            "transmitted",
            "travel",
          ],
          antonyms: ["borrow"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/carry"],
    },
  ],
  wrote: [
    {
      word: "wrote",
      phonetic: "/ɹəʊt/",
      phonetics: [
        { text: "/ɹəʊt/", audio: "" },
        {
          text: "/ɹoʊt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wrote-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1227757",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To form letters, words or symbols on a surface in order to communicate.",
              synonyms: [],
              antonyms: [],
              example: "The pupil wrote his name on the paper.",
            },
            {
              definition: "To be the author of (a book, article, poem, etc.).",
              synonyms: [],
              antonyms: [],
              example: "My uncle writes newspaper articles for The Herald.",
            },
            {
              definition: "To send written information to.",
              synonyms: [],
              antonyms: [],
              example: "(UK) Please write to me when you get there.",
            },
            {
              definition: "To show (information, etc) in written form.",
              synonyms: [],
              antonyms: [],
              example:
                "The due day of the homework is written in the syllabus.",
            },
            {
              definition: "To be an author.",
              synonyms: [],
              antonyms: [],
              example: "I write for a living.",
            },
            {
              definition:
                "(with to) To record data mechanically or electronically.",
              synonyms: [],
              antonyms: [],
              example:
                "The computer writes to the disk faster than it reads from it.",
            },
            {
              definition:
                "(of an exam, a document, etc.) To fill in, to complete using words.",
              synonyms: [],
              antonyms: [],
              example:
                "I was very anxious to know my score after I wrote the test.",
            },
            {
              definition: "To impress durably; to imprint; to engrave.",
              synonyms: [],
              antonyms: [],
              example: "truth written on the heart",
            },
            {
              definition:
                "To make known by writing; to record; to prove by one's own written testimony; often used reflexively.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To sell (an option or other derivative).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "author",
            "pen",
            "save",
            "store",
            "sit",
            "inscribe",
            "scrawl",
            "scribble",
            "post",
            "display",
            "indicate",
            "mark",
            "show",
          ],
          antonyms: ["load", "read", "retrieve"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/write",
        "https://en.wiktionary.org/wiki/wrote",
      ],
    },
  ],
  clear: [
    {
      word: "clear",
      phonetic: "/klɪə(ɹ)/",
      phonetics: [
        { text: "/klɪə(ɹ)/", audio: "" },
        {
          text: "/klɪɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/clear-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=840627",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Full extent; distance between extreme limits; especially; the distance between the nearest surfaces of two bodies, or the space between walls.",
              synonyms: [],
              antonyms: [],
              example: "a room ten feet square in the clear",
            },
            {
              definition:
                "The completion of a stage or challenge, or of the whole game.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person who is free from the influence of engrams.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To remove obstructions, impediments or other unwanted items from.",
              synonyms: [],
              antonyms: [],
              example: "If you clear the table, I'll wash up.",
            },
            {
              definition:
                "To remove (items or material) so as to leave something unobstructed or open.",
              synonyms: [],
              antonyms: [],
              example: "Please clear all this stuff off the table.",
            },
            {
              definition:
                "To become free from obstruction or obscurement; to become transparent.",
              synonyms: [],
              antonyms: [],
              example:
                "After a heavy rain, the sky cleared nicely for the evening.",
            },
            {
              definition:
                "To eliminate ambiguity or doubt from (a matter); to clarify or resolve; to clear up.",
              synonyms: [],
              antonyms: [],
              example: "We need to clear this issue once and for all.",
            },
            {
              definition:
                "To remove from suspicion, especially of having committed a crime.",
              synonyms: [],
              antonyms: [],
              example: "The court cleared the man of murder.",
            },
            {
              definition: "To pass without interference; to miss.",
              synonyms: [],
              antonyms: [],
              example: "The door just barely clears the table as it closes.",
            },
            {
              definition:
                "(activities such as jumping or throwing) To exceed a stated mark.",
              synonyms: [],
              antonyms: [],
              example:
                "She was the first female high jumper to clear two metres.",
            },
            {
              definition:
                "To finish or complete (a stage, challenge, or game).",
              synonyms: [],
              antonyms: [],
              example: "I cleared the first level in 36 seconds.",
            },
            {
              definition:
                "Of a check or financial transaction, to go through as payment; to be processed so that the money is transferred.",
              synonyms: [],
              antonyms: [],
              example: "The check might not clear for a couple of days.",
            },
            {
              definition: "To earn a profit of; to net.",
              synonyms: [],
              antonyms: [],
              example: "He's been clearing seven thousand a week.",
            },
            {
              definition:
                "To approve or authorise for a particular purpose or action; to give clearance to.",
              synonyms: [],
              antonyms: [],
              example: "Air traffic control cleared the plane to land.",
            },
            {
              definition: "To obtain approval or authorisation in respect of.",
              synonyms: [],
              antonyms: [],
              example:
                "I've cleared the press release with the marketing department, so go ahead and publish it.",
            },
            {
              definition: "To obtain a clearance.",
              synonyms: [],
              antonyms: [],
              example: "The steamer cleared for Liverpool today.",
            },
            {
              definition:
                "To obtain permission to use (a sample of copyrighted audio) in another track.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To disengage oneself from incumbrances, distress, or entanglements; to become free.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To hit, kick, head, punch etc. (a ball, puck) away in order to defend one's goal.",
              synonyms: [],
              antonyms: [],
              example: "A low cross came in, and Smith cleared.",
            },
            {
              definition:
                "To reset or unset; to return to an empty state or to zero.",
              synonyms: [],
              antonyms: [],
              example:
                "to clear an array;  to clear a single bit (binary digit) in a value",
            },
            {
              definition:
                "To style (an element within a document) so that it is not permitted to float at a given position.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["stub"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Transparent in colour.",
              synonyms: [],
              antonyms: [],
              example: "as clear as crystal",
            },
            {
              definition: "Bright, not dark or obscured.",
              synonyms: [],
              antonyms: [],
              example:
                "Congress passed the President’s Clear Skies legislation.",
            },
            {
              definition: "Free of obstacles.",
              synonyms: [],
              antonyms: [],
              example: "The coast is clear.",
            },
            {
              definition: "Without clouds.",
              synonyms: [],
              antonyms: [],
              example: "clear weather; a clear day",
            },
            {
              definition:
                "Of the sky, such that less than one eighth of its area is obscured by clouds.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Free of ambiguity or doubt.",
              synonyms: [],
              antonyms: [],
              example: "Do I make myself clear? Crystal clear.",
            },
            {
              definition: "Distinct, sharp, well-marked.",
              synonyms: ["conspicuous"],
              antonyms: [],
            },
            {
              definition: "Free of guilt, or suspicion.",
              synonyms: [],
              antonyms: [],
              example: "a clear conscience",
            },
            {
              definition: "(of a soup) Without a thickening ingredient.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Possessing little or no perceptible stimulus.",
              synonyms: [],
              antonyms: [],
              example: "clear of texture;  clear of odor",
            },
            {
              definition:
                "Free from the influence of engrams; see Clear (Scientology).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Able to perceive clearly; keen; acute; penetrating; discriminating.",
              synonyms: [],
              antonyms: [],
              example: "a clear intellect; a clear head",
            },
            {
              definition: "Not clouded with passion; serene; cheerful.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Easily or distinctly heard; audible.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Unmixed; entirely pure.",
              synonyms: [],
              antonyms: [],
              example: "clear sand",
            },
            {
              definition:
                "Without defects or blemishes, such as freckles or knots.",
              synonyms: [],
              antonyms: [],
              example: "a clear complexion; clear lumber",
            },
            {
              definition: "Without diminution; in full; net.",
              synonyms: [],
              antonyms: [],
              example: "a clear profit",
            },
          ],
          synonyms: ["pellucid", "transparent", "homogeneous", "conspicuous"],
          antonyms: [
            "thick",
            "opaque",
            "turbid",
            "cloudy",
            "nebulous",
            "obscure",
          ],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "All the way; entirely.",
              synonyms: [],
              antonyms: [],
              example: "I threw it clear across the river to the other side.",
            },
            {
              definition: "Not near something or touching it.",
              synonyms: [],
              antonyms: [],
              example: "Stand clear of the rails, a train is coming.",
            },
            {
              definition: "Free (or separate) from others",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "In a clear manner; plainly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/clear"],
    },
  ],
  named: [
    {
      word: "named",
      phonetic: "/neɪmd/",
      phonetics: [
        { text: "/neɪmd/", audio: "" },
        {
          text: "/ˈneɪmd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/named-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1243842",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(ditransitive) To give a name to.",
              synonyms: [],
              antonyms: [],
              example:
                "One visitor named Hou Yugang said he was not too concerned about climate change and Baishui’s melting.",
            },
            {
              definition: "To mention, specify.",
              synonyms: [],
              antonyms: [],
              example: "He named his demands.",
            },
            {
              definition: "To identify as relevant or important",
              synonyms: [],
              antonyms: [],
              example: "naming the problem",
            },
            {
              definition: "To publicly implicate.",
              synonyms: [],
              antonyms: [],
              example: "The painter was named as an accomplice.",
            },
            {
              definition: "To designate for a role.",
              synonyms: [],
              antonyms: [],
              example: "My neighbor was named to the steering committee.",
            },
            {
              definition:
                "(Westminster system politics) To initiate a process to temporarily remove a member of parliament who is breaking the rules of conduct.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bename", "designate", "dub"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Having a name.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: ["unnamed"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/name",
        "https://en.wiktionary.org/wiki/named",
      ],
    },
  ],
  books: [
    {
      word: "books",
      phonetic: "/bʊks/",
      phonetics: [
        {
          text: "/bʊks/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/books-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1227718",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A collection of sheets of paper bound together to hinge at one edge, containing printed or written material, pictures, etc.",
              synonyms: [],
              antonyms: [],
              example:
                "He was frustrated because he couldn't find anything about dinosaurs in the book.",
            },
            {
              definition:
                "A long work fit for publication, typically prose, such as a novel or textbook, and typically published as such a bound collection of sheets, but now sometimes electronically as an e-book.",
              synonyms: [],
              antonyms: [],
              example: "I have three copies of his first book.",
            },
            {
              definition: "A major division of a long work.",
              synonyms: ["tome", "volume"],
              antonyms: [],
              example: "Genesis is the first book of the Bible.",
            },
            {
              definition:
                "A record of betting (from the use of a notebook to record what each person has bet).",
              synonyms: [],
              antonyms: [],
              example: "I'm running a book on who is going to win the race.",
            },
            {
              definition:
                "A convenient collection, in a form resembling a book, of small paper items for individual use.",
              synonyms: ["booklet"],
              antonyms: [],
              example: "a book of raffle tickets",
            },
            {
              definition: "The script of a musical or opera.",
              synonyms: ["libretto"],
              antonyms: [],
            },
            {
              definition:
                "(usually in the plural) Records of the accounts of a business.",
              synonyms: ["account", "record"],
              antonyms: [],
            },
            {
              definition:
                "A book award, a recognition for receiving the highest grade in a class (traditionally an actual book, but recently more likely a letter or certificate acknowledging the achievement).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(whist) Six tricks taken by one side.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Four of a kind", synonyms: [], antonyms: [] },
            {
              definition:
                "A document, held by the referee, of the incidents happened in the game.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) A list of all players who have been booked (received a warning) in a game.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The twenty-sixth Lenormand card.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any source of instruction.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "account",
            "record",
            "booklet",
            "libretto",
            "tome",
            "volume",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To reserve (something) for future use.",
              synonyms: ["reserve"],
              antonyms: [],
              example: "I can book tickets for the concert next week.",
            },
            {
              definition:
                "To write down, to register or record in a book or as in a book.",
              synonyms: ["make a note of", "note down", "record", "write down"],
              antonyms: [],
              example: "They booked that message from the hill",
            },
            {
              definition:
                "(law enforcement) To record the name and other details of a suspected offender and the offence for later judicial action.",
              synonyms: [],
              antonyms: [],
              example: "The police booked him for driving too fast.",
            },
            {
              definition:
                "To issue with a caution, usually a yellow card, or a red card if a yellow card has already been issued.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To travel very fast.",
              synonyms: ["bomb", "hurtle", "rocket", "shoot", "speed", "whiz"],
              antonyms: [],
              example: "He was really booking, until he passed the speed trap.",
            },
            {
              definition: "To record bets as bookmaker.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(law student slang) To receive the highest grade in a class.",
              synonyms: [],
              antonyms: [],
              example:
                "The top three students had a bet on which one was going to book their intellectual property class.",
            },
            {
              definition: "To leave.",
              synonyms: [],
              antonyms: [],
              example: "He was here earlier, but he booked.",
            },
          ],
          synonyms: [
            "bomb",
            "hurtle",
            "rocket",
            "shoot",
            "speed",
            "whiz",
            "make a note of",
            "note down",
            "record",
            "write down",
            "reserve",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/book",
        "https://en.wiktionary.org/wiki/books",
      ],
    },
  ],
  child: [
    {
      word: "child",
      phonetic: "/t͡ʃaɪld/",
      phonetics: [
        {
          text: "/t͡ʃaɪld/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/child-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9014325",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/t͡ʃaɪld/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/child-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1227475",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person who has not yet reached adulthood, whether natural (puberty), cultural (initiation), or legal (majority)",
              synonyms: [],
              antonyms: [],
              example: "Go easy on him: he is but a child.",
            },
            {
              definition: "(specifically) A female child, a girl.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(with possessive) One's direct descendant by birth, regardless of age; a son or daughter.",
              synonyms: [],
              antonyms: [],
              example: "My youngest child is forty-three this year.",
            },
            {
              definition: "The thirteenth Lenormand card.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A figurative offspring, particularly:",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A child of noble birth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The cognomen given to the oldest son prior to his taking his father's title.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/child",
        "https://en.wiktionary.org/wiki/childe",
      ],
    },
    {
      word: "child",
      phonetic: "/t͡ʃaɪld/",
      phonetics: [
        {
          text: "/t͡ʃaɪld/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/child-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9014325",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/t͡ʃaɪld/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/child-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1227475",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To give birth; to beget or procreate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/child"],
    },
  ],
  glass: [
    {
      word: "glass",
      phonetic: "/ɡlɑːs/",
      phonetics: [
        {
          text: "/ɡlɑːs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/glass-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9014192",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ɡlæs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/glass-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=862122",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To apply fibreglass to.",
              synonyms: [],
              antonyms: [],
              example: "to fibreglass the hull of a fishing-boat",
            },
          ],
          synonyms: ["glass"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An amorphous solid, often transparent substance made by melting sand with a mixture of soda, potash and lime.",
              synonyms: [],
              antonyms: [],
              example:
                "A popular myth is that window glass is actually an extremely viscous liquid.",
            },
            {
              definition:
                "A vessel from which one drinks, especially one made of glass, plastic, or similar translucent or semi-translucent material.",
              synonyms: [],
              antonyms: [],
              example: "Fill my glass with milk, please.",
            },
            {
              definition:
                "(metonymically) The quantity of liquid contained in such a vessel.",
              synonyms: [],
              antonyms: [],
              example:
                "There is half a glass of milk in each pound of chocolate we produce.",
            },
            {
              definition: "Glassware.",
              synonyms: [],
              antonyms: [],
              example: "We collected art glass.",
            },
            {
              definition: "A mirror.",
              synonyms: [],
              antonyms: [],
              example: "She adjusted her lipstick in the glass.",
            },
            {
              definition: "A magnifying glass or telescope.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A barrier made of solid, transparent material.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A barometer.", synonyms: [], antonyms: [] },
            {
              definition: "(in names of species) Transparent or translucent.",
              synonyms: [],
              antonyms: [],
              example: "glass frog;  glass shrimp;  glass worm",
            },
            { definition: "An hourglass.", synonyms: [], antonyms: [] },
            {
              definition: "Lenses, considered collectively.",
              synonyms: [],
              antonyms: [],
              example:
                "Her new camera was incompatible with her old one, so she needed to buy new glass.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To fit with glass; to glaze.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To enclose in glass.", synonyms: [], antonyms: [] },
            {
              definition:
                "To strike (someone), particularly in the face, with a drinking glass with the intent of causing injury.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To bombard an area with such intensity (nuclear bomb, fusion bomb, etc) as to melt the landscape into glass.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To view through an optical instrument such as binoculars.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To smooth or polish (leather, etc.), by rubbing it with a glass burnisher.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To reflect; to mirror.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To make glassy.", synonyms: [], antonyms: [] },
            { definition: "To become glassy.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fibreglass",
        "https://en.wiktionary.org/wiki/glass",
      ],
    },
  ],
  human: [
    {
      word: "human",
      phonetic: "/ˈhjuː.mən/",
      phonetics: [
        { text: "/ˈhjuː.mən/", audio: "" },
        { text: "/ˈ(h)juː.mən/", audio: "" },
        {
          text: "/ˈ(h)ju.mən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/human-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1227508",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A human being, whether man, woman or child.",
              synonyms: ["human being", "man"],
              antonyms: [],
              example: "Humans share common ancestors with other apes.",
            },
          ],
          synonyms: ["human being", "man"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To behave as or become, or to cause to behave as or become, a human.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(notcomp) Of or belonging to the species Homo sapiens or its closest relatives.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Having the nature or attributes of a human being.",
              synonyms: [],
              antonyms: [],
              example: "To err is human; to forgive, divine.",
            },
          ],
          synonyms: ["mannish", "mennish"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/human"],
    },
  ],
  takes: [
    {
      word: "takes",
      phonetic: "/teɪks/",
      phonetics: [
        {
          text: "/teɪks/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/takes-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1230725",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The or an act of taking.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Something that is taken; a haul.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An interpretation or view, opinion or assessment; perspective.",
              synonyms: [],
              antonyms: [],
              example: "What's your take on this issue, Fred?",
            },
            {
              definition: "An approach, a (distinct) treatment.",
              synonyms: [],
              antonyms: [],
              example: "a new take on a traditional dish",
            },
            {
              definition:
                "A scene recorded (filmed) at one time, without an interruption or break; a recording of such a scene.",
              synonyms: [],
              antonyms: [],
              example: "Act seven, scene three, take two.",
            },
            {
              definition:
                "A recording of a musical performance made during an uninterrupted single recording period.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A visible (facial) response to something, especially something unexpected; a facial gesture in response to an event.",
              synonyms: [],
              antonyms: [],
              example: "I did a take when I saw the new car in the driveway.",
            },
            {
              definition: "An instance of successful inoculation/vaccination.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A catch of the ball (in cricket, especially one by the wicket-keeper).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The quantity of copy given to a compositor at one time.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To get into one's hands, possession or control, with or without force.",
              synonyms: [],
              antonyms: [],
              example: "I'll take that plate off the table.",
            },
            {
              definition:
                "To receive or accept (something) (especially something given or bestowed, awarded, etc).",
              synonyms: [],
              antonyms: [],
              example: "The camera takes 35mm film.",
            },
            {
              definition: "To remove.",
              synonyms: [],
              antonyms: [],
              example: "take two eggs from the carton",
            },
            { definition: "To have sex with.", synonyms: [], antonyms: [] },
            {
              definition: "To defeat (someone or something) in a fight.",
              synonyms: [],
              antonyms: [],
              example: "Don't try to take that guy. He's bigger than you.",
            },
            {
              definition: "To grasp or grip.",
              synonyms: [],
              antonyms: [],
              example: "He took her hand in his.",
            },
            {
              definition: "To select or choose; to pick.",
              synonyms: [],
              antonyms: [],
              example: "I'll take the blue plates.",
            },
            {
              definition: "To adopt (select) as one's own.",
              synonyms: [],
              antonyms: [],
              example: "She took his side in every argument.",
            },
            {
              definition: "To carry or lead (something or someone).",
              synonyms: [],
              antonyms: [],
              example: "I'll take the plate with me.",
            },
            {
              definition: "To use as a means of transportation.",
              synonyms: [],
              antonyms: [],
              example:
                "He took the bus to London, and then took a train to Manchester.",
            },
            {
              definition: "To visit; to include in a course of travel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To obtain for use by payment or lease.",
              synonyms: [],
              antonyms: [],
              example: "He took a full-page ad in the Times.",
            },
            { definition: "To consume.", synonyms: [], antonyms: [] },
            {
              definition: "To experience, undergo, or endure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause to change to a specified state or condition.",
              synonyms: [],
              antonyms: [],
              example: "He had to take it apart to fix it.",
            },
            {
              definition: "To regard in a specified way.",
              synonyms: [],
              antonyms: [],
              example: "He took the news badly.",
            },
            {
              definition:
                "To conclude or form (a decision or an opinion) in the mind.",
              synonyms: [],
              antonyms: [],
              example: "took a dim view of city officials",
            },
            {
              definition: "To understand (especially in a specified way).",
              synonyms: [],
              antonyms: [],
              example: "Don't take my comments as an insult.",
            },
            {
              definition:
                "To accept or be given (rightly or wrongly); assume (especially as if by right).",
              synonyms: [],
              antonyms: [],
              example:
                "He took all the credit for the project, although he had done almost none of the work.",
            },
            {
              definition: "To believe, to accept the statements of.",
              synonyms: [],
              antonyms: [],
              example: "take her word for it",
            },
            {
              definition:
                "To assume or suppose; to reckon; to regard or consider.",
              synonyms: [],
              antonyms: [],
              example: "Do you take me for a fool?",
            },
            {
              definition:
                "To draw, derive, or deduce (a meaning from something).",
              synonyms: [],
              antonyms: [],
              example: "I'm not sure what moral to take from that story.",
            },
            {
              definition: "To derive (as a title); to obtain from a source.",
              synonyms: [],
              antonyms: [],
              example:
                '"As I Lay Dying" takes its title from Book XI of Homer\'s "Odyssey"',
            },
            {
              definition: "To catch or contract (an illness, etc).",
              synonyms: [],
              antonyms: [],
              example: "took a chill",
            },
            {
              definition:
                "To come upon or catch (in a particular state or situation).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To captivate or charm; to gain or secure the interest or affection of.",
              synonyms: [],
              antonyms: [],
              example: "took her attention",
            },
            {
              definition:
                "(of a material) To absorb or be impregnated by (dye, ink, etc); to be susceptible to being treated by (polish, etc).",
              synonyms: [],
              antonyms: [],
              example: "cloth that takes dye well",
            },
            {
              definition: "(of a ship) To let in (water).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To require.",
              synonyms: [],
              antonyms: [],
              example:
                "Finishing this on schedule will take a lot of overtime.",
            },
            {
              definition: "To proceed to fill.",
              synonyms: [],
              antonyms: [],
              example: "He took a seat in the front row.",
            },
            {
              definition: "To fill, to use up (time or space).",
              synonyms: [],
              antonyms: [],
              example: "His collection takes a lot of space.",
            },
            {
              definition: "To avail oneself of.",
              synonyms: [],
              antonyms: [],
              example: "He took that opportunity to leave France.",
            },
            {
              definition: "To practice; perform; execute; carry out; do.",
              synonyms: [],
              antonyms: [],
              example: "Pirès ran in to take the kick.",
            },
            {
              definition: "To assume or perform (a form or role).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To bind oneself by.",
              synonyms: [],
              antonyms: [],
              example: "he took the oath of office last night",
            },
            {
              definition: "To move into.",
              synonyms: [],
              antonyms: [],
              example: "the next team took the field",
            },
            {
              definition: "To go into, through, or along.",
              synonyms: [],
              antonyms: [],
              example: "go down two blocks and take the next left",
            },
            {
              definition: "To have and use one's recourse to.",
              synonyms: [],
              antonyms: [],
              example: "take cover/shelter/refuge",
            },
            {
              definition:
                "To ascertain or determine by measurement, examination or inquiry.",
              synonyms: [],
              antonyms: [],
              example: "take a census",
            },
            {
              definition: "To write down; to get in, or as if in, writing.",
              synonyms: [],
              antonyms: [],
              example: "He took a mental inventory of his supplies.",
            },
            {
              definition:
                "To make (a photograph, film, or other reproduction of something).",
              synonyms: [],
              antonyms: [],
              example: "Could you take a picture of us?",
            },
            {
              definition:
                "To take a picture, photograph, etc of (a person, scene, etc).",
              synonyms: [],
              antonyms: [],
              example: "The photographer will take you sitting down.",
            },
            {
              definition: "To obtain money from, especially by swindling.",
              synonyms: [],
              antonyms: [],
              example: "took me for ten grand",
            },
            {
              definition:
                "(now chiefly by enrolling in a class or course) To apply oneself to the study of.",
              synonyms: [],
              antonyms: [],
              example: "As a child, she took ballet.",
            },
            {
              definition: "To deal with.",
              synonyms: [],
              antonyms: [],
              example: "take matters as they arise",
            },
            {
              definition:
                "To consider in a particular way, or to consider as an example.",
              synonyms: [],
              antonyms: [],
              example:
                "I've had a lot of problems recently: take last Monday, for example. My car broke down on the way to work. Then ... etc.",
            },
            {
              definition:
                "To decline to swing at (a pitched ball); to refrain from hitting at, and allow to pass.",
              synonyms: [],
              antonyms: [],
              example: "He'll probably take this one.",
            },
            {
              definition: "To accept as an input to a relation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To get or accept (something) into one's possession.",
              synonyms: [],
              antonyms: [],
              example:
                "My husband and I have a dysfunctional marriage. He just takes and takes; he never gives.",
            },
            {
              definition: "To engage, take hold or have effect.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To become; to be affected in a specified way.",
              synonyms: [],
              antonyms: [],
              example: "She took sick with the flu.",
            },
            {
              definition:
                "(possibly obsolete) To be able to be accurately or beautifully photographed.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An intensifier.", synonyms: [], antonyms: [] },
            {
              definition: "To deliver, bring, give (something) to (someone).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(obsolete outside dialectal and slang) To give or deliver (a blow, to someone); to strike or hit.",
              synonyms: [],
              antonyms: [],
              example: "He took me a blow on the head.",
            },
          ],
          synonyms: [
            "capture",
            "conquer",
            "seize",
            "ingest",
            "swallow",
            "beat",
            "confiscate",
            "seize",
            "grab",
            "grasp",
            "grip",
            "nim",
            "have",
            "sleep with",
            "do in",
            "off",
            "terminate",
            "garner",
            "get",
            "obtain",
            "win",
            "knock off",
            "subduct",
            "take away",
          ],
          antonyms: ["give", "bring", "drop"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/take",
        "https://en.wiktionary.org/wiki/takes",
      ],
    },
  ],
  party: [
    {
      word: "party",
      phonetic: "/ˈpɑ(ː)ɾi/",
      phonetics: [
        { text: "/ˈpɑ(ː)ɾi/", audio: "" },
        {
          text: "/ˈpɑɹ.ti/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/party-ca.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=5191937",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
        {
          text: "/ˈpɑː.ti/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/party-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=85473844",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈpɑɹ.ti/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/party-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1224286",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person or group of people constituting a particular side in a contract or legal action.",
              synonyms: [],
              antonyms: [],
              example:
                "The contract requires that the party of the first part pay the fee.",
            },
            { definition: "A person.", synonyms: [], antonyms: [] },
            {
              definition:
                "A group of people forming one side in a given dispute, contest etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A political group considered as a formal whole, united under one specific political platform of issues and campaigning to take part in government.",
              synonyms: [],
              antonyms: [],
              example: "The green party took 12% of the vote.",
            },
            {
              definition:
                "A discrete detachment of troops, especially for a particular purpose.",
              synonyms: [],
              antonyms: [],
              example:
                "The settlers were attacked early next morning by a scouting party.",
            },
            {
              definition:
                "A group of persons collected or gathered together for some particular purpose.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A part or division.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["bash", "do", "rave"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To celebrate at a party, to have fun, to enjoy oneself.",
              synonyms: [],
              antonyms: [],
              example: "We partied until the early hours.",
            },
            {
              definition: "To take recreational drugs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To engage in flings, to have one-night stands, to sow one's wild oats.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To form a party (with).",
              synonyms: [],
              antonyms: [],
              example:
                "If you want to beat that monster, you should party with a healer.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/party"],
    },
    {
      word: "party",
      phonetic: "/ˈpɑ(ː)ɾi/",
      phonetics: [
        { text: "/ˈpɑ(ː)ɾi/", audio: "" },
        {
          text: "/ˈpɑɹ.ti/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/party-ca.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=5191937",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
        {
          text: "/ˈpɑː.ti/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/party-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=85473844",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈpɑɹ.ti/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/party-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1224286",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "(except in compounds) Divided; in part.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Parted or divided, as in the direction or form of one of the ordinaries.",
              synonyms: [],
              antonyms: [],
              example: "an escutcheon party per pale",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/party"],
    },
  ],
  build: [
    {
      word: "build",
      phonetic: "/bɪld/",
      phonetics: [
        {
          text: "/bɪld/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/build-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9027951",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/bɪld/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/build-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749728",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The physique of a human body; constitution or structure of a human body.",
              synonyms: [],
              antonyms: [],
              example: "Rugby players are of sturdy build.",
            },
            {
              definition:
                "Any of various versions of a software product as it is being developed for release to users.",
              synonyms: [],
              antonyms: [],
              example:
                "The computer company has introduced a new prototype build to beta testers.",
            },
            {
              definition:
                "Any structure, such as a building, statue, pool or forest, created by the player.",
              synonyms: [],
              antonyms: [],
              example:
                "I made a build that looked like the Parthenon in that game.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To form (something) by combining materials or parts.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To develop or give form to (something) according to a plan or process.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To increase or strengthen (something) by adding gradually to.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To establish a basis for (something).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To form by combining materials or parts.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To develop in magnitude or extent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To construct (software) by compiling its source code.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of source code) To be converted into software by compilation, usually with minimal human intervention.",
              synonyms: [],
              antonyms: [],
              example:
                "This code won't build any more. Have you made any changes?",
            },
          ],
          synonyms: [
            "create",
            "base",
            "found",
            "ground",
            "construct",
            "erect",
            "build up",
            "enlarge",
            "increase",
            "strengthen",
          ],
          antonyms: [
            "demolish",
            "destroy",
            "ruin",
            "wreck",
            "decrease",
            "dissipate",
            "weaken",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/build"],
    },
  ],
  seems: [
    {
      word: "seems",
      phonetic: "/siːmz/",
      phonetics: [
        {
          text: "/siːmz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/seems-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1227528",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To appear; to look outwardly; to be perceived as.",
              synonyms: [],
              antonyms: [],
              example:
                "He seems to be ill.   Her eyes seem blue.   It seems like it is going to rain later.   How did she seem to you?",
            },
            { definition: "To befit; to beseem.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/seem",
        "https://en.wiktionary.org/wiki/seems",
      ],
    },
  ],
  blood: [
    {
      word: "blood",
      phonetic: "/blʊd/",
      phonetics: [
        { text: "/blʊd/", audio: "" },
        {
          text: "/blʌd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/blood-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9014286",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/blʌd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/blood-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=839238",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A member of the Los Angeles gang The Bloods.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A vital liquid flowing in the bodies of many types of animals that usually conveys nutrients and oxygen. In vertebrates, it is colored red by hemoglobin, is conveyed by arteries and veins, is pumped by the heart and is usually generated in bone marrow.",
              synonyms: [],
              antonyms: [],
              example: "The cultists gathered around a chalice of blood.",
            },
            {
              definition:
                "A family relationship due to birth, such as that between siblings; contrasted with relationships due to marriage or adoption (see blood relative, blood relation, by blood).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One of the four humours in the human body.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A blood test or blood sample.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The sap or juice which flows in or from plants.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The juice of anything, especially if red.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Temper of mind; disposition; mood",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A lively, showy man; a rake; a dandy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A blood horse, one of good pedigree.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Bloodshed.",
              synonyms: [],
              antonyms: [],
              example: "They came looking for blood.",
            },
          ],
          synonyms: ["purging", "background", "descent", "heritage", "stock"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cause something to be covered with blood; to bloody.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To let blood (from); to bleed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To initiate into warfare or a blood sport, traditionally by smearing with the blood of the first kill witnessed.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/Blood",
        "https://en.wiktionary.org/wiki/blood",
      ],
    },
  ],
  sides: [
    {
      word: "sides",
      phonetic: "/saɪdz/",
      phonetics: [
        {
          text: "/saɪdz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sides-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1250006",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A bounding straight edge of a two-dimensional shape.",
              synonyms: [],
              antonyms: [],
              example: "A square has four sides.",
            },
            {
              definition:
                "A flat surface of a three-dimensional object; a face.",
              synonyms: [],
              antonyms: [],
              example: "A cube has six sides.",
            },
            {
              definition:
                "One half (left or right, top or bottom, front or back, etc.) of something or someone.",
              synonyms: [],
              antonyms: [],
              example:
                "Which side of the tray shall I put it on?  The patient was bleeding on the right side.",
            },
            {
              definition:
                "A region in a specified position with respect to something.",
              synonyms: [],
              antonyms: [],
              example: "Meet me on the north side of the monument.",
            },
            {
              definition:
                "The portion of the human torso usually covered by the arms when they are not raised; the areas on the left and right between the belly or chest and the back.",
              synonyms: [],
              antonyms: [],
              example: "I generally sleep on my side.",
            },
            {
              definition:
                'One surface of a sheet of paper (used instead of "page", which can mean one or both surfaces.)',
              synonyms: [],
              antonyms: [],
              example: "John wrote 15 sides for his essay!",
            },
            {
              definition: "One possible aspect of a concept, person or thing.",
              synonyms: [],
              antonyms: [],
              example: "Look on the bright side.",
            },
            {
              definition: "One set of competitors in a game.",
              synonyms: [],
              antonyms: [],
              example: "Which side has kick-off?",
            },
            { definition: "A sports team.", synonyms: [], antonyms: [] },
            {
              definition:
                "A group having a particular allegiance in a conflict or competition.",
              synonyms: [],
              antonyms: [],
              example:
                "In the second world war, the Italians were on the side of the Germans.",
            },
            {
              definition:
                "A recorded piece of music; a record, especially in jazz.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Sidespin; english",
              synonyms: [],
              antonyms: [],
              example: "He had to put a bit of side on to hit the pink ball.",
            },
            {
              definition:
                "A television channel, usually as opposed to the one currently being watched (from when there were only two channels).",
              synonyms: [],
              antonyms: [],
              example:
                "I just want to see what's on the other side — James said there was a good film on tonight.",
            },
            {
              definition:
                "A dish that accompanies the main course; a side dish.",
              synonyms: [],
              antonyms: [],
              example: "Do you want a side of cole-slaw with that?",
            },
            {
              definition:
                "A line of descent traced through one parent as distinguished from that traced through another.",
              synonyms: [],
              antonyms: [],
              example: "his mother's side of the family",
            },
            {
              definition:
                "The batters faced in an inning by a particular pitcher",
              synonyms: [],
              antonyms: [],
              example: "Clayton Kershaw struck out the side in the 6th inning.",
            },
            {
              definition: "An unjustified air of self-importance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A written monologue or part of a scene to be read by an actor at an audition.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A man who prefers not to engage in anal sex during homosexual intercourse.",
              synonyms: [],
              antonyms: [],
              example:
                "My boyfriend and I are both sides, so we prefer to do oral on each other.",
            },
          ],
          synonyms: [
            "edge",
            "face",
            "half",
            "team",
            "page",
            "channel",
            "station",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                'To ally oneself, be in an alliance, usually with "with" or rarely "in with"',
              synonyms: [],
              antonyms: [],
              example: "Which will you side with, good or evil?",
            },
            { definition: "To lean on one side.", synonyms: [], antonyms: [] },
            {
              definition:
                "To be or stand at the side of; to be on the side toward.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To suit; to pair; to match.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To work (a timber or rib) to a certain thickness by trimming the sides.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To furnish with a siding.",
              synonyms: [],
              antonyms: [],
              example: "to side a house",
            },
            {
              definition: "To provide with, as a side or accompaniment.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["take side"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To clear, tidy or sort.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/side",
        "https://en.wiktionary.org/wiki/sides",
      ],
    },
  ],
  seven: [
    {
      word: "seven",
      phonetic: "/ˈsɛv.ən/",
      phonetics: [
        { text: "/ˈsɛv.ən/", audio: "" },
        {
          text: "/ˈsɛv.ən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/seven-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=537396",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The digit/figure 7 or an occurrence thereof.",
              synonyms: [],
              antonyms: [],
              example: "He wrote three sevens on the paper.",
            },
            {
              definition: "A card bearing seven pips.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "numeral",
          definitions: [
            {
              definition:
                "A numerical value equal to 7; the number following six and preceding eight. This many dots: (•••••••).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Describing a group or set with seven elements.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/seven"],
    },
  ],
  mouth: [
    {
      word: "mouth",
      phonetic: "/mʌʊθ/",
      phonetics: [
        { text: "/mʌʊθ/", audio: "" },
        {
          text: "/maʊθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mouth-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9023253",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/maʊθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mouth-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=398188",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The opening of a creature through which food is ingested.",
              synonyms: [],
              antonyms: [],
              example:
                "\"Open your mouth and say 'aah',\" directed the doctor.",
            },
            {
              definition:
                "The end of a river out of which water flows into a sea or other large body of water.",
              synonyms: [],
              antonyms: [],
              example:
                "The mouth of the river is a good place to go birdwatching in spring and autumn.",
            },
            {
              definition: "An outlet, aperture or orifice.",
              synonyms: [],
              antonyms: [],
              example: "The mouth of a cave",
            },
            {
              definition: "A loud or overly talkative person.",
              synonyms: [],
              antonyms: [],
              example: "My kid sister is a real mouth; she never shuts up.",
            },
            {
              definition:
                "(saddlery) The crosspiece of a bridle bit, which enters the mouth of an animal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A principal speaker; one who utters the common opinion; a mouthpiece.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Cry; voice.", synonyms: [], antonyms: [] },
            {
              definition: "Speech; language; testimony.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A wry face; a grimace; a mow.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To speak; to utter.",
              synonyms: [],
              antonyms: [],
              example: "He mouthed his opinions on the subject at the meeting.",
            },
            {
              definition:
                "To make the actions of speech, without producing sound.",
              synonyms: [],
              antonyms: [],
              example:
                "The prompter mouthed the words to the actor, who had forgotten them.",
            },
            {
              definition:
                "To utter with a voice that is overly loud or swelling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To pick up or handle with the lips or mouth, but not chew or swallow.",
              synonyms: [],
              antonyms: [],
              example: "The fish mouthed the lure, but didn't bite.",
            },
            {
              definition:
                "To take into the mouth; to seize or grind with the mouth or teeth; to chew; to devour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To form or cleanse with the mouth; to lick, as a bear licks her cub.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To make mouths at.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mouth"],
    },
  ],
  solve: [
    {
      word: "solve",
      phonetic: "/sɒlv/",
      phonetics: [
        {
          text: "/sɒlv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/solve-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88671370",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/sɑlv/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(chiefly law enforcement) A solution; an explanation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To find an answer or solution to a problem or question; to work out.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To find the values of variables that satisfy a system of equations and/or inequalities.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To algebraically manipulate an equation or inequality into a form that isolates a chosen variable on one side, so that the other side consists of an expression that may be used to generate solutions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To loosen or separate the parts of.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/solve"],
    },
  ],
  north: [
    {
      word: "north",
      phonetic: "/noːθ/",
      phonetics: [
        { text: "/noːθ/", audio: "" },
        { text: "/noːθ/", audio: "" },
        {
          text: "/nɔːθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/north-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9023281",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/noɹθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/north-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=872827",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One of the four major compass points, specifically 0°, directed toward the North Pole, and conventionally upwards on a map, abbreviated as N.",
              synonyms: [],
              antonyms: [],
              example: "Minnesota is in the north of the USA.",
            },
            {
              definition: "The up or positive direction.",
              synonyms: [],
              antonyms: [],
              example: "Stock prices are heading north.",
            },
            {
              definition:
                "The positive or north pole of a magnet, which seeks the magnetic pole near Earth's geographic North Pole (which, for its magnetic properties, is a south pole).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["south"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To turn or move toward the north.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of or pertaining to the north; northern.",
              synonyms: [],
              antonyms: [],
              example: "He lived in north Germany.",
            },
            {
              definition: "Toward the north; northward.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of wind, from the north.",
              synonyms: [],
              antonyms: [],
              example: "The north wind was cold.",
            },
            {
              definition:
                "Pertaining to the part of a corridor used by northbound traffic.",
              synonyms: [],
              antonyms: [],
              example: "north highway 1",
            },
            {
              definition: "More or greater than.",
              synonyms: [],
              antonyms: [],
              example: "The wedding ended up costing north of $50,000.",
            },
          ],
          synonyms: ["boreal"],
          antonyms: ["south"],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Toward the north; northward; northerly.",
              synonyms: [],
              antonyms: [],
              example: "Switzerland is north of Italy.",
            },
          ],
          synonyms: [],
          antonyms: ["south"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/north"],
    },
  ],
  value: [
    {
      word: "value",
      phonetic: "/ˈvæljuː/",
      phonetics: [
        {
          text: "/ˈvæljuː/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/value-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1197666",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The quality (positive or negative) that renders something desirable or valuable.",
              synonyms: ["worth"],
              antonyms: [],
              example:
                "The Shakespearean Shylock is of dubious value in the modern world.",
            },
            {
              definition: "The degree of importance given to something.",
              synonyms: [],
              antonyms: [],
              example:
                "The value of my children's happiness is second only to that of my wife.",
            },
            {
              definition:
                "That which is valued or highly esteemed, such as one's morals, morality, or belief system.",
              synonyms: [],
              antonyms: [],
              example: "He does not share his parents' values.",
            },
            {
              definition:
                "The amount (of money or goods or services) that is considered to be a fair equivalent for something else.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The relative duration of a musical note.",
              synonyms: [],
              antonyms: [],
              example: "The value of a crotchet is twice that of a quaver.",
            },
            {
              definition:
                "The relative darkness or lightness of a color in (a specific area of) a painting etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any definite numerical quantity or other mathematical object, determined by being measured, computed, or otherwise defined.",
              synonyms: [],
              antonyms: [],
              example:
                "The exact value of pi cannot be represented in decimal notation.",
            },
            {
              definition: "Precise meaning; import.",
              synonyms: [],
              antonyms: [],
              example: "the value of a word; the value of a legal instrument",
            },
            {
              definition:
                "(in the plural) The valuable ingredients to be obtained by treating a mass or compound; specifically, the precious metals contained in rock, gravel, etc.",
              synonyms: [],
              antonyms: [],
              example: "The vein carries good values.",
            },
            { definition: "Esteem; regard.", synonyms: [], antonyms: [] },
            {
              definition: "Valour; also spelled valew.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["valence", "worth"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To estimate the value of; judge the worth of something.",
              synonyms: [],
              antonyms: [],
              example:
                "I will have the family jewels valued by a professional.",
            },
            {
              definition:
                "To fix or determine the value of; assign a value to, as of jewelry or art work.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To regard highly; think much of; place importance upon.",
              synonyms: [],
              antonyms: [],
              example: "Gold was valued highly among the Romans.",
            },
            {
              definition: "To hold dear.",
              synonyms: [],
              antonyms: [],
              example: "I value these old photographs.",
            },
          ],
          synonyms: [
            "appreciate",
            "assess",
            "esteem",
            "prize",
            "rate",
            "respect",
            "treasure",
            "valuate",
            "worthen",
          ],
          antonyms: [
            "belittle",
            "derogate",
            "despise",
            "disesteem",
            "disrespect",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/value"],
    },
  ],
  death: [
    {
      word: "death",
      phonetic: "/diːθ/",
      phonetics: [
        {
          text: "/diːθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/death-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75858836",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/diːθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/death-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9014361",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/diːθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/death-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=854598",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The cessation of life and all associated processes; the end of an organism's existence as an entity independent from its environment and its return to an inert, nonliving state.",
              synonyms: [],
              antonyms: [],
              example: "The death of my grandmother saddened the whole family.",
            },
            {
              definition:
                "(often capitalized) The personification of death as a hooded figure with a scythe; the Grim Reaper. The pronoun he is not the only option, but probably the most traditional one, as it matches with the male grammatical gender of Old English dēaþ, also with cognate German der Tod. The fourth apocalyptic rider (Bible, revelations 6:8) is male θᾰ́νᾰτος (thanatos) in Greek. It has the female name Mors in Latin, but is referred to with male forms qui and eum. The following quotes show this rider on a pale horse is his in the English Bible and she in Peter Gabriel's lyrics.",
              synonyms: [],
              antonyms: [],
              example: "When death walked in, a chill spread through the room.",
            },
            {
              definition: "(the death) The collapse or end of something.",
              synonyms: [],
              antonyms: [],
              example:
                "England scored a goal at the death to even the score at one all.",
            },
            {
              definition: "Spiritual lifelessness.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/death"],
    },
  ],
  maybe: [
    {
      word: "maybe",
      phonetic: "/ˈmeɪbi/",
      phonetics: [
        {
          text: "/ˈmeɪbi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/maybe-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9023226",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ˈmeɪbi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/maybe-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1217969",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Something that is possibly true.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An answer that shows neither agreement nor disagreement.",
              synonyms: [],
              antonyms: [],
              example:
                "The results of the poll were inconclusive. We got two yeses, three nos, and four maybes.",
            },
            {
              definition: "A future event that may or may not happen.",
              synonyms: [],
              antonyms: [],
              example: "About your raise: it's a big maybe.",
            },
          ],
          synonyms: ["perhaps"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Possible; uncertain.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition:
                "Modifies a verb, indicating a lack of certainty: it may be that...",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(as a pro-sentence) Perhaps that is true (expressing no commitment to a decision or a neutral viewpoint to a statement).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "could be",
            "mayhaps",
            "might be",
            "perhaps",
            "possible",
            "possibly",
            "mayhaps",
            "peradventure",
            "perhaps",
            "possibly",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/maybe"],
    },
  ],
  happy: [
    {
      word: "happy",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/happy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75797241",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈhæpiː/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/happy-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9021973",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ˈhæpi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/happy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=545617",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A happy event, thing, person, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Preceded by the: happy people as a group.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "Often followed by up: to become happy; to brighten up, to cheer up.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Often followed by up: to make happy; to brighten, to cheer, to enliven.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["happify"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Having a feeling arising from a consciousness of well-being or of enjoyment; enjoying good of any kind, such as comfort, peace, or tranquillity; blissful, contented, joyous.",
              synonyms: [],
              antonyms: [],
              example: "Music makes me feel happy.",
            },
            {
              definition:
                "Experiencing the effect of favourable fortune; favored by fortune or luck; fortunate, lucky, propitious.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Content, satisfied (with or to do something); having no objection (to something).",
              synonyms: [],
              antonyms: [],
              example: "Are you happy to pay me back by the end of the week?",
            },
            {
              definition:
                "(Of acts, speech, etc.) Appropriate, apt, felicitous.",
              synonyms: [],
              antonyms: [],
              example: "a happy coincidence",
            },
            {
              definition: "(in combination) Favoring or inclined to use.",
              synonyms: [],
              antonyms: [],
              example: "slaphappy, trigger-happy",
            },
            {
              definition:
                '(of people, often followed by "at" or "in") Dexterous, ready, skilful.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "cheerful",
            "content",
            "delighted",
            "elated",
            "exultant",
            "glad",
            "joyful",
            "jubilant",
            "merry",
            "orgasmic",
            "fortunate",
            "lucky",
            "propitious",
          ],
          antonyms: [
            "inappropriate",
            "inapt",
            "unfelicitous",
            "disenchanted",
            "dissatisfied",
            "blue",
            "depressed",
            "down",
            "miserable",
            "moody",
            "morose",
            "sad",
            "unhappy",
            "unfortunate",
            "unlucky",
            "unpropitious",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/happy"],
    },
  ],
  tells: [
    {
      word: "tells",
      phonetic: "/tɛlz/",
      phonetics: [
        {
          text: "/tɛlz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tells-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1661717",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A reflexive, often habitual behavior, especially one occurring in a context that often features attempts at deception by persons under psychological stress (such as a poker game or police interrogation), that reveals information that the person exhibiting the behavior is attempting to withhold.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "That which is told; a tale or account.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A private message to an individual in a chat room; a whisper.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(archaic outside of idioms) To count, reckon, or enumerate.",
              synonyms: [],
              antonyms: [],
              example:
                "All told, there were over a dozen.  Can you tell time on a clock?  He had untold wealth.",
            },
            {
              definition: "To narrate.",
              synonyms: [],
              antonyms: [],
              example: "I want to tell a story;  I want to tell you a story.",
            },
            {
              definition: "To convey by speech; to say.",
              synonyms: [],
              antonyms: [],
              example:
                "Finally, someone told him the truth.  He seems to like to tell lies.",
            },
            {
              definition: "To instruct or inform.",
              synonyms: [],
              antonyms: [],
              example: "Please tell me how to do it.",
            },
            {
              definition: "To order; to direct, to say to someone.",
              synonyms: [],
              antonyms: [],
              example: "Tell him to go away.",
            },
            {
              definition: "To discern, notice, identify or distinguish.",
              synonyms: [],
              antonyms: [],
              example:
                "Can you tell whether those flowers are real or silk, from this distance?  No, there's no way to tell.",
            },
            {
              definition: "To reveal.",
              synonyms: [],
              antonyms: [],
              example: "Time will tell what became of him.",
            },
            { definition: "To be revealed.", synonyms: [], antonyms: [] },
            {
              definition:
                "To have an effect, especially a noticeable one; to be apparent, to be demonstrated.",
              synonyms: [],
              antonyms: [],
              example:
                "Sir Gerald was moving slower; his wounds were beginning to tell.",
            },
            {
              definition:
                "To use (beads or similar objects) as an aid to prayer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To inform someone in authority about a wrongdoing.",
              synonyms: [],
              antonyms: [],
              example: "I saw you steal those sweets! I'm going to tell!",
            },
            {
              definition:
                "(authorship) To reveal information in prose through outright expository statement -- contrasted with show",
              synonyms: [],
              antonyms: [],
              example:
                "Maria rewrote the section of her novel that talked about Meg and Sage's friendship to have less telling and more showing.",
            },
          ],
          synonyms: [
            "count",
            "number",
            "grass up",
            "snitch",
            "tattle",
            "narrate",
            "recount",
            "relate",
            "disclose",
            "make known",
            "advise",
            "apprise",
          ],
          antonyms: ["ask"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A hill or mound, originally and especially in the Middle East, over or consisting of the ruins of ancient settlements.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tell",
        "https://en.wiktionary.org/wiki/tells",
      ],
    },
  ],
  gives: [
    {
      word: "gives",
      phonetic: "/ɡɪvz/",
      phonetics: [
        {
          text: "/ɡɪvz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gives-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1241412",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(ditransitive) To move, shift, provide something abstract or concrete to someone or something or somewhere.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(ditransitive) To estimate or predict (a duration or probability) for (something).",
              synonyms: [],
              antonyms: [],
              example: "I give it a 95% chance of success.",
            },
            {
              definition: "To yield slightly when a force is applied.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To collapse under pressure or force.",
              synonyms: [],
              antonyms: [],
              example:
                "One pillar gave, then more, and suddenly the whole floor pancaked onto the floor below.",
            },
            {
              definition: "To provide, as, a service or a broadcast.",
              synonyms: [],
              antonyms: [],
              example: "They're giving my favorite show!",
            },
            {
              definition: "To lead (onto or into).",
              synonyms: [],
              antonyms: [],
              example: "The master bedroom gives onto a spacious balcony.",
            },
            {
              definition: "To provide a view of.",
              synonyms: [],
              antonyms: [],
              example: "His window gave the park.",
            },
            {
              definition:
                "To exhibit as a product or result; to produce; to yield.",
              synonyms: [],
              antonyms: [],
              example:
                "The number of men, divided by the number of ships, gives four hundred to each ship.",
            },
            {
              definition: "To cause; to make; used with the infinitive.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause (someone) to have; produce in (someone); effectuate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To allow or admit by way of supposition; to concede.",
              synonyms: [],
              antonyms: [],
              example:
                "He can be bad-tempered, I'll give you that, but he's a hard worker.",
            },
            {
              definition: "To attribute; to assign; to adjudge.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To communicate or announce (advice, tidings, etc.); to pronounce or utter (an opinion, a judgment, a shout, etc.).",
              synonyms: [],
              antonyms: [],
              example:
                "The umpire finally gave his decision: the ball was out.",
            },
            {
              definition: "To grant power or permission to; to allow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To devote or apply (oneself).",
              synonyms: [],
              antonyms: [],
              example: "That boy is given to fits of bad temper.",
            },
            {
              definition: "To become soft or moist.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To shed tears; to weep.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To have a misgiving.", synonyms: [], antonyms: [] },
            {
              definition: "To be going on, to be occurring",
              synonyms: [],
              antonyms: [],
              example: "What gives?",
            },
          ],
          synonyms: [
            "bend",
            "cede",
            "flex",
            "move",
            "yield",
            "estimate",
            "guess",
            "predict",
          ],
          antonyms: ["resist", "get", "obtain", "receive", "take"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/give",
        "https://en.wiktionary.org/wiki/gives",
      ],
    },
    {
      word: "gives",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A shackle or fetter, especially for the leg.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/give",
        "https://en.wiktionary.org/wiki/gives",
        "https://en.wiktionary.org/wiki/gyve",
      ],
    },
  ],
  looks: [
    {
      word: "looks",
      phonetic: "/lʊks/",
      phonetics: [
        {
          text: "/lʊks/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/looks-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1224259",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One's appearance or attractiveness.",
              synonyms: [],
              antonyms: [],
              example:
                "His charm and good looks accounted for much of his popularity in the polls.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/looks"],
    },
  ],
  shape: [
    {
      word: "shape",
      phonetic: "/ʃeɪp/",
      phonetics: [
        {
          text: "/ʃeɪp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shape-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1661702",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The status or condition of something",
              synonyms: [],
              antonyms: [],
              example:
                "The used bookshop wouldn't offer much due to the poor shape of the book.",
            },
            {
              definition:
                "Condition of personal health, especially muscular health.",
              synonyms: [],
              antonyms: [],
              example:
                "The vet checked to see what kind of shape the animal was in.",
            },
            {
              definition:
                "The appearance of something in terms of its arrangement in space, especially its outline; often a basic geometric two-dimensional figure.",
              synonyms: [],
              antonyms: [],
              example: "He cut a square shape out of the cake.",
            },
            { definition: "Form; formation.", synonyms: [], antonyms: [] },
            {
              definition:
                "(iron manufacture) A rolled or hammered piece, such as a bar, beam, angle iron, etc., having a cross section different from merchant bar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(iron manufacture) A piece which has been roughly forged nearly to the form it will receive when completely forged or fitted.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A mould for making jelly, blancmange etc., or a piece of such food formed moulded into a particular shape.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In the Hack programming language, a group of data fields each of which has a name and a data type.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To create or make.",
              synonyms: [],
              antonyms: [],
              example: "Earth was shapen by God for God's folk.",
            },
            {
              definition: "To give something a shape and definition.",
              synonyms: [],
              antonyms: [],
              example:
                "Shape the dough into a pretzel.   For my art project, I plan to shape my clay lump into a bowl.",
            },
            {
              definition:
                "To form or manipulate something into a certain shape.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of a country, person, etc) To give influence to.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To suit; to be adjusted or conformable.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To imagine; to conceive.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["form", "mold"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shape"],
    },
  ],
  lives: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  steps: [
    {
      word: "steps",
      phonetic: "/stɛps/",
      phonetics: [
        {
          text: "/stɛps/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/steps-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1239934",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An advance or movement made from one foot to the other; a pace.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A rest, or one of a set of rests, for the foot in ascending or descending, as a stair, or a rung of a ladder.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A distinct part of a process; stage; phase.",
              synonyms: [],
              antonyms: [],
              example: "He improved step by step, or by steps.",
            },
            {
              definition:
                "A running board where passengers step to get on and off the bus.",
              synonyms: [],
              antonyms: [],
              example:
                "The driver must have a clear view of the step in order to prevent accidents.",
            },
            {
              definition:
                "The space passed over by one movement of the foot in walking or running.",
              synonyms: [],
              antonyms: [],
              example:
                "One step is generally about three feet, but may be more or less.",
            },
            {
              definition: "A small space or distance.",
              synonyms: [],
              antonyms: [],
              example: "It is but a step.",
            },
            {
              definition:
                "A print of the foot; a footstep; a footprint; track.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A gait; manner of walking.",
              synonyms: [],
              antonyms: [],
              example: "The approach of a man is often known by his step.",
            },
            {
              definition: "Proceeding; measure; action; act.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(plural) A walk; passage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(plural) A portable framework of stairs, much used indoors in reaching to a high position.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A framing in wood or iron which is intended to receive an upright shaft; specifically, a block of wood, or a solid platform upon the keelson, supporting the heel of the mast.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(machines) One of a series of offsets, or parts, resembling the steps of stairs, as one of the series of parts of a cone pulley on which the belt runs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(machines) A bearing in which the lower extremity of a spindle or a vertical shaft revolves.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The interval between two contiguous degrees of the scale.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(kinematics) A change of position effected by a motion of translation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A constant difference between consecutive values in a series.",
              synonyms: [],
              antonyms: [],
              example:
                "Printing from 0 to 9 with a step of 3 will display 0, 3, 6 and 9.",
            },
            { definition: "A stepsibling.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["stride"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To move the foot in walking; to advance or recede by raising and moving one of the feet to another resting place, or by moving both feet in succession.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To walk; to go on foot; especially, to walk a little distance.",
              synonyms: [],
              antonyms: [],
              example: "to step to one of the neighbors",
            },
            {
              definition: "To walk slowly, gravely, or resolutely.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To move mentally; to go in imagination.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To set, as the foot.", synonyms: [], antonyms: [] },
            {
              definition: "To fix the foot of (a mast) in its step; to erect.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/step",
        "https://en.wiktionary.org/wiki/steps",
      ],
    },
  ],
  areas: [
    {
      word: "areas",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/areas-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453376",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A measure of the extent of a surface; it is measured in square units.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A particular geographic region.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any particular extent of surface, especially an empty or unused extent.",
              synonyms: [],
              antonyms: [],
              example: "The photo is a little dark in that area.",
            },
            {
              definition:
                "The extent, scope, or range of an object or concept.",
              synonyms: [],
              antonyms: [],
              example: "The plans are a bit vague in that area.",
            },
            {
              definition:
                "An open space, below ground level, between the front of a house and the pavement.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Penalty box; penalty area.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Genitals.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/area",
        "https://en.wiktionary.org/wiki/areas",
      ],
    },
  ],
  sense: [
    {
      word: "sense",
      phonetic: "/sɛn(t)s/",
      phonetics: [
        {
          text: "/sɛn(t)s/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sense-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88517663",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/sɛns/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sense-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=825515",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of the manners by which living beings perceive the physical world: for humans sight, smell, hearing, touch, taste.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Perception through the intellect; apprehension; awareness.",
              synonyms: [],
              antonyms: [],
              example: "a sense of security",
            },
            {
              definition: "Sound practical or moral judgment.",
              synonyms: [],
              antonyms: [],
              example:
                "It's common sense not to put metal objects in a microwave oven.",
            },
            {
              definition: "The meaning, reason, or value of something.",
              synonyms: [],
              antonyms: [],
              example: "You don’t make any sense.",
            },
            {
              definition: "A natural appreciation or ability.",
              synonyms: [],
              antonyms: [],
              example: "A keen musical sense",
            },
            {
              definition: "The way that a referent is presented.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A single conventional use of a word; one of the entries for a word in a dictionary.",
              synonyms: [],
              antonyms: [],
              example:
                "The definition of sense in this context, is given in sense 7 of its definition.",
            },
            {
              definition:
                "One of two opposite directions in which a vector (especially of motion) may point. See also polarity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of two opposite directions of rotation, clockwise versus anti-clockwise.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Referring to the strand of a nucleic acid that directly specifies the product.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To use biological senses: to either see, hear, smell, taste, or feel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To instinctively be aware.",
              synonyms: [],
              antonyms: [],
              example: "She immediately sensed her disdain.",
            },
            { definition: "To comprehend.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sense"],
    },
  ],
  speak: [
    {
      word: "speak",
      phonetic: "/spiːk/",
      phonetics: [
        {
          text: "/spiːk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/speak-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9028733",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/spik/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/speak-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=360849",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A low class bar, a speakeasy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Language, jargon, or terminology used uniquely in a particular environment or group.",
              synonyms: [],
              antonyms: [],
              example: "Corporate speak; IT speak.",
            },
            { definition: "Speech, conversation.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To communicate with one's voice, to say words out loud.",
              synonyms: [],
              antonyms: [],
              example: "I was so surprised I couldn't speak.",
            },
            {
              definition: "To have a conversation.",
              synonyms: [],
              antonyms: [],
              example: "It's been ages since we've spoken.",
            },
            {
              definition:
                "(by extension) To communicate or converse by some means other than orally, such as writing or facial expressions.",
              synonyms: [],
              antonyms: [],
              example: "Actions speak louder than words.",
            },
            {
              definition:
                "To deliver a message to a group; to deliver a speech.",
              synonyms: [],
              antonyms: [],
              example:
                "This evening I shall speak on the topic of correct English usage.",
            },
            {
              definition: "To be able to communicate in a language.",
              synonyms: [],
              antonyms: [],
              example: "He speaks Mandarin fluently.",
            },
            {
              definition: "To utter.",
              synonyms: [],
              antonyms: [],
              example: "I was so surprised that I couldn't speak a word.",
            },
            {
              definition:
                "To communicate (some fact or feeling); to bespeak, to indicate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(sometimes humorous) To understand (as though it were a language).",
              synonyms: [],
              antonyms: [],
              example: "So you can program in C. But do you speak C++?",
            },
            {
              definition: "To produce a sound; to sound.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of a bird, to be able to vocally reproduce words or phrases from a human language.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To address; to accost; to speak to.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["articulate", "talk", "verbalize"],
          antonyms: ["be silent"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/speak"],
    },
  ],
  force: [
    {
      word: "force",
      phonetic: "/fɔːs/",
      phonetics: [
        { text: "/fɔːs/", audio: "" },
        {
          text: "/fɔɹs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/force-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=857054",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Strength or energy of body or mind; active power; vigour; might; capacity of exercising an influence or producing an effect.",
              synonyms: [],
              antonyms: [],
              example: "the force of an appeal, an argument, or a contract",
            },
            {
              definition:
                "Power exerted against will or consent; compulsory power; violence; coercion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Anything that is able to make a substantial change in a person or thing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A physical quantity that denotes ability to push, pull, twist or accelerate a body and which has a direction and is measured in a unit dimensioned in mass × distance/time² (ML/T²): SI: newton (N); CGS: dyne (dyn)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something or anything that has the power to produce a physical effect upon something else, such as causing it to move or change shape.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A group that aims to attack, control, or constrain.",
              synonyms: [],
              antonyms: [],
              example: "police force",
            },
            {
              definition: "The ability to attack, control, or constrain.",
              synonyms: [],
              antonyms: [],
              example: "show of force",
            },
            {
              definition:
                "A magic trick in which the outcome is known to the magician beforehand, especially one involving the apparent free choice of a card by another person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Legal validity.",
              synonyms: [],
              antonyms: [],
              example: "The law will come into force in January.",
            },
            {
              definition:
                'Either unlawful violence, as in a "forced entry", or lawful compulsion.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Ability of an utterance or its element (word, form, prosody, ...) to effect a given meaning.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(with the, often capitalized) A metaphysical and ubiquitous power from the fictional Star Wars universe created by George Lucas. See usage note.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To violate (a woman); to rape.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To exert oneself, to do one's utmost.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To compel (someone or something) to do something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To constrain by force; to overcome the limitations or resistance of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To drive (something) by force, to propel (generally + prepositional phrase or adverb).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause to occur (despite inertia, resistance etc.); to produce through force.",
              synonyms: [],
              antonyms: [],
              example:
                "The comedian's jokes weren't funny, but I forced a laugh now and then.",
            },
            {
              definition: "To forcibly open (a door, lock etc.).",
              synonyms: [],
              antonyms: [],
              example: "To force a lock.",
            },
            {
              definition:
                "To obtain or win by strength; to take by violence or struggle; specifically, to capture by assault; to storm, as a fortress.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To create an out by touching a base in advance of a runner who has no base to return to while in possession of a ball which has already touched the ground.",
              synonyms: [],
              antonyms: [],
              example:
                "Jones forced the runner at second by stepping on the bag.",
            },
            {
              definition:
                "(whist) To compel (an adversary or partner) to trump a trick by leading a suit that he/she does not hold.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To put in force; to cause to be executed; to make binding; to enforce.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To provide with forces; to reinforce; to strengthen by soldiers; to man; to garrison.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To allow the force of; to value; to care for.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(law enforcement) Any police organization; a constabulary.",
              synonyms: ["police", "police department", "police service"],
              antonyms: [],
              example:
                "He joined the police force a long time ago, when he lived in Virginia.",
            },
          ],
          synonyms: ["police", "police department", "police service"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/force",
        "https://en.wiktionary.org/wiki/police%20force",
      ],
    },
    {
      word: "force",
      phonetic: "/fɔːs/",
      phonetics: [
        { text: "/fɔːs/", audio: "" },
        {
          text: "/fɔɹs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/force-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=857054",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A waterfall or cascade.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/force"],
    },
    {
      word: "force",
      phonetic: "/fɔːs/",
      phonetics: [
        { text: "/fɔːs/", audio: "" },
        {
          text: "/fɔɹs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/force-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=857054",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To stuff; to lard; to farce.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/force"],
    },
  ],
  ocean: [
    {
      word: "ocean",
      phonetic: "/ˈəʊ.ʃən/",
      phonetics: [
        { text: "/ˈəʊ.ʃən/", audio: "" },
        {
          text: "/ˈoʊ.ʃən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ocean-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1684682",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One of the large bodies of water separating the continents.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Water belonging to an ocean.",
              synonyms: [],
              antonyms: [],
              example: "The island is surrounded by ocean",
            },
            {
              definition:
                "An immense expanse; any vast space or quantity without apparent limits.",
              synonyms: [],
              antonyms: [],
              example: "an ocean of affairs",
            },
            {
              definition:
                "A blue colour, like that of the ocean (also called ocean blue).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["ogin"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ocean"],
    },
  ],
  speed: [
    {
      word: "speed",
      phonetic: "/spiːd/",
      phonetics: [
        {
          text: "/spiːd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/speed-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1676865",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The state of moving quickly or the capacity for rapid motion; rapidity.",
              synonyms: [],
              antonyms: [],
              example: "How does Usain Bolt run at that speed?",
            },
            {
              definition:
                "The rate of motion or action, specifically / the magnitude of the velocity; the rate distance is traversed in a given time.",
              synonyms: [],
              antonyms: [],
              example:
                "Speed limits provide information to the drivers about the safe speed to travel in average conditions.",
            },
            {
              definition: "The sensitivity to light of film, plates or sensor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The duration of exposure, the time during which a camera shutter is open (shutter speed).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The largest size of the lens opening at which a lens can be used.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The ratio of the focal length to the diameter of a photographic objective.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Amphetamine or any amphetamine-based drug (especially methamphetamine) used as a stimulant, especially illegally.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Luck, success, prosperity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Personal preference.",
              synonyms: [],
              antonyms: [],
              example:
                "We could go to the shore next week, or somewhere else if that's not your speed.",
            },
            {
              definition:
                "A third-order measure of derivative price sensitivity, expressed as the rate of change of gamma with respect to changes in the underlying asset price.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "DgammaDspot",
            "gamma of the gamma",
            "celerity",
            "velocity",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/speed"],
    },
    {
      word: "speed",
      phonetic: "/spiːd/",
      phonetics: [
        {
          text: "/spiːd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/speed-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1676865",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To succeed; to prosper, be lucky.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To help someone, to give them fortune; to aid or favour.",
              synonyms: [],
              antonyms: [],
              example: "God speed, until we meet again.",
            },
            {
              definition: "To go fast.",
              synonyms: [],
              antonyms: [],
              example: "The Ferrari was speeding along the road.",
            },
            {
              definition: "To exceed the speed limit.",
              synonyms: [],
              antonyms: [],
              example: "Why do you speed when the road is so icy?",
            },
            {
              definition: "To increase the rate at which something occurs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be under the influence of stimulant drugs, especially amphetamines.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To be expedient.", synonyms: [], antonyms: [] },
            {
              definition: "To hurry to destruction; to put an end to; to ruin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To wish success or good fortune to, in any undertaking, especially in setting out upon a journey.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause to make haste; to dispatch with celerity; to drive at full speed; hence, to hasten; to hurry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To hasten to a conclusion; to expedite.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/speed"],
    },
  ],
  women: [
    {
      word: "women",
      phonetic: "/ˈwɪmən/",
      phonetics: [
        {
          text: "/ˈwɪmən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/women-ca.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=54916442",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
        { text: "/ˈwɘmɘn/", audio: "" },
        {
          text: "/ˈwɪmən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/women-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=508120",
        },
        { text: "/ˈwɪmən/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An adult female human.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(collective) All females collectively; womankind.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A female person, usually an adult; a (generally adult) female sentient being, whether human, supernatural, elf, alien, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A wife (or sometimes a fiancée or girlfriend).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A female who is extremely fond of or devoted to a specified type of thing. (Used as the last element of a compound.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A female attendant or servant.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["female", "lady"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/woman",
        "https://en.wiktionary.org/wiki/women",
      ],
    },
  ],
  metal: [
    {
      word: "metal",
      phonetic: "/ˈmɛtəl/",
      phonetics: [
        {
          text: "/ˈmɛtəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/metal-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=882797",
        },
        {
          text: "[ˈmɛ.ɾɫ̩]",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/metal-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=879883",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(heading) Chemical elements or alloys, and the mines where their ores come from.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A light tincture used in a coat of arms, specifically argent and or.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Molten glass that is to be blown or moulded to form objects.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A category of rock music encompassing a number of genres (including thrash metal, death metal, heavy metal, etc.) characterized by strong drum-beats and distorted guitars.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The substance that constitutes something or someone; matter; hence, character or temper; mettle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The effective power or calibre of guns carried by a vessel of war.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(in the plural) The rails of a railway.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(travel) The actual airline operating a flight, rather than any of the codeshare operators.",
              synonyms: [],
              antonyms: [],
              example:
                "We have American Airlines tickets, but it's on British Airways metal.",
            },
          ],
          synonyms: [],
          antonyms: ["nonmetal"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make a road using crushed rock, stones etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Characterized by strong drum-beats and distorted guitars.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Having the emotional or social characteristics associated with metal music; brash, bold, frank, unyielding, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/metal"],
    },
  ],
  south: [
    {
      word: "south",
      phonetic: "/sæoθ/",
      phonetics: [
        { text: "/sæoθ/", audio: "" },
        { text: "/sʌʊθ/", audio: "" },
        { text: "/sæoθ/", audio: "" },
        {
          text: "/saʊθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/south-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9027486",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/saʊθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/south-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=872830",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One of the four major compass points, specifically 180°, directed toward the South Pole, and conventionally downwards on a map, abbreviated as S.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The side of a church on the right hand of a person facing the altar.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To turn or move toward the south; to veer toward the south.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To come to the meridian; to cross the north and south line.",
              synonyms: [],
              antonyms: [],
              example: "The moon souths at nine.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Toward the south; southward.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of wind) from the south.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of or pertaining to the south; southern.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Pertaining to the part of a corridor used by southbound traffic.",
              synonyms: [],
              antonyms: [],
              example: "south highway 1",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Toward the south; southward.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Downward.", synonyms: [], antonyms: [] },
            {
              definition: "In an adverse direction or trend (go south).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of wind, from the south.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/south"],
    },
  ],
  grass: [
    {
      word: "grass",
      phonetic: "[ɡɹ̠äːs]",
      phonetics: [
        { text: "[ɡɹ̠äːs]", audio: "" },
        { text: "[ɡɹ̠eəs]", audio: "" },
        { text: "[ɡɹ̠as]", audio: "" },
        { text: "[ɡɹ̠as]", audio: "" },
        { text: "[ɡɹ̠äːs]", audio: "" },
        {
          text: "[ɡɹ̠ɑːs]",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grass-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=491576",
        },
        {
          text: "[ɡɹ̠eəs]",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grass-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=862128",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any plant of the family Poaceae, characterized by leaves that arise from nodes in the stem and leaf bases that wrap around the stem, especially those grown as ground cover rather than for grain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Various plants not in family Poaceae that resemble grasses.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A lawn.", synonyms: [], antonyms: [] },
            { definition: "Marijuana.", synonyms: [], antonyms: [] },
            {
              definition:
                "An informer, police informer; one who betrays a group (of criminals, etc) to the authorities.",
              synonyms: [],
              antonyms: [],
              example:
                "What just happened must remain secret. Don't be a 'grass.",
            },
            {
              definition:
                "Sharp, closely spaced discontinuities in the trace of a cathode-ray tube, produced by random interference.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Noise on an A-scope or similar type of radar display.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The season of fresh grass; spring or summer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "That which is transitory.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: '(folk etymology) Asparagus; "sparrowgrass".',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The surface of a mine.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["breakup", "spring", "springtime", "ephemera"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To lay out on the grass; to knock down (an opponent etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To act as a grass or informer, to betray; to report on (criminals etc) to the authorities.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cover with grass or with turf.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To feed with grass.", synonyms: [], antonyms: [] },
            {
              definition:
                "To expose, as flax, on the grass for bleaching, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To bring to the grass or ground; to land.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "flatten",
            "floor",
            "knock down",
            "knock out",
            "knock over",
            "lay low",
            "lay out",
            "strike down",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/grass"],
    },
  ],
  scale: [
    {
      word: "scale",
      phonetic: "/skeɪl/",
      phonetics: [
        {
          text: "/skeɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scale-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75857666",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/skeɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scale-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1157602",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A ladder; a series of steps; a means of ascending.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An ordered, usually numerical sequence used for measurement, means of assigning a magnitude.",
              synonyms: [],
              antonyms: [],
              example: "Please rate your experience on a scale from 1 to 10.",
            },
            {
              definition: "Size; scope.",
              synonyms: [],
              antonyms: [],
              example: "The Holocaust was insanity on an enormous scale.",
            },
            {
              definition: "The ratio of depicted distance to actual distance.",
              synonyms: [],
              antonyms: [],
              example: "This map uses a scale of 1:10.",
            },
            {
              definition:
                "A line or bar associated with a drawing, used to indicate measurement when the image has been magnified or reduced.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A series of notes spanning an octave, tritave, or pseudo-octave, used to make melodies.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A mathematical base for a numeral system; radix.",
              synonyms: [],
              antonyms: [],
              example: "the decimal scale; the binary scale",
            },
            {
              definition:
                "Gradation; succession of ascending and descending steps and degrees; progressive series; scheme of comparative rank or order.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A standard amount of money to be received by a performer or writer, negotiated by a union.",
              synonyms: [],
              antonyms: [],
              example:
                "Sally wasn't the star of the show, so she was glad to be paid scale.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To change the size of something whilst maintaining proportion; especially to change a process in order to produce much larger amounts of the final product.",
              synonyms: [],
              antonyms: [],
              example: "We should scale that up by a factor of 10.",
            },
            {
              definition: "To climb to the top of.",
              synonyms: [],
              antonyms: [],
              example:
                "Hilary and Norgay were the first known to have scaled Everest.",
            },
            {
              definition:
                "To tolerate significant increases in throughput or other potentially limiting factors.",
              synonyms: [],
              antonyms: [],
              example:
                "That architecture won't scale to real-world environments.",
            },
            {
              definition:
                "To weigh, measure or grade according to a scale or system.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scale"],
    },
    {
      word: "scale",
      phonetic: "/skeɪl/",
      phonetics: [
        {
          text: "/skeɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scale-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75857666",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/skeɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scale-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1157602",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Part of an overlapping arrangement of many small, flat and hard pieces of keratin covering the skin of an animal, particularly a fish or reptile.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small piece of pigmented chitin, many of which coat the wings of a butterfly or moth to give them their color.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A flake of skin of an animal afflicted with dermatitis.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Part of an overlapping arrangement of many small, flat and hard protective layers forming a pinecone that flare when mature to release pine nut seeds.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The flaky material sloughed off heated metal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Scale mail (as opposed to chain mail).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Limescale.", synonyms: [], antonyms: [] },
            { definition: "A scale insect.", synonyms: [], antonyms: [] },
            {
              definition:
                "The thin metallic side plate of the handle of a pocketknife.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To remove the scales of.",
              synonyms: ["descale"],
              antonyms: [],
              example: "Please scale that fish for dinner.",
            },
            {
              definition: "To become scaly; to produce or develop scales.",
              synonyms: [],
              antonyms: [],
              example: "The dry weather is making my skin scale.",
            },
            {
              definition: "To strip or clear of scale; to descale.",
              synonyms: [],
              antonyms: [],
              example: "to scale the inside of a boiler",
            },
            {
              definition:
                "To take off in thin layers or scales, as tartar from the teeth; to pare off, as a surface.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To separate and come off in thin layers or laminae.",
              synonyms: [],
              antonyms: [],
              example: "Some sandstone scales by exposure.",
            },
            {
              definition: "To scatter; to spread.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To clean, as the inside of a cannon, by the explosion of a small quantity of powder.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["descale"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scale"],
    },
    {
      word: "scale",
      phonetic: "/skeɪl/",
      phonetics: [
        {
          text: "/skeɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scale-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75857666",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/skeɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scale-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1157602",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A device to measure mass or weight.",
              synonyms: [],
              antonyms: [],
              example:
                "After the long, lazy winter I was afraid to get on the scale.",
            },
            {
              definition:
                "Either of the pans, trays, or dishes of a balance or scales.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scale"],
    },
  ],
  cells: [
    {
      word: "cells",
      phonetic: "/sɛlz/",
      phonetics: [{ text: "/sɛlz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A single-room dwelling for a hermit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small monastery or nunnery dependent on a larger religious establishment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small room in a monastery or nunnery accommodating one person.",
              synonyms: [],
              antonyms: [],
              example:
                "Gregor Mendel must have spent a good amount of time outside of his cell.",
            },
            {
              definition: "A room in a prison or jail for one or more inmates.",
              synonyms: ["prison cell"],
              antonyms: [],
              example: "The combatants spent the night in separate cells.",
            },
            {
              definition:
                "Each of the small hexagonal compartments in a honeycomb.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various chambers in a tissue or organism having specific functions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The discal cell of the wing of a lepidopteran insect.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Specifically, any of the supposed compartments of the brain, formerly thought to be the source of specific mental capacities, knowledge, or memories.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A section or compartment of a larger structure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any small dwelling; a remote nook, a den.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A device which stores electrical power; used either singly or together in batteries; the basic unit of a battery.",
              synonyms: [],
              antonyms: [],
              example: "This MP3 player runs on 2 AAA cells.",
            },
            {
              definition:
                "The basic unit of a living organism, consisting of a quantity of protoplasm surrounded by a cell membrane, which is able to synthesize proteins and replicate itself.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small thunderstorm, caused by convection, that forms ahead of a storm front.",
              synonyms: [],
              antonyms: [],
              example: "There is a powerful storm cell headed our way.",
            },
            {
              definition:
                "The minimal unit of a cellular automaton that can change state and has an associated behavior.",
              synonyms: [],
              antonyms: [],
              example:
                "The upper right cell always starts with the color green.",
            },
            {
              definition:
                "In FreeCell-type games, a space where one card can be placed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small group of people forming part of a larger organization, often an outlawed one.",
              synonyms: [],
              antonyms: [],
              example:
                "Those three fellows are the local cell of that organization.",
            },
            {
              definition:
                "(communication) A short, fixed-length packet as in asynchronous transfer mode.",
              synonyms: [],
              antonyms: [],
              example: "Virtual Channel number 5 received 170 cells.",
            },
            {
              definition:
                "(communication) A region of radio reception that is a part of a larger radio network.",
              synonyms: [],
              antonyms: [],
              example:
                "I get good reception in my home because it is near a cell tower.",
            },
            {
              definition: "A three-dimensional facet of a polytope.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The unit in a statistical array (a spreadsheet, for example) where a row and a column intersect.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The space between the ribs of a vaulted roof.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A cella.", synonyms: [], antonyms: [] },
            {
              definition: "An area of an insect wing bounded by veins",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["prison cell"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To place or enclose in a cell.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A cellular phone.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cell",
        "https://en.wiktionary.org/wiki/cells",
      ],
    },
  ],
  lower: [
    {
      word: "lower",
      phonetic: "/ˈləʊə/",
      phonetics: [
        { text: "/ˈləʊə/", audio: "" },
        {
          text: "/ˈloʊɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lower-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1243967",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Situated close to, or even below, the ground or another normal reference plane; not high or lofty.",
              synonyms: [],
              antonyms: [],
              example: "a low wall  a low shelf",
            },
            {
              definition:
                "Of less than normal height; below the average or normal level from which elevation is measured.",
              synonyms: [],
              antonyms: [],
              example: "a low bow",
            },
            {
              definition:
                "Not high in status, esteem or rank, dignity, or quality. (Compare vulgar.)",
              synonyms: [],
              antonyms: [],
              example: "Now that was low even for you!",
            },
            {
              definition: "Humble, meek, not haughty.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Disparaging; assigning little value or excellence.",
              synonyms: [],
              antonyms: [],
              example:
                "She had a low opinion of cats. He took a low view of dogs.",
            },
            {
              definition: "Being a nadir, a bottom.",
              synonyms: [],
              antonyms: [],
              example: "the low point in her career",
            },
            {
              definition: "Depressed in mood, dejected, sad.",
              synonyms: [],
              antonyms: [],
              example: "low spirits",
            },
            {
              definition:
                "Lacking health or vitality, strength or vivacity; feeble; weak.",
              synonyms: [],
              antonyms: [],
              example: "a low pulse",
            },
            {
              definition:
                "Small, not high (in amount or quantity, value, force, energy, etc).",
              synonyms: [],
              antonyms: [],
              example:
                "My credit union charges a low interest rate.   Jogging during a whiteout, with such low temperatures and low visibility, is dangerous.   The store sold bread at low prices, and milk at even lower prices.   The contractors gave a low estimate of the costs.   low cholesterol   a low voltage wire   a low number",
            },
            {
              definition:
                "Simple in complexity or development; (in several set phrases) favoring simplicity; (see e.g. low church, Low Tory).",
              synonyms: [],
              antonyms: [],
              example:
                "low protozoan animals, low cryptogamic plants, and other low organisms",
            },
            {
              definition: "(in several set phrases) Being near the equator.",
              synonyms: [],
              antonyms: [],
              example: "the low northern latitudes",
            },
            {
              definition:
                "(acoustics) Grave in pitch, due to being produced by relatively slow vibrations (wave oscillations); flat.",
              synonyms: [],
              antonyms: [],
              example:
                "Generally, European men have lower voices than their Indian counterparts.",
            },
            {
              definition: "Quiet; soft; not loud.",
              synonyms: [],
              antonyms: [],
              example:
                "They spoke in low voices so I would not hear what they were saying.",
            },
            {
              definition:
                "Made with a relatively large opening between the tongue and the palate; made with (part of) the tongue positioned low in the mouth, relative to the palate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Lesser in value than other cards, denominations, suits, etc.",
              synonyms: [],
              antonyms: [],
              example: "a low card",
            },
            {
              definition:
                "Not rich or seasoned; offering the minimum of nutritional requirements; plain, simple.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of an automobile, gear, etc) Designed for a slow (or the slowest) speed.",
              synonyms: [],
              antonyms: [],
              example: "low gear",
            },
          ],
          synonyms: [
            "blue",
            "depressed",
            "down",
            "gloomy",
            "miserable",
            "sad",
            "unhappy",
            "abject",
            "immoral",
            "scummy",
            "scurvy",
            "nether",
            "underslung",
            "devalued",
            "low-level",
            "reduced",
            "low-toned",
            "soft",
            "deep",
            "flat",
            "low-pitched",
            "short",
            "small",
          ],
          antonyms: ["high", "tall"],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            { definition: "Close to the ground.", synonyms: [], antonyms: [] },
            {
              definition: "Of a pitch, at a lower frequency.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "With a low voice or sound; not loudly; gently.",
              synonyms: [],
              antonyms: [],
              example: "to speak low",
            },
            {
              definition:
                "Under the usual price; at a moderate price; cheaply.",
              synonyms: [],
              antonyms: [],
              example: "He sold his wheat low.",
            },
            {
              definition: "In a low mean condition; humbly; meanly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "In a time approaching our own.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In a path near the equator, so that the declination is small, or near the horizon, so that the altitude is small; said of the heavenly bodies with reference to the diurnal revolution.",
              synonyms: [],
              antonyms: [],
              example:
                "The moon runs low, i.e. comparatively near the horizon when on or near the meridian.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To let descend by its own weight, as something suspended; to let down",
              synonyms: [],
              antonyms: [],
              example: "lower a bucket into a well",
            },
            {
              definition: "To pull down",
              synonyms: [],
              antonyms: [],
              example: "to lower a flag",
            },
            {
              definition: "To reduce the height of",
              synonyms: [],
              antonyms: [],
              example: "lower a chimney or turret",
            },
            {
              definition: "To depress as to direction",
              synonyms: [],
              antonyms: [],
              example: "lower the aim of a gun",
            },
            {
              definition: "To make less elevated",
              synonyms: [],
              antonyms: [],
              example: "to lower one's ambition, aspirations, or hopes",
            },
            {
              definition: "To reduce the degree, intensity, strength, etc., of",
              synonyms: [],
              antonyms: [],
              example: "lower distilled liquors",
            },
            {
              definition: "To bring down; to humble",
              synonyms: [],
              antonyms: [],
              example: "lower one's pride",
            },
            {
              definition:
                "(lower oneself) To humble oneself; to do something one considers to be beneath one's dignity.",
              synonyms: [],
              antonyms: [],
              example:
                "I could never lower myself enough to buy second-hand clothes.",
            },
            {
              definition: "To reduce (something) in value, amount, etc.",
              synonyms: [],
              antonyms: [],
              example: "lower the interest rate",
            },
            {
              definition:
                "To fall; to sink; to grow less; to diminish; to decrease",
              synonyms: [],
              antonyms: [],
              example: "The river lowered as rapidly as it rose.",
            },
            {
              definition: "To decrease in value, amount, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "lessen",
            "reduce",
            "die off",
            "drop",
            "fall",
            "fall off",
            "shrink",
            "reduce",
            "cut",
            "reduce",
            "reduce",
            "turn down",
            "shorten",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/low",
        "https://en.wiktionary.org/wiki/lower",
      ],
    },
    {
      word: "lower",
      phonetic: "/ˈlaʊ.ə/",
      phonetics: [
        {
          text: "/ˈlaʊ.ə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lower-2-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88876129",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈlaʊ.ɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To frown; to look sullen.",
              synonyms: ["glower", "scowl"],
              antonyms: [],
            },
            {
              definition:
                "To be dark, gloomy, and threatening, as clouds; of the sky: to be covered with dark and threatening clouds; to show threatening signs of approach, as a tempest.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["glower", "scowl"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lour",
        "https://en.wiktionary.org/wiki/lower",
      ],
    },
  ],
  sleep: [
    {
      word: "sleep",
      phonetic: "/sliːp/",
      phonetics: [
        { text: "/sliːp/", audio: "" },
        {
          text: "/slip/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sleep-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1227715",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To rest in a state of reduced consciousness.",
              synonyms: [],
              antonyms: [],
              example: "You should sleep 8 hours a day.",
            },
            {
              definition:
                "(of a spinning top or yo-yo) To spin on its axis with no other perceptible motion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause (a spinning top or yo-yo) to spin on its axis with no other perceptible motion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To accommodate in beds.",
              synonyms: [],
              antonyms: [],
              example: "This caravan can sleep four people comfortably.",
            },
            {
              definition: "To be slumbering in (a state).",
              synonyms: [],
              antonyms: [],
              example: "to sleep a dreamless sleep",
            },
            {
              definition:
                "To be careless, inattentive, or unconcerned; not to be vigilant; to live thoughtlessly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be dead; to lie in the grave.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be, or appear to be, in repose; to be quiet; to be unemployed, unused, or unagitated; to rest; to lie dormant.",
              synonyms: [],
              antonyms: [],
              example: "a question sleeps for the present; the law sleeps",
            },
            {
              definition:
                "To wait for a period of time without performing any action.",
              synonyms: [],
              antonyms: [],
              example:
                "After a failed connection attempt, the program sleeps for 5 seconds before trying again.",
            },
            {
              definition: "To place into a state of hibernation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sleep"],
    },
    {
      word: "sleep",
      phonetic: "/sliːp/",
      phonetics: [
        { text: "/sliːp/", audio: "" },
        {
          text: "/slip/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sleep-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1227715",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The state of reduced consciousness during which a human or animal rests in a daily rhythm.",
              synonyms: [],
              antonyms: [],
              example: "I really need some sleep.",
            },
            {
              definition: "An act or instance of sleeping.",
              synonyms: [],
              antonyms: [],
              example: "I’m just going to have a quick sleep.",
            },
            {
              definition: "(metonymically) A night.",
              synonyms: [],
              antonyms: [],
              example: "There are only three sleeps till Christmas!",
            },
            {
              definition:
                "Rheum, crusty or gummy discharge found in the corner of the eyes after waking, whether real or a figurative objectification of sleep (in the sense of reduced consciousness).",
              synonyms: ["crusty", "gound", "sleeper", "sleepy", "sleepy dust"],
              antonyms: [],
              example: "Wipe the sleep from your eyes.",
            },
            {
              definition:
                "A state of plants, usually at night, when their leaflets approach each other and the flowers close and droop, or are covered by the folded leaves.",
              synonyms: ["nyctinasty", "nyctitropism"],
              antonyms: [],
            },
            {
              definition: "The hibernation of animals.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "bed booger",
            "eye bogey",
            "eye bogie",
            "eye booger",
            "eye crust",
            "eye goop",
            "eye gunk",
            "eye sand",
            "eye snot",
            "eye-snot",
            "sleepies",
            "sleepy booger",
            "crusty",
            "gound",
            "sleeper",
            "sleepy",
            "sleepy dust",
            "nyctinasty",
            "nyctitropism",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sleep"],
    },
  ],
  wrong: [
    {
      word: "wrong",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wrong-ca.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=935999",
          license: {
            name: "BY 2.5",
            url: "https://creativecommons.org/licenses/by/2.5",
          },
        },
        { text: "/ɹɒŋ/", audio: "" },
        {
          text: "/ɹɔŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wrong-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=252405",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Something that is immoral or not good.",
              synonyms: [],
              antonyms: [],
              example: "Injustice is a heinous wrong.",
            },
            {
              definition:
                "An instance of wronging someone (sometimes with possessive to indicate the wrongdoer).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The incorrect or unjust position or opinion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The opposite of right; the concept of badness.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["wrength"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To treat unjustly; to injure or harm.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To deprive of some right, or to withhold some act of justice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To slander; to impute evil to unjustly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Incorrect or untrue.",
              synonyms: [],
              antonyms: [],
              example:
                "Some of your answers were correct, and some were wrong.",
            },
            {
              definition: "Asserting something incorrect or untrue.",
              synonyms: [],
              antonyms: [],
              example: "You're wrong: he's not Superman at all.",
            },
            {
              definition: "Immoral, not good, bad.",
              synonyms: [],
              antonyms: [],
              example: "It is wrong to lie.",
            },
            {
              definition: "Improper; unfit; unsuitable.",
              synonyms: [],
              antonyms: [],
              example: "A bikini is the wrong thing to wear on a cold day.",
            },
            {
              definition: "Not working; out of order.",
              synonyms: [],
              antonyms: [],
              example: "Don't cry, honey. Tell me what's wrong.",
            },
            {
              definition:
                "Designed to be worn or placed inward; as, the wrong side of a garment or of a piece of cloth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Twisted; wry.",
              synonyms: [],
              antonyms: [],
              example: "a wrong nose",
            },
          ],
          synonyms: [
            "detrimental",
            "false",
            "faulty",
            "injurious",
            "unfit",
            "unjust",
            "unsuitable",
          ],
          antonyms: ["right"],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "In a way that isn't right; incorrectly, wrongly.",
              synonyms: [],
              antonyms: [],
              example: "I spelled several names wrong in my address book.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/wrong"],
    },
  ],
  pages: [
    {
      word: "pages",
      phonetic: "/ˈpeɪdʒɪz/",
      phonetics: [
        {
          text: "/ˈpeɪdʒɪz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pages-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1676814",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One of the many pieces of paper bound together within a book or similar document.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One side of a paper leaf on which one has written or printed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any record or writing; a collective memory.",
              synonyms: [],
              antonyms: [],
              example: "the page of history",
            },
            {
              definition: "The type set up for printing a page.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A screenful of text and possibly other content.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A web page.", synonyms: [], antonyms: [] },
            {
              definition: "A block of contiguous memory of a fixed length.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["account", "record", "side"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To mark or number the pages of, as a book or manuscript.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(often with “through”) To turn several pages of a publication.",
              synonyms: [],
              antonyms: [],
              example:
                "The patient paged through magazines while he waited for the doctor.",
            },
            {
              definition: "To furnish with folios.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A serving boy – a youth attending a person of high degree, especially at courts, as a position of honor and education.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A youth employed for doing errands, waiting on the door, and similar service in households.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A boy or girl employed to wait upon the members of a legislative body.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in libraries) The common name given to an employee whose main purpose is to replace materials that have either been checked out or otherwise moved, back to their shelves.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A boy child.", synonyms: [], antonyms: [] },
            {
              definition:
                "A contrivance, as a band, pin, snap, or the like, to hold the skirt of a woman’s dress from the ground.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A track along which pallets carrying newly molded bricks are conveyed to the hack.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A message sent to someone's pager.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any one of several species of colorful South American moths of the genus Urania.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["boy", "page boy"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To attend (someone) as a page.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To call or summon (someone).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To contact (someone) by means of a pager or other mobile device.",
              synonyms: [],
              antonyms: [],
              example: "I’ll be out all day, so page me if you need me.",
            },
            {
              definition:
                "To call (somebody) using a public address system so as to find them.",
              synonyms: [],
              antonyms: [],
              example: "An SUV parked me in. Could you please page its owner?",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/page",
        "https://en.wiktionary.org/wiki/pages",
      ],
    },
  ],
  ships: [
    {
      word: "ships",
      phonetic: "/ʃɪps/",
      phonetics: [
        {
          text: "/ʃɪps/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ships-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1250048",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A water-borne vessel generally larger than a boat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(chiefly in combination) A vessel which travels through any medium other than across land, such as an airship or spaceship.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sailing vessel with three or more square-rigged masts.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A dish or utensil (originally fashioned like the hull of a ship) used to hold incense.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The third card of the Lenormand deck.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To send by water-borne transport.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To send (a parcel or container) to a recipient (by any means of transport).",
              synonyms: [],
              antonyms: [],
              example: "to ship freight by railroad",
            },
            {
              definition: "To release a product to vendors; to launch.",
              synonyms: [],
              antonyms: [],
              example: "Our next issue ships early next year.",
            },
            {
              definition: "To engage to serve on board a vessel.",
              synonyms: [],
              antonyms: [],
              example: "I shipped on a man-of-war.",
            },
            { definition: "To embark on a ship.", synonyms: [], antonyms: [] },
            {
              definition: "To put in its place.",
              synonyms: [],
              antonyms: [],
              example: "to ship the tiller or rudder",
            },
            {
              definition: "To take in (water) over the sides of a vessel.",
              synonyms: [],
              antonyms: [],
              example:
                "We were shipping so much water I was sure we would capsize.",
            },
            {
              definition: "To pass (from one person to another).",
              synonyms: [],
              antonyms: [],
              example: "Can you ship me the ketchup?",
            },
            { definition: "To go all in.", synonyms: [], antonyms: [] },
            {
              definition: "To trade or send a player to another team.",
              synonyms: [],
              antonyms: [],
              example: "Twins ship Delmon Young to Tigers.",
            },
            {
              definition:
                "To bungle a kick and give the opposing team possession.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A fictional romantic relationship between two characters, either real or themselves fictional.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To support or approve of a fictional romantic relationship between two characters, either real or themselves fictional, typically in fan fiction.",
              synonyms: [],
              antonyms: [],
              example: "I ship Kirk and Spock in “Star Trek”.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ship",
        "https://en.wiktionary.org/wiki/ships",
      ],
    },
  ],
  needs: [
    {
      word: "needs",
      phonetic: "/niːdz/",
      phonetics: [
        {
          text: "/niːdz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/needs-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=91894731",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/niːdz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/needs-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1229337",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition:
                'Of necessity; necessarily; indispensably; used with an auxiliary verb (often must), and equivalent to "of need".',
              synonyms: [],
              antonyms: [],
              example: "One must needs run when the devil drives.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/needs"],
    },
    {
      word: "needs",
      phonetic: "/niːdz/",
      phonetics: [
        {
          text: "/niːdz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/needs-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=91894731",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/niːdz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/needs-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1229337",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A requirement for something; something needed.",
              synonyms: [],
              antonyms: [],
              example:
                "I've always tried to have few needs beyond food, clothing and shelter.",
            },
            {
              definition:
                "Lack of means of subsistence; poverty; indigence; destitution.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/need",
        "https://en.wiktionary.org/wiki/needs",
      ],
    },
    {
      word: "needs",
      phonetic: "/niːdz/",
      phonetics: [
        {
          text: "/niːdz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/needs-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=91894731",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/niːdz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/needs-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1229337",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To have an absolute requirement for.",
              synonyms: [],
              antonyms: [],
              example: "Living things need water to survive.",
            },
            {
              definition:
                "To want strongly; to feel that one must have something.",
              synonyms: [],
              antonyms: [],
              example:
                "After ten days of hiking, I needed a shower and a shave.",
            },
            {
              definition:
                "(modal verb) To be obliged or required (to do something).",
              synonyms: [],
              antonyms: [],
              example: "You need not go if you don't want to.",
            },
            {
              definition: "To be required; to be necessary.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be necessary (to someone).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "desire",
            "want",
            "will",
            "wish",
            "would like",
            "be",
            "lack",
            "without",
            "in need of",
            "require",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/need",
        "https://en.wiktionary.org/wiki/needs",
      ],
    },
  ],
  rocks: [
    {
      word: "rocks",
      phonetic: "/ɹɒks/",
      phonetics: [
        {
          text: "/ɹɒks/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rocks-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1661698",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An enormous mythical bird in Eastern legend.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["peng"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Rocuronium.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A formation of minerals, specifically:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A large hill or island having no vegetation.",
              synonyms: [],
              antonyms: [],
              example:
                "Pearl Rock near Cape Cod is so named because the morning sun makes it gleam like a pearl.",
            },
            {
              definition:
                "Something that is strong, stable, and dependable; a person who provides security or support to another.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A lump or cube of ice.",
              synonyms: [],
              antonyms: [],
              example: "I'll have a whisky on the rocks, please.",
            },
            {
              definition:
                "A type of confectionery made from sugar in the shape of a stick, traditionally having some text running through its length.",
              synonyms: [],
              antonyms: [],
              example: "While we're in Brighton, let's get a stick of rock!",
            },
            {
              definition: "A crystallized lump of crack cocaine.",
              synonyms: ["candy", "crack rock"],
              antonyms: [],
            },
            {
              definition:
                "An unintelligent person, especially one who repeats mistakes.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An Afrikaner.", synonyms: [], antonyms: [] },
            {
              definition:
                "An extremely conservative player who is willing to play only the very strongest hands.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Any of several fish:", synonyms: [], antonyms: [] },
            {
              definition: "A basketball.",
              synonyms: [],
              antonyms: [],
              example: "Yo homie, pass the rock!",
            },
            {
              definition:
                "(rock paper scissors) A closed hand (a handshape resembling a rock), that beats scissors and loses to paper. It beats lizard and loses to Spock in rock-paper-scissors-lizard-Spock.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cricket ball, especially a new one that has not been softened by use",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A crystal used to control the radio frequency.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "Afrikaner",
            "boulder",
            "pebble",
            "stone",
            "crack",
            "ice",
            "ice cube",
            "stone",
            "diamond",
            "gem",
            "cliff",
            "foundation",
            "support",
            "bedrock",
            "candy",
            "crack rock",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An act of rocking; a rocking motion; a sway.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To move gently back and forth.",
              synonyms: [],
              antonyms: [],
              example: "Rock the baby to sleep.",
            },
            {
              definition: "To cause to shake or sway violently.",
              synonyms: [],
              antonyms: [],
              example: "Don't rock the boat.",
            },
            {
              definition: "To sway or tilt violently back and forth.",
              synonyms: [],
              antonyms: [],
              example: "The boat rocked at anchor.",
            },
            {
              definition:
                "(of ore etc.) To be washed and panned in a cradle or in a rocker.",
              synonyms: [],
              antonyms: [],
              example: "The ores had been rocked and laid out for inspection.",
            },
            {
              definition:
                "To disturb the emotional equilibrium of; to distress; to greatly impact (most often positively).",
              synonyms: [],
              antonyms: [],
              example:
                "Downing Street has been rocked by yet another sex scandal.",
            },
            {
              definition: "To do well or to be operating at high efficiency.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make love to or have sex with.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "agitate",
            "trouble",
            "cook with gas",
            "flourish",
            "go to bed with",
            "hit",
            "sleep with",
            "waver",
            "judder",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To play, perform, or enjoy rock music, especially with a lot of skill or energy.",
              synonyms: [],
              antonyms: [],
              example: "Let’s rock!",
            },
            {
              definition:
                "To be very favourable or skilful; excel; be fantastic.",
              synonyms: [],
              antonyms: [],
              example: "Chocolate rocks.",
            },
            {
              definition: "To thrill or excite, especially with rock music",
              synonyms: [],
              antonyms: [],
              example: "Let's rock this joint!",
            },
            {
              definition: "To do something with excitement yet skillfully",
              synonyms: [],
              antonyms: [],
              example: "I need to rock a piss.",
            },
            {
              definition:
                "To wear (a piece of clothing, outfit etc.) successfully or with style; to carry off (a particular look, style).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["rule"],
          antonyms: ["stink", "suck"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Distaff.", synonyms: [], antonyms: [] },
            {
              definition: "The flax or wool on a distaff.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["distaff"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Money.", synonyms: [], antonyms: [] },
            { definition: "Testicles.", synonyms: [], antonyms: [] },
            {
              definition: "Crack cocaine.",
              synonyms: [],
              antonyms: [],
              example:
                "You seem a bit out of it lately, have you been smoking rocks again?",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/roc",
        "https://en.wiktionary.org/wiki/rock",
        "https://en.wiktionary.org/wiki/rocks",
      ],
    },
  ],
  eight: [
    {
      word: "eight",
      phonetic: "/eɪt/",
      phonetics: [
        { text: "/eɪt/", audio: "" },
        {
          text: "/eɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/eight-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=867490",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The digit/figure 8.", synonyms: [], antonyms: [] },
            {
              definition:
                "Any of the four cards in a normal deck with the value eight.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A light, narrow rowing boat, especially one used in competitive rowing, steered by a cox, in which eight rowers each have two oars.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(especially in plural) A race in which such craft participate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The eight people who crew a rowing-boat.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "numeral",
          definitions: [
            {
              definition:
                "A numerical value equal to 8; the number occurring after seven and before nine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Describing a group or set with eight elements.",
              synonyms: [],
              antonyms: [],
              example: "He works eight hours a day.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "The ordinal form of the number eight.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["8th", "VIII", "VIIIth"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/eight",
        "https://en.wiktionary.org/wiki/eighth",
      ],
    },
    {
      word: "eight",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An island in a river, especially the River Thames in England.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["eyot"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ait",
        "https://en.wiktionary.org/wiki/eight",
      ],
    },
  ],
  major: [
    {
      word: "major",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/major-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75622520",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈmeɪ.dʒə(ɹ)/", audio: "" },
        {
          text: "/ˈmeɪ.dʒɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/major-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2423296",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A military rank between captain and lieutenant colonel.",
              synonyms: [],
              antonyms: [],
              example: "He used to be a major in the army.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/major"],
    },
    {
      word: "major",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/major-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75622520",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈmeɪ.dʒə(ɹ)/", audio: "" },
        {
          text: "/ˈmeɪ.dʒɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/major-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2423296",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The main area of study of a student working toward a degree at a college or university.",
              synonyms: [],
              antonyms: [],
              example:
                "Midway through his second year of college, he still hadn't chosen a major.",
            },
            {
              definition:
                "A student at a college or university concentrating on a given area of study.",
              synonyms: [],
              antonyms: [],
              example: "She is a math major.",
            },
            {
              definition: "A person of legal age.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The major premise.", synonyms: [], antonyms: [] },
            {
              definition: "A touchdown, or major score.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A large, commercially successful company, especially a record label that is bigger than an indie.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(British slang) An elder brother (especially at a public school).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A large leaf-cutter ant that acts as a soldier, defending the nest.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["minor"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To concentrate on a particular area of study as a student in a college or university",
              synonyms: [],
              antonyms: [],
              example: "I have decided to major in mathematics.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of great significance or importance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Greater in number, quantity, or extent",
              synonyms: [],
              antonyms: [],
              example: "the major part of the assembly",
            },
            {
              definition: "Of full legal age, having attained majority",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of a scale that follows the pattern: tone - tone - semitone - tone - tone - tone - semitone",
              synonyms: [],
              antonyms: [],
              example: "A major scale.",
            },
            {
              definition:
                "Being the larger of two intervals denoted by the same ordinal number.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Containing the note a major third (four half steps) above the tonic.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(campanology) Bell changes rung on eight bells.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["minor"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/major"],
    },
  ],
  level: [
    {
      word: "level",
      phonetic: "/ˈlɛv.əl/",
      phonetics: [
        {
          text: "/ˈlɛv.əl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/level-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88518399",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈlɛv.əl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/level-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=876696",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A tool for finding whether a surface is level, or for creating a horizontal or vertical line of reference.",
              synonyms: [],
              antonyms: [],
              example:
                "Hand me the level so I can tell if this is correctly installed.",
            },
            {
              definition: "A distance relative to a given reference elevation.",
              synonyms: [],
              antonyms: [],
              example:
                "By the end of the day, we'd dug down to the level of the old basement floor.",
            },
            {
              definition: "Degree or amount.",
              synonyms: [],
              antonyms: [],
              example:
                "The sound level is much too high; this hurts my ears.   We've reached a new level of success.",
            },
            {
              definition: "Achievement or qualification.",
              synonyms: [],
              antonyms: [],
              example: "She achieved a high level of distinction.",
            },
            {
              definition: "Distance from the root node of a tree structure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of several discrete segments of a game, generally increasing in difficulty and representing different locations in the game world.",
              synonyms: ["stage", "world", "zone"],
              antonyms: [],
              example:
                "It took me weeks to get to level seven.   Watch out for the next level; the bad guys there are really overpowered.",
            },
            {
              definition:
                "A numeric value that quantifies a character's experience and power.",
              synonyms: [],
              antonyms: [],
              example:
                "My half-orc barbarian reached fifth level before he was squashed by a troll.",
            },
            {
              definition: "A floor of a multi-storey building.",
              synonyms: [],
              antonyms: [],
              example: "Take the elevator and get off at the promenade level.",
            },
            {
              definition: "An area of almost perfectly flat land.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A school grade or year.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["stage", "world", "zone"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To adjust so as to make as flat or perpendicular to the ground as possible.",
              synonyms: [],
              antonyms: [],
              example:
                "You can level the table by turning the pads that screw into the feet.",
            },
            {
              definition: "To destroy by reducing to ground level; to raze.",
              synonyms: [],
              antonyms: [],
              example: "The hurricane leveled the forest.",
            },
            {
              definition: "To progress to the next level.",
              synonyms: [],
              antonyms: [],
              example: "I levelled after defeating the dragon.",
            },
            {
              definition:
                "To aim or direct (a weapon, a stare, an accusation, etc).",
              synonyms: [],
              antonyms: [],
              example:
                "He levelled an accusation of fraud at the directors.  The hunter levels the gun before taking a shot.",
            },
            {
              definition:
                "To direct or impose (a penalty, fine, etc) at or upon (someone).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make the score of a game equal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To bring to a common level or plane, in respect of rank, condition, character, privilege, etc.",
              synonyms: [],
              antonyms: [],
              example: "to level all the ranks and conditions of men",
            },
            {
              definition: "To adjust or adapt to a certain level.",
              synonyms: [],
              antonyms: [],
              example: "to level remarks to the capacity of children",
            },
            {
              definition:
                '(usually with "with") To speak honestly and openly with.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "The same height at all places; parallel to a flat ground.",
              synonyms: [],
              antonyms: [],
              example:
                "This table isn't quite level; see how this marble rolls off it?",
            },
            {
              definition:
                "At the same height as some reference; constructed as level with.",
              synonyms: [],
              antonyms: [],
              example:
                "We tried to hang the pictures so that the bottom of the frames were level with the dark line in the wallpaper.",
            },
            {
              definition: "Unvaried in frequency.",
              synonyms: [],
              antonyms: [],
              example: "His pulse has been level for 12 hours.",
            },
            {
              definition: "Unvaried in volume.",
              synonyms: [],
              antonyms: [],
              example:
                "His voice has been unchanged. It has been level for 12 hours.",
            },
            {
              definition: "Calm.",
              synonyms: [],
              antonyms: [],
              example: "He kept a level gaze.",
            },
            {
              definition: "In the same position or rank.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Straightforward; direct; clear.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Well balanced; even; just; steady; impartial.",
              synonyms: [],
              antonyms: [],
              example: "a level head; a level understanding",
            },
            {
              definition:
                "Of even tone; without rising or falling inflection; monotonic.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Perpendicular to a gravitational force.",
              synonyms: [],
              antonyms: [],
              example:
                "The earth's oceans remain level in relation to the pull of gravity.",
            },
          ],
          synonyms: [],
          antonyms: ["tilted", "unbalanced", "uneven"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/level"],
    },
  ],
  total: [
    {
      word: "total",
      phonetic: "/ˈtəʊ.təl/",
      phonetics: [
        { text: "/ˈtəʊ.təl/", audio: "" },
        { text: "/ˈtoʊ.təl/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An amount obtained by the addition of smaller amounts.",
              synonyms: [],
              antonyms: [],
              example: "A total of £145 was raised by the bring-and-buy stall.",
            },
            {
              definition: "Sum.",
              synonyms: [],
              antonyms: [],
              example: "The total of 4, 5 and 6 is 15.",
            },
          ],
          synonyms: ["sum"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To add up; to calculate the sum of.",
              synonyms: [],
              antonyms: [],
              example:
                "When we totalled the takings, we always got a different figure.",
            },
            {
              definition: "To equal a total of; to amount to.",
              synonyms: [],
              antonyms: [],
              example: "That totals seven times so far.",
            },
            {
              definition: "To demolish; to wreck completely. (from total loss)",
              synonyms: [],
              antonyms: [],
              example: "Honey, I’m OK, but I’ve totaled the car.",
            },
            {
              definition: "To amount to; to add up to.",
              synonyms: [],
              antonyms: [],
              example: "It totals nearly a pound.",
            },
          ],
          synonyms: ["add up", "sum", "demolish", "trash", "wreck"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Entire; relating to the whole of something.",
              synonyms: [],
              antonyms: [],
              example:
                "The total book is rubbish from start to finish.  The total number of votes cast is 3,270.",
            },
            {
              definition: "(used as an intensifier) Complete; absolute.",
              synonyms: [],
              antonyms: [],
              example: "He is a total failure.",
            },
          ],
          synonyms: [
            "absolute",
            "complete",
            "utter",
            "entire",
            "full",
            "whole",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/total"],
    },
  ],
  ahead: [
    {
      word: "ahead",
      phonetic: "/əˈhɛd/",
      phonetics: [
        {
          text: "/əˈhɛd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ahead-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1230120",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "In or to the front; in advance; onward.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "In the direction one is facing or moving.",
              synonyms: [],
              antonyms: [],
              example: "Just ahead you can see the cliffs.",
            },
            {
              definition: "In or for the future.",
              synonyms: [],
              antonyms: [],
              example: "There may be tough times ahead.",
            },
            {
              definition: "At an earlier time.",
              synonyms: [],
              antonyms: [],
              example: "He paid his rent ahead.",
            },
            {
              definition: "Having progressed more.",
              synonyms: [],
              antonyms: [],
              example: "In all of his classes Jack was ahead.",
            },
          ],
          synonyms: [],
          antonyms: ["astern", "behind"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ahead"],
    },
  ],
  reach: [
    {
      word: "reach",
      phonetic: "/ɹiːt͡ʃ/",
      phonetics: [
        {
          text: "/ɹiːt͡ʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/reach-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1236001",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act of stretching or extending; extension.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The ability to reach or touch with the person, a limb, or something held or thrown.",
              synonyms: [],
              antonyms: [],
              example: "The fruit is beyond my reach.",
            },
            {
              definition:
                "The power of stretching out or extending action, influence, or the like; power of attainment or management; extent of force or capacity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Extent; stretch; expanse; hence, application; influence; result; scope.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An exaggeration; an extension beyond evidence or normal; a stretch.",
              synonyms: [],
              antonyms: [],
              example: "To call George eloquent is certainly a reach.",
            },
            {
              definition:
                "The distance a boxer's arm can extend to land a blow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any point of sail in which the wind comes from the side of a vessel, excluding close-hauled.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The distance traversed between tacks.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A stretch of a watercourse which can be sailed in one reach (in the previous sense). An extended portion of water; a stretch; a straightish portion of a stream, river, or arm of the sea extending up into the land, as from one turn to another. By extension, the adjacent land.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A level stretch of a watercourse, as between rapids in a river or locks in a canal. (examples?)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An extended portion or area of land or water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An article to obtain an advantage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The pole or rod connecting the rear axle with the forward bolster of a wagon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An effort to vomit; a retching.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To extend, stretch, or thrust out (for example a limb or object held in the hand).",
              synonyms: [],
              antonyms: [],
              example: "He reached for a weapon that was on the table.",
            },
            {
              definition:
                "To give to someone by stretching out a limb, especially the hand; to give with the hand; to pass to another person; to hand over.",
              synonyms: [],
              antonyms: [],
              example: "to reach one a book",
            },
            {
              definition: "To stretch out the hand.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To attain or obtain by stretching forth the hand; to extend some part of the body, or something held, so as to touch, strike, grasp, etc.",
              synonyms: [],
              antonyms: [],
              example:
                "The gun was stored in a small box on a high closet shelf, but the boy managed to reach it by climbing on other boxes.",
            },
            {
              definition: "To strike or touch with a missile.",
              synonyms: [],
              antonyms: [],
              example: "His bullet reached its intended target.",
            },
            {
              definition:
                "Hence, to extend an action, effort, or influence to; to penetrate to; to pierce, or cut.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To extend to; to stretch out as far as; to touch by virtue of extent.",
              synonyms: [],
              antonyms: [],
              example:
                "When the forest reaches the river, you will be able to rest.",
            },
            {
              definition: "To arrive at (a place) by effort of any kind.",
              synonyms: [],
              antonyms: [],
              example: "After three years, he reached the position of manager.",
            },
            {
              definition: "To make contact with.",
              synonyms: ["contact", "get hold of", "get in touch"],
              antonyms: [],
              example: "I tried to reach you all day.",
            },
            {
              definition:
                "To connect with (someone) on an emotional level, making them receptive of (one); to get through to (someone).",
              synonyms: [],
              antonyms: [],
              example: "What will it take for me to reach him?",
            },
            {
              definition: "To arrive at a particular destination.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To continue living until, or up to, a certain age.",
              synonyms: [],
              antonyms: [],
              example:
                "You can only access the inheritance money when you reach the age of 25.",
            },
            {
              definition: "To understand; to comprehend.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To overreach; to deceive.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To strain after something; to make (sometimes futile or pretentious) efforts.",
              synonyms: [],
              antonyms: [],
              example: "Reach for the stars!",
            },
            {
              definition:
                "To extend in dimension, time etc.; to stretch out continuously (past, beyond, above, from etc. something).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To sail on the wind, as from one point of tacking to another, or with the wind nearly abeam.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To experience a vomiting reflex; to gag; to retch.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["contact", "get hold of", "get in touch"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/reach"],
    },
  ],
  stars: [
    {
      word: "stars",
      phonetic: "/stɑːz/",
      phonetics: [
        { text: "/stɑːz/", audio: "" },
        {
          text: "/stɑɹz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stars-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1661709",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any small luminous dot appearing in the cloudless portion of the night sky, especially with a fixed location relative to other such dots.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A luminous celestial body, made up of plasma (particularly hydrogen and helium) and having a spherical shape. Depending on context the sun may or may not be included.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A concave polygon with regular, pointy protrusions and indentations, generally with five or six points.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An actor in a leading role.",
              synonyms: [],
              antonyms: [],
              example: "Many Hollywood stars attended the launch party.",
            },
            {
              definition:
                "An exceptionally talented or famous person, often in a specific field; a celebrity.",
              synonyms: [],
              antonyms: [],
              example: "His teacher tells us he is a star pupil.",
            },
            { definition: "An asterisk (*).", synonyms: [], antonyms: [] },
            {
              definition:
                "A symbol used to rate hotels, films, etc. with a higher number of stars denoting better quality.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A simple dance, or part of a dance, where a group of four dancers each put their right or left hand in the middle and turn around in a circle. You call them right-hand stars or left-hand stars, depending on the hand which is in the middle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A planet supposed to influence one's destiny.",
              synonyms: [],
              antonyms: [],
              example:
                "What's in the stars for you today? Find out in our horoscope.",
            },
            {
              definition:
                "A star-shaped ornament worn on the breast to indicate rank or honour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A composition of combustible matter used in the heading of rockets, in mines, etc., which, exploding in the air, presents a starlike appearance.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["*"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To appear as a featured performer or headliner, especially in an entertainment program.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To feature (a performer or a headliner), especially in a movie or an entertainment program.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To mark with a star or asterisk.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To set or adorn with stars, or bright, radiating bodies; to bespangle.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To shine like a star.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: '(with "the") Outer space.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/star",
        "https://en.wiktionary.org/wiki/stars",
      ],
    },
  ],
  store: [
    {
      word: "store",
      phonetic: "/stɔː/",
      phonetics: [
        { text: "/stɔː/", audio: "" },
        {
          text: "/stɔɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/store-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1194275",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A place where items may be accumulated or routinely kept.",
              synonyms: [],
              antonyms: [],
              example: "This building used to be a store for old tires.",
            },
            {
              definition: "A supply held in storage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(mainly North American) A place where items may be purchased; a shop.",
              synonyms: [],
              antonyms: [],
              example: "I need to get some milk from the grocery store.",
            },
            {
              definition: "Memory.",
              synonyms: [],
              antonyms: [],
              example:
                "The main store of 1000 36-bit words seemed large at the time.",
            },
            {
              definition: "A great quantity or number; abundance.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["memory", "boutique", "shop", "stock", "supply"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To keep (something) while not in use, generally in a place meant for that purpose.",
              synonyms: [],
              antonyms: [],
              example: "I'll store these books in the attic.",
            },
            {
              definition: "To write (something) into memory or registers.",
              synonyms: [],
              antonyms: [],
              example: "This operation stores the result on the stack.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/store"],
    },
  ],
  sight: [
    {
      word: "sight",
      phonetic: "/saɪt/",
      phonetics: [
        {
          text: "/saɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sight-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1227547",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(in the singular) The ability to see.",
              synonyms: [],
              antonyms: [],
              example: "He is losing his sight and now can barely read.",
            },
            {
              definition:
                "The act of seeing; perception of objects by the eye; view.",
              synonyms: [],
              antonyms: [],
              example: "to gain sight of land",
            },
            { definition: "Something seen.", synonyms: [], antonyms: [] },
            {
              definition:
                "Something worth seeing; a spectacle, either good or bad.",
              synonyms: [],
              antonyms: [],
              example:
                "We went to London and saw all the sights – Buckingham Palace, Tower Bridge, and so on.",
            },
            {
              definition:
                "A device used in aiming a projectile, through which the person aiming looks at the intended target.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small aperture through which objects are to be seen, and by which their direction is settled or ascertained.",
              synonyms: [],
              antonyms: [],
              example: "the sight of a quadrant",
            },
            {
              definition:
                "A great deal, a lot; frequently used to intensify a comparative.",
              synonyms: [],
              antonyms: [],
              example:
                "This is a darn sight better than what I'm used to at home!",
            },
            {
              definition:
                "In a drawing, picture, etc., that part of the surface, as of paper or canvas, which is within the frame or the border or margin. In a frame, the open space, the opening.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The instrument of seeing; the eye.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Mental view; opinion; judgment.",
              synonyms: [],
              antonyms: [],
              example: "In their sight it was harmless.",
            },
          ],
          synonyms: ["sense", "vision", "peep sight", "scope", "view"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To register visually.", synonyms: [], antonyms: [] },
            {
              definition: "To get sight of (something).",
              synonyms: [],
              antonyms: [],
              example: "to sight land from a ship",
            },
            {
              definition:
                "To apply sights to; to adjust the sights of; also, to give the proper elevation and direction to by means of a sight.",
              synonyms: [],
              antonyms: [],
              example: "to sight a rifle or a cannon",
            },
            { definition: "To take aim at.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["espy", "glimpse", "spot", "aim", "take aim", "see"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sight"],
    },
  ],
  terms: [
    {
      word: "terms",
      phonetic: "/tɜːmz/",
      phonetics: [
        { text: "/tɜːmz/", audio: "" },
        {
          text: "/tɝmz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/terms-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1227518",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "That which limits the extent of anything; limit, extremity, bound, boundary.",
              synonyms: [],
              antonyms: [],
              example:
                '"Alright, look...we can spend the holidays with your parents, but this time it will be on my terms."',
            },
            {
              definition: "A chronological limitation or restriction.",
              synonyms: [],
              antonyms: [],
              example:
                "The term of a lease agreement is the period of time during which the lease is effective, and may be fixed, periodic, or of indefinite duration.",
            },
            {
              definition:
                "Any of the binding conditions or promises in a legal contract.",
              synonyms: [],
              antonyms: [],
              example:
                "Be sure to read the terms and conditions before signing.",
            },
            {
              definition: "A point, line, or superficies that limits.",
              synonyms: [],
              antonyms: [],
              example:
                "A line is the term of a superficies, and a superficies is the term of a solid.",
            },
            {
              definition:
                "A word or phrase, especially one from a specialised area of knowledge.",
              synonyms: [],
              antonyms: [],
              example: '"Algorithm" is a term used in computer science.',
            },
            {
              definition: "Relations among people.",
              synonyms: [],
              antonyms: [],
              example: "We are on friendly terms with each other.",
            },
            {
              definition:
                "Part of a year, especially one of the three parts of an academic year.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Duration of a set length; period in office of fixed length.",
              synonyms: [],
              antonyms: [],
              example: "He was sentenced to a term of six years in prison.",
            },
            {
              definition:
                "With respect to a pregnancy, the period during which birth usually happens (approximately 40 weeks from conception).",
              synonyms: [],
              antonyms: [],
              example: "at term, preterm, postterm",
            },
            {
              definition:
                "(of a patent) The maximum period during which the patent can be maintained into force.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A menstrual period.", synonyms: [], antonyms: [] },
            {
              definition:
                "Any value (variable or constant) or expression separated from another term by a space or an appropriate character, in an overall expression or table.",
              synonyms: [],
              antonyms: [],
              example: "All the terms of this sum cancel out.",
            },
            {
              definition:
                "The subject or the predicate of a proposition; one of the three component parts of a syllogism, each one of which is used twice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An essential dignity in which unequal segments of every astrological sign have internal rulerships which affect the power and integrity of each planet in a natal chart.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A statue of the upper body, sometimes without the arms, ending in a pillar or pedestal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of carved work placed under each end of the taffrail.",
              synonyms: [],
              antonyms: [],
              example:
                "The Cabin is large and commodious, well calculated for the Accommodation of Paſengers. Merchandiſe, Produce, &c. carried on the loweſt Terms.https//upload.wikimedia.org/wikipedia/commons/e/ea/Packet_Schooner.jpg",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To phrase a certain way; to name or call.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A computer program that emulates a physical terminal.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One whose employment has been terminated",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["axe", "fire", "sack"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To terminate one's employment",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/term",
        "https://en.wiktionary.org/wiki/terms",
      ],
    },
  ],
  catch: [
    {
      word: "catch",
      phonetic: "/kæt͡ʃ/",
      phonetics: [
        {
          text: "/kæt͡ʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/catch-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899791",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/kæt͡ʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/catch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=189827",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act of seizing or capturing.",
              synonyms: [],
              antonyms: [],
              example:
                "The catch of the perpetrator was the product of a year of police work.",
            },
            {
              definition:
                "The act of catching an object in motion, especially a ball.",
              synonyms: [],
              antonyms: [],
              example: "Nice catch!",
            },
            {
              definition: "The act of noticing, understanding or hearing.",
              synonyms: [],
              antonyms: [],
              example: "Good catch. I never would have remembered that.",
            },
            {
              definition: "The game of catching a ball.",
              synonyms: [],
              antonyms: [],
              example: "The kids love to play catch.",
            },
            {
              definition: "Something which is captured or caught.",
              synonyms: [],
              antonyms: [],
              example: "The catch amounted to five tons of swordfish.",
            },
            {
              definition:
                "(by extension) A find, in particular a boyfriend or girlfriend or prospective spouse.",
              synonyms: [],
              antonyms: [],
              example: "Did you see his latest catch?",
            },
            {
              definition:
                "A stopping mechanism, especially a clasp which stops something from opening.",
              synonyms: [],
              antonyms: [],
              example:
                "She installed a sturdy catch to keep her cabinets closed tight.",
            },
            {
              definition: "A hesitation in voice, caused by strong emotion.",
              synonyms: [],
              antonyms: [],
              example:
                "There was a catch in his voice when he spoke his father's name.",
            },
            {
              definition:
                "(sometimes noun adjunct) A concealed difficulty, especially in a deal or negotiation.",
              synonyms: [],
              antonyms: [],
              example: "Be careful, that's a catch question.",
            },
            {
              definition:
                "A crick; a sudden muscle pain during unaccustomed positioning when the muscle is in use.",
              synonyms: [],
              antonyms: [],
              example:
                "I bent over to see under the table and got a catch in my side.",
            },
            {
              definition: "A fragment of music or poetry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A state of readiness to capture or seize; an ambush.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A crop which has germinated and begun to grow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A type of strong boat, usually having two masts; a ketch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A type of humorous round in which the voices gradually catch up with one another; usually sung by men and often having bawdy lyrics.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The refrain; a line or lines of a song which are repeated from verse to verse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The act of catching a hit ball before it reaches the ground, resulting in an out.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A player in respect of his catching ability; particularly one who catches well.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The first contact of an oar with the water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A stoppage of breath, resembling a slight cough.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Passing opportunities seized; snatches.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A slight remembrance; a trace.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "beau",
            "conquest",
            "find",
            "prize",
            "capture",
            "collar",
            "seizure",
            "snatch",
            "observation",
            "bit",
            "fragment",
            "snatch",
            "snippet",
            "gimmick",
            "hitch",
            "problem",
            "snag",
            "trick",
            "haul",
            "take",
            "burden",
            "chorus",
            "refrain",
            "chock",
            "clasp",
            "hasp",
            "latch",
            "stop",
            "grasp",
            "snatch",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(heading) To capture, overtake.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(heading) To seize hold of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(heading) To intercept.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(heading) To receive (by being in the way).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(heading) To take in with one's senses or intellect.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(heading) To seize attention, interest.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(heading) To obtain or experience",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "get",
            "take",
            "capture",
            "hook",
            "snare",
            "take",
            "fang",
            "grab",
            "snatch",
          ],
          antonyms: ["drop", "release"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/catch"],
    },
  ],
  works: [
    {
      word: "works",
      phonetic: "/wɜːks/",
      phonetics: [
        { text: "/wɜːks/", audio: "" },
        {
          text: "/wɝks/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/works-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1227403",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "(heading) Employment.", synonyms: [], antonyms: [] },
            { definition: "(heading) Effort.", synonyms: [], antonyms: [] },
            {
              definition:
                "Sustained effort to achieve a goal or result, especially overcoming obstacles.",
              synonyms: [],
              antonyms: [],
              example:
                "We don't have much time. Let's get to work piling up those sandbags.",
            },
            {
              definition: "(heading) Product; the result of effort.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The staging of events to appear as real.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Ore before it is dressed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The equipment needed to inject a drug (syringes, needles, swabs etc.)",
              synonyms: [],
              antonyms: [],
              example: "Tell me you're using clean works at least.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To do a specific task by employing physical or mental powers.",
              synonyms: [],
              antonyms: [],
              example: "He’s working in a bar.",
            },
            {
              definition: "To effect by gradual degrees.",
              synonyms: [],
              antonyms: [],
              example: "he worked his way through the crowd",
            },
            {
              definition: "To embroider with thread.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To set into action.",
              synonyms: [],
              antonyms: [],
              example: "He worked the levers.",
            },
            { definition: "To cause to ferment.", synonyms: [], antonyms: [] },
            { definition: "To ferment.", synonyms: [], antonyms: [] },
            {
              definition: "To exhaust, by working.",
              synonyms: [],
              antonyms: [],
              example:
                "The mine was worked until the last scrap of ore had been extracted.",
            },
            {
              definition: "To shape, form, or improve a material.",
              synonyms: [],
              antonyms: [],
              example: "He used pliers to work the wire into shape.",
            },
            {
              definition: "To operate in a certain place, area, or speciality.",
              synonyms: [],
              antonyms: [],
              example: "she works the night clubs",
            },
            {
              definition: "To operate in or through; as, to work the phones.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To provoke or excite; to influence.",
              synonyms: [],
              antonyms: [],
              example:
                "The rock musician worked the crowd of young girls into a frenzy.",
            },
            {
              definition: "To use or manipulate to one’s advantage.",
              synonyms: [],
              antonyms: [],
              example: "She knows how to work the system.",
            },
            {
              definition: "To cause to happen or to occur as a consequence.",
              synonyms: [],
              antonyms: [],
              example: "I cannot work a miracle.",
            },
            {
              definition: "To cause to work.",
              synonyms: [],
              antonyms: [],
              example: "He is working his servants hard.",
            },
            {
              definition:
                "To function correctly; to act as intended; to achieve the goal designed for.",
              synonyms: [],
              antonyms: [],
              example:
                'he pointed at the car and asked, "Does it work"?;  he looked at the bottle of pain pills, wondering if they would work;  my plan didn’t work',
            },
            {
              definition: "To influence.",
              synonyms: [],
              antonyms: [],
              example: "They worked on her to join the group.",
            },
            {
              definition:
                "To effect by gradual degrees; as, to work into the earth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To move in an agitated manner.",
              synonyms: [],
              antonyms: [],
              example: "A ship works in a heavy sea.",
            },
            {
              definition: "To behave in a certain way when handled",
              synonyms: [],
              antonyms: [],
              example:
                "this dough does not work easily;  the soft metal works well",
            },
            {
              definition:
                "(with two objects) To cause (someone) to feel (something); to do unto somebody (something, whether good or bad).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To hurt; to ache.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A mechanism or machine; the means by which something happens.",
              synonyms: [],
              antonyms: [],
              example: "A stray wrench can really gum up the works.",
            },
            {
              definition:
                "A factory or factories, or similar collection(s) of buildings.",
              synonyms: [],
              antonyms: [],
              example: "The steel works almost fills the valley.",
            },
            {
              definition:
                '(with "the") Everything or everything that is available or possible; especially, all available toppings on food.',
              synonyms: [],
              antonyms: [],
              example: "I'll have a Behemoth Burger with the works.",
            },
            {
              definition:
                '(with "the") Drastic treatment; abuse; the axe (dismissal).',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/work",
        "https://en.wiktionary.org/wiki/works",
      ],
    },
  ],
  board: [
    {
      word: "board",
      phonetic: "/bɔːd/",
      phonetics: [
        { text: "/bɔːd/", audio: "" },
        { text: "/bɔːɹd/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A relatively long, wide and thin piece of any material, usually wood or similar, often for use in construction or furniture-making.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A device (e.g., switchboard) containing electrical switches and other controls and designed to control lights, sound, telephone connections, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A flat surface with markings for playing a board game.",
              synonyms: [],
              antonyms: [],
              example:
                "Each player starts the game with four counters on the board.",
            },
            {
              definition:
                "Short for blackboard, whiteboard, chessboard, surfboard, message board (on the Internet), etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A committee that manages the business of an organization, e.g., a board of directors.",
              synonyms: [],
              antonyms: [],
              example: "We have to wait to hear back from the board.",
            },
            {
              definition:
                "Regular meals or the amount paid for them in a place of lodging.",
              synonyms: [],
              antonyms: [],
              example: "Room and board",
            },
            { definition: "The side of a ship.", synonyms: [], antonyms: [] },
            {
              definition:
                "The distance a sailing vessel runs between tacks when working to windward.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The wall that surrounds an ice hockey rink, often in plural.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A long, narrow table, like that used in a medieval dining hall.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Paper made thick and stiff like a board, for book covers, etc.; pasteboard.",
              synonyms: [],
              antonyms: [],
              example: "to bind a book in boards",
            },
            {
              definition: "A level or stage having a particular layout.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A container for holding pre-dealt cards that is used to allow multiple sets of players to play the same cards.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To step or climb onto or otherwise enter a ship, aircraft, train or other conveyance.",
              synonyms: [],
              antonyms: ["alight", "disembark"],
              example: "It is time to board the aircraft.",
            },
            {
              definition:
                "To provide someone with meals and lodging, usually in exchange for money.",
              synonyms: [],
              antonyms: [],
              example: "to board one's horse at a livery stable",
            },
            {
              definition: "To receive meals and lodging in exchange for money.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To capture an enemy ship by going alongside and grappling her, then invading her with a boarding party",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To obtain meals, or meals and lodgings, statedly for compensation",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To approach (someone); to make advances to, accost.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cover with boards or boarding.",
              synonyms: [],
              antonyms: [],
              example: "to board a house",
            },
            {
              definition: "To hit (someone) with a wooden board.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To write something on a board, especially a blackboard or whiteboard.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["alight", "disembark"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/board"],
    },
    {
      word: "board",
      phonetic: "/bɔːd/",
      phonetics: [
        { text: "/bɔːd/", audio: "" },
        { text: "/bɔːɹd/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A rebound.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/board"],
    },
  ],
  cover: [
    {
      word: "cover",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cover-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899945",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈkʌvə/", audio: "" },
        {
          text: "/ˈkʌvɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cover-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=840673",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A lid.", synonyms: [], antonyms: [] },
            {
              definition:
                "Area or situation which screens a person or thing from view.",
              synonyms: [],
              antonyms: [],
              example: "The soldiers took cover behind a ruined building.",
            },
            {
              definition:
                "The front and back of a book, magazine, CD package, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The top sheet of a bed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cloth, usually fitted, placed over an item such as a car or sofa to protect it from dust, rain, etc. when not in use.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A cover charge.",
              synonyms: [],
              antonyms: [],
              example: "There's a $15 cover tonight.",
            },
            {
              definition: "A setting at a restaurant table or formal dinner.",
              synonyms: [],
              antonyms: [],
              example: "We need to set another cover for the Smith party.",
            },
            {
              definition:
                "A new performance or rerecording of a previously recorded song; a cover version; a cover song.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A fielding position on the off side, between point and mid off, about 30° forward of square; a fielder in this position.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A set (more often known as a family) of sets, whose union contains the given set.",
              synonyms: [],
              antonyms: [],
              example: "The open intervals are a cover for the real numbers.",
            },
            {
              definition: "An envelope complete with stamps and postmarks etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A solid object, including terrain, that provides protection from enemy fire.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In commercial law, a buyer’s purchase on the open market of goods similar or identical to the goods contracted for after a seller has breached a contract of sale by failure to deliver the goods contracted for.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An insurance contract; coverage by an insurance contract.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A persona maintained by a spy or undercover operative; cover story.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A swindler's confederate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The portion of a slate, tile, or shingle that is hidden by the overlap of the course above.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "In a steam engine, the lap of a slide valve.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The distance between reinforcing steel and the exterior of concrete.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To place something over or upon, as to conceal or protect.",
              synonyms: [],
              antonyms: [],
              example: "He covered the baby with a blanket.",
            },
            {
              definition: "To be over or upon, as to conceal or protect.",
              synonyms: [],
              antonyms: [],
              example: "The blanket covered the baby.",
            },
            {
              definition: "To be upon all of, so as to completely conceal.",
              synonyms: [],
              antonyms: [],
              example: "Regular hexagons can cover the plane.",
            },
            {
              definition: "To set upon all of, so as to completely conceal.",
              synonyms: [],
              antonyms: [],
              example: "You can cover the plane with regular hexagons.",
            },
            { definition: "To put on one's hat.", synonyms: [], antonyms: [] },
            {
              definition:
                "To invest (oneself with something); to bring upon (oneself).",
              synonyms: [],
              antonyms: [],
              example: "The heroic soldier covered himself with glory.",
            },
            {
              definition:
                "(of a publication) To discuss thoroughly; to provide coverage of.",
              synonyms: [],
              antonyms: [],
              example:
                "The magazine covers such diverse topics as politics, news from the world of science, and the economy.",
            },
            {
              definition: "To deal with or include someone or something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be enough money for.",
              synonyms: [],
              antonyms: [],
              example: "Ten dollars should cover lunch.",
            },
            {
              definition: "To act as a replacement.",
              synonyms: [],
              antonyms: [],
              example: "I need to take off Tuesday. Can you cover for me?",
            },
            {
              definition: "To have as an assignment or responsibility.",
              synonyms: [],
              antonyms: [],
              example:
                "Can you cover the morning shift tomorrow? I'll give you off next Monday instead.",
            },
            {
              definition:
                "To make a cover version of (a song that was originally recorded by another artist).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(law enforcement) To protect using an aimed firearm and the threat of firing; or to protect using continuous, heaving fire at or in the direction of the enemy so as to force the enemy to remain in cover; or to threaten using an aimed firearm.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To provide insurance coverage for.",
              synonyms: [],
              antonyms: [],
              example: "Does my policy cover accidental loss?",
            },
            {
              definition:
                "To copulate with (said of certain male animals such as dogs and horses).",
              synonyms: ["impregnate"],
              antonyms: [],
              example: "I would like to have my bitch covered next spring.",
            },
            {
              definition: "To protect or control (a piece or square).",
              synonyms: [],
              antonyms: [],
              example:
                "In order to checkmate a king on the side of the board, the five squares adjacent to the king must all be covered.",
            },
            {
              definition:
                "To extend over a given period of time or range, to occupy, to stretch over a given area.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To defend a particular player or area.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["impregnate"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Of or pertaining to the front cover of a book or magazine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of, pertaining to, or consisting of cover versions.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cover"],
    },
  ],
  songs: [
    {
      word: "songs",
      phonetic: "/sɔŋz/",
      phonetics: [
        { text: "/sɔŋz/", audio: "" },
        { text: "/sɔŋz/", audio: "" },
        { text: "/sɒŋz/", audio: "" },
        {
          text: "/sɑŋz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/songs-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2079755",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A musical composition with lyrics for voice or voices, performed by singing.",
              synonyms: [],
              antonyms: [],
              example:
                "Thomas listened to his favorite song on the radio yesterday.",
            },
            {
              definition: "(by extension) Any musical composition.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Poetical composition; poetry; verse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The act or art of singing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A melodious sound made by a bird, insect, whale or other animal.",
              synonyms: [],
              antonyms: [],
              example: "I love hearing the song of canary birds.",
            },
            {
              definition:
                "The distinctive sound that a male bird utters to attract a mate or to protect his territory; contrasts with call",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A low price, especially one under the expected value; chiefly in for a song.",
              synonyms: [],
              antonyms: [],
              example: "He bought that car for a song.",
            },
            {
              definition: "An object of derision; a laughing stock.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/song",
        "https://en.wiktionary.org/wiki/songs",
      ],
    },
  ],
  equal: [
    {
      word: "equal",
      phonetic: "/ˈiːkwəl/",
      phonetics: [
        {
          text: "/ˈiːkwəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/equal-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=267012",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A person or thing of equal status to others.",
              synonyms: [],
              antonyms: [],
              example: "This beer has no equal.",
            },
            {
              definition: "State of being equal; equality.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["peer"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To be equal to, to have the same value as; to correspond to.",
              synonyms: [],
              antonyms: [],
              example: "Two plus two equals four.",
            },
            {
              definition: "To make equivalent to; to cause to match.",
              synonyms: [],
              antonyms: [],
              example:
                "David equaled the water levels of the bottles, so they now both contain exactly 1 liter.",
            },
            {
              definition: "To have as its consequence.",
              synonyms: [],
              antonyms: [],
              example: "Losing this deal equals losing your job.",
            },
          ],
          synonyms: [
            "entail",
            "imply",
            "lead",
            "mean",
            "result in",
            "spell",
            "be",
            "is",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "The same in all respects.",
              synonyms: [],
              antonyms: [],
              example: "All men are created equal.",
            },
            {
              definition: "Exactly identical, having the same value.",
              synonyms: [],
              antonyms: [],
              example: "All right angles are equal.",
            },
            { definition: "Fair, impartial.", synonyms: [], antonyms: [] },
            {
              definition: "Adequate; sufficiently capable or qualified.",
              synonyms: [],
              antonyms: [],
              example:
                "This test is pretty tough, but I think I'm equal to it.",
            },
            {
              definition: "Not variable; equable; uniform; even.",
              synonyms: [],
              antonyms: [],
              example: "an equal movement",
            },
            {
              definition:
                "Intended for voices of one kind only, either all male or all female; not mixed.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "equivalent",
            "identical",
            "even",
            "fair",
            "uniform",
            "unvarying",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/equal"],
    },
  ],
  stone: [
    {
      word: "stone",
      phonetic: "/stɐʉn/",
      phonetics: [
        { text: "/stɐʉn/", audio: "" },
        {
          text: "/stəʊn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stone-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=77899690",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/stoʊn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stone-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1194273",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A hard earthen substance that can form large rocks.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A small piece of stone, a pebble.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A gemstone, a jewel, especially a diamond.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(plural: stone) A unit of mass equal to 14 pounds. Used to measure the weights of people, animals, cheese, wool, etc. 1 stone ≈ 6.3503 kilograms",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The central part of some fruits, particularly drupes; consisting of the seed and a hard endocarp layer.",
              synonyms: [],
              antonyms: [],
              example: "a peach stone",
            },
            {
              definition: "A hard, stone-like deposit.",
              synonyms: [],
              antonyms: [],
              example: "kidney stone",
            },
            {
              definition:
                "A playing piece made of any hard material, used in various board games such as backgammon, and go.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A dull light grey or beige, like that of some stones.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A 42-pound, precisely shaped piece of granite with a handle attached, which is bowled down the ice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A monument to the dead; a gravestone or tombstone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A mirror, or its glass.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A testicle of an animal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A stand or table with a smooth, flat top of stone, commonly marble, on which to arrange the pages of a book, newspaper, etc. before printing; also called imposing stone.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["rock", "calculus", "pip", "pit", "pebble", "rock"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To pelt with stones, especially to kill by pelting with stones.",
              synonyms: [],
              antonyms: [],
              example: "She got stoned to death after they found her.",
            },
            { definition: "To wall with stones.", synonyms: [], antonyms: [] },
            {
              definition: "To remove a stone from (fruit etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To form a stone during growth, with reference to fruit etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To intoxicate, especially with narcotics. (Usually in passive)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To do nothing, to stare blankly into space and not pay attention when relaxing or when bored.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To lap with an abrasive stone to remove surface irregularities.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "chill",
            "chill out",
            "chillax",
            "hang out",
            "rilek",
            "daydream",
            "veg out",
            "lapidate",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Constructed of stone.",
              synonyms: ["stonen"],
              antonyms: [],
              example: "stone walls",
            },
            {
              definition: "Having the appearance of stone.",
              synonyms: [],
              antonyms: [],
              example: "stone pot",
            },
            {
              definition:
                "Of a dull light grey or beige, like that of some stones.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Used as an intensifier.",
              synonyms: [],
              antonyms: [],
              example: "She is one stone fox.",
            },
            {
              definition:
                "Willing to give sexual pleasure but not to receive it.",
              synonyms: [],
              antonyms: ["pillow princess"],
              example: "stone butch; stone femme",
            },
          ],
          synonyms: ["stonen"],
          antonyms: ["pillow princess"],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "As a stone (used with following adjective).",
              synonyms: [],
              antonyms: [],
              example: "My father is stone deaf. This soup is stone cold.",
            },
            {
              definition:
                "Absolutely, completely (used with following adjectives).",
              synonyms: [],
              antonyms: [],
              example:
                "I said the medication made my vision temporarily blurry, it did not make me stone blind.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stone"],
    },
  ],
  waves: [
    {
      word: "waves",
      phonetic: "/weɪvz/",
      phonetics: [
        { text: "/weɪvz/", audio: "" },
        {
          text: "/weɪvz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/waves-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1684766",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To relinquish (a right etc.); to give up claim to; to forego.",
              synonyms: [],
              antonyms: [],
              example:
                "If you waive the right to be silent, anything you say can be used against you in a court of law.",
            },
            { definition: "To put aside, avoid.", synonyms: [], antonyms: [] },
            { definition: "To outlaw (someone).", synonyms: [], antonyms: [] },
            {
              definition: "To abandon, give up (someone or something).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To move from side to side; to sway.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To stray, wander.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To move back and forth repeatedly and somewhat loosely.",
              synonyms: [],
              antonyms: [],
              example: "The flag waved in the gentle breeze.",
            },
            {
              definition:
                "To move one’s hand back and forth (generally above the shoulders) in greeting or departure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(metonymic) To call attention to, or give a direction or command to, by a waving motion, as of the hand; to signify by waving; to beckon; to signal; to indicate.",
              synonyms: [],
              antonyms: [],
              example: "I waved goodbye from across the room.",
            },
            {
              definition: "To have an undulating or wavy form.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To raise into inequalities of surface; to give an undulating form or surface to.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To produce waves to the hair.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To swing and miss at a pitch.",
              synonyms: [],
              antonyms: [],
              example: "Jones waves at strike one.",
            },
            {
              definition: "To cause to move back and forth repeatedly.",
              synonyms: [],
              antonyms: [],
              example: "The starter waved the flag to begin the race.",
            },
            {
              definition:
                "(metonymic) To signal (someone or something) with a waving movement.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To fluctuate; to waver; to be in an unsettled state.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To move like a wave, or by floating; to waft.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A moving disturbance in the level of a body of liquid; an undulation.",
              synonyms: [],
              antonyms: [],
              example:
                "The wave traveled from the center of the lake before breaking on the shore.",
            },
            { definition: "The ocean.", synonyms: [], antonyms: [] },
            {
              definition:
                "A moving disturbance in the energy level of a field.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A shape that alternatingly curves in opposite directions.",
              synonyms: [],
              antonyms: [],
              example: "Her hair had a nice wave to it.",
            },
            {
              definition:
                "Any of a number of species of moths in the geometrid subfamily Sterrhinae, which have wavy markings on the wings.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A loose back-and-forth movement, as of the hands.",
              synonyms: [],
              antonyms: [],
              example: "He dismissed her with a wave of the hand.",
            },
            {
              definition:
                "A sudden unusually large amount of something that is temporarily experienced.",
              synonyms: ["rush"],
              antonyms: [],
              example:
                "A wave of emotion overcame her when she thought about her son who was killed in battle.",
            },
            {
              definition:
                "(by extension) One of the successive swarms of enemies sent to attack the player in certain games.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                '(usually "the wave") A group activity in a crowd imitating a wave going through water, where people in successive parts of the crowd stand and stretch upward, then sit.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["und", "Mexican wave", "rush"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/waive",
        "https://en.wiktionary.org/wiki/wave",
        "https://en.wiktionary.org/wiki/waves",
      ],
    },
  ],
  guess: [
    {
      word: "guess",
      phonetic: "/ɡɛs/",
      phonetics: [
        {
          text: "/ɡɛs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/guess-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1221244",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To reach a partly (or totally) unqualified conclusion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To solve by a correct conjecture; to conjecture rightly.",
              synonyms: [],
              antonyms: [],
              example: "He who guesses the riddle shall have the ring.",
            },
            {
              definition:
                "To suppose (introducing a proposition of uncertain plausibility).",
              synonyms: [],
              antonyms: [],
              example:
                "That album is quite hard to find, but I guess you could try ordering it online.",
            },
            {
              definition:
                'To think, conclude, or decide (without a connotation of uncertainty). Usually in first person: "I guess".',
              synonyms: [],
              antonyms: [],
              example: '"I guess I\'ll go to bed."',
            },
            {
              definition: "To hit upon or reproduce by memory.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["assume", "hypothesize", "speculate", "take a stab"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/guess"],
    },
    {
      word: "guess",
      phonetic: "/ɡɛs/",
      phonetics: [
        {
          text: "/ɡɛs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/guess-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1221244",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A prediction about the outcome of something, typically made without factual evidence or support.",
              synonyms: ["estimate", "hypothesis", "prediction"],
              antonyms: [],
              example: "If you don't know the answer, take a guess.",
            },
          ],
          synonyms: ["estimate", "hypothesis", "prediction"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/guess"],
    },
  ],
  dance: [
    {
      word: "dance",
      phonetic: "[dæːns]",
      phonetics: [
        { text: "[dæːns]", audio: "" },
        { text: "[dans]", audio: "" },
        { text: "[dans]", audio: "" },
        { text: "[däːns]", audio: "" },
        {
          text: "[dɑːns]",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dance-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=8789612",
        },
        {
          text: "/dæns/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dance-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1230720",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sequence of rhythmic steps or movements usually performed to music, for pleasure or as a form of social interaction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A social gathering where dancing is the main activity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A normally horizontal stripe called a fess that has been modified to zig-zag across the center of a coat of arms from dexter to sinister.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A genre of modern music characterised by sampled beats, repetitive rhythms and few lyrics.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The art, profession, and study of dancing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A piece of music with a particular dance rhythm.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A battle of wits, especially one commonly fought between two rivals.",
              synonyms: [],
              antonyms: [],
              example: "So how much longer are we gonna do this dance?",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To move with rhythmic steps or movements, especially in time to music.",
              synonyms: [],
              antonyms: [],
              example: "I danced with her all night long.",
            },
            {
              definition: "To leap or move lightly and rapidly.",
              synonyms: [],
              antonyms: [],
              example:
                "His eyes danced with pleasure as he spoke.   She accused her political opponent of dancing around the issue instead of confronting it.",
            },
            {
              definition: "To perform the steps to.",
              synonyms: [],
              antonyms: [],
              example: "Have you ever danced the tango?",
            },
            {
              definition: "To cause to dance, or move nimbly or merrily about.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make love or have sex.",
              synonyms: [],
              antonyms: [],
              example: "You make me feel like dancing.",
            },
          ],
          synonyms: ["throw shapes", "do the deed", "get some", "have sex"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dance"],
    },
  ],
  spoke: [
    {
      word: "spoke",
      phonetic: "/spəʊk/",
      phonetics: [
        {
          text: "/spəʊk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spoke-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1227535",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A support structure that connects the axle or the hub of a wheel to the rim.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A projecting handle of a steering wheel.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A rung of a ladder.", synonyms: [], antonyms: [] },
            {
              definition:
                "A device for fastening the wheel of a vehicle to prevent it from turning when going downhill.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the outlying points in a hub-and-spoke model of transportation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To furnish (a wheel) with spokes.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spoke"],
    },
    {
      word: "spoke",
      phonetic: "/spəʊk/",
      phonetics: [
        {
          text: "/spəʊk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spoke-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1227535",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To communicate with one's voice, to say words out loud.",
              synonyms: [],
              antonyms: [],
              example: "I was so surprised I couldn't speak.",
            },
            {
              definition: "To have a conversation.",
              synonyms: [],
              antonyms: [],
              example: "It's been ages since we've spoken.",
            },
            {
              definition:
                "(by extension) To communicate or converse by some means other than orally, such as writing or facial expressions.",
              synonyms: [],
              antonyms: [],
              example: "Actions speak louder than words.",
            },
            {
              definition:
                "To deliver a message to a group; to deliver a speech.",
              synonyms: [],
              antonyms: [],
              example:
                "This evening I shall speak on the topic of correct English usage.",
            },
            {
              definition: "To be able to communicate in a language.",
              synonyms: [],
              antonyms: [],
              example: "He speaks Mandarin fluently.",
            },
            {
              definition: "To utter.",
              synonyms: [],
              antonyms: [],
              example: "I was so surprised that I couldn't speak a word.",
            },
            {
              definition:
                "To communicate (some fact or feeling); to bespeak, to indicate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(sometimes humorous) To understand (as though it were a language).",
              synonyms: [],
              antonyms: [],
              example: "So you can program in C. But do you speak C++?",
            },
            {
              definition: "To produce a sound; to sound.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of a bird, to be able to vocally reproduce words or phrases from a human language.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To address; to accost; to speak to.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["articulate", "talk", "verbalize"],
          antonyms: ["be silent"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/speak",
        "https://en.wiktionary.org/wiki/spoke",
      ],
    },
  ],
  break: [
    {
      word: "break",
      phonetic: "/bɹeɪk/",
      phonetics: [
        {
          text: "/bɹeɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/break-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=360847",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An instance of breaking something into two or more pieces.",
              synonyms: [],
              antonyms: [],
              example: "The femur has a clean break and so should heal easily.",
            },
            {
              definition:
                "A physical space that opens up in something or between two things.",
              synonyms: [],
              antonyms: [],
              example:
                "He waited minutes for a break in the traffic to cross the highway.",
            },
            {
              definition: "A rest or pause, usually from work.",
              synonyms: [],
              antonyms: [],
              example: "Let’s take a five-minute break.",
            },
            {
              definition: "A time for students to talk or play.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A short holiday.",
              synonyms: [],
              antonyms: [],
              example: "a weekend break on the Isle of Wight",
            },
            {
              definition: "A temporary split with a romantic partner.",
              synonyms: [],
              antonyms: [],
              example: "I think we need a break.",
            },
            {
              definition:
                "An interval or intermission between two parts of a performance, for example a theatre show, broadcast, or sports game.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A significant change in circumstance, attitude, perception, or focus of attention.",
              synonyms: [],
              antonyms: [],
              example: "big break",
            },
            {
              definition: "The beginning (of the morning).",
              synonyms: [],
              antonyms: [],
              example: "at the break of day",
            },
            {
              definition: "An act of escaping.",
              synonyms: [],
              antonyms: [],
              example: "It was a clean break.",
            },
            {
              definition:
                "The separation between lines, paragraphs or pages of a written text.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A keystroke or other signal that causes a program to terminate or suspend execution.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A point or condition in a program at which operation may be suspended during debugging so that the state of the program at that point can be investigated. A breakpoint.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A change, particularly the end of a spell of persistent good or bad weather.",
              synonyms: [],
              antonyms: [],
            },
            { definition: ":", synonyms: [], antonyms: [] },
            {
              definition:
                "A large four-wheeled carriage, having a straight body and calash top, with the driver's seat in front and the footman's behind.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(equitation) A sharp bit or snaffle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A short section of music, often between verses, in which some performers stop while others continue.",
              synonyms: [],
              antonyms: [],
              example:
                "The fiddle break was amazing; it was a pity the singer came back in on the wrong note.",
            },
            {
              definition:
                "The point in the musical scale at which a woodwind instrument is designed to overblow, that is, to move from its lower to its upper register.",
              synonyms: [],
              antonyms: [],
              example:
                "Crossing the break smoothly is one of the first lessons the young clarinettist needs to master.",
            },
            {
              definition:
                "Usu. plural An area along a river that features steep banks, bluffs, or gorges (e.g., Upper Missouri River Breaks National Monument, US).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "crack of dawn",
            "split",
            "breach",
            "gap",
            "space",
            "time-out",
            "day off",
            "time off",
            "playtime",
            "recess",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To separate into two or more pieces, to fracture or crack, by a process that cannot easily be reversed for reassembly.",
              synonyms: [],
              antonyms: [],
              example: "If the vase falls to the floor, it might break.",
            },
            {
              definition:
                "To divide (something, often money) into smaller units.",
              synonyms: [],
              antonyms: [],
              example: "Can you break a hundred-dollar bill for me?",
            },
            {
              definition:
                "To cause (a person or animal) to lose spirit or will; to crush the spirits of.",
              synonyms: [],
              antonyms: [],
              example: "Her child's death broke Angela.",
            },
            {
              definition: "To be crushed, or overwhelmed with sorrow or grief.",
              synonyms: [],
              antonyms: [],
              example: "My heart is breaking.",
            },
            {
              definition:
                "To interrupt; to destroy the continuity of; to dissolve or terminate.",
              synonyms: [],
              antonyms: [],
              example:
                "I had won four games in a row, but now you've broken my streak of luck.",
            },
            {
              definition: "To ruin financially.",
              synonyms: [],
              antonyms: [],
              example: "The recession broke some small businesses.",
            },
            {
              definition: "To violate, to not adhere to.",
              synonyms: [],
              antonyms: [],
              example: "He broke his vows by cheating on his wife.",
            },
            {
              definition:
                "(of a fever) To pass the most dangerous part of the illness; to go down, in terms of temperature.",
              synonyms: [],
              antonyms: [],
              example:
                "Susan's fever broke at about 3 AM, and the doctor said the worst was over.",
            },
            {
              definition: "(of a spell of settled weather) To end.",
              synonyms: [],
              antonyms: [],
              example:
                "The forecast says the hot weather will break by midweek.",
            },
            {
              definition: "(of a storm) To begin; to end.",
              synonyms: [],
              antonyms: [],
              example:
                "Around midday the storm broke, and the afternoon was calm and sunny.",
            },
            {
              definition: "(of morning, dawn, day etc.) To arrive.",
              synonyms: [],
              antonyms: [],
              example: "Morning has broken.",
            },
            {
              definition:
                "To render (a game) unchallenging by altering its rules or exploiting loopholes or weaknesses in them in a way that gives a player an unfair advantage.",
              synonyms: [],
              antonyms: [],
              example:
                "Changing the rules to let white have three extra queens would break chess.",
            },
            {
              definition:
                "To stop, or to cause to stop, functioning properly or altogether.",
              synonyms: [],
              antonyms: [],
              example: "Did you two break the trolley by racing with it?",
            },
            {
              definition: "To cause (a barrier) to no longer bar.",
              synonyms: [],
              antonyms: [],
              example: "break a seal",
            },
            {
              definition:
                "To destroy the arrangement of; to throw into disorder; to pierce.",
              synonyms: [],
              antonyms: [],
              example:
                "The cavalry were not able to break the British squares.",
            },
            {
              definition:
                "(of a wave of water) To collapse into surf, after arriving in shallow water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To burst forth; to make its way; to come into view.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To interrupt or cease one's work or occupation temporarily.",
              synonyms: [],
              antonyms: [],
              example: "Let's break for lunch.",
            },
            {
              definition:
                "To interrupt (a fall) by inserting something so that the falling object does not (immediately) hit something else beneath.",
              synonyms: [],
              antonyms: [],
              example:
                "He survived the jump out the window because the bushes below broke his fall.",
            },
            {
              definition: "To disclose or make known an item of news, etc.",
              synonyms: [],
              antonyms: [],
              example:
                "I don't know how to break this to you, but your cat is not coming back.",
            },
            {
              definition: "(of a sound) To become audible suddenly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To change a steady state abruptly.",
              synonyms: [],
              antonyms: [],
              example: "His coughing broke the silence.",
            },
            {
              definition: "To suddenly become.",
              synonyms: [],
              antonyms: [],
              example:
                "The arrest was standard, when suddenly the suspect broke ugly.",
            },
            {
              definition: "Of a male voice, to become deeper at puberty.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of a voice, to alter in type due to emotion or strain: in men generally to go up, in women sometimes to go down; to crack.",
              synonyms: [],
              antonyms: [],
              example: "His voice breaks when he gets emotional.",
            },
            {
              definition:
                "To surpass or do better than (a specific number), to do better than (a record), setting a new record.",
              synonyms: [],
              antonyms: [],
              example: "He broke the men's 100-meter record.",
            },
            { definition: ":", synonyms: [], antonyms: [] },
            {
              definition:
                "(most often in the passive tense) To demote, to reduce the military rank of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To end (a connection), to disconnect.",
              synonyms: [],
              antonyms: [],
              example:
                "I couldn't hear a thing he was saying, so I broke the connection and called him back.",
            },
            {
              definition: "(of an emulsion) To demulsify.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To counter-attack", synonyms: [], antonyms: [] },
            {
              definition:
                "To lay open, as a purpose; to disclose, divulge, or communicate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To become weakened in constitution or faculties; to lose health or strength.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fail in business; to become bankrupt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To destroy the strength, firmness, or consistency of.",
              synonyms: [],
              antonyms: [],
              example: "to break flax",
            },
            {
              definition:
                "To destroy the official character and standing of; to cashier; to dismiss.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make an abrupt or sudden change; to change the gait.",
              synonyms: [],
              antonyms: [],
              example: "to break into a run or gallop",
            },
            {
              definition: "To fall out; to terminate friendship.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To terminate the execution of a program before normal completion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To suspend the execution of a program during debugging so that the state of the program can be investigated.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "crack",
            "fracture",
            "burst",
            "bust",
            "shatter",
            "shear",
            "smash",
            "split",
            "break down",
            "bust",
            "fail",
            "go down",
            "contravene",
            "go against",
            "violate",
            "break in",
            "subject",
            "tame",
          ],
          antonyms: [
            "hold",
            "assemble",
            "fix",
            "join",
            "mend",
            "put together",
            "repair",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/break"],
    },
    {
      word: "break",
      phonetic: "/bɹeɪk/",
      phonetics: [
        {
          text: "/bɹeɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/break-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=360847",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A section of extended repetition of the percussion break to a song, created by a hip-hop DJ as rhythmic dance music.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/break"],
    },
  ],
  cause: [
    {
      word: "cause",
      phonetic: "/kɔːz/",
      phonetics: [
        { text: "/kɔːz/", audio: "" },
        {
          text: "/kɔz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cause-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=840599",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(often with of, typically of adverse results) The source of, or reason for, an event or action; that which produces or effects a result.",
              synonyms: [],
              antonyms: [],
              example:
                "They identified a burst pipe as the cause of the flooding.",
            },
            {
              definition:
                "(especially with for and a bare noun) Sufficient reason for a state, as of emotion.",
              synonyms: ["grounds", "justification"],
              antonyms: [],
              example: "The end of the war was a cause for celebration.",
            },
            {
              definition:
                "A goal, aim or principle, especially one which transcends purely selfish ends.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Sake; interest; advantage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any subject of discussion or debate; a matter; an affair.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A suit or action in court; any legal process by which a party endeavors to obtain his claim, or what he regards as his right; case; ground of action.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["grounds", "justification"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To set off an event or action.",
              synonyms: [],
              antonyms: [],
              example: "The lightning caused thunder.",
            },
            {
              definition:
                "(ditransitive) To actively produce as a result, by means of force or authority.",
              synonyms: [],
              antonyms: [],
              example:
                "His dogged determination caused the fundraising to be successful.",
            },
            {
              definition:
                "To assign or show cause; to give a reason; to make excuse.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cause"],
    },
  ],
  radio: [
    {
      word: "radio",
      phonetic: "/ˈɹeɪdiˌəʊ/",
      phonetics: [
        { text: "/ˈɹeɪdiˌəʊ/", audio: "" },
        {
          text: "/ˈɹeɪdiˌoʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/radio-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1755206",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The technology that allows for the transmission of sound or other signals by modulation of electromagnetic waves.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A device that can capture (receive) the signal sent over radio waves and render the modulated signal as sound.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "On-board entertainment system in a car, usually including a radio receiver as well as the capability to play audio from recorded media.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A device that can transmit radio signals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The continuous broadcasting of sound via the Internet in the style of traditional radio.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["receiver", "tuner", "wireless"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To use two-way radio to transmit (a message) (to another radio or other radio operator).",
              synonyms: [],
              antonyms: [],
              example:
                "I think the boat is sinking; we'd better radio for help. / I radioed him already. / Radio the coordinates this time. / OK. I radioed them the coordinates.",
            },
            {
              definition:
                "To order or assist (to a location), using telecommunications.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/radio"],
    },
  ],
  weeks: [
    {
      word: "weeks",
      phonetic: "/wiːks/",
      phonetics: [
        {
          text: "/wiːks/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/weeks-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1230774",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Any period of seven consecutive days.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A period of seven days beginning with Sunday or Monday.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A period of five days beginning with Monday.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A subdivision of the month into longer periods of work days punctuated by shorter weekend periods of days for markets, rest, or religious observation such as a sabbath.",
              synonyms: [],
              antonyms: [],
              example:
                "A 4-day week consists of Monday, Tuesday, Wednesday, and Thursday.",
            },
            {
              definition:
                "Seven days after (sometimes before) a specified date.",
              synonyms: [],
              antonyms: [],
              example: "I'll see you Thursday week.",
            },
          ],
          synonyms: ["hebdomad", "sennight"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/week",
        "https://en.wiktionary.org/wiki/weeks",
      ],
    },
  ],
  lands: [
    {
      word: "lands",
      phonetic: "/lændz/",
      phonetics: [
        {
          text: "/lændz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lands-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1669326",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The part of Earth which is not covered by oceans or other bodies of water.",
              synonyms: [],
              antonyms: [],
              example: "Most insects live on land.",
            },
            {
              definition:
                "Real estate or landed property; a partitioned and measurable area which is owned and on which buildings can be erected.",
              synonyms: [],
              antonyms: [],
              example: "There are 50 acres of land in this estate.",
            },
            { definition: "A country or region.", synonyms: [], antonyms: [] },
            {
              definition:
                "A person's country of origin and/or homeplace; homeland.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The soil, in respect to its nature or quality for farming.",
              synonyms: [],
              antonyms: [],
              example: "wet land; good or bad land for growing potatoes",
            },
            {
              definition: "(often in combination) realm, domain.",
              synonyms: [],
              antonyms: [],
              example: "I'm going to Disneyland.",
            },
            {
              definition:
                "The ground left unploughed between furrows; any of several portions into which a field is divided for ploughing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A shock or fright.",
              synonyms: [],
              antonyms: [],
              example: "He got an awful land when the police arrived.",
            },
            {
              definition:
                "A conducting area on a board or chip which can be used for connecting wires.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In a compact disc or similar recording medium, an area of the medium which does not have pits.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(travel) The non-airline portion of an itinerary. Hotel, tours, cruises, etc.",
              synonyms: [],
              antonyms: [],
              example:
                "Our city offices sell a lot more land than our suburban offices.",
            },
            { definition: "The ground or floor.", synonyms: [], antonyms: [] },
            {
              definition:
                "The lap of the strakes in a clinker-built boat; the lap of plates in an iron vessel; called also landing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In any surface prepared with indentations, perforations, or grooves, that part of the surface which is not so treated, such as the level part of a millstone between the furrows.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A group of dwellings or tenements under one roof and having a common entry.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To descend to a surface, especially from the air.",
              synonyms: [],
              antonyms: [],
              example: "The plane is about to land.",
            },
            {
              definition: "To alight, to descend from a vehicle.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To come into rest.", synonyms: [], antonyms: [] },
            {
              definition:
                "To arrive at land, especially a shore, or a dock, from a body of water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To bring to land.",
              synonyms: [],
              antonyms: [],
              example: "It can be tricky to land a helicopter.",
            },
            {
              definition: "To acquire; to secure.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To deliver.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/land",
        "https://en.wiktionary.org/wiki/lands",
      ],
    },
  ],
  basic: [
    {
      word: "basic",
      phonetic: "/ˈbeɪsɪk/",
      phonetics: [
        {
          text: "/ˈbeɪsɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/basic-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241195",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A necessary commodity, a staple requirement.",
              synonyms: [],
              antonyms: [],
              example: "Rice is a basic for many Asian villagers.",
            },
            {
              definition:
                "An elementary building block, e.g. a fundamental piece of knowledge.",
              synonyms: [],
              antonyms: [],
              example: "Arithmetic is a basic for the study of mathematics.",
            },
            {
              definition: "Basic training.",
              synonyms: [],
              antonyms: [],
              example: "The drill sergeants gave him hell in basic.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Necessary, essential for life or some process.",
              synonyms: [],
              antonyms: [],
              example: "Flour is a basic ingredient of bread.",
            },
            {
              definition: "Elementary, simple, fundamental, merely functional.",
              synonyms: [],
              antonyms: [],
              example: "The Hotel Sparta’s accommodation is very basic.",
            },
            {
              definition:
                "Of or pertaining to a base; having a pH greater than 7.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Unremarkable or uninteresting; boring; uncool.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["alkaline"],
          antonyms: ["acidic"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/basic"],
    },
  ],
  liked: [
    {
      word: "liked",
      phonetic: "/laɪkt/",
      phonetics: [
        {
          text: "/laɪkt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/liked-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1648267",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To enjoy, be pleased by; favor; be in favor of.",
              synonyms: [],
              antonyms: ["dislike", "hate", "mislike"],
              example: "I like hamburgers.",
            },
            { definition: "To please.", synonyms: [], antonyms: [] },
            {
              definition:
                "To derive pleasure of, by or with someone or something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To prefer and maintain (an action) as a regular habit or activity.",
              synonyms: [],
              antonyms: [],
              example: "I like to go to the dentist every six months.",
            },
            {
              definition:
                "To have an appearance or expression; to look; to seem to be (in a specified condition).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To come near; to avoid with difficulty; to escape narrowly.",
              synonyms: [],
              antonyms: [],
              example: "He liked to have been too late.",
            },
            {
              definition:
                "To find attractive; to prefer the company of; to have mild romantic feelings for.",
              synonyms: ["enjoy", "fancy", "love"],
              antonyms: ["dislike", "hate", "mislike"],
              example: "I really like Sandra but don't know how to tell her.",
            },
            { definition: "To liken; to compare.", synonyms: [], antonyms: [] },
            {
              definition:
                "To show support for, or approval of, something posted on the Internet by marking it with a vote.",
              synonyms: [],
              antonyms: ["unlike"],
              example:
                "I can't stand Bloggs' tomato ketchup, but I liked it on Facebook so I could enter a competition.",
            },
            {
              definition:
                "(with 'would' and in certain other phrases) To want, desire. See also would like.",
              synonyms: [],
              antonyms: [],
              example: "We could go to the museum if you like.",
            },
          ],
          synonyms: ["enjoy", "fancy", "love"],
          antonyms: [
            "dislike",
            "hate",
            "mislike",
            "dislike",
            "hate",
            "mislike",
            "unlike",
          ],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To be likely.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/like",
        "https://en.wiktionary.org/wiki/liked",
      ],
    },
  ],
  trade: [
    {
      word: "trade",
      phonetic: "/tɹeɪd/",
      phonetics: [
        {
          text: "/tɹeɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/trade-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9028884",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/tɹeɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/trade-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1197446",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Buying and selling of goods and services on a market.",
              synonyms: ["commerce"],
              antonyms: [],
            },
            {
              definition: "A particular instance of buying or selling.",
              synonyms: ["barter", "deal"],
              antonyms: [],
              example: "I did no trades with them once the rumors started.",
            },
            {
              definition:
                "An instance of bartering items in exchange for one another.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Those who perform a particular kind of skilled work.",
              synonyms: ["business"],
              antonyms: [],
              example:
                "The skilled trades were the first to organize modern labor unions.",
            },
            {
              definition:
                "Those engaged in an industry or group of related industries.",
              synonyms: [],
              antonyms: [],
              example: "It is not a retail showroom. It is only for the trade.",
            },
            {
              definition: "The skilled practice of a practical occupation.",
              synonyms: ["craft"],
              antonyms: [],
              example: "He learned his trade as an apprentice.",
            },
            {
              definition:
                "An occupation in the secondary sector, as opposed to an agricultural, professional or military one.",
              synonyms: [],
              antonyms: [],
              example:
                "After failing his entrance exams, he decided to go into a trade.",
            },
            {
              definition:
                "The business given to a commercial establishment by its customers.",
              synonyms: ["patronage"],
              antonyms: [],
              example: "Even before noon there was considerable trade.",
            },
            {
              definition:
                "(chiefly in the plural) Steady winds blowing from east to west above and below the equator.",
              synonyms: [],
              antonyms: [],
              example: "They rode the trades going west.",
            },
            {
              definition:
                "(only as plural) A publication intended for participants in an industry or related group of industries.",
              synonyms: [],
              antonyms: [],
              example: "Rumors about layoffs are all over the trades.",
            },
            {
              definition: "A brief sexual encounter.",
              synonyms: [],
              antonyms: [],
              example: "Josh picked up some trade last night.",
            },
            {
              definition: "Instruments of any occupation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Refuse or rubbish from a mine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A track or trail; a way; a path; passage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Course; custom; practice; occupation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "barter",
            "deal",
            "business",
            "commerce",
            "craft",
            "patronage",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To engage in trade.",
              synonyms: ["deal"],
              antonyms: [],
              example: "This company trades (in) precious metal.",
            },
            {
              definition:
                "To be traded at a certain price or under certain conditions.",
              synonyms: [],
              antonyms: [],
              example: "Apple is trading at $200.",
            },
            {
              definition: "To give (something) in exchange for.",
              synonyms: ["exchange", "swap", "switch", "truck"],
              antonyms: [],
              example: "Will you trade your precious watch for my earring?",
            },
            {
              definition:
                "To give someone a plant and receive a different one in return.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To do business; offer for sale as for one's livelihood.",
              synonyms: ["do business"],
              antonyms: [],
            },
            {
              definition:
                "To have dealings; to be concerned or associated (with).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "deal",
            "do business",
            "exchange",
            "swap",
            "switch",
            "truck",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/trade"],
    },
  ],
  fresh: [
    {
      word: "fresh",
      phonetic: "/fɹɛʃ/",
      phonetics: [
        {
          text: "/fɹɛʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fresh-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1239722",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A rush of water, along a river or onto the land; a flood.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A stream or spring of fresh water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The mingling of fresh water with salt in rivers or bays, as by means of a flood of fresh water flowing toward or into the sea.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(commercial fishing) To pack (fish) loosely on ice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To flood or dilute an area of salt water with flowing fresh water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of wind) To become stronger.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To rebore the barrel of a rifle or shotgun.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To update.", synonyms: [], antonyms: [] },
            { definition: "To freshen up.", synonyms: [], antonyms: [] },
            { definition: "To renew.", synonyms: [], antonyms: [] },
            {
              definition: "(of a dairy cow) to give birth to a calf.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Newly produced or obtained; recent.",
              synonyms: [],
              antonyms: [],
              example: "He followed the fresh hoofprints to find the deer.",
            },
            {
              definition: "(of food) Not cooked, dried, frozen, or spoiled.",
              synonyms: [],
              antonyms: ["stale"],
              example:
                "After taking a beating in the boxing ring, the left side of his face looked like fresh meat.",
            },
            {
              definition: "(of plant material) Still green and not dried.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Invigoratingly cool and refreshing.",
              synonyms: ["cool"],
              antonyms: [],
              example: "What a nice fresh breeze.",
            },
            {
              definition: "(of water) Without salt; not saline.",
              synonyms: [],
              antonyms: ["saline"],
              example:
                "After a day at sea it was good to feel the fresh water of the stream.",
            },
            {
              definition: "Rested; not tired or fatigued.",
              synonyms: ["rested"],
              antonyms: ["tired"],
            },
            {
              definition: "In a raw or untried state; uncultured; unpracticed.",
              synonyms: [],
              antonyms: [],
              example: "a fresh hand on a ship",
            },
            { definition: "Youthful; florid.", synonyms: [], antonyms: [] },
            {
              definition: "Good, fashionable.",
              synonyms: ["cool", "fashionable"],
              antonyms: [],
              example: "a fresh pair of sneakers",
            },
            { definition: "Tipsy; drunk.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["cool", "cool", "fashionable", "rested"],
          antonyms: ["saline", "stale", "tired"],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Recently; just recently; most recently",
              synonyms: [],
              antonyms: [],
              example: "We are fresh out of milk.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fresh"],
    },
    {
      word: "fresh",
      phonetic: "/fɹɛʃ/",
      phonetics: [
        {
          text: "/fɹɛʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fresh-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1239722",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Rude, cheeky, or inappropriate; presumptuous; disrespectful; forward.",
              synonyms: [],
              antonyms: [],
              example: "No one liked his fresh comments.",
            },
            {
              definition:
                "Sexually aggressive or forward; prone to caress too eagerly; overly flirtatious.",
              synonyms: [],
              antonyms: [],
              example: "Hey, don't get fresh with me!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fresh"],
    },
  ],
  final: [
    {
      word: "final",
      phonetic: "/ˈfaɪ.nəl/",
      phonetics: [
        {
          text: "/ˈfaɪ.nəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/final-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1669308",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A final examination; a test or examination given at the end of a term or class; the test that concludes a class.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The last round, game or match in a contest, after which the winner is determined.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A contest that narrows a field of contestants (finalists) to ranked positions, usually in numbered places (1st place/prize, 2nd place/prize, etc.) or a winner and numbered runners-up (1st runner-up, etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The final part of a syllable, the combination of medial and rime in phonetics and phonology.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The tonic or keynote of a Gregorian mode, and hence the final note of any conventional melody played in that mode.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Last; ultimate.",
              synonyms: [],
              antonyms: [],
              example: "final solution;   the final day of a school term",
            },
            {
              definition: "Conclusive; decisive.",
              synonyms: [],
              antonyms: [],
              example:
                "a final judgment;   the battle of Waterloo brought the contest to a final issue",
            },
            {
              definition:
                "Respecting an end or object to be gained; respecting the purpose or ultimate end in view.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(grammar) Expressing purpose; as in the term final clause.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Word-final, occurring at the end of a word.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dernier", "endly", "terminal"],
          antonyms: ["early", "first", "initial"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/final"],
    },
  ],
  fight: [
    {
      word: "fight",
      phonetic: "[fʌɪt]",
      phonetics: [
        { text: "[fʌɪt]", audio: "" },
        {
          text: "/faɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fight-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9014436",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "[fʌɪt]",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fight-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=424743",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To contend in physical conflict, either singly or in war, battle etc.",
              synonyms: [],
              antonyms: [],
              example:
                "A wounded animal will fight like a maniac, relentless, savage and murderous.",
            },
            {
              definition:
                "To contend in physical conflict with each other, either singly or in war, battle etc.",
              synonyms: [],
              antonyms: [],
              example:
                "The two boxers have been fighting for more than half an hour.",
            },
            {
              definition:
                "To strive for something; to campaign or contend for success.",
              synonyms: [],
              antonyms: [],
              example: "He fought for the Democrats in the last election.",
            },
            {
              definition: "To conduct or engage in (battle, warfare etc.).",
              synonyms: [],
              antonyms: [],
              example: "The battle was fought just over that hill.",
            },
            {
              definition:
                "To engage in combat with; to oppose physically, to contest with.",
              synonyms: [],
              antonyms: [],
              example: "My grandfather fought the Nazis in World War II.",
            },
            {
              definition: "To try to overpower; to fiercely counteract.",
              synonyms: [],
              antonyms: [],
              example: "The government pledged to fight corruption.",
            },
            {
              definition:
                "To cause to fight; to manage or manoeuvre in a fight.",
              synonyms: [],
              antonyms: [],
              example: "to fight cocks; to fight one's ship",
            },
            {
              definition:
                "Of colours or other design elements: to clash; to fail to harmonize.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fight"],
    },
    {
      word: "fight",
      phonetic: "[fʌɪt]",
      phonetics: [
        { text: "[fʌɪt]", audio: "" },
        {
          text: "/faɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fight-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9014436",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "[fʌɪt]",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fight-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=424743",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An occasion of fighting.",
              synonyms: [],
              antonyms: [],
              example:
                "One of them got stuck in a chokehold and got stabbed to death during the fight.",
            },
            {
              definition: "A battle between opposing armies.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A physical confrontation or combat between two or more people or groups.",
              synonyms: [],
              antonyms: [],
              example: "Watch your language, are you looking for a fight?",
            },
            {
              definition: "A boxing or martial arts match.",
              synonyms: [],
              antonyms: [],
              example:
                "I'm going to Nick’s to watch the big fight tomorrow night.",
            },
            {
              definition:
                "A conflict, possibly nonphysical, with opposing ideas or forces; strife.",
              synonyms: [],
              antonyms: [],
              example: "I'll put up a fight to save this company.",
            },
            {
              definition: "The will or ability to fight.",
              synonyms: [],
              antonyms: [],
              example:
                "That little guy has a bit of fight in him after all.   As soon as he saw the size of his opponent, all the fight went out of him.",
            },
            {
              definition: "A screen for the combatants in ships.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fight"],
    },
  ],
  meant: [
    {
      word: "meant",
      phonetic: "/ˈmɛnt/",
      phonetics: [
        {
          text: "/ˈmɛnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/meant-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1217923",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To intend.", synonyms: [], antonyms: [] },
            {
              definition: "To convey (a meaning).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To have conviction in (something said or expressed); to be sincere in (what one says).",
              synonyms: [],
              antonyms: [],
              example: "Does she really mean what she said to him last night?",
            },
            {
              definition:
                "To cause or produce (a given result); to bring about (a given result).",
              synonyms: [],
              antonyms: [],
              example: "One faltering step means certain death.",
            },
            {
              definition:
                "(usually with to) To be of some level of importance.",
              synonyms: [],
              antonyms: [],
              example: "Formality and titles mean nothing in their circle.",
            },
          ],
          synonyms: [
            "bring about",
            "cause",
            "lead to",
            "result in",
            "convey",
            "indicate",
            "signify",
            "imply",
            "intend",
            "serious",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mean",
        "https://en.wiktionary.org/wiki/meant",
      ],
    },
  ],
  drive: [
    {
      word: "drive",
      phonetic: "/dɹaɪv/",
      phonetics: [
        {
          text: "/dɹaɪv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/drive-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899546",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/dɹaɪv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/drive-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9027991",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/dɹaɪv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/drive-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1230599",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Motivation to do or achieve something; ability coupled with ambition.",
              synonyms: [],
              antonyms: [],
              example:
                "Crassus had wealth and wit, but Pompey had drive and Caesar as much again.",
            },
            {
              definition:
                "Violent or rapid motion; a rushing onward or away; especially, a forced or hurried dispatch of business.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An act of driving animals forward, as to be captured, hunted etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sustained advance in the face of the enemy to take a strategic objective.",
              synonyms: [],
              antonyms: [],
              example:
                "Napoleon's drive on Moscow was as determined as it was disastrous.",
            },
            {
              definition:
                "A mechanism used to power or give motion to a vehicle or other machine or machine part.",
              synonyms: [],
              antonyms: [],
              example: "Some old model trains have clockwork drives.",
            },
            {
              definition:
                "A trip made in a vehicle (now generally in a motor vehicle).",
              synonyms: [],
              antonyms: [],
              example: "It was a long drive.",
            },
            {
              definition: "A driveway.",
              synonyms: [],
              antonyms: [],
              example: "The mansion had a long, tree-lined drive.",
            },
            {
              definition: "A type of public roadway.",
              synonyms: [],
              antonyms: [],
              example: "Beverly Hills’ most famous street is Rodeo Drive.",
            },
            {
              definition:
                "A place suitable or agreeable for driving; a road prepared for driving.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Desire or interest.", synonyms: [], antonyms: [] },
            {
              definition:
                "An apparatus for reading and writing data to or from a mass storage device such as a disk, as a floppy drive.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A mass storage device in which the mechanism for reading and writing data is integrated with the mechanism for storing data, as a hard drive, a flash drive.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A stroke made with a driver.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A ball struck in a flat trajectory.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A type of shot played by swinging the bat in a vertical arc, through the line of the ball, and hitting it along the ground, normally between cover and midwicket.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A straight level shot or pass.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An offensive possession, generally one consisting of several plays and/ or first downs, often leading to a scoring opportunity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A charity event such as a fundraiser, bake sale, or toy drive.",
              synonyms: [],
              antonyms: [],
              example: "a whist drive; a beetle drive",
            },
            {
              definition:
                "(retail) A campaign aimed at selling more of a certain product, e.g. by offering a discount.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An impression or matrix formed by a punch drift.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A collection of objects that are driven; a mass of logs to be floated down a river.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "line drive",
            "disk drive",
            "approach",
            "driveway",
            "engine",
            "mechanism",
            "motor",
            "desire",
            "impetus",
            "impulse",
            "urge",
            "avenue",
            "boulevard",
            "road",
            "street",
            "ambition",
            "enthusiasm",
            "get-up-and-go",
            "motivation",
            "self-motivation",
            "verve",
            "attack",
            "push",
            "ride",
            "spin",
            "trip",
          ],
          antonyms: ["inertia", "laziness", "phlegm", "sloth"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To provide an impetus for motion or other physical change, to move an object by means of the provision of force thereto.",
              synonyms: [],
              antonyms: [],
              example: "You drive nails into wood with a hammer.",
            },
            {
              definition:
                "To provide an impetus for a non-physical change, especially a change in one's state of mind.",
              synonyms: [],
              antonyms: [],
              example:
                "My wife's constant harping about the condition of the house threatens to drive me to distraction.",
            },
            {
              definition:
                "To displace either physically or non-physically, through the application of force.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause intrinsic motivation through the application or demonstration of force: to impel or urge onward thusly, to compel to move on, to coerce, intimidate or threaten.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(especially of animals) To impel or urge onward by force; to push forward; to compel to move on.",
              synonyms: [],
              antonyms: [],
              example:
                "to drive twenty thousand head of cattle from Texas to the Kansas railheads; to drive sheep out of a field",
            },
            {
              definition:
                "To direct a vehicle powered by a horse, ox or similar animal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause animals to flee out of.",
              synonyms: [],
              antonyms: [],
              example:
                "The beaters drove the brambles, causing a great rush of rabbits and other creatures.",
            },
            {
              definition: "To move (something) by hitting it with great force.",
              synonyms: [],
              antonyms: [],
              example: "You drive nails into wood with a hammer.",
            },
            {
              definition: "To cause (a mechanism) to operate.",
              synonyms: [],
              antonyms: [],
              example: "The pistons drive the crankshaft.",
            },
            {
              definition: "To operate (a wheeled motorized vehicle).",
              synonyms: [],
              antonyms: [],
              example: "drive a car",
            },
            {
              definition: "To motivate; to provide an incentive for.",
              synonyms: [],
              antonyms: [],
              example: "What drives a person to run a marathon?",
            },
            {
              definition: "To compel (to do something).",
              synonyms: [],
              antonyms: [],
              example: "Their debts finally drove them to sell the business.",
            },
            {
              definition: "To cause to become.",
              synonyms: [],
              antonyms: [],
              example:
                "This constant complaining is going to drive me to insanity.   You are driving me crazy!",
            },
            {
              definition: "To hit the ball with a drive.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To travel by operating a wheeled motorized vehicle.",
              synonyms: [],
              antonyms: [],
              example: "I drive to work every day.",
            },
            {
              definition:
                "To convey (a person, etc) in a wheeled motorized vehicle.",
              synonyms: [],
              antonyms: [],
              example: "My wife drove me to the airport.",
            },
            { definition: "To move forcefully.", synonyms: [], antonyms: [] },
            {
              definition:
                "To be moved or propelled forcefully (especially of a ship).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To urge, press, or bring to a point or state.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To carry or to keep in motion; to conduct; to prosecute.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To clear, by forcing away what is contained.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To dig horizontally; to cut a horizontal gallery or tunnel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To put together a drive (n.): to string together offensive plays and advance the ball down the field.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To distrain for rent.", synonyms: [], antonyms: [] },
            {
              definition:
                "To separate the lighter (feathers or down) from the heavier, by exposing them to a current of air.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be the dominant party in a sex act.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "move",
            "operate",
            "make",
            "render",
            "send",
            "compel",
            "force",
            "oblige",
            "push",
            "require",
            "take",
            "herd",
            "impel",
            "incentivise",
            "incentivize",
            "motivate",
            "push",
            "urge",
            "force",
            "push",
            "motorvate",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/drive"],
    },
  ],
  spent: [
    {
      word: "spent",
      phonetic: "/ˈspɛnt/",
      phonetics: [
        {
          text: "/ˈspɛnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spent-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1235941",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To pay out (money).",
              synonyms: [],
              antonyms: [],
              example:
                "He spends far more on gambling than he does on living proper.",
            },
            {
              definition: "To bestow; to employ; often with on or upon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To squander.",
              synonyms: [],
              antonyms: [],
              example: "to spend an estate in gambling",
            },
            {
              definition: "To exhaust, to wear out.",
              synonyms: [],
              antonyms: [],
              example: "The violence of the waves was spent.",
            },
            {
              definition: "To consume, to use up (time).",
              synonyms: [],
              antonyms: [],
              example: "My sister usually spends her free time in nightclubs.",
            },
            {
              definition: "To have an orgasm; to ejaculate sexually.",
              synonyms: [],
              antonyms: [],
              example:
                "The fish spends his semen on eggs which he finds floating and whose mother he has never seen.",
            },
            {
              definition: "To waste or wear away; to be consumed.",
              synonyms: [],
              antonyms: [],
              example: "Energy spends in the using of it.",
            },
            {
              definition: "To be diffused; to spread.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To break ground; to continue working.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Consumed, used up, exhausted, depleted.",
              synonyms: [],
              antonyms: [],
              example: "a spent cartridge",
            },
            {
              definition: "Of fish: exhausted as a result of having spawned.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/spend",
        "https://en.wiktionary.org/wiki/spent",
      ],
    },
  ],
  local: [
    {
      word: "local",
      phonetic: "/ˈləʊkl̩/",
      phonetics: [
        { text: "/ˈləʊkl̩/", audio: "" },
        {
          text: "/ˈloʊkl̩/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/local-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1684661",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A person who lives near a given place.",
              synonyms: [],
              antonyms: [],
              example: "It's easy to tell the locals from the tourists.",
            },
            {
              definition:
                "A branch of a nationwide organization such as a trade union.",
              synonyms: [],
              antonyms: [],
              example: "I'm in the TWU, too. Local 6.",
            },
            {
              definition:
                "A train that stops at all, or almost all, stations between its origin and destination, including very small ones.",
              synonyms: [],
              antonyms: [],
              example:
                "The expresses skipped my station, so I had to take a local.",
            },
            {
              definition:
                "One's nearest or regularly frequented public house or bar.",
              synonyms: [],
              antonyms: [],
              example:
                "I got barred from my local, so I've started going all the way into town for a drink.",
            },
            {
              definition: "A locally scoped identifier.",
              synonyms: [],
              antonyms: [],
              example:
                "Functional programming languages usually don't allow changing the immediate value of locals once they've been initialized, unless they're explicitly marked as being mutable.",
            },
            {
              definition:
                "An item of news relating to the place where the newspaper is published.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["stopper"],
          antonyms: ["express", "fast"],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "From or in a nearby location.",
              synonyms: [],
              antonyms: [],
              example: "We prefer local produce.",
            },
            {
              definition:
                "(of a variable or identifier) Having limited scope (either lexical or dynamic); only being accessible within a certain portion of a program.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a condition or state) Applying to each point in a space rather than the space as a whole.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of or pertaining to a restricted part of an organism.",
              synonyms: [],
              antonyms: [],
              example:
                "The patient didn't want to be sedated, so we applied only local anesthesia.",
            },
            {
              definition: "Descended from an indigenous population.",
              synonyms: [],
              antonyms: [],
              example: "Hawaiian Pidgin is spoken by the local population.",
            },
          ],
          synonyms: ["topical"],
          antonyms: ["global"],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition:
                "In the local area; within a city, state, country, etc.",
              synonyms: [],
              antonyms: [],
              example: "It's never been more important to buy local.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An anesthetic (anesthetic substance) that causes loss of sensation only to the area to which it is applied.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/local",
        "https://en.wiktionary.org/wiki/local%20anesthetic",
      ],
    },
  ],
  waxes: [
    {
      word: "waxes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Beeswax.", synonyms: [], antonyms: [] },
            {
              definition: "Earwax.",
              synonyms: [],
              antonyms: [],
              example: "What role does the wax in your earhole fulfill?",
            },
            {
              definition:
                "Any oily, water-resistant substance; normally long-chain hydrocarbons, alcohols or esters.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any preparation containing wax, used as a polish.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The phonograph record format for music.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A thick syrup made by boiling down the sap of the sugar maple and then cooling it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A type of drugs with as main ingredients weed oil and butane; hash oil",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "beeswax",
            "cerumen",
            "earwax",
            "disc",
            "disk",
            "record",
            "polish",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To apply wax to (something, such as a shoe, a floor, a car, or an apple), usually to make it shiny.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To remove hair at the roots from (a part of the body) by coating the skin with a film of wax that is then pulled away sharply.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To defeat utterly.", synonyms: [], antonyms: [] },
            {
              definition: "To kill, especially to murder a person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(usually of a musical or oral performance) To record.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "polish",
            "bump off",
            "knock off",
            "whack",
            "buff",
            "burnish",
            "furbish",
            "polish",
            "shine",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To increasingly assume the specified characteristic.",
              synonyms: ["become"],
              antonyms: [],
            },
            { definition: "To grow.", synonyms: [], antonyms: ["wane"] },
            {
              definition:
                "(of the moon) To appear larger each night as a progression from a new moon to a full moon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of the tide) To move from low tide to high tide.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["become"],
          antonyms: ["wane"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "An outburst of anger.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wax",
        "https://en.wiktionary.org/wiki/waxes",
      ],
    },
  ],
  knows: [
    {
      word: "knows",
      phonetic: "/nəʊz/",
      phonetics: [
        { text: "/nəʊz/", audio: "" },
        {
          text: "/noʊz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/knows-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1224230",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Knowledge; the state of knowing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To perceive the truth or factuality of; to be certain of or that.",
              synonyms: [],
              antonyms: [],
              example: "He knew something terrible was going to happen.",
            },
            {
              definition: "To be aware of; to be cognizant of.",
              synonyms: [],
              antonyms: [],
              example:
                "Did you know Michelle and Jack were getting divorced? ― Yes, I knew.",
            },
            {
              definition:
                "To be acquainted or familiar with; to have encountered.",
              synonyms: [],
              antonyms: [],
              example: "I know your mother, but I’ve never met your father.",
            },
            {
              definition: "To experience.",
              synonyms: [],
              antonyms: [],
              example: "Their relationship knew ups and downs.",
            },
            {
              definition:
                "To be able to distinguish, to discern, particularly by contrast or comparison; to recognize the nature of.",
              synonyms: [],
              antonyms: [],
              example: "I wouldn't know one from the other.",
            },
            {
              definition:
                "To recognize as the same (as someone or something previously encountered) after an absence or change.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To understand or have a grasp of through experience or study.",
              synonyms: [],
              antonyms: [],
              example:
                "His mother tongue is Italian, but he also knows French and English.",
            },
            {
              definition:
                "To have sexual relations with. This meaning normally specified in modern English as e.g. to ’know someone in the biblical sense’ or to ‘know Biblically.’",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To have knowledge; to have information, be informed.",
              synonyms: [],
              antonyms: [],
              example: "He knows about 19th century politics.",
            },
            {
              definition: "To be or become aware or cognizant.",
              synonyms: [],
              antonyms: [],
              example:
                "Did you know Michelle and Jack were getting divorced? ― Yes, I knew.",
            },
            {
              definition: "To be acquainted (with another person).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be able to play or perform (a song or other piece of music).",
              synonyms: [],
              antonyms: [],
              example: 'Do you know "Blueberry Hill"?',
            },
          ],
          synonyms: ["coitize", "go to bed with", "sleep with"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/know",
        "https://en.wiktionary.org/wiki/knows",
      ],
    },
  ],
  train: [
    {
      word: "train",
      phonetic: "/tɹeɪn/",
      phonetics: [
        {
          text: "/tɹeɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/train-1-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=45093042",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/tɹeɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/train-1-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9014230",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/tɹeɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/train-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1197447",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Elongated portion.", synonyms: [], antonyms: [] },
            {
              definition: "Connected sequence of people or things.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To practice an ability.",
              synonyms: [],
              antonyms: [],
              example:
                "She trained seven hours a day to prepare for the Olympics.",
            },
            {
              definition:
                "To teach and form (someone) by practice; to educate (someone).",
              synonyms: [],
              antonyms: [],
              example: "You can't train a pig to write poetry.",
            },
            {
              definition: "To improve one's fitness.",
              synonyms: [],
              antonyms: [],
              example: "I trained with weights all winter.",
            },
            {
              definition: "To proceed in sequence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To move (a gun) laterally so that it points in a different direction.",
              synonyms: [],
              antonyms: [],
              example: "The assassin had trained his gun on the minister.",
            },
            {
              definition:
                "To encourage (a plant or branch) to grow in a particular direction or shape, usually by pruning and bending.",
              synonyms: [],
              antonyms: [],
              example: "The vine had been trained over the pergola.",
            },
            {
              definition:
                "To trace (a lode or any mineral appearance) to its head.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To create a trainer for; to apply cheats to (a game).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To draw along; to trail; to drag.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To draw by persuasion, artifice, or the like; to attract by stratagem; to entice; to allure.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/train"],
    },
    {
      word: "train",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Treachery; deceit.", synonyms: [], antonyms: [] },
            { definition: "A trick or stratagem.", synonyms: [], antonyms: [] },
            {
              definition: "A trap for animals; a snare.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A lure; a decoy.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/train"],
    },
  ],
  bread: [
    {
      word: "bread",
      phonetic: "/bɹeːd/",
      phonetics: [
        { text: "/bɹeːd/", audio: "" },
        {
          text: "/bɹɛd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bread-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9014291",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/bɹɛd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bread-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=502023",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A foodstuff made by baking dough made from cereals.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Any variety of bread.", synonyms: [], antonyms: [] },
            { definition: "Money.", synonyms: [], antonyms: [] },
            {
              definition: "Food; sustenance; support of life, in general.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "dough",
            "folding stuff",
            "lolly",
            "paper",
            "spondulicks",
            "wonga",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To coat with breadcrumbs",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bread"],
    },
    {
      word: "bread",
      phonetic: "/bɹeːd/",
      phonetics: [
        { text: "/bɹeːd/", audio: "" },
        {
          text: "/bɹɛd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bread-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9014291",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/bɹɛd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bread-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=502023",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "Breadth.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bread"],
    },
    {
      word: "bread",
      phonetic: "/bɹeːd/",
      phonetics: [
        { text: "/bɹeːd/", audio: "" },
        {
          text: "/bɹɛd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bread-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9014291",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/bɹɛd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bread-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=502023",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make broad; spread.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bread"],
    },
    {
      word: "bread",
      phonetic: "/bɹeːd/",
      phonetics: [
        { text: "/bɹeːd/", audio: "" },
        {
          text: "/bɹɛd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bread-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9014291",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/bɹɛd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bread-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=502023",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A piece of embroidery; a braid.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To form in meshes; net.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bread"],
    },
  ],
  homes: [
    {
      word: "homes",
      phonetic: "/həʊmz/",
      phonetics: [
        { text: "/həʊmz/", audio: "" },
        {
          text: "/hoʊmz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/homes-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100756",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An informal term of address, like man or dude.",
              synonyms: [],
              antonyms: [],
              example: "Hey, holmes! What's up?",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A dwelling.", synonyms: [], antonyms: [] },
            {
              definition:
                "One’s native land; the place or country in which one dwells; the place where one’s ancestors dwell or dwelt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The locality where a thing is usually found, or was first found, or where it is naturally abundant; habitat; seat.",
              synonyms: [],
              antonyms: [],
              example: "the home of the pine",
            },
            { definition: "A focus point.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "home base",
            "abode",
            "domicile",
            "dwelling",
            "house",
            "residence",
            "tenement",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(of animals) To return to its owner.",
              synonyms: [],
              antonyms: [],
              example: "The dog homed.",
            },
            {
              definition: '(always with "in on") To seek or aim for something.',
              synonyms: [],
              antonyms: [],
              example: "The missile was able to home in on the target.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A directory that contains a user's files.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/holmes",
        "https://en.wiktionary.org/wiki/home",
        "https://en.wiktionary.org/wiki/home%20directory",
        "https://en.wiktionary.org/wiki/homes",
      ],
    },
  ],
  teeth: [
    {
      word: "teeth",
      phonetic: "/tiːθ/",
      phonetics: [
        {
          text: "/tiːθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/teeth-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1661715",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The ability to be enforced, or to be enforced to any useful effect.",
              synonyms: [],
              antonyms: [],
              example:
                "The international community's sanctions against the regime had some teeth to them this time around.",
            },
          ],
          synonyms: [
            "enforceability",
            "Hampstead Heath",
            "chompers",
            "pearly whites",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To grow teeth.",
              synonyms: [],
              antonyms: [],
              example: "Babies typically start teething at about six months.",
            },
            {
              definition:
                "To bite on something to relieve discomfort caused by growing teeth.",
              synonyms: [],
              antonyms: [],
              example:
                "She'll teethe on anything that she can get into her mouth.",
            },
          ],
          synonyms: ["breed", "cut new teeth", "dentize"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A hard, calcareous structure present in the mouth of many vertebrate animals, generally used for eating.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sharp projection on the blade of a saw or similar implement.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A projection on the edge of a gear that meshes with similar projections on adjacent gears, or on the circumference of a cog that engages with a chain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A projection or point in other parts of the body resembling the tooth of a vertebrate animal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A pointed projection from the margin of a leaf.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The rough surface of some kinds of cel or other films that allows better adhesion of artwork.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Liking, fondness (compare toothsome).",
              synonyms: ["appetite", "fondness", "palate", "taste"],
              antonyms: [],
              example: "I have a sweet tooth: I love sugary treats.",
            },
            {
              definition:
                "An irreducible component of a comb that intersects the handle in exactly one point, that point being distinct from the unique point of intersection for any other tooth of the comb.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["appetite", "fondness", "palate", "taste"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/teeth",
        "https://en.wiktionary.org/wiki/teethe",
        "https://en.wiktionary.org/wiki/tooth",
      ],
    },
  ],
  coast: [
    {
      word: "coast",
      phonetic: "/kəʊst/",
      phonetics: [
        { text: "/kəʊst/", audio: "" },
        {
          text: "/koʊst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/coast-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1446777",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The edge of the land where it meets an ocean, sea, gulf, bay, or large lake.",
              synonyms: [],
              antonyms: [],
              example: "The rocky coast of Maine has few beaches.",
            },
            {
              definition: "The side or edge of something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A region of land; a district or country.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A region of the air or heavens.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To glide along without adding energy; to allow a vehicle to continue moving forward after disengaging the engine or ceasing to apply motive power.",
              synonyms: [],
              antonyms: [],
              example:
                "When I ran out of gas, fortunately I managed to coast into a nearby gas station.",
            },
            {
              definition: "To sail along a coast.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make a minimal effort; to continue to do something in a routine way, without initiative or effort.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To draw near to; to approach; to keep near, or by the side of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To sail by or near; to follow the coastline of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To conduct along a coast or river bank.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To slide downhill; to slide on a sled upon snow or ice.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/coast"],
    },
  ],
  thick: [
    {
      word: "thick",
      phonetic: "/θɪk/",
      phonetics: [
        { text: "/θɪk/", audio: "" },
        { text: "/θɘk/", audio: "" },
        { text: "/θɪk/", audio: "" },
        { text: "/θɪk/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The thickest, or most active or intense, part of something.",
              synonyms: [],
              antonyms: [],
              example: "It was mayhem in the thick of battle.",
            },
            { definition: "A thicket.", synonyms: [], antonyms: [] },
            {
              definition: "A stupid person; a fool.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To thicken.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Relatively great in extent from one surface to the opposite in its smallest solid dimension.",
              synonyms: ["broad"],
              antonyms: ["slim", "thin"],
            },
            {
              definition:
                "Measuring a certain number of units in this dimension.",
              synonyms: [],
              antonyms: [],
              example: "I want some planks that are two inches thick.",
            },
            {
              definition: "Heavy in build; thickset.",
              synonyms: ["chunky", "solid", "stocky", "thickset"],
              antonyms: ["slender", "slight", "slim", "svelte", "thin"],
              example:
                "He had such a thick neck that he had to turn his body to look to the side.",
            },
            {
              definition: "Densely crowded or packed.",
              synonyms: ["crowded", "dense", "packed"],
              antonyms: ["sparse"],
              example: "We walked through thick undergrowth.",
            },
            {
              definition: "Having a viscous consistency.",
              synonyms: ["glutinous", "viscous"],
              antonyms: ["free-flowing", "runny"],
              example: "My mum’s gravy was thick but at least it moved about.",
            },
            {
              definition: "Abounding in number.",
              synonyms: ["overflowing", "swarming", "teeming"],
              antonyms: ["scant", "scarce", "slight"],
              example: "The room was thick with reporters.",
            },
            {
              definition: "Impenetrable to sight.",
              synonyms: ["dense", "opaque", "solid"],
              antonyms: ["thin", "transparent"],
              example: "We drove through thick fog.",
            },
            {
              definition: "(Of an accent) Prominent, strong.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Stupid.",
              synonyms: [
                "dense",
                "dumb",
                "stupid",
                "thick as pigshit",
                "thick as two short planks",
              ],
              antonyms: ["brainy", "intelligent", "smart"],
              example: "He was as thick as two short planks.",
            },
            {
              definition: "Friendly or intimate.",
              synonyms: [
                "chummy",
                "close",
                "close-knit",
                "friendly",
                "intimate",
                "pally",
                "tight-knit",
              ],
              antonyms: ["unacquainted"],
              example: "They were as thick as thieves.",
            },
            {
              definition: "Deep, intense, or profound.",
              synonyms: ["extreme", "great"],
              antonyms: [],
              example: "Thick darkness.",
            },
            {
              definition: "Troublesome; unreasonable",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(chiefly of women) Curvy and voluptuous, and especially having large hips.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "broad",
            "chummy",
            "close",
            "close-knit",
            "friendly",
            "intimate",
            "pally",
            "tight-knit",
            "chunky",
            "solid",
            "stocky",
            "thickset",
            "crowded",
            "dense",
            "packed",
            "dense",
            "dumb",
            "stupid",
            "thick as pigshit",
            "thick as two short planks",
            "dense",
            "opaque",
            "solid",
            "extreme",
            "great",
            "glutinous",
            "viscous",
            "overflowing",
            "swarming",
            "teeming",
          ],
          antonyms: [
            "brainy",
            "intelligent",
            "smart",
            "free-flowing",
            "runny",
            "scant",
            "scarce",
            "slight",
            "slender",
            "slight",
            "slim",
            "svelte",
            "thin",
            "slim",
            "thin",
            "sparse",
            "thin",
            "transparent",
            "unacquainted",
          ],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "In a thick manner.",
              synonyms: [],
              antonyms: [],
              example: "Snow lay thick on the ground.",
            },
            {
              definition: "Frequently or numerously.",
              synonyms: [],
              antonyms: [],
              example: "The arrows flew thick and fast around us.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/thick"],
    },
  ],
  brown: [
    {
      word: "brown",
      phonetic: "/bɹaʊn/",
      phonetics: [
        {
          text: "/bɹaʊn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/brown-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9014293",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/bɹaʊn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/brown-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=840572",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A colour like that of chocolate or coffee.",
              synonyms: [],
              antonyms: [],
              example:
                "The browns and greens in this painting give it a nice woodsy feel.",
            },
            {
              definition:
                "One of the colour balls used in snooker, with a value of 4 points.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Black tar heroin.", synonyms: [], antonyms: [] },
            { definition: "A copper coin.", synonyms: [], antonyms: [] },
            {
              definition: "A brown horse or other animal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(sometimes capitalised) A person of Middle Eastern, Latino or South Asian descent; a brown-skinned person; someone of mulatto or biracial appearance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various nymphalid butterflies of subfamily Satyrinae (formerly the family Satyridae).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of certain species of nymphalid butterflies of subfamily Satyrinae, such as those of the genera Heteronympha and Melanitis.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A brown trout (Salmo trutta).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To become brown.",
              synonyms: [],
              antonyms: [],
              example: "Fry the onions until they brown.",
            },
            {
              definition: "To cook something until it becomes brown.",
              synonyms: [],
              antonyms: [],
              example: "Brown the onions in a large frying pan.",
            },
            {
              definition: "To tan.",
              synonyms: [],
              antonyms: [],
              example:
                "Light-skinned people tend to brown when exposed to the sun.",
            },
            {
              definition: "To make brown or dusky.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To give a bright brown colour to, as to gun barrels, by forming a thin coating of oxide on their surface.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(usually derogatory) To turn progressively more Middle Eastern, Hispanic or Latino, in the context of the population of a geographic region.",
              synonyms: [],
              antonyms: [],
              example: "the browning of America",
            },
            {
              definition: "To treat with deference, or respect.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having a brown colour.",
              synonyms: [],
              antonyms: ["nonbrown"],
            },
            { definition: "Gloomy.", synonyms: [], antonyms: [] },
            {
              definition:
                "(sometimes capitalized) Of or relating to any of various ethnic groups having dark pigmentation of the skin.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["nonbrown"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/brown"],
    },
  ],
  clean: [
    {
      word: "clean",
      phonetic: "/kleːn/",
      phonetics: [
        { text: "/kleːn/", audio: "" },
        {
          text: "/kliːn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/clean-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9014334",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/klin/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/clean-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=840622",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Removal of dirt.",
              synonyms: [],
              antonyms: [],
              example: "This place needs a clean.",
            },
            {
              definition:
                "The first part of the event clean and jerk in which the weight is brought from the ground to the shoulders.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To remove dirt from a place or object.",
              synonyms: [],
              antonyms: [],
              example: "Can you clean the windows today?",
            },
            {
              definition: "To tidy up, make a place neat.",
              synonyms: [],
              antonyms: [],
              example: "Clean your room right now!",
            },
            {
              definition:
                "To remove equipment from a climbing route after it was previously lead climbed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make things clean in general.",
              synonyms: [],
              antonyms: [],
              example: "She just likes to clean. That’s why I married her.",
            },
            {
              definition:
                "To remove unnecessary files, etc. from (a directory, etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To brush the ice lightly in front of a moving rock to remove any debris and ensure a correct line; less vigorous than a sweep.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To purge a raw of any blemishes caused by the scanning process such as brown tinting and poor color contrast.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To remove guts and/or scales of a butchered animal.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(heading, physical) Free of dirt or impurities or protruberances.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(heading, behavioural) Free of immorality or criminality.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Smooth, exact, and performed well",
              synonyms: [],
              antonyms: [],
              example:
                "I’ll need a sharper knife to make clean cuts.  a clean leap over a fence",
            },
            {
              definition: 'Total; utter. (still in "clean sweep")',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Cool or neat.",
              synonyms: [],
              antonyms: [],
              example: "Wow, Dude, those are some clean shoes ya got there!",
            },
            {
              definition:
                "(health) Being free of sexually transmitted diseases (STDs).",
              synonyms: [],
              antonyms: [],
              example:
                "I want to make sure my fiancé is clean before we are married.",
            },
            {
              definition: "That does not damage the environment.",
              synonyms: [],
              antonyms: [],
              example: "clean energy;  clean coal",
            },
            {
              definition:
                "Free from that which is useless or injurious; without defects.",
              synonyms: [],
              antonyms: [],
              example: "clean land;  clean timber",
            },
            {
              definition: "Free from restraint or neglect; complete; entire.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Well-proportioned; shapely.",
              synonyms: [],
              antonyms: [],
              example: "clean limbs",
            },
            {
              definition: "(of a route) Ascended without falling.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["clean"],
          antonyms: ["dirty", "unclean"],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Fully and completely.",
              synonyms: [],
              antonyms: [],
              example: "He was stabbed clean through.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/clean"],
    },
  ],
  quiet: [
    {
      word: "quiet",
      phonetic: "/ˈkwaɪ.ət/",
      phonetics: [
        {
          text: "/ˈkwaɪ.ət/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/quiet-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=667178",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The absence of sound; quietness.",
              synonyms: [],
              antonyms: [],
              example:
                "There was a strange quiet in the normally very lively plaza.",
            },
            {
              definition: "The absence of movement; stillness, tranquility",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To become quiet, silent, still, tranquil, calm.",
              synonyms: ["quiet down", "quieten"],
              antonyms: [],
              example: "When you quiet, we can start talking.",
            },
            {
              definition: "To cause someone to become quiet.",
              synonyms: ["quiet down", "quieten"],
              antonyms: [],
              example: "Can you quiet your child? He's making lots of noise.",
            },
          ],
          synonyms: ["quiet down", "quieten", "quiet down", "quieten"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "With little or no sound; free of disturbing noise.",
              synonyms: [],
              antonyms: [],
              example: "I can't hear the music; it is too quiet.",
            },
            {
              definition: "Having little motion or activity; calm.",
              synonyms: [],
              antonyms: [],
              example: "a quiet night at home",
            },
            {
              definition: "Not busy, of low quantity.",
              synonyms: [],
              antonyms: [],
              example: "Business was quiet for the season.",
            },
            {
              definition: "Not talking much or not talking loudly; reserved.",
              synonyms: [],
              antonyms: [],
              example:
                "He's a very quiet man usually, but is very chatty after a few beers.",
            },
            {
              definition: "Not showy; undemonstrative.",
              synonyms: [],
              antonyms: [],
              example: "a quiet dress",
            },
            {
              definition: "Requiring little or no interaction.",
              synonyms: [],
              antonyms: [],
              example: "a quiet install",
            },
          ],
          synonyms: ["slow", "unbusy", "modest", "plain", "simple"],
          antonyms: ["loud", "sounded", "vocal"],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "Be quiet.",
              synonyms: [],
              antonyms: [],
              example: "Quiet! The children are sleeping.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/quiet"],
    },
  ],
  sugar: [
    {
      word: "sugar",
      phonetic: "/ˈʃʊɡə(ɹ)/",
      phonetics: [
        {
          text: "/ˈʃʊɡə(ɹ)/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sugar-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9027500",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ˈʃʊɡɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sugar-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1194285",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Sucrose in the form of small crystals, obtained from sugar cane or sugar beet and used to sweeten food and drink.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A specific variety of sugar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various small carbohydrates that are used by organisms to store energy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small serving of this substance (typically about one teaspoon), used to sweeten a drink.",
              synonyms: [],
              antonyms: [],
              example: "He usually has his coffee white with one sugar.",
            },
            {
              definition: "A term of endearment.",
              synonyms: [],
              antonyms: [],
              example: "I'll be with you in a moment, sugar.",
            },
            { definition: "A kiss.", synonyms: [], antonyms: [] },
            {
              definition: "Effeminacy in a male, often implying homosexuality.",
              synonyms: [],
              antonyms: [],
              example: "I think John has a little bit of sugar in him.",
            },
            { definition: "Diabetes.", synonyms: [], antonyms: [] },
            {
              definition:
                "Anything resembling sugar in taste or appearance, especially in chemistry.",
              synonyms: [],
              antonyms: [],
              example:
                "Sugar of lead (lead acetate) is a poisonous white crystalline substance with a sweet taste.",
            },
            {
              definition:
                "Compliment or flattery used to disguise or render acceptable something obnoxious; honeyed or soothing words.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Heroin.", synonyms: [], antonyms: [] },
            { definition: "Money.", synonyms: [], antonyms: [] },
            { definition: "Syntactic sugar.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To add sugar to; to sweeten with sugar.",
              synonyms: [],
              antonyms: [],
              example: "John heavily sugars his coffee.",
            },
            {
              definition: "To make (something unpleasant) seem less so.",
              synonyms: [],
              antonyms: [],
              example:
                "She has a gift for sugaring what would otherwise be harsh words.",
            },
            {
              definition:
                "In making maple sugar, to complete the process of boiling down the syrup till it is thick enough to crystallize; to approach or reach the state of granulation; with the preposition off.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To apply sugar to trees or plants in order to catch moths.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To rewrite (source code) using syntactic sugar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To compliment (a person).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["sweeten", "sugar-coat", "sweeten"],
          antonyms: [],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "Used in place of shit!",
              synonyms: [],
              antonyms: [],
              example: "Oh, sugar!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sugar"],
    },
  ],
  facts: [
    {
      word: "facts",
      phonetic: "/fæks/",
      phonetics: [
        { text: "/fæks/", audio: "" },
        {
          text: "/fæks/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/facts-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1446739",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Something actual as opposed to invented.",
              synonyms: [],
              antonyms: [],
              example:
                "In this story, the Gettysburg Address is a fact, but the rest is fiction.",
            },
            {
              definition: "Something which is real.",
              synonyms: [],
              antonyms: [],
              example: "Gravity is a fact, not a theory.",
            },
            {
              definition:
                "Something concrete used as a basis for further interpretation.",
              synonyms: [],
              antonyms: [],
              example: "Let's look at the facts of the case before deciding.",
            },
            {
              definition:
                "An objective consensus on a fundamental reality that has been agreed upon by a substantial number of experts.",
              synonyms: [],
              antonyms: [],
              example:
                "There is no doubting the fact that the Earth orbits the Sun.",
            },
            {
              definition:
                "Information about a particular subject, especially actual conditions and/or circumstances.",
              synonyms: [],
              antonyms: [],
              example: "The facts about space travel.",
            },
            {
              definition:
                "An individual value or measurement at the lowest level of granularity in a data warehouse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Action; the realm of action.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A wrongful or criminal deed.",
              synonyms: [],
              antonyms: [],
              example: "He had become an accessory after the fact.",
            },
            {
              definition: "A feat or meritorious deed.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["fiction"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fact",
        "https://en.wiktionary.org/wiki/facts",
      ],
    },
  ],
  steel: [
    {
      word: "steel",
      phonetic: "/stiːl/",
      phonetics: [
        {
          text: "/stiːl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/steel-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1158036",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An artificial metal produced from iron, harder and more elastic than elemental iron; used figuratively as a symbol of hardness.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any item made of this metal, particularly including:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Medicinal consumption of this metal; chalybeate medicine; (eventually) any iron or iron-treated water consumed as a medical treatment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Varieties of this metal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(colors) The gray hue of this metal; steel-gray, or steel blue.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Extreme hardness or resilience.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To edge, cover, or point with steel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To harden or strengthen; to nerve or make obdurate; to fortify against.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of mirrors) To back with steel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To treat a liquid with steel for medicinal purposes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To press with a flat iron.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause to resemble steel in appearance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To steelify; to turn iron into steel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To electroplate an item, particularly an engraving plate, with a layer of iron.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To sharpen with a honing steel.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Made of steel.", synonyms: [], antonyms: [] },
            {
              definition:
                "Similar to steel in color, strength, or the like; steely.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of or belonging to the manufacture or trade in steel.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Containing steel.", synonyms: [], antonyms: [] },
            { definition: "Engraved on steel.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/steel"],
    },
    {
      word: "steel",
      phonetic: "/stiːl/",
      phonetics: [
        {
          text: "/stiːl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/steel-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1158036",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "proper noun",
          definitions: [
            {
              definition: "Coldbath Fields Prison in London, closed in 1877.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/steel"],
    },
  ],
  forth: [
    {
      word: "forth",
      phonetic: "/fɔːθ/",
      phonetics: [
        { text: "/fɔːθ/", audio: "" },
        {
          text: "/fɔɹθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/forth-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1227495",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Forward in time, place or degree.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Out into view; from a particular place or position.",
              synonyms: [],
              antonyms: [],
              example: "The plants in spring put forth leaves.",
            },
            {
              definition: "Beyond a (certain) boundary; away; abroad; out.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Thoroughly; from beginning to end.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["fromward"],
          antonyms: [],
        },
        {
          partOfSpeech: "preposition",
          definitions: [
            { definition: "Forth from; out of.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/forth"],
    },
  ],
  rules: [
    {
      word: "rules",
      phonetic: "/ˈɹuːlz/",
      phonetics: [
        {
          text: "/ˈɹuːlz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rules-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1661699",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A regulation, law, guideline.",
              synonyms: [],
              antonyms: [],
              example: "All participants must adhere to the rules.",
            },
            {
              definition:
                "A ruler; device for measuring, a straightedge, a measure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A straight line (continuous mark, as made by a pen or the like), especially one lying across a paper as a guide for writing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A regulating principle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The act of ruling; administration of law; government; empire; authority; control.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A normal condition or state of affairs.",
              synonyms: [],
              antonyms: [],
              example: "As a rule, our senior editors are serious-minded.",
            },
            { definition: "Conduct; behaviour.", synonyms: [], antonyms: [] },
            {
              definition:
                "An order regulating the practice of the courts, or an order made between parties to an action or a suit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A determinate method prescribed for performing any operation and producing a certain result.",
              synonyms: [],
              antonyms: [],
              example: "a rule for extracting the cube root",
            },
            {
              definition:
                "A thin plate of brass or other metal, of the same height as the type, and used for printing lines, as between columns on the same page, or in tabular work.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To regulate, be in charge of, make decisions for, reign over.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To excel.",
              synonyms: [],
              antonyms: [],
              example: "This game rules!",
            },
            {
              definition: "To mark (paper or the like) with rules (lines).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To decide judicially.", synonyms: [], antonyms: [] },
            {
              definition:
                "To establish or settle by, or as by, a rule; to fix by universal or general consent, or by common practice.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To revel.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rule",
        "https://en.wiktionary.org/wiki/rules",
      ],
    },
  ],
  notes: [
    {
      word: "notes",
      phonetic: "/nəʊts/",
      phonetics: [
        { text: "/nəʊts/", audio: "" },
        {
          text: "/noʊts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/notes-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1669330",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(heading) A symbol or annotation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(heading) A written or printed communication or commitment.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "(heading) A sound.", synonyms: [], antonyms: [] },
            {
              definition: "Observation; notice; heed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Reputation; distinction.",
              synonyms: [],
              antonyms: [],
              example: "a poet of note",
            },
            {
              definition: "Notification; information; intelligence.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Mark of disgrace.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "blemish",
            "blot",
            "brand",
            "reproach",
            "stain",
            "stigma",
            "taint",
            "attention",
            "mark",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To notice with care; to observe; to remark; to heed.",
              synonyms: [],
              antonyms: [],
              example:
                "If you look to the left, you can note the old cathedral.",
            },
            {
              definition: "To record in writing; to make a memorandum of.",
              synonyms: [],
              antonyms: [],
              example: "We noted his speech.",
            },
            {
              definition: "To denote; to designate.",
              synonyms: [],
              antonyms: [],
              example:
                "The modular multiplicative inverse of x may be noted x-1.",
            },
            { definition: "To annotate.", synonyms: [], antonyms: [] },
            {
              definition: "To set down in musical characters.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To record on the back of (a bill, draft, etc.) a refusal of acceptance, as the ground of a protest, which is done officially by a notary.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "That which is needed or necessary; business; duty; work.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The giving of milk by a cow or sow; the period following calving or farrowing during which a cow or sow is at her most useful (i.e. gives milk); the milk given by a cow or sow during such a period.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/note",
        "https://en.wiktionary.org/wiki/notes",
      ],
    },
  ],
  units: [
    {
      word: "units",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A particular, minute unit of mass, defined differently for different substances, but so that varying substances of the same general type have the property that one international unit of the one has the same effect on the human body as one international unit of the other.",
              synonyms: ["IU"],
              antonyms: [],
            },
          ],
          synonyms: ["IU"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Oneness, singularity, seen as a component of a whole number; a magnitude of one.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A standard measure of a quantity.",
              synonyms: [],
              antonyms: [],
              example: "The centimetre is a unit of length.",
            },
            { definition: "The number one.", synonyms: [], antonyms: [] },
            {
              definition:
                "An organized group comprising people and/or equipment.",
              synonyms: [],
              antonyms: [],
              example: "He was a member of a special police unit.",
            },
            {
              definition: "A member of a military organization.",
              synonyms: [],
              antonyms: [],
              example:
                "The fifth tank brigade moved in with 20 units. (i.e., 20 tanks)",
            },
            {
              definition:
                "Any military element whose structure is prescribed by competent authority, such as a table of organization and equipment; specifically, part of an organization.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An organization title of a subdivision of a group in a task force.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A standard or basic quantity into which an item of supply is divided, issued or detailed. In this meaning, also called unit of issue.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "With regard to Reserve Components of the Armed Forces, denotes a Selected Reserve unit organized, equipped, and trained for mobilization to serve on active duty as a unit or to augment or be augmented by another unit. Headquarters and support functions without wartime missions are not considered units.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The identity element, neutral element.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An element having an inverse, an invertible element; an associate of the unity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In an adjunction, a natural transformation from the identity functor of the domain of the left adjoint functor to the composition of the right adjoint functor with the left adjoint functor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A volume of rock or ice of identifiable origin and age range that is defined by the distinctive and dominant, easily mapped and recognizable petrographic, lithologic or paleontologic features (facies) that characterize it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An item which may be sold singly.",
              synonyms: [],
              antonyms: [],
              example:
                "We shipped nearly twice as many units this month as last month.",
            },
            { definition: "A unit of alcohol.", synonyms: [], antonyms: [] },
            {
              definition:
                "One kilowatt-hour (as recorded on an electricity meter).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A measure of housing equivalent to the living quarters of one household; an apartment where a group of apartments is contained in one or more multi-storied buildings or a group of dwellings is in one or more single storey buildings, usually arranged around a driveway.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A gold coin of the reign of James I, worth twenty shillings.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A work unit.", synonyms: [], antonyms: [] },
            {
              definition: "A physically large person.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["identity element", "unit element", "unity"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/international%20unit",
        "https://en.wiktionary.org/wiki/unit",
        "https://en.wiktionary.org/wiki/units",
      ],
    },
  ],
  peace: [
    {
      word: "peace",
      phonetic: "/piːs/",
      phonetics: [
        {
          text: "/piːs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/peace-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9023297",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/piːs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/peace-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1113784",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A state of tranquility, quiet, and harmony; absence of violence. For instance, a state free from civil disturbance.",
              synonyms: ["frith"],
              antonyms: ["disruption", "violence"],
              example:
                "Our lounge strives to maintain an environment of peace for the comfort of our customers.",
            },
            {
              definition:
                "A state free of oppressive and unpleasant thoughts and emotions.",
              synonyms: [],
              antonyms: [],
              example: "The safety equipment will give me some peace of mind.",
            },
            {
              definition: "Harmony in personal relations.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A state free of war, in particular war between different countries.",
              synonyms: [],
              antonyms: ["violence", "war"],
              example: "May there be peace in our time.",
            },
          ],
          synonyms: ["frith"],
          antonyms: ["disruption", "violence", "violence", "war"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make peace; to put at peace; to be at peace.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To peace out.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "Shut up!, silence!; be quiet, be silent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Shortened form of peace out; goodbye.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/peace"],
    },
  ],
  month: [
    {
      word: "month",
      phonetic: "/mʌnθ/",
      phonetics: [
        {
          text: "/mʌnθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/month-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9014202",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/mʌnθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/month-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=874107",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A period into which a year is divided, historically based on the phases of the moon.",
              synonyms: [],
              antonyms: [],
              example: "July is my favourite month.",
            },
            {
              definition:
                "A period of 30 days, 31 days, or some alternation thereof.",
              synonyms: [],
              antonyms: [],
              example: "We went on holiday for two months.",
            },
            {
              definition:
                "(in the plural) A woman's period; menstrual discharge.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/month"],
    },
  ],
  verbs: [
    {
      word: "verbs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(grammar) A word that indicates an action, event, or state.",
              synonyms: [],
              antonyms: [],
              example: "The word “speak” is an English verb.",
            },
            { definition: "Any word; a vocable.", synonyms: [], antonyms: [] },
            {
              definition: "An action as opposed to a trait or thing.",
              synonyms: [],
              antonyms: [],
              example:
                "Kindness is a verb, not an adjective. You're only kind if you do kind things.",
            },
            {
              definition:
                "A named command that performs a specific operation on an object.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To use any word that is not, or had not been a verb (especially a noun) as if it were a verb.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(used as a neutral, unspecific verb) To perform any action that is normally expressed by a verb.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/verb",
        "https://en.wiktionary.org/wiki/verbs",
      ],
    },
  ],
  seeds: [
    {
      word: "seeds",
      phonetic: "/siːdz/",
      phonetics: [{ text: "/siːdz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A fertilized and ripened ovule, containing an embryonic plant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any small seed-like fruit.",
              synonyms: [],
              antonyms: [],
              example:
                "If you plant a seed in the spring, you may have a pleasant surprise in the autumn.",
            },
            {
              definition:
                "Any propagative portion of a plant which may be sown, such as true seeds, seed-like fruits, tubers, or bulbs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(collective) An amount of seeds that cannot be readily counted.",
              synonyms: [],
              antonyms: [],
              example:
                "The entire field was covered with geese eating the freshly sown seed.",
            },
            { definition: "A fragment of coral.", synonyms: [], antonyms: [] },
            {
              definition: "Semen.",
              synonyms: [],
              antonyms: [],
              example: "A man must use his seed to start and raise a family.",
            },
            {
              definition: "A precursor.",
              synonyms: ["germ"],
              antonyms: [],
              example: "the seed of an idea; which idea was the seed (idea)?",
            },
            {
              definition:
                "The initial state, condition or position of a changing, growing or developing process; the ultimate precursor in a defined chain of precursors.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Offspring, descendants, progeny.",
              synonyms: [],
              antonyms: [],
              example: "the seed of Abraham",
            },
            {
              definition: "Race; generation; birth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A small bubble formed in imperfectly fused glass.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["germ"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To plant or sow an area with seeds.",
              synonyms: [],
              antonyms: [],
              example: "I seeded my lawn with bluegrass.",
            },
            {
              definition:
                "To cover thinly with something scattered; to ornament with seedlike decorations.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To start; to provide, assign or determine the initial resources for, position of, state of.",
              synonyms: [],
              antonyms: [],
              example: "A venture capitalist seeds young companies.",
            },
            {
              definition: "To allocate a seeding to a competitor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To leave (files) available for others to download through peer-to-peer file sharing protocols (e.g. BitTorrent).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be qualified to compete, especially in a quarter-final, semi-final or final.",
              synonyms: [],
              antonyms: [],
              example: "The tennis player seeded into the quarters.",
            },
            { definition: "To produce seed.", synonyms: [], antonyms: [] },
            { definition: "To grow to maturity.", synonyms: [], antonyms: [] },
            {
              definition:
                "To ejaculate inside the penetratee during intercourse, especially in the rectum.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/seed",
        "https://en.wiktionary.org/wiki/seeds",
      ],
    },
  ],
  helps: [
    {
      word: "helps",
      phonetic: "/hɛlps/",
      phonetics: [
        {
          text: "/hɛlps/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/helps.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=69668707",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Action given to provide assistance; aid.",
              synonyms: [],
              antonyms: [],
              example: "I need some help with my homework.",
            },
            {
              definition:
                "(usually uncountable) Something or someone which provides assistance with a task.",
              synonyms: [],
              antonyms: [],
              example: "He was a great help to me when I was moving house.",
            },
            {
              definition:
                "Documentation provided with computer software, etc. and accessed using the computer.",
              synonyms: [],
              antonyms: [],
              example:
                "I can't find anything in the help about rotating an image.",
            },
            {
              definition:
                "(usually uncountable) One or more people employed to help in the maintenance of a house or the operation of a farm or enterprise.",
              synonyms: [],
              antonyms: [],
              example: "Most of the hired help is seasonal, for the harvest.",
            },
            {
              definition:
                "Correction of deficits, as by psychological counseling or medication or social support or remedial training.",
              synonyms: [],
              antonyms: [],
              example: "He really needs help in handling customer complaints.",
            },
          ],
          synonyms: ["aid", "assistance"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To provide assistance to (someone or something).",
              synonyms: [],
              antonyms: [],
              example: "He helped his grandfather cook breakfast.",
            },
            {
              definition:
                "To assist (a person) in getting something, especially food or drink at table; used with to.",
              synonyms: [],
              antonyms: [],
              example: "Help yourself to whatever's in the fridge.",
            },
            {
              definition: "To contribute in some way to.",
              synonyms: [],
              antonyms: [],
              example:
                "If you want to get a job, it helps to have some prior experience.",
            },
            {
              definition: "To provide assistance.",
              synonyms: [],
              antonyms: [],
              example: "Please, help!",
            },
            {
              definition:
                "To avoid; to prevent; to refrain from; to restrain (oneself). Usually used in nonassertive contexts with can.",
              synonyms: [],
              antonyms: [],
              example: "Are they going to beat us? Not if I can help it!",
            },
          ],
          synonyms: [
            "contribute",
            "assist",
            "aid",
            "assist",
            "come to the aid of",
            "help out",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/help",
        "https://en.wiktionary.org/wiki/helps",
      ],
    },
  ],
  sharp: [
    {
      word: "sharp",
      phonetic: "/ʃɑːp/",
      phonetics: [
        { text: "/ʃɑːp/", audio: "" },
        {
          text: "/ʃɑɹp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sharp-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1157663",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The symbol ♯, placed after the name of a note in the key signature or before a note on the staff to indicate that the note is to be played a semitone higher.",
              synonyms: [],
              antonyms: [],
              example: "The pitch pipe sounded out a perfect F♯ (F sharp).",
            },
            {
              definition:
                "A note that is played a semitone higher than usual; denoted by the name of the note that is followed by the symbol ♯.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A note that is sharp in a particular key.",
              synonyms: [],
              antonyms: [],
              example:
                "The piece was difficult to read after it had been transposed, since in the new key many notes were sharps.",
            },
            {
              definition:
                "The scale having a particular sharp note as its tonic.",
              synonyms: [],
              antonyms: [],
              example:
                'Beethoven\'s "Moonlight Sonata" is written in C♯ minor (C sharp minor.)',
            },
            {
              definition: "(usually in the plural) Something that is sharp.",
              synonyms: [],
              antonyms: [],
              example:
                "Place sharps in the specially marked red container for safe disposal.",
            },
            {
              definition: "A dishonest person; a cheater.",
              synonyms: [],
              antonyms: [],
              example:
                "The casino kept in the break room a set of pictures of known sharps for the bouncers to see.",
            },
            {
              definition: "Part of a stream where the water runs very rapidly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sewing needle with a very slender point, more pointed than a blunt or a between.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in the plural) Fine particles of husk mixed with coarse particle of flour of cereals; middlings.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An expert.", synonyms: [], antonyms: [] },
            {
              definition:
                "A sharpie (member of Australian gangs of the 1960s and 1970s).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To raise the pitch of a note half a step making a natural note a sharp.",
              synonyms: [],
              antonyms: [],
              example:
                "That new musician must be tone deaf: he sharped half the notes of the song!",
            },
            {
              definition: "To play tricks in bargaining; to act the sharper.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To sharpen.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Terminating in a point or edge, especially one that can cut easily; not obtuse or rounded.",
              synonyms: [],
              antonyms: [],
              example: "A face with sharp features",
            },
            {
              definition: "Intelligent.",
              synonyms: [],
              antonyms: [],
              example:
                "My nephew is a sharp lad; he can count to 100 in six languages, and he's only five years old.",
            },
            {
              definition:
                "Higher than usual by one semitone (denoted by the symbol ♯ after the name of the note).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Higher in pitch than required.",
              synonyms: [],
              antonyms: [],
              example:
                "The orchestra's third violin several times was sharp about an eighth of a tone.",
            },
            {
              definition: "Having an intense, acrid flavour.",
              synonyms: [],
              antonyms: [],
              example:
                "Milly couldn't stand sharp cheeses when she was pregnant, because they made her nauseated.",
            },
            {
              definition: "Sudden and intense.",
              synonyms: [],
              antonyms: [],
              example:
                "A pregnant woman during labor normally experiences a number of sharp contractions.",
            },
            {
              definition: "Illegal or dishonest.",
              synonyms: [],
              antonyms: [],
              example:
                "Michael had a number of sharp ventures that he kept off the books.",
            },
            {
              definition:
                "Keenly or unduly attentive to one's own interests; shrewd.",
              synonyms: [],
              antonyms: [],
              example: "a sharp dealer;  a sharp customer",
            },
            {
              definition: "Exact, precise, accurate; keen.",
              synonyms: [],
              antonyms: [],
              example: "You'll need sharp aim to make that shot.",
            },
            {
              definition: "Offensive, critical, or acrimonious.",
              synonyms: [],
              antonyms: [],
              example:
                "When the two rivals met, first there were sharp words, and then a fight broke out.",
            },
            {
              definition: "Stylish or attractive.",
              synonyms: [],
              antonyms: [],
              example: "You look so sharp in that tuxedo!",
            },
            {
              definition: "Observant; alert; acute.",
              synonyms: [],
              antonyms: [],
              example:
                "Keep a sharp watch on the prisoners. I don't want them to escape!",
            },
            {
              definition:
                "Forming a small angle; especially, forming an angle of less than ninety degrees.",
              synonyms: [],
              antonyms: [],
              example:
                "Drive down Main for three quarters of a mile, then make a sharp right turn onto Pine.",
            },
            {
              definition: "Steep; precipitous; abrupt.",
              synonyms: [],
              antonyms: [],
              example: "a sharp ascent or descent; a sharp turn or curve",
            },
            {
              definition:
                "(of a statement) Said of as extreme a value as possible.",
              synonyms: [],
              antonyms: [],
              example:
                "Sure, any planar graph can be five-colored. But that result is not sharp: in fact, any planar graph can be four-colored. That is sharp: the same can't be said for any lower number.",
            },
            { definition: "Tactical; risky.", synonyms: [], antonyms: [] },
            {
              definition: "Piercing; keen; severe; painful.",
              synonyms: [],
              antonyms: [],
              example: "a sharp pain; the sharp and frosty winter air",
            },
            {
              definition:
                "Eager or keen in pursuit; impatient for gratification.",
              synonyms: [],
              antonyms: [],
              example: "a sharp appetite",
            },
            {
              definition: "Fierce; ardent; fiery; violent; impetuous.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Composed of hard, angular grains; gritty.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Uttered in a whisper, or with the breath alone; aspirated; unvoiced.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Hungry.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "keen",
            "razor",
            "razor-sharp",
            "pointed",
            "accurate",
            "exact",
            "keen",
            "precise",
            "acrimonious",
            "bitter",
            "cutting",
            "harsh",
            "hostile",
            "nasty",
            "acrid",
            "pungent",
            "dishonest",
            "dodgy",
            "illegal",
            "illicit",
            "underhand",
            "brainy",
            "bright",
            "intelligent",
            "keen",
            "smart",
            "witty",
            "acute",
            "alert",
            "keen",
            "observant",
            "sharp-eyed",
            "chic",
            "elegant",
            "smart",
            "stylish",
            "abrupt",
            "acute",
            "stabbing",
          ],
          antonyms: [
            "blunt",
            "dull",
            "blunt",
            "imprecise",
            "inaccurate",
            "complimentary",
            "flattering",
            "friendly",
            "kind",
            "nice",
            "bland",
            "insipid",
            "tasteless",
            "flat",
            "above-board",
            "honest",
            "legit",
            "legitimate",
            "reputable",
            "dim",
            "dim-witted",
            "slow",
            "slow-witted",
            "thick",
            "flat",
            "unobservant",
            "inelegant",
            "scruffy",
            "shabby",
            "dull",
          ],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "To a point or edge; piercingly; eagerly; sharply.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(notcomp) Exactly.",
              synonyms: [],
              antonyms: [],
              example: "I'll see you at twelve o'clock sharp.",
            },
            {
              definition: "In a higher pitch than is correct or desirable.",
              synonyms: [],
              antonyms: [],
              example:
                "I didn't enjoy the concert much because the tenor kept going sharp on the high notes.",
            },
          ],
          synonyms: ["exactly", "on the dot", "precisely"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sharp"],
    },
  ],
  visit: [
    {
      word: "visit",
      phonetic: "/ˈvɪzɪt/",
      phonetics: [
        {
          text: "/ˈvɪzɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/visit-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1227739",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A single act of visiting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A meeting with a doctor at their surgery or the doctor's at one's home.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To habitually go to (someone in distress, sickness etc.) to comfort them. (Now generally merged into later senses, below.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To go and meet (a person) as an act of friendliness or sociability.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of God: to appear to (someone) to comfort, bless, or chastise or punish them. (Now generally merged into later senses, below.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To punish, to inflict harm upon (someone or something).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of a sickness, misfortune etc.: to afflict (someone).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To inflict punishment, vengeance for (an offense) on or upon someone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To go to (a shrine, temple etc.) for worship. (Now generally merged into later senses, below.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To go to (a place) for pleasure, on an errand, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["call on"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/visit"],
    },
  ],
  woods: [
    {
      word: "woods",
      phonetic: "/wʊdz/",
      phonetics: [
        {
          text: "/wʊdz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/woods-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1250154",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The substance making up the central part of the trunk and branches of a tree. Used as a material for construction, to manufacture various items, etc. or as fuel.",
              synonyms: [],
              antonyms: [],
              example: "There was lots of wood on the beach.",
            },
            {
              definition: "The wood of a particular species of tree.",
              synonyms: [],
              antonyms: [],
              example:
                "Teak is much used for outdoor benches, but a number of other woods are also suitable, such as ipé, redwood, etc.",
            },
            {
              definition: "A forested or wooded area.",
              synonyms: [],
              antonyms: [],
              example: "He got lost in the woods beyond Seattle.",
            },
            {
              definition: "Firewood.",
              synonyms: [],
              antonyms: [],
              example: "We need more wood for the fire.",
            },
            {
              definition:
                "A type of golf club, the head of which was traditionally made of wood.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A woodwind instrument.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An erection of the penis.",
              synonyms: [],
              antonyms: [],
              example: "That girl at the strip club gave me wood.",
            },
            { definition: "Chess pieces.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["timber", "wood lot"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cover or plant with trees.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To hide behind trees.", synonyms: [], antonyms: [] },
            {
              definition: "To supply with wood, or get supplies of wood for.",
              synonyms: [],
              antonyms: [],
              example: "to wood a steamboat or a locomotive",
            },
            {
              definition: "To take or get a supply of wood.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(sometimes offensive, of a person) A peckerwood.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(usually with plural construction, sometimes with singular construction) A dense collection of trees, usually one covering a relatively small area; usually smaller than a forest.",
              synonyms: [],
              antonyms: [],
              example: "These woods are part of the Campbell property.",
            },
            {
              definition:
                "For chemical behavior purposes, trees in full leaf (coniferous or medium-dense deciduous forests).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wood",
        "https://en.wiktionary.org/wiki/woods",
      ],
    },
  ],
  chief: [
    {
      word: "chief",
      phonetic: "/tʃiːf/",
      phonetics: [
        {
          text: "/tʃiːf/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chief-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=840611",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A leader or head of a group of people, organisation, etc.",
              synonyms: [],
              antonyms: [],
              example: "All firefighters report to the fire chief.",
            },
            {
              definition:
                "The top part of a shield or escutcheon; more specifically, an ordinary consisting of the upper part of the field cut off by a horizontal line, generally occupying the top third.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The principal part or top of anything.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An informal term of address, sometimes ironic.",
              synonyms: [],
              antonyms: [],
              example: "Hey, chief.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To smoke cannabis.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Primary; principal.",
              synonyms: [],
              antonyms: [],
              example: "Negligence was the chief cause of the disaster.",
            },
            { definition: "Intimate, friendly.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chief"],
    },
  ],
  walls: [
    {
      word: "walls",
      phonetic: "/wɔːlz/",
      phonetics: [
        { text: "/wɔːlz/", audio: "" },
        {
          text: "/wɔlz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/walls-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1239895",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A rampart of earth, stones etc. built up for defensive purposes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A structure built for defense surrounding a city, castle etc.",
              synonyms: [],
              antonyms: [],
              example: "The town wall was surrounded by a moat.",
            },
            {
              definition:
                "Each of the substantial structures acting either as the exterior of or divisions within a structure.",
              synonyms: [],
              antonyms: [],
              example:
                "We're adding another wall in this room during the remodeling.  The wind blew against the walls of the tent.",
            },
            {
              definition: "A point of desperation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A point of defeat or extinction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An impediment to free movement.",
              synonyms: [],
              antonyms: [],
              example:
                "A wall of police officers met the protesters before they reached the capitol steps.",
            },
            {
              definition: "A type of butterfly (Lasiommata megera).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(often in combination) A barrier.",
              synonyms: [],
              antonyms: [],
              example: "a seawall;  a firewall",
            },
            { definition: "A barrier to vision.", synonyms: [], antonyms: [] },
            {
              definition:
                "Something with the apparent solidity and dimensions of a building wall.",
              synonyms: [],
              antonyms: [],
              example: "a wall of sound;  a wall of water",
            },
            {
              definition:
                "A divisive or containing structure in an organ or cavity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(auction) A fictional bidder used to increase the price at an auction.",
              synonyms: ["chandelier"],
              antonyms: [],
            },
            {
              definition:
                "A doctor who tries to admit as few patients as possible.",
              synonyms: [],
              antonyms: ["sieve"],
            },
            {
              definition:
                "A line of defenders set up between an opposing free-kick taker and the goal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A personal notice board listing messages of interest to a particular user.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["chandelier", "profile", "rampart", "chandelier"],
          antonyms: ["sieve"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To enclose with, or as if with, a wall or walls.",
              synonyms: [],
              antonyms: [],
              example: "He walled the study with books.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To boil.", synonyms: [], antonyms: [] },
            {
              definition: "To well, as water; spring.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A spring of water.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A kind of knot often used at the end of a rope; a wall knot or wale.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make a wall knot on the end of (a rope).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wall",
        "https://en.wiktionary.org/wiki/walls",
      ],
    },
  ],
  cross: [
    {
      word: "cross",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cross-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75900009",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cross-ca.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=94250430",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/kɹɑs/", audio: "" },
        { text: "/kɹɒs/", audio: "" },
        {
          text: "/kɹɔs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cross-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1446744",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A geometrical figure consisting of two straight lines or bars intersecting each other such that at least one of them is bisected by the other.",
              synonyms: [],
              antonyms: [],
              example:
                "Put a cross for a wrong answer and a tick for a right one.",
            },
            {
              definition:
                "Any geometric figure having this or a similar shape, such as a cross of Lorraine or a Maltese cross.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A wooden post with a perpendicular beam attached and used (especially in the Roman Empire) to execute criminals (by crucifixion).",
              synonyms: [],
              antonyms: [],
              example: "Criminals were commonly executed on a wooden cross.",
            },
            {
              definition:
                "(usually with the) The cross on which Christ was crucified.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hand gesture made in imitation of the shape of the Cross.",
              synonyms: [],
              antonyms: [],
              example: "She made the cross after swearing.",
            },
            {
              definition:
                "A modified representation of the crucifixion stake, worn as jewellery or displayed as a symbol of religious devotion.",
              synonyms: [],
              antonyms: [],
              example: "She was wearing a cross on her necklace.",
            },
            {
              definition:
                "(figurative, from Christ's bearing of the cross) A difficult situation that must be endured.",
              synonyms: [],
              antonyms: [],
              example: "It's a cross I must bear.",
            },
            {
              definition:
                "The act of going across; the act of passing from one side to the other",
              synonyms: [],
              antonyms: [],
              example: "A quick cross of the road.",
            },
            {
              definition:
                "An animal or plant produced by crossbreeding or cross-fertilization.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) A hybrid of any kind.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A hook thrown over the opponent's punch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A pass in which the ball travels from by one touchline across the pitch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A place where roads intersect and lead off in four directions; a crossroad (common in UK and Irish place names such as Gerrards Cross).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A monument that marks such a place. (Also common in UK or Irish place names such as Charing Cross)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A coin stamped with the figure of a cross, or that side of such a piece on which the cross is stamped; hence, money in general.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Church lands.", synonyms: [], antonyms: [] },
            {
              definition: "A line drawn across or through another line.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An instrument for laying of offsets perpendicular to the main course.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A pipe-fitting with four branches whose axes usually form a right angle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Rubik's Cube) Four edge cubies of one side that are in their right places, forming the shape of a cross.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The thirty-sixth Lenormand card.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Crossfire.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["True Cross", "hybrid"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make or form a cross.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To move relatively.", synonyms: [], antonyms: [] },
            { definition: "(social) To oppose.", synonyms: [], antonyms: [] },
            {
              definition: "To cross-fertilize or crossbreed.",
              synonyms: [],
              antonyms: [],
              example: "They managed to cross a sheep with a goat.",
            },
            {
              definition:
                "To stamp or mark (a cheque) in such a way as to prevent it being cashed, thus requiring it to be deposited into a bank account.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["cross-fertilize", "crossbreed"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Transverse; lying across the main direction.",
              synonyms: [],
              antonyms: [],
              example:
                "At the end of each row were cross benches which linked the rows.",
            },
            {
              definition: "Opposite, opposed to.",
              synonyms: [],
              antonyms: [],
              example:
                "His actions were perversely cross to his own happiness.",
            },
            {
              definition:
                "Opposing, adverse; being contrary to what one would hope or wish for.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Bad-tempered, angry, annoyed.",
              synonyms: [],
              antonyms: [],
              example:
                "Please don't get cross at me. (or) Please don't get cross with me.",
            },
            {
              definition:
                "Made in an opposite direction, or an inverse relation; mutually inverse; interchanged.",
              synonyms: [],
              antonyms: [],
              example: "cross interrogatories",
            },
          ],
          synonyms: [
            "angry",
            "annoyed",
            "irritated",
            "antipodal",
            "contrarily",
            "opposed",
            "reverse",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "preposition",
          definitions: [
            {
              definition: "Across",
              synonyms: [],
              antonyms: [],
              example: "She walked cross the mountains.",
            },
            {
              definition:
                "Cross product of the previous vector and the following vector.",
              synonyms: [],
              antonyms: [],
              example: "The Lorentz force is q times v cross B.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cross"],
    },
  ],
  wings: [
    {
      word: "wings",
      phonetic: "/wɪŋz/",
      phonetics: [
        {
          text: "/wɪŋz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wings-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1676905",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An appendage of an animal's (bird, bat, insect) body that enables it to fly; a similar fin at the side of a ray or similar fish",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Human arm.", synonyms: [], antonyms: [] },
            {
              definition:
                "Part of an aircraft that produces the lift for rising into the air.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One of the large pectoral fins of a flying fish.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the broad, thin, anterior lobes of the foot of a pteropod, used as an organ in swimming.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any membranaceous expansion, such as that along the sides of certain stems, or of a fruit of the kind called samara.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Either of the two side petals of a papilionaceous flower.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A side shoot of a tree or plant; a branch growing up by the side of another.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Passage by flying; flight.",
              synonyms: [],
              antonyms: [],
              example: "to take wing",
            },
            {
              definition:
                "Motive or instrument of flight; means of flight or of rapid motion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A part of something that is lesser in size than the main body, such as an extension from the main building.",
              synonyms: [],
              antonyms: [],
              example: "the west wing of the hospital",
            },
            {
              definition:
                "Anything that agitates the air as a wing does, or is put in winglike motion by the action of the air, such as a fan or vane for winnowing grain, the vane or sail of a windmill, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A protruding piece of material on a tampon to hold it in place and prevent leakage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An ornament worn on the shoulder; a small epaulet or shoulder knot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cosmetic effect where eyeliner curves outward and ends at a point.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A fraction of a political movement. Usually implies a position apart from the mainstream center position.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An organizational grouping in a military aviation service:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A panel of a car which encloses the wheel area, especially the front wheels.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A platform on either side of the bridge of a vessel, normally found in pairs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That part of the hold or orlop of a vessel which is nearest the sides. In a fleet, one of the extremities when the ships are drawn up in line, or when forming the two sides of a triangle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A position in several field games on either side of the field.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A player occupying such a position, also called a winger",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A háček.", synonyms: [], antonyms: [] },
            {
              definition:
                "One of the unseen areas on the side of the stage in a theatre.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in the plural) The insignia of a qualified pilot or aircrew member.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A portable shelter consisting of a fabric roof on a frame, like a tent without sides.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "On the Enneagram, one of the two adjacent types to an enneatype that forms an individual's subtype of his or her enneatype",
              synonyms: [],
              antonyms: [],
              example: "Tom's a 4 on the Enneagram, with a 3 wing.",
            },
          ],
          synonyms: ["delta", "garrison", "fender", "guard", "forward"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To injure slightly (as with a gunshot), especially in the wing or arm.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To fly.", synonyms: [], antonyms: [] },
            {
              definition: "(of a building) To add a wing (extra part) to.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To act or speak extemporaneously; to improvise; to wing it.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To throw.", synonyms: [], antonyms: [] },
            {
              definition: "To furnish with wings.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To transport with, or as if with, wings; to bear in flight, or speedily.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To traverse by flying.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A type of scuba harness with an attached buoyancy compensation device: see wikipedia:Backplate and wing",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A flip (hairstyle)", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wing",
        "https://en.wiktionary.org/wiki/wings",
      ],
    },
  ],
  grown: [
    {
      word: "grown",
      phonetic: "/ɡɹəʊn/",
      phonetics: [
        { text: "/ɡɹəʊn/", audio: "" },
        {
          text: "/ɡɹoʊn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grown-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1648253",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To become larger, to increase in magnitude.",
              synonyms: [],
              antonyms: [],
              example: "Children grow quickly.",
            },
            {
              definition: "To appear or sprout.",
              synonyms: [],
              antonyms: [],
              example: "A long tail began to grow from his backside.",
            },
            {
              definition: "To develop, to mature.",
              synonyms: [],
              antonyms: [],
              example:
                "As I grew throughout adolescence, I came to appreciate many things about human nature.",
            },
            {
              definition:
                "To cause or allow something to become bigger, especially to cultivate plants.",
              synonyms: [],
              antonyms: [],
              example: "Have you ever grown your hair before?",
            },
            {
              definition: "To assume a condition or quality over time.",
              synonyms: [],
              antonyms: [],
              example: "The boy grew wise as he matured.",
            },
            {
              definition: "To become attached or fixed; to adhere.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["shrink"],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Covered by growth; overgrown.",
              synonyms: [],
              antonyms: [],
              example: "What once was a farm was grown with trees.",
            },
            { definition: "Of a person: adult.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["grown up", "grown-ass", "mature"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/grow",
        "https://en.wiktionary.org/wiki/grown",
      ],
    },
  ],
  cases: [
    {
      word: "cases",
      phonetic: "/ˈkeɪsɪz/",
      phonetics: [
        {
          text: "/ˈkeɪsɪz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cases-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1243843",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An actual event, situation, or fact.",
              synonyms: [],
              antonyms: [],
              example: "For a change, in this case, he was telling the truth.",
            },
            {
              definition: "A given condition or state.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of work, specifically defined within a profession.",
              synonyms: [],
              antonyms: [],
              example:
                "It was one of the detective's easiest cases.  Social workers should work on a maximum of forty active cases.  The doctor told us of an interesting case he had treated that morning.",
            },
            {
              definition:
                "(academia) An instance or event as a topic of study.",
              synonyms: [],
              antonyms: [],
              example:
                "The teaching consists of theory lessons and case studies.",
            },
            {
              definition: "A legal proceeding, lawsuit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(grammar) A specific inflection of a word depending on its function in the sentence.",
              synonyms: [],
              antonyms: [],
              example:
                "The accusative case canonically indicates a direct object.  Latin has six cases, and remnants of a seventh.",
            },
            {
              definition:
                "(grammar) Grammatical cases and their meanings taken either as a topic in general or within a specific language.",
              synonyms: [],
              antonyms: [],
              example:
                "Jane has been studying case in Caucasian languages.  Latin is a language that employs case.",
            },
            {
              definition:
                "An instance of a specific condition or set of symptoms.",
              synonyms: [],
              antonyms: [],
              example: "There were another five cases reported overnight.",
            },
            {
              definition:
                "A section of code representing one of the actions of a conditional switch.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To propose hypothetical cases.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A box that contains or can contain a number of identical items of manufacture.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A box, sheath, or covering generally.",
              synonyms: [],
              antonyms: [],
              example: "a case for spectacles; the case of a watch",
            },
            {
              definition:
                "A piece of luggage that can be used to transport an apparatus such as a sewing machine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An enclosing frame or casing.",
              synonyms: [],
              antonyms: [],
              example: "a door case; a window case",
            },
            { definition: "A suitcase.", synonyms: [], antonyms: [] },
            {
              definition:
                "A piece of furniture, constructed partially of transparent glass or plastic, within which items can be displayed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The outer covering or framework of a piece of apparatus such as a computer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'A shallow tray divided into compartments or "boxes" for holding type, traditionally arranged in sets of two, the "upper case" (containing capitals, small capitals, accented) and "lower case" (small letters, figures, punctuation marks, quadrats, and spaces).',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) The nature of a piece of alphabetic type, whether a “capital” (upper case) or “small” (lower case) letter.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Four of a kind.", synonyms: [], antonyms: [] },
            {
              definition:
                "A unit of liquid measure used to measure sales in the beverage industry, equivalent to 192 fluid ounces.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small fissure which admits water into the workings.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A thin layer of harder metal on the surface of an object whose deeper metal is allowed to remain soft.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cardboard box that holds (usually 24) beer bottles or cans.",
              synonyms: ["carton"],
              antonyms: [],
              example: "a single case of Bud Light",
            },
          ],
          synonyms: ["carton"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To place (an item or items of manufacture) into a box, as in preparation for shipment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cover or protect with, or as if with, a case; to enclose.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To survey (a building or other location) surreptitiously, as in preparation for a robbery.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/case",
        "https://en.wiktionary.org/wiki/cases",
      ],
    },
  ],
  foods: [
    {
      word: "foods",
      phonetic: "/fuːdz/",
      phonetics: [
        { text: "/fuːdz/", audio: "" },
        { text: "/fudz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any solid substance that can be consumed by living organisms, especially by eating, in order to sustain life.",
              synonyms: [],
              antonyms: [],
              example: "The innkeeper brought them food and drink.",
            },
            {
              definition: "A foodstuff.",
              synonyms: ["belly-timber", "foodstuff", "provender"],
              antonyms: [],
            },
            {
              definition: "Anything that nourishes or sustains.",
              synonyms: [],
              antonyms: [],
              example: "Mozart and Bach are food for my soul.",
            },
          ],
          synonyms: [
            "belly-timber",
            "chow",
            "comestible",
            "eats",
            "feed",
            "fodder",
            "foodstuffs",
            "nosh",
            "nourishment",
            "provender",
            "sustenance",
            "victuals",
            "belly-timber",
            "foodstuff",
            "provender",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/food",
        "https://en.wiktionary.org/wiki/foods",
      ],
    },
  ],
  crops: [
    {
      word: "crops",
      phonetic: "/kɹɒps/",
      phonetics: [
        { text: "/kɹɒps/", audio: "" },
        {
          text: "/kɹɑps/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/crops-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453934",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A plant, especially a cereal, grown to be harvested as food, livestock fodder or fuel or for any other economic purpose.",
              synonyms: [],
              antonyms: [],
              example: "the farmer had lots of crops to sell at the market",
            },
            {
              definition:
                "The natural production for a specific year, particularly of plants.",
              synonyms: [],
              antonyms: [],
              example: "it was a good crop of lambs that year",
            },
            {
              definition:
                "A group, cluster or collection of things occurring at the same time.",
              synonyms: [],
              antonyms: [],
              example: "a crop of ideas",
            },
            {
              definition:
                "A group of vesicles at the same stage of development in a disease",
              synonyms: [],
              antonyms: [],
              example: "Like in chicken pox.",
            },
            {
              definition: "The lashing end of a whip",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An entire short whip, especially as used in horse-riding; a riding crop.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A rocky outcrop.", synonyms: [], antonyms: [] },
            { definition: "The act of cropping.", synonyms: [], antonyms: [] },
            {
              definition:
                "A photograph or other image that has been reduced by removing the outer parts.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A short haircut.", synonyms: [], antonyms: [] },
            {
              definition:
                "A pouch-like part of the alimentary tract of some birds (and some other animals), used to store food before digestion, or for regurgitation; a craw.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The foliate part of a finial.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The head of a flower, especially when picked; an ear of corn; the top branches of a tree.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Tin ore prepared for smelting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Outcrop of a vein or seam at the surface.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An entire oxhide.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "craw",
            "harvest",
            "yield",
            "bat",
            "hunting crop",
            "riding crop",
            "whip",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To remove the top end of something, especially a plant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To mow, reap or gather.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cut (especially hair or an animal's tail or ears) short.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To remove the outer parts of a photograph or other image, typically in order to frame the subject better.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To yield harvest.", synonyms: [], antonyms: [] },
            {
              definition: "To cause to bear a crop.",
              synonyms: [],
              antonyms: [],
              example: "to crop a field",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/crop",
        "https://en.wiktionary.org/wiki/crops",
      ],
    },
  ],
  fruit: [
    {
      word: "fruit",
      phonetic: "/fɹuːt/",
      phonetics: [
        {
          text: "/fɹuːt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fruit-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9021865",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/fɹut/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fruit-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=502000",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(often in the plural) In general, a product of plant growth useful to man or animals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Specifically, a sweet, edible part of a plant that resembles seed-bearing fruit (see next sense), even if it does not develop from a floral ovary; also used in a technically imprecise sense for some sweet or sweetish vegetables, such as the petioles of rhubarb, that resemble a true fruit or are used in cookery as if they were a fruit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A product of fertilization in a plant, specifically:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An end result, effect, or consequence; advantageous or disadvantageous result.",
              synonyms: [],
              antonyms: [],
              example:
                "His long nights in the office eventually bore fruit when his business boomed and he was given a raise.",
            },
            {
              definition:
                "Of, belonging to, related to, or having fruit or its characteristics; (of living things) producing or consuming fruit.",
              synonyms: [],
              antonyms: [],
              example:
                "the fresh-squeezed fruit juice; a fruit salad; an artificial fruit flavor; a fruit tree; a fruit bat",
            },
            {
              definition: "A homosexual man; an effeminate man.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Offspring from a sexual union.",
              synonyms: [],
              antonyms: [],
              example:
                "Blessed art thou amongst women and blessed is the fruit of thy womb.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To produce fruit, seeds, or spores.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fruit"],
    },
  ],
  stick: [
    {
      word: "stick",
      phonetic: "/stɪk/",
      phonetics: [
        {
          text: "/stɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stick-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=338390",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An elongated piece of wood or similar material, typically put to some use, for example as a wand or baton.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any roughly cylindrical (or rectangular) unit of a substance.",
              synonyms: [],
              antonyms: [],
              example:
                "Sealing wax is available as a cylindrical or rectangular stick.",
            },
            {
              definition:
                "Material or objects attached to a stick or the like.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A tool, control, or instrument shaped somewhat like a stick.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A stick-like item:", synonyms: [], antonyms: [] },
            {
              definition: "Ability; specifically:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person or group of people. (Perhaps, in some senses, because people are, broadly speaking, tall and thin, like pieces of wood.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Encouragement or punishment, or (resulting) vigour or other improved behavior.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A measure.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cut a piece of wood to be the stick member of a cope-and-stick joint.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To compose; to set, or arrange, in a composing stick.",
              synonyms: [],
              antonyms: [],
              example: "to stick type",
            },
            {
              definition: "To furnish or set with sticks.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stick"],
    },
    {
      word: "stick",
      phonetic: "/stɪk/",
      phonetics: [
        {
          text: "/stɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stick-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=338390",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The traction of tires on the road surface.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The amount of fishing line resting on the water surface before a cast; line stick.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A thrust with a pointed instrument; a stab.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To become or remain attached; to adhere.",
              synonyms: [],
              antonyms: [],
              example: "The tape will not stick if it melts.",
            },
            {
              definition: "To jam; to stop moving.",
              synonyms: [],
              antonyms: [],
              example: "The lever sticks if you push it too far up.",
            },
            {
              definition: "To tolerate, to endure, to stick with.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To persist.",
              synonyms: [],
              antonyms: [],
              example: "His old nickname stuck.",
            },
            {
              definition: "Of snow, to remain frozen on landing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To remain loyal; to remain firm.",
              synonyms: [],
              antonyms: [],
              example: "Just stick to your strategy, and you will win.",
            },
            {
              definition:
                "To hesitate, to be reluctant; to refuse (in negative phrases).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be puzzled (at something), have difficulty understanding.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause difficulties, scruples, or hesitation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To attach with glue or as if by gluing.",
              synonyms: [],
              antonyms: [],
              example: "Stick the label on the jar.",
            },
            {
              definition: "To place, set down (quickly or carelessly).",
              synonyms: [],
              antonyms: [],
              example: "Stick your bag over there and come with me.",
            },
            {
              definition:
                "To press (something with a sharp point) into something else.",
              synonyms: [],
              antonyms: [],
              example: "The balloon will pop when I stick this pin in it.",
            },
            {
              definition: "To fix on a pointed instrument; to impale.",
              synonyms: [],
              antonyms: [],
              example: "to stick an apple on a fork",
            },
            {
              definition:
                "To adorn or deck with things fastened on as by piercing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To perform (a landing) perfectly.",
              synonyms: [],
              antonyms: [],
              example: "Once again, the world champion sticks the dismount.",
            },
            {
              definition: "To propagate plants by cuttings.",
              synonyms: [],
              antonyms: [],
              example: "Stick cuttings from geraniums promptly.",
            },
            {
              definition:
                "(joinery) To run or plane (mouldings) in a machine, in contradistinction to working them by hand. Such mouldings are said to be stuck.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To bring to a halt; to stymie; to puzzle.",
              synonyms: [],
              antonyms: [],
              example: "to stick somebody with a hard problem",
            },
            {
              definition:
                "To impose upon; to compel to pay; sometimes, to cheat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To have sexual intercourse with.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To stand pat: to cease taking any more cards and finalize one's hand.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "abide",
            "carry on",
            "cleave",
            "cling",
            "agglutinate",
            "conglutinate",
            "glue",
            "gum",
            "paste",
            "puzzle",
            "stump",
            "thwart",
            "fix",
            "impale",
            "run through",
            "stake",
            "transfix",
            "have sex",
            "falter",
            "waver",
            "pop",
            "set down",
            "pierce",
            "prick",
            "puncture",
            "stand by",
            "stick by",
            "jam",
            "stall",
            "live with",
            "put up with",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Likely to stick; sticking, sticky.",
              synonyms: [],
              antonyms: [],
              example: "A sticker type of glue. The stickest kind of gum.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stick"],
    },
    {
      word: "stick",
      phonetic: "/stɪk/",
      phonetics: [
        {
          text: "/stɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stick-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=338390",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Criticism or ridicule.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stick"],
    },
  ],
  wants: [
    {
      word: "wants",
      phonetic: "/wɒnts/",
      phonetics: [
        { text: "/wɒnts/", audio: "" },
        {
          text: "/wɑnts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wants-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1224198",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A desire, wish, longing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(often followed by of) Lack, absence.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Poverty.", synonyms: [], antonyms: [] },
            {
              definition:
                "Something needed or desired; a thing of which the loss is felt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A depression in coal strata, hollowed out before the subsequent deposition took place.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To wish for or desire (something); to feel a need or desire for; to crave or demand.",
              synonyms: [],
              antonyms: [],
              example:
                "What do you want to eat?  I want you to leave.  I never wanted to go back to live with my mother.",
            },
            {
              definition:
                "(in particular) To wish, desire or demand to see, have the presence of or do business with.",
              synonyms: [],
              antonyms: [],
              example: "Danish police want him for embezzlement.",
            },
            {
              definition: "To desire (to experience desire); to wish.",
              synonyms: [],
              antonyms: [],
              example: "You can leave if you want.",
            },
            {
              definition:
                "(usually second person, often future tense) To be advised to do something (compare should, ought).",
              synonyms: [],
              antonyms: [],
              example:
                "You’ll want to repeat this three or four times to get the best result.",
            },
            {
              definition:
                "(now colloquial) To lack and be in need of or require (something, such as a noun or verbal noun).",
              synonyms: [],
              antonyms: [],
              example: "That chair wants fixing.",
            },
            {
              definition:
                "(now rare) To have occasion for (something requisite or useful); to require or need.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be lacking or deficient or absent.",
              synonyms: [],
              antonyms: [],
              example: "There was something wanting in the play.",
            },
            {
              definition:
                "To be in a state of destitution; to be needy; to lack.",
              synonyms: [],
              antonyms: [],
              example: "The paupers desperately want.",
            },
            {
              definition: "To lack and be without, to not have (something).",
              synonyms: [],
              antonyms: [],
              example: "She wanted anything she needed.",
            },
            {
              definition: "(by extension) To lack and (be able to) do without.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "set one's heart on",
            "wish",
            "would like",
            "without",
            "in need",
            "need",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/want",
        "https://en.wiktionary.org/wiki/wants",
      ],
    },
  ],
  stage: [
    {
      word: "stage",
      phonetic: "/steɪd͡ʒ/",
      phonetics: [
        {
          text: "/steɪd͡ʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stage-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88518368",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/steɪd͡ʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stage-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1158026",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A phase.",
              synonyms: [],
              antonyms: [],
              example:
                "Completion of an identifiable stage of maintenance such as removing an aircraft engine for repair or storage.",
            },
            {
              definition:
                "A platform; a surface, generally elevated, upon which show performances or other public events are given.",
              synonyms: [],
              antonyms: [],
              example: "The band returned to the stage to play an encore.",
            },
            {
              definition: "A floor or storey of a house.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A floor elevated for the convenience of mechanical work, etc.; scaffolding; staging.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A platform, often floating, serving as a kind of wharf.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A stagecoach, an enclosed horsedrawn carriage used to carry passengers.",
              synonyms: [],
              antonyms: [],
              example:
                "The stage pulled into town carrying the payroll for the mill and three ladies.",
            },
            {
              definition:
                "A place of rest on a regularly travelled road; a station; a place appointed for a relay of horses.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A degree of advancement in a journey; one of several portions into which a road or course is marked off; the distance between two places of rest on a road.",
              synonyms: [],
              antonyms: [],
              example: "a stage of ten miles",
            },
            {
              definition:
                "The number of an electronic circuit’s block, such as a filter, an amplifier, etc.",
              synonyms: [],
              antonyms: [],
              example:
                "a 3-stage cascade of a 2nd-order bandpass Butterworth filter",
            },
            {
              definition:
                "The place on a microscope where the slide is located for viewing.",
              synonyms: [],
              antonyms: [],
              example: "He placed the slide on the stage.",
            },
            {
              definition:
                "A level; one of the sequential areas making up the game.",
              synonyms: ["level"],
              antonyms: [],
              example:
                "How do you get past the flying creatures in the third stage?",
            },
            {
              definition:
                "A place where anything is publicly exhibited, or a remarkable affair occurs; the scene.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The succession of rock strata laid down in a single age on the geologic time scale.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "level",
            "tier",
            "area",
            "board",
            "level",
            "map",
            "phase",
            "track",
            "world",
            "zone",
            "level",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To produce on a stage, to perform a play.",
              synonyms: [],
              antonyms: [],
              example:
                'The local theater group will stage "Pride and Prejudice".',
            },
            {
              definition: "To demonstrate in a deceptive manner.",
              synonyms: [],
              antonyms: [],
              example:
                "The salesman's demonstration of the new cleanser was staged to make it appear highly effective.",
            },
            {
              definition: "To orchestrate; to carry out.",
              synonyms: [],
              antonyms: [],
              example:
                "A protest will be staged in the public square on Monday.",
            },
            {
              definition: "To place in position to prepare for use.",
              synonyms: [],
              antonyms: [],
              example:
                "We staged the cars to be ready for the start, then waited for the starter to drop the flag.",
            },
            {
              definition:
                "To determine what stage (a disease, etc.) has progressed to",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["fake"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stage"],
    },
  ],
  sheep: [
    {
      word: "sheep",
      phonetic: "/ʃiːp/",
      phonetics: [
        {
          text: "/ʃiːp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sheep-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9014220",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ʃip/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sheep-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=720490",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A woolly ruminant of the genus Ovis.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A timid, shy person who is easily led by others.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(chiefly plural) A religious adherent, a member of a congregation or religious community (compare flock).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Sheepskin leather.", synonyms: [], antonyms: [] },
            {
              definition:
                "(speech recognition) A person who is easily understood by a speech recognition system; contrasted with goat.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sheep"],
    },
    {
      word: "sheep",
      phonetic: "/ʃiːp/",
      phonetics: [
        {
          text: "/ʃiːp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sheep-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9014220",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ʃip/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sheep-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=720490",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A sheep; specifically singular form of sheep.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sheep",
        "https://en.wiktionary.org/wiki/shoop",
      ],
    },
  ],
  nouns: [
    {
      word: "nouns",
      phonetic: "/naʊnz/",
      phonetics: [{ text: "/naʊnz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(grammar, narrow sense) A word that can be used to refer to a person, animal, place, thing, phenomenon, substance, quality, or idea; one of the basic parts of speech in many languages, including English.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(grammar, now rare, broad sense) Either a word that can be used to refer to a person, animal, place, thing, phenomenon, substance, quality or idea, or a word that modifies or describes a previous word or its referent; a substantive or adjective, sometimes also including other parts of speech such as numeral or pronoun.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "name",
            "nameword",
            "naming word",
            "noun substantive",
            "substantive",
            "substantive noun",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To convert a word to a noun.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/noun",
        "https://en.wiktionary.org/wiki/nouns",
      ],
    },
  ],
  plain: [
    {
      word: "plain",
      phonetic: "/pleɪn/",
      phonetics: [
        {
          text: "/pleɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/plain-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1227752",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Flat, level.", synonyms: [], antonyms: [] },
            { definition: "Simple.", synonyms: [], antonyms: [] },
            { definition: "Obvious.", synonyms: [], antonyms: [] },
            { definition: "Open.", synonyms: [], antonyms: [] },
            {
              definition: "Not unusually beautiful; unattractive.",
              synonyms: [],
              antonyms: [],
              example:
                "Throughout high school she worried that she had a rather plain face.",
            },
            { definition: "Not a trump.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "frank",
            "sincere",
            "consarn",
            "darned",
            "stinking",
            "no-frills",
            "simple",
            "unadorned",
            "unseasoned",
            "normal",
            "ordinary",
            "blatant",
            "ostensible",
            "monochrome",
          ],
          antonyms: [
            "bells and whistles",
            "decorative",
            "exotic",
            "fancy",
            "ornate",
          ],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Simply.",
              synonyms: [],
              antonyms: [],
              example: "I plain forgot.",
            },
            {
              definition: "Plainly; distinctly.",
              synonyms: [],
              antonyms: [],
              example: "Tell me plain: do you love me or no?",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/plain"],
    },
    {
      word: "plain",
      phonetic: "/pleɪn/",
      phonetics: [
        {
          text: "/pleɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/plain-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1227752",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A lamentation.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To complain.", synonyms: [], antonyms: [] },
            {
              definition: "To lament, bewail.",
              synonyms: [],
              antonyms: [],
              example: "to plain a loss",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/plain"],
    },
    {
      word: "plain",
      phonetic: "/pleɪn/",
      phonetics: [
        {
          text: "/pleɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/plain-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1227752",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A land area free of woodland, cities, and towns; open country.",
              synonyms: [],
              antonyms: [],
              example:
                "There are several species of wild flowers growing in this field.",
            },
            {
              definition:
                "A wide, open space that is usually used to grow crops or to hold farm animals.",
              synonyms: [],
              antonyms: [],
              example: "A crop circle was made in a corn field.",
            },
            {
              definition: "A place where competitive matches are carried out.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various figurative meanings, regularly dead metaphors.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "course",
            "court",
            "ground",
            "pitch",
            "area",
            "domain",
            "realm",
            "sphere",
            "box",
            "input field",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An expanse of land with relatively low relief, usually exclusive of forests, deserts, and wastelands.",
              synonyms: ["flatland", "grassland"],
              antonyms: [],
            },
          ],
          synonyms: ["flatland", "grassland"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To level; to raze; to make plain or even on the surface.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make plain or manifest; to explain.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A level or flat surface.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A flat surface extending infinitely in all directions (e.g. horizontal or vertical plane).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A level of existence or development. (eg, astral plane)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A roughly flat, thin, often moveable structure used to create lateral force by the flow of air or water over its surface, found on aircraft, submarines, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Unicode) Any of a number of designated ranges of sequential code points.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An imaginary plane which divides the body into two portions.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A tool for smoothing wood by removing thin layers from the surface.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An airplane; an aeroplane.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various nymphalid butterflies, of various genera, having a slow gliding flight. Also called aeroplanes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The butterfly Bindahara phocides, family Lycaenidae, of Asia and Australasia.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A deciduous tree of the genus Platanus.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(Northern UK) A sycamore.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/field",
        "https://en.wiktionary.org/wiki/plain",
        "https://en.wiktionary.org/wiki/plane",
      ],
    },
  ],
  drink: [
    {
      word: "drink",
      phonetic: "/dɹɪŋk/",
      phonetics: [
        { text: "/dɹɪŋk/", audio: "" },
        {
          text: "/dɹɪŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/drink-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=856986",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To consume (a liquid) through the mouth.",
              synonyms: [],
              antonyms: [],
              example: "He drank the water I gave him.",
            },
            {
              definition:
                "(metonymic) To consume the liquid contained within (a bottle, glass, etc.).",
              synonyms: [],
              antonyms: [],
              example: "Jack drank the whole bottle by himself.",
            },
            {
              definition: "To consume alcoholic beverages.",
              synonyms: [],
              antonyms: [],
              example:
                "Everyone who is drinking is drinking, but not everyone who is drinking is drinking.",
            },
            {
              definition:
                "To take in (a liquid), in any manner; to suck up; to absorb; to imbibe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To take in; to receive within one, through the senses; to inhale; to hear; to see.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To smoke, as tobacco.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "gulp",
            "imbibe",
            "quaff",
            "sip",
            "alcohol",
            "hit the sauce",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/drink"],
    },
    {
      word: "drink",
      phonetic: "/dɹɪŋk/",
      phonetics: [
        { text: "/dɹɪŋk/", audio: "" },
        {
          text: "/dɹɪŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/drink-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=856986",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A beverage.",
              synonyms: [],
              antonyms: [],
              example: "I’d like another drink please.",
            },
            {
              definition: "Drinks in general; something to drink",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A type of beverage (usually mixed).",
              synonyms: [],
              antonyms: [],
              example: "My favourite drink is the White Russian.",
            },
            {
              definition: "A (served) alcoholic beverage.",
              synonyms: [],
              antonyms: [],
              example: "Can I buy you a drink?",
            },
            {
              definition:
                "The action of drinking, especially with the verbs take or have.",
              synonyms: [],
              antonyms: [],
              example: "He was about to take a drink from his root beer.",
            },
            {
              definition: "Alcoholic beverages in general.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(with the) Any body of water.",
              synonyms: [],
              antonyms: [],
              example:
                "If he doesn't pay off the mafia, he’ll wear cement shoes to the bottom of the drink!",
            },
          ],
          synonyms: [
            "gulp",
            "sip",
            "swig",
            "alcohol",
            "beverage",
            "beverage",
            "beverage",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/drink"],
    },
  ],
  bones: [
    {
      word: "bones",
      phonetic: "/bəʊnz/",
      phonetics: [
        { text: "/bəʊnz/", audio: "" },
        {
          text: "/boʊnz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bones-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749725",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A composite material consisting largely of calcium phosphate and collagen and making up the skeleton of most vertebrates.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of the components of an endoskeleton, made of bone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A bone of a fish; a fishbone.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A bonefish", synonyms: [], antonyms: [] },
            {
              definition:
                "One of the rigid parts of a corset that forms its frame, the boning, originally made of whalebone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the fragments of bone held between the fingers of the hand and rattled together to keep time to music.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Anything made of bone, such as a bobbin for weaving bone lace.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The framework of anything.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An off-white colour, like the colour of bone.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A dollar.", synonyms: [], antonyms: [] },
            {
              definition: "The wishbone formation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An erect penis; a boner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(chiefly in the plural) A domino or dice.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["rib", "stay"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To prepare (meat, etc) by removing the bone or bones from.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fertilize with bone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To put whalebone into.",
              synonyms: [],
              antonyms: [],
              example: "to bone stays",
            },
            {
              definition:
                "To make level, using a particular procedure; to survey a level line.",
              synonyms: [],
              antonyms: [],
              example: "boning rod",
            },
            {
              definition: "(usually of a man) To have sexual intercourse with.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                '(in Aboriginal culture) To perform "bone pointing", a ritual that is intended to bring illness or even death to the victim.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: '(usually with "up") To study.',
              synonyms: [],
              antonyms: [],
              example: "bone up",
            },
            {
              definition: "To polish boots to a shiny finish.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "debone",
            "unbone",
            "bonk",
            "bury the bone",
            "fuck",
            "screw",
            "shag",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To apprehend, steal.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To sight along an object or set of objects to check whether they are level or in line.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A percussive folk musical instrument played as a pair in one hand, often made from bovine ribs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The act of two fists meeting together in the manner equivalent to a high-five.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The framework or foundation of something.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["fist bump", "props"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A musical instrument in the brass family, having a cylindrical bore, and usually a sliding tube (but sometimes piston valves, and rarely both). Most often refers to the tenor trombone, which is the most common type of trombone and has a fundamental tone of B♭ˌ (contra B♭).",
              synonyms: [],
              antonyms: [],
              example: "Jim plays the trombone very well.",
            },
            {
              definition: "The common European bittern.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bone",
        "https://en.wiktionary.org/wiki/bones",
        "https://en.wiktionary.org/wiki/trombone",
      ],
    },
  ],
  apart: [
    {
      word: "apart",
      phonetic: "/əˈpɑː(ɹ)t/",
      phonetics: [
        { text: "/əˈpɑː(ɹ)t/", audio: "" },
        {
          text: "/əˈpɑɹt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/apart-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1235971",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(Used after a noun or in the predicate) Exceptional, distinct.",
              synonyms: [],
              antonyms: [],
              example: "a world apart",
            },
            {
              definition: "Having been taken apart; disassembled, in pieces.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Placed separately (in regard to space or time).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Separately, exclusively, not together",
              synonyms: [],
              antonyms: [],
              example: "Consider the two propositions apart.",
            },
            { definition: "Aside; away.", synonyms: [], antonyms: [] },
            {
              definition: "In or into two or more parts.",
              synonyms: [],
              antonyms: [],
              example: "We took the computer apart and put it back together.",
            },
          ],
          synonyms: ["independently", "separately", "asunder", "in twain"],
          antonyms: ["together"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/apart"],
    },
  ],
  turns: [
    {
      word: "turns",
      phonetic: "/tɜːnz/",
      phonetics: [
        { text: "/tɜːnz/", audio: "" },
        {
          text: "/tɝnz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/turns-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1750046",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(heading) to make a non-linear physical movement.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(heading) To change condition or attitude.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To change one's course of action; to take a new approach.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(usually with over) To complete.",
              synonyms: [],
              antonyms: [],
              example: "They say they can turn the parts in two days.",
            },
            {
              definition: "To make (money); turn a profit.",
              synonyms: [],
              antonyms: [],
              example: "We turned a pretty penny with that little scheme.",
            },
            {
              definition:
                "Of a player, to go past an opposition player with the ball in one's control.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To undergo the process of turning on a lathe.",
              synonyms: [],
              antonyms: [],
              example: "Ivory turns well.",
            },
            {
              definition:
                "To bring down the feet of a child in the womb, in order to facilitate delivery.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To invert a type of the same thickness, as a temporary substitute for any sort which is exhausted.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To translate.",
              synonyms: [],
              antonyms: [],
              example: "to turn the Iliad",
            },
            {
              definition: "To magically or divinely attack undead.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "become",
            "get",
            "go",
            "rotate",
            "complete",
            "go bad",
            "go off",
            "sour",
            "spoil",
            "rotate",
            "spin",
            "twirl",
            "rebel",
            "revolt",
            "lathe",
            "steer",
            "swerve",
            "tack",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A change of direction or orientation.",
              synonyms: [],
              antonyms: [],
              example: "Give the handle a turn, then pull it.",
            },
            {
              definition:
                "A movement of an object about its own axis in one direction that continues until the object returns to its initial orientation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A walk to and fro.",
              synonyms: ["promenade"],
              antonyms: [],
              example: "Let's take a turn in the garden.",
            },
            {
              definition:
                "A chance to use (something) shared in sequence with others.",
              synonyms: [],
              antonyms: [],
              example: "They took turns playing with the new toy.",
            },
            {
              definition:
                "A spell of work, especially the time allotted to a person in a rota or schedule.",
              synonyms: [],
              antonyms: [],
              example: "I cooked tonight, so it's your turn to do the dishes.",
            },
            {
              definition:
                "One's chance to make a move in a game having two or more players.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A figure in music, often denoted ~, consisting of the note above the one indicated, the note itself, the note below the one indicated, and the note itself again.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The time required to complete a project.",
              synonyms: ["turnaround"],
              antonyms: [],
              example: "They quote a three-day turn on parts like those.",
            },
            {
              definition: "A fit or a period of giddiness.",
              synonyms: [],
              antonyms: [],
              example: "I've had a funny turn.",
            },
            {
              definition: "A change in temperament or circumstance.",
              synonyms: [],
              antonyms: [],
              example: "She took a turn for the worse.",
            },
            {
              definition:
                "A sideways movement of the ball when it bounces (caused by rotation in flight).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The fourth communal card in Texas hold 'em.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The flop (the first three community cards) in Texas hold 'em.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A deed done to another; an act of kindness or malice.",
              synonyms: [],
              antonyms: [],
              example:
                "I felt that the man was of a vindictive nature, and would do me an evil turn if he found the opportunity [...].",
            },
            {
              definition: "A single loop of a coil.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(rope) A pass behind or through an object.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Character; personality; nature.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An instance of going past an opposition player with the ball in one's control.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(circus, especially physical comedy) A short skit, act, or routine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A type turned upside down to serve for another character that is not available.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The profit made by a stockjobber, being the difference between the buying and selling prices.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "change",
            "swing",
            "dizziness",
            "dizzy",
            "giddiness",
            "spell",
            "complete",
            "full",
            "rotation",
            "loop",
            "go",
            "go",
            "move",
            "promenade",
            "turnaround",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/turn",
        "https://en.wiktionary.org/wiki/turns",
      ],
    },
  ],
  moves: [
    {
      word: "moves",
      phonetic: "/muːvz/",
      phonetics: [{ text: "/muːvz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act of moving; a movement.",
              synonyms: [],
              antonyms: [],
              example:
                "A slight move of the tiller, and the boat will go off course.",
            },
            {
              definition:
                "An act for the attainment of an object; a step in the execution of a plan or purpose.",
              synonyms: [],
              antonyms: [],
              example:
                "He made another move towards becoming a naturalized citizen.",
            },
            {
              definition:
                "A formalized or practiced action used in athletics, dance, physical exercise, self-defense, hand-to-hand combat, etc.",
              synonyms: [],
              antonyms: [],
              example:
                "She always gets spontaneous applause for that one move.",
            },
            {
              definition: "The event of changing one's residence.",
              synonyms: [],
              antonyms: [],
              example: "The move into my fiancé's house took two long days.",
            },
            {
              definition: "A change in strategy.",
              synonyms: [],
              antonyms: [],
              example: "I am worried about our boss's move.",
            },
            {
              definition: "A transfer, a change from one employer to another.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The act of moving a token on a gameboard from one position to another according to the rules of the game.",
              synonyms: ["play"],
              antonyms: [],
              example: "If you roll a six, you can make two moves.",
            },
          ],
          synonyms: ["relocation", "removal", "play"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To change place or posture; to go, in any manner, from one place or position to another.",
              synonyms: ["stir"],
              antonyms: [],
              example: "A ship moves rapidly.",
            },
            {
              definition: "To act; to take action; to begin to act",
              synonyms: ["get moving", "stir"],
              antonyms: [],
              example: "Come on guys, let's move: there's work to do!",
            },
            {
              definition:
                "To change residence, for example from one house, town, or state, to another; to go and live at another place. See also move out and move in.",
              synonyms: [],
              antonyms: [],
              example:
                "I decided to move to the country for a more peaceful life.",
            },
            {
              definition:
                "(and other games) To change the place of a piece in accordance with the rules of the game.",
              synonyms: [],
              antonyms: [],
              example:
                "My opponent's counter was moving much quicker round the board than mine.",
            },
            {
              definition:
                "To cause to change place or posture in any manner; to set in motion; to carry, convey, draw, or push from one place to another",
              synonyms: ["impel", "stir"],
              antonyms: [],
              example: "The horse moves a carriage.",
            },
            {
              definition:
                "To transfer (a piece or man) from one space or position to another, according to the rules of the game",
              synonyms: [],
              antonyms: [],
              example: "She moved the queen closer to the centre of the board.",
            },
            {
              definition:
                "To excite to action by the presentation of motives; to rouse by representation, persuasion, or appeal; to influence.",
              synonyms: [],
              antonyms: [],
              example: "This song moves me to dance.",
            },
            {
              definition:
                "To arouse the feelings or passions of; especially, to excite to tenderness or compassion, to excite (for example, an emotion).",
              synonyms: ["affect", "trouble"],
              antonyms: [],
              example: "That book really moved me.",
            },
            {
              definition:
                "To propose; to recommend; specifically, to propose formally for consideration and determination, in a deliberative assembly; to submit",
              synonyms: [],
              antonyms: [],
              example:
                "I move to repeal the rule regarding obligatory school uniform.",
            },
            {
              definition:
                "To mention; to raise (a question); to suggest (a course of action); to lodge (a complaint).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To incite, urge (someone to do something); to solicit (someone for or of an issue); to make a proposal to.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To apply to, as for aid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To request an action from the court.",
              synonyms: [],
              antonyms: [],
              example:
                "An attorney moved the court to issue a restraining order.",
            },
            {
              definition: "To bow or salute upon meeting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To sell, to market (especially, but not exclusively, illegal products)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "actuate",
            "affect",
            "trouble",
            "agitate",
            "get moving",
            "stir",
            "impel",
            "impel",
            "stir",
            "incite",
            "incline",
            "induce",
            "influence",
            "instigate",
            "offer",
            "persuade",
            "prompt",
            "propose",
            "rouse",
            "stir",
            "stir",
            "transfer",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A good ability to dance.",
              synonyms: [],
              antonyms: [],
              example:
                "Check out the guy on the dancefloor, he's got seriously good moves.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/move",
        "https://en.wiktionary.org/wiki/moves",
      ],
    },
  ],
  touch: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  angle: [
    {
      word: "angle",
      phonetic: "/ˈæŋ.ɡəl/",
      phonetics: [
        {
          text: "/ˈæŋ.ɡəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/angle-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=718866",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A figure formed by two rays which start from a common point (a plane angle) or by three planes that intersect (a solid angle).",
              synonyms: [],
              antonyms: [],
              example: "the angle between lines A and B",
            },
            {
              definition:
                "The measure of such a figure. In the case of a plane angle, this is the ratio (or proportional to the ratio) of the arc length to the radius of a section of a circle cut by the two rays, centered at their common point. In the case of a solid angle, this is the ratio of the surface area to the square of the radius of the section of a sphere.",
              synonyms: [],
              antonyms: [],
              example:
                "The angle between lines A and B is π/4 radians, or 45 degrees.",
            },
            {
              definition: "A corner where two walls intersect.",
              synonyms: [],
              antonyms: [],
              example: "an angle of a building",
            },
            {
              definition: "A change in direction.",
              synonyms: [],
              antonyms: [],
              example: "The horse took off at an angle.",
            },
            {
              definition: "A viewpoint; a way of looking at something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The focus of a news story.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any of various hesperiid butterflies.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A storyline between two wrestlers, providing the background for and approach to a feud.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An ulterior motive; a scheme or means of benefitting from a situation, usually hidden, often immoral",
              synonyms: [],
              antonyms: [],
              example:
                "His angle is that he gets a percentage, but mostly in trade.",
            },
            {
              definition: "A projecting or sharp corner; an angular fragment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of the four cardinal points of an astrological chart: the Ascendant, the Midheaven, the Descendant and the Imum Coeli.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "swerve",
            "corner",
            "nook",
            "-gon",
            "hexagon",
            "opinion",
            "perspective",
            "point of view",
            "slant",
            "view",
            "viewpoint",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(often in the passive) To place (something) at an angle.",
              synonyms: [],
              antonyms: [],
              example: "The roof is angled at 15 degrees.",
            },
            {
              definition: "To change direction rapidly.",
              synonyms: [],
              antonyms: [],
              example:
                "The five ball angled off the nine ball but failed to reach the pocket.",
            },
            {
              definition:
                "To present or argue something in a particular way or from a particular viewpoint.",
              synonyms: [],
              antonyms: [],
              example:
                "How do you want to angle this when we talk to the client?",
            },
            {
              definition:
                '(cue sports) To hamper (oneself or one\'s opponent) by leaving the cue ball in the jaws of a pocket such that the surround of the pocket (the "angle") blocks the path from cue ball to object ball.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/angle"],
    },
    {
      word: "angle",
      phonetic: "/ˈæŋ.ɡəl/",
      phonetics: [
        {
          text: "/ˈæŋ.ɡəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/angle-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=718866",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A fishhook; tackle for catching fish, consisting of a line, hook, and bait, with or without a rod.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To try to catch fish with a hook and line.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(with for) To attempt to subtly persuade someone to offer a desired thing.",
              synonyms: [],
              antonyms: [],
              example: "He must be angling for a pay rise.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/angle"],
    },
  ],
  based: [
    {
      word: "based",
      phonetic: "/beɪst/",
      phonetics: [
        {
          text: "/beɪst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/based-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1669275",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To give as its foundation or starting point; to lay the foundation of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be located (at a particular place).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(acrobatics, cheerleading) To act as a base; to be the person supporting the flyer.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Founded on; having a basis; often used in combining forms.",
              synonyms: [],
              antonyms: [],
              example: "That was a soundly based argument.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/base",
        "https://en.wiktionary.org/wiki/based",
      ],
    },
    {
      word: "based",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(of a person) Not caring what others think about one's personality, style, or behavior; focused on maintaining individuality.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(4chan, Reddit, Instagram) Praiseworthy and admirable, often through exhibiting independence and security.",
              synonyms: [],
              antonyms: ["cringe"],
              example: "Based and red pilled.",
            },
          ],
          synonyms: [],
          antonyms: ["cringe"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/based"],
    },
  ],
  range: [
    {
      word: "range",
      phonetic: "/ɹeɪndʒ/",
      phonetics: [
        {
          text: "/ɹeɪndʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/range-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1157454",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A line or series of mountains, buildings, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A fireplace; a fire or other cooking apparatus; now specifically, a large cooking stove with many hotplates.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Selection, array.",
              synonyms: [],
              antonyms: [],
              example: "We sell a wide range of cars.",
            },
            {
              definition: "An area for practicing shooting at targets.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An area for military training or equipment testing.",
              synonyms: ["base", "training area", "training ground"],
              antonyms: [],
            },
            {
              definition:
                "The distance from a person or sensor to an object, target, emanation, or event.",
              synonyms: ["distance", "radius"],
              antonyms: [],
              example:
                "One can use the speed of sound to estimate the range of a lightning flash.",
            },
            {
              definition:
                "Maximum distance of capability (of a weapon, radio, detector, fuel supply, etc.).",
              synonyms: [],
              antonyms: [],
              example: "This missile's range is 500 kilometres.",
            },
            {
              definition: "An area of open, often unfenced, grazing land.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Extent or space taken in by anything excursive; compass or extent of excursion; reach; scope.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The set of values (points) which a function can obtain.",
              synonyms: [],
              antonyms: ["domain"],
            },
            {
              definition:
                "The length of the smallest interval which contains all the data in a sample; the difference between the largest and smallest observations in the sample.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The defensive area that a player can cover.",
              synonyms: [],
              antonyms: [],
              example: "Jones has good range for a big man.",
            },
            {
              definition:
                "The scale of all the tones a voice or an instrument can produce.",
              synonyms: ["compass"],
              antonyms: [],
            },
            {
              definition:
                "The geographical area or zone where a species is normally naturally found.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sequential list of values specified by an iterator.",
              synonyms: [],
              antonyms: [],
              example:
                "std::for_each  calls the given function on each value in the input range.",
            },
            {
              definition:
                "An aggregate of individuals in one rank or degree; an order; a class.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The step of a ladder; a rung.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A bolting sieve to sift meal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A wandering or roving; a going to and fro; an excursion; a ramble; an expedition.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In the public land system, a row or line of townships lying between two succession meridian lines six miles apart.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The scope of something, the extent that something covers or includes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The variety of roles that an actor can play in a satisfactory way.",
              synonyms: [],
              antonyms: [],
              example:
                "By playing in comedies as well as in dramas he has proved his acting range.",
            },
          ],
          synonyms: [
            "base",
            "training area",
            "training ground",
            "compass",
            "distance",
            "radius",
          ],
          antonyms: ["domain"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To travel over (an area, etc); to roam, wander.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To rove over or through.",
              synonyms: [],
              antonyms: [],
              example: "to range the fields",
            },
            {
              definition:
                "To exercise the power of something over something else; to cause to submit to, over.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To bring (something) into a specified position or relationship (especially, of opposition) with something else.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(followed by over) Of a variable, to be able to take any of the values in a specified range.",
              synonyms: [],
              antonyms: [],
              example:
                "The variable x ranges over all real values from 0 to 10.",
            },
            {
              definition: "To classify.",
              synonyms: [],
              antonyms: [],
              example: "to range plants and animals in genera and species",
            },
            {
              definition: "To form a line or a row.",
              synonyms: [],
              antonyms: [],
              example: "The front of a house ranges with the street.",
            },
            {
              definition:
                "To be placed in order; to be ranked; to admit of arrangement or classification; to rank.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To set in a row, or in rows; to place in a regular line or lines, or in ranks; to dispose in the proper order.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To place among others in a line, row, or order, as in the ranks of an army; usually, reflexively and figuratively, to espouse a cause, to join a party, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be native to, or live in, a certain district or region.",
              synonyms: [],
              antonyms: [],
              example: "The peba ranges from Texas to Paraguay.",
            },
            {
              definition: "To separate into parts; to sift.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To sail or pass in a direction parallel to or near.",
              synonyms: [],
              antonyms: [],
              example: "to range the coast",
            },
            {
              definition:
                "Of a player, to travel a significant distance for a defensive play.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/range"],
    },
  ],
  marks: [
    {
      word: "marks",
      phonetic: "/mɑːks/",
      phonetics: [
        { text: "/mɑːks/", audio: "" },
        {
          text: "/mɑɹks/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/marks-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2079719",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(heading) Boundary, land within a boundary.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(heading) Characteristic, sign, visible impression.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(heading) Indicator of position, objective etc.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "(heading) Attention.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["Mk", "Mk.", "heed", "observance"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To put a mark on (something); to make (something) recognizable by a mark; to label or write on (something).",
              synonyms: [],
              antonyms: [],
              example: "to mark a box or bale of merchandise",
            },
            {
              definition:
                "To leave a mark (often an undesirable or unwanted one) on (something).",
              synonyms: ["blemish", "scar", "scratch", "stain"],
              antonyms: [],
              example: "See where this pencil has marked the paper.",
            },
            {
              definition:
                "To have a long-lasting negative impact on (someone or something).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To create an indication of (a location).",
              synonyms: [],
              antonyms: [],
              example:
                "She folded over the corner of the page to mark where she left off reading.",
            },
            {
              definition:
                "To be an indication of (something); to show where (something) is located.",
              synonyms: [
                "demonstrate",
                "indicate",
                "manifest",
                "reveal",
                "show",
                "signal",
              ],
              antonyms: [],
              example: "A bell marked the end of visiting hours.",
            },
            {
              definition:
                "To indicate (something) in writing or by other symbols.",
              synonyms: ["display", "show", "write"],
              antonyms: [],
              example: "In her Bible, the words of Christ were marked in red.",
            },
            {
              definition: "To create (a mark) on a surface.",
              synonyms: ["draw", "trace"],
              antonyms: [],
            },
            {
              definition:
                "To celebrate or acknowledge (an event) through an action of some kind.",
              synonyms: ["commemorate", "solemnize"],
              antonyms: [],
              example: "The national holiday is marked by fireworks.",
            },
            {
              definition:
                "(of things) To identify (someone as a particular type of person or as having a particular role).",
              synonyms: [],
              antonyms: [],
              example: "His courage and energy marked him as a leader.",
            },
            {
              definition:
                "(of people) To assign (someone) to a particular category or class.",
              synonyms: ["classify", "mark out"],
              antonyms: [],
            },
            {
              definition:
                "(of people) To choose or intend (someone) for a particular end or purpose.",
              synonyms: ["destine", "mark out", "target"],
              antonyms: [],
            },
            {
              definition:
                "To be a point in time or space at which something takes place; to accompany or be accompanied by (an event, action, etc.); to coincide with.",
              synonyms: ["represent", "see"],
              antonyms: [],
              example:
                "That summer marked the beginning of her obsession with cycling.",
            },
            {
              definition: "To be typical or characteristic of (something).",
              synonyms: ["characterize", "typify"],
              antonyms: [],
            },
            {
              definition: "To distinguish (one person or thing from another).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To focus one's attention on (something or someone); to pay attention to, to take note of.",
              synonyms: ["heed", "listen to", "look at", "observe", "watch"],
              antonyms: [],
              example: "Mark my words: that boy’s up to no good.",
            },
            {
              definition:
                "To become aware of (something) through the physical senses.",
              synonyms: [
                "hear",
                "note",
                "notice",
                "observe",
                "perceive",
                "see",
              ],
              antonyms: [],
            },
            {
              definition: "To hold (someone) in one's line of sight.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To indicate the correctness of and give a score to (a school assignment, exam answers, etc.).",
              synonyms: ["grade", "score"],
              antonyms: [],
              example:
                "The teacher had to spend her weekend marking all the tests.",
            },
            {
              definition: "To record that (someone) has a particular status.",
              synonyms: [],
              antonyms: [],
              example: "to mark a student absent.",
            },
            {
              definition:
                "To keep account of; to enumerate and register; to keep score.",
              synonyms: [],
              antonyms: [],
              example:
                "to mark the points in a game of billiards or a card game",
            },
            {
              definition:
                "To follow a player not in possession of the ball when defending, to prevent them receiving a pass easily.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To catch the ball directly from a kick of 15 metres or more without having been touched in transit, resulting in a free kick.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To put a marker in the place of one's ball.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To sing softly, sometimes an octave lower than usual, in order to protect one's voice during a rehearsal.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "blemish",
            "scar",
            "scratch",
            "stain",
            "characterize",
            "typify",
            "classify",
            "mark out",
            "commemorate",
            "solemnize",
            "demonstrate",
            "indicate",
            "manifest",
            "reveal",
            "show",
            "signal",
            "destine",
            "mark out",
            "target",
            "display",
            "show",
            "write",
            "draw",
            "trace",
            "grade",
            "score",
            "hear",
            "note",
            "notice",
            "observe",
            "perceive",
            "see",
            "heed",
            "listen to",
            "look at",
            "observe",
            "watch",
            "represent",
            "see",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A measure of weight (especially for gold and silver), once used throughout Europe, equivalent to 8 oz.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An English and Scottish unit of currency (originally valued at one mark weight of silver), equivalent to 13 shillings and fourpence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various European monetary units, especially the base unit of currency of Germany between 1948 and 2002, equal to 100 pfennigs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A coin worth one mark.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["Deutsche Mark", "Deutschmark", "German mark"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mark",
        "https://en.wiktionary.org/wiki/marks",
      ],
    },
  ],
  tired: [
    {
      word: "tired",
      phonetic: "/taɪəd/",
      phonetics: [
        {
          text: "/taɪəd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tired-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9027611",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/taɪɚd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tired-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1197441",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To become sleepy or weary.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make sleepy or weary.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To become bored or impatient (with).",
              synonyms: [],
              antonyms: [],
              example: "I tire of this book.",
            },
            { definition: "To bore.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To dress or adorn.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To seize, pull, and tear prey, as a hawk does.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To seize, rend, or tear something as prey; to be fixed upon, or engaged with, anything.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "In need of some rest or sleep.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Fed up, annoyed, irritated, sick of.",
              synonyms: [],
              antonyms: [],
              example: "I'm tired of this",
            },
            {
              definition: "Overused, cliché.",
              synonyms: [],
              antonyms: [],
              example: "a tired song",
            },
            {
              definition: "Ineffectual; incompetent",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["exhausted", "fatigued", "languid", "sleepy"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tire",
        "https://en.wiktionary.org/wiki/tired",
      ],
    },
  ],
  older: [
    {
      word: "older",
      phonetic: "/ˈəʊldə/",
      phonetics: [
        { text: "/ˈəʊldə/", audio: "" },
        {
          text: "/ˈoʊldɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/older-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1676811",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Of an object, concept, relationship, etc., having existed for a relatively long period of time.",
              synonyms: [],
              antonyms: [],
              example: "an old abandoned building;  an old friend",
            },
            {
              definition: "Having been used and thus no longer new or unused.",
              synonyms: [],
              antonyms: [],
              example:
                "I find that an old toothbrush is good to clean the keyboard with.",
            },
            {
              definition: "Having existed or lived for the specified time.",
              synonyms: [],
              antonyms: [],
              example:
                "How old are they? She’s five years old and he's seven. We also have a young teen and a two-year-old child.",
            },
            {
              definition: "(heading) Of an earlier time.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Tiresome after prolonged repetition.",
              synonyms: [],
              antonyms: [],
              example: "Your constant pestering is getting old.",
            },
            {
              definition:
                "Said of subdued colors, particularly reds, pinks and oranges, as if they had faded over time.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A grammatical intensifier, often used in describing something positive. (Mostly in idioms like good old, big old and little old, any old and some old.)",
              synonyms: [],
              antonyms: [],
              example:
                "We're having a good old time. My next car will be a big old SUV.  My wife makes the best little old apple pie in Texas.",
            },
            { definition: "Excessive, abundant.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "erstwhile",
            "ex-",
            "former",
            "one-time",
            "past",
            "ancient",
            "long in the tooth",
            "aged",
            "of age",
            "aged",
            "ageing",
            "aging",
            "elderly",
            "long in the tooth",
            "on in years",
            "antiquated",
            "obsolete",
            "outdated",
          ],
          antonyms: [
            "current",
            "latest",
            "new",
            "brand new",
            "fresh",
            "new",
            "young",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/old",
        "https://en.wiktionary.org/wiki/older",
      ],
    },
  ],
  farms: [
    {
      word: "farms",
      phonetic: "/fɑːmz/",
      phonetics: [
        { text: "/fɑːmz/", audio: "" },
        {
          text: "/fɑɹmz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/farms-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2650943",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A place where agricultural and similar activities take place, especially the growing of crops or the raising of livestock.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A tract of land held on lease for the purpose of cultivation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(usually in combination) A location used for an industrial purpose, having many similar structures",
              synonyms: [],
              antonyms: [],
              example: "antenna farm",
            },
            {
              definition: "A group of coordinated servers.",
              synonyms: [],
              antonyms: [],
              example: "a render farm",
            },
            {
              definition: "Food; provisions; a meal.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A banquet; feast.", synonyms: [], antonyms: [] },
            {
              definition:
                "A fixed yearly amount (food, provisions, money, etc.) payable as rent or tax.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A fixed yearly sum accepted from a person as a composition for taxes or other moneys which he is empowered to collect; also, a fixed charge imposed on a town, county, etc., in respect of a tax or taxes to be collected within its limits.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The letting-out of public revenue to a ‘farmer’; the privilege of farming a tax or taxes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The body of farmers of public revenues.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The condition of being let at a fixed rent; lease; a lease.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To work on a farm, especially in the growing and harvesting of crops.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To devote (land) to farming.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To grow (a particular crop).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To give up to another, as an estate, a business, the revenue, etc., on condition of receiving in return a percentage of what it yields; to farm out.",
              synonyms: [],
              antonyms: [],
              example: "to farm the taxes",
            },
            {
              definition:
                "To lease or let for an equivalent, e.g. land for a rent; to yield the use of to proceeds.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To take at a certain rent or rate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To engage in grinding (repetitive activity) in a particular area or against specific enemies for a particular drop or item.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cleanse; clean out; put in order; empty; empty out",
              synonyms: [],
              antonyms: [],
              example: "Farm out the stable and pigsty.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/farm",
        "https://en.wiktionary.org/wiki/farms",
      ],
    },
  ],
  spend: [
    {
      word: "spend",
      phonetic: "/spɛnd/",
      phonetics: [
        {
          text: "/spɛnd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spend-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1230264",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Amount of money spent (during a period); expenditure.",
              synonyms: [],
              antonyms: [],
              example:
                "I’m sorry, boss, but the advertising spend exceeded the budget again this month.",
            },
            {
              definition:
                "(in the plural) Expenditures; money or pocket money.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Discharged semen.", synonyms: [], antonyms: [] },
            { definition: "Vaginal discharge.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To pay out (money).",
              synonyms: [],
              antonyms: [],
              example:
                "He spends far more on gambling than he does on living proper.",
            },
            {
              definition: "To bestow; to employ; often with on or upon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To squander.",
              synonyms: [],
              antonyms: [],
              example: "to spend an estate in gambling",
            },
            {
              definition: "To exhaust, to wear out.",
              synonyms: [],
              antonyms: [],
              example: "The violence of the waves was spent.",
            },
            {
              definition: "To consume, to use up (time).",
              synonyms: [],
              antonyms: [],
              example: "My sister usually spends her free time in nightclubs.",
            },
            {
              definition: "To have an orgasm; to ejaculate sexually.",
              synonyms: [],
              antonyms: [],
              example:
                "The fish spends his semen on eggs which he finds floating and whose mother he has never seen.",
            },
            {
              definition: "To waste or wear away; to be consumed.",
              synonyms: [],
              antonyms: [],
              example: "Energy spends in the using of it.",
            },
            {
              definition: "To be diffused; to spread.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To break ground; to continue working.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spend"],
    },
  ],
  shoes: [
    {
      word: "shoes",
      phonetic: "/ʃuːz/",
      phonetics: [
        {
          text: "/ʃuːz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shoes-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9027404",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ʃuːz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shoes-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749983",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A protective covering for the foot, with a bottom part composed of thick leather or plastic sole and often a thicker heel, and a softer upper part made of leather or synthetic material. Shoes generally do not extend above the ankle, as opposed to boots, which do.",
              synonyms: [],
              antonyms: [],
              example: "Get your shoes on now, or you'll be late for school.",
            },
            {
              definition:
                "A piece of metal designed to be attached to a horse's foot as a means of protection; a horseshoe.",
              synonyms: [],
              antonyms: [],
              example:
                "Throw the shoe from behind the line, and try to get it to land circling (a ringer) or touching the far stake.",
            },
            {
              definition:
                "A device for holding multiple decks of playing cards, allowing more games to be played by reducing the time between shuffles.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something resembling a shoe in form, position, or function, such as a brake shoe.",
              synonyms: [],
              antonyms: [],
              example:
                "Remember to turn the rotors when replacing the brake shoes, or they will wear out unevenly.",
            },
            {
              definition:
                "The outer cover or tread of a pneumatic tire, especially for an automobile.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To put shoes on one's feet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To put horseshoes on a horse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To equip an object with a protection against wear.",
              synonyms: [],
              antonyms: [],
              example: "The billiard cue stick was shod in silver.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/shoe",
        "https://en.wiktionary.org/wiki/shoes",
      ],
    },
  ],
  goods: [
    {
      word: "goods",
      phonetic: "/ɡʊdz/",
      phonetics: [
        {
          text: "/ɡʊdz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/goods-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1676773",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The forces or behaviours that are the enemy of evil. Usually consists of helping others and general benevolence.",
              synonyms: [],
              antonyms: ["bad", "evil"],
            },
            {
              definition:
                "A result that is positive in the view of the speaker.",
              synonyms: [],
              antonyms: ["bad"],
            },
            {
              definition:
                "The abstract instantiation of goodness; that which possesses desirable qualities, promotes success, welfare, or happiness, is serviceable, fit, excellent, kind, benevolent, etc.",
              synonyms: [],
              antonyms: [],
              example: "The best is the enemy of the good.",
            },
            {
              definition: "(usually in the plural) An item of merchandise.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["bad", "bad", "evil"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "That which is produced, then traded, bought or sold, then finally consumed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(often preceded by the) Something authentic, important, or revealing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Freight (not passengers)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Short for goods train.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["evidence", "fact", "wares"],
          antonyms: ["capital", "services"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/good",
        "https://en.wiktionary.org/wiki/goods",
      ],
    },
  ],
  chair: [
    {
      word: "chair",
      phonetic: "/t͡ʃɛə(ɹ)/",
      phonetics: [
        {
          text: "/t͡ʃɛə(ɹ)/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chair-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9014318",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/t͡ʃɛəɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chair-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1239752",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An item of furniture used to sit on or in, comprising a seat, legs, back, and sometimes arm rests, for use by one person. Compare stool, couch, sofa, settee, loveseat and bench.",
              synonyms: [],
              antonyms: [],
              example:
                "All I need to weather a snowstorm is hot coffee, a warm fire, a good book and a comfortable chair.",
            },
            {
              definition:
                "The seating position of a particular musician in an orchestra.",
              synonyms: [],
              antonyms: [],
              example:
                "My violin teacher used to play first chair with the Boston Pops.",
            },
            {
              definition:
                "An iron block used on railways to support the rails and secure them to the sleepers, and similar devices.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of two possible conformers of cyclohexane rings (the other being boat), shaped roughly like a chair.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A distinguished professorship at a university.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A vehicle for one person; either a sedan borne upon poles, or a two-wheeled carriage drawn by one horse; a gig.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The seat or office of a person in authority, such as a judge or bishop.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To act as chairperson at; to preside over.",
              synonyms: [],
              antonyms: [],
              example: "Bob will chair tomorrow's meeting.",
            },
            {
              definition:
                "To carry in a seated position upon one's shoulders, especially in celebration or victory.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To award a chair to (a winning poet) at a Welsh eisteddfod.",
              synonyms: [],
              antonyms: [],
              example: "The poet was chaired at the national Eisteddfod.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A chairman or chairwoman, someone who presides over a meeting, board, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["chair", "officer", "presider", "presiding"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A chair-like device used for performing execution by electrocution.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An electrically powered wheelchair.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A transitional hold in which an attacking wrestler hoists an opponent up onto his/her shoulders so that they are both facing in the same direction.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/chair",
        "https://en.wiktionary.org/wiki/chairperson",
        "https://en.wiktionary.org/wiki/electric%20chair",
      ],
    },
  ],
  twice: [
    {
      word: "twice",
      phonetic: "/twaɪs/",
      phonetics: [
        {
          text: "/twaɪs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/twice-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1648324",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            { definition: "Two times.", synonyms: [], antonyms: [] },
            {
              definition:
                '(usually with "as", of a specified quality) Doubled in quantity, intensity, or degree.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["two times"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/twice"],
    },
  ],
  cents: [
    {
      word: "cents",
      phonetic: "/sɛn/",
      phonetics: [
        {
          text: "/sɛn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cents-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=47844610",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(money) A subunit of currency equal to one-hundredth of the main unit of currency in many countries. Symbol: ¢.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A small sum of money.",
              synonyms: [],
              antonyms: [],
              example: "He blew every last cent.",
            },
            {
              definition:
                "(money) A subunit of currency equal to one-hundredth of the euro.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(money) A coin having face value of one cent (in either of the above senses).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A hundredth of a semitone or half step.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["penny", "dollarcent", "eurocent"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The point in the interior of a circle that is equidistant from all points on the circumference.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The point in the interior of a sphere that is equidistant from all points on the circumference.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The middle portion of something; the part well away from the edges.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The point on a line that is midway between the ends.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The point in the interior of any figure of any number of dimensions that has as its coordinates the arithmetic mean of the coordinates of all points on the perimeter of the figure (or of all points in the interior for a center of volume).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The subgroup (respectively, subring), denoted Z(G), of those elements of a given group (respectively, ring) G that commute with every element of G.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A place where the greater part of some function or activity occurs.",
              synonyms: [],
              antonyms: [],
              example:
                "shopping center, convention center, civic center, garment center, Lincoln Center for the Performing Arts, Rockefeller Center",
            },
            {
              definition:
                "A topic that is particularly important in a given context.",
              synonyms: [],
              antonyms: [],
              example: "the center of attention",
            },
            {
              definition: "A player in the middle of the playing area",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A player who can go all over the court, except the shooting circles.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A pass played into the centre of the pitch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the backs operating in a central area of the pitch, either the inside centre or outside centre.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A temporary structure upon which the materials of a vault or arch are supported in position until the work becomes self-supporting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the two conical steel pins in a lathe, etc., upon which the work is held, and about which it revolves.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A conical recess or indentation in the end of a shaft or other work, to receive the point of a center, on which the work can turn, as in a lathe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The ensemble of moderate or centrist political parties.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "middle",
            "midst",
            "center of gravity",
            "center of mass",
            "centroid",
            "midpoint",
          ],
          antonyms: ["periphery"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A centigrade temperature scale having the freezing point of water defined as 0° and its boiling point defined as 100° at standard atmospheric pressure. Known as the Celsius scale since 1948.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A single degree on this scale.",
              synonyms: [],
              antonyms: [],
              example: "a temperature of 34 centigrades",
            },
            {
              definition:
                "A unit of angle equal to the hundredth part of a quadrant. Its symbol is gon.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(Sanskrit and other Indian philology) satakam, set of one hundred verses connected by the same metre or topic",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Perfect score on a board exam",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A period of 100 consecutive years; often specifically a numbered period with conventional start and end dates, e.g., the twentieth century, which stretches from (strictly) 1901 through 2000, or (informally) 1900 through 1999. The first century AD was from 1 to 100.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A unit in ancient Roman army, originally of 100 army soldiers as part of a cohort, later of more varied sizes (but typically containing 60 to 70 or 80) soldiers or other men (guards, police, firemen), commanded by a centurion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A political division of ancient Rome, meeting in the Centuriate Assembly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A hundred things of the same kind; a hundred.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hundred runs scored either by a single player in one innings, or by two players in a partnership.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A score of one hundred points.",
              synonyms: [],
              antonyms: [],
              example: "That was his tenth professional century.",
            },
            {
              definition:
                "A race a hundred units (as meters, kilometres, miles) in length.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A banknote in the denomination of one hundred dollars.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["centuria", "yearhundred"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cent",
        "https://en.wiktionary.org/wiki/center",
        "https://en.wiktionary.org/wiki/centigrade",
        "https://en.wiktionary.org/wiki/cents",
        "https://en.wiktionary.org/wiki/centum",
        "https://en.wiktionary.org/wiki/century",
      ],
    },
  ],
  empty: [
    {
      word: "empty",
      phonetic: "/ˈɛmpti/",
      phonetics: [
        {
          text: "/ˈɛmpti/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/empty-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9014402",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ˈɛmpti/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/empty-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1661645",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(usually plural) A container, especially a bottle, whose contents have been used up, leaving it empty.",
              synonyms: [],
              antonyms: [],
              example: "Put the empties out to be recycled.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make empty; to void; to remove the contents of.",
              synonyms: [],
              antonyms: [],
              example: "The cinema emptied quickly after the end of the film.",
            },
            {
              definition:
                "Of a river, duct, etc: to drain or flow toward an ultimate destination.",
              synonyms: [],
              antonyms: [],
              example:
                "Salmon River empties on the W shore about 2 miles below Bear River.",
            },
          ],
          synonyms: [],
          antonyms: ["fill"],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Devoid of content; containing nothing or nobody; vacant.",
              synonyms: [],
              antonyms: [],
              example: "an empty purse; an empty jug; an empty stomach",
            },
            {
              definition:
                "Containing no elements (as of a string, array, or set), opposed to being null (having no valid value).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Free; clear; devoid; often with of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Having nothing to carry, emptyhanded; unburdened.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Destitute of effect, sincerity, or sense; said of language.",
              synonyms: [],
              antonyms: [],
              example: "empty offer",
            },
            {
              definition: "Unable to satisfy; hollow; vain.",
              synonyms: [],
              antonyms: [],
              example: "empty pleasures",
            },
            {
              definition:
                "Destitute of reality, or real existence; unsubstantial.",
              synonyms: [],
              antonyms: [],
              example: "empty dreams",
            },
            {
              definition:
                "Destitute of, or lacking, sense, knowledge, or courtesy.",
              synonyms: [],
              antonyms: [],
              example: "empty brains; an empty coxcomb",
            },
            {
              definition:
                "(of some female animals, especially cows and sheep) Not pregnant; not producing offspring when expected to do so during the breeding season.",
              synonyms: [],
              antonyms: [],
              example: "Empty cow rates have increased in recent years.",
            },
            {
              definition:
                "Producing nothing; unfruitful; said of a plant or tree.",
              synonyms: [],
              antonyms: [],
              example: "an empty vine",
            },
          ],
          synonyms: ["clean", "clear", "leer", "toom", "unoccupied"],
          antonyms: ["non-empty", "full"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/empty"],
    },
  ],
  alike: [
    {
      word: "alike",
      phonetic: "/əˈlaɪk/",
      phonetics: [
        { text: "/əˈlaɪk/", audio: "" },
        {
          text: "/əˈlaɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/alike-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1684545",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Having resemblance or similitude; similar; without difference.",
              synonyms: [],
              antonyms: [],
              example: "The twins were alike.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition:
                "In the same manner, form, or degree; in common; equally.",
              synonyms: [],
              antonyms: [],
              example: "We are all alike concerned in religion.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/alike"],
    },
  ],
  style: [
    {
      word: "style",
      phonetic: "/staɪl/",
      phonetics: [
        { text: "/staɪl/", audio: "" },
        {
          text: "/staɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/style-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1648315",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Senses relating to a thin, pointed object.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension from sense 1.1) A particular manner of expression in writing or speech, especially one regarded as good.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A particular manner of creating, doing, or presenting something, especially a work of architecture or art.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To design, fashion, make, or arrange in a certain way or form (style)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To call or give a name or title to.",
              synonyms: ["designate", "dub", "name"],
              antonyms: [],
            },
            {
              definition:
                "To create for, or give to, someone a style, fashion, or image, particularly one which is regarded as attractive, tasteful, or trendy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To act in a way which seeks to show that one possesses style.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["designate", "dub", "name"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/style"],
    },
  ],
  broke: [
    {
      word: "broke",
      phonetic: "/bɹəʊk/",
      phonetics: [
        { text: "/bɹəʊk/", audio: "" },
        {
          text: "/bɹoʊk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/broke-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1231937",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To separate into two or more pieces, to fracture or crack, by a process that cannot easily be reversed for reassembly.",
              synonyms: [],
              antonyms: [],
              example: "If the vase falls to the floor, it might break.",
            },
            {
              definition:
                "To divide (something, often money) into smaller units.",
              synonyms: [],
              antonyms: [],
              example: "Can you break a hundred-dollar bill for me?",
            },
            {
              definition:
                "To cause (a person or animal) to lose spirit or will; to crush the spirits of.",
              synonyms: [],
              antonyms: [],
              example: "Her child's death broke Angela.",
            },
            {
              definition: "To be crushed, or overwhelmed with sorrow or grief.",
              synonyms: [],
              antonyms: [],
              example: "My heart is breaking.",
            },
            {
              definition:
                "To interrupt; to destroy the continuity of; to dissolve or terminate.",
              synonyms: [],
              antonyms: [],
              example:
                "I had won four games in a row, but now you've broken my streak of luck.",
            },
            {
              definition: "To ruin financially.",
              synonyms: [],
              antonyms: [],
              example: "The recession broke some small businesses.",
            },
            {
              definition: "To violate, to not adhere to.",
              synonyms: [],
              antonyms: [],
              example: "He broke his vows by cheating on his wife.",
            },
            {
              definition:
                "(of a fever) To pass the most dangerous part of the illness; to go down, in terms of temperature.",
              synonyms: [],
              antonyms: [],
              example:
                "Susan's fever broke at about 3 AM, and the doctor said the worst was over.",
            },
            {
              definition: "(of a spell of settled weather) To end.",
              synonyms: [],
              antonyms: [],
              example:
                "The forecast says the hot weather will break by midweek.",
            },
            {
              definition: "(of a storm) To begin; to end.",
              synonyms: [],
              antonyms: [],
              example:
                "Around midday the storm broke, and the afternoon was calm and sunny.",
            },
            {
              definition: "(of morning, dawn, day etc.) To arrive.",
              synonyms: [],
              antonyms: [],
              example: "Morning has broken.",
            },
            {
              definition:
                "To render (a game) unchallenging by altering its rules or exploiting loopholes or weaknesses in them in a way that gives a player an unfair advantage.",
              synonyms: [],
              antonyms: [],
              example:
                "Changing the rules to let white have three extra queens would break chess.",
            },
            {
              definition:
                "To stop, or to cause to stop, functioning properly or altogether.",
              synonyms: [],
              antonyms: [],
              example: "Did you two break the trolley by racing with it?",
            },
            {
              definition: "To cause (a barrier) to no longer bar.",
              synonyms: [],
              antonyms: [],
              example: "break a seal",
            },
            {
              definition:
                "To destroy the arrangement of; to throw into disorder; to pierce.",
              synonyms: [],
              antonyms: [],
              example:
                "The cavalry were not able to break the British squares.",
            },
            {
              definition:
                "(of a wave of water) To collapse into surf, after arriving in shallow water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To burst forth; to make its way; to come into view.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To interrupt or cease one's work or occupation temporarily.",
              synonyms: [],
              antonyms: [],
              example: "Let's break for lunch.",
            },
            {
              definition:
                "To interrupt (a fall) by inserting something so that the falling object does not (immediately) hit something else beneath.",
              synonyms: [],
              antonyms: [],
              example:
                "He survived the jump out the window because the bushes below broke his fall.",
            },
            {
              definition: "To disclose or make known an item of news, etc.",
              synonyms: [],
              antonyms: [],
              example:
                "I don't know how to break this to you, but your cat is not coming back.",
            },
            {
              definition: "(of a sound) To become audible suddenly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To change a steady state abruptly.",
              synonyms: [],
              antonyms: [],
              example: "His coughing broke the silence.",
            },
            {
              definition: "To suddenly become.",
              synonyms: [],
              antonyms: [],
              example:
                "The arrest was standard, when suddenly the suspect broke ugly.",
            },
            {
              definition: "Of a male voice, to become deeper at puberty.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of a voice, to alter in type due to emotion or strain: in men generally to go up, in women sometimes to go down; to crack.",
              synonyms: [],
              antonyms: [],
              example: "His voice breaks when he gets emotional.",
            },
            {
              definition:
                "To surpass or do better than (a specific number), to do better than (a record), setting a new record.",
              synonyms: [],
              antonyms: [],
              example: "He broke the men's 100-meter record.",
            },
            { definition: ":", synonyms: [], antonyms: [] },
            {
              definition:
                "(most often in the passive tense) To demote, to reduce the military rank of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To end (a connection), to disconnect.",
              synonyms: [],
              antonyms: [],
              example:
                "I couldn't hear a thing he was saying, so I broke the connection and called him back.",
            },
            {
              definition: "(of an emulsion) To demulsify.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To counter-attack", synonyms: [], antonyms: [] },
            {
              definition:
                "To lay open, as a purpose; to disclose, divulge, or communicate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To become weakened in constitution or faculties; to lose health or strength.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fail in business; to become bankrupt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To destroy the strength, firmness, or consistency of.",
              synonyms: [],
              antonyms: [],
              example: "to break flax",
            },
            {
              definition:
                "To destroy the official character and standing of; to cashier; to dismiss.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make an abrupt or sudden change; to change the gait.",
              synonyms: [],
              antonyms: [],
              example: "to break into a run or gallop",
            },
            {
              definition: "To fall out; to terminate friendship.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To terminate the execution of a program before normal completion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To suspend the execution of a program during debugging so that the state of the program can be investigated.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "crack",
            "fracture",
            "burst",
            "bust",
            "shatter",
            "shear",
            "smash",
            "split",
            "break down",
            "bust",
            "fail",
            "go down",
            "contravene",
            "go against",
            "violate",
            "break in",
            "subject",
            "tame",
          ],
          antonyms: [
            "hold",
            "assemble",
            "fix",
            "join",
            "mend",
            "put together",
            "repair",
          ],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Financially ruined, bankrupt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Without any money, penniless.",
              synonyms: [],
              antonyms: [],
              example: "dead broke; flat broke",
            },
            {
              definition: "Broken.",
              synonyms: [],
              antonyms: [],
              example: "If it ain't broke, don't fix it.",
            },
            {
              definition: "Demoted, deprived of a commission.",
              synonyms: [],
              antonyms: [],
              example:
                "He was broke and rendered unfit to serve His Majesty at sea.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/break",
        "https://en.wiktionary.org/wiki/broke",
      ],
    },
    {
      word: "broke",
      phonetic: "/bɹəʊk/",
      phonetics: [
        { text: "/bɹəʊk/", audio: "" },
        {
          text: "/bɹoʊk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/broke-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1231937",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(papermaking) Paper or board that is discarded and repulped during the manufacturing process.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A fragment, remains, a piece broken off.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/broke"],
    },
    {
      word: "broke",
      phonetic: "/bɹəʊk/",
      phonetics: [
        { text: "/bɹəʊk/", audio: "" },
        {
          text: "/bɹoʊk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/broke-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1231937",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To act as a broker; to transact business for another.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To act as procurer in love matters; to pimp.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/broke"],
    },
    {
      word: "broke",
      phonetic: "/bɹəʊk/",
      phonetics: [
        { text: "/bɹəʊk/", audio: "" },
        {
          text: "/bɹoʊk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/broke-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1231937",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Broke off, rich, wealthy",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/broke"],
    },
  ],
  pairs: [
    {
      word: "pairs",
      phonetic: "/pɛəz/",
      phonetics: [
        { text: "/pɛəz/", audio: "" },
        { text: "/pɛɹz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Two similar or identical things taken together; often followed by of.",
              synonyms: [],
              antonyms: [],
              example:
                "I couldn't decide which of the pair of designer shirts I preferred, so I bought the pair.",
            },
            {
              definition:
                "Two people in a relationship, partnership or friendship.",
              synonyms: [],
              antonyms: [],
              example: "Spouses should make a great pair.",
            },
            {
              definition:
                "Used with binary nouns (often in the plural to indicate multiple instances, since such nouns are plural only, except in some technical contexts)",
              synonyms: [],
              antonyms: [],
              example:
                "a pair of scissors; two pairs of spectacles; several pairs of jeans",
            },
            {
              definition:
                "A couple of working animals attached to work together, as by a yoke.",
              synonyms: [],
              antonyms: [],
              example:
                "A pair is harder to drive than two mounts with separate riders.",
            },
            {
              definition:
                "A poker hand that contains two cards of identical rank, which cannot also count as a better hand.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A score of zero runs (a duck) in both innings of a two-innings match.",
              synonyms: ["pair of spectacles", "spectacles"],
              antonyms: [],
            },
            {
              definition: "A double play, two outs recorded in one play.",
              synonyms: [],
              antonyms: [],
              example: "They turned a pair to end the fifth.",
            },
            {
              definition:
                "A doubleheader, two games played on the same day between the same teams",
              synonyms: [],
              antonyms: [],
              example: "The Pirates took a pair from the Phillies.",
            },
            {
              definition: "A boat for two sweep rowers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A pair of breasts",
              synonyms: [],
              antonyms: [],
              example: "She's got a gorgeous pair.",
            },
            {
              definition:
                "The exclusion of one member of a parliamentary party from a vote, if a member of the other party is absent for important personal reasons.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Two members of opposite parties or opinion, as in a parliamentary body, who mutually agree not to vote on a given question, or on issues of a party nature during a specified time.",
              synonyms: [],
              antonyms: [],
              example: "There were two pairs on the final vote.",
            },
            {
              definition:
                "A number of things resembling one another, or belonging together; a set.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(kinematics) In a mechanism, two elements, or bodies, which are so applied to each other as to mutually constrain relative motion; named in accordance with the motion it permits, as in turning pair, sliding pair, twisting pair.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["pair of spectacles", "spectacles"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To group into one or more sets of two.",
              synonyms: [],
              antonyms: [],
              example:
                "The wedding guests were paired boy/girl and groom's party/bride's party.",
            },
            {
              definition:
                "To bring two (animals, notably dogs) together for mating.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To engage (oneself) with another of opposite opinions not to vote on a particular question or class of questions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To suit; to fit, as a counterpart.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To impair, to make worse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To become worse, to deteriorate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pair",
        "https://en.wiktionary.org/wiki/pairs",
      ],
    },
  ],
  count: [
    {
      word: "count",
      phonetic: "/kaʊnt/",
      phonetics: [
        { text: "/kaʊnt/", audio: "" },
        {
          text: "/kaʊnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/count-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1234352",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act of counting or tallying a quantity.",
              synonyms: [],
              antonyms: [],
              example:
                "Give the chairs a quick count to check if we have enough.",
            },
            {
              definition:
                "The result of a tally that reveals the number of items in a set; a quantity counted.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A countdown.", synonyms: [], antonyms: [] },
            {
              definition:
                "A charge of misconduct brought in a legal proceeding.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The number of balls and strikes, respectively, on a batter's in-progress plate appearance.",
              synonyms: [],
              antonyms: [],
              example: "He has a 3–2 count with the bases loaded.",
            },
            {
              definition:
                "An object of interest or account; value; estimation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To recite numbers in sequence.",
              synonyms: [],
              antonyms: [],
              example: "Can you count to a hundred?",
            },
            {
              definition: "To determine the number (of objects in a group).",
              synonyms: [],
              antonyms: [],
              example: "There are three apples; count them.",
            },
            {
              definition: "To be of significance; to matter.",
              synonyms: [],
              antonyms: [],
              example:
                "Your views don’t count here.    It does count if you cheat with someone when you’re drunk.",
            },
            {
              definition:
                "To be an example of something: often followed by as and an indefinite noun.",
              synonyms: [],
              antonyms: [],
              example: "Apples count as a type of fruit.",
            },
            {
              definition: "To consider something an example of something.",
              synonyms: [],
              antonyms: [],
              example:
                "He counts himself a hero after saving the cat from the river.   I count you as more than a friend.",
            },
            {
              definition: "To take account or note (of).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To plead orally; to argue a matter in court; to recite a count.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["enumerate", "number"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "(grammar) Countable.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/count"],
    },
    {
      word: "count",
      phonetic: "/kaʊnt/",
      phonetics: [
        { text: "/kaʊnt/", audio: "" },
        {
          text: "/kaʊnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/count-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1234352",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The male ruler of a county.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A nobleman holding a rank intermediate between dukes and barons.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various nymphalid butterflies of the genus Tanaecia. Other butterflies in this genus are called earls and viscounts.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["earl", "comte", "graf", "conte"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/count"],
    },
  ],
  enjoy: [
    {
      word: "enjoy",
      phonetic: "/ənˈd͡ʒɔɪ/",
      phonetics: [
        {
          text: "/ənˈd͡ʒɔɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/enjoy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1235919",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To receive pleasure or satisfaction from something",
              synonyms: [],
              antonyms: [],
              example: "Enjoy your holidays!   I enjoy dancing.",
            },
            {
              definition: "To have the use or benefit of something.",
              synonyms: [],
              antonyms: [],
              example:
                "I plan to go travelling while I still enjoy good health.",
            },
            {
              definition: "To be satisfied or receive pleasure.",
              synonyms: [],
              antonyms: [],
              example: "I enjoyed a lot.",
            },
            {
              definition: "To have sexual intercourse with.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "coitize",
            "go to bed with",
            "sleep with",
            "appreciate",
            "delight in",
            "rejoice",
            "relish",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/enjoy"],
    },
  ],
  score: [
    {
      word: "score",
      phonetic: "/skɔː/",
      phonetics: [
        { text: "/skɔː/", audio: "" },
        {
          text: "/skɔɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/score-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1770051",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The total number of goals, points, runs, etc. earned by a participant in a game.",
              synonyms: [],
              antonyms: [],
              example: "The player with the highest score is the winner.",
            },
            {
              definition:
                "The number of points accrued by each of the participants in a game, expressed as a ratio or a series of numbers.",
              synonyms: [],
              antonyms: [],
              example: "The score is 8-1 even though it's not even half-time!",
            },
            {
              definition:
                "The performance of an individual or group on an examination or test, expressed by a number, letter, or other symbol; a grade.",
              synonyms: [],
              antonyms: [],
              example: "The test scores for this class were high.",
            },
            {
              definition: "Twenty, 20 (number).",
              synonyms: [],
              antonyms: [],
              example: "Some words have scores of meanings.",
            },
            {
              definition:
                "A distance of twenty yards, in ancient archery and gunnery.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A weight of twenty pounds.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The written form of a musical composition showing all instrumental and vocal parts below each other.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The music of a movie or play.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Subject.", synonyms: [], antonyms: [] },
            {
              definition: "Account; reason; motive; sake; behalf.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A notch or incision; especially, one that is made as a tally mark; hence, a mark, or line, made for the purpose of account.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An account or reckoning; account of dues; bill; debt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A criminal act, especially:",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A sexual conquest.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cut a notch or a groove in a surface.",
              synonyms: [],
              antonyms: [],
              example:
                "The baker scored the cake so that the servers would know where to slice it.",
            },
            {
              definition:
                "To record the tally of points for a game, a match, or an examination.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To obtain something desired.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To provide (a film, etc.) with a musical score.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "come by",
            "earn",
            "obtain",
            "groove",
            "notch",
            "shake down",
            "pull",
            "soundtrack",
            "keep",
            "score",
            "tally",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "Acknowledgement of success",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/score"],
    },
  ],
  shore: [
    {
      word: "shore",
      phonetic: "/ʃɔː/",
      phonetics: [
        { text: "/ʃɔː/", audio: "" },
        {
          text: "/ʃɔɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shore-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1243840",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Land adjoining a non-flowing body of water, such as an ocean, lake or pond.",
              synonyms: [],
              antonyms: [],
              example:
                "lake shore;  bay shore;  gulf shore;  island shore;  mainland shore;  river shore;  estuary shore;  pond shore;  sandy shore;  rocky shore",
            },
            {
              definition:
                "(from the perspective of one on a body of water) Land, usually near a port.",
              synonyms: [],
              antonyms: [],
              example: "The passengers signed up for shore tours.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To set on shore.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shore"],
    },
    {
      word: "shore",
      phonetic: "/ʃɔː/",
      phonetics: [
        { text: "/ʃɔː/", audio: "" },
        {
          text: "/ʃɔɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shore-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1243840",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A prop or strut supporting the weight or flooring above it.",
              synonyms: [],
              antonyms: [],
              example: "The shores stayed upright during the earthquake.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(without up) To provide with support.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(usually with up) To reinforce (something at risk of failure).",
              synonyms: [],
              antonyms: [],
              example:
                "The workers were shoring up the dock after part of it fell into the water.",
            },
          ],
          synonyms: [
            "bolster",
            "prop up",
            "buttress",
            "reinforce",
            "strengthen",
            "support",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shore"],
    },
    {
      word: "shore",
      phonetic: "/ʃɔː/",
      phonetics: [
        { text: "/ʃɔː/", audio: "" },
        {
          text: "/ʃɔɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shore-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1243840",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cut, originally with a sword or other bladed weapon, now usually with shears, or as if using shears.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To remove the fleece from a sheep etc by clipping.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To deform because of forces pushing in opposite directions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To transform by displacing every point in a direction parallel to some given line by a distance proportional to the point’s distance from the line.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make a vertical cut in the coal.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To reap, as grain.", synonyms: [], antonyms: [] },
            {
              definition: "To deprive of property; to fleece.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/shear",
        "https://en.wiktionary.org/wiki/shore",
      ],
    },
    {
      word: "shore",
      phonetic: "/ʃɔː/",
      phonetics: [
        { text: "/ʃɔː/", audio: "" },
        {
          text: "/ʃɔɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shore-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1243840",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(Obsolete except in Hiberno-English) A sewer.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shore"],
    },
    {
      word: "shore",
      phonetic: "/ʃɔː/",
      phonetics: [
        { text: "/ʃɔː/", audio: "" },
        {
          text: "/ʃɔɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shore-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1243840",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To warn or threaten.", synonyms: [], antonyms: [] },
            { definition: "To offer.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shore"],
    },
  ],
  roots: [
    {
      word: "roots",
      phonetic: "/ɹuːts/",
      phonetics: [
        {
          text: "/ɹuːts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/roots-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100525",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The part of a plant, generally underground, that anchors and supports the plant body, absorbs and stores water and nutrients, and in some plants is able to perform vegetative reproduction.",
              synonyms: [],
              antonyms: [],
              example:
                "This tree's roots can go as deep as twenty metres underground.",
            },
            { definition: "A root vegetable.", synonyms: [], antonyms: [] },
            {
              definition:
                "The part of a tooth extending into the bone holding the tooth in place.",
              synonyms: [],
              antonyms: [],
              example: "Root damage is a common problem of overbrushing.",
            },
            {
              definition:
                "The part of a hair under the skin that holds the hair in place.",
              synonyms: [],
              antonyms: [],
              example: "The root is the only part of the hair that is alive.",
            },
            {
              definition:
                "The part of a hair near the skin that has not been dyed, permed, or otherwise treated.",
              synonyms: [],
              antonyms: [],
              example:
                "He dyed his hair black last month, so the grey roots can be seen.",
            },
            {
              definition: "The primary source; origin.",
              synonyms: ["basis", "origin", "source"],
              antonyms: [],
              example: "The love of money is the root of all evil.",
            },
            {
              definition:
                "Of a number or expression, a number which, when raised to a specified power, yields the specified number or expression.",
              synonyms: [],
              antonyms: [],
              example: "The cube root of 27 is 3.",
            },
            {
              definition:
                "A square root (understood if no power is specified; in which case, “the root of” is often abbreviated to “root”).",
              synonyms: [],
              antonyms: [],
              example: "Multiply by root 2.",
            },
            {
              definition: "A zero (of an equation).",
              synonyms: ["zero"],
              antonyms: ["pole"],
            },
            {
              definition: "The single node of a tree that has no parent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The primary lexical unit of a word, which carries the most significant aspects of semantic content and cannot be reduced into smaller constituents. Inflectional stems often derive from roots.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(philology) A word from which another word or words are derived.",
              synonyms: ["etymon"],
              antonyms: [],
            },
            {
              definition:
                "The fundamental tone of any chord; the tone from whose harmonics, or overtones, a chord is composed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The lowest place, position, or part.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In UNIX terminology, the first user account with complete access to the operating system and its configuration, found at the root of the directory structure; the person who manages accounts on a UNIX system.",
              synonyms: ["root account", "root user", "superuser"],
              antonyms: [],
              example: "I have to log in as root before I do that.",
            },
            {
              definition:
                "The highest directory of a directory structure which may contain both files and subdirectories.",
              synonyms: [],
              antonyms: [],
              example: "I installed the files in the root directory.",
            },
            {
              definition: "A penis, especially the base of a penis.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "basis",
            "origin",
            "source",
            "etymon",
            "root account",
            "root user",
            "superuser",
            "zero",
          ],
          antonyms: ["pole"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An act of sexual intercourse.",
              synonyms: ["screw", "shag"],
              antonyms: [],
              example: "Fancy a root?",
            },
            {
              definition: "A sexual partner.",
              synonyms: ["screw"],
              antonyms: [],
            },
          ],
          synonyms: ["screw", "screw", "shag"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Ancestry.",
              synonyms: [],
              antonyms: [],
              example: "I have both Irish and German roots.",
            },
            {
              definition: "Beginnings; origin.",
              synonyms: [],
              antonyms: [],
              example: "Jazz has its roots in blues.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/root",
        "https://en.wiktionary.org/wiki/roots",
      ],
    },
  ],
  paint: [
    {
      word: "paint",
      phonetic: "/peɪnt/",
      phonetics: [
        {
          text: "/peɪnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/paint-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1113754",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A substance that is applied as a liquid or paste, and dries into a solid coating that protects or adds color/colour to an object or surface to which it has been applied.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in the plural) A set of containers or blocks of paint of different colors/colours, used for painting pictures.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The free-throw lane, construed with the.",
              synonyms: [],
              antonyms: [],
              example:
                "The Nimrods are strong on the outside, but not very good in the paint.",
            },
            {
              definition: "Paintballs.",
              synonyms: [],
              antonyms: [],
              example: "I am running low on paint for my marker.",
            },
            {
              definition: "A face card (king, queen, or jack).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Graphics drawn using an input device, not scanned or generated.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Makeup.", synonyms: [], antonyms: [] },
            {
              definition: "Any substance fixed with latex to harden it.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To apply paint to.", synonyms: [], antonyms: [] },
            {
              definition: "To apply in the manner that paint is applied.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cover (something) with spots of colour, like paint.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To create (an image) with paints.",
              synonyms: [],
              antonyms: [],
              example: "to paint a portrait or a landscape",
            },
            {
              definition: "To practise the art of painting pictures.",
              synonyms: [],
              antonyms: [],
              example: "I've been painting since I was a young child.",
            },
            {
              definition: "To draw an element in a graphical user interface.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To depict or portray.",
              synonyms: [],
              antonyms: [],
              example:
                "She sued the author of the biography, claiming it painted her as a duplicitous fraud.",
            },
            {
              definition: "To color one's face by way of beautifying it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To direct a radar beam toward.",
              synonyms: [],
              antonyms: [],
              example:
                '"We\'ll paint the target for the flyboys," the JTAC said.',
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/paint"],
    },
  ],
  heads: [
    {
      word: "heads",
      phonetic: "/hɛdz/",
      phonetics: [
        {
          text: "/hɛdz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/heads-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1244088",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The part of the body of an animal or human which contains the brain, mouth and main sense organs.",
              synonyms: [],
              antonyms: [],
              example:
                "Be careful when you pet that dog on the head; it may bite.",
            },
            {
              definition: "The topmost, foremost, or leading part.",
              synonyms: [],
              antonyms: [],
              example: "What does it say at the head of the page?",
            },
            {
              definition: "(social, metonymy) A leader or expert.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A significant or important part.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Headway; progress.",
              synonyms: [],
              antonyms: [],
              example:
                "We are having a difficult time making head against this wind.",
            },
            {
              definition: "Topic; subject.",
              synonyms: [],
              antonyms: [],
              example:
                "We will consider performance issues under the head of future improvements.",
            },
            {
              definition: "Denouement; crisis.",
              synonyms: [],
              antonyms: [],
              example: "These isses are going to come to a head today.",
            },
            {
              definition: "(fluid dynamics) Pressure and energy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Fellatio or cunnilingus; oral sex.",
              synonyms: [],
              antonyms: [],
              example: "She gave great head.",
            },
            { definition: "The glans penis.", synonyms: [], antonyms: [] },
            {
              definition: "A heavy or habitual user of illicit drugs.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Power; armed force.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "blow job",
            "blowjob",
            "fellatio",
            "oral sex",
            "headmaster",
            "headmistress",
            "principal",
            "boss",
            "chief",
            "leader",
            "mind",
            "composure",
            "poise",
            "bonce",
            "caput",
            "loaf",
            "noggin",
            "noodle",
            "nut",
            "pate",
            "top",
          ],
          antonyms: [
            "point",
            "sharp",
            "tip",
            "subordinate",
            "underling",
            "base",
            "bottom",
            "foot",
            "tail",
            "underside",
          ],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A pair of speakers worn over or in the ears so only the wearer can hear the sound.",
              synonyms: ["can (slang)", "heads"],
              antonyms: [],
            },
          ],
          synonyms: ["can (slang)", "heads"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "That part of older sailing ships forward of the forecastle and around the beak, used by the crew as their lavatory; still used as the word for toilets on a ship.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The side of a coin that bears the picture of the head of state or similar.",
              synonyms: ["obverse"],
              antonyms: ["tails"],
              example: "Heads, I win.",
            },
            {
              definition:
                "Draft scheme of a bill before it is formally introduced to a parliament.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Tiles laid at the eaves of a house.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["obverse"],
          antonyms: ["tails"],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition:
                "A shouted warning that something is falling from above, mind your heads.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["fore", "heads up", "timber"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/head",
        "https://en.wiktionary.org/wiki/headphones",
        "https://en.wiktionary.org/wiki/heads",
      ],
    },
    {
      word: "heads",
      phonetic: "/hɛdz/",
      phonetics: [
        {
          text: "/hɛdz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/heads-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1244088",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To be in command of. (See also head up.)",
              synonyms: [],
              antonyms: [],
              example: "Who heads the board of trustees?",
            },
            {
              definition: "To come at the beginning of; to commence.",
              synonyms: [],
              antonyms: [],
              example: "A group of clowns headed the procession.",
            },
            {
              definition:
                "To strike with the head; as in soccer, to head the ball",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To move in a specified direction.",
              synonyms: [],
              antonyms: [],
              example: "How does the ship head?",
            },
            {
              definition: "To remove the head from a fish.",
              synonyms: [],
              antonyms: [],
              example: "The salmon are first headed and then scaled.",
            },
            {
              definition:
                "To originate; to spring; to have its course, as a river.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To form a head.",
              synonyms: [],
              antonyms: [],
              example: "This kind of cabbage heads early.",
            },
            {
              definition: "To form a head to; to fit or furnish with a head.",
              synonyms: [],
              antonyms: [],
              example: "to head a nail",
            },
            {
              definition: "To cut off the top of; to lop off.",
              synonyms: [],
              antonyms: [],
              example: "to head trees",
            },
            {
              definition: "To behead; to decapitate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To go in front of.",
              synonyms: [],
              antonyms: [],
              example: "to head a drove of cattle",
            },
            {
              definition:
                "To get in the front of, so as to hinder or stop; to oppose.",
              synonyms: [],
              antonyms: [],
              example: "The wind headed the ship and made progress difficult.",
            },
            {
              definition: "(by extension) To check or restrain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To set on the head.",
              synonyms: [],
              antonyms: [],
              example: "to head a cask",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/head",
        "https://en.wiktionary.org/wiki/heads",
      ],
    },
    {
      word: "heads",
      phonetic: "/hɛdz/",
      phonetics: [
        {
          text: "/hɛdz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/heads-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1244088",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "High-grade marijuana.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["KB", "KBs", "kine bud", "headies"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/heads"],
    },
  ],
  shook: [
    {
      word: "shook",
      phonetic: "/ʃʊk/",
      phonetics: [
        {
          text: "/ʃʊk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shook-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=277094",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A set of pieces for making a cask or box, usually wood.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The parts of a piece of house furniture, as a bedstead, packed together.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["stook"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To pack (staves, etc.) in a shook.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shook"],
    },
    {
      word: "shook",
      phonetic: "/ʃʊk/",
      phonetics: [
        {
          text: "/ʃʊk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shook-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=277094",
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cause (something) to move rapidly in opposite directions alternatingly.",
              synonyms: [],
              antonyms: [],
              example:
                "He shook the can of soda for thirty seconds before delivering it to me, so that, when I popped it open, soda went everywhere.",
            },
            {
              definition:
                "To move (one's head) from side to side, especially to indicate refusal, reluctance or disapproval.",
              synonyms: [],
              antonyms: [],
              example: 'Shaking his head, he kept repeating "No, no, no".',
            },
            {
              definition:
                "To move or remove by agitating; to throw off by a jolting or vibrating motion.",
              synonyms: [],
              antonyms: [],
              example: "to shake fruit down from a tree",
            },
            {
              definition: "To disturb emotionally; to shock.",
              synonyms: ["traumatize"],
              antonyms: [],
              example: "He was shaken by what had happened.",
            },
            {
              definition: "To lose, evade, or get rid of (something).",
              synonyms: [],
              antonyms: [],
              example: "I can't shake the feeling that I forgot something.",
            },
            {
              definition: "To move from side to side.",
              synonyms: ["shiver", "tremble"],
              antonyms: [],
              example: "She shook with grief.",
            },
            {
              definition: '(usually as "shake on") To shake hands.',
              synonyms: [],
              antonyms: [],
              example: "OK, let's shake on it.",
            },
            {
              definition: "To dance.",
              synonyms: [],
              antonyms: [],
              example: "She was shaking it on the dance floor.",
            },
            {
              definition: "To give a tremulous tone to; to trill.",
              synonyms: [],
              antonyms: [],
              example: "to shake a note in music",
            },
            {
              definition: "To threaten to overthrow.",
              synonyms: [],
              antonyms: [],
              example: "The experience shook my religious belief.",
            },
            {
              definition: "To be agitated; to lose firmness.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["shiver", "tremble", "traumatize"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Shaken up; rattled; shocked or surprised.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["shooketh"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/shake",
        "https://en.wiktionary.org/wiki/shook",
      ],
    },
  ],
  serve: [
    {
      word: "serve",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/serve-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75649699",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/sɜːv/", audio: "" },
        {
          text: "/sɝv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/serve-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1247121",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An act of putting the ball or shuttlecock in play in various games.",
              synonyms: [],
              antonyms: [],
              example: "Whose serve is it?",
            },
            {
              definition: "A portion of food or drink, a serving.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["service", "serving"],
          antonyms: ["receive"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(personal) To provide a service (or, by extension, a product, especially food or drink).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To treat (someone) in a given manner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be suitor to; to be the lover of.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To be effective.", synonyms: [], antonyms: [] },
            {
              definition: "To deliver a document.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To lead off with the first delivery over the net in tennis, volleyball, ping pong, badminton etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To copulate with (of male animals); to cover.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be in military service.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To work, to operate (a weapon).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To work through (a given period of time in prison, a sentence).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To wind spun yarn etc. tightly around (a rope or cable, etc.) so as to protect it from chafing or from the weather.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To perform (a public obligation).",
              synonyms: [],
              antonyms: [],
              example:
                "I've received a summons for jury duty. It says I serve one day or one trial.",
            },
            {
              definition:
                "To provide crack cocaine (to), usually by selling, dealing, or distributing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["attend", "bestand", "wait on"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/serve"],
    },
  ],
  angry: [
    {
      word: "angry",
      phonetic: "/ˈæŋ.ɡɹi/",
      phonetics: [
        {
          text: "/ˈæŋ.ɡɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/angry-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=718867",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Displaying or feeling anger.",
              synonyms: [],
              antonyms: [],
              example: "An angry mob started looting the warehouse.",
            },
            {
              definition:
                "(said about a wound or a rash) Inflamed and painful.",
              synonyms: [],
              antonyms: [],
              example: "The broken glass left two angry cuts across my arm.",
            },
            {
              definition:
                "(said about the elements, like the sky or the sea) Dark and stormy, menacing.",
              synonyms: [],
              antonyms: [],
              example: "Angry clouds raced across the sky.",
            },
          ],
          synonyms: [
            "annoyed",
            "apoplectic",
            "cheesed off",
            "enraged",
            "furious",
            "irritated",
            "mad",
            "pissed off",
            "psyched up",
            "vexed",
            "worked up",
            "wrathful",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/angry"],
    },
  ],
  crowd: [
    {
      word: "crowd",
      phonetic: "/kɹaʊd/",
      phonetics: [
        {
          text: "/kɹaʊd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/crowd-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1268340",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A group of people congregated or collected into a close body without order.",
              synonyms: [],
              antonyms: [],
              example:
                "After the movie let out, a crowd of people pushed through the exit doors.",
            },
            {
              definition:
                "Several things collected or closely pressed together; also, some things adjacent to each other.",
              synonyms: [],
              antonyms: [],
              example:
                "There was a crowd of toys pushed beneath the couch where the children were playing.",
            },
            {
              definition:
                "(with definite article) The so-called lower orders of people; the populace, vulgar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A group of people united or at least characterised by a common interest.",
              synonyms: [],
              antonyms: [],
              example:
                "That obscure author's fans were a nerdy crowd which hardly ever interacted before the Internet age.",
            },
          ],
          synonyms: [
            "audience",
            "group",
            "multitude",
            "public",
            "swarm",
            "throng",
            "aggregation",
            "cluster",
            "group",
            "mass",
            "everyone",
            "general public",
            "masses",
            "mob",
            "rabble",
            "unwashed",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To press forward; to advance by pushing.",
              synonyms: [],
              antonyms: [],
              example: "The man crowded into the packed room.",
            },
            {
              definition: "To press together or collect in numbers",
              synonyms: ["crowd in", "swarm", "throng"],
              antonyms: [],
              example: "They crowded through the archway and into the park.",
            },
            {
              definition:
                "To press or drive together, especially into a small space; to cram.",
              synonyms: [],
              antonyms: [],
              example: "He tried to crowd too many cows into the cow-pen.",
            },
            {
              definition: "To fill by pressing or thronging together",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                '(often used with "out of" or "off") To push, to press, to shove.',
              synonyms: [],
              antonyms: [],
              example: "They tried to crowd her off the sidewalk.",
            },
            {
              definition:
                "To approach another ship too closely when it has right of way.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a square-rigged ship) To carry excessive sail in the hope of moving faster.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To press by solicitation; to urge; to dun; hence, to treat discourteously or unreasonably.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["crowd in", "swarm", "throng"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/crowd"],
    },
    {
      word: "crowd",
      phonetic: "/kɹaʊd/",
      phonetics: [
        {
          text: "/kɹaʊd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/crowd-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1268340",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(now dialectal) A fiddle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To play on a crowd; to fiddle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An archaic stringed instrument associated particularly with Wales, though once played widely in Europe, and characterized by a vaulted back and enough space for the player to stop each of the six strings on the fingerboard.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["rote"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/crowd",
        "https://en.wiktionary.org/wiki/crwth",
      ],
    },
  ],
  wheel: [
    {
      word: "wheel",
      phonetic: "/ʍiːl/",
      phonetics: [
        { text: "/ʍiːl/", audio: "" },
        {
          text: "/wil/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wheel-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1197713",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A circular device capable of rotating on its axis, facilitating movement or transportation or performing labour in machines.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The breaking wheel, an old instrument of torture.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person with a great deal of power or influence; a big wheel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The lowest straight in poker: ace, 2, 3, 4, 5.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A wheelrim.", synonyms: [], antonyms: [] },
            {
              definition: "A round portion of cheese.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A Catherine wheel firework.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A rolling or revolving body; anything of a circular form; a disk; an orb.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A turn or revolution; rotation; compass.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A recurring or cyclical course of events.",
              synonyms: [],
              antonyms: [],
              example: "the wheel of life",
            },
            { definition: "A dollar.", synonyms: [], antonyms: [] },
            {
              definition: 'A crown coin; a "cartwheel".',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["breaking wheel", "rim"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To roll along on wheels.",
              synonyms: [],
              antonyms: [],
              example: "Wheel that trolley over here, would you?",
            },
            {
              definition:
                "To transport something or someone using any wheeled mechanism, such as a wheelchair.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To ride a bicycle or tricycle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To change direction quickly, turn, pivot, whirl, wheel around.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause to change direction quickly, turn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To travel around in large circles, particularly in the air.",
              synonyms: [],
              antonyms: [],
              example: "The vulture wheeled above us.",
            },
            {
              definition:
                "To put into a rotatory motion; to cause to turn or revolve; to make or perform in a circle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/wheel"],
    },
  ],
  quick: [
    {
      word: "quick",
      phonetic: "/kwɪk/",
      phonetics: [
        {
          text: "/kwɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/quick-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899671",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/kwɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/quick-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9023326",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/kwɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/quick-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1157442",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Raw or sensitive flesh, especially that underneath finger and toe nails.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Plants used in making a quickset hedge",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The life; the mortal point; a vital part; a part susceptible to serious injury or keen feeling.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Quitchgrass.", synonyms: [], antonyms: [] },
            { definition: "A fast bowler.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To amalgamate surfaces prior to gilding or silvering by dipping them into a solution of mercury in nitric acid.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To quicken.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Moving with speed, rapidity or swiftness, or capable of doing so; rapid; fast.",
              synonyms: [],
              antonyms: [],
              example: "He's a quick runner.",
            },
            {
              definition:
                "Occurring in a short time; happening or done rapidly.",
              synonyms: [],
              antonyms: [],
              example: "That was a quick meal.",
            },
            {
              definition: "Lively, fast-thinking, witty, intelligent.",
              synonyms: [],
              antonyms: [],
              example:
                "You have to be very quick to be able to compete in ad-lib theatrics.",
            },
            {
              definition: "Mentally agile, alert, perceptive.",
              synonyms: [],
              antonyms: [],
              example: "My father is old but he still has a quick wit.",
            },
            {
              definition: "Of temper: easily aroused to anger; quick-tempered.",
              synonyms: [],
              antonyms: [],
              example: "He is wont to be rather quick of temper when tired.",
            },
            { definition: "Alive, living.", synonyms: [], antonyms: [] },
            {
              definition:
                "Pregnant, especially at the stage where the foetus's movements can be felt; figuratively, alive with some emotion or feeling.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Of water: flowing.", synonyms: [], antonyms: [] },
            {
              definition: "Burning, flammable, fiery.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Fresh; bracing; sharp; keen.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: '(of a vein of ore) productive; not "dead" or barren',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "extant",
            "live",
            "vital",
            "hotheaded",
            "rattish",
            "short-tempered",
            "snippish",
            "snippy",
            "bright",
            "droll",
            "keen",
            "fluent",
            "fluminous",
            "fast",
            "rapid",
            "speedy",
            "swift",
            "brief",
            "momentary",
            "short-lived",
            "expecting",
            "gravid",
            "with child",
          ],
          antonyms: ["slow"],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Quickly, in a quick manner.",
              synonyms: [],
              antonyms: [],
              example: "Come here, quick!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/quick"],
    },
  ],
  dress: [
    {
      word: "dress",
      phonetic: "/dɹɛs/",
      phonetics: [
        {
          text: "/dɹɛs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dress-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9014373",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/dɹɛs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dress-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=856985",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An item of clothing (usually worn by a woman or young girl) which both covers the upper part of the body and includes skirts below the waist.",
              synonyms: [],
              antonyms: [],
              example: "Amy and Mary looked very pretty in their dresses.",
            },
            {
              definition: "Apparel, clothing.",
              synonyms: [],
              antonyms: [],
              example: "He came to the party in formal dress.",
            },
            {
              definition: "The system of furrows on the face of a millstone.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A dress rehearsal.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To fit out with the necessary clothing; to clothe, put clothes on (something or someone).",
              synonyms: [],
              antonyms: [],
              example: "He was dressed in the latest fashions.",
            },
            {
              definition: "To clothe oneself; to put on clothes.",
              synonyms: [],
              antonyms: [],
              example:
                "I rose and dressed before daybreak.  It's very cold out. Dress warm.",
            },
            {
              definition:
                "To put on the uniform and equipment necessary to play the game.",
              synonyms: [],
              antonyms: [],
              example:
                "Due to a left ankle sprain, Kobe Bryant did not dress for the game against Indiana",
            },
            {
              definition:
                "Of a man, to allow the genitals to fall to one side or other within the trousers.",
              synonyms: [],
              antonyms: [],
              example: "Does sir dress to the right or the left?",
            },
            {
              definition:
                "To prepare (food) for cooking, especially by seasoning it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To prepare oneself; to make ready.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To adorn, ornament.",
              synonyms: [],
              antonyms: [],
              example: "It was time to dress the windows for Christmas again.",
            },
            {
              definition:
                'To ornament (a ship) by hoisting the national colours at the peak and mastheads, and setting the jack forward; when "dressed full", the signal flags and pennants are added.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To prepare (a set) by installing the props, scenery, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To treat (a wound, or wounded person).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To prepare for use; to fit for any use; to render suitable for an intended purpose; to get ready.",
              synonyms: [],
              antonyms: [],
              example:
                "to dress leather or cloth;  to dress a garden;  to dress grain, by cleansing it;  in mining and metallurgy, to dress ores, by sorting and separating them",
            },
            {
              definition:
                "To prepare the surface of (a material; usually stone or lumber).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To manure (land).", synonyms: [], antonyms: [] },
            {
              definition: "To bolt or sift flour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(sometimes imperative) To arrange in exact continuity of line, as soldiers; commonly to adjust to a straight line and at proper distance; to align.",
              synonyms: [],
              antonyms: [],
              example: "Right, dress!",
            },
            {
              definition:
                "To break and train for use, as a horse or other animal.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bandage", "clothe", "don", "get dressed"],
          antonyms: [
            "strip",
            "undress",
            "disrobe",
            "get undressed",
            "strip",
            "undress",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dress"],
    },
  ],
  share: [
    {
      word: "share",
      phonetic: "/ʃɛə/",
      phonetics: [
        { text: "/ʃɛə/", audio: "" },
        {
          text: "/ʃɛəɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/share-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=424723",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A portion of something, especially a portion given or allotted to someone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A financial instrument that shows that one owns a part of a company that provides the benefit of limited liability.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A configuration enabling a resource to be shared over a network.",
              synonyms: [],
              antonyms: [],
              example:
                "Upload media from the browser or directly to the file share.",
            },
            {
              definition:
                "The action of sharing something with other people via social media.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The sharebone or pubis.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To give part of what one has to somebody else to use or consume.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To have or use in common.",
              synonyms: [],
              antonyms: [],
              example:
                "to share a shelter with another;  They share a language.",
            },
            {
              definition: "To divide and distribute.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To tell to another.",
              synonyms: [],
              antonyms: [],
              example: "He shared his story with the press.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/share"],
    },
    {
      word: "share",
      phonetic: "/ʃɛə/",
      phonetics: [
        { text: "/ʃɛə/", audio: "" },
        {
          text: "/ʃɛəɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/share-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=424723",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The cutting blade of an agricultural machine like a plough, a cultivator or a seeding-machine.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cut; to shear; to cleave; to divide.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/share"],
    },
  ],
  alive: [
    {
      word: "alive",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/alive-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=91894741",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/əˈlaɪv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/alive-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1230203",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having life; living; not dead",
              synonyms: [],
              antonyms: [],
              example:
                "As long as the plant is alive, he will continue to water it.",
            },
            {
              definition:
                "In a state of action; in force or operation; existent",
              synonyms: [],
              antonyms: [],
              example: "to keep the affections alive",
            },
            {
              definition:
                "Busy with activity of many living beings; swarming; thronged; busy.",
              synonyms: [],
              antonyms: [],
              example:
                "Although quite dull during the day, the main street comes alive at night, with many bars and clubs opening.",
            },
            {
              definition: "Sprightly; lively; brisk.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Susceptible; easy to impress; having keen feelings, as opposed to apathy",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(as an intensifier) out of all living creatures.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "alive and kicking",
            "extant",
            "vital",
            "existing",
            "extant",
            "ever",
            "in the world",
            "frisky",
            "peppy",
            "zestful",
          ],
          antonyms: ["dead"],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(only used attributively) Having life; that is alive.",
              synonyms: [],
              antonyms: [],
              example: "The post office will not ship live animals.",
            },
            {
              definition: "Being in existence; actual",
              synonyms: [],
              antonyms: [],
              example:
                "He is a live example of the consequences of excessive drinking.",
            },
            {
              definition: "Having active properties; being energized.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Operational; in actual use rather than in testing etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Taken from a living animal.",
              synonyms: [],
              antonyms: [],
              example: "live feathers",
            },
            {
              definition: "Imparting power; having motion.",
              synonyms: [],
              antonyms: [],
              example: "a live, or driving, axle",
            },
            {
              definition: "Still in active play.",
              synonyms: [],
              antonyms: [],
              example: "a live ball",
            },
            {
              definition: "Seen or heard from a broadcast, as it happens.",
              synonyms: [],
              antonyms: [],
              example:
                "The station presented a live news program every evening.",
            },
            {
              definition: "(of a performance or speech) In person.",
              synonyms: [],
              antonyms: [],
              example: "This nightclub has a live band on weekends.",
            },
            {
              definition:
                "(entertainment, performing) Recorded in front of an audience and not having been edited after recording.",
              synonyms: [],
              antonyms: [],
              example: "a live album",
            },
            {
              definition: "Of firearms or explosives, capable of causing harm.",
              synonyms: [],
              antonyms: [],
              example:
                "The air force practices dropping live bombs on the uninhabited island.",
            },
            {
              definition:
                "(circuitry) Electrically charged or energized, usually indicating that the item may cause electrocution if touched.",
              synonyms: [],
              antonyms: [],
              example: "Use caution when working near live wires.",
            },
            {
              definition:
                "Being a bet which can be raised by the bettor, usually in reference to a blind or straddle.",
              synonyms: [],
              antonyms: [],
              example:
                "Tommy's blind was live, so he was given the option to raise.",
            },
            {
              definition:
                "Featuring humans; not animated, in the phrases “live actors” or “live action”.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Being in a state of ignition; burning.",
              synonyms: [],
              antonyms: [],
              example: "a live coal; live embers",
            },
            {
              definition: "Full of earnestness; active; wide awake; glowing.",
              synonyms: [],
              antonyms: [],
              example: "a live man, or orator",
            },
            { definition: "Vivid; bright.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "real",
            "hot",
            "alive",
            "living",
            "in person",
            "in the flesh",
          ],
          antonyms: [
            "prerecorded",
            "recorded",
            "blank",
            "dummy",
            "dead",
            "neutral",
            "animated",
            "dead",
            "broadcast",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/alive",
        "https://en.wiktionary.org/wiki/live",
      ],
    },
  ],
  noise: [
    {
      word: "noise",
      phonetic: "/nɔɪz/",
      phonetics: [
        {
          text: "/nɔɪz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/noise-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9023279",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/nɔɪz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/noise-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1113683",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Various sounds, usually unwanted or unpleasant.",
              synonyms: [],
              antonyms: [],
              example:
                "He knew that it was trash day, when the garbage collectors made all the noise.",
            },
            {
              definition: "Sound or signal generated by random fluctuations.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Unwanted part of a signal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) Unwanted fuss or bustle; useless activity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The measured level of variation in gene expression among cells, regardless of source, within a supposedly identical population.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Rumour or complaint.",
              synonyms: [],
              antonyms: [],
              example:
                "The problems with the new computer system are causing a lot of noise at Head Office.",
            },
            {
              definition:
                "Music, in general; a concert; also, a company of musicians; a band.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A genre of rock music that uses static and other non-musical sounds, also influenced by art rock.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["sound"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make a noise; to sound.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To spread news of; to spread as rumor or gossip.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/noise"],
    },
  ],
  solid: [
    {
      word: "solid",
      phonetic: "/ˈsɒlɪd/",
      phonetics: [
        { text: "/ˈsɒlɪd/", audio: "" },
        {
          text: "/ˈsɑlɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/solid-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1157895",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A substance in the fundamental state of matter that retains its size and shape without need of a container (as opposed to a liquid or gas).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A three-dimensional figure (as opposed to a surface, an area, or a curve).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A favor.",
              synonyms: [],
              antonyms: [],
              example: "I owe him; he did me a solid last year.",
            },
            {
              definition:
                "An article of clothing which is of a single color throughout.",
              synonyms: [],
              antonyms: [],
              example: "I prefer solids over paisleys.",
            },
            {
              definition: "(in the plural) Food which is not liquid-based.",
              synonyms: [],
              antonyms: [],
              example:
                "The doctor said I can't eat any solids four hours before the operation.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(of an object or substance) That can be picked up or held, having a texture, and usually firm. Unlike a liquid or a gas.",
              synonyms: [],
              antonyms: [],
              example: "Almost all metals are solid at room temperature.",
            },
            {
              definition: "Large in size, quantity, or value.",
              synonyms: ["massive", "substantial"],
              antonyms: [],
            },
            {
              definition:
                "Lacking holes, hollows or admixtures of other materials.",
              synonyms: [],
              antonyms: [],
              example: "solid chocolate",
            },
            {
              definition: "Strong or unyielding.",
              synonyms: [],
              antonyms: [],
              example: "a solid foundation",
            },
            {
              definition: "Excellent, of high quality, or reliable.",
              synonyms: [],
              antonyms: [],
              example:
                "I don't think Dave would have done that. He's a solid dude.",
            },
            {
              definition: "Hearty; filling.",
              synonyms: [],
              antonyms: [],
              example: "a solid meal",
            },
            {
              definition:
                "Worthy of credit, trust, or esteem; substantial; not frivolous or fallacious.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Financially well off; wealthy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Sound; not weak.",
              synonyms: [],
              antonyms: [],
              example: "a solid constitution of body",
            },
            {
              definition: "Written as one word, without spaces or hyphens.",
              synonyms: ["closed"],
              antonyms: [],
              example:
                "American English writes many words as solid that British English hyphenates.",
            },
            {
              definition: "Not having the lines separated by leads; not open.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "United; without division; unanimous.",
              synonyms: [],
              antonyms: [],
              example: "The delegation is solid for a candidate.",
            },
            {
              definition: "Of a single color throughout.",
              synonyms: [],
              antonyms: [],
              example: "He wore a solid shirt with floral pants.",
            },
            {
              definition:
                "(of drawn lines) Continuous; unbroken; not dotted or dashed.",
              synonyms: [],
              antonyms: [],
              example:
                "The solid lines show roads, and the dotted lines footpaths.",
            },
            {
              definition: "Having all the geometrical dimensions; cubic.",
              synonyms: [],
              antonyms: [],
              example: "A solid foot contains 1,728 solid inches.",
            },
          ],
          synonyms: ["closed", "massive", "substantial"],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            { definition: "Solidly.", synonyms: [], antonyms: [] },
            {
              definition: "Without spaces or hyphens.",
              synonyms: [],
              antonyms: [],
              example: "Many long-established compounds are set solid.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/solid"],
    },
  ],
  cloth: [
    {
      word: "cloth",
      phonetic: "/klɑθ/",
      phonetics: [
        { text: "/klɑθ/", audio: "" },
        { text: "/klɒθ/", audio: "" },
        {
          text: "/klɔθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cloth-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=840634",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A woven fabric such as used in dressing, decorating, cleaning or other practical use.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Specifically, a tablecloth, especially as spread before a meal or removed afterwards.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A piece of cloth used for a particular purpose.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(metaphoric) Substance or essence; the whole of something complex.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(metaphoric) Appearance; seeming.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A form of attire that represents a particular profession or status.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(in idioms) Priesthood, clergy.",
              synonyms: [],
              antonyms: [],
              example: "He is a respected man of the cloth.",
            },
          ],
          synonyms: ["material", "stuff"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cloth"],
    },
  ],
  signs: [
    {
      word: "signs",
      phonetic: "/saɪnz/",
      phonetics: [
        {
          text: "/saɪnz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/signs-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1676857",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(sometimes also used uncountably) A visible indication.",
              synonyms: [],
              antonyms: [],
              example: "I gave them a thumbs-up sign.",
            },
            {
              definition: "Physical evidence left by an animal.",
              synonyms: [],
              antonyms: [],
              example: "The hunters found deer sign at the end of the trail.",
            },
            {
              definition:
                "A clearly visible object, generally flat, bearing a short message in words or pictures.",
              synonyms: [],
              antonyms: [],
              example:
                "I missed the sign at the corner so I took the wrong turn.",
            },
            {
              definition: "A wonder; miracle; prodigy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An astrological sign.",
              synonyms: [],
              antonyms: [],
              example: "Your sign is Taurus? That's no surprise.",
            },
            {
              definition:
                "Positive or negative polarity, as denoted by the + or - sign.",
              synonyms: [],
              antonyms: [],
              example: "I got the magnitude right, but the sign was wrong.",
            },
            {
              definition:
                "A specific gesture or motion used to communicate by those with speaking or hearing difficulties; now specifically, a linguistic unit in sign language equivalent to word in spoken languages.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Sign language in general.",
              synonyms: [],
              antonyms: [],
              example: "Sorry, I don't know sign very well.",
            },
            {
              definition: "An omen.",
              synonyms: [],
              antonyms: [],
              example:
                '"It\'s a sign of the end of the world," the doom prophet said.',
            },
            {
              definition:
                "A property of the body that indicates a disease and, unlike a symptom, is unlikely to be noticed by the patient.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A military emblem carried on a banner or standard.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To make a mark", synonyms: [], antonyms: [] },
            {
              definition: "To make the sign of the cross",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To indicate", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sign",
        "https://en.wiktionary.org/wiki/signs",
      ],
    },
  ],
  hills: [
    {
      word: "hills",
      phonetic: "/hɪlz/",
      phonetics: [
        {
          text: "/hɪlz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hills-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1250062",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An elevated location smaller than a mountain.",
              synonyms: [],
              antonyms: [],
              example:
                "The park is sheltered from the wind by a hill to the east.",
            },
            {
              definition: "A sloping road.",
              synonyms: [],
              antonyms: [],
              example:
                "You need to pick up speed to get up the hill that's coming up.",
            },
            {
              definition: "A heap of earth surrounding a plant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A single cluster or group of plants growing close together, and having the earth heaped up about them.",
              synonyms: [],
              antonyms: [],
              example: "a hill of corn or potatoes",
            },
            { definition: "The pitcher’s mound.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To form into a heap or mound.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To heap or draw earth around plants.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hill",
        "https://en.wiktionary.org/wiki/hills",
      ],
    },
  ],
  types: [
    {
      word: "types",
      phonetic: "/taɪps/",
      phonetics: [
        {
          text: "/taɪps/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/types-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2101160",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A grouping based on shared characteristics; a class.",
              synonyms: [],
              antonyms: [],
              example:
                "This type of plane can handle rough weather more easily than that type of plane.",
            },
            {
              definition:
                "An individual considered typical of its class, one regarded as typifying a certain profession, environment, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An individual that represents the ideal for its class; an embodiment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A letter or character used for printing, historically a cast or engraved block.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something, often a specimen, selected as an objective anchor to connect a scientific name to a taxon; this need not be representative or typical.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Preferred sort of person; sort of person that one is attracted to.",
              synonyms: [],
              antonyms: [],
              example: "He was exactly her type.",
            },
            { definition: "A blood group.", synonyms: [], antonyms: [] },
            {
              definition:
                "(corpus linguistics) A word that occurs in a text or corpus irrespective of how many times it occurs, as opposed to a token.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An event or person that prefigures or foreshadows a later event - commonly an Old Testament event linked to Christian times.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A tag attached to variables and values used in determining which kinds of value can be used in which situations; a data type.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The original object, or class of objects, scene, face, or conception, which becomes the subject of a copy; especially, the design on the face of a medal or a coin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A simple compound, used as a mode or pattern to which other compounds are conveniently regarded as being related, and from which they may be actually or theoretically derived.",
              synonyms: [],
              antonyms: [],
              example:
                "The fundamental types used to express the simplest and most essential chemical relations are hydrochloric acid, water, ammonia, and methane.",
            },
            {
              definition:
                'A part of the partition of the object domain of a logical theory (which due to the existence of such partition, would be called a typed theory). (Note: this corresponds to the notion of "data type" in computing theory.)',
              synonyms: [],
              antonyms: [],
              example:
                "Categorial grammar is like a combination of context-free grammar and types.",
            },
          ],
          synonyms: [
            "data type",
            "category",
            "class",
            "genre",
            "group",
            "kind",
            "nature",
            "sort",
            "stripe",
            "tribe",
            "sort",
            "sort",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To put text on paper using a typewriter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To enter text or commands into a computer using a keyboard.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To determine the blood type of.",
              synonyms: [],
              antonyms: [],
              example:
                "The doctor ordered the lab to type the patient for a blood transfusion.",
            },
            {
              definition:
                "To represent by a type, model, or symbol beforehand; to prefigure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To furnish an expression or copy of; to represent; to typify.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To categorize into types.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/type",
        "https://en.wiktionary.org/wiki/types",
      ],
    },
  ],
  drawn: [
    {
      word: "drawn",
      phonetic: "[dɹɔːn]",
      phonetics: [
        { text: "[dɹɔːn]", audio: "" },
        {
          text: "[dɹɔn]",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/drawn-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1239919",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
        { text: "[dɹɑn]", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To move or develop something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To exert or experience force.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(fluidic) To remove or separate or displace.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To change in size or shape.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To attract or be attracted.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(usually as draw on or draw upon) To rely on; utilize as a source.",
              synonyms: [],
              antonyms: [],
              example:
                "She had to draw upon her experience to solve the problem.",
            },
            { definition: "To disembowel.", synonyms: [], antonyms: [] },
            {
              definition:
                "To end a game in a draw (with neither side winning).",
              synonyms: [],
              antonyms: [],
              example:
                "We drew last time we played.  I drew him last time I played him.  I drew my last game against him.",
            },
            {
              definition: "To choose by means of a random selection process.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make a shot that lands gently in the house (the circular target) without knocking out other stones.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To play (a short-length ball directed at the leg stump) with an inclined bat so as to deflect the ball between the legs and the wicket.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To hit (the ball) with the toe of the club so that it is deflected toward the left.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To strike (the cue ball) below the center so as to give it a backward rotation which causes it to take a backward direction on striking another ball.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Appearing tired and unwell, as from stress; haggard.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of a game: undecided; having no definite winner and loser.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/draw",
        "https://en.wiktionary.org/wiki/drawn",
      ],
    },
  ],
  worth: [
    {
      word: "worth",
      phonetic: "/wɜːθ/",
      phonetics: [
        { text: "/wɜːθ/", audio: "" },
        {
          text: "/wɝθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/worth-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=471127",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Value.",
              synonyms: [],
              antonyms: [],
              example: "I’ll have a dollar's worth of candy, please.",
            },
            {
              definition: "Merit, excellence.",
              synonyms: [],
              antonyms: [],
              example:
                "Our new director is a man whose worth is well acknowledged.",
            },
            {
              definition: "Wealth, fortune, riches, property, possessions.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having a value of; proper to be exchanged for.",
              synonyms: [],
              antonyms: [],
              example: "A painting worth thousands.",
            },
            {
              definition: "Deserving of.",
              synonyms: [],
              antonyms: [],
              example: "His friendship is not worth having.",
            },
            {
              definition: "(except in Scots) Valuable, worth while.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Making a fair equivalent of, repaying or compensating.",
              synonyms: [],
              antonyms: [],
              example: "This job is hardly worth the effort.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/worth"],
    },
    {
      word: "worth",
      phonetic: "/wɜːθ/",
      phonetics: [
        { text: "/wɜːθ/", audio: "" },
        {
          text: "/wɝθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/worth-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=471127",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(except in set phrases) To be, become, betide.",
              synonyms: [],
              antonyms: [],
              example: "Well worth thee, me friend.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/worth"],
    },
  ],
  truck: [
    {
      word: "truck",
      phonetic: "/tɹʌk/",
      phonetics: [
        {
          text: "/tɹʌk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/truck-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75701742",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/tɹʌk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/truck-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1770085",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small wheel or roller, specifically the wheel of a gun carriage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The ball on top of a flagpole.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'On a wooden mast, a circular disc (or sometimes a rectangle) of wood near or at the top of the mast, usually with holes or sheaves to reeve signal halyards; also a temporary or emergency place for a lookout. "Main" refers to the mainmast, whereas a truck on another mast may be called (on the mizzenmast, for example) "mizzen-truck".',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: 'A semi-tractor ("semi") trailer; a lorry.',
              synonyms: [],
              antonyms: [],
              example:
                "Mexican open-bed trucks haul most of the fresh produce that comes into the United States from Mexico.",
            },
            {
              definition:
                "Any motor vehicle designed for carrying cargo, including delivery vans, pickups, and other motorized vehicles (including passenger autos) fitted with a bed designed to carry goods.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A garden cart, a two-wheeled wheelbarrow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small wagon or cart, of various designs, pushed or pulled by hand or pulled by an animal, as with those in hotels for moving luggage, or in libraries for transporting books.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A pantechnicon (removal van).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A flatbed railway car; a flatcar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A pivoting frame, one attached to the bottom of the bed of a railway car at each end, that rests on the axle and which swivels to allow the axle (at each end of which is a solid wheel) to turn with curves in the track; a bogie.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The part of a skateboard or roller skate that joins the wheels to the deck, consisting of a hanger, baseplate, kingpin, and bushings, and sometimes mounted with a riser in between.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A platform with wheels or casters.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Dirt or other messiness.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["hauler", "lorry", "rig", "tractor trailer"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To drive a truck: Generally a truck driver's slang.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To convey by truck.",
              synonyms: [],
              antonyms: [],
              example:
                "Last week, Cletus trucked 100 pounds of lumber up to Dubuque.",
            },
            {
              definition: "To travel or live contentedly.",
              synonyms: [],
              antonyms: [],
              example: "Keep on trucking!",
            },
            {
              definition: "To persist, to endure.",
              synonyms: [],
              antonyms: [],
              example: "Keep on trucking!",
            },
            {
              definition:
                "(film production) To move a camera parallel to the movement of the subject.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fight or otherwise physically engage with.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To run over or through a tackler in American football.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/truck"],
    },
    {
      word: "truck",
      phonetic: "/tɹʌk/",
      phonetics: [
        {
          text: "/tɹʌk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/truck-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75701742",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/tɹʌk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/truck-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1770085",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To fail; run out; run short; be unavailable; diminish; abate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To give in; give way; knuckle under; truckle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To deceive; cheat; defraud.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/truck"],
    },
    {
      word: "truck",
      phonetic: "/tɹʌk/",
      phonetics: [
        {
          text: "/tɹʌk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/truck-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75701742",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/tɹʌk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/truck-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1770085",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To tread (down); stamp on; trample (down).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/truck"],
    },
    {
      word: "truck",
      phonetic: "/tɹʌk/",
      phonetics: [
        {
          text: "/tɹʌk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/truck-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75701742",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/tɹʌk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/truck-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1770085",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(often in the plural) Small, humble items; things, often for sale or barter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The practice of paying workers in kind, or with tokens only exchangeable at a shop owned by the employer [forbidden in the 19th century by the Truck Acts].",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Garden produce, groceries (see truck garden).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(usually with negative) Social intercourse; dealings, relationships.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To trade, exchange; barter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To engage in commerce; to barter or deal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To have dealings or social relationships with; to engage with.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/truck"],
    },
  ],
  piano: [
    {
      word: "piano",
      phonetic: "/piˈænoʊ/",
      phonetics: [
        { text: "/piˈænoʊ/", audio: "" },
        {
          text: "/piˈænəʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/piano-1-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88671230",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/piˈænoʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A percussive keyboard musical instrument, usually ranging over seven octaves, with white and black colored keys, played by pressing these keys, causing hammers to strike strings",
              synonyms: ["pianoforte"],
              antonyms: [],
              example: 'He can play "Happy Birthday" on the piano.',
            },
          ],
          synonyms: ["pianoforte"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Soft, quiet", synonyms: [], antonyms: [] },
            {
              definition: "In extended use; quiet, subdued",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/piano"],
    },
    {
      word: "piano",
      phonetic: "/piˈɑnoʊ/",
      phonetics: [
        { text: "/piˈɑnoʊ/", audio: "" },
        { text: "/piˈɑːnəʊ/", audio: "" },
        { text: "/piˈɑnoʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition:
                "Softly, as a musical direction (abbreviated to p. in sheet music)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/piano"],
    },
  ],
  upper: [
    {
      word: "upper",
      phonetic: "/ˈʌpə/",
      phonetics: [
        { text: "/ˈʌpə/", audio: "" },
        {
          text: "/ˈʌpɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/upper-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1661721",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A stimulant, such as amphetamine, that increases energy and decreases appetite.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The upper portion of something",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Someone with higher social standing",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "That which is higher, contrasted with the lower.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A spiritual passageway through which consciousness can reach a higher dimension.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "At a higher level, rank or position.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Situated on higher ground, further inland, or more northerly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of strata or geological time periods) younger, more recent",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of or pertaining to a secondary school.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["lower", "under", "lower", "lower"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/upper"],
    },
  ],
  loved: [
    {
      word: "loved",
      phonetic: "/lʌvd/",
      phonetics: [
        {
          text: "/lʌvd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/loved-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1227737",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(usually transitive, sometimes intransitive, stative) To have a strong affection for (someone or something).",
              synonyms: [],
              antonyms: [],
              example: "I love my spouse.   I love you!",
            },
            {
              definition: "To need, thrive on.",
              synonyms: [],
              antonyms: [],
              example: "Mold loves moist, dark places.",
            },
            {
              definition:
                "To be strongly inclined towards something; an emphatic form of like.",
              synonyms: [],
              antonyms: [],
              example:
                "I love walking barefoot on wet grass;  I'd love to join the team;  I love what you've done with your hair",
            },
            {
              definition:
                "(usually transitive, sometimes intransitive) To care deeply about, to be dedicated to (someone or something).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To derive delight from a fact or situation.",
              synonyms: [],
              antonyms: [],
              example:
                "I love the fact that the coffee shop now offers fat-free chai latte.",
            },
            {
              definition: "To have sex with (perhaps from make love).",
              synonyms: [],
              antonyms: [],
              example: "I wish I could love her all night long.",
            },
          ],
          synonyms: [
            "adore",
            "cherish",
            "enjoy",
            "go to bed with",
            "sleep with",
          ],
          antonyms: ["despise", "hate"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To praise; commend.", synonyms: [], antonyms: [] },
            {
              definition: "To praise as of value; prize; set a price on.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Being the object of love.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/love",
        "https://en.wiktionary.org/wiki/loved",
      ],
    },
  ],
  usual: [
    {
      word: "usual",
      phonetic: "/ˈjuːʒʊəl/",
      phonetics: [
        {
          text: "/ˈjuːʒʊəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/usual-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1236006",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The typical state of something, or something that is typical.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A specific good or service (e.g. a drink) that someone typically orders.",
              synonyms: [],
              antonyms: [],
              example: "I'll just have the usual.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Most commonly occurring; typical.",
              synonyms: [],
              antonyms: [],
              example:
                "It is becoming more usual these days to rear children as bilingual.",
            },
          ],
          synonyms: [
            "common",
            "normal",
            "ordinary",
            "plain",
            "regular",
            "simple",
            "standard",
            "typical",
            "wonted",
          ],
          antonyms: ["abnormal", "atypical", "unusual"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/usual"],
    },
  ],
  faces: [
    {
      word: "faces",
      phonetic: "/ˈfeɪsɪz/",
      phonetics: [
        {
          text: "/ˈfeɪsɪz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/faces-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1446772",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The front part of the head of a human or other animal, featuring the eyes, nose and mouth, and the surrounding area.",
              synonyms: [],
              antonyms: [],
              example: "That girl has a pretty face.",
            },
            {
              definition: "One's facial expression.",
              synonyms: [],
              antonyms: [],
              example: "Why the sad face?",
            },
            {
              definition:
                "(in expressions such as 'make a face') A distorted facial expression; an expression of displeasure, insult, etc.",
              synonyms: [],
              antonyms: [],
              example: "Children! Stop making faces at each other!",
            },
            {
              definition: "The public image; outward appearance.",
              synonyms: [],
              antonyms: [],
              example:
                "He managed to show a bold face despite his embarrassment.",
            },
            {
              definition: "The frontal aspect of something.",
              synonyms: [],
              antonyms: [],
              example: "The face of the cliff loomed above them.",
            },
            {
              definition:
                "An aspect of the character or nature of someone or something.",
              synonyms: [],
              antonyms: [],
              example: "Poverty is the ugly face of capitalism.",
            },
            {
              definition: "Presence; sight; front.",
              synonyms: [],
              antonyms: [],
              example: "to fly in the face of danger",
            },
            {
              definition: "The directed force of something.",
              synonyms: [],
              antonyms: [],
              example: "They turned the boat into the face of the storm.",
            },
            {
              definition:
                "Good reputation; standing in the eyes of others; dignity; prestige. (See lose face, save face).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Shameless confidence; boldness; effrontery.",
              synonyms: [],
              antonyms: [],
              example:
                "You've got some face coming round here after what you've done.",
            },
            {
              definition: "Any surface, especially a front or outer one.",
              synonyms: [],
              antonyms: [],
              example:
                "Put a big sign on each face of the building that can be seen from the road.",
            },
            {
              definition:
                "Any of the flat bounding surfaces of a polyhedron. More generally, any of the bounding pieces of a polytope of any dimension.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The numbered dial of a clock or watch, the clock face.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The mouth.",
              synonyms: [],
              antonyms: [],
              example: "He's always stuffing his face with chips.",
            },
            {
              definition: "Makeup; one's complete facial cosmetic application.",
              synonyms: [],
              antonyms: [],
              example: "I'll be out in a sec. Just let me put on my face.",
            },
            {
              definition: "(metonymic) A person.",
              synonyms: [],
              antonyms: [],
              example: "It was just the usual faces at the pub tonight.",
            },
            {
              definition:
                "A familiar or well-known person; a member of a particular scene, such as music or fashion scene.",
              synonyms: [],
              antonyms: [],
              example:
                "He owned several local businesses and was a face around town.",
            },
            {
              definition:
                'A headlining wrestler with a persona embodying heroic or virtuous traits and who is regarded as a "good guy", especially one who is handsome and well-conditioned; a baby face.',
              synonyms: [],
              antonyms: [],
              example: "The fans cheered on the face as he made his comeback.",
            },
            {
              definition: "The front surface of a bat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The part of a golf club that hits the ball.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The side of the card that shows its value (as opposed to the back side, which looks the same on all cards of the deck).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The head of a lion, shown face-on and cut off immediately behind the ears.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The width of a pulley, or the length of a cog from end to end.",
              synonyms: [],
              antonyms: [],
              example: "a pulley or cog wheel of ten inches face",
            },
            { definition: "A typeface.", synonyms: [], antonyms: [] },
            {
              definition:
                "Mode of regard, whether favourable or unfavourable; favour or anger.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The amount expressed on a bill, note, bond, etc., without any interest or discount; face value.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "countenance",
            "expression",
            "facial expression",
            "look",
            "visage",
            "facet",
            "surface",
            "countenance",
            "phiz",
            "phizog",
            "visage",
            "image",
            "public image",
            "reputation",
            "cakehole",
            "gob",
            "mush",
            "piehole",
            "trap",
            "good guy",
            "hero",
            "foreside",
          ],
          antonyms: ["heel"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(of a person or animal) To position oneself or itself so as to have one's face closest to (something).",
              synonyms: [],
              antonyms: [],
              example: "Face the sun.",
            },
            {
              definition:
                "(of an object) To have its front closest to, or in the direction of (something else).",
              synonyms: [],
              antonyms: [],
              example: "Turn the chair so it faces the table.",
            },
            {
              definition:
                "To cause (something) to turn or present a face or front, as in a particular direction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be presented or confronted with; to have in prospect.",
              synonyms: [],
              antonyms: [],
              example: "We are facing an uncertain future.",
            },
            {
              definition:
                "To deal with (a difficult situation or person); to accept (facts, reality, etc.) even when undesirable.",
              synonyms: [],
              antonyms: [],
              example: "I'm going to have to face this sooner or later.",
            },
            {
              definition: "To have the front in a certain direction.",
              synonyms: [],
              antonyms: [],
              example: "The seats in the carriage faced backwards.",
            },
            {
              definition: "To have as an opponent.",
              synonyms: [],
              antonyms: [],
              example:
                "Puddletown United face Mudchester Rovers in the quarter-finals.",
            },
            {
              definition: "To be the batsman on strike.",
              synonyms: [],
              antonyms: [],
              example: "Willoughby comes in to bowl, and it's Hobson facing.",
            },
            {
              definition: "To confront impudently; to bully.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cover in front, for ornament, protection, etc.; to put a facing upon.",
              synonyms: [],
              antonyms: [],
              example: "a building faced with marble",
            },
            {
              definition:
                "To line near the edge, especially with a different material.",
              synonyms: [],
              antonyms: [],
              example: "to face the front of a coat, or the bottom of a dress",
            },
            {
              definition:
                "To cover with better, or better appearing, material than the mass consists of, for purpose of deception, as the surface of a box of tea, a barrel of sugar, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make the surface of (anything) flat or smooth; to dress the face of (a stone, a casting, etc.); especially, in turning, to shape or smooth the flat surface of, as distinguished from the cylindrical surface.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(retail) To arrange the products in (a store) so that they are tidy and attractive.",
              synonyms: [],
              antonyms: [],
              example:
                "In my first job, I learned how to operate a till and to face the store to high standards.",
            },
          ],
          synonyms: ["confront", "deal with"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/face",
        "https://en.wiktionary.org/wiki/faces",
      ],
    },
  ],
  drove: [
    {
      word: "drove",
      phonetic: "/dɹəʊv/",
      phonetics: [
        {
          text: "/dɹəʊv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/drove-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=31972299",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
        {
          text: "/dɹoʊv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/drove-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1669296",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A number of cattle driven to market or new pastures.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(usually in the plural) A large number of people on the move (literally or figuratively).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(collective) A group of hares.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A road or track along which cattle are habitually driven.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A narrow drain or channel used in the irrigation of land.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A broad chisel used to bring stone to a nearly smooth surface.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The grooved surface of stone finished by the drove chisel.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/drove"],
    },
    {
      word: "drove",
      phonetic: "/dɹəʊv/",
      phonetics: [
        {
          text: "/dɹəʊv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/drove-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=31972299",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
        {
          text: "/dɹoʊv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/drove-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1669296",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To provide an impetus for motion or other physical change, to move an object by means of the provision of force thereto.",
              synonyms: [],
              antonyms: [],
              example: "You drive nails into wood with a hammer.",
            },
            {
              definition:
                "To provide an impetus for a non-physical change, especially a change in one's state of mind.",
              synonyms: [],
              antonyms: [],
              example:
                "My wife's constant harping about the condition of the house threatens to drive me to distraction.",
            },
            {
              definition:
                "To displace either physically or non-physically, through the application of force.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause intrinsic motivation through the application or demonstration of force: to impel or urge onward thusly, to compel to move on, to coerce, intimidate or threaten.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(especially of animals) To impel or urge onward by force; to push forward; to compel to move on.",
              synonyms: [],
              antonyms: [],
              example:
                "to drive twenty thousand head of cattle from Texas to the Kansas railheads; to drive sheep out of a field",
            },
            {
              definition:
                "To direct a vehicle powered by a horse, ox or similar animal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause animals to flee out of.",
              synonyms: [],
              antonyms: [],
              example:
                "The beaters drove the brambles, causing a great rush of rabbits and other creatures.",
            },
            {
              definition: "To move (something) by hitting it with great force.",
              synonyms: [],
              antonyms: [],
              example: "You drive nails into wood with a hammer.",
            },
            {
              definition: "To cause (a mechanism) to operate.",
              synonyms: [],
              antonyms: [],
              example: "The pistons drive the crankshaft.",
            },
            {
              definition: "To operate (a wheeled motorized vehicle).",
              synonyms: [],
              antonyms: [],
              example: "drive a car",
            },
            {
              definition: "To motivate; to provide an incentive for.",
              synonyms: [],
              antonyms: [],
              example: "What drives a person to run a marathon?",
            },
            {
              definition: "To compel (to do something).",
              synonyms: [],
              antonyms: [],
              example: "Their debts finally drove them to sell the business.",
            },
            {
              definition: "To cause to become.",
              synonyms: [],
              antonyms: [],
              example:
                "This constant complaining is going to drive me to insanity.   You are driving me crazy!",
            },
            {
              definition: "To hit the ball with a drive.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To travel by operating a wheeled motorized vehicle.",
              synonyms: [],
              antonyms: [],
              example: "I drive to work every day.",
            },
            {
              definition:
                "To convey (a person, etc) in a wheeled motorized vehicle.",
              synonyms: [],
              antonyms: [],
              example: "My wife drove me to the airport.",
            },
            { definition: "To move forcefully.", synonyms: [], antonyms: [] },
            {
              definition:
                "To be moved or propelled forcefully (especially of a ship).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To urge, press, or bring to a point or state.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To carry or to keep in motion; to conduct; to prosecute.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To clear, by forcing away what is contained.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To dig horizontally; to cut a horizontal gallery or tunnel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To put together a drive (n.): to string together offensive plays and advance the ball down the field.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To distrain for rent.", synonyms: [], antonyms: [] },
            {
              definition:
                "To separate the lighter (feathers or down) from the heavier, by exposing them to a current of air.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be the dominant party in a sex act.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "move",
            "operate",
            "make",
            "render",
            "send",
            "compel",
            "force",
            "oblige",
            "push",
            "require",
            "take",
            "herd",
            "impel",
            "incentivise",
            "incentivize",
            "motivate",
            "push",
            "urge",
            "force",
            "push",
            "motorvate",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To herd cattle; particularly over a long distance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To finish (stone) with a drove chisel.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/drive",
        "https://en.wiktionary.org/wiki/drove",
      ],
    },
  ],
  cabin: [
    {
      word: "cabin",
      phonetic: "/ˈkæbɪn/",
      phonetics: [
        {
          text: "/ˈkæbɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cabin-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749731",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small dwelling characteristic of the frontier, especially when built from logs with simple tools and not constructed by professional builders, but by those who meant to live in it.",
              synonyms: [],
              antonyms: [],
              example: "Abraham Lincoln was born in a log cabin.",
            },
            {
              definition:
                "A chalet or lodge, especially one that can hold large groups of people.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A private room on a ship.",
              synonyms: [],
              antonyms: [],
              example:
                "the captain's cabin:  Passengers shall remain in their cabins.",
            },
            {
              definition:
                "The interior of a boat, enclosed to create a small room, particularly for sleeping.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The passenger area of an airplane.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(travel) The section of a passenger plane having the same class of service.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A signal box.", synonyms: [], antonyms: [] },
            {
              definition: "A small room; an enclosed place.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Indian English) A private office; particularly of a doctor, businessman, lawyer, or other professional.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["cell", "chamber", "hut", "pod", "shack", "shed"],
          antonyms: ["hall", "palace", "villa"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To place in a cabin or other small space.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) To limit the scope of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To live in, or as if in, a cabin; to lodge.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cabin"],
    },
  ],
  boats: [
    {
      word: "boats",
      phonetic: "/bəʊts/",
      phonetics: [
        { text: "/bəʊts/", audio: "" },
        {
          text: "/boʊts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/boats-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749724",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A craft used for transportation of goods, fishing, racing, recreational cruising, or military use on or in the water, propelled by oars or outboard motor or inboard motor or by wind.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A full house.", synonyms: [], antonyms: [] },
            {
              definition:
                "A vehicle, utensil, or dish somewhat resembling a boat in shape.",
              synonyms: [],
              antonyms: [],
              example: "a stone boat;  a gravy boat",
            },
            {
              definition:
                "One of two possible conformations of cyclohexane rings (the other being chair), shaped roughly like a boat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The refugee boats arriving in Australian waters, and by extension, refugees generally.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["craft", "ship", "vessel"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To travel by boat.", synonyms: [], antonyms: [] },
            {
              definition: "To transport in a boat.",
              synonyms: [],
              antonyms: [],
              example: "to boat goods",
            },
            {
              definition: "To place in a boat.",
              synonyms: [],
              antonyms: [],
              example: "to boat oars",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/boat",
        "https://en.wiktionary.org/wiki/boats",
      ],
    },
  ],
  towns: [
    {
      word: "towns",
      phonetic: "/taʊnz/",
      phonetics: [
        {
          text: "/taʊnz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/towns-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1684756",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A settlement; an area with residential districts, shops and amenities, and its own local government; especially one larger than a village and smaller than a city.",
              synonyms: [],
              antonyms: [],
              example:
                "This town is really dangerous because these youngsters have Beretta handguns.",
            },
            {
              definition:
                "Any more urbanized center than the place of reference.",
              synonyms: [],
              antonyms: [],
              example:
                "I'll be in Yonkers, then I'm driving into town to see the Knicks at the Garden tonight.",
            },
            {
              definition:
                "A rural settlement in which a market was held at least once a week.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The residents (as opposed to gown: the students, faculty, etc.) of a community which is the site of a university.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Used to refer to a town or similar entity under discussion.",
              synonyms: [],
              antonyms: [],
              example: "Call me when you get to town.",
            },
            {
              definition:
                "A major city, especially one where the speaker is located.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A municipal organization, such as a corporation, defined by the laws of the entity of which it is a part.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An enclosure which surrounded the mere homestead or dwelling of the lord of the manor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The whole of the land which constituted the domain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A collection of houses enclosed by fences or walls.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A farm or farmstead; also, a court or farmyard.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/town",
        "https://en.wiktionary.org/wiki/towns",
      ],
    },
  ],
  proud: [
    {
      word: "proud",
      phonetic: "/pɹaʊd/",
      phonetics: [
        {
          text: "/pɹaʊd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/proud-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1234442",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Feeling honoured (by something); feeling happy or satisfied about an event or fact; gratified.",
              synonyms: [],
              antonyms: [],
              example: "I am proud of Sivu’s schoolwork.",
            },
            {
              definition:
                "Possessed of a due sense of what one deserves or is worth.",
              synonyms: [],
              antonyms: [],
              example: "I was too proud to apologise.",
            },
            {
              definition:
                "Having too high an opinion of oneself; arrogant, supercilious.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Generating a sense of pride; being a cause for pride.",
              synonyms: [],
              antonyms: [],
              example:
                "It was a proud day when we finally won the championship.",
            },
            {
              definition:
                "(Of things) standing upwards as in the manner of a proud person; stately or majestic.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Standing out or raised; swollen.",
              synonyms: [],
              antonyms: [],
              example:
                "After it had healed, the scar tissue stood proud of his flesh.",
            },
            {
              definition: "Brave, valiant; gallant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Excited by sexual desire; specifically of a female animal: in heat.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["ashamed"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/proud"],
    },
  ],
  court: [
    {
      word: "court",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/court-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75270135",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/kɔːt/", audio: "" },
        {
          text: "/kɔɹt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/court-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1231957",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An enclosed space; a courtyard; an uncovered area shut in by the walls of a building, or by different buildings; also, a space opening from a street and nearly surrounded by houses; a blind alley.",
              synonyms: [],
              antonyms: [],
              example: "The girls were playing in the court.",
            },
            {
              definition: "(social) Royal society.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Attention directed to a person in power; behaviour designed to gain favor; politeness of manner; civility towards someone",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The administration of law.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A place arranged for playing the games of tennis, basketball, squash, badminton, volleyball and some other games",
              synonyms: [],
              antonyms: [],
              example:
                "The local sports club has six tennis courts and two squash courts.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To seek to achieve or win.",
              synonyms: [],
              antonyms: [],
              example:
                "He was courting big new accounts that previous salesman had not attempted.",
            },
            {
              definition: "To risk (a consequence, usually negative).",
              synonyms: [],
              antonyms: [],
              example: "He courted controversy with his frank speeches.",
            },
            {
              definition: "To try to win a commitment to marry from.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To engage in behavior leading to mating.",
              synonyms: [],
              antonyms: [],
              example: "The bird was courting by making an elaborate dance.",
            },
            {
              definition: "To attempt to attract.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To attempt to gain alliance with.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To engage in activities intended to win someone's affections.",
              synonyms: ["romance", "solicit"],
              antonyms: [],
              example: "She's had a few beaus come courting.",
            },
            {
              definition: "To engage in courtship behavior.",
              synonyms: [],
              antonyms: [],
              example: "In this season, you can see many animals courting.",
            },
            {
              definition: "To invite by attractions; to allure; to attract.",
              synonyms: ["charm", "entrance"],
              antonyms: [],
            },
          ],
          synonyms: ["charm", "entrance", "romance", "solicit"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/court"],
    },
  ],
  model: [
    {
      word: "model",
      phonetic: "/ˈmɒdl̩/",
      phonetics: [
        { text: "/ˈmɒdl̩/", audio: "" },
        {
          text: "/ˈmɑdl̩/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/model-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100796",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person who serves as a subject for artwork or fashion, usually in the medium of photography but also for painting or drawing.",
              synonyms: [],
              antonyms: [],
              example:
                "The beautiful model had her face on the cover of almost every fashion magazine imaginable.",
            },
            {
              definition:
                "A person, usually an attractive female, hired to show items or goods to the public, such as items given away as prizes on a TV game show.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A representation of a physical object, usually in miniature.",
              synonyms: [],
              antonyms: [],
              example:
                "The boy played with a model of a World War II fighter plane.",
            },
            {
              definition:
                "A simplified representation used to explain the workings of a real world system or event.",
              synonyms: [],
              antonyms: [],
              example:
                "The computer weather model did not correctly predict the path of the hurricane.",
            },
            {
              definition: "A style, type, or design.",
              synonyms: [],
              antonyms: [],
              example:
                "He decided to buy the turbo engine model of the sports car.",
            },
            {
              definition: "The structural design of a complex system.",
              synonyms: [],
              antonyms: [],
              example: "The team developed a sound business model.",
            },
            {
              definition:
                "A successful example to be copied, with or without modifications.",
              synonyms: [],
              antonyms: [],
              example:
                "British parliamentary democracy was seen as a model for other countries to follow.",
            },
            {
              definition:
                "An interpretation function which assigns a truth value to each atomic proposition.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An interpretation which makes a set of sentences true, in which case that interpretation is called a model of that set.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An animal that is used to study a human disease or pathology.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any copy, or resemblance, more or less exact.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In software applications using the model-view-controller design pattern, the part or parts of the application that manage the data.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To display for others to see, especially in regard to wearing clothing while performing the role of a fashion model",
              synonyms: [],
              antonyms: [],
              example: "She modelled the shoes for her friends to see.",
            },
            {
              definition:
                "To use as an object in the creation of a forecast or model",
              synonyms: [],
              antonyms: [],
              example:
                "They modelled the data with a computer to analyze the experiment’s results.",
            },
            {
              definition: "To make a miniature model of",
              synonyms: [],
              antonyms: [],
              example:
                "He takes great pride in his skill at modeling airplanes.",
            },
            {
              definition: "To create from a substance such as clay",
              synonyms: [],
              antonyms: [],
              example:
                "The sculptor modelled the clay into the form of a dolphin.",
            },
            {
              definition: "To make a model or models",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be a model of any kind",
              synonyms: [],
              antonyms: [],
              example:
                "The actress used to model before being discovered by Hollywood.",
            },
          ],
          synonyms: ["modelise", "modelize"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Worthy of being a model; exemplary.",
              synonyms: ["ideal"],
              antonyms: [],
            },
          ],
          synonyms: ["ideal"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/model"],
    },
  ],
  prime: [
    {
      word: "prime",
      phonetic: "/pɹaɪ̯m/",
      phonetics: [
        {
          text: "/pɹaɪ̯m/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/prime-1-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88676200",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The first hour of daylight; the first canonical hour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The religious service appointed to this hour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The early morning generally.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The earliest stage of something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The most active, thriving, or successful stage or period.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The chief or best individual or part.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The first note or tone of a musical scale.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The first defensive position, with the sword hand held at head height, and the tip of the sword at head height.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A prime element of a mathematical structure, particularly a prime number.",
              synonyms: [],
              antonyms: [],
              example: "3 is a prime.",
            },
            {
              definition:
                "A four-card hand containing one card of each suit in the game of primero; the opposite of a flush in poker.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Six consecutive blocks, which prevent the opponent's pieces from passing.",
              synonyms: [],
              antonyms: [],
              example: "I'm threatening to build a prime here.",
            },
            {
              definition:
                "The symbol ′ used to indicate feet, minutes, derivation and other measures and mathematical operations.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any number expressing the combining weight or equivalent of any particular element; so called because these numbers were respectively reduced to their lowest relative terms on the fixed standard of hydrogen as 1.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An inch, as composed of twelve seconds in the duodecimal system.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The priming in a flintlock.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Contraction of prime lens, a film lens",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "prime number",
            "choice",
            "prize",
            "quality",
            "select",
            "bloom",
            "blossom",
            "efflorescence",
            "flower",
            "flush",
            "heyday",
            "peak",
          ],
          antonyms: ["composite"],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "First in importance, degree, or rank.",
              synonyms: [
                "greatest",
                "main",
                "most important",
                "primary",
                "principal",
                "top",
              ],
              antonyms: [],
              example: "Our prime concern here is to keep the community safe.",
            },
            {
              definition: "First in time, order, or sequence.",
              synonyms: ["earliest", "first", "original"],
              antonyms: [],
              example:
                "Both the English and French governments established prime meridians in their capitals.",
            },
            {
              definition: "First in excellence, quality, or value.",
              synonyms: ["excellent", "top quality"],
              antonyms: [],
              example: "This is a prime location for a bookstore.",
            },
            {
              definition:
                "(lay) Having exactly two integral factors: itself and unity (1 in the case of integers).",
              synonyms: [],
              antonyms: [],
              example: "Thirteen is a prime number.",
            },
            {
              definition:
                "Such that if it divides a product, it divides one of the multiplicands.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Having its complement closed under multiplication: said only of ideals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Marked or distinguished by the prime symbol.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Early; blooming; being in the first stage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Lecherous, lewd, lustful.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "indivisible",
            "earliest",
            "first",
            "original",
            "excellent",
            "top quality",
            "greatest",
            "main",
            "most important",
            "primary",
            "principal",
            "top",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/prime"],
    },
    {
      word: "prime",
      phonetic: "/pɹaɪ̯m/",
      phonetics: [{ text: "/pɹaɪ̯m/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To prepare a mechanism for its main work.",
              synonyms: [],
              antonyms: [],
              example:
                "You'll have to press this button twice to prime the fuel pump.",
            },
            {
              definition: "To apply a coat of primer paint to.",
              synonyms: [],
              antonyms: [],
              example:
                "I need to prime these handrails before we can apply the finish coat.",
            },
            { definition: "To be renewed.", synonyms: [], antonyms: [] },
            {
              definition: "To serve as priming for the charge of a gun.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a steam boiler) To work so that foaming occurs from too violent ebullition, which causes water to become mixed with, and be carried along with, the steam that is formed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To apply priming to (a musket or cannon); to apply a primer to (a metallic cartridge).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To prepare; to make ready; to instruct beforehand; to coach.",
              synonyms: [],
              antonyms: [],
              example: "The boys are primed for mischief.",
            },
            {
              definition: "To trim or prune.",
              synonyms: [],
              antonyms: [],
              example: "to prime trees",
            },
            {
              definition: "To mark with a prime mark.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["ground", "undercoat"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/prime"],
    },
    {
      word: "prime",
      phonetic: "/pɹiːm/",
      phonetics: [{ text: "/pɹiːm/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An intermediate sprint within a race, usually offering a prize and/or points.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/prime"],
    },
  ],
  fifty: [
    {
      word: "fifty",
      phonetic: "/ˈfɪfti/",
      phonetics: [
        {
          text: "/ˈfɪfti/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fifty-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899705",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈfɪfti/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fifty-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=867523",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A banknote or coin with a denomination of 50.",
              synonyms: [],
              antonyms: [],
              example: "Do you want small bills or are fifties OK?",
            },
            {
              definition:
                "A batsman's score of at least 50 runs and less than 100 runs.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "numeral",
          definitions: [
            {
              definition:
                "The cardinal number occurring after forty-nine and before fifty-one.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["50", "Arabic numerals", "L", "Roman numerals"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fifty"],
    },
  ],
  plans: [
    {
      word: "plans",
      phonetic: "/plænz/",
      phonetics: [
        {
          text: "/plænz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/plans-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1235972",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A drawing showing technical details of a building, machine, etc., with unwanted details omitted, and often using symbols rather than detailed drawing to represent doors, valves, etc.",
              synonyms: [],
              antonyms: [],
              example:
                "The plans for many important buildings were once publicly available.",
            },
            {
              definition:
                "A set of intended actions, usually mutually related, through which one expects to achieve a goal.",
              synonyms: [],
              antonyms: [],
              example:
                "He didn't really have a plan; he had a goal and a habit of control.",
            },
            {
              definition:
                "A two-dimensional drawing of a building as seen from above with obscuring or irrelevant details such as roof removed, or of a floor of a building, revealing the internal layout; as distinct from the elevation.",
              synonyms: ["floor plan"],
              antonyms: [],
              example:
                "Seen in plan, the building had numerous passageways not apparent to visitors.",
            },
            {
              definition: "A method; a way of procedure; a custom.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A subscription to a service.",
              synonyms: [],
              antonyms: [],
              example: "a phone plan",
            },
          ],
          synonyms: ["floor plan"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To design (a building, machine, etc.).",
              synonyms: [],
              antonyms: [],
              example: "The architect planned the building for the client.",
            },
            {
              definition: "To create a plan for.",
              synonyms: [],
              antonyms: [],
              example:
                "They jointly planned the project in phases, with good detail for the first month.",
            },
            {
              definition: "To intend.",
              synonyms: [],
              antonyms: [],
              example: "He planned to go, but work intervened.",
            },
            {
              definition: "To make a plan.",
              synonyms: [],
              antonyms: [],
              example:
                "They planned for the worst, bringing lots of emergency supplies.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/plan",
        "https://en.wiktionary.org/wiki/plans",
      ],
    },
  ],
  yards: [
    {
      word: "yards",
      phonetic: "/jɑːdz/",
      phonetics: [
        { text: "/jɑːdz/", audio: "" },
        {
          text: "/jɑɹdz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/yards-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1676911",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small, usually uncultivated area adjoining or (now especially) within the precincts of a house or other building.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The property surrounding one's house, typically dominated by one's lawn.",
              synonyms: ["garden"],
              antonyms: [],
            },
            {
              definition:
                "An enclosed area designated for a specific purpose, e.g. on farms, railways etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A place where moose or deer herd together in winter for pasture, protection, etc.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "One’s house or home.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["garden"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To confine to a yard.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A unit of length equal to 3 feet in the US customary and British imperial systems of measurement, equal to precisely 0.9144 m since 1959 (US) or 1963 (UK).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Units of similar composition or length in other systems.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any spar carried aloft.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A branch, twig, or shoot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A staff, rod, or stick.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A penis.", synonyms: [], antonyms: [] },
            { definition: "100 dollars.", synonyms: [], antonyms: [] },
            {
              definition:
                "The yardland, an obsolete English unit of land roughly understood as 30 acres.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The rod, a surveying unit of (once) 15 or (now) 16 1/2 feet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The rood, area bound by a square rod, 1/4 acre.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["hundred", "ell", "virgate", "rood", "rod"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "109, A short scale billion; a long scale thousand millions or milliard.",
              synonyms: [],
              antonyms: [],
              example: "I need to hedge a yard of yen.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The totality of the sailing rig.",
              synonyms: [],
              antonyms: [],
              example: "Her yards were bare and cockabill.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/yard",
        "https://en.wiktionary.org/wiki/yards",
      ],
    },
  ],
  prove: [
    {
      word: "prove",
      phonetic: "/pɹuːv/",
      phonetics: [
        {
          text: "/pɹuːv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/prove-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1234224",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To proofread.", synonyms: [], antonyms: [] },
            {
              definition: "To make resistant, especially to water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To allow yeast-containing dough to rise.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To test the activeness of yeast.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The process of dough proofing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To demonstrate that something is true or viable; to give proof for.",
              synonyms: [],
              antonyms: [],
              example:
                "I will prove that my method is more effective than yours.",
            },
            {
              definition: "To turn out; to manifest.",
              synonyms: [],
              antonyms: [],
              example: "It proved to be a cold day.",
            },
            {
              definition: "To turn out to be.",
              synonyms: [],
              antonyms: [],
              example:
                "Have an exit strategy should your calculations prove incorrect.",
            },
            {
              definition: "To put to the test, to make trial of.",
              synonyms: [],
              antonyms: [],
              example: "The exception proves the rule.",
            },
            {
              definition:
                "To ascertain or establish the genuineness or validity of; to verify.",
              synonyms: [],
              antonyms: [],
              example: "to prove a will",
            },
            { definition: "To experience.", synonyms: [], antonyms: [] },
            {
              definition: "To take a trial impression of; to take a proof of.",
              synonyms: [],
              antonyms: [],
              example: "to prove a page",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/proof",
        "https://en.wiktionary.org/wiki/prove",
      ],
    },
    {
      word: "prove",
      phonetic: "/pɹəʊv/",
      phonetics: [{ text: "/pɹəʊv/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To proofread.", synonyms: [], antonyms: [] },
            {
              definition: "To make resistant, especially to water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To allow yeast-containing dough to rise.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To test the activeness of yeast.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To demonstrate that something is true or viable; to give proof for.",
              synonyms: [],
              antonyms: [],
              example:
                "I will prove that my method is more effective than yours.",
            },
            {
              definition: "To turn out; to manifest.",
              synonyms: [],
              antonyms: [],
              example: "It proved to be a cold day.",
            },
            {
              definition: "To turn out to be.",
              synonyms: [],
              antonyms: [],
              example:
                "Have an exit strategy should your calculations prove incorrect.",
            },
            {
              definition: "To put to the test, to make trial of.",
              synonyms: [],
              antonyms: [],
              example: "The exception proves the rule.",
            },
            {
              definition:
                "To ascertain or establish the genuineness or validity of; to verify.",
              synonyms: [],
              antonyms: [],
              example: "to prove a will",
            },
            { definition: "To experience.", synonyms: [], antonyms: [] },
            {
              definition: "To take a trial impression of; to take a proof of.",
              synonyms: [],
              antonyms: [],
              example: "to prove a page",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/proof",
        "https://en.wiktionary.org/wiki/proove",
        "https://en.wiktionary.org/wiki/prove",
      ],
    },
  ],
  tools: [
    {
      word: "tools",
      phonetic: "/tuːlz/",
      phonetics: [{ text: "/tuːlz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A mechanical device intended to make a task easier.",
              synonyms: [],
              antonyms: [],
              example:
                "Hand me that tool, would you?   I don't have the right tools to start fiddling around with the engine.",
            },
            {
              definition:
                "Equipment used in a profession, e.g., tools of the trade.",
              synonyms: [],
              antonyms: [],
              example: "These are the tools of the trade.",
            },
            {
              definition:
                "Something to perform an operation; an instrument; a means.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of software used to develop software or hardware, or to perform low-level operations.",
              synonyms: [],
              antonyms: [],
              example:
                "The software engineer had been developing lots of EDA tools.   a tool for recovering deleted files from a disk",
            },
            {
              definition:
                "A person or group which is used or controlled, usually unwittingly, by another person or group.",
              synonyms: [],
              antonyms: [],
              example: "He was a tool, no more than a pawn to her.",
            },
            { definition: "Penis.", synonyms: [], antonyms: [] },
            {
              definition: "(by extension) An obnoxious or uptight person.",
              synonyms: [],
              antonyms: [],
              example:
                "He won't sell us tickets because it's 3:01, and they went off sale at 3. That guy's such a tool.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To work on or shape with tools, e.g., hand-tooled leather.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To equip with tools.", synonyms: [], antonyms: [] },
            { definition: "To work very hard.", synonyms: [], antonyms: [] },
            {
              definition:
                "To put down another person (possibly in a subtle, hidden way), and in that way to use him or her to meet a goal.",
              synonyms: [],
              antonyms: [],
              example: "Dude, he's not your friend. He's just tooling you.",
            },
            {
              definition:
                "To intentionally attack the ball so that it deflects off a blocker out of bounds.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To drive (a coach or other vehicle).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To carry or convey in a coach or other vehicle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To travel in a vehicle; to ride or drive.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["use"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tool",
        "https://en.wiktionary.org/wiki/tools",
      ],
    },
  ],
  price: [
    {
      word: "price",
      phonetic: "/pɹaɪs/",
      phonetics: [
        {
          text: "/pɹaɪs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/price-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9023318",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/pɹaɪs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/price-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1118933",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The cost required to gain possession of something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The cost of an action or deed.",
              synonyms: [],
              antonyms: [],
              example: "I paid a high price for my folly.",
            },
            {
              definition: "Value; estimation; excellence; worth.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To determine the monetary value of (an item); to put a price on.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To pay the price of; to make reparation for.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To set a price on; to value; to prize.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To ask the price of.",
              synonyms: [],
              antonyms: [],
              example: "to price eggs",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/price"],
    },
  ],
  sheet: [
    {
      word: "sheet",
      phonetic: "/ʃiːt/",
      phonetics: [
        {
          text: "/ʃiːt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sheet-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9027385",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ʃit/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sheet-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769519",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A thin bed cloth used as a covering for a mattress or as a layer over the sleeper.",
              synonyms: [],
              antonyms: [],
              example: "Use the sheets in the hall closet to make the bed.",
            },
            {
              definition:
                "A piece of paper, usually rectangular, that has been prepared for writing, artwork, drafting, wrapping, manufacture of packaging (boxes, envelopes, etc.), and for other uses. The word does not include scraps and irregular small pieces destined to be recycled, used for stuffing or cushioning or paper mache, etc.",
              synonyms: [],
              antonyms: [],
              example:
                "A sheet of paper measuring eight and one-half inches wide by eleven inches high is a popular item in commerce.",
            },
            {
              definition:
                "A flat metal pan, often without raised edge, used for baking.",
              synonyms: [],
              antonyms: [],
              example:
                "Place the rolls on the cookie sheet, edges touching, and bake for 10-11 minutes.",
            },
            {
              definition: "A thin, flat layer of solid material.",
              synonyms: [],
              antonyms: [],
              example:
                "A sheet of that new silicon stuff is as good as a sheet of tinfoil to keep food from sticking in the baking pan.",
            },
            {
              definition: "A broad, flat expanse of a material on a surface.",
              synonyms: [],
              antonyms: [],
              example:
                "Mud froze on the road in a solid sheet, then more rain froze into a sheet of ice on top of the mud!",
            },
            {
              definition: "A line (rope) used to adjust the trim of a sail.",
              synonyms: [],
              antonyms: [],
              example:
                'To be "three sheets to the wind" is to say that a four-cornered sail is tethered only by one sheet and thus the sail is useless.',
            },
            { definition: "A sail.", synonyms: [], antonyms: [] },
            {
              definition:
                "The area of ice on which the game of curling is played.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A layer of veneer.", synonyms: [], antonyms: [] },
            {
              definition:
                "Precipitation of such quantity and force as to resemble a thin, virtually solid wall.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An extensive bed of an eruptive rock intruded between, or overlying, other strata.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The space in the forward or after part of a boat where there are no rowers.",
              synonyms: [],
              antonyms: [],
              example: "fore sheets; stern sheets",
            },
            {
              definition: "A distinct level or stage within a game.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["blanket", "coat", "coating", "layer", "rope", "page"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cover or wrap with cloth, or paper, or other similar material.",
              synonyms: [],
              antonyms: [],
              example: "Remember to sheet the floor before you start painting.",
            },
            { definition: "To form into sheets.", synonyms: [], antonyms: [] },
            {
              definition: "Of rain, or other precipitation, to pour heavily.",
              synonyms: [],
              antonyms: [],
              example:
                "We couldn't go out because the rain was sheeting down all day long.",
            },
            {
              definition: "To trim a sail using a sheet.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sheet"],
    },
  ],
  smell: [
    {
      word: "smell",
      phonetic: "/smɛl/",
      phonetics: [
        { text: "/smɛl/", audio: "" },
        {
          text: "/smɛl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/smell-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1157742",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sensation, pleasant or unpleasant, detected by inhaling air (or, the case of water-breathing animals, water) carrying airborne molecules of a substance.",
              synonyms: [],
              antonyms: [],
              example: "I love the smell of fresh bread.",
            },
            {
              definition: "The sense that detects odours.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A conclusion or intuition that a situation is wrong, more complex than it seems, or otherwise inappropriate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["olfaction", "sense of smell"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To sense a smell or smells.",
              synonyms: ["detect", "sense"],
              antonyms: [],
              example: "I can smell fresh bread.",
            },
            {
              definition:
                "Followed by like or of if descriptive: to have a particular smell, whether good or bad.",
              synonyms: ["pong", "reek", "stink", "whiff"],
              antonyms: [],
              example: "Her feet smell of cheese.",
            },
            {
              definition: "(without a modifier) To smell bad; to stink.",
              synonyms: [],
              antonyms: [],
              example: "Ew, this stuff smells.",
            },
            {
              definition:
                "To have a particular tincture or smack of any quality; to savour.",
              synonyms: [],
              antonyms: [],
              example: "A report smells of calumny.",
            },
            { definition: "To exercise sagacity.", synonyms: [], antonyms: [] },
            {
              definition: "To detect or perceive; often with out.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To give heed to.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["detect", "sense", "pong", "reek", "stink", "whiff"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/smell"],
    },
  ],
  boxes: [
    {
      word: "boxes",
      phonetic: "/ˈbɒksɪz/",
      phonetics: [
        { text: "/ˈbɒksɪz/", audio: "" },
        {
          text: "/ˈbɑksɪz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/boxes-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2707459",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Senses relating to a three-dimensional object or space.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Senses relating to a two-dimensional object or space",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "computer",
            "machine",
            "TV",
            "telly",
            "tube",
            "boxful",
            "loge",
            "case",
            "package",
            "box seat",
            "text box",
            "cup",
            "shelter",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To place inside a box; to pack in one or more boxes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Usually followed by in: to surround and enclose in a way that restricts movement; to corner, to hem in.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To mix two containers of paint of similar colour to ensure that the color is identical.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make an incision or hole in (a tree) for the purpose of procuring the sap.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To enclose with boarding, lathing, etc., so as to conceal (for example, pipes) or to bring to a required form.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To furnish (for example, the axle of a wheel) with a box.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To enclose (images, text, etc.) in a box.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To place a value of a primitive type into a corresponding object.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "box up",
            "case",
            "embox",
            "encase",
            "pack",
            "pack up",
            "package",
          ],
          antonyms: ["unbox", "uncase", "unpack"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of various evergreen shrubs or trees of the genus Buxus, especially the common box, European box, or boxwood (Buxus sempervirens) which is often used for making hedges and topiary.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The wood from a box tree: boxwood.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A musical instrument, especially one made from boxwood.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An evergreen tree of the genus Lophostemon (for example, the box scrub, Brisbane box, brush box, pink box, or Queensland box, Lophostemon confertus).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Various species of Eucalyptus trees are popularly called various kinds of boxes, on the basis of the nature of their wood, bark, or appearance for example, the drooping (Eucalyptus bicolor), shiny-leaved (Eucalyptus tereticornis), black, or ironbark box trees.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A blow with the fist.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["blow", "cuff", "punch"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To strike with the fists; to punch.",
              synonyms: [],
              antonyms: [],
              example: "Leave this place before I box you!",
            },
            {
              definition: "To fight against (a person) in a boxing match.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To participate in boxing; to be a boxer.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A Mediterranean food fish of the genus Boops, which is a variety of sea bream; a bogue or oxeye.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/box",
        "https://en.wiktionary.org/wiki/boxes",
      ],
    },
  ],
  raise: [
    {
      word: "raise",
      phonetic: "/ɹeɪz/",
      phonetics: [
        {
          text: "/ɹeɪz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/raise-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=830093",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An increase in wages or salary; a rise (UK).",
              synonyms: [],
              antonyms: [],
              example: "The boss gave me a raise.",
            },
            {
              definition:
                "A shoulder exercise in which the arms are elevated against resistance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A shot in which the delivered stone bumps another stone forward.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A bet that increases the previous bet.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(physical) To cause to rise; to lift or elevate.",
              synonyms: [],
              antonyms: [],
              example:
                "to raise your hand if you want to say something; to raise your walking stick to defend yourself",
            },
            {
              definition: "To create, increase or develop.",
              synonyms: [],
              antonyms: [],
              example: "We need to raise the motivation level in the company.",
            },
            {
              definition:
                "To establish contact with (e.g., by telephone or radio).",
              synonyms: [],
              antonyms: [],
              example:
                "Despite all the call congestion, she was eventually able to raise the police.",
            },
            {
              definition:
                "To respond to a bet by increasing the amount required to continue in the hand.",
              synonyms: [],
              antonyms: [],
              example:
                "John bet, and Julie raised, requiring John to put in more money.",
            },
            {
              definition: "To exponentiate, to involute.",
              synonyms: [],
              antonyms: [],
              example: "Two raised to the fifth power equals 32.",
            },
            {
              definition:
                "(of a verb) To extract (a subject or other verb argument) out of an inner clause.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a vowel) To produce a vowel with the tongue positioned closer to the roof of the mouth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To increase the nominal value of (a cheque, money order, etc.) by fraudulently changing the writing or printing in which the sum payable is specified.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To throw (an exception).",
              synonyms: [],
              antonyms: [],
              example: "A division by zero will raise an exception.",
            },
          ],
          synonyms: ["lift"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/raise"],
    },
    {
      word: "raise",
      phonetic: "/ɹeɪz/",
      phonetics: [
        {
          text: "/ɹeɪz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/raise-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=830093",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A cairn or pile of stones.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/raise"],
    },
  ],
  match: [
    {
      word: "match",
      phonetic: "/mæt͡ʃ/",
      phonetics: [
        {
          text: "/mæt͡ʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/match-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9023225",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/mæt͡ʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/match-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=825478",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A competitive sporting event such as a boxing meet, a baseball game, or a cricket match.",
              synonyms: [],
              antonyms: [],
              example:
                "My local team are playing in a match against their arch-rivals today.",
            },
            {
              definition:
                "Any contest or trial of strength or skill, or to determine superiority.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Someone with a measure of an attribute equaling or exceeding the object of comparison.",
              synonyms: [],
              antonyms: [],
              example: "He knew he had met his match.",
            },
            { definition: "A marriage.", synonyms: [], antonyms: [] },
            {
              definition:
                "A candidate for matrimony; one to be gained in marriage.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Suitability.", synonyms: [], antonyms: [] },
            {
              definition: "Equivalence; a state of correspondence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Equality of conditions in contest or competition.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A pair of items or entities with mutually suitable characteristics.",
              synonyms: [],
              antonyms: [],
              example: "The carpet and curtains are a match.",
            },
            {
              definition: "An agreement or compact.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A perforated board, block of plaster, hardened sand, etc., in which a pattern is partly embedded when a mould is made, for giving shape to the surfaces of separation between the parts of the mould.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To agree; to be equal; to correspond.",
              synonyms: [],
              antonyms: [],
              example:
                "Their interests didn't match, so it took a long time to agree what to do together.",
            },
            {
              definition: "To agree with; to be equal to; to correspond to.",
              synonyms: [],
              antonyms: [],
              example: "His interests didn't match her interests.",
            },
            {
              definition: "To make a successful match or pairing.",
              synonyms: [],
              antonyms: [],
              example:
                "They found out about his color-blindness when he couldn't match socks properly.",
            },
            {
              definition: "To equal or exceed in achievement.",
              synonyms: [],
              antonyms: [],
              example:
                "She matched him at every turn: anything he could do, she could do as well or better.",
            },
            {
              definition: "To unite in marriage, to mate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To fit together, or make suitable for fitting together; specifically, to furnish with a tongue and groove at the edges.",
              synonyms: [],
              antonyms: [],
              example: "to match boards",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/match"],
    },
    {
      word: "match",
      phonetic: "/mæt͡ʃ/",
      phonetics: [
        {
          text: "/mæt͡ʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/match-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9023225",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/mæt͡ʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/match-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=825478",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A device made of wood or paper, at the tip coated with chemicals that ignite with the friction of being dragged (struck) against a rough dry surface.",
              synonyms: ["spunk (obsolete)"],
              antonyms: [],
              example: "He struck a match and lit his cigarette.",
            },
          ],
          synonyms: ["spunk (obsolete)"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/match"],
    },
  ],
  truth: [
    {
      word: "truth",
      phonetic: "/tɹuːθ/",
      phonetics: [
        {
          text: "/tɹuːθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/truth-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1224226",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "True facts, genuine depiction or statements of reality.",
              synonyms: [],
              antonyms: [],
              example:
                "The truth is that our leaders knew a lot more than they were letting on.",
            },
            {
              definition:
                "Conformity to fact or reality; correctness, accuracy.",
              synonyms: [],
              antonyms: [],
              example:
                "There was some truth in his statement that he had no other choice.",
            },
            {
              definition:
                "The state or quality of being true to someone or something.",
              synonyms: [],
              antonyms: [],
              example: "Truth to one's own feelings is all-important in life.",
            },
            {
              definition: "Faithfulness, fidelity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A pledge of loyalty or faith.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Conformity to rule; exactness; close correspondence with an example, mood, model, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That which is real, in a deeper sense; spiritual or ‘genuine’ reality.",
              synonyms: [],
              antonyms: [],
              example: "Alcoholism and redemption led me finally to truth.",
            },
            {
              definition:
                "Something acknowledged to be true; a true statement or axiom.",
              synonyms: [],
              antonyms: [],
              example:
                "Hunger and jealousy are just eternal truths of human existence.",
            },
            {
              definition: "Topness; the property of a truth quark.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [
            "drivel",
            "falsehood",
            "falsity",
            "half-truth",
            "lie",
            "nonsense",
            "untruth",
          ],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To assert as true; to declare; to speak truthfully.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make exact; to correct for inaccuracy.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To tell the truth.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/truth"],
    },
  ],
  roads: [
    {
      word: "roads",
      phonetic: "/ɹəʊdz/",
      phonetics: [
        { text: "/ɹəʊdz/", audio: "" },
        {
          text: "/ɹoʊdz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/roads-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2079743",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A way used for travelling between places, originally one wide enough to allow foot passengers and horses to travel, now (US) usually one surfaced with asphalt or concrete and designed to accommodate many vehicles travelling in both directions. In the UK both senses are heard: a country road is the same as a country lane.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A road; or particularly a car, as a means of transportation.",
              synonyms: [],
              antonyms: [],
              example: "We travelled to the seaside by road.",
            },
            {
              definition: "A path chosen in life or career.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An underground tunnel in a mine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A railway or a single railway track.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The act of riding on horseback.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A hostile ride against a particular area; a raid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(often in the plural) A partly sheltered area of water near a shore in which vessels may ride at anchor; a roadstead.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A journey, or stage of a journey.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A way or route.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(occasionally in the singular) A roadstead.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/road",
        "https://en.wiktionary.org/wiki/roads",
      ],
    },
  ],
  threw: [
    {
      word: "threw",
      phonetic: "/θɹuː/",
      phonetics: [
        {
          text: "/θɹuː/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/threw-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1243863",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To change place.", synonyms: [], antonyms: [] },
            {
              definition: "To change in state or status",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To move through time.", synonyms: [], antonyms: [] },
            { definition: "To be accepted.", synonyms: [], antonyms: [] },
            {
              definition: "In any game, to decline to play in one's turn.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To do or be better.", synonyms: [], antonyms: [] },
            {
              definition: "To take heed.",
              synonyms: ["take heed", "take notice"],
              antonyms: [],
            },
          ],
          synonyms: [
            "spend",
            "pledge",
            "promise",
            "vow",
            "take heed",
            "take notice",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To hurl; to cause an object to move rapidly through the air.",
              synonyms: [
                "bowl",
                "bung",
                "buzz",
                "cast",
                "catapult",
                "chuck",
                "dash",
                "direct",
                "fire",
                "fling",
                "flip",
                "heave",
                "hurl",
                "launch",
                "lob",
                "pitch",
                "project",
                "propel",
                "send",
                "shoot",
                "shy",
                "sling",
                "toss",
                "whang",
              ],
              antonyms: [],
              example:
                "throw a shoe; throw a javelin; the horse threw its rider",
            },
            {
              definition: "To eject or cause to fall off.",
              synonyms: ["eject", "throw off"],
              antonyms: [],
            },
            {
              definition:
                "To move to another position or condition; to displace.",
              synonyms: ["displace", "relocate"],
              antonyms: [],
              example: "throw the switch",
            },
            {
              definition:
                "To make (a pot) by shaping clay as it turns on a wheel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a bowler) to deliver (the ball) illegally by straightening the bowling arm during delivery.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To send (an error) to an exception-handling mechanism in order to interrupt normal processing.",
              synonyms: [],
              antonyms: [],
              example:
                "If the file is read-only, the method throws an invalid-operation exception.",
            },
            {
              definition: "To intentionally lose a game.",
              synonyms: ["take a dive"],
              antonyms: [],
              example:
                "The tennis player was accused of taking bribes to throw the match.",
            },
            {
              definition: "To confuse or mislead.",
              synonyms: [],
              antonyms: [],
              example: "The deliberate red herring threw me at first.",
            },
            {
              definition: "To send desperately.",
              synonyms: [],
              antonyms: [],
              example: "Their sergeant threw the troops into pitched battle.",
            },
            {
              definition: "To imprison.",
              synonyms: [],
              antonyms: [],
              example:
                "The magistrate ordered the suspect to be thrown into jail.",
            },
            {
              definition: "To organize an event, especially a party.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To roll (a die or dice).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause a certain number on the die or dice to be shown after rolling it.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To discard.", synonyms: [], antonyms: [] },
            {
              definition:
                "To lift the opponent off the ground and bring him back down, especially into a position behind the thrower.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(said of one's voice) To change in order to give the illusion that the voice is that of someone else.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To show sudden emotion, especially anger.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To project or send forth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To put on hastily; to spread carelessly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To twist two or more filaments of (silk, etc.) so as to form one thread; to twist together, as singles, in a direction contrary to the twist of the singles themselves; sometimes applied to the whole class of operations by which silk is prepared for the weaver.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a team, a manager, etc.) To select (a pitcher); to assign a pitcher to a given role (such as starter or reliever).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To install (a bridge).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To twist or turn.",
              synonyms: [],
              antonyms: [],
              example: "a thrown nail",
            },
          ],
          synonyms: [
            "bowl",
            "bung",
            "buzz",
            "cast",
            "catapult",
            "chuck",
            "dash",
            "direct",
            "fire",
            "fling",
            "flip",
            "heave",
            "hurl",
            "launch",
            "lob",
            "pitch",
            "project",
            "propel",
            "send",
            "shoot",
            "shy",
            "sling",
            "toss",
            "whang",
            "displace",
            "relocate",
            "eject",
            "throw off",
            "take a dive",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(said of animals) To give birth to.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pass",
        "https://en.wiktionary.org/wiki/threw",
        "https://en.wiktionary.org/wiki/throw",
      ],
    },
  ],
  enemy: [
    {
      word: "enemy",
      phonetic: "/ˈɛnəmi/",
      phonetics: [
        {
          text: "/ˈɛnəmi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/enemy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1227769",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Someone who is hostile to, feels hatred towards, opposes the interests of, or intends injury to someone else.",
              synonyms: [
                "adversary",
                "backfriend",
                "foe",
                "nemesis",
                "unfriend",
              ],
              antonyms: ["ally", "friend"],
              example: "Crush the enemy!",
            },
            {
              definition:
                "A hostile force or nation; a fighting member of such a force or nation.",
              synonyms: ["adversary", "foe", "nemesis"],
              antonyms: ["ally", "friend"],
              example: "Rally together against a common enemy.",
            },
            {
              definition: "Something harmful or threatening to another",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "adversary",
            "backfriend",
            "foe",
            "nemesis",
            "unfriend",
            "adversary",
            "foe",
            "nemesis",
          ],
          antonyms: ["ally", "friend", "ally", "friend"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To make an enemy of.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of, relating to, or belonging to an enemy",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/enemy"],
    },
  ],
  lunch: [
    {
      word: "lunch",
      phonetic: "/lʌnt͡ʃ/",
      phonetics: [
        {
          text: "/lʌnt͡ʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lunch-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75900003",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/lʌnt͡ʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lunch-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9023211",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/lʌnt͡ʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lunch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1234305",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A light meal usually eaten around midday, notably when not as main meal of the day.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A break in play between the first and second sessions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Minnesota) Any small meal, especially one eaten at a social gathering.",
              synonyms: [],
              antonyms: [],
              example:
                "After the funeral there was a lunch for those who didn't go to the cemetery.",
            },
          ],
          synonyms: ["luncheon", "nones"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To eat lunch.",
              synonyms: [],
              antonyms: [],
              example: "I like to lunch in Italian restaurants.",
            },
            { definition: "To treat to lunch.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lunch"],
    },
  ],
  chart: [
    {
      word: "chart",
      phonetic: "/tʃɑːt/",
      phonetics: [
        { text: "/tʃɑːt/", audio: "" },
        {
          text: "/tʃɑɹt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chart-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2650744",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A map.", synonyms: [], antonyms: [] },
            {
              definition: "A systematic non-narrative presentation of data.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A written deed; a charter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A subspace of a manifold used as part of an atlas",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To draw a chart or map of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To draw or figure out (a route or plan).",
              synonyms: [],
              antonyms: [],
              example: "Let's chart how we're going to get from here to there.",
            },
            {
              definition: "To record systematically.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a record or artist) To appear on a hit-recording chart.",
              synonyms: [],
              antonyms: [],
              example: "The band first charted in 1994.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chart"],
    },
  ],
  scene: [
    {
      word: "scene",
      phonetic: "/siːn/",
      phonetics: [
        {
          text: "/siːn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scene-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1239786",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The location of an event that attracts attention.",
              synonyms: [],
              antonyms: [],
              example: "the scene of the crime",
            },
            {
              definition: "The stage.",
              synonyms: [],
              antonyms: [],
              example: "They stood in the centre of the scene.",
            },
            {
              definition:
                "The decorations; furnishings and backgrounds of a stage, representing the place in which the action of a play is set",
              synonyms: [],
              antonyms: [],
              example: "behind the scenes",
            },
            {
              definition:
                "A part of a dramatic work that is set in the same place or time. In the theatre, generally a number of scenes constitute an act.",
              synonyms: [],
              antonyms: [],
              example:
                "The most moving scene is the final one, where he realizes he has wasted his whole life.",
            },
            {
              definition:
                "The location, time, circumstances, etc., in which something occurs, or in which the action of a story, play, or the like, is set up",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A combination of objects or events in view or happening at a given moment at a particular place.",
              synonyms: [],
              antonyms: [],
              example:
                "He assessed the scene to check for any danger, and agreed it was safe.",
            },
            {
              definition: "A landscape, or part of a landscape; scenery.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An exhibition of passionate or strong feeling before others, creating embarrassment or disruption; often, an artificial or affected action, or course of action, done for effect; a theatrical display",
              synonyms: [],
              antonyms: [],
              example: "The crazy lady made a scene in the grocery store.",
            },
            {
              definition: "An element of fiction writing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A social environment consisting of an informal, vague group of people with a uniting interest; their sphere of activity; a subculture.",
              synonyms: [],
              antonyms: [],
              example: "She got into the emo scene at an early age.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To exhibit as a scene; to make a scene of; to display.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scene"],
    },
  ],
  graph: [
    {
      word: "graph",
      phonetic: "/ɡɹæf/",
      phonetics: [
        { text: "/ɡɹæf/", audio: "" },
        { text: "/ɡɹɑːf/", audio: "" },
        {
          text: "/ɡɹæf/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/graph-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50240917",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(applied mathematics) A data chart (graphical representation of data) intended to illustrate the relationship between a set (or sets) of numbers (quantities, measurements or indicative numbers) and a reference set, whose elements are indexed to those of the former set(s) and may or may not be numbers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A set of points constituting a graphical representation of a real function; (formally) a set of tuples (x_1, x_2, \\ldots, x_m, y)\\in\\R^{m+1}, where y=f(x_1, x_2, \\ldots, x_m) for a given function f: \\R^m\\rightarrow\\R.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(formally) An ordered pair of sets (V,E), where the elements of V are called vertices or nodes and E is a set of pairs (called edges) of elements of V; (less formally) a set of vertices (or nodes) together with a set of edges that connect (some of) the vertices.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A topological space which represents some graph (ordered pair of sets) and which is constructed by representing the vertices as points and the edges as copies of the real interval [0,1] (where, for any given edge, 0 and 1 are identified with the points representing the two vertices) and equipping the result with a particular topology called the graph topology.",
              synonyms: ["topological graph"],
              antonyms: [],
            },
            {
              definition:
                "(of a morphism f) A morphism \\Gamma_f from the domain of f to the product of the domain and codomain of f, such that the first projection applied to \\Gamma_f equals the identity of the domain, and the second projection applied to \\Gamma_f is equal to f.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A graphical unit on the token-level, the abstracted fundamental shape of a character or letter as distinct from its ductus (realization in a particular typeface or handwriting on the instance-level) and as distinct by a grapheme on the type-level by not fundamentally distinguishing meaning.",
              synonyms: ["glyph"],
              antonyms: [],
            },
          ],
          synonyms: ["glyph", "topological graph"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To draw a graph.", synonyms: [], antonyms: [] },
            {
              definition: "To draw a graph of a function.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["chart", "plot"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/graph"],
    },
  ],
  doubt: [
    {
      word: "doubt",
      phonetic: "/dʌʊt/",
      phonetics: [
        { text: "/dʌʊt/", audio: "" },
        { text: "/daʊt/", audio: "" },
        {
          text: "/daʊt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/doubt-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=192896",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Disbelief or uncertainty (about something); a particular instance of such disbelief or uncertainty.",
              synonyms: [],
              antonyms: [],
              example:
                "I have doubts about how to convert this code to JavaScript.",
            },
            {
              definition: "A point of uncertainty; a query.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To be undecided about; to lack confidence in; to disbelieve, to question.",
              synonyms: ["distrust", "mistrust"],
              antonyms: [],
              example: "He doubted that was really what you meant.",
            },
            {
              definition: "To harbour suspicion about; suspect.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To anticipate with dread or fear; to apprehend.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fill with fear; to affright.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To dread, to fear.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["distrust", "mistrust"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/doubt"],
    },
  ],
  guide: [
    {
      word: "guide",
      phonetic: "/ɡaɪd/",
      phonetics: [
        {
          text: "/ɡaɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/guide-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=862140",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Someone who guides, especially someone hired to show people around a place or an institution and offer information and explanation.",
              synonyms: [],
              antonyms: [],
              example:
                "The guide led us around the museum and explained the exhibits.",
            },
            {
              definition:
                "A document or book that offers information or instruction; guidebook.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sign that guides people; guidepost.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any marking or object that catches the eye to provide quick reference.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A device that guides part of a machine, or guides motion or action.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A spirit believed to speak through a medium.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A member of a group marching in formation who sets the pattern of movement or alignment for the rest.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/guide"],
    },
    {
      word: "guide",
      phonetic: "/ɡaɪd/",
      phonetics: [
        {
          text: "/ɡaɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/guide-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=862140",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To serve as a guide for someone or something; to lead or direct in a way; to conduct in a course or path.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To steer or navigate, especially a ship or as a pilot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To exert control or influence over someone or something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To supervise the education or training of someone.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To act as a guide.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/guide"],
    },
  ],
  winds: [
    {
      word: "winds",
      phonetic: "/wɪndz/",
      phonetics: [{ text: "/wɪndz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Real or perceived movement of atmospheric air usually caused by convection or differences in air pressure.",
              synonyms: [],
              antonyms: [],
              example:
                "As they accelerated onto the motorway, the wind tore the plywood off the car's roof-rack.",
            },
            {
              definition:
                "Air artificially put in motion by any force or action.",
              synonyms: [],
              antonyms: [],
              example: "the wind of a cannon ball;  the wind of a bellows",
            },
            {
              definition: "The ability to breathe easily.",
              synonyms: [],
              antonyms: [],
              example: "After the second lap he was already out of wind.",
            },
            {
              definition:
                "News of an event, especially by hearsay or gossip. (Used with catch, often in the past tense.)",
              synonyms: [],
              antonyms: [],
              example:
                "Steve caught wind of Martha's dalliance with his best friend.",
            },
            {
              definition:
                "One of the five basic elements in Indian and Japanese models of the Classical elements).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Flatus.",
              synonyms: [],
              antonyms: [],
              example: "Eww. Someone just passed wind.",
            },
            {
              definition:
                "Breath modulated by the respiratory and vocal organs, or by an instrument.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The woodwind section of an orchestra. Occasionally also used to include the brass section.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'A direction from which the wind may blow; a point of the compass; especially, one of the cardinal points, which are often called the "four winds".',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Types of playing-tile in the game of mah-jongg, named after the four winds.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A disease of sheep, in which the intestines are distended with air, or rather affected with a violent inflammation. It occurs immediately after shearing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Mere breath or talk; empty effort; idle words.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A bird, the dotterel.", synonyms: [], antonyms: [] },
            {
              definition:
                "The region of the solar plexus, where a blow may paralyze the diaphragm and cause temporary loss of breath or other injury.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["gas", "breeze", "draft", "gale"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The act of winding or turning; a turn; a bend; a twist.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wind",
        "https://en.wiktionary.org/wiki/winds",
      ],
    },
    {
      word: "winds",
      phonetic: "/waɪndz/",
      phonetics: [{ text: "/waɪndz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To blow air through a wind instrument or horn to make a sound.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause (someone) to become breathless, as by a blow to the abdomen, or by physical exertion, running, etc.",
              synonyms: [],
              antonyms: [],
              example: "The boxer was winded during round two.",
            },
            {
              definition:
                "To cause a baby to bring up wind by patting its back after being fed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To turn a boat or ship around, so that the wind strikes it on the opposite side.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To expose to the wind; to winnow; to ventilate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To perceive or follow by scent.",
              synonyms: [],
              antonyms: [],
              example: "The hounds winded the game.",
            },
            {
              definition:
                "To rest (a horse, etc.) in order to allow the breath to be recovered; to breathe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To turn a windmill so that its sails face into the wind.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To turn coils of (a cord or something similar) around something.",
              synonyms: [],
              antonyms: [],
              example: "to wind thread on a spool or into a ball",
            },
            {
              definition:
                "To tighten the spring of a clockwork mechanism such as that of a clock.",
              synonyms: [],
              antonyms: [],
              example: "Please wind that old-fashioned alarm clock.",
            },
            {
              definition: "To entwist; to enfold; to encircle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To travel in a way that is not straight.",
              synonyms: [],
              antonyms: [],
              example:
                "Vines wind round a pole.  The river winds through the plain.",
            },
            {
              definition:
                "To have complete control over; to turn and bend at one's pleasure; to vary or alter or will; to regulate; to govern.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To introduce by insinuation; to insinuate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cover or surround with something coiled about.",
              synonyms: [],
              antonyms: [],
              example: "to wind a rope with twine",
            },
            {
              definition:
                "To cause to move by exerting a winding force; to haul or hoist, as by a winch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To turn (a ship) around, end for end.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wind",
        "https://en.wiktionary.org/wiki/winds",
      ],
    },
  ],
  block: [
    {
      word: "block",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/block-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899594",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/blɒk/", audio: "" },
        {
          text: "/blɑk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/block-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769619",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A substantial, often approximately cuboid, piece of any substance.",
              synonyms: [],
              antonyms: [],
              example: "a block of ice",
            },
            {
              definition:
                "A chopping block; cuboid base for cutting or beheading.",
              synonyms: [],
              antonyms: [],
              example:
                "Anne Boleyn placed her head on the block and awaited her execution.",
            },
            {
              definition:
                "A group of urban lots of property, several acres in extent, not crossed by public streets.",
              synonyms: [],
              antonyms: [],
              example: "I'm going for a walk around the block.",
            },
            {
              definition: "A residential building consisting of flats.",
              synonyms: [],
              antonyms: [],
              example: "a block of flats",
            },
            {
              definition:
                "The distance from one street to another in a city that is built (approximately) to a grid pattern.",
              synonyms: [],
              antonyms: [],
              example:
                "The place you are looking for is two long blocks east and one short block north.",
            },
            {
              definition: "Interference or obstruction of cognitive processes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The human head.",
              synonyms: [],
              antonyms: [],
              example: "I'll knock your block off!",
            },
            {
              definition:
                "A wig block: a simplified head model upon which wigs are worn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A mould on which hats, bonnets, etc., are shaped.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A set of sheets (of paper) joined together at one end.",
              synonyms: [],
              antonyms: [],
              example: "a block of 100 tickets",
            },
            {
              definition:
                "A logical data storage unit containing one or more physical sectors (see cluster).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A region of code in a program that acts as a single unit, such as a function or loop.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A fixed-length group of bits making up part of a message.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A case with one or more sheaves/pulleys, used with ropes to increase or redirect force, for example, as part of the rigging of a sailing ship.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A portion of a macromolecule, comprising many units, that has at least one feature not present in adjacent portions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Something that prevents something from passing.",
              synonyms: ["barrier", "blockage", "obstruction"],
              antonyms: [],
              example:
                "There's a block in the pipe that means the water can't get through.",
            },
            {
              definition:
                "An action to interfere with the movement of an opposing player or of the object of play (ball, puck).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A shot played by holding the bat vertically in the path of the ball, so that it loses momentum and drops to the ground.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A defensive play by one or more players meant to deflect a spiked ball back to the hitter’s court.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A joined group of four (or in some cases nine) postage stamps, forming a roughly square shape.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A section of split logs used as fuel.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Solitary confinement.", synonyms: [], antonyms: [] },
            { definition: "A cellblock.", synonyms: [], antonyms: [] },
            {
              definition: "The perch on which a bird of prey is kept.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of hard wood on which a stereotype or electrotype plate is mounted.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A blockhead; a stupid fellow; a dolt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A section of a railroad where the block system is used.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The position of a player or bat when guarding the wicket.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A blockhole.", synonyms: [], antonyms: [] },
            { definition: "The popping crease.", synonyms: [], antonyms: [] },
            {
              definition:
                "A discrete group of vines in a vineyard, often distinguished from others by variety, clone, canopy training method, irrigation infrastructure, or some combination thereof.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "roof",
            "stuff",
            "wall",
            "barrier",
            "blockage",
            "obstruction",
            "city block",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To fill (something) so that it is not possible to pass.",
              synonyms: [],
              antonyms: [],
              example: "The pipe is blocked.",
            },
            {
              definition: "To prevent (something or someone) from passing.",
              synonyms: [],
              antonyms: [],
              example: "You're blocking the road – I can't get through!",
            },
            {
              definition:
                "To prevent (something from happening or someone from doing something).",
              synonyms: [],
              antonyms: [],
              example:
                "His plan to take over the business was blocked by the boss.",
            },
            {
              definition: "To impede an opponent.",
              synonyms: [],
              antonyms: [],
              example: "He blocked the basketball player's shot.",
            },
            {
              definition:
                "To specify the positions and movements of the actors.",
              synonyms: [],
              antonyms: [],
              example:
                "It was very difficult to block this scene convincingly.",
            },
            { definition: "To hit with a block.", synonyms: [], antonyms: [] },
            { definition: "To play a block shot.", synonyms: [], antonyms: [] },
            {
              definition:
                "To disable communication via telephone, instant messaging, etc., with an undesirable someone.",
              synonyms: [],
              antonyms: [],
              example: "I tried to send you a message, but you've blocked me!",
            },
            {
              definition: "To wait.",
              synonyms: [],
              antonyms: [],
              example:
                "When the condition expression is false, the thread blocks on the condition variable.",
            },
            {
              definition:
                "To stretch or mould (a knitted item, a hat, etc.) into the desired shape.",
              synonyms: [],
              antonyms: [],
              example:
                "I blocked the mittens by wetting them and pinning them to a shaped piece of cardboard.",
            },
            {
              definition: "To shape or sketch out roughly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/block"],
    },
  ],
  grain: [
    {
      word: "grain",
      phonetic: "/ɡɹeɪn/",
      phonetics: [
        {
          text: "/ɡɹeɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grain-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=196492",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The harvested seeds of various grass food crops eg: wheat, corn, barley.",
              synonyms: [],
              antonyms: [],
              example: "We stored a thousand tons of grain for the winter.",
            },
            {
              definition:
                "Similar seeds from any food crop, e.g., buckwheat, amaranth, quinoa.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A single seed of grass food crops.",
              synonyms: [],
              antonyms: [],
              example: "a grain of wheat",
            },
            {
              definition: "The crops from which grain is harvested.",
              synonyms: [],
              antonyms: [],
              example: "The fields were planted with grain.",
            },
            {
              definition: "A linear texture of a material or surface.",
              synonyms: [],
              antonyms: [],
              example: "Cut along the grain of the wood.",
            },
            {
              definition: "A single particle of a substance.",
              synonyms: [],
              antonyms: [],
              example: "a grain of salt",
            },
            {
              definition:
                "A very small unit of weight, in England equal to 1/480 of an ounce troy, 0.0648 grams or, to be more exact, 64.79891 milligrams (0.002285714 avoirdupois ounce). A carat grain or pearl grain is 1/4 carat or 50 milligrams. The old French grain was 1/9216 livre or 53.11 milligrams, and in the mesures usuelles permitted from 1812 to 1839, with the livre redefined as 500 grams, it was 54.25 milligrams.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'A former unit of gold purity, also known as carat grain, equal to 1/4 "carat" (karat).',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(materials) A region within a material having a single crystal structure or direction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A reddish dye made from the coccus insect, or kermes; hence, a red color of any tint or hue, as crimson, scarlet, etc.; sometimes used by the poets as equivalent to Tyrian purple.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The hair side of a piece of leather, or the marking on that side.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in the plural) The remains of grain, etc., after brewing or distillation; hence, any residuum. Also called draff.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A rounded prominence on the back of a sepal, as in the common dock.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Temper; natural disposition; inclination.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(videography) Visual texture in processed photographic film due to the presence of small particles of a metallic silver, or dye clouds, developed from silver halide that have received enough photons.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To feed grain to.", synonyms: [], antonyms: [] },
            {
              definition: "To make granular; to form into grains.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To form grains, or to assume a granular form, as the result of crystallization; to granulate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To texture a surface in imitation of the grain of a substance such as wood.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(tanning) To remove the hair or fat from a skin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(tanning) To soften leather.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To yield fruit.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/grain"],
    },
    {
      word: "grain",
      phonetic: "/ɡɹeɪn/",
      phonetics: [
        {
          text: "/ɡɹeɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grain-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=196492",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A branch of a tree; a stalk or stem of a plant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A tine, prong, or fork.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(founding) A thin piece of metal, used in a mould to steady a core.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/grain"],
    },
  ],
  smoke: [
    {
      word: "smoke",
      phonetic: "/sməʊk/",
      phonetics: [
        {
          text: "/sməʊk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/smoke-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=77899705",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/smoʊk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/smoke-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1157750",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The visible vapor/vapour, gases, and fine particles given off by burning or smoldering material.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A cigarette.",
              synonyms: [],
              antonyms: [],
              example:
                "Can I bum a smoke off you?;  I need to go buy some smokes.",
            },
            {
              definition:
                "Anything to smoke (e.g. cigarettes, marijuana, etc.)",
              synonyms: [],
              antonyms: [],
              example: "Hey, you got some smoke?",
            },
            {
              definition:
                "(never plural) An instance of smoking a cigarette, cigar, etc.; the duration of this act.",
              synonyms: [],
              antonyms: [],
              example: "I'm going out for a smoke.",
            },
            {
              definition:
                "A fleeting illusion; something insubstantial, evanescent, unreal, transitory, or without result.",
              synonyms: [],
              antonyms: [],
              example:
                "The excitement behind the new candidate proved to be smoke.",
            },
            {
              definition:
                "Something used to obscure or conceal; an obscuring condition; see also smoke and mirrors.",
              synonyms: [],
              antonyms: [],
              example: "The smoke of controversy.",
            },
            {
              definition: "A light grey colour/color tinted with blue.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A particulate of solid or liquid particles dispersed into the air on the battlefield to degrade enemy ground or for aerial observation. Smoke has many uses--screening smoke, signaling smoke, smoke curtain, smoke haze, and smoke deception. Thus it is an artificial aerosol.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A fastball.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["cancer stick", "cig", "ciggy", "coffin nail", "fag"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of the colour known as smoke.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Made of or with smoke.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/smoke"],
    },
    {
      word: "smoke",
      phonetic: "/sməʊk/",
      phonetics: [
        {
          text: "/sməʊk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/smoke-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=77899705",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/smoʊk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/smoke-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1157750",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To inhale and exhale the smoke from a burning cigarette, cigar, pipe, etc.",
              synonyms: [],
              antonyms: [],
              example: "He's smoking his pipe.",
            },
            {
              definition: "To inhale and exhale tobacco smoke.",
              synonyms: [],
              antonyms: [],
              example: "Do you smoke?",
            },
            {
              definition: "To give off smoke.",
              synonyms: [],
              antonyms: [],
              example: "My old truck was still smoking even after the repairs.",
            },
            {
              definition:
                "To preserve or prepare (food) for consumption by treating with smoke.",
              synonyms: [],
              antonyms: [],
              example: "You'll need to smoke the meat for several hours.",
            },
            {
              definition: "To dry or medicate by smoke.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To fill or scent with smoke; hence, to fill with incense; to perfume.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make unclear or blurry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(chiefly as present participle) To perform (e.g. music) energetically or skillfully.",
              synonyms: [],
              antonyms: [],
              example: "The horn section was really smokin' on that last tune.",
            },
            {
              definition: "To beat someone at something.",
              synonyms: [],
              antonyms: [],
              example: "We smoked them at rugby.",
            },
            {
              definition: "To kill, especially with a gun.",
              synonyms: [],
              antonyms: [],
              example: "He got smoked by the mob.",
            },
            { definition: "To thrash; to beat.", synonyms: [], antonyms: [] },
            {
              definition: "To smell out; to hunt out; to find out; to detect.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To ridicule to the face; to mock.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To burn; to be kindled; to rage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To raise a dust or smoke by rapid motion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To suffer severely; to be punished.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To punish (a person) for a minor offense by excessive physical exercise.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cover (a key blank) with soot or carbon to aid in seeing the marks made by impressioning.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/smoke"],
    },
  ],
  mixed: [
    {
      word: "mixed",
      phonetic: "/mɪkst/",
      phonetics: [
        {
          text: "/mɪkst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mixed-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1113561",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To stir together.",
              synonyms: [],
              antonyms: [],
              example:
                "Mix the eggs and milk with the flour until the consistency is smooth.",
            },
            {
              definition:
                "To combine (items from two or more sources normally kept separate).",
              synonyms: [],
              antonyms: [],
              example: "Don't mix the meat recipes with the dairy recipes.",
            },
            {
              definition:
                "To form by mingling; to produce by the stirring together of ingredients; to concoct from different parts.",
              synonyms: [],
              antonyms: [],
              example: "Yellow and blue paint mix to make green.",
            },
            {
              definition: "To blend by the use of a mixer (machine).",
              synonyms: [],
              antonyms: [],
              example: "Mix the egg whites until they are stiff.",
            },
            {
              definition: "To combine (several tracks).",
              synonyms: [],
              antonyms: [],
              example: "I'll mix the rhythm tracks down to a single track.",
            },
            {
              definition: "To produce a finished version of (a recording).",
              synonyms: [],
              antonyms: [],
              example: "I'm almost done mixing this song.",
            },
            {
              definition: "To unite with in company; to join; to associate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "mix together",
            "mix up",
            "muddle",
            "muddle up",
            "blend",
            "combine",
            "intermix",
            "mingle",
            "mix together",
            "mix up",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having two or more separate aspects.",
              synonyms: [],
              antonyms: [],
              example:
                "I get a very mixed feeling from this puzzling painting.",
            },
            {
              definition: "Not completely pure, tainted or adulterated.",
              synonyms: [],
              antonyms: [],
              example:
                "My joy was somewhat mixed when my partner said she was pregnant: it's a lot of responsibility.",
            },
            {
              definition: "Including both male(s) and female(s).",
              synonyms: [],
              antonyms: [],
              example:
                "My son attends a mixed school, my daughter an all-girl grammar school.",
            },
            {
              definition: "Stemming from two or more races or breeds",
              synonyms: [],
              antonyms: [],
              example:
                "Mixed blood can surprisingly produce inherited properties which neither parent showed",
            },
          ],
          synonyms: [
            "ambivalent",
            "conflicted",
            "equivocal",
            "heterogeneous",
            "co-ed",
            "unsegregated",
            "impure",
            "hybrid",
            "mongrel",
          ],
          antonyms: [
            "homogeneous",
            "unmixed",
            "single-sex",
            "pure",
            "pedigree",
            "pure",
            "pureblooded",
            "purebred",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mix",
        "https://en.wiktionary.org/wiki/mixed",
      ],
    },
  ],
  games: [
    {
      word: "games",
      phonetic: "/ɡeɪmz/",
      phonetics: [
        {
          text: "/ɡeɪmz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/games-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2454050",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A playful or competitive activity.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A video game.", synonyms: [], antonyms: [] },
            {
              definition:
                "(nearly always singular) A field of gainful activity, as an industry or profession.",
              synonyms: [],
              antonyms: [],
              example: "He's in the securities game somehow.",
            },
            {
              definition:
                "Something that resembles a game with rules, despite not being designed.",
              synonyms: [],
              antonyms: [],
              example:
                "In the game of life, you may find yourself playing the waiting game far too often.",
            },
            {
              definition:
                "An exercise simulating warfare, whether computerized or involving human participants.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Wild animals hunted for food.",
              synonyms: [],
              antonyms: [],
              example: "The forest has plenty of game.",
            },
            {
              definition:
                "(used mostly of males) The ability to seduce someone, usually by strategy.",
              synonyms: [],
              antonyms: [],
              example:
                "He didn't get anywhere with her because he had no game.",
            },
            {
              definition: "Mastery; the ability to excel at something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A questionable or unethical practice in pursuit of a goal; a scheme.",
              synonyms: [],
              antonyms: [],
              example:
                "You want to borrow my credit card for a week? What's your game?",
            },
          ],
          synonyms: [
            "racket",
            "line",
            "match",
            "wargame",
            "racket",
            "amusement",
            "diversion",
            "entertainment",
            "festivity",
            "frolic",
            "fun",
            "gaiety",
            "gambol",
            "lark",
            "merriment",
            "merrymaking",
            "pastime",
            "play",
            "prank",
            "recreation",
            "sport",
            "spree",
          ],
          antonyms: ["drudgery", "toil", "work"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To gamble.", synonyms: [], antonyms: [] },
            {
              definition: "To play card games, board games, or video games.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To exploit loopholes in a system or bureaucracy in a way which defeats or nullifies the spirit of the rules in effect, usually to obtain a result which otherwise would be unobtainable.",
              synonyms: [],
              antonyms: [],
              example: "We'll bury them in paperwork, and game the system.",
            },
            {
              definition:
                "(of males) To perform premeditated seduction strategy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/game",
        "https://en.wiktionary.org/wiki/games",
      ],
    },
  ],
  wagon: [
    {
      word: "wagon",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wagon-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75701849",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈwæɡ(ə)n/", audio: "" },
        {
          text: "/ˈwæɡən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wagon-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780481",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A four-wheeled cart for hauling loads.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A four-wheeled child's riding toy, pulled or steered by a long handle attached to the front.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An enclosed vehicle for carrying goods or people; (by extension) a lorry, a truck.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An enclosed vehicle used as a movable dwelling; a caravan.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Short for dinner wagon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Short for paddy wagon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A freight car on a railway.",
              synonyms: ["goods wagon"],
              antonyms: [],
            },
            {
              definition:
                "Short for station wagon; (by extension) a sport utility vehicle (SUV); any car.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A woman of loose morals, a promiscuous woman, a slapper; (by extension) a woman regarded as obnoxious; a bitch, a cow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A kind of prefix used in de Bruijn notation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["goods wagon"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To load into a wagon in preparation for transportation; to transport by means of a wagon.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To travel in a wagon.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/wagon"],
    },
  ],
  sweet: [
    {
      word: "sweet",
      phonetic: "/swiːt/",
      phonetics: [
        { text: "/swiːt/", audio: "" },
        {
          text: "/swit/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sweet-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1194291",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The basic taste sensation induced by sugar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A confection made from sugar, or high in sugar content; a candy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A food eaten for dessert.",
              synonyms: [],
              antonyms: [],
              example: "Can we see the sweet menu, please?",
            },
            { definition: "Sweetheart; darling.", synonyms: [], antonyms: [] },
            {
              definition:
                "That which is sweet or pleasant in odour; a perfume.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Sweetness, delight; something pleasant to the mind or senses.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "dessert",
            "bonbon",
            "candy",
            "confection",
            "confectionery",
            "lolly",
            "sweetness",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To sweeten.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Having a pleasant taste, especially one relating to the basic taste sensation induced by sugar.",
              synonyms: [],
              antonyms: [],
              example: "a sweet apple",
            },
            {
              definition: "Having a taste of sugar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Retaining a portion of sugar.",
              synonyms: [],
              antonyms: [],
              example: "Sweet wines are better dessert wines.",
            },
            {
              definition: "Not having a salty taste.",
              synonyms: [],
              antonyms: [],
              example: "sweet butter",
            },
            {
              definition: "Having a pleasant smell.",
              synonyms: [],
              antonyms: [],
              example: "a sweet scent",
            },
            {
              definition:
                "Not decaying, fermented, rancid, sour, spoiled, or stale.",
              synonyms: [],
              antonyms: [],
              example: "sweet milk",
            },
            {
              definition: "Having a pleasant sound.",
              synonyms: [],
              antonyms: [],
              example: "a sweet tune",
            },
            {
              definition: "Having a pleasing disposition.",
              synonyms: [],
              antonyms: [],
              example: "a sweet child",
            },
            {
              definition: "Having a helpful disposition.",
              synonyms: [],
              antonyms: [],
              example: "It was sweet of him to help out.",
            },
            {
              definition:
                "Free from excessive unwanted substances like acid or sulphur.",
              synonyms: [],
              antonyms: [],
              example: "sweet crude oil",
            },
            {
              definition: "Very pleasing; agreeable.",
              synonyms: [],
              antonyms: [],
              example: "The new Lexus was a sweet birthday gift.",
            },
            {
              definition: "Doing well; in a good or happy position.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(followed by on) Romantically fixated, enamoured with, fond of",
              synonyms: [],
              antonyms: [],
              example:
                "The attraction was mutual and instant; they were sweet on one another from first sight.",
            },
            {
              definition: "Fresh; not salt or brackish.",
              synonyms: [],
              antonyms: [],
              example: "sweet water",
            },
            {
              definition:
                "Pleasing to the eye; beautiful; mild and attractive; fair.",
              synonyms: [],
              antonyms: [],
              example: "a sweet colour or complexion",
            },
          ],
          synonyms: [
            "awesome",
            "rad",
            "wicked",
            "sugared",
            "sweetened",
            "gracious",
            "helpful",
            "kind",
            "sensitive",
            "thoughtful",
            "fragrant",
            "odoriferous",
            "odorous",
            "perfumed",
            "scented",
            "sweet-scented",
            "sweet-smelling",
            "dulcet",
            "honeyed",
            "mellifluous",
            "mellisonant",
            "cute",
            "lovable",
            "pleasant",
            "saccharine",
            "sugary",
            "fresh",
            "unfermented",
            "wholesome",
            "fresh",
            "unsalty",
          ],
          antonyms: [
            "lame",
            "uncool",
            "nonsweet",
            "sugarless",
            "unsugared",
            "unsweet",
            "unsweetened",
            "sour",
            "bitter",
            "salty",
            "sour",
            "decaying",
            "fermented",
            "rancid",
            "sour",
            "spoiled",
            "stale",
            "salty",
            "savoury",
            "dry",
          ],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            { definition: "In a sweet manner.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["sweetly"],
          antonyms: [],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition:
                "Used as a positive response to good news or information.",
              synonyms: [],
              antonyms: [],
              example: "They're making a sequel? Ah, sweet!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sweet"],
    },
  ],
  topic: [
    {
      word: "topic",
      phonetic: "/ˈtɒpɪk/",
      phonetics: [
        { text: "/ˈtɒpɪk/", audio: "" },
        {
          text: "/ˈtɑpɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/topic-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780450",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Subject; theme; a category or general area of interest.",
              synonyms: [],
              antonyms: [],
              example:
                "A society where a topic cannot be discussed, does not have free speech.",
            },
            { definition: "Discussion thread.", synonyms: [], antonyms: [] },
            {
              definition:
                "A musical sign intended to suggest a particular style or genre.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An argument or reason.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An external local application or remedy, such as a plaster, a blister, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["subject", "subject area"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [{ definition: "Topical", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/topic"],
    },
  ],
  extra: [
    {
      word: "extra",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/extra-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899620",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈɛkstɹə/", audio: "" },
        {
          text: "/ˈɛkstɹə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/extra-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2707504",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Something additional, such as an item above and beyond the ordinary school curriculum, or added to the usual charge on a bill.",
              synonyms: ["addition", "supplement"],
              antonyms: [],
            },
            {
              definition:
                "An extra edition of a newspaper, which is printed outside of the normal printing cycle.",
              synonyms: [],
              antonyms: [],
              example: "Extra, extra! Read all about it!",
            },
            {
              definition:
                "A run scored without the ball having hit the striker's bat - a wide, bye, leg bye or no ball.",
              synonyms: ["sundry"],
              antonyms: [],
            },
            {
              definition: "A supernumerary or walk-on in a film or play.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Something of an extra quality or grade.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["addition", "supplement", "sundry"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Beyond what is due, usual, expected, or necessary; extraneous; additional; supernumerary.",
              synonyms: [],
              antonyms: [],
              example:
                "I don't mind doing some extra work, as long as I get extra pay.",
            },
            {
              definition: "Extraordinarily good; superior.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Over the top; going beyond what is normal or appropriate, often in a dramatic manner.",
              synonyms: [],
              antonyms: [],
              example:
                "You unfollowed her for posting cat memes? You're so extra!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "To an extraordinary degree.",
              synonyms: [],
              antonyms: [],
              example: "That day he ran to school extra fast.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/extra"],
    },
  ],
  plate: [
    {
      word: "plate",
      phonetic: "/pleɪt/",
      phonetics: [
        {
          text: "/pleɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/plate-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75649020",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/pleɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/plate-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9014208",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/pleɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/plate-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1118855",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A slightly curved but almost flat dish from which food is served or eaten.",
              synonyms: [],
              antonyms: [],
              example: "I filled my plate from the bountiful table.",
            },
            {
              definition: "Such dishes collectively.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The contents of such a dish.",
              synonyms: [],
              antonyms: [],
              example: "I ate a plate of beans.",
            },
            {
              definition: "A course at a meal.",
              synonyms: [],
              antonyms: [],
              example: "The meat plate was particularly tasty.",
            },
            {
              definition: "An agenda of tasks, problems, or responsibilities",
              synonyms: [],
              antonyms: [],
              example:
                "With revenues down and transfer payments up, the legislature has a full plate.",
            },
            {
              definition: "A flat metallic object of uniform thickness.",
              synonyms: [],
              antonyms: [],
              example: "A clutch usually has two plates.",
            },
            {
              definition: "A vehicle license plate.",
              synonyms: [],
              antonyms: [],
              example:
                "He stole a car and changed the plates as soon as he could.",
            },
            {
              definition: "A taxi permit, especially of a metal disc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A layer of a material on the surface of something, usually qualified by the type of the material; plating",
              synonyms: [],
              antonyms: [],
              example:
                "The bullets just bounced off the steel plate on its hull.",
            },
            {
              definition: "A material covered with such a layer.",
              synonyms: [],
              antonyms: [],
              example:
                "If you're not careful, someone will sell you silverware that's really only silver plate.",
            },
            {
              definition:
                "A decorative or food service item coated with silver or gold.",
              synonyms: [],
              antonyms: [],
              example: "The tea was served in the plate.",
            },
            {
              definition:
                "A weighted disk, usually of metal, with a hole in the center for use with a barbell, dumbbell, or exercise machine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An engraved surface used to transfer an image to paper.",
              synonyms: [],
              antonyms: [],
              example: "We finished making the plates this morning.",
            },
            { definition: "An image or copy.", synonyms: [], antonyms: [] },
            {
              definition:
                "An illustration in a book, either black and white, or colour, usually on a page of paper of different quality from the text pages.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A shaped and fitted surface, usually ceramic or metal that fits into the mouth and in which teeth are implanted; a dental plate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A horizontal framing member at the top or bottom of a group of vertical studs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: 'A foot, from "plates of meat".',
              synonyms: [],
              antonyms: [],
              example: "Sit down and give your plates a rest.",
            },
            {
              definition: "Home plate.",
              synonyms: [],
              antonyms: [],
              example: "There was a close play at the plate.",
            },
            { definition: "A tectonic plate.", synonyms: [], antonyms: [] },
            {
              definition: "Plate armour.",
              synonyms: [],
              antonyms: [],
              example: "He was confronted by two knights in full plate.",
            },
            {
              definition:
                "Any of various larger scales found in some reptiles.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A flat electrode such as can be found in an accumulator battery, or in an electrolysis tank.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The anode of a vacuum tube.",
              synonyms: [],
              antonyms: [],
              example:
                "Regulating the oscillator plate voltage greatly improves the keying.",
            },
            {
              definition:
                'Silver or gold, in the form of a coin, or less often silver or gold utensils or dishes (from Spanish plata ("silver")).',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A roundel of silver or tinctured argent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A prize given to the winner in a contest.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any flat piece of material such as coated glass or plastic.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(travel industry) A metallic card, used to imprint tickets with an airline's logo, name, and numeric code.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(travel industry, by extension) The ability of a travel agent to issue tickets on behalf of a particular airline.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A VIN plate, particularly with regard to the car's year of manufacture.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One of the thin parts of the brisket of an animal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A very light steel horseshoe for racehorses.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(furriers' slang) Skins for fur linings of garments, sewn together and roughly shaped, but not finally cut or fitted.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(hat-making) The fine nap (as of beaver, musquash, etc.) on a hat whose body is made from inferior material.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A record, usually vinyl.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cover the surface material of an object with a thin coat of another material, usually a metal.",
              synonyms: [],
              antonyms: [],
              example: "This ring is plated with a thin layer of gold.",
            },
            {
              definition:
                "To place the various elements of a meal on the diner's plate prior to serving.",
              synonyms: [],
              antonyms: [],
              example: "After preparation, the chef will plate the dish.",
            },
            {
              definition: "To score a run.",
              synonyms: [],
              antonyms: [],
              example: "The single plated the runner from second base.",
            },
            {
              definition: "To arm or defend with metal plates.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To beat into thin plates.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(travel industry) To specify which airline a ticket will be issued on behalf of.",
              synonyms: [],
              antonyms: [],
              example:
                "Tickets are normally plated on an itinerary's first international airline.",
            },
            {
              definition:
                "To categorise stamps based on their position on the original sheet, in order to reconstruct an entire sheet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(particularly with early British stamps) to identify the printing plate used.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/plate"],
    },
    {
      word: "plate",
      phonetic: "/pleɪt/",
      phonetics: [
        {
          text: "/pleɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/plate-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75649020",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/pleɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/plate-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9014208",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/pleɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/plate-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1118855",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Precious metal, especially silver.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/plate"],
    },
  ],
  title: [
    {
      word: "title",
      phonetic: "/ˈtaɪtl̩/",
      phonetics: [
        {
          text: "/ˈtaɪtl̩/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/title-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1661718",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A prefix (honorific) or suffix (post-nominal) added to a person's name to signify either veneration, official position or a professional or academic qualification. See also",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Legal right to ownership of a property; a deed or other certificate proving this.",
              synonyms: [],
              antonyms: [],
              example: "a good title to an estate, or an imperfect title",
            },
            {
              definition:
                "In canon law, that by which a beneficiary holds a benefice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A church to which a priest was ordained, and where he was to reside.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The name of a book, film, musical piece, painting, or other work of art.",
              synonyms: [],
              antonyms: [],
              example:
                "I know the singer's name, but not the title of the song.",
            },
            {
              definition: "A publication.",
              synonyms: [],
              antonyms: [],
              example:
                "Buyers of the new video game console can choose from three bundled titles.",
            },
            {
              definition:
                "A section or division of a subject, as of a law or a book.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(chiefly in the plural) A written title, credit, or caption shown with a film, video, or performance.",
              synonyms: [],
              antonyms: [],
              example: "The titles scrolled by too quickly to read.",
            },
            {
              definition:
                "The panel for the name, between the bands of the back of a book.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The subject of a writing; a short phrase that summarizes the entire topic.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A division of an act of law",
              synonyms: [],
              antonyms: [],
              example: "Title II of the USA PATRIOT Act",
            },
            {
              definition:
                "The recognition given to the winner of a championship in sports.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To assign a title to; to entitle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/title"],
    },
  ],
  knife: [
    {
      word: "knife",
      phonetic: "/naɪf/",
      phonetics: [
        {
          text: "/naɪf/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/knife-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9014196",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/naɪf/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/knife-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=269551",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A utensil or a tool designed for cutting, consisting of a flat piece of hard material, usually steel or other metal (the blade), usually sharpened on one edge, attached to a handle. The blade may be pointed for piercing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A weapon designed with the aforementioned specifications intended for slashing and/or stabbing and too short to be called a sword. A dagger.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any blade-like part in a tool or a machine designed for cutting, such as that of a chipper.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To cut with a knife.", synonyms: [], antonyms: [] },
            {
              definition:
                "To use a knife to injure or kill by stabbing, slashing, or otherwise using the sharp edge of the knife as a weapon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cut through as if with a knife.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To betray, especially in the context of a political slate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To positively ignore, especially in order to denigrate. compare cut",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/knife"],
    },
  ],
  fence: [
    {
      word: "fence",
      phonetic: "/fɛns/",
      phonetics: [
        {
          text: "/fɛns/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fence-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=755214",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A thin artificial barrier that separates two pieces of land or a house perimeter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Someone who hides or buys and sells stolen goods, a criminal middleman for transactions of stolen goods.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Skill in oral debate.", synonyms: [], antonyms: [] },
            {
              definition: "The art or practice of fencing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A guard or guide on machinery.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A barrier, for example an emotional barrier.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A memory barrier.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To enclose, contain or separate by building fence.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To defend or guard.", synonyms: [], antonyms: [] },
            {
              definition: "To engage in the selling or buying of stolen goods.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To engage in the sport of fencing.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To jump over a fence.", synonyms: [], antonyms: [] },
            {
              definition:
                "To conceal the truth by giving equivocal answers; to hedge; to be evasive.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["pawn"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fence"],
    },
  ],
  falls: [
    {
      word: "falls",
      phonetic: "/fɑlz/",
      phonetics: [
        { text: "/fɑlz/", audio: "" },
        {
          text: "/fɔːlz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/falls-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749838",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The act of moving to a lower position under the effect of gravity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A reduction in quantity, pitch, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The time of the year when the leaves typically fall from the trees; autumn; the season of the year between the autumnal equinox and the winter solstice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A loss of greatness or status.",
              synonyms: [],
              antonyms: [],
              example: "the fall of Rome",
            },
            {
              definition: "That which falls or cascades.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A crucial event or circumstance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hairpiece for women consisting of long strands of hair on a woven backing, intended primarily to cover hair loss.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Blame or punishment for a failure or misdeed.",
              synonyms: [],
              antonyms: [],
              example: "He set up his rival to take the fall.",
            },
            {
              definition:
                "The part of the rope of a tackle to which the power is applied in hoisting (usu. plural).",
              synonyms: [],
              antonyms: [],
              example:
                "Have the goodness to secure the falls of the mizzen halyards.",
            },
            {
              definition: "An old Scots unit of measure equal to six ells.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A short, flexible piece of leather forming part of a bullwhip, placed between the thong and the cracker.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The lid, on a piano, that covers the keyboard",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "descent",
            "drop",
            "rap",
            "downfall",
            "decrease",
            "dip",
            "drop",
            "lowering",
            "reduction",
            "autumn",
            "back end",
            "harvest",
          ],
          antonyms: ["ascent", "rise", "ascent", "rise", "increase", "rise"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(heading) To be moved downwards.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To move downwards.", synonyms: [], antonyms: [] },
            {
              definition: "To happen, to change negatively.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be allotted to; to arrive through chance, fate, or inheritance.",
              synonyms: [],
              antonyms: [],
              example:
                "And so it falls to me to make this important decision.  The estate fell to his brother; the kingdom fell into the hands of his rivals.",
            },
            {
              definition: "To diminish; to lessen or lower.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To bring forth.",
              synonyms: [],
              antonyms: [],
              example: "to fall lambs",
            },
            {
              definition:
                "To issue forth into life; to be brought forth; said of the young of certain animals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To descend in character or reputation; to become degraded; to sink into vice, error, or sin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To become ensnared or entrapped; to be worse off than before.",
              synonyms: [],
              antonyms: [],
              example: "to fall into error;  to fall into difficulties",
            },
            {
              definition:
                "To assume a look of shame or disappointment; to become or appear dejected; said of the face.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To happen; to come to pass; to chance or light (upon).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To begin with haste, ardour, or vehemence; to rush or hurry.",
              synonyms: [],
              antonyms: [],
              example: "After arguing, they fell to blows.",
            },
            {
              definition: "To be dropped or uttered carelessly.",
              synonyms: [],
              antonyms: [],
              example: "An unguarded expression fell from his lips.",
            },
            {
              definition:
                "(of a fabric) To hang down (under the influence of gravity).",
              synonyms: [],
              antonyms: [],
              example:
                "An Empire-style dress has a high waistline – directly under the bust – from which the dress falls all the way to a hem as low as the floor.",
            },
          ],
          synonyms: [
            "responsibility",
            "up to",
            "become",
            "get",
            "come down",
            "descend",
            "drop",
            "drop",
            "lower",
            "prostrate",
            "die",
            "drop",
            "plummet",
            "plunge",
            "beaten",
            "defeated",
            "overthrown",
            "smitten",
            "vanquished",
            "cut down",
            "fell",
            "knock down",
            "knock over",
            "strike down",
            "dip",
            "drop",
          ],
          antonyms: [
            "ascend",
            "go",
            "rise",
            "get up",
            "pick oneself up",
            "stand up",
            "beat",
            "defeat",
            "overthrow",
            "smite",
            "vanquish",
            "rise",
          ],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The chasing of a hunted whale.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A waterfall.",
              synonyms: [],
              antonyms: [],
              example: "Niagara Falls",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fall",
        "https://en.wiktionary.org/wiki/falls",
      ],
    },
  ],
  cloud: [
    {
      word: "cloud",
      phonetic: "/klaʊd/",
      phonetics: [
        {
          text: "/klaʊd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cloud-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9014337",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/klaʊd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cloud-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=840635",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A rock; boulder; a hill.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A visible mass of water droplets suspended in the air.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any mass of dust, steam or smoke resembling such a mass.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Anything which makes things foggy or gloomy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Anything unsubstantial.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A dark spot on a lighter material or background.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A group or swarm, especially suspended above the ground or flying.",
              synonyms: [],
              antonyms: [],
              example: "He opened the door and was greeted by a cloud of bats.",
            },
            {
              definition:
                "An elliptical shape or symbol whose outline is a series of semicircles, supposed to resemble a cloud.",
              synonyms: [],
              antonyms: [],
              example:
                "The comic-book character's thoughts appeared in a cloud above his head.",
            },
            {
              definition:
                '(with "the") The Internet, regarded as an abstract amorphous omnipresent space for processing and storage, the focus of cloud computing.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A negative or foreboding aspect of something positive: see every cloud has a silver lining or every silver lining has a cloud.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Crystal methamphetamine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A large, loosely-knitted headscarf worn by women.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To become foggy or gloomy, or obscured from sight.",
              synonyms: [],
              antonyms: [],
              example: "The glass clouds when you breathe on it.",
            },
            {
              definition: "To overspread or hide with a cloud or clouds.",
              synonyms: [],
              antonyms: [],
              example: "The sky is clouded.",
            },
            {
              definition: "To make obscure.",
              synonyms: [],
              antonyms: [],
              example:
                "All this talk about human rights is clouding the real issue.",
            },
            {
              definition: "To make less acute or perceptive.",
              synonyms: [],
              antonyms: [],
              example: "The tears began to well up and cloud my vision.",
            },
            {
              definition: "To make gloomy or sullen.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To blacken; to sully; to stain; to tarnish (reputation or character).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To mark with, or darken in, veins or sports; to variegate with colors.",
              synonyms: [],
              antonyms: [],
              example: "to cloud yarn",
            },
            {
              definition:
                "To become marked, darkened or variegated in this way.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cloud"],
    },
  ],
  wheat: [
    {
      word: "wheat",
      phonetic: "/wiːt/",
      phonetics: [
        {
          text: "/wiːt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wheat-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9028961",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/wiːt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wheat-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1770097",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of several cereal grains, of the genus Triticum, that yields flour as used in bakery.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A light brown colour, like that of wheat.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Wheaten, of a light brown colour, like that of wheat.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/wheat"],
    },
  ],
  plays: [
    {
      word: "plays",
      phonetic: "/pleɪz/",
      phonetics: [
        {
          text: "/pleɪz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/plays-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100487",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Activity for amusement only, especially among the young.",
              synonyms: [],
              antonyms: [],
              example: "Children learn through play.",
            },
            {
              definition:
                "Similar activity in young animals, as they explore their environment and learn new skills.",
              synonyms: [],
              antonyms: [],
              example:
                "This kind of play helps the young lion cubs develop their hunting skills.",
            },
            {
              definition: "The conduct, or course, of a game.",
              synonyms: [],
              antonyms: [],
              example: "After the rain break, play resumed at 3 o'clock.",
            },
            {
              definition: "An individual's performance in a sport or game.",
              synonyms: [],
              antonyms: [],
              example: "His play has improved a lot this season.",
            },
            {
              definition: "A short sequence of action within a game.",
              synonyms: [],
              antonyms: [],
              example:
                "That was a great play by the Mudchester Rovers forward.",
            },
            {
              definition:
                "(turn-based games) An action carried out when it is one's turn to play.",
              synonyms: ["move"],
              antonyms: [],
            },
            {
              definition:
                "A literary composition, intended to be represented by actors impersonating the characters and speaking the dialogue.",
              synonyms: ["drama"],
              antonyms: [],
              example: "This book contains all of Shakespeare's plays.",
            },
            {
              definition: "A theatrical performance featuring actors.",
              synonyms: [],
              antonyms: [],
              example: "We saw a two-act play in the theatre.",
            },
            {
              definition: "A major move by a business or investor.",
              synonyms: [],
              antonyms: [],
              example:
                "ABC Widgets makes a play in the bicycle market with its bid to take over Acme Sprockets.",
            },
            {
              definition:
                "A geological formation that contains an accumulation or prospect of hydrocarbons or other resources.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The extent to which a part of a mechanism can move freely.",
              synonyms: [],
              antonyms: [],
              example:
                "No wonder the fanbelt is slipping: there’s too much play in it.",
            },
            {
              definition: "Sexual activity or sexual role-playing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An instance of watching or listening to digital media.",
              synonyms: ["listen", "view"],
              antonyms: [],
            },
            {
              definition:
                "A button that, when pressed, causes media to be played.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(now usually in compounds) Activity relating to martial combat or fighting.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["drama", "listen", "view", "move"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To act in a manner such that one has fun; to engage in activities expressly for the purpose of recreation or entertainment.",
              synonyms: [],
              antonyms: [],
              example: "They played long and hard.",
            },
            {
              definition:
                "To perform in (a sport); to participate in (a game).",
              synonyms: [],
              antonyms: [],
              example: "He plays on three teams",
            },
            {
              definition: "To take part in amorous activity; to make love.",
              synonyms: ["get it on", "have sex", "make out"],
              antonyms: [],
            },
            {
              definition:
                "To act as the indicated role, especially in a performance.",
              synonyms: [],
              antonyms: [],
              example: "He plays the King, and she's the Queen.",
            },
            {
              definition: "(heading) To produce music or theatre.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(heading) To behave in a particular way.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To move in any manner; especially, to move regularly with alternate or reciprocating motion; to operate.",
              synonyms: [],
              antonyms: [],
              example: "He played the torch beam around the room.",
            },
            { definition: "To move to and fro.", synonyms: [], antonyms: [] },
            {
              definition: "To put in action or motion.",
              synonyms: [],
              antonyms: [],
              example: "to play a trump in a card game",
            },
            {
              definition:
                "To keep in play, as a hooked fish in order to land it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To manipulate, deceive, or swindle someone.",
              synonyms: ["defraud"],
              antonyms: [],
              example: "You played me!",
            },
          ],
          synonyms: ["defraud", "get it on", "have sex", "make out"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/play",
        "https://en.wiktionary.org/wiki/plays",
      ],
    },
  ],
  enter: [
    {
      word: "enter",
      phonetic: "/ˈɛntə(ɹ)/",
      phonetics: [
        {
          text: "/ˈɛntə(ɹ)/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/enter-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9028010",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ˈɛntɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/enter-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=267009",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: 'The "Enter" key on a computer keyboard.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A stroke of the Enter key.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To go or come into an enclosed or partially enclosed space.",
              synonyms: [],
              antonyms: [],
              example:
                "You should knock before you enter, unless you want to see me naked.",
            },
            {
              definition:
                "To cause to go (into), or to be received (into); to put in; to insert; to cause to be admitted.",
              synonyms: [],
              antonyms: [],
              example:
                "to enter a knife into a piece of wood;  to enter a boy at college, a horse for a race, etc.",
            },
            {
              definition: "To go or come into (a state or profession).",
              synonyms: [],
              antonyms: [],
              example:
                "My twelve-year-old son will be entering his teens next year.  She had planned to enter the legal profession.",
            },
            {
              definition: "To type (something) into a computer; to input.",
              synonyms: [],
              antonyms: [],
              example: "Enter your user name and password.",
            },
            {
              definition: "To record (something) in an account, ledger, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To become a party to an agreement, treaty, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To become effective; to come into effect.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To go into or upon, as lands, and take actual possession of them.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To place in regular form before the court, usually in writing; to put upon record in proper from and order.",
              synonyms: [],
              antonyms: [],
              example: "to enter a writ, appearance, rule, or judgment",
            },
            {
              definition:
                "To make report of (a vessel or its cargo) at the custom house; to submit a statement of (imported goods), with the original invoices, to the proper customs officer for estimating the duties. See entry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To file, or register with the land office, the required particulars concerning (a quantity of public land) in order to entitle a person to a right of preemption.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To deposit for copyright the title or description of (a book, picture, map, etc.).",
              synonyms: [],
              antonyms: [],
              example: "entered according to act of Congress",
            },
            {
              definition: "To initiate; to introduce favourably.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["come in", "go in", "ingo"],
          antonyms: ["exit"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/Enter",
        "https://en.wiktionary.org/wiki/enter",
      ],
    },
  ],
  broad: [
    {
      word: "broad",
      phonetic: "/bɹɑd/",
      phonetics: [
        { text: "/bɹɑd/", audio: "" },
        { text: "/bɹɔːd/", audio: "" },
        {
          text: "/bɹɔd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/broad-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1250064",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A shallow lake, one of a number of bodies of water in eastern Norfolk and Suffolk.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A lathe tool for turning down the insides and bottoms of cylinders.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A British gold coin worth 20 shillings, issued by the Commonwealth of England in 1656.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Wide in extent or scope.",
              synonyms: [],
              antonyms: [],
              example: "the broad expanse of ocean",
            },
            {
              definition:
                "Extended, in the sense of diffused; open; clear; full.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Having a large measure of any thing or quality; unlimited; unrestrained.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Comprehensive; liberal; enlarged.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Plain; evident.",
              synonyms: [],
              antonyms: [],
              example: "a broad hint",
            },
            {
              definition: "(writing) Unsubtle; obvious.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Free; unrestrained; unconfined.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Gross; coarse; indelicate.",
              synonyms: [],
              antonyms: [],
              example: "a broad compliment; a broad joke; broad humour",
            },
            {
              definition: "(of an accent) Strongly regional.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(Gaelic languages) Velarized, i.e. not palatalized.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["all-encompassing", "slender", "skinny", "narrow", "thin"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/broad"],
    },
    {
      word: "broad",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A prostitute, a woman of loose morals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(sometimes obsolete) A woman or girl.",
              synonyms: [],
              antonyms: [],
              example: "Who was that broad I saw you with?",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/broad"],
    },
  ],
  steam: [
    {
      word: "steam",
      phonetic: "/stiːm/",
      phonetics: [
        {
          text: "/stiːm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/steam-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1158034",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The vapor formed when water changes from liquid phase to gas phase.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Pressurized water vapour used for heating, cooking, or to provide mechanical energy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Internal energy for motive power.",
              synonyms: [],
              antonyms: [],
              example:
                "After three weeks in bed he was finally able to sit up under his own steam.",
            },
            {
              definition: "Pent-up anger.",
              synonyms: [],
              antonyms: [],
              example: "Dad had to go outside to blow off some steam.",
            },
            {
              definition: "A steam-powered vehicle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Travel by means of a steam-powered vehicle.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Any exhalation.", synonyms: [], antonyms: [] },
            {
              definition: "Fencing without the use of any electric equipment.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["steamer"],
          antonyms: ["electric"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cook with steam.",
              synonyms: [],
              antonyms: [],
              example: "The best way to cook artichokes is to steam them.",
            },
            {
              definition:
                "To expose to the action of steam; to apply steam to for softening, dressing, or preparing.",
              synonyms: [],
              antonyms: [],
              example: "to steam wood or cloth",
            },
            {
              definition: "To produce or vent steam.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To rise in vapour; to issue, or pass off, as vapour.",
              synonyms: [],
              antonyms: [],
              example: "Our breath steamed in the cold winter air.",
            },
            {
              definition: "To become angry; to fume; to be incensed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make angry.",
              synonyms: [],
              antonyms: [],
              example: "It really steams me to see her treat him like that.",
            },
            {
              definition: "To be covered with condensed water vapor.",
              synonyms: [],
              antonyms: [],
              example:
                "With all the heavy breathing going on the windows were quickly steamed in the car.",
            },
            {
              definition: "To travel by means of steam power.",
              synonyms: [],
              antonyms: [],
              example: "The ship steamed out of the harbour",
            },
            {
              definition: "To move with great or excessive purposefulness.",
              synonyms: [],
              antonyms: [],
              example:
                "If he heard of anyone picking the fruit he would steam off and lecture them.",
            },
            { definition: "To exhale.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Old-fashioned; from before the digital age.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/steam"],
    },
  ],
  atoms: [
    {
      word: "atoms",
      phonetic: "/ˈatəmz/",
      phonetics: [{ text: "/ˈatəmz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The smallest possible amount of matter which still retains its identity as a chemical element, now known to consist of a nucleus surrounded by electrons.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(history of science) A hypothetical particle posited by Greek philosophers as an ultimate and indivisible component of matter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The smallest, indivisible constituent part or unit of something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In logical atomism, a fundamental fact that cannot be further broken down.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The smallest medieval unit of time, equal to fifteen ninety-fourths of a second.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A mote of dust in a sunbeam.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A very small amount; a whit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Lisp) An individual number or symbol, as opposed to a list; a scalar value.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A non-zero member of a Boolean algebra that is not a union of any other elements. Or, a non-zero member of a Boolean lattice that has only zero below it.",
              synonyms: [],
              antonyms: [],
              example:
                "In a Venn diagram, an atom is depicted as an area circumscribed by lines but not cut by any line.",
            },
            {
              definition:
                "An element of a set that is not itself a set; an urelement.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                '(usually capitalised as "Atom") A member of an age group division in hockey for ten- to 11-year-olds.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/atom",
        "https://en.wiktionary.org/wiki/atoms",
      ],
    },
  ],
  press: [
    {
      word: "press",
      phonetic: "/pɹɛs/",
      phonetics: [
        {
          text: "/pɹɛs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/press-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9023317",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/pɹɛs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/press-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1684689",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A device used to apply pressure to an item.",
              synonyms: [],
              antonyms: [],
              example: "a flower press",
            },
            {
              definition: "A printing machine.",
              synonyms: ["printing press"],
              antonyms: [],
              example: "Stop the presses!",
            },
            {
              definition:
                "(collective) The print-based media (both the people and the newspapers).",
              synonyms: [],
              antonyms: [],
              example: "This article appeared in the press.",
            },
            { definition: "A publisher.", synonyms: [], antonyms: [] },
            {
              definition: "An enclosed storage space (e.g. closet, cupboard).",
              synonyms: [],
              antonyms: [],
              example: "Put the cups in the press.",
            },
            {
              definition:
                "An exercise in which weight is forced away from the body by extension of the arms or legs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(wagering) An additional bet in a golf match that duplicates an existing (usually losing) wager in value, but begins even at the time of the bet.",
              synonyms: [],
              antonyms: [],
              example: "He can even the match with a press.",
            },
            {
              definition: "Pure, unfermented grape juice.",
              synonyms: [],
              antonyms: [],
              example: "I would like some Concord press with my meal tonight.",
            },
            {
              definition:
                "A commission to force men into public service, particularly into the navy.",
              synonyms: ["press-gang"],
              antonyms: [],
            },
            { definition: "A crowd.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "closet",
            "cupboard",
            "pantry",
            "press-gang",
            "printing press",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/press"],
    },
    {
      word: "press",
      phonetic: "/pɹɛs/",
      phonetics: [
        {
          text: "/pɹɛs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/press-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9023317",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/pɹɛs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/press-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1684689",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To exert weight or force against, to act upon with force or weight; to exert pressure upon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To activate a button or key by exerting a downward or forward force on it, and then releasing it.",
              synonyms: ["depress", "hit", "strike"],
              antonyms: [],
            },
            {
              definition: "To compress, squeeze.",
              synonyms: ["thring", "thrutch"],
              antonyms: [],
              example: "to press fruit for the purpose of extracting the juice",
            },
            {
              definition: "To clasp, hold in an embrace.",
              synonyms: ["hug"],
              antonyms: [],
            },
            {
              definition:
                "To reduce to a particular shape or form by pressure, especially flatten or smooth.",
              synonyms: [],
              antonyms: [],
              example: "to press a hat",
            },
            {
              definition:
                "To flatten a selected area of fabric using an iron with an up-and-down, not sliding, motion, so as to avoid disturbing adjacent areas.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To drive or thrust by pressure, to force in a certain direction.",
              synonyms: ["thring", "thrutch"],
              antonyms: [],
              example: "to press a crowd back",
            },
            {
              definition: "To weigh upon, oppress, trouble.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To force to a certain end or result; to urge strongly.",
              synonyms: ["impel"],
              antonyms: [],
            },
            {
              definition: "To try to force (something upon someone).",
              synonyms: ["inculcate", "urge"],
              antonyms: [],
              example: "to press the Bible on an audience",
            },
            {
              definition: "To hasten, urge onward.",
              synonyms: [],
              antonyms: [],
              example: "to press a horse in a race",
            },
            {
              definition: "To urge, beseech, entreat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To lay stress upon.",
              synonyms: ["emphasize"],
              antonyms: [],
            },
            {
              definition: "To throng, crowd.",
              synonyms: ["thring", "thrutch"],
              antonyms: [],
            },
            { definition: "To print.", synonyms: [], antonyms: [] },
            {
              definition:
                "To force into service, particularly into naval service.",
              synonyms: ["press-gang"],
              antonyms: [],
            },
          ],
          synonyms: [
            "depress",
            "hit",
            "strike",
            "emphasize",
            "hug",
            "impel",
            "inculcate",
            "urge",
            "press-gang",
            "thring",
            "thrutch",
            "thring",
            "thrutch",
            "thring",
            "thrutch",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/press"],
    },
  ],
  lying: [
    {
      word: "lying",
      phonetic: "/ˈlaɪ.ɪŋ/",
      phonetics: [
        { text: "/ˈlaɪ.ɪŋ/", audio: "" },
        {
          text: "/ˈlaɪ.ɪŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lying-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1230393",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To rest in a horizontal position on a surface.",
              synonyms: [],
              antonyms: [],
              example:
                "The book lies on the table;  the snow lies on the roof;  he lies in his coffin",
            },
            {
              definition: "To be placed or situated.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To abide; to remain for a longer or shorter time; to be in a certain state or condition.",
              synonyms: [],
              antonyms: [],
              example: "The paper does not lie smooth on the wall.",
            },
            {
              definition:
                "Used with in: to be or exist; to belong or pertain; to have an abiding place; to consist.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Used with with: to have sexual relations with.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Used with on/upon: to be incumbent (on); to be the responsibility of a person.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To lodge; to sleep.", synonyms: [], antonyms: [] },
            {
              definition: "To be still or quiet, like one lying down to rest.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be sustainable; to be capable of being maintained.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To give false information intentionally with intent to deceive.",
              synonyms: [],
              antonyms: [],
              example:
                "If you are found to have lied in court, you could face a penalty.",
            },
            {
              definition: "To convey a false image or impression.",
              synonyms: [],
              antonyms: [],
              example: "Photographs often lie.",
            },
            {
              definition:
                "To be mistaken or unintentionally spread false information.",
              synonyms: [],
              antonyms: [],
              example:
                "Sorry, I haven't seen your keys anywhere...wait, I lied! They're right there on the coffee table.",
            },
          ],
          synonyms: ["prevaricate"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The act of one who lies, or keeps low to the ground.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lie",
        "https://en.wiktionary.org/wiki/lying",
      ],
    },
    {
      word: "lying",
      phonetic: "/ˈlaɪ.ɪŋ/",
      phonetics: [
        { text: "/ˈlaɪ.ɪŋ/", audio: "" },
        {
          text: "/ˈlaɪ.ɪŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lying-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1230393",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To rest in a horizontal position on a surface.",
              synonyms: [],
              antonyms: [],
              example:
                "The book lies on the table;  the snow lies on the roof;  he lies in his coffin",
            },
            {
              definition: "To be placed or situated.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To abide; to remain for a longer or shorter time; to be in a certain state or condition.",
              synonyms: [],
              antonyms: [],
              example: "The paper does not lie smooth on the wall.",
            },
            {
              definition:
                "Used with in: to be or exist; to belong or pertain; to have an abiding place; to consist.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Used with with: to have sexual relations with.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Used with on/upon: to be incumbent (on); to be the responsibility of a person.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To lodge; to sleep.", synonyms: [], antonyms: [] },
            {
              definition: "To be still or quiet, like one lying down to rest.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be sustainable; to be capable of being maintained.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To give false information intentionally with intent to deceive.",
              synonyms: [],
              antonyms: [],
              example:
                "If you are found to have lied in court, you could face a penalty.",
            },
            {
              definition: "To convey a false image or impression.",
              synonyms: [],
              antonyms: [],
              example: "Photographs often lie.",
            },
            {
              definition:
                "To be mistaken or unintentionally spread false information.",
              synonyms: [],
              antonyms: [],
              example:
                "Sorry, I haven't seen your keys anywhere...wait, I lied! They're right there on the coffee table.",
            },
          ],
          synonyms: ["prevaricate"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An act of telling a lie or falsehood.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lie",
        "https://en.wiktionary.org/wiki/lying",
      ],
    },
  ],
  basis: [
    {
      word: "basis",
      phonetic: "/ˈbeɪsɪs/",
      phonetics: [
        { text: "/ˈbeɪsɪs/", audio: "" },
        {
          text: "/ˈbeɪsɪs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/basis-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100334",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A physical base or foundation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A starting point, base or foundation for an argument or hypothesis.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An underlying condition or circumstance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A regular frequency.",
              synonyms: [],
              antonyms: [],
              example: "Cars must be checked on a yearly basis.",
            },
            {
              definition:
                "In a vector space, a linearly independent set of vectors spanning the whole vector space.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Amount paid for an investment, including commissions and other expenses.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'A collection of subsets ("basis elements") of a set, such that this collection covers the set, and for any two basis elements which both contain an element of the set, there is a third basis element contained in the intersection of the first two, which also contains that element.',
              synonyms: [],
              antonyms: [],
              example:
                "The collection of all possible unions of basis elements of a basis is said to be the topology generated by that basis.",
            },
          ],
          synonyms: ["base"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/basis"],
    },
  ],
  clock: [
    {
      word: "clock",
      phonetic: "/klɒk/",
      phonetics: [
        {
          text: "/klɒk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/clock-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=68569023",
          license: {
            name: "BY 4.0",
            url: "https://creativecommons.org/licenses/by/4.0",
          },
        },
        {
          text: "/klɑk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/clock-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=840632",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An instrument used to measure or keep track of time; a non-portable timepiece.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The odometer of a motor vehicle.",
              synonyms: [],
              antonyms: [],
              example: "This car has over 300,000 miles on the clock.",
            },
            {
              definition:
                "An electrical signal that synchronizes timing among digital circuits of semiconductor chips or modules.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The seed head of a dandelion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A time clock.",
              synonyms: [],
              antonyms: [],
              example: "I can't go off to lunch yet: I'm still on the clock.",
            },
            {
              definition: "A CPU clock cycle, or T-state.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["timepiece", "odometer"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To measure the duration of.",
              synonyms: ["time"],
              antonyms: [],
            },
            {
              definition: "To measure the speed of.",
              synonyms: [],
              antonyms: [],
              example: "He was clocked at 155 miles per hour.",
            },
            {
              definition: "To hit (someone) heavily.",
              synonyms: ["slug", "smack", "thump", "whack"],
              antonyms: [],
              example:
                "When the boxer let down his guard, his opponent clocked him.",
            },
            {
              definition:
                "To take notice of; to realise; to recognize someone or something",
              synonyms: ["check out", "scope out"],
              antonyms: [],
              example:
                "A trans person may be able to easily clock other trans people.",
            },
            {
              definition:
                "To falsify the reading of the odometer of a vehicle.",
              synonyms: [
                "turn back (the vehicle's) clock",
                "wind back (the vehicle's) clock",
              ],
              antonyms: [],
              example:
                "I don't believe that car has done only 40,000 miles. It's been clocked.",
            },
            {
              definition: "To beat a video game.",
              synonyms: [],
              antonyms: [],
              example: "Have you clocked that game yet?",
            },
          ],
          synonyms: [
            "check out",
            "scope out",
            "slug",
            "smack",
            "thump",
            "whack",
            "time",
            "turn back (the vehicle's) clock",
            "wind back (the vehicle's) clock",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/clock"],
    },
    {
      word: "clock",
      phonetic: "/klɒk/",
      phonetics: [
        {
          text: "/klɒk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/clock-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=68569023",
          license: {
            name: "BY 4.0",
            url: "https://creativecommons.org/licenses/by/4.0",
          },
        },
        {
          text: "/klɑk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/clock-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=840632",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A pattern near the heel of a sock or stocking.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To ornament (e.g. the side of a stocking) with figured work.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/clock"],
    },
    {
      word: "clock",
      phonetic: "/klɒk/",
      phonetics: [
        {
          text: "/klɒk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/clock-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=68569023",
          license: {
            name: "BY 4.0",
            url: "https://creativecommons.org/licenses/by/4.0",
          },
        },
        {
          text: "/klɑk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/clock-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=840632",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A large beetle, especially the European dung beetle (Geotrupes stercorarius).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/clock"],
    },
    {
      word: "clock",
      phonetic: "/klɒk/",
      phonetics: [
        {
          text: "/klɒk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/clock-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=68569023",
          license: {
            name: "BY 4.0",
            url: "https://creativecommons.org/licenses/by/4.0",
          },
        },
        {
          text: "/klɑk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/clock-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=840632",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make the sound of a hen; to cluck.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To hatch.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/clock"],
    },
  ],
  taste: [
    {
      word: "taste",
      phonetic: "/teɪst/",
      phonetics: [
        {
          text: "/teɪst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/taste-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1197414",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One of the sensations produced by the tongue in response to certain chemicals; the quality of giving this sensation.",
              synonyms: [],
              antonyms: [],
              example: "He had a strange taste in his mouth.",
            },
            {
              definition:
                "The sense that consists in the perception and interpretation of this sensation.",
              synonyms: [],
              antonyms: [],
              example: "His taste was impaired by an illness.",
            },
            {
              definition:
                "A small sample of food, drink, or recreational drugs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person's implicit set of preferences, especially esthetic, though also culinary, sartorial, etc.",
              synonyms: [],
              antonyms: [],
              example: "Dr. Parker has good taste in wine.",
            },
            {
              definition: "Personal preference; liking; predilection.",
              synonyms: [],
              antonyms: [],
              example: "I have developed a taste for fine wine.",
            },
            {
              definition:
                "A small amount of experience with something that gives a sense of its quality as a whole.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A kind of narrow and thin silk ribbon.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "smack",
            "smatch",
            "culture",
            "discernment",
            "refinement",
            "style",
            "impression",
            "sample",
            "trial",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To sample the flavor of something orally.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To have a taste; to excite a particular sensation by which flavour is distinguished.",
              synonyms: [],
              antonyms: [],
              example:
                "The chicken tasted great, but the milk tasted like garlic.",
            },
            {
              definition: "To experience.",
              synonyms: [],
              antonyms: [],
              example: "I tasted in her arms the delights of paradise.",
            },
            { definition: "To take sparingly.", synonyms: [], antonyms: [] },
            {
              definition:
                "To try by eating a little; to eat a small quantity of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To try by the touch; to handle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["hint", "smack", "smack", "smake"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/taste"],
    },
  ],
  grows: [
    {
      word: "grows",
      phonetic: "/ɡɹəʊz/",
      phonetics: [
        { text: "/ɡɹəʊz/", audio: "" },
        {
          text: "/ɡɹoʊz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grows-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100439",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To become larger, to increase in magnitude.",
              synonyms: [],
              antonyms: [],
              example: "Children grow quickly.",
            },
            {
              definition: "To appear or sprout.",
              synonyms: [],
              antonyms: [],
              example: "A long tail began to grow from his backside.",
            },
            {
              definition: "To develop, to mature.",
              synonyms: [],
              antonyms: [],
              example:
                "As I grew throughout adolescence, I came to appreciate many things about human nature.",
            },
            {
              definition:
                "To cause or allow something to become bigger, especially to cultivate plants.",
              synonyms: [],
              antonyms: [],
              example: "Have you ever grown your hair before?",
            },
            {
              definition: "To assume a condition or quality over time.",
              synonyms: [],
              antonyms: [],
              example: "The boy grew wise as he matured.",
            },
            {
              definition: "To become attached or fixed; to adhere.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["shrink"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/grow",
        "https://en.wiktionary.org/wiki/grows",
      ],
    },
  ],
  thank: [
    {
      word: "thank",
      phonetic: "[θaŋk]",
      phonetics: [
        { text: "[θaŋk]", audio: "" },
        {
          text: "[t̪eɪ̯ŋk]",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/thank-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1217989",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An expression of appreciation; a thought.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/thank"],
    },
    {
      word: "thank",
      phonetic: "[θaŋk]",
      phonetics: [
        { text: "[θaŋk]", audio: "" },
        {
          text: "[t̪eɪ̯ŋk]",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/thank-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1217989",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To express gratitude or appreciation toward.",
              synonyms: [],
              antonyms: [],
              example: "She thanked him for the lift.",
            },
            {
              definition: "To feel gratitude or appreciation toward.",
              synonyms: [],
              antonyms: [],
              example: "I'll thank you not to smoke in my house!",
            },
            {
              definition: "To credit or hold responsible.",
              synonyms: [],
              antonyms: [],
              example: "We can thank global warming for this weather.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/thank"],
    },
  ],
  storm: [
    {
      word: "storm",
      phonetic: "/stɔːm/",
      phonetics: [
        { text: "/stɔːm/", audio: "" },
        {
          text: "/stɔɹm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/storm-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1661710",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any disturbed state of the atmosphere, especially as affecting the earth's surface, and strongly implying destructive or unpleasant weather.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A violent agitation of human society; a civil, political, or domestic commotion; violent outbreak.",
              synonyms: [],
              antonyms: [],
              example: "The proposed reforms have led to a political storm.",
            },
            {
              definition:
                "A wind scale for very strong wind, stronger than a gale, less than a hurricane (10 or higher on the Beaufort scale).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A violent assault on a stronghold or fortified position.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/storm"],
    },
    {
      word: "storm",
      phonetic: "/stɔːm/",
      phonetics: [
        { text: "/stɔːm/", audio: "" },
        {
          text: "/stɔɹm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/storm-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1661710",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(with adverbial of direction) To move quickly and noisily like a storm, usually in a state of uproar or anger.",
              synonyms: [],
              antonyms: [],
              example: "She stormed out of the room.",
            },
            {
              definition: "To rage or fume; to be in a violent temper.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To assault (a stronghold or fortification) with military forces.",
              synonyms: [],
              antonyms: [],
              example: "Troops stormed the complex.",
            },
            {
              definition:
                "To have the weather be violent, with strong winds and usually rain, thunder, lightning, or snow.",
              synonyms: [],
              antonyms: [],
              example: "It stormed throughout the night.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/storm"],
    },
  ],
  agree: [
    {
      word: "agree",
      phonetic: "/əˈɡɹi/",
      phonetics: [
        {
          text: "/əˈɡɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/agree-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1234347",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To harmonize in opinion, statement, or action; to be in unison or concord; to be or become united or consistent; to concur.",
              synonyms: [],
              antonyms: [],
              example: "all parties agree in the expediency of the law.",
            },
            {
              definition: "To yield assent; to accede;—followed by to.",
              synonyms: [],
              antonyms: [],
              example: "to agree to an offer, or to opinion.",
            },
            {
              definition: "To yield assent to; to approve.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make a stipulation by way of settling differences or determining a price; to exchange promises; to come to terms or to a common resolve; to promise.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be conformable; to resemble; to coincide; to correspond.",
              synonyms: [],
              antonyms: [],
              example:
                "the picture does not agree with the original; the two scales agree exactly.",
            },
            {
              definition:
                "(now always with with) To suit or be adapted in its effects; to do well.",
              synonyms: [],
              antonyms: [],
              example: "the same food does not agree with every constitution.",
            },
            {
              definition:
                "(grammar) To correspond to (another word) in a grammatical category, such as gender, number, case, or person.",
              synonyms: [],
              antonyms: [],
              example:
                "In Romanian, the articles, adjectives, pronouns agree in gender, number and case with the noun they refer to.",
            },
            {
              definition:
                "To consent to a contract or to an element of a contract.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "coincide",
            "correspond",
            "match",
            "resemble",
            "bargain",
            "deal",
            "engage",
            "fit",
            "suit",
            "concur",
            "harmonize",
            "accede",
            "come around",
            "give way",
            "approve",
            "set",
          ],
          antonyms: ["disagree"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/agree"],
    },
  ],
  brain: [
    {
      word: "brain",
      phonetic: "/bɹeɪn/",
      phonetics: [
        {
          text: "/bɹeɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/brain-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88518392",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/bɹeɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/brain-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=840300",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The control center of the central nervous system of an animal located in the skull which is responsible for perception, cognition, attention, memory, emotion, and action.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An intelligent person.",
              synonyms: [],
              antonyms: [],
              example: "She was a total brain.",
            },
            {
              definition: "(in the plural) Intellect.",
              synonyms: [],
              antonyms: [],
              example: "She has a lot of brains.",
            },
            {
              definition:
                "By analogy with a human brain, the part of a machine or computer that performs calculations.",
              synonyms: [],
              antonyms: [],
              example:
                "The computer's brain is capable of millions of calculations a second.",
            },
            { definition: "Oral sex.", synonyms: [], antonyms: [] },
            {
              definition: "Mind.",
              synonyms: [],
              antonyms: [],
              example: "I have too much on my brain today.",
            },
          ],
          synonyms: ["harns"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To dash out the brains of; to kill by smashing the skull.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To strike (someone) on the head.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To destroy; to put an end to.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To conceive in the mind; to understand.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/brain"],
    },
  ],
  track: [
    {
      word: "track",
      phonetic: "/tɹæk/",
      phonetics: [
        {
          text: "/tɹæk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/track-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899481",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/tɹæk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/track-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2079770",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A mark left by something that has passed along.",
              synonyms: ["trace", "trail", "wake"],
              antonyms: [],
              example: "Can you see any tracks in the snow?",
            },
            {
              definition:
                "A mark or impression left by the foot, either of man or animal.",
              synonyms: ["footprint", "impression"],
              antonyms: [],
              example: "The fox tracks were still visible in the snow.",
            },
            {
              definition:
                "The entire lower surface of the foot; said of birds, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A road or other similar beaten path.",
              synonyms: ["path", "road", "way"],
              antonyms: [],
              example: "Follow the track for a hundred metres.",
            },
            {
              definition: "Physical course; way.",
              synonyms: ["course", "path", "trajectory", "way"],
              antonyms: [],
              example: "Astronomers predicted the track of the comet.",
            },
            {
              definition:
                "A path or course laid out for a race, for exercise, etc.",
              synonyms: ["course", "racetrack"],
              antonyms: [],
              example: "The athletes ran round the track.",
            },
            {
              definition:
                "The direction and progress of someone or something; path.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The way or rails along which a train moves.",
              synonyms: ["rails", "railway", "tracks", "train tracks"],
              antonyms: [],
              example:
                "They briefly closed the railway to remove debris found on the track.",
            },
            {
              definition: "A tract or area, such as of land.",
              synonyms: ["area", "parcel", "region", "tract"],
              antonyms: [],
            },
            {
              definition: "The street, as a prostitute's place of work.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Awareness of something, especially when arising from close monitoring.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The distance between two opposite wheels on a same axletree.",
              synonyms: ["track width"],
              antonyms: [],
            },
            {
              definition: "Short for caterpillar track.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The pitch.",
              synonyms: ["ground", "pitch"],
              antonyms: [],
            },
            {
              definition: "Sound stored on a record.",
              synonyms: ["recording"],
              antonyms: [],
            },
            {
              definition: "The physical track on a record.",
              synonyms: ["groove"],
              antonyms: [],
            },
            {
              definition:
                "A song or other relatively short piece of music, on a record, separated from others by a short silence.",
              synonyms: [],
              antonyms: [],
              example: 'My favourite track on the album is "Sunshine".',
            },
            {
              definition:
                "A circular (never-ending) data storage unit on a side of magnetic or optical disk, divided into sectors.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The racing events of track and field; track and field in general.",
              synonyms: ["athletics", "track and field"],
              antonyms: [],
              example: "I'm going to try out for track next week.",
            },
            {
              definition: "A session talk on a conference.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "area",
            "parcel",
            "region",
            "tract",
            "athletics",
            "track and field",
            "course",
            "path",
            "trajectory",
            "way",
            "course",
            "racetrack",
            "footprint",
            "impression",
            "groove",
            "ground",
            "pitch",
            "path",
            "road",
            "way",
            "rails",
            "railway",
            "tracks",
            "train tracks",
            "recording",
            "trace",
            "trail",
            "wake",
            "track width",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To continue over time.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To follow the tracks of.",
              synonyms: [],
              antonyms: [],
              example:
                "My uncle spent all day tracking the deer, whose hoofprints were clear in the mud.",
            },
            { definition: "To make tracks on.", synonyms: [], antonyms: [] },
            {
              definition: "To create a musical recording (a track).",
              synonyms: [],
              antonyms: [],
              example: "Lil Kyle is gonna track with that DJ next week.",
            },
            {
              definition:
                "To make sense; to be consistent with known information",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "check out",
            "make sense",
            "find",
            "locate",
            "trace",
            "track down",
            "follow",
            "monitor",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/track"],
    },
  ],
  smile: [
    {
      word: "smile",
      phonetic: "/ˈsmaɪ.əl/",
      phonetics: [
        {
          text: "/ˈsmaɪ.əl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/smile-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1157745",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A facial expression comprised by flexing the muscles of both ends of one's mouth, often showing the front teeth, without vocalisation, and in humans is a common involuntary or voluntary expression of happiness, pleasure, amusement or anxiety.",
              synonyms: [],
              antonyms: [],
              example: "He always puts a smile on my face.",
            },
            {
              definition: "Favour; propitious regard.",
              synonyms: [],
              antonyms: [],
              example: "the smile of the gods",
            },
            {
              definition: "A drink bought by one person for another.",
              synonyms: ["treat"],
              antonyms: [],
            },
          ],
          synonyms: ["treat"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To have (a smile) on one's face.",
              synonyms: [],
              antonyms: [],
              example: "I don't know what he's smiling about.",
            },
            {
              definition: "To express by smiling.",
              synonyms: [],
              antonyms: [],
              example: "to smile consent, or a welcome",
            },
            {
              definition:
                "To express amusement, pleasure, or love and kindness.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To look cheerful and joyous; to have an appearance suited to excite joy.",
              synonyms: [],
              antonyms: [],
              example: "The sun smiled down from a clear summer sky.",
            },
            {
              definition: "To be propitious or favourable; to countenance.",
              synonyms: [],
              antonyms: [],
              example: "The gods smiled on his labours.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/smile"],
    },
  ],
  funny: [
    {
      word: "funny",
      phonetic: "/ˈfʊnɪ/",
      phonetics: [
        { text: "/ˈfʊnɪ/", audio: "" },
        {
          text: "/ˈfʌni/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/funny-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=91894736",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈfʌni/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/funny-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1229415",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A joke.", synonyms: [], antonyms: [] },
            { definition: "A comic strip.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Amusing; humorous; comical.",
              synonyms: [],
              antonyms: [],
              example:
                "When I went to the circus, I only found the clowns funny.",
            },
            {
              definition: "Strange or unusual, often implying unpleasant.",
              synonyms: [],
              antonyms: [],
              example:
                "I've got a funny feeling that this isn't going to work.",
            },
            {
              definition: "Showing unexpected resentment.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "In an unusual manner; strangely.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/funny"],
    },
    {
      word: "funny",
      phonetic: "/ˈfʊnɪ/",
      phonetics: [
        { text: "/ˈfʊnɪ/", audio: "" },
        {
          text: "/ˈfʌni/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/funny-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=91894736",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈfʌni/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/funny-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1229415",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A narrow clinker-built boat for sculling.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/funny"],
    },
  ],
  beach: [
    {
      word: "beach",
      phonetic: "/biːt͡ʃ/",
      phonetics: [
        {
          text: "/biːt͡ʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/beach-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9014275",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/bit͡ʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/beach-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1318130",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The shore of a body of water, especially when sandy or pebbly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A horizontal strip of land, usually sandy, adjoining water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The loose pebbles of the seashore, especially worn by waves; shingle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["backshore", "sand", "strand"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To run aground on a beach.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To run (something) aground on a beach.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a vehicle) To run into an obstacle or rough or soft ground, so that the floor of the vehicle rests on the ground and the wheels cannot gain traction.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["strand"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A gravel-filled zone on a racetrack, used as a hazard, exclusionary region, and, safety device to slow down and trap vehicles.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/beach",
        "https://en.wiktionary.org/wiki/gravel%20trap",
      ],
    },
  ],
  stock: [
    {
      word: "stock",
      phonetic: "/stɒk/",
      phonetics: [
        { text: "/stɒk/", audio: "" },
        {
          text: "/stɑk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stock-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1676870",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A store or supply.", synonyms: [], antonyms: [] },
            {
              definition:
                "The capital raised by a company through the issue of shares. The total of shares held by an individual shareholder.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The raw material from which things are made; feedstock.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Stock theater, summer stock theater.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The trunk and woody main stems of a tree. The base from which something grows or branches.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of the several species of cruciferous flowers in the genus Matthiola.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A handle or stem to which the working part of an implement or weapon is attached.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Part of a machine that supports items or holds them in place.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A bar, stick or rod.", synonyms: [], antonyms: [] },
            {
              definition: "A type of (now formal or official) neckwear.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A bed for infants; a crib, cot, or cradle",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of wood magically made to be just like a real baby and substituted for it by magical beings.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A cover for the legs; a stocking.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A block of wood; something fixed and solid; a pillar; a firm support; a post.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) A person who is as dull and lifeless as a stock or post; one who has little sense.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The longest part of a split tally stick formerly struck in the exchequer, which was delivered to the person who had lent the king money on account, as the evidence of indebtedness.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in the plural) The frame or timbers on which a ship rests during construction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in the plural) Red and grey bricks, used for the exterior of walls and the front of buildings.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In tectology, an aggregate or colony of individuals, such as as trees, chains of salpae, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The beater of a fulling mill.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "rudder stock",
            "livestock",
            "card stock",
            "rootstock",
            "understock",
            "rolling stock",
            "feedstock",
            "stock-tie",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To have on hand for sale.",
              synonyms: [],
              antonyms: [],
              example: "The store stocks all kinds of dried vegetables.",
            },
            {
              definition:
                "To provide with material requisites; to store; to fill; to supply.",
              synonyms: [],
              antonyms: [],
              example:
                "to stock a farm, i.e. to supply it with cattle and tools",
            },
            {
              definition:
                "To allow (cows) to retain milk for twenty-four hours or more prior to sale.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To put in the stocks as punishment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To fit (an anchor) with a stock, or to fasten the stock firmly in place.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To arrange cards in a certain manner for cheating purposes; to stack the deck.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of a type normally available for purchase/in stock.",
              synonyms: [],
              antonyms: [],
              example: "stock items",
            },
            {
              definition:
                "(racing, of a race car) Having the same configuration as cars sold to the non-racing public, or having been modified from such a car.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Straightforward, ordinary, just another, very basic.",
              synonyms: [],
              antonyms: [],
              example: "He gave me a stock answer",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stock"],
    },
    {
      word: "stock",
      phonetic: "/stɒk/",
      phonetics: [
        { text: "/stɒk/", audio: "" },
        {
          text: "/stɑk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stock-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1676870",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A thrust with a rapier; a stoccado.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stock"],
    },
  ],
  hurry: [
    {
      word: "hurry",
      phonetic: "/ˈhʌ.ɹi/",
      phonetics: [
        {
          text: "/ˈhʌ.ɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hurry-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1971136",
        },
        {
          text: "/ˈhʌ.ɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hurry-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=20075772",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Rushed action.",
              synonyms: [],
              antonyms: [],
              example: "Why are you in such a big hurry?",
            },
            {
              definition: "Urgency.",
              synonyms: [],
              antonyms: [],
              example: "There is no hurry on that paperwork.",
            },
            {
              definition:
                "An incidence of a defensive player forcing the quarterback to act faster than the quarterback was prepared to, resulting in a failed offensive play.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A tremolando passage for violins, etc., accompanying an exciting situation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To do things quickly.",
              synonyms: [],
              antonyms: [],
              example: "He's hurrying because he's late.",
            },
            {
              definition:
                "Often with up, to speed up the rate of doing something.",
              synonyms: [],
              antonyms: [],
              example: "If you don't hurry (up) you won't finish on time.",
            },
            {
              definition: "To cause to be done quickly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To hasten; to impel to greater speed; to urge on.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To impel to precipitate or thoughtless action; to urge to confused or irregular activity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To put: to convey coal in the mine, e.g. from the working to the tramway.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hurry"],
    },
  ],
  saved: [
    {
      word: "saved",
      phonetic: "/seɪvd/",
      phonetics: [
        {
          text: "/seɪvd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/saved-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1648307",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To prevent harm or difficulty.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To put aside, to avoid.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Rescued from the consequences of sin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'Retained for future use rather than spent e.g. ː"A penny saved is a penny earned."',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/save",
        "https://en.wiktionary.org/wiki/saved",
      ],
    },
  ],
  sorry: [
    {
      word: "sorry",
      phonetic: "/ˈsɔɹi/",
      phonetics: [
        {
          text: "/ˈsɔɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sorry-ca.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=13280547",
        },
        { text: "/ˈsɒɹi/", audio: "" },
        {
          text: "/ˈsɑɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sorry-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1217957",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act of saying sorry; an apology.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(of a person) Regretful for an action; grieved or saddened, especially by the loss of something or someone.",
              synonyms: [],
              antonyms: [],
              example: "I am sorry I stepped on your toes. It was an accident.",
            },
            {
              definition: "Poor, pitifully sad or regrettable.",
              synonyms: [],
              antonyms: [],
              example: "The storm left his garden in a sorry state.",
            },
            {
              definition:
                "Pathetic and inferior to the point of causing others disgust.",
              synonyms: [],
              antonyms: [],
              example: "Bob is a sorry excuse for a football player.",
            },
          ],
          synonyms: [
            "apologetic",
            "attritional",
            "compunctious",
            "contrite",
            "heavyhearted",
            "melancholy",
            "mournful",
            "penitent",
            "penitential",
            "regretful",
            "remorseful",
            "repentant",
            "sad",
            "unhappy",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "Expresses regret, remorse, or sorrow.",
              synonyms: [],
              antonyms: [],
              example: "Sorry! I didn't see that you were on the phone.",
            },
            {
              definition:
                "Used as a request for someone to repeat something not heard or understood clearly.",
              synonyms: [],
              antonyms: [],
              example: "Sorry? What was that? The phone cut out.",
            },
            {
              definition: "Used to correct oneself in speech.",
              synonyms: [],
              antonyms: [],
              example:
                "There are four — sorry, five — branches of the store locally.",
            },
          ],
          synonyms: [
            "soz",
            "I beg your pardon",
            "I'm sorry",
            "come again",
            "excuse me",
            "huh",
            "say again",
            "say what",
            "what",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sorry"],
    },
  ],
  giant: [
    {
      word: "giant",
      phonetic: "/ˈdʒaɪ.ənt/",
      phonetics: [
        {
          text: "/ˈdʒaɪ.ənt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/giant-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100434",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A mythical human of very great size.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Specifically, any of the gigantes, the race of giants in the Greek mythology.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A very tall and large person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A tall species of a particular animal or plant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A star that is considerably more luminous than a main sequence star of the same temperature (e.g. red giant, blue giant).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An Ethernet packet that exceeds the medium's maximum packet size of 1,518 bytes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A very large organisation.",
              synonyms: [],
              antonyms: [],
              example:
                "The retail giant is set to acquire two more struggling high-street chains.",
            },
            {
              definition:
                "A person of extraordinary strength or powers, bodily or intellectual.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Jotun", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Very large.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "colossal",
            "enormous",
            "gigantic",
            "immense",
            "prodigious",
            "vast",
          ],
          antonyms: ["dwarf", "midget"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/giant"],
    },
  ],
  trail: [
    {
      word: "trail",
      phonetic: "/tɹeɪl/",
      phonetics: [
        {
          text: "/tɹeɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/trail-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1750018",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The track or indication marking the route followed by something that has passed, such as the footprints of animal on land or the contrail of an airplane in the sky.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A route for travel over land, especially a narrow, unpaved pathway for use by hikers, horseback riders, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A trailer broadcast on television for a forthcoming film or programme.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A walk in which all the edges are distinct.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "dirt track",
            "footpath",
            "path",
            "track",
            "sign",
            "spoor",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To follow behind (someone or something); to tail (someone or something).",
              synonyms: [],
              antonyms: [],
              example: "The hunters trailed their prey deep into the woods.",
            },
            {
              definition: "To drag (something) behind on the ground.",
              synonyms: [],
              antonyms: [],
              example:
                "You'll get your coat all muddy if you trail it around like that.",
            },
            {
              definition: "To leave (a trail of).",
              synonyms: [],
              antonyms: [],
              example:
                "He walked into the house, soaking wet, and trailed water all over the place.",
            },
            {
              definition:
                "To show a trailer of (a film, TV show etc.); to release or publish a preview of (a report etc.) in advance of the full publication.",
              synonyms: [],
              antonyms: [],
              example: "His new film was trailed on TV last night.",
            },
            {
              definition:
                "To hang or drag loosely behind; to move with a slow sweeping motion.",
              synonyms: [],
              antonyms: [],
              example:
                "The bride's long dress trailed behind her as she walked down the aisle.",
            },
            {
              definition: "To run or climb like certain plants.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To drag oneself lazily or reluctantly along.",
              synonyms: [],
              antonyms: [],
              example: "Our parents marched to church and we trailed behind.",
            },
            {
              definition: "To be losing, to be behind in a competition.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To carry (a firearm) with the breech near the ground and the upper part inclined forward, the piece being held by the right hand near the middle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To flatten (grass, etc.) by walking through it; to tread down.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To take advantage of the ignorance of; to impose upon.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/trail"],
    },
  ],
  offer: [
    {
      word: "offer",
      phonetic: "/ˈɑfɚ/",
      phonetics: [
        { text: "/ˈɑfɚ/", audio: "" },
        { text: "/ˈɒfə(ɹ)/", audio: "" },
        {
          text: "/ˈɔfɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/offer-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1113705",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A proposal that has been made.",
              synonyms: [],
              antonyms: [],
              example: "I decline your offer to contract.",
            },
            {
              definition: "Something put forth, bid, proffered or tendered.",
              synonyms: [],
              antonyms: [],
              example: "His offer was $3.50 per share.",
            },
            {
              definition:
                "An invitation to enter into a binding contract communicated to another party which contains terms sufficiently definite to create an enforceable contract if the other party accepts the invitation.",
              synonyms: [],
              antonyms: [],
              example:
                "His first letter was not a real offer, but an attempt to determine interest.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/offer"],
    },
    {
      word: "offer",
      phonetic: "/ˈɑfɚ/",
      phonetics: [
        { text: "/ˈɑfɚ/", audio: "" },
        { text: "/ˈɒfə(ɹ)/", audio: "" },
        {
          text: "/ˈɔfɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/offer-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1113705",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To propose or express one's willingness (to do something).",
              synonyms: [],
              antonyms: [],
              example: "She offered to help with her homework.",
            },
            {
              definition:
                "To present in words; to proffer; to make a proposal of; to suggest.",
              synonyms: [],
              antonyms: [],
              example: "Everybody offered an opinion.",
            },
            {
              definition:
                "To place at someone’s disposal; to present (something) to be either accepted or turned down.",
              synonyms: [],
              antonyms: [],
              example:
                "He offered use of his car for the week.  He offered his good will for the Councilman's vote.",
            },
            {
              definition:
                "To present (something) to God or gods as a gesture of worship, or for a sacrifice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To place (something) in a position where it can be added to an existing mechanical assembly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To bid, as a price, reward, or wages.",
              synonyms: [],
              antonyms: [],
              example:
                "I offered twenty dollars for it. The company is offering a salary of £30,000 a year.",
            },
            {
              definition: "To happen, to present itself.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make an attempt; typically used with at.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To put in opposition to; to manifest in an offensive way; to threaten.",
              synonyms: [],
              antonyms: [],
              example: "to offer violence to somebody",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/offer"],
    },
    {
      word: "offer",
      phonetic: "/ˈɑfɚ/",
      phonetics: [
        { text: "/ˈɑfɚ/", audio: "" },
        { text: "/ˈɒfə(ɹ)/", audio: "" },
        {
          text: "/ˈɔfɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/offer-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1113705",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(used in combinations from phrasal verbs) agent noun of off",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/offer"],
    },
  ],
  ought: [
    {
      word: "ought",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Property; possession", synonyms: [], antonyms: [] },
            { definition: "Duty; place; office", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/aught",
        "https://en.wiktionary.org/wiki/ought",
      ],
    },
  ],
  rough: [
    {
      word: "rough",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rough-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899764",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ɹʌf/", audio: "" },
        {
          text: "/ɹʌf/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rough-us.oga",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1604356",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The unmowed part of a golf course.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A rude fellow; a coarse bully; a rowdy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A scuffed and roughened area of the pitch, where the bowler's feet fall, used as a target by spin bowlers because of its unpredictable bounce.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The raw material from which faceted or cabochon gems are created.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A quick sketch, similar to a thumbnail but larger and more detailed, used for artistic brainstorming.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Boisterous weather.", synonyms: [], antonyms: [] },
            {
              definition:
                "A piece inserted in a horseshoe to keep the animal from slipping.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To create in an approximate form.",
              synonyms: [],
              antonyms: [],
              example: "Rough in the shape first, then polish the details.",
            },
            {
              definition:
                "To commit the offense of roughing, i.e. to punch another player.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To render rough; to roughen.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To break in (a horse, etc.), especially for military purposes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To endure primitive conditions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To roughen a horse's shoes to keep the animal from slipping.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Not smooth; uneven.", synonyms: [], antonyms: [] },
            {
              definition: "Approximate; hasty or careless; not finished.",
              synonyms: [],
              antonyms: [],
              example:
                "a rough estimate; a rough sketch of a building; a rough plan",
            },
            {
              definition: "Turbulent.",
              synonyms: [],
              antonyms: [],
              example: "rough sea",
            },
            {
              definition: "Difficult; trying.",
              synonyms: [],
              antonyms: [],
              example: "Being a teenager nowadays can be rough.",
            },
            {
              definition: "Crude; unrefined",
              synonyms: [],
              antonyms: [],
              example: "His manners are a bit rough, but he means well.",
            },
            {
              definition: "Violent; not careful or subtle",
              synonyms: [],
              antonyms: [],
              example: "This box has been through some rough handling.",
            },
            {
              definition:
                "Loud and hoarse; offensive to the ear; harsh; grating.",
              synonyms: [],
              antonyms: [],
              example: "a rough tone; a rough voice",
            },
            {
              definition: "Not polished; uncut; said of a gem.",
              synonyms: [],
              antonyms: [],
              example: "a rough diamond",
            },
            {
              definition: "Harsh-tasting.",
              synonyms: [],
              antonyms: [],
              example: "rough wine",
            },
            { definition: "Somewhat ill; sick", synonyms: [], antonyms: [] },
            {
              definition: "Unwell due to alcohol; hungover",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["smooth"],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "In a rough manner; rudely; roughly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rough"],
    },
  ],
  daily: [
    {
      word: "daily",
      phonetic: "/ˈdeɪli/",
      phonetics: [
        {
          text: "/ˈdeɪli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/daily-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1446787",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Something that is produced, consumed, used, or done every day.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["help", "maid", "daily paper"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To drive an automobile frequently, on a daily basis, for regular and mundane tasks.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "That occurs every day, or at least every working day",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Diurnal, by daylight, as opposed to nightly",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["quotidian"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/daily"],
    },
    {
      word: "daily",
      phonetic: "/ˈdeɪli/",
      phonetics: [
        {
          text: "/ˈdeɪli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/daily-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1446787",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Quotidianly, every day",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Diurnally, by daylight",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/daily"],
    },
  ],
  avoid: [
    {
      word: "avoid",
      phonetic: "/əˈvɔɪd/",
      phonetics: [
        {
          text: "/əˈvɔɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/avoid-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1676698",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To try not to meet or communicate with (a person); to shun",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To keep away from; to keep clear of; to stay away from",
              synonyms: [],
              antonyms: [],
              example: "I try to avoid the company of gamblers.",
            },
            {
              definition:
                "To try not to do something or to have something happen",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make empty; to clear.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make void, to annul; to refute (especially a contract).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To defeat or evade; to invalidate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To emit or throw out; to void.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To leave, evacuate; to leave as empty, to withdraw or come away from.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To get rid of.", synonyms: [], antonyms: [] },
            {
              definition: "To retire; to withdraw, depart, go away.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To become void or vacant.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/avoid"],
    },
  ],
  keeps: [
    {
      word: "keeps",
      phonetic: "/kiːps/",
      phonetics: [
        {
          text: "/kiːps/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/keeps-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100462",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The main tower of a castle or fortress, located within the castle walls.",
              synonyms: ["donjon"],
              antonyms: [],
            },
            {
              definition:
                "The food or money required to keep someone alive and healthy; one's support, maintenance.",
              synonyms: [],
              antonyms: [],
              example: "He works as a cobbler's apprentice for his keep.",
            },
            {
              definition:
                "The act or office of keeping; custody; guard; care; heed; charge; notice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The state of being kept; hence, the resulting condition; case.",
              synonyms: [],
              antonyms: [],
              example: "to be in good keep",
            },
            {
              definition: "That which is kept in charge; a charge.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cap for holding something, such as a journal box, in place.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["donjon"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To continue in (a course or mode of action); not to intermit or fall from; to uphold or maintain.",
              synonyms: [],
              antonyms: [],
              example:
                "to keep silence;  to keep one's word;  to keep possession",
            },
            {
              definition: "(heading) To hold the status of something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(heading) To hold or be held in a state.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To wait for, keep watch for.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To act as wicket-keeper.",
              synonyms: [],
              antonyms: [],
              example: "Godfrey Evans kept for England for many years.",
            },
            {
              definition: "To take care; to be solicitous; to watch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be in session; to take place.",
              synonyms: [],
              antonyms: [],
              example: "School keeps today.",
            },
            {
              definition:
                "To observe; to adhere to; to fulfill; not to swerve from or violate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) To visit (a place) often; to frequent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To observe or celebrate (a holiday).",
              synonyms: [],
              antonyms: [],
              example: "The feast of St. Stephen is kept on December 26.",
            },
          ],
          synonyms: ["retain", "preserve", "protect"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "Keeping", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/keep",
        "https://en.wiktionary.org/wiki/keeps",
      ],
    },
  ],
  throw: [
    {
      word: "throw",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/throw-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75900259",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/θɹəʊ/", audio: "" },
        {
          text: "/θɹoʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/throw-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1230556",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To change place.", synonyms: [], antonyms: [] },
            {
              definition: "To change in state or status",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To move through time.", synonyms: [], antonyms: [] },
            { definition: "To be accepted.", synonyms: [], antonyms: [] },
            {
              definition: "In any game, to decline to play in one's turn.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To do or be better.", synonyms: [], antonyms: [] },
            {
              definition: "To take heed.",
              synonyms: ["take heed", "take notice"],
              antonyms: [],
            },
          ],
          synonyms: [
            "spend",
            "pledge",
            "promise",
            "vow",
            "take heed",
            "take notice",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The flight of a thrown object.",
              synonyms: [],
              antonyms: [],
              example: "What a great throw by the quarterback!",
            },
            {
              definition: "The act of throwing something.",
              synonyms: [],
              antonyms: [],
              example:
                "The gambler staked everything on one throw of the dice.",
            },
            {
              definition: "One's ability to throw.",
              synonyms: [],
              antonyms: [],
              example: "He's always had a pretty decent throw.",
            },
            {
              definition: "A distance travelled; displacement.",
              synonyms: [],
              antonyms: [],
              example: "the throw of the piston",
            },
            {
              definition:
                "A piece of fabric used to cover a bed, sofa or other soft furnishing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A single instance, occurrence, venture, or chance.",
              synonyms: [],
              antonyms: [],
              example: "Football tickets are expensive at fifty bucks a throw.",
            },
            { definition: "A violent effort.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To hurl; to cause an object to move rapidly through the air.",
              synonyms: [
                "bowl",
                "bung",
                "buzz",
                "cast",
                "catapult",
                "chuck",
                "dash",
                "direct",
                "fire",
                "fling",
                "flip",
                "heave",
                "hurl",
                "launch",
                "lob",
                "pitch",
                "project",
                "propel",
                "send",
                "shoot",
                "shy",
                "sling",
                "toss",
                "whang",
              ],
              antonyms: [],
              example:
                "throw a shoe; throw a javelin; the horse threw its rider",
            },
            {
              definition: "To eject or cause to fall off.",
              synonyms: ["eject", "throw off"],
              antonyms: [],
            },
            {
              definition:
                "To move to another position or condition; to displace.",
              synonyms: ["displace", "relocate"],
              antonyms: [],
              example: "throw the switch",
            },
            {
              definition:
                "To make (a pot) by shaping clay as it turns on a wheel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a bowler) to deliver (the ball) illegally by straightening the bowling arm during delivery.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To send (an error) to an exception-handling mechanism in order to interrupt normal processing.",
              synonyms: [],
              antonyms: [],
              example:
                "If the file is read-only, the method throws an invalid-operation exception.",
            },
            {
              definition: "To intentionally lose a game.",
              synonyms: ["take a dive"],
              antonyms: [],
              example:
                "The tennis player was accused of taking bribes to throw the match.",
            },
            {
              definition: "To confuse or mislead.",
              synonyms: [],
              antonyms: [],
              example: "The deliberate red herring threw me at first.",
            },
            {
              definition: "To send desperately.",
              synonyms: [],
              antonyms: [],
              example: "Their sergeant threw the troops into pitched battle.",
            },
            {
              definition: "To imprison.",
              synonyms: [],
              antonyms: [],
              example:
                "The magistrate ordered the suspect to be thrown into jail.",
            },
            {
              definition: "To organize an event, especially a party.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To roll (a die or dice).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause a certain number on the die or dice to be shown after rolling it.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To discard.", synonyms: [], antonyms: [] },
            {
              definition:
                "To lift the opponent off the ground and bring him back down, especially into a position behind the thrower.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(said of one's voice) To change in order to give the illusion that the voice is that of someone else.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To show sudden emotion, especially anger.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To project or send forth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To put on hastily; to spread carelessly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To twist two or more filaments of (silk, etc.) so as to form one thread; to twist together, as singles, in a direction contrary to the twist of the singles themselves; sometimes applied to the whole class of operations by which silk is prepared for the weaver.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a team, a manager, etc.) To select (a pitcher); to assign a pitcher to a given role (such as starter or reliever).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To install (a bridge).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To twist or turn.",
              synonyms: [],
              antonyms: [],
              example: "a thrown nail",
            },
          ],
          synonyms: [
            "bowl",
            "bung",
            "buzz",
            "cast",
            "catapult",
            "chuck",
            "dash",
            "direct",
            "fire",
            "fling",
            "flip",
            "heave",
            "hurl",
            "launch",
            "lob",
            "pitch",
            "project",
            "propel",
            "send",
            "shoot",
            "shy",
            "sling",
            "toss",
            "whang",
            "displace",
            "relocate",
            "eject",
            "throw off",
            "take a dive",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pass",
        "https://en.wiktionary.org/wiki/throw",
      ],
    },
    {
      word: "throw",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/throw-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75900259",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/θɹəʊ/", audio: "" },
        {
          text: "/θɹoʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/throw-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1230556",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Pain, especially pain associated with childbirth; throe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The act of giving birth in animals, especially in cows.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(said of animals) To give birth to.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/throw"],
    },
    {
      word: "throw",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/throw-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75900259",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/θɹəʊ/", audio: "" },
        {
          text: "/θɹoʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/throw-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1230556",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A moment, time, occasion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A period of time; a while.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["stound"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/throw"],
    },
  ],
  allow: [
    {
      word: "allow",
      phonetic: "/əˈlaʊ/",
      phonetics: [
        {
          text: "/əˈlaʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/allow-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9027925",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/əˈlaʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/allow-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1243844",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To grant, give, admit, accord, afford, or yield; to let one have.",
              synonyms: [],
              antonyms: [],
              example:
                "to allow a servant his liberty;  to allow a free passage;  to allow one day for rest",
            },
            {
              definition:
                "To acknowledge; to accept as true; to concede; to accede to an opinion.",
              synonyms: [],
              antonyms: [],
              example:
                "to allow a right;  to allow a claim;  to allow the truth of a proposition",
            },
            {
              definition:
                "To grant (something) as a deduction or an addition; especially to abate or deduct.",
              synonyms: [],
              antonyms: [],
              example: "To allow a sum for leakage.",
            },
            {
              definition: "To grant license to; to permit; to consent to.",
              synonyms: [],
              antonyms: [],
              example: "Smoking allowed only in designated areas.",
            },
            {
              definition: "To not bar or obstruct.",
              synonyms: [],
              antonyms: [],
              example:
                "Although I don't consent to their holding such meetings, I will allow them for the time being.",
            },
            {
              definition: "To acknowledge or concede.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To take into account by making an allowance.",
              synonyms: [],
              antonyms: [],
              example:
                "When calculating a budget for a construction project, always allow for contingencies.",
            },
            {
              definition: "To render physically possible.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To praise; to approve of; hence, to sanction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To sanction; to invest; to entrust.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To like; to be suited or pleased with.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "admit",
            "allot",
            "assign",
            "bestow",
            "concede",
            "let",
            "permit",
            "suffer",
            "tolerate",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/allow"],
    },
  ],
  cream: [
    {
      word: "cream",
      phonetic: "/kɹiːm/",
      phonetics: [
        {
          text: "/kɹiːm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cream-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762650",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The butterfat/milkfat part of milk which rises to the top; this part when separated from the remainder.",
              synonyms: [],
              antonyms: [],
              example: "Take 100 ml of cream and 50 grams of sugar…",
            },
            {
              definition: "A yellowish white colour; the colour of cream.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Frosting, custard, creamer or another substance similar to the oily part of milk or to whipped cream.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The best part of something.",
              synonyms: [],
              antonyms: [],
              example:
                "the cream of the crop;  the cream of a collection of books or pictures",
            },
            {
              definition:
                "A viscous aqueous oil/fat emulsion with a medicament added, used to apply that medicament to the skin. (compare with ointment)",
              synonyms: [],
              antonyms: [],
              example: "You look really sunburnt; you should apply some cream.",
            },
            { definition: "Semen.", synonyms: [], antonyms: [] },
            {
              definition:
                "The chrism or consecrated oil used in anointing ceremonies.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["ream"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To puree, to blend with a liquifying process.",
              synonyms: [],
              antonyms: [],
              example:
                "Cream the vegetables with the olive oil, flour, salt and water mixture.",
            },
            {
              definition:
                "To turn a yellowish white colour; to give something the color of cream.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To obliterate, to defeat decisively.",
              synonyms: [],
              antonyms: [],
              example: "We creamed the opposing team!",
            },
            {
              definition: "To ejaculate (used of either gender).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To ejaculate in (clothing).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To rub, stir, or beat (butter) into a light creamy consistency.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To skim, or take off by skimming, as cream.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To take off the best or choicest part of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To furnish with, or as if with, cream.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To gather or form cream.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Cream-coloured; having a yellowish white colour.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cream"],
    },
  ],
  laugh: [
    {
      word: "laugh",
      phonetic: "/laːf/",
      phonetics: [
        { text: "/laːf/", audio: "" },
        { text: "/lɑːf/", audio: "" },
        {
          text: "/læf/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/laugh-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=275476",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An expression of mirth particular to the human species; the sound heard in laughing; laughter.",
              synonyms: [],
              antonyms: [],
              example: "His deep laughs boomed through the room.",
            },
            {
              definition: "Something that provokes mirth or scorn.",
              synonyms: [],
              antonyms: [],
              example: "Your new hat's an absolute laugh, dude.",
            },
            { definition: "A fun person.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "cachinnation",
            "cackle",
            "chortle",
            "chuckle",
            "giggle",
            "guffaw",
            "snicker",
            "snigger",
            "titter",
            "joke",
            "laughing stock",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To show mirth, satisfaction, or derision, by peculiar movement of the muscles of the face, particularly of the mouth, causing a lighting up of the face and eyes, and usually accompanied by the emission of explosive or chuckling sounds from the chest and throat; to indulge in laughter.",
              synonyms: [],
              antonyms: [],
              example:
                "There were many laughing children running on the school grounds.",
            },
            {
              definition:
                "To be or appear cheerful, pleasant, mirthful, lively, or brilliant; to sparkle; to sport.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                '(followed by "at") To make an object of laughter or ridicule; to make fun of; to deride; to mock.',
              synonyms: [],
              antonyms: [],
              example: "Don't laugh at my new hat, man!",
            },
            {
              definition:
                "To affect or influence by means of laughter or ridicule.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To express by, or utter with, laughter.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "cackle",
            "chortle",
            "chuckle",
            "giggle",
            "guffaw",
            "snicker",
            "snigger",
            "titter",
          ],
          antonyms: ["cry", "weep"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/laugh"],
    },
  ],
  edges: [
    {
      word: "edges",
      phonetic: "/ˈɛdʒɪz/",
      phonetics: [
        {
          text: "/ˈɛdʒɪz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/edges-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2650891",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The boundary line of a surface.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A one-dimensional face of a polytope. In particular, the joining line between two vertices of a polygon; the place where two faces of a polyhedron meet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An advantage.",
              synonyms: [],
              antonyms: [],
              example: "I have the edge on him.",
            },
            {
              definition:
                "The thin cutting side of the blade of an instrument, such as an ax, knife, sword, or scythe; that which cuts as an edge does, or wounds deeply, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sharp terminating border; a margin; a brink; an extreme verge.",
              synonyms: [],
              antonyms: [],
              example: "He is standing on the edge of a precipice.",
            },
            {
              definition:
                "Sharpness; readiness or fitness to cut; keenness; intenseness of desire.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The border or part adjacent to the line of division; the beginning or early part (of a period of time)",
              synonyms: [],
              antonyms: [],
              example: "in the edge of evening",
            },
            {
              definition:
                "A shot where the ball comes off the edge of the bat, often unintentionally.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A connected pair of vertices in a graph.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In male masturbation, a level of sexual arousal that is maintained just short of reaching the point of inevitability, or climax; see also edging.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "advantage",
            "gain",
            "line",
            "boundary",
            "brink",
            "lip",
            "margin",
            "rim",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To move an object slowly and carefully in a particular direction.",
              synonyms: [],
              antonyms: [],
              example: "He edged the book across the table.",
            },
            {
              definition:
                "To move slowly and carefully in a particular direction.",
              synonyms: [],
              antonyms: [],
              example: "He edged away from her.",
            },
            {
              definition:
                "(usually in the form 'just edge') To win by a small margin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To hit the ball with an edge of the bat, causing a fine deflection.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To trim the margin of a lawn where the grass meets the sidewalk, usually with an electric or gas-powered lawn edger.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To furnish with an edge; to construct an edging.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To furnish with an edge, as a tool or weapon; to sharpen.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make sharp or keen; to incite; to exasperate; to goad; to urge or egg on.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To delay one's orgasm so as to remain almost at the point of orgasm.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/edge",
        "https://en.wiktionary.org/wiki/edges",
      ],
    },
  ],
  teach: [
    {
      word: "teach",
      phonetic: "/tiːt͡ʃ/",
      phonetics: [
        {
          text: "/tiːt͡ʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/teach-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=589429",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To show (someone) the way; to guide, conduct; to point, indicate.",
              synonyms: [],
              antonyms: [],
              example:
                "‘The bliss is there’, mumbled the old man and taught to Heaven.",
            },
            {
              definition: "(ditransitive) To pass on knowledge to.",
              synonyms: ["educate", "instruct"],
              antonyms: [],
              example: "Can you teach me to sew?  Can you teach sewing to me?",
            },
            {
              definition:
                "To pass on knowledge, especially as one's profession; to act as a teacher.",
              synonyms: [],
              antonyms: ["learn"],
              example: "She used to teach at university.",
            },
            {
              definition: "(ditransitive) To cause to learn or understand.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(ditransitive) To cause to know the disagreeable consequences of some action.",
              synonyms: [],
              antonyms: [],
              example: "I'll teach you to make fun of me!",
            },
          ],
          synonyms: ["educate", "instruct"],
          antonyms: ["learn"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/teach"],
    },
    {
      word: "teach",
      phonetic: "/tiːt͡ʃ/",
      phonetics: [
        {
          text: "/tiːt͡ʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/teach-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=589429",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(usually as a term of address) teacher",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/teach"],
    },
  ],
  frame: [
    {
      word: "frame",
      phonetic: "/fɹeɪm/",
      phonetics: [
        {
          text: "/fɹeɪm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/frame-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=857060",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The structural elements of a building or other constructed object.",
              synonyms: [],
              antonyms: [],
              example:
                "Now that the frame is complete, we can start on the walls.",
            },
            {
              definition:
                "Anything composed of parts fitted and united together; a fabric; a structure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The structure of a person's body; the human body.",
              synonyms: [],
              antonyms: [],
              example:
                "His starved flesh hung loosely on his once imposing frame.",
            },
            {
              definition:
                "A rigid, generally rectangular mounting for paper, canvas or other flexible material.",
              synonyms: [],
              antonyms: [],
              example: "The painting was housed in a beautifully carved frame.",
            },
            {
              definition: "A piece of photographic film containing an image.",
              synonyms: [],
              antonyms: [],
              example: "A film projector shows many frames in a single second.",
            },
            {
              definition: "A context for understanding or interpretation.",
              synonyms: [],
              antonyms: [],
              example:
                "In this frame, it's easy to ask the question that the investigators missed.",
            },
            {
              definition:
                "A complete game of snooker, from break-off until all the balls (or as many as necessary to win) have been potted.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An independent chunk of data sent over a network.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A set of balls whose results are added together for scoring purposes. Usually two balls, but only one ball in the case of a strike, and three balls in the case of a strike or a spare in the last frame of a game.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A movable structure used for the cultivation or the sheltering of plants.",
              synonyms: [],
              antonyms: [],
              example: "a forcing-frame; a cucumber frame",
            },
            {
              definition:
                "The outer decorated portion of a stamp's image, often repeated on several issues although the inner picture may change.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The outer circle of a cancellation mark.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A division of time on a multimedia timeline, such as 1/30th or 1/60th of a second.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An individually scrollable region of a webpage.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An inning.", synonyms: [], antonyms: [] },
            {
              definition:
                "Any of certain machines built upon or within framework.",
              synonyms: [],
              antonyms: [],
              example: "a stocking frame; a lace frame; a spinning frame",
            },
            {
              definition: "Frame of mind; disposition.",
              synonyms: [],
              antonyms: [],
              example: "to be always in a happy frame",
            },
            {
              definition: "Contrivance; the act of devising or scheming.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A stage or level of a video game.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                '("reading frame") A way of dividing nucleotide sequences into a set of consecutive triplets.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A form of knowledge representation in artificial intelligence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A complete lattice in which meets distribute over arbitrary joins.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To fit, as for a specific end or purpose; make suitable or comfortable; adapt; adjust.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To construct by fitting or uniting together various parts; fabricate by union of constituent parts.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To bring or put into form or order; adjust the parts or elements of; compose; contrive; plan; devise.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of a constructed object such as a building, to put together the structural elements.",
              synonyms: [],
              antonyms: [],
              example:
                "Once we finish framing the house, we'll hang tin on the roof.",
            },
            {
              definition:
                "Of a picture such as a painting or photograph, to place inside a decorative border.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To position visually within a fixed boundary.",
              synonyms: [],
              antonyms: [],
              example: "The director frames the fishing scene very well.",
            },
            {
              definition:
                "To construct in words so as to establish a context for understanding or interpretation.",
              synonyms: [],
              antonyms: [],
              example: "How would you frame your accomplishments?",
            },
            {
              definition:
                "Conspire to incriminate falsely a presumably innocent person. See frameup.",
              synonyms: [],
              antonyms: [],
              example:
                "The gun had obviously been placed in her car in an effort to frame her.",
            },
            {
              definition: "To wash ore with the aid of a frame.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To move.", synonyms: [], antonyms: [] },
            { definition: "To proceed; to go.", synonyms: [], antonyms: [] },
            {
              definition:
                "To hit (the ball) with the frame of the racquet rather than the strings (normally a mishit).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To strengthen; refresh; support.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To execute; perform.",
              synonyms: [],
              antonyms: [],
              example:
                "All have sworn him an oath that they should frame his will on earth.",
            },
            {
              definition: "To cause; to bring about; to produce.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To profit; avail.", synonyms: [], antonyms: [] },
            { definition: "To fit; accord.", synonyms: [], antonyms: [] },
            {
              definition:
                "To succeed in doing or trying to do something; manage.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["fit up"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/frame"],
    },
  ],
  bells: [
    {
      word: "bells",
      phonetic: "/bɛlz/",
      phonetics: [
        {
          text: "/bɛlz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bells-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2423257",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A percussive instrument made of metal or other hard material, typically but not always in the shape of an inverted cup with a flared rim, which resonates when struck.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The sounding of a bell as a signal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A telephone call.",
              synonyms: [],
              antonyms: [],
              example: "I’ll give you a bell later.",
            },
            {
              definition:
                "A signal at a school that tells the students when a class is starting or ending.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The flared end of a brass or woodwind instrument.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of a series of strokes on a bell (or similar), struck every half hour to indicate the time (within a four hour watch)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The flared end of a pipe, designed to mate with a narrow spigot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A device control code that produces a beep (or rings a small electromechanical bell on older teleprinters etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Anything shaped like a bell, such as the cup or corolla of a flower.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The part of the capital of a column included between the abacus and neck molding; also used for the naked core of nearly cylindrical shape, assumed to exist within the leafage of a capital.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An instrument situated on a bicycle's handlebar, used by the cyclist to warn of his or her presence.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["campane", "tintinnabule"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To attach a bell to.",
              synonyms: [],
              antonyms: [],
              example: "Who will bell the cat?",
            },
            {
              definition: "To shape so that it flares out like a bell.",
              synonyms: [],
              antonyms: [],
              example: "to bell a tube",
            },
            { definition: "To telephone.", synonyms: [], antonyms: [] },
            {
              definition:
                "To develop bells or corollas; to take the form of a bell; to blossom.",
              synonyms: [],
              antonyms: [],
              example: "Hops bell.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The bellow or bay of certain animals, such as a hound on the hunt or a stag in rut.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To bellow or roar.", synonyms: [], antonyms: [] },
            {
              definition: "To utter in a loud manner; to thunder forth.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Ship's bells; the strokes on a ship's bell, every half hour, to mark the passage of time.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Short for bell-bottoms.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bell",
        "https://en.wiktionary.org/wiki/bells",
      ],
    },
  ],
  dream: [
    {
      word: "dream",
      phonetic: "/dɹiːm/",
      phonetics: [
        { text: "/dɹiːm/", audio: "" },
        {
          text: "/dɹim/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dream-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1230645",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Imaginary events seen in the mind while sleeping.",
              synonyms: ["sweven"],
              antonyms: [],
            },
            { definition: "A hope or wish.", synonyms: [], antonyms: [] },
            {
              definition: "A visionary scheme; a wild conceit; an idle fancy.",
              synonyms: ["vision"],
              antonyms: [],
              example: "a dream of bliss",
            },
          ],
          synonyms: ["sweven", "vision"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To see imaginary events in one's mind while sleeping.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To hope, to wish.", synonyms: [], antonyms: [] },
            {
              definition: "To daydream.",
              synonyms: [],
              antonyms: [],
              example: "Stop dreaming and get back to work.",
            },
            {
              definition:
                "To envision as an imaginary experience (usually when asleep).",
              synonyms: [],
              antonyms: [],
              example: "I dreamed a vivid dream last night.",
            },
            {
              definition: "To consider the possibility (of).",
              synonyms: [],
              antonyms: [],
              example: "I wouldn't dream of snubbing you in public.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Ideal; perfect.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dream"],
    },
  ],
  magic: [
    {
      word: "magic",
      phonetic: "/ˈmadʒɪk/",
      phonetics: [
        { text: "/ˈmadʒɪk/", audio: "" },
        {
          text: "/ˈmædʒɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/magic-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762983",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The application of rituals or actions, especially those based on occult knowledge, to subdue or manipulate natural or supernatural beings and forces in order to have some benefit from them",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A specific ritual or procedure associated with such magic; a spell.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The supernatural forces which are drawn on in such a ritual",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something producing successful and remarkable results, especially when not fully understood; an enchanting quality; exceptional skill.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A conjuring trick or illusion performed to give the appearance of supernatural phenomena or powers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The art or practice of performing conjuring tricks and illusions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Complicated or esoteric code that is not expected to be generally understood.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "charmwork",
            "conjuring",
            "dweomercraft",
            "dwimmer",
            "dwimmercraft",
            "enchantment",
            "hexcraft",
            "sorcery",
            "spellcasting",
            "spellcraft",
            "spellwork",
            "thaumaturgy",
            "wandwork",
            "warlockry",
            "witchcraft",
            "wizardcraft",
            "wizardry",
            "dwimmer",
            "illusionism",
            "legerdemain",
            "sleight of hand",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To produce, transform (something), (as if) by magic.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["conjure up", "magic up"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Having supernatural talents, properties or qualities attributed to magic.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Producing extraordinary results, as though through the use of magic; wonderful, amazing.",
              synonyms: [],
              antonyms: [],
              example: "a magic moment",
            },
            {
              definition:
                "Pertaining to conjuring tricks or illusions performed for entertainment etc.",
              synonyms: [],
              antonyms: [],
              example: "a magic show; a magic trick",
            },
            {
              definition: "Great; excellent.",
              synonyms: [],
              antonyms: [],
              example:
                "— I cleaned up the flat while you were out. — Really? Magic!",
            },
            {
              definition:
                "Describing the number of nucleons in a particularly stable isotopic nucleus; 2, 8, 20, 28, 50, 82, 126, and 184.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Being a literal number or string value with no meaning or context, not defined as a constant or variable",
              synonyms: [],
              antonyms: [],
              example:
                "The code is full of magic numbers and we can't figure out what they mean.",
            },
          ],
          synonyms: ["magical"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/magic"],
    },
  ],
  occur: [
    {
      word: "occur",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/occur-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75834268",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/əˈkɜː/", audio: "" },
        {
          text: "/əˈkɝ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/occur-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749915",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To happen or take place.",
              synonyms: [],
              antonyms: [],
              example: "The liftoff will occur in exactly twelve seconds.",
            },
            {
              definition: "To present or offer itself.",
              synonyms: [],
              antonyms: [],
              example: "I will write if the opportunity occurs.",
            },
            {
              definition:
                "To come or be presented to the mind; to suggest itself.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be present or found.",
              synonyms: [],
              antonyms: [],
              example:
                "The chemical monofluoroacetate occurs in all parts of Dichapetalum cymosum, and is responsible for its toxic effects.",
            },
          ],
          synonyms: [
            "belimp",
            "betide",
            "betime",
            "come to pass",
            "happen",
            "take place",
            "appear",
            "arise",
            "come up",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/occur"],
    },
  ],
  ended: [
    {
      word: "ended",
      phonetic: "/ˈɛndɪd/",
      phonetics: [
        {
          text: "/ˈɛndɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ended-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1669298",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To come to an end",
              synonyms: [],
              antonyms: [],
              example: "Is this movie never going to end?",
            },
            {
              definition: "To finish, terminate.",
              synonyms: [],
              antonyms: [],
              example: "The referee blew the whistle to end the game.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(in combination) Having (a specified kind or number of) ends.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/end",
        "https://en.wiktionary.org/wiki/ended",
      ],
    },
  ],
  chord: [
    {
      word: "chord",
      phonetic: "/kɔː(ɹ)d/",
      phonetics: [
        { text: "/kɔː(ɹ)d/", audio: "" },
        {
          text: "/kɔɹd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chord-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769344",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A harmonic set of three or more notes that is heard as if sounding simultaneously.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A straight line between two points of a curve.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A horizontal member of a truss.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The distance between the leading and trailing edge of a wing, measured in the direction of the normal airflow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An imaginary line from the luff of a sail to its leech.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A keyboard shortcut that involves two or more distinct keypresses, such as Ctrl+M followed by P.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The string of a musical instrument.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A cord.", synonyms: [], antonyms: [] },
            {
              definition:
                "An edge that is not part of a cycle but connects two vertices of the cycle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To write chords for.", synonyms: [], antonyms: [] },
            {
              definition: "To accord; to harmonize together.",
              synonyms: [],
              antonyms: [],
              example: "This note chords with that one.",
            },
            {
              definition:
                "To provide with musical chords or strings; to string; to tune.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chord"],
    },
  ],
  false: [
    {
      word: "false",
      phonetic: "/fɒls/",
      phonetics: [
        { text: "/fɒls/", audio: "" },
        { text: "/fɒls/", audio: "" },
        {
          text: "/fɒls/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/false-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9014426",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/fɑls/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/false-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=857025",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One of two options on a true-or-false test.",
              synonyms: [],
              antonyms: [],
              example:
                "The student received a failing grade for circling every true and false on her quiz.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Untrue, not factual, factually incorrect.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Based on factually incorrect premises.",
              synonyms: [],
              antonyms: [],
              example: "false legislation, false punishment",
            },
            {
              definition: "Spurious, artificial.",
              synonyms: [],
              antonyms: [],
              example: "false teeth",
            },
            {
              definition:
                "Of a state in Boolean logic that indicates a negative result.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Uttering falsehood; dishonest or deceitful.",
              synonyms: [],
              antonyms: [],
              example: "a false witness",
            },
            {
              definition:
                "Not faithful or loyal, as to obligations, allegiance, vows, etc.; untrue; treacherous.",
              synonyms: [],
              antonyms: [],
              example: "a false friend, lover, or subject;  false to promises",
            },
            {
              definition:
                "Not well founded; not firm or trustworthy; erroneous.",
              synonyms: [],
              antonyms: [],
              example: "a false conclusion;  a false construction in grammar",
            },
            {
              definition:
                "Not essential or permanent, as parts of a structure which are temporary or supplemental.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Used in the vernacular name of a species (or group of species) together with the name of another species to which it is similar in appearance.",
              synonyms: [],
              antonyms: [],
              example: "false killer whale (a dolphin)",
            },
            { definition: "Out of tune.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["lease"],
          antonyms: ["real", "true"],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "In a dishonest and disloyal way; falsely.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/false"],
    },
  ],
  skill: [
    {
      word: "skill",
      phonetic: "/skɪl/",
      phonetics: [
        {
          text: "/skɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/skill-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1676860",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Capacity to do something well; technique, ability. Skills are usually acquired or learned, as opposed to abilities, which are often thought of as innate.",
              synonyms: ["ability", "talent"],
              antonyms: [],
            },
            {
              definition: "Discrimination; judgment; propriety; reason; cause.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Knowledge; understanding.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Display of art; exercise of ability; contrivance; address.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["ability", "talent"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Great, excellent.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/skill"],
    },
    {
      word: "skill",
      phonetic: "/skɪl/",
      phonetics: [
        {
          text: "/skɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/skill-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1676860",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To set apart; separate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To discern; have knowledge or understanding; to know how (to).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To know; to understand.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To have knowledge or comprehension; discern.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To have personal or practical knowledge; be versed or practised; be expert or dextrous.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make a difference; signify; matter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To spend acquired points in exchange for skills.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["split"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/skill"],
    },
  ],
  holes: [
    {
      word: "holes",
      phonetic: "/həʊlz/",
      phonetics: [
        { text: "/həʊlz/", audio: "" },
        {
          text: "/hoʊlz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/holes-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2707531",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A hollow place or cavity; an excavation; a pit; an opening in or through a solid body, a fabric, etc.; a perforation; a rent; a fissure.",
              synonyms: [],
              antonyms: [],
              example:
                "There’s a hole in my shoe.  Her stocking has a hole in it.",
            },
            { definition: "(heading) In games.", synonyms: [], antonyms: [] },
            {
              definition: "An excavation pit or trench.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A weakness; a flaw or ambiguity.",
              synonyms: [],
              antonyms: [],
              example: "I have found a hole in your argument.",
            },
            {
              definition: "A container or receptacle.",
              synonyms: [],
              antonyms: [],
              example: "car hole;  brain hole",
            },
            {
              definition:
                "In semiconductors, a lack of an electron in an occupied band behaving like a positively charged particle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A security vulnerability in software which can be taken advantage of by an exploit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An orifice, in particular the anus. When used with shut it always refers to the mouth.",
              synonyms: [],
              antonyms: [],
              example: "Just shut your hole!",
            },
            {
              definition:
                '(particularly in the phrase "get one\'s hole") Sex, or a sex partner.',
              synonyms: [],
              antonyms: [],
              example: "Are you going out to get your hole tonight?",
            },
            {
              definition:
                '(with "the") Solitary confinement, a high-security prison cell often used as punishment.',
              synonyms: ["box"],
              antonyms: [],
            },
            {
              definition: "An undesirable place to live or visit; a hovel.",
              synonyms: [],
              antonyms: [],
              example: "His apartment is a hole!",
            },
            {
              definition: "Difficulty, in particular, debt.",
              synonyms: [],
              antonyms: [],
              example: "If you find yourself in a hole, stop digging.",
            },
            {
              definition: "A chordless cycle in a graph.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "SCU",
            "SHU",
            "ad-seg",
            "administrative segregation",
            "block",
            "box",
            "cooler",
            "hotbox",
            "lockdown",
            "pound",
            "security housing unit",
            "special handling unit",
            "box",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make holes in (an object or surface).",
              synonyms: [],
              antonyms: [],
              example: "Shrapnel holed the ship's hull.",
            },
            {
              definition: "(by extension) To destroy.",
              synonyms: [],
              antonyms: [],
              example: "She completely holed the argument.",
            },
            { definition: "To go into a hole.", synonyms: [], antonyms: [] },
            {
              definition:
                "To drive into a hole, as an animal, or a billiard ball or golf ball.",
              synonyms: [],
              antonyms: [],
              example: "Woods holed a standard three foot putt",
            },
            {
              definition: "To cut, dig, or bore a hole or holes in.",
              synonyms: [],
              antonyms: [],
              example: "to hole a post for the insertion of rails or bars",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hole",
        "https://en.wiktionary.org/wiki/holes",
      ],
    },
  ],
  dozen: [
    {
      word: "dozen",
      phonetic: "/ˈdʌzən/",
      phonetics: [
        {
          text: "/ˈdʌzən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dozen-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1669295",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A set of twelve.",
              synonyms: [],
              antonyms: [],
              example: "Can I have a dozen eggs, please?",
            },
            {
              definition:
                "(as plural only, always followed by of) A large, unspecified number of, comfortably estimated in small multiples of twelve, thus generally implied to be significantly more than ten or twelve, but less than perhaps one or two hundred; many.",
              synonyms: [],
              antonyms: [],
              example:
                "There must have been dozens of examples just on the first page.",
            },
            {
              definition:
                "An old English measure of ore containing 12 hundredweight.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "a great deal",
            "a lot",
            "heaps",
            "hundreds",
            "loads",
            "lots",
            "many",
            "millions",
            "scads",
            "scores",
            "thousands",
          ],
          antonyms: ["few"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dozen"],
    },
  ],
  brave: [
    {
      word: "brave",
      phonetic: "/bɹeɪv/",
      phonetics: [
        {
          text: "/bɹeɪv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/brave-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1648210",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A Native American warrior.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A man daring beyond discretion; a bully.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A challenge; a defiance; bravado.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To encounter with courage and fortitude, to defy, to provoke.",
              synonyms: [],
              antonyms: [],
              example:
                "After braving tricks on the high-dive, he braved a jump off the first diving platform.",
            },
            {
              definition: "To adorn; to make fine or showy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Strong in the face of fear; courageous.",
              synonyms: [
                "bold",
                "daring",
                "doughty",
                "orped",
                "resilient",
                "stalwart",
              ],
              antonyms: ["cowardly", "fearful", "mean", "weak"],
            },
            {
              definition: "Having any sort of superiority or excellence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Making a fine show or display.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "bold",
            "daring",
            "doughty",
            "orped",
            "resilient",
            "stalwart",
          ],
          antonyms: ["cowardly", "fearful", "mean", "weak"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/brave"],
    },
  ],
  apple: [
    {
      word: "apple",
      phonetic: "/ˈæp.əl/",
      phonetics: [
        {
          text: "/ˈæp.əl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/apple-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9014262",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ˈæp.əl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/apple-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=718877",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A common, round fruit produced by the tree Malus domestica, cultivated in temperate climates.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various tree-borne fruits or vegetables especially considered as resembling an apple; also (with qualifying words) used to form the names of other specific fruits such as custard apple, rose apple, thorn apple etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The fruit of the Tree of Knowledge, eaten by Adam and Eve according to post-Biblical Christian tradition; the forbidden fruit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A tree of the genus Malus, especially one cultivated for its edible fruit; the apple tree.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The wood of the apple tree.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in the plural) Short for apples and pears, slang for stairs.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The ball in baseball.", synonyms: [], antonyms: [] },
            {
              definition:
                "When smiling, the round, fleshy part of the cheeks between the eyes and the corners of the mouth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A Native American or red-skinned person who acts and/or thinks like a white (Caucasian) person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(ice hockey slang) An assist.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To become apple-like.", synonyms: [], antonyms: [] },
            { definition: "To form buds.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/apple"],
    },
  ],
  climb: [
    {
      word: "climb",
      phonetic: "/klaɪm/",
      phonetics: [
        {
          text: "/klaɪm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/climb-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1779984",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "An act of climbing.", synonyms: [], antonyms: [] },
            {
              definition: "The act of getting to somewhere more elevated.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An upwards struggle", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To ascend; rise; to go up.",
              synonyms: [],
              antonyms: [],
              example: "Prices climbed steeply.",
            },
            {
              definition: "To mount; to move upwards on.",
              synonyms: [],
              antonyms: [],
              example: "Climbing a tree",
            },
            {
              definition: "To scale; to get to the top of something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To move (especially up and down something) by gripping with the hands and using the feet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To practise the sport of climbing",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To jump high", synonyms: [], antonyms: [] },
            {
              definition: "To move to a higher position on the social ladder.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of plants, to grow upwards by clinging to something.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["scale"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/climb"],
    },
  ],
  outer: [
    {
      word: "outer",
      phonetic: "/ˈaʊtə/",
      phonetics: [
        {
          text: "/ˈaʊtə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/outer-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=31972276",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
        {
          text: "/ˈaʊtɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/outer-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2079727",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "An outer part.", synonyms: [], antonyms: [] },
            {
              definition:
                "The 4th circle on a target, outside the inner and magpie.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A shot which strikes the outer of a target.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(retail) The smallest single unit sold by wholesalers to retailers, usually one retail display box.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Outside; external.", synonyms: [], antonyms: [] },
            {
              definition: "Farther from the centre of the inside.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["inner"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/outer"],
    },
    {
      word: "outer",
      phonetic: "/ˈaʊtə/",
      phonetics: [
        {
          text: "/ˈaʊtə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/outer-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=31972276",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
        {
          text: "/ˈaʊtɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/outer-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2079727",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Someone who admits to something publicly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Someone who outs another.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One who puts out, ousts, or expels.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An ouster; dispossession.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One who supports leaving the European Union.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/outer"],
    },
  ],
  pitch: [
    {
      word: "pitch",
      phonetic: "/pɪtʃ/",
      phonetics: [
        {
          text: "/pɪtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pitch-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75648986",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/pɪtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pitch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=825490",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A sticky, gummy substance secreted by trees; sap.",
              synonyms: [],
              antonyms: [],
              example: "It is hard to get this pitch off my hand.",
            },
            {
              definition:
                "A dark, extremely viscous material remaining in still after distilling crude oil and tar.",
              synonyms: [],
              antonyms: [],
              example: "It was pitch black because there was no moon.",
            },
            { definition: "Pitchstone.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cover or smear with pitch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To darken; to blacken; to obscure.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pitch"],
    },
    {
      word: "pitch",
      phonetic: "/pɪtʃ/",
      phonetics: [
        {
          text: "/pɪtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pitch-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75648986",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/pɪtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pitch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=825490",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A throw; a toss; a cast, as of something from the hand.",
              synonyms: [],
              antonyms: [],
              example: "a good pitch in quoits",
            },
            {
              definition: "The act of pitching a baseball.",
              synonyms: [],
              antonyms: [],
              example: "The pitch was low and inside.",
            },
            {
              definition:
                'The field on which cricket, soccer, rugby or field hockey is played. (In cricket, the pitch is in the centre of the field; see cricket pitch.) Not used in America, where "field" is the preferred word.',
              synonyms: [],
              antonyms: [],
              example: "The teams met on the pitch.",
            },
            {
              definition: "An effort to sell or promote something.",
              synonyms: [],
              antonyms: [],
              example: "He gave me a sales pitch.",
            },
            {
              definition:
                "The distance between evenly spaced objects, e.g. the teeth of a saw or gear, the turns of a screw thread, the centres of holes, or letters in a monospace font.",
              synonyms: [],
              antonyms: [],
              example:
                "A helical scan with a pitch of zero is equivalent to constant z-axis scanning.",
            },
            {
              definition: "The angle at which an object sits.",
              synonyms: [],
              antonyms: [],
              example: "the pitch of the roof or haystack",
            },
            {
              definition:
                "A level or degree, or (by extension), a peak or highest degree.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The rotation angle about the transverse axis.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The place where a busker performs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An area in a market (or similar) allocated to a particular trader.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An area on a campsite intended for occupation by a single tent, caravan or similar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A point or peak; the extreme point of elevation or depression.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Prominence; importance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A section of a climb or rock face; specifically, the climbing distance between belays or stances.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A vertical cave passage, only negotiable by using rope or ladders.",
              synonyms: [],
              antonyms: [],
              example: "The entrance pitch requires 30 metres of rope.",
            },
            {
              definition: "A person's or animal's height.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That point of the ground on which the ball pitches or lights when bowled.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A descent; a fall; a thrusting down.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The point where a declivity begins; hence, the declivity itself; a descending slope; the degree or rate of descent or slope; slant.",
              synonyms: [],
              antonyms: [],
              example: "a steep pitch in the road",
            },
            {
              definition:
                "The limit of ground set to a miner who receives a share of the ore taken out.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To throw.",
              synonyms: [],
              antonyms: [],
              example: "He pitched the horseshoe.",
            },
            {
              definition: "To throw (the ball) toward a batter at home plate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To play baseball in the position of pitcher.",
              synonyms: [],
              antonyms: [],
              example: "Bob pitches today.",
            },
            {
              definition: "To throw away; discard.",
              synonyms: [],
              antonyms: [],
              example: "He pitched the candy wrapper.",
            },
            {
              definition: "To promote, advertise, or attempt to sell.",
              synonyms: [],
              antonyms: [],
              example: "He pitched the idea for months with no takers.",
            },
            {
              definition:
                "To deliver in a certain tone or style, or with a certain audience in mind.",
              synonyms: [],
              antonyms: [],
              example: "At which level should I pitch my presentation?",
            },
            {
              definition: "To assemble or erect (a tent).",
              synonyms: [],
              antonyms: [],
              example: "Pitch the tent over there.",
            },
            {
              definition:
                "To fix or place a tent or temporary habitation; to encamp.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To move so that the front of an aircraft or boat goes alternatively up and down.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To play a short, high, lofty shot that lands with backspin.",
              synonyms: [],
              antonyms: [],
              example:
                "The only way to get on the green from here is to pitch the ball over the bunker.",
            },
            {
              definition: "To bounce on the playing surface.",
              synonyms: [],
              antonyms: [],
              example: "The ball pitched well short of the batsman.",
            },
            {
              definition: "(of snow) To settle and build up, without melting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To alight; to settle; to come to rest from flight.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(with on or upon) To fix one's choice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To plunge or fall; especially, to fall forward; to decline or slope.",
              synonyms: [],
              antonyms: [],
              example: "The field pitches toward the east.",
            },
            {
              definition:
                "(of an embankment, roadway) To set, face, or pave with rubble or undressed stones.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of a price, value) To set or fix.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of a card) To discard for some gain.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pitch"],
    },
    {
      word: "pitch",
      phonetic: "/pɪtʃ/",
      phonetics: [
        {
          text: "/pɪtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pitch-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75648986",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/pɪtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pitch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=825490",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The perceived frequency of a sound or note.",
              synonyms: [],
              antonyms: [],
              example: 'The pitch of middle "C" is familiar to many musicians.',
            },
            {
              definition:
                "In an a cappella group, the singer responsible for singing a note for the other members to tune themselves by.",
              synonyms: [],
              antonyms: [],
              example:
                'Bob, our pitch, let out a clear middle "C" and our conductor gave the signal to start.',
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To produce a note of a given pitch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fix or set the tone of.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pitch"],
    },
  ],
  ruler: [
    {
      word: "ruler",
      phonetic: "/ˈɹuːlə(ɹ)/",
      phonetics: [
        { text: "/ˈɹuːlə(ɹ)/", audio: "" },
        {
          text: "/ˈɹulɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ruler-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780338",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A (usually rigid), flat, rectangular measuring or drawing device with graduations in units of measurement; a straightedge with markings.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person who rules or governs; someone or something that exercises dominion or controlling power over others.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["rule"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To beat with a ruler (as a traditional school punishment).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ruler"],
    },
  ],
  holds: [
    {
      word: "holds",
      phonetic: "/həʊldz/",
      phonetics: [
        { text: "/həʊldz/", audio: "" },
        {
          text: "/hoʊldz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/holds-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2079711",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A grasp or grip.",
              synonyms: [],
              antonyms: [],
              example: "Keep a firm hold on the handlebars.",
            },
            {
              definition: "An act or instance of holding.",
              synonyms: [],
              antonyms: [],
              example: "Can I have a hold of the baby?",
            },
            {
              definition: "A place where animals are held for safety",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An order that something is to be reserved or delayed, limiting or preventing how it can be dealt with.",
              synonyms: [],
              antonyms: [],
              example:
                "Senator X placed a hold on the bill, then went to the library and placed a hold on a book.",
            },
            {
              definition: "Something reserved or kept.",
              synonyms: [],
              antonyms: [],
              example: "We have a hold here for you.",
            },
            {
              definition: "Power over someone or something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The ability to persist.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The property of maintaining the shape of styled hair.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A position or grip used to control the opponent.",
              synonyms: [],
              antonyms: [],
              example: "He got him in a tight hold and pinned him to the mat.",
            },
            {
              definition:
                "(exercise) An exercise involving holding a position for a set time",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The percentage the house wins on a gamble, the house or bookmaker's hold.",
              synonyms: [],
              antonyms: [],
              example:
                "The House Hold on the game is 10,000, this is the amount of decision or risk the house wishes to assume.",
            },
            {
              definition: "The wager amount, the total hold.",
              synonyms: [],
              antonyms: [],
              example:
                "As of Monday night the total Melbourne Cup hold was $848,015",
            },
            {
              definition:
                "An instance of holding one's service game, as opposed to being broken.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The part of an object one is intended to grasp, or anything one can use for grasping with hands or feet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A fruit machine feature allowing one or more of the reels to remain fixed while the others spin.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A pause facility.", synonyms: [], antonyms: [] },
            {
              definition:
                "The queueing system on telephones and similar communication systems which maintains a connection when all lines are busy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A statistic awarded to a relief pitcher who is not still pitching at the end of the game and who records at least one out and maintains a lead for his team.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A region of airspace reserved for aircraft being kept in a holding pattern.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To grasp or grip.",
              synonyms: [],
              antonyms: [],
              example: "Hold the pencil like this.",
            },
            {
              definition: "To contain or store.",
              synonyms: [],
              antonyms: [],
              example: "This package holds six bottles.",
            },
            {
              definition:
                "(heading) To maintain or keep to a position or state.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(heading) To maintain or keep to particular opinions, promises, actions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To win one's own service game.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To take place, to occur.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To organise an event or meeting (usually in passive voice).",
              synonyms: [],
              antonyms: [],
              example:
                "Elections will be held on the first Sunday of next month.",
            },
            {
              definition: "To derive right or title.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In a food or drink order at an informal restaurant etc., requesting that a component normally included in that order be omitted.",
              synonyms: [],
              antonyms: [],
              example: "A martini, please, and hold the olive.",
            },
            {
              definition: "To be in possession of illicit drugs for sale.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["clasp", "grasp", "grip", "own", "happen"],
          antonyms: ["release"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The cargo area of a ship or aircraft (often holds or cargo hold).",
              synonyms: [],
              antonyms: [],
              example: "Put that in the hold.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hold",
        "https://en.wiktionary.org/wiki/holds",
      ],
    },
  ],
  fixed: [
    {
      word: "fixed",
      phonetic: "/fɪkst/",
      phonetics: [
        {
          text: "/fɪkst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fixed-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=857037",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To pierce; now generally replaced by transfix.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To attach; to affix; to hold in place or at a particular time.",
              synonyms: [],
              antonyms: [],
              example:
                "A dab of chewing gum will fix your note to the bulletin board.",
            },
            {
              definition: "To mend, to repair.",
              synonyms: [],
              antonyms: [],
              example: "That heater will start a fire if you don't fix it.",
            },
            {
              definition: "To prepare (food or drink).",
              synonyms: [],
              antonyms: [],
              example: "She fixed dinner for the kids.",
            },
            {
              definition:
                "To make (a contest, vote, or gamble) unfair; to privilege one contestant or a particular group of contestants, usually before the contest begins; to arrange immunity for defendants by tampering with the justice system via bribery or extortion",
              synonyms: [],
              antonyms: [],
              example:
                "A majority of voters believed the election was fixed in favor of the incumbent.",
            },
            {
              definition:
                "To surgically render an animal, especially a pet, infertile.",
              synonyms: [],
              antonyms: [],
              example:
                "Rover stopped digging under the fence after we had the vet fix him.",
            },
            {
              definition: "(sematics) To map a (point or subset) to itself.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To take revenge on, to best; to serve justice on an assumed miscreant.",
              synonyms: [],
              antonyms: [],
              example:
                "He got caught breaking into lockers, so a couple of guys fixed him after work.",
            },
            {
              definition:
                "To render (a photographic impression) permanent by treating with such applications as will make it insensitive to the action of light.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To convert into a stable or available form.",
              synonyms: [],
              antonyms: [],
              example:
                "Legumes are valued in crop rotation for their ability to fix nitrogen.",
            },
            {
              definition:
                "To become fixed; to settle or remain permanently; to cease from wandering; to rest.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To become firm, so as to resist volatilization; to cease to flow or be fluid; to congeal; to become hard and malleable, as a metallic substance.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "join",
            "put together",
            "unite",
            "doctor",
            "rig",
            "patch",
            "put to rights",
            "rectify",
            "impale",
            "run through",
            "stick",
            "castrate",
            "desex",
            "neuter",
            "spay",
            "establish",
            "settle down",
          ],
          antonyms: ["change", "move"],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Not changing, not able to be changed, staying the same.",
              synonyms: [],
              antonyms: [],
              example: "Every religion has its own fixed ideas.",
            },
            { definition: "Stationary.", synonyms: [], antonyms: [] },
            { definition: "Attached; affixed.", synonyms: [], antonyms: [] },
            { definition: "Chemically stable.", synonyms: [], antonyms: [] },
            {
              definition: "Supplied with what one needs.",
              synonyms: [],
              antonyms: [],
              example: "She's nicely fixed after two divorce settlements.",
            },
            {
              definition: "Of sound, recorded on a permanent medium.",
              synonyms: [],
              antonyms: [],
              example:
                "In the United States, recordings are only granted copyright protection when the sounds in the recording were fixed and first published on or after February 15, 1972.",
            },
            {
              definition:
                "Surgically rendered infertile (spayed, neutered or castrated).",
              synonyms: [],
              antonyms: [],
              example: "a fixed tomcat; the she-cat has been fixed",
            },
            {
              definition: "Rigged; fraudulently prearranged.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of a problem) Resolved; corrected.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Repaired", synonyms: [], antonyms: [] },
          ],
          synonyms: ["immobile", "stable"],
          antonyms: ["mobile"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fix",
        "https://en.wiktionary.org/wiki/fixed",
      ],
    },
  ],
  costs: [
    {
      word: "costs",
      phonetic: "/ˈkɑsts/",
      phonetics: [
        { text: "/ˈkɑsts/", audio: "" },
        { text: "/ˈkɒsts/", audio: "" },
        {
          text: "/ˈkɔsts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/costs-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749768",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To incur a charge of; to require payment of a (specified) price.",
              synonyms: [],
              antonyms: [],
              example:
                "It will cost you a lot of money to take a trip around the world.",
            },
            {
              definition:
                "To cause something to be lost; to cause the expenditure or relinquishment of.",
              synonyms: [],
              antonyms: [],
              example:
                "Trying to rescue the man from the burning building cost them their lives.",
            },
            {
              definition: "To require to be borne or suffered; to cause.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To calculate or estimate a price.",
              synonyms: [],
              antonyms: [],
              example: "I'd cost the repair work at a few thousand.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Amount of money, time, etc. that is required or used.",
              synonyms: [],
              antonyms: [],
              example:
                "The average cost of a new house is twice as much as it was 20 years ago.",
            },
            {
              definition:
                "A negative consequence or loss that occurs or is required to occur.",
              synonyms: [],
              antonyms: [],
              example:
                "Spending all your time working may earn you a lot of money at the cost of your health.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Manner; way; means; available course; contrivance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Quality; condition; property; value; worth; a wont or habit; disposition; nature; kind; characteristic.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A rib; a side.", synonyms: [], antonyms: [] },
            { definition: "A cottise.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cost",
        "https://en.wiktionary.org/wiki/costs",
      ],
    },
  ],
  calls: [
    {
      word: "calls",
      phonetic: "/kɑlz/",
      phonetics: [
        { text: "/kɑlz/", audio: "" },
        { text: "/kɔːlz/", audio: "" },
        {
          text: "/kɔlz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/calls-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1235944",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A telephone conversation.",
              synonyms: [],
              antonyms: [],
              example: "I received several calls today.",
            },
            {
              definition: "A short visit, usually for social purposes.",
              synonyms: [],
              antonyms: [],
              example: "I paid a call to a dear friend of mine.",
            },
            {
              definition: "A visit by a ship or boat to a port.",
              synonyms: [],
              antonyms: [],
              example: "The ship made a call at Southampton.",
            },
            {
              definition: "A cry or shout.",
              synonyms: [],
              antonyms: [],
              example: "He heard a call from the other side of the room.",
            },
            {
              definition: "A decision or judgement.",
              synonyms: [],
              antonyms: [],
              example: "That was a good call.",
            },
            {
              definition: "The characteristic cry of a bird or other animal.",
              synonyms: [],
              antonyms: [],
              example: "That sound is the distinctive call of the cuckoo bird.",
            },
            {
              definition: "A beckoning or summoning.",
              synonyms: [],
              antonyms: [],
              example: "I had to yield to the call of the wild.",
            },
            {
              definition:
                "The right to speak at a given time during a debate or other public event; the floor.",
              synonyms: [],
              antonyms: [],
              example: "I give the call to the Manager of Opposition Business.",
            },
            {
              definition:
                "An option to buy stock at a specified price during or at a specified time.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The act of calling to the other batsman.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The state of being the batsman whose role it is to call (depends on where the ball goes.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A work shift which requires one to be available when requested (see on call).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The act of jumping to a subprogram, saving the means to return to the original point.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A statement of a particular state, or rule, made in many games such as bridge, craps, jacks, and so on.",
              synonyms: [],
              antonyms: [],
              example:
                "There was a 20 dollar bet on the table, and my call was 9.",
            },
            {
              definition:
                "The act of matching a bet made by a player who has previously bet in the same round of betting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A note blown on the horn to encourage the dogs in a hunt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A whistle or pipe, used by the boatswain and his mate to summon the sailors to duty.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A pipe or other instrument to call birds or animals by imitating their note or cry. A game call.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An invitation to take charge of or serve a church as its pastor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Vocation; employment; calling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A reference to, or statement of, an object, course, distance, or other matter of description in a survey or grant requiring or calling for a corresponding object, etc., on the land.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(prostitution) A meeting with a client for paid sex; hookup; job.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(heading) To use one's voice.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "(heading) To visit.", synonyms: [], antonyms: [] },
            {
              definition: "(heading) To name, identify or describe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(heading) Direct or indirect use of the voice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(sometimes with for) To require, demand.",
              synonyms: [],
              antonyms: [],
              example: "He felt called to help the old man.",
            },
            {
              definition:
                "To announce the early extinction of a debt by prepayment, usually at a premium.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To demand repayment of a loan.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To jump to (another part of a program) to perform some operation, returning to the original point on completion.",
              synonyms: [],
              antonyms: [],
              example: "A recursive function is one that calls itself.",
            },
          ],
          synonyms: [
            "drop a line",
            "ring",
            "holler",
            "yell",
            "designate",
            "dub",
            "name",
            "augur",
            "foretell",
            "wake up",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/call",
        "https://en.wiktionary.org/wiki/calls",
      ],
    },
  ],
  blank: [
    {
      word: "blank",
      phonetic: "/blæŋk/",
      phonetics: [
        {
          text: "/blæŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/blank-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241208",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small French coin, originally of silver, afterwards of copper, worth 5 deniers; also a silver coin of Henry V current in the parts of France then held by the English, worth about 8 pence .",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A nonplus .", synonyms: [], antonyms: [] },
            {
              definition:
                "The white spot in the centre of a target; hence the object to which anything is directed or aimed, the range of such aim .",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A lot by which nothing is gained; a ticket in a lottery on which no prize is indicated .",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An empty space; a void, for example on a paper .",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(now chiefly U.S.) A document, paper, or form with spaces left blank to be filled up at the pleasure of the person to whom it is given (e.g. a blank charter, ballot, form, contract, etc.), or as the event may determine; a blank form .",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Blank verse .", synonyms: [], antonyms: [] },
            {
              definition:
                "A piece of metal (such as a coin, screw, nuts), cut and shaped to the required size of the thing to be made, and ready for the finishing operations; (coining) the disc of metal before stamping .",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A vacant space, place, or period; a void .",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The 1 / 230400 of a grain .",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An empty space in one's memory; a forgotten item or memory .",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A dash written in place of an omitted letter or word",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The space character; the character resulting from pressing the space-bar on a keyboard.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A domino without points on one or both of its divisions.",
              synonyms: [],
              antonyms: [],
              example: "the double blank",
            },
            {
              definition: "Short for blank cartridge. .",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in the expression ‘shooting blanks’) An ineffective effort which achieves nothing .",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["blank bullet", "blank cartridge"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make void; to erase.",
              synonyms: [],
              antonyms: [],
              example: "I blanked out my previous entry.",
            },
            {
              definition: "To ignore (a person) deliberately.",
              synonyms: [],
              antonyms: [],
              example: "She blanked me for no reason.",
            },
            {
              definition:
                "To prevent from scoring, for example in a sporting event.",
              synonyms: [],
              antonyms: [],
              example: "England blanks Wales to advance to the final.",
            },
            { definition: "To become blank.", synonyms: [], antonyms: [] },
            {
              definition: "To be temporarily unable to remember.",
              synonyms: [],
              antonyms: [],
              example: "I'm blanking on her name right now.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "White or pale; without colour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Free from writing, printing, or marks; having an empty space to be filled in",
              synonyms: [],
              antonyms: [],
              example: "a blank ballot",
            },
            {
              definition: "Scoreless; without any goals or points.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Lacking characteristics which give variety; uniform.",
              synonyms: [],
              antonyms: [],
              example: "a blank desert; a blank wall; blank unconsciousness",
            },
            {
              definition: "Absolute; downright; sheer.",
              synonyms: [],
              antonyms: [],
              example: "There was a look of blank terror on his face.",
            },
            {
              definition: "Without expression.",
              synonyms: [],
              antonyms: [],
              example:
                "Failing to understand the question, he gave me a blank stare.",
            },
            {
              definition: "Utterly confounded or discomfited.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Empty; void; without result; fruitless.",
              synonyms: [],
              antonyms: [],
              example: "a blank day",
            },
            {
              definition: "Devoid of thoughts, memory, or inspiration.",
              synonyms: [],
              antonyms: [],
              example: "The shock left his memory blank.",
            },
            {
              definition:
                "Of ammunition: having propellant but no bullets; unbulleted.",
              synonyms: [],
              antonyms: [],
              example:
                "The recruits were issued with blank rounds for a training exercise.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/blank"],
    },
  ],
  staff: [
    {
      word: "staff",
      phonetic: "/ˈstæf/",
      phonetics: [
        { text: "/ˈstæf/", audio: "" },
        { text: "/stɑːf/", audio: "" },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/staff-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1684744",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(plural staffs or staves) A long, straight, thick wooden rod or stick, especially one used to assist in walking.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(plural staves) A series of horizontal lines on which musical notes are written; a stave.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(plural staff or staffs) The employees of a business.",
              synonyms: [],
              antonyms: [],
              example:
                "The company employed 10 new members of staff this month.",
            },
            {
              definition:
                "A mixture of plaster and fibre used as a temporary exterior wall covering.W",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A pole, stick, or wand borne as an ensign of authority; a badge of office.",
              synonyms: [],
              antonyms: [],
              example: "a constable's staff",
            },
            {
              definition:
                "A pole upon which a flag is supported and displayed.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The rung of a ladder.", synonyms: [], antonyms: [] },
            {
              definition:
                "A series of verses so disposed that, when it is concluded, the same order begins again; a stanza; a stave.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An arbor, as of a wheel or a pinion of a watch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The grooved director for the gorget, or knife, used in cutting for stone in the bladder.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An establishment of officers in various departments attached to an army, to a section of an army, or to the commander of an army. The general's staff consists of those officers about his person who are employed in carrying his commands into execution.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["personnel", "stave"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To supply (a business, volunteer organization, etc.) with employees or staff members.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/staff"],
    },
  ],
  labor: [
    {
      word: "labor",
      phonetic: "/ˈleɪ.bɚ/",
      phonetics: [
        {
          text: "/ˈleɪ.bɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/labor-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=473936",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Effort expended on a particular task; toil, work.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That which requires hard work for its accomplishment; that which demands effort.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Workers in general; the working class, the workforce; sometimes specifically the labour movement, organised labour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A political party or force aiming or claiming to represent the interests of labour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The act of a mother giving birth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The time period during which a mother gives birth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The pitching or tossing of a vessel which results in the straining of timbers and rigging.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An old measure of land area in Mexico and Texas, approximately 177 acres.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To toil, to work.", synonyms: [], antonyms: [] },
            {
              definition:
                "To belabour, to emphasise or expand upon (a point in a debate, etc).",
              synonyms: [],
              antonyms: [],
              example:
                "I think we've all got the idea. There's no need to labour the point.",
            },
            {
              definition:
                "To be oppressed with difficulties or disease; to do one's work under conditions which make it especially hard or wearisome; to move slowly, as against opposition, or under a burden.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To suffer the pangs of childbirth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To pitch or roll heavily, as a ship in a turbulent sea.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/labor",
        "https://en.wiktionary.org/wiki/labour",
      ],
    },
  ],
  eaten: [
    {
      word: "eaten",
      phonetic: "/ˈiːt(ə)n/",
      phonetics: [
        {
          text: "/ˈiːt(ə)n/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/eaten-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100381",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To ingest; to be ingested.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To use up.", synonyms: [], antonyms: [] },
            {
              definition: "To cause (someone) to worry.",
              synonyms: [],
              antonyms: [],
              example: "What’s eating you?",
            },
            {
              definition: "To take the loss in a transaction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be injured or killed by (something such as a firearm or its projectile), especially in the mouth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To corrode or erode.",
              synonyms: [],
              antonyms: [],
              example:
                "The acid rain ate away the statue.  The strong acid eats through the metal.",
            },
            {
              definition: "To perform oral sex (on a person or body part).",
              synonyms: [],
              antonyms: [],
              example: "Eat me!",
            },
          ],
          synonyms: [
            "bother",
            "disturb",
            "worry",
            "consume",
            "swallow",
            "breakfast",
            "chow down",
            "dine",
            "dinner",
            "feed one's face",
            "lunch",
            "supper",
            "tea",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(especially in combination) That has been consumed by eating",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/eat",
        "https://en.wiktionary.org/wiki/eaten",
      ],
    },
  ],
  youth: [
    {
      word: "youth",
      phonetic: "/juθ/",
      phonetics: [
        {
          text: "/juθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/youth-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1235998",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The quality or state of being young.",
              synonyms: ["juvenility", "youthfulness"],
              antonyms: ["age", "dotage", "old age", "senility"],
              example: "Her youth and beauty attracted him to her.",
            },
            {
              definition:
                "The part of life following childhood; the period of existence preceding maturity or age; the whole early part of life, from childhood, or, sometimes, from infancy, to adulthood.",
              synonyms: [],
              antonyms: [],
              example:
                "I made many mistakes in my youth, but learned from them all.",
            },
            {
              definition: "A young person.",
              synonyms: [
                "adolescent",
                "child",
                "kid",
                "lad",
                "teen",
                "teenager",
                "youngster",
              ],
              antonyms: ["adult", "grown-up"],
              example:
                "There was a group of youths hanging around the parking lot, reading fashion magazines and listening to music.",
            },
            {
              definition: "A young man; a male adolescent or young adult.",
              synonyms: ["boy", "young man"],
              antonyms: [],
            },
            {
              definition:
                "(used with a plural or singular verb) Young persons, collectively.",
              synonyms: [
                "adolescents",
                "kids",
                "teenagers",
                "teens",
                "young people",
                "youngsters",
              ],
              antonyms: [],
            },
          ],
          synonyms: [
            "adolescent",
            "child",
            "kid",
            "lad",
            "teen",
            "teenager",
            "youngster",
            "adolescents",
            "kids",
            "teenagers",
            "teens",
            "young people",
            "youngsters",
            "boy",
            "young man",
            "juvenility",
            "youthfulness",
          ],
          antonyms: [
            "adult",
            "grown-up",
            "age",
            "dotage",
            "old age",
            "senility",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/youth"],
    },
  ],
  tones: [
    {
      word: "tones",
      phonetic: "/təʊnz/",
      phonetics: [
        { text: "/təʊnz/", audio: "" },
        {
          text: "/toʊnz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tones-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2079766",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A specific pitch.", synonyms: [], antonyms: [] },
            {
              definition:
                "(in the diatonic scale) An interval of a major second.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(in a Gregorian chant) A recitational melody.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The character of a sound, especially the timbre of an instrument or voice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "General character, mood, or trend.",
              synonyms: [],
              antonyms: [],
              example:
                "Her rousing speech gave an upbeat tone to the rest of the evening.",
            },
            {
              definition:
                "The pitch of a word that distinguishes a difference in meaning, for example in Chinese.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A whining style of speaking; a kind of mournful or artificial strain of voice; an affected speaking with a measured rhythm and a regular rise and fall of the voice.",
              synonyms: [],
              antonyms: [],
              example: "Children often read with a tone.",
            },
            {
              definition: "The manner in which speech or writing is expressed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "State of mind; temper; mood.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The shade or quality of a colour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The favourable effect of a picture produced by the combination of light and shade, or of colours.",
              synonyms: [],
              antonyms: [],
              example: "This picture has tone.",
            },
            {
              definition:
                "The definition and firmness of a muscle or organ; see also: tonus.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The state of a living body or of any of its organs or parts in which the functions are healthy and performed with due vigor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Normal tension or responsiveness to stimuli.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A gun", synonyms: [], antonyms: [] },
          ],
          synonyms: ["whole tone"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To give a particular tone to",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To change the colour of",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make (something) firmer",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To harmonize, especially in colour",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To utter with an affected tone.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "color",
            "colour",
            "dye",
            "paint",
            "tint",
            "harmonise",
            "harmonize",
            "firm",
            "firm up",
            "tone up",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tone",
        "https://en.wiktionary.org/wiki/tones",
      ],
    },
  ],
  honor: [
    {
      word: "honor",
      phonetic: "/ˈɒn.ə/",
      phonetics: [
        { text: "/ˈɒn.ə/", audio: "" },
        {
          text: "/ˈɑn.ɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/honor-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1231889",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Recognition of importance or value; respect; veneration (of someone, usually for being morally upright or successful)",
              synonyms: [],
              antonyms: [],
              example:
                "The crowds gave the returning general much honor and praise.",
            },
            {
              definition:
                "The state of being morally upright, honest, noble, virtuous, and magnanimous; excellence of character; the perception of such a state; favourable reputation; dignity",
              synonyms: [],
              antonyms: [],
              example:
                "He was a most perfect knight, for he had great honor and chivalry.",
            },
            {
              definition:
                "A token of praise or respect; something that represents praiseworthiness or respect, such as a prize or award given by the state to a citizen",
              synonyms: [],
              antonyms: [],
              example:
                "Audie Murphy received many honors, such as the Distinguished Service Cross.",
            },
            {
              definition: "A privilege",
              synonyms: [],
              antonyms: [],
              example: "I had the honour of dining with the ambassador.",
            },
            {
              definition: "(in the plural) the privilege of going first",
              synonyms: [],
              antonyms: [],
              example: "I'll let you have the honours, Bob—go ahead.",
            },
            {
              definition:
                "A cause of respect and fame; a glory; an excellency; an ornament.",
              synonyms: [],
              antonyms: [],
              example: "He is an honour to his nation.",
            },
            {
              definition:
                "(feudal law) a seigniory or lordship held of the king, on which other lordships and manors depended",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The center point of the upper half of an armorial escutcheon (compare honour point)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In bridge, an ace, king, queen, jack, or ten especially of the trump suit. In some other games, an ace, king, queen or jack.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in the plural) (courses for) an honours degree: a university qualification of the highest rank",
              synonyms: [],
              antonyms: [],
              example: "At university I took honours in modern history.",
            },
          ],
          synonyms: ["chivalry", "gentlemanliness", "glory"],
          antonyms: ["dishonor"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To think of highly, to respect highly; to show respect for; to recognise the importance or spiritual value of",
              synonyms: [],
              antonyms: [],
              example:
                "The freedom fighters will be forever remembered and honored by the people.",
            },
            {
              definition:
                "To conform to, abide by, act in accordance with (an agreement, treaty, promise, request, or the like)",
              synonyms: [],
              antonyms: [],
              example: "I trusted you, but you have not honored your promise.",
            },
            {
              definition:
                "To confer (bestow) an honour or privilege upon (someone)",
              synonyms: [],
              antonyms: [],
              example:
                "Ten members of the profession were honored at the ceremony.",
            },
            {
              definition:
                "To make payment in respect of (a cheque, banker's draft, etc.)",
              synonyms: [],
              antonyms: [],
              example:
                "I'm sorry Sir, but the bank did not honour your cheque.",
            },
          ],
          synonyms: ["worthy"],
          antonyms: ["contempt", "despise"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/honor"],
    },
  ],
  globe: [
    {
      word: "globe",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/globe-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75271505",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ɡloːb/", audio: "" },
        { text: "/ɡləʊb/", audio: "" },
        { text: "/ɡloʊb/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Any spherical (or nearly spherical) object.",
              synonyms: [],
              antonyms: [],
              example: "the globe of the eye; the globe of a lamp",
            },
            { definition: "The planet Earth.", synonyms: [], antonyms: [] },
            {
              definition: "A spherical model of Earth or any planet.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A light bulb.", synonyms: [], antonyms: [] },
            {
              definition:
                "A circular military formation used in Ancient Rome, corresponding to the modern infantry square.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(chiefly in the plural) A woman's breast.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A group.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["Earth", "Sol III", "Terra", "world"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To become spherical.", synonyms: [], antonyms: [] },
            { definition: "To make spherical.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/globe"],
    },
  ],
  gases: [
    {
      word: "gases",
      phonetic: "/ˈɡæsɪz/",
      phonetics: [{ text: "/ˈɡæsɪz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Matter in a state intermediate between liquid and plasma that can be contained only if it is fully surrounded by a solid (or in a bubble of liquid) (or held together by gravitational pull); it can condense into a liquid, or can (rarely) become a solid directly.",
              synonyms: ["vapor / vapour"],
              antonyms: [],
              example: "A lot of gas had escaped from the cylinder.",
            },
            {
              definition: "A chemical element or compound in such a state.",
              synonyms: [],
              antonyms: [],
              example:
                "The atmosphere is made up of a number of different gases.",
            },
            {
              definition:
                "A flammable gaseous hydrocarbon or hydrocarbon mixture (typically predominantly methane) used as a fuel, e.g. for cooking, heating, electricity generation or as a fuel in internal combustion engines in vehicles.",
              synonyms: [],
              antonyms: [],
              example:
                "Gas-fired power stations have largely replaced coal-burning ones.",
            },
            {
              definition: "A hob on a gas cooker.",
              synonyms: [],
              antonyms: [],
              example:
                "She turned the gas on, put the potatoes on, then lit the oven.",
            },
            {
              definition:
                "Methane or other waste gases trapped in one's belly as a result of the digestive process.",
              synonyms: [],
              antonyms: [],
              example: "My tummy hurts so bad, I have gas.",
            },
            {
              definition: "A humorous or entertaining event or person.",
              synonyms: [],
              antonyms: [],
              example: "He is such a gas!",
            },
            { definition: "Frothy talk; chatter.", synonyms: [], antonyms: [] },
            {
              definition: "A fastball.",
              synonyms: [],
              antonyms: [],
              example: "The closer threw him nothing but gas.",
            },
            {
              definition: "Arterial or venous blood gas.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Marijuana, typically of high quality.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["fart", "wind", "vapor / vapour"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To kill with poisonous gas.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To talk in a boastful or vapid way; chatter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To impose upon by talking boastfully.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To emit gas.",
              synonyms: [],
              antonyms: [],
              example: "The battery cell was gassing.",
            },
            {
              definition: "To impregnate with gas.",
              synonyms: [],
              antonyms: [],
              example:
                "to gas lime with chlorine in the manufacture of bleaching powder",
            },
            {
              definition:
                "To singe, as in a gas flame, so as to remove loose fibers.",
              synonyms: [],
              antonyms: [],
              example: "to gas thread",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Gasoline; a derivative of petroleum used as fuel.",
              synonyms: ["gasoline", "petrol"],
              antonyms: [],
            },
            { definition: "Gas pedal.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["gasoline", "petrol"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To give a vehicle more fuel in order to accelerate it.",
              synonyms: ["en", "hit the gas", "step on the gas"],
              antonyms: [],
              example: "The cops are coming. Gas it!",
            },
            {
              definition: "To fill (a vehicle's fuel tank) with fuel.",
              synonyms: ["refuel"],
              antonyms: [],
            },
          ],
          synonyms: ["en", "hit the gas", "step on the gas", "refuel"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gas",
        "https://en.wiktionary.org/wiki/gases",
      ],
    },
  ],
  doors: [
    {
      word: "doors",
      phonetic: "/dɔːz/",
      phonetics: [
        { text: "/dɔːz/", audio: "" },
        {
          text: "/dɔɹz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/doors-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1669293",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A portal of entry into a building, room, or vehicle, consisting of a rigid plane movable on a hinge. Doors are frequently made of wood or metal. May have a handle to help open and close, a latch to hold the door closed and a lock that ensures the door cannot be opened without the key.",
              synonyms: [],
              antonyms: [],
              example: "I knocked on the vice president's door",
            },
            {
              definition: "Any flap, etc. that opens like a door.",
              synonyms: [],
              antonyms: [],
              example: "the 24 doors in an Advent calendar",
            },
            {
              definition: "(immigration) An entry point.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A means of approach or access.",
              synonyms: [],
              antonyms: [],
              example: "Learning is the door to wisdom.",
            },
            {
              definition: "A barrier.",
              synonyms: [],
              antonyms: [],
              example: "Keep a door on your anger.",
            },
            {
              definition:
                "A software mechanism by which a user can interact with a program running remotely on a bulletin board system. See BBS door.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cause a collision by opening the door of a vehicle in front of an oncoming cyclist or pedestrian.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/door",
        "https://en.wiktionary.org/wiki/doors",
      ],
    },
  ],
  poles: [
    {
      word: "poles",
      phonetic: "/pəʊlz/",
      phonetics: [
        { text: "/pəʊlz/", audio: "" },
        {
          text: "/poʊlz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/poles-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651313",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Originally, a stick; now specifically, a long and slender piece of metal or (especially) wood, used for various construction or support purposes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A type of basic fishing rod.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A long sports implement used for pole-vaulting; now made of glassfiber or carbon fiber, formerly also metal, bamboo and wood have been used.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(spotting) A telescope used to identify birds, aeroplanes or wildlife.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A unit of length, equal to a rod (1/4 chain or 5 1/2 yards).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Pole position.", synonyms: [], antonyms: [] },
            { definition: "A gun.", synonyms: [], antonyms: [] },
            { definition: "A penis", synonyms: [], antonyms: [] },
          ],
          synonyms: ["rod"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To propel by pushing with poles, to push with a pole.",
              synonyms: [],
              antonyms: [],
              example:
                "Huck Finn poled that raft southward down the Mississippi because going northward against the current was too much work.",
            },
            {
              definition:
                "To identify something quite precisely using a telescope.",
              synonyms: [],
              antonyms: [],
              example:
                "He poled off the serial of the Gulfstream to confirm its identity.",
            },
            {
              definition: "To furnish with poles for support.",
              synonyms: [],
              antonyms: [],
              example: "to pole beans or hops",
            },
            {
              definition: "To convey on poles.",
              synonyms: [],
              antonyms: [],
              example: "to pole hay into a barn",
            },
            {
              definition: "To stir, as molten glass, with a pole.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To strike (the ball) very hard.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Either of the two points on the earth's surface around which it rotates; also, similar points on any other rotating object.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A point of magnetic focus, especially each of the two opposing such points of a magnet (designated north and south).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A fixed point relative to other points or lines.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A contact on an electrical device (such as a battery) at which electric current enters or leaves.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "For a meromorphic function f(z), any point a for which f(z) \\rightarrow \\infty as z \\rightarrow a.",
              synonyms: [],
              antonyms: [],
              example:
                "The function f(z) = \\frac{1}{z-3} has a single pole at z = 3",
            },
            {
              definition: "The firmament; the sky.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Either of the states that characterize a bipolar disorder.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["zero"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To induce piezoelectricity in (a substance) by aligning the dipoles.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pole",
        "https://en.wiktionary.org/wiki/poles",
      ],
    },
  ],
  loose: [
    {
      word: "loose",
      phonetic: "/luːs/",
      phonetics: [
        {
          text: "/luːs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/loose-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=623008",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The release of an arrow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A state of laxity or indulgence; unrestrained freedom, abandonment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "All play other than set pieces (scrums and line-outs).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Freedom from restraint.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A letting go; discharge.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To let loose, to free from restraints.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To unfasten, to loosen.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make less tight, to loosen.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of a grip or hold, to let go.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To shoot (an arrow)", synonyms: [], antonyms: [] },
            { definition: "To set sail.", synonyms: [], antonyms: [] },
            {
              definition: "To solve; to interpret.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "fire",
            "shoot",
            "free",
            "release",
            "loosen",
            "relax",
            "slacken",
            "let go",
            "release",
            "loosen",
            "unbind",
            "undo",
            "unfasten",
            "untie",
          ],
          antonyms: [
            "fast",
            "bind",
            "constrain",
            "tighten",
            "tighten",
            "bind",
            "fasten",
            "tie",
          ],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Not fixed in place tightly or firmly.",
              synonyms: [],
              antonyms: [],
              example: "This wheelbarrow has a loose wheel.",
            },
            {
              definition: "Not held or packaged together.",
              synonyms: [],
              antonyms: [],
              example:
                "'You can buy apples in a pack, but they are cheaper loose.",
            },
            {
              definition: "Not under control.",
              synonyms: [],
              antonyms: [],
              example: "The dog is loose again.",
            },
            {
              definition: "Not fitting closely",
              synonyms: [],
              antonyms: [],
              example: "'I wear loose clothes when it is hot.",
            },
            {
              definition: "Not compact.",
              synonyms: [],
              antonyms: [],
              example: "'It is difficult walking on loose gravel.",
            },
            {
              definition: "Relaxed.",
              synonyms: [],
              antonyms: [],
              example: "She danced with a loose flowing movement.",
            },
            {
              definition: "Not precise or exact; vague; indeterminate.",
              synonyms: [],
              antonyms: [],
              example: "a loose way of reasoning",
            },
            {
              definition: "Indiscreet.",
              synonyms: [],
              antonyms: [],
              example: "Loose talk costs lives.",
            },
            {
              definition:
                "(somewhat dated) Free from moral restraint; immoral, unchaste.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Not being in the possession of any competing team during a game.",
              synonyms: [],
              antonyms: [],
              example: "He caught an elbow going after a loose ball.",
            },
            {
              definition: "Not costive; having lax bowels.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "indiscreet",
            "free",
            "untethered",
            "diffuse",
            "spaced out",
            "baggy",
            "unfastened",
            "unsecured",
            "separate",
            "unpackaged",
            "polygamous",
            "promiscuous",
            "slutty",
            "tarty",
            "whorish",
            "loose-limbed",
            "relaxed",
          ],
          antonyms: [
            "discreet",
            "bound",
            "leashed",
            "tethered",
            "tied",
            "tied up",
            "compact",
            "firm",
            "close-fitting",
            "snug",
            "tight",
            "firm",
            "tight",
            "packaged",
            "faithful",
            "monogamous",
            "tense",
            "tensed",
          ],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "Begin shooting; release your arrows",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["fast"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/loose"],
    },
  ],
  apply: [
    {
      word: "apply",
      phonetic: "/əˈplaɪ/",
      phonetics: [
        {
          text: "/əˈplaɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/apply-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1676689",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To lay or place; to put (one thing to another)",
              synonyms: [],
              antonyms: [],
              example: "to apply cream to a rash",
            },
            {
              definition:
                "To put to use; to use or employ for a particular purpose, or in a particular case",
              synonyms: ["appropriate", "devote", "use"],
              antonyms: [],
              example: "to apply funds to the repayment of a debt",
            },
            {
              definition:
                "To make use of, declare, or pronounce, as suitable, fitting, or relative",
              synonyms: [],
              antonyms: [],
              example:
                "We need to apply the skills we've learned to solve this problem",
            },
            {
              definition:
                "To put closely; to join; to engage and employ diligently, or with attention",
              synonyms: ["attach", "incline"],
              antonyms: [],
            },
            {
              definition:
                "To to address; to refer; generally used reflexively.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'To submit oneself as a candidate (with the adposition "to" designating the recipient of the submission, and the adposition "for" designating the position).',
              synonyms: [],
              antonyms: [],
              example:
                "I recently applied to the tavern for a job as a bartender.",
            },
            {
              definition:
                "To pertain or be relevant to a specified individual or group.",
              synonyms: [],
              antonyms: [],
              example: "That rule only applies to foreigners.",
            },
            {
              definition: "To busy; to keep at work; to ply.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To visit.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["appropriate", "devote", "use", "attach", "incline"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/apply"],
    },
    {
      word: "apply",
      phonetic: "/ˈæp(ə)li/",
      phonetics: [
        {
          text: "/ˈæp(ə)li/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/apply-2-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=83326140",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Resembling apples, apple-like.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of, or pertaining to, apples.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/appley",
        "https://en.wiktionary.org/wiki/apply",
      ],
    },
  ],
  tears: [
    {
      word: "tears",
      phonetic: "/tiːɹz/",
      phonetics: [
        { text: "/tiːɹz/", audio: "" },
        { text: "/tɪəz/", audio: "" },
        {
          text: "/tɪɹz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tears-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1229278",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A hole or break caused by tearing.",
              synonyms: [],
              antonyms: [],
              example: "A small tear is easy to mend, if it is on the seam.",
            },
            {
              definition: "A rampage.",
              synonyms: [],
              antonyms: [],
              example: "to go on a tear",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To rend (a solid material) by holding or restraining in two places and pulling apart, whether intentionally or not; to destroy or separate.",
              synonyms: [],
              antonyms: [],
              example: "He tore his coat on the nail.",
            },
            {
              definition: "To injure as if by pulling apart.",
              synonyms: [],
              antonyms: [],
              example: "He has a torn ligament.",
            },
            {
              definition:
                "To destroy or reduce abstract unity or coherence, such as social, political or emotional.",
              synonyms: [],
              antonyms: [],
              example: "He was torn by conflicting emotions.",
            },
            {
              definition: "To make (an opening) with force or energy.",
              synonyms: [],
              antonyms: [],
              example:
                "A piece of debris tore a tiny straight channel through the satellite.",
            },
            {
              definition: "(often with off or out) To remove by tearing.",
              synonyms: [],
              antonyms: [],
              example: "Tear the coupon out of the newspaper.",
            },
            {
              definition: "(of structures, with down) To demolish",
              synonyms: [],
              antonyms: [],
              example:
                "The slums were torn down to make way for the new development.",
            },
            {
              definition: "To become torn, especially accidentally.",
              synonyms: [],
              antonyms: [],
              example: "My dress has torn.",
            },
            {
              definition:
                "To move or act with great speed, energy, or violence.",
              synonyms: [],
              antonyms: [],
              example: "He tore into the backlog of complaints.",
            },
            {
              definition: "To smash or enter something with great force.",
              synonyms: [],
              antonyms: [],
              example:
                "The chain shot tore into the approaching line of infantry.",
            },
          ],
          synonyms: ["rend", "rip"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A drop of clear, salty liquid produced from the eyes by crying or irritation.",
              synonyms: [],
              antonyms: [],
              example: "Ryan wiped the tear from the paper he was crying on.",
            },
            {
              definition:
                "Something in the form of a transparent drop of fluid matter; also, a solid, transparent, tear-shaped drop, as of some balsams or resins.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(glass manufacture) A partially vitrified bit of clay in glass.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That which causes or accompanies tears; a lament; a dirge.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To produce tears.",
              synonyms: [],
              antonyms: [],
              example: "Her eyes began to tear in the harsh wind.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tear",
        "https://en.wiktionary.org/wiki/tears",
      ],
    },
  ],
  exact: [
    {
      word: "exact",
      phonetic: "/ɪɡˈzækt/",
      phonetics: [
        {
          text: "/ɪɡˈzækt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/exact-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=694304",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To demand and enforce the payment or performance of, sometimes in a forcible or imperious way.",
              synonyms: [],
              antonyms: [],
              example: "to exact tribute, fees, or obedience from someone",
            },
            {
              definition: "To make desirable or necessary.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To inflict; to forcibly obtain or produce.",
              synonyms: [],
              antonyms: [],
              example: "to exact revenge on someone",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Precisely agreeing with a standard, a fact, or the truth; perfectly conforming; neither exceeding nor falling short in any respect.",
              synonyms: [],
              antonyms: [],
              example: "He paid the exact debt.",
            },
            {
              definition:
                "Habitually careful to agree with a standard, a rule, or a promise; accurate; methodical; punctual.",
              synonyms: [],
              antonyms: [],
              example: "In my doings I was exact.",
            },
            {
              definition:
                "Precisely or definitely conceived or stated; strict.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a sequence of groups connected by homomorphisms) Such that the kernel of one homomorphism is the image of the preceding one.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "correct",
            "perfect",
            "precise",
            "true",
            "strict",
            "spot on",
          ],
          antonyms: ["approximate", "imprecise", "inexact", "loose"],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Exactly",
              synonyms: [],
              antonyms: [],
              example: "She's wearing the exact same sweater as I am!",
            },
          ],
          synonyms: ["accurately", "just", "precisely"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/exact"],
    },
  ],
  brush: [
    {
      word: "brush",
      phonetic: "/bɹʌʃ/",
      phonetics: [
        {
          text: "/bɹʌʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/brush-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=180093",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An implement consisting of multiple more or less flexible bristles or other filaments attached to a handle, used for any of various purposes including cleaning, painting, and arranging hair.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The act of brushing something.",
              synonyms: [],
              antonyms: [],
              example: "She gave her hair a quick brush.",
            },
            {
              definition:
                "A piece of conductive material, usually carbon, serving to maintain electrical contact between the stationary and rotating parts of a machine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A brush-like electrical discharge of sparks.",
              synonyms: ["corposant"],
              antonyms: [],
            },
            {
              definition:
                "Wild vegetation, generally larger than grass but smaller than trees.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A short and sometimes occasional encounter or experience.",
              synonyms: [],
              antonyms: [],
              example: "He has had brushes with communism from time to time.",
            },
            {
              definition: "The furry tail of an animal, especially of a fox.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A tuft of hair on the mandibles.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A short contest, or trial, of speed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An instrument, resembling a brush, used to produce a soft sound from drums or cymbals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'An on-screen tool for "painting" a particular colour or texture.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A set of defined design and parameters that produce drawn strokes of a certain texture and quality.",
              synonyms: [],
              antonyms: [],
              example: "donwloading brushes for Photoshop",
            },
            {
              definition:
                "In 3D video games, a convex polyhedron, especially one that defines structure of the play area.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The floorperson of a poker room, usually in a casino.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(North Wisconsin) Evergreen boughs, especially balsam, locally cut and baled for export, usually for use in making wreaths.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["corposant"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To clean with a brush.",
              synonyms: [],
              antonyms: [],
              example: "Brush your teeth.",
            },
            {
              definition: "To untangle or arrange with a brush.",
              synonyms: [],
              antonyms: [],
              example: "Brush your hair.",
            },
            {
              definition: "To apply with a brush.",
              synonyms: [],
              antonyms: [],
              example: "Brush the paint onto the walls.",
            },
            {
              definition: "To remove with a sweeping motion.",
              synonyms: [],
              antonyms: [],
              example: "Brush the flour off your clothes.",
            },
            {
              definition:
                "To touch with a sweeping motion, or lightly in passing.",
              synonyms: [],
              antonyms: [],
              example: "Her scarf brushed his skin.",
            },
            {
              definition: "To clean one's teeth by brushing them.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/brush"],
    },
  ],
  chest: [
    {
      word: "chest",
      phonetic: "/t͡ʃɛst/",
      phonetics: [
        {
          text: "/t͡ʃɛst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chest-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88517655",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/t͡ʃɛst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chest-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=840610",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A box, now usually a large strong box with a secure convex lid.",
              synonyms: [],
              antonyms: [],
              example: "The clothes are kept in a chest.",
            },
            { definition: "A coffin.", synonyms: [], antonyms: [] },
            {
              definition:
                "The place in which public money is kept; a treasury.",
              synonyms: [],
              antonyms: [],
              example: "You can take the money from the chest.",
            },
            { definition: "A chest of drawers.", synonyms: [], antonyms: [] },
            {
              definition:
                "The portion of the front of the human body from the base of the neck to the top of the abdomen; the thorax. Also the analogous area in other animals.",
              synonyms: [],
              antonyms: [],
              example: "She had a sudden pain in her chest.",
            },
            {
              definition: "A hit or blow made with one's chest.",
              synonyms: [],
              antonyms: [],
              example: "He scored with a chest into the goal.",
            },
          ],
          synonyms: ["trunk", "breast"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To hit with one's chest (front of one's body)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To deposit in a chest.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To place in a coffin.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chest"],
    },
    {
      word: "chest",
      phonetic: "/t͡ʃɛst/",
      phonetics: [
        {
          text: "/t͡ʃɛst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chest-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88517655",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/t͡ʃɛst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chest-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=840610",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Debate; quarrel; strife; enmity.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chest"],
    },
  ],
  layer: [
    {
      word: "layer",
      phonetic: "/leɪə/",
      phonetics: [
        { text: "/leɪə/", audio: "" },
        {
          text: "/ˈleɪ.ɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/layer-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780191",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A single thickness of some material covering a surface.",
              synonyms: [],
              antonyms: [],
              example:
                "After the first coat of paint dried, he applied another layer.",
            },
            {
              definition: "A (usually) horizontal deposit; a stratum.",
              synonyms: [],
              antonyms: [],
              example: "I find seven-layer cake a bit too rich.",
            },
            {
              definition: "One of the items in a hierarchy.",
              synonyms: [],
              antonyms: [],
              example: "mired in layers of deceit",
            },
            {
              definition:
                "(by analogy to a stack of transparencies) one in a stack of (initially transparent) drawing surfaces that comprise an image; used to keep elements of an image separate so that they can be modified independently from one another.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["lay", "stratum"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cut or divide (something) into layers",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To arrange (something) in layers.",
              synonyms: [],
              antonyms: [],
              example:
                "Layer the ribbons on top of one another to make an attractive pattern.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/layer"],
    },
    {
      word: "layer",
      phonetic: "/leɪə/",
      phonetics: [
        { text: "/leɪə/", audio: "" },
        {
          text: "/ˈleɪ.ɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/layer-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780191",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A person who lays things, such as tiles.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A mature female bird, insect, etc. that is able to lay eggs.",
              synonyms: [],
              antonyms: [],
              example:
                "When dealing with an infestation of headlice, the first step is to eliminate the layers.",
            },
            {
              definition: "A hen kept to lay eggs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A shoot of a plant, laid underground for growth.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/layer"],
    },
  ],
  whale: [
    {
      word: "whale",
      phonetic: "/weɪl/",
      phonetics: [
        {
          text: "/weɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/whale-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=623343",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of several species of large sea mammals of the infraorder Cetacea.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Something, or someone, that is very large.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Something, or someone, that is excellent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In a casino, a person who routinely bets at the maximum limit allowable.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An investor who deals with very large amounts of money.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) A video game player who spends large amounts of money on premium content.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To hunt for whales.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/whale"],
    },
    {
      word: "whale",
      phonetic: "/weɪl/",
      phonetics: [
        {
          text: "/weɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/whale-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=623343",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To thrash, to flog, to beat vigorously or soundly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/whale"],
    },
  ],
  minor: [
    {
      word: "minor",
      phonetic: "/ˈmaɪnɚ/",
      phonetics: [
        {
          text: "/ˈmaɪnɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/minor-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769999",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person who is below the age of majority, consent, criminal responsibility or other adult responsibilities and accountabilities.",
              synonyms: [],
              antonyms: [],
              example:
                "It is illegal to sell weapons to minors under the age of eighteen.",
            },
            {
              definition:
                "A subject area of secondary concentration of a student at a college or university, or the student who has chosen such a secondary concentration.",
              synonyms: [],
              antonyms: [],
              example: "I became an English minor.",
            },
            {
              definition: "Determinant of a square submatrix",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(British slang) A younger brother (especially at a public school).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small worker in a leaf-cutter ant colony, sized between a minim and a media.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The term of a syllogism which forms the subject of the conclusion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(campanology) Bell changes rung on six bells.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["underage"],
          antonyms: ["adult", "major"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To choose or have an area of secondary concentration as a student in a college or university.",
              synonyms: [],
              antonyms: [],
              example:
                "I had so many credit hours of English, I decided to minor in it.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of little significance or importance.",
              synonyms: [],
              antonyms: [],
              example:
                "The physical appearance of a candidate is a minor factor in recruitment.",
            },
            {
              definition:
                "Of a scale which has lowered scale degrees three, six, and seven relative to major, but with the sixth and seventh not always lowered",
              synonyms: [],
              antonyms: [],
              example: "a minor scale",
            },
            {
              definition:
                "Being the smaller of the two intervals denoted by the same ordinal number",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["major"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/minor"],
    },
  ],
  faith: [
    {
      word: "faith",
      phonetic: "/feɪθ/",
      phonetics: [
        {
          text: "/feɪθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/faith-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=399134",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A trust or confidence in the intentions or abilities of a person, object, or ideal from prior empirical evidence.",
              synonyms: [],
              antonyms: [],
              example: "I have faith in the goodness of my fellow man.",
            },
            {
              definition:
                "The process of forming or understanding abstractions, ideas, or beliefs, without empirical evidence, experience or observation.",
              synonyms: [],
              antonyms: [],
              example: "I have faith in the healing power of crystals.",
            },
            {
              definition: "A religious or spiritual belief system.",
              synonyms: [],
              antonyms: [],
              example: "The Christian faith.",
            },
            {
              definition:
                "An obligation of loyalty or fidelity and the observance of such an obligation.",
              synonyms: [],
              antonyms: [],
              example:
                "He acted in good faith to restore broken diplomatic ties after defeating the incumbent.",
            },
            { definition: "Credibility or truth.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["belief", "confidence", "conviction", "trust", "religion"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/faith"],
    },
  ],
  tests: [
    {
      word: "tests",
      phonetic: "/tests/",
      phonetics: [{ text: "/tests/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A challenge, trial.", synonyms: [], antonyms: [] },
            {
              definition:
                "A cupel or cupelling hearth in which precious metals are melted for trial and refinement.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(academia) An examination, given often during the academic term.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A session in which a product or piece of equipment is examined under everyday or extreme conditions to evaluate its durability, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(normally “Test”) A Test match.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The external calciferous shell, or endoskeleton, of an echinoderm, e.g. sand dollars and sea urchins.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Testa; seed coat.", synonyms: [], antonyms: [] },
            {
              definition: "Judgment; distinction; discrimination.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["examination", "quiz"],
          antonyms: ["recess"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To challenge.",
              synonyms: [],
              antonyms: [],
              example: "Climbing the mountain tested our stamina.",
            },
            {
              definition:
                "To refine (gold, silver, etc.) in a test or cupel; to subject to cupellation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To put to the proof; to prove the truth, genuineness, or quality of by experiment, or by some principle or standard; to try.",
              synonyms: [],
              antonyms: [],
              example:
                "to test the soundness of a principle; to test the validity of an argument",
            },
            {
              definition:
                "(academics) To administer or assign an examination, often given during the academic term, to (somebody).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To place a product or piece of equipment under everyday and/or extreme conditions and examine it for its durability, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be shown to be by test.",
              synonyms: [],
              antonyms: [],
              example: "He tested positive for cancer.",
            },
            {
              definition: "To examine or try, as by the use of some reagent.",
              synonyms: [],
              antonyms: [],
              example: "to test a solution by litmus paper",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A witness.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To attest (a document) legally, and date it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make a testament, or will.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/test",
        "https://en.wiktionary.org/wiki/tests",
      ],
    },
  ],
  judge: [
    {
      word: "judge",
      phonetic: "/d͡ʒʌd͡ʒ/",
      phonetics: [
        {
          text: "/d͡ʒʌd͡ʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/judge-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1235975",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A public official whose duty it is to administer the law, especially by presiding over trials and rendering judgments; a justice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person who decides the fate of someone or something that has been called into question.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person officiating at a sports event or similar.",
              synonyms: [],
              antonyms: [],
              example:
                "At a boxing match, the decision of the judges is final.",
            },
            {
              definition:
                "A person who evaluates something or forms an opinion.",
              synonyms: [],
              antonyms: [],
              example: "She is a good judge of wine.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/judge"],
    },
    {
      word: "judge",
      phonetic: "/d͡ʒʌd͡ʒ/",
      phonetics: [
        {
          text: "/d͡ʒʌd͡ʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/judge-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1235975",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To sit in judgment on; to pass sentence on.",
              synonyms: [],
              antonyms: [],
              example: "A higher power will judge you after you are dead.",
            },
            {
              definition: "To sit in judgment, to act as judge.",
              synonyms: [],
              antonyms: [],
              example: "Justices in this country judge without appeal.",
            },
            {
              definition: "To form an opinion on.",
              synonyms: [],
              antonyms: [],
              example: "I judge a man’s character by the cut of his suit.",
            },
            {
              definition:
                "To arbitrate; to pass opinion on something, especially to settle a dispute etc.",
              synonyms: [],
              antonyms: [],
              example: "We cannot both be right: you must judge between us.",
            },
            {
              definition: "To have as an opinion; to consider, suppose.",
              synonyms: [],
              antonyms: [],
              example: "I judge it safe to leave the house once again.",
            },
            {
              definition: "To form an opinion; to infer.",
              synonyms: [],
              antonyms: [],
              example: "I judge from the sky that it might rain later.",
            },
            {
              definition: "To criticize or label another person or thing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/judge"],
    },
  ],
  items: [
    {
      word: "items",
      phonetic: "/ˈaɪtəmz/",
      phonetics: [
        {
          text: "/ˈaɪtəmz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/items-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651086",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A distinct physical object.",
              synonyms: [],
              antonyms: [],
              example: "Tweezers are great for manipulating small items.",
            },
            {
              definition:
                "(by extension) An object that can be picked up for later use.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A line of text having a legal or other meaning; a separate particular in an account.",
              synonyms: [],
              antonyms: [],
              example: "In response to the first item, we deny all wrongdoing.",
            },
            {
              definition:
                "(psychometrics) A question on a test, which may include its answers.",
              synonyms: [],
              antonyms: [],
              example:
                "The exam has 100 items, each of which includes a correct response and three distractors.",
            },
            {
              definition: "A matter for discussion in an agenda.",
              synonyms: [],
              antonyms: [],
              example:
                "The first item for discussion is the budget for next year's picnic.",
            },
            {
              definition:
                "Two people who are having a relationship with each other.",
              synonyms: [],
              antonyms: [],
              example: "Jack and Jill are an item.",
            },
            {
              definition: "A short article in a newspaper.",
              synonyms: [],
              antonyms: [],
              example: "an item concerning the weather",
            },
            { definition: "A hint; an innuendo.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "subject",
            "topic",
            "article",
            "object",
            "thing",
            "couple",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To make a note of.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/item",
        "https://en.wiktionary.org/wiki/items",
      ],
    },
  ],
  worry: [
    {
      word: "worry",
      phonetic: "/ˈwɒɹi/",
      phonetics: [
        { text: "/ˈwɒɹi/", audio: "" },
        { text: "/ˈwɒɹi/", audio: "" },
        { text: "/ˈwʌɹi/", audio: "" },
        {
          text: "/ˈwɝɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/worry-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=21750995",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A strong feeling of anxiety.",
              synonyms: [],
              antonyms: [],
              example: "I'm afflicted by worry throughout the night.",
            },
            {
              definition: "An instance or cause of such a feeling.",
              synonyms: [],
              antonyms: [],
              example: "My main worry is that I'll miss the train.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To be troubled; to give way to mental anxiety or doubt.",
              synonyms: [],
              antonyms: [],
              example: "Stop worrying about your test, it’ll be fine.",
            },
            {
              definition:
                "Disturb the peace of mind of; afflict with mental agitation or distress.",
              synonyms: [],
              antonyms: [],
              example: "Your tone of voice worries me.",
            },
            {
              definition: "To harass; to irritate or distress.",
              synonyms: [],
              antonyms: [],
              example:
                "The President was worried into military action by persistent advisors.",
            },
            {
              definition:
                "To seize or shake by the throat, especially of a dog or wolf.",
              synonyms: [],
              antonyms: [],
              example: "Your dog’s been worrying sheep again.",
            },
            {
              definition: "To touch repeatedly, to fiddle with.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To strangle.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["fret"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/worry"],
    },
  ],
  waste: [
    {
      word: "waste",
      phonetic: "/weɪst/",
      phonetics: [
        {
          text: "/weɪst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/waste-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=830156",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Excess of material, useless by-products or damaged, unsaleable products; garbage; rubbish.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Excrement or urine.",
              synonyms: [],
              antonyms: [],
              example: "The cage was littered with animal waste",
            },
            {
              definition:
                "A waste land; an uninhabited desolate region; a wilderness or desert.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A place that has been laid waste or destroyed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A large tract of uncultivated land.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The part of the land of a manor (of whatever size) not used for cultivation or grazing, nowadays treated as common land.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A vast expanse of water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A disused mine or part of one.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The action or progress of wasting; extravagant consumption or ineffectual use.",
              synonyms: [],
              antonyms: [],
              example: "Her life seemed a waste",
            },
            {
              definition:
                "Large abundance of something, specifically without it being used.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Gradual loss or decay.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A decaying of the body by disease; wasting away.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'Destruction or devastation caused by war or natural disasters; See "to lay waste"',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cause of action which may be brought by the owner of a future interest in property against the current owner of that property to prevent the current owner from degrading the value or character of the property, either intentionally or through neglect.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Material derived by mechanical and chemical erosion from the land, carried by streams to the sea.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/waste"],
    },
    {
      word: "waste",
      phonetic: "/weɪst/",
      phonetics: [
        {
          text: "/weɪst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/waste-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=830156",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Uncultivated, uninhabited.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Barren; desert.", synonyms: [], antonyms: [] },
            {
              definition:
                "Rejected as being defective; eliminated as being worthless; produced in excess.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Superfluous; needless.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Dismal; gloomy; cheerless.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Unfortunate; disappointing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/waste"],
    },
    {
      word: "waste",
      phonetic: "/weɪst/",
      phonetics: [
        {
          text: "/weɪst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/waste-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=830156",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To devastate, destroy", synonyms: [], antonyms: [] },
            {
              definition:
                "To squander (money or resources) uselessly; to spend (time) idly.",
              synonyms: [],
              antonyms: [],
              example:
                "We wasted millions of dollars and several years on that project.",
            },
            { definition: "To kill; to murder.", synonyms: [], antonyms: [] },
            {
              definition:
                "To wear away by degrees; to impair gradually; to diminish by constant loss; to use up; to consume; to spend; to wear out.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Gradually lose weight, weaken, become frail.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be diminished; to lose bulk, substance, strength, value etc. gradually.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To damage, impair, or injure (an estate, etc.) voluntarily, or by allowing the buildings, fences, etc., to fall into decay.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["cack", "duppy", "top"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/waste"],
    },
  ],
  hoped: [
    {
      word: "hoped",
      phonetic: "/həʊpt/",
      phonetics: [
        { text: "/həʊpt/", audio: "" },
        {
          text: "/hoʊpt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hoped-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1669316",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To want something to happen, with a sense of expectation that it might.",
              synonyms: [],
              antonyms: [],
              example: "I am still hoping that all will turn out well.",
            },
            {
              definition: "To be optimistic; be full of hope; have hopes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To place confidence; to trust with confident expectation of good; usually followed by in.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To wish.",
              synonyms: [],
              antonyms: [],
              example: "I hope you all the best.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hope",
        "https://en.wiktionary.org/wiki/hoped",
      ],
    },
  ],
  strip: [
    {
      word: "strip",
      phonetic: "/stɹɪp/",
      phonetics: [
        {
          text: "/stɹɪp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/strip-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1123072",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A long, thin piece of land; any long, thin area.",
              synonyms: [],
              antonyms: [],
              example:
                "The countries were in dispute over the ownership of a strip of desert about 100 metres wide.",
            },
            {
              definition:
                "(usually countable, sometimes uncountable) A long, thin piece of any material; any such material collectively.",
              synonyms: [],
              antonyms: [],
              example:
                "I have some strip left over after fitting out the kitchen.",
            },
            { definition: "A comic strip.", synonyms: [], antonyms: [] },
            { definition: "A landing strip.", synonyms: [], antonyms: [] },
            { definition: "A strip steak.", synonyms: [], antonyms: [] },
            {
              definition:
                "A street with multiple shopping or entertainment possibilities.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The playing area, roughly 14 meters by 2 meters.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The uniform of a football team, or the same worn by supporters.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A trough for washing ore.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The issuing of a projectile from a rifled gun without acquiring the spiral motion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A television series aired at the same time daily (or at least on Mondays to Fridays), so that it appears as a strip straight across the weekly schedule.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/strip"],
    },
    {
      word: "strip",
      phonetic: "/stɹɪp/",
      phonetics: [
        {
          text: "/stɹɪp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/strip-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1123072",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act of removing one's clothes; a striptease.",
              synonyms: [],
              antonyms: [],
              example: "She stood up on the table and did a strip.",
            },
            {
              definition:
                "(of games) Denotes a version of a game in which losing players must progressively remove their clothes.",
              synonyms: [],
              antonyms: [],
              example: "strip poker; strip Scrabble",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To remove or take away, often in strips or stripes.",
              synonyms: [],
              antonyms: [],
              example:
                "Norm will strip the old varnish before painting the chair.",
            },
            {
              definition: "(usually intransitive) To take off clothing.",
              synonyms: [],
              antonyms: [],
              example:
                "Seeing that no one else was about, he stripped and dived into the river.",
            },
            {
              definition: "To perform a striptease.",
              synonyms: [],
              antonyms: [],
              example:
                "In the seedy club, a group of drunken men were watching a woman stripping.",
            },
            {
              definition:
                "To take away something from (someone or something); to plunder; to divest.",
              synonyms: [],
              antonyms: [],
              example:
                "The athlete was stripped of his medal after failing a drugs test.",
            },
            {
              definition: "To remove cargo from (a container).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To remove (the thread or teeth) from a screw, nut, or gear, especially inadvertently by overtightening.",
              synonyms: [],
              antonyms: [],
              example:
                "Don't tighten that bolt any more or you'll strip the thread.",
            },
            {
              definition:
                "To fail in the thread; to lose the thread, as a bolt, screw, or nut.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To remove color from hair, cloth, etc. to prepare it to receive new color.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To remove all cards of a particular suit from another player. (See also strip-squeeze.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To empty (tubing) by applying pressure to the outside of (the tubing) and moving that pressure along (the tubing).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To milk a cow, especially by stroking and compressing the teats to draw out the last of the milk.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To press out the ripe roe or milt from fishes, for artificial fecundation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To run a television series at the same time daily (or at least on Mondays to Fridays), so that it appears as a strip straight across the weekly schedule.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To pare off the surface of (land) in strips.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To remove the overlying earth from (a deposit).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To pass; to get clear of; to outstrip.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To remove the metal coating from (a plated article), as by acids or electrolytic action.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To remove fibre, flock, or lint from; said of the teeth of a card when it becomes partly clogged.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'To pick the cured leaves from the stalks of (tobacco) and tie them into "hands".',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To remove the midrib from (tobacco leaves).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["deprive", "peel", "uncover"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/strip"],
    },
  ],
  begun: [
    {
      word: "begun",
      phonetic: "/bɪˈɡʌn/",
      phonetics: [
        {
          text: "/bɪˈɡʌn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/begun-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1661630",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To start, to initiate or take the first step into something.",
              synonyms: [],
              antonyms: [],
              example:
                "I began playing the piano at the age of five.   Now that everyone is here, we should begin the presentation.",
            },
            {
              definition: "To be in the first stage of some situation",
              synonyms: [],
              antonyms: [],
              example:
                "The program begins at 9 o'clock on the dot.    I rushed to get to class on time, but the lesson had already begun.",
            },
            {
              definition: "To come into existence.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["commence", "initiate", "start"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/begin",
        "https://en.wiktionary.org/wiki/begun",
      ],
    },
  ],
  aside: [
    {
      word: "aside",
      phonetic: "/əˈsaɪd/",
      phonetics: [
        {
          text: "/əˈsaɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/aside-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1250035",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An incidental remark made quietly so as to be heard by the person to whom it is said and not by any others in the vicinity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A brief comment by a character addressing the audience, unheard by other characters.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A minor related mention, an afterthought.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["side note", "sidebar"],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "To or on one side so as to be out of the way.",
              synonyms: [],
              antonyms: [],
              example:
                "Move aside, please, so that these people can come through.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/aside"],
    },
  ],
  lakes: [
    {
      word: "lakes",
      phonetic: "/leɪks/",
      phonetics: [
        {
          text: "/leɪks/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lakes-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651116",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small stream of running water; a channel for water; a drain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A large, landlocked stretch of water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A large amount of liquid; as, a wine lake.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A pit, or ditch", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An offering, sacrifice, gift.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Play; sport; game; fun; glee.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To present an offering.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To leap, jump, exert oneself, play.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A kind of fine, white linen.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "In dyeing and painting, an often fugitive crimson or vermillion pigment derived from an organic colorant (cochineal or madder, for example) and an inorganic, generally metallic mordant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In the composition of colors for use in products intended for human consumption, made by extending on a substratum of alumina, a salt prepared from one of the certified water-soluble straight colors.",
              synonyms: [],
              antonyms: [],
              example:
                "For example, the name of a lake prepared by extending the aluminum salt prepared from FD&C Blue No. 1 upon the substratum would be FD&C Blue No. 1--Aluminum Lake.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To make lake-red.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lake",
        "https://en.wiktionary.org/wiki/lakes",
      ],
    },
  ],
  bound: [
    {
      word: "bound",
      phonetic: "/ˈbaʊnd/",
      phonetics: [
        {
          text: "/ˈbaʊnd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bound-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1239772",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To tie; to confine by any ligature.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cohere or stick together in a mass.",
              synonyms: [],
              antonyms: [],
              example: "Just to make the cheese more binding",
            },
            {
              definition:
                "To be restrained from motion, or from customary or natural action, as by friction.",
              synonyms: [],
              antonyms: [],
              example:
                "I wish I knew why the sewing machine binds up after I use it for a while.",
            },
            {
              definition: "To exert a binding or restraining influence.",
              synonyms: [],
              antonyms: [],
              example: "These are the ties that bind.",
            },
            {
              definition:
                "To tie or fasten tightly together, with a cord, band, ligature, chain, etc.",
              synonyms: ["fasten", "fetter", "make fast", "restrain", "tie"],
              antonyms: [],
              example: "to bind grain in bundles  to bind a prisoner",
            },
            {
              definition:
                "To confine, restrain, or hold by physical force or influence of any kind.",
              synonyms: [],
              antonyms: [],
              example: "Frost binds the earth.",
            },
            { definition: "To couple.", synonyms: [], antonyms: [] },
            {
              definition:
                "To oblige, restrain, or hold, by authority, law, duty, promise, vow, affection, or other social tie.",
              synonyms: ["obligate", "restrain", "restrict"],
              antonyms: [],
              example:
                "to bind the conscience  to bind by kindness  bound by affection  commerce binds nations to each other",
            },
            {
              definition:
                "To put (a person) under definite legal obligations, especially, under the obligation of a bond or covenant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To place under legal obligation to serve.",
              synonyms: ["indenture"],
              antonyms: [],
              example: "to bind an apprentice  bound out to service",
            },
            {
              definition:
                "To protect or strengthen by applying a band or binding, as the edge of a carpet or garment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make fast (a thing) about or upon something, as by tying; to encircle with something.",
              synonyms: [],
              antonyms: [],
              example:
                "to bind a belt about one  to bind a compress upon a wound",
            },
            {
              definition: "To cover, as with a bandage.",
              synonyms: ["bandage", "dress"],
              antonyms: [],
              example: "to bind up a wound",
            },
            {
              definition:
                "To prevent or restrain from customary or natural action, as by producing constipation.",
              synonyms: [],
              antonyms: [],
              example: "Certain drugs bind the bowels.",
            },
            {
              definition: "To put together in a cover, as of books.",
              synonyms: [],
              antonyms: [],
              example: "The three novels were bound together.",
            },
            {
              definition: "To make two or more elements stick together.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To associate an identifier with a value; to associate a variable name, method name, etc. with the content of a storage location.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To complain; to whine about something.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "bandage",
            "dress",
            "fasten",
            "fetter",
            "make fast",
            "restrain",
            "tie",
            "indenture",
            "obligate",
            "restrain",
            "restrict",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "(with infinitive) Obliged (to).",
              synonyms: [],
              antonyms: [],
              example: "You are not legally bound to reply.",
            },
            {
              definition:
                "(of a morpheme) That cannot stand alone as a free word.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of a variable) Constrained by a quantifier.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Constipated; costive.", synonyms: [], antonyms: [] },
            {
              definition:
                "Confined or restricted to a certain place; e.g. railbound.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Unable to move in certain conditions; e.g. snowbound.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["free"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bind",
        "https://en.wiktionary.org/wiki/bound",
      ],
    },
    {
      word: "bound",
      phonetic: "/ˈbaʊnd/",
      phonetics: [
        {
          text: "/ˈbaʊnd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bound-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1239772",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Ready, prepared.", synonyms: [], antonyms: [] },
            {
              definition:
                "Ready to start or go (to); moving in the direction (of).",
              synonyms: [],
              antonyms: [],
              example: "Is that message bound for me?",
            },
            {
              definition: "(with infinitive) Very likely (to), certain to",
              synonyms: [],
              antonyms: [],
              example: "They were bound to come into conflict eventually.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bound"],
    },
    {
      word: "bound",
      phonetic: "/ˈbaʊnd/",
      phonetics: [
        {
          text: "/ˈbaʊnd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bound-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1239772",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(often used in plural) A boundary, the border which one must cross in order to enter or leave a territory.",
              synonyms: [],
              antonyms: [],
              example:
                "I reached the northern bound of my property, took a deep breath and walked on.",
            },
            {
              definition:
                "A value which is known to be greater or smaller than a given set of values.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bound"],
    },
    {
      word: "bound",
      phonetic: "/ˈbaʊnd/",
      phonetics: [
        {
          text: "/ˈbaʊnd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bound-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1239772",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To surround a territory or other geographical entity.",
              synonyms: [],
              antonyms: [],
              example: "France, Portugal, Gibraltar and Andorra bound Spain.",
            },
            {
              definition: "To be the boundary of.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bound"],
    },
    {
      word: "bound",
      phonetic: "/ˈbaʊnd/",
      phonetics: [
        {
          text: "/ˈbaʊnd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bound-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1239772",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A sizeable jump, great leap.",
              synonyms: [],
              antonyms: [],
              example: "The deer crossed the stream in a single bound.",
            },
            {
              definition: "A spring from one foot to the other in dancing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A bounce; a rebound.",
              synonyms: [],
              antonyms: [],
              example: "the bound of a ball",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To leap, move by jumping.",
              synonyms: [],
              antonyms: [],
              example: "The rabbit bounded down the lane.",
            },
            {
              definition: "To cause to leap.",
              synonyms: [],
              antonyms: [],
              example: "to bound a horse",
            },
            {
              definition: "To rebound; to bounce.",
              synonyms: [],
              antonyms: [],
              example: "a rubber ball bounds on the floor",
            },
            {
              definition:
                "To cause to rebound; to throw so that it will rebound; to bounce.",
              synonyms: [],
              antonyms: [],
              example: "to bound a ball on the floor",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bound"],
    },
  ],
  depth: [
    {
      word: "depth",
      phonetic: "/dɛpθ/",
      phonetics: [
        {
          text: "/dɛpθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/depth-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762654",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The vertical distance below a surface; the degree to which something is deep",
              synonyms: ["deepness", "lowness"],
              antonyms: [],
              example:
                "Measure the depth of the water in this part of the bay.",
            },
            {
              definition:
                "The distance between the front and the back, as the depth of a drawer or closet",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The intensity, complexity, strength, seriousness or importance of an emotion, situation, etc.",
              synonyms: [],
              antonyms: [],
              example: "The depth of her misery was apparent to everyone.",
            },
            {
              definition: "Lowness",
              synonyms: [],
              antonyms: [],
              example: "the depth of a sound",
            },
            {
              definition: "(colors) the total palette of available colors",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The property of appearing three-dimensional",
              synonyms: [],
              antonyms: [],
              example: "The depth of field in this picture is amazing.",
            },
            {
              definition:
                "(usually in the plural) the deepest part (usually of a body of water)",
              synonyms: [],
              antonyms: [],
              example: "The burning ship finally sunk into the depths.",
            },
            {
              definition: "(usually in the plural) a very remote part.",
              synonyms: [],
              antonyms: [],
              example: "In the depths of the night,",
            },
            {
              definition: "The most severe part",
              synonyms: [],
              antonyms: [],
              example: "in the depth of the crisis",
            },
            {
              definition:
                "The number of simple elements which an abstract conception or notion includes; the comprehension or content",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A pair of toothed wheels which work together",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The perpendicular distance from the chord to the farthest point of an arched surface",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The lower of the two ranks of a value in an ordered set of values",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "abyss",
            "bathos",
            "bottom",
            "nadir",
            "deepness",
            "lowness",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/depth"],
    },
  ],
  candy: [
    {
      word: "candy",
      phonetic: "/ˈkændi/",
      phonetics: [
        {
          text: "/ˈkændi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/candy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1779954",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Edible, sweet-tasting confectionery containing sugar, or sometimes artificial sweeteners, and often flavored with fruit, chocolate, nuts, herbs and spices, or artificial flavors.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A piece of confectionery of this kind.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(slang, chiefly US) crack cocaine.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "confectionery",
            "lollies",
            "sugar candy",
            "sweet",
            "lolly",
            "sweet",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cook in, or coat with, sugar syrup.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To have sugar crystals form in or on.",
              synonyms: [],
              antonyms: [],
              example: "Fruits preserved in sugar candy after a time.",
            },
            {
              definition:
                "To be formed into candy; to solidify in a candylike form or mass.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/candy"],
    },
    {
      word: "candy",
      phonetic: "/ˈkændi/",
      phonetics: [
        {
          text: "/ˈkændi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/candy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1779954",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A unit of mass used in southern India, equal to twenty maunds, roughly equal to 500 pounds avoirdupois but varying locally.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["maunee"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/candy"],
    },
  ],
  event: [
    {
      word: "event",
      phonetic: "/əˈvɛnt/",
      phonetics: [
        {
          text: "/əˈvɛnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/event-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=857013",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An occurrence; something that happens.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A prearranged social activity (function, etc.)",
              synonyms: [],
              antonyms: [],
              example: "I went to an event in San Francisco last week.",
            },
            {
              definition:
                "One of several contests that combine to make up a competition.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An end result; an outcome (now chiefly in phrases).",
              synonyms: [],
              antonyms: [],
              example:
                "In the event, he turned out to have what I needed anyway.",
            },
            {
              definition:
                "A point in spacetime having three spatial coordinates and one temporal coordinate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A possible action that the user can perform that is monitored by an application or the operating system (event listener). When an event occurs an event handler is called which performs a specific task.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A set of some of the possible outcomes; a subset of the sample space.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An affair in hand; business; enterprise.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An episode of severe health conditions.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To occur, take place.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/event"],
    },
    {
      word: "event",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To be emitted or breathed out; to evaporate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To expose to the air, ventilate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/event"],
    },
  ],
  worse: [
    {
      word: "worse",
      phonetic: "/wɜːs/",
      phonetics: [
        { text: "/wɜːs/", audio: "" },
        {
          text: "/wɝs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/worse-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=471120",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Unfavorable; negative; not good.",
              synonyms: [],
              antonyms: [],
              example: "Abducting children is bad for mothers.",
            },
            {
              definition: "Not suitable or fitting.",
              synonyms: [],
              antonyms: [],
              example:
                "Do you think it is a bad idea to confront him directly?",
            },
            {
              definition: "Not appropriate, of manners etc.",
              synonyms: [],
              antonyms: [],
              example: "It is bad manners to talk with your mouth full.",
            },
            {
              definition: "Unhealthy; liable to cause health problems.",
              synonyms: [],
              antonyms: [],
              example:
                "Lard is bad for you. Smoking is bad for you, too. Grapes are bad for dogs but not for humans.",
            },
            {
              definition: "Sickly, unhealthy, unwell.",
              synonyms: [],
              antonyms: [],
              example:
                "I went to the hospital to see how my grandfather was doing. Unfortunately, he's in a bad state.",
            },
            {
              definition:
                "Not behaving; behaving badly; misbehaving; mischievous or disobedient.",
              synonyms: [],
              antonyms: [],
              example: "Stop being bad, or you will get a spanking!",
            },
            {
              definition: "Tricky; stressful; unpleasant.",
              synonyms: [],
              antonyms: [],
              example:
                "Divorce is usually a bad experience for everybody involved.",
            },
            {
              definition: "Evil; wicked.",
              synonyms: [],
              antonyms: [],
              example: "Be careful. There are bad people in the world.",
            },
            {
              definition: "Faulty; not functional.",
              synonyms: [],
              antonyms: [],
              example: "I had a bad headlight.",
            },
            {
              definition: "(of food) Spoiled, rotten, overripe.",
              synonyms: [],
              antonyms: [],
              example: "These apples have gone bad.",
            },
            {
              definition: "(of breath) Malodorous; foul.",
              synonyms: [],
              antonyms: [],
              example: "Bad breath is not pleasant for anyone.",
            },
            {
              definition: "False; counterfeit; illegitimate.",
              synonyms: [],
              antonyms: [],
              example: "They were caught trying to pass bad coinage.",
            },
            {
              definition: "Unskilled; of limited ability; not good.",
              synonyms: [],
              antonyms: [],
              example:
                "He's a bad gardener; everything he tries to grow ends up dying.",
            },
            {
              definition: "Of poor physical appearance.",
              synonyms: [],
              antonyms: [],
              example: "I don't look bad in this dress, do I?",
            },
            { definition: "Bold and daring.", synonyms: [], antonyms: [] },
            {
              definition: "Good, superlative, excellent, cool.",
              synonyms: [],
              antonyms: [],
              example: "Man, that new car you bought is bad!",
            },
            {
              definition: "(of a need, want, or pain) Severe, urgent.",
              synonyms: [],
              antonyms: [],
              example: "He is in bad need of a haircut.",
            },
            {
              definition: "Overly promiscuous, licentious.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of a draft/check) Not covered by funds on account.",
              synonyms: [],
              antonyms: [],
              example: "He gave me a bad check.",
            },
          ],
          synonyms: [
            "badass",
            "evil",
            "vicious",
            "vile",
            "wicked",
            "false",
            "spurious",
            "unhealthful",
            "unwholesome",
            "faulty",
            "inoperative",
            "negative",
            "unfavorable",
            "inappropriate",
            "unfit",
            "hot",
            "rubber",
            "dire",
            "severe",
            "urgent",
            "foul",
            "malodorous",
            "rotten",
            "bungling",
            "inept",
            "repulsive",
            "unsightly",
            "foul",
            "loathsome",
            "ill",
            "poorly",
            "sickly",
            "abandoned",
            "abominable",
            "base",
            "corrupt",
            "deficient",
            "detestable",
            "disgusting",
            "inferior",
            "lousy",
            "off",
            "poor",
            "punk",
            "substandard",
            "unacceptable",
            "ungodly",
            "unsatisfactory",
            "vicious",
            "wanting",
            "wretched",
            "wrong",
          ],
          antonyms: [
            "adequate",
            "advantageous",
            "beneficial",
            "benevolent",
            "choice",
            "competent",
            "excellent",
            "exceptional",
            "first-class",
            "first-rate",
            "good",
            "honest",
            "just",
            "premium",
            "prime",
            "profitable",
            "propitious",
            "reputable",
            "right",
            "sincere",
            "sufficient",
            "superior",
            "true",
            "upright",
            "virtuous",
            "worthy",
          ],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            { definition: "In a bad manner.", synonyms: [], antonyms: [] },
            {
              definition: "Very much; to a great degree.",
              synonyms: [],
              antonyms: [],
              example: "I want it so badly.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Evil; wicked (of people).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Morally reprehensible (of behaviour etc.); blameworthy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Indicative of unkind or malevolent intentions; harsh, cruel.",
              synonyms: [],
              antonyms: [],
              example: "He suffered from ill treatment.",
            },
            {
              definition:
                "Unpropitious, unkind, faulty, not up to reasonable standard.",
              synonyms: [],
              antonyms: [],
              example: "ill manners; ill will",
            },
            {
              definition:
                "Unwell in terms of health or physical condition; sick.",
              synonyms: [],
              antonyms: [],
              example: "I've been ill with the flu for the past few days.",
            },
            {
              definition: "Having an urge to vomit.",
              synonyms: [],
              antonyms: [],
              example: "Seeing those pictures made me ill.",
            },
            {
              definition:
                "(hip-hop slang) Sublime, with the connotation of being so in a singularly creative way.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Extremely bad (bad enough to make one ill). Generally used indirectly with to be.",
              synonyms: [],
              antonyms: [],
              example: "That band was ill.",
            },
            {
              definition: "Unwise; not a good idea.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "bad",
            "mal-",
            "disgusted",
            "nauseate",
            "nauseous",
            "sick",
            "sickened",
            "dope",
            "diseased",
            "poorly",
            "sick",
            "under the weather",
            "unwell",
          ],
          antonyms: ["good", "wack", "fine", "hale", "healthy", "well"],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Not well; imperfectly, badly; hardly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["illy"],
          antonyms: ["well"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Loss; disadvantage; defeat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "That which is worse; something less good.",
              synonyms: [],
              antonyms: [],
              example: "Do not think the worse of him for his enterprise.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make worse; to put at disadvantage; to discomfit.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bad",
        "https://en.wiktionary.org/wiki/badly",
        "https://en.wiktionary.org/wiki/ill",
        "https://en.wiktionary.org/wiki/worse",
      ],
    },
  ],
  aware: [
    {
      word: "aware",
      phonetic: "/əˈweːɹ/",
      phonetics: [
        { text: "/əˈweːɹ/", audio: "" },
        { text: "/əˈwɛə/", audio: "" },
        {
          text: "/əˈwɛɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/aware-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1648206",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Vigilant or on one's guard against danger or difficulty.",
              synonyms: [],
              antonyms: [],
              example: "Stay aware! Don't let your guard down.",
            },
            {
              definition: "Conscious or having knowledge of something.",
              synonyms: [],
              antonyms: [],
              example: "Are you aware of what is being said about you?",
            },
          ],
          synonyms: [
            "apprised",
            "cognizant",
            "conscious",
            "informed",
            "mindful",
            "alert",
            "attentive",
            "observant",
            "sensitive",
            "wary",
            "watchful",
          ],
          antonyms: ["unaware"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/aware"],
    },
  ],
  shell: [
    {
      word: "shell",
      phonetic: "/ʃɛl/",
      phonetics: [
        {
          text: "/ʃɛl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shell-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100850",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A hard external covering of an animal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The hard calcareous covering of a bird egg.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One of the outer layers of skin of an onion.",
              synonyms: [],
              antonyms: [],
              example: "The restaurant served caramelized onion shells.",
            },
            {
              definition:
                "The hard external covering of various plant seed forms.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The accreted mineral formed around a hollow geode.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The casing of a self-contained single-unit artillery projectile.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hollow, usually spherical or cylindrical projectile fired from a siege mortar or a smoothbore cannon. It contains an explosive substance designed to be ignited by a fuse or by percussion at the target site so that it will burst and scattered at high velocity its contents and fragments. Formerly called a bomb.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The cartridge of a breechloading firearm; a load; a bullet; a round.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any slight hollow structure; a framework, or exterior structure, regarded as not complete or filled in, as the shell of a house.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A garment, usually worn by women, such as a shirt, blouse, or top, with short sleeves or no sleeves, that often fastens in the rear.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A coarse or flimsy coffin; a thin interior coffin enclosed within a more substantial one.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A string instrument, as a lyre, whose acoustical chamber is formed like a shell.",
              synonyms: [],
              antonyms: [],
              example:
                "The first lyre may have been made by drawing strings over the underside of a tortoise shell.",
            },
            {
              definition:
                "The body of a drum; the often wooden, often cylindrical acoustic chamber, with or without rims added for tuning and for attaching the drum head.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An engraved copper roller used in print works.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The thin coating of copper on an electrotype.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The watertight outer covering of the hull of a vessel, often made with planking or metal plating.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(rigging) The outer frame or case of a block within which the sheaves revolve.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A light boat whose frame is covered with thin wood, impermeable fabric, or water-proofed paper; a racing shell or dragon boat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A set of atomic orbitals that have the same principal quantum number.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The outward form independent of what is inside.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The empty outward form of someone or something.",
              synonyms: [],
              antonyms: [],
              example:
                "The setback left him a mere shell; he was never the same again.",
            },
            {
              definition: "An emaciated person.",
              synonyms: [],
              antonyms: [],
              example:
                "He's lost so much weight from illness; he's a shell of his former self.",
            },
            {
              definition: "A psychological barrier to social interaction.",
              synonyms: [],
              antonyms: [],
              example: "Even after months of therapy he's still in his shell.",
            },
            {
              definition:
                "An operating system software user interface, whose primary purpose is to launch other programs and control their interactions; the user's command interpreter. Shell is a way to separate the internal complexity of the implementation of the command from the user. The internals can change while the user experience/interface remains the same.",
              synonyms: [],
              antonyms: [],
              example:
                'The name "Bash" is an acronym which stands for "Bourne-again shell", itself a pun on the name of the "Bourne shell", an earlier Unix shell designed by Stephen Bourne, and the Christian concept of being "born again".',
            },
            {
              definition: "A legal entity that has no operations.",
              synonyms: [],
              antonyms: [],
              example:
                "A shell corporation was formed to acquire the old factory.",
            },
            {
              definition:
                "A concave rough cast-iron tool in which a convex lens is ground to shape.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A gouge bit or shell bit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The onset and coda of a syllable.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person's ear.",
              synonyms: ["shell-like"],
              antonyms: [],
              example: "Can I have a quick word in your shell?",
            },
          ],
          synonyms: ["shell-like"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To remove the outer covering or shell of something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To bombard, to fire projectiles at, especially with artillery.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To disburse or give up money, to pay. (Often used with out).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fall off, as a shell, crust, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cast the shell, or exterior covering; to fall out of the pod or husk.",
              synonyms: [],
              antonyms: [],
              example: "Nuts shell in falling.",
            },
            {
              definition: "To switch to a shell or command line.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To form shallow, irregular cracks (in a coating).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To form a shelling.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shell"],
    },
  ],
  rooms: [
    {
      word: "rooms",
      phonetic: "/ɹuːmz/",
      phonetics: [
        { text: "/ɹuːmz/", audio: "" },
        { text: "/ɹuːmz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Opportunity or scope (to do something).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Space for something, or to carry out an activity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A particular portion of space.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Sufficient space for or to do something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A space between the timbers of a ship's frame.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Place; stead.", synonyms: [], antonyms: [] },
            {
              definition:
                "A separate part of a building, enclosed by walls, a floor and a ceiling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(with possessive pronoun) (One's) bedroom.",
              synonyms: [],
              antonyms: [],
              example: "Go to your room!",
            },
            {
              definition:
                "(in the plural) A set of rooms inhabited by someone; one's lodgings.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(always in the singular, metonymy) The people in a room.",
              synonyms: [],
              antonyms: [],
              example: "The room was on its feet.",
            },
            {
              definition: "An area for working in a coal mine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A portion of a cave that is wider than a passage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A IRC or chat room.",
              synonyms: [],
              antonyms: [],
              example: "Some users may not be able to access the AOL room.",
            },
            {
              definition:
                "Place or position in society; office; rank; post, sometimes when vacated by its former occupant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Furniture sufficient to furnish a room.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "chamber",
            "quarters",
            "chamber",
            "elbow room",
            "legroom",
            "space",
            "rm",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To reside, especially as a boarder or tenant.",
              synonyms: [],
              antonyms: [],
              example:
                "Doctor Watson roomed with Sherlock Holmes at Baker Street.",
            },
            {
              definition: "To assign to a room; to allocate a room to.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/room",
        "https://en.wiktionary.org/wiki/rooms",
      ],
    },
  ],
  ranch: [
    {
      word: "ranch",
      phonetic: "/ɹɑːntʃ/",
      phonetics: [
        { text: "/ɹɑːntʃ/", audio: "" },
        {
          text: "/ɹæntʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ranch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=5049189",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A large plot of land used for raising cattle, sheep or other livestock.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small farm that cultivates vegetables and/or livestock, especially one in the Southwestern United States.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A house or property on a plot of ranch land.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Ranch dressing.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To operate a ranch; engage in ranching.",
              synonyms: [],
              antonyms: [],
              example:
                "Formally the widow still ranches, but in fact she leaves all ranching to the foreman.",
            },
            { definition: "To work on a ranch", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ranch"],
    },
  ],
  image: [
    {
      word: "image",
      phonetic: "/ˈɪmɪd͡ʒ/",
      phonetics: [
        {
          text: "/ˈɪmɪd͡ʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/image-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1684648",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An optical or other representation of a real object; a graphic; a picture.",
              synonyms: [],
              antonyms: [],
              example: "The Bible forbids the worship of graven images.",
            },
            {
              definition:
                "A mental picture of something not real or not present.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A statue or idol.", synonyms: [], antonyms: [] },
            {
              definition:
                "A file that contains all information needed to produce a live working copy. (See disk image and image copy.)",
              synonyms: [],
              antonyms: [],
              example:
                "Most game console emulators do not come with any ROM images for copyright reasons.",
            },
            {
              definition:
                "A characteristic of a person, group or company etc., style, manner of dress, how one is, or wishes to be, perceived by others.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Something mapped to by a function.",
              synonyms: [],
              antonyms: [],
              example:
                "The number 6 is the image of 3 under f that is defined as f(x) = 2x.",
            },
            {
              definition:
                "The subset of a codomain comprising those elements that are images of something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'A form of interference: a weaker "copy" of a strong signal that occurs at a different frequency.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Show; appearance; cast.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["idea", "picture", "value", "range"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To represent by an image or symbol; to portray.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To reflect, mirror.", synonyms: [], antonyms: [] },
            {
              definition: "To create an image of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To create a complete backup copy of a file system or other entity.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/image"],
    },
  ],
  snake: [
    {
      word: "snake",
      phonetic: "/ˈsneɪk/",
      phonetics: [
        {
          text: "/ˈsneɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/snake-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75701410",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈsneɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/snake-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1755222",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A legless reptile of the sub-order Serpentes with a long, thin body and a fork-shaped tongue.",
              synonyms: ["joe blake", "serpent"],
              antonyms: [],
            },
            { definition: "A treacherous person.", synonyms: [], antonyms: [] },
            {
              definition: "Somebody who acts deceitfully for social gain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A tool for unclogging plumbing.",
              synonyms: ["auger", "plumber's snake"],
              antonyms: [],
            },
            {
              definition: "A tool to aid cable pulling.",
              synonyms: ["wirepuller"],
              antonyms: [],
            },
            {
              definition:
                "A flavoured jube (confectionary) in the shape of a snake.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Trouser snake; the penis.",
              synonyms: ["trouser snake"],
              antonyms: [],
            },
            {
              definition: "A series of Bézier curves.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The seventh Lenormand card.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "auger",
            "plumber's snake",
            "joe blake",
            "serpent",
            "trouser snake",
            "wirepuller",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To follow or move in a winding route.",
              synonyms: ["slither", "wind"],
              antonyms: [],
              example: "The path snaked through the forest.",
            },
            {
              definition: "To steal slyly.",
              synonyms: [],
              antonyms: [],
              example: "He snaked my DVD!",
            },
            {
              definition: "To clean using a plumbing snake.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To drag or draw, as a snake from a hole; often with out.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To wind round spirally, as a large rope with a smaller, or with cord, the small rope lying in the spaces between the strands of the large one; to worm.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["slither", "wind"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/snake"],
    },
  ],
  aloud: [
    {
      word: "aloud",
      phonetic: "/əˈlaʊd/",
      phonetics: [
        {
          text: "/əˈlaʊd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/aloud-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749701",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Spoken out loud.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "With a loud voice, or great noise; loudly; audibly.",
              synonyms: [],
              antonyms: [],
              example: "Try speaking aloud rather than whispering.",
            },
            {
              definition: "Audibly, as opposed to silently/quietly.",
              synonyms: [],
              antonyms: [],
              example: "speaking aloud rather than thinking thoughts privately",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/aloud"],
    },
  ],
  dried: [
    {
      word: "dried",
      phonetic: "/dɹaɪd/",
      phonetics: [
        {
          text: "/dɹaɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dried-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100956",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Without water or moisture, said of something that has previously been wet or moist; resulting from the process of drying.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Usually of foods: cured, preserved by drying.",
              synonyms: [],
              antonyms: [],
              example: "dried fish; dried fruit",
            },
            {
              definition: "Sold raw and unprocessed.",
              synonyms: [],
              antonyms: [],
              example: "dried lentils",
            },
          ],
          synonyms: ["anhydrous", "desiccated", "dehydrated"],
          antonyms: ["canned", "tinned"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To lose moisture.",
              synonyms: [],
              antonyms: [],
              example: "The clothes dried on the line.",
            },
            {
              definition: "To remove moisture from.",
              synonyms: [],
              antonyms: [],
              example: "Devin dried her eyes with a handkerchief.",
            },
            { definition: "To be thirsty.", synonyms: [], antonyms: [] },
            {
              definition: "To exhaust; to cause to run dry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "For an actor to forget his or her lines while performing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dried",
        "https://en.wiktionary.org/wiki/dry",
      ],
    },
  ],
  likes: [
    {
      word: "likes",
      phonetic: "/laɪks/",
      phonetics: [{ text: "/laɪks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(usually in the plural) Something that a person likes (prefers).",
              synonyms: ["favorite", "preference"],
              antonyms: ["dislike", "pet hate", "pet peeve"],
              example: "Tell me your likes and dislikes.",
            },
            {
              definition:
                "An individual vote showing support for, or approval of, something posted on the Internet.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["favorite", "preference"],
          antonyms: ["dislike", "pet hate", "pet peeve"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To enjoy, be pleased by; favor; be in favor of.",
              synonyms: [],
              antonyms: ["dislike", "hate", "mislike"],
              example: "I like hamburgers.",
            },
            { definition: "To please.", synonyms: [], antonyms: [] },
            {
              definition:
                "To derive pleasure of, by or with someone or something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To prefer and maintain (an action) as a regular habit or activity.",
              synonyms: [],
              antonyms: [],
              example: "I like to go to the dentist every six months.",
            },
            {
              definition:
                "To have an appearance or expression; to look; to seem to be (in a specified condition).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To come near; to avoid with difficulty; to escape narrowly.",
              synonyms: [],
              antonyms: [],
              example: "He liked to have been too late.",
            },
            {
              definition:
                "To find attractive; to prefer the company of; to have mild romantic feelings for.",
              synonyms: ["enjoy", "fancy", "love"],
              antonyms: ["dislike", "hate", "mislike"],
              example: "I really like Sandra but don't know how to tell her.",
            },
            { definition: "To liken; to compare.", synonyms: [], antonyms: [] },
            {
              definition:
                "To show support for, or approval of, something posted on the Internet by marking it with a vote.",
              synonyms: [],
              antonyms: ["unlike"],
              example:
                "I can't stand Bloggs' tomato ketchup, but I liked it on Facebook so I could enter a competition.",
            },
            {
              definition:
                "(with 'would' and in certain other phrases) To want, desire. See also would like.",
              synonyms: [],
              antonyms: [],
              example: "We could go to the museum if you like.",
            },
          ],
          synonyms: ["enjoy", "fancy", "love"],
          antonyms: [
            "dislike",
            "hate",
            "mislike",
            "dislike",
            "hate",
            "mislike",
            "unlike",
          ],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(sometimes as the likes of') Someone similar to a given person, or something similar to a given object; a comparative; a type; a sort.",
              synonyms: [],
              antonyms: [],
              example:
                "It was something the likes of which I had never seen before.",
            },
            {
              definition:
                "The stroke that equalizes the number of strokes played by the opposing player or side.",
              synonyms: [],
              antonyms: [],
              example: "to play the like",
            },
          ],
          synonyms: ["ilk"],
          antonyms: ["antithesis", "opposite"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To be likely.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/like",
        "https://en.wiktionary.org/wiki/likes",
      ],
    },
  ],
  motor: [
    {
      word: "motor",
      phonetic: "/ˈməʊtə/",
      phonetics: [
        { text: "/ˈməʊtə/", audio: "" },
        {
          text: "/ˈmoʊtɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/motor-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780228",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A machine or device that converts other energy forms into mechanical energy, or imparts motion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A motor car, or automobile.",
              synonyms: [],
              antonyms: [],
              example: "Nice motor!",
            },
            {
              definition:
                "A source of power for something; an inspiration; a driving force.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any protein capable of converting chemical energy into mechanical work.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The controller or prime mover of the universe; God.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'The fermenting mass of fruit that is the basis of pruno, or "prison wine".',
              synonyms: ["kicker"],
              antonyms: [],
            },
          ],
          synonyms: ["engine", "kicker"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make a journey by motor vehicle; to drive.",
              synonyms: [],
              antonyms: [],
              example: "On Saturday we motored down to Plymouth.",
            },
            {
              definition: "To move at a brisk pace.",
              synonyms: [],
              antonyms: [],
              example:
                "Sales were slow at first, but now things are really motoring.",
            },
            {
              definition: "To leave.",
              synonyms: [],
              antonyms: [],
              example: "I gotta motor.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Relating to the ability to move",
              synonyms: [],
              antonyms: [],
              example: "She has excellent motor skills.",
            },
            {
              definition: "Relating to motor cars",
              synonyms: [],
              antonyms: [],
              example: "Motor insurance is expensive for youngsters.",
            },
            {
              definition:
                "Propelled by an internal combustion engine (as opposed to a steam engine or turbine).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/motor"],
    },
  ],
  pound: [
    {
      word: "pound",
      phonetic: "/paʊnd/",
      phonetics: [
        {
          text: "/paʊnd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pound-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1755195",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                'A unit of mass equal to 16 avoirdupois ounces (= 453.592 37 g). Today this value is the most common meaning of "pound" as a unit of weight.',
              synonyms: ["lb"],
              antonyms: [],
            },
            {
              definition:
                "A unit of mass equal to 12 troy ounces (≈ 373.242 g). Today, this is a common unit of weight when measuring precious metals, and is little used elsewhere.",
              synonyms: ["lb t"],
              antonyms: [],
            },
            {
              definition: "The symbol # (octothorpe, hash)",
              synonyms: ["hash", "sharp"],
              antonyms: [],
            },
            {
              definition:
                "The unit of currency used in the United Kingdom and its dependencies. It is divided into 100 pence.",
              synonyms: ["GBP", "nicker", "pound sterling", "quid", "£"],
              antonyms: [],
            },
            {
              definition:
                "Any of various units of currency used in Egypt and Lebanon, and formerly in the Republic of Ireland, Cyprus and Israel.",
              synonyms: ["punt"],
              antonyms: [],
            },
            {
              definition:
                "Any of various units of currency formerly used in the United States.",
              synonyms: [],
              antonyms: [],
              example: "the Rhode Island pound; the New Hampshire pound",
            },
            {
              definition:
                "Abbreviation for pound-force, a unit of force/weight. Using this abbreviation to describe pound-force is inaccurate and unscientific.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "GBP",
            "nicker",
            "pound sterling",
            "quid",
            "£",
            "hash",
            "sharp",
            "lb",
            "lb t",
            "punt",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pound"],
    },
    {
      word: "pound",
      phonetic: "/paʊnd/",
      phonetics: [
        {
          text: "/paʊnd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pound-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1755195",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A place for the detention of stray or wandering animals. An animal shelter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A place for the detention of automobiles that have been illegally parked, abandoned, etc. Short form of impound.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A section of a canal between two adjacent locks.",
              synonyms: ["reach"],
              antonyms: [],
            },
            {
              definition:
                "A kind of fishing net, having a large enclosure with a narrow entrance into which fish are directed by wings spreading outward.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["reach"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To confine in, or as in, a pound; to impound.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pound"],
    },
    {
      word: "pound",
      phonetic: "/paʊnd/",
      phonetics: [
        {
          text: "/paʊnd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pound-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1755195",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A hard blow.",
              synonyms: ["pounding"],
              antonyms: [],
            },
          ],
          synonyms: ["pounding"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To strike hard, usually repeatedly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To crush to pieces; to pulverize.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To eat or drink very quickly.",
              synonyms: [],
              antonyms: [],
              example: "You really pounded that beer!",
            },
            {
              definition: "To pitch consistently to a certain location.",
              synonyms: [],
              antonyms: [],
              example:
                "The pitcher has been pounding the outside corner all night.",
            },
            {
              definition:
                "(of a body part, generally heart, blood, or head) To beat strongly or throb.",
              synonyms: [],
              antonyms: [],
              example:
                "As I tiptoed past the sleeping dog, my heart was pounding but I remained silent.",
            },
            {
              definition: "To penetrate sexually, with vigour.",
              synonyms: [],
              antonyms: [],
              example: "I was pounding her all night!",
            },
            {
              definition: "To advance heavily with measured steps.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make a jarring noise, as when running.",
              synonyms: [],
              antonyms: [],
              example: "The engine pounds.",
            },
            { definition: "To wager a pound on.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "pulverate",
            "triturate",
            "bolt",
            "chug",
            "down",
            "drill",
            "get up in",
            "nail",
            "poke",
            "hammer",
            "pelt",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pound"],
    },
  ],
  knees: [
    {
      word: "knees",
      phonetic: "/niːz/",
      phonetics: [
        {
          text: "/niːz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/knees-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1669323",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "In humans, the joint or the region of the joint in the middle part of the leg between the thigh and the shank.",
              synonyms: [],
              antonyms: [],
              example:
                "Penny was wearing a miniskirt, so she skinned her exposed knees when she fell.",
            },
            {
              definition:
                "In the horse and allied animals, the carpal joint, corresponding to the wrist in humans.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The part of a garment that covers the knee.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of timber or metal formed with an angle somewhat in the shape of the human knee when bent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An act of kneeling, especially to show respect or courtesy.",
              synonyms: [],
              antonyms: [],
              example: "To make a knee.",
            },
            {
              definition:
                "Any knee-shaped item or sharp angle in a line; an inflection point.",
              synonyms: [],
              antonyms: [],
              example: "the knee of a graph",
            },
            {
              definition: "A blow made with the knee; a kneeing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To kneel to.",
              synonyms: [],
              antonyms: [],
              example:
                "1605: I could as well be brought / To knee his throne and, squire-like, pension beg / To keep base life afoot. — William Shakespeare, King Lear II.ii",
            },
            {
              definition: "To poke or strike with the knee.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To move on the knees; to use the knees to move.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/knee",
        "https://en.wiktionary.org/wiki/knees",
      ],
    },
  ],
  refer: [
    {
      word: "refer",
      phonetic: "/ɹɪˈfɜː/",
      phonetics: [
        { text: "/ɹɪˈfɜː/", audio: "" },
        {
          text: "/ɹɪˈfɝ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/refer-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89915113",
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To direct the attention of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To submit to (another person or group) for consideration; to send or direct elsewhere.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To place in or under by a mental or rational process; to assign to, as a class, a cause, source, a motive, reason, or ground of explanation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(construed with to) To allude to, make a reference or allusion to.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(grammar) To be referential to another element in a sentence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To address a specific location in computer memory.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Required to resit an examination.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["delegate", "direct"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/refer"],
    },
  ],
  fully: [
    {
      word: "fully",
      phonetic: "/ˈfʊli/",
      phonetics: [
        {
          text: "/ˈfʊli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fully-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1250148",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "In a full manner; without lack or defect.",
              synonyms: [],
              antonyms: [],
              example: "He fully met his responsibilities.",
            },
            {
              definition: "In a full degree; to a full extent.",
              synonyms: [],
              antonyms: [],
              example: "He is fully capable of meeting his responsibilities.",
            },
            {
              definition: "As a minimum; at least.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "abundantly",
            "amply",
            "completely",
            "copiously",
            "entirely",
            "largely",
            "maturely",
            "perfectly",
            "plenteously",
            "plentifully",
            "sufficiently",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fully"],
    },
  ],
  chain: [
    {
      word: "chain",
      phonetic: "/ˈt͡ʃeɪn/",
      phonetics: [
        {
          text: "/ˈt͡ʃeɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chain-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=840602",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A series of interconnected rings or links usually made of metal.",
              synonyms: [],
              antonyms: [],
              example: "He wore a gold chain around the neck.",
            },
            {
              definition: "A series of interconnected things.",
              synonyms: [],
              antonyms: [],
              example: "This led to an unfortunate chain of events.",
            },
            {
              definition:
                "A series of stores or businesses with the same brand name.",
              synonyms: [],
              antonyms: [],
              example: "That chain of restaurants is expanding into our town.",
            },
            {
              definition:
                "A number of atoms in a series, which combine to form a molecule.",
              synonyms: [],
              antonyms: [],
              example:
                "When examined, the molecular chain included oxygen and hydrogen.",
            },
            {
              definition:
                "A series of interconnected links of known length, used as a measuring device.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A long measuring tape.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A unit of length equal to 22 yards. The length of a Gunter's surveying chain. The length of a cricket pitch. Equal to 20.12 metres, 4 rods, or 100 links.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A totally ordered set, especially a totally ordered subset of a poset.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'A sequence of linked house purchases, each of which is dependent on the preceding and succeeding purchase (said to be "broken" if a buyer or seller pulls out).',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "That which confines, fetters, or secures; a bond.",
              synonyms: [],
              antonyms: [],
              example: "the chains of habit",
            },
            {
              definition:
                "(in the plural) Iron links bolted to the side of a vessel to bold the dead-eyes connected with the shrouds; also, the channels.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The warp threads of a web.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["rackle"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To fasten something with a chain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To link multiple items together.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To secure someone with fetters.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To obstruct the mouth of a river etc with a chain.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To obligate.", synonyms: [], antonyms: [] },
            {
              definition: "To relate data items with a chain of pointers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be chained to another data item.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To measure a distance using a 66-foot long chain, as in land surveying.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(associated with Acorn Computers) To load and automatically run (a program).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chain"],
    },
  ],
  shirt: [
    {
      word: "shirt",
      phonetic: "/ʃɜːt/",
      phonetics: [
        {
          text: "/ʃɜːt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shirt-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9027402",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ʃɝt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shirt-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=836407",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An article of clothing that is worn on the upper part of the body, and often has sleeves, either long or short, that cover the arms.",
              synonyms: [],
              antonyms: [],
              example:
                "It can take a while to learn how to iron a shirt properly.",
            },
            {
              definition: "An interior lining in a blast furnace.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A member of the shirt-wearing team in a shirts and skins game.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shirt"],
    },
    {
      word: "shirt",
      phonetic: "/ʃɜːt/",
      phonetics: [
        {
          text: "/ʃɜːt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shirt-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9027402",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ʃɝt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shirt-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=836407",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cover or clothe with a shirt, or as if with a shirt.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shirt"],
    },
  ],
  flour: [
    {
      word: "flour",
      phonetic: "/ˈflaʊə/",
      phonetics: [
        { text: "/ˈflaʊə/", audio: "" },
        {
          text: "/ˈflaʊɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/flour-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769945",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Powder obtained by grinding or milling cereal grains, especially wheat, or other foodstuffs such as soybeans and potatoes, and used to bake bread, cakes, and pastry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The food made by grinding and bolting cleaned wheat (not durum or red durum) until it meets specified levels of fineness, dryness and freedom from bran and germ, also containing any of certain enzymes, ascorbic acid and certain bleaching agents.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Powder of other material.",
              synonyms: [],
              antonyms: [],
              example: "mustard flour",
            },
          ],
          synonyms: ["plain flour", "smeddum", "wheat flour", "white flour"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To apply flour to something; to cover with flour.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To reduce to flour.", synonyms: [], antonyms: [] },
            {
              definition:
                "To break up into fine globules of mercury in the amalgamation process.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A colorful, conspicuous structure associated with angiosperms, frequently scented and attracting various insects, and which may or may not be used for sexual reproduction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A reproductive structure in angiosperms (flowering plants), often conspicuously colourful and typically including sepals, petals, and either or both stamens and/or a pistil.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A plant that bears flowers, especially a plant that is small and lacks wood.",
              synonyms: [],
              antonyms: [],
              example: "We transplanted the flowers to a larger pot.",
            },
            {
              definition:
                "(usually with in) Of plants, a state of bearing blooms.",
              synonyms: [],
              antonyms: [],
              example: "The dogwoods are in flower this week.",
            },
            {
              definition:
                "(hypocoristic) The vulva, especially the labia majora.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The best examples or representatives of a group.",
              synonyms: [],
              antonyms: [],
              example: "We selected the flower of the applicants.",
            },
            {
              definition: "The best state of things; the prime.",
              synonyms: [],
              antonyms: [],
              example: "She was in the flower of her life.",
            },
            { definition: "Flour.", synonyms: [], antonyms: [] },
            {
              definition:
                "(in the plural) A substance in the form of a powder, especially when condensed from sublimation.",
              synonyms: [],
              antonyms: [],
              example: "the flowers of sulphur",
            },
            {
              definition: "A figure of speech; an ornament of style.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Ornamental type used chiefly for borders around pages, cards, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(in the plural) Menstrual discharges.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["cream", "prime", "head", "pseudanthium"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Something that flows, such as a river.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/flour",
        "https://en.wiktionary.org/wiki/flower",
      ],
    },
  ],
  drops: [
    {
      word: "drops",
      phonetic: "/dɹɒps/",
      phonetics: [
        { text: "/dɹɒps/", audio: "" },
        {
          text: "/dɹɑps/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/drops-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100957",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small quantity of liquid, just large enough to hold its own round shape via surface tension, especially one that falls from a source of liquid.",
              synonyms: [],
              antonyms: [],
              example: "Put three drops of oil into the mixture.",
            },
            {
              definition:
                "A very small quantity of liquid, or (by extension) of anything.",
              synonyms: [],
              antonyms: [],
              example:
                "asked for just a drop more tea; she was thirsty and there wasn't a drop of water to be found",
            },
            {
              definition:
                '(now especially with "the") Alcoholic spirits in general.',
              synonyms: [],
              antonyms: [],
              example:
                "It doesn't matter where you're from; anyone who enjoys the drop is a friend of mine.",
            },
            {
              definition:
                "That which resembles or hangs like a liquid globule: a hanging diamond ornament or earring, a glass pendant on a chandelier, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A thing which drops or hangs down:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A fall, descent; an act or instance of dropping.",
              synonyms: [],
              antonyms: [],
              example:
                "That was a long drop, but fortunately I didn't break any bones.",
            },
            {
              definition: "A release (of music, a vdeo game, etc).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A decline in quantity, quality, rate, or degree; a decline (when going from one value to another).",
              synonyms: [],
              antonyms: [],
              example: "a drop in demand for oil resulted in a drop in prices",
            },
            {
              definition: "A thing, person, etc which is dropped:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An unsolicited credit card issue.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The amount of money that a gambler exchanges for chips in a casino.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The distance below a cliff or other high position into which someone or something could fall; the distance to which someone or something drops; a steep slope.",
              synonyms: [],
              antonyms: [],
              example: "On one side of the road was a 50-foot drop.",
            },
            {
              definition: "The vertical length of a hanging curtain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The depth of a (square) sail (generally applied to the courses only); the vertical dimension of a sail.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The distance of the axis of a shaft below the base of a hanger.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A place where items or supplies may be left for others to collect, whether openly (as with a mail drop) or secretly or illegaly (as in espionage or crime); a drop-off point.",
              synonyms: [],
              antonyms: [],
              example: "I left the plans at the drop, like you asked.",
            },
            {
              definition: "A drop-back.",
              synonyms: [],
              antonyms: [],
              example:
                "The Tiger quarterback took a one-step drop, expecting his tight end to be open.",
            },
            {
              definition: "(Rugby football) A drop-kick.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A drop target.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To fall in droplets (of a liquid).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To drip (a liquid).",
              synonyms: [],
              antonyms: [],
              example:
                "The equipment shows how much the glacier has moved and the amount it dropped in height over the summer.",
            },
            {
              definition: "Generally, to fall (straight down).",
              synonyms: [],
              antonyms: [],
              example:
                "A single shot was fired and the bird dropped from the sky.",
            },
            {
              definition:
                "To let fall; to allow to fall (either by releasing hold of, or losing one's grip on).",
              synonyms: [],
              antonyms: [],
              example:
                "Don't drop that plate!   The police ordered the men to drop their weapons.",
            },
            {
              definition: "To let drops fall; to discharge itself in drops.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To lower; to move to a lower position.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To set down from a vehicle; to deliver or deposit by stopping.",
              synonyms: ["drop off"],
              antonyms: [],
              example:
                "Could you drop me at the airport on your way to work tomorrow?",
            },
            {
              definition: "To sink quickly to the ground.",
              synonyms: [],
              antonyms: [],
              example: "Drop and give me thirty push-ups, private!",
            },
            {
              definition: "To fall dead, or to fall in death.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To come to an end (by not being kept up); to stop.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To mention casually or incidentally, usually in conversation.",
              synonyms: [],
              antonyms: [],
              example:
                "The moderator would drop hints whenever the students struggled.",
            },
            {
              definition: "To part with or spend (money).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cease concerning oneself over; to have nothing more to do with (a subject, discussion etc.).",
              synonyms: [],
              antonyms: [],
              example: "I'm tired of this subject. Will you just drop it?",
            },
            {
              definition:
                "To lessen, decrease, or diminish in value, condition, degree, etc.",
              synonyms: [],
              antonyms: [],
              example:
                "The stock dropped 1.5% yesterday.   We can take our vacation when the price of fuel drops.   Watch for the temperature to drop sharply, then you'll know the reaction is complete.",
            },
            {
              definition:
                "To let (a letter etc.) fall into a postbox; to send (a letter or message) in an offhand manner.",
              synonyms: [],
              antonyms: [],
              example: "Drop me a note when you get to the city.",
            },
            {
              definition:
                "To make (someone or something) fall to the ground from a blow, gunshot etc.; to bring down, to shoot down.",
              synonyms: [],
              antonyms: [],
              example: "Make any sudden movements and I will drop you!",
            },
            {
              definition:
                "To fail to write, or (especially) to pronounce (a syllable, letter etc.).",
              synonyms: [],
              antonyms: [],
              example: "Cockneys drop their aitches.",
            },
            {
              definition:
                "(of a fielder) To fail to make a catch from a batted ball that would have led to the batsman being out.",
              synonyms: [],
              antonyms: [],
              example:
                "Warne dropped Tendulkar on 99. Tendulkar went on to get a century next ball",
            },
            {
              definition: "To swallow (a drug), particularly LSD.",
              synonyms: [],
              antonyms: [],
              example: "They had never dropped acid.",
            },
            {
              definition: "To dispose (of); get rid of; to remove; to lose.",
              synonyms: [],
              antonyms: [],
              example: "I dropped ten pounds and an obnoxious fiancée.",
            },
            {
              definition:
                "To eject; to dismiss; to cease to include, as if on a list.",
              synonyms: [],
              antonyms: [],
              example: "I've been dropped from the football team.",
            },
            {
              definition: "To drop out of the betting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(rugby football) To score (a goal) by means of a drop kick.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To impart.",
              synonyms: [],
              antonyms: [],
              example: "I drop knowledge wherever I go.",
            },
            {
              definition: "To release to the public.",
              synonyms: [],
              antonyms: [],
              example:
                "That hacker has been threatening to drop my docs [i.e. publish my personal information].",
            },
            {
              definition:
                "To play a portion of music in the manner of a disc jockey.",
              synonyms: [],
              antonyms: [],
              example: "I love it when he drops his funky beats.",
            },
            {
              definition: "To enter public distribution.",
              synonyms: [],
              antonyms: [],
              example: '"Hip-Hop Xmas" dropped in time for the holidays.',
            },
            {
              definition: "To tune (a guitar string, etc.) to a lower note.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cancel or end a scheduled event, project or course.",
              synonyms: [],
              antonyms: [],
              example:
                "I had to drop calculus because it was taking up too much of my time and I couldn't go anymore.",
            },
            {
              definition:
                "(fast food) To cook, especially by deep-frying or grilling.",
              synonyms: [],
              antonyms: [],
              example: "Drop a basket of fries.",
            },
            {
              definition:
                "(of a voice) To lower in timbre, often relating to puberty.",
              synonyms: [],
              antonyms: [],
              example: "Billy's voice dropped suddenly when he turned 12.",
            },
            {
              definition:
                "(of a sound or song) To lower in pitch, tempo, key, or other quality.",
              synonyms: [],
              antonyms: [],
              example:
                "My synthesizer makes the notes sound funny when they drop below C2.",
            },
            {
              definition:
                "(of people) To visit informally; used with in or by.",
              synonyms: [],
              antonyms: [],
              example: "Do drop by soon and I'll lend you that book.",
            },
            {
              definition: "To give birth to.",
              synonyms: [],
              antonyms: [],
              example: "to drop a lamb",
            },
            {
              definition: "To cover with drops; to variegate; to bedrop.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of the testicles) To hang lower and begin producing sperm due to puberty.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["drop off"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/drop",
        "https://en.wiktionary.org/wiki/drops",
      ],
    },
  ],
  spite: [
    {
      word: "spite",
      phonetic: "/spaɪt/",
      phonetics: [
        {
          text: "/spaɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spite-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1244047",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Ill will or hatred toward another, accompanied with the desire to irritate, annoy, or thwart; a want to disturb or put out another; mild malice",
              synonyms: ["grudge", "rancor"],
              antonyms: [],
              example:
                "He was so filled with spite for his ex-wife, he could not hold down a job.",
            },
            {
              definition: "Vexation; chagrin; mortification.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["grudge", "rancor"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To treat maliciously; to try to injure or thwart.",
              synonyms: [],
              antonyms: [],
              example: "She soon married again, to spite her ex-husband.",
            },
            {
              definition: "To be angry at; to hate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fill with spite; to offend; to vex.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spite"],
    },
    {
      word: "spite",
      phonetic: "/spaɪt/",
      phonetics: [
        {
          text: "/spaɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spite-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1244047",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "preposition",
          definitions: [
            {
              definition: "Notwithstanding; despite.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spite"],
    },
  ],
  orbit: [
    {
      word: "orbit",
      phonetic: "/ˈɔː(ɹ)bɪt/",
      phonetics: [
        {
          text: "/ˈɔː(ɹ)bɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/orbit-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1763009",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A circular or elliptical path of one object around another object, particularly in astronomy and space travel.",
              synonyms: [],
              antonyms: [],
              example:
                "The Moon's orbit around the Earth takes nearly one month to complete.",
            },
            {
              definition: "A sphere of influence; an area of control.",
              synonyms: [],
              antonyms: [],
              example:
                "In the post WWII era, several eastern European countries came into the orbit of the Soviet Union.",
            },
            {
              definition:
                "The course of one's usual progression, or the extent of one's typical range.",
              synonyms: [],
              antonyms: [],
              example:
                "The convenience store was a heavily travelled point in her daily orbit, as she purchased both cigarettes and lottery tickets there.",
            },
            {
              definition:
                "The bony cavity containing the eyeball; the eye socket.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A mathematical function that describes the wave-like behavior of an electron in an atom; area of the highest probability of electron´s occurrence around the atom's nucleus.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A collection of points related by the evolution function of a dynamical system.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The subset of elements of a set X to which a given element can be moved by members of a specified group of transformations that act on X.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A state of increased excitement, activity, or anger.",
              synonyms: [],
              antonyms: [],
              example:
                "Dad went into orbit when I told him that I'd crashed the car.",
            },
            {
              definition:
                "A path for the ball on the outer edge of the playfield, usually connected so that the ball entering in one end will come out of the other.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To circle or revolve around another object.",
              synonyms: [],
              antonyms: [],
              example: "The Earth orbits the Sun.",
            },
            {
              definition: "To move around the general vicinity of something.",
              synonyms: [],
              antonyms: [],
              example:
                "The harried mother had a cloud of children orbiting her, begging for sweets.",
            },
            {
              definition: "To place an object into an orbit around a planet.",
              synonyms: [],
              antonyms: [],
              example: "A rocket was used to orbit the satellite.",
            },
          ],
          synonyms: ["circumambulate", "tag along", "launch"],
          antonyms: ["deorbit"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/orbit"],
    },
  ],
  banks: [
    {
      word: "banks",
      phonetic: "/bæŋks/",
      phonetics: [
        {
          text: "/bæŋks/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/banks-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1684560",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An institution where one can place and borrow money and take care of financial affairs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A branch office of such an institution.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An underwriter or controller of a card game; also banque.",
              synonyms: ["banker"],
              antonyms: [],
            },
            {
              definition:
                "A fund from deposits or contributions, to be used in transacting business; a joint stock or capital.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The sum of money etc. which the dealer or banker has as a fund from which to draw stakes and pay losses.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Money; profit", synonyms: [], antonyms: [] },
            {
              definition:
                "In certain games, such as dominos, a fund of pieces from which the players are allowed to draw.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A safe and guaranteed place of storage for and retrieval of important items or goods.",
              synonyms: [],
              antonyms: [],
              example: "blood bank; sperm bank; data bank",
            },
            {
              definition: "A device used to store coins or currency.",
              synonyms: [],
              antonyms: [],
              example:
                "If you want to buy a bicycle, you need to put the money in your piggy bank.",
            },
          ],
          synonyms: ["banker"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To deal with a bank or financial institution, or for an institution to provide financial services to a client.",
              synonyms: [],
              antonyms: [],
              example: "He banked with Barclays.",
            },
            {
              definition: "To put into a bank.",
              synonyms: [],
              antonyms: [],
              example: "I'm going to bank the money.",
            },
            {
              definition: "To conceal in the rectum for use in prison.",
              synonyms: [],
              antonyms: [],
              example: "Johnny banked some coke for me.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An edge of river, lake, or other watercourse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An elevation, or rising ground, under the sea; a shallow area of shifting sand, gravel, mud, and so forth (for example, a sandbank or mudbank).",
              synonyms: [],
              antonyms: [],
              example: "the banks of Newfoundland",
            },
            {
              definition: "A slope of earth, sand, etc.; an embankment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The incline of an aircraft, especially during a turn.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An incline, a hill.", synonyms: [], antonyms: [] },
            {
              definition: "A mass noun for a quantity of clouds.",
              synonyms: [],
              antonyms: [],
              example:
                "The bank of clouds on the horizon announced the arrival of the predicted storm front.",
            },
            {
              definition: "The face of the coal at which miners are working.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A deposit of ore or coal, worked by excavations above water level.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The ground at the top of a shaft.",
              synonyms: [],
              antonyms: [],
              example: "Ores are brought to bank.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To roll or incline laterally in order to turn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause (an aircraft) to bank.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To form into a bank or heap, to bank up.",
              synonyms: [],
              antonyms: [],
              example: "to bank sand",
            },
            {
              definition:
                "To cover the embers of a fire with ashes in order to retain heat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To raise a mound or dike about; to enclose, defend, or fortify with a bank; to embank.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To pass by the banks of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To provide additional power for a train ascending a bank (incline) by attaching another locomotive.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A row or panel of items stored or grouped together.",
              synonyms: [],
              antonyms: [],
              example: "a bank of pay phones",
            },
            {
              definition:
                "A row of keys on a musical keyboard or the equivalent on a typewriter keyboard.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A contiguous block of memory that is of fixed, hardware-dependent size, but often larger than a page and partitioning the memory such that two distinct banks do not overlap.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A set of multiple adjacent drop targets.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["block", "grid", "line", "panel", "rank", "tier"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(order and arrangement) To arrange or order in a row.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A bench, as for rowers in a galley; also, a tier of oars.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A bench or seat for judges in court.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The regular term of a court of law, or the full court sitting to hear arguments upon questions of law, as distinguished from a sitting at nisi prius, or a court held for jury trials. See banc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A kind of table used by printers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bench, or row of keys belonging to a keyboard, as in an organ.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Slang for money", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bank",
        "https://en.wiktionary.org/wiki/banks",
      ],
    },
  ],
  shoot: [
    {
      word: "shoot",
      phonetic: "/ʃuːt/",
      phonetics: [
        {
          text: "/ʃuːt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shoot-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=91894783",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ʃuːt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shoot-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=782545",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The emerging stem and embryonic leaves of a new plant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A photography session.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A hunt or shooting competition.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An event that is unscripted or legitimate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The act of shooting; the discharge of a missile; a shot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A rush of water; a rapid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A weft thread shot through the shed by the shuttle; a pick.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A shoat; a young pig.", synonyms: [], antonyms: [] },
            {
              definition:
                "A vein of ore running in the same general direction as the lode.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An inclined plane, either artificial or natural, down which timber, coal, ore, etc., are caused to slide; a chute.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The act of taking all point cards in one hand.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To launch a projectile.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To move or act quickly or suddenly.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To act or achieve.", synonyms: [], antonyms: [] },
            {
              definition: "To measure the distance and direction to (a point).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To inject a drug (such as heroin) intravenously.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To develop, move forward.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To protrude; to jut; to project; to extend.",
              synonyms: [],
              antonyms: [],
              example: "The land shoots into a promontory.",
            },
            {
              definition: "To plane straight; to fit by planing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To variegate as if by sprinkling or intermingling; to color in spots or patches.W",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To shoot the moon.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shoot"],
    },
    {
      word: "shoot",
      phonetic: "/ʃuːt/",
      phonetics: [
        {
          text: "/ʃuːt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shoot-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=91894783",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ʃuːt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shoot-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=782545",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "A mild expletive, expressing disbelief or disdain",
              synonyms: [],
              antonyms: [],
              example: "Didn't you have a concert tonight?",
            },
          ],
          synonyms: ["darn", "dash", "fiddlesticks", "shucks", "sugar"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shoot"],
    },
  ],
  curve: [
    {
      word: "curve",
      phonetic: "/kɜːv/",
      phonetics: [
        { text: "/kɜːv/", audio: "" },
        {
          text: "/kɝv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/curve-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=840692",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A gentle bend, such as in a road.",
              synonyms: [],
              antonyms: [],
              example: "You should slow down when approaching a curve.",
            },
            {
              definition:
                "A simple figure containing no straight portions and no angles; a curved line.",
              synonyms: [],
              antonyms: [],
              example: "She scribbled a curve on the paper.",
            },
            {
              definition:
                "A grading system based on the scale of performance of a group used to normalize a right-skewed grade distribution (with more lower scores) into a bell curve, so that more can receive higher grades, regardless of their actual knowledge of the subject.",
              synonyms: [],
              antonyms: [],
              example: "The teacher was nice and graded the test on a curve.",
            },
            {
              definition:
                "A continuous map from a one-dimensional space to a multidimensional space.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A one-dimensional figure of non-zero length; the graph of a continuous map from a one-dimensional space.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An algebraic curve; a polynomial relation of the planar coordinates.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A one-dimensional continuum.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(usually in the plural) The attractive shape of a woman's body.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To bend; to crook.",
              synonyms: [],
              antonyms: [],
              example: "to curve a line",
            },
            {
              definition: "To cause to swerve from a straight course.",
              synonyms: [],
              antonyms: [],
              example: "to curve a ball in pitching it",
            },
            {
              definition: "To bend or turn gradually from a given direction.",
              synonyms: [],
              antonyms: [],
              example: "the road curves to the right",
            },
            {
              definition:
                "To grade on a curve (bell curve of a normal distribution).",
              synonyms: [],
              antonyms: [],
              example: "The teacher will curve the test.",
            },
            {
              definition: "To reject, to turn down romantic advances.",
              synonyms: [],
              antonyms: [],
              example: "I was once curved three times by the same woman.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Bent without angles; crooked; curved.",
              synonyms: [],
              antonyms: [],
              example: "a curve line",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/curve"],
    },
  ],
  tribe: [
    {
      word: "tribe",
      phonetic: "/tɹaɪb/",
      phonetics: [
        {
          text: "/tɹaɪb/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tribe-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1750021",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A socially, ethnically, or politically cohesive group of people.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A society larger than a band but smaller than a state.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A group of apes who live and work together.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A hierarchal rank between family and genus.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The collective noun for various animals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(stock breeding) A family of animals descended from some particular female progenitor, through the female line.",
              synonyms: [],
              antonyms: [],
              example: "the Duchess tribe of shorthorns",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To distribute into tribes or classes; to categorize.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tribe"],
    },
  ],
  tight: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  blind: [
    {
      word: "blind",
      phonetic: "/blaɪnd/",
      phonetics: [
        {
          text: "/blaɪnd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/blind-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=179855",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A covering for a window to keep out light. The covering may be made of cloth or of narrow slats that can block light or allow it to pass.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A destination sign mounted on a public transport vehicle displaying the route destination, number, name and/or via points, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any device intended to conceal or hide.",
              synonyms: [],
              antonyms: [],
              example: "a duck blind",
            },
            {
              definition:
                "Something to mislead the eye or the understanding, or to conceal some covert deed or design; a subterfuge.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A blindage.", synonyms: [], antonyms: [] },
            { definition: "A halting place.", synonyms: [], antonyms: [] },
            { definition: "The blindside.", synonyms: [], antonyms: [] },
            { definition: "(1800s) No score.", synonyms: [], antonyms: [] },
            {
              definition: "A forced bet: the small blind or the big blind.",
              synonyms: [],
              antonyms: [],
              example: "The blinds are $10/$20 and the ante is $1.",
            },
            {
              definition: "A player who is forced to pay such a bet.",
              synonyms: [],
              antonyms: [],
              example: "The blinds immediately folded when I reraised.",
            },
            {
              definition:
                "(as a plural) Those who are blind, taken as a group.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["rollsign"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make temporarily or permanently blind.",
              synonyms: [],
              antonyms: [],
              example:
                "Don't wave that pencil in my face - do you want to blind me?",
            },
            { definition: "To curse.", synonyms: [], antonyms: [] },
            {
              definition:
                "To darken; to obscure to the eye or understanding; to conceal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cover with a thin coating of sand and fine gravel, for example a road newly paved, in order that the joints between the stones may be filled.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(of a person or animal) Unable to see, due to physiological or neurological factors.",
              synonyms: [],
              antonyms: ["seeing", "sighted"],
            },
            {
              definition:
                "(of an eye) Unable to be used to see, due to physiological or neurological factors.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Failing to see, acknowledge, perceive.",
              synonyms: [],
              antonyms: [],
              example: "Authors are blind to their own defects.",
            },
            {
              definition: "Of a place, having little or no visibility.",
              synonyms: [],
              antonyms: [],
              example: "a blind corner",
            },
            {
              definition: "Closed at one end; having a dead end",
              synonyms: [],
              antonyms: [],
              example: "a blind gut",
            },
            {
              definition: "Having no openings for light or passage.",
              synonyms: [],
              antonyms: [],
              example: "a blind alley",
            },
            {
              definition: "(in certain phrases) Smallest or slightest.",
              synonyms: [],
              antonyms: [],
              example: "I shouted, but he didn't take a blind bit of notice.",
            },
            {
              definition: "Without any prior knowledge.",
              synonyms: [],
              antonyms: [],
              example:
                "He took a blind guess at which fork in the road would take him to the airport.",
            },
            {
              definition:
                "Unconditional; without regard to evidence, logic, reality, accidental mistakes, extenuating circumstances, etc.",
              synonyms: [],
              antonyms: [],
              example: "blind deference",
            },
            {
              definition: "Unintelligible or illegible.",
              synonyms: [],
              antonyms: [],
              example: "a blind passage in a book; blind writing",
            },
            {
              definition: "Abortive; failing to produce flowers or fruit.",
              synonyms: [],
              antonyms: [],
              example: "blind buds",
            },
          ],
          synonyms: [],
          antonyms: ["seeing", "sighted"],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Without seeing; unseeingly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Absolutely, totally.",
              synonyms: [],
              antonyms: [],
              example: "to swear blind",
            },
            {
              definition:
                "(three card brag) Without looking at the cards dealt.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/blind"],
    },
  ],
  slept: [
    {
      word: "slept",
      phonetic: "/slɛpt/",
      phonetics: [
        {
          text: "/slɛpt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/slept-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1676861",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To rest in a state of reduced consciousness.",
              synonyms: [],
              antonyms: [],
              example: "You should sleep 8 hours a day.",
            },
            {
              definition:
                "(of a spinning top or yo-yo) To spin on its axis with no other perceptible motion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause (a spinning top or yo-yo) to spin on its axis with no other perceptible motion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To accommodate in beds.",
              synonyms: [],
              antonyms: [],
              example: "This caravan can sleep four people comfortably.",
            },
            {
              definition: "To be slumbering in (a state).",
              synonyms: [],
              antonyms: [],
              example: "to sleep a dreamless sleep",
            },
            {
              definition:
                "To be careless, inattentive, or unconcerned; not to be vigilant; to live thoughtlessly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be dead; to lie in the grave.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be, or appear to be, in repose; to be quiet; to be unemployed, unused, or unagitated; to rest; to lie dormant.",
              synonyms: [],
              antonyms: [],
              example: "a question sleeps for the present; the law sleeps",
            },
            {
              definition:
                "To wait for a period of time without performing any action.",
              synonyms: [],
              antonyms: [],
              example:
                "After a failed connection attempt, the program sleeps for 5 seconds before trying again.",
            },
            {
              definition: "To place into a state of hibernation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sleep",
        "https://en.wiktionary.org/wiki/slept",
      ],
    },
  ],
  shade: [
    {
      word: "shade",
      phonetic: "/ʃeɪd/",
      phonetics: [
        {
          text: "/ʃeɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shade-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1157659",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Darkness where light, particularly sunlight, is blocked.",
              synonyms: [],
              antonyms: [],
              example: "The old oak tree gave shade in the heat of the day.",
            },
            {
              definition:
                "Something that blocks light, particularly in a window.",
              synonyms: [],
              antonyms: [],
              example: "Close the shade, please: it's too bright in here.",
            },
            {
              definition:
                "A variety of a colour/color, in particular one obtained by adding black (compare tint).",
              synonyms: [],
              antonyms: [],
              example:
                "I've painted my room in five lovely shades of pink and chartreuse.",
            },
            {
              definition: "A subtle variation in a concept.",
              synonyms: [],
              antonyms: [],
              example: "shades of meaning",
            },
            {
              definition: "An aspect that is reminiscent of something.",
              synonyms: [],
              antonyms: [],
              example: "shades of Groucho",
            },
            {
              definition:
                "A very small degree of a quantity, or variety of meaning",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A ghost or specter; a spirit.",
              synonyms: [],
              antonyms: [],
              example: "The adventurer was attacked by a shade.",
            },
            {
              definition:
                "A postage stamp showing an obvious difference in colour/color to the original printing and needing a separate catalogue/catalog entry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(originally gay slang) Subtle insults.",
              synonyms: [],
              antonyms: [],
              example:
                "Why did you paint your room chartreuse? No shade; I'm genuinely curious.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shade"],
    },
    {
      word: "shade",
      phonetic: "/ʃeɪd/",
      phonetics: [
        {
          text: "/ʃeɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shade-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1157659",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To shield from light.",
              synonyms: [],
              antonyms: [],
              example:
                "The old oak tree shaded the lawn in the heat of the day.",
            },
            {
              definition: "To alter slightly.",
              synonyms: [],
              antonyms: [],
              example:
                "Most politicians will shade the truth if it helps them.",
            },
            {
              definition:
                "To vary or approach something slightly, particularly in color.",
              synonyms: [],
              antonyms: [],
              example:
                "The hillside was bright green, shading towards gold in the drier areas.",
            },
            {
              definition:
                "(of a defensive player) To move slightly from one's normal fielding position.",
              synonyms: [],
              antonyms: [],
              example:
                "Jones will shade a little to the right on this pitch count.",
            },
            {
              definition: "To darken, particularly in drawing.",
              synonyms: [],
              antonyms: [],
              example:
                "I draw contours first, gradually shading in midtones and shadows.",
            },
            {
              definition: "To surpass by a narrow margin.",
              synonyms: [],
              antonyms: [],
              example:
                "Both parties claimed afterwards that their man did best in the debate, but an early opinion poll suggested Mr Cameron shaded it.",
            },
            {
              definition:
                "To reduce (a window) so that only its title bar is visible.",
              synonyms: [],
              antonyms: ["unshade"],
            },
            {
              definition:
                "To shelter; to cover from injury; to protect; to screen.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To present a shadow or image of; to shadow forth; to represent.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["unshade"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shade"],
    },
  ],
  claim: [
    {
      word: "claim",
      phonetic: "/kleɪm/",
      phonetics: [
        {
          text: "/kleɪm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/claim-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1648216",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A demand of ownership made for something.",
              synonyms: [],
              antonyms: [],
              example: "a claim of ownership",
            },
            { definition: "The thing claimed.", synonyms: [], antonyms: [] },
            {
              definition: "The right or ground of demanding.",
              synonyms: [],
              antonyms: [],
              example:
                "You don't have any claim on my time, since I'm no longer your employee.",
            },
            {
              definition:
                "A new statement of something one believes to be the truth, usually when the statement has yet to be verified or without valid evidence provided.",
              synonyms: [],
              antonyms: [],
              example:
                "The company's share price dropped amid claims of accounting fraud.",
            },
            {
              definition: "A demand of ownership for previously unowned land.",
              synonyms: [],
              antonyms: [],
              example: "Miners had to stake their claims during the gold rush.",
            },
            {
              definition: "A legal demand for compensation or damages.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To demand ownership of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To state a new fact, typically without providing evidence to prove it is true.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To demand ownership or right to use for land.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To demand compensation or damages through the courts.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be entitled to anything; to deduce a right or title; to have a claim.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause the loss of, usually by violent means.",
              synonyms: [],
              antonyms: [],
              example: "A fire claimed two homes.",
            },
            { definition: "To proclaim.", synonyms: [], antonyms: [] },
            { definition: "To call or name.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/claim"],
    },
  ],
  flies: [
    {
      word: "flies",
      phonetic: "/flaɪz/",
      phonetics: [{ text: "/flaɪz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The open area above a stage where scenery and equipment may be hung.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any insect of the order Diptera; characterized by having two wings (except for some wingless species), also called true flies.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(non-technical) Especially, any of the insects of the family Muscidae, such as the common housefly (other families of Diptera include mosquitoes and midges).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any similar, but unrelated insect such as dragonfly or butterfly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A lightweight fishing lure resembling an insect.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A chest exercise performed by moving extended arms from the sides to in front of the chest. (also flye)",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A witch's familiar.", synonyms: [], antonyms: [] },
            { definition: "A parasite.", synonyms: [], antonyms: [] },
            {
              definition: "The butterfly stroke (plural is normally flys)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(preceded by definite article) A simple dance in which the hands are shaken in the air, popular in the 1960s.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The action of flying; flight.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An act of flying.",
              synonyms: [],
              antonyms: [],
              example:
                "There was a good wind, so I decided to give the kite a fly.",
            },
            { definition: "A fly ball.", synonyms: [], antonyms: [] },
            {
              definition:
                "A type of small, fast carriage (sometimes pluralised flys).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of canvas that covers the opening at the front of a tent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(often plural) A strip of material (sometimes hiding zippers or buttons) at the front of a pair of trousers, pants, underpants, bootees, etc.",
              synonyms: [],
              antonyms: [],
              example: "Ha-ha! Your flies are undone!",
            },
            {
              definition: "The free edge of a flag.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The horizontal length of a flag.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An exercise that involves wide opening and closing of the arms perpendicular to the shoulders.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The part of a vane pointing the direction from which the wind blows.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That part of a compass on which the points are marked; the compass card.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Two or more vanes set on a revolving axis, to act as a fanner, or to equalize or impede the motion of machinery by the resistance of the air, as in the striking part of a clock.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A heavy wheel, or cross arms with weights at the ends on a revolving axis, to regulate or equalize the motion of machinery by means of its inertia, where the power communicated, or the resistance to be overcome, is variable, as in the steam engine or the coining press. See flywheel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A light horse-drawn carriage that can be hired for transportation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In a knitting machine, the piece hinged to the needle, which holds the engaged loop in position while the needle is penetrating another loop; a latch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The pair of arms revolving around the bobbin, in a spinning wheel or spinning frame, to twist the yarn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A shuttle driven through the shed by a blow or jerk.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The person who took the printed sheets from the press.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A vibrating frame with fingers, attached to a power printing press for doing the same work.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One of the upper screens of a stage in a theatre.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(cotton manufacture) waste cotton",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To hit a fly ball; to hit a fly ball that is caught for an out. Compare ground (verb) and line (verb).",
              synonyms: [],
              antonyms: [],
              example: "Jones flied to right in his last at-bat.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To travel through the air, another gas or a vacuum, without being in contact with a grounded surface.",
              synonyms: [],
              antonyms: [],
              example:
                "Birds of passage fly to warmer regions as it gets colder in winter.",
            },
            {
              definition: "To flee, to escape (from).",
              synonyms: [],
              antonyms: [],
              example: "Fly, my lord! The enemy are upon us!",
            },
            {
              definition:
                "To cause to fly (travel or float in the air): to transport via air or the like.",
              synonyms: [],
              antonyms: [],
              example:
                "Birds fly their prey to their nest to feed it to their young.",
            },
            {
              definition:
                "(of a proposal, project or idea) To be accepted, come about or work out.",
              synonyms: [],
              antonyms: [],
              example: "Let's see if that idea flies.",
            },
            {
              definition: "To travel very fast, hasten.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To move suddenly, or with violence; to do an act suddenly or swiftly.",
              synonyms: [],
              antonyms: [],
              example: "a door flies open;  a bomb flies apart",
            },
            {
              definition: "To display (a flag) on a flagpole.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To hunt with a hawk.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "hurry",
            "zoom",
            "abscond",
            "escape",
            "flee",
            "ascend",
            "aviate",
            "float",
            "glide",
            "hover",
            "rise",
            "skim",
            "soar",
            "wing",
            "dart",
            "flit",
          ],
          antonyms: ["remain", "stay", "walk"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(rural) A wing.",
              synonyms: [],
              antonyms: [],
              example: "The bullet barely grazed the wild fowl's fly.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/flies",
        "https://en.wiktionary.org/wiki/fly",
      ],
    },
  ],
  theme: [
    {
      word: "theme",
      phonetic: "/θiːm/",
      phonetics: [
        {
          text: "/θiːm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/theme-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=399105",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A subject of a talk or an artistic piece; a topic.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A recurring idea; a motif.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An essay written for school.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The main melody of a piece of music, especially one that is the source of variations.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A song, or a snippet of a song, that identifies a film, a TV program, a character, etc. by playing at the appropriate time.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'The collection of color schemes, sounds, artwork etc., that "skin" an environment towards a particular motif.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(grammar) The stem of a word.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Thematic relation of a noun phrase to a verb.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Theta role in generative grammar and government and binding theory.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Topic, what is generally being talked about, as opposed to rheme.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A regional unit of organisation in the Byzantine empire.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To give a theme to.",
              synonyms: [],
              antonyms: [],
              example: "We themed the birthday party around superheroes.",
            },
            {
              definition:
                "To apply a theme to; to change the visual appearance and/or layout of (software).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/theme"],
    },
  ],
  queen: [
    {
      word: "queen",
      phonetic: "/kwiːn/",
      phonetics: [
        {
          text: "/kwiːn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/queen-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1213486",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A female monarch. Example: Queen Victoria.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The wife or widow of a king.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The most powerful piece, able to move any number of spaces horizontally, vertically, or diagonally.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'A playing card with the letter "Q" and the image of a queen on it, the twelfth card in a given suit.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A red disk that is the most valuable piece in the Asian game of carom.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A powerful or forceful female person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An effeminate male homosexual. (See usage notes.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A reproductive female animal in a hive, such as an ant, bee, termite or wasp.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An adult female cat valued for breeding. See also tom.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A queen olive.", synonyms: [], antonyms: [] },
            {
              definition:
                "A monarch butterfly (Danaus spp., esp. Danaus gilippus).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "queen regnant",
            "bitch",
            "cowgirl",
            "girl",
            "lady",
            "mop squeezer",
            "queen consort",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To make a queen.", synonyms: [], antonyms: [] },
            {
              definition: "To act the part of a queen; to queen it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To promote a pawn, usually to a queen.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To provide with a new queen.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be the queen of a colony.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(BDSM, of a female) To sit on the face of (a partner) to receive oral sex.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/queen"],
    },
  ],
  fifth: [
    {
      word: "fifth",
      phonetic: "/fɪft/",
      phonetics: [
        { text: "/fɪft/", audio: "" },
        {
          text: "/fɪft/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fifth-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=268353",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The person or thing in the fifth position.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One of five equal parts of a whole.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The fifth gear of an engine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A quantity of liquor equal to one-fifth of a gallon, or, more commonly, 750 milliliters (that is, three quarters of a liter).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The musical interval between one note and another seven semitones higher (the fifth note in the major/minor scale)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The fifth voice in a polyphonic melody.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["⅕"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To support something fifth, after four others have already done so.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To divide by 5, equates to multiplying a denominator by 5",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "The ordinal form of the number five.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["5th", "V", "Vth"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fifth"],
    },
  ],
  union: [
    {
      word: "union",
      phonetic: "/ˈjuːnjən/",
      phonetics: [
        {
          text: "/ˈjuːnjən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/union-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1235966",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The act of uniting or joining two or more things into one.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The state of being united or joined; a state of unity or harmony.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That which is united, or made one; something formed by a combination or coalition of parts or members; a confederation; a consolidated body; a league.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A trade union; a workers' union.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An association of students at a university for social and/or political purposes; also in some cases a debating body.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A joint or other connection uniting parts of machinery, such as pipes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The set containing all of the elements of two or more sets.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The act or state of marriage.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Sexual intercourse.", synonyms: [], antonyms: [] },
            {
              definition:
                "A data structure that can store any of various types of item, but only one at a time.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A large, high-quality pearl.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An affiliation of several parishes for joint support and management of their poor; also the jointly-owned workhouse.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["coalition", "combination", "junction"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To combine sets using the union operation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/union"],
    },
  ],
  hence: [
    {
      word: "hence",
      phonetic: "/ˈhɛns/",
      phonetics: [
        {
          text: "/ˈhɛns/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hence-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1684632",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "From here, from this place, away",
              synonyms: [],
              antonyms: [],
              example: "Get thee hence, Satan!",
            },
            {
              definition: "From the living or from this world",
              synonyms: [],
              antonyms: [],
              example: "After a long battle, my poor daughter was taken hence.",
            },
            {
              definition: "(of a length of time) in the future from now",
              synonyms: [],
              antonyms: [],
              example: "A year hence it will be forgotten.",
            },
            {
              definition: "As a result; therefore, for this reason",
              synonyms: [],
              antonyms: [],
              example:
                "I shall go to Japan and hence will not be here in time for the party.",
            },
          ],
          synonyms: ["consequently", "herefrom"],
          antonyms: ["hither"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hence"],
    },
  ],
  straw: [
    {
      word: "straw",
      phonetic: "/stɹɔː/",
      phonetics: [
        { text: "/stɹɔː/", audio: "" },
        {
          text: "/stɹɔ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/straw-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780406",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A dried stalk of a cereal plant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Such dried stalks considered collectively.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A drinking straw.", synonyms: [], antonyms: [] },
            {
              definition:
                "A pale, yellowish beige colour, like that of a dried straw.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Anything proverbially worthless; the least possible thing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To lay straw around plants to protect them from frost.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To sell straws on the streets in order to cover the giving to the purchaser of things usually banned, such as pornography.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Made of straw.",
              synonyms: ["strawen"],
              antonyms: [],
              example: "straw hat",
            },
            {
              definition:
                "Of a pale, yellowish beige colour, like that of a dried straw.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Imaginary, but presented as real.",
              synonyms: [],
              antonyms: [],
              example:
                "A straw enemy built up in the media to seem like a real threat, which then collapses like a balloon.",
            },
          ],
          synonyms: ["strawen"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/straw"],
    },
  ],
  entry: [
    {
      word: "entry",
      phonetic: "/ˈɛntɹi/",
      phonetics: [
        {
          text: "/ˈɛntɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/entry.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=81984459",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The act of entering.", synonyms: [], antonyms: [] },
            {
              definition: "Permission to enter.",
              synonyms: [],
              antonyms: [],
              example:
                "Children are allowed entry only if accompanied by an adult.",
            },
            {
              definition:
                "A doorway that provides a means of entering a building.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The act of taking possession.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The start of an insurance contract.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A passageway between terraced houses that provides a means of entering a back garden or yard.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small room immediately inside the front door of a house or other building, often having an access to a stairway and leading on to other rooms",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small group formed within a church, especially Episcopal, for simple dinner and fellowship, and to help facilitate new friendships",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An item in a list, such as an article in a dictionary or encyclopedia.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A record made in a log, diary or anything similarly organized; a datum in a database.",
              synonyms: [],
              antonyms: [],
              example: "What does the entry for 2 August 2005 say?",
            },
            {
              definition: "A term at any position in a matrix.",
              synonyms: [],
              antonyms: [],
              example:
                "The entry in the second row and first column of this matrix is 6.",
            },
            {
              definition:
                "The exhibition or depositing of a ship's papers at the customhouse, to procure licence to land goods; or the giving an account of a ship's cargo to the officer of the customs, and obtaining his permission to land the goods.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The point when a musician starts to play or sing; entrance.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "access",
            "enter",
            "entrance",
            "article",
            "lemma",
            "lexeme",
            "entrance",
            "ingang",
            "way in",
            "access",
            "admission",
            "record",
            "element",
          ],
          antonyms: ["departure", "exit", "leaving", "exit", "way out"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/entry"],
    },
  ],
  issue: [
    {
      word: "issue",
      phonetic: "/ˈɪsjuː/",
      phonetics: [
        {
          text: "/ˈɪsjuː/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/issue-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=53355522",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈɪʃ(j)u/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/issue-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1684654",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The action or an instance of flowing or coming out, an outflow, particularly:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Someone or something that flows out or comes out, particularly:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The means or opportunity by which something flows or comes out, particularly:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The place where something flows or comes out, an outlet, particularly:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The action or an instance of sending something out, particularly:",
              synonyms: [],
              antonyms: [],
              example:
                "The issue of the directive from the treasury prompted the central bank's most recent issue of currency.",
            },
            {
              definition:
                "Any question or situation to be resolved, particularly:",
              synonyms: [],
              antonyms: [],
              example: "Please stand by. We are having technical issues.",
            },
            {
              definition:
                "The action or an instance of concluding something, particularly:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The end result of an event or events, any result or outcome, particularly:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The action or an instance of feeling some emotion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The action or an instance of leaving any state or condition.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "charge",
            "sally",
            "sortie",
            "descendant",
            "fruit of one's loins",
            "offspring",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To flow out, to proceed from, to come out or from.",
              synonyms: [],
              antonyms: [],
              example:
                "The rents issuing from the land permitted him to live as a man of independent means.",
            },
            {
              definition: "To rush out, to sally forth.",
              synonyms: [],
              antonyms: [],
              example:
                "The men issued from the town and attacked the besiegers.",
            },
            {
              definition: "To extend into, to open onto.",
              synonyms: [],
              antonyms: [],
              example: "The road issues into the highway.",
            },
            {
              definition: "To turn out in a certain way, to result in.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To come to a point in fact or law on which the parties join issue.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To send out; to put into circulation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To deliver for use.",
              synonyms: [],
              antonyms: [],
              example: "The prison issued new uniforms for the inmates.",
            },
            {
              definition: "To deliver by authority.",
              synonyms: [],
              antonyms: [],
              example: "The court issued a writ of mandamus.",
            },
          ],
          synonyms: ["begive"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/issue"],
    },
  ],
  birth: [
    {
      word: "birth",
      phonetic: "/bɜːθ/",
      phonetics: [
        { text: "/bɜːθ/", audio: "" },
        {
          text: "/bɝθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/birth-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=837238",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The process of childbearing; the beginning of life.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An instance of childbirth.",
              synonyms: [],
              antonyms: [],
              example:
                "Intersex babies account for roughly one per cent of all births.",
            },
            {
              definition: "A beginning or start; a point of origin.",
              synonyms: [],
              antonyms: [],
              example: "the birth of an empire",
            },
            {
              definition:
                "The circumstances of one's background, ancestry, or upbringing.",
              synonyms: [],
              antonyms: [],
              example:
                "He was of noble birth, but fortune had not favored him.",
            },
            { definition: "That which is born.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: ["death"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To bear or give birth to (a child).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To produce, give rise to.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "A familial relationship established by childbirth.",
              synonyms: [],
              antonyms: [],
              example:
                "Her birth father left when she was a baby; she was raised by her mother and stepfather.",
            },
          ],
          synonyms: ["biological", "blood", "consanguineous"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/birth"],
    },
  ],
  feels: [
    {
      word: "feels",
      phonetic: "/fiːlz/",
      phonetics: [
        {
          text: "/fiːlz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/feels-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1231912",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of or relating to the emotions.",
              synonyms: [],
              antonyms: [],
              example: "emotional crisis",
            },
            {
              definition: "Characterised by emotion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Determined by emotion rather than reason.",
              synonyms: [],
              antonyms: [],
              example: "emotional decision",
            },
            {
              definition: "Appealing to or arousing emotion.",
              synonyms: [],
              antonyms: [],
              example: "emotional speech",
            },
            {
              definition: "Easily affected by emotion.",
              synonyms: [],
              antonyms: [],
              example: "She’s an emotional person.",
            },
            {
              definition: "Readily displaying emotion.",
              synonyms: [],
              antonyms: [],
              example: "emotional greeting",
            },
          ],
          synonyms: [
            "demonstrative",
            "effusive",
            "sentimental",
            "temperamental",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A quality of an object experienced by touch.",
              synonyms: [],
              antonyms: [],
              example: "Bark has a rough feel.",
            },
            {
              definition: "A vague mental impression.",
              synonyms: [],
              antonyms: [],
              example: "You should get a feel of the area before moving in.",
            },
            {
              definition: "An act of fondling.",
              synonyms: [],
              antonyms: [],
              example: "She gave me a quick feel to show that she loves me.",
            },
            {
              definition: "A vague understanding.",
              synonyms: [],
              antonyms: [],
              example: "I'm getting a feel for what you mean.",
            },
            {
              definition: "An intuitive ability.",
              synonyms: [],
              antonyms: [],
              example: "She has a feel for music.",
            },
            {
              definition: "Alternative form of feeling.",
              synonyms: [],
              antonyms: [],
              example: "I know that feel.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(heading) To use or experience the sense of touch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(heading) To sense or think emotionally or judgmentally.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be or become aware of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To experience the consequences of.",
              synonyms: [],
              antonyms: [],
              example: "Feel my wrath!",
            },
            {
              definition: "To seem (through touch or otherwise).",
              synonyms: [],
              antonyms: [],
              example: "It looks like wood, but it feels more like plastic.",
            },
            {
              definition: "To understand.",
              synonyms: [],
              antonyms: [],
              example: "I don't want you back here, ya feel me?",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Feelings, emotions", synonyms: [], antonyms: [] },
          ],
          synonyms: ["fee-fees"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/emotional",
        "https://en.wiktionary.org/wiki/feel",
        "https://en.wiktionary.org/wiki/feels",
      ],
    },
  ],
  anger: [
    {
      word: "anger",
      phonetic: "/ˈæŋɡə(ɹ)/",
      phonetics: [
        { text: "/ˈæŋɡə(ɹ)/", audio: "" },
        {
          text: "/ˈæŋɡɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/anger-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1669272",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A strong feeling of displeasure, hostility or antagonism towards someone or something, usually combined with an urge to harm.",
              synonyms: [],
              antonyms: [],
              example: "You need to control your anger.",
            },
            { definition: "Pain or stinging.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cause such a feeling of antagonism in.",
              synonyms: [],
              antonyms: [],
              example: "He who angers you conquers you.",
            },
            {
              definition: "To become angry.",
              synonyms: [],
              antonyms: [],
              example: "You anger too easily.",
            },
          ],
          synonyms: [
            "angry",
            "get",
            "aggravate",
            "annoy",
            "displease",
            "enrage",
            "grill",
            "infuriate",
            "irritate",
            "vex",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/anger"],
    },
  ],
  brief: [
    {
      word: "brief",
      phonetic: "/bɹiːf/",
      phonetics: [
        {
          text: "/bɹiːf/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/brief-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1446913",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A writ summoning one to answer to any action.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An answer to any action.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A memorandum of points of fact or of law for use in conducting a case.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) A position of interest or advocacy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An attorney's legal argument in written form for submission to a court.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(English law) The material relevant to a case, delivered by a solicitor to the barrister who tries the case.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A short news story or report.",
              synonyms: [],
              antonyms: [],
              example: "We got a news brief.",
            },
            {
              definition: "(usually in the plural) undershorts briefs.",
              synonyms: [],
              antonyms: [],
              example:
                "I wear boxers under trousers but for sports I usually wear a brief.",
            },
            {
              definition:
                "A summary, précis or epitome; an abridgement or abstract.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A letter patent, from proper authority, authorizing a collection or charitable contribution of money in churches, for any public or private purpose.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(slang) A ticket of any type.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To summarize a recent development to some person with decision-making power.",
              synonyms: [],
              antonyms: [],
              example:
                "The U.S. president was briefed on the military coup and its implications on African stability.",
            },
            {
              definition: "To write a legal argument and submit it to a court.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of short duration; happening quickly.",
              synonyms: [],
              antonyms: [],
              example: "Her reign was brief but spectacular.",
            },
            {
              definition: "Concise; taking few words.",
              synonyms: [],
              antonyms: [],
              example: "His speech of acceptance was brief but moving.",
            },
            {
              definition:
                "Occupying a small distance, area or spatial extent; short.",
              synonyms: [],
              antonyms: [],
              example: "Her skirt was extremely brief but doubtless cool.",
            },
            {
              definition: "Rife; common; prevalent.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            { definition: "Briefly.", synonyms: [], antonyms: [] },
            { definition: "Soon; quickly.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/brief"],
    },
  ],
  rhyme: [
    {
      word: "rhyme",
      phonetic: "/ɹaɪm/",
      phonetics: [
        { text: "/ɹaɪm/", audio: "" },
        {
          text: "/ɹaɪm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rhyme-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769502",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Rhyming verse (poetic form)",
              synonyms: [],
              antonyms: [],
              example:
                "Many editors say they don't want stories written in rhyme.",
            },
            {
              definition:
                "A thought expressed in verse; a verse; a poem; a tale told in verse.",
              synonyms: [],
              antonyms: [],
              example: "Tennyson’s rhymes",
            },
            {
              definition: "A word that rhymes with another.",
              synonyms: [],
              antonyms: [],
              example:
                'Norse poetry is littered with rhymes like "sól ... sunnan".',
            },
            {
              definition: "Rhyming: sameness of sound of part of some words.",
              synonyms: [],
              antonyms: [],
              example: "The poem exhibits a peculiar form of rhyme.",
            },
            { definition: "Rime", synonyms: [], antonyms: [] },
            { definition: "Number.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To compose or treat in verse; versify.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(followed by with) Of a word, to be pronounced identically with another from the vowel in its stressed syllable to the end.",
              synonyms: [],
              antonyms: [],
              example: '"Creation" rhymes with "integration" and "station".',
            },
            {
              definition:
                "Of two or more words, to be pronounced identically from the vowel in the stressed syllable of each to the end of each.",
              synonyms: [],
              antonyms: [],
              example:
                '"India" and "windier" rhyme with each other in non-rhotic accents.',
            },
            {
              definition: "To number; count; reckon.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rhyme"],
    },
  ],
  glory: [
    {
      word: "glory",
      phonetic: "/ˈɡlo(ː)ɹi/",
      phonetics: [
        {
          text: "/ˈɡlo(ː)ɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/glory-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1244079",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Great or overwhelming beauty or splendour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Honour, admiration, or distinction, accorded by common consent to a person or thing; high reputation; renown.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That quality in a person or thing which secures general praise or honour.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Worship or praise.", synonyms: [], antonyms: [] },
            {
              definition:
                "An optical phenomenon, consisting of concentric rings and somewhat similar to a rainbow, caused by sunlight or moonlight interacting with the water droplets that compose mist or clouds, centered on the antisolar or antilunar point.",
              synonyms: ["anticorona"],
              antonyms: [],
            },
            { definition: "Victory; success.", synonyms: [], antonyms: [] },
            {
              definition:
                "An emanation of light supposed to shine from beings that are specially holy. It is represented in art by rays of gold, or the like, proceeding from the head or body, or by a disk, or a mere line.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The manifestation of the presence of God as perceived by humans in Abrahamic religions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Pride; boastfulness; arrogance.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "halo",
            "anticorona",
            "fame",
            "honor",
            "honour",
            "praise",
            "worship",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To exult with joy; to rejoice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To boast; to be proud.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To shine radiantly.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/glory"],
    },
  ],
  guard: [
    {
      word: "guard",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/guard-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75271734",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ɡɑːd/", audio: "" },
        {
          text: "/ɡɑɹd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/guard-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1648254",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person who, or thing that, protects or watches over something.",
              synonyms: [],
              antonyms: [],
              example:
                "The prison guard unlocked the door of the cell.   After completing the repairs, he replaced the sump guard.",
            },
            {
              definition: "A garda; a police officer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A squad responsible for protecting something.",
              synonyms: [],
              antonyms: [],
              example: "The president inspected the guard of honour.",
            },
            {
              definition:
                "The part of a sword that protects the wielder's hand.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A part of a machine which blocks access to dangerous parts.",
              synonyms: [],
              antonyms: [],
              example:
                "The motorcycle mechanic removed the damaged chain guard.",
            },
            { definition: "A watchchain.", synonyms: [], antonyms: [] },
            {
              definition:
                "A panel of a car that encloses the wheel area, especially the front wheels.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A state of caution; posture of defence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something worn to protect part of the body, e.g. the shins in cricket.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A relatively short player, playing farther from the basket than a forward or center.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The position on the popping crease where a batsman makes a mark to align himself with the wicket; see take guard.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'Either of two offensive positions between the center and each of the offensive tackles, whose main responsibilities are to protect the quarterback, and open up "holes" through which offensive players can run.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A player playing a position named guard.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An employee, normally travelling in the last vehicle of a train, responsible for the safety of the train.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A Boolean expression that must evaluate to true for a branch of program execution to continue.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["fender", "protection", "quillon"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To protect from danger; to secure against surprise, attack, or injury; to keep in safety; to defend.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To keep watch over, in order to prevent escape or restrain from acts of violence, or the like.",
              synonyms: [],
              antonyms: [],
              example: "Guard the prisoner.",
            },
            {
              definition:
                "To watch by way of caution or defense; to be caution; to be in a state or position of defense or safety.",
              synonyms: [],
              antonyms: [],
              example: "Careful people guard against mistakes.",
            },
            {
              definition:
                "To protect the edge of, especially with an ornamental border; hence, to face or ornament with lists, laces, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fasten by binding; to gird.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/guard"],
    },
  ],
  flows: [
    {
      word: "flows",
      phonetic: "/fləʊz/",
      phonetics: [
        { text: "/fləʊz/", audio: "" },
        {
          text: "/floʊz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/flows-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2650961",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A movement in people or things with a particular way in large numbers or amounts",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The movement of a real or figurative fluid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A formalization of the idea of the motion of particles in a fluid, as a group action of the real numbers on a set.",
              synonyms: [],
              antonyms: [],
              example:
                "The notion of flow is basic to the study of ordinary differential equations.",
            },
            {
              definition: "The rising movement of the tide.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Smoothness or continuity.",
              synonyms: [],
              antonyms: [],
              example: "The room was small, but it had good symmetry and flow.",
            },
            {
              definition:
                "The amount of a fluid that moves or the rate of fluid movement.",
              synonyms: [],
              antonyms: [],
              example:
                "Other devices measure water flow in streams fed by melted ice.",
            },
            {
              definition:
                "A flow pipe, carrying liquid away from a boiler or other central plant (compare with return pipe which returns fluid to central plant).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A mental state characterized by concentration, focus and enjoyment of a given task.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The emission of blood during menstruation.",
              synonyms: [],
              antonyms: [],
              example:
                "Tampons can be small or large, slender or thick. From “slender” to “super”, you can pick the size that matches your flow.",
            },
            {
              definition: "The ability to skilfully rap along to a beat.",
              synonyms: [],
              antonyms: [],
              example:
                "The production on his new mixtape is mediocre but his flow is on point.",
            },
          ],
          synonyms: [],
          antonyms: ["ebb"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To move as a fluid from one position to another.",
              synonyms: [],
              antonyms: [],
              example: "Rivers flow from springs and lakes.",
            },
            {
              definition: "To proceed; to issue forth.",
              synonyms: [],
              antonyms: [],
              example: "Wealth flows from industry and economy.",
            },
            {
              definition:
                "To move or match smoothly, gracefully, or continuously.",
              synonyms: [],
              antonyms: [],
              example:
                "The writing is grammatically correct, but it just doesn't flow.",
            },
            {
              definition:
                "To have or be in abundance; to abound, so as to run or flow over.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To hang loosely and wave.",
              synonyms: [],
              antonyms: [],
              example: "a flowing mantle; flowing locks",
            },
            {
              definition: "To rise, as the tide; opposed to ebb.",
              synonyms: [],
              antonyms: [],
              example: "The tide flows twice in twenty-four hours.",
            },
            {
              definition:
                "To arrange (text in a wordprocessor, etc.) so that it wraps neatly into a designated space; to reflow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cover with water or other liquid; to overflow; to inundate; to flood.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cover with varnish.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To discharge excessive blood from the uterus.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A morass or marsh.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/flow",
        "https://en.wiktionary.org/wiki/flows",
      ],
    },
  ],
  flesh: [
    {
      word: "flesh",
      phonetic: "/flɛʃ/",
      phonetics: [
        {
          text: "/flɛʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/flesh-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1661655",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The soft tissue of the body, especially muscle and fat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The skin of a human or animal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) Bare arms, bare legs, bare torso.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Animal tissue regarded as food; meat (but sometimes excluding fish).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The human body as a physical entity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The mortal body of a human being, contrasted with the spirit or soul.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The evil and corrupting principle working in man.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The soft, often edible, parts of fruits or vegetables.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Tenderness of feeling; gentleness.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Kindred; stock; race.", synonyms: [], antonyms: [] },
            {
              definition:
                "A yellowish pink colour; the colour of some Caucasian human skin.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To reward (a hound, bird of prey etc.) with flesh of the animal killed, to excite it for further hunting; to train (an animal) to have an appetite for flesh.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To bury (something, especially a weapon) in flesh.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To inure or habituate someone in or to a given practice.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To glut.", synonyms: [], antonyms: [] },
            {
              definition: "To put flesh on; to fatten.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To remove the flesh from the skin during the making of leather.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/flesh"],
    },
  ],
  owned: [
    {
      word: "owned",
      phonetic: "/əʊnd/",
      phonetics: [
        { text: "/əʊnd/", audio: "" },
        {
          text: "/oʊnd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/owned-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2101084",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To have rightful possession of (property, goods or capital); to have legal title to.",
              synonyms: [],
              antonyms: [],
              example: "I own this car.",
            },
            {
              definition:
                "To have recognized political sovereignty over a place, territory, as distinct from the ordinary connotation of property ownership.",
              synonyms: [],
              antonyms: [],
              example:
                "The United States owns Point Roberts by the terms of the Treaty of Oregon.",
            },
            {
              definition: "To defeat or embarrass; to overwhelm.",
              synonyms: [],
              antonyms: [],
              example: "I will own my enemies.",
            },
            {
              definition: "To virtually or figuratively enslave.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To defeat, dominate, or be above, also spelled pwn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To illicitly obtain superuser or root access to a computer system, thereby having access to all of the user files on that system; pwn.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "beat",
            "best",
            "defeat",
            "have",
            "overcome",
            "overthrow",
            "take",
            "vanquish",
            "possess",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To grant; give.", synonyms: [], antonyms: [] },
            {
              definition:
                "To admit, concede, grant, allow, acknowledge, confess; not to deny.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To admit; concede; acknowledge.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To take responsibility for.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To answer to.", synonyms: [], antonyms: [] },
            {
              definition: "To recognise; acknowledge.",
              synonyms: [],
              antonyms: [],
              example: "to own one as a son",
            },
            {
              definition: "To claim as one's own.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To confess.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/own",
        "https://en.wiktionary.org/wiki/owned",
      ],
    },
  ],
  trick: [
    {
      word: "trick",
      phonetic: "/tɹɪk/",
      phonetics: [
        {
          text: "/tɹɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/trick-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1197657",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Something designed to fool or swindle.",
              synonyms: [],
              antonyms: [],
              example:
                "It was just a trick to say that the house was underpriced.",
            },
            {
              definition:
                "A single element of a magician's (or any variety entertainer's) act; a magic trick.",
              synonyms: [],
              antonyms: [],
              example:
                "And for my next trick, I will pull a wombat out of a duffel bag.",
            },
            {
              definition: "An entertaining difficult physical action.",
              synonyms: [],
              antonyms: [],
              example:
                "That's a nice skateboard, but can you do any tricks on it?",
            },
            {
              definition:
                "An effective, clever or quick way of doing something.",
              synonyms: [],
              antonyms: [],
              example:
                "tricks of the trade;  what's the trick of getting this chair to fold up?",
            },
            {
              definition: "Mischievous or annoying behavior; a prank.",
              synonyms: [],
              antonyms: [],
              example: "the tricks of boys",
            },
            {
              definition:
                "A particular habit or manner; a peculiarity; a trait.",
              synonyms: [],
              antonyms: [],
              example:
                "a trick of drumming with the fingers; a trick of frowning",
            },
            {
              definition: "A knot, braid, or plait of hair.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sequence in which each player plays a card and a winning play is determined.",
              synonyms: [],
              antonyms: [],
              example:
                "I was able to take the second trick with the queen of hearts.",
            },
            {
              definition:
                "A sex act, chiefly one performed for payment; an act of prostitution.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A customer to a prostitute.",
              synonyms: [],
              antonyms: [],
              example:
                'As the businessman rounded the corner, she thought, "Here comes another trick."',
            },
            {
              definition:
                "A daily period of work, especially in shift-based jobs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sailor's spell of work at the helm, usually two hours long.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A toy; a trifle; a plaything.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "john",
            "shift",
            "illusion",
            "magic trick",
            "sleight of hand",
            "artifice",
            "con",
            "gambit",
            "ploy",
            "rip-off",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To fool; to cause to believe something untrue; to deceive.",
              synonyms: [],
              antonyms: [],
              example:
                "You tried to trick me when you said that house was underpriced.",
            },
            {
              definition:
                "To draw (as opposed to blazon - to describe in words).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To dress; to decorate; to adorn fantastically; often followed by up, off, or out.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "con",
            "dupe",
            "fool",
            "gull",
            "have",
            "hoodwink",
            "pull the wool over someone's eyes",
            "rip off",
            "mod",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Involving trickery or deception.",
              synonyms: [],
              antonyms: [],
              example: "trick photography",
            },
            {
              definition: "Able to perform tricks.",
              synonyms: [],
              antonyms: [],
              example: "A trick pony",
            },
            {
              definition: "Defective or unreliable.",
              synonyms: [],
              antonyms: [],
              example: "a trick knee",
            },
            {
              definition: "Stylish or cool.",
              synonyms: [],
              antonyms: [],
              example: "Wow, your new sportscar is so trick.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/trick"],
    },
  ],
  yours: [
    {
      word: "yours",
      phonetic: "/jɔː(ɹ)z/",
      phonetics: [
        { text: "/jɔː(ɹ)z/", audio: "" },
        {
          text: "/jɔɹz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/yours-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1229427",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "pronoun",
          definitions: [
            {
              definition:
                "That which belongs to you (singular); the possessive second-person singular pronoun used without a following noun.",
              synonyms: [],
              antonyms: [],
              example:
                "If this edit is mine, the other must be yours.  Their encyclopedia is good, but yours is even better.  It’s all yours.",
            },
            {
              definition:
                "That which belongs to you (plural); the possessive second-person plural pronoun used without a following noun.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Written at the end of a letter, before the signature.",
              synonyms: [],
              antonyms: [],
              example:
                "Yours sincerely,  Yours faithfully,  Yours,  Sincerely yours,",
            },
          ],
          synonyms: ["yourn"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/yours"],
    },
  ],
  sizes: [
    {
      word: "sizes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(obsolete outside dialectal) An assize.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A regulation determining the amount of money paid in fees, taxes etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A fixed standard for the magnitude, quality, quantity etc. of goods, especially food and drink.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The dimensions or magnitude of a thing; how big something is.",
              synonyms: [],
              antonyms: [],
              example:
                "The size of the building seemed to have increased since I was last there.",
            },
            {
              definition: "A regulation, piece of ordinance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A specific set of dimensions for a manufactured article, especially clothing.",
              synonyms: [],
              antonyms: [],
              example: "I don't think we have the red one in your size.",
            },
            {
              definition: "A number of edges in a graph.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Degree of rank, ability, character, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An instrument consisting of a number of perforated gauges fastened together at one end by a rivet, used for measuring the size of pearls.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To adjust the size of; to make a certain size.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To classify or arrange by size.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To approximate the dimensions, estimate the size of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To take a greater size; to increase in size.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Cambridge University) To order food or drink from the buttery; hence, to enter a score, as upon the buttery book.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To swell; to increase the bulk of.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A thin, weak glue used as primer for paper or canvas intended to be painted upon.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Wallpaper paste.", synonyms: [], antonyms: [] },
            {
              definition: "The thickened crust on coagulated blood.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any viscous substance, such as gilder's varnish.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To apply glue or other primer to a surface which is to be painted.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/size",
        "https://en.wiktionary.org/wiki/sizes",
      ],
    },
  ],
  noted: [
    {
      word: "noted",
      phonetic: "/ˈnəʊtɪd/",
      phonetics: [
        { text: "/ˈnəʊtɪd/", audio: "" },
        {
          text: "/ˈnoʊtɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/noted-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749908",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To notice with care; to observe; to remark; to heed.",
              synonyms: [],
              antonyms: [],
              example:
                "If you look to the left, you can note the old cathedral.",
            },
            {
              definition: "To record in writing; to make a memorandum of.",
              synonyms: [],
              antonyms: [],
              example: "We noted his speech.",
            },
            {
              definition: "To denote; to designate.",
              synonyms: [],
              antonyms: [],
              example:
                "The modular multiplicative inverse of x may be noted x-1.",
            },
            { definition: "To annotate.", synonyms: [], antonyms: [] },
            {
              definition: "To set down in musical characters.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To record on the back of (a bill, draft, etc.) a refusal of acceptance, as the ground of a protest, which is done officially by a notary.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Well known because of one's reputation; famous, celebrated.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/note",
        "https://en.wiktionary.org/wiki/noted",
      ],
    },
  ],
  width: [
    {
      word: "width",
      phonetic: "/ˈwɪdθ/",
      phonetics: [
        {
          text: "/ˈwɪdθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/width-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899672",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈwɪdθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/width-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=519871",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The state of being wide.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The measurement of the extent of something from side to side.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of material measured along its smaller dimension, especially fabric.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The horizontal distance between a batsman and the ball as it passes him.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The use of all the width of the pitch, from one side to the other.",
              synonyms: [],
              antonyms: [],
              example: "Manchester United like to play with width.",
            },
          ],
          synonyms: ["breadth"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/width"],
    },
  ],
  burst: [
    {
      word: "burst",
      phonetic: "/bɜːst/",
      phonetics: [
        { text: "/bɜːst/", audio: "" },
        {
          text: "/bɝst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/burst-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=840579",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An act or instance of bursting.",
              synonyms: [],
              antonyms: [],
              example: "The bursts of the bombs could be heard miles away.",
            },
            {
              definition:
                "A sudden, often intense, expression, manifestation or display.",
              synonyms: ["spurt"],
              antonyms: [],
            },
            {
              definition: "A series of shots fired from an automatic firearm.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A drinking spree.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["spurt"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To break from internal pressure.",
              synonyms: [],
              antonyms: [],
              example: "I blew the balloon up too much, and it burst.",
            },
            {
              definition: "To cause to break from internal pressure.",
              synonyms: [],
              antonyms: [],
              example: "I burst the balloon when I blew it up too much.",
            },
            {
              definition: "To cause to break by any means.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To separate (printer paper) at perforation lines.",
              synonyms: [],
              antonyms: [],
              example:
                "I printed the report on form-feed paper, then burst the sheets.",
            },
            {
              definition: "To enter or exit hurriedly and unexpectedly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To erupt; to change state suddenly as if bursting.",
              synonyms: [],
              antonyms: [],
              example:
                "The flowers burst into bloom on the first day of spring.",
            },
            {
              definition: "To produce as an effect of bursting.",
              synonyms: [],
              antonyms: [],
              example: "to burst a hole through the wall",
            },
            {
              definition:
                "To interrupt suddenly in a violent or explosive manner; to shatter.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/burst"],
    },
  ],
  route: [
    {
      word: "route",
      phonetic: "/ɹʉːt/",
      phonetics: [
        { text: "/ɹʉːt/", audio: "" },
        { text: "/ɹut/", audio: "" },
        {
          text: "/ɹuːt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/route-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=568389",
        },
        {
          text: "/ɹaʊt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/route-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=568280",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A course or way which is traveled or passed.",
              synonyms: [],
              antonyms: [],
              example: "The route was used so much that it formed a rut.",
            },
            {
              definition:
                "A regular itinerary of stops, or the path followed between these stops, such as for delivery or passenger transportation.",
              synonyms: [],
              antonyms: [],
              example: "Here is a map of our delivery routes.",
            },
            {
              definition: "A road or path; often specifically a highway.",
              synonyms: [],
              antonyms: [],
              example: "Follow Route 49 out of town.",
            },
            {
              definition:
                "One of multiple methods or approaches to doing something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the major provinces of imperial China from the Later Jin to the Song, corresponding to the Tang and early Yuan circuits.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A specific entry in a router that tells the router how to transmit the data it receives.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["circuit", "lu", "province"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To direct or divert along a particular course.",
              synonyms: [],
              antonyms: [],
              example: "All incoming mail was routed through a single office.",
            },
            {
              definition:
                "To connect two local area networks, thereby forming an internet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To send (information) through a router.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/route"],
    },
    {
      word: "route",
      phonetic: "/ɹʉːt/",
      phonetics: [
        { text: "/ɹʉːt/", audio: "" },
        { text: "/ɹut/", audio: "" },
        {
          text: "/ɹuːt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/route-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=568389",
        },
        {
          text: "/ɹaʊt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/route-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=568280",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To grow roots; to enter the earth, as roots; to take root and begin to grow.",
              synonyms: [],
              antonyms: [],
              example: "The cuttings are starting to root.",
            },
            {
              definition:
                "To prepare, oversee, or otherwise cause the rooting of cuttings",
              synonyms: [],
              antonyms: [],
              example: "We rooted some cuttings last summer.",
            },
            {
              definition: "To be firmly fixed; to be established.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To get root or priviledged access on a computer system or mobile phone, often through bypassing some security mechanism.",
              synonyms: ["jailbreak"],
              antonyms: [],
              example:
                "I want to root my Android phone so I can remove the preinstalled crapware.",
            },
          ],
          synonyms: ["jailbreak"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To turn up or dig with the snout.",
              synonyms: [],
              antonyms: [],
              example: "A pig roots the earth for truffles.",
            },
            {
              definition:
                "(by extension) To seek favour or advancement by low arts or grovelling servility; to fawn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To rummage; to search as if by digging in soil.",
              synonyms: ["dig out", "root out", "rummage"],
              antonyms: [],
              example: "rooting about in a junk-filled drawer",
            },
            {
              definition: "To root out; to abolish.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To have sexual intercourse.",
              synonyms: ["bang", "drill", "screw", "shag"],
              antonyms: [],
            },
          ],
          synonyms: [
            "bang",
            "drill",
            "screw",
            "shag",
            "dig out",
            "root out",
            "rummage",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                '(with "for" or "on") To cheer (on); to show support (for) and hope for the success of. (See root for.)',
              synonyms: ["barrack", "cheer on"],
              antonyms: [],
              example: "I'm rooting for you, don't let me down!",
            },
          ],
          synonyms: ["barrack", "cheer on"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/root",
        "https://en.wiktionary.org/wiki/route",
      ],
    },
  ],
  lungs: [
    {
      word: "lungs",
      phonetic: "/lʌŋz/",
      phonetics: [
        {
          text: "/lʌŋz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lungs-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651149",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A biological organ of vertebrates that controls breathing and oxygenates the blood.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(plural) Capacity for exercise or exertion; breath.",
              synonyms: [],
              antonyms: [],
              example:
                "He hasn't the lungs to play long rallies like he use to.",
            },
            {
              definition:
                "That which supplies oxygen or fresh air, such as trees, parklands, forest, etc., to a place.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bellows", "lights"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lung",
        "https://en.wiktionary.org/wiki/lungs",
      ],
    },
  ],
  uncle: [
    {
      word: "uncle",
      phonetic: "/ˈʌŋ.kəl/",
      phonetics: [
        {
          text: "/ˈʌŋ.kəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/uncle-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=91894780",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈʌŋ.kəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/uncle-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1648325",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The brother or brother-in-law of one’s parent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The male cousin of one’s parent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A companion to one's (usually unmarried) mother.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A source of advice, encouragement, or help.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A pawnbroker.", synonyms: [], antonyms: [] },
            {
              definition:
                "An affectionate term for a man of an older generation than oneself, especially a friend of one's parents, by means of fictive kin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An older male African-American person.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["eam", "eme"],
          antonyms: ["boy", "aunty", "nephew", "niece", "aunt"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To address somebody by the term uncle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To act like, or as, an uncle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "A cry used to indicate surrender.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/uncle"],
    },
  ],
  bears: [
    {
      word: "bears",
      phonetic: "/bɛəz/",
      phonetics: [
        { text: "/bɛəz/", audio: "" },
        {
          text: "/bɛɹz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bears-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100335",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A large omnivorous mammal, related to the dog and raccoon, having shaggy hair, a very small tail, and flat feet; a member of family Ursidae.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A rough, unmannerly, uncouth person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An investor who sells commodities, securities or futures in anticipation of a fall in prices.",
              synonyms: [],
              antonyms: ["bull"],
            },
            {
              definition: "A state policeman (short for smokey bear).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A large, hairy man, especially one who is homosexual.",
              synonyms: [],
              antonyms: ["twink"],
            },
            {
              definition: "A portable punching machine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A block covered with coarse matting, used to scour the deck.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The fifteenth Lenormand card.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Something difficult or tiresome; a burden or chore.",
              synonyms: [],
              antonyms: [],
              example: "That window can be a bear to open.",
            },
          ],
          synonyms: [],
          antonyms: ["bull", "twink"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To endeavour to depress the price of, or prices in.",
              synonyms: [],
              antonyms: [],
              example: "to bear a railroad stock",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To carry or convey, literally or figuratively.",
              synonyms: [],
              antonyms: [],
              example:
                "Judging from the look on his face, he wasn't bearing good news.",
            },
            {
              definition: "To support, sustain, or endure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To support, keep up, or maintain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To press or impinge upon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To produce, yield, give birth to.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(originally nautical) To be, or head, in a specific direction or azimuth (from somewhere).",
              synonyms: [],
              antonyms: [],
              example:
                "By my readings, we're bearing due south, so we should turn about ten degrees east.",
            },
            { definition: "To gain or win.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["brook", "endure"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bear",
        "https://en.wiktionary.org/wiki/bears",
      ],
    },
  ],
  royal: [
    {
      word: "royal",
      phonetic: "/ˈɹɔɪəl/",
      phonetics: [
        {
          text: "/ˈɹɔɪəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/royal-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1446802",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A royal person; a member of a royal family.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A standard size of printing paper, measuring 25 by 20 inches.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A standard size of writing paper, measuring 24 by 19 inches.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'The Australian decimal currency intended to replace the pound in 1966; was changed to "dollar" before it was actually circulated.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any of various lycaenid butterflies.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The fourth tine of an antler's beam.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A stag with twelve points (six on each antler).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In large sailing ships, square sail over the topgallant sail.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An old English gold coin, the rial.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A small mortar.", synonyms: [], antonyms: [] },
            {
              definition: "In auction bridge, a royal spade.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A tuft of beard on the lower lip.",
              synonyms: ["imperial"],
              antonyms: [],
            },
            {
              definition: "(campanology) Bell changes rung on ten bells.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["imperial"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of or relating to a monarch or their family.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Having the air or demeanour of a monarch; illustrious; magnanimous; of more than common size or excellence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In large sailing ships, of a mast right above the topgallant mast and its sails.",
              synonyms: [],
              antonyms: [],
              example: "royal mast;  royal sail",
            },
            {
              definition:
                "Free-for-all, especially involving multiple combatants.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Used as an intensifier.",
              synonyms: [],
              antonyms: [],
              example: "a royal pain in the neck",
            },
          ],
          synonyms: [
            "majestic",
            "regal",
            "stately",
            "major",
            "kingly",
            "monarchical",
            "princely",
            "queenly",
            "regal",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/royal"],
    },
  ],
  kings: [
    {
      word: "kings",
      phonetic: "/kɪŋz/",
      phonetics: [
        {
          text: "/kɪŋz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/kings-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1648265",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A male monarch; a man who heads a monarchy. If it's an absolute monarchy, then he is the supreme ruler of his nation.",
              synonyms: [],
              antonyms: [],
              example: "Henry VIII was the king of England from 1509 to 1547.",
            },
            {
              definition: "A powerful or majorly influential person.",
              synonyms: [],
              antonyms: [],
              example:
                'Howard Stern styled himself as the "king of all media".',
            },
            {
              definition: "Something that has a preeminent position.",
              synonyms: [],
              antonyms: [],
              example: "In times of financial panic, cash is king.",
            },
            {
              definition: "A component of certain games.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A king skin.",
              synonyms: [],
              antonyms: [],
              example: "Oi mate, have you got kings?",
            },
            {
              definition: "A male dragonfly; a drake.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A king-sized bed.", synonyms: [], antonyms: [] },
            { definition: "A male person.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To crown king, to make (a person) king.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To rule over as king.", synonyms: [], antonyms: [] },
            {
              definition: "To perform the duties of a king.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To assume or pretend preeminence (over); to lord it over.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To promote a piece of draughts/checkers that has traversed the board to the opposite side, that piece subsequently being permitted to move backwards as well as forwards.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To dress and perform as a drag king.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A drinking game that uses playing cards.",
              synonyms: ["Ring of Fire"],
              antonyms: [],
            },
          ],
          synonyms: ["Ring of Fire"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A sounding stone, a Chinese musical instrument.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/king",
        "https://en.wiktionary.org/wiki/kings",
        "https://en.wiktionary.org/wiki/qing",
      ],
    },
  ],
  forty: [
    {
      word: "forty",
      phonetic: "/ˈfɔɹti/",
      phonetics: [
        {
          text: "/ˈfɔɹti/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/forty-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=867528",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A bottle of beer containing forty fluid ounces.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "numeral",
          definitions: [
            {
              definition:
                "The cardinal number occurring after thirty-nine and before forty-one.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["Arabic numerals", "Roman numerals", "XL"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/forty"],
    },
  ],
  trial: [
    {
      word: "trial",
      phonetic: "/ˈtɹaɪəl/",
      phonetics: [
        {
          text: "/ˈtɹaɪəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/trial-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1676885",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An opportunity to test something out; a test.",
              synonyms: [],
              antonyms: [],
              example:
                "They will perform the trials for the new equipment next week.",
            },
            {
              definition:
                "Appearance at judicial court in order to be examined.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A difficult or annoying experience.",
              synonyms: [],
              antonyms: [],
              example: "That boy was a trial to his parents.",
            },
            {
              definition: "A tryout to pick members of a team.",
              synonyms: [],
              antonyms: [],
              example: "soccer trials",
            },
            {
              definition: "A piece of ware used to test the heat of a kiln.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An internal examination set by Eton College.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To carry out a series of tests on (a new product, procedure etc.) before marketing or implementing it.",
              synonyms: [],
              antonyms: [],
              example:
                "The warning system was extensively trialed before being fitted to all our vehicles.",
            },
            {
              definition: "To try out (a new player) in a sports team.",
              synonyms: [],
              antonyms: [],
              example:
                "The team trialled a new young goalkeeper in Saturday's match, with mixed results.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Pertaining to a trial or test.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Attempted on a provisional or experimental basis.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/trial"],
    },
    {
      word: "trial",
      phonetic: "/ˈtɹaɪəl/",
      phonetics: [
        {
          text: "/ˈtɹaɪəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/trial-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1676885",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Characterized by having three (usually equivalent) components.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Triple.", synonyms: [], antonyms: [] },
            {
              definition:
                "(grammar) Pertaining to a language form referring to three of something, like people; contrast singular, dual and plural. (See Ambai language for an example.)",
              synonyms: [],
              antonyms: [],
              example: "No language has a trial number unless it has a dual.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/trial"],
    },
  ],
  cards: [
    {
      word: "cards",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cards-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100925",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A playing card.", synonyms: [], antonyms: [] },
            {
              definition:
                "(in the plural) Any game using playing cards; a card game.",
              synonyms: [],
              antonyms: [],
              example: "He played cards with his friends.",
            },
            {
              definition:
                "A resource or an argument, used to achieve a purpose.",
              synonyms: [],
              antonyms: [],
              example: "He accused them of playing the race card.",
            },
            {
              definition:
                "Any flat, normally rectangular piece of stiff paper, plastic etc.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A map or chart.", synonyms: [], antonyms: [] },
            {
              definition:
                "An amusing or entertaining person, often slightly eccentrically so.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A list of scheduled events or of performers or contestants.",
              synonyms: [],
              antonyms: [],
              example: "What’s on the card for tonight?",
            },
            {
              definition:
                "A tabular presentation of the key statistics of an innings or match: batsmen’s scores and how they were dismissed, extras, total score and bowling figures.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A removable electronic device that may be inserted into a powered electronic device to provide additional capability.",
              synonyms: [],
              antonyms: [],
              example:
                "He needed to replace the card his computer used to connect to the internet.",
            },
            {
              definition: "A greeting card.",
              synonyms: [],
              antonyms: [],
              example:
                "She gave her neighbors a card congratulating them on their new baby.",
            },
            {
              definition: "A business card.",
              synonyms: [],
              antonyms: [],
              example:
                "The realtor gave me her card so I could call if I had any questions about buying a house.",
            },
            {
              definition:
                "Title card / Intertitle: A piece of filmed, printed text edited into the midst of the photographed action at various points, generally to convey character dialogue or descriptive narrative material related to the plot.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A test card.", synonyms: [], antonyms: [] },
            {
              definition:
                "A published note, containing a brief statement, explanation, request, expression of thanks, etc.",
              synonyms: [],
              antonyms: [],
              example: "to put a card in the newspapers",
            },
            { definition: "A printed programme.", synonyms: [], antonyms: [] },
            {
              definition: "(by extension) An attraction or inducement.",
              synonyms: [],
              antonyms: [],
              example: "This will be a good card for the last day of the fair.",
            },
            {
              definition:
                "A paper on which the points of the compass are marked; the dial or face of the mariner's compass.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A perforated pasteboard or sheet-metal plate for warp threads, making part of the Jacquard apparatus of a loom.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An indicator card.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To check IDs, especially against a minimum age requirement.",
              synonyms: [],
              antonyms: [],
              example:
                "I heard you don't get carded at the other liquor store.",
            },
            { definition: "To play cards.", synonyms: [], antonyms: [] },
            {
              definition:
                "To make (a stated score), as recorded on a scoring card.",
              synonyms: [],
              antonyms: [],
              example:
                "McIlroy carded a stellar nine-under-par 61 in the final round.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Material with embedded short wire bristles.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A comb- or brush-like device or tool to raise the nap on a fabric.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hand-held tool formed similarly to a hairbrush but with bristles of wire or other rigid material. It is used principally with raw cotton, wool, hair, or other natural fibers to prepare these materials for spinning into yarn or thread on a spinning wheel, with a whorl or other hand-held spindle. The card serves to untangle, clean, remove debris from, and lay the fibers straight.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A machine for disentangling the fibres of wool prior to spinning.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A roll or sliver of fibre (as of wool) delivered from a carding machine.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To use a carding device to disentangle the fibres of wool prior to spinning.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To scrape or tear someone’s flesh using a metal comb, as a form of torture.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To comb with a card; to cleanse or disentangle by carding.",
              synonyms: [],
              antonyms: [],
              example: "to card a horse",
            },
            {
              definition: "To clean or clear, as if by using a card.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To mix or mingle, as with an inferior or weaker article.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One of the officials appointed by the pope in the Roman Catholic Church, ranking only below the pope and the patriarchs, constituting the special college which elects the pope. (See Wikipedia article on Catholic cardinals.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of a genus of songbirds of the finch family, Cardinalis.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various related passerine birds of the family Cardinalidae (See Wikipedia article on cardinals) and other similar birds that were once considered to be related.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(color) A deep red color, somewhat less vivid than scarlet, the traditional colour of a Catholic cardinal's cassock. (same as cardinal red)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Short for cardinal number, a number indicating quantity, or the size of a set (e.g., zero, one, two, three). (See Wikipedia article on Cardinal number.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(grammar) Short for cardinal numeral, a word used to represent a cardinal number.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Short for cardinal flower (Lobelia cardinalis), a flowering plant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Short for cardinal tetra (Paracheirodon axelrodi), a freshwater fish.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(bow) A woman's short cloak with a hood, originally made of scarlet cloth.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Mulled red wine.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Card games",
              synonyms: [],
              antonyms: [],
              example: "He's a fan of cards.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/card",
        "https://en.wiktionary.org/wiki/cardinal",
        "https://en.wiktionary.org/wiki/cards",
      ],
    },
  ],
  brass: [
    {
      word: "brass",
      phonetic: "/bɹɑːs/",
      phonetics: [
        { text: "/bɹɑːs/", audio: "" },
        {
          text: "/bɹæs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/brass-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=840322",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A metallic alloy of copper and zinc used in many industrial and plumbing applications.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A class of wind instruments, usually made of metal (such as brass), that use vibrations of the player's lips to produce sound; the section of an orchestra that features such instruments",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Spent shell casings (usually made of brass); the part of the cartridge left over after bullets have been fired.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The colour of brass.", synonyms: [], antonyms: [] },
            {
              definition:
                "(used as a singular or plural noun, metonym) High-ranking officers.",
              synonyms: [],
              antonyms: [],
              example: "The brass are not going to like this.",
            },
            {
              definition: "A brave or foolhardy attitude; impudence.",
              synonyms: [],
              antonyms: [],
              example: "You've got a lot of brass telling me to do that!",
            },
            { definition: "Money.", synonyms: [], antonyms: [] },
            { definition: "Inferior composition.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To coat with brass.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Made of brass, of or pertaining to brass.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of the colour of brass.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Impertinent, bold: brazen.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Bad, annoying; as wordplay applied especially to brass instruments.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of inferior composition.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/brass"],
    },
    {
      word: "brass",
      phonetic: "/bɹɑːs/",
      phonetics: [
        { text: "/bɹɑːs/", audio: "" },
        {
          text: "/bɹæs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/brass-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=840322",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Brass in pocket; money.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A brass nail; a prostitute.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Brass monkey; cold.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/brass"],
    },
  ],
  opera: [
    {
      word: "opera",
      phonetic: "/ˈɒp.əɹ.ə/",
      phonetics: [
        { text: "/ˈɒp.əɹ.ə/", audio: "" },
        {
          text: "/ˈɑ.pəɹ.ə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/opera-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2454174",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A theatrical work, combining drama, music, song and sometimes dance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The score for such a work.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A building designed for the performance of such works; an opera house.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A company dedicated to performing such works.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) Any showy, melodramatic or unrealistic production resembling an opera.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/opera"],
    },
    {
      word: "opera",
      phonetic: "/ˈɒp.əɹ.ə/",
      phonetics: [
        { text: "/ˈɒp.əɹ.ə/", audio: "" },
        {
          text: "/ˈɑ.pəɹ.ə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/opera-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2454174",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A work of music or set of works with a specified rank in an ordering of a composer's complete published works.",
              synonyms: [],
              antonyms: [],
              example:
                "Beethoven's opus eighteen quartets are considered by many to be the beginning of the Romantic era.",
            },
            {
              definition: "A work, especially of art.",
              synonyms: [],
              antonyms: [],
              example:
                "The painter's last opus was a dedication to all things living, in a surprising contrast to all of his prior work.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/opera",
        "https://en.wiktionary.org/wiki/opus",
      ],
    },
  ],
  chose: [
    {
      word: "chose",
      phonetic: "/tʃəʊz/",
      phonetics: [
        { text: "/tʃəʊz/", audio: "" },
        {
          text: "/tʃoʊz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chose-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749745",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To pick; to make the choice of; to select.",
              synonyms: [],
              antonyms: [],
              example: "I chose a nice ripe apple from the fruit bowl.",
            },
            {
              definition: "To elect.",
              synonyms: [],
              antonyms: [],
              example: "He was chosen as president in 1990.",
            },
            {
              definition: "To decide to act in a certain way.",
              synonyms: [],
              antonyms: [],
              example: "I chose to walk to work today.",
            },
            {
              definition: "To wish; to desire; to prefer.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/choose",
        "https://en.wiktionary.org/wiki/chose",
      ],
    },
    {
      word: "chose",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A thing; personal property.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chose"],
    },
  ],
  owner: [
    {
      word: "owner",
      phonetic: "/ˈəʊnə/",
      phonetics: [
        { text: "/ˈəʊnə/", audio: "" },
        {
          text: "/ˈoʊnɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/owner-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=667199",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One who owns something.",
              synonyms: [],
              antonyms: [],
              example:
                "The police recovered the stolen car and returned it to its owner.",
            },
            {
              definition: "The captain of a ship.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["possessor", "proprietor"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/owner"],
    },
  ],
  vapor: [
    {
      word: "vapor",
      phonetic: "/ˈveɪpə/",
      phonetics: [
        { text: "/ˈveɪpə/", audio: "" },
        {
          text: "/ˈveɪpɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/vapor-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762798",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Cloudy diffused matter such as mist, steam or fumes suspended in the air.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The gaseous state of a substance that is normally a solid or liquid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something insubstantial, fleeting, or transitory; unreal fancy; vain imagination; idle talk; boasting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any medicinal agent designed for administration in the form of inhaled vapour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in the plural) Hypochondria; melancholy; the blues; hysteria, or other nervous disorder.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Wind; flatulence.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To become vapor; to be emitted or circulated as vapor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To turn into vapor.",
              synonyms: [],
              antonyms: [],
              example: "to vapor away a heated fluid",
            },
            {
              definition: "To emit vapor or fumes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To use insubstantial language; to boast or bluster.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To give (someone) the vapors; to depress, to bore.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/vapor"],
    },
  ],
  beats: [
    {
      word: "beats",
      phonetic: "/biːts/",
      phonetics: [{ text: "/biːts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A stroke; a blow.", synonyms: [], antonyms: [] },
            {
              definition: "A pulsation or throb.",
              synonyms: [],
              antonyms: [],
              example: "a beat of the heart; the beat of the pulse",
            },
            {
              definition:
                "A pulse on the beat level, the metric level at which pulses are heard as the basic unit. Thus a beat is the basic time unit of a piece.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A rhythm.", synonyms: [], antonyms: [] },
            {
              definition:
                "The interference between two tones of almost equal frequency",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(authorship) A short pause in a play, screenplay, or teleplay, for dramatic or comedic effect; a plot point or story development.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The route patrolled by a police officer or a guard.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) An area of a person's responsibility, especially",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An act of reporting news or scientific results before a rival; a scoop.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "That which beats, or surpasses, another or others.",
              synonyms: [],
              antonyms: [],
              example: "the beat of him",
            },
            { definition: "A precinct.", synonyms: [], antonyms: [] },
            {
              definition: "A place of habitual or frequent resort.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A low cheat or swindler.",
              synonyms: [],
              antonyms: [],
              example: "a dead beat",
            },
            {
              definition:
                "The instrumental portion of a piece of hip-hop music.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The act of scouring, or ranging over, a tract of land to rouse or drive out game; also, those so engaged, collectively.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A smart tap on the adversary's blade.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To hit; strike",
              synonyms: ["hammer", "knock", "pound", "strike", "whack"],
              antonyms: [],
              example:
                "As soon as she heard that her father had died, she went into a rage and beat the wall with her fists until her knuckles bled.",
            },
            {
              definition:
                "To strike or pound repeatedly, usually in some sort of rhythm.",
              synonyms: [],
              antonyms: [],
              example: "He danced hypnotically while she beat the atabaque.",
            },
            {
              definition:
                "To strike repeatedly; to inflict repeated blows; to knock vigorously or loudly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To move with pulsation or throbbing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To win against; to defeat or overcome; to do better than, outdo, or excel (someone) in a particular, competitive event.",
              synonyms: [],
              antonyms: [],
              example:
                "I just can't seem to beat the last level of this video game.",
            },
            {
              definition:
                "To sail to windward using a series of alternate tacks across the wind.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To strike (water, foliage etc.) in order to drive out game; to travel through (a forest etc.) for hunting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To mix food in a rapid fashion. Compare whip.",
              synonyms: [],
              antonyms: [],
              example: "Beat the eggs and whip the cream.",
            },
            {
              definition:
                "(In haggling for a price) of a buyer, to persuade the seller to reduce a price",
              synonyms: ["negotiate"],
              antonyms: [],
              example:
                "He wanted $50 for it, but I managed to beat him down to $35.",
            },
            {
              definition: "To indicate by beating or drumming.",
              synonyms: [],
              antonyms: [],
              example: "to beat a retreat; to beat to quarters",
            },
            { definition: "To tread, as a path.", synonyms: [], antonyms: [] },
            {
              definition: "To exercise severely; to perplex; to trouble.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be in agitation or doubt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make a sound when struck.",
              synonyms: [],
              antonyms: [],
              example: "The drums beat.",
            },
            {
              definition: "To make a succession of strokes on a drum.",
              synonyms: [],
              antonyms: [],
              example: "The drummers beat to call soldiers to their quarters.",
            },
            {
              definition:
                "To sound with more or less rapid alternations of greater and less intensity, so as to produce a pulsating effect; said of instruments, tones, or vibrations, not perfectly in unison.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To arrive at a place before someone.",
              synonyms: [],
              antonyms: [],
              example: "He beat me there.",
            },
            {
              definition: "To have sexual intercourse.",
              synonyms: ["do it", "get it on", "have sex", "shag"],
              antonyms: [],
              example: "Bruv, she came in just as we started to beat.",
            },
            {
              definition: "To rob.",
              synonyms: [],
              antonyms: [],
              example: "He beat me out of 12 bucks last night.",
            },
          ],
          synonyms: [
            "do it",
            "get it on",
            "have sex",
            "shag",
            "hammer",
            "knock",
            "pound",
            "strike",
            "whack",
            "negotiate",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A beatnik.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/beat",
        "https://en.wiktionary.org/wiki/beats",
      ],
    },
  ],
  mouse: [
    {
      word: "mouse",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Any small rodent of the genus Mus.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A member of the many small rodent and marsupial species resembling such a rodent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A quiet or shy person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(plural mice or, rarely, mouses) An input device that is moved over a pad or other flat surface to produce a corresponding movement of a pointer on a graphical display.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Hematoma.", synonyms: [], antonyms: [] },
            {
              definition:
                "A turn or lashing of spun yarn or small stuff, or a metallic clasp or fastening, uniting the point and shank of a hook to prevent its unhooking or straightening out.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A familiar term of endearment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A match used in firing guns or blasting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small model of (a fragment of) Zermelo-Fraenkel set theory with desirable properties (depending on the context).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A small cushion for a woman's hair.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To move cautiously or furtively, in the manner of a mouse (the rodent) (frequently used in the phrasal verb to mouse around).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To hunt or catch mice (the rodents), usually of cats.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To close the mouth of a hook by a careful binding of marline or wire.",
              synonyms: [],
              antonyms: [],
              example:
                "Captain Higgins moused the hook with a bit of marline to prevent the block beckets from falling out under slack.",
            },
            {
              definition: "To navigate by means of a computer mouse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To tear, as a cat devours a mouse.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mouse"],
    },
  ],
  tough: [
    {
      word: "tough",
      phonetic: "/tʌf/",
      phonetics: [
        {
          text: "/tʌf/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tough-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=589398",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person who obtains things by force; a thug or bully.",
              synonyms: [],
              antonyms: [],
              example:
                "They were doing fine until they encountered a bunch of toughs from the opposition.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To endure.", synonyms: [], antonyms: [] },
            { definition: "To toughen.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Strong and resilient; sturdy.",
              synonyms: [],
              antonyms: [],
              example:
                "The tent, made of tough canvas, held up to many abuses.",
            },
            {
              definition: "(of food) Difficult to cut or chew.",
              synonyms: [],
              antonyms: [],
              example:
                "To soften a tough cut of meat, the recipe suggested simmering it for hours.",
            },
            {
              definition: "Rugged or physically hardy.",
              synonyms: [],
              antonyms: [],
              example: "Only a tough species will survive in the desert.",
            },
            {
              definition: "Stubborn.",
              synonyms: [],
              antonyms: [],
              example: "He had a reputation as a tough negotiator.",
            },
            {
              definition: "(of weather etc) Harsh or severe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Rowdy or rough.",
              synonyms: [],
              antonyms: [],
              example:
                "A bunch of the tough boys from the wrong side of the tracks threatened him.",
            },
            {
              definition: "(of questions, etc.) Difficult or demanding.",
              synonyms: [],
              antonyms: [],
              example: "This is a tough crowd.",
            },
            {
              definition: "Undergoing plastic deformation before breaking.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "Used to indicate lack of sympathy",
              synonyms: [],
              antonyms: [],
              example: "If you don't like it, tough!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tough"],
    },
  ],
  wires: [
    {
      word: "wires",
      phonetic: "/waɪəz/",
      phonetics: [
        { text: "/waɪəz/", audio: "" },
        {
          text: "/waɪɹz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wires-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=278935",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Metal formed into a thin, even thread, now usually by being drawn through a hole in a steel die.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of such material; a thread or slender rod of metal, a cable.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A metal conductor that carries electricity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A fence made of usually barbed wire.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A finish line of a racetrack.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A telecommunication wire or cable",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) An electric telegraph; a telegram.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hidden listening device on the person of an undercover operative for the purposes of obtaining incriminating spoken evidence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A deadline or critical endpoint.",
              synonyms: [],
              antonyms: [],
              example: "This election is going to go right to the wire",
            },
            {
              definition:
                "A wire strung with beads and hung horizontally above or near the table which is used to keep score.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(usually in the plural) Any of the system of wires used to operate the puppets in a puppet show; hence, the network of hidden influences controlling the action of a person or organization; strings.",
              synonyms: [],
              antonyms: [],
              example: "to pull the wires for office",
            },
            {
              definition: "(thieves' slang) A pickpocket who targets women.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A covert signal sent between people cheating in a card game.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A knitting needle.", synonyms: [], antonyms: [] },
            {
              definition: "The slender shaft of the plumage of certain birds.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "barbed wire",
            "telegram",
            "telegraph",
            "conducting wire",
            "score string",
            "cable",
            "steel wire",
            "thread",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To fasten with wire, especially with reference to wine bottles, corks, or fencing.",
              synonyms: [],
              antonyms: [],
              example: "We need to wire that hole in the fence.",
            },
            {
              definition: "To string on a wire.",
              synonyms: [],
              antonyms: [],
              example: "wire beads",
            },
            {
              definition: "To equip with wires for use with electricity.",
              synonyms: [],
              antonyms: [],
              example: "Do you know how to wire a plug?",
            },
            {
              definition:
                "To add something into an electrical system by means of wiring; to incorporate or include something.",
              synonyms: [],
              antonyms: [],
              example: "I'll just wire your camera to the computer screen.",
            },
            {
              definition:
                "(usually passive) To fix or predetermine (someone's personality or behaviour) in a particular way.",
              synonyms: [],
              antonyms: [],
              example:
                "There's no use trying to get Sarah to be less excitable. That's just the way she's wired.",
            },
            {
              definition:
                "To send a message or monetary funds to another person through a telecommunications system, formerly predominantly by telegraph.",
              synonyms: [],
              antonyms: [],
              example:
                "The detective wired ahead, hoping that the fugitive would be caught at the railway station.",
            },
            {
              definition:
                "To make someone tense or psyched up. See also adjective wired.",
              synonyms: [],
              antonyms: [],
              example: "Coffee late at night wires me good and proper.",
            },
            {
              definition: "To install eavesdropping equipment.",
              synonyms: [],
              antonyms: [],
              example: "We wired the suspect's house.",
            },
            {
              definition: "To snare by means of a wire or wires.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To place (a ball) so that the wire of a wicket prevents a successful shot.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["electrify", "cable", "telegraph"],
          antonyms: ["unwire"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wire",
        "https://en.wiktionary.org/wiki/wires",
      ],
    },
  ],
  meter: [
    {
      word: "meter",
      phonetic: "/ˈmiːtə/",
      phonetics: [
        { text: "/ˈmiːtə/", audio: "" },
        {
          text: "/ˈmitəɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/meter-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762993",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(always meter) A device that measures things.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(always meter) A parking meter or similar device for collecting payment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(always meter) One who metes or measures.",
              synonyms: [],
              antonyms: [],
              example: "a labouring coal-meter",
            },
            {
              definition:
                "(elsewhere metre) The base unit of length in the International System of Units (SI), conceived of as 1/10000000 of the distance from the North Pole to the Equator, and now defined as the distance light will travel in a vacuum in 1/299792458 second.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(elsewhere metre) An increment of music; the overall rhythm; particularly, the number of beats in a measure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(elsewhere metre) The rhythm pattern in a poem.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(elsewhere metre) A line above or below a hanging net, to which the net is attached in order to strengthen it.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A poem.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To measure with a metering device.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To imprint a postage mark with a postage meter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To regulate the flow of or to deliver in regulated amounts (usually of fluids but sometimes of other things such as anticipation or breath).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/meter"],
    },
  ],
  tower: [
    {
      word: "tower",
      phonetic: "/ˈtaʊ.ə(ɹ)/",
      phonetics: [
        { text: "/ˈtaʊ.ə(ɹ)/", audio: "" },
        {
          text: "/ˈtaʊɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tower-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2079769",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A very tall iron-framed structure, usually painted red and white, on which microwave, radio, satellite, or other communication antennas are installed; mast.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A similarly framed structure with a platform or enclosed area on top, used as a lookout for spotting fires, plane crashes, fugitives, etc.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A water tower.", synonyms: [], antonyms: [] },
            { definition: "A control tower.", synonyms: [], antonyms: [] },
            {
              definition: "Any very tall building or structure; skyscraper.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any item, such as a computer case, that is usually higher than it is wide.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An interlocking tower.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A strong refuge; a defence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A tall fashionable headdress worn in the time of King William III and Queen Anne.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "High flight; elevation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The sixteenth trump or Major Arcana card in many Tarot decks, usually deemed an ill omen.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The nineteenth Lenormand card, representing structure, bureaucracy, stability and loneliness.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["donjon"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tower"],
    },
    {
      word: "tower",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To be very tall.",
              synonyms: [],
              antonyms: [],
              example: "The office block towered into the sky.",
            },
            {
              definition: "To be high or lofty; to soar.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To soar into.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tower"],
    },
    {
      word: "tower",
      phonetic: "/ˈtəʊ.ə(ɹ)/",
      phonetics: [{ text: "/ˈtəʊ.ə(ɹ)/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "One who tows.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tower"],
    },
  ],
  finds: [
    {
      word: "finds",
      phonetic: "/faɪndz/",
      phonetics: [
        {
          text: "/faɪndz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/finds-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749844",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Anything that is found (usually valuable), as objects on an archeological site or a person with talent.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The act of finding.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["catch", "discovery"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To encounter or discover by accident; to happen upon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To encounter or discover something being searched for; to locate.",
              synonyms: [],
              antonyms: [],
              example: "I found my car keys. They were under the couch.",
            },
            {
              definition:
                "(ditransitive) To discover by study or experiment direct to an object or end.",
              synonyms: [],
              antonyms: [],
              example: "Water is found to be a compound substance.",
            },
            {
              definition: "To gain, as the object of desire or effort.",
              synonyms: [],
              antonyms: [],
              example: "to find leisure; to find means",
            },
            {
              definition: "To attain to; to arrive at; to acquire.",
              synonyms: [],
              antonyms: [],
              example: "Looks like he found a new vehicle for himself!",
            },
            {
              definition: "To point out.",
              synonyms: [],
              antonyms: [],
              example: "He kept finding faults with my work.",
            },
            {
              definition:
                "(ditransitive) To decide that, to discover that, to form the opinion that.",
              synonyms: [],
              antonyms: [],
              example: "I find your argument unsatisfactory.",
            },
            {
              definition:
                "To arrive at, as a conclusion; to determine as true; to establish.",
              synonyms: [],
              antonyms: [],
              example:
                "to find a verdict; to find a true bill (of indictment) against an accused person",
            },
            {
              definition: "To supply; to furnish.",
              synonyms: [],
              antonyms: [],
              example: "to find food for workmen",
            },
            {
              definition: "To provide for",
              synonyms: [],
              antonyms: [],
              example: "He finds his nephew in money.",
            },
            {
              definition: "To determine or judge.",
              synonyms: [],
              antonyms: [],
              example: "The jury finds for the defendant.",
            },
            { definition: "To discover game.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: ["lose"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/find",
        "https://en.wiktionary.org/wiki/finds",
      ],
    },
  ],
  inner: [
    {
      word: "inner",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/inner-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899750",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈɪnə/", audio: "" },
        {
          text: "/ˈɪnɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/inner-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749874",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "An inner part.", synonyms: [], antonyms: [] },
            {
              definition: "A duvet, excluding the cover.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A forward who plays in or near the center of the field.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A thin glove worn inside batting gloves or wicket-keeping gloves.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One who supports remaining in the European Union.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The 2nd circle on a target, between the bull (or bull's eye) and magpie.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["outer"],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Being or occurring (farther) inside, situated farther in, located (situated) or happening on the inside of something, situated within or farther within contained within something.",
              synonyms: [],
              antonyms: [],
              example:
                "inner door;  inner room;  inner sanctum;  inner surface",
            },
            {
              definition:
                "Close to the centre, located near or closer to center.",
              synonyms: [],
              antonyms: [],
              example: "the inner suburbs",
            },
            {
              definition: "Inside or closer to the inside of the body.",
              synonyms: [],
              antonyms: [],
              example: "inner ear",
            },
            {
              definition:
                "Of mind or spirit, relating to the mind or spirit, to spiritual or mental processes, mental, spiritual, relating to somebody's private feelings or happening in somebody's mind, existing as an often repressed part of one's psychological makeup.",
              synonyms: [],
              antonyms: [],
              example:
                "inner confidence;  inner strength;  inner life;  inner child;  inner artist;  inner peace;  inner light",
            },
            {
              definition:
                "Not obvious, private, not expressed, not apparent, hidden, less apparent, deeper, obscure; innermost or essential; needing to be examined closely or thought about in order to be seen or understood.",
              synonyms: [],
              antonyms: [],
              example: "inner meaning;  inner resources;  inner logic",
            },
            {
              definition:
                "Privileged, more or most privileged, more or most influential, intimate, exclusive, more important, more intimate, private, secret, confined to an exclusive group, exclusive to a center; especially a center of influence being near a center especially of influence.",
              synonyms: [],
              antonyms: [],
              example: "inner circle;  inner council",
            },
          ],
          synonyms: ["interior", "internal"],
          antonyms: ["outer"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/inner"],
    },
  ],
  stuck: [
    {
      word: "stuck",
      phonetic: "/ˈstʌk/",
      phonetics: [
        { text: "/ˈstʌk/", audio: "" },
        {
          text: "/ˈstʌk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stuck-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2101149",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To become or remain attached; to adhere.",
              synonyms: [],
              antonyms: [],
              example: "The tape will not stick if it melts.",
            },
            {
              definition: "To jam; to stop moving.",
              synonyms: [],
              antonyms: [],
              example: "The lever sticks if you push it too far up.",
            },
            {
              definition: "To tolerate, to endure, to stick with.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To persist.",
              synonyms: [],
              antonyms: [],
              example: "His old nickname stuck.",
            },
            {
              definition: "Of snow, to remain frozen on landing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To remain loyal; to remain firm.",
              synonyms: [],
              antonyms: [],
              example: "Just stick to your strategy, and you will win.",
            },
            {
              definition:
                "To hesitate, to be reluctant; to refuse (in negative phrases).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be puzzled (at something), have difficulty understanding.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause difficulties, scruples, or hesitation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To attach with glue or as if by gluing.",
              synonyms: [],
              antonyms: [],
              example: "Stick the label on the jar.",
            },
            {
              definition: "To place, set down (quickly or carelessly).",
              synonyms: [],
              antonyms: [],
              example: "Stick your bag over there and come with me.",
            },
            {
              definition:
                "To press (something with a sharp point) into something else.",
              synonyms: [],
              antonyms: [],
              example: "The balloon will pop when I stick this pin in it.",
            },
            {
              definition: "To fix on a pointed instrument; to impale.",
              synonyms: [],
              antonyms: [],
              example: "to stick an apple on a fork",
            },
            {
              definition:
                "To adorn or deck with things fastened on as by piercing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To perform (a landing) perfectly.",
              synonyms: [],
              antonyms: [],
              example: "Once again, the world champion sticks the dismount.",
            },
            {
              definition: "To propagate plants by cuttings.",
              synonyms: [],
              antonyms: [],
              example: "Stick cuttings from geraniums promptly.",
            },
            {
              definition:
                "(joinery) To run or plane (mouldings) in a machine, in contradistinction to working them by hand. Such mouldings are said to be stuck.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To bring to a halt; to stymie; to puzzle.",
              synonyms: [],
              antonyms: [],
              example: "to stick somebody with a hard problem",
            },
            {
              definition:
                "To impose upon; to compel to pay; sometimes, to cheat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To have sexual intercourse with.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To stand pat: to cease taking any more cards and finalize one's hand.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "abide",
            "carry on",
            "cleave",
            "cling",
            "agglutinate",
            "conglutinate",
            "glue",
            "gum",
            "paste",
            "puzzle",
            "stump",
            "thwart",
            "fix",
            "impale",
            "run through",
            "stake",
            "transfix",
            "have sex",
            "falter",
            "waver",
            "pop",
            "set down",
            "pierce",
            "prick",
            "puncture",
            "stand by",
            "stick by",
            "jam",
            "stall",
            "live with",
            "put up with",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Unable to move.",
              synonyms: [],
              antonyms: [],
              example: "Can you shift this gate? I think it’s stuck.",
            },
            {
              definition: "Unable to progress with a task.",
              synonyms: [],
              antonyms: [],
              example: "I’m totally stuck on this question in the test.",
            },
            {
              definition: "No longer functioning, frozen up, frozen.",
              synonyms: [],
              antonyms: [],
              example: "There are several ways to close a stuck program.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/stick",
        "https://en.wiktionary.org/wiki/stuck",
      ],
    },
    {
      word: "stuck",
      phonetic: "/ˈstʌk/",
      phonetics: [
        { text: "/ˈstʌk/", audio: "" },
        {
          text: "/ˈstʌk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stuck-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2101149",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A thrust.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stuck"],
    },
  ],
  arrow: [
    {
      word: "arrow",
      phonetic: "/ˈæɹ.əʊ/",
      phonetics: [
        { text: "/ˈæɹ.əʊ/", audio: "" },
        { text: "/ˈæɹ.oʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A projectile consisting of a shaft, a point and a tail with stabilizing fins that is shot from a bow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sign or symbol used to indicate a direction (e.g. →).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A directed edge.", synonyms: [], antonyms: [] },
            { definition: "A dart.", synonyms: [], antonyms: [] },
            {
              definition:
                "The -> symbol, which has specific meanings in various programming languages.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The inflorescence or tassel of a mature sugar cane plant.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["arc", "directed edge", "streal"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To move swiftly and directly (like an arrow)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To let fly swiftly and directly",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a sugar cane plant) To develop an inflorescence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To navigate using the arrow keys.",
              synonyms: [],
              antonyms: [],
              example:
                "Arrow left until you reach the start of the text you want to delete.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/arrow"],
    },
  ],
  poems: [
    {
      word: "poems",
      phonetic: "/ˈpəʊ.ɪmz/",
      phonetics: [
        { text: "/ˈpəʊ.ɪmz/", audio: "" },
        {
          text: "/poʊmz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/poems-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2079732",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A literary piece written in verse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of writing in the tradition of poetry, an instance of poetry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of poetic writing, that is with an intensity or depth of expression or inspiration greater than is usual in prose.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/poem",
        "https://en.wiktionary.org/wiki/poems",
      ],
    },
  ],
  label: [
    {
      word: "label",
      phonetic: "/ˈleɪbəl/",
      phonetics: [
        { text: "/ˈleɪbəl/", audio: "" },
        {
          text: "/ˈleɪbəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/label-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=5092139",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small ticket or sign giving information about something to which it is attached or intended to be attached.",
              synonyms: ["sign", "tag", "ticket"],
              antonyms: [],
              example:
                "Although the label priced this poster at three pounds, I got it for two.",
            },
            {
              definition:
                "A name given to something or someone to categorise them as part of a particular social group.",
              synonyms: ["category", "pigeonhole"],
              antonyms: [],
              example:
                'Ever since he started going to the rock club, he\'s been given the label "waster".',
            },
            {
              definition: "A company that sells records.",
              synonyms: ["record label"],
              antonyms: [],
              example: "The label signed the band after hearing a demo tape.",
            },
            {
              definition:
                "A user-defined alias for a numerical designation, the reverse of an enumeration.",
              synonyms: [],
              antonyms: [],
              example: "Storage devices can be given by label or ID.",
            },
            {
              definition:
                "A named place in source code that can be jumped to using a GOTO or equivalent construct.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A charge resembling the strap crossing the horse’s chest from which pendants are hung.",
              synonyms: ["lambel"],
              antonyms: [],
            },
            { definition: "A tassel.", synonyms: [], antonyms: [] },
            {
              definition:
                "A piece of writing added to something, such as a codicil appended to a will.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A brass rule with sights, formerly used with a circumferentor to take altitudes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The projecting moulding by the sides, and over the tops, of openings in mediaeval architecture.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In mediaeval art, the representation of a band or scroll containing an inscription.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A non-interactive control or widget displaying text, often used to describe the purpose of another control.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "category",
            "pigeonhole",
            "lambel",
            "record label",
            "sign",
            "tag",
            "ticket",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To put a label (a ticket or sign) on (something).",
              synonyms: [],
              antonyms: [],
              example:
                "The shop assistant labeled all the products in the shop.",
            },
            {
              definition:
                "(ditransitive) To give a label to (someone or something) in order to categorise that person or thing.",
              synonyms: [],
              antonyms: [],
              example:
                "He's been unfairly labeled as a cheat, although he's only ever cheated once.",
            },
            {
              definition:
                "To replace specific atoms by their isotope in order to track the presence or movement of this isotope through a reaction, metabolic pathway or cell.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To add a detectable substance, either transiently or permanently, to a biological substance in order to track the presence of the label-substance combination either in situ or in vitro",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "categorise",
            "compartmentalise",
            "peg",
            "pigeonhole",
            "price",
            "tag",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/label"],
    },
  ],
  swing: [
    {
      word: "swing",
      phonetic: "/ˈswɪŋ/",
      phonetics: [
        {
          text: "/ˈswɪŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/swing-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75900171",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈswɪŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/swing-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=188341",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The manner in which something is swung.",
              synonyms: [],
              antonyms: [],
              example: "Door swing indicates direction the door opens.",
            },
            {
              definition: "The sweep or compass of a swinging body.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A line, cord, or other thing suspended and hanging loose, upon which anything may swing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hanging seat in a children's playground, for acrobats in a circus, or on a porch for relaxing.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A dance style.", synonyms: [], antonyms: [] },
            {
              definition:
                "The genre of music associated with this dance style.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The amount of change towards or away from something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Sideways movement of the ball as it flies through the air.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Capacity of a turning lathe, as determined by the diameter of the largest object that can be turned in it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In a musical theater production, a performer who understudies several roles.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A basic dance step in which a pair link hands and turn round together in a circle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Free course; unrestrained liberty.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Influence or power of anything put in motion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A type of hook with the arm more extended.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To rotate about an off-centre fixed point.",
              synonyms: [],
              antonyms: [],
              example: "The plant swung in the breeze.",
            },
            { definition: "To dance.", synonyms: [], antonyms: [] },
            {
              definition: "To ride on a swing.",
              synonyms: [],
              antonyms: [],
              example: "The children laughed as they swung.",
            },
            {
              definition:
                "To participate in the swinging lifestyle; to participate in wife-swapping.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To hang from the gallows.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of a ball) to move sideways in its trajectory.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fluctuate or change.",
              synonyms: [],
              antonyms: [],
              example:
                "It wasn't long before the crowd's mood swung towards restless irritability.",
            },
            {
              definition: "To move (an object) backward and forward; to wave.",
              synonyms: [],
              antonyms: [],
              example: "He swung his sword as hard as he could.",
            },
            {
              definition:
                "To change (a numerical result); especially to change the outcome of an election.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make (something) work; especially to afford (something) financially.",
              synonyms: [],
              antonyms: [],
              example: "If it’s not too expensive, I think we can swing it.",
            },
            {
              definition:
                "To play notes that are in pairs by making the first of the pair slightly longer than written (augmentation) and the second shorter, resulting in a bouncy, uneven rhythm.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a bowler) to make the ball move sideways in its trajectory.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To move one's arm in a punching motion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In dancing, to turn around in a small circle with one's partner, holding hands or arms.",
              synonyms: [],
              antonyms: [],
              example: '"to swing one\'s partner", or simply "to swing"',
            },
            {
              definition:
                "To admit or turn something for the purpose of shaping it; said of a lathe.",
              synonyms: [],
              antonyms: [],
              example: "The lathe can swing a pulley of 12 inches diameter.",
            },
            {
              definition:
                "To put (a door, gate, etc.) on hinges so that it can swing or turn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To turn round by action of wind or tide when at anchor.",
              synonyms: [],
              antonyms: [],
              example: "A ship swings with the tide.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/swing"],
    },
  ],
  solar: [
    {
      word: "solar",
      phonetic: "/ˈsəʊlə/",
      phonetics: [
        { text: "/ˈsəʊlə/", audio: "" },
        {
          text: "/ˈsoʊlɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/solar-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651524",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Of or pertaining to the sun; proceeding from the sun",
              synonyms: [],
              antonyms: [],
              example: "solar influence",
            },
            {
              definition: "Born under the predominant influence of the sun.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Measured by the progress or revolution of the sun in the ecliptic; as, the solar year.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Produced by the action of the sun, or peculiarly affected by its influence.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["sunly"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/solar"],
    },
    {
      word: "solar",
      phonetic: "/ˈsəʊlə/",
      phonetics: [
        { text: "/ˈsəʊlə/", audio: "" },
        {
          text: "/ˈsoʊlɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/solar-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651524",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A loft or upper chamber forming the private accommodation of the head of the household in a medieval hall; a garret room.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/solar"],
    },
  ],
  truly: [
    {
      word: "truly",
      phonetic: "/ˈtɹuːli/",
      phonetics: [
        { text: "/ˈtɹuːli/", audio: "" },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/truly-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1669366",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition:
                "(manner) In accordance with the facts; truthfully, accurately.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(modal) Honestly, genuinely, in fact, really.",
              synonyms: [],
              antonyms: [],
              example: "That is truly all I know.",
            },
            {
              definition: "(degree) Very.",
              synonyms: [],
              antonyms: [],
              example: "You are truly silly.",
            },
          ],
          synonyms: [
            "in point of fact",
            "literally",
            "frankly",
            "sincerely",
            "exceedingly",
            "extremely",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/truly"],
    },
  ],
  tense: [
    {
      word: "tense",
      phonetic: "/tɛns/",
      phonetics: [
        {
          text: "/tɛns/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tense-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1755230",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(grammar) Any of the forms of a verb which distinguish when an action or state of being occurs or exists.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(grammar) An inflected form of a verb that indicates tense.",
              synonyms: [],
              antonyms: [],
              example:
                "English only has a present tense and a past tense; it has no future tense.",
            },
            {
              definition:
                "The property of indicating the point in time at which an action or state of being occurs or exists.",
              synonyms: [],
              antonyms: [],
              example: "Dyirbal verbs are not inflected for tense.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(grammar) To apply a tense to.",
              synonyms: [],
              antonyms: [],
              example: "tensing a verb",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tense"],
    },
    {
      word: "tense",
      phonetic: "/tɛns/",
      phonetics: [
        {
          text: "/tɛns/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tense-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1755230",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make or become tense.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Showing signs of stress or strain; not relaxed.",
              synonyms: ["stressed", "unrelaxed"],
              antonyms: [],
              example:
                "You need to relax, all this overtime and stress is making you tense.",
            },
            {
              definition: "Pulled taut, without any slack.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["stressed", "unrelaxed"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tense"],
    },
  ],
  beans: [
    {
      word: "beans",
      phonetic: "/biːnz/",
      phonetics: [
        {
          text: "/biːnz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/beans-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79268029",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A reusable software component written in Java.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bean"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any plant of several genera of the taxonomic family Fabaceae that produces large edible seeds or edible seedpods.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The large edible seed of such a plant (for example, a broad bean, navy bean or garbanzo bean).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The edible seedpod of such a plant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) The bean-like seed of certain other plants, such a a vanilla bean or (especially) a coffee bean.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) An object resembling a pea or bean in shape, often made from plastic or styrofoam and used in large numbers as packing material or as stuffing for beanbags and similar items.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The head or brain.", synonyms: [], antonyms: [] },
            { definition: "The clitoris.", synonyms: [], antonyms: [] },
            { definition: "A person.", synonyms: [], antonyms: [] },
            { definition: "A guinea coin.", synonyms: [], antonyms: [] },
            {
              definition: "(usually in the negative) Money.",
              synonyms: [],
              antonyms: [],
              example: "I haven't got a bean.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To hit deliberately with a projectile, especially in the head.",
              synonyms: [],
              antonyms: [],
              example:
                "The pitcher beaned the batter, rather than letting him hit another home run.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(urban) pills; drugs in pill form",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Testes", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/JavaBean",
        "https://en.wiktionary.org/wiki/bean",
        "https://en.wiktionary.org/wiki/beans",
      ],
    },
  ],
  split: [
    {
      word: "split",
      phonetic: "/splɪt/",
      phonetics: [
        {
          text: "/splɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/split-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88517660",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/splɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/split-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762775",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A crack or longitudinal fissure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A breach or separation, as in a political party; a division.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece that is split off, or made thin, by splitting; a splinter; a fragment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(leather manufacture) One of the sections of a skin made by dividing it into two or more thicknesses.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                '(cheerleading, usually in the phrase "to do the splits") A maneuver of spreading or sliding the feet apart until the legs are flat on the floor 180 degrees apart, either sideways to the body or with one leg in front and one behind, thus lowering the body completely to the floor in an upright position.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A workout routine as seen by its distribution of muscle groups or the extent and manner they are targetted in a microcycle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A split-finger fastball.",
              synonyms: [],
              antonyms: [],
              example: "He’s got a nasty split.",
            },
            {
              definition:
                "A result of a first throw that leaves two or more pins standing with one or more pins between them knocked down.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A split shot or split stroke.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A dessert or confection resembling a banana split.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A unit of measure used for champagne or other spirits: 18.75 centiliters or one quarter of a standard 75-centiliter bottle. Commercially comparable to 1/20 gallon, which is 1/2 of a fifth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bottle of wine containing 37.5 centiliters, half the volume of a standard 75-centiliter bottle; a demi.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The elapsed time at specific intermediate points in a race.",
              synonyms: [],
              antonyms: [],
              example: "In the 3000 m race, his 800 m split was 1:45.32",
            },
            {
              definition:
                "The elapsed time at specific intermediate points in a speedrun.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A tear resulting from tensile stresses.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A division of a stake happening when two cards of the kind on which the stake is laid are dealt in the same turn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A recording containing songs by multiple artists.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "Of something solid, to divide fully or partly along a more or less straight line.",
              synonyms: ["cleave"],
              antonyms: [],
              example: "He has split his lip.",
            },
            {
              definition:
                "Of something solid, particularly wood, to break along the grain fully or partly along a more or less straight line.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To share; to divide.",
              synonyms: [],
              antonyms: [],
              example: "We split the money among three people.",
            },
            {
              definition: "To leave.",
              synonyms: [],
              antonyms: [],
              example:
                "Let's split this scene and see if we can find a real party.",
            },
            {
              definition: "(of a couple) To separate.",
              synonyms: ["break up", "split up"],
              antonyms: [],
              example:
                "Did you hear Dick and Jane split? They'll probably get a divorce.",
            },
            {
              definition: "To (cause to) break up; to throw into discord.",
              synonyms: [],
              antonyms: [],
              example:
                "Accusations of bribery split the party just before the election.",
            },
            {
              definition:
                "(acts on a polynomial) To factor into linear factors.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be broken; to be dashed to pieces.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To burst out laughing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To divulge a secret; to betray confidence; to peach.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In athletics (especially baseball), for both teams involved in a doubleheader to win one game each and lose another game.",
              synonyms: [],
              antonyms: [],
              example:
                "Boston split with Philadelphia in a doubleheader, winning the first game 3-1 before losing 2-0 in the nightcap.",
            },
            {
              definition: "To vote for candidates of opposite parties.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["break up", "split up", "cleave"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Divided.",
              synonyms: [],
              antonyms: [],
              example:
                "Republicans appear split on the centerpiece of Mr. Obama's economic recovery plan.",
            },
            {
              definition:
                "(of a short exact sequence) Having the middle group equal to the direct product of the others.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of coffee) Comprising half decaffeinated and half caffeinated espresso.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of an order, sale, etc.) Divided so as to be done or executed part at one time or price and part at another time or price.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of quotations) Given in sixteenths rather than eighths.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(London stock exchange) Designating ordinary stock that has been divided into preferred ordinary and deferred ordinary.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/split"],
    },
  ],
  rises: [
    {
      word: "rises",
      phonetic: "/ˈɹaɪzɪz/",
      phonetics: [
        {
          text: "/ˈɹaɪzɪz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rises-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2101123",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To move, or appear to move, physically upwards relative to the ground.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To increase in value or standing.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To begin; to develop.", synonyms: [], antonyms: [] },
            {
              definition: "To go up; to ascend; to climb.",
              synonyms: [],
              antonyms: [],
              example: "to rise a hill",
            },
            {
              definition: "To cause to go up or ascend.",
              synonyms: [],
              antonyms: [],
              example:
                "to rise a fish, or cause it to come to the surface of the water",
            },
            {
              definition: "To retire; to give up a siege.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To come; to offer itself.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be lifted, or capable of being lifted, from the imposing stone without dropping any of the type; said of a form.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "come back from the dead",
            "arise",
            "get up",
            "climb",
            "climb",
            "go up",
            "increase",
          ],
          antonyms: [
            "descend",
            "drop",
            "fall",
            "sink",
            "set",
            "decrease",
            "drop",
            "fall",
            "go down",
            "reduced",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rise",
        "https://en.wiktionary.org/wiki/rises",
      ],
    },
  ],
  weigh: [
    {
      word: "weigh",
      phonetic: "/weɪ/",
      phonetics: [
        {
          text: "/weɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/weigh-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769559",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To determine the weight of an object.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'Often with "out", to measure a certain amount of something by its weight, e.g. for sale.',
              synonyms: [],
              antonyms: [],
              example: "He weighed out two kilos of oranges for a client.",
            },
            {
              definition:
                "To determine the intrinsic value or merit of an object, to evaluate.",
              synonyms: [],
              antonyms: [],
              example:
                "You have been weighed in the balance and found wanting.",
            },
            {
              definition: "To judge; to estimate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To consider a subject.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To have a certain weight.",
              synonyms: [],
              antonyms: [],
              example: "I weigh ten and a half stone.",
            },
            {
              definition: "To have weight; to be heavy; to press down.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be considered as important; to have weight in the intellectual balance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To raise an anchor free of the seabed.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To weigh anchor.", synonyms: [], antonyms: [] },
            {
              definition:
                "To bear up; to raise; to lift into the air; to swing up.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To consider as worthy of notice; to regard.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/weigh"],
    },
  ],
  hotel: [
    {
      word: "hotel",
      phonetic: "/(h)əʊˈtɛl/",
      phonetics: [
        { text: "/(h)əʊˈtɛl/", audio: "" },
        {
          text: "/hoʊˈtɛl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hotel-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1235894",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A large town house or mansion; a grand private residence, especially in France.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An establishment that provides accommodation and other services for paying guests; normally larger than a guesthouse, and often one of a chain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A restaurant; any dining establishment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The letter H in the ICAO spelling alphabet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The larger red property in the game of Monopoly, in contradistinction to houses.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The guest accommodation and dining section of a cruise ship.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hotel"],
    },
  ],
  stems: [
    {
      word: "stems",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Acronym of science, technology, engineering, (and) mathematics.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An electron microscope that transmits a very narrow beam of electrons through a sample; it can detect individual large or heavy atoms.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A gleam of light; a flame.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The stock of a family; a race or generation of progenitors.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A branch of a family.", synonyms: [], antonyms: [] },
            {
              definition: "An advanced or leading position; the lookout.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The above-ground stalk (technically axis) of a vascular plant, and certain anatomically similar, below-ground organs such as rhizomes, bulbs, tubers, and corms.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A slender supporting member of an individual part of a plant such as a flower or a leaf; also, by analogy, the shaft of a feather.",
              synonyms: [],
              antonyms: [],
              example: "the stem of an apple or a cherry",
            },
            {
              definition:
                "A narrow part on certain man-made objects, such as a wine glass, a tobacco pipe, a spoon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The main part of an uninflected word to which affixes may be added to form inflections of the word. A stem often has a more fundamental root. Systematic conjugations and declensions derive from their stems.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A person's leg.", synonyms: [], antonyms: [] },
            { definition: "The penis.", synonyms: [], antonyms: [] },
            {
              definition: "A vertical stroke of a letter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A vertical stroke marking the length of a note in written music.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A premixed portion of a track for use in audio mastering and remixing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The vertical or nearly vertical forward extension of the keel, to which the forward ends of the planks or strakes are attached.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A component on a bicycle that connects the handlebars to the bicycle fork",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A part of an anatomic structure considered without its possible branches or ramifications.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A crack pipe; or the long, hollow portion of a similar pipe (i.e. meth pipe) resembling a crack pipe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(chiefly British) A winder on a clock, watch, or similar mechanism",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["tail", "virgula"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To remove the stem from.",
              synonyms: [],
              antonyms: [],
              example: "to stem cherries; to stem tobacco leaves",
            },
            {
              definition: "To be caused or derived; to originate.",
              synonyms: [],
              antonyms: [],
              example:
                "The current crisis stems from the short-sighted politics of the previous government.",
            },
            {
              definition: "To descend in a family line.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To direct the stem (of a ship) against; to make headway against.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To hit with the stem of a ship; to ram.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To ram (clay, etc.) into a blasting hole.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["arise", "due to"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To stop, hinder (for instance, a river or blood).",
              synonyms: [],
              antonyms: [],
              example: "to stem a tide",
            },
            {
              definition:
                "To move the feet apart and point the tips of the skis inward in order to slow down the speed or to facilitate a turn.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/STEM",
        "https://en.wiktionary.org/wiki/scanning%20transmission%20electron%20microscope",
        "https://en.wiktionary.org/wiki/steem",
        "https://en.wiktionary.org/wiki/stem",
        "https://en.wiktionary.org/wiki/stems",
      ],
    },
  ],
  pride: [
    {
      word: "pride",
      phonetic: "/pɹaɪd/",
      phonetics: [
        {
          text: "/pɹaɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pride-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1244066",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The quality or state of being proud; an unreasonable overestimation of one's own superiority in terms of talents, looks, wealth, importance etc., which manifests itself in lofty airs, distance, reserve and often contempt of others.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(often with of or in) A sense of one's own worth, and abhorrence of what is beneath or unworthy of one; lofty self-respect; noble self-esteem; elevation of character; dignified bearing; proud delight; -- in a good sense.",
              synonyms: [],
              antonyms: [],
              example: "He had pride of ownership in his department.",
            },
            {
              definition:
                "Proud or disdainful behavior or treatment; insolence or arrogance of demeanor; haughty bearing and conduct; insolent exultation; disdain; hubris.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That of which one is proud; that which excites boasting or self-congratulation; the occasion or ground of self-esteem, or of arrogant and presumptuous confidence, as beauty, ornament, noble character, children, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Show; ostentation; glory.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Highest pitch; elevation reached; loftiness; prime; glory,",
              synonyms: [],
              antonyms: [],
              example: "to be in the pride of one's life.",
            },
            {
              definition:
                "Consciousness of power; fullness of animal spirits; mettle; wantonness.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Lust; sexual desire; especially, excitement of sexual appetite in a female beast.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(collective) A company of lions or other large felines.",
              synonyms: [],
              antonyms: [],
              example:
                "A pride of lions often consists of a dominant male, his harem and their offspring, but young adult males 'leave home' to roam about as bachelors pride until able to seize/establish a family pride of their own.",
            },
            {
              definition:
                "The small European lamprey species Petromyzon branchialis.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Alternative letter-case form of Pride.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dignity", "prid", "sandpiper", "conceit", "disdain"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To take or experience pride in something; to be proud of it.",
              synonyms: [],
              antonyms: [],
              example: "I pride myself on being a good judge of character.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pride"],
    },
  ],
  swung: [
    {
      word: "swung",
      phonetic: "/ˈswʌŋ/",
      phonetics: [
        {
          text: "/ˈswʌŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/swung-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100860",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To rotate about an off-centre fixed point.",
              synonyms: [],
              antonyms: [],
              example: "The plant swung in the breeze.",
            },
            { definition: "To dance.", synonyms: [], antonyms: [] },
            {
              definition: "To ride on a swing.",
              synonyms: [],
              antonyms: [],
              example: "The children laughed as they swung.",
            },
            {
              definition:
                "To participate in the swinging lifestyle; to participate in wife-swapping.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To hang from the gallows.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of a ball) to move sideways in its trajectory.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fluctuate or change.",
              synonyms: [],
              antonyms: [],
              example:
                "It wasn't long before the crowd's mood swung towards restless irritability.",
            },
            {
              definition: "To move (an object) backward and forward; to wave.",
              synonyms: [],
              antonyms: [],
              example: "He swung his sword as hard as he could.",
            },
            {
              definition:
                "To change (a numerical result); especially to change the outcome of an election.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make (something) work; especially to afford (something) financially.",
              synonyms: [],
              antonyms: [],
              example: "If it’s not too expensive, I think we can swing it.",
            },
            {
              definition:
                "To play notes that are in pairs by making the first of the pair slightly longer than written (augmentation) and the second shorter, resulting in a bouncy, uneven rhythm.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a bowler) to make the ball move sideways in its trajectory.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To move one's arm in a punching motion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In dancing, to turn around in a small circle with one's partner, holding hands or arms.",
              synonyms: [],
              antonyms: [],
              example: '"to swing one\'s partner", or simply "to swing"',
            },
            {
              definition:
                "To admit or turn something for the purpose of shaping it; said of a lathe.",
              synonyms: [],
              antonyms: [],
              example: "The lathe can swing a pulley of 12 inches diameter.",
            },
            {
              definition:
                "To put (a door, gate, etc.) on hinges so that it can swing or turn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To turn round by action of wind or tide when at anchor.",
              synonyms: [],
              antonyms: [],
              example: "A ship swings with the tide.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/swing",
        "https://en.wiktionary.org/wiki/swung",
      ],
    },
  ],
  grade: [
    {
      word: "grade",
      phonetic: "/ɡɹeɪd/",
      phonetics: [
        {
          text: "/ɡɹeɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grade-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=827564",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A rating.",
              synonyms: [],
              antonyms: [],
              example: "I gave him a good grade for effort.",
            },
            {
              definition:
                "The performance of an individual or group on an examination or test, expressed by a number, letter, or other symbol; a score.",
              synonyms: ["mark"],
              antonyms: [],
              example: "He got a good grade on the test.",
            },
            {
              definition:
                "A degree or level of something; a position within a scale; a degree of quality.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A slope (up or down) of a roadway or other passage",
              synonyms: [],
              antonyms: [],
              example: "The grade of this hill is more than 5 percent.",
            },
            {
              definition: "A level of primary and secondary education.",
              synonyms: [],
              antonyms: [],
              example: "Clancy is entering the fifth grade this year.",
            },
            {
              definition:
                "A student of a particular grade (used with the grade level).",
              synonyms: [],
              antonyms: [],
              example: "The grade fives are on a field trip.",
            },
            {
              definition:
                "An area that has been flattened by a grader (construction machine).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The level of the ground.",
              synonyms: [],
              antonyms: [],
              example:
                "This material absorbs moisture and is probably not a good choice for use below grade.",
            },
            { definition: "A gradian.", synonyms: [], antonyms: [] },
            {
              definition:
                "In a linear system of divisors on an n-dimensional variety, the number of free intersection points of n generic divisors.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A harsh scraping or cutting; a grating.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A taxon united by a level of morphological or physiological complexity that is not a clade.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The degree of malignity of a tumor expressed on a scale.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["group", "paraphyletic", "mark"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To assign scores to the components of an academic test.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To assign a score to overall academic performance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To organize in grades.",
              synonyms: [],
              antonyms: [],
              example: "a graded reader",
            },
            {
              definition: "To flatten, level, or smooth a large surface.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To remove or trim part of a seam allowance from a finished seam so as to reduce bulk and make the finished piece more even when turned right side out.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To pass imperceptibly from one grade into another.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/grade"],
    },
  ],
  digit: [
    {
      word: "digit",
      phonetic: "/ˈdɪdʒɪt/",
      phonetics: [
        {
          text: "/ˈdɪdʒɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/digit-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1755141",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A stage of proficiency or qualification in a course of study, now especially an award bestowed by a university or, in some countries, a college, as a certification of academic achievement. (In the United States, can include secondary schools.)",
              synonyms: [],
              antonyms: [],
              example:
                "She has two bachelor's degrees and is studying towards a master's degree.",
            },
            {
              definition:
                "A unit of measurement of angle equal to 1/360 of a circle's circumference.",
              synonyms: [],
              antonyms: [],
              example: "A right angle is a ninety-degree angle.",
            },
            {
              definition:
                "A unit of measurement of temperature on any of several scales, such as Celsius or Fahrenheit.",
              synonyms: [],
              antonyms: [],
              example:
                "180 degrees Fahrenheit is equivalent to 100 degrees Celsius.",
            },
            {
              definition:
                "The sum of the exponents of a term; the order of a polynomial.",
              synonyms: [],
              antonyms: [],
              example: "A quadratic polynomial is a polynomial of degree 2.",
            },
            {
              definition: "The dimensionality of a field extension.",
              synonyms: [],
              antonyms: [],
              example:
                "The Galois field \\operatorname{GF}(125) = \\operatorname{GF}(5^3) has degree 3 over its subfield \\operatorname{GF}(5).",
            },
            {
              definition:
                "The number of edges that a vertex takes part in; a valency.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The number of logical connectives in a formula.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The curvature of a circular arc, expressed as the angle subtended by a fixed length of arc or chord.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A unit of measurement of latitude and longitude which together identify a location on the Earth's surface.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(grammar) Any of the three stages (positive, comparative, superlative) in the comparison of an adjective or an adverb.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(obsolete outside heraldry) A step on a set of stairs; the rung of a ladder.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An individual step, or stage, in any process or scale of values.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A stage of rank or privilege; social standing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(genealogy) A ‘step’ in genealogical descent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One's relative state or experience; way, manner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The amount that an entity possesses a certain property; relative intensity, extent.",
              synonyms: [],
              antonyms: [],
              example:
                "To what degree do the two accounts of the accident concur?",
            },
          ],
          synonyms: ["arcdegree", "°", "°", "°", "°"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The whole numbers from 0 to 9 and the Arabic numerals representing them, which are combined to represent base-ten numbers.",
              synonyms: [],
              antonyms: [],
              example:
                "The number 123.4 has four digits: the hundreds digit is 1, the tens digit is 2, the units digit is 3, and the tenths digit is 4.",
            },
            {
              definition:
                "A distinct symbol representing one of an arithmetic progression of numbers between 0 and the radix.",
              synonyms: [],
              antonyms: [],
              example:
                "Hexadecimal numeration (Base sixteen) includes the digits 0, 1, 2, 3, 4, 5, 6, 7, 8, 9 but also A (=10 decimal), B, C, D, E, and F. Sixteen itself is written as the two-digit number 10.",
            },
            {
              definition:
                "1/12 the apparent diameter of the sun or moon, as a measure of the totality of an eclipse.",
              synonyms: [],
              antonyms: [],
              example: "A six-digit eclipse covers half the lunar surface.",
            },
            {
              definition:
                "A unit of length notionally based upon the width of an adult human finger, standardized differently in various places and times, the English digit of 1/16 foot (about 1.9 cm).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A narrow extremity of the human hand or foot: a finger, thumb, or toe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Similar or similar-looking structures in other animals.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "finger",
            "figure",
            "place",
            "finger",
            "fingerbreadth",
            "fingersbreadth",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To point at or point out with the finger.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A unit of length equal to one twelfth of a foot, or exactly 2.54 centimetres.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The amount of water which would cover a surface to the depth of an inch, used as a measurement of rainfall.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The amount of an alcoholic beverage which would fill a glass or bottle to the depth of an inch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A very short distance.",
              synonyms: [],
              antonyms: [],
              example: '"Don\'t move an inch!"',
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A small island", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/degree",
        "https://en.wiktionary.org/wiki/digit",
        "https://en.wiktionary.org/wiki/inch",
      ],
    },
  ],
  badly: [
    {
      word: "badly",
      phonetic: "/ˈbæd.li/",
      phonetics: [
        {
          text: "/ˈbæd.li/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/badly-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100332",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Ill, unwell.",
              synonyms: [],
              antonyms: [],
              example: "He's never badly.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            { definition: "In a bad manner.", synonyms: [], antonyms: [] },
            {
              definition: "Very much; to a great degree.",
              synonyms: [],
              antonyms: [],
              example: "I want it so badly.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/badly"],
    },
  ],
  boots: [
    {
      word: "boots",
      phonetic: "/buːts/",
      phonetics: [
        {
          text: "/buːts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/boots-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899622",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/buːts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/boots-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100341",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A heavy shoe that covers part of the leg.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A blow with the foot; a kick.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A flexible cover of rubber or plastic, which may be preformed to a particular shape and used to protect a shaft, lever, switch, or opening from dust, dirt, moisture, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(usually preceded by definite article) A torture device used on the feet or legs, such as a Spanish boot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A parking enforcement device used to immobilize a car until it can be towed or a fine is paid; a wheel clamp.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A rubber bladder on the leading edge of an aircraft’s wing, which is inflated periodically to remove ice buildup. A deicing boot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A place at the side of a coach, where attendants rode; also, a low outside place before and behind the body of the coach.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A place for baggage at either end of an old-fashioned stagecoach.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(police) A recently arrived recruit; a rookie.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The luggage storage compartment of a sedan or saloon car.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The act or process of removing or firing someone (give someone the boot).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'Unattractive person, ugly woman (usually as "old boot")',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hard plastic case for a long firearm, typically moulded to the shape of the gun and intended for use in a vehicle.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A bobbled ball.", synonyms: [], antonyms: [] },
            {
              definition: "The inflated flag leaf sheath of a wheat plant.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "kick",
            "dicky",
            "trunk",
            "wheel clamp",
            "fired",
            "laid off",
            "buskin",
            "mukluk",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To kick.",
              synonyms: [],
              antonyms: [],
              example: "I booted the ball toward my teammate.",
            },
            {
              definition: "To put boots on, especially for riding.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To apply corporal punishment (compare slippering).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To forcibly eject.",
              synonyms: [],
              antonyms: [],
              example:
                "We need to boot those troublemakers as soon as possible",
            },
            {
              definition:
                "To disconnect forcibly; to eject from an online service, conversation, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To vomit.",
              synonyms: [],
              antonyms: [],
              example: "Sorry, I didn’t mean to boot all over your couch.",
            },
            {
              definition: "(criminal slang) To shoot, to kill by gunfire.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["kick", "hoof", "kick"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Remedy, amends.", synonyms: [], antonyms: [] },
            { definition: "Profit, plunder.", synonyms: [], antonyms: [] },
            {
              definition:
                "That which is given to make an exchange equal, or to make up for the deficiency of value in one of the things exchanged; compensation; recompense.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Profit; gain; advantage; use.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Repair work; the act of fixing structures or buildings.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A medicinal cure or remedy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To avail, benefit, profit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To benefit, to enrich; to give in addition.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The act or process of bootstrapping; the starting or re-starting of a computing device.",
              synonyms: [],
              antonyms: [],
              example:
                "It took three boots, but I finally got the application installed.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To bootstrap; to start a system, e.g. a computer, by invoking its boot process or bootstrap.",
              synonyms: ["boot up", "bootstrap", "start"],
              antonyms: ["shut down", "stop", "turn off"],
              example:
                "When arriving at the office, first thing I do is booting my machine.",
            },
          ],
          synonyms: ["boot up", "bootstrap", "start"],
          antonyms: ["shut down", "stop", "turn off"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A bootleg recording.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A servant at a hotel etc. who cleans and blacks the boots and shoes.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/boot",
        "https://en.wiktionary.org/wiki/boots",
      ],
    },
  ],
  pilot: [
    {
      word: "pilot",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pilot-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75648941",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈpaɪlət/", audio: "" },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pilot-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651297",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A person who steers a ship, a helmsman.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person who knows well the depths and currents of a harbor or coastal area, who is hired by a vessel to help navigate the harbor or coast.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A guide book for maritime navigation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An instrument for detecting the compass error.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A pilot vehicle.", synonyms: [], antonyms: [] },
            {
              definition:
                "A person authorised to drive such a vehicle during an escort.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A guide or escort through an unknown or dangerous area.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Something serving as a test or trial.",
              synonyms: [],
              antonyms: [],
              example:
                "We would like to run a pilot in your facility before rolling out the program citywide.",
            },
            {
              definition:
                "A person who is in charge of the controls of an aircraft.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sample episode of a proposed TV series produced to decide if it should be made or not. If approved, typically the first episode of an actual TV series.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A cowcatcher.", synonyms: [], antonyms: [] },
            { definition: "A pilot light.", synonyms: [], antonyms: [] },
            { definition: "One who flies a kite.", synonyms: [], antonyms: [] },
            {
              definition:
                "A short plug, sometimes made interchangeable, at the end of a counterbore to guide the tool.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To control (an aircraft or watercraft).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To guide (a vessel) through coastal waters.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To test or have a preliminary trial of (an idea, a new product, etc.)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Made or used as a test or demonstration of capability.",
              synonyms: [],
              antonyms: [],
              example:
                "The pilot plant showed the need for major process changes.",
            },
            {
              definition: "Used to control or activate another device.",
              synonyms: [],
              antonyms: [],
              example: "a pilot light",
            },
            {
              definition:
                "Being a vehicle to warn other road users of the presence of an oversize vehicle/combination.",
              synonyms: [],
              antonyms: [],
              example: "a pilot vehicle",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pilot"],
    },
  ],
  sales: [
    {
      word: "sales",
      phonetic: "/seɪlz/",
      phonetics: [
        {
          text: "/seɪlz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sales-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651455",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "A hall.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An exchange of goods or services for currency or credit.",
              synonyms: [],
              antonyms: [],
              example: "He celebrated after the sale of company.",
            },
            {
              definition:
                "(Short for discount sale) The sale of goods at reduced prices.",
              synonyms: [],
              antonyms: [],
              example: "They are having a clearance sale: 50% off.",
            },
            {
              definition:
                "The act of putting up for auction to the highest bidder.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The activities involved in selling goods or services.",
              synonyms: [],
              antonyms: [],
              example:
                "He's likable and motivated: perfect for a career in sales.",
            },
            {
              definition: "The amount or value of goods and services sold.",
              synonyms: [],
              antonyms: [],
              example: "Sales were up 12% over last year.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sale",
        "https://en.wiktionary.org/wiki/sales",
      ],
    },
  ],
  swept: [
    {
      word: "swept",
      phonetic: "/ˈswɛpt/",
      phonetics: [
        {
          text: "/ˈswɛpt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/swept-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1684753",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To clean (a surface) by means of a stroking motion of a broom or brush.",
              synonyms: [],
              antonyms: [],
              example: "to sweep a floor, the street, or a chimney",
            },
            {
              definition:
                "To move through a (horizontal) arc or similar long stroke.",
              synonyms: [],
              antonyms: [],
              example: "The offended countess swept out of the ballroom.",
            },
            {
              definition: "To search (a place) methodically.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To travel quickly.", synonyms: [], antonyms: [] },
            { definition: "To play a sweep shot.", synonyms: [], antonyms: [] },
            {
              definition:
                "To brush the ice in front of a moving stone, causing it to travel farther and to curl less.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To move something in a long sweeping motion, as a broom.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To win (a series) without drawing or losing any of the games in that series.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To defeat (a team) in a series without drawing or losing any of the games in that series.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To remove something abruptly and thoroughly.",
              synonyms: [],
              antonyms: [],
              example: "She swept the peelings off the table onto the floor.",
            },
            {
              definition: "To brush against or over; to rub lightly along.",
              synonyms: [],
              antonyms: [],
              example:
                "Their long descending train, / With rubies edg'd and sapphires, swept the plain.",
            },
            {
              definition:
                "To carry with a long, swinging, or dragging motion; hence, to carry in a stately or proud fashion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To strike with a long stroke.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To row with one oar to either the port or starboard side.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To draw or drag something over.",
              synonyms: [],
              antonyms: [],
              example: "to sweep the bottom of a river with a net",
            },
            {
              definition:
                "To pass over, or traverse, with the eye or with an instrument of observation.",
              synonyms: [],
              antonyms: [],
              example: "to sweep the heavens with a telescope",
            },
            {
              definition: "(including) to vacuum a carpet or rug",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sweep",
        "https://en.wiktionary.org/wiki/swept",
      ],
    },
  ],
  lucky: [
    {
      word: "lucky",
      phonetic: "/ˈlʌki/",
      phonetics: [
        {
          text: "/ˈlʌki/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lucky-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1229925",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(of people) Favoured by luck; fortunate; having good success or good fortune",
              synonyms: [],
              antonyms: [],
              example: "The downed pilot is very lucky to be alive.",
            },
            {
              definition: "Producing, or resulting in, good fortune",
              synonyms: ["auspicious", "favorable", "fortunate"],
              antonyms: [],
              example: "a lucky cast",
            },
          ],
          synonyms: [
            "fortunate",
            "successful",
            "auspicious",
            "favorable",
            "favourable",
            "fortuitous",
            "fortunate",
            "auspicious",
            "favorable",
            "fortunate",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lucky"],
    },
  ],
  prize: [
    {
      word: "prize",
      phonetic: "/pɹaɪz/",
      phonetics: [
        {
          text: "/pɹaɪz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/prize-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780288",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "That which is taken from another; something captured; a thing seized by force, stratagem, or superior power.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Anything captured by a belligerent using the rights of war; especially, property captured at sea in virtue of the rights of war, as a vessel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An honour or reward striven for in a competitive contest; anything offered to be competed for, or as an inducement to, or reward of, effort.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "That which may be won by chance, as in a lottery.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Anything worth striving for; a valuable possession held or in prospect.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A contest for a reward; competition.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A lever; a pry; also, the hold of a lever.",
              synonyms: ["prise"],
              antonyms: [],
            },
          ],
          synonyms: ["prise"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/prize"],
    },
    {
      word: "prize",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To consider highly valuable; to esteem.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To set or estimate the value of; to appraise; to price; to rate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To move with a lever; to force up or open; to prise or pry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To compete in a prizefight.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/prize"],
    },
    {
      word: "prize",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having won a prize; award-winning.",
              synonyms: [],
              antonyms: [],
              example: "a prize vegetable",
            },
            {
              definition: "First-rate; exceptional",
              synonyms: [],
              antonyms: [],
              example: "He was a prize fool.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/prize"],
    },
    {
      word: "prize",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The cost required to gain possession of something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The cost of an action or deed.",
              synonyms: [],
              antonyms: [],
              example: "I paid a high price for my folly.",
            },
            {
              definition: "Value; estimation; excellence; worth.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/price",
        "https://en.wiktionary.org/wiki/prize",
      ],
    },
  ],
  stove: [
    {
      word: "stove",
      phonetic: "/stəʊv/",
      phonetics: [
        { text: "/stəʊv/", audio: "" },
        {
          text: "/stoʊv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stove-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769527",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A heater, a closed apparatus to burn fuel for the warming of a room.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A device for heating food, (UK) a cooker.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A hothouse (heated greenhouse).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A house or room artificially warmed or heated.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To heat or dry, as in a stove.",
              synonyms: [],
              antonyms: [],
              example: "to stove feathers",
            },
            {
              definition:
                "To keep warm, in a house or room, by artificial heat.",
              synonyms: [],
              antonyms: [],
              example: "to stove orange trees",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stove"],
    },
    {
      word: "stove",
      phonetic: "/stəʊv/",
      phonetics: [
        { text: "/stəʊv/", audio: "" },
        {
          text: "/stoʊv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stove-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769527",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To fit or furnish with staves or rundles.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(usually with 'in') To break in the staves of; to break a hole in; to burst.",
              synonyms: [],
              antonyms: [],
              example: "to stave in a cask",
            },
            {
              definition: "(with 'off') To push, or keep off, as with a staff.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(usually with 'off') To delay by force or craft; to drive away.",
              synonyms: [],
              antonyms: [],
              example: "We ate grass in an attempt to stave off our hunger.",
            },
            {
              definition:
                "(rare or archaic) To burst in pieces by striking against something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To walk or move rapidly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To suffer, or cause to be lost by breaking the cask.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To render impervious or solid by driving with a calking iron.",
              synonyms: [],
              antonyms: [],
              example:
                "to stave lead, or the joints of pipes into which lead has been run",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/stave",
        "https://en.wiktionary.org/wiki/stove",
      ],
    },
  ],
  tubes: [
    {
      word: "tubes",
      phonetic: "/tjuːbz/",
      phonetics: [
        { text: "/tjuːbz/", audio: "" },
        {
          text: "/tuːbz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tubes-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=873563",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Anything that is hollow and cylindrical in shape.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An approximately cylindrical container, usually with a crimped end and a screw top, used to contain and dispense semiliquid substances.",
              synonyms: [],
              antonyms: [],
              example: "A tube of toothpaste.",
            },
            {
              definition:
                "(often capitalized as Tube) The London Underground railway system, originally referred to the lower level lines that ran in tubular tunnels as opposed to the higher ones which ran in rectangular section tunnels. (Often the tube.)",
              synonyms: [],
              antonyms: [],
              example:
                "I took the tube to Waterloo and walked the rest of the way.",
            },
            {
              definition: "A tin can containing beer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A wave which pitches forward when breaking, creating a hollow space inside.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A television.",
              synonyms: ["boob tube", "telly"],
              antonyms: [],
              example:
                "Are you just going to sit around all day and watch the tube?",
            },
            { definition: "An idiot.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["boob tube", "telly"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To supply with, or enclose in, a tube.",
              synonyms: [],
              antonyms: [],
              example: "She tubes lipstick in the cosmetics factory.",
            },
            {
              definition: "To ride an inner tube.",
              synonyms: [],
              antonyms: [],
              example: "They tubed down the Colorado River.",
            },
            {
              definition: "To intubate.",
              synonyms: [],
              antonyms: [],
              example: "The patient was tubed.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tube",
        "https://en.wiktionary.org/wiki/tubes",
      ],
    },
  ],
  acres: [
    {
      word: "acres",
      phonetic: "/ˈeɪ.kəz/",
      phonetics: [
        { text: "/ˈeɪ.kəz/", audio: "" },
        { text: "/ˈeɪ.kɚz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An English unit of land area (symbol: a. or ac.) originally denoting a day's plowing for a yoke of oxen, now standardized as 4,840 square yards or 4,046.86 square meters.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various similar units of area in other systems.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(usually in the plural) A wide expanse.",
              synonyms: [],
              antonyms: [],
              example: "I like my new house - there’s acres of space!",
            },
            {
              definition: "(usually in the plural) A large quantity.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A field.", synonyms: [], antonyms: [] },
            {
              definition:
                "The acre's breadth by the length, English units of length equal to the statute dimensions of the acre: 22 yds (≈20 m) by 220 yds (≈200 m).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A duel fought between individual Scots and Englishmen in the borderlands.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "morgen",
            "feddan",
            "arpen",
            "arpent",
            "pose",
            "bigha",
            "cawney",
            "cawny",
            "Irish acre",
            "collop",
            "plantation acre",
            "juger",
            "jugerum",
            "Scotch acre",
            "Scots acre",
            "Scottish acre",
            "acair",
            "Welsh acre",
            "cover",
            "cyfair",
            "erw",
            "stang",
            "day's math",
            "demath",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/acre",
        "https://en.wiktionary.org/wiki/acres",
      ],
    },
  ],
  wound: [
    {
      word: "wound",
      phonetic: "/wuːnd/",
      phonetics: [
        { text: "/wuːnd/", audio: "" },
        {
          text: "/wund/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wound-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1197730",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An injury, such as a cut, stab, or tear, to a (usually external) part of the body.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hurt to a person's feelings, reputation, prospects, etc.",
              synonyms: [],
              antonyms: [],
              example:
                "It took a long time to get over the wound of that insult.",
            },
            {
              definition:
                "An injury to a person by which the skin is divided or its continuity broken.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["injury", "lesion", "insult", "slight", "slur"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To hurt or injure (someone) by cutting, piercing, or tearing the skin.",
              synonyms: [],
              antonyms: [],
              example:
                "The police officer wounded the suspect during the fight that ensued.",
            },
            {
              definition: "To hurt (a person's feelings).",
              synonyms: [],
              antonyms: [],
              example:
                "The actor's pride was wounded when the leading role went to his rival.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/wound"],
    },
    {
      word: "wound",
      phonetic: "/waʊnd/",
      phonetics: [
        { text: "/waʊnd/", audio: "" },
        {
          text: "/waʊnd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wound-2-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1197731",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To blow air through a wind instrument or horn to make a sound.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause (someone) to become breathless, as by a blow to the abdomen, or by physical exertion, running, etc.",
              synonyms: [],
              antonyms: [],
              example: "The boxer was winded during round two.",
            },
            {
              definition:
                "To cause a baby to bring up wind by patting its back after being fed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To turn a boat or ship around, so that the wind strikes it on the opposite side.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To expose to the wind; to winnow; to ventilate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To perceive or follow by scent.",
              synonyms: [],
              antonyms: [],
              example: "The hounds winded the game.",
            },
            {
              definition:
                "To rest (a horse, etc.) in order to allow the breath to be recovered; to breathe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To turn a windmill so that its sails face into the wind.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To turn coils of (a cord or something similar) around something.",
              synonyms: [],
              antonyms: [],
              example: "to wind thread on a spool or into a ball",
            },
            {
              definition:
                "To tighten the spring of a clockwork mechanism such as that of a clock.",
              synonyms: [],
              antonyms: [],
              example: "Please wind that old-fashioned alarm clock.",
            },
            {
              definition: "To entwist; to enfold; to encircle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To travel in a way that is not straight.",
              synonyms: [],
              antonyms: [],
              example:
                "Vines wind round a pole.  The river winds through the plain.",
            },
            {
              definition:
                "To have complete control over; to turn and bend at one's pleasure; to vary or alter or will; to regulate; to govern.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To introduce by insinuation; to insinuate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cover or surround with something coiled about.",
              synonyms: [],
              antonyms: [],
              example: "to wind a rope with twine",
            },
            {
              definition:
                "To cause to move by exerting a winding force; to haul or hoist, as by a winch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To turn (a ship) around, end for end.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wind",
        "https://en.wiktionary.org/wiki/wound",
      ],
    },
  ],
  steep: [
    {
      word: "steep",
      phonetic: "/stiːp/",
      phonetics: [
        {
          text: "/stiːp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/steep-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=384728",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The steep side of a mountain etc.; a slope or acclivity.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["brant"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Of a near-vertical gradient; of a slope, surface, curve, etc. that proceeds upward at an angle near vertical.",
              synonyms: [],
              antonyms: [],
              example:
                "a steep hill or mountain; a steep roof; a steep ascent; a steep barometric gradient",
            },
            {
              definition: "Expensive",
              synonyms: [],
              antonyms: [],
              example: "Twenty quid for a shave? That's a bit steep.",
            },
            {
              definition:
                "Difficult to access; not easy reached; lofty; elevated; high.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of the rake of a ship's mast, or a car's windshield) resulting in a mast or windshield angle that strongly diverges from the perpendicular",
              synonyms: [],
              antonyms: [],
              example:
                "The steep rake of the windshield enhances the fast lines of the exterior. http//legacy.sandiegouniontribune.com/uniontrib/20070303/news_lz1dd3maynard.html",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/steep"],
    },
    {
      word: "steep",
      phonetic: "/stiːp/",
      phonetics: [
        {
          text: "/stiːp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/steep-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=384728",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A liquid used in a steeping process",
              synonyms: [],
              antonyms: [],
              example: "Corn steep has many industrial uses.",
            },
            { definition: "A rennet bag.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(middle voice) To soak or wet thoroughly.",
              synonyms: [],
              antonyms: [],
              example: "The tea is steeping.",
            },
            {
              definition: "To imbue with something; to be deeply immersed in.",
              synonyms: [],
              antonyms: [],
              example: "The learned of the nation were steeped in Latin.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/steep"],
    },
  ],
  slide: [
    {
      word: "slide",
      phonetic: "/slaɪd/",
      phonetics: [
        {
          text: "/slaɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/slide-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762766",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An item of play equipment that children can climb up and then slide down again.",
              synonyms: [],
              antonyms: [],
              example: "The long, red slide was great fun for the kids.",
            },
            {
              definition:
                "A surface of ice, snow, butter, etc. on which someone can slide for amusement or as a practical joke.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The falling of large amounts of rubble, earth and stones down the slope of a hill or mountain; avalanche.",
              synonyms: [],
              antonyms: [],
              example: "The slide closed the highway.",
            },
            {
              definition:
                "An inclined plane on which heavy bodies slide by the force of gravity, especially one constructed on a mountainside for conveying logs by sliding them down.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A mechanism consisting of a part which slides on or against a guide.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The act of sliding; smooth, even passage or progress.",
              synonyms: [],
              antonyms: [],
              example: "a slide on the ice",
            },
            {
              definition: "A lever that can be moved in two directions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A valve that works by sliding, such as in a trombone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A transparent plate bearing an image to be projected to a screen.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) A page of a computer presentation package such as PowerPoint.",
              synonyms: [],
              antonyms: [],
              example:
                "I still need to prepare some slides for my presentation tomorrow.",
            },
            {
              definition:
                "A flat, usually rectangular piece of glass or similar material on which a prepared sample may be viewed through a microscope Generally referred to as a microscope slide.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The act of dropping down and skidding into a base",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(guitar) A hand-held device made of smooth, hard material, used in the practice of slide guitar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A lively dance from County Kerry, in 12/8 time.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small dislocation in beds of rock along a line of fissure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A grace consisting of two or more small notes moving by conjoint degrees, and leading to a principal note either above or below.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sound which, by a gradual change in the position of the vocal organs, passes imperceptibly into another sound.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A clasp or brooch for a belt, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(footwear) A shoe that is backless and open-toed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(speech therapy) A voluntary stutter used as a technique to control stuttering in one's speech.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["chute", "slippery dip", "runner"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To (cause to) move in continuous contact with a surface",
              synonyms: [],
              antonyms: [],
              example: "He slid the boat across the grass.",
            },
            {
              definition: "To move on a low-friction surface.",
              synonyms: [],
              antonyms: [],
              example: "The car slid on the ice.",
            },
            {
              definition: "To drop down and skid into a base.",
              synonyms: [],
              antonyms: [],
              example: "Jones slid into second.",
            },
            {
              definition: "To lose one’s balance on a slippery surface.",
              synonyms: [],
              antonyms: [],
              example: "He slid while going around the corner.",
            },
            {
              definition: "To pass or put imperceptibly; to slip.",
              synonyms: [],
              antonyms: [],
              example: "to slide in a word to vary the sense of a question",
            },
            {
              definition: "To pass inadvertently.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To pass along smoothly or unobservedly; to move gently onward without friction or hindrance.",
              synonyms: [],
              antonyms: [],
              example: "A ship or boat slides through the water.",
            },
            {
              definition:
                "To pass from one note to another with no perceptible cessation of sound.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/slide"],
    },
  ],
  trunk: [
    {
      word: "trunk",
      phonetic: "/tɹʌŋk/",
      phonetics: [
        { text: "/tɹʌŋk/", audio: "" },
        {
          text: "/tɹʌŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/trunk-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1770086",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(heading, biological) Part of a body.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(heading) A container.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(heading) A channel for flow of some kind.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'In software projects under source control: the most current source tree, from which the latest unstable builds (so-called "trunk builds") are compiled.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The main line or body of anything.",
              synonyms: [],
              antonyms: [],
              example:
                "the trunk of a vein or of an artery, as distinct from the branches",
            },
            {
              definition:
                "A large pipe forming the piston rod of a steam engine, of sufficient diameter to allow one end of the connecting rod to be attached to the crank, and the other end to pass within the pipe directly to the piston, thus making the engine more compact.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Shorts used for swimming (swim trunks).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["boot", "dicky", "proboscis", "tree trunk"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To lop off; to curtail; to truncate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To extract (ores) from the slimes in which they are contained, by means of a trunk.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/trunk"],
    },
  ],
  error: [
    {
      word: "error",
      phonetic: "/ˈɛɹə(ɹ)/",
      phonetics: [
        { text: "/ˈɛɹə(ɹ)/", audio: "" },
        {
          text: "/ˈɛɹɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/error-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=857012",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The state, quality, or condition of being wrong.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A mistake; an accidental wrong action or a false statement not made deliberately.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Sin; transgression.", synonyms: [], antonyms: [] },
            {
              definition:
                "A failure to complete a task, usually involving a premature termination.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The difference between a measured or calculated value and a true one.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A play which is scored as having been made incorrectly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One or more mistakes in a trial that could be grounds for review of the judgement.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any alteration in the DNA chemical structure occurring during DNA replication, recombination or repairing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "blooper",
            "blunder",
            "boo-boo",
            "defect",
            "fault",
            "faux pas",
            "flub",
            "fluff",
            "fumble",
            "gaffe",
            "lapse",
            "mistake",
            "slip",
            "stumble",
            "thinko",
            "wrength",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To function improperly due to an error, especially accompanied by error message.",
              synonyms: [],
              antonyms: [],
              example:
                "Remove that line of code and the script should stop erroring there.",
            },
            {
              definition: "To show or contain an error or fault.",
              synonyms: [],
              antonyms: [],
              example:
                "The block transmission errored near the start and could not be received.",
            },
            { definition: "To err.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["err"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/error"],
    },
  ],
  porch: [
    {
      word: "porch",
      phonetic: "/pɔːtʃ/",
      phonetics: [
        { text: "/pɔːtʃ/", audio: "" },
        {
          text: "/pɔɹtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/porch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2707567",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A covered and enclosed entrance to a building, whether taken from the interior, and forming a sort of vestibule within the main wall, or projecting without and with a separate roof.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A portico; a covered walk.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The platform outside the external hatch of a spacecraft.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/porch"],
    },
  ],
  slave: [
    {
      word: "slave",
      phonetic: "/sleɪv/",
      phonetics: [
        {
          text: "/sleɪv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/slave-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1669349",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person who is held in servitude as the property of another person, and whose labor (and often also whose body and life) is subject to the owner's volition and control.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A drudge; one who labors or is obliged (e.g. by prior contract) to labor like a slave with limited rights, e.g. an indentured servant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An abject person.",
              synonyms: ["wretch"],
              antonyms: [],
            },
            {
              definition:
                "One who has no power of resistance (to something), one who surrenders to or is under the domination (of something).",
              synonyms: [],
              antonyms: [],
              example: "a slave to passion, to strong drink, or to ambition",
            },
            {
              definition:
                "(BDSM) A submissive partner in a BDSM relationship who (consensually) submits to (sexually and/or personally) serving one or more masters or mistresses.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sex slave, a person who is forced against their will to perform, for another person or group, sexual acts on a regular or continuing basis.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A device (such as a secondary flash or hard drive) that is subject to the control of another (a master).",
              synonyms: ["secondary", "worker"],
              antonyms: ["master", "primary"],
            },
          ],
          synonyms: ["secondary", "worker", "wretch"],
          antonyms: ["master", "primary"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To work as a slaver, to enslave people.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To work hard.",
              synonyms: [],
              antonyms: [],
              example: "I was slaving all day over a hot stove.",
            },
            {
              definition: "To place a device under the control of another.",
              synonyms: [],
              antonyms: [],
              example: "to slave a hard disk",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/slave"],
    },
  ],
  exist: [
    {
      word: "exist",
      phonetic: "/ɪɡˈzɪst/",
      phonetics: [
        {
          text: "/ɪɡˈzɪst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/exist-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1684604",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(stative) to be; have existence; have being or reality",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["be"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/exist"],
    },
  ],
  faced: [
    {
      word: "faced",
      phonetic: "/feɪst/",
      phonetics: [
        {
          text: "/feɪst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/faced-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100966",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(of a person or animal) To position oneself or itself so as to have one's face closest to (something).",
              synonyms: [],
              antonyms: [],
              example: "Face the sun.",
            },
            {
              definition:
                "(of an object) To have its front closest to, or in the direction of (something else).",
              synonyms: [],
              antonyms: [],
              example: "Turn the chair so it faces the table.",
            },
            {
              definition:
                "To cause (something) to turn or present a face or front, as in a particular direction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be presented or confronted with; to have in prospect.",
              synonyms: [],
              antonyms: [],
              example: "We are facing an uncertain future.",
            },
            {
              definition:
                "To deal with (a difficult situation or person); to accept (facts, reality, etc.) even when undesirable.",
              synonyms: [],
              antonyms: [],
              example: "I'm going to have to face this sooner or later.",
            },
            {
              definition: "To have the front in a certain direction.",
              synonyms: [],
              antonyms: [],
              example: "The seats in the carriage faced backwards.",
            },
            {
              definition: "To have as an opponent.",
              synonyms: [],
              antonyms: [],
              example:
                "Puddletown United face Mudchester Rovers in the quarter-finals.",
            },
            {
              definition: "To be the batsman on strike.",
              synonyms: [],
              antonyms: [],
              example: "Willoughby comes in to bowl, and it's Hobson facing.",
            },
            {
              definition: "To confront impudently; to bully.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cover in front, for ornament, protection, etc.; to put a facing upon.",
              synonyms: [],
              antonyms: [],
              example: "a building faced with marble",
            },
            {
              definition:
                "To line near the edge, especially with a different material.",
              synonyms: [],
              antonyms: [],
              example: "to face the front of a coat, or the bottom of a dress",
            },
            {
              definition:
                "To cover with better, or better appearing, material than the mass consists of, for purpose of deception, as the surface of a box of tea, a barrel of sugar, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make the surface of (anything) flat or smooth; to dress the face of (a stone, a casting, etc.); especially, in turning, to shape or smooth the flat surface of, as distinguished from the cylindrical surface.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(retail) To arrange the products in (a store) so that they are tidy and attractive.",
              synonyms: [],
              antonyms: [],
              example:
                "In my first job, I learned how to operate a till and to face the store to high standards.",
            },
          ],
          synonyms: ["confront", "deal with"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(in combination) Having a specified type or number of faces.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Having the outer surface dressed, with the front, as of a dress, covered ornamentally with another material.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/face",
        "https://en.wiktionary.org/wiki/faced",
      ],
    },
    {
      word: "faced",
      phonetic: "/feɪst/",
      phonetics: [
        {
          text: "/feɪst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/faced-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100966",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Drunk",
              synonyms: [],
              antonyms: [],
              example: "That night was the first time I ever got faced.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/faced"],
    },
  ],
  mines: [
    {
      word: "mines",
      phonetic: "/maɪnz/",
      phonetics: [{ text: "/maɪnz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Demeanor; facial expression or attitude, especially one which is intended by its bearer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A specific facial expression.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An excavation from which ore or solid minerals are taken, especially one consisting of underground tunnels.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any source of wealth or resources.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A passage dug toward or underneath enemy lines, which is then packed with explosives.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A device intended to explode when stepped upon or touched, or when approached by a ship, vehicle, or person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A type of firework that explodes on the ground, shooting sparks upward.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The cavity made by a caterpillar while feeding inside a leaf.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A machine or network of machines used to extract units of a cryptocurrency.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To remove (ore) from the ground.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To dig into, for ore or metal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To sow mines (the explosive devices) in (an area).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To damage (a vehicle or ship) with a mine (an explosive device).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To dig a tunnel or hole; to burrow in the earth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To dig away, or otherwise remove, the substratum or foundation of; to lay a mine under; to sap; to undermine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) To ruin or destroy by slow degrees or secret means.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To pick one's nose.", synonyms: [], antonyms: [] },
            {
              definition:
                "To earn new units of cryptocurrency by doing certain calculations.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "pronoun",
          definitions: [
            {
              definition:
                "Variation of mine, formed through analogy based on the final -s of the other possessive pronouns (ours, yours, hers, theirs, his)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mien",
        "https://en.wiktionary.org/wiki/mine",
        "https://en.wiktionary.org/wiki/mines",
      ],
    },
  ],
  marry: [
    {
      word: "marry",
      phonetic: "/ˈmæɹɪ/",
      phonetics: [
        { text: "/ˈmæɹɪ/", audio: "" },
        {
          text: "/ˈmeɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/marry-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=19422116",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To enter into the conjugal or connubial state; to take a husband or a wife.",
              synonyms: [],
              antonyms: [],
              example: "Neither of her daughters showed any desire to marry.",
            },
            {
              definition:
                "(in passive) To be joined to (someone) as spouse according to law or custom.",
              synonyms: [],
              antonyms: [],
              example:
                "His daughter was married some five years ago to a tailor's apprentice.",
            },
            {
              definition:
                "To arrange for the marriage of; to give away as wife or husband.",
              synonyms: [],
              antonyms: [],
              example: "He was eager to marry his daughter to a nobleman.",
            },
            {
              definition: "To take as husband or wife.",
              synonyms: [],
              antonyms: [],
              example:
                "In some cultures, it is acceptable for an uncle to marry his niece.",
            },
            {
              definition: "To unite; to join together into a close union.",
              synonyms: [],
              antonyms: [],
              example:
                "The attempt to marry medieval plainsong with speed metal produced interesting results.",
            },
            {
              definition:
                "To unite in wedlock or matrimony; to perform the ceremony of joining spouses; to bring about a marital union according to the laws or customs of a place.",
              synonyms: [],
              antonyms: [],
              example: "A justice of the peace will marry Jones and Smith.",
            },
            {
              definition:
                "To place (two ropes) alongside each other so that they may be grasped and hauled on at the same time.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To join (two ropes) end to end so that both will pass through a block.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["divorce"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/marry"],
    },
    {
      word: "marry",
      phonetic: "/ˈmæɹɪ/",
      phonetics: [
        { text: "/ˈmæɹɪ/", audio: "" },
        {
          text: "/ˈmeɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/marry-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=19422116",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "Indeed!, in truth!; a term of asseveration.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/marry"],
    },
  ],
  juice: [
    {
      word: "juice",
      phonetic: "/d͡ʒuːs/",
      phonetics: [
        { text: "/d͡ʒuːs/", audio: "" },
        {
          text: "/d͡ʒus/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/juice-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1699731",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A liquid from a plant, especially fruit.",
              synonyms: [],
              antonyms: [],
              example: "Squeeze the orange and some juice will come out.",
            },
            {
              definition: "A beverage made of juice.",
              synonyms: [],
              antonyms: [],
              example: "I’d like two orange juices please.",
            },
            {
              definition: "Any liquid resembling juice.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A soft drink.", synonyms: [], antonyms: [] },
            { definition: "Electricity.", synonyms: [], antonyms: [] },
            { definition: "Liquor.", synonyms: [], antonyms: [] },
            { definition: "Political power.", synonyms: [], antonyms: [] },
            { definition: "Petrol; gasoline.", synonyms: [], antonyms: [] },
            { definition: "Vitality.", synonyms: [], antonyms: [] },
            {
              definition:
                "The amount charged by a bookmaker for betting services.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Steroids.", synonyms: [], antonyms: [] },
            { definition: "Semen.", synonyms: [], antonyms: [] },
            {
              definition:
                "The vaginal lubrication that a woman naturally produces when sexually aroused.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Musical agreement between instrumentalists.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["cut", "take", "vig", "vigorish"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To extract the juice from something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To energize or stimulate something.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/juice"],
    },
    {
      word: "juice",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Alternative spelling of Jew's (used in certain set phrases like juice harp)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/juice"],
    },
  ],
  raced: [
    {
      word: "raced",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/raced-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=830157",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To take part in a race (in the sense of a contest).",
              synonyms: [],
              antonyms: [],
              example: "The drivers were racing around the track.",
            },
            {
              definition: "To compete against in such a race.",
              synonyms: [],
              antonyms: [],
              example:
                "I raced him to the car, but he was there first, so he got to ride shotgun.",
            },
            {
              definition: "To move or drive at high speed; to hurry or speed.",
              synonyms: [],
              antonyms: [],
              example:
                "As soon as it was time to go home, he raced for the door.",
            },
            {
              definition:
                "Of a motor, to run rapidly when not engaged to a transmission.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To assign a race to; to perceive as having a (usually specified) race.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(social studies) Belonging to a certain race of people.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To demolish; to level to the ground.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To scrape as if with a razor.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/race",
        "https://en.wiktionary.org/wiki/raced",
        "https://en.wiktionary.org/wiki/raze",
      ],
    },
  ],
  waved: [
    {
      word: "waved",
      phonetic: "/weɪvd/",
      phonetics: [
        {
          text: "/weɪvd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/waved-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80152223",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To relinquish (a right etc.); to give up claim to; to forego.",
              synonyms: [],
              antonyms: [],
              example:
                "If you waive the right to be silent, anything you say can be used against you in a court of law.",
            },
            { definition: "To put aside, avoid.", synonyms: [], antonyms: [] },
            { definition: "To outlaw (someone).", synonyms: [], antonyms: [] },
            {
              definition: "To abandon, give up (someone or something).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To move from side to side; to sway.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To stray, wander.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To move back and forth repeatedly and somewhat loosely.",
              synonyms: [],
              antonyms: [],
              example: "The flag waved in the gentle breeze.",
            },
            {
              definition:
                "To move one’s hand back and forth (generally above the shoulders) in greeting or departure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(metonymic) To call attention to, or give a direction or command to, by a waving motion, as of the hand; to signify by waving; to beckon; to signal; to indicate.",
              synonyms: [],
              antonyms: [],
              example: "I waved goodbye from across the room.",
            },
            {
              definition: "To have an undulating or wavy form.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To raise into inequalities of surface; to give an undulating form or surface to.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To produce waves to the hair.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To swing and miss at a pitch.",
              synonyms: [],
              antonyms: [],
              example: "Jones waves at strike one.",
            },
            {
              definition: "To cause to move back and forth repeatedly.",
              synonyms: [],
              antonyms: [],
              example: "The starter waved the flag to begin the race.",
            },
            {
              definition:
                "(metonymic) To signal (someone or something) with a waving movement.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To fluctuate; to waver; to be in an unsettled state.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To move like a wave, or by floating; to waft.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having a wave-like form or outline; undulating.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Indented.", synonyms: [], antonyms: [] },
            {
              definition:
                "Having on the margin a succession of curved segments or incisions.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/waive",
        "https://en.wiktionary.org/wiki/wave",
        "https://en.wiktionary.org/wiki/waved",
      ],
    },
  ],
  goose: [
    {
      word: "goose",
      phonetic: "/ˈɡuːs/",
      phonetics: [
        {
          text: "/ˈɡuːs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/goose-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=3883903",
        },
        {
          text: "/ˈɡuːs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/goose-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=25637001",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of various grazing waterfowl of the family Anatidae, which have feathers and webbed feet and are capable of flying, swimming, and walking on land, and which are bigger than ducks.",
              synonyms: [],
              antonyms: [],
              example: "There is a flock of geese on the pond.",
            },
            {
              definition: "A female goose (sense 1).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The flesh of the goose used as food.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A silly person.", synonyms: [], antonyms: [] },
            {
              definition:
                "A tailor's iron, heated in live coals or embers, used to press fabrics.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A young woman or girlfriend.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An old English board game in which players moved counters along a board, earning a double move when they reached the picture of a goose.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["goose iron"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To sharply poke or pinch someone's buttocks. Derived from a goose's inclination to bite at a retreating intruder's hindquarters.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To stimulate, to spur.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To gently accelerate an automobile or machine, or give repeated small taps on the accelerator.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(UK slang) Of private-hire taxi drivers, to pick up a passenger who has not pre-booked a cab. This is unauthorised under UK licensing conditions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To hiss (a performer) off the stage.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/goose"],
    },
  ],
  trust: [
    {
      word: "trust",
      phonetic: "/trʊst/",
      phonetics: [
        { text: "/trʊst/", audio: "" },
        {
          text: "/trʌst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/trust-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1224266",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Confidence in or reliance on some person or quality.",
              synonyms: [],
              antonyms: [],
              example:
                "He needs to regain her trust if he is ever going to win her back.",
            },
            {
              definition: "Dependence upon something in the future; hope.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Confidence in the future payment for goods or services supplied; credit.",
              synonyms: [],
              antonyms: [],
              example:
                "I was out of cash, but the landlady let me have it on trust.",
            },
            {
              definition:
                "That which is committed or entrusted; something received in confidence; a charge.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That upon which confidence is reposed; ground of reliance; hope.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Trustworthiness, reliability.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The condition or obligation of one to whom anything is confided; responsible charge or office.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The confidence vested in a person who has legal ownership of a property to manage for the benefit of another.",
              synonyms: [],
              antonyms: [],
              example: "I put the house into my sister's trust.",
            },
            {
              definition:
                "An estate devised or granted in confidence that the devisee or grantee shall convey it, or dispose of the profits, at the will, or for the benefit, of another; an estate held for the use of another.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A group of businessmen or traders organised for mutual benefit to produce and distribute specific commodities or services, and managed by a central body of trustees.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Affirmation of the access rights of a user of a computer system.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["belief", "confidence", "expectation", "faith", "hope"],
          antonyms: ["distrust", "mistrust", "untrust", "wantrust"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To place confidence in; to rely on, to confide, or have faith, in.",
              synonyms: [],
              antonyms: [],
              example: "We cannot trust anyone who deceives us.",
            },
            {
              definition: "To give credence to; to believe; to credit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To hope confidently; to believe (usually with a phrase or infinitive clause as the object)",
              synonyms: [],
              antonyms: [],
              example: "I trust you have cleaned your room?",
            },
            {
              definition:
                "To show confidence in a person by entrusting them with something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To commit, as to one's care; to entrust.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To give credit to; to sell to upon credit, or in confidence of future payment.",
              synonyms: [],
              antonyms: [],
              example:
                "Merchants and manufacturers trust their customers annually with goods.",
            },
            {
              definition:
                "(followed by to) To rely on (something), as though having trust (on it).",
              synonyms: [],
              antonyms: [],
              example:
                "Having lost the book, he had to trust to his memory for further details.",
            },
            {
              definition: "To risk; to venture confidently.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To have trust; to be credulous; to be won to confidence; to confide.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To sell or deliver anything in reliance upon a promise of payment; to give credit.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["distrust", "mistrust"],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Secure, safe.", synonyms: [], antonyms: [] },
            { definition: "Faithful, dependable.", synonyms: [], antonyms: [] },
            {
              definition: "Of or relating to a trust.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/trust"],
    },
  ],
  fewer: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  favor: [
    {
      word: "favor",
      phonetic: "/ˈfeɪvə/",
      phonetics: [
        { text: "/ˈfeɪvə/", audio: "" },
        {
          text: "/ˈfeɪvɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/favor-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1661650",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A kind or helpful deed; an instance of voluntarily assisting (someone).",
              synonyms: [],
              antonyms: [],
              example:
                "He did me a favor when he took the time to drive me home.",
            },
            {
              definition: "Goodwill; benevolent regard.",
              synonyms: [],
              antonyms: [],
              example: "She enjoyed the queen's favor.",
            },
            {
              definition: "A small gift; a party favor.",
              synonyms: [],
              antonyms: [],
              example:
                "A marriage favour is a bunch or knot of white ribbons or white flowers worn at a wedding.",
            },
            {
              definition: "Mildness or mitigation of punishment; lenity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The object of regard; person or thing favoured.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Appearance; look; countenance; face.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Partiality; bias.", synonyms: [], antonyms: [] },
            {
              definition: "A letter, a written communication.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Anything worn publicly as a pledge of a woman's favor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(in the plural) Lovelocks.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["aid", "help", "lend a hand", "token"],
          antonyms: [
            "discriminate",
            "discrimination",
            "disfavor",
            "harm",
            "sabotage",
          ],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To look upon fondly; to prefer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To encourage, conduce to",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To do a favor [noun sense 1] for; to show beneficence toward.",
              synonyms: [],
              antonyms: [],
              example: "Would you favor us with a poetry reading?",
            },
            {
              definition: "To treat with care.",
              synonyms: [],
              antonyms: [],
              example: "Favoring your sore leg will only injure the other one.",
            },
            {
              definition:
                "(including) To resemble, to look like (another person).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["discriminate", "disfavor"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/favor"],
    },
  ],
  mills: [
    {
      word: "mills",
      phonetic: "/mɪlz/",
      phonetics: [
        {
          text: "/mɪlz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mills-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651178",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A grinding apparatus for substances such as grains, seeds, etc.",
              synonyms: [],
              antonyms: [],
              example:
                "Pepper has a stronger flavor when it is ground straight from a mill.",
            },
            {
              definition: "The building housing such a grinding apparatus.",
              synonyms: [],
              antonyms: [],
              example: "My grandfather worked in a mill.",
            },
            {
              definition:
                "A machine used for expelling the juice, sap, etc., from vegetable tissues by pressure, or by pressure in combination with a grinding, or cutting process.",
              synonyms: [],
              antonyms: [],
              example: "a cider mill; a cane mill",
            },
            {
              definition: "A machine for grinding and polishing.",
              synonyms: [],
              antonyms: [],
              example: "a lapidary mill",
            },
            {
              definition:
                "The raised or ridged edge or surface made in milling anything, such as a coin or screw.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A manufacturing plant for paper, steel, textiles, etc.",
              synonyms: [],
              antonyms: [],
              example: "a steel mill",
            },
            {
              definition: "A building housing such a plant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An establishment that handles a certain type of situation or procedure routinely, or produces large quantities of an item without much regard to quality, such as a divorce mill, a puppy mill, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An institution awarding educational certificates not officially recognised",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An engine.", synonyms: [], antonyms: [] },
            {
              definition: "A boxing match, fistfight.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(die sinking) A hardened steel roller with a design in relief, used for imprinting a reversed copy of the design in a softer metal, such as copper.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An excavation in rock, transverse to the workings, from which material for filling is obtained.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A passage underground through which ore is shot.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A milling cutter.", synonyms: [], antonyms: [] },
            { definition: "A treadmill.", synonyms: [], antonyms: [] },
            {
              definition: "A typewriter used to transcribe messages received.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["factory", "works"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To grind or otherwise process in a mill or other machine.",
              synonyms: [],
              antonyms: [],
              example: "to mill flour",
            },
            {
              definition: "To shape, polish, dress or finish using a machine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To engrave one or more grooves or a pattern around the edge of (a cylindrical object such as a coin).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(followed by around, about, etc.) To move about in an aimless fashion.",
              synonyms: [],
              antonyms: [],
              example:
                "I didn't have much to do, so I just milled around the town looking at the shops.",
            },
            {
              definition: "To cause to mill, or circle around.",
              synonyms: [],
              antonyms: [],
              example: "to mill cattle",
            },
            {
              definition: "(of air-breathing creatures) To swim underwater.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of a whale) To swim suddenly in a new direction.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To beat; to pound.", synonyms: [], antonyms: [] },
            {
              definition: "To pass through a fulling mill; to full, as cloth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To roll (steel, etc.) into bars.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make (drinking chocolate) frothy, as by churning.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To undergo hulling.",
              synonyms: [],
              antonyms: [],
              example: "This maize mills well.",
            },
            {
              definition: "To take part in a fistfight; to box.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To fill (a winze or interior incline) with broken ore, to be drawn out at the bottom.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(thieves' cant) To commit burglary.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["roam", "wander"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An obsolete coin worth one thousandth of a US dollar, or one tenth of a cent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One thousandth part, particularly in millage rates of property tax.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["permille", "‰", "₥"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A line of three matching pieces in nine men's morris and related games.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(trading card games) Discarding a card from one's deck.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(trading card games) A strategy centered on depleting the opponent's deck.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(trading card games) To move (a card) from a deck to the discard pile.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Hearthstone) To destroy (a card) due to having a full hand.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["burn"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mill",
        "https://en.wiktionary.org/wiki/mills",
      ],
    },
  ],
  views: [
    {
      word: "views",
      phonetic: "/vjuːz/",
      phonetics: [
        {
          text: "/vjuːz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/views-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1676892",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(physical) Visual perception.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A picture, drawn or painted; a sketch.",
              synonyms: [],
              antonyms: [],
              example: "a fine view of Lake George",
            },
            {
              definition: "(psychological) Opinion, judgement, imagination.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A virtual or logical table composed of the result set of a query in relational databases.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The part of a computer program which is visible to the user and can be interacted with",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A wake.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: ["controller", "model"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To look at.",
              synonyms: [],
              antonyms: [],
              example: "The video was viewed by millions of people.",
            },
            {
              definition: "To regard in a stated way.",
              synonyms: [],
              antonyms: [],
              example: "I view it as a serious breach of trust.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/view",
        "https://en.wiktionary.org/wiki/views",
      ],
    },
  ],
  joint: [
    {
      word: "joint",
      phonetic: "/dʒɔɪnt/",
      phonetics: [
        {
          text: "/dʒɔɪnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/joint-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241341",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The point where two components of a structure join, but are still able to rotate.",
              synonyms: ["hinge", "pivot"],
              antonyms: [],
              example:
                "This rod is free to swing at the joint with the platform.",
            },
            {
              definition:
                "The point where two components of a structure join rigidly.",
              synonyms: [],
              antonyms: [],
              example:
                "The water is leaking out of the joint between the two pipes.",
            },
            {
              definition:
                "Any part of the body where two bones join, in most cases allowing that part of the body to be bent or straightened.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The means of securing together the meeting surfaces of components of a structure.",
              synonyms: [],
              antonyms: [],
              example:
                "The dovetail joint, while more difficult to make, is also quite strong.",
            },
            {
              definition: "A cut of meat.",
              synonyms: [],
              antonyms: [],
              example:
                "Set the joint in a roasting tin and roast for the calculated cooking time.",
            },
            {
              definition:
                "The part or space included between two joints, knots, nodes, or articulations.",
              synonyms: [],
              antonyms: [],
              example: "a joint of cane or of a grass stem; a joint of the leg",
            },
            {
              definition:
                "A fracture in which the strata are not offset; a geologic joint.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(somewhat derogatory) A place of business, particularly in the food service or hospitality industries.",
              synonyms: [],
              antonyms: [],
              example:
                "It was the kind of joint you wouldn't want your boss to see you in.",
            },
            {
              definition: "(with the definite article) Prison.",
              synonyms: [],
              antonyms: [],
              example: "I'm just trying to stay out of the joint.",
            },
            {
              definition: "A marijuana cigarette.",
              synonyms: [],
              antonyms: [],
              example:
                "After locking the door and closing the shades, they lit the joint.",
            },
            {
              definition: "A syringe used to inject an illicit drug.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The penis.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["hinge", "pivot"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To unite by a joint or joints; to fit together; to prepare so as to fit together",
              synonyms: [],
              antonyms: [],
              example: "a jointing plane",
            },
            {
              definition: "To join; to connect; to unite; to combine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To provide with a joint or joints; to articulate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To separate the joints; of; to divide at the joint or joints; to disjoint; to cut up into joints, as meat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fit as if by joints; to coalesce as joints do.",
              synonyms: [],
              antonyms: [],
              example: "the stones joint, neatly.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Done by two or more people or organisations working together.",
              synonyms: ["mutual", "shared"],
              antonyms: [],
              example:
                "The play was a joint production between the two companies.",
            },
          ],
          synonyms: ["mutual", "shared"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/joint"],
    },
  ],
  eager: [
    {
      word: "eager",
      phonetic: "/ˈiːɡə/",
      phonetics: [
        { text: "/ˈiːɡə/", audio: "" },
        {
          text: "/ˈiɡɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/eager-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1676748",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Sharp; sour; acid.", synonyms: [], antonyms: [] },
            {
              definition: "Sharp; keen; bitter; severe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Desirous; keen to do or obtain something.",
              synonyms: [],
              antonyms: [],
              example:
                "I was eager to show my teacher how much I'd learned over the holidays.",
            },
            {
              definition: "Brittle; inflexible; not ductile.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Not employing lazy evaluation; calculating results immediately, rather than deferring calculation until they are required.",
              synonyms: [],
              antonyms: [],
              example: "an eager algorithm",
            },
          ],
          synonyms: ["raring"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/eager"],
    },
    {
      word: "eager",
      phonetic: "/ˈiːɡə/",
      phonetics: [
        { text: "/ˈiːɡə/", audio: "" },
        {
          text: "/ˈiɡɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/eager-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1676748",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A tidal bore", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/eager",
        "https://en.wiktionary.org/wiki/eagre",
      ],
    },
  ],
  spots: [
    {
      word: "spots",
      phonetic: "/spɒts/",
      phonetics: [
        { text: "/spɒts/", audio: "" },
        {
          text: "/spɑts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spots-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2454302",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A round or irregular patch on the surface of a thing having a different color, texture etc. and generally round in shape.",
              synonyms: [],
              antonyms: [],
              example:
                "The leopard is noted for the spots of color in its fur.",
            },
            {
              definition: "A stain or disfiguring mark.",
              synonyms: [],
              antonyms: [],
              example:
                "I have tried everything, and I can’t get this spot out.",
            },
            {
              definition: "A pimple, papule or pustule.",
              synonyms: [],
              antonyms: [],
              example: "I think she's got chicken pox; she's covered in spots.",
            },
            {
              definition: "A small, unspecified amount or quantity.",
              synonyms: [],
              antonyms: [],
              example:
                "Would you like to come round on Sunday for a spot of lunch?",
            },
            {
              definition:
                "A bill of five-dollar or ten-dollar denomination in dollars.",
              synonyms: [],
              antonyms: [],
              example:
                "Here's the twenty bucks I owe you, a ten spot and two five spots.",
            },
            {
              definition: "A location or area.",
              synonyms: [],
              antonyms: [],
              example:
                "For our anniversary we went back to the same spot where we first met.",
            },
            { definition: "A parking space.", synonyms: [], antonyms: [] },
            {
              definition: "An official determination of placement.",
              synonyms: [],
              antonyms: [],
              example:
                "The fans were very unhappy with the referee's spot of the ball.",
            },
            {
              definition: "A bright lamp; a spotlight.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A brief advertisement or program segment on television.",
              synonyms: [],
              antonyms: [],
              example:
                "Did you see the spot on the news about the shoelace factory?",
            },
            {
              definition: "Difficult situation; predicament.",
              synonyms: [],
              antonyms: [],
              example:
                "She was in a real spot when she ran into her separated husband while on a date.",
            },
            {
              definition:
                "One who spots (supports or assists a maneuver, or is prepared to assist if safety dictates); a spotter.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Penalty spot.", synonyms: [], antonyms: [] },
            {
              definition: "The act of spotting or noticing something.",
              synonyms: [],
              antonyms: [],
              example: "- Whoops. Good spot.",
            },
            {
              definition:
                "A variety of the common domestic pigeon, so called from a spot on its head just above the beak.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A food fish (Leiostomus xanthurus) of the Atlantic coast of the United States, with a black spot behind the shoulders and fifteen oblique dark bars on the sides.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The southern redfish, or red horse (Sciaenops ocellatus), which has a spot on each side at the base of the tail.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in the plural, brokers' slang) Commodities, such as merchandise and cotton, sold for immediate delivery.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An autosoliton.", synonyms: [], antonyms: [] },
            {
              definition: "A decimal point; point.",
              synonyms: [],
              antonyms: [],
              example: "Twelve spot two five pounds sterling. (ie. £12.25)",
            },
            {
              definition:
                "Any of various points marked on the table, from which balls are played, in snooker, pool, billiards, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of the balls marked with spots in the game of pool, which one player aims to pot, the other player taking the stripes.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To see, find; to pick out, notice, locate, distinguish or identify.",
              synonyms: [],
              antonyms: [],
              example:
                "Try to spot the differences between these two pictures.",
            },
            {
              definition: "To loan a small amount of money to someone.",
              synonyms: [],
              antonyms: [],
              example: "I’ll spot you ten dollars for lunch.",
            },
            {
              definition: "To stain; to leave a spot (on).",
              synonyms: [],
              antonyms: [],
              example: "Hard water will spot if it is left on a surface.",
            },
            {
              definition: "To remove, or attempt to remove, a stain.",
              synonyms: [],
              antonyms: [],
              example:
                "I spotted the carpet where the child dropped spaghetti.",
            },
            {
              definition:
                "To retouch a photograph on film to remove minor flaws.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To support or assist a maneuver, or to be prepared to assist if safety dictates.",
              synonyms: [],
              antonyms: [],
              example: "I can’t do a back handspring unless somebody spots me.",
            },
            {
              definition:
                "To keep the head and eyes pointing in a single direction while turning.",
              synonyms: [],
              antonyms: [],
              example:
                "Most figure skaters do not spot their turns like dancers do.",
            },
            {
              definition:
                "To stain; to blemish; to taint; to disgrace; to tarnish, as reputation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cut or chip (timber) in preparation for hewing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To place an object at a location indicated by a spot. Notably in billiards or snooker.",
              synonyms: [],
              antonyms: [],
              example: "The referee had to spot the pink on the blue spot.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/spot",
        "https://en.wiktionary.org/wiki/spots",
      ],
    },
  ],
  blend: [
    {
      word: "blend",
      phonetic: "/blɛnd/",
      phonetics: [
        {
          text: "/blɛnd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/blend-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769617",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A mixture of two or more things.",
              synonyms: [],
              antonyms: [],
              example:
                "Our department has a good blend of experienced workers and young promise.",
            },
            {
              definition:
                "A word formed by combining two other words; a grammatical contamination, portmanteau word.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "combination",
            "mix",
            "mixture",
            "frankenword",
            "portmanteau",
            "portmanteau word",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To mingle; to mix; to unite intimately; to pass or shade insensibly into each other.",
              synonyms: [],
              antonyms: [],
              example:
                "To make hummus you need to blend chickpeas, olive oil, lemon juice and garlic.",
            },
            {
              definition: "To be mingled or mixed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To pollute by mixture or association; to spoil or corrupt; to blot; to stain.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/blend"],
    },
  ],
  rings: [
    {
      word: "rings",
      phonetic: "/ɹɪŋz/",
      phonetics: [
        {
          text: "/ɹɪŋz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rings-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2707593",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(physical) A solid object in the shape of a circle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(physical) A group of objects arranged in a circle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A piece of food in the shape of a ring.",
              synonyms: [],
              antonyms: [],
              example: "onion rings",
            },
            {
              definition:
                "A place where some sports or exhibitions take place; notably a circular or comparable arena, such as a boxing ring or a circus ring; hence the field of a political contest.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An exclusive group of people, usually involving some unethical or illegal practices.",
              synonyms: [],
              antonyms: [],
              example:
                "a crime ring; a prostitution ring; a bidding ring (at an auction sale)",
            },
            {
              definition:
                "A group of atoms linked by bonds to form a closed chain in a molecule.",
              synonyms: [],
              antonyms: [],
              example: "a benzene ring",
            },
            {
              definition:
                "A planar geometrical figure included between two concentric circles.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A diacritical mark in the shape of a hollow circle placed above or under the letter; a kroužek.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An old English measure of corn equal to the coomb or half a quarter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hierarchical level of privilege in a computer system, usually at hardware level, used to protect data and functionality (also protection ring).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Either of the pair of clamps used to hold a telescopic sight to a rifle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The twenty-fifth Lenormand card.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To enclose or surround.",
              synonyms: [],
              antonyms: [],
              example: "The inner city was ringed with dingy industrial areas.",
            },
            {
              definition: "To make an incision around; to girdle.",
              synonyms: [],
              antonyms: [],
              example:
                "They ringed the trees to make the clearing easier next year.",
            },
            {
              definition: "To attach a ring to, especially for identification.",
              synonyms: [],
              antonyms: [],
              example: "We managed to ring 22 birds this morning.",
            },
            {
              definition:
                "To surround or fit with a ring, or as if with a ring.",
              synonyms: [],
              antonyms: [],
              example: "to ring a pig’s snout",
            },
            {
              definition: "To rise in the air spirally.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To steal and change the identity of (cars) in order to resell them.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The resonant sound of a bell, or a sound resembling it.",
              synonyms: [],
              antonyms: [],
              example:
                "The church bell's ring could be heard the length of the valley.",
            },
            {
              definition: "A pleasant or correct sound.",
              synonyms: [],
              antonyms: [],
              example: "The name has a nice ring to it.",
            },
            {
              definition:
                "A sound or appearance that is characteristic of something.",
              synonyms: [],
              antonyms: [],
              example: "Her statements in court had a ring of falsehood.",
            },
            {
              definition: "A telephone call.",
              synonyms: [],
              antonyms: [],
              example: "I’ll give you a ring when the plane lands.",
            },
            {
              definition:
                "Any loud sound; the sound of numerous voices; a sound continued, repeated, or reverberated.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A chime, or set of bells harmonically tuned.",
              synonyms: [],
              antonyms: [],
              example: "St Mary's has a ring of eight bells.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "Of a bell, etc., to produce a resonant sound.",
              synonyms: [],
              antonyms: [],
              example: "The bells were ringing in the town.",
            },
            {
              definition: "To make (a bell, etc.) produce a resonant sound.",
              synonyms: [],
              antonyms: [],
              example:
                "The deliveryman rang the doorbell to drop off a parcel.",
            },
            {
              definition: "To produce (a sound) by ringing.",
              synonyms: [],
              antonyms: [],
              example: "They rang a Christmas carol on their handbells.",
            },
            {
              definition: "To produce the sound of a bell or a similar sound.",
              synonyms: [],
              antonyms: [],
              example: "Whose mobile phone is ringing?",
            },
            {
              definition:
                "Of something spoken or written, to appear to be, to seem, to sound.",
              synonyms: [],
              antonyms: [],
              example: "That does not ring true.",
            },
            {
              definition: "To telephone (someone).",
              synonyms: [],
              antonyms: [],
              example: "I will ring you when we arrive.",
            },
            {
              definition: "To resound, reverberate, echo.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To produce music with bells.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To repeat often, loudly, or earnestly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An algebraic structure which consists of a set with two binary operations: an additive operation and a multiplicative operation, such that the set is an abelian group under the additive operation, a monoid under the multiplicative operation, and such that the multiplicative operation is distributive with respect to the additive operation.",
              synonyms: [],
              antonyms: [],
              example:
                "The set of integers, \\mathbb{Z}, is the prototypical ring.",
            },
            {
              definition:
                "An algebraic structure as above, but only required to be a semigroup under the multiplicative operation, that is, there need not be a multiplicative identity element.",
              synonyms: [],
              antonyms: [],
              example:
                "The definition of ring without unity allows, for instance, the set 2\\mathbb{Z} of even integers to be a ring.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A gymnastics apparatus and discipline consisting of 2 rings suspended from a bar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A traditional Irish game of throwing rings onto hooks.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["still rings"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ring",
        "https://en.wiktionary.org/wiki/rings",
      ],
    },
  ],
  adult: [
    {
      word: "adult",
      phonetic: "/əˈdʌlt/",
      phonetics: [
        {
          text: "/əˈdʌlt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/adult-ca.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=51307332",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
        { text: "/ˈæd.əlt/", audio: "" },
        {
          text: "/əˈdʌlt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/adult-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762858",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A fully grown human or animal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person who has reached the legal age of majority.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["grown up"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To (cause to) be or become an adult.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To behave like an adult.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Fully grown.",
              synonyms: [],
              antonyms: [],
              example: "an adult human, animal, or plant",
            },
            {
              definition:
                "Intended for or restricted to adults rather than children.",
              synonyms: [],
              antonyms: [],
              example: "adult clothes",
            },
            {
              definition:
                "Containing material of an explicit sexual nature; of, or pertaining to, pornography.",
              synonyms: [],
              antonyms: [],
              example:
                "This program contains adult content. Parental discretion is advised.",
            },
            { definition: "Vulgar or profane.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "X-rated",
            "XXX",
            "XXXX",
            "pornographic",
            "big",
            "fully grown",
            "grown up",
            "grown up",
            "blue",
            "indecent",
            "obscene",
            "salacious",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/adult"],
    },
  ],
  index: [
    {
      word: "index",
      phonetic: "/ˈɪndɛks/",
      phonetics: [
        {
          text: "/ˈɪndɛks/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/index-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453542",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An alphabetical listing of items and their location.",
              synonyms: [],
              antonyms: [],
              example:
                "The index of a book lists words or expressions and the pages of the book upon which they are to be found.",
            },
            {
              definition: "The index finger; the forefinger.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A movable finger on a gauge, scale, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A symbol resembling a pointing hand, used to direct particular attention to a note or paragraph.",
              synonyms: ["manicule"],
              antonyms: [],
            },
            {
              definition:
                "That which points out; that which shows, indicates, manifests, or discloses.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sign; an indication; a token.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A type of noun where the meaning of the form changes with respect to the context. E.g., 'Today's newspaper' is an indexical form since its referent will differ depending on the context. See also icon and symbol.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A single number calculated from an array of prices or of quantities.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A number representing a property or ratio, a coefficient.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A raised suffix indicating a power.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An integer or other key indicating the location of data e.g. within an array, vector, database table, associative array, or hash table.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A data structure that improves the performance of operations on a table.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A prologue indicating what follows.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["manicule"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To arrange an index for something, especially a long text.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To inventory, to take stock.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To normalise in order to account for inflation; to correct for inflation by linking to a price index in order to maintain real levels.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be indexical for (some situation or state of affairs); to indicate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To access a value in a data container by an index.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/index"],
    },
  ],
  nails: [
    {
      word: "nails",
      phonetic: "/neɪlz/",
      phonetics: [
        {
          text: "/neɪlz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/nails-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651202",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The thin, horny plate at the ends of fingers and toes on humans and some other animals.",
              synonyms: [],
              antonyms: [],
              example: "When I'm nervous I bite my nails.",
            },
            {
              definition:
                "The basal thickened portion of the anterior wings of certain hemiptera.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The terminal horny plate on the beak of ducks, and other allied birds.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The claw of a bird or other animal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A spike-shaped metal fastener used for joining wood or similar materials. The nail is generally driven through two or more layers of material by means of impacts from a hammer or other device. It is then held in place by friction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A round pedestal on which merchants once carried out their business, such as the four nails outside The Exchange, Bristol.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An archaic English unit of length equivalent to 1/20 of an ell or 1/16 of a yard (2 1/4 inches or 5.715 cm).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To fix (an object) to another object using a nail.",
              synonyms: ["pin", "rivet", "screw"],
              antonyms: [],
              example: "He nailed the placard to the post.",
            },
            {
              definition: "To drive a nail.",
              synonyms: ["hammer"],
              antonyms: [],
              example: "He used the ax head for nailing.",
            },
            {
              definition: "To stud or boss with nails, or as if with nails.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To catch.",
              synonyms: ["arrest", "collar", "nick"],
              antonyms: [],
            },
            { definition: "To expose as a sham.", synonyms: [], antonyms: [] },
            {
              definition: "To accomplish (a task) completely and successfully.",
              synonyms: ["ace", "fulfill", "fullbring"],
              antonyms: [],
              example: "I really nailed that test.",
            },
            {
              definition: "To hit (a target) effectively with some weapon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of a male, to engage in sexual intercourse with.",
              synonyms: ["dick", "pound", "rail", "screw"],
              antonyms: [],
            },
            {
              definition: "To spike, as a cannon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To nail down: to make certain, or confirm.",
              synonyms: ["clinch", "fix", "lock down", "pin down"],
              antonyms: [],
            },
          ],
          synonyms: [
            "ace",
            "fulfill",
            "fullbring",
            "arrest",
            "collar",
            "nick",
            "clinch",
            "fix",
            "lock down",
            "pin down",
            "dick",
            "pound",
            "rail",
            "screw",
            "hammer",
            "pin",
            "rivet",
            "screw",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/nail",
        "https://en.wiktionary.org/wiki/nails",
      ],
    },
  ],
  horns: [
    {
      word: "horns",
      phonetic: "/hɔːnz/",
      phonetics: [
        { text: "/hɔːnz/", audio: "" },
        {
          text: "/hɔɹnz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/horns-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2454089",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A hard growth of keratin that protrudes from the top of the head of certain animals, usually paired.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any similar real or imaginary growth or projection such as the elongated tusk of a narwhal, the eyestalk of a snail, the pointed growth on the nose of a rhinoceros, or the hornlike projection on the head of a demon or similar.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An antler.", synonyms: [], antonyms: [] },
            {
              definition:
                "The hard substance from which animals' horns are made, sometimes used by man as a material for making various objects.",
              synonyms: ["keratin"],
              antonyms: [],
              example: "an umbrella with a handle made of horn",
            },
            {
              definition:
                "An object whose shape resembles a horn, such as cornucopia, the point of an anvil, or a vessel for gunpowder or liquid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any of several musical wind instruments.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An instrument resembling a musical horn and used to signal others.",
              synonyms: [],
              antonyms: [],
              example: "hunting horn",
            },
            {
              definition: "A loud alarm, especially one on a motor vehicle.",
              synonyms: ["hooter", "klaxon"],
              antonyms: [],
            },
            {
              definition: "A sound signaling the expiration of time.",
              synonyms: [],
              antonyms: [],
              example:
                "The shot was after the horn and therefore did not count.",
            },
            {
              definition: "A conical device used to direct waves.",
              synonyms: ["funnel"],
              antonyms: [],
              example: "antenna horn",
            },
            {
              definition: "Generally, any brass wind instrument.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(from the horn-shaped earpieces of old communication systems that used air tubes) A telephone.",
              synonyms: [
                "blower (UK)",
                "dog and bone (Cockney rhyming slang)",
                "phone",
              ],
              antonyms: [],
              example:
                "Get him on the horn so that we can have a discussion about this.",
            },
            {
              definition: "(definite article) An erection of the penis.",
              synonyms: ["boner (US)", "hard-on", "stiffy"],
              antonyms: [],
            },
            {
              definition: "A peninsula or crescent-shaped tract of land.",
              synonyms: ["peninsula"],
              antonyms: [],
              example: "to navigate around the horn",
            },
            {
              definition:
                "A diacritical mark that may be attached to the top right corner of the letters o and u when writing in Vietnamese, thus forming ơ and ư.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An incurved, tapering and pointed appendage found in the flowers of the milkweed (Asclepias).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "blower (UK)",
            "dog and bone (Cockney rhyming slang)",
            "phone",
            "boner (US)",
            "hard-on",
            "stiffy",
            "funnel",
            "hooter",
            "klaxon",
            "keratin",
            "peninsula",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(of an animal) To assault with the horns.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To furnish with horns.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To cuckold.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/horn",
        "https://en.wiktionary.org/wiki/horns",
      ],
    },
  ],
  balls: [
    {
      word: "balls",
      phonetic: "/bɔːlz/",
      phonetics: [
        {
          text: "/bɔːlz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/balls-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1350437",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
        {
          text: "/bɔːlz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/balls-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453857",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A solid or hollow sphere, or roughly spherical mass.",
              synonyms: [],
              antonyms: [],
              example: "a ball of spittle; a fecal ball",
            },
            {
              definition: "A round or ellipsoidal object.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(mildly, usually in the plural) A testicle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A leather-covered cushion, fastened to a handle called a ballstock; formerly used by printers for inking the form, then superseded by the roller.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A large pill, a form in which medicine was given to horses; a bolus.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["chutzpah", "guts", "nerve", "globe", "sphere"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To form or wind into a ball.",
              synonyms: [],
              antonyms: [],
              example: "to ball cotton",
            },
            {
              definition:
                "To heat in a furnace and form into balls for rolling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To have sexual intercourse with.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To gather balls which cling to the feet, as of damp snow or clay; to gather into balls.",
              synonyms: [],
              antonyms: [],
              example: "The horse balls; the snow balls.",
            },
            {
              definition: "(usually in present participle) To be hip or cool.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To play basketball.", synonyms: [], antonyms: [] },
            {
              definition: "To punish by affixing a ball and chain",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["roll", "wad", "bonk", "fuck", "lay", "screw", "shag"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A formal dance.", synonyms: [], antonyms: [] },
            {
              definition: "A very enjoyable time.",
              synonyms: [],
              antonyms: [],
              example: "I had a ball at that concert.",
            },
          ],
          synonyms: ["blast", "whale of a time"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "Speaking or acting with bravado to achieve (something)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To engage in sexual intercourse.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Very. Intensifier.",
              synonyms: [],
              antonyms: [],
              example: "It is balls cold out there.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ball",
        "https://en.wiktionary.org/wiki/balls",
      ],
    },
  ],
  flame: [
    {
      word: "flame",
      phonetic: "/fleɪm/",
      phonetics: [
        {
          text: "/fleɪm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/flame-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=857043",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The visible part of fire; a stream of burning vapour or gas, emitting light and heat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A romantic partner or lover in a usually short-lived but passionate affair.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Intentionally insulting criticism or remark meant to incite anger.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A brilliant reddish orange-gold fiery colour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The contrasting light and dark figure seen in wood used for stringed instrument making; the curl.",
              synonyms: [],
              antonyms: [],
              example:
                "The cello has a two-piece back with a beautiful narrow flame.",
            },
            {
              definition:
                "Burning zeal, passion, imagination, excitement, or anger.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To produce flames; to burn with a flame or blaze.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To burst forth like flame; to break out in violence of passion; to be kindled with zeal or ardour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To post a destructively critical or abusive message (to somebody).",
              synonyms: [],
              antonyms: [],
              example: "I flamed him for spamming in my favourite newsgroup.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Of a brilliant reddish orange-gold colour, like that of a flame.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/flame"],
    },
  ],
  rates: [
    {
      word: "rates",
      phonetic: "/ɹeɪts/",
      phonetics: [
        {
          text: "/ɹeɪts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rates-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651369",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The worth of something; value.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The proportional relationship between one amount, value etc. and another.",
              synonyms: [],
              antonyms: [],
              example:
                "At the height of his powers, he was producing pictures at the rate of four a year.",
            },
            {
              definition: "Speed.",
              synonyms: [],
              antonyms: [],
              example: "The car was speeding down here at a hell of a rate.",
            },
            {
              definition: "The relative speed of change or progress.",
              synonyms: [],
              antonyms: [],
              example: "The rate of production at the factory is skyrocketing.",
            },
            {
              definition: "The price of (an individual) thing; cost.",
              synonyms: [],
              antonyms: [],
              example: "He asked quite a rate to take me to the airport.",
            },
            {
              definition:
                "A set price or charge for all examples of a given case, commodity, service etc.",
              synonyms: [],
              antonyms: [],
              example: "Postal rates here are low.",
            },
            {
              definition: "A wage calculated in relation to a unit of time.",
              synonyms: [],
              antonyms: [],
              example:
                "We pay an hourly rate of between $10 – $15 per hour depending on qualifications and experience.",
            },
            {
              definition:
                "Any of various taxes, especially those levied by a local authority.",
              synonyms: [],
              antonyms: [],
              example:
                "I hardly have enough left every month to pay the rates.",
            },
            {
              definition:
                "A class into which ships were assigned based on condition, size etc.; by extension, rank.",
              synonyms: [],
              antonyms: [],
              example: "This textbook is first-rate.",
            },
            {
              definition:
                "Established portion or measure; fixed allowance; ration.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Order; arrangement.", synonyms: [], antonyms: [] },
            {
              definition: "Ratification; approval.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The gain or loss of a timepiece in a unit of time.",
              synonyms: [],
              antonyms: [],
              example: "daily rate; hourly rate; etc.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To assign or be assigned a particular rank or level.",
              synonyms: [],
              antonyms: [],
              example: "She is rated fourth in the country.",
            },
            {
              definition: "To evaluate or estimate the value of.",
              synonyms: [],
              antonyms: [],
              example: "They rate his talents highly.",
            },
            {
              definition: "To consider or regard.",
              synonyms: [],
              antonyms: [],
              example: "He rated this book brilliant.",
            },
            {
              definition: "To deserve; to be worth.",
              synonyms: [],
              antonyms: [],
              example:
                "The view here hardly rates a mention in the travel guide.",
            },
            {
              definition:
                "To determine the limits of safe functioning for a machine or electrical device.",
              synonyms: [],
              antonyms: [],
              example: "The transformer is rated at 10 watts.",
            },
            {
              definition:
                "To evaluate a property's value for the purposes of local taxation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To like; to think highly of.",
              synonyms: [],
              antonyms: [],
              example: "The customers don't rate the new burgers.",
            },
            {
              definition: "To have position (in a certain class).",
              synonyms: [],
              antonyms: [],
              example: "He rates as the best cyclist in the country.",
            },
            {
              definition: "To have value or standing.",
              synonyms: [],
              antonyms: [],
              example:
                "This last performance of hers didn't rate very high with the judges.",
            },
            { definition: "To ratify.", synonyms: [], antonyms: [] },
            {
              definition:
                "To ascertain the exact rate of the gain or loss of (a chronometer) as compared with true time.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["rank"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To berate, scold.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(British, New Zealand, or Australian) Taxes, usually on property, levied by local government.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rate",
        "https://en.wiktionary.org/wiki/rates",
      ],
    },
  ],
  drill: [
    {
      word: "drill",
      phonetic: "/dɹɪl/",
      phonetics: [
        {
          text: "/dɹɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/drill-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762921",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A tool used to remove material so as to create a hole, typically by plunging a rotating cutting bit into a stationary workpiece.",
              synonyms: [],
              antonyms: [],
              example: "Wear safety glasses when operating an electric drill.",
            },
            {
              definition: "The portion of a drilling tool that drives the bit.",
              synonyms: [],
              antonyms: [],
              example:
                "Use a drill with a wire brush to remove any rust or buildup.",
            },
            {
              definition:
                "An activity done as an exercise or practice (especially a military exercise), particularly in preparation for some possible future event or occurrence.",
              synonyms: [],
              antonyms: [],
              example:
                "Regular fire drills can ensure that everyone knows how to exit safely in an emergency.",
            },
            {
              definition:
                "Any of several molluscs, of the genus Urosalpinx, especially the oyster drill (Urosalpinx cinerea), that drill holes in the shells of other animals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A style of trap music with gritty, violent lyrics, originating on the South Side of Chicago.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To create (a hole) by removing material with a drill (tool).",
              synonyms: ["bore", "excavate", "gouge"],
              antonyms: [],
              example:
                "Drill a small hole to start the screw in the right direction.",
            },
            {
              definition:
                "To practice, especially in (or as in) a military context.",
              synonyms: [],
              antonyms: [],
              example: "They drilled daily to learn the routine exactly.",
            },
            {
              definition:
                "To cause to drill (practice); to train in military arts.",
              synonyms: [],
              antonyms: [],
              example:
                "The sergeant was up by 6:00 every morning, drilling his troops.",
            },
            {
              definition:
                "To repeat an idea frequently in order to encourage someone to remember it.",
              synonyms: [],
              antonyms: [],
              example:
                "The instructor drilled into us the importance of reading the instructions.",
            },
            {
              definition:
                "To investigate or examine something in more detail or at a different level",
              synonyms: [],
              antonyms: [],
              example:
                "Drill deeper and you may find the underlying assumptions faulty.",
            },
            {
              definition: "To hit or kick with a lot of power.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To hit someone with a pitch, especially in an intentional context.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To have sexual intercourse with; to penetrate.",
              synonyms: ["plow", "poke", "root", "shaft"],
              antonyms: [],
            },
          ],
          synonyms: [
            "bore",
            "excavate",
            "gouge",
            "plow",
            "poke",
            "root",
            "shaft",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/drill"],
    },
    {
      word: "drill",
      phonetic: "/dɹɪl/",
      phonetics: [
        {
          text: "/dɹɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/drill-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762921",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An agricultural implement for making holes for sowing seed, and sometimes so formed as to contain seeds and drop them into the hole made.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A light furrow or channel made to put seed into, when sowing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A row of seed sown in a furrow.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To sow (seeds) by dribbling them along a furrow or in a row.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/drill"],
    },
    {
      word: "drill",
      phonetic: "/dɹɪl/",
      phonetics: [
        {
          text: "/dɹɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/drill-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762921",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A small trickling stream; a rill.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cause to flow in drills or rills or by trickling; to drain by trickling.",
              synonyms: [],
              antonyms: [],
              example: "waters drilled through a sandy stratum",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/drill"],
    },
    {
      word: "drill",
      phonetic: "/dɹɪl/",
      phonetics: [
        {
          text: "/dɹɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/drill-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762921",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To entice or allure; to decoy; with on.",
              synonyms: ["entice", "lead on", "lure"],
              antonyms: [],
            },
            {
              definition: "To cause to slip or waste away by degrees.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["entice", "lead on", "lure"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/drill"],
    },
    {
      word: "drill",
      phonetic: "/dɹɪl/",
      phonetics: [
        {
          text: "/dɹɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/drill-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762921",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An Old World monkey of West Africa, Mandrillus leucophaeus, similar in appearance to the mandrill, but lacking the colorful face.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/drill"],
    },
    {
      word: "drill",
      phonetic: "/dɹɪl/",
      phonetics: [
        {
          text: "/dɹɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/drill-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762921",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A strong, durable cotton fabric with a strong bias (diagonal) in the weave.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["chino"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/drill"],
    },
  ],
  trace: [
    {
      word: "trace",
      phonetic: "/tɹeɪs/",
      phonetics: [
        {
          text: "/tɹeɪs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/trace-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1750017",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An act of tracing.",
              synonyms: [],
              antonyms: [],
              example: "Your cell phone company can put a trace on your line.",
            },
            {
              definition:
                "An enquiry sent out for a missing article, such as a letter or an express package.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A mark left as a sign of passage of a person or animal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A residue of some substance or material.",
              synonyms: [],
              antonyms: [],
              example: "There are traces of chocolate around your lips.",
            },
            {
              definition: "A very small amount.",
              synonyms: [],
              antonyms: [],
              example: "All of our chocolates may contain traces of nuts.",
            },
            {
              definition:
                "A current-carrying conductive pathway on a printed circuit board.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An informal road or prominent path in an arid area.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of two straps, chains, or ropes of a harness, extending from the collar or breastplate to a whippletree attached to a vehicle or thing to be drawn; a tug.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A connecting bar or rod, pivoted at each end to the end of another piece, for transmitting motion, especially from one plane to another; specifically, such a piece in an organ stop action to transmit motion from the trundle to the lever actuating the stop slider.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(fortification) The ground plan of a work or works.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The intersection of a plane of projection, or an original plane, with a coordinate plane.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The sum of the diagonal elements of a square matrix.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(grammar) An empty category occupying a position in the syntactic structure from which something has been moved, used to explain constructions such as wh-movement and the passive.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["track", "trail"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/trace"],
    },
    {
      word: "trace",
      phonetic: "/tɹeɪs/",
      phonetics: [
        {
          text: "/tɹeɪs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/trace-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1750017",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To follow the trail of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To follow the history of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To draw or sketch lightly or with care.",
              synonyms: [],
              antonyms: [],
              example:
                "He carefully traced the outlines of the old building before him.",
            },
            {
              definition:
                "To copy onto a sheet of paper superimposed over the original, by drawing over its lines.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To copy; to imitate.", synonyms: [], antonyms: [] },
            {
              definition: "To walk; to go; to travel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To walk over; to pass through; to traverse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To follow the execution of the program by making it to stop after every instruction, or by making it print a message after every step.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/trace"],
    },
  ],
  skins: [
    {
      word: "skins",
      phonetic: "/skɪnz/",
      phonetics: [
        {
          text: "/skɪnz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/skins-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80066898",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The outer protective layer of the body of any animal, including of a human.",
              synonyms: [],
              antonyms: [],
              example: "He is so disgusting he makes my skin crawl.",
            },
            {
              definition: "The outer protective layer of the fruit of a plant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The skin and fur of an individual animal used by humans for clothing, upholstery, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A congealed layer on the surface of a liquid.",
              synonyms: [],
              antonyms: [],
              example:
                "In order to get to the rest of the paint in the can, you′ll have to remove the skin floating on top of it.",
            },
            {
              definition:
                "A set of resources that modifies the appearance and/or layout of the graphical user interface of a computer program.",
              synonyms: [],
              antonyms: [],
              example: "You can use this skin to change how the browser looks.",
            },
            {
              definition:
                "An alternate appearance (texture map or geometry) for a character model in a video game.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Rolling paper for cigarettes.",
              synonyms: [],
              antonyms: [],
              example: "Pass me a skin, mate.",
            },
            {
              definition:
                "A subgroup of Australian aboriginal people; such divisions are cultural and not related to an individual′s physical skin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Bare flesh, particularly bare breasts.",
              synonyms: [],
              antonyms: [],
              example: "Let me see a bit of skin.",
            },
            {
              definition: "A vessel made of skin, used for holding liquids.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That part of a sail, when furled, which remains on the outside and covers the whole.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The covering, as of planking or iron plates, outside the framing, forming the sides and bottom of a vessel; the shell; also, a lining inside the framing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A drink of whisky served hot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Person, chap",
              synonyms: [],
              antonyms: [],
              example: "He was a decent old skin.",
            },
          ],
          synonyms: [
            "film",
            "dermis",
            "integument",
            "tegument",
            "peel",
            "pericarp",
            "hide",
            "pelt",
            "moiety",
            "section",
            "subsection",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To injure the skin of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To remove the skin and/or fur of an animal or a human.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To high five.", synonyms: [], antonyms: [] },
            {
              definition: "To apply a skin to (a computer program).",
              synonyms: [],
              antonyms: [],
              example:
                "Can I skin the application to put the picture of my cat on it?",
            },
            {
              definition: "To use tricks to go past a defender.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To become covered with skin.",
              synonyms: [],
              antonyms: [],
              example: "A wound eventually skins over.",
            },
            {
              definition:
                "To cover with skin, or as if with skin; hence, to cover superficially.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To produce, in recitation, examination, etc., the work of another for one's own, or to use cribs, memoranda, etc., which are prohibited.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To strip of money or property; to cheat.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "bark",
            "chafe",
            "excoriate",
            "graze",
            "scrape",
            "flay",
            "fleece",
            "flense",
            "scalp",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Someone with a shaved head.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Member of the skinhead subculture arising in late 1960s England or its diaspora, often incorrectly associated with violence and white-supremacist or anti-immigrant principles.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["baldie", "boot boy", "bovver boy", "skin", "slaphead"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/skin",
        "https://en.wiktionary.org/wiki/skinhead",
        "https://en.wiktionary.org/wiki/skins",
      ],
    },
  ],
  waxed: [
    {
      word: "waxed",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/waxed-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651708",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To apply wax to (something, such as a shoe, a floor, a car, or an apple), usually to make it shiny.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To remove hair at the roots from (a part of the body) by coating the skin with a film of wax that is then pulled away sharply.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To defeat utterly.", synonyms: [], antonyms: [] },
            {
              definition: "To kill, especially to murder a person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(usually of a musical or oral performance) To record.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "polish",
            "bump off",
            "knock off",
            "whack",
            "buff",
            "burnish",
            "furbish",
            "polish",
            "shine",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To increasingly assume the specified characteristic.",
              synonyms: ["become"],
              antonyms: [],
            },
            { definition: "To grow.", synonyms: [], antonyms: ["wane"] },
            {
              definition:
                "(of the moon) To appear larger each night as a progression from a new moon to a full moon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of the tide) To move from low tide to high tide.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["become"],
          antonyms: ["wane"],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Of an object, coated or treated with wax in order to make it shiny or waterproof, or to protect it.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wax",
        "https://en.wiktionary.org/wiki/waxed",
      ],
    },
  ],
  seats: [
    {
      word: "seats",
      phonetic: "/siːts/",
      phonetics: [
        {
          text: "/siːts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/seats-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100848",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Something to be sat upon.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A location or site.", synonyms: [], antonyms: [] },
            {
              definition: "The starting point of a fire.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Posture, or way of sitting, on horseback.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To put an object into a place where it will rest; to fix; to set firm.",
              synonyms: [],
              antonyms: [],
              example:
                "Be sure to seat the gasket properly before attaching the cover.",
            },
            {
              definition: "To provide with places to sit.",
              synonyms: [],
              antonyms: [],
              example:
                "The waiter seated us and asked what we would like to drink.",
            },
            {
              definition: "To request or direct one or more persons to sit.",
              synonyms: [],
              antonyms: [],
              example:
                "Please seat the audience after the anthem and then introduce the first speaker.",
            },
            {
              definition:
                "(legislature) To recognize the standing of a person or persons by providing them with one or more seats which would allow them to participate fully in a meeting or session.",
              synonyms: [],
              antonyms: [],
              example:
                "Only half the delegates from the state were seated at the convention because the state held its primary too early.",
            },
            {
              definition: "To assign the seats of.",
              synonyms: [],
              antonyms: [],
              example: "to seat a church",
            },
            {
              definition:
                "To cause to occupy a post, site, or situation; to station; to establish; to fix; to settle.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To rest; to lie down.", synonyms: [], antonyms: [] },
            {
              definition: "To settle; to plant with inhabitants.",
              synonyms: [],
              antonyms: [],
              example: "to seat a country",
            },
            {
              definition: "To put a seat or bottom in.",
              synonyms: [],
              antonyms: [],
              example: "to seat a chair",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/seat",
        "https://en.wiktionary.org/wiki/seats",
      ],
    },
  ],
  stuff: [
    {
      word: "stuff",
      phonetic: "/stʌf/",
      phonetics: [
        {
          text: "/stʌf/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stuff-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75701577",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/stʌf/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stuff-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=589408",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Miscellaneous items or objects; (with possessive) personal effects.",
              synonyms: [],
              antonyms: [],
              example:
                "What is all that stuff on your bedroom floor?  He didn't want his pockets to bulge so he was walking around with all his stuff in his hands.",
            },
            {
              definition: "Unspecified things or matters.",
              synonyms: [],
              antonyms: [],
              example: "I had to do some stuff.",
            },
            {
              definition:
                "The tangible substance that goes into the makeup of a physical object.",
              synonyms: ["matter"],
              antonyms: [],
            },
            {
              definition:
                "A material for making clothing; any woven textile, but especially a woollen fabric.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Boards used for building.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Abstract substance or character.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Used as placeholder, usually for material of unknown type or name.",
              synonyms: ["doodad", "thingamabob"],
              antonyms: [],
              example: "Can I have some of that stuff on my ice-cream sundae?",
            },
            {
              definition: "Narcotic drugs, especially heroin.",
              synonyms: ["dope", "gear"],
              antonyms: [],
            },
            {
              definition: "Furniture; goods; domestic vessels or utensils.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A medicine or mixture; a potion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Refuse or worthless matter; hence, also, foolish or irrational language; nonsense; trash.",
              synonyms: ["garbage", "rubbish"],
              antonyms: [],
            },
            {
              definition:
                "A melted mass of turpentine, tallow, etc., with which the masts, sides, and bottom of a ship are smeared for lubrication.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Paper stock ground ready for use. When partly ground, it is called half stuff.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Money.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "doodad",
            "thingamabob",
            "dope",
            "gear",
            "garbage",
            "rubbish",
            "matter",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To fill by packing or crowding something into; to cram with something; to load to excess.",
              synonyms: [],
              antonyms: [],
              example: "I'm going to stuff this pillow with feathers.",
            },
            {
              definition:
                "To fill a space with (something) in a compressed manner.",
              synonyms: [],
              antonyms: [],
              example:
                "He stuffed his clothes into the closet and shut the door.",
            },
            {
              definition: "To fill with seasoning.",
              synonyms: [],
              antonyms: [],
              example:
                "She stuffed the turkey for Thanksgiving using her secret recipe.",
            },
            {
              definition: "To load goods into (a container) for transport.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(used in the passive) To sate.",
              synonyms: [],
              antonyms: [],
              example:
                "I’m stuffed after having eaten all that turkey, mashed potatoes and delicious stuffing.",
            },
            {
              definition:
                "(pronominal) To eat, especially in a hearty or greedy manner.",
              synonyms: [
                "feed one's face",
                "fill one's face",
                "stuff one's face",
              ],
              antonyms: [],
              example:
                "She sits on the sofa all day, watching TV and stuffing herself with cream buns.",
            },
            {
              definition: "To break; to destroy.",
              synonyms: [],
              antonyms: [],
              example:
                "He skidded off the road and totally stuffed his brand new car.",
            },
            {
              definition: "To sexually penetrate.",
              synonyms: ["fuck", "root", "screw"],
              antonyms: [],
              example:
                "His wife came home early and found him on the couch stuffing the maid.",
            },
            {
              definition:
                "(mildly vulgar, often imperative) Used to contemptuously dismiss or reject something. See also stuff it.",
              synonyms: [],
              antonyms: [],
              example: "Stuff your stupid rules, I'll do what I like.",
            },
            {
              definition: "To heavily defeat or get the better of.",
              synonyms: [],
              antonyms: [],
              example: "Mudchester Rovers were stuffed 7-0 in the semi-final.",
            },
            {
              definition:
                "To cut off another competitor in a race by disturbing his projected and committed racing line (trajectory) by an abrupt manoeuvre.",
              synonyms: [],
              antonyms: [],
              example:
                "I got stuffed by that guy on the supermoto going into that turn, almost causing us to crash.",
            },
            {
              definition:
                "To preserve a dead bird or other animal by filling its skin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To obstruct, as any of the organs; to affect with some obstruction in the organs of sense or respiration.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To form or fashion by packing with the necessary material.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To crowd with facts; to cram the mind of; sometimes, to crowd or fill with false or idle tales or fancies.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To compress (a file or files) in the StuffIt format, to be unstuffed later.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "feed one's face",
            "fill one's face",
            "stuff one's face",
            "fuck",
            "root",
            "screw",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stuff"],
    },
  ],
  ratio: [
    {
      word: "ratio",
      phonetic: "/ˈɹeɪ.ʃi.əʊ/",
      phonetics: [
        { text: "/ˈɹeɪ.ʃi.əʊ/", audio: "" },
        {
          text: "/ˈɹeɪ.ʃiˌoʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ratio-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780306",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A number representing a comparison between two named things.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The relative magnitudes of two quantities (usually expressed as a quotient).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Short for ratio decidendi.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The amount of comments to a post or other expression on social media relative to the number of likes.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(social media) To respond to a post or message on social media in a greater number than the number of likes the post receives.",
              synonyms: [],
              antonyms: [],
              example:
                "The schoolchildren ganged up to ratio the school catering over its shamefully undemocratic inclusion of vegetables in meals.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ratio"],
    },
  ],
  minds: [
    {
      word: "minds",
      phonetic: "/maɪndz/",
      phonetics: [
        {
          text: "/maɪndz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/minds-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1661676",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The ability for rational thought.",
              synonyms: [],
              antonyms: [],
              example:
                "Despite advancing age, his mind was still as sharp as ever.",
            },
            {
              definition: "The ability to be aware of things.",
              synonyms: [],
              antonyms: [],
              example: "There was no doubt in his mind that they would win.",
            },
            {
              definition: "The ability to remember things.",
              synonyms: [],
              antonyms: [],
              example: "My mind just went blank.",
            },
            {
              definition: "The ability to focus the thoughts.",
              synonyms: [],
              antonyms: [],
              example: "I can’t keep my mind on what I’m doing.",
            },
            {
              definition: "Somebody that embodies certain mental qualities.",
              synonyms: [],
              antonyms: [],
              example: "He was one of history’s greatest minds.",
            },
            {
              definition: "Judgment, opinion, or view.",
              synonyms: [],
              antonyms: [],
              example: "He changed his mind after hearing the speech.",
            },
            {
              definition: "Desire, inclination, or intention.",
              synonyms: [],
              antonyms: [],
              example: "I am of a mind to listen.",
            },
            {
              definition: "A healthy mental state.",
              synonyms: [],
              antonyms: [],
              example:
                "I, ______ being of sound mind and body, do hereby [...]",
            },
            {
              definition:
                "The non-material substance or set of processes in which consciousness, perception, affectivity, judgement, thinking, and will are based.",
              synonyms: [],
              antonyms: [],
              example: "The mind is a process of the brain.",
            },
            {
              definition:
                "Continual prayer on a dead person's behalf for a period after their death.",
              synonyms: [],
              antonyms: [],
              example: "a month's [or monthly] mind; a year's mind",
            },
          ],
          synonyms: [
            "brain",
            "head",
            "intellect",
            "intelligence",
            "nous",
            "psyche",
            "reason",
            "wit",
            "awareness",
            "consciousness",
            "sentience",
            "attention",
            "concentration",
            "focus",
            "memory",
            "recollection",
            "desire",
            "disposition",
            "idea",
            "inclination",
            "intention",
            "mood",
            "sanity",
            "idea",
            "judgement",
            "judgment",
            "opinion",
            "view",
            "genius",
            "intellectual",
            "thinker",
            "cognition",
            "learning",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(originally and chiefly in negative or interrogative constructions) To dislike, to object to; to be bothered by.",
              synonyms: [],
              antonyms: [],
              example: "Do you mind if I smoke?",
            },
            {
              definition:
                "To look after, to take care of, especially for a short period of time.",
              synonyms: [],
              antonyms: [],
              example: "Would you mind my bag for me?",
            },
            {
              definition:
                "(chiefly in the imperative) To make sure, to take care (that).",
              synonyms: [],
              antonyms: [],
              example: "Mind you don't knock that glass over.",
            },
            { definition: "To be careful about.", synonyms: [], antonyms: [] },
            {
              definition:
                "Take note; used to point out an exception or caveat.",
              synonyms: [],
              antonyms: [],
              example: "I'm not very healthy. I do eat fruit sometimes, mind.",
            },
            {
              definition:
                "To attend to, concern oneself with, heed, be mindful of.",
              synonyms: [],
              antonyms: [],
              example: "You should mind your own business.",
            },
            { definition: "To remember.", synonyms: [], antonyms: [] },
            {
              definition: "To have in mind; to intend.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To put in mind; to remind.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["heed"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mind",
        "https://en.wiktionary.org/wiki/minds",
      ],
    },
  ],
  dirty: [
    {
      word: "dirty",
      phonetic: "/ˈdɜːti/",
      phonetics: [
        {
          text: "/ˈdɜːti/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dirty-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9014368",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ˈdɝti/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dirty-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=856966",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make (something) dirty.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To stain or tarnish (somebody) with dishonor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To debase by distorting the real nature of (something).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To become soiled.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["soil", "taint", "sully"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Unclean; covered with or containing unpleasant substances such as dirt or grime.",
              synonyms: [],
              antonyms: [],
              example:
                "Despite a walk in the rain, my shoes weren't too dirty.",
            },
            {
              definition: "That makes one unclean; corrupting, infecting.",
              synonyms: [],
              antonyms: [],
              example: "Don't put that in your mouth, dear. It's dirty.",
            },
            {
              definition:
                "Morally unclean; obscene or indecent, especially sexually.",
              synonyms: [],
              antonyms: [],
              example:
                "At the reception, Uncle Nick got drunk and told dirty jokes to the bridesmaids.",
            },
            {
              definition:
                "Dishonourable; violating accepted standards or rules.",
              synonyms: [],
              antonyms: [],
              example:
                "He might have scored, but it was a dirty trick that won him the penalty.",
            },
            {
              definition: "Corrupt, illegal, or improper.",
              synonyms: [],
              antonyms: [],
              example: "I won't accept your dirty money!",
            },
            {
              definition: "Out of tune.",
              synonyms: [],
              antonyms: [],
              example:
                "You need to tune that guitar: the G string sounds dirty.",
            },
            {
              definition: "Of color, discolored by impurities.",
              synonyms: [],
              antonyms: [],
              example: "The old flag was a dirty white.",
            },
            {
              definition:
                "Containing data needing to be written back to memory or disk.",
              synonyms: [],
              antonyms: [],
              example:
                "Occasionally it reads the sector into a dirty buffer, which means it needs to sync the dirty buffer first.",
            },
            {
              definition:
                "Carrying illegal drugs among one's possessions or inside of one's bloodstream.",
              synonyms: [],
              antonyms: [],
              example: "None of y'all get into my car if you're dirty.",
            },
            {
              definition:
                'Used as an intensifier, especially in conjunction with "great".',
              synonyms: [],
              antonyms: [],
              example: "He lives in a dirty great mansion.",
            },
            {
              definition: "Sleety; gusty; stormy.",
              synonyms: [],
              antonyms: [],
              example: "dirty weather",
            },
            {
              definition:
                "Of an alcoholic beverage, especially a cocktail or mixed drink: served with the juice of olives.",
              synonyms: [],
              antonyms: [],
              example: "dirty martini",
            },
            {
              definition: "Of food, indulgent in an unhealthy way.",
              synonyms: [],
              antonyms: [],
              example: "The waiter served dirty burgers to the customers.",
            },
          ],
          synonyms: [
            "base",
            "despicable",
            "dishonest",
            "dishonorable",
            "filthy",
            "lousy",
            "mean",
            "sordid",
            "unethical",
            "vile",
            "indecent",
            "lewd",
            "obscene",
            "raunchy",
            "salacious",
            "filthy",
            "soiled",
            "sordid",
            "unclean",
            "unwashed",
            "ill-gotten",
            "dingy",
            "dullish",
            "muddied",
            "muddy",
            "cheating",
            "foul",
            "unsporting",
            "unsportsmanlike",
          ],
          antonyms: ["clean", "bright", "pure", "sportsmanlike", "neat"],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "In a dirty manner.",
              synonyms: [],
              antonyms: [],
              example: "to play dirty",
            },
          ],
          synonyms: ["deceptively", "dirtily", "indecently", "underhandedly"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dirty"],
    },
  ],
  silly: [
    {
      word: "silly",
      phonetic: "/ˈsɪli/",
      phonetics: [
        {
          text: "/ˈsɪli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/silly-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899964",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈsɪli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/silly-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100532",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A silly person.", synonyms: [], antonyms: [] },
            { definition: "A term of address.", synonyms: [], antonyms: [] },
            { definition: "A mistake.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Laughable or amusing through foolishness or a foolish appearance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Blessed, particularly:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Pitiful, inspiring compassion, particularly:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Simple, plain, particularly:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Mentally simple, foolish, particularly:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a fielding position) Very close to the batsman, facing the bowler; closer than short.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["charming"],
          antonyms: ["pious"],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Sillily: in a silly manner.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/silly"],
    },
  ],
  coins: [
    {
      word: "coins",
      phonetic: "/kɔɪnz/",
      phonetics: [{ text: "/kɔɪnz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(money) A piece of currency, usually metallic and in the shape of a disc, but sometimes polygonal, or with a hole in the middle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A token used in a special establishment like a casino.",
              synonyms: ["chip"],
              antonyms: [],
            },
            {
              definition: "That which serves for payment or recompense.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Money in general, not limited to coins.",
              synonyms: ["money"],
              antonyms: [],
              example: "She spent some serious coin on that car!",
            },
            {
              definition:
                "One of the suits of minor arcana in tarot, or a card of that suit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A corner or external angle.",
              synonyms: ["quoin", "wedge"],
              antonyms: [],
            },
            {
              definition: "A small circular slice of food.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A cryptocurrency.",
              synonyms: [],
              antonyms: [],
              example: "What's the best coin to buy right now?",
            },
          ],
          synonyms: ["chip", "money", "quoin", "wedge"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make of a definite fineness, and convert into coins, as a mass of metal.",
              synonyms: ["manufacture", "mint"],
              antonyms: [],
              example: "to coin a medal",
            },
            {
              definition: "(by extension) To make or fabricate.",
              synonyms: ["invent", "originate"],
              antonyms: [],
              example:
                "Over the last century the advance in science has led to many new words being coined.",
            },
            {
              definition: "To acquire rapidly, as money; to make.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["invent", "originate", "manufacture", "mint"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/coin",
        "https://en.wiktionary.org/wiki/coins",
      ],
    },
  ],
  hello: [
    {
      word: "hello",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hello-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75797336",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/həˈləʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hello-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9021983",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        { text: "/həˈloʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: '"Hello!" or an equivalent greeting.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["greeting"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: 'To greet with "hello".',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition:
                "A greeting (salutation) said when meeting someone or acknowledging someone’s arrival or presence.",
              synonyms: [],
              antonyms: [],
              example: "Hello, everyone.",
            },
            {
              definition: "A greeting used when answering the telephone.",
              synonyms: [],
              antonyms: [],
              example: "Hello? How may I help you?",
            },
            {
              definition:
                "A call for response if it is not clear if anyone is present or listening, or if a telephone conversation may have been disconnected.",
              synonyms: [],
              antonyms: [],
              example: "Hello? Is anyone there?",
            },
            {
              definition:
                "Used sarcastically to imply that the person addressed or referred to has done something the speaker or writer considers to be foolish.",
              synonyms: [],
              antonyms: [],
              example:
                "You just tried to start your car with your cell phone. Hello?",
            },
            {
              definition: "An expression of puzzlement or discovery.",
              synonyms: [],
              antonyms: [],
              example: "Hello! What’s going on here?",
            },
          ],
          synonyms: [],
          antonyms: ["bye", "goodbye"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hello"],
    },
  ],
  trips: [
    {
      word: "trips",
      phonetic: "/tɹɪps/",
      phonetics: [
        {
          text: "/tɹɪps/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/trips-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79268801",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A journey; an excursion or jaunt",
              synonyms: [],
              antonyms: [],
              example: "We made a trip to the beach.",
            },
            {
              definition: "A stumble or misstep",
              synonyms: [],
              antonyms: [],
              example: "He was injured due to a trip down the stairs.",
            },
            {
              definition: "An error; a failure; a mistake",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A period of time in which one experiences drug-induced reverie or hallucinations",
              synonyms: [],
              antonyms: [],
              example: "He had a strange trip after taking LSD.",
            },
            {
              definition: "A faux pas, a social error",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Intense involvement in or enjoyment of a condition",
              synonyms: [],
              antonyms: [],
              example: "ego trip; power trip; nostalgia trip; guilt trip",
            },
            {
              definition: "A mechanical cutout device",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A trip-switch or cut-out",
              synonyms: [],
              antonyms: [],
              example: "It's dark because the trip operated.",
            },
            {
              definition:
                "A quick, light step; a lively movement of the feet; a skip",
              synonyms: [],
              antonyms: [],
              example: "trip the light fantastic W",
            },
            {
              definition: "A small piece; a morsel; a bit",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The act of tripping someone, or causing them to lose their footing",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A single board, or tack, in plying, or beating, to windward",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To fall over or stumble over an object as a result of striking it with one's foot",
              synonyms: [],
              antonyms: [],
              example: "Be careful not to trip on the tree roots.",
            },
            {
              definition:
                '(sometimes followed by "up") to cause (a person or animal) to fall or stumble by knocking their feet from under them',
              synonyms: [],
              antonyms: [],
              example:
                "A pedestrian was able to trip the burglar as he was running away.",
            },
            {
              definition:
                "To be guilty of a misstep or mistake; to commit an offence against morality, propriety, etc",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To detect in a misstep; to catch; to convict",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To activate or set in motion, as in the activation of a trap, explosive, or switch",
              synonyms: [],
              antonyms: [],
              example: "When we get into the factory, trip the lights.",
            },
            {
              definition: "To be activated, as by a signal or an event",
              synonyms: [],
              antonyms: [],
              example:
                "The alarm system tripped, throwing everyone into a panic.",
            },
            {
              definition:
                "To experience a state of reverie or to hallucinate, due to consuming psychoactive drugs",
              synonyms: [],
              antonyms: [],
              example:
                "After taking the LSD, I started tripping about fairies and colors.",
            },
            {
              definition: "To journey, to make a trip",
              synonyms: [],
              antonyms: [],
              example: "Last summer we tripped to the coast.",
            },
            {
              definition:
                "To move with light, quick steps; to walk or move lightly; to skip",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To raise (an anchor) from the bottom, by its cable or buoy rope, so that it hangs free",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To pull (a yard) into a perpendicular position for lowering it",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(most commonly used in the form tripping) to become unreasonably upset, especially over something unimportant; to cause a scene or a disruption",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A herd or flock of sheep, goats, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A troop of men; a host",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A flock of wigeons", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Three of a kind, especially if one of the three cards is in one's hand and the other two are on the board. Compare set.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/trip",
        "https://en.wiktionary.org/wiki/trips",
      ],
    },
  ],
  leads: [
    {
      word: "leads",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A heavy, pliable, inelastic metal element, having a bright, bluish color, but easily tarnished; both malleable and ductile, though with little tenacity. It is easily fusible, forms alloys with other metals, and is an ingredient of solder and type metal. Atomic number 82, symbol Pb (from Latin plumbum).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A plummet or mass of lead attached to a line, used in sounding depth at sea or to estimate velocity in knots.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A thin strip of type metal, used to separate lines of type in printing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Vertical space in advance of a row or between rows of text. Also known as leading.",
              synonyms: [],
              antonyms: [],
              example:
                "This copy has too much lead; I prefer less space between the lines.",
            },
            {
              definition:
                "Sheets or plates of lead used as a covering for roofs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(plural leads) A roof covered with lead sheets or terne plates.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A thin cylinder of black lead or plumbago (graphite) used in pencils.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Bullets; ammunition.",
              synonyms: [],
              antonyms: [],
              example: "They pumped him full of lead.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cover, fill, or affect with lead",
              synonyms: [],
              antonyms: [],
              example: "continuous firing leads the grooves of a rifle.",
            },
            {
              definition: "To place leads between the lines of.",
              synonyms: [],
              antonyms: [],
              example: "leaded matter",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The act of leading or conducting; guidance; direction, course",
              synonyms: [],
              antonyms: [],
              example: "to be under the lead of another",
            },
            {
              definition:
                "Precedence; advance position; also, the measure of precedence; the state of being ahead in a race; the highest score in a game in an incomplete game.",
              synonyms: [],
              antonyms: [],
              example: "She lost the lead.",
            },
            {
              definition:
                "An insulated metallic wire for electrical devices and equipment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The situation where a runner steps away from a base while waiting for the pitch to be thrown.",
              synonyms: [],
              antonyms: [],
              example: "The runner took his lead from first.",
            },
            {
              definition:
                "The act or right of playing first in a game or round; the card suit, or piece, so played",
              synonyms: [],
              antonyms: [],
              example: "your partner has the lead",
            },
            {
              definition: "The main role in a play or film; the lead role.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The actor who plays the main role; lead actor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A channel of open water in an ice field.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A lode.", synonyms: [], antonyms: [] },
            {
              definition: "The course of a rope from end to end.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A rope, leather strap, or similar device with which to lead an animal; a leash",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In a steam engine, the width of port opening which is uncovered by the valve, for the admission or release of steam, at the instant when the piston is at end of its stroke.",
              synonyms: [],
              antonyms: [],
              example:
                "Usage note: When used alone it means outside lead, or lead for the admission of steam. Inside lead refers to the release or exhaust.",
            },
            { definition: "Charging lead.", synonyms: [], antonyms: [] },
            {
              definition:
                "The distance of haul, as from a cutting to an embankment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The action of a tooth, such as a tooth of a wheel, in impelling another tooth or a pallet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Hypothesis that has not been pursued",
              synonyms: [],
              antonyms: [],
              example:
                "The investigation stalled when all leads turned out to be dead ends.",
            },
            {
              definition:
                "Information obtained by a detective or police officer that allows him or her to discover further details about a crime or incident.",
              synonyms: [],
              antonyms: [],
              example:
                "The police have a couple of leads they will follow to solve the case.",
            },
            {
              definition:
                "Potential opportunity for a sale or transaction, a potential customer.",
              synonyms: [],
              antonyms: [],
              example:
                "Joe is a great addition to our sales team, he has numerous leads in the paper industry.",
            },
            {
              definition:
                "Information obtained by a news reporter about an issue or subject that allows him or her to discover more details.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The player who throws the first two rocks for a team.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(newspapers) A teaser; a lead-in; the start of a newspaper column, telling who, what, when, where, why and how. (Sometimes spelled as lede for this usage to avoid ambiguity.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An important news story that appears on the front page of a newspaper or at the beginning of a news broadcast",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The axial distance a screw thread travels in one revolution. It is equal to the pitch times the number of starts.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In a barbershop quartet, the person who sings the melody, usually the second tenor",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The announcement by one voice part of a theme to be repeated by the other parts.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A mark or a short passage in one voice part, as of a canon, serving as a cue for the entrance of others.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The excess above a right angle in the angle between two consecutive cranks, as of a compound engine, on the same shaft.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The angle between the line joining the brushes of a continuous-current dynamo and the diameter symmetrical between the poles.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The advance of the current phase in an alternating circuit beyond that of the electromotive force producing it.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(heading) To guide or conduct.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To guide or conduct, as by accompanying, going before, showing, influencing, directing with authority, etc.; to have precedence or preeminence; to be first or chief; — used in most of the senses of the transitive verb.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(heading) To begin, to be ahead.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To draw or direct by influence, whether good or bad; to prevail on; to induce; to entice; to allure",
              synonyms: [],
              antonyms: [],
              example: "to lead someone to a righteous cause",
            },
            {
              definition:
                "To tend or reach in a certain direction, or to a certain place.",
              synonyms: [],
              antonyms: [],
              example:
                "the path leads to the mill;  gambling leads to other vices",
            },
            {
              definition: "To produce (with to).",
              synonyms: [],
              antonyms: [],
              example: "The shock led to a change in his behaviour.",
            },
            {
              definition:
                "(transitive) To live or experience (a particular way of life).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lead",
        "https://en.wiktionary.org/wiki/leads",
      ],
    },
  ],
  rifle: [
    {
      word: "rifle",
      phonetic: "/ˈɹaɪfəl/",
      phonetics: [
        {
          text: "/ˈɹaɪfəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rifle-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1763034",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A shouldered firearm with a long, rifled barrel to improve range and accuracy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(usually plural) A rifleman.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An artillery piece with a rifled barrel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A strip of wood covered with emery or a similar material, used for sharpening scythes.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To quickly search through many items (such as papers, the contents of a drawer, a pile of clothing). (See also rifflehttp//verbmall.blogspot.com/2008/05/riffle-or-rifle.html)",
              synonyms: [],
              antonyms: [],
              example:
                "She made a mess when she rifled through the stack of papers, looking for the title document.",
            },
            {
              definition: "To commit robbery or theft.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To search with intent to steal; to ransack, pillage or plunder.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To strip of goods; to rob; to pillage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To seize and bear away by force; to snatch away; to carry off.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To add a spiral groove to a gun bore to make a fired bullet spin in flight in order to improve range and accuracy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause (a projectile, as a rifle bullet) to travel in a flat ballistic trajectory.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To move in a flat ballistic trajectory (as a rifle bullet).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To dispose of in a raffle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To engage in a raffle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rifle"],
    },
  ],
  hopes: [
    {
      word: "hopes",
      phonetic: "/həʊps/",
      phonetics: [
        { text: "/həʊps/", audio: "" },
        {
          text: "/hoʊps/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hopes-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1648259",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To want something to happen, with a sense of expectation that it might.",
              synonyms: [],
              antonyms: [],
              example: "I am still hoping that all will turn out well.",
            },
            {
              definition: "To be optimistic; be full of hope; have hopes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To place confidence; to trust with confident expectation of good; usually followed by in.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To wish.",
              synonyms: [],
              antonyms: [],
              example: "I hope you all the best.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The feeling of trust, confidence, belief or expectation that something wished for can or will happen.",
              synonyms: [],
              antonyms: [],
              example:
                "After losing my job, there's no hope of being able to afford my world cruise.",
            },
            {
              definition: "The actual thing wished for.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person or thing that is a source of hope.",
              synonyms: [],
              antonyms: [],
              example:
                "We still have one hope left: my roommate might see the note I left on the table.",
            },
            {
              definition: "The virtuous desire for future good.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A hollow; a valley, especially the upper end of a narrow mountain valley when it is nearly encircled by smooth, green slopes; a comb.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A sloping plain between mountain ridges.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A small bay; an inlet; a haven.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hope",
        "https://en.wiktionary.org/wiki/hopes",
      ],
    },
  ],
  bases: [
    {
      word: "bases",
      phonetic: "/ˈbeɪsɪz/",
      phonetics: [
        {
          text: "/ˈbeɪsɪz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bases-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=60478221",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Something from which other things extend; a foundation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The starting point of a logical deduction or thought; basis.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A permanent structure for housing military personnel and material.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The place where decisions for an organization are made; headquarters.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A basic but essential component or ingredient.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A substance used as a mordant in dyeing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Foundation: a cosmetic cream to make the face appear uniform.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of a class of generally water-soluble compounds, having bitter taste, that turn red litmus blue, and react with acids to form salts.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Important areas in games and sports.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The lowermost part of a column, between the shaft and the pedestal or pavement.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A nucleotide's nucleobase in the context of a DNA or RNA biopolymer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The end of a leaf, petal or similar organ where it is attached to its support.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The name of the controlling terminal of a bipolar transistor (BJT).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The lowest side of a in a triangle or other polygon, or the lowest face of a cone, pyramid or other polyhedron laid flat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The lowest third of a shield or escutcheon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The lower part of the field. See escutcheon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A number raised to the power of an exponent.",
              synonyms: [],
              antonyms: [],
              example: "The logarithm to base 2 of 8 is 3.",
            },
            {
              definition: "The set of sets from which a topology is generated.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A topological space, looked at in relation to one of its covering spaces, fibrations, or bundles.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sequence of elements not jointly stabilized by any nontrivial group element.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(acrobatics, cheerleading) In hand-to-hand balance, the person who supports the flyer; the person that remains in contact with the ground.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A morpheme (or morphemes) that serves as a basic foundation on which affixes can be attached.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The smallest kind of cannon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The housing of a horse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in the plural) A kind of skirt (often of velvet or brocade, but sometimes of mailed armour) which hung from the middle to about the knees, or lower.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The lower part of a robe or petticoat.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An apron.", synonyms: [], antonyms: [] },
            {
              definition:
                "A line in a survey which, being accurately determined in length and position, serves as the origin from which to compute the distances and positions of any points or objects connected with it by a system of triangles.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A group of voters who almost always support a single party's candidates for elected office.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The forces and relations of production that produce the necessities and amenities of life.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A material that holds paint or other materials together; a binder.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Short for base leg.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["alkali"],
          antonyms: ["acid", "apex"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To give as its foundation or starting point; to lay the foundation of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be located (at a particular place).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(acrobatics, cheerleading) To act as a base; to be the person supporting the flyer.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A low spectrum of sound tones.",
              synonyms: [],
              antonyms: [],
              example:
                "Peter adjusted the equalizer on his audio equipment to emphasize the bass.",
            },
            {
              definition:
                "A section of musical group that produces low-pitched sound, lower than the baritone and tenor.",
              synonyms: [],
              antonyms: [],
              example:
                "The conductor preferred to situate the bass in the middle rear, rather than to one side of the orchestra.",
            },
            {
              definition: "One who sings in the bass range.",
              synonyms: [],
              antonyms: [],
              example:
                "Halfway through middle school, Edgar morphed from a soprano to a bass, much to the amazement and amusement of his fellow choristers.",
            },
            {
              definition:
                "An instrument that plays in the bass range, in particular a double bass, bass guitar, electric bass or bass synthesiser.",
              synonyms: [],
              antonyms: [],
              example:
                "The musician swung the bass over his head like an axe and smashed it into the amplifier, creating a discordant howl of noise.",
            },
            {
              definition:
                "The clef sign that indicates that the pitch of the notes is below middle C; a bass clef.",
              synonyms: [],
              antonyms: [],
              example:
                "The score had been written without the treble and bass, but it was easy to pick out which was which based on the location of the notes on the staff.",
            },
          ],
          synonyms: ["F clef", "basso"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A root.", synonyms: [], antonyms: [] },
            {
              definition:
                "A primitive word, from which other words may be derived.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The number of distinct symbols used to represent numbers in a particular base, as ten for decimal.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["primitive", "radical"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/base",
        "https://en.wiktionary.org/wiki/bases",
        "https://en.wiktionary.org/wiki/bass",
        "https://en.wiktionary.org/wiki/radix",
      ],
    },
    {
      word: "bases",
      phonetic: "/ˈbeɪsiːz/",
      phonetics: [
        {
          text: "/ˈbeɪsiːz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bases-2-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=92970945",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A physical base or foundation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A starting point, base or foundation for an argument or hypothesis.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An underlying condition or circumstance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A regular frequency.",
              synonyms: [],
              antonyms: [],
              example: "Cars must be checked on a yearly basis.",
            },
            {
              definition:
                "In a vector space, a linearly independent set of vectors spanning the whole vector space.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Amount paid for an investment, including commissions and other expenses.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'A collection of subsets ("basis elements") of a set, such that this collection covers the set, and for any two basis elements which both contain an element of the set, there is a third basis element contained in the intersection of the first two, which also contains that element.',
              synonyms: [],
              antonyms: [],
              example:
                "The collection of all possible unions of basis elements of a basis is said to be the topology generated by that basis.",
            },
          ],
          synonyms: ["base"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bases",
        "https://en.wiktionary.org/wiki/basis",
      ],
    },
  ],
  shine: [
    {
      word: "shine",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shine-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899880",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ʃaɪn/", audio: "" },
        {
          text: "/ʃaɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shine-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1229472",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Brightness from a source of light.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Brightness from reflected light.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Excellence in quality or appearance; splendour.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Shoeshine.", synonyms: [], antonyms: [] },
            { definition: "Sunshine.", synonyms: [], antonyms: [] },
            {
              definition: "Moonshine; illicitly brewed alcoholic drink.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The amount of shininess on a cricket ball, or on each side of the ball.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A liking for a person; a fancy.",
              synonyms: [],
              antonyms: [],
              example: "She's certainly taken a shine to you.",
            },
            {
              definition: "A caper; an antic; a row.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "effulgence",
            "radiance",
            "radiancy",
            "refulgence",
            "refulgency",
            "luster",
            "brilliance",
            "splendor",
            "shoeshine",
            "moonshine",
            "sunshine",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To emit light.", synonyms: [], antonyms: [] },
            { definition: "To reflect light.", synonyms: [], antonyms: [] },
            {
              definition: "To distinguish oneself; to excel.",
              synonyms: [],
              antonyms: [],
              example:
                "My nephew tried other sports before deciding on football, which he shone at right away, quickly becoming the star of his school team.",
            },
            {
              definition: "To be effulgent in splendour or beauty.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be eminent, conspicuous, or distinguished; to exhibit brilliant intellectual powers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be immediately apparent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To create light with (a flashlight, lamp, torch, or similar).",
              synonyms: [],
              antonyms: [],
              example:
                "I shone my light into the darkness to see what was making the noise.",
            },
            {
              definition: "To cause to shine, as a light.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make bright; to cause to shine by reflected light.",
              synonyms: [],
              antonyms: [],
              example:
                "in hunting, to shine the eyes of a deer at night by throwing a light on them",
            },
          ],
          synonyms: [
            "excel",
            "beam",
            "glow",
            "radiate",
            "buff",
            "burnish",
            "furbish",
            "polish",
            "wax",
            "gleam",
            "glint",
            "glisten",
            "glitter",
            "reflect",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shine"],
    },
    {
      word: "shine",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shine-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899880",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ʃaɪn/", audio: "" },
        {
          text: "/ʃaɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shine-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1229472",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cause (something) to shine; put a shine on (something); polish (something).",
              synonyms: [],
              antonyms: [],
              example:
                "He shined my shoes until they were polished smooth and gleaming.",
            },
            {
              definition:
                "To polish a cricket ball using saliva and one’s clothing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["polish", "smooth", "smoothen"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shine"],
    },
  ],
  bench: [
    {
      word: "bench",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bench-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75267232",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/bɛntʃ/", audio: "" },
        {
          text: "/bɛntʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bench-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=338325",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A long seat with or without a back, found for example in parks and schools.",
              synonyms: [],
              antonyms: [],
              example:
                "They sat on a park bench and tossed bread crumbs to the ducks and pigeons.",
            },
            {
              definition:
                "The people who decide on the verdict; the judiciary.",
              synonyms: [],
              antonyms: [],
              example:
                "They are awaiting a decision on the motion from the bench.",
            },
            {
              definition: "The place where the judges sit.",
              synonyms: [],
              antonyms: [],
              example: "She sat on the bench for 30 years before she retired.",
            },
            {
              definition: "The dignity of holding an official seat.",
              synonyms: [],
              antonyms: [],
              example: "the bench of bishops",
            },
            {
              definition:
                "The place where players (substitutes) and coaches sit when not playing.",
              synonyms: [],
              antonyms: [],
              example: "He spent the first three games on the bench, watching.",
            },
            {
              definition:
                "The number of players on a team able to participate, expressed in terms of length.",
              synonyms: [],
              antonyms: [],
              example: "Injuries have shortened the bench.",
            },
            {
              definition:
                "A place where assembly or hand work is performed; a workbench.",
              synonyms: [],
              antonyms: [],
              example:
                "She placed the workpiece on the bench, inspected it closely, and opened the cover.",
            },
            {
              definition:
                "A horizontal padded surface, usually adjustable in height and inclination and often with attached weight rack, used for proper posture during exercise.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bracket used to mount land surveying equipment onto a stone or a wall.",
              synonyms: [],
              antonyms: [],
              example:
                "After removing the bench, we can use the mark left on the wall as a reference point.",
            },
            {
              definition:
                "A flat ledge in the slope of an earthwork, work of masonry, or similar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A thin strip of relatively flat land bounded by steeper slopes above and below.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A kitchen surface on which to prepare food, a counter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bathroom surface which holds the washbasin, a vanity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A collection or group of dogs exhibited to the public, traditionally on benches or raised platforms.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To remove a player from play.",
              synonyms: ["sideline"],
              antonyms: [],
              example:
                "They benched him for the rest of the game because they thought he was injured.",
            },
            {
              definition:
                "To remove someone from a position of responsibility temporarily.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To push a person backward against a conspirator behind them who is on their hands and knees, causing them to fall over.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To furnish with benches.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To place on a bench or seat of honour.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["sideline"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bench"],
    },
    {
      word: "bench",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bench-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75267232",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/bɛntʃ/", audio: "" },
        {
          text: "/bɛntʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bench-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=338325",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The weight one is able to bench press, especially the maximum weight capable of being pressed.",
              synonyms: [],
              antonyms: [],
              example:
                "He became frustrated when his bench increased by only 10 pounds despite a month of training.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To lift by bench pressing",
              synonyms: [],
              antonyms: [],
              example: "I heard he can bench 150 pounds.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bench"],
    },
    {
      word: "bench",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bench-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75267232",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/bɛntʃ/", audio: "" },
        {
          text: "/bɛntʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bench-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=338325",
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To recite the Birkat Hamazon.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bench",
        "https://en.wiktionary.org/wiki/bentsh",
      ],
    },
  ],
  moral: [
    {
      word: "moral",
      phonetic: "/ˈmɒɹəl/",
      phonetics: [
        { text: "/ˈmɒɹəl/", audio: "" },
        {
          text: "/ˈmɑɹəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/moral-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1244051",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(of a narrative) The ethical significance or practical lesson.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(chiefly in the plural) Moral practices or teachings: modes of conduct.",
              synonyms: [],
              antonyms: [],
              example: "a candidate with strong morals",
            },
            { definition: "A morality play.", synonyms: [], antonyms: [] },
            { definition: "A moral certainty.", synonyms: [], antonyms: [] },
            { definition: "An exact counterpart.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["ethics", "mores"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To moralize.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Of or relating to principles of right and wrong in behaviour, especially for teaching right behaviour.",
              synonyms: [],
              antonyms: [],
              example: "moral judgments;  a moral poem",
            },
            {
              definition:
                "Conforming to a standard of right behaviour; sanctioned by or operative on one's conscience or ethical judgment.",
              synonyms: [],
              antonyms: [],
              example: "a moral obligation",
            },
            {
              definition: "Capable of right and wrong action.",
              synonyms: [],
              antonyms: [],
              example: "a moral agent",
            },
            {
              definition: "Probable but not proved.",
              synonyms: [],
              antonyms: [],
              example: "a moral certainty",
            },
            {
              definition: "Positively affecting the mind, confidence, or will.",
              synonyms: [],
              antonyms: [],
              example: "a moral victory;  moral support",
            },
          ],
          synonyms: [
            "ethical",
            "incorruptible",
            "noble",
            "righteous",
            "virtuous",
            "virtual",
          ],
          antonyms: [
            "amoral",
            "corrupt",
            "immoral",
            "non-moral",
            "unethical",
            "unmoral",
            "unscrupulous",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/moral"],
    },
  ],
  fires: [
    {
      word: "fires",
      phonetic: "/ˈfaɪ̯ə(ɹ)z/",
      phonetics: [
        { text: "/ˈfaɪ̯ə(ɹ)z/", audio: "" },
        {
          text: "/ˈfaɪ̯ɚz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fires-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100716",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A (usually self-sustaining) chemical reaction involving the bonding of oxygen with carbon or other fuel, with the production of heat and the presence of flame or smouldering.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An instance of this chemical reaction, especially when intentionally created and maintained in a specific location to a useful end (such as a campfire or a hearth fire).",
              synonyms: [],
              antonyms: [],
              example: "We sat about the fire singing songs and telling tales.",
            },
            {
              definition:
                "The occurrence, often accidental, of fire in a certain place, causing damage and danger.",
              synonyms: [],
              antonyms: [],
              example:
                "During hot and dry summers many fires in forests are caused by regardlessly discarded cigarette butts.",
            },
            {
              definition:
                "The aforementioned chemical reaction of burning, considered one of the Classical elements or basic elements of alchemy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A heater or stove used in place of a real fire (such as an electric fire).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The elements necessary to start a fire.",
              synonyms: [],
              antonyms: [],
              example: "The fire was laid and needed to be lit.",
            },
            {
              definition: "The bullets or other projectiles fired from a gun.",
              synonyms: [],
              antonyms: [],
              example: "The fire from the enemy guns kept us from attacking.",
            },
            {
              definition: "Strength of passion, whether love or hate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Liveliness of imagination or fancy; intellectual and moral enthusiasm.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Splendour; brilliancy; lustre; hence, a star.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A severe trial; anything inflaming or provoking.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Red coloration in a piece of opal.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["blaze", "conflagration", "inferno"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To set (something, often a building) on fire.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To heat as with fire, but without setting on fire, as ceramic, metal objects, etc.",
              synonyms: [],
              antonyms: [],
              example:
                "If you fire the pottery at too high a temperature, it may crack.",
            },
            {
              definition: "To drive away by setting a fire.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To terminate the employment contract of (an employee), especially for cause (such as misconduct or poor performance).",
              synonyms: [],
              antonyms: ["hire"],
            },
            {
              definition: "To shoot (a gun or analogous device).",
              synonyms: [],
              antonyms: [],
              example: "He fired his radar gun at passing cars.",
            },
            {
              definition: "To shoot a gun, cannon, or similar weapon.",
              synonyms: ["open fire", "shoot"],
              antonyms: [],
              example: "Don't fire until you see the whites of their eyes.",
            },
            {
              definition: "To shoot; to attempt to score a goal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause an action potential in a cell.",
              synonyms: [],
              antonyms: [],
              example: "When a neuron fires, it transmits information.",
            },
            {
              definition: "To forcibly direct (something).",
              synonyms: [],
              antonyms: [],
              example: "He answered the questions the reporters fired at him.",
            },
            {
              definition:
                "(computer sciences, software engineering) To initiate an event (by means of an event handler).",
              synonyms: [],
              antonyms: [],
              example:
                "The event handler should only fire after all web page content has finished loading.",
            },
            {
              definition: "To inflame; to irritate, as the passions.",
              synonyms: [],
              antonyms: [],
              example: "to fire the soul with anger, pride, or revenge",
            },
            {
              definition: "To animate; to give life or spirit to.",
              synonyms: [],
              antonyms: [],
              example: "to fire the genius of a young man",
            },
            {
              definition: "To feed or serve the fire of.",
              synonyms: [],
              antonyms: [],
              example: "to fire a boiler",
            },
            {
              definition: "To light up as if by fire; to illuminate.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To cauterize.", synonyms: [], antonyms: [] },
            {
              definition: "To catch fire; to be kindled.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be irritated or inflamed with passion.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "set on fire",
            "cards",
            "dehire",
            "dismiss",
            "give the boot",
            "give the elbow",
            "give the old heave-ho",
            "let go",
            "redundant",
            "sack",
            "terminate",
            "throw out",
            "unhire",
            "let off",
            "loose",
            "shoot",
            "open fire",
            "shoot",
          ],
          antonyms: ["hire"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fire",
        "https://en.wiktionary.org/wiki/fires",
      ],
    },
  ],
  meals: [
    {
      word: "meals",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Food that is prepared and eaten, usually at a specific time, and usually in a comparatively large quantity (as opposed to a snack).",
              synonyms: [],
              antonyms: [],
              example:
                "Breakfast is the morning meal, lunch is the noon meal, and dinner, or supper, is the evening meal.",
            },
            {
              definition: "Food served or eaten as a repast.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A time or an occasion.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The coarse-ground edible part of various grains often used to feed animals; flour or a coarser blend than flour.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A speck or spot.", synonyms: [], antonyms: [] },
            {
              definition: "A part; a fragment; a portion.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/meal",
        "https://en.wiktionary.org/wiki/meals",
      ],
    },
  ],
  shake: [
    {
      word: "shake",
      phonetic: "/ˈʃeɪk/",
      phonetics: [
        {
          text: "/ˈʃeɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shake-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1157660",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The act of shaking or being shaken; tremulous or back-and-forth motion.",
              synonyms: [],
              antonyms: [],
              example: "She replied in the negative, with a shake of her head.",
            },
            { definition: "A milkshake.", synonyms: [], antonyms: [] },
            {
              definition:
                "A beverage made by adding ice cream to a (usually carbonated) drink; a float.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Shake cannabis, small, leafy fragments of cannabis that gather at the bottom of a bag of marijuana.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(building material) A thin shingle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A crack or split between the growth rings in wood.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A fissure in rock or earth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A basic wooden shingle made from split logs, traditionally used for roofing etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Instant, second. (Especially in two shakes.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the staves of a hogshead or barrel taken apart.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A rapid alternation of a principal tone with another represented on the next degree of the staff above or below it; a trill.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A shook of staves and headings.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The redshank, so called from the nodding of its head while on the ground.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cause (something) to move rapidly in opposite directions alternatingly.",
              synonyms: [],
              antonyms: [],
              example:
                "He shook the can of soda for thirty seconds before delivering it to me, so that, when I popped it open, soda went everywhere.",
            },
            {
              definition:
                "To move (one's head) from side to side, especially to indicate refusal, reluctance or disapproval.",
              synonyms: [],
              antonyms: [],
              example: 'Shaking his head, he kept repeating "No, no, no".',
            },
            {
              definition:
                "To move or remove by agitating; to throw off by a jolting or vibrating motion.",
              synonyms: [],
              antonyms: [],
              example: "to shake fruit down from a tree",
            },
            {
              definition: "To disturb emotionally; to shock.",
              synonyms: ["traumatize"],
              antonyms: [],
              example: "He was shaken by what had happened.",
            },
            {
              definition: "To lose, evade, or get rid of (something).",
              synonyms: [],
              antonyms: [],
              example: "I can't shake the feeling that I forgot something.",
            },
            {
              definition: "To move from side to side.",
              synonyms: ["shiver", "tremble"],
              antonyms: [],
              example: "She shook with grief.",
            },
            {
              definition: '(usually as "shake on") To shake hands.',
              synonyms: [],
              antonyms: [],
              example: "OK, let's shake on it.",
            },
            {
              definition: "To dance.",
              synonyms: [],
              antonyms: [],
              example: "She was shaking it on the dance floor.",
            },
            {
              definition: "To give a tremulous tone to; to trill.",
              synonyms: [],
              antonyms: [],
              example: "to shake a note in music",
            },
            {
              definition: "To threaten to overthrow.",
              synonyms: [],
              antonyms: [],
              example: "The experience shook my religious belief.",
            },
            {
              definition: "To be agitated; to lose firmness.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["shiver", "tremble", "traumatize"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shake"],
    },
  ],
  shops: [
    {
      word: "shops",
      phonetic: "/ʃɒps/",
      phonetics: [
        { text: "/ʃɒps/", audio: "" },
        { text: "/ʃɑps/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An establishment that sells goods or services to the public; originally only a physical location, but now a virtual establishment as well.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A place where things are manufactured or crafted; a workshop.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A large garage where vehicle mechanics work.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Workplace; office. Used mainly in expressions such as shop talk, closed shop and shop floor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A variety of classes taught in junior or senior high school that teach vocational skill.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An establishment where a barber or beautician works.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An act of shopping, especially routine shopping for food and other domestic supplies.",
              synonyms: [],
              antonyms: [],
              example: "This is where I do my weekly shop.",
            },
            {
              definition: "Discussion of business or professional affairs.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "garage",
            "boutique",
            "outlet",
            "retail",
            "store",
            "metal shop",
            "metalwork",
            "atelier",
            "studio",
            "workshop",
            "carpentry",
            "wood shop",
            "woodwork",
            "office",
            "workplace",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To visit stores or shops to browse or explore merchandise, especially with the intention of buying such merchandise.",
              synonyms: [],
              antonyms: [],
              example: "He’s shopping for clothes.",
            },
            {
              definition:
                "To purchase products from (a range or catalogue, etc.).",
              synonyms: [],
              antonyms: [],
              example: "Shop our new arrivals.",
            },
            {
              definition:
                "To report the criminal activities or whereabouts of someone to an authority.",
              synonyms: [],
              antonyms: [],
              example: "He shopped his mates in to the police.",
            },
            { definition: "To imprison.", synonyms: [], antonyms: [] },
            {
              definition:
                "To photoshop; to digitally edit a picture or photograph.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/shop",
        "https://en.wiktionary.org/wiki/shops",
      ],
    },
  ],
  cycle: [
    {
      word: "cycle",
      phonetic: "/ˈsaɪkəl/",
      phonetics: [
        {
          text: "/ˈsaɪkəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cycle-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762904",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An interval of space or time in which one set of events or phenomena is completed.",
              synonyms: [],
              antonyms: [],
              example: "the cycle of the seasons, or of the year",
            },
            {
              definition: "A complete rotation of anything.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A process that returns to its beginning and then repeats itself in the same sequence.",
              synonyms: [],
              antonyms: [],
              example: "electoral cycle",
            },
            {
              definition:
                "The members of the sequence formed by such a process.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In musical set theory, an interval cycle is the set of pitch classes resulting from repeatedly applying the same interval class to the starting pitch class.",
              synonyms: [],
              antonyms: [],
              example:
                "The interval cycle C4 consists of the pitch classes 0, 4 and 8; when starting on E, it is realised as the pitches E, G# and C.",
            },
            {
              definition: "A series of poems, songs or other works of art.",
              synonyms: [],
              antonyms: [],
              example:
                'The "Ring of the Nibelung" is a cycle of four operas by Richard Wagner.',
            },
            {
              definition:
                "A programme on a washing machine, dishwasher, or other such device.",
              synonyms: [],
              antonyms: [],
              example: "Put the washing in on a warm cycle.",
            },
            {
              definition:
                "A pedal-powered vehicle, such as a unicycle, bicycle, or tricycle, or a motorized vehicle that has either two or three wheels.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A single, a double, a triple, and a home run hit by the same player in the same game.",
              synonyms: [],
              antonyms: [],
              example: "Jones hit for the cycle in the game.",
            },
            {
              definition:
                "A closed walk or path, with or without repeated vertices allowed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(algebraic topology) A chain whose boundary is zero.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An imaginary circle or orbit in the heavens; one of the celestial spheres.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An age; a long period of time.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An orderly list for a given time; a calendar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One entire round in a circle or a spire.",
              synonyms: [],
              antonyms: [],
              example: "a cycle or set of leaves",
            },
            {
              definition: "A discharge of a taser.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To ride a bicycle or other cycle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To go through a cycle or to put through a cycle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To turn power off and back on",
              synonyms: [],
              antonyms: [],
              example: "Avoid cycling the device unnecessarily.",
            },
            {
              definition:
                "To maintain a team's possession of the puck in the offensive zone by handling and passing the puck in a loop from the boards near the goal up the side boards and passing to back to the boards near the goal",
              synonyms: [],
              antonyms: [],
              example: "They have their cycling game going tonight.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cycle"],
    },
  ],
  movie: [
    {
      word: "movie",
      phonetic: "/ˈmuːvi/",
      phonetics: [
        {
          text: "/ˈmuːvi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/movie-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1231871",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A recorded sequence of images displayed on a screen at a rate sufficiently fast to create the appearance of motion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(usually plural) A cinema.",
              synonyms: [],
              antonyms: [],
              example: "Let's go to the movies.",
            },
          ],
          synonyms: [
            "film",
            "flick",
            "motion picture",
            "motion-picture show",
            "moving picture",
            "moving-picture show",
            "photoplay",
            "picture",
            "picture show",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/movie"],
    },
  ],
  slope: [
    {
      word: "slope",
      phonetic: "/sləʊp/",
      phonetics: [
        { text: "/sləʊp/", audio: "" },
        {
          text: "/sloʊp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/slope-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=424753",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An area of ground that tends evenly upward or downward.",
              synonyms: [],
              antonyms: [],
              example: "I had to climb a small slope to get to the site.",
            },
            {
              definition:
                "The degree to which a surface tends upward or downward.",
              synonyms: [],
              antonyms: [],
              example:
                "The road has a very sharp downward slope at that point.",
            },
            {
              definition:
                "The ratio of the vertical and horizontal distances between two points on a line; zero if the line is horizontal, undefined if it is vertical.",
              synonyms: [],
              antonyms: [],
              example: "The slope of this line is 0.5",
            },
            {
              definition:
                "The slope of the line tangent to a curve at a given point.",
              synonyms: [],
              antonyms: [],
              example: "The slope of a parabola increases linearly with x.",
            },
            {
              definition:
                "The angle a roof surface makes with the horizontal, expressed as a ratio of the units of vertical rise to the units of horizontal length (sometimes referred to as run).",
              synonyms: [],
              antonyms: [],
              example:
                "The slope of an asphalt shingle roof system should be 4:12 or greater.",
            },
            {
              definition: "A person of Chinese or other East Asian descent.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "bank",
            "embankment",
            "gradient",
            "hill",
            "incline",
            "gradient",
            "first derivative",
            "gradient",
            "Chinaman",
            "Chink",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To tend steadily upward or downward.",
              synonyms: [],
              antonyms: [],
              example: "The road slopes sharply down at that point.",
            },
            {
              definition:
                "To form with a slope; to give an oblique or slanting direction to; to incline or slant.",
              synonyms: [],
              antonyms: [],
              example:
                "to slope the ground in a garden;   to slope a piece of cloth in cutting a garment",
            },
            {
              definition:
                "(usually followed by a preposition) To try to move surreptitiously.",
              synonyms: [],
              antonyms: [],
              example:
                "I sloped in through the back door, hoping my boss wouldn't see me.",
            },
            {
              definition:
                "To hold a rifle at a slope with forearm perpendicular to the body in front holding the butt, the rifle resting on the shoulder.",
              synonyms: [],
              antonyms: [],
              example: 'The order was given to "slope arms".',
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [{ definition: "Sloping.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            { definition: "Slopingly", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/slope"],
    },
  ],
  canoe: [
    {
      word: "canoe",
      phonetic: "/kəˈnuː/",
      phonetics: [
        {
          text: "/kəˈnuː/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/canoe-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100922",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small long and narrow boat, propelled by one or more people (depending on the size of canoe), using single-bladed paddles. The paddlers face in the direction of travel, in either a seated position, or kneeling on the bottom of the boat. Canoes are open on top, and pointed at both ends.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An oversize, usually older, luxury car.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of the deflectors positioned around a roulette wheel, shaped like upside-down boats.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To ride or paddle a canoe.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/canoe"],
    },
  ],
  teams: [
    {
      word: "teams",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A set of draught animals, such as two horses in front of a carriage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any group of people involved in the same activity, especially sports or work.",
              synonyms: [],
              antonyms: [],
              example:
                "The IT manager leads a team of three software developers.",
            },
            {
              definition:
                "A group of animals moving together, especially young ducks.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A royalty or privilege granted by royal charter to a lord of a manor, of having, keeping, and judging in his court, his bondmen, neifes, and villains, and their offspring, or suit, that is, goods and chattels, and appurtenances thereto.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To form a group, as for sports or work.",
              synonyms: [],
              antonyms: [],
              example: "They teamed to complete the project.",
            },
            {
              definition: "(by extension) To go together well; to harmonize.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To convey or haul with a team.",
              synonyms: [],
              antonyms: [],
              example: "to team lumber",
            },
            {
              definition: "To form together into a team.",
              synonyms: [],
              antonyms: [],
              example: "to team oxen",
            },
            {
              definition: "To give work to a gang under a subcontractor.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/team",
        "https://en.wiktionary.org/wiki/teams",
      ],
    },
  ],
  folks: [
    {
      word: "folks",
      phonetic: "/fəʊks/",
      phonetics: [
        { text: "/fəʊks/", audio: "" },
        {
          text: "/foʊks/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/folks-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=653248",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A grouping of smaller peoples or tribes as a nation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The inhabitants of a region, especially the native inhabitants.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(plural: folks) One’s relatives, especially one’s parents.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Folk music.", synonyms: [], antonyms: [] },
            {
              definition: "People in general.",
              synonyms: [],
              antonyms: [],
              example:
                "Young folk, old folk, everybody come, / To our little Sunday School and have a lot of fun.",
            },
            {
              definition: "A particular group of people.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The members of one's immediate family, especially one's parents",
              synonyms: [],
              antonyms: [],
              example: "My folks visit us at Christmas.",
            },
            {
              definition: "People in general; everybody or anybody.",
              synonyms: [],
              antonyms: [],
              example: "Lots of folks like to travel during the holidays.",
            },
            { definition: "The police.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/folk",
        "https://en.wiktionary.org/wiki/folks",
      ],
    },
    {
      word: "folks",
      phonetic: "/fəʊks/",
      phonetics: [
        { text: "/fəʊks/", audio: "" },
        {
          text: "/foʊks/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/folks-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=653248",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(California) Late 19th and early 20th century migrants to California from Iowa and other parts of the Midwestern United States.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/folks"],
    },
  ],
  fired: [
    {
      word: "fired",
      phonetic: "/ˈfaɪəd/",
      phonetics: [
        { text: "/ˈfaɪəd/", audio: "" },
        {
          text: "/ˈfaɪ(ə)ɹd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fired-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749846",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To set (something, often a building) on fire.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To heat as with fire, but without setting on fire, as ceramic, metal objects, etc.",
              synonyms: [],
              antonyms: [],
              example:
                "If you fire the pottery at too high a temperature, it may crack.",
            },
            {
              definition: "To drive away by setting a fire.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To terminate the employment contract of (an employee), especially for cause (such as misconduct or poor performance).",
              synonyms: [],
              antonyms: ["hire"],
            },
            {
              definition: "To shoot (a gun or analogous device).",
              synonyms: [],
              antonyms: [],
              example: "He fired his radar gun at passing cars.",
            },
            {
              definition: "To shoot a gun, cannon, or similar weapon.",
              synonyms: ["open fire", "shoot"],
              antonyms: [],
              example: "Don't fire until you see the whites of their eyes.",
            },
            {
              definition: "To shoot; to attempt to score a goal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause an action potential in a cell.",
              synonyms: [],
              antonyms: [],
              example: "When a neuron fires, it transmits information.",
            },
            {
              definition: "To forcibly direct (something).",
              synonyms: [],
              antonyms: [],
              example: "He answered the questions the reporters fired at him.",
            },
            {
              definition:
                "(computer sciences, software engineering) To initiate an event (by means of an event handler).",
              synonyms: [],
              antonyms: [],
              example:
                "The event handler should only fire after all web page content has finished loading.",
            },
            {
              definition: "To inflame; to irritate, as the passions.",
              synonyms: [],
              antonyms: [],
              example: "to fire the soul with anger, pride, or revenge",
            },
            {
              definition: "To animate; to give life or spirit to.",
              synonyms: [],
              antonyms: [],
              example: "to fire the genius of a young man",
            },
            {
              definition: "To feed or serve the fire of.",
              synonyms: [],
              antonyms: [],
              example: "to fire a boiler",
            },
            {
              definition: "To light up as if by fire; to illuminate.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To cauterize.", synonyms: [], antonyms: [] },
            {
              definition: "To catch fire; to be kindled.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be irritated or inflamed with passion.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "set on fire",
            "cards",
            "dehire",
            "dismiss",
            "give the boot",
            "give the elbow",
            "give the old heave-ho",
            "let go",
            "redundant",
            "sack",
            "terminate",
            "throw out",
            "unhire",
            "let off",
            "loose",
            "shoot",
            "open fire",
            "shoot",
          ],
          antonyms: ["hire"],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Dismissed, let go from a job.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Heated in a furnace, kiln, etc., to become permanently hardened.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fire",
        "https://en.wiktionary.org/wiki/fired",
      ],
    },
  ],
  bands: [
    {
      word: "bands",
      phonetic: "/bændz/",
      phonetics: [
        {
          text: "/bændz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bands-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2707454",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A strip of material used for strengthening or coupling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A long strip of material, color, etc, that is different from the surrounding area.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A strip of decoration.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That which serves as the means of union or connection between persons; a tie.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A linen collar or ruff worn in the 16th and 17th centuries.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in the plural) Two strips of linen hanging from the neck in front as part of a clerical, legal, or academic dress.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A part of the electromagnetic spectrum.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A group of energy levels in a solid state material.",
              synonyms: [],
              antonyms: [],
              example: "valence band;  conduction band",
            },
            { definition: "A bond.", synonyms: [], antonyms: [] },
            { definition: "Pledge; security.", synonyms: [], antonyms: [] },
            {
              definition:
                "A ring, such as a wedding ring (wedding band), or a ring put on a bird's leg to identify it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any distinguishing line formed by chromatography, electrophoresis etc",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Short for band cell.", synonyms: [], antonyms: [] },
            {
              definition:
                "(hiphop, often in the plural) A wad of money totaling $1K, held together by a band; (by extension) money",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To fasten with a band.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To fasten an identifying band around the leg of (a bird).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A group of musicians who perform together as an ensemble, usually for a professional recording artist.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A type of orchestra originally playing janissary music.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A marching band.", synonyms: [], antonyms: [] },
            {
              definition:
                "A group of people loosely united for a common purpose (a band of thieves).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small group of people living in a simple society, contrasted with tribes, chiefdoms, and states.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A group of aboriginals that has official recognition as an organized unit by the federal government of Canada.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To group together for a common purpose; to confederate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To group (students) together by perceived ability; to stream.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/band",
        "https://en.wiktionary.org/wiki/bands",
      ],
    },
  ],
  thumb: [
    {
      word: "thumb",
      phonetic: "/θʌm/",
      phonetics: [
        {
          text: "/θʌm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/thumb-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=243182",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The short thick digit of the hand that for humans has the most mobility and can be made to oppose (moved to touch) all of the other fingers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The part of a slider that may be moved linearly along the slider.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A thumbnail picture.",
              synonyms: [],
              antonyms: [],
              example:
                '2001, "Gary", Wanna See Porn? Take a Look At These (Free Expandable Thumbs) - CLICK HERE (on newsgroup alt.sex.services)',
            },
          ],
          synonyms: ["digit I", "first digit", "pollex", "thumby"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To touch or cover with the thumb.",
              synonyms: [],
              antonyms: [],
              example: "to thumb the touch-hole of a cannon",
            },
            {
              definition:
                "(with through) To turn the pages of (a book) in order to read it cursorily.",
              synonyms: [],
              antonyms: [],
              example:
                "I thumbed through the book and decided not to bother reading it all.",
            },
            {
              definition: "(travel) To hitchhike",
              synonyms: [],
              antonyms: [],
              example: "So I started thumbin' back east, toward my hometown.",
            },
            {
              definition:
                "To soil or wear with the thumb or the fingers; to soil, or wear out, by frequent handling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To manipulate (an object) with the thumb; especially, to pull back the hammer or open the cylinder of a revolver.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To fire (a single action revolver) quickly by pulling the hammer while keeping the trigger depressed.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["browse", "leaf", "page", "peruse"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/thumb"],
    },
  ],
  shout: [
    {
      word: "shout",
      phonetic: "/ʃaʊt/",
      phonetics: [
        {
          text: "/ʃaʊt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shout-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75649839",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ʃʌʊt/", audio: "" },
        {
          text: "/ʃaʊt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shout-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780373",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A loud burst of voice or voices; a violent and sudden outcry, especially that of a multitude expressing joy, triumph, exultation, anger, or animated courage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A round of drinks in a pub; the turn to pay the shot or scot; an act of paying for a round of drinks.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A call-out for an emergency services team.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A greeting, name-check or other mention, for example on a radio or TV programme.",
              synonyms: ["shout out"],
              antonyms: [],
              example:
                "Next up the new single from Beyoncé, but first a shout to Barry Bloggins and his wife Belinda...",
            },
          ],
          synonyms: ["shout out"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To utter a sudden and loud cry, as in joy, triumph, exultation or anger, or to attract attention, to animate others, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To utter with a shout; to cry; to shout out",
              synonyms: [],
              antonyms: [],
              example: "They shouted his name to get his attention.",
            },
            {
              definition: "To pay for food, drink or entertainment for others.",
              synonyms: [],
              antonyms: [],
              example: "He′s shouting us all to the opening night of the play.",
            },
            {
              definition:
                "To post a text message (for example, email) in upper case, regarded as the electronic messaging equivalent of oral shouting.",
              synonyms: [],
              antonyms: [],
              example: "Please don't shout in the chat room.",
            },
            {
              definition: "To treat with shouts or clamor.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shout"],
    },
    {
      word: "shout",
      phonetic: "/ʃaʊt/",
      phonetics: [
        {
          text: "/ʃaʊt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shout-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75649839",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ʃʌʊt/", audio: "" },
        {
          text: "/ʃaʊt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shout-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780373",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A light flat-bottomed boat used in duck-shooting.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shout"],
    },
  ],
  canal: [
    {
      word: "canal",
      phonetic: "/kəˈnɛl/",
      phonetics: [
        { text: "/kəˈnɛl/", audio: "" },
        {
          text: "/kəˈnæl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/canal-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9014313",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An artificial waterway or artificially improved river used for travel, shipping, or irrigation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A tubular channel within the body.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the faint, hazy markings resembling straight lines on early telescopic images of the surface of Mars.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To dig an artificial waterway in or to (a place), especially for drainage",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To travel along a canal by boat",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/canal"],
    },
  ],
  habit: [
    {
      word: "habit",
      phonetic: "/ˈhæbət/",
      phonetics: [
        {
          text: "/ˈhæbət/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/habit-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1648255",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An action performed on a regular basis.",
              synonyms: ["wont"],
              antonyms: [],
              example:
                "It’s become a habit of mine to have a cup of coffee after dinner.",
            },
            {
              definition:
                "An action performed repeatedly and automatically, usually without awareness.",
              synonyms: [],
              antonyms: [],
              example:
                "By force of habit, he dressed for work even though it was holiday.",
            },
            {
              definition: "A long piece of clothing worn by monks and nuns.",
              synonyms: [],
              antonyms: [],
              example:
                "It’s interesting how Catholic and Buddhist monks both wear habits.",
            },
            {
              definition:
                "A piece of clothing worn uniformly for a specific activity.",
              synonyms: [],
              antonyms: [],
              example: "The new riding habits of the team looked smashing!",
            },
            {
              definition: "Outward appearance; attire; dress.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Form of growth or general appearance of a variety or species of plant or crystal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An addiction.",
              synonyms: [],
              antonyms: [],
              example: "He has a 10-cigar habit.",
            },
          ],
          synonyms: ["wont"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/habit"],
    },
    {
      word: "habit",
      phonetic: "/ˈhæbət/",
      phonetics: [
        {
          text: "/ˈhæbət/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/habit-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1648255",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To clothe.", synonyms: [], antonyms: [] },
            { definition: "To inhabit.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/habit"],
    },
  ],
  reply: [
    {
      word: "reply",
      phonetic: "/ɹɪˈplaɪ/",
      phonetics: [
        {
          text: "/ɹɪˈplaɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/reply-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1243836",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A written or spoken response; part of a conversation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Something given in reply.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A counterattack.", synonyms: [], antonyms: [] },
            {
              definition: "The answer of a figure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A document written by a party specifically replying to a responsive declaration and in some cases an answer.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "account",
            "answer",
            "comeback",
            "reaction",
            "rejoinder",
            "response",
            "retort",
            "return",
            "riposte",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To give a written or spoken response, especially to a question, request, accusation or criticism; to answer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To act or gesture in response.",
              synonyms: [],
              antonyms: [],
              example:
                "Joanne replied to Pete's insult with a slap to his face.",
            },
            {
              definition: "To repeat something back; to echo.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "answer",
            "answer back",
            "counter",
            "follow up",
            "get back to",
            "react",
            "rejoin",
            "respond",
            "retort",
            "return",
            "revert",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/reply"],
    },
  ],
  ruled: [
    {
      word: "ruled",
      phonetic: "/ɹuːld/",
      phonetics: [
        {
          text: "/ɹuːld/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ruled-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2454273",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To regulate, be in charge of, make decisions for, reign over.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To excel.",
              synonyms: [],
              antonyms: [],
              example: "This game rules!",
            },
            {
              definition: "To mark (paper or the like) with rules (lines).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To decide judicially.", synonyms: [], antonyms: [] },
            {
              definition:
                "To establish or settle by, or as by, a rule; to fix by universal or general consent, or by common practice.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To revel.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having printed lines.",
              synonyms: [],
              antonyms: [],
              example: "a letter on ruled paper",
            },
            {
              definition:
                "(of a surface) Being a scroll; being such that through every point of S there is a straight line that lies on S.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rule",
        "https://en.wiktionary.org/wiki/ruled",
      ],
    },
  ],
  fever: [
    {
      word: "fever",
      phonetic: "/ˈfiːvə/",
      phonetics: [
        { text: "/ˈfiːvə/", audio: "" },
        {
          text: "/ˈfivɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fever-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749842",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A higher than normal body temperature of a person (or, generally, a mammal), usually caused by disease.",
              synonyms: [],
              antonyms: [],
              example: '"I have a fever. I think I\'ve the flu."',
            },
            {
              definition:
                "(usually in combination with one or more preceding words) Any of various diseases.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A state of excitement or anxiety.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A group of stingrays.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "excitation",
            "excitement",
            "passion",
            "pyrexia",
            "temperature",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To put into a fever; to affect with fever.",
              synonyms: [],
              antonyms: [],
              example: "a fevered lip",
            },
            { definition: "To become fevered.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fever"],
    },
  ],
  crust: [
    {
      word: "crust",
      phonetic: "/kɹʌst/",
      phonetics: [
        {
          text: "/kɹʌst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/crust-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88675797",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A more solid, dense or hard layer on a surface or boundary.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The external, hardened layer of certain foodstuffs, including most types of bread, fried meat, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An outer layer composed of pastry",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The bread-like base of a pizza.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The outermost layer of the lithosphere of the Earth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The shell of crabs, lobsters, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Nerve, gall.",
              synonyms: [],
              antonyms: [],
              example: "You've got a lot of crust standing there saying that.",
            },
            {
              definition: "A living.",
              synonyms: ["daily bread", "income", "livelihood"],
              antonyms: [],
              example: "to earn one's crust",
            },
          ],
          synonyms: ["daily bread", "income", "livelihood"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cover with a crust.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To form a crust.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A subgenre of punk rock, derived from hardcore punk with extreme metal elements, often with pessimistic lyrics about political and social ills.",
              synonyms: ["crust", "crustcore", "stenchcore"],
              antonyms: [],
            },
          ],
          synonyms: ["crust", "crustcore", "stenchcore"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/crust",
        "https://en.wiktionary.org/wiki/crust%20punk",
      ],
    },
  ],
  shelf: [
    {
      word: "shelf",
      phonetic: "/ʃɛlf/",
      phonetics: [{ text: "/ʃɛlf/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A flat, rigid structure, fixed at right angles to a wall or forming a part of a cabinet, desk etc., and used to support, store or display objects.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The capacity of such an object",
              synonyms: [],
              antonyms: [],
              example: "a shelf of videos",
            },
            {
              definition: "A projecting ledge that resembles such an object.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A reef, shoal or sandbar.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["shelfful"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shelf"],
    },
  ],
  walks: [
    {
      word: "walks",
      phonetic: "/wɑks/",
      phonetics: [
        { text: "/wɑks/", audio: "" },
        { text: "/wɔːks/", audio: "" },
        {
          text: "/wɔks/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/walks-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100561",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To move on the feet by alternately setting each foot (or pair or group of feet, in the case of animals with four or more feet) forward, with at least one foot on the ground at all times. Compare run.",
              synonyms: [],
              antonyms: [],
              example: "To walk briskly for an hour every day is to keep fit.",
            },
            {
              definition:
                'To "walk free", i.e. to win, or avoid, a criminal court case, particularly when actually guilty.',
              synonyms: [],
              antonyms: [],
              example:
                "If you can’t present a better case, that robber is going to walk.",
            },
            {
              definition: "Of an object, to go missing or be stolen.",
              synonyms: [],
              antonyms: [],
              example:
                "If you leave your wallet lying around, it’s going to walk.",
            },
            {
              definition:
                "(of a batsman) To walk off the field, as if given out, after the fielding side appeals and before the umpire has ruled; done as a matter of sportsmanship when the batsman believes he is out.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To travel (a distance) by walking.",
              synonyms: [],
              antonyms: [],
              example:
                "I walk two miles to school every day.  The museum’s not far from here – you can walk it.",
            },
            {
              definition: "To take for a walk or accompany on a walk.",
              synonyms: [],
              antonyms: [],
              example: "I walk the dog every morning.  Will you walk me home?",
            },
            {
              definition:
                "To allow a batter to reach base by pitching four balls.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To move something by shifting between two positions, as if it were walking.",
              synonyms: [],
              antonyms: [],
              example: "I carefully walked the ladder along the wall.",
            },
            {
              definition:
                "To full; to beat cloth to give it the consistency of felt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To traverse by walking (or analogous gradual movement).",
              synonyms: [],
              antonyms: [],
              example:
                "I walked the streets aimlessly.   Debugging this computer program involved walking the heap.",
            },
            {
              definition:
                "To operate the left and right throttles of (an aircraft) in alternation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To leave, resign.",
              synonyms: [],
              antonyms: [],
              example: "If we don't offer him more money he'll walk.",
            },
            {
              definition: "To push (a vehicle) alongside oneself as one walks.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To behave; to pursue a course of life; to conduct oneself.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be stirring; to be abroad; to go restlessly about; said of things or persons expected to remain quiet, such as a sleeping person, or the spirit of a dead person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be in motion; to act; to move.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To put, keep, or train (a puppy) in a walk, or training area for dogfighting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(hotel) To move a guest to another hotel if their confirmed reservation is not available on day of check-in.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "nicked",
            "pinched",
            "stolen",
            "full",
            "waulk",
            "acquitted",
            "get off",
            "go free",
          ],
          antonyms: ["run"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A trip made by walking.",
              synonyms: [],
              antonyms: [],
              example: "I take a walk every morning",
            },
            {
              definition: "A distance walked.",
              synonyms: [],
              antonyms: [],
              example: "It’s a long walk from my house to the library",
            },
            {
              definition:
                "An Olympic Games track event requiring that the heel of the leading foot touch the ground before the toe of the trailing foot leaves the ground.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A manner of walking; a person's style of walking.",
              synonyms: [],
              antonyms: [],
              example: "The Ministry of Silly Walks is underfunded this year",
            },
            {
              definition:
                "A path, sidewalk/pavement or other maintained place on which to walk. Compare trail.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A situation where all players fold to the big blind, as their first action (instead of calling or raising), once they get their cards.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'An award of first base to a batter following four balls being thrown by the pitcher; known in the rules as a "base on balls".',
              synonyms: [],
              antonyms: [],
              example: "The pitcher now has two walks in this inning alone",
            },
            {
              definition:
                "In coffee, coconut, and other plantations, the space between them.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Belize) An area of an estate planted with fruit-bearing trees.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A place for keeping and training puppies for dogfighting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An enclosed area in which a gamecock is confined to prepare him for fighting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sequence of alternating vertices and edges, where each edge's endpoints are the preceding and following vertices in the sequence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something very easily accomplished; a walk in the park.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'A cheque drawn on a bank that was not a member of the London Clearing and whose sort code was allocated on a one-off basis; they had to be "walked" (hand-delivered by messengers).',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "hike",
            "trek",
            "gait",
            "footpath",
            "path",
            "pavement",
            "sidewalk",
            "hike",
            "stroll",
            "trek",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/walk",
        "https://en.wiktionary.org/wiki/walks",
      ],
    },
  ],
  midst: [
    {
      word: "midst",
      phonetic: "/mɪdst/",
      phonetics: [
        {
          text: "/mɪdst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/midst-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1648269",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A place in the middle of something; may be used of a literal or metaphorical location.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["center", "centre", "middle"],
          antonyms: [],
        },
        {
          partOfSpeech: "preposition",
          definitions: [
            {
              definition: "Among, in the middle of; amid.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["amid", "amidst"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/midst"],
    },
  ],
  crack: [
    {
      word: "crack",
      phonetic: "/kɹæk/",
      phonetics: [
        {
          text: "/kɹæk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/crack-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=840676",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A thin and usually jagged space opened in a previously solid material.",
              synonyms: [],
              antonyms: [],
              example: "A large crack had formed in the roadway.",
            },
            {
              definition: "A narrow opening.",
              synonyms: [],
              antonyms: [],
              example: "Open the door a crack.",
            },
            {
              definition: "A sharply humorous comment; a wisecrack.",
              synonyms: [],
              antonyms: [],
              example: "I didn't appreciate that crack about my hairstyle.",
            },
            {
              definition:
                "Crack cocaine, a potent, relatively cheap, addictive variety of cocaine; often a rock, usually smoked through a crack-pipe.",
              synonyms: [],
              antonyms: [],
              example: "crack head",
            },
            {
              definition: "The sharp sound made when solid material breaks.",
              synonyms: [],
              antonyms: [],
              example:
                "The crack of the falling branch could be heard for miles.",
            },
            {
              definition: "Any sharp sound.",
              synonyms: [],
              antonyms: [],
              example: "The crack of the bat hitting the ball.",
            },
            {
              definition: "A sharp, resounding blow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An attempt at something.",
              synonyms: [],
              antonyms: [],
              example: "I'd like to take a crack at that game.",
            },
            {
              definition: "Vagina.",
              synonyms: [],
              antonyms: [],
              example: "I'm so horny even the crack of dawn isn't safe!",
            },
            {
              definition: "The space between the buttocks.",
              synonyms: [],
              antonyms: [],
              example: "Pull up your pants! Your crack is showing.",
            },
            {
              definition:
                "Conviviality; fun; good conversation, chat, gossip, or humorous storytelling; good company.",
              synonyms: [],
              antonyms: [],
              example: "He/she is quare good crack.",
            },
            {
              definition: "Business; events; news.",
              synonyms: [],
              antonyms: [],
              example: "What's the crack?",
            },
            {
              definition:
                "A program or procedure designed to circumvent restrictions or usage limits on software.",
              synonyms: [],
              antonyms: [],
              example: "Has anyone got a crack for DocumentWriter 3.0?",
            },
            {
              definition:
                "(elsewhere throughout the North of the UK) a meaningful chat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Extremely silly, absurd or off-the-wall ideas or prose.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The tone of voice when changed at puberty.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A mental flaw; a touch of craziness; partial insanity.",
              synonyms: [],
              antonyms: [],
              example: "He has a crack.",
            },
            {
              definition: "A crazy or crack-brained person.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A boast; boasting.", synonyms: [], antonyms: [] },
            { definition: "Breach of chastity.", synonyms: [], antonyms: [] },
            {
              definition: "A boy, generally a pert, lively boy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A brief time; an instant; a jiffy.",
              synonyms: [],
              antonyms: [],
              example: "I'll be with you in a crack.",
            },
          ],
          synonyms: ["crackpot", "crack cocaine", "arse", "ass", "bum"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To form cracks.",
              synonyms: [],
              antonyms: [],
              example: "It's been so dry, the ground is starting to crack.",
            },
            {
              definition: "To break apart under pressure.",
              synonyms: [],
              antonyms: [],
              example: "When I tried to stand on the chair, it cracked.",
            },
            {
              definition: "To become debilitated by psychological pressure.",
              synonyms: [],
              antonyms: [],
              example: "Anyone would crack after being hounded like that.",
            },
            {
              definition:
                "To break down or yield, especially under interrogation or torture.",
              synonyms: [],
              antonyms: [],
              example:
                "When we showed him the pictures of the murder scene, he cracked.",
            },
            {
              definition: "To make a cracking sound.",
              synonyms: [],
              antonyms: [],
              example:
                "The bat cracked with authority and the ball went for six.",
            },
            {
              definition: "(of a voice) To change rapidly in register.",
              synonyms: [],
              antonyms: [],
              example: "His voice cracked with emotion.",
            },
            {
              definition:
                "(of a pubescent boy's voice) To alternate between high and low register in the process of eventually lowering.",
              synonyms: [],
              antonyms: [],
              example: "His voice finally cracked when he was fourteen.",
            },
            {
              definition: "To make a sharply humorous comment.",
              synonyms: [],
              antonyms: [],
              example: '"I would too, with a face like that," she cracked.',
            },
            {
              definition: "To make a crack or cracks in.",
              synonyms: [],
              antonyms: [],
              example: "The ball cracked the window.",
            },
            {
              definition:
                "To break open or crush to small pieces by impact or stress.",
              synonyms: [],
              antonyms: [],
              example: "You'll need a hammer to crack a black walnut.",
            },
            {
              definition: "To strike forcefully.",
              synonyms: [],
              antonyms: [],
              example: "She cracked him over the head with her handbag.",
            },
            {
              definition: "To open slightly.",
              synonyms: [],
              antonyms: [],
              example: "Could you please crack the window?",
            },
            {
              definition:
                "To cause to yield under interrogation or other pressure. (Figurative)",
              synonyms: [],
              antonyms: [],
              example: "They managed to crack him on the third day.",
            },
            {
              definition:
                "To solve a difficult problem. (Figurative, from cracking a nut.)",
              synonyms: [],
              antonyms: [],
              example:
                "I've finally cracked it, and of course the answer is obvious in hindsight.",
            },
            {
              definition: "To overcome a security system or a component.",
              synonyms: [],
              antonyms: [],
              example:
                "It took a minute to crack the lock, three minutes to crack the security system, and about twenty minutes to crack the safe.",
            },
            {
              definition: "To cause to make a sharp sound.",
              synonyms: [],
              antonyms: [],
              example: "to crack a whip",
            },
            {
              definition: "To tell (a joke).",
              synonyms: [],
              antonyms: [],
              example:
                "The performance was fine until he cracked that dead baby joke.",
            },
            {
              definition:
                "To break down (a complex molecule), especially with the application of heat: to pyrolyse.",
              synonyms: [],
              antonyms: [],
              example: "Acetone is cracked to ketene and methane at 700°C.",
            },
            {
              definition:
                "To circumvent software restrictions such as regional coding or time limits.",
              synonyms: [],
              antonyms: [],
              example:
                "That software licence will expire tomorrow unless we can crack it.",
            },
            {
              definition:
                "To open a canned beverage, or any packaged drink or food.",
              synonyms: [],
              antonyms: [],
              example: "I'd love to crack open a beer.",
            },
            { definition: "To brag, boast.", synonyms: [], antonyms: [] },
            {
              definition: "To be ruined or impaired; to fail.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/crack"],
    },
    {
      word: "crack",
      phonetic: "/kɹæk/",
      phonetics: [
        {
          text: "/kɹæk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/crack-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=840676",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Highly trained and competent.",
              synonyms: [],
              antonyms: [],
              example:
                "Even a crack team of investigators would have trouble solving this case.",
            },
            {
              definition: "Excellent, first-rate, superior, top-notch.",
              synonyms: [],
              antonyms: [],
              example: "She's a crack shot with that rifle.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/crack"],
    },
  ],
  print: [
    {
      word: "print",
      phonetic: "/pɹɪnt/",
      phonetics: [
        {
          text: "/pɹɪnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/print-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1135153",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Books and other material created by printing presses, considered collectively or as a medium.",
              synonyms: [],
              antonyms: [],
              example: "TV and the internet haven't killed print.",
            },
            {
              definition:
                "Clear handwriting, especially, writing without connected letters as in cursive.",
              synonyms: [],
              antonyms: [],
              example: "Write in print using block letters.",
            },
            {
              definition: "The letters forming the text of a document.",
              synonyms: [],
              antonyms: [],
              example: "The print is too small for me to read.",
            },
            { definition: "A newspaper.", synonyms: [], antonyms: [] },
            {
              definition: "A visible impression on a surface.",
              synonyms: [],
              antonyms: [],
              example:
                "Using a crayon, the girl made a print of the leaf under the page.",
            },
            {
              definition: "A fingerprint.",
              synonyms: [],
              antonyms: [],
              example: "Did the police find any prints at the scene?",
            },
            { definition: "A footprint.", synonyms: [], antonyms: [] },
            {
              definition:
                "(visual art) A picture that was created in multiple copies by printing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A photograph that has been printed onto paper from the negative.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A copy of a film that can be projected.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Cloth that has had a pattern of dye printed onto it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A plaster cast in bas relief.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["cursive"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To produce one or more copies of a text or image on a surface, especially by machine; often used with out or off: print out, print off.",
              synonyms: [],
              antonyms: [],
              example:
                "Print the draft double-spaced so we can mark changes between the lines.",
            },
            {
              definition:
                "To produce a microchip (an integrated circuit) in a process resembling the printing of an image.",
              synonyms: [],
              antonyms: [],
              example:
                "The circuitry is printed onto the semiconductor surface.",
            },
            {
              definition:
                "To write very clearly, especially, to write without connecting the letters as in cursive.",
              synonyms: [],
              antonyms: [],
              example: "I'm only in grade 2, so I only know how to print.",
            },
            {
              definition: "To publish in a book, newspaper, etc.",
              synonyms: [],
              antonyms: [],
              example: "How could they print an unfounded rumour like that?",
            },
            {
              definition:
                "To stamp or impress (something) with coloured figures or patterns.",
              synonyms: [],
              antonyms: [],
              example: "to print calico",
            },
            {
              definition:
                "To fix or impress, as a stamp, mark, character, idea, etc., into or upon something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To stamp something in or upon; to make an impression or mark upon by pressure, or as by pressure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To display a string on the terminal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To produce an observable value.",
              synonyms: [],
              antonyms: [],
              example:
                "On March 16, 2020, the S&P printed at 2,386.13, one of the worst drops in history.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Of, relating to, or writing for printed publications.",
              synonyms: [],
              antonyms: [],
              example: "a print edition of a book",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/print"],
    },
  ],
  tales: [
    {
      word: "tales",
      phonetic: "/teɪlz/",
      phonetics: [
        {
          text: "/teɪlz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tales-1-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88897053",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of several units of measure used in China and elsewhere in eastern Asia, approximately 40 grams.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of several monetary units equal to the equivalent weight in silver.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Leung, a traditional unit of weight, in modern usage legally defined as 1/16 of a catty or kan (斤) or 0.0377993638 kilograms",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An account of an asserted fact or circumstance; a rumour; a report, especially an idle or malicious story; a piece of gossip or slander; a lie.",
              synonyms: [],
              antonyms: [],
              example: "Don't tell tales!",
            },
            {
              definition:
                "A rehearsal of what has occurred; narrative; discourse; statement; history; story.",
              synonyms: [],
              antonyms: [],
              example: "the Canterbury Tales",
            },
            {
              definition:
                "A number told or counted off; a reckoning by count; an enumeration.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The fraudulent opportunity presented by a confidence man to the mark or victim.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Number; tally; quota.", synonyms: [], antonyms: [] },
            {
              definition: "Account; estimation; regard; heed.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Speech; language.", synonyms: [], antonyms: [] },
            {
              definition:
                "A speech; a statement; talk; conversation; discourse.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A count; declaration.", synonyms: [], antonyms: [] },
            {
              definition: "A number of things considered as an aggregate; sum.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A report of any matter; a relation; a version.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tael",
        "https://en.wiktionary.org/wiki/tale",
        "https://en.wiktionary.org/wiki/tales",
      ],
    },
    {
      word: "tales",
      phonetic: "/ˈteɪliːz/",
      phonetics: [
        {
          text: "/ˈteɪliːz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tales-2-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88897054",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A person available to fill vacancies in a jury.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A book or register of people available to fill jury vacancies.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A writ to summon people to court to fill vacancies in a jury.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tales"],
    },
  ],
  coach: [
    {
      word: "coach",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/coach-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75270067",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/kəʊtʃ/", audio: "" },
        {
          text: "/koʊtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/coach-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100348",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A wheeled vehicle, generally drawn by horse power.",
              synonyms: ["carriage"],
              antonyms: [],
            },
            {
              definition:
                "A passenger car, either drawn by a locomotive or part of a multiple unit.",
              synonyms: ["carriage"],
              antonyms: [],
            },
            {
              definition:
                "(originally Oxford University slang) A trainer or instructor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A single-decked long-distance, or privately hired, bus.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The forward part of the cabin space under the poop deck of a sailing ship; the fore-cabin under the quarter deck.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(chiefly US) The part of a commercial passenger airplane or train reserved for those paying the lower standard fares; the economy section.",
              synonyms: [],
              antonyms: [],
              example: "John flew coach to Vienna, but first-class back home.",
            },
          ],
          synonyms: ["carriage", "carriage"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To train.", synonyms: [], antonyms: [] },
            {
              definition: "To instruct; to train.",
              synonyms: [],
              antonyms: [],
              example: "She has coached many opera stars.",
            },
            {
              definition: "To study under a tutor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To travel in a coach (sometimes coach it).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To convey in a coach.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/coach"],
    },
  ],
  stiff: [
    {
      word: "stiff",
      phonetic: "/stɪf/",
      phonetics: [
        {
          text: "/stɪf/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stiff-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1194270",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An average person, usually male, of no particular distinction, skill, or education, often a working stiff or lucky stiff.",
              synonyms: [],
              antonyms: [],
              example:
                "A Working Stiff's Manifesto: A Memoir of Thirty Jobs I Quit, Nine That Fired Me, and Three I Can't Remember was published in 2003.",
            },
            {
              definition:
                "A person who is deceived, as a mark or pigeon in a swindle.",
              synonyms: [],
              antonyms: [],
              example:
                "She convinced the stiff to go to her hotel room, where her henchman was waiting to rob him.",
            },
            {
              definition: "A cadaver; a dead person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A flop; a commercial failure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person who leaves (especially a restaurant) without paying the bill.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) A customer who does not leave a tip.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any hard hand where it is possible to exceed 21 by drawing an additional card.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Negotiable instruments, possibly forged.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To fail to pay that which one owes (implicitly or explicitly) to another, especially by departing hastily.",
              synonyms: [],
              antonyms: [],
              example:
                "Realizing he had forgotten his wallet, he stiffed the taxi driver when the cab stopped for a red light.",
            },
            { definition: "To cheat someone", synonyms: [], antonyms: [] },
            { definition: "To tip ungenerously", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "(of an object) Rigid; hard to bend; inflexible.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of policies and rules and their application and enforcement) Inflexible; rigid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of a person) Formal in behavior; unrelaxed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Harsh, severe.",
              synonyms: [],
              antonyms: [],
              example: "He was eventually caught, and given a stiff fine.",
            },
            {
              definition:
                "(of muscles or parts of the body) Painful as a result of excessive or unaccustomed exercise.",
              synonyms: [],
              antonyms: [],
              example: "My legs are stiff after climbing that hill yesterday.",
            },
            {
              definition: "Potent.",
              synonyms: [],
              antonyms: [],
              example: "a stiff drink; a stiff dose; a stiff breeze.",
            },
            { definition: "Dead, deceased.", synonyms: [], antonyms: [] },
            { definition: "(of a penis) Erect.", synonyms: [], antonyms: [] },
            {
              definition:
                "(of whipping cream or egg whites) Beaten until so aerated that they stand up straight on their own.",
              synonyms: [],
              antonyms: [],
              example: "beat the egg whites until they are stiff",
            },
            {
              definition:
                "Of an equation: for which certain numerical solving methods are numerically unstable, unless the step size is taken to be extremely small.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Keeping upright.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stiff"],
    },
  ],
  flood: [
    {
      word: "flood",
      phonetic: "/flʌd/",
      phonetics: [
        {
          text: "/flʌd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/flood-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769943",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A (usually disastrous) overflow of water from a lake or other body of water due to excessive rainfall or other input of water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A large number or quantity of anything appearing more rapidly than can easily be dealt with.",
              synonyms: [],
              antonyms: [],
              example: "a flood of complaints",
            },
            {
              definition: "The flowing in of the tide, opposed to the ebb.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A floodlight.", synonyms: [], antonyms: [] },
            {
              definition: "Menstrual discharge; menses.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Water as opposed to land.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To overflow, as by water from excessive rainfall.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cover or partly fill as if by a flood.",
              synonyms: [],
              antonyms: [],
              example: "The floor was flooded with beer.",
            },
            {
              definition:
                "To provide (someone or something) with a larger number or quantity of something than can easily be dealt with.",
              synonyms: [],
              antonyms: [],
              example:
                "The station's switchboard was flooded with listeners making complaints.",
            },
            {
              definition:
                "To paste numerous lines of text to (a chat system) in order to disrupt the conversation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To bleed profusely, as after childbirth.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["inundate", "overfill", "deluge", "inundate", "swamp"],
          antonyms: ["drain"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/flood"],
    },
  ],
  verse: [
    {
      word: "verse",
      phonetic: "/ˈvɜːs/",
      phonetics: [
        { text: "/ˈvɜːs/", audio: "" },
        {
          text: "/ˈvɝs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/verse-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=589449",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A poetic form with regular meter and a fixed rhyme scheme.",
              synonyms: ["poetry"],
              antonyms: [],
              example:
                "Restoration literature is well known for its carefully constructed verse.",
            },
            {
              definition: "Poetic form in general.",
              synonyms: [],
              antonyms: [],
              example:
                "The restrictions of verse have been steadily relaxed over time.",
            },
            {
              definition:
                "One of several similar units of a song, consisting of several lines, generally rhymed.",
              synonyms: ["stanza"],
              antonyms: [],
              example:
                "Note the shift in tone between the first verse and the second.",
            },
            {
              definition: "A small section of the Jewish or Christian Bible.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A portion of an anthem to be performed by a single voice to each part.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["poetry", "stanza"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To compose verses.", synonyms: [], antonyms: [] },
            {
              definition: "To tell in verse, or poetry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To educate about, to teach about.",
              synonyms: [],
              antonyms: [],
              example: "He versed us in the finer points of category theory.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/verse"],
    },
    {
      word: "verse",
      phonetic: "/ˈvɜːs/",
      phonetics: [
        { text: "/ˈvɜːs/", audio: "" },
        {
          text: "/ˈvɝs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/verse-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=589449",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To oppose, to compete against, especially in a video game.",
              synonyms: [],
              antonyms: [],
              example: "Verse him, G!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/verse"],
    },
  ],
  awake: [
    {
      word: "awake",
      phonetic: "/əˈweɪk/",
      phonetics: [
        {
          text: "/əˈweɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/awake-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=718901",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Not asleep; conscious.",
              synonyms: ["conscious", "lucid", "wide awake"],
              antonyms: ["asleep", "unconscious"],
            },
            {
              definition: "(by extension) Alert, aware.",
              synonyms: ["wary", "woke"],
              antonyms: ["heedless", "oblivious"],
              example:
                "They were awake to the possibility of a decline in sales.",
            },
          ],
          synonyms: ["conscious", "lucid", "wide awake", "wary", "woke"],
          antonyms: ["asleep", "unconscious", "heedless", "oblivious"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/awake"],
    },
    {
      word: "awake",
      phonetic: "/əˈweɪk/",
      phonetics: [
        {
          text: "/əˈweɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/awake-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=718901",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To become conscious after having slept.",
              synonyms: ["awaken", "wake up"],
              antonyms: ["fall asleep"],
            },
            {
              definition: "To cause (somebody) to stop sleeping.",
              synonyms: ["bring round", "cry", "wake up"],
              antonyms: ["put to sleep"],
            },
            {
              definition: "To excite or to stir up something latent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To rouse from a state of inaction or dormancy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To come out of a state of inaction or dormancy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["awaken", "wake up", "bring round", "cry", "wake up"],
          antonyms: ["fall asleep", "put to sleep"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/awake"],
    },
  ],
  rocky: [
    {
      word: "rocky",
      phonetic: "[ˈɹʷɔkiː]",
      phonetics: [
        { text: "[ˈɹʷɔkiː]", audio: "" },
        { text: "/ˈɹɒki/", audio: "" },
        { text: "[ˈɹʷɒki]", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Unstable; easily rocked.",
              synonyms: [],
              antonyms: [],
              example: "The table was rocky, so we put a book under one leg.",
            },
            {
              definition: "In the style of rock music.",
              synonyms: [],
              antonyms: [],
              example: "His new album is quite rocky.",
            },
            {
              definition: "Troubled; or difficult; in danger or distress.",
              synonyms: [],
              antonyms: [],
              example:
                "Their relationship had weathered some rocky times, but they loved each other.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rocky"],
    },
    {
      word: "rocky",
      phonetic: "[ˈɹʷɔkiː]",
      phonetics: [
        { text: "[ˈɹʷɔkiː]", audio: "" },
        { text: "/ˈɹɒki/", audio: "" },
        { text: "[ˈɹʷɒki]", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Full of, or abounding in, rocks; consisting of rocks.",
              synonyms: [],
              antonyms: [],
              example: "a rocky mountain",
            },
            {
              definition: "Like a rock.",
              synonyms: [],
              antonyms: [],
              example: "the rocky orb of a shield",
            },
            {
              definition:
                "Not easily impressed or affected; hard; unfeeling; obdurate",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rocky"],
    },
  ],
  march: [
    {
      word: "march",
      phonetic: "/mɑːtʃ/",
      phonetics: [
        { text: "/mɑːtʃ/", audio: "" },
        {
          text: "/mɑɹtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/march-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=473892",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A formal, rhythmic way of walking, used especially by soldiers, bands and in ceremonies.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A political rally or parade",
              synonyms: ["parade", "protest", "rally"],
              antonyms: [],
            },
            {
              definition:
                "Any song in the genre of music written for marching (see Wikipedia's article on this type of music)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Steady forward movement or progression.",
              synonyms: ["advancement", "process", "progression"],
              antonyms: [],
              example: "the march of time",
            },
            {
              definition:
                "(euchre) The feat of taking all the tricks of a hand.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "advancement",
            "process",
            "progression",
            "parade",
            "protest",
            "rally",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To walk with long, regular strides, as a soldier does.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause someone to walk somewhere.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To go to war; to make military advances.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make steady progress.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/march"],
    },
    {
      word: "march",
      phonetic: "/mɑːtʃ/",
      phonetics: [
        { text: "/mɑːtʃ/", audio: "" },
        {
          text: "/mɑɹtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/march-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=473892",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A border region, especially one originally set up to defend a boundary.",
              synonyms: ["frontier", "marchland"],
              antonyms: [],
            },
            {
              definition: "A region at a frontier governed by a marquess.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various territories with similar meanings or etymologies in their native languages.",
              synonyms: ["county palatinate", "county palatine"],
              antonyms: [],
            },
          ],
          synonyms: [
            "county palatinate",
            "county palatine",
            "frontier",
            "marchland",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To have common borders or frontiers",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/march"],
    },
    {
      word: "march",
      phonetic: "/mɑːtʃ/",
      phonetics: [
        { text: "/mɑːtʃ/", audio: "" },
        {
          text: "/mɑɹtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/march-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=473892",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Smallage.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["smallage"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/march"],
    },
  ],
  fault: [
    {
      word: "fault",
      phonetic: "/fɒlt/",
      phonetics: [
        {
          text: "/fɒlt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fault-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1935413",
        },
        { text: "/fɔlt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A defect; something that detracts from perfection.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A mistake or error.",
              synonyms: [],
              antonyms: [],
              example: "No! This is my fault, not yours.",
            },
            {
              definition: "A weakness of character; a failing.",
              synonyms: [],
              antonyms: [],
              example: "For all her faults, she’s a good person at heart.",
            },
            { definition: "A minor offense.", synonyms: [], antonyms: [] },
            {
              definition: "Blame; the responsibility for a mistake.",
              synonyms: [],
              antonyms: [],
              example: "The fault lies with you.",
            },
            {
              definition:
                "A fracture in a rock formation causing a discontinuity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In coal seams, coal rendered worthless by impurities in the seam.",
              synonyms: [],
              antonyms: [],
              example: "slate fault  dirt fault",
            },
            { definition: "An illegal serve.", synonyms: [], antonyms: [] },
            {
              definition: "An abnormal connection in a circuit.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Want; lack", synonyms: [], antonyms: [] },
            {
              definition: "A lost scent; act of losing the scent.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To criticize, blame or find fault with something or someone.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To fracture.", synonyms: [], antonyms: [] },
            {
              definition: "To commit a mistake or error.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To undergo a page fault.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fault"],
    },
  ],
  swift: [
    {
      word: "swift",
      phonetic: "/swɪft/",
      phonetics: [
        {
          text: "/swɪft/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/swift-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1676874",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small plain-colored bird of the family Apodidae that resembles a swallow and is noted for its rapid flight.",
              synonyms: ["needletail", "spinetail", "swiftlet"],
              antonyms: [],
            },
            {
              definition: "Any of certain lizards of the genus Sceloporus.",
              synonyms: ["fence lizard"],
              antonyms: [],
            },
            {
              definition:
                "A moth of the family Hepialidae, swift moth, ghost moth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any of various fast-flying hesperiid butterflies.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A reel for winding yarn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The main cylinder of a carding-machine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The current of a stream.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["fence lizard", "needletail", "spinetail", "swiftlet"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Fast; quick; rapid.", synonyms: [], antonyms: [] },
            {
              definition: "Capable of moving at high speeds.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [{ definition: "Swiftly.", synonyms: [], antonyms: [] }],
          synonyms: ["swith"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/swift"],
    },
  ],
  faint: [
    {
      word: "faint",
      phonetic: "/feɪnt/",
      phonetics: [
        {
          text: "/feɪnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/faint-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1669303",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act of fainting, syncope.",
              synonyms: [],
              antonyms: [],
              example: "She suffered another faint.",
            },
            {
              definition: "The state of one who has fainted; a swoon.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(of a being) Lacking strength; weak; languid; inclined to lose consciousness",
              synonyms: [],
              antonyms: [],
              example: "I felt faint after my fifth gin and tonic.",
            },
            {
              definition:
                "Lacking courage, spirit, or energy; cowardly; dejected",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Barely perceptible; not bright, or loud, or sharp",
              synonyms: [],
              antonyms: [],
              example: "There was a faint red light in the distance.",
            },
            {
              definition:
                "Performed, done, or acted, weakly; not exhibiting vigor, strength, or energy",
              synonyms: [],
              antonyms: [],
              example: "faint efforts",
            },
            { definition: "Slight; minimal.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/faint"],
    },
    {
      word: "faint",
      phonetic: "/feɪnt/",
      phonetics: [
        {
          text: "/feɪnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/faint-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1669303",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To lose consciousness through a lack of oxygen or nutrients to the brain, usually as a result of suddenly reduced blood flow (may be caused by emotional trauma, loss of blood or various medical conditions).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To sink into dejection; to lose courage or spirit; to become depressed or despondent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To decay; to disappear; to vanish.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "black out",
            "keel over",
            "pass out",
            "sweb",
            "swoon",
            "queal",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/faint"],
    },
  ],
  civil: [
    {
      word: "civil",
      phonetic: "/ˈsɪv.əl/",
      phonetics: [
        {
          text: "/ˈsɪv.əl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/civil-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1676714",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Having to do with people and government office as opposed to the military or religion.",
              synonyms: [],
              antonyms: [],
              example:
                "She went into civil service because she wanted to help the people.",
            },
            {
              definition: "Behaving in a reasonable or polite manner.",
              synonyms: [],
              antonyms: [
                "anti-civil",
                "impolite",
                "inconsiderate",
                "noncivil",
                "rude",
              ],
              example: "It was very civil of him to stop the argument.",
            },
            {
              definition:
                "Relating to private relations among citizens, as opposed to criminal matters.",
              synonyms: [],
              antonyms: [],
              example: "a civil case",
            },
            { definition: "Secular.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [
            "anti-civil",
            "impolite",
            "inconsiderate",
            "noncivil",
            "rude",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/civil"],
    },
  ],
  ghost: [
    {
      word: "ghost",
      phonetic: "/ɡəʊst/",
      phonetics: [
        { text: "/ɡəʊst/", audio: "" },
        {
          text: "/ɡoʊst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ghost-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100976",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The spirit; the soul of man.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The disembodied soul; the soul or spirit of a deceased person; a spirit appearing after death",
              synonyms: [],
              antonyms: [],
              example:
                "Everyone believed that the ghost of an old lady haunted the crypt.",
            },
            {
              definition: "Any faint shadowy semblance; an unsubstantial image",
              synonyms: [],
              antonyms: [],
              example: "not a ghost of a chance; the ghost of an idea",
            },
            {
              definition:
                "A false image formed in a telescope, camera, or other optical device by reflection from the surfaces of one or more lenses.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An unwanted image similar to and overlapping or adjacent to the main one on a television screen, caused by the transmitted image being received both directly and via reflection.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A ghostwriter.", synonyms: [], antonyms: [] },
            {
              definition:
                "An unresponsive user on IRC, resulting from the user's client disconnecting without notifying the server.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An image of a file or hard disk.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An understudy.", synonyms: [], antonyms: [] },
            {
              definition: "A covert (and deniable) agent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The faint image that remains after an attempt to remove graffiti.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An opponent in a racing game that follows a previously recorded route, allowing players to compete against previous best times.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A dead person whose identity is stolen by another. See ghosting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(in names of species) White or pale.",
              synonyms: [],
              antonyms: [],
              example:
                "ghost slug; ghostberry; ghostflower; ghost crab; ghost bat",
            },
            {
              definition: "(in names of species) Transparent or translucent.",
              synonyms: [],
              antonyms: [],
              example:
                "ghost ant; ghost catfish; ghost nipper; ghost nudibranch",
            },
            {
              definition: "Abandoned.",
              synonyms: [],
              antonyms: [],
              example: "ghost town; ghost net; ghost ramp; ghost ship",
            },
            {
              definition: "Remnant; the remains of a(n).",
              synonyms: [],
              antonyms: [],
              example: "ghost cell; ghost crater; ghost image",
            },
            {
              definition: "Perceived or listed but not real.",
              synonyms: [],
              antonyms: [],
              example:
                "ghost pain; ghost cellphone vibration; ghost island; ghost voter",
            },
            {
              definition:
                "Of cryptid, supernatural or extraterrestrial nature.",
              synonyms: [],
              antonyms: [],
              example: "ghost rocket; ghost deer; ghost cat",
            },
            {
              definition: "Substitute.",
              synonyms: [],
              antonyms: [],
              example: "ghost writer; ghost band; ghost singer",
            },
          ],
          synonyms: [
            "spook",
            "spy",
            "glimmer",
            "glimmering",
            "glimpse",
            "hint",
            "inkling",
            "phantom",
            "spark",
            "suggestion",
            "echo",
            "ghostwriter",
            "shadow",
            "backup",
            "essence",
            "soul",
            "spirit",
            "apparition",
            "bogey",
            "haint",
            "phantom",
            "revenant",
            "specter",
            "spectre",
            "spook",
            "wraith",
            "understudy",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To haunt; to appear to in the form of an apparition.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To die; to expire.", synonyms: [], antonyms: [] },
            { definition: "To ghostwrite.", synonyms: [], antonyms: [] },
            {
              definition: "To sail seemingly without wind.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To copy a file or hard drive image.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To gray out (a visual item) to indicate that it is unavailable.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To forcibly disconnect an IRC user who is using one's reserved nickname.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To appear without warning; to move quickly and quietly; to slip.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To kill.", synonyms: [], antonyms: [] },
            {
              definition:
                "To break up with someone without warning or explanation; to perform an act of ghosting.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ghost"],
    },
  ],
  feast: [
    {
      word: "feast",
      phonetic: "/fiːst/",
      phonetics: [
        {
          text: "/fiːst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/feast-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2079700",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A very large meal, often of a ceremonial nature.",
              synonyms: [],
              antonyms: [],
              example: "We had a feast to celebrate the harvest.",
            },
            {
              definition: "Something delightful",
              synonyms: [],
              antonyms: [],
              example: "It was a feast for the eyes.",
            },
            {
              definition:
                "A festival; a holy day or holiday; a solemn, or more commonly, a joyous, anniversary.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["banquet"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/feast"],
    },
    {
      word: "feast",
      phonetic: "/fiːst/",
      phonetics: [
        {
          text: "/fiːst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/feast-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2079700",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To partake in a feast, or large meal.",
              synonyms: [],
              antonyms: [],
              example: "I feasted on turkey and dumplings.",
            },
            {
              definition: "To dwell upon (something) with delight.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To hold a feast in honor of (someone).",
              synonyms: [],
              antonyms: [],
              example: "We feasted them after the victory.",
            },
            {
              definition: "To serve as a feast for; to feed sumptuously.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/feast"],
    },
  ],
  blade: [
    {
      word: "blade",
      phonetic: "/bleɪd/",
      phonetics: [
        {
          text: "/bleɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/blade-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=839235",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The sharp cutting edge of a knife, chisel, or other tool, a razor blade/sword.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The flat functional end of a propeller, oar, hockey stick, screwdriver, skate, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The narrow leaf of a grass or cereal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The thin, flat part of a plant leaf, attached to a stem (petiole). The lamina.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A flat bone, especially the shoulder blade.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cut of beef from near the shoulder blade (part of the chuck).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The part of the tongue just behind the tip, used to make laminal consonants.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A sword or knife.", synonyms: [], antonyms: [] },
            {
              definition:
                "A piece of prepared, sharp-edged stone, often flint, at least twice as long as it is wide; a long flake of ground-edge stone or knapped vitreous stone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(ultimate frisbee) A throw characterized by a tight parabolic trajectory due to a steep lateral attitude.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The rudder, daggerboard, or centerboard of a vessel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bulldozer or surface-grading machine with mechanically adjustable blade that is nominally perpendicular to the forward motion of the vehicle.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A dashing young man.", synonyms: [], antonyms: [] },
            {
              definition: "A homosexual, usually male.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Thin plate, foil.", synonyms: [], antonyms: [] },
            {
              definition:
                "One of a series of small plates that make up the aperture or the shutter of a camera.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(in the plural) The principal rafters of a roof.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The four large shell plates on the sides, and the five large ones of the middle, of the carapace of the sea turtle, which yield the best tortoise shell.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Airfoil in windmills and windturbines.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A blade server.", synonyms: [], antonyms: [] },
            {
              definition:
                "An exterior product of vectors. (The product may have more than two factors. Also, a scalar counts as a 0-blade, a vector as a 1-blade; an exterior product of k vectors may be called a k-blade.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The part of a key that is inserted into the lock.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To skate on rollerblades.",
              synonyms: [],
              antonyms: [],
              example: "Want to go blading with me later in the park?",
            },
            {
              definition: "To furnish with a blade.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To put forth or have a blade.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To stab with a blade",
              synonyms: [],
              antonyms: [],
              example: "The gang member got bladed in a fight.",
            },
            {
              definition: "To cut (a person) so as to provoke bleeding.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A kind of piton used in thin cracks.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/blade",
        "https://en.wiktionary.org/wiki/knifeblade",
      ],
    },
  ],
  limit: [
    {
      word: "limit",
      phonetic: "/ˈlɪmt/",
      phonetics: [
        { text: "/ˈlɪmt/", audio: "" },
        {
          text: "/ˈlɪmɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/limit-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=876708",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A restriction; a bound beyond which one may not go.",
              synonyms: [],
              antonyms: [],
              example: "There are several existing limits to executive power.",
            },
            {
              definition:
                "A value to which a sequence converges. Equivalently, the common value of the upper limit and the lower limit of a sequence: if the upper and lower limits are different, then the sequence has no limit (i.e., does not converge).",
              synonyms: [],
              antonyms: [],
              example: "The sequence of reciprocals has zero as its limit.",
            },
            {
              definition:
                "Any of several abstractions of this concept of limit.",
              synonyms: [],
              antonyms: [],
              example:
                "Category theory defines a very general concept of limit.",
            },
            {
              definition:
                "The cone of a diagram through which any other cone of that same diagram can factor uniquely.",
              synonyms: ["inverse limit", "projective limit"],
              antonyms: [],
            },
            { definition: "Fixed limit.", synonyms: [], antonyms: [] },
            {
              definition:
                "The final, utmost, or furthest point; the border or edge.",
              synonyms: [],
              antonyms: [],
              example: "the limit of a walk, of a town, or of a country",
            },
            {
              definition: "The space or thing defined by limits.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That which terminates a period of time; hence, the period itself; the full time or extent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A restriction; a check or curb; a hindrance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A determining feature; a distinguishing characteristic.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The first group of riders to depart in a handicap race.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                '(as "the limit") A person who is exasperating, intolerable, astounding, etc.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "bound",
            "boundary",
            "limitation",
            "restriction",
            "inverse limit",
            "projective limit",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Being a fixed limit game.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/limit"],
    },
    {
      word: "limit",
      phonetic: "/ˈlɪmt/",
      phonetics: [
        { text: "/ˈlɪmt/", audio: "" },
        {
          text: "/ˈlɪmɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/limit-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=876708",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To restrict; not to allow to go beyond a certain bound, to set boundaries.",
              synonyms: [],
              antonyms: [],
              example: "I'm limiting myself to two drinks tonight.",
            },
            {
              definition: "To have a limit in a particular set.",
              synonyms: [],
              antonyms: [],
              example: "The sequence limits on the point a.",
            },
            {
              definition:
                "To beg, or to exercise functions, within a certain limited region.",
              synonyms: [],
              antonyms: [],
              example: "a limiting friar",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/limit"],
    },
  ],
  germs: [
    {
      word: "germs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The small mass of cells from which a new organism develops; a seed, bud or spore.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A pathogenic microorganism.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The embryo of a seed, especially of a seed used as a cereal or grain. See Wikipedia article on cereal germ.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The origin of an idea or project.",
              synonyms: [],
              antonyms: [],
              example: "the germ of civil liberty",
            },
            {
              definition:
                "An equivalence class that includes a specified function defined in an open neighborhood.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/germ",
        "https://en.wiktionary.org/wiki/germs",
      ],
    },
  ],
  reads: [
    {
      word: "reads",
      phonetic: "/ɹiːdz/",
      phonetics: [{ text: "/ɹiːdz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A reading or an act of reading, especially an actor's part of a play.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in combination) Something to be read; a written work.",
              synonyms: [],
              antonyms: [],
              example: "His thrillers are always a gripping read.",
            },
            {
              definition:
                "A person's interpretation or impression of something.",
              synonyms: [],
              antonyms: [],
              example: "What's your read of the current political situation?",
            },
            {
              definition: "An instance of reading.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To look at and interpret letters or other information that is written.",
              synonyms: [
                "interpret",
                "make out",
                "make sense of",
                "scan",
                "understand",
              ],
              antonyms: [],
              example: "Have you read this book?",
            },
            {
              definition:
                "To speak aloud words or other information that is written. Often construed with a to phrase or an indirect object.",
              synonyms: ["read aloud, read out, read out loud, speak"],
              antonyms: [],
              example: "All right, class, who wants to read next?",
            },
            {
              definition: "To read work(s) written by (a named author).",
              synonyms: [],
              antonyms: [],
              example: "At the moment I'm reading Milton.",
            },
            {
              definition:
                "To interpret, or infer a meaning, significance, thought, intention, etc. from.",
              synonyms: [],
              antonyms: [],
              example: "I can read his feelings in his face.",
            },
            {
              definition: "To consist of certain text.",
              synonyms: [],
              antonyms: [],
              example: 'On the door hung a sign that reads "No admittance".',
            },
            {
              definition:
                "Of text, etc., to be interpreted or read in a particular way.",
              synonyms: [],
              antonyms: [],
              example: "Arabic reads right to left.",
            },
            {
              definition:
                "To substitute (a corrected piece of text in place of an erroneous one); used to introduce an emendation of a text.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(usually ironic) Used after a euphemism to introduce the intended, more blunt meaning of a term.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be able to hear what another person is saying over a radio connection.",
              synonyms: ["copy", "hear", "receive"],
              antonyms: [],
              example: "Do you read me?",
            },
            {
              definition: "To observe and comprehend (a displayed signal)",
              synonyms: [],
              antonyms: [],
              example:
                "A repeater signal may be used where the track geometry makes the main signal difficult to read from a distance.",
            },
            {
              definition:
                "(except Scotland) To make a special study of, as by perusing textbooks.",
              synonyms: ["learn", "study"],
              antonyms: [],
              example: "I am reading theology at university.",
            },
            {
              definition: "To fetch data from (a storage medium, etc.).",
              synonyms: [],
              antonyms: [],
              example:
                "to read a hard disk; to read a port; to read the keyboard",
            },
            {
              definition: "To think, believe; to consider (that).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To advise; to counsel. See rede.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To tell; to declare; to recite.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To recognise (someone) as being transgender.",
              synonyms: ["clock"],
              antonyms: ["pass"],
              example:
                "Every time I go outside, I worry that someone will read me.",
            },
            {
              definition:
                "To call attention to the flaws of (someone) in either a playful, a taunting, or an insulting way.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "clock",
            "copy",
            "hear",
            "receive",
            "interpret",
            "make out",
            "make sense of",
            "scan",
            "understand",
            "learn",
            "study",
            "read aloud, read out, read out loud, speak",
          ],
          antonyms: ["pass"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/read",
        "https://en.wiktionary.org/wiki/reads",
      ],
    },
  ],
  ducks: [
    {
      word: "ducks",
      phonetic: "/dʌks/",
      phonetics: [
        {
          text: "/dʌks/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ducks-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=698701",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To quickly lower the head or body in order to prevent it from being struck by something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To quickly lower (the head) in order to prevent it from being struck by something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To lower (something) into water; to thrust or plunge under liquid and suddenly withdraw.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To go under the surface of water and immediately reappear; to plunge one's head into water or other liquid.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To bow.", synonyms: [], antonyms: [] },
            {
              definition: "To evade doing something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To lower the volume of (a sound) so that other sounds in the mix can be heard more clearly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To enter a place for a short moment.",
              synonyms: [],
              antonyms: [],
              example:
                "I'm just going to duck into the loo for a minute, can you hold my bag?",
            },
          ],
          synonyms: ["dip", "dip", "dunk", "duck down"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/duck",
        "https://en.wiktionary.org/wiki/ducks",
      ],
    },
    {
      word: "ducks",
      phonetic: "/dʌks/",
      phonetics: [
        {
          text: "/dʌks/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ducks-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=698701",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An aquatic bird of the family Anatidae, having a flat bill and webbed feet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Specifically, an adult female duck; contrasted with drake and with duckling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The flesh of a duck used as food.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A batsman's score of zero after getting out. (short for duck's egg, since the digit \"0\" is round like an egg.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A playing card with the rank of two.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A partly-flooded cave passage with limited air space.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A building intentionally constructed in the shape of an everyday object to which it is related.",
              synonyms: [],
              antonyms: [],
              example:
                "A luncheonette in the shape of a coffee cup is particularly conspicuous, as is intended of an architectural duck or folly.",
            },
            {
              definition:
                "A marble to be shot at with another marble (the shooter) in children's games.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A cairn used to mark a trail.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the weights used to hold a spline in place for the purpose of drawing a curve.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A long-necked medical urinal for men.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A tightly-woven cotton fabric used as sailcloth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(in plural) Trousers made of such material.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A term of endearment; pet; darling.",
              synonyms: [],
              antonyms: [],
              example:
                "And hold-fast is the only dog, my duck (William Shakespeare - The Life of King Henry the Fifth, Act 2, Scene 3).",
            },
            {
              definition:
                "Dear, mate (informal way of addressing a friend or stranger).",
              synonyms: [],
              antonyms: [],
              example: "Ay up duck, ow'a'tha?",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A pair of twos.", synonyms: [], antonyms: [] },
            {
              definition: "(used vocatively) Dear (used as a pet name).",
              synonyms: [],
              antonyms: [],
              example: "Hello ducks, how are you today?",
            },
            {
              definition: "The light trousers worn by sailors in hot climates.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dear", "duck", "ducky", "love"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person or thing that is helpless, inefficient or disabled.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An elected official who has lost the recent election or is not eligible for reelection and is marking time until leaving office.",
              synonyms: [],
              antonyms: [],
              example:
                "Congressman Jones was a lame duck and did not vote on many issues that were important to his constituents.",
            },
            {
              definition: "A person who cannot fulfill their contracts.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/duck",
        "https://en.wiktionary.org/wiki/ducks",
        "https://en.wiktionary.org/wiki/lame%20duck",
      ],
    },
  ],
  dairy: [
    {
      word: "dairy",
      phonetic: "/ˈdɛəɹi/",
      phonetics: [
        { text: "/ˈdɛəɹi/", audio: "" },
        {
          text: "/ˈdɛəɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dairy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=12523036",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(also dairy products or dairy produce) Products produced from milk.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A place, often on a farm, where milk is processed and turned into products such as butter and cheese.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A dairy farm.", synonyms: [], antonyms: [] },
            {
              definition: "A shop selling dairy products.",
              synonyms: ["milkhouse", "milkery"],
              antonyms: [],
            },
            {
              definition: "A corner store, superette or minimart.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(chiefly in the plural) A woman's breast.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["milkery", "milkhouse"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Referring to products produced from milk.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Referring to the milk production and processing industries.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "On food labelling, containing fats only from dairy sources (e.g. dairy ice cream).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dairy"],
    },
  ],
  worst: [
    {
      word: "worst",
      phonetic: "/wɜːst/",
      phonetics: [
        { text: "/wɜːst/", audio: "" },
        {
          text: "/wɝst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/worst-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=471125",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Unfavorable; negative; not good.",
              synonyms: [],
              antonyms: [],
              example: "Abducting children is bad for mothers.",
            },
            {
              definition: "Not suitable or fitting.",
              synonyms: [],
              antonyms: [],
              example:
                "Do you think it is a bad idea to confront him directly?",
            },
            {
              definition: "Not appropriate, of manners etc.",
              synonyms: [],
              antonyms: [],
              example: "It is bad manners to talk with your mouth full.",
            },
            {
              definition: "Unhealthy; liable to cause health problems.",
              synonyms: [],
              antonyms: [],
              example:
                "Lard is bad for you. Smoking is bad for you, too. Grapes are bad for dogs but not for humans.",
            },
            {
              definition: "Sickly, unhealthy, unwell.",
              synonyms: [],
              antonyms: [],
              example:
                "I went to the hospital to see how my grandfather was doing. Unfortunately, he's in a bad state.",
            },
            {
              definition:
                "Not behaving; behaving badly; misbehaving; mischievous or disobedient.",
              synonyms: [],
              antonyms: [],
              example: "Stop being bad, or you will get a spanking!",
            },
            {
              definition: "Tricky; stressful; unpleasant.",
              synonyms: [],
              antonyms: [],
              example:
                "Divorce is usually a bad experience for everybody involved.",
            },
            {
              definition: "Evil; wicked.",
              synonyms: [],
              antonyms: [],
              example: "Be careful. There are bad people in the world.",
            },
            {
              definition: "Faulty; not functional.",
              synonyms: [],
              antonyms: [],
              example: "I had a bad headlight.",
            },
            {
              definition: "(of food) Spoiled, rotten, overripe.",
              synonyms: [],
              antonyms: [],
              example: "These apples have gone bad.",
            },
            {
              definition: "(of breath) Malodorous; foul.",
              synonyms: [],
              antonyms: [],
              example: "Bad breath is not pleasant for anyone.",
            },
            {
              definition: "False; counterfeit; illegitimate.",
              synonyms: [],
              antonyms: [],
              example: "They were caught trying to pass bad coinage.",
            },
            {
              definition: "Unskilled; of limited ability; not good.",
              synonyms: [],
              antonyms: [],
              example:
                "He's a bad gardener; everything he tries to grow ends up dying.",
            },
            {
              definition: "Of poor physical appearance.",
              synonyms: [],
              antonyms: [],
              example: "I don't look bad in this dress, do I?",
            },
            { definition: "Bold and daring.", synonyms: [], antonyms: [] },
            {
              definition: "Good, superlative, excellent, cool.",
              synonyms: [],
              antonyms: [],
              example: "Man, that new car you bought is bad!",
            },
            {
              definition: "(of a need, want, or pain) Severe, urgent.",
              synonyms: [],
              antonyms: [],
              example: "He is in bad need of a haircut.",
            },
            {
              definition: "Overly promiscuous, licentious.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of a draft/check) Not covered by funds on account.",
              synonyms: [],
              antonyms: [],
              example: "He gave me a bad check.",
            },
          ],
          synonyms: [
            "badass",
            "evil",
            "vicious",
            "vile",
            "wicked",
            "false",
            "spurious",
            "unhealthful",
            "unwholesome",
            "faulty",
            "inoperative",
            "negative",
            "unfavorable",
            "inappropriate",
            "unfit",
            "hot",
            "rubber",
            "dire",
            "severe",
            "urgent",
            "foul",
            "malodorous",
            "rotten",
            "bungling",
            "inept",
            "repulsive",
            "unsightly",
            "foul",
            "loathsome",
            "ill",
            "poorly",
            "sickly",
            "abandoned",
            "abominable",
            "base",
            "corrupt",
            "deficient",
            "detestable",
            "disgusting",
            "inferior",
            "lousy",
            "off",
            "poor",
            "punk",
            "substandard",
            "unacceptable",
            "ungodly",
            "unsatisfactory",
            "vicious",
            "wanting",
            "wretched",
            "wrong",
          ],
          antonyms: [
            "adequate",
            "advantageous",
            "beneficial",
            "benevolent",
            "choice",
            "competent",
            "excellent",
            "exceptional",
            "first-class",
            "first-rate",
            "good",
            "honest",
            "just",
            "premium",
            "prime",
            "profitable",
            "propitious",
            "reputable",
            "right",
            "sincere",
            "sufficient",
            "superior",
            "true",
            "upright",
            "virtuous",
            "worthy",
          ],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Evil; wicked (of people).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Morally reprehensible (of behaviour etc.); blameworthy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Indicative of unkind or malevolent intentions; harsh, cruel.",
              synonyms: [],
              antonyms: [],
              example: "He suffered from ill treatment.",
            },
            {
              definition:
                "Unpropitious, unkind, faulty, not up to reasonable standard.",
              synonyms: [],
              antonyms: [],
              example: "ill manners; ill will",
            },
            {
              definition:
                "Unwell in terms of health or physical condition; sick.",
              synonyms: [],
              antonyms: [],
              example: "I've been ill with the flu for the past few days.",
            },
            {
              definition: "Having an urge to vomit.",
              synonyms: [],
              antonyms: [],
              example: "Seeing those pictures made me ill.",
            },
            {
              definition:
                "(hip-hop slang) Sublime, with the connotation of being so in a singularly creative way.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Extremely bad (bad enough to make one ill). Generally used indirectly with to be.",
              synonyms: [],
              antonyms: [],
              example: "That band was ill.",
            },
            {
              definition: "Unwise; not a good idea.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "bad",
            "mal-",
            "disgusted",
            "nauseate",
            "nauseous",
            "sick",
            "sickened",
            "dope",
            "diseased",
            "poorly",
            "sick",
            "under the weather",
            "unwell",
          ],
          antonyms: ["good", "wack", "fine", "hale", "healthy", "well"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Something or someone that is the worst",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To make worse.", synonyms: [], antonyms: [] },
            {
              definition: "To grow worse; to deteriorate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To outdo or defeat, especially in battle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Superlative of bad",
              synonyms: [],
              antonyms: [],
              example: "My sore leg hurts worst when it's cold and rainy.",
            },
            { definition: "Superlative of ill", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bad",
        "https://en.wiktionary.org/wiki/ill",
        "https://en.wiktionary.org/wiki/worst",
      ],
    },
  ],
  gifts: [
    {
      word: "gifts",
      phonetic: "/ɡɪfts/",
      phonetics: [
        {
          text: "/ɡɪfts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gifts-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749856",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Something given to another voluntarily, without charge.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A talent or natural ability.",
              synonyms: [],
              antonyms: [],
              example: "She had a gift for playing the flute.",
            },
            {
              definition: "Something gained incidentally, without effort.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The act, right, or power of giving or bestowing.",
              synonyms: [],
              antonyms: [],
              example: "The office is in the gift of the President.",
            },
          ],
          synonyms: [
            "favor",
            "ability",
            "aptitude",
            "knack",
            "strength",
            "talent",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To give as a gift or donation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To give away, to concede easily.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["contribute", "donate", "give"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gift",
        "https://en.wiktionary.org/wiki/gifts",
      ],
    },
  ],
  lists: [
    {
      word: "lists",
      phonetic: "/lɪsts/",
      phonetics: [
        {
          text: "/lɪsts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lists-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80949488",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A strip of fabric, especially from the edge of a piece of cloth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Material used for cloth selvage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A register or roll of paper consisting of a compilation or enumeration of a set of possible items; the compilation or enumeration itself.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in the plural) The barriers or palisades used to fence off a space for jousting or tilting tournaments.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A codified representation of a list used to store data or in processing; especially, in the LISP programming language, a data structure consisting of a sequence of zero or more items.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A little square moulding; a fillet or listel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A narrow strip of wood, especially sapwood, cut from the edge of a board or plank.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(ropemaking) A piece of woollen cloth with which the yarns are grasped by a worker.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(tin-plate manufacture) The first thin coating of tin; a wire-like rim of tin left on an edge of the plate after it is coated.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A stripe.", synonyms: [], antonyms: [] },
            {
              definition: "A boundary or limit; a border.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To create or recite a list.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To place in listings.", synonyms: [], antonyms: [] },
            {
              definition:
                "To sew together, as strips of cloth, so as to make a show of colours, or to form a border.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cover with list, or with strips of cloth; to put list on; to stripe as if with list.",
              synonyms: [],
              antonyms: [],
              example: "to list a door",
            },
            {
              definition: "To plough and plant with a lister.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To prepare (land) for a cotton crop by making alternating beds and alleys with a hoe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cut away a narrow strip, as of sapwood, from the edge of.",
              synonyms: [],
              antonyms: [],
              example: "to list a board",
            },
            {
              definition: "To enclose (a field, etc.) for combat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To engage a soldier, etc.; to enlist.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To engage in public service by enrolling one's name; to enlist.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["tabulate"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To listen.", synonyms: [], antonyms: [] },
            { definition: "To listen to.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To be pleasing to.", synonyms: [], antonyms: [] },
            {
              definition: "To desire, like, or wish (to do something).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A tilt to a building.", synonyms: [], antonyms: [] },
            {
              definition:
                "A careening or tilting to one side, usually not intentionally or under a vessel's own power.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cause (something) to tilt to one side.",
              synonyms: [],
              antonyms: [],
              example: "the steady wind listed the ship",
            },
            {
              definition: "To tilt to one side.",
              synonyms: [],
              antonyms: [],
              example: "the ship listed to port",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/list",
        "https://en.wiktionary.org/wiki/lists",
      ],
    },
  ],
  stops: [
    {
      word: "stops",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stops-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80067090",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/stɑps/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A (usually marked) place where buses, trams or trains halt to let passengers get on and off, usually smaller than a station.",
              synonyms: [],
              antonyms: [],
              example: "They agreed to meet at the bus stop.",
            },
            {
              definition: "An action of stopping; interruption of travel.",
              synonyms: [],
              antonyms: [],
              example: "That stop was not planned.",
            },
            {
              definition:
                "That which stops, impedes, or obstructs; an obstacle; an impediment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A device intended to block the path of a moving object",
              synonyms: [],
              antonyms: [],
              example: "door stop",
            },
            {
              definition:
                "A consonant sound in which the passage of air through the mouth is temporarily blocked by the lips, tongue, or glottis.",
              synonyms: ["occlusive", "plosive"],
              antonyms: [],
            },
            {
              definition:
                "A symbol used for purposes of punctuation and representing a pause or separating clauses, particularly a full stop, comma, colon or semicolon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A knob or pin used to regulate the flow of air in an organ.",
              synonyms: [],
              antonyms: [],
              example: "The organ is loudest when all the stops are pulled.",
            },
            {
              definition:
                "One of the vent-holes in a wind instrument, or the place on the wire of a stringed instrument, by the stopping or pressing of which certain notes are produced.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A very short shot which touches the ground close behind the net and is intended to bounce as little as possible.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The depression in a dog’s face between the skull and the nasal bones.",
              synonyms: [],
              antonyms: [],
              example: "The stop in a bulldog's face is very marked.",
            },
            {
              definition:
                "A part of a photographic system that reduces the amount of light.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A unit of exposure corresponding to a doubling of the brightness of an image.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An f-stop.", synonyms: [], antonyms: [] },
            {
              definition:
                "The diaphragm used in optical instruments to cut off the marginal portions of a beam of light passing through lenses.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A coup d'arret, or stop thrust.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["occlusive", "plosive"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cease moving.",
              synonyms: [],
              antonyms: [],
              example: "I stopped at the traffic lights.",
            },
            {
              definition: "To not continue.",
              synonyms: [],
              antonyms: [],
              example: "Soon the rain will stop.",
            },
            {
              definition:
                "To cause (something) to cease moving or progressing.",
              synonyms: [],
              antonyms: [],
              example: "The sight of the armed men stopped him in his tracks.",
            },
            {
              definition: "To cease; to no longer continue (doing something).",
              synonyms: [],
              antonyms: [],
              example: "One of the wrestlers suddenly stopped fighting.",
            },
            {
              definition: "To cause (something) to come to an end.",
              synonyms: [],
              antonyms: [],
              example: "The referees stopped the fight.",
            },
            {
              definition: "To close or block an opening.",
              synonyms: [],
              antonyms: [],
              example: "He stopped the wound with gauze.",
            },
            {
              definition:
                '(often with "up" or "down") To adjust the aperture of a camera lens.',
              synonyms: [],
              antonyms: [],
              example:
                "To achieve maximum depth of field, he stopped down to an f-stop of 22.",
            },
            {
              definition:
                "To stay; to spend a short time; to reside or tarry temporarily.",
              synonyms: [],
              antonyms: [],
              example:
                "He stopped at his friend's house before continuing with his drive.",
            },
            {
              definition:
                "To regulate the sounds of (musical strings, etc.) by pressing them against the fingerboard with the finger, or otherwise shortening the vibrating part.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To punctuate.", synonyms: [], antonyms: [] },
            {
              definition: "To make fast; to stopper.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "arrest",
            "freeze",
            "halt",
            "blin",
            "cancel",
            "cease",
            "discontinue",
            "halt",
            "terminate",
            "brake",
            "desist",
            "halt",
            "blin",
            "cease",
            "desist",
            "discontinue",
            "halt",
            "terminate",
            "lodge",
            "stop over",
            "hang about",
            "hang around",
            "linger",
            "loiter",
            "pause",
          ],
          antonyms: [
            "continue",
            "move",
            "continue",
            "move",
            "continue",
            "go",
            "move",
            "proceed",
            "continue",
            "proceed",
          ],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A small well-bucket; a milk-pail.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/stop",
        "https://en.wiktionary.org/wiki/stops",
      ],
    },
  ],
  rapid: [
    {
      word: "rapid",
      phonetic: "/ˈɹæpɪd/",
      phonetics: [
        { text: "/ˈɹæpɪd/", audio: "" },
        {
          text: "/ˈɹæpɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rapid-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1684708",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(often in the plural) a rough section of a river or stream which is difficult to navigate due to the swift and turbulent motion of the water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A burst of rapid fire.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Very swift or quick.",
              synonyms: [],
              antonyms: [],
              example: "a rapid stream",
            },
            {
              definition: "Steep, changing altitude quickly. (of a slope)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Needing only a brief exposure time. (of a lens, plate, film, etc.)",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Violent, severe.", synonyms: [], antonyms: [] },
            { definition: "Happy.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "(archaic or colloquial) Rapidly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rapid"],
    },
  ],
  brick: [
    {
      word: "brick",
      phonetic: "/bɹɪk/",
      phonetics: [
        { text: "/bɹɪk/", audio: "" },
        {
          text: "/bɹɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/brick-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=310408",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A hardened rectangular block of mud, clay etc., used for building.",
              synonyms: [],
              antonyms: [],
              example: "This wall is made of bricks.",
            },
            {
              definition:
                "Such hardened mud, clay, etc. considered collectively, as a building material.",
              synonyms: [],
              antonyms: [],
              example: "This house is made of brick.",
            },
            {
              definition: "Something shaped like a brick.",
              synonyms: [],
              antonyms: [],
              example: "a plastic explosive brick",
            },
            {
              definition: "A helpful and reliable person.",
              synonyms: [],
              antonyms: [],
              example: "Thanks for helping me wash the car. You're a brick.",
            },
            {
              definition:
                "A shot which misses, particularly one which bounces directly out of the basket because of a too-flat trajectory, as if the ball were a heavier object.",
              synonyms: [],
              antonyms: [],
              example:
                "We can't win if we keep throwing up bricks from three-point land.",
            },
            {
              definition:
                "A power brick; an external power supply consisting of a small box with an integral male power plug and an attached electric cord terminating in another power plug.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An electronic device, especially a heavy box-shaped one, that has become non-functional or obsolete.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A carton of 500 rimfire cartridges, which forms the approximate size and shape of a brick.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A community card (usually the turn or the river) which does not improve a player's hand.",
              synonyms: [],
              antonyms: [],
              example: "The two of clubs was a complete brick on the river.",
            },
            { definition: "The colour brick red.", synonyms: [], antonyms: [] },
            { definition: "One kilo of cocaine.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To build with bricks.", synonyms: [], antonyms: [] },
            { definition: "To make into bricks.", synonyms: [], antonyms: [] },
            {
              definition: "To hit someone or something with a brick.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make an electronic device nonfunctional and usually beyond repair, essentially making it no more useful than a brick.",
              synonyms: [],
              antonyms: [],
              example: "My VCR was bricked during the lightning storm.",
            },
          ],
          synonyms: [],
          antonyms: ["unbrick"],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "(of weather) Extremely cold.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/brick"],
    },
  ],
  claws: [
    {
      word: "claws",
      phonetic: "/klɔːz/",
      phonetics: [{ text: "/klɔːz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A curved, pointed horny nail on each digit of the foot of a mammal, reptile, or bird.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A foot equipped with such.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The pincer (chela) of a crustacean or other arthropod.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A mechanical device resembling a claw, used for gripping or lifting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A slender appendage or process, formed like a claw, such as the base of petals of the pink.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The act of catching a ball overhand.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To scratch or to tear at.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To use the claws to seize, to grip.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To use the claws to climb.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To perform a claw catch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To move with one's fingertips.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To relieve uneasy feeling, such as an itch, by scratching; hence, to humor or flatter, to court someone.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To rail at; to scold.", synonyms: [], antonyms: [] },
            {
              definition: "To flatter; to fawn on (a person).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/claw",
        "https://en.wiktionary.org/wiki/claws",
      ],
    },
  ],
  beads: [
    {
      word: "beads",
      phonetic: "/biːdz/",
      phonetics: [{ text: "/biːdz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Prayer, later especially with a rosary.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Each in a string of small balls making up the rosary or paternoster.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A small, round object.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(heading) A ridge, band, or molding.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Knowledge sufficient to direct one's activities to a purpose.",
              synonyms: [],
              antonyms: [],
              example:
                "We now have a bead on the main technical issues for the project",
            },
            {
              definition:
                "A glassy drop of molten flux, as borax or microcosmic salt, used as a solvent and color test for several mineral earths and oxides, as of iron, manganese, etc., before the blowpipe.",
              synonyms: [],
              antonyms: [],
              example: "the borax bead;  the iron bead, etc.",
            },
            { definition: "Front sight of a gun.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To form into a bead.",
              synonyms: [],
              antonyms: [],
              example: "The raindrops beaded on the car's waxed finish.",
            },
            {
              definition: "To apply beads to.",
              synonyms: [],
              antonyms: [],
              example: "She spent the morning beading the gown.",
            },
            {
              definition: "To form into a bead.",
              synonyms: [],
              antonyms: [],
              example: "He beaded some solder for the ends of the wire.",
            },
            {
              definition: "To cause beads to form on (something).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A beaded necklace.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bead",
        "https://en.wiktionary.org/wiki/beads",
      ],
    },
  ],
  beast: [
    {
      word: "beast",
      phonetic: "/biːst/",
      phonetics: [
        { text: "/biːst/", audio: "" },
        {
          text: "/biːst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/beast-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749717",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any animal other than a human; usually only applied to land vertebrates, especially large or dangerous four-footed ones.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(more specific) A domestic animal, especially a bovine farm animal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person who behaves in a violent, antisocial or uncivilized manner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Anything regarded as larger or more powerful than one of its normal size or strength.",
              synonyms: [],
              antonyms: [],
              example: "That is a beast of a stadium.",
            },
            {
              definition:
                "Someone who is particularly impressive, especially athletically or physically.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(prisons) A sex offender.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Something unpleasant and difficult.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A thing or matter, especially a difficult or unruly one.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To impose arduous exercises, either as training or as punishment.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(chiefly Midwestern and northeastern US) great; excellent; powerful",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/beast"],
    },
  ],
  skirt: [
    {
      word: "skirt",
      phonetic: "/skɜːt/",
      phonetics: [
        {
          text: "/skɜːt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/skirt-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9027431",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/skɝt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/skirt-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1157736",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An article of clothing, usually worn by women and girls, that hangs from the waist and covers the lower part of the body.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The part of a dress or robe, etc., that hangs below the waist.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A loose edging to any part of a dress.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A petticoat.", synonyms: [], antonyms: [] },
            { definition: "A woman.", synonyms: [], antonyms: [] },
            {
              definition: "Women collectively, in a sexual context.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Sexual intercourse with a woman.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Border; edge; margin; extreme part of anything.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The diaphragm, or midriff, in animals.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To be on or form the border of.",
              synonyms: [],
              antonyms: [],
              example: "The plain was skirted by rows of trees.",
            },
            {
              definition:
                "To move around or along the border of; to avoid the center of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cover with a skirt; to surround.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To avoid or ignore (something); to manage to avoid (something or a problem); to skate by (something).",
              synonyms: [],
              antonyms: [],
              example:
                "He skirted the issue of which parties to attend by staying at home instead.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/skirt"],
    },
  ],
  cakes: [
    {
      word: "cakes",
      phonetic: "/keɪks/",
      phonetics: [
        {
          text: "/keɪks/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cakes-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2707461",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A rich, sweet dessert food, typically made of flour, sugar and eggs and baked in an oven, and often covered in icing.",
              synonyms: ["gâteau"],
              antonyms: [],
            },
            {
              definition:
                "A small mass of baked dough, especially a thin loaf from unleavened dough.",
              synonyms: [],
              antonyms: [],
              example: "a johnnycake",
            },
            {
              definition:
                "A thin wafer-shaped mass of fried batter; a griddlecake or pancake.",
              synonyms: [],
              antonyms: [],
              example: "buckwheat cakes",
            },
            {
              definition: "A block of any of various dense materials.",
              synonyms: ["block"],
              antonyms: [],
              example: "a cake of sand",
            },
            {
              definition:
                "A trivially easy task or responsibility; from a piece of cake.",
              synonyms: ["piece of cake"],
              antonyms: [],
            },
            { definition: "Money.", synonyms: [], antonyms: [] },
            {
              definition:
                "Used to describe the doctrine of having one's cake and eating it too.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A buttock, especially one that is exceptionally plump.",
              synonyms: [],
              antonyms: [],
              example: "Mmm, I'd like to cut me some of that cake!",
            },
          ],
          synonyms: ["block", "gâteau", "piece of cake"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "Coat (something) with a crust of solid material.",
              synonyms: ["crust", "encrust"],
              antonyms: [],
              example: "His shoes are caked with mud.",
            },
            {
              definition: "To form into a cake, or mass.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["crust", "encrust"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cackle like a goose.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cake",
        "https://en.wiktionary.org/wiki/cakes",
      ],
    },
  ],
  lions: [
    {
      word: "lions",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lions-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651141",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A big cat, Panthera leo, native to Africa, India and formerly much of Europe.",
              synonyms: [],
              antonyms: [],
              example:
                "Tigers and lions share a common ancestor from a few million years ago.",
            },
            {
              definition:
                "(by extension) Any of various extant and extinct big cats, especially the mountain lion.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A Chinese foo dog.", synonyms: [], antonyms: [] },
            {
              definition:
                "An individual who shows strength and courage, attributes associated with the lion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A famous person regarded with interest and curiosity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A light brown color that resembles the fur of a lion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An old Scottish coin, with a lion on the obverse, worth 74 shillings.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lion",
        "https://en.wiktionary.org/wiki/lions",
      ],
    },
  ],
  frogs: [
    {
      word: "frogs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small tailless amphibian of the order Anura that typically hops.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The part of a violin bow (or that of other similar string instruments such as the viola, cello and contrabass) located at the end held by the player, to which the horsehair is attached.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Road. Shorter, more common form of frog and toad.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The depression in the upper face of a pressed or handmade clay brick.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An organ on the bottom of a horse’s hoof that assists in the circulation of blood.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The part of a railway switch or turnout where the running-rails cross (from the resemblance to the frog in a horse’s hoof).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To hunt or trap frogs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To use a pronged plater to transfer (cells) to another plate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To spatchcock (a chicken).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["frosh", "frosk", "pad", "paddock", "common crossing"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A French person.",
              synonyms: ["baguette"],
              antonyms: [],
            },
            {
              definition: "A French-speaking person from Quebec.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["baguette"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A leather or fabric loop used to attach a sword or bayonet, or its scabbard, to a waist or shoulder belt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An ornate fastener for clothing consisting of an oblong button (covered with netted thread), toggle, or knot, that fits through a loop.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To ornament or fasten a coat, etc. with frogs.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To unravel part of (a knitted garment) while knitting it in order to correct a mistake.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/frog",
        "https://en.wiktionary.org/wiki/frogs",
      ],
    },
  ],
  tries: [
    {
      word: "tries",
      phonetic: "/tɹaɪz/",
      phonetics: [
        {
          text: "/tɹaɪz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tries-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651639",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An ordered tree data structure that is used to store an associative array where the keys are usually strings.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["prefix tree"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An attempt.",
              synonyms: [],
              antonyms: [],
              example: "I gave unicycling a try but I couldn’t do it.",
            },
            {
              definition: "An act of tasting or sampling.",
              synonyms: [],
              antonyms: [],
              example: "I gave sushi a try but I didn’t like it.",
            },
            {
              definition:
                "A score in rugby league and rugby union, analogous to a touchdown in American football.",
              synonyms: [],
              antonyms: [],
              example: "Today I scored my first try.",
            },
            {
              definition: "A screen, or sieve, for grain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A field goal or extra point",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "touchdown",
            "sampling",
            "taste",
            "tasting",
            "bash",
            "go",
            "stab",
            "whirl",
            "extra point",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To attempt; to endeavour. Followed by infinitive.",
              synonyms: [],
              antonyms: [],
              example: "I tried to rollerblade, but I couldn’t.",
            },
            {
              definition: "To divide; to separate.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To test, to work out.", synonyms: [], antonyms: [] },
            {
              definition: "To experiment, to strive.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To lie to in heavy weather under just sufficient sail to head into the wind.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To strain; to subject to excessive tests.",
              synonyms: [],
              antonyms: [],
              example: "Repeated failures try one's patience.",
            },
            {
              definition: "(used with another verb) To want",
              synonyms: [],
              antonyms: [],
              example:
                "I am really not trying to hear you talk about my mama like that.",
            },
          ],
          synonyms: [
            "attempt",
            "endeavor",
            "fand",
            "mint",
            "take a run at",
            "take a stab at",
            "sample",
            "taste",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/trie",
        "https://en.wiktionary.org/wiki/tries",
        "https://en.wiktionary.org/wiki/try",
      ],
    },
  ],
  nerve: [
    {
      word: "nerve",
      phonetic: "/nɛɾv/",
      phonetics: [
        { text: "/nɛɾv/", audio: "" },
        { text: "/nɜːv/", audio: "" },
        {
          text: "/nɜɪv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/nerve-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1113663",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A bundle of neurons with their connective tissue sheaths, blood vessels and lymphatics.",
              synonyms: [],
              antonyms: [],
              example: "The nerves can be seen through the skin.",
            },
            { definition: "A neuron.", synonyms: [], antonyms: [] },
            {
              definition: "A vein in a leaf; a grain in wood",
              synonyms: [],
              antonyms: [],
              example:
                "Some plants have ornamental value because of their contrasting nerves.",
            },
            {
              definition: "Courage, boldness.",
              synonyms: [],
              antonyms: [],
              example:
                "He hasn't the nerve to tell her he likes her. What a wimp!",
            },
            {
              definition: "Patience.",
              synonyms: [],
              antonyms: [],
              example:
                "The web-team found git-sed is really a time and nerve saver when doing mass changes on your repositories",
            },
            {
              definition: "Stamina, endurance, fortitude.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Audacity, gall.",
              synonyms: ["balls", "brashness", "brazenness"],
              antonyms: [],
              example: "He had the nerve to enter my house uninvited.",
            },
            {
              definition:
                "(polymer technology) The elastic resistance of raw rubber or other polymers to permanent deformation during processing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in the plural) Agitation caused by fear, stress or other negative emotion.",
              synonyms: [],
              antonyms: [],
              example: "Ellie had a bad case of nerves before the big test.",
            },
            { definition: "Sinew, tendon.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["balls", "brashness", "brazenness"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To give courage.",
              synonyms: [],
              antonyms: [],
              example: "May their example nerve us to face the enemy.",
            },
            {
              definition: "To give strength; to supply energy or vigour.",
              synonyms: [],
              antonyms: [],
              example:
                "The liquor nerved up several of the men after their icy march.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/nerve"],
    },
  ],
  grand: [
    {
      word: "grand",
      phonetic: "/ɡɹænd/",
      phonetics: [
        {
          text: "/ɡɹænd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grand-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1661659",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                '(plural "grand") A thousand of some unit of currency, such as dollars or pounds. (Compare G.)',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: '(plural "grands") A grand piano',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of a large size or extent; great.",
              synonyms: [],
              antonyms: [],
              example: "a grand army",
            },
            {
              definition:
                "Great in size, and fine or imposing in appearance or impression; illustrious, dignified, magnificent.",
              synonyms: [],
              antonyms: [],
              example:
                "His simple vision has transformed into something far more grand.",
            },
            {
              definition:
                "Having higher rank or more dignity, size, or importance than other persons or things of the same name.",
              synonyms: [],
              antonyms: [],
              example: "The Grand Viziers of the Ottoman Empire.",
            },
            {
              definition:
                "(usually in compound forms) Standing in the second or some more remote degree of parentage or descent.",
              synonyms: [],
              antonyms: [],
              example: "grandfather, grandson, grand-child",
            },
            {
              definition: "Fine; lovely.",
              synonyms: [],
              antonyms: [],
              example: "A cup of tea? That'd be grand.",
            },
            {
              definition:
                "Containing all the parts proper to a given form of composition.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/grand"],
    },
    {
      word: "grand",
      phonetic: "/ɡɹænd/",
      phonetics: [
        {
          text: "/ɡɹænd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grand-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1661659",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A grandparent or grandchild.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/grand"],
    },
  ],
  armed: [
    {
      word: "armed",
      phonetic: "/ɑːmd/",
      phonetics: [
        { text: "/ɑːmd/", audio: "" },
        {
          text: "/ɑɹmd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/armed-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1684551",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To take by the arm; to take up in one's arms.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To supply with armour or (later especially) weapons.",
              synonyms: [],
              antonyms: [],
              example: "The king armed his knights with swords and shields.",
            },
            {
              definition:
                "To prepare a tool or a weapon for action; to activate.",
              synonyms: [],
              antonyms: [],
              example:
                "Remember to arm the alarm system before leaving for work.",
            },
            {
              definition:
                "To cover or furnish with a plate, or with whatever will add strength, force, security, or efficiency.",
              synonyms: [],
              antonyms: [],
              example: "to arm the hit of a sword; to arm a hook in angling",
            },
            {
              definition:
                "To furnish with means of defence; to prepare for resistance; to fortify, in a moral sense.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To take up weapons; to arm oneself.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fit (a magnet) with an armature.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["beweapon"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(sometimes in combination) Equipped, especially with a weapon.",
              synonyms: [],
              antonyms: [],
              example: "nuclear-armed",
            },
            {
              definition: "(of a weapon) Prepared for use; loaded.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Furnished with whatever serves to add strength, force, or efficiency.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Having prickles or thorns.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/arm",
        "https://en.wiktionary.org/wiki/armed",
      ],
    },
    {
      word: "armed",
      phonetic: "/ɑːmd/",
      phonetics: [
        { text: "/ɑːmd/", audio: "" },
        {
          text: "/ɑɹmd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/armed-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1684551",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(chiefly in combination) Having an arm or arms, often of a specified number or type.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a creature) Possessing arms of a specified number or type.",
              synonyms: [],
              antonyms: [],
              example: "the four-armed creature.",
            },
            {
              definition:
                "(heraldry, of animals) Having horns, claws, teeth, a beak, etc. in a particular tincture, as contrasted with that of the animal as a whole.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/armed"],
    },
  ],
  treat: [
    {
      word: "treat",
      phonetic: "/tɹiːt/",
      phonetics: [
        {
          text: "/tɹiːt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/treat-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1750019",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An entertainment, outing, food, drink, or other indulgence provided by someone for the enjoyment of others.",
              synonyms: [],
              antonyms: [],
              example:
                "Here are some healthy Halloween treats for ghouls and witches of all ages.",
            },
            {
              definition:
                "An unexpected gift, event etc., which provides great pleasure.",
              synonyms: [],
              antonyms: [],
              example:
                "It was such a treat to see her back in action on the London stage.",
            },
            {
              definition: "A snack food item designed to be given to pets.",
              synonyms: [],
              antonyms: [],
              example:
                "I lured the cat into her carrier by throwing a couple of treats in there.",
            },
            {
              definition: "A parley or discussion of terms; a negotiation.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An entreaty.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To negotiate, discuss terms, bargain (for or with).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To discourse; to handle a subject in writing or speaking; to conduct a discussion.",
              synonyms: [],
              antonyms: [],
              example:
                "Cicero's writing treats mainly of old age and personal duty.",
            },
            {
              definition:
                "To discourse on; to represent or deal with in a particular way, in writing or speaking.",
              synonyms: [],
              antonyms: [],
              example:
                "The article treated feminism as a quintessentially modern movement.",
            },
            {
              definition: "To entreat or beseech (someone).",
              synonyms: [],
              antonyms: [],
              example: "Only let my family live, I treat thee.",
            },
            {
              definition:
                "To handle, deal with or behave towards in a specific way.",
              synonyms: [],
              antonyms: [],
              example: "She was tempted to treat the whole affair as a joke.",
            },
            {
              definition:
                "To entertain with food or drink, especially at one's own expense; to show hospitality to; to pay for as celebration or reward.",
              synonyms: [],
              antonyms: [],
              example: "I treated my son to some popcorn in the interval.",
            },
            {
              definition:
                "To commit the offence of providing food, drink, entertainment or provision to corruptly influence a voter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To care for medicinally or surgically; to apply medical care to.",
              synonyms: [],
              antonyms: [],
              example: "They treated me for malaria.",
            },
            {
              definition:
                "To subject to a chemical or other action; to act upon with a specific scientific result in mind.",
              synonyms: [],
              antonyms: [],
              example: "He treated the substance with sulphuric acid.",
            },
            {
              definition: "To provide something special and pleasant.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["leech", "make better", "behandle"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/treat"],
    },
  ],
  honey: [
    {
      word: "honey",
      phonetic: "/ˈhʌni/",
      phonetics: [
        {
          text: "/ˈhʌni/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/honey-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1224117",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A viscous, sweet fluid produced from plant nectar by bees. Often used to sweeten tea or to spread on baked goods.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A variety of this substance.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Nectar.", synonyms: [], antonyms: [] },
            {
              definition: "Something sweet or desirable.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A term of affection.",
              synonyms: [],
              antonyms: [],
              example: "Honey, I'm home.",
            },
            {
              definition: "A woman, especially an attractive one.",
              synonyms: [],
              antonyms: [],
              example: "Man, there are some fine honeys here tonight!",
            },
            {
              definition:
                "A spectrum of pale yellow to brownish-yellow colour, like that of most types of (the sweet substance) honey.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["mel", "darling", "sweetie"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To sweeten; to make agreeable.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be gentle, agreeable, or coaxing; to talk fondly; to use endearments.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be or become obsequiously courteous or complimentary; to fawn.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Involving or resembling honey.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of a pale yellow to brownish-yellow colour, like most types of honey.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/honey"],
    },
  ],
  moist: [
    {
      word: "moist",
      phonetic: "/mɔɪst/",
      phonetics: [
        {
          text: "/mɔɪst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/moist-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=53353101",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/mɔɪst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/moist-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1212541",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To moisten.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Slightly wet; characterised by the presence of moisture, not dry; damp.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of eyes: tearful, wet with tears.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of weather, climate etc.: rainy, damp.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Pertaining to one of the four essential qualities formerly believed to be present in all things, characterised by wetness.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Watery, liquid, fluid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Characterised by the presence of pus, mucus etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Sexually lubricated (of the vagina); sexually aroused, turned on (of a woman).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "dank",
            "damp",
            "thoan",
            "thone",
            "dewy-eyed",
            "misty",
            "weepy",
            "wet",
            "liquidlike",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/moist"],
    },
  ],
  legal: [
    {
      word: "legal",
      phonetic: "/ˈliː.ɡəl/",
      phonetics: [
        { text: "/ˈliː.ɡəl/", audio: "" },
        {
          text: "/ˈliɡəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/legal-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1247132",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The legal department of a company.",
              synonyms: [],
              antonyms: [],
              example: "Legal wants this in writing.",
            },
            {
              definition:
                "Paper in sheets 8½ in × 14 in (215.9 mm × 355.6 mm).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A spy who is attached to, and ostensibly employed by, an embassy, military outpost, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Somebody who immigrated lawfully.",
              synonyms: [],
              antonyms: ["illegal", "undocumented"],
            },
          ],
          synonyms: [],
          antonyms: ["illegal", "undocumented"],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Relating to the law or to lawyers.",
              synonyms: [],
              antonyms: [],
              example: "legal profession",
            },
            {
              definition: "Having its basis in the law.",
              synonyms: [],
              antonyms: [],
              example: "legal precedent",
            },
            {
              definition: "Being allowed or prescribed by law.",
              synonyms: [],
              antonyms: [],
              example: "legal motion",
            },
            {
              definition: "Above the age of consent or the legal drinking age.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of paper or document layouts) Measuring 8½ in × 14 in (215.9 mm × 355.6 mm) (also legal-size).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [
            "banned",
            "contraband",
            "disallowed",
            "forbidden",
            "illegal",
            "outlawed",
            "unlawful",
            "back-alley",
            "black-market",
            "underage",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/legal"],
    },
  ],
  penny: [
    {
      word: "penny",
      phonetic: "/pəni/",
      phonetics: [
        {
          text: "/pəni/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/penny-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2454192",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "In the United Kingdom and Ireland, a copper coin worth 1/240 of a pound sterling or Irish pound before decimalisation. Abbreviation: d.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In the United Kingdom, a copper coin worth 1/100 of a pound sterling. Abbreviation: p.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In Ireland, a coin worth 1/100 of an Irish pound before the introduction of the euro. Abbreviation: p.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In the US and Canada, a one-cent coin, worth 1/100 of a dollar. Abbreviation: ¢.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In various countries, a small-denomination copper or brass coin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A unit of nail size, said to be either the cost per 100 nails, or the number of nails per penny. Abbreviation: d.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Money in general.",
              synonyms: [],
              antonyms: [],
              example: "to turn an honest penny",
            },
          ],
          synonyms: ["new penny", "old penny", "cent"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To jam a door shut by inserting pennies between the doorframe and the door.",
              synonyms: [],
              antonyms: [],
              example:
                "Zach and Ben had only been at college for a week when their door was pennied by the girls down the hall.",
            },
            {
              definition:
                "To circumvent the tripping of an electrical circuit breaker by the dangerous practice of inserting a coin in place of a fuse in a fuse socket.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/penny"],
    },
  ],
  crown: [
    {
      word: "crown",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/crown-1-ca.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=788369",
          license: {
            name: "BY 2.5",
            url: "https://creativecommons.org/licenses/by/2.5",
          },
        },
        { text: "/kɹaʊn/", audio: "" },
        { text: "/kɹaʊn/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A royal, imperial or princely headdress; a diadem.",
              synonyms: ["coronet", "diadem"],
              antonyms: [],
            },
            {
              definition:
                "A wreath or band for the head, especially one given as reward of victory or a mark of honor.",
              synonyms: ["garland", "wreath"],
              antonyms: [],
            },
            {
              definition:
                "(by extension) Any reward of victory or mark of honor.",
              synonyms: ["award", "garland", "honor", "prize", "wreath"],
              antonyms: [],
              example: "the martyr's crown",
            },
            {
              definition: "Imperial or regal power, or those who wield it.",
              synonyms: ["monarchy", "royalty"],
              antonyms: [],
            },
            {
              definition:
                "(metonym) The sovereign (in a monarchy), as head of state.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) The state, the government (headed by a monarch).",
              synonyms: [],
              antonyms: [],
              example:
                "Treasure recovered from shipwrecks automatically becomes property of the Crown.",
            },
            {
              definition: "The top part of something:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A kind of spire or lantern formed by converging flying buttresses.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Splendor; culmination; acme.",
              synonyms: ["completion", "culmination", "finish", "splendor"],
              antonyms: [],
            },
            {
              definition:
                "Any currency (originally) issued by the crown (regal power) and often bearing a crown (headdress); (translation) various currencies known by similar names in their native languages, such as the koruna, kruna, krone, korona.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A former pre-decimalization British coin worth five shillings.",
              synonyms: [
                "bull",
                "caroon",
                "cartwheel",
                "caser",
                "coachwheel",
                "thick-un",
                "tosh",
                "tosheroon",
                "tush",
                "tusheroon",
              ],
              antonyms: [],
            },
            {
              definition: "The part of a plant where the root and stem meet.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The top of a tree.", synonyms: [], antonyms: [] },
            {
              definition: "The part of a tooth above the gums.",
              synonyms: ["corona"],
              antonyms: [],
            },
            {
              definition: "A prosthetic covering for a tooth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A knot formed in the end of a rope by tucking in the strands to prevent them from unravelling",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The part of an anchor where the arms and the shank meet",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The rounding, or rounded part, of the deck from a level line.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in the plural) The bights formed by the turns of a cable.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In England, a standard size of printing paper measuring 20 × 15 inches.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In American, a standard size of writing paper measuring 19 × 15 inches.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A monocyclic ligand having three or more binding sites, capable of holding a guest in a central location",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "During childbirth, the appearance of the baby's head from the mother's vagina",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A rounding or smoothing of the barrel opening",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The area enclosed between two concentric perimeters.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A round spot shaved clean on the top of the head, as a mark of the clerical state; the tonsure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A whole bird with the legs and wings removed to produce a joint of white meat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A formal hat worn by women to Sunday church services; a church crown.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The knurled knob or dial, on the outside of a watch case, used to wind it or adjust the hands.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "award",
            "garland",
            "honor",
            "prize",
            "wreath",
            "bull",
            "caroon",
            "cartwheel",
            "caser",
            "coachwheel",
            "thick-un",
            "tosh",
            "tosheroon",
            "tush",
            "tusheroon",
            "completion",
            "culmination",
            "finish",
            "splendor",
            "corona",
            "coronet",
            "diadem",
            "garland",
            "wreath",
            "monarchy",
            "royalty",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To place a crown on the head of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To formally declare (someone) a king, queen, emperor, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To bestow something upon as a mark of honour, dignity, or recompense; to adorn; to dignify.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To form the topmost or finishing part of; to complete; to consummate; to perfect.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To declare (someone) a winner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of a baby, during the birthing process; for the surface of the baby's head to appear in the vaginal opening.",
              synonyms: [],
              antonyms: [],
              example:
                "The mother was in the second stage of labor and the fetus had just crowned, prompting a round of encouragement from the midwives.",
            },
            {
              definition:
                "To cause to round upward; to make anything higher at the middle than at the edges, such as the face of a machine pulley.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To hit on the head.", synonyms: [], antonyms: [] },
            {
              definition:
                "To shoot an opponent in the back of the head with a shotgun in a first-person shooter video game.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In checkers, to stack two checkers to indicate that the piece has become a king.",
              synonyms: [],
              antonyms: [],
              example:
                "“Crown me!” I said, as I moved my checker to the back row.",
            },
            {
              definition: "To widen the opening of the barrel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To effect a lodgment upon, as upon the crest of the glacis, or the summit of the breach.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To lay the ends of the strands of (a knot) over and under each other.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of, related to, or pertaining to a crown.",
              synonyms: [],
              antonyms: [],
              example: "crown prince",
            },
            {
              definition:
                "Of, related to, pertaining to the top of a tree or trees.",
              synonyms: [],
              antonyms: [],
              example: "a crown fire",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/crown"],
    },
    {
      word: "crown",
      phonetic: "/kɹoʊn/",
      phonetics: [{ text: "/kɹoʊn/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make the shrill sound characteristic of a rooster; to make a sound in this manner, either in gaiety, joy, pleasure, or defiance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To shout in exultation or defiance; to brag.",
              synonyms: [],
              antonyms: [],
              example:
                "He’s been crowing all day about winning the game of cards.",
            },
            {
              definition:
                "To test the reed of a double reed instrument by placing the reed alone in the mouth and blowing it.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/crow",
        "https://en.wiktionary.org/wiki/crown",
      ],
    },
  ],
  shock: [
    {
      word: "shock",
      phonetic: "/ʃɒk/",
      phonetics: [
        { text: "/ʃɒk/", audio: "" },
        {
          text: "/ʃɑk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shock-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1157673",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A sudden, heavy impact.",
              synonyms: [],
              antonyms: [],
              example: "The train hit the buffers with a great shock.",
            },
            {
              definition:
                "A discontinuity arising in the solution of a partial differential equation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cause to be emotionally shocked, to cause (someone) to feel surprised and upset.",
              synonyms: [],
              antonyms: [],
              example: "The disaster shocked the world.",
            },
            {
              definition: "To give an electric shock to.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To meet with a shock; to collide in a violent encounter.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shock"],
    },
    {
      word: "shock",
      phonetic: "/ʃɒk/",
      phonetics: [
        { text: "/ʃɒk/", audio: "" },
        {
          text: "/ʃɑk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shock-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1157673",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An arrangement of sheaves for drying; a stook.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A lot consisting of sixty pieces; a term applied in some Baltic ports to loose goods.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) A tuft or bunch of something, such as hair or grass.",
              synonyms: [],
              antonyms: [],
              example: "His head boasted a shock of sandy hair.",
            },
            {
              definition:
                "A small dog with long shaggy hair, especially a poodle or spitz; a shaggy lapdog.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To collect, or make up, into a shock or shocks; to stook.",
              synonyms: [],
              antonyms: [],
              example: "to shock rye",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shock"],
    },
  ],
  taxes: [
    {
      word: "taxes",
      phonetic: "/ˈtæksɪz/",
      phonetics: [
        {
          text: "/ˈtæksɪz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/taxes-1-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9027551",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ˈtæksɪz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/taxes-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2079763",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Money paid to the government other than for transaction-specific goods and services.",
              synonyms: [
                "assessment",
                "contribution",
                "custom",
                "demand",
                "duty",
                "exaction",
                "impost",
                "levy",
                "rate",
                "toll",
                "tribute",
              ],
              antonyms: ["subsidy"],
            },
            {
              definition: "A burdensome demand.",
              synonyms: [],
              antonyms: [],
              example: "a heavy tax on time or health",
            },
            {
              definition:
                "A task exacted from one who is under control; a contribution or service, the rendering of which is imposed upon a subject.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Charge; censure", synonyms: [], antonyms: [] },
            {
              definition: "A lesson to be learned.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "assessment",
            "contribution",
            "custom",
            "demand",
            "duty",
            "exaction",
            "impost",
            "levy",
            "rate",
            "toll",
            "tribute",
          ],
          antonyms: ["subsidy"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To impose and collect a tax from (a person or company).",
              synonyms: [],
              antonyms: [],
              example: "Some think to tax the wealthy is the fairest.",
            },
            {
              definition: "To impose and collect a tax on (something).",
              synonyms: [],
              antonyms: [],
              example:
                "Some think to tax wealth is destructive of a private sector.",
            },
            {
              definition: "To make excessive demands on.",
              synonyms: [],
              antonyms: [],
              example: "Do not tax my patience.",
            },
            { definition: "To accuse.", synonyms: [], antonyms: [] },
            {
              definition:
                "To examine accounts in order to allow or disallow items.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tax",
        "https://en.wiktionary.org/wiki/taxes",
      ],
    },
    {
      word: "taxes",
      phonetic: "/ˈtæksiːz/",
      phonetics: [
        {
          text: "/ˈtæksiːz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/taxes-2-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88897059",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The directional movement of an organism in response to a stimulus.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The manipulation of a body part into its normal position after injury.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The arrangement of the parts of a topic.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Arrangement or ordering generally, as in architecture or grammar",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A brigade in an Ancient Greek army.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/taxes",
        "https://en.wiktionary.org/wiki/taxis",
      ],
    },
  ],
  sixty: [
    {
      word: "sixty",
      phonetic: "/ˈsɪkstɪ/",
      phonetics: [
        {
          text: "/ˈsɪkstɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sixty-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80066890",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈsɪksti/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sixty-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=867591",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "numeral",
          definitions: [
            {
              definition:
                "The cardinal number occurring after fifty-nine and before sixty-one, represented in Roman numerals as LX and in Arabic numerals as 60.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["60", "LX"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sixty"],
    },
  ],
  altar: [
    {
      word: "altar",
      phonetic: "/ˈɑl.tɚ/",
      phonetics: [
        { text: "/ˈɑl.tɚ/", audio: "" },
        { text: "/ˈɒl.tə/", audio: "" },
        {
          text: "/ˈɑl.tɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/altar-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769832",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A table or similar flat-topped structure used for religious rites.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A raised area around an altar in a church; the sanctuary.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Anything that is worshipped or sacrificed to.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/altar"],
    },
  ],
  pulls: [
    {
      word: "pulls",
      phonetic: "/pʊlz/",
      phonetics: [{ text: "/pʊlz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An act of pulling (applying force)",
              synonyms: [],
              antonyms: [],
              example: "He gave the hair a sharp pull and it came out.",
            },
            {
              definition:
                "An attractive force which causes motion towards the source",
              synonyms: [],
              antonyms: [],
              example: "She took a pull on her cigarette.",
            },
            {
              definition:
                "Any device meant to be pulled, as a lever, knob, handle, or rope",
              synonyms: [],
              antonyms: [],
              example: "a zipper pull",
            },
            {
              definition:
                "Something in one's favour in a comparison or a contest; an advantage; means of influencing.",
              synonyms: [],
              antonyms: [],
              example: "In weights the favourite had the pull.",
            },
            {
              definition: "Appeal or attraction (e.g. of a movie star)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The situation where a client sends out a request for data from a server, as in server pull, pull technology",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A journey made by rowing",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A contest; a struggle.",
              synonyms: [],
              antonyms: [],
              example: "va wrestling pull}}",
            },
            {
              definition: "Loss or violence suffered.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The act of drinking; a mouthful or swig of a drink.",
              synonyms: [],
              antonyms: [],
              example: "to take a pull at a mug of beer",
            },
            {
              definition:
                "A kind of stroke by which a leg ball is sent to the off side, or an off ball to the side.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A mishit shot which travels in a straight line and (for a right-handed player) left of the intended path.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A single impression from a handpress.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "drag",
            "toke",
            "tug",
            "yank",
            "attraction",
            "handle",
            "knob",
            "lever",
            "rope",
            "influence",
            "sway",
          ],
          antonyms: [
            "push",
            "shove",
            "repulsion",
            "button",
            "push",
            "push-button",
          ],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To apply a force to (an object) so that it comes toward the person or thing applying the force.",
              synonyms: [],
              antonyms: [],
              example: "When I give the signal, pull the rope.",
            },
            {
              definition:
                "To gather with the hand, or by drawing toward oneself; to pluck.",
              synonyms: [],
              antonyms: [],
              example: "pull a finch",
            },
            {
              definition: "To attract or net; to pull in.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To persuade (someone) to have sex with one.",
              synonyms: [],
              antonyms: [],
              example: "He's pulled that bird over there.",
            },
            {
              definition:
                "To remove (something), especially from public circulation or availability.",
              synonyms: [],
              antonyms: [],
              example:
                "Each day, they pulled the old bread and set out fresh loaves.",
            },
            {
              definition: "To retrieve or generate for use.",
              synonyms: [],
              antonyms: [],
              example: "I'll have to pull a part number for that.",
            },
            {
              definition: "To do or perform.",
              synonyms: [],
              antonyms: [],
              example: "He regularly pulls 12-hour days, sometimes 14.",
            },
            {
              definition:
                "(with 'a' and the name of a person, place, event, etc.) To copy or emulate the actions or behaviour that is associated with the person or thing mentioned.",
              synonyms: [],
              antonyms: [],
              example: "He pulled an Elvis and got really fat.",
            },
            {
              definition:
                "To toss a frisbee with the intention of launching the disc across the length of a field.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To row.", synonyms: [], antonyms: [] },
            {
              definition: "To achieve by rowing on a rowing machine.",
              synonyms: [],
              antonyms: [],
              example: "I pulled a personal best on the erg yesterday.",
            },
            {
              definition: "To draw apart; to tear; to rend.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To strain (a muscle, tendon, ligament, etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To draw (a hostile non-player character) into combat, or toward or away from some location or target.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To score a certain number of points in a sport.",
              synonyms: [],
              antonyms: [],
              example: "How many points did you pull today, Albert?",
            },
            {
              definition: "To hold back, and so prevent from winning.",
              synonyms: [],
              antonyms: [],
              example: "The favourite was pulled.",
            },
            {
              definition:
                "To take or make (a proof or impression); so called because hand presses were worked by pulling a lever.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To strike the ball in a particular manner. (See noun sense.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To draw beer from a pump, keg, or other source.",
              synonyms: [],
              antonyms: [],
              example:
                "Let's stop at Finnigan's. The barman pulls a good pint.",
            },
            {
              definition:
                "(rail transportation, of a railroad car) To pull out from a yard or station; to leave.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To pluck or pick (flowers, fruit etc.).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "drag",
            "tow",
            "tug",
            "yank",
            "score",
            "carry out",
            "complete",
            "do",
            "execute",
            "perform",
            "recall",
            "withdraw",
            "yank",
            "generate",
            "get",
            "get hold of",
            "get one's hands on",
            "lay one's hands on",
            "obtain",
            "retrieve",
            "score",
          ],
          antonyms: ["push", "repel", "shove"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pull",
        "https://en.wiktionary.org/wiki/pulls",
      ],
    },
  ],
  sport: [
    {
      word: "sport",
      phonetic: "/spɔːt/",
      phonetics: [
        { text: "/spɔːt/", audio: "" },
        {
          text: "/spɔɹt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sport-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780392",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any activity that uses physical exertion or skills competitively under a set of rules that is not based on aesthetics.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something done for fun, regardless of its design or intended purpose.",
              synonyms: [],
              antonyms: [],
              example:
                "Joe was banned from getting legal help. He seemed to view lawsuits as a sport.",
            },
            {
              definition:
                "A person who exhibits either good or bad sportsmanship.",
              synonyms: [],
              antonyms: [],
              example:
                "Jen may have won, but she was sure a poor sport; she laughed at the loser.",
            },
            {
              definition:
                "Somebody who behaves or reacts in an admirably good-natured manner, e.g. to being teased or to losing a game; a good sport.",
              synonyms: [],
              antonyms: [],
              example:
                "You're such a sport! You never get upset when we tease you.",
            },
            {
              definition:
                "That which diverts, and makes mirth; pastime; amusement.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Mockery, making fun; derision.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A toy; a plaything; an object of mockery.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Gaming for money as in racing, hunting, fishing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A plant or an animal, or part of a plant or animal, which has some peculiarity not usually seen in the species; an abnormal variety or growth. The term encompasses both mutants and organisms with non-genetic developmental abnormalities such as birth defects.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sportsman; a gambler.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One who consorts with disreputable people, including prostitutes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An amorous dalliance.",
              synonyms: [],
              antonyms: [],
              example:
                "Charlie and Lisa enjoyed a bit of sport after their hike.",
            },
            {
              definition:
                "(usually singular) A friend or acquaintance (chiefly used when speaking to the friend in question)",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Play; idle jingle.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To amuse oneself, to play.",
              synonyms: [],
              antonyms: [],
              example: "children sporting on the green",
            },
            {
              definition: "To mock or tease, treat lightly, toy with.",
              synonyms: [],
              antonyms: [],
              example: "Jen sports with Bill's emotions.",
            },
            {
              definition: "To display; to have as a notable feature.",
              synonyms: [],
              antonyms: [],
              example:
                "Jen's sporting a new pair of shoes;  he was sporting a new wound from the combat",
            },
            {
              definition: "To divert; to amuse; to make merry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To represent by any kind of play.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To practise the diversions of the field or the turf; to be given to betting, as upon races.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To assume suddenly a new and different character from the rest of the plant or from the type of the species; said of a bud, shoot, plant, or animal.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To close (a door).", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sport"],
    },
  ],
  drums: [
    {
      word: "drums",
      phonetic: "/dɹʌmz/",
      phonetics: [{ text: "/dɹʌmz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A percussive musical instrument spanned with a thin covering on at least one end for striking, forming an acoustic chamber, affecting what materials are used to make it; a membranophone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any similar hollow, cylindrical object.",
              synonyms: [],
              antonyms: [],
              example: "Replace the drum unit of your printer.",
            },
            {
              definition:
                "A barrel or large cylindrical container for liquid transport and storage.",
              synonyms: [],
              antonyms: [],
              example: "The restaurant ordered ketchup in 50-gallon drums.",
            },
            {
              definition: "The encircling wall that supports a dome or cupola.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of the cylindrical blocks that make up the shaft of a pillar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A drumfish (family Sciaenidae).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A tip; a piece of information.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A small hill or ridge of hills.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A social gathering or assembly held in the evening.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person's home; a house or other building, especially when insalubrious; a tavern, a brothel.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/drum",
        "https://en.wiktionary.org/wiki/drums",
      ],
    },
  ],
  talks: [
    {
      word: "talks",
      phonetic: "/ˈtɔːks/",
      phonetics: [{ text: "/ˈtɔːks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To communicate, usually by means of speech.",
              synonyms: [],
              antonyms: [],
              example:
                "Although I don't speak Chinese I managed to talk with the villagers using signs and gestures.",
            },
            {
              definition: "To discuss; to talk about.",
              synonyms: [],
              antonyms: [],
              example: "That's enough about work, let's talk holidays!",
            },
            {
              definition: "To speak (a certain language).",
              synonyms: [],
              antonyms: [],
              example: "We talk French sometimes.",
            },
            {
              definition:
                "(chiefly used in progressive tenses) Used to emphasise the importance, size, complexity etc. of the thing mentioned.",
              synonyms: [],
              antonyms: [],
              example:
                "Are you interested in the job? They're talking big money.",
            },
            {
              definition: "To confess, especially implicating others.",
              synonyms: [],
              antonyms: [],
              example: "She can be relied upon not to talk.",
            },
            {
              definition:
                "To criticize someone for something of which one is guilty oneself.",
              synonyms: [],
              antonyms: [],
              example: "I am not the one to talk.",
            },
            {
              definition: "To gossip; to create scandal.",
              synonyms: [],
              antonyms: [],
              example: "Aren't you afraid the neighbours will talk?",
            },
            {
              definition:
                "(chiefly used in progressive tenses) To influence someone to express something, especially a particular stance or viewpoint or in a particular manner.",
              synonyms: [],
              antonyms: [],
              example:
                "That's not like you at all, Jared. The drugs are talking. Snap out of it!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A conversation or discussion; usually serious, but informal.",
              synonyms: [],
              antonyms: [],
              example: "We need to have a talk about your homework.",
            },
            {
              definition: "A lecture.",
              synonyms: [],
              antonyms: [],
              example: "There is a talk on Shakespeare tonight.",
            },
            {
              definition: "Gossip; rumour.",
              synonyms: [],
              antonyms: [],
              example: "There's been talk lately about the two of them.",
            },
            {
              definition:
                "(preceded by the; often qualified by a following of) A major topic of social discussion.",
              synonyms: [],
              antonyms: [],
              example: "She is the talk of the day.",
            },
            {
              definition:
                "(preceded by the) A customary conversation by parent(s) or guardian(s) with their (often teenaged) child about a reality of life; in particular:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(not preceded by an article) Empty boasting, promises or claims.",
              synonyms: [],
              antonyms: [],
              example: "The party leader's speech was all talk.",
            },
            {
              definition:
                "(usually plural) Meeting to discuss a particular matter.",
              synonyms: [],
              antonyms: [],
              example:
                "The leaders of the G8 nations are currently in talks over nuclear weapons.",
            },
          ],
          synonyms: ["conference", "debate", "discussion", "meeting"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/talk",
        "https://en.wiktionary.org/wiki/talks",
      ],
    },
  ],
  dying: [
    {
      word: "dying",
      phonetic: "/ˈdaɪ.ɪŋ/",
      phonetics: [
        {
          text: "/ˈdaɪ.ɪŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dying-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1669297",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To stop living; to become dead; to undergo death.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To (stop living and) undergo (a specified death).",
              synonyms: [],
              antonyms: [],
              example: "He died a hero's death.",
            },
            {
              definition: "To yearn intensely.",
              synonyms: [],
              antonyms: [],
              example: "I'm dying for a packet of crisps.",
            },
            {
              definition:
                "To be or become hated or utterly ignored or cut off, as if dead.",
              synonyms: [],
              antonyms: [],
              example: "The day our sister eloped, she died to our mother.",
            },
            {
              definition: "To become spiritually dead; to lose hope.",
              synonyms: [],
              antonyms: [],
              example:
                "He died a little inside each time she refused to speak to him.",
            },
            {
              definition: "To be mortified or shocked by a situation.",
              synonyms: [],
              antonyms: [],
              example:
                "If anyone sees me wearing this ridiculous outfit, I'll die.",
            },
            {
              definition:
                "To be so overcome with emotion or laughter as to be incapacitated.",
              synonyms: [],
              antonyms: [],
              example:
                "When I found out my two favorite musicians would be recording an album together, I literally planned my own funeral arrangements and died.",
            },
            {
              definition: "(of a machine) To stop working, to break down.",
              synonyms: [],
              antonyms: [],
              example: "My car died in the middle of the freeway this morning.",
            },
            {
              definition:
                "(of a computer program) To abort, to terminate (as an error condition).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To perish; to cease to exist; to become lost or extinct.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To sink; to faint; to pine; to languish, with weakness, discouragement, love, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                '(often with "to") To become indifferent; to cease to be subject.',
              synonyms: [],
              antonyms: [],
              example: "to die to pleasure or to sin",
            },
            {
              definition:
                "To disappear gradually in another surface, as where mouldings are lost in a sloped or curved face.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To become vapid, flat, or spiritless, as liquor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a stand-up comedian or a joke) To fail to evoke laughter from the audience.",
              synonyms: [],
              antonyms: [],
              example: "Then there was that time I died onstage in Montreal...",
            },
          ],
          synonyms: [
            "be no more",
            "bite the big one",
            "bite the dust",
            "buy the farm",
            "cease to be",
            "check out",
            "cross over",
            "cross the river",
            "expire",
            "give up the ghost",
            "go to meet one's maker",
            "hop off the twig",
            "join the choir invisible",
            "kick the bucket",
            "pass",
            "pass away",
            "pass on",
            "push up the daisies",
            "shuffle off this mortal coil",
            "stiff",
            "succumb",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To colour with dye, or as if with dye.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["shade", "stain", "streak", "tint"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The process of approaching death; loss of life; less precisely, death.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                '(with "the") Those who are currently expiring, moribund.',
              synonyms: [],
              antonyms: [],
              example: "The battlefield was littered with the dead and dying.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Approaching death; about to die; moribund.",
              synonyms: [],
              antonyms: [],
              example:
                "The dying dog was put out of his misery with a single shot!",
            },
            {
              definition: "Declining, terminal, or drawing to an end.",
              synonyms: [],
              antonyms: [],
              example:
                "In the dying moments of daylight I glimpsed a sail on the horizon.",
            },
            {
              definition: "Pertaining to death, or the moments before death.",
              synonyms: [],
              antonyms: [],
              example: "His dying words were of his mother.",
            },
          ],
          synonyms: [],
          antonyms: ["nascent"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/die",
        "https://en.wiktionary.org/wiki/dye",
        "https://en.wiktionary.org/wiki/dying",
      ],
    },
  ],
  dates: [
    {
      word: "dates",
      phonetic: "/deɪts/",
      phonetics: [
        {
          text: "/deɪts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dates-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=61116321",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The fruit of the date palm, Phoenix dactylifera, somewhat in the shape of an olive, containing a soft, sweet pulp and enclosing a hard kernel.",
              synonyms: [],
              antonyms: [],
              example: "We made a nice cake from dates.",
            },
            {
              definition: "The date palm.",
              synonyms: [],
              antonyms: [],
              example: "There were a few dates planted around the house.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The addition to a writing, inscription, coin, etc., which specifies the time (especially the day, month, and year) when the writing or inscription was given, executed, or made.",
              synonyms: [],
              antonyms: [],
              example:
                "the date of a letter, of a will, of a deed, of a coin, etc.",
            },
            {
              definition:
                "A specific day in time at which a transaction or event takes place, or is appointed to take place; a given point of time.",
              synonyms: [],
              antonyms: [],
              example: "Do you know the date of the wedding?",
            },
            {
              definition: "A point in time.",
              synonyms: [],
              antonyms: [],
              example: "You may need that at a later date.",
            },
            {
              definition: "Assigned end; conclusion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Given or assigned length of life; duration.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A pre-arranged meeting.",
              synonyms: [],
              antonyms: [],
              example:
                "I arranged a date with my Australian business partners.",
            },
            {
              definition: "One's companion for social activities or occasions.",
              synonyms: [],
              antonyms: [],
              example: "I brought Melinda to the wedding as my date.",
            },
            {
              definition:
                "A romantic meeting or outing with a lover or potential lover, or the person so met.",
              synonyms: [],
              antonyms: [],
              example:
                "The cinema is a popular place to take someone on a date.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To note the time or place of writing or executing; to express in an instrument the time of its execution.",
              synonyms: [],
              antonyms: [],
              example: "to date a letter, a bond, a deed, or a charter",
            },
            {
              definition:
                "To note or fix the time of (an event); to give the date of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To determine the age of something.",
              synonyms: [],
              antonyms: [],
              example: "to date the building of the pyramids",
            },
            {
              definition: "To take (someone) on a date, or a series of dates.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) To have a steady relationship with; to be romantically involved with.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) To have a steady relationship with each other; to be romantically involved with each other.",
              synonyms: [],
              antonyms: [],
              example:
                "They met a couple of years ago, but have been dating for about five months.",
            },
            {
              definition:
                "To make or become old, especially in such a way as to fall out of fashion, become less appealing or attractive, etc.",
              synonyms: [],
              antonyms: [],
              example:
                "The comedian dated himself by making quips about bands from the 1960s.",
            },
            {
              definition:
                "(with from) To have beginning; to begin; to be dated or reckoned.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["age", "elden", "obsolesce", "go out", "see"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/date",
        "https://en.wiktionary.org/wiki/dates",
      ],
    },
  ],
  drank: [
    {
      word: "drank",
      phonetic: "/dɹæŋk/",
      phonetics: [
        { text: "/dɹæŋk/", audio: "" },
        {
          text: "/dɹæŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/drank-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2079683",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Dextromethorphan", synonyms: [], antonyms: [] },
            {
              definition: "A drink, usually alcoholic",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To consume (a liquid) through the mouth.",
              synonyms: [],
              antonyms: [],
              example: "He drank the water I gave him.",
            },
            {
              definition:
                "(metonymic) To consume the liquid contained within (a bottle, glass, etc.).",
              synonyms: [],
              antonyms: [],
              example: "Jack drank the whole bottle by himself.",
            },
            {
              definition: "To consume alcoholic beverages.",
              synonyms: [],
              antonyms: [],
              example:
                "Everyone who is drinking is drinking, but not everyone who is drinking is drinking.",
            },
            {
              definition:
                "To take in (a liquid), in any manner; to suck up; to absorb; to imbibe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To take in; to receive within one, through the senses; to inhale; to hear; to see.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To smoke, as tobacco.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "gulp",
            "imbibe",
            "quaff",
            "sip",
            "alcohol",
            "hit the sauce",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/drank",
        "https://en.wiktionary.org/wiki/drink",
      ],
    },
    {
      word: "drank",
      phonetic: "/dɹæŋk/",
      phonetics: [
        { text: "/dɹæŋk/", audio: "" },
        {
          text: "/dɹæŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/drank-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2079683",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Wild oats, or darnel grass.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/drank"],
    },
  ],
  blows: [
    {
      word: "blows",
      phonetic: "/bləʊz/",
      phonetics: [
        { text: "/bləʊz/", audio: "" },
        {
          text: "/bloʊz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/blows-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100644",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A strong wind.",
              synonyms: [],
              antonyms: [],
              example: "We're having a bit of a blow this afternoon.",
            },
            {
              definition: "A chance to catch one’s breath.",
              synonyms: [],
              antonyms: [],
              example:
                "The players were able to get a blow during the last timeout.",
            },
            { definition: "Cocaine.", synonyms: [], antonyms: [] },
            { definition: "Cannabis.", synonyms: [], antonyms: [] },
            {
              definition: "(US Chicago Regional) Heroin.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["snow"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To produce an air current.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To propel by an air current.",
              synonyms: [],
              antonyms: [],
              example: "Blow the dust off that book and open it up.",
            },
            {
              definition: "To be propelled by an air current.",
              synonyms: [],
              antonyms: [],
              example: "The leaves blow through the streets in the fall.",
            },
            {
              definition:
                "To create or shape by blowing; as in to blow bubbles, to blow glass.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To force a current of air upon with the mouth, or by other means.",
              synonyms: [],
              antonyms: [],
              example: "to blow the fire",
            },
            {
              definition: "To clear of contents by forcing air through.",
              synonyms: [],
              antonyms: [],
              example: "to blow an egg",
            },
            {
              definition:
                "To cause to make sound by blowing, as a musical instrument.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make a sound as the result of being blown.",
              synonyms: [],
              antonyms: [],
              example: "In the harbor, the ships' horns blew.",
            },
            {
              definition:
                "(of a cetacean) To exhale visibly through the spout the seawater which it has taken in while feeding.",
              synonyms: [],
              antonyms: [],
              example: 'There she blows! (i.e. "I see a whale spouting!")',
            },
            {
              definition: "To explode.",
              synonyms: [],
              antonyms: [],
              example:
                "Get away from that burning gas tank! It's about to blow!",
            },
            {
              definition:
                '(with "up" or with prep phrase headed by "to") To cause to explode, shatter, or be utterly destroyed.',
              synonyms: [],
              antonyms: [],
              example: "The aerosol can was blown to bits.",
            },
            {
              definition: "To cause sudden destruction of.",
              synonyms: [],
              antonyms: [],
              example: "He blew the tires and the engine.",
            },
            {
              definition: "To suddenly fail destructively.",
              synonyms: [],
              antonyms: [],
              example:
                "He tried to sprint, but his ligaments blew and he was barely able to walk to the finish line.",
            },
            {
              definition: "(used to express displeasure or frustration) Damn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be very undesirable. (See also suck.)",
              synonyms: [],
              antonyms: [],
              example: "This blows!",
            },
            {
              definition: "To recklessly squander.",
              synonyms: [],
              antonyms: [],
              example: "I blew $35 thou on a car.",
            },
            {
              definition: "To fellate; to perform oral sex on (usually a man)",
              synonyms: [],
              antonyms: [],
              example:
                "Who did you have to blow to get those backstage passes?",
            },
            {
              definition: "To leave, especially suddenly or in a hurry.",
              synonyms: [],
              antonyms: [],
              example: "Let's blow this joint.",
            },
            {
              definition:
                "To make flyblown, to defile, especially with fly eggs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To spread by report; to publish; to disclose.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To inflate, as with pride; to puff up.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To breathe hard or quick; to pant; to puff.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To put out of breath; to cause to blow from fatigue.",
              synonyms: [],
              antonyms: [],
              example: "to blow a horse",
            },
            {
              definition: "To talk loudly; to boast; to storm.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To sing.",
              synonyms: [],
              antonyms: [],
              example:
                "That girl has a wonderful voice; just listen to her blow!",
            },
            {
              definition:
                "To leave the Church of Scientology in an unauthorized manner.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act of striking or hitting.",
              synonyms: ["bace", "hit", "punch", "strike"],
              antonyms: [],
              example:
                "A fabricator is used to direct a sharp blow to the surface of the stone.",
            },
            {
              definition: "A sudden or forcible act or effort; an assault.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A damaging occurrence.",
              synonyms: ["calamity", "disaster"],
              antonyms: [],
              example:
                "A further blow to the group came in 1917 when Thomson died while canoeing in Algonquin Park.",
            },
          ],
          synonyms: ["bace", "hit", "punch", "strike", "calamity", "disaster"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A mass or display of flowers; a yield.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A display of anything brilliant or bright.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A bloom, state of flowering.",
              synonyms: [],
              antonyms: [],
              example: "Roses in full blow.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To blossom; to cause to bloom or blossom.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/blow",
        "https://en.wiktionary.org/wiki/blows",
      ],
    },
  ],
  lever: [
    {
      word: "lever",
      phonetic: "/ˈliː.vɚ/",
      phonetics: [
        { text: "/ˈliː.vɚ/", audio: "" },
        {
          text: "/ˈliː.və/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lever-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=8789538",
        },
        {
          text: "/ˈlɛ.vɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lever-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1644214",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(except in generalized senses below) A crowbar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A rigid piece which is capable of turning about one point, or axis (the fulcrum), and in which are two or more other points where forces are applied; — used for transmitting and modifying force and motion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small such piece to trigger or control a mechanical device (like a button).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bar, as a capstan bar, applied to a rotatory piece to turn it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An arm on a rock shaft, to give motion to the shaft or to obtain motion from it.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To move with a lever.",
              synonyms: [],
              antonyms: [],
              example:
                "With great effort and a big crowbar I managed to lever the beam off the floor.",
            },
            {
              definition:
                "To use, operate or move (something) like a lever (physically).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To use (something) like a lever (in an abstract sense).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To increase the share of debt in the capitalization of a business.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lever"],
    },
    {
      word: "lever",
      phonetic: "/ˈliː.vɚ/",
      phonetics: [
        { text: "/ˈliː.vɚ/", audio: "" },
        {
          text: "/ˈliː.və/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lever-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=8789538",
        },
        {
          text: "/ˈlɛ.vɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lever-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1644214",
        },
      ],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [{ definition: "Rather.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lever"],
    },
    {
      word: "lever",
      phonetic: "/ˈliː.vɚ/",
      phonetics: [
        { text: "/ˈliː.vɚ/", audio: "" },
        {
          text: "/ˈliː.və/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lever-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=8789538",
        },
        {
          text: "/ˈlɛ.vɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lever-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1644214",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "A levee.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lever"],
    },
  ],
  wages: [
    {
      word: "wages",
      phonetic: "/ˈweɪd͡ʒɪz/",
      phonetics: [
        {
          text: "/ˈweɪd͡ʒɪz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wages-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100874",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(often in plural) An amount of money paid to a worker for a specified quantity of work, usually calculated on an hourly basis and expressed in an amount of money per hour.",
              synonyms: [],
              antonyms: [],
              example: "Before her promotion, her wages were 20% less.",
            },
          ],
          synonyms: ["earnings", "pay", "salary"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To wager, bet.", synonyms: [], antonyms: [] },
            {
              definition:
                "To expose oneself to, as a risk; to incur, as a danger; to venture; to hazard.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To employ for wages; to hire.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To conduct or carry out (a war or other contest).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To adventure, or lay out, for hire or reward; to hire out.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To give security for the performance of.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wage",
        "https://en.wiktionary.org/wiki/wages",
      ],
    },
  ],
  proof: [
    {
      word: "proof",
      phonetic: "/pɹʉːf/",
      phonetics: [
        { text: "/pɹʉːf/", audio: "" },
        { text: "/pɹuːf/", audio: "" },
        { text: "/pɹʉːf/", audio: "" },
        { text: "/pɹuːf/", audio: "" },
        {
          text: "/pɹuf/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/proof-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1669339",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An effort, process, or operation designed to establish or discover a fact or truth; an act of testing; a test; a trial.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The degree of evidence which convinces the mind of any truth or fact, and produces belief; a test by facts or arguments which induce, or tend to induce, certainty of the judgment; conclusive evidence; demonstration.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The quality or state of having been proved or tried; firmness or hardness which resists impression, or does not yield to force; impenetrability of physical bodies.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Experience of something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Firmness of mind; stability not to be shaken.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A proof sheet; a trial impression, as from type, taken for correction or examination.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sequence of statements consisting of axioms, assumptions, statements already demonstrated in another proof, and statements that logically follow from previous statements in the sequence, and which concludes with a statement that is the object of the proof.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A process for testing the accuracy of an operation performed. Compare prove, transitive verb, 5.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Armour of excellent or tried quality, and deemed impenetrable; properly, armour of proof.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A measure of the alcohol content of liquor. Originally, in Britain, 100 proof was defined as 57.1% by volume (no longer used). In the US, 100 proof means that the alcohol content is 50% of the total volume of the liquid; thus, absolute alcohol would be 200 proof.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To proofread.", synonyms: [], antonyms: [] },
            {
              definition: "To make resistant, especially to water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To allow yeast-containing dough to rise.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To test the activeness of yeast.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Used in proving or testing.",
              synonyms: [],
              antonyms: [],
              example: "a proof load; a proof charge",
            },
            {
              definition: "Firm or successful in resisting.",
              synonyms: [],
              antonyms: [],
              example: "proof against harm",
            },
            {
              definition:
                "(of alcoholic liquors) Being of a certain standard as to alcohol content.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/proof"],
    },
  ],
  drugs: [
    {
      word: "drugs",
      phonetic: "/dɹʌɡz/",
      phonetics: [
        { text: "/dɹʌɡz/", audio: "" },
        {
          text: "/dɹʌɡz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/drugs-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2650884",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A substance used to treat an illness, relieve a symptom, or modify a chemical process in the body for a specific purpose.",
              synonyms: [],
              antonyms: [],
              example:
                "Aspirin is a drug that reduces pain, acts against inflammation and lowers body temperature.",
            },
            {
              definition:
                "A psychoactive substance, especially one which is illegal and addictive, ingested for recreational use, such as cocaine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Anything, such as a substance, emotion or action, to which one is addicted.",
              synonyms: [],
              antonyms: [],
              example:
                "2010, Kesha Rose Sebert (Ke$ha), with Pebe Sebert and Joshua Coleman (Ammo), Your Love is My Drug",
            },
            {
              definition:
                "Any commodity that lies on hand, or is not salable; an article of slow sale, or in no demand.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To administer intoxicating drugs to, generally without the recipient's knowledge or consent.",
              synonyms: [],
              antonyms: [],
              example:
                "She suddenly felt strange, and only then realized she'd been drugged.",
            },
            {
              definition:
                "To add intoxicating drugs to with the intention of drugging someone.",
              synonyms: [],
              antonyms: [],
              example:
                "She suddenly felt strange. She realized her drink must have been drugged.",
            },
            {
              definition: "To prescribe or administer drugs or medicines.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A drudge.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/drug",
        "https://en.wiktionary.org/wiki/drugs",
      ],
    },
  ],
  tanks: [
    {
      word: "tanks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A closed container for liquids or gases.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An open container or pool for storing water or other liquids.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A pond, pool, or small lake, natural or artificial.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The fuel reservoir of a vehicle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The amount held by a container; a tankful.",
              synonyms: [],
              antonyms: [],
              example: "I burned three tanks of gas on the drive to New York.",
            },
            {
              definition:
                "An armoured fighting vehicle, armed with a gun, and moving on caterpillar tracks.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(Australian and Indian English) A reservoir or dam.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A large metal container for holding drinking water for animals, usually placed near a wind-driven water pump, in an animal pen or field.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "By extension a small pond for the same purpose.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A very muscular and physically imposing person. Somebody who is built like a tank.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A unit or character designed primarily around damage absorption and holding the attention of the enemy (as opposed to dealing damage, healing, or other tasks).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A prison cell, or prison generally.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A metaphorical place where a player goes to contemplate a decision; see in the tank.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["armour", "battle tank", "combat tank", "tango"],
          antonyms: ["glass cannon"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To fail or fall (often used in describing the economy or the stock market); to degenerate or decline rapidly; to plummet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To attract the attacks of an enemy target in cooperative team-based combat, so that one's teammates can defeat the enemy in question more efficiently.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To put (fuel, etc.) into a tank.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To deliberately lose a sports match with the intent of gaining a perceived future competitive advantage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To resist damage; to be attacked without being hurt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To contemplate a decision for a long time; to go in the tank.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small Indian dry measure, averaging 240 grains in weight.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A Bombay weight of 72 grains, for pearls.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To stand; to tolerate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tank",
        "https://en.wiktionary.org/wiki/tanks",
      ],
    },
    {
      word: "tanks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "interjection",
          definitions: [{ definition: "Thanks", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tanks"],
    },
  ],
  sings: [
    {
      word: "sings",
      phonetic: "/sɪŋz/",
      phonetics: [
        {
          text: "/sɪŋz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sings-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651508",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A gathering at which people sing songs.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To produce musical or harmonious sounds with one’s voice.",
              synonyms: [],
              antonyms: [],
              example:
                '"I really want to sing in the school choir," said Vera.',
            },
            {
              definition:
                "To express audibly by means of a harmonious vocalization.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To soothe with singing.",
              synonyms: [],
              antonyms: [],
              example: "to sing somebody to sleep",
            },
            {
              definition: "To confess under interrogation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make a small, shrill sound.",
              synonyms: [],
              antonyms: [],
              example: "The air sings in passing through a crevice.",
            },
            {
              definition: "To relate in verse; to celebrate in poetry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To display fine qualities; to stand out as excellent.",
              synonyms: [],
              antonyms: [],
              example: "The sauce really makes this lamb sing.",
            },
            {
              definition:
                "To be capable of being sung; to produce a certain effect by being sung.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sing",
        "https://en.wiktionary.org/wiki/sings",
      ],
    },
  ],
  tails: [
    {
      word: "tails",
      phonetic: "/teɪlz/",
      phonetics: [
        {
          text: "/teɪlz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tails-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79294184",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The caudal appendage of an animal that is attached to its posterior and near the anus.",
              synonyms: [],
              antonyms: [],
              example: "Most primates have a tail and fangs.",
            },
            {
              definition:
                "An object or part of an object resembling a tail in shape, such as the thongs on a cat-o'-nine-tails.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The back, last, lower, or inferior part of anything.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The feathers attached to the pygostyle of a bird.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The tail-end of an object, e.g. the rear of an aircraft's fuselage, containing the tailfin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The rear structure of an aircraft, the empennage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The visible stream of dust and gases blown from a comet by the solar wind.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The latter part of a time period or event, or (collectively) persons or objects represented in this part.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The part of a distribution most distant from the mode; as, a long tail.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One who surreptitiously follows another.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The lower order of batsmen in the batting order, usually specialist bowlers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The lower loop of the letters in the Roman alphabet, as in g, q or y.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(chiefly in the plural) The side of a coin not bearing the head; normally the side on which the monetary value of the coin is indicated; the reverse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "All the last terms of a sequence, from some term on.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The buttocks or backside.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The penis of a person or animal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Sexual intercourse.",
              synonyms: [],
              antonyms: [],
              example: "I'm gonna get me some tail tonight.",
            },
            {
              definition: "(kayaking) The stern; the back of the kayak.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A train or company of attendants; a retinue.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The distal tendon of a muscle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A filamentous projection on the tornal section of each hind wing of certain butterflies.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A downy or feathery appendage of certain achens, formed of the permanent elongated style.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A portion of an incision, at its beginning or end, which does not go through the whole thickness of the skin, and is more painful than a complete incision; called also tailing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the strips at the end of a bandage formed by splitting the bandage one or more times.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A rope spliced to the strap of a block, by which it may be lashed to anything.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The part of a note which runs perpendicularly upward or downward from the head; the stem.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A tailing.", synonyms: [], antonyms: [] },
            {
              definition:
                "The bottom or lower portion of a member or part such as a slate or tile.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A tailcoat.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "ass",
            "poon",
            "poontang",
            "punani",
            "pussy",
            "skin",
            "tang",
            "descender",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To follow and observe surreptitiously.",
              synonyms: [],
              antonyms: [],
              example: "Tail that car!",
            },
            {
              definition:
                "To hold by the end; said of a timber when it rests upon a wall or other support; with in or into",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To swing with the stern in a certain direction; said of a vessel at anchor.",
              synonyms: [],
              antonyms: [],
              example: "This vessel tails downstream.",
            },
            {
              definition:
                "To follow or hang to, like a tail; to be attached closely to, as that which can not be evaded.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To pull or draw by the tail.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A formal evening jacket with an extended back panel; a dress coat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any coat with similar tails.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The side of a coin that doesn't bear the picture of the head of state or similar.",
              synonyms: [],
              antonyms: ["heads"],
              example: "Tails, I win.",
            },
            { definition: "Tailings; waste", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: ["heads"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tail",
        "https://en.wiktionary.org/wiki/tailcoat",
        "https://en.wiktionary.org/wiki/tails",
      ],
    },
  ],
  pause: [
    {
      word: "pause",
      phonetic: "/pɔːz/",
      phonetics: [
        { text: "/pɔːz/", audio: "" },
        {
          text: "/pɔz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pause-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1684686",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A button whose functions are pausing and resuming something, such as a DVD player, a video game or a computer.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A temporary stop or rest; an intermission of action; interruption; suspension; cessation.",
              synonyms: ["hiatus", "moratorium", "recess"],
              antonyms: [],
            },
            {
              definition: "A short time for relaxing and doing something else.",
              synonyms: ["break", "holiday", "recess"],
              antonyms: [],
            },
            {
              definition: "Hesitation; suspense; doubt.",
              synonyms: ["vacillation", "wavering"],
              antonyms: [],
            },
            {
              definition:
                "In writing and printing, a mark indicating the place and nature of an arrest of voice in reading; a punctuation mark.",
              synonyms: [],
              antonyms: [],
              example: "Teach the pupil to mind the pauses.",
            },
            {
              definition: "A break or paragraph in writing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sign indicating continuance of a note or rest.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(as direct object) take pause: hesitate; give pause: cause to hesitate",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "break",
            "holiday",
            "recess",
            "hiatus",
            "moratorium",
            "recess",
            "vacillation",
            "wavering",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To take a temporary rest, take a break for a short period after an effort.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To interrupt an activity and wait.",
              synonyms: [],
              antonyms: [],
              example: "When telling the scary story, he paused for effect.",
            },
            {
              definition: "To hesitate; to hold back; to delay.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To halt the play or playback of, temporarily, so that it can be resumed from the same point.",
              synonyms: [],
              antonyms: [],
              example: "to pause a song, a video, or a computer game",
            },
            {
              definition: "To consider; to reflect.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/Pause",
        "https://en.wiktionary.org/wiki/pause",
      ],
    },
  ],
  herds: [
    {
      word: "herds",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A number of domestic animals assembled together under the watch or ownership of a keeper.",
              synonyms: [],
              antonyms: [],
              example: "a herd of cattle",
            },
            {
              definition:
                "Any collection of animals gathered or travelling in a company.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(now usually derogatory) A crowd, a mass of people; now usually pejorative: a rabble.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To unite or associate in a herd; to feed or run together, or in company.",
              synonyms: [],
              antonyms: [],
              example: "Sheep herd on many hills.",
            },
            {
              definition: "To unite or associate in a herd",
              synonyms: [],
              antonyms: [],
              example: "He is employed to herd the goats.",
            },
            {
              definition:
                "To associate; to ally oneself with, or place oneself among, a group or company.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Someone who keeps a group of domestic animals; a herdsman.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To act as a herdsman or a shepherd.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To form or put into a herd.",
              synonyms: [],
              antonyms: [],
              example:
                "I heard the herd of cattle being herded home from a long way away.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/herd",
        "https://en.wiktionary.org/wiki/herds",
      ],
    },
  ],
  arose: [
    {
      word: "arose",
      phonetic: "/əˈɹoʊz/",
      phonetics: [
        {
          text: "/əˈɹoʊz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/arose-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1676691",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To come up from a lower to a higher position.",
              synonyms: [],
              antonyms: [],
              example: "to arise from a kneeling posture",
            },
            {
              definition:
                "To come up from one's bed or place of repose; to get up.",
              synonyms: [],
              antonyms: [],
              example: "He arose early in the morning.",
            },
            {
              definition:
                "To spring up; to come into action, being, or notice; to become operative, sensible, or visible; to begin to act a part; to present itself.",
              synonyms: [],
              antonyms: [],
              example: "A cloud arose and covered the sun.",
            },
          ],
          synonyms: [
            "rise",
            "spring",
            "stand up",
            "awaken",
            "come about",
            "come to pass",
            "occur",
            "appear",
            "emerge",
            "originate",
            "pop up",
            "reappear",
            "surface",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/arise",
        "https://en.wiktionary.org/wiki/arose",
      ],
    },
  ],
  hated: [
    {
      word: "hated",
      phonetic: "/ˈheɪtɪd/",
      phonetics: [
        {
          text: "/ˈheɪtɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hated-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2079705",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To dislike intensely or greatly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To experience hatred.",
              synonyms: [],
              antonyms: [],
              example:
                "Do not fear; he who fears hates; he who hates kills. — attributed to Gandhi",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Disliked; odious; reviled.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hate",
        "https://en.wiktionary.org/wiki/hated",
      ],
    },
  ],
  clues: [
    {
      word: "clues",
      phonetic: "/kluːz/",
      phonetics: [{ text: "/kluːz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A strand of yarn etc. as used to guide one through a labyrinth; something which points the way, a guide.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Information which may lead one to a certain point or conclusion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An object or a kind of indication which may be used as evidence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'Insight or understanding ("to have a clue [about]" or "to have clue". See have a clue, clue stick)',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["hint", "indication", "suggestion", "signature", "idea"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To provide with a clue.",
              synonyms: [],
              antonyms: [],
              example:
                'The crossword compiler wasn\'t sure how to clue the word "should".',
            },
            {
              definition:
                'To provide someone with information which he or she lacks (often used with "in" or "up").',
              synonyms: [],
              antonyms: [],
              example: "Smith, clue Jones in on what's been happening.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/clue",
        "https://en.wiktionary.org/wiki/clues",
      ],
    },
  ],
  novel: [
    {
      word: "novel",
      phonetic: "/ˈnɒvl̩/",
      phonetics: [
        { text: "/ˈnɒvl̩/", audio: "" },
        {
          text: "/ˈnɑvəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/novel-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=187018",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Newly made, formed or evolved; having no precedent; of recent origin; new.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Original, especially in an interesting way; new and striking; not of the typical or ordinary type.",
              synonyms: ["unusual"],
              antonyms: [],
            },
          ],
          synonyms: ["unusual"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/novel"],
    },
    {
      word: "novel",
      phonetic: "/ˈnɒvl̩/",
      phonetics: [
        { text: "/ˈnɒvl̩/", audio: "" },
        {
          text: "/ˈnɑvəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/novel-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=187018",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A work of prose fiction, longer than a novella.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A fable; a short tale, especially one of many making up a larger work.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/novel"],
    },
    {
      word: "novel",
      phonetic: "/ˈnɒvl̩/",
      phonetics: [
        { text: "/ˈnɒvl̩/", audio: "" },
        {
          text: "/ˈnɑvəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/novel-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=187018",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A novelty; something new.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/novel"],
    },
    {
      word: "novel",
      phonetic: "/ˈnɒvl̩/",
      phonetics: [
        { text: "/ˈnɒvl̩/", audio: "" },
        {
          text: "/ˈnɑvəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/novel-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=187018",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A new legal constitution in ancient Rome.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/novel"],
    },
  ],
  shame: [
    {
      word: "shame",
      phonetic: "/ʃeɪm/",
      phonetics: [
        {
          text: "/ʃeɪm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shame-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1157662",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Uncomfortable or painful feeling due to recognition or consciousness of one's own impropriety or dishonor or something being exposed that should have been kept private.",
              synonyms: [],
              antonyms: [],
              example:
                "The teenager couldn’t bear the shame of introducing his parents.",
            },
            {
              definition: "Something to regret.",
              synonyms: [],
              antonyms: [],
              example:
                "It was a shame not to see the show after driving all that way.",
            },
            {
              definition:
                "Reproach incurred or suffered; dishonour; ignominy; derision.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The cause or reason of shame; that which brings reproach and ignominy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That which is shameful and private, especially private parts.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "dishonor",
            "humiliation",
            "mortification",
            "pity",
            "dishonor",
          ],
          antonyms: ["honor"],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition:
                "A cry of admonition for the subject of a speech, either to denounce the speaker or to agree with the speaker's denunciation of some person or matter; often used reduplicated, especially in political debates.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Expressing sympathy.",
              synonyms: [],
              antonyms: [],
              example: "Shame, you poor thing, you must be cold!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shame"],
    },
    {
      word: "shame",
      phonetic: "/ʃeɪm/",
      phonetics: [
        {
          text: "/ʃeɪm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shame-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1157662",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cause to feel shame.",
              synonyms: [],
              antonyms: [],
              example: "I was shamed by the teacher's public disapproval.",
            },
            {
              definition:
                "To cover with reproach or ignominy; to dishonor; to disgrace.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To drive or compel by shame.",
              synonyms: [],
              antonyms: [],
              example: "The politician was shamed into resigning.",
            },
            {
              definition: "To feel shame, be ashamed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To mock at; to deride.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["demean", "humiliate", "insult", "mortify"],
          antonyms: ["dignify", "honor"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shame"],
    },
  ],
  burnt: [
    {
      word: "burnt",
      phonetic: "/ˈbɜːnt/",
      phonetics: [
        { text: "/ˈbɜːnt/", audio: "" },
        {
          text: "/ˈbɜɹnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/burnt-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100343",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cause to be consumed by fire.",
              synonyms: [],
              antonyms: [],
              example: "He burned his manuscript in the fireplace.",
            },
            {
              definition: "To be consumed by fire, or in flames.",
              synonyms: [],
              antonyms: [],
              example: "He watched the house burn.",
            },
            {
              definition: "To overheat so as to make unusable.",
              synonyms: [],
              antonyms: [],
              example: "He burned the toast. The blacksmith burned the steel.",
            },
            {
              definition:
                "To become overheated to the point of being unusable.",
              synonyms: [],
              antonyms: [],
              example: "The grill was too hot and the steak burned.",
            },
            {
              definition:
                "To make or produce by the application of fire or burning heat.",
              synonyms: [],
              antonyms: [],
              example: "to burn a hole;  to burn letters into a block",
            },
            {
              definition:
                "To injure (a person or animal) with heat or chemicals that produce similar damage.",
              synonyms: [],
              antonyms: [],
              example:
                "She burned the child with an iron, and was jailed for ten years.",
            },
            { definition: "To cauterize.", synonyms: [], antonyms: [] },
            {
              definition: "To sunburn.",
              synonyms: [],
              antonyms: [],
              example: "She forgot to put on sunscreen and burned.",
            },
            {
              definition:
                "To consume, injure, or change the condition of, as if by action of fire or heat; to affect as fire or heat does.",
              synonyms: [],
              antonyms: [],
              example: "to burn the mouth with pepper",
            },
            {
              definition: "To be hot, e.g. due to embarrassment.",
              synonyms: [],
              antonyms: [],
              example:
                "The child's forehead was burning with fever.  Her cheeks burned with shame.",
            },
            {
              definition:
                "To cause to combine with oxygen or other active agent, with evolution of heat; to consume; to oxidize.",
              synonyms: [],
              antonyms: [],
              example:
                "A human being burns a certain amount of carbon at each respiration.  to burn iron in oxygen",
            },
            {
              definition: "To combine energetically, with evolution of heat.",
              synonyms: [],
              antonyms: [],
              example: "Copper burns in chlorine.",
            },
            {
              definition:
                "To write data to a permanent storage medium like a compact disc or a ROM chip.",
              synonyms: [],
              antonyms: [],
              example:
                "We’ll burn this program onto an EEPROM one hour before the demo begins.",
            },
            {
              definition: "To betray.",
              synonyms: [],
              antonyms: [],
              example: "The informant burned him.",
            },
            {
              definition: "To insult or defeat.",
              synonyms: [],
              antonyms: [],
              example: "I just burned you again.",
            },
            {
              definition: "To waste (time); to waste money or other resources.",
              synonyms: [],
              antonyms: [],
              example:
                "The company has burned more than a million dollars a month this year.",
            },
            {
              definition:
                "In certain games, to approach near to a concealed object which is sought.",
              synonyms: [],
              antonyms: [],
              example: "You're cold... warm... hot... you're burning!",
            },
            {
              definition: "To accidentally touch a moving stone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In pontoon, to swap a pair of cards for another pair, or to deal a dead card.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To increase the exposure for certain areas of a print in order to make them lighter (compare dodge).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of an element) To be converted to another element in a nuclear fusion reaction, especially in a star",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To discard.", synonyms: [], antonyms: [] },
            {
              definition: "To shoot someone with a firearm.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Damaged or injured by fire or heat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of food) Carbonised.",
              synonyms: [],
              antonyms: [],
              example: "The toast was too burnt to eat.",
            },
            {
              definition: "(of a person) Having a sunburn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a colour) Being darker than standard, especially browner.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/burn",
        "https://en.wiktionary.org/wiki/burnt",
      ],
    },
  ],
  races: [
    {
      word: "races",
      phonetic: "/ˈɹeɪsɪz/",
      phonetics: [
        {
          text: "/ˈɹeɪsɪz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/races-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2101106",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A contest between people, animals, vehicles, etc. where the goal is to be the first to reach some objective. Example: Several horses run in a horse race, and the first one to reach the finishing post wins",
              synonyms: [],
              antonyms: [],
              example:
                "The race around the park was won by Johnny, who ran faster than the others.",
            },
            {
              definition:
                "Swift progress; rapid motion; an instance of moving or driving at high speed.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A race condition.", synonyms: [], antonyms: [] },
            {
              definition: "A progressive movement toward a goal.",
              synonyms: [],
              antonyms: [],
              example: "the race to cure cancer",
            },
            {
              definition:
                "A fast-moving current of water, such as that which powers a mill wheel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A water channel, esp. one built to lead water to or from a point where it is utilised.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Competitive action of any kind, especially when prolonged; hence, career; course of life.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The bushings of a rolling element bearing which contacts the rolling elements.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To take part in a race (in the sense of a contest).",
              synonyms: [],
              antonyms: [],
              example: "The drivers were racing around the track.",
            },
            {
              definition: "To compete against in such a race.",
              synonyms: [],
              antonyms: [],
              example:
                "I raced him to the car, but he was there first, so he got to ride shotgun.",
            },
            {
              definition: "To move or drive at high speed; to hurry or speed.",
              synonyms: [],
              antonyms: [],
              example:
                "As soon as it was time to go home, he raced for the door.",
            },
            {
              definition:
                "Of a motor, to run rapidly when not engaged to a transmission.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A group of sentient beings, particularly people, distinguished by common ancestry, heritage or characteristics:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A population geographically separated from others of its species that develops significantly different characteristics; an informal term for a subspecies.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A breed or strain of domesticated animal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A category or species of something that has emerged or evolved from an older one (with an implied parallel to animal breeding or evolutionary science).",
              synonyms: [],
              antonyms: [],
              example:
                "Recent developments in artificial intelligence has brought about a new race of robots that can perform household chores without supervision.",
            },
            {
              definition:
                "Peculiar flavour, taste, or strength, as of wine; that quality, or assemblage of qualities, which indicates origin or kind, as in wine; hence, characteristic flavour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Characteristic quality or disposition.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["breed", "subspecies", "variety"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To assign a race to; to perceive as having a (usually specified) race.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A rhizome or root, especially of ginger.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To demolish; to level to the ground.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To scrape as if with a razor.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/race",
        "https://en.wiktionary.org/wiki/races",
        "https://en.wiktionary.org/wiki/raze",
      ],
    },
  ],
  flash: [
    {
      word: "flash",
      phonetic: "/flæʃ/",
      phonetics: [
        {
          text: "/flæʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/flash-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75796841",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/flæʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/flash-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=558881",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A device that produces a short flash of light to help illuminate a scene, mostly for night-time or indoors photography.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A sudden, short, temporary burst of light.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A very short amount of time.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A flashlight; an electric torch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sudden and brilliant burst, as of genius or wit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Material left around the edge of a moulded part at the parting line of the mould.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Cockney) The strips of bright cloth or buttons worn around the collars of market traders.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A pattern where each prop is thrown and caught only once.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A language, created by a minority to maintain cultural identity, that cannot be understood by the ruling class.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A preparation of capsicum, burnt sugar, etc., for colouring liquor to make it look stronger.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A form of military insignia.",
              synonyms: [],
              antonyms: [],
              example: "I just got my first commando flash.",
            },
            {
              definition:
                "Any of various lycaenid butterflies of the genera Artipe, Deudorix and Rapala.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A tattoo flash.", synonyms: [], antonyms: [] },
            {
              definition:
                'The sudden sensation of being "high" after taking a recreational drug.',
              synonyms: [],
              antonyms: [],
            },
            { definition: "A newsflash.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["gleam", "glint", "molding flash", "moulding flash"],
          antonyms: ["aeon"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cause to shine briefly or intermittently.",
              synonyms: [],
              antonyms: [],
              example:
                "He flashed the light at the water, trying to see what made the noise.",
            },
            {
              definition: "To blink; to shine or illuminate intermittently.",
              synonyms: [],
              antonyms: [],
              example: "The light flashed on and off.",
            },
            {
              definition: "To be visible briefly.",
              synonyms: [],
              antonyms: [],
              example: "The scenery flashed by quickly.",
            },
            {
              definition: "To make visible briefly.",
              synonyms: [],
              antonyms: [],
              example: "A number will be flashed on the screen.",
            },
            {
              definition:
                "To briefly, and often unintentionally, expose one's naked body or underwear, or part of it, in public. (Contrast streak.)",
              synonyms: [],
              antonyms: [],
              example:
                "Her skirt was so short that she flashed her underpants as she was getting out of her car.",
            },
            {
              definition:
                'To show or expose an "inappropriate" part of the body to someone for humorous reasons or as an act of contempt.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To break forth like a sudden flood of light; to show a momentary brilliance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To flaunt; to display in a showy manner.",
              synonyms: [],
              antonyms: [],
              example: "He flashed a wad of hundred-dollar bills.",
            },
            {
              definition: "To communicate quickly.",
              synonyms: [],
              antonyms: [],
              example:
                "The news services flashed the news about the end of the war to all corners of the globe.",
            },
            {
              definition: "To move, or cause to move, suddenly.",
              synonyms: [],
              antonyms: [],
              example: "Flash forward to the present day.",
            },
            {
              definition:
                "To telephone a person, only allowing the phone to ring once, in order to request a call back.",
              synonyms: [],
              antonyms: [],
              example:
                "Susan flashed Jessica, and then Jessica called her back, because Susan didn't have enough credit on her phone to make the call.",
            },
            {
              definition:
                "(of liquid) To evaporate suddenly. (See flash evaporation.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To climb (a route) successfully on the first attempt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To write to the memory of (an updatable component such as a BIOS chip or games cartridge).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(glassmaking) To cover with a thin layer, as objects of glass with glass of a different colour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(glassmaking) To expand (blown glass) into a disc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To send by some startling or sudden means.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To burst out into violence.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To perform a flash.", synonyms: [], antonyms: [] },
            {
              definition: "To release the pressure from a pressurized vessel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To trick up in a showy manner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To strike and throw up large bodies of water from the surface; to splash.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["beep", "glint"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Expensive-looking and demanding attention; stylish; showy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of a person) Having plenty of ready money.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a person) Liable to show off expensive possessions or money.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Occurring very rapidly, almost instantaneously.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Relating to thieves and vagabonds.",
              synonyms: [],
              antonyms: [],
              example: "flash notes: counterfeit banknotes",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/camera%20flash",
        "https://en.wiktionary.org/wiki/flash",
      ],
    },
    {
      word: "flash",
      phonetic: "/flæʃ/",
      phonetics: [
        {
          text: "/flæʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/flash-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75796841",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/flæʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/flash-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=558881",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A pool.", synonyms: [], antonyms: [] },
            {
              definition:
                "A reservoir and sluiceway beside a navigable stream, just above a shoal, so that the stream may pour in water as boats pass, and thus bear them over the shoal.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/flash"],
    },
  ],
  weary: [
    {
      word: "weary",
      phonetic: "/ˈwiːɹi/",
      phonetics: [
        { text: "/ˈwiːɹi/", audio: "" },
        { text: "/ˈwɪəɹi/", audio: "" },
        {
          text: "/ˈwɪəɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/weary-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1676900",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make or to become weary.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Having the strength exhausted by toil or exertion; tired; fatigued.",
              synonyms: [],
              antonyms: [],
              example: "A weary traveller knocked at the door.",
            },
            {
              definition:
                "Having one's patience, relish, or contentment exhausted; tired; sick.",
              synonyms: [],
              antonyms: [],
              example:
                "soldiers weary of marching, or of confinement;  I grew weary of studying and left the library.",
            },
            {
              definition: "Expressive of fatigue.",
              synonyms: [],
              antonyms: [],
              example: "He gave me a weary smile.",
            },
            {
              definition: "Causing weariness; tiresome.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/weary"],
    },
  ],
  heels: [
    {
      word: "heels",
      phonetic: "/hiːlz/",
      phonetics: [
        {
          text: "/hiːlz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/heels-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100983",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The rear part of the foot, where it joins the leg.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The part of a shoe's sole which supports the foot's heel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The rear part of a sock or similar covering for the foot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The part of the palm of a hand closest to the wrist.",
              synonyms: [],
              antonyms: [],
              example: "He drove the heel of his hand into the man's nose.",
            },
            {
              definition: "(usually in the plural) A woman's high-heeled shoe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The back, upper part of the stock.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The last or lowest part of anything.",
              synonyms: [],
              antonyms: [],
              example: "the heel of a mast",
            },
            {
              definition: "A crust end-piece of a loaf of bread.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The base of a bun sliced in half lengthwise.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A contemptible, inconsiderate or thoughtless person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'A headlining wrestler regarded as a "bad guy," whose ring persona embodies villainous or reprehensible traits and demonstrates characteristics of a braggart and a bully.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The cards set aside for later use in a patience or solitaire game.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Anything resembling a human heel in shape; a protuberance; a knob.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The lower end of a timber in a frame, as a post or rafter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(specifically) The obtuse angle of the lower end of a rafter set sloping.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(workman slang) A cyma reversa.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The short side of an angled cut.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The part of a club head's face nearest the shaft.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The lower end of the bit (cutting edge) of an axehead; as opposed to the toe (upper end).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In a carding machine, the part of a flat nearest the cylinder.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["ender", "outsider"],
          antonyms: ["toe", "babyface"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The act of inclining or canting from a vertical position; a cant.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["heeling"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "High-heeled shoes.",
              synonyms: [],
              antonyms: [],
              example: "She wore a short skirt and heels.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/heel",
        "https://en.wiktionary.org/wiki/heels",
      ],
    },
  ],
  token: [
    {
      word: "token",
      phonetic: "/ˈtəʊkən/",
      phonetics: [
        { text: "/ˈtəʊkən/", audio: "" },
        {
          text: "/ˈtoʊkən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/token-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50423080",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Something serving as an expression of something else.",
              synonyms: ["sign", "symbol"],
              antonyms: [],
              example:
                "According to the Bible, the rainbow is a token of God's covenant with Noah.",
            },
            {
              definition: "A keepsake.",
              synonyms: ["memento", "souvenir"],
              antonyms: [],
              example:
                "Please accept this bustier as a token of our time together.",
            },
            {
              definition:
                "A piece of stamped metal or plastic, etc., used as a substitute for money; a voucher that can be exchanged for goods or services.",
              synonyms: [],
              antonyms: [],
              example:
                "A book token is the easiest option for a Christmas gift.",
            },
            {
              definition:
                "(sometimes figurative) Evidence, proof; a confirming detail; physical trace, mark, footprint.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Support for a belief; grounds for an opinion.",
              synonyms: ["reason", "reasoning"],
              antonyms: [],
            },
            {
              definition:
                "An extraordinary event serving as evidence of supernatural power.",
              synonyms: ["miracle"],
              antonyms: [],
            },
            {
              definition:
                "An object or disclosure to attest or authenticate the bearer or an instruction.",
              synonyms: ["password"],
              antonyms: [],
            },
            {
              definition: "A seal guaranteeing the quality of an item.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something given or shown as a symbol or guarantee of authority or right; a sign of authenticity, of power, good faith.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A tally.", synonyms: [], antonyms: [] },
            {
              definition: "A particular thing to which a concept applies.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An atomic piece of data, such as a word, for which a meaning may be inferred during parsing.",
              synonyms: ["symbol"],
              antonyms: [],
            },
            {
              definition:
                "A conceptual object that can be possessed by a computer, process, etc. in order to regulate a turn-taking system such as a token ring network.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A meaningless placeholder used as a substitute for sensitive data.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(grammar) A lexeme; a basic, grammatically indivisible unit of a language such as a keyword, operator or identifier.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(corpus linguistics) A single example of a certain word in a text or corpus.",
              synonyms: [],
              antonyms: ["type"],
            },
            {
              definition:
                "A characteristic sign of a disease or of a bodily disorder, a symptom; a sign of a bodily condition, recovery, or health.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A livid spot upon the body, indicating, or supposed to indicate, the approach of death.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Ten and a half quires, or, commonly, 250 sheets, of paper printed on both sides; also, in some cases, the same number of sheets printed on one side, or half the number printed on both sides.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bit of leather having a peculiar mark designating a particular miner. Each hewer sends one of these with each corf or tub he has hewn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A thin bed of coal indicating the existence of a thicker seam at no great distance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A physical object used for exchange between drivers and signalmen on single track lines.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In a loom, a colored signal to show the weaver which shuttle to use.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Church of Scotland) A piece of metal given beforehand to each person in the congregation who is permitted to partake of the Lord's Supper.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "memento",
            "souvenir",
            "miracle",
            "password",
            "reason",
            "reasoning",
            "sign",
            "symbol",
            "symbol",
          ],
          antonyms: ["type"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To betoken, indicate, portend, designate, denote",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To betroth", synonyms: [], antonyms: [] },
            {
              definition: "To symbolize, instantiate",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Done as an indication or a pledge; perfunctory, minimal or merely symbolic.",
              synonyms: [],
              antonyms: [],
              example:
                "He made a token tap on the brake pedal at the stop sign.",
            },
            {
              definition:
                "A minor attempt for appearance's sake, or to minimally comply with a requirement",
              synonyms: [],
              antonyms: [],
              example: "he was hired as the company's token black person",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/token"],
    },
  ],
  coats: [
    {
      word: "coats",
      phonetic: "/kəʊts/",
      phonetics: [
        { text: "/kəʊts/", audio: "" },
        {
          text: "/koʊts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/coats-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2650757",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An outer garment covering the upper torso and arms.Wp",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A covering of material, such as paint.Wp",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The fur or feathers covering an animal's skin.Wp",
              synonyms: [],
              antonyms: [],
              example:
                "When the dog shed its coat, it left hair all over the furniture and the carpet.",
            },
            {
              definition:
                "Canvas painted with thick tar and secured round a mast or bowsprit to prevent water running down the sides into the hold (now made of rubber or leather).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A petticoat.", synonyms: [], antonyms: [] },
            {
              definition:
                "The habit or vesture of an order of men, indicating the order or office; cloth.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A coat of arms.Wp", synonyms: [], antonyms: [] },
            { definition: "A coat card.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cover with a coating of some material.",
              synonyms: [],
              antonyms: [],
              example:
                "The frying pan was coated with a layer of non-stick material, making it easier to wash.",
            },
            { definition: "To cover like a coat.", synonyms: [], antonyms: [] },
            { definition: "To clothe.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/coat",
        "https://en.wiktionary.org/wiki/coats",
      ],
    },
  ],
  spare: [
    {
      word: "spare",
      phonetic: "/ˈspɛə(ɹ)/",
      phonetics: [
        { text: "/ˈspɛə(ɹ)/", audio: "" },
        {
          text: "/ˈspɛəɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spare-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1684743",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act of sparing; moderation; restraint.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Parsimony; frugal use.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An opening in a petticoat or gown; a placket.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "That which has not been used or expended.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A spare part, especially a spare tire.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'A superfluous or second-best person, specially (in a dynastic context) in the phrase "An heir and a spare".',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The right of bowling again at a full set of pins, after having knocked all the pins down in less than three bowls. If all the pins are knocked down in one bowl it is a double spare; in two bowls, a single spare.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The act of knocking down all remaining pins in second ball of a frame; this entitles the pins knocked down on the next ball to be added to the score for that frame.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A free period; a block of school during which one does not have a class.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Scant; not abundant or plentiful.",
              synonyms: [],
              antonyms: [],
              example: "a spare diet",
            },
            {
              definition:
                "Sparing; frugal; parsimonious; not spending much money.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Being more than what is necessary, or what must be used or reserved; not wanted, or not used; superfluous.",
              synonyms: [],
              antonyms: [],
              example: "I have no spare time.",
            },
            {
              definition: "Held in reserve, to be used in an emergency.",
              synonyms: [],
              antonyms: [],
              example: "a spare anchor; a spare bed or room",
            },
            {
              definition: "Lean; lacking flesh; meager; thin; gaunt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Very angry; frustrated or distraught.",
              synonyms: [],
              antonyms: [],
              example:
                "The poor girl is going spare, stuck in the house all day with the kids like that.",
            },
            { definition: "Slow.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spare"],
    },
    {
      word: "spare",
      phonetic: "/ˈspɛə(ɹ)/",
      phonetics: [
        { text: "/ˈspɛə(ɹ)/", audio: "" },
        {
          text: "/ˈspɛəɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spare-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1684743",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To show mercy.", synonyms: [], antonyms: [] },
            { definition: "To keep.", synonyms: [], antonyms: [] },
            {
              definition:
                "To give up To deprive oneself of, as by being frugal; to do without; to dispense with; to give up; to part with.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spare"],
    },
  ],
  shiny: [
    {
      word: "shiny",
      phonetic: "/ˈʃaɪni/",
      phonetics: [
        {
          text: "/ˈʃaɪni/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shiny-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=6541460",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Anything shiny; a trinket.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'Contraction of disparaging term "shiny arses", originating during World War Two, to describe a desk worker.https//books.google.co.uk/books?id=mAdUqLrKw4YC&pg=PA1717',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Reflecting light.", synonyms: [], antonyms: [] },
            { definition: "Emitting light.", synonyms: [], antonyms: [] },
            {
              definition: "Excellent; remarkable.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Bright; luminous; clear; unclouded.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shiny"],
    },
  ],
  alarm: [
    {
      word: "alarm",
      phonetic: "/əˈlɑːm/",
      phonetics: [
        { text: "/əˈlɑːm/", audio: "" },
        {
          text: "/əˈlɑɹm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/alarm-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749700",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A summons to arms, as on the approach of an enemy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any sound or information intended to give notice of approaching danger; a warning sound to arouse attention; a warning of danger.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sudden attack; disturbance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Sudden surprise with fear or terror excited by apprehension of danger; in the military use, commonly, sudden apprehension of being attacked by surprise.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A mechanical device for awaking people, or rousing their attention.",
              synonyms: [],
              antonyms: [],
              example:
                "The clock radio is a friendlier version of the cold alarm by the bedside.",
            },
            {
              definition:
                "An instance of an alarm ringing, beeping or clanging, to give a noise signal at a certain time.",
              synonyms: [],
              antonyms: [],
              example:
                "You should set the alarm on your watch to go off at seven o'clock.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To call to arms for defense",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To give (someone) notice of approaching danger",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To rouse to vigilance and action; to put on the alert.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To surprise with apprehension of danger; to fill with anxiety in regard to threatening evil; to excite with sudden fear.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To keep in excitement; to disturb.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/alarm"],
    },
  ],
  dimes: [
    {
      word: "dimes",
      phonetic: "/daɪmz/",
      phonetics: [{ text: "/daɪmz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A coin worth one-tenth of a U.S. dollar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A coin worth one-tenth of a Canadian dollar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A small amount of money",
              synonyms: [],
              antonyms: [],
              example: "She didn't spend a dime.",
            },
            { definition: "An assist", synonyms: [], antonyms: [] },
            {
              definition: "A playing card with the rank of ten",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Ten dollars", synonyms: [], antonyms: [] },
            { definition: "A thousand dollars", synonyms: [], antonyms: [] },
            {
              definition:
                "A measurement of illicit drugs (usually marijuana) sold in ten dollar bags.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Payment responsibility",
              synonyms: [],
              antonyms: [],
              example: "Are you traveling on the company's dime?",
            },
            {
              definition: "A beautiful woman (10 on a 10-point scale)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A defensive formation with six defensive backs, one of whom is a dimeback.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["ten cent piece", "grand"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dime",
        "https://en.wiktionary.org/wiki/dimes",
      ],
    },
  ],
  sixth: [
    {
      word: "sixth",
      phonetic: "/sɪk(s)θ/",
      phonetics: [
        {
          text: "/sɪk(s)θ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sixth-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88557346",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/sɪk(s)θ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sixth-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=192007",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(not used in the plural) The person or thing in the sixth position.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One of six equal parts of a whole.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The interval between one note and another, five notes higher in the scale, for example C to A, a major sixth, or C to A flat, a minor sixth. (Note that the interval covers six notes counting inclusively, for example C-D-E-F-G-A.)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["⅙"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To divide by six, which also means multiplying a denominator by six",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "The ordinal form of the number six.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["6th", "VI", "VIth"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sixth"],
    },
  ],
  clerk: [
    {
      word: "clerk",
      phonetic: "/klɐːk/",
      phonetics: [
        { text: "/klɐːk/", audio: "" },
        { text: "/klɑːk/", audio: "" },
        {
          text: "/klɝk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/clerk-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100347",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One who occupationally works with records, accounts, letters, etc.; an office worker.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A facilitator of a Quaker meeting for business affairs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In the Church of England, the layman that assists in the church service, especially in reading the responses (also called parish clerk).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'A cleric or clergyman (the legal title for clergy of the Church of England is "Clerk in Holy Orders", still used in legal documents and cherished by some of their number).',
              synonyms: [],
              antonyms: [],
            },
            { definition: "A scholar.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To act as a clerk, to perform the duties or functions of a clerk",
              synonyms: [],
              antonyms: [],
              example:
                "The law school graduate clerked for the supreme court judge for the summer.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/clerk"],
    },
  ],
  mercy: [
    {
      word: "mercy",
      phonetic: "/ˈmɜːsi/",
      phonetics: [
        { text: "/ˈmɜːsi/", audio: "" },
        {
          text: "/ˈmɝsi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mercy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=192266",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Relenting; forbearance to cause or allow harm to another.",
              synonyms: [],
              antonyms: ["cruelty", "mercilessness", "ruthlessness"],
              example: "She took mercy on him and quit embarrassing him.",
            },
            {
              definition:
                "Forgiveness or compassion, especially toward those less fortunate.",
              synonyms: [],
              antonyms: [],
              example: "Have mercy on the poor and assist them if you can.",
            },
            {
              definition: "A tendency toward forgiveness, pity, or compassion.",
              synonyms: [],
              antonyms: [],
              example: "Mercy is one of his many virtues.",
            },
            {
              definition: "Instances of forbearance or forgiveness.",
              synonyms: [],
              antonyms: [],
              example:
                "Psalms 40:11 Do not withhold Your tender mercies from me, O Lord",
            },
            {
              definition: "A blessing; something to be thankful for.",
              synonyms: [],
              antonyms: [],
              example:
                "It was a mercy that we were not inside when the roof collapsed",
            },
          ],
          synonyms: [],
          antonyms: ["cruelty", "mercilessness", "ruthlessness"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To feel mercy", synonyms: [], antonyms: [] },
            {
              definition:
                "To show mercy; to pardon or treat leniently because of mercy",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "Expressing surprise or alarm.",
              synonyms: [],
              antonyms: [],
              example: "Mercy! Look at the state of you!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mercy"],
    },
  ],
  sunny: [
    {
      word: "sunny",
      phonetic: "/ˈsʌni/",
      phonetics: [
        {
          text: "/ˈsʌni/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sunny-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780418",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A sunfish.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "(of weather or a day) Featuring a lot of sunshine.",
              synonyms: [],
              antonyms: [],
              example:
                "Whilst it may be sunny today, the weather forecast is predicting rain.",
            },
            {
              definition: "(of a place) Receiving a lot of sunshine.",
              synonyms: [],
              antonyms: [],
              example:
                "I would describe Spain as sunny, but it's nothing in comparison to the Sahara.",
            },
            {
              definition: "(of a person or a person's mood) cheerful",
              synonyms: [],
              antonyms: [],
              example: "a sunny disposition",
            },
            {
              definition:
                "Of or relating to the sun; proceeding from, or resembling the sun; shiny; radiant.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bright", "cheerful", "sunlit", "bright", "sunshiny"],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            { definition: "Sunny side up", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sunny"],
    },
  ],
  guest: [
    {
      word: "guest",
      phonetic: "/ɡɛst/",
      phonetics: [
        {
          text: "/ɡɛst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/guest-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749859",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A recipient of hospitality, specifically someone staying by invitation at the house of another.",
              synonyms: [],
              antonyms: [],
              example: "The guests were let in by the butler.",
            },
            {
              definition: "A patron or customer in a hotel etc.",
              synonyms: [],
              antonyms: [],
              example:
                "Guests must vacate their rooms by 10 o'clock on their day of departure.",
            },
            {
              definition:
                "An invited visitor or performer to an institution or to a broadcast.",
              synonyms: [],
              antonyms: [],
              example: "The guest for the broadcast was a leading footballer.",
            },
            {
              definition:
                "A user given temporary access to a system despite not having an account of their own.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any insect that lives in the nest of another without compulsion and usually not as a parasite.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An inquiline.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To appear as a guest, especially on a broadcast",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "As a musician, to play as a guest, providing an instrument that a band/orchestra does not normally have in its line up (for instance, percussion in a string band)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To receive or entertain hospitably.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/guest"],
    },
  ],
  float: [
    {
      word: "float",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/float-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75269513",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/fləʊt/", audio: "" },
        {
          text: "/floʊt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/float-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762935",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A buoyant device used to support something in water or another liquid.",
              synonyms: [],
              antonyms: [],
              example:
                "Attach the float and the weight to the fishing line, above the hook.",
            },
            {
              definition:
                "A mass of timber or boards fastened together, and conveyed down a stream by the current; a raft.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A float board.", synonyms: [], antonyms: [] },
            {
              definition: "A tool similar to a rasp, used in various trades.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sort of trowel used for finishing concrete surfaces or smoothing plaster.",
              synonyms: [],
              antonyms: [],
              example:
                "When pouring a new driveway, you can use a two-by-four as a float.",
            },
            {
              definition:
                "An elaborately decorated trailer or vehicle, intended for display in a parade or pageant.",
              synonyms: [],
              antonyms: [],
              example: "That float covered in roses is very pretty.",
            },
            {
              definition:
                "A small vehicle used for local deliveries, especially in the term milk float.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Funds committed to be paid but not yet paid.",
              synonyms: [],
              antonyms: [],
              example:
                "Our bank does a nightly sweep of accounts, to adjust the float so we stay within our reserves limit.",
            },
            {
              definition:
                "(and other Commonwealth countries?) An offering of shares in a company (or units in a trust) to members of the public, normally followed by a listing on a stock exchange.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The total amount of checks/cheques or other drafts written against a bank account but not yet cleared and charged against the account.",
              synonyms: [],
              antonyms: [],
              example:
                "No sir, your current float is not taken into account, when assets are legally garnished.",
            },
            {
              definition: "Premiums taken in but not yet paid out.",
              synonyms: [],
              antonyms: [],
              example: "We make a lot of interest from our nightly float.",
            },
            {
              definition:
                "A floating-point number, especially one that has lower precision than a double.",
              synonyms: [],
              antonyms: [],
              example:
                "That routine should not have used an int; it should be a float.",
            },
            {
              definition:
                "A soft beverage with a scoop of ice-cream floating in it.",
              synonyms: [],
              antonyms: [],
              example:
                "It's true - I don't consider anything other than root-beer with vanilla ice-cream to be a \"real\" float.",
            },
            {
              definition:
                "A small sum of money put in a cashier's till at the start of business to enable change to be made.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A maneuver where a player calls on the flop or turn with a weak hand, with the intention of bluffing after a subsequent community card.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the loose ends of yarn on an unfinished work.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A car carrier or car transporter truck or truck-and-trailer combination",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A lowboy trailer", synonyms: [], antonyms: [] },
            {
              definition:
                "(tempering) A device sending a copious stream of water to the heated surface of a bulky object, such as an anvil or die.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The act of flowing; flux; flow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A quantity of earth, eighteen feet square and one foot deep.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A polishing block used in marble working; a runner.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A coal cart.", synonyms: [], antonyms: [] },
            {
              definition:
                "A breakdancing move in which the body is held parallel to the floor while balancing on one or both hands.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A visual style on a web page that causes the styled elements to float above or beside others.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["initial public offering"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "Of an object or substance, to be supported by a liquid of greater density than the object so as that part of the object or substance remains above the surface.",
              synonyms: [],
              antonyms: [],
              example: "The boat floated on the water.",
            },
            {
              definition:
                "To cause something to be suspended in a liquid of greater density.",
              synonyms: [],
              antonyms: [],
              example: "to float a boat",
            },
            {
              definition: "To be capable of floating.",
              synonyms: [],
              antonyms: [],
              example: "Oil floats on vinegar.",
            },
            {
              definition:
                "To move in a particular direction with the liquid in which one is floating",
              synonyms: [],
              antonyms: [],
              example: "I’d love to just float downstream.",
            },
            {
              definition: "To drift or wander aimlessly.",
              synonyms: [],
              antonyms: [],
              example: "Images from my childhood floated through my mind.",
            },
            {
              definition: "To drift gently through the air.",
              synonyms: [],
              antonyms: [],
              example: "The balloon floated off into the distance.",
            },
            {
              definition: "To move in a fluid manner.",
              synonyms: [],
              antonyms: [],
              example: "The dancer floated gracefully around the stage.",
            },
            {
              definition: "To circulate.",
              synonyms: [],
              antonyms: [],
              example:
                "There's a rumour floating around the office that Jan is pregnant.",
            },
            {
              definition: "(of an idea or scheme) To be viable.",
              synonyms: [],
              antonyms: [],
              example: "That’s a daft idea... it’ll never float.",
            },
            {
              definition: "To propose (an idea) for consideration.",
              synonyms: [],
              antonyms: [],
              example:
                "I floated the idea of free ice-cream on Fridays, but no one was interested.",
            },
            {
              definition:
                "To automatically adjust a parameter as related parameters change.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of currencies) To have an exchange value determined by the markets as opposed to by rule.",
              synonyms: [],
              antonyms: [],
              example: "The yen floats against the dollar.",
            },
            {
              definition:
                "To allow (the exchange value of a currency) to be determined by the markets.",
              synonyms: [],
              antonyms: [],
              example:
                "Increased pressure on Thailand’s currency, the baht, in 1997 led to a crisis that forced the government to float the currency.",
            },
            {
              definition: "To extend a short-term loan to.",
              synonyms: [],
              antonyms: [],
              example: "Could you float me $50 until payday?",
            },
            {
              definition:
                "To issue or sell shares in a company (or units in a trust) to members of the public, followed by listing on a stock exchange.",
              synonyms: [],
              antonyms: [],
              example:
                "2007, Jonathan Reuvid, Floating Your Company: The Essential Guide to Going Public.",
            },
            {
              definition:
                "To spread plaster over (a surface), using the tool called a float.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To use a float (rasp-like tool) upon.",
              synonyms: [],
              antonyms: [],
              example: "It is time to float this horse's teeth.",
            },
            {
              definition: "To transport by float (vehicular trailer).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To perform a float.", synonyms: [], antonyms: [] },
            {
              definition:
                "To cause (an element within a document) to float above or beside others.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/float"],
    },
  ],
  shone: [
    {
      word: "shone",
      phonetic: "/ʃɒn/",
      phonetics: [
        {
          text: "/ʃɒn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shone-au-ca-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=94251794",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ʃɒn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shone-au-ca-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=94251794",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ʃɒn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shone-au-ca-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=94251794",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ʃoʊn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shone-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749985",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To emit light.", synonyms: [], antonyms: [] },
            { definition: "To reflect light.", synonyms: [], antonyms: [] },
            {
              definition: "To distinguish oneself; to excel.",
              synonyms: [],
              antonyms: [],
              example:
                "My nephew tried other sports before deciding on football, which he shone at right away, quickly becoming the star of his school team.",
            },
            {
              definition: "To be effulgent in splendour or beauty.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be eminent, conspicuous, or distinguished; to exhibit brilliant intellectual powers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be immediately apparent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To create light with (a flashlight, lamp, torch, or similar).",
              synonyms: [],
              antonyms: [],
              example:
                "I shone my light into the darkness to see what was making the noise.",
            },
            {
              definition: "To cause to shine, as a light.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make bright; to cause to shine by reflected light.",
              synonyms: [],
              antonyms: [],
              example:
                "in hunting, to shine the eyes of a deer at night by throwing a light on them",
            },
          ],
          synonyms: [
            "excel",
            "beam",
            "glow",
            "radiate",
            "buff",
            "burnish",
            "furbish",
            "polish",
            "wax",
            "gleam",
            "glint",
            "glisten",
            "glitter",
            "reflect",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/shine",
        "https://en.wiktionary.org/wiki/shone",
      ],
    },
  ],
  pipes: [
    {
      word: "pipes",
      phonetic: "/paɪps/",
      phonetics: [
        {
          text: "/paɪps/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pipes-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651302",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Meanings relating to a wind instrument.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Meanings relating to a hollow conduit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Meanings relating to a container.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Meanings relating to something resembling a tube.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Meanings relating to computing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Meanings relating to a smoking implement.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["pan", "bar", "vertical bar", "vertical line", "virgule"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To play (music) on a pipe instrument, such as a bagpipe or a flute.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To shout loudly and at high pitch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To emit or have a shrill sound like that of a pipe; to whistle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of a metal ingot: to become hollow in the process of solidifying.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To convey or transport (something) by means of pipes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To install or configure with pipes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To dab moisture away from.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To lead or conduct as if by pipes, especially by wired transmission.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To directly feed (the output of one program) as input to another program, indicated by the pipe character (pipe) at the command line.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To create or decorate with piping (icing).",
              synonyms: [],
              antonyms: [],
              example: "to pipe flowers on to a cupcake",
            },
            {
              definition:
                "To order or signal by a note pattern on a boatswain's pipe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a male) To have sexual intercourse with a female.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To see.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A single pipe organ.", synonyms: [], antonyms: [] },
            { definition: "One's vocal capacity.", synonyms: [], antonyms: [] },
            { definition: "Biceps.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pipe",
        "https://en.wiktionary.org/wiki/pipes",
      ],
    },
  ],
  worms: [
    {
      word: "worms",
      phonetic: "/wɜːmz/",
      phonetics: [
        { text: "/wɜːmz/", audio: "" },
        {
          text: "/wɝmz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/worms-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453807",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A generally tubular invertebrate of the annelid phylum; an earthworm.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "More loosely, any of various tubular invertebrates resembling annelids but not closely related to them, such as velvet worms, acorn worms, flatworms, or roundworms.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'A type of wingless "dragon", especially a gigantic sea serpent.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'Either a mythical "dragon" (especially wingless), a gigantic sea serpent, or a creature that resembles a Mongolian death worm.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A contemptible or devious being.",
              synonyms: [],
              antonyms: [],
              example: "Don't try to run away, you little worm!",
            },
            {
              definition:
                "A self-replicating program that propagates through a network.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A graphical representation of the total runs scored in an innings.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Anything helical, especially the thread of a screw.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any creeping or crawling animal, such as a snake, snail, or caterpillar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An internal tormentor; something that gnaws or afflicts one’s mind with remorse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A strip of linked tiles sharing parallel edges in a tiling.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The lytta.", synonyms: [], antonyms: [] },
            {
              definition:
                "(preceded by definite article) A dance, or dance move, in which the dancer lies on the floor and undulates the body horizontally thereby moving forwards.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make (one's way) with a crawling motion.",
              synonyms: [],
              antonyms: [],
              example: "We wormed our way through the underbrush.",
            },
            {
              definition: "To move with one's body dragging the ground.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To work one's way by artful or devious means.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To work (one's way or oneself) (into) gradually or slowly; to insinuate.",
              synonyms: [],
              antonyms: [],
              example: "He wormed his way into the organization",
            },
            {
              definition:
                "To effect, remove, drive, draw, or the like, by slow and secret means; often followed by out.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in “worm out of”) To drag out of, to get information that someone is reluctant or unwilling to give (through artful or devious means or by pleading or asking repeatedly).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To fill in the contlines of (a rope) before parcelling and serving.",
              synonyms: [],
              antonyms: [],
              example:
                "Worm and parcel with the lay; turn and serve the other way.",
            },
            {
              definition: "To deworm (an animal).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cut the worm, or lytta, from under the tongue of (a dog, etc.) for the purpose of checking a disposition to gnaw, and formerly supposed to guard against canine madness.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To clean by means of a worm; to draw a wad or cartridge from, as a firearm.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/worm",
        "https://en.wiktionary.org/wiki/worms",
      ],
    },
  ],
  bills: [
    {
      word: "bills",
      phonetic: "/bɪlz/",
      phonetics: [
        {
          text: "/bɪlz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bills-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2707457",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of various bladed or pointed hand weapons, originally designating an Anglo-Saxon sword, and later a weapon of infantry, especially in the 14th and 15th centuries, commonly consisting of a broad, heavy, double-edged, hook-shaped blade, with a short pike at the back and another at the top, attached to the end of a long staff.",
              synonyms: ["polearm"],
              antonyms: [],
            },
            {
              definition:
                "A cutting instrument, with hook-shaped point, and fitted with a handle, used in pruning, etc.; a billhook.",
              synonyms: ["billhook", "hand bill", "hedge bill"],
              antonyms: [],
            },
            {
              definition: "Somebody armed with a bill; a billman.",
              synonyms: ["billman"],
              antonyms: [],
            },
            {
              definition: "A pickaxe, or mattock.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The extremity of the arm of an anchor; the point of or beyond the fluke (also called the peak).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "billhook",
            "hand bill",
            "hedge bill",
            "billman",
            "polearm",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To dig, chop, etc., with a bill.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The beak of a bird, especially when small or flattish; sometimes also used with reference to a platypus, turtle, or other animal.",
              synonyms: ["beak", "neb", "nib", "pecker"],
              antonyms: [],
            },
            {
              definition: "A beak-like projection, especially a promontory.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of a cap or hat: the brim or peak, serving as a shade to keep sun off the face and out of the eyes.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["beak", "neb", "nib", "pecker"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To peck", synonyms: [], antonyms: [] },
            {
              definition:
                "To stroke bill against bill, with reference to doves; to caress in fondness",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A written list or inventory. (Now obsolete except in specific senses or set phrases; bill of lading, bill of goods, etc.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A document, originally sealed; a formal statement or official memorandum. (Now obsolete except with certain qualifying words; bill of health, bill of sale etc.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A draft of a law, presented to a legislature for enactment; a proposed or projected law.",
              synonyms: ["measure"],
              antonyms: [],
            },
            {
              definition:
                "A declaration made in writing, stating some wrong the complainant has suffered from the defendant, or a fault committed by some person against a law.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A piece of paper money; a banknote.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A written note of goods sold, services rendered, or work done, with the price or charge; an invoice.",
              synonyms: ["account", "invoice"],
              antonyms: [],
            },
            {
              definition:
                "A paper, written or printed, and posted up or given away, to advertise something, as a lecture, a play, or the sale of goods",
              synonyms: [
                "notice",
                "placard",
                "broadsheet",
                "broadside",
                "card",
                "circular",
                "flier",
                "flyer",
                "handbill",
                "poster",
                "posting",
                "throwaway",
              ],
              antonyms: [],
            },
            {
              definition:
                "A writing binding the signer or signers to pay a certain sum at a future day or on demand, with or without interest, as may be stated in the document; a bill of exchange. In the United States, it is usually called a note, a note of hand, or a promissory note.",
              synonyms: [
                "Federal Reserve note",
                "bank bill",
                "bank note",
                "banker's bill",
                "banknote",
                "government note",
                "greenback",
                "note",
              ],
              antonyms: [],
            },
            {
              definition: "A set of items presented together.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "notice",
            "placard",
            "broadsheet",
            "broadside",
            "card",
            "circular",
            "flier",
            "flyer",
            "handbill",
            "poster",
            "posting",
            "throwaway",
            "Federal Reserve note",
            "bank bill",
            "bank note",
            "banker's bill",
            "banknote",
            "government note",
            "greenback",
            "note",
            "account",
            "invoice",
            "measure",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To advertise by a bill or public notice.",
              synonyms: ["placard"],
              antonyms: [],
            },
            {
              definition: "To charge; to send a bill to.",
              synonyms: ["charge"],
              antonyms: [],
            },
          ],
          synonyms: ["charge", "placard"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The bell, or boom, of the bittern.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bill",
        "https://en.wiktionary.org/wiki/bills",
      ],
    },
  ],
  sweat: [
    {
      word: "sweat",
      phonetic: "/swɛt/",
      phonetics: [
        {
          text: "/swɛt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sweat-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=856239",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Fluid that exits the body through pores in the skin usually due to physical stress and/or high temperature for the purpose of regulating body temperature and removing certain compounds from the circulation.",
              synonyms: ["perspiration"],
              antonyms: [],
            },
            {
              definition: "The state of one who sweats; diaphoresis.",
              synonyms: [],
              antonyms: [],
              example:
                "Just thinking about the interview tomorrow puts me into a nervous sweat.",
            },
            {
              definition:
                "(especially WWI) A soldier (especially one who is old or experienced).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The sweating sickness.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Moisture issuing from any substance.",
              synonyms: [],
              antonyms: [],
              example: "the sweat of hay or grain in a mow or stack",
            },
            {
              definition: "A short run by a racehorse as a form of exercise.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Hard work; toil.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["perspiration", "sudor"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sweat"],
    },
    {
      word: "sweat",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To emit sweat.",
              synonyms: ["perspire"],
              antonyms: [],
            },
            {
              definition: "To cause to excrete moisture through skin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To work hard.",
              synonyms: ["slave", "slog"],
              antonyms: [],
              example: "I've been sweating over my essay all day.",
            },
            {
              definition:
                "To extract money, labour, etc. from, by exaction or oppression.",
              synonyms: [],
              antonyms: [],
              example: "to sweat a spendthrift",
            },
            {
              definition: "To worry.",
              synonyms: ["fret", "worry"],
              antonyms: [],
            },
            {
              definition: "To worry about (something).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To emit, in the manner of sweat.",
              synonyms: [],
              antonyms: [],
              example: "to sweat blood",
            },
            {
              definition: "To emit moisture.",
              synonyms: [],
              antonyms: [],
              example:
                "The cheese will start sweating if you don't refrigerate it.",
            },
            {
              definition: "To solder (a pipe joint) together.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To stress out.",
              synonyms: [],
              antonyms: [],
              example: "Stop sweatin'  me!",
            },
            {
              definition:
                "To cook slowly at low heat, in shallow oil and without browning, to reduce moisture content.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To remove a portion of (a coin), as by shaking it with others in a bag, so that the friction wears off a small quantity of the metal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To suffer a penalty; to smart for one's misdeeds.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To scrape the sweat from (a horse).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["fret", "worry", "perspire", "slave", "slog"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sweat"],
    },
  ],
  suits: [
    {
      word: "suits",
      phonetic: "/sjuːts/",
      phonetics: [
        {
          text: "/sjuːts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/suits-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651578",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A set of clothes to be worn together, now especially a man's matching jacket and trousers (also business suit or lounge suit), or a similar outfit for a woman.",
              synonyms: [],
              antonyms: [],
              example: "Nick hired a navy-blue suit for the wedding.",
            },
            {
              definition:
                "(by extension) A single garment that covers the whole body: space suit, boiler suit, protective suit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(metonym) A person who wears matching jacket and trousers, especially a boss or a supervisor.",
              synonyms: [],
              antonyms: [],
              example:
                'Be sure to keep your nose to the grindstone today; the suits are making a "surprise" visit to this department.',
            },
            { definition: "A full set of armour.", synonyms: [], antonyms: [] },
            {
              definition:
                "The attempt to gain an end by legal process; a process instituted in a court of law for the recovery of a right or claim; a lawsuit.",
              synonyms: [],
              antonyms: [],
              example:
                "If you take my advice, you'll file a suit against him immediately.",
            },
            {
              definition:
                "Obsolete The act of following or pursuing; pursuit, chase.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Pursuit of a love-interest; wooing, courtship.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The act of suing; the pursuit of a particular object or goal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The full set of sails required for a ship.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Each of the sets of a pack of cards distinguished by color and/or specific emblems, such as the spades, hearts, diamonds or clubs of traditional Anglo, Hispanic and French playing cards.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Regular order; succession.",
              synonyms: [],
              antonyms: [],
              example:
                "Every five and thirty years the same kind and suit of weather comes again.",
            },
            {
              definition: "A company of attendants or followers; a retinue.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A group of similar or related objects or items considered as a whole; a suite (of rooms etc.)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make proper or suitable; to adapt or fit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(said of clothes, hairstyle or other fashion item) To be suitable or apt for one's image.",
              synonyms: [],
              antonyms: [],
              example: "That new top suits you. Where did you buy it?",
            },
            {
              definition: "To be appropriate or apt for.",
              synonyms: [],
              antonyms: [],
              example: "Ill suits his cloth the praise of railing well.",
            },
            {
              definition:
                "(most commonly used in the passive form) To dress; to clothe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To please; to make content; to fit one's taste.",
              synonyms: [],
              antonyms: [],
              example: "He is well suited with his place.",
            },
            {
              definition:
                "To agree; to be fitted; to correspond (usually followed by to, archaically also followed by with)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["agree", "answer", "match"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/suit",
        "https://en.wiktionary.org/wiki/suits",
      ],
    },
  ],
  smart: [
    {
      word: "smart",
      phonetic: "/smɑːt/",
      phonetics: [
        { text: "/smɑːt/", audio: "" },
        {
          text: "/smɑɹt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/smart-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1234276",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To hurt or sting.",
              synonyms: [],
              antonyms: [],
              example:
                'After being hit with a pitch, the batter exclaimed "Ouch, my arm smarts!"',
            },
            {
              definition: "To cause a smart or sting in.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To feel a pungent pain of mind; to feel sharp pain or grief; be punished severely; to feel the sting of evil.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/smart"],
    },
    {
      word: "smart",
      phonetic: "/smɑːt/",
      phonetics: [
        { text: "/smɑːt/", audio: "" },
        {
          text: "/smɑɹt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/smart-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1234276",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Exhibiting social ability or cleverness.",
              synonyms: ["bright", "capable", "sophisticated", "witty"],
              antonyms: ["backward", "banal", "boorish", "dull", "inept"],
            },
            {
              definition:
                "Exhibiting intellectual knowledge, such as that found in books.",
              synonyms: ["cultivated", "educated", "learned"],
              antonyms: ["ignorant", "simple", "uncultivated"],
            },
            {
              definition:
                "(often in combination) Equipped with intelligent behaviour (digital/computer technology).",
              synonyms: [],
              antonyms: [],
              example: "smart bomb",
            },
            {
              definition: "Good-looking; well dressed; fine; fashionable.",
              synonyms: ["attractive", "chic", "dapper", "handsome", "stylish"],
              antonyms: ["garish", "outré", "tacky"],
              example: "You look smart in that business suit.",
            },
            {
              definition:
                "Cleverly shrewd and humorous in a way that may be rude and disrespectful.",
              synonyms: ["silly"],
              antonyms: [],
              example: "Don't get smart with me!",
            },
            { definition: "Sudden and intense.", synonyms: [], antonyms: [] },
            {
              definition: "Causing sharp pain; stinging.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Sharp; keen; poignant.",
              synonyms: [],
              antonyms: [],
              example: "a smart pain",
            },
            {
              definition:
                "(Southern) Intense in feeling; painful. Used usually with the adverb intensifier right.",
              synonyms: [],
              antonyms: [],
              example:
                "He raised his voice, and it hurt her feelings right smart.",
            },
            {
              definition: "Efficient; vigorous; brilliant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Pretentious; showy; spruce.",
              synonyms: [],
              antonyms: [],
              example: "a smart gown",
            },
            {
              definition: "Brisk; fresh.",
              synonyms: [],
              antonyms: [],
              example: "a smart breeze",
            },
          ],
          synonyms: [
            "attractive",
            "chic",
            "dapper",
            "handsome",
            "stylish",
            "bright",
            "capable",
            "sophisticated",
            "witty",
            "cultivated",
            "educated",
            "learned",
            "silly",
          ],
          antonyms: [
            "backward",
            "banal",
            "boorish",
            "dull",
            "inept",
            "garish",
            "outré",
            "tacky",
            "ignorant",
            "simple",
            "uncultivated",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/smart"],
    },
    {
      word: "smart",
      phonetic: "/smɑːt/",
      phonetics: [
        { text: "/smɑːt/", audio: "" },
        {
          text: "/smɑɹt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/smart-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1234276",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A sharp, quick, lively pain; a sting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Mental pain or suffering; grief; affliction.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Smart-money.", synonyms: [], antonyms: [] },
            {
              definition:
                "A dandy; one who is smart in dress; one who is brisk, vivacious, or clever.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/smart"],
    },
  ],
  upset: [
    {
      word: "upset",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Disturbance or disruption.",
              synonyms: [],
              antonyms: [],
              example:
                "My late arrival caused the professor considerable upset.",
            },
            {
              definition:
                "An unexpected victory of a competitor or candidate that was not favored to win.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(automobile insurance) An overturn.",
              synonyms: [],
              antonyms: [],
              example:
                '"collision and upset": impact with another object or an overturn for whatever reason.',
            },
            { definition: "An upset stomach.", synonyms: [], antonyms: [] },
            {
              definition:
                "An upper set; a subset (X,≤) of a partially ordered set with the property that, if x is in U and x≤y, then y is in U.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The dangerous situation where the flight attitude or airspeed of an aircraft is outside the designed bounds of operation, possibly resulting in loss of control.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["disruption", "disturbance"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make (a person) angry, distressed, or unhappy.",
              synonyms: [],
              antonyms: [],
              example:
                "I’m sure the bad news will upset him, but he needs to know.",
            },
            {
              definition: "To disturb, disrupt or adversely alter (something).",
              synonyms: [],
              antonyms: [],
              example:
                "Introducing a foreign species can upset the ecological balance.",
            },
            {
              definition: "To tip or overturn (something).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To defeat unexpectedly.",
              synonyms: [],
              antonyms: [],
              example:
                "Truman upset Dewey in the 1948 US presidential election.",
            },
            {
              definition: "To be upset or knocked over.",
              synonyms: [],
              antonyms: [],
              example: "The carriage upset when the horse bolted.",
            },
            {
              definition: "To set up; to put upright.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To thicken and shorten, as a heated piece of iron, by hammering on the end.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To shorten (a tire) in the process of resetting, originally by cutting it and hammering on the ends.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "disrupt",
            "disturb",
            "forset",
            "turn upside down",
            "forset",
            "invert",
            "overturn",
            "tip",
            "anger",
            "distress",
            "forset",
            "sadden",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "(of a person) Angry, distressed or unhappy.",
              synonyms: [],
              antonyms: [],
              example: "He was upset when she refused his friendship.",
            },
            {
              definition:
                "(of a stomach or gastrointestinal tract, referred to as stomach) Feeling unwell, nauseated, or ready to vomit.",
              synonyms: [],
              antonyms: [],
              example: "His stomach was upset, so he didn't want to move.",
            },
          ],
          synonyms: ["angry", "distressed", "unhappy"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/upset"],
    },
  ],
  rains: [
    {
      word: "rains",
      phonetic: "/ɹeɪnz/",
      phonetics: [
        {
          text: "/ɹeɪnz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rains-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651367",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Condensed water falling from a cloud.",
              synonyms: [],
              antonyms: [],
              example: "The rains came late that year.",
            },
            {
              definition:
                "Any matter moving or falling, usually through air, and especially if liquid or otherwise figuratively identifiable with raindrops.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An instance of particles or larger pieces of matter moving or falling through air.",
              synonyms: [],
              antonyms: [],
              example: "A rain of mortar fire fell on our trenches.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To have rain fall from the sky.",
              synonyms: [],
              antonyms: [],
              example: "It will rain today.",
            },
            {
              definition: "To fall as or like rain.",
              synonyms: [],
              antonyms: [],
              example: "Bombs rained from the sky.",
            },
            {
              definition: "To issue (something) in large quantities.",
              synonyms: [],
              antonyms: [],
              example: "The boxer rained punches on his opponent's head.",
            },
            { definition: "To reign.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A rainy season.",
              synonyms: [],
              antonyms: [],
              example: "The rains came early this year.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rain",
        "https://en.wiktionary.org/wiki/rains",
      ],
    },
  ],
  sandy: [
    {
      word: "sandy",
      phonetic: "/ˈsændi/",
      phonetics: [
        {
          text: "/ˈsændi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sandy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2101131",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Covered with sand.", synonyms: [], antonyms: [] },
            { definition: "Sprinkled with sand.", synonyms: [], antonyms: [] },
            {
              definition: "Containing sand.",
              synonyms: [],
              antonyms: [],
              example: "Some plants grow best in sandy soil.",
            },
            {
              definition: "Like sand, especially in texture.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Having the colour of sand.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sandy"],
    },
  ],
  rainy: [
    {
      word: "rainy",
      phonetic: "/ˈɹeɪni/",
      phonetics: [{ text: "/ˈɹeɪni/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Pouring with rain; wet; showery",
              synonyms: [],
              antonyms: [],
              example:
                "Due to the rainy weather, we decided not to play in the park.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rainy"],
    },
  ],
  parks: [
    {
      word: "parks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An area of land set aside for environment preservation or recreation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A wide, flat-bottomed valley in a mountainous region.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An area used for specific purposes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An inventory of matériel.",
              synonyms: [],
              antonyms: [],
              example: "A country's tank park or artillery park.",
            },
            {
              definition: "A space in which to leave a car; a parking space.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["courtyard", "garden", "plaza"],
          antonyms: ["building", "skyscraper", "street"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To bring (something such as a vehicle) to a halt or store in a specified place.",
              synonyms: [],
              antonyms: [],
              example:
                "I parked the drive heads of my hard disk before travelling with my laptop.",
            },
            {
              definition: "To defer (a matter) until a later date.",
              synonyms: [],
              antonyms: [],
              example: "Let's park that until next week's meeting.",
            },
            {
              definition: "To bring together in a park, or compact body.",
              synonyms: [],
              antonyms: [],
              example: "to park artillery, wagons, automobiles, etc.",
            },
            {
              definition: "To enclose in a park, or as in a park.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To hit a home run, to hit the ball out of the park.",
              synonyms: [],
              antonyms: [],
              example: "He really parked that one.",
            },
            {
              definition:
                "To engage in romantic or sexual activities inside a nonmoving vehicle.",
              synonyms: [],
              antonyms: [],
              example:
                "They stopped at a romantic overlook, shut off the engine, and parked.",
            },
            {
              definition:
                "(sometimes reflexive) To sit, recline, or put, especially in a manner suggesting an intent to remain for some time.",
              synonyms: [],
              antonyms: [],
              example: "He came in and parked himself in our living room.",
            },
            {
              definition:
                "To invest money temporarily in an investment instrument considered to relatively free of risk, especially while awaiting other opportunities.",
              synonyms: [],
              antonyms: [],
              example:
                "We decided to park our money in a safe, stable, low-yield bond fund until market conditions improve.",
            },
            {
              definition:
                "To register a domain name, but make no use of it (See domain parking)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(oyster culture) To enclose in a park, or partially enclosed basin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To promenade or drive in a park.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of horses) To display style or gait on a park drive.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/park",
        "https://en.wiktionary.org/wiki/parks",
      ],
    },
  ],
  sadly: [
    {
      word: "sadly",
      phonetic: "/ˈsadli/",
      phonetics: [
        { text: "/ˈsadli/", audio: "" },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sadly-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2101126",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "In a sad manner; sorrowfully.",
              synonyms: [],
              antonyms: [],
              example: "“Only when we climb up can we see it,” he said sadly.",
            },
            {
              definition: "Unfortunately, sad to say.",
              synonyms: [],
              antonyms: [],
              example:
                "If you think you’re getting out of this place alive, you are sadly mistaken, my friend.",
            },
            {
              definition: "Very much (of a desire etc.); dearly; urgently.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Deeply, completely.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "mournfully",
            "regretfully",
            "ruefully",
            "sorrowfully",
            "alas",
            "regretfully",
            "regrettably",
            "sad to say",
            "unfortunately",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sadly"],
    },
  ],
  fancy: [
    {
      word: "fancy",
      phonetic: "/ˈfæn.si/",
      phonetics: [
        {
          text: "/ˈfæn.si/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fancy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1247125",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The imagination.", synonyms: [], antonyms: [] },
            {
              definition:
                "An image or representation of anything formed in the mind.",
              synonyms: ["conception", "idea", "thought"],
              antonyms: [],
            },
            {
              definition:
                "An opinion or notion formed without much reflection.",
              synonyms: ["impression"],
              antonyms: [],
            },
            {
              definition: "A whim.",
              synonyms: [],
              antonyms: [],
              example: "I had a fancy to learn to play the flute.",
            },
            {
              definition: "Love or amorous attachment.",
              synonyms: [],
              antonyms: [],
              example: "He took a fancy to her.",
            },
            {
              definition: "The object of inclination or liking.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any sport or hobby pursued by a group.",
              synonyms: ["hobby"],
              antonyms: [],
              example: "Trainspotting is the fancy of a special lot.",
            },
            {
              definition: "The enthusiasts of such a pursuit.",
              synonyms: [],
              antonyms: [],
              example:
                "He fell out of favor with the boxing fancy after the incident.",
            },
            {
              definition: "A diamond with a distinctive colour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That which pleases or entertains the taste or caprice without much use or value.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sort of love song or light impromptu ballad.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In the game of jacks, a style of play involving additional actions (contrasted with plainsies).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["conception", "idea", "thought", "hobby", "impression"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Decorative.",
              synonyms: ["decorative", "ornate"],
              antonyms: ["plain", "simple"],
              example: "This is a fancy shawl.",
            },
            {
              definition: "Of a superior grade.",
              synonyms: ["high-end"],
              antonyms: [],
              example: "This box contains bottles of the fancy grade of jelly.",
            },
            {
              definition: "Executed with skill.",
              synonyms: [],
              antonyms: [],
              example:
                "He initiated the game winning play with a fancy, deked saucer pass to the winger.",
            },
            {
              definition: "Unnecessarily complicated.",
              synonyms: ["highfalutin"],
              antonyms: ["simple"],
              example: "I'm not keen on him and his fancy ideas.",
            },
            {
              definition: "Extravagant; above real value.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["decorative", "ornate", "high-end", "highfalutin"],
          antonyms: ["plain", "simple", "simple"],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "In a fancy manner; fancily.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fancy"],
    },
    {
      word: "fancy",
      phonetic: "/ˈfæn.si/",
      phonetics: [
        {
          text: "/ˈfæn.si/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fancy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1247125",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To appreciate without jealousy or greed.",
              synonyms: [],
              antonyms: [],
              example: "I fancy your new car, but I like my old one just fine.",
            },
            {
              definition: "Would like",
              synonyms: ["feel like"],
              antonyms: [],
              example: "Do you fancy going to town this weekend?",
            },
            {
              definition: "To be sexually attracted to.",
              synonyms: ["like"],
              antonyms: [],
              example: "I fancy that girl over there.",
            },
            {
              definition: "To imagine, suppose.",
              synonyms: [],
              antonyms: [],
              example: "Fancy meeting you here!",
            },
            {
              definition: "To form a conception of; to portray in the mind.",
              synonyms: ["imagine"],
              antonyms: [],
            },
            {
              definition:
                "To have a fancy for; to like; to be pleased with, particularly on account of external appearance or manners.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To breed (animals) as a hobby.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["feel like", "imagine", "like"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fancy"],
    },
  ],
  rider: [
    {
      word: "rider",
      phonetic: "/ˈɹaɪ.də(ɹ)/",
      phonetics: [
        { text: "/ˈɹaɪ.də(ɹ)/", audio: "" },
        {
          text: "/ˈɹaɪ.dəɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rider-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651435",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One who rides, often on a horse or a motorcycle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A provision annexed to a bill under the consideration of a legislature, having little connection with the subject matter of the bill.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) Something extra or burdensome that is imposed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An amendment or addition to an entertainer's performance contract, often covering a performer's equipment or food, drinks, and general comfort requirements.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An additional benefit attached to an insurance contract.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small, sliding piece of aluminium on a chemical balance, used to determine small weights.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An agent who goes out with samples of goods to obtain orders; a commercial traveller.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One who breaks in or manages a horse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The first Lenormand card, also known as either the horseman or the cavalier.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A problem of extra difficulty added to another on an examination paper.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An old Dutch gold coin with the figure of a man on horseback stamped upon it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Rock material in a vein of ore, dividing it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An interior rib occasionally fixed in a ship's hold, reaching from the keelson to the beams of the lower deck, to strengthen the frame.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The second tier of casks in a vessel's hold.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small forked weight which straddles the beam of a balance, along which it can be moved in the manner of the weight on a steelyard.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A robber.", synonyms: [], antonyms: [] },
            {
              definition:
                "A piece, such as the rook or bishop, which moves any distance in one direction, as long as no other piece is in the way.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rider"],
    },
  ],
  unity: [
    {
      word: "unity",
      phonetic: "/ˈjuːnɪtɪ/",
      phonetics: [
        { text: "/ˈjuːnɪtɪ/", audio: "" },
        {
          text: "/ˈjunɪti/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/unity-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2101163",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Oneness; the state or fact of being one undivided entity.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Agreement; harmony.", synonyms: [], antonyms: [] },
            {
              definition:
                "A single undivided thing, seen as complete in itself.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of the three classical rules of drama: unity of action (nothing should be admitted not directly relevant to the development of the plot), unity of place (the scenes should be set in the same place), and unity of time (all the events should be such as might happen within a single day).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The number 1 or any element of a set or field that behaves under a given operation as the number 1 behaves under multiplication.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The peculiar characteristics of an estate held by several in joint tenancy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The form of consensus in a Quaker meeting for business which signals that a decision has been reached. In order to achieve unity, everyone who does not agree with the decision must explicitly stand aside, possibly being recorded in the minutes as doing so.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["disunity", "multiplicity", "plurality"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/unity"],
    },
  ],
  bunch: [
    {
      word: "bunch",
      phonetic: "/ˈbʌntʃ/",
      phonetics: [
        {
          text: "/ˈbʌntʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bunch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1639875",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A group of similar things, either growing together, or in a cluster or clump, usually fastened together.",
              synonyms: [],
              antonyms: [],
              example:
                "a bunch of grapes;  a bunch of bananas;  a bunch of keys;  a bunch of yobs on a street corner",
            },
            {
              definition:
                "The peloton; the main group of riders formed during a race.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An informal body of friends.",
              synonyms: [],
              antonyms: [],
              example: "He still hangs out with the same bunch.",
            },
            {
              definition: "A considerable amount.",
              synonyms: [],
              antonyms: [],
              example: "a bunch of trouble",
            },
            {
              definition: "An unmentioned amount; a number.",
              synonyms: [],
              antonyms: [],
              example: "A bunch of them went down to the field.",
            },
            {
              definition: "A group of logs tied together for skidding.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An unusual concentration of ore in a lode or a small, discontinuous occurrence or patch of ore in the wallrock.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The reserve yarn on the filling bobbin to allow continuous weaving between the time of indication from the midget feeler until a new bobbin is put in the shuttle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An unfinished cigar, before the wrapper leaf is added.",
              synonyms: [],
              antonyms: [],
              example:
                "Two to four filler leaves are laid end to end and rolled into the two halves of the binder leaves, making up what is called the bunch.",
            },
            {
              definition: "A protuberance; a hunch; a knob or lump; a hump.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "cluster",
            "group",
            "circle",
            "gang",
            "group",
            "pack",
            "bunch of ore",
            "kidney",
            "nest",
            "nest of ore",
            "ore bunch",
            "ore pocket",
            "pocket",
            "pocket of ore",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To gather into a bunch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To gather fabric into folds.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To form a bunch.", synonyms: [], antonyms: [] },
            {
              definition: "To be gathered together in folds",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To protrude or swell", synonyms: [], antonyms: [] },
          ],
          synonyms: ["cluster", "group"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bunch"],
    },
  ],
  rolls: [
    {
      word: "rolls",
      phonetic: "/ɹəʊlz/",
      phonetics: [
        { text: "/ɹəʊlz/", audio: "" },
        { text: "/ɹoʊlz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The act or result of rolling, or state of being rolled.",
              synonyms: [],
              antonyms: [],
              example: "Look at the roll of the waves.",
            },
            {
              definition:
                "A forward or backward roll in gymnastics; going head over heels. A tumble.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Something which rolls.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A swagger or rolling gait.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A heavy, reverberatory sound.",
              synonyms: [],
              antonyms: [],
              example: "Hear the roll of cannon.",
            },
            {
              definition:
                "The uniform beating of a drum with strokes so rapid as scarcely to be distinguished by the ear.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The oscillating movement of a nautical vessel as it rotates from side to side, on its fore-and-aft axis, causing its sides to go up and down, as distinguished from the alternate rise and fall of bow and stern called pitching; or the equivalent in an aircraft.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The measure or extent to which a vessel rotates from side to side, on its fore-and-aft axis.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The rotation angle about the longitudinal axis.",
              synonyms: [],
              antonyms: [],
              example: "Calculate the roll of that aircraft.",
            },
            {
              definition:
                "The act of, or total resulting from, rolling one or more dice.",
              synonyms: [],
              antonyms: [],
              example: "Make your roll.",
            },
            {
              definition:
                "A winning streak of continuing luck, especially at gambling (and especially in the phrase on a roll).",
              synonyms: [],
              antonyms: [],
              example: "He is on a roll tonight.",
            },
            {
              definition: "A training match for a fighting dog.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(paddlesport) An instance of the act of righting a canoe or kayak which has capsized, without exiting the watercraft, or being assisted.",
              synonyms: [],
              antonyms: [],
              example: "That was a good roll.",
            },
            {
              definition:
                "(paddlesport) The skill of righting a canoe or kayak which has capsized.",
              synonyms: [],
              antonyms: [],
              example: "She has a bombproof roll.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cause to revolve by turning over and over; to move by turning on an axis; to impel forward by causing to turn over and over on a supporting surface.",
              synonyms: [],
              antonyms: [],
              example: "To roll a wheel, a ball, or a barrel.",
            },
            {
              definition: "To turn over and over.",
              synonyms: [],
              antonyms: [],
              example: "The child will roll on the floor.",
            },
            {
              definition: "To tumble in gymnastics; to do a somersault.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To wrap (something) round on itself; to form into a spherical or cylindrical body by causing to turn over and over.",
              synonyms: [],
              antonyms: [],
              example:
                "To roll a sheet of paper; to roll clay or putty into a ball.",
            },
            {
              definition:
                "To bind or involve by winding, as in a bandage; to enwrap; often with up.",
              synonyms: [],
              antonyms: [],
              example: "To roll up the map for shipping.",
            },
            {
              definition: "To be wound or formed into a cylinder or ball.",
              synonyms: [],
              antonyms: [],
              example: "The cloth rolls unevenly; the snow rolls well.",
            },
            {
              definition:
                "To drive or impel forward with an easy motion, as of rolling.",
              synonyms: [],
              antonyms: [],
              example: "This river will roll its waters to the ocean.",
            },
            {
              definition:
                "To utter copiously, especially with sounding words; to utter with a deep sound; — often with forth, or out.",
              synonyms: [],
              antonyms: [],
              example:
                "To roll forth someone's praises; to roll out sentences.",
            },
            {
              definition:
                "To press or level with a roller; to spread or form with a roll, roller, or rollers.",
              synonyms: [],
              antonyms: [],
              example: "to roll a field;  to roll paste;  to roll steel rails.",
            },
            {
              definition: "To spread itself under a roller or rolling-pin.",
              synonyms: [],
              antonyms: [],
              example: "The pastry rolls well.",
            },
            {
              definition:
                "To move, or cause to be moved, upon, or by means of, rollers or small wheels.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To leave or begin a journey.",
              synonyms: [],
              antonyms: [],
              example: "I want to get there early; let's roll.",
            },
            {
              definition: "To compete, especially with vigor.",
              synonyms: [],
              antonyms: [],
              example: "OK guys, we're only down by two points. Let's roll!",
            },
            {
              definition:
                "To beat with rapid, continuous strokes, as a drum; to sound a roll upon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To apply (one line or surface) to another without slipping; to bring all the parts of (one line or surface) into successive contact with another, in such a manner that at every instant the parts that have been in contact are equal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To turn over in one's mind; to revolve.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To behave in a certain way; to adopt a general disposition toward a situation.",
              synonyms: [],
              antonyms: [],
              example:
                "I was going to kick his ass, but he wasn't worth getting all worked up over; I don't roll like that.",
            },
            { definition: "To throw dice.", synonyms: [], antonyms: [] },
            {
              definition:
                "To roll dice such that they form a given pattern or total.",
              synonyms: [],
              antonyms: [],
              example: "If you roll doubles, you get an extra turn.",
            },
            {
              definition:
                "To create a new character in a role-playing game, especially by using dice to determine properties.",
              synonyms: [],
              antonyms: [],
              example: "I'm gonna go and roll a new shaman tonight.",
            },
            {
              definition: "To generate a random number.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a vessel) To rotate on its fore-and-aft axis, causing its sides to go up and down. Compare with pitch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(in folk songs) To travel by sailing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To beat up; to attack and cause physical damage to.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause to betray secrets or to testify for the prosecution.",
              synonyms: [],
              antonyms: [],
              example:
                "The feds rolled him by giving him a free pass for most of what he'd done.",
            },
            {
              definition: "To betray secrets.",
              synonyms: [],
              antonyms: [],
              example: "He rolled on those guys after being in jail two days.",
            },
            {
              definition:
                "To be under the influence of MDMA (a psychedelic stimulant, also known as ecstasy).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of a camera) To (cause to) film.",
              synonyms: [],
              antonyms: [],
              example: "It's time to roll the cameras.",
            },
            {
              definition: "To slip past (a defender) with the ball.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To have a rolling aspect.",
              synonyms: [],
              antonyms: [],
              example: "the hills rolled on",
            },
            {
              definition:
                "To perform a periodical revolution; to move onward as with a revolution.",
              synonyms: [],
              antonyms: [],
              example: "The years roll on.",
            },
            {
              definition:
                "To move, like waves or billows, with alternate swell and depression.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To move and cause an effect on someone",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make a loud or heavy rumbling noise.",
              synonyms: [],
              antonyms: [],
              example: "The thunder rolled and the lightning flashed.",
            },
            {
              definition: "To utter with an alveolar trill.",
              synonyms: [],
              antonyms: [],
              example: "Many languages roll their r's.",
            },
            {
              definition:
                "To enrobe in toilet-paper (as a prank or spectacle).",
              synonyms: [],
              antonyms: [],
              example: "The kids rolled the principal's house and yard.",
            },
            {
              definition: "To create a customized version of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To engage in sparring in the context of jujitsu or other grappling disciplines.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "That which is rolled up.",
              synonyms: [],
              antonyms: [],
              example: "a roll of fat, of wool, paper, cloth, etc.",
            },
            {
              definition:
                "A document written on a piece of parchment, paper, or other materials which may be rolled up; a scroll.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An official or public document; a register; a record",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A catalogue or list", synonyms: [], antonyms: [] },
            {
              definition: "A quantity of cloth wound into a cylindrical form.",
              synonyms: [],
              antonyms: [],
              example: "a roll of carpeting; a roll of ribbon",
            },
            {
              definition: "A cylindrical twist of tobacco.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A kind of shortened raised biscuit or bread, often rolled or doubled upon itself; see also bread roll.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Part; office; duty; rôle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A measure of parchments, containing five dozen.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(paddlesport) An instance of the act of righting a canoe or kayak which has capsized, without exiting the watercraft, or being assisted.",
              synonyms: [],
              antonyms: [],
              example: "That was a good roll.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/roll",
        "https://en.wiktionary.org/wiki/rolls",
      ],
    },
  ],
  crash: [
    {
      word: "crash",
      phonetic: "/kɹæʃ/",
      phonetics: [
        {
          text: "/kɹæʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/crash-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2707485",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sudden, intense, loud sound, as made for example by cymbals.",
              synonyms: [],
              antonyms: [],
              example: "After the lightning came the crash of thunder.",
            },
            {
              definition: "An automobile, airplane, or other vehicle accident.",
              synonyms: [],
              antonyms: [],
              example: "Nobody survived the plane crash",
            },
            {
              definition:
                "A malfunction of computer software or hardware which causes it to shut down or become partially or totally inoperable.",
              synonyms: ["abend"],
              antonyms: [],
              example: "My computer had a crash so I had to reboot it.",
            },
            {
              definition:
                "A sudden large decline of business or the prices of stocks (especially one that causes additional failures).",
              synonyms: [],
              antonyms: [],
              example: "the stock market crash",
            },
            {
              definition: "A comedown from a drug.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(collective) A group of rhinoceroses.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["abend"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To collide with something destructively, fall or come down violently.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To severely damage or destroy something by causing it to collide with something else.",
              synonyms: [],
              antonyms: [],
              example:
                "I'm sorry for crashing the bike into a wall. I'll pay for repairs.",
            },
            {
              definition:
                "(via gatecrash) To attend a social event without invitation, usually with unfavorable intentions.",
              synonyms: [],
              antonyms: [],
              example:
                "We weren't invited to the party so we decided to crash it.",
            },
            {
              definition:
                "To accelerate a project or a task or its schedule by devoting more resources to it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make or experience informal temporary living arrangements, especially overnight.",
              synonyms: [],
              antonyms: [],
              example: "Hey dude, can I crash at your pad?",
            },
            { definition: "To give, as a favor.", synonyms: [], antonyms: [] },
            {
              definition:
                "To lie down for a long rest, sleep or nap, as from tiredness or exhaustion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(hardware) To terminate extraordinarily.",
              synonyms: [],
              antonyms: [],
              example:
                "If the system crashes again, we'll have it fixed in the computer shop.",
            },
            {
              definition: "(hardware) To cause to terminate extraordinarily.",
              synonyms: [],
              antonyms: [],
              example: "Double-clicking this icon crashes the desktop.",
            },
            {
              definition:
                "To experience a period of depression and/or lethargy after a period of euphoria, as after the euphoric effect of a psychotropic drug has dissipated.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To hit or strike with force",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a patient's condition) To take a sudden and severe turn for the worse; to rapidly deteriorate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make a sudden loud noise.",
              synonyms: [],
              antonyms: [],
              example: "Thunder crashed directly overhead.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Quick, fast, intensive, impromptu.",
              synonyms: [],
              antonyms: [],
              example: "crash diet",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/crash"],
    },
    {
      word: "crash",
      phonetic: "/kɹæʃ/",
      phonetics: [
        {
          text: "/kɹæʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/crash-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2707485",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(fibre) A type of rough linen.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/crash"],
    },
  ],
  craft: [
    {
      word: "craft",
      phonetic: "/kɹɑːft/",
      phonetics: [
        { text: "/kɹɑːft/", audio: "" },
        {
          text: "/kɹæft/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/craft-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100673",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Strength; power; might; force .",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Intellectual power; skill; art.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(obsolete in the general sense) A work or product of art .",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A device, a means; a magical device, spell or enchantment .",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Learning of the schools, scholarship; a branch of learning or knowledge, a science, especially one of the ‘seven liberal arts’ of the medieval universities .",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Skill, skilfulness, art, especially the skill needed for a particular profession .",
              synonyms: ["craftsmanship", "workmanship"],
              antonyms: [],
              example: "The craft of writing plays.",
            },
            {
              definition:
                "(plural crafts) A branch of skilled work or trade, especially one requiring manual dexterity or artistic skill, but sometimes applied equally to any business, calling or profession; the skilled practice of a practical occupation .",
              synonyms: [
                "art",
                "business",
                "handicraft",
                "profession",
                "trade",
              ],
              antonyms: [],
              example: "He learned his craft as an apprentice.",
            },
            {
              definition:
                "A trade or profession as embodied in its practitioners collectively; the members of a trade or handicraft as a body; an association of these; a trade's union, guild, or ‘company’ .",
              synonyms: [],
              antonyms: [],
              example: "She represented the craft of brewers.",
            },
            {
              definition:
                "(plural craft) A vehicle designed for navigation in or on water or air or through outer space .",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Implements used in catching fish, such as net, line, or hook. Modern use primarily in whaling, as in harpoons, hand-lances, etc. .",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "art",
            "business",
            "handicraft",
            "profession",
            "trade",
            "craftsmanship",
            "workmanship",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make by hand and with much skill.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To construct, develop something (like a skilled craftsman).",
              synonyms: [],
              antonyms: [],
              example:
                "state crafting; the process of crafting global policing",
            },
            {
              definition:
                "To combine multiple items to form a new item, such as armour or medicine.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/craft"],
    },
  ],
  newly: [
    {
      word: "newly",
      phonetic: "/ˈnjuːli/",
      phonetics: [
        { text: "/ˈnjuːli/", audio: "" },
        { text: "/ˈnuli/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Very recently; in the immediate past.",
              synonyms: [],
              antonyms: [],
              example: "She smelled the newly budding flowers.",
            },
          ],
          synonyms: ["freshly", "recently"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/newly"],
    },
  ],
  gates: [
    {
      word: "gates",
      phonetic: "/ɡeɪts/",
      phonetics: [
        {
          text: "/ɡeɪts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gates-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749853",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A doorlike structure outside a house.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Doorway, opening, or passage in a fence or wall.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Movable barrier.",
              synonyms: [],
              antonyms: [],
              example:
                "The gate in front of the railroad crossing went up after the train had passed.",
            },
            {
              definition:
                "A logical pathway made up of switches which turn on or off. Examples are and, or, nand, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The gap between a batsman's bat and pad.",
              synonyms: [],
              antonyms: [],
              example:
                "Singh was bowled through the gate, a very disappointing way for a world-class batsman to get out.",
            },
            {
              definition:
                "The amount of money made by selling tickets to a concert or a sports event.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(flow cytometry) A line that separates particle type-clusters on two-dimensional dot plots.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Passageway (as in an air terminal) where passengers can embark or disembark.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The controlling terminal of a field effect transistor (FET).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In a lock tumbler, the opening for the stump of the bolt to pass through or into.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The channel or opening through which metal is poured into the mould; the ingate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The waste piece of metal cast in the opening; a sprue or sullage piece. Also written geat and git.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A mechanism, in a film camera and projector, that holds each frame momentarily stationary behind the aperture.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A tally mark consisting of four vertical bars crossed by a diagonal, representing a count of five.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["logic gate", "doorway", "entrance", "passage"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To keep something inside by means of a closed gate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To punish, especially a child or teenager, by not allowing them to go out.",
              synonyms: ["ground"],
              antonyms: [],
            },
            {
              definition: "To open a closed ion channel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To furnish with a gate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To turn (an image intensifier) on and off selectively as needed, or to avoid damage. See autogating.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["ground"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A way, path.", synonyms: [], antonyms: [] },
            { definition: "A journey.", synonyms: [], antonyms: [] },
            {
              definition:
                'A street; now used especially as a combining form to make the name of a street e.g. "Briggate" (a common street name in the north of England meaning "Bridge Street") or Kirkgate meaning "Church Street".',
              synonyms: [],
              antonyms: [],
            },
            { definition: "Manner; gait.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gate",
        "https://en.wiktionary.org/wiki/gates",
      ],
    },
  ],
  hatch: [
    {
      word: "hatch",
      phonetic: "/hætʃ/",
      phonetics: [
        {
          text: "/hætʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hatch-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79218717",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A horizontal door in a floor or ceiling.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A trapdoor.", synonyms: [], antonyms: [] },
            {
              definition:
                "An opening in a wall at window height for the purpose of serving food or other items. A pass through.",
              synonyms: [],
              antonyms: [],
              example: "The cook passed the dishes through the serving hatch.",
            },
            {
              definition:
                "A small door in large mechanical structures and vehicles such as aircraft and spacecraft often provided for access for maintenance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An opening through the deck of a ship or submarine",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A gullet.", synonyms: [], antonyms: [] },
            {
              definition: "A frame or weir in a river, for catching fish.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A floodgate; a sluice gate.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A bedstead.", synonyms: [], antonyms: [] },
            {
              definition: "An opening into, or in search of, a mine.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To close with a hatch or hatches.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hatch"],
    },
    {
      word: "hatch",
      phonetic: "/hætʃ/",
      phonetics: [
        {
          text: "/hætʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hatch-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79218717",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The act of hatching.", synonyms: [], antonyms: [] },
            {
              definition: "Development; disclosure; discovery.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(poultry) A group of birds that emerged from eggs at a specified time.",
              synonyms: [],
              antonyms: [],
              example: "These pullets are from an April hatch.",
            },
            {
              definition:
                "(often as mayfly hatch) The phenomenon, lasting 1–2 days, of large clouds of mayflies appearing in one location to mate, having reached maturity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'A birth, the birth records (in the newspaper) — compare the phrase "hatched, matched, and dispatched."',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(of young animals) To emerge from an egg.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of eggs) To break open when a young animal emerges from it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To incubate eggs; to cause to hatch.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To devise.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hatch"],
    },
    {
      word: "hatch",
      phonetic: "/hætʃ/",
      phonetics: [
        {
          text: "/hætʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hatch-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79218717",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To shade an area of (a drawing, diagram, etc.) with fine parallel lines, or with lines which cross each other (cross-hatch).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cross; to spot; to stain; to steep.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hatch"],
    },
  ],
  paths: [
    {
      word: "paths",
      phonetic: "/pɑːðz/",
      phonetics: [
        { text: "/pɑːðz/", audio: "" },
        { text: "/pæðz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A trail for the use of, or worn by, pedestrians.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A course taken.",
              synonyms: [],
              antonyms: [],
              example: "the path of a meteor, of a caravan, or of a storm",
            },
            {
              definition:
                "A Pagan tradition, for example witchcraft, Wicca, druidism, Heathenry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A metaphorical course.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A method or direction of proceeding.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A human-readable specification for a location within a hierarchical or tree-like structure, such as a file system or as part of a URL.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sequence of vertices from one vertex to another using the arcs (edges). A path does not visit the same vertex more than once (unless it is a closed path, where only the first and the last vertex are the same).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A continuous map f from the unit interval I = [0,1] to a topological space X.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A slot available for allocation to a railway train over a given route in between other trains.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["track", "trail"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make a path in, or on (something), or for (someone).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/path",
        "https://en.wiktionary.org/wiki/paths",
      ],
    },
  ],
  funds: [
    {
      word: "funds",
      phonetic: "/fʌndz/",
      phonetics: [
        { text: "/fʌndz/", audio: "" },
        { text: "/fʌndz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A sum or source of money.",
              synonyms: [],
              antonyms: [],
              example: "a fund for the maintenance of underprivileged students",
            },
            {
              definition: "An organization managing such money.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A money-management operation, such as a mutual fund.",
              synonyms: [],
              antonyms: [],
              example: "Several major funds were declared insolvent recently.",
            },
            {
              definition: "A large supply of something to be drawn upon.",
              synonyms: [],
              antonyms: [],
              example: "He drew on his immense fund of knowledge.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To pay for.",
              synonyms: [],
              antonyms: [],
              example:
                "He used his inheritance to fund his gambling addiction.",
            },
            {
              definition: "To place (money) in a fund.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To form a debt into a stock charged with interest.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fund",
        "https://en.wiktionary.org/wiki/funds",
      ],
    },
  ],
  wider: [
    {
      word: "wider",
      phonetic: "/ˈwaɪdə/",
      phonetics: [
        { text: "/ˈwaɪdə/", audio: "" },
        { text: "/ˈwaɪdɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having a large physical extent from side to side.",
              synonyms: [],
              antonyms: [],
              example: "We walked down a wide corridor.",
            },
            {
              definition: "Large in scope.",
              synonyms: [],
              antonyms: [],
              example: "The inquiry had a wide remit.",
            },
            {
              definition: "Operating at the side of the playing area.",
              synonyms: [],
              antonyms: [],
              example: "That team needs a decent wide player.",
            },
            {
              definition:
                "On one side or the other of the mark; too far sideways from the mark, the wicket, the batsman, etc.",
              synonyms: [],
              antonyms: [],
              example: "Too bad! That was a great passing-shot, but it's wide.",
            },
            {
              definition:
                "Made, as a vowel, with a less tense, and more open and relaxed, condition of the organs in the mouth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(now rare) Vast, great in extent, extensive.",
              synonyms: [],
              antonyms: [],
              example: "The wide, lifeless expanse.",
            },
            {
              definition: "Located some distance away; distant, far.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Far from truth, propriety, necessity, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of or supporting a greater range of text characters than can fit into the traditional 8-bit representation.",
              synonyms: [],
              antonyms: [],
              example: "a wide character; a wide stream",
            },
          ],
          synonyms: [],
          antonyms: ["narrow", "skinny", "thin"],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Extensively",
              synonyms: [],
              antonyms: [],
              example: "He travelled far and wide.",
            },
            {
              definition: "Completely",
              synonyms: [],
              antonyms: [],
              example: "He was wide awake.",
            },
            {
              definition: "Away from a given goal",
              synonyms: [],
              antonyms: [],
              example: "A few shots were fired but they all went wide.",
            },
            {
              definition:
                "So as to leave or have a great space between the sides; so as to form a large opening.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wide",
        "https://en.wiktionary.org/wiki/wider",
      ],
    },
  ],
  grace: [
    {
      word: "grace",
      phonetic: "/ɡɹeɪs/",
      phonetics: [
        {
          text: "/ɡɹeɪs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grace-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1339548",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
        {
          text: "/ɡɹeɪs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grace-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1230572",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Charming, pleasing qualities.",
              synonyms: [],
              antonyms: [],
              example:
                "The Princess brought grace to an otherwise dull and boring party.",
            },
            {
              definition: "A short prayer of thanks before or after a meal.",
              synonyms: [],
              antonyms: [],
              example:
                "It has become less common to say grace before having dinner.",
            },
            {
              definition:
                "In the games of patience or solitaire: a special move that is normally against the rules.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A grace note.", synonyms: [], antonyms: [] },
            {
              definition: "Elegant movement; balance or poise.",
              synonyms: [],
              antonyms: [],
              example: "The dancer moved with grace and strength.",
            },
            {
              definition:
                "An allowance of time granted to a debtor during which he or she is free of at least part of his normal obligations towards the creditor.",
              synonyms: [],
              antonyms: [],
              example:
                "The repayment of the loan starts after a three-year grace.",
            },
            {
              definition:
                "Free and undeserved favour, especially of God; unmerited divine assistance given to humans for their regeneration or sanctification, or for resisting sin.",
              synonyms: [],
              antonyms: [],
              example:
                "I’m so grateful to God for the grace that He has given me.",
            },
            {
              definition:
                "An act or decree of the governing body of an English university.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To adorn; to decorate; to embellish and dignify.",
              synonyms: [],
              antonyms: [],
              example: "He graced the room by simply being there.",
            },
            {
              definition: "To dignify or raise by an act of favour; to honour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To supply with heavenly grace.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To add grace notes, cadenzas, etc., to.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["mense"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/grace"],
    },
  ],
  grave: [
    {
      word: "grave",
      phonetic: "/ɡɹeɪv/",
      phonetics: [
        {
          text: "/ɡɹeɪv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grave-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1243828",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An excavation in the earth as a place of burial",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any place of interment; a tomb; a sepulcher.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) Death, destruction.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/grave"],
    },
    {
      word: "grave",
      phonetic: "/ɡɹeɪv/",
      phonetics: [
        {
          text: "/ɡɹeɪv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grave-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1243828",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To dig.", synonyms: [], antonyms: [] },
            {
              definition:
                "To carve or cut, as letters or figures, on some hard substance; to engrave.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To carve out or give shape to, by cutting with a chisel; to sculpture.",
              synonyms: [],
              antonyms: [],
              example: "to grave an image",
            },
            {
              definition: "To impress deeply (on the mind); to fix indelibly.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To entomb; to bury.", synonyms: [], antonyms: [] },
            {
              definition:
                "To write or delineate on hard substances, by means of incised lines; to practice engraving.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/grave"],
    },
    {
      word: "grave",
      phonetic: "/ɡɹeɪv/",
      phonetics: [
        {
          text: "/ɡɹeɪv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grave-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1243828",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A written accent used in French, Italian, and other languages. è is an e with a grave accent (`).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Characterised by a dignified sense of seriousness; not cheerful.",
              synonyms: [
                "sedate",
                "serious",
                "sober",
                "solemn",
                "sombre",
                "staid",
              ],
              antonyms: [],
            },
            {
              definition: "Low in pitch, tone etc.",
              synonyms: [],
              antonyms: ["acute"],
            },
            {
              definition:
                "Serious, in a negative sense; important, formidable.",
              synonyms: ["important", "momentous", "serious"],
              antonyms: [],
            },
            {
              definition: "Influential, important; authoritative.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "demure",
            "sage",
            "thoughtful",
            "weighty",
            "important",
            "momentous",
            "serious",
            "sedate",
            "serious",
            "sober",
            "solemn",
            "sombre",
            "staid",
            "sweer",
            "weightsome",
          ],
          antonyms: ["acute"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/grave"],
    },
    {
      word: "grave",
      phonetic: "/ɡɹeɪv/",
      phonetics: [
        {
          text: "/ɡɹeɪv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grave-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1243828",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A count, prefect, or person holding office.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/grave"],
    },
    {
      word: "grave",
      phonetic: "/ɡɹeɪv/",
      phonetics: [
        {
          text: "/ɡɹeɪv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grave-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1243828",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To clean, as a vessel's bottom, of barnacles, grass, etc., and pay it over with pitch — so called because graves or greaves was formerly used for this purpose.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/grave"],
    },
  ],
  tides: [
    {
      word: "tides",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tides-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651614",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The periodic change of the sea level, particularly when caused by the gravitational influence of the sun and the moon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A stream, current or flood.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(chronology, except in liturgy) Time, notably anniversary, period or season linked to an ecclesiastical feast.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A time.",
              synonyms: [],
              antonyms: [],
              example: "The doctor's no good this tide.",
            },
            {
              definition:
                "A point or period of time identified or described by a qualifier (found in compounds).",
              synonyms: [],
              antonyms: [],
              example:
                "Eventide, noontide, morrowtide, nighttide, moon-tide, harvest-tide, wintertide, summertide, springtide, autumn-tide etc.,.",
            },
            {
              definition: "The period of twelve hours.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Something which changes like the tides of the sea.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Tendency or direction of causes, influences, or events; course; current.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Violent confluence", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cause to float with the tide; to drive or carry with the tide or stream.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To pour a tide or flood.",
              synonyms: [],
              antonyms: [],
              example: "The ocean tided most impressively.",
            },
            {
              definition:
                "To work into or out of a river or harbor by drifting with the tide and anchoring when it becomes adverse.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To happen, occur.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["befall", "betide"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tide",
        "https://en.wiktionary.org/wiki/tides",
      ],
    },
  ],
  admit: [
    {
      word: "admit",
      phonetic: "/ədˈmɪt/",
      phonetics: [
        {
          text: "/ədˈmɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/admit-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=174337",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To allow to enter; to grant entrance (to), whether into a place, into the mind, or into consideration",
              synonyms: [],
              antonyms: [],
              example: "A ticket admits one into a playhouse.",
            },
            {
              definition:
                "To allow (someone) to enter a profession or to enjoy a privilege; to recognize as qualified for a franchise.",
              synonyms: [],
              antonyms: [],
              example: "the prisoner was admitted to bail",
            },
            {
              definition:
                "To concede as true; to acknowledge or assent to, as an allegation which it is impossible to deny",
              synonyms: ["confess", "own up"],
              antonyms: [],
              example: "he admitted his guilt",
            },
            {
              definition:
                'To be capable of; to permit. In this sense, "of" may be used after the verb, or may be omitted.',
              synonyms: [],
              antonyms: [],
              example: "the words do not admit such a construction.",
            },
            {
              definition:
                "To give warrant or allowance, to grant opportunity or permission (+ of).",
              synonyms: [],
              antonyms: [],
              example: "circumstances do not admit of this",
            },
            {
              definition:
                "To allow to enter a hospital or similar facility for treatment.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "inlet",
            "let in",
            "acknowledge",
            "own",
            "confess",
            "own up",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/admit"],
    },
  ],
  shift: [
    {
      word: "shift",
      phonetic: "/ʃɪft/",
      phonetics: [
        {
          text: "/ʃɪft/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shift-ca.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=43869762",
        },
        { text: "/ʃɪft/", audio: "" },
        { text: "/ʃɪft/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A modifier key whose main function is shifting between two or more functions of any of certain other keys (usually by pressing Shift and the other button simultaneously).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["shift key"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A type of women's undergarment, a slip.",
              synonyms: [],
              antonyms: [],
              example: "Just last week she bought a new shift at the market.",
            },
            {
              definition:
                "A change of workers, now specifically a set group of workers or period of working time.",
              synonyms: [],
              antonyms: [],
              example: "We'll work three shifts a day till the job's done.",
            },
            {
              definition: "An act of shifting; a slight movement or change.",
              synonyms: [],
              antonyms: [],
              example: "There was a shift in the political atmosphere.",
            },
            {
              definition: "The gear mechanism in a motor vehicle.",
              synonyms: [],
              antonyms: [],
              example: "Does it come with a stick-shift?",
            },
            { definition: "A bit shift.", synonyms: [], antonyms: [] },
            {
              definition: "The infield shift.",
              synonyms: [],
              antonyms: [],
              example: "Teams often use the shift against this lefty.",
            },
            {
              definition:
                "(often with the definite article, usually uncountable) The act of kissing passionately.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A contrivance, a device to try when other methods fail.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A trick, an artifice.", synonyms: [], antonyms: [] },
            {
              definition:
                "The extent, or arrangement, of the overlapping of plank, brick, stones, etc., that are placed in courses so as to break joints.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A breaking off and dislocation of a seam; a fault.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A mutation in which the DNA or RNA from two different sources (such as viruses or bacteria) combine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In violin-playing, any position of the left hand except that nearest the nut.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(sometimes figurative) To move from one place to another; to redistribute.",
              synonyms: [],
              antonyms: [],
              example:
                "We'll have to shift these boxes to the downtown office.",
            },
            {
              definition: "To change in form or character; swap.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To change position.",
              synonyms: [],
              antonyms: [],
              example: "His political stance shifted daily.",
            },
            {
              definition: "To change residence; to leave and live elsewhere.",
              synonyms: ["move"],
              antonyms: [],
              example: "We are shifting to America next month.",
            },
            {
              definition: "To change (clothes, especially underwear).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To change (someone's) clothes; sometimes specifically, to change underwear.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To change gears (in a car).",
              synonyms: [],
              antonyms: [],
              example: "I crested the hill and shifted into fifth.",
            },
            {
              definition:
                "(typewriters) To move the keys of a typewriter over in order to type capital letters and special characters.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(computer keyboards) To switch to a character entry mode for capital letters and special characters.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To manipulate a binary number by moving all of its digits left or right; compare rotate.",
              synonyms: [],
              antonyms: [],
              example:
                "Shifting 1001 to the left yields 10010; shifting it right yields 100.",
            },
            {
              definition: "To remove the first value from an array.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To dispose of.",
              synonyms: [],
              antonyms: [],
              example: "How can I shift a grass stain?",
            },
            {
              definition: "To hurry.",
              synonyms: [],
              antonyms: [],
              example: "If you shift, you might make the 2:19.",
            },
            {
              definition: "To engage in sexual petting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To resort to expedients for accomplishing a purpose; to contrive; to manage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To practice indirect or evasive methods.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In violin-playing, to move the left hand from its original position next to the nut.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "reposition",
            "interchange",
            "swap",
            "get rid of",
            "remove",
            "fondle",
            "grope",
            "hasten",
            "rush",
            "relocate",
            "transfer",
            "move",
          ],
          antonyms: ["unshift"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/Shift",
        "https://en.wiktionary.org/wiki/shift",
      ],
    },
  ],
  sails: [
    {
      word: "sails",
      phonetic: "/seɪlz/",
      phonetics: [
        {
          text: "/seɪlz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sails-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2707596",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A piece of fabric attached to a boat and arranged such that it causes the wind to drive the boat along. The sail may be attached to the boat via a combination of mast, spars and ropes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(nautical,uncountable) The concept of a sail or sails, as if a substance.",
              synonyms: [],
              antonyms: [],
              example: "Take in sail: a storm is coming.",
            },
            {
              definition:
                "The power harnessed by a sail or sails, or the use of this power for travel or transport.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A trip in a boat, especially a sailboat.",
              synonyms: [],
              antonyms: [],
              example: "Let's go for a sail.",
            },
            {
              definition:
                '(plural "sail") A sailing vessel; a vessel of any kind; a craft.',
              synonyms: [],
              antonyms: [],
              example: "Twenty sail were in sight.",
            },
            {
              definition: "The blade of a windmill.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A tower-like structure found on the dorsal (topside) surface of submarines.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The floating organ of siphonophores, such as the Portuguese man-of-war.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sailfish.",
              synonyms: [],
              antonyms: [],
              example: "We caught three sails today.",
            },
            {
              definition:
                "An outward projection of the spine, occurring in certain dinosaurs and synapsids",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Anything resembling a sail, such as a wing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To be impelled or driven forward by the action of wind upon sails, as a ship on water; to be impelled on a body of water by steam or other power.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To move through or on the water; to swim, as a fish or a waterfowl.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To ride in a boat, especially a sailboat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To set sail; to begin a voyage.",
              synonyms: [],
              antonyms: [],
              example: "We sail for Australia tomorrow.",
            },
            {
              definition: "To move briskly and gracefully through the air.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To move briskly.",
              synonyms: [],
              antonyms: [],
              example: "The duchess sailed haughtily out of the room.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sail",
        "https://en.wiktionary.org/wiki/sails",
      ],
    },
  ],
  pupil: [
    {
      word: "pupil",
      phonetic: "/ˈpjuːpəl/",
      phonetics: [
        {
          text: "/ˈpjuːpəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pupil-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2454236",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A learner under the supervision of a teacher or professor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An orphan who is a minor and under the protection of the state.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pupil"],
    },
    {
      word: "pupil",
      phonetic: "/ˈpjuːpəl/",
      phonetics: [
        {
          text: "/ˈpjuːpəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pupil-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2454236",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The hole in the middle of the iris of the eye, through which light passes to be focused on the retina.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The central dark part of an ocellated spot.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pupil"],
    },
  ],
  tiger: [
    {
      word: "tiger",
      phonetic: "/ˈtaɪɡə/",
      phonetics: [
        { text: "/ˈtaɪɡə/", audio: "" },
        {
          text: "/ˈtaɪɡɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tiger-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1755234",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Panthera tigris, a large predatory mammal of the cat family, indigenous to Asia.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A representation of a large mythological cat, used on a coat of arms.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A servant in livery, who rides with his master or mistress.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A leopard.", synonyms: [], antonyms: [] },
            {
              definition:
                "A person who is very athletic during sexual intercourse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A ferocious, bloodthirsty and audacious person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A kind of growl or screech, after cheering.",
              synonyms: [],
              antonyms: [],
              example: "three cheers and a tiger",
            },
            {
              definition: "A pneumatic box or pan used in refining sugar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A tiger moth in the family Arctiidae.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A tiger beetle.", synonyms: [], antonyms: [] },
            {
              definition:
                "A tiger butterfly in tribe Danaini, especially subtribe Danaina",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A relatively small country or group of countries with a fast-growing economy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tiger"],
    },
    {
      word: "tiger",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A final shouted phrase, accompanied by a jump or outstretched arms, at the end of a cheer.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tiger"],
    },
  ],
  angel: [
    {
      word: "angel",
      phonetic: "/ˈeɪn.dʒəl/",
      phonetics: [
        {
          text: "/ˈeɪn.dʒəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/angel-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=562443",
        },
        {
          text: "/ˈeɪn.dʒəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/angel-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1317466",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An incorporeal and sometimes divine messenger from a deity, or other divine entity, often depicted in art as a youthful winged figure in flowing robes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Abrahamic tradition) One of the lowest order of such beings, below virtues.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person having the qualities attributed to angels, such as purity or selflessness.",
              synonyms: [],
              antonyms: [],
              example:
                "Thanks for making me breakfast in bed, you little angel.",
            },
            {
              definition: "Attendant spirit; genius; demon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(possibly obsolete) An official (a bishop, or sometimes a minister) who heads a Christian church, especially a Catholic Apostolic church.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An English gold coin, bearing the figure of the archangel Michael, circulated between the 15th and 17th centuries, and varying in value from six shillings and eightpence to ten shillings.",
              synonyms: ["angel-noble"],
              antonyms: [],
            },
            {
              definition:
                "(originally Royal Air Force) An altitude, measured in thousands of feet.",
              synonyms: [],
              antonyms: [],
              example: "Climb to angels sixty. (“ascend to 60,000 feet”)",
            },
            {
              definition:
                "An unidentified flying object detected by air traffic control radar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An affluent individual who provides capital for a startup, usually in exchange for convertible debt or ownership equity; an angel investor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The person who funds a show.",
              synonyms: ["backer"],
              antonyms: [],
            },
          ],
          synonyms: ["errand-ghost", "angel-noble", "backer"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To support by donating money.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/angel"],
    },
    {
      word: "angel",
      phonetic: "/ˈeɪn.dʒəl/",
      phonetics: [
        {
          text: "/ˈeɪn.dʒəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/angel-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=562443",
        },
        {
          text: "/ˈeɪn.dʒəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/angel-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1317466",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A person who has Angelman syndrome.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/angel"],
    },
  ],
  cruel: [
    {
      word: "cruel",
      phonetic: "/kɹuː(ə)l/",
      phonetics: [
        {
          text: "/kɹuː(ə)l/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cruel-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75270169",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To spoil or ruin (one's chance of success)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To violently provoke (a child) in the belief that this will make them more assertive.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Intentionally causing or reveling in pain and suffering; merciless, heartless.",
              synonyms: ["sadistic"],
              antonyms: ["merciful"],
              example:
                "The supervisor was very cruel to Josh, as he would always give Josh the hardest, most degrading work he could find.",
            },
            {
              definition: "Harsh; severe.",
              synonyms: ["brutal"],
              antonyms: [],
            },
            { definition: "Cool; awesome; neat.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["brutal", "sadistic"],
          antonyms: ["merciful"],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "To a great degree; terribly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cruel"],
    },
    {
      word: "cruel",
      phonetic: "/kɹuː(ə)l/",
      phonetics: [
        {
          text: "/kɹuː(ə)l/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cruel-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75270169",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Worsted yarn, slackly twisted, used for embroidery.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/crewel",
        "https://en.wiktionary.org/wiki/cruel",
      ],
    },
  ],
  agent: [
    {
      word: "agent",
      phonetic: "/ˈeɪ.dʒənt/",
      phonetics: [
        {
          text: "/ˈeɪ.dʒənt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/agent-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749699",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One who exerts power, or has the power to act",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One who acts for, or in the place of, another (the principal), by authority from him/her; someone entrusted to do the business of another",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person who looks for work for another person",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Someone who works for an intelligence agency",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An active power or cause or substance; something which has the power to produce an effect",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In the client-server model, the part of the system that performs information preparation and exchange on behalf of a client or server. Especially in the phrase “intelligent agent” it implies some kind of autonomous process which can communicate with other agents to perform some collective task on behalf of one or more humans.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                '(grammar) The participant of a situation that carries out the action in this situation, e.g. "the boy" in the sentences "The boy kicked the ball" and "The ball was kicked by the boy".',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A cheat who is assisted by dishonest casino staff.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["actor", "performer", "manager", "rep", "representative"],
          antonyms: ["patient", "recipient", "undergoer"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/agent"],
    },
  ],
  drama: [
    {
      word: "drama",
      phonetic: "/ˈdɹæmə/",
      phonetics: [
        { text: "/ˈdɹæmə/", audio: "" },
        { text: "/ˈdɹæmə/", audio: "" },
        { text: "/ˈdɹɑːmə/", audio: "" },
        {
          text: "/ˈdɹɑmə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/drama-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100700",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A composition, normally in prose, telling a story and intended to be represented by actors impersonating the characters and speaking the dialogue",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Such a work for television, radio or the cinema (usually one that is not a comedy)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Theatrical plays in general",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A situation in real life that has the characteristics of such a theatrical play",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Rumor, lying or exaggerated reaction to life or online events; melodrama; an angry dispute or scene; a situation made more complicated or worse than it should be; intrigue or spiteful interpersonal maneuvering.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/drama"],
    },
  ],
  urged: [
    {
      word: "urged",
      phonetic: "/ɜːdʒd/",
      phonetics: [
        { text: "/ɜːdʒd/", audio: "" },
        {
          text: "/ɝdʒd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/urged-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1684759",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To press; to push; to drive; to impel; to force onward.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To press the mind or will of; to ply with motives, arguments, persuasion, or importunity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To provoke; to exasperate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To press hard upon; to follow closely.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To present in an urgent manner; to insist upon.",
              synonyms: [],
              antonyms: [],
              example: "to urge an argument; to urge the necessity of a case",
            },
            {
              definition:
                "To treat with forcible means; to take severe or violent measures with.",
              synonyms: [],
              antonyms: [],
              example: "to urge an ore with intense heat",
            },
            {
              definition: "To press onward or forward.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be pressing in argument; to insist; to persist.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "animate",
            "encourage",
            "impel",
            "incite",
            "instigate",
            "stimulate",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/urge",
        "https://en.wiktionary.org/wiki/urged",
      ],
    },
  ],
  patch: [
    {
      word: "patch",
      phonetic: "/pætʃ/",
      phonetics: [
        {
          text: "/pætʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/patch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=825479",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A piece of cloth, or other suitable material, sewed or otherwise fixed upon a garment to repair or strengthen it, especially upon an old garment to cover a hole.",
              synonyms: [],
              antonyms: [],
              example:
                "His sleeves had patches on the elbows where different fabric had been sewn on to replace material that had worn away.",
            },
            {
              definition:
                "A small piece of anything used to repair damage or a breach; as, a patch on a kettle, a roof, etc.",
              synonyms: [],
              antonyms: [],
              example:
                "I can't afford to replace the roof, which is what it really needs. I'll have the roofer apply a patch.",
            },
            {
              definition:
                "A piece of any size, used to repair something for a temporary period only, or that it is temporary because it is not meant to last long or will be removed as soon as a proper repair can be made, which will happen in the near future.",
              synonyms: [],
              antonyms: [],
              example:
                '"This patch should hold until you reach the city," the mechanic said as he patted the car\'s hood.',
            },
            {
              definition:
                "A small, usually contrasting but always somehow different or distinct, part of something else (location, time, size)",
              synonyms: [],
              antonyms: [],
              example: "Doesn't that patch of clouds looks like a bunny?",
            },
            {
              definition:
                "(specifically) A small area, a small plot of land or piece of ground.",
              synonyms: [],
              antonyms: [],
              example: "Scattered patches of trees or growing corn.",
            },
            {
              definition: "A local region of professional responsibility.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small piece of black silk stuck on the face or neck to heighten beauty by contrast, worn by ladies in the 17th and 18th centuries; an imitation beauty mark.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A piece of material used to cover a wound.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An adhesive piece of material, impregnated with a drug, which is worn on the skin, the drug being slowly absorbed over a period of time.",
              synonyms: [],
              antonyms: [],
              example:
                "Many people use a nicotine patch to wean themselves off of nicotine.",
            },
            {
              definition: "A cover worn over a damaged eye, an eyepatch.",
              synonyms: [],
              antonyms: [],
              example:
                "He had scratched his cornea so badly that his doctor told him to wear a patch.",
            },
            {
              definition:
                "A block on the muzzle of a gun, to do away with the effect of dispart, in sighting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A patch file, a file that describes changes to be made to a computer file or files, usually changes made to a computer program that fix a programming bug.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small piece of material that is manually passed through a gun barrel to clean it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of greased cloth or leather used as wrapping for a rifle ball, to make it fit the bore.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(often patch cable, patch cord etc.; see also patch panel) A cable connecting two pieces of electrical equipment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sound setting for a musical synthesizer (originally selected by means of a patch cable).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An overlay used to obtain a stronger impression.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "tract",
            "area",
            "blotch",
            "period",
            "section",
            "spell",
            "spot",
            "stretch",
            "diff file",
            "beauty spot",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To mend by sewing on a piece or pieces of cloth, leather, or the like",
              synonyms: [],
              antonyms: [],
              example: "My coat needs patching.",
            },
            {
              definition:
                "To mend with pieces; to repair by fastening pieces on.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make out of pieces or patches, like a quilt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To join or unite the pieces of; to patch the skirt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To employ a temporary, removable electronic connection, as one between two components in a communications system.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                '(generally with the particle "up") To repair or arrange in a hasty or clumsy manner',
              synonyms: [],
              antonyms: [],
              example:
                "The truce between the two countries has been patched up.",
            },
            {
              definition:
                "To make the changes a patch describes; to apply a patch to the files in question. Hence:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To connect two pieces of electrical equipment using a cable.",
              synonyms: [],
              antonyms: [],
              example: "I'll need to patch the preamp output to the mixer.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/patch"],
    },
    {
      word: "patch",
      phonetic: "/pætʃ/",
      phonetics: [
        {
          text: "/pætʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/patch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=825479",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A paltry fellow; a rogue; a ninny; a fool.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/patch"],
    },
  ],
  nests: [
    {
      word: "nests",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A structure built by a bird as a place to incubate eggs and rear young.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A place used by another mammal, fish, amphibian or insect, for depositing eggs and hatching young.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A snug, comfortable, or cosy residence or job situation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A retreat, or place of habitual resort.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hideout for bad people to frequent or haunt; a den.",
              synonyms: [],
              antonyms: [],
              example: "That nightclub is a nest of strange people!",
            },
            {
              definition:
                "A home that a child or young adult shares with a parent or guardian.",
              synonyms: [],
              antonyms: [],
              example: "I am aspiring to leave the nest.",
            },
            {
              definition:
                "A fixed number of cards in some bidding games awarded to the highest bidder allowing him to exchange any or all with cards in his hand.",
              synonyms: [],
              antonyms: [],
              example:
                "I was forced to change trumps when I found the ace, jack, and nine of diamonds in the nest.",
            },
            {
              definition: "A fortified position for a weapon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A structure consisting of nested structures, such as nested loops or nested subroutine calls.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A circular bed of pasta, rice, etc. to be topped or filled with other foods.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An aggregated mass of any ore or mineral, in an isolated state, within a rock.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A collection of boxes, cases, or the like, of graduated size, each put within the one next larger.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A compact group of pulleys, gears, springs, etc., working together or collectively.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(of animals) To build or settle into a nest.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To settle into a home.",
              synonyms: [],
              antonyms: [],
              example:
                "We loved the new house and were nesting there in two days!",
            },
            {
              definition: "To successively neatly fit inside another.",
              synonyms: [],
              antonyms: [],
              example: "I bought a set of nesting mixing bowls for my mother.",
            },
            {
              definition: "To place in, or as if in, a nest.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To place one thing neatly inside another, and both inside yet another (and so on).",
              synonyms: [],
              antonyms: [],
              example:
                "There would be much more room in the attic if you had nested all the empty boxes.",
            },
            {
              definition:
                'To hunt for birds\' nests or their contents (usually "go nesting").',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/nest",
        "https://en.wiktionary.org/wiki/nests",
      ],
    },
  ],
  vital: [
    {
      word: "vital",
      phonetic: "/ˈvaɪt̬əl/",
      phonetics: [
        {
          text: "/ˈvaɪt̬əl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/vital-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2101171",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Relating to, or characteristic of life.",
              synonyms: ["lifely"],
              antonyms: [],
              example: "vital energies; vital functions; vital actions",
            },
            {
              definition:
                "Necessary to the continuation of life; being the seat of life; being that on which life depends.",
              synonyms: [],
              antonyms: [],
              example: "The brain is a vital organ.",
            },
            {
              definition: "Invigorating or life-giving.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Necessary to continued existence.",
              synonyms: [],
              antonyms: [],
              example:
                "The transition to farming was vital for the creation of civilisation.",
            },
            {
              definition: "Relating to the recording of life events.",
              synonyms: [],
              antonyms: [],
              example:
                "Birth, marriage and death certificates are vital records.",
            },
            {
              definition: "Very important.",
              synonyms: ["crucial", "necessary", "significant"],
              antonyms: [],
              example: "It is vital that you don't forget to do your homework.",
            },
            {
              definition: "Containing life; living.",
              synonyms: ["extant", "kicking", "live"],
              antonyms: [],
            },
            {
              definition: "Capable of living; in a state to live; viable.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "crucial",
            "necessary",
            "significant",
            "extant",
            "kicking",
            "live",
            "lifely",
          ],
          antonyms: ["mortal"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/vital"],
    },
  ],
  sword: [
    {
      word: "sword",
      phonetic: "/sɔːd/",
      phonetics: [
        { text: "/sɔːd/", audio: "" },
        {
          text: "/sɔɹd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sword-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1244086",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A long-bladed weapon with a hilt, and usually a pommel and cross-guard, which is designed to stab, slash, and/or hack.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A suit in the minor arcana in tarot.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A card of this suit.", synonyms: [], antonyms: [] },
            {
              definition:
                "One of the end bars by which the lay of a hand loom is suspended.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The weapon, often used as a heraldic charge.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sword"],
    },
  ],
  blame: [
    {
      word: "blame",
      phonetic: "/bleɪm/",
      phonetics: [
        {
          text: "/bleɪm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/blame-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88518396",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/bleɪm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/blame-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1231942",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Censure.",
              synonyms: [],
              antonyms: [],
              example: "Blame came from all directions.",
            },
            {
              definition: "Culpability for something negative or undesirable.",
              synonyms: [],
              antonyms: [],
              example:
                "The blame for starting the fire lies with the arsonist.",
            },
            {
              definition: "Responsibility for something meriting censure.",
              synonyms: [],
              antonyms: [],
              example: "They accepted the blame, but it was an accident.",
            },
            {
              definition:
                "A source control feature that can show which user was responsible for a particular portion of the source code.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/blame"],
    },
    {
      word: "blame",
      phonetic: "/bleɪm/",
      phonetics: [
        {
          text: "/bleɪm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/blame-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88518396",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/bleɪm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/blame-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1231942",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To censure (someone or something); to criticize.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To bring into disrepute.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                '(usually followed by "for") To assert or consider that someone is the cause of something negative; to place blame, to attribute responsibility (for something negative or for doing something negative).',
              synonyms: [],
              antonyms: [],
              example: "The arsonist was blamed for the fire.",
            },
          ],
          synonyms: [
            "reproach",
            "shend",
            "take to task",
            "upbraid",
            "hold to account",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/blame"],
    },
  ],
  weeds: [
    {
      word: "weeds",
      phonetic: "/wiːdz/",
      phonetics: [
        { text: "/wiːdz/", audio: "" },
        {
          text: "/wiːdz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/weeds-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=4124484",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any plant regarded as unwanted at the place where, and at the time when it is growing.",
              synonyms: [],
              antonyms: [],
              example:
                "If it isn't in a straight line or marked with a label, it's a weed.",
            },
            { definition: "Short for duckweed.", synonyms: [], antonyms: [] },
            {
              definition: "Underbrush; low shrubs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A drug or the like made from the leaves of a plant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A weak horse, which is therefore unfit to breed from.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A puny person; one who has little physical strength.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something unprofitable or troublesome; anything useless.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To remove unwanted vegetation from a cultivated area.",
              synonyms: [],
              antonyms: [],
              example: "I weeded my flower bed.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A garment or piece of clothing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Clothing collectively; clothes, dress.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An article of dress worn in token of grief; a mourning garment or badge.",
              synonyms: [],
              antonyms: [],
              example: "He wore a weed on his hat.",
            },
            {
              definition:
                '(especially in the plural as "widow\'s weeds") (Female) mourning apparel.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sudden illness or relapse, often attended with fever, which befalls those who are about to give birth, are giving birth, or have recently given birth or miscarried or aborted.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Lymphangitis in a horse.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/weed",
        "https://en.wiktionary.org/wiki/weeds",
      ],
    },
    {
      word: "weeds",
      phonetic: "/wiːdz/",
      phonetics: [
        { text: "/wiːdz/", audio: "" },
        {
          text: "/wiːdz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/weeds-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=4124484",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "Clothes.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/weeds"],
    },
  ],
  screw: [
    {
      word: "screw",
      phonetic: "/skɹuː/",
      phonetics: [
        {
          text: "/skɹuː/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/screw-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1157608",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A device that has a helical function.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The motion of screwing something; a turn or twist to one side.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A prison guard.", synonyms: [], antonyms: [] },
            {
              definition: "An extortioner; a sharp bargainer; a skinflint.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An instructor who examines with great or unnecessary severity; also, a searching or strict examination of a student by an instructor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Sexual intercourse; the act of screwing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A casual sexual partner.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Salary, wages.", synonyms: [], antonyms: [] },
            { definition: "Backspin.", synonyms: [], antonyms: [] },
            {
              definition: "A small packet of tobacco.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An old, worn-out, unsound and worthless horse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A straight line in space with which a definite linear magnitude termed the pitch is associated. It is used to express the displacement of a rigid body, which may always be made to consist of a rotation about an axis combined with a translation parallel to that axis.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An amphipod crustacean.",
              synonyms: [],
              antonyms: [],
              example: "the skeleton screw (Caprella);  the sand screw",
            },
            {
              definition: '(in the plural, with "the") Rheumatism.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To connect or assemble pieces using a screw.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To have sexual intercourse with.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cheat someone or ruin their chances in a game or other situation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To practice extortion upon; to oppress by unreasonable or extortionate exactions; to put the screws on.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To contort.", synonyms: [], antonyms: [] },
            {
              definition:
                "To miskick (a ball) by hitting it with the wrong part of the foot.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To screw back.", synonyms: [], antonyms: [] },
            {
              definition:
                "To examine (a student) rigidly; to subject to a severe examination.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "fuck",
            "screw over",
            "screw up",
            "twist",
            "writhe",
            "fuck",
            "root",
            "shag",
            "extort",
          ],
          antonyms: ["unscrew"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/screw"],
    },
  ],
  vocal: [
    {
      word: "vocal",
      phonetic: "/ˈvəʊ.kəl/",
      phonetics: [
        {
          text: "/ˈvəʊ.kəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/vocal-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=81996482",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈvoʊ.kəl/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A vocal sound; specifically, a purely vocal element of speech, unmodified except by resonance; a vowel or a diphthong; a tonic element; a tonic; distinguished from a subvocal, and a nonvocal",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Roman Catholic Church) A man who has a right to vote in certain elections.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of or pertaining to the voice or speech",
              synonyms: [],
              antonyms: [],
              example: "vocal problems",
            },
            { definition: "Having a voice", synonyms: [], antonyms: [] },
            {
              definition: "Uttered or modulated by the voice; oral",
              synonyms: [],
              antonyms: [],
              example: "vocal melody",
            },
            {
              definition: "Of or pertaining to a voice sound; spoken",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Consisting of, or characterized by, voice, or tone produced in the larynx, which may be modified, either by resonance, as in the case of the vowels, or by obstructive action, as in certain consonants, such as v, l, etc., or by both, as in the nasals (m), (n), ng; sonant; intonated; voiced. See voice, and vowel",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of or pertaining to a vowel; having the character of a vowel; vowel",
              synonyms: [],
              antonyms: [],
              example: "a vocal sound",
            },
            {
              definition: "Loud; getting oneself heard.",
              synonyms: [],
              antonyms: [],
              example:
                "The protesters were very vocal in their message to the mayor.",
            },
          ],
          synonyms: ["audible", "loud", "audible"],
          antonyms: [
            "inaudible",
            "quiet",
            "silent",
            "inaudible",
            "quiet",
            "silent",
            "voiceless",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/vocal"],
    },
  ],
  bacon: [
    {
      word: "bacon",
      phonetic: "/ˈbeɪ.kən/",
      phonetics: [
        {
          text: "/ˈbeɪ.kən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bacon-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79240975",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Cured meat from the sides, belly or back of a pig.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Thin slices of the above in long strips.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The police or spies.",
              synonyms: [],
              antonyms: [],
              example: "Run! It's the bacon!",
            },
            { definition: "Road rash.", synonyms: [], antonyms: [] },
            { definition: "A saucisse.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["ham", "pork"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bacon"],
    },
  ],
  chalk: [
    {
      word: "chalk",
      phonetic: "/t͡ʃɔːk/",
      phonetics: [
        { text: "/t͡ʃɔːk/", audio: "" },
        { text: "/t͡ʃɔk/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A soft, white, powdery limestone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of chalk, or nowadays processed compressed gypsum, that is used for drawing and for writing on a blackboard.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Tailor's chalk.", synonyms: [], antonyms: [] },
            {
              definition:
                "A white powdery substance used to prevent hands slipping from holds when climbing, sometimes but not always limestone-chalk.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A platoon-sized group of airborne soldiers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(horseracing) The favorite in a sporting event.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The prediction that there will be no upsets, and the favored competitor will win.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To apply chalk to anything, such as the tip of a billiard cue.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To record something, as on a blackboard, using chalk.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To use powdered chalk to mark the lines on a playing field.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To record a score or event, as if on a chalkboard.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To manure (land) with chalk.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make white, as if with chalk; to make pale; to bleach.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chalk"],
    },
  ],
  cargo: [
    {
      word: "cargo",
      phonetic: "/ˈkɑːɡəʊ/",
      phonetics: [
        { text: "/ˈkɑːɡəʊ/", audio: "" },
        {
          text: "/ˈkɑɹɡoʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cargo-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2650731",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Freight carried by a ship, aircraft, or motor vehicle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(Papua New Guinea) Western material goods.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cargo"],
    },
  ],
  crazy: [
    {
      word: "crazy",
      phonetic: "/ˈkɹeɪzi/",
      phonetics: [
        {
          text: "/ˈkɹeɪzi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/crazy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1224309",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An insane or eccentric person; a crackpot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Eccentric behaviour; lunacy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "lunatic",
            "mad man",
            "nut ball",
            "nut case",
            "nutso",
            "psychopath",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Flawed or damaged; unsound, liable to break apart; ramshackle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Sickly, frail; diseased.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of unsound mind; insane, demented.",
              synonyms: [],
              antonyms: [],
              example: "His ideas were both frightening and crazy.",
            },
            {
              definition: "Out of control.",
              synonyms: [],
              antonyms: [],
              example: "When she gets on the motorcycle she goes crazy.",
            },
            {
              definition: "Very excited or enthusiastic.",
              synonyms: [],
              antonyms: [],
              example: "He went crazy when he won.",
            },
            {
              definition: "In love; experiencing romantic feelings.",
              synonyms: [],
              antonyms: [],
              example: "Why is she so crazy about him?",
            },
            {
              definition: "Very unexpected; wildly surprising.",
              synonyms: [],
              antonyms: [],
              example: "The game had a crazy ending.",
            },
          ],
          synonyms: [
            "deranged",
            "loco",
            "nutso",
            "zany",
            "nutso",
            "off the chain",
            "insane",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Very, extremely.",
              synonyms: [],
              antonyms: [],
              example: "That trick was crazy good.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/crazy"],
    },
  ],
  acted: [
    {
      word: "acted",
      phonetic: "/ˈæk.tɪd/",
      phonetics: [
        {
          text: "/ˈæk.tɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/acted-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1684540",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To do something.",
              synonyms: [],
              antonyms: [],
              example: "If you don't act soon, you will be in trouble.",
            },
            {
              definition: "To do (something); to perform.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To perform a theatrical role.",
              synonyms: [],
              antonyms: [],
              example:
                "I started acting at the age of eleven in my local theatre.",
            },
            {
              definition: "Of a play: to be acted out (well or badly).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To behave in a certain manner for an indefinite length of time.",
              synonyms: [],
              antonyms: [],
              example: "A dog which acts aggressively is likely to bite.",
            },
            {
              definition: "To convey an appearance of being.",
              synonyms: [],
              antonyms: [],
              example: "He acted unconcerned so the others wouldn't worry.",
            },
            {
              definition:
                "To do something that causes a change binding on the doer.",
              synonyms: [],
              antonyms: [],
              example: "act on behalf of John",
            },
            {
              definition: "(construed with on or upon) To have an effect (on).",
              synonyms: [],
              antonyms: [],
              example: "Gravitational force acts on heavy bodies.",
            },
            {
              definition: "To play (a role).",
              synonyms: [],
              antonyms: [],
              example:
                "He's been acting Shakespearean leads since he was twelve.",
            },
            {
              definition: "To feign.",
              synonyms: [],
              antonyms: [],
              example: "He acted the angry parent, but was secretly amused.",
            },
            {
              definition:
                "(construed with on or upon, of a group) To map via a homomorphism to a group of automorphisms (of).",
              synonyms: [],
              antonyms: [],
              example:
                "This group acts on the circle, so it can't be left-orderable!",
            },
            {
              definition: "To move to action; to actuate; to animate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/act",
        "https://en.wiktionary.org/wiki/acted",
      ],
    },
  ],
  goats: [
    {
      word: "goats",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A mammal, Capra aegagrus hircus, and similar species of the genus Capra.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A lecherous man.", synonyms: [], antonyms: [] },
            { definition: "A scapegoat.", synonyms: [], antonyms: [] },
            { definition: "A Pontiac GTO car.", synonyms: [], antonyms: [] },
            {
              definition:
                "(speech recognition) A person who is not easily understood by a speech recognition system; contrasted with sheep.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A fool, loser, or object of ridicule.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To allow goats to feed on.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To scapegoat.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/goat",
        "https://en.wiktionary.org/wiki/goats",
      ],
    },
  ],
  arise: [
    {
      word: "arise",
      phonetic: "/əˈɹaɪz/",
      phonetics: [
        {
          text: "/əˈɹaɪz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/arise-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1684550",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To come up from a lower to a higher position.",
              synonyms: [],
              antonyms: [],
              example: "to arise from a kneeling posture",
            },
            {
              definition:
                "To come up from one's bed or place of repose; to get up.",
              synonyms: [],
              antonyms: [],
              example: "He arose early in the morning.",
            },
            {
              definition:
                "To spring up; to come into action, being, or notice; to become operative, sensible, or visible; to begin to act a part; to present itself.",
              synonyms: [],
              antonyms: [],
              example: "A cloud arose and covered the sun.",
            },
          ],
          synonyms: [
            "rise",
            "spring",
            "stand up",
            "awaken",
            "come about",
            "come to pass",
            "occur",
            "appear",
            "emerge",
            "originate",
            "pop up",
            "reappear",
            "surface",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/arise"],
    },
  ],
  witch: [
    {
      word: "witch",
      phonetic: "/wɪtʃ/",
      phonetics: [
        {
          text: "/wɪtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/witch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1770099",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person who practices witchcraft; a woman or man who practices witchcraft.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An ugly or unpleasant woman.",
              synonyms: [],
              antonyms: [],
              example: "I hate that old witch.",
            },
            {
              definition:
                "One who exercises more-than-common power of attraction; a charming or bewitching person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One given to mischief, especially a woman or child.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A certain curve of the third order, described by Maria Agnesi under the name versiera.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The stormy petrel.", synonyms: [], antonyms: [] },
            {
              definition: "Any of a number of flatfish:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The Indomalayan butterfly Araotes lapithis, of the family Lycaenidae.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["sorceress", "wizardess", "sorcerer", "warlock", "wizard"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To practise witchcraft.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To bewitch.", synonyms: [], antonyms: [] },
            { definition: "To dowse for water.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/witch"],
    },
    {
      word: "witch",
      phonetic: "/wɪtʃ/",
      phonetics: [
        {
          text: "/wɪtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/witch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1770099",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A cone of paper which is placed in a vessel of lard or other fat and used as a taper.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/witch"],
    },
  ],
  loves: [
    {
      word: "loves",
      phonetic: "/lʌvz/",
      phonetics: [
        {
          text: "/lʌvz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/loves-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1230390",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Strong affection.", synonyms: [], antonyms: [] },
            {
              definition:
                "A person who is the object of romantic feelings; a darling, a sweetheart, a beloved.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A term of friendly address, regardless of feelings.",
              synonyms: [],
              antonyms: [],
              example: "Hello love, how can I help you?",
            },
            {
              definition:
                "A thing, activity etc which is the object of one's deep liking or enthusiasm.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Sexual desire; attachment based on sexual attraction.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Sexual activity.", synonyms: [], antonyms: [] },
            {
              definition:
                "An instance or episode of being in love; a love affair.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Used as the closing, before the signature, of a letter, especially between good friends or family members, or by the young.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Alternative letter-case form of Love.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A thin silk material.", synonyms: [], antonyms: [] },
            {
              definition: "A climbing plant, Clematis vitalba.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "baby",
            "darling",
            "honey",
            "love bird",
            "lover",
            "pet",
            "sweetheart",
            "romance",
            "coitus",
            "sex",
            "the beast with two backs",
            "aphrodisia",
            "carnality",
            "darling",
            "lover",
            "mate",
            "sweetheart",
            "sweetie",
          ],
          antonyms: [
            "indifference",
            "angst",
            "hate",
            "hatred",
            "malice",
            "spite",
          ],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(usually transitive, sometimes intransitive, stative) To have a strong affection for (someone or something).",
              synonyms: [],
              antonyms: [],
              example: "I love my spouse.   I love you!",
            },
            {
              definition: "To need, thrive on.",
              synonyms: [],
              antonyms: [],
              example: "Mold loves moist, dark places.",
            },
            {
              definition:
                "To be strongly inclined towards something; an emphatic form of like.",
              synonyms: [],
              antonyms: [],
              example:
                "I love walking barefoot on wet grass;  I'd love to join the team;  I love what you've done with your hair",
            },
            {
              definition:
                "(usually transitive, sometimes intransitive) To care deeply about, to be dedicated to (someone or something).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To derive delight from a fact or situation.",
              synonyms: [],
              antonyms: [],
              example:
                "I love the fact that the coffee shop now offers fat-free chai latte.",
            },
            {
              definition: "To have sex with (perhaps from make love).",
              synonyms: [],
              antonyms: [],
              example: "I wish I could love her all night long.",
            },
          ],
          synonyms: [
            "adore",
            "cherish",
            "enjoy",
            "go to bed with",
            "sleep with",
          ],
          antonyms: ["despise", "hate"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To praise; commend.", synonyms: [], antonyms: [] },
            {
              definition: "To praise as of value; prize; set a price on.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/love",
        "https://en.wiktionary.org/wiki/loves",
      ],
    },
  ],
  queer: [
    {
      word: "queer",
      phonetic: "/kwɪə/",
      phonetics: [
        { text: "/kwɪə/", audio: "" },
        {
          text: "/kwɪɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/queer-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2079736",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(sometimes derogatory) A person who is or appears homosexual, or who has homosexual qualities.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(sometimes derogatory) A person of any non-heterosexual sexuality or sexual identity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(sometimes derogatory) A person of any genderqueer identity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: '(definite, with "the") Counterfeit money.',
              synonyms: ["funny money", "snide"],
              antonyms: [],
            },
          ],
          synonyms: ["funny money", "snide"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To render an endeavor or agreement ineffective or null.",
              synonyms: ["invalidate"],
              antonyms: [],
            },
            { definition: "To puzzle.", synonyms: [], antonyms: [] },
            {
              definition: "To ridicule; to banter; to rally.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To spoil the effect or success of, as by ridicule; to throw a wet blanket on; to spoil.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To reevaluate or reinterpret (a work) with an eye to sexual orientation and/or to gender, as by applying queer theory.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["invalidate"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Weird, odd or different; whimsical.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: 'Slightly unwell (mainly in "to feel queer").',
              synonyms: [],
              antonyms: [],
            },
            { definition: "Drunk.", synonyms: [], antonyms: [] },
            {
              definition: "(sometimes derogatory) Homosexual.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(sometimes derogatory) Not heterosexual, or not cisgender: homosexual, bisexual, asexual, transgender, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(broadly) Pertaining to sexual or gender behaviour or identity which does not conform to conventional heterosexual or cisgender norms, assumptions etc.",
              synonyms: [],
              antonyms: [],
              example: "the queer community",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [{ definition: "Queerly.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/queer"],
    },
  ],
  dwell: [
    {
      word: "dwell",
      phonetic: "/dwɛl/",
      phonetics: [
        {
          text: "/dwɛl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dwell-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2079687",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A period of time in which a system or component remains in a given state.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A brief pause in the motion of part of a mechanism to allow an operation to be completed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A planned delay in a timed control program.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In a petrol engine, the period of time the ignition points are closed to let current flow through the ignition coil in between each spark. This is measured as an angle in degrees around the camshaft in the distributor which controls the points, for example in a 4-cylinder engine it might be 55° (spark at 90° intervals, points closed for 55° between each).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To live; to reside.", synonyms: [], antonyms: [] },
            {
              definition:
                "To linger (on) a particular thought, idea etc.; to remain fixated (on).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be in a given state.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To abide; to remain; to continue.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dwell"],
    },
  ],
  backs: [
    {
      word: "backs",
      phonetic: "/bæks/",
      phonetics: [
        {
          text: "/bæks/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/backs-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453856",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The rear of the body, especially the part between the neck and the end of the spine and opposite the chest and belly.",
              synonyms: [],
              antonyms: [],
              example: "Could you please scratch my back?",
            },
            {
              definition: "That which is farthest away from the front.",
              synonyms: [],
              antonyms: [],
              example: "He sat in the back of the room.",
            },
            {
              definition:
                "Upper part of a natural object which is considered to resemble an animal's back.",
              synonyms: [],
              antonyms: [],
              example: "The small boat raced over the backs of the waves.",
            },
            {
              definition: "A support or resource in reserve.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The keel and keelson of a ship.",
              synonyms: [],
              antonyms: [],
              example: "The ship's back broke in the pounding surf.",
            },
            {
              definition: "The roof of a horizontal underground passage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Effort, usually physical.",
              synonyms: [],
              antonyms: [],
              example: "Put some back into it!",
            },
            {
              definition:
                "A non-alcoholic drink (often water or a soft drink), to go with hard liquor or a cocktail.",
              synonyms: [],
              antonyms: [],
              example: "Could I get a martini with a water back?",
            },
            {
              definition:
                "Among leather dealers, one of the thickest and stoutest tanned hides.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dorsum", "reverse"],
          antonyms: ["front", "front"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To go in the reverse direction.",
              synonyms: [],
              antonyms: [],
              example:
                "the train backed into the station;  the horse refuses to back",
            },
            {
              definition: "To support.",
              synonyms: [],
              antonyms: [],
              example:
                "I back you all the way;  which horse are you backing in this race?",
            },
            {
              definition:
                "(of the wind) To change direction contrary to the normal pattern; that is, to shift anticlockwise in the northern hemisphere, or clockwise in the southern hemisphere.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a square sail) To brace the yards so that the wind presses on the front of the sail, to slow the ship.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of an anchor) To lay out a second, smaller anchor to provide additional holding power.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a hunting dog) To stand still behind another dog which has pointed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To push or force backwards.",
              synonyms: [],
              antonyms: [],
              example:
                "The mugger backed her into a corner and demanded her wallet.",
            },
            {
              definition: "To get upon the back of; to mount.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To place or seat upon the back.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make a back for; to furnish with a back.",
              synonyms: [],
              antonyms: [],
              example: "to back books",
            },
            {
              definition: "To adjoin behind; to be at the back of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To write upon the back of, possibly as an endorsement.",
              synonyms: [],
              antonyms: [],
              example: "to back a letter;  to back a note or legal document",
            },
            {
              definition:
                "(of a justice of the peace) To sign or endorse (a warrant, issued in another county, to apprehend an offender).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To row backward with (oars).",
              synonyms: [],
              antonyms: [],
              example: "to back the oars",
            },
          ],
          synonyms: [],
          antonyms: ["veer"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A large shallow vat; a cistern, tub, or trough, used by brewers, distillers, dyers, picklers, gluemakers, and others, for mixing or cooling wort, holding water, hot glue, etc.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A ferryboat.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/back",
        "https://en.wiktionary.org/wiki/backs",
      ],
    },
  ],
  ropes: [
    {
      word: "ropes",
      phonetic: "/ɹəʊps/",
      phonetics: [
        { text: "/ɹəʊps/", audio: "" },
        {
          text: "/ɹoʊps/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ropes-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651448",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Thick strings, yarn, monofilaments, metal wires, or strands of other cordage that are twisted together to form a stronger line.",
              synonyms: [],
              antonyms: [],
              example:
                "Nylon rope is usually stronger than similar rope made of plant fibers.",
            },
            {
              definition: "An individual length of such material.",
              synonyms: [],
              antonyms: [],
              example:
                "The swinging bridge is constructed of 40 logs and 30 ropes.",
            },
            {
              definition: "A cohesive strand of something.",
              synonyms: [],
              antonyms: [],
              example: "The duchess wore a rope of pearls to the soirée.",
            },
            { definition: "A continuous stream.", synonyms: [], antonyms: [] },
            {
              definition: "A hard line drive.",
              synonyms: [],
              antonyms: [],
              example: "He hit a rope past third and into the corner.",
            },
            {
              definition:
                "A long thin segment of soft clay, either extruded or formed by hand.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A data structure resembling a string, using a concatenation tree in which each leaf represents a character.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A unit of distance equivalent to the distance covered in six months by a god flying at ten million miles per second.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(jewelry) A necklace of at least 1 meter in length.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Cordage of at least 1 inch in diameter, or a length of such cordage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A unit of length equal to 20 feet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Flunitrazepam, also known as Rohypnol.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A shot of semen that a man releases during ejaculation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(in the plural) The small intestines.",
              synonyms: [],
              antonyms: [],
              example: "the ropes of birds",
            },
          ],
          synonyms: ["cord", "line", "twine", "infinitude", "rajju"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To tie (something) with rope.",
              synonyms: [],
              antonyms: [],
              example: "The robber roped the victims.",
            },
            {
              definition:
                "To throw a rope (or something similar, e.g. a lasso, cable, wire, etc.) around (something).",
              synonyms: [],
              antonyms: [],
              example: "The cowboy roped the calf.",
            },
            {
              definition:
                "To be formed into rope; to draw out or extend into a filament or thread.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To commit suicide.",
              synonyms: [],
              antonyms: [],
              example: "My life is a mess; I might as well rope.",
            },
          ],
          synonyms: ["lasso", "bind", "secure", "tie"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rope",
        "https://en.wiktionary.org/wiki/ropes",
      ],
    },
  ],
  shots: [
    {
      word: "shots",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shots-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651501",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The result of launching a projectile or bullet.",
              synonyms: [],
              antonyms: [],
              example: "The shot was wide off the mark.",
            },
            {
              definition:
                "The act of launching a ball or similar object toward a goal.",
              synonyms: [],
              antonyms: [],
              example: "They took the lead on a last-minute shot.",
            },
            {
              definition: "The heavy iron ball used for the shot put.",
              synonyms: [],
              antonyms: [],
              example:
                "The shot flew twenty metres, and nearly landed on the judge's foot.",
            },
            {
              definition: "Small metal balls used as ammunition.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Metal balls (or similar) used as ammunition; not necessarily small.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(referring to one's skill at firing a gun) Someone who shoots (a gun) regularly",
              synonyms: [],
              antonyms: [],
              example: "He'd make a bad soldier as he's a lousy shot.",
            },
            {
              definition: "An opportunity or attempt.",
              synonyms: [],
              antonyms: [],
              example: "I'd like just one more shot at winning this game.",
            },
            {
              definition:
                "A remark or comment, especially one which is critical or insulting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A punch or other physical blow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'A measure of alcohol, usually spirits, as taken either from a shot-glass or directly from the bottle, equivalent to about 44 milliliters; 1.5 ounces. ("pony shot"= 30 milliliters; 1 fluid ounce)',
              synonyms: [],
              antonyms: [],
              example: "I'd like a shot of whisky in my coffee.",
            },
            {
              definition: "A single serving of espresso.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A reckoning, a share of a tavern bill, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A single snapshot or an unbroken sequence of photographic film exposures, or the digital equivalent; an unedited sequence of frames.",
              synonyms: [],
              antonyms: [],
              example: "We got a good shot of the hummingbirds mating.",
            },
            {
              definition: "A vaccination or injection.",
              synonyms: [],
              antonyms: [],
              example: "I went to the doctor to get a shot for malaria.",
            },
            {
              definition:
                "A home run that scores one, two, or three runs (a four run home run is usually referred to as a grand slam).",
              synonyms: [],
              antonyms: [],
              example:
                "His solo shot in the seventh inning ended up winning the game.",
            },
            {
              definition:
                "(US federal prison system) Written documentation of a behavior infraction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(fisheries) A cast of one or more nets.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(fisheries) A place or spot for setting nets.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(fisheries) A single draft or catch of fish made.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To load (a gun) with shot.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A charge to be paid, a scot or shout.",
              synonyms: [],
              antonyms: [],
              example: "Drink up. It's his shot.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/shot",
        "https://en.wiktionary.org/wiki/shots",
      ],
    },
  ],
  merry: [
    {
      word: "merry",
      phonetic: "/ˈmɛɹi/",
      phonetics: [
        { text: "/ˈmɛɹi/", audio: "" },
        {
          text: "/ˈmeɪɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/merry-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749896",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Jolly and full of high spirits.",
              synonyms: [],
              antonyms: [],
              example: "We had a very merry Christmas.",
            },
            {
              definition: "Festive and full of fun and laughter.",
              synonyms: [],
              antonyms: [],
              example: "Everyone was merry at the party.",
            },
            {
              definition: "Brisk",
              synonyms: [],
              antonyms: [],
              example: "The play moved along at a merry pace.",
            },
            {
              definition: "Causing laughter, mirth, gladness, or delight.",
              synonyms: [],
              antonyms: [],
              example: "a merry jest",
            },
            {
              definition: "Drunk; tipsy",
              synonyms: [],
              antonyms: [],
              example:
                "Some of us got a little merry at the office Christmas party.",
            },
          ],
          synonyms: [
            "energetic",
            "lively",
            "spirited",
            "delightful",
            "gladful",
            "lushy",
            "muzzy",
            "squiffy",
            "convivial",
            "gay",
            "jovial",
            "cheerful",
            "content",
            "ecstatic",
            "exultant",
            "gay",
            "happy",
            "jovial",
            "joyful",
            "pleased",
          ],
          antonyms: ["miserable", "unhappy"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/merry"],
    },
    {
      word: "merry",
      phonetic: "/ˈmɛɹi/",
      phonetics: [
        { text: "/ˈmɛɹi/", audio: "" },
        {
          text: "/ˈmeɪɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/merry-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749896",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An English wild cherry.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/merry"],
    },
  ],
  phone: [
    {
      word: "phone",
      phonetic: "/fəʊ̯n/",
      phonetics: [
        {
          text: "/fəʊ̯n/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/phone-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9023306",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/foʊ̯n/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/phone-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1224261",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A device for transmitting conversations and other sounds in real time across distances, now often a small portable unit also capable of running software etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To call (someone) using a telephone.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["call", "ring", "telephone"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/phone"],
    },
    {
      word: "phone",
      phonetic: "/fəʊ̯n/",
      phonetics: [
        {
          text: "/fəʊ̯n/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/phone-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9023306",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/foʊ̯n/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/phone-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1224261",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A speech segment that possesses distinct physical or perceptual properties, considered as a physical event without regard to its place in the phonology of a language.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/phone"],
    },
  ],
  cheek: [
    {
      word: "cheek",
      phonetic: "/tʃiːk/",
      phonetics: [
        {
          text: "/tʃiːk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cheek-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749743",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The soft skin on each side of the face, below the eyes; the outer surface of the sides of the oral cavity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(usually in the plural) The lower part of the buttocks that is often exposed beneath very brief underwear, swimwear, or extremely short shorts.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Impudence.",
              synonyms: [],
              antonyms: [],
              example: "You’ve got some cheek, asking me for money!",
            },
            {
              definition:
                "One of the genae, flat areas on the sides of a trilobite's cephalon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the pieces of a machine, or of timber or stonework, that form corresponding sides or a similar pair.",
              synonyms: [],
              antonyms: [],
              example: "the cheeks of a vice; the cheeks of a gun carriage",
            },
            {
              definition: "(in plural) The branches of a bridle bit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Either side of an axehead.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The middle section of a flask, made so that it can be moved laterally, to permit the removal of the pattern from the mould.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "arsecheek",
            "asscheek",
            "butt cheek",
            "nether cheek",
            "gena",
            "brass neck",
            "chutzpah",
            "impertinence",
            "impudence",
            "nerve",
            "sass",
            "wang",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To be impudent towards.",
              synonyms: [],
              antonyms: [],
              example: "Don't cheek me, you little rascal!",
            },
            {
              definition:
                "To pull a horse's head back toward the saddle using the cheek strap of the bridle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cheek"],
    },
  ],
  peaks: [
    {
      word: "peaks",
      phonetic: "/piːks/",
      phonetics: [{ text: "/piːks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A point; the sharp end or top of anything that terminates in a point; as, the peak, or front, of a cap.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The highest value reached by some quantity in a time period.",
              synonyms: ["apex", "pinnacle"],
              antonyms: [],
              example: "The stock market reached a peak in September 1929.",
            },
            {
              definition:
                "The top, or one of the tops, of a hill, mountain, or range, ending in a point.",
              synonyms: ["summit", "top"],
              antonyms: [],
              example: "They reached the peak after 8 hours of climbing.",
            },
            {
              definition:
                "The whole hill or mountain, especially when isolated.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The upper aftermost corner of a fore-and-aft sail.",
              synonyms: [],
              antonyms: [],
              example: "peak-brails",
            },
            {
              definition:
                "The narrow part of a vessel's bow, or the hold within it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The extremity of an anchor fluke; the bill.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A local maximum of a function, e.g. for sine waves, each point at which the value of y is at its maximum.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["apex", "pinnacle", "summit", "top"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To reach a highest degree or maximum.",
              synonyms: [],
              antonyms: [],
              example:
                "Historians argue about when the Roman Empire began to peak and ultimately decay.",
            },
            {
              definition:
                "To rise or extend into a peak or point; to form, or appear as, a peak.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To raise the point of (a gaff) closer to perpendicular.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["culminate"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To become sick or wan.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To acquire sharpness of figure or features; hence, to look thin or sickly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To pry; to peep slyly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/peak",
        "https://en.wiktionary.org/wiki/peaks",
      ],
    },
  ],
  ideal: [
    {
      word: "ideal",
      phonetic: "/aɪˈdiː.əl/",
      phonetics: [
        {
          text: "/aɪˈdiː.əl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ideal-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749869",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A perfect standard of beauty, intellect etc., or a standard of excellence to aim at.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A subring closed under multiplication by its containing ring.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(lattice theory) A non-empty lower set (of a partially ordered set) which is closed under binary suprema (a.k.a. joins).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A collection of sets, considered small or negligible, such that every subset of each member and the union of any two members are also members of the collection.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Lie theory) A Lie subalgebra (subspace that is closed under the Lie bracket) 𝖍 of a given Lie algebra 𝖌 such that the Lie bracket [𝖌,𝖍] is a subset of 𝖍.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["Lie ideal"],
          antonyms: ["filter"],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Optimal; being the best possibility.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Perfect, flawless, having no defects.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Pertaining to ideas, or to a given idea.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Existing only in the mind; conceptual, imaginary.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Teaching or relating to the doctrine of idealism.",
              synonyms: [],
              antonyms: [],
              example: "the ideal theory or philosophy",
            },
            {
              definition:
                "Not actually present, but considered as present when limits at infinity are included.",
              synonyms: [],
              antonyms: [],
              example:
                "An ideal triangle in the hyperbolic disk is one bounded by three geodesics that meet precisely on the circle.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ideal"],
    },
  ],
  beard: [
    {
      word: "beard",
      phonetic: "/bɪəd/",
      phonetics: [
        { text: "/bɪəd/", audio: "" },
        {
          text: "/biɚd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/beard-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1318177",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Facial hair on the chin, cheeks, jaw and neck.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The cluster of small feathers at the base of the beak in some birds.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The appendages to the jaw in some cetaceans, and to the mouth or jaws of some fishes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The byssus of certain shellfish.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The gills of some bivalves, such as the oyster.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In insects, the hairs of the labial palpi of moths and butterflies.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Long or stiff hairs on a plant; the awn.",
              synonyms: [],
              antonyms: [],
              example: "the beard of grain",
            },
            {
              definition:
                "A barb or sharp point of an arrow or other instrument, projecting backward to prevent the head from being easily drawn out.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The curved underside of an axehead, extending from the lower end of the cutting edge to the axehandle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That part of the underside of a horse's lower jaw which is above the chin, and bears the curb of a bridle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That part of a type which is between the shoulder of the shank and the face.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A fake customer or companion, especially a woman who accompanies a gay man in order to give the impression that he is heterosexual.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To grow hair on the chin and jaw.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To boldly and bravely oppose or confront, often to the chagrin of the one being bearded.",
              synonyms: [],
              antonyms: [],
              example:
                "Robin Hood is always shown as bearding the Sheriff of Nottingham.",
            },
            {
              definition:
                "To take by the beard; to seize, pluck, or pull the beard of (a man), in anger or contempt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To deprive (an oyster or similar shellfish) of the gills.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of a gay man or woman: to accompany a gay person of the opposite sex in order to give the impression that they are heterosexual.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/beard"],
    },
  ],
  eagle: [
    {
      word: "eagle",
      phonetic: "/ˈiːɡəl/",
      phonetics: [
        {
          text: "/ˈiːɡəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/eagle-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1755145",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of several large carnivorous and carrion-eating birds in the family Accipitridae, having a powerful hooked bill and keen vision.",
              synonyms: ["broadwing", "erne"],
              antonyms: [],
            },
            {
              definition:
                "A gold coin with a face value of ten dollars, formerly used in the United States.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A 13th-century coin minted in Europe and circulated in England as a debased sterling silver penny, outlawed under Edward I.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A score of two under par for a hole.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["broadwing", "erne"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To score an eagle.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/eagle"],
    },
  ],
  creek: [
    {
      word: "creek",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/creek-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75270151",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/kɹiːk/", audio: "" },
        {
          text: "/kɹik/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/creek-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2079678",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small inlet or bay, often saltwater, narrower and extending farther into the land than a cove; a recess in the shore of the sea, or of a river; the inner part of a port that is used as a dock for small boats.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A stream of water (often freshwater) smaller than a river and larger than a brook.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Any turn or winding.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "bayou",
            "branch",
            "brook",
            "run",
            "beck",
            "brook",
            "burn",
            "stream",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/creek"],
    },
  ],
  cries: [
    {
      word: "cries",
      phonetic: "/kɹaɪ̯z/",
      phonetics: [
        {
          text: "/kɹaɪ̯z/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cries-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749773",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A shedding of tears; the act of crying.",
              synonyms: [],
              antonyms: [],
              example:
                "After we broke up, I retreated to my room for a good cry.",
            },
            {
              definition: "A shout or scream.",
              synonyms: [],
              antonyms: [],
              example: "I heard a cry from afar.",
            },
            {
              definition: "Words shouted or screamed.",
              synonyms: [],
              antonyms: [],
              example: "a battle cry",
            },
            { definition: "A clamour or outcry.", synonyms: [], antonyms: [] },
            { definition: "A group of hounds.", synonyms: [], antonyms: [] },
            {
              definition: "(by extension) A pack or company of people.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of an animal) A typical sound made by the species in question.",
              synonyms: [],
              antonyms: [],
              example:
                '"Woof" is the cry of a dog, while "neigh" is the cry of a horse.',
            },
            {
              definition: "A desperate or urgent request.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Common report; gossip.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To shed tears; to weep.",
              synonyms: [],
              antonyms: [],
              example: "That sad movie always makes me cry.",
            },
            {
              definition: "To utter loudly; to call out; to declare publicly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To shout, scream, yell.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To utter inarticulate sounds, as animals do.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause to do something, or bring to some state, by crying or weeping.",
              synonyms: [],
              antonyms: [],
              example: "Tonight I'll cry myself to sleep.",
            },
            {
              definition:
                "To make oral and public proclamation of; to notify or advertise by outcry, especially things lost or found, goods to be sold, etc.",
              synonyms: [],
              antonyms: [],
              example: "to cry goods",
            },
            {
              definition: "Hence, to publish the banns of, as for marriage.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bawl", "blubber", "sob", "wail", "weep", "whimper"],
          antonyms: ["laugh"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cries",
        "https://en.wiktionary.org/wiki/cry",
      ],
    },
  ],
  ashes: [
    {
      word: "ashes",
      phonetic: "/ˈæʃ.əz/",
      phonetics: [
        {
          text: "/ˈæʃ.əz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ashes-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100914",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The solid remains of a fire.",
              synonyms: [],
              antonyms: [],
              example:
                "Ash from a fireplace can restore minerals to your garden's soil.",
            },
            {
              definition:
                "The nonaqueous remains of a material subjected to any complete oxidation process.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Fine particles from a volcano, volcanic ash.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in the plural) Human (or animal) remains after cremation.",
              synonyms: [],
              antonyms: [],
              example:
                "The urn containing his ashes was eventually removed to a closet.",
            },
            {
              definition: "What remains after a catastrophe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A gray colour, like that of ash.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["cremains"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To reduce to a residue of ash. See ashing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To hit the end off of a burning cigar or cigarette.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To hit the end off (a burning cigar or cigarette).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(mostly used in the passive) To cover newly-sown fields of crops with ashes.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A shade tree of the genus Fraxinus.",
              synonyms: [],
              antonyms: [],
              example: "The ash trees are dying off due to emerald ash borer.",
            },
            {
              definition: "The wood of this tree.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The traditional name for the ae ligature (æ), as used in Old English.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["ash tree"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ash",
        "https://en.wiktionary.org/wiki/ashes",
      ],
    },
  ],
  stall: [
    {
      word: "stall",
      phonetic: "/stɔːl/",
      phonetics: [
        {
          text: "/stɔːl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stall-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88671706",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/stɔl/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A compartment for a single animal in a stable or cattle shed.",
              synonyms: ["boose"],
              antonyms: [],
            },
            {
              definition: "A stable; a place for cattle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bench or table on which small articles of merchandise are exposed for sale.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small open-fronted shop, for example in a market, food court, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A very small room used for a shower or a toilet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A seat in a theatre close to and (about) level with the stage; traditionally, a seat with arms, or otherwise partly enclosed, as distinguished from the benches, sofas, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Loss of lift due to an airfoil's critical angle of attack being exceeded.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An Heathen altar, typically an indoor one, as contrasted with a more substantial outdoor harrow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A seat in a church, especially one next to the chancel or choir, reserved for church officials and dignitaries.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A church office that entitles the incumbent to the use of a church stall.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sheath to protect the finger.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The space left by excavation between pillars.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A parking stall; a space for a vehicle in a parking lot or parkade.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["boose"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To put (an animal, etc.) in a stall.",
              synonyms: [],
              antonyms: [],
              example: "to stall an ox",
            },
            {
              definition: "To fatten.",
              synonyms: [],
              antonyms: [],
              example: "to stall cattle",
            },
            {
              definition: "To come to a standstill.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause to stop making progress, to hinder, to slow down, to delay or forestall.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To plunge into mire or snow so as not to be able to get on; to set; to fix.",
              synonyms: [],
              antonyms: [],
              example: "to stall a cart",
            },
            {
              definition: "(of an engine) To stop suddenly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause the engine of a manual-transmission car to stop by going too slowly for the selected gear.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To exceed the critical angle of attack, resulting in loss of lift.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To live in, or as if in, a stall; to dwell.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be stuck, as in mire or snow; to stick fast.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be tired of eating, as cattle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To place in an office with the customary formalities; to install.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To forestall; to anticipate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To keep close; to keep secret.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stall"],
    },
    {
      word: "stall",
      phonetic: "/stɔːl/",
      phonetics: [
        {
          text: "/stɔːl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stall-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88671706",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/stɔl/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An action that is intended to cause or actually causes delay.",
              synonyms: [],
              antonyms: [],
              example:
                "His encounters with security, reception, the secretary, and the assistant were all stalls until the general manager's attorney arrived.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To employ delaying tactics against.",
              synonyms: [],
              antonyms: [],
              example: "He stalled the creditors as long as he could.",
            },
            {
              definition: "To employ delaying tactics.",
              synonyms: [],
              antonyms: [],
              example:
                "Soon it became clear that she was stalling to give him time to get away.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stall"],
    },
  ],
  yield: [
    {
      word: "yield",
      phonetic: "/jiːld/",
      phonetics: [
        {
          text: "/jiːld/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/yield-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=640136",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To pay, give in payment; repay, recompense; reward; requite.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To furnish; to afford; to render; to give forth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To give way; to allow another to pass first.",
              synonyms: [],
              antonyms: [],
              example: "Yield the right of way to pedestrians.",
            },
            {
              definition:
                "To give as required; to surrender, relinquish or capitulate.",
              synonyms: [],
              antonyms: [],
              example: "They refuse to yield to the enemy.",
            },
            {
              definition: "To give, or give forth, (anything).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To give way; to succumb to a force.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To produce as return, as from an investment.",
              synonyms: [],
              antonyms: [],
              example: "Historically, that security yields a high return.",
            },
            {
              definition: "To produce as a result.",
              synonyms: [],
              antonyms: [],
              example: "Adding 3 and 4 yields a result of 7.",
            },
            {
              definition:
                "To produce a particular sound as the result of a sound law.",
              synonyms: [],
              antonyms: [],
              example: "Indo-European p- yields Germanic f-.",
            },
            {
              definition:
                "(of a material specimen) To pass the material's yield point and undergo plastic deformation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To admit to be true; to concede; to allow.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "bear",
            "capitulate",
            "cede",
            "defer",
            "give in",
            "give up",
            "give way",
            "produce",
            "relent",
            "submit",
            "succumb",
            "supply",
            "surrender",
            "trade away",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/yield"],
    },
    {
      word: "yield",
      phonetic: "/jiːld/",
      phonetics: [
        {
          text: "/jiːld/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/yield-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=640136",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Payment; tribute.", synonyms: [], antonyms: [] },
            {
              definition: "A product; the quantity of something produced.",
              synonyms: [],
              antonyms: [],
              example:
                "Zucchini plants always seem to produce a high yield of fruit.",
            },
            {
              definition:
                "The current return as a percentage of the price of a stock or bond.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Profit earned from an investment; return on investment.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["crop", "fruits", "gain", "harvest", "produce", "return"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/yield"],
    },
  ],
  mayor: [
    {
      word: "mayor",
      phonetic: "/ˈmeɪ.ə/",
      phonetics: [
        { text: "/ˈmeɪ.ə/", audio: "" },
        {
          text: "/ˈmeɪ.ɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mayor-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1227655",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The chief executive of the municipal government of a city, borough, &c., formerly usually appointed as a caretaker by European royal courts but now usually appointed or elected locally.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Short for mayor of the palace, the royal stewards of the Frankish Empire.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Synonym of mair, various former officials in the Kingdom of Scotland.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A member of a city council.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A high justice, an important judge.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A largely ceremonial position in some municipal governments that presides over the city council while a contracted city manager holds actual executive power.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A local VIP, a muckamuck or big shot reckoned to lead some local group.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "mayoress",
            "Lord Provost",
            "boroughmaster",
            "burgomaster",
            "praetor",
            "provost",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mayor"],
    },
  ],
  opens: [
    {
      word: "opens",
      phonetic: "/ˈəʊ.pənz/",
      phonetics: [
        { text: "/ˈəʊ.pənz/", audio: "" },
        {
          text: "/ˈoʊ.pənz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/opens-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2454173",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make something accessible or allow for passage by moving from a shut position.",
              synonyms: [],
              antonyms: [],
              example: "Turn the doorknob to open the door.",
            },
            {
              definition:
                "To make (an open space, etc.) by clearing away an obstacle or obstacles, in order to allow for passage, access, or visibility.",
              synonyms: [],
              antonyms: [],
              example: "He opened a path through the undergrowth.",
            },
            {
              definition: "To bring up, broach.",
              synonyms: [],
              antonyms: [],
              example: "I don't want to open that subject.",
            },
            {
              definition: "To enter upon, begin.",
              synonyms: [],
              antonyms: [],
              example: "to open a case in court, or a meeting",
            },
            {
              definition:
                "To spread; to expand into an open or loose position.",
              synonyms: [],
              antonyms: [],
              example: "to open a closed fist",
            },
            {
              definition: "To make accessible to customers or clients.",
              synonyms: [],
              antonyms: [],
              example: "I will open the shop an hour early tomorrow.",
            },
            {
              definition: "To start (a campaign).",
              synonyms: [],
              antonyms: [],
              example: "Vermont will open elk hunting season next week.",
            },
            {
              definition: "To become open.",
              synonyms: [],
              antonyms: [],
              example: "The door opened all by itself.",
            },
            {
              definition: "To begin conducting business.",
              synonyms: [],
              antonyms: [],
              example: "The shop opens at 9:00.",
            },
            {
              definition:
                "To begin a side's innings as one of the first two batsmen.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To bet before any other player has in a particular betting round in a game of poker.",
              synonyms: [],
              antonyms: [],
              example: "After the first two players fold, Julie opens for $5.",
            },
            {
              definition: "To reveal one's hand.",
              synonyms: [],
              antonyms: [],
              example: "Jeff opens his hand revealing a straight flush.",
            },
            {
              definition:
                "(of a file, document, etc.) To load into memory for viewing or editing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To disclose; to reveal; to interpret; to explain.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["raise", "bare", "start", "unseal"],
          antonyms: ["bare", "shut"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(with the) Open or unobstructed space; an exposed location.",
              synonyms: [],
              antonyms: [],
              example:
                "I can't believe you left the lawnmower out in the open when you knew it was going to rain this afternoon!",
            },
            {
              definition: "(with the) Public knowledge or scrutiny; full view.",
              synonyms: [],
              antonyms: [],
              example:
                "We have got to bring this company's corrupt business practices into the open.",
            },
            {
              definition:
                "A defect in an electrical circuit preventing current from flowing.",
              synonyms: [],
              antonyms: [],
              example:
                "The electrician found the open in the circuit after a few minutes of testing.",
            },
            {
              definition: "A sports event in which anybody can compete",
              synonyms: [],
              antonyms: [],
              example: "the Australian Open",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/open",
        "https://en.wiktionary.org/wiki/opens",
      ],
    },
  ],
  input: [
    {
      word: "input",
      phonetic: "/ˈɪnpʊt/",
      phonetics: [
        {
          text: "/ˈɪnpʊt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/input-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80065935",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act or process of putting in; infusion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "That which is put in, as in an amount.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Contribution of work or information, as an opinion or advice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something fed into a process with the intention of it shaping or affecting the output of that process.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To put in; put on.", synonyms: [], antonyms: [] },
            {
              definition: "To enter data.",
              synonyms: [],
              antonyms: [],
              example:
                "The user inputs his date of birth and the computer displays his age.",
            },
            {
              definition: "To accept data that is entered.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/input"],
    },
  ],
  fleet: [
    {
      word: "fleet",
      phonetic: "/fliːt/",
      phonetics: [
        { text: "/fliːt/", audio: "" },
        {
          text: "/flit/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fleet-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1684614",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A group of vessels or vehicles.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any group of associated items.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A large, coordinated group of people.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A number of vessels in company, especially war vessels; also, the collective naval force of a country, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(British Royal Navy) Any command of vessels exceeding a squadron in size, or a rear admiral's command, composed of five sail-of-the-line, with any number of smaller vessels.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fleet"],
    },
    {
      word: "fleet",
      phonetic: "/fliːt/",
      phonetics: [
        { text: "/fliːt/", audio: "" },
        {
          text: "/flit/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fleet-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1684614",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An arm of the sea; a run of water, such as an inlet or a creek.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A location, as on a navigable river, where barges are secured.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fleet"],
    },
    {
      word: "fleet",
      phonetic: "/fliːt/",
      phonetics: [
        { text: "/fliːt/", audio: "" },
        {
          text: "/flit/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fleet-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1684614",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To float.", synonyms: [], antonyms: [] },
            {
              definition: "To pass over rapidly; to skim the surface of.",
              synonyms: [],
              antonyms: [],
              example: "a ship that fleets the gulf",
            },
            {
              definition:
                "To hasten over; to cause to pass away lightly, or in mirth and joy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To flee, to escape, to speed away.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To evanesce, disappear, die out.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To move up a rope, so as to haul to more advantage; especially to draw apart the blocks of a tackle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of people) To move or change in position.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To shift the position of dead-eyes when the shrouds are become too long.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause to slip down the barrel of a capstan or windlass, as a rope or chain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To take the cream from; to skim.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Swift in motion; light and quick in going from place to place.",
              synonyms: ["fast", "nimble"],
              antonyms: [],
            },
            {
              definition:
                "Light; superficially thin; not penetrating deep, as soil.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["fast", "nimble"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fleet"],
    },
    {
      word: "fleet",
      phonetic: "/fliːt/",
      phonetics: [
        { text: "/fliːt/", audio: "" },
        {
          text: "/flit/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fleet-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1684614",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Floor; bottom; lower surface.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A house; home.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fleet",
        "https://en.wiktionary.org/wiki/flet",
      ],
    },
  ],
  tooth: [
    {
      word: "tooth",
      phonetic: "/tuːθ/",
      phonetics: [
        {
          text: "/tuːθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tooth-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9028868",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/tuːθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tooth-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1197443",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
        { text: "/tʊθ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A hard, calcareous structure present in the mouth of many vertebrate animals, generally used for eating.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sharp projection on the blade of a saw or similar implement.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A projection on the edge of a gear that meshes with similar projections on adjacent gears, or on the circumference of a cog that engages with a chain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A projection or point in other parts of the body resembling the tooth of a vertebrate animal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A pointed projection from the margin of a leaf.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The rough surface of some kinds of cel or other films that allows better adhesion of artwork.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Liking, fondness (compare toothsome).",
              synonyms: ["appetite", "fondness", "palate", "taste"],
              antonyms: [],
              example: "I have a sweet tooth: I love sugary treats.",
            },
            {
              definition:
                "An irreducible component of a comb that intersects the handle in exactly one point, that point being distinct from the unique point of intersection for any other tooth of the comb.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["appetite", "fondness", "palate", "taste"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To provide or furnish with teeth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To indent; to jag.",
              synonyms: [],
              antonyms: [],
              example: "to tooth a saw",
            },
            {
              definition: "To lock into each other, like gear wheels.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tooth"],
    },
  ],
  cubic: [
    {
      word: "cubic",
      phonetic: "/ˈkjuː.bɪk/",
      phonetics: [
        { text: "/ˈkjuː.bɪk/", audio: "" },
        {
          text: "/ˈkju.bɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cubic-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=67258930",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A cubic curve.",
              synonyms: ["cubic curve"],
              antonyms: [],
            },
          ],
          synonyms: ["cubic curve"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Used in the names of units of volume formed by multiplying a unit of length by itself twice.",
              synonyms: [],
              antonyms: [],
              example: "cubic foot",
            },
            {
              definition:
                "Of a class of polynomial of the form ax^3 + bx^2 + cx + d",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Having three equal axes and all angles 90°.",
              synonyms: ["isometric", "monometric"],
              antonyms: [],
              example: "cubic cleavage",
            },
          ],
          synonyms: ["isometric", "monometric"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cubic"],
    },
  ],
  wives: [
    {
      word: "wives",
      phonetic: "/waɪvz/",
      phonetics: [
        {
          text: "/waɪvz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wives-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=268362",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A married woman, especially in relation to her spouse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The female of a pair of mated animals.",
              synonyms: [],
              antonyms: [],
              example: "A new wife for the gander is introduced into the pen.",
            },
          ],
          synonyms: ["little woman"],
          antonyms: ["husband", "were"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An adult female human.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(collective) All females collectively; womankind.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A female person, usually an adult; a (generally adult) female sentient being, whether human, supernatural, elf, alien, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A wife (or sometimes a fiancée or girlfriend).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A female who is extremely fond of or devoted to a specified type of thing. (Used as the last element of a compound.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A female attendant or servant.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["female", "lady"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wife",
        "https://en.wiktionary.org/wiki/wives",
        "https://en.wiktionary.org/wiki/woman",
      ],
    },
  ],
  burns: [
    {
      word: "burns",
      phonetic: "/bɜːnz/",
      phonetics: [
        { text: "/bɜːnz/", audio: "" },
        { text: "/bɝnz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A physical injury caused by heat, cold, electricity, radiation or caustic chemicals.",
              synonyms: [],
              antonyms: [],
              example:
                "She had second-degree burns from falling in the bonfire.",
            },
            {
              definition: "A sensation resembling such an injury.",
              synonyms: [],
              antonyms: [],
              example: "chili burn from eating hot peppers",
            },
            {
              definition: "The act of burning something with fire.",
              synonyms: [],
              antonyms: [],
              example: "They're doing a controlled burn of the fields.",
            },
            {
              definition:
                "An intense non-physical sting, as left by shame or an effective insult.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An effective insult, often in the expression sick burn (excellent or badass insult).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Physical sensation in the muscles following strenuous exercise, caused by build-up of lactic acid.",
              synonyms: [],
              antonyms: [],
              example: "One and, two and, keep moving; feel the burn!",
            },
            { definition: "Tobacco.", synonyms: [], antonyms: [] },
            {
              definition:
                "The writing of data to a permanent storage medium like a compact disc or a ROM chip.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The operation or result of burning or baking, as in brickmaking.",
              synonyms: [],
              antonyms: [],
              example: "They have a good burn.",
            },
            {
              definition: "A disease in vegetables; brand.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cause to be consumed by fire.",
              synonyms: [],
              antonyms: [],
              example: "He burned his manuscript in the fireplace.",
            },
            {
              definition: "To be consumed by fire, or in flames.",
              synonyms: [],
              antonyms: [],
              example: "He watched the house burn.",
            },
            {
              definition: "To overheat so as to make unusable.",
              synonyms: [],
              antonyms: [],
              example: "He burned the toast. The blacksmith burned the steel.",
            },
            {
              definition:
                "To become overheated to the point of being unusable.",
              synonyms: [],
              antonyms: [],
              example: "The grill was too hot and the steak burned.",
            },
            {
              definition:
                "To make or produce by the application of fire or burning heat.",
              synonyms: [],
              antonyms: [],
              example: "to burn a hole;  to burn letters into a block",
            },
            {
              definition:
                "To injure (a person or animal) with heat or chemicals that produce similar damage.",
              synonyms: [],
              antonyms: [],
              example:
                "She burned the child with an iron, and was jailed for ten years.",
            },
            { definition: "To cauterize.", synonyms: [], antonyms: [] },
            {
              definition: "To sunburn.",
              synonyms: [],
              antonyms: [],
              example: "She forgot to put on sunscreen and burned.",
            },
            {
              definition:
                "To consume, injure, or change the condition of, as if by action of fire or heat; to affect as fire or heat does.",
              synonyms: [],
              antonyms: [],
              example: "to burn the mouth with pepper",
            },
            {
              definition: "To be hot, e.g. due to embarrassment.",
              synonyms: [],
              antonyms: [],
              example:
                "The child's forehead was burning with fever.  Her cheeks burned with shame.",
            },
            {
              definition:
                "To cause to combine with oxygen or other active agent, with evolution of heat; to consume; to oxidize.",
              synonyms: [],
              antonyms: [],
              example:
                "A human being burns a certain amount of carbon at each respiration.  to burn iron in oxygen",
            },
            {
              definition: "To combine energetically, with evolution of heat.",
              synonyms: [],
              antonyms: [],
              example: "Copper burns in chlorine.",
            },
            {
              definition:
                "To write data to a permanent storage medium like a compact disc or a ROM chip.",
              synonyms: [],
              antonyms: [],
              example:
                "We’ll burn this program onto an EEPROM one hour before the demo begins.",
            },
            {
              definition: "To betray.",
              synonyms: [],
              antonyms: [],
              example: "The informant burned him.",
            },
            {
              definition: "To insult or defeat.",
              synonyms: [],
              antonyms: [],
              example: "I just burned you again.",
            },
            {
              definition: "To waste (time); to waste money or other resources.",
              synonyms: [],
              antonyms: [],
              example:
                "The company has burned more than a million dollars a month this year.",
            },
            {
              definition:
                "In certain games, to approach near to a concealed object which is sought.",
              synonyms: [],
              antonyms: [],
              example: "You're cold... warm... hot... you're burning!",
            },
            {
              definition: "To accidentally touch a moving stone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In pontoon, to swap a pair of cards for another pair, or to deal a dead card.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To increase the exposure for certain areas of a print in order to make them lighter (compare dodge).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of an element) To be converted to another element in a nuclear fusion reaction, especially in a star",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To discard.", synonyms: [], antonyms: [] },
            {
              definition: "To shoot someone with a firearm.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A stream.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/burn",
        "https://en.wiktionary.org/wiki/burns",
      ],
    },
  ],
  poets: [
    {
      word: "poets",
      phonetic: "/ˈpəʊ.ɪts/",
      phonetics: [
        { text: "/ˈpəʊ.ɪts/", audio: "" },
        {
          text: "/ˈpoʊ.ɪts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/poets-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2079733",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A person who writes poems.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person with a creative or romantic imagination.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/poet",
        "https://en.wiktionary.org/wiki/poets",
      ],
    },
  ],
  apron: [
    {
      word: "apron",
      phonetic: "/ˈeɪ.pɹən/",
      phonetics: [
        { text: "/ˈeɪ.pɹən/", audio: "" },
        {
          text: "/ˈeɪ.pɹən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/apron-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1317627",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An article of clothing worn over the front of the torso and/or legs for protection from spills; also historically worn by Freemasons and as part of women's fashion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The short cassock ordinarily worn by English bishops.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A hard surface bordering a structure or area.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The sides of a tree's canopy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The cap of a cannon; a piece of lead laid over the vent to keep the priming dry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A removable cover for the passengers' feet and legs in an open horse carriage.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cover with, or as if with, an apron.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/apron"],
    },
  ],
  spear: [
    {
      word: "spear",
      phonetic: "/spɪɹ/",
      phonetics: [
        { text: "/spɪɹ/", audio: "" },
        { text: "/spɪə̯(ɹ)/", audio: "" },
        {
          text: "/spɪɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spear-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2707604",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A long stick with a sharp tip used as a weapon for throwing or thrusting, or anything used to make a thrusting motion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A soldier armed with such a weapon; a spearman.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A lance with barbed prongs, used by fishermen to retrieve fish.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An illegal maneuver using the end of a hockey stick to strike into another hockey player.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In professional wrestling, a running tackle in which the wrestler's shoulder is driven into the opponent's midsection.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A shoot, as of grass; a spire.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The feather of a horse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The rod to which the bucket, or plunger, of a pump is attached; a pump rod.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A long, thin strip from a vegetable.",
              synonyms: [],
              antonyms: [],
              example: "asparagus and broccoli spears",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To pierce with a spear.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) To penetrate or strike with, or as if with, any long narrow object; to make a thrusting motion that catches an object on the tip of a long device.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To shoot into a long stem, as some plants do.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Male.",
              synonyms: [],
              antonyms: [],
              example: "a spear counterpart",
            },
            {
              definition: "Pertaining to male family members.",
              synonyms: [],
              antonyms: [],
              example: "the spear side of the family",
            },
          ],
          synonyms: [],
          antonyms: ["distaff"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spear"],
    },
  ],
  organ: [
    {
      word: "organ",
      phonetic: "/ˈɔː.ɡən/",
      phonetics: [
        { text: "/ˈɔː.ɡən/", audio: "" },
        {
          text: "/ˈɔɹ.ɡən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/organ-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1512015",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A larger part of an organism, composed of tissues that perform similar functions.",
              synonyms: [],
              antonyms: [],
              example: "bodily organs",
            },
            {
              definition:
                "(by extension) A body of an organization dedicated to the performing of certain functions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A musical instrument that has multiple pipes which play when a key is pressed (the pipe organ), or an electronic instrument designed to replicate such.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An official magazine, newsletter, or similar publication of an organization.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Short for organ pipe cactus.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The penis.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To supply with an organ or organs; to fit with organs.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/organ"],
    },
  ],
  cliff: [
    {
      word: "cliff",
      phonetic: "/klɪf/",
      phonetics: [
        {
          text: "/klɪf/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cliff-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769347",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A vertical (or nearly vertical) rock face.",
              synonyms: ["precipice"],
              antonyms: [],
            },
            {
              definition:
                "A point where something abruptly fails or decreases in value etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["precipice"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cliff"],
    },
    {
      word: "cliff",
      phonetic: "/klɪf/",
      phonetics: [
        {
          text: "/klɪf/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cliff-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769347",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A symbol found on a musical staff that indicates the pitches represented by the lines and the spaces on the staff",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/clef",
        "https://en.wiktionary.org/wiki/cliff",
      ],
    },
  ],
  stamp: [
    {
      word: "stamp",
      phonetic: "/stæmp/",
      phonetics: [
        {
          text: "/stæmp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stamp-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9027488",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/stæmp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stamp-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1158028",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An act of stamping the foot, paw or hoof.",
              synonyms: [],
              antonyms: [],
              example:
                "The horse gave two quick stamps and rose up on its hind legs.",
            },
            {
              definition: "An indentation, imprint, or mark made by stamping.",
              synonyms: [],
              antonyms: [],
              example: "My passport has quite a collection of stamps.",
            },
            {
              definition: "A device for stamping designs.",
              synonyms: [],
              antonyms: [],
              example:
                "She loved to make designs with her collection of stamps.",
            },
            {
              definition:
                "A small piece of paper bearing a design on one side and adhesive on the other, used to decorate letters or craft work.",
              synonyms: [],
              antonyms: [],
              example: "These stamps have a Christmas theme.",
            },
            {
              definition:
                "A small piece of paper, with a design and a face value, used to prepay postage or other costs such as tax or licence fees.",
              synonyms: [],
              antonyms: [],
              example: "I need one first-class stamp to send this letter.",
            },
            { definition: "A tattoo.", synonyms: [], antonyms: [] },
            {
              definition: "A single dose of lysergic acid diethylamide.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A kind of heavy pestle, raised by water or steam power, for crushing ores.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Cast; form; character; distinguishing mark or sign; evidence.",
              synonyms: [],
              antonyms: [],
              example: "the stamp of criminality",
            },
          ],
          synonyms: ["postage stamp", "revenue stamp", "tax stamp"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To step quickly and heavily, once or repeatedly.",
              synonyms: [],
              antonyms: [],
              example:
                "The toddler screamed and stamped, but still got no candy.",
            },
            {
              definition:
                "To move (the foot or feet) quickly and heavily, once or repeatedly.",
              synonyms: [],
              antonyms: [],
              example:
                "The crowd cheered and stamped their feet in appreciation.",
            },
            {
              definition:
                "To strike, beat, or press forcibly with the bottom of the foot, or by thrusting the foot downward.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To mark by pressing quickly and heavily.",
              synonyms: [],
              antonyms: [],
              example: "This machine stamps the design into the metal cover.",
            },
            {
              definition:
                "To give an official marking to, generally by impressing or imprinting a design or symbol.",
              synonyms: [],
              antonyms: [],
              example: "The immigration officer stamped my passport.",
            },
            {
              definition: "To apply postage stamps to.",
              synonyms: [],
              antonyms: [],
              example: "I forgot to stamp this letter.",
            },
            { definition: "To mark; to impress.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["impress", "imprint", "dent", "emboss"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stamp"],
    },
  ],
  paste: [
    {
      word: "paste",
      phonetic: "/peɪst/",
      phonetics: [
        {
          text: "/peɪst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/paste-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1113778",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A soft moist mixture, in particular:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A substance that behaves as a solid until a sufficiently large load or stress is applied, at which point it flows like a fluid",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hard lead-containing glass, or an artificial gemstone made from this glass.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Pasta.", synonyms: [], antonyms: [] },
            {
              definition:
                "The mineral substance in which other minerals are embedded.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To stick with paste; to cause to adhere by or as if by paste.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To insert a piece of media (e.g. text, picture, audio, video) previously copied or cut from somewhere else.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To strike or beat someone or something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To defeat decisively or by a large margin.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/paste"],
    },
  ],
  rural: [
    {
      word: "rural",
      phonetic: "/ˈɹɔːɹəl/",
      phonetics: [
        {
          text: "/ˈɹɔːɹəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rural-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=83004917",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈɹɝəl/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A person from the countryside; a rustic.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Relating to the countryside or to agriculture.",
              synonyms: [],
              antonyms: ["suburban", "urban"],
            },
          ],
          synonyms: ["campestral", "landly"],
          antonyms: ["suburban", "urban"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rural"],
    },
  ],
  baked: [
    {
      word: "baked",
      phonetic: "/beɪkt/",
      phonetics: [
        {
          text: "/beɪkt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/baked-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79240979",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(with person as subject) To cook (something) in an oven.",
              synonyms: [],
              antonyms: [],
              example: "I baked a delicious cherry pie.",
            },
            {
              definition:
                "(with baked thing as subject) To be cooked in an oven.",
              synonyms: [],
              antonyms: [],
              example: "The cake baked at 350°F.",
            },
            {
              definition: "To be warmed to drying and hardening.",
              synonyms: [],
              antonyms: [],
              example: "The clay baked in the sun.",
            },
            {
              definition: "To dry by heat.",
              synonyms: [],
              antonyms: [],
              example:
                "They baked the electrical parts lightly to remove moisture.",
            },
            {
              definition: "To be hot.",
              synonyms: [],
              antonyms: [],
              example: "I'm baking after that workout in the gym.",
            },
            { definition: "To cause to be hot.", synonyms: [], antonyms: [] },
            { definition: "To smoke marijuana.", synonyms: [], antonyms: [] },
            { definition: "To harden by cold.", synonyms: [], antonyms: [] },
            {
              definition:
                "To fix (lighting, reflections, etc.) as part of the texture of an object to improve rendering performance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                '(with "in" or "into") To incorporate into something greater.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "That has been cooked by baking.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "High on cannabis.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bake",
        "https://en.wiktionary.org/wiki/baked",
      ],
    },
  ],
  chase: [
    {
      word: "chase",
      phonetic: "/tʃeɪs/",
      phonetics: [
        {
          text: "/tʃeɪs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chase-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899822",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/tʃeɪs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chase-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769627",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act of one who chases another; a pursuit.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A hunt.", synonyms: [], antonyms: [] },
            {
              definition: "A children's game where one player chases another.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A large country estate where game may be shot or hunted.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Anything being chased, especially a vessel in time of war.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A wild animal that is hunted.",
              synonyms: ["game"],
              antonyms: [],
            },
            {
              definition:
                "Any of the guns that fire directly ahead or astern; either a bow chase or stern chase.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                '(real tennis) The occurrence of a second bounce by the ball in certain areas of the court, giving the server the chance, later in the game, to "play off" the chase from the receiving end and possibly win the point.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(real tennis) A division of the floor of a gallery, marked by a figure or otherwise; the spot where a ball falls, and between which and the dedans the adversary must drive the ball in order to gain a point.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One or more riders who are ahead of the peloton and trying to join the race or stage leaders.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["game"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To pursue.", synonyms: [], antonyms: [] },
            {
              definition:
                "To consume another beverage immediately after drinking hard liquor, typically something better tasting or less harsh such as soda or beer; to use a drink as a chaser",
              synonyms: [],
              antonyms: [],
              example: "I need something to chase this shot with.",
            },
            {
              definition:
                "To attempt to win by scoring the required number of runs in the final innings.",
              synonyms: [],
              antonyms: [],
              example:
                "Australia will be chasing 217 for victory on the final day.",
            },
            {
              definition:
                "To swing at a pitch outside of the strike zone, typically an outside pitch",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To produce enough offense to cause the pitcher to be removed",
              synonyms: [],
              antonyms: [],
              example: "The rally chased the starter.",
            },
          ],
          synonyms: ["pursue"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chase"],
    },
    {
      word: "chase",
      phonetic: "/tʃeɪs/",
      phonetics: [
        {
          text: "/tʃeɪs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chase-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899822",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/tʃeɪs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chase-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769627",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A rectangular steel or iron frame into which pages or columns of type are locked for printing or plate-making.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chase"],
    },
    {
      word: "chase",
      phonetic: "/tʃeɪs/",
      phonetics: [
        {
          text: "/tʃeɪs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chase-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899822",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/tʃeɪs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chase-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769627",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A groove cut in an object; a slot: the chase for the quarrel on a crossbow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A trench or channel or other encasement structure for encasing (archaically spelled enchasing) drainpipes or wiring; a hollow space in the wall of a building encasing ventilation ducts, chimney flues, wires, cables or plumbing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The part of a gun in front of the trunnions.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The cavity of a mold.", synonyms: [], antonyms: [] },
            {
              definition:
                "A kind of joint by which an overlap joint is changed to a flush joint by means of a gradually deepening rabbet, as at the ends of clinker-built boats.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To groove; indent.", synonyms: [], antonyms: [] },
            {
              definition:
                "To place piping or wiring in a groove encased within a wall or floor, or in a hidden space encased by a wall.",
              synonyms: [],
              antonyms: [],
              example: "chase the pipe",
            },
            {
              definition: "To cut (the thread of a screw).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To decorate (metal) by engraving or embossing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chase"],
    },
  ],
  slice: [
    {
      word: "slice",
      phonetic: "/slaɪs/",
      phonetics: [
        {
          text: "/slaɪs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/slice-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75650008",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/slaɪs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/slice-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=186386",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "That which is thin and broad.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A thin, broad piece cut off.",
              synonyms: [],
              antonyms: [],
              example: "Jim was munching on a slice of toast.",
            },
            {
              definition: "An amount of anything.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A piece of pizza.", synonyms: [], antonyms: [] },
            {
              definition: "A snack consisting of pastry with savoury filling.",
              synonyms: [],
              antonyms: [],
              example:
                "I bought a ham and cheese slice at the service station.",
            },
            {
              definition: "A broad, thin piece of plaster.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A knife with a thin, broad blade for taking up or serving fish; also, a spatula for spreading anything, as paint or ink.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A salver, platter, or tray.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A plate of iron with a handle, forming a kind of chisel, or a spadelike implement, variously proportioned, and used for various purposes, as for stripping the planking from a vessel's side, for cutting blubber from a whale, or for stirring a fire of coals; a slice bar; a peel; a fire shovel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the wedges by which the cradle and the ship are lifted clear of the building blocks to prepare for launching.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A removable sliding bottom to a galley.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A shot that (for the right-handed player) curves unintentionally to the right. See fade, hook, draw",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of a class of heavy cakes or desserts made in a tray and cut out into squarish slices.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A section of image taken of an internal organ using MRI (magnetic resonance imaging), CT (computed tomography), or various forms of x-ray.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hawk's or falcon's dropping which squirts at an angle other than vertical. (See mute.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A contiguous portion of an array.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cut into slices.",
              synonyms: [],
              antonyms: [],
              example: "Slice the cheese thinly.",
            },
            {
              definition: "To cut with an edge utilizing a drawing motion.",
              synonyms: [],
              antonyms: [],
              example: "The knife left sliced his arm.",
            },
            {
              definition:
                "To clear (e.g. a fire, or the grate bars of a furnace) by means of a slice bar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To hit the shuttlecock with the racket at an angle, causing it to move sideways and downwards.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To hit a shot that slices (travels from left to right for a right-handed player).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To angle the blade so that it goes too deeply into the water when starting to take a stroke.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To kick the ball so that it goes in an unintended direction, at too great an angle or too high.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To hit the ball with a stroke that causes a spin, resulting in the ball swerving or staying low after a bounce.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having the properties of a slice knot.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/slice"],
    },
  ],
  slant: [
    {
      word: "slant",
      phonetic: "/ˈslænt/",
      phonetics: [
        {
          text: "/ˈslænt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/slant-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75649994",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈslænt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/slant-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=554127",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A slope; an incline, inclination.",
              synonyms: [],
              antonyms: [],
              example:
                "The house was built on a bit of a slant and was never quite level.",
            },
            {
              definition: "A sloped surface or line.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A run: a heading driven diagonally between the dip and strike of a coal seam.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An oblique movement or course.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sloping surface in a culture medium.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A pan with a sloped bottom used for holding paintbrushes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A container or surface bearing shallow sloping areas to hold watercolors.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sarcastic remark; shade, an indirect mocking insult.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An opportunity, particularly to go somewhere.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A crime committed for the purpose of being apprehended and transported to a major settlement.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(originally United States) A point of view, an angle; a bias.",
              synonyms: [],
              antonyms: [],
              example:
                "It was a well written article, but it had a bit of a leftist slant.",
            },
            { definition: "A look, a glance.", synonyms: [], antonyms: [] },
            {
              definition:
                "A person with slanting eyes, particularly an East Asian.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["slash"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To lean, tilt or incline.",
              synonyms: [],
              antonyms: [],
              example:
                "If you slant the track a little more, the marble will roll down it faster.",
            },
            {
              definition: "To bias or skew.",
              synonyms: [],
              antonyms: [],
              example:
                "The group tends to slant its policies in favor of the big businesses it serves.",
            },
            { definition: "To lie or exaggerate.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Sloping; oblique; slanted.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A slashing action or motion, particularly:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A mark made by a slashing motion, particularly:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Something resembling such a mark, particularly:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The loose woody debris remaining from a slash, (particularly forestry) the trimmings left while preparing felled trees for removal.",
              synonyms: [],
              antonyms: [],
              example:
                "Slash generated during logging may constitute a fire hazard.",
            },
            { definition: "Slash fiction.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "gash",
            "bar",
            "diagonal",
            "diagonal mark",
            "foreslash",
            "forward slash",
            "forward stroke",
            "front slash",
            "frontslash",
            "oblique",
            "oblique dash",
            "oblique mark",
            "oblique stroke",
            "scratch comma",
            "separatrix",
            "shilling mark",
            "slant",
            "slant line",
            "slash mark",
            "solidus",
            "stroke",
            "virgil",
            "virgula",
            "virgule",
            "whack",
            "cunt",
          ],
          antonyms: ["backslash"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A drink of something; a draft.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A piss: an act of urination.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A swampy area; a swamp.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A large quantity of watery food such as broth.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The period of a transitory breeze.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An interval of good weather.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The loose part of a rope; slack.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/slant",
        "https://en.wiktionary.org/wiki/slash",
        "https://en.wiktionary.org/wiki/slatch",
      ],
    },
  ],
  knock: [
    {
      word: "knock",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/knock-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899799",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/nɒk/", audio: "" },
        {
          text: "/nɑk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/knock-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=252403",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Sudden fatigue as a result of glycogen depletion from not having taken in enough nutrition.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An abrupt rapping sound, as from an impact of a hard object against wood.",
              synonyms: [],
              antonyms: [],
              example: "I heard a knock on my door.",
            },
            {
              definition: "A sharp impact.",
              synonyms: [],
              antonyms: [],
              example: "He took a knock on the head.",
            },
            { definition: "Criticism.", synonyms: [], antonyms: [] },
            {
              definition:
                "Preignition, a type of abnormal combustion occurring in spark ignition engines caused by self-ignition; also, the characteristic knocking sound associated with it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A batsman's innings.",
              synonyms: [],
              antonyms: [],
              example: "He played a slow but sure knock of 35.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To strike for admittance; to rap upon, as a door.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To criticize verbally; to denigrate; to undervalue.",
              synonyms: [],
              antonyms: [],
              example: "Don’t knock it until you’ve tried it.",
            },
            {
              definition: "To kick a ball towards another player; to pass.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To impress forcibly or strongly; to astonish; to move to admiration or applause.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To bump or impact.",
              synonyms: [],
              antonyms: [],
              example: "I accidentally knocked my drink off the bar.",
            },
            {
              definition:
                "To rap one's knuckles against something, especially wood.",
              synonyms: [],
              antonyms: [],
              example: "Knock on the door and find out if they’re home.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hunger%20knock",
        "https://en.wiktionary.org/wiki/knock",
      ],
    },
  ],
  noisy: [
    {
      word: "noisy",
      phonetic: "/ˈnɔːɪzɪ/",
      phonetics: [
        { text: "/ˈnɔːɪzɪ/", audio: "" },
        { text: "/ˈnɔɪzi/", audio: "" },
        {
          text: "/ˈnɔɪzi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/noisy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651220",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Making a noise, especially a loud unpleasant sound",
              synonyms: ["boisterous", "clamorous", "turbulent", "vociferous"],
              antonyms: [],
              example: "the noisy crowd.",
            },
            {
              definition: "Full of noise.",
              synonyms: [],
              antonyms: [],
              example: "a noisy bar",
            },
            {
              definition: "Unpleasant-looking and causing unwanted attention",
              synonyms: [],
              antonyms: [],
              example: "noisy clothes",
            },
          ],
          synonyms: ["boisterous", "clamorous", "turbulent", "vociferous"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/noisy"],
    },
  ],
  sorts: [
    {
      word: "sorts",
      phonetic: "/sɔːts/",
      phonetics: [
        { text: "/sɔːts/", audio: "" },
        {
          text: "/sɔɹts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sorts-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749997",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A general type.", synonyms: [], antonyms: [] },
            {
              definition: "Manner; form of being or acting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Condition above the vulgar; rank.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person evaluated in a certain way (bad, good, strange, etc.).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Group, company.", synonyms: [], antonyms: [] },
            { definition: "A good-looking woman.", synonyms: [], antonyms: [] },
            {
              definition: "An act of sorting.",
              synonyms: [],
              antonyms: [],
              example: "I had a sort of my cupboard.",
            },
            {
              definition:
                "An algorithm for sorting a list of items into a particular sequence.",
              synonyms: [],
              antonyms: [],
              example:
                "Popular algorithms for sorts include quicksort and heapsort.",
            },
            {
              definition:
                "A piece of metal type used to print one letter, character, or symbol in a particular size and style.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A type.", synonyms: [], antonyms: [] },
            { definition: "Chance; lot; destiny.", synonyms: [], antonyms: [] },
            {
              definition:
                "A full set of anything, such as a pair of shoes, or a suit of clothes.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "sort-out",
            "algorithm",
            "character",
            "individual",
            "person",
            "type",
            "genre",
            "genus",
            "kind",
            "type",
            "variety",
            "glyph",
            "type",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To separate items into different categories according to certain criteria that determine their sorts.",
              synonyms: ["categorize", "class", "classify", "group"],
              antonyms: [],
              example:
                "Sort the letters in those bags into a separate pile for each language.",
            },
            {
              definition:
                "To arrange into some sequence, usually numerically, alphabetically or chronologically.",
              synonyms: ["order", "rank"],
              antonyms: [],
              example:
                "Sort those bells into a row in ascending sequence of pitch.",
            },
            {
              definition:
                "To conjoin; to put together in distribution; to class.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To conform; to adapt; to accommodate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To choose from a number; to select; to cull.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To join or associate with others, especially with others of the same kind or species; to agree.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To suit; to fit; to be in accord; to harmonize.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fix (a problem) or handle (a task).",
              synonyms: ["sort out"],
              antonyms: [],
            },
            {
              definition: "To attack physically.",
              synonyms: ["sort out"],
              antonyms: [],
              example: "If he comes nosing around here again I'll sort him!",
            },
          ],
          synonyms: [
            "categorize",
            "class",
            "classify",
            "group",
            "order",
            "rank",
            "sort out",
            "sort out",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sort",
        "https://en.wiktionary.org/wiki/sorts",
      ],
    },
  ],
  stays: [
    {
      word: "stays",
      phonetic: "/steɪz/",
      phonetics: [
        {
          text: "/steɪz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stays-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80067088",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Continuance or a period of time spent in a place; abode for an indefinite time; sojourn.",
              synonyms: [],
              antonyms: [],
              example: "I hope you enjoyed your stay in Hawaii.",
            },
            {
              definition:
                "A postponement, especially of an execution or other punishment.",
              synonyms: [],
              antonyms: [],
              example: "The governor granted a stay of execution.",
            },
            {
              definition:
                "A stop; a halt; a break or cessation of action, motion, or progress.",
              synonyms: [],
              antonyms: [],
              example: "stand at a stay",
            },
            {
              definition: "A fixed state; fixedness; stability; permanence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A station or fixed anchorage for vessels.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Restraint of passion; prudence; moderation; caution; steadiness; sobriety.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Hindrance; let; check.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To prop; support; sustain; hold up; steady.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To support from sinking; to sustain with strength; to satisfy in part or for the time.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To stop; detain; keep back; delay; hinder.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To restrain; withhold; check; stop.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause to cease; to put an end to.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To put off; defer; postpone; delay; keep back.",
              synonyms: [],
              antonyms: [],
              example:
                "The governor stayed the execution until the appeal could be heard.",
            },
            {
              definition: "To hold the attention of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To bear up under; to endure; to hold out against; to resist.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To wait for; await.", synonyms: [], antonyms: [] },
            {
              definition:
                "To remain for the purpose of; to stay to take part in or be present at (a meal, ceremony etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To rest; depend; rely.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To stop; come to a stand or standstill.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To come to an end; cease.",
              synonyms: [],
              antonyms: [],
              example: "That day the storm stayed.",
            },
            {
              definition: "To dwell; linger; tarry; wait.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make a stand; to stand firm.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To hold out, as in a race or contest; last or persevere to the end.",
              synonyms: [],
              antonyms: [],
              example: "That horse stays well.",
            },
            {
              definition:
                "To remain in a particular place, especially for a definite or short period of time; sojourn; abide.",
              synonyms: [],
              antonyms: [],
              example:
                "We stayed in Hawaii for a week.  I can only stay for an hour.",
            },
            {
              definition: "To wait; rest in patience or expectation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(used with on or upon) To wait as an attendant; give ceremonious or submissive attendance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To continue to have a particular quality.",
              synonyms: [],
              antonyms: [],
              example: "Wear gloves so your hands stay warm.",
            },
            {
              definition: "To live; reside",
              synonyms: [],
              antonyms: [],
              example: "Hey, where do you stay at?",
            },
          ],
          synonyms: [
            "endure",
            "resist",
            "cancel",
            "cease",
            "discontinue",
            "halt",
            "stop",
            "terminate",
            "blin",
            "brake",
            "desist",
            "halt",
            "stop",
            "cease",
            "continue",
            "keep",
            "remain",
            "break a lance",
            "contend",
            "stand firm",
            "take a stand",
            "bear",
            "prop up",
            "uphold",
            "abide",
            "sojourn",
            "wait",
            "curb",
            "repress",
            "stifle",
            "attend",
            "bestand",
            "serve",
            "await",
            "wait for",
            "wait on",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A prop; a support.", synonyms: [], antonyms: [] },
            {
              definition:
                "A piece of stiff material, such as plastic or whalebone, used to stiffen a piece of clothing.",
              synonyms: [],
              antonyms: [],
              example: "Where are the stays for my collar?",
            },
            {
              definition: "(in the plural) A corset.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A fastening for a garment; a hook; a clasp; anything to hang another thing on.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A strong rope or wire supporting a mast, and leading from one masthead down to some other, or other part of the vessel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A guy, rope, or wire supporting or stabilizing a platform, such as a bridge, a pole, such as a tentpole, the mast of a derrick, or other structural element.",
              synonyms: [],
              antonyms: [],
              example:
                "The engineer insisted on using stays for the scaffolding.",
            },
            {
              definition: "The transverse piece in a chain-cable link.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["mastrope"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To brace or support with a stay or stays",
              synonyms: [],
              antonyms: [],
              example: "stay a mast",
            },
            {
              definition:
                "To incline forward, aft, or to one side by means of stays.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To tack; put on the other tack.",
              synonyms: [],
              antonyms: [],
              example: "to stay ship",
            },
            {
              definition: "To change; tack; go about; be in stays, as a ship.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A corset.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/stay",
        "https://en.wiktionary.org/wiki/stays",
      ],
    },
  ],
  wiped: [
    {
      word: "wiped",
      phonetic: "/waɪpt/",
      phonetics: [
        {
          text: "/waɪpt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wiped-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651718",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To move an object over, maintaining contact, with the intention of removing some substance from the surface. (Compare rub.)",
              synonyms: [],
              antonyms: [],
              example:
                "I wiped the sweat from my brow with the back of my hand.",
            },
            {
              definition:
                "To remove by rubbing; to rub off; to obliterate; usually followed by away, off, or out.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cheat; to defraud; to trick; usually followed by out.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To erase.",
              synonyms: [],
              antonyms: [],
              example: "I accidentally wiped my hard drive.",
            },
            {
              definition:
                "To make (a joint, as between pieces of lead pipe), by surrounding the junction with a mass of solder, applied in a plastic condition by means of a rag with which the solder is shaped by rubbing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To remove an expression from one's face.",
              synonyms: [],
              antonyms: [],
              example:
                "You should wipe that smirk off your face before the boss comes in.",
            },
            { definition: "To deperm (a ship).", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To have all members of a party die in a single campaign, event, or battle; to be wiped out.",
              synonyms: [],
              antonyms: [],
              example:
                "If you try to fight that boss underprepared, you're definitely gonna wipe.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wipe",
        "https://en.wiktionary.org/wiki/wiped",
      ],
    },
  ],
  blown: [
    {
      word: "blown",
      phonetic: "/bləʊn/",
      phonetics: [
        { text: "/bləʊn/", audio: "" },
        {
          text: "/bloʊn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/blown-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=659587",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To produce an air current.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To propel by an air current.",
              synonyms: [],
              antonyms: [],
              example: "Blow the dust off that book and open it up.",
            },
            {
              definition: "To be propelled by an air current.",
              synonyms: [],
              antonyms: [],
              example: "The leaves blow through the streets in the fall.",
            },
            {
              definition:
                "To create or shape by blowing; as in to blow bubbles, to blow glass.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To force a current of air upon with the mouth, or by other means.",
              synonyms: [],
              antonyms: [],
              example: "to blow the fire",
            },
            {
              definition: "To clear of contents by forcing air through.",
              synonyms: [],
              antonyms: [],
              example: "to blow an egg",
            },
            {
              definition:
                "To cause to make sound by blowing, as a musical instrument.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make a sound as the result of being blown.",
              synonyms: [],
              antonyms: [],
              example: "In the harbor, the ships' horns blew.",
            },
            {
              definition:
                "(of a cetacean) To exhale visibly through the spout the seawater which it has taken in while feeding.",
              synonyms: [],
              antonyms: [],
              example: 'There she blows! (i.e. "I see a whale spouting!")',
            },
            {
              definition: "To explode.",
              synonyms: [],
              antonyms: [],
              example:
                "Get away from that burning gas tank! It's about to blow!",
            },
            {
              definition:
                '(with "up" or with prep phrase headed by "to") To cause to explode, shatter, or be utterly destroyed.',
              synonyms: [],
              antonyms: [],
              example: "The aerosol can was blown to bits.",
            },
            {
              definition: "To cause sudden destruction of.",
              synonyms: [],
              antonyms: [],
              example: "He blew the tires and the engine.",
            },
            {
              definition: "To suddenly fail destructively.",
              synonyms: [],
              antonyms: [],
              example:
                "He tried to sprint, but his ligaments blew and he was barely able to walk to the finish line.",
            },
            {
              definition: "(used to express displeasure or frustration) Damn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be very undesirable. (See also suck.)",
              synonyms: [],
              antonyms: [],
              example: "This blows!",
            },
            {
              definition: "To recklessly squander.",
              synonyms: [],
              antonyms: [],
              example: "I blew $35 thou on a car.",
            },
            {
              definition: "To fellate; to perform oral sex on (usually a man)",
              synonyms: [],
              antonyms: [],
              example:
                "Who did you have to blow to get those backstage passes?",
            },
            {
              definition: "To leave, especially suddenly or in a hurry.",
              synonyms: [],
              antonyms: [],
              example: "Let's blow this joint.",
            },
            {
              definition:
                "To make flyblown, to defile, especially with fly eggs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To spread by report; to publish; to disclose.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To inflate, as with pride; to puff up.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To breathe hard or quick; to pant; to puff.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To put out of breath; to cause to blow from fatigue.",
              synonyms: [],
              antonyms: [],
              example: "to blow a horse",
            },
            {
              definition: "To talk loudly; to boast; to storm.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To sing.",
              synonyms: [],
              antonyms: [],
              example:
                "That girl has a wonderful voice; just listen to her blow!",
            },
            {
              definition:
                "To leave the Church of Scientology in an unauthorized manner.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To blossom; to cause to bloom or blossom.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Distended, swollen or inflated",
              synonyms: [],
              antonyms: [],
              example:
                "Cattle are said to be blown when gorged with green food which develops gas.",
            },
            {
              definition: "Panting and out of breath",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of glass) Formed by blowing",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Under the influence of drugs, especially marijuana.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Stale; worthless", synonyms: [], antonyms: [] },
            {
              definition:
                "Covered with the eggs and larvae of flies; flyblown.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Given a hot rod blower.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/blow",
        "https://en.wiktionary.org/wiki/blown",
      ],
    },
  ],
  piled: [
    {
      word: "piled",
      phonetic: "/paɪld/",
      phonetics: [
        {
          text: "/paɪld/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/piled-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651295",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                '(often used with the preposition "up") To lay or throw into a pile or heap; to heap up; to collect into a mass; to accumulate',
              synonyms: [],
              antonyms: [],
              example: "They were piling up wood on the wheelbarrow.",
            },
            {
              definition:
                "To cover with heaps; or in great abundance; to fill or overfill; to load.",
              synonyms: [],
              antonyms: [],
              example: "We piled the camel with our loads.",
            },
            {
              definition: "To add something to a great number.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of vehicles) To create a hold-up.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To place (guns, muskets, etc.) together in threes so that they can stand upright, supporting each other.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["heap", "pile up"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To drive piles into; to fill with piles; to strengthen with piles.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To give a pile to; to make shaggy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "(iron manufacturing) Formed from a pile or fagot.",
              synonyms: [],
              antonyms: [],
              example: "piled iron",
            },
            {
              definition: "Having a pile or point; pointed.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Having a pile or nap.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pile",
        "https://en.wiktionary.org/wiki/piled",
      ],
    },
  ],
  clubs: [
    {
      word: "clubs",
      phonetic: "/klʌbz/",
      phonetics: [
        {
          text: "/klʌbz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/clubs-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90913996",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An association of members joining together for some common purpose, especially sports or recreation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A heavy stick intended for use as a weapon or plaything.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A joint charge of expense, or any person's share of it; a contribution to a common fund.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An establishment that provides staged entertainment, often with food and drink, such as a nightclub.",
              synonyms: [],
              antonyms: [],
              example:
                "She was sitting in a jazz club, sipping wine and listening to a bass player's solo.",
            },
            {
              definition:
                "A black clover shape (♣), one of the four symbols used to mark the suits of playing cards.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any set of people with a shared characteristic.",
              synonyms: [],
              antonyms: [],
              example: "Michael stood you up?  Welcome to the club.",
            },
            { definition: "A club sandwich.", synonyms: [], antonyms: [] },
            {
              definition:
                "The slice of bread in the middle of a club sandwich.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["team", "cudgel"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To hit with a club.",
              synonyms: [],
              antonyms: [],
              example: "He clubbed the poor dog.",
            },
            {
              definition: "To join together to form a group.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To combine into a club-shaped mass.",
              synonyms: [],
              antonyms: [],
              example:
                "a medical condition with clubbing of the fingers and toes",
            },
            {
              definition: "To go to nightclubs.",
              synonyms: [],
              antonyms: [],
              example: "We went clubbing in Ibiza.",
            },
            {
              definition:
                "To pay an equal or proportionate share of a common charge or expense.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To raise, or defray, by a proportional assessment.",
              synonyms: [],
              antonyms: [],
              example: "to club the expense",
            },
            {
              definition: "To drift in a current with an anchor out.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To throw, or allow to fall, into confusion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To unite, or contribute, for the accomplishment of a common end.",
              synonyms: [],
              antonyms: [],
              example: "to club exertions",
            },
            {
              definition:
                "To turn the breech of (a musket) uppermost, so as to use it as a club.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One of the four suits of playing cards, marked with the symbol ♣.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A game in which questions are asked for the purpose of enabling the questioners to discover a word or thing previously selected by two persons who answer the questions.",
              synonyms: ["clubs"],
              antonyms: [],
            },
          ],
          synonyms: ["clubs"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/club",
        "https://en.wiktionary.org/wiki/clubs",
        "https://en.wiktionary.org/wiki/clumps",
      ],
    },
  ],
  cheer: [
    {
      word: "cheer",
      phonetic: "/t͡ʃɪə(ɹ)/",
      phonetics: [
        { text: "/t͡ʃɪə(ɹ)/", audio: "" },
        {
          text: "/t͡ʃɪɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cheer-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100933",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A cheerful attitude; happiness; a good, happy, or positive mood.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That which promotes good spirits or cheerfulness; provisions prepared for a feast; entertainment.",
              synonyms: [],
              antonyms: [],
              example: "a table loaded with good cheer",
            },
            {
              definition:
                'A cry expressing joy, approval or support such as "hurray".',
              synonyms: [],
              antonyms: [],
              example: "A cheer rose from the crowd.",
            },
            {
              definition:
                "A chant made in support of a team at a sports event.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One's facial expression or countenance.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "One's attitude, mood.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To gladden; to make cheerful; often with up.",
              synonyms: [],
              antonyms: [],
              example: "We were cheered by the offer of a cup of tea.",
            },
            {
              definition:
                "To infuse life, courage, animation, or hope, into; to inspirit; to solace or comfort.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To applaud or encourage with cheers or shouts.",
              synonyms: [],
              antonyms: [],
              example: "The crowd cheered in support of the athletes.",
            },
          ],
          synonyms: [],
          antonyms: ["boo"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cheer"],
    },
    {
      word: "cheer",
      phonetic: "/t͡ʃɪə(ɹ)/",
      phonetics: [
        { text: "/t͡ʃɪə(ɹ)/", audio: "" },
        {
          text: "/t͡ʃɪɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cheer-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100933",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Cheerleading.",
              synonyms: [],
              antonyms: [],
              example: "I'm going to wear my new cheer shoes at cheer today.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cheer"],
    },
  ],
  widow: [
    {
      word: "widow",
      phonetic: "/ˈwɪ.dəʊ/",
      phonetics: [
        { text: "/ˈwɪ.dəʊ/", audio: "" },
        {
          text: "/ˈwɪ.doʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/widow-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1750053",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A woman whose spouse has died (and who has not remarried); feminine of widower.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person whose spouse has died (and who has not remarried).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in combination) A woman whose husband is often away pursuing a sport, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An additional hand of cards dealt face down in some card games, to be used by the highest bidder.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A single line of type that ends a paragraph, carried over to the next page or column.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A venomous spider, of the genus Latrodectus.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make a widow or widower of someone; to cause the death of the spouse of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To strip of anything valued.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To endow with a widow's right.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To be widow to.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/widow"],
    },
  ],
  twist: [
    {
      word: "twist",
      phonetic: "/twɪst/",
      phonetics: [
        {
          text: "/twɪst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/twist-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1197661",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A twisting force.", synonyms: [], antonyms: [] },
            {
              definition: "Anything twisted, or the act of twisting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The form given in twisting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The degree of stress or strain when twisted.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A type of thread made from two filaments twisted together.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sliver of lemon peel added to a cocktail, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sudden bend (or short series of bends) in a road, path, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A distortion to the meaning of a word or passage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An unexpected turn in a story, tale, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(preceded by definite article) A type of dance characterised by rotating one’s hips. See Twist (dance) on Wikipedia for more details.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A rotation of the body when diving.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sprain, especially to the ankle.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A twig.", synonyms: [], antonyms: [] },
            { definition: "A girl, a woman.", synonyms: [], antonyms: [] },
            {
              definition: "A roll of twisted dough, baked.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A small roll of tobacco.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A material for gun barrels, consisting of iron and steel twisted and welded together.",
              synonyms: [],
              antonyms: [],
              example: "Damascus twist",
            },
            {
              definition:
                "The spiral course of the rifling of a gun barrel or a cannon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A beverage made of brandy and gin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A strong individual tendency or bent; inclination.",
              synonyms: [],
              antonyms: [],
              example: "a twist toward fanaticism",
            },
            { definition: "An appetite for food.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To turn the ends of something, usually thread, rope etc., in opposite directions, often using force.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To join together by twining one part around another.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To contort; to writhe; to complicate; to crook spirally; to convolve.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To wreathe; to wind; to encircle; to unite by intertexture of parts.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To wind into; to insinuate.",
              synonyms: [],
              antonyms: [],
              example: "Avarice twists itself into all human concerns.",
            },
            { definition: "To turn a knob etc.", synonyms: [], antonyms: [] },
            {
              definition:
                "To distort or change the truth or meaning of words when repeating.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To form a twist (in any of the above noun meanings).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To injure (a body part) by bending it in the wrong direction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a path) To wind; to follow a bendy or wavy course; to have many bends.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To cause to rotate.", synonyms: [], antonyms: [] },
            {
              definition:
                "To dance the twist (a type of dance characterised by twisting one's hips).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To coax.", synonyms: [], antonyms: [] },
            {
              definition:
                "In the game of blackjack (pontoon or twenty-one), to be dealt another card.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/twist"],
    },
  ],
  tenth: [
    {
      word: "tenth",
      phonetic: "/tɛnθ/",
      phonetics: [
        { text: "/tɛnθ/", audio: "" },
        {
          text: "/tɛnθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tenth-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=874111",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The person or thing coming next after the ninth in a series; that which is in the tenth position.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One of ten equal parts of a whole.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The interval between any tone and the tone represented on the tenth degree of the staff above it, as between one of the scale and three of the octave above; the octave of the third.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in the plural) A temporary aid issuing out of personal property, and granted to the king by Parliament; formerly, the real tenth part of all the movables belonging to the subject.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "decima",
            "decim",
            "decima",
            "decimate",
            "tithe",
            "titheling",
            "tithing",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To divide by ten, into tenths.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "The ordinal numeral form of ten; next in order after that which is ninth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Being one of ten equal parts of a whole.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["10th", "X", "Xth", "tithe"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tenth"],
    },
  ],
  hides: [
    {
      word: "hides",
      phonetic: "/haɪdz/",
      phonetics: [{ text: "/haɪdz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To put (something) in a place where it will be harder to discover or out of sight.",
              synonyms: ["conceal", "hide away", "secrete"],
              antonyms: ["disclose", "expose", "reveal", "show", "uncover"],
              example: "He hides his magazines under the bed.",
            },
            {
              definition:
                "To put oneself in a place where one will be harder to find or out of sight.",
              synonyms: ["go undercover", "hide away", "hide out", "lie low"],
              antonyms: ["reveal", "show"],
            },
          ],
          synonyms: [
            "conceal",
            "hide away",
            "secrete",
            "go undercover",
            "hide away",
            "hide out",
            "lie low",
          ],
          antonyms: [
            "disclose",
            "expose",
            "reveal",
            "show",
            "uncover",
            "reveal",
            "show",
          ],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The skin of an animal.",
              synonyms: ["pelt", "skin"],
              antonyms: [],
            },
            { definition: "The human skin.", synonyms: [], antonyms: [] },
            {
              definition:
                "(usually United States) One's own life or personal safety, especially when in peril.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(mainly British) A covered structure from which hunters, birdwatchers, etc can observe animals without scaring them.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A secret room for hiding oneself or valuables; a hideaway.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A covered structure to which a pet animal can retreat, as is recommended for snakes.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["pelt", "skin"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To beat with a whip made from hide.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A unit of land and tax assessment of varying size, originally as intended to support one household with dependents.",
              synonyms: ["carucate"],
              antonyms: [],
            },
          ],
          synonyms: ["carucate"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hide",
        "https://en.wiktionary.org/wiki/hides",
      ],
    },
  ],
  comma: [
    {
      word: "comma",
      phonetic: "/ˈkɒm.ə/",
      phonetics: [
        { text: "/ˈkɒm.ə/", audio: "" },
        {
          text: "/ˈkɑm.ə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/comma-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1755129",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The punctuation mark ⟨,⟩ used to indicate a set off parts of a sentence or between elements of a list.",
              synonyms: [
                "come",
                "comma-point",
                "scratch comma",
                "virgula",
                "virgule",
              ],
              antonyms: [],
            },
            {
              definition: "A similar-looking subscript diacritical mark.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various nymphalid butterflies of the genus Polygonia, having a comma-shaped white mark on the underwings, especially Polygonia c-album and Polygonia c-aureum of North Africa, Europe, and Asia.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A difference in the calculation of nearly identical intervals by different ways.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A delimiting marker between items in a genetic sequence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In Ancient Greek rhetoric, a short clause, something less than a colon, originally denoted by comma marks. In antiquity it was defined as a combination of words having no more than eight syllables in all. It was later applied to longer phrases, e.g. the Johannine comma.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A brief interval.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "come",
            "comma-point",
            "scratch comma",
            "virgula",
            "virgule",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To place a comma or commas within text; to follow, precede, or surround a portion of text with commas.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/comma"],
    },
  ],
  sweep: [
    {
      word: "sweep",
      phonetic: "/swiːp/",
      phonetics: [
        {
          text: "/swiːp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sweep-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75900163",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/swiːp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sweep-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762784",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A single action of sweeping.",
              synonyms: [],
              antonyms: [],
              example:
                "Give the front steps a quick sweep to get rid of those fallen leaves.",
            },
            {
              definition: "The person who steers a dragon boat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person who stands at the stern of a surf boat, steering with a steering oar and commanding the crew.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A chimney sweep.", synonyms: [], antonyms: [] },
            {
              definition:
                "A methodical search, typically for bugs (electronic listening devices).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A batsman's shot, played from a kneeling position with a swinging horizontal bat.",
              synonyms: [],
              antonyms: [],
              example:
                "Bradman attempted a sweep, but in fact top edged the ball to the wicket keeper",
            },
            {
              definition:
                "A lottery, usually on the results of a sporting event, where players win if their randomly chosen team wins.",
              synonyms: [],
              antonyms: [],
              example:
                "Jim will win fifty dollars in the office sweep if Japan wins the World Cup.",
            },
            {
              definition:
                "A flow of water parallel to shore caused by wave action at an ocean beach or at a point or headland.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A throw or takedown that primarily uses the legs to attack an opponent's legs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Violent and general destruction.",
              synonyms: [],
              antonyms: [],
              example: "the sweep of an epidemic disease",
            },
            {
              definition:
                "A movable templet for making moulds, in loam moulding.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In the game casino, the act of capturing all face-up cards from the table.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The compass of any turning body or of any motion.",
              synonyms: [],
              antonyms: [],
              example: "the sweep of a door; the sweep of the eye",
            },
            {
              definition:
                "Direction or departure of a curve, a road, an arch, etc. away from a rectilinear line.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A large oar used in small vessels, partly to propel them and partly to steer them.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A rowing style in which each rower rows with oar on either the port or starboard side.",
              synonyms: [],
              antonyms: [],
              example: "I am primarily a sweep rower.",
            },
            {
              definition: "(refining) The almond furnace.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A long pole, or piece of timber, moved on a horizontal fulcrum fixed to a tall post and used to raise and lower a bucket in a well for drawing water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any of the blades of a windmill.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in the plural) The sweepings of workshops where precious metals are worked, containing filings, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of several sea chub in the kyphosid subfamily Scorpidinae.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An expanse or a swath, a strip of land.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To clean (a surface) by means of a stroking motion of a broom or brush.",
              synonyms: [],
              antonyms: [],
              example: "to sweep a floor, the street, or a chimney",
            },
            {
              definition:
                "To move through a (horizontal) arc or similar long stroke.",
              synonyms: [],
              antonyms: [],
              example: "The offended countess swept out of the ballroom.",
            },
            {
              definition: "To search (a place) methodically.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To travel quickly.", synonyms: [], antonyms: [] },
            { definition: "To play a sweep shot.", synonyms: [], antonyms: [] },
            {
              definition:
                "To brush the ice in front of a moving stone, causing it to travel farther and to curl less.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To move something in a long sweeping motion, as a broom.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To win (a series) without drawing or losing any of the games in that series.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To defeat (a team) in a series without drawing or losing any of the games in that series.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To remove something abruptly and thoroughly.",
              synonyms: [],
              antonyms: [],
              example: "She swept the peelings off the table onto the floor.",
            },
            {
              definition: "To brush against or over; to rub lightly along.",
              synonyms: [],
              antonyms: [],
              example:
                "Their long descending train, / With rubies edg'd and sapphires, swept the plain.",
            },
            {
              definition:
                "To carry with a long, swinging, or dragging motion; hence, to carry in a stately or proud fashion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To strike with a long stroke.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To row with one oar to either the port or starboard side.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To draw or drag something over.",
              synonyms: [],
              antonyms: [],
              example: "to sweep the bottom of a river with a net",
            },
            {
              definition:
                "To pass over, or traverse, with the eye or with an instrument of observation.",
              synonyms: [],
              antonyms: [],
              example: "to sweep the heavens with a telescope",
            },
            {
              definition: "(including) to vacuum a carpet or rug",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sweep"],
    },
  ],
  spoon: [
    {
      word: "spoon",
      phonetic: "/spuːn/",
      phonetics: [
        {
          text: "/spuːn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spoon-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9014222",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/spun/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spoon-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1158019",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An implement for eating or serving; a scooped utensil whose long handle is straight, in contrast to a ladle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An implement for stirring food while being prepared; a wooden spoon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A measure that will fit into a spoon; a spoonful.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A wooden-headed golf club with moderate loft, similar to the modern three wood.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An oar.", synonyms: [], antonyms: [] },
            {
              definition:
                "A type of metal lure resembling the concave head of a tablespoon.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A spoon excavator.", synonyms: [], antonyms: [] },
            {
              definition: "A simpleton, a spooney.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A safety handle on a hand grenade, a trigger.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A metaphoric unit of energy available to cope with problems.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To serve using a spoon; to transfer (something) with a spoon.",
              synonyms: [],
              antonyms: [],
              example: "Sarah spooned some apple sauce onto her plate.",
            },
            {
              definition:
                "To flirt; to make advances; to court, to interact romantically or amorously.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of persons) To lie nestled front-to-back, following the contours of the bodies, in a manner reminiscent of stacked spoons.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To hit (the ball) weakly, pushing it with a lifting motion, instead of striking with an audible knock.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fish with a concave spoon bait.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To catch by fishing with a concave spoon bait.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spoon"],
    },
    {
      word: "spoon",
      phonetic: "/spuːn/",
      phonetics: [
        {
          text: "/spuːn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spoon-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9014222",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/spun/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spoon-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1158019",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To sail briskly with the wind astern, with or without sails hoisted.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/spoom",
        "https://en.wiktionary.org/wiki/spoon",
      ],
    },
  ],
  stern: [
    {
      word: "stern",
      phonetic: "/stɜːn/",
      phonetics: [
        { text: "/stɜːn/", audio: "" },
        {
          text: "/stɝn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stern-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1750004",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having a hardness and severity of nature or manner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Grim and forbidding in appearance.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stern"],
    },
    {
      word: "stern",
      phonetic: "/stɜːn/",
      phonetics: [
        { text: "/stɜːn/", audio: "" },
        {
          text: "/stɝn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stern-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1750004",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The rear part or after end of a ship or vessel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The post of management or direction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The hinder part of anything.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The tail of an animal; now used only of the tail of a dog.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["poop"],
          antonyms: ["bow"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stern"],
    },
    {
      word: "stern",
      phonetic: "/stɜːn/",
      phonetics: [
        { text: "/stɜːn/", audio: "" },
        {
          text: "/stɝn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stern-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1750004",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A bird, the black tern.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stern"],
    },
  ],
  crept: [
    {
      word: "crept",
      phonetic: "/ˈkɹɛpt/",
      phonetics: [
        {
          text: "/ˈkɹɛpt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/crept-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=592006",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To move slowly with the abdomen close to the ground.",
              synonyms: ["crawl"],
              antonyms: [],
            },
            {
              definition:
                "Of plants, to grow across a surface rather than upwards.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To move slowly and quietly in a particular direction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make small gradual changes, usually in a particular direction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To move in a stealthy or secret manner; to move imperceptibly or clandestinely; to steal in; to insinuate itself or oneself.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To slip, or to become slightly displaced.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To move or behave with servility or exaggerated humility; to fawn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To have a sensation as of insects creeping on the skin of the body; to crawl.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To drag in deep water with creepers, as for recovering a submarine cable.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To covertly have sex (with a person other than one's primary partner); to cheat with.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["crawl"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/creep",
        "https://en.wiktionary.org/wiki/crept",
      ],
    },
  ],
  maple: [
    {
      word: "maple",
      phonetic: "/ˈmeɪpl̩/",
      phonetics: [
        {
          text: "/ˈmeɪpl̩/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/maple-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780206",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A tree of the Acer genus, characterised by its usually palmate leaves and winged seeds.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The wood of such a tree, prized for its hardness and attractive appearance",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/maple"],
    },
  ],
  deeds: [
    {
      word: "deeds",
      phonetic: "/diːdz/",
      phonetics: [
        {
          text: "/diːdz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/deeds-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749786",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An action or act; something that is done.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A brave or noteworthy action; a feat or exploit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Action or fact, as opposed to rhetoric or deliberation.",
              synonyms: [],
              antonyms: [],
              example: "I have fulfilled my promise in word and in deed.",
            },
            {
              definition:
                "A legal instrument that is executed under seal or before witnesses.",
              synonyms: [],
              antonyms: [],
              example: "I inherited the deed to the house.",
            },
          ],
          synonyms: ["act", "action", "certificate", "document", "instrument"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To transfer real property by deed.",
              synonyms: [],
              antonyms: [],
              example:
                "He deeded over the mineral rights to some fellas from Denver.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/deed",
        "https://en.wiktionary.org/wiki/deeds",
      ],
    },
  ],
  rides: [
    {
      word: "rides",
      phonetic: "/ɹaɪdz/",
      phonetics: [{ text: "/ɹaɪdz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An instance of riding.",
              synonyms: [],
              antonyms: [],
              example: "Can I have a ride on your bike?",
            },
            {
              definition: "A vehicle.",
              synonyms: [],
              antonyms: [],
              example: "That's a nice ride; what did it cost?",
            },
            {
              definition: "An amusement ridden at a fair or amusement park.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A lift given to someone in another person's vehicle.",
              synonyms: [],
              antonyms: [],
              example: "Can you give me a ride?",
            },
            {
              definition:
                "A road or avenue cut in a wood, for riding; a bridleway or other wide country path.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A saddle horse.", synonyms: [], antonyms: [] },
            {
              definition:
                "A person (or sometimes a thing or a place) that is visually attractive.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "In jazz, to play in a steady rhythmical style.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To transport oneself by sitting on and directing a horse, later also a bicycle etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be transported in a vehicle; to travel as a passenger.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(chiefly US and South Africa) To transport (someone) in a vehicle.",
              synonyms: [],
              antonyms: [],
              example: "The cab rode him downtown.",
            },
            {
              definition: "Of a ship: to sail, to float on the water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be carried or supported by something lightly and quickly; to travel in such a way, as though on horseback.",
              synonyms: [],
              antonyms: [],
              example: "The witch cackled and rode away on her broomstick.",
            },
            {
              definition: "To traverse by riding.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To convey, as by riding; to make or do by riding.",
              synonyms: [],
              antonyms: [],
              example: "How many races have you ridden this year?",
            },
            {
              definition:
                "To support a rider, as a horse; to move under the saddle.",
              synonyms: [],
              antonyms: [],
              example: "A horse rides easy or hard, slow or fast.",
            },
            {
              definition:
                "To mount (someone) to have sex with them; to have sexual intercourse with.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To nag or criticize; to annoy (someone).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of clothing: to gradually move (up) and crease; to ruckle.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To rely, depend (on).", synonyms: [], antonyms: [] },
            {
              definition:
                "Of clothing: to rest (in a given way on a part of the body).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To play defense on the defensemen or midfielders, as an attackman.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To manage insolently at will; to domineer over.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To overlap (each other); said of bones or fractured fragments.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To monitor (some component of an audiovisual signal) in order to keep it within acceptable bounds.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "In jazz, a steady rhythmical style.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["do it", "get it on"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ride",
        "https://en.wiktionary.org/wiki/rides",
      ],
    },
  ],
  muddy: [
    {
      word: "muddy",
      phonetic: "[ˈmʌdi]",
      phonetics: [
        {
          text: "[ˈmʌdi]",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/muddy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=653280",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To get mud on (something).",
              synonyms: [],
              antonyms: [],
              example: "If you muddy your shoes don't wear them inside.",
            },
            {
              definition:
                "To make a mess of, or create confusion with regard to; to muddle.",
              synonyms: [],
              antonyms: [],
              example:
                "The discussion only muddied their understanding of the subject.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Covered with or full of mud or wet soil.",
              synonyms: [],
              antonyms: [],
              example: "He slogged across the muddy field.",
            },
            {
              definition:
                "With mud or other sediment brought into suspension, turbid.",
              synonyms: [],
              antonyms: [],
              example:
                "The previously limpid water was now muddy as a result of the epic struggle.",
            },
            {
              definition: "Not clear; mixed up or blurry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Confused; stupid; incoherent; vague.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Soiled with feces.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/muddy"],
    },
  ],
  crime: [
    {
      word: "crime",
      phonetic: "/kɹaɪm/",
      phonetics: [
        {
          text: "/kɹaɪm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/crime-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=840681",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A specific act committed in violation of the law.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any great sin or wickedness; iniquity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "That which occasions crime.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Criminal acts collectively.",
              synonyms: ["criminality", "delinquency"],
              antonyms: [],
            },
            {
              definition: "The habit or practice of committing crimes.",
              synonyms: [],
              antonyms: [],
              example: "Crime doesn’t pay.",
            },
          ],
          synonyms: ["criminality", "delinquency"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To subject to disciplinary punishment.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To commit crime(s).", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/crime"],
    },
  ],
  jelly: [
    {
      word: "jelly",
      phonetic: "/ˈd͡ʒɛl.i/",
      phonetics: [
        {
          text: "/ˈd͡ʒɛl.i/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/jelly-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75272049",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈd͡ʒɛl.i/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/jelly-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=862239",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An explosive mixture of nitroglycerine and nitrate absorbed onto a base of wood pulp.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                'A dessert made by boiling gelatine, sugar and some flavouring (often derived from fruit) and allowing it to set, known as "jello" in North America.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'A clear or translucent fruit preserve, made from fruit juice and set using either naturally occurring, or added, pectin. Known as "jam" in Commonwealth English.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A savoury substance, derived from meat, that has the same texture as the dessert.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any substance or object having the consistency of jelly.",
              synonyms: [],
              antonyms: [],
              example: "calf's-foot jelly",
            },
            { definition: "A jellyfish.", synonyms: [], antonyms: [] },
            {
              definition: "A pretty girl; a girlfriend.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A large backside, especially a woman's.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A jelly shoe.", synonyms: [], antonyms: [] },
            { definition: "Blood.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["Jell-O", "jello", "jam", "marmalade"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To wiggle like jelly.", synonyms: [], antonyms: [] },
            { definition: "To make jelly.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A coconut with soft or gelatinous meat inside as well as the meat itself (which is of regular quality if the coconut is immature)",
              synonyms: [],
              antonyms: ["water coconut", "waternut"],
            },
          ],
          synonyms: [],
          antonyms: ["water coconut", "waternut"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gelignite",
        "https://en.wiktionary.org/wiki/jelly",
        "https://en.wiktionary.org/wiki/jelly%20coconut",
      ],
    },
    {
      word: "jelly",
      phonetic: "/ˈd͡ʒɛl.i/",
      phonetics: [
        {
          text: "/ˈd͡ʒɛl.i/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/jelly-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75272049",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈd͡ʒɛl.i/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/jelly-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=862239",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [{ definition: "Jealous.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/jelly"],
    },
  ],
  ridge: [
    {
      word: "ridge",
      phonetic: "/ɹɪdʒ/",
      phonetics: [
        { text: "/ɹɪdʒ/", audio: "" },
        {
          text: "/ɹɪdʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ridge-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1234332",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The back of any animal; especially the upper or projecting part of the back of a quadruped.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any extended protuberance; a projecting line or strip.",
              synonyms: [],
              antonyms: [],
              example:
                "The plough threw up ridges of earth between the furrows.",
            },
            {
              definition:
                "The line along which two sloping surfaces meet which diverge towards the ground.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The highest point on a roof, represented by a horizontal line where two roof areas intersect, running the length of the area.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(fortifications) The highest portion of the glacis proceeding from the salient angle of the covered way.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A chain of mountains.", synonyms: [], antonyms: [] },
            { definition: "A chain of hills.", synonyms: [], antonyms: [] },
            {
              definition: "A long narrow elevation on an ocean bottom.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An elongated region of high atmospheric pressure.",
              synonyms: [],
              antonyms: ["trough"],
            },
          ],
          synonyms: [],
          antonyms: ["trough"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To form into a ridge", synonyms: [], antonyms: [] },
            { definition: "To extend in ridges", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ridge"],
    },
  ],
  drift: [
    {
      word: "drift",
      phonetic: "/dɹɪft/",
      phonetics: [
        {
          text: "/dɹɪft/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/drift-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899534",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/dɹɪft/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/drift-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1140039",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(physical) Movement; that which moves or is moved.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The act or motion of drifting; the force which impels or drives; an overpowering influence or impulse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A place (a ford) along a river where the water is shallow enough to permit crossing to the opposite side.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The tendency of an act, argument, course of conduct, or the like; object aimed at or intended; intention; hence, also, import or meaning of a sentence or discourse; aim.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The horizontal thrust or pressure of an arch or vault upon the abutments.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "(handiwork) A tool.", synonyms: [], antonyms: [] },
            {
              definition:
                "A deviation from the line of fire, peculiar to oblong projectiles.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Minor deviation of audio or video playback from its correct speed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The situation where a performer gradually and unintentionally moves from their proper location within the scene.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A passage driven or cut between shaft and shaft; a driftway; a small subterranean gallery; an adit or tunnel.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Movement.", synonyms: [], antonyms: [] },
            {
              definition:
                "A sideways movement of the ball through the air, when bowled by a spin bowler.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Slow, cumulative change.",
              synonyms: [],
              antonyms: [],
              example: "genetic drift",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To move slowly, especially pushed by currents of water, air, etc.",
              synonyms: [],
              antonyms: [],
              example: "The balloon was drifting in the breeze.",
            },
            {
              definition: "To move haphazardly without any destination.",
              synonyms: [],
              antonyms: [],
              example: "He drifted from town to town, never settling down.",
            },
            {
              definition:
                "To deviate gently from the intended direction of travel.",
              synonyms: [],
              antonyms: [],
              example: "This car tends to drift left at high speeds.",
            },
            {
              definition: "To drive or carry, as currents do a floating body.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To drive into heaps.",
              synonyms: [],
              antonyms: [],
              example: "A current of wind drifts snow or sand",
            },
            {
              definition:
                "To accumulate in heaps by the force of wind; to be driven into heaps.",
              synonyms: [],
              antonyms: [],
              example: "Snow or sand drifts.",
            },
            {
              definition:
                "To make a drift; to examine a vein or ledge for the purpose of ascertaining the presence of metals or ores; to follow a vein; to prospect.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To enlarge or shape, as a hole, with a drift.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To oversteer a vehicle, causing loss of traction, while maintaining control from entry to exit of a corner. See Drifting (motorsport).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/drift"],
    },
  ],
  dusty: [
    {
      word: "dusty",
      phonetic: "/ˈdʌsti/",
      phonetics: [
        {
          text: "/ˈdʌsti/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dusty-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2650887",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Covered with dust.", synonyms: [], antonyms: [] },
            {
              definition: "Powdery and resembling dust.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Grey in parts.", synonyms: [], antonyms: [] },
            {
              definition: "Ugly, disgusting (a general term of abuse)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dust-ridden"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dusty"],
    },
  ],
  devil: [
    {
      word: "devil",
      phonetic: "/ˈdɛvəl/",
      phonetics: [
        {
          text: "/ˈdɛvəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/devil-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1669290",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An evil creature.",
              synonyms: ["demon"],
              antonyms: ["angel", "god"],
            },
            {
              definition: "(with article) The chief devil; Satan.",
              synonyms: [],
              antonyms: ["God"],
            },
            {
              definition:
                "A fictional image of a man, usually red or orange in skin color; with a set of horns on his head, a pointed goatee and a long tail and carrying a pitchfork; that represents evil and portrayed to children in an effort to discourage bad behavior.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The bad part of the conscience; the opposite to the angel.",
              synonyms: [],
              antonyms: ["angel", "conscience"],
              example: "The devil in me wants to let him suffer.",
            },
            {
              definition:
                "A wicked or naughty person, or one who harbors reckless, spirited energy, especially in a mischievous way; usually said of a young child.",
              synonyms: ["imp", "rascal", "scamp", "scoundrel"],
              antonyms: ["angel", "saint"],
              example: "Those two kids are devils in a toy store.",
            },
            {
              definition:
                "A thing that is awkward or difficult to understand or do.",
              synonyms: ["bastard", "bitch", "bugger", "stinker"],
              antonyms: ["cakewalk", "piece of cake"],
              example: "That math problem was a devil.",
            },
            {
              definition: "(with an article, as an intensifier) Hell.",
              synonyms: ["deuce", "dickens", "fuck", "heck", "hell"],
              antonyms: [],
              example: "She is having a devil of a time fixing it.",
            },
            {
              definition:
                "A person, especially a man; used to express a particular opinion of him, usually in the phrases poor devil and lucky devil.",
              synonyms: ["bugger", "cow", "sod"],
              antonyms: [],
            },
            { definition: "A dust devil.", synonyms: [], antonyms: [] },
            {
              definition: "(Christian Science) An evil or erring entity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in compounds) A barren, unproductive and unused area.",
              synonyms: [],
              antonyms: [],
              example: "devil strip",
            },
            {
              definition:
                "A dish, as a bone with the meat, broiled and excessively peppered; a grill with Cayenne pepper.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A machine for tearing or cutting rags, cotton, etc.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A Tasmanian devil.", synonyms: [], antonyms: [] },
            {
              definition:
                "An endurance event where riders who fall behind are periodically eliminated.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "bastard",
            "bitch",
            "bugger",
            "stinker",
            "bugger",
            "cow",
            "sod",
            "demon",
            "deuce",
            "dickens",
            "fuck",
            "heck",
            "hell",
            "imp",
            "rascal",
            "scamp",
            "scoundrel",
          ],
          antonyms: [
            "God",
            "angel",
            "conscience",
            "angel",
            "god",
            "angel",
            "saint",
            "cakewalk",
            "piece of cake",
          ],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make like a devil; to invest with the character of a devil.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To annoy or bother.",
              synonyms: ["bedevil"],
              antonyms: [],
            },
            {
              definition:
                "To work as a ‘devil’; to work for a lawyer or writer without fee or recognition.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To prepare (food) with spices, making it spicy:",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bedevil"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/devil"],
    },
  ],
  tempo: [
    {
      word: "tempo",
      phonetic: "/ˈtɛm.pəʊ/",
      phonetics: [{ text: "/ˈtɛm.pəʊ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A frequency or rate.", synonyms: [], antonyms: [] },
            {
              definition:
                "A move which is part of one's own plan or strategy and forces, e.g. by means of a check or attacking a piece, the opponent to make a move which is not bad but of no use for him (the player gains a tempo, the opponent loses a tempo), or equivalently a player achieves the same result in fewer moves by one approach rather than another.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The timing advantage of being on lead, thus being first to initiate a strategy to develop tricks for one's side.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The timing of a particular event – earlier or later than in an alternative situation (as in chess example)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The number of beats per minute in a piece of music; also, an indicative term denoting approximate rate of speed in written music (examples: allegro, andante)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The steady pace set by the frontmost riders.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small truck or cargo van with three or four wheels, commonly used for commercial transport and deliveries (particularly in Asian and African countries): a genericized trademark, originally associated with the manufacturer Vidal & Sohn Tempo-Werke GmbH.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A rapid rate of play by the offense resulting from reducing the amount of time which elapses after one play ends and the next starts.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tempo"],
    },
  ],
  humor: [
    {
      word: "humor",
      phonetic: "/hjuː.mə(ɹ)/",
      phonetics: [
        { text: "/hjuː.mə(ɹ)/", audio: "" },
        {
          text: "/ˈhjuːmɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/humor-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=862203",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The quality of being amusing, comical, funny.",
              synonyms: ["amusingness", "comedy", "comicality", "wit"],
              antonyms: [],
              example:
                "She has a great sense of humour, and I always laugh a lot whenever we get together.",
            },
            {
              definition:
                "A mood, especially a bad mood; a temporary state of mind or disposition brought upon by an event; an abrupt illogical inclination or whim.",
              synonyms: ["mood"],
              antonyms: [],
              example: "He was in a particularly vile humour that afternoon.",
            },
            {
              definition:
                'Any of the fluids in an animal body, especially the four "cardinal humours" of blood, yellow bile, black bile and phlegm that were believed to control the health and mood of the human body.',
              synonyms: ["bodily fluid"],
              antonyms: [],
            },
            {
              definition:
                "Either of the two regions of liquid within the eyeball, the aqueous humour and vitreous humour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Moist vapour, moisture.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "comedy",
            "wit",
            "witticism",
            "amusingness",
            "comedy",
            "comicality",
            "wit",
            "bodily fluid",
            "mood",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To pacify by indulging.",
              synonyms: [],
              antonyms: [],
              example:
                "I know you don't believe my story, but humour me for a minute and imagine it to be true.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/humor",
        "https://en.wiktionary.org/wiki/humour",
      ],
    },
  ],
  sends: [
    {
      word: "sends",
      phonetic: "/sɛndz/",
      phonetics: [{ text: "/sɛndz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make something (such as an object or message) go from one place to another.",
              synonyms: [],
              antonyms: [],
              example:
                "Every day at two o'clock, he sends his secretary out to buy him a coffee.",
            },
            {
              definition: "To excite, delight, or thrill (someone).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To bring to a certain condition.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To dispatch an agent or messenger to convey a message, or to do an errand.",
              synonyms: [],
              antonyms: [],
              example: "Seeing how ill she was, we sent for a doctor at once.",
            },
            {
              definition:
                "To cause to be or to happen; to bestow; to inflict; to grant; sometimes followed by a dependent proposition.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To pitch.", synonyms: [], antonyms: [] },
            {
              definition:
                "To make a successful free ascent (i.e. not relying on gear) of a sport climbing route.",
              synonyms: [],
              antonyms: [],
              example:
                "She finally sent the 12a after hours of failed attempts.",
            },
          ],
          synonyms: ["broadcast", "emit", "mail"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/send",
        "https://en.wiktionary.org/wiki/sends",
      ],
    },
  ],
  steal: [
    {
      word: "steal",
      phonetic: "/stiːl/",
      phonetics: [
        {
          text: "/stiːl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/steal-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1158036",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The act of stealing.", synonyms: [], antonyms: [] },
            {
              definition:
                "A piece of merchandise available at a very attractive price.",
              synonyms: [],
              antonyms: [],
              example: "At this price, this car is a steal.",
            },
            {
              definition:
                "A situation in which a defensive player actively takes possession of the ball or puck from the opponent's team.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A stolen base.", synonyms: [], antonyms: [] },
            {
              definition: "Scoring in an end without the hammer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A policy in database systems that a database follows which allows a transaction to be written on nonvolatile storage before its commit occurs.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bargain"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To take illegally, or without the owner's permission, something owned by someone else.",
              synonyms: [],
              antonyms: [],
              example:
                "Three irreplaceable paintings were stolen from the gallery.",
            },
            {
              definition:
                "(of ideas, words, music, a look, credit, etc.) To appropriate without giving credit or acknowledgement.",
              synonyms: [],
              antonyms: [],
              example:
                "They stole my idea for a biodegradable, disposable garbage de-odorizer.",
            },
            {
              definition: "To get or effect surreptitiously or artfully.",
              synonyms: [],
              antonyms: [],
              example:
                "He stole glances at the pretty woman across the street.",
            },
            {
              definition: "To acquire at a low price.",
              synonyms: [],
              antonyms: [],
              example:
                "He stole the car for two thousand less than its book value.",
            },
            {
              definition:
                "To draw attention unexpectedly in (an entertainment), especially by being the outstanding performer. Usually used in the phrase steal the show.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To move silently or secretly.",
              synonyms: [],
              antonyms: [],
              example: "He stole across the room, trying not to wake her.",
            },
            {
              definition: "To convey (something) clandestinely.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To withdraw or convey (oneself) clandestinely.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To advance safely to (another base) during the delivery of a pitch, without the aid of a hit, walk, passed ball, wild pitch, or defensive indifference.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To dispossess", synonyms: [], antonyms: [] },
            {
              definition: "To borrow for a short moment.",
              synonyms: [],
              antonyms: [],
              example: "Can I steal your pen?",
            },
          ],
          synonyms: ["sneak"],
          antonyms: [
            "bestow",
            "donate",
            "grant",
            "buy",
            "earn",
            "purchase",
            "receive",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/steal"],
    },
  ],
  tents: [
    {
      word: "tents",
      phonetic: "/tɛnts/",
      phonetics: [
        {
          text: "/tɛnts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tents-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2454338",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A pavilion or portable lodge consisting of skins, canvas, or some strong cloth, stretched and sustained by poles, used for sheltering people from the weather.",
              synonyms: [],
              antonyms: [],
              example: "We were camping in a three-man tent.",
            },
            {
              definition: "The representation of a tent used as a bearing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A portable pulpit set up outside to accommodate worshippers who cannot fit into a church.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A trouser tent; a piece of fabric, etc. protruding outward like a tent.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To go camping.",
              synonyms: [],
              antonyms: [],
              example: "We’ll be tented at the campground this weekend.",
            },
            {
              definition:
                'To prop up aluminum foil in an inverted "V" (reminiscent of a pop-up tent) over food to reduce splatter, before putting it in the oven.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To form into a tent-like shape.",
              synonyms: [],
              antonyms: [],
              example: "The sheet tented over his midsection.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Attention; regard, care.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Intention; design.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To attend to; to heed", synonyms: [], antonyms: [] },
            { definition: "To guard; to hinder.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A roll of lint or linen, or a conical or cylindrical piece of sponge or other absorbent, used chiefly to dilate a natural canal, to keep open the orifice of a wound, or to absorb discharges.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A probe for searching a wound.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(sometimes figurative) To probe or to search with a tent; to keep open with a tent.",
              synonyms: [],
              antonyms: [],
              example: "to tent a wound",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A kind of wine of a deep red color, chiefly from Galicia or Malaga in Spain; called also tent wine, and tinta.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tent",
        "https://en.wiktionary.org/wiki/tents",
      ],
    },
  ],
  waist: [
    {
      word: "waist",
      phonetic: "/weɪst/",
      phonetics: [{ text: "/weɪst/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The part of the body between the pelvis and the stomach.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A part of a piece of clothing that covers the waist.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The narrow connection between the thorax and abdomen in certain insects (e.g., bees, ants and wasps).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The middle portion of the hull of a ship or the fuselage of an aircraft.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That part of the upper deck of a ship between the quarterdeck and the forecastle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The middle part of anything.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/waist"],
    },
  ],
  roses: [
    {
      word: "roses",
      phonetic: "/ɹoʊzɨz̥/",
      phonetics: [
        {
          text: "/ɹoʊzɨz̥/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/roses-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100846",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A shrub of the genus Rosa, with red, pink, white or yellow flowers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A flower of the rose plant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A plant or species in the rose family. (Rosaceae)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Something resembling a rose flower.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The rose flower, usually depicted with five petals, five barbs, and a circular seed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A purplish-red or pink colour, the colour of some rose flowers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A round nozzle for a sprinkling can or hose.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The base of a light socket.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various large, red-bodied, papilionid butterflies of the genus Pachliopta.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various flower-like polar graphs of sinusoids or their squares.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A graph with only one vertex.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make rose-coloured; to redden or flush.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To perfume, as with roses.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A pale pink wine made by removing the dark grape skins at the required point during fermentation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["blush"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ros%C3%A9",
        "https://en.wiktionary.org/wiki/rose",
        "https://en.wiktionary.org/wiki/roses",
      ],
    },
  ],
  reign: [
    {
      word: "reign",
      phonetic: "[ɹeɪn]",
      phonetics: [
        {
          text: "[ɹeɪn]",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/reign-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=631401",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The exercise of sovereign power.",
              synonyms: [],
              antonyms: [],
              example: "England prospered under Elizabeth I's reign.",
            },
            {
              definition: "The period during which a monarch rules.",
              synonyms: [],
              antonyms: [],
              example: "The reign of Victoria was a long one.",
            },
            {
              definition:
                "The territory or sphere over which a kingdom; empire; realm; dominion, etc. is ruled.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To exercise sovereign power, to rule as a monarch.",
              synonyms: [],
              antonyms: [],
              example: "He reigned in an autocratic manner.",
            },
            {
              definition: "To reign over (a country)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be the winner of the most recent iteration of a competition.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be a dominant quality of a place or situation; to prevail, predominate, rule.",
              synonyms: [],
              antonyms: [],
              example: "Silence reigned.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/reign"],
    },
  ],
  noble: [
    {
      word: "noble",
      phonetic: "/ˈnəʊbəl/",
      phonetics: [
        { text: "/ˈnəʊbəl/", audio: "" },
        {
          text: "/ˈnoʊbəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/noble-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1239735",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An aristocrat; one of aristocratic blood.",
              synonyms: [],
              antonyms: ["commoner", "plebeian"],
              example:
                "This country house was occupied by nobles in the 16th century.",
            },
            {
              definition:
                "A medieval gold coin of England in the 14th and 15th centuries, usually valued at 6s 8d.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["commoner", "plebeian"],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Having honorable qualities; having moral eminence and freedom from anything petty, mean or dubious in conduct and character.",
              synonyms: ["great", "honorable"],
              antonyms: ["despicable", "ignoble", "mean", "vile"],
              example:
                "He is a noble man who would never put his family in jeopardy.",
            },
            {
              definition: "Grand; stately; magnificent; splendid.",
              synonyms: [],
              antonyms: [],
              example: "a noble edifice",
            },
            {
              definition:
                "Of exalted rank; of or relating to the nobility; distinguished from the masses by birth, station, or title; highborn.",
              synonyms: ["superior"],
              antonyms: ["inferior", "plebeian"],
              example: "noble blood; a noble personage",
            },
            {
              definition: "(of a polyhedron) Both isohedral and isogonal.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["great", "honorable", "superior"],
          antonyms: [
            "despicable",
            "ignoble",
            "mean",
            "vile",
            "inferior",
            "plebeian",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/noble"],
    },
  ],
  cheap: [
    {
      word: "cheap",
      phonetic: "/t͡ʃiːp/",
      phonetics: [
        {
          text: "/t͡ʃiːp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cheap-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9014319",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        { text: "/t͡ʃip/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Trade; traffic; chaffer; chaffering.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A market; marketplace.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Price.", synonyms: [], antonyms: [] },
            {
              definition: "A low price; a bargain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Cheapness; lowness of price; abundance of supply.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To trade; traffic; bargain; chaffer; ask the price of goods; cheapen goods.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To bargain for; chaffer for; ask the price of; offer a price for; cheapen.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To buy; purchase.", synonyms: [], antonyms: [] },
            { definition: "To sell.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Low and/or reduced in price.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Of poor quality.", synonyms: [], antonyms: [] },
            { definition: "Of little worth.", synonyms: [], antonyms: [] },
            {
              definition:
                "(of an action or tactic in a game of skill) Underhand or unfair.",
              synonyms: [],
              antonyms: [],
              example:
                "the cheap trick of hiding deadly lava under pushable blocks",
            },
            {
              definition: "Stingy; mean; excessively frugal.",
              synonyms: [],
              antonyms: [],
              example:
                "Insurance is expensive, but don't be so cheap that you risk losing your home because of a fire.",
            },
            {
              definition:
                "Trading at a price level which is low relative to historical trends, a similar asset, or (for derivatives) a theoretical value.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "bargain",
            "frugal",
            "inexpensive",
            "no-frills",
            "priced-off",
            "flimsy",
          ],
          antonyms: [
            "rich",
            "dear",
            "expensive",
            "high-priced",
            "pricey",
            "precious",
            "valuable",
          ],
        },
        {
          partOfSpeech: "adverb",
          definitions: [{ definition: "Cheaply.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cheap"],
    },
  ],
  dense: [
    {
      word: "dense",
      phonetic: "/dɛns/",
      phonetics: [
        { text: "/dɛns/", audio: "" },
        {
          text: "/dɛns/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dense-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453953",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A thicket.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having relatively high density.",
              synonyms: ["solid"],
              antonyms: [],
            },
            {
              definition: "Compact; crowded together.",
              synonyms: ["compact", "crowded", "packed"],
              antonyms: ["diffuse"],
            },
            {
              definition: "Thick; difficult to penetrate.",
              synonyms: ["solid", "thick"],
              antonyms: ["thin"],
            },
            {
              definition: "Opaque; allowing little light to pass through.",
              synonyms: ["cloudy", "opaque"],
              antonyms: [
                "clear",
                "diaphanous",
                "see-through",
                "translucent",
                "transparent",
              ],
            },
            {
              definition: "Obscure, or difficult to understand.",
              synonyms: [
                "abstruse",
                "difficult",
                "hard",
                "incomprehensible",
                "obscure",
                "tough",
              ],
              antonyms: [
                "clear",
                "comprehensible",
                "easy",
                "simple",
                "straightforward",
                "understandable",
              ],
            },
            {
              definition:
                "Being a subset of a topological space that approximates the space well. See Wikipedia article on dense sets for mathematical definition.",
              synonyms: [],
              antonyms: ["meager"],
            },
            {
              definition:
                "(of a person) Slow to comprehend; of low intelligence.",
              synonyms: ["dumb", "slow", "stupid", "thick"],
              antonyms: [
                "bright",
                "canny",
                "intelligent",
                "quick",
                "quick-witted",
                "smart",
              ],
            },
          ],
          synonyms: [
            "abstruse",
            "difficult",
            "hard",
            "incomprehensible",
            "obscure",
            "tough",
            "cloudy",
            "opaque",
            "compact",
            "crowded",
            "packed",
            "dumb",
            "slow",
            "stupid",
            "thick",
            "solid",
            "solid",
            "thick",
          ],
          antonyms: [
            "diffuse",
            "few and far between",
            "rarefied",
            "scattered",
            "sparse",
            "bright",
            "canny",
            "intelligent",
            "quick",
            "quick-witted",
            "smart",
            "clear",
            "comprehensible",
            "easy",
            "simple",
            "straightforward",
            "understandable",
            "clear",
            "diaphanous",
            "see-through",
            "translucent",
            "transparent",
            "diffuse",
            "meager",
            "thin",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dense"],
    },
  ],
  linen: [
    {
      word: "linen",
      phonetic: "/ˈlɪnɪn/",
      phonetics: [
        {
          text: "/ˈlɪnɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/linen-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=195410",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Thread or cloth made from flax fiber.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Domestic textiles, such as tablecloths, bedding, towels, underclothes, etc., that are made of linen or linen-like fabrics of cotton or other fibers; linens.",
              synonyms: [],
              antonyms: [],
              example:
                "She put the freshly cleaned linens into the linen closet.",
            },
            {
              definition:
                "A light beige colour, like that of linen cloth undyed.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Made from linen cloth or thread.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Having the colour linen, light beige.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/linen"],
    },
  ],
  geese: [
    {
      word: "geese",
      phonetic: "/ˈɡiːs/",
      phonetics: [
        {
          text: "/ˈɡiːs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/geese-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=33136741",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of various grazing waterfowl of the family Anatidae, which have feathers and webbed feet and are capable of flying, swimming, and walking on land, and which are bigger than ducks.",
              synonyms: [],
              antonyms: [],
              example: "There is a flock of geese on the pond.",
            },
            {
              definition: "A female goose (sense 1).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The flesh of the goose used as food.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A silly person.", synonyms: [], antonyms: [] },
            {
              definition:
                "A tailor's iron, heated in live coals or embers, used to press fabrics.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A young woman or girlfriend.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An old English board game in which players moved counters along a board, earning a double move when they reached the picture of a goose.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["goose iron"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/geese",
        "https://en.wiktionary.org/wiki/goose",
      ],
    },
  ],
  woven: [
    {
      word: "woven",
      phonetic: "/ˈwəʊvən/",
      phonetics: [
        { text: "/ˈwəʊvən/", audio: "" },
        {
          text: "/ˈwoʊvən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/woven-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2454395",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To form something by passing lengths or strands of material over and under one another.",
              synonyms: [],
              antonyms: [],
              example: "This loom weaves yarn into sweaters.",
            },
            {
              definition: "To spin a cocoon or a web.",
              synonyms: [],
              antonyms: [],
              example: "Spiders weave beautiful but deadly webs.",
            },
            {
              definition: "To unite by close connection or intermixture.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To compose creatively and intricately; to fabricate.",
              synonyms: [],
              antonyms: [],
              example: "to weave the plot of a story",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A cloth formed by weaving. It only stretches in the bias directions (between the warp and weft directions), unless the threads are elastic.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Fabricated by weaving.",
              synonyms: [],
              antonyms: [],
              example: "Woven kevlar is tough enough to be bulletproof.",
            },
            {
              definition: "Interlaced",
              synonyms: [],
              antonyms: [],
              example: "The woven words of the sonnet were deep and moving.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/weave",
        "https://en.wiktionary.org/wiki/woven",
      ],
    },
  ],
  posts: [
    {
      word: "posts",
      phonetic: "/pəʊsts/",
      phonetics: [
        { text: "/pəʊsts/", audio: "" },
        {
          text: "/poʊsts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/posts-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1640134",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A long dowel or plank protruding from the ground; a fencepost; a lightpost.",
              synonyms: [],
              antonyms: [],
              example: "ram a post into the ground",
            },
            {
              definition: "A stud; a two-by-four.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A pole in a battery.", synonyms: [], antonyms: [] },
            {
              definition:
                "A long, narrow piece inserted into a root canal to provide retention for a crown.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(chiefly a cappella) A prolonged final melody note, among moving harmony notes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A printing paper size measuring 19.25 inches x 15.5 inches.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A goalpost.", synonyms: [], antonyms: [] },
            {
              definition: "A location on a basketball court near the basket.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The doorpost of a victualler's shop or inn, on which were chalked the scores of customers; hence, a score; a debt.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To hang (a notice) in a conspicuous manner for general review.",
              synonyms: [],
              antonyms: [],
              example: "Post no bills.",
            },
            {
              definition:
                "To hold up to public blame or reproach; to advertise opprobriously; to denounce by public proclamation.",
              synonyms: [],
              antonyms: [],
              example: "to post someone for cowardice",
            },
            {
              definition:
                "To carry (an account) from the journal to the ledger.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To inform; to give the news to; to make acquainted with the details of a subject; often with up.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To pay (a blind).",
              synonyms: [],
              antonyms: [],
              example:
                "Since Jim was new to the game, he had to post $4 in order to receive a hand.",
            },
            {
              definition:
                "To put content online, usually through a publicly accessible mean, such as a video channel, gallery, message board, blog etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Each of a series of men stationed at specific places along a postroad, with responsibility for relaying letters and dispatches of the monarch (and later others) along the route.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A station, or one of a series of stations, established for the refreshment and accommodation of travellers on some recognized route.",
              synonyms: [],
              antonyms: [],
              example: "a stage or railway post",
            },
            {
              definition:
                "A military base; the place at which a soldier or a body of troops is stationed; also, the troops at such a station.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Someone who travels express along a set route carrying letters and dispatches; a courier.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An organisation for delivering letters, parcels etc., or the service provided by such an organisation.",
              synonyms: [],
              antonyms: [],
              example: "sent via post; parcel post",
            },
            {
              definition:
                "A single delivery of letters; the letters or deliveries that make up a single batch delivered to one person or one address.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A message posted in an electronic or Internet forum, or on a blog, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A moderate to deep passing route in which a receiver runs 10-20 yards from the line of scrimmage straight down the field, then cuts toward the middle of the field (towards the facing goalposts) at a 45-degree angle.",
              synonyms: [],
              antonyms: [],
              example: "Two of the receivers ran post patterns.",
            },
            {
              definition:
                "Haste or speed, like that of a messenger or mail carrier.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One who has charge of a station, especially a postal station.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To travel with relays of horses; to travel by post horses, originally as a courier.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To travel quickly; to hurry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To send (an item of mail etc.) through the postal service.",
              synonyms: [],
              antonyms: [],
              example:
                "Mail items posted before 7.00pm within the Central Business District and before 5.00pm outside the Central Business District will be delivered the next working day.",
            },
            {
              definition:
                "(horse-riding) To rise and sink in the saddle, in accordance with the motion of the horse, especially in trotting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To publish (a message) to a newsgroup, forum, blog, etc.",
              synonyms: [],
              antonyms: [],
              example:
                "I couldn't figure it out, so I posted a question on the mailing list.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An assigned station; a guard post.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An appointed position in an organization, job.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To enter (a name) on a list, as for service, promotion, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To assign to a station; to set; to place.",
              synonyms: [],
              antonyms: [],
              example: "Post a sentinel in front of the door.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A post mortem (investigation of body's cause of death).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/post",
        "https://en.wiktionary.org/wiki/posts",
      ],
    },
  ],
  hired: [
    {
      word: "hired",
      phonetic: "/ˈhaɪəd/",
      phonetics: [
        { text: "/ˈhaɪəd/", audio: "" },
        {
          text: "/ˈhaɪɚd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hired-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2454086",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To obtain the services of in return for fixed payment.",
              synonyms: ["rent"],
              antonyms: [],
              example:
                "We hired a car for two weeks because ours had broken down.",
            },
            {
              definition:
                "To employ; to obtain the services of (a person) in exchange for remuneration; to give someone a job.",
              synonyms: [],
              antonyms: [],
              example:
                "The company had problems when it tried to hire more skilled workers.",
            },
            {
              definition: "To exchange the services of for remuneration.",
              synonyms: [],
              antonyms: [],
              example:
                "They hired themselves out as day laborers.  They hired out their basement for Inauguration week.",
            },
            {
              definition: "To accomplish by paying for services.",
              synonyms: [],
              antonyms: [],
              example:
                "After waiting two years for her husband to finish the tiling, she decided to hire it done.",
            },
            {
              definition: "To accept employment.",
              synonyms: [],
              antonyms: [],
              example: "They hired out as day laborers.",
            },
          ],
          synonyms: ["rent"],
          antonyms: ["fire"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hire",
        "https://en.wiktionary.org/wiki/hired",
      ],
    },
  ],
  wrath: [
    {
      word: "wrath",
      phonetic: "/ɹæθ/",
      phonetics: [
        { text: "/ɹæθ/", audio: "" },
        { text: "/ɹɒθ/", audio: "" },
        {
          text: "/ɹæθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wrath-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=399118",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Great anger.",
              synonyms: ["fury", "ire"],
              antonyms: [],
              example:
                "Homer relates an episode in the Trojan War that reveals the tragic consequences of the wrath of Achilles.",
            },
            { definition: "Punishment.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["fury", "ire"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To anger; to enrage.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Wrathful; wroth; very angry.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/wrath"],
    },
  ],
  salad: [
    {
      word: "salad",
      phonetic: "/ˈsæləd/",
      phonetics: [
        {
          text: "/ˈsæləd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/salad-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1770041",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A food made primarily of a mixture of raw or cold ingredients, typically vegetables, usually served with a dressing such as vinegar or mayonnaise.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A raw vegetable of the kind used in salads.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/salad"],
    },
  ],
  bowed: [
    {
      word: "bowed",
      phonetic: "/baʊd/",
      phonetics: [
        {
          text: "/baʊd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bowed-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1684566",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To play music on (a stringed) instrument using a bow.",
              synonyms: [],
              antonyms: [],
              example: "The musician bowed his violin expertly.",
            },
            {
              definition: "To become bent or curved.",
              synonyms: [],
              antonyms: [],
              example: "The shelf bowed under the weight of the books.",
            },
            {
              definition: "To make something bend or curve.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To exercise powerful or controlling influence over; to bend, figuratively; to turn; to incline.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To premiere.",
              synonyms: [],
              antonyms: [],
              example: 'Cronenberg’s "Cosmopolis" bows in Cannes this week.',
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To bend oneself as a gesture of respect or deference.",
              synonyms: [],
              antonyms: [],
              example:
                "That singer always bows towards her audience for some reason.",
            },
            { definition: "To debut.", synonyms: [], antonyms: [] },
            {
              definition: "To defer (to something).",
              synonyms: [],
              antonyms: [],
              example: "I bow to your better judgement in the matter.",
            },
            {
              definition:
                "To give a direction, indication, or command to by bowing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bow",
        "https://en.wiktionary.org/wiki/bowed",
      ],
    },
    {
      word: "bowed",
      phonetic: "/bəʊd/",
      phonetics: [
        { text: "/bəʊd/", audio: "" },
        { text: "/boʊd/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To play music on (a stringed) instrument using a bow.",
              synonyms: [],
              antonyms: [],
              example: "The musician bowed his violin expertly.",
            },
            {
              definition: "To become bent or curved.",
              synonyms: [],
              antonyms: [],
              example: "The shelf bowed under the weight of the books.",
            },
            {
              definition: "To make something bend or curve.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To exercise powerful or controlling influence over; to bend, figuratively; to turn; to incline.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To premiere.",
              synonyms: [],
              antonyms: [],
              example: 'Cronenberg’s "Cosmopolis" bows in Cannes this week.',
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To bend oneself as a gesture of respect or deference.",
              synonyms: [],
              antonyms: [],
              example:
                "That singer always bows towards her audience for some reason.",
            },
            { definition: "To debut.", synonyms: [], antonyms: [] },
            {
              definition: "To defer (to something).",
              synonyms: [],
              antonyms: [],
              example: "I bow to your better judgement in the matter.",
            },
            {
              definition:
                "To give a direction, indication, or command to by bowing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Having a bow (rod for playing stringed instruments), e.g. a bowed instrument is played with a bow",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bow",
        "https://en.wiktionary.org/wiki/bowed",
      ],
    },
  ],
  tires: [
    {
      word: "tires",
      phonetic: "/ˈtaɪ̯ə(ɹ)z/",
      phonetics: [
        { text: "/ˈtaɪ̯ə(ɹ)z/", audio: "" },
        { text: "/ˈtaɪ̯ɚz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To become sleepy or weary.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make sleepy or weary.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To become bored or impatient (with).",
              synonyms: [],
              antonyms: [],
              example: "I tire of this book.",
            },
            { definition: "To bore.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Accoutrements, accessories.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Dress, clothes, attire.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A covering for the head; a headdress.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Metal rim of a wheel, especially that of a railroad locomotive.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The rubber covering on a wheel; a tyre.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A child's apron covering the upper part of the body, and tied with tape or cord; a pinafore. Also tier.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To dress or adorn.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To seize, pull, and tear prey, as a hawk does.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To seize, rend, or tear something as prey; to be fixed upon, or engaged with, anything.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A tier, row, or rank.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tire",
        "https://en.wiktionary.org/wiki/tires",
      ],
    },
  ],
  shark: [
    {
      word: "shark",
      phonetic: "/ʃɑːk/",
      phonetics: [
        { text: "/ʃɑːk/", audio: "" },
        {
          text: "/ʃɑɹk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shark-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769517",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A scaleless, predatory fish of the superorder Selachimorpha, with a cartilaginous skeleton and 5 to 7 gill slits on each side of its head.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To fish for sharks.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shark"],
    },
    {
      word: "shark",
      phonetic: "/ʃɑːk/",
      phonetics: [
        { text: "/ʃɑːk/", audio: "" },
        {
          text: "/ʃɑɹk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shark-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769517",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Someone who exploits others, for example by trickery, lies, usury, extortion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sleazy and amoral lawyer",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An ambulance chaser.", synonyms: [], antonyms: [] },
            {
              definition:
                "A relentless and resolute person or group, especially in business.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A very good poker or pool player. Compare fish (a bad poker player).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person who feigns ineptitude to win money from others.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["hustler"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To steal or obtain through fraud.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To play the petty thief; to practice fraud or trickery; to swindle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To live by shifts and stratagems.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shark"],
    },
    {
      word: "shark",
      phonetic: "/ʃɑːk/",
      phonetics: [
        { text: "/ʃɑːk/", audio: "" },
        {
          text: "/ʃɑɹk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shark-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769517",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To pick or gather indiscriminately or covertly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shark"],
    },
  ],
  belts: [
    {
      word: "belts",
      phonetic: "/bɛlts/",
      phonetics: [{ text: "/bɛlts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A band worn around the waist to hold clothing to one's body (usually pants), hold weapons (such as a gun or sword), or serve as a decorative piece of clothing.",
              synonyms: [],
              antonyms: [],
              example:
                "As part of the act, the fat clown's belt broke, causing his pants to fall down.",
            },
            {
              definition:
                "A band used as a restraint for safety purposes, such as a seat belt.",
              synonyms: [],
              antonyms: [],
              example:
                "Keep your belt fastened; this is going to be quite a bumpy ride.",
            },
            {
              definition:
                "A band that is used in a machine to help transfer motion or power.",
              synonyms: [],
              antonyms: [],
              example:
                "The motor had a single belt that snaked its way back and forth around a variety of wheels.",
            },
            {
              definition:
                "Anything that resembles a belt, or that encircles or crosses like a belt; a strip or stripe.",
              synonyms: [],
              antonyms: [],
              example: "a belt of trees; a belt of sand",
            },
            {
              definition:
                "A trophy in the shape of a belt, generally awarded for martial arts.",
              synonyms: [],
              antonyms: [],
              example: "the heavyweight belt",
            },
            {
              definition:
                "A collection of rocky-constituted bodies (such as asteroids) which orbit a star.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of certain girdles or zones on the surface of the planets Jupiter and Saturn, supposed to be of the nature of clouds.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A powerful blow, often made with a fist or heavy object.",
              synonyms: [],
              antonyms: [],
              example:
                "After the bouncer gave him a solid belt to the gut, Simon had suddenly had enough of barfighting.",
            },
            {
              definition: "A quick drink of liquor.",
              synonyms: [],
              antonyms: [],
              example: "Care to join me in a belt of scotch?",
            },
            {
              definition:
                "(usually capitalized) A geographical region known for a particular product, feature or demographic (Corn Belt, Bible Belt, Black Belt, Green Belt).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The part of the strike zone at the height of the batter's waist.",
              synonyms: [],
              antonyms: [],
              example: "That umpire called that pitch a strike at the belt.",
            },
            {
              definition:
                "Device that holds and feeds cartridges into a belt-fed weapon",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "restraint",
            "safety belt",
            "seat belt",
            "girdle",
            "sash",
            "strap",
            "waistband",
            "blow",
            "punch",
            "sock",
            "wallop",
            "dram",
            "nip",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To encircle.",
              synonyms: [],
              antonyms: [],
              example:
                "The small town was belted by cornfields in all directions.",
            },
            {
              definition: "To fasten a belt on.",
              synonyms: [],
              antonyms: [],
              example: "Edgar belted himself in and turned the car's ignition.",
            },
            {
              definition:
                "To invest (a person) with a belt as part of a formal ceremony such as knighthood.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To hit with a belt.",
              synonyms: [],
              antonyms: [],
              example:
                "The child was misbehaving so he was belted as punishment.",
            },
            {
              definition: "To scream or sing in a loud manner.",
              synonyms: [],
              antonyms: [],
              example: "He belted out the national anthem.",
            },
            {
              definition: "To drink quickly, often in gulps.",
              synonyms: [],
              antonyms: [],
              example: "He belted down a shot of whisky.",
            },
            {
              definition: "To hit someone or something.",
              synonyms: [],
              antonyms: [],
              example:
                "The angry player belted the official across the face, and as a result was ejected from the game.",
            },
            {
              definition:
                "To hit a pitched ball a long distance, usually for a home run.",
              synonyms: [],
              antonyms: [],
              example: "He belted that pitch over the grandstand.",
            },
            {
              definition: "To move very fast",
              synonyms: [],
              antonyms: [],
              example: "He was really belting along.",
            },
          ],
          synonyms: [
            "gulp",
            "pound",
            "slurp",
            "circle",
            "girdle",
            "surround",
            "buckle",
            "fasten",
            "strap",
            "bash",
            "clobber",
            "smack",
            "wallop",
            "strap",
            "whip",
            "book",
            "speed",
            "whiz",
            "zoom",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/belt",
        "https://en.wiktionary.org/wiki/belts",
      ],
    },
  ],
  grasp: [
    {
      word: "grasp",
      phonetic: "/ɡɹɑːsp/",
      phonetics: [
        { text: "/ɡɹɑːsp/", audio: "" },
        {
          text: "/ɡɹæsp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grasp-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100436",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(sometimes figurative) Grip.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Understanding.", synonyms: [], antonyms: [] },
            {
              definition:
                "That which is accessible; that which is within one's reach or ability.",
              synonyms: [],
              antonyms: [],
              example: "The goal is within my grasp.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To grip; to take hold, particularly with the hand.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To understand.",
              synonyms: [],
              antonyms: [],
              example:
                "I have never been able to grasp the concept of infinity.",
            },
            {
              definition:
                "To take advantage of something, to seize, to jump at a chance.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "clasp",
            "grip",
            "hold tight",
            "jump at the chance",
            "jump on",
            "comprehend",
            "fathom",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/grasp"],
    },
  ],
  blast: [
    {
      word: "blast",
      phonetic: "/blɑːst/",
      phonetics: [
        {
          text: "/blɑːst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/blast-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=86872704",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/blæst/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A violent gust of wind.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A forcible stream of gas or liquid from an orifice, for example from a bellows, the mouth, etc.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A hit from a pipe.", synonyms: [], antonyms: [] },
            {
              definition:
                "The continuous blowing to which one charge of ore or metal is subjected in a furnace",
              synonyms: [],
              antonyms: [],
              example: "many tons of iron were melted at a blast",
            },
            {
              definition:
                "The exhaust steam from an engine, driving a column of air out of a boiler chimney, and thus creating an intense draught through the fire; also, any draught produced by the blast.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An explosion, especially for the purpose of destroying a mass of rock, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An explosive charge for blasting.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A loud, sudden sound.", synonyms: [], antonyms: [] },
            {
              definition:
                "A sudden, pernicious effect, as if by a noxious wind, especially on animals and plants; a blight.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A good time; an enjoyable moment.",
              synonyms: [],
              antonyms: [],
              example: "We had a blast at the party last night.",
            },
            {
              definition:
                "A promotional message sent to an entire mailing list.",
              synonyms: [],
              antonyms: [],
              example: "an e-mail blast; a fax blast",
            },
            {
              definition: "A flatulent disease of sheep.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/blast"],
    },
    {
      word: "blast",
      phonetic: "/blɑːst/",
      phonetics: [
        {
          text: "/blɑːst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/blast-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=86872704",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/blæst/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make an impression on, by making a loud blast or din.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To make a loud noise.", synonyms: [], antonyms: [] },
            {
              definition: "To shatter, as if by an explosion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To open up a hole in, usually by means of a sudden and imprecise method (such as an explosion).",
              synonyms: [],
              antonyms: [],
              example: "Blast right through it.",
            },
            {
              definition: "To curse; to damn.",
              synonyms: [],
              antonyms: [],
              example: "Blast it! Foiled again.",
            },
            {
              definition:
                "(sci-fi) To shoot, especially with an energy weapon (as opposed to one which fires projectiles).",
              synonyms: [],
              antonyms: [],
              example:
                "Chewbacca blasted the Stormtroopers with his laser rifle.",
            },
            {
              definition: "To shoot; kick the ball in hope of scoring a goal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To criticize or reprimand severely; to verbally discipline or punish.",
              synonyms: [],
              antonyms: [],
              example:
                "My manager suddenly blasted me yesterday for being a little late to work for five days in a row, because I was never getting myself up on time.",
            },
            {
              definition: "To blight or wither.",
              synonyms: [],
              antonyms: [],
              example: "A cold wind blasted the rose plants.",
            },
            {
              definition: "To be blighted or withered.",
              synonyms: [],
              antonyms: [],
              example: "The bud blasted in the blossom.",
            },
            {
              definition: "To blow, for example on a trumpet.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "(chiefly British) To show displeasure; damn",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/blast"],
    },
    {
      word: "blast",
      phonetic: "/blɑːst/",
      phonetics: [
        {
          text: "/blɑːst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/blast-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=86872704",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/blæst/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An immature or undifferentiated cell (e.g., lymphoblast, myeloblast).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/blast"],
    },
    {
      word: "blast",
      phonetic: "/blɑːst/",
      phonetics: [
        {
          text: "/blɑːst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/blast-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=86872704",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/blæst/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To run a nucleotide sequence (for nucleic acids) or an amino acid sequence (for proteins) through a BLAST (Basic Local Alignment Search Tool).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/blast"],
    },
  ],
  polar: [
    {
      word: "polar",
      phonetic: "/ˈpəʊ̯lə(ɹ)/",
      phonetics: [
        { text: "/ˈpəʊ̯lə(ɹ)/", audio: "" },
        { text: "/ˈpoʊ̯lɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The line joining the points of contact of tangents drawn to meet a curve from a point called the pole of the line.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of or having a pole or polarity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of, relating to, measured from, or referred to a geographic pole (the North Pole or South Pole); within the Arctic or Antarctic circles.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(space sciences) Of an orbit that passes over, or near, one of these poles.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Having a dipole; ionic.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of a coordinate system, specifying the location of a point in a plane by using a radius and an angle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a question) Having but two possible answers, yes and no.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/polar"],
    },
  ],
  fungi: [
    {
      word: "fungi",
      phonetic: "/ˈfʌn.dʒaɪ/",
      phonetics: [
        {
          text: "/ˈfʌn.dʒaɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fungi-1-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1408106",
        },
        {
          text: "/ˈfʌn.dʒaɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fungi-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=6036801",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any member of the kingdom Fungi; a eukaryotic organism typically having chitin cell walls but no chlorophyll or plastids. Fungi may be unicellular or multicellular.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A spongy, abnormal excrescence, such as excessive granulation tissue formed in a wound.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fungi",
        "https://en.wiktionary.org/wiki/fungus",
      ],
    },
    {
      word: "fungi",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A style of folk and popular music from the Virgin Islands, traditionally performed by bands consisting of ukulele, banjo, guitar and washboard with various percussion instruments on rhythm.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["quelbe", "scratch"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fungi"],
    },
    {
      word: "fungi",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A cornmeal dish eaten in the Caribbean, usually made with okra and served with salt fish, shellfish or chicken; variant recipes are also known as cou-cou (coucou) or coo-coo (coocoo).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fungee",
        "https://en.wiktionary.org/wiki/fungi",
      ],
    },
  ],
  tends: [
    {
      word: "tends",
      phonetic: "/tɛndz/",
      phonetics: [
        {
          text: "/tɛndz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tends-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2454337",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(Old English law) To make a tender of; to offer or tender.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(followed by a to-infinitive) To be likely, or probable to do something, or to have a certain habit or leaning.",
              synonyms: [],
              antonyms: [],
              example: "It tends to snow here in winter.",
            },
            {
              definition: "To contribute to or toward some outcome.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(with to) To look after (e.g. an ill person.)",
              synonyms: [],
              antonyms: [],
              example:
                "We need to tend to the garden, which has become a mess.",
            },
            {
              definition:
                "To accompany as an assistant or protector; to care for the wants of; to look after; to watch; to guard.",
              synonyms: [],
              antonyms: [],
              example: "Shepherds tend their flocks.",
            },
            {
              definition:
                "To wait (upon), as attendants or servants; to serve; to attend.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To await; to expect.", synonyms: [], antonyms: [] },
            {
              definition:
                "To be attentive to; to note carefully; to attend to.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To manage (an anchored vessel) when the tide turns, to prevent it from entangling the cable when swinging.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "guard",
            "look after",
            "watch",
            "attend to",
            "care for",
            "minister",
            "nurse",
            "see to",
            "take care of",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To kindle; ignite; set on fire; light; inflame; burn.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tend",
        "https://en.wiktionary.org/wiki/tends",
      ],
    },
  ],
  pearl: [
    {
      word: "pearl",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pearl-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241558",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/pɜːl/", audio: "" },
        { text: "/pɝl/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A shelly concretion, usually rounded, and having a brilliant luster, with varying tints, found in the mantle, or between the mantle and shell, of certain bivalve mollusks, especially in the pearl oysters and river mussels, and sometimes in certain univalves. It is usually due to a secretion of shelly substance around some irritating foreign particle. Its substance is the same as nacre, or mother-of-pearl. Round lustrous pearls are used in jewellery.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Something precious.", synonyms: [], antonyms: [] },
            {
              definition:
                "A capsule of gelatin or similar substance containing liquid for e.g. medicinal application.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Nacre, or mother-of-pearl.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A whitish speck or film on the eye.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A fish allied to the turbot; the brill.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A light-colored tern.", synonyms: [], antonyms: [] },
            {
              definition:
                "One of the circle of tubercles which form the bur on a deer's antler.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The size of type between diamond and agate, standardized as 5-point.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A fringe or border.", synonyms: [], antonyms: [] },
            { definition: "A jewel or gem.", synonyms: [], antonyms: [] },
            { definition: "The clitoris.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(sometimes figurative) To set or adorn with pearls, or with mother-of-pearl.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause to resemble pearls in shape; to make into small round grains.",
              synonyms: [],
              antonyms: [],
              example: "to pearl barley",
            },
            {
              definition:
                "To cause to resemble pearls in lustre or iridescence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To resemble pearl or pearls.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To hunt for pearls",
              synonyms: [],
              antonyms: [],
              example: "to go pearling",
            },
            {
              definition:
                "To dig the nose of one's surfboard into the water, often on takeoff.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pearl"],
    },
  ],
  loads: [
    {
      word: "loads",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/loads-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79293630",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ləʊdz/", audio: "" },
        { text: "/loʊdz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A burden; a weight to be carried.",
              synonyms: [],
              antonyms: [],
              example:
                "I struggled up the hill with the heavy load in my rucksack.",
            },
            {
              definition:
                "A worry or concern to be endured, especially in the phrase a load off one's mind.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A certain number of articles or quantity of material that can be transported or processed at one time.",
              synonyms: [],
              antonyms: [],
              example:
                "She put another load of clothes in the washing machine.",
            },
            {
              definition:
                "A quantity of washing put into a washing machine for a wash cycle.",
              synonyms: [],
              antonyms: [],
              example: "I put a load on before we left.",
            },
            {
              definition:
                "(in combination) Used to form nouns that indicate a large quantity, often corresponding to the capacity of a vehicle",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(often in the plural) A large number or amount.",
              synonyms: [],
              antonyms: [],
              example: "I got a load of emails about that.",
            },
            {
              definition: "The volume of work required to be performed.",
              synonyms: [],
              antonyms: [],
              example: "Will our web servers be able to cope with that load?",
            },
            {
              definition:
                "The force exerted on a structural component such as a beam, girder, cable etc.",
              synonyms: [],
              antonyms: [],
              example:
                "Each of the cross-members must withstand a tensile load of 1,000 newtons.",
            },
            {
              definition:
                "The electrical current or power delivered by a device.",
              synonyms: [],
              antonyms: [],
              example:
                "I'm worried that the load on that transformer will be too high.",
            },
            {
              definition:
                "A resistive force encountered by a prime mover when performing work.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any component that draws current or power from an electrical circuit.",
              synonyms: [],
              antonyms: [],
              example:
                "Connect a second 24-ohm load across the power supply's output terminals.",
            },
            {
              definition: "A unit of measure for various quantities.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The viral load", synonyms: [], antonyms: [] },
            {
              definition:
                "A very small explosive inserted as a gag into a cigarette or cigar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The charge of powder for a firearm.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Weight or violence of blows.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The contents (e.g. semen) of an ejaculation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Nonsense; rubbish.",
              synonyms: [],
              antonyms: [],
              example: "What a load!",
            },
            {
              definition:
                "The process of loading something, i.e. transferring it into memory or over a network, etc.",
              synonyms: [],
              antonyms: [],
              example:
                "All of those uncompressed images are going to slow down the page load.",
            },
          ],
          synonyms: [
            "cumwad",
            "wad",
            "carrus",
            "cartload",
            "charrus",
            "fodder",
            "fother",
            "charge",
            "freight",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To put a load on or in (a means of conveyance or a place of storage).",
              synonyms: [],
              antonyms: [],
              example: "The dock workers refused to load the ship.",
            },
            {
              definition:
                "To place in or on a conveyance or a place of storage.",
              synonyms: [],
              antonyms: [],
              example: "He loaded his stuff into his storage locker.",
            },
            {
              definition: "To put a load on something.",
              synonyms: [],
              antonyms: [],
              example:
                "The truck was supposed to leave at dawn, but in fact we spent all morning loading.",
            },
            {
              definition: "To receive a load.",
              synonyms: [],
              antonyms: [],
              example: "The truck is designed to load easily.",
            },
            {
              definition: "To be placed into storage or conveyance.",
              synonyms: [],
              antonyms: [],
              example: "The containers load quickly and easily.",
            },
            {
              definition: "To fill (a firearm or artillery) with munition.",
              synonyms: [],
              antonyms: [],
              example:
                "I pulled the trigger, but nothing happened. I had forgotten to load the gun.",
            },
            {
              definition:
                "To insert (an item or items) into an apparatus so as to ready it for operation, such as a reel of film into a camera, sheets of paper into a printer etc.",
              synonyms: [],
              antonyms: [],
              example:
                "Now that you've loaded the camera [with film], you're ready to start shooting.",
            },
            {
              definition: "To fill (an apparatus) with raw material.",
              synonyms: [],
              antonyms: [],
              example:
                "The workers loaded the blast furnace with coke and ore.",
            },
            {
              definition: "To be put into use in an apparatus.",
              synonyms: [],
              antonyms: [],
              example: "The cartridge was designed to load easily.",
            },
            {
              definition:
                "To read (data or a program) from a storage medium into computer memory.",
              synonyms: [],
              antonyms: [],
              example: "Click OK to load the selected data.",
            },
            {
              definition:
                "To transfer from a storage medium into computer memory.",
              synonyms: [],
              antonyms: [],
              example: "This program takes an age to load.",
            },
            {
              definition: "To put runners on first, second and third bases",
              synonyms: [],
              antonyms: [],
              example: "He walks to load the bases.",
            },
            {
              definition: "To tamper with so as to produce a biased outcome.",
              synonyms: [],
              antonyms: [],
              example:
                "The wording of the ballot paper loaded the vote in favour of the Conservative candidate.",
            },
            {
              definition:
                "To ask or adapt a question so that it will be more likely to be answered in a certain way.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To encumber with something negative, to place as an encumbrance.",
              synonyms: [],
              antonyms: [],
              example: "The new owners had loaded the company with debt.",
            },
            {
              definition: "To provide in abundance.",
              synonyms: [],
              antonyms: [],
              example: "He loaded carbs into his system before the marathon.",
            },
            {
              definition:
                "To weight (a cane, whip, etc.) with lead or similar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To adulterate or drug.",
              synonyms: [],
              antonyms: [],
              example: "to load wine",
            },
            { definition: "To magnetize.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Lots, much, plenty, a great deal.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["a lot", "a thing or two", "tons"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/load",
        "https://en.wiktionary.org/wiki/loads",
      ],
    },
  ],
  jokes: [
    {
      word: "jokes",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/jokes-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241548",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/dʒəʊks/", audio: "" },
        { text: "/dʒoʊks/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "An amusing story.", synonyms: [], antonyms: [] },
            {
              definition:
                "Something said or done for amusement, not in seriousness.",
              synonyms: [],
              antonyms: [],
              example: "It was a joke!",
            },
            {
              definition:
                "The root cause or main issue, especially an unexpected one",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A laughably worthless thing or person; a sham.",
              synonyms: [],
              antonyms: [],
              example: "The president was a joke.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To do or say something for amusement rather than seriously.",
              synonyms: [],
              antonyms: [],
              example: "I didn’t mean what I said — I was only joking.",
            },
            {
              definition:
                "(intransitive, followed by with) To dupe in a friendly manner for amusement; to mess with, play with.",
              synonyms: [],
              antonyms: [],
              example: "Relax, man, I'm just joking with you.",
            },
            {
              definition: "To make merry with; to make jokes upon; to rally.",
              synonyms: [],
              antonyms: [],
              example: "to joke a comrade",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Really good.", synonyms: [], antonyms: [] },
            {
              definition: "Funny or entertaining.",
              synonyms: [],
              antonyms: [],
              example: "Man, that video is bare jokes!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/joke",
        "https://en.wiktionary.org/wiki/jokes",
      ],
    },
  ],
  veins: [
    {
      word: "veins",
      phonetic: "/veɪnz/",
      phonetics: [{ text: "/veɪnz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A blood vessel that transports blood from the capillaries back to the heart.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(in plural) The entrails of a shrimp.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In leaves, a thickened portion of the leaf containing the vascular bundle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The nervure of an insect’s wing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A stripe or streak of a different colour or composition in materials such as wood, cheese, marble or other rocks.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A topic of discussion; a train of association, thoughts, emotions, etc.",
              synonyms: [],
              antonyms: [],
              example: "in the same vein",
            },
            {
              definition: "A style, tendency, or quality.",
              synonyms: [],
              antonyms: [],
              example: "The play is in a satirical vein.",
            },
            {
              definition:
                "A fissure, cleft or cavity, as in the earth or other substance.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To mark with veins or a vein-like pattern.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/vein",
        "https://en.wiktionary.org/wiki/veins",
      ],
    },
  ],
  frost: [
    {
      word: "frost",
      phonetic: "/fɹɑst/",
      phonetics: [
        { text: "/fɹɑst/", audio: "" },
        { text: "/fɹɒst/", audio: "" },
        {
          text: "/fɹɔst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/frost-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=454000",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A cover of minute ice crystals on objects that are exposed to the air. Frost is formed by the same process as dew, except that the temperature of the frosted object is below freezing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The cold weather that causes these ice crystals to form.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Coldness or insensibility; severity or rigidity of character.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The act of freezing; the congelation of water or other liquid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A shade of white, like that of frost.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A disappointment; a cheat.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To cover with frost.", synonyms: [], antonyms: [] },
            {
              definition: "To become covered with frost.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To coat (something, e.g. a cake) with icing to resemble frost.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To anger or annoy.",
              synonyms: [],
              antonyms: [],
              example: "I think the boss's decision frosted him a bit.",
            },
            {
              definition:
                "To sharpen (the points of a horse's shoe) to prevent it from slipping on ice.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/frost"],
    },
  ],
  hears: [
    {
      word: "hears",
      phonetic: "/hɪə(ɹ)z/",
      phonetics: [
        { text: "/hɪə(ɹ)z/", audio: "" },
        {
          text: "/hɪɹz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hears-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1230269",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
        { text: "/hjɜzː/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(stative) To perceive sounds through the ear.",
              synonyms: [],
              antonyms: [],
              example: "I was deaf, and now I can hear.",
            },
            {
              definition:
                "(stative) To perceive (a sound, or something producing a sound) with the ear, to recognize (something) in an auditory way.",
              synonyms: [],
              antonyms: [],
              example: "I heard a sound from outside the window.",
            },
            {
              definition:
                "To exercise this faculty intentionally; to listen to.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To listen favourably to; to grant (a request etc.).",
              synonyms: [],
              antonyms: [],
              example: "Eventually the king chose to hear her entreaties.",
            },
            {
              definition: "To receive information about; to come to learn of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(with from) To be contacted by.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To listen to (a person, case) in a court of law; to try.",
              synonyms: [],
              antonyms: [],
              example: "Your case will be heard at the end of the month.",
            },
            {
              definition:
                "To sympathize with; to understand the feelings or opinion of.",
              synonyms: [],
              antonyms: [],
              example: "You're tired of all the ads on TV? I hear ya.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hear",
        "https://en.wiktionary.org/wiki/hears",
      ],
    },
  ],
  loses: [
    {
      word: "loses",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cause (something) to cease to be in one's possession or capability due to unfortunate or unknown circumstances, events or reasons.",
              synonyms: [],
              antonyms: [],
              example: "He lost his hearing in the explosion.",
            },
            {
              definition:
                "To wander from; to miss, so as not to be able to find; to go astray from.",
              synonyms: [],
              antonyms: [],
              example: "I lost my way in the forest.",
            },
            {
              definition: "To fail to win (a game, competition, trial, etc).",
              synonyms: [],
              antonyms: [],
              example: "We lost the football match.",
            },
            {
              definition:
                "To be unable to follow or trace (somebody or something) any longer.",
              synonyms: [],
              antonyms: [],
              example:
                "Mission control lost the satellite as its signal died down.",
            },
            {
              definition:
                "To cause (somebody) to be unable to follow or trace one any longer.",
              synonyms: [],
              antonyms: [],
              example: "We managed to lose our pursuers in the forest.",
            },
            {
              definition:
                "To cease exhibiting; to overcome (a behavior or emotion).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To shed, remove, discard, or eliminate.",
              synonyms: [],
              antonyms: [],
              example: "When we get into the building, please lose the hat.",
            },
            {
              definition: "Of a clock, to run slower than expected.",
              synonyms: [],
              antonyms: [],
              example:
                "It's already 5:30? My watch must have lost a few minutes.",
            },
            {
              definition:
                "(ditransitive) To cause (someone) the loss of something; to deprive of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fail to catch with the mind or senses; to miss.",
              synonyms: [],
              antonyms: [],
              example: "I lost a part of what he said.",
            },
            {
              definition: "To cause to part with; to deprive of.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "leave behind",
            "mislay",
            "forfeit",
            "drop",
            "shed",
            "ditch",
            "drop",
            "dump",
            "get rid of",
            "jettison",
          ],
          antonyms: [
            "find",
            "acquire",
            "come across",
            "discover",
            "find",
            "gain",
            "get",
            "pick up",
            "procure",
            "snag",
            "come first",
            "win",
            "win",
            "gain",
            "put on",
            "pick up",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lose",
        "https://en.wiktionary.org/wiki/loses",
      ],
    },
  ],
  hosts: [
    {
      word: "hosts",
      phonetic: "/həʊsts/",
      phonetics: [
        { text: "/həʊsts/", audio: "" },
        {
          text: "/hoʊsts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hosts-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651039",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One which receives or entertains a guest, socially, commercially, or officially.",
              synonyms: [],
              antonyms: [],
              example:
                "A good host is always considerate of the guest’s needs.",
            },
            {
              definition: "One that provides a facility for an event.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person or organization responsible for running an event.",
              synonyms: [],
              antonyms: [],
              example:
                "Our company is host of the annual conference this year.",
            },
            {
              definition:
                "A moderator or master of ceremonies for a performance.",
              synonyms: [],
              antonyms: [],
              example:
                "The host was terrible, but the acts themselves were good.",
            },
            {
              definition: "Any computer attached to a network.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cell or organism which harbors another organism or biological entity, usually a parasite.",
              synonyms: [],
              antonyms: [],
              example:
                "Viruses depend on the host that they infect in order to be able to reproduce.",
            },
            {
              definition:
                "(evolution) An organism bearing certain genetic material.",
              synonyms: [],
              antonyms: [],
              example:
                "The so-called junk DNA is known, so far, to provide no apparent benefit to its host.",
            },
            {
              definition:
                "A paid male companion offering conversation and in some cases sex, as in certain types of bar in Japan.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To perform the role of a host.",
              synonyms: [],
              antonyms: [],
              example: "I was terrible at hosting that show.",
            },
            { definition: "To lodge at an inn.", synonyms: [], antonyms: [] },
            {
              definition:
                "To run software made available to a remote user or process.",
              synonyms: [],
              antonyms: [],
              example: "Kremvax hosts a variety of services.",
            },
          ],
          synonyms: ["presenter"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A multitude of people arrayed as an army; used also in religious senses, as: Heavenly host (of angels)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A large number of items; a large inventory.",
              synonyms: [],
              antonyms: [],
              example: "The dealer stocks a host of parts for my Model A.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The consecrated bread or wafer of the Eucharist.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/host",
        "https://en.wiktionary.org/wiki/hosts",
      ],
    },
  ],
  diver: [
    {
      word: "diver",
      phonetic: "/ˈdaɪ̯vəɹ/",
      phonetics: [
        {
          text: "/ˈdaɪ̯vəɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/diver-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79268356",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈdaɪ̯vəɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/diver-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80536200",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Someone who dives, especially as a sport.",
              synonyms: ["urinator"],
              antonyms: [],
            },
            {
              definition: "Someone who works underwater; a frogman.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The loon (bird).", synonyms: [], antonyms: [] },
            {
              definition: "The New Zealand sand diver.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The long-finned sand diver.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A passenger carrying vehicle using an underground route; specially, a diver tram, one using the former Kingsway tramway subway (1906-1952).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A pickpocket.", synonyms: [], antonyms: [] },
            {
              definition:
                "A competitor in certain sports who is known to regularly imitate being fouled, with the purpose of getting his/her opponent penalised.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["urinator"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/diver"],
    },
  ],
  phase: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  toads: [
    {
      word: "toads",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An amphibian, a kind of frog (order Anura) with shorter hindlegs and a drier, wartier skin, many in family Bufonidae.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A contemptible or unpleasant person.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An ugly person.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["pad", "paddock"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To expel (a user) permanently from a MUD or similar system, so that their account is deleted.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/toad",
        "https://en.wiktionary.org/wiki/toads",
      ],
    },
  ],
  alert: [
    {
      word: "alert",
      phonetic: "/əˈlɜːt/",
      phonetics: [
        { text: "/əˈlɜːt/", audio: "" },
        {
          text: "/əˈlɝt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/alert-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2650631",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "An alarm.", synonyms: [], antonyms: [] },
            {
              definition:
                "A notification of higher importance than an advisory.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A state of readiness for potential combat.",
              synonyms: [],
              antonyms: [],
              example: "an airborne alert; ground alert",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Attentive; awake; on guard.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Brisk; nimble; moving with celerity.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/alert"],
    },
    {
      word: "alert",
      phonetic: "/əˈlɜːt/",
      phonetics: [
        { text: "/əˈlɜːt/", audio: "" },
        {
          text: "/əˈlɝt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/alert-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2650631",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To give warning to.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/alert"],
    },
  ],
  tasks: [
    {
      word: "tasks",
      phonetic: "/tæsks/",
      phonetics: [
        {
          text: "/tæsks/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tasks-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80152023",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A piece of work done as part of one’s duties.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A difficult or tedious undertaking.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An objective.", synonyms: [], antonyms: [] },
            {
              definition: "A process or execution of a program.",
              synonyms: [],
              antonyms: [],
              example: "The user killed the frozen task.",
            },
          ],
          synonyms: [
            "undertaking",
            "goal",
            "objective",
            "chore",
            "job",
            "process",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To assign a task to, or impose a task on.",
              synonyms: [],
              antonyms: [],
              example:
                "On my first day in the office, I was tasked with sorting a pile of invoices.",
            },
            {
              definition:
                "To oppress with severe or excessive burdens; to tax.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To charge, as with a fault.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/task",
        "https://en.wiktionary.org/wiki/tasks",
      ],
    },
  ],
  seams: [
    {
      word: "seams",
      phonetic: "/siːmz/",
      phonetics: [{ text: "/siːmz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A folded-back and stitched piece of fabric; especially, the stitching that joins two or more pieces of fabric.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A suture.", synonyms: [], antonyms: [] },
            {
              definition:
                "A thin stratum, especially of an economically viable material such as coal or mineral.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The stitched equatorial seam of a cricket ball; the sideways movement of a ball when it bounces on the seam.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A joint formed by mating two separate sections of materials.",
              synonyms: [],
              antonyms: [],
              example:
                "Seams can be made or sealed in a variety of ways, including adhesive bonding, hot-air welding, solvent welding, using adhesive tapes, sealant, etc.",
            },
            {
              definition:
                "A line or depression left by a cut or wound; a scar; a cicatrix.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A line of junction; a joint.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To put together with a seam.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make the appearance of a seam in, as in knitting a stocking; hence, to knit with a certain stitch, like that in such knitting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To mark with a seam or line; to scar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To crack open along a seam.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of the ball, to move sideways after bouncing on the seam.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of a bowler, to make the ball move thus.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An old English measure of grain, containing eight bushels.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An old English measure of glass, containing twenty-four weys of five pounds, or 120 pounds.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Grease; tallow; lard", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/seam",
        "https://en.wiktionary.org/wiki/seams",
      ],
    },
  ],
  coral: [
    {
      word: "coral",
      phonetic: "/ˈkɒɹəl/",
      phonetics: [
        {
          text: "/ˈkɒɹəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/coral-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89034008",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈkɔɹəl/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A hard substance made of the limestone skeletons of marine polyps.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A colony of marine polyps.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A somewhat yellowish pink colour, the colour of red coral.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The ovaries of a cooked lobster; so called from their colour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of coral, usually fitted with small bells and other appurtenances, used by children as a plaything.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Made of coral.", synonyms: [], antonyms: [] },
            {
              definition: "Having the yellowish pink colour of coral.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/coral"],
    },
  ],
  focus: [
    {
      word: "focus",
      phonetic: "/ˈfəʊ.kəs/",
      phonetics: [
        { text: "/ˈfəʊ.kəs/", audio: "" },
        { text: "/ˈfoʊ.kəs/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A point at which reflected or refracted rays of light converge.",
              synonyms: [],
              antonyms: [],
              example:
                "The heat of sunlight at the focus of a magnifying glass can easily set dry leaves on fire.",
            },
            {
              definition:
                "A point of a conic at which rays reflected from a curve or surface converge.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The fact of the convergence of light on the photographic medium.",
              synonyms: [],
              antonyms: [],
              example:
                "Unfortunately, the license plate is out of focus in this image.",
            },
            {
              definition:
                "The quality of the convergence of light on the photographic medium.",
              synonyms: [],
              antonyms: [],
              example:
                "During this scene, the boy’s face shifts subtly from soft focus into sharp focus.",
            },
            {
              definition: "Concentration of attention.",
              synonyms: [],
              antonyms: [],
              example:
                "I believe I can bring the high degree of focus required for this important job.",
            },
            {
              definition:
                "The exact point of where an earthquake occurs, in three dimensions (underneath the epicentre).",
              synonyms: [],
              antonyms: [],
              example:
                "The earthquake's focus was at exactly 37 degrees north, 18 degrees south, seventy five meters below the ground.",
            },
            {
              definition:
                "The indicator of the currently active element in a user interface.",
              synonyms: [],
              antonyms: [],
              example:
                "Text entered at the keyboard or pasted from a clipboard is sent to the component which currently has the focus.",
            },
            {
              definition:
                "The most important word or phrase in a sentence or passage, or the one that imparts information.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An object used in casting a magic spell.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(followed by on or upon) To concentrate one's attention.",
              synonyms: [],
              antonyms: [],
              example: "I have to focus on my work.",
            },
            {
              definition:
                "To cause (rays of light, etc) to converge at a single point.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To adjust (a lens, an optical instrument) in order to position an image with respect to the focal plane.",
              synonyms: [],
              antonyms: [],
              example:
                "You'll need to focus the microscope carefully in order to capture the full detail of this surface.",
            },
            {
              definition: "To concentrate one’s attention.",
              synonyms: [],
              antonyms: [],
              example:
                "If you're going to beat your competitors, you need to focus.",
            },
            {
              definition:
                "To transfer the input focus to (a visual element), so that it receives subsequent input.",
              synonyms: [],
              antonyms: [],
              example:
                "The text box won't receive the user's keystrokes unless you explicitly focus it.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/focus"],
    },
  ],
  naked: [
    {
      word: "naked",
      phonetic: "/ˈnɛkɪd/",
      phonetics: [
        {
          text: "/ˈnɛkɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/naked-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100475",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Bare, not covered by clothing.",
              synonyms: [],
              antonyms: [],
              example: "She was as naked as the day she was born.",
            },
            {
              definition: "Lacking some clothing; clothed only in underwear.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Glib, without decoration, put bluntly.",
              synonyms: [],
              antonyms: [],
              example:
                "The naked facts lay there on the table, enclosed within the files.",
            },
            {
              definition:
                "Characterized by the nakedness of the people concerned or to whom the described noun is attributed.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Unarmed.", synonyms: [], antonyms: [] },
            {
              definition: "Unaided, unaccompanied.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Unprotected, uncovered; (by extension) without a condom.",
              synonyms: [],
              antonyms: [],
              example: "I entered her naked and came in her too.",
            },
            {
              definition:
                "(of a derivative contract) Where the writer (seller) does not own the underlying asset to cover the contract.",
              synonyms: ["uncovered"],
              antonyms: [],
              example: "a naked put, a naked call",
            },
            {
              definition: "Resourceless, poor, lacking means.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(with “of”) Lacking or devoid of something.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Blank, clean, empty.", synonyms: [], antonyms: [] },
            {
              definition:
                "(of land, rocks, or plants) Barren, having no foliage, unvegetated.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Uncomfortable or vulnerable, as if missing something important.",
              synonyms: [],
              antonyms: [],
              example: "I feel naked without my mobile phone.",
            },
            {
              definition:
                "(of food or other consumer products) Without any additives, or without some component that would usually be included.",
              synonyms: [],
              antonyms: [],
              example: "Naked Bacon (a brand without nitrates or phosphates)",
            },
            {
              definition:
                "Of a singularity, not hidden within an event horizon and thus observable from other parts of spacetime.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "bare",
            "bare-assed",
            "bareskin",
            "butt-naked",
            "in one's birthday suit",
            "nude",
            "skyclad",
            "starkers",
            "unclad",
            "unclothed",
            "uncovered",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/naked"],
    },
    {
      word: "naked",
      phonetic: "/neɪkt/",
      phonetics: [{ text: "/neɪkt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make naked; to bare.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["expose", "reveal"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/nake",
        "https://en.wiktionary.org/wiki/naked",
      ],
    },
  ],
  puppy: [
    {
      word: "puppy",
      phonetic: "/ˈpʌpi/",
      phonetics: [
        { text: "/ˈpʌpi/", audio: "" },
        {
          text: "/ˈpʌpi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/puppy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=186235",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A young dog, especially before sexual maturity (12-18 months)",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A young rat.", synonyms: [], antonyms: [] },
            { definition: "A young seal.", synonyms: [], antonyms: [] },
            {
              definition: "(usually in the plural) A woman’s breast.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A (generic) thing; particularly something that is a nuisance; a sucker.",
              synonyms: [],
              antonyms: [],
              example:
                "I have another two dozen of these puppies to finish before I can go home.",
            },
            {
              definition:
                "A conceited and impertinent person, especially a young man.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dogling", "pup", "whelp", "pup"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To bring forth whelps or give birth to pups.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["pup", "whelp"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/puppy"],
    },
  ],
  jumps: [
    {
      word: "jumps",
      phonetic: "/dʒʌmps/",
      phonetics: [{ text: "/dʒʌmps/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act of jumping; a leap; a spring; a bound.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An effort; an attempt; a venture.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A dislocation in a stratum; a fault.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An abrupt interruption of level in a piece of brickwork or masonry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An instance of propelling oneself upwards.",
              synonyms: [],
              antonyms: [],
              example: "The boy took a skip and a jump down the lane.",
            },
            {
              definition: "An object which causes one to jump, a ramp.",
              synonyms: [],
              antonyms: [],
              example: "He went off a jump.",
            },
            {
              definition:
                "An instance of causing oneself to fall from an elevated location.",
              synonyms: [],
              antonyms: [],
              example: "There were a couple of jumps from the bridge.",
            },
            {
              definition:
                "An instance of employing a parachute to leave an aircraft or elevated location.",
              synonyms: [],
              antonyms: [],
              example:
                "She was terrified before the jump, but was thrilled to be skydiving.",
            },
            {
              definition:
                "An instance of reacting to a sudden stimulus by jerking the body.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A jumping move in a board game.",
              synonyms: [],
              antonyms: [],
              example: "the knight's jump in chess",
            },
            {
              definition:
                "A button (of a joypad, joystick or similar device) used to make a video game character jump (propel itself upwards).",
              synonyms: [],
              antonyms: [],
              example: "Press jump to start.",
            },
            {
              definition:
                "An obstacle that forms part of a showjumping course, and that the horse has to jump over cleanly.",
              synonyms: [],
              antonyms: [],
              example:
                "Heartless managed the scale the first jump but fell over the second.",
            },
            {
              definition: "(with on) An early start or an advantage.",
              synonyms: [],
              antonyms: [],
              example:
                "He got a jump on the day because he had laid out everything the night before.",
            },
            {
              definition:
                "A discontinuity in the graph of a function, where the function is continuous in a punctured interval of the discontinuity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An instance of faster-than-light travel, not observable from ordinary space.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A change of the path of execution to a different location.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Short for jump-start.",
              synonyms: [],
              antonyms: [],
              example: "My car won't start. Could you give me a jump?",
            },
          ],
          synonyms: ["leap", "flinch", "jerk", "twitch"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To propel oneself rapidly upward, downward and/or in any horizontal direction such that momentum causes the body to become airborne.",
              synonyms: [],
              antonyms: [],
              example: "Kangaroos are known for their ability to jump high.",
            },
            {
              definition:
                "To cause oneself to leave an elevated location and fall downward.",
              synonyms: [],
              antonyms: [],
              example: "She is going to jump from the diving board.",
            },
            {
              definition: "To pass by a spring or leap; to overleap.",
              synonyms: [],
              antonyms: [],
              example: "to jump a stream",
            },
            {
              definition:
                "To employ a parachute to leave an aircraft or elevated location.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To react to a sudden, often unexpected, stimulus (such as a sharp prick or a loud sound) by jerking the body violently.",
              synonyms: [],
              antonyms: [],
              example: "The sudden sharp sound made me jump.",
            },
            {
              definition: "To increase sharply, to rise, to shoot up.",
              synonyms: [],
              antonyms: [],
              example:
                "Share prices jumped by 10% after the company announced record profits.",
            },
            {
              definition:
                "To employ a move in certain board games where one game piece is moved from one legal position to another passing over the position of another piece.",
              synonyms: [],
              antonyms: [],
              example: "The player's knight jumped the opponent's bishop.",
            },
            {
              definition:
                "To move to a position (in a queue/line) that is further forward.",
              synonyms: [],
              antonyms: [],
              example: "I hate it when people jump the queue.",
            },
            {
              definition: "To attack suddenly and violently.",
              synonyms: [],
              antonyms: [],
              example: "The hoodlum jumped a woman in the alley.",
            },
            {
              definition: "To engage in sexual intercourse with (a person).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause to jump.",
              synonyms: [],
              antonyms: [],
              example: "The rider jumped the horse over the fence.",
            },
            {
              definition: "To move the distance between two opposing subjects.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To increase the height of a tower crane by inserting a section at the base of the tower and jacking up everything above it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To increase speed aggressively and without warning.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To expose to danger; to risk; to hazard.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(smithwork) To join by a buttweld.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To thicken or enlarge by endwise blows; to upset.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(quarrying) To bore with a jumper.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To coincide; to agree; to accord; to tally; followed by with.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To start executing code from a different location, rather than following the program counter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To flee; to make one's escape.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "ambush",
            "assail",
            "jump down",
            "jump off",
            "skydive",
            "hump",
            "jump someone's bones",
            "beat it",
            "rabbit",
            "take off",
            "skip",
            "leap",
            "spring",
            "flinch",
            "jerk",
            "jump out of one's skin",
            "leap out of one's skin",
            "twitch",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A kind of loose jacket for men.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A bodice worn instead of stays by women in the 18th century.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An occasion when a performer or team of them (especially in vaudeville) expects to perform at a theater for a single evening.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A single sexual encounter between two individuals, where at least one of the partners has no immediate intention or expectation of establishing a longer-term sexual or romantic relationship. As the phrase implies, the relationship lasts for only one night.",
              synonyms: ["pump and dump", "wham, bam, thank you ma'am"],
              antonyms: [],
            },
            {
              definition:
                "Either of the two partners involved in such a single sexual encounter.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["pump and dump", "wham, bam, thank you ma'am"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/jump",
        "https://en.wiktionary.org/wiki/jumps",
        "https://en.wiktionary.org/wiki/one-night%20stand",
      ],
    },
  ],
  spoil: [
    {
      word: "spoil",
      phonetic: "/spɔɪl/",
      phonetics: [
        {
          text: "/spɔɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spoil-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762776",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(Also in plural: spoils) Plunder taken from an enemy or victim.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Material (such as rock or earth) removed in the course of an excavation, or in mining or dredging. Tailings.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["gangue", "slag", "tailings"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To strip (someone who has been killed or defeated) of their arms or armour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To strip or deprive (someone) of their possessions; to rob, despoil.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To plunder, pillage (a city, country etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To carry off (goods) by force; to steal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To ruin; to damage (something) in some way making it unfit for use.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To ruin the character of, by overindulgence; to coddle or pamper to excess.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of food, to become bad, sour or rancid; to decay.",
              synonyms: [],
              antonyms: [],
              example:
                "Make sure you put the milk back in the fridge, otherwise it will spoil.",
            },
            {
              definition:
                "To render (a ballot paper) invalid by deliberately defacing it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To reveal the ending or major events of (a story etc.); to ruin (a surprise) by exposing it ahead of time.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "coddle",
            "indulge",
            "mollycoddle",
            "pamper",
            "damage",
            "destroy",
            "ruin",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spoil"],
    },
  ],
  quart: [
    {
      word: "quart",
      phonetic: "/kwɔːt/",
      phonetics: [
        { text: "/kwɔːt/", audio: "" },
        {
          text: "/kwɔɹt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/quart-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50310041",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A unit of liquid capacity equal to two pints; one-fourth (quarter) of a gallon. Equivalent to 1.136 liters in the UK and 0.946 liter (liquid quart) or 1.101 liters (dry quart) in the U.S.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Four successive cards of the same suit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A fourth; a quarter; hence, a region of the earth.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/quart"],
    },
  ],
  macro: [
    {
      word: "macro",
      phonetic: "/ˈmæk.ɹəʊ/",
      phonetics: [
        { text: "/ˈmæk.ɹəʊ/", audio: "" },
        { text: "/ˈmæk.ɹoʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Very large in scope or scale.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Of a lifestyle incorporating a dietary regimen including locally grown, seasonal, natural foods, or of the diet itself.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Long-lived.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The study of the entire economy in terms of the total amount of goods and services produced, total income earned, the level of employment of productive resources, and the general behavior of prices.",
              synonyms: [],
              antonyms: ["microeconomics"],
            },
          ],
          synonyms: [],
          antonyms: ["microeconomics"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of the elements required in large amounts by all living things.",
              synonyms: ["macro"],
              antonyms: ["micronutrient"],
            },
          ],
          synonyms: ["macro"],
          antonyms: ["micronutrient"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/macro",
        "https://en.wiktionary.org/wiki/macrobiotic",
        "https://en.wiktionary.org/wiki/macroeconomics",
        "https://en.wiktionary.org/wiki/macronutrient",
      ],
    },
    {
      word: "macro",
      phonetic: "/ˈmæk.ɹəʊ/",
      phonetics: [
        { text: "/ˈmæk.ɹəʊ/", audio: "" },
        { text: "/ˈmæk.ɹoʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A comparatively human-friendly abbreviation of complex input to a computer program.",
              synonyms: [],
              antonyms: [],
              example:
                "The preprocessor expands any embedded macros into source code before it is compiled.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/macro"],
    },
    {
      word: "macro",
      phonetic: "/ˈmæk.ɹəʊ/",
      phonetics: [
        { text: "/ˈmæk.ɹəʊ/", audio: "" },
        { text: "/ˈmæk.ɹoʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Macro lens", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/macro"],
    },
  ],
  fears: [
    {
      word: "fears",
      phonetic: "/fɪəz/",
      phonetics: [
        { text: "/fɪəz/", audio: "" },
        {
          text: "/fɪɹz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fears-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749841",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A strong, uncontrollable, unpleasant emotion or feeling caused by actual or perceived danger or threat.",
              synonyms: [],
              antonyms: [],
              example: "He was struck by fear on seeing the snake.",
            },
            {
              definition:
                "A phobia, a sense of fear induced by something or someone.",
              synonyms: [],
              antonyms: [],
              example:
                "Not everybody has the same fears.  I have a fear of ants.",
            },
            {
              definition:
                "Terrified veneration or reverence, particularly towards God, gods, or sovereigns.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dread"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To feel fear about (something or someone); to be afraid of; to consider or expect with alarm.",
              synonyms: [],
              antonyms: [],
              example: "I fear the worst will happen.",
            },
            {
              definition: "To feel fear (about something).",
              synonyms: [],
              antonyms: [],
              example: "Never fear; help is always near.",
            },
            {
              definition:
                "(used with for) To worry about, to feel concern for, to be afraid for.",
              synonyms: [],
              antonyms: [],
              example: "She fears for her son’s safety.",
            },
            {
              definition: "To venerate; to feel awe towards.",
              synonyms: [],
              antonyms: [],
              example:
                "People who fear God can be found in Christian churches.",
            },
            {
              definition: "To regret.",
              synonyms: [],
              antonyms: [],
              example: "I fear I have bad news for you: your husband has died.",
            },
            {
              definition: "To cause fear to; to frighten.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be anxious or solicitous for.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To suspect; to doubt.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "afraid",
            "frightened",
            "scared",
            "terrorised",
            "terrorized",
            "revere",
            "venerate",
          ],
          antonyms: ["belittle", "contemn"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fear",
        "https://en.wiktionary.org/wiki/fears",
      ],
    },
  ],
  flung: [
    {
      word: "flung",
      phonetic: "/ˈflʌŋ/",
      phonetics: [
        {
          text: "/ˈflʌŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/flung-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=592032",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To move (oneself) abruptly or violently; to rush or dash.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To throw with violence or quick movement; to hurl.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To throw; to wince; to flounce.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To utter abusive language; to sneer.",
              synonyms: [],
              antonyms: [],
              example: "The scold began to flout and fling.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fling",
        "https://en.wiktionary.org/wiki/flung",
      ],
    },
  ],
  spark: [
    {
      word: "spark",
      phonetic: "/spɑːk/",
      phonetics: [
        { text: "/spɑːk/", audio: "" },
        {
          text: "/spɑɹk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spark-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780390",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small particle of glowing matter, either molten or on fire.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A short or small burst of electrical discharge.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small, shining body, or transient light; a sparkle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small amount of something, such as an idea or romantic affection, that has the potential to become something greater, just as a spark can start a fire.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various lycaenid butterflies of the Indomalayan genus Sinthusa.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in plural sparks but treated as a singular) A ship's radio operator.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An electrician.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["beginning", "germ", "glimmer", "ember", "funk", "gnast"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To trigger, kindle into activity (an argument, etc).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To light; to kindle.", synonyms: [], antonyms: [] },
            {
              definition: "To give off a spark or sparks.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Appalachian To court.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spark"],
    },
    {
      word: "spark",
      phonetic: "/spɑːk/",
      phonetics: [
        { text: "/spɑːk/", audio: "" },
        {
          text: "/spɑɹk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spark-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780390",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A gallant; a foppish young man.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A beau, lover.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To woo, court; to act the gallant or beau.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["make love", "romance", "solicit"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spark"],
    },
  ],
  vivid: [
    {
      word: "vivid",
      phonetic: "/ˈvɪvɪd/",
      phonetics: [
        {
          text: "/ˈvɪvɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/vivid-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1161562",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A felt-tipped permanent marker.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "(of perception) Clear, detailed or powerful.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of an image) Bright, intense or colourful.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Full of life, strikingly alive.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/vivid"],
    },
  ],
  brook: [
    {
      word: "brook",
      phonetic: "/bɹʊk/",
      phonetics: [
        {
          text: "/bɹʊk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/brook-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762881",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To use; enjoy; have the full employment of.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To earn; deserve.", synonyms: [], antonyms: [] },
            {
              definition:
                "To bear; endure; support; put up with; tolerate (usually used in the negative, with an abstract noun as object).",
              synonyms: [],
              antonyms: [],
              example:
                "I will not brook any disobedience.   I will brook no refusal.   I will brook no impertinence.",
            },
          ],
          synonyms: ["apply", "employ", "utilize"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/brook"],
    },
    {
      word: "brook",
      phonetic: "/bɹʊk/",
      phonetics: [
        {
          text: "/bɹʊk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/brook-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762881",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A body of running water smaller than a river; a small stream.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A water meadow.", synonyms: [], antonyms: [] },
            {
              definition: "(in the plural) Low, marshy ground.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["beck", "burn", "coulee", "creek", "stream"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/brook"],
    },
  ],
  steer: [
    {
      word: "steer",
      phonetic: "/stɪə(ɹ)/",
      phonetics: [
        {
          text: "/stɪə(ɹ)/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/steer-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769526",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A suggestion about a course of action.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A helmsman; a pilot.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To guide the course of a vessel, vehicle, aircraft etc. (by means of a device such as a rudder, paddle, or steering wheel).",
              synonyms: [],
              antonyms: [],
              example: "I steered homeward.",
            },
            {
              definition:
                "To guide the course of a vessel, vehicle, aircraft etc. (by means of a device such as a rudder, paddle, or steering wheel).",
              synonyms: [],
              antonyms: [],
              example: "I find it very difficult to steer a skateboard.",
            },
            {
              definition:
                "To be directed and governed; to take a direction, or course; to obey the helm.",
              synonyms: [],
              antonyms: [],
              example: "The boat steers easily.",
            },
            {
              definition: "To direct a group of animals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To maneuver or manipulate a person or group into a place or course of action.",
              synonyms: [],
              antonyms: [],
              example:
                "Hume believes that principles of association steer the imagination of artists.",
            },
            {
              definition: "To direct a conversation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To conduct oneself; to take or pursue a course of action.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/steer"],
    },
    {
      word: "steer",
      phonetic: "/stɪə(ɹ)/",
      phonetics: [
        {
          text: "/stɪə(ɹ)/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/steer-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769526",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The castrated male of cattle, especially one raised for beef production.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["ox"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To castrate (a male calf).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/steer"],
    },
  ],
  spray: [
    {
      word: "spray",
      phonetic: "/spɹeɪ/",
      phonetics: [
        {
          text: "/spɹeɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spray-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=508876",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A fine, gentle, dispersed mist of liquid.",
              synonyms: [],
              antonyms: [],
              example: "The sailor could feel the spray from the waves.",
            },
            {
              definition: "A pressurized container; an atomizer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of numerous commercial products, including paints, cosmetics, and insecticides, that are dispensed from containers in this manner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A jet of fine medicated vapour, used either as an application to a diseased part or to charge the air of a room with a disinfectant or a deodorizer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A side channel or branch of the runner of a flask, made to distribute the metal to all parts of the mold.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A group of castings made in the same mold and connected by sprues formed in the runner and its branches.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The allocation and filling of blocks of memory with the same byte sequence, hoping to establish that sequence in a certain predetermined location as part of an exploit.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To project a liquid in a dispersive manner toward something.",
              synonyms: [],
              antonyms: [],
              example: "The firemen sprayed the house.",
            },
            {
              definition: "To project in a dispersive manner.",
              synonyms: [],
              antonyms: [],
              example: "Spray some ointment on that scratch.",
            },
            {
              definition: "To project many small items dispersively.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To urinate in order to mark territory.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To allocate blocks of memory from (a heap, etc.), and fill them with the same byte sequence, hoping to establish that sequence in a certain predetermined location as part of an exploit.",
              synonyms: [],
              antonyms: [],
              example: "to spray the heap of a target process",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spray"],
    },
    {
      word: "spray",
      phonetic: "/spɹeɪ/",
      phonetics: [
        {
          text: "/spɹeɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spray-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=508876",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A small branch of flowers or berries.",
              synonyms: [],
              antonyms: [],
              example: "The bridesmaid carried a spray of lily-of-the-valley.",
            },
            {
              definition: "A collective body of small branches.",
              synonyms: [],
              antonyms: [],
              example: "The tree has a beautiful spray.",
            },
            {
              definition: "Branches and twigs collectively; foliage.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An orchard.", synonyms: [], antonyms: [] },
            {
              definition: "An ornament or design that resembles a branch.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spray"],
    },
  ],
  decay: [
    {
      word: "decay",
      phonetic: "/dɪˈkeɪ/",
      phonetics: [
        {
          text: "/dɪˈkeɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/decay-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=86873060",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/di.ˈkeɪ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The process or result of being gradually decomposed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A deterioration of condition; loss of status or fortune.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To deteriorate, to get worse, to lose strength or health, to decline in quality.",
              synonyms: [],
              antonyms: [],
              example:
                "The pair loved to take pictures in the decaying hospital on forty-third street.",
            },
            {
              definition: "(of organic material) To rot, to go bad.",
              synonyms: [],
              antonyms: [],
              example: "The cat's body decayed rapidly.",
            },
            {
              definition:
                "(of an unstable atom) To change by undergoing fission, by emitting radiation, or by capturing or losing one or more electrons.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a quantum system) To undergo optical decay, that is, to relax to a less excited state, usually by emitting a photon or phonon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Loss of airspeed due to drag.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause to rot or deteriorate.",
              synonyms: [],
              antonyms: [],
              example:
                "The extreme humidity decayed the wooden sculptures in the museum's collection in a matter of years.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/decay"],
    },
  ],
  ports: [
    {
      word: "ports",
      phonetic: "/pɔːts/",
      phonetics: [
        { text: "/pɔːts/", audio: "" },
        {
          text: "/pɔɹts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ports-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651320",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A place on the coast at which ships can shelter, or dock to load and unload cargo or passengers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A town or city containing such a place, a port city.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The left-hand side of a vessel, including aircraft, when one is facing the front. Used to unambiguously refer to directions relative to the vessel structure, rather than to a person or object on board.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sweep rower that primarily rows with an oar on the port side.",
              synonyms: [],
              antonyms: [],
              example: "Each eight has four ports and four starboards.",
            },
          ],
          synonyms: [
            "backboard",
            "larboard",
            "left",
            "harbour",
            "haven",
            "harbour city",
            "harbour town",
            "port city",
          ],
          antonyms: ["starboard"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "An entryway or gate.", synonyms: [], antonyms: [] },
            {
              definition:
                "An opening or doorway in the side of a ship, especially for boarding or loading; an embrasure through which a cannon may be discharged; a porthole.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(bowls) A space between two stones wide enough for a delivered stone or bowl to pass through.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An opening where a connection (such as a pipe) is made.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A logical or physical construct in and from which data are transferred. Computer port (hardware)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A female connector of an electronic device, into which a cable's male connector can be inserted.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Something used to carry a thing, especially a frame for wicks in candle-making.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The manner in which a person carries himself; bearing; deportment; carriage. See also portance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The position of a weapon when ported; a rifle position executed by throwing the weapon diagonally across the front of the body, with the right hand grasping the small of the stock and the barrel sloping upward and crossing the point of the left shoulder.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A program that has been adapted, modified, or recoded so that it works on a different platform from the one for which it was created; the act of this adapting.",
              synonyms: [],
              antonyms: [],
              example:
                "Gamers can't wait until a port of the title is released on the new system.",
            },
            {
              definition:
                "(BSD) A set of files used to build and install a binary executable file from the source code of an application.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A type of very sweet fortified wine, mostly dark red, traditionally made in Portugal.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["port wine", "porto"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A suitcase or schoolbag.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/port",
        "https://en.wiktionary.org/wiki/ports",
      ],
    },
  ],
  socks: [
    {
      word: "socks",
      phonetic: "/sɒks/",
      phonetics: [
        {
          text: "/sɒks/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/socks-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9027441",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        { text: "/sɑːks/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A knitted or woven covering for the foot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A shoe worn by Greco-Roman comedy actors.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A color pattern (usually white) on a cat's or dog's lower leg that is different from the color pattern on the rest of the animal.",
              synonyms: ["mitten"],
              antonyms: [],
            },
            {
              definition: "(WMF jargon) A sock puppet.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A gun sock.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["mitten"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A violent blow; a punch.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To hit or strike violently; to deliver a blow to.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To throw.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A ploughshare.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An opening into which a plug or other connecting part is designed to fit (e.g. a light bulb socket).",
              synonyms: ["jack"],
              antonyms: [],
            },
            {
              definition:
                "A hollow into a bone which a part fits, such as an eye, or another bone, in the case of a joint.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One endpoint of a two-way communication link, used for interprocess communication across a network.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One endpoint of a two-way named pipe on Unix and Unix-like systems, used for interprocess communication.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hollow tool for grasping and lifting tools dropped in a well-boring.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The hollow of a candlestick.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A steel apparatus attached to a saddle to protect the thighs and legs.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["jack"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sock",
        "https://en.wiktionary.org/wiki/socket",
        "https://en.wiktionary.org/wiki/socks",
      ],
    },
  ],
  urban: [
    {
      word: "urban",
      phonetic: "/ˈɜːbən/",
      phonetics: [
        { text: "/ˈɜːbən/", audio: "" },
        { text: "/ˈɝbən/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Related to the (or any) city.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Characteristic of city life.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Relating to contemporary African American culture.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["bucolic", "nonurban", "rural"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/urban"],
    },
  ],
  goals: [
    {
      word: "goals",
      phonetic: "/ɡɔʊlz/",
      phonetics: [
        { text: "/ɡɔʊlz/", audio: "" },
        {
          text: "/ɡoʊlz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/goals-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2454064",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A result that one is attempting to achieve.",
              synonyms: [],
              antonyms: [],
              example: "My lifelong goal is to get into a Hollywood movie.",
            },
            {
              definition:
                "In many sports, an area into which the players attempt to put an object.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The act of placing the object into the goal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A point scored in a game as a result of placing the object into the goal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A noun or noun phrase that receives the action of a verb. The subject of a passive verb or the direct object of an active verb. Also called a patient, target, or undergoer.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["ambition", "aspiration", "objective", "purpose"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To score a goal.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/goal",
        "https://en.wiktionary.org/wiki/goals",
      ],
    },
  ],
  grant: [
    {
      word: "grant",
      phonetic: "/ɡɹɑːnt/",
      phonetics: [
        { text: "/ɡɹɑːnt/", audio: "" },
        {
          text: "/ɡɹænt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grant-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1661660",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The act of granting; a bestowing or conferring; concession; allowance; permission.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The yielding or admission of something in dispute.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The thing or property granted; a gift; a boon.",
              synonyms: [],
              antonyms: [],
              example:
                "I got a grant from the government to study archeology in Egypt.",
            },
            {
              definition:
                "A transfer of property by deed or writing; especially, an appropriation or conveyance made by the government.",
              synonyms: [],
              antonyms: [],
              example: "a grant of land or of money",
            },
            {
              definition:
                "The deed or writing by which such a transfer is made.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An application for a grant (monetary boon to aid research or the like).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(ditransitive) to give (permission or wish)",
              synonyms: [],
              antonyms: [],
              example: "He was granted permission to attend the meeting.",
            },
            {
              definition:
                "(ditransitive) To bestow or confer, with or without compensation, particularly in answer to prayer or request; to give.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To agree with (someone) on (something); to accept (something) for the sake of argument; to admit to (someone) that (something) is true.",
              synonyms: ["allow", "concede", "concur"],
              antonyms: [],
            },
            {
              definition: "To assent; to consent.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["allow", "concede", "concur"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/grant"],
    },
  ],
  minus: [
    {
      word: "minus",
      phonetic: "/ˈmaɪnəs/",
      phonetics: [
        {
          text: "/ˈmaɪnəs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/minus-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780221",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The minus sign (−).", synonyms: [], antonyms: [] },
            { definition: "A negative quantity.", synonyms: [], antonyms: [] },
            {
              definition: "A downside or disadvantage.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["defect", "deficiency", "drawback", "flaw", "shortcoming"],
          antonyms: [
            "advantage",
            "bonus",
            "boon",
            "gain",
            "plus",
            "plus",
            "plus sign",
            "positive",
          ],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To subtract.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Being a negative quantity; pertaining to a deficit or reduction.",
              synonyms: [],
              antonyms: [],
              example: "a minus number",
            },
            {
              definition:
                "That is below zero by (a specified amount) on a scale.",
              synonyms: [],
              antonyms: [],
              example: "minus seven degrees",
            },
            {
              definition: "Worse off than before; out of pocket.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Ranking just below (a designated rating).",
              synonyms: [],
              antonyms: [],
              example: "He got a grade of B minus for his essay.",
            },
          ],
          synonyms: ["negative", "below", "zero"],
          antonyms: [],
        },
        {
          partOfSpeech: "preposition",
          definitions: [
            {
              definition:
                "Made less or reduced by (followed by an expression of number or quantity).",
              synonyms: [],
              antonyms: ["plus"],
              example: "seven minus two is five",
            },
            {
              definition: "Without; deprived of.",
              synonyms: ["lacking", "without"],
              antonyms: [],
              example: "I walked out minus my coat.",
            },
          ],
          synonyms: ["lacking", "without"],
          antonyms: ["plus"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/minus"],
    },
  ],
  films: [
    {
      word: "films",
      phonetic: "/fɪlmz/",
      phonetics: [
        {
          text: "/fɪlmz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/films-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=61415819",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A thin layer of some substance; a pellicle; a membranous covering, causing opacity.",
              synonyms: [],
              antonyms: [],
              example: "a clear plastic film for wrapping food",
            },
            {
              definition: "A medium used to capture images in a camera.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A movie.", synonyms: [], antonyms: [] },
            {
              definition: "Cinema; movies as a group.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A slender thread, such as that of a cobweb.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["movie"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To record (activity, or a motion picture) on photographic film.",
              synonyms: [],
              antonyms: [],
              example: "A Hollywood studio was filming on location in NYC.",
            },
            {
              definition:
                "To cover or become covered with a thin skin or pellicle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/film",
        "https://en.wiktionary.org/wiki/films",
      ],
    },
  ],
  tunes: [
    {
      word: "tunes",
      phonetic: "/tjuːns/",
      phonetics: [{ text: "/tjuːns/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A melody.", synonyms: [], antonyms: [] },
            {
              definition: "A song, or short musical composition.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The act of tuning or maintenance.",
              synonyms: [],
              antonyms: [],
              example: "Your engine needs a good tune.",
            },
            {
              definition: "The state or condition of being correctly tuned.",
              synonyms: [],
              antonyms: [],
              example: "This piano is not in tune.",
            },
            {
              definition: "Temper; frame of mind.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sound; a note; a tone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Order; harmony; concord.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To adjust (a musical instrument) so that it produces the correct pitches.",
              synonyms: [],
              antonyms: [],
              example: "to tune a piano or a violin",
            },
            {
              definition:
                "To adjust or modify (esp. a mechanical or electrical device) so that it functions optimally.",
              synonyms: [],
              antonyms: [],
              example: "Tune your mind, and anything becomes possible.",
            },
            {
              definition:
                "To adjust the frequency on a radio or TV set, so as to receive the desired channel.",
              synonyms: [],
              antonyms: [],
              example:
                "Tune to Channel 6 for all your favourite daytime shows.",
            },
            {
              definition:
                "(e.g. of senses or faculties) To adapt to or direct towards a particular target.",
              synonyms: [],
              antonyms: [],
              example: "My ears were tuned to the sounds of the forest.",
            },
            {
              definition:
                "To make more precise, intense, or effective; to put into a proper state or disposition.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To attune; to adapt in style of music; to make harmonious.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To give a certain tone or character to.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To sing with melody or harmony.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cheek; to be impudent towards.",
              synonyms: [],
              antonyms: [],
              example: "Are you tuning me?",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tune",
        "https://en.wiktionary.org/wiki/tunes",
      ],
    },
  ],
  shaft: [
    {
      word: "shaft",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shaft-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241912",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ʃɑːft/", audio: "" },
        { text: "/ʃæft/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The entire body of a long weapon, such as an arrow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The long, narrow, central body of a spear, arrow, or javelin.",
              synonyms: [],
              antonyms: [],
              example:
                "Her hand slipped off the javelin's shaft towards the spearpoint and that's why her score was lowered.",
            },
            {
              definition:
                "(by extension) Anything cast or thrown as a spear or javelin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any long thin object, such as the handle of a tool, one of the poles between which an animal is harnessed to a vehicle, the driveshaft of a motorized vehicle with rear-wheel drive, an axle, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A beam or ray of light.",
              synonyms: [],
              antonyms: [],
              example:
                "Isn't that shaft of light from that opening in the cave beautiful?",
            },
            {
              definition: "The main axis of a feather.",
              synonyms: [],
              antonyms: [],
              example:
                "I had no idea that they removed the feathers' shafts to make the pillows softer!",
            },
            {
              definition: "The long narrow body of a lacrosse stick.",
              synonyms: [],
              antonyms: [],
              example:
                "Sarah, if you wear gloves your hands might not slip on your shaft and you can up your game, girl!",
            },
            {
              definition:
                "A vertical or inclined passage sunk into the earth as part of a mine",
              synonyms: [],
              antonyms: [],
              example:
                "Your grandfather used to work with a crane hauling ore out of the gold mine's shafts.",
            },
            {
              definition:
                "A vertical passage housing a lift or elevator; a liftshaft.",
              synonyms: [],
              antonyms: [],
              example:
                "Darn it, my keys fell through the gap and into the elevator shaft.",
            },
            {
              definition: "A ventilation or heating conduit; an air duct.",
              synonyms: [],
              antonyms: [],
              example:
                "Our parrot flew into the air duct and got stuck in the shaft.",
            },
            {
              definition:
                "Any column or pillar, particularly the body of a column between its capital and pedestal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The main cylindrical part of the penis.",
              synonyms: [],
              antonyms: [],
              example:
                "The female labia minora is homologous to the penis shaft skin of males.",
            },
            {
              definition: "The chamber of a blast furnace.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "rachis",
            "mineshaft",
            "stail",
            "stale",
            "steal",
            "steel",
            "stele",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To fuck over; to cause harm to, especially through deceit or treachery.",
              synonyms: [],
              antonyms: [],
              example:
                "Your boss really shafted you by stealing your idea like that.",
            },
            {
              definition: "To equip with a shaft.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fuck; to have sexual intercourse with.",
              synonyms: [],
              antonyms: [],
              example: "Turns out my roommate was shafting my girlfriend.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shaft"],
    },
  ],
  firms: [
    {
      word: "firms",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A business partnership; the name under which it trades.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A business enterprise, however organized.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A criminal gang, especially based around football hooliganism.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make firm or strong; fix securely.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make compact or resistant to pressure; solidify.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To become firm; stabilise.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To improve after decline.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To shorten (of betting odds).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To select (a higher education institution) as one's preferred choice, so as to enrol automatically if one's grades match the conditional offer.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/firm",
        "https://en.wiktionary.org/wiki/firms",
      ],
    },
  ],
  skies: [
    {
      word: "skies",
      phonetic: "/skaɪz/",
      phonetics: [
        {
          text: "/skaɪz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/skies-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2454294",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The atmosphere above a given point, especially as visible from the ground during the day.",
              synonyms: [],
              antonyms: [],
              example: "That year, a meteor fell from the sky.",
            },
            {
              definition:
                "The part of the sky which can be seen from a specific place or at a specific time; its condition, climate etc.",
              synonyms: [],
              antonyms: [],
              example: "I lay back under a warm Texas sky.",
            },
            {
              definition: "Heaven.",
              synonyms: [],
              antonyms: [],
              example: "This mortal has incurred the wrath of the skies.",
            },
            {
              definition:
                "(theoretical physics) The set of all lightlike lines (or directions) passing through a given point in space-time.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In an art gallery, the upper rows of pictures that cannot easily be seen.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A cloud.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["celestial sphere", "firmament", "heaven", "lift"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To hit, kick or throw (a ball) extremely high.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To clear (a hurdle, high jump bar, etc.) by a large margin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To hang (a picture on exhibition) near the top of a wall, where it cannot be well seen.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To drink something from a container without one's lips touching the container.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A vibrant light blue colour, like that of the sky on a fine day.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/skie",
        "https://en.wiktionary.org/wiki/skies",
        "https://en.wiktionary.org/wiki/sky",
        "https://en.wiktionary.org/wiki/sky%20blue",
      ],
    },
    {
      word: "skies",
      phonetic: "/skiːz/",
      phonetics: [{ text: "/skiːz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To move on skis", synonyms: [], antonyms: [] },
            {
              definition:
                "To travel over (a slope etc.) on skis; to travel on skis at (a place), (especially as a sport)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ski",
        "https://en.wiktionary.org/wiki/skies",
      ],
    },
  ],
  bride: [
    {
      word: "bride",
      phonetic: "/bɹaɪd/",
      phonetics: [
        { text: "/bɹaɪd/", audio: "" },
        { text: "/bɹaɪd/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A woman in the context of her own wedding; one who is going to marry or has just been married.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An object ardently loved.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To make a bride of", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bride"],
    },
    {
      word: "bride",
      phonetic: "/bɹaɪd/",
      phonetics: [
        { text: "/bɹaɪd/", audio: "" },
        { text: "/bɹaɪd/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An individual loop or other device connecting the patterns in lacework",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bride"],
    },
  ],
  wreck: [
    {
      word: "wreck",
      phonetic: "/ˈɹɛk/",
      phonetics: [
        {
          text: "/ˈɹɛk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wreck-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780502",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Something or someone that has been ruined.",
              synonyms: ["basket case, mess"],
              antonyms: [],
              example: "He was an emotional wreck after the death of his wife.",
            },
            {
              definition:
                "The remains of something that has been severely damaged or worn down.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An event in which something is damaged through collision.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Goods, etc. cast ashore by the sea after a shipwreck.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["basket case, mess", "crash", "ruins"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To destroy violently; to cause severe damage to something, to a point where it no longer works, or is useless.",
              synonyms: [],
              antonyms: [],
              example: "He wrecked the car in a collision.",
            },
            {
              definition: "To ruin or dilapidate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To dismantle wrecked vehicles or other objects, to reclaim any useful parts.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To involve in a wreck; hence, to cause to suffer ruin; to balk of success, and bring disaster on.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["build", "construct", "make", "produce"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/wreck"],
    },
  ],
  flock: [
    {
      word: "flock",
      phonetic: "/flɒk/",
      phonetics: [
        { text: "/flɒk/", audio: "" },
        {
          text: "/flɑk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/flock-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762936",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A large number of birds, especially those gathered together for the purpose of migration.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A large number of animals, especially sheep or goats kept together.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Those served by a particular pastor or shepherd.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A large number of people.",
              synonyms: ["congregation"],
              antonyms: [],
            },
            {
              definition: "A religious congregation.",
              synonyms: ["congregation"],
              antonyms: [],
            },
          ],
          synonyms: [
            "bunch",
            "gaggle",
            "horde",
            "host",
            "legion",
            "litter",
            "nest",
            "rabble",
            "swarm",
            "throng",
            "wake",
            "congregation",
            "congregation",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To congregate in or head towards a place in large numbers.",
              synonyms: [],
              antonyms: [],
              example: "People flocked to the cinema to see the new film.",
            },
            {
              definition: "To flock to; to crowd.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To treat a pool with chemicals to remove suspended particles.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/flock"],
    },
    {
      word: "flock",
      phonetic: "/flɒk/",
      phonetics: [
        { text: "/flɒk/", audio: "" },
        {
          text: "/flɑk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/flock-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762936",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Coarse tufts of wool or cotton used in bedding.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A lock of wool or hair.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Very fine sifted woollen refuse, especially that from shearing the nap of cloths, formerly used as a coating for wallpaper to give it a velvety or clothlike appearance; also, the dust of vegetable fibre used for a similar purpose.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To coat a surface with dense fibers or particles.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/flock"],
    },
  ],
  stare: [
    {
      word: "stare",
      phonetic: "/stɛə(ɹ)/",
      phonetics: [
        {
          text: "/stɛə(ɹ)/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stare-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=91208369",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/stɛəɹ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A persistent gaze.",
              synonyms: [],
              antonyms: [],
              example: "the stares of astonished passers-by",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(construed with at) To look fixedly (at something).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To influence in some way by looking fixedly.",
              synonyms: [],
              antonyms: [],
              example: "to stare a timid person into submission",
            },
            {
              definition:
                "To be very conspicuous on account of size, prominence, colour, or brilliancy.",
              synonyms: [],
              antonyms: [],
              example: "staring windows or colours",
            },
            {
              definition: "To stand out; to project; to bristle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stare"],
    },
    {
      word: "stare",
      phonetic: "/stɛə(ɹ)/",
      phonetics: [
        {
          text: "/stɛə(ɹ)/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stare-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=91208369",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/stɛəɹ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A starling.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stare"],
    },
  ],
  hobby: [
    {
      word: "hobby",
      phonetic: "/ˈhɒ.bi/",
      phonetics: [
        {
          text: "/ˈhɒ.bi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hobby-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80065931",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈhɑ.bi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An activity that one enjoys doing in one's spare time.",
              synonyms: [],
              antonyms: [],
              example:
                "I like to collect stamps from different countries as a hobby.",
            },
            {
              definition:
                "An extinct breed of horse native to the British Isles, also known as the Irish Hobby",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["avocation", "pastime"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hobby"],
    },
    {
      word: "hobby",
      phonetic: "/ˈhɒ.bi/",
      phonetics: [
        {
          text: "/ˈhɒ.bi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hobby-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80065931",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈhɑ.bi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of four species of small falcons in the genus Falco, especially Falco subbuteo.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hobby"],
    },
  ],
  bonds: [
    {
      word: "bonds",
      phonetic: "/bɒndz/",
      phonetics: [
        { text: "/bɒndz/", audio: "" },
        {
          text: "/bɑndz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bonds-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453874",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Evidence of a long-term debt, by which the bond issuer (the borrower) is obliged to pay interest when due, and repay the principal at maturity, as specified on the face of the bond certificate. The rights of the holder are specified in the bond indenture, which contains the legal terms and conditions under which the bond was issued. Bonds are available in two forms: registered bonds, and bearer bonds.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A documentary obligation to pay a sum or to perform a contract; a debenture.",
              synonyms: [],
              antonyms: [],
              example:
                "Investors face a quandary. Cash offers a return of virtually zero in many developed countries; government-bond yields may have risen in recent weeks but they are still unattractive. Equities have suffered two big bear markets since 2000 and are wobbling again. It is hardly surprising that pension funds, insurers and endowments are searching for new sources of return.",
            },
            {
              definition:
                "A partial payment made to show a provider that the customer is sincere about buying a product or a service. If the product or service is not purchased the customer then forfeits the bond.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(often in the plural) A physical connection which binds, a band.",
              synonyms: [],
              antonyms: [],
              example:
                "The prisoner was brought before the tribunal in iron bonds.",
            },
            {
              definition:
                "An emotional link, connection or union; that which holds two or more people together, as in a friendship; a tie.",
              synonyms: [],
              antonyms: [],
              example:
                "They had grown up as friends and neighbors, and not even vastly differing political views could break the bond of their friendship.",
            },
            {
              definition: "Moral or political duty or obligation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A link or force between neighbouring atoms in a molecule.",
              synonyms: [],
              antonyms: [],
              example:
                "Organic chemistry primarily consists of the study of carbon bonds, in their many variations.",
            },
            {
              definition: "A binding agreement, a covenant.",
              synonyms: [],
              antonyms: [],
              example:
                "Herbert resented his wife for subjecting him to the bonds of matrimony; he claimed they had gotten married while drunk.",
            },
            {
              definition: "A bail bond.",
              synonyms: [],
              antonyms: [],
              example:
                "The bailiff released the prisoner as soon as the bond was posted.",
            },
            {
              definition: "Any constraining or cementing force or material.",
              synonyms: [],
              antonyms: [],
              example:
                "A bond of superglue adhered the teacups to the ceiling, much to the consternation of the cafe owners.",
            },
            {
              definition: "In building, a specific pattern of bricklaying.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "In Scotland, a mortgage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A heavy copper wire or rod connecting adjacent rails of an electric railway track when used as a part of the electric circuit.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To connect, secure or tie with a bond; to bind.",
              synonyms: [],
              antonyms: [],
              example:
                "The gargantuan ape was bonded in iron chains and carted onto the stage.",
            },
            {
              definition: "To cause to adhere (one material with another).",
              synonyms: [],
              antonyms: [],
              example:
                "The children bonded their snapshots to the scrapbook pages with mucilage.",
            },
            {
              definition: "To form a chemical compound with.",
              synonyms: [],
              antonyms: [],
              example:
                "Under unusual conditions, even gold can be made to bond with other elements.",
            },
            {
              definition: "To guarantee or secure a financial risk.",
              synonyms: [],
              antonyms: [],
              example: "The contractor was bonded with a local underwriter.",
            },
            {
              definition: "To form a friendship or emotional connection.",
              synonyms: [],
              antonyms: [],
              example: "The men had bonded while serving together in Vietnam.",
            },
            {
              definition:
                "To put in a bonded warehouse; to secure (goods) until the associated duties are paid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To lay bricks in a specific pattern.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make a reliable electrical connection between two conductors (or any pieces of metal that may potentially become conductors).",
              synonyms: [],
              antonyms: [],
              example:
                "A house's distribution panel should always be bonded to the grounding rods via a panel bond.",
            },
            {
              definition: "To bail out by means of a bail bond.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A peasant; churl.", synonyms: [], antonyms: [] },
            {
              definition: "A vassal; serf; one held in bondage to a superior.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Imprisonment, captivity",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The condition of goods in a bonded warehouse until duty is paid",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bond",
        "https://en.wiktionary.org/wiki/bonds",
      ],
    },
  ],
  dared: [
    {
      word: "dared",
      phonetic: "/dɛəd/",
      phonetics: [
        { text: "/dɛəd/", audio: "" },
        {
          text: "/dɛɹd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dared-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1684582",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To have enough courage (to do something).",
              synonyms: [],
              antonyms: [],
              example: "I wouldn't dare argue with my boss.",
            },
            {
              definition: "To defy or challenge (someone to do something)",
              synonyms: [],
              antonyms: [],
              example: "I dare you (to) kiss that girl.",
            },
            {
              definition:
                "To have enough courage to meet or do something, go somewhere, etc.; to face up to",
              synonyms: [],
              antonyms: [],
              example: "Will you dare death to reach your goal?",
            },
            { definition: "To terrify; to daunt.", synonyms: [], antonyms: [] },
            {
              definition:
                "To catch (larks) by producing terror through the use of mirrors, scarlet cloth, a hawk, etc., so that they lie still till a net is thrown over them.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To stare stupidly or vacantly; to gaze as though amazed or terrified.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To lie or crouch down in fear.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dare",
        "https://en.wiktionary.org/wiki/dared",
      ],
    },
  ],
  faded: [
    {
      word: "faded",
      phonetic: "/ˈfeɪdɪd/",
      phonetics: [
        {
          text: "/ˈfeɪdɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/faded-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100967",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To grow weak; to lose strength; to decay; to perish gradually; to wither, as a plant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To lose freshness, color, or brightness; to become faint in hue or tint; hence, to be wanting in color.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To sink away; to disappear gradually; to grow dim; to vanish.",
              synonyms: [],
              antonyms: [],
              example: "The milkman's whistling faded into the distance.",
            },
            { definition: "To cause to fade.", synonyms: [], antonyms: [] },
            { definition: "To bet against.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "weaken",
            "wither",
            "blanch",
            "bleach",
            "decrease",
            "diminish",
            "wane",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(sometimes figurative) That has lost some of its former colour or intensity.",
              synonyms: [],
              antonyms: [],
              example: "a faded rock star of the 1970s",
            },
            { definition: "High on drugs; stoned", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fade",
        "https://en.wiktionary.org/wiki/faded",
      ],
    },
  ],
  thief: [
    {
      word: "thief",
      phonetic: "/θiːf/",
      phonetics: [
        { text: "/θiːf/", audio: "" },
        {
          text: "/θif/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/thief-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2707615",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One who carries out a theft.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One who steals another person's property, especially by stealth and without using force or violence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A waster in the snuff of a candle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/thief"],
    },
  ],
  crude: [
    {
      word: "crude",
      phonetic: "/kɹʉd/",
      phonetics: [
        { text: "/kɹʉd/", audio: "" },
        {
          text: "/kɹuːd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/crude-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1755132",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Any substance in its natural state.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Crude oil.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "In a natural, untreated state.",
              synonyms: ["raw"],
              antonyms: [],
              example: "crude oil",
            },
            {
              definition:
                "Characterized by simplicity, especially something not carefully or expertly made.",
              synonyms: ["primitive"],
              antonyms: [],
              example: "a crude shelter",
            },
            {
              definition: "Lacking concealing elements.",
              synonyms: ["obvious"],
              antonyms: [],
              example: "a crude truth",
            },
            {
              definition: "Lacking tact or taste.",
              synonyms: ["blunt"],
              antonyms: [],
              example: "a crude remark",
            },
            {
              definition: "Immature or unripe.",
              synonyms: ["immature", "unripe"],
              antonyms: [],
            },
            {
              definition:
                "(grammar) Pertaining to the uninflected stem of a word.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "raw",
            "blunt",
            "immature",
            "unripe",
            "obvious",
            "primitive",
            "raw",
          ],
          antonyms: ["processed", "refined"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/crude"],
    },
  ],
  pants: [
    {
      word: "pants",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pants-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75648924",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/pænts/", audio: "" },
        {
          text: "/pænts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pants-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762732",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(Manchester) An outer garment that covers the body from the waist downwards, covering each leg separately, usually as far as the ankles; trousers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An undergarment that covers the genitals and often the buttocks and the neighbouring parts of the body; underpants.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Rubbish; something worthless.",
              synonyms: [],
              antonyms: [],
              example: "The film was a load [or pile] of pants.",
            },
          ],
          synonyms: [
            "breeks",
            "britches",
            "hosen",
            "slacks",
            "strides",
            "trousers",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of inferior quality, rubbish.",
              synonyms: [],
              antonyms: [],
              example:
                "Your mobile is pants — why don’t you get one like mine?",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pants"],
    },
    {
      word: "pants",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pants-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75648924",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/pænts/", audio: "" },
        {
          text: "/pænts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pants-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762732",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To pull someone’s pants down; to forcibly remove someone’s pants.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dack", "de-pants", "debags", "depants", "keg"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pants"],
    },
    {
      word: "pants",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pants-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75648924",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/pænts/", audio: "" },
        {
          text: "/pænts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pants-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762732",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A quick breathing; a catching of the breath; a gasp.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Eager longing.", synonyms: [], antonyms: [] },
            {
              definition: "A violent palpitation of the heart.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A pair of pants (trousers or underpants).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(used attributively as a modifier) Of or relating to pants.",
              synonyms: [],
              antonyms: [],
              example: "Pant leg",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(Scotland and northeast England) Any public drinking fountain.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pant",
        "https://en.wiktionary.org/wiki/pants",
      ],
    },
    {
      word: "pants",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pants-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75648924",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/pænts/", audio: "" },
        {
          text: "/pænts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pants-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762732",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To breathe quickly or in a labored manner, as after exertion or from eagerness or excitement; to respire with heaving of the breast; to gasp.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To long eagerly; to desire earnestly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To long for (something); to be eager for (something).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of the heart, to beat with unnatural violence or rapidity; to palpitate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To sigh; to flutter; to languish.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To heave, as the breast.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To bulge and shrink successively, of iron hulls, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "gasp",
            "crave",
            "desire",
            "long",
            "pine",
            "crave",
            "desire",
            "long",
            "pine",
            "palpitate",
            "pound",
            "throb",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pant",
        "https://en.wiktionary.org/wiki/pants",
      ],
    },
  ],
  flute: [
    {
      word: "flute",
      phonetic: "/fluːt/",
      phonetics: [
        {
          text: "/fluːt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/flute-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1755156",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A woodwind instrument consisting of a tube with a row of holes that produce sound through vibrations caused by air blown across the edge of the holes, often tuned by plugging one or more holes with a finger; the Western concert flute, a transverse side-blown flute of European origin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A recorder, also a woodwind instrument.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A glass with a long, narrow bowl and a long stem, used for drinking wine, especially champagne.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A lengthwise groove, such as one of the lengthwise grooves on a classical column, or a groove on a cutting tool (such as a drill bit, endmill, or reamer), which helps to form both a cutting edge and a channel through which chips can escape",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A semicylindrical vertical groove, as in a pillar, in plaited cloth, or in a rifle barrel to cut down the weight.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A long French bread roll, baguette.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An organ stop with a flute-like sound.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A shuttle in weaving tapestry etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["edge-blown aerophone", "Western concert flute"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To play on a flute.", synonyms: [], antonyms: [] },
            {
              definition: "To make a flutelike sound.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To utter with a flutelike sound.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To form flutes or channels in (as in a column, a ruffle, etc.); to cut a semicylindrical vertical groove in (as in a pillar, etc.).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/flute"],
    },
    {
      word: "flute",
      phonetic: "/fluːt/",
      phonetics: [
        {
          text: "/fluːt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/flute-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1755156",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A kind of flyboat; a storeship.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/flute"],
    },
  ],
  votes: [
    {
      word: "votes",
      phonetic: "/vəʊts/",
      phonetics: [
        { text: "/vəʊts/", audio: "" },
        { text: "/voʊts/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A formalized choice on matters of administration or other democratic activities",
              synonyms: [],
              antonyms: [],
              example:
                "One occasion indicative votes were used was in 2003 when MPs were presented with seven different options on how to reform the House of Lords.",
            },
            {
              definition:
                "An act or instance of participating in such a choice, e.g., by submitting a ballot",
              synonyms: [],
              antonyms: [],
              example:
                "The Supreme Court upheld the principle of one person, one vote.",
            },
            {
              definition: "An ardent wish or desire; a vow; a prayer",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cast a vote; to assert a formalized choice in an election",
              synonyms: [],
              antonyms: [],
              example: "Did you vote last month?",
            },
            {
              definition:
                "To choose or grant by means of a vote, or by general consent",
              synonyms: [],
              antonyms: [],
              example:
                "The depository may vote shares on behalf of investors who have not submitted instruction to the bank.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/vote",
        "https://en.wiktionary.org/wiki/votes",
      ],
    },
  ],
  tonal: [
    {
      word: "tonal",
      phonetic: "/ˈtəʊnəl/",
      phonetics: [
        { text: "/ˈtəʊnəl/", audio: "" },
        { text: "/ˈtoʊnəl/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of or relating to tones or tonality.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of or relating to the general character, mood, or trend of something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Employing tones that have a predictable relationship to some tonic.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Employing differences in pitch (tones) to distinguish differences in the meaning of otherwise similar words (words which would otherwise be homophonic).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["atonal"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tonal"],
    },
    {
      word: "tonal",
      phonetic: "/ˈtəʊnəl/",
      phonetics: [
        { text: "/ˈtəʊnəl/", audio: "" },
        { text: "/ˈtoʊnəl/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An animal companion which accompanies a person from birth to death.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tonal"],
    },
  ],
  radar: [
    {
      word: "radar",
      phonetic: "/ˈɹeɪdɑː(ɹ)/",
      phonetics: [
        { text: "/ˈɹeɪdɑː(ɹ)/", audio: "" },
        {
          text: "/ˈɹeɪdɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/radar-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50241407",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A method of detecting distant objects and determining their position, velocity, or other characteristics by analysis of sent radio waves (usually microwaves) reflected from their surfaces",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A type of system using such method, differentiated by platform, configuration, frequency, power, and other technical attributes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An installation of such a system or of the transmitting and receiving apparatus.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A superior ability to detect something.",
              synonyms: [],
              antonyms: [],
              example:
                "His sensitive radar for hidden alliances keeps him out of trouble.",
            },
          ],
          synonyms: ["RADAR"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To scan with radar, or as if with radar.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/radar"],
    },
  ],
  wells: [
    {
      word: "wells",
      phonetic: "/wɛlz/",
      phonetics: [
        {
          text: "/wɛlz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wells-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651713",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A hole sunk into the ground as a source of water, oil, natural gas or other fluids.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A place where a liquid such as water surfaces naturally; a spring.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small depression suitable for holding liquid or other objects.",
              synonyms: [],
              antonyms: [],
              example: "Make a well in the dough mixture and pour in the milk.",
            },
            { definition: "A source of supply.", synonyms: [], antonyms: [] },
            {
              definition:
                "A vertical, cylindrical trunk in a ship, reaching down to the lowest part of the hull, through which the bilge pumps operate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The cockpit of a sailboat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A compartment in the middle of the hold of a fishing vessel, made tight at the sides, but having holes perforated in the bottom to let in water to keep fish alive while they are transported to market.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A vertical passage in the stern into which an auxiliary screw propeller may be drawn up out of the water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hole or excavation in the earth, in mining, from which run branches or galleries.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An opening through the floors of a building, as for a staircase or an elevator; a wellhole.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The open space between the bench and the counsel tables in a courtroom.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The lower part of a furnace, into which the metal falls.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A well drink.",
              synonyms: [],
              antonyms: [],
              example: "They're having a special tonight: $1 wells.",
            },
            {
              definition:
                "The playfield of Tetris and similar video games, into which the blocks fall.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In a microtiter plate, each of the small equal circular or square sections which serve as test tubes.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["shaft"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To issue forth, as water from the earth; to flow; to spring.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To have something seep out of the surface.",
              synonyms: [],
              antonyms: [],
              example: "Her eyes welled with tears.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/well",
        "https://en.wiktionary.org/wiki/wells",
      ],
    },
  ],
  skull: [
    {
      word: "skull",
      phonetic: "/skʌl/",
      phonetics: [
        {
          text: "/skʌl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/skull-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769767",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The main bones of the head considered as a unit; including the cranium, facial bones, and mandible.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A symbol for death; death's-head",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The mind or brain.", synonyms: [], antonyms: [] },
            {
              definition:
                "A crust formed on the ladle, etc. by the partial cooling of molten metal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The crown of the headpiece in armour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A shallow bow-handled basket.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["brainpan", "harnpan"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To hit in the head with a fist, a weapon, or a thrown object.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To strike the top of (the ball).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/skull"],
    },
    {
      word: "skull",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(collective) A group of fish or a group of marine mammals such as porpoises, dolphins, or whales.",
              synonyms: [],
              antonyms: [],
              example: "The divers encountered a huge school of mackerel.",
            },
            { definition: "A multitude.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["shoal"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/school",
        "https://en.wiktionary.org/wiki/skull",
      ],
    },
  ],
  hairs: [
    {
      word: "hairs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A pigmented filament of keratin which grows from a follicle on the skin of humans and other mammals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The collection or mass of such growths growing from the skin of humans and animals, and forming a covering for a part of the head or for any part or the whole body.",
              synonyms: [],
              antonyms: [],
              example:
                "In the western world, women usually have long hair while men usually have short hair.",
            },
            {
              definition:
                "A slender outgrowth from the chitinous cuticle of insects, spiders, crustaceans, and other invertebrates. Such hairs are totally unlike those of vertebrates in structure, composition, and mode of growth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cellular outgrowth of the epidermis, consisting of one or of several cells, whether pointed, hooked, knobbed, or stellated.",
              synonyms: [],
              antonyms: [],
              example:
                "Internal hairs occur in the flower stalk of the yellow frog lily (Nuphar).",
            },
            {
              definition:
                "A locking spring or other safety device in the lock of a rifle, etc., capable of being released by a slight pressure on a hair-trigger.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Haircloth; a hair shirt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any very small distance, or degree; a hairbreadth.",
              synonyms: [],
              antonyms: [],
              example:
                "Just a little louder please—turn that knob a hair to the right.",
            },
            {
              definition: "Complexity; difficulty; quality of being hairy",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hair",
        "https://en.wiktionary.org/wiki/hairs",
      ],
    },
  ],
  argue: [
    {
      word: "argue",
      phonetic: "/ˈɑː.ɡjuː/",
      phonetics: [
        { text: "/ˈɑː.ɡjuː/", audio: "" },
        {
          text: "/ˈɑɹ.ɡju/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/argue-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769301",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To show grounds for concluding (that); to indicate, imply.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To debate, disagree or discuss opposing or differing viewpoints.",
              synonyms: [],
              antonyms: [],
              example:
                "He also argued for stronger methods to be used against China.",
            },
            {
              definition: "To have an argument, a quarrel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To present (a viewpoint or an argument therefor).",
              synonyms: [],
              antonyms: [],
              example: "He argued his point.",
            },
            { definition: "To prove.", synonyms: [], antonyms: [] },
            { definition: "To accuse.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/argue"],
    },
  ],
  wears: [
    {
      word: "wears",
      phonetic: "/wɛəz/",
      phonetics: [
        { text: "/wɛəz/", audio: "" },
        {
          text: "/wɛɹz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wears-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651710",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To carry or have equipped on or about one's body, as an item of clothing, equipment, decoration, etc.",
              synonyms: [],
              antonyms: [],
              example:
                "He's wearing some nice pants today.  She wore her medals with pride.  Please wear your seatbelt.  Can you wear makeup and sunscreen at the same time?  He was wearing his lunch after tripping and falling into the buffet.",
            },
            {
              definition:
                "To have or carry on one's person habitually, consistently; or, to maintain in a particular fashion or manner.",
              synonyms: [],
              antonyms: [],
              example: "He wears eyeglasses.  She wears her hair in braids.",
            },
            {
              definition: "To bear or display in one's aspect or appearance.",
              synonyms: [],
              antonyms: [],
              example:
                "She wore a smile all day.  He walked out of the courtroom wearing an air of satisfaction.",
            },
            {
              definition:
                '(with "it") To overcome one\'s reluctance and endure a (previously specified) situation.',
              synonyms: [],
              antonyms: [],
              example:
                "I know you don't like working with him, but you'll just have to wear it.",
            },
            {
              definition:
                "To eat away at, erode, diminish, or consume gradually; to cause a gradual deterioration in; to produce (some change) through attrition, exposure, or constant use.",
              synonyms: [],
              antonyms: [],
              example:
                "You're going to wear a hole in the bottom of those shoes.  The water has slowly worn a channel into these rocks.  Long illness had worn the bloom from her cheeks.  Exile had worn the man to a shadow.",
            },
            {
              definition:
                "To undergo gradual deterioration; become impaired; be reduced or consumed gradually due to any continued process, activity, or use.",
              synonyms: [],
              antonyms: [],
              example:
                "The tiles were wearing thin due to years of children's feet.",
            },
            {
              definition: "To exhaust, fatigue, expend, or weary.",
              synonyms: [],
              antonyms: [],
              example:
                "His neverending criticism has finally worn my patience.  Toil and care soon wear the spirit.  Our physical advantage allowed us to wear the other team out and win.",
            },
            {
              definition:
                "To last or remain durable under hard use or over time; to retain usefulness, value, or desirable qualities under any continued strain or long period of time; sometimes said of a person, regarding the quality of being easy or difficult to tolerate.",
              synonyms: [],
              antonyms: [],
              example:
                "Don't worry, this fabric will wear. These pants will last you for years.  This color wears so well. I must have washed this sweater a thousand times.  I have to say, our friendship has worn pretty well.  It's hard to get to know him, but he wears well.",
            },
            {
              definition:
                '(in the phrase "wearing on (someone)") To cause annoyance, irritation, fatigue, or weariness near the point of an exhaustion of patience.',
              synonyms: [],
              antonyms: [],
              example: "Her high pitched voice is really wearing on me lately.",
            },
            {
              definition: "(of time) To pass slowly, gradually or tediously.",
              synonyms: [],
              antonyms: [],
              example:
                "wear on, wear away.  As the years wore on, we seemed to have less and less in common.",
            },
            {
              definition:
                'To bring (a sailing vessel) onto the other tack by bringing the wind around the stern (as opposed to tacking when the wind is brought around the bow); to come round on another tack by turning away from the wind. Also written "ware". Past: weared, or wore/worn.',
              synonyms: ["gybe"],
              antonyms: [],
            },
          ],
          synonyms: ["gybe"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To guard; watch; keep watch, especially from entry or invasion.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To defend; protect.", synonyms: [], antonyms: [] },
            {
              definition:
                "To ward off; prevent from approaching or entering; drive off; repel.",
              synonyms: [],
              antonyms: [],
              example: "to wear the wolf from the sheep",
            },
            {
              definition:
                "To conduct or guide with care or caution, as into a fold or place of safety.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An adjustable dam placed across a river to regulate the flow of water downstream.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A fence placed across a river to catch fish.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wear",
        "https://en.wiktionary.org/wiki/wears",
        "https://en.wiktionary.org/wiki/weir",
      ],
    },
  ],
  dolls: [
    {
      word: "dolls",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A toy in the form of a human.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(sometimes offensive) An attractive young woman",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A term of endearment: darling, sweetheart.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A dollar.", synonyms: [], antonyms: [] },
            {
              definition:
                "(now possibly offensive) A good-natured, cooperative or helpful girl.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The smallest or pet pig in a litter.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/doll",
        "https://en.wiktionary.org/wiki/dolls",
      ],
    },
  ],
  voted: [
    {
      word: "voted",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/voted-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651698",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cast a vote; to assert a formalized choice in an election",
              synonyms: [],
              antonyms: [],
              example: "Did you vote last month?",
            },
            {
              definition:
                "To choose or grant by means of a vote, or by general consent",
              synonyms: [],
              antonyms: [],
              example:
                "The depository may vote shares on behalf of investors who have not submitted instruction to the bank.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/vote",
        "https://en.wiktionary.org/wiki/voted",
      ],
    },
  ],
  caves: [
    {
      word: "caves",
      phonetic: "/keɪvz/",
      phonetics: [{ text: "/keɪvz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A large, naturally-occurring cavity formed underground or in the face of a cliff or a hillside.",
              synonyms: [],
              antonyms: [],
              example:
                "We found a cave on the mountainside where we could take shelter.",
            },
            {
              definition:
                "A hole, depression, or gap in earth or rock, whether natural or man-made.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A storage cellar, especially for wine or cheese.",
              synonyms: [],
              antonyms: [],
              example: "This wine has been aged in our cave for thirty years.",
            },
            {
              definition: "A place of retreat, such as a man cave.",
              synonyms: [],
              antonyms: [],
              example:
                "My room was a cozy cave where I could escape from my family.",
            },
            {
              definition:
                "A naturally-occurring cavity in bedrock which is large enough to be entered by an adult.",
              synonyms: [],
              antonyms: [],
              example:
                "It was not strictly a cave, but a narrow fissure in the rock.",
            },
            {
              definition:
                "A shielded area where nuclear experiments can be carried out.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(drilling) Debris, particularly broken rock, which falls into a drill hole and interferes with drilling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A collapse or cave-in.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The vagina.", synonyms: [], antonyms: [] },
            {
              definition:
                '(often "Cave") A group that breaks from a larger political party or faction on a particular issue.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any hollow place, or part; a cavity.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A code cave.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["earthhole"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To surrender.",
              synonyms: [],
              antonyms: [],
              example: "He caved under pressure.",
            },
            {
              definition: "To collapse.",
              synonyms: [],
              antonyms: [],
              example:
                "First the braces buckled, then the roof began to cave, then we ran.",
            },
            {
              definition: "To hollow out or undermine.",
              synonyms: [],
              antonyms: [],
              example:
                "The levee has been severely caved by the river current.",
            },
            {
              definition: "To engage in the recreational exploration of caves.",
              synonyms: ["spelunk"],
              antonyms: [],
              example: "I have caved from Yugoslavia to Kentucky.",
            },
            {
              definition:
                "In room-and-pillar mining, to extract a deposit of rock by breaking down a pillar which had been holding it in place.",
              synonyms: [],
              antonyms: [],
              example: "The deposit is caved by knocking out the posts.",
            },
            {
              definition:
                "To work over tailings to dress small pieces of marketable ore.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To dwell in a cave.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["spelunk"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cave",
        "https://en.wiktionary.org/wiki/caves",
      ],
    },
  ],
  cared: [
    {
      word: "cared",
      phonetic: "/kɛəd/",
      phonetics: [
        { text: "/kɛəd/", audio: "" },
        {
          text: "/kɛɹd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cared-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749735",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To be concerned (about), to have an interest (in); to feel concern (about).",
              synonyms: [],
              antonyms: [],
              example:
                '"She doesn\'t care what you think." "I don\'t care, I\'m still going."',
            },
            {
              definition:
                "(polite) To want, to desire; to like; to be inclined towards.",
              synonyms: [],
              antonyms: [],
              example: "I don't care to hear your opinion.",
            },
            {
              definition: "(with for) To look after or look out for.",
              synonyms: [],
              antonyms: [],
              example: "He cared for his mother while she was sick.",
            },
            { definition: "To mind; to object.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/care",
        "https://en.wiktionary.org/wiki/cared",
      ],
    },
  ],
  broom: [
    {
      word: "broom",
      phonetic: "/bɹuːm/",
      phonetics: [
        {
          text: "/bɹuːm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/broom-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769623",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A domestic utensil with fibers bound together at the end of a long handle, used for sweeping.",
              synonyms: ["besom"],
              antonyms: [],
            },
            {
              definition:
                "An implement with which players sweep the ice to make a stone travel further and curl less; a sweeper.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of several yellow-flowered shrubs of the family Fabaceae, in the tribe Genisteae, including genera Cytisus, Genista, and Spartium, with long, thin branches and small or few leaves.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A gun, because it is more or less long, held similarly to a besom and “cleans” what is in front.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["besom"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To sweep with a broom.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(roofing) To improve the embedding of a membrane by using a broom or squeegee to smooth it out and ensure contact with the adhesive under the membrane.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/broom"],
    },
    {
      word: "broom",
      phonetic: "/bɹuːm/",
      phonetics: [
        {
          text: "/bɹuːm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/broom-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769623",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To clean (e.g. a ship's bottom of clinging shells, seaweed, etc.) by the application of fire and scraping.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bream",
        "https://en.wiktionary.org/wiki/broom",
      ],
    },
  ],
  scent: [
    {
      word: "scent",
      phonetic: "/sɛnt/",
      phonetics: [
        {
          text: "/sɛnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scent-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1770050",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A distinctive odour or smell.",
              synonyms: [],
              antonyms: [],
              example: "the scent of a skunk",
            },
            {
              definition:
                "An odour left by an animal that may be used for tracing.",
              synonyms: [],
              antonyms: [],
              example: "The dogs lost the scent.",
            },
            {
              definition: "The sense of smell.",
              synonyms: [],
              antonyms: [],
              example:
                "I believe the bloodhound has the best scent of all dogs.",
            },
            { definition: "A perfume.", synonyms: [], antonyms: [] },
            {
              definition:
                "Any trail or trace that can be followed to find something or someone, such as the paper left behind in a paperchase.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Sense, perception.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To detect the scent of; to discern by the sense of smell.",
              synonyms: [],
              antonyms: [],
              example: "The hounds scented the fox in the woods.",
            },
            {
              definition: "To have a suspicion of.",
              synonyms: [],
              antonyms: [],
              example:
                "I scented trouble when I saw them running down the hill towards me.",
            },
            {
              definition: "To impart an odour to.",
              synonyms: [],
              antonyms: [],
              example:
                "Scent the air with burning sage before you begin your meditation.",
            },
            { definition: "To have a smell.", synonyms: [], antonyms: [] },
            {
              definition: "To hunt animals by means of the sense of smell.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scent"],
    },
  ],
  panel: [
    {
      word: "panel",
      phonetic: "/ˈpænəl/",
      phonetics: [
        {
          text: "/ˈpænəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/panel-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780257",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A (usually) rectangular section of a surface, or of a covering or of a wall, fence etc.",
              synonyms: [],
              antonyms: [],
              example: "Behind the picture was a panel on the wall.",
            },
            {
              definition:
                "A group of people gathered to judge, interview, discuss etc. as on a television or radio broadcast for example.",
              synonyms: [],
              antonyms: [],
              example: "Today’s panel includes John Smith.",
            },
            {
              definition: "An individual frame or drawing in a comic.",
              synonyms: [],
              antonyms: [],
              example:
                "The last panel of a comic strip usually contains a punchline.",
            },
            {
              definition: "A type of GUI widget, such as a control panel.",
              synonyms: [],
              antonyms: [],
              example: "admin panel",
            },
            {
              definition:
                "A document containing the names of persons summoned as jurors by the sheriff; hence, more generally, the whole jury.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A prisoner arraigned for trial at the bar of a criminal court.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A piece of cloth serving as a saddle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A soft pad beneath a saddletree to prevent chafing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(joinery) A board having its edges inserted in the groove of a surrounding frame.",
              synonyms: [],
              antonyms: [],
              example: "the panel of a door",
            },
            {
              definition: "One of the faces of a hewn stone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A slab or plank of wood used instead of a canvas for painting on.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A heap of dressed ore.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the districts divided by pillars of extra size, into which a mine is laid off in one system of extracting coal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A frame for carrying a mortar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A plain strip or band, as of velvet or plush, placed at intervals lengthwise on the skirt of a dress, for ornament.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A portion of a framed structure between adjacent posts or struts, as in a bridge truss.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A list of doctors who could provide limited free healthcare prior to the introduction of the NHS.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A group of tests or assays, a battery.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To fit with panels.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/panel"],
    },
  ],
  fairy: [
    {
      word: "fairy",
      phonetic: "/ˈfɛə̯ɹi/",
      phonetics: [
        { text: "/ˈfɛə̯ɹi/", audio: "" },
        {
          text: "/ˈfɛə̯ɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fairy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769390",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The realm of faerie; enchantment, illusion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A mythical being with magical powers, known in many sizes and descriptions, although often depicted in modern illustrations only as a small sprite with gauze-like wings, and revered in some modern forms of paganism.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An enchantress, or creature of overpowering charm.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A male homosexual, especially one who is effeminate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A member of two species of hummingbird in the genus Heliothryx.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "fag",
            "faggot",
            "poof",
            "queen",
            "fae",
            "fay",
            "fey",
            "goblin",
            "sprite",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Like a fairy; fanciful, whimsical, delicate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fairy"],
    },
  ],
  olive: [
    {
      word: "olive",
      phonetic: "/ˈɒlɪv/",
      phonetics: [
        {
          text: "/ˈɒlɪv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/olive-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=509978",
        },
        {
          text: "/ˈɑlɪv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/olive-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=537390",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A tree, Olea europaea, cultivated since ancient times in the Mediterranean for its fruit and the oil obtained from it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The small oval fruit of this tree, eaten ripe (usually black) or unripe (usually green).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The wood of the olive tree.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A dark yellowish-green color, that of an unripe olive.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An olivary body, part of the medulla oblongata.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A component of a plumbing compression joint; a ring which is placed between the nut and the pipe and compressed during fastening to provide a seal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small slice of meat seasoned, rolled up, and cooked.",
              synonyms: [],
              antonyms: [],
              example: "a beef olive",
            },
            {
              definition:
                "Any shell of the genus Oliva and allied genera; so called from the shape.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An oystercatcher, a shore bird.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of a grayish green color, that of an unripe olive.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/olive"],
    },
  ],
  bends: [
    {
      word: "bends",
      phonetic: "/bɛndz/",
      phonetics: [{ text: "/bɛndz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A curve.",
              synonyms: [],
              antonyms: [],
              example: "There's a sharp bend in the road ahead.",
            },
            {
              definition:
                "Any of the various knots which join the ends of two lines.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in the plural, underwater diving, with the) A severe condition caused by excessively quick decompression, causing bubbles of nitrogen to form in the blood; decompression sickness.",
              synonyms: [],
              antonyms: [],
              example:
                "A diver who stays deep for too long must ascend very slowly in order to prevent the bends.",
            },
            {
              definition:
                "One of the honourable ordinaries formed by two diagonal lines drawn from the dexter chief to the sinister base; it generally occupies a fifth part of the shield if uncharged, but if charged one third.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Turn; purpose; inclination; ends.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In the leather trade, the best quality of sole leather; a butt; sometimes, half a butt cut lengthwise.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Hard, indurated clay; bind.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in the plural) The thickest and strongest planks in a ship's sides, more generally called wales, which have the beams, knees, and futtocks bolted to them.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in the plural) The frames or ribs that form the ship's body from the keel to the top of the sides.",
              synonyms: [],
              antonyms: [],
              example: "the midship bends",
            },
            {
              definition:
                "A glissando, or glide between one pitch and another.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cause (something) to change its shape into a curve, by physical force, chemical action, or any other means.",
              synonyms: [],
              antonyms: [],
              example: "Don’t bend your knees.",
            },
            {
              definition: "To become curved.",
              synonyms: [],
              antonyms: [],
              example: "Look at the trees bending in the wind.",
            },
            {
              definition: "To cause to change direction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To change direction.",
              synonyms: [],
              antonyms: [],
              example: "The road bends to the right",
            },
            {
              definition: "To be inclined; to direct itself.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: '(usually with "down") To stoop.',
              synonyms: [],
              antonyms: [],
              example: "He bent down to pick up the pieces.",
            },
            {
              definition: "To bow in prayer, or in token of submission.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To force to submit.",
              synonyms: [],
              antonyms: [],
              example: "They bent me to their will.",
            },
            {
              definition: "To submit.",
              synonyms: [],
              antonyms: [],
              example: "I am bending to my desire to eat junk food.",
            },
            {
              definition: "To apply to a task or purpose.",
              synonyms: [],
              antonyms: [],
              example:
                "He bent the company's resources to gaining market share.",
            },
            {
              definition: "To apply oneself to a task or purpose.",
              synonyms: [],
              antonyms: [],
              example: "He bent to the goal of gaining market share.",
            },
            {
              definition:
                "To adapt or interpret to for a purpose or beneficiary.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To tie, as in securing a line to a cleat; to shackle a chain to an anchor; make fast.",
              synonyms: [],
              antonyms: [],
              example: "Bend the sail to the yard.",
            },
            {
              definition: "To smoothly change the pitch of a note.",
              synonyms: [],
              antonyms: [],
              example:
                "You should bend the G slightly sharp in the next measure.",
            },
            {
              definition: "To swing the body when rowing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The thickest and strongest planks in a wooden ship's side, wales.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sometimes fatal condition resulting from the formation of nitrogen bubbles in the blood and tissues, because of too rapid decompression, seen especially in deep-sea divers ascending rapidly from a dive. It is characterized by severe pains in the joints and chest, skin irritation, cramps, nausea, and paralysis.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "aeroembolism",
            "aeroemphysema",
            "caisson disease",
            "the bends",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bend",
        "https://en.wiktionary.org/wiki/bends",
        "https://en.wiktionary.org/wiki/decompression%20sickness",
      ],
    },
  ],
  prism: [
    {
      word: "prism",
      phonetic: "/ˈpɹɪzəm/",
      phonetics: [
        {
          text: "/ˈpɹɪzəm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/prism-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=283965",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A polyhedron with parallel ends of the same size and shape, the other faces being parallelogram-shaped sides.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A transparent block in the shape of a prism (typically with triangular ends), used to split or reflect light.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A crystal in which the faces are parallel to the vertical axis.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/prism"],
    },
  ],
  lamps: [
    {
      word: "lamps",
      phonetic: "/læmps/",
      phonetics: [{ text: "/læmps/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A device that generates heat, light or other radiation. Especially an electric light bulb.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A device containing oil, burnt through a wick for illumination; an oil lamp.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of furniture holding one or more electric light sockets.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To hit, clout, belt, wallop.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To hunt at night using a lamp; see lamping.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To hang out or chill; to do nothing in particular.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lamp",
        "https://en.wiktionary.org/wiki/lamps",
      ],
    },
  ],
  cable: [
    {
      word: "cable",
      phonetic: "/keɪ.bl/",
      phonetics: [
        {
          text: "/keɪ.bl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cable-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762628",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(material) A long object used to make a physical connection.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(communications) A system for transmitting television or Internet services over a network of coaxial or fibreoptic cables.",
              synonyms: [],
              antonyms: [],
              example:
                "I tried to watch the movie last night but my cable was out.",
            },
            {
              definition:
                "A telegram, notably when sent by (submarine) telegraph cable.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A unit of length equal to one tenth of a nautical mile.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(unit, chiefly nautical) 100 fathoms, 600 imperial feet, approximately 185 m.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The currency pair British Pound against United States Dollar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A moulding, shaft of a column, or any other member of convex, rounded section, made to resemble the spiral twist of a rope.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A textural pattern achieved by passing groups of stitches over one another.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["cable length", "cablegram", "cord", "wire rope"],
          antonyms: ["hawser"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To provide with cable(s)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fasten (as if) with cable(s)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To wrap wires to form a cable",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To send a telegram by cable",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To communicate by cable",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To ornament with cabling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To create cable stitches.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cable"],
    },
  ],
  peach: [
    {
      word: "peach",
      phonetic: "/piːt͡ʃ/",
      phonetics: [
        {
          text: "/piːt͡ʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/peach-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1763014",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A tree (Prunus persica), native to China and now widely cultivated throughout temperate regions, having pink flowers and edible fruit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The soft juicy stone fruit of the peach tree, having yellow flesh, downy, red-tinted yellow skin, and a deeply sculptured pit or stone containing a single seed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A light moderate to strong yellowish pink to light orange color.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A particularly admirable or pleasing person or thing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["peachtree"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of or pertaining to the color peach.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Particularly pleasing or agreeable.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "agreeable",
            "fair",
            "orange",
            "paragon",
            "peachy",
            "rosy",
            "🍑",
          ],
          antonyms: ["disagreeable", "foul", "ugly", "unpleasant"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/peach"],
    },
    {
      word: "peach",
      phonetic: "/piːt͡ʃ/",
      phonetics: [
        {
          text: "/piːt͡ʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/peach-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1763014",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To inform on someone; turn informer.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To inform against.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["sing", "squeal", "tattle"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/peach"],
    },
    {
      word: "peach",
      phonetic: "/piːt͡ʃ/",
      phonetics: [
        {
          text: "/piːt͡ʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/peach-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1763014",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A particular rock found in tin mines, sometimes associated with chlorite.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/peach"],
    },
  ],
  ruins: [
    {
      word: "ruins",
      phonetic: "/ˈɹuː.ɪnz/",
      phonetics: [
        { text: "/ˈɹuː.ɪnz/", audio: "" },
        {
          text: "/ˈɹu.ɪnz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ruins-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100847",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(sometimes in the plural) The remains of a destroyed or dilapidated construction, such as a house or castle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The state of being a ruin, destroyed or decayed.",
              synonyms: [],
              antonyms: [],
              example: "The monastery has fallen into ruin.",
            },
            {
              definition:
                "Something that leads to serious trouble or destruction.",
              synonyms: [],
              antonyms: [],
              example: "Gambling has been the ruin of many.",
            },
            { definition: "A fall or tumble.", synonyms: [], antonyms: [] },
            {
              definition:
                "A change that destroys or defeats something; destruction; overthrow.",
              synonyms: [],
              antonyms: [],
              example:
                "the ruin of a ship or an army;  the ruin of a constitution or a government;  the ruin of health or hopes",
            },
            {
              definition: "Complete financial loss; bankruptcy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cause the fiscal ruin of.",
              synonyms: [],
              antonyms: [],
              example: "With all these purchases, you surely mean to ruin us!",
            },
            {
              definition: "To destroy or make something no longer usable.",
              synonyms: [],
              antonyms: [],
              example:
                "He ruined his new white slacks by accidentally spilling oil on them.",
            },
            {
              definition:
                "To cause severe financial loss to; to bankrupt or drive out of business.",
              synonyms: [],
              antonyms: [],
              example:
                "The crooked stockbroker's fraudulent scheme ruined dozens of victims; some investors lost their life savings and even their houses.",
            },
            {
              definition:
                "To upset or overturn the plans or progress of, or to have a disastrous effect on something.",
              synonyms: [],
              antonyms: [],
              example:
                "My car breaking down just as I was on the road ruined my vacation.",
            },
            {
              definition: "To make something less enjoyable or likeable.",
              synonyms: [],
              antonyms: [],
              example:
                "I used to love that song, but being assaulted when that song was playing ruined the song for me.",
            },
            {
              definition: "To reveal the ending of (a story); to spoil.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fall into a state of decay.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To seduce or debauch, and thus harm the social standing of.",
              synonyms: [],
              antonyms: [],
              example:
                "The young libertine was notorious for ruining local girls.",
            },
          ],
          synonyms: ["destroy", "fordo", "ruinate", "wreck"],
          antonyms: ["build", "construct", "found", "produce"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ruin",
        "https://en.wiktionary.org/wiki/ruins",
      ],
    },
  ],
  rally: [
    {
      word: "rally",
      phonetic: "/ˈɹæ.li/",
      phonetics: [
        {
          text: "/ˈɹæ.li/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rally-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80066768",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A public gathering or mass meeting that is not mainly a protest and is organized to inspire enthusiasm for a cause.",
              synonyms: [],
              antonyms: [],
              example: "a campaign rally",
            },
            {
              definition:
                "A protest or demonstration for or against something, but often with speeches and often without marching, especially in North America.",
              synonyms: [],
              antonyms: [],
              example: "a political rally",
            },
            {
              definition:
                "A sequence of strokes between serving and scoring a point.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An event in which competitors drive through a series of timed special stages at intervals. The winner is the driver who completes all stages with the shortest cumulative time.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A recovery after a decline in prices (said of the market, stocks, etc.)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To collect, and reduce to order, as troops dispersed or thrown into confusion; to gather again; to reunite.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To come into orderly arrangement; to renew order, or united effort, as troops scattered or put to flight; to assemble; to unite.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To collect one's vital powers or forces; to regain health or consciousness; to recuperate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To recover strength after a decline in prices; -- said of the market, stocks, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["muster", "bounce back", "rebound"],
          antonyms: ["decline"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rally"],
    },
    {
      word: "rally",
      phonetic: "/ˈɹæ.li/",
      phonetics: [
        {
          text: "/ˈɹæ.li/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rally-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80066768",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Good-humoured raillery.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To tease; to chaff good-humouredly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rally"],
    },
  ],
  schwa: [
    {
      word: "schwa",
      phonetic: "/ʃwɑ/",
      phonetics: [
        {
          text: "/ʃwɑ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/schwa-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769511",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                'An indeterminate central vowel sound as the "a" in "about", represented as /ə/ in IPA.',
              synonyms: ["natural vowel", "neutral vowel"],
              antonyms: [],
            },
            { definition: "The character ə.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["natural vowel", "neutral vowel"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/schwa"],
    },
  ],
  lambs: [
    {
      word: "lambs",
      phonetic: "/læmz/",
      phonetics: [{ text: "/læmz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A young sheep.", synonyms: [], antonyms: [] },
            {
              definition: "The flesh of a lamb or sheep used as food.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person who is meek, docile and easily led.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A simple, unsophisticated person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One who ignorantly speculates on the stock exchange and is victimized.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["sheepling"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "Of a sheep, to give birth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To assist (sheep) to give birth.",
              synonyms: [],
              antonyms: [],
              example: "The shepherd was up all night, lambing her young ewes.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lamb",
        "https://en.wiktionary.org/wiki/lambs",
      ],
    },
  ],
  sells: [
    {
      word: "sells",
      phonetic: "/sɛlz/",
      phonetics: [{ text: "/sɛlz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An act of selling.",
              synonyms: [],
              antonyms: [],
              example: "This is going to be a tough sell.",
            },
            { definition: "An easy task.", synonyms: [], antonyms: [] },
            {
              definition:
                "An imposition, a cheat; a hoax; a disappointment; anything occasioning a loss of pride or dignity.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(ditransitive) To transfer goods or provide services in exchange for money.",
              synonyms: [],
              antonyms: [],
              example: "I'll sell you all three for a hundred dollars.",
            },
            {
              definition: "To be sold.",
              synonyms: [],
              antonyms: [],
              example: "The corn sold for a good price.",
            },
            {
              definition: "To promote a product or service.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To promote a particular viewpoint.",
              synonyms: [],
              antonyms: [],
              example:
                "My boss is very old-fashioned and I'm having a lot of trouble selling the idea of working at home occasionally.",
            },
            { definition: "To betray for money.", synonyms: [], antonyms: [] },
            {
              definition: "To trick, cheat, or manipulate someone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To pretend that an opponent's blows or maneuvers are causing legitimate injury; to act.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["buy"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A seat or stool.", synonyms: [], antonyms: [] },
            { definition: "A saddle.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A rope (usually for tying up cattle, but can also mean any sort of rope).",
              synonyms: [],
              antonyms: [],
              example:
                'He picked up the sell from the straw-strewn barn-floor, snelly sneaked up behind her and sleekly slung it around her swire while scryingː "dee, dee ye fooking quhoreǃ".',
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sell",
        "https://en.wiktionary.org/wiki/sells",
      ],
    },
  ],
  cools: [
    {
      word: "cools",
      phonetic: "/kuːlz/",
      phonetics: [{ text: "/kuːlz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To lose heat, to get colder.",
              synonyms: [],
              antonyms: [],
              example:
                "I like to let my tea cool before drinking it so I don't burn my tongue.",
            },
            {
              definition: "To make cooler, less warm.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To become less intense, e.g. less amicable or passionate.",
              synonyms: [],
              antonyms: [],
              example:
                "Relations cooled between the USA and the USSR after 1980.",
            },
            {
              definition:
                "To make less intense, e.g. less amicable or passionate.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To kill.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cool",
        "https://en.wiktionary.org/wiki/cools",
      ],
    },
  ],
  draft: [
    {
      word: "draft",
      phonetic: "/dɹæft/",
      phonetics: [
        { text: "/dɹæft/", audio: "" },
        {
          text: "/dɹæft/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/draft-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769380",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A current of air, usually coming into a room or vehicle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Draw through a flue of gasses (smoke) resulting from a combustion process.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An act of drinking.", synonyms: [], antonyms: [] },
            {
              definition:
                "The quantity of liquid (such as water, alcohol, or medicine) drunk in one swallow.",
              synonyms: [],
              antonyms: [],
              example: "She took a deep draft from the bottle of water.",
            },
            {
              definition: "A dose (of medicine, alcohol, etc.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Beer drawn from a cask or keg rather than a bottle or can.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Depth of water needed to float a ship; depth below the water line to the bottom of a vessel's hull; depth of water drawn by a vessel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An early version of a written work (such as a book or e-mail) or drawing.",
              synonyms: [],
              antonyms: [],
              example:
                "His first drafts were better than most authors' final products.",
            },
            {
              definition: "A preliminary sketch or outline for a plan.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A cheque, an order for money to be paid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Conscription, the system of forcing people to serve in the military.",
              synonyms: [],
              antonyms: [],
              example: "He left the country to avoid the draft.",
            },
            {
              definition:
                "A system of forcing or convincing people to take an elected position.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A system of assigning rookie players to professional sports teams.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The pulling force (tension) on couplers and draft gear during a slack stretched condition.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The bevel given to the pattern for a casting, so that it can be drawn from the sand without damaging the mould.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(possibly obsolete) The action or an act (especially of a beast of burden or vehicle) of pulling something along or back.",
              synonyms: [],
              antonyms: [],
              example:
                "using oxen for draft   shot forth an arrow with a mighty draft",
            },
            {
              definition:
                "(possibly obsolete) The act of drawing in a net for fish.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(possibly obsolete) That which is drawn in; a catch, a haul.",
              synonyms: [],
              antonyms: [],
              example: "he cast his net, which brought him a very great draft",
            },
            {
              definition:
                "A quantity that is requisitioned or drawn out from a larger population.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["swig"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To write a first version, make a preliminary sketch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To draw in outline; to make a draught, sketch, or plan of, as in architectural and mechanical drawing.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To write a law.", synonyms: [], antonyms: [] },
            {
              definition:
                "To conscript a person, force a person to serve in some capacity, especially in the military.",
              synonyms: [],
              antonyms: [],
              example: "He was drafted during the Vietnam War.",
            },
            {
              definition:
                "To select someone (or something) for a particular role or purpose.",
              synonyms: [],
              antonyms: [],
              example:
                "There was a campaign to draft Smith to run for President.",
            },
            {
              definition:
                "To select and separate an animal or animals from a group.",
              synonyms: [],
              antonyms: [],
              example: "The calves were drafted from the cows.",
            },
            {
              definition:
                "To select a rookie player onto a professional sports team.",
              synonyms: [],
              antonyms: [],
              example:
                "After his last year of college football, he was drafted by the Miami Dolphins.",
            },
            {
              definition:
                "To follow very closely (behind another vehicle), thereby providing an aerodynamic advantage to both lead and follower and conserving energy or increasing speed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To draw out; to call forth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To draw fibers out of a clump, for spinning in the production of yarn.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Referring to drinks on tap, in contrast to bottled.",
              synonyms: [],
              antonyms: [],
              example: "I'd rather have a fresh, cheap draft beer.",
            },
            {
              definition: "Referring to animals used for pulling heavy loads.",
              synonyms: [],
              antonyms: [],
              example: "A Clydesdale is a draft horse.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/draft"],
    },
  ],
  charm: [
    {
      word: "charm",
      phonetic: "/tʃɑːm/",
      phonetics: [
        { text: "/tʃɑːm/", audio: "" },
        {
          text: "/tʃɑɹm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/charm-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749742",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An object, act or words believed to have magic power (usually carries a positive connotation).",
              synonyms: [],
              antonyms: [],
              example: "It works like a charm.",
            },
            {
              definition:
                "The ability to persuade, delight or arouse admiration; often constructed in the plural.",
              synonyms: [],
              antonyms: [],
              example: "He had great personal charm.",
            },
            {
              definition:
                "A small trinket on a bracelet or chain, etc., traditionally supposed to confer luck upon the wearer.",
              synonyms: [],
              antonyms: [],
              example: "She wears a charm bracelet on her wrist.",
            },
            {
              definition:
                "A quantum number of hadrons determined by the number of charm quarks and antiquarks.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A second-order measure of derivative price sensitivity, expressed as the instantaneous rate of change of delta with respect to time.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "DdeltaDtime",
            "delta decay",
            "appeal",
            "attraction",
            "charisma",
            "amulet",
            "incantation",
            "spell",
            "talisman",
            "amulet",
            "dangle",
            "ornament",
          ],
          antonyms: ["boredom", "dryness"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To seduce, persuade or fascinate someone or something.",
              synonyms: [],
              antonyms: [],
              example:
                "He charmed her with his dashing tales of his days as a sailor.",
            },
            {
              definition:
                "To use a magical charm upon; to subdue, control, or summon by incantation or supernatural influence.",
              synonyms: [],
              antonyms: [],
              example:
                "After winning three games while wearing the chain, Dan began to think it had been charmed.",
            },
            {
              definition:
                "To protect with, or make invulnerable by, spells, charms, or supernatural influences.",
              synonyms: [],
              antonyms: [],
              example: "She led a charmed life.",
            },
            { definition: "To make music upon.", synonyms: [], antonyms: [] },
            {
              definition:
                "To subdue or overcome by some secret power, or by that which gives pleasure; to allay; to soothe.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "delight",
            "enchant",
            "entrance",
            "bewitch",
            "enchant",
            "ensorcel",
            "enspell",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/charm"],
    },
    {
      word: "charm",
      phonetic: "/tʃɑːm/",
      phonetics: [
        { text: "/tʃɑːm/", audio: "" },
        {
          text: "/tʃɑɹm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/charm-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749742",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The mixed sound of many voices, especially of birds or children.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A flock, group (especially of finches).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/charm"],
    },
  ],
  limbs: [
    {
      word: "limbs",
      phonetic: "/lɪmz/",
      phonetics: [
        {
          text: "/lɪmz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/limbs-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100469",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A major appendage of human or animal, used for locomotion (such as an arm, leg or wing).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A branch of a tree.",
              synonyms: ["bough"],
              antonyms: [],
            },
            {
              definition: "The part of the bow, from the handle to the tip.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An elementary piece of the mechanism of a lock.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A thing or person regarded as a part or member of, or attachment to, something else.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The part of a corolla beyond the throat.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bough"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To remove the limbs from (an animal or tree).",
              synonyms: [],
              antonyms: [],
              example:
                "They limbed the felled trees before cutting them into logs.",
            },
            { definition: "To supply with limbs.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["delimb"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The apparent visual edge of a celestial body.",
              synonyms: [],
              antonyms: [],
              example: "solar limb",
            },
            {
              definition:
                "(on a measuring instrument) The graduated edge of a circle or arc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The border or upper spreading part of a monopetalous corolla, or of a petal or sepal; blade.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/limb",
        "https://en.wiktionary.org/wiki/limbs",
      ],
    },
  ],
  brake: [
    {
      word: "brake",
      phonetic: "/bɹeɪk/",
      phonetics: [
        {
          text: "/bɹeɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/brake-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=634980",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A fern; bracken.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/brake"],
    },
    {
      word: "brake",
      phonetic: "/bɹeɪk/",
      phonetics: [
        {
          text: "/bɹeɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/brake-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=634980",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A thicket, or an area overgrown with briers etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/brake"],
    },
    {
      word: "brake",
      phonetic: "/bɹeɪk/",
      phonetics: [
        {
          text: "/bɹeɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/brake-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=634980",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A tool used for breaking flax or hemp.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A type of machine for bending sheet metal. (See wikipedia.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A large, heavy harrow for breaking clods after ploughing; a drag.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To bruise and crush; to knead",
              synonyms: [],
              antonyms: [],
              example:
                "The farmer's son brakes the flax while mother brakes the bread dough",
            },
            {
              definition: "To pulverise with a harrow",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/brake"],
    },
    {
      word: "brake",
      phonetic: "/bɹeɪk/",
      phonetics: [
        {
          text: "/bɹeɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/brake-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=634980",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An ancient engine of war analogous to the crossbow and ballista.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The handle of a pump.",
              synonyms: ["swipe"],
              antonyms: [],
            },
            {
              definition:
                "A device used to slow or stop the motion of a wheel, or of a vehicle, by friction; also, the controls or apparatus used to engage such a mechanism such as the pedal in a car.",
              synonyms: [],
              antonyms: [],
              example:
                "She slammed the brakes when she saw a child run in front of the car.",
            },
            {
              definition: "A baker's kneading trough.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A device used to confine or prevent the motion of an animal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That part of a carriage, as of a movable battery, or engine, which enables it to turn.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["swipe"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To operate (a) brake(s).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be stopped or slowed (as if) by braking.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [
            "accelerate",
            "floor it",
            "put the pedal to the metal",
            "redline",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/brake"],
    },
    {
      word: "brake",
      phonetic: "/bɹeɪk/",
      phonetics: [
        {
          text: "/bɹeɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/brake-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=634980",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A cage.", synonyms: [], antonyms: [] },
            {
              definition: "A type of torture instrument.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/brake"],
    },
    {
      word: "brake",
      phonetic: "/bɹeɪk/",
      phonetics: [
        {
          text: "/bɹeɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/brake-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=634980",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To separate into two or more pieces, to fracture or crack, by a process that cannot easily be reversed for reassembly.",
              synonyms: [],
              antonyms: [],
              example: "If the vase falls to the floor, it might break.",
            },
            {
              definition:
                "To divide (something, often money) into smaller units.",
              synonyms: [],
              antonyms: [],
              example: "Can you break a hundred-dollar bill for me?",
            },
            {
              definition:
                "To cause (a person or animal) to lose spirit or will; to crush the spirits of.",
              synonyms: [],
              antonyms: [],
              example: "Her child's death broke Angela.",
            },
            {
              definition: "To be crushed, or overwhelmed with sorrow or grief.",
              synonyms: [],
              antonyms: [],
              example: "My heart is breaking.",
            },
            {
              definition:
                "To interrupt; to destroy the continuity of; to dissolve or terminate.",
              synonyms: [],
              antonyms: [],
              example:
                "I had won four games in a row, but now you've broken my streak of luck.",
            },
            {
              definition: "To ruin financially.",
              synonyms: [],
              antonyms: [],
              example: "The recession broke some small businesses.",
            },
            {
              definition: "To violate, to not adhere to.",
              synonyms: [],
              antonyms: [],
              example: "He broke his vows by cheating on his wife.",
            },
            {
              definition:
                "(of a fever) To pass the most dangerous part of the illness; to go down, in terms of temperature.",
              synonyms: [],
              antonyms: [],
              example:
                "Susan's fever broke at about 3 AM, and the doctor said the worst was over.",
            },
            {
              definition: "(of a spell of settled weather) To end.",
              synonyms: [],
              antonyms: [],
              example:
                "The forecast says the hot weather will break by midweek.",
            },
            {
              definition: "(of a storm) To begin; to end.",
              synonyms: [],
              antonyms: [],
              example:
                "Around midday the storm broke, and the afternoon was calm and sunny.",
            },
            {
              definition: "(of morning, dawn, day etc.) To arrive.",
              synonyms: [],
              antonyms: [],
              example: "Morning has broken.",
            },
            {
              definition:
                "To render (a game) unchallenging by altering its rules or exploiting loopholes or weaknesses in them in a way that gives a player an unfair advantage.",
              synonyms: [],
              antonyms: [],
              example:
                "Changing the rules to let white have three extra queens would break chess.",
            },
            {
              definition:
                "To stop, or to cause to stop, functioning properly or altogether.",
              synonyms: [],
              antonyms: [],
              example: "Did you two break the trolley by racing with it?",
            },
            {
              definition: "To cause (a barrier) to no longer bar.",
              synonyms: [],
              antonyms: [],
              example: "break a seal",
            },
            {
              definition:
                "To destroy the arrangement of; to throw into disorder; to pierce.",
              synonyms: [],
              antonyms: [],
              example:
                "The cavalry were not able to break the British squares.",
            },
            {
              definition:
                "(of a wave of water) To collapse into surf, after arriving in shallow water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To burst forth; to make its way; to come into view.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To interrupt or cease one's work or occupation temporarily.",
              synonyms: [],
              antonyms: [],
              example: "Let's break for lunch.",
            },
            {
              definition:
                "To interrupt (a fall) by inserting something so that the falling object does not (immediately) hit something else beneath.",
              synonyms: [],
              antonyms: [],
              example:
                "He survived the jump out the window because the bushes below broke his fall.",
            },
            {
              definition: "To disclose or make known an item of news, etc.",
              synonyms: [],
              antonyms: [],
              example:
                "I don't know how to break this to you, but your cat is not coming back.",
            },
            {
              definition: "(of a sound) To become audible suddenly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To change a steady state abruptly.",
              synonyms: [],
              antonyms: [],
              example: "His coughing broke the silence.",
            },
            {
              definition: "To suddenly become.",
              synonyms: [],
              antonyms: [],
              example:
                "The arrest was standard, when suddenly the suspect broke ugly.",
            },
            {
              definition: "Of a male voice, to become deeper at puberty.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of a voice, to alter in type due to emotion or strain: in men generally to go up, in women sometimes to go down; to crack.",
              synonyms: [],
              antonyms: [],
              example: "His voice breaks when he gets emotional.",
            },
            {
              definition:
                "To surpass or do better than (a specific number), to do better than (a record), setting a new record.",
              synonyms: [],
              antonyms: [],
              example: "He broke the men's 100-meter record.",
            },
            { definition: ":", synonyms: [], antonyms: [] },
            {
              definition:
                "(most often in the passive tense) To demote, to reduce the military rank of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To end (a connection), to disconnect.",
              synonyms: [],
              antonyms: [],
              example:
                "I couldn't hear a thing he was saying, so I broke the connection and called him back.",
            },
            {
              definition: "(of an emulsion) To demulsify.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To counter-attack", synonyms: [], antonyms: [] },
            {
              definition:
                "To lay open, as a purpose; to disclose, divulge, or communicate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To become weakened in constitution or faculties; to lose health or strength.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fail in business; to become bankrupt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To destroy the strength, firmness, or consistency of.",
              synonyms: [],
              antonyms: [],
              example: "to break flax",
            },
            {
              definition:
                "To destroy the official character and standing of; to cashier; to dismiss.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make an abrupt or sudden change; to change the gait.",
              synonyms: [],
              antonyms: [],
              example: "to break into a run or gallop",
            },
            {
              definition: "To fall out; to terminate friendship.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To terminate the execution of a program before normal completion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To suspend the execution of a program during debugging so that the state of the program can be investigated.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "crack",
            "fracture",
            "burst",
            "bust",
            "shatter",
            "shear",
            "smash",
            "split",
            "break down",
            "bust",
            "fail",
            "go down",
            "contravene",
            "go against",
            "violate",
            "break in",
            "subject",
            "tame",
          ],
          antonyms: [
            "hold",
            "assemble",
            "fix",
            "join",
            "mend",
            "put together",
            "repair",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/brake",
        "https://en.wiktionary.org/wiki/break",
      ],
    },
  ],
  gazed: [
    {
      word: "gazed",
      phonetic: "/ɡeɪzd/",
      phonetics: [
        {
          text: "/ɡeɪzd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gazed-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1684620",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To stare intently or earnestly.",
              synonyms: [],
              antonyms: [],
              example:
                "In fact, for Antonioni this gazing is probably the most fundamental of all cognitive activities ... (from Thinking in the Absence of Image)",
            },
            { definition: "To stare at.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["gape", "look", "stare"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gaze",
        "https://en.wiktionary.org/wiki/gazed",
      ],
    },
  ],
  cubes: [
    {
      word: "cubes",
      phonetic: "/kjuːbz/",
      phonetics: [{ text: "/kjuːbz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A regular polyhedron having six identical square faces.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any object more or less in the form of a cube.",
              synonyms: [],
              antonyms: [],
              example: "a stock cube",
            },
            {
              definition:
                "The third power of a number, value, term or expression.",
              synonyms: [],
              antonyms: [],
              example: "the cube of 2 is 8",
            },
            {
              definition:
                "A data structure consisting of a three-dimensional array; a data cube",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A Rubick's cube style puzzle, not necessarily in the shape of a cube",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "regular hexahedron",
            "power",
            "third",
            "block",
            "brick",
            "die",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To raise to the third power; to determine the result of multiplying by itself twice.",
              synonyms: [],
              antonyms: [],
              example:
                "Three cubed can be written as 33, and equals twenty-seven.",
            },
            {
              definition: "To form into the shape of a cube.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cut into cubes.",
              synonyms: [],
              antonyms: [],
              example: "Cube the ham right after adding the curry to the rice.",
            },
            {
              definition: "To use a Rubik's cube.",
              synonyms: [],
              antonyms: [],
              example: "He likes to cube now and then.",
            },
          ],
          synonyms: ["dice"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A cubicle, especially one of those found in offices.",
              synonyms: [],
              antonyms: [],
              example:
                "My co-worker annoys me by throwing things over the walls of my cube.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cube",
        "https://en.wiktionary.org/wiki/cubes",
      ],
    },
  ],
  delay: [
    {
      word: "delay",
      phonetic: "/dɪˈleɪ/",
      phonetics: [
        {
          text: "/dɪˈleɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/delay-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1684585",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A period of time before an event occurs; the act of delaying; procrastination; lingering inactivity.",
              synonyms: [],
              antonyms: [],
              example: "the delay before the echo of a sound",
            },
            {
              definition:
                "An audio effects unit that introduces a controlled delay.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To put off until a later time; to defer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To retard; to stop, detain, or hinder, for a time.",
              synonyms: [],
              antonyms: [],
              example: "The mail is delayed by a heavy fall of snow.",
            },
            { definition: "To allay; to temper.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "calm",
            "moderate",
            "quell",
            "cunctation",
            "hold-up",
            "adjourn",
            "defer",
            "forslow",
            "on ice",
            "penelopize",
            "postpone",
            "put off",
            "suspend",
            "forslow",
            "get in the way",
            "hold up",
            "impede",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/delay"],
    },
    {
      word: "delay",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To dilute, temper.", synonyms: [], antonyms: [] },
            {
              definition: "To assuage, quench, allay.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/delay"],
    },
  ],
  beams: [
    {
      word: "beams",
      phonetic: "/biːmz/",
      phonetics: [
        {
          text: "/biːmz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/beams-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2650680",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any large piece of timber or iron long in proportion to its thickness, and prepared for use.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the principal horizontal timbers of a building; one of the transverse members of a ship's frame on which the decks are laid — supported at the sides by knees in wooden ships and by stringers in steel ones.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The maximum width of a vessel (note that a vessel with a beam of 15 foot can also be said to be 15 foot abeam)",
              synonyms: ["breadth"],
              antonyms: [],
              example: "This ship has more beam than that one.",
            },
            {
              definition:
                "The crossbar of a mechanical balance, from the ends of which the scales are suspended.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The principal stem of the antler of a deer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The pole of a carriage or chariot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cylinder of wood, making part of a loom, on which weavers wind the warp before weaving and the cylinder on which the cloth is rolled, as it is woven.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The straight part or shank of an anchor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The central bar of a plow, to which the handles and colter are secured, and to the end of which are attached the oxen or horses that draw it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In steam engines, a heavy iron lever having an oscillating motion on a central axis, one end of which is connected with the piston rod from which it receives motion, and the other with the crank of the wheel shaft.",
              synonyms: ["walking beam", "working beam"],
              antonyms: [],
            },
            {
              definition:
                "A ray or collection of approximately parallel rays emitted from the sun or other luminous body.",
              synonyms: [],
              antonyms: [],
              example: "a beam of energy",
            },
            {
              definition: "A ray; a gleam.",
              synonyms: [],
              antonyms: [],
              example: "a beam of hope, or of comfort",
            },
            {
              definition: "One of the long feathers in the wing of a hawk.",
              synonyms: ["beam feather"],
              antonyms: [],
            },
            {
              definition:
                "A horizontal bar which connects the stems of two or more notes to group them and to indicate metric value.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An elevated rectangular dirt pile used to cheaply build an elevated portion of a railway.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["beam feather", "breadth", "walking beam", "working beam"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To emit beams of light; shine; radiate.",
              synonyms: [],
              antonyms: [],
              example: "to beam forth light",
            },
            {
              definition: "To smile broadly or especially cheerfully.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To furnish or supply with beams",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To give the appearance of beams to.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To transmit matter or information via a high-tech wireless mechanism.",
              synonyms: [],
              antonyms: [],
              example:
                "Beam me up, Scotty; there's no intelligent life down here.",
            },
            {
              definition:
                "(currying) To stretch something (for example an animal hide) on a beam.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To put (something) on a beam",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To connect (musical notes) with a beam, or thick line, in music notation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/beam",
        "https://en.wiktionary.org/wiki/beams",
      ],
    },
  ],
  fetch: [
    {
      word: "fetch",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fetch-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75796730",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/fɛtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fetch-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80152577",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/fɛtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fetch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100713",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An act of fetching, of bringing something from a distance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The object of fetching; the source of an attraction; a force, propensity, or quality which attracts.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A stratagem or trick; an artifice.",
              synonyms: ["contrivance", "dodge"],
              antonyms: [],
            },
          ],
          synonyms: ["contrivance", "dodge"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To retrieve; to bear towards; to go and get.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To obtain as price or equivalent; to sell for.",
              synonyms: [],
              antonyms: [],
              example:
                "If you put some new tyres on it, and clean it up a bit, the car should fetch about $5,000",
            },
            {
              definition:
                "To bring or get within reach by going; to reach; to arrive at; to attain; to reach by sailing.",
              synonyms: [],
              antonyms: [],
              example: "to fetch headway or sternway",
            },
            {
              definition:
                "To bring oneself; to make headway; to veer; as, to fetch about; to fetch to windward.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To take (a breath), to heave (a sigh)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause to come; to bring to a particular state.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To recall from a swoon; to revive; sometimes with to.",
              synonyms: [],
              antonyms: [],
              example: "to fetch a man to",
            },
            { definition: "To reduce; to throw.", synonyms: [], antonyms: [] },
            {
              definition:
                "To bring to accomplishment; to achieve; to make; to perform, with certain objects.",
              synonyms: [],
              antonyms: [],
              example: "to fetch a compass;  to fetch a leap",
            },
            {
              definition:
                "To make (a pump) draw water by pouring water into the top and working the handle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fetch"],
    },
    {
      word: "fetch",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fetch-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75796730",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/fɛtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fetch-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80152577",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/fɛtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fetch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100713",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(originally Ireland) The apparition of a living person; a person's double, the sight of which is supposedly a sign that they are fated to die soon, a doppelganger; a wraith.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fetch"],
    },
  ],
  ranks: [
    {
      word: "ranks",
      phonetic: "/ɹæŋks/",
      phonetics: [
        {
          text: "/ɹæŋks/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ranks-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100500",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                'A row of people or things organized in a grid pattern, often soldiers [the corresponding term for the perpendicular columns in such a pattern is "file"].',
              synonyms: [],
              antonyms: [],
              example:
                "The front rank kneeled to reload while the second rank fired over their heads.",
            },
            {
              definition:
                "In a pipe organ, a set of pipes of a certain quality for which each pipe corresponds to one key or pedal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One's position in a list sorted by a shared property such as physical location, population, or quality",
              synonyms: [],
              antonyms: [],
              example: "Based on your test scores, you have a rank of 23.",
            },
            {
              definition:
                "The level of one's position in a class-based society",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hierarchical level in an organization such as the military",
              synonyms: [],
              antonyms: [],
              example:
                "He rose up through the ranks of the company, from mailroom clerk to CEO.",
            },
            {
              definition: "A level in a scientific taxonomy system",
              synonyms: [],
              antonyms: [],
              example:
                "Phylum is the taxonomic rank below kingdom and above class.",
            },
            {
              definition:
                "Maximal number of linearly independent columns (or rows) of a matrix.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The dimensionality of an array or tensor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The maximum quantity of D-linearly independent elements of a module (over an integral domain D).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The size of any basis of a given matroid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the eight horizontal lines of squares on a chessboard (i.e., those identified by a number). The analog vertical lines are the files.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(typically in the plural) A category of people, such as those who share an occupation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To place abreast, or in a line.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To have a ranking.",
              synonyms: [],
              antonyms: [],
              example: "Their defense ranked third in the league.",
            },
            {
              definition:
                "To assign a suitable place in a class or order; to classify.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To take rank of; to outrank.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rank",
        "https://en.wiktionary.org/wiki/ranks",
      ],
    },
  ],
  array: [
    {
      word: "array",
      phonetic: "/əˈɹeɪ/",
      phonetics: [
        {
          text: "/əˈɹeɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/array-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762865",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Clothing and ornamentation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A collection laid out to be viewed in full.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An orderly series, arrangement or sequence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Order; a regular and imposing arrangement; disposition in regular lines; hence, order of battle.",
              synonyms: [],
              antonyms: [],
              example: "drawn up in battle array",
            },
            {
              definition: "A large collection.",
              synonyms: [],
              antonyms: [],
              example: "We offer a dazzling array of choices.",
            },
            {
              definition: "Common name for matrix.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various data structures designed to hold multiple elements of the same type; especially, a data structure that holds these elements in adjacent memory locations so that they may be retrieved using numeric indices.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A ranking or setting forth in order, by the proper officer, of a jury as impanelled in a cause; the panel itself; or the whole body of jurors summoned to attend the court.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A militia.", synonyms: [], antonyms: [] },
            { definition: "A group of hedgehogs.", synonyms: [], antonyms: [] },
            { definition: "A microarray.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: ["disarray"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To clothe and ornament; to adorn or attire.",
              synonyms: [],
              antonyms: [],
              example: "He was arrayed in his finest robes and jewels.",
            },
            {
              definition:
                "To lay out in an orderly arrangement; to deploy or marshal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To set in order, as a jury, for the trial of a cause; that is, to call them one at a time.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["don", "dress", "put on"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/array"],
    },
  ],
  harsh: [
    {
      word: "harsh",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/harsh-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241255",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/hɑːʃ/", audio: "" },
        { text: "/hɑɹʃ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To negatively criticize.",
              synonyms: [],
              antonyms: [],
              example: "Quit harshing me already, I said that I was sorry!",
            },
            {
              definition: "To put a damper on (a mood).",
              synonyms: [],
              antonyms: [],
              example: "Dude, you're harshing my buzz.",
            },
          ],
          synonyms: ["rough"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Unpleasantly rough to the touch or other senses.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Severe or cruel.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: ["genteel"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/harsh"],
    },
  ],
  camel: [
    {
      word: "camel",
      phonetic: "/ˈkæməl/",
      phonetics: [
        {
          text: "/ˈkæməl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/camel-1-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=7971991",
        },
        {
          text: "/ˈkæməl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/camel-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762629",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A beast of burden, much used in desert areas, of the genus Camelus.",
              synonyms: ["oont"],
              antonyms: [],
            },
            {
              definition:
                "A light brownish color, like that of a camel (also called camel brown).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Loaded vessels lashed tightly, one on each side of another vessel, and then emptied to reduce the draught of the ship in the middle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["oont"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of a light brown color like that of a camel.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/camel"],
    },
    {
      word: "camel",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A ruminant, of the genus Giraffa, of the African savannah with long legs and highly elongated neck, which make it the tallest living animal; yellow fur patterned with dark spots, often in the form of a network; and two or more short, skin-covered horns, so-called; strictly speaking the horn-like projections are ossicones.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A giraffe unicycle.", synonyms: [], antonyms: [] },
            {
              definition: "A laugh.",
              synonyms: [],
              antonyms: [],
              example: "Are you having a giraffe?!",
            },
          ],
          synonyms: ["bubble", "bubble bath", "camelopard"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/camel",
        "https://en.wiktionary.org/wiki/giraffe",
      ],
    },
  ],
  vines: [
    {
      word: "vines",
      phonetic: "/vaɪnz/",
      phonetics: [
        {
          text: "/vaɪnz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/vines-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90914496",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The climbing plant that produces grapes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any plant of the genus Vitis.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) Any similar climbing or trailing plant.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["climber", "grapevine"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/vine",
        "https://en.wiktionary.org/wiki/vines",
      ],
    },
  ],
  picks: [
    {
      word: "picks",
      phonetic: "/pɪks/",
      phonetics: [{ text: "/pɪks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A tool used for digging; a pickaxe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A tool for unlocking a lock without the original key; a lock pick, picklock.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A comb with long widely spaced teeth, for use with tightly curled hair.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A choice; ability to choose.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That which would be picked or chosen first; the best.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A screen.", synonyms: [], antonyms: [] },
            {
              definition:
                "An offensive tactic in which a player stands so as to block a defender from reaching a teammate.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An interception.", synonyms: [], antonyms: [] },
            {
              definition: "A good defensive play by an infielder.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A pickoff.", synonyms: [], antonyms: [] },
            {
              definition:
                "A tool used for strumming the strings of a guitar; a plectrum.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A pointed hammer used for dressing millstones.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A pike or spike; the sharp point fixed in the center of a buckler.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A particle of ink or paper embedded in the hollow of a letter, filling up its face, and causing a spot on a printed sheet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That which is picked in, as with a pointed pencil, to correct an unevenness in a picture.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The blow that drives the shuttle, used in calculating the speed of a loom (in picks per minute); hence, in describing the fineness of a fabric, a weft thread.",
              synonyms: [],
              antonyms: [],
              example: "so many picks to an inch",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To grasp and pull with the fingers or fingernails.",
              synonyms: [],
              antonyms: [],
              example: "Don't pick at that scab.",
            },
            {
              definition:
                "To harvest a fruit or vegetable for consumption by removing it from the plant to which it is attached; to harvest an entire plant by removing it from the ground.",
              synonyms: [],
              antonyms: [],
              example: "It's time to pick the tomatoes.",
            },
            {
              definition:
                "To pull apart or away, especially with the fingers; to pluck.",
              synonyms: [],
              antonyms: [],
              example: "She picked flowers in the meadow.",
            },
            {
              definition:
                "To take up; especially, to gather from here and there; to collect; to bring together.",
              synonyms: [],
              antonyms: [],
              example: "to pick rags",
            },
            {
              definition:
                "To remove something from somewhere with a pointed instrument, with the fingers, or with the teeth.",
              synonyms: [],
              antonyms: [],
              example:
                "to pick the teeth; to pick a bone; to pick a goose; to pick a pocket",
            },
            {
              definition: "To decide upon, from a set of options; to select.",
              synonyms: [],
              antonyms: [],
              example: "I'll pick the one with the nicest name.",
            },
            {
              definition:
                "To seek (a fight or quarrel) where the opportunity arises.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To recognise the type of ball being bowled by a bowler by studying the position of the hand and arm as the ball is released.",
              synonyms: [],
              antonyms: [],
              example: "He didn't pick the googly, and was bowled.",
            },
            {
              definition:
                "To pluck the individual strings of a musical instrument or to play such an instrument.",
              synonyms: [],
              antonyms: [],
              example: "He picked a tune on his banjo.",
            },
            {
              definition: "To open (a lock) with a wire, lock pick, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To eat slowly, sparingly, or by morsels; to nibble.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To do anything fastidiously or carefully, or by attending to small things; to select something with care.",
              synonyms: [],
              antonyms: [],
              example: "I gingerly picked my way between the thorny shrubs.",
            },
            { definition: "To steal; to pilfer.", synonyms: [], antonyms: [] },
            { definition: "To throw; to pitch.", synonyms: [], antonyms: [] },
            {
              definition:
                "To peck at, as a bird with its beak; to strike at with anything pointed; to act upon with a pointed instrument; to pierce; to prick, as with a pin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To separate or open by means of a sharp point or points.",
              synonyms: [],
              antonyms: [],
              example: "to pick matted wool, cotton, oakum, etc.",
            },
            { definition: "To screen.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pick",
        "https://en.wiktionary.org/wiki/picks",
      ],
    },
  ],
  naval: [
    {
      word: "naval",
      phonetic: "/ˈneɪvəl/",
      phonetics: [
        {
          text: "/ˈneɪvəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/naval-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50348390",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of or relating to a navy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of or relating to ships in general.",
              synonyms: [],
              antonyms: [],
              example: "naval architect",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/naval"],
    },
  ],
  purse: [
    {
      word: "purse",
      phonetic: "/pɜːs/",
      phonetics: [
        { text: "/pɜːs/", audio: "" },
        {
          text: "/pɝs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/purse-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2423303",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A small bag for carrying money.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A handbag (small bag usually used by women for carrying various small personal items)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A quantity of money given for a particular purpose.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A specific sum of money in certain countries: formerly 500 piastres in Turkey or 50 tomans in Persia.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "bursary",
            "grant",
            "change purse",
            "coin purse",
            "pocketbook",
            "handbag",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To press (one's lips) in and together so that they protrude.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To draw up or contract into folds or wrinkles; to pucker; to knit.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To put into a purse.", synonyms: [], antonyms: [] },
            {
              definition: "To steal purses; to rob.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["pucker"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/purse"],
    },
  ],
  rigid: [
    {
      word: "rigid",
      phonetic: "/ˈɹɪdʒɪd/",
      phonetics: [
        {
          text: "/ˈɹɪdʒɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rigid-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769504",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A bicycle with no suspension system.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Stiff, rather than flexible.",
              synonyms: ["inflexible"],
              antonyms: ["flexible"],
            },
            {
              definition: "Fixed, rather than moving.",
              synonyms: [],
              antonyms: ["moving"],
            },
            {
              definition: "Rigorous and unbending.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Uncompromising.",
              synonyms: [],
              antonyms: ["compromising"],
            },
          ],
          synonyms: ["inflexible"],
          antonyms: ["compromising", "flexible", "moving"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rigid"],
    },
  ],
  crawl: [
    {
      word: "crawl",
      phonetic: "/kɹɔl/",
      phonetics: [
        { text: "/kɹɔl/", audio: "" },
        {
          text: "/kɹɔl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/crawl-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780006",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The act of moving slowly on hands and knees etc, or with frequent stops.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A rapid swimming stroke with alternate overarm strokes and a fluttering kick.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A very slow pace.",
              synonyms: [],
              antonyms: [],
              example:
                "My computer has slowed down to a crawl since I installed that software package.",
            },
            {
              definition:
                "A piece of horizontally or vertically scrolling text overlaid on the main image.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To creep; to move slowly on hands and knees, or by dragging the body along the ground.",
              synonyms: [],
              antonyms: [],
              example:
                "Clutching my wounded side, I crawled back to the trench.",
            },
            {
              definition: "To move forward slowly, with frequent stops.",
              synonyms: [],
              antonyms: [],
              example: "The rush-hour traffic crawled around the bypass.",
            },
            {
              definition: "To act in a servile manner.",
              synonyms: [],
              antonyms: [],
              example: "Don't come crawling to me with your useless apologies!",
            },
            {
              definition: '(with "with") See crawl with.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To feel a swarming sensation.",
              synonyms: [],
              antonyms: [],
              example: "The horrible sight made my skin crawl.",
            },
            {
              definition: "To swim using the crawl stroke.",
              synonyms: [],
              antonyms: [],
              example: "I think I'll crawl the next hundred metres.",
            },
            {
              definition: "To move over an area on hands and knees.",
              synonyms: [],
              antonyms: [],
              example: "The baby crawled the entire second floor.",
            },
            {
              definition: "To visit while becoming inebriated.",
              synonyms: [],
              antonyms: [],
              example: "They crawled the downtown bars.",
            },
            {
              definition:
                "To visit files or web sites in order to index them for searching.",
              synonyms: [],
              antonyms: [],
              example:
                "Yahoo Search has updated its Slurp Crawler to crawl web sites faster and more efficiently.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/crawl"],
    },
    {
      word: "crawl",
      phonetic: "/kɹɔl/",
      phonetics: [
        { text: "/kɹɔl/", audio: "" },
        {
          text: "/kɹɔl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/crawl-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780006",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A pen or enclosure of stakes and hurdles for holding fish.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/crawl"],
    },
  ],
  toast: [
    {
      word: "toast",
      phonetic: "/təʊst/",
      phonetics: [
        { text: "/təʊst/", audio: "" },
        {
          text: "/toʊst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/toast-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2454345",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Toasted bread.",
              synonyms: [],
              antonyms: [],
              example: "I ate a piece of toast for breakfast.",
            },
            {
              definition:
                'A proposed salutation (e.g. to say "cheers") while drinking alcohol.',
              synonyms: [],
              antonyms: [],
              example:
                "At the reception, there were many toasts from the well-wishers.",
            },
            {
              definition:
                "A person, group, or notable object to which a salutation with alcohol is made; a person or group held in similar esteem.",
              synonyms: [],
              antonyms: [],
              example: "He was the toast of high society.",
            },
            {
              definition:
                "(chiefly US) Something that will be no more; something subject to impending destruction, harm or injury.",
              synonyms: [],
              antonyms: [],
              example:
                "If I ever get my hands on the guy that stole my wallet, he’s toast!",
            },
            {
              definition: "Extemporaneous narrative poem or rap.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A transient, informational pop-up window.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/toast"],
    },
    {
      word: "toast",
      phonetic: "/təʊst/",
      phonetics: [
        { text: "/təʊst/", audio: "" },
        {
          text: "/toʊst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/toast-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2454345",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To lightly cook by browning via direct exposure to a fire or other heat source.",
              synonyms: [],
              antonyms: [],
              example: "We liked to toast marshmallows around the campfire.",
            },
            {
              definition:
                "To grill, lightly cook by browning specifically under a grill or in a toaster",
              synonyms: [],
              antonyms: [],
              example:
                "Top with cheese and toast under the grill for a few minutes.",
            },
            {
              definition:
                "To engage in a salutation and/or accompanying raising of glasses while drinking alcohol in honor of someone or something.",
              synonyms: [],
              antonyms: [],
              example:
                "We toasted the happy couple many times over the course of the evening.",
            },
            {
              definition: "To warm thoroughly.",
              synonyms: [],
              antonyms: [],
              example: "I toasted my feet by the fire.",
            },
            {
              definition: "To perform extemporaneous narrative poem or rap.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/toast"],
    },
  ],
  soils: [
    {
      word: "soils",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A mixture of mineral particles and organic material, used to support plant growth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The unconsolidated mineral or organic material on the immediate surface of the earth that serves as a natural medium for the growth of land plants.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The unconsolidated mineral or organic matter on the surface of the earth that has been subjected to and shows effects of genetic and environmental factors of: climate (including water and temperature effects), and macro- and microorganisms, conditioned by relief, acting on parent material over a period of time. A product-soil differs from the material from which it is derived in many physical, chemical, biological, and morphological properties and characteristics.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Country or territory.",
              synonyms: [],
              antonyms: [],
              example: "Kenyan soil",
            },
            {
              definition: "That which soils or pollutes; a stain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A marshy or miry place to which a hunted boar resorts for refuge; hence, a wet place, stream, or tract of water, sought for by other game, as deer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Dung; compost; manure.",
              synonyms: [],
              antonyms: [],
              example: "night soil",
            },
          ],
          synonyms: ["dirt", "earth"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Faeces or urine etc. when found on clothes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A bag containing soiled items.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dirt"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To make dirty.", synonyms: [], antonyms: [] },
            {
              definition: "To become dirty or soiled.",
              synonyms: [],
              antonyms: [],
              example: "Light colours soil sooner than dark ones.",
            },
            {
              definition:
                "To stain or mar, as with infamy or disgrace; to tarnish; to sully.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To dirty one's clothing by accidentally defecating while clothed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make invalid, to ruin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To enrich with soil or muck; to manure.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["besmirch", "dirty", "smirch"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A wet or marshy place in which a boar or other such game seeks refuge when hunted.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To feed, as cattle or horses, in the barn or an enclosure, with fresh grass or green food cut for them, instead of sending them out to pasture; hence (such food having the effect of purging them), to purge by feeding on green food.",
              synonyms: [],
              antonyms: [],
              example: "to soil a horse",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/soil",
        "https://en.wiktionary.org/wiki/soils",
      ],
    },
  ],
  sauce: [
    {
      word: "sauce",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sauce-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75649581",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/sɔːs/", audio: "" },
        {
          text: "/sɑs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sauce-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769510",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A liquid (often thickened) condiment or accompaniment to food.",
              synonyms: [],
              antonyms: [],
              example: "apple sauce; mint sauce",
            },
            {
              definition: "Tomato sauce (similar to US tomato ketchup), as in:",
              synonyms: [],
              antonyms: [],
              example: "[meat] pie and [tomato] sauce",
            },
            {
              definition: "(usually “the”) Alcohol, booze.",
              synonyms: [],
              antonyms: [],
              example: "Maybe you should lay off the sauce.",
            },
            { definition: "Anabolic steroids.", synonyms: [], antonyms: [] },
            {
              definition:
                "A soft crayon for use in stump drawing or in shading with the stump.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Cheek; impertinence; backtalk; sass.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "(1800s) Vegetables.", synonyms: [], antonyms: [] },
            {
              definition: "Any garden vegetables eaten with meat.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["sowl"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To add sauce to; to season.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause to relish anything, as if with a sauce; to tickle or gratify, as the palate; to please; to stimulate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make poignant; to give zest, flavour or interest to; to set off; to vary and render attractive.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To treat with bitter, pert, or tart language; to be impudent or saucy to.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The person, place or thing from which something (information, goods, etc.) comes or is acquired.",
              synonyms: [],
              antonyms: [],
              example:
                "The accused refused to reveal the source of the illegal drugs she was selling.",
            },
            {
              definition:
                "Spring; fountainhead; wellhead; any collection of water on or under the surface of the ground in which a stream originates.",
              synonyms: [],
              antonyms: [],
              example:
                "The main sources of the Euphrates River are the Karasu and Murat Rivers.",
            },
            {
              definition: "A reporter's informant.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Source code.", synonyms: [], antonyms: [] },
            {
              definition:
                "The name of one terminal of a field effect transistor (FET).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["wellspring"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sauce",
        "https://en.wiktionary.org/wiki/source",
      ],
    },
  ],
  basin: [
    {
      word: "basin",
      phonetic: "/ˈbeɪsɪn/",
      phonetics: [
        { text: "/ˈbeɪsɪn/", audio: "" },
        {
          text: "/ˈbeɪsɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/basin-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=837165",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A wide bowl for washing, sometimes affixed to a wall",
              synonyms: ["sink"],
              antonyms: [],
            },
            {
              definition:
                "A shallow bowl used for a single serving of a drink or liquidy food",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A depression, natural or artificial, containing water",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An area of land from which water drains into a common outlet; drainage basin",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A rock formation scooped out by water erosion",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["sink"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To create a concavity or depression in.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To serve as or become a basin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To shelter or enclose in a basin.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/basin"],
    },
  ],
  ponds: [
    {
      word: "ponds",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An inland body of standing water, either natural or man-made, that is smaller than a lake.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An inland body of standing water of any size that is fed by springs rather than by a river.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The Atlantic Ocean. Especially in across the pond.",
              synonyms: [],
              antonyms: [],
              example:
                "I haven't been back home across the pond in twenty years.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pond",
        "https://en.wiktionary.org/wiki/ponds",
      ],
    },
  ],
  twins: [
    {
      word: "twins",
      phonetic: "/twɪnz/",
      phonetics: [
        {
          text: "/twɪnz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/twins-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79293929",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Either of two people (or, less commonly, animals) who shared the same uterus at the same time; one who was born at the same birth as a sibling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Either of two similar or closely related objects, entities etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A room in a hotel, guesthouse, etc. with two beds; a twin room.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A twin size mattress or a bed designed for such a mattress.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A twin crystal.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["doublet", "twindle", "twinling"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(obsolete outside Scotland) To separate, divide.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(obsolete outside Scotland) To split, part; to go away, depart.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(usually in the passive) To join, unite; to form links between (now especially of two places in different countries).",
              synonyms: [],
              antonyms: [],
              example:
                "Coventry twinned with Dresden as an act of peace and reconciliation, both cities having been heavily bombed during the war.",
            },
            {
              definition: "To be paired or suited.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To give birth to twins.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be born at the same birth.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A woman's breasts", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/twin",
        "https://en.wiktionary.org/wiki/twins",
      ],
    },
  ],
  wrist: [
    {
      word: "wrist",
      phonetic: "/ɹɪst/",
      phonetics: [
        {
          text: "/ɹɪst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wrist-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780503",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The complex joint between forearm bones, carpus, and metacarpals where the hand is attached to the arm; the carpus in a narrow sense.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A stud or pin which forms a journal.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To hit a wrist shot", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/wrist"],
    },
  ],
  fluid: [
    {
      word: "fluid",
      phonetic: "/ˈfluːɪd/",
      phonetics: [
        {
          text: "/ˈfluːɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fluid-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50330725",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any substance which can flow with relative ease, tends to assume the shape of its container, and obeys Bernoulli's principle; a liquid, gas or plasma.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A liquid (as opposed to a solid or gas).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(specifically, typically in the plural) Intravenous fluids.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of or relating to fluid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "In a state of flux; subject to change.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Moving smoothly, or giving the impression of a liquid in motion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of an asset) Convertible into cash.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Genderfluid.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "fluent",
            "fluxive",
            "fluidical",
            "liquid",
            "unstable",
            "variable",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fluid"],
    },
  ],
  pools: [
    {
      word: "pools",
      phonetic: "/puːlz/",
      phonetics: [
        {
          text: "/puːlz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pools-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=91208367",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small and rather deep collection of (usually) fresh water, as one supplied by a spring, or occurring in the course of a stream; a reservoir for water.",
              synonyms: [],
              antonyms: [],
              example: "the pools of Solomon",
            },
            {
              definition:
                "A small body of standing or stagnant water; a puddle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A supply of resources.",
              synonyms: [],
              antonyms: [],
              example:
                "There is a limited pool of candidates from which to choose the new manager.",
            },
            {
              definition:
                "(by extension) A set of resources that are kept ready to use.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A small amount of liquid on a surface.",
              synonyms: [],
              antonyms: [],
              example: "a pool of blood",
            },
            {
              definition: "A localized glow of light.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(of a liquid) To form a pool.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(game) A game at billiards, in which each of the players stakes a certain sum, the winner taking the whole; also, in public billiard rooms, a game in which the loser pays the entrance fee for all who engage in the game.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cue sport played on a pool table. There are 15 balls, 7 of one colour, 7 of another, and the black ball (also called the 8 ball). A player must pocket all their own colour balls and then the black ball in order to win.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In rifle shooting, a contest in which each competitor pays a certain sum for every shot he makes, the net proceeds being divided among the winners.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any gambling or commercial venture in which several persons join.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The stake played for in certain games of cards, billiards, etc.; an aggregated stake to which each player has contributed a share; also, the receptacle for the stakes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A combination of persons contributing money to be used for the purpose of increasing or depressing the market price of stocks, grain, or other commodities; also, the aggregate of the sums so contributed.",
              synonyms: [],
              antonyms: [],
              example: "He put $10,000 into the pool.",
            },
            {
              definition: "A set of players in quadrille etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A mutual arrangement between competing lines, by which the receipts of all are aggregated, and then distributed pro rata according to agreement.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An aggregation of properties or rights, belonging to different people in a community, in a common fund, to be charged with common liabilities.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To put together; contribute to a common fund, on the basis of a mutual division of profits or losses; to make a common interest of.",
              synonyms: [],
              antonyms: [],
              example: "We must pool our resources.",
            },
            {
              definition:
                "To combine or contribute with others, as for a commercial, speculative, or gambling transaction.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A pool of water used for swimming, usually one which has been artificially constructed.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pool",
        "https://en.wiktionary.org/wiki/pools",
        "https://en.wiktionary.org/wiki/swimming%20pool",
      ],
    },
  ],
  brand: [
    {
      word: "brand",
      phonetic: "/bɹand/",
      phonetics: [
        {
          text: "/bɹand/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/brand-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769621",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A conflagration; a flame.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of burning wood or peat, or a glowing cinder.",
              synonyms: [],
              antonyms: [],
              example: "To burn something to brands and ashes.",
            },
            {
              definition: "A torch used for signaling.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A sword.", synonyms: [], antonyms: [] },
            {
              definition:
                "A mark or scar made by burning with a hot iron, especially to mark cattle or to classify the contents of a cask.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A branding iron.", synonyms: [], antonyms: [] },
            {
              definition:
                "The symbolic identity, represented by a name and/or a logo, which indicates a certain product or service to the public.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A specific product, service, or provider so distinguished.",
              synonyms: [],
              antonyms: [],
              example:
                "Some brands of breakfast cereal contain a lot of sugar.",
            },
            {
              definition:
                "(by extension) Any specific type or variety of something; a distinct style or manner.",
              synonyms: [],
              antonyms: [],
              example: "I didn't appreciate his particular brand of flattery.",
            },
            {
              definition:
                "The public image or reputation and recognized, typical style of an individual or group.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A mark of infamy; stigma.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any minute fungus producing a burnt appearance in plants.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "brand name",
            "logo",
            "marque",
            "proprietary name",
            "trademark",
            "tradename",
            "good name",
            "name",
            "repute",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To burn the flesh with a hot iron, either as a marker (for criminals, slaves etc.) or to cauterise a wound.",
              synonyms: [],
              antonyms: [],
              example:
                "When they caught him, he was branded and then locked up.",
            },
            {
              definition:
                "To mark (especially cattle) with a brand as proof of ownership.",
              synonyms: [],
              antonyms: [],
              example:
                "The ranch hands had to brand every new calf by lunchtime.",
            },
            {
              definition:
                "To make an indelible impression on the memory or senses.",
              synonyms: [],
              antonyms: [],
              example: "Her face is branded upon my memory.",
            },
            {
              definition: "To stigmatize, label (someone).",
              synonyms: [],
              antonyms: [],
              example:
                "He was branded a fool by everyone that heard his story.",
            },
            {
              definition:
                "To associate a product or service with a trademark or other name and related images.",
              synonyms: [],
              antonyms: [],
              example:
                'They branded the new detergent "Suds-O", with a nature scene inside a green O on the muted-colored recycled-cardboard box.',
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/brand"],
    },
  ],
  stalk: [
    {
      word: "stalk",
      phonetic: "/stɔːk/",
      phonetics: [
        { text: "/stɔːk/", audio: "" },
        {
          text: "/stɔk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stalk-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=22249688",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The stem or main axis of a plant, which supports the seed-carrying parts.",
              synonyms: [],
              antonyms: [],
              example:
                "a stalk of wheat, rye, or oats;  the stalks of maize or hemp",
            },
            {
              definition: "The petiole, pedicel, or peduncle of a plant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something resembling the stalk of a plant, such as the stem of a quill.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An ornament in the Corinthian capital resembling the stalk of a plant, from which the volutes and helices spring.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One of the two upright pieces of a ladder.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A stem or peduncle, as in certain barnacles and crinoids.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The narrow basal portion of the abdomen of a hymenopterous insect.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The peduncle of the eyes of decapod crustaceans.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An iron bar with projections inserted in a core to strengthen it; a core arbor.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stalk"],
    },
    {
      word: "stalk",
      phonetic: "/stɔːk/",
      phonetics: [
        { text: "/stɔːk/", audio: "" },
        {
          text: "/stɔk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stalk-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=22249688",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A particular episode of trying to follow or contact someone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The hunting of a wild animal by stealthy approach.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To approach slowly and quietly in order not to be discovered when getting closer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To (try to) follow or contact someone constantly, often resulting in harassment.Wp",
              synonyms: [],
              antonyms: [],
              example: "My ex-girlfriend is stalking me.",
            },
            {
              definition:
                "To walk slowly and cautiously; to walk in a stealthy, noiseless manner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To walk behind something, such as a screen, for the purpose of approaching game; to proceed under cover.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stalk"],
    },
    {
      word: "stalk",
      phonetic: "/stɔːk/",
      phonetics: [
        { text: "/stɔːk/", audio: "" },
        {
          text: "/stɔk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stalk-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=22249688",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A haughty style of walking.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To walk haughtily.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stalk"],
    },
  ],
  robot: [
    {
      word: "robot",
      phonetic: "/ˈɹoʊbət/",
      phonetics: [
        {
          text: "/ˈɹoʊbət/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/robot-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75857943",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈɹəʊbɒt/", audio: "" },
        {
          text: "/ˈɹoʊbɑt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/robot-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1328998",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A machine built to carry out some complex task or group of tasks by physically moving, especially one which can be programmed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An intelligent mechanical being designed to look like a human or other creature, and usually made from metal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person who does not seem to have any emotions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A traffic light (from earlier robot policeman).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A theodolite which follows the movements of a prism and can be used by a one-man crew.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A style of dance popular in disco in which the dancer imitates the stiff movements of a stereotypical android robot.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/robot"],
    },
  ],
  reeds: [
    {
      word: "reeds",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of various types of tall stiff perennial grass-like plants growing together in groups near water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The hollow stem of these plants.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Part of the mouthpiece of certain woodwind instruments, comprising a thin piece of wood or metal which shakes very quickly to produce sound when a musician blows over it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A musical instrument such as the clarinet or oboe, which produces sound when a musician blows on the reed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A comb-like part of a beater for beating the weft when weaving.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of whalebone or similar for stiffening the skirt or waist of a woman's dress.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Reeding.", synonyms: [], antonyms: [] },
            {
              definition:
                "A tube containing the train of powder for igniting the charge in blasting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Straw prepared for thatching a roof.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A missile weapon.", synonyms: [], antonyms: [] },
            { definition: "A measuring rod.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To thatch.", synonyms: [], antonyms: [] },
            {
              definition: "To mill or mint with reeding.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The fourth stomach of a ruminant; rennet.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/reed",
        "https://en.wiktionary.org/wiki/reeds",
      ],
    },
  ],
  hoofs: [
    {
      word: "hoofs",
      phonetic: "/hufs/",
      phonetics: [{ text: "/hufs/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The tip of a toe of an ungulate such as a horse, ox or deer, strengthened by a thick keratin covering.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The human foot.",
              synonyms: [],
              antonyms: [],
              example: "Get your hooves off me!",
            },
            { definition: "An ungula.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To trample with hooves.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To walk.", synonyms: [], antonyms: [] },
            {
              definition: "To dance, especially as a professional.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(football (soccer)) To kick, especially to kick a football a long way downfield with little accuracy.",
              synonyms: ["boot"],
              antonyms: [],
            },
          ],
          synonyms: ["boot"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hoof",
        "https://en.wiktionary.org/wiki/hoofs",
      ],
    },
  ],
  buses: [
    {
      word: "buses",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A motor vehicle for transporting large numbers of people along roads.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An electrical conductor or interface serving as a common connection for two or more circuits or components.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(medical industry) An ambulance.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "busbar",
            "digit trunk",
            "electrical bus",
            "autobus",
            "coach",
            "loser cruiser",
            "motorbus",
            "multibus",
            "omnibus",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To transport via a motor bus.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To transport students to school, often to a more distant school for the purposes of achieving racial integration.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To travel by bus.", synonyms: [], antonyms: [] },
            {
              definition: "(food service) To clear meal remains from.",
              synonyms: [],
              antonyms: [],
              example: "He bussed tables as the restaurant emptied out.",
            },
            {
              definition:
                "(food service) To work at clearing the remains of meals from tables or counters; to work as a busboy.",
              synonyms: [],
              antonyms: [],
              example: "He’s been bussing for minimum wage.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bus",
        "https://en.wiktionary.org/wiki/buses",
      ],
    },
  ],
  sheer: [
    {
      word: "sheer",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sheer-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75857906",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈʃɪə/", audio: "" },
        {
          text: "/ʃɪɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sheer-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=667206",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A sheer curtain or fabric.",
              synonyms: [],
              antonyms: [],
              example: "Use sheers to maximize natural light.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Very thin or transparent.",
              synonyms: [],
              antonyms: [],
              example: "Her light, sheer dress caught everyone’s attention.",
            },
            {
              definition: "Pure in composition; unmixed; unadulterated.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) Downright; complete; pure.",
              synonyms: [],
              antonyms: [],
              example: "I think it is sheer genius to invent such a thing.",
            },
            {
              definition:
                "Used to emphasize the amount or degree of something.",
              synonyms: [],
              antonyms: [],
              example: "The army's sheer size made it impossible to resist.",
            },
            {
              definition: "Very steep; almost vertical or perpendicular.",
              synonyms: [],
              antonyms: [],
              example: "It was a sheer drop of 180 feet.",
            },
          ],
          synonyms: [
            "downright",
            "mere",
            "pure",
            "unmitigated",
            "pure",
            "undiluted",
            "perpendicular",
            "steep",
            "vertical",
            "diaphanous",
            "see-through",
            "thin",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Clean; quite; at once.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sheer"],
    },
    {
      word: "sheer",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sheer-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75857906",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈʃɪə/", audio: "" },
        {
          text: "/ʃɪɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sheer-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=667206",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The curve of the main deck or gunwale from bow to stern.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An abrupt swerve from the course of a ship.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To swerve from a course.",
              synonyms: [],
              antonyms: [],
              example: "A horse sheers at a bicycle.",
            },
            { definition: "To shear.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sheer"],
    },
  ],
  grief: [
    {
      word: "grief",
      phonetic: "/ɡɹiːf/",
      phonetics: [
        {
          text: "/ɡɹiːf/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grief-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88517671",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ɡɹiːf/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grief-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1669315",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Suffering, hardship.", synonyms: [], antonyms: [] },
            {
              definition:
                "Pain of mind arising from misfortune, significant personal loss, bereavement, misconduct of oneself or others, etc.; sorrow; sadness.",
              synonyms: [],
              antonyms: [],
              example: "She was worn out from so much grief.",
            },
            {
              definition:
                "Cause or instance of sorrow or pain; that which afflicts or distresses; trial.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To deliberately harass and annoy or cause grief to other players of a game in order to interfere with their enjoyment of it; especially, to do this as one’s primary activity in the game.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/grief"],
    },
  ],
  bloom: [
    {
      word: "bloom",
      phonetic: "/bluːm/",
      phonetics: [
        {
          text: "/bluːm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bloom-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453870",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A blossom; the flower of a plant; an expanded bud.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Flowers, collectively.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The opening of flowers in general; the state of blossoming or of having the flowers open.",
              synonyms: [],
              antonyms: [],
              example: "The cherry trees are in bloom.",
            },
            {
              definition:
                "A state or time of beauty, freshness, and vigor; an opening to higher perfection, analogous to that of buds into blossoms.",
              synonyms: [],
              antonyms: [],
              example: "the bloom of youth",
            },
            {
              definition: "Rosy colour; the flush or glow on a person's cheek.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The delicate, powdery coating upon certain growing or newly-gathered fruits or leaves, as on grapes, plums, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Anything giving an appearance of attractive freshness.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The clouded appearance which varnish sometimes takes upon the surface of a picture.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A yellowish deposit or powdery coating which appears on well-tanned leather.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A bright-hued variety of some minerals.",
              synonyms: [],
              antonyms: [],
              example: "the rose-red cobalt bloom",
            },
            {
              definition:
                "A white area of cocoa butter that forms on the surface of chocolate when warmed and cooled.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An undesirable halo effect that may occur when a very bright region is displayed next to a very dark region of the screen.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["flush", "glow", "blossom", "flower", "blossom", "flower"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bloom"],
    },
    {
      word: "bloom",
      phonetic: "/bluːm/",
      phonetics: [
        {
          text: "/bluːm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bloom-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453870",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cause to blossom; to make flourish.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To bestow a bloom upon; to make blooming or radiant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of a plant, to produce blooms; to open its blooms.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of a person, business, etc, to flourish; to be in a state of healthful, growing youth and vigour; to show beauty and freshness.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Cooking) To bring out the flavor of a spice by cooking it in oil.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["blossom", "flourish", "thrive", "blossom", "flower"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bloom"],
    },
    {
      word: "bloom",
      phonetic: "/bluːm/",
      phonetics: [
        {
          text: "/bluːm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bloom-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453870",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The spongy mass of metal formed in a furnace by the smelting process.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bloom"],
    },
  ],
  dwelt: [
    {
      word: "dwelt",
      phonetic: "/ˈdwɛlt/",
      phonetics: [
        {
          text: "/ˈdwɛlt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dwelt-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=592025",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To live; to reside.", synonyms: [], antonyms: [] },
            {
              definition:
                "To linger (on) a particular thought, idea etc.; to remain fixated (on).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be in a given state.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To abide; to remain; to continue.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dwell",
        "https://en.wiktionary.org/wiki/dwelt",
      ],
    },
  ],
  melts: [
    {
      word: "melts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Molten material, the product of melting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The transition of matter from a solid state to a liquid state.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The springtime snow runoff in mountain regions.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A melt sandwich.", synonyms: [], antonyms: [] },
            {
              definition:
                "A wax-based substance for use in an oil burner as an alternative to mixing oils and water.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An idiot.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To change (or to be changed) from a solid state to a liquid state, usually by a gradual heat.",
              synonyms: [],
              antonyms: [],
              example: "I melted butter to make a cake.",
            },
            {
              definition: "To dissolve, disperse, vanish.",
              synonyms: [],
              antonyms: [],
              example: "His troubles melted away.",
            },
            {
              definition:
                "To soften, as by a warming or kindly influence; to relax; to render gentle or susceptible to mild influences; sometimes, in a bad sense, to take away the firmness of; to weaken.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To be discouraged.", synonyms: [], antonyms: [] },
            {
              definition: "To be emotionally softened or touched.",
              synonyms: [],
              antonyms: [],
              example:
                "She melted when she saw the romantic message in the Valentine's Day card.",
            },
            {
              definition: "To be very hot and sweat profusely.",
              synonyms: [],
              antonyms: [],
              example: "I need shade! I'm melting!",
            },
          ],
          synonyms: ["found", "thaw"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/melt",
        "https://en.wiktionary.org/wiki/melts",
      ],
    },
  ],
  risen: [
    {
      word: "risen",
      phonetic: "/ˈɹɪzən/",
      phonetics: [
        {
          text: "/ˈɹɪzən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/risen-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100521",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To move, or appear to move, physically upwards relative to the ground.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To increase in value or standing.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To begin; to develop.", synonyms: [], antonyms: [] },
            {
              definition: "To go up; to ascend; to climb.",
              synonyms: [],
              antonyms: [],
              example: "to rise a hill",
            },
            {
              definition: "To cause to go up or ascend.",
              synonyms: [],
              antonyms: [],
              example:
                "to rise a fish, or cause it to come to the surface of the water",
            },
            {
              definition: "To retire; to give up a siege.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To come; to offer itself.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be lifted, or capable of being lifted, from the imposing stone without dropping any of the type; said of a form.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "come back from the dead",
            "arise",
            "get up",
            "climb",
            "climb",
            "go up",
            "increase",
          ],
          antonyms: [
            "descend",
            "drop",
            "fall",
            "sink",
            "set",
            "decrease",
            "drop",
            "fall",
            "go down",
            "reduced",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rise",
        "https://en.wiktionary.org/wiki/risen",
      ],
    },
  ],
  flags: [
    {
      word: "flags",
      phonetic: "/flæɡz/",
      phonetics: [{ text: "/flæɡz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A piece of cloth, often decorated with an emblem, used as a visual signal or symbol.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An exact representation of a flag (for example: a digital one used in websites).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A flag flown by a ship to show the presence on board of the admiral; the admiral himself, or his flagship.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(often used attributively) A signal flag.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The use of a flag, especially to indicate the start of a race or other event.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A variable or memory location that stores a true-or-false, yes-or-no value, typically either recording the fact that a certain event has occurred or requesting that a certain optional action take place.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In a command line interface, a command parameter requesting optional behavior or otherwise modifying the action of the command being invoked.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A mechanical indicator that pops up to draw the pilot's attention to a problem or malfunction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The game of capture the flag.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sequence of faces of a given polytope, one of each dimension up to that of the polytope (formally, though in practice not always explicitly, including the null face and the polytope itself), such that each face in the sequence is part of the next-higher dimension face.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sequence of subspaces of a vector space, beginning with the null space and ending with the vector space itself, such that each member of the sequence (until the last) is a proper subspace of the next.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["option", "switch", "Boolean", "dart"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To furnish or deck out with flags.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To mark with a flag, especially to indicate the importance of something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(often with down) To signal to, especially to stop a passing vehicle etc.",
              synonyms: [],
              antonyms: [],
              example: "Please flag down a taxi for me.",
            },
            {
              definition: "To convey (a message) by means of flag signals.",
              synonyms: [],
              antonyms: [],
              example: "to flag an order to troops or vessels at a distance",
            },
            {
              definition:
                "(often with up) To note, mark or point out for attention.",
              synonyms: [],
              antonyms: [],
              example:
                "I've flagged up the need for further investigation into this.",
            },
            {
              definition: "To signal (an event).",
              synonyms: [],
              antonyms: [],
              example: "The compiler flagged three errors.",
            },
            {
              definition: "To set a program variable to true.",
              synonyms: [],
              antonyms: [],
              example: "Flag the debug option before running the program.",
            },
            {
              definition:
                "To decoy (game) by waving a flag, handkerchief, etc. to arouse the animal's curiosity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To penalize for an infraction.",
              synonyms: [],
              antonyms: [],
              example: "The defender was flagged for unsportsmanlike conduct.",
            },
            {
              definition:
                "To defeat (an opponent) on time, especially in a blitz game.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To weaken, become feeble.",
              synonyms: [],
              antonyms: [],
              example: "His strength flagged toward the end of the race.",
            },
            {
              definition:
                "To hang loose without stiffness; to bend down, as flexible bodies; to be loose, yielding, limp.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To let droop; to suffer to fall, or let fall, into feebleness.",
              synonyms: [],
              antonyms: [],
              example: "to flag the wings",
            },
            {
              definition:
                "To enervate; to exhaust the vigour or elasticity of.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of various plants with sword-shaped leaves, especially irises; specifically, Iris pseudacorus.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A slice of turf; a sod.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A slab of stone; a flagstone, a flat piece of stone used for paving.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any hard, evenly stratified sandstone, which splits into layers suitable for flagstones.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To pave with flagstones.",
              synonyms: [],
              antonyms: [],
              example: "Fred is planning to flag his patio this weekend.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A group of feathers on the lower part of the legs of certain hawks, owls, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A group of elongated wing feathers in certain hawks.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The bushy tail of a dog such as a setter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hook attached to the stem of a written note that assigns its rhythmic value",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/flag",
        "https://en.wiktionary.org/wiki/flags",
      ],
    },
  ],
  knelt: [
    {
      word: "knelt",
      phonetic: "/nɛlt/",
      phonetics: [
        {
          text: "/nɛlt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/knelt-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88681560",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To rest on one's bent knees, sometimes only one; to move to such a position.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause to kneel.",
              synonyms: [],
              antonyms: [],
              example: "She knelt the doll to fit it into the box.",
            },
            {
              definition: "To rest on (one's) knees",
              synonyms: [],
              antonyms: [],
              example: "He knelt him down to pray.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/kneel",
        "https://en.wiktionary.org/wiki/knelt",
      ],
    },
  ],
  fiber: [
    {
      word: "fiber",
      phonetic: "/ˈfaɪ.bə/",
      phonetics: [
        { text: "/ˈfaɪ.bə/", audio: "" },
        {
          text: "/ˈfaɪ.bɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fiber-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50247189",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A single elongated piece of a given material, roughly round in cross-section, often twisted with other fibers to form thread.",
              synonyms: [],
              antonyms: [],
              example:
                "The microscope showed a single blue fiber stuck to the sole of the shoe.",
            },
            {
              definition: "A material in the form of fibers.",
              synonyms: [],
              antonyms: [],
              example: "The cloth is made from strange, somewhat rough fiber.",
            },
            {
              definition:
                "A material whose length is at least 1000 times its width.",
              synonyms: [],
              antonyms: [],
              example: "Please use polyester fiber for this shirt.",
            },
            {
              definition: "Dietary fiber.",
              synonyms: [],
              antonyms: [],
              example: "Fresh vegetables are a good source of fiber",
            },
            {
              definition: "Moral strength and resolve.",
              synonyms: [],
              antonyms: [],
              example: "The ordeal was a test of everyone's fiber.",
            },
            {
              definition:
                "The preimage of a given point in the range of a map.",
              synonyms: [],
              antonyms: [],
              example:
                "Under this map, any two values in the fiber of a given point on the circle differ by 2π",
            },
            {
              definition:
                "Said to be of a morphism over a global element: The pullback of the said morphism along the said global element.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A kind of lightweight thread of execution.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fiber"],
    },
  ],
  roofs: [
    {
      word: "roofs",
      phonetic: "/ɹuːfs/",
      phonetics: [
        {
          text: "/ɹuːfs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/roofs-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651446",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The external covering at the top of a building.",
              synonyms: [],
              antonyms: [],
              example: "The roof was blown off by the tornado.",
            },
            {
              definition: "The top external level of a building.",
              synonyms: [],
              antonyms: [],
              example: "Let's go up to the roof.",
            },
            {
              definition: "The upper part of a cavity.",
              synonyms: [],
              antonyms: [],
              example: "The palate is the roof of the mouth.",
            },
            {
              definition:
                "The surface or bed of rock immediately overlying a bed of coal or a flat vein.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(rockclimbing) An overhanging rock wall",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["rooftop", "tect", "thatch", "ceiling"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cover or furnish with a roof.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To traverse buildings by walking or climbing across their roofs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To put into prison, to bird.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To shelter as if under a roof.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/roof",
        "https://en.wiktionary.org/wiki/roofs",
      ],
    },
  ],
  freed: [
    {
      word: "freed",
      phonetic: "/fɹiːd/",
      phonetics: [{ text: "/fɹiːd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make free; set at liberty; release.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To rid of something that confines or oppresses.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "befree",
            "emancipate",
            "let loose",
            "liberate",
            "manumit",
            "release",
            "unchain",
            "unfetter",
            "unshackle",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/free",
        "https://en.wiktionary.org/wiki/freed",
      ],
    },
  ],
  armor: [
    {
      word: "armor",
      phonetic: "/ˈɑː.mə/",
      phonetics: [
        { text: "/ˈɑː.mə/", audio: "" },
        {
          text: "/ˈɑːɹ.mɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/armor-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769837",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A protective layer over a body, vehicle, or other object intended to deflect or diffuse damaging forces.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A natural form of this kind of protection on an animal's body.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Metal plate, protecting a ship, military vehicle, or aircraft.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A tank, or other heavy mobile assault vehicle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A military formation consisting primarily of tanks or other armoured fighting vehicles, collectively.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The naturally occurring surface of pebbles, rocks or boulders that line the bed of a waterway or beach and provide protection against erosion.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "carapace",
            "chitin",
            "horn",
            "body armor",
            "body armour",
            "chain mail",
            "mail",
            "plate",
            "suit of armor",
            "suit of armour",
            "armor plate",
            "armour plate",
            "cavalry",
            "mechanized",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To equip something with armor or a protective coating or hardening.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To provide something with an analogous form of protection.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/armor"],
    },
  ],
  piles: [
    {
      word: "piles",
      phonetic: "/paɪlz/",
      phonetics: [{ text: "/paɪlz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A mass of things heaped together; a heap.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A group or list of related items up for consideration, especially in some kind of selection process.",
              synonyms: [],
              antonyms: [],
              example:
                'When we were looking for a new housemate, we put the nice woman on the "maybe" pile, and the annoying guy on the "no" pile',
            },
            {
              definition: "A mass formed in layers.",
              synonyms: [],
              antonyms: [],
              example: "a pile of shot",
            },
            {
              definition: "A funeral pile; a pyre.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A large amount of money.",
              synonyms: [],
              antonyms: [],
              example: "He made a pile from that invention of his.",
            },
            {
              definition: "A large building, or mass of buildings.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bundle of pieces of wrought iron to be worked over into bars or other shapes by rolling or hammering at a welding heat; a fagot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A vertical series of alternate disks of two dissimilar metals (especially copper and zinc), laid up with disks of cloth or paper moistened with acid water between them, for producing a current of electricity; a voltaic pile, or galvanic pile.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An atomic pile; an early form of nuclear reactor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The reverse (or tails) of a coin.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A list or league", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                '(often used with the preposition "up") To lay or throw into a pile or heap; to heap up; to collect into a mass; to accumulate',
              synonyms: [],
              antonyms: [],
              example: "They were piling up wood on the wheelbarrow.",
            },
            {
              definition:
                "To cover with heaps; or in great abundance; to fill or overfill; to load.",
              synonyms: [],
              antonyms: [],
              example: "We piled the camel with our loads.",
            },
            {
              definition: "To add something to a great number.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of vehicles) To create a hold-up.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To place (guns, muskets, etc.) together in threes so that they can stand upright, supporting each other.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["heap", "pile up"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A dart; an arrow.", synonyms: [], antonyms: [] },
            {
              definition: "The head of an arrow or spear.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A large stake, or piece of pointed timber, steel etc., driven into the earth or sea-bed for the support of a building, a pier, or other superstructure, or to form a cofferdam, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the ordinaries or subordinaries having the form of a wedge, usually placed palewise, with the broadest end uppermost.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To drive piles into; to fill with piles; to strengthen with piles.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(usually in the plural) A hemorrhoid.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Hair, especially when very fine or short; the fine underfur of certain animals. (Formerly countable, now treated as a collective singular.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The raised hairs, loops or strands of a fabric; the nap of a cloth.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To give a pile to; to make shaggy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(piles of) A large amount of.",
              synonyms: [],
              antonyms: [],
              example: "He must earn piles of money.",
            },
          ],
          synonyms: ["heap", "loads", "mountains", "shedloads", "tons"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pile",
        "https://en.wiktionary.org/wiki/piles",
      ],
    },
    {
      word: "piles",
      phonetic: "/paɪlz/",
      phonetics: [{ text: "/paɪlz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Haemorrhoids.",
              synonyms: [],
              antonyms: [],
              example: "Many women get piles when pregnant.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/piles"],
    },
  ],
  aimed: [
    {
      word: "aimed",
      phonetic: "/eɪmd/",
      phonetics: [
        {
          text: "/eɪmd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/aimed-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2650629",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To point or direct a missile, or a weapon which propels as missile, towards an object or spot with the intent of hitting it",
              synonyms: [],
              antonyms: [],
              example:
                "He aimed at the target, but the arrow flew straight over it.",
            },
            {
              definition:
                "To direct the intention or purpose; to attempt the accomplishment of a purpose; to try to gain; to endeavor;—followed by at, or by an infinitive",
              synonyms: [],
              antonyms: [],
              example: "to aim at a pass",
            },
            {
              definition:
                "To direct or point (e.g. a weapon), at a particular object; to direct, as a missile, an act, or a proceeding, at, to, or against an object",
              synonyms: [],
              antonyms: [],
              example: "She aimed a punch at her ex-boyfriend.",
            },
            {
              definition:
                "To direct (something verbal) towards a certain person, thing, or group",
              synonyms: [],
              antonyms: [],
              example: "to aim a satirical comment at Communists in general",
            },
            {
              definition: "To guess or conjecture.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Directed towards a target.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/aim",
        "https://en.wiktionary.org/wiki/aimed",
      ],
    },
  ],
  algae: [
    {
      word: "algae",
      phonetic: "/æl.ɡɪ/",
      phonetics: [
        { text: "/æl.ɡɪ/", audio: "" },
        { text: "/ˈæl.dʒi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of many aquatic photosynthetic organisms, including the seaweeds, whose size ranges from a single cell to giant kelps and whose biochemistry and forms are very diverse, some being eukaryotic.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Algal organisms viewed collectively or as a mass; algal growth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A particular kind of algae.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/alga",
        "https://en.wiktionary.org/wiki/algae",
      ],
    },
  ],
  twigs: [
    {
      word: "twigs",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/twigs-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651647",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A small thin branch of a tree or bush.",
              synonyms: [],
              antonyms: [],
              example:
                "They used twigs and leaves as a base to start the fire.",
            },
          ],
          synonyms: ["tillow"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To beat with twigs.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To realise something; to catch on; to recognize someone or something.",
              synonyms: [],
              antonyms: [],
              example:
                "He hasn't twigged that we're planning a surprise party for him.",
            },
            {
              definition:
                "To understand the meaning of (a person); to comprehend.",
              synonyms: [],
              antonyms: [],
              example: "Do you twig me?",
            },
            {
              definition: "To observe slyly; also, to perceive; to discover.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "check out",
            "peep",
            "spy on",
            "surveil",
            "clock",
            "get it",
            "notice",
            "fathom",
            "figure out",
            "grasp",
            "ken",
            "work out",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To twitch; to pull; to tweak.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/twig",
        "https://en.wiktionary.org/wiki/twigs",
      ],
    },
  ],
  lemon: [
    {
      word: "lemon",
      phonetic: "/ˈlɛmən/",
      phonetics: [
        {
          text: "/ˈlɛmən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lemon-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9023191",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A yellowish citrus fruit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A semitropical evergreen tree, Citrus limon, that bears such fruits.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A taste or flavour/flavor of lemons.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A more or less bright shade of yellow associated with lemon fruits.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A defective or inadequate item or individual.",
              synonyms: [],
              antonyms: [],
              example:
                "He didn’t realise until he’d paid for it that the car was a lemon.",
            },
            {
              definition: "(shortened from “lemon flavour”) Favor.",
              synonyms: [],
              antonyms: [],
              example:
                "A thousand quid for that motor? Do me a lemon, I could get it for half that.",
            },
            {
              definition:
                "A piece of fanfiction involving explicit sex (named after the erotic anime series Cream Lemon).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bomb", "PWP", "smutfic"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To flavour with lemon.",
              synonyms: [],
              antonyms: [],
              example:
                "You can start the vegetables cooking while you are lemoning the fish.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Containing or having the flavour/flavor and/or scent of lemons.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of the pale yellow colour/color of lemons.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: '(from "lemon tart") Smart; cheeky, vocal.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lemon"],
    },
  ],
  ditch: [
    {
      word: "ditch",
      phonetic: "/dɪtʃ/",
      phonetics: [
        {
          text: "/dɪtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ditch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=379140",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A trench; a long, shallow indentation, as for irrigation or drainage.",
              synonyms: [],
              antonyms: [],
              example:
                "Digging ditches has long been considered one of the most demanding forms of manual labor.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To discard or abandon.",
              synonyms: [],
              antonyms: [],
              example:
                "Once the sun came out we ditched our rain-gear and started a campfire.",
            },
            {
              definition: "To deliberately crash-land an airplane on water.",
              synonyms: [],
              antonyms: [],
              example:
                "When the second engine failed, the pilot was forced to ditch; their last location was just south of the Azores.",
            },
            {
              definition: "To deliberately not attend classes; to play hookey.",
              synonyms: [],
              antonyms: [],
              example:
                "The truant officer caught Louise ditching with her friends, and her parents were forced to pay a fine.",
            },
            {
              definition: "To dig ditches.",
              synonyms: [],
              antonyms: [],
              example:
                "Enclosure led to fuller winter employment in hedging and ditching.",
            },
            {
              definition: "To dig ditches around.",
              synonyms: [],
              antonyms: [],
              example: "The soldiers ditched the tent to prevent flooding.",
            },
            {
              definition: "To throw into a ditch.",
              synonyms: [],
              antonyms: [],
              example: "The engine was ditched and turned on its side.",
            },
          ],
          synonyms: ["abandon", "discard", "dump", "jettison", "lose", "shed"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ditch"],
    },
    {
      word: "ditch",
      phonetic: "/dɪtʃ/",
      phonetics: [
        {
          text: "/dɪtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ditch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=379140",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Dirt ingrained on the hands, or in cracks, crevices, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To smear, daub, plaster, or impregnate, especially with dirt which becomes hard and ingrained.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/deech",
        "https://en.wiktionary.org/wiki/ditch",
      ],
    },
  ],
  drunk: [
    {
      word: "drunk",
      phonetic: "/dɹʌŋk/",
      phonetics: [
        {
          text: "/dɹʌŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/drunk-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100378",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To consume (a liquid) through the mouth.",
              synonyms: [],
              antonyms: [],
              example: "He drank the water I gave him.",
            },
            {
              definition:
                "(metonymic) To consume the liquid contained within (a bottle, glass, etc.).",
              synonyms: [],
              antonyms: [],
              example: "Jack drank the whole bottle by himself.",
            },
            {
              definition: "To consume alcoholic beverages.",
              synonyms: [],
              antonyms: [],
              example:
                "Everyone who is drinking is drinking, but not everyone who is drinking is drinking.",
            },
            {
              definition:
                "To take in (a liquid), in any manner; to suck up; to absorb; to imbibe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To take in; to receive within one, through the senses; to inhale; to hear; to see.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To smoke, as tobacco.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "gulp",
            "imbibe",
            "quaff",
            "sip",
            "alcohol",
            "hit the sauce",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One who is intoxicated with alcohol.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A habitual drinker, especially one who is frequently intoxicated.",
              synonyms: [
                "alcoholic",
                "drunkard",
                "piss artist",
                "pisshead",
                "sot",
              ],
              antonyms: [],
            },
            {
              definition: "A drinking-bout; a period of drunkenness.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A drunken state.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["alcoholic", "drunkard", "piss artist", "pisshead", "sot"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Intoxicated as a result of excessive alcohol consumption, usually by drinking alcoholic beverages.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Habitually or frequently in a state of intoxication.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(usually followed by with or on) Elated or emboldened.",
              synonyms: [],
              antonyms: [],
              example:
                "Drunk with power he immediately ordered a management reshuffle.",
            },
            {
              definition: "Drenched or saturated with moisture or liquid.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["boozy", "sottish"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/drink",
        "https://en.wiktionary.org/wiki/drunk",
      ],
    },
  ],
  rests: [
    {
      word: "rests",
      phonetic: "/ɹɛsts/",
      phonetics: [{ text: "/ɹɛsts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(of a person or animal) Relief from work or activity by sleeping; sleep.",
              synonyms: ["sleep", "slumber"],
              antonyms: [],
              example:
                "I need to get a good rest tonight; I was up late last night.",
            },
            {
              definition:
                "Any relief from exertion; a state of quiet and relaxation.",
              synonyms: ["break", "repose", "time off"],
              antonyms: [],
              example:
                "We took a rest at the top of the hill to get our breath back.",
            },
            {
              definition:
                "Peace; freedom from worry, anxiety, annoyances; tranquility.",
              synonyms: [
                "peace",
                "quiet",
                "roo",
                "silence",
                "stillness",
                "tranquility",
              ],
              antonyms: [],
              example:
                "It was nice to have a rest from the phone ringing when I unplugged it for a while.",
            },
            {
              definition:
                "(of an object or concept) A state of inactivity; a state of little or no motion; a state of completion.",
              synonyms: [],
              antonyms: [],
              example:
                "Now that we're all in agreement, we can put that issue to rest.",
            },
            {
              definition: "A final position after death.",
              synonyms: ["peace"],
              antonyms: [],
              example: "She was laid to rest in the village cemetery.",
            },
            {
              definition: "A pause of a specified length in a piece of music.",
              synonyms: [],
              antonyms: [],
              example: "Remember there's a rest at the end of the fourth bar.",
            },
            {
              definition:
                "A written symbol indicating such a pause in a musical score such as in sheet music.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Absence of motion.",
              synonyms: [],
              antonyms: ["motion"],
              example:
                "The body's centre of gravity may affect its state of rest.",
            },
            {
              definition:
                "A stick with a U-, V- or X-shaped head used to support the tip of a cue when the cue ball is otherwise out of reach.",
              synonyms: [],
              antonyms: [],
              example:
                "Higgins can't quite reach the white with his cue, so he'll be using the rest.",
            },
            {
              definition:
                "Any object designed to be used to support something else.",
              synonyms: ["cradle", "support"],
              antonyms: [],
              example: "He placed his hands on the arm rests of the chair.",
            },
            {
              definition:
                "A projection from the right side of the cuirass of armour, serving to support the lance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A place where one may rest, either temporarily, as in an inn, or permanently, as, in an abode.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A short pause in reading poetry; a caesura.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The striking of a balance at regular intervals in a running account. Often, specifically, the intervals after which compound interest is added to capital.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A set or game at tennis.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "break",
            "repose",
            "time off",
            "cradle",
            "support",
            "peace",
            "peace",
            "quiet",
            "roo",
            "silence",
            "stillness",
            "tranquility",
            "sleep",
            "slumber",
          ],
          antonyms: ["activity", "motion"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cease from action, motion, work, or performance of any kind; stop; desist; be without motion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To come to a pause or an end; end.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be free from that which harasses or disturbs; be quiet or still; be undisturbed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be or to put into a state of rest.",
              synonyms: [],
              antonyms: [],
              example: "I shall not rest until I have uncovered the truth.",
            },
            {
              definition: "To stay, remain, be situated.",
              synonyms: [],
              antonyms: [],
              example: "The blame seems to rest with your father.",
            },
            {
              definition: "To lean, lie, or lay.",
              synonyms: [],
              antonyms: [],
              example: "A column rests on its pedestal.",
            },
            {
              definition:
                "To complete one's active advocacy in a trial or other proceeding, and thus to wait for the outcome (however, one is still generally available to answer questions, etc.)",
              synonyms: [],
              antonyms: [],
              example: "I rest my case.",
            },
            { definition: "To sleep; slumber.", synonyms: [], antonyms: [] },
            { definition: "To lie dormant.", synonyms: [], antonyms: [] },
            {
              definition:
                "To sleep the final sleep; sleep in death; die; be dead.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To rely or depend on.",
              synonyms: [],
              antonyms: [],
              example: "The decision rests on getting a bank loan.",
            },
            {
              definition: "To be satisfied; to acquiesce.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "be",
            "lie",
            "remain",
            "reside",
            "stay",
            "relieve",
            "lean",
            "lie",
            "relax",
            "have a breather",
            "pause",
            "take a break",
            "take time off",
            "take time out",
            "lay",
            "lean",
            "place",
            "put",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To remain.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To arrest.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rest",
        "https://en.wiktionary.org/wiki/rests",
      ],
    },
  ],
  chill: [
    {
      word: "chill",
      phonetic: "/tʃɪl/",
      phonetics: [
        {
          text: "/tʃɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chill-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50014384",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A moderate, but uncomfortable and penetrating coldness.",
              synonyms: [],
              antonyms: [],
              example: "There was a chill in the air.",
            },
            {
              definition:
                "A sudden penetrating sense of cold, especially one that causes a brief trembling nerve response through the body; the trembling response itself; often associated with illness: fevers and chills, or susceptibility to illness.",
              synonyms: [],
              antonyms: [],
              example:
                "Close the window or you'll catch a chill.   I felt a chill when the wind picked up.",
            },
            {
              definition:
                "An uncomfortable and numbing sense of fear, dread, anxiety, or alarm, often one that is sudden and usually accompanied by a trembling nerve response resembling the body's response to biting cold.",
              synonyms: [],
              antonyms: [],
              example:
                "Despite the heat, he felt a chill as he entered the crime scene.   The actor's eerie portrayal sent chills through the audience.   His menacing presence cast a chill over everyone.",
            },
            {
              definition:
                "An iron mould or portion of a mould, serving to cool rapidly, and so to harden, the surface of molten iron brought in contact with it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The hardened part of a casting, such as the tread of a carriage wheel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A lack of warmth and cordiality; unfriendliness.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Calmness; equanimity.", synonyms: [], antonyms: [] },
            {
              definition: "A sense of style; trendiness; savoir faire.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To lower the temperature of something; to cool",
              synonyms: [],
              antonyms: [],
              example: "Chill before serving.",
            },
            {
              definition: "To become cold",
              synonyms: [],
              antonyms: [],
              example: "In the wind he chilled quickly.",
            },
            {
              definition: "To harden a metal surface by sudden cooling",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To become hard by rapid cooling",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To relax, lie back",
              synonyms: [],
              antonyms: [],
              example:
                "Chill, man, we've got a whole week to do it; no sense in getting worked up.",
            },
            {
              definition:
                'To "hang", hang out; to spend time with another person or group. Also chill out.',
              synonyms: [],
              antonyms: [],
              example: "Hey, we should chill this weekend.",
            },
            {
              definition: "To smoke marijuana",
              synonyms: [],
              antonyms: [],
              example: "On Friday night do you wanna chill?",
            },
            {
              definition: "To discourage, depress",
              synonyms: [],
              antonyms: [],
              example: "Censorship chills public discourse.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Moderately cold or chilly.",
              synonyms: [],
              antonyms: [],
              example: "A chill wind was blowing down the street.",
            },
            {
              definition: "Unwelcoming; not cordial.",
              synonyms: [],
              antonyms: [],
              example:
                "Arriving late at the wedding, we were met with a chill reception.",
            },
            {
              definition: "Calm, relaxed, easygoing.",
              synonyms: [],
              antonyms: [],
              example:
                "Paint-your-own ceramics studios are a chill way to express yourself while learning more about your date's right brain.",
            },
            {
              definition:
                '"Cool"; meeting a certain hip standard or garnering the approval of a certain peer group.',
              synonyms: [],
              antonyms: [],
              example: "That new movie was chill, man.",
            },
            {
              definition: "Okay, not a problem.",
              synonyms: [],
              antonyms: [],
              example: '"Sorry about that." "It\'s chill."',
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chill"],
    },
  ],
  slain: [
    {
      word: "slain",
      phonetic: "/sleɪn/",
      phonetics: [
        {
          text: "/sleɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/slain-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749989",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: '(with "the") Those who have been killed.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To kill, murder.",
              synonyms: [],
              antonyms: [],
              example: "Our foes must all be slain.",
            },
            {
              definition: "To eradicate or stamp out.",
              synonyms: [],
              antonyms: [],
              example: "You must slay these thoughts.",
            },
            {
              definition:
                "(by extension) To defeat, overcome (in a competition or contest).",
              synonyms: [],
              antonyms: [],
              example:
                "1956, “Giants Slay Bears in Pro Title Battle”, in Lodi News-Sentinel, 1956 December 31, page 8.",
            },
            {
              definition: "To delight or overwhelm, especially with laughter.",
              synonyms: [],
              antonyms: [],
              example: "Ha ha! You slay me!",
            },
            {
              definition:
                "To amaze, stun or otherwise incapacitate by awesomeness; to be awesome at something; to kill (slang sense).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To have sex with", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "coitize",
            "go to bed with",
            "sleep with",
            "conquer",
            "defeat",
            "overcome",
            "assassinate",
            "kill",
            "murder",
            "hit it out of the park",
            "kill",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/slain",
        "https://en.wiktionary.org/wiki/slay",
      ],
    },
  ],
  panic: [
    {
      word: "panic",
      phonetic: "/ˈpænɪk/",
      phonetics: [
        { text: "/ˈpænɪk/", audio: "" },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/panic-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651262",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Overpowering fright, often affecting groups of people or animals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Rapid reduction in asset prices due to broad efforts to raise cash in anticipation of continuing decline in asset prices.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A kernel panic or system crash.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To feel overwhelming fear.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause somebody to panic.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) To crash.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) To cause the system to crash.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Pertaining to the god Pan.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of fear, fright etc: sudden or overwhelming (attributed by the ancient Greeks to the influence of Pan).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/panic"],
    },
    {
      word: "panic",
      phonetic: "/ˈpænɪk/",
      phonetics: [
        { text: "/ˈpænɪk/", audio: "" },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/panic-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651262",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A plant of the genus Panicum.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["panicgrass"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/panic"],
    },
  ],
  cords: [
    {
      word: "cords",
      phonetic: "/kɔːdz/",
      phonetics: [
        { text: "/kɔːdz/", audio: "" },
        {
          text: "/kɔɹdz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cords-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=341492",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A harmonic set of three or more notes that is heard as if sounding simultaneously.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A straight line between two points of a curve.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A horizontal member of a truss.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The distance between the leading and trailing edge of a wing, measured in the direction of the normal airflow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An imaginary line from the luff of a sail to its leech.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A keyboard shortcut that involves two or more distinct keypresses, such as Ctrl+M followed by P.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The string of a musical instrument.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A cord.", synonyms: [], antonyms: [] },
            {
              definition:
                "An edge that is not part of a cycle but connects two vertices of the cycle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A long, thin, flexible length of twisted yarns (strands) of fiber (rope, for example); (uncountable) such a length of twisted strands considered as a commodity.",
              synonyms: [],
              antonyms: [],
              example: "He looped some cord around his fingers.",
            },
            {
              definition:
                "A small flexible electrical conductor composed of wires insulated separately or in bundles and assembled together usually with an outer cover; the electrical cord of a lamp, sweeper ((US) vacuum cleaner), or other appliance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A unit of measurement for firewood, equal to 128 cubic feet (4 × 4 × 8 feet), composed of logs and/or split logs four feet long and none over eight inches diameter. It is usually seen as a stack four feet high by eight feet long.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any influence by which persons are caught, held, or drawn, as if by a cord.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any structure having the appearance of a cord, especially a tendon or nerve.",
              synonyms: [],
              antonyms: [],
              example:
                "spermatic cord; spinal cord; umbilical cord; vocal cords",
            },
          ],
          synonyms: ["cable", "twine", "cable", "flex"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To furnish with cords", synonyms: [], antonyms: [] },
            {
              definition: "To tie or fasten with cords",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To flatten a book during binding",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To arrange (wood, etc.) in a pile for measurement by the cord.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/chord",
        "https://en.wiktionary.org/wiki/cord",
        "https://en.wiktionary.org/wiki/cords",
      ],
    },
    {
      word: "cords",
      phonetic: "/kɔːdz/",
      phonetics: [
        { text: "/kɔːdz/", audio: "" },
        {
          text: "/kɔɹdz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cords-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=341492",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Corduroys.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cords"],
    },
  ],
  tuned: [
    {
      word: "tuned",
      phonetic: "/tjuːnd/",
      phonetics: [{ text: "/tjuːnd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To adjust (a musical instrument) so that it produces the correct pitches.",
              synonyms: [],
              antonyms: [],
              example: "to tune a piano or a violin",
            },
            {
              definition:
                "To adjust or modify (esp. a mechanical or electrical device) so that it functions optimally.",
              synonyms: [],
              antonyms: [],
              example: "Tune your mind, and anything becomes possible.",
            },
            {
              definition:
                "To adjust the frequency on a radio or TV set, so as to receive the desired channel.",
              synonyms: [],
              antonyms: [],
              example:
                "Tune to Channel 6 for all your favourite daytime shows.",
            },
            {
              definition:
                "(e.g. of senses or faculties) To adapt to or direct towards a particular target.",
              synonyms: [],
              antonyms: [],
              example: "My ears were tuned to the sounds of the forest.",
            },
            {
              definition:
                "To make more precise, intense, or effective; to put into a proper state or disposition.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To attune; to adapt in style of music; to make harmonious.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To give a certain tone or character to.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To sing with melody or harmony.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cheek; to be impudent towards.",
              synonyms: [],
              antonyms: [],
              example: "Are you tuning me?",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Adjusted in pitch or resonance.",
              synonyms: [],
              antonyms: [],
              example:
                "The tuned wind chimes sounded almost like they were playing a melody.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tune",
        "https://en.wiktionary.org/wiki/tuned",
      ],
    },
  ],
  crisp: [
    {
      word: "crisp",
      phonetic: "/kɹɪsp/",
      phonetics: [
        {
          text: "/kɹɪsp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/crisp-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2650817",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A thin slice of fried potato eaten as a snack.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A baked dessert made with fruit and crumb topping",
              synonyms: ["crumble", "crunch"],
              antonyms: [],
            },
            {
              definition: "Anything baked or fried and eaten as a snack",
              synonyms: [],
              antonyms: [],
              example: "kale crisps",
            },
          ],
          synonyms: ["potato chip", "potato crisp", "crumble", "crunch"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make crisp.",
              synonyms: ["crispen"],
              antonyms: [],
              example: "to crisp bacon by frying it",
            },
            {
              definition: "To become crisp.",
              synonyms: ["crispen"],
              antonyms: [],
              example: "to put celery into ice water to crisp",
            },
            {
              definition:
                "To cause to curl or wrinkle (of the leaves or petals of plants, for example); to form into ringlets or tight curls (of hair).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To become curled.", synonyms: [], antonyms: [] },
            {
              definition:
                "To cause to undulate irregularly (of water); to cause to ripple.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To undulate or ripple.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To wrinkle, contort or tense (a part of one's body).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To become contorted or tensed (of a part of the body).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To interweave (of the branches of trees).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make a sharp or harsh sound.",
              synonyms: ["crackle", "creak", "crunch", "rustle"],
              antonyms: [],
            },
            {
              definition:
                "To colour (something with highlights); to add small amounts of colour to (something).",
              synonyms: ["tinge"],
              antonyms: [],
            },
          ],
          synonyms: [
            "crackle",
            "creak",
            "crunch",
            "rustle",
            "crispen",
            "crispen",
            "tinge",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(of something seen or heard) Sharp, clearly defined.",
              synonyms: [],
              antonyms: [],
              example: "This new television set has a very crisp image.",
            },
            {
              definition:
                "Brittle; friable; in a condition to break with a short, sharp fracture.",
              synonyms: [],
              antonyms: [],
              example: "The crisp snow crunched underfoot.",
            },
            {
              definition:
                "Possessing a certain degree of firmness and freshness.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of weather, air etc.) Dry and cold.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of movement, action etc.) Quick and accurate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of talk, text, etc.) Brief and to the point.",
              synonyms: [],
              antonyms: [],
              example:
                "An expert, given a certain query, will often come up with a crisp answer: “yes” or “no”.",
            },
            {
              definition:
                "(of wine) having a refreshing amount of acidity; having less acidity than green wine, but more than a flabby one.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Lively; sparking; effervescing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Curling in stiff curls or ringlets.",
              synonyms: [],
              antonyms: [],
              example: "crisp hair",
            },
            {
              definition: "Curled by the ripple of water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Not using fuzzy logic; based on a binary distinction between true and false.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/crisp"],
    },
  ],
  ledge: [
    {
      word: "ledge",
      phonetic: "/lɛdʒ/",
      phonetics: [
        {
          text: "/lɛdʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ledge-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241426",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A shelf on which articles may be laid; also, that which resembles such a shelf in form or use, as a projecting ridge or part, or a molding or edge in joinery.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A shelf, ridge, or reef, of rocks.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A layer or stratum.", synonyms: [], antonyms: [] },
            {
              definition:
                "A lode; a limited mass of rock bearing valuable mineral.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A (door or window) lintel.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A cornice.", synonyms: [], antonyms: [] },
            {
              definition:
                "A piece of timber to support the deck, placed athwartship between beams.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cause to have, or to develop, a ledge (during mining, canal construction, building, etc).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ledge"],
    },
    {
      word: "ledge",
      phonetic: "/lɛdʒ/",
      phonetics: [
        {
          text: "/lɛdʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ledge-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241426",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A lege; a legend.",
              synonyms: [],
              antonyms: [],
              example:
                "http//www.independent.co.uk/sport/football/news-and-comment/pete-jenson-adebayors-fresh-start-means-hard-work-short-hair-ndash-and-vital-goals-2263767.html, http//www.telegraph.co.uk/sport/tennis/wimbledon/8605605/Andy-Murray-v-Feliciano-Lopez-at-Wimbledon-2011-as-it-happened.html, https//web.archive.org/web/20121109021139/http://www.bbc.co.uk/sport/0/olympics/19083758",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ledge"],
    },
    {
      word: "ledge",
      phonetic: "/lɛdʒ/",
      phonetics: [
        {
          text: "/lɛdʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ledge-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241426",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A provincial or territorial legislature building.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A provincial or territorial legislative assembly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ledge"],
    },
  ],
  dived: [
    {
      word: "dived",
      phonetic: "/ˈdaɪ̯vd/",
      phonetics: [{ text: "/ˈdaɪ̯vd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To swim under water.", synonyms: [], antonyms: [] },
            {
              definition: "To jump into water head-first.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To jump headfirst toward the ground or into another substance.",
              synonyms: [],
              antonyms: [],
              example: "to dive into home plate",
            },
            {
              definition: "To descend sharply or steeply.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(especially with in) To undertake with enthusiasm.",
              synonyms: [],
              antonyms: [],
              example: "She dove right in and started making improvements.",
            },
            {
              definition:
                "To deliberately fall down after a challenge, imitating being fouled, in the hope of getting one's opponent penalised.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause to descend, dunk; to plunge something into water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To explore by diving; to plunge into.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To plunge or to go deeply into any subject, question, business, etc.; to penetrate; to explore.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dive",
        "https://en.wiktionary.org/wiki/dived",
      ],
    },
  ],
  swamp: [
    {
      word: "swamp",
      phonetic: "/swɒmp/",
      phonetics: [
        { text: "/swɒmp/", audio: "" },
        {
          text: "/swɑmp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/swamp-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780425",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A piece of wet, spongy land; low ground saturated with water; soft, wet ground which may have a growth of certain kinds of trees, but is unfit for agricultural or pastoral purposes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A type of wetland that stretches for vast distances, and is home to many creatures which have adapted specifically to that environment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A place or situation that is foul or where progress is difficult.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To drench or fill with water.",
              synonyms: [],
              antonyms: [],
              example: "The boat was swamped in the storm.",
            },
            {
              definition:
                "To overwhelm; to make too busy, or overrun the capacity of.",
              synonyms: [],
              antonyms: [],
              example:
                "I have been swamped with paperwork ever since they started using the new system.",
            },
            {
              definition:
                "To plunge into difficulties and perils; to overwhelm; to ruin; to wreck.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/swamp"],
    },
  ],
  clung: [
    {
      word: "clung",
      phonetic: "/ˈklʌŋ/",
      phonetics: [
        {
          text: "/ˈklʌŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/clung-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2707470",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To hold very tightly, as to not fall off.",
              synonyms: [],
              antonyms: [],
              example: "Seaweed clung to the anchor.",
            },
            {
              definition:
                "To adhere to an object, without being affixed, in such a way as to follow its contours. Used especially of fabrics and films.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause to adhere to, especially by twining round or embracing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause to dry up or wither.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To dry up or wither.",
              synonyms: [],
              antonyms: [],
              example: "Wood clings.",
            },
            {
              definition:
                "(with preposition to) to be fond of, to feel strongly about and dependent on",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["cleave", "stick", "clinch", "grip"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Wasted away; shrunken", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cling",
        "https://en.wiktionary.org/wiki/clung",
      ],
    },
  ],
  stole: [
    {
      word: "stole",
      phonetic: "/ˈstəʊl/",
      phonetics: [
        { text: "/ˈstəʊl/", audio: "" },
        {
          text: "/ˈstoʊl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stole-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2101143",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To take illegally, or without the owner's permission, something owned by someone else.",
              synonyms: [],
              antonyms: [],
              example:
                "Three irreplaceable paintings were stolen from the gallery.",
            },
            {
              definition:
                "(of ideas, words, music, a look, credit, etc.) To appropriate without giving credit or acknowledgement.",
              synonyms: [],
              antonyms: [],
              example:
                "They stole my idea for a biodegradable, disposable garbage de-odorizer.",
            },
            {
              definition: "To get or effect surreptitiously or artfully.",
              synonyms: [],
              antonyms: [],
              example:
                "He stole glances at the pretty woman across the street.",
            },
            {
              definition: "To acquire at a low price.",
              synonyms: [],
              antonyms: [],
              example:
                "He stole the car for two thousand less than its book value.",
            },
            {
              definition:
                "To draw attention unexpectedly in (an entertainment), especially by being the outstanding performer. Usually used in the phrase steal the show.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To move silently or secretly.",
              synonyms: [],
              antonyms: [],
              example: "He stole across the room, trying not to wake her.",
            },
            {
              definition: "To convey (something) clandestinely.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To withdraw or convey (oneself) clandestinely.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To advance safely to (another base) during the delivery of a pitch, without the aid of a hit, walk, passed ball, wild pitch, or defensive indifference.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To dispossess", synonyms: [], antonyms: [] },
            {
              definition: "To borrow for a short moment.",
              synonyms: [],
              antonyms: [],
              example: "Can I steal your pen?",
            },
          ],
          synonyms: ["sneak"],
          antonyms: [
            "bestow",
            "donate",
            "grant",
            "buy",
            "earn",
            "purchase",
            "receive",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/steal",
        "https://en.wiktionary.org/wiki/stole",
      ],
    },
    {
      word: "stole",
      phonetic: "/ˈstəʊl/",
      phonetics: [
        { text: "/ˈstəʊl/", audio: "" },
        {
          text: "/ˈstoʊl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stole-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2101143",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An ecclesiastical garment consisting of a decorated band worn on the back of the neck with each end hanging over the chest.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A scarf-like garment, often made of fur.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stole"],
    },
    {
      word: "stole",
      phonetic: "/ˈstəʊl/",
      phonetics: [
        { text: "/ˈstəʊl/", audio: "" },
        {
          text: "/ˈstoʊl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stole-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2101143",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A stolon.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stole"],
    },
  ],
  molds: [
    {
      word: "molds",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A hollow form or matrix for shaping a fluid or plastic substance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A frame or model around or on which something is formed or shaped.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Something that is made in or shaped on a mold.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The shape or pattern of a mold.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "General shape or form.",
              synonyms: [],
              antonyms: [],
              example: "the oval mold of her face",
            },
            {
              definition: "Distinctive character or type.",
              synonyms: [],
              antonyms: [],
              example: "a leader in the mold of her predecessors",
            },
            {
              definition: "A fixed or restrictive pattern or form.",
              synonyms: [],
              antonyms: [],
              example:
                "His method of scientific investigation broke the mold and led to a new discovery.",
            },
            {
              definition: "A group of moldings.",
              synonyms: [],
              antonyms: [],
              example:
                "the arch mold of a porch or doorway;  the pier mold of a Gothic pier, meaning the whole profile, section, or combination of parts",
            },
            { definition: "A fontanelle.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To shape in or on a mold; to form into a particular shape; to give shape to.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To guide or determine the growth or development of; influence",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fit closely by following the contours of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make a mold of or from (molten metal, for example) before casting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To ornament with moldings.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be shaped in or as if in a mold.",
              synonyms: [],
              antonyms: [],
              example: "These shoes gradually molded to my feet.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A natural substance in the form of a woolly or furry growth of tiny fungi that appears when organic material lies for a long time exposed to (usually warm and moist) air.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cause to become moldy; to cause mold to grow upon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To become moldy; to be covered or filled, in whole or in part, with a mold.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Loose friable soil, rich in humus and fit for planting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(chiefly plural) Earth, ground.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cover with mold or soil.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mold",
        "https://en.wiktionary.org/wiki/molds",
      ],
    },
  ],
  yarns: [
    {
      word: "yarns",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A twisted strand of fiber used for knitting or weaving.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Bundles of fibers twisted together, and which in turn are twisted in bundles to form strands, which in their turn are twisted or plaited to form rope.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A story, a tale, especially one that is incredible.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["story", "tale"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To tell a story or stories.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/yarn",
        "https://en.wiktionary.org/wiki/yarns",
      ],
    },
  ],
  liver: [
    {
      word: "liver",
      phonetic: "/ˈlɪvə/",
      phonetics: [
        { text: "/ˈlɪvə/", audio: "" },
        {
          text: "/ˈlɪvɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/liver-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762978",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A large organ in the body that stores and metabolizes nutrients, destroys toxins and produces bile. It is responsible for thousands of biochemical reactions.",
              synonyms: [],
              antonyms: [],
              example: "Steve Jobs is a famous liver transplant recipient.",
            },
            {
              definition: "This organ, as taken from animals used as food.",
              synonyms: [],
              antonyms: [],
              example: "I'd like some goose liver pate.",
            },
            {
              definition:
                "A dark brown colour, tinted with red and gray, like the colour of liver.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Of the colour of liver (dark brown, tinted with red and gray).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/liver"],
    },
    {
      word: "liver",
      phonetic: "/ˈlɪvə(ɹ)/",
      phonetics: [
        {
          text: "/ˈlɪvə(ɹ)/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/liver-2-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762978",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Someone who lives (usually in a specified way).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/liver"],
    },
  ],
  gauge: [
    {
      word: "gauge",
      phonetic: "/ˈɡeɪdʒ/",
      phonetics: [
        {
          text: "/ˈɡeɪdʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gauge-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=700892",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A measure; a standard of measure; an instrument to determine dimensions, distance, or capacity; a standard",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An act of measuring.", synonyms: [], antonyms: [] },
            { definition: "An estimate.", synonyms: [], antonyms: [] },
            {
              definition:
                "Any instrument for ascertaining or regulating the level, state, dimensions or forms of things",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A thickness of sheet metal or wire designated by any of several numbering schemes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The distance between the rails of a railway.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A semi-norm; a function that assigns a non-negative size to all vectors in a vector space.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The number of stitches per inch, centimetre, or other unit of distance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Relative positions of two or more vessels with reference to the wind.",
              synonyms: [],
              antonyms: [],
              example:
                "A vessel has the weather gauge of another when on the windward side of it, and the lee gauge when on the lee side of it.",
            },
            {
              definition: "The depth to which a vessel sinks in the water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(plastering) The quantity of plaster of Paris used with common plaster to make it set more quickly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That part of a shingle, slate, or tile, which is exposed to the weather, when laid; also, one course of such shingles, slates, or tiles.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A unit of measurement which describes how many spheres of bore diameter of a shotgun can be had from one pound of lead; 12 gauge is roughly equivalent to .75 caliber.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) A shotgun (synecdoche for 12 gauge shotgun, the most common chambering for combat and hunting shotguns).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A tunnel-like ear piercing consisting of a hollow ring embedded in the lobe.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To measure or determine with a gauge; to measure the capacity of.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To estimate.", synonyms: [], antonyms: [] },
            {
              definition:
                "To appraise the character or ability of; to judge of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To draw into equidistant gathers by running a thread through it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To mix (a quantity of ordinary plaster) with a quantity of plaster of Paris.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To chip, hew or polish (stones, bricks, etc) to a standard size and/or shape.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gauge"],
    },
  ],
  breed: [
    {
      word: "breed",
      phonetic: "/bɹiːd/",
      phonetics: [
        {
          text: "/bɹiːd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/breed-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2650713",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "All animals or plants of the same species or subspecies.",
              synonyms: [],
              antonyms: [],
              example: "a breed of animal",
            },
            {
              definition: "A race or lineage; offspring or issue.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A group of people with shared characteristics.",
              synonyms: [],
              antonyms: [],
              example:
                "People who were taught classical Greek and Latin at school are a dying breed.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To produce offspring sexually; to bear young.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To give birth to; to be the native place of.",
              synonyms: [],
              antonyms: [],
              example:
                "a pond breeds fish; a northern country breeds stout men",
            },
            { definition: "Of animals, to mate.", synonyms: [], antonyms: [] },
            {
              definition:
                "To keep animals and have them reproduce in a way that improves the next generation’s qualities.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To arrange the mating of specific animals.",
              synonyms: [],
              antonyms: [],
              example:
                "She wanted to breed her cow to the neighbor's registered bull.",
            },
            {
              definition:
                "To propagate or grow plants trying to give them certain qualities.",
              synonyms: [],
              antonyms: [],
              example: "He tries to breed blue roses.",
            },
            {
              definition:
                "To take care of in infancy and through childhood; to bring up.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To yield or result in.",
              synonyms: [],
              antonyms: [],
              example: "disaster breeds famine;  familiarity breeds contempt",
            },
            {
              definition:
                "To be formed in the parent or dam; to be generated, or to grow, like young before birth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(sometimes as breed up) To educate; to instruct; to bring up",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To produce or obtain by any natural process.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To have birth; to be produced, developed or multiplied.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To ejaculate inside someone's ass",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bring up", "raise", "rear"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/breed"],
    },
  ],
  stool: [
    {
      word: "stool",
      phonetic: "/stuːl/",
      phonetics: [
        {
          text: "/stuːl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stool-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780404",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A seat, especially for one person and without armrests.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A close-stool; a seat used for urination and defecation: a chamber pot, commode, outhouse seat, or toilet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A plant that has been cut down until its main stem is close to the ground, resembling a stool, to promote new growth.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Feces, excrement.", synonyms: [], antonyms: [] },
            {
              definition:
                "A production of feces or excrement, an act of defecation, stooling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A decoy; a portable piece of wood to which a pigeon is fastened to lure wild birds.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small channel on the side of a vessel, for the deadeyes of the backstays.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Material, such as oyster shells, spread on the sea bottom for oyster spat to adhere to.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To produce stool: to defecate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cut down (a plant) until its main stem is close to the ground, resembling a stool, to promote new growth.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stool"],
    },
    {
      word: "stool",
      phonetic: "/stuːl/",
      phonetics: [
        {
          text: "/stuːl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stool-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780404",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A plant from which layers are propagated by bending its branches into the soil.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To ramify; to tiller, as grain; to shoot out suckers.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stool"],
    },
  ],
  gulls: [
    {
      word: "gulls",
      phonetic: "/ɡʌlz/",
      phonetics: [{ text: "/ɡʌlz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A seabird of the genus Larus or of the family Laridae.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various pierid butterflies of the genus Cepora.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["mew", "seagull", "seamew"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A cheating trick; a fraud.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One easily cheated; a dupe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(Oxford University slang) A swindler or trickster.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To deceive or cheat.", synonyms: [], antonyms: [] },
            { definition: "To mislead.", synonyms: [], antonyms: [] },
            { definition: "To trick and defraud.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gull",
        "https://en.wiktionary.org/wiki/gulls",
      ],
    },
  ],
  awoke: [
    {
      word: "awoke",
      phonetic: "/əˈwəʊk/",
      phonetics: [
        {
          text: "/əˈwəʊk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/awoke-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2423256",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To become conscious after having slept.",
              synonyms: ["awaken", "wake up"],
              antonyms: ["fall asleep"],
            },
            {
              definition: "To cause (somebody) to stop sleeping.",
              synonyms: ["bring round", "cry", "wake up"],
              antonyms: ["put to sleep"],
            },
            {
              definition: "To excite or to stir up something latent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To rouse from a state of inaction or dormancy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To come out of a state of inaction or dormancy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["awaken", "wake up", "bring round", "cry", "wake up"],
          antonyms: ["fall asleep", "put to sleep"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/awake",
        "https://en.wiktionary.org/wiki/awoke",
      ],
    },
  ],
  gross: [
    {
      word: "gross",
      phonetic: "/ɡɹəʊs/",
      phonetics: [
        { text: "/ɡɹəʊs/", audio: "" },
        {
          text: "/ɡɹoʊs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gross-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100437",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Twelve dozen = 144.", synonyms: [], antonyms: [] },
            {
              definition:
                "The total nominal earnings or amount, before taxes, expenses, exceptions or similar are deducted. That which remains after all deductions is called net.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The bulk, the mass, the masses.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To earn money, not including expenses.",
              synonyms: [],
              antonyms: [],
              example: "The movie grossed three million on the first weekend.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(of behaviour considered to be wrong) Highly or conspicuously offensive.",
              synonyms: [
                "appalling",
                "egregious",
                "flagrant",
                "serious",
                "shameful",
              ],
              antonyms: [],
              example:
                "a gross mistake;  gross injustice;  gross negligence; a gross insult",
            },
            {
              definition:
                "(of an amount) Excluding any deductions; including all associated amounts.",
              synonyms: ["aggregate", "entire", "overall", "total", "whole"],
              antonyms: ["net"],
              example: "gross domestic product; gross income; gross weight",
            },
            {
              definition:
                "Seen without a microscope (usually for a tissue or an organ); at a large scale; not detailed.",
              synonyms: ["macroscopic"],
              antonyms: ["microscopic"],
              example: "gross anatomy",
            },
            {
              definition: "Causing disgust.",
              synonyms: [
                "disgusting",
                "gro",
                "grody",
                "grotty",
                "nasty",
                "revolting",
                "yucky",
              ],
              antonyms: [],
              example: "I threw up all over the bed. It was totally gross.",
            },
            {
              definition:
                "Lacking refinement in behaviour or manner; offending a standard of morality.",
              synonyms: ["coarse", "impure", "obscene", "rude", "vulgar"],
              antonyms: [],
            },
            {
              definition:
                "(of a product) Lacking refinement; not of high quality.",
              synonyms: ["coarse", "rough", "unrefined"],
              antonyms: ["fine"],
            },
            {
              definition:
                "(of a person) Heavy in proportion to one's height; having a lot of excess flesh.",
              synonyms: ["bulky", "fat", "great", "large", "obese"],
              antonyms: [],
            },
            {
              definition: "Not sensitive in perception or feeling.",
              synonyms: ["dull", "witless"],
              antonyms: [],
            },
            {
              definition: "Difficult or impossible to see through.",
              synonyms: ["heavy", "thick"],
              antonyms: [],
            },
            {
              definition: "Easy to perceive.",
              synonyms: ["clear", "obvious"],
              antonyms: [],
            },
          ],
          synonyms: [
            "aggregate",
            "entire",
            "overall",
            "total",
            "whole",
            "appalling",
            "egregious",
            "flagrant",
            "serious",
            "shameful",
            "bulky",
            "fat",
            "great",
            "large",
            "obese",
            "clear",
            "obvious",
            "coarse",
            "impure",
            "obscene",
            "rude",
            "vulgar",
            "coarse",
            "rough",
            "unrefined",
            "disgusting",
            "gro",
            "grody",
            "grotty",
            "nasty",
            "revolting",
            "yucky",
            "dull",
            "witless",
            "heavy",
            "thick",
            "macroscopic",
          ],
          antonyms: ["fine", "microscopic", "net"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gross"],
    },
  ],
  diary: [
    {
      word: "diary",
      phonetic: "/ˈdaɪəɹi/",
      phonetics: [
        {
          text: "/ˈdaɪəɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/diary-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780035",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A daily log of experiences, especially those of the writer.",
              synonyms: [],
              antonyms: [],
              example:
                "They kept separate diaries. His was on paper and her diary was on her computer's hard drive.",
            },
            {
              definition: "A personal organizer or appointment diary.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["daybook", "journal"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To keep a diary or journal.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Lasting for one day.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/diary"],
    },
  ],
  rails: [
    {
      word: "rails",
      phonetic: "/ɹeɪlz/",
      phonetics: [{ text: "/ɹeɪlz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A horizontal bar extending between supports and used for support or as a barrier; a railing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The metal bar that makes the track for a railroad.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A railroad; a railway, as a means of transportation.",
              synonyms: [],
              antonyms: [],
              example: "We travelled to the seaside by rail.",
            },
            {
              definition:
                "A horizontal piece of wood that serves to separate sections of a door or window.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One of the lengthwise edges of a surfboard.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A vertical section on one side of a web page.",
              synonyms: [],
              antonyms: [],
              example: "We're experimenting with ads in the right-hand rail.",
            },
            {
              definition:
                "A large line (portion or serving of a powdery illegal drug).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To travel by railway.", synonyms: [], antonyms: [] },
            {
              definition: "To enclose with rails or a railing.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To range in a line.", synonyms: [], antonyms: [] },
            {
              definition: "To criticize severely.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Any of several birds in the family Rallidae.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To complain violently (against, about).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An item of clothing; a cloak or other garment; a dress.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Specifically, a woman's headscarf or neckerchief.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(of a liquid) To gush, flow.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rail",
        "https://en.wiktionary.org/wiki/rails",
      ],
    },
  ],
  belly: [
    {
      word: "belly",
      phonetic: "/bɛli/",
      phonetics: [
        {
          text: "/bɛli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/belly-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1318262",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The abdomen, especially a fat one.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The stomach.", synonyms: [], antonyms: [] },
            { definition: "The womb.", synonyms: [], antonyms: [] },
            {
              definition: "The lower fuselage of an airplane.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The part of anything which resembles the human belly in protuberance or in cavity; the innermost part.",
              synonyms: [],
              antonyms: [],
              example: "the belly of a flask, muscle, violin, sail, or ship",
            },
            {
              definition:
                "The hollow part of a curved or bent timber, the convex part of which is the back.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To position one’s belly; to move on one’s belly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To swell and become protuberant; to bulge or billow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause to swell out; to fill.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/belly"],
    },
  ],
  trend: [
    {
      word: "trend",
      phonetic: "/tɹɛnd/",
      phonetics: [
        {
          text: "/tɹɛnd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/trend-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80152074",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An inclination in a particular direction.",
              synonyms: [],
              antonyms: [],
              example: "the trend of a coastline",
            },
            {
              definition: "A tendency.",
              synonyms: [],
              antonyms: [],
              example:
                "There is a trend, these days, for people in films not to smoke.",
            },
            {
              definition: "A fad or fashion style.",
              synonyms: [],
              antonyms: [],
              example:
                "Miniskirts were one of the biggest trends of the 1960s.",
            },
            {
              definition:
                "A line drawn on a graph that approximates the trend of a number of disparate points.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The lower end of the shank of an anchor, being the same distance on the shank from the throat that the arm measures from the throat to the bill.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The angle made by the line of a vessel's keel and the direction of the anchor cable, when she is swinging at anchor.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To have a particular direction; to run; to stretch; to tend.",
              synonyms: [],
              antonyms: [],
              example: "The shore of the sea trends to the southwest.",
            },
            {
              definition: "To cause to turn; to bend.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be the subject of a trend; to be currently popular, relevant or interesting.",
              synonyms: [],
              antonyms: [],
              example:
                "What topics have been trending on social networks this week?",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/trend"],
    },
    {
      word: "trend",
      phonetic: "/tɹɛnd/",
      phonetics: [
        {
          text: "/tɹɛnd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/trend-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80152074",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Clean wool.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cleanse or clean (something, usually wool).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/trend"],
    },
  ],
  flask: [
    {
      word: "flask",
      phonetic: "/flæsk/",
      phonetics: [
        {
          text: "/flæsk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/flask-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=51104638",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A narrow-necked vessel of metal or glass, used for various purposes; as of sheet metal, to carry gunpowder in; or of wrought iron, to contain quicksilver; or of glass, to heat water in, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A container used to discreetly carry a small amount of a hard alcoholic beverage; a pocket flask.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Laboratory glassware used to hold larger volumes than test tubes, normally having a narrow mouth of a standard size which widens to a flat or spherical base.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A container for holding a casting mold, especially for sand casting molds.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A bed in a gun carriage.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To invest a denture in a flask so as to produce a sectional mold.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/flask"],
    },
  ],
  stake: [
    {
      word: "stake",
      phonetic: "/steɪk/",
      phonetics: [
        {
          text: "/steɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stake-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769778",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A piece of wood or other material, usually long and slender, pointed at one end so as to be easily driven into the ground as a marker or a support or stay.",
              synonyms: [],
              antonyms: [],
              example:
                "We have surveyor's stakes at all four corners of this field, to mark exactly its borders.",
            },
            {
              definition:
                "A piece of wood driven in the ground, placed in the middle of the court, that is used as the finishing point after scoring 12 hoops in croquet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A stick inserted upright in a lop, eye, or mortise, at the side or end of a cart, flat car, flatbed trailer, or the like, to prevent goods from falling off.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(with definite article) The piece of timber to which a person condemned to death was affixed to be burned.",
              synonyms: [],
              antonyms: [],
              example: "Thomas Cranmer was burnt at the stake.",
            },
            {
              definition:
                "A share or interest in a business or a given situation.",
              synonyms: [],
              antonyms: [],
              example:
                "The owners let the managers eventually earn a stake in the business.",
            },
            {
              definition:
                "That which is laid down as a wager; that which is staked or hazarded; a pledge.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small anvil usually furnished with a tang to enter a hole in a bench top, as used by tinsmiths, blacksmiths, etc., for light work, punching hole in or cutting a work piece, or for specific forming techniques etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A territorial division comprising all the Mormons (typically several thousand) in a geographical area.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["peg"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To fasten, support, defend, or delineate with stakes.",
              synonyms: [],
              antonyms: [],
              example: "to stake vines or plants",
            },
            {
              definition: "To pierce or wound with a stake.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To put at risk upon success in competition, or upon a future contingency.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To provide another with money in order to engage in an activity as betting or a business venture.",
              synonyms: [],
              antonyms: [],
              example:
                "His family staked him $10,000 to get his business started.",
            },
          ],
          synonyms: ["bet", "hazard", "wager"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stake"],
    },
  ],
  fried: [
    {
      word: "fried",
      phonetic: "/fɹaɪd/",
      phonetics: [
        {
          text: "/fɹaɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fried-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780093",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Cooked by frying.", synonyms: [], antonyms: [] },
            {
              definition:
                "(specifically, of an egg) Fried with the yolk unbroken.",
              synonyms: [],
              antonyms: [],
              example: "He always ate his eggs fried, never scrambled.",
            },
            {
              definition:
                "Cooked in a deep fryer or pressure fryer or the like after being coated (breaded) in batter; compare deep-fried.",
              synonyms: [],
              antonyms: [],
              example: "a bucket of fried chicken",
            },
            {
              definition:
                "(of computer equipment) Broken as a result of excessive heat or an electrical surge.",
              synonyms: [],
              antonyms: [],
              example: "It looks like your motherboard is fried.",
            },
            {
              definition: "Stoned; under the influence of drugs",
              synonyms: [],
              antonyms: [],
              example: "Man, I got totally fried on weed at Chad's party.'",
            },
            {
              definition:
                "Extremely tired due to exertion or stress; exhausted",
              synonyms: [],
              antonyms: [],
              example:
                "After nearly twelve hours at the office plus a nightmare commute home, I was fried and couldn’t think of anything but sleep.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "A method of cooking food.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be affected by extreme heat or current.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fried",
        "https://en.wiktionary.org/wiki/fry",
      ],
    },
  ],
  draws: [
    {
      word: "draws",
      phonetic: "/dɹɑz/",
      phonetics: [{ text: "/dɹɑz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The result of a contest that neither side has won; a tie.",
              synonyms: [],
              antonyms: [],
              example: "The game ended in a draw.",
            },
            {
              definition:
                "The procedure by which the result of a lottery is determined.",
              synonyms: [],
              antonyms: [],
              example: "The draw is on Saturday.",
            },
            {
              definition: "Something that attracts e.g. a crowd.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The result of a two-innings match in which at least one side did not complete all their innings before time ran out (as distinguished from a tie).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A golf shot that (for the right-handed player) curves intentionally to the left. See hook, slice, fade.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A shot that is intended to land gently in the house (the circular target) without knocking out other stones; cf. takeout.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A dry stream bed that drains surface water only during periods of heavy rain or flooding.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A bag of cannabis.", synonyms: [], antonyms: [] },
            { definition: "Cannabis.", synonyms: [], antonyms: [] },
            {
              definition:
                "In a commission-based job, an advance on future (potential) commissions given to an employee by the employer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A situation in which one or more players has four cards of the same suit or four out of five necessary cards for a straight and requires a further card to make their flush or straight.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The act of pulling back the strings in preparation of firing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The spin or twist imparted to a ball etc. by a drawing stroke.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["stalemate", "dry creek"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To move or develop something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To exert or experience force.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(fluidic) To remove or separate or displace.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To change in size or shape.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To attract or be attracted.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(usually as draw on or draw upon) To rely on; utilize as a source.",
              synonyms: [],
              antonyms: [],
              example:
                "She had to draw upon her experience to solve the problem.",
            },
            { definition: "To disembowel.", synonyms: [], antonyms: [] },
            {
              definition:
                "To end a game in a draw (with neither side winning).",
              synonyms: [],
              antonyms: [],
              example:
                "We drew last time we played.  I drew him last time I played him.  I drew my last game against him.",
            },
            {
              definition: "To choose by means of a random selection process.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make a shot that lands gently in the house (the circular target) without knocking out other stones.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To play (a short-length ball directed at the leg stump) with an inclined bat so as to deflect the ball between the legs and the wicket.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To hit (the ball) with the toe of the club so that it is deflected toward the left.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To strike (the cue ball) below the center so as to give it a backward rotation which causes it to take a backward direction on striking another ball.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/draw",
        "https://en.wiktionary.org/wiki/draws",
      ],
    },
  ],
  actor: [
    {
      word: "actor",
      phonetic: "/ˈæk.tə/",
      phonetics: [
        {
          text: "/ˈæk.tə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/actor-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79900309",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈæk.tɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/actor-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762567",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person who performs, plays a part in a theatrical play or film.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "One who acts; a doer.", synonyms: [], antonyms: [] },
            {
              definition: "One who takes part in a situation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An advocate or proctor in civil courts or causes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One who institutes a suit; plaintiff or complainant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(policy debate) One who enacts a certain policy action.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The entity that performs a role (in use case analysis).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                '(grammar) The most agent-like argument of a clause, e.g. \'the torpedo\' in "The torpedo sank the boat" and "The torpedo fired".',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "complainant",
            "plaintiff",
            "role",
            "doer",
            "participant",
            "performer",
            "player",
          ],
          antonyms: ["undergoer"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/actor"],
    },
  ],
  handy: [
    {
      word: "handy",
      phonetic: "/ˈhæn.di/",
      phonetics: [
        {
          text: "/ˈhæn.di/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/handy-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762697",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Easy to use, useful.",
              synonyms: [],
              antonyms: [],
              example: "Some people regard duct tape as a handy fix-all.",
            },
            {
              definition: "Nearby, within reach.",
              synonyms: ["at hand"],
              antonyms: [],
              example: "I keep a first-aid kit handy in case of emergency.",
            },
            {
              definition: "Of a person: dexterous, skilful.",
              synonyms: ["crafty"],
              antonyms: [],
              example:
                "She's very handy: she made all her own kitchen cupboards.",
            },
            {
              definition: "Physically violent; tending to use one's fists.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of a freight ship: having a small cargo capacity (less than 40,000 DWT); belonging to the handysize class.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["at hand", "crafty"],
          antonyms: ["awkward"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/handy"],
    },
    {
      word: "handy",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A hand job.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/handy"],
    },
    {
      word: "handy",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A handgun.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/handy"],
    },
  ],
  bowls: [
    {
      word: "bowls",
      phonetic: "/bəʊlz/",
      phonetics: [
        { text: "/bəʊlz/", audio: "" },
        { text: "/boʊlz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A roughly hemispherical container used to hold, mix or present food, such as salad, fruit or soup, or other items.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "As much as is held by a bowl.",
              synonyms: [],
              antonyms: [],
              example:
                "You can’t have any more soup – you’ve had three bowls already.",
            },
            {
              definition:
                "A dish comprising a mix of different foods, not all of which need be cooked, served in a bowl.",
              synonyms: [],
              antonyms: [],
              example: "This restaurant offers a number of different bowls.",
            },
            {
              definition:
                "A haircut in which straight hair is cut at an even height around the edges, forming a bowl shape.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The round hollow part of anything.",
              synonyms: [],
              antonyms: [],
              example:
                "Direct the cleaning fluid around the toilet bowl and under the rim.",
            },
            {
              definition: "A round crater (or similar) in the ground.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An elliptical-shaped stadium or amphitheater resembling a bowl.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A postseason football competition, a bowl game (i.e. Rose Bowl, Super Bowl)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bowlful", "crater", "hollow", "bowl cut", "pudding bowl"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The ball rolled by players in the game of lawn bowls.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The action of bowling a ball.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in the plural, but used with a singular verb) The game of bowls.",
              synonyms: ["lawn bowling", "lawn bowls"],
              antonyms: [],
            },
          ],
          synonyms: ["lawn bowling", "lawn bowls"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To roll or throw (a ball) in the correct manner in cricket and similar games and sports.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To throw the ball (in cricket and similar games and sports).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To roll or carry smoothly on, or as on, wheels.",
              synonyms: [],
              antonyms: [],
              example: "We were bowled rapidly along the road.",
            },
            {
              definition: "To pelt or strike with anything rolled.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A precision sport where the goal is to roll biased balls (weighted on one side, and called bowls) closer to a smaller white ball (the jack or kitty) than one's opponent is able to do.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A ball used in the game of bowls",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bowl",
        "https://en.wiktionary.org/wiki/bowls",
      ],
    },
  ],
  haste: [
    {
      word: "haste",
      phonetic: "/heɪst/",
      phonetics: [
        {
          text: "/heɪst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/haste-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749861",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Speed; swiftness; dispatch.",
              synonyms: [],
              antonyms: [],
              example: "We were running late so we finished our meal in haste.",
            },
            {
              definition:
                "Urgency; sudden excitement of feeling or passion; precipitance; vehemence.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To urge onward; to hasten.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To move with haste.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["hurry", "rush", "scamper", "scramble", "scurry"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/haste"],
    },
  ],
  scope: [
    {
      word: "scope",
      phonetic: "/ˈskoʊp/",
      phonetics: [
        {
          text: "/ˈskoʊp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scope-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651467",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The breadth, depth or reach of a subject; a domain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A device used in aiming a projectile, through which the person aiming looks at the intended target.",
              synonyms: ["telescopic sight"],
              antonyms: [],
            },
            {
              definition: "Opportunity; broad range; degree of freedom.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The region of program source code in which a given identifier is meaningful, or a given object can be accessed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The shortest sub-wff of which a given instance of a logical connective is a part.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The region of an utterance to which some modifying element applies.",
              synonyms: [],
              antonyms: [],
              example: "the scope of an adverb",
            },
            {
              definition: "A periscope, telescope, microscope or oscilloscope.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any medical procedure that ends in the suffix -scopy, such as endoscopy, colonoscopy, bronchoscopy, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["telescopic sight"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To perform a cursory investigation of; scope out.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To perform any medical procedure that ends in the suffix -scopy, such as endoscopy, colonoscopy, bronchoscopy, etc.",
              synonyms: [],
              antonyms: [],
              example:
                "The surgeon will scope the football player's knee to repair damage to a ligament.",
            },
            {
              definition:
                "To limit (an object or variable) to a certain region of program source code.",
              synonyms: [],
              antonyms: [],
              example:
                "If we locally scope the user's login name, it won't be accessible from outside this function.",
            },
            {
              definition: "To examine under a microscope.",
              synonyms: [],
              antonyms: [],
              example:
                "The entomologist explained that he could not tell what species of springtail we were looking at without scoping it.",
            },
            {
              definition: "To observe a bird using a spotting scope.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scope"],
    },
    {
      word: "scope",
      phonetic: "/ˈskoʊp/",
      phonetics: [
        {
          text: "/ˈskoʊp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scope-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651467",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A bundle, as of twigs.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scope"],
    },
  ],
  deals: [
    {
      word: "deals",
      phonetic: "/diːlz/",
      phonetics: [{ text: "/diːlz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A division, a portion, a share.",
              synonyms: [],
              antonyms: [],
              example: "We gave three deals of grain in tribute to the king.",
            },
            {
              definition:
                "(often followed by of) An indefinite quantity or amount; a lot (now usually qualified by great or good).",
              synonyms: [
                "batch",
                "flock",
                "good deal",
                "great deal",
                "hatful",
                "heap",
                "load",
                "lot",
                "mass",
                "mess",
                "mickle",
                "mint",
                "muckle",
                "peck",
                "pile",
                "plenty",
                "pot",
                "quite a little",
                "raft",
                "sight",
                "slew",
                "spate",
                "stack",
                "tidy sum",
                "wad",
                "whole lot",
                "whole slew",
              ],
              antonyms: [],
            },
          ],
          synonyms: [
            "allotment",
            "apportionment",
            "distribution",
            "doling out",
            "sharing",
            "batch",
            "flock",
            "good deal",
            "great deal",
            "hatful",
            "heap",
            "load",
            "lot",
            "mass",
            "mess",
            "mickle",
            "mint",
            "muckle",
            "peck",
            "pile",
            "plenty",
            "pot",
            "quite a little",
            "raft",
            "sight",
            "slew",
            "spate",
            "stack",
            "tidy sum",
            "wad",
            "whole lot",
            "whole slew",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An act of dealing or sharing out.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The distribution of cards to players; a player's turn for this.",
              synonyms: [],
              antonyms: [],
              example: "I believe it's your deal.",
            },
            {
              definition:
                "A particular instance of buying or selling; a transaction",
              synonyms: [],
              antonyms: [],
              example:
                "We need to finalise the deal with Henderson by midnight.",
            },
            {
              definition:
                "Specifically, a transaction offered which is financially beneficial; a bargain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An agreement between parties; an arrangement",
              synonyms: [],
              antonyms: [],
              example: "He made a deal with the devil.",
            },
            {
              definition: "A situation, occasion, or event.",
              synonyms: [],
              antonyms: [],
              example: "What's the deal?",
            },
            {
              definition: "A thing, an unspecified or unidentified object.",
              synonyms: [],
              antonyms: [],
              example: "The deal with four tines is called a pitchfork.",
            },
          ],
          synonyms: [
            "bargain",
            "steal",
            "contract",
            "pact",
            "hand",
            "business deal",
            "sale",
            "trade",
            "transaction",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To distribute among a number of recipients, to give out as one’s portion or share.",
              synonyms: [],
              antonyms: [],
              example:
                "The fighting is over; now we deal out the spoils of victory.",
            },
            {
              definition: "To administer or give out, as in small portions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To distribute cards to the players in a game.",
              synonyms: [],
              antonyms: [],
              example: "I was dealt four aces.",
            },
            {
              definition: "To pitch.",
              synonyms: [],
              antonyms: [],
              example:
                "The whole crowd waited for him to deal a real humdinger.",
            },
            {
              definition: "To have dealings or business.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To conduct oneself, to behave.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To take action; to act.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To trade professionally (followed by in).",
              synonyms: [],
              antonyms: [],
              example: "She deals in gold.",
            },
            {
              definition: "To sell, especially to sell illicit drugs.",
              synonyms: [],
              antonyms: [],
              example: "This club takes a dim view of members who deal drugs.",
            },
            { definition: "To be concerned with.", synonyms: [], antonyms: [] },
            {
              definition: "To handle, to manage, to cope.",
              synonyms: [],
              antonyms: [],
              example: "I can't deal with this.",
            },
          ],
          synonyms: [
            "administer",
            "allot",
            "deal out",
            "dish out",
            "dispense",
            "distribute",
            "dole out",
            "hand out",
            "lot",
            "mete out",
            "parcel out",
            "shell out",
            "pitch",
            "throw",
            "apportion",
            "divvy up",
            "portion out",
            "share",
            "share out",
            "sell",
            "bargain",
            "sell",
            "trade",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Wood that is easy to saw (from conifers such as pine or fir).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A plank of softwood (fir or pine board).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A wooden board or plank, usually between 12 or 14 feet in length, traded as a commodity in shipbuilding.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/deal",
        "https://en.wiktionary.org/wiki/deals",
      ],
    },
  ],
  knots: [
    {
      word: "knots",
      phonetic: "/nɒts/",
      phonetics: [
        { text: "/nɒts/", audio: "" },
        {
          text: "/nɑts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/knots-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453570",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A looping of a piece of string or of any other long, flexible material that cannot be untangled without passing one or both ends of the material through its loops.",
              synonyms: [],
              antonyms: [],
              example:
                "Climbers must make sure that all knots are both secure and of types that will not weaken the rope.",
            },
            {
              definition: "(of hair, etc) A tangled clump.",
              synonyms: [],
              antonyms: [],
              example:
                "The nurse was brushing knots from the protesting child's hair.",
            },
            { definition: "A maze-like pattern.", synonyms: [], antonyms: [] },
            {
              definition:
                "A non-self-intersecting closed curve in (e.g., three-dimensional) space that is an abstraction of a knot (in sense 1 above).",
              synonyms: [],
              antonyms: [],
              example:
                "A knot can be defined as a non-self-intersecting broken line whose endpoints coincide: when such a knot is constrained to lie in a plane, then it is simply a polygon.",
            },
            {
              definition: "A difficult situation.",
              synonyms: [],
              antonyms: [],
              example:
                "I got into a knot when I inadvertently insulted a policeman.",
            },
            {
              definition:
                "The whorl left in lumber by the base of a branch growing out of the tree's trunk.",
              synonyms: [],
              antonyms: [],
              example:
                "When preparing to tell stories at a campfire, I like to set aside a pile of pine logs with lots of knots, since they burn brighter and make dramatic pops and cracks.",
            },
            {
              definition:
                "Local swelling in a tissue area, especially skin, often due to injury.",
              synonyms: [],
              antonyms: [],
              example:
                "Jeremy had a knot on his head where he had bumped it on the bedframe.",
            },
            {
              definition: "A protuberant joint in a plant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any knob, lump, swelling, or protuberance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The swelling of the bulbus glandis in members of the dog family, Canidae",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The point on which the action of a story depends; the gist of a matter.",
              synonyms: [],
              antonyms: [],
              example: "the knot of the tale",
            },
            { definition: "A node.", synonyms: [], antonyms: [] },
            {
              definition: "A kind of epaulet; a shoulder knot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A group of people or things.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A bond of union; a connection; a tie.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A unit of speed, equal to one nautical mile per hour. (From the practice of counting the number of knots in the log-line (as it is paid out) in a standard time. Traditionally spaced at one every 1/120 of a mile.)",
              synonyms: [],
              antonyms: [],
              example: "Cedric claimed his old yacht could make 12 knots.",
            },
            { definition: "A nautical mile", synonyms: [], antonyms: [] },
            { definition: "The bulbus glandis", synonyms: [], antonyms: [] },
            {
              definition:
                "In omegaverse fiction, a bulbus glandis-like structure on the penis of a male alpha, which ties him to an omega during intercourse.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To form into a knot; to tie with a knot or knots.",
              synonyms: [],
              antonyms: [],
              example:
                "We knotted the ends of the rope to keep it from unravelling.",
            },
            {
              definition:
                "To form wrinkles in the forehead, as a sign of concentration, concern, surprise, etc.",
              synonyms: [],
              antonyms: [],
              example:
                "She knotted her brow in concentration while attempting to unravel the tangled strands.",
            },
            {
              definition: "To unite closely; to knit together.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To entangle or perplex; to puzzle.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To form knots.", synonyms: [], antonyms: [] },
            {
              definition: "To knit knots for a fringe.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "baffle",
            "flummox",
            "bind",
            "tie",
            "knit",
            "attach",
            "join",
            "put together",
          ],
          antonyms: ["loosen", "unbind", "unknot", "untie"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One of a variety of shore birds; the red-breasted sandpiper (variously Calidris canutus or Tringa canutus).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/knot",
        "https://en.wiktionary.org/wiki/knots",
      ],
    },
  ],
  moons: [
    {
      word: "moons",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(by extension of Moon) Any natural satellite of a planet.",
              synonyms: [],
              antonyms: [],
              example: "That's no moon, you idiot... it's a space station!",
            },
            {
              definition: "A month, particularly a lunar month.",
              synonyms: [],
              antonyms: [],
              example: "They stayed with their aunt and uncle for many moons.",
            },
            {
              definition: "A crescent-like outwork in a fortification.",
              synonyms: [],
              antonyms: [],
              example:
                "The moons surrounding the city walls were built in the sixteenth century.",
            },
            {
              definition:
                "The eighteenth trump/major arcana card of the Tarot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The thirty-second Lenormand card.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In hearts, the action of taking all the point cards in one hand.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "Moon",
            "calendar month",
            "lunar month",
            "month",
            "natural satellite",
            "satellite",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To display one's buttocks to, typically as a jest, insult, or protest.",
              synonyms: [],
              antonyms: [],
              example:
                "It was ill-advised of Sam to moon the photographer during the shoot.",
            },
            {
              definition:
                "(usually followed by over or after) To fuss over something adoringly; to be infatuated with someone.",
              synonyms: [],
              antonyms: [],
              example: "Sarah mooned over Sam's photograph for months.",
            },
            {
              definition: "To spend time idly, absent-mindedly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To expose to the rays of the Moon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To adorn with moons or crescents.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(cryptocurrency) Of a coin or token: to rise in price rapidly.",
              synonyms: [],
              antonyms: [],
              example:
                "It is impractical if a currency moons and plummets often.",
            },
            { definition: "To shoot the moon.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/moon",
        "https://en.wiktionary.org/wiki/moons",
      ],
    },
  ],
  essay: [
    {
      word: "essay",
      phonetic: "/ˈɛˌseɪ/",
      phonetics: [
        { text: "/ˈɛˌseɪ/", audio: "" },
        {
          text: "/ˈɛˌseɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/essay-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769385",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(authorship) A written composition of moderate length, exploring a particular issue or subject.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A test, experiment; an assay.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An attempt.", synonyms: [], antonyms: [] },
            {
              definition:
                "A proposed design for a postage stamp or a banknote.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/essay"],
    },
    {
      word: "essay",
      phonetic: "/ɛˈseɪ/",
      phonetics: [
        { text: "/ɛˈseɪ/", audio: "" },
        { text: "/ɛˈseɪ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To try.", synonyms: [], antonyms: [] },
            {
              definition: "To move forth, as into battle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/essay"],
    },
  ],
  thump: [
    {
      word: "thump",
      phonetic: "/θʌmp/",
      phonetics: [
        {
          text: "/θʌmp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/thump-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80152027",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A blow that produces a muffled sound.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The sound of such a blow; a thud.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'Used to replace the vulgar or blasphemous element in "what the hell" and similar phrases.',
              synonyms: [],
              antonyms: [],
              example: "Where the thump have you been?!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To hit (someone or something) as if to make a thump.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause to make a thumping sound.",
              synonyms: [],
              antonyms: [],
              example: "The cat thumped its tail in irritation.",
            },
            { definition: "To thud or pound.", synonyms: [], antonyms: [] },
            {
              definition: "To throb with a muffled rhythmic sound.",
              synonyms: [],
              antonyms: [],
              example: "Dance music thumped from the nightclub entrance.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/thump"],
    },
  ],
  hangs: [
    {
      word: "hangs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To be or remain suspended.",
              synonyms: [],
              antonyms: [],
              example: "The lights hung from the ceiling.",
            },
            {
              definition: "To float, as if suspended.",
              synonyms: [],
              antonyms: [],
              example: "The smoke hung in the room.",
            },
            {
              definition:
                "(of a ball in cricket, tennis, etc.) To rebound unexpectedly or unusually slowly, due to backward spin on the ball or imperfections of the ground.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To hold or bear in a suspended or inclined manner or position instead of erect.",
              synonyms: [],
              antonyms: [],
              example: "He hung his head in shame.",
            },
            {
              definition:
                "To cause (something) to be suspended, as from a hook, hanger, hinges or the like.",
              synonyms: [],
              antonyms: [],
              example: "Hang those lights from the ceiling.",
            },
            {
              definition: "To execute (someone) by suspension from the neck.",
              synonyms: [],
              antonyms: [],
              example: "The culprits were hanged from the nearest tree.",
            },
            {
              definition:
                "To be executed by suspension by one's neck from a gallows, a tree, or other raised bar, attached by a rope tied into a noose.",
              synonyms: [],
              antonyms: [],
              example: "You will hang for this, my friend.",
            },
            {
              definition: "(used in maledictions) To damn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To loiter, hang around, to spend time idly.",
              synonyms: [],
              antonyms: [],
              example:
                "Are you busy, or can you hang with me?  I didn't see anything, officer. I was just hanging.",
            },
            {
              definition: "To exhibit (an object) by hanging.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To apply (wallpaper or drywall to a wall).",
              synonyms: [],
              antonyms: [],
              example: "Let's hang this cute animal design in the nursery.",
            },
            {
              definition: "To decorate (something) with hanging objects.",
              synonyms: [],
              antonyms: [],
              example: "Let's hang the nursery with some new wallpaper.",
            },
            {
              definition: "To remain persistently in one's thoughts.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To prevent from reaching a decision, especially by refusing to join in a verdict that must be unanimous.",
              synonyms: [],
              antonyms: [],
              example: "One obstinate juror can hang a jury.",
            },
            {
              definition:
                "To stop responding to manual input devices such as keyboard and mouse.",
              synonyms: [],
              antonyms: [],
              example:
                "The computer has hung again. Not even pressing ++ works.  When I push this button the program hangs.",
            },
            {
              definition:
                "To cause (a program or computer) to stop responding.",
              synonyms: [],
              antonyms: [],
              example: "The program has a bug that can hang the system.",
            },
            {
              definition: "To cause (a piece) to become vulnerable to capture.",
              synonyms: [],
              antonyms: [],
              example: "If you move there, you'll hang your rook.",
            },
            {
              definition: "To be vulnerable to capture.",
              synonyms: [],
              antonyms: [],
              example:
                "In this standard opening position White has to be careful because the pawn on e4 hangs.",
            },
            {
              definition: "Of a pitcher, to throw a hittable off-speed pitch.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "put up",
            "gallows",
            "swing",
            "take a ride to Tyburn",
            "dangle",
            "suspended",
            "suspend",
            "freeze",
            "lock up",
            "freeze",
            "lock up",
            "bedeck",
            "deck",
            "decorate",
            "lynch",
            "string up",
            "exhibit",
            "show",
            "float",
            "hover",
            "drop",
            "lower",
            "hang about",
            "hang around",
            "loiter",
            "hook",
            "hook up",
            "hang up",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hang",
        "https://en.wiktionary.org/wiki/hangs",
      ],
    },
  ],
  bliss: [
    {
      word: "bliss",
      phonetic: "/blɪs/",
      phonetics: [{ text: "/blɪs/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Perfect happiness", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bliss"],
    },
  ],
  dealt: [
    {
      word: "dealt",
      phonetic: "/ˈdɛlt/",
      phonetics: [
        {
          text: "/ˈdɛlt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dealt-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=592008",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To distribute among a number of recipients, to give out as one’s portion or share.",
              synonyms: [],
              antonyms: [],
              example:
                "The fighting is over; now we deal out the spoils of victory.",
            },
            {
              definition: "To administer or give out, as in small portions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To distribute cards to the players in a game.",
              synonyms: [],
              antonyms: [],
              example: "I was dealt four aces.",
            },
            {
              definition: "To pitch.",
              synonyms: [],
              antonyms: [],
              example:
                "The whole crowd waited for him to deal a real humdinger.",
            },
            {
              definition: "To have dealings or business.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To conduct oneself, to behave.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To take action; to act.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To trade professionally (followed by in).",
              synonyms: [],
              antonyms: [],
              example: "She deals in gold.",
            },
            {
              definition: "To sell, especially to sell illicit drugs.",
              synonyms: [],
              antonyms: [],
              example: "This club takes a dim view of members who deal drugs.",
            },
            { definition: "To be concerned with.", synonyms: [], antonyms: [] },
            {
              definition: "To handle, to manage, to cope.",
              synonyms: [],
              antonyms: [],
              example: "I can't deal with this.",
            },
          ],
          synonyms: [
            "administer",
            "allot",
            "deal out",
            "dish out",
            "dispense",
            "distribute",
            "dole out",
            "hand out",
            "lot",
            "mete out",
            "parcel out",
            "shell out",
            "pitch",
            "throw",
            "apportion",
            "divvy up",
            "portion out",
            "share",
            "share out",
            "sell",
            "bargain",
            "sell",
            "trade",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/deal",
        "https://en.wiktionary.org/wiki/dealt",
      ],
    },
  ],
  gains: [
    {
      word: "gains",
      phonetic: "/ɡeɪnz/",
      phonetics: [{ text: "/ɡeɪnz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act of gaining; acquisition.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "What is gained.", synonyms: [], antonyms: [] },
            {
              definition: "The factor by which a signal is multiplied.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["loss"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To acquire possession of.",
              synonyms: [],
              antonyms: [],
              example: "Looks like you've gained a new friend.",
            },
            {
              definition:
                "To have or receive advantage or profit; to acquire gain; to grow rich; to advance in interest, health, or happiness; to make progress.",
              synonyms: [],
              antonyms: [],
              example: "The sick man gains daily.",
            },
            {
              definition:
                "To come off winner or victor in; to be successful in; to obtain by competition.",
              synonyms: [],
              antonyms: [],
              example: "to gain a battle; to gain a case at law",
            },
            { definition: "To increase.", synonyms: [], antonyms: [] },
            {
              definition:
                "To be more likely to catch or overtake an individual.",
              synonyms: [],
              antonyms: [],
              example: "I'm gaining (on you).",
            },
            {
              definition: "To reach.",
              synonyms: [],
              antonyms: [],
              example: "to gain the top of a mountain",
            },
            {
              definition:
                "To draw into any interest or party; to win to one's side; to conciliate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To put on weight.",
              synonyms: [],
              antonyms: [],
              example: "I've been gaining.",
            },
            {
              definition: "(of a clock or watch) To run fast.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A square or bevelled notch cut out of a girder, binding joist, or other timber which supports a floor beam, so as to receive the end of the floor beam.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gain",
        "https://en.wiktionary.org/wiki/gains",
      ],
    },
  ],
  bombs: [
    {
      word: "bombs",
      phonetic: "/bɒmz/",
      phonetics: [{ text: "/bɒmz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An explosive device used or intended as a weapon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A failure; an unpopular commercial product.",
              synonyms: [],
              antonyms: [],
              example: "box office bomb",
            },
            {
              definition: "A large amount of money.",
              synonyms: ["fortune", "packet", "pretty penny"],
              antonyms: [],
              example: "cost a bomb",
            },
            {
              definition: "(social) Something highly effective or attractive.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cyclone whose central pressure drops at an average rate of at least one millibar per hour for at least 24 hours.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A heavy-walled container designed to permit chemical reactions under high pressure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A great booming noise; a hollow sound.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A woman’s breast.", synonyms: [], antonyms: [] },
            {
              definition:
                "A professional wrestling throw in which an opponent is lifted and then slammed back-first down to the mat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A recreational drug ground up, wrapped, and swallowed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An act of jumping into water while keeping one's arms and legs tucked into the body, as in a squatting position, to maximize splashing.",
              synonyms: ["cannonball"],
              antonyms: [],
            },
          ],
          synonyms: ["cannonball", "fortune", "packet", "pretty penny"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To attack using one or more bombs; to bombard.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To fail dismally.", synonyms: [], antonyms: [] },
            {
              definition:
                "To jump into water in a squatting position, with the arms wrapped around the legs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To sound; to boom; to make a humming or buzzing sound.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cover an area in many graffiti tags.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To add an excessive amount of chlorine to a pool when it has not been maintained properly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make oneself drunk.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(especially with along, down, up etc.) To move at high speed.",
              synonyms: [],
              antonyms: [],
              example: "I was bombing down the road on my motorbike.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bomb",
        "https://en.wiktionary.org/wiki/bombs",
      ],
    },
  ],
  clown: [
    {
      word: "clown",
      phonetic: "/klaʊn/",
      phonetics: [
        {
          text: "/klaʊn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/clown-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89013034",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A slapstick performance artist often associated with a circus and usually characterized by bright, oversized clothing, a red nose, face paint, and a brightly colored wig.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person who acts in a silly fashion.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A stupid person.", synonyms: [], antonyms: [] },
            {
              definition:
                "A man of coarse nature and manners; an awkward fellow; an illbred person; a boor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One who works upon the soil; a rustic; a churl; a yokel.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["buffoon", "fool"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To act in a silly or playful fashion.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To ridicule.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/clown"],
    },
  ],
  palms: [
    {
      word: "palms",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/palms-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651260",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of various evergreen trees from the family Palmae or Arecaceae, which are mainly found in the tropics.",
              synonyms: ["palm tree"],
              antonyms: [],
            },
            {
              definition:
                "A branch or leaf of the palm, anciently borne or worn as a symbol of victory or rejoicing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) Triumph; victory.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of 23 awards that can be earned after obtaining the Eagle Scout rank, but generally only before turning 18 years old.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["palm tree"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The inner and somewhat concave part of the human hand that extends from the wrist to the bases of the fingers.",
              synonyms: ["loof"],
              antonyms: ["hardel"],
            },
            {
              definition:
                "The corresponding part of the forefoot of a lower mammal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A linear measure equal either to the breadth of the hand or to its length from the wrist to the ends of the fingers; a hand; used in measuring a horse's height.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(sailmaking) A metallic disk attached to a strap and worn in the palm of the hand; used to push the needle through the canvas, in sewing sails, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The broad flattened part of an antler, as of a full-grown fallow deer; so called as resembling the palm of the hand with its protruding fingers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The flat inner face of an anchor fluke.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["loof"],
          antonyms: ["hardel"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To hold or conceal something in the palm of the hand, e.g, for an act of sleight of hand or to steal something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To hold something without bending the fingers significantly.",
              synonyms: [],
              antonyms: [],
              example: "John palmed the ball.",
            },
            {
              definition: "To move something with the palm of the hand.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/palm",
        "https://en.wiktionary.org/wiki/palms",
      ],
    },
  ],
  cones: [
    {
      word: "cones",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A surface of revolution formed by rotating a segment of a line around another line that intersects the first line.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A solid of revolution formed by rotating a triangle around one of its altitudes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A space formed by taking the direct product of a given space with a closed interval and identifying all of one end to a point.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Anything shaped like a cone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The fruit of a conifer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cone-shaped flower head of various plants, such as banksias and proteas.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An ice cream cone.", synonyms: [], antonyms: [] },
            { definition: "A traffic cone", synonyms: [], antonyms: [] },
            {
              definition:
                "A unit of volume, applied solely to marijuana and only while it is in a smokable state; roughly 1.5 cubic centimetres, depending on use.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of the small cone-shaped structures in the retina.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The bowl piece on a bong.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The process of smoking cannabis in a bong.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A cone-shaped cannabis joint.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A passenger on a cruise ship (so-called by employees after traffic cones, from the need to navigate around them)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An object V together with an arrow going from V to each object of a diagram such that for any arrow A in the diagram, the pair of arrows from V which subtend A also commute with it. (Then V can be said to be the cone’s vertex and the diagram which the cone subtends can be said to be its base.)",
              synonyms: [],
              antonyms: [],
              example:
                "A cone is an object (the apex) and a natural transformation from a constant functor (whose image is the apex of the cone and its identity morphism) to a diagram functor. Its components are projections from the apex to the objects of the diagram and it has a “naturality triangle” for each morphism in the diagram. (A “naturality triangle” is just a naturality square which is degenerate at its apex side.)",
            },
            {
              definition: "A shell of the genus Conus, having a conical form.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A set of formal languages with certain desirable closure properties, in particular those of the regular languages, the context-free languages and the recursively enumerable languages.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["conical surface", "cornet", "ice cream cone"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To fashion into the shape of a cone.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To form a cone shape.", synonyms: [], antonyms: [] },
            {
              definition:
                '(frequently followed by "off") To segregate or delineate an area using traffic cones',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cone",
        "https://en.wiktionary.org/wiki/cones",
      ],
    },
  ],
  roast: [
    {
      word: "roast",
      phonetic: "/ɹəʊst/",
      phonetics: [
        { text: "/ɹəʊst/", audio: "" },
        {
          text: "/ɹoʊst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/roast-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769753",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A cut of meat suited to roasting",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A meal consisting of roast foods.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The degree to which something, especially coffee, is roasted.",
              synonyms: [],
              antonyms: [],
              example:
                "Dark roast means that the coffee bean has been roasted to a higher temperature and for a longer period of time than in light roast.",
            },
            {
              definition:
                "A comical event, originally fraternal, where a person is subjected to verbal attack, yet may be praised by sarcasm and jokes.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cook food by heating in an oven or over a fire without covering, resulting in a crisp, possibly even slightly charred appearance.",
              synonyms: [],
              antonyms: [],
              example: "to roast meat on a spit",
            },
            {
              definition:
                "To cook by surrounding with hot embers, ashes, sand, etc.",
              synonyms: [],
              antonyms: [],
              example: "to roast a potato in ashes",
            },
            {
              definition:
                "To process by drying through exposure to sun or artificial heat",
              synonyms: [],
              antonyms: [],
              example: "Coffee beans need roasting before use.",
            },
            {
              definition: "To heat to excess; to heat violently; to burn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To admonish someone vigorously",
              synonyms: [],
              antonyms: [],
              example:
                "I’m late home for the fourth time this week; my mate will really roast me this time.",
            },
            {
              definition:
                "To subject to bantering, severely criticize, sometimes as a comedy routine.",
              synonyms: [],
              antonyms: [],
              example:
                "The class clown enjoys being roasted by mates as well as staff.",
            },
            {
              definition:
                "To dissipate by heat the volatile parts of, as ores.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having been cooked by roasting.",
              synonyms: ["roasted"],
              antonyms: [],
            },
            {
              definition:
                "Subjected to roasting, bantered, severely criticized.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["roasted"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/roast"],
    },
  ],
  tidal: [
    {
      word: "tidal",
      phonetic: "/ˈtaɪd(ə)l/",
      phonetics: [{ text: "/ˈtaɪd(ə)l/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Relating to tides", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tidal"],
    },
  ],
  bored: [
    {
      word: "bored",
      phonetic: "/bɔːd/",
      phonetics: [
        { text: "/bɔːd/", audio: "" },
        { text: "/bɔːɹd/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To inspire boredom in somebody.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make a hole through something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make a hole with, or as if with, a boring instrument; to cut a circular hole by the rotary motion of a tool.",
              synonyms: [],
              antonyms: [],
              example: "An insect bores into a tree.",
            },
            {
              definition:
                "To form or enlarge (something) by means of a boring instrument or apparatus.",
              synonyms: [],
              antonyms: [],
              example:
                "to bore a steam cylinder or a gun barrel; to bore a hole",
            },
            {
              definition:
                "To make (a passage) by laborious effort, as in boring; to force a narrow and difficult passage through.",
              synonyms: [],
              antonyms: [],
              example: "to bore one's way through a crowd",
            },
            {
              definition:
                "To be pierced or penetrated by an instrument that cuts as it turns.",
              synonyms: [],
              antonyms: [],
              example: "This timber does not bore well.",
            },
            {
              definition:
                "To push forward in a certain direction with laborious effort.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a horse) To shoot out the nose or toss it in the air.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To fool; to trick.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: ["interest"],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Suffering from boredom; mildly annoyed and restless through having nothing to do",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Uninterested, without attention",
              synonyms: [],
              antonyms: [],
              example:
                "The piano teacher's bored look indicated he wasn't paying much attention to his pupil's lackluster rendition of Mozart's Requiem",
            },
            {
              definition:
                "Perforated by a hole or holes (through bioerosion or other)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bore",
        "https://en.wiktionary.org/wiki/bored",
      ],
    },
  ],
  chant: [
    {
      word: "chant",
      phonetic: "/tʃænt/",
      phonetics: [
        { text: "/tʃænt/", audio: "" },
        {
          text: "/tʃænt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chant-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2650739",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Type of singing done generally without instruments and harmony.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A short and simple melody, divided into two parts by double bars, to which unmetrical psalms, etc., are sung or recited. It is the most ancient form of choral music.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Twang; manner of speaking; a canting tone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A repetitive song, typically an incantation or part of a ritual.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To sing, especially without instruments, and as applied to monophonic and pre-modern music.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To sing or intone sacred text.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To utter or repeat in a strongly rhythmical manner, especially as a group.",
              synonyms: [],
              antonyms: [],
              example: "The football fans chanted insults at the referee.",
            },
            {
              definition:
                "To sell horses fraudulently, exaggerating their merits.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chant"],
    },
  ],
  acids: [
    {
      word: "acids",
      phonetic: "/ˈæ.sɪdz/",
      phonetics: [{ text: "/ˈæ.sɪdz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A sour substance.", synonyms: [], antonyms: [] },
            {
              definition:
                "Any of several classes of compound having the following properties:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Lysergic acid diethylamide (LSD)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["alkali", "base"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/acid",
        "https://en.wiktionary.org/wiki/acids",
      ],
    },
  ],
  dough: [
    {
      word: "dough",
      phonetic: "/dʌf/",
      phonetics: [
        { text: "/dʌf/", audio: "" },
        {
          text: "/dəʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dough-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=797546",
        },
        {
          text: "/doʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dough-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=266993",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A thick, malleable substance made by mixing flour with other ingredients such as water, eggs, and/or butter, that is made into a particular form and then baked.",
              synonyms: [],
              antonyms: [],
              example: "Pizza dough is very stretchy.",
            },
            {
              definition: "Money.",
              synonyms: [],
              antonyms: [],
              example:
                "Hey Martin, we are playing a hold'em card game for some dough, would you like to join?",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make into dough.",
              synonyms: [],
              antonyms: [],
              example:
                "The flour was doughed with a suitable quantity of water.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dough"],
    },
  ],
  camps: [
    {
      word: "camps",
      phonetic: "/kæmps/",
      phonetics: [{ text: "/kæmps/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An outdoor place acting as temporary accommodation in tents or other temporary structures.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An organised event, often taking place in tents or temporary accommodation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A base of a military group, not necessarily temporary.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A single hut or shelter.",
              synonyms: [],
              antonyms: [],
              example: "a hunter's camp",
            },
            {
              definition: "The company or body of persons encamped.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A group of people with the same strong ideals or political leanings.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Campus", synonyms: [], antonyms: [] },
            { definition: "A summer camp.", synonyms: [], antonyms: [] },
            { definition: "A prison.", synonyms: [], antonyms: [] },
            {
              definition:
                "A mound of earth in which potatoes and other vegetables are stored for protection against frost",
              synonyms: ["burrow", "pie"],
              antonyms: [],
            },
            { definition: "Conflict; battle.", synonyms: [], antonyms: [] },
            {
              definition:
                "An ancient game of football, played in some parts of England.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["burrow", "pie"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To live in a tent or similar temporary accommodation.",
              synonyms: [],
              antonyms: [],
              example: "We're planning to camp in the field until Sunday.",
            },
            { definition: "To set up a camp.", synonyms: [], antonyms: [] },
            {
              definition: "To afford rest or lodging for.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To stay in an advantageous location in a video game, such as next to a power-up's spawning point or in order to guard an area.",
              synonyms: [],
              antonyms: [],
              example: "Go and camp the flag for the win.",
            },
            {
              definition:
                "To fight; contend in battle or in any kind of contest; to strive with others in doing anything; compete.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To wrangle; argue.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/camp",
        "https://en.wiktionary.org/wiki/camps",
      ],
    },
  ],
  swore: [
    {
      word: "swore",
      phonetic: "/swɔː/",
      phonetics: [
        { text: "/swɔː/", audio: "" },
        {
          text: "/swɔɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/swore-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2707614",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To take an oath, to promise.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To use offensive, profane, or obscene language.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/swear",
        "https://en.wiktionary.org/wiki/swore",
      ],
    },
  ],
  lover: [
    {
      word: "lover",
      phonetic: "/ˈlʌvə/",
      phonetics: [
        { text: "/ˈlʌvə/", audio: "" },
        {
          text: "/ˈlʌvɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lover-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1676801",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One who loves and cares for another person in a romantic way; a sweetheart, love, soulmate, boyfriend, girlfriend or spouse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sexual partner, especially one with whom someone is having an affair.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person who loves something.",
              synonyms: [],
              antonyms: [],
              example: "a lover of fine wines",
            },
            {
              definition:
                '(with "my") An informal term of address for any friend.',
              synonyms: [],
              antonyms: [],
              example: "All right, me lover?",
            },
          ],
          synonyms: [
            "love",
            "love interest",
            "significant other",
            "spouse",
            "sweetheart",
            "connoisseur",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lover"],
    },
    {
      word: "lover",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A type of turret on the roof of certain medieval buildings designed to allow ventilation or the admission of light.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(chiefly in plural) A series of sloping overlapping slats or boards which admit air and light but exclude rain etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of a system of slits, as in the hood of an automobile, for ventilation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/louver",
        "https://en.wiktionary.org/wiki/lover",
      ],
    },
  ],
  hooks: [
    {
      word: "hooks",
      phonetic: "/hʊks/",
      phonetics: [{ text: "/hʊks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A rod bent into a curved shape, typically with one end free and the other end secured to a rope or other attachment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A barbed metal hook used for fishing; a fishhook.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various hook-shaped agricultural implements such as a billhook.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The curved needle used in the art of crochet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The part of a hinge which is fixed to a post, and on which a door or gate hangs and turns.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A loop shaped like a hook under certain written letters, for example, g and j.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A tie-in to a current event or trend that makes a news story or editorial relevant and timely.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A snare; a trap.", synonyms: [], antonyms: [] },
            {
              definition:
                "(in the plural) The projecting points of the thighbones of cattle; called also hook bones.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Removal or expulsion from a group or activity",
              synonyms: [],
              antonyms: [],
              example:
                "He is not handling this job, so we're giving him the hook.",
            },
            {
              definition: "A field sown two years in succession.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(authorship) A brief, punchy opening statement intended to get attention from an audience, reader, or viewer, and make them want to continue to listen to a speech, read a book, or watch a play.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(authorship) A gimmick or element of a creative work intended to be attention-grabbing for the audience; a compelling idea for a story that will be sure to attract people's attention.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A finesse.", synonyms: [], antonyms: [] },
            {
              definition: "A jack (the playing card).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A spit or narrow cape of sand or gravel turned landward at the outer end, such as Sandy Hook in New Jersey.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A catchy musical phrase which forms the basis of a popular song.",
              synonyms: [],
              antonyms: [],
              example: "The song's hook snared me.",
            },
            { definition: "A ship's anchor.", synonyms: [], antonyms: [] },
            {
              definition:
                "Part of a system's operation that can be intercepted to change or augment its behaviour.",
              synonyms: [],
              antonyms: [],
              example:
                "We've added hooks to allow undefined message types to be handled with custom code.",
            },
            {
              definition:
                "(Scrabble) An instance of playing a word perpendicular to a word already on the board, adding a letter to the start or the end of the word to form a new word.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A diacritical mark shaped like the upper part of a question mark, as in ỏ.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A háček.", synonyms: [], antonyms: [] },
            {
              definition: "Senses relating to sports.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To attach a hook to.",
              synonyms: [],
              antonyms: [],
              example:
                "Hook the bag here, and the conveyor will carry it away.",
            },
            {
              definition: "To catch with a hook (hook a fish).",
              synonyms: [],
              antonyms: [],
              example:
                "He hooked a snake accidentally, and was so scared he dropped his rod into the water.",
            },
            {
              definition:
                "To work yarn into a fabric using a hook; to crochet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To insert in a curved way reminiscent of a hook.",
              synonyms: [],
              antonyms: [],
              example: "He hooked his fingers through his belt loops.",
            },
            {
              definition: "To ensnare or obligate someone, as if with a hook.",
              synonyms: [],
              antonyms: [],
              example: "A free trial is a good way to hook customers.",
            },
            { definition: "To steal.", synonyms: [], antonyms: [] },
            {
              definition: "To connect (hook into, hook together).",
              synonyms: [],
              antonyms: [],
              example:
                "If you hook your network cable into the jack, you'll be on the network.",
            },
            {
              definition:
                "(usually in passive) To make addicted; to captivate.",
              synonyms: [],
              antonyms: [],
              example: "He had gotten hooked on cigarettes in his youth.",
            },
            { definition: "To play a hook shot.", synonyms: [], antonyms: [] },
            {
              definition:
                "To succeed in heeling the ball back out of a scrum (used particularly of the team's designated hooker).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To engage in the illegal maneuver of hooking (i.e., using the hockey stick to trip or block another player)",
              synonyms: [],
              antonyms: [],
              example:
                "The opposing team's forward hooked me, but the referee didn't see it, so no penalty.",
            },
            {
              definition:
                "To swerve a ball; kick a ball so it swerves or bends.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To engage in prostitution.",
              synonyms: [],
              antonyms: [],
              example:
                "I had a cheap flat in the bad part of town, and I could watch the working girls hooking from my bedroom window.",
            },
            {
              definition:
                "(Scrabble) To play a word perpendicular to another word by adding a single letter to the existing word.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To finesse.", synonyms: [], antonyms: [] },
            {
              definition:
                "To seize or pierce with the points of the horns, as cattle in attacking enemies; to gore.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To move or go with a sudden turn.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hook",
        "https://en.wiktionary.org/wiki/hooks",
      ],
    },
  ],
  males: [
    {
      word: "males",
      phonetic: "/meɪlz/",
      phonetics: [
        { text: "/meɪlz/", audio: "" },
        { text: "/meɪlz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One of the male (masculine) sex or gender.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["female"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/male",
        "https://en.wiktionary.org/wiki/males",
      ],
    },
  ],
  cocoa: [
    {
      word: "cocoa",
      phonetic: "/ˈkəʊ.kəʊ/",
      phonetics: [
        { text: "/ˈkəʊ.kəʊ/", audio: "" },
        {
          text: "/ˈkoʊ.koʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cocoa-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769862",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The dried and partially fermented fatty seeds of the cacao tree from which chocolate is made.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An unsweetened brown powder made from roasted, ground cocoa beans, used in making chocolate, and in cooking.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hot drink made with milk, cocoa powder, and sugar.",
              synonyms: [],
              antonyms: [],
              example: "Do you like cocoa?",
            },
            {
              definition: "A serving of this drink.",
              synonyms: [],
              antonyms: [],
              example: "I like to watch TV with a cocoa.",
            },
            {
              definition: "A light to medium brown colour.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["chocolate", "drinking chocolate", "hot chocolate"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Of a light to medium brown colour, like that of cocoa powder.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cocoa"],
    },
    {
      word: "cocoa",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Coconut palm.", synonyms: [], antonyms: [] },
            {
              definition: "Coconut, the fruit of the coconut palm.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/coco",
        "https://en.wiktionary.org/wiki/cocoa",
      ],
    },
  ],
  punch: [
    {
      word: "punch",
      phonetic: "/pʌntʃ/",
      phonetics: [
        {
          text: "/pʌntʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/punch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651359",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A hit or strike with one's fist.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Power, strength, energy.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Impact.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["oomph", "pep", "blow", "box", "bunch of fives"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To strike with one's fist.",
              synonyms: [],
              antonyms: [],
              example: "If she punches me, I'm gonna break her nose.",
            },
            { definition: "(of cattle) To herd.", synonyms: [], antonyms: [] },
            {
              definition:
                "To operate (a device or system) by depressing a button, key, bar, or pedal, or by similar means.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To enter (information) on a device or system.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To hit (a ball or similar object) with less than full force.",
              synonyms: [],
              antonyms: [],
              example: "He punched a hit into shallow left field.",
            },
            {
              definition:
                "To make holes in something (rail ticket, leather belt, etc)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To thrust against; to poke.",
              synonyms: [],
              antonyms: [],
              example: "to punch one with the end of a stick or the elbow",
            },
          ],
          synonyms: ["box", "slug"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/punch"],
    },
    {
      word: "punch",
      phonetic: "/pʌntʃ/",
      phonetics: [
        {
          text: "/pʌntʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/punch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651359",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A device, generally slender and round, used for creating holes in thin material, for driving an object through a hole in a containing object, or to stamp or emboss a mark or design on a surface.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A hole or opening created with a punch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(piledriving) An extension piece applied to the top of a pile; a dolly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A prop, as for the roof of a mine.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To employ a punch to create a hole in or stamp or emboss a mark on something.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To mark a ticket.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/punch"],
    },
    {
      word: "punch",
      phonetic: "/pʌntʃ/",
      phonetics: [
        {
          text: "/pʌntʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/punch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651359",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A beverage, generally containing a mixture of fruit juice and some other beverage, often alcoholic.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/punch"],
    },
    {
      word: "punch",
      phonetic: "/pʌntʃ/",
      phonetics: [
        {
          text: "/pʌntʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/punch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651359",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of various riodinid butterflies of the genus Dodona of Asia.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/punch"],
    },
  ],
  award: [
    {
      word: "award",
      phonetic: "/əˈwɔːd/",
      phonetics: [
        { text: "/əˈwɔːd/", audio: "" },
        {
          text: "/əˈwɔɹd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/award-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769603",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A judgment, sentence, or final decision. Specifically: The decision of arbitrators in a case submitted.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The paper containing the decision of arbitrators; that which is warded.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A trophy or medal; something that denotes an accomplishment, especially in a competition. A prize or honor based on merit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Australia, NZ, industrial relations) A negotiated minimum wage that is set for a particular trade or industry; an industrial award.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To give by sentence or judicial determination; to assign or apportion, after careful regard to the nature of the case; to adjudge",
              synonyms: [],
              antonyms: [],
              example: "the arbitrators awarded damages to the complainant",
            },
            {
              definition: "To determine; to make or grant an award.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To give (an award).",
              synonyms: ["bestow"],
              antonyms: [],
              example: "Four or five of these medals are awarded every year.",
            },
            {
              definition: "To give (a person) an award.",
              synonyms: [],
              antonyms: [],
              example: "He was awarded the Nobel Prize for Literature.",
            },
          ],
          synonyms: ["crown", "bestow"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/award"],
    },
  ],
  reins: [
    {
      word: "reins",
      phonetic: "/ɹeɪnz/",
      phonetics: [{ text: "/ɹeɪnz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A strap or rope attached to a bridle or bit, used to control a horse, animal or young child.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An instrument or means of curbing, restraining, or governing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To direct or stop a horse by using reins.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To restrain; to control; to check.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To obey directions given with the reins.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(chiefly in plural) A kidney.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The inward impulses; the affections and passions, formerly supposed to be located in the area of the kidneys.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rein",
        "https://en.wiktionary.org/wiki/reins",
      ],
    },
    {
      word: "reins",
      phonetic: "/ɹeɪnz/",
      phonetics: [{ text: "/ɹeɪnz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The kidneys.", synonyms: [], antonyms: [] },
            {
              definition: "The lower part of the back.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The loins.", synonyms: [], antonyms: [] },
            {
              definition:
                "The parts of a vault between the crown and the spring or abutment, including, and having especial reference to, the loading or filling behind the shell of the vault.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/reins"],
    },
  ],
  ninth: [
    {
      word: "ninth",
      phonetic: "/naɪnθ/",
      phonetics: [
        { text: "/naɪnθ/", audio: "" },
        {
          text: "/naɪnθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ninth-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1755183",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The person or thing in the ninth position.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One of nine equal parts of a whole.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The compound interval between any tone and the tone represented on the ninth degree of the staff above it, as between one of the scale and two of the octave above; the octave of the second, consisting of 13 or 14 semitones (called minor and major ninth).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To lose a ninth", synonyms: [], antonyms: [] },
            { definition: "To divide by nine", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "The ordinal form of the number nine.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["9th", "IX", "IXth"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ninth"],
    },
  ],
  noses: [
    {
      word: "noses",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/noses-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453622",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A protuberance on the face housing the nostrils, which are used to breathe or smell.",
              synonyms: [],
              antonyms: [],
              example: "She has a cold in the nose.",
            },
            {
              definition: "A snout, the nose of an animal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The tip of an object.",
              synonyms: [],
              antonyms: [],
              example:
                "the nose of a tea-kettle, a bellows, or a fighter plane",
            },
            {
              definition:
                "The bulge on the side of a piece of a jigsaw puzzle, that fits into the hole of its adjacent piece.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The length of a horse’s nose, used to indicate the distance between horses at the finish of a race, or any very close race.",
              synonyms: [],
              antonyms: [],
              example: "Red Rum only won by a nose.",
            },
            { definition: "A perfumer.", synonyms: [], antonyms: [] },
            {
              definition: "The power of smelling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Bouquet, the smell of something, especially wine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The skill in recognising bouquet.",
              synonyms: [],
              antonyms: [],
              example:
                "It is essential that a winetaster develops a good nose.",
            },
            {
              definition: "(by extension) Skill at finding information.",
              synonyms: [],
              antonyms: [],
              example: "A successful reporter has a nose for news.",
            },
            {
              definition: "A downward projection from a cornice.",
              synonyms: ["drip"],
              antonyms: [],
            },
            { definition: "An informer.", synonyms: ["nark"], antonyms: [] },
          ],
          synonyms: ["tab", "drip", "nark"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To move cautiously by advancing its front end.",
              synonyms: [],
              antonyms: [],
              example: "The ship nosed through the minefield.",
            },
            {
              definition: "To snoop.",
              synonyms: [],
              antonyms: [],
              example: "She was nosing around other people’s business.",
            },
            {
              definition: "To detect by smell or as if by smell.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To push with one's nose; to nuzzle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To defeat (as in a race or other contest) by a narrow margin; sometimes with out.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To utter in a nasal manner; to pronounce with a nasal twang.",
              synonyms: [],
              antonyms: [],
              example: "to nose a prayer",
            },
            {
              definition: "To furnish with a nose.",
              synonyms: [],
              antonyms: [],
              example: "to nose a stair tread",
            },
            {
              definition:
                "To confront; be closely face to face or opposite to.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/nose",
        "https://en.wiktionary.org/wiki/noses",
      ],
    },
  ],
  links: [
    {
      word: "links",
      phonetic: "/lɪŋks/",
      phonetics: [
        { text: "/lɪŋks/", audio: "" },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/links-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749885",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Some text or a graphic in an electronic document that can be activated to display another document or trigger an action.",
              synonyms: [],
              antonyms: [],
              example: "Click the hyperlink to go to the next page.",
            },
            {
              definition:
                "(by extension) An address, URL, or program that defines a hyperlink's function.",
              synonyms: [],
              antonyms: [],
              example: "Copy the hyperlink and paste it into an email.",
            },
          ],
          synonyms: ["link"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A connection between places, people, events, things, or ideas.",
              synonyms: [],
              antonyms: [],
              example: "The mayor’s assistant serves as the link to the media.",
            },
            {
              definition: "One element of a chain or other connected series.",
              synonyms: [],
              antonyms: [],
              example:
                "The third link of the silver chain needs to be resoldered.",
            },
            {
              definition: "The connection between buses or systems.",
              synonyms: [],
              antonyms: [],
              example: "A by-N-link is composed of N lanes.",
            },
            {
              definition: "A space comprising one or more disjoint knots.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A thin wild bank of land splitting two cultivated patches and often linking two hills.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An individual person or element in a system",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Anything doubled and closed like a link of a chain.",
              synonyms: [],
              antonyms: [],
              example: "a link of horsehair",
            },
            {
              definition: "A sausage that is not a patty.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(kinematics) Any one of the several elementary pieces of a mechanism, such as the fixed frame, or a rod, wheel, mass of confined liquid, etc., by which relative motion of other parts is produced and constrained.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any intermediate rod or piece for transmitting force or motion, especially a short connecting rod with a bearing at each end; specifically (in steam engines) the slotted bar, or connecting piece, to the opposite ends of which the eccentric rods are jointed, and by means of which the movement of the valve is varied, in a link motion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The length of one joint of Gunter's chain, being the hundredth part of it, or 7.92 inches, the chain being 66 feet in length.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bond of affinity, or a unit of valence between atoms; applied to a unit of chemical force or attraction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(plural) The windings of a river; the land along a winding stream.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["connection"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To connect two or more things.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a Web page) To contain a hyperlink to another page.",
              synonyms: [],
              antonyms: [],
              example: "My homepage links to my wife's.",
            },
            {
              definition:
                "To supply (somebody) with a hyperlink; to direct by means of a link.",
              synonyms: [],
              antonyms: [],
              example: "Haven't you seen his Web site? I'll link you to it.",
            },
            {
              definition: "To post a hyperlink to.",
              synonyms: [],
              antonyms: [],
              example: "Stop linking those unfunny comics all the time!",
            },
            {
              definition: "To demonstrate a correlation between two things.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To combine objects generated by a compiler into a single executable.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["affix", "attach", "join", "put together"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A torch, used to light dark streets.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To skip or trip along smartly; to go quickly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hyperlink",
        "https://en.wiktionary.org/wiki/link",
        "https://en.wiktionary.org/wiki/links",
      ],
    },
    {
      word: "links",
      phonetic: "/lɪŋks/",
      phonetics: [
        { text: "/lɪŋks/", audio: "" },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/links-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749885",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A golf course, especially one situated on dunes by the sea.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/links"],
    },
  ],
  drain: [
    {
      word: "drain",
      phonetic: "/dɹeɪn/",
      phonetics: [
        {
          text: "/dɹeɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/drain-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=856976",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A conduit allowing liquid to flow out of an otherwise contained volume; a plughole (UK)",
              synonyms: [],
              antonyms: [],
              example: "The drain in the kitchen sink is clogged.",
            },
            {
              definition:
                "An access point or conduit for rainwater that drains directly downstream in a (drainage) basin without going through sewers or water treatment in order to prevent or belay floods.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something consuming resources and providing nothing in return.",
              synonyms: [],
              antonyms: [],
              example: "That rental property is a drain on our finances.",
            },
            { definition: "An act of urination.", synonyms: [], antonyms: [] },
            {
              definition: "One terminal of a field effect transistor (FET).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An outhole.", synonyms: [], antonyms: [] },
            { definition: "A drink.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To lose liquid.",
              synonyms: [],
              antonyms: [],
              example: "The clogged sink drained slowly.",
            },
            {
              definition: "To flow gradually.",
              synonyms: [],
              antonyms: [],
              example: "The water of low ground drains off.",
            },
            {
              definition: "To cause liquid to flow out of.",
              synonyms: [],
              antonyms: [],
              example: "Please drain the sink. It's full of dirty water.",
            },
            {
              definition: "To convert a perennially wet place into a dry one.",
              synonyms: [],
              antonyms: [],
              example:
                "They had to drain the swampy land before the parking lot could be built.",
            },
            {
              definition: "To deplete of energy or resources.",
              synonyms: [],
              antonyms: [],
              example: "The stress of this job is really draining me.",
            },
            {
              definition:
                "To draw off by degrees; to cause to flow gradually out or off; hence, to exhaust.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To filter.", synonyms: [], antonyms: [] },
            {
              definition: "To fall off the bottom of the playfield.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/drain"],
    },
  ],
  fills: [
    {
      word: "fills",
      phonetic: "/fɪlz/",
      phonetics: [{ text: "/fɪlz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To occupy fully, to take up all of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To add contents to (a container, cavity or the like) so that it is full.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To enter (something), making it full.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To become full.",
              synonyms: [],
              antonyms: [],
              example: "the bucket filled with rain;  the sails fill with wind",
            },
            {
              definition: "To become pervaded with something.",
              synonyms: [],
              antonyms: [],
              example: "My heart filled with joy.",
            },
            {
              definition:
                "To satisfy or obey (an order, request or requirement).",
              synonyms: [],
              antonyms: [],
              example: "The pharmacist filled my prescription for penicillin.",
            },
            {
              definition:
                "To install someone, or be installed, in (a position or office), eliminating a vacancy.",
              synonyms: [],
              antonyms: [],
              example:
                "Sorry, no more applicants. The position has been filled.",
            },
            {
              definition:
                "To treat (a tooth) by adding a dental filling to it.",
              synonyms: [],
              antonyms: [],
              example: "Dr. Smith filled Jim's cavity with silver amalgam.",
            },
            {
              definition:
                "To fill or supply fully with food; to feed; to satisfy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To trim (a yard) so that the wind blows on the after side of the sails.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a male) To have sexual intercourse with (a female).",
              synonyms: [],
              antonyms: [],
              example: "Did you fill that girl last night?",
            },
          ],
          synonyms: ["dick", "get up in", "knob", "swive", "pervade"],
          antonyms: ["empty", "empty"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(after a possessive) A sufficient or more than sufficient amount.",
              synonyms: [],
              antonyms: [],
              example: "Don't feed him any more: he's had his fill.",
            },
            {
              definition: "An amount that fills a container.",
              synonyms: [],
              antonyms: [],
              example: "The mixer returned to the plant for another fill.",
            },
            {
              definition: "The filling of a container or area.",
              synonyms: [],
              antonyms: [],
              example: "That machine can do 20 fills a minute.",
            },
            {
              definition:
                "Inexpensive material used to occupy empty spaces, especially in construction.",
              synonyms: [],
              antonyms: [],
              example:
                "The ruins of earlier buildings were used as fill for more recent construction.",
            },
            {
              definition:
                "Soil and/or human-created debris discovered within a cavity or cut in the layers and exposed by excavation; fill soil.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An embankment, as in railroad construction, to fill a hollow or ravine; also, the place which is to be filled.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A short passage, riff, or rhythmic sound that helps to keep the listener's attention during a break between the phrases of a melody.",
              synonyms: [],
              antonyms: [],
              example: "bass fill",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One of the thills or shafts of a carriage.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fill",
        "https://en.wiktionary.org/wiki/fills",
      ],
    },
  ],
  nylon: [
    {
      word: "nylon",
      phonetic: "/ˈnaɪlɒn/",
      phonetics: [
        { text: "/ˈnaɪlɒn/", audio: "" },
        { text: "/ˈnaɪlɑn/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Originally, the DuPont company trade name for polyamide, a copolymer whose molecules consist of alternating diamine and dicarboxylic acid monomers bonded together; now generically used for this type of polymer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in the plural) A stocking originally fabricated from nylon; also used generically for any long, sheer stocking worn on a woman's legs.",
              synonyms: [],
              antonyms: [],
              example:
                "I tore a hole in my nylons while walking home through the woods.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/nylon"],
    },
  ],
  lunar: [
    {
      word: "lunar",
      phonetic: "/ˈl(j)uːnə/",
      phonetics: [
        { text: "/ˈl(j)uːnə/", audio: "" },
        {
          text: "/ˈlunɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lunar-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=63567",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The middle bone of the proximal series of the carpus in the wrist, which is shaped like a half-moon.",
              synonyms: ["intermedium", "lunar bone", "semilunar"],
              antonyms: [],
            },
            {
              definition:
                "An observation of a lunar distance, especially for establishing the longitude of a ship at sea.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["intermedium", "lunar bone", "semilunar"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Of, pertaining to, or resembling the Moon (that is, Luna, the Earth's moon); Lunar.",
              synonyms: [
                "lunarlike",
                "lunary",
                "moonish",
                "moonlike",
                "moonly",
                "selenic",
              ],
              antonyms: [],
              example: "lunar observations  a lunar eclipse",
            },
            {
              definition: "Shaped like a crescent moon; lunate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Believed to be) influenced by the Moon, as in character, growth, or properties.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of or pertaining to silver (which was symbolically associated with the Moon by alchemists).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of or pertaining to travel through space between the Earth and the Moon, or exploration and scientific investigation of the Moon.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "lunarlike",
            "lunary",
            "moonish",
            "moonlike",
            "moonly",
            "selenic",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lunar"],
    },
  ],
  pulse: [
    {
      word: "pulse",
      phonetic: "/pʊls/",
      phonetics: [
        { text: "/pʊls/", audio: "" },
        { text: "/pʌls/", audio: "" },
        {
          text: "/pʌls/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pulse-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651358",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A normally regular beat felt when arteries are depressed, caused by the pumping action of the heart.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A beat or throb.", synonyms: [], antonyms: [] },
            {
              definition: "The beat or tactus of a piece of music.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An autosoliton", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pulse"],
    },
    {
      word: "pulse",
      phonetic: "/pʊls/",
      phonetics: [
        { text: "/pʊls/", audio: "" },
        { text: "/pʌls/", audio: "" },
        {
          text: "/pʌls/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pulse-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651358",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To beat, to throb, to flash.",
              synonyms: [],
              antonyms: [],
              example:
                "In the dead of night, all was still but the pulsing light.",
            },
            {
              definition: "To flow, particularly of blood.",
              synonyms: [],
              antonyms: [],
              example: "Hot blood pulses through my veins.",
            },
            {
              definition: "To emit in discrete quantities.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To operate a blender in short bursts, to break down ingredients without liquidizing them.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pulse"],
    },
    {
      word: "pulse",
      phonetic: "/pʊls/",
      phonetics: [
        { text: "/pʊls/", audio: "" },
        { text: "/pʌls/", audio: "" },
        {
          text: "/pʌls/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pulse-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651358",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any annual legume yielding from 1 to 12 grains or seeds within a pod, and used as food for humans or animals, especially in the mature, dry condition.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pulse"],
    },
  ],
  flown: [
    {
      word: "flown",
      phonetic: "/ˈfləʊn/",
      phonetics: [
        { text: "/ˈfləʊn/", audio: "" },
        {
          text: "/ˈfloʊn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/flown-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=592033",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Suspended in the flies.",
              synonyms: [],
              antonyms: [],
              example: "flown scenery",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To travel through the air, another gas or a vacuum, without being in contact with a grounded surface.",
              synonyms: [],
              antonyms: [],
              example:
                "Birds of passage fly to warmer regions as it gets colder in winter.",
            },
            {
              definition: "To flee, to escape (from).",
              synonyms: [],
              antonyms: [],
              example: "Fly, my lord! The enemy are upon us!",
            },
            {
              definition:
                "To cause to fly (travel or float in the air): to transport via air or the like.",
              synonyms: [],
              antonyms: [],
              example:
                "Birds fly their prey to their nest to feed it to their young.",
            },
            {
              definition:
                "(of a proposal, project or idea) To be accepted, come about or work out.",
              synonyms: [],
              antonyms: [],
              example: "Let's see if that idea flies.",
            },
            {
              definition: "To travel very fast, hasten.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To move suddenly, or with violence; to do an act suddenly or swiftly.",
              synonyms: [],
              antonyms: [],
              example: "a door flies open;  a bomb flies apart",
            },
            {
              definition: "To display (a flag) on a flagpole.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To hunt with a hawk.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "hurry",
            "zoom",
            "abscond",
            "escape",
            "flee",
            "ascend",
            "aviate",
            "float",
            "glide",
            "hover",
            "rise",
            "skim",
            "soar",
            "wing",
            "dart",
            "flit",
          ],
          antonyms: ["remain", "stay", "walk"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/flown",
        "https://en.wiktionary.org/wiki/fly",
      ],
    },
  ],
  elbow: [
    {
      word: "elbow",
      phonetic: "/ˈɛl.bəʊ/",
      phonetics: [
        { text: "/ˈɛl.bəʊ/", audio: "" },
        {
          text: "/ˈɛl.boʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/elbow-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=297769",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The joint between the upper arm and the forearm.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) Any turn or bend like that of the elbow, in a wall, building, coastline, etc.; an angular or jointed part of any structure, such as the raised arm of a chair or sofa, or a short pipe fitting, turning at an angle or bent.",
              synonyms: [],
              antonyms: [],
              example:
                "the sides of windows, where the jamb makes an elbow with the window back",
            },
            { definition: "A detective.", synonyms: [], antonyms: [] },
            {
              definition:
                "Part of a basketball court located at the intersection of the free-throw line and the free-throw lane.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A hit with the elbow.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To push with the elbow.",
              synonyms: [],
              antonyms: [],
              example: "He elbowed his way through the crowd.",
            },
            {
              definition: "(by extension) To nudge, jostle or push.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/elbow"],
    },
  ],
  fatal: [
    {
      word: "fatal",
      phonetic: "[ˈfeɪ.ɾɫ̩]",
      phonetics: [
        {
          text: "[ˈfeɪ.ɾɫ̩]",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fatal-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1684610",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A fatality; an event that leads to death.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A fatal error; a failure that causes a program to terminate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Proceeding from, or appointed by, fate or destiny.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Foreboding death or great disaster.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Causing death or destruction.",
              synonyms: [],
              antonyms: [],
              example:
                "a fatal wound; a fatal disease; that fatal day; a fatal mistake",
            },
            {
              definition: "Causing a sudden end to the running of a program.",
              synonyms: [],
              antonyms: [],
              example: "a fatal error; a fatal exception",
            },
          ],
          synonyms: [
            "calamitous",
            "deadly",
            "destructive",
            "mortal",
            "terminal",
            "inevitable",
            "necessary",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fatal"],
    },
  ],
  sites: [
    {
      word: "sites",
      phonetic: "/saɪts/",
      phonetics: [{ text: "/saɪts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Sorrow, grief.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The place where anything is fixed; situation; local position",
              synonyms: [],
              antonyms: [],
              example: "the site of a city or of a house",
            },
            {
              definition:
                "A place fitted or chosen for any certain permanent use or occupation",
              synonyms: [],
              antonyms: [],
              example: "a site for a church",
            },
            {
              definition: "The posture or position of a thing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A computer installation, particularly one associated with an intranet or internet service or telecommunications.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A website.", synonyms: [], antonyms: [] },
            {
              definition:
                "A category together with a choice of Grothendieck topology.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Region of a protein, a piece of DNA or RNA where chemical reactions take place.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A part of the body which has been operated on.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To situate or place a building.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/site",
        "https://en.wiktionary.org/wiki/sites",
      ],
    },
  ],
  moths: [
    {
      word: "moths",
      phonetic: "/mɑðz/",
      phonetics: [
        { text: "/mɑðz/", audio: "" },
        { text: "/mɒθs/", audio: "" },
        { text: "/mɑθs/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A small particle; a speck.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A meeting for discussion.",
              synonyms: [],
              antonyms: [],
              example: "a wardmote in the city of London",
            },
            {
              definition:
                "A body of persons who meet for discussion, especially about the management of affairs.",
              synonyms: [],
              antonyms: [],
              example: "a folk mote",
            },
            {
              definition: "A place of meeting for discussion.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A tiny computer for remote sensing; a component element of smartdust.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A usually nocturnal insect of the order Lepidoptera, distinguished from butterflies by feather-like antennae.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Anything that gradually and silently eats, consumes, or wastes any other thing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["lep"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To hunt for moths.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The plant Vigna aconitifolia, moth bean.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["matki"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A liver spot, especially an irregular or feathery one.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mote",
        "https://en.wiktionary.org/wiki/moth",
        "https://en.wiktionary.org/wiki/moths",
      ],
    },
  ],
  meats: [
    {
      word: "meats",
      phonetic: "/miːts/",
      phonetics: [{ text: "/miːts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The flesh (muscle tissue) of an animal used as food.",
              synonyms: [],
              antonyms: [],
              example:
                "A large portion of domestic meat production comes from animals raised on factory farms.",
            },
            {
              definition:
                "A type of meat, by anatomic position and provenance.",
              synonyms: [],
              antonyms: [],
              example:
                "The butchery's profit rate on various meats varies greatly.",
            },
            {
              definition:
                "Food, for animals or humans, especially solid food. See also meat and drink.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A type of food, a dish.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A meal.", synonyms: [], antonyms: [] },
            { definition: "Meal; flour.", synonyms: [], antonyms: [] },
            {
              definition:
                "Any relatively thick, solid part of a fruit, nut etc.",
              synonyms: [],
              antonyms: [],
              example:
                "The apple looked fine on the outside, but the meat was not very firm.",
            },
            { definition: "A penis.", synonyms: [], antonyms: [] },
            {
              definition: "The best or most substantial part of something.",
              synonyms: [],
              antonyms: [],
              example:
                "We recruited him right from the meat of our competitor.",
            },
            {
              definition:
                "The sweet spot of a bat or club (in cricket, golf, baseball etc.).",
              synonyms: [],
              antonyms: [],
              example: "He hit it right on the meat of the bat.",
            },
            {
              definition: "A meathead.",
              synonyms: [],
              antonyms: [],
              example: "Throw it in here, meat.",
            },
            {
              definition:
                "(Australian Aboriginal) A totem, or (by metonymy) a clan or clansman which uses it.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["flesh", "crux", "gist"],
          antonyms: ["drink"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/meat",
        "https://en.wiktionary.org/wiki/meats",
      ],
    },
  ],
  foxes: [
    {
      word: "foxes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A red fox, small carnivore (Vulpes vulpes), related to dogs and wolves, with red or silver fur and a bushy tail.",
              synonyms: [],
              antonyms: [],
              example: "The quick brown fox jumps over the lazy dog.",
            },
            {
              definition:
                "Any of numerous species of small wild canids resembling the red fox. In the taxonomy they form the tribe Vulpini within the family Canidae, consisting of nine genera (see the Wikipedia article on the fox).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The fur of a fox.", synonyms: [], antonyms: [] },
            { definition: "A fox terrier.", synonyms: [], antonyms: [] },
            {
              definition:
                "The gemmeous dragonet, a fish, Callionymus lyra, so called from its yellow color.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A cunning person.", synonyms: [], antonyms: [] },
            {
              definition: "A physically attractive man or woman.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small strand of rope made by twisting several rope-yarns together. Used for seizings, mats, sennits, and gaskets.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A wedge driven into the split end of a bolt to tighten it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hidden radio transmitter, finding which is the goal of radiosport.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The fourteenth Lenormand card.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sword; so called from the stamp of a fox on the blade, or perhaps of a wolf taken for a fox.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["tod"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To trick, fool or outwit (someone) by cunning or ingenuity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To confuse or baffle (someone).",
              synonyms: [],
              antonyms: [],
              example: "This crossword puzzle has completely foxed me.",
            },
            {
              definition: "To act slyly or craftily.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To discolour paper. Fox marks are spots on paper caused by humidity. (See foxing.)",
              synonyms: [],
              antonyms: [],
              example: "The pages of the book show distinct foxing.",
            },
            {
              definition: "To make sour, as beer, by causing it to ferment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To turn sour; said of beer, etc., when it sours in fermenting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To intoxicate; to stupefy with drink.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To repair (boots) with new front upper leather, or to piece the upper fronts of.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fox",
        "https://en.wiktionary.org/wiki/foxes",
      ],
    },
  ],
  mined: [
    {
      word: "mined",
      phonetic: "/maɪnd/",
      phonetics: [{ text: "/maɪnd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To remove (ore) from the ground.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To dig into, for ore or metal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To sow mines (the explosive devices) in (an area).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To damage (a vehicle or ship) with a mine (an explosive device).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To dig a tunnel or hole; to burrow in the earth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To dig away, or otherwise remove, the substratum or foundation of; to lay a mine under; to sap; to undermine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) To ruin or destroy by slow degrees or secret means.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To pick one's nose.", synonyms: [], antonyms: [] },
            {
              definition:
                "To earn new units of cryptocurrency by doing certain calculations.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mine",
        "https://en.wiktionary.org/wiki/mined",
      ],
    },
  ],
  attic: [
    {
      word: "attic",
      phonetic: "/ˈætɪk/",
      phonetics: [{ text: "/ˈætɪk/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The space, often unfinished and with sloped walls, directly below the roof in the uppermost part of a house or other building, generally used for storage or habitation.",
              synonyms: [],
              antonyms: [],
              example:
                "We went up to the attic to look for the boxes containing our childhood keepsakes.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/attic"],
    },
  ],
  fiery: [
    {
      word: "fiery",
      phonetic: "/ˈfaɪəɹi/",
      phonetics: [
        {
          text: "/ˈfaɪəɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fiery.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=78391875",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of or relating to fire.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Burning or glowing.", synonyms: [], antonyms: [] },
            {
              definition: "Inflammable or easily ignited.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Having the colour of fire.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Hot or inflamed.", synonyms: [], antonyms: [] },
            {
              definition: "Tempestuous or emotionally volatile.",
              synonyms: [],
              antonyms: [],
              example: "a fiery temper",
            },
            {
              definition: "Spirited or filled with emotion.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fiery"],
    },
  ],
  mount: [
    {
      word: "mount",
      phonetic: "/maʊnt/",
      phonetics: [
        {
          text: "/maʊnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mount-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780229",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A hill or mountain.", synonyms: [], antonyms: [] },
            {
              definition:
                "Any of seven fleshy prominences in the palm of the hand, taken to represent the influences of various heavenly bodies.",
              synonyms: [],
              antonyms: [],
              example: "the mount of Jupiter",
            },
            {
              definition: "A bulwark for offence or defence; a mound.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A bank; a fund.", synonyms: [], antonyms: [] },
            {
              definition: "A green hillock in the base of a shield.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mount"],
    },
    {
      word: "mount",
      phonetic: "/maʊnt/",
      phonetics: [
        {
          text: "/maʊnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mount-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780229",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An animal, usually a horse, used to ride on, unlike a draught horse",
              synonyms: [],
              antonyms: [],
              example: "The rider climbed onto his mount.",
            },
            {
              definition:
                "A mounting; an object on which another object is mounted.",
              synonyms: [],
              antonyms: [],
              example:
                "The post is the mount on which the mailbox is installed.",
            },
            {
              definition: "A rider in a cavalry unit or division.",
              synonyms: [],
              antonyms: [],
              example: "The General said he has 2,000 mounts.",
            },
            {
              definition: "A step or block to assist in mounting a horse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A signal for mounting a horse.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To get upon; to ascend; to climb.",
              synonyms: [],
              antonyms: [],
              example: "to mount stairs",
            },
            {
              definition:
                "To place oneself on (a horse, a bicycle, etc.); to bestride.",
              synonyms: [],
              antonyms: [],
              example: "The rider mounted his horse.",
            },
            {
              definition:
                "To cause to mount; to put on horseback; to furnish with animals for riding.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause (something) to rise or ascend; to drive up; to raise; to elevate; to lift up.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To rise on high; to go up; to be upraised or uplifted; to tower aloft; to ascend; often with up.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To attach (an object) to a support, backing, framework etc.",
              synonyms: [],
              antonyms: [],
              example: "to mount a mailbox on a post",
            },
            {
              definition:
                "To attach (a drive or device) to the file system in order to make it available to the operating system.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(sometimes with up) To increase in quantity or intensity.",
              synonyms: [],
              antonyms: [],
              example:
                "The bills mounted up and the business failed.  There is mounting tension in Crimea.",
            },
            {
              definition: "To attain in value; to amount (to).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To get on top of (an animal) to mate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To have sexual intercourse with someone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To begin (a campaign, military assault, etc.); to launch.",
              synonyms: [],
              antonyms: [],
              example: "The General gave the order to mount the attack.",
            },
            {
              definition: "To deploy (cannon) for use.",
              synonyms: [],
              antonyms: [],
              example: "to mount a cannon",
            },
            {
              definition:
                "To prepare and arrange the scenery, furniture, etc. for use in (a play or production).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To incorporate fat, especially butter, into (a dish, especially a sauce to finish it).",
              synonyms: [],
              antonyms: [],
              example: "Mount the sauce with one tablespoon of butter.",
            },
          ],
          synonyms: ["coitize", "go to bed with", "sleep with"],
          antonyms: ["demount", "dismount", "unmount"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mount"],
    },
  ],
  usage: [
    {
      word: "usage",
      phonetic: "/ˈjuːsɪd͡ʒ/",
      phonetics: [
        {
          text: "/ˈjuːsɪd͡ʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/usage-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651674",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The manner or the amount of using; use.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Habit or accepted practice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The ways and contexts in which spoken and written words are used, determined by a lexicographer's intuition or from corpus analysis.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The treatment of someone or something.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/usage"],
    },
  ],
  swear: [
    {
      word: "swear",
      phonetic: "/swɛə/",
      phonetics: [
        { text: "/swɛə/", audio: "" },
        {
          text: "/swɛɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/swear-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1230879",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To take an oath, to promise.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To use offensive, profane, or obscene language.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/swear"],
    },
    {
      word: "swear",
      phonetic: "/swɛə/",
      phonetics: [
        { text: "/swɛə/", audio: "" },
        {
          text: "/swɛɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/swear-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1230879",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A swear word.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/swear"],
    },
    {
      word: "swear",
      phonetic: "/swɛə/",
      phonetics: [
        { text: "/swɛə/", audio: "" },
        {
          text: "/swɛɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/swear-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1230879",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To be lazy; rest for a short while during working hours.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Heavy.", synonyms: [], antonyms: [] },
            { definition: "Top-heavy; too high.", synonyms: [], antonyms: [] },
            {
              definition: "Dull; heavy; lazy; slow; reluctant; unwilling.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Niggardly.", synonyms: [], antonyms: [] },
            {
              definition:
                "A lazy time; a short rest during working hours (especially field labour); a siesta.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/swear"],
    },
  ],
  snowy: [
    {
      word: "snowy",
      phonetic: "/snəʊi/",
      phonetics: [
        { text: "/snəʊi/", audio: "" },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/snowy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2454298",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Marked by snow, characterized by snow.",
              synonyms: [],
              antonyms: [],
              example: "snowy day",
            },
            {
              definition: "Covered with snow, snow-covered, besnowed.",
              synonyms: [],
              antonyms: [],
              example: "snowy hillside",
            },
            {
              definition: "Snow-white in color, white as snow.",
              synonyms: ["niveous"],
              antonyms: [],
            },
          ],
          synonyms: ["niveous"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A large, white, nomadic Arctic owl, Bubo scandiacus.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["snowy"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/snowy",
        "https://en.wiktionary.org/wiki/snowy%20owl",
      ],
    },
  ],
  rusty: [
    {
      word: "rusty",
      phonetic: "/ˈɹʌsti/",
      phonetics: [{ text: "/ˈɹʌsti/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Marked or corroded by rust.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of the rust color, reddish or reddish-brown.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Lacking recent experience, out of practice, especially with respect to a skill or activity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of clothing, especially dark clothing: worn, shabby.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Affected with the fungal plant disease called rust.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rusty"],
    },
    {
      word: "rusty",
      phonetic: "/ˈɹʌsti/",
      phonetics: [{ text: "/ˈɹʌsti/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Discolored and rancid; reasty.",
              synonyms: [],
              antonyms: [],
              example: "rusty bacon",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rusty"],
    },
  ],
  scare: [
    {
      word: "scare",
      phonetic: "/skɛə/",
      phonetics: [
        { text: "/skɛə/", audio: "" },
        {
          text: "/skɛɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scare-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651465",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A minor fright.",
              synonyms: [],
              antonyms: [],
              example: "Johnny had a bad scare last night.",
            },
            {
              definition:
                "A cause of slight terror; something that inspires fear or dread.",
              synonyms: [],
              antonyms: [],
              example: "a food-poisoning scare",
            },
            {
              definition: "A device or object used to frighten.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scare"],
    },
    {
      word: "scare",
      phonetic: "/skɛə/",
      phonetics: [
        { text: "/skɛə/", audio: "" },
        {
          text: "/skɛɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scare-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651465",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To frighten, terrify, startle, especially in a minor way.",
              synonyms: [],
              antonyms: [],
              example: 'Did it scare you when I said "Boo!"?',
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scare"],
    },
    {
      word: "scare",
      phonetic: "/skɛə/",
      phonetics: [
        { text: "/skɛə/", audio: "" },
        {
          text: "/skɛɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scare-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651465",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Lean; scanty", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scare"],
    },
  ],
  traps: [
    {
      word: "traps",
      phonetic: "/tɹæps/",
      phonetics: [
        {
          text: "/tɹæps/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/traps-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=61267460",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A machine or other device designed to catch (and sometimes kill) animals, either by holding them in a container, or by catching hold of part of the body.",
              synonyms: ["snare"],
              antonyms: [],
              example:
                "I put down some traps in my apartment to try and deal with the mouse problem.",
            },
            {
              definition:
                "A trick or arrangement designed to catch someone in a more general sense; a snare.",
              synonyms: [],
              antonyms: [],
              example:
                "Unfortunately she fell into the trap of confusing biology with destiny.",
            },
            {
              definition: "A covering over a hole or opening; a trapdoor.",
              synonyms: [],
              antonyms: [],
              example:
                "Close the trap, would you, before someone falls and breaks their neck.",
            },
            {
              definition: "A kind of movable stepladder or set of stairs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A wooden instrument shaped somewhat like a shoe, used in the game of trapball",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The game of trapball itself.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any device used to hold and suddenly release an object.",
              synonyms: [],
              antonyms: [],
              example:
                "They shot out of the school gates like greyhounds out of the trap.",
            },
            {
              definition:
                "A bend, sag, or other device in a waste-pipe arranged so that the liquid contents form a seal which prevents the escape of noxious gases, but permits the flow of liquids.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A place in a water pipe, pump, etc., where air accumulates for lack of an outlet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A light two-wheeled carriage with springs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person's mouth.",
              synonyms: [],
              antonyms: [],
              example: "Keep your trap shut.",
            },
            {
              definition: "(in the plural) Belongings.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A cubicle (in a public toilet).",
              synonyms: [],
              antonyms: [],
              example:
                "I've just laid a cable in trap 2 so I'd give it 5 minutes if I were you.",
            },
            { definition: "Trapshooting.", synonyms: [], antonyms: [] },
            {
              definition:
                "A geological structure that creates a petroleum reservoir.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An exception generated by the processor or by an external event.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A mining license inspector during the Australian gold rush.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'A vehicle, residential building, or sidewalk corner where drugs are manufactured, packaged, or sold. (Also used attributively to describe things which are used for the sale of drugs, e.g. "a trap phone", "a trap car".)',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A fictional character from anime, or related media, who is coded as or has qualities typically associated with a gender other than the character's ostensible gender; otokonoko.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A trans woman, transfeminine person, or crossdressing man.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A genre of hip-hop music, with half-time drums and heavy sub-bass.",
              synonyms: ["trap music"],
              antonyms: [],
            },
            {
              definition: "The money earned by a prostitute for a pimp.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["snare", "trap music"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To physically capture, to catch in a trap or traps, or something like a trap.",
              synonyms: [],
              antonyms: [],
              example: "to trap foxes",
            },
            {
              definition: "To ensnare; to take by stratagem; to entrap.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To provide with a trap.",
              synonyms: [],
              antonyms: [],
              example: "to trap a drain",
            },
            {
              definition:
                "To set traps for game; to make a business of trapping game",
              synonyms: [],
              antonyms: [],
              example: "trap for beaver",
            },
            {
              definition: "To leave suddenly, to flee.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To sell illegal drugs, especially in a public area.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To capture (e.g. an error) in order to handle or process it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To attend to and open and close a (trap-)door.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A dark coloured igneous rock, now used to designate any non-volcanic, non-granitic igneous rock; trap rock.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To dress with ornaments; to adorn (especially said of horses).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The trapezius muscle.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/trap",
        "https://en.wiktionary.org/wiki/traps",
      ],
    },
  ],
  relax: [
    {
      word: "relax",
      phonetic: "/ɹɪˈlæks/",
      phonetics: [
        {
          text: "/ɹɪˈlæks/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/relax-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1234029",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To calm down.", synonyms: [], antonyms: [] },
            {
              definition: "To make something loose.",
              synonyms: [],
              antonyms: [],
              example: "to relax a rope or cord",
            },
            { definition: "To become loose.", synonyms: [], antonyms: [] },
            {
              definition: "To make something less severe or tense.",
              synonyms: [],
              antonyms: [],
              example: "to relax discipline",
            },
            {
              definition: "To become less severe or tense.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make something (such as codes and regulations) more lenient.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of codes and regulations) To become more lenient.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To relieve (something) from stress.",
              synonyms: [],
              antonyms: [],
              example: "Amusement relaxes the mind.",
            },
            {
              definition: "To relieve from constipation; to loosen; to open.",
              synonyms: [],
              antonyms: [],
              example: "An aperient relaxes the bowels.",
            },
          ],
          synonyms: [],
          antonyms: ["excite", "stress"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/relax"],
    },
  ],
  react: [
    {
      word: "react",
      phonetic: "/ɹiːˈækt/",
      phonetics: [{ text: "/ɹiːˈækt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An emoji used to express a reaction to a post on social media.",
              synonyms: [],
              antonyms: [],
              example: "Sad reacts only",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To act or perform a second time; to do over again; to reenact.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To return an impulse or impression; to resist the action of another body by an opposite force",
              synonyms: [],
              antonyms: [],
              example:
                "Every body reacts on the body that impels it from its natural state.",
            },
            {
              definition:
                "To act upon each other; to exercise a reciprocal or a reverse effect, as two or more chemical agents; to act in opposition.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause chemical agents to react; to cause one chemical agent to react with another.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/react"],
    },
  ],
  valid: [
    {
      word: "valid",
      phonetic: "/ˈvælɪd/",
      phonetics: [
        {
          text: "/ˈvælɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/valid-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=94709661",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Well grounded or justifiable, pertinent.",
              synonyms: [],
              antonyms: [],
              example:
                "I will believe him as soon as he offers a valid answer.",
            },
            {
              definition:
                "Acceptable, proper or correct; in accordance with the rules.",
              synonyms: [],
              antonyms: [],
              example: "A valid format for the date is MM/DD/YY.",
            },
            {
              definition:
                "Related to the current topic, or presented within context, relevant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of a formula or system: such that it evaluates to true regardless of the input values.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of an argument: whose conclusion is always true whenever its premises are true.",
              synonyms: [],
              antonyms: [],
              example:
                "An argument is valid if and only if the set consisting of both (1) all of its premises and (2) the contradictory of its conclusion is inconsistent.",
            },
            {
              definition: "Effective.",
              synonyms: [],
              antonyms: [],
              example:
                "He is a priest now: although his ordination was contrary to the law of the church, it was still valid.",
            },
          ],
          synonyms: [],
          antonyms: ["invalid", "nonvalid"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/valid"],
    },
  ],
  robin: [
    {
      word: "robin",
      phonetic: "/ˈɹɒb.ɪn/",
      phonetics: [
        { text: "/ˈɹɒb.ɪn/", audio: "" },
        {
          text: "/ˈɹɑb.ɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/robin-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762755",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of various passerine birds (about 100 species) of the families Muscicapidae, Turdidae and Petroicidae (formerly Eopsaltriidae), typically with a red breast.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A trimming in front of a dress.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["redbreast", "robin redbreast", "ruddock"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/robin"],
    },
  ],
  cease: [
    {
      word: "cease",
      phonetic: "/siːs/",
      phonetics: [
        {
          text: "/siːs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cease-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749736",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Cessation; extinction (see without cease).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To stop.",
              synonyms: [],
              antonyms: [],
              example: "And with that, his twitching ceased.",
            },
            {
              definition: "To stop doing (something).",
              synonyms: [],
              antonyms: [],
              example: "And with that, he ceased twitching.",
            },
            {
              definition: "To be wanting; to fail; to pass away.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "desert",
            "lack",
            "discontinue",
            "hold",
            "terminate",
            "arrest",
            "discontinue",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cease"],
    },
  ],
  gills: [
    {
      word: "gills",
      phonetic: "/ɡɪlz/",
      phonetics: [{ text: "/ɡɪlz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(animal anatomy) a breathing organ of fish and other aquatic animals",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of a fish) a gill slit or gill cover",
              synonyms: [],
              antonyms: [],
              example: "Gill nets are designed to catch a fish by the gills.",
            },
            {
              definition:
                "One of the radial folds on the underside of the cap of a mushroom, on the surface of which the spore-producing organs are borne",
              synonyms: ["lamella"],
              antonyms: [],
            },
            {
              definition:
                "(animal anatomy) the fleshy flap that hangs below the beak of a fowl; a wattle",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The flesh under or about the chin; a wattle",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(spinning) one of the combs of closely ranged steel pins which divide the ribbons of flax fiber or wool into fewer parallel filaments",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["lamella"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To remove the gills from a fish as part of gutting and cleaning it",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To catch (a fish) in a gillnet",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be or become entangled in a gillnet",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A drink measure for spirits and wine (size varies regionally but it is about one quarter of a pint)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A measuring jug holding a quarter or half a pint",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Rivulet", synonyms: [], antonyms: [] },
            { definition: "Ravine", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A two-wheeled frame for transporting timber",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "A leech", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A female ferret", synonyms: [], antonyms: [] },
            {
              definition: "A promiscuous woman; harlot, wanton",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A prostitute", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To act as a prostitute.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gill",
        "https://en.wiktionary.org/wiki/gills",
      ],
    },
  ],
  prior: [
    {
      word: "prior",
      phonetic: "/ˈpɹaɪɚ/",
      phonetics: [
        {
          text: "/ˈpɹaɪɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/prior-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651343",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A high-ranking member of a monastery, usually lower in rank than an abbot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A chief magistrate in Italy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(law enforcement) A previous arrest or criminal conviction on someone's record.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In Bayesian inference, a prior probability distribution, one based on information or belief before additional data is collected.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["provost"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Advance; previous; coming before.",
              synonyms: [],
              antonyms: [],
              example: "I had no prior knowledge you were coming.",
            },
            {
              definition: "Former, previous.",
              synonyms: [],
              antonyms: [],
              example: "His prior residence was smaller than his current one.",
            },
          ],
          synonyms: ["anterior"],
          antonyms: ["posterior"],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Previously.",
              synonyms: ["ago", "hitherto"],
              antonyms: [],
              example: "The doctor had known three months prior.",
            },
          ],
          synonyms: ["ago", "hitherto"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/prior"],
    },
  ],
  safer: [
    {
      word: "safer",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Not in danger; out of harm's reach.",
              synonyms: [],
              antonyms: [],
              example: "You’ll be safe here.",
            },
            {
              definition: "Free from risk.",
              synonyms: ["harmless", "riskless"],
              antonyms: ["dangerous", "harmful"],
              example: "It’s safe to eat this.",
            },
            {
              definition:
                "Providing protection from danger; providing shelter.",
              synonyms: [],
              antonyms: [],
              example:
                "We have to find a safe spot, where we can hide out until this is over.",
            },
            {
              definition:
                "When a batter successfully reaches first base, or when a baserunner successfully advances to the next base or returns to the base he last occupied; not out.",
              synonyms: [],
              antonyms: [],
              example:
                "The pitcher attempted to pick off the runner at first, but he was safe.",
            },
            {
              definition: "Properly secured.",
              synonyms: ["secure"],
              antonyms: [],
              example: "The documents are safe.",
            },
            {
              definition:
                "(used after a noun, often forming a compound) Not susceptible to a specified source of harm.",
              synonyms: [],
              antonyms: [],
              example: "dishwasher-safe",
            },
            {
              definition:
                "Great, cool, awesome, respectable; a term of approbation, often as interjection.",
              synonyms: ["cool", "wicked"],
              antonyms: [],
            },
            {
              definition:
                "Lenient, usually describing a teacher that is easy-going.",
              synonyms: ["easy-going", "lenient", "merciful", "tolerant"],
              antonyms: ["harsh", "intolerant", "strict"],
            },
            {
              definition: "Reliable; trusty.",
              synonyms: ["trustworthy"],
              antonyms: [],
            },
            { definition: "Cautious.", synonyms: [], antonyms: [] },
            {
              definition:
                "Of a programming language, type-safe or more generally offering well-defined behavior despite programming errors.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "cool",
            "wicked",
            "easy-going",
            "lenient",
            "merciful",
            "tolerant",
            "harmless",
            "riskless",
            "secure",
            "trustworthy",
          ],
          antonyms: [
            "dangerous",
            "harmful",
            "harsh",
            "intolerant",
            "strict",
            "unsafe",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/safe",
        "https://en.wiktionary.org/wiki/safer",
      ],
    },
  ],
  polio: [
    {
      word: "polio",
      phonetic: "/ˈpəʊljəʊ/",
      phonetics: [
        { text: "/ˈpəʊljəʊ/", audio: "" },
        { text: "/-ljoʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A sufferer from poliomyelitis.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Acute infection by the poliovirus, especially of the motor neurons in the spinal cord and brainstem, leading to muscle weakness, paralysis and sometimes deformity.",
              synonyms: ["infantile paralysis", "polio"],
              antonyms: [],
            },
          ],
          synonyms: ["infantile paralysis", "polio"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/polio",
        "https://en.wiktionary.org/wiki/poliomyelitis",
      ],
    },
  ],
  loyal: [
    {
      word: "loyal",
      phonetic: "/ˈlɔɪəl/",
      phonetics: [
        {
          text: "/ˈlɔɪəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/loyal-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80065958",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Having or demonstrating undivided and constant support for someone or something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Firm in allegiance to a person or institution.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Faithful to a person or cause.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["disloyal", "fickle", "treacherous"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/loyal"],
    },
  ],
  swell: [
    {
      word: "swell",
      phonetic: "/swɛl/",
      phonetics: [
        {
          text: "/swɛl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/swell-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88680900",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To become bigger, especially due to being engorged.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause to become bigger.",
              synonyms: [],
              antonyms: [],
              example: "Rains and dissolving snow swell the rivers in spring.",
            },
            {
              definition: "To grow gradually in force or loudness.",
              synonyms: [],
              antonyms: [],
              example: "The organ music swelled.",
            },
            {
              definition: "To raise to arrogance; to puff up; to inflate.",
              synonyms: [],
              antonyms: [],
              example: "to be swelled with pride or haughtiness",
            },
            {
              definition: "To be raised to arrogance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be elated; to rise arrogantly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be turgid, bombastic, or extravagant.",
              synonyms: [],
              antonyms: [],
              example: "swelling words  a swelling style",
            },
            {
              definition: "To protuberate; to bulge out.",
              synonyms: [],
              antonyms: [],
              example: "A cask swells in the middle.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/swell"],
    },
    {
      word: "swell",
      phonetic: "/swɛl/",
      phonetics: [
        {
          text: "/swɛl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/swell-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88680900",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act of swelling; increase in size.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A bulge or protuberance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Increase of power in style, or of rhetorical force.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A long series of ocean waves, generally produced by wind, and lasting after the wind has ceased.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A gradual crescendo followed by diminuendo.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A device for controlling the volume of a pipe organ.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A division in a pipe organ, usually the largest enclosed division.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A hillock or similar raised area of terrain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An upward protrusion of strata from whose central region the beds dip quaquaversally at a low angle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person who dresses in a fancy or elegant manner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person of high social standing; an important person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The front brow of a saddle bow, connected in the tree by the two saddle bars to the cantle on the other end.",
              synonyms: ["fork", "pommel"],
              antonyms: [],
            },
          ],
          synonyms: ["dandy", "dude", "toff", "toff", "fork", "pommel"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/swell"],
    },
    {
      word: "swell",
      phonetic: "/swɛl/",
      phonetics: [
        {
          text: "/swɛl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/swell-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88680900",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Fashionable, like a swell or dandy.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Excellent.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            { definition: "Very well.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/swell"],
    },
  ],
  salty: [
    {
      word: "salty",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/salty-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79218866",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈsɒl.ti/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Tasting of salt.", synonyms: [], antonyms: [] },
            { definition: "Containing salt.", synonyms: [], antonyms: [] },
            {
              definition: "Coarse, provocative, earthy; said of language.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Experienced, especially used to indicate a veteran of the naval services; salty dog (from salt of the sea).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Irritated, annoyed (from the sharp, spicy flavor of salt).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Indignant or offended due to over-sensitivity, humourlessness, disappointment, or defeat (implying the person is a crybaby, shedding salty tears); said of interlocutors expressing indignation, or merely disagreement.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Pertaining to the Sardinian language and those dialects of Catalan, spoken in the Balearic Islands and along the coast of Catalonia, that use definitive articles descended from the Latin ipse instead of the Latin ille.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/salty"],
    },
  ],
  marsh: [
    {
      word: "marsh",
      phonetic: "/mɑː(ɹ)ʃ/",
      phonetics: [
        { text: "/mɑː(ɹ)ʃ/", audio: "" },
        {
          text: "/mɑɹʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/marsh-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780210",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An area of low, wet land, often with tall grass.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/marsh"],
    },
  ],
  vague: [
    {
      word: "vague",
      phonetic: "/veɪɡ/",
      phonetics: [
        {
          text: "/veɪɡ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/vague-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=501246",
        },
        {
          text: "/veɪɡ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/vague-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=501416",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A wandering; a vagary.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An indefinite expanse.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To wander; to roam; to stray.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To become vague or act in a vague manner.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Not clearly expressed; stated in indefinite terms.",
              synonyms: ["unclear"],
              antonyms: [],
            },
            {
              definition: "Not having a precise meaning.",
              synonyms: ["ambiguous", "equivocal"],
              antonyms: [],
              example: "a vague term of abuse",
            },
            {
              definition:
                "Not clearly defined, grasped, or understood; indistinct; slight.",
              synonyms: ["ambiguous", "equivocal", "indistinct", "obscure"],
              antonyms: [],
              example: "I haven’t the vaguest idea.",
            },
            {
              definition: "Not clearly felt or sensed; somewhat subconscious.",
              synonyms: [],
              antonyms: [],
              example: "a vague longing",
            },
            {
              definition:
                "Not thinking or expressing one’s thoughts clearly or precisely.",
              synonyms: ["dazed"],
              antonyms: [],
            },
            {
              definition: "Lacking expression; vacant.",
              synonyms: ["vacant", "vacuous"],
              antonyms: [],
            },
            {
              definition: "Not sharply outlined; hazy.",
              synonyms: ["fuzzy", "hazy", "ill-defined"],
              antonyms: [],
            },
            {
              definition: "Wandering; vagrant; vagabond.",
              synonyms: [
                "erratic",
                "roaming",
                "unsettled",
                "vagabond",
                "vagrant",
              ],
              antonyms: [],
            },
          ],
          synonyms: [
            "ambiguous",
            "equivocal",
            "ambiguous",
            "equivocal",
            "indistinct",
            "obscure",
            "dazed",
            "erratic",
            "roaming",
            "unsettled",
            "vagabond",
            "vagrant",
            "fuzzy",
            "hazy",
            "ill-defined",
            "unclear",
            "vacant",
            "vacuous",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/vague"],
    },
  ],
  weave: [
    {
      word: "weave",
      phonetic: "/wiːv/",
      phonetics: [
        {
          text: "/wiːv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/weave-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1755247",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A type or way of weaving.",
              synonyms: [],
              antonyms: [],
              example: "That rug has a very tight weave.",
            },
            {
              definition:
                "Human or artificial hair worn to alter one's appearance, either to supplement or to cover the natural hair.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To form something by passing lengths or strands of material over and under one another.",
              synonyms: [],
              antonyms: [],
              example: "This loom weaves yarn into sweaters.",
            },
            {
              definition: "To spin a cocoon or a web.",
              synonyms: [],
              antonyms: [],
              example: "Spiders weave beautiful but deadly webs.",
            },
            {
              definition: "To unite by close connection or intermixture.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To compose creatively and intricately; to fabricate.",
              synonyms: [],
              antonyms: [],
              example: "to weave the plot of a story",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/weave"],
    },
    {
      word: "weave",
      phonetic: "/wiːv/",
      phonetics: [
        {
          text: "/wiːv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/weave-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1755247",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To move by turning and twisting.",
              synonyms: [],
              antonyms: [],
              example: "The drunk weaved into another bar.",
            },
            {
              definition:
                "To make (a path or way) by winding in and out or from side to side.",
              synonyms: [],
              antonyms: [],
              example:
                "The ambulance weaved its way through the heavy traffic.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/weave"],
    },
  ],
  mound: [
    {
      word: "mound",
      phonetic: "/maʊnd/",
      phonetics: [
        {
          text: "/maʊnd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mound-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241473",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An artificial hill or elevation of earth; a raised bank; an embankment thrown up for defense",
              synonyms: ["bulwark", "rampart"],
              antonyms: [],
            },
            {
              definition:
                "A natural elevation appearing as if thrown up artificially; a regular and isolated hill, hillock, or knoll.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Elevated area of dirt upon which the pitcher stands to pitch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A ball or globe forming part of the regalia of an emperor or other sovereign. It is encircled with bands, enriched with precious stones, and surmounted with a cross.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The mons veneris.", synonyms: [], antonyms: [] },
            { definition: "(measurement) A hand.", synonyms: [], antonyms: [] },
            {
              definition: "A protection; restraint; curb.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A helmet.", synonyms: [], antonyms: [] },
            { definition: "Might; size.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["globe", "globus cruciger", "orb", "bulwark", "rampart"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To fortify with a mound; add a barrier, rampart, etc. to.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To force or pile into a mound or mounds.",
              synonyms: [],
              antonyms: [],
              example:
                "He mounded up his mashed potatoes so they left more space on the plate for the meat.",
            },
          ],
          synonyms: [
            "bank",
            "bank up",
            "bulwark",
            "rampart",
            "heap up",
            "pile",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mound"],
    },
  ],
  seals: [
    {
      word: "seals",
      phonetic: "/siːlz/",
      phonetics: [
        {
          text: "/siːlz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/seals-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453701",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A pinniped (Pinnipedia), particularly an earless seal (true seal) or eared seal.",
              synonyms: [],
              antonyms: [],
              example:
                "The seals in the harbor looked better than they smelled.",
            },
            {
              definition:
                "A bearing representing a creature something like a walrus.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To hunt seals.",
              synonyms: [],
              antonyms: [],
              example: "They're organizing a protest against sealing.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A stamp used to impress a design on a soft substance such as wax.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An impression of such stamp on wax, paper or other material used for sealing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A design or insignia usually associated with an organization or an official role.",
              synonyms: [],
              antonyms: [],
              example: "The front of the podium bore the presidential seal.",
            },
            {
              definition: "Anything that secures or authenticates.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something which will be visibly damaged if a covering or container is opened, and which may or may not bear an official design.",
              synonyms: [],
              antonyms: [],
              example:
                "The result was declared invalid, as the seal on the meter had been broken.",
            },
            {
              definition: "Confirmation or approval, or an indication of this.",
              synonyms: [],
              antonyms: [],
              example: "Her clothes always had her mom's seal of approval.",
            },
            {
              definition:
                "Something designed to prevent liquids or gases from leaking through a joint.",
              synonyms: [],
              antonyms: [],
              example:
                "The canister is leaking. I think the main seal needs to be replaced.",
            },
            {
              definition: "A tight closure, secure against leakage.",
              synonyms: [],
              antonyms: [],
              example: "Close the lid tightly to get a good seal.",
            },
            { definition: "A chakra.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To place a seal on (a document).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To mark with a stamp, as an evidence of standard exactness, legal size, or merchantable quality.",
              synonyms: [],
              antonyms: [],
              example: "to seal silverware",
            },
            {
              definition:
                "To fasten (something) so that it cannot be opened without visible damage.",
              synonyms: [],
              antonyms: [],
              example:
                "The cover is sealed. If anyone tries to open it, we'll know about it.",
            },
            {
              definition:
                "To prevent people or vehicles from crossing (something).",
              synonyms: [
                "block",
                "block off",
                "close",
                "close off",
                "obstruct",
                "seal off",
              ],
              antonyms: [],
              example:
                "The border has been sealed until the fugitives are found.",
            },
            {
              definition: "To close securely to prevent leakage.",
              synonyms: [],
              antonyms: [],
              example: "I've sealed the bottle to keep the contents fresh.",
            },
            {
              definition: "To place in a sealed container.",
              synonyms: ["enclose"],
              antonyms: [],
              example: "I've sealed the documents in this envelope.",
            },
            {
              definition:
                "To place a notation of one's next move in a sealed envelope to be opened after an adjournment.",
              synonyms: [],
              antonyms: [],
              example:
                "After thinking for half an hour, the champion sealed his move.",
            },
            {
              definition: "To guarantee.",
              synonyms: [],
              antonyms: [],
              example: "The last-minute goal sealed United’s win.",
            },
            {
              definition:
                "To fix, as a piece of iron in a wall, with cement or plaster, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To close by means of a seal.",
              synonyms: [],
              antonyms: [],
              example: "to seal a drainpipe with water",
            },
            {
              definition:
                "To confirm or set apart as a second or additional wife.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "block",
            "block off",
            "close",
            "close off",
            "obstruct",
            "seal off",
            "enclose",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To tie up animals (especially cattle) in their stalls.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/seal",
        "https://en.wiktionary.org/wiki/seals",
      ],
    },
  ],
  mules: [
    {
      word: "mules",
      phonetic: "/mjuːlz/",
      phonetics: [
        {
          text: "/mjuːlz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mules-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651196",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The generally sterile male or female hybrid offspring of a male donkey and a female horse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The generally sterile hybrid offspring of any two species of animals.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A hybrid plant.", synonyms: [], antonyms: [] },
            { definition: "A stubborn person.", synonyms: [], antonyms: [] },
            {
              definition: "A person paid to smuggle drugs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A coin or medal minted with obverse and reverse designs not normally seen on the same piece, either intentionally or in error.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A MMORPG character, or NPC companion in a tabletop RPG, used mainly to store extra inventory for the owner's primary character.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of a group of cocktails involving ginger ale or ginger beer, citrus juice, and various liquors.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A kind of triangular sail for a yacht.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A kind of cotton-spinning machine.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To smuggle (illegal drugs).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A shoe that has no fitting or strap around the heel, but which covers the foot.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mule",
        "https://en.wiktionary.org/wiki/mules",
      ],
    },
    {
      word: "mules",
      phonetic: "/mjuːlz/",
      phonetics: [
        {
          text: "/mjuːlz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mules-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651196",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To remove skin from (an animal) to prevent myiasis.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mules"],
    },
  ],
  virus: [
    {
      word: "virus",
      phonetic: "/ˈvaɪɹəs/",
      phonetics: [
        {
          text: "/ˈvaɪɹəs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/virus-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2454373",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A submicroscopic, non-cellular structure consisting of a core of DNA or RNA surrounded by a protein coat, that requires a living host cell to replicate, and often causes disease in the host organism.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A disease caused by these organisms.",
              synonyms: [],
              antonyms: [],
              example: "He caught a virus and had to stay home from school.",
            },
            {
              definition: "Venom, as produced by a poisonous animal etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A type of malware which can covertly transmit itself between computers via networks (especially the Internet) or removable storage such as disks, often causing damage to systems and data; also computer virus.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Any type of malware.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/virus"],
    },
  ],
  scout: [
    {
      word: "scout",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scout-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899823",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "[skʌʊt]", audio: "" },
        { text: "/skaʊt/", audio: "" },
        { text: "/skaʊt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person sent out to gain and bring in tidings; especially, one employed in war to gain information about the enemy and ground.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An act of scouting or reconnoitering.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A member of any number of youth organizations belonging to the international scout movement, such as the Boy Scouts of America or Girl Scouts of the United States.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person who assesses and/or recruits others; especially, one who identifies promising talent on behalf of a sports team.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A college servant (in Oxford, England or Yale or Harvard), originally implying a male servant, attending to (usually several) students or undergraduates in a variety of ways that includes cleaning; corresponding to the duties of a gyp or possibly bedder at Cambridge University; and at Dublin, a skip.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A fielder in a game for practice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(up until 1920s) A fighter aircraft.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Term of address for a man or boy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To explore a wide terrain, as if on a search; to reconnoiter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To observe, watch, or look for, as a scout; to follow for the purpose of observation, as a scout.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scout"],
    },
    {
      word: "scout",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scout-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899823",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "[skʌʊt]", audio: "" },
        { text: "/skaʊt/", audio: "" },
        { text: "/skaʊt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To reject with contempt.",
              synonyms: [],
              antonyms: [],
              example: "to scout an idea or an apology",
            },
            { definition: "To scoff.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scout"],
    },
    {
      word: "scout",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scout-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899823",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "[skʌʊt]", audio: "" },
        { text: "/skaʊt/", audio: "" },
        { text: "/skaʊt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A swift sailing boat.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scout"],
    },
    {
      word: "scout",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scout-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899823",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "[skʌʊt]", audio: "" },
        { text: "/skaʊt/", audio: "" },
        { text: "/skaʊt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A projecting rock.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scout"],
    },
    {
      word: "scout",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scout-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899823",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "[skʌʊt]", audio: "" },
        { text: "/skaʊt/", audio: "" },
        { text: "/skaʊt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The guillemot.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To pour forth a liquid forcibly, especially excrement.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scout"],
    },
  ],
  acute: [
    {
      word: "acute",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/acute-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75729328",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/əˈkjuːt/", audio: "" },
        {
          text: "/əˈkjut/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/acute-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769826",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person who has the acute form of a disorder, such as schizophrenia.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An accent or tone higher than others.",
              synonyms: [],
              antonyms: ["grave"],
            },
            {
              definition: "An acute accent (´).",
              synonyms: [],
              antonyms: [],
              example: "The word ‘cafe’ often has an acute over the ‘e’.",
            },
          ],
          synonyms: [],
          antonyms: ["grave"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To give an acute sound to.",
              synonyms: [],
              antonyms: [],
              example: "He acutes his rising inflection too much.",
            },
            {
              definition: "To make acute; to sharpen, to whet.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Brief, quick, short.",
              synonyms: ["fast", "rapid"],
              antonyms: ["leisurely", "slow"],
              example: "It was an acute event.",
            },
            {
              definition: "High or shrill.",
              synonyms: [],
              antonyms: ["grave"],
              example: "an acute accent or tone",
            },
            {
              definition: "Intense, sensitive, sharp.",
              synonyms: ["keen", "powerful", "strong"],
              antonyms: ["dull", "obtuse", "slow", "witless"],
              example:
                "She had an acute sense of honour.  Eagles have very acute vision.",
            },
            {
              definition: "Urgent.",
              synonyms: ["emergent", "pressing", "sudden"],
              antonyms: [],
              example: "His need for medical attention was acute.",
            },
            {
              definition:
                "With the sides meeting directly to form an acute angle (at an apex or base).",
              synonyms: [],
              antonyms: ["obtuse"],
            },
            {
              definition: "Of an angle: less than 90 degrees.",
              synonyms: [],
              antonyms: ["obtuse"],
              example: "The teacher pointed out the acute angle.",
            },
            {
              definition:
                "Of a triangle: having all three interior angles measuring less than 90 degrees.",
              synonyms: ["acute-angled"],
              antonyms: ["obtuse", "obtuse-angled"],
              example: "an acute triangle",
            },
            {
              definition: "Of an accent or tone: generally higher than others.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of an abnormal condition of recent or sudden onset, in contrast to delayed onset; this sense does not imply severity, unlike the common usage.",
              synonyms: [],
              antonyms: [],
              example: "He dropped dead of an acute illness.",
            },
            {
              definition:
                "Of a short-lived condition, in contrast to a chronic condition; this sense also does not imply severity.",
              synonyms: [],
              antonyms: ["chronic"],
              example: "The acute symptoms resolved promptly.",
            },
            {
              definition:
                "After a letter of the alphabet: having an acute accent.",
              synonyms: [],
              antonyms: [],
              example: "The last letter of ‘café’ is ‘e’ acute.",
            },
          ],
          synonyms: [
            "acute-angled",
            "emergent",
            "pressing",
            "sudden",
            "fast",
            "rapid",
            "keen",
            "powerful",
            "strong",
          ],
          antonyms: [
            "chronic",
            "dull",
            "obtuse",
            "slow",
            "witless",
            "grave",
            "leisurely",
            "slow",
            "obtuse",
            "obtuse",
            "obtuse",
            "obtuse-angled",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/acute"],
    },
  ],
  windy: [
    {
      word: "windy",
      phonetic: "/ˈwɪndi/",
      phonetics: [
        {
          text: "/ˈwɪndi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/windy-1-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241933",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "Fart", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Accompanied by wind.",
              synonyms: [],
              antonyms: [],
              example: "It was a long and windy night.",
            },
            {
              definition: "Unsheltered and open to the wind.",
              synonyms: [],
              antonyms: [],
              example: "They made love in a windy bus shelter.",
            },
            {
              definition: "Empty and lacking substance.",
              synonyms: [],
              antonyms: [],
              example: "They made windy promises they would not keep.",
            },
            {
              definition: "Long-winded; orally verbose.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Flatulent.", synonyms: [], antonyms: [] },
            { definition: "Nervous, frightened.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["blowy", "blustery", "breezy"],
          antonyms: ["calm", "windless"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/windy"],
    },
    {
      word: "windy",
      phonetic: "/ˈwaɪndi/",
      phonetics: [
        {
          text: "/ˈwaɪndi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/windy-2-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88897400",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(of a path etc) Having many bends; winding, twisting or tortuous.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/windy"],
    },
  ],
  stout: [
    {
      word: "stout",
      phonetic: "/stʌʊt/",
      phonetics: [
        { text: "/stʌʊt/", audio: "" },
        {
          text: "/staʊt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stout-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100540",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A dark and strong malt brew made with toasted grain.",
              synonyms: [],
              antonyms: [],
              example:
                "Stout is darker, stronger and sweeter than porter beer.",
            },
            { definition: "An obese person.", synonyms: [], antonyms: [] },
            {
              definition: "A large clothing size.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Large; bulky.",
              synonyms: ["thickset", "corpulent", "fat"],
              antonyms: [],
            },
            {
              definition: "Bold, strong-minded.",
              synonyms: ["lusty", "muscular", "robust", "sinewy", "vigorous"],
              antonyms: [],
            },
            {
              definition: "Proud; haughty.",
              synonyms: ["arrogant", "hard", "haughty"],
              antonyms: [],
            },
            {
              definition: "Firm; resolute; dauntless.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Materially strong, enduring.",
              synonyms: [],
              antonyms: [],
              example: "Campers prefer stout vessels, sticks and cloth.",
            },
            { definition: "Obstinate.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "thickset",
            "corpulent",
            "fat",
            "arrogant",
            "hard",
            "haughty",
            "lusty",
            "muscular",
            "robust",
            "sinewy",
            "vigorous",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stout"],
    },
    {
      word: "stout",
      phonetic: "/stʌʊt/",
      phonetics: [
        { text: "/stʌʊt/", audio: "" },
        {
          text: "/staʊt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stout-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100540",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Gnat.", synonyms: ["midge"], antonyms: [] },
            { definition: "Gadfly.", synonyms: ["horsefly"], antonyms: [] },
          ],
          synonyms: ["horsefly", "midge"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stout"],
    },
  ],
  folds: [
    {
      word: "folds",
      phonetic: "/fəʊldz/",
      phonetics: [
        { text: "/fəʊldz/", audio: "" },
        {
          text: "/foʊldz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/folds-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2650963",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An act of folding.",
              synonyms: ["bending", "creasing"],
              antonyms: [],
              example:
                "After two reraises in quick succession, John realised his best option was probably a fold.",
            },
            {
              definition: "A bend or crease.",
              synonyms: ["bend", "crease"],
              antonyms: [],
            },
            {
              definition: "Any correct move in origami.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(newspapers) The division between the top and bottom halves of a broadsheet: headlines above the fold will be readable in a newsstand display; usually the fold.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) The division between the part of a web page visible in a web browser window without scrolling; usually the fold.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That which is folded together, or which enfolds or envelops; embrace.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The bending or curving of one or a stack of originally flat and planar surfaces, such as sedimentary strata, as a result of plastic (i.e. permanent) deformation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In functional programming, any of a family of higher-order functions that process a data structure recursively to build up a value.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A section of source code that can be collapsed out of view in an editor to aid readability.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bend", "crease", "bending", "creasing"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To bend (any thin material, such as paper) over so that it comes in contact with itself.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make the proper arrangement (in a thin material) by bending.",
              synonyms: [],
              antonyms: [],
              example:
                "If you fold the sheets, they'll fit more easily in the drawer.",
            },
            {
              definition: "To become folded; to form folds.",
              synonyms: [],
              antonyms: [],
              example: "Cardboard doesn't fold very easily.",
            },
            {
              definition: "To fall over; to be crushed.",
              synonyms: [],
              antonyms: [],
              example: "The chair folded under his enormous weight.",
            },
            {
              definition: "To enclose within folded arms (see also enfold).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To give way on a point or in an argument.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To withdraw from betting.",
              synonyms: [],
              antonyms: [],
              example:
                "With no hearts in the river and no chance to hit his straight, he folded.",
            },
            {
              definition: "(by extension) To withdraw or quit in general.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To stir gently, with a folding action.",
              synonyms: [],
              antonyms: [],
              example: "Fold the egg whites into the batter.",
            },
            {
              definition: "Of a company, to cease to trade.",
              synonyms: [],
              antonyms: [],
              example:
                "The company folded after six quarters of negative growth.",
            },
            {
              definition:
                "To double or lay together, as the arms or the hands.",
              synonyms: [],
              antonyms: [],
              example: "He folded his arms in defiance.",
            },
            {
              definition: "To cover or wrap up; to conceal.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "bend",
            "crease",
            "fall over",
            "concede",
            "give in",
            "give way",
            "yield",
          ],
          antonyms: ["unfold"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A pen or enclosure for sheep or other domestic animals.",
              synonyms: ["enclosure", "pen", "penfold", "pinfold"],
              antonyms: [],
            },
            {
              definition: "(collective) A group of sheep or goats.",
              synonyms: ["flock"],
              antonyms: [],
            },
            {
              definition: "Home, family.",
              synonyms: ["family", "home"],
              antonyms: [],
            },
            {
              definition:
                "A church congregation, a group of people who adhere to a common faith and habitually attend a given church; the Christian church as a whole, the flock of Christ.",
              synonyms: ["congregation", "flock"],
              antonyms: [],
            },
            {
              definition:
                "A group of people with shared ideas or goals or who live or work together.",
              synonyms: ["cohort"],
              antonyms: [],
            },
            { definition: "A boundary or limit.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "cohort",
            "congregation",
            "flock",
            "enclosure",
            "pen",
            "penfold",
            "pinfold",
            "family",
            "home",
            "flock",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To confine animals in a fold.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fold",
        "https://en.wiktionary.org/wiki/folds",
      ],
    },
  ],
  seize: [
    {
      word: "seize",
      phonetic: "/siːz/",
      phonetics: [
        {
          text: "/siːz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/seize-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=399100",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To deliberately take hold of; to grab or capture.",
              synonyms: ["clasp", "grasp", "grip"],
              antonyms: [],
            },
            {
              definition:
                "To take advantage of (an opportunity or circumstance).",
              synonyms: ["jump on"],
              antonyms: [],
            },
            {
              definition: "To take possession of (by force, law etc.).",
              synonyms: ["arrogate", "commandeer", "confiscate"],
              antonyms: [],
              example: "to seize a ship after libeling",
            },
            {
              definition: "To have a sudden and powerful effect upon.",
              synonyms: [],
              antonyms: [],
              example: "a fever seized him",
            },
            {
              definition:
                "To bind, lash or make fast, with several turns of small rope, cord, or small line.",
              synonyms: [],
              antonyms: [],
              example: "to seize or stop one rope on to another",
            },
            { definition: "To fasten, fix.", synonyms: [], antonyms: [] },
            {
              definition:
                "To lay hold in seizure, by hands or claws (+ on or upon).",
              synonyms: [],
              antonyms: [],
              example: "to seize on the neck of a horse",
            },
            { definition: "To have a seizure.", synonyms: [], antonyms: [] },
            {
              definition:
                "To bind or lock in position immovably; see also seize up.",
              synonyms: [],
              antonyms: [],
              example: "Rust caused the engine to seize, never to run again.",
            },
            {
              definition: "To submit for consideration to a deliberative body.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(with of) To cause (an action or matter) to be or remain before (a certain judge or court).",
              synonyms: [],
              antonyms: [],
              example: "This Court will remain seized of this matter.",
            },
          ],
          synonyms: [
            "arrogate",
            "commandeer",
            "confiscate",
            "clasp",
            "grasp",
            "grip",
            "jump on",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/seize"],
    },
  ],
  hilly: [
    {
      word: "hilly",
      phonetic: "/ˈhɪli/",
      phonetics: [{ text: "/ˈhɪli/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(of a landscape) Abundant in hills; having many hills.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hilly"],
    },
  ],
  joins: [
    {
      word: "joins",
      phonetic: "/dʒɔɪnz/",
      phonetics: [{ text: "/dʒɔɪnz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An intersection of piping or wiring; an interconnect.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An intersection of data in two or more database tables.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The act of joining something, such as a network.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The lowest upper bound, an operation between pairs of elements in a lattice, denoted by the symbol ∨.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["meet"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To connect or combine into one; to put together.",
              synonyms: [],
              antonyms: [],
              example: "The plumber joined the two ends of the broken pipe.",
            },
            {
              definition: "To come together; to meet.",
              synonyms: [],
              antonyms: [],
              example: "Parallel lines never join.",
            },
            {
              definition: "To come into the company of.",
              synonyms: [],
              antonyms: [],
              example:
                "I will join you watching the football game as soon as I have finished my work.",
            },
            {
              definition: "To become a member of.",
              synonyms: [],
              antonyms: [],
              example: "Many children join a sports club.",
            },
            {
              definition:
                "To produce an intersection of data in two or more database tables.",
              synonyms: [],
              antonyms: [],
              example:
                "By joining the Customer table on the Product table, we can show each customer's name alongside the products they have ordered.",
            },
            { definition: "To unite in marriage.", synonyms: [], antonyms: [] },
            {
              definition: "To enjoin upon; to command.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To accept, or engage in, as a contest.",
              synonyms: [],
              antonyms: [],
              example: "to join encounter, battle, or issue",
            },
          ],
          synonyms: ["bewed", "connect", "fay", "unite"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/join",
        "https://en.wiktionary.org/wiki/joins",
      ],
    },
  ],
  pluck: [
    {
      word: "pluck",
      phonetic: "/plʌk/",
      phonetics: [
        {
          text: "/plʌk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pluck-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=823079",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An instance of plucking.",
              synonyms: [],
              antonyms: [],
              example: "Those tiny birds are hardly worth the tedious pluck.",
            },
            {
              definition:
                "The lungs, heart with trachea and often oesophagus removed from slaughtered animals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Guts, nerve, fortitude or persistence.",
              synonyms: [],
              antonyms: [],
              example:
                "He didn't get far with the attempt, but you have to admire his pluck.",
            },
            { definition: "Cheap wine.", synonyms: ["plonk"], antonyms: [] },
          ],
          synonyms: ["plonk"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To pull something sharply; to pull something out",
              synonyms: [],
              antonyms: [],
              example: "She plucked the phone from her bag and dialled.",
            },
            {
              definition:
                "To take or remove (someone) quickly from a particular place or situation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To gently play a single string, e.g. on a guitar, violin etc.",
              synonyms: [],
              antonyms: [],
              example:
                "Whereas a piano strikes the string, a harpsichord plucks it.",
            },
            {
              definition: "To remove feathers from a bird.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To rob, fleece, steal forcibly",
              synonyms: [],
              antonyms: [],
              example:
                "The horny highwayman plucked his victims to their underwear, or attractive ones all the way.",
            },
            {
              definition: "To play a string instrument pizzicato.",
              synonyms: [],
              antonyms: [],
              example: "Plucking a bow instrument may cause a string to break.",
            },
            {
              definition: "To pull or twitch sharply.",
              synonyms: [],
              antonyms: [],
              example: "to pluck at somebody's sleeve",
            },
            {
              definition:
                "(university slang) To be rejected after failing an examination for a degree.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of a glacier: to transport individual pieces of bedrock by means of gradual erosion through freezing and thawing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pluck"],
    },
  ],
  stack: [
    {
      word: "stack",
      phonetic: "/stæk/",
      phonetics: [
        {
          text: "/stæk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stack-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75701505",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/stæk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stack-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80067081",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "(heading) A pile.", synonyms: [], antonyms: [] },
            { definition: "A smokestack.", synonyms: [], antonyms: [] },
            {
              definition: "(heading) In computing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A generalization of schemes in algebraic geometry and of sheaves.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A coastal landform, consisting of a large vertical column of rock in the sea.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(library) Compactly spaced bookshelves used to house large collections of books.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A large amount of an object.",
              synonyms: [],
              antonyms: [],
              example: "They paid him a stack of money to keep quiet.",
            },
            {
              definition: "A pile of rifles or muskets in a cone shape.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The amount of money a player has on the table.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(heading) In architecture.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A fall or crash, a prang.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A blend of various dietary supplements or anabolic steroids with supposed synergistic benefits.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A holding pattern, with aircraft circling one above the other as they wait to land.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The quantity of a given item which fills up an inventory slot or bag.",
              synonyms: [],
              antonyms: [],
              example:
                "I've got 107 Golden Branches, but the stack size is 20 so they're taking up 6 spaces in my inventory.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To arrange in a stack, or to add to an existing stack.",
              synonyms: ["build up", "stack up"],
              antonyms: [],
              example: "Please stack those chairs in the corner.",
            },
            {
              definition:
                "To arrange the cards in a deck in a particular manner.",
              synonyms: [],
              antonyms: [],
              example:
                "This is the third hand in a row where you've drawn four of a kind. Someone is stacking the deck!",
            },
            {
              definition:
                "To take all the money another player currently has on the table.",
              synonyms: [],
              antonyms: [],
              example: "I won Jill's last $100 this hand; I stacked her!",
            },
            {
              definition:
                "To deliberately distort the composition of (an assembly, committee, etc.).",
              synonyms: ["gerrymander"],
              antonyms: [],
              example:
                "The Government was accused of stacking the parliamentary committee.",
            },
            {
              definition: "To crash; to fall.",
              synonyms: ["smash", "wreck"],
              antonyms: [],
              example:
                "Jim couldn′t make it today as he stacked his car on the weekend.",
            },
            {
              definition: "To operate cumulatively.",
              synonyms: [],
              antonyms: [],
              example:
                "A magical widget will double your mojo. And yes, they do stack: if you manage to get two magical widgets, your mojo will be quadrupled. With three, it will be octupled, and so forth.",
            },
            {
              definition: "To place (aircraft) into a holding pattern.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To collect precious metal in the form of various small objects such as coins and bars.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["build up", "stack up", "gerrymander", "smash", "wreck"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stack"],
    },
  ],
  lords: [
    {
      word: "lords",
      phonetic: "/lɔːdz/",
      phonetics: [
        { text: "/lɔːdz/", audio: "" },
        {
          text: "/lɔɹdz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lords-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749886",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The master of the servants of a household; the master of a feudal manor",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One possessing similar mastery over others; any feudal superior generally; any nobleman or aristocrat; any chief, prince, or sovereign ruler; in Scotland, a male member of the lowest rank of nobility (the equivalent rank in England is baron)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One possessing similar mastery in figurative senses (esp. as lord of ~)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The heavenly body considered to possess a dominant influence over an event, time, etc.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A hunchback.", synonyms: [], antonyms: [] },
            {
              definition: "(via Cockney rhyming slang) Sixpence.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["drighten", "possessor", "proprietor", "sovereign"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "Domineer or act like a lord.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To invest with the dignity, power, and privileges of a lord; to grant the title of lord.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["elevate", "ennoble", "invest"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lord",
        "https://en.wiktionary.org/wiki/lords",
      ],
    },
  ],
  dunes: [
    {
      word: "dunes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A ridge or hill of sand piled up by the wind.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["sand dune", "sand-dune"],
          antonyms: ["dike", "dyke"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dune",
        "https://en.wiktionary.org/wiki/dunes",
      ],
    },
  ],
  burro: [
    {
      word: "burro",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A small donkey", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/burro"],
    },
  ],
  hawks: [
    {
      word: "hawks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A diurnal predatory bird of the family Accipitridae, smaller than an eagle.",
              synonyms: [],
              antonyms: [],
              example:
                "It is illegal to hunt hawks or other raptors in many parts of the world.",
            },
            {
              definition:
                "Any diurnal predatory terrestrial bird of similar size and appearance to the accipitrid hawks, such as a falcon",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An advocate of aggressive political positions and actions.",
              synonyms: ["war hawk", "warmonger"],
              antonyms: ["dove"],
            },
            {
              definition:
                "An uncooperative or purely-selfish participant in an exchange or game, especially when untrusting, acquisitive or treacherous. Refers specifically to the Prisoner's Dilemma, alias the Hawk-Dove game.",
              synonyms: [],
              antonyms: ["dove"],
            },
          ],
          synonyms: ["war hawk", "warmonger"],
          antonyms: ["dove", "dove"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To hunt with a hawk.", synonyms: [], antonyms: [] },
            {
              definition:
                "To make an attack while on the wing; to soar and strike like a hawk.",
              synonyms: [],
              antonyms: [],
              example: "to hawk at flies",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A plasterer's tool, made of a flat surface with a handle below, used to hold an amount of plaster prior to application to the wall or ceiling being worked on: a mortarboard.",
              synonyms: ["mortarboard"],
              antonyms: [],
            },
          ],
          synonyms: ["mortarboard"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To sell; to offer for sale by outcry in the street; to carry (merchandise) about from place to place for sale; to peddle.",
              synonyms: [],
              antonyms: [],
              example:
                "The vendors were hawking their wares from little tables lining either side of the market square.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A noisy effort to force up phlegm from the throat.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["hawking"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cough up something from one's throat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To try to cough up something from one's throat; to clear the throat loudly.",
              synonyms: [],
              antonyms: [],
              example:
                "Grandpa sat on the front porch, hawking and wheezing, as he packed his pipe with cheap tobacco.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hawk",
        "https://en.wiktionary.org/wiki/hawks",
      ],
    },
  ],
  trout: [
    {
      word: "trout",
      phonetic: "/tɹʌʊt/",
      phonetics: [
        { text: "/tɹʌʊt/", audio: "" },
        {
          text: "/tɹaʊt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/trout-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1770084",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of several species of fish in Salmonidae, closely related to salmon, and distinguished by spawning more than once.",
              synonyms: [],
              antonyms: [],
              example:
                "Many anglers consider trout to be the archetypical quarry.",
            },
            {
              definition: "An objectionable elderly woman.",
              synonyms: [],
              antonyms: [],
              example:
                "Look, you silly old trout, you can't keep bringing home cats! You can't afford the ones you have!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To (figuratively) slap someone with a slimy, stinky, wet trout; to admonish jocularly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/trout"],
    },
  ],
  feeds: [
    {
      word: "feeds",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Food given to (especially herbivorous) animals.",
              synonyms: [],
              antonyms: [],
              example:
                "They sell feed, riding helmets, and everything else for horses.",
            },
            {
              definition: "Something supplied continuously.",
              synonyms: [],
              antonyms: [],
              example: "a satellite feed",
            },
            {
              definition:
                "The part of a machine that supplies the material to be operated upon.",
              synonyms: [],
              antonyms: [],
              example: "the paper feed of a printer",
            },
            {
              definition:
                "The forward motion of the material fed into a machine.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A meal.", synonyms: [], antonyms: [] },
            {
              definition: "A gathering to eat, especially in quantity.",
              synonyms: [],
              antonyms: [],
              example: "They held a crab feed on the beach.",
            },
            {
              definition:
                "Encapsulated online content, such as news or a blog, that can be subscribed to.",
              synonyms: [],
              antonyms: [],
              example:
                "I've subscribed to the feeds of my favourite blogs, so I can find out when new posts are added without having to visit those sites.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(ditransitive) To give (someone or something) food to eat.",
              synonyms: [],
              antonyms: [],
              example: "Feed the dog every evening.",
            },
            {
              definition: "To eat (usually of animals).",
              synonyms: [],
              antonyms: [],
              example: "Spiders feed on gnats and flies.",
            },
            {
              definition:
                "To give (someone or something) to (someone or something else) as food.",
              synonyms: [],
              antonyms: [],
              example: "Feed the fish to the dolphins.",
            },
            {
              definition: "To give to a machine to be processed.",
              synonyms: [],
              antonyms: [],
              example: "Feed the paper gently into the document shredder.",
            },
            {
              definition:
                "To satisfy, gratify, or minister to (a sense, taste, desire, etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To supply with something.",
              synonyms: [],
              antonyms: [],
              example: "Springs feed ponds with water.",
            },
            {
              definition:
                "To graze; to cause to be cropped by feeding, as herbage by cattle.",
              synonyms: [],
              antonyms: [],
              example: "If grain is too forward in autumn, feed it with sheep.",
            },
            { definition: "To pass to.", synonyms: [], antonyms: [] },
            {
              definition:
                "(of a phonological rule) To create the environment where another phonological rule can apply; to be applied before another rule.",
              synonyms: [],
              antonyms: [],
              example: "Nasalization feeds raising.",
            },
            {
              definition:
                "(of a syntactic rule) To create the syntactic environment in which another syntactic rule is applied; to be applied before another syntactic rule.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["nourish"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/feed",
        "https://en.wiktionary.org/wiki/feeds",
      ],
    },
  ],
  scarf: [
    {
      word: "scarf",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scarf-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241556",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/skɑːf/", audio: "" },
        { text: "/skɑːɹf/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A long, often knitted, garment worn around the neck.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A headscarf.", synonyms: [], antonyms: [] },
            {
              definition: "A neckcloth or cravat.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To throw on loosely; to put on like a scarf.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To dress with a scarf, or as with a scarf; to cover with a loose wrapping.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scarf"],
    },
    {
      word: "scarf",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scarf-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241556",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/skɑːf/", audio: "" },
        { text: "/skɑːɹf/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A type of joint in woodworking.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A groove on one side of a sewing machine needle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A dip or notch or cut made in the trunk of a tree to direct its fall when felling.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["muffler"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To shape by grinding.", synonyms: [], antonyms: [] },
            {
              definition:
                'To form a scarf on the end or edge of, as for a joint in timber, forming a "V" groove for welding adjacent metal plates, metal rods, etc.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To unite, as two pieces of timber or metal, by a scarf joint.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scarf"],
    },
    {
      word: "scarf",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scarf-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241556",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/skɑːf/", audio: "" },
        { text: "/skɑːɹf/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To eat very quickly.",
              synonyms: [],
              antonyms: [],
              example: "You sure scarfed that pizza.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scarf"],
    },
    {
      word: "scarf",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scarf-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241556",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/skɑːf/", audio: "" },
        { text: "/skɑːɹf/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A cormorant.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scarf"],
    },
  ],
  halls: [
    {
      word: "halls",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A corridor; a hallway.",
              synonyms: [],
              antonyms: [],
              example: "The drinking fountain was out in the hall.",
            },
            {
              definition: "A meeting room.",
              synonyms: [],
              antonyms: [],
              example:
                "The hotel had three halls for conferences, and two were in use by the convention.",
            },
            {
              definition:
                "A manor house (originally because a magistrate's court was held in the hall of his mansion).",
              synonyms: [],
              antonyms: [],
              example: "The duke lived in a great hall overlooking the sea.",
            },
            {
              definition:
                "A building providing student accommodation at a university.",
              synonyms: [],
              antonyms: [],
              example:
                "The student government hosted several social events so that students from different halls would intermingle.",
            },
            {
              definition: "The principal room of a secular medieval building.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Cleared passageway through a crowd, as for dancing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A place for special professional education, or for conferring professional degrees or licences.",
              synonyms: [],
              antonyms: [],
              example: "a Divinity Hall; Apothecaries' Hall",
            },
            { definition: "A living room.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Student accommodation", synonyms: [], antonyms: [] },
          ],
          synonyms: ["hall of residence", "residence hall"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hall",
        "https://en.wiktionary.org/wiki/halls",
      ],
    },
  ],
  coals: [
    {
      word: "coals",
      phonetic: "/kɔʊlz/",
      phonetics: [
        { text: "/kɔʊlz/", audio: "" },
        { text: "/koʊlz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A black rock formed from prehistoric plant remains, composed largely of carbon and burned as a fuel.",
              synonyms: [],
              antonyms: [],
              example: "Put some coal on the fire.",
            },
            {
              definition:
                "A piece of coal used for burning (this use is less common in American English)",
              synonyms: [],
              antonyms: [],
              example: "Put some coals on the fire.",
            },
            {
              definition:
                "A type of coal, such as bituminous, anthracite, or lignite, and grades and varieties thereof.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A glowing or charred piece of coal, wood, or other solid fuel.",
              synonyms: [],
              antonyms: [],
              example:
                "Just as the camp-fire died down to just coals, with no flames to burn the marshmallows, someone dumped a whole load of wood on, so I gave up and went to bed.",
            },
            { definition: "Charcoal.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To take on a supply of coal (usually of steam ships).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To supply with coal.",
              synonyms: [],
              antonyms: [],
              example: "to coal a steamer",
            },
            {
              definition: "To be converted to charcoal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To burn to charcoal; to char.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To mark or delineate with charcoal.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/coal",
        "https://en.wiktionary.org/wiki/coals",
      ],
    },
  ],
  towel: [
    {
      word: "towel",
      phonetic: "/taʊl/",
      phonetics: [
        {
          text: "/taʊl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/towel-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9028869",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/taʊl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/towel-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=519873",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A cloth used for wiping, especially one used for drying anything wet, as a person after a bath.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To hit with a towel.", synonyms: [], antonyms: [] },
            {
              definition: "To dry by using a towel.",
              synonyms: [],
              antonyms: [],
              example: "He got out of the shower and toweled himself dry.",
            },
            {
              definition:
                "To block up (a door, etc.) with a towel, to conceal the fumes of a recreational drug.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To beat with a stick.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/towel"],
    },
  ],
  souls: [
    {
      word: "souls",
      phonetic: "/səʊlz/",
      phonetics: [
        { text: "/səʊlz/", audio: "" },
        {
          text: "/soʊlz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/souls-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1669354",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The spirit or essence of a person usually thought to consist of one's thoughts and personality. Often believed to live on after the person's death.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The spirit or essence of anything.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Life, energy, vigor.", synonyms: [], antonyms: [] },
            { definition: "Soul music.", synonyms: [], antonyms: [] },
            {
              definition: "A person, especially as one among many.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An individual life.",
              synonyms: [],
              antonyms: [],
              example: "Fifty souls were lost when the ship sank.",
            },
            {
              definition:
                "A kind of submanifold involved in the soul theorem of Riemannian geometry.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To endow with a soul; to furnish with a soul or mind.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To beg on All Soul's Day.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["crux", "gist"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To afford suitable sustenance.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/soul",
        "https://en.wiktionary.org/wiki/souls",
      ],
    },
  ],
  elect: [
    {
      word: "elect",
      phonetic: "/iːˈlɛkt/",
      phonetics: [
        {
          text: "/iːˈlɛkt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/elect-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453999",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One chosen or set apart.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In Calvinist theology, one foreordained to Heaven. In other Christian theologies, someone chosen by God for salvation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["reprobate"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To choose or make a decision (to do something)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To choose (a candidate) in an election",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Who has been elected in a specified post, but has not yet entered office.",
              synonyms: [],
              antonyms: [],
              example: "He is the President elect.",
            },
            {
              definition: "Chosen; taken by preference from among two or more.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/elect"],
    },
  ],
  buggy: [
    {
      word: "buggy",
      phonetic: "/bʌ.ɡi/",
      phonetics: [{ text: "/bʌ.ɡi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A small horse-drawn cart.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A small motor vehicle, such as a dune buggy.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A hearse.", synonyms: [], antonyms: [] },
            {
              definition: "A pushchair; a stroller.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A shopping cart or trolley.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["stroller"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Infested with insects", synonyms: [], antonyms: [] },
            {
              definition: "Containing programming errors",
              synonyms: [],
              antonyms: [],
              example:
                "This software is so buggy that I don't know how anyone can use it!",
            },
            { definition: "Resembling an insect", synonyms: [], antonyms: [] },
            { definition: "Crazy; bughouse", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/buggy"],
    },
  ],
  pumps: [
    {
      word: "pumps",
      phonetic: "/pʌmps/",
      phonetics: [{ text: "/pʌmps/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A device for moving or compressing a liquid or gas.",
              synonyms: [],
              antonyms: [],
              example: "This pump can deliver 100 gallons of water per minute.",
            },
            {
              definition:
                "An instance of the action of a pump; one stroke of a pump; any action similar to pumping",
              synonyms: [],
              antonyms: [],
              example:
                "It takes thirty pumps to get 10 litres; he did 50 pumps of the weights.",
            },
            {
              definition:
                "A device for dispensing liquid or gas to be sold, particularly fuel.",
              synonyms: [],
              antonyms: [],
              example:
                "This pump is out of order, but you can gas up at the next one.",
            },
            {
              definition:
                "A swelling of the muscles caused by increased blood flow following high intensity weightlifting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A ride on a bicycle given to a passenger, usually on the handlebars or fender.",
              synonyms: [],
              antonyms: [],
              example: "She gave the other girl a pump on her new bike.",
            },
            { definition: "The heart.", synonyms: [], antonyms: [] },
            {
              definition: "(British slang) The vagina.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To use a pump to move (liquid or gas).",
              synonyms: [],
              antonyms: [],
              example:
                "I've pumped over 1000 gallons of water in the last ten minutes.",
            },
            {
              definition: "(often followed by up) To fill with air.",
              synonyms: [],
              antonyms: [],
              example:
                "He pumped up the air-bed by hand, but used the service station air to pump up the tyres.",
            },
            {
              definition: "To move rhythmically, as the motion of a pump.",
              synonyms: [],
              antonyms: [],
              example: "I pumped my fist with joy when I won the race.",
            },
            {
              definition: "To shake (a person's hand) vigorously.",
              synonyms: ["handshake"],
              antonyms: [],
            },
            {
              definition:
                "To gain information from (a person) by persistent questioning.",
              synonyms: ["grill", "interrogate"],
              antonyms: [],
            },
            {
              definition: "To use a pump to move liquid or gas.",
              synonyms: [],
              antonyms: [],
              example:
                "I've been pumping for over a minute but the water isn't coming through.",
            },
            {
              definition: "To be going very well.",
              synonyms: [],
              antonyms: [],
              example: "Last night's party was really pumping.",
            },
            {
              definition: "To kick, throw or hit the ball far and high.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To pass gas; to fart.",
              synonyms: ["trump"],
              antonyms: [],
            },
            {
              definition:
                "To pass (messages) into a program so that it can obey them.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(British slang) To copulate.",
              synonyms: ["bang"],
              antonyms: [],
            },
            {
              definition: "To weightlift.",
              synonyms: ["big up", "lift", "pump iron"],
              antonyms: [],
            },
          ],
          synonyms: [
            "bang",
            "big up",
            "lift",
            "pump iron",
            "grill",
            "interrogate",
            "handshake",
            "trump",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A type of shoe, a trainer or sneaker.",
              synonyms: ["dap", "plimsoll", "sneaker", "trainer"],
              antonyms: [],
            },
            {
              definition:
                "A type of women's shoe which leaves the instep uncovered and has a relatively high heel, especially a stiletto (with a very high and thin heel)",
              synonyms: [],
              antonyms: [],
              example: "She was wearing a lovely new pair of pumps.",
            },
            { definition: "A dancing shoe.", synonyms: [], antonyms: [] },
            {
              definition: "A type of shoe without a heel.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dap", "plimsoll", "sneaker", "trainer"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pump",
        "https://en.wiktionary.org/wiki/pumps",
      ],
    },
  ],
  loans: [
    {
      word: "loans",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An act or instance of lending, an act or instance of granting something for temporary use.",
              synonyms: ["lending", "loaning"],
              antonyms: [],
              example:
                "Because of the loan that John made to me, I was able to pay my tuition for the upcoming semester.",
            },
            {
              definition:
                "A sum of money or other property that a natural or legal person borrows from another with the condition that it be returned or repaid over time or at a later date (sometimes with interest).",
              synonyms: ["principal"],
              antonyms: [],
              example:
                "All loans from the library, whether books or audio material, must be returned within two weeks.",
            },
            {
              definition:
                "The contract and array of legal or ethical obligations surrounding a loan.",
              synonyms: [],
              antonyms: [],
              example: "He made a payment on his loan.",
            },
            {
              definition: "The permission to borrow any item.",
              synonyms: [],
              antonyms: [],
              example: "Thank you for the loan of your lawn mower.",
            },
          ],
          synonyms: ["lending", "loaning", "principal"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To lend (something) to (someone).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A lonnen.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/loan",
        "https://en.wiktionary.org/wiki/loans",
      ],
    },
  ],
  spins: [
    {
      word: "spins",
      phonetic: "/spɪnz/",
      phonetics: [
        {
          text: "/spɪnz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spins-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1212371",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Rapid circular motion.",
              synonyms: [],
              antonyms: [],
              example: "He put some spin on the cue ball.",
            },
            {
              definition:
                "A quantum angular momentum associated with subatomic particles, which also creates a magnetic moment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A favourable comment or interpretation intended to bias opinion on an otherwise unpleasant situation.",
              synonyms: ["propaganda"],
              antonyms: [],
              example:
                "The politician was mocked in the press for his reliance on spin rather than facts.",
            },
            {
              definition:
                "Rotation of the ball as it flies through the air; sideways movement of the ball as it bounces.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A condition of flight where a stalled aircraft is simultaneously pitching, yawing and rolling in a spinning motion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A brief trip by vehicle, especially one made for pleasure.",
              synonyms: [],
              antonyms: [],
              example: "I'm off out for a spin in my new sports car.",
            },
            {
              definition:
                "A bundle of spun material; a mass of strands and filaments.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A single play of a record by a radio station.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A search of a prisoner's cell for forbidden articles.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Unmarried woman, spinster.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The use of an exercise bicycle, especially as part of a gym class.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["propaganda"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To rotate, revolve, gyrate (usually quickly); to partially or completely rotate to face another direction.",
              synonyms: [],
              antonyms: [],
              example: "I spun myself around a few times.",
            },
            {
              definition:
                "To make yarn by twisting and winding fibers together.",
              synonyms: [],
              antonyms: [],
              example: "They spin the cotton into thread.",
            },
            {
              definition:
                "To present, describe, or interpret, or to introduce a bias or slant, so as to give something a favorable or advantageous appearance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a bowler) To make the ball move sideways when it bounces on the pitch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of a ball) To move sideways when bouncing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To form into thin strips or ribbons, as with sugar",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To form (a web, a cocoon, silk, etc.) from threads produced by the extrusion of a viscid, transparent liquid, which hardens on coming into contact with the air; said of the spider, the silkworm, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To shape, as malleable sheet metal, into a hollow form, by bending or buckling it by pressing against it with a smooth hand tool or roller while the metal revolves, as in a lathe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To move swiftly.",
              synonyms: [],
              antonyms: [],
              example:
                "to spin along the road in a carriage, on a bicycle, etc.",
            },
            {
              definition:
                "To stream or issue in a thread or a small current or jet.",
              synonyms: [],
              antonyms: [],
              example: "Blood spins from a vein.",
            },
            {
              definition:
                "To wait in a loop until some condition becomes true.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To play (vinyl records, etc.) as a disc jockey.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To use an exercise bicycle, especially as part of a gym class.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An abnormal condition in journal bearings where the bearing seizes to the shaft that is rotating and rotates inside the journal, destroying both the shaft and the journal.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "blandish",
            "dress up",
            "gild",
            "put lipstick on",
            "sugarcoat",
            "whitewash",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/spin",
        "https://en.wiktionary.org/wiki/spins",
      ],
    },
  ],
  files: [
    {
      word: "files",
      phonetic: "/faɪlz/",
      phonetics: [
        {
          text: "/faɪlz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/files-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1684613",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A collection of papers collated and archived together.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A roll or list.", synonyms: [], antonyms: [] },
            {
              definition: "Course of thought; thread of narration.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An aggregation of data on a storage device, identified by a name.",
              synonyms: [],
              antonyms: [],
              example:
                "I'm going to delete these unwanted files to free up some disk space.",
            },
          ],
          synonyms: ["document", "paper"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To commit (official papers) to some office.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To place in an archive in a logical place and order",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To store a file (aggregation of data) on a storage medium such as a disc or another computer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(with for) To submit a formal request to some office.",
              synonyms: [],
              antonyms: [],
              example: "She filed for divorce the next day.",
            },
            {
              definition: "To set in order; to arrange, or lay away.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                'A column of people one behind another, whether "single file" or in a large group with many files side by side.',
              synonyms: [],
              antonyms: [],
              example: "The troops marched in Indian file.",
            },
            {
              definition: "A small detachment of soldiers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the eight vertical lines of squares on a chessboard (i.e., those identified by a letter). The analog horizontal lines are the ranks.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To move in a file.",
              synonyms: [],
              antonyms: [],
              example:
                "The applicants kept filing into the room until it was full.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A hand tool consisting of a handle to which a block of coarse metal is attached, and used for removing sharp edges or for cutting, especially through metal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A cunning or resourceful person.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To smooth, grind, or cut with a file.",
              synonyms: [],
              antonyms: [],
              example:
                "I'd better file the bottoms of the table legs. Otherwise they will scratch the flooring.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To defile", synonyms: [], antonyms: [] },
            { definition: "To corrupt", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/file",
        "https://en.wiktionary.org/wiki/files",
      ],
    },
  ],
  oxide: [
    {
      word: "oxide",
      phonetic: "/ˈɒksaɪd/",
      phonetics: [
        {
          text: "/ˈɒksaɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/oxide-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89844755",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A binary chemical compound of oxygen with another chemical element.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/oxide"],
    },
  ],
  pains: [
    {
      word: "pains",
      phonetic: "/peɪnz/",
      phonetics: [
        {
          text: "/peɪnz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pains-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2079729",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An ache or bodily suffering, or an instance of this; an unpleasant sensation, resulting from a derangement of functions, disease, or injury by violence; hurt.",
              synonyms: [],
              antonyms: [],
              example:
                "I had to stop running when I started getting pains in my feet.",
            },
            {
              definition:
                "The condition or fact of suffering or anguish especially mental, as opposed to pleasure; torment; distress",
              synonyms: [],
              antonyms: [],
              example: "In the final analysis, pain is a fact of life.",
            },
            {
              definition:
                "(from pain in the neck) An annoying person or thing.",
              synonyms: [],
              antonyms: [],
              example: "Your mother is a right pain.",
            },
            {
              definition: "Suffering inflicted as punishment or penalty.",
              synonyms: [],
              antonyms: [],
              example: "You may not leave this room on pain of death.",
            },
            {
              definition:
                "(chiefly in the plural) Labour; effort; great care or trouble taken in doing something.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["pest"],
          antonyms: ["pleasure"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To hurt; to put to bodily uneasiness or anguish; to afflict with uneasy sensations of any degree of intensity; to torment; to torture.",
              synonyms: [],
              antonyms: [],
              example: "The wound pained him.",
            },
            {
              definition:
                "To render uneasy in mind; to disquiet; to distress; to grieve.",
              synonyms: [],
              antonyms: [],
              example: "It pains me to say that I must let you go.",
            },
            {
              definition: "To inflict suffering upon as a penalty; to punish.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Any of various breads stuffed with a filling.",
              synonyms: [],
              antonyms: [],
              example: "gammon pain; Spanish pain",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pain",
        "https://en.wiktionary.org/wiki/pains",
      ],
    },
  ],
  photo: [
    {
      word: "photo",
      phonetic: "/ˈfəʊ.təʊ/",
      phonetics: [
        {
          text: "/ˈfəʊ.təʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/photo-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=31972273",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
        {
          text: "/ˈfoʊ.toʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/photo-us.ogg",
          sourceUrl:
            "https://commons.wikimedia.org/w/index.php?curid=112398767",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A photograph.", synonyms: [], antonyms: [] },
            { definition: "A photo finish.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To take a photograph of.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/photo"],
    },
  ],
  rival: [
    {
      word: "rival",
      phonetic: "/ˈɹaɪvəl/",
      phonetics: [
        {
          text: "/ˈɹaɪvəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rival-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100522",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A competitor (person, team, company, etc.) with the same goal as another, or striving to attain the same thing. Defeating a rival may be a primary or necessary goal of a competitor.",
              synonyms: [],
              antonyms: [],
              example: "Chris is my biggest rival in the 400-metre race.",
            },
            {
              definition:
                "Someone or something with similar claims of quality or distinction as another.",
              synonyms: [],
              antonyms: [],
              example: "As a social historian, he has no rival.",
            },
            {
              definition:
                "One having a common right or privilege with another; a partner.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To oppose or compete with.",
              synonyms: [],
              antonyms: [],
              example: "to rival somebody in love",
            },
            {
              definition: "To be equal to, or match, or to surpass another.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To strive to equal or excel; to emulate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Having the same pretensions or claims; standing in competition for superiority.",
              synonyms: [],
              antonyms: [],
              example: "rival lovers; rival claims or pretensions",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rival"],
    },
  ],
  flats: [
    {
      word: "flats",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An area of level ground.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A note played a semitone lower than a natural, denoted by the symbol ♭ placed after the letter representing the note (e.g., B♭) or in front of the note symbol (e.g. ♭♪).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A flat tyre/tire.", synonyms: [], antonyms: [] },
            {
              definition:
                "(in the plural) A type of ladies' shoes with very low heels.",
              synonyms: [],
              antonyms: [],
              example:
                "She liked to walk in her flats more than in her high heels.",
            },
            {
              definition:
                "(in the plural) A type of flat-soled running shoe without spikes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A thin, broad brush used in oil and watercolor/watercolour painting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The flat part of something:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A wide, shallow container or pallet.",
              synonyms: [],
              antonyms: [],
              example: "a flat of strawberries",
            },
            {
              definition:
                "(mail) A large mail piece measuring at least 8 1/2 by 11 inches, such as catalogs, magazines, and unfolded paper enclosed in large envelopes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A railroad car without a roof, and whose body is a platform without sides; a platform car or flatcar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A flat-bottomed boat, without keel, and of small draught.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A subset of n-dimensional space that is congruent to a Euclidean space of lower dimension.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A straw hat, broad-brimmed and low-crowned.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A flat sheet for use on a bed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A platform on a wheel, upon which emblematic designs etc. are carried in processions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A horizontal vein or ore deposit auxiliary to a main vein; also, any horizontal portion of a vein not elsewhere horizontal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A dull fellow; a simpleton.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(technical, theatre) A rectangular wooden structure covered with masonite, lauan, or muslin that depicts a building or other part of a scene, also called backcloth and backdrop.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various hesperiid butterflies that spread their wings open when they land.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An early kind of toy soldier having a flat design.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["sharp", "high heels"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make a flat call; to call without raising.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To become flat or flattened; to sink or fall to an even surface.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fall from the pitch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To depress in tone, as a musical note; especially, to lower in pitch by half a tone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make flat; to flatten; to level.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To render dull, insipid, or spiritless; to depress.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An apartment, usually on one level and usually consisting of more than one room.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["apartment"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To beat or strike; pound",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To dash or throw", synonyms: [], antonyms: [] },
            { definition: "To dash, rush", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/flat",
        "https://en.wiktionary.org/wiki/flats",
      ],
    },
  ],
  syrup: [
    {
      word: "syrup",
      phonetic: "/ˈsɪ.ɹəp/",
      phonetics: [
        { text: "/ˈsɪ.ɹəp/", audio: "" },
        {
          text: "/ˈsi.ɹəp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/syrup-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780428",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any thick liquid that has a high sugar content and which is added to or poured over food as a flavouring.",
              synonyms: [],
              antonyms: [],
              example: "maple syrup",
            },
            {
              definition: "(by extension) Any viscous liquid.",
              synonyms: [],
              antonyms: [],
              example: "cough syrup",
            },
            {
              definition: '(shortened from "syrup of figs") A wig.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/syrup"],
    },
  ],
  rodeo: [
    {
      word: "rodeo",
      phonetic: "/ɹoʊ.ˈdeɪ.oʊ/",
      phonetics: [
        {
          text: "/ɹoʊ.ˈdeɪ.oʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rodeo-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80066782",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A gathering of cattle to be branded.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A North American sport involving skills with horses, cows and other livestock.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An entertainment event associated with the sport.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To perform in a rodeo show.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rodeo"],
    },
  ],
  sands: [
    {
      word: "sands",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sands-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651457",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Rock that is ground more finely than gravel, but is not as fine as silt (more formally, see grain sizes chart), forming beaches and deserts and also used in construction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(often in the plural) A beach or other expanse of sand.",
              synonyms: [],
              antonyms: [],
              example: "The Canadian tar sands are a promising source of oil.",
            },
            {
              definition: "(circa 1920) Personal courage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A particle from 62.5 microns to 2 mm in diameter, following the Wentworth scale.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A light beige colour, like that of typical sand.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A single grain of sand.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A moment or interval of time; the term or extent of one's life (referring to the sand in an hourglass).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To abrade the surface of (something) with sand or sandpaper in order to smooth or clean it.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To cover with sand.", synonyms: [], antonyms: [] },
            {
              definition: "To blot ink using sand.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A sandpiper.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sand",
        "https://en.wiktionary.org/wiki/sands",
      ],
    },
  ],
  moose: [
    {
      word: "moose",
      phonetic: "/muːs/",
      phonetics: [
        {
          text: "/muːs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/moose-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75833696",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/muːs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/moose-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50422650",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The largest member of the deer family (Alces americanus, sometimes included in Alces alces), of which the male has very large, palmate antlers.",
              synonyms: [],
              antonyms: [],
              example: "We saw a moose at the edge of the woods.",
            },
            { definition: "An ugly person.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["Newfoundland speed bump", "elk"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/moose"],
    },
    {
      word: "moose",
      phonetic: "/muːs/",
      phonetics: [
        {
          text: "/muːs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/moose-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75833696",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/muːs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/moose-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50422650",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An Asian girl taken as a lover.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/moose"],
    },
  ],
  pints: [
    {
      word: "pints",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A unit of volume, equivalent to:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(metonym) A pint of milk.",
              synonyms: [],
              antonyms: [],
              example: "Please leave three pints tomorrow, milkman.",
            },
            {
              definition:
                "(metonymy) A glass of beer or cider, served by the pint.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pint",
        "https://en.wiktionary.org/wiki/pints",
      ],
    },
  ],
  curly: [
    {
      word: "curly",
      phonetic: "/ˈkɜːli/",
      phonetics: [
        {
          text: "/ˈkɜːli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/curly-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=94707607",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈkɝli/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A person or animal with curly hair.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Having curls.", synonyms: [], antonyms: [] },
            {
              definition:
                "Curling in a direction, as opposed to straight (quotation marks or apostrophes)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Complicated and difficult; knotty.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/curly"],
    },
  ],
  comic: [
    {
      word: "comic",
      phonetic: "/ˈkɒmɪk/",
      phonetics: [
        { text: "/ˈkɒmɪk/", audio: "" },
        {
          text: "/ˈkɑmɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/comic-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762645",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A comedian.", synonyms: [], antonyms: [] },
            {
              definition:
                "A story composed of cartoon images arranged in sequence, usually with textual captions; a graphic novel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A children's newspaper.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Funny; amusing; comical.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Relating to comedy.",
              synonyms: [],
              antonyms: [],
              example: "a comic stereotype",
            },
          ],
          synonyms: ["comedic", "comical"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/comic"],
    },
  ],
  cloak: [
    {
      word: "cloak",
      phonetic: "/ˈkloʊk/",
      phonetics: [
        {
          text: "/ˈkloʊk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cloak-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100651",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A long outer garment worn over the shoulders covering the back; a cape, often with a hood.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A blanket-like covering, often metaphorical.",
              synonyms: [],
              antonyms: [],
              example: "Night hid her movements with its cloak of darkness.",
            },
            {
              definition: "That which conceals; a disguise or pretext.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A text replacement for an IRC user's hostname or IP address, making the user less identifiable.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cover as with a cloak.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To hide or conceal.", synonyms: [], antonyms: [] },
            {
              definition:
                "To render or become invisible via futuristic technology.",
              synonyms: [],
              antonyms: [],
              example:
                "The ship cloaked before entering the enemy sector of space.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cloak"],
    },
  ],
  onion: [
    {
      word: "onion",
      phonetic: "/ˈʌŋjɪn/",
      phonetics: [
        { text: "/ˈʌŋjɪn/", audio: "" },
        {
          text: "/ˈʌnjən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/onion-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9023290",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ˈʌnjən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/onion-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1235964",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A monocotyledonous plant (Allium cepa), allied to garlic, used as vegetable and spice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The bulb of such a plant.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The genus as a whole.", synonyms: [], antonyms: [] },
            { definition: "A ball.", synonyms: [], antonyms: [] },
            {
              definition: "A person from Bermuda or of Bermudian descent.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["violet"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/onion"],
    },
  ],
  clams: [
    {
      word: "clams",
      phonetic: "/klæmz/",
      phonetics: [{ text: "/klæmz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Acronym of Clip-on Load Adjusting Mechanism. A device that can be fitted onto an oar to adjust set.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A bivalve mollusk of many kinds, especially those that are edible; for example the soft-shell clam (Mya arenaria), the hard clam (Mercenaria mercenaria), the sea clam or hen clam (Spisula solidissima), and other species. The name is said to have been given originally to the Tridacna gigas, a huge East Indian bivalve.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Strong pincers or forceps.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A kind of vise, usually of wood.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A dollar (usually used in the plural).",
              synonyms: [],
              antonyms: [],
              example: "Those sneakers cost me fifty clams!",
            },
            { definition: "A Scientologist.", synonyms: [], antonyms: [] },
            { definition: "A vagina.", synonyms: [], antonyms: [] },
            {
              definition:
                "One who clams up; a taciturn person, one who refuses to speak.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To dig for clams.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A crash or clangor made by ringing all the bells of a chime at once.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To produce, in bellringing, a clam or clangor; to cause to clang.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To be moist or glutinous; to stick; to adhere.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To clog, as with glutinous or viscous matter.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/CLAM",
        "https://en.wiktionary.org/wiki/clam",
        "https://en.wiktionary.org/wiki/clams",
      ],
    },
  ],
  scrap: [
    {
      word: "scrap",
      phonetic: "/skɹæp/",
      phonetics: [
        {
          text: "/skɹæp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scrap-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453696",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A (small) piece; a fragment; a detached, incomplete portion.",
              synonyms: [],
              antonyms: [],
              example: "I found a scrap of cloth to patch the hole.",
            },
            {
              definition: "(usually in the plural) Leftover food.",
              synonyms: [],
              antonyms: [],
              example: "Give the scraps to the dogs and watch them fight.",
            },
            {
              definition:
                "The crisp substance that remains after drying out animal fat.",
              synonyms: [],
              antonyms: [],
              example: "pork scraps",
            },
            {
              definition:
                "Discarded objects (especially metal) that may be dismantled to recover their constituent materials, junk.",
              synonyms: [],
              antonyms: [],
              example: "That car isn't good for anything but scrap.",
            },
            {
              definition:
                "(in the plural) A piece of deep-fried batter left over from frying fish, sometimes sold with chips.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A Hispanic criminal, especially a Mexican or one affiliated with the Norte gang.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A snare for catching birds.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To discard.", synonyms: [], antonyms: [] },
            {
              definition:
                "(of a project or plan) To stop working on indefinitely.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To scrapbook; to create scrapbooks.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To dispose of at a scrapyard.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To make into scrap.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scrap"],
    },
    {
      word: "scrap",
      phonetic: "/skɹæp/",
      phonetics: [
        {
          text: "/skɹæp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scrap-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453696",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A fight, tussle, skirmish.",
              synonyms: [],
              antonyms: [],
              example: "We got in a little scrap over who should pay the bill.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [{ definition: "To fight", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scrap"],
    },
  ],
  didst: [
    {
      word: "didst",
      phonetic: "/ˈdɪdst/",
      phonetics: [
        {
          text: "/ˈdɪdst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/didst-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453959",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(auxiliary) A syntactic marker.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To perform; to execute.",
              synonyms: ["accomplish", "carry out", "functionate"],
              antonyms: [],
              example:
                "All you ever do is surf the Internet. What will you do this afternoon?",
            },
            {
              definition: "To cause, make (someone) (do something).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To suffice.",
              synonyms: [],
              antonyms: [],
              example:
                "it’s not the best broom, but it will have to do;  this will do me, thanks.",
            },
            {
              definition: "To be reasonable or acceptable.",
              synonyms: [],
              antonyms: [],
              example:
                "It simply will not do to have dozens of children running around such a quiet event.",
            },
            {
              definition: "(ditransitive) To have (as an effect).",
              synonyms: [],
              antonyms: [],
              example: "The fresh air did him some good.",
            },
            {
              definition: "To fare, perform (well or poorly).",
              synonyms: [],
              antonyms: [],
              example:
                "Our relationship isn't doing very well;  how do you do?",
            },
            {
              definition: "(chiefly in questions) To have as one's job.",
              synonyms: [],
              antonyms: [],
              example: "What does Bob do? — He's a plumber.",
            },
            {
              definition:
                "To perform the tasks or actions associated with (something).",
              synonyms: [],
              antonyms: [],
              example:
                "\"Don't forget to do your report\" means something quite different depending on whether you're a student or a programmer.",
            },
            {
              definition: "To cook.",
              synonyms: [],
              antonyms: [],
              example: "I'll just do some eggs.",
            },
            {
              definition: "To travel in, to tour, to make a circuit of.",
              synonyms: [],
              antonyms: [],
              example: "Let’s do New York also.",
            },
            {
              definition: "To treat in a certain way.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To work for or on, by way of caring for, looking after, preparing, cleaning, keeping in order, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To act or behave in a certain manner; to conduct oneself.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To spend (time) in jail. (See also do time)",
              synonyms: ["serve"],
              antonyms: [],
              example: "I did five years for armed robbery.",
            },
            {
              definition: "To impersonate or depict.",
              synonyms: ["imitate", "personate", "take off"],
              antonyms: [],
              example:
                "They really laughed when he did Clinton, with a perfect accent and a leer.",
            },
            {
              definition:
                "(with 'a' and the name of a person, place, event, etc.) To copy or emulate the actions or behaviour that is associated with the person or thing mentioned.",
              synonyms: [],
              antonyms: [],
              example: "He did a Henry VIII and got married six times.",
            },
            {
              definition: "To kill.",
              synonyms: ["do in", "murder", "off", "rub out"],
              antonyms: [],
            },
            {
              definition:
                "To deal with for good and all; to finish up; to undo; to ruin; to do for.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To punish for a misdemeanor.",
              synonyms: [],
              antonyms: [],
              example: "He got done for speeding.",
            },
            {
              definition: "To have sex with. (See also do it)",
              synonyms: ["go to bed with", "sleep with"],
              antonyms: [],
            },
            {
              definition: "To cheat or swindle.",
              synonyms: ["defraud", "diddle", "mug off", "rip off", "scam"],
              antonyms: [],
              example: "That guy just did me out of two hundred bucks!",
            },
            {
              definition:
                "To convert into a certain form; especially, to translate.",
              synonyms: [],
              antonyms: [],
              example:
                "the novel has just been done into English;  I'm going to do this play into a movie",
            },
            { definition: "To finish.", synonyms: [], antonyms: [] },
            {
              definition: "To work as a domestic servant (with for).",
              synonyms: ["attend", "serve", "wait on"],
              antonyms: [],
            },
            {
              definition:
                "(auxiliary) Used to form the present progressive of verbs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cash or to advance money for, as a bill or note.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(ditransitive) To make or provide.",
              synonyms: ["furnish", "give", "supply"],
              antonyms: [],
              example:
                "Could you do me a burger with mayonnaise instead of ketchup?",
            },
            {
              definition: "To injure (one's own body part).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To take drugs.",
              synonyms: [],
              antonyms: [],
              example: "I do cocaine.",
            },
            {
              definition:
                "(in the form be doing [somewhere]) To exist with a purpose or for a reason.",
              synonyms: [],
              antonyms: [],
              example: "What's that car doing in our swimming pool?",
            },
          ],
          synonyms: [
            "accomplish",
            "carry out",
            "functionate",
            "attend",
            "serve",
            "wait on",
            "defraud",
            "diddle",
            "mug off",
            "rip off",
            "scam",
            "do in",
            "murder",
            "off",
            "rub out",
            "furnish",
            "give",
            "supply",
            "go to bed with",
            "sleep with",
            "imitate",
            "personate",
            "take off",
            "serve",
          ],
          antonyms: ["don't"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/didst",
        "https://en.wiktionary.org/wiki/do",
      ],
    },
  ],
  couch: [
    {
      word: "couch",
      phonetic: "/kaʊtʃ/",
      phonetics: [
        {
          text: "/kaʊtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/couch-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75729703",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/kaʊtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/couch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1317709",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Couch grass, a species of persistent grass, Elymus repens, usually considered a weed.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/couch"],
    },
  ],
  codes: [
    {
      word: "codes",
      phonetic: "/kəʊdz/",
      phonetics: [
        { text: "/kəʊdz/", audio: "" },
        {
          text: "/koʊdz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/codes-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2707471",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A short symbol, often with little relation to the item it represents.",
              synonyms: [],
              antonyms: [],
              example: "This flavour of soup has been assigned the code WRT-9.",
            },
            {
              definition:
                "A body of law, sanctioned by legislation, in which the rules of law to be specifically applied by the courts are set forth in systematic form; a compilation of laws by public authority; a digest.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any system of principles, rules or regulations relating to one subject.",
              synonyms: [],
              antonyms: [],
              example:
                "The medical code is a system of rules for the regulation of the professional conduct of physicians.",
            },
            {
              definition:
                "A set of rules for converting information into another form or representation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A message represented by rules intended to conceal its meaning.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cryptographic system using a codebook that converts words or phrases into codewords.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Instructions for a computer, written in a programming language; the input of a translator, an interpreter or a browser, namely: source code, machine code, bytecode.",
              synonyms: [],
              antonyms: [],
              example: "I wrote some code to reformat text documents.",
            },
            {
              definition: "(scientific programming) A program.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A particular lect or language variety.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An emergency requiring situation-trained members of the staff.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To write software programs.",
              synonyms: [],
              antonyms: [],
              example:
                "I learned to code on an early home computer in the 1980s.",
            },
            {
              definition: "To add codes to a dataset.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To categorise by assigning identifiers from a schedule, for example CPT coding for medical insurance purposes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To encode.",
              synonyms: [],
              antonyms: [],
              example: "We should code the messages we send out on Usenet.",
            },
            { definition: "To encode a protein.", synonyms: [], antonyms: [] },
            {
              definition: "To call a hospital emergency code.",
              synonyms: [],
              antonyms: [],
              example: "coding in the CT scanner",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "Of a patient, to suffer a sudden medical emergency (a code blue) such as cardiac arrest.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/code",
        "https://en.wiktionary.org/wiki/codes",
      ],
    },
  ],
  fails: [
    {
      word: "fails",
      phonetic: "/feɪlz/",
      phonetics: [{ text: "/feɪlz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Poor quality; substandard workmanship.",
              synonyms: [],
              antonyms: [],
              example: "The project was full of fail.",
            },
            {
              definition: "A failure (condition of being unsuccessful)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A failure (something incapable of success)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A failure, especially of a financial transaction (a termination of an action).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A failing grade in an academic examination.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To be unsuccessful.",
              synonyms: [],
              antonyms: [],
              example: "Throughout my life, I have always failed.",
            },
            {
              definition:
                "Not to achieve a particular stated goal. (Usage note: The direct object of this word is usually an infinitive.)",
              synonyms: [],
              antonyms: [],
              example: "The truck failed to start.",
            },
            {
              definition: "To neglect.",
              synonyms: [],
              antonyms: [],
              example:
                "The report fails to take into account all the mitigating factors.",
            },
            {
              definition: "Of a machine, etc.: to cease to operate correctly.",
              synonyms: [],
              antonyms: [],
              example: "After running five minutes, the engine failed.",
            },
            {
              definition:
                "To be wanting to, to be insufficient for, to disappoint, to desert.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To receive one or more non-passing grades in academic pursuits.",
              synonyms: [],
              antonyms: [],
              example: "I failed English last year.",
            },
            {
              definition:
                "To give a student a non-passing grade in an academic endeavour.",
              synonyms: [],
              antonyms: [],
              example:
                "The professor failed me because I did not complete any of the course assignments.",
            },
            {
              definition: "To miss attaining; to lose.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be wanting; to fall short; to be or become deficient in any measure or degree up to total absence.",
              synonyms: [],
              antonyms: [],
              example: "The crops failed last year.",
            },
            {
              definition:
                "To be affected with want; to come short; to lack; to be deficient or unprovided; used with of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To fall away; to become diminished; to decline; to decay; to sink.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To deteriorate in respect to vigour, activity, resources, etc.; to become weaker.",
              synonyms: [],
              antonyms: [],
              example: "A sick man fails.",
            },
            {
              definition: "To perish; to die; used of a person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To err in judgment; to be mistaken.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To become unable to meet one's engagements; especially, to be unable to pay one's debts or discharge one's business obligation; to become bankrupt or insolvent.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["fall on one's face", "flunk"],
          antonyms: ["succeed"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A piece of turf cut from grassland.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fail",
        "https://en.wiktionary.org/wiki/fails",
      ],
    },
  ],
  ounce: [
    {
      word: "ounce",
      phonetic: "/aʊns/",
      phonetics: [
        {
          text: "/aʊns/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ounce-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80066553",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An avoirdupois ounce, weighing 1/16 of an avoirdupois pound, or 28.3495 grams.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A troy ounce, weighing 1/12 of a troy pound, or 480 grains, or 31.1035 grams.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A US fluid ounce, with a volume of 1/16 of a US pint, 1.8047 cubic inches or 29.5735 millilitres.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A British imperial fluid ounce, with a volume of 1/20 of an imperial pint, 1.7339 cubic inches or 28.4131 millilitres.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A little bit.",
              synonyms: [],
              antonyms: [],
              example:
                "He didn't feel even an ounce of regret for his actions.",
            },
          ],
          synonyms: ["℥"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ounce"],
    },
    {
      word: "ounce",
      phonetic: "/aʊns/",
      phonetics: [
        {
          text: "/aʊns/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ounce-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80066553",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A large wild feline, such as a lynx or cougar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Now specifically, the snow leopard, Uncia uncia.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ounce"],
    },
  ],
  lodge: [
    {
      word: "lodge",
      phonetic: "/lɒdʒ/",
      phonetics: [
        { text: "/lɒdʒ/", audio: "" },
        {
          text: "/lɑdʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lodge-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780198",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A building for recreational use such as a hunting lodge or a summer cabin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Short for porter's lodge: a building or room near the entrance of an estate or building, especially as a college mailroom.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A local chapter of some fraternities, such as freemasons.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A local chapter of a trade union.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A rural hotel or resort, an inn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A beaver's shelter constructed on a pond or lake.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A den or cave.", synonyms: [], antonyms: [] },
            {
              definition:
                "The chamber of an abbot, prior, or head of a college.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The space at the mouth of a level next to the shaft, widened to permit wagons to pass, or ore to be deposited for hoisting; called also platt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A collection of objects lodged together.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An indigenous American home, such as tipi or wigwam. By extension, the people who live in one such home; a household.",
              synonyms: [],
              antonyms: [],
              example:
                "The tribe consists of about two hundred lodges, that is, of about a thousand individuals.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To be firmly fixed in a specified position.",
              synonyms: [],
              antonyms: [],
              example: "I've got some spinach lodged between my teeth.",
            },
            {
              definition:
                "To stay in a boarding-house, paying rent to the resident landlord or landlady.",
              synonyms: [],
              antonyms: [],
              example: "The detective Sherlock Holmes lodged in Baker Street.",
            },
            {
              definition: "To stay in any place or shelter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To drive (an animal) to covert.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To supply with a room or place to sleep in for a time.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To put money, jewellery, or other valuables for safety.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To place (a statement, etc.) with the proper authorities (such as courts, etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To become flattened, as grass or grain, when overgrown or beaten down by the wind.",
              synonyms: [],
              antonyms: [],
              example: "The heavy rain caused the wheat to lodge.",
            },
            {
              definition: "To cause to flatten, as grass or grain.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["stay over", "stop"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lodge"],
    },
  ],
  greet: [
    {
      word: "greet",
      phonetic: "/ɡɹiːt/",
      phonetics: [
        {
          text: "/ɡɹiːt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/greet-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2650995",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To welcome in a friendly manner, either in person or through another means e.g. writing or over the phone/internet",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To arrive at or reach, or meet (talking of something which brings joy)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To accost; to address.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To meet and give salutations.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be perceived by (somebody).",
              synonyms: [],
              antonyms: [],
              example:
                "A brilliant dawn greeted her eyes as she looked out of the window.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/greet"],
    },
    {
      word: "greet",
      phonetic: "/ɡɹiːt/",
      phonetics: [
        {
          text: "/ɡɹiːt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/greet-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2650995",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "(obsolete outside Scotland) Great.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/greet"],
    },
    {
      word: "greet",
      phonetic: "/ɡɹiːt/",
      phonetics: [
        {
          text: "/ɡɹiːt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/greet-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2650995",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Mourning, weeping, lamentation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To weep; to cry.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/greet"],
    },
  ],
  gypsy: [
    {
      word: "gypsy",
      phonetic: "/ˈd͡ʒɪp.si/",
      phonetics: [
        {
          text: "/ˈd͡ʒɪp.si/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gypsy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780122",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(sometimes offensive) A member of the Romani people, or one of the sub-groups (Roma, Sinti, Romanichal, etc).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["Rom", "Roma", "Romani", "tzigane", "zigeuner"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(sometimes offensive) Of or belonging to the Romani people or one of it sub-groups (Roma, Sinti, Romanichel, etc).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An itinerant person or any person, not necessarily Romani; a tinker, a traveller or a carny.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(sometimes offensive) A move in contra dancing in which two dancers walk in a circle around each other while maintaining eye contact (but not touching as in a swing). (Compare whole gyp, half gyp, and gypsy meltdown, in which this step precedes a swing.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A member of a Broadway musical chorus line.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person with a dark complexion.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A sly, roguish woman.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["gip", "gyp", "gyre"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To roam around the country like a gypsy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To perform the gypsy step in contra dancing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Of or having the qualities of an itinerant person or group with qualities traditionally ascribed to Romani people; making a living from dishonest practices or theft etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/Gypsy",
        "https://en.wiktionary.org/wiki/gypsy",
      ],
    },
  ],
  utter: [
    {
      word: "utter",
      phonetic: "/ˈʌtə/",
      phonetics: [
        { text: "/ˈʌtə/", audio: "" },
        {
          text: "/ˈʌtɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/utter-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1684761",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Outer; furthest out, most remote.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Outward.", synonyms: [], antonyms: [] },
            {
              definition: "Absolute, unconditional, total, complete.",
              synonyms: [],
              antonyms: [],
              example: "utter ruin; utter darkness",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/utter"],
    },
    {
      word: "utter",
      phonetic: "/ˈʌtə/",
      phonetics: [
        { text: "/ˈʌtə/", audio: "" },
        {
          text: "/ˈʌtɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/utter-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1684761",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To produce (speech or other sounds) with one's voice.",
              synonyms: ["let out", "say", "speak"],
              antonyms: [],
              example: "Don't you utter another word!",
            },
            {
              definition:
                "To reveal or express (an idea, thought, desire, etc.) with speech.",
              synonyms: ["declare", "say", "tell"],
              antonyms: [],
            },
            {
              definition: "To produce (a noise) (of an inanimate object).",
              synonyms: ["emit", "let out"],
              antonyms: [],
              example:
                "Sally's car uttered a hideous shriek when she applied the brakes.",
            },
            {
              definition: "To spit or blow (something) out of one's mouth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To emit or give off (breath).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To shed (a tear or tears).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To offer (something) for sale; to sell.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To put (currency) into circulation.",
              synonyms: ["circulate"],
              antonyms: [],
            },
            {
              definition:
                "To show (something that has been hidden); to reveal the identity of (someone).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To send or put (something) out.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "circulate",
            "declare",
            "say",
            "tell",
            "emit",
            "let out",
            "let out",
            "say",
            "speak",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/utter"],
    },
  ],
  paved: [
    {
      word: "paved",
      phonetic: "/peɪvd/",
      phonetics: [
        {
          text: "/peɪvd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/paved-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=892540",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cover something with paving slabs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cover with stone, concrete, blacktop or other solid covering, especially to aid travel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To pave the way for; to make easy and smooth.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Covered in pavement; having a hard surface, as of concrete or asphalt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Laid out or made, as intentions, desires, plans, etc.",
              synonyms: [],
              antonyms: [],
              example: "The road to Hell is paved with good intentions.",
            },
          ],
          synonyms: ["paven"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pave",
        "https://en.wiktionary.org/wiki/paved",
      ],
    },
  ],
  zones: [
    {
      word: "zones",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Each of the five regions of the earth's surface into which it was divided by climatic differences, namely the torrid zone (between the tropics), two temperate zones (between the tropics and the polar circles), and two frigid zones (within the polar circles).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any given region or area of the world.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A given area distinguished on the basis of a particular characteristic, use, restriction, etc.",
              synonyms: [],
              antonyms: [],
              example:
                "Files in the Internet zone are blocked by default, as a security measure.",
            },
            {
              definition: "A band or area of growth encircling anything.",
              synonyms: [],
              antonyms: [],
              example:
                "a zone of evergreens on a mountain; the zone of animal or vegetable life in the ocean around an island or a continent",
            },
            {
              definition: "A band or stripe extending around a body.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A series of planes having mutually parallel intersections.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The strike zone.",
              synonyms: [],
              antonyms: [],
              example: "That pitch was low and away, just outside of the zone.",
            },
            {
              definition:
                "Every of the three parts of an ice rink, divided by two blue lines.",
              synonyms: [],
              antonyms: [],
              example:
                "Players are off side, if they enter the attacking zone before the puck.",
            },
            {
              definition: "A semicircular area in front of each goal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A high-performance phase or period.",
              synonyms: [],
              antonyms: [],
              example:
                "I just got in the zone late in the game: everything was going in.",
            },
            {
              definition:
                "A defensive scheme where defenders guard a particular area of the court or field, as opposed to a particular opposing player.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That collection of a domain's DNS resource records, the domain and its subdomains, that are not delegated to another authority.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Apple computing) A logical group of network devices on AppleTalk.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A belt or girdle.", synonyms: [], antonyms: [] },
            {
              definition:
                "The curved surface of a frustum of a sphere, the portion of surface of a sphere delimited by parallel planes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(perhaps by meronymy) A frustum of a sphere.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A circuit; a circumference.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "area",
            "belt",
            "district",
            "region",
            "section",
            "sector",
            "sphere",
            "territory",
            "crease",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To divide into or assign sections or areas.",
              synonyms: [],
              antonyms: [],
              example:
                "Please zone off our staging area, a section for each group.",
            },
            {
              definition:
                "To define the property use classification of an area.",
              synonyms: [],
              antonyms: [],
              example: "This area was zoned for industrial use.",
            },
            {
              definition:
                "To enter a daydream state temporarily, for instance as a result of boredom, fatigue, or intoxication; to doze off.",
              synonyms: [],
              antonyms: [],
              example:
                'Everyone just put their goddamn heads together and zoned. (Byron Coley, liner notes for the album "Piece for Jetsun Dolma" by Thurston Moore)',
            },
            {
              definition: "To girdle or encircle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["doze off", "zone out"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/zone",
        "https://en.wiktionary.org/wiki/zones",
      ],
    },
  ],
  fours: [
    {
      word: "fours",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The digit or figure 4; an occurrence thereof.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Anything measuring four units, as length.",
              synonyms: [],
              antonyms: [],
              example:
                "Do you have any more fours? I want to make this a little taller.",
            },
            {
              definition: "A person who is four years old.",
              synonyms: [],
              antonyms: [],
              example:
                "I'll take the threes, fours and fives and go to the playground.",
            },
            {
              definition:
                "An event in which the batsmen run four times between the wickets or, more often, a batsman hits a ball which bounces on the ground before passing over a boundary, resulting in an award of 4 runs for the batting team. If the ball does not bounce before passing over the boundary, a six is awarded instead.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A power forward.", synonyms: [], antonyms: [] },
            {
              definition:
                "Four-man sweep racing shell, with or without a coxswain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A four-pennyworth of spirits.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A pair of fours.", synonyms: [], antonyms: [] },
            {
              definition: "The cells located on the third floor.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/four",
        "https://en.wiktionary.org/wiki/fours",
      ],
    },
  ],
  alley: [
    {
      word: "alley",
      phonetic: "/ˈæ.li/",
      phonetics: [
        {
          text: "/ˈæ.li/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/alley-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762575",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A narrow street or passageway, especially one through the middle of a block giving access to the rear of lots or buildings.",
              synonyms: ["alleyway"],
              antonyms: [],
              example:
                "The parking lot to my friend's apartment building is in the alley.",
            },
            {
              definition: "The area between the outfielders.",
              synonyms: ["gap"],
              antonyms: [],
              example: "He hit one deep into the alley.",
            },
            {
              definition: "An establishment where bowling is played.",
              synonyms: ["bowling alley"],
              antonyms: [],
            },
            {
              definition:
                "An elongated wooden strip of floor along which a bowling ball is rolled.",
              synonyms: ["lane"],
              antonyms: [],
            },
            {
              definition:
                "The extra area between the sidelines or tramlines on a tennis court that is used for doubles matches.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A walk or passage in a garden or park, bordered by rows of trees or bushes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A passageway between rows of pews in a church.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(perspective drawing) Any passage having the entrance represented as wider than the exit, so as to give the appearance of length.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The space between two rows of compositors' stands in a printing office.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["alleyway", "bowling alley", "gap", "lane"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/alley"],
    },
    {
      word: "alley",
      phonetic: "/ˈæ.li/",
      phonetics: [
        {
          text: "/ˈæ.li/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/alley-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762575",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A glass marble or taw.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/alley"],
    },
  ],
  tiles: [
    {
      word: "tiles",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A regularly-shaped slab of clay or other material, affixed to cover or decorate a surface, as in a roof-tile, glazed tile, stove tile, carpet tile etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A rectangular graphic.",
              synonyms: [],
              antonyms: [],
              example:
                "Each tile within Google Maps consists of 256 × 256 pixels.",
            },
            {
              definition:
                "Any of various flat cuboid playing pieces used in certain games, such as dominoes, Scrabble, or mahjong.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A stiff hat.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cover with tiles.",
              synonyms: [],
              antonyms: [],
              example: "The handyman tiled the kitchen.",
            },
            {
              definition:
                "To arrange in a regular pattern, with adjoining edges (applied to tile-like objects, graphics, windows in a computer interface).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To optimize (a loop in program code) by means of the tiling technique.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To protect from the intrusion of the uninitiated.",
              synonyms: [],
              antonyms: [],
              example: "tile the door",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tile",
        "https://en.wiktionary.org/wiki/tiles",
      ],
    },
  ],
  bless: [
    {
      word: "bless",
      phonetic: "/blɛs/",
      phonetics: [
        {
          text: "/blɛs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bless-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749723",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make something holy by religious rite, sanctify.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make the sign of the cross upon, so as to sanctify.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To invoke divine favor upon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To honor as holy, glorify; to extol for excellence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To esteem or account happy; to felicitate.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To wave; to brandish.", synonyms: [], antonyms: [] },
            {
              definition:
                "(past tense only blessed) To turn (a reference) into an object.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(with from) To secure, defend, or prevent from.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["unbless", "condemn", "curse"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bless"],
    },
    {
      word: "bless",
      phonetic: "/blɛs/",
      phonetics: [
        {
          text: "/blɛs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bless-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749723",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition:
                "Used as an expression of endearment, gratitude, or (ironically) belittlement.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bless"],
    },
  ],
  crest: [
    {
      word: "crest",
      phonetic: "/kɹɛst/",
      phonetics: [
        {
          text: "/kɹɛst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/crest-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=86873003",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The summit of a hill or mountain ridge.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A tuft, or other natural ornament, growing on an animal's head, for example the comb of a cockerel, the swelling on the head of a snake, the lengthened feathers of the crown or nape of bird, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The plume of feathers, or other decoration, worn on or displayed on a helmet; the distinctive ornament of a helmet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bearing worn, not upon the shield, but usually on a helmet above it, sometimes (as for clerics) separately above the shield or separately as a mark for plate, in letterheads, and the like.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The upper curve of a horse's neck.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The ridge or top of a wave.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The helm or head, as typical of a high spirit; pride; courage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The ornamental finishing which surmounts the ridge of a roof, canopy, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The top line of a slope or embankment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A ridge along the surface of a bone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A design or logo, especially one of an institution, association or high-class family.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of several birds in the family Regulidae, including the goldcrests and firecrests.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["cockscomb", "comb"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "Particularly with reference to waves, to reach a peak.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To reach the crest of (a hill or mountain)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To furnish with, or surmount as, a crest; to serve as a crest for.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To mark with lines or streaks like waving plumes.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/crest"],
    },
  ],
  elder: [
    {
      word: "elder",
      phonetic: "/ˈeldə/",
      phonetics: [
        { text: "/ˈeldə/", audio: "" },
        { text: "/ˈɛldə/", audio: "" },
        { text: "/ˈɛldɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An older person or an older member, usually a leader, of some community.",
              synonyms: [],
              antonyms: [],
              example: "We were presented to the village elder.",
            },
            {
              definition: "One who is older than another.",
              synonyms: [],
              antonyms: [],
              example: "Respect your elders.",
            },
            {
              definition: "One who lived at an earlier period; a predecessor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An officer of a church, sometimes having teaching responsibilities.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A clergyman authorized to administer all the sacraments.",
              synonyms: [],
              antonyms: [],
              example: "a travelling elder",
            },
            {
              definition:
                "One ordained to the lowest office in the Melchizedek priesthood.",
              synonyms: [],
              antonyms: [],
              example:
                "After being a member of the Church for a while, Bill was ordained to the office of elder.",
            },
            {
              definition: "Male missionary.",
              synonyms: [],
              antonyms: [],
              example: "The elders are coming over for dinner tonight.",
            },
            {
              definition:
                "(often capitalized) Title for a male missionary; title for a general authority.",
              synonyms: [],
              antonyms: [],
              example:
                "One of the long-time leaders in the Church is Elder Packer.",
            },
            {
              definition: "A pagan or Heathen priest or priestess.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["eld", "mzee", "senior", "antecessor", "forerunner"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To admonish or reprove for improper conduct by the elders of the meeting.",
              synonyms: [],
              antonyms: [],
              example:
                "I was eldered for directly responding to someone else's message in meeting for worship.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Of an object, concept, relationship, etc., having existed for a relatively long period of time.",
              synonyms: [],
              antonyms: [],
              example: "an old abandoned building;  an old friend",
            },
            {
              definition: "Having been used and thus no longer new or unused.",
              synonyms: [],
              antonyms: [],
              example:
                "I find that an old toothbrush is good to clean the keyboard with.",
            },
            {
              definition: "Having existed or lived for the specified time.",
              synonyms: [],
              antonyms: [],
              example:
                "How old are they? She’s five years old and he's seven. We also have a young teen and a two-year-old child.",
            },
            {
              definition: "(heading) Of an earlier time.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Tiresome after prolonged repetition.",
              synonyms: [],
              antonyms: [],
              example: "Your constant pestering is getting old.",
            },
            {
              definition:
                "Said of subdued colors, particularly reds, pinks and oranges, as if they had faded over time.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A grammatical intensifier, often used in describing something positive. (Mostly in idioms like good old, big old and little old, any old and some old.)",
              synonyms: [],
              antonyms: [],
              example:
                "We're having a good old time. My next car will be a big old SUV.  My wife makes the best little old apple pie in Texas.",
            },
            { definition: "Excessive, abundant.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "erstwhile",
            "ex-",
            "former",
            "one-time",
            "past",
            "ancient",
            "long in the tooth",
            "aged",
            "of age",
            "aged",
            "ageing",
            "aging",
            "elderly",
            "long in the tooth",
            "on in years",
            "antiquated",
            "obsolete",
            "outdated",
          ],
          antonyms: [
            "current",
            "latest",
            "new",
            "brand new",
            "fresh",
            "new",
            "young",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/elder",
        "https://en.wiktionary.org/wiki/old",
      ],
    },
    {
      word: "elder",
      phonetic: "/ˈeldə/",
      phonetics: [
        { text: "/ˈeldə/", audio: "" },
        { text: "/ˈɛldə/", audio: "" },
        { text: "/ˈɛldɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small tree, Sambucus nigra, having white flowers in a cluster, and edible purple berries",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of the other species of the genus Sambucus: small trees, shrubs or herbaceous perennials with red, purple, or white/yellow berries (some of which are poisonous).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["black elder"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/elder"],
    },
  ],
  kills: [
    {
      word: "kills",
      phonetic: "/kɪlz/",
      phonetics: [
        {
          text: "/kɪlz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/kills-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651104",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act of killing.",
              synonyms: [],
              antonyms: [],
              example:
                "The assassin liked to make a clean kill, and thus favored small arms over explosives.",
            },
            {
              definition: "Specifically, the death blow.",
              synonyms: [],
              antonyms: [],
              example:
                "The hunter delivered the kill with a pistol shot to the head.",
            },
            {
              definition: "The result of killing; that which has been killed.",
              synonyms: [],
              antonyms: [],
              example: "The fox dragged its kill back to its den.",
            },
            {
              definition:
                "The grounding of the ball on the opponent's court, winning the rally.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To put to death; to extinguish the life of.",
              synonyms: [],
              antonyms: [],
              example:
                "Smoking kills more people each year than alcohol and drugs combined.",
            },
            {
              definition: "To render inoperative.",
              synonyms: [],
              antonyms: [],
              example:
                "He killed the engine and turned off the headlights, but remained in the car, waiting.",
            },
            {
              definition: "To stop, cease or render void; to terminate.",
              synonyms: [],
              antonyms: [],
              example:
                "My computer wouldn't respond until I killed some of the running processes.",
            },
            {
              definition: "To amaze, exceed, stun or otherwise incapacitate.",
              synonyms: [],
              antonyms: [],
              example: "That joke always kills me.",
            },
            {
              definition: "To cause great pain, discomfort or distress to.",
              synonyms: [],
              antonyms: [],
              example: "These tight shoes are killing my feet.",
            },
            {
              definition:
                "To produce feelings of dissatisfaction or revulsion in.",
              synonyms: [],
              antonyms: [],
              example:
                "It kills me to learn how many poor people are practically starving in this country while rich moguls spend such outrageous amounts on useless luxuries.",
            },
            {
              definition: "To use up or to waste.",
              synonyms: [],
              antonyms: [],
              example:
                'He told the bartender, pointing at the bottle of scotch he planned to consume, "Leave it, I\'m going to kill the bottle."',
            },
            {
              definition: "To exert an overwhelming effect on.",
              synonyms: [],
              antonyms: [],
              example:
                "Between the two of us, we killed the rest of the case of beer.",
            },
            {
              definition: "To overpower, overwhelm or defeat.",
              synonyms: [],
              antonyms: [],
              example:
                "The team had absolutely killed their traditional rivals, and the local sports bars were raucous with celebrations.",
            },
            {
              definition: "To force a company out of business.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To produce intense pain.",
              synonyms: [],
              antonyms: [],
              example:
                "You don't ever want to get rabies. The doctor will have to give you multiple shots and they really kill.",
            },
            {
              definition: "To punish severely.",
              synonyms: [],
              antonyms: [],
              example: "My parents are going to kill me!",
            },
            {
              definition:
                "To strike (a ball, etc.) with such force and placement as to make a shot that is impossible to defend against, usually winning a point.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause (a ball, etc.) to be out of play, resulting in a stoppage of gameplay.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To succeed with an audience, especially in comedy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause to assume the value zero.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(IRC) To disconnect (a user) involuntarily from the network.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To deadmelt.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "annihilate",
            "assassinate",
            "bump off",
            "dispatch",
            "ice",
            "knock off",
            "liquidate",
            "murder",
            "rub out",
            "slaughter",
            "slay",
            "top",
            "whack",
            "break",
            "deactivate",
            "disable",
            "turn off",
            "fritter away",
            "while away",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(north-east US) A creek; a body of water; a channel or arm of the sea.",
              synonyms: [],
              antonyms: [],
              example: "Schuylkill, Catskill, etc.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "A kiln.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/kill",
        "https://en.wiktionary.org/wiki/kills",
      ],
    },
  ],
  yeast: [
    {
      word: "yeast",
      phonetic: "/iːst/",
      phonetics: [
        {
          text: "/iːst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/yeast-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79218960",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An often humid, yellowish froth produced by fermenting malt worts, and used to brew beer, leaven bread, and also used in certain medicines.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A single-celled fungus of a wide variety of taxonomic families.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A frothy foam.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To ferment.", synonyms: [], antonyms: [] },
            {
              definition:
                "(of something prepared with a yeasted dough) To rise.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To exaggerate", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/yeast"],
    },
  ],
  erect: [
    {
      word: "erect",
      phonetic: "/ɪˈɹɛkt/",
      phonetics: [
        {
          text: "/ɪˈɹɛkt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/erect-ca.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=47152990",
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Upright; vertical or reaching broadly upwards.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of body parts) Rigid, firm; standing out perpendicularly, especially as the result of stimulation.",
              synonyms: ["hard", "stiff"],
              antonyms: [],
              example:
                "The penis should be fully erect before commencing copulation.",
            },
            {
              definition: "(of a man) Having an erect penis",
              synonyms: ["hard", "stiff"],
              antonyms: [],
              example: "OK, baby, I'm erect now. Let's get it on!",
            },
            {
              definition: "Bold; confident; free from depression; undismayed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Directed upward; raised; uplifted.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Watchful; alert.", synonyms: [], antonyms: [] },
            {
              definition:
                "Elevated, as the tips of wings, heads of serpents, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["hard", "stiff", "hard", "stiff"],
          antonyms: ["flaccid"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/erect"],
    },
    {
      word: "erect",
      phonetic: "/ɪˈɹɛkt/",
      phonetics: [
        {
          text: "/ɪˈɹɛkt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/erect-ca.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=47152990",
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To put up by the fitting together of materials or parts.",
              synonyms: [],
              antonyms: [],
              example: "to erect a house or a fort",
            },
            {
              definition: "To cause to stand up or out.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To raise and place in an upright or perpendicular position; to set upright; to raise.",
              synonyms: [],
              antonyms: [],
              example: "to erect a pole, a flagstaff, a monument, etc.",
            },
            {
              definition: "To lift up; to elevate; to exalt; to magnify.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To animate; to encourage; to cheer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cast or draw up (a figure of the heavens, horoscope etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To set up as an assertion or consequence from premises, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To set up or establish; to found; to form; to institute.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["build"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/erect"],
    },
  ],
  bugle: [
    {
      word: "bugle",
      phonetic: "/ˈbjuːɡəl/",
      phonetics: [{ text: "/ˈbjuːɡəl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A horn used by hunters.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A simple brass instrument consisting of a horn with no valves, playing only pitches in its harmonic series",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A plant in the family Lamiaceae grown as a ground cover, Ajuga reptans, and other plants in the genus Ajuga.",
              synonyms: ["bugleweed", "carpet bugle", "ground pine"],
              antonyms: [],
            },
            {
              definition:
                "Anything shaped like a bugle, round or conical and having a bell on one end.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "cone",
            "funnel",
            "bugleweed",
            "carpet bugle",
            "ground pine",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To announce, sing, or cry in the manner of a musical bugle",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["trumpet"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bugle"],
    },
    {
      word: "bugle",
      phonetic: "/ˈbjuːɡəl/",
      phonetics: [{ text: "/ˈbjuːɡəl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A tubular glass or plastic bead sewn onto clothes as a decorative trim",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Jet-black", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bugle"],
    },
    {
      word: "bugle",
      phonetic: "/ˈbjuːɡəl/",
      phonetics: [{ text: "/ˈbjuːɡəl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A sort of wild ox; a buffalo.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bugle"],
    },
  ],
  medal: [
    {
      word: "medal",
      phonetic: "[ˈmeɾ.ɫ̩]",
      phonetics: [
        { text: "[ˈmeɾ.ɫ̩]", audio: "" },
        {
          text: "[ˈmɛd.ɫ̩]",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/medal-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1879383",
        },
        { text: "[ˈmɛɾ.ɫ̩]", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A stamped metal disc used as a personal ornament, a charm, or a religious object.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A stamped or cast metal object (usually a disc), particularly one awarded as a prize or reward.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To win a medal.",
              synonyms: [],
              antonyms: [],
              example: "He medalled twice at the Olympics.",
            },
            { definition: "To award a medal to.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/medal"],
    },
  ],
  roles: [
    {
      word: "roles",
      phonetic: "/ɹəʊlz/",
      phonetics: [
        {
          text: "/ɹəʊlz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/roles-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80152008",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ɹoʊlz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A character or part played by a performer or actor.",
              synonyms: [],
              antonyms: [],
              example:
                "Her dream was to get a role in a Hollywood movie, no matter how small.",
            },
            {
              definition:
                "The expected behaviour of an individual in a society.",
              synonyms: [],
              antonyms: [],
              example:
                "The role of women has changed significantly in the last century.",
            },
            {
              definition: "The function or position of something.",
              synonyms: [],
              antonyms: [],
              example:
                "Local volunteers played an important role in cleaning the beach after the oil spill.",
            },
            {
              definition:
                "Designation that denotes an associated set of responsibilities, knowledge, skills, and attitudes",
              synonyms: [],
              antonyms: [],
              example:
                "The project manager role is responsible for ensuring that everyone on the team knows and executes his or her assigned tasks.",
            },
            {
              definition: "(grammar) The function of a word in a phrase.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In the Raku programming language, a code element akin to an interface, used for composition of classes without adding to their inheritance chain.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An ancient unit of quantity, 72 sheets of parchment.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/role",
        "https://en.wiktionary.org/wiki/roles",
      ],
    },
  ],
  hound: [
    {
      word: "hound",
      phonetic: "/haʊnd/",
      phonetics: [
        {
          text: "/haʊnd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hound-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769966",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A dog, particularly a breed with a good sense of smell developed for hunting other animals.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Any canine animal.", synonyms: [], antonyms: [] },
            {
              definition: "(by extension) Someone who seeks something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) A male who constantly seeks the company of desirable women.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A despicable person.", synonyms: [], antonyms: [] },
            { definition: "A houndfish.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hound"],
    },
    {
      word: "hound",
      phonetic: "/haʊnd/",
      phonetics: [
        {
          text: "/haʊnd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hound-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769966",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To persistently harass.",
              synonyms: [],
              antonyms: [],
              example:
                "He hounded me for weeks, but I was simply unable to pay back his loan.",
            },
            {
              definition: "To urge on against; to set (dogs) upon in hunting.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hound"],
    },
    {
      word: "hound",
      phonetic: "/haʊnd/",
      phonetics: [
        {
          text: "/haʊnd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hound-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769966",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(in the plural) Projections at the masthead, serving as a support for the trestletrees and top to rest on.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A side bar used to strengthen portions of the running gear of a vehicle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hound"],
    },
  ],
  snail: [
    {
      word: "snail",
      phonetic: "/sneɪl/",
      phonetics: [
        {
          text: "/sneɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/snail-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762769",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of very many animals (either hermaphroditic or nonhermaphroditic), of the class Gastropoda, having a coiled shell.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) A slow person; a sluggard.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A spiral cam, or a flat piece of metal of spirally curved outline, used for giving motion to, or changing the position of, another part, as the hammer tail of a striking clock.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A tortoise or testudo; a movable roof or shed to protect besiegers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The pod of the snail clover.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dodman", "hodmandod"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To move or travel very slowly.",
              synonyms: [],
              antonyms: [],
              example:
                "The cars were snailing along the motorway during the rush hour.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/snail"],
    },
  ],
  alter: [
    {
      word: "alter",
      phonetic: "/ˈɑl.tɚ/",
      phonetics: [
        { text: "/ˈɑl.tɚ/", audio: "" },
        { text: "/ˈɒl.tə/", audio: "" },
        {
          text: "/ˈɔl.tɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/alter-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769832",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To change the form or structure of.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To become different.", synonyms: [], antonyms: [] },
            {
              definition: "To tailor clothes to make them fit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To castrate, neuter or spay (a dog or other animal).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To affect mentally, as by psychotropic drugs or illness.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/alter"],
    },
    {
      word: "alter",
      phonetic: "/ˈɑl.tɚ/",
      phonetics: [
        { text: "/ˈɑl.tɚ/", audio: "" },
        { text: "/ˈɒl.tə/", audio: "" },
        {
          text: "/ˈɔl.tɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/alter-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769832",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(especially in the plural) An identity or headmate of a person with dissociative identity disorder (previously known as multiple personality disorder).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/alter"],
    },
    {
      word: "alter",
      phonetic: "/ˈɑl.tɚ/",
      phonetics: [
        { text: "/ˈɑl.tɚ/", audio: "" },
        { text: "/ˈɒl.tə/", audio: "" },
        {
          text: "/ˈɔl.tɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/alter-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769832",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A table or similar flat-topped structure used for religious rites.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A raised area around an altar in a church; the sanctuary.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Anything that is worshipped or sacrificed to.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/altar",
        "https://en.wiktionary.org/wiki/alter",
      ],
    },
  ],
  ankle: [
    {
      word: "ankle",
      phonetic: "/ˈæŋ.kəl/",
      phonetics: [
        {
          text: "/ˈæŋ.kəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ankle-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769291",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The skeletal joint which connects the foot with the leg; the uppermost portion of the foot and lowermost portion of the leg, which contain this skeletal joint.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To walk.", synonyms: [], antonyms: [] },
            {
              definition:
                "To cyclically angle the foot at the ankle while pedaling, to maximize the amount of work applied to the pedal during each revolution.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ankle"],
    },
  ],
  relay: [
    {
      word: "relay",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/relay-1-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88882729",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A new set of hounds.", synonyms: [], antonyms: [] },
            {
              definition:
                "A new set of horses kept along a specific route so that they can replace animals that are tired.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) A new set of anything.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A series of vehicles travelling in sequence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A track and field discipline where runners take turns in carrying a baton from start to finish. Most common events are 4x100 meter and 4x400 meter competitions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An electrical actuator that allows a relatively small electrical voltage or current to control a larger voltage or current.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To release a new set of hounds.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To place (people or horses) in relays, such that one can take over from another.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To take on a new relay of horses; to change horses.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To pass on or transfer (information).",
              synonyms: [],
              antonyms: [],
              example: "Can you relay this message to John?",
            },
          ],
          synonyms: ["convey"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/relay"],
    },
    {
      word: "relay",
      phonetic: "/ˌɹiːˈleɪ/",
      phonetics: [
        {
          text: "/ˌɹiːˈleɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/relay-2-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88882730",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˌɹiˈleɪ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To lay (for example, flooring or railroad track) again.",
              synonyms: [],
              antonyms: [],
              example:
                "He had to re-lay the tiles because the cement was too dry.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/re-lay",
        "https://en.wiktionary.org/wiki/relay",
      ],
    },
  ],
  loops: [
    {
      word: "loops",
      phonetic: "/luːps/",
      phonetics: [{ text: "/luːps/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A length of thread, line or rope that is doubled over to make an opening.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The opening so formed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A shape produced by a curve that bends around and crosses itself.",
              synonyms: [],
              antonyms: [],
              example:
                "Arches, loops, and whorls are patterns found in fingerprints.",
            },
            {
              definition: "A ring road or beltway.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An endless strip of tape or film allowing continuous repetition.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A complete circuit for an electric current.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A programmed sequence of instructions that is repeated until or while a particular condition is satisfied.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An edge that begins and ends on the same vertex.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A path that starts and ends at the same point.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bus or rail route, walking route, etc. that starts and ends at the same point.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A place at a terminus where trains or trams can turn round and go back the other way without having to reverse; a balloon loop, turning loop, or reversing loop.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A quasigroup with an identity element.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A loop-shaped intrauterine device.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An aerobatic maneuver in which an aircraft flies a circular path in a vertical plane.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A small, narrow opening; a loophole.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A flexible region in a protein's secondary structure.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To form something into a loop.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fasten or encircle something with a loop.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fly an aircraft in a loop.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To move something in a loop.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To join electrical components to complete a circuit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To duplicate the route of a pipeline.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To create an error in a computer program so that it runs in an endless loop and the computer freezes up.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To form a loop.", synonyms: [], antonyms: [] },
            {
              definition: "To move in a loop.",
              synonyms: [],
              antonyms: [],
              example: "The program loops until the user presses a key.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A mass of iron in a pasty condition gathered into a ball for the tilt hammer or rolls.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/loop",
        "https://en.wiktionary.org/wiki/loops",
        "https://en.wiktionary.org/wiki/loup",
      ],
    },
  ],
  zeros: [
    {
      word: "zeros",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The numeric symbol that represents the cardinal number zero.",
              synonyms: [],
              antonyms: [],
              example:
                "In unary and k-adic notation in general, zero is the empty string.",
            },
            {
              definition:
                "The digit 0 in the decimal, binary, and all other base numbering systems.",
              synonyms: [],
              antonyms: [],
              example: "One million has six zeroes.",
            },
            {
              definition: "Nothing, or none.",
              synonyms: [],
              antonyms: [],
              example: "He knows zero about humour.",
            },
            {
              definition:
                "The value of a magnitude corresponding to the cardinal number zero.",
              synonyms: [],
              antonyms: [],
              example:
                "The electromagnetic field does not drop all of the way to zero before a reversal.",
            },
            {
              definition:
                "The point on a scale at which numbering or measurement originates.",
              synonyms: [],
              antonyms: [],
              example: "The temperature outside is ten degrees below zero.",
            },
            {
              definition:
                "A value of the independent variables of a function, for which the function is equal to zero.",
              synonyms: [],
              antonyms: [],
              example:
                "The derivative of a continuous, differentiable function that twice crosses the axis must have a zero.",
            },
            {
              definition:
                "The additive identity element of a monoid or greater algebraic structure, particularly a group or ring.",
              synonyms: [],
              antonyms: [],
              example:
                "Since a commutative zero is the inverse of any additive identity, it must be unique when it exists.",
            },
            {
              definition: "A person of little or no importance.",
              synonyms: [],
              antonyms: [],
              example: "They rudely treated him like a zero.",
            },
            {
              definition:
                "A Mitsubishi A6M Zero, a long range fighter aircraft operated by the Japanese Navy Air Service from 1940 to 1945.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A setting of calibrated instruments such as a firearm.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A security which has a zero coupon (paying no periodic interest).",
              synonyms: [],
              antonyms: [],
              example: "The takeovers were financed by issuing zeroes.",
            },
          ],
          synonyms: [
            "slashed zero",
            "additive identity",
            "nadir",
            "bugger all",
            "fuck all",
            "nada",
            "naught",
            "nil",
            "nothing",
            "nought",
            "nowt",
            "null",
            "sod all",
            "sweet FA",
            "sweet Fanny Adams",
            "zilch",
            "zip",
            "cipher",
            "cipher",
            "nobody",
            "nonentity",
            "origin",
            "zero point",
            "root",
          ],
          antonyms: ["pole"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/zero",
        "https://en.wiktionary.org/wiki/zeros",
      ],
    },
  ],
  bites: [
    {
      word: "bites",
      phonetic: "/baɪts/",
      phonetics: [{ text: "/baɪts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The act of biting.", synonyms: [], antonyms: [] },
            {
              definition: "The wound left behind after having been bitten.",
              synonyms: [],
              antonyms: [],
              example: "That snake bite really hurts!",
            },
            {
              definition:
                "The swelling of one's skin caused by an insect's mouthparts or sting.",
              synonyms: [],
              antonyms: [],
              example:
                "After just one night in the jungle I was covered with mosquito bites.",
            },
            {
              definition:
                "A piece of food of a size that would be produced by biting; a mouthful.",
              synonyms: [],
              antonyms: [],
              example: "There were only a few bites left on the plate.",
            },
            {
              definition: "Something unpleasant.",
              synonyms: [],
              antonyms: [],
              example: "That's really a bite!",
            },
            {
              definition: "An act of plagiarism.",
              synonyms: [],
              antonyms: [],
              example: "That song is a bite of my song!",
            },
            {
              definition: "A small meal or snack.",
              synonyms: [],
              antonyms: [],
              example:
                "I'll have a quick bite to quiet my stomach until dinner.",
            },
            { definition: "Aggression", synonyms: [], antonyms: [] },
            {
              definition:
                "The hold which the short end of a lever has upon the thing to be lifted, or the hold which one part of a machine has upon another.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A cheat; a trick; a fraud.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sharper; one who cheats.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A blank on the edge or corner of a page, owing to a portion of the frisket, or something else, intervening between the type and paper.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A cut, a proportion of profits; an amount of money.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["mouthful", "snack", "sting"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cut into something by clamping the teeth.",
              synonyms: [],
              antonyms: [],
              example:
                "As soon as you bite that sandwich, you'll know how good it is.",
            },
            {
              definition: "To hold something by clamping one's teeth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To attack with the teeth.",
              synonyms: [],
              antonyms: [],
              example: "That dog is about to bite!",
            },
            {
              definition: "To behave aggressively; to reject advances.",
              synonyms: [],
              antonyms: [],
              example: "If you see me, come and say hello. I don't bite.",
            },
            {
              definition: "To take hold; to establish firm contact with.",
              synonyms: [],
              antonyms: [],
              example: "I needed snow chains to make the tires bite.",
            },
            {
              definition: "To have significant effect, often negative.",
              synonyms: [],
              antonyms: [],
              example:
                "For homeowners with adjustable rate mortgages, rising interest will really bite.",
            },
            {
              definition:
                "(of a fish) To bite a baited hook or other lure and thus be caught.",
              synonyms: [],
              antonyms: [],
              example: "Are the fish biting today?",
            },
            {
              definition:
                "To accept something offered, often secretly or deceptively, to cause some action by the acceptor.",
              synonyms: [],
              antonyms: [],
              example: "I've planted the story. Do you think they'll bite?",
            },
            {
              definition: "(of an insect) To sting.",
              synonyms: [],
              antonyms: [],
              example: "These mosquitoes are really biting today!",
            },
            {
              definition:
                "To cause a smarting sensation; to have a property which causes such a sensation; to be pungent.",
              synonyms: [],
              antonyms: [],
              example: "It bites like pepper or mustard.",
            },
            {
              definition:
                "(sometimes figurative) To cause sharp pain or damage to; to hurt or injure.",
              synonyms: [],
              antonyms: [],
              example: "Pepper bites the mouth.",
            },
            {
              definition:
                "To cause sharp pain; to produce anguish; to hurt or injure; to have the property of so doing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To take or keep a firm hold.",
              synonyms: [],
              antonyms: [],
              example: "The anchor bites.",
            },
            {
              definition: "To take hold of; to hold fast; to adhere to.",
              synonyms: [],
              antonyms: [],
              example: "The anchor bites the ground.",
            },
            {
              definition: "To lack quality; to be worthy of derision; to suck.",
              synonyms: [],
              antonyms: [],
              example: "This music really bites.",
            },
            {
              definition: "To perform oral sex on. Used in invective.",
              synonyms: [],
              antonyms: [],
              example: "You don't like that I sat on your car? Bite me.",
            },
            {
              definition: "To plagiarize, to imitate.",
              synonyms: [],
              antonyms: [],
              example: "He always be biting my moves.",
            },
            {
              definition: "To deceive or defraud; to take in.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bite",
        "https://en.wiktionary.org/wiki/bites",
      ],
    },
  ],
  modes: [
    {
      word: "modes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One of several ancient Greek scales.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of several common scales in modern Western music, one of which corresponds to the modern major scale and one to the natural minor scale.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A particular means of accomplishing something.",
              synonyms: [],
              antonyms: [],
              example: "What was the mode of entry?",
            },
            {
              definition: "A particular state of being, or frame of mind.",
              synonyms: [],
              antonyms: [],
              example:
                "After a series of early setbacks, her political campaign is in crisis mode.",
            },
            {
              definition:
                "The most frequently occurring value in a distribution",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A state of a system that is represented by an eigenfunction of that system.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of various related sets of rules for processing data; more generally, any state of the system associated with certain behaviours.",
              synonyms: [],
              antonyms: [],
              example:
                "In insert mode, characters typed are directly inserted into the buffer.",
            },
            {
              definition:
                "A series of settings on a device used for a specific purpose.",
              synonyms: [],
              antonyms: [],
              example: "airplane mode; night mode",
            },
            {
              definition:
                "A variation in gameplay, such as a difficulty level.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(grammar) A verb form that depends on how its containing clause relates to the speaker’s or writer’s wish, intent, or assertion about reality.",
              synonyms: ["grammatical mood", "mood"],
              antonyms: [],
            },
            {
              definition: "That which exists only as a quality of substance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In lace-making, a small decorative piece inserted into a pattern.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The openwork between the solid parts of a pattern.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A woman's mantle with a hood.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["grammatical mood", "mood"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Style or fashion; popular trend.",
              synonyms: [],
              antonyms: [],
              example: "Her wardrobe is always in mode.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mode",
        "https://en.wiktionary.org/wiki/modes",
      ],
    },
  ],
  debts: [
    {
      word: "debts",
      phonetic: "/dɛts/",
      phonetics: [{ text: "/dɛts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An action, state of mind, or object one has an obligation to perform for another, adopt toward another, or give to another.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The state or condition of owing something to another.",
              synonyms: [],
              antonyms: [],
              example: "I am in your debt.",
            },
            {
              definition:
                "Money that one person or entity owes or is required to pay to another, generally as a result of a loan or other financial transaction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An action at law to recover a certain specified sum of money alleged to be due.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/debt",
        "https://en.wiktionary.org/wiki/debts",
      ],
    },
  ],
  realm: [
    {
      word: "realm",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/realm-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75857709",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ɹɛlm/", audio: "" },
        {
          text: "/ɹɛlm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/realm-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780308",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An abstract sphere of influence, real or imagined.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The domain of a certain abstraction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A scope of operation in networking or security.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A territory or state, as ruled by a specific power, especially by a king.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An otherworldly dimension or domain — magical, ethereal, or otherwise — usually ruled or created by a mystical character.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A taxonomic rank in the phylogeny of viruses, higher than kingdoms.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["field", "province", "country", "kingdom", "land"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/realm"],
    },
  ],
  glove: [
    {
      word: "glove",
      phonetic: "/ɡlʌv/",
      phonetics: [
        {
          text: "/ɡlʌv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/glove-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899538",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ɡlʌv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/glove-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=862123",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An item of clothing other than a mitten, covering all or part of the hand and fingers, but usually allowing independent movement of the fingers.",
              synonyms: [],
              antonyms: [],
              example: "I wore gloves to keep my hands warm.",
            },
            { definition: "A baseball mitt.", synonyms: [], antonyms: [] },
            {
              definition: "The ability to catch a hit ball.",
              synonyms: [],
              antonyms: [],
              example:
                "Frederico had a great glove, but he couldn't hit a curveball, so he never broke into the pros.",
            },
            { definition: "A condom.", synonyms: [], antonyms: [] },
            {
              definition:
                "(with definite article) A challenge from one to another.",
              synonyms: [],
              antonyms: [],
              example:
                "to throw down the glove, i.e. to offer a challenge; to take up the glove, to accept it",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To catch the ball in a baseball mitt.",
              synonyms: [],
              antonyms: [],
              example: "He gloved the line drive for the third out.",
            },
            {
              definition: "To put a glove or gloves on.",
              synonyms: [],
              antonyms: [],
              example:
                "Maxwell gloved his hand so that he wouldn't leave fingerprints, then pulled the trigger.",
            },
            {
              definition:
                "To touch a delivery with one's glove while the gloved hand is on the bat. Under the rules of cricket, the batsman is deemed to have hit the ball.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/glove"],
    },
  ],
  rayon: [
    {
      word: "rayon",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A manufactured regenerated cellulosic fiber.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rayon"],
    },
    {
      word: "rayon",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An administrative unit of some Eastern European and Asian states.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/raion",
        "https://en.wiktionary.org/wiki/rayon",
      ],
    },
    {
      word: "rayon",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A ray or beam.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rayon"],
    },
  ],
  swims: [
    {
      word: "swims",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An act or instance of swimming.",
              synonyms: [],
              antonyms: [],
              example: "I'm going for a swim.",
            },
            {
              definition: "The sound, or air bladder, of a fish.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A part of a stream much frequented by fish.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A dance move of the 1960s in which the arms are moved in a freestyle swimming manner.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To move through the water, without touching the bottom; to propel oneself in water by natural means.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To become immersed in, or as if in, or flooded with, or as if with, a liquid",
              synonyms: [],
              antonyms: [],
              example: "a bare few bits of meat swimming in watery sauce",
            },
            {
              definition: "To move around freely because of excess space.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To traverse (a specific body of water, or a specific distance) by swimming; or, to utilize a specific swimming stroke; or, to compete in a specific swimming event.",
              synonyms: [],
              antonyms: [],
              example: "For exercise, we like to swim laps around the pool.",
            },
            {
              definition: "To cause to swim.",
              synonyms: [],
              antonyms: [],
              example: "Half of the guinea pigs were swum daily.",
            },
            {
              definition: "To float.",
              synonyms: [],
              antonyms: [],
              example: "sink or swim",
            },
            {
              definition: "To be overflowed or drenched.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To immerse in water to make the lighter parts float.",
              synonyms: [],
              antonyms: [],
              example: "to swim wheat in order to select seed",
            },
            {
              definition:
                "To test (a suspected witch) by throwing into a river; those who floated rather than sinking were deemed to be witches.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To glide along with a waving motion.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A dizziness; swoon.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To be dizzy or vertiginous; have a giddy sensation; to have, or appear to have, a whirling motion.",
              synonyms: [],
              antonyms: [],
              example:
                "My head was swimming after drinking two bottles of cheap wine.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/swim",
        "https://en.wiktionary.org/wiki/swims",
      ],
    },
  ],
  poked: [
    {
      word: "poked",
      phonetic: "/pəʊkt/",
      phonetics: [
        { text: "/pəʊkt/", audio: "" },
        { text: "/poʊkt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To prod or jab with an object such as a finger or a stick.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To stir up a fire to remove ash or promote burning.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To rummage; to feel or grope around.",
              synonyms: [],
              antonyms: [],
              example:
                "I poked about in the rubble, trying to find my lost keys.",
            },
            {
              definition: "To modify the value stored in (a memory address).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To put a poke (device to prevent leaping or breaking fences) on (an animal).",
              synonyms: [],
              antonyms: [],
              example: "to poke an ox",
            },
            {
              definition: "To thrust at with the horns; to gore.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To notify (another user) of activity on social media or an instant messenger.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To thrust (something) in a particular direction such as the tongue.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To penetrate in sexual intercourse.",
              synonyms: [],
              antonyms: [],
              example: "If she smokes, she pokes.",
            },
          ],
          synonyms: ["drill", "nail", "pound", "fumble", "glaum", "root"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/poke",
        "https://en.wiktionary.org/wiki/poked",
      ],
    },
  ],
  stray: [
    {
      word: "stray",
      phonetic: "/stɹeɪ/",
      phonetics: [
        {
          text: "/stɹeɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stray-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=4110965",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any domestic animal that has no enclosure, or its proper place and company, and wanders at large, or is lost; an estray.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One who is lost, either literally or metaphorically.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The act of wandering or going astray.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'An area of common land or place administered for the use of general domestic animals, i.e. "the stray"',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stray"],
    },
    {
      word: "stray",
      phonetic: "/stɹeɪ/",
      phonetics: [
        {
          text: "/stɹeɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stray-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=4110965",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To wander, as from a direct course; to deviate, or go out of the way.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To wander from one's limits; to rove or roam at large; to go astray.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To wander from the path of duty or rectitude; to err.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To cause to stray.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["deviate", "err"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stray"],
    },
    {
      word: "stray",
      phonetic: "/stɹeɪ/",
      phonetics: [
        {
          text: "/stɹeɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stray-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=4110965",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having gone astray; strayed; wandering",
              synonyms: [],
              antonyms: [],
              example:
                "The alley is full of stray cats rummaging through the garbage.",
            },
            {
              definition: "In the wrong place; misplaced.",
              synonyms: [],
              antonyms: [],
              example: "a stray comma",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stray"],
    },
  ],
  lifts: [
    {
      word: "lifts",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lifts-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651133",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An act of lifting or raising.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The act of transporting someone in a vehicle; a ride; a trip.",
              synonyms: [],
              antonyms: [],
              example: "He gave me a lift to the bus station.",
            },
            {
              definition:
                "Mechanical device for vertically transporting goods or people between floors in a building; an elevator.",
              synonyms: [],
              antonyms: [],
              example: "Take the lift to the fourth floor.",
            },
            {
              definition:
                "An upward force, such as the force that keeps aircraft aloft.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(measurement) The difference in elevation between the upper pool and lower pool of a waterway, separated by lock.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A thief.", synonyms: [], antonyms: [] },
            {
              definition: "The lifting of a dance partner into the air.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Permanent construction with a built-in platform that is lifted vertically.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An improvement in mood.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The amount or weight to be lifted.",
              synonyms: [],
              antonyms: [],
              example: "What's the maximum lift of this crane?",
            },
            {
              definition:
                "The space or distance through which anything is lifted.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A rise; a degree of elevation.",
              synonyms: [],
              antonyms: [],
              example: "the lift of a lock in canals",
            },
            { definition: "A liftgate.", synonyms: [], antonyms: [] },
            {
              definition:
                "A rope leading from the masthead to the extremity of a yard below, and used for raising or supporting the end of the yard.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One of the steps of a cone pulley.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(shoemaking) A layer of leather in the heel of a shoe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That portion of the vibration of a balance during which the impulse is given.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["elevator", "ride", "uplift"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To raise or rise.",
              synonyms: [],
              antonyms: [],
              example: "The fog eventually lifted, leaving the streets clear.",
            },
            { definition: "To steal.", synonyms: [], antonyms: [] },
            {
              definition:
                "To source directly without acknowledgement; to plagiarise.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To arrest (a person).", synonyms: [], antonyms: [] },
            {
              definition: "To remove (a ban, restriction, etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To alleviate, to lighten (pressure, tension, stress, etc.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause to move upwards.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To lift weights; to weight-lift.",
              synonyms: [],
              antonyms: [],
              example: "She lifts twice a week at the gym.",
            },
            {
              definition:
                "To try to raise something; to exert the strength for raising or bearing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To elevate or improve in rank, condition, etc.; often with up.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To bear; to support.", synonyms: [], antonyms: [] },
            {
              definition: "To collect, as moneys due; to raise.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To transform (a function) into a corresponding function in a different context.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To buy a security or other asset previously offered for sale.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Air.", synonyms: [], antonyms: [] },
            {
              definition: "The sky; the heavens; firmament; atmosphere.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["atmosphere", "air", "welkin"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lift",
        "https://en.wiktionary.org/wiki/lifts",
      ],
    },
  ],
  maker: [
    {
      word: "maker",
      phonetic: "/ˈmeɪk.ə/",
      phonetics: [
        { text: "/ˈmeɪk.ə/", audio: "" },
        { text: "/ˈmeɪk.ɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Someone who makes; a person or thing that makes or produces something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(usually capitalized and preceded by the) God.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A poet.", synonyms: [], antonyms: [] },
            {
              definition:
                "Someone who signs a promissory note, thereby becoming responsible for payment.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/maker"],
    },
  ],
  lumps: [
    {
      word: "lumps",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Something that protrudes, sticks out, or sticks together; a cluster or blob; a mound or mass of no particular shape.",
              synonyms: [],
              antonyms: [],
              example: "Stir the gravy until there are no more lumps.",
            },
            {
              definition: "A group, set, or unit.",
              synonyms: [],
              antonyms: [],
              example:
                "The money arrived all at once as one big lump sum payment.",
            },
            {
              definition:
                "A small, shaped mass of sugar, typically about a teaspoonful.",
              synonyms: [],
              antonyms: [],
              example: "Do you want one lump or two with your coffee?",
            },
            {
              definition: "A dull or lazy person.",
              synonyms: [],
              antonyms: [],
              example: "Don't just sit there like a lump.",
            },
            {
              definition: "(as plural) A beating or verbal abuse.",
              synonyms: [],
              antonyms: [],
              example: "He's taken his lumps over the years.",
            },
            {
              definition:
                "A projection beneath the breech end of a gun barrel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A kind of fish, the lumpsucker.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Food given to a tramp to be eaten on the road.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A beating or verbal abuse.",
              synonyms: [],
              antonyms: [],
              example: "He's taken his lumps over the years.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lump",
        "https://en.wiktionary.org/wiki/lumps",
      ],
    },
  ],
  graze: [
    {
      word: "graze",
      phonetic: "/ɡɹeɪz/",
      phonetics: [
        {
          text: "/ɡɹeɪz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/graze-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50240574",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The act of grazing; a scratching or injuring lightly on passing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A light abrasion; a slight scratch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The act of animals feeding from pasture.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To feed or supply (cattle, sheep, etc.) with grass; to furnish pasture for.",
              synonyms: [],
              antonyms: [],
              example:
                "1999: Although it is perfectly good meadowland, none of the villagers has ever grazed animals on the meadow on the other side of the wall. — Stardust, Neil Gaiman, page 4 (2001 Perennial Edition).",
            },
            {
              definition:
                "To feed on; to eat (growing herbage); to eat grass from (a pasture)",
              synonyms: [],
              antonyms: [],
              example: "Cattle graze in the meadows.",
            },
            {
              definition: "To tend (cattle, etc.) while grazing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To eat periodically throughout the day, rather than at fixed mealtimes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To shoplift by consuming food or drink items before reaching the checkout.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To rub or touch lightly the surface of (a thing) in passing.",
              synonyms: [],
              antonyms: [],
              example: "the bullet grazed the wall",
            },
            {
              definition: "To cause a slight wound to; to scratch.",
              synonyms: [],
              antonyms: [],
              example: "to graze one's knee",
            },
            {
              definition: "To yield grass for grazing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/graze"],
    },
  ],
  dread: [
    {
      word: "dread",
      phonetic: "/dɹɛd/",
      phonetics: [
        {
          text: "/dɹɛd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dread-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749811",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Great fear in view of impending evil; fearful apprehension of danger; anticipatory terror.",
              synonyms: [],
              antonyms: [],
              example: "my visit to the doctor is filling me with dread",
            },
            {
              definition: "Reverential or respectful fear; awe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Somebody or something dreaded.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person highly revered.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Fury; dreadfulness.", synonyms: [], antonyms: [] },
            { definition: "A Rastafarian.", synonyms: [], antonyms: [] },
            {
              definition: "(chiefly in the plural) dreadlock",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To fear greatly.", synonyms: [], antonyms: [] },
            {
              definition: "To anticipate with fear.",
              synonyms: [],
              antonyms: [],
              example:
                "I'm dreading getting the results of the test, as it could decide my whole life.",
            },
            {
              definition: "To be in dread, or great fear.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To style (the hair) into dreadlocks.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Terrible; greatly feared.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Awe-inspiring; held in fearful awe.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dread"],
    },
  ],
  barns: [
    {
      word: "barns",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A building, often found on a farm, used for storage or keeping animals such as cattle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A unit of surface area equal to 10−28 square metres.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An arena.",
              synonyms: [],
              antonyms: [],
              example: "Maple Leaf Gardens was a grand old barn.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To lay up in a barn.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "A child.", synonyms: [], antonyms: [] }],
          synonyms: ["bairn"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/barn",
        "https://en.wiktionary.org/wiki/barns",
      ],
    },
  ],
  docks: [
    {
      word: "docks",
      phonetic: "/dɒks/",
      phonetics: [
        {
          text: "/dɒks/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/docks-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80536201",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of the genus Rumex of coarse weedy plants with small green flowers related to buckwheat, especially common dock, and used as potherbs and in folk medicine, especially in curing nettle rash.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A burdock plant, or the leaves of that plant.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The fleshy root of an animal's tail.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The part of the tail which remains after the tail has been docked.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The buttocks or anus.", synonyms: [], antonyms: [] },
            {
              definition:
                "A leather case to cover the clipped or cut tail of a horse.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cut off a section of an animal's tail, to practise a caudectomy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To reduce (wages); to deduct from.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cut off, bar, or destroy.",
              synonyms: [],
              antonyms: [],
              example: "to dock an entail",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A fixed structure attached to shore to which a vessel is secured when in port.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A structure attached to shore for loading and unloading vessels.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The body of water between two piers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The place of arrival and departure of a train in a railway station.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A section of a hotel or restaurant.",
              synonyms: [],
              antonyms: [],
              example: "coffee dock",
            },
            {
              definition:
                "A device designed as a base for holding a connected portable appliance such as a laptop computer (in this case, referred to as a docking station), or a mobile telephone, for providing the necessary electrical charge for its autonomy, or as a hardware extension for additional capabilities.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A toolbar that provides the user with a way of launching applications, and switching between running applications.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An act of docking; joining two things together.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["slip", "quay", "wharf"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To land at a harbour.", synonyms: [], antonyms: [] },
            {
              definition: "To join two moving items.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To drag a user interface element (such as a toolbar) to a position on screen where it snaps into place.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To place (an electronic device) in its dock.",
              synonyms: [],
              antonyms: [],
              example:
                "I docked the laptop and allowed it to recharge for an hour.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Part of a courtroom where the accused sits.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To pierce with holes, as pricking pastry or dough with a fork to prevent excessive rising in the oven.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A collection of docks, wharves, warehouses and offices",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dock",
        "https://en.wiktionary.org/wiki/docks",
      ],
    },
  ],
  masts: [
    {
      word: "masts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A tall, slim post or tower, usually tapering upward, used to support, for example, the sails on a ship, flags, floodlights, meteorological instruments ,or communications equipment such as an aerial, usually supported by guy-wires.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'A non-judicial punishment ("NJP") disciplinary hearing under which a commanding officer studies and disposes of cases involving those under his command.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To supply and fit a mast to (a ship).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The fruit of forest-trees (beech, oak, chestnut, pecan, etc.), especially if having fallen from the tree, used as fodder for pigs and other animals.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(of swine and other animals) To feed on forest seed or fruit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a population of plants) To vary fruit and seed production in multi-year cycles.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A type of heavy cue, with the broad end of which one strikes the ball.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mast",
        "https://en.wiktionary.org/wiki/masts",
      ],
    },
  ],
  pours: [
    {
      word: "pours",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act of pouring.",
              synonyms: [],
              antonyms: [],
              example:
                "The bartender's inexpert pour left me with a pint of beer that was half foam.",
            },
            {
              definition: "Something, or an amount, poured.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A downpour, or flood of precipitation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cause (liquid, or liquid-like substance) to flow in a stream, either out of a container or into it.",
              synonyms: [],
              antonyms: [],
              example: "pour water from a jug",
            },
            {
              definition:
                "To send out as in a stream or a flood; to cause (an emotion) to come out; to cause to escape.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To send forth from, as in a stream; to discharge uninterruptedly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To flow, pass or issue in a stream; to fall continuously and abundantly.",
              synonyms: [],
              antonyms: [],
              example: "the rain poured down.",
            },
            {
              definition: "To rain hard.",
              synonyms: [],
              antonyms: [],
              example: "It's pouring outside.",
            },
            {
              definition:
                "Of a beverage, to be on tap or otherwise available for serving to customers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To move in a throng, as a crowd.",
              synonyms: [],
              antonyms: [],
              example: "The people poured out of the theater.",
            },
          ],
          synonyms: ["shink", "skink"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pour",
        "https://en.wiktionary.org/wiki/pours",
      ],
    },
  ],
  wharf: [
    {
      word: "wharf",
      phonetic: "/wɔːf/",
      phonetics: [
        { text: "/wɔːf/", audio: "" },
        { text: "/wɔɹf/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A man-made landing place for ships on a shore or river bank.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The bank of a river, or the shore of the sea.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dock", "quay"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To secure by a wharf.", synonyms: [], antonyms: [] },
            { definition: "To place on a wharf.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/wharf"],
    },
  ],
  curse: [
    {
      word: "curse",
      phonetic: "/kɜːs/",
      phonetics: [
        { text: "/kɜːs/", audio: "" },
        {
          text: "/kɝs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/curse-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100363",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A supernatural detriment or hindrance; a bane.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A prayer or imprecation that harm may befall someone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The cause of great harm, evil, or misfortune; that which brings evil or severe affliction; torment.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A vulgar epithet.", synonyms: [], antonyms: [] },
            {
              definition: '(usually with "the") A woman\'s menses.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/curse"],
    },
    {
      word: "curse",
      phonetic: "/kɜːs/",
      phonetics: [
        { text: "/kɜːs/", audio: "" },
        {
          text: "/kɝs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/curse-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100363",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To place a curse upon (a person or object).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To call upon divine or supernatural power to send injury upon; to imprecate evil upon; to execrate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To speak or shout a vulgar curse or epithet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To use offensive or morally inappropriate language.",
              synonyms: ["swear"],
              antonyms: [],
            },
            {
              definition:
                "To bring great evil upon; to be the cause of serious harm or unhappiness to; to furnish with that which will be a cause of deep trouble; to afflict or injure grievously; to harass or torment.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["swear"],
          antonyms: ["bless"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/curse"],
    },
  ],
  plump: [
    {
      word: "plump",
      phonetic: "/plʌmp/",
      phonetics: [
        {
          text: "/plʌmp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/plump-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=92971265",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To grow plump; to swell out.",
              synonyms: [],
              antonyms: [],
              example: "Her cheeks have plumped.",
            },
            {
              definition:
                "To make plump; to fill (out) or support; often with up.",
              synonyms: [],
              antonyms: [],
              example:
                "to plump oysters or scallops by placing them in fresh or brackish water",
            },
            {
              definition:
                "To cast or let drop all at once, suddenly and heavily.",
              synonyms: [],
              antonyms: [],
              example: "to plump a stone into water",
            },
            {
              definition: "To give a plumper (kind of vote).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To give (a vote), as a plumper.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To favor or decide in favor of something.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Having a full and rounded shape; chubby, somewhat overweight.",
              synonyms: [],
              antonyms: [],
              example: "a plump baby;  plump cheeks",
            },
            { definition: "Fat.", synonyms: [], antonyms: [] },
            {
              definition:
                "Sudden and without reservation; blunt; direct; downright.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/plump"],
    },
    {
      word: "plump",
      phonetic: "/plʌmp/",
      phonetics: [
        {
          text: "/plʌmp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/plump-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=92971265",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The sound of a sudden heavy fall.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To drop or fall suddenly or heavily, all at once.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Directly; suddenly; perpendicularly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/plump"],
    },
    {
      word: "plump",
      phonetic: "/plʌmp/",
      phonetics: [
        {
          text: "/plʌmp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/plump-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=92971265",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A knot or cluster; a group; a crowd.",
              synonyms: [],
              antonyms: [],
              example: "a plump of trees, fowls, or spears",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/plump"],
    },
  ],
  robes: [
    {
      word: "robes",
      phonetic: "/ɹəʊbz/",
      phonetics: [
        { text: "/ɹəʊbz/", audio: "" },
        { text: "/ɹoʊbz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A long loose outer garment, often signifying honorary stature.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The skin of an animal, especially the bison, dressed with the fur on, and used as a wrap.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A wardrobe, especially one built into a bedroom.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The largest and strongest tobacco leaves.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/robe",
        "https://en.wiktionary.org/wiki/robes",
      ],
    },
  ],
  seeks: [
    {
      word: "seeks",
      phonetic: "/siːks/",
      phonetics: [
        {
          text: "/siːks/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/seeks-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651478",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To try to find; to look for; to search for.",
              synonyms: [],
              antonyms: [],
              example: "I seek wisdom.",
            },
            {
              definition: "To ask for; to solicit; to beseech.",
              synonyms: [],
              antonyms: [],
              example: "I seek forgiveness through prayer.",
            },
            {
              definition:
                "To try to acquire or gain; to strive after; to aim at.",
              synonyms: [],
              antonyms: [],
              example: "I sought my fortune on the goldfields.",
            },
            {
              definition: "To go, move, travel (in a given direction).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To try to reach or come to; to go to; to resort to.",
              synonyms: [],
              antonyms: [],
              example: "When the alarm went off I sought the exit in a panic.",
            },
            {
              definition: "To attempt, endeavour, try",
              synonyms: [],
              antonyms: [],
              example:
                "Our company does not seek to limit its employees from using the internet or engaging in social networking.",
            },
            {
              definition: "To navigate through a stream.",
              synonyms: ["scrub"],
              antonyms: [],
            },
          ],
          synonyms: ["look for", "scrub", "search"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/seek",
        "https://en.wiktionary.org/wiki/seeks",
      ],
    },
  ],
  cedar: [
    {
      word: "cedar",
      phonetic: "/ˈsiː.də/",
      phonetics: [
        { text: "/ˈsiː.də/", audio: "" },
        {
          text: "/ˈsi.dɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cedar-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1784068",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A coniferous tree of the genus Cedrus in the family Pinaceae.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A coniferous tree of the family Cupressaceae, especially of the genera Juniperus, Cupressus, Calocedrus, or Thuja.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A flowering tree of the family Meliaceae, especially of the genera Cedrela or Toona.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The aromatic wood from a Cedrus tree, or from any of several unrelated trees.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cedar"],
    },
  ],
  curls: [
    {
      word: "curls",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/curls-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2650822",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A piece or lock of curling hair; a ringlet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A curved stroke or shape.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A spin making the trajectory of an object curve.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Movement of a moving rock away from a straight line.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any exercise performed by bending the arm, wrist, or leg on the exertion against resistance, especially those that train the biceps.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The vector field denoting the rotationality of a given vector field.",
              synonyms: [],
              antonyms: [],
              example:
                "The curl of the vector field \\vec{F}(x,y,z) is the vector field \\operatorname{curl}\\,\\vec{F} \\equiv \\vec{\\nabla}\\times\\vec{F}=\\left( \\frac{\\partial F_z}{\\partial y} - \\frac{\\partial F_y}{\\partial z}, \\frac{\\partial F_x}{\\partial z} - \\frac{\\partial F_z}{\\partial x}, \\frac{\\partial F_y}{\\partial x} - \\frac{\\partial F_x}{\\partial y} \\right).",
            },
            {
              definition:
                "(proper noun) The vector operator, denoted \\rm{curl}\\; or \\vec{\\nabla}\\times\\vec{\\left(\\cdot\\right)}, that generates this field.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various diseases of plants causing the leaves or shoots to curl up; often specifically the potato curl.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The contrasting light and dark figure seen in wood used for stringed instrument making; the flame.",
              synonyms: [],
              antonyms: [],
              example: "The one-piece back is of a medium curl.",
            },
            {
              definition:
                "A pattern where the receiver appears to be running a fly pattern but after a set number of steps or yards quickly stops and turns around, looking for a pass.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "curlicue",
            "curve",
            "flourish",
            "loop",
            "spiral",
            "ringlet",
          ],
          antonyms: ["extension"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cause to move in a curve.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make into a curl or spiral.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To assume the shape of a curl or spiral.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To move in curves.", synonyms: [], antonyms: [] },
            {
              definition: "To take part in the sport of curling.",
              synonyms: [],
              antonyms: [],
              example: "I curl at my local club every weekend.",
            },
            {
              definition:
                "To exercise by bending the arm, wrist, or leg on the exertion against resistance, especially of the biceps.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To twist or form (the hair, etc.) into ringlets.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To deck with, or as if with, curls; to ornament.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To raise in waves or undulations; to ripple.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(hat-making) To shape (the brim of a hat) into a curve.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "coil",
            "roll up",
            "arch",
            "coil",
            "roll up",
            "curve",
            "spiral",
          ],
          antonyms: [
            "straighten",
            "uncoil",
            "unroll",
            "straighten",
            "uncoil",
            "unroll",
          ],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Specifically, a body of curly hair on someone's head",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["curlies"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/curl",
        "https://en.wiktionary.org/wiki/curls",
      ],
    },
  ],
  jolly: [
    {
      word: "jolly",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/jolly-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241305",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈdʒɒli/", audio: "" },
        { text: "/ˈdʒɑli/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A pleasure trip or excursion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A marine in the English navy.",
              synonyms: ["joey"],
              antonyms: [],
            },
          ],
          synonyms: ["joey"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To amuse or divert.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Full of merriment and high spirits; jovial.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Full of life and mirth; jouous; merry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Splendid, excellent, pleasant",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Large", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            { definition: "Very, extremely", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/jolly"],
    },
  ],
  myths: [
    {
      word: "myths",
      phonetic: "/mɪθs/",
      phonetics: [{ text: "/mɪθs/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A traditional story which embodies a belief regarding some fact or phenomenon of experience, and in which often the forces of nature and of the soul are personified; a sacred narrative regarding a god, a hero, the origin of the world or of a people, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Such stories as a genre.",
              synonyms: [],
              antonyms: [],
              example:
                "Myth was the product of man's emotion and imagination, acted upon by his surroundings. (E. Clodd, Myths & Dreams (1885), 7, cited after OED)",
            },
            {
              definition:
                "A commonly-held but false belief, a common misconception; a fictitious or imaginary person or thing; a popular conception about a real person or event which exaggerates or idealizes reality.",
              synonyms: [],
              antonyms: [],
              example:
                "Scientists debunk the myth that gum stays in the human stomach for seven years.",
            },
            {
              definition:
                "A person or thing held in excessive or quasi-religious awe or admiration based on popular legend",
              synonyms: [],
              antonyms: [],
              example:
                "Father Flanagan was legendary, his institution an American myth. (Tucson (Arizona) Citizen, 20 September 1979, 5A/3, cited after OED)",
            },
            {
              definition:
                "A person or thing existing only in imagination, or whose actual existence is not verifiable.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/myth",
        "https://en.wiktionary.org/wiki/myths",
      ],
    },
  ],
  cages: [
    {
      word: "cages",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An enclosure made of bars, normally to hold animals.",
              synonyms: [],
              antonyms: [],
              example:
                "The most dangerous prisoners are locked away in a cage.",
            },
            {
              definition: "The passenger compartment of a lift.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(water polo) The goal.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An automobile.", synonyms: [], antonyms: [] },
            {
              definition: "Something that hinders freedom.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The area from which competitors throw a discus or hammer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An outer framework of timber, enclosing something within it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A skeleton frame to limit the motion of a loose piece, such as a ball valve.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A wirework strainer, used in connection with pumps and pipes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The drum on which the rope is wound in a hoisting whim.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The catcher's wire mask.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A regular graph that has as few vertices as possible for its girth.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cage",
        "https://en.wiktionary.org/wiki/cages",
      ],
    },
  ],
  gloom: [
    {
      word: "gloom",
      phonetic: "/ɡlum/",
      phonetics: [
        {
          text: "/ɡlum/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gloom-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100435",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Darkness, dimness or obscurity.",
              synonyms: [],
              antonyms: [],
              example: "the gloom of a forest, or of midnight",
            },
            {
              definition: "A melancholic, depressing or despondent atmosphere.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Cloudiness or heaviness of mind; melancholy; aspect of sorrow; low spirits; dullness.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A drying oven used in gunpowder manufacture.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To be dark or gloomy.", synonyms: [], antonyms: [] },
            {
              definition: "To look or feel sad, sullen or despondent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To render gloomy or dark; to obscure; to darken.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fill with gloom; to make sad, dismal, or sullen.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To shine or appear obscurely or imperfectly; to glimmer.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gloom"],
    },
  ],
  locks: [
    {
      word: "locks",
      phonetic: "/lɑks/",
      phonetics: [{ text: "/lɑks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Something used for fastening, which can only be opened with a key or combination.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) A mutex or other token restricting access to a resource.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A segment of a canal or other waterway enclosed by gates, used for raising and lowering boats between levels.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The firing mechanism.", synonyms: [], antonyms: [] },
            {
              definition: "Complete control over a situation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Something sure to be a success.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A player in the scrum behind the front row, usually the tallest members of the team.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A fastening together or interlacing; a closing of one thing upon another; a state of being fixed or immovable.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A place from which egress is prevented, as by a lock.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A device for keeping a wheel from turning.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A grapple in wrestling.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To become fastened in place.",
              synonyms: [],
              antonyms: [],
              example:
                "If you put the brakes on too hard, the wheels will lock.",
            },
            {
              definition: "To fasten with a lock.",
              synonyms: [],
              antonyms: [],
              example: "Remember to lock the door when you leave.",
            },
            {
              definition: "To be capable of becoming fastened in place.",
              synonyms: [],
              antonyms: [],
              example: "This door locks with a key.",
            },
            {
              definition: "To intertwine or dovetail.",
              synonyms: [],
              antonyms: [],
              example: "We locked arms and stepped out into the night.",
            },
            {
              definition:
                "(break dancing) To freeze one's body or a part thereof in place.",
              synonyms: [],
              antonyms: [],
              example: "a pop and lock routine",
            },
            {
              definition: "To furnish (a canal) with locks.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To raise or lower (a boat) in a lock.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To seize (e.g. the sword arm of an antagonist) by turning the left arm around it, to disarm him.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To modify (a thread) so that users cannot make new posts in it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(WMF jargon) To prevent a page from being edited by other users.",
              synonyms: [],
              antonyms: [],
              example:
                "Frequently vandalized pages are generally locked to prevent further damage.",
            },
          ],
          synonyms: [],
          antonyms: ["unlock"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A tuft or length of hair, wool etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A small quantity of straw etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A quantity of meal, the perquisite of a mill-servant.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lock",
        "https://en.wiktionary.org/wiki/locks",
      ],
    },
  ],
  pedal: [
    {
      word: "pedal",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pedal-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88743959",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A lever operated by one's foot that is used to control or power a machine or mechanism, such as a bicycle or piano",
              synonyms: [],
              antonyms: [],
              example: "A piano usually has two or three pedals.",
            },
            {
              definition: "A foot or footlike part.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An effects unit, especially one designed to be activated by being stepped on.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(equestrian) A stirrup.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The ranks of pipes played from the pedal-board of an organ.",
              synonyms: [],
              antonyms: [],
              example:
                "A small organ commonly has only one or two ranks on the pedal.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To operate a pedal attached to a wheel in a continuous circular motion.",
              synonyms: [],
              antonyms: [],
              example: "to pedal one's loom",
            },
            {
              definition: "To operate a bicycle.",
              synonyms: [],
              antonyms: [],
              example: "He was out of breath from pedalling up the steep hill.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of or relating to the foot.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pedal"],
    },
  ],
  beets: [
    {
      word: "beets",
      phonetic: "/biːts/",
      phonetics: [{ text: "/biːts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Beta vulgaris, a plant with a swollen root which is eaten or used to make sugar.",
              synonyms: [],
              antonyms: [],
              example: "The beet is a hardy species.",
            },
            {
              definition: "A beetroot, a swollen root of such a plant.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/beet",
        "https://en.wiktionary.org/wiki/beets",
      ],
    },
  ],
  crows: [
    {
      word: "crows",
      phonetic: "/kɹəʊz/",
      phonetics: [
        { text: "/kɹəʊz/", audio: "" },
        { text: "/kɹoʊz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A bird, usually black, of the genus Corvus, having a strong conical beak, with projecting bristles; it has a harsh, croaking call.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The cry of the rooster.",
              synonyms: ["cock-a-doodle-doo"],
              antonyms: [],
            },
            {
              definition:
                "Any of various dark-coloured nymphalid butterflies of the genus Euploea.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bar of iron with a beak, crook, or claw; a bar of iron used as a lever; a crowbar.",
              synonyms: ["crowbar"],
              antonyms: [],
            },
            {
              definition:
                "A gangplank (corvus) used by the Ancient Roman navy to board enemy ships.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(among butchers) The mesentery of an animal.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A black person.", synonyms: [], antonyms: [] },
            {
              definition: "The emblem of an eagle, a sign of military rank.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["cock-a-doodle-doo", "crowbar"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make the shrill sound characteristic of a rooster; to make a sound in this manner, either in gaiety, joy, pleasure, or defiance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To shout in exultation or defiance; to brag.",
              synonyms: [],
              antonyms: [],
              example:
                "He’s been crowing all day about winning the game of cards.",
            },
            {
              definition:
                "To test the reed of a double reed instrument by placing the reed alone in the mouth and blowing it.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/crow",
        "https://en.wiktionary.org/wiki/crows",
      ],
    },
  ],
  anode: [
    {
      word: "anode",
      phonetic: "/ˈæn.əʊd/",
      phonetics: [
        { text: "/ˈæn.əʊd/", audio: "" },
        {
          text: "/ˈæn.oʊd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/anode-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=11781649",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An electrode, of a cell or other electrically polarized device, through which a positive current of electricity flows inwards (and thus, electrons flow outwards). It can have either a negative or a positive voltage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) The electrode at which chemical oxidation of anions takes place, usually resulting in the erosion of metal from the electrode.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The electrode which collects electrons emitted by the cathode in a vacuum tube or gas-filled tube.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That electrode of a semiconductor device which is connected to the p-type material of a p-n junction.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/anode"],
    },
  ],
  slash: [
    {
      word: "slash",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/slash-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899986",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/slaʃ/", audio: "" },
        { text: "/slæʃ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A slashing action or motion, particularly:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A mark made by a slashing motion, particularly:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Something resembling such a mark, particularly:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The loose woody debris remaining from a slash, (particularly forestry) the trimmings left while preparing felled trees for removal.",
              synonyms: [],
              antonyms: [],
              example:
                "Slash generated during logging may constitute a fire hazard.",
            },
            { definition: "Slash fiction.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "gash",
            "bar",
            "diagonal",
            "diagonal mark",
            "foreslash",
            "forward slash",
            "forward stroke",
            "front slash",
            "frontslash",
            "oblique",
            "oblique dash",
            "oblique mark",
            "oblique stroke",
            "scratch comma",
            "separatrix",
            "shilling mark",
            "slant",
            "slant line",
            "slash mark",
            "solidus",
            "stroke",
            "virgil",
            "virgula",
            "virgule",
            "whack",
            "cunt",
          ],
          antonyms: ["backslash"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cut or attempt to cut, particularly:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To strike violently and randomly, particularly:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To move quickly and violently.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To crack a whip with a slashing motion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To clear land, (particularly forestry) with violent action such as logging or brushfires or through grazing.",
              synonyms: [],
              antonyms: [],
              example:
                "The province's traditional slash-and-burn agriculture was only sustainable with a much smaller population.",
            },
            {
              definition: "To write slash fiction.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["crack", "lash", "scourge", "thrash"],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Used to note the sound or action of a slash.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "conjunction",
          definitions: [
            {
              definition: "Used to connect two or more identities in a list.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Used to list alternatives.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["stroke", "or", "and", "and/or", "or"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/slash"],
    },
    {
      word: "slash",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/slash-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899986",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/slaʃ/", audio: "" },
        { text: "/slæʃ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A drink of something; a draft.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A piss: an act of urination.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To piss, to urinate.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/slash"],
    },
    {
      word: "slash",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/slash-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899986",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/slaʃ/", audio: "" },
        { text: "/slæʃ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A swampy area; a swamp.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A large quantity of watery food such as broth.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To work in wet conditions.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/slash"],
    },
    {
      word: "slash",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/slash-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899986",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/slaʃ/", audio: "" },
        { text: "/slæʃ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The period of a transitory breeze.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An interval of good weather.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The loose part of a rope; slack.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/slash",
        "https://en.wiktionary.org/wiki/slatch",
      ],
    },
  ],
  creep: [
    {
      word: "creep",
      phonetic: "/kɹiːp/",
      phonetics: [
        {
          text: "/kɹiːp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/creep-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762651",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To move slowly with the abdomen close to the ground.",
              synonyms: ["crawl"],
              antonyms: [],
            },
            {
              definition:
                "Of plants, to grow across a surface rather than upwards.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To move slowly and quietly in a particular direction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make small gradual changes, usually in a particular direction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To move in a stealthy or secret manner; to move imperceptibly or clandestinely; to steal in; to insinuate itself or oneself.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To slip, or to become slightly displaced.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To move or behave with servility or exaggerated humility; to fawn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To have a sensation as of insects creeping on the skin of the body; to crawl.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To drag in deep water with creepers, as for recovering a submarine cable.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To covertly have sex (with a person other than one's primary partner); to cheat with.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["crawl"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/creep"],
    },
    {
      word: "creep",
      phonetic: "/kɹiːp/",
      phonetics: [
        {
          text: "/kɹiːp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/creep-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762651",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The movement of something that creeps (like worms or snails)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A relatively small gradual change, variation or deviation (from a planned value) in a measure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A slight displacement of an object: the slight movement of something",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The gradual expansion or proliferation of something beyond its original goals or boundaries, considered negatively.",
              synonyms: [],
              antonyms: [],
              example: "christmas creep",
            },
            {
              definition:
                "In sewn books, the tendency of pages on the inside of a quire to stand out farther than those on the outside of it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An increase in strain with time; the gradual flow or deformation of a material under stress.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The imperceptible downslope movement of surface rock.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Someone unpleasantly strange or eccentric.",
              synonyms: ["weirdo"],
              antonyms: [],
            },
            {
              definition:
                "A frightening and/or disconcerting person, especially one who gives the speaker chills.",
              synonyms: [],
              antonyms: [],
              example: "Stop following me, you creep!",
            },
            {
              definition:
                "A barrier with small openings used to keep large animals out while allowing smaller animals to pass through.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["weirdo"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/creep"],
    },
  ],
  rowed: [
    {
      word: "rowed",
      phonetic: "/ɹəʊd/",
      phonetics: [{ text: "/ɹəʊd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To propel (a boat or other craft) over water using oars.",
              synonyms: ["paddle"],
              antonyms: [],
            },
            {
              definition: "To transport in a boat propelled with oars.",
              synonyms: [],
              antonyms: [],
              example: "to row the captain ashore in his barge",
            },
            {
              definition: "To be moved by oars.",
              synonyms: [],
              antonyms: [],
              example: "The boat rows easily.",
            },
          ],
          synonyms: ["paddle"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To argue noisily",
              synonyms: ["argue", "fight"],
              antonyms: [],
            },
          ],
          synonyms: ["argue", "fight"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/row",
        "https://en.wiktionary.org/wiki/rowed",
      ],
    },
    {
      word: "rowed",
      phonetic: "/ɹəʊd/",
      phonetics: [{ text: "/ɹəʊd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Formed into a row, or rows; having a specified number of rows.",
              synonyms: [],
              antonyms: [],
              example: "a twelve-rowed ear of corn",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rowed"],
    },
  ],
  chips: [
    {
      word: "chips",
      phonetic: "/tʃɪps/",
      phonetics: [
        {
          text: "/tʃɪps/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chips-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=60628029",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small piece broken from a larger piece of solid material.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A damaged area of a surface where a small piece has been broken off.",
              synonyms: [],
              antonyms: [],
              example: "This cup has a chip in it.",
            },
            {
              definition: "(games) A token used in place of cash.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sovereign (the coin).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A circuit fabricated in one piece on a small, thin substrate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hybrid device mounted in a substrate, containing electronic circuitry and miniaturised mechanical, chemical and/or biochemical devices.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(especially in the plural) A fried strip of potato of square or rectangular cross-section; a french fry.",
              synonyms: [],
              antonyms: [],
              example: "Do you want sauce or mayonnaise on your chips?",
            },
            {
              definition:
                "(especially in the plural) A thin, crisp, fried slice of potato, or sometimes another vegetable; a crisp.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A shot during which the ball travels more predominantly upwards than in a regular shot, as to clear an obstacle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A takeout that hits a rock at an angle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A dried piece of dung, often used as fuel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(northern) A receptacle, usually for strawberries or other fruit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small, near-conical piece of food added in baking.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small rectangle of colour printed on coated paper for colour selection and matching. A virtual equivalent in software applications.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The triangular piece of wood attached to the log line.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Wood or Cuban palm leaf split into slips, or straw plaited in a special manner, for making hats or bonnets.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Anything dried up, withered, or without flavour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A low shot that travels further along the ground than it does in the air.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "pottle",
            "punnet",
            "IC",
            "integrated circuit",
            "microchip",
            "silicon chip",
            "crisp",
            "French fries",
            "fry",
            "flake",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To chop or cut into small pieces.",
              synonyms: [],
              antonyms: [],
              example: "The workers chipped the dead branches into mulch.",
            },
            {
              definition: "To break small pieces from.",
              synonyms: [],
              antonyms: [],
              example: "Be careful not to chip the paint.",
            },
            {
              definition:
                "To play a shot hitting the ball predominantly upwards rather than forwards. In association football specifically, when the shot is a shot on goal, the opposing goalkeeper may be the direct object of the verb, rather than the ball.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To upgrade an engine management system, usually to increase power.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To become chipped.",
              synonyms: [],
              antonyms: [],
              example: "This varnish chips easily.",
            },
            {
              definition: '(often with "in") To ante (up).',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fit (an animal) with a microchip.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: '(often with "in") to contribute.',
              synonyms: [],
              antonyms: [],
              example:
                "Everyone needs to chip in £1 for George's leaving collection",
            },
            {
              definition: "(also, to chip at) To make fun of.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A carpenter.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/chip",
        "https://en.wiktionary.org/wiki/chips",
      ],
    },
  ],
  fists: [
    {
      word: "fists",
      phonetic: "/fɪsts/",
      phonetics: [
        {
          text: "/fɪsts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fists.mp3",
          sourceUrl:
            "https://commons.wikimedia.org/w/index.php?curid=100636130",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A hand with the fingers clenched or curled inward.",
              synonyms: [],
              antonyms: [],
              example:
                "The boxer's fists rained down on his opponent in the last round.",
            },
            {
              definition: "The pointing hand symbol ☞.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The characteristic signaling rhythm of an individual telegraph or CW operator when sending Morse code.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person's characteristic handwriting.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A group of men.", synonyms: [], antonyms: [] },
            {
              definition: "The talons of a bird of prey.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An attempt at something.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bunch of fives", "ductus", "fist-size"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To strike with the fist.",
              synonyms: [],
              antonyms: [],
              example:
                '...may not score a point with his open hand(s), but may score a point by fisting the ball. Damian Cullen. "Running the rule." The Irish Times 18 Aug 2003, pg. 52.',
            },
            {
              definition: "To close (the hand) into a fist.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To grip with a fist.", synonyms: [], antonyms: [] },
            { definition: "To fist-fuck.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act of breaking wind; fise.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A puffball.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To break wind.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fist",
        "https://en.wiktionary.org/wiki/fists",
      ],
    },
  ],
  wines: [
    {
      word: "wines",
      phonetic: "/waɪnz/",
      phonetics: [
        {
          text: "/waɪnz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wines-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=385388",
          license: {
            name: "BY 2.5",
            url: "https://creativecommons.org/licenses/by/2.5",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An alcoholic beverage made by fermenting the juice of grapes.",
              synonyms: [],
              antonyms: [],
              example: "She ordered some wine for the meal.",
            },
            {
              definition:
                'An alcoholic beverage made by fermenting the juice of fruits or vegetables other than grapes, usually preceded by the type of the fruit or vegetable; for example, "dandelion wine".',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A serving of wine.",
              synonyms: [],
              antonyms: [],
              example: "I'd like three beers and two wines, please.",
            },
            {
              definition: "A dark purplish red colour; the colour of red wine.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To entertain with wine.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To drink wine.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wine",
        "https://en.wiktionary.org/wiki/wines",
      ],
    },
  ],
  cares: [
    {
      word: "cares",
      phonetic: "/kɛəz/",
      phonetics: [
        { text: "/kɛəz/", audio: "" },
        {
          text: "/kɛɹz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cares-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100928",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Grief, sorrow.", synonyms: [], antonyms: [] },
            {
              definition: "Close attention; concern; responsibility.",
              synonyms: [],
              antonyms: [],
              example: "Care should be taken when holding babies.",
            },
            {
              definition: "Worry.",
              synonyms: [],
              antonyms: [],
              example: "I don't have a care in the world.",
            },
            {
              definition: "Maintenance, upkeep.",
              synonyms: [],
              antonyms: [],
              example: "dental care",
            },
            {
              definition:
                "The treatment of those in need (especially as a profession).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The state of being cared for by others.",
              synonyms: [],
              antonyms: [],
              example: "in care",
            },
            {
              definition: "The object of watchful attention or anxiety.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To be concerned (about), to have an interest (in); to feel concern (about).",
              synonyms: [],
              antonyms: [],
              example:
                '"She doesn\'t care what you think." "I don\'t care, I\'m still going."',
            },
            {
              definition:
                "(polite) To want, to desire; to like; to be inclined towards.",
              synonyms: [],
              antonyms: [],
              example: "I don't care to hear your opinion.",
            },
            {
              definition: "(with for) To look after or look out for.",
              synonyms: [],
              antonyms: [],
              example: "He cared for his mother while she was sick.",
            },
            { definition: "To mind; to object.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/care",
        "https://en.wiktionary.org/wiki/cares",
      ],
    },
  ],
  valve: [
    {
      word: "valve",
      phonetic: "/ˈvælv/",
      phonetics: [
        {
          text: "/ˈvælv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/valve-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88675763",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A device that controls the flow of a gas or fluid through a pipe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A device that admits fuel and air into the cylinder of an internal combustion engine, or one that allows combustion gases to exit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One or more membranous partitions, flaps, or folds, which permit the passage of the contents of a vessel or cavity in one direction, but stop or control the flow in the opposite direction",
              synonyms: [],
              antonyms: [],
              example: "the ileocolic, mitral, and semilunar valves",
            },
            {
              definition:
                "One of the leaves of a folding-door, or a window-sash.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A vacuum tube.", synonyms: [], antonyms: [] },
            {
              definition:
                "One of the pieces into which certain fruits naturally separate when they dehisce.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small portion of certain anthers, which opens like a trapdoor to allow the pollen to escape, such as in the barberry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the pieces or divisions of bivalve or multivalve shells.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the two similar portions of the shell of a diatom.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To control (flow) by means of a valve.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/valve"],
    },
  ],
  newer: [
    {
      word: "newer",
      phonetic: "/ˈn(j)uː.ɚ/",
      phonetics: [{ text: "/ˈn(j)uː.ɚ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Recently made, or created.",
              synonyms: [],
              antonyms: [],
              example:
                "This is a new scratch on my car!   The band just released a new album.",
            },
            {
              definition: "Additional; recently discovered.",
              synonyms: [],
              antonyms: [],
              example: "We turned up some new evidence from the old files.",
            },
            {
              definition: "Current or later, as opposed to former.",
              synonyms: [],
              antonyms: [],
              example:
                "My new car is much better than my previous one, even though it is older.   We had been in our new house for five years by then.",
            },
            {
              definition:
                "Used to distinguish something established more recently, named after something or some place previously existing.",
              synonyms: [],
              antonyms: [],
              example: "New Bond Street is an extension of Bond Street.",
            },
            {
              definition:
                "In original condition; pristine; not previously worn or used.",
              synonyms: [],
              antonyms: [],
              example: "Are you going to buy a new car or a second-hand one?",
            },
            {
              definition: "Refreshed, reinvigorated, reformed.",
              synonyms: [],
              antonyms: [],
              example:
                "That shirt is dirty. Go and put on a new one.   I feel like a new person after a good night's sleep.   After the accident, I saw the world with new eyes.",
            },
            {
              definition: "Newborn.",
              synonyms: [],
              antonyms: [],
              example:
                "My sister has a new baby, and our mother is excited to finally have a grandchild.",
            },
            {
              definition: "Of recent origin; having taken place recently.",
              synonyms: [],
              antonyms: [],
              example:
                "I can't see you for a while; the pain is still too new.   Did you see the new King Lear at the theatre?",
            },
            {
              definition: "Strange, unfamiliar or not previously known.",
              synonyms: [],
              antonyms: [],
              example: "The idea was new to me.   I need to meet new people.",
            },
            {
              definition: "Recently arrived or appeared.",
              synonyms: [],
              antonyms: [],
              example:
                "Have you met the new guy in town?   He is the new kid at school.",
            },
            {
              definition: "Inexperienced or unaccustomed at some task.",
              synonyms: [],
              antonyms: [],
              example:
                "Don't worry that you're new at this job; you'll get better with time.   I'm new at this business.",
            },
            {
              definition:
                "(of a period of time) Next; about to begin or recently begun.",
              synonyms: [],
              antonyms: [],
              example: "We expect to grow at 10% annually in the new decade.",
            },
          ],
          synonyms: [
            "recent",
            "current",
            "brand new",
            "mint",
            "pristine",
            "spanking",
            "brand new",
            "green",
            "newborn",
            "young",
            "fresh",
            "novel",
            "singular",
            "brand new",
            "recent",
            "born-again",
            "reformed",
            "refreshed",
            "reinvigorated",
            "revived",
            "strange",
            "unfamiliar",
          ],
          antonyms: [
            "dated",
            "old",
            "former",
            "old",
            "old",
            "old",
            "used",
            "worn",
            "accustomed",
            "experienced",
            "expert",
            "original",
            "previous",
            "established",
            "ancient",
            "dated",
            "old",
            "old",
            "familiar",
            "old",
            "old",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/new",
        "https://en.wiktionary.org/wiki/newer",
      ],
    },
  ],
  motel: [
    {
      word: "motel",
      phonetic: "/moʊˈtɛl/",
      phonetics: [{ text: "/moʊˈtɛl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A type of hotel or lodging establishment, often located near a major highway, which typically features a series of rooms the entrances of which are immediately adjacent to a parking lot to facilitate convenient access to automobiles parked there.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A low-cost short-stay hotel, often with hourly rates rather than daily rates, and notorious for permitting illicit sexual activities; love hotel.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To stay in a motel or motels.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/motel"],
    },
  ],
  ivory: [
    {
      word: "ivory",
      phonetic: "/ˈaɪvəɹi/",
      phonetics: [
        {
          text: "/ˈaɪvəɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ivory-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651087",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The hard white form of dentin which forms the tusks of elephants, walruses and other animals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A creamy white color, the color of ivory.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Something made from or resembling ivory.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(collective, singular or in plural) The teeth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(collective, singular or in plural) The keys of a piano.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A white person.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Made of ivory.", synonyms: [], antonyms: [] },
            {
              definition: "Resembling or having the colour of ivory.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ivory"],
    },
  ],
  necks: [
    {
      word: "necks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The part of the body connecting the head and the trunk found in humans and some animals.",
              synonyms: [],
              antonyms: [],
              example: "Giraffes have long necks.",
            },
            {
              definition:
                "The corresponding part in some other anatomical contexts.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The part of a shirt, dress etc., which fits a person's neck.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The tapered part of a bottle toward the opening.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The slender tubelike extension atop an archegonium, through which the sperm swim to reach the egg.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The extension of any stringed instrument on which a fingerboard is mounted",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A long narrow tract of land projecting from the main body, or a narrow tract connecting two larger tracts.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A reduction in size near the end of an object, formed by a groove around it.",
              synonyms: [],
              antonyms: [],
              example: "a neck forming the journal of a shaft",
            },
            {
              definition:
                "The constriction between the root and crown of a tooth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The gorgerin of a capital.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The small part of a gun between the chase and the swell of the muzzle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person's life.",
              synonyms: [],
              antonyms: [],
              example: "to risk one's neck; to save someone's neck",
            },
            { definition: "A falsehood; a lie.", synonyms: [], antonyms: [] },
            {
              definition:
                "Shapeshifting water spirits in Germanic mythology and folklore",
              synonyms: [
                "Neck",
                "Näcken",
                "nicor",
                "nix",
                "nixie",
                "nixy",
                "nokk",
                "nokken",
                "näck",
              ],
              antonyms: [],
            },
          ],
          synonyms: [
            "Neck",
            "Näcken",
            "nicor",
            "nix",
            "nixie",
            "nixy",
            "nokk",
            "nokken",
            "näck",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To hang by the neck; strangle; kill, eliminate",
              synonyms: [],
              antonyms: [],
              example: "Go neck yourself.",
            },
            {
              definition:
                "To make love; to intently kiss or cuddle; to canoodle.",
              synonyms: [
                "French kiss",
                "grope",
                "pet",
                "smooch",
                "smoodge",
                "snog",
                "snuggle",
              ],
              antonyms: [],
              example:
                "Alan and Betty were necking in the back of a car when Betty's dad caught them.",
            },
            {
              definition: "To drink rapidly.",
              synonyms: ["chug"],
              antonyms: [],
            },
            {
              definition: "To decrease in diameter.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "French kiss",
            "grope",
            "pet",
            "smooch",
            "smoodge",
            "snog",
            "snuggle",
            "chug",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/neck",
        "https://en.wiktionary.org/wiki/necks",
      ],
    },
  ],
  clamp: [
    {
      word: "clamp",
      phonetic: "/klæmp/",
      phonetics: [
        {
          text: "/klæmp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/clamp-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=49875206",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A brace, band, or clasp for strengthening or holding things together.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An instrument used to temporarily shut off blood vessels, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A parking enforcement device used to immobilise a car until it can be towed or a fine is paid; a wheel clamp.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A mass of bricks heaped up to be burned; or of ore for roasting, or of coal coking.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A pile of root vegetables stored under a layer of earth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of wood (batten) across the grain of a board end to keep it flat, as in a breadboard.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An electronic circuit that fixes either the positive or the negative peak excursions of a signal to a defined value by shifting its DC value.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To fasten in place or together with (or as if with) a clamp.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To hold or grip tightly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To modify (a numeric value) so it lies within a specific range.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cover (vegetables, etc.) with earth.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/clamp"],
    },
    {
      word: "clamp",
      phonetic: "/klæmp/",
      phonetics: [
        {
          text: "/klæmp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/clamp-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=49875206",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A heavy footstep; a tramp.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To tread heavily or clumsily; to clump or clomp.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/clamp"],
    },
  ],
  barge: [
    {
      word: "barge",
      phonetic: "/bɑːdʒ/",
      phonetics: [
        { text: "/bɑːdʒ/", audio: "" },
        { text: "/bɑɹdʒ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A large flat-bottomed towed or self-propelled boat used mainly for river and canal transport of heavy goods or bulk cargo.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A richly decorated ceremonial state vessel propelled by rowers for river processions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A large flat-bottomed coastal trading vessel having a large spritsail and jib-headed topsail, a fore staysail and a very small mizen, and having leeboards instead of a keel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One of the boats of a warship having fourteen oars",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The wooden disk in which bread or biscuit is placed on a mess table.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A double-decked passenger or freight vessel, towed by a steamboat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A large omnibus used for excursions.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["lighter"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To intrude or break through, particularly in an unwelcome or clumsy manner.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To push someone.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/barge"],
    },
  ],
  blues: [
    {
      word: "blues",
      phonetic: "/bluːz/",
      phonetics: [{ text: "/bluːz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The colour of the clear sky or the deep sea, between green and violet in the visible spectrum, and one of the primary additive colours for transmitted light; the colour obtained by subtracting red and green from white light using magenta and cyan filters; or any colour resembling this.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A blue dye or pigment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of several processes to protect metal against rust.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Blue clothing",
              synonyms: [],
              antonyms: [],
              example: "The boys in blue marched to the pipers.",
            },
            {
              definition: "(in the plural) A blue uniform. See blues.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A member of law enforcement",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The sky, literally or figuratively.",
              synonyms: [],
              antonyms: [],
              example: "His request for leave came out of the blue.",
            },
            {
              definition: "The ocean; deep waters.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The far distance; a remote or distant place.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Anything blue, especially to distinguish it from similar objects differing only in color.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A dog or cat with a slaty gray coat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the colour balls used in snooker, with a value of five points.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of the butterflies of the subfamily Polyommatinae in the family Lycaenidae, most of which have blue on their wings.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A bluefish.", synonyms: [], antonyms: [] },
            { definition: "An argument.", synonyms: [], antonyms: [] },
            {
              definition:
                "A liquid with an intense blue colour, added to a laundry wash to prevent yellowing of white clothes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A type of firecracker.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A bluestocking.", synonyms: [], antonyms: [] },
            {
              definition: "One of the three color charges for quarks.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/blue",
        "https://en.wiktionary.org/wiki/blues",
      ],
    },
    {
      word: "blues",
      phonetic: "/bluːz/",
      phonetics: [{ text: "/bluːz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(usually in the plural) A feeling of sadness or depression.",
              synonyms: [],
              antonyms: [],
              example: "I've got the blues today.",
            },
            {
              definition:
                "A musical form, African-American in origin, generally featuring an eight-bar or twelve-bar blues structure and using the blues scale.",
              synonyms: [],
              antonyms: [],
              example:
                "A large portion of modern popular music is influenced by the blues.",
            },
            {
              definition: "A uniform made principally of a blue fabric.",
              synonyms: [],
              antonyms: [],
              example: "The marched in their dress blues.",
            },
            {
              definition:
                "Any of a number of sports teams which wear blue kit.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/blues"],
    },
    {
      word: "blues",
      phonetic: "/bluːz/",
      phonetics: [{ text: "/bluːz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make or become blue.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To treat the surface of steel so that it is passivated chemically and becomes more resistant to rust.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(laundry) To brighten by treating with blue (laundry aid)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To spend (money) extravagantly; to blow.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/blue",
        "https://en.wiktionary.org/wiki/blues",
      ],
    },
  ],
  alien: [
    {
      word: "alien",
      phonetic: "/ˈeɪ.li.ən/",
      phonetics: [
        {
          text: "/ˈeɪ.li.ən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/alien-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1755109",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any life form of extraterrestrial or extradimensional origin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person, animal, plant, or other thing which is from outside the family, group, organization, or territory under consideration.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A foreigner residing in a country.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One excluded from certain privileges; one alienated or estranged.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["outlander", "fremd", "guest", "stranger"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To estrange; to alienate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To transfer the ownership of something.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Not belonging to the same country, land, or government, or to the citizens or subjects thereof; foreign.",
              synonyms: [],
              antonyms: [],
              example: "alien subjects, enemies, property, or shores",
            },
            {
              definition: "Very unfamiliar, strange, or removed.",
              synonyms: [],
              antonyms: [],
              example: "principles alien to our religion",
            },
            {
              definition: "Pertaining to extraterrestrial life.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/alien"],
    },
  ],
  frown: [
    {
      word: "frown",
      phonetic: "/fɹaʊn/",
      phonetics: [
        {
          text: "/fɹaʊn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/frown-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2650974",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A facial expression in which the eyebrows are brought together, and the forehead is wrinkled, usually indicating displeasure, sadness or worry, or less often confusion or concentration.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A facial expression in which the corners of the mouth are pointed down.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/frown"],
    },
    {
      word: "frown",
      phonetic: "/fɹaʊn/",
      phonetics: [
        {
          text: "/fɹaʊn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/frown-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2650974",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To have a frown on one's face.",
              synonyms: [],
              antonyms: [],
              example: "She frowned when I told her the news.",
            },
            {
              definition:
                "To manifest displeasure or disapprobation; to look with disfavour or threateningly.",
              synonyms: [],
              antonyms: [],
              example: "Noisy gossip in the library is frowned upon.",
            },
            {
              definition:
                "To repress or repel by expressing displeasure or disapproval; to rebuke with a look.",
              synonyms: [],
              antonyms: [],
              example: "Let us frown the impudent fellow into silence.",
            },
            {
              definition: "To communicate by frowning.",
              synonyms: [],
              antonyms: [],
              example: "Frank frowned his displeasure with my proposal.",
            },
          ],
          synonyms: ["scowl"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/frown"],
    },
  ],
  strap: [
    {
      word: "strap",
      phonetic: "/stɹæp/",
      phonetics: [
        {
          text: "/stɹæp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/strap-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241641",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A long, narrow, pliable strip of leather, cloth, or the like.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A strip of thick leather used in flogging.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something made of such a strip, or of a part of one, or a combination of two or more for a particular use.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of leather, or strip of wood covered with a suitable material, used to hone the sharpened edge of a razor; a strop.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A narrow strip of anything, as of iron or brass.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The flat part of the corolla in ligulate florets, as those of the white circle in the daisy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The leaf, exclusive of its sheath, in some grasses.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A gun, normally a personal firearm such as a pistol or machine pistol.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Credit offered to a customer, especially for alcoholic drink.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                '(with "the") A championship belt, or by extension, the title.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To beat or chastise with a strap; to whip, to lash.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fasten or bind with a strap.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To sharpen by rubbing on a strap, or strop",
              synonyms: [],
              antonyms: [],
              example: "to strap a razor",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A secondary heading, especially one printed above another.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A memorable slogan attached to a commercial brand.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/strap",
        "https://en.wiktionary.org/wiki/strapline",
      ],
    },
  ],
  crews: [
    {
      word: "crews",
      phonetic: "/kɹuːz/",
      phonetics: [{ text: "/kɹuːz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A pen for livestock such as chickens or pigs",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The Manx shearwater.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/crew",
        "https://en.wiktionary.org/wiki/crews",
      ],
    },
  ],
  shack: [
    {
      word: "shack",
      phonetic: "/ʃæk/",
      phonetics: [
        {
          text: "/ʃæk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shack-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88676273",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A crude, roughly built hut or cabin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any poorly constructed or poorly furnished building.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The room from which a ham radio operator transmits.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To live (in or with); to shack up.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shack"],
    },
    {
      word: "shack",
      phonetic: "/ʃæk/",
      phonetics: [
        {
          text: "/ʃæk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shack-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88676273",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Grain fallen to the ground and left after harvest.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Nuts which have fallen to the ground.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Freedom to pasturage in order to feed upon shack.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A shiftless fellow; a low, itinerant beggar; a vagabond; a tramp.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Bait that can be picked up at sea.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To shed or fall, as corn or grain at harvest.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To feed in stubble, or upon waste.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To wander as a vagabond or tramp.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To hibernate; to go into winter quarters.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shack"],
    },
  ],
  gonna: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  saves: [
    {
      word: "saves",
      phonetic: "/seɪvz/",
      phonetics: [{ text: "/seɪvz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "In various sports, a block that prevents an opponent from scoring.",
              synonyms: [],
              antonyms: [],
              example: "The goaltender made a great save.",
            },
            {
              definition:
                "When a relief pitcher comes into a game leading by 3 points (runs) or less, and his team wins while continually being ahead.",
              synonyms: [],
              antonyms: [],
              example: "Jones retired seven to earn the save.",
            },
            {
              definition:
                "A point in a professional wrestling match when one or more wrestlers run to the ring to aid a fellow wrestler who is being beaten.",
              synonyms: [],
              antonyms: [],
              example:
                "The giant wrestler continued to beat down his smaller opponent, until several wrestlers ran in for the save.",
            },
            {
              definition:
                "The act, process, or result of saving data to a storage medium.",
              synonyms: [],
              antonyms: [],
              example:
                "If you're hit by a power cut, you'll lose all of your changes since your last save.",
            },
            { definition: "A saving throw.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To prevent harm or difficulty.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To put aside, to avoid.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/save",
        "https://en.wiktionary.org/wiki/saves",
      ],
    },
  ],
  stump: [
    {
      word: "stump",
      phonetic: "/stʌmp/",
      phonetics: [
        {
          text: "/stʌmp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stump-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75900137",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/stʌmp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stump-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=49896644",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The remains of something that has been cut off; especially the remains of a tree, the remains of a limb.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The place or occasion at which a campaign takes place; the husting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A place or occasion at which a person harangues or otherwise addresses a group in a manner suggesting political oration.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of three small wooden posts which together with the bails make the wicket and that the fielding team attempt to hit with the ball.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(drawing) An artists’ drawing tool made of rolled paper used to smudge or blend marks made with charcoal, Conté crayon, pencil or other drawing media.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A wooden or concrete pole used to support a house.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A leg.",
              synonyms: [],
              antonyms: [],
              example: "to stir one's stumps",
            },
            {
              definition:
                "A pin in a tumbler lock which forms an obstruction to throwing the bolt except when the gates of the tumblers are properly arranged, as by the key.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A pin or projection in a lock to form a guide for a movable piece.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To stop, confuse, or puzzle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To baffle; to make unable to find an answer to a question or problem.",
              synonyms: [],
              antonyms: [],
              example: "This last question has me stumped.",
            },
            {
              definition: "To campaign.",
              synonyms: ["campaign"],
              antonyms: [],
              example: "He’s been stumping for that reform for months.",
            },
            {
              definition:
                "To travel over (a state, a district, etc.) giving speeches for electioneering purposes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of a wicket keeper) To get a batsman out stumped.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To bowl down the stumps of (a wicket).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To walk heavily or clumsily, plod, trudge.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To reduce to a stump; to truncate or cut off a part of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To strike unexpectedly; to stub, as the toe against something fixed.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["campaign"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stump"],
    },
  ],
  ferry: [
    {
      word: "ferry",
      phonetic: "/ˈfɛɹɪ/",
      phonetics: [
        { text: "/ˈfɛɹɪ/", audio: "" },
        { text: "/ˈfɛɹi/", audio: "" },
        {
          text: "/ˈfɛɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ferry-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=20641316",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A ship used to transport people, smaller vehicles and goods from one port to another, usually on a regular schedule.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A place where passengers are transported across water in such a ship.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The legal right or franchise that entitles a corporate body or an individual to operate such a service.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To carry; transport; convey.",
              synonyms: [],
              antonyms: [],
              example:
                "Trucks plowed through the water to ferry flood victims to safety.",
            },
            {
              definition:
                "To move someone or something from one place to another, usually repeatedly.",
              synonyms: [],
              antonyms: [],
              example:
                "Being a good waiter takes more than the ability to ferry plates of food around a restaurant.",
            },
            {
              definition:
                "To carry or transport over a contracted body of water, as a river or strait, in a boat or other floating conveyance plying between opposite shores.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To pass over water in a boat or by ferry.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ferry"],
    },
  ],
  idols: [
    {
      word: "idols",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A graven image or representation of anything that is revered, or believed to convey spiritual power.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A cultural icon, or especially popular person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(originally Japan) Popular entertainer; usually young, captivating, attractive; and often female, with an image of being close to fans.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An eidolon or phantom; something misleading or elusive.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["icon", "star", "superstar", "afgod"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/idol",
        "https://en.wiktionary.org/wiki/idols",
      ],
    },
  ],
  cooks: [
    {
      word: "cooks",
      phonetic: "/kʊks/",
      phonetics: [{ text: "/kʊks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A person who prepares food.",
              synonyms: [],
              antonyms: [],
              example: "I'm a terrible cook, so I eat a lot of frozen dinners.",
            },
            {
              definition: "The head cook of a manor house",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The degree or quality of cookedness of food",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One who manufactures certain illegal drugs, especially meth.",
              synonyms: [],
              antonyms: [],
              example:
                "Police found two meth cooks working in the illicit lab.",
            },
            {
              definition:
                "A session of manufacturing certain illegal drugs, especially meth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A fish, the European striped wrasse, Labrus mixtus.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To prepare (food) for eating by heating it, often by combining it with other ingredients.",
              synonyms: [],
              antonyms: [],
              example: "I'm cooking bangers and mash.",
            },
            {
              definition:
                "To prepare (unspecified) food for eating by heating it, often by combining it with other ingredients.",
              synonyms: [],
              antonyms: [],
              example: "He's in the kitchen, cooking.",
            },
            {
              definition: "To be cooked.",
              synonyms: [],
              antonyms: [],
              example: "The dinner is cooking on the stove.",
            },
            {
              definition: "To be uncomfortably hot.",
              synonyms: [],
              antonyms: [],
              example:
                "Look at that poor dog shut up in that car on a day like today - it must be cooking in there.",
            },
            {
              definition: "To execute by electric chair.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To hold onto (a grenade) briefly after igniting the fuse, so that it explodes almost immediately after being thrown.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To concoct or prepare.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To tamper with or alter; to cook up.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To play or improvise in an inspired and rhythmically exciting way. (From 1930s jive talk.)",
              synonyms: [],
              antonyms: [],
              example: "Crank up the Coltrane and start cooking!",
            },
            {
              definition: "To play music vigorously.",
              synonyms: [],
              antonyms: [],
              example: "On the Wagner piece, the orchestra was cooking!",
            },
          ],
          synonyms: ["cook off", "bake", "stew"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make the noise of the cuckoo.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To throw.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cook",
        "https://en.wiktionary.org/wiki/cooks",
      ],
    },
  ],
  juicy: [
    {
      word: "juicy",
      phonetic: "/ˈdʒusi/",
      phonetics: [
        {
          text: "/ˈdʒusi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/juicy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1316132",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having lots of juice.",
              synonyms: [],
              antonyms: [],
              example: "a juicy peach",
            },
            {
              definition: "(of a story, etc.) Exciting; titillating.",
              synonyms: [],
              antonyms: [],
              example: "I do not keep up with all the latest juicy rumors.",
            },
            {
              definition: "(of a blow, strike, etc.) Strong, painful.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Voluptuous, curvy, thick.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["unjuicy"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/juicy"],
    },
  ],
  glare: [
    {
      word: "glare",
      phonetic: "/ɡlɛə/",
      phonetics: [
        {
          text: "/ɡlɛə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/glare-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88987700",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ɡlɛəɹ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An intense, blinding light.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Showy brilliance; gaudiness.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An angry or fierce stare.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A call collision; the situation where an incoming call occurs at the same time as an outgoing call.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A smooth, bright, glassy surface.",
              synonyms: [],
              antonyms: [],
              example: "a glare of ice",
            },
            {
              definition: "A viscous, transparent substance; glair.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To stare angrily.",
              synonyms: [],
              antonyms: [],
              example:
                "He walked in late, with the teacher glaring at him the whole time.",
            },
            {
              definition: "To shine brightly.",
              synonyms: [],
              antonyms: [],
              example: "The sun glared down on the desert sand.",
            },
            {
              definition:
                "To be bright and intense, or ostentatiously splendid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To shoot out, or emit, as a dazzling light.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "(of ice) smooth and bright or translucent; glary",
              synonyms: [],
              antonyms: [],
              example: "skating on glare ice",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/glare"],
    },
  ],
  carts: [
    {
      word: "carts",
      phonetic: "/kɑːts/",
      phonetics: [
        { text: "/kɑːts/", audio: "" },
        {
          text: "/kɑɹts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/carts-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453411",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small, open, wheeled vehicle, drawn or pushed by a person or animal, more often used for transporting goods than passengers.",
              synonyms: [],
              antonyms: [],
              example: "The grocer delivered his goods by cart.",
            },
            {
              definition: "A small motor vehicle resembling a car; a go-cart.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A shopping cart.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To carry goods.",
              synonyms: [],
              antonyms: [],
              example: "I've been carting these things around all day.",
            },
            {
              definition: "To carry or convey in a cart.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To remove, especially involuntarily or for disposal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To expose in a cart by way of punishment.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A tape cartridge used for pre-recorded material such as jingles and advertisements.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A cartridge for a video game system.",
              synonyms: [],
              antonyms: [],
              example:
                "My Final Fantasy cart on the NES is still alive and kicking.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cart",
        "https://en.wiktionary.org/wiki/carts",
      ],
    },
  ],
  alloy: [
    {
      word: "alloy",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/alloy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762576",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A metal that is a combination of two or more elements, at least one of which is a metal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A metal of lesser value, mixed with a metal of greater value.",
              synonyms: [],
              antonyms: [],
              example: "gold without alloy",
            },
            {
              definition:
                "An admixture; something added which stains, taints etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Fusion, marriage, combination.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/alloy"],
    },
    {
      word: "alloy",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/alloy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762576",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To mix or combine; often used of metals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To reduce the purity of by mixing with a less valuable substance.",
              synonyms: [],
              antonyms: [],
              example:
                "to alloy gold with silver or copper, or silver with copper",
            },
            {
              definition: "To impair or debase by mixture.",
              synonyms: [],
              antonyms: [],
              example: "to alloy pleasure with misfortunes",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/alloy"],
    },
  ],
  bulbs: [
    {
      word: "bulbs",
      phonetic: "/bʌlbz/",
      phonetics: [{ text: "/bʌlbz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any solid object rounded at one end and tapering on the other, possibly attached to a larger object at the tapered end.",
              synonyms: [],
              antonyms: [],
              example: "the bulb of the aorta",
            },
            { definition: "A light bulb.", synonyms: [], antonyms: [] },
            {
              definition:
                "The bulb-shaped root portion of a plant such as a tulip, from which the rest of the plant may be regrown.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bulbous protuberance at the forefoot of certain vessels to reduce turbulence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The medulla oblongata.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To take the shape of a bulb; to swell.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bulb",
        "https://en.wiktionary.org/wiki/bulbs",
      ],
    },
  ],
  lawns: [
    {
      word: "lawns",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An open space between woods.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Ground (generally in front of or around a house) covered with grass kept closely mown.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An overgrown agar culture, such that no separation between single colonies exists.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A type of thin linen or cotton.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in the plural) Pieces of this fabric, especially as used for the sleeves of a bishop.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A piece of clothing made from lawn.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lawn",
        "https://en.wiktionary.org/wiki/lawns",
      ],
    },
  ],
  lasts: [
    {
      word: "lasts",
      phonetic: "/lasts/",
      phonetics: [
        { text: "/lasts/", audio: "" },
        { text: "/ɫasts/", audio: "" },
        { text: "/lɑːsts/", audio: "" },
        {
          text: "/læsts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lasts-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651119",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To perform, carry out.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To endure, continue over time.",
              synonyms: [],
              antonyms: [],
              example: "Summer seems to last longer each year.",
            },
            {
              definition: "To hold out, continue undefeated or entire.",
              synonyms: [],
              antonyms: [],
              example:
                "I don't know how much longer we can last without reinforcements.",
            },
          ],
          synonyms: ["continue", "endure", "survive"],
          antonyms: ["disintegrate", "dissipate", "fall apart", "wear out"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A tool for shaping or preserving the shape of shoes.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To shape with a last; to fasten or fit to a last; to place smoothly on a last.",
              synonyms: [],
              antonyms: [],
              example: "to last a boot",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A burden; load; a cargo; freight.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A measure of weight or quantity, varying in designation depending on the goods concerned.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An old English (and Dutch) measure of the carrying capacity of a ship, equal to two tons.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A load of some commodity with reference to its weight and commercial value.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/last",
        "https://en.wiktionary.org/wiki/lasts",
      ],
    },
  ],
  fuels: [
    {
      word: "fuels",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Substance consumed to provide energy through combustion, or through chemical or nuclear reaction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Substance that provides nourishment for a living organism; food.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something that stimulates, encourages or maintains an action.",
              synonyms: [],
              antonyms: [],
              example: "His books were fuel for the revolution.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To provide with fuel.", synonyms: [], antonyms: [] },
            {
              definition: "To exacerbate, to cause to grow or become greater.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fuel",
        "https://en.wiktionary.org/wiki/fuels",
      ],
    },
  ],
  oddly: [
    {
      word: "oddly",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "In a peculiar manner; strangely; unusually.",
              synonyms: ["curiously", "queerly"],
              antonyms: [],
              example: "For December, it was oddly warm.",
            },
            {
              definition: "In a manner measured by an odd number.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["curiously", "queerly"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/oddly"],
    },
  ],
  crane: [
    {
      word: "crane",
      phonetic: "/kɹeɪn/",
      phonetics: [
        {
          text: "/kɹeɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/crane-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1230519",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any bird of the family Gruidae, large birds with long legs and a long neck which is extended during flight.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Ardea herodias, the great blue heron.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A mechanical lifting machine or device, often used for lifting heavy loads for industrial or construction purposes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An iron arm with horizontal motion, attached to the side or back of a fireplace for supporting kettles etc. over the fire.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A siphon, or bent pipe, for drawing liquors out of a cask.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A forked post or projecting bracket to support spars, etc.; generally used in pairs.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To extend (one's neck).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To raise or lower with, or as if with, a crane.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To pull up before a jump.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/crane"],
    },
    {
      word: "crane",
      phonetic: "/kɹeɪn/",
      phonetics: [
        {
          text: "/kɹeɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/crane-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1230519",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The cranium.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/crane"],
    },
  ],
  filed: [
    {
      word: "filed",
      phonetic: "/faɪld/",
      phonetics: [
        {
          text: "/faɪld/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/filed-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90913499",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To commit (official papers) to some office.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To place in an archive in a logical place and order",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To store a file (aggregation of data) on a storage medium such as a disc or another computer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(with for) To submit a formal request to some office.",
              synonyms: [],
              antonyms: [],
              example: "She filed for divorce the next day.",
            },
            {
              definition: "To set in order; to arrange, or lay away.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To move in a file.",
              synonyms: [],
              antonyms: [],
              example:
                "The applicants kept filing into the room until it was full.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To smooth, grind, or cut with a file.",
              synonyms: [],
              antonyms: [],
              example:
                "I'd better file the bottoms of the table legs. Otherwise they will scratch the flooring.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To defile", synonyms: [], antonyms: [] },
            { definition: "To corrupt", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/file",
        "https://en.wiktionary.org/wiki/filed",
      ],
    },
  ],
  weird: [
    {
      word: "weird",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/weird-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75857985",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈwiːəd/", audio: "" },
        {
          text: "/ˈwiɚd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/weird-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1230402",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Fate; destiny; luck.", synonyms: [], antonyms: [] },
            { definition: "A prediction.", synonyms: [], antonyms: [] },
            { definition: "A spell or charm.", synonyms: [], antonyms: [] },
            {
              definition: "That which comes to pass; a fact.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(in the plural) The Fates (personified).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "Norns",
            "kismet",
            "lot",
            "orlay",
            "wyrd",
            "fortune",
            "luck",
            "foretale",
            "foretelling",
            "prognostication",
            "cantrip",
            "enchantment",
            "incantation",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To destine; doom; change by witchcraft or sorcery.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To warn solemnly; adjure.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having an unusually strange character or behaviour.",
              synonyms: [],
              antonyms: [],
              example: "There are lots of weird people in this place.",
            },
            {
              definition: "Deviating from the normal; bizarre.",
              synonyms: [],
              antonyms: [],
              example:
                "It was quite weird to bump into all my ex-girlfriends on the same day.",
            },
            {
              definition: "Of or pertaining to the Fates.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Connected with fate or destiny; able to influence fate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of or pertaining to witches or witchcraft; supernatural; unearthly; suggestive of witches, witchcraft, or unearthliness; wild; uncanny.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Having supernatural or preternatural power.",
              synonyms: [],
              antonyms: [],
              example: "There was a weird light shining above the hill.",
            },
          ],
          synonyms: [
            "bizarre",
            "fremd",
            "odd",
            "out of the ordinary",
            "strange",
            "eerie",
            "spooky",
            "uncanny",
            "fateful",
            "odd",
            "oddball",
            "peculiar",
            "strange",
            "whacko",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/weird"],
    },
  ],
  shawl: [
    {
      word: "shawl",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shawl-us-cot-caught%20merger.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780369",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A square or rectangular piece of cloth worn as a covering for the head, neck, and shoulders, typically by women.",
              synonyms: [],
              antonyms: [],
              example: "She wears her shawl when it's cold outside.",
            },
            {
              definition:
                "A fold of wrinkled flesh under the lips and neck of a bloodhound, used in scenting.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To wrap in a shawl.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shawl"],
    },
  ],
  slips: [
    {
      word: "slips",
      phonetic: "/slɪps/",
      phonetics: [
        {
          text: "/slɪps/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/slips-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75900006",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A thin, slippery mix of clay and water.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Mud, slime.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A twig or shoot; a cutting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A descendant, a scion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A young person (now usually with of introducing descriptive qualifier).",
              synonyms: [],
              antonyms: [],
              example:
                "She couldn't hurt a fly, young slip of a girl that she is.",
            },
            {
              definition: "A long, thin piece of something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small piece of paper, especially one longer than it is wide, typically a form for writing on or one giving printed information.",
              synonyms: [],
              antonyms: [],
              example: "a salary slip",
            },
            {
              definition:
                "(marine insurance) A memorandum of the particulars of a risk for which a policy is to be executed. It usually bears the broker's name and is initiated by the underwriters.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An act or instance of slipping.",
              synonyms: [],
              antonyms: [],
              example: "I had a slip on the ice and bruised my hip.",
            },
            {
              definition:
                "A woman's undergarment worn under a skirt or dress to conceal unwanted nudity that may otherwise be revealed by the skirt or dress itself; a shift.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A slipdress.", synonyms: [], antonyms: [] },
            { definition: "A mistake or error.", synonyms: [], antonyms: [] },
            {
              definition: "A berth; a space for a ship to moor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A difference between the theoretical distance traveled per revolution of the propeller and the actual advance of the vessel.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A slipway.", synonyms: [], antonyms: [] },
            {
              definition:
                "A one-time return to previous maladaptive behaviour after cure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of several fielding positions to the off side of the wicket keeper, designed to catch the ball after being deflected from the bat; a fielder in that position (See first slip, second slip, third slip, fourth slip and fifth slip.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A number between 0 and 1 that is the difference between the angular speed of a rotating magnetic field and the angular speed of its rotor, divided by the angular speed of the magnetic field.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A leash or string by which a dog is held; so called from its being made in such a manner as to slip, or become loose, by relaxation of the hand.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An escape; a secret or unexpected desertion.",
              synonyms: [],
              antonyms: [],
              example:
                "He gave the warden the slip and escaped from the prison.",
            },
            {
              definition:
                "A portion of the columns of a newspaper etc. struck off by itself; a proof from a column of type when set up and in the galley.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A child's pinafore.", synonyms: [], antonyms: [] },
            {
              definition: "An outside covering or case.",
              synonyms: [],
              antonyms: [],
              example: "a pillow slip",
            },
            {
              definition:
                "A counterfeit piece of money, made from brass covered with silver.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Matter found in troughs of grindstones after the grinding of edge tools.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An aqueous suspension of minerals, usually clay, used, among other things, to stick workpieces together.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A particular quantity of yarn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A narrow passage between buildings.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A long seat or narrow pew in churches, often without a door.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A dislocation of a lead, destroying continuity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The motion of the centre of resistance of the float of a paddle wheel, or the blade of an oar, through the water horizontally, or the difference between a vessel's actual speed and the speed it would have if the propelling instrument acted upon a solid; also, the velocity, relatively to still water, of the backward current of water produced by the propeller.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The difference between the actual and synchronous speeds of an induction motor.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A fish, the sole.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "blooper",
            "blunder",
            "boo-boo",
            "defect",
            "error",
            "fault",
            "faux pas",
            "fluff",
            "gaffe",
            "lapse",
            "mistake",
            "stumble",
            "thinko",
            "lapse",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To lose one’s traction on a slippery surface; to slide due to a lack of friction.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To err.", synonyms: [], antonyms: [] },
            {
              definition:
                "To accidentally reveal a secret or otherwise say something unintentional.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To move or fly (out of place); to shoot; often with out, off, etc.",
              synonyms: [],
              antonyms: [],
              example: "A bone may slip out of place.",
            },
            {
              definition: "To pass (a note, money, etc.), often covertly.",
              synonyms: [],
              antonyms: [],
              example:
                "She thanked the porter and slipped a ten-dollar bill into his hand.",
            },
            {
              definition:
                "To cause to move smoothly and quickly; to slide; to convey gently or secretly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To move quickly and often secretively; to depart, withdraw, enter, appear, intrude, or escape as if by sliding.",
              synonyms: [],
              antonyms: [],
              example: "Some errors slipped into the appendix.",
            },
            {
              definition: "To move down; to slide.",
              synonyms: [],
              antonyms: [],
              example: "Profits have slipped over the past six months.",
            },
            {
              definition:
                "To release (a dog, a bird of prey, etc.) to go after a quarry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To remove the skin of a soft fruit, such as a tomato or peach, by blanching briefly in boiling water, then transferring to cold water so that the skin peels, or slips, off easily.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To omit; to lose by negligence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cut slips from; to cut; to take off; to make a slip or slips of.",
              synonyms: [],
              antonyms: [],
              example: "to slip a piece of cloth or paper",
            },
            {
              definition: "To cause to slip or slide off, or out of place.",
              synonyms: [],
              antonyms: [],
              example: "A horse slips his bridle; a dog slips his collar.",
            },
            {
              definition: "To bring forth (young) prematurely; to slink.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause (a schedule or release, etc.) to go, or let it go, beyond the allotted deadline.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The area of the field covered by fielders in the slip positions; the slip fielders collectively",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/slip",
        "https://en.wiktionary.org/wiki/slips",
      ],
    },
  ],
  troop: [
    {
      word: "troop",
      phonetic: "/tɹuːp/",
      phonetics: [
        {
          text: "/tɹuːp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/troop-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89033310",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/tɹup/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(collective) A collection of people; a number; a multitude (in general).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small unit of cavalry or armour commanded by a captain, corresponding to a platoon or company of infantry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A detachment of soldiers or police, especially horse artillery, armour, or state troopers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: 'Soldiers, military forces (usually "troops").',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A company of stageplayers; a troupe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A basic unit of girl or boy scouts, consisting of 6 to 10 youngsters.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(collective) A group of baboons.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A particular roll of the drum; a quick march.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Mushrooms that are in a close group but not close enough to be called a cluster.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To move in numbers; to come or gather in crowds or troops.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To march on; to go forward in haste.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To move or march as if in a crowd.",
              synonyms: [],
              antonyms: [],
              example: "The children trooped into the room.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/troop"],
    },
  ],
  bolts: [
    {
      word: "bolts",
      phonetic: "/bəʊlts/",
      phonetics: [
        { text: "/bəʊlts/", audio: "" },
        { text: "/boʊlts/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A (usually) metal fastener consisting of a cylindrical body that is threaded, with a larger head on one end. It can be inserted into an unthreaded hole up to the head, with a nut then threaded on the other end; a heavy machine screw.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sliding pin or bar in a lock or latch mechanism.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bar of wood or metal dropped in horizontal hooks on a door and adjoining wall or between the two sides of a double door, to prevent the door(s) from being forced open.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sliding mechanism to chamber and unchamber a cartridge in a firearm.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small personal-armour-piercing missile for short-range use, or (in common usage though deprecated by experts) a short arrow, intended to be shot from a crossbow or a catapult.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A lightning spark, i.e., a lightning bolt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sudden event, action or emotion.",
              synonyms: [],
              antonyms: [],
              example:
                "The problem's solution struck him like a bolt from the blue.",
            },
            {
              definition:
                "A large roll of fabric or similar material, as a bolt of cloth.",
              synonyms: [],
              antonyms: [],
              example:
                '24 March 1774 - Newspaper: Stamford Mercury - "Mr. Cole, Basket-maker...has lost near 300 boults of rods" https//www.britishnewspaperarchive.co.uk/viewer/bl/0000254/17740324/001/0001',
            },
            {
              definition: "A sudden spring or start; a sudden leap aside.",
              synonyms: [],
              antonyms: [],
              example: "The horse made a bolt.",
            },
            {
              definition: "A sudden flight, as to escape creditors.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A refusal to support a nomination made by the party with which one has been connected; a breaking away from one's party.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An iron to fasten the legs of a prisoner; a shackle; a fetter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A burst of speed or efficiency.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To connect or assemble pieces using a bolt.",
              synonyms: [],
              antonyms: [],
              example: "Bolt the vice to the bench.",
            },
            {
              definition: "To secure a door by locking or barring it.",
              synonyms: [],
              antonyms: [],
              example: "Bolt the door.",
            },
            {
              definition: "To flee, to depart, to accelerate suddenly.",
              synonyms: [],
              antonyms: [],
              example: "Seeing the snake, the horse bolted.",
            },
            {
              definition:
                "To cause to start or spring forth; to dislodge (an animal being hunted).",
              synonyms: [],
              antonyms: [],
              example: "to bolt a rabbit",
            },
            {
              definition: "To strike or fall suddenly like a bolt.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To escape.", synonyms: [], antonyms: [] },
            {
              definition: "Of a plant, to grow quickly; to go to seed.",
              synonyms: [],
              antonyms: [],
              example: "Lettuce and spinach will bolt as the weather warms up.",
            },
            {
              definition: "To swallow food without chewing it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To drink one's drink very quickly; to down a drink.",
              synonyms: [],
              antonyms: [],
              example:
                "Come on, everyone, bolt your drinks; I want to go to the next pub!",
            },
            {
              definition:
                "To refuse to support a nomination made by a party or caucus with which one has been connected; to break away from a party.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To utter precipitately; to blurt or throw out.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sieve, especially a long fine sieve used in milling for bolting flour and meal; a bolter.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To sift, especially through a cloth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To sift the bran and germ from wheat flour.",
              synonyms: [],
              antonyms: [],
              example: "Graham flour is unbolted flour.",
            },
            {
              definition:
                "To separate, assort, refine, or purify by other means.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To discuss or argue privately, and for practice, as cases at law.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bolt",
        "https://en.wiktionary.org/wiki/bolts",
      ],
    },
  ],
  suite: [
    {
      word: "suite",
      phonetic: "/swiːt/",
      phonetics: [
        {
          text: "/swiːt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/suite-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2454325",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A retinue or company of attendants, as of a distinguished personage",
              synonyms: [],
              antonyms: [],
              example: "the ambassador's suite",
            },
            {
              definition:
                "A connected series or succession of objects; a number of things used or classed together",
              synonyms: [],
              antonyms: [],
              example: "a suite of minerals",
            },
            {
              definition:
                "A group of connected rooms, usually separable from other rooms by means of access.",
              synonyms: [],
              antonyms: [],
              example:
                "The Presidential suite is well appointed and allows for good security.",
            },
            {
              definition:
                "A musical form, popular before the time of the sonata, consisting of a string or series of pieces all in the same key, mostly in various dance rhythms, with sometimes an elaborate prelude.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An excerpt of instrumental music from a larger work that contains other elements besides the music; for example, the Nutcracker Suite is the music (but not the dancing) from the ballet The Nutcracker, and the Carmen Suite is the instrumental music (but not the singing and dancing) from the opera Carmen.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A group of related computer programs distributed together.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/suite"],
    },
  ],
  sleek: [
    {
      word: "sleek",
      phonetic: "/sliːk/",
      phonetics: [{ text: "/sliːk/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "That which makes smooth; varnish.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make smooth or glossy; to polish or cause to be attractive.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having an even, smooth surface; smooth",
              synonyms: ["frictionless", "silky"],
              antonyms: [],
              example: "sleek hair",
            },
            {
              definition: "Glossy",
              synonyms: ["glacé", "sheeny"],
              antonyms: [],
            },
            {
              definition: "Not rough or harsh.",
              synonyms: [
                "civilized",
                "classy",
                "elegant",
                "graceful",
                "refined",
              ],
              antonyms: [],
            },
            {
              definition: "Slim and streamlined; not plump, thick, or stocky.",
              synonyms: ["lithe", "svelte"],
              antonyms: [],
            },
          ],
          synonyms: [
            "civilized",
            "classy",
            "elegant",
            "graceful",
            "refined",
            "frictionless",
            "silky",
            "glacé",
            "sheeny",
            "lithe",
            "svelte",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "With ease and dexterity.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sleek"],
    },
  ],
  quilt: [
    {
      word: "quilt",
      phonetic: "/kwɪlt/",
      phonetics: [
        {
          text: "/kwɪlt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/quilt-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=49896634",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A bed covering consisting of two layers of fabric stitched together, with insulation between, often having a decorative design.",
              synonyms: [],
              antonyms: [],
              example: "My grandmother is going to sew a quilt.",
            },
            {
              definition:
                "A roll of material with sound-absorbing properties, used in soundproofing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A quilted skirt worn by women.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To construct a quilt.", synonyms: [], antonyms: [] },
            {
              definition:
                "To construct something, such as clothing, using the same technique.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/quilt"],
    },
  ],
  tramp: [
    {
      word: "tramp",
      phonetic: "/tɹæmp/",
      phonetics: [
        {
          text: "/tɹæmp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tramp-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75701698",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/tɹæmp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tramp-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651629",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(sometimes derogatory) A homeless person; a vagabond.",
              synonyms: ["bum", "hobo", "vagabond"],
              antonyms: [],
            },
            {
              definition: "A disreputable, promiscuous woman; a slut.",
              synonyms: [],
              antonyms: [],
              example:
                '"Claudia is such a tramp; making out with all those men when she has a boyfriend."',
            },
            {
              definition:
                "Any ship which does not have a fixed schedule or published ports of call.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A long walk, possibly of more than one day, in a scenic or wilderness area.",
              synonyms: ["bushwalk", "hike", "ramble", "trek"],
              antonyms: [],
            },
            {
              definition:
                "(in apposition) Of objects, stray and intrusive and unwanted",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A metal plate worn by diggers under the hollow of the foot to save the shoe.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "bum",
            "hobo",
            "vagabond",
            "bushwalk",
            "hike",
            "ramble",
            "trek",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To walk with heavy footsteps.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To walk for a long time (usually through difficult terrain).",
              synonyms: [],
              antonyms: [],
              example:
                "We tramped through the woods for hours before we found the main path again.",
            },
            { definition: "To hitchhike.", synonyms: [], antonyms: [] },
            {
              definition: "To tread upon forcibly and repeatedly; to trample.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To travel or wander through.",
              synonyms: [],
              antonyms: [],
              example: "to tramp the country",
            },
            {
              definition:
                "To cleanse, as clothes, by treading upon them in water.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A gymnastic and recreational device consisting of a piece of taut, strong fabric stretched over a steel frame using many coiled springs as anchors.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of a variety of looping or jumping instructions in specific programming languages",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tramp",
        "https://en.wiktionary.org/wiki/trampoline",
      ],
    },
  ],
  blaze: [
    {
      word: "blaze",
      phonetic: "/bleɪz/",
      phonetics: [
        {
          text: "/bleɪz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/blaze-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453868",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A fire, especially a fast-burning fire producing a lot of flames and light.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Intense, direct light accompanied with heat.",
              synonyms: [],
              antonyms: [],
              example: "to seek shelter from the blaze of the sun",
            },
            {
              definition:
                "The white or lighter-coloured markings on a horse's face.",
              synonyms: [],
              antonyms: [],
              example: "The palomino had a white blaze on its face.",
            },
            {
              definition:
                "A high-visibility orange colour, typically used in warning signs and hunters' clothing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bursting out, or active display of any quality; an outburst.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A spot made on trees by chipping off a piece of the bark, usually as a surveyor's mark.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A hand consisting of five face cards.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/blaze"],
    },
    {
      word: "blaze",
      phonetic: "/bleɪz/",
      phonetics: [
        {
          text: "/bleɪz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/blaze-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453868",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To be on fire, especially producing bright flames.",
              synonyms: [],
              antonyms: [],
              example: "The campfire blazed merrily.",
            },
            {
              definition:
                "To send forth or reflect a bright light; shine like a flame.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be conspicuous; shine brightly a brilliancy (of talents, deeds, etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To set in a blaze; burn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause to shine forth; exhibit vividly; be resplendent with.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(only in the past participle) To mark with a white spot on the face (as a horse).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To set a mark on (as a tree, usually by cutting off a piece of its bark).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To indicate or mark out (a trail, especially through vegetation) by a series of blazes.",
              synonyms: [],
              antonyms: [],
              example: "The guide blazed his way through the undergrowth.",
            },
            {
              definition:
                "To set a precedent for the taking-on of a challenge; lead by example.",
              synonyms: [],
              antonyms: [],
              example: "Darwin blazed a path for the rest of us.",
            },
            {
              definition: "To be furiously angry; to speak or write in a rage.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To smoke marijuana.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/blaze"],
    },
    {
      word: "blaze",
      phonetic: "/bleɪz/",
      phonetics: [
        {
          text: "/bleɪz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/blaze-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453868",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Publication; the act of spreading widely by report",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To blow, as from a trumpet",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To publish; announce publicly",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To disclose; bewray; defame",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To blazon", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/blaze"],
    },
  ],
  atlas: [
    {
      word: "atlas",
      phonetic: "/ˈætləs/",
      phonetics: [
        { text: "/ˈætləs/", audio: "" },
        { text: "/ˈætləs/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A bound collection of maps often including tables, illustrations or other text.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bound collection of tables, illustrations etc. on any given subject.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(especially of the human body) A detailed visual conspectus of something of great and multi-faceted complexity, with its elements splayed so as to be presented in as discrete a manner as possible whilst retaining a realistic view of the whole.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A collection of top-dimensional subspaces, called charts, each homeomorphic to Euclidean space, which comprise the entirety of a manifold, such that intersecting charts' respective homeomorphisms are compatible in a certain way.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The uppermost vertebra of the neck.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One who supports a heavy burden; mainstay.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A figure of a man used as a column; telamon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sheet of paper measuring 26 inches by 34 inches.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/atlas"],
    },
    {
      word: "atlas",
      phonetic: "/ˈætləs/",
      phonetics: [
        { text: "/ˈætləs/", audio: "" },
        { text: "/ˈætləs/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A rich satin fabric.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/atlas"],
    },
  ],
  odors: [
    {
      word: "odors",
      phonetic: "/ˈoʊ.dɚz/",
      phonetics: [
        {
          text: "/ˈoʊ.dɚz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/odors-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=61084538",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Any smell, whether fragrant or offensive.",
              synonyms: ["perfume", "scent"],
              antonyms: [],
            },
            {
              definition: "A strong, pervasive quality.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Esteem.",
              synonyms: ["esteem", "repute"],
              antonyms: [],
            },
            {
              definition:
                "Something which produces a scent; incense, a perfume.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["esteem", "repute", "perfume", "scent"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/odor",
        "https://en.wiktionary.org/wiki/odors",
      ],
    },
  ],
  scrub: [
    {
      word: "scrub",
      phonetic: "/skɹʌb/",
      phonetics: [
        {
          text: "/skɹʌb/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scrub-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88681607",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One who labors hard and lives meanly; a mean fellow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One who is incompetent or unable to complete easy tasks.",
              synonyms: [],
              antonyms: [],
              example:
                "What a scrub! Instead of washing the dishes she put the used food on her face!",
            },
            {
              definition:
                "A thicket or jungle, often specified by the name of the prevailing plant",
              synonyms: [],
              antonyms: [],
              example: "oak scrub",
            },
            {
              definition:
                "(stock breeding) One of the common livestock of a region of no particular breed or not of pure breed, especially when inferior in size, etc. Often used to refer to male animals unsuited for breeding.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Vegetation of inferior quality, though sometimes thick and impenetrable, growing in poor soil or in sand; also, brush.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One not on the first team of players; a substitute.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Mean; dirty; contemptible; scrubby.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scrub"],
    },
    {
      word: "scrub",
      phonetic: "/skɹʌb/",
      phonetics: [
        {
          text: "/skɹʌb/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scrub-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88681607",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An instance of scrubbing.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A cancellation.", synonyms: [], antonyms: [] },
            { definition: "A worn-out brush.", synonyms: [], antonyms: [] },
            { definition: "One who scrubs.", synonyms: [], antonyms: [] },
            {
              definition:
                "(in the plural) Clothing worn while performing surgery.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension, in the plural) Any medical uniform consisting of a short-sleeved shirt and pants (trousers).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An exfoliant for the body.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To rub hard; to wash with rubbing; usually, to rub with a wet brush, or with something coarse or rough, for the purpose of cleaning or brightening",
              synonyms: [],
              antonyms: [],
              example: "to scrub a floor",
            },
            {
              definition:
                "To rub anything hard, especially with a wet brush; to scour",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be diligent and penurious",
              synonyms: [],
              antonyms: [],
              example: "to scrub hard for a living",
            },
            {
              definition: "To call off a scheduled event; to cancel.",
              synonyms: [],
              antonyms: [],
              example:
                "Engineers had to scrub the satellite launch due to bad weather.",
            },
            {
              definition:
                "To eliminate or to correct data from a set of records to bring it inline with other similar datasets",
              synonyms: [],
              antonyms: [],
              example:
                "The street segment data from the National Post Office will need to be scrubbed before it can be integrated into our system.",
            },
            {
              definition:
                "(audio) To move a recording tape back and forth with a scrubbing motion to produce a scratching sound, or to do so by a similar use of a control on an editing system.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(audio, video) To maneuver the play position on a media editing system by using a scroll bar or touch-based interface.",
              synonyms: ["seek"],
              antonyms: [],
            },
          ],
          synonyms: ["seek"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scrub"],
    },
  ],
  crabs: [
    {
      word: "crabs",
      phonetic: "/kɹæbz/",
      phonetics: [
        {
          text: "/kɹæbz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/crabs-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79293263",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A crustacean of the infraorder Brachyura, having five pairs of legs, the foremost of which are in the form of claws, and a carapace.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The meat of this crustacean, served as food; crabmeat",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A bad-tempered person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in plural crabs) An infestation of pubic lice (Pthirus pubis).",
              synonyms: [],
              antonyms: [],
              example:
                "Although crabs themselves are an easily treated inconvenience, the patient and his partner(s) clearly run major STD risks.",
            },
            {
              definition: "A playing card with the rank of three.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A position in rowing where the oar is pushed under the rigger by the force of the water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A defect in an outwardly normal object that may render it inconvenient and troublesome to use.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An unsold book that is returned to the publisher.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To fish for crabs.", synonyms: [], antonyms: [] },
            { definition: "To ruin.", synonyms: [], antonyms: [] },
            { definition: "To complain.", synonyms: [], antonyms: [] },
            {
              definition:
                "To drift or move sideways or to leeward (by analogy with the movement of a crab).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To navigate (an aircraft, e.g. a glider) sideways against an air current in order to maintain a straight-line course.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To move (a camera) sideways.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(World War I), to fly slightly off the straight-line course towards an enemy aircraft, as the machine guns on early aircraft did not allow firing through the propeller disk.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To back out of something.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The crab apple or wild apple.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The tree bearing crab apples, which has a dogbane-like bitter bark with medical use.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cudgel made of the wood of the crab tree; a crabstick.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A movable winch or windlass with powerful gearing, used with derricks, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A form of windlass, or geared capstan, for hauling ships into dock, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A machine used in ropewalks to stretch the yarn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A claw for anchoring a portable machine.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["crab apple", "crab apple"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(obsolete) To irritate, make surly or sour",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be ill-tempered; to complain or find fault.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(British dialect) To cudgel or beat, as with a crabstick",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The tree species Carapa guianensis, native to South America.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Short for carabiner.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/crab",
        "https://en.wiktionary.org/wiki/crabs",
      ],
    },
  ],
  probe: [
    {
      word: "probe",
      phonetic: "/pɹəʊb/",
      phonetics: [
        {
          text: "/pɹəʊb/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/probe-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=94709330",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/pɹoʊb/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of various medical instruments used to explore wounds, organs, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something which penetrates something else, as though to explore; something which obtains information.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An act of probing; a prod, a poke.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An investigation or inquiry.",
              synonyms: [],
              antonyms: [],
              example: "They launched a probe into the cause of the accident.",
            },
            {
              definition:
                "A tube attached to an aircraft which can be fitted into the drogue from a tanker aircraft to allow for aerial refuelling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small device, especially an electrode, used to explore, investigate or measure something by penetrating or being placed in it.",
              synonyms: [],
              antonyms: [],
              example:
                "Insert the probe into the soil and read the temperature.",
            },
            {
              definition:
                "A small, usually unmanned, spacecraft used to acquire information or measurements about its surroundings.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(game of go) a move with multiple answers seeking to make the opponent choose and commit to a strategy",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any group of atoms or molecules radioactively labeled in order to study a given molecule or other structure",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["yosu-miru"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To explore, investigate, or question",
              synonyms: [],
              antonyms: [],
              example:
                "If you probe further, you may discover different reasons.",
            },
            {
              definition: "To insert a probe into.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/probe"],
    },
  ],
  logic: [
    {
      word: "logic",
      phonetic: "/ˈlɒdʒɪk/",
      phonetics: [
        { text: "/ˈlɒdʒɪk/", audio: "" },
        {
          text: "/ˈlɑdʒɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/logic-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762980",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A method of human thought that involves thinking in a linear, step-by-step manner about how a problem can be solved. Logic is the basis of many principles including the scientific method.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The study of the principles and criteria of valid inference and demonstration.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The mathematical study of relationships between rigorously defined concepts and of mathematical proof of statements.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A formal or informal language together with a deductive system or a model-theoretic semantics.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any system of thought, whether rigorous and productive or not, especially one associated with a particular person.",
              synonyms: [],
              antonyms: [],
              example: "It's hard to work out his system of logic.",
            },
            {
              definition:
                "The part of a system (usually electronic) that performs the boolean logic operations, short for logic gates or logic circuit.",
              synonyms: [],
              antonyms: [],
              example: "Fred is designing the logic for the new controller.",
            },
          ],
          synonyms: [
            "formal logic",
            "modern logic",
            "formal system",
            "predicate logic",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To engage in excessive or inappropriate application of logic.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To apply logical reasoning to.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To overcome by logical argument.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [{ definition: "Logical", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/logic"],
    },
  ],
  adobe: [
    {
      word: "adobe",
      phonetic: "/əˈdəʊ.bi/",
      phonetics: [
        { text: "/əˈdəʊ.bi/", audio: "" },
        {
          text: "/əˈdoʊ.bi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/adobe-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=34058898",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An unburnt brick dried in the sun.",
              synonyms: [],
              antonyms: [],
              example:
                "Many people in Texas and New Mexico live in adobe houses.",
            },
            {
              definition: "The earth from which such bricks are made.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A house made of adobe brick.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["mudbrick"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/adobe"],
    },
  ],
  exile: [
    {
      word: "exile",
      phonetic: "/ˈɛkˌsaɪl/",
      phonetics: [
        {
          text: "/ˈɛkˌsaɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/exile-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=86873136",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈɛkˌsaɪl/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The state of being banished from one's home or country.",
              synonyms: ["banishment"],
              antonyms: [],
              example: "He lived in exile.",
            },
            {
              definition: "Someone who is banished from their home or country.",
              synonyms: ["expat", "expatriate"],
              antonyms: [],
              example: "She lived as an exile.",
            },
          ],
          synonyms: ["banishment", "expat", "expatriate"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To send into exile.",
              synonyms: ["banish", "forban"],
              antonyms: [],
            },
          ],
          synonyms: ["banish", "forban"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/exile"],
    },
  ],
  rebel: [
    {
      word: "rebel",
      phonetic: "/ˈɹɛbəl/",
      phonetics: [
        {
          text: "/ˈɹɛbəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rebel-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780309",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person who resists an established authority, often violently",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person from the Confederate States of America",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rebel"],
    },
    {
      word: "rebel",
      phonetic: "/ɹɪˈbɛl/",
      phonetics: [
        {
          text: "/ɹɪˈbɛl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rebel-2-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2380137",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To resist or become defiant toward an authority.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["defy"],
          antonyms: ["obey", "submit"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rebel"],
    },
  ],
  grind: [
    {
      word: "grind",
      phonetic: "/ˈɡɹaɪnd/",
      phonetics: [
        {
          text: "/ˈɡɹaɪnd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grind-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762695",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The act of reducing to powder, or of sharpening, by friction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something that has been reduced to powder, something that has been ground.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A specific degree of pulverization of coffee beans.",
              synonyms: [],
              antonyms: [],
              example: "This bag contains espresso grind.",
            },
            {
              definition: "A tedious and laborious task.",
              synonyms: ["chore"],
              antonyms: [],
              example: "This homework is a grind.",
            },
            {
              definition: "A grinding trick on a skateboard or snowboard.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One who studies hard; a swot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Grindcore (subgenre of heavy metal)",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Hustle", synonyms: [], antonyms: [] },
          ],
          synonyms: ["chore"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To reduce to smaller pieces by crushing with lateral motion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To shape with the force of friction.",
              synonyms: [],
              antonyms: [],
              example: "grind a lens; grind an axe",
            },
            {
              definition:
                "To remove material by rubbing with an abrasive surface.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To become ground, pulverized, or polished by friction.",
              synonyms: [],
              antonyms: [],
              example: "Steel grinds to a sharp edge.",
            },
            {
              definition: "To move with much difficulty or friction; to grate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To slide the flat portion of a skateboard or snowboard across an obstacle such as a railing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To oppress, hold down or weaken.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To rotate the hips erotically.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To dance in a sexually suggestive way with both partners in very close proximity, often pressed against each other.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To repeat a task a large number of times in a row to achieve a specific goal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To operate by turning a crank.",
              synonyms: [],
              antonyms: [],
              example: "to grind an organ",
            },
            {
              definition:
                "To produce mechanically and repetitively as if by turning a crank.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To automatically format and indent code.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To instill through repetitive teaching.",
              synonyms: [],
              antonyms: [],
              example:
                "Grinding lessons into students' heads does not motivate them to learn.",
            },
            {
              definition: "To eat.",
              synonyms: [],
              antonyms: [],
              example: "Eh, brah, let's go grind.",
            },
            {
              definition: "To work or study hard; to hustle or drudge.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To annoy or irritate (a person); to grind one's gears.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/grind"],
    },
    {
      word: "grind",
      phonetic: "/ˈɡɹɪnd/",
      phonetics: [{ text: "/ˈɡɹɪnd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A traditional communal pilot whale hunt in the Faroe Islands.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["grindadráp"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/grind"],
    },
  ],
  sting: [
    {
      word: "sting",
      phonetic: "/stɪŋ/",
      phonetics: [
        {
          text: "/stɪŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sting-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1084805",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A bump left on the skin after having been stung.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A bite by an insect.", synonyms: [], antonyms: [] },
            {
              definition:
                "A pointed portion of an insect or arachnid used for attack.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sharp, localised pain primarily on the epidermis",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sharp-pointed hollow hair seated on a gland which secretes an acrid fluid, as in nettles.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The thrust of a sting into the flesh; the act of stinging; a wound inflicted by stinging.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(law enforcement) A police operation in which the police pretend to be criminals in order to catch a criminal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A short percussive phrase played by a drummer to accent the punchline in a comedy show.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A brief sequence of music used in films, TV, and video games as a form of scenic punctuation or to identify the broadcasting station.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A support for a wind tunnel model which extends parallel to the air flow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The harmful or painful part of something.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A goad; incitement.", synonyms: [], antonyms: [] },
            {
              definition:
                "The concluding point of an epigram or other sarcastic saying.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["stinger"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sting"],
    },
    {
      word: "sting",
      phonetic: "/stɪŋ/",
      phonetics: [
        {
          text: "/stɪŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sting-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1084805",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To hurt, usually by introducing poison or a sharp point, or both.",
              synonyms: [],
              antonyms: [],
              example:
                "Right so came out an adder of a little heathbush, and it stung a knight in the foot.",
            },
            {
              definition: "(of an insect) To bite.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(sometimes figurative) To hurt, to be in pain.",
              synonyms: [],
              antonyms: [],
              example: "My hand stings after knocking on the door so long.",
            },
            {
              definition: "To cause harm or pain to.",
              synonyms: [],
              antonyms: [],
              example:
                "I thought I could park in front of the hotel, but they stung me for five pounds!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sting"],
    },
  ],
  spine: [
    {
      word: "spine",
      phonetic: "/spaɪn/",
      phonetics: [
        { text: "/spaɪn/", audio: "" },
        { text: "/spaɪn/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The series of bones situated at the back from the head to the pelvis of a person, or from the head to the tail of an animal; backbone, vertebral column.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something resembling a backbone, such as a ridge, or a long, central structure from which other structures radiate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A pointed, fairly rigid protuberance or needle-like structure on an animal, shell, or plant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The heartwood of trees.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Courage or assertiveness.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The stiffness of an arrow.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["needle", "quill", "spike", "virgula"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spine"],
    },
  ],
  cling: [
    {
      word: "cling",
      phonetic: "/ˈklɪŋ/",
      phonetics: [
        {
          text: "/ˈklɪŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cling-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88681554",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Fruit (especially peach) whose flesh adheres strongly to the pit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Adherence; attachment; devotion",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To hold very tightly, as to not fall off.",
              synonyms: [],
              antonyms: [],
              example: "Seaweed clung to the anchor.",
            },
            {
              definition:
                "To adhere to an object, without being affixed, in such a way as to follow its contours. Used especially of fabrics and films.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause to adhere to, especially by twining round or embracing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause to dry up or wither.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To dry up or wither.",
              synonyms: [],
              antonyms: [],
              example: "Wood clings.",
            },
            {
              definition:
                "(with preposition to) to be fond of, to feel strongly about and dependent on",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["cleave", "stick", "clinch", "grip"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cling"],
    },
    {
      word: "cling",
      phonetic: "/ˈklɪŋ/",
      phonetics: [
        {
          text: "/ˈklɪŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cling-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88681554",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To produce a high-pitched ringing sound, like a small bell.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cling"],
    },
  ],
  desks: [
    {
      word: "desks",
      phonetic: "/dɛsks/",
      phonetics: [{ text: "/dɛsks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A table, frame, or case, in past centuries usually with a sloping top but now usually with a flat top, for the use of writers and readers. It often has a drawer or repository underneath.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A reading table or lectern to support the book from which the liturgical service is read, differing from the pulpit from which the sermon is preached; also (especially in the United States), a pulpit. Hence, used symbolically for the clerical profession.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A department of a newspaper tasked with covering a particular geographical region or aspect of the news.",
              synonyms: [],
              antonyms: [],
              example: "city desk",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To shut up, as in a desk; to treasure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To equip with a desk or desks.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/desk",
        "https://en.wiktionary.org/wiki/desks",
      ],
    },
  ],
  grove: [
    {
      word: "grove",
      phonetic: "/ɡɹəʊv/",
      phonetics: [
        { text: "/ɡɹəʊv/", audio: "" },
        {
          text: "/ɡɹoʊv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grove-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651003",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A small forest.", synonyms: [], antonyms: [] },
            {
              definition: "An orchard of fruit trees.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(Druidism) A place of worship.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A lodge of the Ancient Order of Druids.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cultivate in groves; to grow naturally so as to form groves.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of trees) To cultivate with periodic harvesting that also serves to create order (gaps and lines of trees) to facilitate further harvesting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To plough or gouge with lines.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["groove"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/grove"],
    },
  ],
  leaps: [
    {
      word: "leaps",
      phonetic: "/liːps/",
      phonetics: [{ text: "/liːps/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act of leaping or jumping.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The distance traversed by a leap or jump.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A group of leopards.", synonyms: [], antonyms: [] },
            {
              definition: "A significant move forward.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A large step in reasoning, often one that is not justified by the facts.",
              synonyms: [],
              antonyms: [],
              example:
                "It's quite a leap to claim that those cloud formations are evidence of UFOs.",
            },
            { definition: "A fault.", synonyms: [], antonyms: [] },
            {
              definition: "Copulation with, or coverture of, a female beast.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A passing from one note to another by an interval, especially by a long one, or by one including several other intermediate intervals.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A salmon ladder.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A basket.", synonyms: [], antonyms: [] },
            {
              definition: "A trap or snare for fish, made from twigs; a weely.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Half a bushel.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/leap",
        "https://en.wiktionary.org/wiki/leaps",
      ],
    },
  ],
  prose: [
    {
      word: "prose",
      phonetic: "/ˈpɹəʊz/",
      phonetics: [
        { text: "/ˈpɹəʊz/", audio: "" },
        {
          text: "/ˈpɹoʊz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/prose-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1157435",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Language, particularly written language, not intended as poetry.",
              synonyms: [],
              antonyms: [],
              example:
                "Though known mostly for her prose, she also produced a small body of excellent poems.",
            },
            {
              definition:
                "Language which evinces little imagination or animation; dull and commonplace discourse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hymn with no regular meter, sometimes introduced into the Mass.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["poetry", "verse"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To write or repeat in a dull, tedious, or prosy way.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/prose"],
    },
  ],
  lofty: [
    {
      word: "lofty",
      phonetic: "/ˈlɑfti/",
      phonetics: [
        { text: "/ˈlɑfti/", audio: "" },
        { text: "/ˈlɒfti/", audio: "" },
        {
          text: "/ˈlɔːfti/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lofty-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=363327",
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "High, tall, having great height or stature",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Idealistic, implying over-optimism",
              synonyms: [],
              antonyms: [],
              example: "a lofty goal",
            },
            {
              definition: "Extremely proud; arrogant; haughty",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["honorable", "noble"],
          antonyms: ["ignoble", "mean", "familiar", "vulgar"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lofty"],
    },
  ],
  agony: [
    {
      word: "agony",
      phonetic: "/ˈæ.ɡə.niː/",
      phonetics: [
        {
          text: "/ˈæ.ɡə.niː/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/agony-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769276",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Extreme pain.", synonyms: [], antonyms: [] },
            {
              definition:
                "The sufferings of Jesus Christ in the garden of Gethsemane.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Violent contest or striving.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Paroxysm of joy; keen emotion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The last struggle of life; death struggle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "anguish",
            "distress",
            "pang",
            "suffering",
            "throe",
            "torment",
          ],
          antonyms: ["ecstasy"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/agony"],
    },
  ],
  snare: [
    {
      word: "snare",
      phonetic: "/snɛə/",
      phonetics: [
        { text: "/snɛə/", audio: "" },
        {
          text: "/snɛəɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/snare-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=473316",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A trap (especially one made from a loop of wire, string, or leather).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A mental or psychological trap.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A loop of cord used in obstetric cases, to hold or to pull a fetus from the mother animal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A similar looped instrument formerly used to remove tumours etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A set of stiff wires held under tension against the lower skin of a drum to create a rattling sound.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A snare drum.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To catch or hold, especially with a loop.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To ensnare.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/snare"],
    },
  ],
  tusks: [
    {
      word: "tusks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One of a pair of elongated pointed teeth that extend outside the mouth of an animal such as walrus, elephant or wild boar.",
              synonyms: [],
              antonyms: [],
              example:
                "Until the CITES sales ban, elephant tusks were the 'backbone' of the legal ivory trade.",
            },
            {
              definition: "A small projection on a (tusk) tenon.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A tusk shell.", synonyms: [], antonyms: [] },
            {
              definition:
                "A projecting member like a tenon, and serving the same or a similar purpose, but composed of several steps, or offsets, called teeth.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A sharp point.", synonyms: [], antonyms: [] },
            {
              definition: "The share of a plough.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A fish, the torsk (Brosme brosme).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tusk",
        "https://en.wiktionary.org/wiki/tusks",
      ],
    },
  ],
  bulls: [
    {
      word: "bulls",
      phonetic: "/bʊlz/",
      phonetics: [{ text: "/bʊlz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An adult male of domesticated cattle or oxen.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A male of domesticated cattle or oxen of any age.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any adult male bovine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An adult male of certain large mammals, such as whales, elephants, camels and seals.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A large, strong man.", synonyms: [], antonyms: [] },
            {
              definition:
                "An investor who buys (commodities or securities) in anticipation of a rise in prices.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A policeman.", synonyms: [], antonyms: [] },
            {
              definition: "A crown coin; its value, 5 shillings.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "(Philadelphia) A man.", synonyms: [], antonyms: [] },
            {
              definition:
                "A man who has sex with another man's wife or girlfriend with the consent of both.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A drink made by pouring water into a cask that previously held liquor.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "bro",
            "cat",
            "dude",
            "guy",
            "cop",
            "copper",
            "pig",
            "rozzer",
          ],
          antonyms: ["bear"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A papal bull, an official document or edict from the Pope.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A seal affixed to a document, especially a document from the Pope.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(16th century) A bubble.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The centre of a target, inside the inner and magpie.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A shot which hits the centre of a target.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The two central rings on a dartboard.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A hard striped peppermint-flavoured boiled sweet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Thick glass set into the side of a ship to let in light.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hand-cancelled postmark issued by a counter clerk at a post office, typically done on a receipt for proof of mailing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The central part of a crown glass disk, with concentric ripple effect.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A convex glass lens which is placed in front of a lamp to concentrate the light so as to make it more conspicuous as a signal; also the lantern itself.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A £50 banknote.", synonyms: [], antonyms: [] },
            {
              definition:
                "Any of the first postage stamps produced in Brazil from 1843.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The faeces of a bull.", synonyms: [], antonyms: [] },
            {
              definition:
                "False or exaggerated statements made to impress and deceive the listener rather than inform; nonsense.",
              synonyms: [
                "BS",
                "bull",
                "bulldada",
                "bullpucky",
                "bushwah",
                "malarkey",
              ],
              antonyms: [],
              example:
                "Don't pay any attention to him. He talks a lot of bullshit.",
            },
            {
              definition:
                "A card game in which the object is to bluff about cards laid down and to determine when one's opponents are bluffing.",
              synonyms: ["BS", "I doubt it", "cheat"],
              antonyms: [],
              example: "Anyone want to play a few hands of bullshit?",
            },
            {
              definition:
                "An object of frustration and/or disgust, often caused by a perceived deception.",
              synonyms: [],
              antonyms: [],
              example:
                "That's total bullshit! I called your office and they said you never came in!",
            },
            {
              definition:
                "Statements that may be true but misleading nonetheless.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Statements made without any particular reference to their truth value.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "BS",
            "I doubt it",
            "cheat",
            "BS",
            "bull",
            "bulldada",
            "bullpucky",
            "bushwah",
            "malarkey",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bull",
        "https://en.wiktionary.org/wiki/bulls",
        "https://en.wiktionary.org/wiki/bullseye",
        "https://en.wiktionary.org/wiki/bullshit",
      ],
    },
  ],
  moods: [
    {
      word: "moods",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A mental or emotional state, composure.",
              synonyms: ["composure", "humor", "spirit", "temperament"],
              antonyms: [],
              example: "I've been in a bad mood since I dumped my boyfriend.",
            },
            {
              definition:
                "Emotional character (of a work of music, literature, or other art).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sullen, gloomy or angry mental state; a bad mood.",
              synonyms: ["huff", "pet", "temper"],
              antonyms: ["good humour", "good mood", "good spirits"],
              example: "He's in a mood with me today.",
            },
            {
              definition:
                "A disposition to do something, a state of mind receptive or disposed to do something.",
              synonyms: ["frame of mind", "huff"],
              antonyms: [],
              example: "I'm not in the mood for running today.",
            },
            {
              definition: "A prevalent atmosphere, attitude, or feeling.",
              synonyms: [],
              antonyms: [],
              example: "A good politician senses the mood of the crowd.",
            },
            {
              definition:
                "A familiar, relatable feeling, experience, or thing.",
              synonyms: ["big mood"],
              antonyms: [],
            },
            {
              definition: "Courage, heart, valor; also vim and vigor.",
              synonyms: [],
              antonyms: [],
              example: "He fought with mood in many a bloody slaught.",
            },
          ],
          synonyms: [
            "big mood",
            "composure",
            "humor",
            "spirit",
            "temperament",
            "frame of mind",
            "huff",
            "huff",
            "pet",
            "temper",
          ],
          antonyms: ["good humour", "good mood", "good spirits"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(grammar) A verb form that depends on how its containing clause relates to the speaker’s or writer’s wish, intent, or assertion about reality.",
              synonyms: ["grammatical mood", "mode"],
              antonyms: [],
              example: "The most common mood in English is the indicative.",
            },
          ],
          synonyms: ["grammatical mood", "mode"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mood",
        "https://en.wiktionary.org/wiki/moods",
      ],
    },
  ],
  humid: [
    {
      word: "humid",
      phonetic: "/ˈhjuːmɪd/",
      phonetics: [
        {
          text: "/ˈhjuːmɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/humid-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50011166",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Containing perceptible moisture (usually describing air or atmosphere); damp; moist; somewhat wet or watery.",
              synonyms: ["damp", "moist"],
              antonyms: [],
              example: "humid earth",
            },
          ],
          synonyms: ["damp", "moist"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/humid"],
    },
  ],
  finer: [
    {
      word: "finer",
      phonetic: "/ˈfaɪnə/",
      phonetics: [
        { text: "/ˈfaɪnə/", audio: "" },
        { text: "/ˈfaɪnɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Senses referring to subjective quality.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Senses referring to objective quality.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Behind the batsman and at a small angle to the line between the wickets.",
              synonyms: [],
              antonyms: [],
              example:
                "[...] to nudge it through the covers (or tickle it down to fine leg) for a four [...]",
            },
            {
              definition: "Subtle; thin; tenuous.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "fine-threaded",
            "fine-grained",
            "powdered",
            "powdery",
            "pulverised",
            "pulverized",
            "small-grained",
            "all right",
            "hunky-dory",
            "kosher",
            "o.k.",
            "ok",
            "okay",
            "excellent",
            "good",
          ],
          antonyms: ["coarse", "coarse"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One who fines or purifies.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fine",
        "https://en.wiktionary.org/wiki/finer",
      ],
    },
  ],
  dimly: [
    {
      word: "dimly",
      phonetic: "/ˈdɪmli/",
      phonetics: [
        {
          text: "/ˈdɪmli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dimly-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2650863",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            { definition: "In a dim manner.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dimly"],
    },
  ],
  plank: [
    {
      word: "plank",
      phonetic: "/plæŋk/",
      phonetics: [
        {
          text: "/plæŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/plank-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453647",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A long, broad and thick piece of timber, as opposed to a board which is less thick.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A political issue that is of concern to a faction or a party of the people and the political position that is taken on that issue.",
              synonyms: [],
              antonyms: [],
              example:
                "Germanization was a central plank of German conservative thinking in the 19th and 20th centuries.",
            },
            {
              definition:
                "Physical exercise in which one holds a pushup position for a measured length of time.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A stupid person, idiot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "That which supports or upholds.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cover something with planking.",
              synonyms: [],
              antonyms: [],
              example: "to plank a floor or a ship",
            },
            {
              definition: "To bake (fish, etc.) on a piece of cedar lumber.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To lay down, as on a plank or table; to stake or pay cash.",
              synonyms: [],
              antonyms: [],
              example: "to plank money in a wager",
            },
            {
              definition: "To harden, as hat bodies, by felting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To splice together the ends of slivers of wool, for subsequent drawing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To pose for a photograph while lying rigid, face down, arms at side, in an unusual place.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/plank"],
    },
  ],
  china: [
    {
      word: "china",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/china-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75269996",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/tʃʌɪnə/", audio: "" },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/china-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1684572",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The plant Smilax china, a liana of much of eastern Asia.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["china"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A plant or flower of the repeat-blooming Chinese rose species Rosa chinensis.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A plant or flower of one of the class of hybrids developed from Rosa chinensis.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Synonym of elderflower rose, Rosa cymosa.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["china"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Hibiscus rosa-sinensis, native to east Asia.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["China rose"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Chinaware: porcelain tableware.",
              synonyms: [],
              antonyms: [],
              example:
                "He set the table with china, cloth napkins, and crystal stemware.",
            },
            {
              definition:
                "Cheaper and lower-quality ceramic and ceramic tableware, distinguished from porcelain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Synonym of cheyney: worsted or woolen stuff.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Tea from China, varieties cured by smoking or opposed to Indian cultivars.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(games) A glazed china marble.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A kind of drum cymbal approximating a Chinese style of cymbal, but usually with Turkish influences.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small gastropod (Monetaria moneta, syn. Cypraea moneta) common in the Indian Ocean; its shell.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) Any gastropod of the genus Cypraea; its shell.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) Any gastropod of the family Cypraeidae; its shell.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["money cowrie", "wampum"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person other than a family member, spouse or lover whose company one enjoys and towards whom one feels affection.",
              synonyms: [],
              antonyms: [],
              example:
                "John and I have been friends ever since we were roommates at college.   Trust is important between friends.   I used to find it hard to make friends when I was shy.",
            },
            {
              definition: "An associate who provides assistance.",
              synonyms: [],
              antonyms: [],
              example:
                "The Automobile Association is every motorist's friend.   The police is every law-abiding citizen's friend.",
            },
            {
              definition:
                "A person with whom one is vaguely or indirectly acquainted.",
              synonyms: [],
              antonyms: [],
              example:
                "a friend of a friend;  I added him as a friend on Facebook, but I hardly know him.",
            },
            {
              definition: "A person who backs or supports something.",
              synonyms: [],
              antonyms: [],
              example: "I’m not a friend of cheap wine.",
            },
            {
              definition: "An object or idea that can be used for good.",
              synonyms: [],
              antonyms: [],
              example: "Wiktionary is your friend.",
            },
            {
              definition:
                "(used only in the vocative) Used as a form of address when warning someone.",
              synonyms: [],
              antonyms: [],
              example: "You’d better watch it, friend.",
            },
            {
              definition:
                "A function or class granted special access to the private and protected members of another class.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A spring-loaded camming device.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A lover; a boyfriend or girlfriend.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A relative, a relation by blood or marriage.",
              synonyms: [],
              antonyms: [],
              example: "Friends agree best at a distance.",
            },
          ],
          synonyms: [
            "buddy",
            "buster",
            "mate",
            "pal",
            "sonny",
            "admirer",
            "booster",
            "champion",
            "protagonist",
            "supporter",
            "ally",
            "acquaintance",
            "contact",
          ],
          antonyms: ["enemy", "foe", "stranger"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A fine clay, rich in kaolinite, used in ceramics, paper-making, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["E559", "anti-caking agent", "china clay", "paper clay"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(usually uncountable) A hard white translucent ceramic, originally made by firing kaolin, quartz, and feldspar at high temperatures but now also inclusive of similar artificial materials; also often such a material as a symbol of the fragility, elegance, etc. traditionally associated with porcelain goods.",
              synonyms: [],
              antonyms: [],
              example: "Tableware and toilets are both made of porcelain.",
            },
            {
              definition:
                "(now usually plural) An object made of porcelain, art objects or items of tableware.",
              synonyms: [],
              antonyms: [],
              example:
                "The museum has an extensive collection of rare Chinese porcelains.",
            },
            {
              definition:
                "(often capitalized) A kind of pigeon with deep brown and off-white feathers.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["china", "chinaware"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Small beads made from polished shells, especially white ones, formerly used as money and jewelry by certain Native American peoples.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A string of such beads.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Money.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["peag", "porcelain", "seawan"],
          antonyms: ["suckanhock"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/China%20root",
        "https://en.wiktionary.org/wiki/China%20rose",
        "https://en.wiktionary.org/wiki/Chinese%20hibiscus",
        "https://en.wiktionary.org/wiki/china",
        "https://en.wiktionary.org/wiki/cowrie",
        "https://en.wiktionary.org/wiki/friend",
        "https://en.wiktionary.org/wiki/kaolin",
        "https://en.wiktionary.org/wiki/porcelain",
        "https://en.wiktionary.org/wiki/wampum",
      ],
    },
  ],
  pines: [
    {
      word: "pines",
      phonetic: "/paɪnz/",
      phonetics: [
        {
          text: "/paɪnz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pines-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651298",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Any coniferous tree of the genus Pinus.",
              synonyms: ["pine tree"],
              antonyms: [],
              example: "The northern slopes were covered mainly in pine.",
            },
            {
              definition:
                "Any tree (usually coniferous) which resembles a member of this genus in some respect.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The wood of this tree.",
              synonyms: ["pinewood"],
              antonyms: [],
            },
            {
              definition: "(obsolete except South Africa) A pineapple.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["pine tree", "pinewood"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A painful longing.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To languish; to lose flesh or wear away through distress.",
              synonyms: ["droop", "languish"],
              antonyms: [],
            },
            {
              definition: "To long, to yearn so much that it causes suffering.",
              synonyms: ["long", "yearn"],
              antonyms: [],
              example: "Laura was pining for Bill all the time he was gone.",
            },
            {
              definition: "To grieve or mourn for.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To inflict pain upon; to torment.",
              synonyms: ["afflict", "torment", "torture"],
              antonyms: [],
            },
          ],
          synonyms: [
            "afflict",
            "torment",
            "torture",
            "droop",
            "languish",
            "long",
            "yearn",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pine",
        "https://en.wiktionary.org/wiki/pines",
      ],
    },
  ],
  guilt: [
    {
      word: "guilt",
      phonetic: "/ɡɪlt/",
      phonetics: [{ text: "/ɡɪlt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Responsibility for wrongdoing.",
              synonyms: [],
              antonyms: ["innocence"],
            },
            {
              definition:
                "The state of having been found guilty or admitted guilt in legal proceedings.",
              synonyms: [],
              antonyms: ["innocence"],
            },
            {
              definition: "The regret of having done wrong.",
              synonyms: ["remorse"],
              antonyms: [],
            },
          ],
          synonyms: ["remorse"],
          antonyms: ["innocence", "innocence"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/guilt"],
    },
    {
      word: "guilt",
      phonetic: "/ɡɪlt/",
      phonetics: [{ text: "/ɡɪlt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To commit offenses; act criminally.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause someone to feel guilt, particularly in order to influence their behaviour.",
              synonyms: [],
              antonyms: [],
              example:
                "He didn't want to do it, but his wife guilted him into it.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/guilt"],
    },
  ],
  sacks: [
    {
      word: "sacks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A bag or pouch inside a plant or animal that typically contains a fluid.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(games) A sacrifice.",
              synonyms: [],
              antonyms: [],
              example:
                "Kasparov's queen sac early in the game gained him a positional advantage against Kramnik.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(games) To sacrifice.",
              synonyms: [],
              antonyms: [],
              example:
                "I kept saccing monsters at the altar until I was rewarded with a new weapon.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The privilege, formerly enjoyed by the lord of a manor, of holding courts, trying causes, and imposing fines.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A bag; especially a large bag of strong, coarse material for storage and handling of various commodities, such as potatoes, coal, coffee; or, a bag with handles used at a supermarket, a grocery sack; or, a small bag for small items, a satchel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The amount a sack holds; also, an archaic or historical measure of varying capacity, depending on commodity type and according to local usage; an old English measure of weight, usually of wool, equal to 13 stone (182 pounds), or in other sources, 26 stone (364 pounds).",
              synonyms: [],
              antonyms: [],
              example:
                "The American sack of salt is 215 pounds; the sack of wheat, two bushels. — McElrath.",
            },
            {
              definition:
                "The plunder and pillaging of a captured town or city.",
              synonyms: [],
              antonyms: [],
              example: "The sack of Rome.",
            },
            {
              definition: "Loot or booty obtained by pillage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A successful tackle of the quarterback behind the line of scrimmage. See verb sense4 below.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the square bases anchored at first base, second base, or third base.",
              synonyms: [],
              antonyms: [],
              example: "He twisted his ankle sliding into the sack at second.",
            },
            {
              definition:
                "Dismissal from employment, or discharge from a position, usually as give (someone) the sack or get the sack. See verb sense5 below.",
              synonyms: [],
              antonyms: [],
              example: "He got the sack for being late all the time.",
            },
            {
              definition:
                "Bed; usually as hit the sack or in the sack. See also sack out.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(also sacque) A kind of loose-fitting gown or dress with sleeves which hangs from the shoulders, such as a gown with a Watteau back or sack-back, fashionable in the late 17th to 18th century; or, formerly, a loose-fitting hip-length jacket, cloak or cape.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sack coat; a kind of coat worn by men, and extending from top to bottom without a cross seam.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The scrotum.",
              synonyms: [],
              antonyms: [],
              example: "He got passed the ball, but it hit him in the sack.",
            },
          ],
          synonyms: [
            "bag",
            "poke",
            "tote",
            "hay",
            "rack",
            "pink slip",
            "the axe",
            "the boot",
            "the chop",
            "the elbow",
            "the old heave-ho",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To put in a sack or sacks.",
              synonyms: [],
              antonyms: [],
              example: "Help me sack the groceries.",
            },
            {
              definition:
                "To bear or carry in a sack upon the back or the shoulders.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To plunder or pillage, especially after capture; to obtain spoils of war from.",
              synonyms: [],
              antonyms: [],
              example: "The barbarians sacked Rome.",
            },
            {
              definition:
                "To tackle the quarterback behind the line of scrimmage, especially before he is able to throw a pass.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To discharge from a job or position; to fire.",
              synonyms: [],
              antonyms: [],
              example: "He was sacked last September.",
            },
            {
              definition:
                "In the phrase sack out, to fall asleep. See also hit the sack.",
              synonyms: [],
              antonyms: [],
              example: "The kids all sacked out before 9:00 on New Year’s Eve.",
            },
          ],
          synonyms: [
            "loot",
            "ransack",
            "rack",
            "axe",
            "can",
            "dismiss",
            "fire",
            "give the boot",
            "give the chop",
            "give the elbow",
            "give the old heave-ho",
            "lay off",
            "let go",
            "redundant",
            "terminate",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A variety of light-colored dry wine from Spain or the Canary Islands; also, any strong white wine from southern Europe; sherry.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sac",
        "https://en.wiktionary.org/wiki/sack",
        "https://en.wiktionary.org/wiki/sacks",
      ],
    },
  ],
  brace: [
    {
      word: "brace",
      phonetic: "/bɹeɪs/",
      phonetics: [
        {
          text: "/bɹeɪs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/brace-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=86872858",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Armor for the arm; vambrace.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A measurement of length, originally representing a person's outstretched arms.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A curved instrument or handle of iron or wood, for holding and turning bits, etc.; a bitstock.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That which holds anything tightly or supports it firmly; a bandage or a prop.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cord, ligament, or rod, for producing or maintaining tension.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A thong used to regulate the tension of a drum.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The state of being braced or tight; tension.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Harness; warlike preparation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'A curved, pointed line, also known as "curly bracket": { or } connecting two or more words or lines, which are to be considered together, such as in {role, roll}; in music, used to connect staves.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A pair, a couple; originally used of dogs, and later of animals generally (e.g., a brace of conies) and then other things, but rarely human persons. (The plural in this sense is unchanged.) In British use (as plural), this is a particularly common reference to game birds.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of material used to transmit, or change the direction of, weight or pressure; any one of the pieces, in a frame or truss, which divide the structure into triangular parts. It may act as a tie, or as a strut, and serves to prevent distortion of the structure, and transverse strains in its members. A boiler brace is a diagonal stay, connecting the head with the shell.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A rope reeved through a block at the end of a yard, by which the yard is moved horizontally; also, a rudder gudgeon.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The mouth of a shaft.", synonyms: [], antonyms: [] },
            {
              definition:
                "(chiefly in the plural) Straps or bands to sustain trousers; suspenders.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(plural in North America, singular or plural in the UK) A system of wires, brackets, and elastic bands used to correct crooked teeth or to reduce overbite.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Two goals scored by one player in a game.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["fathom", "dyad", "twosome"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To prepare for something bad, such as an impact or blow.",
              synonyms: [],
              antonyms: [],
              example: "All hands, brace for impact!",
            },
            {
              definition:
                "To place in a position for resisting pressure; to hold firmly.",
              synonyms: [],
              antonyms: [],
              example: "He braced himself against the crowd.",
            },
            {
              definition:
                "To swing round the yards of a square rigged ship, using braces, to present a more efficient sail surface to the direction of the wind.",
              synonyms: [],
              antonyms: [],
              example: "to brace the yards",
            },
            {
              definition:
                "To stop someone for questioning, usually said of police.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To confront with questions, demands or requests.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To furnish with braces; to support; to prop.",
              synonyms: [],
              antonyms: [],
              example: "to brace a beam in a building",
            },
            {
              definition:
                "To draw tight; to tighten; to put in a state of tension; to strain; to strengthen.",
              synonyms: [],
              antonyms: [],
              example: "to brace the nerves",
            },
            {
              definition: "To bind or tie closely; to fasten tightly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/brace"],
    },
  ],
  quote: [
    {
      word: "quote",
      phonetic: "/kwəʊt/",
      phonetics: [
        {
          text: "/kwəʊt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/quote-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1763028",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A quotation; a statement attributed to a person.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A quotation mark.", synonyms: [], antonyms: [] },
            {
              definition: "A summary of work to be done with a set price.",
              synonyms: [],
              antonyms: [],
              example:
                "After going over the hefty quotes, the board decided it was cheaper to have the project executed by its own staff.",
            },
            {
              definition: "A price set for a financial security or commodity.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To repeat the exact words of (a person).",
              synonyms: [],
              antonyms: [],
              example: "The writer quoted the president.",
            },
            {
              definition: "To repeat (the exact words of a person).",
              synonyms: [],
              antonyms: [],
              example: "The writer quoted the president's speech.",
            },
            {
              definition:
                "To prepare a summary of work to be done and set a price.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Commerce) To name the current price, notably of a financial security.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To indicate verbally or by equivalent means the start of a quotation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To observe, to take account of.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["cite"],
          antonyms: ["end quote", "unquote"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/quote"],
    },
  ],
  lathe: [
    {
      word: "lathe",
      phonetic: "/leɪð/",
      phonetics: [
        {
          text: "/leɪð/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lathe-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=872814",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To invite; bid; ask.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lathe"],
    },
    {
      word: "lathe",
      phonetic: "/leɪð/",
      phonetics: [
        {
          text: "/leɪð/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lathe-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=872814",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An administrative division of the county of Kent, in England, from the Anglo-Saxon period until it fell entirely out of use in the early twentieth century.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lathe"],
    },
    {
      word: "lathe",
      phonetic: "/leɪð/",
      phonetics: [
        {
          text: "/leɪð/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lathe-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=872814",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A machine tool used to shape a piece of material, or workpiece, by rotating the workpiece against a cutting tool.",
              synonyms: [],
              antonyms: [],
              example: "He shaped the bedpost by turning it on a lathe.",
            },
            {
              definition:
                "The movable swing frame of a loom, carrying the reed for separating the warp threads and beating up the weft; a lay, or batten.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A granary; a barn.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To shape with a lathe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To produce a three-dimensional model by rotating a set of points around a fixed axis.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lathe"],
    },
  ],
  gaily: [
    {
      word: "gaily",
      phonetic: "/ˈɡeɪli/",
      phonetics: [{ text: "/ˈɡeɪli/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            { definition: "Merrily.", synonyms: [], antonyms: [] },
            { definition: "Showily.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gaily"],
    },
  ],
  fonts: [
    {
      word: "fonts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A receptacle in a church for holy water, especially one used in baptism.",
              synonyms: ["stoup"],
              antonyms: [],
            },
            {
              definition: "A receptacle for oil in a lamp.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Spring, source, fountain.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["stoup"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A set of glyphs of unified design, belonging to one typeface (e.g., Helvetica), style (e.g., italic), and weight (e.g., bold). Usually representing the letters of an alphabet and its supplementary characters.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A computer file containing the code used to draw and compose the glyphs of one or more typographic fonts on a computer display or printer.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To overlay (text) on the picture.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A source, wellspring, fount.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/font",
        "https://en.wiktionary.org/wiki/fonts",
      ],
    },
  ],
  scalp: [
    {
      word: "scalp",
      phonetic: "/skælp/",
      phonetics: [
        {
          text: "/skælp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scalp-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780350",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The top of the head; the skull.",
              synonyms: ["skull"],
              antonyms: [],
            },
            {
              definition:
                "The part of the head where the hair grows from, or used to grow from.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A part of the skin of the head, with the hair attached, formerly cut or torn off from an enemy by warriors in some cultures as a token of victory.",
              synonyms: [],
              antonyms: [],
              example:
                "Some tribes used to collect scalps to prove how many of the enemy they had killed in battle.",
            },
            {
              definition:
                "The skin of the head of a stag with the horns attached.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A victory, especially at the expense of someone else.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A bed or stratum of shellfish.",
              synonyms: ["scaup"],
              antonyms: [],
            },
            {
              definition: "The top; the summit.",
              synonyms: ["summit"],
              antonyms: [],
            },
          ],
          synonyms: ["scaup", "skull", "summit"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To remove the scalp (part of the head from where the hair grows), by brutal act or accident.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To resell, especially tickets, usually for an inflated price, often illegally.",
              synonyms: ["resell"],
              antonyms: [],
              example: "Tickets were being scalped for $300.",
            },
            {
              definition:
                "On an open outcry exchange trading floor, to buy and sell rapidly for one's own account, aiming to buy from a seller and a little later sell to a buyer, making a small profit from the difference (roughly the amount of the bid/offer spread, or less).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To screen or sieve ore before further processing.",
              synonyms: ["sieve"],
              antonyms: [],
              example: "scalped ore",
            },
            {
              definition: "To remove the skin of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To remove the grass from.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To destroy the political influence of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(milling) To brush the hairs or fuzz from (wheat grains, etc.) in the process of high milling.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["resell", "sieve"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scalp"],
    },
  ],
  adopt: [
    {
      word: "adopt",
      phonetic: "/əˈdɒpt/",
      phonetics: [
        { text: "/əˈdɒpt/", audio: "" },
        {
          text: "/əˈdɑpt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/adopt-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769271",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To take by choice into relationship (a child, heir, friend, citizen, etc.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To take or receive as one's own what is not so naturally.",
              synonyms: [],
              antonyms: [],
              example:
                "He adopted a new look in order to fit in with his new workmates.",
            },
            {
              definition: "To select and take or approve.",
              synonyms: [],
              antonyms: [],
              example: "These resolutions were adopted.",
            },
            {
              definition: "To win ten consecutive games against an opponent",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/adopt"],
    },
  ],
  foggy: [
    {
      word: "foggy",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Obscured by mist or fog; unclear; hazy",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Confused, befuddled, etc.",
              synonyms: [],
              antonyms: [],
              example: "He was still foggy with sleep.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/foggy"],
    },
  ],
  ferns: [
    {
      word: "ferns",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of a group of some twenty thousand species of vascular plants classified in the division Pteridophyta that lack seeds and reproduce by shedding spores to initiate an alternation of generations.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fern",
        "https://en.wiktionary.org/wiki/ferns",
      ],
    },
  ],
  grams: [
    {
      word: "grams",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A photograph or video shared on this service.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A unit of mass equal to one-thousandth of a kilogram. Symbol: g",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Grandmother", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/'gram",
        "https://en.wiktionary.org/wiki/Instagram",
        "https://en.wiktionary.org/wiki/gram",
        "https://en.wiktionary.org/wiki/grams",
      ],
    },
    {
      word: "grams",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Grandmother", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/grams"],
    },
  ],
  clump: [
    {
      word: "clump",
      phonetic: "/klʌmp/",
      phonetics: [
        {
          text: "/klʌmp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/clump-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=694227",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A cluster or lump; an unshaped piece or mass.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A thick group or bunch, especially of bushes or hair.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A dull thud.", synonyms: [], antonyms: [] },
            {
              definition: "The compressed clay of coal strata.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A small group of trees or plants.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A thick addition to the sole of a shoe.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To form clusters or lumps.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To gather in dense groups.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To walk with heavy footfalls.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To strike; to beat.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/clump"],
    },
  ],
  perch: [
    {
      word: "perch",
      phonetic: "/pɜːtʃ/",
      phonetics: [
        { text: "/pɜːtʃ/", audio: "" },
        {
          text: "/pɝtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/perch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1763017",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of the three species of spiny-finned freshwater fish in the genus Perca.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of the about 200 related species of fish in the taxonomic family Percidae, especially:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Several similar species in the order Perciformes, such as the grouper.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A rod, staff, or branch of a tree etc used as a roost by a bird",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A pole connecting the fore gear and hind gear of a spring carriage; a reach.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A position that is secure and advantageous, especially one which is prominent or elevated",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A position that is overly elevated or haughty",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A linear measure of 5 1/2 yards, equal to a rod, a pole or 1/4 chain; the related square measure",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cubic measure of stonework equal to 16.6 × 1.5 × 1 feet",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A frame used to examine cloth",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bar used to support a candle (especially in a church)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/perch"],
    },
    {
      word: "perch",
      phonetic: "/pɜːtʃ/",
      phonetics: [
        { text: "/pɜːtʃ/", audio: "" },
        {
          text: "/pɝtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/perch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1763017",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of the three species of spiny-finned freshwater fish in the genus Perca.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of the about 200 related species of fish in the taxonomic family Percidae, especially:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Several similar species in the order Perciformes, such as the grouper.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A rod, staff, or branch of a tree etc used as a roost by a bird",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A pole connecting the fore gear and hind gear of a spring carriage; a reach.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A position that is secure and advantageous, especially one which is prominent or elevated",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A position that is overly elevated or haughty",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A linear measure of 5 1/2 yards, equal to a rod, a pole or 1/4 chain; the related square measure",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cubic measure of stonework equal to 16.6 × 1.5 × 1 feet",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A frame used to examine cloth",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bar used to support a candle (especially in a church)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To rest on (or as if on) a perch; to roost.",
              synonyms: [],
              antonyms: [],
              example: "The macaw was perched on Jim's shoulder.",
            },
            {
              definition: "To stay in an elevated position.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To place something on (or as if on) a perch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To inspect cloth using a perch.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/perch"],
    },
  ],
  tumor: [
    {
      word: "tumor",
      phonetic: "/tjuː.mə/",
      phonetics: [
        {
          text: "/tjuː.mə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tumor-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=53355682",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/tuː.məɹ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An abnormal growth; differential diagnosis includes abscess, metaplasia, and neoplasia.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["neoplasm"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tumor"],
    },
  ],
  teens: [
    {
      word: "teens",
      phonetic: "/tiːnz/",
      phonetics: [{ text: "/tiːnz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A teenager.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Grief; sorrow; trouble.",
              synonyms: ["harm", "ill-fortune", "suffering"],
              antonyms: [],
            },
            {
              definition: "Vexation; anger; hate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["harm", "ill-fortune", "suffering"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The numbers between thirteen and nineteen.",
              synonyms: [],
              antonyms: [],
              example: "The number of people at the meeting was in the teens.",
            },
            {
              definition:
                "The time of life between thirteen and nineteen years old; the teenage years.",
              synonyms: [],
              antonyms: [],
              example: "She is in her teens.",
            },
            {
              definition:
                "The second decade of a century: the 1910s, 2010s, etc. The oneties, the tens.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(temperature, rates) The range between 10 and 19.",
              synonyms: [],
              antonyms: [],
              example:
                "Although it was a scorching desert by day, temperatures could drop as low as the teens at night.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/teen",
        "https://en.wiktionary.org/wiki/teens",
      ],
    },
  ],
  crank: [
    {
      word: "crank",
      phonetic: "/kɹæŋk/",
      phonetics: [
        {
          text: "/kɹæŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/crank-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=196881",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A bent piece of an axle or shaft, or an attached arm perpendicular, or nearly so, to the end of a shaft or wheel, used to impart a rotation to a wheel or other mechanical device; also used to change circular into reciprocating motion, or reciprocating into circular motion.",
              synonyms: [],
              antonyms: [],
              example:
                "I grind my coffee by hand with a coffee grinder with a crank handle.",
            },
            {
              definition:
                "The act of converting power into motion, by turning a crankshaft.",
              synonyms: [],
              antonyms: [],
              example: "Yes, a crank was all it needed to start.",
            },
            {
              definition: "Any bend, turn, or winding, as of a passage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An ill-tempered or nasty person.",
              synonyms: [],
              antonyms: [],
              example: "Billy-Bob is a nasty old crank! He chased my cat away.",
            },
            {
              definition: "A twist or turn of the mind; caprice; whim;",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A fit of temper or passion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(dated in US) A person who is considered strange or odd by others. They may behave in unconventional ways.",
              synonyms: ["kook", "odd duck", "weirdo"],
              antonyms: [],
              example: "John is a crank because he talks to himself.",
            },
            {
              definition: "(1800s) A baseball fan.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An advocate of a pseudoscience movement.",
              synonyms: ["crackpot"],
              antonyms: [],
              example:
                "That crank next door thinks he’s created cold fusion in his garage.",
            },
            {
              definition:
                "A twist or turn in speech; word play consisting in a change of the form or meaning of a word.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sick person; an invalid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A penis.",
              synonyms: ["cock", "dick"],
              antonyms: [],
            },
          ],
          synonyms: ["cock", "dick", "crackpot", "kook", "odd duck", "weirdo"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To turn by means of a crank.",
              synonyms: [],
              antonyms: [],
              example: "Motorists had to crank their engine by hand.",
            },
            {
              definition: "To turn a crank.",
              synonyms: [],
              antonyms: [],
              example:
                "He's been cranking all day and yet it refuses to crank.",
            },
            {
              definition: "(of a crank or similar) To turn.",
              synonyms: [],
              antonyms: [],
              example:
                "He's been cranking all day and yet it refuses to crank.",
            },
            {
              definition:
                "To cause to spin via other means, as though turned by a crank.",
              synonyms: [],
              antonyms: [],
              example: "Crank it up!",
            },
            {
              definition:
                "To act in a cranky manner; to behave unreasonably and irritably, especially through complaining.",
              synonyms: [],
              antonyms: [],
              example: "Quit cranking about your spilt milk!",
            },
            {
              definition: "To be running at a high level of output or effort.",
              synonyms: [],
              antonyms: [],
              example:
                "By one hour into the shift, the boys were really cranking.",
            },
            {
              definition:
                "To run with a winding course; to double; to crook; to wind and turn.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Strange, weird, odd.", synonyms: [], antonyms: [] },
            { definition: "Sick; unwell", synonyms: ["infirm"], antonyms: [] },
            {
              definition:
                "(of a ship) Liable to capsize because of poorly stowed cargo or insufficient ballast.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Full of spirit; brisk; lively; sprightly; overconfident; opinionated.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["infirm"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A highly addictive phenethylamine stimulant drug, similar to cocaine. Its systematic (IUPAC) name is (S)-N-methyl-1-phenylpropan-2-amine.",
              synonyms: [
                "crystal",
                "crystal meth",
                "glass",
                "ice",
                "meth",
                "methamphetamines",
                "methylamphetamine",
                "speed",
                "tina",
                "tweak",
              ],
              antonyms: [],
            },
          ],
          synonyms: [
            "crystal",
            "crystal meth",
            "glass",
            "ice",
            "meth",
            "methamphetamines",
            "methylamphetamine",
            "speed",
            "tina",
            "tweak",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/crank",
        "https://en.wiktionary.org/wiki/methamphetamine",
      ],
    },
  ],
  fable: [
    {
      word: "fable",
      phonetic: "/ˈfeɪbəl/",
      phonetics: [
        {
          text: "/ˈfeɪbəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fable-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1755151",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A fictitious narrative intended to enforce some useful truth or precept, usually with animals, etc. as characters; an apologue. Prototypically, Aesop's Fables.",
              synonyms: ["morality play"],
              antonyms: [],
            },
            {
              definition:
                "Any story told to excite wonder; common talk; the theme of talk.",
              synonyms: ["legend"],
              antonyms: [],
            },
            {
              definition: "Fiction; untruth; falsehood.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The plot, story, or connected series of events forming the subject of an epic or dramatic poem.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["legend", "morality play"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To compose fables; hence, to write or speak fiction; to write or utter what is not true.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make up; to devise, and speak of, as true or real; to tell of falsely; to recount in the form of a fable.",
              synonyms: ["devise", "feign", "invent", "make up"],
              antonyms: [],
            },
          ],
          synonyms: ["devise", "feign", "invent", "make up"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fable"],
    },
  ],
  hedge: [
    {
      word: "hedge",
      phonetic: "/hɛdʒ/",
      phonetics: [
        {
          text: "/hɛdʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hedge-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651019",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A thicket of bushes or other shrubbery, especially one planted as a fence between two portions of land, or to separate the parts of a garden.",
              synonyms: [],
              antonyms: [],
              example: "He trims the hedge once a week.",
            },
            {
              definition:
                "A barrier (often consisting of a line of persons or objects) to protect someone or something from harm.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A mound of earth, stone- or turf-faced, often topped with bushes, used as a fence between any two portions of land.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A non-committal or intentionally ambiguous statement.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Contract or arrangement reducing one's exposure to risk (for example the risk of price movements or interest rate movements).",
              synonyms: [],
              antonyms: [],
              example:
                "A hedge is an investment position intended to offset potential losses/gains that may be incurred by a companion investment. In simple language, a hedge is used to reduce any substantial losses/gains suffered by an individual or an organization.",
            },
            {
              definition:
                "(noun adjunct) Used attributively, with figurative indication of a person's upbringing, or professional activities, taking place by the side of the road; third-rate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hedge"],
    },
    {
      word: "hedge",
      phonetic: "/hɛdʒ/",
      phonetics: [
        {
          text: "/hɛdʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hedge-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651019",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To enclose with a hedge or hedges.",
              synonyms: [],
              antonyms: [],
              example: "to hedge a field or garden",
            },
            {
              definition: "To obstruct or surround.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To offset the risk associated with.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To avoid verbal commitment.",
              synonyms: [],
              antonyms: [],
              example: "He carefully hedged his statements with weasel words.",
            },
            {
              definition: "To construct or repair a hedge.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To reduce one's exposure to risk.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hedge"],
    },
  ],
  genes: [
    {
      word: "genes",
      phonetic: "/dʒiːnz/",
      phonetics: [{ text: "/dʒiːnz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A theoretical unit of heredity of living organisms; a gene may take several values and in principle predetermines a precise trait of an organism's form (phenotype), such as hair color.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A segment of DNA or RNA from a cell's or an organism's genome, that may take several forms and thus parameterizes a phenomenon, in general the structure of a protein; locus.",
              synonyms: [],
              antonyms: [],
              example:
                "A change in a gene is reflected in the protein or RNA molecule that it codes for.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gene",
        "https://en.wiktionary.org/wiki/genes",
      ],
    },
  ],
  sober: [
    {
      word: "sober",
      phonetic: "/ˈsəʊ.bə(ɹ)/",
      phonetics: [
        {
          text: "/ˈsəʊ.bə(ɹ)/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sober-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780387",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(often with up) To make or become sober.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(often with up) To overcome or lose a state of intoxication.",
              synonyms: [],
              antonyms: [],
              example: "It took him hours to sober up.",
            },
            {
              definition: "To moderate one's feelings",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Not drunk; not intoxicated",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Not given to excessive drinking of alcohol",
              synonyms: [],
              antonyms: [],
              example:
                "1890, John Charles, The Godly, Righteous, And Sober Life",
            },
            {
              definition:
                "Moderate; realistic; serious; not playful; not passionate; cool; self-controlled",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Dull; not bright or colorful",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Subdued; solemn; grave",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Poor; feeble", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: ["drunk"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sober"],
    },
  ],
  boast: [
    {
      word: "boast",
      phonetic: "/bəʊst/",
      phonetics: [
        { text: "/bəʊst/", audio: "" },
        {
          text: "/boʊst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/boast-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453873",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A brag; ostentatious positive appraisal of oneself.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Something that one brags about.",
              synonyms: [],
              antonyms: [],
              example:
                "It was his regular boast that he could eat two full English breakfasts in one sitting.",
            },
            {
              definition:
                "A shot where the ball is driven off a side wall and then strikes the front wall.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To brag; to talk loudly in praise of oneself.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To speak of with pride, vanity, or exultation, with a view to self-commendation; to extol.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To speak in exulting language of another; to glory; to exult.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To play a boast shot.", synonyms: [], antonyms: [] },
            {
              definition: "To possess something special.",
              synonyms: [],
              antonyms: [],
              example: "His family boasted a famous name.",
            },
          ],
          synonyms: ["brag"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/boast"],
    },
    {
      word: "boast",
      phonetic: "/bəʊst/",
      phonetics: [
        { text: "/bəʊst/", audio: "" },
        {
          text: "/boʊst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/boast-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453873",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To dress, as a stone, with a broad chisel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(sculpting) To shape roughly as a preparation for the finer work to follow; to cut to the general form required.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/boast"],
    },
  ],
  tract: [
    {
      word: "tract",
      phonetic: "/tɹækt/",
      phonetics: [
        {
          text: "/tɹækt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tract-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651626",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An area or expanse.",
              synonyms: [],
              antonyms: [],
              example: "an unexplored tract of sea",
            },
            {
              definition:
                "A series of connected body organs, as in the digestive tract.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small booklet such as a pamphlet, often for promotional or informational uses.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A brief treatise or discourse on a subject.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A commentator's view or perspective on a subject.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Continued or protracted duration, length, extent",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Part of the proper of the liturgical celebration of the Eucharist for many Christian denominations, used instead of the alleluia during Lenten or pre-Lenten seasons, in a Requiem Mass, and on a few other penitential occasions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Continuity or extension of anything.",
              synonyms: [],
              antonyms: [],
              example: "the tract of speech",
            },
            {
              definition: "Traits; features; lineaments.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The footprint of a wild animal.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Track; trace.", synonyms: [], antonyms: [] },
            {
              definition: "Treatment; exposition.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["system"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tract"],
    },
    {
      word: "tract",
      phonetic: "/tɹækt/",
      phonetics: [
        {
          text: "/tɹækt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tract-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651626",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To pursue, follow; to track.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To draw out; to protract.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tract"],
    },
  ],
  cigar: [
    {
      word: "cigar",
      phonetic: "/sɪˈɡɑː(ɹ)/",
      phonetics: [
        { text: "/sɪˈɡɑː(ɹ)/", audio: "" },
        {
          text: "/sɪˈɡɑɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cigar-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762897",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Tobacco rolled and wrapped with an outer covering of tobacco leaves, intended to be smoked.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Penis", synonyms: [], antonyms: [] },
          ],
          synonyms: ["stogie"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cigar"],
    },
  ],
  unite: [
    {
      word: "unite",
      phonetic: "/juˈnaɪt/",
      phonetics: [
        {
          text: "/juˈnaɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/unite-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780467",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A British gold coin worth 20 shillings, first produced during the reign of King James I, and bearing a legend indicating the king's intention of uniting the kingdoms of England and Scotland.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To bring together as one.",
              synonyms: [],
              antonyms: [],
              example:
                "I hope this song can unite people from all different cultures.",
            },
            {
              definition: "To come together as one.",
              synonyms: [],
              antonyms: [],
              example: "If we want to win, we will need to unite.",
            },
          ],
          synonyms: ["bewed", "join"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/unite"],
    },
  ],
  owing: [
    {
      word: "owing",
      phonetic: "/ˈəʊ.ɪŋ/",
      phonetics: [
        { text: "/ˈəʊ.ɪŋ/", audio: "" },
        {
          text: "/ˈoʊ.ɪŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/owing-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749920",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To be under an obligation to give something back to someone or to perform some action for someone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To have debt; to be in debt.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Still to be paid; owed as a debt.",
              synonyms: [],
              antonyms: [],
              example: "These seven subscriptions remain owing.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/owe",
        "https://en.wiktionary.org/wiki/owing",
      ],
    },
  ],
  thigh: [
    {
      word: "thigh",
      phonetic: "/θaɪ/",
      phonetics: [
        {
          text: "/θaɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/thigh-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=872817",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The upper leg of a human, between the hip and the knee.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That part of the leg of vertebrates (or sometimes other animals) which corresponds to the human thigh in position or function; the tibia of a horse, the tarsus of a bird; the third leg-section of an insect.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/thigh"],
    },
  ],
  haiku: [
    {
      word: "haiku",
      phonetic: "/ˈhaɪ.kuː/",
      phonetics: [
        { text: "/ˈhaɪ.kuː/", audio: "" },
        {
          text: "/haɪˈku/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/haiku-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=8167296",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A Japanese poem in three lines, the first and last consisting of five morae, and the second consisting of seven morae, usually with an emphasis on the season or a naturalistic theme.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A three-line poem in any language, with five syllables in the first and last lines and seven syllables in the second, usually with an emphasis on the season or a naturalistic theme.",
              synonyms: [],
              antonyms: [],
              example: "Haiku, a poem",
            },
          ],
          synonyms: ["hokku"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/haiku"],
    },
  ],
  swish: [
    {
      word: "swish",
      phonetic: "/swɪʃ/",
      phonetics: [
        {
          text: "/swɪʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/swish-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241648",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A short rustling, hissing or whistling sound, often made by friction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hissing, sweeping movement through the air, as of an animal's tail.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sound of liquid flowing inside a container.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A twig or bundle of twigs, used for administering beatings; a switch",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A successful basketball shot that does not touch the rim or backboard.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An effeminate male homosexual.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An improvised alcoholic drink made by fermenting whatever ingredients are available.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make a rustling sound while moving.",
              synonyms: [],
              antonyms: [],
              example: "The cane swishes.",
            },
            {
              definition: "To flourish with a swishing sound.",
              synonyms: [],
              antonyms: [],
              example: "to swish a cane back and forth",
            },
            { definition: "To flog; to lash.", synonyms: [], antonyms: [] },
            {
              definition:
                "To make a successful basketball shot that does not touch the rim or backboard.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To mince or otherwise to behave in an effeminate manner.",
              synonyms: [],
              antonyms: [],
              example: "I shall not swish; I'll merely act limp-wristed.",
            },
            {
              definition:
                "(transitive) To cause a liquid to move around in a container, or in one's mouth.",
              synonyms: [],
              antonyms: [],
              example:
                "Swish the mouthwash around the mouth and between the teeth for one minute.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Sophisticated; fashionable; smooth.",
              synonyms: [],
              antonyms: [],
              example:
                "This restaurant looks very swish — it even has linen tablecloths.",
            },
            { definition: "Attractive, stylish", synonyms: [], antonyms: [] },
            { definition: "Effeminate.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition:
                "A hissing or whistling sound of something travelling quickly through the air.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/swish"],
    },
  ],
  dikes: [
    {
      word: "dikes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A well-dressed man.", synonyms: [], antonyms: [] },
            {
              definition: "Formalwear or other fashionable dress.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(usually derogatory) A lesbian, particularly one with masculine or butch traits or behavior.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dike",
        "https://en.wiktionary.org/wiki/dikes",
        "https://en.wiktionary.org/wiki/dyke",
      ],
    },
  ],
  wedge: [
    {
      word: "wedge",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wedge-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75857970",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/wɛdʒ/", audio: "" },
        { text: "/wɛdʒ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One of the simple machines; a piece of material, such as metal or wood, thick at one edge and tapered to a thin edge at the other for insertion in a narrow crevice, used for splitting, tightening, securing, or levering.",
              synonyms: [],
              antonyms: [],
              example:
                "Stick a wedge under the door, will you? It keeps blowing shut.",
            },
            {
              definition:
                "A piece (of food, metal, wood etc.) having this shape.",
              synonyms: [],
              antonyms: [],
              example: "Can you cut me a wedge of cheese?",
            },
            {
              definition:
                "A five-sided polyhedron with a rectangular base, two rectangular or trapezoidal sides meeting in an edge, and two triangular ends.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something that creates a division, gap or distance between things.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A flank of cavalry acting to split some portion of an opposing army, charging in an inverted V formation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A type of iron club used for short, high trajectories.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A group of geese, swans or other birds when they are in flight in a V formation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One of a pair of wedge-heeled shoes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A quantity of money.",
              synonyms: [],
              antonyms: [],
              example: "I made a big fat wedge from that job.",
            },
            {
              definition: "A sandwich made on a long, cylindrical roll.",
              synonyms: [],
              antonyms: [],
              example: "I ordered a chicken parm wedge from the deli.",
            },
            { definition: "Háček", synonyms: [], antonyms: [] },
            {
              definition:
                "The IPA character ʌ, which denotes an open-mid back unrounded vowel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The symbol ∧, denoting a meet (infimum) operation or logical conjunction.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A wedge tornado.", synonyms: [], antonyms: [] },
            {
              definition:
                "A market trend characterized by a contracting range in prices coupled with an upward trend in prices (a rising wedge) or a downward trend in prices (a falling wedge).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["skein", "turned v"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To support or secure using a wedge.",
              synonyms: [],
              antonyms: [],
              example: "I wedged open the window with a screwdriver.",
            },
            {
              definition: "To force into a narrow gap.",
              synonyms: [],
              antonyms: [],
              example:
                "He had wedged the package between the wall and the back of the sofa.",
            },
            {
              definition:
                "To work wet clay by cutting or kneading for the purpose of homogenizing the mass and expelling air bubbles.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of a computer program or system: to get stuck in an unresponsive state.",
              synonyms: [],
              antonyms: [],
              example:
                "My Linux kernel wedged after I installed the latest update.",
            },
            {
              definition: "To cleave with a wedge.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To force or drive with a wedge.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To shape into a wedge.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/wedge"],
    },
    {
      word: "wedge",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wedge-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75857970",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/wɛdʒ/", audio: "" },
        { text: "/wɛdʒ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(Cambridge University slang) The person whose name stands lowest on the list of the classical tripos.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["wooden wedge"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/wedge"],
    },
  ],
  booth: [
    {
      word: "booth",
      phonetic: "/buːð/",
      phonetics: [
        { text: "/buːð/", audio: "" },
        { text: "/buːθ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A small stall for the display and sale of goods.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An enclosure just big enough to accommodate one standing person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An enclosed table with seats, as in a diner or café.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An enclosure for keeping animals.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/booth"],
    },
  ],
  eased: [
    {
      word: "eased",
      phonetic: "/iːzd/",
      phonetics: [{ text: "/iːzd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To free (something) from pain, worry, agitation, etc.",
              synonyms: [],
              antonyms: [],
              example: "He eased his conscience by confessing.",
            },
            {
              definition: "To alleviate, assuage or lessen (pain).",
              synonyms: [],
              antonyms: [],
              example: "He loosened his shoe to ease the pain.",
            },
            {
              definition: "To give respite to (someone).",
              synonyms: [],
              antonyms: [],
              example: "The provision of extra staff eased their workload.",
            },
            {
              definition: "To loosen or slacken the tension on a line.",
              synonyms: [],
              antonyms: [],
              example: "We eased the boom vang, then lowered the sail.",
            },
            {
              definition: "To reduce the difficulty of (something).",
              synonyms: [],
              antonyms: [],
              example: "We had to ease the entry requirements.",
            },
            {
              definition: "To move (something) slowly and carefully.",
              synonyms: [],
              antonyms: [],
              example: "He eased the cork from the bottle.",
            },
            {
              definition: "To lessen in severity.",
              synonyms: [],
              antonyms: [],
              example: "The pain eased overnight.",
            },
            {
              definition: "To proceed with little effort.",
              synonyms: [],
              antonyms: [],
              example: "The car eased onto the motorway.",
            },
          ],
          synonyms: [
            "allay",
            "alleviate",
            "assuage",
            "lessen",
            "reduce",
            "assuage",
            "salve",
            "give someone a break",
            "lay off",
            "lessen",
            "reduce",
            "loosen",
            "relax",
            "slacken",
            "cruise",
            "facilitate",
            "simplify",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ease",
        "https://en.wiktionary.org/wiki/eased",
      ],
    },
  ],
  frail: [
    {
      word: "frail",
      phonetic: "/fɹeɪl/",
      phonetics: [
        {
          text: "/fɹeɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/frail-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241229",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A basket made of rushes, used chiefly to hold figs and raisins.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The quantity of fruit or other items contained in a frail.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A rush for weaving baskets.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A girl.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To play a stringed instrument, usually a banjo, by picking with the back of a fingernail.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Easily broken physically; not firm or durable; liable to fail and perish",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Weak; infirm.", synonyms: [], antonyms: [] },
            { definition: "Mentally fragile.", synonyms: [], antonyms: [] },
            {
              definition:
                "Liable to fall from virtue or be led into sin; not strong against temptation; weak in resolution; unchaste.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/frail"],
    },
  ],
  cough: [
    {
      word: "cough",
      phonetic: "/kɑf/",
      phonetics: [
        { text: "/kɑf/", audio: "" },
        { text: "/kɒf/", audio: "" },
        {
          text: "/kɔf/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cough-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=186542",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sudden, usually noisy expulsion of air from the lungs, often involuntary.",
              synonyms: [],
              antonyms: [],
              example: "Behind me, I heard a distinct, dry cough.",
            },
            {
              definition:
                "A condition that causes one to cough; a tendency to cough.",
              synonyms: [],
              antonyms: [],
              example:
                "Sorry, I can't come to work today – I've got a nasty cough.",
            },
            {
              definition:
                "Used to focus attention on a following utterance, often a euphemism or an attribution of blame",
              synonyms: [],
              antonyms: [],
              example: "He was – cough – indisposed.",
            },
          ],
          synonyms: ["tussis"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To push air from the lungs in a quick, noisy explosion.",
              synonyms: [],
              antonyms: [],
              example:
                "I breathed in a lungful of smoke by mistake, and started to cough.",
            },
            {
              definition:
                '(sometimes followed by "up") To force something out of the throat or lungs by coughing.',
              synonyms: [],
              antonyms: [],
              example: "Sometimes she coughed (up) blood.",
            },
            {
              definition: "To make a noise like a cough.",
              synonyms: [],
              antonyms: [],
              example: "The engine coughed and sputtered.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cough"],
    },
  ],
  tombs: [
    {
      word: "tombs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                'A small building (or "vault") for the remains of the dead, with walls, a roof, and (if it is to be used for more than one corpse) a door. It may be partly or wholly in the ground (except for its entrance) in a cemetery, or it may be inside a church proper or in its crypt. Single tombs may be permanently sealed; those for families (or other groups) have doors for access whenever needed.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A pit in which the dead body of a human being is deposited; a grave.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One who keeps secrets.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [{ definition: "To bury.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tomb",
        "https://en.wiktionary.org/wiki/tombs",
      ],
    },
  ],
  darts: [
    {
      word: "darts",
      phonetic: "/ˈdɑːts/",
      phonetics: [
        {
          text: "/ˈdɑːts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/darts-uk.mp3",
          sourceUrl:
            "https://commons.wikimedia.org/w/index.php?curid=100246727",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A pointed missile weapon, intended to be thrown by the hand, for example a short lance or javelin",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any sharp-pointed missile weapon, such as an arrow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(sometimes figurative) Anything resembling such a missile; something that pierces or wounds like such a weapon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small object with a pointed tip at one end and feathers at the other, which is thrown at a target in the game of darts.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A dart-shaped target towed behind an aircraft to train shooters.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A plan or scheme.", synonyms: [], antonyms: [] },
            {
              definition: "A sudden or fast movement.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A fold that is stitched on a garment.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A fish, the dace.", synonyms: [], antonyms: [] },
            { definition: "A cigarette.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To throw with a sudden effort or thrust; to hurl or launch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To send forth suddenly or rapidly; to emit; to shoot",
              synonyms: [],
              antonyms: [],
              example: "The sun darts forth his beams.",
            },
            {
              definition:
                "To shoot with a dart, especially a tranquilizer dart",
              synonyms: [],
              antonyms: [],
              example:
                "They had to dart the animal to get close enough to help",
            },
            {
              definition:
                "To fly or pass swiftly, like a dart; to move rapidly in one direction; to shoot out quickly",
              synonyms: [],
              antonyms: [],
              example: "The flying man darted eastward.",
            },
            {
              definition: "To start and run with speed; to shoot rapidly along",
              synonyms: [],
              antonyms: [],
              example: "The deer darted from the thicket.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(games) A game or sport in which darts are thrown at a board, and points are scored depending on where the darts land",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dart",
        "https://en.wiktionary.org/wiki/darts",
      ],
    },
  ],
  forts: [
    {
      word: "forts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A fortified defensive structure stationed with troops.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any permanent army post.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An outlying trading-station, as in British North America.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "bastion",
            "bulwark",
            "bunker",
            "castle",
            "citadel",
            "donjon",
            "fortification",
            "fortress",
            "foxhole",
            "keep",
            "motte and bailey",
            "rampart",
            "stronghold",
            "air base",
            "armory",
            "arsenal",
            "base",
            "camp",
            "headquarters",
            "supply depot",
            "watchtower",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To create a fort, fortifications, a strong point, or a redoubt.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fort",
        "https://en.wiktionary.org/wiki/forts",
      ],
    },
  ],
  choir: [
    {
      word: "choir",
      phonetic: "/kwaɪə(ɹ)/",
      phonetics: [
        {
          text: "/kwaɪə(ɹ)/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/choir-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=501816",
        },
        {
          text: "/kwaɪɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/choir-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50330720",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Singing group; group of people who sing together; company of people who are trained to sing together.",
              synonyms: [],
              antonyms: [],
              example: "The church choir practices Thursday nights.",
            },
            {
              definition:
                "The part of a church where the choir assembles for song.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Christian angelology) One of the nine ranks or orders of angels.",
              synonyms: [],
              antonyms: [],
              example:
                "Seraphim, Cherubim, and Thrones are three of the choirs of angels.",
            },
            {
              definition: "Set of strings (one per note) for a harpsichord.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To sing in concert.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/choir"],
    },
  ],
  pouch: [
    {
      word: "pouch",
      phonetic: "/paʊt͡ʃ/",
      phonetics: [
        {
          text: "/paʊt͡ʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pouch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780284",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A small bag usually closed with a drawstring.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A pocket in which a marsupial carries its young.",
              synonyms: ["marsupium"],
              antonyms: [],
            },
            {
              definition:
                "Any pocket or bag-shaped object, such as a cheek pouch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A protuberant belly; a paunch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A cyst or sac containing fluid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A silicle, or short pod, as of the shepherd's purse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bulkhead in the hold of a vessel, to prevent grain etc. from shifting.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["marsupium"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To enclose within a pouch.",
              synonyms: [],
              antonyms: [],
              example: "The beggar pouched the coin.",
            },
            {
              definition:
                "To transport within a pouch, especially a diplomatic pouch.",
              synonyms: [],
              antonyms: [],
              example:
                "We pouched the encryption device to our embassy in Beijing.",
            },
            {
              definition: "(of fowls and fish) To swallow.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To pout.", synonyms: [], antonyms: [] },
            {
              definition: "To pocket; to put up with.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pouch"],
    },
  ],
  pinch: [
    {
      word: "pinch",
      phonetic: "/pɪntʃ/",
      phonetics: [
        {
          text: "/pɪntʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pinch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780270",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The action of squeezing a small amount of a person's skin and flesh, making it hurt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A close compression of anything with the fingers.",
              synonyms: [],
              antonyms: [],
              example:
                "I gave the leather of the sofa a pinch, gauging the texture.",
            },
            {
              definition:
                "A small amount of powder or granules, such that the amount could be held between fingertip and thumb tip.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An awkward situation of some kind (especially money or social) which is difficult to escape.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A metal bar used as a lever for lifting weights, rolling wheels, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An organic herbal smoke additive.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A magnetic compression of an electrically-conducting filament.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The narrow part connecting the two bulbs of an hourglass.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An arrest.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To squeeze a small amount of a person's skin and flesh, making it hurt.",
              synonyms: [],
              antonyms: [],
              example: "The children were scolded for pinching each other.",
            },
            {
              definition: "To squeeze between the thumb and forefinger.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To squeeze between two objects.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To steal, usually something inconsequential.",
              synonyms: [],
              antonyms: [],
              example: "Someone has pinched my handkerchief!",
            },
            { definition: "To arrest or capture.", synonyms: [], antonyms: [] },
            {
              definition:
                "To cut shoots or buds of a plant in order to shape the plant, or to improve its yield.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To sail so close-hauled that the sails begin to flutter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To take hold; to grip, as a dog does.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be stingy or covetous; to live sparingly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To seize; to grip; to bite; said of animals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cramp; to straiten; to oppress; to starve.",
              synonyms: [],
              antonyms: [],
              example: "to be pinched for money",
            },
            {
              definition:
                "To move, as a railroad car, by prying the wheels with a pinch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To complain or find fault.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pinch"],
    },
  ],
  hairy: [
    {
      word: "hairy",
      phonetic: "/ˈhɛəɹi/",
      phonetics: [
        {
          text: "/ˈhɛəɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hairy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780123",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of a person, having a lot of hair on the body.",
              synonyms: [],
              antonyms: [],
              example: "a hairy man",
            },
            {
              definition: "Of an animal, having a lot of fur.",
              synonyms: [],
              antonyms: [],
              example: "hairy mammoth",
            },
            {
              definition:
                "Of a body part other than the head, having hair growing from it.",
              synonyms: [],
              antonyms: [],
              example: "She is a great admirer of hairy chests.",
            },
            {
              definition: "Difficult, complex, intricate, or intimidating.",
              synonyms: [],
              antonyms: [],
              example:
                "It’s a hairy problem, and will probably take several weeks to sort out.",
            },
            {
              definition: "Causing anxiety or fright; terrifying, scary.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "furry",
            "furry",
            "hirsute",
            "furry",
            "hairful",
            "hirsute",
          ],
          antonyms: ["bald", "hairless"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hairy"],
    },
  ],
  buyer: [
    {
      word: "buyer",
      phonetic: "/ˈbaɪ.ə(ɹ)/",
      phonetics: [
        { text: "/ˈbaɪ.ə(ɹ)/", audio: "" },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/buyer-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769857",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A person who makes one or more purchases.",
              synonyms: [],
              antonyms: [],
              example:
                "Every person who steps through the door is a potential buyer, so acknowledge their presence.",
            },
            {
              definition:
                "(retailing) A person who purchases items for resale in a retail establishment.",
              synonyms: [],
              antonyms: [],
              example:
                "The supermarket's new buyer decided to stock a larger range of vegetarian foods.",
            },
            {
              definition:
                "A person who purchases items consumed or used as components in the manufacture of products.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["purchasing agent", "purchaser"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/buyer"],
    },
  ],
  torch: [
    {
      word: "torch",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/torch-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75701656",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/tɔːtʃ/", audio: "" },
        {
          text: "/tɔɹtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/torch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769541",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A stick with a flame on one end, used chiefly as a light source; a similarly shaped implement with a replaceable supply of flammable material.",
              synonyms: [],
              antonyms: [],
              example:
                "The mob of angry villagers carried torches and pitchforks to the vampire's castle.",
            },
            {
              definition:
                "A portable light source powered by electricity; a flashlight.",
              synonyms: [],
              antonyms: [],
              example:
                "Ernst slipped and dropped his torch on the flagstones, shattering the bulb and plunging us into darkness.",
            },
            { definition: "An arsonist.", synonyms: [], antonyms: [] },
            {
              definition: "A blowtorch or oxy-gas torch.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["flashlight", "brand"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To set fire to, especially by use of a torch (flaming stick).",
              synonyms: [],
              antonyms: [],
              example:
                "Some hoodlums had torched a derelict automobile, which emitted a ghastly pall of thick, black smoke that filled the street.",
            },
          ],
          synonyms: ["ablaze", "burn", "firebomb", "ignite", "inflame", "set"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/torch"],
    },
  ],
  vigor: [
    {
      word: "vigor",
      phonetic: "/ˈvɪɡɚ/",
      phonetics: [
        {
          text: "/ˈvɪɡɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/vigor-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651690",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Active strength or force of body or mind; capacity for exertion, physically, intellectually, or morally; energy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Strength or force in animal or vegetable nature or action.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Strength; efficacy; potency.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/vigor",
        "https://en.wiktionary.org/wiki/vigour",
      ],
    },
  ],
  waltz: [
    {
      word: "waltz",
      phonetic: "/wɔːlts/",
      phonetics: [
        { text: "/wɔːlts/", audio: "" },
        { text: "/wɑlts/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A ballroom dance in 3/4 time.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of music for this dance (or in triple time).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A simple task.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To dance the waltz (with).",
              synonyms: [],
              antonyms: [],
              example:
                "They waltzed for twenty-one hours and seventeen minutes straight, setting a record.",
            },
            {
              definition:
                "(usually with in, into, around, etc.) To move briskly and unhesitatingly, especially in an inappropriately casual manner, or when unannounced or uninvited.",
              synonyms: [],
              antonyms: [],
              example: "He waltzed into the room like he owned the place.",
            },
            {
              definition: "To accomplish a task with little effort.",
              synonyms: [],
              antonyms: [],
              example: "Don't worry about the interview — you'll waltz it.",
            },
            { definition: "To move with fanfare.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/waltz"],
    },
  ],
  heats: [
    {
      word: "heats",
      phonetic: "/hiːts/",
      phonetics: [{ text: "/hiːts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Thermal energy.",
              synonyms: [],
              antonyms: [],
              example:
                "This furnace puts out 5000 BTUs of heat.   That engine is really throwing off some heat.   Removal of heat from the liquid caused it to turn into a solid.",
            },
            {
              definition: "The condition or quality of being hot.",
              synonyms: [],
              antonyms: [],
              example: "Stay out of the heat of the sun!",
            },
            {
              definition:
                "An attribute of a spice that causes a burning sensation in the mouth.",
              synonyms: [],
              antonyms: [],
              example: "The chili sauce gave the dish heat.",
            },
            {
              definition: "A period of intensity, particularly of emotion.",
              synonyms: ["passion", "vehemence"],
              antonyms: [],
              example:
                "It's easy to make bad decisions in the heat of the moment.",
            },
            {
              definition: "An undesirable amount of attention.",
              synonyms: [],
              antonyms: [],
              example:
                "The heat from her family after her DUI arrest was unbearable.",
            },
            {
              definition: "The police.",
              synonyms: [],
              antonyms: [],
              example: "The heat! Scram!",
            },
            { definition: "One or more firearms.", synonyms: [], antonyms: [] },
            {
              definition: "A fastball.",
              synonyms: [],
              antonyms: [],
              example: "The catcher called for the heat, high and tight.",
            },
            {
              definition:
                "A condition where a mammal is aroused sexually or where it is especially fertile and therefore eager to mate.",
              synonyms: [],
              antonyms: [],
              example: "The male canines were attracted by the female in heat.",
            },
            {
              definition:
                "A preliminary race, used to determine the participants in a final race",
              synonyms: [],
              antonyms: [],
              example:
                "The runner had high hopes, but was out of contention after the first heat.",
            },
            {
              definition:
                "One cycle of bringing metal to maximum temperature and working it until it is too cool to work further.",
              synonyms: [],
              antonyms: [],
              example: "I can make a scroll like that in a single heat.",
            },
            {
              definition: "A hot spell.",
              synonyms: [],
              antonyms: [],
              example:
                "The children stayed indoors during this year's summer heat.",
            },
            {
              definition:
                "Heating system; a system that raises the temperature of a room or building.",
              synonyms: [],
              antonyms: [],
              example: "I'm freezing; could you turn on the heat?",
            },
            {
              definition: "The output of a heating system.",
              synonyms: [],
              antonyms: [],
              example:
                "During the power outage we had no heat because the controls are electric.   Older folks like more heat than the young.",
            },
            {
              definition:
                "In omegaverse fiction, a cyclical period in which alphas and omegas experience an intense, sometimes irresistible biological urge to mate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["passion", "vehemence"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                'To cause an increase in temperature of (an object or space); to cause to become hot (often with "up").',
              synonyms: [],
              antonyms: [],
              example: "I'll heat up the water.",
            },
            {
              definition: "To become hotter.",
              synonyms: [],
              antonyms: [],
              example: "There's a pot of soup heating on the stove.",
            },
            {
              definition:
                "To excite or make hot by action or emotion; to make feverish.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To excite ardour in; to rouse to action; to excite to excess; to inflame, as the passions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To arouse, to excite (sexually).",
              synonyms: [],
              antonyms: [],
              example: "The massage heated her up.",
            },
          ],
          synonyms: ["heat up", "hot", "hot up", "stoke", "warm up"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(plural noun) A period of hot weather.",
              synonyms: [],
              antonyms: [],
              example: "Everyone suffered during the heats of July.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/heat",
        "https://en.wiktionary.org/wiki/heats",
      ],
    },
  ],
  herbs: [
    {
      word: "herbs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any green, leafy plant, or parts thereof, used to flavour or season food.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A plant whose roots, leaves or seeds, etc. are used in medicine.",
              synonyms: [],
              antonyms: [],
              example:
                "If any medicinal herbs used by witches were supposedly evil, then how come people from at least the past benefited from the healing properties of such herbs?",
            },
            { definition: "Marijuana.", synonyms: [], antonyms: [] },
            {
              definition:
                "A plant whose stem is not woody and does not persist beyond each growing season",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Grass; herbage.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["grass", "weed"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/herb",
        "https://en.wiktionary.org/wiki/herbs",
      ],
    },
  ],
  users: [
    {
      word: "users",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One who uses or makes use of something, a consumer/client or an express or implied licensee (free user) or a trespasser.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person who uses drugs, especially illegal drugs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person who uses a computer or a computing network, especially a person who has received a user account.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'An exploiter, an abuser (a person who "uses" people, that is treats and regards people unfairly, selfishly and/or unethically).',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In land law, meaning either 1. or 2. above or use. Usually in singular form to mean use wherever there is assiduous re-use of precedents and aloof textbooks verbatim. Modern law, guarded against ambiguity, widely disfavors the term.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["parasite"],
          antonyms: ["nonuser"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/user",
        "https://en.wiktionary.org/wiki/users",
      ],
    },
  ],
  flint: [
    {
      word: "flint",
      phonetic: "/flɪnt/",
      phonetics: [
        {
          text: "/flɪnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/flint-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50497463",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A hard, fine-grained quartz that fractures conchoidally and generates sparks when struck.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of flint, such as a gunflint, used to produce a spark by striking it with a firestriker.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small cylinder of some other material of the same function in a cigarette lighter, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A type of maize/corn with a hard outer hull.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Anything figuratively hard.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To furnish or decorate an object with flint.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/flint"],
    },
  ],
  click: [
    {
      word: "click",
      phonetic: "/klɪk/",
      phonetics: [
        {
          text: "/klɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/click-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1045395",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A brief, sharp, not particularly loud, relatively high-pitched sound produced by the impact of something small and hard against something hard, such as by the operation of a switch, a lock or a latch, or a finger pressed against the thumb and then released to strike the hand.",
              synonyms: [],
              antonyms: [],
              example:
                "I turned the key, the lock gave a click and the door opened;  a click of one’s fingers",
            },
            {
              definition:
                "An ingressive sound made by coarticulating a velar or uvular closure with another closure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Sound made by a dolphin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The act of operating a switch, etc., so that it clicks.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The act of pressing a button on a computer mouse, both as a physical act and a reaction in the software.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A pawl or similar catch.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cause to make a click; to operate (a switch, etc) so that it makes a click.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To press and release (a button on a computer mouse).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To select a software item using, usually, but not always, the pressing of a mouse button.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To visit a web site.",
              synonyms: [],
              antonyms: [],
              example: "Visit a location, call, or click www.example.com.",
            },
            {
              definition: "To navigate by clicking a mouse button.",
              synonyms: [],
              antonyms: [],
              example:
                "From the home page, click through to the Products section.",
            },
            {
              definition: "To emit a click.",
              synonyms: [],
              antonyms: [],
              example: "He bent his fingers back until the joints clicked.",
            },
            {
              definition: "To make sense suddenly.",
              synonyms: [],
              antonyms: [],
              example:
                "Then it clicked - I had been going the wrong way all that time.",
            },
            {
              definition: "To get on well.",
              synonyms: [],
              antonyms: [],
              example:
                "When we met at the party, we just clicked and we’ve been best friends ever since.",
            },
            { definition: "To tick.", synonyms: [], antonyms: [] },
            {
              definition: "To take (a photograph) with a camera.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "The sound of a click.",
              synonyms: [],
              antonyms: [],
              example: "Click! The door opened.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/click"],
    },
    {
      word: "click",
      phonetic: "/klɪk/",
      phonetics: [
        {
          text: "/klɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/click-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1045395",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A brief, sharp, not particularly loud, relatively high-pitched sound produced by the impact of something small and hard against something hard, such as by the operation of a switch, a lock or a latch, or a finger pressed against the thumb and then released to strike the hand.",
              synonyms: [],
              antonyms: [],
              example:
                "I turned the key, the lock gave a click and the door opened;  a click of one’s fingers",
            },
            {
              definition:
                "An ingressive sound made by coarticulating a velar or uvular closure with another closure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Sound made by a dolphin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The act of operating a switch, etc., so that it clicks.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The act of pressing a button on a computer mouse, both as a physical act and a reaction in the software.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A pawl or similar catch.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A detent, pawl, or ratchet, such as that which catches the cogs of a ratchet wheel to prevent backward motion.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The latch of a door.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A kilometer.", synonyms: [], antonyms: [] },
            {
              definition: "(usually in the plural) Kilometres per hour.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["click"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/click",
        "https://en.wiktionary.org/wiki/klick",
      ],
    },
    {
      word: "click",
      phonetic: "/klɪk/",
      phonetics: [
        {
          text: "/klɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/click-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1045395",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A detent, pawl, or ratchet, such as that which catches the cogs of a ratchet wheel to prevent backward motion.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The latch of a door.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/click"],
    },
    {
      word: "click",
      phonetic: "/klɪk/",
      phonetics: [
        {
          text: "/klɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/click-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1045395",
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To snatch.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/click"],
    },
  ],
  madam: [
    {
      word: "madam",
      phonetic: "/ˈmæd.əm/",
      phonetics: [
        {
          text: "/ˈmæd.əm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/madam-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241382",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A polite form of address for a woman or lady.",
              synonyms: [],
              antonyms: [],
              example:
                "Later, Mrs Grey was sitting in her favourite tea shop. “Would madam like the usual cream cakes and patisserie with her tea?” the waitress asked.",
            },
            {
              definition: "The mistress of a household.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A conceited or quarrelsome girl.",
              synonyms: [],
              antonyms: [],
              example:
                "Selina kept pushing and shoving during musical chairs. The nursery school teacher said she was a bad-tempered little madam.",
            },
            {
              definition:
                "A woman who runs a brothel, particularly one that specializes in finding prostitutes for rich and important clients.",
              synonyms: [],
              antonyms: [],
              example:
                "After she grew too old to work as a prostitute, she became a madam.",
            },
          ],
          synonyms: [
            "dame",
            "lady",
            "matron",
            "mistress",
            "woman",
            "abbess",
            "bawd",
            "brothel-keeper",
            "lady abbess",
            "nookie-bookie",
            "whoremistress",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: 'To address as "madam".',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/madam"],
    },
  ],
  bleak: [
    {
      word: "bleak",
      phonetic: "/bliːk/",
      phonetics: [
        {
          text: "/bliːk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bleak-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=360878",
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Without color; pale; pallid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Desolate and exposed; swept by cold winds.",
              synonyms: [],
              antonyms: [],
              example: "A bleak and bare rock.",
            },
            {
              definition:
                "Unhappy; cheerless; miserable; emotionally desolate.",
              synonyms: [],
              antonyms: [],
              example: "A bleak future is in store for you.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bleak"],
    },
    {
      word: "bleak",
      phonetic: "/bliːk/",
      phonetics: [
        {
          text: "/bliːk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bleak-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=360878",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small European river fish (Alburnus alburnus), of the family Cyprinidae.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["ablet", "alburn", "blay"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bleak"],
    },
  ],
  blunt: [
    {
      word: "blunt",
      phonetic: "/blʌnt/",
      phonetics: [
        { text: "/blʌnt/", audio: "" },
        {
          text: "/blʌnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/blunt-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2650702",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A fencer's practice foil with a soft tip.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A short needle with a strong point.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(smoking) A marijuana cigar.",
              synonyms: [],
              antonyms: [],
              example:
                "2005: to make his point, lead rapper B-Real fired up a blunt in front of the cameras and several hundred thousand people and announced, “I'm taking a hit for every one of y'all!” — Martin Torgoff, Can't Find My Way Home (Simon & Schuster 2005, p. 461)",
            },
            { definition: "Money", synonyms: [], antonyms: [] },
            {
              definition:
                "A playboating move resembling a cartwheel performed on a wave.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having a thick edge or point; not sharp.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Dull in understanding; slow of discernment; opposed to acute.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Abrupt in address; plain; unceremonious; wanting the forms of civility; rough in manners or speech.",
              synonyms: [],
              antonyms: [],
              example: "the blunt admission that he had never liked my company",
            },
            {
              definition: "Hard to impress or penetrate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Slow or deficient in feeling: insensitive.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "brusque",
            "curt",
            "harsh",
            "impolite",
            "rude",
            "short",
            "uncivil",
            "obtuse",
            "stupid",
            "coarse",
            "dull",
            "pointless",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/blunt"],
    },
    {
      word: "blunt",
      phonetic: "/blʌnt/",
      phonetics: [
        { text: "/blʌnt/", audio: "" },
        {
          text: "/blʌnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/blunt-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2650702",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To dull the edge or point of, by making it thicker; to make blunt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To repress or weaken; to impair the force, keenness, or susceptibility, of",
              synonyms: [],
              antonyms: [],
              example: "It blunted my appetite.",
            },
          ],
          synonyms: ["blunten"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/blunt"],
    },
  ],
  aided: [
    {
      word: "aided",
      phonetic: "/ˈeɪ.dɪd/",
      phonetics: [
        {
          text: "/ˈeɪ.dɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/aided-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2650628",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To provide support to; to further the progress of; to help; to assist.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To climb with the use of aids such as pitons.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "assist",
            "befriend",
            "bestand",
            "cooperate",
            "help",
            "promote",
            "relieve",
            "succor",
            "support",
            "sustain",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/aid",
        "https://en.wiktionary.org/wiki/aided",
      ],
    },
  ],
  lacks: [
    {
      word: "lacks",
      phonetic: "/læks/",
      phonetics: [
        { text: "/læks/", audio: "" },
        { text: "/læks/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A defect or failing; moral or spiritual degeneracy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A deficiency or need (of something desirable or necessary); an absence, want.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["glut", "surplus"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To be without, to need, to require.",
              synonyms: [],
              antonyms: [],
              example: "My life lacks excitement.",
            },
            {
              definition: "To be short (of or for something).",
              synonyms: [],
              antonyms: [],
              example:
                "He'll never lack for company while he's got all that money.",
            },
            { definition: "To be in want.", synonyms: [], antonyms: [] },
            {
              definition:
                "To see the deficiency in (someone or something); to find fault with, to malign, reproach.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lack",
        "https://en.wiktionary.org/wiki/lacks",
      ],
    },
  ],
  masks: [
    {
      word: "masks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A cover, or partial cover, for the face, used for disguise or protection.",
              synonyms: [],
              antonyms: [],
              example: "a dancer's mask; a fencer's mask; a ball player's mask",
            },
            {
              definition: "That which disguises; a pretext or subterfuge.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A festive entertainment of dancing or other diversions, where all wear masks; a masquerade",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person wearing a mask.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A dramatic performance, formerly in vogue, in which the actors wore masks and represented mythical or allegorical characters.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A grotesque head or face, used to adorn keystones and other prominent parts, to spout water in fountains, and the like",
              synonyms: ["mascaron"],
              antonyms: [],
            },
            {
              definition:
                "(fortification) In a permanent fortification, a redoubt which protects the caponiere.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(fortification) A screen for a battery",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The lower lip of the larva of a dragonfly, modified so as to form a prehensile organ.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Puebloan) A ceremonial object used in Puebloan kachina cults that resembles a Euro-American mask. (The term is objected to as an appropriate translation by Puebloan peoples as it emphasizes imitation but ignores power and representational intent.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A pattern of bits used in bitwise operations; bitmask.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A two-color (black and white) bitmap generated from an image, used to create transparency in the image.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The head of a fox, shown face-on and cut off immediately behind the ears.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["mascaron", "vizard"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cover (the face or something else), in order to conceal the identity or protect against injury; to cover with a mask or visor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To disguise; to cover; to hide.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To conceal; also, to intervene in the line of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cover or keep in check.",
              synonyms: [],
              antonyms: [],
              example:
                "to mask a body of troops or a fortess by a superior force, while some hostile evolution is being carried out",
            },
            {
              definition: "To take part as a masker in a masquerade",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To wear a mask; to be disguised in any way",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cover or shield a part of a design or picture in order to prevent reproduction or to safeguard the surface from the colors used when working with an air brush or painting",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To set or unset (certain bits, or binary digits, within a value) by means of a bitmask.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To disable (an interrupt, etc.) by setting or unsetting the associated bit.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A mesh.", synonyms: [], antonyms: [] },
            {
              definition: "The mesh of a net; a net; net-bag.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "Mash.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To mash.", synonyms: [], antonyms: [] },
            {
              definition: "To mix malt with hot water to yield wort.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be infused or steeped.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To prepare tea in a teapot; alternative to brew.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To bewilder; confuse.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mask",
        "https://en.wiktionary.org/wiki/masks",
      ],
    },
  ],
  waded: [
    {
      word: "waded",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To walk through water or something that impedes progress.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To progress with difficulty",
              synonyms: [],
              antonyms: [],
              example: "to wade through a dull book",
            },
            {
              definition:
                "To walk through (water or similar impediment); to pass through by wading",
              synonyms: [],
              antonyms: [],
              example: "wading swamps and rivers",
            },
            {
              definition: "To enter recklessly.",
              synonyms: [],
              antonyms: [],
              example: "to wade into a fight or a debate",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wade",
        "https://en.wiktionary.org/wiki/waded",
      ],
    },
  ],
  risks: [
    {
      word: "risks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A possible adverse event or outcome",
              synonyms: ["danger", "hazard", "peril"],
              antonyms: [],
            },
            {
              definition:
                "The probability of a negative outcome to a decision or event.",
              synonyms: [],
              antonyms: [],
              example:
                "2006, Trever Ramsey on BBC News website, Exercise 'cuts skin cancer risk' read at http//news.bbc.co.uk/1/hi/health/4764535.stm on 14 May 2006",
            },
            {
              definition:
                "The magnitude of possible loss consequent to a decision or event.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(formal use in business, engineering, etc.) The potential (conventionally negative) effect of an event, determined by combining the likelihood of the event occurring with the effect should it occur.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An entity insured by an insurer or the specific uncertain events that the insurer underwrites.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["danger", "hazard", "peril"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To incur risk of (something).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To incur risk of harming or jeopardizing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To incur risk as a result of (doing something).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/risk",
        "https://en.wiktionary.org/wiki/risks",
      ],
    },
  ],
  nurse: [
    {
      word: "nurse",
      phonetic: "/nɜːs/",
      phonetics: [
        { text: "/nɜːs/", audio: "" },
        {
          text: "/nɝs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/nurse-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749910",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A wet nurse.", synonyms: [], antonyms: [] },
            {
              definition:
                "A person (usually a woman) who takes care of other people’s young.",
              synonyms: [],
              antonyms: [],
              example: "They hired a nurse to care for their young boy.",
            },
            {
              definition: "A person trained to provide care for the sick.",
              synonyms: [],
              antonyms: [],
              example: "The nurse made her rounds through the hospital ward.",
            },
            {
              definition:
                "One who, or that which, brings up, rears, causes to grow, trains, or fosters.",
              synonyms: [],
              antonyms: [],
              example:
                'Eton College has been called "the chief nurse of England\'s statesmen".',
            },
            {
              definition: "A shrub or tree that protects a young plant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A lieutenant or first officer who takes command when the captain is unfit for his place.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A larva of certain trematodes, which produces cercariae by asexual reproduction.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A nurse shark.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To breastfeed: to feed (a baby) at the breast; to suckle.",
              synonyms: [],
              antonyms: [],
              example:
                "She believes that nursing her baby will make him strong and healthy.",
            },
            {
              definition: "To breastfeed: to be fed at the breast.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To care for (someone), especially in sickness; to tend to.",
              synonyms: [],
              antonyms: [],
              example: "She nursed him back to health.",
            },
            {
              definition: "To treat kindly and with extra care",
              synonyms: [],
              antonyms: [],
              example: "She nursed the rosebush and that season it bloomed.",
            },
            {
              definition: "To manage with care and economy",
              synonyms: ["husband"],
              antonyms: [],
            },
            { definition: "To drink slowly", synonyms: [], antonyms: [] },
            { definition: "To foster, to nourish", synonyms: [], antonyms: [] },
            {
              definition: "To hold closely to one's chest",
              synonyms: [],
              antonyms: [],
              example: "Would you like to nurse the puppy?",
            },
            {
              definition:
                "To strike (billiard balls) gently, so as to keep them in good position during a series of shots.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["sip", "husband"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/nurse"],
    },
  ],
  chaos: [
    {
      word: "chaos",
      phonetic: "/ˈkeɪ.ɒs/",
      phonetics: [
        { text: "/ˈkeɪ.ɒs/", audio: "" },
        {
          text: "/ˈkeɪ.ɑs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chaos-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=311303",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The unordered state of matter in classical accounts of cosmogony.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any state of disorder; a confused or amorphous mixture or conglomeration.",
              synonyms: [],
              antonyms: [],
              example: "After the earthquake, the local hospital was in chaos",
            },
            {
              definition:
                "A behaviour of iterative non-linear systems in which arbitrarily small variations in initial conditions become magnified over time.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the two metaphysical forces of the world in some fantasy settings, as opposed to law.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A vast chasm or abyss.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A given medium; a space in which something exists or lives; an environment.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["cosmos", "order"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chaos"],
    },
  ],
  sewed: [
    {
      word: "sewed",
      phonetic: "/ˈsəʊd/",
      phonetics: [
        {
          text: "/ˈsəʊd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sewed-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88681572",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈsoʊd/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To use a needle to pass thread repeatedly through (pieces of fabric) in order to join them together.",
              synonyms: [],
              antonyms: [],
              example:
                "Balls were first made of grass or leaves held together by strings, and later of pieces of animal skin sewn together and stuffed with feathers or hay.",
            },
            {
              definition:
                "To use a needle to pass thread repeatedly through pieces of fabric in order to join them together.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Followed by into: to enclose by sewing.",
              synonyms: [],
              antonyms: [],
              example: "to sew money into a bag",
            },
          ],
          synonyms: ["stitch"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To drain the water from.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of a ship, to be grounded.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sew",
        "https://en.wiktionary.org/wiki/sewed",
      ],
    },
  ],
  cured: [
    {
      word: "cured",
      phonetic: "/kjɔːd/",
      phonetics: [
        { text: "/kjɔːd/", audio: "" },
        {
          text: "/kjɝd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cured-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2650820",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To restore to health.",
              synonyms: ["heal"],
              antonyms: [],
              example: "Unaided nature cured him.",
            },
            {
              definition: "To bring (a disease or its bad effects) to an end.",
              synonyms: [],
              antonyms: [],
              example: "Unaided nature cured his ailments.",
            },
            {
              definition: "To cause to be rid of (a defect).",
              synonyms: [],
              antonyms: [],
              example: "Experience will cure him of his naïveté.",
            },
            {
              definition:
                "To prepare or alter especially by chemical or physical processing for keeping or use.",
              synonyms: [],
              antonyms: [],
              example: "The smoke and heat cures the meat.",
            },
            {
              definition: "To bring about a cure of any kind.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be undergoing a chemical or physical process for preservation or use.",
              synonyms: [],
              antonyms: [],
              example: "The meat was put in the smokehouse to cure.",
            },
            {
              definition: "To preserve (food), typically by salting",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To solidify or gel.",
              synonyms: [],
              antonyms: [],
              example: "The parts were curing in the autoclave.",
            },
            { definition: "To become healed.", synonyms: [], antonyms: [] },
            {
              definition: "To pay heed; to care; to give attention.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["heal"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cure",
        "https://en.wiktionary.org/wiki/cured",
      ],
    },
  ],
  ample: [
    {
      word: "ample",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ample-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75729425",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈæm.pəl/", audio: "" },
        {
          text: "/ˈæm.pəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ample-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2423251",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Large; great in size, extent, capacity, or bulk; for example spacious, roomy or widely extended.",
              synonyms: [],
              antonyms: [],
              example: "an ample house",
            },
            {
              definition: "Fully sufficient; abundant; plenty",
              synonyms: [],
              antonyms: [],
              example: "ample material",
            },
            {
              definition:
                "Not contracted or brief; not concise; extended; diffusive",
              synonyms: [],
              antonyms: [],
              example: "an ample story",
            },
          ],
          synonyms: [
            "abundant",
            "bountiful",
            "capacious",
            "copious",
            "extensive",
            "full",
            "liberal",
            "munificent",
            "plenteous",
            "plentiful",
            "rich",
            "spacious",
            "wide",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ample"],
    },
  ],
  lease: [
    {
      word: "lease",
      phonetic: "/liːs/",
      phonetics: [
        { text: "/liːs/", audio: "" },
        {
          text: "/liːs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lease-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651126",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(chiefly dialectal) To gather.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(chiefly dialectal) To pick, select, pick out; to pick up.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(chiefly dialectal) To glean.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(chiefly dialectal) To glean, gather up leavings.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lease"],
    },
    {
      word: "lease",
      phonetic: "/liːs/",
      phonetics: [
        { text: "/liːs/", audio: "" },
        {
          text: "/liːs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lease-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651126",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To tell lies; tell lies about; slander; calumniate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lease"],
    },
    {
      word: "lease",
      phonetic: "/liːs/",
      phonetics: [
        { text: "/liːs/", audio: "" },
        {
          text: "/liːs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lease-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651126",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An open pasture or common.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lease"],
    },
    {
      word: "lease",
      phonetic: "/liːs/",
      phonetics: [
        { text: "/liːs/", audio: "" },
        {
          text: "/liːs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lease-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651126",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To release; let go; unloose.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lease"],
    },
    {
      word: "lease",
      phonetic: "/liːs/",
      phonetics: [
        { text: "/liːs/", audio: "" },
        {
          text: "/liːs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lease-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651126",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A contract granting use or occupation of property during a specified period in exchange for a specified rent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The period of such a contract.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A leasehold.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To operate or live in some property or land through purchasing a long-term contract (or leasehold) from the owner (or freeholder).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To take or hold by lease.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To grant a lease; to let or rent.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lease"],
    },
    {
      word: "lease",
      phonetic: "/liːs/",
      phonetics: [
        { text: "/liːs/", audio: "" },
        {
          text: "/liːs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lease-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651126",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The place at which the warp-threads cross on a loom.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lease"],
    },
  ],
  steak: [
    {
      word: "steak",
      phonetic: "/steɪk/",
      phonetics: [
        {
          text: "/steɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/steak-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=360872",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Beefsteak, a slice of beef, broiled or cut for broiling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) A relatively large, thick slice or slab cut from another animal, a vegetable, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(seafood) A slice of meat cut across the grain (perpendicular to the spine) from a fish.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cook (something, especially fish) like or as a steak.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/steak"],
    },
  ],
  sinks: [
    {
      word: "sinks",
      phonetic: "/sɪŋks/",
      phonetics: [
        { text: "/sɪŋks/", audio: "" },
        { text: "/sɪŋks/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A basin used for holding water for washing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A drain for carrying off wastewater.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A sinkhole.", synonyms: [], antonyms: [] },
            {
              definition:
                "A depression in land where water collects, with no visible outlet.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A heat sink.", synonyms: [], antonyms: [] },
            {
              definition: "A place that absorbs resources or energy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The motion of a sinker pitch.",
              synonyms: [],
              antonyms: [],
              example: "Jones has a two-seamer with heavy sink.",
            },
            {
              definition:
                "An object or callback that captures events; event sink",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A destination vertex in a transportation network",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An abode of degraded persons; a wretched place.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A depression in a stereotype plate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A stage trap-door for shifting scenery.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An excavation less than a shaft.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(game development) One or several systems that remove currency from the game's economy, thus controlling or preventing inflation",
              synonyms: [],
              antonyms: ["faucet"],
            },
          ],
          synonyms: ["basin", "washbasin"],
          antonyms: ["source", "faucet"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(heading, physical) To move or be moved into something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(heading, social) To diminish or be diminished.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To conceal and appropriate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To keep out of sight; to suppress; to ignore.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To pay absolutely.",
              synonyms: [],
              antonyms: [],
              example: "I have sunk thousands of pounds into this project.",
            },
            {
              definition: "To reduce or extinguish by payment.",
              synonyms: [],
              antonyms: [],
              example: "to sink the national debt",
            },
            {
              definition:
                "To be overwhelmed or depressed; to fail in strength.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To decrease in volume, as a river; to subside; to become diminished in volume or in apparent height.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["descend", "go down", "dip", "dunk", "submerge"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sink",
        "https://en.wiktionary.org/wiki/sinks",
      ],
    },
  ],
  merit: [
    {
      word: "merit",
      phonetic: "/ˈmɛɹət/",
      phonetics: [
        { text: "/ˈmɛɹət/", audio: "" },
        {
          text: "/ˈmɛɹət/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/merit-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749895",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A claim to commendation or a reward.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A mark or token of approbation or to recognize excellence.",
              synonyms: [],
              antonyms: ["demerit"],
              example:
                "For her good performance in the examination, her teacher gave her ten merits.",
            },
            {
              definition:
                "Something deserving or worthy of positive recognition or reward.",
              synonyms: ["excellence", "value", "worth"],
              antonyms: ["demerit"],
              example: "His reward for his merit was a check for $50.",
            },
            {
              definition:
                "The sum of all the good deeds that a person does which determines the quality of the person's next state of existence and contributes to the person's growth towards enlightenment.",
              synonyms: [],
              antonyms: [],
              example: "to acquire or make merit",
            },
            {
              definition:
                "Usually in the plural form the merits: the substantive rightness or wrongness of a legal argument, a lawsuit, etc., as opposed to technical matters such as the admissibility of evidence or points of legal procedure; (by extension) the overall good or bad quality, or rightness or wrongness, of some other thing.",
              synonyms: [],
              antonyms: [],
              example:
                "Even though the plaintiff was ordered by the judge to pay some costs for not having followed the correct procedure, she won the case on the merits.",
            },
            {
              definition:
                "The quality or state of deserving retribution, whether reward or punishment.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["excellence", "value", "worth"],
          antonyms: ["demerit", "demerit"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To deserve, to earn.",
              synonyms: [],
              antonyms: [],
              example: "Her performance merited wild applause.",
            },
            {
              definition: "To be deserving or worthy.",
              synonyms: [],
              antonyms: [],
              example: "They were punished as they merited.",
            },
            { definition: "To reward.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/merit"],
    },
  ],
  bluff: [
    {
      word: "bluff",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bluff-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241099",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/blʌf/", audio: "" },
        { text: "/blʌf/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An act of bluffing; a false expression of the strength of one's position in order to intimidate; braggadocio.",
              synonyms: [],
              antonyms: [],
              example: "That is only bluff, or a bluff.",
            },
            {
              definition:
                "An attempt to represent oneself as holding a stronger hand than one actually does.",
              synonyms: [],
              antonyms: [],
              example:
                "John's bet was a bluff: he bet without even so much as a pair.",
            },
            { definition: "The card game poker.", synonyms: [], antonyms: [] },
            {
              definition: "One who bluffs; a bluffer.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An excuse.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make a bluff; to give the impression that one's hand is stronger than it is.",
              synonyms: [],
              antonyms: [],
              example: "John bluffed by betting without even a pair.",
            },
            {
              definition:
                "(by analogy) To frighten or deter with a false show of strength or confidence; to give a false impression of strength or temerity in order to intimidate and gain some advantage.",
              synonyms: [],
              antonyms: [],
              example:
                "The government claims it will call an election if this bill does not pass. Is it truly ready to do so, or is it bluffing?",
            },
            {
              definition: "To take advantage by bluffing.",
              synonyms: [],
              antonyms: [],
              example: "We bluffed our way past the guards.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bluff"],
    },
    {
      word: "bluff",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bluff-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241099",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/blʌf/", audio: "" },
        { text: "/blʌf/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A high, steep bank, for example by a river or the sea, or beside a ravine or plain; a cliff with a broad face.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small wood or stand of trees, typically poplar or willow.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having a broad, flattened front.",
              synonyms: [],
              antonyms: [],
              example: "the bluff bows of a ship",
            },
            {
              definition: "Rising steeply with a flat or rounded front.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Surly; churlish; gruff; rough.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Roughly frank and hearty in one's manners.",
              synonyms: ["abrupt", "blunt", "brusque", "unceremonious"],
              antonyms: [],
              example: "a bluff answer",
            },
          ],
          synonyms: ["abrupt", "blunt", "brusque", "unceremonious"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bluff"],
    },
    {
      word: "bluff",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bluff-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241099",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/blʌf/", audio: "" },
        { text: "/blʌf/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To fluff, puff or swell up.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bluff"],
    },
  ],
  bathe: [
    {
      word: "bathe",
      phonetic: "/beɪð/",
      phonetics: [
        {
          text: "/beɪð/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bathe-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769610",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The act of swimming or bathing, especially in the sea, a lake, or a river; a swimming bath.",
              synonyms: [],
              antonyms: [],
              example: "I'm going to have a midnight bathe tonight.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To clean oneself by immersion in water or using water; to take a bath, have a bath.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To immerse oneself, or part of the body, in water for pleasure or refreshment; to swim.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To clean a person by immersion in water or using water; to give someone a bath.",
              synonyms: [],
              antonyms: [],
              example:
                "We bathe our baby before going to bed; other parents do it in the morning if they have time.",
            },
            {
              definition:
                "To apply water or other liquid to; to suffuse or cover with liquid.",
              synonyms: [],
              antonyms: [],
              example:
                "She bathed her eyes with liquid to remove the stinging chemical.",
            },
            {
              definition: "(transitive and intransitive) To cover or surround.",
              synonyms: [],
              antonyms: [],
              example: "A dense fog bathed the city streets.",
            },
            {
              definition: "To sunbathe.",
              synonyms: [],
              antonyms: [],
              example: "The women bathed in the sun.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bathe"],
    },
  ],
  gleam: [
    {
      word: "gleam",
      phonetic: "/ɡliːm/",
      phonetics: [
        {
          text: "/ɡliːm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gleam-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2707520",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A small or indistinct shaft or stream of light.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A glimpse or hint; an indistinct sign of something.",
              synonyms: [],
              antonyms: [],
              example:
                "The rescue workers preserved a gleam of optimism that they might still survive.",
            },
            {
              definition: "Brightness or shininess; splendor.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "dazzle",
            "lambency",
            "shine",
            "flicker",
            "glimmer",
            "trace",
            "beam",
            "ray",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To shine; to glitter; to glisten.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be briefly but strongly apparent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To disgorge filth, as a hawk.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "flare",
            "flash",
            "kindle",
            "glint",
            "sparkle",
            "glow",
            "shine",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gleam"],
    },
  ],
  bonus: [
    {
      word: "bonus",
      phonetic: "/ˈbəʊ.nəs/",
      phonetics: [
        { text: "/ˈbəʊ.nəs/", audio: "" },
        { text: "/ˈboʊ.nəs/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Something extra that is good; an added benefit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An extra sum given as a premium, e.g. to an employee or to a shareholder.",
              synonyms: [],
              antonyms: [],
              example:
                "The employee of the week receives a bonus for his excellent work.",
            },
            {
              definition:
                "An addition to the player's score based on performance, e.g. for time remaining.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One or more free throws awarded to a team when the opposing team has accumulated enough fouls.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To pay a bonus, premium",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bonus"],
    },
  ],
  colts: [
    {
      word: "colts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A young male horse.", synonyms: [], antonyms: [] },
            { definition: "A young crane (bird).", synonyms: [], antonyms: [] },
            {
              definition: "A youthful or inexperienced person; a novice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A short piece of rope once used by petty officers as an instrument of punishment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A young camel or donkey.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To horse; to get with young.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To befool.", synonyms: [], antonyms: [] },
            {
              definition:
                "To frisk or frolic like a colt; to act licentiously or wantonly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/colt",
        "https://en.wiktionary.org/wiki/colts",
      ],
    },
  ],
  shear: [
    {
      word: "shear",
      phonetic: "/ʃɪə(ɹ)/",
      phonetics: [
        { text: "/ʃɪə(ɹ)/", audio: "" },
        {
          text: "/ʃiɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shear-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=667206",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A cutting tool similar to scissors, but often larger.",
              synonyms: ["shears"],
              antonyms: [],
            },
            {
              definition:
                "The act of shearing, or something removed by shearing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Forces that push in opposite directions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A transformation that displaces every point in a direction parallel to some given line by a distance proportional to the point’s distance from the line.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The response of a rock to deformation usually by compressive stress, resulting in particular textures.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["shears"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cut, originally with a sword or other bladed weapon, now usually with shears, or as if using shears.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To remove the fleece from a sheep etc by clipping.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To deform because of forces pushing in opposite directions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To transform by displacing every point in a direction parallel to some given line by a distance proportional to the point’s distance from the line.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make a vertical cut in the coal.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To reap, as grain.", synonyms: [], antonyms: [] },
            {
              definition: "To deprive of property; to fleece.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shear"],
    },
  ],
  gland: [
    {
      word: "gland",
      phonetic: "/ɡlænd/",
      phonetics: [
        {
          text: "/ɡlænd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gland-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=91208362",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ɡlænd/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An organ that synthesizes a substance, such as hormones or breast milk, and releases it, often into the bloodstream (endocrine gland) or into cavities inside the body or its outer surface (exocrine gland).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A secretory structure on the surface of an organ.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gland"],
    },
    {
      word: "gland",
      phonetic: "/ɡlænd/",
      phonetics: [
        {
          text: "/ɡlænd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gland-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=91208362",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ɡlænd/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A compressable cylindrical case and its contents around a shaft where it passes through a barrier, intended to prevent the passage of a fluid past the barrier, such as:",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gland"],
    },
  ],
  silky: [
    {
      word: "silky",
      phonetic: "/ˈsɪlki/",
      phonetics: [{ text: "/ˈsɪlki/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A seal which can magically transform into a human by shedding its skin.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A chicken of a certain breed with very fine, silk-like feathers.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Similar in appearance or texture (especially in softness and smoothness) to silk.",
              synonyms: [],
              antonyms: [],
              example: "cloth with a silky lustre",
            },
            {
              definition: "Smooth and pleasant; seductive.",
              synonyms: [],
              antonyms: [],
              example: "a silky voice",
            },
            {
              definition:
                "Covered in long, slender, glistening hairs pressed close to the surface; sericeous.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/selkie",
        "https://en.wiktionary.org/wiki/silkie",
        "https://en.wiktionary.org/wiki/silky",
      ],
    },
  ],
  skate: [
    {
      word: "skate",
      phonetic: "/skeɪt/",
      phonetics: [
        {
          text: "/skeɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/skate-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780378",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A light boot, fitted with a blade, used for ice skating.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A boot having small wheels or casters attached to its sole; used for roller skating",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["roller boot"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A runner or blade, usually of steel, with a frame shaped to fit the sole of a shoe, made to be fastened under the foot, and used for gliding on ice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The act of skateboarding",
              synonyms: [],
              antonyms: [],
              example: "There's time for a quick skate before dinner.",
            },
            {
              definition: "The act of roller skating or ice skating",
              synonyms: [],
              antonyms: [],
              example:
                "The boys had a skate every morning when the lake was frozen.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To move along a surface (ice or ground) using skates.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To skateboard", synonyms: [], antonyms: [] },
            {
              definition: "To use the skating technique.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To get away with something; to be acquitted of a crime for which one is manifestly guilty.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Pertaining to the technique of skating.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ice%20skate",
        "https://en.wiktionary.org/wiki/roller%20skate",
        "https://en.wiktionary.org/wiki/skate",
      ],
    },
    {
      word: "skate",
      phonetic: "/skeɪt/",
      phonetics: [
        {
          text: "/skeɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/skate-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780378",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A fish of the family Rajidae in the superorder Batoidea (rays) which inhabit most seas. Skates generally have small heads with protruding muzzles, and wide fins attached to a flat body.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/skate"],
    },
    {
      word: "skate",
      phonetic: "/skeɪt/",
      phonetics: [
        {
          text: "/skeɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/skate-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780378",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A worn-out horse.", synonyms: [], antonyms: [] },
            {
              definition: "A mean or contemptible person.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/skate"],
    },
  ],
  birch: [
    {
      word: "birch",
      phonetic: "/bɜːtʃ/",
      phonetics: [
        { text: "/bɜːtʃ/", audio: "" },
        {
          text: "/bɝtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/birch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769615",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of various trees of the genus Betula, native to countries in the Northern Hemisphere.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hard wood taken from the birch tree, typically used to make furniture.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A stick, rod or bundle of twigs made from birch wood, used for punishment.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A birch-bark canoe.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["makepeace", "switch"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To punish with a stick, bundle of twigs, or rod made of birch wood.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To punish as though one were using a stick, bundle of twigs, or rod made of birch wood.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/birch"],
    },
  ],
  sleds: [
    {
      word: "sleds",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                'A small, light vehicle with runners, used recreationally, mostly by children, for sliding down snow-covered hills. (A "sled" in this sense is not pulled by an animal as a "sleigh" is.)',
              synonyms: [],
              antonyms: [],
              example: "The child zoomed down the hill on his sled.",
            },
            {
              definition:
                'A vehicle on runners, used for conveying loads over the snow or ice. (contrast "sleigh", which is larger)',
              synonyms: [],
              antonyms: [],
              example: '"Mush!" he yelled at the dogs pulling the sled.',
            },
            { definition: "A snowmobile.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To ride a sled.", synonyms: [], antonyms: [] },
            { definition: "To convey on a sled.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sled",
        "https://en.wiktionary.org/wiki/sleds",
      ],
    },
  ],
  groan: [
    {
      word: "groan",
      phonetic: "/ɡɹəʊn/",
      phonetics: [
        { text: "/ɡɹəʊn/", audio: "" },
        {
          text: "/ɡɹoʊn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/groan-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2650999",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A low, mournful sound uttered in pain or grief.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A low, guttural sound uttered in frustration, disapproval, or ecstasy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of an object) A low creaking sound from applied pressure or weight.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make a groan.",
              synonyms: [],
              antonyms: [],
              example:
                "The wooden table groaned under the weight of the banquet.",
            },
            {
              definition: "To strive after earnestly, as if with groans.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/groan"],
    },
  ],
  maids: [
    {
      word: "maids",
      phonetic: "/meɪdz/",
      phonetics: [
        {
          text: "/meɪdz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/maids-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651153",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A girl or an unmarried young woman; maiden.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A female servant or cleaner (short for maidservant).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A virgin, now female but originally one of either gender.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/maid",
        "https://en.wiktionary.org/wiki/maids",
      ],
    },
  ],
  meets: [
    {
      word: "meets",
      phonetic: "/miːts/",
      phonetics: [
        {
          text: "/miːts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/meets-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651170",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sports competition, especially for track and field (a track meet) or swimming (a swim meet).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A gathering of riders, horses and hounds for foxhunting; a field meet for hunting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A meeting of two trains in opposite directions on a single track, when one is put into a siding to let the other cross.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A meeting.",
              synonyms: [],
              antonyms: [],
              example: "OK, let's arrange a meet with Tyler and ask him.",
            },
            {
              definition:
                "The greatest lower bound, an operation between pairs of elements in a lattice, denoted by the symbol ∧.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An act of French kissing someone.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["join", "pass"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make contact (with) while in proximity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(Of groups) To come together.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make physical or perceptual contact.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To satisfy; to comply with.",
              synonyms: [],
              antonyms: [],
              example:
                "This proposal meets my requirements. The company agrees to meet the cost of any repairs.",
            },
            {
              definition: "To balance or come out correct.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To perceive; to come to a knowledge of; to have personal acquaintance with; to experience; to suffer.",
              synonyms: [],
              antonyms: [],
              example: "The eye met a horrid sight. He met his fate.",
            },
            {
              definition: "To be mixed with, to be combined with aspects of.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "preposition",
          definitions: [
            {
              definition: "Forming a combination or nexus of.",
              synonyms: [],
              antonyms: [],
              example:
                "The concept of the movie was Fantastic Voyage meets The Devil Wears Prada.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/meet",
        "https://en.wiktionary.org/wiki/meets",
      ],
    },
  ],
  speck: [
    {
      word: "speck",
      phonetic: "/spɛk/",
      phonetics: [
        {
          text: "/spɛk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/speck-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769773",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A tiny spot, especially of dirt etc.",
              synonyms: [],
              antonyms: [],
              example: "a tiny speck of soot",
            },
            {
              definition: "A very small thing; a particle; a whit.",
              synonyms: [],
              antonyms: [],
              example: "He has not a speck of money.",
            },
            {
              definition:
                "A small etheostomoid fish, Etheostoma stigmaeum, common in the eastern United States.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To mark with specks; to speckle.",
              synonyms: [],
              antonyms: [],
              example:
                "paper specked by impurities in the water used in its manufacture",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/speck"],
    },
    {
      word: "speck",
      phonetic: "/spɛk/",
      phonetics: [
        {
          text: "/spɛk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/speck-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769773",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Fat; lard; fat meat.", synonyms: [], antonyms: [] },
            {
              definition: "A juniper-flavoured ham originally from Tyrol.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The blubber of whales or other marine mammals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The fat of the hippopotamus.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/speck"],
    },
  ],
  hymns: [
    {
      word: "hymns",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hymns-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2454093",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A song of praise or worship.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To sing a hymn.", synonyms: [], antonyms: [] },
            {
              definition: "To praise or extol in hymns.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hymn",
        "https://en.wiktionary.org/wiki/hymns",
      ],
    },
  ],
  hints: [
    {
      word: "hints",
      phonetic: "/hɪnts/",
      phonetics: [{ text: "/hɪnts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A clue.",
              synonyms: [],
              antonyms: [],
              example: "I needed a hint to complete the crossword.",
            },
            {
              definition: "A tacit suggestion that avoids a direct statement.",
              synonyms: [],
              antonyms: [],
              example: "He gave me a hint that my breath smelt.",
            },
            {
              definition: "A small, barely detectable amount of.",
              synonyms: [],
              antonyms: [],
              example: "I could taste a hint of lemon in the wine.",
            },
            {
              definition:
                "Information in a computer-based font that suggests how the outlines of the font's glyphs should be distorted in order to produce, at specific sizes, a visually appealing pixel-based rendering. Also known as hinting.",
              synonyms: [],
              antonyms: [],
              example:
                "This font does not scale well; at small point sizes it has no hinting at all, and the hints that it has for the 10- and 12-point letter 'g' still need work.",
            },
            {
              definition: "An opportunity; occasion; fit time.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To suggest tacitly without a direct statement; to provide a clue.",
              synonyms: [],
              antonyms: [],
              example:
                "She hinted at the possibility of a recount of the votes.",
            },
            {
              definition:
                "To bring to mind by a slight mention or remote allusion; to suggest in an indirect manner.",
              synonyms: [],
              antonyms: [],
              example: "to hint a suspicion",
            },
            {
              definition: "To develop and add hints to a font.",
              synonyms: [],
              antonyms: [],
              example:
                "The typographer worked all day on hinting her new font so it would look good on computer screens.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hint",
        "https://en.wiktionary.org/wiki/hints",
      ],
    },
  ],
  drown: [
    {
      word: "drown",
      phonetic: "/dɹaʊn/",
      phonetics: [
        {
          text: "/dɹaʊn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/drown-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780049",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To die from suffocation while immersed in water or other fluid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To kill by suffocating in water or another liquid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be flooded: to be inundated with or submerged in (literally) water or (figuratively) other things; to be overwhelmed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To inundate, submerge, overwhelm.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(usually passive) To obscure, particularly amid an overwhelming volume of other items.",
              synonyms: [],
              antonyms: [],
              example:
                "The answers intelligence services seek are often drowned in the flood of information they can now gather.",
            },
          ],
          synonyms: ["flood", "inundate"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/drown"],
    },
  ],
  bosom: [
    {
      word: "bosom",
      phonetic: "/ˈbʊz(ə)m/",
      phonetics: [
        { text: "/ˈbʊz(ə)m/", audio: "" },
        { text: "/ˈbʊz(ə)m/", audio: "" },
        {
          text: "/ˈbʊz(ə)m/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bosom-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1684565",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(somewhat obsolete) The breast or chest of a human (or sometimes of another animal).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The seat of one's inner thoughts, feelings etc.; one's secret feelings; desire.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The protected interior or inner part of something; the area enclosed as by an embrace.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The part of a dress etc. covering the chest; a neckline.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A woman's breast(s).", synonyms: [], antonyms: [] },
            {
              definition:
                "Any thing or place resembling the breast; a supporting surface; an inner recess; the interior.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A depression round the eye of a millstone.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To enclose or carry in the bosom; to keep with care; to take to heart; to cherish.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To conceal; to hide from view; to embosom.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To belly; to billow, swell or bulge.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To belly; to cause to billow, swell or bulge.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "In a very close relationship.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bosom"],
    },
  ],
  slick: [
    {
      word: "slick",
      phonetic: "/slɪk/",
      phonetics: [
        {
          text: "/slɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/slick-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79268954",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A covering of liquid, particularly oil.",
              synonyms: [],
              antonyms: [],
              example:
                "Careful in turn three — there's an oil slick on the road.",
            },
            {
              definition: "Someone who is clever and untrustworthy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A tool used to make something smooth or even.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A tire with a smooth surface instead of a tread pattern, often used in auto racing.",
              synonyms: ["slick tire", "slick tyre"],
              antonyms: [],
              example: "You'll go much faster if you put on slicks.",
            },
            { definition: "A helicopter.", synonyms: [], antonyms: [] },
            {
              definition:
                'A camera-ready image to be used by a printer. The "slick" is photographed to produce a negative image which is then used to burn a positive offset plate or other printing device.',
              synonyms: [],
              antonyms: [],
              example:
                "The project was delayed because the slick had not been delivered to the printer.",
            },
            {
              definition: "A wide paring chisel used in joinery.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["slick tire", "slick tyre"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make slick.",
              synonyms: [],
              antonyms: [],
              example: "The surface had been slicked.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Slippery or smooth due to a covering of liquid; often used to describe appearances.",
              synonyms: [],
              antonyms: [],
              example: "His large round head was shaved slick.",
            },
            {
              definition: "Appearing expensive or sophisticated.",
              synonyms: [],
              antonyms: [],
              example: "They read all kinds of slick magazines.",
            },
            {
              definition:
                "Superficially convincing but actually untrustworthy.",
              synonyms: [],
              antonyms: [],
              example:
                "That new sales rep is slick. Be sure to read the fine print before you buy anything.",
            },
            {
              definition:
                "(often used sarcastically) Clever, making an apparently hard task easy.",
              synonyms: [],
              antonyms: [],
              example:
                "Our new process for extracting needles from haystacks is extremely slick.",
            },
            {
              definition: "Extraordinarily great or special.",
              synonyms: [],
              antonyms: [],
              example:
                "That is one slick bicycle: it has all sorts of features!",
            },
            { definition: "Sleek; smooth", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/slick"],
    },
    {
      word: "slick",
      phonetic: "/slɪk/",
      phonetics: [
        {
          text: "/slɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/slick-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79268954",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The finer portion of crushed ore, as of gold, lead, or tin, separated by the water in certain wet processes.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/schlich",
        "https://en.wiktionary.org/wiki/slick",
      ],
    },
  ],
  quest: [
    {
      word: "quest",
      phonetic: "/kwɛst/",
      phonetics: [
        {
          text: "/kwɛst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/quest-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=308930",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A journey or effort in pursuit of a goal (often lengthy, ambitious, or fervent); a mission.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The act of seeking, or looking after anything; attempt to find or obtain; search; pursuit.",
              synonyms: [],
              antonyms: [],
              example:
                "to rove in quest of game, of a lost child, of property, etc.",
            },
            {
              definition: "Request; desire; solicitation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A group of people making search or inquiry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Inquest; jury of inquest.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To seek or pursue a goal; to undertake a mission or job.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To search for; to examine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of a tick) To locate and attach to a host animal.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/quest"],
    },
  ],
  coils: [
    {
      word: "coils",
      phonetic: "/kɔɪlz/",
      phonetics: [{ text: "/kɔɪlz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Something wound in the form of a helix or spiral.",
              synonyms: [],
              antonyms: [],
              example: "the sinuous coils of a snake",
            },
            {
              definition:
                "Any intrauterine device (Abbreviation: IUD)—the first IUDs were coil-shaped.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A coil of electrically conductive wire through which electricity can flow.",
              synonyms: ["inductor"],
              antonyms: [],
            },
            {
              definition: "Entanglement; perplexity.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["inductor"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To wind or reel e.g. a wire or rope into regular rings, often around a centerpiece.",
              synonyms: [],
              antonyms: [],
              example:
                "A simple transformer can be made by coiling two pieces of insulated copper wire around an iron heart.",
            },
            {
              definition:
                "To wind into loops (roughly) around a common center.",
              synonyms: [],
              antonyms: [],
              example:
                "The sailor coiled the free end of the hawser on the pier.",
            },
            {
              definition: "To wind cylindrically or spirally.",
              synonyms: [],
              antonyms: [],
              example: "The snake coiled itself before springing.",
            },
            {
              definition: "To encircle and hold with, or as if with, coils.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A noise, tumult, bustle, or turmoil.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/coil",
        "https://en.wiktionary.org/wiki/coils",
      ],
    },
  ],
  spied: [
    {
      word: "spied",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To act as a spy.",
              synonyms: [],
              antonyms: [],
              example:
                "During the Cold War, Russia and America would each spy on each other for recon.",
            },
            {
              definition: "To spot; to catch sight of.",
              synonyms: [],
              antonyms: [],
              example: "I think I can spy that hot guy coming over here.",
            },
            {
              definition: "To search narrowly; to scrutinize.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To explore; to see; to view; inspect and examine secretly, as a country.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/spied",
        "https://en.wiktionary.org/wiki/spy",
      ],
    },
  ],
  snows: [
    {
      word: "snows",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/snows-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651521",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The frozen, crystalline state of water that falls as precipitation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any similar frozen form of a gas or liquid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A snowfall; a blanket of frozen, crystalline water.",
              synonyms: [],
              antonyms: [],
              example: "We have had several heavy snows this year.",
            },
            {
              definition: "A shade of the color white.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The moving pattern of random dots displayed on a television, etc., when no transmission signal is being received.",
              synonyms: ["shash"],
              antonyms: [],
            },
            { definition: "Cocaine.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["blow", "shash"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To have snow fall from the sky.",
              synonyms: [],
              antonyms: [],
              example: "It is snowing.",
            },
            {
              definition:
                "To hoodwink someone, especially by presenting confusing information.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To bluff in draw poker by refusing to draw any cards.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A square-rigged vessel, differing from a brig only in that she has a trysail mast close abaft the mainmast, on which a large trysail is hoisted.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/snow",
        "https://en.wiktionary.org/wiki/snows",
      ],
    },
  ],
  stead: [
    {
      word: "stead",
      phonetic: "/stɛd/",
      phonetics: [
        {
          text: "/stɛd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stead-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2454308",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A place, or spot, in general.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A place where a person normally rests; a seat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An inhabited place; a settlement, city, town etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An estate, a property with its grounds; a farm.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The frame on which a bed is laid; a bedstead.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in phrases) The position or function (of someone or something), as taken on by a successor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'An emotional or circumstantial "place" having specified advantages, qualities etc. (now only in phrases).',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To help, support, benefit or assist; to be helpful or noteful.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fill stead or place of.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stead"],
    },
    {
      word: "stead",
      phonetic: "/stɛd/",
      phonetics: [
        {
          text: "/stɛd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stead-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2454308",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One's partner in a romantic relationship.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stead"],
    },
  ],
  snack: [
    {
      word: "snack",
      phonetic: "/snæk/",
      phonetics: [
        {
          text: "/snæk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/snack-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89012465",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A light meal.", synonyms: [], antonyms: [] },
            {
              definition: "An item of food eaten between meals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A very sexy and attractive person.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To eat a light meal.", synonyms: [], antonyms: [] },
            { definition: "To eat between meals.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/snack"],
    },
    {
      word: "snack",
      phonetic: "/snæk/",
      phonetics: [
        {
          text: "/snæk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/snack-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89012465",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A share; a part or portion.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To snatch.", synonyms: [], antonyms: [] },
            { definition: "To bite.", synonyms: [], antonyms: [] },
            { definition: "To share.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/snack"],
    },
  ],
  plows: [
    {
      word: "plows",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The notional area of land able to be farmed in a year by a team of 8 oxen pulling a carruca plow, usually reckoned at 120 acres.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "carrucate",
            "carve",
            "hide",
            "plough",
            "ploughgate",
            "ploughland",
            "plow",
            "plowgate",
            "plowland",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A device pulled through the ground in order to break it open into furrows for planting.",
              synonyms: [],
              antonyms: [],
              example:
                "The horse-drawn plough had a tremendous impact on agriculture.",
            },
            {
              definition: "The use of a plough; tillage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Alternative form of Plough (Synonym of Ursa Major)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A joiner's plane for making grooves.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bookbinder's implement for trimming or shaving off the edges of books.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["sull", "zowl", "carucate"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To use a plough on to prepare for planting.",
              synonyms: [],
              antonyms: [],
              example: "I've still got to plough that field.",
            },
            {
              definition: "To use a plough.",
              synonyms: [],
              antonyms: [],
              example: "Some days I have to plough from sunrise to sunset.",
            },
            {
              definition: "To have sex with, penetrate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To move with force.",
              synonyms: [],
              antonyms: [],
              example:
                "Trucks plowed through the water to ferry flood victims to safety.",
            },
            {
              definition: "To furrow; to make furrows, grooves, or ridges in.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To run through, as in sailing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To trim, or shave off the edges of, as a book or paper, with a plough.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(joinery) To cut a groove in, as in a plank, or the edge of a board; especially, a rectangular groove to receive the end of a shelf or tread, the edge of a panel, a tongue, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(university slang) To fail (a student).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "flunk",
            "get up in",
            "pound",
            "sleep with",
            "chamfer",
            "groove",
            "rut",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Land that has been or is meant to be ploughed",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["carucate"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/carucate",
        "https://en.wiktionary.org/wiki/plough",
        "https://en.wiktionary.org/wiki/ploughland",
        "https://en.wiktionary.org/wiki/plow",
        "https://en.wiktionary.org/wiki/plows",
      ],
    },
  ],
  blond: [
    {
      word: "blond",
      phonetic: "/blɒnd/",
      phonetics: [
        {
          text: "/blɒnd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/blond-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88902750",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/blɑnd/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A pale yellowish (golden brown) color, especially said of hair color.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person with this hair color.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To color or dye blond", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Of a bleached or pale golden (light yellowish) colour.",
              synonyms: [],
              antonyms: [],
              example: "blond ale",
            },
            {
              definition: "(of a person) Having blond hair.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "(especially of a woman) Stupid, ignorant, naive.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/blond",
        "https://en.wiktionary.org/wiki/blonde",
      ],
    },
  ],
  tamed: [
    {
      word: "tamed",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make (an animal) tame; to domesticate.",
              synonyms: [],
              antonyms: [],
              example: "He tamed the wild horse.",
            },
            {
              definition: "To become tame or domesticated.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make gentle or meek.",
              synonyms: [],
              antonyms: [],
              example: "to tame a rebellion",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To broach or enter upon; to taste, as a liquor; to divide; to distribute; to deal out.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Domesticated; made tame",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["untamed"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tame",
        "https://en.wiktionary.org/wiki/tamed",
      ],
    },
  ],
  thorn: [
    {
      word: "thorn",
      phonetic: "/θɔːn/",
      phonetics: [
        {
          text: "/θɔːn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/thorn-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=877624",
        },
        {
          text: "/θɔɹn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/thorn-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=830189",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A sharp protective spine of a plant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any shrub or small tree that bears thorns, especially a hawthorn.",
              synonyms: [],
              antonyms: [],
              example: "the cockspur thorn",
            },
            {
              definition: "That which pricks or annoys; anything troublesome.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A letter of Latin script (capital: Þ, small: þ), borrowed from the futhark; today used only in Icelandic to represent the voiceless dental fricative, but originally used in several early Germanic scripts, including Old English where it represented the dental fricatives that are today written th (Old English did not have phonemic voicing distinctions for fricatives).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To pierce with, or as if with, a thorn",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/thorn"],
    },
  ],
  waits: [
    {
      word: "waits",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A delay.",
              synonyms: [],
              antonyms: [],
              example: "I had a very long wait at the airport security check.",
            },
            {
              definition: "An ambush.",
              synonyms: [],
              antonyms: [],
              example: "They lay in wait for the patrol.",
            },
            {
              definition: "One who watches; a watchman.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in the plural) Hautboys, or oboes, played by town musicians.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in the plural) Musicians who sing or play at night or in the early morning, especially at Christmas time; serenaders; musical watchmen. [formerly waites, wayghtes.]",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To delay movement or action until the arrival or occurrence of; to await. (Now generally superseded by “wait for”.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To delay movement or action until some event or time; to remain neglected or in readiness.",
              synonyms: [],
              antonyms: [],
              example: "Wait here until your car arrives.",
            },
            {
              definition:
                "To wait tables; to serve customers in a restaurant or other eating establishment.",
              synonyms: [],
              antonyms: [],
              example: "She used to wait down at the Dew Drop Inn.",
            },
            {
              definition:
                "To attend on; to accompany; especially, to attend with ceremony or respect.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To attend as a consequence; to follow upon; to accompany.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To defer or postpone (especially a meal).",
              synonyms: [],
              antonyms: [],
              example: "to wait dinner",
            },
            {
              definition: "To remain chaste while one's lover is unavailable.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "attend",
            "escort",
            "go with",
            "bestand",
            "serve",
            "tend",
            "defer",
            "postpone",
            "await",
            "wait for",
            "hold one's breath",
            "serve",
            "wait on",
            "wait upon",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A group of singers or musicians performing in the streets, especially around Christmas.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wait",
        "https://en.wiktionary.org/wiki/waits",
      ],
    },
  ],
  glued: [
    {
      word: "glued",
      phonetic: "/ɡluːd/",
      phonetics: [{ text: "/ɡluːd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To join or attach something using glue.",
              synonyms: [],
              antonyms: [],
              example: "I need to glue the chair-leg back into place.",
            },
            {
              definition:
                "To cause something to adhere closely to; to follow attentively.",
              synonyms: [],
              antonyms: [],
              example: "His eyes were glued to the screen.",
            },
          ],
          synonyms: [
            "adhere",
            "cling",
            "stick",
            "agglutinate",
            "conglutinate",
            "gum",
            "paste",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/glue",
        "https://en.wiktionary.org/wiki/glued",
      ],
    },
  ],
  banjo: [
    {
      word: "banjo",
      phonetic: "/ˈbæn.dʒəʊ/",
      phonetics: [
        { text: "/ˈbæn.dʒəʊ/", audio: "" },
        {
          text: "/ˈbæn.dʒoʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/banjo-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769846",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A stringed musical instrument (chordophone), usually with a round body, a membrane-like soundboard and a fretted neck, played by plucking or strumming the strings.",
              synonyms: [],
              antonyms: [],
              example: "I come from Alabama with my banjo on my knee...",
            },
            {
              definition:
                "Any of various similar musical instruments, such as the Tuvan doshpuluur, with a membrane-like soundboard.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An object shaped like a banjo, especially a frying pan or a shovel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(Dagenham) A cul-de-sac with a round end.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To play a banjo.", synonyms: [], antonyms: [] },
            {
              definition: "To beat, to knock down.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To shell or attack (a target).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/banjo"],
    },
  ],
  tease: [
    {
      word: "tease",
      phonetic: "/tiːz/",
      phonetics: [
        {
          text: "/tiːz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tease-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=399084",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "One who teases.", synonyms: [], antonyms: [] },
            {
              definition: "A single act of teasing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One who deliberately arouses others (usually men) sexually with no intention of satisfying that arousal.",
              synonyms: [
                "cock tease",
                "cocktease",
                "cockteaser",
                "prickteaser",
              ],
              antonyms: [],
            },
          ],
          synonyms: ["cock tease", "cocktease", "cockteaser", "prickteaser"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To separate the fibres of a fibrous material.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To comb (originally with teasels) so that the fibres all lie in one direction.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To back-comb.", synonyms: [], antonyms: [] },
            { definition: "To poke fun at.", synonyms: [], antonyms: [] },
            {
              definition: "To provoke or disturb; to annoy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To manipulate or influence the behavior of, especially by repeated acts of irritation.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To entice, tempt.", synonyms: [], antonyms: [] },
            {
              definition: "To show as forthcoming, in the manner of a teaser.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tease"],
    },
  ],
  arena: [
    {
      word: "arena",
      phonetic: "/əˈɹiːnə/",
      phonetics: [{ text: "/əˈɹiːnə/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An enclosed area, often outdoor, for the presentation of sporting events (sports arena) or other spectacular events; earthen area, often oval, specifically for rodeos (North America) or circular area for bullfights (especially Hispanic America).",
              synonyms: [],
              antonyms: [],
              example: "A large crowd filled the seats of the arena.",
            },
            {
              definition:
                "The building housing such an area; specifically, a very large, often round building, often topped with a dome, designated for indoor sporting or other major events, such as concerts.",
              synonyms: [],
              antonyms: [],
              example: "The arena is grey with white beams.",
            },
            {
              definition:
                "The sand-covered centre of an amphitheatre where contests were held in Ancient Rome.",
              synonyms: [],
              antonyms: [],
              example: "The gladiators entered the arena.",
            },
            {
              definition:
                "A realm in which events take place; an area of interest, study, behaviour, etc.",
              synonyms: [],
              antonyms: [],
              example:
                "The company was a player in the maritime insurance arena.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/arena"],
    },
  ],
  bulky: [
    {
      word: "bulky",
      phonetic: "/ˈbʌlki/",
      phonetics: [
        {
          text: "/ˈbʌlki/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bulky-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1545242",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Being large in size, mass, or volume.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Unwieldy.", synonyms: [], antonyms: [] },
            {
              definition: "Having excess body mass, especially muscle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bulky"],
    },
  ],
  carve: [
    {
      word: "carve",
      phonetic: "/kɑːv/",
      phonetics: [
        {
          text: "/kɑːv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/carve-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=68569021",
          license: {
            name: "BY 4.0",
            url: "https://creativecommons.org/licenses/by/4.0",
          },
        },
        {
          text: "/kɑɹv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/carve-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=60627110",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A carucate.", synonyms: [], antonyms: [] },
            {
              definition: "The act of carving",
              synonyms: [],
              antonyms: [],
              example: "give that turkey a careful carve",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To cut.", synonyms: [], antonyms: [] },
            {
              definition: "To cut meat in order to serve it.",
              synonyms: [],
              antonyms: [],
              example: "You carve the roast and I'll serve the vegetables.",
            },
            {
              definition:
                "To shape to sculptural effect; to produce (a work) by cutting, or to cut (a material) into a finished work.",
              synonyms: [],
              antonyms: [],
              example: "to carve a name into a tree",
            },
            {
              definition:
                "To perform a series of turns without pivoting, so that the tip and tail of the snowboard take the same path.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To take or make, as by cutting; to provide.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To lay out; to contrive; to design; to plan.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/carve"],
    },
  ],
  stunt: [
    {
      word: "stunt",
      phonetic: "/stʌnt/",
      phonetics: [
        {
          text: "/stʌnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stunt-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780409",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A daring or dangerous feat, often involving the display of gymnastic skills.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Skill", synonyms: [], antonyms: [] },
            {
              definition:
                "A special means of rushing the quarterback done to confuse the opposing team's offensive line.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(cheerleading) To perform a stunt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To show off; to posture.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stunt"],
    },
    {
      word: "stunt",
      phonetic: "/stʌnt/",
      phonetics: [
        {
          text: "/stʌnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stunt-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780409",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A check in growth.", synonyms: [], antonyms: [] },
            {
              definition:
                "That which has been checked in growth; a stunted animal or thing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A two-year-old whale, which, having been weaned, is lean and yields little blubber.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To check or hinder the growth or development of.",
              synonyms: [],
              antonyms: [],
              example: "Some have said smoking stunts your growth.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stunt"],
    },
  ],
  warms: [
    {
      word: "warms",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The act of warming, or the state of being warmed; a heating.",
              synonyms: [],
              antonyms: [],
              example: "Shall I give your coffee a warm in the microwave?",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To make or keep warm.", synonyms: [], antonyms: [] },
            {
              definition: "To become warm, to heat up.",
              synonyms: [],
              antonyms: [],
              example: "My socks are warming by the fire.",
            },
            {
              definition: "To favour increasingly.",
              synonyms: [],
              antonyms: [],
              example: "He is warming to the idea.",
            },
            {
              definition: "To become ardent or animated.",
              synonyms: [],
              antonyms: [],
              example: "The speaker warms as he proceeds.",
            },
            {
              definition:
                "To make engaged or earnest; to interest; to engage; to excite ardor or zeal in; to enliven.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To beat or spank.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/warm",
        "https://en.wiktionary.org/wiki/warms",
      ],
    },
  ],
  shady: [
    {
      word: "shady",
      phonetic: "/ˈʃeɪdi/",
      phonetics: [
        {
          text: "/ˈʃeɪdi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shady-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=681140",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Abounding in shades.", synonyms: [], antonyms: [] },
            { definition: "Causing shade.", synonyms: [], antonyms: [] },
            {
              definition:
                "Overspread with shade; sheltered from the glare of light or sultry heat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Not trustworthy; disreputable.",
              synonyms: [],
              antonyms: [],
              example: "He is a shady character.",
            },
            {
              definition: "Mean, cruel.",
              synonyms: [],
              antonyms: [],
              example: "Don't be shady, give us a go.",
            },
          ],
          synonyms: [
            "corrupt",
            "dodgy",
            "dubious",
            "equivocal",
            "seedy",
            "sketchy",
            "suspicious",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shady"],
    },
  ],
  razor: [
    {
      word: "razor",
      phonetic: "/ˈɹeɪzə/",
      phonetics: [
        {
          text: "/ˈɹeɪzə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/razor-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50244056",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A keen-edged knife of peculiar shape, used in shaving the hair from the face or other parts of the body.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any tool or instrument designed for shaving.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The sharp tusk of a wild boar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A conceptual device that allows one to shave away unlikely explanations for a phenomenon.",
              synonyms: [],
              antonyms: [],
              example: "Occam's razor, Hanlon's razor",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To shave with a razor.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/razor"],
    },
  ],
  folly: [
    {
      word: "folly",
      phonetic: "/ˈfɒli/",
      phonetics: [
        { text: "/ˈfɒli/", audio: "" },
        {
          text: "/ˈfɑli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/folly-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1749849",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Foolishness.",
              synonyms: [],
              antonyms: [],
              example: "It'd be folly.",
            },
            {
              definition: "Thoughtless action resulting in tragic consequence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A fanciful building built for purely ornamental reasons.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/folly"],
    },
  ],
  leafy: [
    {
      word: "leafy",
      phonetic: "/ˈliːfi/",
      phonetics: [{ text: "/ˈliːfi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Covered with leaves",
              synonyms: [],
              antonyms: [],
              example: "leafy trees",
            },
            {
              definition: "Containing much foliage",
              synonyms: [],
              antonyms: [],
              example: "a leafy avenue",
            },
            {
              definition: "In the form of leaves (of some material)",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Resembling a leaf", synonyms: [], antonyms: [] },
            {
              definition: "(of a place) wealthy, middle- or upper-class",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["foliated", "laminate", "layered"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/leafy"],
    },
  ],
  notch: [
    {
      word: "notch",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/notch-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241660",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/nɒtʃ/", audio: "" },
        { text: "/nɑtʃ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A V-shaped cut.", synonyms: [], antonyms: [] },
            { definition: "An indentation.", synonyms: [], antonyms: [] },
            {
              definition: "A mountain pass; a defile.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A discontinuous change in a taxation schedule.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A level or degree.",
              synonyms: [],
              antonyms: [],
              example: "Can you speak a notch louder, please?",
            },
            {
              definition:
                "A portion of a mobile phone that overlaps the edge of the screen, used to house camera, sensors etc. while maximizing screen space.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cut a notch in (something).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To record (a score or similar) by making notches on something.",
              synonyms: [],
              antonyms: [],
              example:
                "The tribe's hunters notch their kills by notches on each's axe's handle.",
            },
            {
              definition: "To join by means of notches.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To achieve (something); to add to one's score or record of successes.",
              synonyms: ["notch up"],
              antonyms: [],
              example: "The team notched a pair of shutout wins on Sunday.",
            },
            {
              definition:
                "To fit (an arrow) to a bow by means of the notch cut at the end of the arrow; to nock.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["notch up"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/notch"],
    },
  ],
  fools: [
    {
      word: "fools",
      phonetic: "/fuːlz/",
      phonetics: [
        {
          text: "/fuːlz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fools-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2454041",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A person with poor judgment or little intelligence.",
              synonyms: [],
              antonyms: [],
              example: "The village fool threw his own shoes down the well.",
            },
            {
              definition:
                "A jester; a person whose role was to entertain a sovereign and the court (or lower personages).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Someone who derives pleasure from something specified.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Buddy, dude, man.", synonyms: [], antonyms: [] },
            {
              definition:
                "A type of dessert made of puréed fruit and custard or cream.",
              synonyms: [],
              antonyms: [],
              example: "an apricot fool; a gooseberry fool",
            },
            {
              definition:
                "(often capitalized, Fool) A particular card in a tarot deck, representing a jester.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["jester", "joker", "gobshite"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To trick; to deceive", synonyms: [], antonyms: [] },
            {
              definition: "To act in an idiotic manner; to act foolishly",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fool",
        "https://en.wiktionary.org/wiki/fools",
      ],
    },
  ],
  otter: [
    {
      word: "otter",
      phonetic: "/ˈɒt.ə/",
      phonetics: [
        { text: "/ˈɒt.ə/", audio: "" },
        { text: "/ˈɑtɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An aquatic or marine carnivorous mammal in the subfamily Lutrinae of the family Mustelidae, which also includes weasels, polecats, badgers, and others.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hairy man with a slender physique, in contrast with a bear, who is more thickset.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/otter"],
    },
    {
      word: "otter",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Annatto (dye)", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/otter"],
    },
  ],
  pears: [
    {
      word: "pears",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An edible fruit produced by the pear tree, similar to an apple but elongated towards the stem.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A type of fruit tree (Pyrus communis).",
              synonyms: ["pear tree"],
              antonyms: [],
            },
            {
              definition: "The wood of the pear tree (pearwood, pear wood).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Choke pear (a torture device).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Avocado, alligator pear",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A desaturated chartreuse yellow colour, like that of a pear.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["pear tree"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pear",
        "https://en.wiktionary.org/wiki/pears",
      ],
    },
  ],
  flush: [
    {
      word: "flush",
      phonetic: "/ˈflʌʃ/",
      phonetics: [
        {
          text: "/ˈflʌʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/flush-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=92971255",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A group of birds that have suddenly started up from undergrowth, trees etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cause to take flight from concealment.",
              synonyms: [],
              antonyms: [],
              example: "The hunters flushed the tiger from the canebrake.",
            },
            {
              definition: "To take suddenly to flight, especially from cover.",
              synonyms: [],
              antonyms: [],
              example: "A covey of quail flushed from the undergrowth.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/flush"],
    },
    {
      word: "flush",
      phonetic: "/ˈflʌʃ/",
      phonetics: [
        {
          text: "/ˈflʌʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/flush-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=92971255",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Smooth, even, aligned; not sticking out.",
              synonyms: [],
              antonyms: [],
              example:
                "Sand down the excess until it is flush with the surface.",
            },
            {
              definition: "Wealthy or well off.",
              synonyms: [],
              antonyms: [],
              example: "He just got a bonus so he's flush today.",
            },
            {
              definition:
                "Short for flush left and right; a body of text aligned with both its left and right margins.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Full of vigour; fresh; glowing; bright.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Affluent; abounding; well furnished or suppled; hence, liberal; prodigal.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "double-clean",
            "flush left and right",
            "force justified",
            "forced",
            "forced justified",
            "justified",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/flush"],
    },
    {
      word: "flush",
      phonetic: "/ˈflʌʃ/",
      phonetics: [
        {
          text: "/ˈflʌʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/flush-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=92971255",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sudden flowing; a rush which fills or overflows, as of water for cleansing purposes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Particularly, such a cleansing of a toilet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A suffusion of the face with blood, as from fear, shame, modesty, or intensity of feeling of any kind; a blush; a glow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any tinge of red colour like that produced on the cheeks by a sudden rush of blood.",
              synonyms: [],
              antonyms: [],
              example:
                "the flush on the side of a peach; the flush on the clouds at sunset",
            },
            {
              definition:
                "A sudden flood or rush of feeling; a thrill of excitement, animation, etc.",
              synonyms: [],
              antonyms: [],
              example: "a flush of joy",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cleanse by flooding with generous quantities of a fluid.",
              synonyms: [],
              antonyms: [],
              example: "Flush the injury with plenty of water.",
            },
            {
              definition:
                "Particularly, to cleanse a toilet by introducing a large amount of water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To become suffused with reddish color due to embarrassment, excitement, overheating, or other systemic disturbance, to blush.",
              synonyms: [],
              antonyms: [],
              example: "The damsel flushed at the scoundrel's suggestion.",
            },
            { definition: "To cause to blush.", synonyms: [], antonyms: [] },
            {
              definition:
                "To cause to be full; to flood; to overflow; to overwhelm with water.",
              synonyms: [],
              antonyms: [],
              example: "to flush the meadows",
            },
            { definition: "To excite, inflame.", synonyms: [], antonyms: [] },
            {
              definition:
                "(of a toilet) To be cleansed by being flooded with generous quantities of water.",
              synonyms: [],
              antonyms: [],
              example:
                "There must be somebody home: I just heard the toilet flushing.",
            },
            {
              definition: "To clear (a buffer) of its contents.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To flow and spread suddenly; to rush.",
              synonyms: [],
              antonyms: [],
              example: "Blood flushes into the face.",
            },
            {
              definition: "To show red; to shine suddenly; to glow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To fill in (joints); to point the level; to make them flush.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To operate a placer mine, where the continuous supply of water is insufficient, by holding back the water, and releasing it periodically in a flood.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To fill underground spaces, especially in coal mines, with material carried by water, which, after drainage, constitutes a compact mass.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To dispose or be disposed of by flushing down a toilet",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["blush"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/flush"],
    },
    {
      word: "flush",
      phonetic: "/ˈflʌʃ/",
      phonetics: [
        {
          text: "/ˈflʌʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/flush-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=92971255",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A hand consisting of all cards with the same suit.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/flush"],
    },
  ],
  genus: [
    {
      word: "genus",
      phonetic: "/ˈdʒiːnəs/",
      phonetics: [
        { text: "/ˈdʒiːnəs/", audio: "" },
        {
          text: "/ˈdʒiːnəs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/genus-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762949",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A rank in the classification of organisms, below family and above species; a taxon at that rank.",
              synonyms: [],
              antonyms: [],
              example: "All magnolias belong to the genus Magnolia.",
            },
            {
              definition: "A group with common attributes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A natural number representing any of several related measures of the complexity of a given manifold or graph.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Within a definition, a broader category of the defined concept.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/genus"],
    },
  ],
  ached: [
    {
      word: "ached",
      phonetic: "/eɪkt/",
      phonetics: [{ text: "/eɪkt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To suffer pain; to be the source of, or be in, pain, especially continued dull pain; to be distressed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause someone or something to suffer pain.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ache",
        "https://en.wiktionary.org/wiki/ached",
      ],
    },
  ],
  fives: [
    {
      word: "fives",
      phonetic: "/faɪvz/",
      phonetics: [
        {
          text: "/faɪvz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fives-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79293425",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The digit/figure 5.",
              synonyms: [],
              antonyms: [],
              example: "He wrote a five followed by four zeroes.",
            },
            {
              definition:
                "A banknote with a denomination of five units of currency. See also fiver.",
              synonyms: [],
              antonyms: [],
              example: "Can anyone here change a five?",
            },
            {
              definition: "Anything measuring five units, as length.",
              synonyms: [],
              antonyms: [],
              example:
                "All the fives are over there in the corner, next to the fours.",
            },
            {
              definition: "A person who is five years old.",
              synonyms: [],
              antonyms: [],
              example:
                "The fives and sixes will have a snack first, then the older kids.",
            },
            {
              definition: "Five o'clock.",
              synonyms: [],
              antonyms: [],
              example: "See you at five.",
            },
            {
              definition: "A short rest, especially one of five minutes.",
              synonyms: [],
              antonyms: [],
              example: "Take five, soldier.",
            },
            {
              definition: "A basketball team, club or lineup.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A ball game, somewhat like tennis, played against a wall.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A pair of fives.", synonyms: [], antonyms: [] },
            {
              definition: "The cells located on the fourth floor.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/five",
        "https://en.wiktionary.org/wiki/fives",
      ],
    },
  ],
  flaps: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  spout: [
    {
      word: "spout",
      phonetic: "/spaʊt/",
      phonetics: [
        {
          text: "/spaʊt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spout-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79218897",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/spʌʊt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A tube or lip through which liquid or steam is poured or discharged.",
              synonyms: [],
              antonyms: [],
              example: "I dropped my china teapot, and its spout broke.",
            },
            { definition: "A stream of liquid.", synonyms: [], antonyms: [] },
            {
              definition:
                "The mixture of air and water thrown up from the blowhole of a whale.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To gush forth in a jet or stream",
              synonyms: [],
              antonyms: [],
              example: "Water spouts from a hole.",
            },
            {
              definition: "To eject water or liquid in a jet.",
              synonyms: [],
              antonyms: [],
              example: "The whale spouted.",
            },
            {
              definition: "To speak tediously or pompously.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To utter magniloquently; to recite in an oratorical or pompous manner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To pawn; to pledge.",
              synonyms: [],
              antonyms: [],
              example: "to spout a watch",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spout"],
    },
  ],
  fumes: [
    {
      word: "fumes",
      phonetic: "/fjuːmz/",
      phonetics: [{ text: "/fjuːmz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A gas or vapour/vapor that is strong-smelling or dangerous to inhale.",
              synonyms: [],
              antonyms: [],
              example:
                "Don't stand around in there breathing the fumes while the adhesive cures.",
            },
            {
              definition:
                "A material that has been vaporized from the solid or liquid state to the gas state and re-coalesced to the solid state.",
              synonyms: [],
              antonyms: [],
              example:
                "Lead fume is a greyish powder, mainly comprising lead sulfate.",
            },
            {
              definition:
                "Rage or excitement which deprives the mind of self-control.",
              synonyms: [],
              antonyms: [],
              example: "the fumes of passion",
            },
            {
              definition:
                "Anything unsubstantial or airy; idle conceit; vain imagination.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The incense of praise; inordinate flattery.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A passionate person.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To expose (something) to fumes; specifically, to expose wood, etc., to ammonia in order to produce dark tints.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To apply or offer incense to.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To emit fumes.", synonyms: [], antonyms: [] },
            {
              definition: "To pass off in fumes or vapours.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To express or feel great anger.",
              synonyms: [],
              antonyms: [],
              example:
                "He’s still fuming about the argument they had yesterday.",
            },
            {
              definition: "To be as in a mist; to be dulled and stupefied.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fume",
        "https://en.wiktionary.org/wiki/fumes",
      ],
    },
  ],
  adapt: [
    {
      word: "adapt",
      phonetic: "/əˈdæpt/",
      phonetics: [
        {
          text: "/əˈdæpt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/adapt-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769235",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make suitable; to make to correspond; to fit or suit",
              synonyms: ["proportion"],
              antonyms: [],
            },
            {
              definition:
                "To fit by alteration; to modify or remodel for a different purpose; to adjust",
              synonyms: [],
              antonyms: [],
              example: "to adapt a story for the stage",
            },
            {
              definition:
                "To make by altering or fitting something else; to produce by change of form or character",
              synonyms: [],
              antonyms: [],
              example: "a word of an adapted form",
            },
            {
              definition: "To make oneself comfortable to a new thing.",
              synonyms: [],
              antonyms: [],
              example:
                "They could not adapt to the new climate and so perished.",
            },
          ],
          synonyms: ["proportion"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Adapted; fit; suited; suitable.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/adapt"],
    },
  ],
  cuffs: [
    {
      word: "cuffs",
      phonetic: "/kʌfs/",
      phonetics: [{ text: "/kʌfs/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Glove; mitten", synonyms: [], antonyms: [] },
            {
              definition: "The end of a shirt sleeve that covers the wrist",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The end of a pants leg, folded up",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Handcuffs", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To furnish with cuffs.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To handcuff.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A blow, especially with the open hand; a box; a slap.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To hit, as a reproach, particularly with the open palm to the head; to slap.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fight; to scuffle; to box.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To buffet.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The scruff of the neck.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cuff",
        "https://en.wiktionary.org/wiki/cuffs",
      ],
    },
  ],
  tasty: [
    {
      word: "tasty",
      phonetic: "/ˈteɪsti/",
      phonetics: [
        {
          text: "/ˈteɪsti/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tasty-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780433",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having a pleasant or satisfying flavor; delicious.",
              synonyms: [],
              antonyms: [],
              example: "You could make this tasty meal for breakfast.",
            },
            {
              definition: "Having or showing good taste; tasteful.",
              synonyms: [],
              antonyms: [],
              example: "These items will make an attractive and tasty display.",
            },
            {
              definition:
                "Appealing; when applied to persons, sexually appealing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Skillful; highly competent.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Potentially violent.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tasty"],
    },
  ],
  stoop: [
    {
      word: "stoop",
      phonetic: "/stuːp/",
      phonetics: [
        { text: "/stuːp/", audio: "" },
        { text: "/stuːp/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The staircase and landing or porch leading to the entrance of a residence.",
              synonyms: ["porch", "verandah"],
              antonyms: [],
            },
            {
              definition: "The threshold of a doorway, a doorstep.",
              synonyms: ["doorstep", "step"],
              antonyms: [],
            },
          ],
          synonyms: ["doorstep", "step", "porch", "verandah"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stoop"],
    },
    {
      word: "stoop",
      phonetic: "/stuːp/",
      phonetics: [
        { text: "/stuːp/", audio: "" },
        { text: "/stuːp/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A stooping, bent position of the body.",
              synonyms: [],
              antonyms: [],
              example: "The old man walked with a stoop.",
            },
            {
              definition:
                "An accelerated descent in flight, as that for an attack.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To bend the upper part of the body forward and downward to a half-squatting position; crouch.",
              synonyms: [],
              antonyms: [],
              example: "He stooped to tie his shoe-laces.",
            },
            {
              definition:
                "To lower oneself; to demean or do something below one's status, standards, or morals.",
              synonyms: [],
              antonyms: [],
              example:
                "Can you believe that a salesman would stoop so low as to hide his customers' car keys until they agreed to the purchase?",
            },
            {
              definition: "Of a bird of prey: to swoop down on its prey.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause to incline downward; to slant.",
              synonyms: [],
              antonyms: [],
              example: "to stoop a cask of liquor",
            },
            {
              definition: "To cause to submit; to prostrate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To yield; to submit; to bend, as by compulsion; to assume a position of humility or subjection.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To descend from rank or dignity; to condescend.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To degrade.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["bend down", "crouch", "squat"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stoop"],
    },
    {
      word: "stoop",
      phonetic: "/stuːp/",
      phonetics: [
        { text: "/stuːp/", audio: "" },
        { text: "/stuːp/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A post or pillar, especially a gatepost or a support in a mine.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stoop"],
    },
    {
      word: "stoop",
      phonetic: "/stuːp/",
      phonetics: [
        { text: "/stuːp/", audio: "" },
        { text: "/stuːp/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A vessel for holding liquids; a flagon.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stoop"],
    },
  ],
  clips: [
    {
      word: "clips",
      phonetic: "/klɪps/",
      phonetics: [{ text: "/klɪps/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Something which clips or grasps; a device for attaching one object to another.",
              synonyms: [],
              antonyms: [],
              example: "Use this clip to attach the check to your tax form.",
            },
            {
              definition:
                "An unspecified but normally understood as rapid speed or pace.",
              synonyms: [],
              antonyms: [],
              example:
                "He was walking at a fair clip and I was out of breath trying to keep up.",
            },
            { definition: "An embrace.", synonyms: [], antonyms: [] },
            {
              definition:
                "A frame containing a number of bullets which is intended to be inserted into the magazine of a firearm to allow for rapid reloading.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A projecting flange on the upper edge of a horseshoe, turned up so as to embrace the lower part of the hoof; a toe clip or beak.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A gaff or hook for landing the fish, as in salmon fishing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To grip tightly.", synonyms: [], antonyms: [] },
            {
              definition: "To fasten with a clip.",
              synonyms: [],
              antonyms: [],
              example:
                "Please clip the photos to the pages where they will go.",
            },
            { definition: "To hug, embrace.", synonyms: [], antonyms: [] },
            {
              definition:
                "To collect signatures, generally with the use of a clipboard.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Something which has been clipped from a larger whole:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An act of clipping, such as a haircut.",
              synonyms: [],
              antonyms: [],
              example: "I went into the salon to get a clip.",
            },
            {
              definition: "The condition of something, its state.",
              synonyms: [],
              antonyms: [],
              example: "Deeky the clip of that aad wife ower thor!",
            },
            {
              definition:
                "A blow with the hand (often in the set phrase clip round the ear)",
              synonyms: [],
              antonyms: [],
              example: "Give him a clip round the ear!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cut, especially with scissors or shears as opposed to a knife etc.",
              synonyms: [],
              antonyms: [],
              example: "Please clip that coupon out of the newspaper.",
            },
            {
              definition: "To curtail; to cut short.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To strike with the hand.",
              synonyms: [],
              antonyms: [],
              example: "I'll clip ye round the lugs!",
            },
            {
              definition: "To hit or strike, especially in passing.",
              synonyms: [],
              antonyms: [],
              example: "The car skidded off the road and clipped a lamppost.",
            },
            {
              definition:
                "An illegal tackle: Throwing the body across the back of an opponent's leg or hitting him from the back below the waist while moving up from behind unless the opponent is a runner or the action is in close line play.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cut off a signal level at a certain maximum value.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To discard (an occluded part of a model or scene) rather than waste resources on rendering it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Of a camera, character model, etc.) To move (through or into) (a rendered object or barrier).",
              synonyms: [],
              antonyms: [],
              example:
                "Clipping through walls is integral to the game's speedruns.",
            },
            {
              definition: "To cheat, swindle, or fleece.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To grab or take stealthily",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/clip",
        "https://en.wiktionary.org/wiki/clips",
      ],
    },
  ],
  disks: [
    {
      word: "disks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A thin, flat, circular plate or similar object.",
              synonyms: [],
              antonyms: [],
              example: "A coin is a disk of metal.",
            },
            {
              definition: "Something resembling a disk.",
              synonyms: [],
              antonyms: [],
              example: "Venus' disk cut off light from the Sun.",
            },
            {
              definition: "An intervertebral disc",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A vinyl phonograph/gramophone record.",
              synonyms: [],
              antonyms: [],
              example: "Turn the disk over, after it has finished.",
            },
            {
              definition:
                "A disc - either a CD-ROM, an audio CD, a DVD or similar removable storage medium.",
              synonyms: [],
              antonyms: [],
              example:
                "She burned some disks yesterday to back up her computer.",
            },
            { definition: "A type of harrow.", synonyms: [], antonyms: [] },
            {
              definition:
                "A ring- or cup-shaped enlargement of the flower receptacle or ovary that bears nectar or, less commonly, the stamens.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To harrow", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A removable disk used for storing digital data, measuring between 2 and 8 inches diagonally and storing between 80 KB and 240 MB.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["disk", "diskette", "floppy", "floppy diskette"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/disk",
        "https://en.wiktionary.org/wiki/disks",
        "https://en.wiktionary.org/wiki/floppy%20disk",
      ],
    },
  ],
  sniff: [
    {
      word: "sniff",
      phonetic: "/snɪf/",
      phonetics: [
        {
          text: "/snɪf/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sniff-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241823",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/snɪf/", audio: "" },
        { text: "/snəf/", audio: "" },
        { text: "/snɪf/", audio: "" },
        { text: "/snɪf/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An instance of sniffing.",
              synonyms: [],
              antonyms: [],
              example:
                "She gave the flowers a quick sniff to check they were real.",
            },
            {
              definition:
                "A quantity of something that is inhaled through the nose",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A brief perception, or tiny amount.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Cocaine.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make a short, audible inhalation, through the nose, as when smelling something.",
              synonyms: [],
              antonyms: [],
              example: "I sniffed the meat to see whether it had gone off.",
            },
            {
              definition:
                "To say something while sniffing, for example in case of illness or unhappiness, or in contempt.",
              synonyms: [],
              antonyms: [],
              example:
                '"He\'s never coming back, is he?" she sniffed while looking at a picture of him.',
            },
            {
              definition: "To perceive vaguely",
              synonyms: [],
              antonyms: [],
              example: "I can sniff trouble coming from the basement.",
            },
            {
              definition: "To be dismissive or contemptuous of something.",
              synonyms: [],
              antonyms: [],
              example: "This opportunity is not to be sniffed at.",
            },
            {
              definition:
                "To intercept and analyse packets of data being transmitted over a network.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To inhale drugs in powder form (usually cocaine) through the nose.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition:
                "A short inhalation sound, sometimes associated with crying.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sniff"],
    },
  ],
  lanes: [
    {
      word: "lanes",
      phonetic: "/leɪnz/",
      phonetics: [{ text: "/leɪnz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(used in street names) A road, street, or similar thoroughfare.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A narrow passageway between fences, walls, hedges or trees.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A narrow road, as in the country.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A lengthwise division of roadway intended for a single line of vehicles.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A similar division of a racetrack to keep runners apart.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A course designated for ships or aircrafts.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An elongated wooden strip of floor along which a bowling ball is rolled.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An empty space in the tableau, formed by the removal of an entire row of cards.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of the parallel slots in which values can be stored in a SIMD architecture.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In MOBA (multiplayer online battle arena) games, a particular path on the map that may be traversed by enemy characters.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The homestretch.",
              synonyms: [],
              antonyms: [],
              example:
                "And it's Uncle Mo in front by two as they come to the top of the lane.",
            },
          ],
          synonyms: ["carriageway", "direction", "roadway", "side"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lane",
        "https://en.wiktionary.org/wiki/lanes",
      ],
    },
  ],
  brisk: [
    {
      word: "brisk",
      phonetic: "/bɹɪsk/",
      phonetics: [{ text: "/bɹɪsk/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                '(often with "up") To make or become lively; to enliven; to animate.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Full of liveliness and activity; characterized by quickness of motion or action",
              synonyms: ["lively", "quick", "spirited"],
              antonyms: [],
              example: "We took a brisk walk yesterday.",
            },
            {
              definition: "Full of spirit of life; effervescing",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Sparkling; fizzy",
              synonyms: [],
              antonyms: [],
              example: "brisk cider",
            },
            {
              definition: "Stimulating or invigorating.",
              synonyms: [],
              antonyms: [],
              example:
                "This morning was a brisk fall day. It wasn't cold enough for frost, but you wanted to keep moving.",
            },
            {
              definition:
                "Abrupt, curt in one's manner or in relation to others.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["lively", "quick", "spirited"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/brisk"],
    },
  ],
  imply: [
    {
      word: "imply",
      phonetic: "/ɪmˈplaɪ/",
      phonetics: [
        { text: "/ɪmˈplaɪ/", audio: "" },
        {
          text: "/ɪmˈplaɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/imply-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762703",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(of a proposition) to have as a necessary consequence",
              synonyms: [],
              antonyms: [],
              example:
                'The proposition that "all dogs are mammals" implies that my dog is a mammal',
            },
            {
              definition: "(of a person) to suggest by logical inference",
              synonyms: [],
              antonyms: [],
              example:
                "When I state that your dog is brown, I am not implying that all dogs are brown",
            },
            {
              definition:
                "(of a person or proposition) to hint; to insinuate; to suggest tacitly and avoid a direct statement",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To enfold, entangle.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["entail", "allude", "hint", "insinuate", "suggest"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/imply"],
    },
  ],
  demon: [
    {
      word: "demon",
      phonetic: "/ˈdiː.mən/",
      phonetics: [
        {
          text: "/ˈdiː.mən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/demon-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88675812",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An evil supernatural spirit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A neutral supernatural spirit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Someone with great strength, passion or skill for a particular activity, pursuit etc.; an enthusiast.",
              synonyms: [],
              antonyms: [],
              example: "He’s a demon at the card tables.",
            },
            {
              definition: "A form of patience (known as Canfield in the US).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various hesperiid butterflies of the genera Notocrypta and Udaspes.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["genius", "tutelary deity"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/demon"],
    },
  ],
  super: [
    {
      word: "super",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/super-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75701597",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/super-ca.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=935997",
          license: {
            name: "BY 2.5",
            url: "https://creativecommons.org/licenses/by/2.5",
          },
        },
        {
          text: "/ˈs(j)uːpə(ɹ)/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/super-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=53353119",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈs(j)upɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of excellent quality, superfine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Better than average, better than usual; wonderful.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["awesome", "excellent"],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Very; extremely (used like the prefix super-).",
              synonyms: [],
              antonyms: [],
              example: "The party was super awesome.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/super"],
    },
    {
      word: "super",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/super-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75701597",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/super-ca.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=935997",
          license: {
            name: "BY 2.5",
            url: "https://creativecommons.org/licenses/by/2.5",
          },
        },
        {
          text: "/ˈs(j)uːpə(ɹ)/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/super-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=53353119",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈs(j)upɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Short for superannuation.",
              synonyms: [],
              antonyms: [],
              example:
                "Jane looked forward to collecting a large super payout when she retired.",
            },
            {
              definition: "Short for supercomputer.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Short for superhero.", synonyms: [], antonyms: [] },
            { definition: "Short for superhive.", synonyms: [], antonyms: [] },
            {
              definition:
                "Short for superintendent, especially, a building's resident manager (sometimes clarified as “building super”).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Short for supernaturalist, especially as distinguished from bright.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Short for supernumerary; specifically, a supernumerary actor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Short for supertanker.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Short for supervisor.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "Short for superhive.", synonyms: [], antonyms: [] },
            {
              definition: "Short for superimpose.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/super"],
    },
  ],
  furry: [
    {
      word: "furry",
      phonetic: "/ˈfɜːɹi/",
      phonetics: [
        {
          text: "/ˈfɜːɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/furry-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80949203",
        },
        { text: "/ˈfɝi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An animal character with human-like characteristics; most commonly refers to such characters created by members of the furry subculture.",
              synonyms: ["fursona"],
              antonyms: [],
              example: "What percentage of furries are wolves?",
            },
            {
              definition: "A member of the furry fandom.",
              synonyms: [],
              antonyms: [],
              example: "The furry had designed an elaborate costume.",
            },
            {
              definition:
                "Someone who roleplays or identifies with a furry character. (Compare therianthrope.)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["fursona"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Covered with fur, or with something resembling fur.",
              synonyms: [],
              antonyms: [],
              example:
                "His treatment of our furry friends was nothing short of appalling.",
            },
            {
              definition: "Of or related to the furry subculture.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/furry"],
    },
  ],
  raged: [
    {
      word: "raged",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/raged-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453663",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To act or speak in heightened anger.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(sometimes figurative) To move with great violence, as a storm etc.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To enrage.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rage",
        "https://en.wiktionary.org/wiki/raged",
      ],
    },
  ],
  growl: [
    {
      word: "growl",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/growl-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75797172",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ɡɹaʊl/", audio: "" },
        {
          text: "/ɡɹaʊl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/growl-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1217829",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A deep, rumbling, threatening sound made in the throat by an animal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) The rumbling sound made by a person's stomach when hungry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) An aggressive grumbling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) A low-pitched rumbling sound produced with a wind instrument.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To utter a deep guttural sound, as an angry animal; to give forth an angry, grumbling sound.",
              synonyms: ["gnar", "gnarl", "gurl", "snarl"],
              antonyms: [],
              example: "The dog growled at me as I walked past.",
            },
            {
              definition:
                "Of a wind instrument: to produce a low-pitched rumbling sound.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To send a user a message via the Growl software library.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To express (something) by growling.",
              synonyms: [],
              antonyms: [],
              example: "The old man growled his displeasure at the postman.",
            },
            {
              definition:
                "To play a wind instrument in a way that produces a low-pitched rumbling sound.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["gnar", "gnarl", "gurl", "snarl"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/growl"],
    },
  ],
  texts: [
    {
      word: "texts",
      phonetic: "/tɛks/",
      phonetics: [{ text: "/tɛks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A writing consisting of multiple glyphs, characters, symbols or sentences.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A book, tome or other set of writings.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A brief written message transmitted between mobile phones.",
              synonyms: ["text message"],
              antonyms: [],
            },
            {
              definition:
                "Data which can be interpreted as human-readable text.",
              synonyms: [],
              antonyms: ["binary"],
            },
            {
              definition:
                "A verse or passage of Scripture, especially one chosen as the subject of a sermon, or in proof of a doctrine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) Anything chosen as the subject of an argument, literary composition, etc.",
              synonyms: ["theme", "topic"],
              antonyms: [],
            },
            {
              definition:
                "A style of writing in large characters; also, a kind of type used in printing.",
              synonyms: ["text hand"],
              antonyms: [],
              example: "German text",
            },
          ],
          synonyms: ["text hand", "text message", "theme", "topic"],
          antonyms: ["binary"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To send a text message to; i.e. to transmit text using the Short Message Service (SMS), or a similar service, between communications devices, particularly mobile phones.",
              synonyms: ["SMS", "message"],
              antonyms: [],
              example: "I'll text the address to you as soon as I find it.",
            },
            {
              definition: "To send and receive text messages.",
              synonyms: [],
              antonyms: [],
              example: "Have you been texting all afternoon?",
            },
            {
              definition: "To write in large characters, as in text hand.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["SMS", "message"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/text",
        "https://en.wiktionary.org/wiki/texts",
      ],
    },
  ],
  hardy: [
    {
      word: "hardy",
      phonetic: "/ˈhɑːdi/",
      phonetics: [
        { text: "/ˈhɑːdi/", audio: "" },
        {
          text: "/ˈhɑɹdi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hardy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50422638",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(usually in the plural) Anything, especially a plant, that is hardy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A blacksmith's fuller or chisel, having a square shank for insertion into a square hole in an anvil, called the hardy hole.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Having rugged physical strength; inured to fatigue or hardships.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Able to survive adverse growing conditions.",
              synonyms: [],
              antonyms: [],
              example:
                "A hardy plant is one that can withstand the extremes of climate, such as frost.",
            },
            { definition: "Brave and resolute.", synonyms: [], antonyms: [] },
            { definition: "Impudent.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["robust", "rugged", "strong"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hardy"],
    },
  ],
  stung: [
    {
      word: "stung",
      phonetic: "/stʌŋ/",
      phonetics: [
        {
          text: "/stʌŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stung-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453735",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To hurt, usually by introducing poison or a sharp point, or both.",
              synonyms: [],
              antonyms: [],
              example:
                "Right so came out an adder of a little heathbush, and it stung a knight in the foot.",
            },
            {
              definition: "(of an insect) To bite.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(sometimes figurative) To hurt, to be in pain.",
              synonyms: [],
              antonyms: [],
              example: "My hand stings after knocking on the door so long.",
            },
            {
              definition: "To cause harm or pain to.",
              synonyms: [],
              antonyms: [],
              example:
                "I thought I could park in front of the hotel, but they stung me for five pounds!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sting",
        "https://en.wiktionary.org/wiki/stung",
      ],
    },
  ],
  typed: [
    {
      word: "typed",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To put text on paper using a typewriter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To enter text or commands into a computer using a keyboard.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To determine the blood type of.",
              synonyms: [],
              antonyms: [],
              example:
                "The doctor ordered the lab to type the patient for a blood transfusion.",
            },
            {
              definition:
                "To represent by a type, model, or symbol beforehand; to prefigure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To furnish an expression or copy of; to represent; to typify.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To categorize into types.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Typewritten",
              synonyms: [],
              antonyms: [],
              example: "a typed document",
            },
            {
              definition:
                "(of a variable, expression, or value) Having a specific data type.",
              synonyms: [],
              antonyms: [],
              example: "A typed variable can only store one kind of data.",
            },
            {
              definition:
                "(of a programming language) In which variables are typed.",
              synonyms: [],
              antonyms: [],
              example: "Scheme is a typed language.",
            },
          ],
          synonyms: [],
          antonyms: ["untyped"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/type",
        "https://en.wiktionary.org/wiki/typed",
      ],
    },
  ],
  hates: [
    {
      word: "hates",
      phonetic: "/heɪts/",
      phonetics: [{ text: "/heɪts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An object of hatred.",
              synonyms: [],
              antonyms: [],
              example: "One of my pet hates is traffic wardens.",
            },
            {
              definition: "Hatred.",
              synonyms: [],
              antonyms: [],
              example: "He gave me a look filled with pure hate.",
            },
            {
              definition: "Negative feedback, abusive behaviour.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To dislike intensely or greatly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To experience hatred.",
              synonyms: [],
              antonyms: [],
              example:
                "Do not fear; he who fears hates; he who hates kills. — attributed to Gandhi",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hate",
        "https://en.wiktionary.org/wiki/hates",
      ],
    },
  ],
  wiser: [
    {
      word: "wiser",
      phonetic: "/ˈwaɪzə/",
      phonetics: [
        { text: "/ˈwaɪzə/", audio: "" },
        {
          text: "/ˈwaɪzɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wiser-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=424820",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Showing good judgement or the benefit of experience.",
              synonyms: [],
              antonyms: [],
              example:
                '"It is a profitable thing, if one is wise, to seem foolish" - Aeschylus',
            },
            {
              definition: "Disrespectful.",
              synonyms: [],
              antonyms: [],
              example: "Don't get wise with me!",
            },
            {
              definition: "Aware, informed.",
              synonyms: [],
              antonyms: [],
              example: "Be careful, the boss is wise.",
            },
          ],
          synonyms: [],
          antonyms: ["foolish", "unwise"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wise",
        "https://en.wiktionary.org/wiki/wiser",
      ],
    },
  ],
  timid: [
    {
      word: "timid",
      phonetic: "/ˈtɪmɪd/",
      phonetics: [
        {
          text: "/ˈtɪmɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/timid-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780444",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Lacking in courage or confidence.",
              synonyms: ["fearful", "shy", "timorous"],
              antonyms: [
                "aggressive",
                "bellicose",
                "daredevil",
                "dauntless",
                "reckless",
              ],
              example:
                "John's a very timid person. I'll doubt he'll be brave enough to face his brother.",
            },
          ],
          synonyms: ["fearful", "shy", "timorous"],
          antonyms: [
            "aggressive",
            "bellicose",
            "daredevil",
            "dauntless",
            "reckless",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/timid"],
    },
  ],
  serum: [
    {
      word: "serum",
      phonetic: "/ˈsiɹəm/",
      phonetics: [
        { text: "/ˈsiɹəm/", audio: "" },
        { text: "/ˈsiːɹəm/", audio: "" },
        { text: "/ˈsɪɹəm/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The clear yellowish liquid obtained upon separating whole blood into its solid and liquid components after it has been allowed to clot.",
              synonyms: ["blood serum"],
              antonyms: [],
            },
            {
              definition:
                "Blood serum from the tissues of immunized animals, containing antibodies and used to transfer immunity to another individual, called antiserum.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A watery liquid from animal tissue, especially one that moistens the surface of serous membranes or that is exuded by such membranes when they become inflamed, such as in edema or a blister.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The watery portion of certain animal fluids like blood, milk, etc; whey.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(skincare) An intensive moisturising product to be applied after cleansing but before a general moisturiser.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["blood serum"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/serum"],
    },
  ],
  beaks: [
    {
      word: "beaks",
      phonetic: "/biːks/",
      phonetics: [{ text: "/biːks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Anatomical uses.", synonyms: [], antonyms: [] },
            { definition: "Figurative uses.", synonyms: [], antonyms: [] },
            { definition: "Colloquial uses.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["honker", "schnozzle", "bill"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "Strike with the beak.", synonyms: [], antonyms: [] },
            { definition: "Seize with the beak.", synonyms: [], antonyms: [] },
            { definition: "To play truant.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/beak",
        "https://en.wiktionary.org/wiki/beaks",
      ],
    },
  ],
  rotor: [
    {
      word: "rotor",
      phonetic: "/ˈɹəʊ.tə/",
      phonetics: [
        { text: "/ˈɹəʊ.tə/", audio: "" },
        { text: "/ˈɹoʊ.tɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A rotating part of a mechanical device, for example in an electric motor, generator, alternator or pump.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The wing of a helicopter or similar aircraft.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A quantity having magnitude, direction and position.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rotor"],
    },
  ],
  casts: [
    {
      word: "casts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "An act of throwing.", synonyms: [], antonyms: [] },
            {
              definition: "An instance of throwing out a fishing line.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Something which has been thrown, dispersed etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'A small mass of earth "thrown off" or excreted by a worm.',
              synonyms: [],
              antonyms: [],
              example:
                "The area near the stream was covered with little bubbly worm casts.",
            },
            {
              definition:
                "The collective group of actors performing a play or production together. Contrasted with crew.",
              synonyms: [],
              antonyms: [],
              example: "He’s in the cast of Oliver.",
            },
            {
              definition: "The casting procedure.",
              synonyms: [],
              antonyms: [],
              example:
                "The men got into position for the cast, two at the ladle, two with long rods, all with heavy clothing.",
            },
            {
              definition: "An object made in a mould.",
              synonyms: [],
              antonyms: [],
              example:
                "The cast would need a great deal of machining to become a recognizable finished part.",
            },
            {
              definition:
                "A supportive and immobilising device used to help mend broken bones.",
              synonyms: [],
              antonyms: [],
              example: "The doctor put a cast on the boy’s broken arm.",
            },
            {
              definition: "The mould used to make cast objects.",
              synonyms: [],
              antonyms: [],
              example: "A plaster cast was made from his face.",
            },
            {
              definition:
                "(hawking) The number of hawks (or occasionally other birds) cast off at one time; a pair.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A squint.", synonyms: [], antonyms: [] },
            {
              definition: "Visual appearance.",
              synonyms: [],
              antonyms: [],
              example: "Her features had a delicate cast to them.",
            },
            {
              definition: "The form of one's thoughts, mind etc.",
              synonyms: [],
              antonyms: [],
              example: "a cast of mind, a mental tendency.",
            },
            {
              definition:
                "An animal, especially a horse, that is unable to rise without assistance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Animal and insect remains which have been regurgitated by a bird.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A group of crabs.", synonyms: [], antonyms: [] },
            { definition: "A broadcast.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(physical) To move, or be moved, away.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To direct (one's eyes, gaze etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To add up (a column of figures, accounts etc.); cross-cast refers to adding up a row of figures.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(social) To predict, to decide, to plan.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To perform, bring forth (a magical spell or enchantment).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To throw (light etc.) on or upon something, or in a given direction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To give birth to (a child) prematurely; to miscarry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To shape (molten metal etc.) by pouring into a mould; to make (an object) in such a way.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To twist or warp (of fabric, timber etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To bring the bows of a sailing ship on to the required tack just as the anchor is weighed by use of the headsail; to bring (a ship) round.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To deposit (a ballot or voting paper); to formally register (one's vote).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To change a variable type from, for example, integer to real, or integer to text.",
              synonyms: [],
              antonyms: [],
              example: "Casting is generally an indication of bad design.",
            },
            {
              definition:
                "Of dogs, hunters: to spread out and search for a scent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To set (a bone etc.) in a cast.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To open a circle in order to begin a spell or meeting of witches.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To broadcast.",
              synonyms: [],
              antonyms: [],
              example:
                "The streamer was the first to cast footage of the new game.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cast",
        "https://en.wiktionary.org/wiki/casts",
      ],
    },
  ],
  baths: [
    {
      word: "baths",
      phonetic: "/bɑːðz/",
      phonetics: [
        {
          text: "/bɑːðz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/baths-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1869622",
        },
        { text: "/bæðz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A tub or pool which is used for bathing: bathtub.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A building or area where bathing occurs.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The act of bathing.", synonyms: [], antonyms: [] },
            {
              definition:
                "A substance or preparation in which something is immersed.",
              synonyms: [],
              antonyms: [],
              example: "a bath of heated sand, ashes, steam, or hot air",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To wash a person or animal in a bath",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A former Hebrew unit of liquid volume (about 23 L or 6 gallons).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A room containing a shower and/or bathtub, and (typically but not necessarily) a toilet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A lavatory: a room containing a toilet and (typically but not necessarily) a bathtub.",
              synonyms: [],
              antonyms: [],
              example:
                "Most Americans don't know 'WC' and many Brits mock 'bathroom' but almost everyone understands 'toilet' or 'lavatory'.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bath",
        "https://en.wiktionary.org/wiki/bathroom",
        "https://en.wiktionary.org/wiki/baths",
      ],
    },
  ],
  glide: [
    {
      word: "glide",
      phonetic: "/ˈɡlaɪd/",
      phonetics: [{ text: "/ˈɡlaɪd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The act of gliding.", synonyms: [], antonyms: [] },
            {
              definition: "A transitional sound, especially a semivowel.",
              synonyms: ["semiconsonant", "semivowel"],
              antonyms: [],
            },
            {
              definition:
                "An attack or preparatory movement made by sliding down the opponent’s blade, keeping it in constant contact.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A bird, the glede or kite.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A kind of cap affixed to the base of the legs of furniture to prevent it from damaging the floor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The joining of two sounds without a break.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A smooth and sliding step in dancing the waltz.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["semiconsonant", "semivowel"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To move softly, smoothly, or effortlessly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To fly unpowered, as of an aircraft. Also relates to gliding birds and flying fish.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To cause to glide.", synonyms: [], antonyms: [] },
            {
              definition: "To pass with a glide, as the voice.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["coast", "slide"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/glide"],
    },
  ],
  plots: [
    {
      word: "plots",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(authorship) The course of a story, comprising a series of incidents which are gradually unfolded, sometimes by unexpected means.",
              synonyms: ["storyline"],
              antonyms: [],
            },
            {
              definition:
                "An area or land used for building on or planting on.",
              synonyms: ["parcel"],
              antonyms: [],
            },
            {
              definition:
                "A graph or diagram drawn by hand or produced by a mechanical or electronic device.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A secret plan to achieve an end, the end or means usually being illegal or otherwise questionable.",
              synonyms: ["conspiracy", "scheme"],
              antonyms: [],
              example:
                "The assassination of Lincoln was part of a larger plot.",
            },
            {
              definition:
                "Contrivance; deep reach thought; ability to plot or intrigue.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Participation in any stratagem or conspiracy.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A plan; a purpose.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["conspiracy", "scheme", "parcel", "storyline"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To conceive (a crime, etc).",
              synonyms: [],
              antonyms: [],
              example: "They had plotted a robbery.",
            },
            {
              definition: "To trace out (a graph or diagram).",
              synonyms: [],
              antonyms: [],
              example: "They plotted the number of edits per day.",
            },
            {
              definition: "To mark (a point on a graph, chart, etc).",
              synonyms: [],
              antonyms: [],
              example: "Every five minutes they plotted their position.",
            },
            {
              definition: "To conceive a crime, misdeed, etc.",
              synonyms: [],
              antonyms: [],
              example: "They were plotting against the king.",
            },
          ],
          synonyms: ["lot", "scheme", "becast"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/plot",
        "https://en.wiktionary.org/wiki/plots",
      ],
    },
  ],
  trait: [
    {
      word: "trait",
      phonetic: "/tɹeɪ/",
      phonetics: [
        {
          text: "/tɹeɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/trait-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90914493",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An identifying characteristic, habit or trend.",
              synonyms: ["characteristic"],
              antonyms: [],
              example:
                "The number one personality trait I hate is hypocrisy. Why can't you be consistent!?",
            },
            {
              definition:
                "An uninstantiable collection of methods that provides functionality to a class by using the class’s own interface.",
              synonyms: [],
              antonyms: [],
              example: "Traits are somewhat between an interface and a mixin.",
            },
          ],
          synonyms: ["characteristic"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/trait"],
    },
  ],
  resin: [
    {
      word: "resin",
      phonetic: "/ˈɹɛzɪn/",
      phonetics: [
        {
          text: "/ˈɹɛzɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/resin-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50330744",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A viscous hydrocarbon secretion of many plants, particularly coniferous trees.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various yellowish viscous liquids or soft solids of plant origin; used in lacquers, varnishes and many other applications; chemically they are mostly hydrocarbons, often polycyclic.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any synthetic compound of similar properties.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To apply resin to.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/resin"],
    },
  ],
  slums: [
    {
      word: "slums",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A dilapidated neighborhood where many people live in a state of poverty.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Inexpensive trinkets awarded as prizes in a carnival game.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To visit a neighborhood of a status below one's own.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/slum",
        "https://en.wiktionary.org/wiki/slums",
      ],
    },
  ],
  lyric: [
    {
      word: "lyric",
      phonetic: "/ˈlɪɹ.ɪk/",
      phonetics: [
        {
          text: "/ˈlɪɹ.ɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lyric-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=83326653",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈlɪɹ.ɪk/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A lyric poem.", synonyms: [], antonyms: [] },
            {
              definition:
                "(also in plural) The words of a song or other vocal music. The singular form often refers to a part of the words, whereas the plural form can refer to all of the words.",
              synonyms: [],
              antonyms: [],
              example: "The lyric in line 3 doesn't rhyme.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Of, or relating to a type of poetry (such as a sonnet or ode) that expresses subjective thoughts and feelings, often in a songlike style",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of or relating to a writer of such poetry",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Lyrical", synonyms: [], antonyms: [] },
            {
              definition: "Having a light singing voice of modest range",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of or relating to musical drama and opera",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Melodious", synonyms: [], antonyms: [] },
            {
              definition: "Of or relating to the lyre (or sometimes the harp)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lyric"],
    },
  ],
  puffs: [
    {
      word: "puffs",
      phonetic: "/pʌfs/",
      phonetics: [{ text: "/pʌfs/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To emit smoke, gas, etc., in puffs.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To pant.", synonyms: [], antonyms: [] },
            { definition: "To advertise.", synonyms: [], antonyms: [] },
            {
              definition: "To blow as an expression of scorn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To swell with air; to be dilated or inflated.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To breathe in a swelling, inflated, or pompous manner; hence, to assume importance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To drive with a puff, or with puffs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To repel with words; to blow at contemptuously.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause to swell or dilate; to inflate.",
              synonyms: [],
              antonyms: [],
              example: "a bladder puffed with air",
            },
            {
              definition:
                "To inflate with pride, flattery, self-esteem, etc.; often with up.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To praise with exaggeration; to flatter; to call public attention to by praises; to praise unduly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/puff",
        "https://en.wiktionary.org/wiki/puffs",
      ],
    },
  ],
  decks: [
    {
      word: "decks",
      phonetic: "/dɛks/",
      phonetics: [{ text: "/dɛks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any raised flat surface that can be walked on: a balcony; a porch; a raised patio; a flat rooftop.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The floorlike covering of the horizontal sections, or compartments, of a ship. Small vessels have only one deck; larger ships have two or three decks.",
              synonyms: [],
              antonyms: [],
              example: "to swab the deck",
            },
            {
              definition:
                "A main aeroplane surface, especially of a biplane or multiplane.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A pack or set of playing cards.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) A set of cards owned by each individual player and from which they draw when playing.",
              synonyms: ["library"],
              antonyms: [],
            },
            {
              definition: "A set of slides for a presentation.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A heap or store.", synonyms: [], antonyms: [] },
            {
              definition: "A folded paper used for distributing illicit drugs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The floor.",
              synonyms: [],
              antonyms: [],
              example: "We hit the deck as bullets began to fly.",
            },
          ],
          synonyms: ["library"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To furnish with a deck, as a vessel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To knock someone to the floor, especially with a single punch.",
              synonyms: [],
              antonyms: [],
              example: "Wow, did you see her deck that guy who pinched her?",
            },
            {
              definition:
                "To cause a player to run out of cards to draw and usually lose the game as a result.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(sometimes with out) To dress (someone) up, to clothe with more than ordinary elegance",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(sometimes with out) To decorate (something).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cover; to overspread.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/deck",
        "https://en.wiktionary.org/wiki/decks",
      ],
    },
  ],
  brood: [
    {
      word: "brood",
      phonetic: "/bɹuːd/",
      phonetics: [
        {
          text: "/bɹuːd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/brood-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1174522",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The young of certain animals, especially a group of young birds or fowl hatched at one time by the same mother.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The young of any egg-laying creature, especially if produced at the same time.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The eggs and larvae of social insects such as bees, ants and some wasps, especially when gathered together in special brood chambers or combs within the colony.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The children in one family; offspring.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "That which is bred or produced; breed; species.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Parentage.", synonyms: [], antonyms: [] },
            {
              definition: "Heavy waste in tin and copper ores.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To keep an egg warm to make it hatch.",
              synonyms: [],
              antonyms: [],
              example:
                "In some species of birds, both the mother and father brood the eggs.",
            },
            {
              definition:
                "To protect (something that is gradually maturing); to foster.",
              synonyms: [],
              antonyms: [],
              example:
                "Under the rock was a midshipman fish, brooding a mass of eggs.",
            },
            {
              definition:
                "(typically with about or over) To dwell upon moodily and at length, mainly alone.",
              synonyms: [],
              antonyms: [],
              example:
                "He sat brooding about the upcoming battle, fearing the outcome.",
            },
            { definition: "To be bred.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Kept or reared for breeding, said of animals.",
              synonyms: [],
              antonyms: [],
              example: "a brood mare",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/brood"],
    },
  ],
  mourn: [
    {
      word: "mourn",
      phonetic: "/mɔːn/",
      phonetics: [
        {
          text: "/mɔːn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mourn-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=86957503",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/mɔɹn/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Sorrow, grief.", synonyms: [], antonyms: [] },
            {
              definition:
                "A ring fitted upon the head of a lance to prevent wounding an adversary in tilting.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To express sadness or sorrow for; to grieve over (especially a death).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To utter in a sorrowful manner.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To wear mourning.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mourn"],
    },
  ],
  aloft: [
    {
      word: "aloft",
      phonetic: "/əˈlɑft/",
      phonetics: [
        { text: "/əˈlɑft/", audio: "" },
        { text: "/əˈlɒft/", audio: "" },
        {
          text: "/əˈlɔft/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/aloft-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2650637",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
        { text: "/əˈlɑft/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "At, to, or in the air or sky.",
              synonyms: [],
              antonyms: [],
              example: "high winds aloft",
            },
            {
              definition: "Above, overhead, in a high place; up.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In the top, at the masthead, or on the higher yards or rigging.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/aloft"],
    },
  ],
  abuse: [
    {
      word: "abuse",
      phonetic: "/əˈbjuːs/",
      phonetics: [
        { text: "/əˈbjuːs/", audio: "" },
        {
          text: "/əˈbjus/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/abuse-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=187206",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Improper treatment or usage; application to a wrong or bad purpose; an unjust, corrupt or wrongful practice or custom.",
              synonyms: ["misuse"],
              antonyms: [],
              example:
                "All abuse, whether physical, verbal, psychological or sexual, is bad.",
            },
            {
              definition: "Misuse; improper use; perversion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A delusion; an imposture; misrepresentation; deception.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Coarse, insulting speech; abusive language; language that unjustly or angrily vilifies.",
              synonyms: [
                "contumely",
                "insult",
                "invective",
                "opprobrium",
                "reproach",
                "scurrility",
              ],
              antonyms: [],
            },
            { definition: "Catachresis.", synonyms: ["abusio"], antonyms: [] },
            {
              definition: "Physical maltreatment; injury; cruel treatment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Violation; defilement; rape; forcing of undesired sexual activity by one person on another, often on a repeated basis.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "abusio",
            "contumely",
            "insult",
            "invective",
            "opprobrium",
            "reproach",
            "scurrility",
            "misuse",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/abuse"],
    },
    {
      word: "abuse",
      phonetic: "/əˈbjuːz/",
      phonetics: [
        { text: "/əˈbjuːz/", audio: "" },
        {
          text: "/əˈbjuz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/abuse-2-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=187207",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To put to a wrong use; to misapply; to use improperly; to misuse; to use for a wrong purpose or end; to pervert",
              synonyms: [],
              antonyms: [],
              example: "He abused his authority.",
            },
            {
              definition:
                "To injure; to maltreat; to hurt; to treat with cruelty, especially repeatedly.",
              synonyms: ["injure", "maltreat"],
              antonyms: [],
            },
            {
              definition:
                "To attack with coarse language; to insult; to revile; malign; to speak in an offensive manner to or about someone; to disparage.",
              synonyms: ["reproach", "revile", "vilify", "vituperate"],
              antonyms: [],
            },
            {
              definition:
                "To imbibe a drug for a purpose other than it was intended; to intentionally take more of a drug than was prescribed for recreational reasons; to take illegal drugs habitually.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To violate; defile; to rape.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Misrepresent; adulterate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To deceive; to trick; to impose on; misuse the confidence of.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Disuse.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "injure",
            "maltreat",
            "reproach",
            "revile",
            "vilify",
            "vituperate",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/abuse"],
    },
  ],
  whirl: [
    {
      word: "whirl",
      phonetic: "/ʍɪɾ(ə̯)l/",
      phonetics: [
        { text: "/ʍɪɾ(ə̯)l/", audio: "" },
        { text: "/ʍɪɾ(ə̯)l/", audio: "" },
        { text: "/wɜːl/", audio: "" },
        {
          text: "/wɝl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/whirl-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453797",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An act of whirling.",
              synonyms: [],
              antonyms: [],
              example: "She gave the top a whirl and it spun across the floor.",
            },
            {
              definition: "Something that whirls.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A confused tumult.", synonyms: [], antonyms: [] },
            {
              definition: "A rapid series of events.",
              synonyms: [],
              antonyms: [],
              example: "My life is one social whirl.",
            },
            {
              definition: "Dizziness or giddiness.",
              synonyms: [],
              antonyms: [],
              example: "My mind was in a whirl.",
            },
            {
              definition:
                "(usually following “give”) A brief experiment or trial.",
              synonyms: [],
              antonyms: [],
              example: "OK, let's give it a whirl.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To rotate, revolve, spin or turn rapidly.",
              synonyms: [],
              antonyms: [],
              example:
                "The dancer whirled across the stage, stopped, and whirled around to face the audience.",
            },
            {
              definition: "To have a sensation of spinning or reeling.",
              synonyms: [],
              antonyms: [],
              example: "My head is whirling after all that drink.",
            },
            {
              definition: "To make something or someone whirl.",
              synonyms: [],
              antonyms: [],
              example: "The dancer whirled his partner round on her toes.",
            },
            {
              definition:
                "To remove or carry quickly with, or as with, a revolving motion; to snatch.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/whirl"],
    },
  ],
  edged: [
    {
      word: "edged",
      phonetic: "/ɛdʒd/",
      phonetics: [{ text: "/ɛdʒd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To move an object slowly and carefully in a particular direction.",
              synonyms: [],
              antonyms: [],
              example: "He edged the book across the table.",
            },
            {
              definition:
                "To move slowly and carefully in a particular direction.",
              synonyms: [],
              antonyms: [],
              example: "He edged away from her.",
            },
            {
              definition:
                "(usually in the form 'just edge') To win by a small margin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To hit the ball with an edge of the bat, causing a fine deflection.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To trim the margin of a lawn where the grass meets the sidewalk, usually with an electric or gas-powered lawn edger.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To furnish with an edge; to construct an edging.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To furnish with an edge, as a tool or weapon; to sharpen.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make sharp or keen; to incite; to exasperate; to goad; to urge or egg on.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To delay one's orgasm so as to remain almost at the point of orgasm.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "That has a sharp planar surface.",
              synonyms: [],
              antonyms: [],
              example:
                "The monks were forbidden to carry edged weapons such as swords and axes.",
            },
            {
              definition:
                "Followed by with: Having an edging of a certain material, color, and so on.",
              synonyms: [],
              antonyms: [],
              example: "a coat edged with fur",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/edge",
        "https://en.wiktionary.org/wiki/edged",
      ],
    },
  ],
  ovary: [
    {
      word: "ovary",
      phonetic: "/ˈəʊvəɹi/",
      phonetics: [
        { text: "/ˈəʊvəɹi/", audio: "" },
        { text: "/ˈoʊvəɹi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A female reproductive organ, often paired, that produces ova and in mammals secretes the hormones estrogen and progesterone.",
              synonyms: [],
              antonyms: [],
              example:
                "The male testicles are homologous to the female ovaries.",
            },
            {
              definition:
                "The lower part of a pistil or carpel that bears ovules and ripens into fruit.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ovary"],
    },
  ],
  quack: [
    {
      word: "quack",
      phonetic: "/kwæk/",
      phonetics: [
        {
          text: "/kwæk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/quack-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=694447",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The sound made by a duck.",
              synonyms: [],
              antonyms: [],
              example: "Did you hear that duck make a quack?",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make a noise like a duck.",
              synonyms: [],
              antonyms: [],
              example: "Do you hear the ducks quack?",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/quack"],
    },
    {
      word: "quack",
      phonetic: "/kwæk/",
      phonetics: [
        {
          text: "/kwæk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/quack-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=694447",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A fraudulent healer or incompetent professional; especially, a doctor of medicine who makes false diagnoses or inappropriate treatment; an impostor who claims to have qualifications to practice medicine.",
              synonyms: [],
              antonyms: [],
              example: "That doctor is nothing but a lousy quack!",
            },
            { definition: "A charlatan.", synonyms: [], antonyms: [] },
            { definition: "A doctor.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["medicaster", "quacksalver"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To practice or commit quackery (fraudulent medicine).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make vain and loud pretensions.",
              synonyms: ["boast"],
              antonyms: [],
            },
          ],
          synonyms: ["boast"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Falsely presented as having medicinal powers.",
              synonyms: [],
              antonyms: [],
              example: "Don't get your hopes up; that's quack medicine!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/quack"],
    },
  ],
  heaps: [
    {
      word: "heaps",
      phonetic: "/hiːps/",
      phonetics: [
        {
          text: "/hiːps/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/heaps-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651016",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A crowd; a throng; a multitude or great number of people.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A pile or mass; a collection of things laid in a body, or thrown together so as to form an elevation.",
              synonyms: [],
              antonyms: [],
              example: "a heap of earth or stones",
            },
            {
              definition: "A great number or large quantity of things.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A data structure consisting of trees in which each node is greater than all its children.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Memory that is dynamically allocated.",
              synonyms: [],
              antonyms: [],
              example:
                "You should move these structures from the stack to the heap to avoid a potential stack overflow.",
            },
            {
              definition: "A dilapidated place or vehicle.",
              synonyms: [],
              antonyms: [],
              example: "My first car was an old heap.",
            },
            {
              definition: "A lot, a large amount",
              synonyms: [],
              antonyms: [],
              example: "Thanks a heap!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To pile in a heap.",
              synonyms: [],
              antonyms: [],
              example: "He heaped the laundry upon the bed and began folding.",
            },
            {
              definition: "To form or round into a heap, as in measuring.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To supply in great quantity.",
              synonyms: [],
              antonyms: [],
              example: "They heaped praise upon their newest hero.",
            },
          ],
          synonyms: ["amass", "heap up", "pile up"],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Very much, a lot",
              synonyms: [],
              antonyms: [],
              example: "I love him heaps.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/heap",
        "https://en.wiktionary.org/wiki/heaps",
      ],
    },
  ],
  slang: [
    {
      word: "slang",
      phonetic: "/sleɪŋ/",
      phonetics: [
        {
          text: "/sleɪŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/slang-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50423061",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Language outside of conventional usage and in the informal register.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Language that is unique to a particular profession or subject; jargon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The specialized language of a social group, sometimes used to make what is said unintelligible to those not members of the group; cant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A particular variety of slang; the slang used by a particular group.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An item of slang; a slang word or expression.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["cant", "dialect", "jargon", "lingo", "vernacular"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To vocally abuse, or shout at.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/slang"],
    },
    {
      word: "slang",
      phonetic: "/sleɪŋ/",
      phonetics: [
        {
          text: "/sleɪŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/slang-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50423061",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To throw with a circular or arcing motion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To throw with a sling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To pass a rope around (a cask, gun, etc.) preparatory to attaching a hoisting or lowering tackle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To sell, peddle, or distribute illicitly (e.g. drugs, sex, etc.).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/slang",
        "https://en.wiktionary.org/wiki/sling",
      ],
    },
    {
      word: "slang",
      phonetic: "/sleɪŋ/",
      phonetics: [
        {
          text: "/sleɪŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/slang-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50423061",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Any long, narrow piece of land; a promontory.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/slang"],
    },
    {
      word: "slang",
      phonetic: "/sleɪŋ/",
      phonetics: [
        {
          text: "/sleɪŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/slang-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50423061",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A fetter worn on the leg by a convict.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A counterfeit weight or measure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A travelling show, or one of its performances.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A hawker's license.", synonyms: [], antonyms: [] },
            { definition: "A watchchain.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/slang"],
    },
    {
      word: "slang",
      phonetic: "/sleɪŋ/",
      phonetics: [
        {
          text: "/sleɪŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/slang-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50423061",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To sell (especially illegal drugs).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/slang"],
    },
  ],
  await: [
    {
      word: "await",
      phonetic: "/əˈwɛɪt/",
      phonetics: [
        { text: "/əˈwɛɪt/", audio: "" },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/await-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769602",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A waiting for; ambush.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Watching, watchfulness, suspicious observation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To wait for.",
              synonyms: [],
              antonyms: [],
              example: "I await your reply to my letter.",
            },
            { definition: "To expect.", synonyms: [], antonyms: [] },
            {
              definition: "To be in store for; to be ready or in waiting for.",
              synonyms: [],
              antonyms: [],
              example:
                "Glorious rewards await the good in heaven; eternal suffering awaits mortal sinners in hell.",
            },
            {
              definition: "To serve or attend; to wait on, wait upon.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To watch, observe.", synonyms: [], antonyms: [] },
            {
              definition: "To wait; to stay in waiting.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "attend to",
            "service",
            "anticipate",
            "listen",
            "wait for",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/await"],
    },
  ],
  civic: [
    {
      word: "civic",
      phonetic: "/ˈsɪvɪk/",
      phonetics: [{ text: "/ˈsɪvɪk/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Of, relating to, or belonging to a city, a citizen, or citizenship; municipal or civil.",
              synonyms: [],
              antonyms: [],
              example:
                "Thousands of people came to the Civic Center to show off their civic pride.",
            },
            {
              definition:
                "Of or relating to the citizen, or of good citizenship and its rights and duties.",
              synonyms: [],
              antonyms: [],
              example: "civic duty",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/civic"],
    },
  ],
  saint: [
    {
      word: "saint",
      phonetic: "/sən(t)/",
      phonetics: [
        { text: "/sən(t)/", audio: "" },
        {
          text: "/seɪnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/saint-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769755",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person whom a church or another religious group has officially recognised as especially holy or godly; one eminent for piety and virtue.",
              synonyms: [],
              antonyms: [],
              example: "Kateri Tekakwitha was proclaimed a saint.",
            },
            {
              definition:
                "(by extension) A person with positive qualities; one who does good.",
              synonyms: [],
              antonyms: [],
              example: "Dorothy Day was a living saint.",
            },
            {
              definition: "One of the blessed in heaven.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A holy object.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["hallow"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/saint"],
    },
    {
      word: "saint",
      phonetic: "/sən(t)/",
      phonetics: [
        { text: "/sən(t)/", audio: "" },
        {
          text: "/seɪnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/saint-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769755",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To canonize, to formally recognize someone as a saint.",
              synonyms: [],
              antonyms: [],
              example:
                "Many wish to see Pope John Paul II sainted immediately.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/saint"],
    },
  ],
  bevel: [
    {
      word: "bevel",
      phonetic: "/ˈbɛvəl/",
      phonetics: [
        {
          text: "/ˈbɛvəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bevel-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=86872695",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An edge that is canted, one that is not a 90-degree angle; a chamfer.",
              synonyms: [],
              antonyms: [],
              example: "to give a bevel to the edge of a table or a stone slab",
            },
            {
              definition:
                "An instrument consisting of two rules or arms, jointed together at one end, and opening to any angle, for adjusting the surfaces of work to the same or a given inclination; a bevel square.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To give a canted edge to a surface; to chamfer.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having the slant of a bevel; slanting.",
              synonyms: [],
              antonyms: [],
              example: "a bevel angle",
            },
            {
              definition: "Morally distorted; not upright.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bevel"],
    },
  ],
  sonar: [
    {
      word: "sonar",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sonar-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50241404",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Echolocation", synonyms: [], antonyms: [] },
            {
              definition:
                "A device that uses hydrophones (in the same manner as radar) to locate objects underwater.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["SONAR"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sonar"],
    },
  ],
  aunts: [
    {
      word: "aunts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The sister or sister-in-law of one’s parent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The female cousin of one’s parent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A woman of an older generation than oneself, especially a friend of one's parents, by means of fictive kin.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Any elderly woman.", synonyms: [], antonyms: [] },
            { definition: "A procuress or bawd.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: ["nephew", "niece", "uncle"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/aunt",
        "https://en.wiktionary.org/wiki/aunts",
      ],
    },
  ],
  packs: [
    {
      word: "packs",
      phonetic: "/pæks/",
      phonetics: [{ text: "/pæks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A bundle made up and prepared to be carried; especially, a bundle to be carried on the back, but also a load for an animal, a bale.",
              synonyms: [],
              antonyms: [],
              example: "The horses carried the packs across the plain.",
            },
            {
              definition:
                "A number or quantity equal to the contents of a pack",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A multitude.",
              synonyms: [],
              antonyms: [],
              example: "a pack of complaints",
            },
            {
              definition:
                "A number or quantity of connected or similar things; a collective.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A full set of playing cards",
              synonyms: [],
              antonyms: [],
              example:
                "We were going to play cards, but nobody brought a pack.",
            },
            {
              definition:
                "The assortment of playing cards used in a particular game.",
              synonyms: [],
              antonyms: [],
              example: "cut the pack",
            },
            {
              definition:
                "A group of hounds or dogs, hunting or kept together.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A wolfpack: a number of wolves, hunting together.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A group of people associated or leagued in a bad design or practice; a gang.",
              synonyms: [],
              antonyms: [],
              example: "a pack of thieves",
            },
            {
              definition: "A group of Cub Scouts.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A shook of cask staves.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bundle of sheet iron plates for rolling simultaneously.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A large area of floating pieces of ice driven together more or less closely.",
              synonyms: [],
              antonyms: [],
              example: "The ship had to sail round the pack of ice.",
            },
            {
              definition:
                "An envelope, or wrapping, of sheets used in hydropathic practice, called dry pack, wet pack, cold pack, etc., according to the method of treatment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: ": A loose, lewd, or worthless person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A tight group of object balls in cue sports. Usually the reds in snooker.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The forwards in a rugby team (eight in Rugby Union, six in Rugby League) who with the opposing pack constitute the scrum.",
              synonyms: [],
              antonyms: [],
              example:
                "The captain had to take a man out of the pack to replace the injured fullback.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(physical) To put or bring things together in a limited or confined space, especially for storage or transport.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "(social) To cheat.", synonyms: [], antonyms: [] },
            {
              definition: "To load with a pack",
              synonyms: [],
              antonyms: [],
              example: "to pack a horse",
            },
            { definition: "To load; to encumber.", synonyms: [], antonyms: [] },
            {
              definition: "To move, send or carry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To block a shot, especially in basketball.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of the forwards in a rugby team) To play together cohesively, specially with reference to their technique in the scrum.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a drag king, transman, etc.) To wear a prosthetic penis inside one’s trousers for better verisimilitude.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["stack"],
          antonyms: ["unpack"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pack",
        "https://en.wiktionary.org/wiki/packs",
      ],
    },
  ],
  froze: [
    {
      word: "froze",
      phonetic: "/ˈfɹəʊz/",
      phonetics: [
        {
          text: "/ˈfɹəʊz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/froze-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88681559",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈfɹoʊz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "Especially of a liquid, to become solid due to low temperature.",
              synonyms: [],
              antonyms: [],
              example: "The lake froze solid.",
            },
            {
              definition:
                "To lower something's temperature to the point that it freezes or becomes hard.",
              synonyms: [],
              antonyms: [],
              example: "Don't freeze meat twice.",
            },
            {
              definition:
                "To drop to a temperature below zero degrees celsius, where water turns to ice.",
              synonyms: [],
              antonyms: [],
              example:
                "It didn't freeze this winter, but last winter was very harsh.",
            },
            {
              definition: "To be affected by extreme cold.",
              synonyms: [],
              antonyms: [],
              example:
                "Don't go outside wearing just a t-shirt; you'll freeze!",
            },
            {
              definition:
                "(of machines and software) To come to a sudden halt, stop working (functioning).",
              synonyms: [],
              antonyms: [],
              example:
                "Since the last update, the program freezes / freezes up after a few minutes of use.",
            },
            {
              definition:
                "(of people and other animals) To stop (become motionless) or be stopped due to attentiveness, fear, surprise, etc.",
              synonyms: [],
              antonyms: [],
              example:
                "Despite all of the rehearsals, I froze up as soon as I got on stage.",
            },
            {
              definition: "To cause someone to become motionless.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To lose or cause to lose warmth of feeling; to shut out; to ostracize.",
              synonyms: [],
              antonyms: [],
              example:
                "Over time, he froze towards her, and ceased to react to her friendly advances.",
            },
            {
              definition:
                "To cause loss of animation or life in, from lack of heat; to give the sensation of cold to; to chill.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To prevent the movement or liquidation of a person's financial assets",
              synonyms: [],
              antonyms: [],
              example: "The court froze the criminal's bank account.",
            },
            {
              definition:
                "Of prices, spending etc., to keep at the same level, without any increase.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "solidify",
            "halt",
            "immobilize",
            "freeze up",
            "grind to a halt",
            "hang",
            "lock up",
            "seize",
            "seize up",
          ],
          antonyms: ["defrost", "liquify", "unfreeze"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/freeze",
        "https://en.wiktionary.org/wiki/froze",
      ],
    },
  ],
  tonic: [
    {
      word: "tonic",
      phonetic: "/ˈtɒnɪk/",
      phonetics: [{ text: "/ˈtɒnɪk/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A substance with medicinal properties intended to restore or invigorate.",
              synonyms: [],
              antonyms: [],
              example:
                "We used to brew a tonic from a particular kind of root.",
            },
            { definition: "Tonic water.", synonyms: [], antonyms: [] },
            {
              definition:
                "(Massachusetts) Any of various carbonated, non-alcoholic beverages; soda pop.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Someone or something that revitalises or reinvigorates.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Pertaining to tension, especially of muscles.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Restorative, curative or invigorating.",
              synonyms: [],
              antonyms: [],
              example:
                "The arrival of the new members had a tonic effect on the team.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tonic"],
    },
    {
      word: "tonic",
      phonetic: "/ˈtɒnɪk/",
      phonetics: [{ text: "/ˈtɒnɪk/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The first note of a diatonic scale; the keynote.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The triad built on the tonic note.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A tonic element or letter; a vowel or a diphthong.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Pertaining to or based upon the first note of a diatonic scale.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Pertaining to the accent or stress in a word or in speech.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of or relating to tones or sounds; specifically being or relating to a speech sound made with tone unmixed and undimmed by obstruction, i.e. a vowel or diphthong.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tonic"],
    },
  ],
  corps: [
    {
      word: "corps",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A battlefield formation composed of two or more divisions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An organized group of people united by a common purpose.",
              synonyms: [],
              antonyms: [],
              example: "White House press corps",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/corps"],
    },
    {
      word: "corps",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A non-commissioned officer army rank with NATO code OR-4. The rank below a sergeant but above a lance corporal and private.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A non-commissioned officer rank in the police force, below a sergeant but above a private or patrolman.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A worker in charge of the wagonway, reporting to the deputy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The white linen cloth on which the elements of the Eucharist are placed; a communion cloth.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A body corporate, created by law or under authority of law, having a continuous existence independent of the existences of its members, and powers and liabilities distinct from those of its members.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The municipal governing body of a borough or city.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In Fascist Italy, a joint association of employers' and workers' representatives.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A protruding belly; a paunch.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["public limited company"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/corp",
        "https://en.wiktionary.org/wiki/corp.",
        "https://en.wiktionary.org/wiki/corporal",
        "https://en.wiktionary.org/wiki/corporation",
        "https://en.wiktionary.org/wiki/corps",
      ],
    },
  ],
  swarm: [
    {
      word: "swarm",
      phonetic: "/swɔːm/",
      phonetics: [
        { text: "/swɔːm/", audio: "" },
        {
          text: "/swɔɹm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/swarm-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453749",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A large number of insects, especially when in motion or (for bees) migrating to a new colony.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A mass of people, animals or things in motion or turmoil.",
              synonyms: [],
              antonyms: [],
              example: "a swarm of meteorites",
            },
            {
              definition:
                "A group of nodes sharing the same torrent in a BitTorrent network.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To move as a swarm.", synonyms: [], antonyms: [] },
            {
              definition: "To teem, or be overrun with insects, people, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fill a place as a swarm.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To overwhelm as by an opposing army.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To climb by gripping with arms and legs alternately.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To breed multitudes.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/swarm"],
    },
  ],
  frank: [
    {
      word: "frank",
      phonetic: "/fɹæŋk/",
      phonetics: [
        {
          text: "/fɹæŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/frank-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1230640",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Free postage, a right exercised by governments (usually with definite article).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The notice on an envelope where a stamp would normally be found.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To place a frank on an envelope.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To exempt from charge for postage, as a letter, package, or packet, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To send by public conveyance free of expense.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Honest, especially in a manner that seems slightly blunt; candid; not reserved or disguised.",
              synonyms: [],
              antonyms: [],
              example: "May I be frank with you?",
            },
            {
              definition: "Unmistakable, clinically obvious, self-evident",
              synonyms: [],
              antonyms: [],
              example:
                "The research probes whether treating pre-diabetes with metformin can prevent progression to frank diabetes.",
            },
            {
              definition: "Unbounded by restrictions, limitations, etc.; free.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Liberal; generous; profuse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Unrestrained; loose; licentious.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/frank"],
    },
    {
      word: "frank",
      phonetic: "/fɹæŋk/",
      phonetics: [
        {
          text: "/fɹæŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/frank-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1230640",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A hot dog or sausage.",
              synonyms: ["frankfurt", "frankfurter"],
              antonyms: [],
              example: "Buy a package of franks for the barbecue.",
            },
          ],
          synonyms: ["frankfurt", "frankfurter"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/frank"],
    },
    {
      word: "frank",
      phonetic: "/fɹæŋk/",
      phonetics: [
        {
          text: "/fɹæŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/frank-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1230640",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The grey heron.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/frank"],
    },
    {
      word: "frank",
      phonetic: "/fɹæŋk/",
      phonetics: [
        {
          text: "/fɹæŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/frank-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1230640",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A pigsty.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To shut up in a frank or sty; to pen up; hence, to cram; to fatten.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/frank"],
    },
  ],
  repay: [
    {
      word: "repay",
      phonetic: "/ɹiˈpeɪ/",
      phonetics: [
        {
          text: "/ɹiˈpeɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/repay-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2454254",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To pay back.",
              synonyms: [],
              antonyms: [],
              example:
                "I finally repaid my student loans, just before sending my kids to college.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/repay"],
    },
  ],
  gaunt: [
    {
      word: "gaunt",
      phonetic: "/ɡɑːnt/",
      phonetics: [
        {
          text: "/ɡɑːnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gaunt-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=508870",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Lean, angular and bony",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Haggard, drawn and emaciated",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Bleak, barren and desolate",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["scraggy", "scrawny", "skinny"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gaunt"],
    },
  ],
  wired: [
    {
      word: "wired",
      phonetic: "/waɪəd/",
      phonetics: [
        { text: "/waɪəd/", audio: "" },
        {
          text: "/waɪɹd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wired-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=278945",
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To fasten with wire, especially with reference to wine bottles, corks, or fencing.",
              synonyms: [],
              antonyms: [],
              example: "We need to wire that hole in the fence.",
            },
            {
              definition: "To string on a wire.",
              synonyms: [],
              antonyms: [],
              example: "wire beads",
            },
            {
              definition: "To equip with wires for use with electricity.",
              synonyms: [],
              antonyms: [],
              example: "Do you know how to wire a plug?",
            },
            {
              definition:
                "To add something into an electrical system by means of wiring; to incorporate or include something.",
              synonyms: [],
              antonyms: [],
              example: "I'll just wire your camera to the computer screen.",
            },
            {
              definition:
                "(usually passive) To fix or predetermine (someone's personality or behaviour) in a particular way.",
              synonyms: [],
              antonyms: [],
              example:
                "There's no use trying to get Sarah to be less excitable. That's just the way she's wired.",
            },
            {
              definition:
                "To send a message or monetary funds to another person through a telecommunications system, formerly predominantly by telegraph.",
              synonyms: [],
              antonyms: [],
              example:
                "The detective wired ahead, hoping that the fugitive would be caught at the railway station.",
            },
            {
              definition:
                "To make someone tense or psyched up. See also adjective wired.",
              synonyms: [],
              antonyms: [],
              example: "Coffee late at night wires me good and proper.",
            },
            {
              definition: "To install eavesdropping equipment.",
              synonyms: [],
              antonyms: [],
              example: "We wired the suspect's house.",
            },
            {
              definition: "To snare by means of a wire or wires.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To place (a ball) so that the wire of a wicket prevents a successful shot.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["electrify", "cable", "telegraph"],
          antonyms: ["unwire"],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Equipped with wires, so as to connect to a power source or to other electric or electronic equipment; connected by wires.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Equipped with hidden electronic eavesdropping devices.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Reinforced, supported, tied or bound with wire.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Very excited, overstimulated; high-strung.",
              synonyms: [],
              antonyms: [],
              example: "After three cups of coffee she was too wired to sleep.",
            },
            { definition: "Having wiry feathers.", synonyms: [], antonyms: [] },
            {
              definition:
                "Being a pair in seven card stud with one face up and one face down.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Being three of a kind as the first three cards in seven card stud.",
              synonyms: [],
              antonyms: [],
              example: "I was dealt three of a kind, wired.",
            },
            {
              definition:
                "(of people or communities) Connected to the Internet; online.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["corded"],
          antonyms: ["wireless"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wire",
        "https://en.wiktionary.org/wiki/wired",
      ],
    },
  ],
  niece: [
    {
      word: "niece",
      phonetic: "/niːs/",
      phonetics: [
        { text: "/niːs/", audio: "" },
        {
          text: "/nis/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/niece-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=825498",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                'A daughter of one’s sibling, brother-in-law, or sister-in-law; either the daughter of one\'s brother ("fraternal niece"), or of one\'s sister ("sororal niece").',
              synonyms: [],
              antonyms: [],
              example: "My niece just celebrated her 15th birthday.",
            },
          ],
          synonyms: [],
          antonyms: ["aunt", "uncle", "nephew"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/niece"],
    },
  ],
  cello: [
    {
      word: "cello",
      phonetic: "/ˈtʃɛləʊ/",
      phonetics: [
        { text: "/ˈtʃɛləʊ/", audio: "" },
        {
          text: "/ˈtʃɛloʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cello-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=7490885",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A large stringed instrument of the violin family with four strings, tuned from lowest to highest C-G-D-A, and played with a bow, also possessing an endpin to support the instrument's weight.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["violoncello"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cello"],
    },
    {
      word: "cello",
      phonetic: "/ˈsɛləʊ/",
      phonetics: [
        { text: "/ˈsɛləʊ/", audio: "" },
        { text: "/ˈsɛloʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Cellophane", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cello"],
    },
  ],
  needy: [
    {
      word: "needy",
      phonetic: "/ˈniːdi/",
      phonetics: [
        {
          text: "/ˈniːdi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/needy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1785214",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "In need; poor.",
              synonyms: [],
              antonyms: [],
              example:
                "Needy people want to give too, but have few material goods to offer.",
            },
            {
              definition:
                "Desiring constant affirmation; lacking self-confidence.",
              synonyms: [],
              antonyms: [],
              example:
                "It's emotionally exhausting to be around her because she's so needy.",
            },
            { definition: "Needful; necessary.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: ["confident", "self-sufficient"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/needy"],
    },
  ],
  chuck: [
    {
      word: "chuck",
      phonetic: "/tʃʌk/",
      phonetics: [
        {
          text: "/tʃʌk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chuck-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899841",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Meat from the shoulder of a cow or other animal.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Food.", synonyms: [], antonyms: [] },
            {
              definition:
                "A mechanical device that holds an object firmly in place, for example holding a drill bit in a high-speed rotating drill or grinder.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To place in a chuck, or hold by means of a chuck, as in turning.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To bore or turn (a hole) in a revolving piece held in a chuck.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chuck"],
    },
    {
      word: "chuck",
      phonetic: "/tʃʌk/",
      phonetics: [
        {
          text: "/tʃʌk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chuck-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899841",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A chicken, a hen.", synonyms: [], antonyms: [] },
            { definition: "A clucking sound.", synonyms: [], antonyms: [] },
            {
              definition: "A friend or close acquaintance; term of endearment.",
              synonyms: [],
              antonyms: [],
              example: "Are you all right, chuck?",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make a clucking sound.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To call, as a hen her chickens.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chuck"],
    },
    {
      word: "chuck",
      phonetic: "/tʃʌk/",
      phonetics: [
        {
          text: "/tʃʌk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chuck-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899841",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A gentle touch or tap.",
              synonyms: [],
              antonyms: [],
              example: "She gave him an affectionate chuck under the chin.",
            },
            { definition: "A casual throw.", synonyms: [], antonyms: [] },
            {
              definition: "A throw, an incorrect bowling action.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An act or instance of vomiting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "On rhythm guitar or mandolin etc., the muting of a chord by lifting the fretting fingers immediately after strumming, producing a percussive effect.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To touch or tap gently.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To throw, especially in a careless or inaccurate manner.",
              synonyms: [],
              antonyms: [],
              example: "Chuck that magazine to me, would you?",
            },
            {
              definition: "To throw; to bowl with an incorrect action.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To discard, to throw away.",
              synonyms: [],
              antonyms: [],
              example: "This food's gone off - you'd better chuck it.",
            },
            {
              definition: "To jilt; to dump.",
              synonyms: [],
              antonyms: [],
              example: "She's chucked me for another man!",
            },
            { definition: "To vomit.", synonyms: [], antonyms: [] },
            {
              definition: "To leave; to depart; to bounce.",
              synonyms: [],
              antonyms: [],
              example: "Let's chuck.",
            },
            { definition: "To chuckle; to laugh.", synonyms: [], antonyms: [] },
            {
              definition:
                "On rhythm guitar or mandolin etc.: to mute a chord by lifting the fretting fingers immediately after strumming, producing a percussive effect.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chuck"],
    },
    {
      word: "chuck",
      phonetic: "/tʃʌk/",
      phonetics: [
        {
          text: "/tʃʌk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chuck-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899841",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A rodent of the family Sciuridae, belonging to the group of large ground squirrels known as marmots, Marmota monax.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["groundhog", "land-beaver", "moonack", "whistle pig"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/chuck",
        "https://en.wiktionary.org/wiki/woodchuck",
      ],
    },
    {
      word: "chuck",
      phonetic: "/tʃʌk/",
      phonetics: [
        {
          text: "/tʃʌk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chuck-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899841",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A small pebble.", synonyms: [], antonyms: [] },
            {
              definition: "(in the plural) Money.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["chuckie", "chucky", "chuckiestone", "chuckstone"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chuck"],
    },
  ],
  stony: [
    {
      word: "stony",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stony-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75701549",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈstəʊni/", audio: "" },
        { text: "/ˈstoʊni/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "As hard as stone.",
              synonyms: ["rock-hard"],
              antonyms: ["soft"],
            },
            {
              definition: "Containing or made up of stones.",
              synonyms: ["pebbly", "rocky", "shingly"],
              antonyms: [],
              example: "a stony path",
            },
            {
              definition: "Of a person, lacking warmth and emotion.",
              synonyms: [
                "cold",
                "cool",
                "hard-hearted",
                "heartless",
                "impassive",
                "unemotional",
                "unfeeling",
              ],
              antonyms: ["passionate", "warm"],
            },
            {
              definition:
                "Of an action such as a look, showing no warmth of emotion.",
              synonyms: ["cold", "cool", "frosty", "unwelcoming"],
              antonyms: ["warm", "welcoming"],
              example: "She gave him a stony reception.",
            },
            {
              definition: "Short for stony broke: without any money.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "cold",
            "cool",
            "frosty",
            "unwelcoming",
            "cold",
            "cool",
            "hard-hearted",
            "heartless",
            "impassive",
            "unemotional",
            "unfeeling",
            "pebbly",
            "rocky",
            "shingly",
            "rock-hard",
          ],
          antonyms: ["passionate", "warm", "soft", "warm", "welcoming"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stony"],
    },
  ],
  media: [
    {
      word: "media",
      phonetic: "/ˈmiːdɪə/",
      phonetics: [
        { text: "/ˈmiːdɪə/", audio: "" },
        { text: "/ˈmiːdi.ə/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The middle layer of the wall of a blood vessel or lymph vessel which is composed of connective and muscular tissue.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A voiced stop consonant.",
              synonyms: [],
              antonyms: ["tenuis"],
            },
            {
              definition:
                "One of the major veins of the insect wing, between the radius and the cubitus",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An ant specialized as a forager in a leaf-cutter ant colony.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["M"],
          antonyms: ["tenuis"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/media"],
    },
    {
      word: "media",
      phonetic: "/ˈmiːdɪə/",
      phonetics: [
        { text: "/ˈmiːdɪə/", audio: "" },
        { text: "/ˈmiːdi.ə/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Means and institutions for publishing and broadcasting information.",
              synonyms: [],
              antonyms: [],
              example:
                "As a result of the rise of, first, television news and entertainment media and, second, web-based media, traditional print-based media has declined in popularity.",
            },
            {
              definition:
                "(usually with a definite article; often used as uncountable) The journalists and other professionals who comprise the mass communication industry.",
              synonyms: [],
              antonyms: [],
              example:
                "Some celebrities dislike press conferences, where the media bombards them with questions.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(plural media or mediums) The chemistry of the surrounding environment, e.g. solid, liquid, gas, vacuum, or a specific substance such as a solvent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(plural media or mediums) The materials or empty space through which signals, waves or forces pass.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(plural media or mediums) A format for communicating or presenting information.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(plural media or mediums) The materials used to finish a workpiece using a mass finishing or abrasive blasting process.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(plural media or mediums) A nutrient solution for the growth of cells in vitro.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(plural media or mediums) The means, channel, or agency by which an aim is achieved.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(plural mediums or media) A liquid base which carries pigment in paint.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(plural mediums or media) A tool used for painting or drawing.",
              synonyms: [],
              antonyms: [],
              example:
                "Acrylics, oils, charcoal, and gouache are all mediums I used in my painting.",
            },
            {
              definition:
                "(plural mediums) Someone who supposedly conveys information from the spirit world.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(plural mediums) Anything having a measurement intermediate between extremes, such as a garment or container.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(plural mediums) A person whom garments or apparel of intermediate size fit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(plural mediums) A half-pint serving of Guinness (or other stout in some regions).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A middle place or degree.",
              synonyms: [],
              antonyms: [],
              example: "a happy medium",
            },
            {
              definition: "An average; sometimes the mathematical mean.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The mean or middle term of a syllogism, that by which the extremes are brought into connection.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/media",
        "https://en.wiktionary.org/wiki/medium",
      ],
    },
  ],
  surge: [
    {
      word: "surge",
      phonetic: "/sɜːdʒ/",
      phonetics: [
        { text: "/sɜːdʒ/", audio: "" },
        {
          text: "/sɝdʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/surge-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=179895",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A sudden transient rush, flood or increase.",
              synonyms: [],
              antonyms: [],
              example: "He felt a surge of excitement.",
            },
            {
              definition:
                "The maximum amplitude of a vehicle's forward/backward oscillation",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sudden electrical spike or increase of voltage and current.",
              synonyms: [],
              antonyms: [],
              example:
                "A power surge at that generator created a blackout across the whole district.",
            },
            {
              definition: "The swell or heave of the sea. (FM 55-501).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A spring; a fountain.", synonyms: [], antonyms: [] },
            {
              definition:
                "The tapered part of a windlass barrel or a capstan, upon which the cable surges, or slips.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["inrush"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To rush, flood, or increase suddenly.",
              synonyms: [],
              antonyms: [],
              example: "Toaster sales surged last year.",
            },
            {
              definition: "To accelerate forwards, particularly suddenly.",
              synonyms: [],
              antonyms: [],
              example: "A ship surges forwards, sways sideways and heaves up.",
            },
            { definition: "To slack off a line.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/surge"],
    },
  ],
  hurts: [
    {
      word: "hurts",
      phonetic: "/hɜːts/",
      phonetics: [
        { text: "/hɜːts/", audio: "" },
        { text: "/hɝts/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An emotional or psychological humiliation or bad experience.",
              synonyms: [],
              antonyms: [],
              example: "how to overcome old hurts of the past",
            },
            {
              definition: "A bodily injury causing pain; a wound or bruise.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Injury; damage; detriment; harm",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A roundel azure (blue circular spot).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A band on a trip-hammer helve, bearing the trunnions.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A husk.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To be painful.",
              synonyms: [],
              antonyms: [],
              example:
                "Does your leg still hurt? / It is starting to feel better.",
            },
            {
              definition: "To cause (a creature) physical pain and/or injury.",
              synonyms: [],
              antonyms: [],
              example: "If anybody hurts my little brother, I will get upset.",
            },
            {
              definition: "To cause (somebody) emotional pain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To undermine, impede, or damage.",
              synonyms: [],
              antonyms: [],
              example:
                "This latest gaffe hurts the legislator’s reelection prospects still further.",
            },
          ],
          synonyms: ["smart", "dere", "injure", "wound"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hurt",
        "https://en.wiktionary.org/wiki/hurts",
      ],
    },
  ],
  repel: [
    {
      word: "repel",
      phonetic: "/ɹɪˈpɛl/",
      phonetics: [
        {
          text: "/ɹɪˈpɛl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/repel-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75857811",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ɹɪˈpɛl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/repel-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780323",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To turn (someone) away from a privilege, right, job, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To reject, put off (a request, demand etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To ward off (a malignant influence, attack etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To drive back (an assailant, advancing force etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To force away by means of a repulsive force.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause repulsion or dislike in; to disgust.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To save (a shot).", synonyms: [], antonyms: [] },
          ],
          synonyms: ["withdrive"],
          antonyms: ["attract"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/repel"],
    },
  ],
  husky: [
    {
      word: "husky",
      phonetic: "/ˈhʌs.ki/",
      phonetics: [
        {
          text: "/ˈhʌs.ki/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/husky-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89322303",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈhʌs.ki/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "(of a voice) Hoarse and rough-sounding.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Burly, stout.", synonyms: [], antonyms: [] },
            {
              definition: "Abounding with husks; consisting of husks.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/husky"],
    },
    {
      word: "husky",
      phonetic: "/ˈhʌs.ki/",
      phonetics: [
        {
          text: "/ˈhʌs.ki/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/husky-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89322303",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈhʌs.ki/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Any of several breeds of dogs used as sled dogs.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["polar dog"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/husky"],
    },
  ],
  dated: [
    {
      word: "dated",
      phonetic: "/ˈdeɪtɪd/",
      phonetics: [
        {
          text: "/ˈdeɪtɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dated-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453942",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To note the time or place of writing or executing; to express in an instrument the time of its execution.",
              synonyms: [],
              antonyms: [],
              example: "to date a letter, a bond, a deed, or a charter",
            },
            {
              definition:
                "To note or fix the time of (an event); to give the date of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To determine the age of something.",
              synonyms: [],
              antonyms: [],
              example: "to date the building of the pyramids",
            },
            {
              definition: "To take (someone) on a date, or a series of dates.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) To have a steady relationship with; to be romantically involved with.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) To have a steady relationship with each other; to be romantically involved with each other.",
              synonyms: [],
              antonyms: [],
              example:
                "They met a couple of years ago, but have been dating for about five months.",
            },
            {
              definition:
                "To make or become old, especially in such a way as to fall out of fashion, become less appealing or attractive, etc.",
              synonyms: [],
              antonyms: [],
              example:
                "The comedian dated himself by making quips about bands from the 1960s.",
            },
            {
              definition:
                "(with from) To have beginning; to begin; to be dated or reckoned.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["age", "elden", "obsolesce", "go out", "see"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Marked with a date.",
              synonyms: [],
              antonyms: [],
              example:
                "The first dated entry in the diary was from October 1922.",
            },
            {
              definition: "Outdated.",
              synonyms: [],
              antonyms: [],
              example: '"Omnibus" is a dated term for a bus.',
            },
            {
              definition:
                "Anachronistic; being obviously inappropriate for its present context.",
              synonyms: [],
              antonyms: [],
              example:
                "Calling a happy person gay seems awfully dated nowadays; people will assume you mean something else.",
            },
            {
              definition: "No longer fashionable.",
              synonyms: [],
              antonyms: [],
              example: "Slang can become dated very quickly.",
            },
          ],
          synonyms: [
            "anachronous",
            "démodé",
            "old hat",
            "old-fashioned",
            "outdated",
            "retro",
          ],
          antonyms: ["undated", "a la mode", "trendy", "current", "up-to-date"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/date",
        "https://en.wiktionary.org/wiki/dated",
      ],
    },
  ],
  hunts: [
    {
      word: "hunts",
      phonetic: "/hʌnts/",
      phonetics: [{ text: "/hʌnts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The act of hunting.", synonyms: [], antonyms: [] },
            { definition: "A hunting expedition.", synonyms: [], antonyms: [] },
            {
              definition:
                "An organization devoted to hunting, or the people belonging to it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A pack of hunting dogs.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To find or search for an animal in the wild with the intention of killing the animal for its meat or for sport.",
              synonyms: [],
              antonyms: [],
              example:
                "Her uncle will go out and hunt for deer, now that it is open season.",
            },
            {
              definition: "To try to find something; search (for).",
              synonyms: [],
              antonyms: [],
              example: "The little girl was hunting for shells on the beach.",
            },
            {
              definition: "To drive; to chase; with down, from, away, etc.",
              synonyms: [],
              antonyms: [],
              example: "He was hunted from the parish.",
            },
            {
              definition: "To use or manage (dogs, horses, etc.) in hunting.",
              synonyms: [],
              antonyms: [],
              example: "Did you hunt that pony last week?",
            },
            {
              definition: "To use or traverse in pursuit of game.",
              synonyms: [],
              antonyms: [],
              example: "He hunts the woods, or the country.",
            },
            {
              definition:
                "(bell-ringing) To move or shift the order of (a bell) in a regular course of changes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(bell-ringing) To shift up and down in order regularly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be in a state of instability of movement or forced oscillation, as a governor which has a large movement of the balls for small change of load, an arc-lamp clutch mechanism which moves rapidly up and down with variations of current, etc.; also, to seesaw, as a pair of alternators working in parallel.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hunt",
        "https://en.wiktionary.org/wiki/hunts",
      ],
    },
  ],
  mists: [
    {
      word: "mists",
      phonetic: "/mɪsts/",
      phonetics: [
        {
          text: "/mɪsts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mists-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453608",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Water or other liquid finely suspended in air.",
              synonyms: [],
              antonyms: [],
              example: "It was difficult to see through the morning mist.",
            },
            {
              definition: "A layer of fine droplets or particles.",
              synonyms: [],
              antonyms: [],
              example: "There was an oily mist on the lens.",
            },
            {
              definition: "Anything that dims, darkens, or hinders vision.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To form mist.",
              synonyms: [],
              antonyms: [],
              example: "It's misting this morning.",
            },
            {
              definition: "To spray fine droplets on, particularly of water.",
              synonyms: [],
              antonyms: [],
              example: "I mist my tropical plants every morning.",
            },
            {
              definition: "To cover with a mist.",
              synonyms: [],
              antonyms: [],
              example: "The lens was misted.",
            },
            {
              definition: "(of the eyes) To be covered by tears.",
              synonyms: [],
              antonyms: [],
              example: "My eyes misted when I remembered what had happened.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mist",
        "https://en.wiktionary.org/wiki/mists",
      ],
    },
  ],
  exert: [
    {
      word: "exert",
      phonetic: "/ɪɡˈzɜːt/",
      phonetics: [
        { text: "/ɪɡˈzɜːt/", audio: "" },
        {
          text: "/ɪɡˈzɝt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/exert-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=16197748",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To put in vigorous action.",
              synonyms: [],
              antonyms: [],
              example: "I exerted myself in today's training.",
            },
            {
              definition:
                "To make use of, to apply, especially of something non-material.",
              synonyms: [],
              antonyms: [],
              example:
                "He considered exerting his influence on John to gain an advantage for himself.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/exert"],
    },
  ],
  dries: [
    {
      word: "dries",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The process by which something is dried.",
              synonyms: [],
              antonyms: [],
              example:
                "This towel is still damp: I think it needs another dry.",
            },
            {
              definition: "A prohibitionist (of alcoholic beverages).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: '(with "the") The dry season.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An area of waterless country.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(UK politics) A radical or hard-line Conservative; especially, one who supported the policies of British Prime Minister Margaret Thatcher in the 1980s.",
              synonyms: [],
              antonyms: ["wet"],
            },
          ],
          synonyms: [],
          antonyms: ["wet"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To lose moisture.",
              synonyms: [],
              antonyms: [],
              example: "The clothes dried on the line.",
            },
            {
              definition: "To remove moisture from.",
              synonyms: [],
              antonyms: [],
              example: "Devin dried her eyes with a handkerchief.",
            },
            { definition: "To be thirsty.", synonyms: [], antonyms: [] },
            {
              definition: "To exhaust; to cause to run dry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "For an actor to forget his or her lines while performing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dries",
        "https://en.wiktionary.org/wiki/dry",
      ],
    },
  ],
  mates: [
    {
      word: "mates",
      phonetic: "/meɪts/",
      phonetics: [{ text: "/meɪts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The conclusive victory in a game of chess that occurs when an opponent's king is threatened with unavoidable capture.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) Any losing situation with no escape; utter defeat.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To put the king of an opponent into checkmate.",
              synonyms: [],
              antonyms: [],
              example: "That jerk checkmated me in four moves!",
            },
            {
              definition:
                "(by extension) To place in a losing situation that has no escape.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A fellow, comrade, colleague, partner or someone with whom something is shared, e.g. shipmate, classmate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(especially of a non-human animal) A breeding partner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A friend, usually of the same sex.",
              synonyms: [],
              antonyms: [],
              example: "He's my best mate.",
            },
            {
              definition:
                "Friendly term of address to a stranger, usually male, of similar age",
              synonyms: [],
              antonyms: [],
              example: "Excuse me, mate, have you got the time?",
            },
            {
              definition:
                "In naval ranks, a non-commissioned officer or his subordinate (e.g. Boatswain's Mate, Gunner's Mate, Sailmaker's Mate, etc).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A ship's officer, subordinate to the master on a commercial ship.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A first mate.", synonyms: [], antonyms: [] },
            {
              definition:
                "A technical assistant in certain trades (e.g. gasfitter's mate, plumber's mate); sometimes an apprentice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The other member of a matched pair of objects.",
              synonyms: [],
              antonyms: [],
              example:
                "I found one of the socks I wanted to wear, but I couldn't find its mate.",
            },
            {
              definition: "A suitable companion; a match; an equal.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["buddy", "fellow", "fere", "friend", "partner", "sir"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To match, fit together without space between.",
              synonyms: [],
              antonyms: [],
              example: "The pieces of the puzzle mate perfectly.",
            },
            { definition: "To copulate.", synonyms: [], antonyms: [] },
            {
              definition: "To pair in order to raise offspring",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To arrange in matched pairs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To introduce (animals) together for the purpose of breeding.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of an animal) To copulate with.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To marry; to match (a person).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To match oneself against; to oppose as equal; to compete with.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fit (objects) together without space between.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To move (a space shuttle orbiter) onto the back of an aircraft that can carry it.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["couple", "match", "pair"],
          antonyms: ["demate"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To confuse; to confound.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The abovementioned plant; the leaves and shoots used for the tea",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Friendly; having a friendship (with).",
              synonyms: [],
              antonyms: [],
              example: "He is mates with my brother.",
            },
          ],
          synonyms: ["chummy", "chums", "friendly", "friends", "pally", "pals"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An evergreen tree, Ilex paraquariensis, native to South America, cultivated for its leaves.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A beverage, resembling tea, made from the dried leaves of this plant.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A cup of this drink.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["maté tea", "yerba mate", "yerba maté"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/checkmate",
        "https://en.wiktionary.org/wiki/mat%C3%A9",
        "https://en.wiktionary.org/wiki/mate",
        "https://en.wiktionary.org/wiki/mates",
      ],
    },
  ],
  sworn: [
    {
      word: "sworn",
      phonetic: "/swɔːn/",
      phonetics: [
        { text: "/swɔːn/", audio: "" },
        {
          text: "/swɔɹn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sworn-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2454331",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To take an oath, to promise.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To use offensive, profane, or obscene language.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Given or declared under oath.",
              synonyms: [],
              antonyms: [],
              example: "His sworn statement convinced the judge.",
            },
            {
              definition: "Bound as though by an oath.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Ardent, devout.",
              synonyms: [],
              antonyms: [],
              example: "a sworn foe",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/swear",
        "https://en.wiktionary.org/wiki/sworn",
      ],
    },
  ],
  baker: [
    {
      word: "baker",
      phonetic: "/ˈbeɪ.kə(ɹ)/",
      phonetics: [
        { text: "/ˈbeɪ.kə(ɹ)/", audio: "" },
        {
          text: "/ˈbeɪ.kɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/baker-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769608",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person who bakes and sells bread, cakes and similar items.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A portable oven for baking.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dougher"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/baker"],
    },
  ],
  spice: [
    {
      word: "spice",
      phonetic: "/spaɪs/",
      phonetics: [
        {
          text: "/spaɪs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spice-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=659543",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Aromatic or pungent plant matter (usually dried) used to season or flavour food.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Appeal, interest; an attribute that makes something appealing, interesting, or engaging.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A synthetic cannabinoid drug.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Sweets, candy.", synonyms: [], antonyms: [] },
            { definition: "Species; kind.", synonyms: [], antonyms: [] },
            {
              definition: "A characteristic touch or taste; smack; flavour.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An aromatic odour.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To add spice or spices to; season.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To spice up.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spice"],
    },
  ],
  oasis: [
    {
      word: "oasis",
      phonetic: "/əʊˈeɪsəs/",
      phonetics: [
        { text: "/əʊˈeɪsəs/", audio: "" },
        {
          text: "/oʊˈeɪsəs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/oasis-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=49883391",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A spring of fresh water, surrounded by a fertile region of vegetation, in a desert.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A quiet, peaceful place or situation separated from surrounding noise or bustle.",
              synonyms: [],
              antonyms: [],
              example: "The park was an oasis in the middle of the busy city.",
            },
          ],
          synonyms: ["island", "refuge"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/oasis"],
    },
  ],
  boils: [
    {
      word: "boils",
      phonetic: "/bɔɪlz/",
      phonetics: [{ text: "/bɔɪlz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A localized accumulation of pus in the skin, resulting from infection.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "abscess",
            "carbuncle",
            "cyst",
            "furuncle",
            "pimple",
            "pustule",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The point at which fluid begins to change to a vapour.",
              synonyms: [],
              antonyms: [],
              example: "Add the noodles when the water comes to the boil.",
            },
            {
              definition: "A dish of boiled food, especially based on seafood.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The collective noun for a group of hawks.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(of liquids) To heat to the point where it begins to turn into a gas.",
              synonyms: [],
              antonyms: [],
              example: "Boil some water in a pan.",
            },
            {
              definition: "To cook in boiling water.",
              synonyms: [],
              antonyms: [],
              example: "Boil the eggs for three minutes.",
            },
            {
              definition: "(of liquids) To begin to turn into a gas, seethe.",
              synonyms: [],
              antonyms: [],
              example: "Pure water boils at 100 degrees Celsius.",
            },
            {
              definition:
                "To bring to a boil, to heat so as to cause the contents to boil.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(used only in progressive tenses, of weather) To be uncomfortably hot.",
              synonyms: [],
              antonyms: [],
              example: "It’s boiling outside!",
            },
            {
              definition:
                "(used only in progressive tenses) To feel uncomfortably hot.",
              synonyms: [],
              antonyms: [],
              example: "I’m boiling in here – could you open the window?",
            },
            {
              definition: "To form, or separate, by boiling or evaporation.",
              synonyms: [],
              antonyms: [],
              example: "to boil sugar or salt",
            },
            {
              definition: "To steep or soak in warm water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be agitated like boiling water; to bubble; to effervesce.",
              synonyms: [],
              antonyms: [],
              example: "the boiling waves of the sea",
            },
            {
              definition:
                "To be moved or excited with passion; to be hot or fervid.",
              synonyms: [],
              antonyms: [],
              example: "His blood boils with anger.",
            },
          ],
          synonyms: [
            "plaw",
            "seethe",
            "well",
            "be baking",
            "be seething",
            "be stewing",
            "be baking",
            "be scorching",
            "be sweltering",
          ],
          antonyms: ["condense", "be freezing", "be freezing"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/boil",
        "https://en.wiktionary.org/wiki/boils",
      ],
    },
  ],
  spurs: [
    {
      word: "spurs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To ask, to inquire", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A rigid implement, often roughly y-shaped, that is fixed to one's heel for the purpose of prodding a horse. Often worn by, and emblematic of, the cowboy or the knight.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A jab given with the spurs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Anything that inspires or motivates, as a spur does a horse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An appendage or spike pointing rearward, near the foot, for instance that of a rooster.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any protruding part connected at one end, for instance a highway that extends from another highway into a city.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Roots, tree roots.", synonyms: [], antonyms: [] },
            {
              definition:
                "A mountain that shoots from another mountain or range and extends some distance in a lateral direction, or at right angles.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A spiked iron worn by seamen upon the bottom of the boot, to enable them to stand upon the carcass of a whale to strip off the blubber.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A brace strengthening a post and some connected part, such as a rafter or crossbeam; a strut.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The short wooden buttress of a post.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A projection from the round base of a column, occupying the angle of a square plinth upon which the base rests, or bringing the bottom bed of the base to a nearly square form. It is generally carved in leafage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Ergotized rye or other grain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A wall in a fortification that crosses a part of a rampart and joins to an inner wall.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of timber fixed on the bilgeways before launching, having the upper ends bolted to the vessel's side.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A curved piece of timber serving as a half to support the deck where a whole beam cannot be placed.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A branch of a vein.", synonyms: [], antonyms: [] },
            {
              definition: "A very short branch line of a railway line.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A short thin side shoot from a branch, especially one that bears fruit or, in conifers, the shoots that bear the leaves.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To prod (especially a horse) on the side or flank, with the intent to urge motion or haste, to gig.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To urge or encourage to action, or to a more vigorous pursuit of an object",
              synonyms: ["drive", "impel", "incite", "instigate", "stimulate"],
              antonyms: [],
            },
            {
              definition: "To put spurs on.",
              synonyms: [],
              antonyms: [],
              example: "to spur boots",
            },
            {
              definition: "To press forward; to travel in great haste.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["drive", "impel", "incite", "instigate", "stimulate"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "A tern.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A spurious tone, one that interferes with a signal in a circuit and is often masked underneath that signal.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The track of an animal, such as an otter; a spoor.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/speer",
        "https://en.wiktionary.org/wiki/spur",
        "https://en.wiktionary.org/wiki/spurs",
      ],
    },
  ],
  doves: [
    {
      word: "doves",
      phonetic: "/dʌvz/",
      phonetics: [{ text: "/dʌvz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A pigeon, especially one smaller in size; a bird (often arbitrarily called either a pigeon or a dove or both) of more than 300 species of the family Columbidae.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person favouring conciliation and negotiation rather than conflict.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Term of endearment for one regarded as pure and gentle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A greyish, bluish, pinkish colour like that of the bird.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["columbid", "columbiform", "culver", "pigeon"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dove",
        "https://en.wiktionary.org/wiki/doves",
      ],
    },
  ],
  sneak: [
    {
      word: "sneak",
      phonetic: "/sniːk/",
      phonetics: [
        {
          text: "/sniːk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sneak-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75900037",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One who sneaks; one who moves stealthily to acquire an item or information.",
              synonyms: [],
              antonyms: [],
              example:
                "My little brother is such a sneak; yesterday I caught him trying to look through my diary.",
            },
            {
              definition: "A cheat; a con artist.",
              synonyms: ["con artist", "trickster"],
              antonyms: [],
              example:
                "I can't believe I gave that sneak $50 for a ticket when they were selling for $20 at the front gate.",
            },
            {
              definition: "An informer; a tell-tale.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A ball bowled so as to roll along the ground; a daisy-cutter",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sneaker; a tennis shoe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A play where the quarterback receives the snap and immediately dives forward.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["con artist", "trickster"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To creep or go stealthily; to come or go while trying to avoid detection, as a person who does not wish to be seen.",
              synonyms: ["skulk"],
              antonyms: [],
              example:
                "He decided to sneak into the kitchen for a second cookie while his mom was on the phone.",
            },
            {
              definition: "To take something stealthily without permission.",
              synonyms: [],
              antonyms: [],
              example: "I went to sneak a chocolate but my dad caught me.",
            },
            {
              definition:
                "(ditransitive) To stealthily bring someone something.",
              synonyms: [],
              antonyms: [],
              example: "She asked me to sneak her a phone next month.",
            },
            {
              definition: "To hide, especially in a mean or cowardly manner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(with on) To inform an authority of another's misdemeanours.",
              synonyms: ["grass", "snitch", "tell tales"],
              antonyms: [],
              example: "If you sneak on me I'll bash you!",
            },
          ],
          synonyms: ["grass", "snitch", "tell tales", "skulk"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "In advance; before release to the general public.",
              synonyms: [],
              antonyms: [],
              example:
                "The company gave us a sneak look at their new electronic devices.",
            },
            {
              definition: "In a stealthy or surreptitious manner.",
              synonyms: [],
              antonyms: [],
              example: "I was able to get a sneak peek at the guest list.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sneak"],
    },
  ],
  paces: [
    {
      word: "paces",
      phonetic: "/ˈpeɪsɪz/",
      phonetics: [{ text: "/ˈpeɪsɪz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Step.", synonyms: [], antonyms: [] },
            { definition: "Way of stepping.", synonyms: [], antonyms: [] },
            {
              definition: "Speed or velocity in general.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A measure of the hardness of a pitch and of the tendency of a cricket ball to maintain its speed after bouncing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(collective) A group of donkeys.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Passage, route.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To walk back and forth in a small distance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To set the speed in a race.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To measure by walking.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "Easter.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pace",
        "https://en.wiktionary.org/wiki/paces",
      ],
    },
  ],
  colon: [
    {
      word: "colon",
      phonetic: "/ˈkəʊ.lɒn/",
      phonetics: [
        { text: "/ˈkəʊ.lɒn/", audio: "" },
        {
          text: "/ˈkoʊ.lən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/colon-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1755127",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: 'The punctuation mark ":".',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The triangular colon (especially in context of not being able to type the actual triangular colon).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A rhetorical figure consisting of a clause which is grammatically, but not logically, complete.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A clause or group of clauses written as a line, or taken as a standard of measure in ancient manuscripts or texts.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["colon-point"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/colon"],
    },
    {
      word: "colon",
      phonetic: "/ˈkəʊ.lɒn/",
      phonetics: [
        { text: "/ˈkəʊ.lɒn/", audio: "" },
        {
          text: "/ˈkoʊlən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/colon-2-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1755127",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Part of the large intestine; the final segment of the digestive system, after (distal to) the ileum and before (proximal to) the anus.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["large bowel"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/colon"],
    },
    {
      word: "colon",
      phonetic: "/kəˈlɒn/",
      phonetics: [
        { text: "/kəˈlɒn/", audio: "" },
        { text: "/kəˈloʊn/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A husbandman.", synonyms: [], antonyms: [] },
            {
              definition:
                "A European colonial settler, especially in a French colony.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/colon"],
    },
  ],
  siege: [
    {
      word: "siege",
      phonetic: "/siːdʒ/",
      phonetics: [
        {
          text: "/siːdʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/siege-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769761",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(heading) Military action.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "(heading) A seat.", synonyms: [], antonyms: [] },
            {
              definition:
                "A place with a toilet seat: an outhouse; a lavatory.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To assault a blockade of a city or fortress with the intent of conquering by force or attrition; to besiege.",
              synonyms: ["besiege"],
              antonyms: [],
            },
          ],
          synonyms: ["besiege"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/siege"],
    },
  ],
  strum: [
    {
      word: "strum",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The sound made by playing various strings of a stringed instrument simultaneously.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The act of strumming.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To play (a guitar or other stringed instrument) using various strings simultaneously.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/strum"],
    },
  ],
  drier: [
    {
      word: "drier",
      phonetic: "/ˈdɹaɪə/",
      phonetics: [
        {
          text: "/ˈdɹaɪə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/drier-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88675850",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈdɹaɪɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One who, or that which, dries; a desiccative.",
              synonyms: [],
              antonyms: [],
              example:
                "The sun and a northwesterly wind are great driers of the earth.",
            },
            {
              definition:
                "A household appliance that removes the water from clothing by accelerating evaporation, usually though heat and a tumbling motion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An electric hair dryer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any other device or substance, household or industrial, designed to remove water or humidity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A catalyst used to promote the drying of paints and varnishes by oxidative crosslinking.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["clothes dryer", "tumble dryer"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/drier",
        "https://en.wiktionary.org/wiki/dryer",
      ],
    },
    {
      word: "drier",
      phonetic: "/ˈdɹaɪə/",
      phonetics: [
        {
          text: "/ˈdɹaɪə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/drier-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88675850",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈdɹaɪɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Free from or lacking moisture.",
              synonyms: [],
              antonyms: [],
              example:
                "This towel's dry. Could you wet it and cover the chicken so it doesn't go dry as it cooks?",
            },
            {
              definition:
                "Unable to produce a liquid, as water, oil, or (farming) milk.",
              synonyms: [],
              antonyms: [],
              example: "This well is as dry as that cow.",
            },
            {
              definition: "Built without or lacking mortar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Anhydrous: free from or lacking water in any state, regardless of the presence of other liquids.",
              synonyms: [],
              antonyms: [],
              example: "Dry alcohol is 200 proof.",
            },
            { definition: "Athirst, eager.", synonyms: [], antonyms: [] },
            {
              definition:
                "Free from or lacking alcohol or alcoholic beverages.",
              synonyms: [],
              antonyms: [],
              example:
                "Of course it's a dry house. He was an alcoholic but he's been dry for almost a year now.",
            },
            {
              definition:
                "Describing an area where sales of alcoholic or strong alcoholic beverages are banned.",
              synonyms: [],
              antonyms: [],
              example:
                "You'll have to drive out of this dry county to find any liquor.",
            },
            {
              definition:
                "Free from or lacking embellishment or sweetness, particularly:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(somewhat derogatory) Involving computations rather than work with biological or chemical matter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a sound recording) Free from applied audio effects.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Without a usual complement or consummation; impotent.",
              synonyms: [],
              antonyms: [],
              example:
                "never dry fire a bow; dry humping her girlfriend; making a dry run",
            },
            {
              definition:
                "Of a mass, service, or rite: involving neither consecration nor communion.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["wet", "wet"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/drier",
        "https://en.wiktionary.org/wiki/dry",
      ],
    },
  ],
  cacao: [
    {
      word: "cacao",
      phonetic: "/kəˈkeɪ̯əʊ̯/",
      phonetics: [
        { text: "/kəˈkeɪ̯əʊ̯/", audio: "" },
        { text: "/kəˈkaʊ̯/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A tree, Theobroma cacao, whose seed is used to make chocolate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "This tree's seed, the cocoa bean.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cacao"],
    },
  ],
  humus: [
    {
      word: "humus",
      phonetic: "/ˈhjuːməs/",
      phonetics: [{ text: "/ˈhjuːməs/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A large group of natural organic compounds, found in the soil, formed from the chemical and biological decomposition of plant and animal residues and from the synthetic activity of microorganisms",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/humus"],
    },
    {
      word: "humus",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A Levantine Arab dip made of chickpea paste with various additions, such as olive oil, fresh garlic, lemon juice, and tahini, often eaten with pitta bread, or as a meze. It is mostly eaten in the Levant.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hummus",
        "https://en.wiktionary.org/wiki/humus",
      ],
    },
  ],
  bales: [
    {
      word: "bales",
      phonetic: "/beɪlz/",
      phonetics: [
        {
          text: "/beɪlz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bales-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=60477539",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A large fire, a conflagration or bonfire.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A funeral pyre.", synonyms: [], antonyms: [] },
            { definition: "A beacon-fire.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A rounded bundle or package of goods in a cloth cover, and corded for storage or transportation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bundle of compressed wool or hay, compacted for shipping and handling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A measurement of hay equal to 10 flakes. Approximately 70-90 lbs (32-41 kg).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A measurement of paper equal to 10 reams.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To remove water from a boat with buckets etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bale",
        "https://en.wiktionary.org/wiki/bales",
      ],
    },
  ],
  piped: [
    {
      word: "piped",
      phonetic: "/paɪpt/",
      phonetics: [{ text: "/paɪpt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To play (music) on a pipe instrument, such as a bagpipe or a flute.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To shout loudly and at high pitch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To emit or have a shrill sound like that of a pipe; to whistle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of a metal ingot: to become hollow in the process of solidifying.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To convey or transport (something) by means of pipes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To install or configure with pipes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To dab moisture away from.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To lead or conduct as if by pipes, especially by wired transmission.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To directly feed (the output of one program) as input to another program, indicated by the pipe character (pipe) at the command line.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To create or decorate with piping (icing).",
              synonyms: [],
              antonyms: [],
              example: "to pipe flowers on to a cupcake",
            },
            {
              definition:
                "To order or signal by a note pattern on a boatswain's pipe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a male) To have sexual intercourse with a female.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To see.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Shaped like a pipe; tubular.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pipe",
        "https://en.wiktionary.org/wiki/piped",
      ],
    },
  ],
  nasty: [
    {
      word: "nasty",
      phonetic: "/ˈnaː.sti/",
      phonetics: [
        { text: "/ˈnaː.sti/", audio: "" },
        { text: "/ˈnɑː.sti/", audio: "" },
        {
          text: "/ˈnæs.ti/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/nasty-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453616",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Something nasty.",
              synonyms: [],
              antonyms: [],
              example:
                "Processed foods are full of aspartame and other nasties.",
            },
            {
              definition: '(preceded by "the") Sexual intercourse.',
              synonyms: [],
              antonyms: [],
            },
            { definition: "A video nasty.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Dirty, filthy.", synonyms: [], antonyms: [] },
            {
              definition: "Contemptible, unpleasant (of a person).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Objectionable, unpleasant (of a thing); repellent, offensive.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Indecent or offensive; obscene, lewd.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Spiteful, unkind.", synonyms: [], antonyms: [] },
            {
              definition: "Awkward, difficult to navigate; dangerous.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Grave or dangerous (of an accident, illness etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Formidable, terrific; wicked.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/nasty"],
    },
  ],
  rinse: [
    {
      word: "rinse",
      phonetic: "/ɹɛns/",
      phonetics: [
        {
          text: "/ɹɛns/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rinse-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50497469",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The action of rinsing.",
              synonyms: [],
              antonyms: [],
              example: "I'll just give this knife a quick rinse.",
            },
            {
              definition:
                "A liquid used to rinse, now particularly a hair dye.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["lavatory", "wash", "lavatory"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To wash (something) quickly using water and no soap.",
              synonyms: [],
              antonyms: [],
              example:
                "You'd better rinse that stain before putting the shirt in the washing machine.",
            },
            {
              definition: "To remove soap from (something) using water.",
              synonyms: [],
              antonyms: [],
              example: "Rinse the dishes after you wash them.",
            },
            {
              definition:
                "To thoroughly defeat in an argument, fight or other competition.",
              synonyms: [],
              antonyms: [],
              example: "Checkmate!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rinse"],
    },
  ],
  boxer: [
    {
      word: "boxer",
      phonetic: "/ˈbɒksə/",
      phonetics: [
        { text: "/ˈbɒksə/", audio: "" },
        { text: "/ˈbɑːksɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A participant (fighter) in a boxing match.",
              synonyms: [],
              antonyms: [],
              example: "You can tell she's a boxer by looking at her nose.",
            },
            {
              definition:
                "A breed of stocky, medium-sized, short-haired dog with a square-jawed muzzle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A type of internal combustion engine in which cylinders are arranged in two banks on either side of a single crankshaft.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The person running a game of two-up.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "One who packs boxes.", synonyms: [], antonyms: [] },
            { definition: "A letterboxer.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["pugilist", "boxer engine"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/boxer"],
    },
  ],
  shrub: [
    {
      word: "shrub",
      phonetic: "/ʃɹʌb/",
      phonetics: [
        { text: "/ʃɹʌb/", audio: "" },
        {
          text: "/ʃɹʌb/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shrub-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=3923959",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A woody plant smaller than a tree, and usually with several stems from the same base.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bush"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To lop; to prune.", synonyms: [], antonyms: [] },
            {
              definition:
                "(Kenyan English) To mispronounce a word by replacing its consonant sound(s) with another or others of a similar place of articulation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shrub"],
    },
    {
      word: "shrub",
      phonetic: "/ʃɹʌb/",
      phonetics: [
        { text: "/ʃɹʌb/", audio: "" },
        {
          text: "/ʃɹʌb/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shrub-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=3923959",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A liquor composed of vegetable acid, fruit juice (especially lemon), sugar, sometimes vinegar, and a small amount of spirit as a preservative. Modern shrub is usually non-alcoholic, but in earlier times it was often mixed with a substantial amount of spirit such as brandy or rum, thus making it a liqueur.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shrub"],
    },
  ],
  amuse: [
    {
      word: "amuse",
      phonetic: "/əˈmjuːz/",
      phonetics: [
        {
          text: "/əˈmjuːz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/amuse-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89012467",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To entertain or occupy in a pleasant manner; to stir with pleasing emotions.",
              synonyms: [],
              antonyms: [],
              example: "I watch these movies because they amuse me.",
            },
            {
              definition: "To cause laughter or amusement; to be funny.",
              synonyms: [],
              antonyms: [],
              example: "His jokes rarely fail to amuse.",
            },
            {
              definition: "To keep in expectation; to beguile; to delude.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To occupy or engage the attention of; to lose in deep thought; to absorb; also, to distract; to bewilder.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "beguile",
            "divert",
            "entertain",
            "exhilarate",
            "gratify",
            "please",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/amuse"],
    },
  ],
  tacks: [
    {
      word: "tacks",
      phonetic: "/tæks/",
      phonetics: [{ text: "/tæks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A small nail with a flat head.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A thumbtack.", synonyms: [], antonyms: [] },
            {
              definition:
                "A loose seam used to temporarily fasten pieces of cloth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The lower corner on the leading edge of a sail relative to the direction of the wind.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A course or heading that enables a sailing vessel to head upwind. See also reach, gybe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A direction or course of action, especially a new one.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The maneuver by which a sailing vessel turns its bow through the wind so that the wind changes from one side to the other.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The distance a sailing vessel runs between these maneuvers when working to windward; a board.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A rope used to hold in place the foremost lower corners of the courses when the vessel is close-hauled; also, a rope employed to pull the lower corner of a studding sail to the boom.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of the various equipment and accessories worn by horses in the course of their use as domesticated animals. Saddles, stirrups, bridles, halters, reins, bits, harnesses, martingales, and breastplates are all forms of horse tack.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The stickiness of a compound, related to its cohesive and adhesive properties.",
              synonyms: [],
              antonyms: [],
              example:
                "The laminate adhesive has very aggressive tack and is hard to move once in place.",
            },
            {
              definition: "Food generally; fare, especially of the bread kind.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "That which is attached; a supplement; an appendix.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A contract by which the use of a thing is set, or let, for hire; a lease.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Confidence; reliance.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["coming about"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To nail with a tack (small nail with a flat head).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To sew/stich with a tack (loose seam used to temporarily fasten pieces of cloth).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To maneuver a sailing vessel so that its bow turns through the wind, i.e. the wind changes from one side of the vessel to the other.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To add something as an extra item.",
              synonyms: [],
              antonyms: [],
              example: "to tack (something) onto (something)",
            },
            {
              definition:
                'Often paired with "up", to place the tack on a horse.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["change"],
          antonyms: ["wear"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A stain; a tache.", synonyms: [], antonyms: [] },
            {
              definition: "A peculiar flavour or taint.",
              synonyms: [],
              antonyms: [],
              example: "a musty tack",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tack",
        "https://en.wiktionary.org/wiki/tacks",
      ],
    },
  ],
  cited: [
    {
      word: "cited",
      phonetic: "/ˈsaɪtɪd/",
      phonetics: [{ text: "/ˈsaɪtɪd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To quote; to repeat, as a passage from a book, or the words of another.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To list the source(s) from which one took information, words or literary or verbal context.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To summon officially or authoritatively to appear in court.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cite",
        "https://en.wiktionary.org/wiki/cited",
      ],
    },
  ],
  slung: [
    {
      word: "slung",
      phonetic: "/ˈslʌŋ/",
      phonetics: [
        {
          text: "/ˈslʌŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/slung-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90913438",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To throw with a circular or arcing motion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To throw with a sling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To pass a rope around (a cask, gun, etc.) preparatory to attaching a hoisting or lowering tackle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To sell, peddle, or distribute illicitly (e.g. drugs, sex, etc.).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sling",
        "https://en.wiktionary.org/wiki/slung",
      ],
    },
  ],
  delta: [
    {
      word: "delta",
      phonetic: "/ˈdɛltə/",
      phonetics: [
        {
          text: "/ˈdɛltə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/delta-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=36786587",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The fourth letter of the modern Greek alphabet Δ, δ.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A landform at the mouth of a river where it empties into a body of water.",
              synonyms: [],
              antonyms: [],
              example: "Nile Delta",
            },
            {
              definition:
                "The letter D in the ICAO spelling alphabet, which assigns words to letters of the alphabet.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The symbol Δ.", synonyms: [], antonyms: [] },
            {
              definition: "A small but noticeable effect. Compare epsilon.",
              synonyms: [],
              antonyms: [],
              example:
                "This will slow the main code path down, but only by delta.",
            },
            {
              definition:
                "The set of differences between two versions of a file.",
              synonyms: [],
              antonyms: [],
              example:
                "When you update the file, the system will only save the deltas.",
            },
            {
              definition:
                'A change in a quantity, likely from "d" for "difference".',
              synonyms: [],
              antonyms: [],
              example:
                'ΔV - "delta vee"(change in velocity, used in rocketry and orbital mechanics)',
            },
            {
              definition:
                "The angle subtended at the center of a circular arc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A type of cargo bike that has one wheel in front and two in back.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The closed figure produced by connecting three coils or circuits successively, end for end, especially in a three-phase system.",
              synonyms: [],
              antonyms: [],
              example: "delta winding; delta connection; delta current",
            },
            {
              definition:
                "The rate of change in an option value with respect to the underlying asset's price.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A value in delta notation indicating the relative abundances of isotopes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(U.S. Space Force) A military unit, nominally headed by a colonel, equivalent to a USAF operations wing, or an army regiment.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["garrison"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/delta"],
    },
  ],
  laden: [
    {
      word: "laden",
      phonetic: "/ˈleɪdən/",
      phonetics: [{ text: "/ˈleɪdən/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To fill or load (related to cargo or a shipment).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To weigh down, oppress, or burden.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To use a ladle or dipper to remove something (generally water).",
              synonyms: [],
              antonyms: [],
              example: "to lade water out of a tub, or into a cistern",
            },
            {
              definition:
                "To transfer (molten glass) from the pot to the forming table, in making plate glass.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To admit water by leakage.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Weighed down with a load, burdened.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Heavy.",
              synonyms: [],
              antonyms: [],
              example: "His comments were laden with deeper meaning.",
            },
            { definition: "Oppressed.", synonyms: [], antonyms: [] },
            {
              definition: "In the form of an adsorbate or adduct.",
              synonyms: [],
              antonyms: [],
              example:
                "Once laden it is easy to regenerate the adsorbent and retrieve the adsorbed species as a gas.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lade",
        "https://en.wiktionary.org/wiki/laden",
      ],
    },
  ],
  larva: [
    {
      word: "larva",
      phonetic: "/ˈlɑː.və/",
      phonetics: [
        { text: "/ˈlɑː.və/", audio: "" },
        {
          text: "/ˈlɑɹ.və/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/larva-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1161563",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An early stage of growth for some insects and amphibians, in which after hatching from their egg, insects are wingless and resemble a caterpillar or grub, and amphibians lack limbs and resemble fish.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An animal in the aforementioned stage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A form of a recently born or hatched animal that is quite different from its adult stage.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/larva"],
    },
  ],
  rents: [
    {
      word: "rents",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rents-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651404",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A payment made by a tenant at intervals in order to occupy a property.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A similar payment for the use of equipment or a service.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A profit from possession of a valuable right, as a restricted license to engage in a trade or business.",
              synonyms: [],
              antonyms: [],
              example:
                "A New York city taxicab license earns more than $10,000 a year in rent.",
            },
            {
              definition: "An object for which rent is charged or paid.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Income; revenue.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To occupy premises in exchange for rent.",
              synonyms: [],
              antonyms: [],
              example: "I rented a house from my friend's parents for a year.",
            },
            {
              definition: "To grant occupation in return for rent.",
              synonyms: [],
              antonyms: [],
              example: "We rented our house to our son's friend for a year.",
            },
            {
              definition:
                "To obtain or have temporary possession of an object (e.g. a movie) in exchange for money.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be leased or let for rent.",
              synonyms: [],
              antonyms: [],
              example: "The house rents for five hundred dollars a month.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A tear or rip in some surface.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A division or schism.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rent",
        "https://en.wiktionary.org/wiki/rents",
      ],
    },
  ],
  yells: [
    {
      word: "yells",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A shout.", synonyms: [], antonyms: [] },
            {
              definition: "A phrase to be shouted.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "Shout; holler; make a loud sound with the voice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To convey by shouting",
              synonyms: [],
              antonyms: [],
              example: "He yelled directions to the party from the car.",
            },
            {
              definition: "To tell someone off (in a loud and angry manner)",
              synonyms: [],
              antonyms: [],
              example: "If I come home late again, my dad is gonna yell at me.",
            },
          ],
          synonyms: ["call", "cry", "holler", "shout"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/yell",
        "https://en.wiktionary.org/wiki/yells",
      ],
    },
  ],
  spool: [
    {
      word: "spool",
      phonetic: "/spuːl/",
      phonetics: [
        {
          text: "/spuːl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spool-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=667319",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A reel; a device around which thread, wire or cable is wound, especially a cylinder or spindle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A temporary storage area for electronic mail, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To wind on a spool or spools.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To send files to a device or a program (a spooler or a daemon that puts them in a queue for processing at a later time).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spool"],
    },
    {
      word: "spool",
      phonetic: "/spuːl/",
      phonetics: [
        {
          text: "/spuːl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spool-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=667319",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small swimming pool that can be used also as a spa.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spool"],
    },
  ],
  spill: [
    {
      word: "spill",
      phonetic: "/spɪl/",
      phonetics: [
        {
          text: "/spɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spill-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75701478",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/spɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spill-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50423065",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A mess of something that has been dropped.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A fall or stumble.",
              synonyms: [],
              antonyms: [],
              example: "The bruise is from a bad spill he had last week.",
            },
            {
              definition:
                "A small stick or piece of paper used to light a candle, cigarette etc by the transfer of a flame from a fire.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A slender piece of anything.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the thick laths or poles driven horizontally ahead of the main timbering in advancing a level in loose ground.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(sound recording) The situation where sound is picked up by a microphone from a source other than that which is intended.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A small sum of money.", synonyms: [], antonyms: [] },
            {
              definition:
                "(Australian politics) A declaration that the leadership of a parliamentary party is vacant, and open for re-election. Short form of leadership spill.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To drop something so that it spreads out or makes a mess; to accidentally pour.",
              synonyms: [],
              antonyms: [],
              example: "I spilled some sticky juice on the kitchen floor.",
            },
            {
              definition: "To spread out or fall out, as above.",
              synonyms: [],
              antonyms: [],
              example: "Some sticky juice spilled onto the kitchen floor.",
            },
            {
              definition: "To drop something that was intended to be caught.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To mar; to damage; to destroy by misuse; to waste.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be destroyed, ruined, or wasted; to come to ruin; to perish; to waste.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause to flow out and be lost or wasted; to shed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cover or decorate with slender pieces of wood, metal, ivory, etc.; to inlay.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To relieve a sail from the pressure of the wind, so that it can be more easily reefed or furled, or to lessen the strain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Australian politics) To open the leadership of a parliamentary party for re-election.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To reveal information to an uninformed party.",
              synonyms: [],
              antonyms: [],
              example: "He spilled his guts out to his new psychologist.",
            },
            {
              definition: "(of a knot). To come undone.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spill"],
    },
  ],
  crush: [
    {
      word: "crush",
      phonetic: "/kɹʌʃ/",
      phonetics: [
        {
          text: "/kɹʌʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/crush-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75270170",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/kɹʌʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/crush-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=840684",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A violent collision or compression; a crash; destruction; ruin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Violent pressure, as of a moving crowd.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A crowd that produces uncomfortable pressure.",
              synonyms: [],
              antonyms: [],
              example: "a crush at a reception",
            },
            { definition: "A violent crowding.", synonyms: [], antonyms: [] },
            {
              definition: "A crowd control barrier.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A drink made by squeezing the juice out of fruit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An infatuation with somebody one is not dating.",
              synonyms: [],
              antonyms: [],
              example:
                "I've had a huge crush on her since we met many years ago.",
            },
            {
              definition:
                "A standing stock or cage with movable sides used to restrain livestock for safe handling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A party or festive function.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The process of crushing cane to remove the raw sugar, or the season when this process takes place.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To press between two hard objects; to squeeze so as to alter the natural shape or integrity of it, or to force together into a mass.",
              synonyms: [],
              antonyms: [],
              example: "to crush grapes",
            },
            {
              definition: "To reduce to fine particles by pounding or grinding",
              synonyms: ["comminute"],
              antonyms: [],
              example: "to crush quartz",
            },
            {
              definition: "To overwhelm by pressure or weight.",
              synonyms: [],
              antonyms: [],
              example:
                "After the corruption scandal, the opposition crushed the ruling party in the elections",
            },
            {
              definition: "To oppress or grievously burden.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To overcome completely; to subdue totally.",
              synonyms: [],
              antonyms: [],
              example:
                "The sultan's black guard crushed every resistance bloodily.",
            },
            {
              definition:
                "To be or become broken down or in, or pressed into a smaller compass, by external weight or force",
              synonyms: [],
              antonyms: [],
              example: "an eggshell crushes easily",
            },
            {
              definition: "To feel infatuation or unrequited love.",
              synonyms: [],
              antonyms: [],
              example: "She's crushing on him.",
            },
            {
              definition:
                "To give a compressed or foreshortened appearance to.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["comminute"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/crush"],
    },
  ],
  jewel: [
    {
      word: "jewel",
      phonetic: "/dʒul/",
      phonetics: [
        { text: "/dʒul/", audio: "" },
        { text: "/ˈdʒuːl/", audio: "" },
        {
          text: "/dʒul/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/jewel-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=357612",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A precious or semi-precious stone; gem, gemstone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A valuable object used for personal ornamentation, especially one made of precious metals and stones; a piece of jewellery.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Anything precious or valuable.",
              synonyms: [],
              antonyms: [],
              example:
                "Galveston was the jewel of Texas prior to the hurricane.",
            },
            {
              definition:
                "A bearing for a pivot in a watch, formed of a crystal or precious stone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various lycaenid butterflies of the genus Hypochrysops.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The clitoris.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To bejewel; to decorate or bedeck with jewels or gems.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/jewel"],
    },
  ],
  snaps: [
    {
      word: "snaps",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A quick breaking or cracking sound or the action of producing such a sound.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A sudden break.", synonyms: [], antonyms: [] },
            {
              definition: "An attempt to seize, bite, attack, or grab.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The act of making a snapping sound by pressing the thumb and an opposing finger of the same hand together and suddenly releasing the grip so that the finger hits against the palm.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A fastening device that makes a snapping sound when used.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A photograph; a snapshot.",
              synonyms: [],
              antonyms: [],
              example:
                "We took a few snaps of the old church before moving on.",
            },
            {
              definition:
                "The sudden release of something held under pressure or tension.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A thin circular cookie or similar baked good.",
              synonyms: [],
              antonyms: [],
              example: "a ginger snap",
            },
            {
              definition:
                "A brief, sudden period of a certain weather; used primarily in the phrase cold snap.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A very short period of time (figuratively, the time taken to snap one's fingers), or a task that can be accomplished in such a period.",
              synonyms: [],
              antonyms: [],
              example: "I can fix most vacuum cleaners in a snap.",
            },
            {
              definition: "A snap bean such as Phaseolus vulgaris.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A backward pass or handoff of a football from its position on the ground that puts the ball in play; a hike.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(somewhat colloquial) A rivet: a scrapbooking embellishment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A small meal, a snack; lunch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'A card game, primarily for children, in which players cry "snap" to claim pairs of matching cards as they are turned up.',
              synonyms: [],
              antonyms: [],
            },
            { definition: "A greedy fellow.", synonyms: [], antonyms: [] },
            {
              definition:
                "That which is, or may be, snapped up; something bitten off, seized, or obtained by a single quick movement; hence, a bite, morsel, or fragment; a scrap.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Briskness; vigour; energy; decision",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any circumstance out of which money may be made or an advantage gained. used primarily in the phrase soft snap.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Something that is easy or effortless.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A snapper, or snap beetle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Jounce (the fourth derivative of the position vector with respect to time), followed by crackle and pop",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A quick offhand shot with a firearm; a snap shot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Something of no value.",
              synonyms: [],
              antonyms: [],
              example: "not worth a snap",
            },
            {
              definition:
                "A visual message sent through the Snapchat application.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A crisp or pithy quality; epigrammatic point or force.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A tool used by riveters.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A tool used by glass-moulders.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A brief theatrical engagement.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An easy and profitable place or task; a sinecure.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A cheat or sharper.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To fracture or break apart suddenly.",
              synonyms: [],
              antonyms: [],
              example: "He snapped his stick in anger.",
            },
            {
              definition:
                "To give forth or produce a sharp cracking noise; to crack.",
              synonyms: [],
              antonyms: [],
              example: "Blazing firewood snaps.",
            },
            {
              definition: "To attempt to seize with the teeth or bite.",
              synonyms: [],
              antonyms: [],
              example: "A dog snaps at a passenger. A fish snaps at the bait.",
            },
            {
              definition: "To attempt to seize with eagerness.",
              synonyms: [],
              antonyms: [],
              example: "She snapped at the chance to appear on television.",
            },
            {
              definition: "To speak abruptly or sharply.",
              synonyms: [],
              antonyms: [],
              example: "He snapped at me for the slightest mistake.",
            },
            {
              definition: "To give way abruptly and loudly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To suffer a mental breakdown, usually while under tension.",
              synonyms: [],
              antonyms: [],
              example: "She should take a break before she snaps.",
            },
            {
              definition: "To flash or appear to flash as with light.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fit or fasten together with a snapping sound.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To jump to a fixed position relative to another element.",
              synonyms: [],
              antonyms: [],
              example:
                "The floating toolbar will snap to the edge of the screen when dragged towards it.",
            },
            {
              definition: "To snatch with or as if with the teeth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To pull apart with a snapping sound; to pop loose.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To say abruptly or sharply.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To speak to abruptly or sharply; to treat snappishly; usually with up.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause something to emit a snapping sound.",
              synonyms: [],
              antonyms: [],
              example: "to snap a fastener",
            },
            {
              definition: "To close something using a snap as a fastener.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To snap one's fingers: to make a snapping sound, often by pressing the thumb and an opposing finger of the same hand together and suddenly releasing the grip so that the finger hits against the palm; alternatively, by bringing the index finger quickly down onto the middle finger and thumb.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause to move suddenly and smartly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To take a photograph; to release a camera's shutter (which may make a snapping sound).",
              synonyms: [],
              antonyms: [],
              example:
                "He snapped a picture of me with my mouth open and my eyes closed.",
            },
            {
              definition:
                "To put (a football) in play by a backward pass or handoff from its position on the ground; to hike (a football).",
              synonyms: [],
              antonyms: [],
              example:
                "He can snap the ball to a back twenty yards behind him.",
            },
            {
              definition: "To misfire.",
              synonyms: [],
              antonyms: [],
              example: "The gun snapped.",
            },
            {
              definition:
                "To catch out sharply (a batsman who has just snicked a bowled ball).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/snap",
        "https://en.wiktionary.org/wiki/snaps",
      ],
    },
  ],
  stain: [
    {
      word: "stain",
      phonetic: "/steɪn/",
      phonetics: [
        {
          text: "/steɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stain-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88988007",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A discoloured spot or area.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A blemish on one's character or reputation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A substance used to soak into a surface and colour it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A reagent or dye used to stain microscope specimens so as to make some structures visible.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of a number of non-standard tinctures used in modern heraldry.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To discolour.",
              synonyms: [],
              antonyms: [],
              example: "armour stained with blood",
            },
            {
              definition:
                "To taint or tarnish someone's character or reputation",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To coat a surface with a stain",
              synonyms: [],
              antonyms: [],
              example: "the stained glass used for church windows",
            },
            {
              definition: "To become stained; to take a stain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To treat (a microscopic specimen) with a dye, especially one that dyes specific features",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause to seem inferior or soiled by comparison.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stain"],
    },
  ],
  kicks: [
    {
      word: "kicks",
      phonetic: "/kɪks/",
      phonetics: [{ text: "/kɪks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A hit or strike with the leg, foot or knee.",
              synonyms: [],
              antonyms: [],
              example: "A kick to the knee.",
            },
            {
              definition: "The action of swinging a foot or leg.",
              synonyms: [],
              antonyms: [],
              example: "The ballerina did a high kick and a leap.",
            },
            {
              definition:
                "Something that tickles the fancy; something fun or amusing.",
              synonyms: [],
              antonyms: [],
              example: "I finally saw the show. What a kick!",
            },
            {
              definition: "The removal of a person from an online activity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any bucking motion of an object that lacks legs or feet.",
              synonyms: [],
              antonyms: [],
              example: "The car had a nasty kick the whole way.",
            },
            { definition: "Piquancy.", synonyms: [], antonyms: [] },
            {
              definition:
                "A stimulation provided by an intoxicating substance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A pass played by kicking with the foot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The distance traveled by kicking the ball.",
              synonyms: [],
              antonyms: [],
              example: "a long kick up the field.",
            },
            { definition: "A recoil of a gun.", synonyms: [], antonyms: [] },
            { definition: "Pocket.", synonyms: [], antonyms: [] },
            {
              definition:
                "An increase in speed in the final part of a running race.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To strike or hit with the foot or other extremity of the leg.",
              synonyms: [],
              antonyms: [],
              example: "Did you kick your brother?",
            },
            {
              definition:
                "To make a sharp jerking movement of the leg, as to strike something.",
              synonyms: [],
              antonyms: [],
              example:
                "He enjoyed the simple pleasure of watching the kickline kick.",
            },
            {
              definition:
                "To direct to a particular place by a blow with the foot or leg.",
              synonyms: [],
              antonyms: [],
              example: "Kick the ball into the goal.",
            },
            {
              definition: '(with "off" or "out") To eject summarily.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To forcibly remove a participant from an online activity.",
              synonyms: [],
              antonyms: [],
              example: "He was kicked by ChanServ for flooding.",
            },
            {
              definition:
                "To overcome (a bothersome or difficult issue or obstacle); to free oneself of (a problem).",
              synonyms: [],
              antonyms: [],
              example:
                "I still smoke, but they keep telling me to kick the habit.",
            },
            {
              definition: "To move or push suddenly and violently.",
              synonyms: [],
              antonyms: [],
              example: "He was kicked sideways by the force of the blast.",
            },
            {
              definition: "(of a firearm) To recoil; to push by recoiling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To attack (a piece) in order to force it to move.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To accelerate quickly with a few pedal strokes in an effort to break away from other riders.",
              synonyms: [],
              antonyms: [],
              example:
                "Contador kicks again to try to rid himself of Rasmussen.",
            },
            {
              definition: "To show opposition or resistance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To work a press by impact of the foot on a treadle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [{ definition: "To die.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "One who kicks.", synonyms: [], antonyms: [] },
            { definition: "One who takes kicks.", synonyms: [], antonyms: [] },
            { definition: "The kicking strap.", synonyms: [], antonyms: [] },
            { definition: "An outboard motor.", synonyms: [], antonyms: [] },
            {
              definition:
                "An unexpected situation, detail or circumstance, often unpleasant.",
              synonyms: [],
              antonyms: [],
              example:
                "John wants to climb the wall, but the kicker is that it is thirty feet tall.",
            },
            {
              definition:
                "An enticement for investors, e.g. warranty added to the investment contract.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An unpaired card which is part of a pair, two pair, or three of a kind poker hand.",
              synonyms: [],
              antonyms: [],
              example:
                "Jill's hand was two pair, aces and sevens, with a king kicker.",
            },
            {
              definition:
                "Small text above a headline that indicates the topic of the story.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The last one or two paragraphs of a story.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A lighthearted or humorous item used to round off a news broadcast.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A device that periodically displaces a newspaper from the print production line, to aid in gathering the newspapers into fixed-size bundles.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A launch ramp.", synonyms: [], antonyms: [] },
            {
              definition:
                'The fermenting mass of fruit that is the basis of pruno, or "prison wine".',
              synonyms: ["motor"],
              antonyms: [],
            },
            {
              definition: "A relaxed party.",
              synonyms: ["kickback"],
              antonyms: [],
            },
            {
              definition: "A backlight positioned at an angle.",
              synonyms: ["kick"],
              antonyms: [],
            },
          ],
          synonyms: ["kick", "kickback", "motor"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A particular type of Texan who is associated with country/western attire, attitudes and/or philosophy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Pleasures, thrills.", synonyms: [], antonyms: [] },
            { definition: "Shoes.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An introduction; something that leads into the beginning of something.",
              synonyms: [],
              antonyms: [],
              example:
                "After the long lead-in, the climax of the story was a disappointment.",
            },
            {
              definition:
                "A region of data at the beginning of a compact disc, holding the table of contents.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A short phrase that begins the caption of a photograph.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["preface", "prologue", "kicker"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/kick",
        "https://en.wiktionary.org/wiki/kicker",
        "https://en.wiktionary.org/wiki/kicks",
        "https://en.wiktionary.org/wiki/lead-in",
      ],
    },
  ],
  tying: [
    {
      word: "tying",
      phonetic: "/ˈtaɪ.ɪŋ/",
      phonetics: [{ text: "/ˈtaɪ.ɪŋ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To twist (a string, rope, or the like) around itself securely.",
              synonyms: [],
              antonyms: [],
              example: "Tie the rope to this tree.",
            },
            {
              definition:
                "To form (a knot or the like) in a string or the like.",
              synonyms: [],
              antonyms: [],
              example: "Tie a knot in this rope for me, please.",
            },
            {
              definition:
                "To attach or fasten (one thing to another) by string or the like.",
              synonyms: [],
              antonyms: [],
              example: "Tie him to the tree.",
            },
            {
              definition: "To secure (something) by string or the like.",
              synonyms: [],
              antonyms: [],
              example: "Tie your shoes.",
            },
            {
              definition:
                "To have the same score or position as another in a competition or ordering.",
              synonyms: [],
              antonyms: [],
              example: "They tied for third place.",
            },
            {
              definition:
                "To have the same score or position as (another) in a competition or ordering.",
              synonyms: [],
              antonyms: [],
              example: "He tied me for third place.",
            },
            {
              definition:
                "To unite (musical notes) with a line or slur in the notation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To believe; to credit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In the Perl programming language, to extend (a variable) so that standard operations performed upon it invoke custom functionality instead.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bind", "fasten", "link"],
          antonyms: ["unfasten", "untie"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Action of the verb to tie; ligature.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The act or process of washing ores in a buddle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tie",
        "https://en.wiktionary.org/wiki/tying",
      ],
    },
  ],
  slits: [
    {
      word: "slits",
      phonetic: "/ˈslɪts/",
      phonetics: [{ text: "/ˈslɪts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A narrow cut or opening; a slot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The opening of the vagina.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A woman, usually a sexually loose woman; a prostitute.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cut a narrow opening.",
              synonyms: [],
              antonyms: [],
              example: "He slit the bag open and the rice began pouring out.",
            },
            {
              definition: "To split into strips by lengthwise cuts.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cut; to sever; to divide.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/slit",
        "https://en.wiktionary.org/wiki/slits",
      ],
    },
  ],
  rated: [
    {
      word: "rated",
      phonetic: "/ˈɹeɪtɪd/",
      phonetics: [{ text: "/ˈɹeɪtɪd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To assign or be assigned a particular rank or level.",
              synonyms: [],
              antonyms: [],
              example: "She is rated fourth in the country.",
            },
            {
              definition: "To evaluate or estimate the value of.",
              synonyms: [],
              antonyms: [],
              example: "They rate his talents highly.",
            },
            {
              definition: "To consider or regard.",
              synonyms: [],
              antonyms: [],
              example: "He rated this book brilliant.",
            },
            {
              definition: "To deserve; to be worth.",
              synonyms: [],
              antonyms: [],
              example:
                "The view here hardly rates a mention in the travel guide.",
            },
            {
              definition:
                "To determine the limits of safe functioning for a machine or electrical device.",
              synonyms: [],
              antonyms: [],
              example: "The transformer is rated at 10 watts.",
            },
            {
              definition:
                "To evaluate a property's value for the purposes of local taxation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To like; to think highly of.",
              synonyms: [],
              antonyms: [],
              example: "The customers don't rate the new burgers.",
            },
            {
              definition: "To have position (in a certain class).",
              synonyms: [],
              antonyms: [],
              example: "He rates as the best cyclist in the country.",
            },
            {
              definition: "To have value or standing.",
              synonyms: [],
              antonyms: [],
              example:
                "This last performance of hers didn't rate very high with the judges.",
            },
            { definition: "To ratify.", synonyms: [], antonyms: [] },
            {
              definition:
                "To ascertain the exact rate of the gain or loss of (a chronometer) as compared with true time.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["rank"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To berate, scold.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Scolded, rebuked.", synonyms: [], antonyms: [] },
            {
              definition:
                "Maximum (load, voltage, etc.) under which a device can function properly",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rate",
        "https://en.wiktionary.org/wiki/rated",
      ],
    },
  ],
  eerie: [
    {
      word: "eerie",
      phonetic: "/ˈɪəɹi/",
      phonetics: [
        {
          text: "/ˈɪəɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/eerie-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=68569020",
          license: {
            name: "BY 4.0",
            url: "https://creativecommons.org/licenses/by/4.0",
          },
        },
        {
          text: "/ˈɪɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/eerie-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=60609833",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Strange, weird, fear-inspiring.",
              synonyms: ["creepy", "spooky"],
              antonyms: [],
              example:
                "The eerie sounds seemed to come from the graveyard after midnight.",
            },
            { definition: "Frightened, timid.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["creepy", "spooky"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/eerie"],
    },
  ],
  smash: [
    {
      word: "smash",
      phonetic: "/smæʃ/",
      phonetics: [
        {
          text: "/smæʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/smash-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1157739",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The sound of a violent impact; a violent striking together.",
              synonyms: [],
              antonyms: [],
              example:
                "I could hear the screech of the brakes, then the horrible smash of cars colliding.",
            },
            {
              definition: "A traffic collision.",
              synonyms: [],
              antonyms: [],
              example:
                "The driver and two passengers were badly injured in the smash.",
            },
            {
              definition:
                "Something very successful or popular (as music, food, fashion, etc); a hit.",
              synonyms: [],
              antonyms: [],
              example: "This new show is sure to be a smash.",
            },
            {
              definition: "A very hard overhead shot hit sharply downward.",
              synonyms: [],
              antonyms: [],
              example:
                "A smash may not be as pretty as a good half volley, but it can still win points.",
            },
            { definition: "A bankruptcy.", synonyms: [], antonyms: [] },
            {
              definition:
                "A kind of julep cocktail containing chunks of fresh fruit that can be eaten after finishing the drink.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["smash hit", "crash", "crash"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To break (something brittle) violently.",
              synonyms: [],
              antonyms: [],
              example: "The demolition team smashed the buildings to rubble.",
            },
            {
              definition: "To be destroyed by being smashed.",
              synonyms: [],
              antonyms: [],
              example: "The crockery smashed as it hit the floor.",
            },
            {
              definition: "To hit extremely hard.",
              synonyms: [],
              antonyms: [],
              example:
                "Bonds smashed the ball 467 feet, the second longest home run in the history of the park.",
            },
            {
              definition: "To ruin completely and suddenly.",
              synonyms: [],
              antonyms: [],
              example: "The news smashed any hopes of a reunion.",
            },
            {
              definition:
                "To defeat overwhelmingly; to gain a comprehensive success.",
              synonyms: [],
              antonyms: [],
              example: "I really smashed that English exam.",
            },
            {
              definition: "To deform through continuous pressure.",
              synonyms: [],
              antonyms: [],
              example:
                "I slowly smashed the modeling clay flat with the palm of my hand.",
            },
            {
              definition: "To have sexual intercourse with.",
              synonyms: [],
              antonyms: [],
              example: "Would you smash her?",
            },
          ],
          synonyms: [
            "shatter",
            "dash",
            "shatter",
            "slaughter",
            "trounce",
            "coitize",
            "go to bed with",
            "sleep with",
            "pound",
            "thump",
            "wallop",
            "dash",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/smash"],
    },
  ],
  plums: [
    {
      word: "plums",
      phonetic: "/plʌmz/",
      phonetics: [{ text: "/plʌmz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The fruit and its tree.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Extended senses.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["ume", "plum tree", "plumtree"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/plum",
        "https://en.wiktionary.org/wiki/plums",
      ],
    },
    {
      word: "plums",
      phonetic: "/plʌmz/",
      phonetics: [{ text: "/plʌmz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To plumb.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/plum",
        "https://en.wiktionary.org/wiki/plums",
      ],
    },
  ],
  zebra: [
    {
      word: "zebra",
      phonetic: "/ˈziːbɹə/",
      phonetics: [
        { text: "/ˈziːbɹə/", audio: "" },
        {
          text: "/ˈziːbɹə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/zebra-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=796361",
        },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/zebra-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=796173",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of three species of genus Equus: E. grevyi, E. quagga, or E. zebra, all with black and white stripes and native to Africa.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A referee.", synonyms: [], antonyms: [] },
            {
              definition:
                'An unlikely diagnosis, especially for symptoms probably caused by a common ailment. (Originates in the advice often given to medical students: "when you hear hoofbeats, think of horses, not zebras".)',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A biracial person, specifically one born to a member of the Sub-Saharan African race and a Caucasian.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A fish, the zebra cichlid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various papilionid butterflies of the subgenus Paranticopsis of the genus Graphium, having black and white markings.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["fascinoma"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/zebra"],
    },
  ],
  earns: [
    {
      word: "earns",
      phonetic: "/ɜːnz/",
      phonetics: [
        {
          text: "/ɜːnz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/earns-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=68569019",
          license: {
            name: "BY 4.0",
            url: "https://creativecommons.org/licenses/by/4.0",
          },
        },
        {
          text: "/ɝnz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/earns-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=60609784",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To gain (success, reward, recognition) through applied effort or work.",
              synonyms: [],
              antonyms: [],
              example:
                "You can have the s'mores: you earned them, clearing the walkway of snow so well.",
            },
            {
              definition: "To receive payment for work.",
              synonyms: [],
              antonyms: [],
              example:
                "He earns seven million dollars a year as CEO.  My bank account is only earning one percent interest.",
            },
            {
              definition: "To receive payment for work.",
              synonyms: [],
              antonyms: [],
              example:
                "Now that you are earning, you can start paying me rent.",
            },
            {
              definition: "To cause (someone) to receive payment or reward.",
              synonyms: [],
              antonyms: [],
              example: "My CD earns me six percent!",
            },
            {
              definition: "To achieve by being worthy of.",
              synonyms: [],
              antonyms: [],
              example: "to earn a spot in the top 20",
            },
          ],
          synonyms: [
            "generate",
            "make",
            "render",
            "yield",
            "deserve",
            "garner",
            "merit",
            "win",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To curdle, as milk.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To long; to yearn.", synonyms: [], antonyms: [] },
            { definition: "To grieve.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/earn",
        "https://en.wiktionary.org/wiki/earns",
      ],
    },
  ],
  bushy: [
    {
      word: "bushy",
      phonetic: "/ˈbʊʃi/",
      phonetics: [{ text: "/ˈbʊʃi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Like a bush in having many widely spread branches.",
              synonyms: [],
              antonyms: [],
              example: "the bushy tail of a squirrel",
            },
            { definition: "Growing thickly.", synonyms: [], antonyms: [] },
            {
              definition:
                "(Racial slur) Derogatory word used to refer to Afro-textured Hair.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bushy"],
    },
  ],
  scary: [
    {
      word: "scary",
      phonetic: "/ˈskɛəɹi/",
      phonetics: [
        { text: "/ˈskɛəɹi/", audio: "" },
        {
          text: "/ˈskɛəɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scary-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1785261",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Causing or able to cause fright.",
              synonyms: [
                "frightening",
                "hair-raising",
                "petrifying",
                "terrifying",
              ],
              antonyms: [],
              example:
                "She was hiding behind her pillow during the scary parts of the film.",
            },
            {
              definition: "Uncannily striking or surprising.",
              synonyms: [],
              antonyms: [],
              example:
                "Linda changed her hair, and it’s scary how much she looks like her mother.",
            },
            {
              definition: "Subject to sudden alarm; easily frightened.",
              synonyms: ["jumpy", "nervous"],
              antonyms: [],
            },
          ],
          synonyms: [
            "frightening",
            "hair-raising",
            "petrifying",
            "terrifying",
            "jumpy",
            "nervous",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scary"],
    },
    {
      word: "scary",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Barren land having only a thin coat of grass.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scary"],
    },
  ],
  squad: [
    {
      word: "squad",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/squad-1-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75900076",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈskwɒd/", audio: "" },
        { text: "/ˈskwɑd/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A group of people organized for some common purpose, usually of about ten members.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One's friend group, taken collectively; one's peeps.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To act as part of, or on behalf of, a squad.",
              synonyms: [],
              antonyms: [],
              example: "We squad on the fifth of the month.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/squad"],
    },
    {
      word: "squad",
      phonetic: "/ˈskwæd/",
      phonetics: [{ text: "/ˈskwæd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Sloppy mud.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/squad"],
    },
  ],
  tutor: [
    {
      word: "tutor",
      phonetic: "/ˈtjuːtə/",
      phonetics: [
        {
          text: "/ˈtjuːtə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tutor-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=53355687",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈtutɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tutor-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=332192",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One who teaches another (usually called a student, learner, or tutee) in a one-on-one or small-group interaction.",
              synonyms: [],
              antonyms: [],
              example:
                "He passed the difficult class with help from his tutor.",
            },
            {
              definition:
                "A university officer responsible for students in a particular hall.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One who has the charge of a child or pupil and his estate; a guardian.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(trading card games) A card that allows you to search your deck for one or more other cards.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["preceptor"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To instruct or teach, especially an individual or small group.",
              synonyms: [],
              antonyms: [],
              example:
                "To help pay her tuition, the college student began to tutor high school students in calculus and physics.",
            },
            {
              definition: "To treat with authority or sternness.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tutor"],
    },
  ],
  silks: [
    {
      word: "silks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A fine fiber excreted by the silkworm or other arthropod (such as a spider).",
              synonyms: [],
              antonyms: [],
              example: "The thread made of silk was barely visible.",
            },
            {
              definition: "A fine, soft cloth woven from silk fibers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Anything which resembles silk, such as the filiform styles of the female flower of maize, or the seed covering of bombaxes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The gown worn by a Senior (i.e. Queen's/King's) Counsel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A Senior (i.e. Queen's or King's) Counsel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(circus arts, in the plural) A pair of long silk sheets suspended in the air on which a performer performs tricks.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(usually in the plural) The garments worn by a jockey displaying the colors of the horse's owner.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To remove the silk from (corn).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/silk",
        "https://en.wiktionary.org/wiki/silks",
      ],
    },
  ],
  slabs: [
    {
      word: "slabs",
      phonetic: "/slæbs/",
      phonetics: [{ text: "/slæbs/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A large, flat piece of solid material; a solid object that is large and flat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A paving stone; a flagstone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A carton containing 24 cans of beer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An outside piece taken from a log or timber when sawing it into boards, planks, etc.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A bird, the wryneck.", synonyms: [], antonyms: [] },
            {
              definition: "The slack part of a sail.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A large, luxury pre-1980 General Motors vehicle, particularly a Buick, Oldsmobile or Cadillac.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A very large wave.", synonyms: [], antonyms: [] },
            {
              definition:
                "A sequence of 12 adjacent bits, serving as a byte in some computers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Part of a tectonic plate that is being subducted.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Mud, sludge.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A car that has been modified with equipment such as loudspeakers, lights, special paint, hydraulics, and other accessories.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/slab",
        "https://en.wiktionary.org/wiki/slabs",
      ],
    },
  ],
  bumps: [
    {
      word: "bumps",
      phonetic: "/bʌmps/",
      phonetics: [{ text: "/bʌmps/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A light blow or jolting collision.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The sound of such a collision.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A protuberance on a level surface.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A swelling on the skin caused by illness or injury.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the protuberances on the cranium which, in phrenology, are associated with distinct faculties or affections of the mind. Also (metonymically) the faculty itself",
              synonyms: [],
              antonyms: [],
              example: "the bump of veneration; the bump of acquisitiveness",
            },
            {
              definition:
                "The point, in a race in which boats are spaced apart at the start, at which a boat begins to overtake the boat ahead.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The swollen abdomen of a pregnant woman.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A post in an Internet forum thread made in order to raise the thread's profile by returning it to the top of the list of active threads.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A temporary increase in a quantity, as shown in a graph.",
              synonyms: [],
              antonyms: [],
              example:
                "US presidential nominees get a post-convention bump in survey ratings.",
            },
            {
              definition:
                "A dose of a drug such as ketamine or cocaine, when snorted recreationally.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The noise made by the bittern; a boom.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(preceded by definite article) A disco dance in which partners rhythmically bump each other's hips together.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In skipping, a single jump over two consecutive turns of the rope.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A coarse cotton fabric.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A training match for a fighting dog.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The jaw of either of the middle pockets.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Music, especially played over speakers at loud volume with strong bass frequency response.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To knock against or run into with a jolt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To move up or down by a step; displace.",
              synonyms: [],
              antonyms: [],
              example:
                "I bumped the font size up to make my document easier to read.",
            },
            {
              definition:
                "To post in an Internet forum thread in order to raise the thread's profile by returning it to the top of the list of active threads.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a superheated liquid) To suddenly boil, causing movement of the vessel and loss of liquid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To move (a booked passenger) to a later flight because of earlier delays or cancellations.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To move the time of (a scheduled event).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To pick (a lock) with a repeated striking motion that dislodges the pins.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make a loud, heavy, or hollow noise; to boom.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To spread out material so as to fill any desired number of pages.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bump",
        "https://en.wiktionary.org/wiki/bumps",
      ],
    },
  ],
  evils: [
    {
      word: "evils",
      phonetic: "/ˈiːvəlz/",
      phonetics: [
        {
          text: "/ˈiːvəlz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/evils-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79268183",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Moral badness; wickedness; malevolence; the forces or behaviors that are the opposite or enemy of good.",
              synonyms: [],
              antonyms: [],
              example:
                "Evil lacks spirituality, hence its need for mind control.",
            },
            {
              definition:
                "Something which impairs the happiness of a being or deprives a being of any good; something which causes suffering of any kind to sentient beings; harm; injury; mischief.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A malady or disease; especially in the phrase king's evil (scrofula).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["good"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/evil",
        "https://en.wiktionary.org/wiki/evils",
      ],
    },
  ],
  fangs: [
    {
      word: "fangs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A long, pointed canine tooth used for biting and tearing flesh",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in snakes) a long pointed tooth for injecting venom",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To strike or attack with the fangs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To enable to catch or tear; to furnish with fangs.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To catch, capture; seize; grip; clutch; lay hold of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To take; receive with assent; accept.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(as a guest) To receive with hospitality; welcome.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(a thing given or imposed) To receive.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To receive or adopt into spiritual relation, as in baptism; be godfather or godmother to.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "cop",
            "get",
            "greet",
            "welcome",
            "clasp",
            "grasp",
            "grip",
            "land",
            "lay hands on",
            "score",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A grasping; capture; the act or power of seizing; hold.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That which is seized or carried off; booty; spoils; stolen goods.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any projection, catch, shoot, or other thing by which hold is taken; a prehensile part or organ.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A channel cut in the rock, or a pipe of wood, used for conveying air.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(in the plural) Cage-shuts.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The coil or bend of a rope; (by extension) a noose; a trap.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The valve of a pump box.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To supply (a pump) with the water necessary for it to operate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fang",
        "https://en.wiktionary.org/wiki/fangs",
      ],
    },
  ],
  snout: [
    {
      word: "snout",
      phonetic: "/snʌʊt/",
      phonetics: [
        { text: "/snʌʊt/", audio: "" },
        {
          text: "/snaʊt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/snout-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=501163",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The long, projecting nose, mouth, and jaw of a beast, as of pigs.",
              synonyms: [],
              antonyms: [],
              example: "The pig rooted around in the dirt with its snout.",
            },
            {
              definition: "The front of the prow of a ship or boat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person's nose.",
              synonyms: [],
              antonyms: [],
              example:
                "His glasses kept slipping further down onto his prominent snout.",
            },
            {
              definition: "The nozzle of a pipe, hose, etc.",
              synonyms: [],
              antonyms: [],
              example:
                "If you place the snout right into the bucket, it won't spray as much.",
            },
            {
              definition:
                "The anterior prolongation of the head of a gastropod; a rostrum.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The anterior prolongation of the head of weevils and allied beetles; a rostrum.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Tobacco; cigarettes.", synonyms: [], antonyms: [] },
            {
              definition: "The terminus of a glacier.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A police informer.", synonyms: [], antonyms: [] },
            {
              definition:
                "A butterfly in the nymphalid subfamily Libytheinae, notable for the snout-like elongation on their heads.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To furnish with a nozzle or point.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/snout"],
    },
  ],
  peril: [
    {
      word: "peril",
      phonetic: "/ˈpɛɹəl/",
      phonetics: [{ text: "/ˈpɛɹəl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A situation of serious and immediate danger.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something that causes, contains, or presents danger.",
              synonyms: [],
              antonyms: [],
              example:
                "The perils of the jungle (animals and insects, weather, etc)",
            },
            {
              definition:
                "An event which causes a loss, or the risk of a specific such event.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["danger", "hazard", "jeopardy", "risk", "threat", "wathe"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cause to be in danger; to imperil; to risk.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/peril"],
    },
  ],
  pivot: [
    {
      word: "pivot",
      phonetic: "/ˈpɪvət/",
      phonetics: [
        {
          text: "/ˈpɪvət/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pivot-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50284270",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A thing on which something turns; specifically a metal pointed pin or short shaft in machinery, such as the end of an axle or spindle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) Something or someone having a paramount significance in a certain situation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Act of turning on one foot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The officer or soldier who simply turns in his place while the company or line moves around him in wheeling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A player with responsibility for co-ordinating their team in a particular jam.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An element of a set to be sorted that is chosen as a midpoint, so as to divide the other elements into two groups to be dealt with recursively.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A pivot table.", synonyms: [], antonyms: [] },
            {
              definition:
                "Any of a row of captioned elements used to navigate to subpages, rather like tabs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An element of a matrix that is used as a focus for row operations, such as dividing the row by the pivot, or adding multiples of the row to other rows making all other values in the pivot column 0.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A quarterback.", synonyms: [], antonyms: [] },
            {
              definition:
                "A shift during a general election in a political candidate's messaging to reflect plans and values more moderate than those advocated during the primary.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To turn on an exact spot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make a sudden or swift change in strategy, policy, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pivot"],
    },
  ],
  yacht: [
    {
      word: "yacht",
      phonetic: "/jɒt/",
      phonetics: [
        {
          text: "/jɒt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/yacht-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88671793",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/jɑt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/yacht-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=252407",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A slick and light ship for making pleasure trips or racing on water, having sails but often motor-powered. At times used as a residence offshore on a dock.",
              synonyms: [],
              antonyms: [],
              example: "Would you like to go sailing on my uncle’s yacht?",
            },
            {
              definition:
                "Any vessel used for private, noncommercial purposes.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To sail, voyage, or race in a yacht.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/yacht"],
    },
  ],
  lobby: [
    {
      word: "lobby",
      phonetic: "/lɒbi/",
      phonetics: [
        { text: "/lɒbi/", audio: "" },
        { text: "/lɑbi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An entryway or reception area; vestibule; passageway; corridor.",
              synonyms: [],
              antonyms: [],
              example:
                "I had to wait in the lobby for hours before seeing the doctor.",
            },
            {
              definition:
                "That part of a hall of legislation not appropriated to the official use of the assembly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A class or group of people who try to influence public officials; collectively, lobbyists.",
              synonyms: [],
              antonyms: [],
              example:
                "The influence of the tobacco lobby has decreased considerably in the US.",
            },
            {
              definition:
                "A virtual area where players can chat and find opponents for a game.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An apartment or passageway in the fore part of an old-fashioned cabin under the quarter-deck.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A confined place for cattle, formed by hedges, trees, or other fencing, near the farmyard.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A margin along either side of the playing field in the sport of kabaddi.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To attempt to influence (a public official or decision-maker) in favor of a specific opinion or cause.",
              synonyms: [],
              antonyms: [],
              example:
                "For years, pro-life groups have continued to lobby hard for restrictions on abortion.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lobby"],
    },
    {
      word: "lobby",
      phonetic: "/lɒbi/",
      phonetics: [
        { text: "/lɒbi/", audio: "" },
        { text: "/lɑbi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Scouse (from lobscouse)",
              synonyms: [],
              antonyms: [],
              example: "My mam cooked us lobby for tea last night.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lobby"],
    },
  ],
  jeans: [
    {
      word: "jeans",
      phonetic: "/d͡ʒiːnz/",
      phonetics: [
        {
          text: "/d͡ʒiːnz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/jeans-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=98158314",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Denim.",
              synonyms: [],
              antonyms: [],
              example: "She wore a tattered jean jacket.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A pair of trousers made from denim cotton.",
              synonyms: [],
              antonyms: [],
              example: "Traditionally most jeans are dyed dark blue.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/jean",
        "https://en.wiktionary.org/wiki/jeans",
      ],
    },
  ],
  grins: [
    {
      word: "grins",
      phonetic: "/ɡɹɪnz/",
      phonetics: [{ text: "/ɡɹɪnz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A smile in which the lips are parted to reveal the teeth.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To smile, parting the lips so as to show the teeth.",
              synonyms: [],
              antonyms: [],
              example: "Why do you grin?  Did I say something funny?",
            },
            {
              definition: "To express by grinning.",
              synonyms: [],
              antonyms: [],
              example: "She grinned pleasure at his embarrassment.",
            },
            {
              definition: "To show the teeth, like a snarling dog.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To grin as part of producing a particular facial expression, such as a smile or sneer.",
              synonyms: [],
              antonyms: [],
              example: "He grinned a broad smile when I told him the result.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A snare; a gin.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/grin",
        "https://en.wiktionary.org/wiki/grins",
      ],
    },
  ],
  viola: [
    {
      word: "viola",
      phonetic: "/viˈoʊ.lə/",
      phonetics: [
        { text: "/viˈoʊ.lə/", audio: "" },
        { text: "/viˈəʊ.lə/", audio: "" },
        {
          text: "/viˈoʊ.lə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/viola-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50423085",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A stringed instrument of the violin family, somewhat larger than a violin, played under the chin, and having a deeper tone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An organ stop having a similar tone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A 10-string steel-string acoustic guitar, used in Brazilian folk music.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A berimbau viola, the smallest member of the berimbau used in capoeira music.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/viola"],
    },
    {
      word: "viola",
      phonetic: "/ˈvaɪələ/",
      phonetics: [
        { text: "/ˈvaɪələ/", audio: "" },
        { text: "/vaɪˈoʊlə/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(botany) Any of several flowering plants, of the genus Viola, including the violets and pansies.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/viola"],
    },
    {
      word: "viola",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "Lo, there it is; see here; ta-da; presto; behold!",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/viola",
        "https://en.wiktionary.org/wiki/voil%C3%A0",
        "https://en.wiktionary.org/wiki/voila",
      ],
    },
  ],
  liner: [
    {
      word: "liner",
      phonetic: "/ˈlaɪnə/",
      phonetics: [
        {
          text: "/ˈlaɪnə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/liner-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79268612",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Someone who fits a lining to something.",
              synonyms: [],
              antonyms: [],
              example:
                "1973, A good liner has a pretty shrewd idea of the value of the painting he is treating and usually charges accordingly. — Kyril Bonfiglioli, Don't Point That Thing at Me (Penguin 2001, p. 41)",
            },
            {
              definition: "A removable cover or lining",
              synonyms: [],
              antonyms: [],
              example: "I threw out the trash can liner.",
            },
            {
              definition:
                "The pamphlet which is contained inside an album of music or movie",
              synonyms: [],
              antonyms: [],
              example: "Does it have the lyrics in the liner notes?",
            },
            {
              definition:
                "A lining within the cylinder of a steam engine, in which the piston works and between which and the outer shell of the cylinder a space is left to form a steam jacket.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A slab on which small pieces of marble, tile, etc., are fastened for grinding.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Formal no show sock", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/liner"],
    },
    {
      word: "liner",
      phonetic: "/ˈlaɪnə/",
      phonetics: [
        {
          text: "/ˈlaɪnə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/liner-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79268612",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A large passenger-carrying ship, especially one on a regular route; an ocean liner.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A ship of the line.", synonyms: [], antonyms: [] },
            { definition: "A line drive.", synonyms: [], antonyms: [] },
            { definition: "A basic salesperson.", synonyms: [], antonyms: [] },
            {
              definition:
                "(in combination) Something with a specified number of lines.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Short for penny-a-liner.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/liner"],
    },
  ],
  comet: [
    {
      word: "comet",
      phonetic: "/ˈkɒmət/",
      phonetics: [
        {
          text: "/ˈkɒmət/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/comet-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1234851",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                'A celestial body consisting mainly of ice, dust and gas in a (usually very eccentric) orbit around the Sun and having a "tail" of matter blown back from it by the solar wind as it approaches the Sun.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A celestial phenomenon with the appearance given by the orbiting celestial body.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of several species of hummingbird found in the Andes.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["faxed star"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/comet"],
    },
  ],
  scars: [
    {
      word: "scars",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scars-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80066792",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/skɑɹz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A permanent mark on the skin, sometimes caused by the healing of a wound.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) A permanent negative effect on someone's mind, caused by a traumatic experience.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any permanent mark resulting from damage.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["cicatrice", "cicatrix"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To mark the skin permanently.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To form a scar.", synonyms: [], antonyms: [] },
            {
              definition: "To affect deeply in a traumatic manner.",
              synonyms: [],
              antonyms: [],
              example:
                "Seeing his parents die in a car crash scarred him for life.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A cliff or rock outcrop.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A rock in the sea breaking out from the surface of the water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bare rocky place on the side of a hill or mountain.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A marine food fish, the scarus or parrotfish (family Scaridae).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/scar",
        "https://en.wiktionary.org/wiki/scars",
      ],
    },
  ],
  chops: [
    {
      word: "chops",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chops-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79267964",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/t͡ʃɒps/", audio: "" },
        { text: "/t͡ʃɑps/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A cut of meat, often containing a section of a rib.",
              synonyms: [],
              antonyms: [],
              example: "I only like lamb chops with mint jelly.",
            },
            {
              definition: "A blow with an axe, cleaver, or similar utensil.",
              synonyms: [],
              antonyms: [],
              example: "It should take just one good chop to fell the sapling.",
            },
            {
              definition:
                "A blow delivered with the hand rigid and outstretched.",
              synonyms: [],
              antonyms: [],
              example: "A karate chop.",
            },
            {
              definition:
                "Ocean waves, generally caused by wind, distinguished from swell by being smaller and not lasting as long.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hand where two or more players have an equal-valued hand, resulting in the chips being shared equally between them.",
              synonyms: [],
              antonyms: [],
              example:
                "With both players having an ace-high straight, the pot was a chop.",
            },
            {
              definition:
                '(with "the") Termination, especially from employment; the sack.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A woodchopping competition.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A crack or cleft; a chap.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["axe", "pink slip", "sack"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cut into pieces with short, vigorous cutting motions.",
              synonyms: [],
              antonyms: [],
              example: "chop wood; chop an onion",
            },
            {
              definition: "To sever with an axe or similar implement.",
              synonyms: [],
              antonyms: [],
              example: "Chop off his head.",
            },
            {
              definition:
                "To give a downward cutting blow or movement, typically with the side of the hand.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To hit the ball downward so that it takes a high bounce.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To divide the pot (or tournament prize) between two or more players.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make a quick, heavy stroke or a series of strokes, with or as with an ax.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To do something suddenly with an unexpected motion; to catch or attempt to seize.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To interrupt; with in or out.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Perl) To remove the final character from (a text string).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A turn of fortune; change; a vicissitude.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To exchange, to barter; to swap.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To chap or crack.", synonyms: [], antonyms: [] },
            {
              definition: "To vary or shift suddenly.",
              synonyms: [],
              antonyms: [],
              example: "The wind chops about.",
            },
            { definition: "To twist words.", synonyms: [], antonyms: [] },
            {
              definition: "To converse, discuss, or speak with another.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(chiefly in the plural) A jaw of an animal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A movable jaw or cheek, as of a vice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The land at each side of the mouth of a river, harbour, or channel.",
              synonyms: [],
              antonyms: [],
              example: "East Chop; West Chop",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An official stamp or seal, as in China and India.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A mark indicating nature, quality, or brand.",
              synonyms: [],
              antonyms: [],
              example: "silk of the first chop",
            },
            {
              definition: "A license or passport that has been sealed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A complete shipment.",
              synonyms: [],
              antonyms: [],
              example: "a chop of tea",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An IRC channel operator.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["chanop", "op"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Jaws, mouth.", synonyms: [], antonyms: [] },
            {
              definition:
                "One's skill at musical interpretation and delivery (originally of jazz); musical performance ability.",
              synonyms: [],
              antonyms: [],
              example:
                "Although the bass player had no experience playing in New Orleans, the crowd's enthusiastic response showed that he had the chops to make it in the very particular Crescent City jazz scene.",
            },
            {
              definition:
                "One's skill at any endeavor; ability, talent; competency.",
              synonyms: [],
              antonyms: [],
              example:
                "Although he did not know all of the ins and outs of the newsroom, he had the writing chops to become a regular contributor.",
            },
            {
              definition:
                "The area where two tides meet and cause an irregular (choppy) sea.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A pattern that involves carrying the object with the hand over the next object before throwing it.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/chop",
        "https://en.wiktionary.org/wiki/chops",
      ],
    },
    {
      word: "chops",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chops-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79267964",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/t͡ʃɒps/", audio: "" },
        { text: "/t͡ʃɑps/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To talk, chat; also, to talk back, to be cheeky.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chops"],
    },
  ],
  raids: [
    {
      word: "raids",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A quick hostile or predatory incursion or invasion in a battle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An attack or invasion for the purpose of making arrests, seizing property, or plundering",
              synonyms: [],
              antonyms: [],
              example: "a police raid of a narcotics factory",
            },
            {
              definition: "An attacking movement.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An activity initiated at or towards the end of a live broadcast by the broadcaster that sends its viewers to a different broadcast, primarily intended to boost the viewership of the receiving broadcaster. This is frequently accompanied by a message in the form of a hashtag that is posted in the broadcast's chat by the viewers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A large group in a massively multiplayer online game, consisting of multiple parties who team up to defeat a powerful enemy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["irruption", "attack", "foray", "incursion"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To engage in a raid against.",
              synonyms: [],
              antonyms: [],
              example: "The police raided the gambling den.",
            },
            {
              definition: "To lure from another; to entice away from.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To indulge oneself by taking from.",
              synonyms: [],
              antonyms: [],
              example: "I raided the fridge for snacks.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/raid",
        "https://en.wiktionary.org/wiki/raids",
      ],
    },
  ],
  eater: [
    {
      word: "eater",
      phonetic: "/ˈiːtə/",
      phonetics: [
        { text: "/ˈiːtə/", audio: "" },
        { text: "/ˈiːtɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "One who eats.", synonyms: [], antonyms: [] },
            { definition: "An eating apple.", synonyms: [], antonyms: [] },
            {
              definition: "One who performs fellatio or cunnilingus.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A configuration of cells that appears to consume another configuration by gradually causing it to disappear.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/eater"],
    },
  ],
  slate: [
    {
      word: "slate",
      phonetic: "/sleɪt/",
      phonetics: [
        {
          text: "/sleɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/slate-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88675752",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A fine-grained homogeneous sedimentary rock composed of clay or volcanic ash which has been metamorphosed so that it cleaves easily into thin layers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The bluish-grey colour of most slate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A chalkboard, sheet of slate for writing on with chalk or with a thin rod of slate (a slate pencil) formerly commonly used by both students and teachers in schools",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A roofing-tile made of slate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A record of money owed.",
              synonyms: [],
              antonyms: [],
              example: "Put it on my slate – I’ll pay you next week.",
            },
            {
              definition: "A list of affiliated candidates for an election.",
              synonyms: [],
              antonyms: [],
              example:
                "Roy Disney led the alternative slate of directors for the stockholder vote.",
            },
            {
              definition:
                "An artificial material resembling slate and used for the same purposes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A thin plate of any material; a flake.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "slate blue",
            "slate gray",
            "slate grey",
            "account",
            "bill",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having the bluish-grey/gray colour of slate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/slate"],
    },
    {
      word: "slate",
      phonetic: "/sleɪt/",
      phonetics: [
        {
          text: "/sleɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/slate-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88675752",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cover with slate.",
              synonyms: [],
              antonyms: [],
              example:
                "The old church ledgers show that the roof was slated in 1775.",
            },
            {
              definition: "To criticise harshly.",
              synonyms: [],
              antonyms: [],
              example: "The play was slated by the critics.",
            },
            {
              definition: "To schedule.",
              synonyms: [],
              antonyms: [],
              example: "The election was slated for November 2nd.",
            },
            {
              definition: "To anticipate or strongly expect.",
              synonyms: [],
              antonyms: [],
              example:
                "The next version of our software is slated to be the best release ever.",
            },
            {
              definition: "To set a dog upon (a person).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/slate"],
    },
  ],
  skips: [
    {
      word: "skips",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A leaping, jumping or skipping movement.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The act of passing over an interval from one thing to another; an omission of a part.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A passage from one sound to another by more than a degree at once.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person who attempts to disappear so as not to be found.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Skywave propagation", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To move by hopping on alternate feet.",
              synonyms: [],
              antonyms: [],
              example:
                "She will skip from one end of the sidewalk to the other.",
            },
            {
              definition: "To leap about lightly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To skim, ricochet or bounce over a surface.",
              synonyms: [],
              antonyms: [],
              example: "The rock will skip across the pond.",
            },
            {
              definition:
                "To throw (something), making it skim, ricochet, or bounce over a surface.",
              synonyms: [],
              antonyms: [],
              example:
                "I bet I can skip this rock to the other side of the pond.",
            },
            {
              definition:
                "To disregard, miss or omit part of a continuation (some item or stage).",
              synonyms: [],
              antonyms: [],
              example:
                "I will read most of the book, but skip the first chapter because the video covered it.",
            },
            {
              definition: "To place an item in a skip.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Not to attend (some event, especially a class or a meeting).",
              synonyms: [],
              antonyms: [],
              example:
                "Yeah, I really should go to the quarterly meeting but I think I'm going to skip it.",
            },
            {
              definition: "To leave, especially in a sudden and covert manner.",
              synonyms: [],
              antonyms: [],
              example:
                "a customer who skipped town without paying her hotel bill",
            },
            {
              definition: "To leap lightly over.",
              synonyms: [],
              antonyms: [],
              example: "to skip the rope",
            },
            {
              definition: "To jump rope.",
              synonyms: [],
              antonyms: [],
              example: "The girls were skipping in the playground.",
            },
            {
              definition:
                "(crocheting) To pass by a stitch as if it were not there, continuing with the next stitch.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["play hookie"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A large open-topped container for waste, designed to be lifted onto the back of a truck to remove it along with its contents. (see also skep).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A transportation container in a mine, usually for ore or mullock.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A skep, or basket.", synonyms: [], antonyms: [] },
            {
              definition: "A wheeled basket used in cotton factories.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(sugar manufacture) A charge of syrup in the pans.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A beehive.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["dumpster"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Short for skipper, the master or captain of a ship, or other person in authority.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(specially) The captain of a sports team. Also, a form of address by the team to the captain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The player who calls the shots and traditionally throws the last two rocks.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(bowls) The captain of a bowls team, who directs the team's tactics and rolls the side's last wood, so as to be able to retrieve a difficult situation if necessary.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The scoutmaster of a troop of scouts (youth organization) and their form of address to him.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An Australian of Anglo-Celtic descent.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(college slang) A college servant.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/skip",
        "https://en.wiktionary.org/wiki/skips",
      ],
    },
  ],
  soles: [
    {
      word: "soles",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A wooden band or yoke put around the neck of an ox or cow in the stall.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A pond or pool; a dirty pond of standing water.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The bottom or plantar surface of the foot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(footwear) The bottom of a shoe or boot.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The foot itself.", synonyms: [], antonyms: [] },
            {
              definition: "Solea solea, a flatfish of the family Soleidae.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The bottom or lower part of anything, or that on which anything rests in standing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The seat or bottom of a mine; applied to horizontal veins or lodes.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["planta"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sole",
        "https://en.wiktionary.org/wiki/soles",
      ],
    },
  ],
  misty: [
    {
      word: "misty",
      phonetic: "/ˈmɪsti/",
      phonetics: [{ text: "/ˈmɪsti/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Covered in mist; foggy.",
              synonyms: [],
              antonyms: [],
              example: "It's very misty this morning; I can't see a thing!",
            },
            {
              definition: "Dim; vague; obscure.",
              synonyms: [],
              antonyms: [],
              example: "a misty memory of his childhood",
            },
            {
              definition: "With tears in the eyes; dewy-eyed.",
              synonyms: [],
              antonyms: [],
              example:
                "Her eyes grew misty the night her long-time friend passed away.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/misty"],
    },
  ],
  urine: [
    {
      word: "urine",
      phonetic: "/ˈjʉːəɹɪn/",
      phonetics: [
        { text: "/ˈjʉːəɹɪn/", audio: "" },
        { text: "/ˈjʊəɹaɪn/", audio: "" },
        { text: "/ˈjɝɪn/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Liquid waste consisting of water, salts and urea, which is made in the kidneys, stored in the bladder, then released through the urethra.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To urinate.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/urine"],
    },
  ],
  knobs: [
    {
      word: "knobs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A rounded protuberance, especially one arising from a flat surface; a fleshy lump or caruncle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A rounded control switch that can be turned on its axis, designed to be operated by the fingers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A ball-shaped part of a handle, lever, etc., designed to be grabbed by the hand.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A rounded ornament on the hilt of an edged weapon; a pommel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A prominent, rounded bump along a mountain ridge.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A prominent rounded hill.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(chiefly in the plural) A woman's breast.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The penis.", synonyms: [], antonyms: [] },
            {
              definition: "The head of the penis; the glans.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by analogy with above) A contemptible person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A dollop, an amount just larger than a spoonful (usually referring to butter).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A chunky branch-like piece, especially of a ginger rhizome.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bulb of the garlic plant consisting of multiple cloves.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/knob",
        "https://en.wiktionary.org/wiki/knobs",
      ],
    },
  ],
  sleet: [
    {
      word: "sleet",
      phonetic: "/sliːt/",
      phonetics: [
        {
          text: "/sliːt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sleet-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88675827",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Pellets of ice made of mostly frozen raindrops or refrozen melted snowflakes.",
              synonyms: ["ice pellets"],
              antonyms: [],
            },
            {
              definition: "A mixture of rain and snow.",
              synonyms: ["slush"],
              antonyms: [],
            },
            {
              definition:
                "A smooth coating of ice formed on ground or other objects by freezing rain.",
              synonyms: ["black ice", "glaze"],
              antonyms: [],
            },
            {
              definition:
                "Part of a mortar extending from the chamber to the trunnions.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["black ice", "glaze", "ice pellets", "slush"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(of the weather) To be in a state in which sleet is falling.",
              synonyms: [],
              antonyms: [],
              example: "I won't bother going out until it's stopped sleeting.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sleet"],
    },
  ],
  holly: [
    {
      word: "holly",
      phonetic: "/ˈhɒli/",
      phonetics: [
        { text: "/ˈhɒli/", audio: "" },
        {
          text: "/ˈhɑli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/holly-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780142",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of various shrubs or (mostly) small trees, of the genus Ilex, either evergreen or deciduous, used as decoration especially at Christmas.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The wood from this tree.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(with a qualifier) Any of several unrelated plant species likened to Ilex because of their prickly, evergreen foliage and/or round, bright-red berries",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/holly"],
    },
  ],
  pests: [
    {
      word: "pests",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A plague, pestilence, epidemic",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any destructive insect or caterpillar that attacks crops or livestock; an agricultural pest.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An annoying person, a nuisance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An animal regarded as a nuisance, destructive, or a parasite, vermin.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An invasive weed.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["bug"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pest",
        "https://en.wiktionary.org/wiki/pests",
      ],
    },
  ],
  forks: [
    {
      word: "forks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A pronged tool having a long straight handle, used for digging, lifting, throwing etc.",
              synonyms: ["pitchfork"],
              antonyms: [],
            },
            {
              definition:
                "A pronged tool for use in the garden; a smaller hand fork for weeding etc., or larger for turning over the soil.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A gallows.", synonyms: [], antonyms: [] },
            {
              definition:
                "A utensil with spikes used to put solid food into the mouth, or to hold food down while cutting.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A tuning fork.", synonyms: [], antonyms: [] },
            {
              definition:
                "An intersection in a road or path where one road is split into two.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the parts into which anything is furcated or divided; a prong; a branch of a stream, a road, etc.; a barbed point, as of an arrow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A point where a waterway, such as a river, splits and goes two (or more) different directions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Used in the names of some river tributaries.",
              synonyms: ["branch"],
              antonyms: [],
              example:
                "West Fork White River and East Fork White River join together to form the White River of Indiana.",
            },
            {
              definition:
                "A point in time where one has to make a decision between two life paths.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The simultaneous attack of two adversary pieces with one single attacking piece (especially a knight).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A splitting-up of an existing process into itself and a child process executing parts of the same program.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The splitting of a software development effort into two or more separate projects, especially in free and open-source software.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of the software projects resulting from such a split.",
              synonyms: [],
              antonyms: [],
              example: "LibreOffice is a fork of OpenOffice.",
            },
            {
              definition:
                "(cryptocurrency, by extension) A split in a blockchain resulting from protocol disagreements, or a branch of the blockchain resulting from such a split.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The crotch.", synonyms: [], antonyms: [] },
            {
              definition: "A forklift.",
              synonyms: [],
              antonyms: [],
              example: "Are you qualified to drive a fork?",
            },
            {
              definition:
                "The set of blades of a forklift, on which the goods to be raised are loaded.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In a bicycle or motorcycle, the portion of the frameset holding the front wheel, allowing the rider to steer and balance, also called front fork.",
              synonyms: [],
              antonyms: [],
              example:
                "The fork can be equipped with a suspension on mountain bikes.",
            },
            {
              definition:
                "The upper front brow of a saddle bow, connected in the tree by the two saddle bars to the cantle on the other end.",
              synonyms: ["pommel", "swell"],
              antonyms: [],
            },
          ],
          synonyms: ["branch", "pitchfork", "pommel", "swell"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To divide into two or more branches.",
              synonyms: [],
              antonyms: [],
              example: "A road, a tree, or a stream forks.",
            },
            {
              definition: "To move with a fork (as hay or food).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To spawn a new child process in some sense duplicating the existing process.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To split a (software) project into several projects.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To split a (software) distributed version control repository",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To kick someone in the crotch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To shoot into blades, as corn does.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The bottom of a sump into which the water of a mine drains.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To bale a shaft dry.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To have sexual intercourse, to copulate.",
              synonyms: [
                "bang",
                "do it",
                "eff",
                "have sex",
                "hump",
                "screw",
                "shag",
              ],
              antonyms: [],
              example: "Fighting for peace is like fucking for virginity.",
            },
            {
              definition: "To have sexual intercourse with.",
              synonyms: [
                "bang",
                "eff",
                "give someone one",
                "hump",
                "ream",
                "screw",
                "shag",
              ],
              antonyms: [],
            },
            {
              definition:
                "To insert one’s penis, a dildo or other phallic object, into a specified orifice or cleft.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To put in an extremely difficult or impossible situation.",
              synonyms: [],
              antonyms: [],
              example: "I'm afraid they're gonna fuck you on this one.",
            },
            {
              definition: "To defraud, deface or otherwise treat badly.",
              synonyms: [],
              antonyms: [],
              example: "I got fucked at the used car lot.",
            },
            {
              definition:
                "Used to express great displeasure with someone or something.",
              synonyms: ["bugger", "eff"],
              antonyms: [],
              example: "Fuck those jerks, and fuck their stupid rules!",
            },
            {
              definition: "(usually followed by up) To break, to destroy.",
              synonyms: ["annihilate", "obliterate", "ruin"],
              antonyms: [],
            },
            {
              definition:
                "To make a joke at one's expense; to make fun of in an embarrassing manner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To throw, to lob something. (angrily)",
              synonyms: ["feck"],
              antonyms: [],
              example: "He fucked the dirty cloth out the window.",
            },
            {
              definition: "To scold",
              synonyms: [],
              antonyms: [],
              example: "The sergeant fucked me upside down.",
            },
          ],
          synonyms: [
            "annihilate",
            "obliterate",
            "ruin",
            "bang",
            "do it",
            "eff",
            "have sex",
            "hump",
            "screw",
            "shag",
            "bang",
            "eff",
            "give someone one",
            "hump",
            "ream",
            "screw",
            "shag",
            "bugger",
            "eff",
            "feck",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fork",
        "https://en.wiktionary.org/wiki/forks",
        "https://en.wiktionary.org/wiki/fuck",
      ],
    },
  ],
  grill: [
    {
      word: "grill",
      phonetic: "/ɡɹɪl/",
      phonetics: [
        {
          text: "/ɡɹɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grill-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75271653",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ɡɹɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grill-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=266952",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A grating; a grid of wire or a sheet of material with a pattern of holes or slots, usually used to protect something while allowing the passage of air and liquids. Typical uses: to allow air through a fan while preventing fingers or objects from passing; to allow people to talk to somebody, while preventing attack.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "On a vehicle, a slotted cover as above, to protect and hide the radiator, while admitting air to cool it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cooking device comprising a source of radiative heat and a means of holding food under it; a broiler in US English",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cooking device comprising a source of radiative and convective heat and a means of holding food above it; a barbecue.",
              synonyms: [],
              antonyms: [],
              example:
                "I put some peppers and mushrooms on the grill to go with dinner.",
            },
            {
              definition: "Food (designed to be) cooked on a grill.",
              synonyms: [],
              antonyms: [],
              example: "a packet of frozen cauliflower cheese grills",
            },
            {
              definition: "A grillroom; a restaurant serving grilled food.",
              synonyms: [],
              antonyms: [],
              example:
                "These coupons will get you a discount at Johnny's Bar and Grill.",
            },
            {
              definition: "A type of jewelry worn on the front teeth.",
              synonyms: ["fronts", "golds"],
              antonyms: [],
            },
            {
              definition:
                "(by extension) The front teeth regarded collectively.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["fronts", "golds"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cook (food) on a grill; to barbecue.",
              synonyms: [],
              antonyms: [],
              example:
                "Why don't we get together Saturday and grill some burgers?",
            },
            {
              definition:
                "To cook food under the element of a stove or only under the top element of an oven – broil, salamander.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To interrogate; to question aggressively or harshly.",
              synonyms: [],
              antonyms: [],
              example:
                "The police grilled him about his movements at the time of the crime.",
            },
            {
              definition: "To feel very hot; to swelter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To stamp or mark with a grill.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/grill"],
    },
    {
      word: "grill",
      phonetic: "/ɡɹɪl/",
      phonetics: [
        {
          text: "/ɡɹɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grill-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75271653",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ɡɹɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grill-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=266952",
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make angry; provoke; incite.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To terrify; make tremble.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To tremble; shiver.", synonyms: [], antonyms: [] },
            { definition: "To snarl; snap.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/grill"],
    },
    {
      word: "grill",
      phonetic: "/ɡɹɪl/",
      phonetics: [
        {
          text: "/ɡɹɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grill-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75271653",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ɡɹɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grill-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=266952",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "Harm.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Harsh, rough, severe; cruel.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/grill"],
    },
  ],
  trays: [
    {
      word: "trays",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small, typically rectangular or round, flat, and rigid object upon which things are carried.",
              synonyms: [],
              antonyms: [],
              example:
                "I carefully arranged the dishes on the tray and brought it upstairs.",
            },
            {
              definition: "A flat carrier for items being transported.",
              synonyms: [],
              antonyms: [],
              example: "Make sure that tray of eggs is properly loaded.",
            },
            {
              definition: "The items on a full tray.",
              synonyms: [],
              antonyms: [],
              example:
                "Before long they had consumed a whole tray of shrimp cocktails and sent for another.",
            },
            {
              definition:
                "A component of a device into which an item is placed for use in the device's operations.",
              synonyms: [],
              antonyms: [],
              example: "The CD tray will not open.",
            },
            {
              definition: "A notification area used for icons and alerts.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A type of retail or wholesale packaging for CPUs where the processors are sold in bulk and/or with minimal packaging.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(pickup) truck bed",
              synonyms: ["bed"],
              antonyms: [],
            },
          ],
          synonyms: ["bed"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To place (items) on a tray",
              synonyms: [],
              antonyms: [],
              example: "Be sure to tray eggs with the large end up.",
            },
            {
              definition:
                "To slide down a snow-covered hill on a tray from a cafeteria.",
              synonyms: [],
              antonyms: [],
              example:
                "Traying has provided collegiate fun and the occasional fatality for decades.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Trouble; annoyance; anger",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To grieve; to annoy", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To betray", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A playing card or die with the rank of three.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A score of three in cards, dice, or dominoes.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A three-pointer.", synonyms: [], antonyms: [] },
            {
              definition:
                "The third bearer of the same personal name in a family, often denoted by suffixed Roman numeral III.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The third branch of a deer's antler.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tray",
        "https://en.wiktionary.org/wiki/trays",
        "https://en.wiktionary.org/wiki/trey",
      ],
    },
  ],
  pails: [
    {
      word: "pails",
      phonetic: "/peɪlz/",
      phonetics: [
        {
          text: "/peɪlz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pails-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80151796",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A vessel of wood, tin, plastic, etc., usually cylindrical and having a handle -- used especially for carrying liquids, for example water or milk; a bucket (sometimes with a cover).",
              synonyms: ["bucket"],
              antonyms: [],
              example: "The milkmaid carried a pail of milk in each hand.",
            },
            {
              definition:
                "(In technical use) A closed (covered) cylindrical shipping container.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bucket"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pail",
        "https://en.wiktionary.org/wiki/pails",
      ],
    },
  ],
  borne: [
    {
      word: "borne",
      phonetic: "/bɔːn/",
      phonetics: [
        { text: "/bɔːn/", audio: "" },
        {
          text: "/bɔɹn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/borne-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1684564",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To carry or convey, literally or figuratively.",
              synonyms: [],
              antonyms: [],
              example:
                "Judging from the look on his face, he wasn't bearing good news.",
            },
            {
              definition: "To support, sustain, or endure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To support, keep up, or maintain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To press or impinge upon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To produce, yield, give birth to.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(originally nautical) To be, or head, in a specific direction or azimuth (from somewhere).",
              synonyms: [],
              antonyms: [],
              example:
                "By my readings, we're bearing due south, so we should turn about ten degrees east.",
            },
            { definition: "To gain or win.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["brook", "endure"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Carried, supported.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bear",
        "https://en.wiktionary.org/wiki/borne",
      ],
    },
  ],
  tenor: [
    {
      word: "tenor",
      phonetic: "/tɛnə(ɹ)/",
      phonetics: [
        {
          text: "/tɛnə(ɹ)/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tenor-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=877616",
        },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tenor-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=830190",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A musical range or section higher than bass and lower than alto.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person, instrument or group that performs in the tenor (higher than bass and lower than alto) range.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A musical part or section that holds or performs the main melody, as opposed to the contratenor bassus and contratenor altus, who perform countermelodies.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The lowest tuned in a ring of bells.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Tone, as of a conversation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Duration; continuance; a state of holding on in a continuous course; general tendency; career.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The subject in a metaphor to which attributes are ascribed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Time to maturity of a bond.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Stamp; character; nature.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An exact copy of a writing, set forth in the words and figures of it. It differs from purport, which is only the substance or general import of the instrument.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That course of thought which holds on through a discourse; the general drift or course of thought; purport; intent; meaning; understanding.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A tenor saxophone.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of or pertaining to the tenor part or range.",
              synonyms: [],
              antonyms: [],
              example: "He has a tenor voice.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tenor"],
    },
  ],
  wares: [
    {
      word: "wares",
      phonetic: "/wɛəz/",
      phonetics: [
        { text: "/wɛəz/", audio: "" },
        {
          text: "/wɛɹz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wares-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651710",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(usually in combination) Goods or a type of goods offered for sale or use.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(in the plural) See wares.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Pottery or metal goods.",
              synonyms: [],
              antonyms: [],
              example: "damascene ware, tole ware",
            },
            {
              definition: "A style or genre of artifact.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Crockery.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Goods or services that are for sale.",
              synonyms: [],
              antonyms: [],
              example:
                "The square was filled with booths, with vendors offering their wares.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ware",
        "https://en.wiktionary.org/wiki/wares",
      ],
    },
  ],
  carol: [
    {
      word: "carol",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small closet or enclosure built against a window on the inner side, to sit in for study.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Hence, a partially partitioned space for studying or reading, often in a library.",
              synonyms: [],
              antonyms: [],
              example:
                "He was busy writing his report in a small library carrel.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/carol",
        "https://en.wiktionary.org/wiki/carrel",
      ],
    },
  ],
  woody: [
    {
      word: "woody",
      phonetic: "/ˈwʊdi/",
      phonetics: [{ text: "/ˈwʊdi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An early station wagon or estate car in which the rear portion of the car's bodywork is made of wood, often associated with Southern California surfing culture.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A wooden rollercoaster; an amusement ride whose rails are overlaid upon a wooden track.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An erection of the penis.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Covered in woods; wooded.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Belonging to the woods; sylvan.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Made of wood, or having wood-like properties.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Non-herbaceous.",
              synonyms: [],
              antonyms: [],
              example:
                "Subshrubs, shrubs, trees and lianas are all woody plants.",
            },
            {
              definition: "Lignified.",
              synonyms: [],
              antonyms: [],
              example: "the woody parts of a plant",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/woodie",
        "https://en.wiktionary.org/wiki/woody",
      ],
    },
  ],
  canon: [
    {
      word: "canon",
      phonetic: "/ˈkæn.ən/",
      phonetics: [
        {
          text: "/ˈkæn.ən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/canon-1-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89259666",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A generally accepted principle; a rule.",
              synonyms: [],
              antonyms: [],
              example: "The trial must proceed according to the canons of law.",
            },
            {
              definition:
                "A group of literary works that are generally accepted as representing a field.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The works of a writer that have been accepted as authentic.",
              synonyms: [],
              antonyms: [],
              example: "the entire Shakespeare canon",
            },
            {
              definition: "A eucharistic prayer, particularly the Roman Canon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A religious law or body of law decreed by the church.",
              synonyms: [],
              antonyms: [],
              example: "We must proceed according to canon law.",
            },
            {
              definition:
                "A catalogue of saints acknowledged and canonized in the Roman Catholic Church.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In monasteries, a book containing the rules of a religious order.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A member of a cathedral chapter; one who possesses a prebend in a cathedral or collegiate church.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of music in which the same melody is played by different voices, but beginning at different times; a round.",
              synonyms: [],
              antonyms: [],
              example: "Pachelbel’s Canon has become very popular.",
            },
            {
              definition:
                "(Roman law) A rent or stipend payable at some regular time, generally annual, e.g., canon frumentarius",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Those sources, especially including literary works, which are considered part of the main continuity regarding a given fictional universe.",
              synonyms: [],
              antonyms: [],
              example:
                "A spin-off book series revealed the aliens to be originally from Earth, but it's not canon.",
            },
            {
              definition:
                "A rolled and filleted loin of meat; also called cannon.",
              synonyms: [],
              antonyms: [],
              example: "a canon of beef or lamb",
            },
            {
              definition:
                "A large size of type formerly used for printing the church canons, standardized as 48-point.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The part of a bell by which it is suspended; the ear or shank of a bell.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A carom.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["French canon"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/canon"],
    },
    {
      word: "canon",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A clergy member serving a cathedral or collegiate church.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A canon regular, a member of any of several Roman Catholic religious orders.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/canon"],
    },
    {
      word: "canon",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A Near Eastern and Caucasian musical instrument related to the zither, dulcimer, or harp having either 26 strings and a single bridge, or twice that number and two bridges.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/canon",
        "https://en.wiktionary.org/wiki/qanun",
      ],
    },
  ],
  wakes: [
    {
      word: "wakes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act of waking, or state of being awake.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The state of forbearing sleep, especially for solemn or festive purposes; a vigil.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(often followed by up) To stop sleeping.",
              synonyms: [],
              antonyms: [],
              example: "I woke up at four o'clock this morning.",
            },
            {
              definition:
                "(often followed by up) To make somebody stop sleeping; to rouse from sleep.",
              synonyms: [],
              antonyms: [],
              example:
                "The neighbour's car alarm woke me from a strange dream.",
            },
            {
              definition: "To put in motion or action; to arouse; to excite.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be excited or roused up; to be stirred up from a dormant, torpid, or inactive state; to be active.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To lay out a body prior to burial in order to allow family and friends to pay their last respects.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To watch, or sit up with, at night, as a dead body.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be or remain awake; not to sleep.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be alert; to keep watch",
              synonyms: [],
              antonyms: [],
              example: "Command unto the guards that they diligently wake.",
            },
            {
              definition:
                "To sit up late for festive purposes; to hold a night revel.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A period after a person's death before or after the body is buried, cremated, etc.; in some cultures accompanied by a party and/or collectively sorting through the deceased's personal effects.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Church of England) An annual parish festival formerly held in commemoration of the dedication of a church. Originally, prayers were said on the evening preceding, and hymns were sung during the night, in the church; subsequently, these vigils were discontinued, and the day itself, often with succeeding days, was occupied in rural pastimes and exercises, attended by eating and drinking.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A number of vultures assembled together.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["death watch"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The path left behind a ship on the surface of the water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The turbulent air left behind a flying aircraft.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The area behind something, typically a rapidly moving object.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A community holiday, particularly in northern England.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wake",
        "https://en.wiktionary.org/wiki/wakes",
      ],
    },
  ],
  kitty: [
    {
      word: "kitty",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/kitty-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241582",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈkɪti/", audio: "" },
        { text: "/ˈkɪti/", audio: "" },
        { text: "/ˈkɪti/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A kitten or young cat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(sometimes capitalized) A pet name for a cat.",
              synonyms: [],
              antonyms: [],
              example: "Aw, look at the kitty!",
            },
            {
              definition:
                "A money pool, as for a card game, or for shared expenses.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In a home game, a small, specified amount taken from each pot to pay the host's expenses.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Female genitals. (Euphemistic form of pussy.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A set of additional cards dealt face down in some games.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["pot"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/kitty"],
    },
  ],
  miner: [
    {
      word: "miner",
      phonetic: "/ˈmaɪnə/",
      phonetics: [
        {
          text: "/ˈmaɪnə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/miner-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769998",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A person who works in a mine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An operator of ordnance mines and similar explosives.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any bird of one of four species of Australian endemic honeyeaters in the genus Manorina.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any bird of one of several species of South American ovenbirds in the genus Geositta.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Software or hardware that mines, or creates new units of cryptocurrency.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/miner"],
    },
  ],
  polls: [
    {
      word: "polls",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A survey of people, usually statistically analyzed to gauge wider public opinion.",
              synonyms: ["election", "survey"],
              antonyms: [],
            },
            {
              definition: "A formal election.",
              synonyms: [],
              antonyms: [],
              example:
                "The student council had a poll to see what people want served in the cafeteria.",
            },
            {
              definition: "A polling place (usually as plural, polling places)",
              synonyms: [],
              antonyms: [],
              example: "The polls close at 8 p.m.",
            },
            {
              definition:
                "(now rare outside veterinary medicine contexts) The head, particularly the scalp or pate upon which hair (normally) grows.",
              synonyms: ["scalp"],
              antonyms: [],
            },
            {
              definition:
                "(in extended senses of the above) A mass of people, a mob or muster, considered as a head count.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The broad or butt end of an axe or a hammer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The pollard or European chub, a kind of fish.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["election", "survey", "scalp"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To take, record the votes of (an electorate).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To solicit mock votes from (a person or group).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To vote at an election.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To register or deposit, as a vote; to elicit or call forth, as votes or voters.",
              synonyms: [],
              antonyms: [],
              example: "He polled a hundred votes more than his opponent.",
            },
            {
              definition:
                "To cut off; to remove by clipping, shearing, etc.; to mow or crop.",
              synonyms: [],
              antonyms: [],
              example: "to poll the hair; to poll wool; to poll grass",
            },
            {
              definition: "To cut the hair of (a creature).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To remove the horns of (an animal).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To remove the top or end of; to clip; to lop.",
              synonyms: [],
              antonyms: [],
              example: "to poll a tree",
            },
            {
              definition:
                "(communication) To (repeatedly) request the status of something (such as a computer or printer on a network).",
              synonyms: [],
              antonyms: [],
              example:
                "The network hub polled the department's computers to determine which ones could still respond.",
            },
            {
              definition: "(with adverb) To be judged in a poll.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To extort from; to plunder; to strip.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To impose a tax upon.", synonyms: [], antonyms: [] },
            {
              definition: "To pay as one's personal tax.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To enter, as polls or persons, in a list or register; to enroll, especially for purposes of taxation; to enumerate one by one.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cut or shave smooth or even; to cut in a straight line without indentation.",
              synonyms: [],
              antonyms: [],
              example: "a polled deed",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A pet parrot.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(Cambridge University) One who does not try for honors at university, but is content to take a degree merely; a passman.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/poll",
        "https://en.wiktionary.org/wiki/polls",
      ],
    },
  ],
  shaky: [
    {
      word: "shaky",
      phonetic: "/ˈʃeɪki/",
      phonetics: [{ text: "/ˈʃeɪki/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Shaking or trembling.",
              synonyms: [],
              antonyms: [],
              example: "a shaky hand",
            },
            {
              definition: "Nervous, anxious.",
              synonyms: [],
              antonyms: [],
              example:
                "He’s a nice guy but when he talks to me, he acts shaky.",
            },
            {
              definition: "(of wood) Full of shakes or cracks; cracked.",
              synonyms: [],
              antonyms: [],
              example: "shaky timber",
            },
            {
              definition: "Easily shaken; tottering; unsound.",
              synonyms: [],
              antonyms: [],
              example: "a shaky constitution",
            },
            { definition: "Wavering; undecided.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "precarious",
            "rickety",
            "tottering",
            "unsafe",
            "unstable",
            "unsteady",
            "wobbly",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shaky"],
    },
  ],
  nasal: [
    {
      word: "nasal",
      phonetic: "/ˈneɪzəl/",
      phonetics: [{ text: "/ˈneɪzəl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A medicine that operates through the nose; an errhine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A vowel or consonant (such as [m] or [n]) articulated with air flowing through the nose.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Part of a helmet projecting to protect the nose; a nose guard.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One of the nasal bones.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A plate, or scale, on the nose of a fish, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of or pertaining to the nose or to the nasion",
              synonyms: ["nosely", "nosey"],
              antonyms: [],
            },
            {
              definition:
                "Having a sound imparted by means of the nose; and specifically, made by lowering the soft palate, in some cases with closure of the oral passage, the voice thus issuing (wholly or partially) through the nose, as in the consonants m, n, ng; characterized by resonance in the nasal passage",
              synonyms: [],
              antonyms: [],
              example: "a nasal utterance.",
            },
          ],
          synonyms: ["nosely", "nosey"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/nasal"],
    },
  ],
  scorn: [
    {
      word: "scorn",
      phonetic: "/skɔːn/",
      phonetics: [
        { text: "/skɔːn/", audio: "" },
        {
          text: "/skɔɹn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scorn-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780355",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Contempt or disdain.", synonyms: [], antonyms: [] },
            {
              definition: "A display of disdain; a slight.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An object of disdain, contempt, or derision.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To feel or display contempt or disdain for something or somebody; to despise.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To reject, turn down.",
              synonyms: [],
              antonyms: [],
              example: "He scorned her romantic advances.",
            },
            {
              definition: "To refuse to do something, as beneath oneself.",
              synonyms: [],
              antonyms: [],
              example: "She scorned to show weakness.",
            },
            {
              definition: "To scoff, to express contempt.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["deride", "mock", "ridicule", "scoff", "sneer"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scorn"],
    },
  ],
  chess: [
    {
      word: "chess",
      phonetic: "/t͡ʃɛs/",
      phonetics: [
        {
          text: "/t͡ʃɛs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chess-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=60627995",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A board game for two players with each beginning with sixteen chess pieces moving according to fixed rules across a chessboard with the objective to checkmate the opposing king.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chess"],
    },
    {
      word: "chess",
      phonetic: "/t͡ʃɛs/",
      phonetics: [
        {
          text: "/t͡ʃɛs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chess-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=60627995",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of several species of grass in the genus Bromus, generally considered weeds.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chess"],
    },
    {
      word: "chess",
      phonetic: "/t͡ʃɛs/",
      phonetics: [
        {
          text: "/t͡ʃɛs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chess-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=60627995",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(chiefly in the plural) One of the platforms, consisting of two or more planks dowelled together, for the flooring of a temporary military bridge.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chess"],
    },
  ],
  taxis: [
    {
      word: "taxis",
      phonetic: "/ˈtæksɪs/",
      phonetics: [
        {
          text: "/ˈtæksɪs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/taxis-1-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88897064",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The directional movement of an organism in response to a stimulus.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The manipulation of a body part into its normal position after injury.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The arrangement of the parts of a topic.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Arrangement or ordering generally, as in architecture or grammar",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A brigade in an Ancient Greek army.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/taxis"],
    },
    {
      word: "taxis",
      phonetic: "/ˈtæksiz/",
      phonetics: [
        {
          text: "/ˈtæksiz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/taxis-2-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88897059",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A vehicle that may be hired for single journeys by members of the public, driven by a taxi driver.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A share taxi.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["cab", "taxicab"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To move an aircraft on the ground under its own power.",
              synonyms: [],
              antonyms: [],
              example: "taxi down the runway",
            },
            { definition: "To travel by taxicab.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/taxi",
        "https://en.wiktionary.org/wiki/taxis",
      ],
    },
  ],
  crate: [
    {
      word: "crate",
      phonetic: "/kɹeɪt/",
      phonetics: [
        {
          text: "/kɹeɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/crate-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=49882997",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A large open box or basket, used especially to transport fragile goods.",
              synonyms: ["packing case"],
              antonyms: [],
            },
            {
              definition:
                "(mildly) A vehicle (car, aircraft, spacecraft, etc.) seen as unreliable.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In the Rust programming language, a binary or library.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["packing case"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To put into a crate.", synonyms: [], antonyms: [] },
            { definition: "To keep in a crate.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/crate"],
    },
  ],
  shyly: [
    {
      word: "shyly",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            { definition: "In a shy manner.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shyly"],
    },
  ],
  tulip: [
    {
      word: "tulip",
      phonetic: "/ˈt(j)uːlɪp/",
      phonetics: [
        {
          text: "/ˈt(j)uːlɪp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tulip-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=34839143",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A type of flowering plant, genus Tulipa.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The flower of this plant.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tulip"],
    },
  ],
  forge: [
    {
      word: "forge",
      phonetic: "/fɔːd͡ʒ/",
      phonetics: [
        {
          text: "/fɔːd͡ʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/forge-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90007940",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/fɔɹd͡ʒ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Furnace or hearth where metals are heated prior to hammering them into shape.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Workshop in which metals are shaped by heating and hammering them.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The act of beating or working iron or steel.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/forge"],
    },
    {
      word: "forge",
      phonetic: "/fɔːd͡ʒ/",
      phonetics: [
        {
          text: "/fɔːd͡ʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/forge-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90007940",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/fɔɹd͡ʒ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To shape a metal by heating and hammering.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To form or create with concerted effort.",
              synonyms: [],
              antonyms: [],
              example:
                "The politician's recent actions are an effort to forge a relationship with undecided voters.",
            },
            {
              definition:
                "To create a forgery of; to make a counterfeit item of; to copy or imitate unlawfully.",
              synonyms: [],
              antonyms: [],
              example:
                "He had to forge his ex-wife's signature.  The jury learned the documents had been forged.",
            },
            {
              definition:
                "To make falsely; to produce, as that which is untrue or not genuine; to fabricate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/forge"],
    },
    {
      word: "forge",
      phonetic: "/fɔːd͡ʒ/",
      phonetics: [
        {
          text: "/fɔːd͡ʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/forge-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90007940",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/fɔɹd͡ʒ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(often as forge ahead) To move forward heavily and slowly (originally as a ship); to advance gradually but steadily; to proceed towards a goal in the face of resistance or difficulty.",
              synonyms: [],
              antonyms: [],
              example:
                "The party of explorers forged through the thick underbrush.",
            },
            {
              definition:
                "(sometimes as forge ahead) To advance, move or act with an abrupt increase in speed or energy.",
              synonyms: [],
              antonyms: [],
              example:
                "With seconds left in the race, the runner forged into first place.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/forge"],
    },
  ],
  nymph: [
    {
      word: "nymph",
      phonetic: "/ˈnɪmf/",
      phonetics: [
        { text: "/ˈnɪmf/", audio: "" },
        {
          text: "/ˈnɪmf/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/nymph-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50330739",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(Greek, Roman) Any female nature spirit associated with water, forests, grotto, wind, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A young girl, especially one who is attractive, beautiful or graceful.",
              synonyms: ["lolita", "nymphet", "nymphette"],
              antonyms: [],
            },
            {
              definition: "The larva of certain insects.",
              synonyms: ["instar", "naiad"],
              antonyms: [],
            },
            {
              definition:
                "Any of various butterflies of the family Nymphalidae.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["instar", "naiad", "lolita", "nymphet", "nymphette"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/nymph"],
    },
  ],
  budge: [
    {
      word: "budge",
      phonetic: "/bʌdʒ/",
      phonetics: [
        {
          text: "/bʌdʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/budge-1-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88987796",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To move.",
              synonyms: [],
              antonyms: [],
              example:
                "I’ve been pushing this rock as hard as I can, but it won’t budge an inch.",
            },
            {
              definition: "To move.",
              synonyms: [],
              antonyms: [],
              example:
                "I’ve been pushing this rock as hard as I can, but I can’t budge it.",
            },
            {
              definition: "To yield in one’s opinions or beliefs.",
              synonyms: [],
              antonyms: [],
              example:
                "The Minister for Finance refused to budge on the new economic rules.",
            },
            {
              definition:
                "(Minnesota, Wisconsin, Iowa) To cut or butt (in line); to join the front or middle rather than the back of a queue.",
              synonyms: [],
              antonyms: [],
              example: "Hey, no budging! Don't budge in line!",
            },
            {
              definition:
                "To try to improve the spot of a decision on a sports field.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["shift"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Brisk; stirring; jocund.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/budge"],
    },
    {
      word: "budge",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A kind of fur prepared from lambskin dressed with the wool on, formerly used as an edging and ornament, especially on scholastic habits.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Austere or stiff, like scholastics",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/budge"],
    },
  ],
  lowly: [
    {
      word: "lowly",
      phonetic: "/ˈləʊli/",
      phonetics: [
        { text: "/ˈləʊli/", audio: "" },
        {
          text: "/ˈloʊli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lowly-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453583",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Not high; not elevated in place; low.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Low in rank or social importance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Not lofty or sublime; humble.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Having a low esteem of one's own worth; humble; meek; free from pride.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "In a low manner; humbly; meekly; modestly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "In a low condition; meanly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "At low pitch or volume.",
              synonyms: [],
              antonyms: [],
              example: "He muttered lowly.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lowly"],
    },
  ],
  abide: [
    {
      word: "abide",
      phonetic: "/əˈbaɪd/",
      phonetics: [
        { text: "/əˈbaɪd/", audio: "" },
        {
          text: "/əˈbaɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/abide-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1755022",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To endure without yielding; to withstand; await defiantly; to encounter; to persevere.",
              synonyms: [],
              antonyms: [],
              example: "The old oak tree abides the wind endlessly.",
            },
            {
              definition:
                "To bear patiently; to tolerate; to put up with; stand.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To pay for; to stand the consequences of; to answer for; to suffer for; to atone for.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To wait in expectation.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To pause; to delay.", synonyms: [], antonyms: [] },
            {
              definition:
                "To stay; to continue in a place; to remain stable or fixed in some state or condition; to be left.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To have one's abode; to dwell; to reside; to sojourn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To endure; to remain; to last.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To stand ready for; to await for someone; watch for.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To endure or undergo a hard trial or a task; to stand up under.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To await submissively; accept without question; submit to.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "brook",
            "put up with",
            "hold on",
            "resist",
            "dwell",
            "live",
            "await",
            "wait for",
            "hold on",
            "stay",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/abide"],
    },
  ],
  depot: [
    {
      word: "depot",
      phonetic: "/ˈdepɐʉ/",
      phonetics: [
        { text: "/ˈdepɐʉ/", audio: "" },
        { text: "/ˈdɛpəʊ/", audio: "" },
        {
          text: "/ˈdiːpoʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/depot-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=892465",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A storage facility, in particular, a warehouse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A bus station or railway station.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A place where recruits are assembled before being sent to active units.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A place for the storage, servicing or upgrade of military hardware.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The portion of a regiment that remains at home when the rest go on foreign service.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The tableau; the area where cards can be arranged in solitaire or patience games.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/depot"],
    },
  ],
  asses: [
    {
      word: "asses",
      phonetic: "/ˈæsɪz/",
      phonetics: [
        {
          text: "/ˈæsɪz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/asses-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=95752003",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of several species of horse-like animals, especially Equus asinus, the domesticated of which are used as beasts of burden.",
              synonyms: ["donkey"],
              antonyms: [],
            },
            {
              definition: "A stupid person.",
              synonyms: ["fool", "idiot"],
              antonyms: [],
              example:
                "That new kid left the cap off the syrup bottle again! What an ass.",
            },
          ],
          synonyms: ["donkey", "fool", "idiot"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Buttocks.", synonyms: [], antonyms: [] },
            {
              definition: "Sex.",
              synonyms: ["poon", "poontang", "punani", "pussy", "tail", "tang"],
              antonyms: [],
              example:
                "I’m going to go down to the bar and try to get me some ass.",
            },
            { definition: "Anus.", synonyms: [], antonyms: [] },
            {
              definition:
                "Used in similes to express something bad or unpleasant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Used after an adjective to indicate extremes or excessiveness.",
              synonyms: [],
              antonyms: [],
              example: "That was one big-ass fish!",
            },
            {
              definition:
                "(metonymy) One's self or person, chiefly their body.",
              synonyms: [],
              antonyms: [],
              example: "Get your lazy ass out of bed!",
            },
          ],
          synonyms: ["poon", "poontang", "punani", "pussy", "tail", "tang"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ass",
        "https://en.wiktionary.org/wiki/asses",
      ],
    },
    {
      word: "asses",
      phonetic: "/ˈæsiːz/",
      phonetics: [{ text: "/ˈæsiːz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(unit of weight) A libra.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of several coins of Rome, coined in bronze or later copper; or the equivalent value.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/as",
        "https://en.wiktionary.org/wiki/asses",
      ],
    },
  ],
  sheds: [
    {
      word: "sheds",
      phonetic: "/ʃɛdz/",
      phonetics: [{ text: "/ʃɛdz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To part, separate or divide.",
              synonyms: [],
              antonyms: [],
              example: "A metal comb shed her golden hair.",
            },
            {
              definition:
                "To part with, separate from, leave off; cast off, let fall, be divested of.",
              synonyms: [],
              antonyms: [],
              example:
                "When we found the snake, it was in the process of shedding its skin.",
            },
            {
              definition: "To pour; to make flow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To allow to flow or fall.",
              synonyms: [],
              antonyms: [],
              example: "A tarpaulin sheds water.",
            },
            {
              definition:
                "To radiate, cast, give off (light); see also shed light on.",
              synonyms: [],
              antonyms: [],
              example: "Can you shed any light on this problem?",
            },
            {
              definition: "To pour forth, give off, impart.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fall in drops; to pour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To sprinkle; to intersperse; to cover.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To divide, as the warp threads, so as to form a shed, or passageway, for the shuttle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An area between upper and lower warp yarns through which the weft is woven.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A distinction or dividing-line.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A parting in the hair.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The top of the head.", synonyms: [], antonyms: [] },
            {
              definition:
                "An area of land as distinguished from those around it.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A slight or temporary structure built to shade or shelter something; a structure usually open in front; an outbuilding; a hut.",
              synonyms: [],
              antonyms: [],
              example: "a wagon shed; a wood shed; a garden shed",
            },
            {
              definition:
                "A large temporary open structure for reception of goods.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An automobile which is old, worn-out, slow, or otherwise of poor quality.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(rail transportation) A British Rail Class 66 locomotive.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To place or allocate a vehicle, such as a locomotive, in or to a depot or shed.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/shed",
        "https://en.wiktionary.org/wiki/sheds",
      ],
    },
  ],
  fudge: [
    {
      word: "fudge",
      phonetic: "/fʌdʒ/",
      phonetics: [
        {
          text: "/fʌdʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fudge-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1177117",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A type of very sweet candy or confection, usually made from sugar, butter, and milk or cream. Often used in the US synonymously with chocolate fudge.",
              synonyms: [],
              antonyms: [],
              example: "Have you tried the vanilla fudge? It's delicious!",
            },
            {
              definition: "Light or frothy nonsense.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A deliberately misleading or vague answer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A made-up story.",
              synonyms: ["humbug", "nonsense"],
              antonyms: [],
            },
            {
              definition:
                "A less than perfect decision or solution; an attempt to fix an incorrect solution after the fact.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["humbug", "nonsense"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To try to avoid giving a direct answer.",
              synonyms: ["equivocate", "hedge", "waffle"],
              antonyms: [],
              example:
                "When I asked them if they had been at the party, they fudged.",
            },
            {
              definition:
                "To alter something from its true state, as to hide a flaw or uncertainty. Always deliberate, but not necessarily dishonest or immoral.",
              synonyms: [],
              antonyms: [],
              example: "Do you fudge your age?",
            },
            {
              definition: "To botch or bungle something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cheat, especially in the game of marbles.",
              synonyms: ["cheat"],
              antonyms: [],
            },
          ],
          synonyms: ["cheat", "equivocate", "hedge", "waffle"],
          antonyms: [],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "(minced oath) Used in place of fuck.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Nonsense; tommyrot.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fudge"],
    },
  ],
  pills: [
    {
      word: "pills",
      phonetic: "/pɪlz/",
      phonetics: [{ text: "/pɪlz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small, usually round or cylindrical object designed for easy swallowing, usually containing some sort of medication.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                '(definite, i.e. used with "the") Contraceptive medication, usually in the form of a pill to be taken by a woman; an oral contraceptive pill.',
              synonyms: [],
              antonyms: [],
              example: "Jane went on the pill when she left for college.",
            },
            {
              definition: "A comical or entertaining person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A contemptible, annoying, or unpleasant person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small piece of any substance, for example a ball of fibres formed on the surface of a textile by rubbing.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A baseball.", synonyms: [], antonyms: [] },
            {
              definition: "A bullet (projectile).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A rounded rectangle indicating the tag or category that an item belongs to.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["cap", "tablet"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "Of a woven fabric surface, to form small matted balls of fiber.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To form into the shape of a pill.",
              synonyms: [],
              antonyms: [],
              example: "Pilling is a skill rarely used by modern pharmacists.",
            },
            {
              definition: "To medicate with pills.",
              synonyms: [],
              antonyms: [],
              example: "She pills herself with all sorts of herbal medicines.",
            },
            {
              definition: "To persuade or convince someone of something.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The peel or skin.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To peel; to remove the outer layer of hair, skin, or bark.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To peel; to make by removing the skin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be peeled; to peel off in flakes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To pillage; to despoil or impoverish.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An inlet on the coast; a small tidal pool or bay.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pill",
        "https://en.wiktionary.org/wiki/pills",
      ],
    },
  ],
  rivet: [
    {
      word: "rivet",
      phonetic: "/ˈɹɪvət/",
      phonetics: [
        {
          text: "/ˈɹɪvət/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rivet-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50284443",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A cylindrical mechanical fastener that attaches multiple parts together by fitting through a hole and deforming the head(s) at either end.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any fixed point or certain basis.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A light kind of footman's armour.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To attach or fasten parts by using rivets.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To install rivets.", synonyms: [], antonyms: [] },
            {
              definition: "To command the attention of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make firm or immovable.",
              synonyms: [],
              antonyms: [],
              example: "Terror riveted him to the spot.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rivet"],
    },
  ],
  thine: [
    {
      word: "thine",
      phonetic: "/ðaɪn/",
      phonetics: [
        { text: "/ðaɪn/", audio: "" },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/thine-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1676878",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "pronoun",
          definitions: [
            {
              definition: "Singular second person possessive pronoun; yours",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/thine"],
    },
  ],
  groom: [
    {
      word: "groom",
      phonetic: "/ɡɹuːm/",
      phonetics: [
        {
          text: "/ɡɹuːm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/groom-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769410",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A man who is about to marry.",
              synonyms: ["bridegroom"],
              antonyms: [],
            },
          ],
          synonyms: ["bridegroom"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/groom"],
    },
    {
      word: "groom",
      phonetic: "/ɡɹuːm/",
      phonetics: [
        {
          text: "/ɡɹuːm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/groom-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769410",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A person who cares for horses.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of several officers of the English royal household, chiefly in the lord chamberlain's department.",
              synonyms: [],
              antonyms: [],
              example: "the groom of the chamber; the groom of the stole",
            },
            {
              definition: "A brushing or cleaning, as of a dog or horse.",
              synonyms: [],
              antonyms: [],
              example: "Give the mare a quick groom before you take her out.",
            },
          ],
          synonyms: ["ostler"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To attend to one's appearance and clothing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To care for (horses or other animals) by brushing and cleaning them.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To prepare (someone) for election or appointment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To prepare (a ski slope) for skiers by packing down the snow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To attempt to gain the trust of (somebody, especially a minor) with the intention of subjecting them to abusive or exploitative behaviour such as sexual abuse or human trafficking.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In agile software development, to review and prioritize the items in the development backlog.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/groom"],
    },
  ],
  lanky: [
    {
      word: "lanky",
      phonetic: "/ˈlæŋk.i/",
      phonetics: [{ text: "/ˈlæŋk.i/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Tall, slim, and rather ungraceful or awkward.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["gangly"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lanky"],
    },
  ],
  boost: [
    {
      word: "boost",
      phonetic: "/buːst/",
      phonetics: [
        {
          text: "/buːst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/boost-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79519611",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A push from behind, as to one who is endeavoring to climb.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something that helps, or adds power or effectiveness; assistance.",
              synonyms: [],
              antonyms: [],
              example: "The controversy gave a boost to the author's sales.",
            },
            {
              definition:
                "(automotive engineering) A positive intake manifold pressure in cars with turbochargers or superchargers.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To lift or push from behind (one who is endeavoring to climb); to push up.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) To help or encourage (something) to increase or improve; to assist in overcoming obstacles.",
              synonyms: [],
              antonyms: [],
              example:
                "This campaign will boost your chances of winning the election.",
            },
            { definition: "To steal.", synonyms: [], antonyms: [] },
            {
              definition:
                "To jump-start a vehicle by using cables to connect the battery in a running vehicle to the battery in a vehicle that won't start.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To give a booster shot to.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To amplify; to signal boost.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/boost"],
    },
  ],
  broth: [
    {
      word: "broth",
      phonetic: "/bɹɑθ/",
      phonetics: [
        { text: "/bɹɑθ/", audio: "" },
        { text: "/bɹɒθ/", audio: "" },
        {
          text: "/bɹɔθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/broth-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769321",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Water in which food (meat or vegetable etc) has been boiled.",
              synonyms: ["bouillon", "liquor", "pot liquor", "stock"],
              antonyms: [],
            },
            {
              definition:
                "A soup made from broth and other ingredients such as vegetables, herbs or diced meat.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bouillon", "liquor", "pot liquor", "stock"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/broth"],
    },
  ],
  heave: [
    {
      word: "heave",
      phonetic: "/hiːv/",
      phonetics: [
        {
          text: "/hiːv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/heave-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75797298",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/hiːv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/heave-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=454029",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An effort to raise something, such as a weight or one's own body, or to move something heavy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An upward motion; a rising; a swell or distention, as of the breast in difficult breathing, of the waves, of the earth in an earthquake, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A horizontal dislocation in a metallic lode, taking place at an intersection with another lode.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The measure of extent to which a nautical vessel goes up and down in a short period of time. Compare pitch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An effort to vomit; retching.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                '(only used attributively as in "heave line" or "heave horse") Broken wind in horses.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A forceful shot in which the ball follows a high trajectory",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To lift with difficulty; to raise with some effort; to lift (a heavy thing).",
              synonyms: [],
              antonyms: [],
              example:
                "We heaved the chest-of-drawers on to the second-floor landing.",
            },
            {
              definition: "To throw, cast.",
              synonyms: [],
              antonyms: [],
              example: "The cap'n hove the body overboard.",
            },
            {
              definition: "To rise and fall.",
              synonyms: [],
              antonyms: [],
              example: "Her chest heaved with emotion.",
            },
            {
              definition: "To utter with effort.",
              synonyms: [],
              antonyms: [],
              example: "She heaved a sigh and stared out of the window.",
            },
            {
              definition: "To pull up with a rope or cable.",
              synonyms: [],
              antonyms: [],
              example: "Heave up the anchor there, boys!",
            },
            {
              definition:
                "To lift (generally); to raise, or cause to move upwards (particularly in ships or vehicles) or forwards.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be thrown up or raised; to rise upward, as a tower or mound.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To displace (a vein, stratum).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause to swell or rise, especially in repeated exertions.",
              synonyms: [],
              antonyms: [],
              example: "The wind heaved the waves.",
            },
            {
              definition:
                "To move in a certain direction or into a certain position or situation.",
              synonyms: [],
              antonyms: [],
              example: "to heave the ship ahead",
            },
            {
              definition: "To retch, to make an effort to vomit; to vomit.",
              synonyms: [],
              antonyms: [],
              example:
                "The smell of the old cheese was enough to make you heave.",
            },
            {
              definition:
                "To make an effort to raise, throw, or move anything; to strain to do something difficult.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To rob; to steal from; to plunder.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/heave"],
    },
  ],
  gravy: [
    {
      word: "gravy",
      phonetic: "/ˈɡɹeɪvi/",
      phonetics: [
        {
          text: "/ˈɡɹeɪvi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gravy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769408",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A thick sauce made from the fat or juices that come out from meat or vegetables as they are being cooked.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A type of gravy.", synonyms: [], antonyms: [] },
            {
              definition: "(Italian-American) Sauce used for pasta.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Curry sauce.", synonyms: [], antonyms: [] },
            { definition: "Unearned gain.", synonyms: [], antonyms: [] },
            {
              definition: "Extra benefit.",
              synonyms: [],
              antonyms: [],
              example:
                "The first thousand tickets and the concessions cover the venue and the band. The rest is gravy.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gravy"],
    },
  ],
  beech: [
    {
      word: "beech",
      phonetic: "/biːt͡ʃ/",
      phonetics: [
        {
          text: "/biːt͡ʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/beech-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769611",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A tree of the genus Fagus having a smooth, light grey trunk, oval, pointed leaves and many branches.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The wood of the beech tree.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["beech tree"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/beech"],
    },
  ],
  timed: [
    {
      word: "timed",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To measure or record the time, duration, or rate of.",
              synonyms: [],
              antonyms: [],
              example:
                "I used a stopwatch to time myself running around the block.",
            },
            {
              definition:
                "To choose when something begins or how long it lasts.",
              synonyms: [],
              antonyms: [],
              example:
                "The President timed his speech badly, coinciding with the Super Bowl.",
            },
            {
              definition: "To keep or beat time; to proceed or move in time.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To pass time; to delay.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To regulate as to time; to accompany, or agree with, in time of movement.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To measure, as in music or harmony.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["set", "clock"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Happening at a certain time.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Regulated by time.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/time",
        "https://en.wiktionary.org/wiki/timed",
      ],
    },
  ],
  quail: [
    {
      word: "quail",
      phonetic: "/ˈkweɪl/",
      phonetics: [
        {
          text: "/ˈkweɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/quail-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75857489",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈkweɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/quail-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=361118",
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To waste away; to fade, to wither",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To daunt or frighten (someone)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To lose heart or courage; to be daunted or fearful.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of courage, faith, etc.: to slacken, to give way.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/quail"],
    },
    {
      word: "quail",
      phonetic: "/ˈkweɪl/",
      phonetics: [
        {
          text: "/ˈkweɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/quail-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75857489",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈkweɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/quail-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=361118",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of various small game birds of the genera Coturnix, Anurophasis or Perdicula in the Old World family Phasianidae or of the New World family Odontophoridae.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The meat from this bird eaten as food.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A prostitute, so called because the quail was thought to be a very amorous bird.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/quail"],
    },
    {
      word: "quail",
      phonetic: "/ˈkweɪl/",
      phonetics: [
        {
          text: "/ˈkweɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/quail-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75857489",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈkweɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/quail-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=361118",
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To curdle or coagulate, as milk does.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/quail"],
    },
  ],
  inert: [
    {
      word: "inert",
      phonetic: "/ɪnˈɝt/",
      phonetics: [{ text: "/ɪnˈɝt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A substance that does not react chemically.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To fill with an inert gas to reduce the risk of explosion.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Unable to move or act; inanimate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In chemistry, not readily reacting with other elements or compounds.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Having no therapeutic action.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["unreactive", "dormant", "motionless"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/inert"],
    },
  ],
  gears: [
    {
      word: "gears",
      phonetic: "/ɡɪɚz/",
      phonetics: [{ text: "/ɡɪɚz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Equipment or paraphernalia, especially that used for an athletic endeavor.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Clothing; garments.", synonyms: [], antonyms: [] },
            {
              definition: "Goods; property; household items.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A wheel with grooves (teeth) engraved on the outer circumference, such that two such devices can interlock and convey motion from one to the other; a gear wheel.",
              synonyms: ["cog", "cogwheel", "gearwheel"],
              antonyms: [],
            },
            {
              definition:
                "A particular combination or choice of interlocking gears, such that a particular gear ratio is achieved.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A configuration of the transmission of a motor car so as to achieve a particular ratio of engine to axle torque.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Recreational drugs, including steroids.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Stuff.", synonyms: [], antonyms: [] },
            {
              definition: "Business matters; affairs; concern.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Anything worthless; nonsense; rubbish.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["cog", "cogwheel", "gearwheel"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To provide with gearing; to fit with gears in order to achieve a desired gear ratio.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be in, or come into, gear.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To dress; to put gear on; to harness.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(usually with to or toward(s)) To design or devise (something) so as to be suitable (for a particular type of person or a particular purpose).",
              synonyms: [],
              antonyms: [],
              example: "They have geared the hotel mainly at tourists.",
            },
            {
              definition: "To borrow money in order to invest it in assets.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gear",
        "https://en.wiktionary.org/wiki/gears",
      ],
    },
  ],
  chick: [
    {
      word: "chick",
      phonetic: "/t͡ʃɪk/",
      phonetics: [
        {
          text: "/t͡ʃɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chick-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=500298",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A young bird.",
              synonyms: ["fledgling"],
              antonyms: [],
            },
            { definition: "A young chicken.", synonyms: [], antonyms: [] },
            {
              definition: "(term of endearment) A young child.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A young, especially attractive, woman or teenage girl.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["fledgling"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To sprout, as seed does in the ground; to vegetate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chick"],
    },
    {
      word: "chick",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A screen or blind made of finely slit bamboo and twine, hung in doorways or windows.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["chick-blinds"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chick"],
    },
  ],
  hinge: [
    {
      word: "hinge",
      phonetic: "/ˈhɪndʒ/",
      phonetics: [
        {
          text: "/ˈhɪndʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hinge-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50699388",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A jointed or flexible device that allows the pivoting of a door etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A naturally occurring joint resembling such hardware in form or action, as in the shell of a bivalve.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A stamp hinge, a folded and gummed paper rectangle for affixing postage stamps in an album.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A principle, or a point in time, on which subsequent reasonings or events depend.",
              synonyms: [],
              antonyms: [],
              example:
                "This argument was the hinge on which the question turned.",
            },
            {
              definition:
                "The median of the upper or lower half of a batch, sample, or probability distribution.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the four cardinal points, east, west, north, or south.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["har", "quartile"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To attach by, or equip with a hinge.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(with on or upon) To depend on something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The breaking off of the distal end of a knapped stone flake whose presumed course across the face of the stone core was truncated prematurely, leaving not a feathered distal end but instead the scar of a nearly perpendicular break.",
              synonyms: [],
              antonyms: [],
              example: "The flake hinged at an inclusion in the core.",
            },
            { definition: "To bend.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hinge"],
    },
  ],
  trash: [
    {
      word: "trash",
      phonetic: "/tɹæʃ/",
      phonetics: [
        {
          text: "/tɹæʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/trash-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1770083",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Useless things to be discarded; rubbish; refuse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A container into which things are discarded.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Something worthless or of poor quality.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "People of low social status or class. (See, for example, white trash or Eurotrash.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A fan who is excessively obsessed with their fandom and its fanworks.",
              synonyms: [],
              antonyms: [],
              example: "I am Harry Potter trash.",
            },
            {
              definition:
                "Temporary storage on disk for files that the user has deleted, allowing them to be recovered if necessary.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A collar, leash, or halter used to restrain a dog in pursuing game.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "trash can",
            "recycle bin",
            "garbage",
            "junk",
            "refuse",
            "rubbish",
            "waste",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To discard.", synonyms: [], antonyms: [] },
            {
              definition: "To make into a mess.",
              synonyms: [],
              antonyms: [],
              example: "The burglars trashed the house.",
            },
            {
              definition: "To beat soundly in a game.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To disrespect someone or something",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To free from trash, or worthless matter; hence, to lop; to crop.",
              synonyms: [],
              antonyms: [],
              example: "to trash the rattoons of sugar cane",
            },
            {
              definition:
                "To treat as trash, or worthless matter; hence, to spurn, humiliate, or crush.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To hold back by a trash or leash, as a dog in pursuing game; hence, to retard, encumber, or restrain; to clog; to hinder vexatiously.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/trash"],
    },
  ],
  clash: [
    {
      word: "clash",
      phonetic: "/klaʃ/",
      phonetics: [
        { text: "/klaʃ/", audio: "" },
        {
          text: "/klæʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/clash-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50422606",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A loud sound, like the crashing together of metal objects.",
              synonyms: [],
              antonyms: [],
              example:
                "I heard a clash from the kitchen, and rushed in to find the cat had knocked over some pots and pans.",
            },
            {
              definition: "A skirmish, a hostile encounter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A match; a game between two sides.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An angry argument", synonyms: [], antonyms: [] },
            {
              definition:
                "Opposition; contradiction; such as between differing or contending interests, views, purposes etc.",
              synonyms: [],
              antonyms: [],
              example: "a clash of beliefs",
            },
            {
              definition:
                "A combination of garments that do not look good together, especially because of conflicting colours.",
              synonyms: [],
              antonyms: [],
              example: "She was wearing a horrible clash of red and orange.",
            },
            {
              definition:
                'An instance of restarting the game after a "dead ball", where it is dropped between two opposing players, who can fight for possession.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Chatter; gossip; idle talk.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make a clashing sound.",
              synonyms: [],
              antonyms: [],
              example: "The cymbals clashed.",
            },
            {
              definition: "To cause to make a clashing sound.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To come into violent conflict.",
              synonyms: [],
              antonyms: [],
              example:
                "Fans from opposing teams clashed on the streets after the game.",
            },
            { definition: "To argue angrily.", synonyms: [], antonyms: [] },
            {
              definition:
                "(in games or sports) To face each other in an important game.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of clothes, decor, colours) To fail to look good together; to contrast unattractively; to fail to harmonize.",
              synonyms: [],
              antonyms: [],
              example:
                "The hotel room was ugly, and the wallpaper clashed with the carpet.",
            },
            {
              definition:
                "(of events) To coincide, to happen at the same time, thereby rendering it impossible to attend all.",
              synonyms: [],
              antonyms: [],
              example:
                "I can't come to your wedding because it clashes with a friend's funeral.",
            },
            { definition: "To chatter or gossip.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/clash"],
    },
  ],
  sighs: [
    {
      word: "sighs",
      phonetic: "/saɪz/",
      phonetics: [
        {
          text: "/saɪz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sighs-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=91894751",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A deep, prolonged audible inhale and exhale of breath; as when fatigued, frustrated, grieved, or relieved; the act of sighing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Figuratively, a manifestation of grief; a lament.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person who is bored.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To inhale a larger quantity of air than usual, and immediately expel it; to make a deep single audible respiration, especially as the result or involuntary expression of fatigue, exhaustion, grief, sorrow, frustration, or the like.",
              synonyms: [],
              antonyms: [],
              example: "He sighed over the lost opportunity.",
            },
            { definition: "To lament; to grieve.", synonyms: [], antonyms: [] },
            {
              definition: "To utter sighs over; to lament or mourn over.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To experience an emotion associated with sighing.",
              synonyms: [],
              antonyms: [],
              example: "He silently sighed for his lost youth.",
            },
            {
              definition: "To make a sound like sighing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To exhale (the breath) in sighs.",
              synonyms: [],
              antonyms: [],
              example: "She sighed a sigh that was nearly a groan.",
            },
            {
              definition: "To express by sighs; to utter in or with sighs.",
              synonyms: [],
              antonyms: [],
              example: '"I guess I have no choice," she sighed.',
            },
          ],
          synonyms: ["sithe"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sigh",
        "https://en.wiktionary.org/wiki/sighs",
      ],
    },
  ],
  renew: [
    {
      word: "renew",
      phonetic: "/ɹɪˈnjuː/",
      phonetics: [
        { text: "/ɹɪˈnjuː/", audio: "" },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/renew-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651402",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make (something) new again; to restore to freshness or original condition.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To replace (something which has broken etc.); to replenish (something which has been exhausted), to keep up a required supply of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make new spiritually; to regenerate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To become new, or as new; to revive.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To begin again; to recommence.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To repeat.", synonyms: [], antonyms: [] },
            {
              definition:
                "To extend a period of loan, especially a library book that is due to be returned.",
              synonyms: [],
              antonyms: [],
              example:
                "I'd like to renew these three books.  Did you know that you can renew online?",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The act of renewing.", synonyms: [], antonyms: [] },
            {
              definition:
                "An offensive action made immediately after a parried one.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["renew"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/renew",
        "https://en.wiktionary.org/wiki/renewal",
      ],
    },
  ],
  bough: [
    {
      word: "bough",
      phonetic: "/baʊ/",
      phonetics: [
        {
          text: "/baʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bough-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=813889",
        },
        {
          text: "/baʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bough-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1779942",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A firm branch of a tree.",
              synonyms: [],
              antonyms: [],
              example: "When the bough breaks, the cradle will fall.",
            },
            { definition: "The gallows.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bough"],
    },
  ],
  dwarf: [
    {
      word: "dwarf",
      phonetic: "/dwɔːf/",
      phonetics: [
        { text: "/dwɔːf/", audio: "" },
        {
          text: "/dwɔɹf/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dwarf-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780051",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any member of a race of beings from (especially Scandinavian and other Germanic) folklore, usually depicted as having some sort of supernatural powers and being skilled in crafting and metalworking, often as short with long beards, and sometimes as clashing with elves.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person of short stature, often one whose limbs are disproportionately small in relation to the body as compared with normal adults, usually as the result of a genetic condition.",
              synonyms: ["midget", "pygmy (imprecise)"],
              antonyms: ["ettin", "giant"],
            },
            {
              definition:
                "An animal, plant or other thing much smaller than the usual of its sort.",
              synonyms: ["runt"],
              antonyms: [],
              example: "dwarf honeysuckle",
            },
            {
              definition: "A star of relatively small size.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["midget", "pygmy (imprecise)", "runt"],
          antonyms: ["ettin", "giant"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To render (much) smaller, turn into a dwarf (version).",
              synonyms: ["miniaturize", "shrink"],
              antonyms: [],
            },
            {
              definition: "To make appear (much) smaller, puny, tiny.",
              synonyms: [],
              antonyms: [],
              example:
                "The newly-built skyscraper dwarfs all older buildings in the downtown skyline.",
            },
            {
              definition: "To make appear insignificant.",
              synonyms: [
                "eclipse",
                "outdo",
                "outmatch",
                "outshadow",
                "outshine",
                "outstrip",
                "overshadow",
                "put to shame",
                "surpass",
                "upstage",
              ],
              antonyms: [],
              example: "Bach dwarfs all other composers.",
            },
            {
              definition: "To become (much) smaller.",
              synonyms: ["shrink"],
              antonyms: [],
            },
            {
              definition:
                "To hinder from growing to the natural size; to make or keep small; to stunt.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "eclipse",
            "outdo",
            "outmatch",
            "outshadow",
            "outshine",
            "outstrip",
            "overshadow",
            "put to shame",
            "surpass",
            "upstage",
            "miniaturize",
            "shrink",
            "shrink",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Miniature.",
              synonyms: [],
              antonyms: [],
              example:
                "It is possible to grow the plants as dwarf as one desires.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dwarf"],
    },
  ],
  slows: [
    {
      word: "slows",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make (something) run, move, etc. less quickly; to reduce the speed of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To keep from going quickly; to hinder the progress of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To become slow; to slacken in speed; to decelerate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["decelerate", "slacken", "delay", "hinder", "retard"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Milk sickness", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/slow",
        "https://en.wiktionary.org/wiki/slows",
      ],
    },
  ],
  quill: [
    {
      word: "quill",
      phonetic: "/kwɪl/",
      phonetics: [
        {
          text: "/kwɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/quill-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=49896867",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The lower shaft of a feather, specifically the region lacking barbs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A pen made from a feather.",
              synonyms: ["feather pen", "quill pen"],
              antonyms: [],
            },
            {
              definition: "Any pen.",
              synonyms: [],
              antonyms: [],
              example: "He picked up his quill and wrote a poem.",
            },
            {
              definition:
                "A sharply pointed, barbed, and easily detached needle-like structure that grows on the skin of a porcupine or hedgehog as a defense against predators.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A thin piece of bark, especially of cinnamon or cinchona, curled up into a tube.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The pen of a squid.", synonyms: [], antonyms: [] },
            {
              definition:
                "The plectrum with which musicians strike the strings of certain instruments.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The tube of a musical instrument.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something having the form of a quill, such as the fold or plain of a ruff, or a spindle, or spool, upon which the thread for the woof is wound in a shuttle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["feather pen", "quill pen"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To pierce or be pierced with quills.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To write.", synonyms: [], antonyms: [] },
            {
              definition: "To form fabric into small, rounded folds.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To decorate with quillwork.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To subject (a woman who is giving birth) to the practice of quilling (blowing pepper into her nose to induce or hasten labor).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/quill"],
    },
  ],
  shave: [
    {
      word: "shave",
      phonetic: "/ʃeɪv/",
      phonetics: [
        {
          text: "/ʃeɪv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shave-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780368",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make bald or shorter by using a tool such as a razor or pair of electric clippers to cut the hair close to the skin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cut anything in this fashion.",
              synonyms: [],
              antonyms: [],
              example:
                "The labourer with the bending scythe is seen / Shaving the surface of the waving green.",
            },
            {
              definition: "To remove hair from one's face by this means.",
              synonyms: [],
              antonyms: [],
              example: "I had little time to shave this morning.",
            },
            {
              definition: "To cut finely, as with slices of meat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To skim along or near the surface of; to pass close to, or touch lightly, in passing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To reduce in size or weight.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be hard and severe in a bargain with; to practice extortion on; to cheat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To buy (a note) at a discount greater than the legal rate of interest, or to deduct in discounting it more than the legal rate allows.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shave"],
    },
    {
      word: "shave",
      phonetic: "/ʃeɪv/",
      phonetics: [
        {
          text: "/ʃeɪv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shave-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780368",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An instance of shaving.",
              synonyms: [],
              antonyms: [],
              example: "I instructed the barber to give me a shave.",
            },
            {
              definition: "A thin slice; a shaving.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An exorbitant discount on a note.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A premium paid for an extension of the time of delivery or payment, or for the right to vary a stock contract in any particular.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hand tool consisting of a sharp blade with a handle at each end; a spokeshave.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A narrow miss or escape; a close shave.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shave"],
    },
  ],
  spore: [
    {
      word: "spore",
      phonetic: "/spɔː/",
      phonetics: [
        { text: "/spɔː/", audio: "" },
        { text: "/spɔɹ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A reproductive particle, usually a single cell, released by a fungus, alga, or plant that may germinate into another.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A thick resistant particle produced by a bacterium or protist to survive in harsh or unfavorable conditions.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To produce spores.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spore"],
    },
  ],
  sixes: [
    {
      word: "sixes",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sixes-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79293783",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The digit or figure 6.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by ellipsis of six o'clock) Rear, behind (rear side of something).",
              synonyms: [],
              antonyms: [],
              example: "cover my six",
            },
            {
              definition:
                "An event whereby a batsman hits a ball which does not bounce before passing over a boundary in the air, resulting in an award of 6 runs for the batting team.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A touchdown.", synonyms: [], antonyms: [] },
            {
              definition: "Small beer sold at six shillings per barrel.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A pair of sixes.", synonyms: [], antonyms: [] },
            {
              definition: "Short for six of one, half a dozen of the other.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/six",
        "https://en.wiktionary.org/wiki/sixes",
      ],
    },
  ],
  chunk: [
    {
      word: "chunk",
      phonetic: "/t͡ʃʌŋk/",
      phonetics: [
        {
          text: "/t͡ʃʌŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chunk-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=461552",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A part of something that has been separated.",
              synonyms: [],
              antonyms: [],
              example: "The statue broke into chunks.",
            },
            {
              definition:
                "A representative portion of a substance, often large and irregular.",
              synonyms: [],
              antonyms: [],
              example: "a chunk of granite",
            },
            {
              definition:
                "A sequence of two or more words that occur in language with high frequency but are not idiomatic; a bundle or cluster.",
              synonyms: [],
              antonyms: [],
              example:
                'examples of chunks would include "in accordance with", "the results of", and "so far"',
            },
            {
              definition:
                "A discrete segment of a file, stream, etc. (especially one that represents audiovisual media); a block.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A segment of a comedian's performance",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To break into large pieces or chunks.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To break down (language, etc.) into conceptual pieces of manageable size.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To throw.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chunk"],
    },
  ],
  madly: [
    {
      word: "madly",
      phonetic: "/ˈmædli/",
      phonetics: [{ text: "/ˈmædli/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition:
                "In a mad manner; without reason or understanding; wildly.",
              synonyms: [],
              antonyms: [],
              example: "I'm madly in love with you.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/madly"],
    },
  ],
  paced: [
    {
      word: "paced",
      phonetic: "/peɪst/",
      phonetics: [
        {
          text: "/peɪst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/paced-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1113778",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To walk back and forth in a small distance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To set the speed in a race.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To measure by walking.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pace",
        "https://en.wiktionary.org/wiki/paced",
      ],
    },
  ],
  braid: [
    {
      word: "braid",
      phonetic: "/bɹeɪd/",
      phonetics: [
        {
          text: "/bɹeɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/braid-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=60578062",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A sudden movement; a jerk, a wrench.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A weave of three or more strands of fibers, ribbons, cords or hair often for decoration.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A stranded wire composed of a number of smaller wires twisted together",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A tubular sheath made of braided strands of metal placed around a central cable for shielding against electromagnetic interference.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A fancy; freak; caprice.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make a sudden movement with, to jerk.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To start into motion.", synonyms: [], antonyms: [] },
            {
              definition:
                "To weave together, intertwine (strands of fibers, ribbons, etc.); to arrange (hair) in braids.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To mix, or make uniformly soft, by beating, rubbing, or straining, as in preparing food.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To reproach; to upbraid.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/braid"],
    },
    {
      word: "braid",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Deceitful.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/braid"],
    },
  ],
  fuzzy: [
    {
      word: "fuzzy",
      phonetic: "/ˈfʌzi/",
      phonetics: [
        {
          text: "/ˈfʌzi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fuzzy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=859843",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(often in the plural) A very small piece of plush material.",
              synonyms: [],
              antonyms: [],
              example: "You've got a fuzzy on your coat.",
            },
            {
              definition:
                "Something covered with fuzz or hair, as an animal or plush toy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person, especially a college student, interested in humanities or social sciences, as opposed to one interested in mathematics, science, or engineering.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A soldier with the rank of private.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Covered with fuzz or a large number of tiny loose fibres like a carpet or many stuffed animals",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Vague or imprecise.",
              synonyms: [],
              antonyms: [],
              example: "My recollection of that event is fuzzy.",
            },
            {
              definition: "Not clear; unfocused.",
              synonyms: [],
              antonyms: [],
              example: "I finally threw out a large stack of fuzzy photos.",
            },
          ],
          synonyms: [
            "blurry",
            "fuzzy",
            "ill-defined",
            "ambiguous",
            "equivocal",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fuzzy"],
    },
  ],
  motto: [
    {
      word: "motto",
      phonetic: "/ˈmɒtəʊ/",
      phonetics: [
        { text: "/ˈmɒtəʊ/", audio: "" },
        { text: "/ˈmɑt.oʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sentence, phrase, or word, forming part of an heraldic achievement.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sentence, phrase, or word, prefixed to an essay, discourse, chapter, canto, or the like, suggestive of its subject matter; a short, suggestive expression of a guiding principle; a maxim.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A paper packet containing a sweetmeat, cracker, etc., together with a scrap of paper bearing a motto.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/motto"],
    },
  ],
  spies: [
    {
      word: "spies",
      phonetic: "/spaɪz/",
      phonetics: [{ text: "/spaɪz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person who secretly watches and examines the actions of other individuals or organizations and gathers information on them (usually to gain an advantage).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["intelligencer"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To act as a spy.",
              synonyms: [],
              antonyms: [],
              example:
                "During the Cold War, Russia and America would each spy on each other for recon.",
            },
            {
              definition: "To spot; to catch sight of.",
              synonyms: [],
              antonyms: [],
              example: "I think I can spy that hot guy coming over here.",
            },
            {
              definition: "To search narrowly; to scrutinize.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To explore; to see; to view; inspect and examine secretly, as a country.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/spies",
        "https://en.wiktionary.org/wiki/spy",
      ],
    },
  ],
  slack: [
    {
      word: "slack",
      phonetic: "/slæk/",
      phonetics: [
        {
          text: "/slæk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/slack-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241748",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The part of anything that hangs loose, having no strain upon it.",
              synonyms: [],
              antonyms: [],
              example: "take in the slack",
            },
            {
              definition:
                "A tidal marsh or shallow that periodically fills and drains.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["culm", "slough"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(normally said of a rope) Lax; not tense; not firmly extended.",
              synonyms: [],
              antonyms: [],
              example: "a slack rope",
            },
            {
              definition: "Weak; not holding fast.",
              synonyms: [],
              antonyms: [],
              example: "a slack hand",
            },
            {
              definition: "Lacking diligence or care; not earnest or eager.",
              synonyms: [],
              antonyms: [],
              example: "slack in duty or service",
            },
            {
              definition: "Not active,successful, or violent.",
              synonyms: [],
              antonyms: [],
              example: "Business is slack.",
            },
            {
              definition: "Excess; surplus to requirements.",
              synonyms: [],
              antonyms: [],
              example: "the slack capacity of an oil pipeline",
            },
            {
              definition:
                "Vulgar; sexually explicit, especially in dancehall music.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["easy", "moderate", "slow"],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Slackly.",
              synonyms: [],
              antonyms: [],
              example: "slack dried hops",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/slack"],
    },
    {
      word: "slack",
      phonetic: "/slæk/",
      phonetics: [
        {
          text: "/slæk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/slack-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241748",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To slacken.", synonyms: [], antonyms: [] },
            {
              definition: "To mitigate; to reduce the strength of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To lose cohesion or solidity by a chemical combination with water; to slake.",
              synonyms: [],
              antonyms: [],
              example: "Lime slacks.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/slack"],
    },
    {
      word: "slack",
      phonetic: "/slæk/",
      phonetics: [
        {
          text: "/slæk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/slack-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241748",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A temporary speed restriction where track maintenance or engineering work is being carried out at a particular place.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/slack"],
    },
    {
      word: "slack",
      phonetic: "/slæk/",
      phonetics: [
        {
          text: "/slæk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/slack-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241748",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A valley, or small, shallow dell.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/slack"],
    },
    {
      word: "slack",
      phonetic: "/slæk/",
      phonetics: [
        {
          text: "/slæk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/slack-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241748",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Small coal; coal dust.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/slack"],
    },
  ],
  mucus: [
    {
      word: "mucus",
      phonetic: "/ˈmjuːkəs/",
      phonetics: [{ text: "/ˈmjuːkəs/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A slippery secretion from the lining of the mucous membranes.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mucus"],
    },
  ],
  magma: [
    {
      word: "magma",
      phonetic: "/ˈmæɡ.mə/",
      phonetics: [
        {
          text: "/ˈmæɡ.mə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/magma-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50014392",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The molten matter within the earth, the source of the material of lava flows, dikes of eruptive rocks, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A basic algebraic structure consisting of a set equipped with a single binary operation.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Any soft doughy mass.", synonyms: [], antonyms: [] },
            {
              definition:
                "The residuum after expressing the juice from fruits.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/magma"],
    },
  ],
  awful: [
    {
      word: "awful",
      phonetic: "/ˈɔːfəl/",
      phonetics: [
        { text: "/ˈɔːfəl/", audio: "" },
        {
          text: "/ˈɔfəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/awful-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1676699",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Very bad.",
              synonyms: [],
              antonyms: [],
              example: "My socks smell awful.",
            },
            {
              definition: "Exceedingly great; usually applied intensively.",
              synonyms: [],
              antonyms: [],
              example: "I have learnt an awful amount today.",
            },
            {
              definition: "Causing fear or horror; appalling, terrible.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Inspiring awe; filling with profound reverence or respect; profoundly impressive.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Struck or filled with awe.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Terror-stricken.", synonyms: [], antonyms: [] },
            {
              definition: "Worshipful; reverential; law-abiding.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Awfully; dreadfully; terribly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Very, extremely.",
              synonyms: [],
              antonyms: [],
              example: "He was blubbering away something awful.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/awful"],
    },
  ],
  discs: [
    {
      word: "discs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A thin, flat, circular plate or similar object.",
              synonyms: [],
              antonyms: [],
              example: "A coin is a disc of metal.",
            },
            {
              definition: "An intervertebral disc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Something resembling a disc.",
              synonyms: [],
              antonyms: [],
              example: "Venus's disc cut off light from the Sun.",
            },
            {
              definition: "A vinyl phonograph / gramophone record.",
              synonyms: [],
              antonyms: [],
              example: "Turn the disc over, after it has finished.",
            },
            {
              definition:
                "The flat surface of an organ, as a leaf, any flat, round growth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(ultimate frisbee) A Frisbee.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/disc",
        "https://en.wiktionary.org/wiki/discs",
      ],
    },
  ],
  erase: [
    {
      word: "erase",
      phonetic: "/ɪˈɹeɪz/",
      phonetics: [
        { text: "/ɪˈɹeɪz/", audio: "" },
        {
          text: "/ɪˈɹeɪs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/erase-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769888",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The operation of deleting data.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To remove markings or information",
              synonyms: [],
              antonyms: [],
              example: "I erased that note because it was wrong.",
            },
            {
              definition:
                "To obliterate information from (a storage medium), such as to clear or (with magnetic storage) to demagnetize.",
              synonyms: [],
              antonyms: [],
              example: "I'm going to erase this tape.",
            },
            {
              definition:
                "To obliterate (information) from a storage medium, such as to clear or to overwrite.",
              synonyms: [],
              antonyms: [],
              example: "I'm going to erase those files.",
            },
            {
              definition:
                "To remove a runner from the bases via a double play or pick off play",
              synonyms: [],
              antonyms: [],
              example: "Jones was erased by a 6-4-3 double play.",
            },
            {
              definition:
                "To be erased (have markings removed, have information removed or be cleared of information).",
              synonyms: [],
              antonyms: [],
              example: "The chalkboard erased easily.",
            },
            {
              definition:
                "To disregard (a group, an orientation, etc.); to prevent from having an active role in society.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To kill; assassinate.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: ["record"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/erase"],
    },
  ],
  posed: [
    {
      word: "posed",
      phonetic: "/pəʊzd/",
      phonetics: [
        { text: "/pəʊzd/", audio: "" },
        { text: "/poʊzd/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To place in an attitude or fixed position, for the sake of effect.",
              synonyms: [],
              antonyms: [],
              example: "To pose a model for a picture.",
            },
            {
              definition: "To ask; to set (a test, quiz, riddle, etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To constitute (a danger, a threat, a risk, etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                '(in the phrase "to pose as") To falsely impersonate (another person or occupation) primarily for the purpose of accomplishing something or reaching a goal.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To assume or maintain a pose; to strike an attitude.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To behave affectedly in order to attract interest or admiration.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To interrogate; to question.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To question with a view to puzzling; to embarrass by questioning or scrutiny; to bring to a stand.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To ask (someone) questions; to interrogate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To puzzle, non-plus, or embarrass with difficult questions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To perplex or confuse (someone).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Firm; determined; fixed.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pose",
        "https://en.wiktionary.org/wiki/posed",
      ],
    },
  ],
  asset: [
    {
      word: "asset",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/asset-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79218497",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈæsɪt/", audio: "" },
        { text: "/ˈæsət/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Something or someone of any value; any portion of one's property or effects so considered.",
              synonyms: [],
              antonyms: [],
              example: "These shares are a valuable asset.",
            },
            {
              definition:
                "Any component, model, process or framework of value that can be leveraged or reused.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An intelligence asset.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(usually in the plural) The private parts; a woman's breasts or buttocks, or a man's genitalia.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["liability"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/asset"],
    },
  ],
  cider: [
    {
      word: "cider",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cider-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75270039",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈsaɪ̯.də/", audio: "" },
        {
          text: "/ˈsaɪ̯.dɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cider-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1779978",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An alcoholic, often sparkling (carbonated) beverage made from fermented apples; hard cider; apple cider",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A non-alcoholic still beverage consisting of the juice of early-harvest apples, usually unfiltered and still containing pulp; apple cider; sweet cider (without pulp such a beverage is called apple juice).",
              synonyms: [],
              antonyms: [],
              example: "She liked an aged cider. He liked a harder cider.",
            },
            {
              definition:
                "A non-alcoholic carbonated beverage made from apples.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in Korea) A non-alcoholic drink, normally carbonated; equivalent to soft drink.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A cup, glass or serving of any of these beverages.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["sweet cider", "hard cider"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cider"],
    },
  ],
  taper: [
    {
      word: "taper",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/taper-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75858661",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈteɪpə/", audio: "" },
        {
          text: "/ˈteɪpɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/taper-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1953844",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A slender wax candle; a small lighted wax candle",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) a small light.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A tapering form; gradual diminution of thickness and/or cross section in an elongated object",
              synonyms: [],
              antonyms: [],
              example: "The legs of the table had a slight taper to them.",
            },
            {
              definition:
                "A thin stick used for lighting candles, either a wax-coated wick or a slow-burning wooden rod.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make thinner or narrower at one end.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To diminish gradually.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["narrow"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Tapered; narrowing to a point.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/taper"],
    },
    {
      word: "taper",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/taper-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75858661",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈteɪpə/", audio: "" },
        {
          text: "/ˈteɪpɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/taper-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1953844",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One who operates a tape machine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Someone who works with tape or tapes.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/taper"],
    },
  ],
  theft: [
    {
      word: "theft",
      phonetic: "/θɛft/",
      phonetics: [
        { text: "/θɛft/", audio: "" },
        { text: "/θɛft/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act of stealing property.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/theft"],
    },
  ],
  churn: [
    {
      word: "churn",
      phonetic: "/tʃɜː(ɹ)n/",
      phonetics: [
        { text: "/tʃɜː(ɹ)n/", audio: "" },
        {
          text: "/tʃɝn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/churn-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=310338",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A vessel used for churning, especially for producing butter.",
              synonyms: [],
              antonyms: [],
              example: "a butter churn",
            },
            {
              definition:
                "Customer attrition; the phenomenon or rate of customers leaving a company.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The time when a consumer switches his/her service provider.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The mass of people who are ready to switch carriers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Cyclic activity that achieves nothing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To agitate rapidly and repetitively, or to stir with a rowing or rocking motion; generally applies to liquids, notably cream.",
              synonyms: [],
              antonyms: [],
              example: "Now the cream is churned to make butter.",
            },
            {
              definition:
                "To produce excessive and sometimes undesirable or unproductive activity or motion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To move rapidly and repetitively with a rocking motion; to tumble, mix or shake.",
              synonyms: [],
              antonyms: [],
              example: "I was so nervous that my stomach was churning.",
            },
            {
              definition:
                "(of a customer) To stop using a company's product or service.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(travel) To repeatedly cancel and rebook a reservation in order to refresh ticket time limits or other fare rule restrictions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(travel) To continually sign up for new credit cards in order to earn signup bonuses, airline miles, and other benefits.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/churn"],
    },
  ],
  satin: [
    {
      word: "satin",
      phonetic: "/ˈsætɪn/",
      phonetics: [
        {
          text: "/ˈsætɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/satin-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2454277",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A cloth woven from silk, nylon or polyester with a glossy surface and a dull back. (The same weaving technique applied to cotton produces cloth termed sateen).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make (paper, silver, etc.) smooth and glossy like satin.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Semigloss.",
              synonyms: [],
              antonyms: [],
              example: "satin paint",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/satin"],
    },
  ],
  slots: [
    {
      word: "slots",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A broad, flat, wooden bar, a slat, especially as used to secure a door, window, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A metal bolt or wooden bar, especially as a crosspiece.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An implement for baring, bolting, locking or securing a door, box, gate, lid, window or the like.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A channel opening in the stator or rotor of a rotating machine for ventilation and insertion of windings.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The barrel or tube of a wave.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To bar, bolt or lock a door or window.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To shut with violence; to slam.",
              synonyms: [],
              antonyms: [],
              example: "to slot a door",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A narrow depression, perforation, or aperture; especially, one for the reception of a piece fitting or sliding in it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A gap in a schedule or sequence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The allocated time for an aircraft's departure or arrival at an airport's runway.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In a flying display, the fourth position; after the leader and two wingmen.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A space in memory or on disk etc. in which a particular type of object can be stored.",
              synonyms: [],
              antonyms: [],
              example: "The game offers four save slots.",
            },
            {
              definition: "A slot machine designed for gambling.",
              synonyms: [],
              antonyms: [],
              example:
                "I walked past the poker tables and went straight to the slots.",
            },
            { definition: "The vagina.", synonyms: [], antonyms: [] },
            {
              definition: "The track of an animal, especially a deer; spoor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Antarctica) A crack or fissure in a glacier or snowfield; a chasm; a crevasse.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To put something (such as a coin) into a slot (narrow aperture)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To assign something or someone into a slot (gap in a schedule or sequence)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To put something where it belongs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Rhodesia, in the context of the Rhodesian Bush War) To kill.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Antarctica) To fall, or cause to fall, into a crevasse.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/slot",
        "https://en.wiktionary.org/wiki/slots",
      ],
    },
  ],
  taxed: [
    {
      word: "taxed",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To impose and collect a tax from (a person or company).",
              synonyms: [],
              antonyms: [],
              example: "Some think to tax the wealthy is the fairest.",
            },
            {
              definition: "To impose and collect a tax on (something).",
              synonyms: [],
              antonyms: [],
              example:
                "Some think to tax wealth is destructive of a private sector.",
            },
            {
              definition: "To make excessive demands on.",
              synonyms: [],
              antonyms: [],
              example: "Do not tax my patience.",
            },
            { definition: "To accuse.", synonyms: [], antonyms: [] },
            {
              definition:
                "To examine accounts in order to allow or disallow items.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Subject to taxation.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: ["untaxed"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tax",
        "https://en.wiktionary.org/wiki/taxed",
      ],
    },
  ],
  bully: [
    {
      word: "bully",
      phonetic: "/ˈbʊli/",
      phonetics: [
        {
          text: "/ˈbʊli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bully-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1160862",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person who is intentionally, physically, or emotionally cruel to others; especially to those who are weaker or have less power or privilege.",
              synonyms: [],
              antonyms: [],
              example: "A playground bully pushed a girl off the swing.",
            },
            {
              definition:
                "A noisy, blustering fellow, more insolent than courageous; one who is threatening and quarrelsome; an insolent, tyrannical fellow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A hired thug.",
              synonyms: ["henchman", "thug"],
              antonyms: [],
            },
            {
              definition: "A sex worker’s minder.",
              synonyms: ["pimp"],
              antonyms: [],
            },
            { definition: "Bully beef.", synonyms: [], antonyms: [] },
            {
              definition: "A brisk, dashing fellow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The small scrum in the Eton College field game.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Various small freshwater or brackishwater fish of the family Eleotridae; sleeper goby.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An (eldest) brother; a fellow workman; comrade",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A companion; mate (male or female).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A darling, sweetheart (male or female).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A standoff between two players from the opposing teams, who repeatedly hit each other's hockey sticks and then attempt to acquire the ball, as a method of resuming the game in certain circumstances.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A miner's hammer.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["henchman", "thug", "pimp"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To intimidate (someone) as a bully.",
              synonyms: [],
              antonyms: [],
              example: "You shouldn't bully people for being gay.",
            },
            {
              definition: "To act aggressively towards.",
              synonyms: ["push around", "ride roughshod over"],
              antonyms: [],
            },
          ],
          synonyms: ["push around", "ride roughshod over"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Very good.",
              synonyms: ["excellent"],
              antonyms: [],
              example: "a bully horse",
            },
            {
              definition: "Jovial and blustering.",
              synonyms: ["dashing"],
              antonyms: [],
            },
          ],
          synonyms: ["dashing", "excellent"],
          antonyms: [],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "(often followed by for) Well done!",
              synonyms: [],
              antonyms: [],
              example: "Bully, she's finally asked for that promotion!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bully"],
    },
  ],
  sloth: [
    {
      word: "sloth",
      phonetic: "/slɑθ/",
      phonetics: [
        { text: "/slɑθ/", audio: "" },
        { text: "/slɒθ/", audio: "" },
        {
          text: "/slɒθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sloth-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=16555275",
        },
        { text: "/slɔθ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Laziness; slowness in the mindset; disinclination to action or labour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A herbivorous, arboreal South American mammal of the families Megalonychidae and Bradypodidae, noted for its slowness and inactivity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A collective term for a group of bears.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To be idle; to idle (away time).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sloth"],
    },
  ],
  shale: [
    {
      word: "shale",
      phonetic: "/ʃeɪl/",
      phonetics: [{ text: "/ʃeɪl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A shell or husk; a cod or pod.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A fine-grained sedimentary rock of a thin, laminated, and often friable, structure.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To take off the shell or coat of.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["shell"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shale"],
    },
  ],
  tread: [
    {
      word: "tread",
      phonetic: "/tɹɛd/",
      phonetics: [
        {
          text: "/tɹɛd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tread-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=33142671",
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To step or walk (on or over something); to trample.",
              synonyms: [],
              antonyms: [],
              example: "Don't tread on the lawn.",
            },
            {
              definition: "To step or walk upon.",
              synonyms: [],
              antonyms: [],
              example: "Actors tread the boards.",
            },
            {
              definition: "To beat or press with the feet.",
              synonyms: [],
              antonyms: [],
              example:
                "to tread a path; to tread land when too light; a well-trodden path",
            },
            {
              definition:
                "To go through or accomplish by walking, dancing, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To crush under the foot; to trample in contempt or hatred; to subdue.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To copulate; said of (especially male) birds.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of a male bird) To copulate with.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tread"],
    },
    {
      word: "tread",
      phonetic: "/tɹɛd/",
      phonetics: [
        {
          text: "/tɹɛd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tread-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=33142671",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A step taken with the foot.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A manner of stepping.", synonyms: [], antonyms: [] },
            {
              definition:
                "The sound made when someone or something is walking.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A way; a track or path.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The horizontal part of a step in a flight of stairs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The grooves carved into the face of a tire, used to give the tire traction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The grooves on the bottom of a shoe or other footwear, used to give grip or traction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The chalaza of a bird's egg; the treadle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The act of avian copulation in which the male bird mounts the female by standing on her back.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(fortification) The top of the banquette, on which soldiers stand to fire over the parapet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bruise or abrasion produced on the foot or ankle of a horse that interferes, or strikes its feet together.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["run"],
          antonyms: ["rise", "riser"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tread"],
    },
  ],
  raked: [
    {
      word: "raked",
      phonetic: "/ɹeɪkt/",
      phonetics: [{ text: "/ɹeɪkt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To walk; to roam, to wander.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of animals (especially sheep): to graze.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To roam or wander through (somewhere).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To act upon with a rake, or as if with a rake.",
              synonyms: [],
              antonyms: [],
              example: "She is raking the gravel path to keep it even.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To move swiftly; to proceed rapidly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of a bird of prey: to fly after a quarry; also, to fly away from the falconer, to go wide of the quarry being pursued.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To incline (something) from a perpendicular direction.",
              synonyms: ["slope"],
              antonyms: [],
              example: "A mast rakes aft.",
            },
            {
              definition: "Senses relating to watercraft.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["slope"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To behave as a rake; to lead a hedonistic and immoral life.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [{ definition: "Sloping", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/raik",
        "https://en.wiktionary.org/wiki/rake",
        "https://en.wiktionary.org/wiki/raked",
      ],
    },
  ],
  curds: [
    {
      word: "curds",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The part of milk that coagulates when it sours or is treated with enzymes; used to make cottage cheese, dahi, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The coagulated part of any liquid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The edible flower head of certain brassicaceous plants.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To form curd; to curdle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause to coagulate or thicken; to cause to congeal; to curdle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/curd",
        "https://en.wiktionary.org/wiki/curds",
      ],
    },
  ],
  manor: [
    {
      word: "manor",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/manor-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79268840",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈmænə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/manor-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=81466768",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈmænəɹ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A landed estate.", synonyms: [], antonyms: [] },
            {
              definition:
                "The main house of such an estate or a similar residence; a mansion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A district over which a feudal lord could exercise certain rights and privileges in medieval western Europe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The lord's residence and seat of control in such a district.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any home area or territory in which authority is exercised, often in a police or criminal context.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One's neighbourhood.",
              synonyms: [],
              antonyms: [],
              example:
                '2012, July 30, Shekhar Bhatia, "My East End manor is now as smart as Notting Hill", The Evening Standard',
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/manor"],
    },
  ],
  aisle: [
    {
      word: "aisle",
      phonetic: "/aɪ̯l/",
      phonetics: [
        { text: "/aɪ̯l/", audio: "" },
        {
          text: "/aɪ̯l/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/aisle-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=174343",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A wing of a building, notably in a church separated from the nave proper by piers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A clear path through rows of seating.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A clear corridor in a supermarket with shelves on both sides containing goods for sale.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any path through an otherwise obstructed space.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Seat in public transport, such as a plane, train or bus, that's beside the aisle.",
              synonyms: [],
              antonyms: [],
              example: "Do you want to seat window or aisle?",
            },
            {
              definition:
                "An idiomatic divide between the Republican Party and the Democratic Party, who are said to be on two sides of the aisle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["isle", "aisle seat"],
          antonyms: ["window", "window seat"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/aisle"],
    },
  ],
  bulge: [
    {
      word: "bulge",
      phonetic: "/bʌldʒ/",
      phonetics: [
        {
          text: "/bʌldʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bulge-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88681266",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/bʊldʒ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Something sticking out from a surface; a swelling, protuberant part; a bending outward, especially when caused by pressure.",
              synonyms: [],
              antonyms: [],
              example: "a bulge in a wall",
            },
            {
              definition: "The bilge or protuberant part of a cask.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The bilge of a vessel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The outline of male genitals visible through clothing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sudden rise in value or quantity.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To stick out from (a surface).",
              synonyms: [],
              antonyms: [],
              example:
                "He stood six feet tall, with muscular arms bulging out of his black T-shirt.",
            },
            {
              definition: "To bilge, as a ship; to founder.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bulge"],
    },
  ],
  loins: [
    {
      word: "loins",
      phonetic: "/lɔɪnz/",
      phonetics: [{ text: "/lɔɪnz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The part of the body (of humans and quadrupeds) at each side of the backbone, between the ribs and hips",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of several cuts of meat taken from this part of an animal",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The pubic region.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/loin",
        "https://en.wiktionary.org/wiki/loins",
      ],
    },
  ],
  stair: [
    {
      word: "stair",
      phonetic: "/stɛə/",
      phonetics: [
        { text: "/stɛə/", audio: "" },
        {
          text: "/stɛəɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stair-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1785284",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A single step in a staircase.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A series of steps; a staircase.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["apples and pears"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stair"],
    },
  ],
  tapes: [
    {
      word: "tapes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Flexible material in a roll with a sticky surface on one or both sides; adhesive tape.",
              synonyms: [],
              antonyms: [],
              example: "Hand me some tape. I need to fix a tear in this paper.",
            },
            {
              definition:
                "Thin and flat paper, plastic or similar flexible material, usually produced in the form of a roll.",
              synonyms: [],
              antonyms: [],
              example: "After the party there was tape all over the place.",
            },
            {
              definition:
                "Finishing tape, stretched across a track to mark the end of a race.",
              synonyms: [],
              antonyms: [],
              example:
                "Jones broke the tape in 47.77 seconds, a new world record.",
            },
            {
              definition:
                "Magnetic or optical recording media in a roll; videotape or audio tape.",
              synonyms: [],
              antonyms: [],
              example: "Did you get that on tape?",
            },
            {
              definition:
                "(by extension) Any video or audio recording, regardless of the method used to produce it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An unthinking, patterned response triggered by a particular stimulus.",
              synonyms: [],
              antonyms: [],
              example:
                "Old couples will sometimes play tapes at each other during a fight.",
            },
            {
              definition:
                "(from ticker tape) The series of prices at which a financial instrument trades.",
              synonyms: [],
              antonyms: [],
              example: "Don’t fight the tape.",
            },
            {
              definition:
                "The wrapping of the primary puck-handling surface of a hockey stick",
              synonyms: [],
              antonyms: [],
              example: "His pass was right on the tape.",
            },
            {
              definition:
                "A strong flexible band rotating on pulleys for directing the sheets in a printing machine.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To bind with adhesive tape.",
              synonyms: [],
              antonyms: [],
              example:
                "Be sure to tape your parcel securely before posting it.",
            },
            {
              definition: "To record, particularly onto magnetic tape.",
              synonyms: [],
              antonyms: [],
              example:
                "You shouldn’t have said that. The microphone was on and we were taping.",
            },
            {
              definition: "(passive) To understand, figure out.",
              synonyms: [],
              antonyms: [],
              example: "I've finally got this thing taped.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tape",
        "https://en.wiktionary.org/wiki/tapes",
      ],
    },
  ],
  leans: [
    {
      word: "leans",
      phonetic: "/liːnz/",
      phonetics: [{ text: "/liːnz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(of an object taller than its width and depth) An inclination away from the vertical.",
              synonyms: [],
              antonyms: [],
              example: "The trees had various leans toward gaps in the canopy.",
            },
          ],
          synonyms: ["tilt"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To incline, deviate, or bend, from a vertical position; to be in a position thus inclining or deviating.",
              synonyms: [],
              antonyms: [],
              example: "She leaned out of the window.",
            },
            {
              definition:
                "To incline in opinion or desire; to conform in conduct; often with to, toward, etc.",
              synonyms: [],
              antonyms: [],
              example:
                "I’m leaning towards voting Conservative in the next election.  The Hispanic vote leans Democratic.",
            },
            {
              definition:
                "Followed by against, on, or upon: to rest or rely, for support, comfort, etc.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To hang outwards.", synonyms: [], antonyms: [] },
            { definition: "To press against.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Meat with no fat on it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An organism that is lean in stature.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To thin out (a fuel-air mixture): to reduce the fuel flow into the mixture so that there is more air or oxygen.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To conceal.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lean",
        "https://en.wiktionary.org/wiki/leans",
      ],
    },
  ],
  bunks: [
    {
      word: "bunks",
      phonetic: "/bʌŋks/",
      phonetics: [{ text: "/bʌŋks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One of a series of berths or beds placed in tiers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A built-in bed on board ship, often erected in tiers one above the other.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A cot.", synonyms: [], antonyms: [] },
            {
              definition:
                "A wooden case or box, which serves for a seat in the daytime and for a bed at night.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of wood placed on a lumberman's sled to sustain the end of heavy timbers.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To occupy a bunk.", synonyms: [], antonyms: [] },
            { definition: "To provide a bunk.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To fail to attend school or work without permission; to play truant (usually as in 'to bunk off').",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To expel from a school.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bunk",
        "https://en.wiktionary.org/wiki/bunks",
      ],
    },
  ],
  squat: [
    {
      word: "squat",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/squat-1-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75858355",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/skwɒt/", audio: "" },
        {
          text: "/skwɑt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/squat-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=11693766",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Nothing; nothing whatsoever.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A position assumed by bending deeply at the knees while resting on one's feet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(exercise) Any of various modes of callisthenic exercises performed by moving the body and bending at least one knee.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A building occupied without permission, as practiced by a squatter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A place of concealment in which a hare spends time when inactive, especially during the day; a form.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A toilet used by squatting as opposed to sitting; a squat toilet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sudden or crushing fall.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A small vein of ore.", synonyms: [], antonyms: [] },
            {
              definition: "A mineral consisting of tin ore and spar.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To bend deeply at the knees while resting on one's feet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(exercise) To perform one or more callisthenic exercises by moving the body and bending at least one knee.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To occupy or reside in a place without the permission of the owner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To sit close to the ground; to cower; to stoop, or lie close, to escape observation, as a partridge or rabbit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To bruise or flatten by a fall; to squash.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Relatively short or low, and thick or broad.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Sitting on the hams or heels; sitting close to the ground; cowering; crouching.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/diddly-squat",
        "https://en.wiktionary.org/wiki/squat",
      ],
    },
    {
      word: "squat",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The angel shark (genus Squatina).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/squat"],
    },
  ],
  towed: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  lance: [
    {
      word: "lance",
      phonetic: "/lɑːns/",
      phonetics: [
        { text: "/lɑːns/", audio: "" },
        {
          text: "/læns/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lance-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651117",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A weapon of war, consisting of a long shaft or handle and a steel blade or head; a spear carried by horsemen.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A wooden spear, sometimes hollow, used in jousting or tilting, designed to shatter on impact with the opposing knight’s armour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A spear or harpoon used by whalers and fishermen.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A soldier armed with a lance; a lancer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An instrument which conveys the charge of a piece of ordnance and forces it home.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(founding) A small iron rod which suspends the core of the mold in casting a shell.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the small paper cases filled with combustible composition, which mark the outlines of a figure.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A lancet.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To pierce with a lance, or with any similar weapon.",
              synonyms: [],
              antonyms: [],
              example:
                "Seized the due victim, and with fury lanced Her back. Dryden.",
            },
            {
              definition: "To open with a lancet; to pierce",
              synonyms: [],
              antonyms: [],
              example: "to lance a vein or an abscess",
            },
            {
              definition: "To throw in the manner of a lance; to lanch.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lance"],
    },
  ],
  panes: [
    {
      word: "panes",
      phonetic: "/peɪnz/",
      phonetics: [
        {
          text: "/peɪnz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/panes-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=81997226",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An individual sheet of glass in a window, door, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A layer in the build-up of a GUI.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A division; a distinct piece or compartment of any surface.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A square of a checkered or plaid pattern.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the openings in a slashed garment, showing the bright colored silk, or the like, within; hence, the piece of colored or other stuff so shown.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A compartment of a surface, or a flat space; hence, one side or face of a building.",
              synonyms: [],
              antonyms: [],
              example: "An octagonal tower is said to have eight panes.",
            },
            {
              definition:
                "A subdivision of an irrigated surface between a feeder and an outlet drain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the flat surfaces, or facets, of any object having several sides.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the eight facets surrounding the table of a brilliant-cut diamond.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The (often spherical) end of the head of a hammer opposite the main hammering end.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pane",
        "https://en.wiktionary.org/wiki/panes",
        "https://en.wiktionary.org/wiki/peen",
      ],
    },
  ],
  sakes: [
    {
      word: "sakes",
      phonetic: "/seɪks/",
      phonetics: [{ text: "/seɪks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An alcoholic beverage made from fermenting various forms of rice, usually with an ABV similar to wine.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["sake"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Cause, interest or account",
              synonyms: [],
              antonyms: [],
              example: "For the sake of argument",
            },
            {
              definition: "Purpose or end; reason",
              synonyms: [],
              antonyms: [],
              example: "For old times' sake",
            },
            {
              definition: "The benefit or regard of someone or something",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(obsolete except in phrases) contention, strife; guilt, sin, accusation or charge",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A class of Japanese rice wines made from polished rice and typically about 20% alcohol by volume.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rice%20wine",
        "https://en.wiktionary.org/wiki/sake",
        "https://en.wiktionary.org/wiki/sakes",
      ],
    },
    {
      word: "sakes",
      phonetic: "/sɑːkis/",
      phonetics: [{ text: "/sɑːkis/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An alcoholic beverage made from fermenting various forms of rice, usually with an ABV similar to wine.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["sake"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Cause, interest or account",
              synonyms: [],
              antonyms: [],
              example: "For the sake of argument",
            },
            {
              definition: "Purpose or end; reason",
              synonyms: [],
              antonyms: [],
              example: "For old times' sake",
            },
            {
              definition: "The benefit or regard of someone or something",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(obsolete except in phrases) contention, strife; guilt, sin, accusation or charge",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A class of Japanese rice wines made from polished rice and typically about 20% alcohol by volume.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rice%20wine",
        "https://en.wiktionary.org/wiki/sake",
        "https://en.wiktionary.org/wiki/sakes",
      ],
    },
  ],
  heirs: [
    {
      word: "heirs",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/heirs-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2454082",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Someone who inherits, or is designated to inherit, the property of another.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One who inherits, or has been designated to inherit, a hereditary title or office.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A successor in a role, representing continuity with the predecessor.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["beneficiary", "inheritor", "inheritor"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/heir",
        "https://en.wiktionary.org/wiki/heirs",
      ],
    },
  ],
  caste: [
    {
      word: "caste",
      phonetic: "/kɑːst/",
      phonetics: [
        { text: "/kɑːst/", audio: "" },
        {
          text: "/kæst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/caste-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453413",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of the hereditary social classes and subclasses of South Asian societies.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A separate and fixed order or class of persons in society who chiefly associate with each other.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A class of polymorphous eusocial insects of a particular size and function within a colony.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/caste"],
    },
  ],
  dummy: [
    {
      word: "dummy",
      phonetic: "/ˈdʌmi/",
      phonetics: [
        {
          text: "/ˈdʌmi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dummy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75269236",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A silent person; a person who does not talk.",
              synonyms: ["dumbie"],
              antonyms: [],
            },
            {
              definition: "An unintelligent person.",
              synonyms: ["dumbie"],
              antonyms: [],
              example: "Don't be such a dummy!",
            },
            {
              definition:
                "A figure of a person or animal used by a ventriloquist; a puppet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something constructed with the size and form of a human, to be used in place of a person.",
              synonyms: ["mannequin", "marionette"],
              antonyms: [],
              example:
                "To understand the effects of the accident, we dropped a dummy from the rooftop.",
            },
            {
              definition:
                "A person who is the mere tool of another; a man of straw.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A deliberately nonfunctional device or tool used in place of a functional one.",
              synonyms: [],
              antonyms: [],
              example: "The hammer and drill in the display are dummies.",
            },
            {
              definition:
                'A "dummy teat"; a plastic or rubber teat used to soothe or comfort a baby; a pacifier.',
              synonyms: [],
              antonyms: [],
              example: "The baby wants her dummy.",
            },
            {
              definition:
                "A player whose hand is shown and is to be played from by another player.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A word serving only to make a construction grammatical.",
              synonyms: [],
              antonyms: [],
              example:
                'The pronoun "it" in "It\'s a mystery why this happened" is a dummy.',
            },
            {
              definition: "An unused parameter or value.",
              synonyms: [],
              antonyms: [],
              example: "If flag1 is false, the other parameters are dummies.",
            },
            {
              definition:
                "A feigned pass or kick or play in order to deceive an opponent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bodily gesture meant to fool an opposing player; a feint.",
              synonyms: ["juke"],
              antonyms: [],
            },
          ],
          synonyms: ["dumbie", "dumbie", "juke", "mannequin", "marionette"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make a mock-up or prototype version of something, without some or all off its intended functionality.",
              synonyms: [],
              antonyms: [],
              example: "The carpenters dummied some props for the rehearsals.",
            },
            { definition: "To feint.", synonyms: ["juke"], antonyms: [] },
          ],
          synonyms: ["juke"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dummy"],
    },
  ],
  pores: [
    {
      word: "pores",
      phonetic: "/pɔɹz/",
      phonetics: [
        {
          text: "/pɔɹz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pores-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=61085173",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A tiny opening in the skin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "By extension any small opening or interstice, especially one of many, or one allowing the passage of a fluid.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To study meticulously; to go over again and again.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To meditate or reflect in a steady way.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pore",
        "https://en.wiktionary.org/wiki/pores",
      ],
    },
  ],
  fauna: [
    {
      word: "fauna",
      phonetic: "/ˈfɔː.nə/",
      phonetics: [{ text: "/ˈfɔː.nə/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Animals considered as a group; especially those of a particular country, region, time.",
              synonyms: [],
              antonyms: [],
              example: "the fauna of China",
            },
            {
              definition: "A book, cataloguing the animals of a country.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fauna"],
    },
  ],
  crook: [
    {
      word: "crook",
      phonetic: "/kɹʊk/",
      phonetics: [
        {
          text: "/kɹʊk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/crook-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75270162",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A bend; turn; curve; curvature; a flexure.",
              synonyms: [],
              antonyms: [],
              example: "She held the baby in the crook of her arm.",
            },
            {
              definition: "A bending of the knee; a genuflection.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bent or curved part; a curving piece or portion (of anything).",
              synonyms: [],
              antonyms: [],
              example: "the crook of a cane",
            },
            {
              definition: "A lock or curl of hair.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A gibbet.", synonyms: [], antonyms: [] },
            {
              definition:
                "A support beam consisting of a post with a cross-beam resting upon it; a bracket or truss consisting of a vertical piece, a horizontal piece, and a strut.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'A shepherd\'s crook; a staff with a semi-circular bend ("hook") at one end used by shepherds.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A bishop's staff of office.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An artifice; a trick; a contrivance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person who steals, lies, cheats or does other dishonest or illegal things; a criminal.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A pothook.", synonyms: [], antonyms: [] },
            {
              definition:
                "A small tube, usually curved, applied to a trumpet, horn, etc., to change its pitch or key.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To bend, or form into a hook.",
              synonyms: [],
              antonyms: [],
              example: "He crooked his finger toward me.",
            },
            {
              definition: "To become bent or hooked.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To turn from the path of rectitude; to pervert; to misapply; to twist.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/crook"],
    },
    {
      word: "crook",
      phonetic: "/kɹʊk/",
      phonetics: [
        {
          text: "/kɹʊk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/crook-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75270162",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Bad, unsatisfactory, not up to standard.",
              synonyms: [],
              antonyms: [],
              example: "Not turning up for training was pretty crook.",
            },
            {
              definition: "Ill, sick.",
              synonyms: [],
              antonyms: [],
              example: "I′m feeling a bit crook.",
            },
            {
              definition: "Annoyed, angry; upset.",
              synonyms: [],
              antonyms: [],
              example: "be crook at/about; go crook at",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/crook"],
    },
  ],
  poise: [
    {
      word: "poise",
      phonetic: "/pɔɪz/",
      phonetics: [
        {
          text: "/pɔɪz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/poise-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=95143542",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A state of balance, equilibrium or stability.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Composure; freedom from embarrassment or affectation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Mien; bearing or deportment of the head or body.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A condition of hovering, or being suspended.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A CGS unit of dynamic viscosity equal to one dyne-second per square centimetre.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Weight; an amount of weight, the amount something weighs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The weight, or mass of metal, used in weighing, to balance the substance weighed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "That which causes a balance; a counterweight.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To hang in equilibrium; to be balanced or suspended; hence, to be in suspense or doubt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To counterpoise; to counterbalance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be of a given weight; to weigh.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To add weight to, to weigh down.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To hold (something) with or against something else in equilibrium; to balance, counterpose.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To hold (something) in equilibrium, to hold balanced and ready; to carry (something) ready to be used.",
              synonyms: [],
              antonyms: [],
              example: "I poised the crowbar in my hand, and waited.",
            },
            {
              definition:
                "To keep (something) in equilibrium; to hold suspended or balanced.",
              synonyms: [],
              antonyms: [],
              example:
                "The rock was poised precariously on the edge of the cliff.",
            },
            {
              definition: "To ascertain, as if by balancing; to weigh.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/poise"],
    },
  ],
  epoch: [
    {
      word: "epoch",
      phonetic: "/ˈiːˌpɒk/",
      phonetics: [
        { text: "/ˈiːˌpɒk/", audio: "" },
        {
          text: "/ˈeɪˌpɑk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/epoch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1093133",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A particular period of history, especially one considered noteworthy or remarkable.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A notable event which marks the beginning of such a period.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A precise instant of time that is used as a point of reference.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Uses in computing.", synonyms: [], antonyms: [] },
            {
              definition:
                "A unit of geologic time subdividing a period into smaller parts.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["age", "era"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To divide (data) into segments by time period.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/epoch"],
    },
  ],
  risky: [
    {
      word: "risky",
      phonetic: "/ˈɹɪski/",
      phonetics: [{ text: "/ˈɹɪski/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Dangerous, involving risks.",
              synonyms: [],
              antonyms: [],
              example: "Investing in this start-up company could be risky.",
            },
          ],
          synonyms: ["dangerous", "hazardous", "perilous"],
          antonyms: ["riskless", "safe", "secure"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/risky"],
    },
  ],
  warns: [
    {
      word: "warns",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make (someone) aware of (something impending); especially:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To caution or admonish (someone) against unwise or unacceptable behaviour.",
              synonyms: [],
              antonyms: [],
              example:
                "Don't let me catch you running in the corridor again, I warn you.",
            },
            {
              definition:
                '(chiefly with "off", "away", and similar words) To advise or order to go or stay away.',
              synonyms: [],
              antonyms: [],
              example: "A sign warns trespassers off/away from the site.",
            },
            { definition: "To give warning.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/warn",
        "https://en.wiktionary.org/wiki/warns",
      ],
    },
  ],
  fling: [
    {
      word: "fling",
      phonetic: "/ˈflɪŋ/",
      phonetics: [
        {
          text: "/ˈflɪŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fling-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762680",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An act of throwing, often violently.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An act of moving the limbs or body with violent movements, especially in a dance.",
              synonyms: [],
              antonyms: [],
              example: "the fling of a horse",
            },
            {
              definition: "An act or period of unrestrained indulgence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A short casual sexual relationship.",
              synonyms: ["hookup"],
              antonyms: [],
              example: "I had a fling with a girl I met on holiday.",
            },
            {
              definition: 'An attempt, a try (as in "give it a fling").',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A severe or contemptuous remark; an expression of sarcastic scorn; a gibe or taunt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A lively Scottish country dance.",
              synonyms: [],
              antonyms: [],
              example: "the Highland fling",
            },
            {
              definition: "A trifling matter; an object of contempt.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["hookup"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fling"],
    },
    {
      word: "fling",
      phonetic: "/ˈflɪŋ/",
      phonetics: [
        {
          text: "/ˈflɪŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fling-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762680",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To move (oneself) abruptly or violently; to rush or dash.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To throw with violence or quick movement; to hurl.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To throw; to wince; to flounce.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To utter abusive language; to sneer.",
              synonyms: [],
              antonyms: [],
              example: "The scold began to flout and fling.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fling"],
    },
  ],
  berry: [
    {
      word: "berry",
      phonetic: "/ˈbɛɹi/",
      phonetics: [
        {
          text: "/ˈbɛɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/berry-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=837237",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small succulent fruit, of any one of many varieties.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A soft fruit which develops from a single ovary and contains seeds not encased in pits.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A coffee bean.", synonyms: [], antonyms: [] },
            {
              definition: "One of the ova or eggs of a fish.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(African American) A police car.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A dollar.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To pick berries.",
              synonyms: [],
              antonyms: [],
              example:
                "On summer days Grandma used to take us berrying, whether we wanted to go or not.",
            },
            {
              definition: "To bear or produce berries.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/berry"],
    },
    {
      word: "berry",
      phonetic: "/ˈbɛɹi/",
      phonetics: [
        {
          text: "/ˈbɛɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/berry-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=837237",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A mound; a barrow.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/berry"],
    },
    {
      word: "berry",
      phonetic: "/ˈbɛɹi/",
      phonetics: [
        {
          text: "/ˈbɛɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/berry-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=837237",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A burrow, especially a rabbit's burrow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An excavation; a military mine.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/berry"],
    },
    {
      word: "berry",
      phonetic: "/ˈbɛɹi/",
      phonetics: [
        {
          text: "/ˈbɛɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/berry-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=837237",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To beat; give a beating to; thrash.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To thresh (grain).", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/berry"],
    },
  ],
  grape: [
    {
      word: "grape",
      phonetic: "/ɡɹeɪp/",
      phonetics: [
        {
          text: "/ɡɹeɪp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grape-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9021889",
          license: {
            name: "BY 3.0 US",
            url: "https://creativecommons.org/licenses/by/3.0/us",
          },
        },
        {
          text: "/ɡɹeɪp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grape-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762691",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small, round, smooth-skinned edible fruit, usually purple, red, or green, that grows in bunches on vines of genus Vitis.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A woody vine that bears clusters of grapes; a grapevine; of genus Vitis.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A dark purplish-red colour, the colour of many grapes.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Grapeshot.", synonyms: [], antonyms: [] },
            {
              definition: "A mangy tumour on a horse's leg.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A person's head.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To pick grapes.", synonyms: [], antonyms: [] },
            {
              definition:
                "(of livestock) To develop tubercules as a result of tuberculosis.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To develop a texture with small grape-like clusters of a contaminant or foreign substance.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "(north) To grope.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Containing grapes or having a grape flavor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of a dark purplish red colour.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/grape"],
    },
  ],
  flank: [
    {
      word: "flank",
      phonetic: "/flæŋk/",
      phonetics: [
        {
          text: "/flæŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/flank-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2707512",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The flesh between the last rib and the hip; the side.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A cut of meat from the flank of an animal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The extreme left or right edge of a military formation, army etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The sides of a bastion perpendicular to the wall from which the bastion projects.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The side of something, in general senses.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The outermost strip of a road.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The wing, one side of the pitch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That part of the acting surface of a gear wheel tooth that lies within the pitch line.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["side", "wing"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To attack the flank(s) of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To defend the flank(s) of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To place to the side(s) of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be placed to the side(s) of something (usually in terms of two objects, one on each side).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(of speed) Maximum. Historically faster than full speed (the most a vessel can sustain without excessive engine wear or risk of damage), now frequently used interchangeably. Typically used in an emergency or during an attack.",
              synonyms: [],
              antonyms: [],
              example: "All ahead flank!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/flank"],
    },
  ],
  drags: [
    {
      word: "drags",
      phonetic: "/dɹæɡz/",
      phonetics: [{ text: "/dɹæɡz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Resistance of the air (or some other fluid) to something moving through it.",
              synonyms: [],
              antonyms: [],
              example:
                "When designing cars, manufacturers have to take drag into consideration.",
            },
            {
              definition: "(foundry) The bottom part of a sand casting mold.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A device dragged along the bottom of a body of water in search of something, e.g. a dead body, or in fishing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A puff on a cigarette or joint.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Someone or something that is annoying or frustrating, or disappointing; an obstacle to progress or enjoyment.",
              synonyms: [],
              antonyms: [],
              example: "Travelling to work in the rush hour is a real drag.",
            },
            {
              definition:
                "A long open horse-drawn carriage with transverse or side seats.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Street, as in 'main drag'.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The scent-path left by dragging a fox, or some other substance such as aniseed, for training hounds to follow scents.",
              synonyms: [],
              antonyms: [],
              example: "to run a drag",
            },
            {
              definition:
                "A large amount of backspin on the cue ball, causing the cue ball to slow down.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A heavy harrow for breaking up ground.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A kind of sledge for conveying heavy objects; also, a kind of low car or handcart.",
              synonyms: [],
              antonyms: [],
              example: "a stone drag",
            },
            {
              definition:
                "The bottom part of a flask or mould, the upper part being the cope.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A steel instrument for completing the dressing of soft stone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The difference between the speed of a screw steamer under sail and that of the screw when the ship outruns the screw; or between the propulsive effects of the different floats of a paddle wheel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Anything towed in the water to retard a ship's progress, or to keep her head up to the wind; especially, a canvas bag with a hooped mouth (drag sail), so used.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A skid or shoe for retarding the motion of a carriage wheel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Motion affected with slowness and difficulty, as if clogged.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Witch house music.", synonyms: [], antonyms: [] },
            {
              definition: "The last position in a line of hikers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(aerodynamics) The act of suppressing wind flow to slow an aircraft in flight, as by use of flaps when landing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A push somewhat under the centre of the cue ball, causing it to follow the object ball a short way.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A device for guiding wood to the saw.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A mailcoach.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To pull along a surface or through a medium, sometimes with difficulty.",
              synonyms: [],
              antonyms: [],
              example: "Let's drag this load of wood over to the shed.",
            },
            {
              definition:
                "To move onward heavily, laboriously, or slowly; to advance with weary effort; to go on lingeringly.",
              synonyms: [],
              antonyms: [],
              example: "Time seems to drag when you’re waiting for a bus.",
            },
            {
              definition:
                "To act or proceed slowly or without enthusiasm; to be reluctant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To draw along (something burdensome); hence, to pass in pain or with difficulty.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To serve as a clog or hindrance; to hold back.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To move (an item) on the computer display by means of a mouse or other input device.",
              synonyms: [],
              antonyms: [],
              example: "Drag the file into the window to open it.",
            },
            {
              definition:
                "(chiefly of a vehicle) To unintentionally rub or scrape on a surface.",
              synonyms: [],
              antonyms: [],
              example:
                "The car was so low to the ground that its muffler was dragging on a speed bump.",
            },
            {
              definition: "To hit or kick off target.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fish with a dragnet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To search for something, as a lost object or body, by dragging something along the bottom of a body of water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To break (land) by drawing a drag or harrow over it; to harrow.",
              synonyms: ["harrow"],
              antonyms: [],
            },
            {
              definition: "To search exhaustively, as if with a dragnet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To roast, say negative things about, or call attention to the flaws of (someone).",
              synonyms: ["criticize"],
              antonyms: [],
              example: "You just drag him 'cause he's got more money than you.",
            },
          ],
          synonyms: ["criticize", "harrow"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Women's clothing worn by men for the purpose of entertainment.",
              synonyms: [],
              antonyms: [],
              example: "He performed in drag.",
            },
            {
              definition: "A men's party attended in women's clothing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any type of clothing or costume associated with a particular occupation or subculture.",
              synonyms: [],
              antonyms: [],
              example: "corporate drag",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To perform as a drag queen or drag king.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/drag",
        "https://en.wiktionary.org/wiki/drags",
      ],
    },
  ],
  squid: [
    {
      word: "squid",
      phonetic: "/skwɪd/",
      phonetics: [
        {
          text: "/skwɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/squid-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=280424",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of several carnivorous marine cephalopod mollusks, of the order Teuthida, having a mantle, eight arms, and a pair of tentacles",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A fishhook with a piece of bright lead, bone, or other substance fastened on its shank to imitate a squid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(mildly) A sailor in the Navy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A quid; one pound sterling.",
              synonyms: [],
              antonyms: [],
              example:
                "Can you lend me five squid? I feel like a bacon sarnie.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To fish with the kind of hook called a squid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(parachuting) To cause squidding (an improper, partial, parachute inflation, that results in the sides of the parachute folding in on the center, and pulsating back and forth).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/squid"],
    },
    {
      word: "squid",
      phonetic: "/skwɪd/",
      phonetics: [
        {
          text: "/skwɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/squid-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=280424",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A motorcyclist, especially a sport biker, characterized by reckless riding and lack of protective gear.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/squid"],
    },
  ],
  pelts: [
    {
      word: "pelts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A blow or stroke from something thrown.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To bombard, as with missiles.",
              synonyms: [],
              antonyms: [],
              example: "They pelted the attacking army with bullets.",
            },
            {
              definition: "To throw; to use as a missile.",
              synonyms: [],
              antonyms: [],
              example: "The children pelted apples at us.",
            },
            {
              definition: "To rain or hail heavily.",
              synonyms: [],
              antonyms: [],
              example: "It's pelting down out there!",
            },
            {
              definition: "To beat or hit, especially repeatedly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To move rapidly, especially in or on a conveyance.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To throw out words.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pelt",
        "https://en.wiktionary.org/wiki/pelts",
      ],
    },
  ],
  icing: [
    {
      word: "icing",
      phonetic: "/ˈaɪsɪŋ/",
      phonetics: [{ text: "/ˈaɪsɪŋ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sweet glaze made primarily of sugar and often flavored, typically used for baked goods; frosting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A minor violation of ice hockey rules, occurring when a player shoots the puck from his/her side of the red line so that it crosses the goal line on the opponent's side. A team playing short-handed is not penalized for this.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The process of forming a layer of ice on a surface.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/icing"],
    },
    {
      word: "icing",
      phonetic: "/ˈaɪsɪŋ/",
      phonetics: [{ text: "/ˈaɪsɪŋ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cool with ice, as a beverage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To become ice; to freeze.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make icy; to freeze.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To murder.", synonyms: [], antonyms: [] },
            {
              definition:
                "To cover with icing (frosting made of sugar and milk or white of egg); to frost; as cakes, tarts, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To put out a team for a match.",
              synonyms: [],
              antonyms: [],
              example: "Milton Keynes have yet to ice a team this season",
            },
            {
              definition:
                "To shoot the puck the length of the playing surface, causing a stoppage in play called icing.",
              synonyms: [],
              antonyms: [],
              example:
                "If the Bruins ice the puck, the faceoff will be in their own zone.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ice",
        "https://en.wiktionary.org/wiki/icing",
      ],
    },
  ],
  irony: [
    {
      word: "irony",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/irony-1-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75803794",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈaɪə.ɹən.i/", audio: "" },
        {
          text: "/ˈaɪ.ɚ.ni/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/irony-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=3574478",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A statement that, when taken in context, may actually mean something different from, or the opposite of, what is written literally; the use of words expressing something other than their literal intention, often in a humorous context.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Dramatic irony: a theatrical effect in which the meaning of a situation, or some incongruity in the plot, is understood by the audience, but not by the characters in the play.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Ignorance feigned for the purpose of confounding or provoking an antagonist; Socratic irony.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The state of two usually unrelated entities, parties, actions, etc. being related through a common connection in an uncommon way.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Contradiction between circumstances and expectations; condition contrary to what might be expected.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/irony"],
    },
    {
      word: "irony",
      phonetic: "/ˈaɪə.ni/",
      phonetics: [
        { text: "/ˈaɪə.ni/", audio: "" },
        {
          text: "/ˈaɪ.ɚ.ni/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/irony-2-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=3483320",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of or pertaining to the metal iron.",
              synonyms: [],
              antonyms: [],
              example: "The food had an irony taste to it.",
            },
          ],
          synonyms: ["ferric", "ferrous"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/irony"],
    },
  ],
  irons: [
    {
      word: "irons",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A common, inexpensive metal, silvery grey when untarnished, that rusts, is attracted by magnets, and is used in making steel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A metallic chemical element having atomic number 26 and symbol Fe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any material, not a steel, predominantly made of elemental iron.",
              synonyms: [],
              antonyms: [],
              example:
                "wrought iron, ductile iron, cast iron, pig iron, gray iron",
            },
            {
              definition:
                "A tool or appliance made of metal, which is heated and then used to transfer heat to something else; most often a thick piece of metal fitted with a handle and having a flat, roughly triangular bottom, which is heated and used to press wrinkles from clothing, and now usually containing an electrical heating apparatus.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(usually plural, irons) Shackles.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A firearm, either a long gun or a handgun.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A dark shade of the colour/color silver.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(shortened from iron hoof, rhyming with poof; countable) A male homosexual.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A golf club used for middle-distance shots.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Used as a symbol of great strength or toughness, or to signify a very strong or tough material.",
              synonyms: [],
              antonyms: [],
              example: "He appeared easygoing, but inside he was pure iron.",
            },
            {
              definition:
                "Weight used as resistance for the purpose of strength training.",
              synonyms: [],
              antonyms: [],
              example: "He lifts iron on the weekends.",
            },
            {
              definition: "A safety curtain in a theatre.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Dumb bombs, those without guidance systems.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "poof",
            "queer",
            "ferrum",
            "shackles",
            "energy",
            "force",
            "clothes iron",
            "flatiron",
            "smoothing iron",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To pass an iron over (clothing or some other item made of cloth) in order to remove creases.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To shackle with irons; to fetter or handcuff.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To furnish or arm with iron.",
              synonyms: [],
              antonyms: [],
              example: "to iron a wagon",
            },
          ],
          synonyms: ["press"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Shackles",
              synonyms: [],
              antonyms: [],
              example: "Up the irons!",
            },
            {
              definition: "A lack of forward motion.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The stirrups.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/iron",
        "https://en.wiktionary.org/wiki/irons",
      ],
    },
  ],
  barks: [
    {
      word: "barks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The short, loud, explosive sound uttered by a dog, a fox, and some other animals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An abrupt loud vocal utterance.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make a short, loud, explosive noise with the vocal organs (said of animals, especially dogs).",
              synonyms: ["give tongue"],
              antonyms: [],
              example: "The neighbour's dog is always barking.",
            },
            {
              definition: "To make a clamor; to make importunate outcries.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To speak sharply.",
              synonyms: [],
              antonyms: [],
              example: "The sergeant barked an order.",
            },
          ],
          synonyms: ["give tongue", "latrate"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The exterior covering of the trunk and branches of a tree.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Peruvian bark or Jesuit's bark, the bark of the cinchona from which quinine is produced.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Hard candy made in flat sheets, for instance out of chocolate, peanut butter, toffee or peppermint.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The crust formed on barbecued meat that has had a rub applied to it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The envelopment or outer covering of anything.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["rind"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To strip the bark from; to peel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To abrade or rub off any outer covering from.",
              synonyms: [],
              antonyms: [],
              example: "to bark one’s heel",
            },
            { definition: "To girdle.", synonyms: [], antonyms: [] },
            {
              definition: "To cover or inclose with bark, or as with bark.",
              synonyms: [],
              antonyms: [],
              example: "bark the roof of a hut",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small sailing vessel, e.g. a pinnace or a fishing smack; a rowing boat or barge.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sailing vessel or boat of any kind.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A vessel, typically with three (or more) masts, with the foremasts (or fore- and mainmasts) square-rigged, and mizzenmast schooner-rigged.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bark",
        "https://en.wiktionary.org/wiki/barks",
      ],
    },
  ],
  whoop: [
    {
      word: "whoop",
      phonetic: "/huːp/",
      phonetics: [
        {
          text: "/huːp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/whoop-1-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=86872601",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A loud, eager cry, usually of joy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A gasp, characteristic of whooping cough.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A bump on a racetrack.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To make a whoop.", synonyms: [], antonyms: [] },
            { definition: "To shout, to yell.", synonyms: [], antonyms: [] },
            {
              definition:
                "To cough or breathe with a sonorous inspiration, as in whooping cough.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To insult with shouts; to chase with derision.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/whoop"],
    },
    {
      word: "whoop",
      phonetic: "/wʊp/",
      phonetics: [
        {
          text: "/wʊp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/whoop-2-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88897398",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To beat, to strike.", synonyms: [], antonyms: [] },
            { definition: "To defeat thoroughly.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/whoop"],
    },
  ],
  choke: [
    {
      word: "choke",
      phonetic: "/t͡ʃəʊk/",
      phonetics: [
        { text: "/t͡ʃəʊk/", audio: "" },
        { text: "/t͡ʃoʊk/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A control on a carburetor to adjust the air/fuel mixture when the engine is cold.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In wrestling, karate (etc.), a type of hold that can result in strangulation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A constriction at the muzzle end of a shotgun barrel which affects the spread of the shot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A partial or complete blockage (of boulders, mud, etc.) in a cave passage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The mass of immature florets in the centre of the bud of an artichoke.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Choking coil", synonyms: [], antonyms: [] },
            {
              definition:
                "A major mistake at a crucial stage of a competition because one is nervous, especially when one is winning.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To be unable to breathe because of obstruction of the windpipe (for instance food or other objects that go down the wrong way, or fumes or particles in the air that cause the throat to constrict).",
              synonyms: [],
              antonyms: [],
              example:
                "Ever since he choked on a bone, he has refused to eat fish.",
            },
            {
              definition:
                "To prevent (someone) from breathing or talking by strangling or filling the windpipe.",
              synonyms: ["asphyxiate", "strangle", "suffocate", "throttle"],
              antonyms: [],
              example:
                "The collar of this shirt is too tight; it’s choking me.",
            },
            {
              definition:
                "To obstruct (a passage, etc.) by filling it up or clogging it.",
              synonyms: [
                "block up",
                "bung up",
                "clog",
                "congest",
                "jam",
                "obstruct",
                "stop up",
              ],
              antonyms: [],
              example: "to choke a cave passage with boulders and mud",
            },
            {
              definition:
                "To hinder or check, as growth, expansion, progress, etc.; to kill (a plant by robbing it of nutrients); to extinguish (fire by robbing it of oxygen).",
              synonyms: ["choke out", "stifle"],
              antonyms: [],
            },
            {
              definition:
                "To perform badly at a crucial stage of a competition because one is nervous, especially when one is winning.",
              synonyms: [],
              antonyms: [],
              example:
                "He has a lot of talent, but he tends to choke under pressure.",
            },
            {
              definition:
                "To move one's fingers very close to the tip of a pencil, brush or other art tool.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be checked or stopped, as if by choking",
              synonyms: ["stick"],
              antonyms: [],
            },
            {
              definition:
                "To check or stop (an utterance or voice) as if by choking.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To have a feeling of strangulation in one's throat as a result of passion or strong emotion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To give (someone) a feeling of strangulation as a result of passion or strong emotion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To say (something) with one’s throat constricted (due to emotion, for example).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To use the choke valve of (a vehicle) to adjust the air/fuel mixture in the engine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a duct) To reach a condition of maximum flowrate, due to the flow at the narrowest point of the duct becoming sonic (Ma = 1).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make a choke, as in a cartridge, or in the bore of the barrel of a shotgun.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "asphyxiate",
            "strangle",
            "suffocate",
            "throttle",
            "block up",
            "bung up",
            "clog",
            "congest",
            "jam",
            "obstruct",
            "stop up",
            "choke out",
            "stifle",
            "stick",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/choke"],
    },
  ],
  diets: [
    {
      word: "diets",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The food and beverage a person or animal consumes.",
              synonyms: [],
              antonyms: [],
              example: "The diet of the Giant Panda consists mainly of bamboo.",
            },
            {
              definition:
                "A controlled regimen of food and drink, as to gain or lose weight or otherwise influence health.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) Any habitual intake or consumption.",
              synonyms: [],
              antonyms: [],
              example:
                "He's been reading a steady diet of nonfiction for the last several years.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To regulate the food of (someone); to put on a diet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To modify one's food and beverage intake so as to decrease or increase body weight or influence health.",
              synonyms: [],
              antonyms: [],
              example:
                "I've been dieting for six months, and have lost some weight.",
            },
            {
              definition: "To eat; to take one's meals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause to take food; to feed.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(usually capitalized as a proper noun) A council or assembly of leaders; a formal deliberative assembly.",
              synonyms: [],
              antonyms: [],
              example:
                "They were given representation of some important diet committees.",
            },
            { definition: "A session of exams", synonyms: [], antonyms: [] },
            {
              definition: "The proceedings under a criminal libel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A clerical or ecclesiastical function in Scotland.",
              synonyms: [],
              antonyms: [],
              example: "a diet of worship",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/diet",
        "https://en.wiktionary.org/wiki/diets",
      ],
    },
  ],
  whips: [
    {
      word: "whips",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                'A lash; a pliant, flexible instrument, such as a rod (commonly of cane or rattan) or a plaited or braided rope or thong (commonly of leather) used to create a sharp "crack" sound for directing or herding animals.',
              synonyms: [],
              antonyms: [],
              example: "I had to use the whip to get the sheep's attention.",
            },
            {
              definition: "A blow administered with a whip.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A whipper-in.", synonyms: [], antonyms: [] },
            {
              definition:
                "A member of a political party who is in charge of enforcing the party's policies in votes.",
              synonyms: [],
              antonyms: [],
              example:
                "I was going to vote against the bill, but the party whip came to see me and made it clear I needed to vote for it.",
            },
            {
              definition:
                "(UK politics, with definite article) A document distributed weekly to MPs by party whips informing them of upcoming votes in parliament.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Whipped cream.",
              synonyms: [],
              antonyms: [],
              example: "Did you want to add some whip to your coffee, ma'am?",
            },
            {
              definition:
                "A purchase in which one block is used to gain a 2:1 mechanical advantage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A mode of personal motorized transportation; an automobile, all makes and models including motorcycles, excluding public transportation.",
              synonyms: [],
              antonyms: [],
              example:
                "Come on, let's take my whip so we can get there in time.",
            },
            {
              definition:
                "A move in which one player transfers momentum to another.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A whipping motion; a thrashing about.",
              synonyms: [],
              antonyms: [],
              example:
                "I was startled by the whip of the rope when it finally snapped.",
            },
            {
              definition:
                "The quality of being whiplike or flexible; suppleness, as of the shaft of a golf club.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various pieces that operate with a quick vibratory motion",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A coach driver; a coachman.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "cat",
            "flail",
            "knout",
            "lash",
            "quirt",
            "scourge",
            "sjambok",
            "thong",
            "crop",
            "dressage whip",
            "driving whip",
            "flail",
            "jumping bat",
            "knout",
            "lash",
            "quirt",
            "scourge",
            "sjambok",
            "thong",
            "party whip",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To hit with a whip.",
              synonyms: [],
              antonyms: [],
              example: "The rider whipped the horse.",
            },
            {
              definition: "(by extension) To hit with any flexible object.",
              synonyms: [],
              antonyms: [],
              example: "I whipped her with a newspaper.",
            },
            {
              definition: "To defeat, as in a contest or game.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To mix in a rapid aerating fashion, especially food.",
              synonyms: [],
              antonyms: [],
              example: "to whip eggs or cream",
            },
            {
              definition: "To urge into action or obedience.",
              synonyms: [],
              antonyms: [],
              example: "He whipped the department into shape.",
            },
            {
              definition:
                "To enforce a member voting in accordance with party policy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To bind the end of a rope with twine or other small stuff to prevent its unlaying: fraying or unravelling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To hoist or purchase by means of a whip.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To sew lightly; specifically, to form (a fabric) into gathers by loosely overcasting the rolled edge and drawing up the thread.",
              synonyms: [],
              antonyms: [],
              example: "to whip a ruffle",
            },
            {
              definition: "To throw or kick an object at a high velocity.",
              synonyms: [],
              antonyms: [],
              example: "He whipped the ball at me.",
            },
            {
              definition:
                "To fish a body of water especially by making repeated casts.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To snap back and forth like a whip.",
              synonyms: [],
              antonyms: [],
              example: "The pennants whipped in the wind.",
            },
            {
              definition: "To move very fast.",
              synonyms: [],
              antonyms: [],
              example: "The wind whipped through the valley.",
            },
            {
              definition:
                "To move (something) very fast; often with up, out, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To transfer momentum from one skater to another.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To lash with sarcasm, abuse, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To thrash; to beat out, as grain, by striking.",
              synonyms: [],
              antonyms: [],
              example: "to whip wheat",
            },
          ],
          synonyms: ["flail", "thrash", "thresh"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/whip",
        "https://en.wiktionary.org/wiki/whips",
      ],
    },
  ],
  tally: [
    {
      word: "tally",
      phonetic: "/ˈtæli/",
      phonetics: [
        {
          text: "/ˈtæli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tally-1.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50423075",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "Target sighted.",
              synonyms: [],
              antonyms: [],
              example:
                "(Air Traffic Control): Speedbird 123, New York, traffic at two o’clock, seven miles, a Boeing 737, west-bound, at 4000 feet.”",
            },
          ],
          synonyms: ["tallyho"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tally"],
    },
    {
      word: "tally",
      phonetic: "/ˈtæli/",
      phonetics: [
        {
          text: "/ˈtæli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tally-2.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50423075",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(by extension) One of two books, sheets of paper, etc., on which corresponding accounts were kept.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) Any account or score kept by notches or marks, whether on wood or paper, or in a book, especially one kept in duplicate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One thing made to suit another; a match; a mate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A notch, mark, or score made on or in a tally; as, to make or earn a score or tally in a game.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A tally shop.", synonyms: [], antonyms: [] },
            {
              definition:
                "A ribbon on a sailor's cap bearing the name of the ship or the (part of) the navy to which they belong.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A state of cohabitation, living with another individual in an intimate relationship outside of marriage.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A bone, or piece of wood, on which notches or scores are cut, as the marks of number, for account keeping.",
              synonyms: ["tally"],
              antonyms: [],
            },
          ],
          synonyms: ["tally"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tally",
        "https://en.wiktionary.org/wiki/tally%20stick",
      ],
    },
    {
      word: "tally",
      phonetic: "/ˈtæli/",
      phonetics: [
        {
          text: "/ˈtæli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tally-3.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50423075",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To count something.", synonyms: [], antonyms: [] },
            {
              definition: "To record something by making marks.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make things correspond or agree with each other.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To keep score.", synonyms: [], antonyms: [] },
            {
              definition: "To correspond or agree.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To check off, as parcels of freight going inboard or outboard.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["enumerate", "number"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tally"],
    },
    {
      word: "tally",
      phonetic: "/ˈtɑl.li/",
      phonetics: [{ text: "/ˈtɑl.li/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "In a tall way; stoutly; with spirit.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tally"],
    },
  ],
  dozed: [
    {
      word: "dozed",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To sleep lightly or briefly; to nap, snooze.",
              synonyms: [],
              antonyms: [],
              example:
                "I didn’t sleep very well, but I think I may have dozed a bit.",
            },
            {
              definition: "To make dull; to stupefy.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To bulldoze.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["slumber"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/doze",
        "https://en.wiktionary.org/wiki/dozed",
      ],
    },
  ],
  twine: [
    {
      word: "twine",
      phonetic: "/twaɪn/",
      phonetics: [
        {
          text: "/twaɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/twine-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88987531",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A twist; a convolution.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A strong thread composed of two or three smaller threads or strands twisted together, and used for various purposes, as for binding small parcels, making nets, and the like; a small cord or string.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The act of twining or winding round.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Intimate and suggestive dance gyrations.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/twine"],
    },
    {
      word: "twine",
      phonetic: "/twaɪn/",
      phonetics: [
        {
          text: "/twaɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/twine-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88987531",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To weave together.", synonyms: [], antonyms: [] },
            {
              definition:
                "To wind, as one thread around another, or as any flexible substance around another body.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To wind about; to embrace; to entwine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To mutually twist together; to become mutually involved; to intertwine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To wind; to bend; to make turns; to meander.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To ascend in spiral lines about a support; to climb spirally.",
              synonyms: [],
              antonyms: [],
              example: "Many plants twine.",
            },
            {
              definition: "To turn round; to revolve.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To change the direction of.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To mingle; to mix.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/twine"],
    },
    {
      word: "twine",
      phonetic: "/twaɪn/",
      phonetics: [
        {
          text: "/twaɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/twine-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88987531",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(obsolete outside Scotland) To separate, divide.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(obsolete outside Scotland) To split, part; to go away, depart.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(usually in the passive) To join, unite; to form links between (now especially of two places in different countries).",
              synonyms: [],
              antonyms: [],
              example:
                "Coventry twinned with Dresden as an act of peace and reconciliation, both cities having been heavily bombed during the war.",
            },
            {
              definition: "To be paired or suited.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To give birth to twins.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be born at the same birth.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/twin",
        "https://en.wiktionary.org/wiki/twine",
      ],
    },
  ],
  kites: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  bikes: [
    {
      word: "bikes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A vehicle that has two wheels, one behind the other, a steering handle, and a saddle seat or seats and is usually propelled by the action of a rider’s feet upon pedals.",
              synonyms: ["bike", "pushbike", "velocipede"],
              antonyms: [],
            },
            {
              definition: "A traveling block used on a cable in skidding logs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The best possible hand in lowball.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A motorbike.", synonyms: [], antonyms: [] },
            {
              definition:
                "(usually in compounds specifying a context) a slut; a promiscuous woman",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bike", "pushbike", "velocipede"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To ride a bike.",
              synonyms: [],
              antonyms: [],
              example: "I biked so much yesterday that I'm very sore today.",
            },
            {
              definition: "To travel by bike.",
              synonyms: [],
              antonyms: [],
              example:
                "It was such a nice day I decided to bike to the store, though it's far enough I usually take my car.",
            },
            {
              definition: "To transport by bicycle",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A hive of bees, or a nest of wasps, hornets, or ants.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension, collective) A crowd of people.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A motorcycle.", synonyms: [], antonyms: [] },
            {
              definition: "A small and light motorcycle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A local woman who is sexually promiscuous.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["town bicycle", "town bike", "village bicycle"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bicycle",
        "https://en.wiktionary.org/wiki/bike",
        "https://en.wiktionary.org/wiki/bikes",
        "https://en.wiktionary.org/wiki/motorbike",
        "https://en.wiktionary.org/wiki/village%20bike",
      ],
    },
  ],
  ticks: [
    {
      word: "ticks",
      phonetic: "/tɪks/",
      phonetics: [{ text: "/tɪks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A tiny woodland arachnid of the suborder Ixodida.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A relatively quiet but sharp sound generally made repeatedly by moving machinery.",
              synonyms: [],
              antonyms: [],
              example:
                "The steady tick of the clock provided a comforting background for the conversation.",
            },
            {
              definition:
                "A mark on any scale of measurement; a unit of measurement.",
              synonyms: [],
              antonyms: [],
              example: "At midday, the long bond is up a tick.",
            },
            {
              definition:
                "A jiffy (unit of time defined by basic timer frequency).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A short period of time, particularly a second.",
              synonyms: ["sec"],
              antonyms: [],
              example: "I'll be back in a tick.",
            },
            {
              definition:
                "A periodic increment of damage or healing caused by an ongoing status effect.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A mark (✓) made to indicate agreement, correctness or acknowledgement.",
              synonyms: ["checkmark"],
              antonyms: [],
              example:
                "Indicate that you are willing to receive marketing material by putting a tick in the box",
            },
            {
              definition:
                "A bird seen (or heard) by a birdwatcher, for the first time that day, year, trip, etc., and thus added to a list of observed birds.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The whinchat.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["checkmark", "sec"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make a clicking noise similar to the movement of the hands in an analog clock.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make a tick or checkmark.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To work or operate, especially mechanically.",
              synonyms: [],
              antonyms: [],
              example: "He took the computer apart to see how it ticked.",
            },
            {
              definition: "To strike gently; to pat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To add a bird to a list of birds that have been seen (or heard).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Ticking.", synonyms: [], antonyms: [] },
            {
              definition:
                "A sheet that wraps around a mattress; the cover of a mattress, containing the filling.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["ticking"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Credit, trust.",
              synonyms: ["credit", "trust"],
              antonyms: [],
            },
          ],
          synonyms: ["credit", "trust"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To go on trust, or credit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To give tick; to trust.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(place names) A goat.",
              synonyms: [],
              antonyms: [],
              example: "Tickenhall Drive",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tick",
        "https://en.wiktionary.org/wiki/ticks",
      ],
    },
  ],
  riots: [
    {
      word: "riots",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Wanton or unrestrained behavior; uproar; tumult.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The tumultuous disturbance of the public peace by an unlawful assembly of three or more persons in the execution of some private object.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A wide and unconstrained variety.",
              synonyms: [],
              antonyms: [],
              example: "In summer this flower garden is a riot of colour.",
            },
            {
              definition:
                "Excessive and expensive feasting; wild and loose festivity; revelry.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To create or take part in a riot; to raise an uproar or sedition.",
              synonyms: [],
              antonyms: [],
              example:
                "The nuclear protesters rioted outside the military base.",
            },
            {
              definition:
                "To act in an unrestrained or wanton manner; to indulge in excess of feasting, luxury, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause to riot; to throw into a tumult.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To annoy.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/riot",
        "https://en.wiktionary.org/wiki/riots",
      ],
    },
  ],
  roars: [
    {
      word: "roars",
      phonetic: "/ɹɔːz/",
      phonetics: [
        {
          text: "/ɹɔːz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/roars-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80066780",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ɹɔɹz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A long, loud, deep shout, as of rage or laughter, made with the mouth wide open.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The cry of the lion.", synonyms: [], antonyms: [] },
            {
              definition: "The deep cry of the bull.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A loud resounding noise.",
              synonyms: [],
              antonyms: [],
              example: "the roar of a motorbike",
            },
            {
              definition: "A show of strength or character.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make a loud, deep cry, especially from pain, anger, or other strong emotion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To laugh in a particularly loud manner.",
              synonyms: [],
              antonyms: [],
              example: "The audience roared at his jokes.",
            },
            {
              definition:
                "Of animals (especially the lion), to make a loud deep noise.",
              synonyms: [],
              antonyms: [],
              example: "The lioness roared to scare off the hyenas.",
            },
            {
              definition:
                "Generally, of inanimate objects etc., to make a loud resounding noise.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To proceed vigorously.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cry aloud; to proclaim loudly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be boisterous; to be disorderly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make a loud noise in breathing, as horses do when they have a certain disease.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(North Midlands) to cry",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/roar",
        "https://en.wiktionary.org/wiki/roars",
      ],
    },
  ],
  vault: [
    {
      word: "vault",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/vault-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75857710",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/vɒlt/", audio: "" },
        {
          text: "/vɑlt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/vault-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780472",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An arched masonry structure supporting and forming a ceiling, whether freestanding or forming part of a larger building.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any arched ceiling or roof.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Anything resembling such a downward-facing concave structure, particularly the sky and caves.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The space covered by an arched roof, particularly underground rooms and church crypts.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any cellar or underground storeroom.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any burial chamber, particularly those underground.",
              synonyms: [],
              antonyms: [],
              example:
                "Family members had been buried in the vault for centuries.",
            },
            {
              definition:
                "The secure room or rooms in or below a bank used to store currency and other valuables; similar rooms in other settings.",
              synonyms: [],
              antonyms: [],
              example: "The bank kept their money safe in a large vault.",
            },
            {
              definition: "A piece of apparatus used for performing jumps.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A gymnastic movement performed on this apparatus.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An encrypted digital archive.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An underground or covered conduit for water or waste; a drain; a sewer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An underground or covered reservoir for water or waste; a cistern; a cesspit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A room employing a cesspit or sewer: an outhouse; a lavatory.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["vaulting table"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To build as, or cover with a vault.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/vault"],
    },
    {
      word: "vault",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/vault-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75857710",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/vɒlt/", audio: "" },
        {
          text: "/vɑlt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/vault-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780472",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An act of vaulting, formerly by deer; a leap or jump.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An event or performance involving a vaulting horse.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To jump or leap over.",
              synonyms: [],
              antonyms: [],
              example: "The fugitive vaulted over the fence to escape.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A turning; a time (chiefly used in phrases signifying that the part is to be repeated).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A volte", synonyms: [], antonyms: [] },
            {
              definition:
                "A turning point or point of change in a poem, most commonly a sonnet.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/vault",
        "https://en.wiktionary.org/wiki/volta",
        "https://en.wiktionary.org/wiki/volte",
      ],
    },
  ],
  looms: [
    {
      word: "looms",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A utensil; tool; a weapon; (usually in compound) an article in general.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A frame or machine of wood or other material, in which a weaver forms cloth out of thread; a machine for interweaving yarn or threads into a fabric, as in knitting or lace making.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The part of an oar which is between the grip or handle and the blade, the shaft.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Loon (bird of order Gaviiformes)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A distorted appearance of something as seen indistinctly or from afar.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To appear indistinctly, eg. when seen on the horizon or through the murk.",
              synonyms: [],
              antonyms: [],
              example: "The clouds loomed over the mountains.",
            },
            {
              definition:
                "To appear in an exaggerated or threatening form; to be imminent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To rise and to be eminent; to be elevated or ennobled, in a moral sense.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/loom",
        "https://en.wiktionary.org/wiki/looms",
      ],
    },
  ],
  scold: [
    {
      word: "scold",
      phonetic: "/skəʊld/",
      phonetics: [
        { text: "/skəʊld/", audio: "" },
        {
          text: "/skoʊld/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scold-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769512",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To burn with hot liquid.",
              synonyms: [],
              antonyms: [],
              example: "to scald the hand",
            },
            {
              definition: "To heat almost to boiling.",
              synonyms: [],
              antonyms: [],
              example: "Scald the milk until little bubbles form.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person who habitually scolds, in particular a troublesome and angry woman.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To rebuke angrily.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/scald",
        "https://en.wiktionary.org/wiki/scold",
      ],
    },
  ],
  blink: [
    {
      word: "blink",
      phonetic: "/blɪŋk/",
      phonetics: [
        {
          text: "/blɪŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/blink-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=86872709",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The act of very quickly closing both eyes and opening them again.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The time needed to close and reopen one's eyes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A text formatting feature that causes text to disappear and reappear as a form of visual emphasis.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A glimpse or glance.", synonyms: [], antonyms: [] },
            {
              definition: "Gleam; glimmer; sparkle",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The dazzling whiteness about the horizon caused by the reflection of light from fields of ice at sea; iceblink",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in the plural) Boughs cast where deer are to pass, in order to turn or check them.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An ability that allows teleporting, mostly for short distances",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To close and reopen both eyes quickly.",
              synonyms: [],
              antonyms: [],
              example:
                "The loser in the staring game is the person who blinks first.",
            },
            {
              definition: "To flash on and off at regular intervals.",
              synonyms: [],
              antonyms: [],
              example: "The blinking text on the screen was distracting.",
            },
            {
              definition:
                "To perform the smallest action that could solicit a response.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To shut out of sight; to evade; to shirk.",
              synonyms: [],
              antonyms: [],
              example: "to blink the question",
            },
            { definition: "To trick; to deceive.", synonyms: [], antonyms: [] },
            {
              definition:
                "To turn slightly sour, or blinky, as beer, milk, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To teleport, mostly for short distances.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["nictitate"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/blink"],
    },
  ],
  dandy: [
    {
      word: "dandy",
      phonetic: "/ˈdændi/",
      phonetics: [
        {
          text: "/ˈdændi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dandy-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88675802",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A man very concerned about his clothes and his appearance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A yawl, or a small after-sail on a yawl.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A dandy roller.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Like a dandy, foppish.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Very good; better than expected but not as good as could be.",
              synonyms: [],
              antonyms: [],
              example: "That's all fine and dandy, but how much does it cost?",
            },
            {
              definition: "Excellent; first-rate.",
              synonyms: [],
              antonyms: [],
              example: "What a dandy little laptop you have.",
            },
          ],
          synonyms: ["all very well", "well and good"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dandy"],
    },
  ],
  pupae: [
    {
      word: "pupae",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An insect in the development stage between larva and adult.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pupa",
        "https://en.wiktionary.org/wiki/pupae",
      ],
    },
  ],
  sieve: [
    {
      word: "sieve",
      phonetic: "/sɪv/",
      phonetics: [
        {
          text: "/sɪv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sieve-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=402827",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A device with a mesh bottom to separate, in a granular material, larger particles from smaller ones, or to separate solid objects from a liquid.",
              synonyms: [],
              antonyms: [],
              example: "Use the sieve to get the pasta from the water.",
            },
            {
              definition:
                "A process, physical or abstract, that arrives at a final result by filtering out unwanted pieces of input from a larger starting set of input.",
              synonyms: [],
              antonyms: [],
              example:
                "Given a list of consecutive numbers starting at 1, the Sieve of Eratosthenes algorithm will find all of the prime numbers.",
            },
            {
              definition: "A kind of coarse basket.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person, or their mind, that cannot remember things or is unable to keep secrets.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A collection of morphisms in a category whose codomain is a certain fixed object of that category, which collection is closed under pre-composition by any morphism in the category.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To strain, sift or sort using a sieve.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To concede; let in", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sieve"],
    },
  ],
  spike: [
    {
      word: "spike",
      phonetic: "/spaɪk/",
      phonetics: [
        {
          text: "/spaɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spike-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50279082",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "In a violin-family instrument, the carved wooden plug which sits in the bottom block of the instrument.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["button", "endpin"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The spike of a cello or double bass that makes contact with the floor and supports the weight of the instrument.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A sort of very large nail.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of pointed metal etc. set with points upward or outward.",
              synonyms: [],
              antonyms: [],
              example: "The trap was lined with spikes.",
            },
            {
              definition: "Anything resembling such a nail in shape.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An ear of corn or grain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A kind of inflorescence in which sessile flowers are arranged on an unbranched elongated axis.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(chiefly in the plural) A running shoe with spikes in the sole to provide grip.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sharp peak in a graph.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A surge in power or in the price of a commodity etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The long, narrow part of a high-heeled shoe that elevates the heel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A long nail for storing papers by skewering them; (by extension) the metaphorical place where rejected newspaper articles are sent.",
              synonyms: ["spindle"],
              antonyms: [],
            },
            {
              definition:
                "An attack from, usually, above the height of the net performed with the intent to send the ball straight to the floor of the opponent or off the hands of the opposing block.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An adolescent male deer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The casual ward of a workhouse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Spike lavender.",
              synonyms: [],
              antonyms: [],
              example: "oil of spike",
            },
          ],
          synonyms: [
            "catkin",
            "cluster",
            "corymb",
            "raceme",
            "umbel",
            "spindle",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To fasten with spikes, or long, large nails.",
              synonyms: [],
              antonyms: [],
              example: "to spike down planks",
            },
            {
              definition: "To set or furnish with spikes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To embed nails into (a tree) so that any attempt to cut it down will damage equipment or injure people.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To fix on a spike.", synonyms: [], antonyms: [] },
            {
              definition:
                "To discard; to decide not to publish or make public.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To increase sharply.",
              synonyms: [],
              antonyms: [],
              example:
                "Traffic accidents spiked in December when there was ice on the roads.",
            },
            {
              definition:
                "To covertly put alcohol or another intoxicating substance into a drink.",
              synonyms: [],
              antonyms: [],
              example: "She spiked my lemonade with vodka!",
            },
            {
              definition: "To add a small amount of one substance to another.",
              synonyms: [],
              antonyms: [],
              example:
                "The water sample to be tested has been spiked with arsenic, antimony, mercury, and lead in quantities commonly found in industrial effluents.",
            },
            {
              definition:
                "To attack from, usually, above the height of the net with the intent to send the ball straight to the floor of the opponent or off the hands of the opposing block.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To render (a gun) unusable by driving a metal spike into its touch hole.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(football slang) To slam the football to the ground, usually in celebration of scoring a touchdown, or to stop expiring time on the game clock after snapping the ball as to save time for the losing team to attempt to score the tying or winning points.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["attack", "hit"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/endbutton",
        "https://en.wiktionary.org/wiki/endpin",
        "https://en.wiktionary.org/wiki/spike",
      ],
    },
  ],
  ducts: [
    {
      word: "ducts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A pipe, tube or canal which carries gas or liquid from one place to another.",
              synonyms: [],
              antonyms: [],
              example: "heating and air-conditioning ducts",
            },
            {
              definition: "An enclosure or channel for electrical cable runs.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Guidance; direction.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To channel something through a duct (or series of ducts).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/duct",
        "https://en.wiktionary.org/wiki/ducts",
      ],
    },
  ],
  lends: [
    {
      word: "lends",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To allow to be used by someone temporarily, on condition that it or its equivalent will be returned.",
              synonyms: [],
              antonyms: [],
              example:
                "I lent her 10 euros to pay for the train tickets, and she paid me back the next day.",
            },
            { definition: "To make a loan.", synonyms: [], antonyms: [] },
            {
              definition: "To be suitable or applicable, to fit.",
              synonyms: [],
              antonyms: [],
              example: "Poems do not lend themselves to translation easily.",
            },
            {
              definition: "To afford; to grant or furnish in general.",
              synonyms: [],
              antonyms: [],
              example: "Can you lend me some assistance?",
            },
            { definition: "To borrow.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["borrow"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "Loins", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lend",
        "https://en.wiktionary.org/wiki/lends",
      ],
    },
  ],
  pizza: [
    {
      word: "pizza",
      phonetic: "/ˈpiːt.sə/",
      phonetics: [
        { text: "/ˈpiːt.sə/", audio: "" },
        { text: "/ˈpitsə/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A baked Italian dish of a thinly rolled bread dough crust typically topped before baking with tomato sauce, cheese and other ingredients such as meat, vegetables or fruit",
              synonyms: [],
              antonyms: [],
              example: "Want to go out for pizza tonight?",
            },
            {
              definition: "A single instance of this dish",
              synonyms: [],
              antonyms: [],
              example: "He ate a whole pizza!",
            },
          ],
          synonyms: ["pie", "pizza pie"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pizza"],
    },
  ],
  brink: [
    {
      word: "brink",
      phonetic: "/bɹɪŋk/",
      phonetics: [{ text: "/bɹɪŋk/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The edge, margin, or border of a steep place, as of a precipice; a bank or edge.",
              synonyms: [],
              antonyms: [],
              example: "the brink of a river",
            },
            {
              definition: "The edge or border",
              synonyms: [],
              antonyms: [],
              example: "He's on the brink of madness.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/brink"],
    },
  ],
  widen: [
    {
      word: "widen",
      phonetic: "/ˈwaɪdən/",
      phonetics: [{ text: "/ˈwaɪdən/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To become wide or wider.",
              synonyms: [],
              antonyms: [],
              example: "His eyes widened as her negligee fell to the floor.",
            },
            {
              definition: "To make wide or wider.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To let out clothes to a larger size.",
              synonyms: [],
              antonyms: [],
              example: "She widened his trousers for him.",
            },
            {
              definition: "To broaden or extend in scope or range.",
              synonyms: [],
              antonyms: [],
              example: "The police widened their enquiries.",
            },
            {
              definition:
                "To convert to a data type that can hold a larger number of distinct values.",
              synonyms: [],
              antonyms: ["narrow"],
            },
          ],
          synonyms: [],
          antonyms: ["narrow"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/widen"],
    },
  ],
  plumb: [
    {
      word: "plumb",
      phonetic: "/plʌm/",
      phonetics: [
        {
          text: "/plʌm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/plumb-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899619",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/plʌm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/plumb-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1316067",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A little mass of lead, or the like, attached to a line, and used by builders, etc., to indicate a vertical direction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A weight on the end of a long line, used by sailors to determine the depth of water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The perpendicular direction or position.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["plumb bob", "plumb line", "plummet"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To determine the depth, generally of a liquid; to sound.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To attach to a water supply and drain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To think about or explore in depth, to get to the bottom of, especially to plumb the depths of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To use a plumb bob as a measuring or aligning tool.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To accurately align vertically or horizontally.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To seal something with lead.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To work as a plumber.", synonyms: [], antonyms: [] },
            {
              definition: "To fall or sink like a plummet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To trace a road or track; to follow it to its end.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To position vertically above or below.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Truly vertical, as indicated by a plumb line.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Describing an LBW where the batsman is hit on the pads directly in front of his wicket and should be given out.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["perpendicular"],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "In a vertical direction; perpendicularly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Squarely, directly; completely.",
              synonyms: [],
              antonyms: [],
              example: "It hit him plumb in the middle of his face.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/plumb"],
    },
    {
      word: "plumb",
      phonetic: "/plʌm/",
      phonetics: [
        {
          text: "/plʌm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/plumb-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899619",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/plʌm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/plumb-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1316067",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The fruit and its tree.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Extended senses.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["ume", "plum tree", "plumtree"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/plum",
        "https://en.wiktionary.org/wiki/plumb",
      ],
    },
  ],
  pagan: [
    {
      word: "pagan",
      phonetic: "/ˈpeɪɡən/",
      phonetics: [
        {
          text: "/ˈpeɪɡən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pagan-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=49875687",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person not adhering to a main world religion; a follower of a pantheistic or nature-worshipping religion.",
              synonyms: [],
              antonyms: [],
              example: "This community has a surprising number of pagans.",
            },
            {
              definition:
                "(by extension) An uncivilized or unsocialized person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) An unruly, badly educated child.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["brat", "paynim", "philistine", "savage"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Relating to, characteristic of religions that differ from main world religions.",
              synonyms: [],
              antonyms: [],
              example:
                "Many converted societies transformed their pagan deities into saints.",
            },
            {
              definition: "(by extension) Savage, immoral, uncivilized, wild.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["heathen", "barbarian", "barbaric"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pagan"],
    },
  ],
  feats: [
    {
      word: "feats",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/feats-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2650947",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A relatively rare or difficult accomplishment.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To form; to fashion.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To feature. I", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/feat",
        "https://en.wiktionary.org/wiki/feats",
      ],
    },
  ],
  bison: [
    {
      word: "bison",
      phonetic: "/ˈbaɪ̯sən/",
      phonetics: [{ text: "/ˈbaɪ̯sən/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A wild ox, Bison bonasus.",
              synonyms: ["European bison", "wisent"],
              antonyms: [],
            },
            {
              definition: "A similar North American animal, Bison bison.",
              synonyms: ["American bison", "American buffalo", "buffalo"],
              antonyms: [],
            },
          ],
          synonyms: [
            "American bison",
            "American buffalo",
            "buffalo",
            "European bison",
            "wisent",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bison"],
    },
  ],
  soggy: [
    {
      word: "soggy",
      phonetic: "/ˈsɒɡi/",
      phonetics: [
        {
          text: "/ˈsɒɡi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/soggy-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80067038",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Soaked with moisture or other liquid.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["drenched", "saturated", "sodden"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/soggy"],
    },
  ],
  scoop: [
    {
      word: "scoop",
      phonetic: "/skuːp/",
      phonetics: [
        {
          text: "/skuːp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scoop-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=659551",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any cup- or bowl-shaped tool, usually with a handle, used to lift and move loose or soft solid material.",
              synonyms: [],
              antonyms: [],
              example: "She kept a scoop in the dog food.",
            },
            {
              definition:
                "The amount or volume of loose or solid material held by a particular scoop.",
              synonyms: [],
              antonyms: [],
              example: "I'll have one scoop of chocolate ice-cream.",
            },
            {
              definition:
                "The act of scooping, or taking with a scoop or ladle; a motion with a scoop, as in dipping or shovelling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A story or fact; especially, news learned and reported before anyone else.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An opening in a hood/bonnet or other body panel to admit air, usually for cooling the engine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The digging attachment on a front-end loader.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A place hollowed out; a basinlike cavity; a hollow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A spoon-shaped surgical instrument, used in extracting certain substances or foreign bodies.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A special spinal board used by emergency medical service staff that divides laterally to scoop up patients.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sweep; a stroke; a swoop.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The peak of a cap.", synonyms: [], antonyms: [] },
            {
              definition:
                "A hole on the playfield that catches a ball, but eventually returns it to play in one way or another.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["scoopful", "scooper"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To lift, move, or collect with a scoop or as though with a scoop.",
              synonyms: [],
              antonyms: [],
              example:
                "He used both hands to scoop water and splash it on his face.",
            },
            {
              definition: "To make hollow; to dig out.",
              synonyms: [],
              antonyms: [],
              example: "I tried scooping a hole in the sand with my fingers.",
            },
            {
              definition:
                "To report on something, especially something worthy of a news article, before (someone else).",
              synonyms: [],
              antonyms: [],
              example:
                "The paper across town scooped them on the City Hall scandal.",
            },
            {
              definition:
                '(often with "up") To begin a vocal note slightly below the target pitch and then to slide up to the target pitch, especially in country music.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To pick (someone) up",
              synonyms: [],
              antonyms: [],
              example: "You have a car. Can you come and scoop me?",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scoop"],
    },
  ],
  argon: [
    {
      word: "argon",
      phonetic: "/ˈɑːɹɡɒn/",
      phonetics: [
        {
          text: "/ˈɑːɹɡɒn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/argon-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=17136184",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The chemical element (symbol Ar) with an atomic number of 18. The third most abundant gas in the Earth's atmosphere, it is a colourless, odourless, inert noble gas.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A single atom of this element.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["E938", "packaging gas"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/argon"],
    },
  ],
  nudge: [
    {
      word: "nudge",
      phonetic: "/nʌdʒ/",
      phonetics: [{ text: "/nʌdʒ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A gentle push.", synonyms: [], antonyms: [] },
            {
              definition:
                "A feature of instant messaging software used to get the attention of another user, as by shaking the conversation window or playing a sound.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The rotation by one step of a fruit machine reel of the player's choice.",
              synonyms: [],
              antonyms: [],
              example:
                "Since the machine was showing two lemons and a cherry, I decided to try a nudge.",
            },
            {
              definition:
                "The use of positive reinforcement and indirect suggestions as ways to influence.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To push against gently, especially in order to gain attention or give a signal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To near or come close to something.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/nudge"],
    },
  ],
  skiff: [
    {
      word: "skiff",
      phonetic: "/skɪf/",
      phonetics: [
        {
          text: "/skɪf/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/skiff-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79293791",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Term used in tea gardens and denotes the act of cutting/pruning the bushes as per the specific norms. Various types of skiff are used viz Light Skiff (LS), Medium Skiff (MS), Deep Skiff (DS) and Level Of Skiff (LOS).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small flat-bottomed open boat with a pointed bow and square stern.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various types of boats small enough for sailing or rowing by one person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A light wind/rain/snow, etc.",
              synonyms: [],
              antonyms: [],
              example:
                "A skiff of rain blew into the shed and the two men moved their chairs back.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To navigate in a skiff.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cut (a tea bush) to maintain the plucking table",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/skiff"],
    },
    {
      word: "skiff",
      phonetic: "/skɪf/",
      phonetics: [
        {
          text: "/skɪf/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/skiff-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79293791",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(Nova Scotia) a deep blanket of snow covering the ground",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/skiff"],
    },
  ],
  amber: [
    {
      word: "amber",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/amber-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75729409",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈam.bə/", audio: "" },
        {
          text: "/ˈæm.bɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/amber-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762579",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Ambergris, the waxy product of the sperm whale.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hard, generally yellow to brown translucent fossil resin, used for jewellery. One variety, blue amber, appears blue rather than yellow under direct sunlight.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A yellow-orange colour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The intermediate light in a set of three traffic lights, which when illuminated indicates that drivers should stop short of the intersection if it is safe to do so.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'The stop codon (nucleotide triplet) "UAG", or a mutant which has this stop codon at a premature place in its DNA sequence.',
              synonyms: [],
              antonyms: [],
              example: "an amber codon, an amber mutation, an amber suppressor",
            },
          ],
          synonyms: ["yellow", "ambergris"],
          antonyms: ["green", "red"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To perfume or flavour with ambergris.",
              synonyms: [],
              antonyms: [],
              example: "ambered wine, an ambered room",
            },
            {
              definition: "To preserve in amber.",
              synonyms: [],
              antonyms: [],
              example: "an ambered fly",
            },
            {
              definition: "To cause to take on the yellow colour of amber.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To take on the yellow colour of amber.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Of a brownish yellow colour, like that of most amber.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/amber"],
    },
  ],
  sexes: [
    {
      word: "sexes",
      phonetic: "/ˈsɛksɪz/",
      phonetics: [
        {
          text: "/ˈsɛksɪz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sexes-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2454285",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An offshoot of a larger religion; a group sharing particular (often unorthodox) political and/or religious beliefs.",
              synonyms: [],
              antonyms: [],
              example: "A religious sect.",
            },
            {
              definition: "A group following a specific ideal or a leader.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A cutting; a scion.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A category into which sexually-reproducing organisms are divided on the basis of their reproductive roles in their species.",
              synonyms: [],
              antonyms: [],
              example:
                "The effect of the medication is dependent upon age, sex, and other factors.",
            },
            {
              definition:
                "Another category, especially of humans and especially based on sexuality or gender roles.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The members of such a category, taken collectively.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The distinction and relation between these categories, especially in humans; gender.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                '(with "the") Women; the human female sex and those who belong to it.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Sexual activity, usually sexual intercourse unless preceded by a modifier.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Genitalia: a penis or vagina.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To determine the sex of an animal.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To have sex with.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["gender"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sect",
        "https://en.wiktionary.org/wiki/sex",
        "https://en.wiktionary.org/wiki/sexe",
        "https://en.wiktionary.org/wiki/sexes",
      ],
    },
  ],
  rouse: [
    {
      word: "rouse",
      phonetic: "/ˈɹaʊz/",
      phonetics: [
        {
          text: "/ˈɹaʊz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rouse-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79268794",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "An arousal.", synonyms: [], antonyms: [] },
            {
              definition:
                "The sounding of a bugle in the morning after reveille, to signal that soldiers are to rise from bed, often the rouse.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To wake (someone) or be awoken from sleep, or from apathy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause, stir up, excite (a feeling, thought, etc.).",
              synonyms: [],
              antonyms: [],
              example: "to rouse the faculties, passions, or emotions",
            },
            {
              definition: "To provoke (someone) to action or anger.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause to start from a covert or lurking place.",
              synonyms: [],
              antonyms: [],
              example: "to rouse a deer or other animal of the chase",
            },
            {
              definition: "To pull by main strength; to haul.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To raise; to make erect.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: '(when followed by "on") To tell off; to criticise.',
              synonyms: [],
              antonyms: [],
              example: "He roused on her for being late yet again.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rouse"],
    },
    {
      word: "rouse",
      phonetic: "/ˈɹaʊz/",
      phonetics: [
        {
          text: "/ˈɹaʊz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rouse-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79268794",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An official ceremony over drinks.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A carousal; a festival; a drinking frolic.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Wine or other liquor considered an inducement to mirth or drunkenness; a full glass; a bumper.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rouse"],
    },
  ],
  salts: [
    {
      word: "salts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A common substance, chemically consisting mainly of sodium chloride (NaCl), used extensively as a condiment and preservative.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the compounds formed from the reaction of an acid with a base, where a positive ion replaces a hydrogen of the acid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A salt marsh, a saline marsh at the shore of a sea.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sailor (also old salt).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Randomly chosen bytes added to a plaintext message prior to encrypting or hashing it, in order to render brute-force decryption more difficult.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person who seeks employment at a company in order to (once employed by it) help unionize it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Flavour; taste; seasoning.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Piquancy; wit; sense.",
              synonyms: [],
              antonyms: [],
              example: "Attic salt",
            },
            {
              definition: "A dish for salt at table; a salt cellar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Skepticism and common sense.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Indignation; outrage; arguing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To add salt to.",
              synonyms: [],
              antonyms: [],
              example:
                "to salt fish, beef, or pork; to salt the city streets in the winter",
            },
            {
              definition: "To deposit salt as a saline solution.",
              synonyms: [],
              antonyms: [],
              example: "The brine begins to salt.",
            },
            {
              definition:
                "To fill with salt between the timbers and planks, as a ship, for the preservation of the timber.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To insert or inject something into an object to give it properties it would not naturally have.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To include colorful language in.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To add filler bytes before encrypting, in order to make brute-force decryption more resource-intensive.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["desalt"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/salt",
        "https://en.wiktionary.org/wiki/salts",
      ],
    },
  ],
  hitch: [
    {
      word: "hitch",
      phonetic: "/hɪtʃ/",
      phonetics: [
        {
          text: "/hɪtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hitch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=657016",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A sudden pull.", synonyms: [], antonyms: [] },
            {
              definition:
                "Any of various knots used to attach a rope to an object other than another rope.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A fastener or connection point, as for a trailer.",
              synonyms: [],
              antonyms: [],
              example: "His truck sported a heavy-duty hitch for his boat.",
            },
            {
              definition: "A problem, delay or source of difficulty.",
              synonyms: [],
              antonyms: [],
              example: "The banquet went off without a hitch",
            },
            {
              definition:
                "A hidden or unfavorable condition or element; a catch.",
              synonyms: [],
              antonyms: [],
              example: "The deal sounds too good to be true. What's the hitch?",
            },
            {
              definition: "A period of time spent in the military.",
              synonyms: [],
              antonyms: [],
              example: "She served two hitches in Vietnam.",
            },
          ],
          synonyms: ["catch"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To pull with a jerk.",
              synonyms: [],
              antonyms: [],
              example: "She hitched her jeans up and then tightened her belt.",
            },
            {
              definition: "To attach, tie or fasten.",
              synonyms: [],
              antonyms: [],
              example:
                "He hitched the bedroll to his backpack and went camping.",
            },
            {
              definition: "To marry oneself to; especially to get hitched.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Contraction of hitchhike, to thumb a ride.",
              synonyms: [],
              antonyms: [],
              example: "to hitch a ride",
            },
            {
              definition:
                "To become entangled or caught; to be linked or yoked; to unite; to cling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To move interruptedly or with halts, jerks, or steps; said of something obstructed or impeded.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To strike the legs together in going, as horses; to interfere.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["affix", "join", "put together", "splice", "wed"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hitch"],
    },
  ],
  exalt: [
    {
      word: "exalt",
      phonetic: "/ɪɡˈzɔːlt/",
      phonetics: [{ text: "/ɪɡˈzɔːlt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To honor; to hold in high esteem.",
              synonyms: [],
              antonyms: [],
              example: "They exalted their queen.",
            },
            {
              definition: "To raise in rank, status etc., to elevate.",
              synonyms: [],
              antonyms: [],
              example:
                "The man was exalted from a humble carpenter to a minister.",
            },
            {
              definition: "To elate, or fill with the joy of success.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To refine or subtilize.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["upgrade"],
          antonyms: ["abase", "demean"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/exalt"],
    },
  ],
  leash: [
    {
      word: "leash",
      phonetic: "/liːʃ/",
      phonetics: [
        { text: "/liːʃ/", audio: "" },
        {
          text: "/liːʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/leash-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1161600",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A strap, cord or rope with which to restrain an animal, often a dog.",
              synonyms: ["lead"],
              antonyms: [],
            },
            {
              definition: "A brace and a half; a tierce.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A set of three; three creatures of any kind, especially greyhounds, foxes, bucks, and hares; hence, the number three in general.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A string with a loop at the end for lifting warp threads, in a loom.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A leg rope.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["lead"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To fasten or secure with a leash.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To curb, restrain", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: ["unleash"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/leash"],
    },
  ],
  dined: [
    {
      word: "dined",
      phonetic: "/daɪnd/",
      phonetics: [
        {
          text: "/daɪnd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dined-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453964",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To eat; to eat dinner or supper.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To give a dinner to; to furnish with the chief meal; to feed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To dine upon; to have to eat.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dine",
        "https://en.wiktionary.org/wiki/dined",
      ],
    },
  ],
  chute: [
    {
      word: "chute",
      phonetic: "/ʃ(j)uːt/",
      phonetics: [{ text: "/ʃ(j)uːt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A framework, trough or tube, upon or through which objects are made to slide from a higher to a lower level, or through which water passes to a wheel.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A waterfall or rapid.", synonyms: [], antonyms: [] },
            {
              definition:
                "The pen in which an animal is confined before being released in a rodeo.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chute"],
    },
    {
      word: "chute",
      phonetic: "/ʃ(j)uːt/",
      phonetics: [{ text: "/ʃ(j)uːt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A parachute.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To parachute.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chute"],
    },
  ],
  snort: [
    {
      word: "snort",
      phonetic: "/snɔɹt/",
      phonetics: [
        {
          text: "/snɔɹt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/snort-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1317479",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The sound made by exhaling or inhaling roughly through the nose.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'A dose of a drug to be snorted. Here, "drug" includes snuff (i.e., pulverized tobacco).',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A consumed portion of alcoholic drink.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A submarine snorkel.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make a snort; to exhale roughly through the nose.",
              synonyms: [],
              antonyms: [],
              example: "She snorted with laughter.",
            },
            {
              definition: "To express or force out by snorting.",
              synonyms: [],
              antonyms: [],
              example: "He snorted a derisory reply and turned on his heel.",
            },
            {
              definition: "To inhale (usually a drug) through the nose.",
              synonyms: [],
              antonyms: [],
              example: "to snort cocaine",
            },
            { definition: "To snore.", synonyms: [], antonyms: [] },
            {
              definition:
                "(of submarines) To sail at periscope depth through the use of a snort or snorkel.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["insufflate"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/snort"],
    },
  ],
  gusts: [
    {
      word: "gusts",
      phonetic: "/ɡʌsts/",
      phonetics: [{ text: "/ɡʌsts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A strong, abrupt rush of wind.",
              synonyms: ["windflaw"],
              antonyms: [],
            },
            {
              definition:
                "(by extension) Any rush or outburst (of water, emotion, etc.).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["windflaw"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To blow in gusts.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To taste.", synonyms: [], antonyms: [] },
            { definition: "To have a relish for.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gust",
        "https://en.wiktionary.org/wiki/gusts",
      ],
    },
  ],
  melon: [
    {
      word: "melon",
      phonetic: "/ˈmɛlən/",
      phonetics: [
        {
          text: "/ˈmɛlən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/melon-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75622594",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈmɛlən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/melon-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769997",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of various plants of the family Cucurbitaceae grown for food, generally not including the cucumber.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The fruit of such plants.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A light pinkish orange colour, like that of some melon flesh.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(usually in the plural) Breasts.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The head.", synonyms: [], antonyms: [] },
            {
              definition:
                "A member of the Green Party, or similar environmental group.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A mass of adipose tissue found in the forehead of all toothed whales, used to focus and modulate vocalizations.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Of a light pinkish orange colour, like that of melon flesh.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/melon"],
    },
    {
      word: "melon",
      phonetic: "/ˈmɛlən/",
      phonetics: [
        {
          text: "/ˈmɛlən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/melon-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75622594",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈmɛlən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/melon-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769997",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The result of heptazine being polymerized with the tri-s-triazine units linked through an amine (NH) link.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/melon"],
    },
  ],
  cheat: [
    {
      word: "cheat",
      phonetic: "/tʃiːt/",
      phonetics: [
        {
          text: "/tʃiːt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cheat-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769628",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To violate rules in order to gain advantage from a situation.",
              synonyms: [],
              antonyms: [],
              example:
                "My brother flunked biology because he cheated on his mid-term.",
            },
            {
              definition: "To be unfaithful to one's spouse or partner.",
              synonyms: [],
              antonyms: [],
              example: "After he found out his wife cheated, he left her.",
            },
            {
              definition:
                "To manage to avoid something even though it seemed unlikely.",
              synonyms: [],
              antonyms: [],
              example:
                "He cheated death when his car collided with a moving train.",
            },
            {
              definition: "To deceive; to fool; to trick.",
              synonyms: [],
              antonyms: [],
              example: "He cheated his way into office.",
            },
          ],
          synonyms: ["belirt", "blench", "break the rules", "lirt"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cheat"],
    },
    {
      word: "cheat",
      phonetic: "/tʃiːt/",
      phonetics: [
        {
          text: "/tʃiːt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cheat-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769628",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Someone who cheats (informal: cheater).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An act of deception or fraud; that which is the means of fraud or deception; a fraud; a trick; imposition; imposture.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The weed cheatgrass.", synonyms: [], antonyms: [] },
            {
              definition:
                "A card game where the goal is to have no cards remaining in a hand, often by telling lies.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hidden means of gaining an unfair advantage in a computer game, often by entering a cheat code.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["BS", "I doubt it", "bullshit"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cheat"],
    },
  ],
  reefs: [
    {
      word: "reefs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A chain or range of rocks, sand, or coral lying at or near the surface of the water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A large vein of auriferous quartz; hence, any body of rock yielding valuable ore.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A portion of a sail rolled and tied down to lessen the area exposed in a high wind.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A reef knot.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To take in part of a sail in order to adapt the size of the sail to the force of the wind.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To pull or yank strongly, especially in relation to horse riding.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of paddles) To move the floats of a paddle wheel toward its center so that they will not dip so deeply.",
              synonyms: [],
              antonyms: [],
              example: "Reef the paddles.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The itch; any eruptive skin disorder.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Dandruff.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/reef",
        "https://en.wiktionary.org/wiki/reefs",
      ],
    },
  ],
  llama: [
    {
      word: "llama",
      phonetic: "/ˈlɑː.mə/",
      phonetics: [
        {
          text: "/ˈlɑː.mə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/llama-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89034010",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈjɑmə/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A South American mammal of the camel family, Lama glama, used as a domestic beast of burden and a source of wool and meat.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/llama"],
    },
  ],
  lasso: [
    {
      word: "lasso",
      phonetic: "/læsˈuː/",
      phonetics: [
        { text: "/læsˈuː/", audio: "" },
        { text: "/læsˈuː/", audio: "" },
        {
          text: "/ˈlæs.oʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lasso-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1316126",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A long rope with a sliding loop on one end, generally used in ranching to catch cattle and horses.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An image-editing function allowing the user to capture an irregularly-shaped object by drawing an approximate outline.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To catch with a lasso.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lasso"],
    },
  ],
  debut: [
    {
      word: "debut",
      phonetic: "/dəˈbʉː/",
      phonetics: [
        { text: "/dəˈbʉː/", audio: "" },
        { text: "/ˈdeɪbjuː/", audio: "" },
        {
          text: "/deɪˈbjuː/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/debut-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=892408",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A performer's first performance to the public, in sport, the arts or some other area.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The first public presentation of a theatrical play, motion picture, opera, musical composition, dance, or other performing arts piece.",
              synonyms: [],
              antonyms: [],
              example:
                "Since making its debut two years ago, the program has gained cult status.",
            },
            {
              definition: "The first appearance of a debutante in society.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To formally introduce, as to the public",
              synonyms: [],
              antonyms: [],
              example:
                "Amalgamated Software Systems debuted release 3.2 in Spring of 2004.",
            },
            {
              definition: "To make one's initial formal appearance",
              synonyms: [],
              antonyms: [],
              example:
                "Release 3.2 debuted to mixed reviews in Spring of 2004.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/debut"],
    },
  ],
  quota: [
    {
      word: "quota",
      phonetic: "/ˈkwoʊtə/",
      phonetics: [{ text: "/ˈkwoʊtə/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A proportional part or share; the share or proportion assigned to each in a division.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A prescribed number or percentage that may serve as, for example, a maximum, a minimum, or a goal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A restriction on the import of something to a specific quantity.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["allocation", "allotment", "apportionment", "quotum"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/quota"],
    },
  ],
  oaths: [
    {
      word: "oaths",
      phonetic: "/əʊðz/",
      phonetics: [
        { text: "/əʊðz/", audio: "" },
        { text: "/oʊðz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A solemn pledge or promise, appealing to a deity, a ruler, or another entity (not necessarily present) to attest to the truth of a statement or sincerity of one's desire to fulfill a contract or promise.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A statement or promise which is strengthened (affirmed) by such a pledge.",
              synonyms: [],
              antonyms: [],
              example:
                "After taking the oath of office, she became the country's forty-third premier.",
            },
            {
              definition:
                "A light, irreverent or insulting appeal to a deity or other entity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A curse, a curse word.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["avowal", "pledge", "vow"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To pledge.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/oath",
        "https://en.wiktionary.org/wiki/oaths",
      ],
    },
  ],
  prone: [
    {
      word: "prone",
      phonetic: "/pɹəʊn/",
      phonetics: [
        { text: "/pɹəʊn/", audio: "" },
        { text: "/pɹoʊn/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Lying face downward.",
              synonyms: ["prostrate"],
              antonyms: ["supine"],
              example: "prone position",
            },
            {
              definition: "Having a downward inclination or slope.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Predisposed, liable, inclined.",
              synonyms: [],
              antonyms: [],
              example: "prone to failure",
            },
          ],
          synonyms: ["neveling", "nuel", "prostrate"],
          antonyms: ["supine"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/prone"],
    },
  ],
  mixes: [
    {
      word: "mixes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To stir together.",
              synonyms: [],
              antonyms: [],
              example:
                "Mix the eggs and milk with the flour until the consistency is smooth.",
            },
            {
              definition:
                "To combine (items from two or more sources normally kept separate).",
              synonyms: [],
              antonyms: [],
              example: "Don't mix the meat recipes with the dairy recipes.",
            },
            {
              definition:
                "To form by mingling; to produce by the stirring together of ingredients; to concoct from different parts.",
              synonyms: [],
              antonyms: [],
              example: "Yellow and blue paint mix to make green.",
            },
            {
              definition: "To blend by the use of a mixer (machine).",
              synonyms: [],
              antonyms: [],
              example: "Mix the egg whites until they are stiff.",
            },
            {
              definition: "To combine (several tracks).",
              synonyms: [],
              antonyms: [],
              example: "I'll mix the rhythm tracks down to a single track.",
            },
            {
              definition: "To produce a finished version of (a recording).",
              synonyms: [],
              antonyms: [],
              example: "I'm almost done mixing this song.",
            },
            {
              definition: "To unite with in company; to join; to associate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "mix together",
            "mix up",
            "muddle",
            "muddle up",
            "blend",
            "combine",
            "intermix",
            "mingle",
            "mix together",
            "mix up",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The result of mixing two or more substances; a mixture.",
              synonyms: [],
              antonyms: [],
              example: "Now add the raisins to the mix.",
            },
            {
              definition:
                "The result of combining items normally kept separate.",
              synonyms: [],
              antonyms: [],
              example: "My recipe file was now a mix of meat and dairy.",
            },
            {
              definition: "The result of mixing several tracks.",
              synonyms: [],
              antonyms: [],
              example: "The rhythm mix sounds muddy.",
            },
            {
              definition: "The finished version of a recording.",
              synonyms: [],
              antonyms: [],
              example: "I've almost finished the mix for this song.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mix",
        "https://en.wiktionary.org/wiki/mixes",
      ],
    },
  ],
  rafts: [
    {
      word: "rafts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A flat-bottomed craft able to float and drift on water, used for transport or as a waterborne platform.",
              synonyms: [],
              antonyms: [],
              example: "An inflatable raft. A log raft.",
            },
            {
              definition:
                "(by extension) Any flattish thing, usually wooden, used in a similar fashion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A thick crowd of seabirds or sea mammals, particularly a group of penguins when in the water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A collection of logs, fallen trees, etc. which obstructs navigation in a river.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(when ordering food) A slice of toast.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A square array of sensors forming part of a large telescope.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To convey on a raft.", synonyms: [], antonyms: [] },
            { definition: "To make into a raft.", synonyms: [], antonyms: [] },
            { definition: "To travel by raft.", synonyms: [], antonyms: [] },
            {
              definition:
                "To dock (toolbars, etc.) so that they share horizontal or vertical space.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A large (but unspecified) number, a lot.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/raft",
        "https://en.wiktionary.org/wiki/rafts",
      ],
    },
  ],
  dives: [
    {
      word: "dives",
      phonetic: "/ˈdaɪvz/",
      phonetics: [{ text: "/ˈdaɪvz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A supernatural entity of disagreeable nature.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A jump or plunge into water.",
              synonyms: [],
              antonyms: [],
              example: "the dive of a hawk after prey",
            },
            {
              definition:
                "A headfirst jump toward the ground or into another substance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A downward swooping motion.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A swim under water.", synonyms: [], antonyms: [] },
            { definition: "A decline.", synonyms: [], antonyms: [] },
            {
              definition: "A seedy bar, nightclub, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Aerial descent with the nose pointed down.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A deliberate fall after a challenge.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To swim under water.", synonyms: [], antonyms: [] },
            {
              definition: "To jump into water head-first.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To jump headfirst toward the ground or into another substance.",
              synonyms: [],
              antonyms: [],
              example: "to dive into home plate",
            },
            {
              definition: "To descend sharply or steeply.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(especially with in) To undertake with enthusiasm.",
              synonyms: [],
              antonyms: [],
              example: "She dove right in and started making improvements.",
            },
            {
              definition:
                "To deliberately fall down after a challenge, imitating being fouled, in the hope of getting one's opponent penalised.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause to descend, dunk; to plunge something into water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To explore by diving; to plunge into.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To plunge or to go deeply into any subject, question, business, etc.; to penetrate; to explore.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/daeva",
        "https://en.wiktionary.org/wiki/dive",
        "https://en.wiktionary.org/wiki/dives",
      ],
    },
  ],
  stale: [
    {
      word: "stale",
      phonetic: "/steɪl/",
      phonetics: [
        {
          text: "/steɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stale-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90914540",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Something stale; a loaf of bread or the like that is no longer fresh.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(of alcohol) To make stale; to age in order to clear and strengthen (a drink, especially beer).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make stale; to cause to go out of fashion or currency; to diminish the novelty or interest of, particularly by excessive exposure or consumption.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To become stale; to grow odious from excessive exposure or consumption.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(alcohol) To become stale; to grow unpleasant from age.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(alcohol) Clear, free of dregs and lees; old and strong.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "No longer fresh, in reference to food, urine, straw, wounds, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "No longer fresh, new, or interesting, in reference to ideas and immaterial things; cliche, hackneyed, dated.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "No longer nubile or suitable for marriage, in reference to people; past one's prime.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Fallow, in reference to land.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Unreasonably long in coming, in reference to claims and actions.",
              synonyms: [],
              antonyms: [],
              example: "a stale affidavit",
            },
            {
              definition: "Taking a long time to change",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Worn out, particularly due to age or over-exertion, in reference to athletes and animals in competition.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Out of date, unpaid for an unreasonable amount of time, particularly in reference to checks.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of data: out of date; not synchronized with the newest copy.",
              synonyms: [],
              antonyms: [],
              example:
                "The bug was found to be caused by stale data in the cache.",
            },
          ],
          synonyms: [],
          antonyms: ["fresh"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stale"],
    },
    {
      word: "stale",
      phonetic: "/steɪl/",
      phonetics: [
        {
          text: "/steɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stale-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90914540",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A long, thin handle (of rakes, axes, etc.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The posts and rungs composing a ladder.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The stem of a plant.", synonyms: [], antonyms: [] },
            {
              definition: "The shaft of an arrow, spear, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["haft", "handle", "helve", "shaft", "snath", "stem"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                'To make a ladder by joining rungs ("stales") between the posts.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stale"],
    },
    {
      word: "stale",
      phonetic: "/steɪl/",
      phonetics: [
        {
          text: "/steɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stale-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90914540",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A fixed position, particularly a soldier's in a battle-line.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A stalemate; a stalemated game.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An ambush.", synonyms: [], antonyms: [] },
            {
              definition: "A band of armed men or hunters.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The main force of an army.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To stalemate.", synonyms: [], antonyms: [] },
            { definition: "To be stalemated.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "At a standstill; stalemated.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stale"],
    },
    {
      word: "stale",
      phonetic: "/steɪl/",
      phonetics: [
        {
          text: "/steɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stale-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90914540",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(livestock) Urine, especially used of horses and cattle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(livestock) To urinate, especially used of horses and cattle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stale"],
    },
    {
      word: "stale",
      phonetic: "/steɪl/",
      phonetics: [
        {
          text: "/steɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stale-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90914540",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A live bird to lure birds of prey or others of its kind into a trap.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any lure, particularly in reference to people used as live bait.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An accomplice of a thief or criminal acting as bait.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A partner whose beloved abandons or torments him in favor of another.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A patsy, a pawn, someone used under some false pretext to forward another's (usu. sinister) designs; a stalking horse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A prostitute of the lowest sort; any wanton woman.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any decoy, either stuffed or manufactured.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To serve as a decoy, to lure.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stale"],
    },
  ],
  inlet: [
    {
      word: "inlet",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To let in; admit.", synonyms: [], antonyms: [] },
            { definition: "To insert; inlay.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/inlet"],
    },
    {
      word: "inlet",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/inlet-2-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1785167",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A body of water let into a coast, such as a bay, cove, fjord or estuary.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A passage that leads into a cavity.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/inlet"],
    },
  ],
  flick: [
    {
      word: "flick",
      phonetic: "/flɪk/",
      phonetics: [
        {
          text: "/flɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/flick-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=825521",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A short, quick movement, especially a brush, sweep, or flip.",
              synonyms: [],
              antonyms: [],
              example:
                "He removed the speck of dust with a flick of his finger.",
            },
            {
              definition:
                'A motion picture; (in plural, usually preceded by "the") movie theater, cinema.',
              synonyms: [],
              antonyms: [],
              example: 'My all-time favorite flick is "Gone with the Wind."',
            },
            {
              definition:
                "A cut that lands with the point, often involving a whip of the foible of the blade to strike at a concealed target.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A powerful underarm volley shot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The act of pressing a place on a touch screen device.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A flitch.",
              synonyms: [],
              antonyms: [],
              example: "a flick of bacon",
            },
            {
              definition: "A unit of time, equal to 1/705,600,000 of a second",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["fillip", "pictures"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To move or hit (something) with a short, quick motion.",
              synonyms: [],
              antonyms: [],
              example: "flick one's hair",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/flick"],
    },
  ],
  pinto: [
    {
      word: "pinto",
      phonetic: "/ˈpɪntəʊ/",
      phonetics: [{ text: "/ˈpɪntəʊ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A horse with a patchy coloration that includes white.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Pied, mottled.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pinto"],
    },
  ],
  brows: [
    {
      word: "brows",
      phonetic: "/bɹaʊz/",
      phonetics: [{ text: "/bɹaʊz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The ridge over the eyes; the eyebrow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The first tine of an antler's beam.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The forehead.", synonyms: [], antonyms: [] },
            {
              definition:
                "The projecting upper edge of a steep place such as a hill.",
              synonyms: [],
              antonyms: [],
              example: "the brow of a precipice",
            },
            {
              definition:
                "A gallery in a coal mine running across the face of the coal.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Aspect; appearance.", synonyms: [], antonyms: [] },
            {
              definition:
                "The gangway from ship to shore when a ship is lying alongside a quay.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The hinged part of a landing craft or ferry which is lowered to form a landing platform; a ramp.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["forehead"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/brow",
        "https://en.wiktionary.org/wiki/brows",
      ],
    },
  ],
  untie: [
    {
      word: "untie",
      phonetic: "/ʌnˈtaɪ/",
      phonetics: [{ text: "/ʌnˈtaɪ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To loosen, as something interlaced or knotted; to disengage the parts of.",
              synonyms: [],
              antonyms: [],
              example: "to untie a knot",
            },
            {
              definition:
                "To free from fastening or from restraint; to let loose; to unbind.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To resolve; to unfold; to clear.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To become untied or loosed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In the Perl programming language, to undo the process of tying, so that a variable uses default instead of custom functionality.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["tie"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/untie"],
    },
  ],
  batch: [
    {
      word: "batch",
      phonetic: "/bæt͡ʃ/",
      phonetics: [
        {
          text: "/bæt͡ʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/batch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50048966",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The quantity of bread or other baked goods baked at one time.",
              synonyms: ["recipe"],
              antonyms: [],
              example: "We made a batch of cookies to take to the party.",
            },
            {
              definition:
                "(by extension) A quantity of anything produced at one operation.",
              synonyms: ["lot", "pressing", "run"],
              antonyms: [],
              example:
                "We poured a bucket of water in at the top, and the ice-maker dispensed a batch of ice-cubes at the bottom.",
            },
            {
              definition:
                "A group or collection of things of the same kind, such as a batch of letters or the next batch of business.",
              synonyms: ["group", "lot"],
              antonyms: [],
            },
            {
              definition:
                "A set of data to be processed with one execution of a program.",
              synonyms: [],
              antonyms: [],
              example:
                "The system throttled itself to batches of 50 requests at a time to keep the thread count under control.",
            },
            { definition: "A bread roll.", synonyms: [], antonyms: [] },
            {
              definition: "A graduating class.",
              synonyms: [],
              antonyms: [],
              example: "She was the valedictorian of Batch '73.",
            },
            {
              definition: "The process of baking.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["group", "lot", "lot", "pressing", "run", "recipe"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To aggregate things together into a batch.",
              synonyms: [],
              antonyms: [],
              example:
                "The contractor batched the purchase orders for the entire month into one statement.",
            },
            {
              definition:
                "To handle a set of input data or requests as a batch process.",
              synonyms: [],
              antonyms: [],
              example:
                "The purchase requests for the day were stored in a queue and batched for printing the next morning.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Of a process, operating for a defined set of conditions, and then halting.",
              synonyms: [],
              antonyms: [],
              example:
                "The plant had two batch assembly lines for packaging, as well as a continuous feed production line.",
            },
          ],
          synonyms: [],
          antonyms: ["continuous"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/batch"],
    },
    {
      word: "batch",
      phonetic: "/bæt͡ʃ/",
      phonetics: [
        {
          text: "/bæt͡ʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/batch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50048966",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A bank; a sandbank.", synonyms: [], antonyms: [] },
            {
              definition:
                "A field or patch of ground lying near a stream; the dale in which a stream flows.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/batch"],
    },
    {
      word: "batch",
      phonetic: "/bæt͡ʃ/",
      phonetics: [
        {
          text: "/bæt͡ʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/batch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50048966",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To live as a bachelor temporarily, of a married man or someone virtually married.",
              synonyms: [],
              antonyms: [],
              example:
                "I am batching next week when my wife visits her sister.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/batch"],
    },
  ],
  greed: [
    {
      word: "greed",
      phonetic: "/ɡɹid/",
      phonetics: [
        {
          text: "/ɡɹid/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/greed-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=16555036",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A selfish or excessive desire for more than is needed or deserved, especially of money, wealth, food, or other possessions.",
              synonyms: [
                "avarice",
                "covetousness",
                "gluttony",
                "greediness",
                "rapacity",
              ],
              antonyms: [],
              example: "His greed was his undoing.",
            },
          ],
          synonyms: [
            "avarice",
            "covetousness",
            "gluttony",
            "greediness",
            "rapacity",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To desire in a greedy manner, or to act on such a desire.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/greed"],
    },
  ],
  chore: [
    {
      word: "chore",
      phonetic: "/tʃɔː/",
      phonetics: [
        {
          text: "/tʃɔː/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chore-1-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88675791",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/tʃɔɹ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A task, especially a difficult, unpleasant, or routine one.",
              synonyms: [],
              antonyms: [],
              example:
                "Washing dishes is a chore, but we cannot just stop eating.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To do chores.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chore"],
    },
    {
      word: "chore",
      phonetic: "/tʃɔː/",
      phonetics: [{ text: "/tʃɔː/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To steal.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["steal", "thieve", "twoc"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chore"],
    },
    {
      word: "chore",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A choir or chorus.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chore"],
    },
  ],
  stirs: [
    {
      word: "stirs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The act or result of stirring (moving around the particles of a liquid etc.)",
              synonyms: [],
              antonyms: [],
              example: "Can you give the soup a little stir?",
            },
            {
              definition:
                "Agitation; tumult; bustle; noise or various movements.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Public disturbance or commotion; tumultuous disorder; seditious uproar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Agitation of thoughts; conflicting passions.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To incite to action",
              synonyms: ["arouse", "excite", "instigate", "prompt"],
              antonyms: [],
            },
            {
              definition:
                "To disturb the relative position of the particles of, as of a liquid, by passing something through it; to agitate.",
              synonyms: [],
              antonyms: [],
              example: "She stirred the pudding with a spoon.",
            },
            {
              definition:
                "To agitate the content of (a container), by passing something through it.",
              synonyms: [],
              antonyms: [],
              example:
                "Would you please stand here and stir this pot so that the chocolate doesn't burn?",
            },
            {
              definition: "To bring into debate; to agitate; to moot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To change the place of in any manner; to move.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To move; to change one’s position.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be in motion; to be active or bustling; to exert or busy oneself.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To become the object of notice; to be on foot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To rise, or be up and about, in the morning.",
              synonyms: ["arise", "get up", "rouse"],
              antonyms: [],
            },
          ],
          synonyms: [
            "arise",
            "get up",
            "rouse",
            "arouse",
            "excite",
            "instigate",
            "prompt",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Jail; prison.",
              synonyms: [],
              antonyms: [],
              example: "He's going to be spending maybe ten years in stir.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/stir",
        "https://en.wiktionary.org/wiki/stirs",
      ],
    },
  ],
  blush: [
    {
      word: "blush",
      phonetic: "/blʌʃ/",
      phonetics: [
        {
          text: "/blʌʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/blush-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1160312",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An act of blushing; a red glow on the face caused by shame, modesty, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A glow; a flush of colour, especially pink or red.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Feeling or appearance of optimism.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sort of makeup, frequently a powder, used to redden the cheeks.",
              synonyms: ["blusher", "rouge"],
              antonyms: [],
            },
            {
              definition: "A color between pink and cream.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A pale pink wine made by removing the dark grape skins at the required point during fermentation.",
              synonyms: ["blush wine", "rosé"],
              antonyms: [],
            },
          ],
          synonyms: ["blush wine", "rosé", "blusher", "rouge"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To become red in the face (and sometimes experience an associated feeling of warmth), especially due to shyness, shame, excitement, or embarrassment.",
              synonyms: ["go red"],
              antonyms: [],
              example:
                "He wasn't used to this much attention, so he blushed as he saw dozens of pairs of eyes watching him.",
            },
            {
              definition: "To be ashamed or embarrassed (to do something).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To become red.", synonyms: [], antonyms: [] },
            {
              definition: "To suffuse with a blush; to redden; to make rosy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To change skin color in the face (to a particular shade).",
              synonyms: [],
              antonyms: [],
              example:
                "I wasn't surprised, but it was embarrassing enough that I blushed a little pink.",
            },
            {
              definition: "To express or make known by blushing.",
              synonyms: [],
              antonyms: [],
              example:
                "Looking at me with a knowing glare, she blushed her discomfort with the situation.",
            },
            {
              definition:
                "To have a warm and delicate colour, like some roses and other flowers.",
              synonyms: [],
              antonyms: [],
              example:
                "The garden was full of blossoms that blushed in myriad shades to form a beautiful carpet of color.",
            },
            {
              definition: "To glance with the eye, cast a glance.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["flush", "go red", "pinken", "redden"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/blush"],
    },
    {
      word: "blush",
      phonetic: "/blʌʃ/",
      phonetics: [
        {
          text: "/blʌʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/blush-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1160312",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The collective noun for a group of boys.",
              synonyms: [],
              antonyms: [],
              example: "A blush of boys.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/blush"],
    },
  ],
  onset: [
    {
      word: "onset",
      phonetic: "/ˈɒnˌsɛt/",
      phonetics: [
        {
          text: "/ˈɒnˌsɛt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/onset-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=83861424",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈɔnˌsɛt/", audio: "" },
        { text: "/ˈɑnˌsɛt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An attack; an assault especially of an army.",
              synonyms: ["storming"],
              antonyms: [],
            },
            {
              definition:
                "The initial phase of a disease or condition, in which symptoms first become apparent.",
              synonyms: [],
              antonyms: [],
              example: "the onset of schizophrenia",
            },
            {
              definition:
                "The initial portion of a syllable, preceding the syllable nucleus.",
              synonyms: [],
              antonyms: ["coda"],
            },
            {
              definition:
                "(acoustics) The beginning of a musical note or other sound, in which the amplitude rises from zero to an initial peak.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A setting about; a beginning.",
              synonyms: ["beginning", "start"],
              antonyms: [],
              example: "the onset of puberty",
            },
            {
              definition:
                "Anything added, such as an ornament or as a useful appendage.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["beginning", "start", "storming"],
          antonyms: ["coda"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To assault; to set upon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To set about; to begin.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/onset"],
    },
  ],
  barbs: [
    {
      word: "barbs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The point that stands backward in an arrow, fishhook, etc., to prevent it from being easily extracted. Hence: Anything which stands out with a sharp point obliquely or crosswise to something else.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A hurtful or disparaging remark.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A beard, or that which resembles it, or grows in the place of it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Armor for a horse, corrupted from bard.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A horse.", synonyms: [], antonyms: [] },
            {
              definition:
                "One of the side branches of a feather, which collectively constitute the vane.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various species of freshwater carp-like fish that have barbels and belong to the cyprinid family.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Menticirrhus americanus (Carolina whiting, king whiting, southern kingcroaker, and southern kingfish), found along the Atlantic and Gulf Coasts of the United States.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A hair or bristle ending in a double hook.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A blackish or dun variety of the pigeon, originally brought from Barbary.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A muffler, worn by nuns and mourners.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Paps, or little projections, of the mucous membrane, which mark the opening of the submaxillary glands under the tongue in horses and cattle. The name is mostly applied when the barbs are inflamed and swollen. [Written also barbel and barble.]",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A bit for a horse.", synonyms: [], antonyms: [] },
            {
              definition:
                "A plastic fastener, shaped roughly like a capital I (with serifs), used to attach socks etc. to their packaging.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To furnish with barbs, or with that which will hold or hurt like barbs, as an arrow, fishhook, spear, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cover a horse in armor, corrupted from bard.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To cut (hair).", synonyms: [], antonyms: [] },
            {
              definition: "To shave or dress the beard of.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To clip; to mow.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The Barbary horse, a superior breed introduced from Barbary into Spain by the Moors.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A blackish or dun variety of pigeon, originally brought from Barbary.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A barbiturate.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/barb",
        "https://en.wiktionary.org/wiki/barbs",
      ],
    },
  ],
  volts: [
    {
      word: "volts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "In the International System of Units, the derived unit of electrical potential and electromotive force (voltage); the potential difference across a conductor when a current of one ampere uses one watt of power. Symbol: V",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A circular tread; a gait by which a horse going sideways round a centre makes two concentric tracks.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sudden movement to avoid a thrust.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/volt",
        "https://en.wiktionary.org/wiki/volts",
      ],
    },
  ],
  beige: [
    {
      word: "beige",
      phonetic: "/ˈbeɪdʒ/",
      phonetics: [
        {
          text: "/ˈbeɪdʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/beige-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1755114",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A slightly yellowish gray colour, as that of unbleached wool.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Debeige; a kind of woollen or mixed dress goods.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Having a slightly yellowish gray colour, as that of unbleached wool.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Comfortably dull and unadventurous, in a way that suggests middle-class suburbia.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/beige"],
    },
  ],
  swoop: [
    {
      word: "swoop",
      phonetic: "/ˈswuːp/",
      phonetics: [
        {
          text: "/ˈswuːp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/swoop-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1785294",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An instance, or the act of suddenly plunging downward.",
              synonyms: [],
              antonyms: [],
              example:
                "The quality of decision is like the well-timed swoop of a falcon which enables it to strike and destroy its victim. – Sun Tzu",
            },
            {
              definition: "A sudden act of seizing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A quick passage from one note to the next.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To fly or glide downwards suddenly; to plunge (in the air) or nosedive.",
              synonyms: [],
              antonyms: [],
              example:
                "The lone eagle swooped down into the lake, snatching its prey, a small fish.",
            },
            {
              definition:
                "To move swiftly, as if with a sweeping movement, especially to attack something.",
              synonyms: [],
              antonyms: [],
              example: "The dog had enthusiastically swooped down on the bone.",
            },
            {
              definition:
                "To fall on at once and seize; to catch while on the wing.",
              synonyms: [],
              antonyms: [],
              example: "A hawk swoops a chicken.",
            },
            {
              definition: "To seize; to catch up; to take with a sweep.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To pass with pomp; to sweep.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/swoop"],
    },
  ],
  paddy: [
    {
      word: "paddy",
      phonetic: "/ˈpædi/",
      phonetics: [
        {
          text: "/ˈpædi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/paddy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75833608",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈpædi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/paddy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1328992",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Rough or unhusked rice, either before it is milled or as a crop to be harvested.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A paddy field, a rice paddy; an irrigated or flooded field where rice is grown.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/paddy"],
    },
    {
      word: "paddy",
      phonetic: "/ˈpædi/",
      phonetics: [
        {
          text: "/ˈpædi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/paddy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75833608",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈpædi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/paddy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1328992",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Low; mean; boorish; vagabond.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/paddy"],
    },
    {
      word: "paddy",
      phonetic: "/ˈpædi/",
      phonetics: [
        {
          text: "/ˈpædi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/paddy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75833608",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈpædi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/paddy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1328992",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A fit of temper; a tantrum",
              synonyms: [],
              antonyms: [],
              example: "throw a paddy etc.",
            },
            { definition: "A white person.", synonyms: [], antonyms: [] },
            {
              definition: "A labourer's assistant or workmate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A drill used in boring wells, with cutters that expand on pressure.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/paddy"],
    },
  ],
  laced: [
    {
      word: "laced",
      phonetic: "/leɪst/",
      phonetics: [{ text: "/leɪst/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To fasten (something) with laces.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To add alcohol, poison, a drug or anything else potentially harmful to (food or drink).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To interweave items.",
              synonyms: [],
              antonyms: [],
              example: "to lace one's fingers together",
            },
            {
              definition: "To interweave the spokes of a bicycle wheel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To beat; to lash; to make stripes on.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To adorn with narrow strips or braids of some decorative material.",
              synonyms: [],
              antonyms: [],
              example: "cloth laced with silver",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Fastened or adorned with lace.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Tainted with something, especially a drug.",
              synonyms: [],
              antonyms: [],
              example:
                "I don't know what it was laced with, but he passed out a minute after drinking that first beer.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lace",
        "https://en.wiktionary.org/wiki/laced",
      ],
    },
  ],
  shove: [
    {
      word: "shove",
      phonetic: "/ʃʌv/",
      phonetics: [{ text: "/ʃʌv/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A rough push.", synonyms: [], antonyms: [] },
            { definition: "An all-in bet.", synonyms: [], antonyms: [] },
            {
              definition: "A forward movement of packed river-ice.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To push, especially roughly or with force.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To move off or along by an act of pushing, as with an oar or pole used in a boat; sometimes with off.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by ellipsis) To make an all-in bet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To pass (counterfeit money).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shove"],
    },
  ],
  jerky: [
    {
      word: "jerky",
      phonetic: "/ˈdʒɝki/",
      phonetics: [{ text: "/ˈdʒɝki/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Characterized by physical jerking.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Having the behavior of a jerk (unpleasant person).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/jerky"],
    },
    {
      word: "jerky",
      phonetic: "/ˈdʒɝki/",
      phonetics: [{ text: "/ˈdʒɝki/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Lean meat cured and preserved by cutting into thin strips and air-drying in the sun.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cure and preserve (meat) by drying it, making jerky.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["jerk"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/jerky"],
    },
  ],
  poppy: [
    {
      word: "poppy",
      phonetic: "/ˈpɒpi/",
      phonetics: [
        { text: "/ˈpɒpi/", audio: "" },
        {
          text: "/ˈpɑpi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/poppy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780277",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any plant of the genus Papaver or the family Papaveraceae, with crumpled, often red, petals and a milky juice having narcotic properties; especially the common poppy or corn poppy (Papaver rhoeas) which has orange-red flowers; the flower of such a plant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bright red colour tinted with orange, like that of the common poppy flower.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A simple artificial poppy flower worn in a buttonhole or displayed in other contexts to remember those who died in the two World Wars and other armed conflicts, especially around Remembrance Sunday.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Of a bright red colour tinted with orange, like that of the common poppy flower (Papaver rhoeas).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/poppy"],
    },
    {
      word: "poppy",
      phonetic: "/ˈpɒpi/",
      phonetics: [
        { text: "/ˈpɒpi/", audio: "" },
        {
          text: "/ˈpɑpi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/poppy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780277",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having a popping or bursting sound.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of a beverage: resembling soda pop; effervescent, fizzy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of eyes: protruding, sticking out.",
              synonyms: ["bulging"],
              antonyms: [],
            },
          ],
          synonyms: ["bulging"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/poppy"],
    },
    {
      word: "poppy",
      phonetic: "/ˈpɒpi/",
      phonetics: [
        { text: "/ˈpɒpi/", audio: "" },
        {
          text: "/ˈpɑpi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/poppy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780277",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Popular.", synonyms: [], antonyms: [] },
            {
              definition: "Typical, or in the style, of pop music.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/poppy"],
    },
    {
      word: "poppy",
      phonetic: "/ˈpɒpi/",
      phonetics: [
        { text: "/ˈpɒpi/", audio: "" },
        {
          text: "/ˈpɑpi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/poppy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780277",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One's father or grandfather, or a male authority figure having similar standing.",
              synonyms: ["pappy", "pop", "poppa", "pops", "pop-pop"],
              antonyms: [],
            },
          ],
          synonyms: ["pappy", "pop", "pop-pop", "poppa", "pops"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/poppy"],
    },
  ],
  leaks: [
    {
      word: "leaks",
      phonetic: "/liːks/",
      phonetics: [{ text: "/liːks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A crack, crevice, fissure, or hole which admits water or other fluid, or lets it escape.",
              synonyms: [],
              antonyms: [],
              example: "a leak in a boat",
            },
            {
              definition:
                "The entrance or escape of a fluid through a crack, fissure, or other aperture.",
              synonyms: [],
              antonyms: [],
              example: "The babies' diapers had big leaks.",
            },
            {
              definition:
                "A divulgation, or disclosure, of information previously held secret.",
              synonyms: [],
              antonyms: [],
              example:
                "The leaks by Chelsea Manning showed the secrets of the US military.",
            },
            {
              definition:
                "The person through whom such divulgation, or disclosure, occurs.",
              synonyms: [],
              antonyms: [],
              example:
                "The press must have learned about the plan through a leak.",
            },
            {
              definition:
                "A loss of electricity through imperfect insulation, or the point where it occurs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The gradual loss of a system resource caused by failure to deallocate previously reserved portions.",
              synonyms: [],
              antonyms: [],
              example: "memory leak",
            },
            {
              definition:
                '(especially with the verb "take") An act of urination.',
              synonyms: [],
              antonyms: [],
              example: "I have to take a leak.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To allow fluid or gas to pass through an opening that should be sealed.",
              synonyms: [],
              antonyms: [],
              example: "The faucet has been leaking since last month.",
            },
            {
              definition:
                "(of a fluid or gas) To pass through an opening that should be sealed.",
              synonyms: [],
              antonyms: [],
              example:
                "No one realized that propane gas was leaking from a rusty tank in the concession area, slowly filling the unventilated room.",
            },
            {
              definition:
                "To disclose secret information surreptitiously or anonymously.",
              synonyms: [],
              antonyms: [],
              example:
                "Someone must have leaked it to our competitors that the new product will be out soon.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/leak",
        "https://en.wiktionary.org/wiki/leaks",
      ],
    },
  ],
  fares: [
    {
      word: "fares",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A going; journey; travel; voyage; course; passage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Money paid for a transport ticket.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A paying passenger, especially in a taxi.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Food and drink.", synonyms: [], antonyms: [] },
            {
              definition: "Supplies for consumption or pleasure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A prostitute's client.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fare",
        "https://en.wiktionary.org/wiki/fares",
      ],
    },
  ],
  dodge: [
    {
      word: "dodge",
      phonetic: "/dɒdʒ/",
      phonetics: [
        {
          text: "/dɒdʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dodge-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453470",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "An act of dodging.", synonyms: [], antonyms: [] },
            {
              definition: "A trick, evasion or wile.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A line of work.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To avoid (something) by moving suddenly out of the way.",
              synonyms: [],
              antonyms: [],
              example: "He dodged traffic crossing the street.",
            },
            {
              definition: "To avoid; to sidestep.",
              synonyms: [],
              antonyms: [],
              example:
                "The politician dodged the question with a meaningless reply.",
            },
            {
              definition: "To go hither and thither.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(videography) To decrease the exposure for certain areas of an image in order to make them darker (compare burn).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To follow by dodging, or suddenly shifting from place to place.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To trick somebody.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["duck", "evade", "fudge", "shun", "skirt"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [{ definition: "Dodgy", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dodge"],
    },
  ],
  godly: [
    {
      word: "godly",
      phonetic: "/ˈɡɒdli/",
      phonetics: [
        { text: "/ˈɡɒdli/", audio: "" },
        {
          text: "/ˈɡɑdli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/godly-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769685",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of or pertaining to a god",
              synonyms: [],
              antonyms: [],
              example: "her godly powers = her superhuman powers",
            },
            {
              definition: "Devoted to a god or God; devout; righteous.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Gloriously good.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "In a godly manner; piously; devoutly; righteously.",
              synonyms: [],
              antonyms: [],
              example:
                "All that will live godly in Christ Jesus shall suffer persecution. — 2. Tim. iii. 12.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/godly"],
    },
  ],
  squaw: [
    {
      word: "squaw",
      phonetic: "/skwɔː/",
      phonetics: [{ text: "/skwɔː/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A woman, wife; especially a Native American woman.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/squaw"],
    },
  ],
  affix: [
    {
      word: "affix",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/affix-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89012050",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "That which is affixed; an appendage.",
              synonyms: ["addition", "supplement"],
              antonyms: [],
            },
            {
              definition: "A bound morpheme added to the word’s stem's end.",
              synonyms: ["postfix", "suffix"],
              antonyms: [],
            },
            {
              definition:
                "(broadly) A bound morpheme added to a word’s stem; a prefix, suffix etc.",
              synonyms: [],
              antonyms: ["nonaffix"],
            },
            {
              definition:
                "The complex number a+bi associated with the point in the Gauss plane with coordinates (a,b).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(decorative art) Any small feature, as a figure, a flower, or the like, added for ornament to a vessel or other utensil, to an architectural feature.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["addition", "supplement", "postfix", "suffix"],
          antonyms: ["nonaffix"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To attach.",
              synonyms: ["join", "put together", "unite"],
              antonyms: [],
              example: "to affix a stigma to a person",
            },
            {
              definition:
                "To subjoin, annex, or add at the close or end; to append to.",
              synonyms: [],
              antonyms: [],
              example: "to affix a seal to an instrument",
            },
            {
              definition: "To fix or fasten figuratively; with on or upon.",
              synonyms: [],
              antonyms: [],
              example: "eyes affixed upon the ground",
            },
          ],
          synonyms: ["join", "put together", "unite"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/affix"],
    },
  ],
  brute: [
    {
      word: "brute",
      phonetic: "/bɹuːt/",
      phonetics: [
        {
          text: "/bɹuːt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/brute-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80175000",
        },
        {
          text: "/bɹut/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/brute-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453883",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An animal seen as being without human reason; a senseless beast.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person with the characteristics of an unthinking animal; a coarse or brutal person.",
              synonyms: [],
              antonyms: [],
              example:
                'One of them was a hulking brute of a man, heavily tattooed and with a hardened face that practically screamed "I just got out of jail."',
            },
            {
              definition:
                "(Cambridge University slang) One who has not yet matriculated.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To shape (diamonds) by grinding them against each other.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Without reason or intelligence (of animals).",
              synonyms: [],
              antonyms: [],
              example: "a brute beast",
            },
            {
              definition:
                "Characteristic of unthinking animals; senseless, unreasoning (of humans).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Being unconnected with intelligence or thought; purely material, senseless.",
              synonyms: [],
              antonyms: [],
              example: "the brute earth; the brute powers of nature",
            },
            { definition: "Crude, unpolished.", synonyms: [], antonyms: [] },
            {
              definition: "Strong, blunt, and spontaneous.",
              synonyms: [],
              antonyms: [],
              example: "I punched him with brute force.",
            },
            {
              definition: "Brutal; cruel; fierce; ferocious; savage; pitiless.",
              synonyms: [],
              antonyms: [],
              example: "brute violence",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/brute"],
    },
    {
      word: "brute",
      phonetic: "/bɹuːt/",
      phonetics: [
        {
          text: "/bɹuːt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/brute-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80175000",
        },
        {
          text: "/bɹut/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/brute-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453883",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To disseminate, promulgate, or spread news, a rumour, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bruit",
        "https://en.wiktionary.org/wiki/brute",
      ],
    },
  ],
  nicer: [
    {
      word: "nicer",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Pleasant, satisfactory.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of a person: friendly, attractive.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Respectable; virtuous.",
              synonyms: [],
              antonyms: [],
              example:
                "What is a nice person like you doing in a place like this?",
            },
            {
              definition:
                "(with and) Shows that the given adjective is desirable, or acts as a mild intensifier; pleasantly, quite.",
              synonyms: [],
              antonyms: [],
              example: "The soup is nice and hot.",
            },
            {
              definition: "Silly, ignorant; foolish.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Particular in one's conduct; scrupulous, painstaking; choosy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Particular as regards rules or qualities; strict.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Showing or requiring great precision or sensitive discernment; subtle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Easily injured; delicate; dainty.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Doubtful, as to the outcome; risky.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "charming",
            "delightful",
            "friendly",
            "kind",
            "lovely",
            "pleasant",
            "sweet",
            "charming",
            "delightful",
            "lovely",
            "pleasant",
            "appetising",
            "appetizing",
            "delicious",
            "moreish",
            "scrummy",
            "scrumptious",
            "tasty",
            "fine",
            "subtle",
          ],
          antonyms: [
            "horrible",
            "horrid",
            "nasty",
            "horrible",
            "horrid",
            "nasty",
            "awful",
            "disgusting",
            "distasteful",
            "foul",
            "gross",
            "horrible",
            "horrid",
            "nasty",
            "nauseating",
            "putrid",
            "rancid",
            "rank",
            "sickening",
            "unsatisfactory",
            "naughty",
          ],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Nicely.",
              synonyms: [],
              antonyms: [],
              example: "Children, play nice.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/nice",
        "https://en.wiktionary.org/wiki/nicer",
      ],
    },
  ],
  undue: [
    {
      word: "undue",
      phonetic: "/ʌnˈdjuː/",
      phonetics: [
        { text: "/ʌnˈdjuː/", audio: "" },
        { text: "/ʌnˈdjuː/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Excessive; going beyond that what is natural or sufficient.",
              synonyms: [],
              antonyms: [],
              example:
                "To individuals who despise killings in any form, death penalty is undue punishment.",
            },
            {
              definition:
                "That which ought not to be done; illegal; unjustified.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of a payment etc) Not owing or payable.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/undue"],
    },
  ],
  snarl: [
    {
      word: "snarl",
      phonetic: "/ˈsnɑː(ɹ)l/",
      phonetics: [
        {
          text: "/ˈsnɑː(ɹ)l/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/snarl-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89013353",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A knot or complication of hair, thread, or the like, difficult to disentangle.",
              synonyms: ["entanglement"],
              antonyms: [],
            },
            {
              definition:
                "An intricate complication; a problematic difficulty; a knotty or tangled situation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A slow-moving traffic jam.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["imbroglio", "entanglement"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To entangle; to complicate; to involve in knots.",
              synonyms: [],
              antonyms: [],
              example: "to snarl a skein of thread",
            },
            { definition: "To become entangled.", synonyms: [], antonyms: [] },
            {
              definition:
                "To place in an embarrassing situation; to ensnare; to make overly complicated.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be congested in traffic, or to make traffic congested.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To form raised work upon the outer surface of (thin metal ware) by the repercussion of a snarling iron upon the inner surface.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/snarl"],
    },
    {
      word: "snarl",
      phonetic: "/ˈsnɑː(ɹ)l/",
      phonetics: [
        {
          text: "/ˈsnɑː(ɹ)l/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/snarl-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89013353",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The act of snarling; a growl; a surly or peevish expression; an angry contention.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A growl, for example that of an angry or surly dog, or similar; grumbling sounds.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A squabble.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To growl angrily by gnashing or baring the teeth; to gnarl; to utter grumbling sounds.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To complain angrily; to utter growlingly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To speak crossly; to talk in rude, surly terms.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/snarl"],
    },
  ],
  merge: [
    {
      word: "merge",
      phonetic: "/mɜːdʒ/",
      phonetics: [
        { text: "/mɜːdʒ/", audio: "" },
        {
          text: "/mɝdʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/merge-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=17239594",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The joining together of multiple sources.",
              synonyms: [],
              antonyms: [],
              example: "The merge of the two documents failed.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To combine into a whole.",
              synonyms: [],
              antonyms: [],
              example:
                "Headquarters merged the operations of the three divisions.",
            },
            {
              definition: "To combine into a whole.",
              synonyms: [],
              antonyms: [],
              example: "The two companies merged.",
            },
            {
              definition: "To blend gradually into something else.",
              synonyms: [],
              antonyms: [],
              example: "The lanes of traffic merged.",
            },
          ],
          synonyms: [],
          antonyms: ["divide", "split"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/merge"],
    },
  ],
  doses: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  showy: [
    {
      word: "showy",
      phonetic: "/ˈʃəʊ.i/",
      phonetics: [
        {
          text: "/ˈʃəʊ.i/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/showy-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88671356",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(sometimes derogatory) calling attention; flashy; standing out to the eye",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/showy"],
    },
  ],
  daddy: [
    {
      word: "daddy",
      phonetic: "/ˈdædi/",
      phonetics: [
        {
          text: "/ˈdædi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/daddy-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=91894812",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈdædi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/daddy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1230200",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(usually childish) Father.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A male lover.", synonyms: [], antonyms: [] },
            {
              definition: "An informal term of address for a man.",
              synonyms: [],
              antonyms: [],
              example: "Rock 'n' roll is cool, daddy, and you know it!",
            },
            {
              definition:
                "A male juvenile delinquent in a reformatory who dominates the other inmates through threats and violence.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "da",
            "dad",
            "dadda",
            "daddio",
            "pa",
            "papa",
            "paw",
            "pop",
            "poppa",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To father; to sire.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/daddy"],
    },
  ],
  roost: [
    {
      word: "roost",
      phonetic: "/ɹuːst/",
      phonetics: [
        {
          text: "/ɹuːst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/roost-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89012604",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The place where a bird sleeps (usually its nest or a branch).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A group of birds roosting together.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A bedroom", synonyms: [], antonyms: [] },
            {
              definition: "The inner roof of a cottage.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(of birds or bats) To settle on a perch in order to sleep or rest",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To spend the night", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/roost"],
    },
    {
      word: "roost",
      phonetic: "/ɹuːst/",
      phonetics: [
        {
          text: "/ɹuːst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/roost-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89012604",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A tidal race.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/roost"],
    },
    {
      word: "roost",
      phonetic: "/ɹuːst/",
      phonetics: [
        {
          text: "/ɹuːst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/roost-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89012604",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To rout out of bed; to rouse",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To harass, to treat in a rough way.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To arrest", synonyms: [], antonyms: [] },
            { definition: "To drive strongly", synonyms: [], antonyms: [] },
          ],
          synonyms: ["collar", "nick", "pinch", "bring round", "knock up"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/roost",
        "https://en.wiktionary.org/wiki/roust",
      ],
    },
  ],
  vases: [
    {
      word: "vases",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An upright open container used mainly for displaying fresh, dried, or artificial flowers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The body of the Corinthian capital.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/vase",
        "https://en.wiktionary.org/wiki/vases",
      ],
    },
  ],
  swirl: [
    {
      word: "swirl",
      phonetic: "/swɜːl/",
      phonetics: [
        { text: "/swɜːl/", audio: "" },
        { text: "/swɜrl/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A whirling eddy.", synonyms: [], antonyms: [] },
            {
              definition: "A twist or coil of something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The upward rushing of a fish through the water to take the bait.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To twist or whirl, as an eddy.",
              synonyms: [],
              antonyms: [],
              example: "I swirled my brush around in the paint.",
            },
            {
              definition: "To be arranged in a twist, spiral or whorl.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To circulate.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/swirl"],
    },
  ],
  petty: [
    {
      word: "petty",
      phonetic: "/ˈpɛti/",
      phonetics: [
        {
          text: "/ˈpɛti/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/petty-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769732",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(usually in the plural) A little schoolboy, either in grade or size.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A class or school for young schoolboys.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An outhouse: an outbuilding used as a lavatory.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["petty form", "ABC", "petty school"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Little, small, secondary in rank or importance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Insignificant, trifling, or inconsiderable.",
              synonyms: [],
              antonyms: [],
              example: "a petty fault",
            },
            {
              definition: "Narrow-minded, small-minded.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Begrudging in nature, especially over insignificant matters.",
              synonyms: [],
              antonyms: [],
              example:
                "That corporation is only slightly pettier than they are greedy, and they are overdue to reap the consequences.",
            },
          ],
          synonyms: ["grudgeful", "grudging"],
          antonyms: ["broad-minded"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/petty"],
    },
  ],
  colds: [
    {
      word: "colds",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A condition of low temperature.",
              synonyms: [],
              antonyms: [],
              example: "Come in, out of the cold.",
            },
            {
              definition: "(with 'the') A harsh place; a place of abandonment.",
              synonyms: [],
              antonyms: [],
              example:
                "The former politician was left out in the cold after his friends deserted him.",
            },
            {
              definition:
                "A common, usually harmless, viral illness, usually with congestion of the nasal passages and sometimes fever.",
              synonyms: [],
              antonyms: [],
              example:
                "I caught a miserable cold and had to stay home for a week",
            },
            { definition: "Rheum, sleepy dust", synonyms: [], antonyms: [] },
          ],
          synonyms: ["common cold", "coryza", "head cold", "pose", "coldness"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cold",
        "https://en.wiktionary.org/wiki/colds",
      ],
    },
  ],
  curry: [
    {
      word: "curry",
      phonetic: "/ˈkʌ.ɹi/",
      phonetics: [
        { text: "/ˈkʌ.ɹi/", audio: "" },
        {
          text: "/ˈkɜ.ɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/curry-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=58523009",
          license: {
            name: "BY 4.0",
            url: "https://creativecommons.org/licenses/by/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One of a family of dishes originating from South Asian cuisine, flavoured by a spiced sauce.",
              synonyms: ["Ruby Murray"],
              antonyms: [],
            },
            {
              definition:
                "A spiced sauce or relish, especially one flavoured with curry powder.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Curry powder.",
              synonyms: ["curry powder"],
              antonyms: [],
            },
          ],
          synonyms: ["Ruby Murray", "curry powder"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cook or season with curry powder.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/curry"],
    },
    {
      word: "curry",
      phonetic: "/ˈkʌ.ɹi/",
      phonetics: [
        { text: "/ˈkʌ.ɹi/", audio: "" },
        {
          text: "/ˈkɜ.ɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/curry-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=58523009",
          license: {
            name: "BY 4.0",
            url: "https://creativecommons.org/licenses/by/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To groom (a horse); to dress or rub down a horse with a curry comb.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To dress (leather) after it is tanned by beating, rubbing, scraping and colouring.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To beat, thrash; to drub.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To try to win or gain (favour) by flattering.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/curry"],
    },
    {
      word: "curry",
      phonetic: "/ˈkʌ.ɹi/",
      phonetics: [
        { text: "/ˈkʌ.ɹi/", audio: "" },
        {
          text: "/ˈkɜ.ɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/curry-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=58523009",
          license: {
            name: "BY 4.0",
            url: "https://creativecommons.org/licenses/by/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To perform currying upon.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/curry"],
    },
    {
      word: "curry",
      phonetic: "/ˈkʌ.ɹi/",
      phonetics: [
        { text: "/ˈkʌ.ɹi/", audio: "" },
        {
          text: "/ˈkɜ.ɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/curry-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=58523009",
          license: {
            name: "BY 4.0",
            url: "https://creativecommons.org/licenses/by/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To scurry; to ride or run hastily",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cover (a distance); (of a projectile) to traverse (its range).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To hurry.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/curry"],
    },
    {
      word: "curry",
      phonetic: "/ˈkʌ.ɹi/",
      phonetics: [
        { text: "/ˈkʌ.ɹi/", audio: "" },
        {
          text: "/ˈkɜ.ɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/curry-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=58523009",
          license: {
            name: "BY 4.0",
            url: "https://creativecommons.org/licenses/by/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A site for mining stone, limestone, or slate.",
              synonyms: [],
              antonyms: [],
              example:
                "Michelangelo personally quarried marble from the world-famous quarry at Carrara.",
            },
          ],
          synonyms: ["delf"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A part of the entrails of a hunted animal, given to the hounds as a reward.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An animal, often a bird or mammal, which is hunted.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An object of search or pursuit.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["mark", "prey", "target"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A diamond-shaped tile or pane, often of glass or stone.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/curry",
        "https://en.wiktionary.org/wiki/quarry",
      ],
    },
  ],
  cobra: [
    {
      word: "cobra",
      phonetic: "/ˈkɒbɹə/",
      phonetics: [
        { text: "/ˈkɒbɹə/", audio: "" },
        { text: "/ˈkoʊbɹə/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of various venomous snakes of the family Elapidae.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cobra"],
    },
  ],
  genie: [
    {
      word: "genie",
      phonetic: "/ˈdʒiː.ni/",
      phonetics: [
        {
          text: "/ˈdʒiː.ni/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/genie-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780100",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A jinn, a being descended from the jann, normally invisible to the human eye, but who may also appear in animal or human form.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A fictional magical being that is typically bound to obey the commands of a mortal possessing its container.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/genie"],
    },
  ],
  flare: [
    {
      word: "flare",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/flare-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75796838",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/flɛə̯/", audio: "" },
        { text: "/flɛɚ̯/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A sudden bright light.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A source of brightly burning light or intense heat.",
              synonyms: [],
              antonyms: [],
              example: "solar flare",
            },
            {
              definition: "A sudden eruption or outbreak; a flare-up.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A widening of an object with an otherwise roughly constant width.",
              synonyms: [],
              antonyms: [],
              example:
                "During assembly of a flare tube fitting, a flare nut is used to secure the flared tubing’s tapered end to the also tapered fitting, producing a pressure-resistant, leak-tight seal.",
            },
            {
              definition: "(in plural) Bell-bottom trousers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The transition from downward flight to level flight just before landing.",
              synonyms: [],
              antonyms: [],
              example:
                "The captain executed the flare perfectly, and we lightly touched down.",
            },
            {
              definition:
                "A low fly ball that is hit in the region between the infielders and the outfielders.",
              synonyms: ["Texas leaguer", "blooper"],
              antonyms: [],
              example:
                "Jones hits a little flare to left that falls for a single.",
            },
            {
              definition:
                "A route run by the running back, releasing toward the sideline and then slightly arcing upfield looking for a short pass.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Short for lens flare.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["Texas leaguer", "blooper"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To cause to burn.", synonyms: [], antonyms: [] },
            {
              definition: "To cause inflammation; to inflame.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To open outward in shape.",
              synonyms: [],
              antonyms: [],
              example:
                "The building flared from the third through the seventh floors to occupy the airspace over the entrance plaza. (intransitive)",
            },
            {
              definition:
                "To (operate an aircraft to) transition from downward flight to level flight just before landing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To blaze brightly.",
              synonyms: [],
              antonyms: [],
              example: "The blast furnace flared in the night.",
            },
            {
              definition:
                "To shine out with a sudden and unsteady light; to emit a dazzling or painfully bright light.",
              synonyms: [],
              antonyms: [],
              example: "The candle flared in a sudden draught.",
            },
            {
              definition:
                "To shine out with gaudy colours; to be offensively bright or showy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To suddenly happen or intensify.",
              synonyms: ["flare up"],
              antonyms: [],
            },
            {
              definition: "To suddenly erupt in anger.",
              synonyms: ["flare up"],
              antonyms: [],
            },
            {
              definition: "To be exposed to too much light.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["flare up", "flare up"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/flare"],
    },
  ],
  messy: [
    {
      word: "messy",
      phonetic: "/ˈmɛsi/",
      phonetics: [{ text: "/ˈmɛsi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(of a place, situation, person, etc) In a disorderly state; chaotic; disorderly.",
              synonyms: [],
              antonyms: [],
              example: "Jim ran his fingers through his messy brown hair.",
            },
            {
              definition: "(of a person) Prone to causing mess.",
              synonyms: [],
              antonyms: [],
              example: "He is the messiest person I've ever met.",
            },
            {
              definition:
                "(of a situation) Difficult or unpleasant to deal with.",
              synonyms: [],
              antonyms: [],
              example: "a messy divorce",
            },
          ],
          synonyms: [],
          antonyms: ["neat", "orderly"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/messy"],
    },
  ],
  cores: [
    {
      word: "cores",
      phonetic: "/kɔɹz/",
      phonetics: [
        { text: "/kɔɹz/", audio: "" },
        { text: "/kɔːz/", audio: "" },
        { text: "/kɔɹz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Various former units of volume, particularly:",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["homer", "omer"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The central part of fruit, containing the kernels or seeds.",
              synonyms: [],
              antonyms: [],
              example: "the core of an apple or quince",
            },
            {
              definition: "The heart or inner part of a physical thing",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The center or inner part of a space or area",
              synonyms: [],
              antonyms: [],
              example: "the core of a square",
            },
            {
              definition: "The most important part of a thing; the essence.",
              synonyms: [],
              antonyms: [],
              example: "the core of a subject",
            },
            {
              definition:
                "The portion of a mold that creates an internal cavity within a casting or that makes a hole in or through a casting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The bony process which forms the central axis of the horns in many animals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Magnetic data storage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An individual computer processor, in the sense when several processors (called cores or CPU cores) are plugged together in one single integrated circuit to work as one (called multi-core processor).",
              synonyms: [],
              antonyms: [],
              example:
                "I wanted to play a particular computer game, which required I buy a new computer, so while the game said it needed at least a dual-core processor, I wanted my computer to be a bit ahead of the curve, so I bought a quad-core.",
            },
            {
              definition:
                "The material between surface materials in a structured composite sandwich material.",
              synonyms: [],
              antonyms: [],
              example: "a floor panel with a Nomex honeycomb core",
            },
            {
              definition:
                "The inner part of a nuclear reactor in which the nuclear reaction takes place.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of ferromagnetic material (i.e. soft iron), inside the windings of an electromagnet, that channels the magnetic field.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A disorder of sheep caused by worms in the liver.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cylindrical sample of rock or other materials obtained by core drilling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A tiny sample of organic material obtained by means of a fine-needle biopsy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The central part of a protein structure consisting in mostly hydrophobic aminoacids.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The set of feasible allocations that cannot be improved upon by a subset (a coalition) of the economy's agents.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hollow cylindrical piece of cardboard around which a web of paper or plastic is winded.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An atomic nucleus plus inner electrons (i.e. except valence electrons).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To remove the core of an apple or other fruit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To extract a sample with a drill.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["crux", "gist"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A body of individuals; an assemblage.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A miner's underground working time or shift.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(machinery, marine) A deposit paid by the purchaser of a rebuilt part, to be refunded on return of a used, rebuildable part, or the returned rebuildable part itself.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cor",
        "https://en.wiktionary.org/wiki/core",
        "https://en.wiktionary.org/wiki/cores",
      ],
    },
  ],
  soaks: [
    {
      word: "soaks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An immersion in water etc.",
              synonyms: [],
              antonyms: [],
              example:
                "After the strenuous climb, I had a nice long soak in a bath.",
            },
            { definition: "A drunkard.", synonyms: [], antonyms: [] },
            {
              definition: "A carouse; a drinking session.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A low-lying depression that fills with water after rain.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["alcoholic", "souse", "suck-pint"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To be saturated with liquid by being immersed in it.",
              synonyms: [],
              antonyms: [],
              example: "I'm going to soak in the bath for a couple of hours.",
            },
            {
              definition:
                "To immerse in liquid to the point of saturation or thorough permeation.",
              synonyms: [],
              antonyms: [],
              example: "Soak the beans overnight before cooking.",
            },
            {
              definition: "To penetrate or permeate by saturation.",
              synonyms: [],
              antonyms: [],
              example: "The water soaked into my shoes and gave me wet feet.",
            },
            {
              definition:
                "To allow (especially a liquid) to be absorbed; to take in, receive. (usually + up)",
              synonyms: [],
              antonyms: [],
              example: "A sponge soaks up water; the skin soaks in moisture.",
            },
            { definition: "To take money from.", synonyms: [], antonyms: [] },
            {
              definition: "To drink intemperately or gluttonously.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To heat (a metal) before shaping it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To hold a kiln at a particular temperature for a given period of time.",
              synonyms: [],
              antonyms: [],
              example: "We should soak the kiln at cone 9 for half an hour.",
            },
            { definition: "To absorb; to drain.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/soak",
        "https://en.wiktionary.org/wiki/soaks",
      ],
    },
  ],
  ripen: [
    {
      word: "ripen",
      phonetic: "/ˈɹaɪpən/",
      phonetics: [{ text: "/ˈɹaɪpən/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To grow ripe; to become mature (said of grain, fruit, flowers etc.)",
              synonyms: [],
              antonyms: [],
              example: "Grapes ripen in the sun.",
            },
            {
              definition: "To approach or come to perfection.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause to mature; to make ripe",
              synonyms: [],
              antonyms: [],
              example: "The warm sun ripened the corn.",
            },
            {
              definition:
                "To mature; to fit or prepare; to bring to perfection",
              synonyms: [],
              antonyms: [],
              example: "ripen the judgment",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ripen"],
    },
  ],
  whine: [
    {
      word: "whine",
      phonetic: "/waɪn/",
      phonetics: [{ text: "/waɪn/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A long-drawn, high-pitched complaining cry or sound",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A complaint or criticism",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To utter a high-pitched cry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make a sound resembling such a cry.",
              synonyms: [],
              antonyms: [],
              example: "The jet engines whined at take off.",
            },
            {
              definition:
                "To complain or protest with a whine or as if with a whine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To move with a whining sound.",
              synonyms: [],
              antonyms: [],
              example: "The jet whined into the air.",
            },
            {
              definition: "To utter with the sound of a whine.",
              synonyms: [],
              antonyms: [],
              example:
                "Kelly Queen was whining that the boss made him put on his tie.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/whine"],
    },
  ],
  amino: [
    {
      word: "amino",
      phonetic: "/əˈmiːnəʊ/",
      phonetics: [
        { text: "/əˈmiːnəʊ/", audio: "" },
        { text: "/əˈminoʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The amine functional group.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Relating to an amine.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/amino"],
    },
  ],
  plaid: [
    {
      word: "plaid",
      phonetic: "/plad/",
      phonetics: [
        { text: "/plad/", audio: "" },
        { text: "/plæd/", audio: "" },
        {
          text: "/plæd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/plaid-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50409590",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A type of twilled woollen cloth, often with a tartan or chequered pattern.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A length of such material used as a piece of clothing, formerly worn in the Scottish Highlands and other parts of northern Britain and remaining as an item of ceremonial dress worn by members of Scottish pipe bands.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The typical chequered pattern of a plaid; tartan.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Having a pattern or colors which resemble a Scottish tartan; checkered or marked with bars or stripes at right angles to one another.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/plaid"],
    },
    {
      word: "plaid",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To act in a manner such that one has fun; to engage in activities expressly for the purpose of recreation or entertainment.",
              synonyms: [],
              antonyms: [],
              example: "They played long and hard.",
            },
            {
              definition:
                "To perform in (a sport); to participate in (a game).",
              synonyms: [],
              antonyms: [],
              example: "He plays on three teams",
            },
            {
              definition: "To take part in amorous activity; to make love.",
              synonyms: ["get it on", "have sex", "make out"],
              antonyms: [],
            },
            {
              definition:
                "To act as the indicated role, especially in a performance.",
              synonyms: [],
              antonyms: [],
              example: "He plays the King, and she's the Queen.",
            },
            {
              definition: "(heading) To produce music or theatre.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(heading) To behave in a particular way.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To move in any manner; especially, to move regularly with alternate or reciprocating motion; to operate.",
              synonyms: [],
              antonyms: [],
              example: "He played the torch beam around the room.",
            },
            { definition: "To move to and fro.", synonyms: [], antonyms: [] },
            {
              definition: "To put in action or motion.",
              synonyms: [],
              antonyms: [],
              example: "to play a trump in a card game",
            },
            {
              definition:
                "To keep in play, as a hooked fish in order to land it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To manipulate, deceive, or swindle someone.",
              synonyms: ["defraud"],
              antonyms: [],
              example: "You played me!",
            },
          ],
          synonyms: ["defraud", "get it on", "have sex", "make out"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/plaid",
        "https://en.wiktionary.org/wiki/play",
      ],
    },
  ],
  spiny: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  mowed: [
    {
      word: "mowed",
      phonetic: "/məʊd/",
      phonetics: [{ text: "/məʊd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cut down grass or crops.",
              synonyms: [],
              antonyms: [],
              example: "He mowed the lawn every few weeks in the summer.",
            },
            {
              definition: "To cut down or slaughter in great numbers.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make grimaces, mock.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To put into mows.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mow",
        "https://en.wiktionary.org/wiki/mowed",
      ],
    },
  ],
  baton: [
    {
      word: "baton",
      phonetic: "/ˈbætɒn/",
      phonetics: [
        { text: "/ˈbætɒn/", audio: "" },
        {
          text: "/bəˈtɑn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/baton-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=36696492",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A staff or truncheon, used for various purposes",
              synonyms: [],
              antonyms: [],
              example: "the baton of a field marshal",
            },
            {
              definition: "The stick of a conductor in musical performances.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An object transferred by runners in a relay race.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A short stout club used primarily by policemen; a truncheon (UK).",
              synonyms: ["billy club", "nightstick"],
              antonyms: [],
            },
            {
              definition:
                "An abatement in coats of arms to denote illegitimacy. (Also spelled batune, baston).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A riband with the ends cut off, resembling a baton, as shown on a coat of arms.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A short vertical lightweight post, not set into the ground, used to separate wires in a fence.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["billy club", "nightstick"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To strike with a baton.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/baton"],
    },
  ],
  peers: [
    {
      word: "peers",
      phonetic: "/pɪəz/",
      phonetics: [
        { text: "/pɪəz/", audio: "" },
        { text: "/pɪɹz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A look; a glance.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To look with difficulty, or as if searching for something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To come in sight; to appear.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Somebody who is, or something that is, at a level or of a value equal (to that of something else).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Someone who is approximately the same age (as someone else).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A noble with a hereditary title, i.e., a peerage, and in times past, with certain rights and privileges not enjoyed by commoners.",
              synonyms: [],
              antonyms: [],
              example: "a peer of the realm",
            },
            {
              definition: "A comrade; a companion; an associate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make equal in rank.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To carry communications traffic terminating on one's own network on an equivalency basis to and from another network, usually without charge or payment. Contrast with transit where one pays another network provider to carry one's traffic.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Someone who pees, someone who urinates.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/peer",
        "https://en.wiktionary.org/wiki/peers",
      ],
    },
  ],
  vowed: [
    {
      word: "vowed",
      phonetic: "/vaʊd/",
      phonetics: [
        {
          text: "/vaʊd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/vowed-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=93629639",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make a vow; to promise.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make a vow regarding (something).",
              synonyms: [],
              antonyms: [],
              example: "The wronged woman vowed revenge.",
            },
            {
              definition:
                "To declare publicly that one has made a vow, usually to show one's determination or to announce an act of retaliation.",
              synonyms: [],
              antonyms: [],
              example: "The rebels vowed to continue their fight.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Undertaken in accordance with a vow; solemnly promised.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/vow",
        "https://en.wiktionary.org/wiki/vowed",
      ],
    },
  ],
  pious: [
    {
      word: "pious",
      phonetic: "/ˈpaɪəs/",
      phonetics: [
        { text: "/ˈpaɪəs/", audio: "" },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pious-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769733",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Of or pertaining to piety, exhibiting piety, devout, godfearing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "devout",
            "dutiful",
            "godly",
            "religious",
            "reverent",
            "reverential",
          ],
          antonyms: ["impious", "independent", "profane"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pious"],
    },
  ],
  swans: [
    {
      word: "swans",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of various species of large, long-necked waterfowl, of genus Cygnus (bird family: Anatidae), most of which have white plumage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One whose grace etc. suggests a swan.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "This bird used as a heraldic charge, sometimes with a crown around its neck (e. g. the arms of Buckinghamshire).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To travel or move about in an aimless, idle, or pretentiously casual way.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To declare (chiefly in first-person present constructions).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/swan",
        "https://en.wiktionary.org/wiki/swans",
      ],
    },
  ],
  exits: [
    {
      word: "exits",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An act of going out or going away, or leaving; a departure.",
              synonyms: ["egress", "outgoing"],
              antonyms: ["entrance", "entry", "ingoing", "ingress"],
              example: "He made his exit at the opportune time.",
            },
            { definition: "A way out.", synonyms: [], antonyms: [] },
            {
              definition: "The act of departing from life; death.",
              synonyms: [],
              antonyms: [],
              example: "the untimely exit of a respected politician",
            },
          ],
          synonyms: ["egress", "outgoing"],
          antonyms: ["entrance", "entry", "ingoing", "ingress"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To go out or go away from a place or situation; to depart, to leave.",
              synonyms: [],
              antonyms: ["arrive", "come", "enter", "ingress"],
            },
            {
              definition: "To depart from life; to die.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To end or terminate (a program, subroutine, etc.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(originally United States) To depart from or leave (a place or situation).",
              synonyms: [],
              antonyms: ["enter"],
            },
          ],
          synonyms: [],
          antonyms: ["arrive", "come", "enter", "ingress", "enter"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Income, returns, revenue.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/exit",
        "https://en.wiktionary.org/wiki/exits",
      ],
    },
  ],
  afoot: [
    {
      word: "afoot",
      phonetic: "/əˈfʊt/",
      phonetics: [{ text: "/əˈfʊt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(predicative) That is on foot, in motion, in action, in progress.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "On foot. (means of locomotion, walking)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "On foot. (support of the body, standing)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "In motion; in action; astir; stirring; in progress.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/afoot"],
    },
  ],
  plugs: [
    {
      word: "plugs",
      phonetic: "/plʌɡz/",
      phonetics: [{ text: "/plʌɡz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A pronged connecting device which fits into a mating socket, especially an electrical one.",
              synonyms: [],
              antonyms: [],
              example:
                "I pushed the plug back into the electrical socket and the lamp began to glow again.",
            },
            {
              definition:
                "Any piece of wood, metal, or other substance used to stop or fill a hole.",
              synonyms: ["bung", "dowel", "stopper", "stopple"],
              antonyms: [],
              example: "Pull the plug out of the tub so it can drain.",
            },
            {
              definition: "A flat oblong cake of pressed tobacco.",
              synonyms: [],
              antonyms: [],
              example: "He preferred a plug of tobacco to loose chaw.",
            },
            {
              definition: "A high, tapering silk hat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A worthless horse.",
              synonyms: ["bum", "dobbin", "hack", "jade", "nag"],
              antonyms: [],
              example: "That sorry old plug is ready for the glue factory!",
            },
            {
              definition: "Any worn-out or useless article.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A block of wood let into a wall to afford a hold for nails.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A mention of a product (usually a book, film or play) in an interview, or an interview which features one or more of these.",
              synonyms: [],
              antonyms: [],
              example:
                "During the interview, the author put in a plug for his latest novel.",
            },
            {
              definition:
                "A body of once molten rock that hardened in a volcanic vent. Usually round or oval in shape.",
              synonyms: [],
              antonyms: [],
              example:
                "Pressure built beneath the plug in the caldera, eventually resulting in a catastrophic explosion of pyroclastic shrapnel and ash.",
            },
            {
              definition:
                "A type of lure consisting of a rigid, buoyant or semi-buoyant body and one or more hooks.",
              synonyms: [],
              antonyms: [],
              example:
                "The fisherman cast the plug into a likely pool, hoping to catch a whopper.",
            },
            {
              definition:
                "A small seedling grown in a tray from expanded polystyrene or polythene filled usually with a peat or compost substrate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(jewellery) A short cylindrical piece of jewellery commonly worn in larger-gauge body piercings, especially in the ear.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A drug dealer.", synonyms: [], antonyms: [] },
            {
              definition: "A branch from a water-pipe to supply a hose.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "bum",
            "dobbin",
            "hack",
            "jade",
            "nag",
            "bung",
            "dowel",
            "stopper",
            "stopple",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To stop with a plug; to make tight by stopping a hole.",
              synonyms: [],
              antonyms: [],
              example: "He attempted to plug the leaks with some caulk.",
            },
            {
              definition:
                "To blatantly mention a particular product or service as if advertising it.",
              synonyms: [],
              antonyms: [],
              example:
                "The main guest on the show just kept plugging his latest movie: it got so tiresome.",
            },
            {
              definition: "To persist or continue with something.",
              synonyms: [],
              antonyms: [],
              example:
                "Keep plugging at the problem until you find a solution.",
            },
            {
              definition: "To shoot a bullet into something with a gun.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To have sex with, penetrate sexually.",
              synonyms: [],
              antonyms: [],
              example: "I'd love to plug him.",
            },
          ],
          synonyms: [
            "drill",
            "pound",
            "sleep with",
            "keep up",
            "soldier on",
            "bust a cap",
            "pop",
            "ventilate",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/plug",
        "https://en.wiktionary.org/wiki/plugs",
      ],
    },
  ],
  idiom: [
    {
      word: "idiom",
      phonetic: "/ˈɪdɪəm/",
      phonetics: [
        {
          text: "/ˈɪdɪəm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/idiom-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=5174209",
        },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/idiom-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762702",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A manner of speaking, a mode of expression peculiar to a language, person, or group of people.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A language or language variety; specifically, a restricted dialect used in a given historical period, context etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An established expression whose meaning is not deducible from the literal meanings of its component words, often peculiar to a given language.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An artistic style (for example, in art, architecture, or music); an instance of such a style.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A programming construct or phraseology that is characteristic of the language.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "dialect",
            "language",
            "languoid",
            "lect",
            "vernacular",
            "expression",
            "form of words",
            "locution",
            "phrase",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/idiom"],
    },
  ],
  chili: [
    {
      word: "chili",
      phonetic: "/ˈt͡ʃɪli/",
      phonetics: [
        {
          text: "/ˈt͡ʃɪli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chili-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=806820",
        },
        { text: "/ˈt͡ʃɪli/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The pungent, spicy fresh or dried fruit of any of several cultivated varieties of capsicum peppers, used in cooking.",
              synonyms: ["chili pepper"],
              antonyms: [],
            },
            {
              definition:
                "Powdered chili pepper, used as a spice or flavouring in cooking.",
              synonyms: ["chili powder"],
              antonyms: [],
            },
            {
              definition:
                "(Indian Chinese cuisine) a spicy stew of chicken or paneer, capsicum and onion, eaten as an appetizer.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["chili pepper", "chili powder"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chili"],
    },
    {
      word: "chili",
      phonetic: "/ˈt͡ʃɪli/",
      phonetics: [
        {
          text: "/ˈt͡ʃɪli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chili-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=806820",
        },
        { text: "/ˈt͡ʃɪli/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A dish made with chili peppers and other ingredients, such as beans and beef.",
              synonyms: ["chili con carne"],
              antonyms: [],
            },
            {
              definition: "(Cincinnati) Cincinnati chili.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["chili con carne"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chili"],
    },
  ],
  rites: [
    {
      word: "rites",
      phonetic: "/ɹaɪts/",
      phonetics: [
        {
          text: "/ɹaɪts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rites-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651438",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "That which complies with justice, law or reason.",
              synonyms: [],
              antonyms: [],
              example: "We're on the side of right in this contest.",
            },
            {
              definition: "A legal, just or moral entitlement.",
              synonyms: [],
              antonyms: [],
              example: "You have no right to go through my personal diary.",
            },
            {
              definition: "The right side or direction.",
              synonyms: [],
              antonyms: [],
              example: "The pharmacy is just on the right past the bookshop.",
            },
            {
              definition: "The right hand or fist.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The ensemble of right-wing political parties; political conservatives as a group.",
              synonyms: [],
              antonyms: [],
              example: "The political right holds too much power.",
            },
            {
              definition:
                "The outward or most finished surface, as of a coin, piece of cloth, a carpet, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A wave breaking from right to left (viewed from the shore).",
              synonyms: [],
              antonyms: ["left"],
            },
          ],
          synonyms: ["3 o'clock", "starboard"],
          antonyms: ["duty", "obligation", "left"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A religious custom.", synonyms: [], antonyms: [] },
            {
              definition: "(by extension) A prescribed behavior.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/right",
        "https://en.wiktionary.org/wiki/rite",
        "https://en.wiktionary.org/wiki/rites",
      ],
    },
  ],
  serfs: [
    {
      word: "serfs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A partially free peasant of a low hereditary class, attached like a slave to the land owned by a feudal lord and required to perform labour, enjoying minimal legal or customary rights",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A similar agricultural labourer in 18th and 19th century Europe",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(strategy games) a worker unit",
              synonyms: ["peasant", "peon", "villager"],
              antonyms: [],
            },
          ],
          synonyms: ["peasant", "peon", "villager"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/serf",
        "https://en.wiktionary.org/wiki/serfs",
      ],
    },
  ],
  cleft: [
    {
      word: "cleft",
      phonetic: "/klɛft/",
      phonetics: [{ text: "/klɛft/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An opening, fissure, or V-shaped indentation made by or as if by splitting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A piece made by splitting.",
              synonyms: [],
              antonyms: [],
              example: "a cleft of wood",
            },
            {
              definition:
                "A disease of horses; a crack on the band of the pastern.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                'To syntactically separate a prominent constituent from the rest of the clause that concerns it, such as threat in "The threat which I saw but which he didn\'t see, was his downfall."',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cleft"],
    },
    {
      word: "cleft",
      phonetic: "/klɛft/",
      phonetics: [{ text: "/klɛft/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To split or sever something with, or as if with, a sharp instrument.",
              synonyms: [],
              antonyms: [],
              example: "The wings cleaved the foggy air.",
            },
            {
              definition:
                "To break a single crystal (such as a gemstone or semiconductor wafer) along one of its more symmetrical crystallographic planes (often by impact), forming facets on the resulting pieces.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make or accomplish by or as if by cutting.",
              synonyms: [],
              antonyms: [],
              example: "The truck cleaved a path through the ice.",
            },
            {
              definition:
                "To split (a complex molecule) into simpler molecules.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To split.", synonyms: [], antonyms: [] },
            {
              definition:
                "Of a crystal, to split along a natural plane of division.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Split, divided, or partially divided into two.",
              synonyms: ["cloven"],
              antonyms: [],
            },
          ],
          synonyms: ["cloven"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cleave",
        "https://en.wiktionary.org/wiki/cleft",
      ],
    },
  ],
  berth: [
    {
      word: "berth",
      phonetic: "/bɜːθ/",
      phonetics: [
        {
          text: "/bɜːθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/berth-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90913984",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/bɝθ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A fixed bunk for sleeping (in caravans, trains, etc).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Room for maneuvering or safety. (Often used in the phrase a wide berth.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A space for a ship to moor or a vehicle to park.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A room in which a number of the officers or ship's company mess and reside.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A job or position, especially on a ship.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Position or seed in a tournament bracket.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Position on the field of play",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To bring (a ship or vehicle) into its berth",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/berth"],
    },
  ],
  grubs: [
    {
      word: "grubs",
      phonetic: "/ɡɹʌbz/",
      phonetics: [{ text: "/ɡɹʌbz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An immature stage in the life cycle of an insect; a larva.",
              synonyms: ["larva"],
              antonyms: [],
            },
            { definition: "Food.", synonyms: [], antonyms: [] },
            {
              definition: "A short, thick man; a dwarf.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A dirty person.", synonyms: [], antonyms: [] },
            {
              definition: "A despicable person; a lowlife.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["larva"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To scavenge or in some way scrounge, typically for food.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To dig; to dig up by the roots; to root out by digging; often followed by up.",
              synonyms: [],
              antonyms: [],
              example: "to grub up trees, rushes, or sedge",
            },
            { definition: "To supply with food.", synonyms: [], antonyms: [] },
            { definition: "To eat.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/grub",
        "https://en.wiktionary.org/wiki/grubs",
      ],
    },
  ],
  annex: [
    {
      word: "annex",
      phonetic: "/ˈænɛks/",
      phonetics: [
        { text: "/ˈænɛks/", audio: "" },
        {
          text: "/əˈnɛks/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/annex-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50240564",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An addition, an extension.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An appendix to a book or document.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An addition or extension to a building.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An addition to the territory of a country or state, from a neighbouring country or state, normally by military force.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/annex"],
    },
    {
      word: "annex",
      phonetic: "/ˈænɛks/",
      phonetics: [
        { text: "/ˈænɛks/", audio: "" },
        {
          text: "/əˈnɛks/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/annex-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50240564",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To add something to another thing, especially territory; to incorporate.",
              synonyms: [],
              antonyms: [],
              example: "The ancient city of Petra was annexed by Rome.",
            },
            {
              definition:
                "To attach or connect, as a consequence, condition, etc.",
              synonyms: [],
              antonyms: [],
              example:
                "to annex a penalty to a prohibition, or punishment to guilt",
            },
            {
              definition: "To join; to be united.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["underjoin"],
          antonyms: ["separate"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/annex"],
    },
  ],
  dizzy: [
    {
      word: "dizzy",
      phonetic: "/ˈdɪzi/",
      phonetics: [
        { text: "/ˈdɪzi/", audio: "" },
        {
          text: "/ˈdɪzi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dizzy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=589694",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make dizzy, to bewilder.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Having a sensation of whirling, with a tendency to fall; giddy; feeling unbalanced or lightheaded.",
              synonyms: [],
              antonyms: [],
              example: "I stood up too fast and felt dizzy.",
            },
            {
              definition: "Producing giddiness.",
              synonyms: [],
              antonyms: [],
              example: "We climbed to a dizzy height.",
            },
            {
              definition: "Empty-headed, scatterbrained or frivolous; ditzy.",
              synonyms: [],
              antonyms: [],
              example: "My new secretary is a dizzy blonde.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dizzy"],
    },
  ],
  hasty: [
    {
      word: "hasty",
      phonetic: "/ˈheɪsti/",
      phonetics: [
        {
          text: "/ˈheɪsti/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hasty-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2707527",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Acting in haste; being too hurried or quick",
              synonyms: [],
              antonyms: [],
              example:
                "Without much thinking about it they made a hasty decision to buy it.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hasty"],
    },
  ],
  latch: [
    {
      word: "latch",
      phonetic: "/lætʃ/",
      phonetics: [
        {
          text: "/lætʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/latch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=825480",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To close or lock as if with a latch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To catch; lay hold of.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/latch"],
    },
    {
      word: "latch",
      phonetic: "/lætʃ/",
      phonetics: [
        {
          text: "/lætʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/latch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=825480",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A fastening for a door that has a bar that fits into a notch or slot, and is lifted by a lever or string from either side.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A flip-flop electronic circuit",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A latching.", synonyms: [], antonyms: [] },
            { definition: "A crossbow.", synonyms: [], antonyms: [] },
            {
              definition: "That which fastens or holds; a lace; a snare.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A breastfeeding baby's connection to the breast.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A lightweight lock to protect internal structures from being modified by multiple concurrent accesses.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/latch"],
    },
    {
      word: "latch",
      phonetic: "/lætʃ/",
      phonetics: [
        {
          text: "/lætʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/latch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=825480",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To smear; to anoint.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/latch"],
    },
  ],
  wasps: [
    {
      word: "wasps",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A member of the dominant American upper-class culture: a white Anglo-Saxon Protestant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A member of the Women Airforce Service Pilots.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of many types of stinging flying insect resembling a hornet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of the members of suborder Apocrita, excepting the ants (family Formicidae) and bees (clade Anthophila).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person who behaves in an angry or insolent way, hence waspish.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/WASP",
        "https://en.wiktionary.org/wiki/wasp",
        "https://en.wiktionary.org/wiki/wasps",
      ],
    },
  ],
  mirth: [
    {
      word: "mirth",
      phonetic: "/mɜːθ/",
      phonetics: [
        { text: "/mɜːθ/", audio: "" },
        {
          text: "/mɜɹθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mirth-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=180103",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The emotion usually following humour and accompanied by laughter; merriment; jollity; gaiety.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "That which causes merriment.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["delight", "glee", "hilarity", "jollity"],
          antonyms: ["gloom", "sadness"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mirth"],
    },
  ],
  baron: [
    {
      word: "baron",
      phonetic: "/ˈbæɹən/",
      phonetics: [
        {
          text: "/ˈbæɹən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/baron-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88675762",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈbɛɹən/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The male ruler of a barony.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A male member of the lowest rank of English nobility (the equivalent rank in Scotland is lord).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A particular cut of beef, made up of a double sirloin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person of great power in society, especially in business and politics.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A prisoner who gains power and influence by lending or selling tobacco.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A husband.",
              synonyms: [],
              antonyms: [],
              example: "baron and feme: husband and wife",
            },
            {
              definition:
                "Any of various nymphalid butterflies of the genus Euthalia.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/baron"],
    },
  ],
  plead: [
    {
      word: "plead",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To present (an argument or a plea), especially in a legal case.",
              synonyms: [],
              antonyms: [],
              example: "The defendant has decided to plead not guilty.",
            },
            {
              definition: "To beg, beseech, or implore.",
              synonyms: [],
              antonyms: [],
              example: "He pleaded with me not to leave the house.",
            },
            {
              definition: "To offer by way of excuse.",
              synonyms: [],
              antonyms: [],
              example: "Not wishing to attend the banquet, I pleaded illness.",
            },
            {
              definition: "To discuss by arguments.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/plead"],
    },
  ],
  aloof: [
    {
      word: "aloof",
      phonetic: "/əˈluːf/",
      phonetics: [{ text: "/əˈluːf/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Reserved and remote; either physically or emotionally distant; standoffish.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition:
                "At or from a distance, but within view, or at a small distance; apart; away.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Without sympathy; unfavorably.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "preposition",
          definitions: [
            { definition: "Away from; clear of.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/aloof"],
    },
  ],
  aging: [
    {
      word: "aging",
      phonetic: "/ˈeɪ.dʒɪŋ/",
      phonetics: [{ text: "/ˈeɪ.dʒɪŋ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cause to grow old; to impart the characteristics of age to.",
              synonyms: [],
              antonyms: [],
              example: "Grief ages us.",
            },
            {
              definition:
                "To postpone an action that would extinguish something, as a debt.",
              synonyms: [],
              antonyms: [],
              example:
                "Money's a little tight right now, let's age our bills for a week or so.",
            },
            {
              definition: "To categorize by age.",
              synonyms: [],
              antonyms: [],
              example:
                "One his first assignments was to age the accounts receivable.",
            },
            {
              definition: "To grow aged; to become old; to show marks of age.",
              synonyms: [],
              antonyms: [],
              example: "He grew fat as he aged.",
            },
          ],
          synonyms: ["mature", "elden"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The process of becoming older or more mature.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The deliberate process of making something (such as an antique) appear (or, in science fiction, become) older than it is.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Becoming senescent; accumulating damage to macromolecules, cells, tissues and organs with the passage of time; progressing loss of health, mobility, vibrancy and body functionality, resulting in biological death.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Wear, wear and tear.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Becoming elderly.",
              synonyms: [],
              antonyms: [],
              example:
                "The aging artist could no longer steadily hold the brush.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/age",
        "https://en.wiktionary.org/wiki/aging",
      ],
    },
  ],
  pixel: [
    {
      word: "pixel",
      phonetic: "/ˈpɪksəl/",
      phonetics: [{ text: "/ˈpɪksəl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One of the tiny dots that make up the representation of an image in a computer's memory.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the squares that make up a pixel art work or a zoomed in image in a computer.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["pel", "pictel", "px"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To create computer graphics by editing individual pixels.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pixel"],
    },
  ],
  bared: [
    {
      word: "bared",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To uncover; to reveal.",
              synonyms: [],
              antonyms: [],
              example: "She bared her teeth at him.",
            },
          ],
          synonyms: ["expose", "lay bare", "reveal", "show", "uncover"],
          antonyms: ["cover", "cover up", "hide"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bare",
        "https://en.wiktionary.org/wiki/bared",
      ],
    },
  ],
  mummy: [
    {
      word: "mummy",
      phonetic: "/ˈmʌmi/",
      phonetics: [
        {
          text: "/ˈmʌmi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mummy-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=31972283",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
        {
          text: "/ˈmʌmi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mummy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=7467918",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An embalmed human or animal corpse wrapped in linen bandages for burial, especially as practised by the ancient Egyptians and some Native American tribes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) A reanimated embalmed human corpse, as a typical character in horror films.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) Any naturally preserved human or animal body.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A brown pigment originally prepared from the ground-up remains of Egyptian animal or human mummies mixed with bitumen, etc.",
              synonyms: ["mummy brown"],
              antonyms: [],
            },
            { definition: "A pulp.", synonyms: [], antonyms: [] },
            {
              definition:
                "A substance used in medicine, prepared from mummified flesh.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sort of wax used in grafting.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["mummy brown"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To mummify.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mummy"],
    },
    {
      word: "mummy",
      phonetic: "/ˈmʌmi/",
      phonetics: [
        {
          text: "/ˈmʌmi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mummy-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=31972283",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
        {
          text: "/ˈmʌmi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mummy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=7467918",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(usually childish) mother.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mummy"],
    },
  ],
  hotly: [
    {
      word: "hotly",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hotly-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651040",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "With great amounts of heat",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "In a heated manner; intensely or vehemently",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hotly"],
    },
  ],
  auger: [
    {
      word: "auger",
      phonetic: "/ˈɔːɡə(ɹ)/",
      phonetics: [
        {
          text: "/ˈɔːɡə(ɹ)/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/auger-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89749541",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A carpenter's tool for boring holes longer than those bored by a gimlet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A snake or plumber's snake (plumbing tool).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A tool used to bore holes in the ground, e.g. for fence posts",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hollow drill used to take core samples of soil, ice, etc. for scientific study.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To use an auger; to drill a hole using an auger.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To proceed in the manner of an auger.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/auger"],
    },
  ],
  buddy: [
    {
      word: "buddy",
      phonetic: "/bʌd.i/",
      phonetics: [
        {
          text: "/bʌd.i/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/buddy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=280452",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A friend or casual acquaintance.",
              synonyms: ["bud", "mate"],
              antonyms: [],
              example: "They have been buddies since they were in school.",
            },
            {
              definition: "A partner for a particular activity.",
              synonyms: ["companion", "partner"],
              antonyms: [],
              example: "drinking buddies",
            },
            {
              definition:
                "An informal and friendly address to a stranger; a friendly (or occasionally antagonistic) placeholder name for a person one does not know.",
              synonyms: ["fellow", "mate"],
              antonyms: [],
              example: "Hey, buddy, I think you dropped this.",
            },
          ],
          synonyms: ["bud", "mate", "companion", "partner", "fellow", "mate"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To assign a buddy, or partner, to.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/buddy"],
    },
    {
      word: "buddy",
      phonetic: "/bʌd.i/",
      phonetics: [
        {
          text: "/bʌd.i/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/buddy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=280452",
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Resembling a bud.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/buddy"],
    },
  ],
  chaps: [
    {
      word: "chaps",
      phonetic: "/tʃæps/",
      phonetics: [{ text: "/tʃæps/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(obsolete outside Britain and Australia) A man, a fellow.",
              synonyms: [],
              antonyms: [],
              example: "Who’s that chap over there?",
            },
            { definition: "A customer, a buyer.", synonyms: [], antonyms: [] },
            { definition: "A child.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A cleft, crack, or chink, as in the surface of the earth, or in the skin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A division; a breach, as in a party.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A blow; a rap.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "Of the skin, to split or flake due to cold weather or dryness.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause to open in slits or chinks; to split; to cause the skin of to crack or become rough.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To strike, knock.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(often in the plural) The jaw.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One of the jaws or cheeks of a vice, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(authorship) One of the main sections into which the text of a book is divided.",
              synonyms: [],
              antonyms: [],
              example:
                "Detective novel writers try to keep up the suspense until the last chapter.",
            },
            {
              definition: "A section of a social or religious body.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sequence (of events), especially when presumed related and likely to continue.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A decretal epistle.", synonyms: [], antonyms: [] },
            {
              definition: "A location or compartment.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["ch.", "chpt."],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/chap",
        "https://en.wiktionary.org/wiki/chaps",
        "https://en.wiktionary.org/wiki/chapter",
      ],
    },
    {
      word: "chaps",
      phonetic: "/tʃæps/",
      phonetics: [{ text: "/tʃæps/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Protective leather leggings attached at the waist.",
              synonyms: [],
              antonyms: [],
              example: "Chaps were a costume staple of Westerns.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chaps"],
    },
  ],
  badge: [
    {
      word: "badge",
      phonetic: "/bædʒ/",
      phonetics: [
        {
          text: "/bædʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/badge-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89012740",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/bædʒ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A distinctive mark, token, sign, emblem or cognizance, worn on one's clothing, as an insignia of some rank, or of the membership of an organization.",
              synonyms: [],
              antonyms: [],
              example: "the badge of a society; the badge of a policeman",
            },
            {
              definition:
                "A small nameplate, identifying the wearer, and often giving additional information.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A card, sometimes with a barcode or magnetic strip, granting access to a certain area.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Something characteristic; a mark; a token.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(thieves' cant) A brand on the hand of a thief, etc.",
              synonyms: [],
              antonyms: [],
              example:
                "He has got his badge, and piked: He was burned in the hand, and is at liberty.",
            },
            {
              definition:
                "A carved ornament on the stern of a vessel, containing a window or the representation of one.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A distinctive mark worn by servants, retainers, and followers of royalty or nobility, who, being beneath the rank of gentlemen, have no right to armorial bearings.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small overlay on an icon that shows additional information about that item, such as the number of new alerts or messages.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An icon or emblem awarded to a user for some achievement.",
              synonyms: [],
              antonyms: [],
              example:
                "When you have checked in to the site from ten different cities, you unlock the Traveller badge.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To mark or distinguish with a badge.",
              synonyms: [],
              antonyms: [],
              example:
                "The television was badged as 'GE', but wasn't made by them.",
            },
            {
              definition: "To show a badge to.",
              synonyms: [],
              antonyms: [],
              example: "He calmed down a lot when the policeman badged him.",
            },
            {
              definition: "To enter a restricted area by showing one's badge.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/badge"],
    },
  ],
  stark: [
    {
      word: "stark",
      phonetic: "/stɑːk/",
      phonetics: [
        { text: "/stɑːk/", audio: "" },
        {
          text: "/stɑɹk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stark-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=49883065",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Hard, firm; obdurate.", synonyms: [], antonyms: [] },
            {
              definition:
                "Severe; violent; fierce (now usually in describing the weather).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Strong; vigorous; powerful.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Stiff, rigid.", synonyms: [], antonyms: [] },
            {
              definition: "Plain in appearance; barren, desolate.",
              synonyms: [],
              antonyms: [],
              example:
                "I picked my way forlornly through the stark, sharp rocks.",
            },
            {
              definition: "Complete, absolute, full.",
              synonyms: [],
              antonyms: [],
              example:
                "A flower was growing, in stark contrast, out of the sidewalk.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Starkly; entirely, absolutely",
              synonyms: [],
              antonyms: [],
              example: "He's gone stark, staring mad.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stark"],
    },
    {
      word: "stark",
      phonetic: "/stɑːk/",
      phonetics: [
        { text: "/stɑːk/", audio: "" },
        {
          text: "/stɑɹk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stark-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=49883065",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To stiffen.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stark"],
    },
  ],
  fairs: [
    {
      word: "fairs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A community gathering to celebrate and exhibit local achievements.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An event for public entertainment and trade, a market.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An event for professionals in a trade to learn of new products and do business, a trade fair.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A travelling amusement park (called a funfair in British English and a (travelling) carnival in US English).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fair",
        "https://en.wiktionary.org/wiki/fairs",
      ],
    },
  ],
  gully: [
    {
      word: "gully",
      phonetic: "/ˈɡʌli/",
      phonetics: [
        {
          text: "/ˈɡʌli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gully-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899613",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈɡʌli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gully-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50422627",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A trench, ravine or narrow channel which was worn by water flow, especially on a hillside.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A small valley.", synonyms: [], antonyms: [] },
            { definition: "A drop kerb.", synonyms: [], antonyms: [] },
            { definition: "A road drain.", synonyms: [], antonyms: [] },
            {
              definition:
                "A fielding position on the off side about 30 degrees behind square, between the slips and point; a fielder in such a position",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A grooved iron rail or tram plate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["gill"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To flow noisily.", synonyms: [], antonyms: [] },
            {
              definition: "To wear away into a gully or gullies.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gully"],
    },
    {
      word: "gully",
      phonetic: "/ˈɡʌli/",
      phonetics: [
        {
          text: "/ˈɡʌli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gully-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899613",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈɡʌli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gully-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50422627",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(northern UK) A large knife.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gully"],
    },
  ],
  mumps: [
    {
      word: "mumps",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A grimace.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To mumble, speak unclearly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To move the lips with the mouth closed; to mumble, as in sulkiness.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To beg, especially if using a repeated phrase.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To deprive of (something) by cheating; to impose upon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cheat; to deceive; to play the beggar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be sullen or sulky.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To nibble.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A cube of peat.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A contagious disease caused by the Mumps virus of the genus Rubulavirus, mostly occurring in childhood, which causes swelling of glands in the face and neck.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A gloomy or sullen silence.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mump",
        "https://en.wiktionary.org/wiki/mumps",
      ],
    },
  ],
  emery: [
    {
      word: "emery",
      phonetic: "/ˈɛməɹi/",
      phonetics: [
        { text: "/ˈɛməɹi/", audio: "" },
        { text: "/ˈɛməɹi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An impure type of corundum, often used for sanding or polishing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To sand or polish with emery.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To coat with emery.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/emery"],
    },
  ],
  filly: [
    {
      word: "filly",
      phonetic: "/ˈfɪli/",
      phonetics: [
        {
          text: "/ˈfɪli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/filly-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=500232",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A young female horse.", synonyms: [], antonyms: [] },
            {
              definition: "A young attractive female.",
              synonyms: [],
              antonyms: [],
              example: "Hey, Homer, get a load of the gams on that filly!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/filly"],
    },
  ],
  ovens: [
    {
      word: "ovens",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A chamber used for baking or heating.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/oven",
        "https://en.wiktionary.org/wiki/ovens",
      ],
    },
  ],
  drone: [
    {
      word: "drone",
      phonetic: "/dɹəʊn/",
      phonetics: [
        {
          text: "/dɹəʊn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/drone-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88681196",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/dɹoʊn/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A male ant, bee or wasp, which does not work but can fertilize the queen bee.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Someone who does not work; a lazy person, an idler.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One who performs menial or tedious work.",
              synonyms: ["drudge"],
              antonyms: [],
            },
            {
              definition:
                "A remotely controlled aircraft, an unmanned aerial vehicle (UAV).",
              synonyms: ["UAS", "UAV"],
              antonyms: [],
              example:
                "One team member launched a camera drone over the Third Pole.",
            },
          ],
          synonyms: ["UAS", "UAV", "drudge"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To kill with a missile fired by unmanned aircraft.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/drone"],
    },
    {
      word: "drone",
      phonetic: "/dɹəʊn/",
      phonetics: [
        {
          text: "/dɹəʊn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/drone-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88681196",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/dɹoʊn/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A low-pitched hum or buzz.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One of the fixed-pitch pipes on a bagpipe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A genre of music that uses repeated lengthy droning sounds.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A humming or deep murmuring sound.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To produce a low-pitched hum or buzz.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To speak in a monotone way.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/drone"],
    },
  ],
  gauze: [
    {
      word: "gauze",
      phonetic: "/ɡɔːz/",
      phonetics: [
        {
          text: "/ɡɔːz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gauze-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89844756",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A thin fabric with a loose, open weave.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A similar bleached cotton fabric used as a surgical dressing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A thin woven metal or plastic mesh.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Wire gauze, used as fence.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Mist or haze", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To apply a dressing of gauze",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To mist; to become gauze-like.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gauze"],
    },
  ],
  idiot: [
    {
      word: "idiot",
      phonetic: "/ˈɪd.i.(j)ɪt/",
      phonetics: [
        { text: "/ˈɪd.i.(j)ɪt/", audio: "" },
        {
          text: "/ˈɪd.i.(j)ɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/idiot-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769435",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A person of low general intelligence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person who makes stupid decisions; a fool.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person of the lowest intellectual standing, a person who lacks the capacity to develop beyond the mental age of a normal four-year-old; a person with an IQ below 30.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["genius"],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Idiotic, stupid", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/idiot"],
    },
  ],
  fussy: [
    {
      word: "fussy",
      phonetic: "/ˈfʌ.si/",
      phonetics: [{ text: "/ˈfʌ.si/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Anxious or particular about petty details.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Having a tendency to fuss, cry, or be bad-tempered/ill-tempered (especially of babies).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fussy"],
    },
  ],
  annoy: [
    {
      word: "annoy",
      phonetic: "/əˈnɔɪ/",
      phonetics: [
        {
          text: "/əˈnɔɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/annoy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769292",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A feeling of discomfort or vexation caused by what one dislikes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "That which causes such a feeling.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["annoyance"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To disturb or irritate, especially by continued or repeated acts; to bother with unpleasant deeds.",
              synonyms: [],
              antonyms: [],
              example:
                "Marc loved his sister, but when she annoyed him he wanted to switch her off.",
            },
            {
              definition:
                "To do something to upset or anger someone; to be troublesome.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To molest; to harm; to injure.",
              synonyms: [],
              antonyms: [],
              example:
                "to annoy an army by impeding its march, or by a cannonade",
            },
          ],
          synonyms: [
            "bother",
            "bug",
            "hassle",
            "irk",
            "irritate",
            "nag",
            "pester",
          ],
          antonyms: ["please"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/annoy"],
    },
  ],
  shank: [
    {
      word: "shank",
      phonetic: "/ʃæŋk/",
      phonetics: [
        {
          text: "/ʃæŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shank-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50423057",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The part of the leg between the knee and the ankle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Meat from that part of an animal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A redshank or greenshank, various species of Old World wading birds in the genus Tringa having distinctly colored legs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A straight, narrow part of an object, such as a key or an anchor; shaft; stem.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The handle of a pair of shears, connecting the ride to the neck.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The center part of a fishhook between the eye and the hook, the 'hook' being the curved part that bends toward the point.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A protruding part of an object, by which it is or can be attached.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The metal part on a curb bit that falls below the mouthpiece, which length controls the severity of the leverage action of the bit, and to which the reins of the bridle are attached.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A poorly played golf shot in which the ball is struck by the part of the club head that connects to the shaft.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An improvised stabbing weapon; a shiv.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A loop forming an eye to a button.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The space between two channels of the Doric triglyph.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A large ladle for molten metal, fitted with long bars for handling it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The body of a type; between the shoulder and the foot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(shoemaking) The part of the sole beneath the instep connecting the broader front part with the heel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Flat-nosed pliers, used by opticians for nipping off the edges of pieces of glass to make them round.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The end or remainder, particularly of a period of time.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The main part or beginning of a period of time.",
              synonyms: [],
              antonyms: [],
              example: "the shank of the morning",
            },
          ],
          synonyms: ["shiv"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To travel on foot.", synonyms: [], antonyms: [] },
            {
              definition: "To stab, especially with an improvised blade.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To remove another's trousers, especially in jest; to depants.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To misstrike the ball with the part of the club head that connects to the shaft.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To hit or kick the ball in an unintended direction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To fall off, as a leaf, flower, or capsule, on account of disease affecting the supporting footstalk; usually followed by off.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To provide (a button) with a shank (loop forming an eye).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(shoemaking) To apply the shank to a shoe, during the process of manufacturing it.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["depants", "shiv"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [{ definition: "Bad.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shank"],
    },
  ],
  gouge: [
    {
      word: "gouge",
      phonetic: "/ɡaʊdʒ/",
      phonetics: [
        { text: "/ɡaʊdʒ/", audio: "" },
        {
          text: "/ɡaʊdʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gouge-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=665075",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Senses relating to cutting tools.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cut or groove, as left by a gouge or something sharp.",
              synonyms: [],
              antonyms: [],
              example: "The nail left a deep gouge in the tire.",
            },
            {
              definition: "(originally United States) An act of gouging.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A cheat, a fraud; an imposition.",
              synonyms: ["swindle"],
              antonyms: [],
            },
            { definition: "An impostor.", synonyms: [], antonyms: [] },
            {
              definition:
                "Soft material lying between the wall of a vein and the solid vein of ore.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Information.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["swindle"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make a groove, hole, or mark in by scooping with or as if with a gouge.",
              synonyms: ["engrave", "grave", "incise"],
              antonyms: [],
              example:
                "Japanese and Chinese printers used to gouge characters in wood.",
            },
            {
              definition:
                "To cheat or impose upon; in particular, to charge an unfairly or unreasonably high price.",
              synonyms: ["defraud", "swindle"],
              antonyms: [],
              example:
                "The company has no competition, so it tends to gouge its customers.",
            },
            {
              definition:
                "To dig or scoop (something) out with or as if with a gouge; in particular, to use a thumb to push or try to push the eye (of a person) out of its socket.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To use a gouge.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["defraud", "swindle", "engrave", "grave", "incise"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gouge"],
    },
  ],
  bleed: [
    {
      word: "bleed",
      phonetic: "/ˈbliːd/",
      phonetics: [{ text: "/ˈbliːd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An incident of bleeding, as in haemophilia.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A narrow edge around a page layout, to be printed but cut off afterwards (added to allow for slight misalignment, especially with pictures that should run to the edge of the finished sheet).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(sound recording) The situation where sound is picked up by a microphone from a source other than that which is intended.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The removal of air bubbles from a pipe containing other fluids.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(of a person or animal) To lose blood through an injured blood vessel.",
              synonyms: [],
              antonyms: [],
              example: "If her nose bleeds, try to use ice.",
            },
            {
              definition: "To let or draw blood from.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To take large amounts of money from.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To steadily lose (something vital).",
              synonyms: [],
              antonyms: [],
              example: "The company was bleeding talent.",
            },
            {
              definition:
                "(of an ink or dye) To spread from the intended location and stain the surrounding cloth or paper.",
              synonyms: [],
              antonyms: [],
              example: "Ink traps counteract bleeding.",
            },
            {
              definition:
                "To remove air bubbles from a pipe containing other fluids.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To bleed on; to make bloody.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To show one's group loyalty by showing (its associated color) in one's blood.",
              synonyms: [],
              antonyms: [],
              example: "He was a devoted Vikings fan: he bled purple.",
            },
            {
              definition: "To lose sap, gum, or juice.",
              synonyms: [],
              antonyms: [],
              example: "A tree or a vine bleeds when tapped or wounded.",
            },
            {
              definition:
                "To issue forth, or drop, like blood from an incision.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a phonological rule) To destroy the environment where another phonological rule would have applied.",
              synonyms: [],
              antonyms: [],
              example: "Labialization bleeds palatalization.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bleed"],
    },
  ],
  elves: [
    {
      word: "elves",
      phonetic: "/ɛlvz/",
      phonetics: [{ text: "/ɛlvz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A luminous spirit presiding over nature and fertility and dwelling in the world of Álfheim (Elfland). Compare angel, nymph, fairy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any from a race of mythical, supernatural beings resembling but seen as distinct from human beings. They are usually delicate-featured and skilled in magic or spellcrafting; sometimes depicted as clashing with dwarves, especially in modern fantasy literature.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of the magical, typically forest-guarding races bearing some similarities to the Norse álfar (through Tolkien's Eldar).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A very diminutive person; a dwarf.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The bluefish (Pomatomus saltatrix).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["fairy", "goblin"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An upper atmospheric optical phenomenon associated with thunderstorms, rapidly expanding disk-shaped regions of luminosity, lasting less than a thousandth of a second, which occur high above energetic cloud-to-ground lightning of positive or negative polarity",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/elf",
        "https://en.wiktionary.org/wiki/elve",
        "https://en.wiktionary.org/wiki/elves",
      ],
    },
  ],
  roped: [
    {
      word: "roped",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To tie (something) with rope.",
              synonyms: [],
              antonyms: [],
              example: "The robber roped the victims.",
            },
            {
              definition:
                "To throw a rope (or something similar, e.g. a lasso, cable, wire, etc.) around (something).",
              synonyms: [],
              antonyms: [],
              example: "The cowboy roped the calf.",
            },
            {
              definition:
                "To be formed into rope; to draw out or extend into a filament or thread.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To commit suicide.",
              synonyms: [],
              antonyms: [],
              example: "My life is a mess; I might as well rope.",
            },
          ],
          synonyms: ["lasso", "bind", "secure", "tie"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rope",
        "https://en.wiktionary.org/wiki/roped",
      ],
    },
  ],
  unfit: [
    {
      word: "unfit",
      phonetic: "/ʌnˈfɪt/",
      phonetics: [
        { text: "/ʌnˈfɪt/", audio: "" },
        { text: "/ʌnˈfɪt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make unfit; to render unsuitable, spoil, disqualify.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Not fit; not having the correct requirements.",
              synonyms: [],
              antonyms: [],
              example: "Jack cannot run, making him unfit for the track team.",
            },
            {
              definition: "Not fit, not having a good physical demeanor.",
              synonyms: [],
              antonyms: [],
              example: "I've become so unfit after stopping cycling to town.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/unfit"],
    },
  ],
  baggy: [
    {
      word: "baggy",
      phonetic: "/ˈbæɡi/",
      phonetics: [{ text: "/ˈbæɡi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Of clothing, very loose-fitting, so as to hang away from the body.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of or relating to a British music genre of the 1980s and 1990s, influenced by Madchester and psychedelia and associated with baggy clothing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of writing, etc.: overwrought; flabby; having too much padding.",
              synonyms: [],
              antonyms: [],
              example: "a baggy book",
            },
          ],
          synonyms: ["loose", "saggy"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/baggy"],
    },
    {
      word: "baggy",
      phonetic: "/ˈbæɡi/",
      phonetics: [{ text: "/ˈbæɡi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A small plastic bag, as for sandwiches.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/baggy"],
    },
  ],
  mower: [
    {
      word: "mower",
      phonetic: "/ˈməʊ.ə(ɹ)/",
      phonetics: [
        {
          text: "/ˈməʊ.ə(ɹ)/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mower-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1160109",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A lawnmower, a machine used to cut grass.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person who cuts grass.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mower"],
    },
  ],
  scant: [
    {
      word: "scant",
      phonetic: "/skænt/",
      phonetics: [
        {
          text: "/skænt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scant-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651464",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Very little, very few.",
              synonyms: [],
              antonyms: [],
              example:
                "After his previous escapades, Mary had scant reason to believe John.",
            },
            {
              definition:
                "Not full, large, or plentiful; scarcely sufficient; scanty; meager; not enough.",
              synonyms: [],
              antonyms: [],
              example:
                "a scant allowance of provisions or water; a scant pattern of cloth for a garment",
            },
            {
              definition: "Sparing; parsimonious; chary.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["geason", "few", "little", "slight"],
          antonyms: ["ample", "plenty"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scant"],
    },
    {
      word: "scant",
      phonetic: "/skænt/",
      phonetics: [
        {
          text: "/skænt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scant-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651464",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To limit in amount or share; to stint.",
              synonyms: [],
              antonyms: [],
              example:
                "to scant someone in provisions; to scant ourselves in the use of necessaries",
            },
            {
              definition: "To fail, or become less; to scantle.",
              synonyms: [],
              antonyms: [],
              example: "The wind scants.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scant"],
    },
    {
      word: "scant",
      phonetic: "/skænt/",
      phonetics: [
        {
          text: "/skænt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scant-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651464",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A block of stone sawn on two sides down to the bed level.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A sheet of stone.", synonyms: [], antonyms: [] },
            {
              definition:
                "(wood) A slightly thinner measurement of a standard wood size.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scant"],
    },
    {
      word: "scant",
      phonetic: "/skænt/",
      phonetics: [
        {
          text: "/skænt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scant-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651464",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Scarcity; lack.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "With difficulty; scarcely; hardly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scant"],
    },
  ],
  grabs: [
    {
      word: "grabs",
      phonetic: "/ɡɹæbz/",
      phonetics: [{ text: "/ɡɹæbz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A sudden snatch at something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An acquisition by violent or unjust means.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A mechanical device that grabs or clutches.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A sound bite.", synonyms: [], antonyms: [] },
            { definition: "That which is seized.", synonyms: [], antonyms: [] },
            { definition: "A simple card game.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["catch", "clutch", "grasp", "seize", "snatch"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To grip suddenly; to seize; to clutch.",
              synonyms: [],
              antonyms: [],
              example:
                "I grabbed her hand to pull her back from the cliff edge.",
            },
            {
              definition:
                "To make a sudden grasping or clutching motion (at something).",
              synonyms: [],
              antonyms: [],
              example:
                "The suspect suddenly broke free and grabbed at the policeman's gun.",
            },
            {
              definition: "To restrain someone; to arrest.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To grip the attention of; to enthrall or interest.",
              synonyms: [],
              antonyms: [],
              example: "How does that idea grab you?",
            },
            {
              definition: "To quickly collect or retrieve.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To consume something quickly.",
              synonyms: [],
              antonyms: [],
              example: "Is there time to grab a coffee?",
            },
            {
              definition: "To take the opportunity of.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A two- or three-masted vessel used on the Malabar coast.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/grab",
        "https://en.wiktionary.org/wiki/grabs",
      ],
    },
  ],
  fleas: [
    {
      word: "fleas",
      phonetic: "/ˈfliːz/",
      phonetics: [{ text: "/ˈfliːz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cause to fly; put to flight; drive off (by frightening).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To frighten; scare; terrify.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To be fear-stricken.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small, wingless, parasitic insect of the order Siphonaptera, renowned for its bloodsucking habits and jumping abilities.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A thing of no significance.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To remove fleas from (an animal).",
              synonyms: ["deflea"],
              antonyms: [],
            },
          ],
          synonyms: ["deflea"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/flay",
        "https://en.wiktionary.org/wiki/flea",
        "https://en.wiktionary.org/wiki/fleas",
      ],
    },
  ],
  lousy: [
    {
      word: "lousy",
      phonetic: "/laʊ.zi/",
      phonetics: [{ text: "/laʊ.zi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Remarkably bad; of poor quality, dirty, or underhanded; mean, contemptible.",
              synonyms: ["miserable"],
              antonyms: [],
              example: "No offense, but his cooking is lousy.",
            },
            { definition: "Infested with lice.", synonyms: [], antonyms: [] },
            {
              definition:
                "(usually with with) Filled or packed (with something, usually bad).",
              synonyms: ["inundated", "jam-packed", "teeming"],
              antonyms: [],
              example: "The place was lousy with students.",
            },
          ],
          synonyms: ["inundated", "jam-packed", "teeming", "miserable"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lousy"],
    },
  ],
  album: [
    {
      word: "album",
      phonetic: "/ˈælbəm/",
      phonetics: [{ text: "/ˈælbəm/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A book specially designed to keep photographs, stamps, or autographs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A collection, especially of literary items",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A phonograph record that is composed of several tracks",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A jacket or cover for such a phonograph record.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A group of audio recordings, on any medium, intended for distribution as a group.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In Ancient Rome, a white tablet or register on which the praetor's edicts and other public notices were recorded.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["LP", "disc", "disk", "long-playing"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/album"],
    },
  ],
  sawed: [
    {
      word: "sawed",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cut (something) with a saw.",
              synonyms: [],
              antonyms: [],
              example:
                "They were stoned, they were sawn asunder, were tempted, were slain with the sword: they wandered about in sheepskins and goatskins; being destitute, afflicted, tormented;",
            },
            {
              definition:
                "To make a motion back and forth similar to cutting something with a saw.",
              synonyms: [],
              antonyms: [],
              example: "The fiddler sawed away at his instrument.",
            },
            {
              definition: "To be cut with a saw.",
              synonyms: [],
              antonyms: [],
              example: "The timber saws smoothly.",
            },
            {
              definition:
                "To form or produce (something) by cutting with a saw.",
              synonyms: [],
              antonyms: [],
              example:
                "to saw boards or planks (i.e. to saw logs or timber into boards or planks)",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/saw",
        "https://en.wiktionary.org/wiki/sawed",
      ],
    },
  ],
  cooky: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  murky: [
    {
      word: "murky",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/murky-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780236",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Hard to see through, as a fog or mist.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Dark, dim, gloomy.", synonyms: [], antonyms: [] },
            {
              definition: "Cloudy, indistinct, obscure.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Dishonest, shady.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["dark"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/murky"],
    },
  ],
  infer: [
    {
      word: "infer",
      phonetic: "/ɪnˈfɜː/",
      phonetics: [
        { text: "/ɪnˈfɜː/", audio: "" },
        {
          text: "/ɪnˈfɝ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/infer-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453544",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To introduce (something) as a reasoned conclusion; to conclude by reasoning or deduction, as from premises or evidence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To lead to (something) as a consequence; to imply. (Now often considered incorrect, especially with a person as subject.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause, inflict (something) upon or to someone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To introduce (a subject) in speaking, writing etc.; to bring in.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["assume", "conclude", "construe", "deduce", "educe"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/infer"],
    },
  ],
  burly: [
    {
      word: "burly",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/burly-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79293237",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈbɜːli/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "(usually of a man) Large, well-built, and muscular.",
              synonyms: [],
              antonyms: [],
              example:
                "He’s a big, burly rugby player who works as a landscape gardener.",
            },
            {
              definition: "(East End of London) Great, amazing, unbelievable.",
              synonyms: [],
              antonyms: [],
              example: "Kimi Räikkönen is a burly Formula 1 driver.",
            },
            {
              definition:
                "(surf culture and/or Southern California) Of large magnitude, either good or bad, and sometimes both.",
              synonyms: [],
              antonyms: [],
              example:
                "That wave was burly! (i.e. large, dangerous and difficult to ride)",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/burly"],
    },
    {
      word: "burly",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/burly-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79293237",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈbɜːli/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Full of burls or knots; knotty.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/burly"],
    },
  ],
  waged: [
    {
      word: "waged",
      phonetic: "/weɪdʒd/",
      phonetics: [{ text: "/weɪdʒd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To wager, bet.", synonyms: [], antonyms: [] },
            {
              definition:
                "To expose oneself to, as a risk; to incur, as a danger; to venture; to hazard.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To employ for wages; to hire.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To conduct or carry out (a war or other contest).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To adventure, or lay out, for hire or reward; to hire out.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To give security for the performance of.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wage",
        "https://en.wiktionary.org/wiki/waged",
      ],
    },
  ],
  dingy: [
    {
      word: "dingy",
      phonetic: "/ˈdɪn.dʒi/",
      phonetics: [
        {
          text: "/ˈdɪn.dʒi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dingy-1-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=83326230",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Drab; shabby; dirty; squalid",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dismal", "drab", "dreary", "gloomy", "grimy"],
          antonyms: ["bright", "clean"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dingy"],
    },
    {
      word: "dingy",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small open boat, propelled by oars or paddles, carried as a tender, lifeboat, or pleasure craft on a ship.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An inflatable rubber life raft.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["tender"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dinghy",
        "https://en.wiktionary.org/wiki/dingy",
      ],
    },
  ],
  brine: [
    {
      word: "brine",
      phonetic: "/bɹaɪn/",
      phonetics: [
        {
          text: "/bɹaɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/brine-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=60611387",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Salt water; water saturated or strongly impregnated with salt; a salt-and-water solution for pickling.",
              synonyms: [],
              antonyms: [],
              example: "Do you want a can of tuna in oil or in brine?",
            },
            {
              definition: "The sea or ocean; the water of the sea.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To preserve food in a salt solution.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To prepare and flavor food (especially meat) for cooking by soaking in a salt solution.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["debrine", "desalinate"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/brine"],
    },
  ],
  kneel: [
    {
      word: "kneel",
      phonetic: "/niːl/",
      phonetics: [
        {
          text: "/niːl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/kneel-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453569",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To rest on one's bent knees, sometimes only one; to move to such a position.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause to kneel.",
              synonyms: [],
              antonyms: [],
              example: "She knelt the doll to fit it into the box.",
            },
            {
              definition: "To rest on (one's) knees",
              synonyms: [],
              antonyms: [],
              example: "He knelt him down to pray.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/kneel"],
    },
  ],
  creak: [
    {
      word: "creak",
      phonetic: "/kɹiːk/",
      phonetics: [
        {
          text: "/kɹiːk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/creak-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88897866",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The sound produced by anything that creaks; a creaking.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make a prolonged sharp grating or squeaking sound, as by the friction of hard substances.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To produce a creaking sound with.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To suffer from strain or old age.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/creak"],
    },
  ],
  vanes: [
    {
      word: "vanes",
      phonetic: "/veɪnz/",
      phonetics: [{ text: "/veɪnz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A weather vane", synonyms: [], antonyms: [] },
            {
              definition:
                "Any of several usually relatively thin, rigid, flat, or sometimes curved surfaces radially mounted along an axis, as a blade in a turbine or a sail on a windmill, that is turned by or used to turn a fluid",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The flattened, web-like part of a feather, consisting of a series of barbs on either side of the shaft",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sight on a sextant or compass",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the metal guidance or stabilizing fins attached to the tail of a bomb or other missile",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/vane",
        "https://en.wiktionary.org/wiki/vanes",
      ],
    },
  ],
  smoky: [
    {
      word: "smoky",
      phonetic: "/ˈsmoʊki/",
      phonetics: [{ text: "/ˈsmoʊki/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Filled with smoke.",
              synonyms: [],
              antonyms: [],
              example: "a smoky cabin",
            },
            {
              definition: "Giving off smoke.",
              synonyms: [],
              antonyms: [],
              example: "a smoky oil lamp",
            },
            {
              definition:
                "Of a colour or colour pattern similar to that of smoke.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Having a flavour or odour like smoke; flavoured with smoke.",
              synonyms: [],
              antonyms: [],
              example: "a smoky whisky",
            },
            {
              definition: "Resembling or composed of smoke.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Blackened by smoke.", synonyms: [], antonyms: [] },
            {
              definition:
                "(of a person's voice) Having a deep, raspy quality, often as a result of smoking tobacco.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Attractive in a sensual way; sultry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Having a dark, thick, bass sound.",
              synonyms: [],
              antonyms: [],
              example: "a few smoky jazz notes",
            },
            {
              definition: "Giving off steam or vapour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Obscuring or insubstantial like smoke.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Suspicious; open to suspicion; jealous.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["fumid", "fumous"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/smoky"],
    },
  ],
  spurt: [
    {
      word: "spurt",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spurt-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241790",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/spɜː(r)t/", audio: "" },
        { text: "/spɝt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A brief gush, as of liquid spurting from an orifice or a cut/wound.",
              synonyms: [],
              antonyms: [],
              example: "a spurt of water; a spurt of blood",
            },
            { definition: "Ejaculation of semen.", synonyms: [], antonyms: [] },
            { definition: "A shoot; a bud.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["spout"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cause to gush out suddenly or violently in a stream or jet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To rush from a confined place in a small stream or jet.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spurt"],
    },
    {
      word: "spurt",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spurt-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241790",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/spɜː(r)t/", audio: "" },
        { text: "/spɝt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A moment, a short period of time.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sudden brief burst of, or increase in, speed, effort, activity, emotion or development.",
              synonyms: [],
              antonyms: [],
              example: "The boss's visit prompted a brief spurt of activity.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make a strong effort for a short period of time.",
              synonyms: [],
              antonyms: [],
              example: "The bullion market spurted on Thursday.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spurt"],
    },
  ],
  combs: [
    {
      word: "combs",
      phonetic: "/kəʊmz/",
      phonetics: [
        {
          text: "/kəʊmz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/combs-1-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88902515",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A toothed implement for grooming the hair or (formerly) for keeping it in place.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A machine used in separating choice cotton fibers from worsted cloth fibers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A fleshy growth on the top of the head of some birds and reptiles; crest.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A structure of hexagon cells made by bees for storing honey; honeycomb.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An old English measure of corn equal to the half quarter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The top part of a gun’s stock.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The toothed plate at the top and bottom of an escalator that prevents objects getting trapped between the moving stairs and fixed landings.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The main body of a harmonica containing the air chambers and to which the reed plates are attached.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A former, commonly cone-shaped, used in hat manufacturing for hardening soft fibre.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A toothed tool used for chasing screws on work in a lathe; a chaser.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The notched scale of a wire micrometer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The collector of an electrical machine, usually resembling a comb.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of a pair of peculiar organs on the base of the abdomen in scorpions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The curling crest of a wave; a comber.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A toothed plate used for creating wells in agar gels for electrophoresis.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A toothed wooden pick used to push the weft thread tightly against the previous pass of thread to create a tight weave.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A connected and reduced curve with irreducible components consisting of a smooth subcurve (called the handle) and one or more additional irreducible components (called teeth) that each intersect the handle in a single point that is unequal to the unique point of intersection for any of the other teeth.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["cockscomb", "crest"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(especially of hair or fur) To groom with a toothed implement; chiefly with a comb.",
              synonyms: [],
              antonyms: [],
              example: "I need to comb my hair before we leave the house",
            },
            {
              definition:
                "To separate choice cotton fibers from worsted cloth fibers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To search thoroughly as if raking over an area with a comb.",
              synonyms: [],
              antonyms: [],
              example: "Police combed the field for evidence after the assault",
            },
            {
              definition:
                "To roll over, as the top or crest of a wave; to break with a white foam, as waves.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(abbreviation) Combination.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A valley, often wooded and often with no river",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A cirque.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/comb",
        "https://en.wiktionary.org/wiki/combe",
        "https://en.wiktionary.org/wiki/combs",
      ],
    },
  ],
  easel: [
    {
      word: "easel",
      phonetic: "/ˈiː.z(ə)l/",
      phonetics: [
        {
          text: "/ˈiː.z(ə)l/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/easel-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88675074",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈi.zəl/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An upright frame, typically on three legs, for displaying or supporting something, such as an artist's canvas.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/easel"],
    },
  ],
  laces: [
    {
      word: "laces",
      phonetic: "/ˈleɪ.sɪz/",
      phonetics: [{ text: "/ˈleɪ.sɪz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A light fabric containing patterns of holes, usually built up from a single thread. Wp",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cord or ribbon passed through eyelets in a shoe or garment, pulled tight and tied to fasten the shoe or garment firmly. Wp",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A snare or gin, especially one made of interwoven cords; a net.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Spirits added to coffee or another beverage.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To fasten (something) with laces.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To add alcohol, poison, a drug or anything else potentially harmful to (food or drink).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To interweave items.",
              synonyms: [],
              antonyms: [],
              example: "to lace one's fingers together",
            },
            {
              definition: "To interweave the spokes of a bicycle wheel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To beat; to lash; to make stripes on.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To adorn with narrow strips or braids of some decorative material.",
              synonyms: [],
              antonyms: [],
              example: "cloth laced with silver",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lace",
        "https://en.wiktionary.org/wiki/laces",
      ],
    },
  ],
  humps: [
    {
      word: "humps",
      phonetic: "/hʌmps/",
      phonetics: [{ text: "/hʌmps/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A mound of earth.", synonyms: [], antonyms: [] },
            {
              definition:
                "A deformity in humans caused by abnormal curvature of the upper spine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(animals) A rounded fleshy mass, such as on a camel or zebu.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An act of sexual intercourse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(with definite article) A bad mood.",
              synonyms: [],
              antonyms: [],
              example:
                "get the hump, have the hump, take the hump, give someone the hump",
            },
            {
              definition: "A painfully boorish person.",
              synonyms: [],
              antonyms: [],
              example: "That guy is such a hump!",
            },
            { definition: "A speed hump.", synonyms: [], antonyms: [] },
            {
              definition:
                "A wave that forms in front of an operating hovercraft and impedes progress at low speeds.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["gibbous", "humpback", "hunch", "hunchback"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To bend something into a hump.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To carry (something), especially with some exertion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To rhythmically thrust the pelvis in a manner conducive to sexual intercourse",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To prepare for a great exertion; to put forth effort.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To vex or annoy.", synonyms: [], antonyms: [] },
            {
              definition:
                "To shunt wagons / freight cars over the hump in a hump yard.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "heft",
            "shoulder",
            "tote",
            "hike",
            "trek",
            "walk",
            "bang",
            "bone",
            "ride",
            "shag",
            "bother",
            "irk",
            "rile",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hump",
        "https://en.wiktionary.org/wiki/humps",
      ],
    },
  ],
  rumor: [
    {
      word: "rumor",
      phonetic: "/ˈɹuːmə(ɹ)/",
      phonetics: [
        { text: "/ˈɹuːmə(ɹ)/", audio: "" },
        { text: "/ˈɹumɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A statement or claim of questionable accuracy, from no known reliable source, usually spread by word of mouth.",
              synonyms: [],
              antonyms: [],
              example:
                "There's a rumor going round that he's going to get married.",
            },
            {
              definition:
                "Information or misinformation of the kind contained in such claims.",
              synonyms: [],
              antonyms: [],
              example: "They say he used to be a thief, but that's just rumor.",
            },
          ],
          synonyms: ["gossip", "hearsay", "talk", "tittle-tattle"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(usually used in the passive voice) To tell a rumor about; to gossip.",
              synonyms: [],
              antonyms: [],
              example: "John is rumored to be next in line for a promotion.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rumor"],
    },
  ],
  aroma: [
    {
      word: "aroma",
      phonetic: "/əˈɹəʊmə/",
      phonetics: [
        { text: "/əˈɹəʊmə/", audio: "" },
        {
          text: "/əˈɹoʊmə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/aroma-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50422595",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A smell; especially a pleasant spicy or fragrant one.",
              synonyms: [],
              antonyms: [],
              example: "I love the aroma of cinnamon.",
            },
          ],
          synonyms: ["fragrance", "nosegay", "scent"],
          antonyms: ["odor", "odour", "pungency", "reek", "stench"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/aroma"],
    },
  ],
  horde: [
    {
      word: "horde",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/horde-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75803267",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/hɔːd/", audio: "" },
        {
          text: "/hɔɹd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/horde-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2526696",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A wandering troop or gang; especially, a clan or tribe of a nomadic people (originally Tatars) migrating from place to place for the sake of pasturage, plunder, etc.; a predatory multitude.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A large number of people.",
              synonyms: [],
              antonyms: [],
              example:
                "We were beset by a horde of street vendors who thought we were tourists and would buy their cheap souvenirs.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To travel en masse, to flock",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/horde"],
    },
  ],
  swiss: [
    {
      word: "swiss",
      phonetic: "/swɪs/",
      phonetics: [{ text: "/swɪs/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To prepare (meat, fabric, etc.) by rolling or pounding in order to soften it.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/swiss"],
    },
  ],
  leapt: [
    {
      word: "leapt",
      phonetic: "/lɛpt/",
      phonetics: [
        {
          text: "/lɛpt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/leapt-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453577",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To jump.", synonyms: [], antonyms: [] },
            {
              definition: "To pass over by a leap or jump.",
              synonyms: [],
              antonyms: [],
              example: "to leap a wall or a ditch",
            },
            {
              definition: "To copulate with (a female beast); to cover.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause to leap.",
              synonyms: [],
              antonyms: [],
              example: "to leap a horse across a ditch",
            },
          ],
          synonyms: [
            "bound",
            "hop",
            "jump",
            "spring",
            "bound",
            "hop",
            "jump",
            "spring",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/leap",
        "https://en.wiktionary.org/wiki/leapt",
      ],
    },
  ],
  opium: [
    {
      word: "opium",
      phonetic: "/ˈəʊpi.əm/",
      phonetics: [
        { text: "/ˈəʊpi.əm/", audio: "" },
        { text: "/ˈoʊpi.əm/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A yellow-brown, addictive narcotic drug obtained from the dried juice of unripe pods of the opium poppy, Papaver somniferum, and containing alkaloids such as morphine, codeine, and papaverine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Anything that numbs or stupefies.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/opium"],
    },
  ],
  slime: [
    {
      word: "slime",
      phonetic: "/slaɪm/",
      phonetics: [
        {
          text: "/slaɪm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/slime-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88671361",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Soft, moist earth or clay, having an adhesive quality; viscous mud; any substance of a dirty nature, that is moist, soft, and adhesive; bitumen; mud containing metallic ore, obtained in the preparatory dressing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any mucilaginous substance; or a mucus-like substance which exudes from the bodies of certain animals, such as snails or slugs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sneaky, unethical person; a slimeball.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A monster having the form of a slimy blob.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Human flesh, seen disparagingly; mere human form.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Jew’s slime (bitumen)", synonyms: [], antonyms: [] },
            { definition: "Friend, homie", synonyms: [], antonyms: [] },
          ],
          synonyms: ["sludge"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To coat with slime.", synonyms: [], antonyms: [] },
            {
              definition: "To besmirch or disparage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To carve (fish), removing the offal.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/slime"],
    },
  ],
  afire: [
    {
      word: "afire",
      phonetic: "/əˈfaɪə(ɹ)/",
      phonetics: [{ text: "/əˈfaɪə(ɹ)/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "On fire (often metaphorically).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["ablaze", "aflame"],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "On fire (often metaphorically).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/afire"],
    },
  ],
  pansy: [
    {
      word: "pansy",
      phonetic: "/ˈpæn.zi/",
      phonetics: [
        {
          text: "/ˈpæn.zi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pansy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241704",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A cultivated flowering plant, derived by hybridization within species Viola tricolor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A deep purple colour, like that of the pansy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various nymphalid butterflies of the genus Junonia. Also called arguses.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A male homosexual, especially one who is effeminate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A timid, weak man or boy; a wuss.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "mama's boy",
            "sissy",
            "friend of Dorothy",
            "omi-palone",
            "mama's boy",
            "nancy boy",
            "sissy",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                '(usually with "around" or "about") To mess about; to fail to get things done.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Wimpy; spineless; feeble.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of a deep purple colour, like that of the pansy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pansy"],
    },
  ],
  mares: [
    {
      word: "mares",
      phonetic: "/mɛəɹz/",
      phonetics: [
        {
          text: "/mɛəɹz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mares-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=61084151",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An adult female horse.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A foolish woman.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: ["colt", "gelding", "stallion", "stud"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A type of evil spirit formerly thought to sit on the chest of a sleeping person; also, the feeling of suffocation felt during sleep, attributed to such a spirit.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A demon or monster, thought to plague people while they slept and cause a feeling of suffocation and terror during sleep.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Sleep paralysis.", synonyms: [], antonyms: [] },
            {
              definition: "A very bad or frightening dream.",
              synonyms: [],
              antonyms: [],
              example:
                "I had a nightmare that I tried to run but could neither move nor breathe.",
            },
            {
              definition:
                "Any bad, miserable, difficult or terrifying situation or experience that arouses anxiety, terror, agony or great displeasure.",
              synonyms: [],
              antonyms: [],
              example:
                "Cleaning up after identity theft can be a nightmare of phone calls and letters.",
            },
          ],
          synonyms: ["incubus", "night hag", "succubus"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mare",
        "https://en.wiktionary.org/wiki/mares",
        "https://en.wiktionary.org/wiki/nightmare",
      ],
    },
  ],
  soaps: [
    {
      word: "soaps",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A substance able to mix with both oil and water, used for cleaning, often in the form of a solid bar or in liquid form, derived from fats or made synthetically.",
              synonyms: [],
              antonyms: [],
              example:
                "I tried washing my hands with soap, but the stain wouldn't go away.",
            },
            {
              definition: "A metallic salt derived from a fatty acid",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Flattery or excessively complacent conversation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Money, specially when used as a bribe.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A soap opera.", synonyms: [], antonyms: [] },
            {
              definition:
                "A solid masonry unit or brick reduced in depth or height from standard dimensions.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To apply soap to in washing.",
              synonyms: [],
              antonyms: [],
              example: "Be sure to soap yourself well before rinsing.",
            },
            {
              definition:
                "To cover, lather or in any other form treat with soap, often as a prank.",
              synonyms: [],
              antonyms: [],
              example: "Those kids soaped my windows!",
            },
            {
              definition: "To be discreet about (a topic).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To flatter; to wheedle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["downplay", "soft soap", "soft-pedal", "sugar soap"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/soap",
        "https://en.wiktionary.org/wiki/soaps",
      ],
    },
  ],
  husks: [
    {
      word: "husks",
      phonetic: "/hʌsks/",
      phonetics: [{ text: "/hʌsks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The dry, leafy or stringy exterior of certain vegetables or fruits, which must be removed before eating the meat inside",
              synonyms: [],
              antonyms: [],
              example: "A coconut has a very thick husk.",
            },
            {
              definition:
                "Any form of useless, dried-up, and subsequently worthless exterior of something",
              synonyms: [],
              antonyms: [],
              example: "His attorney was a dried-up husk of a man.",
            },
            {
              definition: "The supporting frame of a run of millstones.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To remove husks from.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cough, clear one's throat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To say huskily, to utter in a husky voice.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/husk",
        "https://en.wiktionary.org/wiki/husks",
      ],
    },
  ],
  snips: [
    {
      word: "snips",
      phonetic: "/snɪps/",
      phonetics: [{ text: "/snɪps/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The act of snipping; cutting a small amount off of something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A single cut with scissors, clippers, or similar tool.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Something acquired for a low price; a bargain.",
              synonyms: [],
              antonyms: [],
              example: "That wholesale lot on eBay was a snip at $10",
            },
            {
              definition: "A small amount of something; a pinch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(definite, the snip) A vasectomy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A small or weak person, especially a young one.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An impertinent or mischievous person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A share or portion; a snack.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A tailor.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cut with short sharp actions, as with scissors.",
              synonyms: [],
              antonyms: [],
              example:
                "I don't want you to take much hair off; just snip my mullet off.",
            },
            {
              definition: "To reduce the price of a product, to create a snip.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To break off; to snatch away.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To circumcise.", synonyms: [], antonyms: [] },
            {
              definition:
                "To remove the irrelevant parts of quotations in the reply message.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/snip",
        "https://en.wiktionary.org/wiki/snips",
      ],
    },
  ],
  hazel: [
    {
      word: "hazel",
      phonetic: "/ˈheɪzəl/",
      phonetics: [
        {
          text: "/ˈheɪzəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hazel-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762698",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A tree or shrub of the genus Corylus, bearing edible nuts called hazelnuts or filberts.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The nut of the hazel tree.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The wood of a hazelnut tree.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A greenish-brown colour, the colour of a ripe hazelnut.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Freestone", synonyms: [], antonyms: [] },
          ],
          synonyms: ["filbert", "hazelnut"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Of a greenish-brown colour. (often used to refer to eye colour)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hazel"],
    },
  ],
  lined: [
    {
      word: "lined",
      phonetic: "/laɪnd/",
      phonetics: [
        {
          text: "/laɪnd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lined-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651139",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                'To place (objects) into a line (usually used with "up"); to form into a line; to align.',
              synonyms: [],
              antonyms: [],
              example: "to line troops",
            },
            {
              definition:
                "To place persons or things along the side of for security or defense; to strengthen by adding; to fortify.",
              synonyms: [],
              antonyms: [],
              example: "to line works with soldiers",
            },
            { definition: "To form a line along.", synonyms: [], antonyms: [] },
            {
              definition: "To mark with a line or lines, to cover with lines.",
              synonyms: [],
              antonyms: [],
              example: "to line a copy book",
            },
            {
              definition: "To represent by lines; to delineate; to portray.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To read or repeat line by line.",
              synonyms: [],
              antonyms: [],
              example: "to line out a hymn",
            },
            {
              definition:
                "To hit a line drive; to hit a line drive which is caught for an out. Compare fly and ground.",
              synonyms: [],
              antonyms: [],
              example: "Jones lined to left in his last at-bat.",
            },
            {
              definition:
                "To track (wild bees) to their nest by following their line of flight.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To measure.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cover the inner surface of (something), originally especially with linen.",
              synonyms: [],
              antonyms: [],
              example: "The bird lines its nest with soft grass.",
            },
            {
              definition:
                "To reinforce (the back of a book) with glue and glued scrap material such as fabric or paper.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To fill or supply (something), as a purse with money.",
              synonyms: [],
              antonyms: [],
              example: "to line the shelves",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(of a dog) To copulate with, to impregnate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having a lining, an inner layer or covering.",
              synonyms: [],
              antonyms: [],
              example: "Fred liked fully lined trousers with his suits.",
            },
            {
              definition: "(of paper) Having lines, ruled.",
              synonyms: [],
              antonyms: [],
              example:
                "For handwritten work Fred preferred lined paper to plain.",
            },
            {
              definition: "(of skin) Having visible lines or wrinkles.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/line",
        "https://en.wiktionary.org/wiki/lined",
      ],
    },
  ],
  cafes: [
    {
      word: "cafes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A convenience store, originally one that sold coffee and similar basic items.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A coffee shop; an establishment selling coffee and sometimes other non-alcoholic beverages, simple meals or snacks, with a facility to consume them on the premises.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A small restaurant of any genre.",
              synonyms: [],
              antonyms: [],
              example:
                "1982, Joni Mitchell, Chinese Café / Unchained Melody (song)",
            },
          ],
          synonyms: ["caff", "coffee shop", "coffeehouse", "tea shop"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/caf%C3%A9",
        "https://en.wiktionary.org/wiki/cafe",
        "https://en.wiktionary.org/wiki/cafes",
      ],
    },
  ],
  naive: [
    {
      word: "naive",
      phonetic: "/naɪˈiːv/",
      phonetics: [
        {
          text: "/naɪˈiːv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/naive-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50409396",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A naive person; a greenhorn.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Lacking worldly experience, wisdom, or judgement; unsophisticated.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Not having been exposed to something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of art) Produced in a simple, childlike style, deliberately rejecting sophisticated techniques.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Intuitive; designed to follow the way ordinary people approach a problem.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/naive"],
    },
  ],
  wraps: [
    {
      word: "wraps",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A sharp blow with something hard.",
              synonyms: [],
              antonyms: [],
              example:
                "The teacher gave the wayward pupil a rap across the knuckles with her ruler.",
            },
            {
              definition: "Blame for something.",
              synonyms: [],
              antonyms: [],
              example:
                "You can't act irresponsibly and then expect me to take the rap.",
            },
            {
              definition:
                "A charge, whether or not it results in a conviction.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A casual talk.", synonyms: [], antonyms: [] },
            { definition: "Rap music.", synonyms: [], antonyms: [] },
            {
              definition:
                "A song, verse, or instance of singing in the style of rap music.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An appraisal.",
              synonyms: [],
              antonyms: [],
              example: "a good/great/bad rap",
            },
            {
              definition: "A positive appraisal; a recommendation.",
              synonyms: [],
              antonyms: [],
              example: "He gave the novel quite a rap.",
            },
          ],
          synonyms: ["fall"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A lay or skein containing 120 yards of yarn.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of the tokens that passed current for a halfpenny in Ireland in the early part of the eighteenth century; any coin of trifling value.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A whit; a jot.",
              synonyms: [],
              antonyms: [],
              example: "I don't care a rap.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To enclose (an object) completely in any flexible, thin material such as fabric or paper.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To enclose or coil around an object or organism, as a form of grasping.",
              synonyms: [],
              antonyms: [],
              example: "A snake wraps itself around its prey.",
            },
            {
              definition: "To conceal by enveloping or enfolding; to hide.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(video production) To finish shooting (filming) a video, television show, or movie.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(lines, words, text, etc.) To break a continuous line (of text) onto the next line",
              synonyms: [],
              antonyms: [],
              example:
                "I wrapped the text so that I wouldn't need to scroll to the right to read it.",
            },
            {
              definition:
                "To make functionality available through a software wrapper.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To (cause to) reset to an original value after passing a maximum.",
              synonyms: [],
              antonyms: [],
              example:
                "The row counter wraps back to zero when no more rows can be inserted.",
            },
          ],
          synonyms: ["enfold", "lap"],
          antonyms: ["unwrap"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A garment that one wraps around the body to keep oneself warm.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A type of food consisting of various ingredients wrapped in a tortilla or pancake.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(entertainment) The completion of all or a major part of a performance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A wraparound mortgage.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rap",
        "https://en.wiktionary.org/wiki/wrap",
        "https://en.wiktionary.org/wiki/wraps",
      ],
    },
  ],
  sized: [
    {
      word: "sized",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To adjust the size of; to make a certain size.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To classify or arrange by size.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To approximate the dimensions, estimate the size of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To take a greater size; to increase in size.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Cambridge University) To order food or drink from the buttery; hence, to enter a score, as upon the buttery book.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To swell; to increase the bulk of.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To apply glue or other primer to a surface which is to be painted.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Having a certain size. Usually used in combination with an adverb.",
              synonyms: [],
              antonyms: [],
              example: "A badly-sized pair of shoes.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/size",
        "https://en.wiktionary.org/wiki/sized",
      ],
    },
  ],
  piers: [
    {
      word: "piers",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A raised platform built from the shore out over water, supported on piles; used to secure, or provide access to shipping; a jetty.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A similar structure, especially at a seaside resort, used to provide entertainment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A structure that projects tangentially from the shoreline to accommodate ships; often double-sided.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A structure supporting the junction between two spans of a bridge.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A rectangular pillar, or similar structure, that supports an arch, wall or roof, or the hinges of a gate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pier",
        "https://en.wiktionary.org/wiki/piers",
      ],
    },
  ],
  beset: [
    {
      word: "beset",
      phonetic: "/bɪˈsɛt/",
      phonetics: [{ text: "/bɪˈsɛt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To surround or hem in.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(sometimes figurative) To attack or assail, especially from all sides.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To decorate something with jewels etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of a ship, to get trapped by ice.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/beset"],
    },
  ],
  agile: [
    {
      word: "agile",
      phonetic: "/ˈæd͡ʒ.aɪl/",
      phonetics: [
        {
          text: "/ˈæd͡ʒ.aɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/agile-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1212437",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Having the faculty of quick motion in the limbs; apt or ready to move",
              synonyms: ["nimble"],
              antonyms: [],
              example: "an agile creature",
            },
            {
              definition: "Characterised by quick motion",
              synonyms: [],
              antonyms: [],
              example: "agile movements",
            },
            {
              definition:
                "Of or relating to agile software development, a technique for iterative and incremental development of software involving collaboration between teams.",
              synonyms: [],
              antonyms: [],
              example: "agile methods",
            },
          ],
          synonyms: [
            "active",
            "alert",
            "brisk",
            "lively",
            "nimble",
            "quick",
            "nimble",
          ],
          antonyms: ["unagile"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/agile"],
    },
  ],
  tongs: [
    {
      word: "tongs",
      phonetic: "/tɑŋz/",
      phonetics: [
        { text: "/tɑŋz/", audio: "" },
        { text: "/tɒŋz/", audio: "" },
        {
          text: "/tɔŋz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tongs-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=958927",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An instrument or tool used for picking things up without touching them with the hands or fingers, consisting of two slats or grips hinged at the end or in the middle, and sometimes including a spring to open the grips.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) A large scissors-like two-piece center-hinged forged-iron implement with oval-loop handles and with pointed tips turned inward (in the same plane as and perpendicular to the handles) to facilitate lifting and carrying a block of ice. Often called ice tongs.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tongs"],
    },
    {
      word: "tongs",
      phonetic: "/tɑŋz/",
      phonetics: [
        { text: "/tɑŋz/", audio: "" },
        { text: "/tɒŋz/", audio: "" },
        {
          text: "/tɔŋz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tongs-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=958927",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An instrument or tool used for manipulating things in a fire without touching them with the hands.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A Chinese secret society or gang.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The flexible muscular organ in the mouth that is used to move food around, for tasting and that is moved into various positions to modify the flow of air from the lungs in order to produce different sounds in speech.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "This organ, as taken from animals used for food (especially cows).−",
              synonyms: [],
              antonyms: [],
              example: "cold tongue with mustard",
            },
            {
              definition: "(metonym) A language.",
              synonyms: ["idiom", "language", "lingo"],
              antonyms: [],
              example: "He was speaking in his native tongue.",
            },
            {
              definition: "Speakers of a language, collectively.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Voice (the distinctive sound of a person's speech); accent (distinctive manner of pronouncing a language).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Manner of speaking, often habitually.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(metonym) A person speaking in a specified manner (most often plural).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The power of articulate utterance; speech generally.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Discourse; fluency of speech or expression.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Discourse; fluency of speech or expression.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Honourable discourse; eulogy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(often in the plural) Glossolalia.",
              synonyms: ["speaking in tongues"],
              antonyms: [],
            },
            {
              definition:
                "In a shoe, the flap of material that goes between the laces and the foot (so called because it resembles a tongue in the mouth).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any large or long physical protrusion on an automotive or machine part or any other part that fits into a long groove on another part.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A projection, or slender appendage or fixture.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A long, narrow strip of land, projecting from the mainland into a sea or lake.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The pole of a vehicle; especially, the pole of an ox cart, to the end of which the oxen are yoked.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The clapper of a bell.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An individual point of flame from a fire.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A small sole (type of fish).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A short piece of rope spliced into the upper part of standing backstays, etc.; also, the upper main piece of a mast composed of several pieces.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A reed.", synonyms: [], antonyms: [] },
            {
              definition:
                "A division of formation; A layer or member of a formation that pinches out in one direction.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["idiom", "language", "lingo", "speaking in tongues"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tong",
        "https://en.wiktionary.org/wiki/tongs",
        "https://en.wiktionary.org/wiki/tongue",
      ],
    },
    {
      word: "tongs",
      phonetic: "/tɑŋz/",
      phonetics: [
        { text: "/tɑŋz/", audio: "" },
        { text: "/tɒŋz/", audio: "" },
        {
          text: "/tɔŋz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tongs-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=958927",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To use tongs.", synonyms: [], antonyms: [] },
            {
              definition:
                "To grab, manipulate or transport something using tongs.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tong",
        "https://en.wiktionary.org/wiki/tongs",
      ],
    },
    {
      word: "tongs",
      phonetic: "/tɑŋz/",
      phonetics: [
        { text: "/tɑŋz/", audio: "" },
        { text: "/tɒŋz/", audio: "" },
        {
          text: "/tɔŋz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tongs-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=958927",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An instrument or tool used for manipulating things in a fire without touching them with the hands.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A Chinese secret society or gang.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The flexible muscular organ in the mouth that is used to move food around, for tasting and that is moved into various positions to modify the flow of air from the lungs in order to produce different sounds in speech.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "This organ, as taken from animals used for food (especially cows).−",
              synonyms: [],
              antonyms: [],
              example: "cold tongue with mustard",
            },
            {
              definition: "(metonym) A language.",
              synonyms: ["idiom", "language", "lingo"],
              antonyms: [],
              example: "He was speaking in his native tongue.",
            },
            {
              definition: "Speakers of a language, collectively.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Voice (the distinctive sound of a person's speech); accent (distinctive manner of pronouncing a language).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Manner of speaking, often habitually.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(metonym) A person speaking in a specified manner (most often plural).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The power of articulate utterance; speech generally.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Discourse; fluency of speech or expression.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Discourse; fluency of speech or expression.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Honourable discourse; eulogy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(often in the plural) Glossolalia.",
              synonyms: ["speaking in tongues"],
              antonyms: [],
            },
            {
              definition:
                "In a shoe, the flap of material that goes between the laces and the foot (so called because it resembles a tongue in the mouth).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any large or long physical protrusion on an automotive or machine part or any other part that fits into a long groove on another part.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A projection, or slender appendage or fixture.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A long, narrow strip of land, projecting from the mainland into a sea or lake.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The pole of a vehicle; especially, the pole of an ox cart, to the end of which the oxen are yoked.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The clapper of a bell.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An individual point of flame from a fire.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A small sole (type of fish).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A short piece of rope spliced into the upper part of standing backstays, etc.; also, the upper main piece of a mast composed of several pieces.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A reed.", synonyms: [], antonyms: [] },
            {
              definition:
                "A division of formation; A layer or member of a formation that pinches out in one direction.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["idiom", "language", "lingo", "speaking in tongues"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tong",
        "https://en.wiktionary.org/wiki/tongs",
        "https://en.wiktionary.org/wiki/tongue",
      ],
    },
  ],
  steed: [
    {
      word: "steed",
      phonetic: "/stiːd/",
      phonetics: [
        {
          text: "/stiːd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/steed-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241798",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A stallion, especially in the sense of mount.",
              synonyms: [],
              antonyms: [],
              example:
                'And govern\'d him in strength, though not in lust. — Shakespeare, "Venus and Adonis".',
            },
            { definition: "A bicycle.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/steed"],
    },
  ],
  fraud: [
    {
      word: "fraud",
      phonetic: "/fɹɔːd/",
      phonetics: [
        {
          text: "/fɹɔːd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fraud-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88987534",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/fɹɔd/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The crime of stealing or otherwise illegally obtaining money by use of deception tactics.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any act of deception carried out for the purpose of unfair, undeserved and/or unlawful gain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The assumption of a false identity to such deceptive end.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person who performs any such trick.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A trap or snare.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "cheat",
            "faker",
            "fraudster",
            "impostor",
            "trickster",
            "deceit",
            "grift",
            "hoky-poky",
            "imposture",
            "scam",
            "swindle",
            "trickery",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To defraud", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fraud"],
    },
  ],
  booty: [
    {
      word: "booty",
      phonetic: "/ˈbuːti/",
      phonetics: [
        {
          text: "/ˈbuːti/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/booty-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241104",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A form of prize which, when a ship was captured at sea, could be distributed at once.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Plunder taken from an enemy in time of war, or seized by piracy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something that has been stolen or illegally obtained from elsewhere.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/booty"],
    },
    {
      word: "booty",
      phonetic: "/ˈbuːti/",
      phonetics: [
        {
          text: "/ˈbuːti/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/booty-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241104",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The buttocks.",
              synonyms: [],
              antonyms: [],
              example: "You got a big ol' booty.",
            },
            {
              definition:
                "(not countable) A person considered as a sexual partner or sex object.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Sexual intercourse.", synonyms: [], antonyms: [] },
            { definition: "The vulva and vagina.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/booty"],
    },
    {
      word: "booty",
      phonetic: "/ˈbuːti/",
      phonetics: [
        {
          text: "/ˈbuːti/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/booty-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241104",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A soft, woolen shoe, usually knitted, for a baby or small pet.",
              synonyms: [],
              antonyms: [],
              example:
                "Take off the baby's bootees before you put her in the crib.",
            },
            {
              definition: "A thick sock worn under a wetsuit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An overshoe or sock worn to cover dirty shoes or feet.",
              synonyms: [],
              antonyms: [],
              example:
                "Surgeons often slip on bootees before entering the operating room.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bootee",
        "https://en.wiktionary.org/wiki/booty",
      ],
    },
  ],
  valor: [
    {
      word: "valor",
      phonetic: "/ˈvælɚ/",
      phonetics: [
        {
          text: "/ˈvælɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/valor-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453781",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Value; worth.", synonyms: [], antonyms: [] },
            {
              definition:
                "Strength of mind in regard to danger; that quality which enables a person to encounter danger with firmness",
              synonyms: ["bravery", "courage", "intrepidity", "prowess"],
              antonyms: [],
            },
          ],
          synonyms: ["bravery", "courage", "intrepidity", "prowess"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/valor"],
    },
  ],
  downy: [
    {
      word: "downy",
      phonetic: "/ˈdaʊni/",
      phonetics: [{ text: "/ˈdaʊni/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Having down, covered with a soft fuzzy coating as of small feathers or hair.",
              synonyms: [],
              antonyms: [],
              example:
                "The chick's downy coat of feathers formed almost immediately to keep it warm.",
            },
            {
              definition: "Sharp-witted, perceptive.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/downy"],
    },
    {
      word: "downy",
      phonetic: "/ˈdaʊni/",
      phonetics: [{ text: "/ˈdaʊni/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A blanket filled with down; a duvet.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/downy"],
    },
  ],
  witty: [
    {
      word: "witty",
      phonetic: "/ˈwɪti/",
      phonetics: [
        { text: "/ˈwɪti/", audio: "" },
        { text: "/ˈwɪti/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Wise, having good judgement.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Possessing a strong intellect or intellectual capacity; intelligent, skilful, ingenious.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Clever; amusingly ingenious.",
              synonyms: [],
              antonyms: [],
              example: "His speech was both witty and informative.",
            },
            {
              definition: "Full of wit.",
              synonyms: [],
              antonyms: [],
              example: "His frequent quips mark him as particularly witty.",
            },
            {
              definition: "Quick of mind; insightful; in possession of wits.",
              synonyms: [],
              antonyms: [],
              example:
                "She may have grown older, but she has grown no less witty.",
            },
          ],
          synonyms: [
            "facetious",
            "humorous",
            "jocose",
            "jocular",
            "lively",
            "quick",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/witty"],
    },
  ],
  mossy: [
    {
      word: "mossy",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mossy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75648487",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈmɒsi/", audio: "" },
        {
          text: "/ˈmɑːsi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mossy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2191643",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "Mosquito", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Covered in or overgrown with moss.",
              synonyms: ["moss-grown"],
              antonyms: [],
            },
          ],
          synonyms: ["moss-grown"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mossy"],
    },
  ],
  psalm: [
    {
      word: "psalm",
      phonetic: "/sɑːm/",
      phonetics: [
        { text: "/sɑːm/", audio: "" },
        {
          text: "/sɑlm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/psalm-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=11761785",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sacred song; a poetical composition for use in the praise or worship of God.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the hymns by David and others, collected into one book of the Old Testament, or a modern metrical version of such a hymn for public worship.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To extol in psalms; to make music; to sing",
              synonyms: [],
              antonyms: [],
              example: "to psalm his praises.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/psalm"],
    },
  ],
  scuba: [
    {
      word: "scuba",
      phonetic: "/ˈskuːbə/",
      phonetics: [{ text: "/ˈskuːbə/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(underwater diving) An apparatus carried by a diver, which includes a tank holding compressed, filtered air and a regulator which delivers the air to the diver at ambient pressure which can be used underwater.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To perform scuba diving.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scuba"],
    },
  ],
  tours: [
    {
      word: "tours",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A journey through a particular building, estate, country, etc.",
              synonyms: [],
              antonyms: [],
              example:
                "On our last holiday to Spain we took a tour of the wine-growing regions.",
            },
            {
              definition:
                "A guided visit to a particular place, or virtual place.",
              synonyms: [],
              antonyms: [],
              example:
                "On the company's website, you can take a virtual tour of the headquarters.",
            },
            {
              definition:
                "A journey through a given list of places, such as by an entertainer performing concerts.",
              synonyms: [],
              antonyms: [],
              example: "Metallica's tour of Europe",
            },
            {
              definition:
                "A trip taken to another country in which several matches are played.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A street and road race, frequently multiday.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A set of competitions which make up a championship.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A tour of duty.", synonyms: [], antonyms: [] },
            { definition: "A closed trail.", synonyms: [], antonyms: [] },
            {
              definition: "A going round; a circuit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A turn; a revolution.",
              synonyms: [],
              antonyms: [],
              example: "the tours of the heavenly bodies",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make a journey",
              synonyms: [],
              antonyms: [],
              example:
                "The Rolling Stones were still touring when they were in their seventies.",
            },
            {
              definition: "To make a circuit of a place",
              synonyms: [],
              antonyms: [],
              example:
                "The circuses have been touring Europe for the last few weeks.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "A tower.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To toot a horn.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tour",
        "https://en.wiktionary.org/wiki/tours",
      ],
    },
  ],
  polka: [
    {
      word: "polka",
      phonetic: "/ˈpɒl.kə/",
      phonetics: [
        { text: "/ˈpɒl.kə/", audio: "" },
        { text: "/ˈpoʊ(l)kə/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A lively dance originating in Bohemia.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The music for this dance.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A polka jacket.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To dance the polka.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/polka"],
    },
  ],
  milky: [
    {
      word: "milky",
      phonetic: "/ˈmɪlki/",
      phonetics: [
        { text: "/ˈmɪlki/", audio: "" },
        { text: "/ˈmɪlki/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Resembling milk in color, consistency, smell, etc.; consisting of milk.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(color science) Of the black in an image, appearing as dark gray rather than black.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a drink) Containing (an especially large amount of) milk.",
              synonyms: [],
              antonyms: [],
              example: "milky tea; milky cocoa",
            },
            {
              definition: "(of grains) Containing a whitish liquid, juicy.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Cowardly.", synonyms: [], antonyms: [] },
            { definition: "Immature, childish.", synonyms: [], antonyms: [] },
            {
              definition: "Producing milk, lactating.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "lactiferous",
            "fearful",
            "nithing",
            "infantile",
            "puerile",
            "lactifluous",
            "nursing",
            "lacteous",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/milky"],
    },
  ],
  gaudy: [
    {
      word: "gaudy",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gaudy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75797016",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈɡɔː.di/", audio: "" },
        { text: "/ˈɡɔ.di/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One of the large beads in the rosary at which the paternoster is recited.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Very showy or ornamented, now especially when excessive, or in a tasteless or vulgar manner",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Fun; merry; festive", synonyms: [], antonyms: [] },
          ],
          synonyms: ["flashy", "garish", "kitschy", "tawdry", "gaudy"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gaudy"],
    },
    {
      word: "gaudy",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gaudy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75797016",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈɡɔː.di/", audio: "" },
        { text: "/ˈɡɔ.di/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A reunion held by one of the colleges of the University of Oxford for alumni, normally held during the summer vacations.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gaudy"],
    },
  ],
  shrug: [
    {
      word: "shrug",
      phonetic: "/ʃɹʌɡ/",
      phonetics: [
        { text: "/ʃɹʌɡ/", audio: "" },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shrug-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=187023",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A lifting of the shoulders to signal indifference or a casual lack of knowledge.",
              synonyms: [],
              antonyms: [],
              example: "He dismissed my comment with a shrug.",
            },
            {
              definition:
                "A cropped, cardigan-like garment with short or long sleeves, typically knitted.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To raise (the shoulders) to express uncertainty, lack of concern, (formerly) dread, etc.",
              synonyms: [],
              antonyms: [],
              example: "I asked him for an answer and he just shrugged.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shrug"],
    },
  ],
  tufts: [
    {
      word: "tufts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A bunch of feathers, grass or hair, etc., held together at the base.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cluster of threads drawn tightly through upholstery, a mattress or a quilt, etc., to secure and strengthen the padding.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A small clump of trees or bushes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A gold tassel on the cap worn by titled undergraduates at English universities.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person entitled to wear such a tassel.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To provide or decorate with a tuft or tufts.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To form into tufts.", synonyms: [], antonyms: [] },
            {
              definition:
                "To secure and strengthen (a mattress, quilt, etc.) with tufts.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be formed into tufts.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tuft",
        "https://en.wiktionary.org/wiki/tufts",
      ],
    },
  ],
  wilds: [
    {
      word: "wilds",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A wood or forest", synonyms: [], antonyms: [] },
            { definition: "An open country", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The undomesticated state of a wild animal",
              synonyms: [],
              antonyms: [],
              example:
                "After mending the lion's leg, we returned him to the wild.",
            },
            {
              definition: "(chiefly in the plural) a wilderness",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To commit random acts of assault, robbery, and rape in an urban setting, especially as a gang.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/weald",
        "https://en.wiktionary.org/wiki/wild",
        "https://en.wiktionary.org/wiki/wilds",
      ],
    },
  ],
  laser: [
    {
      word: "laser",
      phonetic: "/ˈleɪz.ə(ɹ)/",
      phonetics: [
        { text: "/ˈleɪz.ə(ɹ)/", audio: "" },
        { text: "/ˈleɪzɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A device that produces a monochromatic, coherent beam of light.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A beam of light produced by such a device; a laser beam.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A laser printer.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["LASER", "optical MASER", "optical maser"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To cut with a laser", synonyms: [], antonyms: [] },
            {
              definition: "To throw with laser-like precision",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/laser"],
    },
    {
      word: "laser",
      phonetic: "/ˈleɪs.ə(ɹ)/",
      phonetics: [{ text: "/ˈleɪs.ə(ɹ)/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A gum resin obtained from certain umbelliferous plants.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Such a plant.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/laser"],
    },
  ],
  truss: [
    {
      word: "truss",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/truss-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75858960",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/tɹʌs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/truss-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80152078",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/tɹʌs/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A bandage and belt used to hold a hernia in place.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A structure made up of one or more triangular units made from straight beams of wood or metal, which is used to support a structure as in a roof or bridge.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A triangular bracket.", synonyms: [], antonyms: [] },
            {
              definition:
                "An old English farming measurement. One truss of straw equalled 36 pounds, a truss of old hay equalled 56 pounds, a truss of new hay equalled 60 pounds, and 36 trusses equalled one load.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A bundle; a package.", synonyms: [], antonyms: [] },
            {
              definition:
                "A padded jacket or dress worn under armour, to protect the body from the effects of friction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Part of a woman's dress; a stomacher.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A tuft of flowers formed at the top of the main stem of certain plants.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The rope or iron used to keep the centre of a yard to the mast.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To tie up a bird before cooking it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To secure or bind with ropes.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To support.", synonyms: [], antonyms: [] },
            {
              definition:
                "To take fast hold of; to seize and hold firmly; to pounce upon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To strengthen or stiffen, as a beam or girder, by means of a brace or braces.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To execute by hanging; to hang; usually with up.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/truss"],
    },
  ],
  hares: [
    {
      word: "hares",
      phonetic: "/heɹz/",
      phonetics: [
        {
          text: "/heɹz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hares-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=61043465",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of several plant-eating animals of the family Leporidae, especially of the genus Lepus, similar to a rabbit, but larger and with longer ears.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The player in a paperchase, or hare and hounds game, who leaves a trail of paper to be followed.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To move swiftly.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["race", "speed", "tear"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To excite; to tease, or worry; to harry.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hare",
        "https://en.wiktionary.org/wiki/hares",
      ],
    },
  ],
  creed: [
    {
      word: "creed",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/creed-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75729751",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/kɹiːd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/creed-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80066426",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/kɹid/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/creed-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=64344017",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "That which is believed; accepted doctrine, especially religious doctrine; a particular set of beliefs; any summary of principles or opinions professed or adhered to.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(specifically) A reading or statement of belief that summarizes the faith it represents; a confession of faith for public use, especially one which is brief and comprehensive.",
              synonyms: [],
              antonyms: [],
              example:
                "A creed is a manifesto of religious or spiritual beliefs",
            },
            {
              definition: "The fact of believing; belief, faith.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: '(only survives in "creeded") To believe; to credit.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To provide with a creed.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/creed"],
    },
  ],
  lilac: [
    {
      word: "lilac",
      phonetic: "/ˈlaɪlək/",
      phonetics: [
        { text: "/ˈlaɪlək/", audio: "" },
        {
          text: "/ˈlaɪlək/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lilac-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50048976",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A large shrub of the genus Syringa, especially Syringa vulgaris, bearing white, pale-pink or purple flowers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A flower of the lilac shrub.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(color) A pale purple color, the color of some lilac flowers.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having a pale purple colour.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lilac"],
    },
  ],
  siren: [
    {
      word: "siren",
      phonetic: "/ˈsaɪəɹən/",
      phonetics: [
        { text: "/ˈsaɪəɹən/", audio: "" },
        { text: "/ˈsaɪɹən/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One of a group of nymphs who lured mariners to their death on the rocks.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One who sings sweetly and charms.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A dangerously seductive woman.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A member of an order of mammals of Sirenia (first attested in French in Dominique Bouhours, Les entretiens d'Ariste et d'Eugène, 1671).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A member of a genus of aquatic salamanders of the family Sirenidae (originally introduced by Linnaeus, 1766, for a genus of his reptiles), commonly used for all species subsumed under the family of Sirenidae.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various nymphalid butterflies of the genus Hestina.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A device, either mechanical or electronic, that makes a piercingly loud sound as an alarm or signal, or the sound from such a device (first recorded 1879).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A musical instrument, one of the few aerophones in the percussion section of the symphony orchestra (patented as Acme Siren in 1895).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An instrument for demonstrating the laws of beats and combination tones.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An astrophysical event that can be used for calculating cosmic distances.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["klaxon", "crooner"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make a noise with, or as if with, a siren.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Relating to or like a siren.",
              synonyms: ["bewitching", "enchanting", "enticing", "sirenic"],
              antonyms: [],
            },
          ],
          synonyms: ["bewitching", "enchanting", "enticing", "sirenic"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/siren"],
    },
  ],
  tarry: [
    {
      word: "tarry",
      phonetic: "/ˈtæ.ɹi/",
      phonetics: [
        {
          text: "/ˈtæ.ɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tarry-1-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88897056",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A sojourn.",
              synonyms: ["stay", "stop", "stop-over"],
              antonyms: [],
            },
          ],
          synonyms: ["stay", "stop", "stop-over"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To delay; to be late or tardy in beginning or doing anything.",
              synonyms: ["forestall", "put off"],
              antonyms: [],
            },
            {
              definition:
                "To linger in expectation of something or until something is done or happens.",
              synonyms: ["abide"],
              antonyms: [],
            },
            {
              definition:
                "To abide, stay or wait somewhere, especially if longer than planned.",
              synonyms: ["hang about", "hang around", "linger", "loiter"],
              antonyms: [],
            },
            {
              definition: "To stay somewhere temporarily.",
              synonyms: ["sojourn", "stay", "stay over", "stop", "stop over"],
              antonyms: [],
            },
            {
              definition:
                "To wait for; to stay or stop for; to allow to linger.",
              synonyms: ["await", "wait on"],
              antonyms: [],
            },
          ],
          synonyms: [
            "abide",
            "await",
            "wait on",
            "forestall",
            "put off",
            "hang about",
            "hang around",
            "linger",
            "loiter",
            "sojourn",
            "stay",
            "stay over",
            "stop",
            "stop over",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tarry"],
    },
    {
      word: "tarry",
      phonetic: "/ˈtɑːɹi/",
      phonetics: [
        {
          text: "/ˈtɑːɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tarry-2-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88897057",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Resembling tar.",
              synonyms: ["pitchy"],
              antonyms: [],
            },
            {
              definition: "Covered with tar.",
              synonyms: ["bituminized", "pitchy"],
              antonyms: [],
            },
          ],
          synonyms: ["bituminized", "pitchy", "pitchy"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tarry"],
    },
  ],
  bribe: [
    {
      word: "bribe",
      phonetic: "/bɹaɪb/",
      phonetics: [
        {
          text: "/bɹaɪb/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bribe-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=7465680",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Something (usually money) given in exchange for influence or as an inducement to dishonesty.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "That which seduces; seduction; allurement.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To give a bribe to; specifically, to ask a person to do something, usually against his/her will, in exchange for some type of reward or relief from potential trouble.",
              synonyms: [],
              antonyms: [],
              example:
                "She was accused of trying to bribe the jury into making false statements.",
            },
            {
              definition: "To gain by a bribe; to induce as by a bribe.",
              synonyms: [],
              antonyms: [],
              example: "to bribe somebody's compliance",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bribe"],
    },
  ],
  swine: [
    {
      word: "swine",
      phonetic: "/swaɪn/",
      phonetics: [
        {
          text: "/swaɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/swine-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651593",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(plural swine) A pig (the animal).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A contemptible person (plural swines).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: 'A police officer; a "pig".',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Something difficult or awkward; a pain.",
              synonyms: [],
              antonyms: [],
              example: "That old car is a swine to manoeuvre.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/swine"],
    },
    {
      word: "swine",
      phonetic: "/swaɪn/",
      phonetics: [
        {
          text: "/swaɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/swine-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651593",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A female pig.", synonyms: [], antonyms: [] },
            {
              definition: "A female bear, she-bear.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A female guinea pig.", synonyms: [], antonyms: [] },
            {
              definition: "A channel that conducts molten metal to molds.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A mass of metal solidified in a mold.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A contemptible, often fat woman.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A sowbug.", synonyms: [], antonyms: [] },
            {
              definition:
                "A kind of covered shed, formerly used by besiegers in filling up and passing the ditch of a besieged place, sapping and mining the wall, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bitch", "cow", "ingot"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sow",
        "https://en.wiktionary.org/wiki/swine",
      ],
    },
  ],
  muted: [
    {
      word: "muted",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To silence, to make quiet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To turn off the sound of.",
              synonyms: [],
              antonyms: [],
              example: "Please mute the music while I make a call.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "Of a bird: to defecate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cast off; to moult.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Not expressed strongly or openly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of a sound) Quiet or soft.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "(of color) Subdued.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mute",
        "https://en.wiktionary.org/wiki/muted",
      ],
    },
  ],
  flips: [
    {
      word: "flips",
      phonetic: "/flɪps/",
      phonetics: [{ text: "/flɪps/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A maneuver which rotates an object end over end.",
              synonyms: [],
              antonyms: [],
              example:
                "The diver did a couple of flips before landing in the pool.",
            },
            {
              definition:
                "A complete change of direction, decision, movement etc.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A slingshot.", synonyms: [], antonyms: [] },
            {
              definition:
                "A hairstyle popular among boys in the 1960s–70s and 2000s–10s, in which the hair goes halfway down the ears, at which point it sticks out",
              synonyms: [],
              antonyms: [],
              example:
                "Justin Bieber and Zac Efron are among the celebrities who wore a flip.",
            },
            {
              definition:
                "The purchase of an asset (usually a house) which is then improved and sold quickly for profit.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To throw so as to turn over.",
              synonyms: [],
              antonyms: [],
              example: "You need to flip the pancake onto the other side.",
            },
            {
              definition:
                "To put into a quick revolving motion through a snap of the thumb and index finger.",
              synonyms: [],
              antonyms: [],
              example:
                "If you can't decide which option to go for, flip a coin.",
            },
            {
              definition:
                "To win a state (or county) won by another party in the preceding elections",
              synonyms: [],
              antonyms: [],
              example:
                "Wisconsin had been Democratic for decades, but the Republicans flipped it in 2016.",
            },
            {
              definition:
                "To turn state's evidence; to agree to testify against one's co-conspirators in exchange for concessions from prosecutors.",
              synonyms: [],
              antonyms: [],
              example:
                "The mafioso flipped on his superiors to get a lighter sentence.",
            },
            {
              definition:
                "To induce someone to turn state's evidence; to get someone to agree to testify against their co-conspirators in exchange for concessions.",
              synonyms: [],
              antonyms: [],
              example:
                "The district attorney was able to strengthen his case against the bank robber by flipping the getaway driver.",
            },
            {
              definition: "To go berserk or crazy.",
              synonyms: [],
              antonyms: [],
              example: "I'd flip if anyone broke my phone.",
            },
            {
              definition:
                "To buy an asset (usually a house), improve it and sell it quickly for profit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To invert a bit (binary digit), changing it from 0 to 1 or from 1 to 0.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["toss", "turn", "turn over"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/flip",
        "https://en.wiktionary.org/wiki/flips",
      ],
    },
  ],
  cures: [
    {
      word: "cures",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A method, device or medication that restores good health.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Act of healing or state of being healed; restoration to health after a disease, or to soundness after injury.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A solution to a problem.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A process of preservation, as by smoking.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A process of solidification or gelling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A process whereby a material is caused to form permanent molecular linkages by exposure to chemicals, heat, pressure and/or weathering.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Care, heed, or attention.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Spiritual charge; care of soul; the office of a parish priest or of a curate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That which is committed to the charge of a parish priest or of a curate.",
              synonyms: ["curacy"],
              antonyms: [],
            },
          ],
          synonyms: ["curacy"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To restore to health.",
              synonyms: ["heal"],
              antonyms: [],
              example: "Unaided nature cured him.",
            },
            {
              definition: "To bring (a disease or its bad effects) to an end.",
              synonyms: [],
              antonyms: [],
              example: "Unaided nature cured his ailments.",
            },
            {
              definition: "To cause to be rid of (a defect).",
              synonyms: [],
              antonyms: [],
              example: "Experience will cure him of his naïveté.",
            },
            {
              definition:
                "To prepare or alter especially by chemical or physical processing for keeping or use.",
              synonyms: [],
              antonyms: [],
              example: "The smoke and heat cures the meat.",
            },
            {
              definition: "To bring about a cure of any kind.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be undergoing a chemical or physical process for preservation or use.",
              synonyms: [],
              antonyms: [],
              example: "The meat was put in the smokehouse to cure.",
            },
            {
              definition: "To preserve (food), typically by salting",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To solidify or gel.",
              synonyms: [],
              antonyms: [],
              example: "The parts were curing in the autoclave.",
            },
            { definition: "To become healed.", synonyms: [], antonyms: [] },
            {
              definition: "To pay heed; to care; to give attention.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["heal"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cure",
        "https://en.wiktionary.org/wiki/cures",
      ],
    },
  ],
  sinew: [
    {
      word: "sinew",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sinew-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75858019",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈsɪnjuː/", audio: "" },
        { text: "/ˈsɪnju/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A cord or tendon of the body.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cord or string, particularly as of a musical instrument.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Muscular power, muscle; nerve, nervous energy; vigor, vigorous strength.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(often in the plural) That which gives strength or in which strength consists; a supporting factor or member; mainstay.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A nerve.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To knit together or make strong with, or as if with, sinews.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sinew"],
    },
  ],
  boxed: [
    {
      word: "boxed",
      phonetic: "/bɒkst/",
      phonetics: [{ text: "/bɒkst/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To place inside a box; to pack in one or more boxes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Usually followed by in: to surround and enclose in a way that restricts movement; to corner, to hem in.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To mix two containers of paint of similar colour to ensure that the color is identical.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make an incision or hole in (a tree) for the purpose of procuring the sap.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To enclose with boarding, lathing, etc., so as to conceal (for example, pipes) or to bring to a required form.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To furnish (for example, the axle of a wheel) with a box.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To enclose (images, text, etc.) in a box.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To place a value of a primitive type into a corresponding object.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "box up",
            "case",
            "embox",
            "encase",
            "pack",
            "pack up",
            "package",
          ],
          antonyms: ["unbox", "uncase", "unpack"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To strike with the fists; to punch.",
              synonyms: [],
              antonyms: [],
              example: "Leave this place before I box you!",
            },
            {
              definition: "To fight against (a person) in a boxing match.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To participate in boxing; to be a boxer.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Packed into a box or boxes.",
              synonyms: [],
              antonyms: [],
              example: "The books are boxed, ready for shipping.",
            },
            {
              definition:
                "In bridge and other card games if the cards in a pack are reversed face-up and face-down then the pack is said to be boxed.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["boxed up", "packaged", "packed"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/box",
        "https://en.wiktionary.org/wiki/boxed",
      ],
    },
  ],
  hoops: [
    {
      word: "hoops",
      phonetic: "/huːps/",
      phonetics: [
        {
          text: "/huːps/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hoops-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90914537",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A circular band of metal used to bind a barrel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A ring; a circular band; anything resembling a hoop.",
              synonyms: [],
              antonyms: [],
              example:
                "the cheese hoop, or cylinder in which the curd is pressed in making cheese",
            },
            {
              definition:
                "A circular band of metal, wood, or similar material used for forming part of a framework such as an awning or tent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A circle, or combination of circles, of thin whalebone, metal, or other elastic material, used for expanding the skirts of ladies' dresses; (hence, by extension) a hoop petticoat or hoop skirt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A quart pot; so called because originally bound with hoops, like a barrel. Also, a portion of the contents measured by the distance between the hoops.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An old measure of capacity, variously estimated at from one to four pecks.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The rim part of a basketball net.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(in plural, metonym) The game of basketball.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A hoop earring.", synonyms: [], antonyms: [] },
            {
              definition:
                "(metonym) A jockey; from a common pattern on the blouse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(usually in the plural) A horizontal stripe on the jersey",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(usually in the plural) A requirement that must be met in order to proceed.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To bind or fasten using a hoop.",
              synonyms: [],
              antonyms: [],
              example: "to hoop a barrel or puncheon",
            },
            {
              definition: "To clasp; to encircle; to surround.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A shout; a whoop, as in whooping cough.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The hoopoe.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To utter a loud cry, or a sound imitative of the word, by way of call or pursuit; to shout.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To whoop, as in whooping cough.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hoop",
        "https://en.wiktionary.org/wiki/hoops",
      ],
    },
  ],
  gasps: [
    {
      word: "gasps",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A short, sudden intake of breath.",
              synonyms: [],
              antonyms: [],
              example: "The audience gave a gasp of astonishment",
            },
            {
              definition: ": A draw or drag on a cigarette (or gasper).",
              synonyms: [],
              antonyms: [],
              example: "I'm popping out for a gasp.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To draw in the breath suddenly, as if from a shock.",
              synonyms: [],
              antonyms: [],
              example: "The audience gasped as the magician disappeared.",
            },
            {
              definition: "To breathe laboriously or convulsively.",
              synonyms: [],
              antonyms: [],
              example: "We were all gasping when we reached the summit.",
            },
            {
              definition: "To speak in a breathless manner.",
              synonyms: [],
              antonyms: [],
              example: "The old man gasped his last few words.",
            },
            {
              definition: "To pant with eagerness; to show vehement desire.",
              synonyms: [],
              antonyms: [],
              example: "I'm gasping for a cup of tea.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gasp",
        "https://en.wiktionary.org/wiki/gasps",
      ],
    },
  ],
  hoods: [
    {
      word: "hoods",
      phonetic: "/hʊdz/",
      phonetics: [{ text: "/hʊdz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A covering for the head attached to a larger garment such as a jacket or cloak.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A distinctively coloured fold of material, representing a university degree.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An enclosure that protects something, especially from above.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A soft top of a convertible car or carriage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The hinged cover over the engine of a motor vehicle: known as a bonnet in other countries.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A metal covering that leads to a vent to suck away smoke or fumes.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bonnet", "cowl"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cover something with a hood.",
              synonyms: [],
              antonyms: ["unhood"],
            },
          ],
          synonyms: [],
          antonyms: ["unhood"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Gangster, thug.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Neighborhood.",
              synonyms: [],
              antonyms: [],
              example: "What’s goin’ down in the hood?",
            },
          ],
          synonyms: ["nabe", "neighborhood", "ghetto"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Person wearing a hoodie.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hood",
        "https://en.wiktionary.org/wiki/hoods",
      ],
    },
  ],
  niche: [
    {
      word: "niche",
      phonetic: "/niːʃ/",
      phonetics: [
        {
          text: "/niːʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/niche-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75834025",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/niːʃ/", audio: "" },
        { text: "/niːʃ/", audio: "" },
        {
          text: "/niːʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/niche-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=13276157",
        },
        {
          text: "/niʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/niche-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=874147",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A cavity, hollow, or recess, generally within the thickness of a wall, for a statue, bust, or other erect ornament. Hence, any similar position, literal or figurative.",
              synonyms: ["nook"],
              antonyms: [],
            },
            {
              definition:
                "A function within an ecological system to which an organism is especially suited.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) Any position of opportunity for which one is well-suited, such as a particular market in business.",
              synonyms: ["specialization", "specialty"],
              antonyms: [],
            },
            {
              definition:
                "An arrow woven into a prayer rug pointing in the direction of qibla.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["nook", "specialization", "specialty"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To place in a niche.",
              synonyms: [],
              antonyms: [],
              example: "a niched vase",
            },
            {
              definition:
                "To specialize in a niche, or particular narrow section of the market.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Pertaining to or intended for a market niche; having specific appeal.",
              synonyms: [],
              antonyms: [],
              example: "niche audience",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/niche"],
    },
  ],
  yucca: [
    {
      word: "yucca",
      phonetic: "/ˈjʌkə/",
      phonetics: [
        {
          text: "/ˈjʌkə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/yucca-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88942240",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈjuːkə/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of several evergreen plants of the genus Yucca, having long, pointed, and rigid leaves at the top of a woody stem, and bearing a large panicle of showy white blossoms.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The yuca (cassava).", synonyms: [], antonyms: [] },
          ],
          synonyms: ["oose"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/yucca"],
    },
  ],
  glows: [
    {
      word: "glows",
      phonetic: "/ɡləʊz/",
      phonetics: [
        { text: "/ɡləʊz/", audio: "" },
        { text: "/ɡloʊz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The state of a glowing object.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The condition of being passionate or having warm feelings.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The brilliance or warmth of color in an environment or on a person (especially one's face).",
              synonyms: [],
              antonyms: [],
              example: "He had a bright red glow on his face.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To give off light from heat or to emit light as if heated.",
              synonyms: [],
              antonyms: [],
              example: "The fire was still glowing after ten hours.",
            },
            {
              definition: "To radiate some emotional quality like light.",
              synonyms: [],
              antonyms: [],
              example: "The zealots glowed with religious fervor.",
            },
            {
              definition: "To gaze especially passionately at something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To radiate thermal heat.",
              synonyms: [],
              antonyms: [],
              example:
                "After their workout, the gymnasts' faces were glowing red.",
            },
            {
              definition: "To shine brightly and steadily.",
              synonyms: [],
              antonyms: [],
              example: "The new baby's room glows with bright, loving colors.",
            },
            {
              definition: "To make hot; to flush.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To feel hot; to have a burning sensation, as of the skin, from friction, exercise, etc.; to burn.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/glow",
        "https://en.wiktionary.org/wiki/glows",
      ],
    },
  ],
  sewer: [
    {
      word: "sewer",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sewer-1-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75857861",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈs(j)uːə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sewer-1-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=53355549",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈsuɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sewer-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780367",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A pipe or system of pipes used to remove human waste and to provide drainage.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To provide (a place) with a system of sewers.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sewer"],
    },
    {
      word: "sewer",
      phonetic: "/ˈs(j)uːə/",
      phonetics: [
        {
          text: "/ˈs(j)uːə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sewer-2-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88896692",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈsuɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A servant attending at a meal who is responsible for seating arrangements, serving dishes, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sewer"],
    },
    {
      word: "sewer",
      phonetic: "/ˈsəʊə/",
      phonetics: [
        { text: "/ˈsəʊə/", audio: "" },
        {
          text: "/ˈsoʊɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sewer-3-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=47878747",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "One who sews.", synonyms: [], antonyms: [] },
            {
              definition:
                "A small tortricid moth, the larva of which sews together the edges of a leaf using silk.",
              synonyms: [],
              antonyms: [],
              example: "the apple-leaf sewer, Ancylis nubeculana",
            },
          ],
          synonyms: [
            "seamster",
            "seamstress",
            "sempster",
            "sempstress",
            "tailor",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sewer"],
    },
  ],
  whack: [
    {
      word: "whack",
      phonetic: "/wæk/",
      phonetics: [
        {
          text: "/wæk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/whack-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241736",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Egregious.", synonyms: [], antonyms: [] },
            {
              definition:
                "Bad (not good), inauthentic, of an inferior quality, contemptible, lacking integrity, lame, or strange.",
              synonyms: [],
              antonyms: [],
              example: "Every record they ever made was straight-up wack.",
            },
            { definition: "Crazy, mad, insane.", synonyms: [], antonyms: [] },
            {
              definition: "Cool, bizarre, and potentially scary.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The sound of a heavy strike.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The strike itself.", synonyms: [], antonyms: [] },
            {
              definition:
                "The stroke itself, regardless of its successful impact.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An attempt, a chance, a turn, a go, originally an attempt to beat someone or something.",
              synonyms: [],
              antonyms: [],
              example: "40 bucks a whack.",
            },
            {
              definition:
                "(originally Britain cant) A share, a portion, especially a full share or large portion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A whack-up: a division of an amount into separate whacks, a divvying up.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A deal, an agreement.",
              synonyms: [],
              antonyms: [],
              example: "It's a whack!",
            },
            {
              definition: "The backslash, ⟨ \\ ⟩.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To hit, slap or strike.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To kill, bump off.", synonyms: [], antonyms: [] },
            {
              definition: "To share or parcel out; often with up.",
              synonyms: [],
              antonyms: [],
              example: "to whack the spoils of a robbery",
            },
            {
              definition: "To beat convincingly; to thrash.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(usually in the negative) To surpass; to better.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wack",
        "https://en.wiktionary.org/wiki/whack",
      ],
    },
  ],
  fuses: [
    {
      word: "fuses",
      phonetic: "/ˈfjuːzɪz/",
      phonetics: [{ text: "/ˈfjuːzɪz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A cord that, when lit, conveys the fire to some explosive device.",
              synonyms: ["fuze (US)"],
              antonyms: [],
            },
            {
              definition:
                "The mechanism that ignites the charge in an explosive device.",
              synonyms: ["fuze"],
              antonyms: [],
            },
            {
              definition:
                "A device to prevent the overloading of an electrical circuit, containing a component that melts and interrupts the current when too high a load is passed through it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Indicating a tendency to lose one's temper.",
              synonyms: [],
              antonyms: [],
              example:
                "When talking about being laid off, he has a short fuse.",
            },
            {
              definition:
                "A friction match for smokers' use, having a bulbous head which when ignited is not easily blown out even in a gale of wind.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A kind of match made of paper impregnated with niter and having the usual igniting tip.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["fuze", "fuze (US)"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To melt together; to blend; to mix indistinguishably.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To melt together.", synonyms: [], antonyms: [] },
            {
              definition: "To furnish with or install a fuse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To form a bicyclic compound from two similar or different types of ring such that two or more atoms are shared between the resulting rings",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["meld", "smelt"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fuse",
        "https://en.wiktionary.org/wiki/fuses",
      ],
    },
  ],
  gowns: [
    {
      word: "gowns",
      phonetic: "/ɡaʊnz/",
      phonetics: [{ text: "/ɡaʊnz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A loose, flowing upper garment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A woman's ordinary outer dress, such as a calico or silk gown.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The official robe of certain professional men and scholars, such as university students and officers, barristers, judges, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by metonymy) The university community.",
              synonyms: [],
              antonyms: [],
              example:
                "In the perennial town versus gown battles, townies win some violent battles, but the collegians are winning the war.",
            },
            {
              definition:
                "A loose wrapper worn by gentlemen within doors; a dressing gown.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any sort of dress or garb.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The robe worn by a surgeon.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To dress in a gown, to don or garb with a gown.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gown",
        "https://en.wiktionary.org/wiki/gowns",
      ],
    },
  ],
  droop: [
    {
      word: "droop",
      phonetic: "/ˈdɹuːp/",
      phonetics: [
        {
          text: "/ˈdɹuːp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/droop-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1784106",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Something which is limp or sagging",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A condition or posture of drooping",
              synonyms: [],
              antonyms: [],
              example: "He walked with a discouraged droop.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To hang downward; to sag.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To slowly become limp; to bend gradually.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To lose all energy, enthusiasm or happiness; to flag.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To allow to droop or sink.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To proceed downward, or toward a close; to decline.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/droop"],
    },
  ],
  bucks: [
    {
      word: "bucks",
      phonetic: "/bʌks/",
      phonetics: [{ text: "/bʌks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A male deer, antelope, sheep, goat, rabbit, hare, and sometimes the male of other animals such as the hamster, ferret and shad.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An uncastrated sheep, a ram.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A young buck; an adventurous, impetuous, dashing, or high-spirited young man.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A fop or dandy.", synonyms: [], antonyms: [] },
            {
              definition: "A black or Native American man.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A dollar (one hundred cents).",
              synonyms: [],
              antonyms: [],
              example: "Can I borrow five bucks?",
            },
            {
              definition: "A rand (currency unit).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) Money.",
              synonyms: [],
              antonyms: [],
              example: "Corporations will do anything to make a buck.",
            },
            {
              definition: "One hundred.",
              synonyms: [],
              antonyms: [],
              example:
                "That skinny guy? C'mon, he can't weigh more than a buck and a quarter [125 pounds].",
            },
            {
              definition:
                "An object of various types, placed on a table to indicate turn or status; such as a brass object, placed in rotation on a US Navy wardroom dining table to indicate which officer is to be served first, or an item passed around a poker table indicating the dealer or placed in the pot to remind the winner of some privilege or obligation when his or her turn to deal next comes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in certain metaphors or phrases) Blame; responsibility; scapegoating; finger-pointing.",
              synonyms: [],
              antonyms: [],
              example: "pass the buck",
            },
            {
              definition:
                "The body of a post mill, particularly in East Anglia. See Wikipedia:Windmill machinery.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "One million dollars.", synonyms: [], antonyms: [] },
            { definition: "A euro.", synonyms: [], antonyms: [] },
            {
              definition:
                "A frame on which firewood is sawed; a sawhorse; a sawbuck.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A leather-covered frame used for gymnastic vaulting",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'A wood or metal frame used by automotive customizers and restorers to assist in the shaping of sheet metal bodywork. See Street Rodder "Making a Wood Buck".',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "button",
            "stag",
            "hob",
            "hob-ferret",
            "billy",
            "billygoat",
            "buck-goat",
            "buckling",
            "he-goat",
            "ram",
            "tup",
            "bill",
            "bone",
            "clam",
            "cucumber",
            "dead president",
            "greenback",
            "note",
            "one-spot",
            "paper",
            "simoleon",
            "single",
            "smackeroo",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To copulate, as bucks and does.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To bend; buckle.", synonyms: [], antonyms: [] },
            {
              definition:
                "(of a horse or similar saddle or pack animal) To leap upward arching its back, coming down with head low and forelegs stiff, forcefully kicking its hind legs upward, often in an attempt to dislodge or throw a rider or pack.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a horse or similar saddle or pack animal) To throw (a rider or pack) by bucking.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To subject to a mode of punishment which consists of tying the wrists together, passing the arms over the bent knees, and putting a stick across the arms and in the angle formed by the knees.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) To resist obstinately; oppose or object strongly.",
              synonyms: [],
              antonyms: [],
              example:
                "The vice president bucked at the board's latest solution.",
            },
            {
              definition:
                "(by extension) To move or operate in a sharp, jerking, or uneven manner.",
              synonyms: [],
              antonyms: [],
              example:
                "The motor bucked and sputtered before dying completely.",
            },
            {
              definition:
                "(by extension) To overcome or shed (e.g., an impediment or expectation), in pursuit of a goal; to force a way through despite (an obstacle); to resist or proceed against.",
              synonyms: [],
              antonyms: [],
              example:
                "John is really bucking the odds on that risky business venture. He's doing quite well.",
            },
            {
              definition:
                "(riveting) To press a reinforcing device (bucking bar) against (the force of a rivet) in order to absorb vibration and increase expansion. See Wikipedia: Rivet:Installation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To saw a felled tree into shorter lengths, as for firewood.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To output a voltage that is lower than the input voltage. See Wikipedia: Buck converter",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The beech tree.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To soak, steep or boil in lye or suds, as part of the bleaching process.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To wash (clothes) in lye or suds, or, in later usage, by beating them on stones in running water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To break up or pulverize, as ores.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A type of African American traditional clogging.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Casual oxford shoes made of buckskin, often white or a neutral colour.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The generally sterile male or female hybrid offspring of a male donkey and a female horse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The generally sterile hybrid offspring of any two species of animals.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A hybrid plant.", synonyms: [], antonyms: [] },
            { definition: "A stubborn person.", synonyms: [], antonyms: [] },
            {
              definition: "A person paid to smuggle drugs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A coin or medal minted with obverse and reverse designs not normally seen on the same piece, either intentionally or in error.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A MMORPG character, or NPC companion in a tabletop RPG, used mainly to store extra inventory for the owner's primary character.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of a group of cocktails involving ginger ale or ginger beer, citrus juice, and various liquors.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A kind of triangular sail for a yacht.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A kind of cotton-spinning machine.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A shoe that has no fitting or strap around the heel, but which covers the foot.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/buck",
        "https://en.wiktionary.org/wiki/buck%20dance",
        "https://en.wiktionary.org/wiki/bucks",
        "https://en.wiktionary.org/wiki/mule",
      ],
    },
  ],
  pangs: [
    {
      word: "pangs",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pangs-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651261",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(often in the plural) A paroxysm of extreme physical pain or anguish; a feeling of sudden and transitory agony; a throe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(often in the plural) A sudden sharp feeling of an emotional or mental nature, as of joy or sorrow.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cause to have great pain or suffering; to torment, to torture.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pang",
        "https://en.wiktionary.org/wiki/pangs",
      ],
    },
  ],
  mails: [
    {
      word: "mails",
      phonetic: "/meɪlz/",
      phonetics: [{ text: "/meɪlz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A bag or wallet.", synonyms: [], antonyms: [] },
            {
              definition: "A bag containing letters to be delivered by post.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The material conveyed by the postal service.",
              synonyms: [],
              antonyms: [],
              example: "Don't forget to pick up the mail on your way.",
            },
            {
              definition:
                "A stagecoach, train or ship that delivers such post.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The postal service or system in general.",
              synonyms: [],
              antonyms: [],
              example: "He decided to send his declaration by mail.",
            },
            {
              definition:
                "The letters, parcels, etc. delivered to a particular address or person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Electronic mail, e-mail: a computer network–based service for sending, storing, and forwarding electronic messages.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A trunk, box, or bag, in which clothing, etc., may be carried.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["post"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(ditransitive) To send (a letter, parcel, etc.) through the mail.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(ditransitive) To send by electronic mail.",
              synonyms: [],
              antonyms: [],
              example: "Please mail me the spreadsheet by the end of the day.",
            },
            {
              definition: "To contact (a person) by electronic mail.",
              synonyms: [],
              antonyms: [],
              example: "I need to mail my tutor about the deadline.",
            },
          ],
          synonyms: ["post"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Armour consisting of metal rings or plates linked together.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A contrivance of interlinked rings, for rubbing off the loose hemp on lines and white cordage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any hard protective covering of an animal, as the scales and plates of reptiles, shell of a lobster, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A spot on a bird's feather; by extension, a spotted feather.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To arm with mail.", synonyms: [], antonyms: [] },
            { definition: "To pinion.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An old French coin worth half a denier.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A monetary payment or tribute.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Rent.", synonyms: [], antonyms: [] },
            { definition: "Tax.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mail",
        "https://en.wiktionary.org/wiki/mails",
      ],
    },
  ],
  whisk: [
    {
      word: "whisk",
      phonetic: "/(h)wɪsk/",
      phonetics: [
        {
          text: "/(h)wɪsk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/whisk-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90007921",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A quick, light sweeping motion.",
              synonyms: [],
              antonyms: [],
              example:
                "With a quick whisk, she swept the cat from the pantry with her broom.",
            },
            {
              definition:
                "A kitchen utensil, made from stiff wire loops fixed to a handle, used for whipping (or a mechanical device with the same function).",
              synonyms: [],
              antonyms: [],
              example: "He used a whisk to whip up a light and airy souffle.",
            },
            {
              definition: "A bunch of twigs or hair etc, used as a brush.",
              synonyms: [],
              antonyms: [],
              example:
                "Peter dipped the whisk in lather and applied it to his face, so he could start shaving.",
            },
            {
              definition: "A small handheld broom with a small (or no) handle.",
              synonyms: [],
              antonyms: [],
              example:
                "I used a whisk to sweep the counter, then a push-broom for the floor.",
            },
            {
              definition: "A plane used by coopers for evening chines.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A kind of cape, forming part of a woman's dress.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An impertinent fellow.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To move something with quick light sweeping motions.",
              synonyms: [],
              antonyms: [],
              example: "Vernon whisked the sawdust from his workbench.",
            },
            {
              definition: "In cooking, to whip e.g. eggs or cream.",
              synonyms: [],
              antonyms: [],
              example:
                "The chef prepared to whisk the egg whites for the angel's food cake.",
            },
            {
              definition: "To move something rapidly and with no warning.",
              synonyms: [],
              antonyms: [],
              example:
                "The governess whisked the children from the room before they could see their presents.",
            },
            {
              definition: "To move lightly and nimbly.",
              synonyms: [],
              antonyms: [],
              example:
                "The children whisked down the road to the fair, laughing and chattering as they went.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/whisk"],
    },
    {
      word: "whisk",
      phonetic: "/(h)wɪsk/",
      phonetics: [
        {
          text: "/(h)wɪsk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/whisk-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90007921",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The card game whist.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/whisk"],
    },
  ],
  haven: [
    {
      word: "haven",
      phonetic: "/ˈheɪvən/",
      phonetics: [
        {
          text: "/ˈheɪvən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/haven-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1785158",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A harbour or anchorage protected from the sea.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) A place of safety; a refuge or sanctuary.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) A peaceful place.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["refuge", "sanctuary", "zoar"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To put into, or provide with a haven.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/haven"],
    },
  ],
  clasp: [
    {
      word: "clasp",
      phonetic: "/klɑːsp/",
      phonetics: [
        { text: "/klɑːsp/", audio: "" },
        {
          text: "/klæsp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/clasp-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=186521",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A fastener or holder, particularly one that clasps.",
              synonyms: [],
              antonyms: [],
              example:
                "I always have a hard time working the clasp on this necklace!",
            },
            {
              definition:
                "(in the singular) An embrace, a grasp, or handshake.",
              synonyms: [],
              antonyms: [],
              example: "He took her hand in a firm clasp.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To take hold of; to grasp; to grab tightly.",
              synonyms: [],
              antonyms: [],
              example: "They clasped hands and parted as friends.",
            },
            {
              definition:
                "To shut or fasten together with, or as if with, a clasp.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "begrip",
            "grasp",
            "grip",
            "attach",
            "join",
            "put together",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/clasp"],
    },
  ],
  sling: [
    {
      word: "sling",
      phonetic: "/ˈslɪŋ/",
      phonetics: [
        {
          text: "/ˈslɪŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sling-1-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79218881",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An instrument for throwing stones or other missiles, consisting of a short strap with two strings fastened to its ends, or with a string fastened to one end and a light stick to the other.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A kind of hanging bandage put around the neck, in which a wounded arm or hand is supported.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A loop of cloth, worn around the neck, for supporting a baby or other such load.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A loop of rope, or a rope or chain with hooks, for suspending a barrel, bale, or other heavy object, in hoisting or lowering.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A strap attached to a firearm, for suspending it from the shoulder.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(chiefly in the plural) A band of rope or iron for securing a yard to a mast.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The act or motion of hurling as with a sling; a throw; figuratively, a stroke.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A loop of rope or fabric tape used for various purposes: e.g. as part of a runner, or providing extra protection when abseiling or belaying.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A drink composed of a spirit (usually gin) and water sweetened.",
              synonyms: [],
              antonyms: [],
              example: "a Singapore sling",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To throw with a circular or arcing motion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To throw with a sling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To pass a rope around (a cask, gun, etc.) preparatory to attaching a hoisting or lowering tackle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To sell, peddle, or distribute illicitly (e.g. drugs, sex, etc.).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sling"],
    },
    {
      word: "sling",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A young or infant spider, such as one raised in captivity.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sling"],
    },
  ],
  stint: [
    {
      word: "stint",
      phonetic: "/stɪnt/",
      phonetics: [
        {
          text: "/stɪnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stint-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88671714",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A period of time spent doing or being something; a spell.",
              synonyms: [],
              antonyms: [],
              example: "He had a stint in jail.",
            },
            {
              definition: "Limit; bound; restraint; extent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Quantity or task assigned; proportion allotted.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To stop (an action); cease, desist.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To stop speaking or talking (of a subject).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be sparing or mean.",
              synonyms: [],
              antonyms: [],
              example: "The next party you throw, don't stint on the beer.",
            },
            {
              definition:
                "To restrain within certain limits; to bound; to restrict to a scant allowance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To assign a certain task to (a person), upon the performance of which he/she is excused from further labour for that day or period; to stent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of mares) To impregnate successfully; to get with foal.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stint"],
    },
    {
      word: "stint",
      phonetic: "/stɪnt/",
      phonetics: [
        {
          text: "/stɪnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stint-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88671714",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of several very small wading birds in the genus Calidris. Types of sandpiper, such as the dunlin or the sanderling.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stint"],
    },
  ],
  urges: [
    {
      word: "urges",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/urges-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88518405",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A strong desire; an itch to do something.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To press; to push; to drive; to impel; to force onward.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To press the mind or will of; to ply with motives, arguments, persuasion, or importunity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To provoke; to exasperate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To press hard upon; to follow closely.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To present in an urgent manner; to insist upon.",
              synonyms: [],
              antonyms: [],
              example: "to urge an argument; to urge the necessity of a case",
            },
            {
              definition:
                "To treat with forcible means; to take severe or violent measures with.",
              synonyms: [],
              antonyms: [],
              example: "to urge an ore with intense heat",
            },
            {
              definition: "To press onward or forward.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be pressing in argument; to insist; to persist.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "animate",
            "encourage",
            "impel",
            "incite",
            "instigate",
            "stimulate",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/urge",
        "https://en.wiktionary.org/wiki/urges",
      ],
    },
  ],
  champ: [
    {
      word: "champ",
      phonetic: "/t͡ʃæmp/",
      phonetics: [
        {
          text: "/t͡ʃæmp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/champ-1-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89033315",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/t͡ʃæmp/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Buddy, sport, mate (as a term of address)",
              synonyms: [],
              antonyms: [],
              example: "Whatcha doing, champ?",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An ongoing winner in a game or contest.",
              synonyms: [],
              antonyms: [],
              example:
                "The defending champion is expected to defeat his challenger.",
            },
            {
              definition:
                "Someone who is chosen to represent a group of people in a contest.",
              synonyms: [],
              antonyms: [],
              example:
                "Barcelona is eligible to play in FIFA Club World Cup as the champion of Europe.",
            },
            {
              definition: "Someone who fights for a cause or status.",
              synonyms: ["paladin"],
              antonyms: [],
              example: "champion of women's suffrage",
            },
            {
              definition: "Someone who fights on another's behalf.",
              synonyms: [],
              antonyms: [],
              example: "champion of the poor",
            },
          ],
          synonyms: ["paladin"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/champ",
        "https://en.wiktionary.org/wiki/champion",
      ],
    },
    {
      word: "champ",
      phonetic: "/t͡ʃæmp/",
      phonetics: [
        {
          text: "/t͡ʃæmp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/champ-2-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89033315",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/t͡ʃæmp/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A meal of mashed potatoes and scallions",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To bite or chew, especially noisily or impatiently.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/champ"],
    },
    {
      word: "champ",
      phonetic: "/ʃæmp/",
      phonetics: [{ text: "/ʃæmp/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Champagne", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/champ"],
    },
    {
      word: "champ",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(obsolete or rare) the field or ground on which carving appears in relief",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(obsolete or rare) the field of a shield",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/champ"],
    },
  ],
  piety: [
    {
      word: "piety",
      phonetic: "/ˈpaɪ.ɪ.ti/",
      phonetics: [{ text: "/ˈpaɪ.ɪ.ti/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Reverence and devotion to God.",
              synonyms: [],
              antonyms: [],
              example:
                "Colleen's piety led her to make sacrifices that most people would not have made.",
            },
            {
              definition:
                "Similar reverence to one's parents and family or to one's country.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A devout act or thought.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A belief that is accepted unthinkingly and with undue reverence.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/piety"],
    },
  ],
  chirp: [
    {
      word: "chirp",
      phonetic: "/tʃɜː(ɹ)p/",
      phonetics: [{ text: "/tʃɜː(ɹ)p/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A short, sharp or high note or noise, as of a bird or insect.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(radar, sonar, radio telescopy etc.) A pulse of signal whose frequency sweeps through a band of frequencies for the duration of the pulse.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make a short, sharp, cheerful note, as of small birds or crickets",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To speak in a high-pitched staccato",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(radar, sonar, radio telescopy etc.) To modify (a pulse of signal) so that it sweeps through a band of frequencies throughout its duration.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cheer up; to make (someone) happier.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chirp"],
    },
  ],
  pleat: [
    {
      word: "pleat",
      phonetic: "/pliːt/",
      phonetics: [{ text: "/pliːt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A fold in the fabric of a garment, usually a skirt, as a part of the design of the garment, with the purpose of adding controlled fullness and freedom of movement, or taking up excess fabric. There are many types of pleats, differing in their construction and appearance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A fold in an organ, usually a longitudinal fold in a long leaf such as that of palmetto, lending it stiffness.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A plait.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To form one or more pleats in a piece of fabric or a garment.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To plait.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pleat"],
    },
  ],
  posse: [
    {
      word: "posse",
      phonetic: "/ˈpɒ.si/",
      phonetics: [
        { text: "/ˈpɒ.si/", audio: "" },
        { text: "/ˈpɑ.si/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A group or company of people, originally especially one having hostile intent; a throng, a crowd.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A group of people summoned to help law enforcement.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A search party.", synonyms: [], antonyms: [] },
            { definition: "A criminal gang.", synonyms: [], antonyms: [] },
            {
              definition:
                "A group of (especially young) people seen as constituting a peer group or band of associates; a gang, a group of friends.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/posse"],
    },
  ],
  sunup: [
    {
      word: "sunup",
      phonetic: "/ˈsʌnʌp/",
      phonetics: [{ text: "/ˈsʌnʌp/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The time of day when the sun appears above the eastern horizon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The change in color of the sky at sunup.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dawn", "daybreak", "sunrise"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sunup"],
    },
  ],
  menus: [
    {
      word: "menus",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The details of the food to be served at a banquet; a bill of fare.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A list of dishes offered in a restaurant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A list from which the user may select an operation to be performed, often done with a keyboard, mouse, or controller under a graphical user interface",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/menu",
        "https://en.wiktionary.org/wiki/menus",
      ],
    },
  ],
  howls: [
    {
      word: "howls",
      phonetic: "/haʊlz/",
      phonetics: [{ text: "/haʊlz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The protracted, mournful cry of a dog or a wolf, or other like sound.",
              synonyms: [],
              antonyms: [],
              example: "The howl of the wind",
            },
            {
              definition: "A prolonged cry of distress or anguish; a wail.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To utter a loud, protracted, mournful sound or cry, as dogs and wolves often do.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To utter a sound expressive of pain or distress; to cry aloud and mournfully; to lament; to wail.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make a noise resembling the cry of a wild beast.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To utter with outcry.",
              synonyms: [],
              antonyms: [],
              example: "to howl derision",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/howl",
        "https://en.wiktionary.org/wiki/howls",
      ],
    },
  ],
  quake: [
    {
      word: "quake",
      phonetic: "/kweɪk/",
      phonetics: [
        { text: "/kweɪk/", audio: "" },
        {
          text: "/kweɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/quake-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50310039",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A trembling or shaking.",
              synonyms: [],
              antonyms: [],
              example:
                "We felt a quake in the apartment every time the train went by.",
            },
            {
              definition:
                "An earthquake, a trembling of the ground with force.",
              synonyms: [],
              antonyms: [],
              example:
                "California is plagued by quakes; there are a few minor ones almost every month.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To tremble or shake.",
              synonyms: [],
              antonyms: [],
              example: "I felt the ground quaking beneath my feet.",
            },
            {
              definition:
                "To be in a state of fear, shock, amazement, etc., such as might cause one to tremble.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/quake"],
    },
  ],
  knack: [
    {
      word: "knack",
      phonetic: "/næk/",
      phonetics: [
        {
          text: "/næk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/knack-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1165018",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A readiness in performance; aptness at doing something.",
              synonyms: ["dexterity", "facility", "skill"],
              antonyms: [],
            },
            {
              definition: "A petty contrivance; a toy.",
              synonyms: ["knickknack", "plaything", "toy"],
              antonyms: [],
            },
            {
              definition:
                "Something performed, or to be done, requiring aptness and dexterity.",
              synonyms: ["device", "trick"],
              antonyms: [],
            },
          ],
          synonyms: [
            "device",
            "trick",
            "dexterity",
            "facility",
            "skill",
            "knickknack",
            "plaything",
            "toy",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To crack; to make a sharp, abrupt noise; to chink.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To speak affectedly.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/knack"],
    },
  ],
  plaza: [
    {
      word: "plaza",
      phonetic: "/ˈplæzə/",
      phonetics: [
        {
          text: "/ˈplæzə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/plaza-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762740",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A town's public square.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An open area used for gathering in a city, often having small trees and sitting benches.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A strip mall.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["square"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/plaza"],
    },
  ],
  fiend: [
    {
      word: "fiend",
      phonetic: "/fiːnd/",
      phonetics: [
        {
          text: "/fiːnd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fiend-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780076",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A devil or demon; a malignant or diabolical being; an evil spirit.",
              synonyms: ["monster"],
              antonyms: [],
            },
            {
              definition: "A very evil person.",
              synonyms: ["monster"],
              antonyms: [],
            },
            {
              definition: "An enemy; a foe.",
              synonyms: [],
              antonyms: [],
              example:
                "Religion teaches us to love everybody, be one fiend or friend.",
            },
            {
              definition:
                "The enemy of mankind, specifically, the Devil; Satan.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An addict or fanatic.",
              synonyms: [],
              antonyms: [],
              example: "He's been a jazz fiend since his teenage years.",
            },
          ],
          synonyms: ["monster", "monster"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To yearn; to be desperate (for something).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fiend"],
    },
  ],
  caked: [
    {
      word: "caked",
      phonetic: "/keɪkt/",
      phonetics: [
        {
          text: "/keɪkt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/caked-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79325622",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "Coat (something) with a crust of solid material.",
              synonyms: ["crust", "encrust"],
              antonyms: [],
              example: "His shoes are caked with mud.",
            },
            {
              definition: "To form into a cake, or mass.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["crust", "encrust"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cackle like a goose.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(smoking, of a pipe) Empty with nothing left to smoke but ash.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["kicked"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cake",
        "https://en.wiktionary.org/wiki/caked",
      ],
    },
  ],
  bangs: [
    {
      word: "bangs",
      phonetic: "/bæŋz/",
      phonetics: [{ text: "/bæŋz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A sudden percussive noise.",
              synonyms: [],
              antonyms: [],
              example:
                "When he struck it with a hammer, there was a loud bang.",
            },
            {
              definition: "A strike upon an object causing such a noise.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An explosion.", synonyms: [], antonyms: [] },
            {
              definition: "The symbol !, known as an exclamation point.",
              synonyms: [],
              antonyms: [],
              example: "An e-mail address with an ! is called a bang path.",
            },
            {
              definition:
                "A factorial, in mathematics, because the factorial of n is often written as n!",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An act of sexual intercourse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An offbeat figure typical of reggae songs and played on guitar and piano.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An explosive product.",
              synonyms: [],
              antonyms: [],
              example: "Load the bang into the hole.",
            },
            {
              definition: "An injection, a shot (of a narcotic drug).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(Boston area) An abrupt left turn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Strong smell (of)",
              synonyms: [],
              antonyms: [],
              example: "There was a bang of onions off his breath.",
            },
            { definition: "A thrill.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "exclamation mark",
            "exclamation point",
            "blow",
            "strike",
            "explosion",
          ],
          antonyms: ["hang"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make sudden loud noises, and often repeatedly, especially by exploding or hitting something.",
              synonyms: [],
              antonyms: [],
              example:
                "My head was banging after drinking all night at the concert.",
            },
            {
              definition: "To hit hard.",
              synonyms: [],
              antonyms: [],
              example: "David and Mary banged into each other.",
            },
            {
              definition: "To engage in sexual intercourse.",
              synonyms: ["do it", "have sex", "nail"],
              antonyms: [],
              example: "We can hear the couple banging upstairs.",
            },
            {
              definition: '(with "in") To hammer or to hit anything hard.',
              synonyms: [],
              antonyms: [],
              example: "Hold the picture while I bang in this nail.",
            },
            {
              definition:
                "To cut squarely across, as the tail of a horse, or a person's forelock; to cut (the hair).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To inject intravenously.",
              synonyms: [],
              antonyms: [],
              example: "Do you smoke meth? No, I bang it.",
            },
          ],
          synonyms: ["do it", "have sex", "nail"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Hair hanging over the forehead.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hairstyle including such hair, especially cut straight across the forehead.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bang", "forelocks", "fringe", "bang", "fringe"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Brucellosis, a bacterial disease.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bang",
        "https://en.wiktionary.org/wiki/bangs",
      ],
    },
    {
      word: "bangs",
      phonetic: "/bæŋz/",
      phonetics: [{ text: "/bæŋz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Brucellosis, a bacterial disease.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bangs"],
    },
  ],
  erupt: [
    {
      word: "erupt",
      phonetic: "/ɪˈɹʌpt/",
      phonetics: [
        {
          text: "/ɪˈɹʌpt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/erupt-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780063",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To eject something violently (such as lava or water, as from a volcano or geyser).",
              synonyms: [],
              antonyms: [],
              example: "The volcano erupted, spewing lava across a wide area.",
            },
            {
              definition: "To burst forth; to break out.",
              synonyms: [],
              antonyms: [],
              example:
                "The third molar tooth erupts late in most people, and sometimes does not appear at all.",
            },
            {
              definition: "To spontaneously release pressure or tension.",
              synonyms: [],
              antonyms: [],
              example: "The crowd erupted in anger.",
            },
          ],
          synonyms: ["burst"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/erupt"],
    },
  ],
  poker: [
    {
      word: "poker",
      phonetic: "/ˈpəʊkə/",
      phonetics: [
        {
          text: "/ˈpəʊkə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/poker-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88681200",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈpoʊkɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A metal rod, generally of wrought iron, for adjusting the burning logs or coals in a fire; a firestick.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A tool like a soldering iron for making poker drawings.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "One who pokes.", synonyms: [], antonyms: [] },
            {
              definition: "A kind of duck, the pochard.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["firestick", "stoker"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/poker"],
    },
    {
      word: "poker",
      phonetic: "/ˈpəʊkə/",
      phonetics: [
        {
          text: "/ˈpəʊkə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/poker-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88681200",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈpoʊkɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of various card games in which, following each of one or more rounds of dealing or revealing cards, the players in sequence make tactical bets or drop out, the bets forming a pool to be taken either by the sole remaining player or, after all rounds and bets have been completed, by those remaining players who hold a superior hand according to a standard ranking of hand values for the game.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "All the four cards of the same rank.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/poker"],
    },
    {
      word: "poker",
      phonetic: "/ˈpəʊkə/",
      phonetics: [
        {
          text: "/ˈpəʊkə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/poker-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88681200",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈpoʊkɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any imagined frightful object, especially one supposed to haunt the darkness; a bugbear.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/poker"],
    },
  ],
  olden: [
    {
      word: "olden",
      phonetic: "/ˈəʊl.dn̩/",
      phonetics: [
        { text: "/ˈəʊl.dn̩/", audio: "" },
        { text: "[ˈɔldn̩]", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "From or relating to a previous era.",
              synonyms: [],
              antonyms: [],
              example: "olden days, olden times",
            },
            { definition: "Old; ancient.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/olden"],
    },
    {
      word: "olden",
      phonetic: "/ˈəʊl.dn̩/",
      phonetics: [
        { text: "/ˈəʊl.dn̩/", audio: "" },
        { text: "[ˈɔldn̩]", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To grow old; age; assume an older appearance or character; become affected by age.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["elden", "mature"],
          antonyms: ["youngen"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/olden"],
    },
  ],
  cramp: [
    {
      word: "cramp",
      phonetic: "/kɹæmp/",
      phonetics: [
        {
          text: "/kɹæmp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cramp-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=94707599",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A painful contraction of a muscle which cannot be controlled.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That which confines or contracts; a restraint; a shackle; a hindrance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A clamp for carpentry or masonry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of wood having a curve corresponding to that of the upper part of the instep, on which the upper leather of a boot is stretched to give it the requisite shape.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(of a muscle) To contract painfully and uncontrollably.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To affect with cramps or spasms.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To prohibit movement or expression of.",
              synonyms: [],
              antonyms: [],
              example: "You're cramping my style.",
            },
            {
              definition:
                "To restrain to a specific physical position, as if with a cramp.",
              synonyms: [],
              antonyms: [],
              example: "You're going to need to cramp the wheels on this hill.",
            },
            {
              definition:
                "To fasten or hold with, or as if with, a cramp iron.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) To bind together; to unite.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To form on a cramp.",
              synonyms: [],
              antonyms: [],
              example: "to cramp boot legs",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Cramped; narrow", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cramp"],
    },
  ],
  voter: [
    {
      word: "voter",
      phonetic: "/ˈvəʊtə(ɹ)/",
      phonetics: [
        { text: "/ˈvəʊtə(ɹ)/", audio: "" },
        { text: "/ˈvoʊtɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Someone who votes.",
              synonyms: [],
              antonyms: [],
              example:
                "In your opinion, should we allow 16 and 17 year olds to become voters?",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/voter"],
    },
  ],
  poses: [
    {
      word: "poses",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Common cold, head cold; catarrh.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Position, posture, arrangement (especially of the human body).",
              synonyms: [],
              antonyms: [],
              example: "Please adopt a more graceful pose for my camera.",
            },
            { definition: "Affectation.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To place in an attitude or fixed position, for the sake of effect.",
              synonyms: [],
              antonyms: [],
              example: "To pose a model for a picture.",
            },
            {
              definition: "To ask; to set (a test, quiz, riddle, etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To constitute (a danger, a threat, a risk, etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                '(in the phrase "to pose as") To falsely impersonate (another person or occupation) primarily for the purpose of accomplishing something or reaching a goal.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To assume or maintain a pose; to strike an attitude.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To behave affectedly in order to attract interest or admiration.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To interrogate; to question.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To question with a view to puzzling; to embarrass by questioning or scrutiny; to bring to a stand.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To ask (someone) questions; to interrogate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To puzzle, non-plus, or embarrass with difficult questions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To perplex or confuse (someone).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pose",
        "https://en.wiktionary.org/wiki/poses",
      ],
    },
  ],
  manly: [
    {
      word: "manly",
      phonetic: "/ˈmænli/",
      phonetics: [
        {
          text: "/ˈmænli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/manly-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=86957492",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having the characteristics of a man.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Having qualities befitting a man; courageous, resolute, noble.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["womanly"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/manly"],
    },
  ],
  slump: [
    {
      word: "slump",
      phonetic: "/slʌmp/",
      phonetics: [
        {
          text: "/slʌmp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/slump-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79268698",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A heavy or helpless collapse; a slouching or drooping posture; a period of poor activity or performance, especially an extended period.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A measure of the fluidity of freshly mixed concrete, based on how much the concrete formed in a standard slump cone sags when the cone is removed.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A boggy place.", synonyms: [], antonyms: [] },
            {
              definition:
                "The noise made by anything falling into a hole, or into a soft, miry place.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The gross amount; the mass; the lump.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To collapse heavily or helplessly.",
              synonyms: [],
              antonyms: [],
              example: "Exhausted, he slumped down onto the sofa.",
            },
            {
              definition: "To decline or fall off in activity or performance.",
              synonyms: [],
              antonyms: [],
              example: "Real estate prices slumped during the recession.",
            },
            { definition: "To slouch or droop.", synonyms: [], antonyms: [] },
            {
              definition: "To lump; to throw together messily.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To fall or sink suddenly through or in, when walking on a surface, as on thawing snow or ice, a bog, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(slang) To cause to collapse; to hit hard; to render unsconscious; to kill.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/slump"],
    },
  ],
  fined: [
    {
      word: "fined",
      phonetic: "/faɪnd/",
      phonetics: [
        {
          text: "/faɪnd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fined-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=711108",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make finer, purer, or cleaner; to purify or clarify.",
              synonyms: [],
              antonyms: [],
              example: "to fine gold",
            },
            {
              definition: "To become finer, purer, or cleaner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make finer, or less coarse, as in bulk, texture, etc.",
              synonyms: [],
              antonyms: [],
              example: "to fine the soil",
            },
            {
              definition: "To change by fine gradations.",
              synonyms: [],
              antonyms: [],
              example:
                "to fine down a ship's lines, i.e. to diminish her lines gradually",
            },
            {
              definition: "To clarify (wine and beer) by filtration.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To become gradually fine; to diminish; to dwindle (with away, down, or off).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["clarify", "purify", "refine"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To issue a fine as punishment to (someone).",
              synonyms: [],
              antonyms: [],
              example:
                "She was fined a thousand dollars for littering, but she appealed.",
            },
            { definition: "To pay a fine.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["amerce"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To finish; to cease.", synonyms: [], antonyms: [] },
            {
              definition: "To cause to cease; to stop.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fine",
        "https://en.wiktionary.org/wiki/fined",
      ],
    },
  ],
  grips: [
    {
      word: "grips",
      phonetic: "/ɡɹɪps/",
      phonetics: [{ text: "/ɡɹɪps/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To take hold of, particularly with the hand.",
              synonyms: [],
              antonyms: [],
              example: "After a few slips, the tires gripped the pavement.",
            },
            {
              definition:
                "To help or assist, particularly in an emotional sense.",
              synonyms: [],
              antonyms: [],
              example: "He grips me.",
            },
            {
              definition:
                "To do something with another that makes you happy/gives you relief.",
              synonyms: [],
              antonyms: [],
              example:
                "Let’s grip (get a coffee, hang, take a break, see a movie, etc.)",
            },
            { definition: "To trench; to drain.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "hang out",
            "aid",
            "help out",
            "lend a hand",
            "clasp",
            "grasp",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A hold or way of holding, particularly with the hand.",
              synonyms: [],
              antonyms: [],
              example: "It's good to have a firm grip when shaking hands.",
            },
            {
              definition: "A handle or other place to grip.",
              synonyms: [],
              antonyms: [],
              example:
                "There are several good grips on the northern face of this rock.",
            },
            {
              definition:
                "A visual component on a window etc. enabling it to be resized and/or moved.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(film production) A person responsible for handling equipment on the set.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A channel cut through a grass verge (especially for the purpose of draining water away from the highway).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A lot of something.",
              synonyms: [],
              antonyms: [],
              example: "That is a grip of cheese.",
            },
            {
              definition: "A long time.",
              synonyms: [],
              antonyms: [],
              example: "I haven't seen you in a grip.",
            },
            {
              definition: "A small travelling-bag or gripsack.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An apparatus attached to a car for clutching a traction cable.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Assistance; help or encouragement.",
              synonyms: [],
              antonyms: [],
              example: "He gave me a grip.",
            },
            {
              definition:
                "A helpful, interesting, admirable, or inspiring person.",
              synonyms: [],
              antonyms: [],
              example: "You're a real grip.",
            },
            {
              definition: "As much as one can hold in a hand; a handful.",
              synonyms: [],
              antonyms: [],
              example: "I need to get a grip of nails for my project.",
            },
            {
              definition: "A tenacious grasp; a holding fast.",
              synonyms: [],
              antonyms: [],
              example: "in the grip of a blackmailer",
            },
            {
              definition: "A device for grasping or holding fast to something.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small ditch or trench; a channel to carry off water or other liquid; a drain.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The griffin.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Influenza, the flu.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/grip",
        "https://en.wiktionary.org/wiki/grippe",
        "https://en.wiktionary.org/wiki/grips",
      ],
    },
  ],
  gaped: [
    {
      word: "gaped",
      phonetic: "/ɡeɪpt/",
      phonetics: [{ text: "/ɡeɪpt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To open the mouth wide, especially involuntarily, as in a yawn, anger, or surprise.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To stare in wonder.", synonyms: [], antonyms: [] },
            {
              definition: "To open wide; to display a gap.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of a cat: to open the passage to the vomeronasal organ, analogous to the flehming in other animals.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gape",
        "https://en.wiktionary.org/wiki/gaped",
      ],
    },
  ],
  purge: [
    {
      word: "purge",
      phonetic: "/pɜːdʒ/",
      phonetics: [
        { text: "/pɜːdʒ/", audio: "" },
        {
          text: "/pɝdʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/purge-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=35848055",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "An act of purging.", synonyms: [], antonyms: [] },
            {
              definition: "An evacuation of the bowels or a vomiting.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A cleansing of pipes.", synonyms: [], antonyms: [] },
            {
              definition:
                "A forcible removal of people, for example, from political activity.",
              synonyms: [],
              antonyms: [],
              example:
                "Stalin liked to ensure that his purges were not reversible.",
            },
            {
              definition:
                "That which purges; especially, a medicine that evacuates the intestines; a cathartic.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To clean thoroughly; to cleanse; to rid of impurities.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To free from sin, guilt, or the burden or responsibility of misdeeds",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To remove by cleansing; to wash away.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To void or evacuate (the bowels or the stomach); to defecate or vomit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause someone to purge, operate on (somebody) as or with a cathartic or emetic, or in a similar manner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To clear of a charge, suspicion, or imputation",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To clarify; to clear the dregs from (liquor).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To become pure, as by clarification.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To have or produce frequent evacuations from the intestines, as by means of a cathartic.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To trim, dress or prune.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/purge"],
    },
  ],
  hiked: [
    {
      word: "hiked",
      phonetic: "/haɪkt/",
      phonetics: [{ text: "/haɪkt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To take a long walk for pleasure or exercise.",
              synonyms: [],
              antonyms: [],
              example:
                "Don't forget to bring the map when we go hiking tomorrow.",
            },
            {
              definition: "To unfairly or suddenly raise a price.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To snap the ball to start a play.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To lean out to the windward side of a sailboat in order to counterbalance the effects of the wind on the sails.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To pull up or tug upwards sharply.",
              synonyms: [],
              antonyms: [],
              example: "She hiked her skirt up.",
            },
          ],
          synonyms: ["lean out", "sit out", "tramp"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hike",
        "https://en.wiktionary.org/wiki/hiked",
      ],
    },
  ],
  maize: [
    {
      word: "maize",
      phonetic: "/meɪz/",
      phonetics: [
        {
          text: "/meɪz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/maize-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769993",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Corn; a type of grain of the species Zea mays.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "Indian corn",
            "corn",
            "green corn",
            "sugar corn",
            "sweet corn",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/maize"],
    },
  ],
  fluff: [
    {
      word: "fluff",
      phonetic: "/flʌf/",
      phonetics: [
        {
          text: "/flʌf/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fluff-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75269547",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Anything light, soft or fuzzy, especially fur, hair, feathers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Anything inconsequential or superficial.",
              synonyms: [],
              antonyms: [],
              example:
                "That article was basically a bunch of fluff. It didn't say anything substantive.",
            },
            {
              definition:
                "A lapse or mistake, especially a mistake in an actor's lines.",
              synonyms: ["flub"],
              antonyms: [],
            },
            {
              definition: "Marshmallow creme.",
              synonyms: [],
              antonyms: [],
              example:
                "That New England-style salami and fluff sandwich sure hit the spot!",
            },
            {
              definition: "A passive partner in a lesbian relationship.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A fart.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "blooper",
            "blunder",
            "boo-boo",
            "defect",
            "error",
            "fault",
            "faux pas",
            "gaffe",
            "lapse",
            "mistake",
            "slip",
            "stumble",
            "thinko",
            "BS",
            "all talk",
            "cruft",
            "hype",
            "fuzz",
            "oose",
            "puff",
            "ruffle",
            "flub",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make something fluffy.",
              synonyms: [],
              antonyms: [],
              example: "The cat fluffed its tail.",
            },
            {
              definition: "To become fluffy, puff up.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To move lightly like fluff.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of an actor or announcer) To make a mistake in one's lines.",
              synonyms: ["flub"],
              antonyms: [],
            },
            {
              definition:
                "To do incorrectly, for example mishit, miskick, miscue etc.",
              synonyms: ["flub"],
              antonyms: [],
            },
            { definition: "To fart.", synonyms: [], antonyms: [] },
            {
              definition:
                "To arouse (a male pornographic actor) before filming.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["flub", "flub"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fluff"],
    },
  ],
  strut: [
    {
      word: "strut",
      phonetic: "/stɹʌt/",
      phonetics: [
        {
          text: "/stɹʌt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/strut-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=86872702",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/stɹʌt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Protuberance, air pressure",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To swell; protuberate; bulge or spread out.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(originally said of fowl) To stand or walk stiffly, with the tail erect and spread out.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To walk proudly or haughtily.",
              synonyms: [],
              antonyms: [],
              example:
                "He strutted about the yard, thinking himself master of all he surveyed.",
            },
            {
              definition:
                "To cause to swell; enlarge; give more importance to.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To protrude; cause to bulge.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["swagger"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/strut"],
    },
    {
      word: "strut",
      phonetic: "/stɹʌt/",
      phonetics: [
        {
          text: "/stɹʌt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/strut-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=86872702",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/stɹʌt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A proud step or walk, with the head erect; affected dignity in walking.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/strut"],
    },
    {
      word: "strut",
      phonetic: "/stɹʌt/",
      phonetics: [
        {
          text: "/stɹʌt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/strut-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=86872702",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/stɹʌt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Swelling out; protuberant; bulging.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/strut"],
    },
    {
      word: "strut",
      phonetic: "/stɹʌt/",
      phonetics: [
        {
          text: "/stɹʌt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/strut-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=86872702",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/stɹʌt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A support rod.", synonyms: ["rib"], antonyms: [] },
            {
              definition: "An instrument for adjusting the pleats of a ruff.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["rib"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To brace or support by a strut or struts; hold in place or strengthen by an upright, diagonal, or transverse support.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/strut"],
    },
  ],
  sloop: [
    {
      word: "sloop",
      phonetic: "/sluːp/",
      phonetics: [
        {
          text: "/sluːp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sloop-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88681633",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A single-masted sailboat with only one headsail.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sailing warship, smaller than a frigate, with its guns all on one deck.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sloop-of-war, smaller than a frigate, larger than a corvette.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sloop"],
    },
  ],
  prowl: [
    {
      word: "prowl",
      phonetic: "/pɹaʊl/",
      phonetics: [
        {
          text: "/pɹaʊl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/prowl-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=94709410",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act of prowling.",
              synonyms: [],
              antonyms: [],
              example: "I'm going on a midnight prowl.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To rove over, through, or about in a stealthy manner; especially, to search in, as for prey or booty.",
              synonyms: [],
              antonyms: [],
              example:
                "It's tough to sneak vandalism into Wikipedia as there are plenty of other users prowling the Recent Changes page.",
            },
            {
              definition: "To idle; to go about aimlessly.",
              synonyms: [],
              antonyms: [],
              example:
                "That dandy has nothing better to do than prowl around town all day in his pinstripe suit.",
            },
            {
              definition: "To collect by plunder.",
              synonyms: [],
              antonyms: [],
              example: "to prowl money",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/prowl"],
    },
  ],
  roach: [
    {
      word: "roach",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/roach-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79218860",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ɹəʊtʃ/", audio: "" },
        { text: "/ɹoʊtʃ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Certain members of the fish family Cyprinidae, including:",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/roach"],
    },
    {
      word: "roach",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/roach-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79218860",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ɹəʊtʃ/", audio: "" },
        { text: "/ɹoʊtʃ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A cockroach.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/roach"],
    },
    {
      word: "roach",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/roach-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79218860",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ɹəʊtʃ/", audio: "" },
        { text: "/ɹoʊtʃ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An extra curve of material added to the leech (aft edge) of a sail to increase the sail area.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A kind of headdress worn by some of the indigenous peoples of North America.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/roach"],
    },
    {
      word: "roach",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/roach-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79218860",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ɹəʊtʃ/", audio: "" },
        { text: "/ɹoʊtʃ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(smoking) Marijuana; cannabis used as a drug.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(smoking) A butt of a marijuana cigarette.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(smoking) The filter of a rolled cigarette or joint, made from card or paper.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/roach"],
    },
    {
      word: "roach",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/roach-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79218860",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ɹəʊtʃ/", audio: "" },
        { text: "/ɹoʊtʃ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A bed or stratum of some mineral.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Gritty or coarse rock; especially Portland stone or similar limestone.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/roach"],
    },
  ],
  cocks: [
    {
      word: "cocks",
      phonetic: "/kɒks/",
      phonetics: [
        {
          text: "/kɒks/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cocks-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89748466",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A male bird, especially:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A valve or tap for controlling flow in plumbing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The hammer of a firearm trigger mechanism.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The notch of an arrow or crossbow.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The penis.", synonyms: [], antonyms: [] },
            {
              definition: "The circle at the end of the rink.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The state of being cocked; an upward turn, tilt or angle.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A stupid person.", synonyms: [], antonyms: [] },
            { definition: "Nonsense; rubbish.", synonyms: [], antonyms: [] },
            {
              definition: "(Tasmania) Term of address.",
              synonyms: [],
              antonyms: [],
              example: "All right, cock?",
            },
            {
              definition: "A boastful tilt of one's head or hat.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Shuttlecock", synonyms: [], antonyms: [] },
            {
              definition: "A vane in the shape of a cock; a weathercock.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A chief man; a leader or master.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The crow of a cock, especially the first crow in the morning; cockcrow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A male fish, especially a salmon or trout.",
              synonyms: ["cockfish"],
              antonyms: [],
            },
            {
              definition: "The style or gnomon of a sundial.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The indicator of a balance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The bridge piece that affords a bearing for the pivot of a balance in a clock or watch.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["cockbird", "rooster", "stopcock", "cockfish"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To lift the cock of a firearm or crossbow; to prepare (a gun or crossbow) to be fired.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be prepared to be triggered by having the cock lifted.",
              synonyms: [],
              antonyms: [],
              example: "In the darkness, the gun cocked loudly.",
            },
            { definition: "To erect; to turn up.", synonyms: [], antonyms: [] },
            { definition: "To copulate with.", synonyms: [], antonyms: [] },
            {
              definition:
                "To turn or twist something upwards or to one side; to lift or tilt (e.g. headwear) boastfully.",
              synonyms: [],
              antonyms: [],
              example: "He cocked his hat jauntily.",
            },
            {
              definition:
                "To turn (the eye) obliquely and partially close its lid, as an expression of derision or insinuation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To strut; to swagger; to look big, pert, or menacing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make a nestle-cock of, to pamper or spoil (of children)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(where it is still sometimes used) Vulva, vagina.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A small conical pile of hay.",
              synonyms: [],
              antonyms: [],
              example: "The farmhands stack the hay into cocks",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To form into piles.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Abbreviation of cock-boat, a type of small boat.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cock",
        "https://en.wiktionary.org/wiki/cocks",
      ],
    },
  ],
  bland: [
    {
      word: "bland",
      phonetic: "/blænd/",
      phonetics: [
        {
          text: "/blænd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bland-ca.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=53863924",
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Having a soothing effect; not irritating or stimulating.",
              synonyms: [],
              antonyms: [],
              example: "a bland diet",
            },
            {
              definition: "Lacking in taste, flavor, or vigor.",
              synonyms: [],
              antonyms: [],
              example: "The coffee was bland.",
            },
            {
              definition: "Lacking interest; boring; dull.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Mild; soft, gentle, balmy; smooth in manner; suave.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bland"],
    },
    {
      word: "bland",
      phonetic: "/blænd/",
      phonetics: [
        {
          text: "/blænd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bland-ca.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=53863924",
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To mix; blend; mingle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To connect; associate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bland"],
    },
    {
      word: "bland",
      phonetic: "/blænd/",
      phonetics: [
        {
          text: "/blænd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bland-ca.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=53863924",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Mixture; union.", synonyms: [], antonyms: [] },
            {
              definition:
                "A summer beverage prepared from the whey of churned milk, common among the inhabitants of the Shetland Islands.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bland"],
    },
  ],
  dials: [
    {
      word: "dials",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A graduated, circular scale over which a needle moves to show a measurement (such as speed).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A clock face.", synonyms: [], antonyms: [] },
            { definition: "A sundial.", synonyms: [], antonyms: [] },
            {
              definition:
                "A panel on a radio etc showing wavelengths or channels; a knob that is turned to change the wavelength etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A disk with finger holes on a telephone; used to select the number to be called.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A person's face.", synonyms: [], antonyms: [] },
            { definition: "A miner's compass.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To control or select something with a dial, or (figuratively) as if with a dial.",
              synonyms: [],
              antonyms: [],
              example:
                "President Trump has recently dialled down the rhetoric.",
            },
            {
              definition:
                "To select a number, or to call someone, on a telephone.",
              synonyms: [],
              antonyms: [],
              example: "In an emergency dial 999.",
            },
            {
              definition: "To use a dial or a telephone.",
              synonyms: [],
              antonyms: [],
              example: "Please be careful when dialling.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dial",
        "https://en.wiktionary.org/wiki/dials",
      ],
    },
  ],
  plume: [
    {
      word: "plume",
      phonetic: "/ˈpljuːm/",
      phonetics: [
        { text: "/ˈpljuːm/", audio: "" },
        {
          text: "/ˈplum/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/plume-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780273",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A feather of a bird, especially a large or showy one used as a decoration.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cluster of feathers worn as an ornament, especially on a helmet; a hackle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A token of honour or prowess; that on which one prides oneself; a prize or reward.",
              synonyms: ["feather in one's cap"],
              antonyms: [],
            },
            {
              definition:
                "The vane of a feather, especially when on a quill pen or the fletching of an arrow.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Short for plume moth.", synonyms: [], antonyms: [] },
            {
              definition: "Things resembling a feather.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["feather in one's cap"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/plume"],
    },
    {
      word: "plume",
      phonetic: "/ˈpljuːm/",
      phonetics: [
        { text: "/ˈpljuːm/", audio: "" },
        {
          text: "/ˈplum/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/plume-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780273",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To adorn, cover, or furnish with feathers or plumes, or as if with feathers or plumes.",
              synonyms: ["feather", "fledge"],
              antonyms: [],
            },
            {
              definition:
                "Chiefly of a bird: to arrange and preen the feathers of, specifically in preparation for flight; hence , to prepare for (something).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) To congratulate (oneself) proudly, especially concerning something unimportant or when taking credit for another person's effort; to self-congratulate.",
              synonyms: [],
              antonyms: [],
              example: "He plumes himself on his skill.",
            },
            {
              definition: "To strip (a bird) of feathers; to pluck.",
              synonyms: ["unplume"],
              antonyms: [],
            },
            {
              definition:
                "Of a dispersed substance such as dust or smoke: to fan out or spread in a cloud.",
              synonyms: [],
              antonyms: [],
              example:
                "Smoke plumed from his pipe, then slowly settled towards the floor.",
            },
          ],
          synonyms: ["feather", "fledge", "unplume"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/plume"],
    },
  ],
  slaps: [
    {
      word: "slaps",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A blow, especially one given with the open hand, or with something broad and flat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The sound of such a blow.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Makeup; cosmetics.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To give a slap to.",
              synonyms: [],
              antonyms: [],
              example: "She slapped him in response to the insult.",
            },
            {
              definition: "To cause something to strike soundly.",
              synonyms: [],
              antonyms: [],
              example: "He slapped the reins against the horse's back.",
            },
            {
              definition: "To strike soundly against something.",
              synonyms: [],
              antonyms: [],
              example: "The rain slapped against the window-panes.",
            },
            {
              definition: "To be excellent.",
              synonyms: [],
              antonyms: [],
              example: "The band's new single slaps.",
            },
            {
              definition: "To place, to put carelessly.",
              synonyms: [],
              antonyms: [],
              example: "We'd better slap some fresh paint on that wall.",
            },
            {
              definition: "To impose a penalty, etc. on (someone).",
              synonyms: [],
              antonyms: [],
              example: "I was slapped with a parking fine.",
            },
            {
              definition: "To play slap bass on (an instrument).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A two-player reaction game in which opponents slap each other's hands.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/slap",
        "https://en.wiktionary.org/wiki/slaps",
      ],
    },
  ],
  soups: [
    {
      word: "soups",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of various dishes commonly made by combining liquids, such as water or stock with other ingredients, such as meat and vegetables, that contribute flavor and texture.",
              synonyms: [],
              antonyms: [],
              example: "Pho is a traditional Vietnamese soup.",
            },
            {
              definition:
                "Any mixture or substance suggestive of soup consistency.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To feed: to provide with soup or a meal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To develop (film) in a (chemical) developing solution.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "Alternative form of sup",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Alternative form of sup",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To sup or swallow.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To breathe out; to draw out.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To sweep.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/soup",
        "https://en.wiktionary.org/wiki/soups",
      ],
    },
  ],
  dully: [
    {
      word: "dully",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition:
                "In a dull manner; without liveliness; without lustre.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dully"],
    },
  ],
  wills: [
    {
      word: "wills",
      phonetic: "/wɪlz/",
      phonetics: [{ text: "/wɪlz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One's independent faculty of choice; the ability to be able to exercise one's choice or intention.",
              synonyms: [],
              antonyms: [],
              example:
                "Of course, man's will is often regulated by his reason.",
            },
            {
              definition:
                "One's intention or decision; someone's orders or commands.",
              synonyms: [],
              antonyms: [],
              example: "Eventually I submitted to my parents' will.",
            },
            {
              definition:
                "The act of choosing to do something; a person’s conscious intent or volition.",
              synonyms: [],
              antonyms: [],
              example: "Most creatures have a will to live.",
            },
            {
              definition:
                "Law A formal declaration of one's intent concerning the disposal of one's property and holdings after death; the legal document stating such wishes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "That which is desired; one's wish.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Desire, longing. (Now generally merged with later senses.)",
              synonyms: [],
              antonyms: [],
              example:
                "He felt a great will to make a pilgrimage to the Holy Land.",
            },
          ],
          synonyms: ["last will", "last will and testament", "testament"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To wish, desire.", synonyms: [], antonyms: [] },
            {
              definition: "To instruct (that something be done) in one's will.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To try to make (something) happen by using one's will (intention).",
              synonyms: [],
              antonyms: [],
              example: "All the fans were willing their team to win the game.",
            },
            {
              definition:
                "To bequeath (something) to someone in one's will (legal document).",
              synonyms: [],
              antonyms: [],
              example: "He willed his stamp collection to the local museum.",
            },
          ],
          synonyms: ["bequeath", "leave"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/will",
        "https://en.wiktionary.org/wiki/wills",
      ],
    },
  ],
  foams: [
    {
      word: "foams",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A substance composed of a large collection of bubbles or their solidified remains.",
              synonyms: [],
              antonyms: [],
              example: "He doesn't like so much foam in his beer.",
            },
            {
              definition:
                "A substance formed by trapping pockets of gas in a liquid or solid.",
              synonyms: [],
              antonyms: [],
              example: "A foam mat can soften a hard seat.",
            },
            {
              definition: "(by extension) Sea foam; the sea.",
              synonyms: [],
              antonyms: [],
              example: "He is in Europe, across the foam.",
            },
            { definition: "Fury.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To form or emit foam.", synonyms: [], antonyms: [] },
            {
              definition: "To spew saliva as foam, to foam at the mouth.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/foam",
        "https://en.wiktionary.org/wiki/foams",
      ],
    },
  ],
  solos: [
    {
      word: "solos",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A piece of music for one performer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A job or performance done by one person alone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(games) A card game similar to whist in which each player plays against the others in turn without a partner",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A single shot of espresso.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An instance of soloing the football.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/solo",
        "https://en.wiktionary.org/wiki/solos",
      ],
    },
  ],
  skier: [
    {
      word: "skier",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "One who skis.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/skier"],
    },
    {
      word: "skier",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A ball hit high in the air, often leading to a catch.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/skier"],
    },
  ],
  eaves: [
    {
      word: "eaves",
      phonetic: "/iːvz/",
      phonetics: [
        {
          text: "/iːvz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/eaves-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80535720",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The underside of a roof that extends beyond the external walls of a building.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/eaves"],
    },
  ],
  totem: [
    {
      word: "totem",
      phonetic: "/ˈtəʊtəm/",
      phonetics: [
        { text: "/ˈtəʊtəm/", audio: "" },
        { text: "/ˈtoʊtəm/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any natural object or living creature that serves as an emblem of a tribe, clan or family.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The representation of such object or creature.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The clan whose kinship is defined in reference to such an object or creature.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An arbitrarily chosen object serving as a reminder to check whether one is awake or not, to aid in having lucid dreams.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/totem"],
    },
  ],
  fused: [
    {
      word: "fused",
      phonetic: "/fjuːzd/",
      phonetics: [{ text: "/fjuːzd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To melt together; to blend; to mix indistinguishably.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To melt together.", synonyms: [], antonyms: [] },
            {
              definition: "To furnish with or install a fuse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To form a bicyclic compound from two similar or different types of ring such that two or more atoms are shared between the resulting rings",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["meld", "smelt"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Joined together by fusing",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Melted", synonyms: [], antonyms: [] },
            { definition: "Furnished with a fuse", synonyms: [], antonyms: [] },
            {
              definition:
                "Having at least one bond between two atoms that is part of two or more separate rings",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fuse",
        "https://en.wiktionary.org/wiki/fused",
      ],
    },
  ],
  latex: [
    {
      word: "latex",
      phonetic: "/ˈleɪtɛks/",
      phonetics: [
        { text: "/ˈleɪtɛks/", audio: "" },
        { text: "/ˈleɪˌtɛks/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A clear liquid believed to be a component of a humour or other bodily fluid (esp. plasma and lymph)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The milky sap of several trees that coagulates on exposure to air; used to make rubber.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An emulsion of rubber in water, used in adhesives and the like.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Natural latex rubber, especially non-vulcanized rubber, such as is used in making latex gloves, latex condoms, and latex clothing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/latex"],
    },
  ],
  veils: [
    {
      word: "veils",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Something hung up or spread out to hide or protect the face, or hide an object from view; usually of gauze, crepe, or similar diaphanous material.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A cover; disguise; a mask; a pretense.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The calyptra of mosses.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A membrane connecting the margin of the pileus of a mushroom with the stalk; a velum.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A covering for a person or thing; as, a caul (especially over the head)",
              synonyms: [],
              antonyms: [],
              example: "a nun's veil",
            },
            {
              definition: "Velum (A circular membrane round the cap of medusa)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A thin layer of tissue which is attached to or covers a mushroom.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An obscuration of the clearness of the tones in pronunciation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/veil",
        "https://en.wiktionary.org/wiki/veils",
      ],
    },
  ],
  mused: [
    {
      word: "mused",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To become lost in thought, to ponder.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To say (something) with due consideration or thought.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To think on; to meditate on.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To wonder at.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/muse",
        "https://en.wiktionary.org/wiki/mused",
      ],
    },
  ],
  mains: [
    {
      word: "mains",
      phonetic: "/meɪnz/",
      phonetics: [{ text: "/meɪnz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "Short for mainline.", synonyms: [], antonyms: [] },
            {
              definition:
                "To mainly play a specific character, or side, during a game.",
              synonyms: [],
              antonyms: [],
              example: "He mains the same character as me in that game.",
            },
            {
              definition: "Of a road: to convert into a main or primary road.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "That which is chief or principal; the chief or main portion; the bulk, the greater part, gross.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A large cable or pipe providing utility service to an area or a building, such as a water main or electric main.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Short for main course.",
              synonyms: [],
              antonyms: [],
              example:
                "I had scampi and chips for my main and a slice of cheesecake for dessert.",
            },
            { definition: "The high seas.", synonyms: [], antonyms: [] },
            { definition: "The mainland.", synonyms: [], antonyms: [] },
            { definition: "Short for mainsail.", synonyms: [], antonyms: [] },
            {
              definition:
                "(except in might and main) Force, power, strength, violent effort.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A hand or match in a game of dice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The largest throw in a match at dice; in the game of hazard, a number from one to nine called out by a person before the dice are thrown.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A stake played for at dice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sporting contest or match, especially a cockfighting match.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A banker's shovel for coins.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A basket for gathering grapes.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The domestic electrical power supply.",
              synonyms: [],
              antonyms: [],
              example: "I plugged it into the mains and it blew up!",
            },
            {
              definition:
                "The pipes of a centralized water supply that transport the water to individual buildings.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The main course of a meal.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/main",
        "https://en.wiktionary.org/wiki/mains",
      ],
    },
    {
      word: "mains",
      phonetic: "/meɪnz/",
      phonetics: [{ text: "/meɪnz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The farm attached to a mansion house.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mains"],
    },
  ],
  myrrh: [
    {
      word: "myrrh",
      phonetic: "[mɜː(ɹ)]",
      phonetics: [
        { text: "[mɜː(ɹ)]", audio: "" },
        {
          text: "[mɝ]",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/myrrh-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=91784364",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A red-brown resinous material, the dried sap of a tree of the species Commiphora myrrha.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The herb chervil.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/myrrh"],
    },
  ],
  racks: [
    {
      word: "racks",
      phonetic: "/ɹæks/",
      phonetics: [{ text: "/ɹæks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A series of one or more shelves, stacked one above the other",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various kinds of frame for holding luggage or other objects on a vehicle or vessel.",
              synonyms: ["luggage rack"],
              antonyms: [],
            },
            {
              definition:
                "A device, incorporating a ratchet, used to torture victims by stretching them beyond their natural limits.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece or frame of wood, having several sheaves, through which the running rigging passes.",
              synonyms: ["rack block"],
              antonyms: [],
            },
            { definition: "A bunk.", synonyms: [], antonyms: [] },
            { definition: "(by extension) Sleep.", synonyms: [], antonyms: [] },
            { definition: "A distaff.", synonyms: [], antonyms: [] },
            {
              definition:
                "A bar with teeth on its face or edge, to work with those of a gearwheel, pinion#, or worm, which is to drive or be driven by it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bar with teeth on its face or edge, to work with a pawl as a ratchet allowing movement in one direction only, used for example in a handbrake or crossbow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cranequin, a mechanism including a rack, pinion and pawl, providing both mechanical advantage and a ratchet, used to bend and cock a crossbow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A set of antlers (as on deer, moose or elk).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A cut of meat involving several adjacent ribs.",
              synonyms: [],
              antonyms: [],
              example: "I bought a rack of lamb at the butcher's yesterday.",
            },
            {
              definition:
                "A hollow triangle used for aligning the balls at the start of a game.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A woman's breasts.", synonyms: [], antonyms: [] },
            {
              definition:
                "A friction device for abseiling, consisting of a frame with five or more metal bars, around which the rope is threaded.",
              synonyms: [],
              antonyms: [],
              example: "abseil rack",
            },
            {
              definition:
                "A climber's set of equipment for setting up protection and belays, consisting of runners, slings, carabiners, nuts, Friends, etc.",
              synonyms: [],
              antonyms: [],
              example: "I used almost a full rack on the second pitch.",
            },
            {
              definition: "A grate on which bacon is laid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "That which is extorted; exaction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A set with a distributive binary operation whose result is unique.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A thousand pounds (£1,000), especially such proceeds of crime",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["luggage rack", "rack block"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To place in or hang on a rack.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To torture (someone) on the rack.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause (someone) to suffer pain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To stretch or strain; to harass, or oppress by extortion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To put the balls into the triangular rack and set them in place on the table.",
              synonyms: ["rack up"],
              antonyms: [],
            },
            {
              definition: "To strike a male in the testicles.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To (manually) load (a round of ammunition) from the magazine or belt into firing position in an automatic or semiautomatic firearm.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To move the slide bar on a shotgun in order to chamber the next round.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To wash (metals, ore, etc.) on a rack.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To bind together, as two ropes, with cross turns of yarn, marline, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(structural engineering) Tending to shear a structure (that is, force it to move in different directions at different points).",
              synonyms: ["shear"],
              antonyms: [],
              example: "Post-and-lintel construction racks easily.",
            },
          ],
          synonyms: ["rack up", "shear"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To stretch a person's joints.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To drive; move; go forward rapidly; stir",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fly, as vapour or broken clouds",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To clarify, and thereby deter further fermentation of, beer, wine or cider by draining or siphoning it from the dregs.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A fast amble.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(of a horse) To amble fast, causing a rocking or swaying motion of the body; to pace.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A wreck; destruction.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A young rabbit, or its skin.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rack",
        "https://en.wiktionary.org/wiki/racks",
      ],
    },
  ],
  galls: [
    {
      word: "galls",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Bile, especially that of an animal; the greenish, profoundly bitter-tasting fluid found in bile ducts and gall bladders, structures associated with the liver.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The gall bladder.", synonyms: [], antonyms: [] },
            {
              definition:
                "Great misery or physical suffering, likened to the bitterest-tasting of substances.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A feeling of exasperation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Impudence or brazenness; temerity, chutzpah.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sore or open wound caused by chafing, which may become infected, as with a blister.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sore on a horse caused by an ill-fitted or ill-adjusted saddle; a saddle sore.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A pit on a surface being cut caused by the friction between the two surfaces exceeding the bond of the material at a point.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To bother or trouble.", synonyms: [], antonyms: [] },
            {
              definition:
                "To harass, to harry, often with the intent to cause injury.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To chafe, to rub or subject to friction; to create a sore on the skin.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To exasperate.", synonyms: [], antonyms: [] },
            {
              definition:
                "To cause pitting on a surface being cut from the friction between the two surfaces exceeding the bond of the material at a point.",
              synonyms: [],
              antonyms: [],
              example:
                "Improper cooling and a dull milling blade on titanium can gall the surface.",
            },
            { definition: "To scoff; to jeer.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A blister or tumor-like growth found on the surface of plants, caused by burrowing of insect larvae into the living tissues, especially that of the common oak gall wasp Cynips quercusfolii.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A bump-like imperfection resembling a gall.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["nutgall"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To impregnate with a decoction of gallnuts in dyeing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gall",
        "https://en.wiktionary.org/wiki/galls",
      ],
    },
  ],
  gnats: [
    {
      word: "gnats",
      phonetic: "/næts/",
      phonetics: [{ text: "/næts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any small insect of the order Diptera, specifically within the suborder Nematocera.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gnat",
        "https://en.wiktionary.org/wiki/gnats",
      ],
    },
  ],
  bouts: [
    {
      word: "bouts",
      phonetic: "/ˈbaʊts/",
      phonetics: [{ text: "/ˈbaʊts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A period of something, usually painful or unpleasant.",
              synonyms: [],
              antonyms: [],
              example: "a bout of drought.",
            },
            { definition: "A boxing match.", synonyms: [], antonyms: [] },
            {
              definition:
                "An assault (a fencing encounter) at which the score is kept.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A roller derby match.", synonyms: [], antonyms: [] },
            {
              definition: "A fighting competition.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bulge or widening in a musical instrument, such as either of the two characteristic bulges of a guitar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The going and returning of a plough, or other implement used to mark the ground and create a headland, across a field.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To contest a bout.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bout",
        "https://en.wiktionary.org/wiki/bouts",
      ],
    },
  ],
  sisal: [
    {
      word: "sisal",
      phonetic: "/ˈsʌɪsəl/",
      phonetics: [{ text: "/ˈsʌɪsəl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A Central American plant, Agave sisalana, cultivated for its sword-shaped leaves that yield fibers used for rope.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The fibre of the plant.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A sisal mat.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sisal"],
    },
  ],
  shuts: [
    {
      word: "shuts",
      phonetic: "/ʃʌts/",
      phonetics: [{ text: "/ʃʌts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act or time of shutting; close.",
              synonyms: [],
              antonyms: [],
              example: "the shut of a door",
            },
            {
              definition: "A door or cover; a shutter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The line or place where two pieces of metal are welded together.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To close, to stop from being open.",
              synonyms: [],
              antonyms: [],
              example: "Please shut the door.",
            },
            {
              definition: "To close, to stop being open.",
              synonyms: [],
              antonyms: [],
              example: "If you wait too long, the automatic door will shut.",
            },
            {
              definition:
                "To close a business temporarily, or (of a business) to be closed.",
              synonyms: [],
              antonyms: [],
              example: "The pharmacy is shut on Sunday.",
            },
            {
              definition: "To confine in an enclosed area.",
              synonyms: [],
              antonyms: [],
              example: "I shut the cat in the kitchen before going out.",
            },
            {
              definition: "To catch or snag in the act of shutting something.",
              synonyms: [],
              antonyms: [],
              example: "He's just shut his finger in the door.",
            },
            {
              definition: "To preclude; to exclude; to bar out.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A narrow alley or passage acting as a short cut through the buildings between two streets.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/shut",
        "https://en.wiktionary.org/wiki/shuts",
      ],
    },
  ],
  hoses: [
    {
      word: "hoses",
      phonetic: "/ˈhəʊzɪz/",
      phonetics: [
        { text: "/ˈhəʊzɪz/", audio: "" },
        { text: "/ˈhoʊzɪz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A flexible tube conveying water or other fluid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A stocking-like garment worn on the legs; pantyhose, women's tights.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Close-fitting trousers or breeches, reaching to the knee.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To water or spray with a hose.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To deliver using a hose.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To provide with hose (garment)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To attack and kill somebody, usually using a firearm.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To trick or deceive.", synonyms: [], antonyms: [] },
            {
              definition:
                "To break a computer so everything needs to be reinstalled; to wipe all files.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause an unfair disadvantage to a player or team through poor officiating; especially, to cause a player or team to lose the game with an incorrect call.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hose",
        "https://en.wiktionary.org/wiki/hoses",
      ],
    },
  ],
  dryly: [
    {
      word: "dryly",
      phonetic: "/ˈdɹaɪ.li/",
      phonetics: [{ text: "/ˈdɹaɪ.li/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            { definition: "In a dry manner.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dryly"],
    },
  ],
  hover: [
    {
      word: "hover",
      phonetic: "/ˈhɒ.və(ɹ)/",
      phonetics: [
        { text: "/ˈhɒ.və(ɹ)/", audio: "" },
        {
          text: "/ˈhʌ.vɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hover-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90724703",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The act of hovering", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To float in the air.",
              synonyms: [],
              antonyms: [],
              example: "The hummingbird hovered by the plant.",
            },
            {
              definition:
                "To linger or hang in one place, especially in an uncertain manner.",
              synonyms: [],
              antonyms: [],
              example: "His pen hovered above the paper.",
            },
            {
              definition: "To waver, or be uncertain.",
              synonyms: [],
              antonyms: [],
              example:
                "Filling in the voting form, I hovered between Labour and Liberal Democrat.",
            },
            {
              definition:
                "To place the cursor over a hyperlink or icon without clicking.",
              synonyms: [],
              antonyms: [],
              example: "A tooltip appears when you hover over this link.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hover"],
    },
    {
      word: "hover",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A cover; a shelter; a protection.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hover"],
    },
  ],
  gloss: [
    {
      word: "gloss",
      phonetic: "/ɡlɑs/",
      phonetics: [
        { text: "/ɡlɑs/", audio: "" },
        { text: "/ɡlɒs/", audio: "" },
        {
          text: "/ɡlɔs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gloss-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50330731",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A surface shine or luster/lustre",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A superficially or deceptively attractive appearance",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "façade",
            "front",
            "veneer",
            "brilliance",
            "gleam",
            "luster",
            "lustre",
            "sheen",
            "shine",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To give a gloss or sheen to.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make (something) attractive by deception",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To become shiny.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["polish", "shine"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gloss"],
    },
    {
      word: "gloss",
      phonetic: "/ɡlɑs/",
      phonetics: [
        { text: "/ɡlɑs/", audio: "" },
        { text: "/ɡlɒs/", audio: "" },
        {
          text: "/ɡlɔs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gloss-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50330731",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A brief explanatory note or translation of a foreign, archaic, technical, difficult, complex, or uncommon expression, inserted after the original, in the margin of a document, or between lines of a text.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A glossary; a collection of such notes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An expression requiring such explanatory treatment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An extensive commentary on some text.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A brief explanation in speech or in a written work, including a synonym used with the intent of indicating the meaning of the word to which it is applied",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An interpretation by a court of specific point within a statute or case law",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "commentary",
            "discourse",
            "discussion",
            "explanation",
            "note",
            "glossary",
            "lexicon",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gloss"],
    },
    {
      word: "gloss",
      phonetic: "/ɡlɑs/",
      phonetics: [
        { text: "/ɡlɑs/", audio: "" },
        { text: "/ɡlɒs/", audio: "" },
        {
          text: "/ɡlɔs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gloss-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50330731",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To add a gloss to (a text).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["annotate", "mark up"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gloss"],
    },
  ],
  seeps: [
    {
      word: "seeps",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small spring, pool, or other spot where liquid from the ground (e.g. water, petroleum or tar) has oozed to the surface; a place of seeping.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Moisture, liquid, gas, etc. that seeps out; a seepage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The seeping away of a liquid, etc.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A seafloor vent.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To ooze or pass slowly through pores or other small openings, and in overly small quantities; said of liquids, etc.",
              synonyms: [],
              antonyms: [],
              example: "The water steadily seeped in through the thirl.",
            },
            {
              definition: "To enter or penetrate slowly; to spread or diffuse.",
              synonyms: [],
              antonyms: [],
              example:
                "Fear began to seep into the local community over the contamination of their fishpond.",
            },
            {
              definition: "To diminish or wane away slowly.",
              synonyms: [],
              antonyms: [],
              example:
                "The resistance movement against the invaders had slowly  seeped away.",
            },
          ],
          synonyms: ["leak"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/seep",
        "https://en.wiktionary.org/wiki/seeps",
      ],
    },
  ],
  denim: [
    {
      word: "denim",
      phonetic: "/ˈdɛnɪm/",
      phonetics: [
        {
          text: "/ˈdɛnɪm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/denim-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80065745",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A textile often made of cotton with a distinct diagonal pattern.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/denim"],
    },
  ],
  putty: [
    {
      word: "putty",
      phonetic: "/ˈpʌti/",
      phonetics: [{ text: "/ˈpʌti/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A form of cement, made from linseed oil and whiting, used to fix panes of glass.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any of a range of similar substances.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A golf ball made of composition and not gutta-percha.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To fix or fill using putty.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of, pertaining to, or resembling putty.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/putty"],
    },
    {
      word: "putty",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "(pronunciation spelling) pretty; purdy",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/putty"],
    },
    {
      word: "putty",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A strip of cloth wound round the leg, worn for protection or support by hikers, soldiers etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/puttee",
        "https://en.wiktionary.org/wiki/putty",
      ],
    },
  ],
  guppy: [
    {
      word: "guppy",
      phonetic: "/ˈɡʌpi/",
      phonetics: [{ text: "/ˈɡʌpi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A tiny freshwater fish, Poecilia reticulata, popular in home aquariums, that usually has a plain body and black or dark blue tail for the females and a more colorful tail for the males.",
              synonyms: [],
              antonyms: [],
              example: "Fred kept a tank of guppies by the windowsill.",
            },
            {
              definition: "By extension, any tiny fish.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A tube holding paintballs before they are loaded into the gun.",
              synonyms: [],
              antonyms: [],
              example:
                "2001, John R. Little, Curtis Wong, Ultimate Paintball Field Guide (page 177)",
            },
          ],
          synonyms: [
            "Acanthocephalus guppii",
            "Acanthocephalus reticulatus",
            "Girardinus guppii",
            "Girardinus petersi",
            "Girardinus poeciloides",
            "Girardinus reticulatus",
            "Haridichthys reticulatus",
            "Heterandria guppyi",
            "Lebistes poecilioides",
            "Lebistes reticulatus",
            "Poecilia poeciloides",
            "Poecilia reticulata",
            "Poecilioides reticulatus",
            "millionfish",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/guppy"],
    },
  ],
  leaky: [
    {
      word: "leaky",
      phonetic: "/ˈliːki/",
      phonetics: [{ text: "/ˈliːki/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having leaks; not fully sealed.",
              synonyms: [],
              antonyms: [],
              example:
                "The leaky bucket only dripped one drop at a time, but by the time I got back to the house it was half empty.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/leaky"],
    },
  ],
  dusky: [
    {
      word: "dusky",
      phonetic: "/ˈdʌs.ki/",
      phonetics: [
        {
          text: "/ˈdʌs.ki/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dusky-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1718914",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A dusky shark.", synonyms: [], antonyms: [] },
            { definition: "A dusky dolphin.", synonyms: [], antonyms: [] },
            { definition: "A dusky grouse.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Dimly lit, as at dusk (evening).",
              synonyms: [],
              antonyms: [],
              example:
                "I like it when it is dusky, just before the street lights come on.",
            },
            {
              definition: "Having a shade of color that is rather dark.",
              synonyms: [],
              antonyms: [],
              example:
                "The dusky rose was of a muted color, not clashing with any of the other colors.",
            },
            { definition: "Dark-skinned.", synonyms: [], antonyms: [] },
            {
              definition: "Ashen; having a greyish skin coloration.",
              synonyms: [],
              antonyms: [],
              example: "The patient was in shock and had a dusky skin tone.",
            },
          ],
          synonyms: ["black", "inky", "sable", "sooty", "swarthy"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dusky"],
    },
  ],
  filth: [
    {
      word: "filth",
      phonetic: "/fɪlθ/",
      phonetics: [
        {
          text: "/fɪlθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/filth-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241189",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Dirt; foul matter; that which soils or defiles.",
              synonyms: [],
              antonyms: [],
              example:
                "Before we start cooking we need to clean up the filth in this kitchen.",
            },
            {
              definition:
                "Smut; that which sullies or defiles the moral character; corruption; pollution.",
              synonyms: [],
              antonyms: [],
              example:
                "He spends all his time watching filth on pornographic websites.",
            },
            {
              definition: "(with definite article) The police.",
              synonyms: [],
              antonyms: [],
              example:
                "We were in the middle of stashing the money when the filth arrived.",
            },
            {
              definition: "A vile or disgusting person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Weeds growing on pasture land.",
              synonyms: [],
              antonyms: [],
              example:
                "Grampa remembers when he had to cut filth with a scythe.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/filth"],
    },
  ],
  oboes: [
    {
      word: "oboes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A soprano and melody wind instrument in the modern orchestra and wind ensemble. It is a smaller instrument and generally made of grenadilla wood. It is a member of the double reed family.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/oboe",
        "https://en.wiktionary.org/wiki/oboes",
      ],
    },
  ],
  spans: [
    {
      word: "spans",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The space from the thumb to the end of the little finger when extended; nine inches; an eighth of a fathom.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) A small space or a brief portion of time.",
              synonyms: [],
              antonyms: [],
              example:
                "He has a short attention span and gets bored within minutes.",
            },
            {
              definition: "A portion of something by length; a subsequence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The spread or extent of an arch or between its abutments, or of a beam, girder, truss, roof, bridge, or the like, between supports.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The length of a cable, wire, rope, chain between two consecutive supports.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A rope having its ends made fast so that a purchase can be hooked to the bight; also, a rope made fast in the center so that both ends can be used.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A pair of horses or other animals driven together; usually, such a pair of horses when similar in color, form, and action.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The space of all linear combinations of something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The time required to execute a parallel algorithm on an infinite number of processors, i.e. the shortest distance across a directed acyclic graph representing the computation steps.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To extend through the distance between or across.",
              synonyms: [],
              antonyms: [],
              example: "The suspension bridge spanned the canyon.",
            },
            {
              definition: "To extend through (a time period).",
              synonyms: [],
              antonyms: [],
              example: "The novel spans three centuries.",
            },
            {
              definition:
                "To measure by the span of the hand with the fingers extended, or with the fingers encompassing the object.",
              synonyms: [],
              antonyms: [],
              example: "to span a space or distance; to span a cylinder",
            },
            {
              definition:
                "To generate an entire space by means of linear combinations.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be matched, as horses.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fetter, as a horse; to hobble.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/span",
        "https://en.wiktionary.org/wiki/spans",
      ],
    },
  ],
  fowls: [
    {
      word: "fowls",
      phonetic: "/faʊlz/",
      phonetics: [
        {
          text: "/faʊlz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fowls-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=61416165",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A bird.", synonyms: [], antonyms: [] },
            {
              definition:
                "A bird of the order Galliformes, including chickens, turkeys, pheasant, partridges and quail.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Birds which are hunted or kept for food, including Galliformes and also waterfowl of the order Anseriformes such as ducks, geese and swans.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To hunt fowl.",
              synonyms: [],
              antonyms: [],
              example: "We took our guns and went fowling.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fowl",
        "https://en.wiktionary.org/wiki/fowls",
      ],
    },
  ],
  adorn: [
    {
      word: "adorn",
      phonetic: "/əˈdɔː(ɹ)n/",
      phonetics: [
        { text: "/əˈdɔː(ɹ)n/", audio: "" },
        {
          text: "/əˈdɔɹn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/adorn-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=453999",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Adornment", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make more beautiful and attractive; to decorate.",
              synonyms: [],
              antonyms: [],
              example: "a character adorned with every Christian grace",
            },
          ],
          synonyms: [
            "beautify",
            "bedeck",
            "deck",
            "decorate",
            "grace",
            "ornament",
            "prettify",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Adorned; ornate", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/adorn"],
    },
  ],
  glaze: [
    {
      word: "glaze",
      phonetic: "/ɡleɪz/",
      phonetics: [
        {
          text: "/ɡleɪz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/glaze-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=49895881",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The vitreous coating of pottery or porcelain; anything used as a coating or color in glazing. See glaze (transitive verb).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A transparent or semi-transparent layer of paint.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A smooth edible coating applied to food.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A smooth coating of ice formed on objects due to the freezing of rain; glaze ice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Broth reduced by boiling to a gelatinous paste, and spread thinly over braised dishes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A glazing oven; glost oven.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To install windows.", synonyms: [], antonyms: [] },
            {
              definition: "To apply a thin, transparent layer of coating.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To become glazed or glassy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "For eyes to take on an uninterested appearance.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/glaze"],
    },
  ],
  haunt: [
    {
      word: "haunt",
      phonetic: "/hænt/",
      phonetics: [
        {
          text: "/hænt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/haunt-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=86956868",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A place at which one is regularly found; a habitation or hangout.",
              synonyms: [],
              antonyms: [],
              example:
                "I went back the town I used to live and visited all my old haunts.",
            },
            { definition: "A ghost.", synonyms: [], antonyms: [] },
            {
              definition: "A feeding place for animals.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To inhabit, or visit frequently (most often used in reference to ghosts).",
              synonyms: [],
              antonyms: [],
              example: "A couple of ghosts haunt the old, burnt-down house.",
            },
            {
              definition: "To make uneasy, restless.",
              synonyms: [],
              antonyms: [],
              example: "The memory of his past failures haunted him.",
            },
            {
              definition: "To stalk, to follow",
              synonyms: [],
              antonyms: [],
              example: "The policeman haunted him, following him everywhere.",
            },
            {
              definition: "To live habitually; to stay, to remain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To accustom; habituate; make accustomed to.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To practise; to devote oneself to.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To persist in staying or visiting.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dwell", "live", "nag"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/haunt"],
    },
  ],
  dares: [
    {
      word: "dares",
      phonetic: "/dɛəz/",
      phonetics: [
        { text: "/dɛəz/", audio: "" },
        { text: "/dɛɹz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A challenge to prove courage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The quality of daring; venturesomeness; boldness.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Defiance; challenge.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To have enough courage (to do something).",
              synonyms: [],
              antonyms: [],
              example: "I wouldn't dare argue with my boss.",
            },
            {
              definition: "To defy or challenge (someone to do something)",
              synonyms: [],
              antonyms: [],
              example: "I dare you (to) kiss that girl.",
            },
            {
              definition:
                "To have enough courage to meet or do something, go somewhere, etc.; to face up to",
              synonyms: [],
              antonyms: [],
              example: "Will you dare death to reach your goal?",
            },
            { definition: "To terrify; to daunt.", synonyms: [], antonyms: [] },
            {
              definition:
                "To catch (larks) by producing terror through the use of mirrors, scarlet cloth, a hawk, etc., so that they lie still till a net is thrown over them.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To stare stupidly or vacantly; to gaze as though amazed or terrified.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To lie or crouch down in fear.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A small fish, the dace.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dare",
        "https://en.wiktionary.org/wiki/dares",
      ],
    },
  ],
  obeys: [
    {
      word: "obeys",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To do as ordered by (a person, institution etc), to act according to the bidding of.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To do as one is told.", synonyms: [], antonyms: [] },
            {
              definition:
                "To be obedient, compliant (to a given law, restriction etc.).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["hearken"],
          antonyms: ["defy", "disobey", "rebel", "resist", "violate"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/obey",
        "https://en.wiktionary.org/wiki/obeys",
      ],
    },
  ],
  bakes: [
    {
      word: "bakes",
      phonetic: "/beɪks/",
      phonetics: [{ text: "/beɪks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act of cooking food by baking.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any of various baked dishes resembling casserole.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A social event at which food (such as seafood) is baked, or at which baked food is served.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Barbadian, sometimes US and UK) A small, flat (or ball-shaped) cake of dough eaten in Barbados and sometimes elsewhere, similar in appearance and ingredients to a pancake but fried (or in some places sometimes roasted).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any item that is baked.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(with person as subject) To cook (something) in an oven.",
              synonyms: [],
              antonyms: [],
              example: "I baked a delicious cherry pie.",
            },
            {
              definition:
                "(with baked thing as subject) To be cooked in an oven.",
              synonyms: [],
              antonyms: [],
              example: "The cake baked at 350°F.",
            },
            {
              definition: "To be warmed to drying and hardening.",
              synonyms: [],
              antonyms: [],
              example: "The clay baked in the sun.",
            },
            {
              definition: "To dry by heat.",
              synonyms: [],
              antonyms: [],
              example:
                "They baked the electrical parts lightly to remove moisture.",
            },
            {
              definition: "To be hot.",
              synonyms: [],
              antonyms: [],
              example: "I'm baking after that workout in the gym.",
            },
            { definition: "To cause to be hot.", synonyms: [], antonyms: [] },
            { definition: "To smoke marijuana.", synonyms: [], antonyms: [] },
            { definition: "To harden by cold.", synonyms: [], antonyms: [] },
            {
              definition:
                "To fix (lighting, reflections, etc.) as part of the texture of an object to improve rendering performance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                '(with "in" or "into") To incorporate into something greater.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bake",
        "https://en.wiktionary.org/wiki/bakes",
      ],
    },
  ],
  abyss: [
    {
      word: "abyss",
      phonetic: "/əˈbɪs/",
      phonetics: [
        { text: "/əˈbɪs/", audio: "" },
        {
          text: "/æˈbɪs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/abyss-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1755056",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Hell; the bottomless pit; primeval chaos; a confined subterranean ocean.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(frequently figurative) A bottomless or unfathomed depth, gulf, or chasm; hence, any deep, immeasurable; any void space.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Anything infinite, immeasurable, or profound.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Moral depravity; vast intellectual or moral depth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An impending catastrophic happening.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The center of an escutcheon.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The abyssal zone.", synonyms: [], antonyms: [] },
            {
              definition:
                "A difference, especially a large difference, between groups.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["gulf"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/abyss"],
    },
  ],
  smelt: [
    {
      word: "smelt",
      phonetic: "/ˈsmɛlt/",
      phonetics: [
        {
          text: "/ˈsmɛlt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/smelt-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90913441",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any small anadromous fish of the family Osmeridae, found in the Atlantic and Pacific Oceans and in lakes in North America and northern part of Europe.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A fool; a simpleton.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/smelt"],
    },
    {
      word: "smelt",
      phonetic: "/ˈsmɛlt/",
      phonetics: [
        {
          text: "/ˈsmɛlt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/smelt-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90913441",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To sense a smell or smells.",
              synonyms: ["detect", "sense"],
              antonyms: [],
              example: "I can smell fresh bread.",
            },
            {
              definition:
                "Followed by like or of if descriptive: to have a particular smell, whether good or bad.",
              synonyms: ["pong", "reek", "stink", "whiff"],
              antonyms: [],
              example: "Her feet smell of cheese.",
            },
            {
              definition: "(without a modifier) To smell bad; to stink.",
              synonyms: [],
              antonyms: [],
              example: "Ew, this stuff smells.",
            },
            {
              definition:
                "To have a particular tincture or smack of any quality; to savour.",
              synonyms: [],
              antonyms: [],
              example: "A report smells of calumny.",
            },
            { definition: "To exercise sagacity.", synonyms: [], antonyms: [] },
            {
              definition: "To detect or perceive; often with out.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To give heed to.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["detect", "sense", "pong", "reek", "stink", "whiff"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/smell",
        "https://en.wiktionary.org/wiki/smelt",
      ],
    },
    {
      word: "smelt",
      phonetic: "/ˈsmɛlt/",
      phonetics: [
        {
          text: "/ˈsmɛlt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/smelt-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90913441",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Production of metal, especially iron, from ore in a process that involves melting and chemical reduction of metal compounds into purified metal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of the various liquids or semi-molten solids produced and used during the course of such production.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To fuse or melt two things into one, especially in order to extract metal from ore; to meld",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/smelt"],
    },
  ],
  gangs: [
    {
      word: "gangs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To go; walk; proceed.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A number going in company; a number of friends or persons associated for a particular purpose.",
              synonyms: [],
              antonyms: [],
              example:
                "The gang from our office is going out for drinks Friday night.",
            },
            {
              definition: "A group of laborers under one foreman; a squad.",
              synonyms: [],
              antonyms: [],
              example: "a gang of sailors; a railroad gang",
            },
            {
              definition:
                "A criminal group with a common cultural background and identifying features, often associated with a particular section of a city.",
              synonyms: [],
              antonyms: [],
              example: "a youth gang; a neighborhood gang; motorcycle gang.",
            },
            {
              definition:
                "A group of criminals or alleged criminals who band together for mutual protection and profit.",
              synonyms: [],
              antonyms: [],
              example:
                "The Winter Hill Gang was quite proficient at murdering rival mobsters in order to take over their rackets.",
            },
            {
              definition:
                "A group of politicians united in furtherance of a political goal.",
              synonyms: [],
              antonyms: [],
              example:
                "Not all members of the Gang of Six are consistent in their opposition to filibuster.",
            },
            { definition: "A chain gang.", synonyms: [], antonyms: [] },
            {
              definition:
                "A combination of similar tools or implements arranged so as, by acting together, to save time or labor; a set.",
              synonyms: [],
              antonyms: [],
              example: "a gang of saws; a gang of plows.",
            },
            {
              definition: "A set; all required for an outfit.",
              synonyms: [],
              antonyms: [],
              example: "a new gang of stays.",
            },
            {
              definition:
                "(electrics) A number of switches or other electrical devices wired into one unit and covered by one faceplate.",
              synonyms: [],
              antonyms: [],
              example: "an outlet gang box; a double gang switch.",
            },
            {
              definition: "(electrics) A group of wires attached as a bundle.",
              synonyms: [],
              antonyms: [],
              example:
                "Do a drop for the telephone gang, then another drop for the Internet gang, both through the ceiling of the wiring closet.",
            },
            {
              definition: "A going, journey; a course, path, track.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An outhouse: an outbuilding used as a lavatory.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To attach similar items together to form a larger unit.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To participate in a gangbang.",
              synonyms: [],
              antonyms: [],
              example: "We all got to gangbang the porn star.",
            },
            {
              definition:
                "To beat one or a smaller amount of people as a gang.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To belong to a gang; to commit criminal acts as part of a gang.",
              synonyms: [],
              antonyms: [],
              example: "It broke my heart to see him out there gangbanging.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The earthy waste substances occurring in metallic ore.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["veinstone"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gang",
        "https://en.wiktionary.org/wiki/gangbang",
        "https://en.wiktionary.org/wiki/gangs",
        "https://en.wiktionary.org/wiki/gangue",
      ],
    },
  ],
  aches: [
    {
      word: "aches",
      phonetic: "/eɪks/",
      phonetics: [{ text: "/eɪks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Continued dull pain, as distinguished from sudden twinges, or spasmodic pain.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To suffer pain; to be the source of, or be in, pain, especially continued dull pain; to be distressed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause someone or something to suffer pain.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "Parsley", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The name of the Latin-script letter H.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ache",
        "https://en.wiktionary.org/wiki/aches",
        "https://en.wiktionary.org/wiki/aitch",
      ],
    },
  ],
  trawl: [
    {
      word: "trawl",
      phonetic: "/tɹɔːl/",
      phonetics: [
        { text: "/tɹɔːl/", audio: "" },
        { text: "/tɹɑl/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A net or dragnet used for trawling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A long fishing line having many short lines bearing hooks attached to it; a setline.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To take (fish or other marine animals) with a trawl.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fish from a slow-moving boat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make an exhaustive search for something within a defined area.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/trawl"],
    },
  ],
  claps: [
    {
      word: "claps",
      phonetic: "/klæps/",
      phonetics: [
        {
          text: "/klæps/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/claps-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=64328473",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The act of striking the palms of the hands, or any two surfaces, together.",
              synonyms: [],
              antonyms: [],
              example: "He summoned the waiter with a clap.",
            },
            {
              definition: "The explosive sound of thunder.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any loud, sudden, explosive sound made by striking hard surfaces together, or resembling such a sound.",
              synonyms: [],
              antonyms: [],
              example: "Off in the distance, he heard the clap of thunder.",
            },
            {
              definition: "A slap with the hand, usually in a jovial manner.",
              synonyms: [],
              antonyms: [],
              example:
                "His father's affection never went further than a handshake or a clap on the shoulder.",
            },
            {
              definition: "A single, sudden act or motion; a stroke; a blow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The nether part of the beak of a hawk.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A dropping of cow dung (presumably from the sound made as it hits the ground)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["thunderclap"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To strike the palms of the hands together, creating a sharp sound.",
              synonyms: [],
              antonyms: [],
              example: "The children began to clap in time with the music.",
            },
            {
              definition: "To applaud.",
              synonyms: [],
              antonyms: [],
              example:
                "It isn’t the singers they are clapping; it's the composer.",
            },
            {
              definition: "To slap with the hand in a jovial manner.",
              synonyms: [],
              antonyms: [],
              example:
                "He would often clap his teammates on the back for encouragement.",
            },
            {
              definition:
                "To bring two surfaces together forcefully, creating a sharp sound.",
              synonyms: [],
              antonyms: [],
              example: "He clapped across the floor in his boots.",
            },
            {
              definition: "To come together suddenly with noise.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To create or assemble (something) hastily (usually followed by up or together).",
              synonyms: [],
              antonyms: [],
              example: "The rival factions clapped up a truce.",
            },
            {
              definition: "To set or put, usually in haste.",
              synonyms: [],
              antonyms: [],
              example: "She was the prettiest thing I'd ever clapped eyes on.",
            },
            {
              definition: "To shoot (somebody) with a gun.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: '(with "the") Gonorrhea.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/clap",
        "https://en.wiktionary.org/wiki/claps",
      ],
    },
  ],
  undid: [
    {
      word: "undid",
      phonetic: "/ʌnˈdɪd/",
      phonetics: [{ text: "/ʌnˈdɪd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To reverse the effects of an action.",
              synonyms: [],
              antonyms: [],
              example:
                "Fortunately, we can undo most of the damage to the system by the war.",
            },
            {
              definition: "To unfasten.",
              synonyms: [],
              antonyms: [],
              example: "Could you undo my buckle for me?",
            },
            {
              definition:
                "To impoverish or ruin, as in reputation; to cause the downfall of.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "cancel",
            "reverse",
            "unbuckle",
            "unbutton",
            "untie",
            "unzip",
          ],
          antonyms: [
            "redo",
            "button",
            "button up",
            "do up",
            "tie up",
            "zip",
            "zip up",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/undid",
        "https://en.wiktionary.org/wiki/undo",
      ],
    },
  ],
  spicy: [
    {
      word: "spicy",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of, pertaining to, or containing spice.",
              synonyms: [],
              antonyms: [],
              example: "He prepared a spicy casserole.",
            },
            {
              definition:
                "(of flavors) Provoking a burning sensation due to the presence of chilis or similar hot spices",
              synonyms: [],
              antonyms: [],
              example: "This curry is too spicy for me. I can't eat it.",
            },
            {
              definition: "(of flavors or odors) Tangy, zesty, or pungent.",
              synonyms: [],
              antonyms: [],
              example: "She breathed in the strong, spicy aroma.",
            },
            {
              definition:
                "(of expression or behavior) Vigorous; colorful; stimulating.",
              synonyms: [],
              antonyms: [],
              example: "He is known for his spicy political commentary.",
            },
            {
              definition: "Risqué, sexy, racy; mildly pornographic.",
              synonyms: [],
              antonyms: [],
              example:
                "I don't want my children to see the spicy images on this web site.",
            },
          ],
          synonyms: ["hot", "piquant", "spicy-hot"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spicy"],
    },
  ],
  hoist: [
    {
      word: "hoist",
      phonetic: "/hɔɪst/",
      phonetics: [
        {
          text: "/hɔɪst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hoist-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=49895884",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A hoisting device, such as pulley or crane.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The act of hoisting; a lift.",
              synonyms: [],
              antonyms: [],
              example: "Give me a hoist over that wall.",
            },
            {
              definition:
                "The perpendicular height of a flag, as opposed to the fly, or horizontal length, when flying from a staff.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The vertical edge of a flag which is next to the staff.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The height of a fore-and-aft sail, next the mast or stay.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To raise; to lift; to elevate (especially, to raise or lift to a desired elevation, by means of tackle or pulley, said of a sail, a flag, a heavy package or weight).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To lift a trophy or similar prize into the air in celebration of a victory.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To lift someone up to be flogged.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To be lifted up.", synonyms: [], antonyms: [] },
            {
              definition:
                "To extract (code) from a loop construct as part of optimization.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To steal, to rob.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hoist"],
    },
  ],
  fades: [
    {
      word: "fades",
      phonetic: "/feɪdz/",
      phonetics: [{ text: "/feɪdz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A golf shot that (for the right-handed player) curves intentionally to the right. See slice, hook, draw.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A haircut where the hair is short or shaved on the sides of the head and longer on top. See also high-top fade and low fade.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A fight.", synonyms: [], antonyms: [] },
            {
              definition:
                "A gradual decrease in the brightness of a shot or the volume of sound or music (as a means of cutting to a new scene or starting a new song).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The act of disappearing from a place so as not to be found; covert departure.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To grow weak; to lose strength; to decay; to perish gradually; to wither, as a plant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To lose freshness, color, or brightness; to become faint in hue or tint; hence, to be wanting in color.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To sink away; to disappear gradually; to grow dim; to vanish.",
              synonyms: [],
              antonyms: [],
              example: "The milkman's whistling faded into the distance.",
            },
            { definition: "To cause to fade.", synonyms: [], antonyms: [] },
            { definition: "To bet against.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "weaken",
            "wither",
            "blanch",
            "bleach",
            "decrease",
            "diminish",
            "wane",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fade",
        "https://en.wiktionary.org/wiki/fades",
      ],
    },
  ],
  vicar: [
    {
      word: "vicar",
      phonetic: "/ˈvɪkɚ/",
      phonetics: [{ text: "/ˈvɪkɚ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "In the Church of England, the priest of a parish, receiving a salary or stipend but not tithes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In the Roman Catholic and some other churches, a cleric acting as local representative of a higher ranking member of the clergy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person acting on behalf of, or representing, another person.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/vicar"],
    },
  ],
  acorn: [
    {
      word: "acorn",
      phonetic: "/ˈeɪ.kɔːn/",
      phonetics: [
        { text: "/ˈeɪ.kɔːn/", audio: "" },
        {
          text: "/ˈeɪ.kɔɹn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/acorn-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1316308",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The fruit of the oak, being an oval nut growing in a woody cup or cupule.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cone-shaped piece of wood on the point of the spindle above the vane, on the mast-head.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "See acorn-shell.", synonyms: [], antonyms: [] },
            {
              definition: "(usually in the plural) A testicle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/acorn"],
    },
  ],
  pussy: [
    {
      word: "pussy",
      phonetic: "/ˈpʊsɪ/",
      phonetics: [
        { text: "/ˈpʊsɪ/", audio: "" },
        { text: "/ˈpʊsi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A cat.", synonyms: [], antonyms: [] },
            {
              definition:
                "An affectionate term for a woman or girl, seen as having characteristics associated with cats such as sweetness.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The female genitalia; the vulva and/or vagina.",
              synonyms: [],
              antonyms: [],
              example: "You have a lovely pussy!",
            },
            {
              definition:
                "Anything soft and furry; a bloom form, or catkin, as on the pussy willow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                '(normally in the phrase "to get some pussy") Sexual intercourse with a woman.',
              synonyms: [],
              antonyms: [],
              example: "I’m gonna get me some pussy tonight.",
            },
            {
              definition:
                "A coward; a weakling; an ineffectual, timid, or pathetic person.",
              synonyms: [],
              antonyms: [],
              example: "You're such a pussy!",
            },
            { definition: "A game of tip-cat.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "darling",
            "honey",
            "pussums",
            "kitty",
            "kitty-cat",
            "puss",
            "pussy-cat",
            "scaredy-cat",
            "wimp",
            "wuss",
            "poontang",
            "punani",
            "quim",
            "pansy",
            "sissy",
            "weakling",
            "weenie",
            "cat",
            "cat and dog",
            "one-a-cat",
            "piggy",
            "poon",
            "poontang",
            "punani",
            "tang",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pussy"],
    },
    {
      word: "pussy",
      phonetic: "/ˈpʌsi/",
      phonetics: [
        {
          text: "/ˈpʌsi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pussy-2.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=17047640",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Containing pus.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["purulent"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pussy"],
    },
    {
      word: "pussy",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Out of breath; short of breath, especially due to fatness.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Fat and short.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Puckered.", synonyms: [], antonyms: [] },
            {
              definition: "Purse-proud; vain about one's wealth.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pursy",
        "https://en.wiktionary.org/wiki/pussy",
      ],
    },
  ],
  gruff: [
    {
      word: "gruff",
      phonetic: "/ɡɹʌf/",
      phonetics: [{ text: "/ɡɹʌf/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To speak gruffly.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Having a rough, surly, and harsh demeanor and nature.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Hoarse-voiced.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gruff"],
    },
  ],
  musty: [
    {
      word: "musty",
      phonetic: "[ˈmʌstɪ]",
      phonetics: [
        { text: "[ˈmʌstɪ]", audio: "" },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/musty-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=85506696",
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Having a stale odor.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/musty"],
    },
  ],
  tarts: [
    {
      word: "tarts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A type of small open pie, or piece of pastry, containing jelly or conserve; a sort of fruit pie.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A prostitute.", synonyms: [], antonyms: [] },
            {
              definition: "By extension, any woman with loose sexual morals.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To practice prostitution",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To practice promiscuous sex",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To dress garishly, ostentatiously, whorishly, or sluttily",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tart",
        "https://en.wiktionary.org/wiki/tarts",
      ],
    },
  ],
  snuff: [
    {
      word: "snuff",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/snuff-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79293799",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/snʌf/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Finely ground or pulverized tobacco intended for use by being sniffed or snorted into the nose.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Fine-ground or minced tobacco, dry or moistened, intended for use by placing a pinch behind the lip or beneath the tongue; see also snus.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A snort or sniff of fine-ground, powdered, or pulverized tobacco.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The act of briskly inhaling by the nose; a sniff, a snort.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Resentment or skepticism expressed by quickly drawing air through the nose; snuffling; sniffling.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Snot, mucus.", synonyms: [], antonyms: [] },
            { definition: "Smell, scent, odour.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To inhale through the nose.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To turn up the nose and inhale air, as an expression of contempt; hence, to take offence.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/snuff"],
    },
    {
      word: "snuff",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/snuff-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79293799",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/snʌf/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The burning part of a candle wick, or the black, burnt remains of a wick (which must be periodically removed).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Leavings in a glass after drinking; heeltaps.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A murder.", synonyms: [], antonyms: [] },
            {
              definition:
                "A form of pornographic film which involves someone actually being murdered.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/snuff"],
    },
    {
      word: "snuff",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/snuff-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79293799",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/snʌf/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To extinguish a candle or oil-lamp flame by covering the burning end of the wick until the flame is suffocated.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To trim the burnt part of a candle wick.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To kill a person; to snuff out.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/snuff"],
    },
  ],
  hunch: [
    {
      word: "hunch",
      phonetic: "/hʌntʃ/",
      phonetics: [
        {
          text: "/hʌntʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hunch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=49895886",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A hump; a protuberance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A stooped or curled posture; a slouch.",
              synonyms: [],
              antonyms: [],
              example: "The old man walked with a hunch.",
            },
            {
              definition:
                "A theory, idea, or guess; an intuitive impression that something will happen.",
              synonyms: [],
              antonyms: [],
              example:
                "I have a hunch they'll find a way to solve the problem.",
            },
            {
              definition: "A hunk; a lump; a thick piece.",
              synonyms: [],
              antonyms: [],
              example: "a hunch of bread",
            },
            {
              definition: "A push or thrust, as with the elbow.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["clue", "hint", "inkling"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To bend the top of one's body forward while raising one's shoulders.",
              synonyms: ["lean", "slouch", "stoop"],
              antonyms: [],
              example:
                "Don't hunch over your computer if you want to avoid neck problems.",
            },
            {
              definition:
                "To raise (one's shoulders) (while lowering one's head or bending the top of one's body forward); to curve (one's body) forward (sometimes followed by up).",
              synonyms: [],
              antonyms: [],
              example:
                "He hunched up his shoulders and stared down at the ground.",
            },
            {
              definition: "To walk (somewhere) while hunching one's shoulders.",
              synonyms: ["slouch"],
              antonyms: [],
            },
            {
              definition:
                "To thrust a hump or protuberance out of (something); to crook, as the back.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To push or jostle with the elbow; to push or thrust against (someone).",
              synonyms: ["elbow", "nudge"],
              antonyms: [],
            },
            {
              definition: "To have a hunch, or make an intuitive guess.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["elbow", "nudge", "lean", "slouch", "stoop", "slouch"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hunch"],
    },
  ],
  truce: [
    {
      word: "truce",
      phonetic: "/tɹuːs/",
      phonetics: [
        { text: "/tɹuːs/", audio: "" },
        {
          text: "/tɹus/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/truce-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780462",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A period of time in which no fighting takes place due to an agreement between the opposed parties",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An agreement between opposed parties in which they pledge to cease fighting for a limited time",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["armistice", "ceasefire"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/truce"],
    },
  ],
  tweed: [
    {
      word: "tweed",
      phonetic: "/twiːd/",
      phonetics: [{ text: "/twiːd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A coarse woolen fabric used for clothing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tweed"],
    },
  ],
  dryer: [
    {
      word: "dryer",
      phonetic: "/ˈdɹaɪə/",
      phonetics: [
        { text: "/ˈdɹaɪə/", audio: "" },
        { text: "/ˈdɹaɪɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One who, or that which, dries; a desiccative.",
              synonyms: [],
              antonyms: [],
              example:
                "The sun and a northwesterly wind are great driers of the earth.",
            },
            {
              definition:
                "A household appliance that removes the water from clothing by accelerating evaporation, usually though heat and a tumbling motion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An electric hair dryer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any other device or substance, household or industrial, designed to remove water or humidity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A catalyst used to promote the drying of paints and varnishes by oxidative crosslinking.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["clothes dryer", "tumble dryer"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dryer"],
    },
    {
      word: "dryer",
      phonetic: "/ˈdɹaɪə/",
      phonetics: [
        { text: "/ˈdɹaɪə/", audio: "" },
        { text: "/ˈdɹaɪɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Free from or lacking moisture.",
              synonyms: [],
              antonyms: [],
              example:
                "This towel's dry. Could you wet it and cover the chicken so it doesn't go dry as it cooks?",
            },
            {
              definition:
                "Unable to produce a liquid, as water, oil, or (farming) milk.",
              synonyms: [],
              antonyms: [],
              example: "This well is as dry as that cow.",
            },
            {
              definition: "Built without or lacking mortar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Anhydrous: free from or lacking water in any state, regardless of the presence of other liquids.",
              synonyms: [],
              antonyms: [],
              example: "Dry alcohol is 200 proof.",
            },
            { definition: "Athirst, eager.", synonyms: [], antonyms: [] },
            {
              definition:
                "Free from or lacking alcohol or alcoholic beverages.",
              synonyms: [],
              antonyms: [],
              example:
                "Of course it's a dry house. He was an alcoholic but he's been dry for almost a year now.",
            },
            {
              definition:
                "Describing an area where sales of alcoholic or strong alcoholic beverages are banned.",
              synonyms: [],
              antonyms: [],
              example:
                "You'll have to drive out of this dry county to find any liquor.",
            },
            {
              definition:
                "Free from or lacking embellishment or sweetness, particularly:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(somewhat derogatory) Involving computations rather than work with biological or chemical matter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a sound recording) Free from applied audio effects.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Without a usual complement or consummation; impotent.",
              synonyms: [],
              antonyms: [],
              example:
                "never dry fire a bow; dry humping her girlfriend; making a dry run",
            },
            {
              definition:
                "Of a mass, service, or rite: involving neither consecration nor communion.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["wet", "wet"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dry",
        "https://en.wiktionary.org/wiki/dryer",
      ],
    },
  ],
  loser: [
    {
      word: "loser",
      phonetic: "/luzɚ/",
      phonetics: [{ text: "/luzɚ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A person who loses; one who fails to win or thrive.",
              synonyms: [],
              antonyms: ["winner"],
              example: "He was always a good loser.",
            },
            {
              definition: "Something of poor quality.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person who is frequently unsuccessful in life.",
              synonyms: ["failure"],
              antonyms: [],
              example: "I'm a constant loser in love.",
            },
            {
              definition: "A contemptible or unfashionable person.",
              synonyms: ["crumb", "losel", "louse"],
              antonyms: [],
            },
            {
              definition:
                "One who or that which loses something, such as extra weight, car keys, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["crumb", "losel", "louse", "failure"],
          antonyms: ["winner"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/loser"],
    },
  ],
  sheaf: [
    {
      word: "sheaf",
      phonetic: "/ʃiːf/",
      phonetics: [
        {
          text: "/ʃiːf/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sheaf-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=694284",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A quantity of the stalks and ears of wheat, rye, or other grain, bound together; a bundle of grain or straw.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any collection of things bound together; a bundle.",
              synonyms: [],
              antonyms: [],
              example: "a sheaf of paper",
            },
            {
              definition:
                "A bundle of arrows sufficient to fill a quiver, or the allowance of each archer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A quantity of arrows, usually twenty-four.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A sheave.", synonyms: [], antonyms: [] },
            {
              definition:
                "An abstract construct in topology that associates data to the open sets of a topological space, together with well-defined restrictions from larger to smaller open sets, subject to the condition that compatible data on overlapping open sets corresponds, via the restrictions, to a unique datum on the union of the open sets.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["reap"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To gather and bind into a sheaf; to make into sheaves",
              synonyms: [],
              antonyms: [],
              example: "to sheaf wheat",
            },
            {
              definition:
                "To collect and bind cut grain, or the like; to make sheaves.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sheaf"],
    },
  ],
  moles: [
    {
      word: "moles",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A pigmented spot on the skin, a naevus, slightly raised, and sometimes hairy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["birthmark", "naevus", "nevus", "nævus"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of several small, burrowing insectivores of the family Talpidae.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any of the burrowing rodents also called mole rats.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An internal spy, a person who involves himself or herself with an enemy organisation, especially an intelligence or governmental organisation, to determine and betray its secrets from within.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A kind of self-propelled excavator used to form underground drains, or to clear underground pipelines",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A type of underground drain used in farm fields, in which a mole plow creates an unlined channel through clay subsoil.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["mouldwarp"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A moll, a bitch, a slut.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["moll"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A massive structure, usually of stone, used as a pier, breakwater or junction between places separated by water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A haven or harbour, protected with such a breakwater.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An Ancient Roman mausoleum.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "In the International System of Units, the base unit of amount of substance; the amount of substance of a system which contains as many elementary entities (atoms, ions, molecules, etc.) as there are atoms in 0.012 kg of carbon-12. Symbol: mol. The number of atoms is known as Avogadro’s number.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["gram molecule"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A hemorrhagic mass of tissue in the uterus caused by a dead ovum.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One of several spicy sauces typical of the cuisine of Mexico and neighboring Central America, especially the sauce which contains chocolate and which is used in cooking main dishes, not desserts.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mole",
        "https://en.wiktionary.org/wiki/moles",
      ],
    },
  ],
  lapse: [
    {
      word: "lapse",
      phonetic: "/læps/",
      phonetics: [
        { text: "/læps/", audio: "" },
        { text: "/læps/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A temporary failure; a slip.",
              synonyms: ["blooper", "gaffe", "thinko"],
              antonyms: [],
            },
            {
              definition: "A decline or fall in standards.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A pause in continuity.",
              synonyms: ["hiatus", "moratorium"],
              antonyms: [],
            },
            {
              definition: "An interval of time between events.",
              synonyms: ["between-time", "gap"],
              antonyms: [],
            },
            {
              definition:
                "A termination of a right etc., through disuse or neglect.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A marked decrease in air temperature with increasing altitude because the ground is warmer than the surrounding air.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A common-law rule that if the person to whom property is willed were to die before the testator, then the gift would be ineffective.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A fall or apostasy.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "between-time",
            "gap",
            "blooper",
            "gaffe",
            "thinko",
            "hiatus",
            "moratorium",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To fall away gradually; to subside.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fall into error or heresy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To slip into a bad habit that one is trying to avoid.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To become void.", synonyms: [], antonyms: [] },
            {
              definition:
                "To fall or pass from one proprietor to another, or from the original destination, by the omission, negligence, or failure of somebody, such as a patron or legatee.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lapse"],
    },
  ],
  tawny: [
    {
      word: "tawny",
      phonetic: "/ˈtɔːni/",
      phonetics: [{ text: "/ˈtɔːni/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A light brown to brownish orange colour.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of a light brown to brownish orange color.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sweet, fortified wine which is blended and matured in wood.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["fulvous"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tawny"],
    },
  ],
  vexed: [
    {
      word: "vexed",
      phonetic: "/ˈvɛkst/",
      phonetics: [{ text: "/ˈvɛkst/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To trouble aggressively, to harass.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To annoy, irritate.", synonyms: [], antonyms: [] },
            {
              definition: "To cause (mental) suffering to; to distress.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To twist, to weave.", synonyms: [], antonyms: [] },
            {
              definition: "To be irritated; to fret.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To toss back and forth; to agitate; to disquiet.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "agitate",
            "irk",
            "irritate",
            "afflict",
            "grame",
            "torment",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Annoyed, irritated or distressed",
              synonyms: [],
              antonyms: [],
              example:
                "She became more and more vexed as she struggled to cope with the demands of the job.",
            },
            {
              definition: "Much debated, discussed or disputed",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/vex",
        "https://en.wiktionary.org/wiki/vexed",
      ],
    },
  ],
  autos: [
    {
      word: "autos",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An automobile.",
              synonyms: [],
              antonyms: [],
              example: "My brother is an auto mechanic.",
            },
            {
              definition: "A setting for automatic operation.",
              synonyms: ["automatic"],
              antonyms: ["manual"],
              example: "Put it on auto.",
            },
            {
              definition: "An automatic gearbox / transmission.",
              synonyms: [],
              antonyms: [],
              example:
                "A body coloured centre pillar signalled the arrival of an electronic four-speed auto, slight suspension revisions and minor trim changes.",
            },
            {
              definition: "A car with an automatic gearbox / transmission.",
              synonyms: [],
              antonyms: [],
              example:
                "It wasn't too bad but we did hire an auto (couldn't imagine changing gears with my right hand).",
            },
          ],
          synonyms: ["automobile", "car", "motor", "motorcar", "automatic"],
          antonyms: ["manual"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To travel by automobile.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An autorickshaw.",
              synonyms: [],
              antonyms: [],
              example:
                "... users are often unable to note down the number of the taxi or the auto as the driver will just zoom past.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/auto",
        "https://en.wiktionary.org/wiki/autos",
      ],
    },
  ],
  wager: [
    {
      word: "wager",
      phonetic: "/ˈweɪdʒɚ/",
      phonetics: [
        {
          text: "/ˈweɪdʒɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wager-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651701",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Something deposited, laid, or hazarded on the event of a contest or an unsettled question; a bet; a stake; a pledge.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "That on which bets are laid; the subject of a bet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A contract by which two parties or more agree that a certain sum of money, or other thing, shall be paid or delivered to one of them, on the happening or not happening of an uncertain event.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An offer to make oath.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To bet something; to put it up as collateral",
              synonyms: [],
              antonyms: [],
              example: "I'd wager my boots on it.",
            },
            {
              definition: "To suppose; to dare say.",
              synonyms: [],
              antonyms: [],
              example:
                "I'll wager that Johnson knows something about all this.",
            },
          ],
          synonyms: ["lay odds"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/wager"],
    },
    {
      word: "wager",
      phonetic: "/ˈweɪdʒɚ/",
      phonetics: [
        {
          text: "/ˈweɪdʒɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wager-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651701",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Agent noun of wage; one who wages.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/wager"],
    },
  ],
  domes: [
    {
      word: "domes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A structural element resembling the hollow upper half of a sphere; a cupola",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Anything shaped like an upset bowl, often used as a cover",
              synonyms: [],
              antonyms: [],
              example: "a cake dome",
            },
            {
              definition: "Head (uppermost part of one's body)",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Head, oral sex", synonyms: [], antonyms: [] },
            {
              definition: "A building; a house; an edifice",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any erection resembling the dome or cupola of a building, such as the upper part of a furnace, the vertical steam chamber on the top of a boiler, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A prism formed by planes parallel to a lateral axis which meet above in a horizontal edge, like the roof of a house; also, one of the planes of such a form",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dome",
        "https://en.wiktionary.org/wiki/domes",
      ],
    },
  ],
  sheen: [
    {
      word: "sheen",
      phonetic: "/ʃiːn/",
      phonetics: [
        {
          text: "/ʃiːn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sheen-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89747640",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Splendor; radiance; shininess.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A thin layer of a substance (such as oil) spread on a solid or liquid surface.",
              synonyms: [],
              antonyms: [],
              example: "oil sheen",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To shine; to glisten.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Beautiful, good-looking, attractive; radiant; shiny.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sheen"],
    },
    {
      word: "sheen",
      phonetic: "/ʃiːn/",
      phonetics: [
        {
          text: "/ʃiːn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sheen-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89747640",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The letter ش in the Arabic script.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sheen"],
    },
  ],
  clang: [
    {
      word: "clang",
      phonetic: "/klæŋ/",
      phonetics: [
        {
          text: "/klæŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/clang-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89033316",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A loud, ringing sound, like that made by free-hanging metal objects striking each other.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Quality of tone.", synonyms: [], antonyms: [] },
            {
              definition:
                "The cry of some birds, including the crane and the goose.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A word or phrase linked only by sound and not by meaning, characteristic of some mental disorders.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To strike (objects) together so as to produce a clang.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To give out a clang; to resound.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any periodic sound, especially one composed of a fundamental and harmonics, as opposed to simple periodic sounds (sine tones).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/clang",
        "https://en.wiktionary.org/wiki/klang",
      ],
    },
  ],
  spade: [
    {
      word: "spade",
      phonetic: "/speɪd/",
      phonetics: [
        {
          text: "/speɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spade-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75858282",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/speɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spade-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1157900",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A garden tool with a handle and a flat blade for digging. Not to be confused with a shovel which is used for moving earth or other materials.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A playing card marked with the symbol ♠.",
              synonyms: [],
              antonyms: [],
              example: "I've got only one spade in my hand.",
            },
            { definition: "A black person.", synonyms: [], antonyms: [] },
            {
              definition: "A cutting instrument used in flensing a whale.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To turn over soil with a spade to loosen the ground for planting.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spade"],
    },
    {
      word: "spade",
      phonetic: "/speɪd/",
      phonetics: [
        {
          text: "/speɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spade-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75858282",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/speɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spade-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1157900",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A hart or stag three years old.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A castrated man or animal.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spade"],
    },
  ],
  sowed: [
    {
      word: "sowed",
      phonetic: "/səʊd/",
      phonetics: [{ text: "/səʊd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To scatter, disperse, or plant (seeds).",
              synonyms: [],
              antonyms: [],
              example: "As you sow, so shall you reap.",
            },
            {
              definition: "To spread abroad; to propagate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To scatter over; to besprinkle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["plant", "scatter"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sow",
        "https://en.wiktionary.org/wiki/sowed",
      ],
    },
  ],
  broil: [
    {
      word: "broil",
      phonetic: "/bɹɔɪl/",
      phonetics: [{ text: "/bɹɔɪl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Food prepared by broiling.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cook by direct, radiant heat.",
              synonyms: ["grill"],
              antonyms: [],
            },
            {
              definition: "To expose to great heat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be exposed to great heat.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["grill"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/broil"],
    },
    {
      word: "broil",
      phonetic: "/bɹɔɪl/",
      phonetics: [{ text: "/bɹɔɪl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A brawl; a rowdy disturbance.",
              synonyms: [],
              antonyms: [],
              example: "come to broils",
            },
          ],
          synonyms: ["skirmish"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cause a rowdy disturbance; embroil.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To brawl.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/broil"],
    },
  ],
  slyly: [
    {
      word: "slyly",
      phonetic: "/ˈslaɪli/",
      phonetics: [{ text: "/ˈslaɪli/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "In a sly manner, cunningly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/slyly"],
    },
  ],
  studs: [
    {
      word: "studs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A male animal, especially a stud horse (stallion), kept for breeding.",
              synonyms: ["sire"],
              antonyms: [],
            },
            {
              definition:
                "A female animal, especially a studmare (broodmare), kept for breeding.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(also by extension) A group of such animals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An animal (usually livestock) that has been registered and is retained for breeding.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A place, such as a ranch, where such animals are kept.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sexually attractive male; also a lover in great demand.",
              synonyms: ["he-man", "hunk"],
              antonyms: [],
            },
          ],
          synonyms: ["he-man", "hunk", "sire"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small object that protrudes from something; an ornamental knob.",
              synonyms: [],
              antonyms: [],
              example: "a collar with studs",
            },
            {
              definition: "(jewelry) A small round earring.",
              synonyms: [],
              antonyms: [],
              example: "She's wearing studs in her ears.",
            },
            {
              definition:
                "A vertical post, especially one of the small uprights in the framing for lath and plaster partitions, and furring, and upon which the laths are nailed.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A stem; a trunk.", synonyms: [], antonyms: [] },
            {
              definition:
                "A type of poker where an individual cannot throw cards away and some of her cards are exposed (also stud poker).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A short rod or pin, fixed in and projecting from something, and sometimes forming a journal.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A stud bolt.", synonyms: [], antonyms: [] },
            {
              definition:
                "An iron brace across the shorter diameter of the link of a chain cable.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person who studies or learns about a particular subject.",
              synonyms: [],
              antonyms: [],
              example: "He is a student of life.",
            },
            {
              definition:
                "A person who is formally enrolled at a school, a college or university, or another educational institution.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["candlewaster", "devotee", "disciple", "scholar"],
          antonyms: ["teacher"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A pair of shoes or boots which have studs on the bottom to aid grip.",
              synonyms: [],
              antonyms: [],
              example: "He wore his studs to play soccer.",
            },
            {
              definition:
                "Tires/tyres with metal protrusions used for improved traction on snow and ice in winter.",
              synonyms: [],
              antonyms: [],
              example:
                "Many US states have outlawed studs due to the damage they cause to paved roads.",
            },
          ],
          synonyms: ["cleats", "spikes"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/stud",
        "https://en.wiktionary.org/wiki/student",
        "https://en.wiktionary.org/wiki/studs",
      ],
    },
  ],
  grunt: [
    {
      word: "grunt",
      phonetic: "/ɡɹʌnt/",
      phonetics: [
        {
          text: "/ɡɹʌnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grunt-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1217824",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A short snorting sound, often to show disapproval, or used as a reply when one is reluctant to speak.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The snorting cry of a pig.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any fish of the perciform family Haemulidae.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person who does ordinary and boring work.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(United States Army and Marine Corps slang) An infantry soldier.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The amount of power of which a vehicle is capable.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A dessert of steamed berries and dough, usually blueberries; blueberry grunt.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["fungee", "fungy", "gofer", "lackey", "peon"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(of a person) To make a grunt or grunts.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of a pig) To make a grunt or grunts.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To break wind; to fart.",
              synonyms: [],
              antonyms: [],
              example: "Who just grunted?",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/grunt"],
    },
  ],
  donor: [
    {
      word: "donor",
      phonetic: "/ˈdəʊnə/",
      phonetics: [
        { text: "/ˈdəʊnə/", audio: "" },
        {
          text: "/ˈdoʊnɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/donor-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50049081",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One who makes a donation.",
              synonyms: [],
              antonyms: [],
              example: "The charity raised $2,000 from various donors.",
            },
            {
              definition:
                "A group or molecule that donates either a radical, electrons or a moiety in a chemical reaction. Compare acceptor.",
              synonyms: [],
              antonyms: [],
              example: "a carbonyl donor molecule",
            },
          ],
          synonyms: ["donater", "donator"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/donor"],
    },
  ],
  slugs: [
    {
      word: "slugs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of many terrestrial pulmonate gastropod mollusks, having no (or only a rudimentary) shell.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A slow, lazy person; a sluggard.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bullet or other projectile fired from a firearm; in modern usage, generally refers to a shotgun slug.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A solid block or piece of roughly shaped metal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A counterfeit coin, especially one used to steal from vending machines.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A shot of a drink, usually alcoholic.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A title, name or header, a catchline, a short phrase or title to indicate the content of a newspaper or magazine story for editing use.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(rarely used) the Imperial (English) unit of mass that accelerates by 1 foot per second squared (1 ft/s²) when a force of one pound-force (lbf) is exerted on it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A discrete mass of a material that moves as a unit, usually through another material.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A motile pseudoplasmodium formed by amoebae working together.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An accessory to a diesel-electric locomotive, used to increase adhesive weight and allow full power to be applied at a lower speed. It has trucks with traction motors but lacks a prime mover.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(television editing) A black screen.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of type metal imprinted by a linotype machine; also a black mark placed in the margin to indicate an error; also said in application to typewriters; type slug.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A stranger picked up as a passenger to enable legal use of high occupancy vehicle lanes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(District of Columbia) A hitchhiking commuter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The last part of a clean URL, the displayed resource name, similar to a filename.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A hindrance, an obstruction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A ship that sails slowly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To drink quickly; to gulp; to down.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To take part in casual carpooling; to form ad hoc, informal carpools for commuting, essentially a variation of ride-share commuting and hitchhiking.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a bullet) To become reduced in diameter, or changed in shape, by passing from a larger to a smaller part of the bore of the barrel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To move slowly or sluggishly; to lie idle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To load with a slug or slugs.",
              synonyms: [],
              antonyms: [],
              example: "to slug a gun",
            },
            { definition: "To make sluggish.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A hard blow, usually with the fist.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To hit very hard, usually with the fist.",
              synonyms: [],
              antonyms: [],
              example: "He insulted my mother, so I slugged him.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/slug",
        "https://en.wiktionary.org/wiki/slugs",
      ],
    },
  ],
  aspen: [
    {
      word: "aspen",
      phonetic: "/ˈæspən/",
      phonetics: [{ text: "/ˈæspən/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Pertaining to the asp or aspen tree.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Tremulous, trembling.", synonyms: [], antonyms: [] },
            {
              definition: "Of a woman's tongue: wagging, gossiping.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/aspen"],
    },
    {
      word: "aspen",
      phonetic: "/ˈæspən/",
      phonetics: [{ text: "/ˈæspən/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The asp tree; a kind of poplar tree (genus Populus sect. Populus). A medium-size tree with thin, straight trunks of a greenish-white color.",
              synonyms: [],
              antonyms: [],
              example:
                "Above a certain elevation, the aspens gave way to scrubby, gnarled pines.",
            },
            {
              definition:
                "The wood of such a tree; usually pale, lightweight and soft.",
              synonyms: [],
              antonyms: [],
              example:
                'She claimed that aspen was the only "proper" material from which make a wicker basket.',
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/aspen"],
    },
  ],
  homer: [
    {
      word: "homer",
      phonetic: "/ˈhəʊmə/",
      phonetics: [
        { text: "/ˈhəʊmə/", audio: "" },
        { text: "/ˈhoʊməɹ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Various former units of volume, particularly:",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["homer", "omer"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A former Hebrew unit of dry volume, about equal to 230 L or 6 1/2 US bushels.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["cor", "kor"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cor",
        "https://en.wiktionary.org/wiki/homer",
      ],
    },
    {
      word: "homer",
      phonetic: "/ˈhəʊmə/",
      phonetics: [
        { text: "/ˈhəʊmə/", audio: "" },
        { text: "/ˈhoʊməɹ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A four-base hit; a home run",
              synonyms: [],
              antonyms: [],
              example: "The first baseman hit a homer to lead off the ninth.",
            },
            {
              definition: "A homing pigeon",
              synonyms: [],
              antonyms: [],
              example:
                "Each of the pigeon fanciers released a homer at the same time.",
            },
            {
              definition:
                "A person who is extremely devoted to his favorite team.",
              synonyms: [],
              antonyms: [],
              example:
                "Joe is such a homer that he would never boo the Hometown Hobos, even if they are in last place in the league.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To hit a homer; to hit a home run.",
              synonyms: [],
              antonyms: [],
              example: "The Sultan of Swat homered 714 times.",
            },
          ],
          synonyms: ["hit out of the park", "jack"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/homer"],
    },
  ],
  croak: [
    {
      word: "croak",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/croak-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241131",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/kɹəʊk/", audio: "" },
        { text: "/kɹoʊk/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A faint, harsh sound made in the throat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The cry of a frog or toad. (see also ribbit)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The harsh cry of various birds, such as the raven or corncrake, or other creatures.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To make a croak.", synonyms: [], antonyms: [] },
            {
              definition: "To utter in a low, hoarse voice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a frog, toad, raven, or various other birds or animals) To make its cry.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To die.", synonyms: [], antonyms: [] },
            {
              definition: "To kill someone or something.",
              synonyms: [],
              antonyms: [],
              example: "He'd seen my face, so I had to croak him.",
            },
            {
              definition:
                "To complain; especially, to grumble; to forebode evil; to utter complaints or forebodings habitually.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/croak"],
    },
  ],
  tithe: [
    {
      word: "tithe",
      phonetic: "/taɪð/",
      phonetics: [{ text: "/taɪð/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A tenth.", synonyms: [], antonyms: [] },
            {
              definition:
                "The tenth part of the increase arising from the profits of land and stock, allotted to the clergy for their support, as in England, or devoted to religious or charitable uses.",
              synonyms: [
                "decim",
                "decima",
                "decimate",
                "decimation",
                "titheling",
                "tithing",
              ],
              antonyms: [],
            },
            {
              definition:
                "A contribution to one's religious community or congregation of worship (notably to the LDS church)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A small part or proportion.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "decim",
            "decima",
            "decimate",
            "decimation",
            "titheling",
            "tithing",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To give one-tenth or a tithe of something, particularly:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To take one-tenth or a tithe of something, particularly:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To compose the tenth part of something.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [{ definition: "Tenth.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tithe"],
    },
    {
      word: "tithe",
      phonetic: "/taɪð/",
      phonetics: [{ text: "/taɪð/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A boon (a grant or concession).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To grant, concede.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Receiving a concession or grant; successful in prayer or request.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tithe"],
    },
  ],
  halts: [
    {
      word: "halts",
      phonetic: "/hɑlts/",
      phonetics: [
        {
          text: "/hɑlts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/halts-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=61043458",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To limp; move with a limping gait.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To stand in doubt whether to proceed, or what to do; hesitate; be uncertain; linger; delay; mammer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be lame, faulty, or defective, as in connection with ideas, or in measure, or in versification.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To waver.", synonyms: [], antonyms: [] },
            { definition: "To falter.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A cessation, either temporary or permanent.",
              synonyms: [],
              antonyms: [],
              example: "The contract negotiations put a halt to operations.",
            },
            {
              definition:
                "A minor railway station (usually unstaffed) in the United Kingdom.",
              synonyms: [],
              antonyms: [],
              example:
                "The halt itself never achieved much importance, even with workers coming to and from the adjacent works.",
            },
          ],
          synonyms: [
            "close",
            "endpoint",
            "terminus",
            "hiatus",
            "moratorium",
            "recess",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To stop marching.", synonyms: [], antonyms: [] },
            {
              definition: "To stop either temporarily or permanently.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To bring to a stop.", synonyms: [], antonyms: [] },
            {
              definition: "To cause to discontinue.",
              synonyms: [],
              antonyms: [],
              example:
                "The contract negotiations halted operations for at least a week.",
            },
          ],
          synonyms: [
            "freeze",
            "immobilize",
            "break off",
            "shut down",
            "stop",
            "terminate",
            "brake",
            "desist",
            "stay",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Lameness; a limp.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/halt",
        "https://en.wiktionary.org/wiki/halts",
      ],
    },
  ],
  avert: [
    {
      word: "avert",
      phonetic: "/əˈvɜːt/",
      phonetics: [
        {
          text: "/əˈvɜːt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/avert-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88987459",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/əˈvɝt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To turn aside or away.",
              synonyms: [],
              antonyms: [],
              example: "To avert the eyes from an object.",
            },
            {
              definition:
                "To ward off, or prevent, the occurrence or effects of.",
              synonyms: [],
              antonyms: [],
              example: "How can the danger be averted?",
            },
            { definition: "To turn away.", synonyms: [], antonyms: [] },
            { definition: "To turn away.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["forestall"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/avert"],
    },
  ],
  havoc: [
    {
      word: "havoc",
      phonetic: "/ˈhæv.ək/",
      phonetics: [
        {
          text: "/ˈhæv.ək/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/havoc-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=7467880",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Widespread devastation, destruction",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Mayhem", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To pillage.", synonyms: [], antonyms: [] },
            { definition: "To cause havoc.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition:
                "A cry in war as the signal for indiscriminate slaughter.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/havoc"],
    },
  ],
  hogan: [
    {
      word: "hogan",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hogan-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75803196",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈhoʊ.ɡən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hogan-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=3224617",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A one-room Navajo dwelling or ceremonial lodge, constructed of wood and earth and covered with mud.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hogan"],
    },
  ],
  glint: [
    {
      word: "glint",
      phonetic: "/ɡlɪnt/",
      phonetics: [{ text: "/ɡlɪnt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A short flash of light.",
              synonyms: [],
              antonyms: [],
              example: "I saw the glint of metal as he raised the gun.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To flash or gleam briefly.",
              synonyms: [],
              antonyms: [],
              example: "A wedding ring glinted on her finger.",
            },
            {
              definition:
                "To glance; to peep forth, as a flower from the bud; to glitter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause to flash or gleam; to reflect.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To dry; to wither.",
              synonyms: [],
              antonyms: [],
              example: "The sun glints grass and corn.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "(of a blade) Not sharp; dull.",
              synonyms: [],
              antonyms: [],
              example: "The knife is glint.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/glint"],
    },
  ],
  ruddy: [
    {
      word: "ruddy",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ruddy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75857491",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈɹʌdi/", audio: "" },
        {
          text: "/ˈɹʌdi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ruddy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2833371",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A ruddy duck.", synonyms: [], antonyms: [] },
            { definition: "A ruddy ground dove.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make reddish in colour.",
              synonyms: [],
              antonyms: [],
              example: "The sunset ruddied our faces.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Reddish in color, especially of the face, fire, or sky.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A mild intensifier, expressing irritation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "bally",
            "bleeding",
            "blimming",
            "bloody",
            "blooming",
            "rosy",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "A mild intensifier, expressing irritation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ruddy"],
    },
  ],
  jeeps: [
    {
      word: "jeeps",
      phonetic: "/dʒiːps/",
      phonetics: [{ text: "/dʒiːps/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small, blocky, military-style vehicle with four-wheel drive, suited to rough terrain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A light utility truck from WWII used by the U.S. Army, and subsequently turned into the trademark Jeep when civilianized by originating manufacturer Willys-Overland.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(genericized trademark) A sport utility vehicle or similar four-wheel-drive vehicle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                '(corrosion) A device for detecting discontinuity in a protective coating, also called a "holiday detector"',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'A covered shopping cart, also called a "shopping stroller".',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/jeep",
        "https://en.wiktionary.org/wiki/jeeps",
      ],
    },
  ],
  flaky: [
    {
      word: "flaky",
      phonetic: "/ˈfleɪkiː/",
      phonetics: [
        {
          text: "/ˈfleɪkiː/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/flaky-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=363312",
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Consisting of flakes or of small, loose masses; lying, or cleaving off, in flakes or layers; flakelike.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a person) Unreliable; likely to make plans with others but then abandon those plans.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a thing) Unreliable; working only on an intermittent basis; likely to malfunction.",
              synonyms: [],
              antonyms: [],
              example:
                "I cannot enjoy the online game because of my flaky Internet connection.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/flaky"],
    },
  ],
  ladle: [
    {
      word: "ladle",
      phonetic: "/ˈleɪ.dəl/",
      phonetics: [
        {
          text: "/ˈleɪ.dəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ladle-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89012596",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈleɪ.dəl/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A deep-bowled spoon with a long, usually curved, handle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A container used in a foundry to transport and pour out molten metal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The float of a mill wheel; a ladle board.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An instrument for drawing the charge of a cannon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A ring, with a handle or handles fitted to it, for carrying shot.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dipper"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To pour or serve something with a ladle.",
              synonyms: [],
              antonyms: [],
              example: "One man ladled molten steel into the shot sleeve.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ladle"],
    },
  ],
  taunt: [
    {
      word: "taunt",
      phonetic: "/tɔːnt/",
      phonetics: [
        {
          text: "/tɔːnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/taunt-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90572957",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/tɔnt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A scornful or mocking remark; a jeer or mockery",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make fun of (someone); to goad (a person) into responding, often in an aggressive manner.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/taunt"],
    },
    {
      word: "taunt",
      phonetic: "/tɔːnt/",
      phonetics: [
        {
          text: "/tɔːnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/taunt-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90572957",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/tɔnt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Very high or tall.",
              synonyms: [],
              antonyms: [],
              example: "a ship with taunt masts",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/taunt"],
    },
  ],
  snore: [
    {
      word: "snore",
      phonetic: "/snɔː/",
      phonetics: [
        { text: "/snɔː/", audio: "" },
        {
          text: "/snɔɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/snore-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769522",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act of snoring, and the noise produced.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An extremely boring person or event.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["snoozefest", "snorefest"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To breathe during sleep with harsh, snorting noises caused by vibration of the soft palate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["saw wood"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/snore"],
    },
  ],
  fines: [
    {
      word: "fines",
      phonetic: "/faɪnz/",
      phonetics: [{ text: "/faɪnz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Fine champagne; French brandy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(usually in the plural) Something that is fine; fine particles.",
              synonyms: [],
              antonyms: [],
              example: "They filtered silt and fines out of the soil.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make finer, purer, or cleaner; to purify or clarify.",
              synonyms: [],
              antonyms: [],
              example: "to fine gold",
            },
            {
              definition: "To become finer, purer, or cleaner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make finer, or less coarse, as in bulk, texture, etc.",
              synonyms: [],
              antonyms: [],
              example: "to fine the soil",
            },
            {
              definition: "To change by fine gradations.",
              synonyms: [],
              antonyms: [],
              example:
                "to fine down a ship's lines, i.e. to diminish her lines gradually",
            },
            {
              definition: "To clarify (wine and beer) by filtration.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To become gradually fine; to diminish; to dwindle (with away, down, or off).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["clarify", "purify", "refine"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A fee levied as punishment for breaking the law.",
              synonyms: [],
              antonyms: [],
              example:
                "The fine for jay-walking has gone from two dollars to thirty in the last fifteen years.",
            },
          ],
          synonyms: ["amercement"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To issue a fine as punishment to (someone).",
              synonyms: [],
              antonyms: [],
              example:
                "She was fined a thousand dollars for littering, but she appealed.",
            },
            { definition: "To pay a fine.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["amerce"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The end of a musical composition.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The location in a musical score that indicates the end of the piece, particularly when the piece ends somewhere in the middle of the score due to a section of the music being repeated.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "End; conclusion; termination; extinction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A final agreement concerning lands or rents between persons, as the lord and his vassal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sum of money or price paid for obtaining a benefit, favor, or privilege, as for admission to a copyhold, or for obtaining or renewing a lease.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To finish; to cease.", synonyms: [], antonyms: [] },
            {
              definition: "To cause to cease; to stop.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fine",
        "https://en.wiktionary.org/wiki/fines",
      ],
    },
  ],
  props: [
    {
      word: "props",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/props-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79268572",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An object placed against or under another, to support it; anything that supports.",
              synonyms: [],
              antonyms: [],
              example: "They stuck a block of wood under it as a prop.",
            },
            {
              definition: "The player on either side of the hooker in a scrum.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One of the seashells in the game of props.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(sometimes figurative) To support or shore up something.",
              synonyms: [],
              antonyms: [],
              example:
                "Try using a phone book to prop up the table where the foot is missing.",
            },
            {
              definition: "To play rugby in the prop position",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                '(usually with "up" - see prop up) To position the feet of (a person) while sitting, lying down, or reclining so that the knees are elevated at a higher level.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An item placed on a stage or set to create a scene or scenario in which actors perform.",
              synonyms: [],
              antonyms: [],
              example: "They used the trophy as a prop in the movie.",
            },
            {
              definition:
                "An item placed within an advertisement in order to suggest a style of living etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The propeller of an aircraft.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To manually start the engine of a propeller-driven aircraft with no electric starter by pulling vigorously on one of the propeller blades using the hands, so that the propeller can catch ignition.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A proposition, especially on an election-day ballot.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Propellant", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/prop",
        "https://en.wiktionary.org/wiki/props",
      ],
    },
    {
      word: "props",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/props-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79268572",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Respect for, or recognition of, another person; an expression of approval or a special acknowledgment; accolades; praise.",
              synonyms: [],
              antonyms: [],
              example:
                "I've got to give props to Roger for the way he handled that situation.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/props"],
    },
  ],
  prune: [
    {
      word: "prune",
      phonetic: "/pɹuːn/",
      phonetics: [
        {
          text: "/pɹuːn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/prune-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79218838",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A plum.", synonyms: [], antonyms: [] },
            {
              definition:
                "The dried, wrinkled fruit of certain species of plum.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An old woman, especially a wrinkly one.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To become wrinkled like a dried plum, as the fingers and toes do when kept submerged in water.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/prune"],
    },
    {
      word: "prune",
      phonetic: "/pɹuːn/",
      phonetics: [
        {
          text: "/pɹuːn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/prune-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79218838",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To remove excess material from a tree or shrub; to trim, especially to make more healthy or productive.",
              synonyms: [],
              antonyms: [],
              example: "A good grape grower will prune the vines once a year.",
            },
            {
              definition:
                "To cut down or shorten (by the removal of unnecessary material).",
              synonyms: [],
              antonyms: [],
              example: "to prune a budget, or an essay",
            },
            {
              definition:
                "To remove unnecessary branches from a tree data structure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To preen; to prepare; to dress.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/prune"],
    },
  ],
  pesos: [
    {
      word: "pesos",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A former unit of currency in Spain and Spain's colonies, worth 8 reales; the Spanish dollar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The currency of various countries, including but not limited to Argentina, Chile, Mexico and the Philippines.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/peso",
        "https://en.wiktionary.org/wiki/pesos",
      ],
    },
  ],
  radii: [
    {
      word: "radii",
      phonetic: "/ˈɹeɪ.diˌaɪ/",
      phonetics: [
        { text: "/ˈɹeɪ.diˌaɪ/", audio: "" },
        {
          text: "/ˈɹeɪˌdi.aɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/radii-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50244322",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The long bone in the forearm, on the side of the thumb.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The lighter bone (or fused portion of bone) in the forelimb of an animal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the major veins of the insect wing, between the subcosta and the media; the vein running along the costal edge of the discal cell.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A line segment between any point of a circle or sphere and its center.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The length of this line segment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Anything resembling a radius, such as the spoke of a wheel, the movable arm of a sextant, or one of the radiating lines of a spider's web.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["R"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/radii",
        "https://en.wiktionary.org/wiki/radius",
      ],
    },
  ],
  pokes: [
    {
      word: "pokes",
      phonetic: "/pəʊks/",
      phonetics: [
        { text: "/pəʊks/", audio: "" },
        { text: "/poʊks/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A prod, jab, or thrust.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A lazy person; a dawdler.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A stupid or uninteresting person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A device to prevent an animal from leaping or breaking through fences, consisting of a yoke with a pole inserted, pointed forward.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The storage of a value in a memory address, typically to modify the behaviour of a program or to cheat at a video game.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A notification sent to get another user's attention on social media or an instant messenger.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A poke bonnet.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To prod or jab with an object such as a finger or a stick.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To stir up a fire to remove ash or promote burning.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To rummage; to feel or grope around.",
              synonyms: [],
              antonyms: [],
              example:
                "I poked about in the rubble, trying to find my lost keys.",
            },
            {
              definition: "To modify the value stored in (a memory address).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To put a poke (device to prevent leaping or breaking fences) on (an animal).",
              synonyms: [],
              antonyms: [],
              example: "to poke an ox",
            },
            {
              definition: "To thrust at with the horns; to gore.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To notify (another user) of activity on social media or an instant messenger.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To thrust (something) in a particular direction such as the tongue.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To penetrate in sexual intercourse.",
              synonyms: [],
              antonyms: [],
              example: "If she smokes, she pokes.",
            },
          ],
          synonyms: ["drill", "nail", "pound", "fumble", "glaum", "root"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A sack or bag.", synonyms: [], antonyms: [] },
            {
              definition: "A long, wide sleeve.",
              synonyms: ["poke sleeve"],
              antonyms: [],
            },
            { definition: "An ice cream cone.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["poke sleeve"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "Pokeweed", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/poke",
        "https://en.wiktionary.org/wiki/pokes",
      ],
    },
  ],
  tiled: [
    {
      word: "tiled",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cover with tiles.",
              synonyms: [],
              antonyms: [],
              example: "The handyman tiled the kitchen.",
            },
            {
              definition:
                "To arrange in a regular pattern, with adjoining edges (applied to tile-like objects, graphics, windows in a computer interface).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To optimize (a loop in program code) by means of the tiling technique.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To protect from the intrusion of the uninitiated.",
              synonyms: [],
              antonyms: [],
              example: "tile the door",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Constructed from, or decorated with tiles",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tile",
        "https://en.wiktionary.org/wiki/tiled",
      ],
    },
  ],
  daisy: [
    {
      word: "daisy",
      phonetic: "/ˈdeɪ.zi/",
      phonetics: [
        { text: "/ˈdeɪ.zi/", audio: "" },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/daisy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50048969",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A wild flowering plant Bellis perennis of the Asteraceae family, with a yellow head and white petals",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Many other flowering plants of various species.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A boot or other footwear.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/daisy"],
    },
  ],
  heron: [
    {
      word: "heron",
      phonetic: "/ˈhɛɹən/",
      phonetics: [
        {
          text: "/ˈhɛɹən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/heron-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=268424",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A long-legged, long-necked wading bird of the family Ardeidae.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/heron"],
    },
  ],
  villa: [
    {
      word: "villa",
      phonetic: "/ˈvɪlə/",
      phonetics: [
        {
          text: "/ˈvɪlə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/villa-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50240584",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                '(plural "villas") A house, often larger and more expensive than average, in the countryside or on the coast, often used as a retreat.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                '(plural "villas") A family house, often semi-detached, in a middle class street.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                '(plural "villae") A country house, with farm buildings around a courtyard.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/villa"],
    },
  ],
  farce: [
    {
      word: "farce",
      phonetic: "/fɑːs/",
      phonetics: [
        {
          text: "/fɑːs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/farce-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1339471",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
        {
          text: "/fɑɹs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/farce-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1088641",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A style of humor marked by broad improbabilities with little regard to regularity or method.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A motion picture or play featuring this style of humor.",
              synonyms: [],
              antonyms: [],
              example:
                "The farce that we saw last night had us laughing and shaking our heads at the same time.",
            },
            {
              definition: "A situation abounding with ludicrous incidents.",
              synonyms: [],
              antonyms: [],
              example: "The first month of labor negotiations was a farce.",
            },
            {
              definition: "A ridiculous or empty show.",
              synonyms: [],
              antonyms: [],
              example:
                "The political arena is a mere farce, with all sorts of fools trying to grab power.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/farce"],
    },
    {
      word: "farce",
      phonetic: "/fɑːs/",
      phonetics: [
        {
          text: "/fɑːs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/farce-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1339471",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
        {
          text: "/fɑɹs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/farce-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1088641",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Forcemeat, stuffing.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To stuff with forcemeat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fill full; to stuff.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To make fat.", synonyms: [], antonyms: [] },
            {
              definition: "To swell out; to render pompous.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/farce"],
    },
  ],
  binds: [
    {
      word: "binds",
      phonetic: "/baɪndz/",
      phonetics: [{ text: "/baɪndz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "That which binds or ties.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A troublesome situation; a problem; a predicament or quandary.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any twining or climbing plant or stem, especially a hop vine; a bine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A ligature or tie for grouping notes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A strong grip or stranglehold on a position that is difficult for the opponent to break.",
              synonyms: [],
              antonyms: [],
              example: "the Maróczy Bind",
            },
            {
              definition: "The indurated clay of coal mines.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To tie; to confine by any ligature.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cohere or stick together in a mass.",
              synonyms: [],
              antonyms: [],
              example: "Just to make the cheese more binding",
            },
            {
              definition:
                "To be restrained from motion, or from customary or natural action, as by friction.",
              synonyms: [],
              antonyms: [],
              example:
                "I wish I knew why the sewing machine binds up after I use it for a while.",
            },
            {
              definition: "To exert a binding or restraining influence.",
              synonyms: [],
              antonyms: [],
              example: "These are the ties that bind.",
            },
            {
              definition:
                "To tie or fasten tightly together, with a cord, band, ligature, chain, etc.",
              synonyms: ["fasten", "fetter", "make fast", "restrain", "tie"],
              antonyms: [],
              example: "to bind grain in bundles  to bind a prisoner",
            },
            {
              definition:
                "To confine, restrain, or hold by physical force or influence of any kind.",
              synonyms: [],
              antonyms: [],
              example: "Frost binds the earth.",
            },
            { definition: "To couple.", synonyms: [], antonyms: [] },
            {
              definition:
                "To oblige, restrain, or hold, by authority, law, duty, promise, vow, affection, or other social tie.",
              synonyms: ["obligate", "restrain", "restrict"],
              antonyms: [],
              example:
                "to bind the conscience  to bind by kindness  bound by affection  commerce binds nations to each other",
            },
            {
              definition:
                "To put (a person) under definite legal obligations, especially, under the obligation of a bond or covenant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To place under legal obligation to serve.",
              synonyms: ["indenture"],
              antonyms: [],
              example: "to bind an apprentice  bound out to service",
            },
            {
              definition:
                "To protect or strengthen by applying a band or binding, as the edge of a carpet or garment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make fast (a thing) about or upon something, as by tying; to encircle with something.",
              synonyms: [],
              antonyms: [],
              example:
                "to bind a belt about one  to bind a compress upon a wound",
            },
            {
              definition: "To cover, as with a bandage.",
              synonyms: ["bandage", "dress"],
              antonyms: [],
              example: "to bind up a wound",
            },
            {
              definition:
                "To prevent or restrain from customary or natural action, as by producing constipation.",
              synonyms: [],
              antonyms: [],
              example: "Certain drugs bind the bowels.",
            },
            {
              definition: "To put together in a cover, as of books.",
              synonyms: [],
              antonyms: [],
              example: "The three novels were bound together.",
            },
            {
              definition: "To make two or more elements stick together.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To associate an identifier with a value; to associate a variable name, method name, etc. with the content of a storage location.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To complain; to whine about something.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "bandage",
            "dress",
            "fasten",
            "fetter",
            "make fast",
            "restrain",
            "tie",
            "indenture",
            "obligate",
            "restrain",
            "restrict",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bind",
        "https://en.wiktionary.org/wiki/binds",
      ],
    },
  ],
  cites: [
    {
      word: "cites",
      phonetic: "/saɪts/",
      phonetics: [{ text: "/saɪts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To quote; to repeat, as a passage from a book, or the words of another.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To list the source(s) from which one took information, words or literary or verbal context.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To summon officially or authoritatively to appear in court.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A citation",
              synonyms: [],
              antonyms: [],
              example:
                "We used the number of cites as a rough measure of the significance of each published paper.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cite",
        "https://en.wiktionary.org/wiki/cites",
      ],
    },
  ],
  fixes: [
    {
      word: "fixes",
      phonetic: "/ˈfɪksɪz/",
      phonetics: [{ text: "/ˈfɪksɪz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A repair or corrective action.",
              synonyms: [],
              antonyms: [],
              example:
                "That plumber's fix is much better than the first one's.",
            },
            {
              definition:
                "A difficult situation; a quandary or dilemma; a predicament.",
              synonyms: [],
              antonyms: [],
              example:
                "It rained before we repaired the roof, and were we in a fix!",
            },
            {
              definition:
                "A single dose of an addictive drug administered to a drug user.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A prearrangement of the outcome of a supposedly competitive process, such as a sporting event, a game, an election, a trial, or a bid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A determination of location.",
              synonyms: [],
              antonyms: [],
              example: "We have a fix on your position.",
            },
            {
              definition: "Fettlings (mixture used to line a furnace)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To pierce; now generally replaced by transfix.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To attach; to affix; to hold in place or at a particular time.",
              synonyms: [],
              antonyms: [],
              example:
                "A dab of chewing gum will fix your note to the bulletin board.",
            },
            {
              definition: "To mend, to repair.",
              synonyms: [],
              antonyms: [],
              example: "That heater will start a fire if you don't fix it.",
            },
            {
              definition: "To prepare (food or drink).",
              synonyms: [],
              antonyms: [],
              example: "She fixed dinner for the kids.",
            },
            {
              definition:
                "To make (a contest, vote, or gamble) unfair; to privilege one contestant or a particular group of contestants, usually before the contest begins; to arrange immunity for defendants by tampering with the justice system via bribery or extortion",
              synonyms: [],
              antonyms: [],
              example:
                "A majority of voters believed the election was fixed in favor of the incumbent.",
            },
            {
              definition:
                "To surgically render an animal, especially a pet, infertile.",
              synonyms: [],
              antonyms: [],
              example:
                "Rover stopped digging under the fence after we had the vet fix him.",
            },
            {
              definition: "(sematics) To map a (point or subset) to itself.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To take revenge on, to best; to serve justice on an assumed miscreant.",
              synonyms: [],
              antonyms: [],
              example:
                "He got caught breaking into lockers, so a couple of guys fixed him after work.",
            },
            {
              definition:
                "To render (a photographic impression) permanent by treating with such applications as will make it insensitive to the action of light.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To convert into a stable or available form.",
              synonyms: [],
              antonyms: [],
              example:
                "Legumes are valued in crop rotation for their ability to fix nitrogen.",
            },
            {
              definition:
                "To become fixed; to settle or remain permanently; to cease from wandering; to rest.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To become firm, so as to resist volatilization; to cease to flow or be fluid; to congeal; to become hard and malleable, as a metallic substance.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "join",
            "put together",
            "unite",
            "doctor",
            "rig",
            "patch",
            "put to rights",
            "rectify",
            "impale",
            "run through",
            "stick",
            "castrate",
            "desex",
            "neuter",
            "spay",
            "establish",
            "settle down",
          ],
          antonyms: ["change", "move"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fix",
        "https://en.wiktionary.org/wiki/fixes",
      ],
    },
  ],
  jerks: [
    {
      word: "jerks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sudden, often uncontrolled movement, especially of the body.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A quick, often unpleasant tug or shake.",
              synonyms: [],
              antonyms: [],
              example: 'When I yell "OK," give the mooring line a good jerk!',
            },
            {
              definition: "A dull or stupid person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person with unlikable or obnoxious qualities and behavior, typically mean, self-centered or disagreeable.",
              synonyms: [],
              antonyms: [],
              example:
                "I finally fired him, because he was being a real jerk to his customers, even to some of the staff.",
            },
            {
              definition:
                "The rate of change in acceleration with respect to time.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A soda jerk.", synonyms: [], antonyms: [] },
            {
              definition:
                "A lift in which the weight is taken with a quick motion from shoulder height to a position above the head with arms fully extended and held there for a brief time.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "jolt",
            "lurch",
            "surge",
            "yank",
            "numbskull",
            "jolt",
            "jump",
            "lurch",
            "asshole",
            "bastard",
            "dick",
            "git",
            "knobhead",
            "tosser",
            "twat",
            "wanker",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make a sudden uncontrolled movement.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To give a quick, often unpleasant tug or shake.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To masturbate.", synonyms: [], antonyms: [] },
            { definition: "To beat, to hit.", synonyms: [], antonyms: [] },
            {
              definition:
                "To throw with a quick and suddenly arrested motion of the hand.",
              synonyms: [],
              antonyms: [],
              example: "to jerk a stone",
            },
            {
              definition: "(usually transitive) To lift using a jerk.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To flout with contempt.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cure (meat) by cutting it into strips and drying it, originally in the sun.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/jerk",
        "https://en.wiktionary.org/wiki/jerks",
      ],
    },
  ],
  livid: [
    {
      word: "livid",
      phonetic: "/ˈlɪvɪd/",
      phonetics: [
        {
          text: "/ˈlɪvɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/livid-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75832613",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having a dark, bluish appearance.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Pale, pallid.", synonyms: [], antonyms: [] },
            {
              definition: "So angry that one turns pale; very angry; furious.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/livid"],
    },
  ],
  waked: [
    {
      word: "waked",
      phonetic: "/ˈweɪkt/",
      phonetics: [
        {
          text: "/ˈweɪkt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/waked-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651703",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(often followed by up) To stop sleeping.",
              synonyms: [],
              antonyms: [],
              example: "I woke up at four o'clock this morning.",
            },
            {
              definition:
                "(often followed by up) To make somebody stop sleeping; to rouse from sleep.",
              synonyms: [],
              antonyms: [],
              example:
                "The neighbour's car alarm woke me from a strange dream.",
            },
            {
              definition: "To put in motion or action; to arouse; to excite.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be excited or roused up; to be stirred up from a dormant, torpid, or inactive state; to be active.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To lay out a body prior to burial in order to allow family and friends to pay their last respects.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To watch, or sit up with, at night, as a dead body.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be or remain awake; not to sleep.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be alert; to keep watch",
              synonyms: [],
              antonyms: [],
              example: "Command unto the guards that they diligently wake.",
            },
            {
              definition:
                "To sit up late for festive purposes; to hold a night revel.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wake",
        "https://en.wiktionary.org/wiki/waked",
      ],
    },
  ],
  inked: [
    {
      word: "inked",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/inked-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79268379",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having a tattoo or tattoos.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/inked"],
    },
    {
      word: "inked",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/inked-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79268379",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To apply ink to; to cover or smear with ink.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To sign (a contract or similar document).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To apply a tattoo to (someone).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of a squid or octopus) to eject ink (sense 3)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["endorse", "initial", "inscribe", "subscribe"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ink",
        "https://en.wiktionary.org/wiki/inked",
      ],
    },
  ],
  booms: [
    {
      word: "booms",
      phonetic: "/buːmz/",
      phonetics: [{ text: "/buːmz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A low-pitched, resonant sound, such as of an explosion.",
              synonyms: [],
              antonyms: [],
              example: "The boom of the surf.",
            },
            {
              definition: "A rapid expansion or increase.",
              synonyms: [],
              antonyms: [],
              example:
                "You should prepare for the coming boom in the tech industry.",
            },
            {
              definition: "One of the calls of certain monkeys or birds.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make a loud, hollow, resonant sound.",
              synonyms: [],
              antonyms: [],
              example: "I can hear the organ slowly booming from the chapel.",
            },
            {
              definition:
                "(of speech) To exclaim with force, to shout, to thunder.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make something boom.",
              synonyms: [],
              antonyms: [],
              example: "Men in grey robes slowly boom the drums of death.",
            },
            { definition: "To publicly praise.", synonyms: [], antonyms: [] },
            {
              definition:
                "To rush with violence and noise, as a ship under a press of sail, before a free wind.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A spar extending the foot of a sail; a spar rigged outboard from a ship's side to which boats are secured in harbour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A movable pole used to support a microphone or camera.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A horizontal member of a crane or derrick, used for lifting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The longest element of a Yagi antenna, on which the other, smaller ones are transversally mounted.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A floating barrier used to obstruct navigation, for military or other purposes; or used for the containment of an oil spill or to control the flow of logs from logging operations.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A wishbone-shaped piece of windsurfing equipment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The section of the arm on a backhoe closest to the tractor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A gymnastics apparatus similar to a balance beam.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To extend, or push, with a boom or pole.",
              synonyms: [],
              antonyms: [],
              example: "to boom off a boat",
            },
            {
              definition:
                '(usually with "up" or "down") To raise or lower with a crane boom.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A period of prosperity, growth, progress, or high market activity.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["recession"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To flourish, grow, or progress.",
              synonyms: [],
              antonyms: [],
              example: "Business was booming.",
            },
            {
              definition: "To cause to advance rapidly in price.",
              synonyms: [],
              antonyms: [],
              example: "to boom railroad or mining shares",
            },
          ],
          synonyms: ["flourish", "prosper"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/boom",
        "https://en.wiktionary.org/wiki/booms",
      ],
    },
  ],
  chews: [
    {
      word: "chews",
      phonetic: "/tʃuːz/",
      phonetics: [
        {
          text: "/tʃuːz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chews-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=60628017",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act of chewing; mastication with the mouth.",
              synonyms: [],
              antonyms: [],
              example: "I popped the gum into my mouth and gave it a chew.",
            },
            { definition: "Level of chewiness.", synonyms: [], antonyms: [] },
            {
              definition:
                "A small sweet, such as a taffy, that is eaten by chewing.",
              synonyms: [],
              antonyms: [],
              example:
                "Phillip purchased a bag of licorice chews at the drugstore.",
            },
            {
              definition: "Chewing tobacco.",
              synonyms: [],
              antonyms: [],
              example:
                "The school had banned chew and smokes from the school grounds, even for adults.",
            },
            {
              definition: "A plug or wad of chewing tobacco; chaw or a chaw.",
              synonyms: [],
              antonyms: [],
              example:
                "The ballplayers sat on the bench watching the rain, glumly working their chews.",
            },
            {
              definition:
                "The condition of something being torn or ground up mechanically.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To crush with the teeth by repeated closing and opening of the jaws; done to food to soften it and break it down by the action of saliva before it is swallowed.",
              synonyms: [],
              antonyms: [],
              example:
                "Make sure to chew thoroughly, and don't talk with your mouth full!",
            },
            {
              definition:
                "To grind, tear, or otherwise degrade or demolish something with teeth or as with teeth.",
              synonyms: [],
              antonyms: [],
              example:
                "He keeps his feed in steel drums to prevent the mice from chewing holes in the feed-sacks.",
            },
            {
              definition: "To think about something; to ponder; to chew over.",
              synonyms: [],
              antonyms: [],
              example:
                "The professor stood at the blackboard, chalk in hand, and chewed the question the student had asked.",
            },
          ],
          synonyms: [
            "bite",
            "chavel",
            "chomp",
            "crunch",
            "masticate",
            "grind",
            "pulverize",
            "rip",
            "shred",
            "tear",
            "contemplate",
            "mull",
            "muse",
            "ponder",
            "ruminate",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/chew",
        "https://en.wiktionary.org/wiki/chews",
      ],
    },
  ],
  licks: [
    {
      word: "licks",
      phonetic: "/lɪks/",
      phonetics: [{ text: "/lɪks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act of licking; a stroke of the tongue.",
              synonyms: [],
              antonyms: [],
              example: "The cat gave its fur a lick.",
            },
            {
              definition:
                "The amount of some substance obtainable with a single lick.",
              synonyms: [],
              antonyms: [],
              example: "Give me a lick of ice cream.",
            },
            {
              definition:
                "A quick and careless application of anything, as if by a stroke of the tongue.",
              synonyms: [],
              antonyms: [],
              example: "a lick of paint",
            },
            {
              definition:
                "A place where animals lick minerals from the ground.",
              synonyms: [],
              antonyms: [],
              example: "The birds gathered at the clay lick.",
            },
            {
              definition:
                "A small watercourse or ephemeral stream. It ranks between a rill and a stream.",
              synonyms: [],
              antonyms: [],
              example: "We used to play in the lick.",
            },
            {
              definition: "A stroke or blow.",
              synonyms: [],
              antonyms: [],
              example: "Hit that wedge a good lick with the sledgehammer.",
            },
            {
              definition: "A small amount; a whit.",
              synonyms: [],
              antonyms: [],
              example: "I didn't do a lick of work today.",
            },
            {
              definition: "An attempt at something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A short motif.",
              synonyms: [],
              antonyms: [],
              example: "There are some really good blues licks in this solo.",
            },
            {
              definition:
                "A rate of speed. (Always qualified by good, fair, or a similar adjective.)",
              synonyms: [],
              antonyms: [],
              example:
                "The bus was travelling at a good lick when it swerved and left the road.",
            },
            {
              definition: "An act of cunnilingus.",
              synonyms: [],
              antonyms: [],
              example: "You up for a lick tonight?",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To stroke with the tongue.",
              synonyms: [],
              antonyms: [],
              example: "The cat licked its fur.",
            },
            {
              definition: "To lap; to take in with the tongue.",
              synonyms: [],
              antonyms: [],
              example:
                "She licked the last of the honey off the spoon before washing it.",
            },
            {
              definition: "To beat with repeated blows.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To defeat decisively, particularly in a fight.",
              synonyms: [],
              antonyms: [],
              example: "My dad can lick your dad.",
            },
            {
              definition: "To overcome.",
              synonyms: [],
              antonyms: [],
              example: "I think I can lick this.",
            },
            {
              definition: "To perform cunnilingus.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To do anything partially.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of flame, waves etc.) To lap.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lick",
        "https://en.wiktionary.org/wiki/licks",
      ],
    },
  ],
  hyena: [
    {
      word: "hyena",
      phonetic: "/haɪˈiːnə/",
      phonetics: [
        {
          text: "/haɪˈiːnə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hyena-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1664870",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of the medium-sized to large feliform carnivores of the subfamily Hyaeninae (genera Hyaena and Crocuta), native to Africa and Asia and noted for the sound similar to laughter which they can make if excited.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Sub-Saharan Africa) A man that performs ritualized sex acts with recently widowed women and menarchal girls.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hyena"],
    },
  ],
  scoff: [
    {
      word: "scoff",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scoff-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75857701",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/skɒf/", audio: "" },
        {
          text: "/skɔːf/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scoff-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=3631313",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Derision; ridicule; a derisive or mocking expression of scorn, contempt, or reproach.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An object of scorn, mockery, or derision.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["derision", "ridicule"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To jeer; to laugh with contempt and derision.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To mock; to treat with scorn.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["sneer", "contemn", "deride"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scoff"],
    },
    {
      word: "scoff",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scoff-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75857701",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/skɒf/", audio: "" },
        {
          text: "/skɔːf/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scoff-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=3631313",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Food.", synonyms: [], antonyms: [] },
            { definition: "The act of eating.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To eat food quickly.", synonyms: [], antonyms: [] },
            { definition: "To eat.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["gobble", "scarf"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scoff"],
    },
  ],
  lusty: [
    {
      word: "lusty",
      phonetic: "/lʌsti/",
      phonetics: [
        {
          text: "/lʌsti/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lusty-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=93560689",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                'Exhibiting lust (in the obsolete sense meaning "vigor"); strong, healthy, robust; vigorous; full of sap or vitality.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Hearty, merry, gleesome, enthusiastic, lively, stirring.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Given to experiencing lust; enjoying physical sensations; lustful.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Beautiful; handsome; pleasant.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Of large size; big.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lusty"],
    },
  ],
  sonic: [
    {
      word: "sonic",
      phonetic: "/ˈsɒnɪk/",
      phonetics: [
        {
          text: "/ˈsɒnɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sonic-us.mp3",
          sourceUrl:
            "https://commons.wikimedia.org/w/index.php?curid=107297052",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of or relating to sound.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Having a speed approaching that of the speed of sound in air.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sonic"],
    },
  ],
  smith: [
    {
      word: "smith",
      phonetic: "/smɪθ/",
      phonetics: [
        {
          text: "/smɪθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/smith-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1669351",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A craftsperson who works metal into desired forms using a hammer and other tools, sometimes heating the metal to make it more workable, especially a blacksmith.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) One who makes anything; wright.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An artist.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["metalsmith"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/smith"],
    },
    {
      word: "smith",
      phonetic: "/smɪθ/",
      phonetics: [
        {
          text: "/smɪθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/smith-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1669351",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To forge, to form, usually on an anvil; by heating and pounding.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/smith"],
    },
  ],
  usher: [
    {
      word: "usher",
      phonetic: "[ˈaʃ.ə(ɹ)]",
      phonetics: [
        {
          text: "[ˈaʃ.ə(ɹ)]",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/usher-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75857689",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "[ˈʌʃ.ə(ɹ)]", audio: "" },
        {
          text: "[ˈʌʃ.ɚ]",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/usher-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=278895",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person, in a church, cinema etc., who escorts people to their seats.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A male escort at a wedding.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A doorkeeper in a courtroom.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An assistant to a head teacher or schoolteacher; an assistant teacher.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Any schoolteacher.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["bridesman", "groomsman"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To guide people to their seats.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To accompany or escort (someone).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To precede; to act as a forerunner or herald.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To lead or guide somewhere",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/usher"],
    },
  ],
  tucks: [
    {
      word: "tucks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An act of tucking; a pleat or fold.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A fold in fabric that has been stitched in place from end to end, as to reduce the overall dimension of the fabric piece.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A curled position.", synonyms: [], antonyms: [] },
            {
              definition: "A plastic surgery technique to remove excess skin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(piano, when playing scales on piano keys) The act of keeping the thumb in position while moving the rest of the hand over it to continue playing keys that are outside the thumb.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A curled position, with the shins held towards the body.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The afterpart of a ship, immediately under the stern or counter, where the ends of the bottom planks are collected and terminate by the tuck-rail.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To pull or gather up (an item of fabric).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To push into a snug position; to place somewhere safe or somewhat hidden.",
              synonyms: [],
              antonyms: [],
              example:
                "Tuck in your shirt.  I tucked in the sheet.  He tucked the $10 bill into his shirt pocket.",
            },
            {
              definition: '(often with "in" or "into") To eat; to consume.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fit neatly.",
              synonyms: [],
              antonyms: [],
              example:
                "The sofa tucks nicely into that corner.  Kenwood House is tucked into a corner of Hampstead Heath.",
            },
            {
              definition:
                "To curl into a ball; to fold up and hold one's legs.",
              synonyms: [],
              antonyms: [],
              example:
                "The diver tucked, flipped, and opened up at the last moment.",
            },
            {
              definition: "To sew folds; to make a tuck or tucks in.",
              synonyms: [],
              antonyms: [],
              example: "to tuck a dress",
            },
            { definition: "To full, as cloth.", synonyms: [], antonyms: [] },
            {
              definition:
                "(of a drag queen, trans woman, etc.) To conceal one’s penis and testicles, as with a gaff or by fastening them down with adhesive tape.",
              synonyms: [],
              antonyms: [],
              example:
                "Honey, have you tucked today? We don’t wanna see anything nasty down there.",
            },
            {
              definition:
                "(when playing scales on piano keys) To keep the thumb in position while moving the rest of the hand over it to continue playing keys that are outside the thumb.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["untuck"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A rapier, a sword.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The beat of a drum.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tuck",
        "https://en.wiktionary.org/wiki/tucks",
      ],
    },
  ],
  vigil: [
    {
      word: "vigil",
      phonetic: "/ˈvɪdʒəl/",
      phonetics: [{ text: "/ˈvɪdʒəl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An instance of keeping awake during normal sleeping hours, especially to keep watch or pray.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A period of observation or surveillance at any hour.",
              synonyms: [],
              antonyms: [],
              example:
                "His dog kept vigil outside the hospital for eight days while he was recovering from an accident.",
            },
            {
              definition:
                "The eve of a religious festival in which staying awake is part of the ritual devotions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A quiet demonstration in support of a cause.",
              synonyms: [],
              antonyms: [],
              example:
                "The protesters kept vigil outside the conference centre in which the party congress was being held.",
            },
          ],
          synonyms: ["look-out", "lookout", "qui vive", "watch"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/vigil"],
    },
  ],
  molts: [
    {
      word: "molts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The process of shedding or losing a covering of fur, feathers or skin etc.",
              synonyms: [],
              antonyms: [],
              example: "Some birds change colour during their winter moult.",
            },
            {
              definition:
                "The skin or feathers cast off during the process of moulting.",
              synonyms: ["exuvia"],
              antonyms: [],
            },
          ],
          synonyms: ["exuvia"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To shed or lose a covering of hair or fur, feathers, skin, horns, etc, and replace it with a fresh one.",
              synonyms: ["shed", "slough"],
              antonyms: [],
            },
            {
              definition: "To shed in such a manner.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["shed", "slough"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/molt",
        "https://en.wiktionary.org/wiki/molts",
        "https://en.wiktionary.org/wiki/moult",
      ],
    },
  ],
  sects: [
    {
      word: "sects",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sects-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651476",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An offshoot of a larger religion; a group sharing particular (often unorthodox) political and/or religious beliefs.",
              synonyms: [],
              antonyms: [],
              example: "A religious sect.",
            },
            {
              definition: "A group following a specific ideal or a leader.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A cutting; a scion.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sect",
        "https://en.wiktionary.org/wiki/sects",
      ],
    },
  ],
  spars: [
    {
      word: "spars",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A rafter of a roof.", synonyms: [], antonyms: [] },
            {
              definition: "A thick pole or piece of wood.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A bar of wood used to fasten a door.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any linear object used as a mast, sprit, yard, boom, pole or gaff.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A beam-like structural member that supports ribs in an aircraft wing or other airfoil.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To bolt, bar.", synonyms: [], antonyms: [] },
            {
              definition: "To supply or equip (a vessel) with spars.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sparring session; a preliminary fight, as in boxing or cock-fighting.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To fight, especially as practice for martial arts or hand-to-hand combat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To strike with the feet or spurs, as cocks do.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To contest in words; to wrangle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of various microcrystalline minerals, of light, translucent, or transparent appearance, which are easily cleft.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any crystal with readily discernible faces.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/spar",
        "https://en.wiktionary.org/wiki/spars",
      ],
    },
  ],
  dumps: [
    {
      word: "dumps",
      phonetic: "/dʌmps/",
      phonetics: [{ text: "/dʌmps/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A place where waste or garbage is left; a ground or place for dumping ashes, refuse, etc.; a disposal site.",
              synonyms: [],
              antonyms: [],
              example: "A toxic waste dump.",
            },
            {
              definition: "A car or boat for dumping refuse, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That which is dumped, especially in a chaotic way; a mess.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An act of dumping, or its result.",
              synonyms: [],
              antonyms: [],
              example: "The new XML dump is coming soon.",
            },
            {
              definition:
                "A formatted listing of the contents of program storage, especially when produced automatically by a failing program",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A storage place for supplies, especially military.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An unpleasant, dirty, disreputable, unfashionable, boring or depressing looking place.",
              synonyms: [],
              antonyms: [],
              example: "Don't feel bad about moving away from this dump.",
            },
            {
              definition:
                '(often with the verb "take") An act of defecation; a defecating.',
              synonyms: [],
              antonyms: [],
              example: "I have to take a dump.",
            },
            {
              definition:
                "(usually in the plural) A sad, gloomy state of the mind; sadness; melancholy; despondency",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Absence of mind; revery.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A pile of ore or rock.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A melancholy strain or tune in music; any tune.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An old kind of dance.", synonyms: [], antonyms: [] },
            {
              definition:
                "A small coin made by punching a hole in a larger coin (called a holey dollar).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A temporary display case that holds many copies of an item being sold.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To release, especially in large quantities and chaotic manner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To discard; to get rid of something one does not want anymore.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To sell below cost or very cheaply; to engage in dumping.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To copy data from a system to another place or system, usually in order to archive it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To output the contents of storage or a data structure, often in order to diagnose a bug.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To end a relationship with.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To knock heavily; to stump.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To put or throw down with more or less of violence; hence, to unload from a cart by tilting it",
              synonyms: [],
              antonyms: [],
              example: "We dumped the coal onto the fireplace.",
            },
            {
              definition: "To precipitate (especially snow) heavily.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A thick, ill-shapen piece.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A lead counter used in the game of chuck-farthing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A deep hole in a river bed; a pool.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dump",
        "https://en.wiktionary.org/wiki/dumps",
      ],
    },
  ],
  scaly: [
    {
      word: "scaly",
      phonetic: "/ˈskeɪli/",
      phonetics: [{ text: "/ˈskeɪli/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The scaly yellowfish, Labeobarbus natalensis.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Covered or abounding with scales.",
              synonyms: ["squamulose"],
              antonyms: ["esquamulose", "scaleless"],
              example: "a scaly fish  a scaly stem",
            },
            {
              definition: "Composed of scales lying over each other.",
              synonyms: [],
              antonyms: [],
              example: "a scaly bulb",
            },
            {
              definition: "Resembling scales, laminae, or layers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Low, mean.",
              synonyms: [],
              antonyms: [],
              example: "a scaly fellow",
            },
          ],
          synonyms: ["squamulose"],
          antonyms: ["esquamulose", "scaleless"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scaly"],
    },
  ],
  wisps: [
    {
      word: "wisps",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small bundle, as of straw or other like substance; any slender, flexible structure or group.",
              synonyms: [],
              antonyms: [],
              example:
                "A wisp of hair escaped her barrette and whipped wildly in the wind.",
            },
            {
              definition: "A whisk, or small broom.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A will o' the wisp, or ignis fatuus.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An immeasurable, indefinable essence of life; soul.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A disease affecting the feet of cattle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wisp",
        "https://en.wiktionary.org/wiki/wisps",
      ],
    },
  ],
  sores: [
    {
      word: "sores",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An injured, infected, inflamed or diseased patch of skin.",
              synonyms: [],
              antonyms: [],
              example: "They put ointment and a bandage on the sore.",
            },
            {
              definition: "Grief; affliction; trouble; difficulty.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A group of ducks on land.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A young hawk or falcon in its first year.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A young buck in its fourth year.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sore",
        "https://en.wiktionary.org/wiki/sores",
      ],
    },
  ],
  mince: [
    {
      word: "mince",
      phonetic: "/mɪns/",
      phonetics: [{ text: "/mɪns/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Finely chopped meat.", synonyms: [], antonyms: [] },
            {
              definition:
                "Finely chopped mixed fruit used in Christmas pies; mincemeat.",
              synonyms: [],
              antonyms: [],
              example: "During Christmas time my dad loves to eat mince pies.",
            },
            {
              definition:
                "An affected (often dainty or short and precise) gait.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An affected manner, especially of speaking; an affectation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(chiefly in the plural) An eye (from mince pie).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make less; make small.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To lessen; diminish; to diminish in speaking; speak of lightly or slightingly; minimise.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To effect mincingly.", synonyms: [], antonyms: [] },
            {
              definition: "To cut into very small pieces; to chop fine.",
              synonyms: [],
              antonyms: [],
              example: "Butchers often use machines to mince meat.",
            },
            {
              definition: "To suppress or weaken the force of",
              synonyms: ["extenuate", "palliate", "weaken"],
              antonyms: [],
            },
            {
              definition: "To say or utter vaguely, not directly or frankly",
              synonyms: [],
              antonyms: [],
              example: "a minced oath",
            },
            {
              definition:
                "To affect; to pronounce affectedly or with an accent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To walk with short steps; to walk in a prim, affected manner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To act or talk with affected nicety; to affect delicacy in manner.",
              synonyms: [],
              antonyms: [],
              example:
                "I love going to gay bars and seeing drag queens mince around on stage.",
            },
          ],
          synonyms: ["extenuate", "palliate", "weaken"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mince"],
    },
  ],
  panda: [
    {
      word: "panda",
      phonetic: "/ˈpændə/",
      phonetics: [
        { text: "/ˈpændə/", audio: "" },
        {
          text: "/ˈpændə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/panda-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1770006",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The red panda (Ailurus fulgens), a small raccoon-like animal of northeast Asia with reddish fur and a long, ringed tail.",
              synonyms: ["bear cat", "cat bear", "lesser panda", "wah"],
              antonyms: [],
            },
            {
              definition: "Short for giant panda.",
              synonyms: ["mottled bear", "panda bear"],
              antonyms: [],
            },
            {
              definition: "(law enforcement) Short for panda car.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "bear cat",
            "cat bear",
            "lesser panda",
            "wah",
            "mottled bear",
            "panda bear",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/panda"],
    },
    {
      word: "panda",
      phonetic: "/ˈpæn-/",
      phonetics: [
        {
          text: "/ˈpæn-/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/panda-2-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88875890",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈpɑndə/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A brahmin who acts as the hereditary superintendent of a particular ghat or temple, and is regarded as knowledgeable in matters of genealogy and ritual.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/panda"],
    },
  ],
  flier: [
    {
      word: "flier",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "That which flies, as a bird or insect.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A machine that flies.", synonyms: [], antonyms: [] },
            { definition: "An airplane pilot.", synonyms: [], antonyms: [] },
            {
              definition: "A person who travels by airplane.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A leaflet, often for advertising.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The part of a spinning machine that twists the thread as it takes it to and winds it on the bobbin",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An arch that connects a flying buttress into the structure it supports.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(acrobatics, cheerleading, synchronized swimming) The person who is lifted and/or thrown by another person or persons.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A stray shot away from the group on a target.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A standard rectangular step of a staircase (as opposed to a winder).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A female kangaroo; a roo; a doe; a jill.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A leap or jump.", synonyms: [], antonyms: [] },
            {
              definition: "A risky investment or other venture.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["circular", "leaflet", "aviator", "aviatrix"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To distribute flyers (leaflets).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To distribute flyers in (a location) or to (recipients).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/flier",
        "https://en.wiktionary.org/wiki/flyer",
      ],
    },
  ],
  axles: [
    {
      word: "axles",
      phonetic: "/ˈæksəlz/",
      phonetics: [{ text: "/ˈæksəlz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Shoulder.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The pin or spindle on which a wheel revolves, or which revolves with a wheel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A transverse bar or shaft connecting the opposite wheels of a car or carriage; an axletree.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An axis.",
              synonyms: [],
              antonyms: [],
              example: "the Sun's axle",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/axle",
        "https://en.wiktionary.org/wiki/axles",
      ],
    },
  ],
  plied: [
    {
      word: "plied",
      phonetic: "/plaɪd/",
      phonetics: [{ text: "/plaɪd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To bend; to fold; to mould; to adapt, to modify; to change (a person's) mind, to cause (a person) to submit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To bend, to flex; to be bent by something, to give way or yield (to a force, etc.).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To work at (something) diligently.",
              synonyms: [],
              antonyms: [],
              example: "He plied his trade as carpenter for forty-three years.",
            },
            {
              definition:
                "To wield or use (a tool, a weapon, etc.) steadily or vigorously.",
              synonyms: [],
              antonyms: [],
              example: "He plied his ax with bloody results.",
            },
            {
              definition: "To press upon; to urge persistently.",
              synonyms: [],
              antonyms: [],
              example: "to ply someone with questions or solicitations",
            },
            {
              definition:
                "To persist in offering something to, especially for the purpose of inducement or persuasion.",
              synonyms: [],
              antonyms: [],
              example: "to ply someone with drink",
            },
            {
              definition: "To travel over (a route) regularly.",
              synonyms: [],
              antonyms: [],
              example: "The steamer plies between several ports on the coast.",
            },
            { definition: "To work diligently.", synonyms: [], antonyms: [] },
            {
              definition:
                "To manoeuvre a sailing vessel so that the direction of the wind changes from one side of the vessel to the other; to work to windward, to beat, to tack.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/plied",
        "https://en.wiktionary.org/wiki/ply",
      ],
    },
  ],
  patio: [
    {
      word: "patio",
      phonetic: "/ˈpæti.əʊ/",
      phonetics: [
        { text: "/ˈpæti.əʊ/", audio: "" },
        {
          text: "/ˈpætioʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/patio-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50422652",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A paved outside area, adjoining a house, used for dining or recreation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An inner courtyard typical of traditional houses in some regions of Spain.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/patio"],
    },
  ],
  rabbi: [
    {
      word: "rabbi",
      phonetic: "/ˈɹæ.baɪ/",
      phonetics: [
        {
          text: "/ˈɹæ.baɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rabbi-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780300",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A Jewish scholar or teacher of halacha (Jewish law), capable of making halachic decisions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A Jew who is or is qualified to be the leader of a Jewish congregation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(police) A senior officer who acts as a mentor.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rabbi"],
    },
  ],
  petal: [
    {
      word: "petal",
      phonetic: "/ˈpɛtl̩/",
      phonetics: [{ text: "/ˈpɛtl̩/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One of the component parts of the corolla of a flower. It applies particularly, but not necessarily only, when the corolla consists of separate parts, that is when the petals are not connately fused. Petals are often brightly colored.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Term of endearment.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/petal"],
    },
  ],
  polyp: [
    {
      word: "polyp",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An abnormal growth protruding from a mucous membrane",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cylindrical coelenterate, such as the hydra, having a mouth surrounded with tentacles",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/polyp"],
    },
  ],
  tints: [
    {
      word: "tints",
      phonetic: "/tɪnts/",
      phonetics: [
        {
          text: "/tɪnts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tints-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651618",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A slight coloring.", synonyms: [], antonyms: [] },
            {
              definition:
                "A pale or faint tinge of any color; especially, a variation of a color obtained by adding white (contrast shade)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A color considered with reference to other very similar colors.",
              synonyms: [],
              antonyms: [],
              example:
                "Red and blue are different colors, but two shades of scarlet are different tints.",
            },
            {
              definition:
                "A shaded effect in engraving, produced by the juxtaposition of many fine parallel lines.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To shade, to color.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tint",
        "https://en.wiktionary.org/wiki/tints",
      ],
    },
  ],
  grate: [
    {
      word: "grate",
      phonetic: "/ɡɹeɪt/",
      phonetics: [
        {
          text: "/ɡɹeɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grate-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=268412",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A horizontal metal grill through which water, ash, or small objects can fall, while larger objects cannot",
              synonyms: [],
              antonyms: [],
              example:
                "The grate stopped the sheep from escaping from their field.",
            },
            {
              definition:
                "A frame or bed, or kind of basket, of iron bars, for holding fuel while burning",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["grill"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To furnish with grates; to protect with a grating or crossbars",
              synonyms: [],
              antonyms: [],
              example: "to grate a window",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/grate"],
    },
    {
      word: "grate",
      phonetic: "/ɡɹeɪt/",
      phonetics: [
        {
          text: "/ɡɹeɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grate-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=268412",
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To shred (things, usually foodstuffs), by rubbing across a grater",
              synonyms: [],
              antonyms: [],
              example:
                "I need to grate the cheese before the potato is cooked.",
            },
            {
              definition:
                "To make an unpleasant rasping sound, often as the result of rubbing against something",
              synonyms: [],
              antonyms: [],
              example:
                "Listening to his teeth grate all day long drives me mad.",
            },
            {
              definition:
                "(by extension) to get on one's nerves; to irritate, annoy",
              synonyms: [],
              antonyms: [],
              example:
                "She’s nice enough, but she can begin to grate if there is no-one else to talk to.",
            },
            {
              definition: "(by extension) to annoy",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/grate"],
    },
    {
      word: "grate",
      phonetic: "/ɡɹeɪt/",
      phonetics: [
        {
          text: "/ɡɹeɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grate-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=268412",
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Serving to gratify; agreeable.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/grate"],
    },
    {
      word: "grate",
      phonetic: "/ɡɹeɪt/",
      phonetics: [
        {
          text: "/ɡɹeɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grate-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=268412",
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Relatively large in scale, size, extent, number (i.e. having many parts or members) or duration (i.e. relatively long); very big.",
              synonyms: [],
              antonyms: [],
              example: "A great storm is approaching our shores.",
            },
            {
              definition:
                "Of larger size or more importance than others of its kind.",
              synonyms: [],
              antonyms: [],
              example: "the great auk",
            },
            {
              definition:
                "(qualifying nouns of family relationship) Involving more generations than the qualified word implies — as many extra generations as repetitions of the word great (from 1510s). [see Derived terms]",
              synonyms: [],
              antonyms: [],
              example:
                "great-grandfather, great-great-grandfather, great-great-great-grandfather",
            },
            {
              definition:
                "(followed by 'with') Pregnant; large with young; full of.",
              synonyms: [],
              antonyms: [],
              example: "great with child",
            },
            {
              definition:
                "(except with 'friend' and similar words such as 'mate','buddy') Intimate; familiar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Extreme or more than usual.",
              synonyms: [],
              antonyms: [],
              example: "great worry",
            },
            {
              definition:
                "Of significant importance or consequence; important.",
              synonyms: [],
              antonyms: [],
              example: "a great decision",
            },
            {
              definition:
                "(applied to actions, thoughts and feelings) Arising from or possessing idealism; admirable; superior; commanding; heroic; illustrious; eminent.",
              synonyms: [],
              antonyms: [],
              example: "a great deed",
            },
            {
              definition: "Impressive or striking.",
              synonyms: [],
              antonyms: [],
              example: "a great show of wealth",
            },
            {
              definition: "Much in use; favoured.",
              synonyms: [],
              antonyms: [],
              example: "Poetry was a great convention of the Romantic era.",
            },
            {
              definition:
                "(applied to persons) Endowed with extraordinary powers; of exceptional talents or achievements; uncommonly gifted; able to accomplish vast results; remarkable; strong; powerful; mighty; noble.",
              synonyms: [],
              antonyms: [],
              example:
                "a great hero, scholar, genius, philosopher, writer etc.",
            },
            {
              definition: "Title referring to an important leader.",
              synonyms: [],
              antonyms: [],
              example: "Alexander the Great",
            },
            {
              definition:
                "Doing or exemplifying (a characteristic or pursuit) on a large scale; active or enthusiastic.",
              synonyms: [],
              antonyms: [],
              example: "He's not a great one for reading.",
            },
            {
              definition: "(often followed by 'at') Skilful or adroit.",
              synonyms: [],
              antonyms: [],
              example: "You are great at singing.",
            },
            {
              definition:
                "Very good; excellent; wonderful; fantastic (from 1848).",
              synonyms: [],
              antonyms: [],
              example: "Dinner was great.",
            },
            {
              definition:
                "Intensifying a word or expression, used in mild oaths.",
              synonyms: [],
              antonyms: [],
              example: "Great Scott!",
            },
          ],
          synonyms: ["gr8", "grt"],
          antonyms: ["mediocre", "ordinary", "tiny"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/grate",
        "https://en.wiktionary.org/wiki/great",
      ],
    },
  ],
  troll: [
    {
      word: "troll",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/troll-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241857",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/tɹɒl/", audio: "" },
        { text: "/tɹoʊl/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A supernatural being of varying size, now especially a grotesque humanoid creature living in caves or hills or under bridges.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An ugly person of either sex, especially one seeking sexual experiences.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Optical ejections from the top of the electrically active core regions of thunderstorms that are red in color that seem to occur after tendrils of vigorous sprites extend downward toward the cloud tops.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/troll"],
    },
    {
      word: "troll",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/troll-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241857",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/tɹɒl/", audio: "" },
        { text: "/tɹoʊl/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An instance of trolling, especially, in fishing, the trailing of a baited line.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person who provokes others (chiefly on the Internet) for their own personal amusement or to cause disruption.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To saunter.", synonyms: [], antonyms: [] },
            {
              definition: "To trundle, to roll from side to side.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To draw someone or something out, to entice, to lure as if with trailing bait.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) To fish using a line and bait or lures trailed behind a boat similarly to trawling; to lure fish with bait.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To angle for with a trolling line, or with a hook drawn along the surface of the water; hence, to allure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fish in; to try to catch fish from.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To stroll about in order to find a sexual partner.",
              synonyms: ["cruise"],
              antonyms: [],
              example: "He spends most of his waking hours trolling on WIRE.",
            },
            {
              definition:
                "(to post inflammatory material so as) to attempt to lure others into combative argument for purposes of personal entertainment and/or gratuitous disruption, especially in an online community or discussion",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) To incite anger (including outside of an Internet context); to provoke, harass or annoy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["cruise"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/troll"],
    },
    {
      word: "troll",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/troll-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241857",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/tɹɒl/", audio: "" },
        { text: "/tɹoʊl/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act of moving round; routine; repetition.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A song whose parts are sung in succession; a catch; a round.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A trolley.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To move circularly; to roll; to turn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To send about; to circulate, as a vessel in drinking.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To sing the parts of in succession, as of a round, a catch, and the like; also, to sing loudly, freely or in a carefree way.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/troll"],
    },
  ],
  tolls: [
    {
      word: "tolls",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Loss or damage incurred through a disaster.",
              synonyms: [],
              antonyms: [],
              example: "The war has taken its toll on the people.",
            },
            {
              definition:
                "A fee paid for some liberty or privilege, particularly for the privilege of passing over a bridge or on a highway, or for that of vending goods in a fair, market, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A fee for using any kind of material processing service.",
              synonyms: [],
              antonyms: [],
              example:
                "We can handle on a toll basis your needs for spray drying, repackaging, crushing and grinding, and dry blending.",
            },
            {
              definition: "A tollbooth.",
              synonyms: [],
              antonyms: [],
              example:
                "We will be replacing some manned tolls with high-speed device readers.",
            },
            {
              definition:
                "A liberty to buy and sell within the bounds of a manor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A portion of grain taken by a miller as a compensation for grinding.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To impose a fee for the use of.",
              synonyms: [],
              antonyms: [],
              example:
                "Once more it is proposed to toll the East River bridges.",
            },
            {
              definition: "To levy a toll on (someone or something).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To take as a toll.", synonyms: [], antonyms: [] },
            {
              definition: "To pay a toll or tallage.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act or sound of tolling",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To ring (a bell) slowly and repeatedly.",
              synonyms: [],
              antonyms: [],
              example: "Ask not for whom the bell tolls.",
            },
            {
              definition: "To summon by ringing a bell.",
              synonyms: [],
              antonyms: [],
              example:
                "The ringer tolled the workers back from the fields for vespers.",
            },
            {
              definition: "To announce by tolling.",
              synonyms: [],
              antonyms: [],
              example: "The bells tolled the King’s death.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To draw; pull; tug; drag.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To tear in pieces.", synonyms: [], antonyms: [] },
            {
              definition: "To draw; entice; invite; allure.",
              synonyms: [],
              antonyms: [],
              example:
                'Hou many virgins shal she tolle and drawe to þe Lord - "Life of Our Lady"',
            },
            {
              definition:
                "To lure with bait; tole (especially, fish and animals).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bait", "lure"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To take away; to vacate; to annul.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To suspend.",
              synonyms: [],
              antonyms: [],
              example:
                "The statute of limitations defense was tolled as a result of the defendant’s wrongful conduct.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/toll",
        "https://en.wiktionary.org/wiki/tolls",
      ],
    },
  ],
  relic: [
    {
      word: "relic",
      phonetic: "/ˈɹɛlɪk/",
      phonetics: [{ text: "/ˈɹɛlɪk/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "That which remains; that which is left after loss or decay; a remaining portion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something old and outdated, possibly kept for sentimental reasons.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A part of the body of a saint, or an ancient religious object, kept for veneration.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["halidom", "lave", "remainder", "remnant", "residue"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(often of guitars) To cause (an object) to appear old or worn, to distress.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/relic"],
    },
  ],
  phony: [
    {
      word: "phony",
      phonetic: "/ˈfoʊni/",
      phonetics: [
        {
          text: "/ˈfoʊni/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/phony-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=541853",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person who assumes an identity or quality other than their own.",
              synonyms: [],
              antonyms: [],
              example:
                "He claims to be a doctor, but he's nothing but a fast-talking phony.",
            },
            {
              definition:
                "A person who professes beliefs or opinions that they do not hold.",
              synonyms: [],
              antonyms: [],
              example:
                "He's such a phony, he doesn't believe half of what he says.",
            },
            {
              definition: "Anything fraudulent or fake.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dissembler", "fake", "faker", "pretender"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Fraudulent; fake; having a misleading appearance.",
              synonyms: [],
              antonyms: [],
              example:
                "A good jeweler should be able to tell a real stone from a phony one.",
            },
          ],
          synonyms: ["bogus", "counterfeit", "fake"],
          antonyms: ["authentic", "genuine"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/phony"],
    },
  ],
  bleat: [
    {
      word: "bleat",
      phonetic: "/ˈbliːt/",
      phonetics: [
        {
          text: "/ˈbliːt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bleat-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88680954",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The characteristic cry of a sheep or a goat.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["baa", "baaing", "bleating"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "Of a sheep or goat, to make its characteristic cry; of a human, to mimic this sound.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of a person, to complain.",
              synonyms: [],
              antonyms: [],
              example:
                "The last thing we need is to hear them bleating to us about organizational problems.",
            },
          ],
          synonyms: ["kvetch", "moan", "whine", "whinge", "baa"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bleat"],
    },
  ],
  flaws: [
    {
      word: "flaws",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A flake, fragment, or shiver.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A thin cake, as of ice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A crack or breach, a gap or fissure; a defect of continuity or cohesion.",
              synonyms: [],
              antonyms: [],
              example: "That vase has a flaw.",
            },
            {
              definition:
                "A defect, fault, or imperfection, especially one that is hidden.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sudden burst or gust of wind of short duration; windflaw.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A storm of short duration.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sudden burst of noise and disorder",
              synonyms: ["quarrel", "tumult", "uproar"],
              antonyms: [],
            },
          ],
          synonyms: ["quarrel", "tumult", "uproar"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/flaw",
        "https://en.wiktionary.org/wiki/flaws",
      ],
    },
  ],
  flake: [
    {
      word: "flake",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/flake-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75269473",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/fleɪk/", audio: "" },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/flake-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=825503",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A loose filmy mass or a thin chiplike layer of anything",
              synonyms: [],
              antonyms: [],
              example:
                "There were a few flakes of paint on the floor from when we were painting the walls.",
            },
            {
              definition: "A scale of a fish or similar animal",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A prehistoric tool chipped out of stone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person who is impractical, flighty, unreliable, or inconsistent; especially with maintaining a living.",
              synonyms: [],
              antonyms: [],
              example:
                "She makes pleasant conversation, but she's kind of a flake when it comes time for action.",
            },
            {
              definition:
                "A carnation with only two colours in the flower, the petals having large stripes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A flat turn or tier of rope.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To break or chip off in a flake.",
              synonyms: [],
              antonyms: [],
              example: "The paint flaked off after only a year.",
            },
            {
              definition:
                "To prove unreliable or impractical; to abandon or desert, to fail to follow through.",
              synonyms: [],
              antonyms: [],
              example: "He said he'd come and help, but he flaked.",
            },
            {
              definition: "To store an item such as rope or sail in layers",
              synonyms: [],
              antonyms: [],
              example:
                "The line is flaked into the container for easy attachment and deployment.",
            },
            {
              definition: "To hit (another person).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/flake"],
    },
    {
      word: "flake",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/flake-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75269473",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/fleɪk/", audio: "" },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/flake-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=825503",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Dogfish.", synonyms: [], antonyms: [] },
            {
              definition: "The meat of the gummy shark.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/flake"],
    },
    {
      word: "flake",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/flake-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75269473",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/fleɪk/", audio: "" },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/flake-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=825503",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Something which is not genuine, or is presented fraudulently.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A trick; a swindle.", synonyms: [], antonyms: [] },
            {
              definition:
                "A move meant to deceive an opposing player, used for gaining advantage for example when dribbling an opponent.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["deke", "feint"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One of the circles or windings of a cable or hawser, as it lies in a coil; a single turn or coil.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A paling; a hurdle.", synonyms: [], antonyms: [] },
            {
              definition:
                "A platform of hurdles, or small sticks made fast or interwoven, supported by stanchions, for drying codfish and other things.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small stage hung over a vessel's side, for workmen to stand on while calking, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fake",
        "https://en.wiktionary.org/wiki/flake",
      ],
    },
  ],
  snags: [
    {
      word: "snags",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A stump or base of a branch that has been lopped off; a short branch, or a sharp or rough branch.",
              synonyms: ["knot", "protuberance"],
              antonyms: [],
            },
            {
              definition: "A dead tree that remains standing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A tree, or a branch of a tree, fixed in the bottom of a river or other navigable water, and rising nearly or quite to the surface, by which boats are sometimes pierced and sunk.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) Any sharp protuberant part of an object, which may catch, scratch, or tear other objects brought into contact with it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A tooth projecting beyond the others; a broken or decayed tooth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A problem or difficulty with something.",
              synonyms: ["hitch"],
              antonyms: [],
            },
            {
              definition: "A pulled thread or yarn, as in cloth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One of the secondary branches of an antler.",
              synonyms: ["point", "tine"],
              antonyms: [],
            },
          ],
          synonyms: ["hitch", "knot", "protuberance", "point", "tine"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To catch or tear (e.g. fabric) upon a rough surface or projection.",
              synonyms: [],
              antonyms: [],
              example:
                "Be careful not to snag your stockings on that concrete bench!",
            },
            {
              definition:
                "To damage or sink (a vessel) by collision; said of a tree or branch fixed to the bottom of a navigable body of water and partially submerged or rising to just beneath the surface.",
              synonyms: [],
              antonyms: [],
              example:
                "The steamboat was snagged on the Mississippi River in 1862.",
            },
            {
              definition:
                "To fish by means of dragging a large hook or hooks on a line, intending to impale the body (rather than the mouth) of the target.",
              synonyms: [],
              antonyms: [],
              example:
                "We snagged for spoonbill from the eastern shore of the Mississippi River.",
            },
            {
              definition: "To obtain or pick up (something).",
              synonyms: [],
              antonyms: [],
              example:
                "Ella snagged a bottle of water from the fridge before leaving for her jog.",
            },
            {
              definition:
                "To stealthily steal with legerdemain prowess (something).",
              synonyms: [],
              antonyms: [],
              example:
                "The smiling little girl snagged her phone while performing a dance; but now was far-off among the crowd.",
            },
            {
              definition:
                "To cut the snags or branches from, as the stem of a tree; to hew roughly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A light meal.", synonyms: [], antonyms: [] },
            {
              definition: "A sausage.",
              synonyms: ["banger", "snarler"],
              antonyms: [],
            },
            { definition: "A goal.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["banger", "snarler"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A misnaged, an opponent to Chassidic Judaism (more likely modern, for cultural reasons).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/snag",
        "https://en.wiktionary.org/wiki/snags",
      ],
    },
  ],
  aptly: [
    {
      word: "aptly",
      phonetic: "/ˈæp(t).li/",
      phonetics: [{ text: "/ˈæp(t).li/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "In an apt or suitable manner; fitly; appropriately",
              synonyms: [],
              antonyms: [],
              example: 'My friend, aptly nicknamed "Shorty"',
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/aptly"],
    },
  ],
  drawl: [
    {
      word: "drawl",
      phonetic: "/dɹɔːl/",
      phonetics: [
        {
          text: "/dɹɔːl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/drawl-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88902758",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/dɹɔl/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A way of speaking slowly while lengthening vowel sounds and running words together. Characteristic of some southern US accents, as well as Scots.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To drag on slowly and heavily; to while or dawdle away time indolently.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To utter or pronounce in a dull, spiritless tone, as if by dragging out the utterance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To move slowly and heavily; move in a dull, slow, lazy manner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To speak with a slow, spiritless utterance, from affectation, laziness, or lack of interest.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/drawl"],
    },
  ],
  ulcer: [
    {
      word: "ulcer",
      phonetic: "/ʌlsə/",
      phonetics: [
        { text: "/ʌlsə/", audio: "" },
        { text: "/ʌlsɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An open sore of the skin, eyes or mucous membrane, often caused by an initial abrasion and generally maintained by an inflammation and/or an infection.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Peptic ulcer", synonyms: [], antonyms: [] },
            {
              definition:
                "Anything that festers and corrupts like an open sore; a vice in character.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ulcer"],
    },
  ],
  soapy: [
    {
      word: "soapy",
      phonetic: "/ˈsəʊpi/",
      phonetics: [
        { text: "/ˈsəʊpi/", audio: "" },
        { text: "/ˈsoʊpi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Resembling soap.",
              synonyms: [],
              antonyms: [],
              example: "a soapy taste",
            },
            {
              definition: "Resembling a soap opera.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Full of soap.",
              synonyms: [],
              antonyms: [],
              example: "soapy water",
            },
            {
              definition: "Covered in soap.",
              synonyms: [],
              antonyms: [],
              example: "His skin was still soapy after the shower.",
            },
            {
              definition: "Committing or involving flattery.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/soapy"],
    },
  ],
  bossy: [
    {
      word: "bossy",
      phonetic: "/ˈbɑsi/",
      phonetics: [
        { text: "/ˈbɑsi/", audio: "" },
        { text: "/ˈbɒsi/", audio: "" },
        { text: "/ˈbɔsi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Tending to give orders to others, especially when unwarranted; domineering.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "authoritarian",
            "commanding",
            "demanding",
            "dictatorial",
            "inflexible",
            "tyrannical",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bossy"],
    },
    {
      word: "bossy",
      phonetic: "/ˈbɑsi/",
      phonetics: [
        { text: "/ˈbɑsi/", audio: "" },
        { text: "/ˈbɒsi/", audio: "" },
        { text: "/ˈbɔsi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A cow or calf.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bossy"],
    },
    {
      word: "bossy",
      phonetic: "/ˈbɑsi/",
      phonetics: [
        { text: "/ˈbɑsi/", audio: "" },
        { text: "/ˈbɒsi/", audio: "" },
        { text: "/ˈbɔsi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Ornamented with bosses; studded.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bossy"],
    },
  ],
  monks: [
    {
      word: "monks",
      phonetic: "/mʌŋks/",
      phonetics: [
        {
          text: "/mʌŋks/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/monks-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2454150",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A male member of a monastic order who has devoted his life for religious service.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In earlier usage, an eremite or hermit devoted to solitude, as opposed to a cenobite, who lived communally.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A male who leads an isolated life; a loner, a hermit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An unmarried man who does not have sexual relationships.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A judge.", synonyms: [], antonyms: [] },
            {
              definition:
                "A blotch or spot of ink on a printed page, caused by the ink not being properly distributed; distinguished from a friar, or white spot caused by a deficiency of ink.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of tinder made of agaric, used in firing the powder hose or train of a mine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A South American monkey (Pithecia monachus); also applied to other species, as Cebus xanthosternos.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The bullfinch, common bullfinch, European bullfinch, or Eurasian bullfinch (Pyrrhula pyrrhula).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The monkfish.", synonyms: [], antonyms: [] },
            {
              definition: "A fuse for firing mines.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To be a monk.", synonyms: [], antonyms: [] },
            {
              definition: "To act like a monk; especially to be contemplative.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To monkey or meddle; to behave in a manner that is not systematic.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be intoxicated or confused.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be attached in a way that sticks out.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A monkey.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/monk",
        "https://en.wiktionary.org/wiki/monks",
      ],
    },
  ],
  crags: [
    {
      word: "crags",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A rocky outcrop; a rugged steep rock or cliff.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A rough broken fragment of rock.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A partially compacted bed of gravel mixed with shells, of the Tertiary age.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The neck or throat.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/crag",
        "https://en.wiktionary.org/wiki/crags",
      ],
    },
  ],
  caged: [
    {
      word: "caged",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To confine in a cage; to put into and keep in a cage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To restrict someone's movement or creativity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To track individual responses to direct mail, either to maintain and develop mailing lists or to identify people who are not eligible to vote because they do not reside at the registered addresses.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Confined in a cage.", synonyms: [], antonyms: [] },
            {
              definition:
                "Of eggs: produced by birds confined in cages; not free-range.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cage",
        "https://en.wiktionary.org/wiki/caged",
      ],
    },
  ],
  twang: [
    {
      word: "twang",
      phonetic: "/twæŋ/",
      phonetics: [
        {
          text: "/twæŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/twang-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75859014",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The sharp, quick sound of a vibrating tight string, for example, of a bow or a musical instrument.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A particular sharp vibrating sound characteristic of electric guitars.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A trace of a regional or foreign accent in someone's voice.",
              synonyms: [],
              antonyms: [],
              example:
                "Despite having lived in Canada for 20 years, he still has that Eastern-European twang in his voice.",
            },
            {
              definition:
                "The sound quality that appears in the human voice when the epilaryngeal tube is narrowed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sharp, disagreeable taste or flavor.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To produce or cause to produce a sharp vibrating sound, like a tense string pulled and suddenly let go.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To have a nasal sound.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To have a trace of a regional or foreign accent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To play a stringed musical instrument by plucking and snapping.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/twang"],
    },
  ],
  diner: [
    {
      word: "diner",
      phonetic: "/ˈdaɪnə(ɹ)/",
      phonetics: [
        {
          text: "/ˈdaɪnə(ɹ)/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/diner-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89013020",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "One who dines.", synonyms: [], antonyms: [] },
            {
              definition: "A dining car in a railroad train.",
              synonyms: ["dining car"],
              antonyms: [],
            },
            {
              definition:
                "A typically small restaurant, usually modeled after a railroad dining car, that serves lower-class fare, normally having a counter with stools along one side and booths on the other, and often decorated in 50s and 60s pop culture themes and playing popular music from those decades.",
              synonyms: ["pub"],
              antonyms: [],
            },
          ],
          synonyms: ["dining car", "pub"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/diner"],
    },
  ],
  taped: [
    {
      word: "taped",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To bind with adhesive tape.",
              synonyms: [],
              antonyms: [],
              example:
                "Be sure to tape your parcel securely before posting it.",
            },
            {
              definition: "To record, particularly onto magnetic tape.",
              synonyms: [],
              antonyms: [],
              example:
                "You shouldn’t have said that. The microphone was on and we were taping.",
            },
            {
              definition: "(passive) To understand, figure out.",
              synonyms: [],
              antonyms: [],
              example: "I've finally got this thing taped.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tape",
        "https://en.wiktionary.org/wiki/taped",
      ],
    },
  ],
  cadet: [
    {
      word: "cadet",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cadet-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241250",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/kəˈdɛt/", audio: "" },
        { text: "/kəˈdɛt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A student at a military school who is training to be an officer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A younger or youngest son, who would not inherit as a firstborn son would.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in compounds, chiefly in genealogy) Junior. (See also the heraldic term cadency.)",
              synonyms: [],
              antonyms: [],
              example: "a cadet branch of the family",
            },
            {
              definition:
                "A young man who makes a business of ruining girls to put them in brothels.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A young gentleman learning sheep farming at a station; also, any young man attached to a sheep station.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cadet"],
    },
  ],
  grids: [
    {
      word: "grids",
      phonetic: "/ɡɹɪdz/",
      phonetics: [{ text: "/ɡɹɪdz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A rectangular array of squares or rectangles of equal size, such as in a crossword puzzle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A system for delivery of electricity, consisting of various substations, transformers and generators, connected by wire.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A system or structure of distributed computers working mostly on a peer-to-peer basis, used mainly to solve single and complex scientific or technical problems or to process data at high speeds (as in clusters).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A method of marking off maps into areas.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The pattern of starting positions of the drivers for a race.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The third (or higher) electrode of a vacuum tube (triode or higher).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A battery-plate somewhat like a grating, especially a zinc plate in a primary battery, or a lead plate in a secondary or storage battery.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A grating of parallel bars; a gridiron.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To mark with a grid.", synonyms: [], antonyms: [] },
            {
              definition: "To assign a reference grid to.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/grid",
        "https://en.wiktionary.org/wiki/grids",
      ],
    },
  ],
  spawn: [
    {
      word: "spawn",
      phonetic: "/ˈspɔːn/",
      phonetics: [{ text: "/ˈspɔːn/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To produce or deposit (eggs) in water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To generate, bring into being, especially non-mammalian beings in very large numbers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To bring forth in general.",
              synonyms: [],
              antonyms: [],
              example:
                "The Web server spawns a new process to handle each client's request.",
            },
            {
              definition: "To induce (aquatic organisms) to spawn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To plant with fungal spawn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To deposit (numerous) eggs in water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To reproduce, especially in large numbers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a character or object) (To cause) to appear spontaneously in a game at a certain point and time.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spawn"],
    },
    {
      word: "spawn",
      phonetic: "/ˈspɔːn/",
      phonetics: [{ text: "/ˈspɔːn/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The numerous eggs of an aquatic organism.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Mushroom mycelium prepared for (aided) propagation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension, sometimes derogatory) Any germ or seed, even a figurative source; offspring.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The buds or branches produced from underground stems.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The location in a game where characters or objects spontaneously appear.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spawn"],
    },
  ],
  guile: [
    {
      word: "guile",
      phonetic: "/ɡaɪl/",
      phonetics: [
        {
          text: "/ɡaɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/guile-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90914546",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Astuteness often marked by a certain sense of cunning or artful deception.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Deceptiveness, deceit, fraud, duplicity, dishonesty.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To deceive, beguile, bewile.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/guile"],
    },
  ],
  noose: [
    {
      word: "noose",
      phonetic: "/nuːs/",
      phonetics: [
        {
          text: "/nuːs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/noose-ca.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=46723172",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An adjustable loop of rope, such as the one placed around the neck in hangings, or the one at the end of a lasso.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To tie or catch in a noose; to entrap or ensnare.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/noose"],
    },
  ],
  mores: [
    {
      word: "mores",
      phonetic: "/ˈmɔː.ɹeɪz/",
      phonetics: [{ text: "/ˈmɔː.ɹeɪz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A set of moral norms or customs derived from generally accepted practices rather than written laws.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mores"],
    },
    {
      word: "mores",
      phonetic: "/mɔː.ɹz/",
      phonetics: [{ text: "/mɔː.ɹz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A carrot; a parsnip.", synonyms: [], antonyms: [] },
            { definition: "A root; stock.", synonyms: [], antonyms: [] },
            { definition: "A plant.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/more",
        "https://en.wiktionary.org/wiki/mores",
      ],
    },
    {
      word: "mores",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To root up.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/more",
        "https://en.wiktionary.org/wiki/mores",
      ],
    },
  ],
  girth: [
    {
      word: "girth",
      phonetic: "/ɡɜːθ/",
      phonetics: [
        {
          text: "/ɡɜːθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/girth-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88680993",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ɡɝθ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A band passed under the belly of an animal, which holds a saddle or a harness saddle in place.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The part of an animal around which the girth fits.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One's waistline circumference, most often a large one.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A small horizontal brace or girder.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The distance measured around an object.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The length of the shortest cycle in a graph.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["cinch", "circumference"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To bind as if with a girth or band.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/girth"],
    },
  ],
  slimy: [
    {
      word: "slimy",
      phonetic: "/ˈslaɪ.mi/",
      phonetics: [
        {
          text: "/ˈslaɪ.mi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/slimy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79218880",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A ponyfish.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of or pertaining to slime",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Resembling, of the nature of, covered or daubed with, or abounding in slime",
              synonyms: ["glutinous", "viscous"],
              antonyms: [],
            },
            {
              definition:
                "Friendly in a false, calculating way; underhanded; sneaky; slick; smarmy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["conniving", "glutinous", "viscous", "lubricous"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/slimy"],
    },
  ],
  aides: [
    {
      word: "aides",
      phonetic: "/eɪdz/",
      phonetics: [
        {
          text: "/eɪdz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/aides-uk.mp3",
          sourceUrl:
            "https://commons.wikimedia.org/w/index.php?curid=100246333",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "An assistant.", synonyms: [], antonyms: [] },
            {
              definition:
                "An officer who acts as assistant to a more senior one; an aide-de-camp.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/aide",
        "https://en.wiktionary.org/wiki/aides",
      ],
    },
  ],
  spasm: [
    {
      word: "spasm",
      phonetic: "/ˈspæz.əm/",
      phonetics: [
        {
          text: "/ˈspæz.əm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spasm-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50358140",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sudden, involuntary contraction of a muscle, a group of muscles, or a hollow organ.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A violent, excruciating seizure of pain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sudden and temporary burst of energy, activity, or emotion.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To produce and undergo a spasm.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spasm"],
    },
  ],
  burrs: [
    {
      word: "burrs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A tree growth in which the grain has grown in a deformed manner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Wood of a mottled veneer, usually cut from such a growth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A knot or lump in thread or cloth.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sharp, pointy object, such as a sliver or splinter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bur; a seed pod with sharp features that stick in fur or clothing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small piece of material left on an edge after a cutting operation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A thin flat piece of metal, formed from a sheet by punching; a small washer put on the end of a rivet before it is swaged down.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A broad iron ring on a tilting lance just below the grip, to prevent the hand from slipping.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The ear lobe.", synonyms: [], antonyms: [] },
            {
              definition: "The knot at the bottom of an antler.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bur", "sticker"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A rough humming sound.",
              synonyms: [],
              antonyms: [],
            },
            { definition: 'A uvular "r".', synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A metal ring at the top of the hand-rest on a spear.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/burl",
        "https://en.wiktionary.org/wiki/burr",
        "https://en.wiktionary.org/wiki/burrs",
      ],
    },
  ],
  alibi: [
    {
      word: "alibi",
      phonetic: "/ˈæl.ə.baɪ/",
      phonetics: [
        {
          text: "/ˈæl.ə.baɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/alibi-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=278880",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The plea or mode of defense under which a person on trial for a crime proves or attempts to prove being in another place when the alleged act was committed",
              synonyms: [],
              antonyms: [],
              example: "to prove an alibi",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To provide an alibi for.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To provide an excuse for.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/alibi"],
    },
  ],
  lymph: [
    {
      word: "lymph",
      phonetic: "/lɪmf/",
      phonetics: [
        { text: "/lɪmf/", audio: "" },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lymph-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50330733",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(poetical) Pure water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A colourless, watery bodily fluid, carried by the lymphatic system, that consists mainly of white blood cells.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Discharge from a sore, inflammation etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lymph"],
    },
  ],
  saucy: [
    {
      word: "saucy",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/saucy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75857651",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈsɔː.sɪ/", audio: "" },
        {
          text: "/ˈsɔ.si/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/saucy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=5052297",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Similar to sauce; having the consistency or texture of sauce.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Impertinent or disrespectful, often in a manner that is regarded as entertaining or amusing; smart.",
              synonyms: [],
              antonyms: [],
              example:
                "She is a loud, saucy child who doesn't show a lot of respect to her elders.",
            },
            {
              definition: "Impudently bold; pert.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Sharp; pungent; piquant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Mildly erotic.",
              synonyms: [],
              antonyms: [],
              example:
                "I enjoyed the dancing, but my wife found it a little too saucy.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/saucy"],
    },
  ],
  muggy: [
    {
      word: "muggy",
      phonetic: "/ˈmʌɡi/",
      phonetics: [{ text: "/ˈmʌɡi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "(Of the weather, air, etc) humid, or hot and humid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Wet or mouldy",
              synonyms: [],
              antonyms: [],
              example: "muggy straw",
            },
          ],
          synonyms: ["close", "oppressive", "sticky", "sultry"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/muggy"],
    },
  ],
  liter: [
    {
      word: "liter",
      phonetic: "/ˈliː.tə/",
      phonetics: [
        { text: "/ˈliː.tə/", audio: "" },
        { text: "/ˈli.tɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The metric unit of fluid measure, equal to one cubic decimetre. Symbols: l, L, ℓ",
              synonyms: [],
              antonyms: [],
              example:
                "You should be able to fill four cups with one litre of water.",
            },
            {
              definition: "A measure of volume equivalent to a litre.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/liter",
        "https://en.wiktionary.org/wiki/litre",
      ],
    },
  ],
  joked: [
    {
      word: "joked",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To do or say something for amusement rather than seriously.",
              synonyms: [],
              antonyms: [],
              example: "I didn’t mean what I said — I was only joking.",
            },
            {
              definition:
                "(intransitive, followed by with) To dupe in a friendly manner for amusement; to mess with, play with.",
              synonyms: [],
              antonyms: [],
              example: "Relax, man, I'm just joking with you.",
            },
            {
              definition: "To make merry with; to make jokes upon; to rally.",
              synonyms: [],
              antonyms: [],
              example: "to joke a comrade",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/joke",
        "https://en.wiktionary.org/wiki/joked",
      ],
    },
  ],
  goofy: [
    {
      word: "goofy",
      phonetic: "/ɡuːfi/",
      phonetics: [{ text: "/ɡuːfi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Silly, quirky", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/goofy"],
    },
    {
      word: "goofy",
      phonetic: "/ɡuːfi/",
      phonetics: [{ text: "/ɡuːfi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One who rides with the right foot forward.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Riding with the right foot forward.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["regular"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/goofy"],
    },
  ],
  exams: [
    {
      word: "exams",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/exams-uk.mp3",
          sourceUrl:
            "https://commons.wikimedia.org/w/index.php?curid=100246322",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The act of examining.", synonyms: [], antonyms: [] },
            {
              definition:
                "Particularly, an inspection by a medical professional to establish the extent and nature of any sickness or injury.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A formal test involving answering written or oral questions under a time constraint and usually without access to textbooks.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Interrogation.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/exam",
        "https://en.wiktionary.org/wiki/examination",
        "https://en.wiktionary.org/wiki/exams",
      ],
    },
  ],
  enact: [
    {
      word: "enact",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/enact-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50423038",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Purpose; determination",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make (a bill) into law",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To act the part of; to play",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To do; to effect", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/enact"],
    },
  ],
  stork: [
    {
      word: "stork",
      phonetic: "/stɔːk/",
      phonetics: [
        { text: "/stɔːk/", audio: "" },
        {
          text: "/stɔɹk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stork-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762778",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A large wading bird with long legs and a long beak of the family Ciconiidae.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(children's folklore) The mythical bringer of babies to families, or good news.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The seventeenth Lenormand card.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stork"],
    },
  ],
  lured: [
    {
      word: "lured",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To attract by temptation etc.; to entice",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To recall a hawk with a lure",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lure",
        "https://en.wiktionary.org/wiki/lured",
      ],
    },
  ],
  toxic: [
    {
      word: "toxic",
      phonetic: "/ˈtɒk.sɪk/",
      phonetics: [
        { text: "/ˈtɒk.sɪk/", audio: "" },
        { text: "/ˈtɑk.sɪk/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Having a chemical nature that is harmful to health or lethal if consumed or otherwise entering into the body in sufficient quantities.",
              synonyms: ["poisonous", "venomous"],
              antonyms: [],
              example: "Tobacco smoke contains many toxic substances.",
            },
            {
              definition:
                "Appearing grossly unwell; characterised by serious, potentially life-threatening compromise in the respiratory, circulatory or other body systems.",
              synonyms: [],
              antonyms: [],
              example: "The child appeared toxic on arrival at the hospital.",
            },
            {
              definition: "Severely negative or harmful.",
              synonyms: [],
              antonyms: [],
              example: "a toxic environment that promoted bullying",
            },
            {
              definition: "(of a person) Hateful or strongly antipathetic.",
              synonyms: [],
              antonyms: [],
              example: "It is not good to be around toxic people.",
            },
          ],
          synonyms: ["poisonous", "venomous"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/toxic"],
    },
  ],
  omens: [
    {
      word: "omens",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Something which portends or is perceived to portend either a good or evil event or circumstance in the future, or which causes a foreboding; a portent or augury.",
              synonyms: [],
              antonyms: [],
              example:
                "a rise in imports might be an omen of economic recovery",
            },
            {
              definition: "A thing of prophetic significance.",
              synonyms: [],
              antonyms: [],
              example: "a sign of ill omen",
            },
          ],
          synonyms: [
            "augury",
            "auspice",
            "danger",
            "forecast",
            "foreshadowing",
            "foretoken",
            "forewarning",
            "harbinger",
            "herald",
            "hint",
            "indication",
            "oracle",
            "portent",
            "prediction",
            "presage",
            "prophecy",
            "sign",
            "signal",
            "straw in the wind",
            "token",
            "warning",
            "writing on the wall",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To be an omen of.", synonyms: [], antonyms: [] },
            {
              definition: "To divine or predict from omens.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "augur",
            "auspicate",
            "betoken",
            "bode",
            "forecast",
            "foreshadow",
            "foretell",
            "portend",
            "predict",
            "prefigure",
            "presage",
            "prognosticate",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/omen",
        "https://en.wiktionary.org/wiki/omens",
      ],
    },
  ],
  nears: [
    {
      word: "nears",
      phonetic: "/nɪə(ɹ)z/",
      phonetics: [
        {
          text: "/nɪə(ɹ)z/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/nears-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80066468",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/nɪɹz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The left side of a horse or of a team of horses pulling a carriage etc.",
              synonyms: ["near side"],
              antonyms: ["off side"],
            },
          ],
          synonyms: ["near side"],
          antonyms: ["off side"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To come closer to; to approach.",
              synonyms: [],
              antonyms: [],
              example: "The ship nears the land.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/near",
        "https://en.wiktionary.org/wiki/nears",
      ],
    },
  ],
  covet: [
    {
      word: "covet",
      phonetic: "/ˈkʌvɪt/",
      phonetics: [{ text: "/ˈkʌvɪt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To wish for with eagerness; to desire possession of, often enviously.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To long for inordinately or unlawfully; to hanker after (something forbidden).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To yearn; to have or indulge an inordinate desire, especially for another's possession.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/covet"],
    },
  ],
  wrung: [
    {
      word: "wrung",
      phonetic: "/ˈɹʌŋ/",
      phonetics: [
        {
          text: "/ˈɹʌŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wrung-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2454399",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To squeeze or twist (something) tightly so that liquid is forced out. See also wring out.",
              synonyms: [],
              antonyms: [],
              example: "I didn’t have a towel so I just wrung my hair dry.",
            },
            {
              definition:
                "To extract (a liquid) from something wet, especially cloth, by squeezing and twisting it.",
              synonyms: [],
              antonyms: [],
              example:
                "Put the berries into a cheesecloth and wring the juice into a bowl.",
            },
            {
              definition:
                "To obtain (something from or out of someone or something) by force.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To draw (something from or out of someone); to generate (something) as a response.",
              synonyms: ["elicit", "provoke"],
              antonyms: [],
            },
            {
              definition: "To hold (something) tightly and press or twist.",
              synonyms: ["strangle", "throttle"],
              antonyms: [],
              example: "to wring one's hands (with worry, etc.)",
            },
            {
              definition:
                "To cause pain or distress to (someone / one's heart, soul, etc.).",
              synonyms: ["torment", "torture"],
              antonyms: [],
            },
            {
              definition:
                "To slide two ultraflat surfaces together such that their faces bond.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To twist, as if in pain.",
              synonyms: ["writhe"],
              antonyms: [],
            },
            {
              definition:
                "To give an incorrect meaning to (words, teachings, etc.).",
              synonyms: ["distort", "pervert", "twist", "wrest"],
              antonyms: [],
            },
            {
              definition:
                "To subject (someone) to extortion; to afflict or oppress in order to enforce compliance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To bend or strain out of its position.",
              synonyms: [],
              antonyms: [],
              example: "to wring a mast",
            },
          ],
          synonyms: [
            "distort",
            "pervert",
            "twist",
            "wrest",
            "elicit",
            "provoke",
            "strangle",
            "throttle",
            "torment",
            "torture",
            "writhe",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wring",
        "https://en.wiktionary.org/wiki/wrung",
      ],
    },
  ],
  forum: [
    {
      word: "forum",
      phonetic: "/ˈfɔːɹəm/",
      phonetics: [{ text: "/ˈfɔːɹəm/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A place for discussion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A gathering for the purpose of discussion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A form of discussion involving a panel of presenters and often participation by members of the audience.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An Internet message board where users can post messages regarding one or more topics of discussion.",
              synonyms: [],
              antonyms: [],
              example:
                "Trish was an admin on three forums, and had no trouble at all when it came to moderating them.",
            },
            {
              definition:
                "A square or marketplace in a Roman town, used for public business and commerce.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/forum"],
    },
  ],
  venom: [
    {
      word: "venom",
      phonetic: "/ˈvɛnəm/",
      phonetics: [
        {
          text: "/ˈvɛnəm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/venom-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762800",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A poison carried by an animal, usually injected into an enemy or prey by biting or stinging.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Feeling or speech marked by spite or malice; vitriol.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["atter"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To infect with venom; to envenom; to poison.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Poisonous, poisoned; (figuratively) pernicious.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/venom"],
    },
  ],
  moody: [
    {
      word: "moody",
      phonetic: "/ˈmuːdi/",
      phonetics: [
        {
          text: "/ˈmuːdi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/moody-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79268192",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Given to sudden or frequent changes of mind; temperamental.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Sulky or depressed.", synonyms: [], antonyms: [] },
            {
              definition: "Dour, gloomy or brooding.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Dodgy or stolen.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/moody"],
    },
  ],
  alder: [
    {
      word: "alder",
      phonetic: "/ˈɑldɚ/",
      phonetics: [
        { text: "/ˈɑldɚ/", audio: "" },
        { text: "/ˈɔːldə/", audio: "" },
        { text: "/ˈɑldɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of several trees or shrubs of the genus Alnus, belonging to the birch family.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/alder"],
    },
    {
      word: "alder",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An alderman or alderwoman.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/alder"],
    },
  ],
  sassy: [
    {
      word: "sassy",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sassy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75857633",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈsasi/", audio: "" },
        { text: "/ˈsæsi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Bold and spirited, cheeky, impudent, saucy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Somewhat sexy and provocative.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Lively, vigorous.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sassy"],
    },
  ],
  flair: [
    {
      word: "flair",
      phonetic: "/flɛə̯/",
      phonetics: [
        { text: "/flɛə̯/", audio: "" },
        {
          text: "/flɛɚ̯/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/flair-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=659547",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A natural or innate talent or aptitude.",
              synonyms: ["gift", "knack", "talent"],
              antonyms: [],
              example: "to have a flair for art",
            },
            {
              definition: "Distinctive style or elegance.",
              synonyms: ["elan", "elegance", "grace", "panache", "style"],
              antonyms: [],
              example: "to dress with flair",
            },
            { definition: "Smell; odor.", synonyms: [], antonyms: [] },
            {
              definition: "Olfaction; sense of smell.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "elan",
            "elegance",
            "grace",
            "panache",
            "style",
            "gift",
            "knack",
            "talent",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To add flair.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/flair"],
    },
  ],
  guild: [
    {
      word: "guild",
      phonetic: "/ɡɪld/",
      phonetics: [{ text: "/ɡɪld/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A group or association mainly of tradespeople made up of merchants, craftspeople, or artisans for mutual aid, particularly in the Middle Ages.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A corporation.", synonyms: [], antonyms: [] },
            {
              definition:
                "A group of diverse species that share common characteristics or habits.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An organized group of players who regularly play together in a multiplayer game.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "Hanse",
            "professional association",
            "trade union",
            "union",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/guild"],
    },
  ],
  prays: [
    {
      word: "prays",
      phonetic: "/pɹeɪz/",
      phonetics: [{ text: "/pɹeɪz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To direct words and/or thoughts to God or any higher being, for the sake of adoration, thanks, petition for help, etc.",
              synonyms: [],
              antonyms: [],
              example: "Muslims pray in the direction of Mecca.",
            },
            {
              definition: "To humbly beg a person for aid or their time.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To ask earnestly for; to seek to obtain by supplication; to entreat for.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To implore, to entreat, to request.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pray",
        "https://en.wiktionary.org/wiki/prays",
      ],
    },
  ],
  wrens: [
    {
      word: "wrens",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any member of a mainly New World passerine bird family Troglodytidae; true wren.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Small bird of similar appearance to a true wren.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wren",
        "https://en.wiktionary.org/wiki/wrens",
      ],
    },
  ],
  hauls: [
    {
      word: "hauls",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To transport by drawing or pulling, as with horses or oxen, or a motor vehicle.",
              synonyms: [],
              antonyms: [],
              example: "to haul logs to a sawmill",
            },
            {
              definition: "To draw or pull something heavy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To carry or transport something, with a connotation that the item is heavy or otherwise difficult to move.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To drag, to pull, to tug.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Followed by up: to summon to be disciplined or held answerable for something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To pull apart, as oxen sometimes do when yoked.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To steer (a vessel) closer to the wind.",
              synonyms: [],
              antonyms: ["veer"],
            },
            {
              definition: "Of the wind: to shift fore (more towards the bow).",
              synonyms: [],
              antonyms: ["veer"],
            },
            {
              definition: "To haul ass.",
              synonyms: [],
              antonyms: [],
              example:
                "“How fast was he goin’?” / “I don’t know exactly, but he must’ve been haulin’, given where he landed.”",
            },
          ],
          synonyms: [],
          antonyms: ["veer", "veer"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/haul",
        "https://en.wiktionary.org/wiki/hauls",
      ],
    },
  ],
  stave: [
    {
      word: "stave",
      phonetic: "/steɪv/",
      phonetics: [
        {
          text: "/steɪv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stave-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80067085",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One of a number of narrow strips of wood, or narrow iron plates, placed edge to edge to form the sides, covering, or lining of a vessel or structure; especially, one of the strips which form the sides of a cask, a pail, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the bars or rounds of a rack, rungs of a ladder, etc; one of the cylindrical bars of a lantern wheel",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A metrical portion; a stanza; a staff.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The five horizontal and parallel lines on and between which musical notes are written or pointed; the staff.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A staff or walking stick.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sign, symbol or sigil, including rune or rune-like characters, used in Icelandic magic.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To fit or furnish with staves or rundles.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(usually with 'in') To break in the staves of; to break a hole in; to burst.",
              synonyms: [],
              antonyms: [],
              example: "to stave in a cask",
            },
            {
              definition: "(with 'off') To push, or keep off, as with a staff.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(usually with 'off') To delay by force or craft; to drive away.",
              synonyms: [],
              antonyms: [],
              example: "We ate grass in an attempt to stave off our hunger.",
            },
            {
              definition:
                "(rare or archaic) To burst in pieces by striking against something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To walk or move rapidly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To suffer, or cause to be lost by breaking the cask.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To render impervious or solid by driving with a calking iron.",
              synonyms: [],
              antonyms: [],
              example:
                "to stave lead, or the joints of pipes into which lead has been run",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stave"],
    },
  ],
  tilts: [
    {
      word: "tilts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A slope or inclination.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The inclination of part of the body, such as backbone, pelvis, head, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The controlled vertical movement of a camera, or a device to achieve this.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A jousting contest.", synonyms: [], antonyms: [] },
            {
              definition:
                "An attempt at something, such as a tilt at public office.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A thrust, as with a lance.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A tilt hammer.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To slope or incline (something); to slant.",
              synonyms: [],
              antonyms: [],
              example: "Tilt the barrel to pour out its contents.",
            },
            {
              definition: "(jousting) To charge (at someone) with a lance.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To be at an angle.", synonyms: [], antonyms: [] },
            {
              definition: "To point or thrust a weapon at.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To point or thrust (a weapon).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To forge (something) with a tilt hammer.",
              synonyms: [],
              antonyms: [],
              example: "to tilt steel in order to render it more ductile",
            },
            {
              definition:
                "To play worse than usual (often as a result of previous bad luck or losses).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a machine) To intentionally let the ball fall down to the drain by disabling flippers and most targets, done as a punishment to the player when the machine is nudged too violently or frequently.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["incline", "slant", "slope"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A canvas covering for carts, boats, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any covering overhead; especially, a tent.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cover with a tilt, or awning.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tilt",
        "https://en.wiktionary.org/wiki/tilts",
      ],
    },
  ],
  pecks: [
    {
      word: "pecks",
      phonetic: "/pɛks/",
      phonetics: [{ text: "/pɛks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An act of striking with a beak.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A small kiss.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To strike or pierce with the beak or bill (of a bird).",
              synonyms: [],
              antonyms: [],
              example: "The birds pecked at their food.",
            },
            {
              definition:
                "To form by striking with the beak or a pointed instrument.",
              synonyms: [],
              antonyms: [],
              example: "to peck a hole in a tree",
            },
            {
              definition:
                "To strike, pick, thrust against, or dig into, with a pointed instrument, especially with repeated quick movements.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To seize and pick up with the beak, or as if with the beak; to bite; to eat; often with up.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To do something in small, intermittent pieces.",
              synonyms: [],
              antonyms: [],
              example:
                "He has been pecking away at that project for some time now.",
            },
            {
              definition: "To type by searching for each key individually.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To type in general.", synonyms: [], antonyms: [] },
            { definition: "To kiss briefly.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One quarter of a bushel; a dry measure of eight quarts.",
              synonyms: [],
              antonyms: [],
              example: "They picked a peck of wheat.",
            },
            {
              definition: "A great deal; a large or excessive quantity.",
              synonyms: [],
              antonyms: [],
              example:
                "She figured most children probably ate a peck of dirt before they turned ten.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To throw.", synonyms: [], antonyms: [] },
            {
              definition:
                "To lurch forward; especially, of a horse, to stumble after hitting the ground with the toe instead of the flat of the foot.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/peck",
        "https://en.wiktionary.org/wiki/pecks",
      ],
    },
  ],
  stomp: [
    {
      word: "stomp",
      phonetic: "/stɒmp/",
      phonetics: [
        { text: "/stɒmp/", audio: "" },
        {
          text: "/stɑmp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stomp-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50279080",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A deliberate heavy footfall; a stamp.",
              synonyms: [],
              antonyms: [],
              example: "She obliterated the cockroach with one stomp.",
            },
            {
              definition: "A dance having a heavy, rhythmic step.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The jazz music for this dance.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To trample heavily.", synonyms: [], antonyms: [] },
            {
              definition:
                "To severely beat someone physically or figuratively.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["crush"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stomp"],
    },
  ],
  gales: [
    {
      word: "gales",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A very strong wind, more than a breeze, less than a storm; number 7 through to 9 winds on the 12-step Beaufort scale.",
              synonyms: [],
              antonyms: [],
              example: "It's blowing a gale outside.",
            },
            {
              definition: "An outburst, especially of laughter.",
              synonyms: [],
              antonyms: [],
              example: "a gale of laughter",
            },
            { definition: "A light breeze.", synonyms: [], antonyms: [] },
            { definition: "A song or story.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A periodic payment, such as is made of a rent or annuity.",
              synonyms: [],
              antonyms: [],
              example: "Gale day - the day on which rent or interest is due.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gale",
        "https://en.wiktionary.org/wiki/gales",
      ],
    },
  ],
  tempt: [
    {
      word: "tempt",
      phonetic: "/tɛmpt/",
      phonetics: [
        { text: "/tɛmpt/", audio: "" },
        {
          text: "/tɛmpt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tempt-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2454334",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To provoke someone to do wrong, especially by promising a reward; to entice.",
              synonyms: [],
              antonyms: [],
              example: "She tempted me to eat the apple.",
            },
            {
              definition: "To attract; to allure.",
              synonyms: [],
              antonyms: [],
              example: "Its glossy skin tempted me.",
            },
            {
              definition: "To provoke something; to court.",
              synonyms: [],
              antonyms: [],
              example: "It would be tempting fate.",
            },
          ],
          synonyms: [
            "beguile",
            "entrance",
            "entice",
            "fand",
            "lure",
            "pander",
            "tease",
            "foment",
            "urge",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tempt"],
    },
  ],
  capes: [
    {
      word: "capes",
      phonetic: "/keɪps/",
      phonetics: [{ text: "/keɪps/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A piece or point of land, extending beyond the adjacent coast into a sea or lake; a promontory; a headland.",
              synonyms: ["chersonese", "peninsula", "point"],
              antonyms: [],
            },
          ],
          synonyms: ["chersonese", "peninsula", "point"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sleeveless garment or part of a garment, hanging from the neck over the back, arms, and shoulders.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A superhero.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cape",
        "https://en.wiktionary.org/wiki/capes",
      ],
    },
  ],
  mesas: [
    {
      word: "mesas",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Flat area of land or plateau higher than other land, with one or more clifflike edges.",
              synonyms: [],
              antonyms: [],
              example:
                "A few more miles of hot sand and gravel and red stone brought us around a low mesa to the Little Colorado River.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mesa",
        "https://en.wiktionary.org/wiki/mesas",
      ],
    },
  ],
  omits: [
    {
      word: "omits",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To leave out or exclude.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To fail to perform.", synonyms: [], antonyms: [] },
            {
              definition: "To neglect or take no notice of.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "leave off",
            "miss out",
            "disregard",
            "ignore",
            "pass",
            "turn a blind eye",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/omit",
        "https://en.wiktionary.org/wiki/omits",
      ],
    },
  ],
  tepee: [
    {
      word: "tepee",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Alternative form of teepee",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tepee"],
    },
  ],
  harry: [
    {
      word: "harry",
      phonetic: "/hæɹi/",
      phonetics: [
        { text: "/hæɹi/", audio: "" },
        { text: "/hæɹi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To plunder, pillage, assault.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make repeated attacks on an enemy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To strip, lay waste, ravage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To harass, bother or distress with demands, threats, or criticism.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/harry"],
    },
  ],
  wring: [
    {
      word: "wring",
      phonetic: "/ɹɪŋ/",
      phonetics: [
        {
          text: "/ɹɪŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wring-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1157506",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A powerful squeezing or twisting action.",
              synonyms: [],
              antonyms: [],
              example: "I grasped his hand and gave it a grateful wring.",
            },
            { definition: "Pain or distress.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To squeeze or twist (something) tightly so that liquid is forced out. See also wring out.",
              synonyms: [],
              antonyms: [],
              example: "I didn’t have a towel so I just wrung my hair dry.",
            },
            {
              definition:
                "To extract (a liquid) from something wet, especially cloth, by squeezing and twisting it.",
              synonyms: [],
              antonyms: [],
              example:
                "Put the berries into a cheesecloth and wring the juice into a bowl.",
            },
            {
              definition:
                "To obtain (something from or out of someone or something) by force.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To draw (something from or out of someone); to generate (something) as a response.",
              synonyms: ["elicit", "provoke"],
              antonyms: [],
            },
            {
              definition: "To hold (something) tightly and press or twist.",
              synonyms: ["strangle", "throttle"],
              antonyms: [],
              example: "to wring one's hands (with worry, etc.)",
            },
            {
              definition:
                "To cause pain or distress to (someone / one's heart, soul, etc.).",
              synonyms: ["torment", "torture"],
              antonyms: [],
            },
            {
              definition:
                "To slide two ultraflat surfaces together such that their faces bond.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To twist, as if in pain.",
              synonyms: ["writhe"],
              antonyms: [],
            },
            {
              definition:
                "To give an incorrect meaning to (words, teachings, etc.).",
              synonyms: ["distort", "pervert", "twist", "wrest"],
              antonyms: [],
            },
            {
              definition:
                "To subject (someone) to extortion; to afflict or oppress in order to enforce compliance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To bend or strain out of its position.",
              synonyms: [],
              antonyms: [],
              example: "to wring a mast",
            },
          ],
          synonyms: [
            "distort",
            "pervert",
            "twist",
            "wrest",
            "elicit",
            "provoke",
            "strangle",
            "throttle",
            "torment",
            "torture",
            "writhe",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/wring"],
    },
    {
      word: "wring",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A device for pressing or compressing, especially for cider.",
              synonyms: ["press"],
              antonyms: [],
            },
          ],
          synonyms: ["press"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/wring"],
    },
  ],
  evoke: [
    {
      word: "evoke",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/evoke-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75730547",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ɪˈvəʊk/", audio: "" },
        {
          text: "/ɪˈvoʊk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/evoke-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=10235741",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To call out; to draw out or bring forth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause the manifestation of something (emotion, picture, etc.) in someone's mind or imagination.",
              synonyms: [],
              antonyms: [],
              example: "Being here evokes long forgotten memories.",
            },
            { definition: "To elicit a response.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/evoke"],
    },
  ],
  limes: [
    {
      word: "limes",
      phonetic: "/ˈlʌɪmiːz/",
      phonetics: [{ text: "/ˈlʌɪmiːz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A boundary or border, especially of the Roman Empire.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/limes"],
    },
    {
      word: "limes",
      phonetic: "/lʌɪmz/",
      phonetics: [{ text: "/lʌɪmz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any inorganic material containing calcium, usually calcium oxide (quicklime) or calcium hydroxide (slaked lime).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any gluey or adhesive substance; something which traps or captures someone; sometimes a synonym for birdlime.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A deciduous tree of the genus Tilia, especially Tilia × europaea; the linden tree, or its wood.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of several green citrus fruit, somewhat smaller and sharper-tasting than a lemon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of the trees that bear limes, especially Key lime, Citrus aurantiifolia.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A brilliant, sometimes yellowish, green colour associated with the fruits of a lime tree.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A fan fiction story which contains sexual references, but stops short of full, explicit descriptions of sexual activity (coined by analogy with lemon).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "A leash.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lime",
        "https://en.wiktionary.org/wiki/limes",
        "https://en.wiktionary.org/wiki/lyam",
      ],
    },
  ],
  cluck: [
    {
      word: "cluck",
      phonetic: "/klʌk/",
      phonetics: [
        {
          text: "/klʌk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cluck-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79268453",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The sound made by a hen, especially when brooding, or calling her chicks.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any sound similar to this.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A kind of tongue click used to urge on a horse.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To make such a sound.", synonyms: [], antonyms: [] },
            {
              definition: "To cause (the tongue) to make a clicking sound.",
              synonyms: [],
              antonyms: [],
              example: "My mother clucked her tongue in disapproval.",
            },
            {
              definition:
                "To call together, or call to follow, as a hen does her chickens.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To suffer withdrawal from heroin.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cluck"],
    },
  ],
  lunge: [
    {
      word: "lunge",
      phonetic: "/lʌndʒ/",
      phonetics: [
        {
          text: "/lʌndʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lunge-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75832850",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/lʌndʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lunge-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1524030",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A sudden forward movement, especially with a sword.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A long rope or flat web line, more commonly referred to as a lunge line, approximately 20–30 feet long, attached to the bridle, lungeing cavesson, or halter of a horse and used to control the animal while lungeing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An exercise performed by stepping forward one leg while kneeling with the other leg, then returning to a standing position.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A fish, the namaycush.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To (cause to make) a sudden forward movement (present participle: lunging).",
              synonyms: [],
              antonyms: [],
              example:
                "I lunged at the police officer and made a grab for her gun.",
            },
            {
              definition:
                "To longe or work a horse in a circle around a handler (present participle: lunging or lungeing).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lunge"],
    },
  ],
  highs: [
    {
      word: "highs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A high point or position, literally or figuratively; an elevated place; a superior region; a height; the sky; heaven.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A point of success or achievement; a time when things are at their best.",
              synonyms: [],
              antonyms: [],
              example: "It was one of the highs of his career.",
            },
            {
              definition:
                "A period of euphoria, from excitement or from an intake of drugs.",
              synonyms: [],
              antonyms: [],
              example:
                "That pill gave me a high for a few hours, before I had a comedown.",
            },
            {
              definition: "A drug that gives such a high.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A large area of elevated atmospheric pressure; an anticyclone.",
              synonyms: [],
              antonyms: [],
              example: "A large high is centred on the Azores.",
            },
            {
              definition:
                "The maximum value attained by some quantity within a specified period.",
              synonyms: [],
              antonyms: [],
              example: "Inflation reached a ten-year high.",
            },
            {
              definition:
                "The maximum atmospheric temperature recorded at a particular location, especially during one 24-hour period.",
              synonyms: [],
              antonyms: [],
              example: "Today's high was 32°C.",
            },
            {
              definition: "The highest card dealt or drawn.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Thought; intention; determination; purpose.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/high",
        "https://en.wiktionary.org/wiki/highs",
      ],
    },
  ],
  canes: [
    {
      word: "canes",
      phonetic: "/keɪnz/",
      phonetics: [{ text: "/keɪnz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A plant with simple stems, like bamboo or sugar cane, or the stem thereof",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The stem of such a plant adapted for use as a tool",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A rod-shaped tool or device, somewhat like a cane",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Split rattan, as used in wickerwork, basketry and the like",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A local European measure of length; the canna.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To strike or beat with a cane or similar implement",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To destroy; to comprehensively defeat",
              synonyms: [],
              antonyms: [],
              example: "Mudchester Rovers were caned 10-0.",
            },
            {
              definition: "To do something well, in a competent fashion",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To produce extreme pain",
              synonyms: [],
              antonyms: [],
              example: "Don't hit me with that. It really canes!",
            },
            {
              definition: "To make or furnish with cane or rattan.",
              synonyms: [],
              antonyms: [],
              example: "to cane chairs",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The genus Arundinaria, the sole temperate genus of bamboo native to the New World.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cane",
        "https://en.wiktionary.org/wiki/canes",
      ],
    },
  ],
  giddy: [
    {
      word: "giddy",
      phonetic: "/ɡɪdi/",
      phonetics: [
        {
          text: "/ɡɪdi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/giddy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2650986",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make dizzy or unsteady.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To reel; to whirl.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Dizzy, feeling dizzy or unsteady and as if about to fall down.",
              synonyms: [],
              antonyms: [],
              example: "The man became giddy upon standing up so fast.",
            },
            {
              definition:
                "Causing dizziness: causing dizziness or a feeling of unsteadiness.",
              synonyms: [],
              antonyms: [],
              example: "They climbed to a giddy height.",
            },
            {
              definition: "Lightheartedly silly, or joyfully elated.",
              synonyms: [],
              antonyms: [],
              example:
                "The boy was giddy when he opened his birthday presents.",
            },
            {
              definition: "Frivolous, impulsive, inconsistent, changeable.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dizzy"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/giddy"],
    },
  ],
  lithe: [
    {
      word: "lithe",
      phonetic: "/laɪð/",
      phonetics: [
        {
          text: "/laɪð/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lithe-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1869888",
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [{ definition: "To go.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lithe"],
    },
    {
      word: "lithe",
      phonetic: "/laɪð/",
      phonetics: [
        {
          text: "/laɪð/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lithe-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1869888",
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Mild; calm.",
              synonyms: ["clement", "gentle", "mellow"],
              antonyms: [],
              example: "lithe weather",
            },
            {
              definition: "Slim but not skinny.",
              synonyms: ["lissome", "lithesome", "swack"],
              antonyms: [],
              example: "lithe body",
            },
            {
              definition: "Capable of being easily bent; flexible.",
              synonyms: ["flexible", "limber", "pliant"],
              antonyms: [],
              example: "the elephant’s lithe proboscis.",
            },
            { definition: "Adaptable.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "clement",
            "gentle",
            "mellow",
            "flexible",
            "limber",
            "pliant",
            "lissome",
            "lithesome",
            "swack",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lithe"],
    },
    {
      word: "lithe",
      phonetic: "/laɪð/",
      phonetics: [
        {
          text: "/laɪð/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lithe-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1869888",
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To become calm.", synonyms: [], antonyms: [] },
            {
              definition:
                "To make soft or mild; soften; alleviate; mitigate; lessen; smooth; palliate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lithe"],
    },
    {
      word: "lithe",
      phonetic: "/laɪð/",
      phonetics: [
        {
          text: "/laɪð/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lithe-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1869888",
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To attend; listen, hearken.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To listen to, hearken to.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lithe"],
    },
    {
      word: "lithe",
      phonetic: "/laɪð/",
      phonetics: [
        {
          text: "/laɪð/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lithe-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1869888",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "Shelter.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lithe"],
    },
  ],
  verge: [
    {
      word: "verge",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/verge-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75701828",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/vɜːd͡ʒ/", audio: "" },
        {
          text: "/vɝd͡ʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/verge-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651686",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A rod or staff of office, e.g. of a verger.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An edge or border.", synonyms: [], antonyms: [] },
            { definition: "The phallus.", synonyms: [], antonyms: [] },
            {
              definition: "An old measure of land: a virgate or yardland.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A circumference; a circle; a ring.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The shaft of a column, or a small ornamental shaft.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The edge of the tiling projecting over the gable of a roof.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The spindle of a watch balance, especially one with pallets, as in the old vertical escapement.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/verge"],
    },
    {
      word: "verge",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/verge-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75701828",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/vɜːd͡ʒ/", audio: "" },
        {
          text: "/vɝd͡ʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/verge-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651686",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To be or come very close; to border; to approach.",
              synonyms: [],
              antonyms: [],
              example: "Eating blowfish verges on insanity.",
            },
            {
              definition: "To bend or incline; to tend downward; to slope.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/verge"],
    },
  ],
  khaki: [
    {
      word: "khaki",
      phonetic: "/ˈkɑː.ki/",
      phonetics: [
        {
          text: "/ˈkɑː.ki/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/khaki-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79218753",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈkæki/", audio: "" },
        { text: "/ˈkɑː.ki/", audio: "" },
        { text: "/ˈkɑː.ki/", audio: "" },
        { text: "/ˈkæ.ki/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A dull, yellowish-brown colour, the colour of dust.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Khaki green, a dull green colour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A strong cloth of wool or cotton, often used for military or other uniforms.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A soldier wearing a khaki uniform.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A British person (from the colour of the uniform of British troops, originally in the Second Boer War; compare rooinek). (In this sense the plural generally is khakies.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Khaki clothing or uniform, commonly in the plural.",
              synonyms: [],
              antonyms: [],
              example: "he was dressed in khaki, he was wearing his khakies",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Dust-coloured; of the colour of dust.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/khaki"],
    },
  ],
  queue: [
    {
      word: "queue",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/queue-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75857534",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/kjuː/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/queue-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=6282262",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
        { text: "/kju/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "An animal's tail.", synonyms: [], antonyms: [] },
            {
              definition:
                "A men's hairstyle whose primary attribute is a braid or ponytail at the back of the head, such as that worn by men in Imperial China.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A line of people, vehicles or other objects, in which one at the front end is dealt with first, the one behind is dealt with next, and so on, and which newcomers join at the opposite end (the back).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A waiting list or other means of organizing people or objects into a first-come-first-served order.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A data structure in which objects are added to one end, called the tail, and removed from the other, called the head (- a FIFO queue). The term can also refer to a LIFO queue or stack where these ends coincide.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["line", "lineup"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To put oneself or itself at the end of a waiting line.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To arrange themselves into a physical waiting queue.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To add to a queue data structure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fasten the hair into a queue.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["join", "line up"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/queue"],
    },
  ],
  loath: [
    {
      word: "loath",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/loath-1-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75832638",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ləʊθ/", audio: "" },
        { text: "/loʊθ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Averse, disinclined; reluctant, unwilling.",
              synonyms: [],
              antonyms: [],
              example:
                "I was loath to return to the office without the Henderson file.",
            },
            { definition: "Angry, hostile.", synonyms: [], antonyms: [] },
            {
              definition: "Loathsome, unpleasant.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/loath"],
    },
    {
      word: "loath",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To detest, hate, revile.",
              synonyms: ["abhor", "abominate", "despise"],
              antonyms: [],
              example: "I absolutely loathe this place.",
            },
          ],
          synonyms: ["abhor", "abominate", "despise"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/loath",
        "https://en.wiktionary.org/wiki/loathe",
      ],
    },
  ],
  foyer: [
    {
      word: "foyer",
      phonetic: "/ˈfoɪɘ/",
      phonetics: [
        { text: "/ˈfoɪɘ/", audio: "" },
        {
          text: "/ˈfɔɪ.eɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/foyer-ca.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=43447274",
        },
        { text: "/ˈfɔɪ.eɪ/", audio: "" },
        { text: "/ˈfɔɪ.eɪ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A lobby, corridor, or waiting room, used in a hotel, theater, etc.",
              synonyms: [],
              antonyms: [],
              example:
                "We had a drink in the foyer waiting for the play to start.",
            },
            {
              definition:
                "The crucible or basin in a furnace which receives the molten metal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hostel offering accommodation and work opportunities to homeless young people.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/foyer"],
    },
  ],
  outdo: [
    {
      word: "outdo",
      phonetic: "/aʊtˈdu/",
      phonetics: [{ text: "/aʊtˈdu/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To excel; go beyond in performance; surpass.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["outperform", "outrival"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/outdo"],
    },
  ],
  fared: [
    {
      word: "fared",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To go, travel.",
              synonyms: [],
              antonyms: [],
              example: "Behold! A knight fares forth.",
            },
            {
              definition:
                "To get along, succeed (well or badly); to be in any state, or pass through any experience, good or bad; to be attended with any circumstances or train of events.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To eat, dine.", synonyms: [], antonyms: [] },
            {
              definition: "To happen well, or ill.",
              synonyms: [],
              antonyms: [],
              example: "We shall see how it will fare with him.",
            },
            {
              definition: "To move along; proceed; progress; advance",
              synonyms: [],
              antonyms: [],
              example:
                "We will continue to monitor how the hurricane fares against projected models.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fare",
        "https://en.wiktionary.org/wiki/fared",
      ],
    },
  ],
  deter: [
    {
      word: "deter",
      phonetic: "/dɪˈtɜː(ɹ)/",
      phonetics: [
        {
          text: "/dɪˈtɜː(ɹ)/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/deter-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1784096",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To prevent something from happening.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To persuade someone not to do something; to discourage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To distract someone from something.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dissuade"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/deter"],
    },
  ],
  crumb: [
    {
      word: "crumb",
      phonetic: "/kɹʌm/",
      phonetics: [
        {
          text: "/kɹʌm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/crumb-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769362",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small piece which breaks off from baked food (such as cake, biscuit or bread).",
              synonyms: [],
              antonyms: [],
              example:
                "The pigeons were happily pecking at crumbs of bread on the ground.",
            },
            {
              definition: "A small piece of other material, such as rubber.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A bit, small amount.",
              synonyms: [],
              antonyms: [],
              example: "a crumb of comfort",
            },
            {
              definition:
                "The soft internal portion of bread, surrounded by crust.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A mixture of sugar, cocoa and milk, used to make industrial chocolate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A nobody; a worthless person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A body louse (Pediculus humanus).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["crumbling"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To cover with crumbs.", synonyms: [], antonyms: [] },
            {
              definition:
                "To break into crumbs or small pieces with the fingers; to crumble.",
              synonyms: [],
              antonyms: [],
              example: "to crumb bread",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/crumb"],
    },
  ],
  astir: [
    {
      word: "astir",
      phonetic: "/əˈstɜː(ɹ)/",
      phonetics: [{ text: "/əˈstɜː(ɹ)/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "In motion; characterized by motion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Out of bed; up and about.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/astir"],
    },
  ],
  spire: [
    {
      word: "spire",
      phonetic: "/spaɪə/",
      phonetics: [
        { text: "/spaɪə/", audio: "" },
        {
          text: "/spaɪɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spire-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50423067",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The stalk or stem of a plant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A young shoot of a plant; a spear.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various tall grasses, rushes, or sedges, such as the marram, the reed canary-grass, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sharp or tapering point.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A tapering structure built on a roof or tower, especially as one of the central architectural features of a church or cathedral roof.",
              synonyms: [],
              antonyms: [],
              example: "The spire of the church rose high above the town.",
            },
            {
              definition:
                "The top, or uppermost point, of anything; the summit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A tube or fuse for communicating fire to the charge in blasting.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(of a seed, plant etc.) to sprout, to send forth the early shoots of growth; to germinate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To grow upwards rather than develop horizontally.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To furnish with a spire.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spire"],
    },
    {
      word: "spire",
      phonetic: "/spaɪə/",
      phonetics: [
        { text: "/spaɪə/", audio: "" },
        {
          text: "/spaɪɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spire-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50423067",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To breathe.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spire"],
    },
    {
      word: "spire",
      phonetic: "/spaɪə/",
      phonetics: [
        { text: "/spaɪə/", audio: "" },
        {
          text: "/spaɪɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spire-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50423067",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One of the sinuous foldings of a serpent or other reptile; a coil.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A spiral.", synonyms: [], antonyms: [] },
            {
              definition:
                "The part of a spiral generated in one revolution of the straight line about the pole.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spire"],
    },
  ],
  jumpy: [
    {
      word: "jumpy",
      phonetic: "/ˈdʒʌmpi/",
      phonetics: [
        {
          text: "/ˈdʒʌmpi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/jumpy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769453",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Nervous and excited.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/jumpy"],
    },
  ],
  extol: [
    {
      word: "extol",
      phonetic: "/ɪkˈstəʊl/",
      phonetics: [
        {
          text: "/ɪkˈstəʊl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/extol-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=95442336",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ɪkˈstoʊl/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To praise; to make high.",
              synonyms: ["belaud", "flatter"],
              antonyms: [],
            },
          ],
          synonyms: ["belaud", "flatter"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/extol"],
    },
  ],
  buoys: [
    {
      word: "buoys",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A float moored in water to mark a location, warn of danger, or indicate a navigational channel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A life-buoy; a life preserver.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To keep afloat or aloft; used with up.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To support or maintain at a high level.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To mark with a buoy.",
              synonyms: [],
              antonyms: [],
              example: "to buoy an anchor; to buoy or buoy off a channel",
            },
            {
              definition:
                "To maintain or enhance enthusiasm or confidence; to lift the spirits of.",
              synonyms: [],
              antonyms: [],
              example:
                "Buoyed by the huge success, they announced two other projects.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/buoy",
        "https://en.wiktionary.org/wiki/buoys",
      ],
    },
  ],
  stubs: [
    {
      word: "stubs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Something blunted, stunted, or cut short, such as stubble or a stump.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of certain paper items, designed to be torn off and kept for record or identification purposes.",
              synonyms: [],
              antonyms: [],
              example: "check stub, ticket stub, payment stub",
            },
            {
              definition:
                "A placeholder procedure that has the signature of the planned procedure but does not yet implement the intended behavior.",
              synonyms: [],
              antonyms: [],
              example:
                "http//books.google.com/books?vid=ISBN8120324455&id=K6BloOqIssQC&pg=RA29-PA8-IA9&lpg=RA29-PA8-IA9&dq=stub+procedure+-remote&sig=_Bm9HlXBRIsDwwCy0tqUOcXomL4, http//books.google.com/books?vid=ISBN185233570X&id=t4ZkqmbLHMMC&pg=PA53&lpg=PA53&dq=stub+procedure+-remote&sig=SZtMm8JhyE9HUVlKbp-U_TG2-hY, http//books.google.com/books?vid=ISBN0763707929&id=X_VlpfGoQRgC&pg=PA352&lpg=PA352&dq=stub+procedure+-remote&sig=oppYeiiRBcoPAkpkxZcbpcyaXIA",
            },
            {
              definition:
                "A procedure that translates requests from external systems into a format suitable for processing and then submits those requests for processing.",
              synonyms: [],
              antonyms: [],
              example:
                "http//books.google.com/books?vid=ISBN0387238395&id=_pYyEgj0fX8C&pg=PA152&lpg=PA152&dq=stub+procedure&sig=1xdBGyhc6WYeJtLNWrzzGF0jRXo, http//books.google.com/books?vid=ISBN3540419454&id=mH4MFwHDRB4C&pg=PA716&lpg=PA716&dq=stub+procedure&sig=r3IGw__iPlskg9HCllA6I4lqX-M, http//books.google.com/books?vid=ISBN0849312728&id=Gc886KgsdcsC&pg=PA7&lpg=PA7&dq=stub+procedure&sig=x-txczr_KTmgepfZBsxPHy7Vncw",
            },
            {
              definition:
                "(wikis) A page providing only minimal information and intended for later development.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The remaining part of the docked tail of a dog",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An unequal first or last interest calculation period, as a part of a financial swap contract",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A log or block of wood.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A blockhead.", synonyms: [], antonyms: [] },
            {
              definition: "A pen with a short, blunt nib.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An old and worn horseshoe nail.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Stub iron.", synonyms: [], antonyms: [] },
            {
              definition:
                "The smallest remainder of a smoked cigarette; a butt.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["skeleton"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To remove most of a tree, bush, or other rooted plant by cutting it close to the ground.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To remove a plant by pulling it out by the roots.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To jam, hit, or bump, especially a toe.",
              synonyms: [],
              antonyms: [],
              example:
                "I stubbed my toe trying to find the light switch in the dark.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/stub",
        "https://en.wiktionary.org/wiki/stubs",
      ],
    },
  ],
  lucid: [
    {
      word: "lucid",
      phonetic: "/ˈl(j)uːsɪd/",
      phonetics: [{ text: "/ˈl(j)uːsɪd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A lucid dream.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Clear; easily understood",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Mentally rational; sane",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Bright, luminous, translucent or transparent",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "brilliant",
            "light",
            "clear",
            "perspicuous",
            "straightforward",
            "glowing",
            "radiant",
            "coherent",
            "sane",
            "clear",
            "pellucid",
            "see-through",
            "transparent",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lucid"],
    },
  ],
  thong: [
    {
      word: "thong",
      phonetic: "/θɒŋ/",
      phonetics: [
        {
          text: "/θɒŋ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/thong-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75701734",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A strip of leather.", synonyms: [], antonyms: [] },
            {
              definition:
                "(usually in the plural) An item of footwear, usually of rubber, secured by two straps which join to pass between the big toe and its neighbour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An undergarment or swimwear consisting of very narrow strips designed to cover just the genitals and nothing more.",
              synonyms: [],
              antonyms: [],
              example:
                "No! I won't buy you a thong. You're too young for that.",
            },
            {
              definition:
                "The largest section of a bullwhip constructed of many straps of braided leather.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["flip-flop", "G-string", "butt floss"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/thong"],
    },
  ],
  afore: [
    {
      word: "afore",
      phonetic: "/əˈfɔː/",
      phonetics: [
        { text: "/əˈfɔː/", audio: "" },
        {
          text: "/əˈfɔɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/afore-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2650622",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            { definition: "Before.", synonyms: [], antonyms: [] },
            {
              definition: "In the fore part of a ship.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "preposition",
          definitions: [
            {
              definition: "Before; in advance of the time of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Before; situated geographically or metaphorically in front of.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "conjunction",
          definitions: [
            {
              definition: "In advance of the time when; before.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/afore"],
    },
  ],
  whiff: [
    {
      word: "whiff",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/whiff-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241997",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A waft; a brief, gentle breeze; a light gust of air",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An odour carried briefly through the air",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A short inhalation or exhalation of breath, especially of smoke from a cigarette or pipe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A slight sign of something; a glimpse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A strike (from the batter’s perspective)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An attempted shot that completely misses the ball.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The megrim, a fish: Lepidorhombus boscii or Lepidorhombus whiffiagonis.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["sniff", "puff", "waft"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To waft.", synonyms: [], antonyms: [] },
            { definition: "To sniff.", synonyms: [], antonyms: [] },
            { definition: "To strike out.", synonyms: [], antonyms: [] },
            {
              definition: "To miss the ball completely.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To attempt to strike and miss, especially being off-balance/vulnerable after missing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To throw out in whiffs; to consume in whiffs; to puff.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To carry or convey by a whiff, or as by a whiff; to puff or blow away.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To have or give off a strong, unpleasant smell.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fail spectacularly at a task.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having a strong or unpleasant odor.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/whiff"],
    },
    {
      word: "whiff",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/whiff-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241997",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To fish with a handline.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/whiff"],
    },
  ],
  maxim: [
    {
      word: "maxim",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/maxim-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75833150",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈmæk.sɪm/", audio: "" },
        {
          text: "/ˈmæk.səm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/maxim-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=8407008",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A self-evident axiom or premise; a pithy expression of a general principle or rule.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A precept; a succinct statement or observation of a rule of conduct or moral teaching.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["aphorism", "cliche", "enthymeme", "proverb", "saying"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/maxim"],
    },
  ],
  hulls: [
    {
      word: "hulls",
      phonetic: "/hʌlz/",
      phonetics: [{ text: "/hʌlz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The outer covering of a fruit or seed.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Any covering.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["husk", "peel", "shell"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To remove the outer covering of a fruit or seed.",
              synonyms: [],
              antonyms: [],
              example: "She sat on the back porch hulling peanuts.",
            },
          ],
          synonyms: ["husk", "peel", "shell", "shuck"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The body or frame of a vessel, such as a ship or plane.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a set A) The smallest set that possesses a particular property (such as convexity) and contains every point of A; slightly more formally, the intersection of all sets which possess the specified property and of which A is a subset.",
              synonyms: [],
              antonyms: [],
              example:
                "The orthogonal convex hull of an orthogonal polygon is the smallest orthogonally convex polygon that encloses the original polygon.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To drift; to be carried by the impetus of wind or water on the ship's hull alone, with sails furled.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To hit (a ship) in the hull with cannon fire etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["span"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hull",
        "https://en.wiktionary.org/wiki/hulls",
      ],
    },
  ],
  clogs: [
    {
      word: "clogs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A type of shoe with an inflexible, often wooden sole sometimes with an open heel.",
              synonyms: [],
              antonyms: [],
              example: "Dutch people rarely wear clogs these days.",
            },
            {
              definition: "A blockage.",
              synonyms: [],
              antonyms: [],
              example: "The plumber cleared the clog from the drain.",
            },
            { definition: "A shoe of any type.", synonyms: [], antonyms: [] },
            {
              definition:
                "A weight, such as a log or block of wood, attached to a person or animal to hinder motion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That which hinders or impedes motion; an encumbrance, restraint, or impediment of any kind.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To block or slow passage through (often with 'up').",
              synonyms: [],
              antonyms: [],
              example: "Hair is clogging the drainpipe.",
            },
            {
              definition:
                "To encumber or load, especially with something that impedes motion; to hamper.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To burden; to trammel; to embarrass; to perplex.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To enforce a mortgage lender right that prevents a borrower from exercising a right to redeem.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To perform a clog dance.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/clog",
        "https://en.wiktionary.org/wiki/clogs",
      ],
    },
  ],
  slats: [
    {
      word: "slats",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A thin, narrow strip or bar of wood (lath) or metal.",
              synonyms: [],
              antonyms: [],
              example: "slats of a window blind",
            },
            {
              definition:
                "(aeronautical) A movable control surface at the leading edge of a wing that when moved, changes the chord line of the airfoil, affecting the angle of attack. Employed in conjunction with flaps to allow for a lower stall speed in the landing attitude, facilitating slow flight.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A ski.", synonyms: [], antonyms: [] },
            {
              definition: "A thin piece of stone; a slate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To construct or provide with slats.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To slap; to strike; to beat; to throw down violently.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To split; to crack.", synonyms: [], antonyms: [] },
            { definition: "To set on; to incite.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/slat",
        "https://en.wiktionary.org/wiki/slats",
      ],
    },
  ],
  jiffy: [
    {
      word: "jiffy",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/jiffy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75803906",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈd͡ʒɪ.fi/", audio: "" },
        { text: "/ˈd͡ʒɪ.fi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A very short, unspecified length of time.",
              synonyms: [],
              antonyms: [],
              example: "I’ll be back in a jiffy.",
            },
            {
              definition:
                "A unit of time defined by the frequency of its basic timer – historically, and by convention, 0.01 of a second, but some computer operating systems use other values.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The length of an alternating current power cycle (1/60 or 1/50 of a second).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The time taken for light to travel a specified distance in a vacuum, usually one centimetre, but sometimes one foot or the width of a nucleon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Short for jiffy bag, a padded envelope.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["jif", "jiff"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/jiffy"],
    },
  ],
  arbor: [
    {
      word: "arbor",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/arbor-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762863",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A shady sitting place or pergola usually in a park or garden, surrounded by climbing shrubs, vines or other vegetation.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A grove of trees.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/arbor"],
    },
    {
      word: "arbor",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/arbor-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762863",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An axis or shaft supporting a rotating part on a lathe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A bar for supporting cutting tools.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A spindle of a wheel.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/arbor"],
    },
  ],
  cinch: [
    {
      word: "cinch",
      phonetic: "/sɪntʃ/",
      phonetics: [
        {
          text: "/sɪntʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cinch-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80536192",
        },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cinch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9825570",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A simple saddle girth used in Mexico.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Something that is very easy to do.",
              synonyms: [],
              antonyms: [],
              example: "No problem. It's a cinch.",
            },
            { definition: "A firm hold.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "breeze",
            "cakewalk",
            "doddle",
            "piece of cake",
            "walk in the park",
            "walkover",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To bring to certain conclusion.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To tighten down.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cinch"],
    },
    {
      word: "cinch",
      phonetic: "/sɪntʃ/",
      phonetics: [
        {
          text: "/sɪntʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cinch-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80536192",
        },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cinch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9825570",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                'A variety of auction pitch in which a draw to improve the hand is added, and the five of trumps (called "right Pedro") and the five of the same colour (called "left Pedro", and ranking between the five and the four of trumps) are each worth five. Fifty-one points make a game.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["double Pedro", "high five"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "In the game of cinch, to protect (a trick) by playing a higher trump than the five.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cinch"],
    },
  ],
  igloo: [
    {
      word: "igloo",
      phonetic: "/ˈɪɡluː/",
      phonetics: [{ text: "/ˈɪɡluː/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A dome-shaped Inuit shelter, constructed of blocks cut from snow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cavity, or excavation, made in the snow by a seal, over its breathing hole in the sea ice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A reinforced bunker for the storage of nuclear weapons.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(logistics) A kind of airfreight cargo container.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/igloo"],
    },
  ],
  goody: [
    {
      word: "goody",
      phonetic: "/ˈɡʊdi/",
      phonetics: [{ text: "/ˈɡʊdi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A good character in a story, often a hero.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["baddie"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A small amount of something good to eat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any small, usually free, item.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Pudding made by boiling bread in milk with sugar and spices.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Goodwife, a 17th-century puritan honorific.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An American fish, the lafayette or spot.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Mawkishly good; weakly benevolent or pious.",
              synonyms: ["goody"],
              antonyms: [],
            },
          ],
          synonyms: ["goody"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/goodie",
        "https://en.wiktionary.org/wiki/goody",
        "https://en.wiktionary.org/wiki/goody-goody",
      ],
    },
  ],
  gazes: [
    {
      word: "gazes",
      phonetic: "/ˈɡeɪ.zəz/",
      phonetics: [{ text: "/ˈɡeɪ.zəz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A fixed look; a look of eagerness, wonder, or admiration; a continued look of attention.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The object gazed on.", synonyms: [], antonyms: [] },
            {
              definition:
                "In Lacanian psychoanalysis, the relationship of the subject with the desire to look and awareness that one can be viewed.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To stare intently or earnestly.",
              synonyms: [],
              antonyms: [],
              example:
                "In fact, for Antonioni this gazing is probably the most fundamental of all cognitive activities ... (from Thinking in the Absence of Image)",
            },
            { definition: "To stare at.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["gape", "look", "stare"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gaze",
        "https://en.wiktionary.org/wiki/gazes",
      ],
    },
  ],
  dowel: [
    {
      word: "dowel",
      phonetic: "/ˈdaʊəl/",
      phonetics: [{ text: "/ˈdaʊəl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A pin, or block, of wood or metal, fitting into holes in the abutting portions of two pieces, and being partly in one piece and partly in the other, to keep them in their proper relative position.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A wooden rod, as one to make short pins from.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of wood or similar material fitted into a surface not suitable for fastening so that other pieces may be fastened to it.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To fasten together with dowels.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To furnish with dowels.",
              synonyms: [],
              antonyms: [],
              example: "A cooper dowels pieces for the head of a cask.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dowel"],
    },
  ],
  calms: [
    {
      word: "calms",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(in a person) The state of being calm; peacefulness; absence of worry, anger, fear or other strong negative emotion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in a place or situation) The state of being calm; absence of noise and disturbance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A period of time without wind.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make calm.",
              synonyms: [],
              antonyms: [],
              example: "to calm a crying baby",
            },
            { definition: "To become calm.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "allay",
            "appease",
            "calm down",
            "cool off",
            "ease",
            "pacify",
            "quieten",
            "soothe",
            "subdue",
          ],
          antonyms: ["agitate", "excite"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/calm",
        "https://en.wiktionary.org/wiki/calms",
      ],
    },
  ],
  scowl: [
    {
      word: "scowl",
      phonetic: "/skaʊl/",
      phonetics: [
        {
          text: "/skaʊl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scowl-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1273940",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The wrinkling of the brows or face in frowning; the expression of displeasure, sullenness, or discontent in the countenance; an angry frown.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) Gloom; dark or threatening aspect.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To wrinkle the brows, as in frowning or displeasure; to put on a frowning look; to look sour, sullen, severe, or angry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) To look gloomy, dark, or threatening; to lower.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To look at or repel with a scowl or a frown.",
              synonyms: [],
              antonyms: [],
              example: "to scowl a rival into submission",
            },
            {
              definition: "To express by a scowl.",
              synonyms: [],
              antonyms: [],
              example: "to scowl defiance",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scowl"],
    },
    {
      word: "scowl",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Old workings of iron ore.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scowl"],
    },
  ],
  gulps: [
    {
      word: "gulps",
      phonetic: "/ɡʌlps/",
      phonetics: [{ text: "/ɡʌlps/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The usual amount swallowed.",
              synonyms: ["slug"],
              antonyms: [],
            },
            {
              definition: "The sound of swallowing, sometimes indicating fear.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An unspecified small number of bytes, often two.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["slug"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To swallow eagerly, or in large draughts; to swallow up; to take down in one swallow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To react nervously by swallowing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gulp",
        "https://en.wiktionary.org/wiki/gulps",
      ],
    },
  ],
  coded: [
    {
      word: "coded",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To write software programs.",
              synonyms: [],
              antonyms: [],
              example:
                "I learned to code on an early home computer in the 1980s.",
            },
            {
              definition: "To add codes to a dataset.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To categorise by assigning identifiers from a schedule, for example CPT coding for medical insurance purposes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To encode.",
              synonyms: [],
              antonyms: [],
              example: "We should code the messages we send out on Usenet.",
            },
            { definition: "To encode a protein.", synonyms: [], antonyms: [] },
            {
              definition: "To call a hospital emergency code.",
              synonyms: [],
              antonyms: [],
              example: "coding in the CT scanner",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "Of a patient, to suffer a sudden medical emergency (a code blue) such as cardiac arrest.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Encoded; written in code or cipher.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/code",
        "https://en.wiktionary.org/wiki/coded",
      ],
    },
  ],
  waver: [
    {
      word: "waver",
      phonetic: "/ˈweɪ.və(ɹ)/",
      phonetics: [
        {
          text: "/ˈweɪ.və(ɹ)/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/waver-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89012585",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈweɪ.vɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An act of wavering, vacillating, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Someone who waves, enjoys waving, etc.",
              synonyms: [],
              antonyms: [],
              example:
                "I felt encouraged by all the enthusiastic wavers in the crowd.",
            },
            {
              definition: "Someone who specializes in waving (hair treatment).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A tool that accomplishes hair waving.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sapling left standing in a fallen wood.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To sway back and forth; to totter or reel.",
              synonyms: [],
              antonyms: [],
              example: "Flowers wavered in the breeze.",
            },
            {
              definition: "To flicker, glimmer, quiver, as a weak light.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To fluctuate or vary, as commodity prices or a poorly sustained musical pitch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To shake or tremble, as the hands or voice.",
              synonyms: [],
              antonyms: [],
              example:
                "His voice wavered when the reporter brought up the controversial topic.",
            },
            {
              definition:
                "To falter; become unsteady; begin to fail or give way.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be indecisive between choices; to feel or show doubt or indecision; to vacillate.",
              synonyms: [],
              antonyms: [],
              example:
                "Despite all the terrible things that happened to her, she never wavered from her beliefs.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/waver"],
    },
  ],
  mason: [
    {
      word: "mason",
      phonetic: "/ˈmeɪsən/",
      phonetics: [
        {
          text: "/ˈmeɪsən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mason-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651162",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A bricklayer, one whose occupation is to build with stone or brick",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One who prepares stone for building purposes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A member of the fraternity of Freemasons. See Freemason.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(normally with a preposition) To build stonework or brickwork about, under, in, over, etc.; to construct by masons",
              synonyms: [],
              antonyms: [],
              example: "to mason in a kettle or boiler",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mason"],
    },
  ],
  lobes: [
    {
      word: "lobes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any projection or division, especially one of a somewhat rounded form.",
              synonyms: [],
              antonyms: [],
              example:
                "A lobe of lava was crawling down the side of the volcano.",
            },
            {
              definition:
                "A clear division of an organ that can be determined at the gross anatomy level, especially one of the parts of the brain, liver or lung.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A semicircular pattern left on the ice as the skater travels across it.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lobe",
        "https://en.wiktionary.org/wiki/lobes",
      ],
    },
  ],
  ebony: [
    {
      word: "ebony",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ebony-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75730449",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈɛb.ən.i/", audio: "" },
        { text: "/ˈɛb.ən.i/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A hard, dense, deep black wood from various subtropical and tropical trees, especially of the genus Diospyros.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A tree that yields such wood.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A deep, dark black colour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A black key on a piano or other keyboard instrument.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Made of ebony wood.", synonyms: [], antonyms: [] },
            {
              definition: "A deep, dark black colour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Dark-skinned; black; especially in reference to African-Americans.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ebony"],
    },
  ],
  flail: [
    {
      word: "flail",
      phonetic: "/fleɪl/",
      phonetics: [
        {
          text: "/fleɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/flail-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75796829",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/fleɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/flail-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1505899",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A tool used for threshing, consisting of a long handle with a shorter stick attached with a short piece of chain, thong or similar material.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A weapon which has the (usually spherical) striking part attached to the handle with a flexible joint such as a chain.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["thrashel", "threshel"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To beat using a flail or similar implement.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To wave or swing vigorously",
              synonyms: ["thrash"],
              antonyms: [],
            },
            { definition: "To thresh.", synonyms: [], antonyms: [] },
            {
              definition: "To move like a flail.",
              synonyms: [],
              antonyms: [],
              example: "He was flailing wildly, but didn't land a blow.",
            },
          ],
          synonyms: ["thrash"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/flail"],
    },
  ],
  isles: [
    {
      word: "isles",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A wing of a building, notably in a church separated from the nave proper by piers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A clear path through rows of seating.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A clear corridor in a supermarket with shelves on both sides containing goods for sale.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any path through an otherwise obstructed space.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Seat in public transport, such as a plane, train or bus, that's beside the aisle.",
              synonyms: [],
              antonyms: [],
              example: "Do you want to seat window or aisle?",
            },
            {
              definition:
                "An idiomatic divide between the Republican Party and the Democratic Party, who are said to be on two sides of the aisle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["isle", "aisle seat"],
          antonyms: ["window", "window seat"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A (small) island, compare with islet.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/aisle",
        "https://en.wiktionary.org/wiki/isle",
        "https://en.wiktionary.org/wiki/isles",
      ],
    },
  ],
  clods: [
    {
      word: "clods",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A lump of something, especially of earth or clay.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The ground; the earth; a spot of earth or turf.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A stupid person; a dolt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Part of a shoulder of beef, or of the neck piece near the shoulder.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/clod",
        "https://en.wiktionary.org/wiki/clods",
      ],
    },
  ],
  dazed: [
    {
      word: "dazed",
      phonetic: "/deɪzd/",
      phonetics: [
        {
          text: "/deɪzd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dazed-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=61116335",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To stun or stupefy, for example with bright light, with a blow, with cold, or with fear",
              synonyms: ["benumb", "confuse"],
              antonyms: [],
            },
          ],
          synonyms: ["benumb", "confuse"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "In a state of shock or confusion.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Stunned.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/daze",
        "https://en.wiktionary.org/wiki/dazed",
      ],
    },
  ],
  adept: [
    {
      word: "adept",
      phonetic: "/əˈdɛpt/",
      phonetics: [
        { text: "/əˈdɛpt/", audio: "" },
        { text: "/ædˈɛpt/", audio: "" },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/adept-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=7465669",
        },
        { text: "/əˈdɛpt/", audio: "" },
        { text: "/ædˈɛpt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One fully skilled or well versed in anything; a proficient",
              synonyms: [],
              antonyms: [],
              example: "adepts in philosophy",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Well skilled; completely versed; thoroughly proficient",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["inept"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/adept"],
    },
  ],
  oozed: [
    {
      word: "oozed",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To be secreted or slowly leak.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To give off a strong sense of (something); to exude.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ooze",
        "https://en.wiktionary.org/wiki/oozed",
      ],
    },
  ],
  sedan: [
    {
      word: "sedan",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sedan-1-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75649670",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/səˈdæn/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An enclosed windowed chair suitable for a single occupant, carried by at least two porters, in equal numbers in front and behind, using wooden rails that passed through metal brackets on the sides of the chair.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An automobile designed in a configuration with separate compartments for engine space, driver/passenger space and luggage space.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["saloon", "litter", "sedan chair"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sedan"],
    },
    {
      word: "sedan",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A handbarrow for transporting fish.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sedan"],
    },
  ],
  clays: [
    {
      word: "clays",
      phonetic: "/kleɪz/",
      phonetics: [{ text: "/kleɪz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A mineral substance made up of small crystals of silica and alumina, that is ductile when moist; the material of pre-fired ceramics.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An earth material with ductile qualities.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A tennis court surface made of crushed stone, brick, shale, or other unbound mineral aggregate.",
              synonyms: [],
              antonyms: [],
              example: "The French Open is played on clay.",
            },
            {
              definition: "The material of the human body.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A particle less than 3.9 microns in diameter, following the Wentworth scale.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A clay pipe for smoking tobacco.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A clay pigeon.",
              synonyms: [],
              antonyms: [],
              example: "We went shooting clays at the weekend.",
            },
            {
              definition:
                "Land or territory of a country or other political region, especially when subject to territorial claims",
              synonyms: [],
              antonyms: [],
              example: "Danzig is rightfully German clay.",
            },
          ],
          synonyms: [],
          antonyms: ["soul", "spirit"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To add clay to, to spread clay onto.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of sugar) To purify using clay.",
              synonyms: [],
              antonyms: [],
              example:
                "1809, Jonathan Williams, On the Process of Claying Sugar, in Transactions of the American Philosophical Society, Volume 6.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/clay",
        "https://en.wiktionary.org/wiki/clays",
      ],
    },
  ],
  warts: [
    {
      word: "warts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A type of deformed growth occurring on the skin caused by the human papillomavirus (HPV).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any similar growth occurring in plants or animals, such as the parotoid glands in the back of toads.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any of the prefixes used in Hungarian notation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wart",
        "https://en.wiktionary.org/wiki/warts",
      ],
    },
  ],
  ketch: [
    {
      word: "ketch",
      phonetic: "/kɛtʃ/",
      phonetics: [{ text: "/kɛtʃ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A fore-and-aft rigged sailing vessel with two masts, main and mizzen, the mizzen being stepped forward of the rudder post.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ketch"],
    },
    {
      word: "ketch",
      phonetic: "/kɛtʃ/",
      phonetics: [{ text: "/kɛtʃ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(heading) To capture, overtake.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(heading) To seize hold of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(heading) To intercept.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(heading) To receive (by being in the way).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(heading) To take in with one's senses or intellect.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(heading) To seize attention, interest.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(heading) To obtain or experience",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "get",
            "take",
            "capture",
            "hook",
            "snare",
            "take",
            "fang",
            "grab",
            "snatch",
          ],
          antonyms: ["drop", "release"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/catch",
        "https://en.wiktionary.org/wiki/ketch",
      ],
    },
    {
      word: "ketch",
      phonetic: "/kɛtʃ/",
      phonetics: [{ text: "/kɛtʃ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A hangman.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [{ definition: "To hang.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ketch"],
    },
  ],
  skunk: [
    {
      word: "skunk",
      phonetic: "/skʌŋk/",
      phonetics: [
        {
          text: "/skʌŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/skunk-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79218878",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of various small mammals, of the family Mephitidae, native to North and Central America, having a glossy black with a white coat and two musk glands at the base of the tail for emitting a noxious smell as a defensive measure.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A despicable person.", synonyms: [], antonyms: [] },
            {
              definition:
                "A walkover victory in sports or board games, as when the opposing side is unable to score. Compare shutout.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A win by 30 or more points.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To defeat so badly as to prevent any opposing points.",
              synonyms: [],
              antonyms: [],
              example: "I skunked him at cards.",
            },
            {
              definition: "To win by 30 or more points.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of beer) To go bad, to spoil.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/skunk"],
    },
    {
      word: "skunk",
      phonetic: "/skʌŋk/",
      phonetics: [
        {
          text: "/skʌŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/skunk-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79218878",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A member of a hybrid skinhead and punk subculture.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/skunk"],
    },
    {
      word: "skunk",
      phonetic: "/skʌŋk/",
      phonetics: [
        {
          text: "/skʌŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/skunk-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79218878",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of the strains of hybrids of Cannabis sativa and Cannabis indica that may have THC levels exceeding those of typical hashish.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of several American plants that have an offensive odour, but especially Croton texensis and Polemonium viscosum",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Strong-smelling cannabis",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/skunk",
        "https://en.wiktionary.org/wiki/skunkweed",
      ],
    },
  ],
  manes: [
    {
      word: "manes",
      phonetic: "/ˈmɑːneɪz/",
      phonetics: [
        {
          text: "/ˈmɑːneɪz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/manes-1-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88876232",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The souls or spirits of dead ancestors, conceived as deities or the subjects of reverence, or of other deceased relatives.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/manes"],
    },
    {
      word: "manes",
      phonetic: "/meɪnz/",
      phonetics: [
        {
          text: "/meɪnz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/manes-2-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88876233",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Longer hair growth on back of neck of an animal, especially a horse or lion",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Long or thick hair of a person's head.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mane",
        "https://en.wiktionary.org/wiki/manes",
      ],
    },
  ],
  adore: [
    {
      word: "adore",
      phonetic: "/əˈdɔː/",
      phonetics: [
        { text: "/əˈdɔː/", audio: "" },
        {
          text: "/əˈdɔɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/adore-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=275631",
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To worship.", synonyms: [], antonyms: ["disdain"] },
            {
              definition:
                "To love with one's entire heart and soul; regard with deep respect and affection.",
              synonyms: [],
              antonyms: ["disdain"],
              example: "It is obvious to everyone that Gerry adores Heather.",
            },
            { definition: "To be very fond of.", synonyms: [], antonyms: [] },
            { definition: "To adorn.", synonyms: [], antonyms: ["disdain"] },
          ],
          synonyms: [],
          antonyms: ["disdain", "disdain", "disdain"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/adore"],
    },
  ],
  sneer: [
    {
      word: "sneer",
      phonetic: "/snɪə̯/",
      phonetics: [
        {
          text: "/snɪə̯/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sneer-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80067031",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/snɪə̯ɹ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A facial expression where one slightly raises one corner of the upper lip, generally indicating scorn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A display of contempt; scorn.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To raise a corner of the upper lip slightly, especially in scorn",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To utter with a grimace or contemptuous expression; to say sneeringly.",
              synonyms: [],
              antonyms: [],
              example:
                '"Now here\'s someone who should attend privilege workshops," sneered she.',
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sneer"],
    },
  ],
  mango: [
    {
      word: "mango",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mango-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75833032",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈmæŋɡəʊ/", audio: "" },
        {
          text: "/ˈmæŋɡoʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mango-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50422646",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A tropical Asian fruit tree, Mangifera indica.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The fruit of the mango tree.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A pickled vegetable or fruit with a spicy stuffing; a vegetable or fruit which has been mangoed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(chiefly southern Midwest US) A green bell pepper suitable for pickling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A type of muskmelon, Cucumis melo.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various hummingbirds of the genus Anthracothorax.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A yellow-orange color, like that of mango flesh.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To stuff and pickle (a fruit).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mango"],
    },
  ],
  fiord: [
    {
      word: "fiord",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A long, narrow, deep inlet between cliffs.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fiord",
        "https://en.wiktionary.org/wiki/fjord",
      ],
    },
  ],
  flora: [
    {
      word: "flora",
      phonetic: "/ˈflɔː.ɹə/",
      phonetics: [
        {
          text: "/ˈflɔː.ɹə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/flora-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2650960",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Plants considered as a group, especially those of a particular country, region, time, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A book describing the plants of a country, region, time, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The microorganisms that inhabit some part of the body",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["microflora"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/flora"],
    },
  ],
  roomy: [
    {
      word: "roomy",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A roommate.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Spacious, expansive, comfortable.",
              synonyms: [],
              antonyms: [],
              example:
                "Our new apartment is roomy enough to accommodate all our furniture.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/roomie",
        "https://en.wiktionary.org/wiki/roomy",
      ],
    },
  ],
  minks: [
    {
      word: "minks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(plural mink or minks) Any of various semi-aquatic, carnivorous mammals in the Mustelinae subfamily, similar to weasels, with dark fur, native to Europe and America, of which two species in different genera are extant: the American mink (Neovison vison) and the European mink (Mustela lutreola).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(plural mink) The fur or pelt of a mink, used to make apparel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(plural minks) An article of clothing made of mink.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(plural minks) An individual with poor personal hygiene; a smelly person.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mink",
        "https://en.wiktionary.org/wiki/minks",
      ],
    },
  ],
  thaws: [
    {
      word: "thaws",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The melting of ice, snow, or other congealed matter; the resolution of ice, or the like, into the state of a fluid; liquefaction by heat of anything congealed by frost",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A warmth of weather sufficient to melt that which is frozen",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To gradually melt, dissolve, or become fluid; to soften from frozen",
              synonyms: [],
              antonyms: [],
              example: "the ice thaws",
            },
            {
              definition:
                "To become so warm as to melt ice and snow — said in reference to the weather, and used impersonally.",
              synonyms: [],
              antonyms: [],
              example: "It's beginning to thaw.",
            },
            {
              definition: "To grow gentle or genial.",
              synonyms: [],
              antonyms: [],
              example: "Her anger has thawed.",
            },
            {
              definition:
                "To gradually cause frozen things (such as earth, snow, ice) to melt, soften, or dissolve.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/thaw",
        "https://en.wiktionary.org/wiki/thaws",
      ],
    },
  ],
  watts: [
    {
      word: "watts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "In the International System of Units, the derived unit of power; the power of a system in which one joule of energy is transferred per second. Symbol: W",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/watt",
        "https://en.wiktionary.org/wiki/watts",
      ],
    },
  ],
  freer: [
    {
      word: "freer",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "(social) Unconstrained.",
              synonyms: ["unconstrained", "unfettered", "unhindered"],
              antonyms: ["constrained", "restricted"],
              example: "He was given free rein to do whatever he wanted.",
            },
            {
              definition: "Obtainable without any payment.",
              synonyms: ["free of charge", "gratis"],
              antonyms: [],
              example: "It's free real estate.",
            },
            {
              definition: "(abstract) Unconstrained.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(physical) Unconstrained.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Without; not containing (what is specified); exempt; clear; liberated.",
              synonyms: ["without"],
              antonyms: [],
              example:
                "We had a wholesome, filling meal, free of meat.  I would like to live free from care in the mountains.",
            },
            {
              definition:
                "Ready; eager; acting without spurring or whipping; spirited.",
              synonyms: [],
              antonyms: [],
              example: "a free horse",
            },
            {
              definition:
                "Invested with a particular freedom or franchise; enjoying certain immunities or privileges; admitted to special rights; followed by of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Certain or honourable; the opposite of base.",
              synonyms: [],
              antonyms: [],
              example: "free service;  free socage",
            },
            {
              definition: "Privileged or individual; the opposite of common.",
              synonyms: [],
              antonyms: [],
              example: "a free fishery;  a free warren",
            },
          ],
          synonyms: [
            "free of charge",
            "gratis",
            "unconstrained",
            "unfettered",
            "unhindered",
            "without",
          ],
          antonyms: ["constrained", "restricted", "unfree"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "One who frees.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/free",
        "https://en.wiktionary.org/wiki/freer",
      ],
    },
  ],
  exult: [
    {
      word: "exult",
      phonetic: "/ɪɡˈzʌlt/",
      phonetics: [{ text: "/ɪɡˈzʌlt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To rejoice; to be very happy, especially in triumph.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/exult"],
    },
  ],
  plush: [
    {
      word: "plush",
      phonetic: "/plʌʃ/",
      phonetics: [
        {
          text: "/plʌʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/plush-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88676909",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A textile fabric with a nap or shag on one side, longer and softer than the nap of velvet.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A plush toy.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Very extravagant.", synonyms: [], antonyms: [] },
            {
              definition: "Very expensive, or appearing expensive.",
              synonyms: [],
              antonyms: [],
              example: "They lived in a plush apartment complex.",
            },
            {
              definition:
                "(of a man-made object) Having a soft, fluffy exterior.",
              synonyms: [],
              antonyms: [],
              example: "This plush toy is so cute and soft - I want it!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/plush"],
    },
  ],
  paled: [
    {
      word: "paled",
      phonetic: "/peɪld/",
      phonetics: [
        {
          text: "/peɪld/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/paled-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=94709313",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To turn pale; to lose colour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To become insignificant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make pale; to diminish the brightness of.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To enclose with pales, or as if with pales; to encircle or encompass; to fence off.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Striped.", synonyms: [], antonyms: [] },
            {
              definition: "Enclosed with a paling.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pale",
        "https://en.wiktionary.org/wiki/paled",
      ],
    },
  ],
  twain: [
    {
      word: "twain",
      phonetic: "/tweɪn/",
      phonetics: [
        {
          text: "/tweɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/twain-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453773",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Pair, couple", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [{ definition: "Twofold", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "numeral",
          definitions: [
            {
              definition: "Two",
              synonyms: [],
              antonyms: [],
              example:
                "Bring me these twain cups of wine and water, and let us drink from the one we feel more befitting of this day.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/twain"],
    },
    {
      word: "twain",
      phonetic: "/tweɪn/",
      phonetics: [
        {
          text: "/tweɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/twain-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453773",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To part in twain; divide; sunder.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/twain"],
    },
  ],
  clink: [
    {
      word: "clink",
      phonetic: "/klɪŋk/",
      phonetics: [
        {
          text: "/klɪŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/clink-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=474027",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The sound of metal on metal, or glass on glass.",
              synonyms: [],
              antonyms: [],
              example:
                "You could hear the clink of the glasses from the next room.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make a clinking sound; to make a sound of metal on metal or glass on glass; to strike materials such as metal or glass against one another.",
              synonyms: [],
              antonyms: [],
              example: "The hammers clinked on the stone all night.",
            },
            { definition: "To rhyme.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/clink"],
    },
    {
      word: "clink",
      phonetic: "/klɪŋk/",
      phonetics: [
        {
          text: "/klɪŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/clink-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=474027",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A prison.",
              synonyms: [],
              antonyms: [],
              example:
                "If he keeps doing things like that, he’s sure to end up in the clink.",
            },
            {
              definition:
                "Stress cracks produced in metal ingots as they cool after being cast.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/clink"],
    },
    {
      word: "clink",
      phonetic: "/klɪŋk/",
      phonetics: [
        {
          text: "/klɪŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/clink-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=474027",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To clinch; to rivet.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/clink"],
    },
  ],
  scamp: [
    {
      word: "scamp",
      phonetic: "/skamp/",
      phonetics: [{ text: "/skamp/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A rascal, swindler, or rogue; a ne'er-do-well.",
              synonyms: ["rogue", "swindler"],
              antonyms: [],
            },
            {
              definition:
                "A mischievous person, especially a playful, impish youngster.",
              synonyms: [],
              antonyms: [],
              example:
                "My nephew is a little scamp who likes to leave lighted firecrackers under the lawnchairs of his dozing elders.",
            },
          ],
          synonyms: ["rogue", "swindler"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scamp"],
    },
    {
      word: "scamp",
      phonetic: "/skamp/",
      phonetics: [{ text: "/skamp/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To skimp; to do something in a skimpy or slipshod fashion.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scamp"],
    },
    {
      word: "scamp",
      phonetic: "/skamp/",
      phonetics: [{ text: "/skamp/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A preliminary design sketch.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scamp"],
    },
  ],
  pawed: [
    {
      word: "pawed",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(of an animal) To go through something (such as a garbage can) with paws.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of an animal) To gently push on something with a paw.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of an animal) To draw the forefoot along the ground; to beat or scrape with the forefoot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) To touch someone in a sexual way.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) To clumsily dig through something.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To flatter.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having (a specified kind or number of) paws.",
              synonyms: [],
              antonyms: [],
              example: "a muddy-pawed puppy",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/paw",
        "https://en.wiktionary.org/wiki/pawed",
      ],
    },
  ],
  grope: [
    {
      word: "grope",
      phonetic: "/ɡɹəʊp/",
      phonetics: [
        {
          text: "/ɡɹəʊp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grope-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89032945",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ɡɹoʊp/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An act of groping, especially sexually.",
              synonyms: [],
              antonyms: [],
              example:
                "the old man tried to get a quick grope of the young lady on the train.",
            },
            {
              definition: "An iron fitting of a medieval cart wheel",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To feel with or use the hands; to handle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To search or attempt to find something in the dark, or, as a blind person, by feeling; to move about hesitatingly, as in darkness or obscurity; to feel one's way, as with the hands, when one can not see.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To touch (another person) closely and (especially) sexually.",
              synonyms: [],
              antonyms: [],
              example:
                "We've been together two weeks, and have just been kissing and groping, but no sex yet.",
            },
            {
              definition:
                "To intentionally and inappropriately touch another person, in such a manner as to make the contact appear accidental, for the purpose of one's sexual gratification.",
              synonyms: [],
              antonyms: [],
              example: "That old man groped that girl on the train!",
            },
            {
              definition: "To examine; to test; to sound.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "touch",
            "fumble",
            "glaum",
            "root",
            "caress",
            "feel up",
            "touch up",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/grope"],
    },
  ],
  bravo: [
    {
      word: "bravo",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bravo-ca.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=46623065",
        },
        { text: "/bɹɑːˈvəʊ/", audio: "" },
        { text: "/bɹɑˈvoʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A hired soldier; an assassin; a desperado.",
              synonyms: [],
              antonyms: [],
            },
            { definition: 'A shout of "bravo!"', synonyms: [], antonyms: [] },
            {
              definition: "The letter B in the ICAO spelling alphabet.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cheer or applaud, especially by saying bravo!",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "Used to express acclaim, especially to a performer.",
              synonyms: [],
              antonyms: [],
              example: "Bravo, you have done a brilliant job!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bravo"],
    },
  ],
  gable: [
    {
      word: "gable",
      phonetic: "/ˈɡeɪ.bəl/",
      phonetics: [{ text: "/ˈɡeɪ.bəl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The triangular area at the peak of an external wall adjacent to, and terminating, two sloped roof surfaces (pitches).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gable"],
    },
    {
      word: "gable",
      phonetic: "/ˈɡeɪ.bəl/",
      phonetics: [{ text: "/ˈɡeɪ.bəl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "A cable.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gable"],
    },
  ],
  stink: [
    {
      word: "stink",
      phonetic: "/stɪŋk/",
      phonetics: [
        {
          text: "/stɪŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stink-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1174436",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A strong bad smell.", synonyms: [], antonyms: [] },
            {
              definition: "A complaint or objection.",
              synonyms: [],
              antonyms: [],
              example:
                "If you don't make a stink about the problem, nothing will be done.",
            },
          ],
          synonyms: [
            "fetor",
            "odor",
            "odour",
            "pong",
            "reek",
            "smell",
            "stench",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To have a strong bad smell.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be greatly inferior; to perform badly.",
              synonyms: [],
              antonyms: [],
              example: "That movie stinks. I didn't even stay for the end.",
            },
            {
              definition: "To give an impression of dishonesty or untruth.",
              synonyms: [],
              antonyms: [],
              example: "Something stinks about the politician's excuses.",
            },
            {
              definition: "To cause to stink; to affect by a stink.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["blow", "suck", "fishy", "pong", "reek"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Bad; inferior; worthless.",
              synonyms: [],
              antonyms: [],
              example: "The concert was stink. / That was a stink concert.",
            },
            { definition: "Bad-smelling, stinky.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stink"],
    },
  ],
  sever: [
    {
      word: "sever",
      phonetic: "/ˈsɛv.ɚ/",
      phonetics: [
        {
          text: "/ˈsɛv.ɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sever-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50423056",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cut free.",
              synonyms: [],
              antonyms: [],
              example:
                "After he graduated, he severed all links to his family.",
            },
            {
              definition: "To suffer disjunction; to be parted or separated.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make a separation or distinction; to distinguish.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To disunite; to disconnect; to terminate.",
              synonyms: [],
              antonyms: [],
              example: "to sever an estate in joint tenancy",
            },
          ],
          synonyms: ["becut", "cut off"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sever"],
    },
  ],
  waned: [
    {
      word: "waned",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To progressively lose its splendor, value, ardor, power, intensity etc.; to decline.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Said of light that dims or diminishes in strength.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Said of the Moon as it passes through the phases of its monthly cycle where its surface is less and less visible.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Said of a time period that comes to an end.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To decrease physically in size, amount, numbers or surface.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To cause to decrease.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: ["wax"],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Having wanes, i.e. rounded corners caused by lack of wood, often showing bark.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wane",
        "https://en.wiktionary.org/wiki/waned",
      ],
    },
  ],
  rarer: [
    {
      word: "rarer",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Very uncommon; scarce.",
              synonyms: [
                "geason",
                "scarce",
                "selcouth",
                "seld",
                "seldsome",
                "selly",
                "uncommon",
              ],
              antonyms: ["common", "frequent"],
              example:
                "Black pearls are very rare and therefore very valuable.",
            },
            {
              definition: "(of a gas) Thin; of low density.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Good; enjoyable.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "geason",
            "scarce",
            "selcouth",
            "seld",
            "seldsome",
            "selly",
            "uncommon",
          ],
          antonyms: ["common", "frequent"],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(particularly meats) Cooked very lightly, so the meat is still red (in the case of steak or beef in the general sense).",
              synonyms: [],
              antonyms: ["well done"],
            },
          ],
          synonyms: [],
          antonyms: ["well done"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rare",
        "https://en.wiktionary.org/wiki/rarer",
      ],
    },
  ],
  regal: [
    {
      word: "regal",
      phonetic: "/ˈɹiːɡəl/",
      phonetics: [
        {
          text: "/ˈɹiːɡəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/regal-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88987542",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of or relating to royalty.",
              synonyms: [],
              antonyms: [],
              example: "regal authority;   the regal title",
            },
            {
              definition: "Befitting a king, queen, emperor, or empress.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/regal"],
    },
    {
      word: "regal",
      phonetic: "/ˈɹiːɡəl/",
      phonetics: [
        {
          text: "/ˈɹiːɡəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/regal-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88987542",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small, portable organ whose sound is produced by beating reeds without amplifying resonators. Its tone is keen and rich in harmonics. The regal was common in the sixteenth and seventeenth centuries; today it has been revived for the performance of music from those times.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An organ stop of the reed family, furnished with a normal beating reed, but whose resonator is a fraction of its natural length. In the sixteenth and seventeenth centuries these stops took a multitude of forms. Today only one survives that is of universal currency, the so-called Vox Humana.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/regal"],
    },
  ],
  wards: [
    {
      word: "wards",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A warden; a guard; a guardian or watchman.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Protection, defence.", synonyms: [], antonyms: [] },
            {
              definition:
                "A protected place, and by extension, a type of subdivision.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person under guardianship.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An object used for guarding.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To keep in safety, to watch over, to guard.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To defend, to protect.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To fend off, to repel, to turn aside, as anything mischievous that approaches; -- usually followed by off.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be vigilant; to keep guard.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To act on the defensive with a weapon.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["ward off"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ward",
        "https://en.wiktionary.org/wiki/wards",
      ],
    },
  ],
  fawns: [
    {
      word: "fawns",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A young deer.", synonyms: [], antonyms: [] },
            {
              definition:
                "A pale brown colour tinted with yellow, like that of a fawn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The young of an animal; a whelp.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To give birth to a fawn.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A servile cringe or bow.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Base flattery.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To exhibit affection or attempt to please.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To seek favour by flattery and obsequious behaviour (with on or upon).",
              synonyms: ["grovel", "soft-soap", "toady", "wheedle"],
              antonyms: [],
            },
            {
              definition:
                "(of a dog) To show devotion or submissiveness by wagging its tail, nuzzling, licking, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["grovel", "soft-soap", "toady", "wheedle"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fawn",
        "https://en.wiktionary.org/wiki/fawns",
      ],
    },
  ],
  babes: [
    {
      word: "babes",
      phonetic: "/beɪbz/",
      phonetics: [
        {
          text: "/beɪbz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/babes-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=67350039",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A baby or infant; a very young human or animal.",
              synonyms: [],
              antonyms: [],
              example: "These events came to pass when he was but a babe.",
            },
            {
              definition: "An attractive person, especially a young woman.",
              synonyms: [],
              antonyms: [],
              example: "She's a real babe!",
            },
            {
              definition: "Darling (term of endearment).",
              synonyms: [],
              antonyms: [],
              example: "Hey, babe, how's about you and me getting together?",
            },
          ],
          synonyms: [
            "darling",
            "dear",
            "love",
            "sweetheart",
            "baby",
            "child",
            "infant",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/babe",
        "https://en.wiktionary.org/wiki/babes",
      ],
    },
  ],
  unify: [
    {
      word: "unify",
      phonetic: "/ˈjuːnɪfaɪ/",
      phonetics: [{ text: "/ˈjuːnɪfaɪ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "Cause to become one; make into a unit; consolidate; merge; combine.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Become one.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: ["divide"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/unify"],
    },
  ],
  amend: [
    {
      word: "amend",
      phonetic: "/əˈmɛnd/",
      phonetics: [
        { text: "/əˈmɛnd/", audio: "" },
        { text: "/əˈmɛnd/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(usually in the plural) An act of righting a wrong; compensation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make better; improve.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To become better.", synonyms: [], antonyms: [] },
            {
              definition: "To heal (someone sick); to cure (a disease etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be healed, to be cured, to recover (from an illness).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make a formal alteration (in legislation, a report, etc.) by adding, deleting, or rephrasing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["ameliorate", "correct", "improve"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/amend"],
    },
  ],
  oaken: [
    {
      word: "oaken",
      phonetic: "/əʊk.ən/",
      phonetics: [{ text: "/əʊk.ən/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Made from the wood of the oak tree. Also in metaphorical uses, suggesting robustness.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/oaken"],
    },
  ],
  glade: [
    {
      word: "glade",
      phonetic: "/ɡleɪd/",
      phonetics: [
        {
          text: "/ɡleɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/glade-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88681243",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An open passage through a wood; a grassy open or cleared space in a forest.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An everglade.", synonyms: [], antonyms: [] },
            {
              definition: "An open space in the ice on a river or lake.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A bright surface of ice or snow.",
              synonyms: [],
              antonyms: [],
              example: "a glade of ice",
            },
            { definition: "A gleam of light.", synonyms: [], antonyms: [] },
            {
              definition:
                "A bright patch of sky; the bright space between clouds.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["clearing"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/glade"],
    },
  ],
  visor: [
    {
      word: "visor",
      phonetic: "/ˈvaɪzɚ/",
      phonetics: [
        {
          text: "/ˈvaɪzɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/visor-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50279084",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A part of a helmet, arranged so as to lift or open, and so show the face. The openings for seeing and breathing are generally in it.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A mask for the face.", synonyms: [], antonyms: [] },
            {
              definition:
                "The fore piece of a cap, projecting over, and protecting the eyes.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/visor"],
    },
  ],
  hefty: [
    {
      word: "hefty",
      phonetic: "/ˈhɛfti/",
      phonetics: [
        {
          text: "/ˈhɛfti/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hefty-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=283184",
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Heavy, strong, vigorous, mighty, impressive.",
              synonyms: [],
              antonyms: [],
              example: "He can throw a hefty punch.",
            },
            {
              definition: "Strong; bulky.",
              synonyms: [],
              antonyms: [],
              example: "They use some hefty bolts to hold up road signs.",
            },
            {
              definition:
                "(of a person) Possessing physical strength and weight; rugged and powerful; powerfully or heavily built.",
              synonyms: [],
              antonyms: [],
              example: "He was a tall, hefty man.",
            },
            {
              definition: "Heavy, weighing a lot.",
              synonyms: [],
              antonyms: [],
              example: "She carries a hefty backpack full of books.",
            },
            {
              definition: "(of a number or amount) Large.",
              synonyms: [],
              antonyms: [],
              example: "That's going to cost you a hefty sum.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hefty"],
    },
  ],
  nines: [
    {
      word: "nines",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/nines-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=402884",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The digit or figure 9.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A playing card with nine pips.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A nine-millimeter semi-automatic pistol.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(usually in the plural) A statistical unit of proportion (of reliability, purity, etc.).",
              synonyms: [],
              antonyms: [],
              example:
                "They guaranteed that our Web site would have 99.99% uptime, or four nines.",
            },
            {
              definition:
                "A baseball club, team, or lineup (composed of nine players).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["IX"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A pair of nines.",
              synonyms: [],
              antonyms: [],
              example: "He drew nines from the deck.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/nine",
        "https://en.wiktionary.org/wiki/nines",
      ],
    },
  ],
  throb: [
    {
      word: "throb",
      phonetic: "/θɹɒb/",
      phonetics: [{ text: "/θɹɒb/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A beating, vibration or palpitation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To pound or beat rapidly or violently.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To vibrate or pulsate with a steady rhythm.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a body part) To pulse (often painfully) in time with the circulation of blood.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/throb"],
    },
  ],
  pecan: [
    {
      word: "pecan",
      phonetic: "/piːˈkæn/",
      phonetics: [
        { text: "/piːˈkæn/", audio: "" },
        { text: "/pəˈkɑːn/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A deciduous tree, Carya illinoinensis, of the central and southern United States, having deeply furrowed bark, pinnately compound leaves, and edible nuts.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A smooth, thin-shelled, edible oval nut of this tree.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A half of the edible portion of the inside of this nut.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pecan"],
    },
  ],
  butts: [
    {
      word: "butts",
      phonetic: "/bʌts/",
      phonetics: [{ text: "/bʌts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The larger or thicker end of something; the blunt end, in distinction from the sharp or narrow end",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The waste end of anything",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(generally) An end of something, often distinguished in some way from the other end.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A limit; a bound; a goal; the extreme bound; the end.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To join at the butt, end, or outward extremity; to terminate; to be bounded; to abut.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A push, thrust, or sudden blow, given by the head; a head butt.",
              synonyms: [],
              antonyms: [],
              example:
                "Be careful in the pen, that ram can knock you down with a butt.",
            },
            { definition: "A thrust in fencing.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To strike bluntly, particularly with the head.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To strike bluntly with the head.",
              synonyms: [],
              antonyms: [],
              example: "Rams butt at other males during mating season.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(English units) An English measure of capacity for liquids, containing 126 wine gallons which is one-half tun; equivalent to the pipe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A wooden cask for storing wine, usually containing 126 gallons.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of various flatfish such as sole, plaice or turbot",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A heavy two-wheeled cart.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A three-wheeled cart resembling a wheelbarrow.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/butt",
        "https://en.wiktionary.org/wiki/butts",
      ],
    },
  ],
  pence: [
    {
      word: "pence",
      phonetic: "/pəns/",
      phonetics: [
        { text: "/pəns/", audio: "" },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pence-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88675820",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/pəns/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "In the United Kingdom and Ireland, a copper coin worth 1/240 of a pound sterling or Irish pound before decimalisation. Abbreviation: d.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In the United Kingdom, a copper coin worth 1/100 of a pound sterling. Abbreviation: p.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In Ireland, a coin worth 1/100 of an Irish pound before the introduction of the euro. Abbreviation: p.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In the US and Canada, a one-cent coin, worth 1/100 of a dollar. Abbreviation: ¢.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In various countries, a small-denomination copper or brass coin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A unit of nail size, said to be either the cost per 100 nails, or the number of nails per penny. Abbreviation: d.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Money in general.",
              synonyms: [],
              antonyms: [],
              example: "to turn an honest penny",
            },
          ],
          synonyms: ["new penny", "old penny", "cent"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pence",
        "https://en.wiktionary.org/wiki/penny",
      ],
    },
  ],
  sills: [
    {
      word: "sills",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(also window sill) A horizontal slat which forms the base of a window.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A horizontal, structural member of a building near ground level on a foundation or pilings or lying on the ground in earth-fast construction and bearing the upright portion of a frame. Also called a ground plate, groundsill, sole, sole-plate, mudsill. An interrupted sill fits between posts instead of being below and supporting the posts in timber framing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A horizontal layer of igneous rock between older rock beds.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of timber across the bottom of a canal lock for the gates to shut against.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A raised area at the base of the nasal aperture in the skull.",
              synonyms: [],
              antonyms: [],
              example: "the nasal sill",
            },
            {
              definition: "The inner edge of the bottom of an embrasure.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A young herring.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The shaft or thill of a carriage.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sill",
        "https://en.wiktionary.org/wiki/sills",
      ],
    },
  ],
  jails: [
    {
      word: "jails",
      phonetic: "/dʒeɪlz/",
      phonetics: [{ text: "/dʒeɪlz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A place or institution for the confinement of persons held in lawful custody or detention, especially for minor offenses or with reference to some future judicial proceeding.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Confinement in a jail.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The condition created by the requirement that a horse claimed in a claiming race not be run at another track for some period of time (usually 30 days).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In dodgeball and related games, the area where players who have been struck by the ball are confined.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(FreeBSD) A kind of sandbox for running a guest operating system instance.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["hoosegow", "slammer"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To imprison.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["imprison", "incarcerate"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/jail",
        "https://en.wiktionary.org/wiki/jails",
      ],
    },
  ],
  flyer: [
    {
      word: "flyer",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "That which flies, as a bird or insect.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A machine that flies.", synonyms: [], antonyms: [] },
            { definition: "An airplane pilot.", synonyms: [], antonyms: [] },
            {
              definition: "A person who travels by airplane.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A leaflet, often for advertising.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The part of a spinning machine that twists the thread as it takes it to and winds it on the bobbin",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An arch that connects a flying buttress into the structure it supports.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(acrobatics, cheerleading, synchronized swimming) The person who is lifted and/or thrown by another person or persons.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A stray shot away from the group on a target.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A standard rectangular step of a staircase (as opposed to a winder).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A female kangaroo; a roo; a doe; a jill.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A leap or jump.", synonyms: [], antonyms: [] },
            {
              definition: "A risky investment or other venture.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["circular", "leaflet", "aviator", "aviatrix"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To distribute flyers (leaflets).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To distribute flyers in (a location) or to (recipients).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/flyer"],
    },
  ],
  saber: [
    {
      word: "saber",
      phonetic: "/ˈseɪ.bɚ/",
      phonetics: [
        { text: "/ˈseɪ.bɚ/", audio: "" },
        { text: "/ˈseɪ.bə(ɹ)/", audio: "" },
        { text: "/ˈseɪ.bɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A light sword, sharp along the front edge, part of the back edge, and at the point.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A modern fencing sword modeled after the sabre.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To strike or kill with a sabre.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/saber",
        "https://en.wiktionary.org/wiki/sabre",
      ],
    },
  ],
  nomad: [
    {
      word: "nomad",
      phonetic: "/ˈnəʊmæd/",
      phonetics: [
        { text: "/ˈnəʊmæd/", audio: "" },
        { text: "/ˈnoʊmæd/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A member of a society or class who herd animals from pasture to pasture with no fixed home.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person who changes residence frequently.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A player who changes teams frequently.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of or relating to nomads, whether",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "nomad",
            "ambling",
            "ambulant",
            "ambulatory",
            "discursive",
            "drifting",
            "errant",
            "erratic",
            "floating",
            "footloose",
            "gypsy",
            "itinerant",
            "itinerate",
            "meandering",
            "perambulant",
            "perambulatory",
            "peregrine",
            "peripatetic",
            "prodigal",
            "rambling",
            "roaming",
            "rootless",
            "roving",
            "transient",
            "unsettled",
            "vagabond",
            "vagrant",
            "wandering",
            "wayfaring",
            "pastoral",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One who wanders, who travels aimlessly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various far-migrating nymphalid butterflies of the genus Danaus.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The wandering albatross, Diomedea exulans.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/nomad",
        "https://en.wiktionary.org/wiki/nomadic",
        "https://en.wiktionary.org/wiki/wanderer",
      ],
    },
  ],
  miter: [
    {
      word: "miter",
      phonetic: "/ˈmaɪ.təɹ/",
      phonetics: [{ text: "/ˈmaɪ.təɹ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A covering for the head, worn on solemn occasions by church dignitaries, which has been made in many forms, mostly recently a tall cap with two points or peaks.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The surface forming the bevelled end or edge of a piece where a miter joint is made; also, a joint formed or a junction effected by two beveled ends or edges; a miter joint.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A 13th-century coin minted in Europe which circulated in Ireland as a debased counterfeit sterling penny, outlawed under Edward I.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A cap or cowl for a chimney or ventilation pipe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A gusset in sewing, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A square with one triangular quarter missing from the outside.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To adorn with a mitre.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To unite at an angle of 45°.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/miter",
        "https://en.wiktionary.org/wiki/mitre",
      ],
    },
  ],
  beeps: [
    {
      word: "beeps",
      phonetic: "/biːps/",
      phonetics: [{ text: "/biːps/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The sound produced by the horn of a car, or any similar sound.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A short, electronically produced tone.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bleep"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To sound (something that makes a beep).",
              synonyms: [],
              antonyms: [],
              example:
                "The motorists in the traffic jam were getting more and more frustrated and started beeping their horns.",
            },
            {
              definition:
                "To have sexual intercourse (with) - referring to the bleep tone used to censor obscene words in broadcasts",
              synonyms: [],
              antonyms: [],
              example:
                "Jason beeped Sharlene after they had drunk a few beers.",
            },
            { definition: "To produce a beep.", synonyms: [], antonyms: [] },
            {
              definition: "To contact (someone via) a pager device.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Telephoning a person, but only allowing the phone to ring once, in order to request a call back.",
              synonyms: [],
              antonyms: [],
              example:
                "Susan beeped Jessica, and then Jessica called her back, because Susan didn't have enough credit on her phone to make the call.",
            },
          ],
          synonyms: [
            "honk",
            "hoot",
            "sound",
            "toot",
            "flash",
            "fuck",
            "sleep with",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/beep",
        "https://en.wiktionary.org/wiki/beeps",
      ],
    },
  ],
  domed: [
    {
      word: "domed",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "In the form of a dome.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["hemispherical"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/domed"],
    },
  ],
  gulfs: [
    {
      word: "gulfs",
      phonetic: "/ɡʌlfs/",
      phonetics: [{ text: "/ɡʌlfs/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A hollow place in the earth; an abyss; a deep chasm or basin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "That which swallows; the gullet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That which swallows irretrievably; a whirlpool; a sucking eddy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A portion of an ocean or sea extending into the land; a partially landlocked sea",
              synonyms: [],
              antonyms: [],
              example: "the Gulf of Mexico    the Persian Gulf",
            },
            {
              definition: "A large deposit of ore in a lode.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A difference, especially a large difference, between groups.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Oxbridge slang) The bottom part of a list of those awarded a degree, for those who have only just passed.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["abyss"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(Oxbridge slang) To award a degree to somebody who has only just passed sufficiently.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gulf",
        "https://en.wiktionary.org/wiki/gulfs",
      ],
    },
  ],
  curbs: [
    {
      word: "curbs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A concrete margin along the edge of a road; a kerb (UK, Australia, New Zealand)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A raised margin along the edge of something, such as a well or the eye of a dome, as a strengthening.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Something that checks or restrains; a restraint.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A riding or driving bit for a horse that has rein action which amplifies the pressure in the mouth by leverage advantage placing pressure on the poll via the crown piece of the bridle and chin groove via a curb chain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sidewalk, covered or partially enclosed, bordering the airport terminal road system with adjacent paved areas to permit vehicles to off-load or load passengers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A swelling on the back part of the hind leg of a horse, just behind the lowest part of the hock joint, generally causing lameness.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/curb",
        "https://en.wiktionary.org/wiki/curbs",
      ],
    },
  ],
  heath: [
    {
      word: "heath",
      phonetic: "/hiːθ/",
      phonetics: [
        {
          text: "/hiːθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/heath-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80065919",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A tract of level uncultivated land with sandy soil and scrubby vegetation; heathland.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any small evergreen shrub of the family Ericaceae.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Certain butterflies and moths",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["heather"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/heath"],
    },
  ],
  moors: [
    {
      word: "moors",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/moors-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651190",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An extensive waste covered with patches of heath, and having a poor, light soil, but sometimes marshy, and abounding in peat; a heath",
              synonyms: [],
              antonyms: [],
              example:
                "A cold, biting wind blew across the moor, and the travellers hastened their step.",
            },
            {
              definition: "A game preserve consisting of moorland.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cast anchor or become fastened.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To fix or secure (e.g. a vessel) in a particular place by casting anchor, or by fastening with ropes, cables or chains or the like",
              synonyms: [],
              antonyms: [],
              example: "the vessel was moored in the stream",
            },
            {
              definition: "To secure or fix firmly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/moor",
        "https://en.wiktionary.org/wiki/moors",
      ],
    },
  ],
  aorta: [
    {
      word: "aorta",
      phonetic: "/eɪˈɔːtə/",
      phonetics: [
        { text: "/eɪˈɔːtə/", audio: "" },
        { text: "/eɪˈɔːɹtə/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The great artery which carries the blood from the heart to all parts of the body except the lungs; the main trunk of the arterial system.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The liveliest part of something.",
              synonyms: [],
              antonyms: [],
              example:
                "Tracing their battles, I had many occasions to walk along Second Avenue, the aorta of the Lower East Side, exploring places that were once as vibrant and tumultuous as Midtown Manhattan. http//travel.nytimes.com/2007/01/26/travel/escapes/26yiddish.html?8dpc",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/aorta"],
    },
  ],
  larks: [
    {
      word: "larks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To catch larks (type of bird).",
              synonyms: [],
              antonyms: [],
              example: "to go larking",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To sport, engage in harmless pranking.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To frolic, engage in carefree adventure.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lark",
        "https://en.wiktionary.org/wiki/larks",
      ],
    },
  ],
  tangy: [
    {
      word: "tangy",
      phonetic: "/ˈtæŋiː/",
      phonetics: [{ text: "/ˈtæŋiː/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having a sharp, pungent flavor",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tangy"],
    },
  ],
  wryly: [
    {
      word: "wryly",
      phonetic: "/ˈɹaɪli/",
      phonetics: [
        {
          text: "/ˈɹaɪli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wryly-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=85957993",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "In a wry manner.",
              synonyms: [],
              antonyms: [],
              example:
                "1966: The sins of the father, she thought wryly. Well, they had visited Tony, all right — only he didn't know it. — Jacqueline Susann, Valley of the Dolls (Grove Press 1997, p. 206)",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/wryly"],
    },
  ],
  cheep: [
    {
      word: "cheep",
      phonetic: "/tʃiːp/",
      phonetics: [
        {
          text: "/tʃiːp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cheep-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=720489",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A short, high-pitched sound made by a small bird.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                'Of a small bird, to make short, high-pitched sounds sounding like "cheep".',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To express in a chirping tone.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "The short, high-pitched sound made by a small bird.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cheep"],
    },
  ],
  rages: [
    {
      word: "rages",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Violent uncontrolled anger.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A current fashion or fad.",
              synonyms: [],
              antonyms: [],
              example: "Miniskirts were all the rage back then.",
            },
            { definition: "Any vehement passion.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["fury", "ire"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To act or speak in heightened anger.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(sometimes figurative) To move with great violence, as a storm etc.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To enrage.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rage",
        "https://en.wiktionary.org/wiki/rages",
      ],
    },
  ],
  evade: [
    {
      word: "evade",
      phonetic: "/ɪˈveɪd/",
      phonetics: [
        {
          text: "/ɪˈveɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/evade-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=81996446",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To get away from by cunning; to avoid by dexterity, subterfuge, address, or ingenuity; to elude; to cleverly escape from",
              synonyms: [],
              antonyms: [],
              example: "He evaded his opponent's blows.",
            },
            {
              definition: "To escape; to slip away; — sometimes with from.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To attempt to escape; to practice artifice or sophistry, for the purpose of eluding.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "dodge",
            "end-run",
            "equivocate",
            "give someone the runaround",
            "give the go-by",
            "shuffle",
            "sidestep",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/evade"],
    },
  ],
  lures: [
    {
      word: "lures",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Something that tempts or attracts, especially one with a promise of reward or pleasure",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An artificial bait attached to a fishing line to attract fish",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bunch of feathers attached to a line, used in falconry to recall the hawk",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A velvet smoothing brush",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A trumpet with long curved tube, used for calling cattle, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lure",
        "https://en.wiktionary.org/wiki/lures",
      ],
    },
  ],
  freak: [
    {
      word: "freak",
      phonetic: "/fɹiːk/",
      phonetics: [
        {
          text: "/fɹiːk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/freak-1-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88676256",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A sudden change of mind",
              synonyms: ["caprice", "vagary", "whim"],
              antonyms: [],
            },
            {
              definition:
                "Someone or something that is markedly unusual or unpredictable.",
              synonyms: [],
              antonyms: [],
              example: "a freak genius",
            },
            { definition: "A hippie.", synonyms: [], antonyms: [] },
            { definition: "A drug addict.", synonyms: [], antonyms: [] },
            {
              definition:
                '(of a person) A nonconformist, especially in appearance, social behavior, sexual orientation, gender identity, and/or business practices; an oddball, especially in physiology (i.e., "circus freak"); unique, sometimes in a displeasing way.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person whose physique has grown far beyond the normal limits of muscular development; often a bodybuilder weighing more than 120 kilos (260 pounds).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An enthusiast, or person who has an obsession with, or extreme knowledge of, something.",
              synonyms: [],
              antonyms: [],
              example:
                "Bob's a real video-game freak. He owns every games console of the last ten years.",
            },
            {
              definition:
                "(sometimes affectionate) A very sexually perverse individual.",
              synonyms: [],
              antonyms: [],
              example: "She's a freak in the sack!",
            },
            {
              definition: "A streak of colour; variegation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "druggie",
            "user",
            "fanatic",
            "geek",
            "longhair",
            "treehugger",
            "anomaly",
            "outlier",
            "odd duck",
            "weirdo",
            "horn dog",
            "hypersexual",
            "pervert",
            "superciliary",
            "vein",
            "caprice",
            "fancy",
            "caprice",
            "vagary",
            "whim",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make greatly distressed and/or a discomposed appearance",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be placed or place someone under the influence of a psychedelic drug",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To streak; to variegate",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To experience reality withdrawal, or hallucinations (nightmarish), to behave irrational or unconventional due to drug use.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To react extremely or irrationally, usually under distress or discomposure",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Strange, weird, unexpected",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/freak"],
    },
    {
      word: "freak",
      phonetic: "/fɹiːk/",
      phonetics: [{ text: "/fɹiːk/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A man, particularly a bold, strong, vigorous man.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A fellow; a petulant young man.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/freak"],
    },
  ],
  vogue: [
    {
      word: "vogue",
      phonetic: "/vəʊɡ/",
      phonetics: [{ text: "/vəʊɡ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The prevailing fashion or style.",
              synonyms: [],
              antonyms: [],
              example: "Miniskirts were the vogue in the '60s.",
            },
            {
              definition: "Popularity or a current craze.",
              synonyms: [],
              antonyms: [],
              example: "Hula hoops are no longer in vogue.",
            },
            {
              definition:
                "A highly stylized modern dance that evolved out of the Harlem ballroom scene in the 1960s.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A cigarette.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To dance in the vogue dance style.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To light a cigarette.",
              synonyms: [],
              antonyms: [],
              example: "Vogue me up.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/vogue"],
    },
  ],
  tunic: [
    {
      word: "tunic",
      phonetic: "/tjuːnɪk/",
      phonetics: [{ text: "/tjuːnɪk/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A garment worn over the torso, with or without sleeves, and of various lengths reaching from the hips to the ankles.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any covering, such as seed coat or the organ that covers a membrane.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tunic"],
    },
  ],
  slams: [
    {
      word: "slams",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To shut with sudden force so as to produce a shock and noise.",
              synonyms: [],
              antonyms: [],
              example: "Don't slam the door!",
            },
            {
              definition:
                "To put in or on a particular place with force and loud noise. (Often followed by a preposition such as down, against or into.)",
              synonyms: [],
              antonyms: [],
              example: "Don't slam that trunk down on the pavement!",
            },
            {
              definition: "To strike forcefully with some implement.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To strike against suddenly and heavily.",
              synonyms: [],
              antonyms: [],
              example:
                "The boat slammed into the bank and we were almost thrown into the river.",
            },
            {
              definition: "To speak badly of; to criticize forcefully.",
              synonyms: [],
              antonyms: [],
              example:
                "Critics slammed the new film, calling it violent and meaningless.",
            },
            {
              definition: "To dunk forcefully, to slam dunk.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To make a slam bid.", synonyms: [], antonyms: [] },
            {
              definition:
                "To defeat (opponents at cards) by winning all the tricks of a deal or a hand.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To change providers (e.g. of domain registration or telephone carrier) for a customer without clear (if any) consent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To drink off, to drink quickly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To compete in a poetry slam.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To inject intravenously; shoot up.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bang"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To defeat by winning all the tricks of a deal or a hand.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/slam",
        "https://en.wiktionary.org/wiki/slams",
      ],
    },
  ],
  knits: [
    {
      word: "knits",
      phonetic: "/nɪts/",
      phonetics: [{ text: "/nɪts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A knitted garment.", synonyms: [], antonyms: [] },
            {
              definition: "A session of knitting.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To turn thread or yarn into a piece of fabric by forming loops that are pulled through each other. This can be done by hand with needles or by machine.",
              synonyms: [],
              antonyms: [],
              example:
                "The first generation knitted to order; the second still knits for its own use; the next leaves knitting to industrial manufacturers.",
            },
            {
              definition: "To join closely and firmly together.",
              synonyms: [],
              antonyms: [],
              example:
                "The fight for survival knitted the men closely together.",
            },
            {
              definition:
                "To become closely and firmly joined; become compacted.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To grow together.",
              synonyms: [],
              antonyms: [],
              example:
                "All those seedlings knitted into a kaleidoscopic border.",
            },
            {
              definition: "To combine from various elements.",
              synonyms: [],
              antonyms: [],
              example:
                "The witness knitted together his testimony from contradictory pieces of hearsay.",
            },
            {
              definition: "Of bones: to heal following a fracture.",
              synonyms: [],
              antonyms: [],
              example: "I’ll go skiing again after my bones knit.",
            },
            {
              definition:
                "To form into a knot, or into knots; to tie together, as cord; to fasten by tying.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To draw together; to contract into wrinkles.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/knit",
        "https://en.wiktionary.org/wiki/knits",
      ],
    },
    {
      word: "knits",
      phonetic: "/nɪts/",
      phonetics: [{ text: "/nɪts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Small particles of ore.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/knits"],
    },
  ],
  dumpy: [
    {
      word: "dumpy",
      phonetic: "/ˈdʌmpi/",
      phonetics: [{ text: "/ˈdʌmpi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A short, stout person or animal, especially one of a breed of very short-legged chickens.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A small bottle of beer.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Short and thick; stout or stocky",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dumpy"],
    },
  ],
  mania: [
    {
      word: "mania",
      phonetic: "/ˈmeɪ.ni.ə/",
      phonetics: [{ text: "/ˈmeɪ.ni.ə/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Violent derangement of mind; madness; insanity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Excessive or unreasonable desire; insane passion affecting one or many people; fanaticism.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The state of abnormally elevated or irritable mood, arousal, and/or energy levels.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mania"],
    },
  ],
  spits: [
    {
      word: "spits",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A sudden impact or blow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The shock and noise produced by violently closing a door or other object.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A slam dunk.", synonyms: [], antonyms: [] },
            {
              definition: "An insult.",
              synonyms: [],
              antonyms: [],
              example:
                "I don't mean this as a slam, but you can be really impatient sometimes.",
            },
            {
              definition:
                "The yellow iron silicate produced in alum works as a waste product.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A poetry slam.", synonyms: [], antonyms: [] },
            { definition: "A slambook.", synonyms: [], antonyms: [] },
            {
              definition: "The refuse of alum works.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A subgenre of death metal with elements of hardcore punk focusing on midtempo rhythms, breakdowns and palm-muted riffs",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A type of card game, also called ruff and honours.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A card game, played all at once without separate turns, in which players attempt to get rid of their cards as quickly as possible according to certain rules.",
              synonyms: ["spit"],
              antonyms: [],
            },
            {
              definition: "Losing or winning all the tricks in a game.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bid of six (small slam) or seven (grand slam) in a suit or no trump.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Winning all (or all but one) of the available, major or specified events in a given year or sports season.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["spit"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A shambling fellow.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A thin metal or wooden rod on which meat is skewered for cooking, often over a fire.",
              synonyms: ["broach"],
              antonyms: [],
            },
            {
              definition:
                "A generally low, narrow, pointed, usually sandy peninsula.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["broach"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To impale on a spit; to pierce with a sharp object.",
              synonyms: [],
              antonyms: [],
              example: "to spit a loin of veal",
            },
            {
              definition:
                "To use a spit to cook; to attend to food that is cooking on a spit.",
              synonyms: [],
              antonyms: [],
              example: "She’s spitting the roast in the kitchen.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Saliva, especially when expectorated.",
              synonyms: ["expectoration", "spittle"],
              antonyms: [],
              example: "There was spit all over the washbasin.",
            },
            {
              definition:
                "An instance of spitting; specifically, a light fall of rain or snow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person who exactly resembles someone else (usually in set phrases; see spitting image)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["expectoration", "spittle"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To evacuate (saliva or another substance) from the mouth, etc.",
              synonyms: ["expectorate"],
              antonyms: [],
            },
            {
              definition:
                "To emit or expel in a manner similar to evacuating saliva from the mouth; specifically, to rain or snow slightly.",
              synonyms: [],
              antonyms: [],
              example: "a hot pan spitting droplets of fat",
            },
            {
              definition: "To utter (something) violently.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(hip-hop) To rap, to utter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make a spitting sound, like an angry cat.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["expectorate"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The depth to which the blade of a spade goes into the soil when it is used for digging; a layer of soil of the depth of a spade's blade.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The amount of soil that a spade holds; a spadeful.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To dig (something) using a spade; also, to turn (the soil) using a plough.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To plant (something) using a spade.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To dig, to spade.",
              synonyms: ["delve"],
              antonyms: [],
            },
          ],
          synonyms: ["delve"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/slam",
        "https://en.wiktionary.org/wiki/spit",
        "https://en.wiktionary.org/wiki/spits",
      ],
    },
  ],
  firth: [
    {
      word: "firth",
      phonetic: "/fɜːθ/",
      phonetics: [
        {
          text: "/fɜːθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/firth-1-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=95442829",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/fɝθ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An arm or inlet of the sea; a river estuary.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/firth"],
    },
    {
      word: "firth",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An arm or inlet of the sea; a river estuary.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Peace; security.", synonyms: [], antonyms: [] },
            { definition: "Sanctuary, asylum.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A forest or wood; woodland generally.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Land with mostly undergrowth and few trees; also, land in between forests or woods; pastureland which is not in use.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Brushwood or undergrowth, sometimes in the form of a hedge.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hedge, especially one made from brushwood which has been wattled; also, a movable frame made from wattled branches, a hurdle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A kind of weir made from wattled branches for catching fish.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/firth",
        "https://en.wiktionary.org/wiki/frith",
      ],
    },
  ],
  hikes: [
    {
      word: "hikes",
      phonetic: "/haɪks/",
      phonetics: [{ text: "/haɪks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A long walk.", synonyms: [], antonyms: [] },
            {
              definition: "An abrupt increase.",
              synonyms: [],
              antonyms: [],
              example: "The tenants were not happy with the rent hike.",
            },
            {
              definition: "The snap of the ball to start a play.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sharp upward tug to raise something.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To take a long walk for pleasure or exercise.",
              synonyms: [],
              antonyms: [],
              example:
                "Don't forget to bring the map when we go hiking tomorrow.",
            },
            {
              definition: "To unfairly or suddenly raise a price.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To snap the ball to start a play.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To lean out to the windward side of a sailboat in order to counterbalance the effects of the wind on the sails.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To pull up or tug upwards sharply.",
              synonyms: [],
              antonyms: [],
              example: "She hiked her skirt up.",
            },
          ],
          synonyms: ["lean out", "sit out", "tramp"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hike",
        "https://en.wiktionary.org/wiki/hikes",
      ],
    },
  ],
  trots: [
    {
      word: "trots",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/trots-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75701740",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A supporter of Trotskyism.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of several animals related to Equus ferus caballus.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Equipment with legs.", synonyms: [], antonyms: [] },
            { definition: "Type of equipment.", synonyms: [], antonyms: [] },
            {
              definition:
                "A mass of earthy matter, or rock of the same character as the wall rock, occurring in the course of a vein, as of coal or ore; hence, to take horse (said of a vein) is to divide into branches for a distance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The sedative, antidepressant, and anxiolytic drug morphine, chiefly when used illicitly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'An informal variant of basketball in which players match shots made by their opponent(s), each miss adding a letter to the word "horse", with 5 misses spelling the whole word and eliminating a player, until only the winner is left. Also HORSE, H-O-R-S-E or H.O.R.S.E. (see Variations of basketball#H-O-R-S-E).',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(among students) A translation or other illegitimate aid in study or examination.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(among students) horseplay; tomfoolery",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "horsie",
            "nag",
            "prad",
            "steed",
            "knight",
            "pommel horse",
            "vaulting horse",
            "dobbin",
            "pony",
            "trot",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Heroin (drug).",
              synonyms: [],
              antonyms: [],
              example: "Alright, mate, got any horse?",
            },
          ],
          synonyms: ["H", "smack"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An ugly old woman, a hag.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(chiefly of horses) A gait of a four-legged animal between walk and canter, a diagonal gait (in which diagonally opposite pairs of legs move together).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A gait of a person or animal faster than a walk but slower than a run.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A brisk journey or progression.",
              synonyms: [],
              antonyms: [],
              example:
                "In this lesson we'll have a quick trot through Chapter 3 before moving on to Chapter 4.",
            },
            { definition: "A toddler.", synonyms: [], antonyms: [] },
            { definition: "A young animal.", synonyms: [], antonyms: [] },
            {
              definition: "A moderately rapid dance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A succession of heads thrown in a game of two-up.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: '(with "good" or "bad") A run of luck or fortune.',
              synonyms: [],
              antonyms: [],
              example: "He′s had a good trot, but his luck will end soon.",
            },
            {
              definition: "(as 'the trots') Diarrhoea.",
              synonyms: [],
              antonyms: [],
              example:
                "He's got a bad case of the trots and has to keep running off to the toilet.",
            },
          ],
          synonyms: ["jog"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To move along briskly; specifically, to move at a pace between a walk and a run.",
              synonyms: [],
              antonyms: [],
              example:
                "I didn't want to miss my bus, so I trotted the last few hundred yards to the stop.",
            },
            {
              definition:
                "(of a horse) To move at a gait between a walk and a canter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause to move, as a horse or other animal, in the pace called a trot; to cause to run without galloping or cantering.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["jog", "pace"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Diarrhoea/diarrhea.",
              synonyms: [],
              antonyms: [],
              example:
                "I've had the trots all morning and haven't been able to go out.",
            },
            {
              definition: '(with "the") A trotting race meet; harness racing.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/Trotskyist",
        "https://en.wiktionary.org/wiki/horse",
        "https://en.wiktionary.org/wiki/trot",
        "https://en.wiktionary.org/wiki/trots",
      ],
    },
  ],
  nosed: [
    {
      word: "nosed",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To move cautiously by advancing its front end.",
              synonyms: [],
              antonyms: [],
              example: "The ship nosed through the minefield.",
            },
            {
              definition: "To snoop.",
              synonyms: [],
              antonyms: [],
              example: "She was nosing around other people’s business.",
            },
            {
              definition: "To detect by smell or as if by smell.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To push with one's nose; to nuzzle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To defeat (as in a race or other contest) by a narrow margin; sometimes with out.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To utter in a nasal manner; to pronounce with a nasal twang.",
              synonyms: [],
              antonyms: [],
              example: "to nose a prayer",
            },
            {
              definition: "To furnish with a nose.",
              synonyms: [],
              antonyms: [],
              example: "to nose a stair tread",
            },
            {
              definition:
                "To confront; be closely face to face or opposite to.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "(in combination) Having some specific type of nose.",
              synonyms: [],
              antonyms: [],
              example: "Rudolph the red-nosed reindeer",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/nose",
        "https://en.wiktionary.org/wiki/nosed",
      ],
    },
  ],
  clank: [
    {
      word: "clank",
      phonetic: "/klæŋk/",
      phonetics: [
        {
          text: "/klæŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/clank-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88897871",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A loud, hard sound of metal hitting metal.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make a clanking sound",
              synonyms: [],
              antonyms: [],
              example: "The chains clanked.",
            },
            {
              definition: "To cause to sound with a clank.",
              synonyms: [],
              antonyms: [],
              example: "The prisoners clank their chains.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/clank"],
    },
  ],
  dogma: [
    {
      word: "dogma",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dogma-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75730265",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈdɒɡ.mə/", audio: "" },
        {
          text: "/ˈdɑɡ.mə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dogma-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=5892981",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An authoritative principle, belief or statement of opinion, especially one considered to be absolutely true and indisputable, regardless of evidence or without evidence to support it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A doctrine (or set of doctrines) relating to matters such as morality and faith, set forth authoritatively by a religious organization or leader.",
              synonyms: [],
              antonyms: [],
              example:
                "In the Catholic Church, new dogmas can only be declared by the pope after the extremely rare procedure ex cathedra to make them part of the official faith.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dogma"],
    },
  ],
  bloat: [
    {
      word: "bloat",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bloat-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241344",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/bləʊt/", audio: "" },
        { text: "/bloʊt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Distention of the abdomen from death.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Pathological overdistention of rumen with gas in a ruminant.",
              synonyms: ["ruminal tympany"],
              antonyms: [],
            },
            {
              definition: "Wasteful use of space or other resources.",
              synonyms: [],
              antonyms: [],
              example:
                "Adding an e-mail feature to this simple text editor would be pointless bloat.",
            },
            {
              definition: "A worthless, dissipated fellow.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["ruminal tympany"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cause to become distended.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(veterinary medicine) to get an overdistended rumen, talking of a ruminant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To fill soft substance with gas, water, etc.; to cause to swell.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To become distended; to swell up.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fill with vanity or conceit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To preserve by slightly salting and lightly smoking.",
              synonyms: [],
              antonyms: [],
              example: "bloated herring",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [{ definition: "Bloated.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bloat"],
    },
  ],
  balsa: [
    {
      word: "balsa",
      phonetic: "/ˈbæl.sə/",
      phonetics: [
        { text: "/ˈbæl.sə/", audio: "" },
        { text: "/ˈbɑlsə/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A large tree, Ochroma pyramidale, native to tropical America, with wood that is very light in weight.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The wood of this tree.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A raft or float, used principally on the Pacific coast of South America.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/balsa"],
    },
  ],
  graft: [
    {
      word: "graft",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/graft-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79218691",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ɡɹæft/", audio: "" },
        { text: "/ɡɹɑːft/", audio: "" },
        { text: "/ɡɹæft/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small shoot or scion of a tree inserted in another tree, the stock of which is to support and nourish it. The two unite and become one tree, but the graft determines the kind of fruit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A branch or portion of a tree growing from such a shoot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A portion of living tissue used in the operation of autoplasty.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To insert (a graft) in a branch or stem of another tree; to propagate by insertion in another stock; also, to insert a graft upon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To insert scions (grafts) from one tree, or kind of tree, etc., into another; to practice grafting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To implant a portion of (living flesh or akin) in a lesion so as to form an organic union.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To join (one thing) to another as if by grafting, so as to bring about a close union.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cover, as a ring bolt, block strap, splicing, etc., with a weaving of small cord or rope-yarns.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To form a graft polymer",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["affix", "attach", "put together", "unite"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/graft"],
    },
    {
      word: "graft",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/graft-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79218691",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ɡɹæft/", audio: "" },
        { text: "/ɡɹɑːft/", audio: "" },
        { text: "/ɡɹæft/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A ditch, a canal.", synonyms: [], antonyms: [] },
            {
              definition:
                "The depth of the blade of a digging tool such as a spade or shovel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A narrow spade used in digging drainage trenches.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/graft"],
    },
    {
      word: "graft",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/graft-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79218691",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ɡɹæft/", audio: "" },
        { text: "/ɡɹɑːft/", audio: "" },
        { text: "/ɡɹæft/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Corruption in official life.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Illicit profit by corrupt means, especially in public life.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A criminal’s special branch of practice.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A con job.", synonyms: [], antonyms: [] },
            {
              definition: "A cut of the take (money).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A bribe, especially on an ongoing basis.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Work; labor", synonyms: [], antonyms: [] },
            { definition: "A job or trade.", synonyms: [], antonyms: [] },
            {
              definition: "Effort needed for doing hard work.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To work.", synonyms: [], antonyms: [] },
            {
              definition:
                "To obtain illegal gain from bribery or similar corrupt practices.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/graft"],
    },
  ],
  middy: [
    {
      word: "middy",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/middy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75622620",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A midshipman", synonyms: [], antonyms: [] },
            {
              definition: "A measure of 285 ml (10 fl oz) of beer; a pot.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/middy"],
    },
  ],
  stile: [
    {
      word: "stile",
      phonetic: "/staɪl/",
      phonetics: [
        {
          text: "/staɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stile-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88675056",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/staɪl/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A set of one or more steps surmounting a fence or wall, or a narrow gate or contrived passage through a fence or wall, which in either case allows people but not livestock to pass.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A vertical component of a frame or panel, such as that of a door, window, or ladder.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Senses relating to a thin, pointed object.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension from sense 1.1) A particular manner of expression in writing or speech, especially one regarded as good.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A particular manner of creating, doing, or presenting something, especially a work of architecture or art.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To design, fashion, make, or arrange in a certain way or form (style)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To call or give a name or title to.",
              synonyms: ["designate", "dub", "name"],
              antonyms: [],
            },
            {
              definition:
                "To create for, or give to, someone a style, fashion, or image, particularly one which is regarded as attractive, tasteful, or trendy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To act in a way which seeks to show that one possesses style.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["designate", "dub", "name"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/stile",
        "https://en.wiktionary.org/wiki/style",
      ],
    },
  ],
  keyed: [
    {
      word: "keyed",
      phonetic: "/kiːd/",
      phonetics: [{ text: "/kiːd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To fit (a lock) with a key.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To fit (pieces of a mechanical assembly) with a key to maintain the orientation between them.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To mark or indicate with a symbol indicating membership in a class.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(telegraphy and radio telegraphy) To depress (a telegraph key).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To operate (the transmitter switch of a two-way radio).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(more usually to key in) To enter (information) by typing on a keyboard or keypad.",
              synonyms: [],
              antonyms: [],
              example: "Our instructor told us to key in our user IDs.",
            },
            {
              definition:
                "To vandalize (a car, etc.) by scratching with an implement such as a key.",
              synonyms: [],
              antonyms: [],
              example: "He keyed the car that had taken his parking spot.",
            },
            {
              definition: "To link (as one might do with a key or legend).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be identified as a certain taxon when using a key.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To modify (an advertisement) so as to target a particular group or demographic.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To attune to; to set at; to pitch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To fasten or secure firmly; to fasten or tighten with keys or wedges.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/key",
        "https://en.wiktionary.org/wiki/keyed",
      ],
    },
  ],
  finch: [
    {
      word: "finch",
      phonetic: "/fɪntʃ/",
      phonetics: [{ text: "/fɪntʃ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any bird of the family Fringillidae, seed-eating passerine birds, native chiefly to the Northern Hemisphere and usually having a conical beak.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To hunt for finches, to go finching.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/finch"],
    },
  ],
  sperm: [
    {
      word: "sperm",
      phonetic: "/spɜːm/",
      phonetics: [
        { text: "/spɜːm/", audio: "" },
        {
          text: "/spɜːɹm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sperm-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=7467977",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The reproductive cell or gamete of the male; a spermatozoon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Semen; the generative substance of male animals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Sperm oil; whale oil from a sperm whale; spermaceti.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["cum", "semen", "sperm cell", "spermatozoon"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To ejaculate.", synonyms: [], antonyms: [] },
            {
              definition: "To ejaculate on or into.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sperm"],
    },
  ],
  chaff: [
    {
      word: "chaff",
      phonetic: "/tʃæf/",
      phonetics: [
        { text: "/tʃæf/", audio: "" },
        { text: "/tʃæf/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The inedible parts of a grain-producing plant.",
              synonyms: [],
              antonyms: [],
              example:
                "To separate out the chaff, early cultures tossed baskets of grain into the air and let the wind blow away the lighter chaff.",
            },
            {
              definition: "Straw or hay cut up fine for the food of cattle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any excess or unwanted material, resource, or person; anything worthless.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Light jesting talk; banter; raillery.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Loose material, e.g. small strips of aluminum foil dropped from aircraft, intended to interfere with radar detection.",
              synonyms: ["window"],
              antonyms: [],
            },
          ],
          synonyms: ["window"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To use light, idle language by way of fun or ridicule; to banter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make fun of; to turn into ridicule by addressing in ironical or bantering language; to quiz.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chaff"],
    },
  ],
  wiles: [
    {
      word: "wiles",
      phonetic: "/waɪlz/",
      phonetics: [{ text: "/waɪlz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(usually in the plural) A trick or stratagem practiced for ensnaring or deception; a sly, insidious artifice",
              synonyms: [],
              antonyms: [],
              example: "He was seduced by her wiles.",
            },
          ],
          synonyms: ["allurement", "beguilement"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wile",
        "https://en.wiktionary.org/wiki/wiles",
      ],
    },
  ],
  amigo: [
    {
      word: "amigo",
      phonetic: "/əˈmiː.ɡəʊ/",
      phonetics: [
        { text: "/əˈmiː.ɡəʊ/", audio: "" },
        { text: "/əˈmiɡoʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Friend", synonyms: [], antonyms: [] },
            {
              definition: "(chiefly California) Mexican",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A native of the Philippines who was friendly toward the Spanish.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/amigo"],
    },
  ],
  copra: [
    {
      word: "copra",
      phonetic: "/ˈkɒ.pɹə/",
      phonetics: [{ text: "/ˈkɒ.pɹə/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The dried kernel of the coconut, from which coconut oil is extruded.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/copra"],
    },
  ],
  amiss: [
    {
      word: "amiss",
      phonetic: "/əˈmɪs/",
      phonetics: [
        { text: "/əˈmɪs/", audio: "" },
        { text: "/əˈmɪs/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Fault; wrong; an evil act, a bad deed.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Wrong; faulty; out of order; improper or otherwise incorrect.",
              synonyms: [],
              antonyms: [],
              example: "He suspected something was amiss.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            { definition: "Wrongly.", synonyms: [], antonyms: [] },
            { definition: "Mistakenly.", synonyms: [], antonyms: [] },
            { definition: "Astray.", synonyms: [], antonyms: [] },
            { definition: "Imperfectly.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/amiss"],
    },
  ],
  eying: [
    {
      word: "eying",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To observe carefully or appraisingly.",
              synonyms: [],
              antonyms: [],
              example:
                "After eyeing the document for half an hour, she decided not to sign it.",
            },
            { definition: "To appear; to look.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/eye",
        "https://en.wiktionary.org/wiki/eying",
      ],
    },
  ],
  twirl: [
    {
      word: "twirl",
      phonetic: "/ˈtwɜː(ɹ)l/",
      phonetics: [
        { text: "/ˈtwɜː(ɹ)l/", audio: "" },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/twirl-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=7467987",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A movement where a person spins round elegantly; a pirouette.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any rotating movement; a spin.",
              synonyms: [],
              antonyms: [],
              example:
                "The conductor gave his baton a twirl, and the orchestra began to play.",
            },
            {
              definition: "A little twist of some substance; a swirl.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A prison guard.",
              synonyms: ["screw"],
              antonyms: [],
            },
          ],
          synonyms: ["screw"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To perform a twirl.", synonyms: [], antonyms: [] },
            { definition: "To rotate rapidly.", synonyms: [], antonyms: [] },
            { definition: "To twist round.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/twirl"],
    },
  ],
  lurch: [
    {
      word: "lurch",
      phonetic: "/lɝt͡ʃ/",
      phonetics: [
        {
          text: "/lɝt͡ʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lurch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=678284",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A sudden or unsteady movement.",
              synonyms: [],
              antonyms: [],
              example: "the lurch of a ship, or of a drunkard",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make such a sudden, unsteady movement.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lurch"],
    },
    {
      word: "lurch",
      phonetic: "/lɝt͡ʃ/",
      phonetics: [
        {
          text: "/lɝt͡ʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lurch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=678284",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To swallow or eat greedily; to devour; hence, to swallow up.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lurch"],
    },
    {
      word: "lurch",
      phonetic: "/lɝt͡ʃ/",
      phonetics: [
        {
          text: "/lɝt͡ʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lurch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=678284",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An old game played with dice and counters; a variety of the game of tables.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A double score in cribbage for the winner when his/her adversary has been left in the lurch.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To leave someone in the lurch; to cheat.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To rob.", synonyms: [], antonyms: [] },
            {
              definition: "To evade by stooping; to lurk.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lurch"],
    },
  ],
  popes: [
    {
      word: "popes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(Russian Orthodoxy) A Russian Orthodox priest; a parson.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An honorary title of the Roman Catholic bishop of Rome as father and head of his church, a sovereign of the Vatican city state.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Coptic Church) An honorary title of the Coptic bishop of Alexandria as father and head of his church.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An honorary title of the Orthodox bishop of Alexandria as father and head of his autocephalous church.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any bishop of the early Christian church.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The ruffe, a small Eurasian freshwater fish (Gymnocephalus cernua); others of its genus.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Cumberland) The Atlantic puffin (Fratercula arctica).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The painted bunting (Passerina ciris).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The red-cowled cardinal (Paroaria dominicana).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "Alexandria",
            "Bishop",
            "Orthodox",
            "Alexandria",
            "Bishop",
            "Patriarch",
            "Bishop of Rome",
            "Patriarch",
            "Rome",
            "Vicar of Christ",
            "Guy Fawkes Day",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To act as or like a pope.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To convert to Roman Catholicism.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(alcoholic beverages) Any mulled wine (traditionally including tokay) considered similar and superior to bishop.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The whippoorwill (Caprimulgus vociferus).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The nighthawk (Chordeiles minor).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pop",
        "https://en.wiktionary.org/wiki/pope",
        "https://en.wiktionary.org/wiki/popes",
      ],
    },
  ],
  chins: [
    {
      word: "chins",
      phonetic: "/tʃɪnz/",
      phonetics: [{ text: "/tʃɪnz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The bottom of a face, (specifically) the typically jutting jawline below the mouth.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Talk.", synonyms: [], antonyms: [] },
            { definition: "A lie, a falsehood.", synonyms: [], antonyms: [] },
            {
              definition:
                "The ability to withstand being punched in the chin without being knocked out.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The lower part of the front of an aircraft, below the nose.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The bottom part of a mobile phone, below the screen.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["mentum"],
          antonyms: ["glass jaw"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To talk.", synonyms: [], antonyms: [] },
            {
              definition: "To talk to or with (someone).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To perform a chin-up (exercise in which one lifts one's own weight while hanging from a bar).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To punch or hit (someone)'s chin (part of the body).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To put or hold (a musical instrument) up to one's chin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To turn on or operate (a device) using one's chin; to select (a particular setting) using one's chin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To put one's chin on (something).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To indicate or point toward (someone or something) with one's chin.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["gab"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A chinchilla.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/chin",
        "https://en.wiktionary.org/wiki/chins",
      ],
    },
  ],
  smock: [
    {
      word: "smock",
      phonetic: "/smɒk/",
      phonetics: [
        { text: "/smɒk/", audio: "" },
        { text: "/smɑk/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A type of undergarment worn by women; a shift or slip.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A blouse; a smock frock.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A loose garment worn as protection by a painter, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To provide with, or clothe in, a smock or a smock frock.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To apply smocking.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of or pertaining to a smock; resembling a smock",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Hence, of or pertaining to a woman.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/smock"],
    },
  ],
  tines: [
    {
      word: "tines",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A spike or point on an implement or tool, especially a prong of a fork or a tooth of a comb.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A small branch, especially on an antler or horn.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A wild vetch or tare.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tine",
        "https://en.wiktionary.org/wiki/tines",
      ],
    },
  ],
  guise: [
    {
      word: "guise",
      phonetic: "/ˈɡaɪz/",
      phonetics: [
        {
          text: "/ˈɡaɪz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/guise-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651006",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                'Customary way of speaking or acting; fashion, manner, practice (often used formerly in such phrases as "at his own guise"; that is, in his own fashion, to suit himself.)',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "External appearance in manner or dress; appropriate indication or expression; garb; shape.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Misleading appearance; cover, cloak.",
              synonyms: [],
              antonyms: [],
              example: "Under the guise of patriotism",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To dress.", synonyms: [], antonyms: [] },
            {
              definition:
                "To act as a guiser; to go dressed up in a parade etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/guise"],
    },
  ],
  grits: [
    {
      word: "grits",
      phonetic: "/ɡɹɪts/",
      phonetics: [{ text: "/ɡɹɪts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(usually in the plural) Husked but unground oats.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(usually in the plural) Coarsely ground corn or hominy used as porridge.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/grit",
        "https://en.wiktionary.org/wiki/grits",
      ],
    },
    {
      word: "grits",
      phonetic: "/ɡɹɪts/",
      phonetics: [{ text: "/ɡɹɪts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "Apparently only in grit one's teeth: to clench, particularly in reaction to pain or anger.",
              synonyms: [],
              antonyms: [],
              example: "He has a sleeping disorder and grits his teeth.",
            },
            { definition: "To cover with grit.", synonyms: [], antonyms: [] },
            {
              definition:
                "To give forth a grating sound, like sand under the feet; to grate; to grind.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(usually in the plural) Husked but unground oats.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(usually in the plural) Coarsely ground corn or hominy used as porridge.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/grit",
        "https://en.wiktionary.org/wiki/grits",
      ],
    },
  ],
  junks: [
    {
      word: "junks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To throw away.", synonyms: [], antonyms: [] },
            {
              definition:
                "To find something for very little money (meaning derived from the term junk shop)",
              synonyms: [],
              antonyms: [],
              example:
                '(On Facebook, a record collector wrote:) "The newest addition to my Annette Hanshaw collection, I junked this beautiful flawless E-copy within walking distance from my house."',
            },
          ],
          synonyms: [
            "bin",
            "chuck",
            "chuck away",
            "chuck out",
            "discard",
            "dispose",
            "ditch",
            "dump",
            "scrap",
            "throw away",
            "throw out",
            "toss",
            "trash",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A Chinese sailing vessel.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/junk",
        "https://en.wiktionary.org/wiki/junks",
      ],
    },
  ],
  shoal: [
    {
      word: "shoal",
      phonetic: "/ʃɒʊl/",
      phonetics: [
        { text: "/ʃɒʊl/", audio: "" },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shoal-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780371",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A sandbank or sandbar creating a shallow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A shallow in a body of water.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["sandbank", "sandbar"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To arrive at a shallow (or less deep) area.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause a shallowing; to come to a more shallow part of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To become shallow.",
              synonyms: [],
              antonyms: [],
              example: "The colour of the water shows where it shoals.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Shallow.",
              synonyms: [],
              antonyms: [],
              example: "shoal water",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shoal"],
    },
    {
      word: "shoal",
      phonetic: "/ʃɒʊl/",
      phonetics: [
        { text: "/ʃɒʊl/", audio: "" },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shoal-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780371",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Any large number of persons or things.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(collective) A large number of fish (or other sea creatures) of the same species swimming together.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["school"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To collect in a shoal; to throng.",
              synonyms: [],
              antonyms: [],
              example: "The fish shoaled about the place.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shoal"],
    },
  ],
  cache: [
    {
      word: "cache",
      phonetic: "/kæɪʃ/",
      phonetics: [
        {
          text: "/kæɪʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cache-1-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=35269981",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cache-1-ca.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=575811",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
        {
          text: "/kæʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cache-1-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=501263",
        },
        {
          text: "/kæʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cache-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=453976",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A store of things that may be required in the future, which can be retrieved rapidly, protected or hidden in some way.",
              synonyms: [],
              antonyms: [],
              example:
                "Members of the 29-man Discovery team laid down food caches to allow the polar team to travel light, hopping from food cache to food cache on their return journey.",
            },
            {
              definition:
                "A fast temporary storage where recently or frequently used information is stored to avoid having to reload it from a slower storage medium.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(geocaching) A container containing treasure in a global treasure-hunt game.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To place in a cache.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cache"],
    },
  ],
  tapir: [
    {
      word: "tapir",
      phonetic: "/ˈteɪpiːə(ɹ)/",
      phonetics: [
        {
          text: "/ˈteɪpiːə(ɹ)/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tapir-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=81466730",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any one of the species of large odd-toed ungulates of the taxonomic family Tapiridae with a long prehensile upper lip, of which all four surviving species are in genus Tapirus.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bush cow"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tapir"],
    },
  ],
  atoll: [
    {
      word: "atoll",
      phonetic: "/əˈtɒl/",
      phonetics: [
        { text: "/əˈtɒl/", audio: "" },
        { text: "/əˈtɑl/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A type of island consisting of a ribbon reef that nearly or entirely surrounds a lagoon and supports, in most cases, one to many islets on the reef platform. Atolls have a unique geology, so not all islands with a reef and a lagoon are atolls",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/atoll"],
    },
  ],
  deity: [
    {
      word: "deity",
      phonetic: "/ˈdeɪ.ɪ.tɪ/",
      phonetics: [
        { text: "/ˈdeɪ.ɪ.tɪ/", audio: "" },
        { text: "/ˈdeɪ.ə.ti/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A supernatural divine being; a god or goddess.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The state, position, or fact of being a god or God. [from 14th c.]",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A celestial being inferior to a supreme God but superior to man.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The study of religion or religions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A type of confectionery made with egg whites, corn syrup, and white sugar.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "deity",
            "godhead",
            "godhood",
            "godliness",
            "godship",
            "godlore",
            "theology",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/deity",
        "https://en.wiktionary.org/wiki/divinity",
      ],
    },
  ],
  toils: [
    {
      word: "toils",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Labour, work, especially of a grueling nature.",
              synonyms: ["derve", "drudgery", "swink"],
              antonyms: [],
            },
            { definition: "Trouble, strife.", synonyms: [], antonyms: [] },
            {
              definition:
                "(usually in plural) A net or snare; any thread, web, or string spread for taking prey.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["derve", "drudgery", "swink"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To labour; work.", synonyms: [], antonyms: [] },
            { definition: "To struggle.", synonyms: [], antonyms: [] },
            {
              definition: "To work (something); often with out.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To weary through excessive labour.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/toil",
        "https://en.wiktionary.org/wiki/toils",
      ],
    },
  ],
  spree: [
    {
      word: "spree",
      phonetic: "/spɹiː/",
      phonetics: [{ text: "/spɹiː/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A merry frolic; especially, a drinking frolic.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Uninhibited activity.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["carousal"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To engage in a spree.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["carouse"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spree"],
    },
  ],
  mocks: [
    {
      word: "mocks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An imitation, usually of lesser quality.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Mockery, the act of mocking.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A practice exam set by an educating institution to prepare students for an important exam.",
              synonyms: [],
              antonyms: [],
              example:
                "He got a B in his History mock, but improved to an A in the exam.",
            },
            {
              definition: "A mockup or prototype.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To mimic, to simulate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To create an artistic representation of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make fun of by mimicking, to taunt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To tantalise, and disappoint the hopes of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To create a mockup or prototype of.",
              synonyms: [],
              antonyms: [],
              example: "What's the best way to mock a database layer?",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mock",
        "https://en.wiktionary.org/wiki/mocks",
      ],
    },
  ],
  scans: [
    {
      word: "scans",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Close investigation.", synonyms: [], antonyms: [] },
            {
              definition: "An instance of scanning.",
              synonyms: [],
              antonyms: [],
              example: "The operators vacated the room during the scan.",
            },
            {
              definition: "The result or output of a scanning process.",
              synonyms: [],
              antonyms: [],
              example: "The doctors looked at the scans and made a diagnosis.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To examine sequentially, carefully, or critically; to scrutinize; to behold closely.",
              synonyms: [],
              antonyms: [],
              example:
                "She scanned the passage carefully but could not find what she was looking for.",
            },
            {
              definition: "To look about for; to look over quickly.",
              synonyms: [],
              antonyms: [],
              example: "He scanned the horizon.",
            },
            {
              definition:
                "To create a digital copy of an image using a scanner.",
              synonyms: [],
              antonyms: [],
              example: "Pencil drawings don't scan very well.",
            },
            {
              definition: "To read with an electronic device.",
              synonyms: [],
              antonyms: [],
              example: "to scan a barcode; to scan a QR code",
            },
            {
              definition: "To mount by steps; to go through with step by step.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To read or mark so as to show a specific meter.",
              synonyms: [],
              antonyms: [],
              example: "to scan verse",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/scan",
        "https://en.wiktionary.org/wiki/scans",
      ],
    },
  ],
  shorn: [
    {
      word: "shorn",
      phonetic: "/ʃɔːn/",
      phonetics: [
        {
          text: "/ʃɔːn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shorn-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88681575",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ʃɔɹn/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cut, originally with a sword or other bladed weapon, now usually with shears, or as if using shears.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To remove the fleece from a sheep etc by clipping.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To deform because of forces pushing in opposite directions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To transform by displacing every point in a direction parallel to some given line by a distance proportional to the point’s distance from the line.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make a vertical cut in the coal.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To reap, as grain.", synonyms: [], antonyms: [] },
            {
              definition: "To deprive of property; to fleece.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/shear",
        "https://en.wiktionary.org/wiki/shorn",
      ],
    },
  ],
  revel: [
    {
      word: "revel",
      phonetic: "/ˈɹɛv.əl/",
      phonetics: [
        { text: "/ˈɹɛv.əl/", audio: "" },
        { text: "/ˈɹɛv.əl/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An instance of merrymaking; a celebration.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A kind of dance.", synonyms: [], antonyms: [] },
            { definition: "A wake for the dead.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make merry; to have a happy, lively time.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To take delight (in something).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["carouse", "celebrate"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/revel"],
    },
    {
      word: "revel",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The outer side of a window or door frame; the jamb.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A revelation; an uncovering of what was hidden.",
              synonyms: [],
              antonyms: [],
              example:
                "The comedian had been telling us about his sleep being disturbed by noise. Then came the reveal: he was sleeping on a bed in a department store.",
            },
            {
              definition:
                "(obsolete in the US) The side of an opening for a window, doorway, or the like, between the door frame or window frame and the outer surface of the wall; or, where the opening is not filled with a door, etc., the whole thickness of the wall; the jamb.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["jamb", "revel"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To draw back; to retract.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/reveal",
        "https://en.wiktionary.org/wiki/revel",
      ],
    },
  ],
  raven: [
    {
      word: "raven",
      phonetic: "/ˈɹeɪvən/",
      phonetics: [
        {
          text: "/ˈɹeɪvən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/raven-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1755213",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of several, generally large and lustrous black species of birds in the genus Corvus, especially the common raven, Corvus corax.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A jet-black colour.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of the color of the raven; jet-black",
              synonyms: [],
              antonyms: [],
              example: "She was a tall, sophisticated, raven-haired beauty.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/raven"],
    },
    {
      word: "raven",
      phonetic: "/ˈɹævən/",
      phonetics: [
        {
          text: "/ˈɹævən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/raven-2-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88882576",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Rapine; rapacity.", synonyms: [], antonyms: [] },
            {
              definition: "Prey; plunder; food obtained by violence.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To obtain or seize by violence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To devour with great eagerness.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To prey on with rapacity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To show rapacity; to be greedy (for something).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/raven"],
    },
  ],
  hoary: [
    {
      word: "hoary",
      phonetic: "/hɔː.ɹi/",
      phonetics: [
        { text: "/hɔː.ɹi/", audio: "" },
        {
          text: "/hɔɹ.i/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hoary-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=4233590",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "White, whitish, or greyish-white.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "White or grey with age.",
              synonyms: [],
              antonyms: [],
              example: "The old man bowed his hoary head in acquiescence.",
            },
            {
              definition: "Of a pale silvery grey.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Covered with short, dense, greyish white hairs; canescent.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Remote in time past.", synonyms: [], antonyms: [] },
            { definition: "Moldy; mossy; musty.", synonyms: [], antonyms: [] },
            { definition: "Old or old-fashioned.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "canescent",
            "aged",
            "ancient",
            "olden",
            "bygone",
            "foregone",
            "grey-haired",
            "grizzled",
            "grizzly",
            "silver-haired",
            "silvery-haired",
            "white-haired",
            "albescent",
            "griseous",
            "whity",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hoary"],
    },
  ],
  reels: [
    {
      word: "reels",
      phonetic: "/ɹiːlz/",
      phonetics: [{ text: "/ɹiːlz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A shaky or unsteady gait.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A lively dance originating in Scotland; also, the music of this dance; often called a Scottish (or Scotch) reel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A kind of spool, turning on an axis, on which yarn, threads, lines, or the like, are wound.",
              synonyms: [],
              antonyms: [],
              example: "a garden reel",
            },
            {
              definition:
                "A machine on which yarn is wound and measured into lays and hanks, —-- for cotton or linen it is fifty-four inches in circuit; for worsted, thirty inches.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A device consisting of radial arms with horizontal stats, connected with a harvesting machine, for holding the stalks of grain in position to be cut by the knives.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A short compilation of sample film work used as a demonstrative resume in the entertainment industry.",
              synonyms: ["showreel"],
              antonyms: [],
            },
          ],
          synonyms: ["showreel"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To wind on a reel.", synonyms: [], antonyms: [] },
            {
              definition: "To spin or revolve repeatedly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To unwind, to bring or acquire something by spinning or winding something else.",
              synonyms: [],
              antonyms: [],
              example:
                "He reeled off some tape from the roll and sealed the package.",
            },
            {
              definition:
                "To walk shakily or unsteadily; to stagger; move as if drunk or not in control of oneself.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(with back) To back off or step away unsteadily and quickly.",
              synonyms: [],
              antonyms: [],
              example: "He reeled back from the punch.",
            },
            {
              definition: "To make or cause to reel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To have a whirling sensation; to be giddy.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To be in shock.", synonyms: [], antonyms: [] },
            { definition: "To roll.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/reel",
        "https://en.wiktionary.org/wiki/reels",
      ],
    },
  ],
  scuff: [
    {
      word: "scuff",
      phonetic: "/skʌf/",
      phonetics: [{ text: "/skʌf/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(sometimes attributive) A mark left by scuffing or scraping.",
              synonyms: [],
              antonyms: [],
              example: "Someone left scuff marks in the sand.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To scrape the feet while walking.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To hit lightly, to brush against.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To mishit (a shot on a ball) due to poor contact with the ball.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scuff"],
    },
    {
      word: "scuff",
      phonetic: "/skʌf/",
      phonetics: [{ text: "/skʌf/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A scurf; a scale.", synonyms: [], antonyms: [] },
            {
              definition: "The back part of the neck; the scruff.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scuff"],
    },
  ],
  mimic: [
    {
      word: "mimic",
      phonetic: "/ˈmɪm.ɪk/",
      phonetics: [
        {
          text: "/ˈmɪm.ɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mimic-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50279078",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A person who practices mimicry, or mime.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An imitation.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To imitate, especially in order to ridicule.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To take on the appearance of another, for protection or camouflage.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Pertaining to mimicry; imitative.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Mock, pretended.", synonyms: [], antonyms: [] },
            {
              definition:
                "Imitative; characterized by resemblance to other forms; applied to crystals which by twinning resemble simple forms of a higher grade of symmetry.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mimic"],
    },
  ],
  weedy: [
    {
      word: "weedy",
      phonetic: "/ˈwiːdi/",
      phonetics: [{ text: "/ˈwiːdi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Abounding with weeds.",
              synonyms: [],
              antonyms: [],
              example: "a weedy garden",
            },
            {
              definition: "Of, relating to or resembling weeds.",
              synonyms: ["weedlike"],
              antonyms: [],
            },
            { definition: "Consisting of weeds.", synonyms: [], antonyms: [] },
            {
              definition:
                "Characteristic of a plant that grows rapidly and spreads invasively, and which grows opportunistically in cracks of sidewalks and disturbed areas.",
              synonyms: [],
              antonyms: [],
              example: "a weedy species",
            },
            {
              definition: "(of a person or animal) Small and weak.",
              synonyms: ["scraggy", "ungainly"],
              antonyms: [],
              example: "a weedy lad",
            },
            {
              definition: "Lacking power or effectiveness.",
              synonyms: ["feeble"],
              antonyms: [],
              example: "a weedy attempt",
            },
          ],
          synonyms: ["feeble", "scraggy", "ungainly", "weedlike"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/weedy"],
    },
  ],
  corny: [
    {
      word: "corny",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/corny-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79267987",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈkɔːni/", audio: "" },
        { text: "/ˈkɔɹni/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Boring and unoriginal.",
              synonyms: [],
              antonyms: [],
              example: "The duct tape and wire were a pretty corny solution.",
            },
            {
              definition: "Hackneyed or excessively sentimental.",
              synonyms: [],
              antonyms: [],
              example:
                'He sent a bouquet of twelve red roses and a card: "Roses are red, Violets are blue, Sugar is sweet, And so are you." How corny is that!',
            },
            {
              definition:
                "Producing corn or grain; furnished with grains of corn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Containing corn; tasting well of malt.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Tipsy; drunk", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "drunkish",
            "squiffy",
            "campy",
            "cheesy",
            "kitsch",
            "kitschy",
            "schlocky",
            "schmaltzy",
            "tacky",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/corny"],
    },
    {
      word: "corny",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/corny-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79267987",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈkɔːni/", audio: "" },
        { text: "/ˈkɔɹni/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Strong, stiff, or hard, like a horn; resembling horn.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/corny"],
    },
  ],
  truer: [
    {
      word: "truer",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(of a statement) Conforming to the actual state of reality or fact; factually correct.",
              synonyms: [],
              antonyms: [],
              example: "This is a true story.",
            },
            {
              definition: "Conforming to a rule or pattern; exact; accurate.",
              synonyms: [],
              antonyms: [],
              example: "a true copy;   a true likeness of the original",
            },
            {
              definition:
                "Of the state in Boolean logic that indicates an affirmative or positive result.",
              synonyms: [],
              antonyms: [],
              example:
                '"A and B" is true if and only if "A" is true and "B" is true.',
            },
            {
              definition: "Loyal, faithful.",
              synonyms: [],
              antonyms: [],
              example: "He’s turned out to be a true friend.",
            },
            {
              definition: "Genuine; legitimate.",
              synonyms: [],
              antonyms: [],
              example: "The true king has returned!",
            },
            {
              definition:
                "Used in the designation of group of species, or sometimes a single species, to indicate that it belongs to the clade its common name (which may be more broadly scoped in common speech) is restricted to in technical speech, or to distinguish it from a similar species, the latter of which may be called false.",
              synonyms: [],
              antonyms: [],
              example:
                "true blusher (Amanita rubescens, as distinguished from the false blusher, Amanita pantherina)",
            },
            {
              definition:
                "(of an aim or missile in archery, shooting, golf etc.) Accurate; following a path toward the target.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Fair, unbiased, not loaded.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Of a literary genre) based on actual historical events.",
              synonyms: [],
              antonyms: [],
              example: "true crime",
            },
          ],
          synonyms: [],
          antonyms: ["false", "untrue"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/true",
        "https://en.wiktionary.org/wiki/truer",
      ],
    },
  ],
  rouge: [
    {
      word: "rouge",
      phonetic: "/ɹuːʒ/",
      phonetics: [
        {
          text: "/ɹuːʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rouge-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=720505",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Red or pink makeup to add colour to the cheeks; blusher.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any reddish pink colour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A single point awarded when a team kicks the ball out of its opponent's end zone, or when a kicked ball becomes dead within the non-kicking team's end zone. Etymology uncertain; it is thought that in the early years of the sport, a red flag indicated that a single had been scored. (This scoring term is not often used in Canada, with the term single being more commonly used.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "In the Eton wall game, a scrummage, melée.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In the Eton College field game, a scoring move accomplished by touching the ball down behind the opponents' goal-line (somewhat similar to the try in rugby). Originally, the player who scored the rouge had a chance to kick a goal, and the rouge was used as a tie-breaker if an equal number of goals was scored by each side. In the contemporary Eton College field game, a five-point score is awarded for kicking the ball so that it deflects off one of the opposing players and goes beyond the opposition's end of the pitch, and then touching the ball.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'From 1862 to 1868, a similar scoring move in Sheffield rules football. From 1862 to 1867, accomplished by touching the ball down after it had been kicked between two "rouge flags" either side of the goal. From 1867-1868, awarded for kicking the ball between the rouge flags and under the crossbar.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A red amorphous powder consisting of ferric oxide, used in polishing and as a cosmetic; crocus; jeweller's rouge.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["blush"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To apply rouge (makeup).",
              synonyms: [],
              antonyms: [],
              example: "She rouged her face before setting out for the party.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of a reddish pink colour.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rouge"],
    },
  ],
  ember: [
    {
      word: "ember",
      phonetic: "/ˈɛm.bəː/",
      phonetics: [
        { text: "/ˈɛm.bəː/", audio: "" },
        { text: "/ˈɛm.bɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A glowing piece of coal or wood.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Smoldering ash.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ember"],
    },
    {
      word: "ember",
      phonetic: "/ˈɛm.bəː/",
      phonetics: [
        { text: "/ˈɛm.bəː/", audio: "" },
        { text: "/ˈɛm.bɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Making a circuit of the year or the seasons; recurring in each quarter of the year, as certain religious days set apart for fasting and prayer.",
              synonyms: [],
              antonyms: [],
              example: "ember days",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ember"],
    },
  ],
  floes: [
    {
      word: "floes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A low, flat mass of floating ice.",
              synonyms: ["ice floe"],
              antonyms: [],
            },
          ],
          synonyms: ["ice floe"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/floe",
        "https://en.wiktionary.org/wiki/floes",
      ],
    },
  ],
  torso: [
    {
      word: "torso",
      phonetic: "/ˈtɔː.səʊ/",
      phonetics: [
        { text: "/ˈtɔː.səʊ/", audio: "" },
        {
          text: "/ˈtɔɹ.soʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/torso-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769543",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The main part of the (human) body that extends from the neck to the groin, excluding the head and limbs.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["trunk"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/torso"],
    },
  ],
  wipes: [
    {
      word: "wipes",
      phonetic: "/waɪps/",
      phonetics: [{ text: "/waɪps/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act of wiping something.",
              synonyms: [],
              antonyms: [],
              example: "multiple wipes of a computer's hard disk",
            },
            {
              definition:
                "A soft piece of cloth or cloth-like material used for wiping.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A kind of film transition where one shot replaces another by travelling from one side of the frame to another or with a special shape.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sarcastic remark; a reproof, a jibe.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To move an object over, maintaining contact, with the intention of removing some substance from the surface. (Compare rub.)",
              synonyms: [],
              antonyms: [],
              example:
                "I wiped the sweat from my brow with the back of my hand.",
            },
            {
              definition:
                "To remove by rubbing; to rub off; to obliterate; usually followed by away, off, or out.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cheat; to defraud; to trick; usually followed by out.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To erase.",
              synonyms: [],
              antonyms: [],
              example: "I accidentally wiped my hard drive.",
            },
            {
              definition:
                "To make (a joint, as between pieces of lead pipe), by surrounding the junction with a mass of solder, applied in a plastic condition by means of a rag with which the solder is shaped by rubbing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To remove an expression from one's face.",
              synonyms: [],
              antonyms: [],
              example:
                "You should wipe that smirk off your face before the boss comes in.",
            },
            { definition: "To deperm (a ship).", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A lapwing, especially a northern lapwing (Vanellus vanellus).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An instance of all members of a party dying in a single campaign, event, or battle; a wipeout.",
              synonyms: ["TPK"],
              antonyms: [],
            },
          ],
          synonyms: ["TPK"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To have all members of a party die in a single campaign, event, or battle; to be wiped out.",
              synonyms: [],
              antonyms: [],
              example:
                "If you try to fight that boss underprepared, you're definitely gonna wipe.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wipe",
        "https://en.wiktionary.org/wiki/wipes",
      ],
    },
  ],
  edict: [
    {
      word: "edict",
      phonetic: "/ˈiː.dɪkt/",
      phonetics: [
        {
          text: "/ˈiː.dɪkt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/edict-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=93692000",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A proclamation of law or other authoritative command.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/edict"],
    },
  ],
  sulky: [
    {
      word: "sulky",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A low two-wheeled cart, used in harness racing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any carriage seating only the driver.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Silent and withdrawn after being upset",
              synonyms: ["morose", "sullen"],
              antonyms: [],
              example: "the sulky child",
            },
          ],
          synonyms: ["morose", "sullen"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sulky"],
    },
  ],
  recur: [
    {
      word: "recur",
      phonetic: "/ɹɪˈkɜː(ɹ)/",
      phonetics: [{ text: "/ɹɪˈkɜː(ɹ)/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To have recourse (to) someone or something for assistance, support etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To happen again.",
              synonyms: [],
              antonyms: [],
              example:
                "The theme of the prodigal son recurs later in the third act.",
            },
            { definition: "To recurse.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["repeat"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/recur"],
    },
  ],
  groin: [
    {
      word: "groin",
      phonetic: "/ɡɹɔɪn/",
      phonetics: [
        {
          text: "/ɡɹɔɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/groin-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780119",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The crease or depression of the human body at the junction of the trunk and the thigh, together with the surrounding region.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The area adjoining this fold or depression.",
              synonyms: [],
              antonyms: [],
              example: "He pulled a muscle in his groin.",
            },
            {
              definition:
                "The projecting solid angle formed by the meeting of two vaults",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The genitals.",
              synonyms: [],
              antonyms: [],
              example: "He got kicked in the groin and was writhing in pain.",
            },
            {
              definition: "The surface formed by two such vaults.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To deliver a blow to the genitals of.",
              synonyms: [],
              antonyms: [],
              example: "In the scrum he somehow got groined.",
            },
            { definition: "To build with groins.", synonyms: [], antonyms: [] },
            {
              definition: "(literary) To hollow out, to excavate.",
              synonyms: [],
              antonyms: [],
              example:
                "'Down some profound dull tunnel, long since scooped / Through granites which titanic wars had groined.' (From Strange Meeting by Wilfred Owen).",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/groin"],
    },
    {
      word: "groin",
      phonetic: "/ɡɹɔɪn/",
      phonetics: [
        {
          text: "/ɡɹɔɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/groin-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780119",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To grunt; to growl; to snarl; to murmur.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/groin"],
    },
    {
      word: "groin",
      phonetic: "/ɡɹɔɪn/",
      phonetics: [
        {
          text: "/ɡɹɔɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/groin-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780119",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An often wooden structure that projects from a coastline to prevent erosion, longshore drift etc.; a breakwater.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/groin",
        "https://en.wiktionary.org/wiki/groyne",
      ],
    },
  ],
  baste: [
    {
      word: "baste",
      phonetic: "/beɪst/",
      phonetics: [
        {
          text: "/beɪst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/baste-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=35847998",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To sew with long or loose stitches, as for temporary use, or in preparation for gathering the fabric.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/baste"],
    },
    {
      word: "baste",
      phonetic: "/beɪst/",
      phonetics: [
        {
          text: "/beɪst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/baste-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=35847998",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A basting; a sprinkling of drippings etc. in cooking.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To sprinkle flour and salt and drip butter or fat on, as on meat in roasting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) To coat over something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To mark (sheep, etc.) with tar.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/baste"],
    },
    {
      word: "baste",
      phonetic: "/beɪst/",
      phonetics: [
        {
          text: "/beɪst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/baste-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=35847998",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To beat with a stick; to cudgel.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/baste"],
    },
  ],
  kinks: [
    {
      word: "kinks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A convulsive fit of coughing or laughter; a sonorous indraft of breath; a whoop; a gasp of breath caused by laughing, coughing, or crying.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To laugh loudly.", synonyms: [], antonyms: [] },
            {
              definition: "To gasp for breath as in a severe fit of coughing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A tight curl, twist, or bend in a length of thin material, hair etc.",
              synonyms: [],
              antonyms: [],
              example:
                "We couldn't get enough water to put out the fire because of a kink in the hose.",
            },
            {
              definition:
                "A difficulty or flaw that is likely to impede operation, as in a plan or system.",
              synonyms: [],
              antonyms: [],
              example:
                "They had planned to open another shop downtown, but their plan had a few kinks.",
            },
            {
              definition:
                "An unreasonable notion; a crotchet; a whim; a caprice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Peculiarity or deviation in sexual behaviour or taste.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A positive 1-soliton solution to the Sine–Gordon equation",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["normophilia"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To form a kink or twist.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be formed into a kink or twist.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/kink",
        "https://en.wiktionary.org/wiki/kinks",
      ],
    },
  ],
  surer: [
    {
      word: "surer",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Physically secure and certain, non-failing, reliable.",
              synonyms: [],
              antonyms: [],
              example:
                "This investment is a sure thing.   The bailiff had a sure grip on the prisoner's arm.",
            },
            {
              definition: "Certain in one's knowledge or belief.",
              synonyms: [],
              antonyms: [],
              example:
                "He was sure she was lying.   I am sure of my eventual death.   John was acting sure of himself but in truth had doubts.",
            },
            {
              definition: "Certain to act or be a specified way.",
              synonyms: [],
              antonyms: [],
              example: "Be sure to lock the door when you leave.",
            },
            {
              definition: "Free from danger; safe; secure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Betrothed; engaged to marry.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "certain",
            "failsafe",
            "reliable",
            "sicker",
            "certain",
            "positive",
            "wis",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sure",
        "https://en.wiktionary.org/wiki/surer",
      ],
    },
  ],
  piggy: [
    {
      word: "piggy",
      phonetic: "/ˈpɪɡi/",
      phonetics: [
        {
          text: "/ˈpɪɡi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/piggy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241569",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(hypocoristic) A pig (the animal).",
              synonyms: [],
              antonyms: [],
              example: "This little piggy went to market.",
            },
            {
              definition: "(hypocoristic) A guinea pig.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(hypocoristic) A toe.",
              synonyms: [],
              antonyms: [],
              example: "He has such cute piggies!",
            },
            {
              definition: "(mildly) A pig, a greedy person.",
              synonyms: [],
              antonyms: [],
              example:
                "Can't you finish your dinner? You've been a piggy, haven't you?",
            },
            {
              definition: "A member of the police.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Greedy", synonyms: [], antonyms: [] },
            { definition: "Slovenly, dirty", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/piggy"],
    },
  ],
  moldy: [
    {
      word: "moldy",
      phonetic: "/ˈmoʊldi/",
      phonetics: [
        {
          text: "/ˈmoʊldi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/moldy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780226",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Covered with mold.", synonyms: [], antonyms: [] },
            { definition: "Stale or musty.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/moldy"],
    },
  ],
  franc: [
    {
      word: "franc",
      phonetic: "/fɹæŋk/",
      phonetics: [
        {
          text: "/fɹæŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/franc-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90572951",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A former unit of currency of France, Belgium and Luxembourg, replaced by the euro.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of several units of currency, some of which are multi-national (West African CFA Franc (XOF), Central African CFA Franc (XAF), the Swiss franc (CHF)) while others are national currencies.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/franc"],
    },
  ],
  liars: [
    {
      word: "liars",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "One who tells lies.", synonyms: [], antonyms: [] },
            {
              definition:
                "A swabber responsible for cleaning the outside parts of the ship rather than the cabins, a role traditionally assigned to a person caught telling a lie the previous week.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/liar",
        "https://en.wiktionary.org/wiki/liars",
      ],
    },
  ],
  inept: [
    {
      word: "inept",
      phonetic: "/ɪnˈɛpt/",
      phonetics: [
        { text: "/ɪnˈɛpt/", audio: "" },
        { text: "/ɪnˈɛpt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Not able to do something; not proficient; displaying incompetence.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Unfit; unsuitable.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: ["adept", "skillful"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/inept"],
    },
  ],
  gusty: [
    {
      word: "gusty",
      phonetic: "/ˈɡʌs.ti/",
      phonetics: [{ text: "/ˈɡʌs.ti/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "(of wind) Blowing in gusts; blustery; tempestuous.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension, metaphoric) Characterized by or occurring in instances of sudden strong expression",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(metaphoric) Bombastic, verbose.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gusty"],
    },
    {
      word: "gusty",
      phonetic: "/ˈɡʌs.ti/",
      phonetics: [{ text: "/ˈɡʌs.ti/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "With gusto", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gusty"],
    },
  ],
  facet: [
    {
      word: "facet",
      phonetic: "/ˈfæsɪt/",
      phonetics: [
        {
          text: "/ˈfæsɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/facet-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50279067",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Any one of the flat surfaces cut into a gem.",
              synonyms: [],
              antonyms: [],
              example:
                "This facet of the diamond was masterfully cut to enhance its value.",
            },
            {
              definition:
                "One among many similar or related, yet still distinct things.",
              synonyms: ["aspect"],
              antonyms: [],
              example:
                "The child's learning disability was only one facet of the problems contributing to his delinquency.",
            },
            {
              definition:
                "One of a series of things, such as steps in a project.",
              synonyms: [],
              antonyms: [],
              example:
                "We had just about completed the research facet of the project when the order came to cancel it.",
            },
            {
              definition:
                "One member of a compound eye, as found in insects and crustaceans.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A smooth circumscribed surface.",
              synonyms: [],
              antonyms: [],
              example: "the articular facet of a bone",
            },
            {
              definition:
                "Any of the small joints at each segment of the spine that provide stability and help guide motion",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The narrow plane surface between flutings of a column.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A face of codimension 1 of a polytope.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A criterion that can be used to sort and filter, such as the colour or size of products in an online store.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["aspect"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cut a facet into a gemstone.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/facet"],
    },
  ],
  jetty: [
    {
      word: "jetty",
      phonetic: "/ˈdʒɛti/",
      phonetics: [{ text: "/ˈdʒɛti/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A structure of wood or stone extended into the sea to influence the current or tide, or to protect a harbor or beach.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A wharf or dock extending from the shore.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A part of a building that jets or projects beyond the rest, and overhangs the wall below.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["breakwater", "mole", "pier"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To jut out; to project.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/jetty"],
    },
    {
      word: "jetty",
      phonetic: "/ˈdʒɛti/",
      phonetics: [{ text: "/ˈdʒɛti/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Made of jet, or like jet in color.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/jetty"],
    },
  ],
  equip: [
    {
      word: "equip",
      phonetic: "/ɪˈkwɪp/",
      phonetics: [
        {
          text: "/ɪˈkwɪp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/equip-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=83326239",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To supply with something necessary in order to carry out a specific action or task; to provide with (e.g. weapons, provisions, munitions, rigging)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To dress up; to array; to clothe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To prepare (someone) with a skill.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To equip oneself with (an item).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "apparel",
            "dight",
            "fit out",
            "kit out",
            "don",
            "dress",
            "put on",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/equip"],
    },
  ],
  leper: [
    {
      word: "leper",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A person who has leprosy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person who is shunned; a pariah.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/leper"],
    },
  ],
  slink: [
    {
      word: "slink",
      phonetic: "/slɪŋk/",
      phonetics: [{ text: "/slɪŋk/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A furtive sneaking motion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The young of an animal when born prematurely, especially a calf.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The meat of such a prematurely born animal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A bastard child, one born out of wedlock.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A thievish fellow; a sneak.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To sneak about furtively.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To give birth to an animal prematurely.",
              synonyms: [],
              antonyms: [],
              example: "a cow that slinks her calf",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Thin; lean", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/slink"],
    },
  ],
  soars: [
    {
      word: "soars",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The act of soaring.", synonyms: [], antonyms: [] },
            { definition: "An upward flight.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To fly high with little effort, like a bird.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To mount upward on wings, or as on wings.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To remain aloft by means of a glider or other unpowered aircraft.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To rise, especially rapidly or unusually high.",
              synonyms: [],
              antonyms: [],
              example:
                "The pump prices soared into new heights as the strike continued.",
            },
            {
              definition:
                "To rise in thought, spirits, or imagination; to be exalted in mood.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/soar",
        "https://en.wiktionary.org/wiki/soars",
      ],
    },
  ],
  cater: [
    {
      word: "cater",
      phonetic: "/ˈkeɪtə/",
      phonetics: [
        { text: "/ˈkeɪtə/", audio: "" },
        {
          text: "/ˈkeɪdɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cater-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=619788",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "Caterer", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To provide, particularly:",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person employed to obtain and maintain the storage of provisions, especially food.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person or company hired to provide and serve food, usually for a large group and at a location separate from where the food is prepared.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["cater", "manciple", "obsonator", "steward"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Someone who supplies what is needed, especially food.",
              synonyms: [],
              antonyms: [],
              example: "The merchants are the purveyors of fine selections.",
            },
            {
              definition:
                "An officer who provided provisions for the king's household.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A procurer; a pimp.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["grocer"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/acater",
        "https://en.wiktionary.org/wiki/cater",
        "https://en.wiktionary.org/wiki/caterer",
        "https://en.wiktionary.org/wiki/purveyor",
      ],
    },
    {
      word: "cater",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To place, set, move, or cut diagonally or rhomboidally.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            { definition: "Diagonally.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cater"],
    },
    {
      word: "cater",
      phonetic: "/ˈkatə/",
      phonetics: [
        { text: "/ˈkatə/", audio: "" },
        { text: "/ˈkeɪtəɹ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Four.", synonyms: [], antonyms: [] },
            {
              definition: "The four of cards or dice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A method of ringing nine bells in four pairs with a ninth tenor bell.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cater"],
    },
  ],
  dowry: [
    {
      word: "dowry",
      phonetic: "/ˈdaʊəɹi/",
      phonetics: [
        { text: "/ˈdaʊəɹi/", audio: "" },
        { text: "/ˈdaʊɹi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Payment, such as property or money, paid by the bride's family to the groom or his family at the time of marriage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Payment by the groom or his family to the bride's family: bride price.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Dower.", synonyms: [], antonyms: [] },
            {
              definition: "A natural gift or talent.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["bride price", "dower"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To bestow a dowry upon.",
              synonyms: [],
              antonyms: [],
              example:
                "1999, Judith Everard, Michael C. E. Jones, Charters Duchess Constance Br, Page xvi",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dowry"],
    },
  ],
  sided: [
    {
      word: "sided",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                'To ally oneself, be in an alliance, usually with "with" or rarely "in with"',
              synonyms: [],
              antonyms: [],
              example: "Which will you side with, good or evil?",
            },
            { definition: "To lean on one side.", synonyms: [], antonyms: [] },
            {
              definition:
                "To be or stand at the side of; to be on the side toward.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To suit; to pair; to match.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To work (a timber or rib) to a certain thickness by trimming the sides.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To furnish with a siding.",
              synonyms: [],
              antonyms: [],
              example: "to side a house",
            },
            {
              definition: "To provide with, as a side or accompaniment.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["take side"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To clear, tidy or sort.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/side",
        "https://en.wiktionary.org/wiki/sided",
      ],
    },
  ],
  yearn: [
    {
      word: "yearn",
      phonetic: "/jɜːn/",
      phonetics: [
        { text: "/jɜːn/", audio: "" },
        {
          text: "/jɝn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/yearn-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=33142673",
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To long, have a strong desire (for something).",
              synonyms: [],
              antonyms: [],
              example: "All I yearn for is a simple life.",
            },
            {
              definition:
                "To long for something in the past with melancholy, nostalgically.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To have strong feelings of love, sympathy, affection, etc. (toward someone).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be pained or distressed; to grieve; to mourn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To pain; to grieve; to vex.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/yearn"],
    },
    {
      word: "yearn",
      phonetic: "/jɜːn/",
      phonetics: [
        { text: "/jɜːn/", audio: "" },
        {
          text: "/jɝn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/yearn-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=33142673",
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To curdle, as milk.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/yearn"],
    },
    {
      word: "yearn",
      phonetic: "/jɜːn/",
      phonetics: [
        { text: "/jɜːn/", audio: "" },
        {
          text: "/jɝn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/yearn-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=33142673",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Yearning; yen.",
              synonyms: [],
              antonyms: [],
              example:
                '1917 August 12, "A YEARN FOR PEACE; Pan-Germanism Denounced" Sunday Times (Perth, WA) p.1',
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/yearn"],
    },
  ],
  decoy: [
    {
      word: "decoy",
      phonetic: "/ˈdiːkɔɪ/",
      phonetics: [
        { text: "/ˈdiːkɔɪ/", audio: "" },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/decoy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=823019",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person or object meant to lure somebody into danger.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A real or fake animal used by hunters to lure game.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To lead into danger by artifice; to lure into a net or snare; to entrap.",
              synonyms: [],
              antonyms: [],
              example:
                "to decoy troops into an ambush; to decoy ducks into a net",
            },
            {
              definition: "To act as, or use, a decoy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/decoy"],
    },
  ],
  taboo: [
    {
      word: "taboo",
      phonetic: "/tæˈbuː/",
      phonetics: [{ text: "/tæˈbuː/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An inhibition or ban that results from social custom or emotional aversion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in Polynesia) Something which may not be used, approached or mentioned because it is sacred.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To mark as taboo.", synonyms: [], antonyms: [] },
            { definition: "To ban.", synonyms: [], antonyms: [] },
            { definition: "To avoid.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Excluded or forbidden from use, approach or mention.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Culturally forbidden.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/taboo"],
    },
  ],
  ovals: [
    {
      word: "ovals",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An elongated round shape resembling an egg or ellipse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A thing having such a shape, such as an arena.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In a projective plane, a set of points such that no three are collinear and there is a unique tangent line at each point.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/oval",
        "https://en.wiktionary.org/wiki/ovals",
      ],
    },
  ],
  heals: [
    {
      word: "heals",
      phonetic: "/hiːlz/",
      phonetics: [{ text: "/hiːlz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A spell or ability that restores hit points or removes a status ailment.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Health", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make better from a disease, wound, etc.; to revive or cure.",
              synonyms: [],
              antonyms: [],
              example: "This bandage will heal your cut.",
            },
            {
              definition: "To become better or healthy again.",
              synonyms: [],
              antonyms: [],
              example: "Bandages allow cuts to heal.",
            },
            {
              definition:
                "To reconcile, as a breach or difference; to make whole; to free from guilt.",
              synonyms: [],
              antonyms: [],
              example: "to heal dissensions",
            },
          ],
          synonyms: ["get better", "recover", "cure", "make whole"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To hide, conceal, and keep secret, especially for a secret society (such as the masons).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                '(now especially in the phrase "hele in") To cover or conceal (a seedling, plant, roots, etc).',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/heal",
        "https://en.wiktionary.org/wiki/heals",
        "https://en.wiktionary.org/wiki/hele",
      ],
    },
  ],
  pleas: [
    {
      word: "pleas",
      phonetic: "/pliːz/",
      phonetics: [{ text: "/pliːz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An appeal, petition, urgent prayer or entreaty.",
              synonyms: [],
              antonyms: [],
              example: "a plea for mercy",
            },
            {
              definition: "An excuse; an apology.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That which is alleged or pleaded, in defense or in justification.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That which is alleged by a party in support of his cause.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An allegation of fact in a cause, as distinguished from a demurrer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The defendant’s answer to the plaintiff’s declaration and demand.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cause in court; a lawsuit; as, the Court of Common Pleas.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["plaidoyer"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To plead; to argue.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/plea",
        "https://en.wiktionary.org/wiki/pleas",
      ],
    },
  ],
  beret: [
    {
      word: "beret",
      phonetic: "/ˈbeɹ.eɪ/",
      phonetics: [
        { text: "/ˈbeɹ.eɪ/", audio: "" },
        {
          text: "/bəˈɹeɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/beret-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=21005562",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A type of round, brimless cap with a soft top and a headband to secure it to the head; usually culturally associated with France.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/beret"],
    },
  ],
  spilt: [
    {
      word: "spilt",
      phonetic: "/spɪlt/",
      phonetics: [
        {
          text: "/spɪlt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spilt-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80067080",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To drop something so that it spreads out or makes a mess; to accidentally pour.",
              synonyms: [],
              antonyms: [],
              example: "I spilled some sticky juice on the kitchen floor.",
            },
            {
              definition: "To spread out or fall out, as above.",
              synonyms: [],
              antonyms: [],
              example: "Some sticky juice spilled onto the kitchen floor.",
            },
            {
              definition: "To drop something that was intended to be caught.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To mar; to damage; to destroy by misuse; to waste.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be destroyed, ruined, or wasted; to come to ruin; to perish; to waste.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause to flow out and be lost or wasted; to shed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cover or decorate with slender pieces of wood, metal, ivory, etc.; to inlay.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To relieve a sail from the pressure of the wind, so that it can be more easily reefed or furled, or to lessen the strain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Australian politics) To open the leadership of a parliamentary party for re-election.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To reveal information to an uninformed party.",
              synonyms: [],
              antonyms: [],
              example: "He spilled his guts out to his new psychologist.",
            },
            {
              definition: "(of a knot). To come undone.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "That has been spilt.",
              synonyms: [],
              antonyms: [],
              example:
                "Don't cry over spilt milk; tears won't put it back in the glass.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/spill",
        "https://en.wiktionary.org/wiki/spilt",
      ],
    },
  ],
  gayly: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  rover: [
    {
      word: "rover",
      phonetic: "/ˈɹəʊvə(ɹ)/",
      phonetics: [
        {
          text: "/ˈɹəʊvə(ɹ)/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rover-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=94709431",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(usually in the plural) A randomly selected target.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One who roves, a wanderer, a nomad.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A vagabond, a tramp, an unsteady, restless person, one who by habit doesn't settle down or marry.",
              synonyms: [],
              antonyms: [],
              example:
                "She is a rover and dislikes any sort of ties, physical or emotional.",
            },
            {
              definition: "A vehicle for exploring extraterrestrial bodies.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Australian Rules football) A position that is one of three of a team's followers, who follow the ball around the ground. Formerly a position for short players, rovers in professional leagues are frequently over 183 cm (6').",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A defensive back position whose coverage responsibilities are a hybrid of those of a cornerback, safety and linebacker.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A ball which has passed through all the hoops and would go out if it hit the stake but is continued in play; also, the player of such a ball.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The tenth defensive player in slow-pitch softball.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A sort of arrow.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rover"],
    },
    {
      word: "rover",
      phonetic: "/ˈɹəʊvə(ɹ)/",
      phonetics: [
        {
          text: "/ˈɹəʊvə(ɹ)/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rover-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=94709431",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A pirate.", synonyms: [], antonyms: [] },
            { definition: "A pirate ship.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rover"],
    },
  ],
  endow: [
    {
      word: "endow",
      phonetic: "/ɛn-/",
      phonetics: [
        {
          text: "/ɛn-/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/endow-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=84371837",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ɪnˈdaʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To provide with a dower or a dowry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To give property to (someone) as a gift; specifically, to provide (a person or institution) with support in the form of a permanent fund of money or other benefits.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Followed by with, or rarely by of: to enrich or furnish with some faculty or quality.",
              synonyms: ["begift"],
              antonyms: [],
            },
            {
              definition:
                "Usually in the passive: to naturally furnish (with something).",
              synonyms: ["bless", "gift"],
              antonyms: [],
              example: "She was endowed with a beautiful voice.",
            },
          ],
          synonyms: ["begift", "bless", "gift"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/endow"],
    },
  ],
  pygmy: [
    {
      word: "pygmy",
      phonetic: "/pɪɡmiː/",
      phonetics: [
        {
          text: "/pɪɡmiː/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pygmy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780298",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(often capitalized) A member of one of various Ancient Equatorial African tribal peoples, notable for their very short stature.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A member of a race of dwarfs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any dwarfish person or thing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An insignificant person, at least in some respect.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dwarf", "midget"],
          antonyms: ["ettin", "giant"],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Relating or belonging to the Pygmy people.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Like a pygmy; unusually short or small for its kind.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dwarfish", "pygmyish"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pygmy"],
    },
  ],
  carat: [
    {
      word: "carat",
      phonetic: "/ˈkæɹ.ət/",
      phonetics: [{ text: "/ˈkæɹ.ət/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A unit of weight for precious stones and pearls, equivalent to 200 milligrams.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of several units of weight, varying from 189 to 212 mg, the weight of a carob seed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A measure of the purity of gold, pure gold being 24 carats.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/carat"],
    },
  ],
  abbey: [
    {
      word: "abbey",
      phonetic: "/ˈæb.i/",
      phonetics: [
        {
          text: "/ˈæb.i/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/abbey-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453810",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The office or dominion of an abbot or abbess.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A monastery or society of people, secluded from the world and devoted to religion and celibacy, which is headed by an abbot or abbess; also, the monastic building or buildings.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The church of a monastery.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A residence that was previously an abbatial building.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/abbey"],
    },
  ],
  vents: [
    {
      word: "vents",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An opening through which gases, especially air, can pass.",
              synonyms: [],
              antonyms: [],
              example: "the vent of a cask; the vent of a mould",
            },
            { definition: "A small aperture.", synonyms: [], antonyms: [] },
            {
              definition: "The opening of a volcano from which lava flows.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A verbalized frustration.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The excretory opening of lower orders of vertebrates.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A slit in the seam of a garment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The opening at the breech of a firearm, through which fire is communicated to the powder of the charge; touchhole.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In steam boilers, a sectional area of the passage for gases divided by the length of the same passage in feet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Opportunity of escape or passage from confinement or privacy; outlet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Emission; escape; passage to notice or expression; publication; utterance.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To allow gases to escape.",
              synonyms: [],
              antonyms: [],
              example: "The stove vents to the outside.",
            },
            {
              definition: "To allow to escape through a vent.",
              synonyms: [],
              antonyms: [],
              example: "Exhaust is vented to the outside.",
            },
            {
              definition: "To express a strong emotion.",
              synonyms: [],
              antonyms: [],
              example: "Can we talk? I need to vent.",
            },
            {
              definition: "To snuff; to breathe or puff out; to snort.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Ventriloquism.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To sell; to vend.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A baiting place; an inn.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Ventilation or ventilator.",
              synonyms: [],
              antonyms: [],
              example: "I have adjusted the vent settings.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To ventilate; to use a ventilator; to use ventilation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/vent",
        "https://en.wiktionary.org/wiki/vents",
      ],
    },
  ],
  waken: [
    {
      word: "waken",
      phonetic: "/ˈweɪkən/",
      phonetics: [
        {
          text: "/ˈweɪkən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/waken-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=94709670",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To wake or rouse from sleep.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To awaken; to cease to sleep; to be awakened; to stir.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/waken"],
    },
  ],
  chimp: [
    {
      word: "chimp",
      phonetic: "/tʃɪmp/",
      phonetics: [
        {
          text: "/tʃɪmp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chimp-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50279062",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To review each image on a digital camera after it is taken.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To get very excited when showing images on a digital camera.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A species of great ape in the genus Pan, native to Africa, and believed by biologists to be the closest extant relative to humans.",
              synonyms: ["chimp"],
              antonyms: [],
            },
          ],
          synonyms: ["chimp"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/chimp",
        "https://en.wiktionary.org/wiki/chimpanzee",
      ],
    },
  ],
  fumed: [
    {
      word: "fumed",
      phonetic: "/fjuːmd/",
      phonetics: [{ text: "/fjuːmd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To expose (something) to fumes; specifically, to expose wood, etc., to ammonia in order to produce dark tints.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To apply or offer incense to.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To emit fumes.", synonyms: [], antonyms: [] },
            {
              definition: "To pass off in fumes or vapours.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To express or feel great anger.",
              synonyms: [],
              antonyms: [],
              example:
                "He’s still fuming about the argument they had yesterday.",
            },
            {
              definition: "To be as in a mist; to be dulled and stupefied.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fume",
        "https://en.wiktionary.org/wiki/fumed",
      ],
    },
  ],
  sodas: [
    {
      word: "sodas",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Sodium bicarbonate (usually baking soda).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Sodium carbonate (usually washing soda).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Sodium in chemical combination.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Carbonated water (water impregnated with pressurised carbon dioxide, originally made with sodium bicarbonate).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(especially in the northeast) Any carbonated (usually sweet) soft drink.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(especially in the northeast) A glass, bottle or can of this drink.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The first card in the dealing box in the game of faro, which is discarded to leave 51 cards in play.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "carbonated",
            "coke",
            "drink",
            "fizz",
            "fizzy",
            "fizzy drink",
            "lemonade",
            "pop",
            "soda pop",
            "soft drink",
            "thirst-buster",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/soda",
        "https://en.wiktionary.org/wiki/sodas",
      ],
    },
  ],
  vinyl: [
    {
      word: "vinyl",
      phonetic: "/ˈvaɪ.nəl/",
      phonetics: [
        {
          text: "/ˈvaɪ.nəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/vinyl-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50014409",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The univalent radical CH2=CH−, derived from ethylene.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various compounds and substances containing the vinyl radical, especially various tough, flexible, shiny plastics.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Phonograph records as a medium.",
              synonyms: [],
              antonyms: [],
              example: "Many DJs prefer vinyl to CDs.",
            },
            { definition: "A phonograph record.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Containing the vinyl radical.",
              synonyms: ["vinylic"],
              antonyms: [],
            },
            {
              definition: "Made of polyvinyl chloride.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Pertaining to a phonograph record.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["vinylic"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/vinyl"],
    },
  ],
  clout: [
    {
      word: "clout",
      phonetic: "/klʌʊt/",
      phonetics: [
        { text: "/klʌʊt/", audio: "" },
        {
          text: "/klaʊt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/clout-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89032946",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Influence or effectiveness, especially political.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A blow with the hand.", synonyms: [], antonyms: [] },
            { definition: "A home run.", synonyms: [], antonyms: [] },
            {
              definition:
                "The center of the butt at which archers shoot; probably once a piece of white cloth or a nail head.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A swaddling cloth.", synonyms: [], antonyms: [] },
            {
              definition:
                "A cloth; a piece of cloth or leather; a patch; a rag.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An iron plate on an axletree or other wood to keep it from wearing; a washer.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A piece; a fragment.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To hit, especially with the fist.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cover with cloth, leather, or other material; to bandage, patch, or mend with a clout.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To stud with nails, as a timber, or a boot sole.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To guard with an iron plate, as an axletree.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To join or patch clumsily.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/clout"],
    },
    {
      word: "clout",
      phonetic: "/klʌʊt/",
      phonetics: [
        { text: "/klʌʊt/", audio: "" },
        {
          text: "/klaʊt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/clout-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89032946",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To form a clot or mass.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause to clot or form into a mass.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/clot",
        "https://en.wiktionary.org/wiki/clout",
      ],
    },
  ],
  wades: [
    {
      word: "wades",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An act of wading.",
              synonyms: [],
              antonyms: [],
              example:
                "We had to be careful during our dangerous wade across the river.}}",
            },
            {
              definition: "A ford; a place to cross a river.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To walk through water or something that impedes progress.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To progress with difficulty",
              synonyms: [],
              antonyms: [],
              example: "to wade through a dull book",
            },
            {
              definition:
                "To walk through (water or similar impediment); to pass through by wading",
              synonyms: [],
              antonyms: [],
              example: "wading swamps and rivers",
            },
            {
              definition: "To enter recklessly.",
              synonyms: [],
              antonyms: [],
              example: "to wade into a fight or a debate",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wade",
        "https://en.wiktionary.org/wiki/wades",
      ],
    },
  ],
  mites: [
    {
      word: "mites",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of many minute arachnids which, along with the ticks, comprise subclass Acarina (aka Acari).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small coin formerly circulated in England, rated at about a third of a farthing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A lepton, a small coin used in Palestine in the time of Christ.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A small weight; one twentieth of a grain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(sometimes used adverbially) Anything very small; a minute object; a very little quantity or particle",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(often used affectionately) A small or naughty person, or one you take pity on; rascal",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mite",
        "https://en.wiktionary.org/wiki/mites",
      ],
    },
  ],
  smirk: [
    {
      word: "smirk",
      phonetic: "/smɜːk/",
      phonetics: [
        {
          text: "/smɜːk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/smirk-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=94709442",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/smɝk/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An uneven, often crooked smile that is insolent, self-satisfied or scornful",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A forced or affected smile; a simper",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To smile in a way that is affected, smug, insolent or contemptuous.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["shit-eating grin", "simper"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Smart; spruce; affected; simpering",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/smirk"],
    },
  ],
  bores: [
    {
      word: "bores",
      phonetic: "/bɔːz/",
      phonetics: [
        { text: "/bɔːz/", audio: "" },
        { text: "/bɔɹz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A hole drilled or milled through something, or (by extension) its diameter.",
              synonyms: [],
              antonyms: [],
              example: "the bore of a cannon",
            },
            {
              definition:
                "The tunnel inside of a gun's barrel through which the bullet travels when fired, or (by extension) its diameter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A tool, such as an auger, for making a hole by boring.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A capped well drilled to tap artesian water. The place where the well exists.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One who inspires boredom or lack of interest; an uninteresting person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Something dull or uninteresting",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Calibre; importance.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To inspire boredom in somebody.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make a hole through something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make a hole with, or as if with, a boring instrument; to cut a circular hole by the rotary motion of a tool.",
              synonyms: [],
              antonyms: [],
              example: "An insect bores into a tree.",
            },
            {
              definition:
                "To form or enlarge (something) by means of a boring instrument or apparatus.",
              synonyms: [],
              antonyms: [],
              example:
                "to bore a steam cylinder or a gun barrel; to bore a hole",
            },
            {
              definition:
                "To make (a passage) by laborious effort, as in boring; to force a narrow and difficult passage through.",
              synonyms: [],
              antonyms: [],
              example: "to bore one's way through a crowd",
            },
            {
              definition:
                "To be pierced or penetrated by an instrument that cuts as it turns.",
              synonyms: [],
              antonyms: [],
              example: "This timber does not bore well.",
            },
            {
              definition:
                "To push forward in a certain direction with laborious effort.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a horse) To shoot out the nose or toss it in the air.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To fool; to trick.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: ["interest"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sudden and rapid flow of tide occuring in certain rivers and estuaries which rolls up as a wave.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["eagre"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bore",
        "https://en.wiktionary.org/wiki/bores",
      ],
    },
  ],
  bunny: [
    {
      word: "bunny",
      phonetic: "/ˈbʌni/",
      phonetics: [
        {
          text: "/ˈbʌni/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bunny-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88676877",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈbʌni/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A rabbit, especially a juvenile.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bunny girl: a nightclub waitress who wears a costume having rabbit ears and tail.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In basketball, an easy shot (i.e., one right next to the bucket) that is missed.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Easy or unchallenging.",
              synonyms: [],
              antonyms: [],
              example: "Let’s start on the bunny slope.",
            },
          ],
          synonyms: ["nursery"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bunny"],
    },
    {
      word: "bunny",
      phonetic: "/ˈbʌni/",
      phonetics: [
        {
          text: "/ˈbʌni/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bunny-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88676877",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈbʌni/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A swelling from a blow; a bump.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sudden enlargement or mass of ore, as opposed to a vein or lode.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bunny"],
    },
    {
      word: "bunny",
      phonetic: "/ˈbʌni/",
      phonetics: [
        {
          text: "/ˈbʌni/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bunny-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88676877",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈbʌni/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A culvert or short covered drain connecting two ditches.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A chine or gully formed by water running over the edge of a cliff; a wooded glen or small ravine opening through the cliff line to the sea.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any small drain or culvert.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A brick arch or wooden bridge, covered with earth across a drawn or carriage in a water-meadow, just wide enough to allow a hay-wagon to pass over.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A small pool of water.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bunny"],
    },
    {
      word: "bunny",
      phonetic: "/ˈbʌni/",
      phonetics: [
        {
          text: "/ˈbʌni/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bunny-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88676877",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈbʌni/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Bunny chow; a snack of bread filled with curry.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bunny"],
    },
    {
      word: "bunny",
      phonetic: "/ˈbʌni/",
      phonetics: [
        {
          text: "/ˈbʌni/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bunny-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88676877",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈbʌni/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Resembling a bun (small bread roll).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bunlike"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bunny"],
    },
  ],
  surly: [
    {
      word: "surly",
      phonetic: "/ˈsɜːli/",
      phonetics: [
        { text: "/ˈsɜːli/", audio: "" },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/surly-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=93777508",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Irritated, bad-tempered, unfriendly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Threatening, menacing, gloomy.",
              synonyms: [],
              antonyms: [],
              example: "The surly weather put us all in a bad mood.",
            },
            {
              definition: "Lordly, arrogant, supercilious.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "In an arrogant or supercilious manner.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/surly"],
    },
  ],
  frock: [
    {
      word: "frock",
      phonetic: "/fɹɒk/",
      phonetics: [
        {
          text: "/fɹɒk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/frock-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=91208459",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/fɹɑk/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A dress, a piece of clothing for a female, which consists of a skirt and a cover for the upper body.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An outer garment worn by priests and other clericals; a habit.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A sailor's jersey.", synonyms: [], antonyms: [] },
            {
              definition: "An undress regimental coat.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To clothe in a frock.", synonyms: [], antonyms: [] },
            { definition: "To make a cleric.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/frock"],
    },
    {
      word: "frock",
      phonetic: "/fɹɒk/",
      phonetics: [
        {
          text: "/fɹɒk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/frock-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=91208459",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/fɹɑk/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "A frog.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/frock"],
    },
  ],
  foray: [
    {
      word: "foray",
      phonetic: "/ˈfɒ.ɹeɪ/",
      phonetics: [
        { text: "/ˈfɒ.ɹeɪ/", audio: "" },
        { text: "/ˈfɔːɹ.eɪ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sudden or irregular incursion in border warfare; hence, any irregular incursion for war or spoils; a raid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A brief excursion or attempt, especially outside one's accustomed sphere.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To scour (an area or place) for food, treasure, booty etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To pillage; to ravage.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/foray"],
    },
  ],
  purer: [
    {
      word: "purer",
      phonetic: "/ˈpjʊəɹə(ɹ)/",
      phonetics: [{ text: "/ˈpjʊəɹə(ɹ)/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Free of flaws or imperfections; unsullied.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Free of foreign material or pollutants.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Free of immoral behavior or qualities; clean.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Mere; that and that only.",
              synonyms: [],
              antonyms: [],
              example: "That idea is pure madness!",
            },
            {
              definition:
                "(of a branch of science) Done for its own sake instead of serving another branch of science.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of a single, simple sound or tone; said of some vowels and the unaspirated consonants.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of sound) Without harmonics or overtones; not harsh or discordant.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["innocent"],
          antonyms: [
            "applied",
            "dirty",
            "flawed",
            "impure",
            "contaminated",
            "impure",
            "corrupt",
            "guilty",
            "sinful",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pure",
        "https://en.wiktionary.org/wiki/purer",
      ],
    },
  ],
  milks: [
    {
      word: "milks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A white liquid produced by the mammary glands of female mammals to nourish their young. From certain animals, especially cows, it is also called dairy milk and is a common food for humans as a beverage or used to produce various dairy products such as butter, cheese, and yogurt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A white (or whitish) liquid obtained from a vegetable source such as almonds, coconuts, oats, rice, and/or soy beans. Also called non-dairy milk.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An individual serving of milk.",
              synonyms: [],
              antonyms: [],
              example:
                "Table three ordered three milks. (Formally: The guests at table three ordered three glasses of milk.)",
            },
            {
              definition:
                "An individual portion of milk, such as found in a creamer, for tea and coffee.",
              synonyms: [],
              antonyms: [],
              example: "I take my tea with two milk(s) and two sugar.",
            },
            {
              definition: "The ripe, undischarged spat of an oyster.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Semen.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["cow milk", "cow's milk", "cowmilk"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To express milk from (a mammal, especially a cow).",
              synonyms: [],
              antonyms: [],
              example: "The farmer milked his cows.",
            },
            {
              definition: "To draw (milk) from the breasts or udder.",
              synonyms: [],
              antonyms: [],
              example: "to milk wholesome milk from healthy cows",
            },
            {
              definition: "To express any liquid (from any creature).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make excessive use of (a particular point in speech or writing, a source of funds, etc.); to exploit; to take advantage of (something).",
              synonyms: [],
              antonyms: [],
              example:
                "When the audience began laughing, the comedian milked the joke for more laughs.",
            },
            {
              definition:
                "(of an electrical storage battery) To give off small gas bubbles during the final part of the charging operation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To single-mindedly masturbate a male to ejaculation, especially for the amusement and/or satisfaction of the masturbator/trix rather than the person masturbated.",
              synonyms: [],
              antonyms: [],
              example:
                "Controlled milking can actually establish and consolidate a mistress’ dominance over her sub rather than diminish it.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/milk",
        "https://en.wiktionary.org/wiki/milks",
      ],
    },
  ],
  query: [
    {
      word: "query",
      phonetic: "/ˈkwɪə.ɹi/",
      phonetics: [
        { text: "/ˈkwɪə.ɹi/", audio: "" },
        { text: "/ˈkwɛ.ɹi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A question, an inquiry (US), an enquiry (UK).",
              synonyms: [],
              antonyms: [],
              example:
                "The teacher answered the student’s query concerning biosynthesis.",
            },
            { definition: "A question mark.", synonyms: [], antonyms: [] },
            {
              definition: "A set of instructions passed to a database.",
              synonyms: [],
              antonyms: [],
              example:
                "The database admin switched on query logging for debugging purposes.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To ask a question.", synonyms: [], antonyms: [] },
            { definition: "To ask, inquire.", synonyms: [], antonyms: [] },
            {
              definition: "To question or call into doubt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To pass a set of instructions to a database to retrieve information from it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To send a private message to (a user on IRC).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To send out a query letter.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/query"],
    },
  ],
  mired: [
    {
      word: "mired",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cause or permit to become stuck in mud; to plunge or fix in mud.",
              synonyms: ["bemire", "enmire"],
              antonyms: [],
              example: "to mire a horse or wagon",
            },
            { definition: "To sink into mud.", synonyms: [], antonyms: [] },
            { definition: "To weigh down.", synonyms: [], antonyms: [] },
            {
              definition: "To soil with mud or foul matter.",
              synonyms: ["bemire"],
              antonyms: [],
            },
          ],
          synonyms: ["bemire", "bemire", "enmire"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mire",
        "https://en.wiktionary.org/wiki/mired",
      ],
    },
    {
      word: "mired",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A unit of measurement for color temperature.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mired"],
    },
  ],
  blare: [
    {
      word: "blare",
      phonetic: "/blɛə(ɹ)/",
      phonetics: [
        {
          text: "/blɛə(ɹ)/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/blare-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=361383",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A loud sound.",
              synonyms: [],
              antonyms: [],
              example: "I can hardly hear you over the blare of the radio.",
            },
            {
              definition: "Dazzling, often garish, brilliance.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make a loud sound.",
              synonyms: [],
              antonyms: [],
              example: "The trumpet blaring in my ears gave me a headache.",
            },
            {
              definition:
                "To cause to sound like the blare of a trumpet; to proclaim loudly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/blare"],
    },
  ],
  froth: [
    {
      word: "froth",
      phonetic: "/fɹɑθ/",
      phonetics: [
        { text: "/fɹɑθ/", audio: "" },
        { text: "/fɹɒθ/", audio: "" },
        {
          text: "/fɹɔθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/froth-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769679",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Foam",
              synonyms: [],
              antonyms: [],
              example:
                "Froth is a very important feature of many types of coffee.",
            },
            {
              definition: "Unimportant events or actions; drivel",
              synonyms: [],
              antonyms: [],
              example:
                "Thousands of African children die each day: why do the newspapers continue to discuss unnecessary showbiz froth?",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To create froth in (a liquid).",
              synonyms: [],
              antonyms: [],
              example: "I like to froth my coffee for ten seconds exactly.",
            },
            {
              definition: "(of a liquid) To bubble.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To spit, vent, or eject, as froth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(literally) To spew saliva as froth; (figuratively) to rage, vent one's anger.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cover with froth.",
              synonyms: [],
              antonyms: [],
              example: "A horse froths his chain.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/froth"],
    },
  ],
  gruel: [
    {
      word: "gruel",
      phonetic: "/ɡɹuː(ə)l/",
      phonetics: [{ text: "/ɡɹuː(ə)l/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A thin, watery porridge, formerly eaten primarily by the poor and the ill.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gruel"],
    },
    {
      word: "gruel",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To exhaust; use up; disable; to punish.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gruel"],
    },
  ],
  navel: [
    {
      word: "navel",
      phonetic: "/ˈneɪvəl/",
      phonetics: [
        {
          text: "/ˈneɪvəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/navel-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50348390",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The indentation or bump remaining in the abdomen of mammals where the umbilical cord was attached before birth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The central part or point of anything; the middle.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A navel orange.", synonyms: [], antonyms: [] },
            {
              definition:
                "An eye on the underside of a carronade for securing it to a carriage.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["belly button", "bellybutton", "nave", "umbilicus"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/navel"],
    },
  ],
  paler: [
    {
      word: "paler",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Light in color.",
              synonyms: [],
              antonyms: [],
              example: "I have pale yellow wallpaper.",
            },
            {
              definition:
                "(of human skin) Having a pallor (a light color, especially due to sickness, shock, fright etc.).",
              synonyms: [],
              antonyms: [],
              example:
                "His face turned pale after hearing about his mother's death.",
            },
            {
              definition: "Feeble, faint.",
              synonyms: [],
              antonyms: [],
              example: "He is but a pale shadow of his former self.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pale",
        "https://en.wiktionary.org/wiki/paler",
      ],
    },
  ],
  puffy: [
    {
      word: "puffy",
      phonetic: "/ˈpʌfi/",
      phonetics: [{ text: "/ˈpʌfi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Swollen or inflated in shape, as if filled with air; pillow-like.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Coming or exhaling in puffs.",
              synonyms: [],
              antonyms: [],
              example:
                "A puffy fat man in a tracksuit came jogging around the corner.",
            },
            {
              definition:
                "Speaking or writing in an exaggeratedly eloquent and self-important manner.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/puffy"],
    },
  ],
  casks: [
    {
      word: "casks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A large barrel for the storage of liquid, especially of alcoholic drinks.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A casket; a small box for jewels.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A helmet.", synonyms: [], antonyms: [] },
            {
              definition:
                "A hard structure on the head of some birds, such as the hornbill or cassowary.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cask",
        "https://en.wiktionary.org/wiki/casks",
        "https://en.wiktionary.org/wiki/casque",
      ],
    },
  ],
  grime: [
    {
      word: "grime",
      phonetic: "/ɡɹaɪm/",
      phonetics: [
        {
          text: "/ɡɹaɪm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grime-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90572960",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Dirt, grease, soot, etc. that is ingrained and difficult to remove.",
              synonyms: [],
              antonyms: [],
              example:
                "Underneath all that soot, dirt and grime is the true beauty of the church in soft shades of sandstone.",
            },
            {
              definition:
                "A genre of urban music that emerged in London, England, in the early 2000s, primarily a development of UK garage, dancehall, and hip hop.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To begrime; to cake with dirt.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/grime"],
    },
  ],
  derby: [
    {
      word: "derby",
      phonetic: "/ˈdɑː.bi/",
      phonetics: [
        { text: "/ˈdɑː.bi/", audio: "" },
        { text: "/ˈdɑː.bi/", audio: "" },
        { text: "/ˈdɝ.bi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Any of several annual horse races.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) Any organized race.",
              synonyms: [],
              antonyms: [],
              example: "a donkey derby",
            },
            { definition: "A bowler hat.", synonyms: [], antonyms: [] },
            { definition: "A local derby.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/derby"],
    },
  ],
  mamma: [
    {
      word: "mamma",
      phonetic: "/ˈmæmə/",
      phonetics: [{ text: "/ˈmæmə/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The milk-secreting organ of female humans and other mammals which includes the mammary gland and the nipple or teat; a breast; an udder. (plural: mammae)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An accessory cloud like a mammary in appearance, which can form on the underside of most cloud genera",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mamma"],
    },
    {
      word: "mamma",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(hypocoristic, usually childish) Mother, female parent.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mama",
        "https://en.wiktionary.org/wiki/mamma",
      ],
    },
  ],
  gavel: [
    {
      word: "gavel",
      phonetic: "/ˈɡa.vəl/",
      phonetics: [
        { text: "/ˈɡa.vəl/", audio: "" },
        {
          text: "/ˈɡæ.vəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gavel-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50240573",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Rent.", synonyms: [], antonyms: [] },
            {
              definition: "Usury; interest on money.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An old Saxon and Welsh form of tenure by which an estate passed, on the holder's death, to all the sons equally.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To divide or distribute according to the gavel system.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gavel"],
    },
    {
      word: "gavel",
      phonetic: "/ˈɡa.vəl/",
      phonetics: [
        { text: "/ˈɡa.vəl/", audio: "" },
        {
          text: "/ˈɡæ.vəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gavel-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50240573",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A wooden mallet, used by a courtroom judge, or by a committee chairman, struck against a sounding block to quieten those present, or by an auctioneer to accept the highest bid at auction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(metonymically) The legal system as a whole.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A mason's setting maul.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To use a gavel.",
              synonyms: [],
              antonyms: [],
              example:
                "The judge gavelled for order in the courtroom after the defendant burst out with a confession.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gavel"],
    },
    {
      word: "gavel",
      phonetic: "/ˈɡa.vəl/",
      phonetics: [
        { text: "/ˈɡa.vəl/", audio: "" },
        {
          text: "/ˈɡæ.vəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gavel-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50240573",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A small heap of grain, not tied up into a bundle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gavel"],
    },
    {
      word: "gavel",
      phonetic: "/ˈɡa.vəl/",
      phonetics: [
        { text: "/ˈɡa.vəl/", audio: "" },
        {
          text: "/ˈɡæ.vəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gavel-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50240573",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "A gable.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gavel"],
    },
  ],
  teddy: [
    {
      word: "teddy",
      phonetic: "/ˈtɛdi/",
      phonetics: [{ text: "/ˈtɛdi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "By extension, any stuffed toy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A type of all-in-one piece of women's underwear.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A plush toy in the shape of a bear.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A lovable, endearing, large, strong, hairy, or sweet man",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/teddy",
        "https://en.wiktionary.org/wiki/teddy%20bear",
      ],
    },
  ],
  vomit: [
    {
      word: "vomit",
      phonetic: "/ˈvɒmɪt/",
      phonetics: [
        { text: "/ˈvɒmɪt/", audio: "" },
        {
          text: "/ˈvɑmɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/vomit-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1763078",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The regurgitated former contents of a stomach; vomitus.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The act of regurgitating.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "That which causes vomiting; an emetic.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To regurgitate or eject the contents of the stomach through the mouth; puke.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To regurgitate and discharge (something swallowed); to spew.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To eject from any hollow place; to belch forth; to emit.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["barf", "chunder", "hurl", "puke", "sick", "throw up"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/vomit"],
    },
  ],
  moans: [
    {
      word: "moans",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A low, mournful cry of pain, sorrow or pleasure",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To complain about; to bemoan, to bewail; to mourn.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To grieve.", synonyms: [], antonyms: [] },
            {
              definition: "To distress (someone); to sadden.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make a moan or similar sound.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To say in a moan, or with a moaning voice.",
              synonyms: [],
              antonyms: [],
              example: "‘Please don't leave me,’ he moaned.",
            },
            {
              definition: "To complain; to grumble.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/moan",
        "https://en.wiktionary.org/wiki/moans",
      ],
    },
  ],
  allot: [
    {
      word: "allot",
      phonetic: "/əˈlɒt/",
      phonetics: [
        { text: "/əˈlɒt/", audio: "" },
        { text: "/əˈlɑt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To distribute or apportion by (or as if by) lot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To assign or designate as a task or for a purpose.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/allot"],
    },
  ],
  defer: [
    {
      word: "defer",
      phonetic: "/dɪˈfɜː(ɹ)/",
      phonetics: [
        {
          text: "/dɪˈfɜː(ɹ)/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/defer-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88987439",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To delay or postpone",
              synonyms: [],
              antonyms: [],
              example:
                "We're going to defer the decision until we have all the facts.",
            },
            {
              definition:
                "After winning the opening coin toss, to postpone until the start of the second half a team's choice of whether to kick off or receive (and to allow the opposing team to make this choice at the start of the first half).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To delay, to wait.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/defer"],
    },
    {
      word: "defer",
      phonetic: "/dɪˈfɜː(ɹ)/",
      phonetics: [
        {
          text: "/dɪˈfɜː(ɹ)/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/defer-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88987439",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To submit to the opinion or desire of another in respect to their judgment or authority.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To render, to offer.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/defer"],
    },
  ],
  wield: [
    {
      word: "wield",
      phonetic: "/wiːld/",
      phonetics: [
        {
          text: "/wiːld/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wield-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50309160",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To command, rule over; to possess or own.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To control, to guide or manage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To handle with skill and ease, especially a weapon or tool.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To exercise (authority or influence) effectively.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/wield"],
    },
  ],
  viper: [
    {
      word: "viper",
      phonetic: "/ˈvaɪpɚ/",
      phonetics: [
        {
          text: "/ˈvaɪpɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/viper-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50014410",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A venomous snake in the family Viperidae.",
              synonyms: ["adder"],
              antonyms: [],
            },
            {
              definition: "A dangerous, treacherous, or malignant person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person who smokes marijuana.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["adder"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/viper"],
    },
  ],
  louse: [
    {
      word: "louse",
      phonetic: "/laʊs/",
      phonetics: [
        {
          text: "/laʊs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/louse-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=77606734",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small parasitic wingless insect of the order Psocodea.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(not usually used in plural form) A contemptible person; one who is deceitful or causes harm.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["maggot", "worm", "cootie"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To remove lice from.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["delouse"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/louse"],
    },
  ],
  erred: [
    {
      word: "erred",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make a mistake.",
              synonyms: [],
              antonyms: [],
              example: "He erred in his calculations, and made many mistakes.",
            },
            { definition: "To sin.", synonyms: [], antonyms: [] },
            { definition: "To stray.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/err",
        "https://en.wiktionary.org/wiki/erred",
      ],
    },
    {
      word: "erred",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                'To utter the word "er" when hesitating in speech, found in the phrase um and er.',
              synonyms: [],
              antonyms: [],
              example: "He ummed and erred his way through the presentation.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/er",
        "https://en.wiktionary.org/wiki/erred",
      ],
    },
  ],
  hewed: [
    {
      word: "hewed",
      phonetic: "/hjuːd/",
      phonetics: [{ text: "/hjuːd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To chop away at; to whittle down; to mow down.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To shape; to form.",
              synonyms: [],
              antonyms: [],
              example:
                "One of the most widely used typefaces in the world was hewn by the English printer and typographer John Baskerville.",
            },
            {
              definition:
                "To act according to, to conform to; usually construed with to.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hew",
        "https://en.wiktionary.org/wiki/hewed",
      ],
    },
  ],
  abhor: [
    {
      word: "abhor",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/abhor-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75729288",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/əbˈhɔː(ɹ)/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/abhor-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=593043",
        },
        {
          text: "/æbˈhɔɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/abhor-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=3631233",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To regard with horror or detestation; to shrink back with shuddering from; to feel excessive repugnance toward; to detest to extremity; to loathe.",
              synonyms: [],
              antonyms: [],
              example: "I absolutely abhor being stuck in traffic jams",
            },
            {
              definition: "To fill with horror or disgust.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To turn aside or avoid; to keep away from; to reject.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(canon law) To protest against; to reject solemnly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To shrink back with horror, disgust, or dislike; to be contrary or averse; construed with from.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Differ entirely from.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/abhor"],
    },
  ],
  wrest: [
    {
      word: "wrest",
      phonetic: "/ɹɛst/",
      phonetics: [
        {
          text: "/ɹɛst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wrest-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75858203",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act of wresting; a wrench or twist; distortion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A key to tune a stringed instrument.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Active or motive power.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Short for saw wrest; a saw set.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To pull or twist violently.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To obtain by pulling or violent force.",
              synonyms: [],
              antonyms: [],
              example:
                "He wrested the remote control from my grasp and changed the channel.",
            },
            { definition: "To seize.", synonyms: [], antonyms: [] },
            {
              definition: "To distort, to pervert, to twist.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To tune with a wrest, or key.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/wrest"],
    },
    {
      word: "wrest",
      phonetic: "/ɹɛst/",
      phonetics: [
        {
          text: "/ɹɛst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wrest-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75858203",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A partition in a water wheel by which the form of the buckets is determined.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/wrest"],
    },
    {
      word: "wrest",
      phonetic: "/ɹɛst/",
      phonetics: [
        {
          text: "/ɹɛst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wrest-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75858203",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A metal (formerly wooden) piece of some ploughs attached under the mouldboard (the curved blade that turns over the furrow) for clearing out the furrow; the mouldboard itself.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/wrest"],
    },
  ],
  waxen: [
    {
      word: "waxen",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [{ definition: "Grown.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/waxen"],
    },
    {
      word: "waxen",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To apply wax to (something, such as a shoe, a floor, a car, or an apple), usually to make it shiny.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To remove hair at the roots from (a part of the body) by coating the skin with a film of wax that is then pulled away sharply.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To defeat utterly.", synonyms: [], antonyms: [] },
            {
              definition: "To kill, especially to murder a person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(usually of a musical or oral performance) To record.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "polish",
            "bump off",
            "knock off",
            "whack",
            "buff",
            "burnish",
            "furbish",
            "polish",
            "shine",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To increasingly assume the specified characteristic.",
              synonyms: ["become"],
              antonyms: [],
            },
            { definition: "To grow.", synonyms: [], antonyms: ["wane"] },
            {
              definition:
                "(of the moon) To appear larger each night as a progression from a new moon to a full moon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of the tide) To move from low tide to high tide.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["become"],
          antonyms: ["wane"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wax",
        "https://en.wiktionary.org/wiki/waxen",
      ],
    },
    {
      word: "waxen",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Made of wax; covered with wax.",
              synonyms: [],
              antonyms: [],
              example: "a waxen tablet",
            },
            {
              definition: "Of or pertaining to wax.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Having the pale smooth characteristics of wax, waxlike, waxy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Easily effaced, as if written in wax.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/waxen"],
    },
  ],
  adage: [
    {
      word: "adage",
      phonetic: "/ˈæ.dɪdʒ/",
      phonetics: [{ text: "/ˈæ.dɪdʒ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An old saying which has obtained credit by long use",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An old saying which has been overused or considered a cliché; a trite maxim",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["apophthegm", "colloquialism", "proverb"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/adage"],
    },
  ],
  ardor: [
    {
      word: "ardor",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ardor-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75729546",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈɑː(ɹ)də(ɹ)/", audio: "" },
        {
          text: "/ˈɑːɹdəɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ardor-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1088566",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Great warmth of feeling; fervor; passion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Spirit; enthusiasm; passion.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Intense heat.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "elan",
            "fire in the belly",
            "passion",
            "zeal",
            "intensity",
          ],
          antonyms: ["apathy"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ardor"],
    },
  ],
  stabs: [
    {
      word: "stabs",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stabs-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1212389",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An act of stabbing or thrusting with an object.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A wound made by stabbing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Pain inflicted on a person's feelings.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An attempt.", synonyms: [], antonyms: [] },
            { definition: "Criticism.", synonyms: [], antonyms: [] },
            {
              definition:
                "A single staccato chord that adds dramatic impact to a composition.",
              synonyms: [],
              antonyms: [],
              example: "a horn stab",
            },
            {
              definition:
                "A bacterial culture made by inoculating a solid medium, such as gelatin, with the puncture of a needle or wire.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To pierce or to wound (somebody) with a pointed tool or weapon, especially a knife or dagger.",
              synonyms: [],
              antonyms: [],
              example:
                "If you stab him in the heart he won't live long enough to retaliate.",
            },
            {
              definition: "To thrust in a stabbing motion.",
              synonyms: [],
              antonyms: [],
              example: "to stab a dagger into a person",
            },
            {
              definition:
                "To recklessly hit with the tip of a pointed object, such as a weapon or finger (often used with at).",
              synonyms: [],
              antonyms: [],
              example:
                "He stabbed at my face with the twig but luckily kept missing my eyes.",
            },
            {
              definition:
                "To cause a sharp, painful sensation (often used with at).",
              synonyms: [],
              antonyms: [],
              example:
                "The snow from the blizzard was stabbing at my face as I skied down the mountain.",
            },
            {
              definition:
                "To injure secretly or by malicious falsehood or slander.",
              synonyms: [],
              antonyms: [],
              example: "to stab a person's reputation",
            },
            {
              definition:
                "To roughen a brick wall with a pick so as to hold plaster.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To pierce folded sheets, near their back edges, for the passage of thread or wire.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/stab",
        "https://en.wiktionary.org/wiki/stabs",
      ],
    },
  ],
  pored: [
    {
      word: "pored",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To study meticulously; to go over again and again.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To meditate or reflect in a steady way.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having or furnished with pores",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pore",
        "https://en.wiktionary.org/wiki/pored",
      ],
    },
  ],
  rondo: [
    {
      word: "rondo",
      phonetic: "/ˈɹɒn.dəʊ/",
      phonetics: [{ text: "/ˈɹɒn.dəʊ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A musical composition, commonly of a lively, cheerful character, in which the first strain recurs after each of the other strains.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small, disk-shaped piece of food, especially a single-serving dessert or small piece of candy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A dark-skinned grape, a hybrid of Vitis vinifera with Vitis amurensis and others.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A game resembling keep-away, used to train soccer players: one group is tasked with completing a number of passes while the other group tries to take possession of the ball.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A gambling game played with small balls on a table.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rondo"],
    },
  ],
  loped: [
    {
      word: "loped",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To travel an easy pace with long strides.",
              synonyms: [],
              antonyms: [],
              example:
                "He loped along, hour after hour, not fast but steady and covering much ground.",
            },
            { definition: "To jump, leap.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lope",
        "https://en.wiktionary.org/wiki/loped",
      ],
    },
  ],
  fishy: [
    {
      word: "fishy",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fishy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75796800",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈfɪʃi/", audio: "" },
        {
          text: "/ˈfɪʃi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fishy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100425",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A cold-blooded vertebrate animal that lives in water, moving with the help of fins and breathing with gills.",
              synonyms: [],
              antonyms: [],
              example: "Salmon is a fish.",
            },
            {
              definition:
                "Any animal (or any vertebrate) that lives exclusively in water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The flesh of the fish used as food.",
              synonyms: [],
              antonyms: [],
              example:
                "The seafood pasta had lots of fish but not enough pasta.",
            },
            {
              definition:
                "A card game in which the object is to obtain cards in pairs or sets of four (depending on the variation), by asking the other players for cards of a particular rank.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A woman.", synonyms: [], antonyms: [] },
            {
              definition: "An easy victim for swindling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bad poker player. Compare shark (a good poker player).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A makeshift overlapping longitudinal brace, originally shaped roughly like a fish, used to temporarily repair or extend a spar or mast of a ship.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A purchase used to fish the anchor.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A torpedo.", synonyms: [], antonyms: [] },
            {
              definition:
                "A paraphyletic grouping of the following extant taxonomic groups:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The thirty-fourth Lenormand card.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["donk", "donkey", "Go Fish", "mark"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A period of time spent fishing.",
              synonyms: [],
              antonyms: [],
              example: "The fish at the lake didn't prove successful.",
            },
            {
              definition: "An instance of seeking something.",
              synonyms: [],
              antonyms: [],
              example:
                "Merely two fishes for information told the whole story.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A counter, used in various games.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of, from, or similar to fish.",
              synonyms: [],
              antonyms: [],
              example: "What is that fishy odor?",
            },
            {
              definition: "Suspicious; inspiring doubt.",
              synonyms: [],
              antonyms: [],
              example: "I don't trust him; his claims seem fishy to me.",
            },
            {
              definition: "Of drag queens: appearing feminine.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["fishlike", "fishly", "ichthyic", "piscine"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fish",
        "https://en.wiktionary.org/wiki/fishy",
      ],
    },
  ],
  bible: [
    {
      word: "bible",
      phonetic: "/ˈbaɪbəl/",
      phonetics: [
        { text: "/ˈbaɪbəl/", audio: "" },
        {
          text: "/ˈbaɪbəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bible-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=7448146",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An exemplar of the Bible.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A comprehensive manual that describes something. (e.g., handyman’s bible).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(at certain US universities) A compilation of problems and solutions from previous years of a given course, used by some students to cheat on tests or assignments.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Omasum, the third compartment of the stomach of ruminants",
              synonyms: ["fardel", "manyplies", "omasum", "psalterium"],
              antonyms: [],
            },
          ],
          synonyms: ["fardel", "manyplies", "omasum", "psalterium"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A piece of soft sandstone used for scouring the wooden decks of ships, usually with sand and seawater.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A stone with a naturally-formed hole, used by Yorkshiremen for good luck.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["lucky stone", "bible"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bible",
        "https://en.wiktionary.org/wiki/holystone",
      ],
    },
  ],
  hires: [
    {
      word: "hires",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Payment for the temporary use of something.",
              synonyms: [],
              antonyms: [],
              example: "The sign offered pedalos on hire.",
            },
            { definition: "Reward, payment.", synonyms: [], antonyms: [] },
            {
              definition:
                "The state of being hired, or having a job; employment.",
              synonyms: [],
              antonyms: [],
              example:
                "When my grandfather retired, he had over twenty mechanics in his hire.",
            },
            {
              definition:
                "A person who has been hired, especially in a cohort.",
              synonyms: [],
              antonyms: [],
              example:
                "We pair up each of our new hires with one of our original hires.",
            },
          ],
          synonyms: ["employ", "employment"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To obtain the services of in return for fixed payment.",
              synonyms: ["rent"],
              antonyms: [],
              example:
                "We hired a car for two weeks because ours had broken down.",
            },
            {
              definition:
                "To employ; to obtain the services of (a person) in exchange for remuneration; to give someone a job.",
              synonyms: [],
              antonyms: [],
              example:
                "The company had problems when it tried to hire more skilled workers.",
            },
            {
              definition: "To exchange the services of for remuneration.",
              synonyms: [],
              antonyms: [],
              example:
                "They hired themselves out as day laborers.  They hired out their basement for Inauguration week.",
            },
            {
              definition: "To accomplish by paying for services.",
              synonyms: [],
              antonyms: [],
              example:
                "After waiting two years for her husband to finish the tiling, she decided to hire it done.",
            },
            {
              definition: "To accept employment.",
              synonyms: [],
              antonyms: [],
              example: "They hired out as day laborers.",
            },
          ],
          synonyms: ["rent"],
          antonyms: ["fire"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hire",
        "https://en.wiktionary.org/wiki/hires",
      ],
    },
  ],
  foals: [
    {
      word: "foals",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A young horse or related animal, especially just after birth or less than a year old.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A young boy who assisted the headsman by pushing or pulling the tub.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To give birth to (a foal); to bear offspring.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/foal",
        "https://en.wiktionary.org/wiki/foals",
      ],
    },
  ],
  feuds: [
    {
      word: "feuds",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A state of long-standing mutual hostility.",
              synonyms: [],
              antonyms: [],
              example:
                "You couldn't call it a feud exactly, but there had always been a chill between Phil Mickelson and Tiger Woods.",
            },
            {
              definition: "A staged rivalry between wrestlers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A combination of kindred to avenge injuries or affronts, done or offered to any of their blood, on the offender and all his race.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An estate granted to a vassal by a feudal lord in exchange for service.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["fee", "fief"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/feud",
        "https://en.wiktionary.org/wiki/feuds",
      ],
    },
  ],
  jambs: [
    {
      word: "jambs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(interior decorating) Either of the vertical components that form the side of an opening in a wall, such as that of a door frame, window frame, or fireplace.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any thick mass of rock that prevents miners from following the lode or vein.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/jamb",
        "https://en.wiktionary.org/wiki/jambs",
      ],
    },
  ],
  thuds: [
    {
      word: "thuds",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The sound of a dull impact.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Republic F-105 Thunderchief jet ground attack fighter.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/thud",
        "https://en.wiktionary.org/wiki/thuds",
      ],
    },
  ],
  jeers: [
    {
      word: "jeers",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A mocking remark or reflection.",
              synonyms: ["flout", "jibe", "mockery", "scoff", "taunt"],
              antonyms: [],
            },
          ],
          synonyms: ["flout", "jibe", "mockery", "scoff", "taunt"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(jeer at) To utter sarcastic or mocking comments; to speak with mockery or derision; to use taunting language.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "deride",
            "flout",
            "gibe",
            "mock",
            "ridicule",
            "scoff",
            "sneer",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A gear; a tackle.", synonyms: [], antonyms: [] },
            {
              definition:
                "(in the plural) An assemblage or combination of tackles, for hoisting or lowering the yards of a ship.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/jeer",
        "https://en.wiktionary.org/wiki/jeers",
      ],
    },
  ],
  knead: [
    {
      word: "knead",
      phonetic: "/niːd/",
      phonetics: [
        { text: "/niːd/", audio: "" },
        {
          text: "/niːd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/knead-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780187",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act of kneading something.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To work and press into a mass, usually with the hands; especially, to work, as by repeated pressure with the knuckles, into a well mixed mass, the materials of bread, cake, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To treat or form as if by kneading; to beat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of cats) To make an alternating pressing motion with the two front paws.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To mix thoroughly; form into a homogeneous compound.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["amalgamate"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/knead"],
    },
  ],
  quirk: [
    {
      word: "quirk",
      phonetic: "/kwɜːk/",
      phonetics: [
        { text: "/kwɜːk/", audio: "" },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/quirk-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=711665",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An idiosyncrasy; a slight glitch, mannerism; something unusual about the manner or style of something or someone",
              synonyms: [],
              antonyms: [],
              example:
                "The car steers cleanly, but the gearshift has a few quirks.",
            },
            {
              definition:
                "An acute angle dividing a molding; a groove that runs lengthwise between the upper part of a moulding and a soffit",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A quibble, evasion, or subterfuge.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To move with a wry jerk.",
              synonyms: [],
              antonyms: [],
              example: "He quirked an eyebrow.",
            },
            {
              definition: "To furnish with a quirk or channel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To use verbal tricks or quibbles",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/quirk"],
    },
  ],
  rugby: [
    {
      word: "rugby",
      phonetic: "/ˈɹʌɡbi/",
      phonetics: [
        { text: "/ˈɹʌɡbi/", audio: "" },
        {
          text: "/ˈɹʌɡbi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rugby-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=49896072",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(usually uncountable) A form of football in which players can hold or kick an ovoid ball. The ball cannot be handled forwards and points are scored by touching the ball to the ground in the area past their opponent’s territory or kicking the ball between goalposts and over a crossbar.",
              synonyms: [],
              antonyms: [],
              example: "The scrum is a distinctive element of rugby.",
            },
            {
              definition:
                "A loose fitting shirt with a collar, as worn by rugby players.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rugby"],
    },
    {
      word: "rugby",
      phonetic: "/ˈɹʌɡbi/",
      phonetics: [
        { text: "/ˈɹʌɡbi/", audio: "" },
        {
          text: "/ˈɹʌɡbi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rugby-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=49896072",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Rubber cement, contact cement.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rugby"],
    },
  ],
  expel: [
    {
      word: "expel",
      phonetic: "/ɪkˈspɛl/",
      phonetics: [{ text: "/ɪkˈspɛl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To eject or erupt.", synonyms: [], antonyms: [] },
            {
              definition: "To fire (a bullet, arrow etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To remove from membership.",
              synonyms: ["drive away", "drive out", "force out"],
              antonyms: [],
              example: "He was expelled from school multiple times.",
            },
            { definition: "To deport.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "drive away",
            "drive out",
            "force out",
            "fordrive",
            "turf out",
          ],
          antonyms: ["impel"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/expel"],
    },
  ],
  greys: [
    {
      word: "greys",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An achromatic colour intermediate between black and white.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An animal or thing of grey colour, such as a horse, badger, or salmon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An extraterrestrial humanoid with greyish skin, bulbous black eyes, and an enlarged head.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To become grey.",
              synonyms: [],
              antonyms: [],
              example: "My hair is beginning to grey.",
            },
            {
              definition: "To cause to become grey.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To turn progressively older, in the context of the population of a geographic region.",
              synonyms: [],
              antonyms: [],
              example: "the greying of Europe",
            },
            {
              definition:
                "To give a soft effect to (a photograph) by covering the negative while printing with a ground-glass plate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Grey trousers and/or jackets worn by prisoners on relatively formal occasions.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/grey",
        "https://en.wiktionary.org/wiki/greys",
      ],
    },
  ],
  rigor: [
    {
      word: "rigor",
      phonetic: "/ɹɪɡɚ/",
      phonetics: [
        {
          text: "/ɹɪɡɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rigor-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50423049",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Short for rigor mortis.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Severity or strictness.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Harshness, as of climate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A trembling or shivering response.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Character of being unyielding or inflexible.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Shrewd questioning.", synonyms: [], antonyms: [] },
            {
              definition: "Higher level of difficulty.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rigor",
        "https://en.wiktionary.org/wiki/rigour",
      ],
    },
  ],
  ester: [
    {
      word: "ester",
      phonetic: "/ˈɛstə/",
      phonetics: [
        { text: "/ˈɛstə/", audio: "" },
        { text: "/ˈɛstɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A compound most often formed by the condensation of an alcohol and an acid, with elimination of water, which contains the functional group carbon-oxygen double bond joined via carbon to another oxygen atom.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ester"],
    },
  ],
  lyres: [
    {
      word: "lyres",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An ancient stringed musical instrument (a yoke lute chordophone) of Greek origin, consisting of two arms extending from a body to a crossbar (a yoke), and strings, parallel to the soundboard, connecting the body to the yoke.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A lyre-shaped sheet music holder that attaches to a wind instrument when a music stand is impractical.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A composer of lyric poetry.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["yoke lute"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lyre",
        "https://en.wiktionary.org/wiki/lyres",
      ],
    },
  ],
  aback: [
    {
      word: "aback",
      phonetic: "/əˈbæk/",
      phonetics: [
        { text: "/əˈbæk/", audio: "" },
        {
          text: "/əˈbæk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/aback-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=68433901",
        },
      ],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Towards the back or rear; backwards.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "In the rear; a distance behind.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "By surprise; startled; dumbfounded. (see usage)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'Backward against the mast; said of the sails when pressed by the wind from the "wrong" (forward) side, or of a ship when its sails are set that way.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/aback"],
    },
    {
      word: "aback",
      phonetic: "/əˈbæk/",
      phonetics: [
        { text: "/əˈbæk/", audio: "" },
        {
          text: "/əˈbæk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/aback-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=68433901",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "An abacus.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/aback"],
    },
  ],
  glues: [
    {
      word: "glues",
      phonetic: "/ɡluːz/",
      phonetics: [{ text: "/ɡluːz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A hard gelatin made by boiling bones and hides, used in solution as an adhesive; or any sticky adhesive substance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Anything that binds two things or people together.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Birdlime.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/glue",
        "https://en.wiktionary.org/wiki/glues",
      ],
    },
  ],
  lotus: [
    {
      word: "lotus",
      phonetic: "/ˈləʊtəs/",
      phonetics: [
        { text: "/ˈləʊtəs/", audio: "" },
        { text: "/ˈloʊtəs/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A kind of aquatic plant, genus Nelumbo in the family Nelumbonaceae.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A water lily, genus Nymphaea, especially those of Egypt or India.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A legendary plant eaten by the Lotophagi of the Odyssey that caused drowsiness and euphoria.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A number of other plants bearing lotus in their scientific or common names (see Derived terms below).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An architectural motif of ancient Egyptian temples.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lotus"],
    },
  ],
  lurid: [
    {
      word: "lurid",
      phonetic: "/ˈl(j)ʊə.ɹɪd/",
      phonetics: [
        { text: "/ˈl(j)ʊə.ɹɪd/", audio: "" },
        {
          text: "/ˈlɔɹɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lurid-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1329059",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Shocking, horrifying.",
              synonyms: [],
              antonyms: [],
              example: "The accident was described with lurid detail.",
            },
            { definition: "Melodramatic.", synonyms: [], antonyms: [] },
            {
              definition: "Ghastly, pale, wan in appearance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Being of a light yellow hue.",
              synonyms: [],
              antonyms: [],
              example: "Some paperback novels have lurid covers.",
            },
            {
              definition:
                "Having a brown colour tinged with red, as of flame seen through smoke.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Having a colour tinged with purple, yellow, and grey.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lurid"],
    },
  ],
  rungs: [
    {
      word: "rungs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A crosspiece forming a step of a ladder; a round.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A crosspiece between legs of a chair.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A position in a hierarchy.",
              synonyms: [],
              antonyms: [],
              example: "the lowest rung of the society",
            },
            {
              definition: "A floor timber in a ship.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One of the stakes of a cart; a spar; a heavy staff.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the radial handles projecting from the rim of a steering wheel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One of the pins or trundles of a lantern wheel.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rung",
        "https://en.wiktionary.org/wiki/rungs",
      ],
    },
  ],
  hutch: [
    {
      word: "hutch",
      phonetic: "/hʌtʃ/",
      phonetics: [
        {
          text: "/hʌtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hutch-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=87240910",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A cage for keeping rabbits, guinea pigs, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of furniture in which items may be displayed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A measure of two Winchester bushels.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The case of a flour bolt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A car on low wheels, in which coal is drawn in the mine and hoisted out of the pit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A jig or trough for ore dressing or washing ore.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A baker's kneading-trough.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To hoard or lay up, in a chest.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To wash (ore) in a box or jig.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hutch"],
    },
  ],
  thyme: [
    {
      word: "thyme",
      phonetic: "/taɪm/",
      phonetics: [{ text: "/taɪm/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any plant of the labiate genus Thymus, such as the garden thyme, Thymus vulgaris, a warm, pungent aromatic, that is much used to give a relish to seasoning and soups.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/thyme"],
    },
  ],
  valet: [
    {
      word: "valet",
      phonetic: "/ˈvæleɪ/",
      phonetics: [
        { text: "/ˈvæleɪ/", audio: "" },
        { text: "/væˈleɪ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A man's personal male attendant, responsible for his clothes and appearance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A hotel employee performing such duties for guests.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(professional wrestling) A female performer in professional wrestling, acting as either a manager or personal chaperone; often used to attract and titillate male members of the audience.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A female chaperone who accompanies a man, and is usually not married to him.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person employed to clean or park cars.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person employed to assist the jockey and trainer at a racecourse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A wooden stand on which to hold clothes and accessories in preparation for dressing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A kind of goad or stick with an iron point.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["butler", "gentleman's gentleman"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To serve (someone) as a valet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To clean and service (a car), as a valet does.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To leave (a car) with a valet to park it.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/valet"],
    },
  ],
  tommy: [
    {
      word: "tommy",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tommy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=87030548",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈtɒmi/", audio: "" },
        { text: "/ˈtɑmi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Bread, generally a penny roll; the supply of food carried by workmen as their daily allowance",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A truck, or barter; the exchange of labour for goods instead of money.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To pay (employees) according to the truck system, with goods instead of money.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tommy"],
    },
  ],
  yokes: [
    {
      word: "yokes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Frame around the neck, and related senses.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Pair of harnessed draught animals, and related senses.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Extended uses and quantities.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["control wheel"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To link or to join.", synonyms: [], antonyms: [] },
            { definition: "To unite, to connect.", synonyms: [], antonyms: [] },
            {
              definition:
                "To enslave; to bring into bondage; to restrain; to confine.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/yoke",
        "https://en.wiktionary.org/wiki/yokes",
      ],
    },
  ],
  epics: [
    {
      word: "epics",
      phonetic: "/ˈɛpɪks/",
      phonetics: [{ text: "/ˈɛpɪks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An extended narrative poem in elevated or dignified language, celebrating the feats of a deity, demigod (heroic epic), other legend or traditional hero.",
              synonyms: [],
              antonyms: [],
              example: "The Icelandic epic took all night to recite.",
            },
            {
              definition:
                "A series of events considered appropriate to an epic.",
              synonyms: [],
              antonyms: [],
              example: "The book was an epic in four volumes.",
            },
            {
              definition:
                "In software development, a large or extended user story.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["epos", "épopée"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/epic",
        "https://en.wiktionary.org/wiki/epics",
      ],
    },
  ],
  trill: [
    {
      word: "trill",
      phonetic: "/tɹɪl/",
      phonetics: [
        {
          text: "/tɹɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/trill-1-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88671779",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A rapid alternation between an indicated note and the one above it, in musical notation usually indicated with the letters tr written above the staff.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A type of consonantal sound that is produced by vibrations of the tongue against the place of articulation: for example, Spanish rr.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A tremulous high-pitched vocal sound produced by cats.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To create a trill sound; to utter trills or a trill; to play or sing in tremulous vibrations of sound; to have a trembling sound; to quaver.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To impart the quality of a trill to; to utter as, or with, a trill.",
              synonyms: [],
              antonyms: [],
              example: "to trill a note, or the letter r",
            },
          ],
          synonyms: ["roll"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/trill"],
    },
    {
      word: "trill",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To trickle.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/trill"],
    },
    {
      word: "trill",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To twirl.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/trill"],
    },
  ],
  pikes: [
    {
      word: "pikes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A very long spear used two-handed by infantry soldiers for thrusting (not throwing), both for attacks on enemy foot soldiers and as a countermeasure against cavalry assaults.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sharp point, such as that of the weapon.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A large haycock.", synonyms: [], antonyms: [] },
            {
              definition:
                "Any carnivorous freshwater fish of the genus Esox, especially the northern pike, Esox lucius.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A position with the knees straight and a tight bend at the hips with the torso folded over the legs, usually part of a jack-knife.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A pointy extrusion at the toe of a shoe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Especially in place names: a hill or mountain, particularly one with a sharp peak or summit.",
              synonyms: [],
              antonyms: [],
              example: "Scafell Pike is the highest mountain in England.",
            },
            { definition: "A pick, a pickaxe.", synonyms: [], antonyms: [] },
            { definition: "A hayfork.", synonyms: [], antonyms: [] },
            { definition: "A penis.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["northern pike"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Short for turnpike.",
              synonyms: [],
              antonyms: [],
              example: "There is heavy traffic on the Mass Pike",
            },
            {
              definition:
                "A gypsy, itinerant tramp, or traveller from any ethnic background; a pikey.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pike",
        "https://en.wiktionary.org/wiki/pikes",
      ],
    },
  ],
  ozone: [
    {
      word: "ozone",
      phonetic: "/ˈoʊzoʊn/",
      phonetics: [
        {
          text: "/ˈoʊzoʊn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ozone-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780253",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An allotrope of oxygen (symbol O₃) having three atoms in the molecule instead of the usual two; it is a blue gas, generated from oxygen by electrical discharge.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Fresh air, especially that breathed at the seaside and smelling of seaweed.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To treat with ozone.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ozone"],
    },
  ],
  caper: [
    {
      word: "caper",
      phonetic: "/ˈkeɪpə/",
      phonetics: [
        { text: "/ˈkeɪpə/", audio: "" },
        {
          text: "/ˈkeɪpɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/caper-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=93486905",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A playful leap or jump.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A jump while dancing.", synonyms: [], antonyms: [] },
            {
              definition: "A prank or practical joke.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(usually in plural) Playful behaviour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(figuratively) A crime, especially an elaborate heist, or a narrative about such a crime.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To leap or jump about in a sprightly or playful manner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To jump as part of a dance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To engage in playful behaviour.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/caper"],
    },
    {
      word: "caper",
      phonetic: "/ˈkeɪpə/",
      phonetics: [
        { text: "/ˈkeɪpə/", audio: "" },
        {
          text: "/ˈkeɪpɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/caper-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=93486905",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A vessel formerly used by the Dutch; privateer.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/caper"],
    },
    {
      word: "caper",
      phonetic: "/ˈkeɪpə/",
      phonetics: [
        { text: "/ˈkeɪpə/", audio: "" },
        {
          text: "/ˈkeɪpɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/caper-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=93486905",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The pungent grayish green flower bud of the European and Oriental caper (Capparis spinosa), which is pickled and eaten.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A plant of the genus Capparis.",
              synonyms: ["caper bush", "caper tree", "caperberry"],
              antonyms: [],
            },
          ],
          synonyms: ["caper bush", "caper tree", "caperberry"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/caper"],
    },
    {
      word: "caper",
      phonetic: "/ˈkeɪpə/",
      phonetics: [
        { text: "/ˈkeɪpə/", audio: "" },
        {
          text: "/ˈkeɪpɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/caper-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=93486905",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The capercaillie.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/caper"],
    },
  ],
  chime: [
    {
      word: "chime",
      phonetic: "/ˈtʃaɪm/",
      phonetics: [
        {
          text: "/ˈtʃaɪm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chime-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89033309",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A musical instrument producing a sound when struck, similar to a bell (e.g. a tubular metal bar) or actually a bell. Often used in the plural to refer to the set: the chimes.",
              synonyms: [],
              antonyms: [],
              example: "Hugo was a chime player in the school orchestra.",
            },
            {
              definition: "An individual ringing component of such a set.",
              synonyms: [],
              antonyms: [],
              example:
                "Peter removed the C♯ chime from its mounting so that he could get at the dust that had accumulated underneath.",
            },
            {
              definition:
                "A small bell or other ringing or tone-making device as a component of some other device.",
              synonyms: [],
              antonyms: [],
              example:
                "The professor had stuffed a wad of gum into the chime of his doorbell so that he wouldn't be bothered.",
            },
            {
              definition: "The sound of such an instrument or device.",
              synonyms: [],
              antonyms: [],
              example:
                "The copier gave a chime to indicate that it had finished printing.",
            },
            {
              definition:
                "A small hammer or other device used to strike a bell.",
              synonyms: [],
              antonyms: [],
              example:
                "Strike the bell with the brass chime hanging on the chain next to it.",
            },
          ],
          synonyms: [
            "alarm",
            "bell",
            "buzz",
            "buzzer",
            "carillon",
            "clapper",
            "curfew",
            "ding-dong",
            "dinger",
            "glockenspiel",
            "gong",
            "peal",
            "ringer",
            "siren",
            "tintinnabulum",
            "tocsin",
            "toll",
            "vesper",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make the sound of a chime.",
              synonyms: [],
              antonyms: [],
              example:
                "I got up for lunch as soon as the wall clock began chiming noon.",
            },
            {
              definition:
                "To cause to sound in harmony; to play a tune, as upon a set of bells; to move or strike in harmony.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To utter harmoniously; to recite rhythmically.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To agree; to correspond.",
              synonyms: [],
              antonyms: [],
              example:
                "The other lab's results chimed with mine, so I knew we were on the right track with the research.",
            },
            {
              definition:
                "To make a rude correspondence of sounds; to jingle, as in rhyming.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chime"],
    },
    {
      word: "chime",
      phonetic: "/ˈtʃaɪm/",
      phonetics: [
        {
          text: "/ˈtʃaɪm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chime-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89033309",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The top of a ridge.", synonyms: [], antonyms: [] },
            {
              definition: "The spine of an animal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of the backbone of an animal, with the adjoining parts, cut for cooking.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sharp angle in the cross section of a hull.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hollowed or bevelled channel in the waterway of a ship's deck.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The edge or rim of a cask, etc., formed by the projecting ends of the staves; the chamfered end of a stave.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The back of the blade on a scythe.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/chime",
        "https://en.wiktionary.org/wiki/chine",
      ],
    },
  ],
  frees: [
    {
      word: "frees",
      phonetic: "/fɹiːz/",
      phonetics: [{ text: "/fɹiːz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Free transfer", synonyms: [], antonyms: [] },
            {
              definition:
                "The usual means of restarting play after a foul is committed, where the non-offending team restarts from where the foul was committed.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make free; set at liberty; release.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To rid of something that confines or oppresses.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "befree",
            "emancipate",
            "let loose",
            "liberate",
            "manumit",
            "release",
            "unchain",
            "unfetter",
            "unshackle",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(other ballgames) a kick in which a player may kick the ball without interference from the opposition. Such a kick may be awarded for a foul by the opposition, or earned by a player such as by taking a mark.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The usual means of restarting play after a foul is committed, where the non-offending team restarts from where the foul was committed.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/free",
        "https://en.wiktionary.org/wiki/free%20kick",
        "https://en.wiktionary.org/wiki/frees",
      ],
    },
  ],
  famed: [
    {
      word: "famed",
      phonetic: "/feɪmd/",
      phonetics: [{ text: "/feɪmd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having fame; famous or noted.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/famed"],
    },
  ],
  leech: [
    {
      word: "leech",
      phonetic: "/liːtʃ/",
      phonetics: [
        { text: "/liːtʃ/", audio: "" },
        {
          text: "/liːtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/leech-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=20943277",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An aquatic blood-sucking annelid of class Hirudinea, especially Hirudo medicinalis.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person who derives profit from others in a parasitic fashion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A glass tube designed for drawing blood from damaged tissue by means of a vacuum.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bloodsucker", "parasite", "sponger", "vampire"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To apply a leech medicinally, so that it sucks blood from the patient.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To drain (resources) without giving back.",
              synonyms: [],
              antonyms: [],
              example:
                "Bert leeched hundreds of files from the BBS, but never uploaded anything in return.",
            },
          ],
          synonyms: ["drain"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/leech"],
    },
    {
      word: "leech",
      phonetic: "/liːtʃ/",
      phonetics: [
        { text: "/liːtʃ/", audio: "" },
        {
          text: "/liːtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/leech-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=20943277",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A physician.", synonyms: [], antonyms: [] },
            { definition: "(Heathenry) A healer.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["healer", "barber", "doctor", "physician"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/leech"],
    },
    {
      word: "leech",
      phonetic: "/liːtʃ/",
      phonetics: [
        { text: "/liːtʃ/", audio: "" },
        {
          text: "/liːtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/leech-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=20943277",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To treat, cure or heal.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["cure", "heal", "treat"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/leech"],
    },
    {
      word: "leech",
      phonetic: "/liːtʃ/",
      phonetics: [
        { text: "/liːtʃ/", audio: "" },
        {
          text: "/liːtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/leech-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=20943277",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The vertical edge of a square sail.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The aft edge of a triangular sail.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/leech"],
    },
  ],
  smite: [
    {
      word: "smite",
      phonetic: "/smaɪt/",
      phonetics: [
        {
          text: "/smaɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/smite-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89012324",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To hit, to strike.", synonyms: [], antonyms: [] },
            {
              definition: "To strike down or kill with godly force.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To injure with divine power.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To put to rout in battle; to overthrow by war.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To afflict; to chasten; to punish.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(now only in passive) To strike with love or infatuation.",
              synonyms: [],
              antonyms: [],
              example:
                "Bob was smitten with Laura from the first time he saw her.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/smite"],
    },
  ],
  neigh: [
    {
      word: "neigh",
      phonetic: "/neɪ/",
      phonetics: [
        {
          text: "/neɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/neigh-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2101073",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The cry of a horse.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(of a horse) To make its cry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make a sound similar to a horse's cry.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To scoff or sneer.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/neigh"],
    },
  ],
  erode: [
    {
      word: "erode",
      phonetic: "/ɪˈɹoʊd/",
      phonetics: [{ text: "/ɪˈɹoʊd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To wear away by abrasion, corrosion or chemical reaction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To destroy gradually by an ongoing process.",
              synonyms: [],
              antonyms: [],
              example: "to erode a person's trust",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/erode"],
    },
  ],
  robed: [
    {
      word: "robed",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To clothe; to dress.", synonyms: [], antonyms: [] },
            {
              definition: "To put on official vestments.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dight", "don", "put on"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Wearing a robe.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/robe",
        "https://en.wiktionary.org/wiki/robed",
      ],
    },
  ],
  hoard: [
    {
      word: "hoard",
      phonetic: "/hɔːd/",
      phonetics: [
        { text: "/hɔːd/", audio: "" },
        {
          text: "/hɔɹd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hoard-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1785162",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A hidden supply or fund.",
              synonyms: [],
              antonyms: [],
              example: "a hoard of provisions; a hoard of money",
            },
            {
              definition: "A cache of valuable objects or artefacts; a trove.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To amass, usually for one's own private collection.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["engross", "uphoard"],
          antonyms: ["declutter"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hoard"],
    },
    {
      word: "hoard",
      phonetic: "/hɔːd/",
      phonetics: [
        { text: "/hɔːd/", audio: "" },
        {
          text: "/hɔɹd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hoard-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1785162",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A hoarding (temporary structure used during construction).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A hoarding (billboard).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hoard"],
    },
  ],
  salve: [
    {
      word: "salve",
      phonetic: "/sælv/",
      phonetics: [
        { text: "/sælv/", audio: "" },
        {
          text: "/sælv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/salve-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50423052",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An ointment, cream, or balm with soothing, healing, or calming effects.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any remedy or action that soothes or heals.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/salve"],
    },
    {
      word: "salve",
      phonetic: "/sælv/",
      phonetics: [
        { text: "/sælv/", audio: "" },
        {
          text: "/sælv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/salve-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50423052",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To calm or assuage.", synonyms: [], antonyms: [] },
            {
              definition:
                "To heal by applications or medicaments; to apply salve to; to anoint.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To heal; to remedy; to cure; to make good.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To salvage.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/salve"],
    },
    {
      word: "salve",
      phonetic: "/sælv/",
      phonetics: [
        { text: "/sælv/", audio: "" },
        {
          text: "/sælv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/salve-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50423052",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To save (the appearances or the phenomena); to explain (a celestial phenomenon); to account for (the apparent motions of the celestial bodies).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To resolve (a difficulty); to refute (an objection); to harmonize (an apparent contradiction).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To explain away; to mitigate; to excuse.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/salve"],
    },
    {
      word: "salve",
      phonetic: "/sælv/",
      phonetics: [
        { text: "/sælv/", audio: "" },
        {
          text: "/sælv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/salve-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50423052",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "interjection",
          definitions: [
            { definition: "Hail; a greeting.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/salve"],
    },
    {
      word: "salve",
      phonetic: "/sælv/",
      phonetics: [
        { text: "/sælv/", audio: "" },
        {
          text: "/sælv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/salve-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50423052",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To say “salve” to; to greet; to salute.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/salve"],
    },
  ],
  conic: [
    {
      word: "conic",
      phonetic: "/ˈkɒ.nɪk/",
      phonetics: [
        { text: "/ˈkɒ.nɪk/", audio: "" },
        { text: "/ˈkɑ.nɪk/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A conic section.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of or relating to a cone or cones.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Shaped like a cone.", synonyms: [], antonyms: [] },
            {
              definition:
                "Describing a map projection in which meridians are mapped to equally spaced lines radiating out from the apex and parallels of latitude are mapped to circular arcs centred on the apex.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["conal", "conic", "conic"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/conic",
        "https://en.wiktionary.org/wiki/conical",
      ],
    },
  ],
  gawky: [
    {
      word: "gawky",
      phonetic: "/ˈɡɔːki/",
      phonetics: [{ text: "/ˈɡɔːki/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An awkward, ungainly person.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Awkward, ungainly; lacking grace or dexterity in movement",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["gauche", "ungraceful"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gawky"],
    },
  ],
  craze: [
    {
      word: "craze",
      phonetic: "/kɹeɪz/",
      phonetics: [
        {
          text: "/kɹeɪz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/craze-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=49875575",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Craziness; insanity.", synonyms: [], antonyms: [] },
            {
              definition: "A strong habitual desire or fancy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A temporary passion or infatuation, as for some new amusement, pursuit, or fashion; a fad",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A crack in the glaze or enamel caused by exposure of the pottery to great or irregular heat.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To weaken; to impair; to render decrepit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To derange the intellect of; to render insane.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be crazed, or to act or appear as one that is crazed; to rave; to become insane.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To break into pieces; to crush; to grind to powder. See crase.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To crack, as the glazing of porcelain or pottery.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/craze"],
    },
  ],
  jacks: [
    {
      word: "jacks",
      phonetic: "/dʒæks/",
      phonetics: [
        {
          text: "/dʒæks/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/jacks-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79293537",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A coarse mediaeval coat of defence, especially one made of leather.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A man.", synonyms: [], antonyms: [] },
            { definition: "A device or utensil.", synonyms: [], antonyms: [] },
            {
              definition: "A non-tool object or thing.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A plant or animal.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["jackass", "knave"],
          antonyms: ["plug"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The edible fruit of the Asian tree (Artocarpus heterophyllus); also the tree itself.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A home run.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(games) A children's game involving picking up objects; knucklebones; jackstones.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Alternative form of jakes: an outhouse or lavatory.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/jack",
        "https://en.wiktionary.org/wiki/jacks",
      ],
    },
  ],
  gloat: [
    {
      word: "gloat",
      phonetic: "/ɡləʊt/",
      phonetics: [
        { text: "/ɡləʊt/", audio: "" },
        { text: "/ɡloʊt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An act or instance of gloating.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To exhibit a conspicuous (sometimes malevolent) pleasure or sense of self-satisfaction, often at an adversary's misfortune.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To triumph, crow, relish, glory, revel.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gloat"],
    },
  ],
  mushy: [
    {
      word: "mushy",
      phonetic: "/ˈmʊʃi/",
      phonetics: [{ text: "/ˈmʊʃi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Resembling or having the consistency of mush; semiliquid, pasty, or granular.",
              synonyms: [],
              antonyms: [],
              example: "I don't especially like mushy oatmeal.",
            },
            {
              definition: "Soft; squishy.",
              synonyms: [],
              antonyms: [],
              example: "The brake pedal is mushy sometimes when I step on it.",
            },
            {
              definition: "Overly sappy, corny, or cheesy; maudlin.",
              synonyms: [],
              antonyms: [],
              example: "Skip the mushy, romantic scenes and get to the action.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mushy"],
    },
  ],
  rumps: [
    {
      word: "rumps",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The hindquarters of a four-legged mammal, not including its legs",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A cut of meat from the rump of an animal.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The buttocks.", synonyms: [], antonyms: [] },
            {
              definition: "Remnant, as in Rump Parliament.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["round", "croupe", "crupper"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rump",
        "https://en.wiktionary.org/wiki/rumps",
      ],
    },
  ],
  fetus: [
    {
      word: "fetus",
      phonetic: "/ˈfiːtəs/",
      phonetics: [
        {
          text: "/ˈfiːtəs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fetus-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88675776",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An unborn or unhatched vertebrate showing signs of the mature animal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A human embryo after the eighth week of gestation.",
              synonyms: [],
              antonyms: [],
              example:
                "The sequence is: molecules in reproductive systems, then gametes, zygotes, morulas, blastocysts, and then fetuses.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fetus"],
    },
  ],
  wince: [
    {
      word: "wince",
      phonetic: "/wɪns/",
      phonetics: [
        {
          text: "/wɪns/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wince-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2536822",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A sudden movement or gesture of shrinking away.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A reel used in dyeing, steeping, or washing cloth; a winch. It is placed over the division wall between two wince pits so as to allow the cloth to descend into either compartment at will.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To flinch as if in pain or distress.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To wash (cloth), dip it in dye, etc., with the use of a wince.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To kick or flounce when unsteady or impatient.",
              synonyms: [],
              antonyms: [],
              example: "A horse winces.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/wince"],
    },
  ],
  pinks: [
    {
      word: "pinks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The common minnow, Phoxinus phoxinus.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A young Atlantic salmon, Salmo salar, before it becomes a smolt; a parr.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A narrow boat.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "A stab.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of various flowers in the genus Dianthus, sometimes called carnations.",
              synonyms: [],
              antonyms: [],
              example:
                "This garden in particular has a beautiful bed of pinks.",
            },
            {
              definition:
                "A perfect example; excellence, perfection; the embodiment of some quality.",
              synonyms: [],
              antonyms: [],
              example: "Your hat, madam, is the very pink of fashion.",
            },
            {
              definition:
                "The colour of this flower, between red and white; pale red.",
              synonyms: [],
              antonyms: [],
              example: "My new dress is a wonderful shade of pink.",
            },
            {
              definition: "Hunting pink; scarlet, as worn by hunters.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the colour balls used in snooker, with a value of 6 points.",
              synonyms: [],
              antonyms: [],
              example: "Oh dear, he's left himself snookered behind the pink.",
            },
            {
              definition:
                "An unlettered and uncultured, but relatively prosperous, member of the middle classes; compare Babbitt, bourgeoisie.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The vagina or vulva.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A socialist who is not wholly communist.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Flowers in the family Caryophyllaceae, sometimes called carnations.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The traditional scarlet jacket(s) worn by fox-hunters in the United Kingdom.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pink",
        "https://en.wiktionary.org/wiki/pinko",
        "https://en.wiktionary.org/wiki/pinks",
      ],
    },
  ],
  shalt: [
    {
      word: "shalt",
      phonetic: "/ʃælt/",
      phonetics: [
        {
          text: "/ʃælt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shalt-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1684734",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(modal, auxiliary verb, defective) Used before a verb to indicate the simple future tense in the first person singular or plural.",
              synonyms: [],
              antonyms: [],
              example: "I hope that we shall win the game.",
            },
            {
              definition:
                "Used similarly to indicate determination or obligation in the second and third persons singular or plural.",
              synonyms: [],
              antonyms: [],
              example: "(determination): You shall go to the ball!",
            },
            {
              definition:
                "Used in questions with the first person singular or plural to suggest a possible future action.",
              synonyms: [],
              antonyms: [],
              example: "Let us examine that, shall we?",
            },
            { definition: "To owe.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/shall",
        "https://en.wiktionary.org/wiki/shalt",
      ],
    },
  ],
  toots: [
    {
      word: "toots",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/toots-1-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79268994",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈtuts/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The noise of a horn or whistle.",
              synonyms: [],
              antonyms: [],
              example:
                "He gave a little toot of the horn, to get their attention.",
            },
            {
              definition: "(by extension) A fart; flatus.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Cocaine.", synonyms: [], antonyms: [] },
            {
              definition: "A portion of cocaine that a person snorts.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A spree of drunkness.", synonyms: [], antonyms: [] },
            {
              definition: "(pronounced /tʊt/) Rubbish; tat.",
              synonyms: [],
              antonyms: [],
              example: "I'm not paying fifty pounds for this load of old toot!",
            },
            {
              definition:
                "A message on the social networking software Mastodon.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To stand out, or be prominent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To peep; to look narrowly.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To see; to spy.", synonyms: [], antonyms: [] },
            { definition: "To flatulate.", synonyms: [], antonyms: [] },
            {
              definition: "To make the sound of a horn or whistle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause a horn or whistle to make its sound.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To go on a drinking binge.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To snort (a recreational drug).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To post a message on a Mastodon instance (a self-hosted version of the networking software).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["poop"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A toilet.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/toot",
        "https://en.wiktionary.org/wiki/toots",
      ],
    },
    {
      word: "toots",
      phonetic: "/ˈtʊts/",
      phonetics: [{ text: "/ˈtʊts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(sometimes derogatory) Babe, sweetie: a term used when addressing a young woman, besties, sisters.",
              synonyms: [],
              antonyms: [],
              example: "Hey, toots! How you doing?",
            },
            {
              definition: "A gender-neutral term of affection.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/toots"],
    },
  ],
  glens: [
    {
      word: "glens",
      phonetic: "/ɡlɛnz/",
      phonetics: [{ text: "/ɡlɛnz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A secluded and narrow valley, especially one with a river running through it; a dale; a depression between hills.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/glen",
        "https://en.wiktionary.org/wiki/glens",
      ],
    },
  ],
  cooed: [
    {
      word: "cooed",
      phonetic: "/kuːd/",
      phonetics: [{ text: "/kuːd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make a soft murmuring sound, as a pigeon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To speak in an admiring fashion, to be enthusiastic about.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/coo",
        "https://en.wiktionary.org/wiki/cooed",
      ],
    },
  ],
  rusts: [
    {
      word: "rusts",
      phonetic: "/ɹʌsts/",
      phonetics: [
        {
          text: "/ɹʌsts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rusts-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80066786",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The deteriorated state of iron or steel as a result of moisture and oxidation.",
              synonyms: [],
              antonyms: [],
              example:
                "The rust on my bicycle chain made cycling to work very dangerous.",
            },
            {
              definition:
                'A similar substance based on another metal (usually with qualification, such as "copper rust").',
              synonyms: [],
              antonyms: [],
              example:
                "aerugo. Green or blue-green copper rust; verdigris. (American Heritage Dictionary, 1973)",
            },
            {
              definition: "A reddish-brown color.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A disease of plants caused by a reddish-brown fungus.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Damage caused to stamps and album pages by a fungal infection.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To oxidize, especially of iron or steel.",
              synonyms: [],
              antonyms: [],
              example:
                "The patio furniture had rusted in the wind-driven spray.",
            },
            {
              definition: "To cause to oxidize.",
              synonyms: [],
              antonyms: [],
              example:
                "The wind-driven spray had thoroughly rusted the patio furniture.",
            },
            {
              definition:
                "To be affected with the parasitic fungus called rust.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To (cause to) degenerate in idleness; to make or become dull or impaired by inaction.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["corrode", "oxidise", "oxidize"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rust",
        "https://en.wiktionary.org/wiki/rusts",
      ],
    },
  ],
  stews: [
    {
      word: "stews",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A cooking-dish used for boiling; a cauldron.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A heated bath-room or steam-room; also, a hot bath.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A brothel.", synonyms: [], antonyms: [] },
            { definition: "A prostitute.", synonyms: [], antonyms: [] },
            {
              definition: "A dish cooked by stewing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A pool in which fish are kept in preparation for eating; a stew pond.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An artificial bed of oysters.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A state of agitated excitement, worry, and/or confusion.",
              synonyms: [],
              antonyms: [],
              example: "to be in a stew",
            },
          ],
          synonyms: ["casserole", "hotpot"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cook (food) by slowly boiling or simmering.",
              synonyms: [],
              antonyms: [],
              example: "I'm going to stew some meat for the casserole.",
            },
            {
              definition:
                "To brew (tea) for too long, so that the flavour becomes too strong.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To suffer under uncomfortably hot conditions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be in a state of elevated anxiety or anger.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "brood",
            "fret",
            "sweat",
            "worry",
            "bake",
            "boil",
            "sweat",
            "swelter",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A steward or stewardess on an airplane.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A brothel.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/stew",
        "https://en.wiktionary.org/wiki/stews",
      ],
    },
  ],
  shred: [
    {
      word: "shred",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shred-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241730",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ʃɹɛd/", audio: "" },
        { text: "/ʃɹɛd/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A long, narrow piece cut or torn off; a strip.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In general, a fragment; a piece; a particle; a very small amount.",
              synonyms: [],
              antonyms: [],
              example: "There isn't a shred of evidence to support his claims.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shred"],
    },
    {
      word: "shred",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shred-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241730",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ʃɹɛd/", audio: "" },
        { text: "/ʃɹɛd/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cut or tear into narrow and long pieces or strips.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To reduce by a large percentage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To lop; to prune; to trim.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To ride aggressively.", synonyms: [], antonyms: [] },
            {
              definition: "To drop fat and water weight before a competition.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To play very fast (especially guitar solos in rock and metal genres).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shred"],
    },
  ],
  parka: [
    {
      word: "parka",
      phonetic: "/ˈpɑːkə/",
      phonetics: [
        { text: "/ˈpɑːkə/", audio: "" },
        { text: "/ˈpɑɹkə/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A long jacket with a hood which protects the wearer against rain and wind.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/parka"],
    },
  ],
  chugs: [
    {
      word: "chugs",
      phonetic: "/tʃʌɡz/",
      phonetics: [{ text: "/tʃʌɡz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A dull, fairly quick explosive or percussive sound, as if made by a labouring engine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A large gulp of drink.",
              synonyms: [],
              antonyms: [],
              example: "He drank his beer in three chugs.",
            },
            {
              definition:
                "A homemade Cuban boat, built to carry emigrants to the USA, and often abandoned upon arrival.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make dull explosive sounds.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To move or travel whilst making such sounds.",
              synonyms: [],
              antonyms: [],
              example:
                "We were chugging along a back road when the engine cut out.",
            },
            {
              definition:
                "To drink a large amount (especially of beer) in a single action/without breathing; to chugalug. People usually chant this at the person who is drinking.",
              synonyms: [],
              antonyms: [],
              example: "Chug! Chug! Chug!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A dog; a cross between a pug and a chihuahua.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To solicit charitable donations on the street, particularly in a persistent manner.",
              synonyms: [],
              antonyms: [],
              example: "I got chugged in the town centre today.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(racial slur) A person of Native American descent.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/chug",
        "https://en.wiktionary.org/wiki/chugs",
      ],
    },
  ],
  winks: [
    {
      word: "winks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An act of winking (a blinking of only one eye), or a message sent by winking.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A brief period of sleep; especially forty winks.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A brief time; an instant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The smallest possible amount.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A subtle allusion.",
              synonyms: [],
              antonyms: [],
              example: "The film includes a wink to wartime rationing.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To close one's eyes in sleep.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To close one's eyes.", synonyms: [], antonyms: [] },
            {
              definition:
                "Usually followed by at: to look the other way, to turn a blind eye.",
              synonyms: ["connive", "shut one's eyes"],
              antonyms: [],
            },
            {
              definition:
                "To close one's eyes quickly and involuntarily; to blink.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To blink with only one eye as a message, signal, or suggestion, usually with an implication of conspiracy. (When transitive, the object may be the eye being winked, or the message being conveyed.)",
              synonyms: [],
              antonyms: [],
              example:
                "He winked at me. She winked her eye. He winked his assent.",
            },
            {
              definition:
                "To gleam fitfully or intermitently; to twinkle; to flicker.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["connive", "shut one's eyes", "nictitate"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A disc used in the game of tiddlywinks.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(Chiefly British) Periwinkle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wink",
        "https://en.wiktionary.org/wiki/winks",
      ],
    },
  ],
  clots: [
    {
      word: "clots",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A thrombus, solidified mass of blood.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A solidified mass of any liquid.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A silly person.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To form a clot or mass.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause to clot or form into a mass.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/clot",
        "https://en.wiktionary.org/wiki/clots",
      ],
    },
  ],
  shrew: [
    {
      word: "shrew",
      phonetic: "/ʃɹuː/",
      phonetics: [
        {
          text: "/ʃɹuː/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shrew-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88681594",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of numerous small, mouselike, chiefly nocturnal, mammals of the family Soricidae (order Soricomorpha).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Certain other small mammals that resemble true shrews (order Soricomorpha).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An ill-tempered, nagging woman: a scold.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["ranny"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shrew"],
    },
    {
      word: "shrew",
      phonetic: "/ʃɹuː/",
      phonetics: [
        {
          text: "/ʃɹuː/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shrew-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88681594",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To beshrew; to curse.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shrew"],
    },
  ],
  booed: [
    {
      word: "booed",
      phonetic: "/buːd/",
      phonetics: [{ text: "/buːd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To shout extended boos derisively.",
              synonyms: [],
              antonyms: [],
              example: "When he took the podium, the crowd booed.",
            },
            {
              definition: "To shout extended boos at, as a form of derision.",
              synonyms: [],
              antonyms: [],
              example: "The protesters loudly booed the visiting senator.",
            },
          ],
          synonyms: [],
          antonyms: ["cheer"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/boo",
        "https://en.wiktionary.org/wiki/booed",
      ],
    },
  ],
  filmy: [
    {
      word: "filmy",
      phonetic: "/ˈfɪlmi/",
      phonetics: [{ text: "/ˈfɪlmi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Resembling or made of a thin film; gauzy",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Covered by (or as if by) a film; hazy",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/filmy"],
    },
  ],
  juror: [
    {
      word: "juror",
      phonetic: "/ˈdʒʊəɹɔɹ/",
      phonetics: [
        {
          text: "/ˈdʒʊəɹɔɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/juror-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=424722",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A member of a jury.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["jurat"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/juror"],
    },
  ],
  dents: [
    {
      word: "dents",
      phonetic: "/dɛnts/",
      phonetics: [
        {
          text: "/dɛnts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dents.mp3",
          sourceUrl:
            "https://commons.wikimedia.org/w/index.php?curid=100528634",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A shallow deformation in the surface of an object, produced by an impact.",
              synonyms: [],
              antonyms: [],
              example: "The crash produced a dent in the left side of the car.",
            },
            {
              definition:
                "A type of maize/corn with a relatively soft outer hull, and a soft type of starch that shrinks at maturity to leave an indentation in the surface of the kernel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) A sudden negative change, such as loss, damage, weakening, consumption or diminution, especially one produced by an external force, event or action",
              synonyms: [],
              antonyms: [],
              example: "That purchase put a bit of a dent in my wallet.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To impact something, producing a dent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To develop a dent or dents.",
              synonyms: [],
              antonyms: [],
              example: "Copper is soft and dents easily.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A tooth, as of a card, a gear wheel, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A slot or a wire in a reed",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dent",
        "https://en.wiktionary.org/wiki/dents",
      ],
    },
  ],
  gummy: [
    {
      word: "gummy",
      phonetic: "/ˈɡʌmi/",
      phonetics: [
        {
          text: "/ˈɡʌmi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gummy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75271755",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈɡʌmi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gummy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=60402650",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A sheep that is losing or has lost its teeth.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Showing the gums.",
              synonyms: [],
              antonyms: [],
              example: "a gummy grin",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A species of shark, Mustelus antarcticus, endemic to southern and south-eastern Australia, the meat of which is sold as flake.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gummy",
        "https://en.wiktionary.org/wiki/gummy%20shark",
      ],
    },
    {
      word: "gummy",
      phonetic: "/ˈɡʌmi/",
      phonetics: [
        {
          text: "/ˈɡʌmi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gummy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75271755",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈɡʌmi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gummy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=60402650",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(usually in the plural) A gummi candy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Resembling gum (the substance).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Covered with gum or a substance resembling gum.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["gluey", "gooey", "sticky", "tacky"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gummy"],
    },
  ],
  grays: [
    {
      word: "grays",
      phonetic: "/ɡɹeɪz/",
      phonetics: [{ text: "/ɡɹeɪz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An achromatic colour intermediate between black and white.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An animal or thing of grey colour, such as a horse, badger, or salmon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An extraterrestrial humanoid with grayish skin, bulbous black eyes, and an enlarged head.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A penny with a tail on both sides, used for cheating.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To become gray.",
              synonyms: [],
              antonyms: [],
              example: "My hair is beginning to gray.",
            },
            {
              definition: "To cause to become gray.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To turn progressively older, alluding to graying of hair through aging (used in context of the population of a geographic region)",
              synonyms: [],
              antonyms: [],
              example: "the graying of America",
            },
            {
              definition:
                "To give a soft effect to (a photograph) by covering the negative while printing with a ground-glass plate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "In the International System of Units, the derived unit of absorbed dose of radiation (radiation absorbed by a patient); one joule of energy absorbed per kilogram of the patient's mass. Symbol: Gy",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gray",
        "https://en.wiktionary.org/wiki/grays",
      ],
    },
  ],
  hooky: [
    {
      word: "hooky",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Absence from school or work.",
              synonyms: [],
              antonyms: [],
              example: "Let's play hooky and go to the mall.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hooky"],
    },
    {
      word: "hooky",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Full of hooks.", synonyms: [], antonyms: [] },
            { definition: "Shaped like a hook.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hooky"],
    },
  ],
  butte: [
    {
      word: "butte",
      phonetic: "/ˈbjuːt/",
      phonetics: [{ text: "/ˈbjuːt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An isolated hill with steep sides and a flat top.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/butte"],
    },
  ],
  dogie: [
    {
      word: "dogie",
      phonetic: "/ˈdəʊɡi/",
      phonetics: [
        { text: "/ˈdəʊɡi/", audio: "" },
        { text: "/ˈdoʊɡi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A motherless calf in a range herd of cattle; a calf separated from its cow.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bum calf", "bummer", "leppy"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dogie"],
    },
  ],
  poled: [
    {
      word: "poled",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To propel by pushing with poles, to push with a pole.",
              synonyms: [],
              antonyms: [],
              example:
                "Huck Finn poled that raft southward down the Mississippi because going northward against the current was too much work.",
            },
            {
              definition:
                "To identify something quite precisely using a telescope.",
              synonyms: [],
              antonyms: [],
              example:
                "He poled off the serial of the Gulfstream to confirm its identity.",
            },
            {
              definition: "To furnish with poles for support.",
              synonyms: [],
              antonyms: [],
              example: "to pole beans or hops",
            },
            {
              definition: "To convey on poles.",
              synonyms: [],
              antonyms: [],
              example: "to pole hay into a barn",
            },
            {
              definition: "To stir, as molten glass, with a pole.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To strike (the ball) very hard.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To induce piezoelectricity in (a substance) by aligning the dipoles.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pole",
        "https://en.wiktionary.org/wiki/poled",
      ],
    },
  ],
  reams: [
    {
      word: "reams",
      phonetic: "/ɹiːmz/",
      phonetics: [
        {
          text: "/ɹiːmz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/reams-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80066772",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cream; mantle; foam; froth.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To enlarge a hole, especially using a reamer; to bore a hole wider.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To shape or form, especially using a reamer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To remove (material) by reaming.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To remove burrs and debris from a freshly bored hole.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To yell at or berate.", synonyms: [], antonyms: [] },
            {
              definition:
                "(by extension from sense of enlarging a hole) To sexually penetrate in a rough and painful way.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dig out", "nail", "root", "tap"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A bundle, package, or quantity of paper, nowadays usually containing 500 sheets.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(chiefly in the plural) An abstract large amount of something.",
              synonyms: [],
              antonyms: [],
              example: "I can't go – I still have reams of work left.",
            },
          ],
          synonyms: ["bunch", "load", "pile"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ream",
        "https://en.wiktionary.org/wiki/reams",
      ],
    },
  ],
  fifes: [
    {
      word: "fifes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small shrill pipe, resembling the piccolo flute, used chiefly to accompany the drum in military music",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fife",
        "https://en.wiktionary.org/wiki/fifes",
      ],
    },
  ],
  spank: [
    {
      word: "spank",
      phonetic: "/spæŋk/",
      phonetics: [
        {
          text: "/spæŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spank-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=5819638",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An instance of spanking, separately or part of a multiple blows-beating; a smack, swat, or slap.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A slapping sound, as produced by spanking.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To beat, smack or slap a person's buttocks, with the bare hand or other object, as punishment, gesture, or form of sexual interaction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To soundly defeat, to trounce.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To hit very hard", synonyms: [], antonyms: [] },
            {
              definition: "To move rapidly.",
              synonyms: [],
              antonyms: [],
              example: "The ship was really spanking along.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spank"],
    },
  ],
  gayer: [
    {
      word: "gayer",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "(possibly obsolete) Happy, joyful, and lively.",
              synonyms: [],
              antonyms: [],
              example: "The Gay Science",
            },
            { definition: "Quick, fast.", synonyms: [], antonyms: [] },
            {
              definition: "(possibly obsolete) Festive, bright, or colourful.",
              synonyms: [],
              antonyms: [],
              example:
                "Pennsylvania Dutch include the plain folk and the gay folk.",
            },
            {
              definition:
                "Sexually promiscuous (of any gender), (sometimes particularly) engaged in prostitution.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Homosexual:", synonyms: [], antonyms: [] },
            { definition: "A pejorative:", synonyms: [], antonyms: [] },
            {
              definition: "(of a dog's tail) Upright or curved over the back.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(possibly obsolete) Considerable, great, large in number, size, or degree.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Somebody who is gay (in the sense of either homosexual or uncool).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gay",
        "https://en.wiktionary.org/wiki/gayer",
      ],
    },
  ],
  tepid: [
    {
      word: "tepid",
      phonetic: "/ˈtɛpɪd/",
      phonetics: [
        {
          text: "/ˈtɛpɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tepid-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75858700",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈtɛpɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tepid-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1763060",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Lukewarm; neither warm nor cool.",
              synonyms: ["lukewarm"],
              antonyms: [],
              example: "I'm drinking a cup of tepid water.",
            },
            {
              definition:
                "Uninterested; exhibiting little passion or eagerness.",
              synonyms: ["lukewarm", "uninterested"],
              antonyms: [],
              example: "He gave me a tepid response to the proposal.",
            },
          ],
          synonyms: ["lukewarm", "lukewarm", "uninterested"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tepid"],
    },
  ],
  spook: [
    {
      word: "spook",
      phonetic: "/spuːk/",
      phonetics: [
        {
          text: "/spuːk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spook-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241850",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A ghost or phantom.",
              synonyms: [],
              antonyms: [],
              example: "The building was haunted by a couple of spooks.",
            },
            { definition: "A hobgoblin.", synonyms: [], antonyms: [] },
            {
              definition: "A scare or fright.",
              synonyms: [],
              antonyms: [],
              example: "The big spider gave me a spook.",
            },
            { definition: "A spy.", synonyms: [], antonyms: [] },
            { definition: "A black person.", synonyms: [], antonyms: [] },
            {
              definition:
                "A metaphysical manifestation; an artificial distinction or construct.",
              synonyms: [],
              antonyms: [],
              example:
                "He who is infatuated with Man leaves persons out of account so far as that infatuation extends, and floats in an ideal, sacred interest. Man, you see, is not a person, but an ideal, a spook. — Max Stirner",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To frighten or make nervous (especially by startling).",
              synonyms: [],
              antonyms: [],
              example:
                "The hunters were spooked when the black cat crossed their path. The movement in the bushes spooked the deer and they ran.",
            },
            {
              definition: "To become frightened (by something startling).",
              synonyms: [],
              antonyms: [],
              example: "The deer spooked at the sound of the dogs.",
            },
            { definition: "To haunt.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spook"],
    },
  ],
  taint: [
    {
      word: "taint",
      phonetic: "/teɪnt/",
      phonetics: [
        {
          text: "/teɪnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/taint-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75701643",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/teɪnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/taint-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=378787",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A contamination, decay or putrefaction, especially in food",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A mark of disgrace, especially on one's character; blemish",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Tincture; hue; colour", synonyms: [], antonyms: [] },
            {
              definition: "Infection; corruption; deprivation",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A marker indicating that a variable is unsafe and should be subjected to additional security checks.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To contaminate or corrupt (something) with an external agent, either physically or morally.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To spoil (food) by contamination.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be infected or corrupted; to be touched by something corrupting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be affected with incipient putrefaction.",
              synonyms: [],
              antonyms: [],
              example: "Meat soon taints in warm weather.",
            },
            {
              definition:
                "To mark (a variable) as unsafe, so that operations involving it are subject to additional security checks.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To invalidate (a share capital account) by transferring profits into it.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/taint"],
    },
    {
      word: "taint",
      phonetic: "/teɪnt/",
      phonetics: [
        {
          text: "/teɪnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/taint-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75701643",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/teɪnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/taint-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=378787",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A thrust with a lance, which fails of its intended effect.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An injury done to a lance in an encounter, without its being broken; also, a breaking of a lance in an encounter in a dishonorable or unscientific manner.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To damage, as a lance, without breaking it; also, to break, as a lance, but usually in an unknightly or unscientific manner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To hit or touch lightly, in tilting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To thrust ineffectually with a lance.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/taint"],
    },
    {
      word: "taint",
      phonetic: "/teɪnt/",
      phonetics: [
        {
          text: "/teɪnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/taint-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75701643",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/teɪnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/taint-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=378787",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The perineum.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/taint"],
    },
  ],
  flirt: [
    {
      word: "flirt",
      phonetic: "/flɜːt/",
      phonetics: [
        { text: "/flɜːt/", audio: "" },
        {
          text: "/flɝt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/flirt-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780086",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sudden jerk; a quick throw or cast; a darting motion",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Someone who flirts a lot or enjoys flirting; a flirtatious person.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An act of flirting.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To throw (something) with a jerk or sudden movement; to fling.",
              synonyms: [],
              antonyms: [],
              example: "They flirt water in each other's faces.",
            },
            { definition: "To jeer at; to mock.", synonyms: [], antonyms: [] },
            {
              definition: "To dart about; to move with quick, jerky motions.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To blurt out.", synonyms: [], antonyms: [] },
            {
              definition:
                "To play at courtship; to talk with teasing affection, to insinuate sexual attraction in a playful (especially conversational) way.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To experiment, or tentatively engage, with; to become involved in passing with.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["belittle"],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Pert; wanton", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/flirt"],
    },
  ],
  rogue: [
    {
      word: "rogue",
      phonetic: "/ˈɹəʊ̯ɡ/",
      phonetics: [
        { text: "/ˈɹəʊ̯ɡ/", audio: "" },
        {
          text: "/ˈɹoʊ̯ɡ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rogue-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=720509",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A scoundrel, rascal or unprincipled, deceitful, and unreliable person.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A mischievous scamp.", synonyms: [], antonyms: [] },
            { definition: "A vagrant.", synonyms: [], antonyms: [] },
            {
              definition:
                "Deceitful software pretending to be anti-spyware, but in fact being malicious software itself.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An aggressive animal separate from the herd, especially an elephant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A plant that shows some undesirable variation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A character class focusing on stealthy conduct.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cull; to destroy plants not meeting a required standard, especially when saving seed, rogue or unwanted plants are removed before pollination.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To cheat.", synonyms: [], antonyms: [] },
            {
              definition:
                "To give the name or designation of rogue to; to decry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To wander; to play the vagabond; to play knavish tricks.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(of an animal, especially an elephant) Vicious and solitary.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) Large, destructive and unpredictable.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) Deceitful, unprincipled.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Mischievous, unpredictable.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rogue"],
    },
  ],
  spiky: [
    {
      word: "spiky",
      phonetic: "/ˈspaɪ.ki/",
      phonetics: [{ text: "/ˈspaɪ.ki/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Having spikes, spiny.", synonyms: [], antonyms: [] },
            {
              definition: "Hostile; standoffish",
              synonyms: [],
              antonyms: [],
              example: "a spiky personality",
            },
            {
              definition: "Of hair, erect, resembling spikes.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spiky"],
    },
  ],
  opals: [
    {
      word: "opals",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A mineral consisting, like quartz, of silica, but inferior to quartz in hardness and specific gravity, of the chemical formula SiO2·nH2O.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'A colloquial name used in molecular biology referring to a particular stop codon sequence, "UGA."',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various lycaenid butterflies of the genus Nesolycaena.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/opal",
        "https://en.wiktionary.org/wiki/opals",
      ],
    },
  ],
  miser: [
    {
      word: "miser",
      phonetic: "/ˈmaɪzə(ɹ)/",
      phonetics: [{ text: "/ˈmaɪzə(ɹ)/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person who hoards money rather than spending it; one who is cheap or extremely parsimonious.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A kind of earth auger, typically large-bored and often hand-operated.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["spendthrift"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/miser"],
    },
  ],
  cocky: [
    {
      word: "cocky",
      phonetic: "/ˈkɔki/",
      phonetics: [
        {
          text: "/ˈkɔki/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cocky-au.oga",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75270070",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈkɒki/", audio: "" },
        { text: "/ˈkɒki/", audio: "" },
        { text: "/ˈkɑki/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Overly confident; arrogant and boastful.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cocky"],
    },
    {
      word: "cocky",
      phonetic: "/ˈkɔki/",
      phonetics: [
        {
          text: "/ˈkɔki/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cocky-au.oga",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75270070",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈkɒki/", audio: "" },
        { text: "/ˈkɒki/", audio: "" },
        { text: "/ˈkɑki/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Used as a term of endearment, originally for a person of either sex, but later primarily for a man.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cocky"],
    },
    {
      word: "cocky",
      phonetic: "/ˈkɔki/",
      phonetics: [
        {
          text: "/ˈkɔki/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cocky-au.oga",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75270070",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈkɒki/", audio: "" },
        { text: "/ˈkɒki/", audio: "" },
        { text: "/ˈkɑki/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A (familiar name for a) cockatoo.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Short for cockatoo farmer; (by extension) any farmer or owner of rural land.",
              synonyms: ["cockatoo", "crofter"],
              antonyms: [],
            },
          ],
          synonyms: ["cockatoo", "crofter"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To operate a small-scale farm.",
              synonyms: ["cockatoo"],
              antonyms: [],
            },
          ],
          synonyms: ["cockatoo"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cocky"],
    },
  ],
  coyly: [
    {
      word: "coyly",
      phonetic: "/ˈkɔɪli/",
      phonetics: [{ text: "/ˈkɔɪli/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            { definition: "In a coy manner.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/coyly"],
    },
  ],
  balmy: [
    {
      word: "balmy",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/balmy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75729806",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈbɑː.mi/", audio: "" },
        { text: "/ˈbɑː.mi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Producing balm.", synonyms: [], antonyms: [] },
            { definition: "Soothing or fragrant.", synonyms: [], antonyms: [] },
            { definition: "Mild and pleasant.", synonyms: [], antonyms: [] },
            {
              definition: "Foolish; slightly crazy or mad; eccentric.",
              synonyms: ["barmy"],
              antonyms: [],
            },
          ],
          synonyms: ["barmy"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/balmy"],
    },
  ],
  slosh: [
    {
      word: "slosh",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/slosh-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=949285",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A quantity of a liquid; more than a splash.",
              synonyms: [],
              antonyms: [],
              example: "We added a slosh of white wine to the sauce.",
            },
            {
              definition: "A sloshing sound or motion.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Slush.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(of a liquid) To shift chaotically; to splash noisily.",
              synonyms: [],
              antonyms: [],
              example:
                "The water in his bottle sloshed back and forth as he ran.",
            },
            {
              definition: "(of a liquid) To cause to slosh",
              synonyms: [],
              antonyms: [],
              example: "The boy sloshed water over the edge of the bath.",
            },
            {
              definition: "To make a sloshing sound.",
              synonyms: [],
              antonyms: [],
              example:
                "They were so completely soaked that they sloshed when he walked.",
            },
            {
              definition:
                "(of a liquid) To pour noisily, sloppily or in large amounts",
              synonyms: [],
              antonyms: [],
              example:
                "He really sloshed on the sauce- they were a bit strong for my taste.",
            },
            {
              definition: "To move noisily through water or other liquid.",
              synonyms: [],
              antonyms: [],
              example:
                "The streets were flooded, but they still managed to slosh their way to school.",
            },
            { definition: "To punch (someone).", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/slosh"],
    },
    {
      word: "slosh",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/slosh-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=949285",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Backslash, the character \\.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/slosh"],
    },
  ],
  brawl: [
    {
      word: "brawl",
      phonetic: "/bɹɔːl/",
      phonetics: [
        {
          text: "/bɹɔːl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/brawl-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80832865",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/bɹɔl/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A disorderly argument or fight, usually with a large number of people involved.",
              synonyms: ["row", "scuffle", "squabble"],
              antonyms: [],
            },
          ],
          synonyms: ["row", "scuffle", "squabble"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To engage in a brawl; to fight or quarrel.",
              synonyms: ["squabble", "wrangle"],
              antonyms: [],
            },
            {
              definition: "To create a disturbance; to complain loudly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Especially of a rapid stream running over stones: to make a loud, confused noise.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To pour abuse on; to scold.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["squabble", "wrangle"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/brawl"],
    },
    {
      word: "brawl",
      phonetic: "/bɹɔːl/",
      phonetics: [
        {
          text: "/bɹɔːl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/brawl-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80832865",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/bɹɔl/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To move to and fro, to quiver, to shake.",
              synonyms: ["vibrate", "waver"],
              antonyms: [],
            },
          ],
          synonyms: ["vibrate", "waver"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/brawl"],
    },
    {
      word: "brawl",
      phonetic: "/bɹɔːl/",
      phonetics: [
        {
          text: "/bɹɔːl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/brawl-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80832865",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/bɹɔl/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A dance of French origin dating from the 16th century, performed by couples in a circle or a line; the music for this dance.",
              synonyms: ["brangle", "bransle", "brantle"],
              antonyms: [],
            },
          ],
          synonyms: ["brangle", "bransle", "brantle"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A type of dance move or step.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/branle",
        "https://en.wiktionary.org/wiki/brawl",
      ],
    },
  ],
  aphid: [
    {
      word: "aphid",
      phonetic: "/ˈeɪ.fɪd/",
      phonetics: [
        { text: "/ˈeɪ.fɪd/", audio: "" },
        { text: "/ˈeɪ.fɪd/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Sapsucking pest insect of the superfamily Aphidoidea; an aphidian.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/aphid"],
    },
  ],
  faked: [
    {
      word: "faked",
      phonetic: "/feɪkt/",
      phonetics: [{ text: "/feɪkt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cheat; to swindle; to steal; to rob.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To modify fraudulently, so as to make an object appear better or other than it really is",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make a counterfeit, to counterfeit, to forge, to falsify.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make a false display of, to affect, to feign, to simulate.",
              synonyms: [],
              antonyms: [],
              example: "to fake a marriage",
            },
          ],
          synonyms: ["pass off", "pose", "adulterate"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To coil (a rope, line, or hawser), by winding alternately in opposite directions, in layers usually of zigzag or figure of eight form, to prevent twisting when running out.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fake",
        "https://en.wiktionary.org/wiki/faked",
      ],
    },
  ],
  hydra: [
    {
      word: "hydra",
      phonetic: "/ˈhaɪdɹə/",
      phonetics: [
        {
          text: "/ˈhaɪdɹə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hydra-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89259510",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of several small freshwater polyps of the genus Hydra and related genera, having a naked cylindrical body and an oral opening surrounded by tentacles.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A dragon-like creature with many heads and the ability to regrow them when maimed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A complex, multifarious problem or situation that cannot be solved easily and rapidly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hydra"],
    },
  ],
  brags: [
    {
      word: "brags",
      phonetic: "/bɹæɡz/",
      phonetics: [{ text: "/bɹæɡz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To boast; to talk with excessive pride about what one has, is able to do, or has done; often as an attempt to popularize oneself.",
              synonyms: [],
              antonyms: [],
              example: "to brag of one's exploits, courage, or money",
            },
            { definition: "To boast of.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["boast"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/brag",
        "https://en.wiktionary.org/wiki/brags",
      ],
    },
  ],
  chide: [
    {
      word: "chide",
      phonetic: "/tʃaɪd/",
      phonetics: [{ text: "/tʃaɪd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To admonish in blame; to reproach angrily.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To utter words of disapprobation and displeasure; to find fault; to contend angrily.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make a clamorous noise; to chafe.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chide"],
    },
  ],
  yanks: [
    {
      word: "yanks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sudden, vigorous pull (sometimes defined as mass times jerk, or rate of change of force).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A masturbation session.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["jerk", "tug"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To pull (something) with a quick, strong action.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To remove from distribution.",
              synonyms: [],
              antonyms: [],
              example:
                "They yanked the product as soon as they learned it was unsafe.",
            },
          ],
          synonyms: ["jerk", "tug", "pull", "recall"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A Yankee.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/yank",
        "https://en.wiktionary.org/wiki/yanks",
      ],
    },
  ],
  allay: [
    {
      word: "allay",
      phonetic: "/əˈleɪ/",
      phonetics: [
        {
          text: "/əˈleɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/allay-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=81996473",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Alleviation; abatement; check.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An alloy.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make quiet or put at rest; to pacify or appease; to quell; to calm.",
              synonyms: [
                "appease",
                "assuage",
                "calm",
                "compose",
                "quiet",
                "soothe",
              ],
              antonyms: [],
              example: "to allay popular excitement",
            },
            {
              definition: "To alleviate; to abate; to mitigate.",
              synonyms: ["abate", "alleviate"],
              antonyms: [],
              example:
                "to allay the severity of affliction or the bitterness of adversity",
            },
            {
              definition: "To subside, abate, become peaceful.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To mix (metals); to mix with a baser metal; to alloy; to deteriorate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) To make worse by the introduction of inferior elements.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "abate",
            "alleviate",
            "appease",
            "assuage",
            "calm",
            "compose",
            "quiet",
            "soothe",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/allay"],
    },
  ],
  video: [
    {
      word: "video",
      phonetic: "/ˈvɪ.di.əʊ/",
      phonetics: [
        { text: "/ˈvɪ.di.əʊ/", audio: "" },
        {
          text: "/ˈvɪ.diˌoʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/video-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=5091997",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Television, television show, movie.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A short film clip, with or without audio (as in a music video, or one of the plethora of user-generated short movies on sites such as YouTube).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Motion picture stored on VHS or some other format.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "VHS.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To record using a video camera, to videotape",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To record a television program",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/video"],
    },
  ],
  altos: [
    {
      word: "altos",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A musical part or section higher than tenor and lower than soprano, formerly the part that performed a countermelody above the tenor or main melody.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person or musical instrument that performs the alto part.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An alto saxophone", synonyms: [], antonyms: [] },
          ],
          synonyms: ["contratenor altus", "high countertenor"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/alto",
        "https://en.wiktionary.org/wiki/altos",
      ],
    },
  ],
  eases: [
    {
      word: "eases",
      phonetic: "/ˈiːzɪz/",
      phonetics: [{ text: "/ˈiːzɪz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To free (something) from pain, worry, agitation, etc.",
              synonyms: [],
              antonyms: [],
              example: "He eased his conscience by confessing.",
            },
            {
              definition: "To alleviate, assuage or lessen (pain).",
              synonyms: [],
              antonyms: [],
              example: "He loosened his shoe to ease the pain.",
            },
            {
              definition: "To give respite to (someone).",
              synonyms: [],
              antonyms: [],
              example: "The provision of extra staff eased their workload.",
            },
            {
              definition: "To loosen or slacken the tension on a line.",
              synonyms: [],
              antonyms: [],
              example: "We eased the boom vang, then lowered the sail.",
            },
            {
              definition: "To reduce the difficulty of (something).",
              synonyms: [],
              antonyms: [],
              example: "We had to ease the entry requirements.",
            },
            {
              definition: "To move (something) slowly and carefully.",
              synonyms: [],
              antonyms: [],
              example: "He eased the cork from the bottle.",
            },
            {
              definition: "To lessen in severity.",
              synonyms: [],
              antonyms: [],
              example: "The pain eased overnight.",
            },
            {
              definition: "To proceed with little effort.",
              synonyms: [],
              antonyms: [],
              example: "The car eased onto the motorway.",
            },
          ],
          synonyms: [
            "allay",
            "alleviate",
            "assuage",
            "lessen",
            "reduce",
            "assuage",
            "salve",
            "give someone a break",
            "lay off",
            "lessen",
            "reduce",
            "loosen",
            "relax",
            "slacken",
            "cruise",
            "facilitate",
            "simplify",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ease",
        "https://en.wiktionary.org/wiki/eases",
      ],
    },
  ],
  meted: [
    {
      word: "meted",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To measure.", synonyms: [], antonyms: [] },
            {
              definition:
                "(usually with “out”) To dispense, measure (out), allot (especially punishment, reward etc.).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mete",
        "https://en.wiktionary.org/wiki/meted",
      ],
    },
  ],
  chasm: [
    {
      word: "chasm",
      phonetic: "/ˈkæz(ə)m/",
      phonetics: [
        {
          text: "/ˈkæz(ə)m/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chasm-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1784069",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A deep, steep-sided rift, gap or fissure; a gorge or abyss.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) A large difference of opinion.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chasm"],
    },
  ],
  longs: [
    {
      word: "longs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A long vowel.", synonyms: [], antonyms: [] },
            { definition: "A long syllable.", synonyms: [], antonyms: [] },
            {
              definition:
                "A note formerly used in music, one half the length of a large, twice that of a breve.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A long integer variable, twice the size of an int, two or four times the size of a short, and half of a long long.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An entity with a long position in an asset.",
              synonyms: [],
              antonyms: [],
              example: "Every uptick made the longs cheer.",
            },
            {
              definition: "A long-term investment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The long summer vacation at the English universities.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To take a long position in.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To await, aspire, desire greatly (something to occur or to be true)",
              synonyms: [],
              antonyms: [],
              example: "She longed for him to come back.",
            },
          ],
          synonyms: ["ache", "yearn"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To be appropriate to, to pertain or belong to.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Longitude", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To belong.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Long trousers/pants, as opposed to shorts.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/long",
        "https://en.wiktionary.org/wiki/longs",
      ],
    },
  ],
  excel: [
    {
      word: "excel",
      phonetic: "/ɪkˈsɛl/",
      phonetics: [{ text: "/ɪkˈsɛl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To surpass someone or something; to be better or do better than someone or something.",
              synonyms: [],
              antonyms: [],
              example: "I excelled everyone else with my exam results.",
            },
            {
              definition: "To be much better than others.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To exceed, to go beyond",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "rock",
            "rule",
            "exceed",
            "overstep",
            "surpass",
            "transcend",
            "transgress",
            "better",
            "excel",
            "outclass",
            "outperform",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/excel"],
    },
  ],
  taffy: [
    {
      word: "taffy",
      phonetic: "/ˈtæ.fi/",
      phonetics: [{ text: "/ˈtæ.fi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A soft, chewy candy made from boiled molasses or brown sugar.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Flattery.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/taffy"],
    },
  ],
  impel: [
    {
      word: "impel",
      phonetic: "/ɪmˈpɛl/",
      phonetics: [
        { text: "/ɪmˈpɛl/", audio: "" },
        {
          text: "/ɪmˈpɛl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/impel-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=3861353",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To urge a person; to press on; to incite to action or motion via intrinsic motivation.",
              synonyms: [],
              antonyms: ["propel"],
            },
            {
              definition:
                "To drive forward; to propel an object, to provide an impetus for motion or action.",
              synonyms: ["propel"],
              antonyms: [],
            },
          ],
          synonyms: ["propel"],
          antonyms: ["expel", "propel"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/impel"],
    },
  ],
  savor: [
    {
      word: "savor",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The specific taste or smell of something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A distinctive sensation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Sense of smell; power to scent, or trace by scent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Pleasure; appreciation; relish.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/savor",
        "https://en.wiktionary.org/wiki/savour",
      ],
    },
    {
      word: "savor",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To possess a particular taste or smell, or a distinctive quality.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To appreciate, enjoy or relish something.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To season.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/savor",
        "https://en.wiktionary.org/wiki/savour",
      ],
    },
  ],
  koala: [
    {
      word: "koala",
      phonetic: "/koʊ.ˈɑː.lə/",
      phonetics: [{ text: "/koʊ.ˈɑː.lə/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A tree-dwelling marsupial, Phascolarctos cinereus, that resembles a small bear with a broad head, large ears and sharp claws, mainly found in eastern Australia.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "Phascolarctos cinereus",
            "koala bear",
            "native bear",
            "native monkey",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/koala"],
    },
  ],
  quays: [
    {
      word: "quays",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A stone or concrete structure on navigable water used for loading and unloading vessels; a wharf.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/quay",
        "https://en.wiktionary.org/wiki/quays",
      ],
    },
  ],
  dawns: [
    {
      word: "dawns",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The morning twilight period immediately before sunrise.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The rising of the sun.",
              synonyms: [
                "break of dawn",
                "break of day",
                "day-dawn",
                "dayspring",
                "sunrise",
              ],
              antonyms: [],
            },
            {
              definition: "The time when the sun rises.",
              synonyms: [
                "break of dawn",
                "break of day",
                "crack of dawn",
                "day-dawn",
                "daybreak",
                "dayspring",
                "sunrise",
                "sunup",
              ],
              antonyms: [],
              example: "She rose before dawn to meet the train.",
            },
            {
              definition: "The earliest phase of something.",
              synonyms: ["beginning", "onset", "start"],
              antonyms: [],
              example: "the dawn of civilization",
            },
          ],
          synonyms: [
            "beginning",
            "onset",
            "start",
            "break of dawn",
            "break of day",
            "crack of dawn",
            "day-dawn",
            "daybreak",
            "dayspring",
            "sunrise",
            "sunup",
            "break of dawn",
            "break of day",
            "day-dawn",
            "dayspring",
            "sunrise",
          ],
          antonyms: ["dusk"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To begin to brighten with daylight.",
              synonyms: [],
              antonyms: [],
              example: "A new day dawns.",
            },
            {
              definition: "To start to appear or be realized.",
              synonyms: [],
              antonyms: [],
              example: "I don’t want to be there when the truth dawns on him.",
            },
            {
              definition:
                "To begin to give promise; to begin to appear or to expand.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dawn",
        "https://en.wiktionary.org/wiki/dawns",
      ],
    },
  ],
  proxy: [
    {
      word: "proxy",
      phonetic: "/ˈpɹɒk.si/",
      phonetics: [
        { text: "/ˈpɹɒk.si/", audio: "" },
        {
          text: "/ˈpɹɑk.si/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/proxy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1287237",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An agent or substitute authorized to act for another person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The authority to act for another, especially when written.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The written appointment of a proctor in suits in the ecclesiastical courts.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A measurement of one physical quantity that is used as an indicator of the value of another",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An interface for a service, especially for one that is remote, resource-intensive, or otherwise difficult to use directly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["deputy", "representative", "substitute"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To serve as a proxy for.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To function as a server for a client device, but pass on the requests to another server for service.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Used as a proxy or acting as a proxy.",
              synonyms: [],
              antonyms: [],
              example: "a proxy indicator",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/proxy"],
    },
    {
      word: "proxy",
      phonetic: "/ˈpɹɒk.si/",
      phonetics: [
        { text: "/ˈpɹɒk.si/", audio: "" },
        {
          text: "/ˈpɹɑk.si/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/proxy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1287237",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A proximity mine; a mine that explodes when something approaches within a certain distance.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["proxy mine"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/proxy"],
    },
  ],
  clove: [
    {
      word: "clove",
      phonetic: "/kləʊv/",
      phonetics: [
        { text: "/kləʊv/", audio: "" },
        {
          text: "/kloʊv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/clove-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=882470",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A very pungent aromatic spice, the unexpanded flower bud of the clove tree.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A clove tree, of the species Syzygium aromaticum (syn. Caryophyllus aromaticus), native to the Moluccas (Indonesian islands), which produces the spice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An old English measure of weight, containing 7 pounds (3.2 kg), i.e. half a stone.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/clove"],
    },
    {
      word: "clove",
      phonetic: "/kləʊv/",
      phonetics: [
        { text: "/kləʊv/", audio: "" },
        {
          text: "/kloʊv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/clove-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=882470",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any one of the separate bulbs that make up the larger bulb of garlic.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/clove"],
    },
    {
      word: "clove",
      phonetic: "/kləʊv/",
      phonetics: [
        { text: "/kləʊv/", audio: "" },
        {
          text: "/kloʊv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/clove-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=882470",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To split or sever something with, or as if with, a sharp instrument.",
              synonyms: [],
              antonyms: [],
              example: "The wings cleaved the foggy air.",
            },
            {
              definition:
                "To break a single crystal (such as a gemstone or semiconductor wafer) along one of its more symmetrical crystallographic planes (often by impact), forming facets on the resulting pieces.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make or accomplish by or as if by cutting.",
              synonyms: [],
              antonyms: [],
              example: "The truck cleaved a path through the ice.",
            },
            {
              definition:
                "To split (a complex molecule) into simpler molecules.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To split.", synonyms: [], antonyms: [] },
            {
              definition:
                "Of a crystal, to split along a natural plane of division.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cleave",
        "https://en.wiktionary.org/wiki/clove",
      ],
    },
    {
      word: "clove",
      phonetic: "/kləʊv/",
      phonetics: [
        { text: "/kləʊv/", audio: "" },
        {
          text: "/kloʊv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/clove-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=882470",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A narrow valley with steep sides, used in areas of North America first settled by the Dutch",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/clove"],
    },
  ],
  duets: [
    {
      word: "duets",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A musical composition in two parts, each performed by a single voice (singer, instrument or univoce ensemble).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A song composed for and/or performed by a duo.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A pair or couple, especially one that is harmonious or elegant.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["duo", "couple", "pair", "twosome"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/duet",
        "https://en.wiktionary.org/wiki/duets",
      ],
    },
  ],
  dregs: [
    {
      word: "dregs",
      phonetic: "/dɹɛɡz/",
      phonetics: [
        {
          text: "/dɹɛɡz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dregs-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=371352",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(collectively) The sediment settled at the bottom of a liquid; the lees in a container of unfiltered wine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(the dregs) The worst and lowest part of something.",
              synonyms: [],
              antonyms: [],
              example: "the dregs of society",
            },
          ],
          synonyms: [
            "debris",
            "deposit",
            "draff",
            "dross",
            "exuviate",
            "feculence",
            "grounds",
            "grouts",
            "lees",
            "loser",
            "orts",
            "outcast",
            "rabble",
            "refuse",
            "residue",
            "residuum",
            "riffraff",
            "rubbish",
            "scum",
            "sediment",
            "settling",
            "trash",
            "trub",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dregs"],
    },
  ],
  tardy: [
    {
      word: "tardy",
      phonetic: "/ˈtɑːdi/",
      phonetics: [
        { text: "/ˈtɑːdi/", audio: "" },
        {
          text: "/ˈtɑɹdi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tardy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=269543",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A piece of paper given to students who are late to class.",
              synonyms: [],
              antonyms: [],
              example:
                "The teacher gave her a tardy because she did not come into the classroom until after the bell.",
            },
            {
              definition:
                "An instance of a student being marked as tardy by a teacher in his or her attendance sheet.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To make tardy.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Late; overdue or delayed.",
              synonyms: [],
              antonyms: [],
              example: "He yawned, then raised a tardy hand over his mouth.",
            },
            {
              definition: "Moving with a slow pace or motion; not swift.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Ineffectual; slow-witted, slow to act, or dull.",
              synonyms: [],
              antonyms: [],
              example: "His tardy performance bordered on incompetence.",
            },
            {
              definition:
                "Unwary; unready (especially in the phrase take (someone) tardy).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Criminal; guilty.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["belated", "delayed"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tardy"],
    },
  ],
  briar: [
    {
      word: "briar",
      phonetic: "/bɹʌɪə/",
      phonetics: [{ text: "/bɹʌɪə/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of many plants with thorny stems growing in dense clusters, such as many in the Rosa, Rubus, and Smilax genera.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Anything sharp or unpleasant to the feelings.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/briar"],
    },
    {
      word: "briar",
      phonetic: "/bɹʌɪə/",
      phonetics: [{ text: "/bɹʌɪə/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The white heath, Erica arborea, a thorny Mediterranean shrub.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A pipe for smoking, made from the roots of that shrub.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/briar"],
    },
  ],
  grimy: [
    {
      word: "grimy",
      phonetic: "/ˈɡɹaɪmi/",
      phonetics: [{ text: "/ˈɡɹaɪmi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Stained or covered with grime.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "From the urban musical genre called grime.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/grimy"],
    },
  ],
  ultra: [
    {
      word: "ultra",
      phonetic: "/ˈʌltɹə/",
      phonetics: [{ text: "/ˈʌltɹə/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An ultraroyalist in France.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An extremist, especially an ultranationalist.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An especially devoted football fan, typically associated with the intimidating use of extremist slogans, pyrotechnics and sometimes hooligan violence.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An ultramarathon.", synonyms: [], antonyms: [] },
            {
              definition: "An ultra-prominent peak.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(usually capitalised) Code name used by British codebreakers during World War 2 for decrypted information gained from the enemy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Extreme; far beyond the norm; fanatical; uncompromising.",
              synonyms: [],
              antonyms: [],
              example: "an ultra reformer; ultra measures",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ultra"],
    },
  ],
  meaty: [
    {
      word: "meaty",
      phonetic: "/ˈmiːti/",
      phonetics: [{ text: "/ˈmiːti/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of, relating to, or containing meat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Resembling meat in flavour, etc.",
              synonyms: [],
              antonyms: [],
              example:
                "This tastes rather meaty. Are you sure it's vegetarian?",
            },
            {
              definition: "Of a person or a body part, large and solid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Substantial.",
              synonyms: [],
              antonyms: [],
              example: "I got a nice, meaty bonus.",
            },
          ],
          synonyms: [
            "bulky",
            "chunky",
            "muscular",
            "solid",
            "stocky",
            "fleshy",
            "substantial",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/meaty"],
    },
  ],
  halve: [
    {
      word: "halve",
      phonetic: "/hɑːv/",
      phonetics: [
        {
          text: "/hɑːv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/halve-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90914539",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/hæv/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To reduce to half the original amount.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To divide into two halves.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To make up half of.", synonyms: [], antonyms: [] },
            {
              definition:
                "To join two pieces of timber etc. by cutting away each for half its thickness at the joining place, and fitting together.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "In match play, to achieve a tie or draw on.",
              synonyms: [],
              antonyms: [],
              example: "to halve a hole or a match",
            },
          ],
          synonyms: ["dichotomize", "dimidiate"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/halve"],
    },
  ],
  wails: [
    {
      word: "wails",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A prolonged cry, usually high-pitched, especially as of grief or anguish.",
              synonyms: [],
              antonyms: [],
              example: "She let out a loud, doleful wail.",
            },
            {
              definition: "Any similar sound as of lamentation; a howl.",
              synonyms: [],
              antonyms: [],
              example: "A bird's wail in the night.",
            },
            {
              definition:
                'A sound made by emergency vehicle sirens, contrasted with "yelp" which is higher-pitched and faster.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cry out, as in sorrow or anguish.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To weep, lament persistently or bitterly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make a noise like mourning or crying.",
              synonyms: [],
              antonyms: [],
              example: "The wind wailed and the rain streamed down.",
            },
            {
              definition: "To lament; to bewail; to grieve over.",
              synonyms: [],
              antonyms: [],
              example: "to wail one's death",
            },
            {
              definition: "To perform with great liveliness and force.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To strike the skin in such a way as to produce a wale or welt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To give a surface a texture of wales or welts.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To choose, select.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wail",
        "https://en.wiktionary.org/wiki/wails",
        "https://en.wiktionary.org/wiki/wale",
      ],
    },
  ],
  suede: [
    {
      word: "suede",
      phonetic: "/sweɪd/",
      phonetics: [
        {
          text: "/sweɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/suede-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780412",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A type of soft leather, made from calfskin, with a brushed texture to resemble fabric, often used to make boots, clothing and fashion accessories.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make (leather) into suede.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Made of suede", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/suede"],
    },
  ],
  mauve: [
    {
      word: "mauve",
      phonetic: "/məʊv/",
      phonetics: [
        {
          text: "/məʊv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mauve-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=12523367",
        },
        {
          text: "/moʊv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mauve-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=694083",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A bright purple synthetic dye.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The colour of this dye; a pale purple or violet colour.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having a pale purple colour.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mauve"],
    },
  ],
  envoy: [
    {
      word: "envoy",
      phonetic: "/ˈɛn.vɔɪ/",
      phonetics: [
        { text: "/ˈɛn.vɔɪ/", audio: "" },
        { text: "/ˈɑn.vɔɪ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A short stanza at the end of a poem, used either to address a person or to comment on the preceding body of the poem.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A diplomatic agent of the second rank, next in status after an ambassador.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A representative.", synonyms: [], antonyms: [] },
            { definition: "A diplomat.", synonyms: [], antonyms: [] },
            { definition: "A messenger.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["envoy extraordinary", "minister plenipotentiary"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/envoi",
        "https://en.wiktionary.org/wiki/envoy",
      ],
    },
  ],
  arson: [
    {
      word: "arson",
      phonetic: "/ˈɑːsən/",
      phonetics: [
        { text: "/ˈɑːsən/", audio: "" },
        { text: "/ˈɑɹsən/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The crime of deliberately starting a fire with intent to cause damage.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To illegally set fire to; to burn down in a criminal manner",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/arson"],
    },
  ],
  coves: [
    {
      word: "coves",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A hollow in a rock; a cave or cavern.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A concave vault or archway, especially the arch of a ceiling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small coastal inlet, especially one having high cliffs protecting vessels from prevailing winds.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A strip of prairie extending into woodland.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A recess or sheltered area on the slopes of a mountain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The wooden roof of the stern gallery of an old sailing warship.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A thin line, sometimes gilded, along a yacht's strake below deck level.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(thieves' cant) A fellow; a man.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A friend; a mate.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cove",
        "https://en.wiktionary.org/wiki/coves",
      ],
    },
  ],
  gooey: [
    {
      word: "gooey",
      phonetic: "/ˈɡuː.i/",
      phonetics: [
        {
          text: "/ˈɡuː.i/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gooey-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=268411",
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Of or relating to goo", synonyms: [], antonyms: [] },
            {
              definition: "Soft, sticky and viscous",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["gummy", "icky", "sticky"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gooey"],
    },
  ],
  brews: [
    {
      word: "brews",
      phonetic: "/bɹuːz/",
      phonetics: [{ text: "/bɹuːz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The mixture formed by brewing; that which is brewed; a brewage, such as a cup of tea or a brewed beer.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A beer.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make tea or coffee by mixing tea leaves or coffee beans with hot water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To heat wine, infusing it with spices; to mull.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make a hot soup by combining ingredients and boiling them in water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make beer by steeping a starch source in water and fermenting the resulting sweet liquid with yeast.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To foment or prepare, as by brewing",
              synonyms: ["contrive", "hatch", "plot"],
              antonyms: [],
            },
            {
              definition:
                "To attend to the business, or go through the processes, of brewing or making beer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of an unwelcome event) To be in a state of preparation; to be mixing, forming, or gathering.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To boil or seethe; to cook.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["contrive", "hatch", "plot"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A hill or overhanging cliff.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/brew",
        "https://en.wiktionary.org/wiki/brews",
      ],
    },
  ],
  sofas: [
    {
      word: "sofas",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A raised area of a building's floor, usually covered with carpeting, used for sitting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(furniture) An upholstered seat with a raised back and one or two raised ends, long enough to comfortably accommodate two or more people.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["couch", "divan", "lounge", "settee"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To furnish with one or more sofas.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To seat or lay down on a sofa.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sofa",
        "https://en.wiktionary.org/wiki/sofas",
      ],
    },
  ],
  chums: [
    {
      word: "chums",
      phonetic: "/tʃʌmz/",
      phonetics: [{ text: "/tʃʌmz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A friend; a pal.",
              synonyms: [],
              antonyms: [],
              example: "I ran into an old chum from school the other day.",
            },
            {
              definition: "A roommate, especially in a college or university.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To share rooms with someone; to live together.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To lodge (somebody) with another person or people.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make friends; to socialize.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To accompany.",
              synonyms: [],
              antonyms: [],
              example: "I'll chum you down to the shops.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cast chum into the water to attract fish.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A coarse mould for holding the clay while being worked on a whirler, lathe or manually.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/chum",
        "https://en.wiktionary.org/wiki/chums",
      ],
    },
  ],
  amaze: [
    {
      word: "amaze",
      phonetic: "/əˈmeɪz/",
      phonetics: [
        { text: "/əˈmeɪz/", audio: "" },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/amaze-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=11546272",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Amazement, astonishment.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To fill with wonder and surprise; to astonish, astound, surprise or perplex.",
              synonyms: [],
              antonyms: [],
              example: "He was amazed when he found that the girl was a robot.",
            },
            {
              definition: "To undergo amazement; to be astounded.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To stupefy; to knock unconscious.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To bewilder; to stupefy; to bring into a maze.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To terrify, to fill with panic.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/amaze"],
    },
  ],
  zooms: [
    {
      word: "zooms",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To communicate with someone using the Zoom videoconferencing software.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A humming noise from something moving very fast",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A quick ascent", synonyms: [], antonyms: [] },
            { definition: "A big increase", synonyms: [], antonyms: [] },
            {
              definition: "An augmentation of a view",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To move fast with a humming noise",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fly an airplane straight up",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To move rapidly", synonyms: [], antonyms: [] },
            {
              definition: "To go up sharply",
              synonyms: [],
              antonyms: [],
              example: "prices zoomed",
            },
            {
              definition: "To change the focal length of a zoom lens",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(used with in or out) to manipulate a display so as to magnify or shrink it",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/Zoom",
        "https://en.wiktionary.org/wiki/zoom",
        "https://en.wiktionary.org/wiki/zooms",
      ],
    },
  ],
  abbot: [
    {
      word: "abbot",
      phonetic: "/ˈæb.ət/",
      phonetics: [
        { text: "/ˈæb.ət/", audio: "" },
        {
          text: "/ˈæb.ət/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/abbot-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1755014",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The superior or head of an abbey or monastery.",
              synonyms: [],
              antonyms: [],
              example:
                "The newly appointed abbot decided to take a tour of the abbey with the cardinal's emissary.",
            },
            {
              definition:
                "The pastor and/or administrator of an order, including minor and major orders starting with the minor order of porter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A layman who received the abbey's revenues, after the closing of the monasteries.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(British slang) A brothel-owner's husband or lover.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(British slang) A ponce; a man employed by a prostitute to find clients, and who may also act as a bodyguard or equivalent to a bouncer.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/abbot"],
    },
  ],
  halos: [
    {
      word: "halos",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A circular band of coloured light, visible around the sun or moon etc., caused by reflection and refraction of light by ice crystals in the atmosphere.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cloud of gas and other matter surrounding and captured by the gravitational field of a large diffuse astronomical object, such as a galaxy or cluster of galaxies.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Anything resembling this band, such as an effect caused by imperfect developing of photographs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Nimbus, a luminous disc, often of gold, around or over the heads of saints, etc., in religious paintings.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The metaphorical aura of glory, veneration or sentiment which surrounds an idealized entity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(iconography) a circular annulus ring, frequently luminous, often golden, floating above the head",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A circular brace used to keep the head and neck in position.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A rollbar placed in front of the driver, used to protect the cockpit of a open cockpit racecar.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["aureole", "nimbus"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/halo",
        "https://en.wiktionary.org/wiki/halos",
      ],
    },
  ],
  scour: [
    {
      word: "scour",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scour-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75857720",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈskaʊə/", audio: "" },
        {
          text: "/skaʊɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scour-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=617694",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The removal of sediment caused by swiftly moving water.",
              synonyms: [],
              antonyms: [],
              example:
                "Bridge scour may scoop out scour holes and compromise the integrity of the structure.",
            },
            {
              definition:
                "A place scoured out by running water, as in the bed of a stream below a waterfall.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A place where wool is washed to remove grease and impurities prior to processing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To clean, polish, or wash something by rubbing and scrubbing it vigorously, frequently with an abrasive or cleaning agent.",
              synonyms: [],
              antonyms: [],
              example: "He scoured the burnt food from the pan.",
            },
            {
              definition:
                "To remove debris and dirt by purging; to sweep along or off (by a current of water).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To clear the digestive tract by administering medication that induces defecation or vomiting; to purge.",
              synonyms: [],
              antonyms: [],
              example: "to scour a horse",
            },
            {
              definition:
                "To (cause livestock to) suffer from diarrhoea or dysentery.",
              synonyms: [],
              antonyms: [],
              example: "If a lamb is scouring, do not delay treatment.",
            },
            {
              definition: "To cleanse (without rubbing).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scour"],
    },
    {
      word: "scour",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scour-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75857720",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈskaʊə/", audio: "" },
        {
          text: "/skaʊɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scour-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=617694",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To search an area thoroughly.",
              synonyms: [],
              antonyms: [],
              example: "They scoured the scene of the crime for clues.",
            },
            {
              definition: "To run with speed; to scurry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To move swiftly over; to brush along.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scour"],
    },
  ],
  suing: [
    {
      word: "suing",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To file a legal action against someone, generally a non-criminal action.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To seek by request; to make application; to petition; to entreat; to plead.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of a hawk) To clean (the beak, etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To leave high and dry on shore.",
              synonyms: [],
              antonyms: [],
              example: "to sue a ship",
            },
            { definition: "To court.", synonyms: [], antonyms: [] },
            { definition: "To follow.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sue",
        "https://en.wiktionary.org/wiki/suing",
      ],
    },
    {
      word: "suing",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act of one who sues for something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The process of soaking through anything.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/suing"],
    },
  ],
  cribs: [
    {
      word: "cribs",
      phonetic: "/kɹɪbz/",
      phonetics: [{ text: "/kɹɪbz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A baby’s bed with high, often slatted, often moveable sides, suitable for a child who has outgrown a cradle or bassinet.",
              synonyms: ["cot"],
              antonyms: [],
            },
            {
              definition: "A bed for a child older than a baby.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small sleeping berth in a packet ship or other small vessel",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A wicker basket; compare Moses basket.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A manger, a feeding trough for animals elevated off the earth or floor, especially one for fodder such as hay.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The baby Jesus and the manger in a creche or nativity scene, consisting of statues of Mary, Joseph and various other characters such as the magi.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bin for drying or storing grain, as with a corn crib.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small room or covered structure, especially one of rough construction, used for storage or penning animals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A confined space, as with a cage or office-cubicle",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A job, a position; (British), an appointment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hovel, a roughly constructed building best suited to the shelter of animals but used for human habitation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One’s residence, or where one normally hangs out. A house or dwelling place.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A boxy structure traditionally built of heavy wooden timbers, to support an existing structure from below, as with a mineshaft or a building being raised off its foundation in preparation for being moved; see cribbing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(usually in the plural) A collection of quotes or references for use in speaking, for assembling a written document, or as an aid to a project of some sort; a crib sheet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A minor theft, extortion or embezzlement, with or without criminal intent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The card game cribbage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The cards discarded by players and used by the dealer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A known piece of information corresponding to a section of encrypted text, that is then used to work out the remaining sections.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small holiday home, often near a beach and of simple construction.",
              synonyms: ["bach"],
              antonyms: [],
            },
            {
              definition: "A packed lunch taken to work.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A small raft made of timber.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(thieves' cant) The stomach.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cheat sheet or past test used by students; crib sheet.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bach", "cot"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To place or confine in a crib.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To shut up or confine in a narrow habitation; to cage; to cramp.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To collect one or more passages and/or references for use in a speech, written document or as an aid for some task; to create a crib sheet.",
              synonyms: [],
              antonyms: [],
              example:
                "I cribbed the recipe from the Food Network site, but made a few changes of my own.",
            },
            {
              definition: "To plagiarize; to copy; to cheat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To install timber supports, as with cribbing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To steal or embezzle, to cheat out of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(Indian English) To complain, to grumble",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To crowd together, or to be confined, as if in a crib or in narrow accommodations.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a horse) To seize the manger or other solid object with the teeth and draw in wind.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/crib",
        "https://en.wiktionary.org/wiki/cribs",
      ],
    },
  ],
  sagas: [
    {
      word: "sagas",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An Old Norse (Icelandic) prose narrative, especially one dealing with family or social histories and legends.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something with the qualities of such a saga; an epic, a long story.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/saga",
        "https://en.wiktionary.org/wiki/sagas",
      ],
    },
  ],
  enema: [
    {
      word: "enema",
      phonetic: "/ˈɛn.ə.mə/",
      phonetics: [
        { text: "/ˈɛn.ə.mə/", audio: "" },
        { text: "/ˈɛn.ə.mə/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An injection of fluid into the large intestine by way of the rectum, usually for medical purposes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The fluid so injected.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A device for administering such an injection.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["clyster"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/enema"],
    },
  ],
  wordy: [
    {
      word: "wordy",
      phonetic: "/ˈwɝdi/",
      phonetics: [{ text: "/ˈwɝdi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Using an excessive number of words.",
              synonyms: [],
              antonyms: [],
              example: "The story was long and very wordy.",
            },
          ],
          synonyms: ["pleonastic", "sesquipedalian", "verbose"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/wordy"],
    },
  ],
  harps: [
    {
      word: "harps",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A musical instrument consisting of a body and a curved neck, strung with strings of varying length that are stroked or plucked with the fingers and are vertical to the soundboard when viewed from the end of the body",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A harmonica.", synonyms: [], antonyms: [] },
            { definition: "A grain sieve.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(usually with on) To repeatedly mention a subject.",
              synonyms: [],
              antonyms: [],
              example: "Why do you harp on a single small mistake?",
            },
            {
              definition: "To play on (a harp or similar instrument)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To play (a tune) on the harp.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To develop or give expression to by skill and art; to sound forth as from a harp; to hit upon.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["keep on", "perseverate"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/harp",
        "https://en.wiktionary.org/wiki/harps",
      ],
    },
  ],
  coupe: [
    {
      word: "coupe",
      phonetic: "/kuːp/",
      phonetics: [
        {
          text: "/kuːp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/coupe-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=91208578",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An ice cream dessert; the glass it is served in.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A car with two doors (variant of coupé).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An area of forest where harvesting of wood is planned or has taken place.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/coupe"],
    },
  ],
  molar: [
    {
      word: "molar",
      phonetic: "/ˈmoʊlɚ/",
      phonetics: [{ text: "/ˈmoʊlɚ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A back tooth having a broad surface used for grinding one's food.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["cheektooth", "grinder", "wangtooth"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of or relating to the molar teeth, or to grinding.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/molar"],
    },
    {
      word: "molar",
      phonetic: "/ˈmoʊlɚ/",
      phonetics: [{ text: "/ˈmoʊlɚ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A unit of concentration equal to one mole per litre.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Of, relating to, or being a solution containing one mole of solute per litre of solution.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of or relating to a complete body of matter as distinct from its molecular or atomic constituents.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/molar"],
    },
  ],
  flops: [
    {
      word: "flops",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any simple operation, such as addition, multiplication or division, performed on floating point numbers using a single operation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["FLOP", "flop"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An incident of a certain type of fall; a plopping down.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A complete failure, especially in the entertainment industry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The first three cards turned face-up by the dealer in a community card poker game.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A ponded package of dung, as in a cow-flop.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A flophouse.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["box office bomb", "dud", "fiasco", "turkey"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To fall heavily due to lack of energy.",
              synonyms: [],
              antonyms: [],
              example:
                "He flopped down in front of the television, exhausted from work.",
            },
            {
              definition: "To cause to drop heavily.",
              synonyms: [],
              antonyms: [],
              example:
                "The tired mule flopped its ears forward and trudged on.",
            },
            {
              definition:
                "To fail completely; not to be successful at all (of a movie, play, book, song etc.).",
              synonyms: [],
              antonyms: [],
              example:
                "The latest album flopped and so the studio canceled her contract.",
            },
            {
              definition:
                "To pretend to be fouled in sports, such as basketball, hockey (the same as to dive in soccer)",
              synonyms: [],
              antonyms: [],
              example:
                "It starts with Chris Paul, because Blake didn't really used to flop like that, you know, last year.",
            },
            {
              definition:
                "To strike about with something broad and flat, as a fish with its tail, or a bird with its wings; to rise and fall; to flap.",
              synonyms: [],
              antonyms: [],
              example: "The brim of a hat flops.",
            },
            {
              definition:
                "To have (a hand) using the community cards dealt on the flop.",
              synonyms: [],
              antonyms: [],
              example:
                "Both players flopped sets! Cards dealt on the flop: Q95. Player A's hole cards: 55 (making three of a kind: 555). Player B's hole cards: QQ (making three of a kind: QQQ).",
            },
            {
              definition: "To stay, sleep or live in a place.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One floating-point operation per second, a unit of measure of processor speed.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/floating%20point%20operation",
        "https://en.wiktionary.org/wiki/floating-point%20operation",
        "https://en.wiktionary.org/wiki/flop",
        "https://en.wiktionary.org/wiki/flops",
      ],
    },
  ],
  weeps: [
    {
      word: "weeps",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To cry; shed tears.", synonyms: [], antonyms: [] },
            {
              definition: "To lament; to complain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of a wound or sore) To produce secretions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To flow in drops; to run in drops.",
              synonyms: [],
              antonyms: [],
              example: "a weeping spring, which discharges water slowly",
            },
            {
              definition:
                "To hang the branches, as if in sorrow; to be pendent; to droop; said of a plant or its branches.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To weep over; to bewail.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/weep",
        "https://en.wiktionary.org/wiki/weeps",
      ],
    },
  ],
  mints: [
    {
      word: "mints",
      phonetic: "/mɪnts/",
      phonetics: [{ text: "/mɪnts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A building or institution where money (originally, only coins) is produced under government licence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A large amount of money. A vast sum or amount, etc.",
              synonyms: ["bundle", "pile"],
              antonyms: [],
              example: "It must have cost a mint to produce!",
            },
            {
              definition:
                "Any place regarded as a source of unlimited supply; the supply itself.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bundle", "pile"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To reproduce (coins), usually en masse, under licence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To invent; to forge; to fabricate; to fashion.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any plant in the genus Mentha in the family Lamiaceae, typically aromatic with square stems.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The flavouring of the plant, either a sweet, a jelly or sauce.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any plant in the mint family, Lamiaceae.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A green colour, like that of mint.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A mint-flavored candy, often eaten to sweeten the smell of the breath.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(provincial) Intent, purpose; an attempt, try; effort, endeavor.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(provincial) To try, attempt; take aim.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(provincial) To try, attempt, endeavor; to take aim at; to try to hit; to purpose.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To hint; suggest; insinuate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mint",
        "https://en.wiktionary.org/wiki/mints",
      ],
    },
  ],
  ashen: [
    {
      word: "ashen",
      phonetic: "/ˈæʃ.ɪn/",
      phonetics: [{ text: "/ˈæʃ.ɪn/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Made from the wood of the ash-tree.",
              synonyms: [],
              antonyms: [],
              example: "An ashen bow and quiver of arrows beside.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ashen"],
    },
    {
      word: "ashen",
      phonetic: "/ˈæʃ.ɪn/",
      phonetics: [{ text: "/ˈæʃ.ɪn/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of or resembling ashes.",
              synonyms: [],
              antonyms: [],
              example: "A fine, ashen dust hung in the air.",
            },
            {
              definition: "Ash-colored; pale; anemic, anaemic.",
              synonyms: [],
              antonyms: [],
              example: "His ashen face belied his claims of good health.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ashen"],
    },
    {
      word: "ashen",
      phonetic: "/ˈæʃ.ɪn/",
      phonetics: [{ text: "/ˈæʃ.ɪn/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To turn into ash; make or become ashy",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make or become pale",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ashen"],
    },
  ],
  felts: [
    {
      word: "felts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make into felt, or a feltlike substance; to cause to adhere and mat together.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cover with, or as if with, felt.",
              synonyms: [],
              antonyms: [],
              example: "to felt the cylinder of a steam engine",
            },
            {
              definition: "To cause a player to lose all their chips.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/felt",
        "https://en.wiktionary.org/wiki/felts",
      ],
    },
  ],
  askew: [
    {
      word: "askew",
      phonetic: "/əˈskjuː/",
      phonetics: [{ text: "/əˈskjuː/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Turned or twisted to one side.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Untoward, unfavourable.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Tilted to one side.",
              synonyms: [],
              antonyms: [],
              example: "He wore his hat askew",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/askew"],
    },
  ],
  munch: [
    {
      word: "munch",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/munch-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241808",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/mʌntʃ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A location or restaurant where good eating can be expected.",
              synonyms: [],
              antonyms: [],
              example: "Sally is having a breakfast munch at her place!",
            },
            {
              definition: "An act of eating.",
              synonyms: [],
              antonyms: [],
              example: "We had a good munch at the chippy.",
            },
            { definition: "Food.", synonyms: [], antonyms: [] },
            {
              definition:
                "(BDSM) A casual meeting for those interested in BDSM, usually at a restaurant. See Munch (BDSM).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To chew with a grinding, crunching sound, and with the mouth closed — often used with on.",
              synonyms: [],
              antonyms: [],
              example: "Jim was munching on a biscotti.",
            },
            {
              definition: "To eat vigorously or with excitement.",
              synonyms: [],
              antonyms: [],
              example: "Watching old Bill munch his pancakes makes me hungry!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/munch"],
    },
  ],
  mewed: [
    {
      word: "mewed",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To shut away, confine, lock up.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of a bird) To moult.",
              synonyms: [],
              antonyms: [],
              example: "The hawk mewed his feathers.",
            },
            {
              definition: "(of a bird) To cause to moult.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of a deer) To shed antlers.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(of a cat, especially of a kitten) To meow.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To flatten the tongue against the roof of the mouth for supposed health benefits.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mew",
        "https://en.wiktionary.org/wiki/mewed",
      ],
    },
  ],
  divan: [
    {
      word: "divan",
      phonetic: "/dɪˈvæn/",
      phonetics: [
        { text: "/dɪˈvæn/", audio: "" },
        {
          text: "/dɪˈvæn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/divan-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=5540244",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A Muslim council of state, specifically that of viziers of the Ottoman Empire that discussed and recommended new laws and law changes to a higher authority (the sultan).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The council chamber where this court is held; (by extension), any court of justice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any council or assembly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A couch- or sofa-like piece of furniture made of a mattress lying against the wall and either on the floor or an elevated structure.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A smoking-room.", synonyms: [], antonyms: [] },
            {
              definition:
                "A collection of poems, especially one written by one author in Arabic or Persian.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/divan"],
    },
  ],
  vices: [
    {
      word: "vices",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/vices-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2651688",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A bad habit.",
              synonyms: [],
              antonyms: [],
              example: "Gluttony is a vice, not a virtue.",
            },
            {
              definition:
                "Any of various crimes related (depending on jurisdiction) to prostitution, pornography, gambling, alcohol, or drugs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A defect in the temper or behaviour of a horse, such as to make the animal dangerous, to injure its health, or to diminish its usefulness.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["virtue"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A mechanical screw apparatus used for clamping or holding (also spelled vise).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A tool for drawing lead into cames, or flat grooved rods, for casements.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A grip or grasp.", synonyms: [], antonyms: [] },
            {
              definition: "A winding or spiral staircase.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One who acts in place of a superior.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/vice",
        "https://en.wiktionary.org/wiki/vices",
      ],
    },
  ],
  jumbo: [
    {
      word: "jumbo",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/jumbo-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75803990",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈd͡ʒʌmbəʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/jumbo-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=72712815",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈd͡ʒʌmboʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/jumbo-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=72712823",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An especially large or powerful person, animal, or thing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A platform-mounted machine for drilling rock.",
              synonyms: ["drilling jumbo"],
              antonyms: [],
            },
          ],
          synonyms: ["drilling jumbo"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Especially large or powerful.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/jumbo"],
    },
    {
      word: "jumbo",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/jumbo-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75803990",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈd͡ʒʌmbəʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/jumbo-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=72712815",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈd͡ʒʌmboʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/jumbo-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=72712823",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Short for mumbo jumbo.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/jumbo"],
    },
  ],
  blobs: [
    {
      word: "blobs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Acronym of binary large object. (a data type that allows storage of binary data often of indeterminate length).",
              synonyms: [],
              antonyms: [],
              example: "I've added a BLOB so that we can store pictures.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A shapeless or amorphous mass; a vague shape or amount, especially of a liquid or semisolid substance; a clump, group or collection that lacks definite shape.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In astronomy, a large cloud of gas. In particular, an extended Lyman-Alpha blob is a huge body of gas that may be the precursor to a galaxy.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A bubble; a bleb.", synonyms: [], antonyms: [] },
            {
              definition:
                "A small freshwater fish (Cottus bairdii); the miller's thumb.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The partially inflated air bag used in the sport of blobbing.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A score of zero.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To drop in the form of a blob or blobs",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To drop a blob or blobs onto, cover with blobs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fall in the form of a blob or blobs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To relax idly and mindlessly; to veg out.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/BLOB",
        "https://en.wiktionary.org/wiki/blob",
        "https://en.wiktionary.org/wiki/blobs",
      ],
    },
  ],
  blots: [
    {
      word: "blots",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A blemish, spot or stain made by a coloured substance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) A stain on someone's reputation or character; a disgrace.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A method of transferring proteins, DNA or RNA, onto a carrier.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An exposed piece in backgammon.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cause a blot (on something) by spilling a coloured substance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To soak up or absorb liquid.",
              synonyms: [],
              antonyms: [],
              example: "This paper blots easily.",
            },
            {
              definition: "To dry (writing, etc.) with blotting paper.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To spot, stain, or bespatter, as with ink.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To impair; to damage; to mar; to soil.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To stain with infamy; to disgrace.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To obliterate, as writing with ink; to cancel; to efface; generally with out.",
              synonyms: [],
              antonyms: [],
              example: "to blot out a word or a sentence",
            },
            {
              definition: "To obscure; to eclipse; to shadow.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/blot",
        "https://en.wiktionary.org/wiki/blots",
      ],
    },
  ],
  spunk: [
    {
      word: "spunk",
      phonetic: "/spʌŋk/",
      phonetics: [
        {
          text: "/spʌŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spunk-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75701489",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/spʌŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spunk-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=727458",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A spark.", synonyms: [], antonyms: [] },
            { definition: "Touchwood; tinder.", synonyms: [], antonyms: [] },
            {
              definition:
                "A piece of tinder, sometimes impregnated with sulphur; a match.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Courage; spirit; mettle; determination.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An attractive person (normally male).",
              synonyms: ["Adonis", "beefcake", "hunk"],
              antonyms: [],
            },
            { definition: "Semen.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["Adonis", "beefcake", "hunk"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To catch fire; flame up.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To ejaculate.",
              synonyms: [],
              antonyms: [],
              example: "He spunked into the condom.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spunk"],
    },
  ],
  acrid: [
    {
      word: "acrid",
      phonetic: "/ˈæk.ɹɪd/",
      phonetics: [
        {
          text: "/ˈæk.ɹɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/acrid-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=296175",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Sharp and harsh, or bitter and not to the taste.",
              synonyms: ["acrimonious", "pungent"],
              antonyms: ["delectable", "delicious", "tasteful"],
              example: "Sodium polyacrylate is an acrid salt.",
            },
            {
              definition: "Causing heat and irritation.",
              synonyms: ["corrosive"],
              antonyms: [],
              example:
                "The bombardier beetle sprays acrid secretions to defend itself.",
            },
            {
              definition: "Caustic; bitter; bitterly irritating.",
              synonyms: ["acerbic", "acrimonious"],
              antonyms: [],
              example: "That man has an acrid temper.",
            },
          ],
          synonyms: [
            "acerbic",
            "acrimonious",
            "acrimonious",
            "pungent",
            "corrosive",
          ],
          antonyms: ["delectable", "delicious", "tasteful"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/acrid"],
    },
  ],
  topaz: [
    {
      word: "topaz",
      phonetic: "/ˈtəʊpæz/",
      phonetics: [
        { text: "/ˈtəʊpæz/", audio: "" },
        { text: "/ˈtoʊpæz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A silicate mineral of aluminium and fluorine, usually tinted by impurities.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An often clear, yellowish-brown gemstone cut from this.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A yellowish-brown color, like that of the gemstone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(British India) A black Catholic soldier in the British Army.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Of a yellowish-brown color, like that of the gemstone.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/topaz"],
    },
  ],
  cubed: [
    {
      word: "cubed",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To raise to the third power; to determine the result of multiplying by itself twice.",
              synonyms: [],
              antonyms: [],
              example:
                "Three cubed can be written as 33, and equals twenty-seven.",
            },
            {
              definition: "To form into the shape of a cube.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cut into cubes.",
              synonyms: [],
              antonyms: [],
              example: "Cube the ham right after adding the curry to the rice.",
            },
            {
              definition: "To use a Rubik's cube.",
              synonyms: [],
              antonyms: [],
              example: "He likes to cube now and then.",
            },
          ],
          synonyms: ["dice"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Raised to the third power.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["³"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cube",
        "https://en.wiktionary.org/wiki/cubed",
      ],
    },
  ],
  clans: [
    {
      word: "clans",
      phonetic: "/klænz/",
      phonetics: [
        {
          text: "/klænz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/clans-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=64328426",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A group of people all descended from a common ancestor, in fact or belief.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A traditional social group of families in the Scottish Highlands having a common hereditary chieftain",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any group defined by family ties with some sort of political unity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A group of players who habitually play on the same team in multiplayer games.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A badger colony.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/clan",
        "https://en.wiktionary.org/wiki/clans",
      ],
    },
  ],
  flees: [
    {
      word: "flees",
      phonetic: "/ˈfliːz/",
      phonetics: [{ text: "/ˈfliːz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To run away; to escape.",
              synonyms: [],
              antonyms: [],
              example:
                "The prisoner tried to flee, but was caught by the guards.",
            },
            {
              definition: "To escape from.",
              synonyms: [],
              antonyms: [],
              example: "Many people fled the country as war loomed.",
            },
            {
              definition: "To disappear quickly; to vanish.",
              synonyms: [],
              antonyms: [],
              example: "Ethereal products flee once freely exposed to air.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/flee",
        "https://en.wiktionary.org/wiki/flees",
      ],
    },
  ],
  slurs: [
    {
      word: "slurs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An insult or slight.",
              synonyms: [],
              antonyms: [],
              example: "a racial slur",
            },
            {
              definition:
                "A set of notes that are played legato, without separate articulation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The symbol indicating a legato passage, written as an arc over the slurred notes (not to be confused with a tie).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A trick or deception.", synonyms: [], antonyms: [] },
            {
              definition:
                "In knitting machines, a device for depressing the sinkers successively by passing over them.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To insult or slight.", synonyms: [], antonyms: [] },
            {
              definition: "To run together; to articulate poorly.",
              synonyms: [],
              antonyms: [],
              example:
                "to slur syllables;  He slurs his speech when he is drunk.",
            },
            {
              definition:
                "To play legato or without separate articulation; to connect (notes) smoothly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To soil; to sully; to contaminate; to disgrace.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cover over; to disguise; to conceal; to pass over lightly or with little notice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cheat, as by sliding a die; to trick.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To blur or double, as an impression from type; to mackle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/slur",
        "https://en.wiktionary.org/wiki/slurs",
      ],
    },
  ],
  gnaws: [
    {
      word: "gnaws",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To bite something persistently, especially something tough.",
              synonyms: [],
              antonyms: [],
              example: "The dog gnawed the bone until it broke in two.",
            },
            {
              definition: "To produce excessive anxiety or worry.",
              synonyms: [],
              antonyms: [],
              example:
                "Her comment gnawed at me all day and I couldn't think about anything else.",
            },
            {
              definition: "To corrode; to fret away; to waste.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gnaw",
        "https://en.wiktionary.org/wiki/gnaws",
      ],
    },
  ],
  welds: [
    {
      word: "welds",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The joint made by welding.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To join two materials (especially two metals) together by applying heat, pressure and filler, either separately or in any combination.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To bind together inseparably; to unite closely or intimately.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To wield.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/weld",
        "https://en.wiktionary.org/wiki/welds",
      ],
    },
  ],
  fords: [
    {
      word: "fords",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A location where a stream is shallow and the bottom has good footing, making it possible to cross from one side to the other with no bridge, by walking, riding, or driving through the water; a crossing.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A stream; a current.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cross a stream using a ford.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ford",
        "https://en.wiktionary.org/wiki/fords",
      ],
    },
  ],
  emits: [
    {
      word: "emits",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To send out or give off",
              synonyms: ["output", "outsend"],
              antonyms: [],
            },
          ],
          synonyms: ["output", "outsend"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/emit",
        "https://en.wiktionary.org/wiki/emits",
      ],
    },
  ],
  agate: [
    {
      word: "agate",
      phonetic: "/ˈæ.ɡət/",
      phonetics: [{ text: "/ˈæ.ɡət/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A semi-pellucid, uncrystallized variety of quartz, presenting various tints in the same specimen, with colors delicately arranged in stripes or bands, or blended in clouds.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The size of type between pearl and nonpareil, standardized as 5 1/2-point.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One fourteenth of an inch",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A diminutive person; so called in allusion to the small figures cut in agate for rings and seals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A tool used by gold-wire drawers, bookbinders, etc.;—so called from the agate fixed in it for burnishing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A marble made from agate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(usually in the plural) A testicle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["aggie", "ruby"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/agate"],
    },
    {
      word: "agate",
      phonetic: "/əˈɡæt/",
      phonetics: [{ text: "/əˈɡæt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "On the way; agoing.",
              synonyms: [],
              antonyms: [],
              example: "to be agate; to set the bells agate",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/agate"],
    },
  ],
  pumas: [
    {
      word: "pumas",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The mountain lion or cougar, Puma concolor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A woman in her 20s or 30s who seeks relationships with younger men; a younger cougar.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/puma",
        "https://en.wiktionary.org/wiki/pumas",
      ],
    },
  ],
  mends: [
    {
      word: "mends",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A place, as in clothing, which has been repaired by mending.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The act of repairing.",
              synonyms: [],
              antonyms: [],
              example: "My trousers have a big rip in them and need a mend.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To repair, as anything that is torn, broken, defaced, decayed, or the like; to restore from partial decay, injury, or defacement.",
              synonyms: [],
              antonyms: [],
              example: "My trousers have a big rip in them and need mending.",
            },
            {
              definition:
                "To alter for the better; to set right; to reform; hence, to quicken; as, to mend one's manners or pace.",
              synonyms: [],
              antonyms: [],
              example: "Her stutter was mended by a speech therapist.",
            },
            {
              definition: "To help, to advance, to further; to add to.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To grow better; to advance to a better state; to become improved.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mend",
        "https://en.wiktionary.org/wiki/mends",
      ],
    },
  ],
  darks: [
    {
      word: "darks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A complete or (more often) partial absence of light.",
              synonyms: [],
              antonyms: [],
              example: "Dark surrounds us completely.",
            },
            {
              definition: "Ignorance.",
              synonyms: [],
              antonyms: [],
              example:
                "The lawyer was left in the dark as to why the jury was dismissed.",
            },
            {
              definition: "Nightfall.",
              synonyms: [],
              antonyms: [],
              example: "It was after dark before we got to playing baseball.",
            },
            {
              definition:
                "A dark shade or dark passage in a painting, engraving, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To grow or become dark, darken.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To remain in the dark, lurk, lie hidden or concealed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make dark, darken; to obscure.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dark",
        "https://en.wiktionary.org/wiki/darks",
      ],
    },
  ],
  dukes: [
    {
      word: "dukes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The male ruler of a duchy (female equivalent: duchess).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The sovereign of a small state.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A high title of nobility; the male holder of a dukedom.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A grand duke.", synonyms: [], antonyms: [] },
            {
              definition:
                "Any of various nymphalid butterflies of the Asian genera Bassarona and Dophla.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(usually in the plural) A fist.",
              synonyms: [],
              antonyms: [],
              example: "Put up your dukes!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To hit or beat with the fists.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To give cash to; to give a tip to.",
              synonyms: ["tip"],
              antonyms: [],
              example: "I duked him twenty dollars.",
            },
          ],
          synonyms: ["tip"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/duke",
        "https://en.wiktionary.org/wiki/dukes",
      ],
    },
  ],
  plies: [
    {
      word: "plies",
      phonetic: "/plaɪz/",
      phonetics: [{ text: "/plaɪz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A layer of material.",
              synonyms: [],
              antonyms: [],
              example: "two-ply toilet paper",
            },
            {
              definition:
                "A strand that, twisted together with other strands, makes up rope or yarn.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Short for plywood.", synonyms: [], antonyms: [] },
            {
              definition:
                'In two-player sequential games, a "half-turn" or a move made by one of the players.',
              synonyms: [],
              antonyms: [],
              example:
                "He proposed to build Deep Purple, a super-computer capable of 24-ply look-ahead for chess.",
            },
            { definition: "A condition, a state.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To bend; to fold; to mould; to adapt, to modify; to change (a person's) mind, to cause (a person) to submit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To bend, to flex; to be bent by something, to give way or yield (to a force, etc.).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To work at (something) diligently.",
              synonyms: [],
              antonyms: [],
              example: "He plied his trade as carpenter for forty-three years.",
            },
            {
              definition:
                "To wield or use (a tool, a weapon, etc.) steadily or vigorously.",
              synonyms: [],
              antonyms: [],
              example: "He plied his ax with bloody results.",
            },
            {
              definition: "To press upon; to urge persistently.",
              synonyms: [],
              antonyms: [],
              example: "to ply someone with questions or solicitations",
            },
            {
              definition:
                "To persist in offering something to, especially for the purpose of inducement or persuasion.",
              synonyms: [],
              antonyms: [],
              example: "to ply someone with drink",
            },
            {
              definition: "To travel over (a route) regularly.",
              synonyms: [],
              antonyms: [],
              example: "The steamer plies between several ports on the coast.",
            },
            { definition: "To work diligently.", synonyms: [], antonyms: [] },
            {
              definition:
                "To manoeuvre a sailing vessel so that the direction of the wind changes from one side of the vessel to the other; to work to windward, to beat, to tack.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/plies",
        "https://en.wiktionary.org/wiki/ply",
      ],
    },
    {
      word: "plies",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A smooth and continuous bending of the knees",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pli%C3%A9",
        "https://en.wiktionary.org/wiki/plie",
        "https://en.wiktionary.org/wiki/plies",
      ],
    },
  ],
  canny: [
    {
      word: "canny",
      phonetic: "/ˈkæni/",
      phonetics: [{ text: "/ˈkæni/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Careful, prudent, cautious.",
              synonyms: [],
              antonyms: [],
              example:
                "The politician gave a canny response to the reporter's questions.",
            },
            {
              definition: "Knowing, shrewd, astute.",
              synonyms: [],
              antonyms: [],
              example: "The canny lawyer knew just how to get what he wanted.",
            },
            {
              definition: "Frugal, thrifty.",
              synonyms: [],
              antonyms: [],
              example: "canny investments",
            },
            {
              definition:
                "Pleasant, fair, favorable or agreeable to deal with.",
              synonyms: [],
              antonyms: [],
              example: "She's a canny lass hor like!",
            },
            {
              definition: "Gentle, quiet, steady.",
              synonyms: [],
              antonyms: [],
              example: "a canny horse",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Very, considerably; quite, rather.",
              synonyms: [],
              antonyms: [],
              example: "That's a canny big horse, man!",
            },
            {
              definition: "Gently, quietly; carefully, skilfully.",
              synonyms: [],
              antonyms: [],
              example: "drive canny",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/canny"],
    },
    {
      word: "canny",
      phonetic: "/ˈkæni/",
      phonetics: [{ text: "/ˈkæni/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(especially of sound) Sounding as if it is coming through a tin can.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/canny"],
    },
  ],
  hoots: [
    {
      word: "hoots",
      phonetic: "/huːts/",
      phonetics: [{ text: "/huːts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A derisive cry or shout.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The cry of an owl.", synonyms: [], antonyms: [] },
            {
              definition: "A fun event or person. (See hootenanny)",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A small particle.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cry out or shout in contempt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make the cry of an owl, a hoo.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To assail with contemptuous cries or shouts; to follow with derisive shouts.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To sound the horn of a vehicle",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hoot",
        "https://en.wiktionary.org/wiki/hoots",
      ],
    },
  ],
  oozes: [
    {
      word: "oozes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To be secreted or slowly leak.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To give off a strong sense of (something); to exude.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ooze",
        "https://en.wiktionary.org/wiki/oozes",
      ],
    },
    {
      word: "oozes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Tanning liquor, an aqueous extract of vegetable matter (tanbark, sumac, etc.) in a tanning vat used to tan leather.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An oozing, gentle flowing, or seepage, as of water through sand or earth.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Secretion, humour.", synonyms: [], antonyms: [] },
            { definition: "Juice, sap.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Soft mud, slime, or shells especially in the bed of a river or estuary.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A pelagic marine sediment containing a significant amount of the microscopic remains of either calcareous or siliceous planktonic debris organisms.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A piece of soft, wet, pliable ground.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ooze",
        "https://en.wiktionary.org/wiki/oozes",
      ],
    },
  ],
  lamed: [
    {
      word: "lamed",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cause (a person or animal) to become lame.",
              synonyms: [],
              antonyms: [],
              example:
                "1877, Anna Sewell, Black Beauty: And if you don't want to lame your horse you must look sharp and get them [stones stuck in hooves] out quickly.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To shine.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The twelfth letter of many Semitic alphabets/abjads (Phoenician, Aramaic, Hebrew, Syriac, Arabic and others).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lame",
        "https://en.wiktionary.org/wiki/lamed",
        "https://en.wiktionary.org/wiki/lamedh",
      ],
    },
  ],
  fouls: [
    {
      word: "fouls",
      phonetic: "/faʊlz/",
      phonetics: [{ text: "/faʊlz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A breach of the rules of a game, especially one involving inappropriate contact with an opposing player in order to gain an advantage; for example, tripping someone up in soccer, or contact of any kind in basketball.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A (usually accidental) contact between a bowler and the lane before the bowler has released the ball.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A foul ball, a ball which has been hit outside of the base lines.",
              synonyms: [],
              antonyms: [],
              example: "Jones hit a foul up over the screen.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make dirty.",
              synonyms: [],
              antonyms: [],
              example: "She's fouled her diaper.",
            },
            {
              definition: "To besmirch.",
              synonyms: [],
              antonyms: [],
              example: "He's fouled his reputation.",
            },
            {
              definition: "To clog or obstruct.",
              synonyms: [],
              antonyms: [],
              example: "The hair has fouled the drain.",
            },
            {
              definition: "To entangle.",
              synonyms: [],
              antonyms: [],
              example: "The kelp has fouled the prop.",
            },
            {
              definition:
                "To make contact with an opposing player in order to gain advantage.",
              synonyms: [],
              antonyms: [],
              example: "Smith fouled him hard.",
            },
            {
              definition: "To hit outside of the baselines.",
              synonyms: [],
              antonyms: [],
              example:
                "Jones fouled the ball off the facing of the upper deck.",
            },
            {
              definition: "To become clogged.",
              synonyms: [],
              antonyms: [],
              example: "The drain fouled.",
            },
            {
              definition: "To become entangled.",
              synonyms: [],
              antonyms: [],
              example: "The prop fouled on the kelp.",
            },
            {
              definition: "To commit a foul.",
              synonyms: [],
              antonyms: [],
              example: "Smith fouled within the first minute of the quarter.",
            },
            {
              definition: "To hit a ball outside of the baselines.",
              synonyms: [],
              antonyms: [],
              example: "Jones fouled for strike one.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/foul",
        "https://en.wiktionary.org/wiki/fouls",
      ],
    },
  ],
  clefs: [
    {
      word: "clefs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A symbol found on a musical staff that indicates the pitches represented by the lines and the spaces on the staff",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/clef",
        "https://en.wiktionary.org/wiki/clefs",
      ],
    },
  ],
  nicks: [
    {
      word: "nicks",
      phonetic: "/nɪks/",
      phonetics: [
        { text: "/nɪks/", audio: "" },
        {
          text: "/nɪks/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/nicks-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=5241777",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A small cut in a surface.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Senses connoting something small.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Often in the expressions in bad nick and in good nick: condition, state.",
              synonyms: [],
              antonyms: [],
              example: "The car I bought was cheap and in good nick.",
            },
            {
              definition: "(law enforcement) A police station or prison.",
              synonyms: [],
              antonyms: [],
              example:
                "He was arrested and taken down to Sun Hill nick [police station] to be charged.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make a nick or notch in; to cut or scratch in a minor way.",
              synonyms: [],
              antonyms: [],
              example: "I nicked myself while I was shaving.",
            },
            {
              definition:
                "To fit into or suit, as by a correspondence of nicks; to tally with.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make a cut at the side of the face.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To steal.",
              synonyms: [],
              antonyms: [],
              example: "Someone’s nicked my bike!",
            },
            {
              definition: "(law enforcement) To arrest.",
              synonyms: [],
              antonyms: [],
              example:
                "The police nicked him climbing over the fence of the house he’d broken into.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To give or call (someone) by a nickname; to style.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A nix or nixie.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A familiar, invented given name for a person or thing used instead of the actual name of the person or thing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A kind of byname that describes a person by a characteristic of that person.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "antonomasia",
            "byname",
            "cognomen",
            "handle",
            "hypocoristic",
            "moniker",
            "nick",
            "pet name",
            "sobriquet",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/nick",
        "https://en.wiktionary.org/wiki/nickname",
        "https://en.wiktionary.org/wiki/nicks",
      ],
    },
  ],
  mated: [
    {
      word: "mated",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To put the king of an opponent into checkmate.",
              synonyms: [],
              antonyms: [],
              example: "That jerk checkmated me in four moves!",
            },
            {
              definition:
                "(by extension) To place in a losing situation that has no escape.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To match, fit together without space between.",
              synonyms: [],
              antonyms: [],
              example: "The pieces of the puzzle mate perfectly.",
            },
            { definition: "To copulate.", synonyms: [], antonyms: [] },
            {
              definition: "To pair in order to raise offspring",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To arrange in matched pairs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To introduce (animals) together for the purpose of breeding.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of an animal) To copulate with.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To marry; to match (a person).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To match oneself against; to oppose as equal; to compete with.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fit (objects) together without space between.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To move (a space shuttle orbiter) onto the back of an aircraft that can carry it.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["couple", "match", "pair"],
          antonyms: ["demate"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To confuse; to confound.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Fitted together or interlocked",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/checkmate",
        "https://en.wiktionary.org/wiki/mate",
        "https://en.wiktionary.org/wiki/mated",
      ],
    },
  ],
  skims: [
    {
      word: "skims",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To pass lightly; to glide along in an even, smooth course; to glide along near the surface.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To pass near the surface of; to brush the surface of; to glide swiftly along the surface of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To hasten along with superficial attention.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To put on a finishing coat of plaster.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To throw an object so it bounces on water (skimming stones)",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To ricochet", synonyms: [], antonyms: [] },
            {
              definition: "To read quickly, skipping some detail",
              synonyms: [],
              antonyms: [],
              example: "I skimmed the newspaper over breakfast.",
            },
            {
              definition: "To scrape off; to remove (something) from a surface",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To clear (a liquid) from scum or substance floating or lying on it, by means of a utensil that passes just beneath the surface.",
              synonyms: [],
              antonyms: [],
              example: "to skim milk; to skim broth",
            },
            {
              definition:
                "To clear a liquid from (scum or substance floating or lying on it), especially the cream that floats on top of fresh milk",
              synonyms: [],
              antonyms: [],
              example: "to skim cream",
            },
            {
              definition:
                "To steal money from a business before the transaction has been recorded, thus avoiding detection.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To surreptitiously scan a payment card in order to obtain its information for fraudulent purposes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To become coated over.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/skim",
        "https://en.wiktionary.org/wiki/skims",
      ],
    },
  ],
  brunt: [
    {
      word: "brunt",
      phonetic: "/bɹʌnt/",
      phonetics: [
        {
          text: "/bɹʌnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/brunt-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75729144",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The full adverse effects; the chief consequences or negative results of a thing or event.",
              synonyms: [],
              antonyms: [],
              example:
                "Unfortunately, poor areas such as those in New Orleans bore the brunt of Hurricane Katrina’s winds.",
            },
            {
              definition: "The major part of something; the bulk.",
              synonyms: [],
              antonyms: [],
              example:
                "If you feel tired of walking, just think of the poor donkey who has carried the brunt of our load.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To bear the brunt of; to weather or withstand.",
              synonyms: [],
              antonyms: [],
              example: "We brunted the storm.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/brunt"],
    },
  ],
  tuber: [
    {
      word: "tuber",
      phonetic: "/tjuːbə(ɹ)/",
      phonetics: [{ text: "/tjuːbə(ɹ)/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A fleshy, thickened underground stem of a plant, usually containing stored starch, for example a potato or arrowroot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A thickened rootstock.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A rounded, protuberant structure in a human or animal body.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tuber"],
    },
  ],
  tinge: [
    {
      word: "tinge",
      phonetic: "/tɪn(d)ʒ/",
      phonetics: [
        {
          text: "/tɪn(d)ʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tinge-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88902106",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/tɪn(d)ʒ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small added amount of colour; (by extension) a small added amount of some other thing.",
              synonyms: ["teint", "teinture", "tincture"],
              antonyms: [],
            },
            {
              definition:
                "The degree of vividness of a colour; hue, shade, tint.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["teint", "teinture", "tincture"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To add a small amount of colour; to tint; (by extension) to add a small amount of some other thing.",
              synonyms: ["tinct"],
              antonyms: [],
            },
            {
              definition:
                "To affect or alter slightly, particularly due to the actual or metaphorical influence of some element or thing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To change slightly in shade due to the addition of colour; (by extension) to change slightly in quality due to the addition of some other thing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["tinct"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tinge"],
    },
  ],
  fates: [
    {
      word: "fates",
      phonetic: "/feɪts/",
      phonetics: [{ text: "/feɪts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The presumed cause, force, principle, or divine will that predetermines events.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The effect, consequence, outcome, or inevitable events predetermined by this cause.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An event or a situation which is inevitable in the fullness of time.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Destiny; often with a connotation of death, ruin, misfortune, etc.",
              synonyms: [],
              antonyms: [],
              example: "Accept your fate.",
            },
            {
              definition:
                "Alternative letter-case form of Fate (one of the goddesses said to control the destiny of human beings).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "destiny",
            "doom",
            "fortune",
            "kismet",
            "lot",
            "necessity",
            "orlay",
            "predestination",
            "wyrd",
          ],
          antonyms: ["chance", "choice", "free will", "freedom"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To foreordain or predetermine, to make inevitable.",
              synonyms: [],
              antonyms: [],
              example:
                "The oracle's prediction fated Oedipus to kill his father; not all his striving could change what would occur.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fate",
        "https://en.wiktionary.org/wiki/fates",
      ],
    },
  ],
  ditty: [
    {
      word: "ditty",
      phonetic: "/ˈdɪti/",
      phonetics: [
        {
          text: "/ˈdɪti/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ditty-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=562462",
        },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ditty-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=338328",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A short verse or tune.",
              synonyms: [],
              antonyms: [],
              example:
                "The Acme mattress ditty has been stuck in my head all day.",
            },
            {
              definition:
                "A saying or utterance, especially one that is short and frequently repeated.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To sing; to warble a little tune.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ditty"],
    },
  ],
  thins: [
    {
      word: "thins",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A loss or tearing of paper from the back of a stamp, although not sufficient to create a complete hole.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any food produced or served in thin slices.",
              synonyms: [],
              antonyms: [],
              example: "chocolate mint thins",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make thin or thinner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To become thin or thinner.",
              synonyms: [],
              antonyms: [],
              example:
                "The crowds thinned after the procession had passed: there was nothing more to see.",
            },
            { definition: "To dilute.", synonyms: [], antonyms: [] },
            {
              definition:
                "To remove some plants or parts of plants in order to improve the growth of what remains.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/thin",
        "https://en.wiktionary.org/wiki/thins",
      ],
    },
  ],
  frets: [
    {
      word: "frets",
      phonetic: "/fɹɛts/",
      phonetics: [{ text: "/fɹɛts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Agitation of the surface of a fluid by fermentation or some other cause; a rippling on the surface of water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Agitation of the mind marked by complaint and impatience; disturbance of temper; irritation.",
              synonyms: [],
              antonyms: [],
              example: "He keeps his mind in a continual fret.",
            },
            { definition: "Herpes; tetter.", synonyms: [], antonyms: [] },
            {
              definition:
                "(in the plural) The worn sides of riverbanks, where ores or stones containing them accumulate after being washed down from higher ground, which thus indicate to miners the locality of veins of ore.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "Especially when describing animals: to consume, devour, or eat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To chafe or irritate; to worry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make rough, to agitate or disturb; to cause to ripple.",
              synonyms: [],
              antonyms: [],
              example: "to fret the surface of water",
            },
            {
              definition: "In the form fret out: to squander, to waste.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To gnaw; to consume, to eat away.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be chafed or irritated; to be angry or vexed; to utter peevish expressions through irritation or worry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be worn away; to chafe; to fray.",
              synonyms: [],
              antonyms: [],
              example: "A wristband frets on the edges.",
            },
            {
              definition: "To be anxious, to worry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be agitated; to rankle; to be in violent commotion.",
              synonyms: [],
              antonyms: [],
              example: "Rancour frets in the malignant breast.",
            },
            {
              definition:
                "To have secondary fermentation (fermentation occurring after the conversion of sugar to alcohol in beers and wine) take place.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An ornamental pattern consisting of repeated vertical and horizontal lines, often in relief.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A saltire interlaced with a mascle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To decorate or ornament, especially with an interlaced or interwoven pattern, or with carving or relief (raised) work.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To form a pattern on; to variegate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cut through with a fretsaw, to create fretwork.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A ferrule, a ring.", synonyms: [], antonyms: [] },
            {
              definition:
                "One of the pieces of metal, plastic or wood across the neck of a guitar or other string instrument that marks where a finger should be positioned to depress a string as it is played.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To bind, to tie, originally with a loop or ring.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Musical senses.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A channel, a strait; a fretum.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A channel or passage created by the sea.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A fog or mist at sea, or coming inland from the sea.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fret",
        "https://en.wiktionary.org/wiki/frets",
      ],
    },
  ],
  eider: [
    {
      word: "eider",
      phonetic: "/ˈaɪdə/",
      phonetics: [
        { text: "/ˈaɪdə/", audio: "" },
        { text: "/ˈaɪdɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of the species of the genera Polysticta or Somateria, in the seaduck subfamily Merginae, which line their nests with fine down (taken from their own bodies).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/eider"],
    },
  ],
  bayou: [
    {
      word: "bayou",
      phonetic: "/ˈbaɪ.oʊ/",
      phonetics: [{ text: "/ˈbaɪ.oʊ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A slow-moving, often stagnant creek or river.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A swamp, a marshy (stagnant) body of water.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bayou"],
    },
  ],
  mulch: [
    {
      word: "mulch",
      phonetic: "/mʌltʃ/",
      phonetics: [
        {
          text: "/mʌltʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mulch-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88681532",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any material used to cover the top layer of soil to protect, insulate, or decorate it, or to discourage weeds or retain moisture.",
              synonyms: [],
              antonyms: [],
              example:
                "An organic mulch is a mulch made of natural substances such as leaves or grass clippings.",
            },
            {
              definition:
                "A material used as mulch, as a decorative redwood bark mulch.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To apply mulch.",
              synonyms: [],
              antonyms: [],
              example:
                "Mulch your vegetable garden to retain moisture and keep weeds down.",
            },
            {
              definition: "To turn into mulch.",
              synonyms: [],
              antonyms: [],
              example: "I decided to mulch the grass clippings.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mulch"],
    },
  ],
  fasts: [
    {
      word: "fasts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A train that calls at only some stations it passes between its origin and destination, typically just the principal stations",
              synonyms: ["express", "express train"],
              antonyms: ["local", "slow train", "stopper"],
            },
          ],
          synonyms: ["express", "express train"],
          antonyms: ["local", "slow train", "stopper"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The act or practice of abstaining from food or of eating very little food.",
              synonyms: ["fasting"],
              antonyms: [],
            },
            {
              definition:
                "The period of time during which one abstains from or eats very little food.",
              synonyms: [],
              antonyms: [],
              example: "Lent and Ramadan are fasts of two religions.",
            },
          ],
          synonyms: ["fasting"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To restrict one’s personal consumption, generally of food, but sometimes other things, in various manners (totally, temporally, by avoiding particular items), often for religious or medical reasons.",
              synonyms: [],
              antonyms: [],
              example: "Muslims fast during Ramadan and Catholics during Lent.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fast",
        "https://en.wiktionary.org/wiki/fasts",
      ],
    },
  ],
  amass: [
    {
      word: "amass",
      phonetic: "/əˈmæs/",
      phonetics: [
        { text: "/əˈmæs/", audio: "" },
        {
          text: "/əˈmæs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/amass-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50284257",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A large number of things collected or piled together.",
              synonyms: ["heap", "mass", "pile"],
              antonyms: [],
            },
            { definition: "The act of amassing.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["heap", "mass", "pile"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To collect into a mass or heap.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To gather a great quantity of; to accumulate.",
              synonyms: [],
              antonyms: [],
              example: "to amass a treasure or a fortune",
            },
          ],
          synonyms: [
            "heap up",
            "mound",
            "pile",
            "pile up",
            "stack up",
            "accumulate",
            "amound",
            "collect",
            "gather",
            "hoard",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/amass"],
    },
  ],
  damps: [
    {
      word: "damps",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Moisture; humidity; dampness.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Fog; fogginess; vapor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Dejection or depression; something that spoils a positive emotion (such as enjoyment, satisfaction, expectation or courage) or a desired activity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A gaseous product, formed in coal mines, old wells, pits, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To dampen; to make moderately wet",
              synonyms: ["moisten"],
              antonyms: [],
              example: "to damp cloth",
            },
            {
              definition:
                "To put out, as fire; to weaken, restrain, or make dull.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To suppress vibrations (mechanical) or oscillations (electrical) by converting energy to heat (or some other form of energy).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["moisten"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/damp",
        "https://en.wiktionary.org/wiki/damps",
      ],
    },
  ],
  morns: [
    {
      word: "morns",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "Morning.", synonyms: [], antonyms: [] }],
          synonyms: ["morning", "morrow"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/morn",
        "https://en.wiktionary.org/wiki/morns",
      ],
    },
  ],
  friar: [
    {
      word: "friar",
      phonetic: "/ˈfɹaɪə/",
      phonetics: [
        { text: "/ˈfɹaɪə/", audio: "" },
        { text: "/ˈfɹaɪɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A member of a mendicant Christian order such as the Augustinians, Carmelites (white friars), Franciscans (grey friars) or the Dominicans (black friars).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A white or pale patch on a printed page.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An American fish, the silverside.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["brother", "father", "frater"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/friar"],
    },
  ],
  palsy: [
    {
      word: "palsy",
      phonetic: "/ˈpɔːlzi/",
      phonetics: [{ text: "/ˈpɔːlzi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Complete or partial muscle paralysis of a body part, often accompanied by a loss of feeling and uncontrolled body movements such as shaking.",
              synonyms: ["paralysis"],
              antonyms: [],
            },
          ],
          synonyms: ["paralysis"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To paralyse, either completely or partially.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/palsy"],
    },
    {
      word: "palsy",
      phonetic: "/ˈpælzi/",
      phonetics: [{ text: "/ˈpælzi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Chummy, friendly.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/palsy"],
    },
  ],
  vista: [
    {
      word: "vista",
      phonetic: "/ˈviːstə/",
      phonetics: [{ text: "/ˈviːstə/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A distant view or prospect, especially one seen through some opening, avenue or passage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A site offering such a view.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A vision; a view presented to the mind in prospect or in retrospect by the imagination.",
              synonyms: [],
              antonyms: [],
              example: "a vista of pleasure to come",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make a vista or landscape of.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/vista"],
    },
  ],
  croon: [
    {
      word: "croon",
      phonetic: "/ˈkɹuːn/",
      phonetics: [{ text: "/ˈkɹuːn/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A soft or sentimental hum or song.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To hum or sing softly or in a sentimental manner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To say softly or gently",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To soothe by singing softly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make a continuous hollow moan, as cattle do when in pain.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/croon"],
    },
  ],
  conch: [
    {
      word: "conch",
      phonetic: "/kɒntʃ/",
      phonetics: [
        {
          text: "/kɒntʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/conch-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88681526",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A marine gastropod of the family Strombidae which lives in its own spiral shell.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The shell of this sea animal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A musical instrument made from a large spiral seashell, somewhat like a trumpet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The semidome of an apse, or the apse itself.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To refine the flavour and texture of chocolate by warming and grinding, either in a traditional concher, or between rollers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To play a conch seashell as a musical instrument, by blowing through a hole made close to the origin of the spiral.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A machine (rather like a rotating pestle and mortar) used to refine the flavour and texture of chocolate by warming and grinding.",
              synonyms: ["conch", "conche"],
              antonyms: [],
            },
            {
              definition: "A person who operates such a machine.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["conch", "conche"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/conch",
        "https://en.wiktionary.org/wiki/concher",
      ],
    },
  ],
  udder: [
    {
      word: "udder",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/udder-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79218920",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈʌdə/", audio: "" },
        { text: "/ˈʌdɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An organ formed of the mammary glands of female quadruped mammals, particularly ruminants such as cattle, goats, sheep and deer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(impolite) A woman's breast.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/udder"],
    },
  ],
  tacos: [
    {
      word: "tacos",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A Mexican snack food; a small tortilla (soft or hard shelled), with typically some type of meat, rice, beans, cheese, diced vegetables (usually tomatoes and lettuce, as served in the United States, and cilantro, onion, and avocado, as served in México) and salsa.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The vulva.", synonyms: ["pink taco"], antonyms: [] },
            {
              definition:
                "A yellow stain on a shirt armpit caused by sweat or deodorant.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["pink taco"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To fold or cause to buckle in half, similar to the way a taco is folded.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/taco",
        "https://en.wiktionary.org/wiki/tacos",
      ],
    },
  ],
  skits: [
    {
      word: "skits",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A short comic performance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A jeer or sally; a brief satire.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A wanton girl; a wench.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/skit",
        "https://en.wiktionary.org/wiki/skits",
      ],
    },
  ],
  mikes: [
    {
      word: "mikes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A microphone.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To microphone; to place one or more microphones (mikes) on.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To measure using a micrometer.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A minute.",
              synonyms: [],
              antonyms: [],
              example: "We'll be there in one zero mikes [i.e. ten minutes].",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mike",
        "https://en.wiktionary.org/wiki/mikes",
      ],
    },
  ],
  quits: [
    {
      word: "quits",
      phonetic: "/kwɪts/",
      phonetics: [{ text: "/kwɪts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To pay (a debt, fine etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To repay (someone) for (something).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To repay, pay back (a good deed, injury etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To conduct or acquit (oneself); to behave (in a specified way).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To carry through; to go through to the end.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To set at rest; to free, as from anything harmful or oppressive; to relieve; to clear; to liberate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To release from obligation, accusation, penalty, etc.; to absolve; to acquit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To abandon, renounce (a thing).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To leave (a place).", synonyms: [], antonyms: [] },
            {
              definition: "To resign from (a job, office, position, etc.).",
              synonyms: [],
              antonyms: [],
              example:
                "After having to work overtime without being paid, I quit my job.",
            },
            {
              definition:
                "To stop, give up (an activity) (usually + gerund or verbal noun).",
              synonyms: [],
              antonyms: [],
              example: "John is planning to quit smoking.",
            },
            {
              definition: "To close (an application).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "On equal monetary terms; neither owing or being owed.",
              synonyms: [],
              antonyms: [],
              example:
                "Here's the last of the money you lent me. We're quits now, right?",
            },
          ],
          synonyms: ["all even", "even", "even-steven"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/quit",
        "https://en.wiktionary.org/wiki/quits",
      ],
    },
    {
      word: "quits",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of numerous species of small passerine birds native to tropical America.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/quit",
        "https://en.wiktionary.org/wiki/quits",
      ],
    },
  ],
  preen: [
    {
      word: "preen",
      phonetic: "/pɹiːn/",
      phonetics: [{ text: "/pɹiːn/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A forked tool used by clothiers for dressing cloth.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "(dialectal) pin", synonyms: [], antonyms: [] },
            {
              definition: "(dialectal) bodkin; brooch",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To pin; fasten.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/preen"],
    },
    {
      word: "preen",
      phonetic: "/pɹiːn/",
      phonetics: [{ text: "/pɹiːn/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(of birds) To groom; to trim or dress with the beak, as the feathers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To show off, posture, or smarm.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To trim up, as trees.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/preen"],
    },
  ],
  aster: [
    {
      word: "aster",
      phonetic: "/ˈæstə(ɹ)/",
      phonetics: [
        { text: "/ˈæstə(ɹ)/", audio: "" },
        { text: "/ˈæstə(ɹ)/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of several plants of the genus Aster; one of its flowers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A star-shaped structure formed during the mitosis of a cell.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A star.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/aster"],
    },
  ],
  adder: [
    {
      word: "adder",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/adder-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75729334",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈædɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Any snake.", synonyms: [], antonyms: [] },
            {
              definition:
                "A name loosely applied to various snakes more or less resembling the viper; a viper.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/adder"],
    },
    {
      word: "adder",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/adder-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75729334",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈædɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Someone who or something which performs arithmetic addition; a machine for adding numbers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An electronic device that adds voltages, currents or frequencies.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Something which adds or increases.",
              synonyms: [],
              antonyms: [],
              example:
                "They sought out cost adders with an eye toward eliminating them.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/adder"],
    },
  ],
  elegy: [
    {
      word: "elegy",
      phonetic: "/ˈɛlɪdʒi/",
      phonetics: [
        {
          text: "/ˈɛlɪdʒi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/elegy-ca.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50989158",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
        {
          text: "/ˈɛlɪdʒi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/elegy-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=31972295",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
        { text: "/ˈɛlɪdʒi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A mournful or plaintive poem; a funeral song; a poem of lamentation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A composition of mournful character.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dirge", "threnody"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/elegy"],
    },
  ],
  pulpy: [
    {
      word: "pulpy",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having the characteristics of pulp",
              synonyms: [],
              antonyms: [],
              example: "the pulpy texture of overripe fruit",
            },
            {
              definition:
                "Having the characteristics of pulp fiction; thus, having a garish focus on sex and violence",
              synonyms: [],
              antonyms: [],
              example: "a pulpy TV thriller",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pulpy"],
    },
  ],
  scows: [
    {
      word: "scows",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A large flat-bottomed boat, having broad, square ends.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To transport in a scow.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/scow",
        "https://en.wiktionary.org/wiki/scows",
      ],
    },
  ],
  baled: [
    {
      word: "baled",
      phonetic: "/beɪld/",
      phonetics: [
        {
          text: "/beɪld/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/baled-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=60477602",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To remove water from a boat with buckets etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bale",
        "https://en.wiktionary.org/wiki/baled",
      ],
    },
  ],
  hovel: [
    {
      word: "hovel",
      phonetic: "/ˈhɒvəl/",
      phonetics: [
        {
          text: "/ˈhɒvəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hovel-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=86956875",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An open shed for sheltering cattle, or protecting produce, etc., from the weather.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A poor cottage; a small, mean house; a hut.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In the manufacture of porcelain, a large, conical brick structure around which the firing kilns are grouped.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To put in a hovel; to shelter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To construct a chimney so as to prevent smoking, by making two of the more exposed walls higher than the others, or making an opening on one side near the top.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hovel"],
    },
  ],
  lavas: [
    {
      word: "lavas",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The molten rock ejected by a volcano from its crater or fissured sides.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Magma.", synonyms: [], antonyms: [] },
            {
              definition: "A shade of red, named after the volcanic lava.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lava",
        "https://en.wiktionary.org/wiki/lavas",
      ],
    },
  ],
  crave: [
    {
      word: "crave",
      phonetic: "/kɹeɪv/",
      phonetics: [
        {
          text: "/kɹeɪv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/crave-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=33142654",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A formal application to a court to make a particular order.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To desire strongly, so as to satisfy an appetite; to long or yearn for.",
              synonyms: [],
              antonyms: [],
              example: "to crave after wealth",
            },
            {
              definition: "To ask for earnestly; to beg; to claim.",
              synonyms: [],
              antonyms: [],
              example:
                "I humbly crave your indulgence to read this letter until the end.",
            },
            {
              definition: "To call for; to require as a course of action.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/crave"],
    },
  ],
  optic: [
    {
      word: "optic",
      phonetic: "/ˈɒptɪk/",
      phonetics: [
        {
          text: "/ˈɒptɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/optic-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88987691",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈɑptɪk/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "An eye.", synonyms: [], antonyms: [] },
            {
              definition:
                "A lens or other part of an optical instrument that interacts with light.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A measuring device with a small window, attached to an upside-down bottle, used to dispense alcoholic drinks in a bar.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of, or relating to the eye or to vision.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of, or relating to optics or optical instruments.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/optic"],
    },
  ],
  welts: [
    {
      word: "welts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To roll; revolve", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A ridge or lump on the skin, as caused by a blow; a wheal or weal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(shoemaking) A strip of leather set into the seam between the outsole of a shoe and the upper, through which these parts are joined by stitching or stapling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A strip of material or covered cord applied to a seam or garment edge to strengthen or cover it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In steam boilers and sheet-iron work, a strip riveted upon the edges of plates that form a butt joint.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In carpentry, a strip of wood fastened over a flush seam or joint, or an angle, to strengthen it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In machine-made stockings, a strip, or flap, of which the heel is formed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A narrow border, as of an ordinary, but not extending around the ends.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A feature resembling a welt.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cause to have welts, to beat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To install welt (a welt or welts) to reinforce.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To decay.", synonyms: [], antonyms: [] },
            { definition: "To become stringy.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/welt",
        "https://en.wiktionary.org/wiki/welts",
      ],
    },
  ],
  busts: [
    {
      word: "busts",
      phonetic: "/bʌsts/",
      phonetics: [{ text: "/bʌsts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sculptural portrayal of a person's head and shoulders.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The breasts and upper thorax of a woman.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The downward portion of a boom and bust cycle; a recession.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A police raid or takedown of a criminal enterprise.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A disappointment.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The act of arresting someone for a crime, or raiding a suspected criminal operation.",
              synonyms: [],
              antonyms: [],
              example: "a narcotics bust",
            },
            {
              definition: "A failed enterprise; a bomb.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A refutation of an opening, or of a previously published analysis.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A player who fails to meet expectations.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To break.",
              synonyms: [],
              antonyms: [],
              example: "I busted my cooker while trying to fix it.",
            },
            {
              definition: "To arrest (someone) for a crime.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To catch (someone) in the act of doing something wrong, socially and morally inappropriate, or illegal, especially when being done in a sneaky or secretive state.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An emphatic synonym of do or get.",
              synonyms: [],
              antonyms: [],
              example: "He busted huge air off that jump!",
            },
            {
              definition: "To reduce in rank.",
              synonyms: [],
              antonyms: [],
              example: "He busted him down to patrolman for insubordination.",
            },
            {
              definition:
                "To undo a trade, generally an error trade, that has already been executed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To lose all of one's chips.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To exceed a score of 21.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To break in (an animal).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To ejaculate; to eject semen.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["nick"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bust",
        "https://en.wiktionary.org/wiki/busts",
      ],
    },
  ],
  knave: [
    {
      word: "knave",
      phonetic: "/neɪv/",
      phonetics: [
        {
          text: "/neɪv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/knave-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1547667",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A boy; especially, a boy servant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any male servant; a menial.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A tricky, deceitful fellow; a dishonest person.",
              synonyms: ["rogue", "villain"],
              antonyms: [],
            },
            {
              definition:
                "A playing card marked with the figure of a servant or soldier; a jack.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["rogue", "villain"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/knave"],
    },
  ],
  razed: [
    {
      word: "razed",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To demolish; to level to the ground.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To scrape as if with a razor.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Slashed or striped in patterns.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/raze",
        "https://en.wiktionary.org/wiki/razed",
      ],
    },
  ],
  shins: [
    {
      word: "shins",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The front part of the leg below the knee; the front edge of the shin bone: Shinbone",
              synonyms: ["tibia"],
              antonyms: [],
            },
            {
              definition: "A fishplate for a railway.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["tibia"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                '(as "shin up") To climb a mast, tree, rope, or the like, by embracing it alternately with the arms and legs, without help of steps, spurs, or the like.',
              synonyms: ["shinny"],
              antonyms: [],
              example: "to shin up a mast",
            },
            {
              definition: "To strike with the shin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To run about borrowing money hastily and temporarily, as when trying to make a payment.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["shinny"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The twenty-first letter of many Semitic alphabets/abjads (Phoenician, Aramaic, Hebrew, Syriac, Arabic and others): Shin (letter)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/shin",
        "https://en.wiktionary.org/wiki/shins",
      ],
    },
  ],
  totes: [
    {
      word: "totes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A tote bag.", synonyms: [], antonyms: [] },
            { definition: "A heavy burden.", synonyms: [], antonyms: [] },
            {
              definition:
                "(logistics) A kind of plastic box used for transporting goods.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A pari-mutuel machine; a totalizator.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Pari-mutuel betting.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tote",
        "https://en.wiktionary.org/wiki/totes",
      ],
    },
    {
      word: "totes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "To the fullest extent or degree.",
              synonyms: ["completely", "entirely", "fully", "wholly"],
              antonyms: [],
              example: "They've totally demolished the bank on Main Street.",
            },
            {
              definition: "(degree) Very; extremely.",
              synonyms: [],
              antonyms: [],
              example: "That was totally wicked!",
            },
            {
              definition: "(modal) Definitely; for sure.",
              synonyms: [],
              antonyms: [],
              example: "That was totally not what happened.",
            },
          ],
          synonyms: ["completely", "entirely", "fully", "wholly"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/totally",
        "https://en.wiktionary.org/wiki/totes",
      ],
    },
  ],
  scoot: [
    {
      word: "scoot",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scoot-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241710",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A dollar.", synonyms: [], antonyms: [] },
            { definition: "A scooter.", synonyms: [], antonyms: [] },
            {
              definition: "A sideways shuffling or sliding motion.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To walk fast; to go quickly; to run away hastily.",
              synonyms: [],
              antonyms: [],
              example: "They scooted over to the window.",
            },
            { definition: "To ride on a scooter.", synonyms: [], antonyms: [] },
            {
              definition:
                "(of an animal) To move with the forelegs while sitting, so that the floor rubs against its rear end.",
              synonyms: [],
              antonyms: [],
              example: "The dog was scooting all over our new carpet.",
            },
            {
              definition:
                "To move sideways (especially along a seat for multiple people), usually to make room for someone else (to sit, stand, etc.).",
              synonyms: [],
              antonyms: [],
              example: "Do you mind scooting a bit to the left?",
            },
            {
              definition: "To dispatch someone or something at speed.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scoot"],
    },
    {
      word: "scoot",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scoot-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241710",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A sudden flow of water; a squirt.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To squirt.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scoot"],
    },
  ],
  dears: [
    {
      word: "dears",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A very kind, loving person.",
              synonyms: [],
              antonyms: [],
              example: "My cousin is such a dear, always drawing me pictures.",
            },
            { definition: "A beloved person.", synonyms: [], antonyms: [] },
            {
              definition:
                "An affectionate, familiar term of address, such as used between husband and wife.",
              synonyms: [],
              antonyms: [],
              example: "Pass me the salt, would you dear?",
            },
          ],
          synonyms: ["darling"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dear",
        "https://en.wiktionary.org/wiki/dears",
      ],
    },
  ],
  crock: [
    {
      word: "crock",
      phonetic: "/kɹɒk/",
      phonetics: [
        {
          text: "/kɹɒk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/crock-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79293464",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A stoneware or earthenware jar or storage container.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A piece of broken pottery, a shard.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person who is physically limited by age, illness or injury.",
              synonyms: [],
              antonyms: [],
              example: "old crocks’ home",
            },
            {
              definition:
                "An old or broken-down vehicle (and formerly a horse or ewe).",
              synonyms: [],
              antonyms: [],
              example: "old crocks race",
            },
            {
              definition:
                "Silly talk, a foolish belief, a poor excuse, nonsense.",
              synonyms: [],
              antonyms: [],
              example: "That's a bunch of crock.",
            },
            { definition: "A low stool.", synonyms: [], antonyms: [] },
            {
              definition:
                "A patient who is difficult to treat, especially one who complains of a minor or imagined illness.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["banger"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To break something or injure someone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(leatherworking) To transfer coloring through abrasion from one item to another.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cover the drain holes of a planter with stones or similar material, in order to ensure proper drainage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To put or store (something) in a crock or pot.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/crock"],
    },
    {
      word: "crock",
      phonetic: "/kɹɒk/",
      phonetics: [
        {
          text: "/kɹɒk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/crock-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79293464",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The loose black particles collected from combustion, as on pots and kettles, or in a chimney; soot; smut.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Colouring matter that rubs off from cloth.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To give off crock or smut.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/crock"],
    },
  ],
  mutes: [
    {
      word: "mutes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A stopped consonant; a stop.",
              synonyms: ["occlusive", "plosive", "stop"],
              antonyms: [],
            },
            {
              definition: "An actor who does not speak; a mime performer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person who does not have the power of speech.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hired mourner at a funeral; an undertaker's assistant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An object for dulling the sound of an instrument, especially a brass instrument, or damper for pianoforte; a sordine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An electronic switch or control that mutes the sound.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A mute swan.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["occlusive", "plosive", "stop"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To silence, to make quiet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To turn off the sound of.",
              synonyms: [],
              antonyms: [],
              example: "Please mute the music while I make a call.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The faeces of a hawk or falcon.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "Of a bird: to defecate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cast off; to moult.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mute",
        "https://en.wiktionary.org/wiki/mutes",
      ],
    },
  ],
  trims: [
    {
      word: "trims",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Decoration; especially, decoration placed along edges or borders.",
              synonyms: [],
              antonyms: [],
              example: "Paint the house white with blue trim.",
            },
            {
              definition:
                "A haircut, especially a moderate one to touch up an existing style.",
              synonyms: [],
              antonyms: [],
              example:
                "I went to the hairdresser for a trim but came back nearly bald.",
            },
            {
              definition: "Dress; gear; ornaments.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The manner in which something is equipped or adorned; order; disposition.",
              synonyms: [],
              antonyms: [],
              example: "The car comes in three different trims.",
            },
            { definition: "Sexual intercourse.", synonyms: [], antonyms: [] },
            {
              definition:
                "The fore-and-aft angle of the vessel to the water, with reference to the cargo and ballast; the manner in which a vessel floats on the water, whether on an even keel or down by the head or stern.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The arrangement of the sails with reference to the wind.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To reduce slightly; to cut; especially, to remove excess.",
              synonyms: [],
              antonyms: [],
              example: "A ranch steak is usually trimmed of all excess fat.",
            },
            {
              definition:
                "To decorate or adorn; especially of a Christmas tree.",
              synonyms: [],
              antonyms: [],
              example: "They traditionally trim the tree on Christmas Eve.",
            },
            {
              definition: "(of an aircraft) To adjust pitch using trim tabs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a vessel) To modify the angle relative to the water by shifting cargo or ballast; to adjust for sailing; to assume, or cause to assume a certain position, or trim, in the water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a vessel's sails) To modify the angle (of the sails) relative to the wind, especially to set them at the most advantageous angle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To balance; to fluctuate between parties, so as to appear to favour each.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make trim; to put in due order for any purpose; to make right, neat, or pleasing; to adjust.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of timber) To dress; to make smooth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To rebuke; to reprove.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To beat or thrash.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/trim",
        "https://en.wiktionary.org/wiki/trims",
      ],
    },
  ],
  skein: [
    {
      word: "skein",
      phonetic: "/skeɪn/",
      phonetics: [
        {
          text: "/skeɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/skein-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88902763",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A quantity of yarn, thread, or the like, put up together, after it is taken from the reel. A skein of cotton yarn is formed by eighty turns of the thread around a fifty-four inch reel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A web, a weave, a tangle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The membrane of a fish ovary.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(wagonmaking) A metallic strengthening band or thimble on the wooden arm of an axle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(provincial England) A group of wild fowl, (e.g. geese, goslings) when they are in flight.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A winning streak.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To wind or weave into a skein.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/skein"],
    },
  ],
  doted: [
    {
      word: "doted",
      phonetic: "/ˈdəʊtɪd/",
      phonetics: [{ text: "/ˈdəʊtɪd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(usually with on) To be weakly or foolishly fond of somebody.",
              synonyms: ["adore", "love"],
              antonyms: [],
              example: "Little Bill's parents just keep doting on him.",
            },
            {
              definition: "To act in a foolish manner; to be senile.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["adore", "love"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Stupid; foolish", synonyms: [], antonyms: [] },
            {
              definition: "Half-rotten",
              synonyms: [],
              antonyms: [],
              example: "doted wood",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dote",
        "https://en.wiktionary.org/wiki/doted",
      ],
    },
  ],
  shuns: [
    {
      word: "shuns",
      phonetic: "/ʃʌnz/",
      phonetics: [{ text: "/ʃʌnz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To avoid, especially persistently.",
              synonyms: [],
              antonyms: [],
              example: "Acrophobes shun mountaineering.",
            },
            {
              definition:
                "To escape (a threatening evil, an unwelcome task etc).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To screen, hide.", synonyms: [], antonyms: [] },
            { definition: "To shove, push.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/shun",
        "https://en.wiktionary.org/wiki/shuns",
      ],
    },
  ],
  veers: [
    {
      word: "veers",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A turn or swerve; an instance of veering.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/veer",
        "https://en.wiktionary.org/wiki/veers",
      ],
    },
  ],
  fakes: [
    {
      word: "fakes",
      phonetic: "/feɪks/",
      phonetics: [{ text: "/feɪks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Something which is not genuine, or is presented fraudulently.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A trick; a swindle.", synonyms: [], antonyms: [] },
            {
              definition:
                "A move meant to deceive an opposing player, used for gaining advantage for example when dribbling an opponent.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["deke", "feint"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cheat; to swindle; to steal; to rob.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To modify fraudulently, so as to make an object appear better or other than it really is",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make a counterfeit, to counterfeit, to forge, to falsify.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make a false display of, to affect, to feign, to simulate.",
              synonyms: [],
              antonyms: [],
              example: "to fake a marriage",
            },
          ],
          synonyms: ["pass off", "pose", "adulterate"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One of the circles or windings of a cable or hawser, as it lies in a coil; a single turn or coil.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To coil (a rope, line, or hawser), by winding alternately in opposite directions, in layers usually of zigzag or figure of eight form, to prevent twisting when running out.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fake",
        "https://en.wiktionary.org/wiki/fakes",
      ],
    },
  ],
  yoked: [
    {
      word: "yoked",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/yoked-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79326360",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To link or to join.", synonyms: [], antonyms: [] },
            { definition: "To unite, to connect.", synonyms: [], antonyms: [] },
            {
              definition:
                "To enslave; to bring into bondage; to restrain; to confine.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Wearing a yoke.",
              synonyms: [],
              antonyms: [],
              example: "The yoked oxen stood ready.",
            },
            {
              definition:
                "Having large and well-defined muscles particularly at the neck and the trapezii (forming thus the “yoke“).",
              synonyms: [],
              antonyms: [],
              example:
                "By the end of his fourth year of weight-lifting, Lee was yoked.",
            },
            {
              definition: "Married.",
              synonyms: [],
              antonyms: [],
              example: "They got yoked four years ago.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/yoke",
        "https://en.wiktionary.org/wiki/yoked",
      ],
    },
  ],
  wooed: [
    {
      word: "wooed",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To endeavor to gain someone's support.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(often of a man) To try to persuade (someone) to be in an amorous relationship with",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To court solicitously; to invite with importunity.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["court"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/woo",
        "https://en.wiktionary.org/wiki/wooed",
      ],
    },
  ],
  hacks: [
    {
      word: "hacks",
      phonetic: "/hæks/",
      phonetics: [{ text: "/hæks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A tool for chopping.", synonyms: [], antonyms: [] },
            { definition: "A hacking blow.", synonyms: [], antonyms: [] },
            {
              definition: "A gouge or notch made by such a blow.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A dry cough.", synonyms: [], antonyms: [] },
            {
              definition:
                "A hacking; a catch in speaking; a short, broken cough.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A try, an attempt.", synonyms: [], antonyms: [] },
            {
              definition:
                "The foothold traditionally cut into the ice from which the person who throws the rock pushes off for delivery.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A mattock or a miner's pickaxe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An expedient, temporary solution, such as a small patch or change to code, meant to be replaced with a more elegant solution at a later date.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An interesting technical achievement, particularly in computer programming.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A trick, shortcut, skill, or novelty method to increase productivity, efficiency or ease.",
              synonyms: [],
              antonyms: [],
              example:
                "Putting your phone in a sandwich bag when you go to the beach is such a great hack.",
            },
            {
              definition:
                "An illegal attempt to gain access to a computer network.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A video game or any computer software that has been altered from its original state.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Time check.", synonyms: [], antonyms: [] },
            {
              definition: "A swing of the bat at a pitched ball by the batter.",
              synonyms: [],
              antonyms: [],
              example:
                "He took a few hacks, but the pitcher finally struck him out.",
            },
            {
              definition: "A kick on the shins in football.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Confinement of an officer to their stateroom as a punishment.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "crack",
            "band-aid",
            "contrivance",
            "improvisation",
            "improvision",
            "kludge",
            "makeshift",
            "patch",
            "quick fix",
            "lifehack",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To chop or cut down in a rough manner.",
              synonyms: [],
              antonyms: [],
              example:
                "They hacked the brush down and made their way through the jungle.",
            },
            {
              definition: "To cough noisily.",
              synonyms: [],
              antonyms: [],
              example: "This cold is awful. I can't stop hacking.",
            },
            {
              definition: "To withstand or put up with a difficult situation.",
              synonyms: [],
              antonyms: [],
              example:
                "Can you hack it out here with no electricity or running water?",
            },
            {
              definition:
                "To make a quick code change to patch a computer program, often one that, while being effective, is inelegant or makes the program harder to maintain.",
              synonyms: ["frob", "tweak"],
              antonyms: [],
              example:
                "I hacked in a fix for this bug, but we'll still have to do a real fix later.",
            },
            {
              definition: "To accomplish a difficult programming task.",
              synonyms: [],
              antonyms: [],
              example:
                "He can hack like no one else and make the program work as expected.",
            },
            {
              definition:
                "To work with something on an intimately technical level.",
              synonyms: [],
              antonyms: [],
              example: "I'm currently hacking distributed garbage collection.",
            },
            {
              definition:
                "(by extension) To apply a trick, shortcut, skill, or novelty method to something to increase productivity, efficiency or ease.",
              synonyms: [],
              antonyms: [],
              example: "I read up on dating tips so I can hack my sex life.",
            },
            {
              definition:
                "To hack into; to gain unauthorized access to (a computer system, e.g., a website, or network) by manipulating code.",
              synonyms: ["crack"],
              antonyms: [],
            },
            {
              definition:
                "(by extension) To gain unauthorised access to a computer or online account belonging to (a person or organisation).",
              synonyms: [],
              antonyms: [],
              example:
                "When I logged into the social network, I discovered I'd been hacked.",
            },
            {
              definition:
                "To strike an opponent's leg with one's hockey stick.",
              synonyms: [],
              antonyms: [],
              example:
                "He's going to the penalty box after hacking the defender in front of the goal.",
            },
            {
              definition:
                "To make a flailing attempt to hit the puck with a hockey stick.",
              synonyms: [],
              antonyms: [],
              example:
                "There's a scramble in front of the net as the forwards are hacking at the bouncing puck.",
            },
            {
              definition: "To swing at a pitched ball.",
              synonyms: [],
              antonyms: [],
              example: "He went to the batter's box hacking.",
            },
            {
              definition: "(soccer and rugby) To kick (a player) on the shins.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To strike in a frantic movement.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To strike lightly as part of tapotement massage.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["crack", "frob", "tweak"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A board which the falcon's food is placed on; used by extension for the state of partial freedom in which they are kept before being trained.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A food-rack for cattle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A rack used to dry something, such as bricks, fish, or cheese.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A grating in a mill race.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To lay (bricks) on a rack to dry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To keep (young hawks) in a state of partial freedom, before they are trained.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A horse for hire, especially one which is old and tired.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person, often a journalist, hired to do routine work.",
              synonyms: [],
              antonyms: [],
              example:
                "I got by on hack work for years before I finally published my novel.",
            },
            {
              definition:
                "Someone who is available for hire; hireling, mercenary.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A taxicab (hackney cab) driver.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A vehicle let for hire; originally, a hackney coach, now typically a taxicab.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A hearse.", synonyms: [], antonyms: [] },
            {
              definition: "(authorship) An untalented writer.",
              synonyms: [],
              antonyms: [],
              example: "Dason is nothing but a two-bit hack.",
            },
            {
              definition:
                "One who is professionally successful despite producing mediocre work. (Usually applied to persons in a creative field.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A talented writer-for-hire, paid to put others' thoughts into felicitous language.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A political agitator. (slightly derogatory)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A writer who hires himself out for any sort of literary work; an overworked man; a drudge.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A procuress.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["nag"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make common or cliched; to vulgarise.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To ride a horse at a regular pace; to ride on a road (as opposed to riding cross-country etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be exposed or offered or to common use for hire; to turn prostitute.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To live the life of a drudge or hack.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To use as a hack; to let out for hire.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To use frequently and indiscriminately, so as to render trite and commonplace.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small ball usually made of woven cotton or suede and filled with rice, sand or some other filler, for use in hackeysack.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To play hackeysack.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hack",
        "https://en.wiktionary.org/wiki/hacks",
      ],
    },
  ],
  sprig: [
    {
      word: "sprig",
      phonetic: "/spɹɪɡ/",
      phonetics: [
        {
          text: "/spɹɪɡ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sprig-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=508877",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small shoot or twig of a tree or other plant; a spray.",
              synonyms: [],
              antonyms: [],
              example: "a sprig of laurel or of parsley",
            },
            {
              definition: "An ornament resembling a small shoot or twig.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the separate pieces of lace fastened on a ground in applique lace.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(sometimes mildly) A youth; a lad.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A brad, or nail without a head.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A small eyebolt ragged or barbed at the point.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A house sparrow.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To decorate with sprigs, or with representations of sprigs, as in embroidery or pottery.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sprig"],
    },
  ],
  wands: [
    {
      word: "wands",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A hand-held narrow rod, usually used for pointing or instructing, or as a traditional emblem of authority.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) An instrument shaped like a wand, such as a curling wand.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A magic wand.", synonyms: [], antonyms: [] },
            {
              definition: "A stick, branch, or stalk, especially of willow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A card of a particular suit of the minor arcana in tarot, the wands.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wand",
        "https://en.wiktionary.org/wiki/wands",
      ],
    },
  ],
  lulls: [
    {
      word: "lulls",
      phonetic: "[lɐɫz]",
      phonetics: [
        { text: "[lɐɫz]", audio: "" },
        { text: "[ɫʌɫz]", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A period of rest or soothing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A period of reduced activity; a respite",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A period without waves or wind.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An extended pause between sets of waves.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cause to rest by soothing influences; to compose; to calm",
              synonyms: ["quiet", "soothe"],
              antonyms: [],
            },
            {
              definition:
                "To become gradually calm; to subside; to cease or abate.",
              synonyms: [],
              antonyms: [],
              example: "The storm lulled.",
            },
          ],
          synonyms: ["appease", "quiet", "soothe"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lull",
        "https://en.wiktionary.org/wiki/lulls",
      ],
    },
  ],
  seers: [
    {
      word: "seers",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One who sees something; an eyewitness.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One who foretells the future; a clairvoyant, prophet, soothsayer or diviner.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Unit of mass or volume in the Middle East and Indian subcontinent.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/seer",
        "https://en.wiktionary.org/wiki/seers",
        "https://en.wiktionary.org/wiki/sihr",
      ],
    },
  ],
  snobs: [
    {
      word: "snobs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person who wishes to be seen as a member of the upper classes and who looks down on those perceived to have inferior or unrefined tastes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A cobbler or shoemaker.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A member of the lower classes; a commoner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A workman who works for lower wages than his fellows, or who will not join a strike.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Cambridge University) A townsman, as opposed to a gownsman.",
              synonyms: ["cad"],
              antonyms: [],
            },
          ],
          synonyms: ["cad"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/snob",
        "https://en.wiktionary.org/wiki/snobs",
      ],
    },
  ],
  nooks: [
    {
      word: "nooks",
      phonetic: "/nʊks/",
      phonetics: [{ text: "/nʊks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A small corner formed by two walls; an alcove.",
              synonyms: ["alcove", "ancone", "recess"],
              antonyms: [],
              example:
                "There was a small broom for sweeping ash kept in the nook between the fireplace bricks and the wall.",
            },
            {
              definition: "A hidden or secluded spot; a secluded retreat.",
              synonyms: [],
              antonyms: [],
              example:
                "The back of the used book shop was one of her favorite nooks; she could read for hours and no one would bother her or pester her to buy.",
            },
            {
              definition: "A recess, cove or hollow.",
              synonyms: ["niche"],
              antonyms: [],
            },
            {
              definition:
                "An English unit of land area, originally 1/4 of a yardland but later 12 1/2 or 20 acres.",
              synonyms: ["fardel"],
              antonyms: [],
            },
            {
              definition:
                "A corner of a piece of land; an angled piece of land, especially one extending into other land.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["alcove", "ancone", "recess", "fardel", "niche"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To withdraw into a nook.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To situate in a nook.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/nook",
        "https://en.wiktionary.org/wiki/nooks",
      ],
    },
  ],
  pined: [
    {
      word: "pined",
      phonetic: "/paɪnd/",
      phonetics: [{ text: "/paɪnd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To languish; to lose flesh or wear away through distress.",
              synonyms: ["droop", "languish"],
              antonyms: [],
            },
            {
              definition: "To long, to yearn so much that it causes suffering.",
              synonyms: ["long", "yearn"],
              antonyms: [],
              example: "Laura was pining for Bill all the time he was gone.",
            },
            {
              definition: "To grieve or mourn for.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To inflict pain upon; to torment.",
              synonyms: ["afflict", "torment", "torture"],
              antonyms: [],
            },
          ],
          synonyms: [
            "afflict",
            "torment",
            "torture",
            "droop",
            "languish",
            "long",
            "yearn",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pine",
        "https://en.wiktionary.org/wiki/pined",
      ],
    },
  ],
  perky: [
    {
      word: "perky",
      phonetic: "/ˈpɝki/",
      phonetics: [{ text: "/ˈpɝki/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Lively or enthusiastic.",
              synonyms: [],
              antonyms: [],
              example: "She answered with a perky smile and bounced off.",
            },
            {
              definition: "Standing upright; firm.",
              synonyms: [],
              antonyms: [],
              example: "The plant looks much perkier since I watered it.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/perky"],
    },
  ],
  mooed: [
    {
      word: "mooed",
      phonetic: "/muːd/",
      phonetics: [{ text: "/muːd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "Of a cow or bull, to make its characteristic lowing sound.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["low"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/moo",
        "https://en.wiktionary.org/wiki/mooed",
      ],
    },
  ],
  frill: [
    {
      word: "frill",
      phonetic: "/fɹɪl/",
      phonetics: [{ text: "/fɹɪl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A strip of pleated fabric or paper used as decoration or trim.",
              synonyms: ["flounce", "furbelow", "ruffle"],
              antonyms: [],
            },
            {
              definition:
                "A substance or material on the edge of something, resembling such a strip of fabric.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A wrinkled edge to a film.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something extraneous or not essential; something purely for show or effect; a luxury.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The relatively extensive margin seen on the back of the heads of reptiles, with either a bony support or a cartilaginous one.",
              synonyms: ["neck frill"],
              antonyms: [],
            },
          ],
          synonyms: ["flounce", "furbelow", "ruffle", "neck frill"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To make into a frill.", synonyms: [], antonyms: [] },
            { definition: "To become wrinkled.", synonyms: [], antonyms: [] },
            {
              definition:
                "To provide or decorate with a frill or frills; to turn back in crimped plaits.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/frill"],
    },
    {
      word: "frill",
      phonetic: "/fɹɪl/",
      phonetics: [{ text: "/fɹɪl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To shake or shiver as with cold (with reference to a hawk).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cry (with reference to a bird of prey).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/frill"],
    },
  ],
  dines: [
    {
      word: "dines",
      phonetic: "/daɪnz/",
      phonetics: [{ text: "/daɪnz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To eat; to eat dinner or supper.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To give a dinner to; to furnish with the chief meal; to feed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To dine upon; to have to eat.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dine",
        "https://en.wiktionary.org/wiki/dines",
      ],
    },
  ],
  booze: [
    {
      word: "booze",
      phonetic: "/buːz/",
      phonetics: [
        {
          text: "/buːz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/booze-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241222",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Any alcoholic beverage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A session of drinking alcohol; a drinking party.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["grog"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To drink alcohol.",
              synonyms: [],
              antonyms: [],
              example:
                "We were out all night boozing until we dragged ourselves home hung over.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/booze"],
    },
  ],
  tripe: [
    {
      word: "tripe",
      phonetic: "/tɹaɪp/",
      phonetics: [
        {
          text: "/tɹaɪp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tripe-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=755204",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The lining of the large stomach of ruminating animals, when prepared for food.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The entrails; hence, humorously or in contempt, the belly; -- generally used in the plural.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(chiefly plural) Something disparaged as valueless, especially written works and popular entertainment (movies, television).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "That (what has just been said) is untrue.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tripe"],
    },
  ],
  prong: [
    {
      word: "prong",
      phonetic: "/pɹɒŋ/",
      phonetics: [
        { text: "/pɹɒŋ/", audio: "" },
        { text: "/pɹɑŋ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A thin, pointed, projecting part, as of an antler or a fork or similar tool. A tine.",
              synonyms: [],
              antonyms: [],
              example: "a pitchfork with four prongs",
            },
            {
              definition: "A branch; a fork.",
              synonyms: [],
              antonyms: [],
              example: "the two prongs of a river",
            },
            { definition: "The penis.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To pierce or poke with, or as if with, a prong",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/prong"],
    },
  ],
  drips: [
    {
      word: "drips",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A drop of a liquid.",
              synonyms: [],
              antonyms: [],
              example: "I put a drip of vanilla extract in my hot cocoa.",
            },
            {
              definition:
                "A falling or letting fall in drops; act of dripping.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An apparatus that slowly releases a liquid, especially one that intravenously releases drugs into a patient's bloodstream.",
              synonyms: [],
              antonyms: [],
              example:
                "He's not doing so well. The doctors have put him on a drip.",
            },
            {
              definition: "A limp, ineffectual, or uninteresting person.",
              synonyms: [],
              antonyms: [],
              example:
                "He couldn't even summon up the courage to ask her name... what a drip!",
            },
            {
              definition:
                "That part of a cornice, sill course, or other horizontal member, which projects beyond the rest, and has a section designed to throw off rainwater.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/drip",
        "https://en.wiktionary.org/wiki/drips",
      ],
    },
  ],
  odder: [
    {
      word: "odder",
      phonetic: "/ˈɑd.ɚ/",
      phonetics: [{ text: "/ˈɑd.ɚ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Differing from what is usual, ordinary or expected.",
              synonyms: ["strange", "unusual"],
              antonyms: ["common", "familiar", "mediocre"],
              example: "She slept in, which was very odd.",
            },
            {
              definition:
                "Without a corresponding mate in a pair or set; unmatched; (of a pair or set) mismatched.",
              synonyms: ["mismatched", "single"],
              antonyms: [],
              example: "My cat Fluffy has odd eyes: one blue and one brown.",
            },
            {
              definition:
                "Left over, remaining after the rest have been paired or grouped.",
              synonyms: [],
              antonyms: [],
              example: "I'm the odd one out.",
            },
            {
              definition:
                "Left over or remaining (as a small amount) after counting, payment, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Scattered; occasional, infrequent; not forming part of a set or pattern.",
              synonyms: [],
              antonyms: [],
              example:
                "I don't speak Latin well, so in hearing a dissertation in Latin, I would only be able to make out the odd word of it.",
            },
            {
              definition: "Not regular or planned.",
              synonyms: [],
              antonyms: [],
              example: "He's only worked odd jobs.",
            },
            {
              definition: "Used or employed for odd jobs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Numerically indivisible by two.",
              synonyms: [],
              antonyms: ["even"],
              example: "The product of odd numbers is also odd.",
            },
            {
              definition: "Numbered with an odd number.",
              synonyms: [],
              antonyms: [],
              example: "How do I print only the odd pages?",
            },
            {
              definition:
                "(in combination with a number) About, approximately; somewhat more than (an approximated round number).",
              synonyms: [],
              antonyms: [],
              example: "There were thirty-odd people in the room.",
            },
            {
              definition: "Out of the way, secluded.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "On the left.",
              synonyms: [],
              antonyms: [],
              example: "He served from the odd court.",
            },
            {
              definition:
                "Singular in excellence; matchless; peerless; outstanding.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["mismatched", "single", "strange", "unusual"],
          antonyms: ["common", "familiar", "mediocre", "even"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/odd",
        "https://en.wiktionary.org/wiki/odder",
      ],
    },
  ],
  levee: [
    {
      word: "levee",
      phonetic: "/ˈlɛv.eɪ/",
      phonetics: [
        {
          text: "/ˈlɛv.eɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/levee-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89033109",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ləˈveɪ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An embankment to prevent inundation; as, the levees along the Mississippi.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The steep bank of a river.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The border of an irrigated field.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A pier or other landing place on a river.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dike", "floodwall"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To keep within a channel by means of levees.",
              synonyms: [],
              antonyms: [],
              example: "to levee a river",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/levee"],
    },
    {
      word: "levee",
      phonetic: "/ˈlɛv.eɪ/",
      phonetics: [
        {
          text: "/ˈlɛv.eɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/levee-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89033109",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ləˈveɪ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The act of rising; getting up, especially in the morning after rest.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A reception of visitors held after getting up.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A formal reception, especially one given by royalty or other leaders.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To attend the levee or levees of.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/levee"],
    },
  ],
  antic: [
    {
      word: "antic",
      phonetic: "/ˈæn.tɪk/",
      phonetics: [{ text: "/ˈæn.tɪk/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A grotesque representation of a figure; a gargoyle.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A caricature.", synonyms: [], antonyms: [] },
            {
              definition:
                "(often in plural) A ludicrous gesture or act; ridiculous behaviour; caper.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A grotesque performer or clown, buffoon.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To perform antics, caper.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make a fool of, to cause to look ridiculous.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To perform (an action) as an antic; to mimic ridiculously.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Playful, funny, absurd",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Grotesque, incongruous.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Grotesque, bizarre", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Having existed in ancient times, descended from antiquity; used especially in reference to Greece and Rome.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Belonging to former times, not modern, out of date, old-fashioned.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Designating a style of type.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Embossed without gilt.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["antiquated", "disused", "outdated"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/antic",
        "https://en.wiktionary.org/wiki/antique",
      ],
    },
    {
      word: "antic",
      phonetic: "/ˈæn.tɪk/",
      phonetics: [{ text: "/ˈæn.tɪk/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A pose, often exaggerated, in anticipation of an action; for example, a brief squat before jumping",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/antic"],
    },
  ],
  sidle: [
    {
      word: "sidle",
      phonetic: "/ˈsaɪdl/",
      phonetics: [
        {
          text: "/ˈsaɪdl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sidle-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80980798",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈsaɪdəl/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "An act of sidling.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To (cause something to) move sideways.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In the intransitive sense often followed by up: to (cause something to) advance in a coy, furtive, or unobtrusive manner.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sidle"],
    },
  ],
  pithy: [
    {
      word: "pithy",
      phonetic: "/ˈpɪθi/",
      phonetics: [{ text: "/ˈpɪθi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Concise and meaningful.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of, like, or abounding in pith.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["concise", "laconic", "succinct", "terse"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pithy"],
    },
  ],
  corks: [
    {
      word: "corks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The bark of the cork oak, which is very light and porous and used for making bottle stoppers, flotation devices, and insulation material.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bottle stopper made from this or any other material.",
              synonyms: [],
              antonyms: [],
              example:
                "Snobs feel it's hard to call it wine with a straight face when the cork is made of plastic.",
            },
            {
              definition:
                "An angling float, also traditionally made of oak cork.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The cork oak, Quercus suber.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The dead protective tissue between the bark and cambium in woody plants, with suberin deposits making it impervious to gasses and water.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To seal or stop up, especially with a cork stopper.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To blacken (as) with a burnt cork",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To leave the cork in a bottle after attempting to uncork it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fill with cork, as the center of a baseball bat.",
              synonyms: [],
              antonyms: [],
              example:
                "He corked his bat, which was discovered when it broke, causing a controversy.",
            },
            {
              definition: "To injure through a blow; to induce a haematoma.",
              synonyms: [],
              antonyms: [],
              example: "The vicious tackle corked his leg.",
            },
            {
              definition:
                "To position one's drift net just outside of another person's net, thereby intercepting and catching all the fish that would have gone into that person's net.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An aerialist maneuver involving a rotation where the rider goes heels over head, with the board overhead.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To perform such a maneuver.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cork",
        "https://en.wiktionary.org/wiki/corks",
      ],
    },
  ],
  yelps: [
    {
      word: "yelps",
      phonetic: "/jɛlps/",
      phonetics: [{ text: "/jɛlps/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An abrupt, high-pitched noise or utterance.",
              synonyms: [],
              antonyms: [],
              example: "The puppy let out a yelp when I stepped on her tail.",
            },
            {
              definition:
                "A type of emergency vehicle siren sounding quicker and more intense than the wail.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To utter an abrupt, high-pitched noise.",
              synonyms: [],
              antonyms: [],
              example:
                "The children yelped with delight as they played in the cold water.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/yelp",
        "https://en.wiktionary.org/wiki/yelps",
      ],
    },
  ],
  joker: [
    {
      word: "joker",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/joker-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241301",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈdʒəʊkə/", audio: "" },
        { text: "/ˈdʒoʊkɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A person who makes jokes.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A funny person.", synonyms: [], antonyms: [] },
            {
              definition: "A jester.",
              synonyms: ["court jester", "fool", "jester"],
              antonyms: [],
            },
            {
              definition:
                "A playing card that features a picture of a joker (that is, a jester) and that may be used as a wild card in some card games.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An unspecified, vaguely disreputable person.",
              synonyms: [],
              antonyms: [],
              example: "Some joker keeps throwing eggs at my windows.",
            },
            { definition: "A man.", synonyms: [], antonyms: [] },
            {
              definition:
                "A clause in a contract that undermines its apparent provisions.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["court jester", "fool", "jester"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/joker"],
    },
  ],
  fleck: [
    {
      word: "fleck",
      phonetic: "/flɛk/",
      phonetics: [
        {
          text: "/flɛk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fleck-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89033337",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A flake", synonyms: [], antonyms: [] },
            { definition: "A lock, as of wool.", synonyms: [], antonyms: [] },
            {
              definition: "A small spot or streak; a speckle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To mark with small spots",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fleck"],
    },
  ],
  buffs: [
    {
      word: "buffs",
      phonetic: "/bʌfs/",
      phonetics: [{ text: "/bʌfs/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Undyed leather from the skin of buffalo or similar animals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A tool, often one covered with buff leather, used for polishing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A brownish yellow colour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A military coat made of buff leather.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person who is very interested in a particular subject; an enthusiast.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An effect that makes a character or item stronger.",
              synonyms: [],
              antonyms: [],
              example:
                "I just picked up an epic damage buff! Let's go gank the other team!",
            },
            {
              definition:
                "Compressive coupler force that occurs during a slack bunched condition.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The bare skin.",
              synonyms: [],
              antonyms: [],
              example: "to strip to the buff",
            },
            {
              definition:
                "The greyish viscid substance constituting the buffy coat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A substance used to dilute (street) drugs in order to increase profits.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["aficionado", "revamp"],
          antonyms: ["debuff", "nerf"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To polish and make shiny by rubbing.",
              synonyms: [],
              antonyms: [],
              example: "He was already buffing the car's hubs.",
            },
            {
              definition: "To make a character or an item stronger.",
              synonyms: [],
              antonyms: [],
              example: "I noticed that the pistols were buffed in the update.",
            },
            {
              definition:
                "To modify a medical chart, especially in a dishonest manner.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A buffet; a blow.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To strike.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A buffalo, or the meat of a buffalo.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/buff",
        "https://en.wiktionary.org/wiki/buffs",
      ],
    },
  ],
  scram: [
    {
      word: "scram",
      phonetic: "/skɹæm/",
      phonetics: [{ text: "/skɹæm/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "Leave in a hurry, go away.",
              synonyms: [],
              antonyms: [],
              example: "What are you kids doing on my lawn? Scram!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scram"],
    },
    {
      word: "scram",
      phonetic: "/skɹæm/",
      phonetics: [{ text: "/skɹæm/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The emergency shutdown device of a nuclear reactor, originally specifically by insertion of one or more safety control rods.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An activation of the emergency shutdown of a nuclear reactor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) Any emergency shutdown.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To use the shutdown or safety device of a nuclear reactor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) To use any emergency shutdown.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/SCRAM",
        "https://en.wiktionary.org/wiki/scram",
      ],
    },
    {
      word: "scram",
      phonetic: "/skɹæm/",
      phonetics: [{ text: "/skɹæm/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A scratch, especially caused by claws or fingernails.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "Scratch with claws or fingernails.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scram"],
    },
  ],
  tiers: [
    {
      word: "tiers",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tiers-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=97502877",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One who ties (knots, etc).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Something that ties.", synonyms: [], antonyms: [] },
            { definition: "A child's apron.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A layer or rank, especially of seats or a wedding cake.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To arrange in layers.", synonyms: [], antonyms: [] },
            {
              definition: "To cascade in an overlapping sequence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To move (data) from one storage medium to another as an optimization, based on how frequently it is accessed.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tier",
        "https://en.wiktionary.org/wiki/tiers",
      ],
    },
  ],
  bogey: [
    {
      word: "bogey",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bogey-au.oga",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75268175",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/bəʊɡi/", audio: "" },
        { text: "/boʊɡi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One who robs others in a lawless area, especially as part of a group.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An outlaw.", synonyms: [], antonyms: [] },
            {
              definition: "One who cheats others.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An enemy aircraft.", synonyms: [], antonyms: [] },
            {
              definition:
                "A runner who covertly joins a race without having registered as a participant.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["cheater", "criminal", "fugitive", "outlaw"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An obnoxious, selfish and overbearing person; an attention hog.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(often capitalized, usually with definite article) The Devil.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A ghost, goblin, or other hostile supernatural creature.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A bugbear: any terrifying thing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A standard of performance set up as a mark to be aimed at in competition.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An unidentified aircraft, especially as observed as a spot on a radar screen and suspected to be hostile.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A score of one over par on a hole.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["Devil", "goblin", "railroad truck"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To make a bogey.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A bog-standard (representative) specimen taken from the center of production.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A swim or bathe; a bath.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One of two sets of wheels under a locomotive or railcar; the structure with axles and wheels under a locomotive, railcar, or semi.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(Indian English, dated in UK) A railway carriage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A toy similar to a violin bow, consisting of a wooden stick with notches along one or more sides or edges to produce a rattly noise when stroked against a hard edge, eg, the lip of container.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["goblin", "railroad truck"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A piece of solid or semisolid mucus in or removed from a nostril.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Something suggestive of this material.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A thing; especially a problematic or difficult thing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bogey", "bogie", "snot", "bugger"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(mildly derogatory) A bodyboarder.",
              synonyms: [],
              antonyms: [],
              example: "Watch the local boogers charge it!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bandit",
        "https://en.wiktionary.org/wiki/bogart",
        "https://en.wiktionary.org/wiki/bogey",
        "https://en.wiktionary.org/wiki/bogie",
        "https://en.wiktionary.org/wiki/booger",
      ],
    },
    {
      word: "bogey",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bogey-au.oga",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75268175",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/bəʊɡi/", audio: "" },
        { text: "/boʊɡi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A bog-standard (representative) specimen taken from the center of production.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bogey"],
    },
    {
      word: "bogey",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bogey-au.oga",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75268175",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/bəʊɡi/", audio: "" },
        { text: "/boʊɡi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A swim or bathe; a bath.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To swim; to bathe.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bogey"],
    },
  ],
  doled: [
    {
      word: "doled",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To distribute in small amounts; to share out small portions of a meager resource.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dole",
        "https://en.wiktionary.org/wiki/doled",
      ],
    },
  ],
  irate: [
    {
      word: "irate",
      phonetic: "/aɪˈɹeɪt/",
      phonetics: [{ text: "/aɪˈɹeɪt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Extremely angry; wrathful; enraged.",
              synonyms: ["furious", "infuriated", "sore"],
              antonyms: [],
            },
          ],
          synonyms: ["furious", "infuriated", "sore"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/irate"],
    },
  ],
  vales: [
    {
      word: "vales",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A valley.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["dale"],
          antonyms: ["hill"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/vale",
        "https://en.wiktionary.org/wiki/vales",
      ],
    },
  ],
  coped: [
    {
      word: "coped",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To deal effectively with something, especially if difficult.",
              synonyms: [],
              antonyms: [],
              example:
                "I thought I would never be able to cope with life after the amputation, but I have learned how to be happy again.",
            },
            {
              definition: "To cut and form a mitred joint in wood or metal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To clip the beak or talons of a bird.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["contend", "hold it together"],
          antonyms: ["lose one's shit"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cover (a joint or structure) with coping.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To form a cope or arch; to bend or arch; to bow.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To bargain for; to buy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To exchange or barter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make return for; to requite; to repay.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To match oneself against; to meet; to encounter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To encounter; to meet; to have to do with.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Clad in a cope.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cope",
        "https://en.wiktionary.org/wiki/coped",
      ],
    },
  ],
  hails: [
    {
      word: "hails",
      phonetic: "/heɪlz/",
      phonetics: [{ text: "/heɪlz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "Of hail, to fall from the sky.",
              synonyms: [],
              antonyms: [],
              example: "They say it's going to hail tomorrow.",
            },
            {
              definition: "To send or release hail.",
              synonyms: [],
              antonyms: [],
              example:
                "The cloud would hail down furiously within a few minutes.",
            },
            {
              definition: "To pour down in rapid succession.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To greet; give salutation to; salute.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To name; to designate; to call.",
              synonyms: [],
              antonyms: [],
              example: "He was hailed as a hero.",
            },
            {
              definition:
                "To call out loudly in order to gain the attention of",
              synonyms: [],
              antonyms: [],
              example: "Hail a taxi.",
            },
            {
              definition: "To signal in order to initiate communication with.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hail",
        "https://en.wiktionary.org/wiki/hails",
      ],
    },
  ],
  elude: [
    {
      word: "elude",
      phonetic: "/ɪˈluːd/",
      phonetics: [
        {
          text: "/ɪˈluːd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/elude-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=503106",
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To evade, or escape from someone or something, especially by using cunning or skill",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To shake off a pursuer; to give someone the slip",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To escape understanding of; to be incomprehensible to",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/elude"],
    },
  ],
  bulks: [
    {
      word: "bulks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Size, specifically, volume.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any huge body or structure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The major part of something.",
              synonyms: [],
              antonyms: [],
              example:
                "I understood the bulk of what you were saying, just one of two points I need to hear again.",
            },
            { definition: "Dietary fibre.", synonyms: [], antonyms: [] },
            {
              definition:
                "Unpackaged goods when transported in large volumes, e.g. coal, ore or grain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cargo or any items moved or communicated in the manner of cargo.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Excess body mass, especially muscle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A period where one tries to gain muscle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(brane cosmology) A hypothetical higher-dimensional space within which our own four-dimensional universe may exist.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The body.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To appear or seem to be, as to bulk or extent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To grow in size; to swell or expand.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To gain body mass by means of diet, exercise, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To put or hold in bulk.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To add bulk to, to bulk out.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bulk",
        "https://en.wiktionary.org/wiki/bulks",
      ],
    },
  ],
  aired: [
    {
      word: "aired",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To bring (something) into contact with the air, so as to freshen or dry it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To let fresh air into a room or a building, to ventilate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To discuss varying viewpoints on a given topic.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To broadcast (a television show etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be broadcast.",
              synonyms: [],
              antonyms: [],
              example:
                "This game show first aired in the 1990s and is still going today.",
            },
            { definition: "To ignore.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(of a complaint or problem) Having been uttered or spoken of, such that certain persons are aware.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a show) Having been broadcast, such as on television or radio.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/air",
        "https://en.wiktionary.org/wiki/aired",
      ],
    },
  ],
  vying: [
    {
      word: "vying",
      phonetic: "/ˈvaɪ.ɪŋ/",
      phonetics: [{ text: "/ˈvaɪ.ɪŋ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To fight for superiority; to contend; to compete eagerly so as to gain something.",
              synonyms: [],
              antonyms: [],
              example: "Her suitors were all vying for her attention.",
            },
            {
              definition: "To rival (something), etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To do or produce in emulation, competition, or rivalry; to put in competition; to bandy.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To stake; to wager.", synonyms: [], antonyms: [] },
            {
              definition:
                "To stake a sum of money upon a hand of cards, as in the old game of gleek. See revie.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["battle", "compete", "oppose"],
          antonyms: ["concede", "reconcile"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act of one who vies; rivalry.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/vie",
        "https://en.wiktionary.org/wiki/vying",
      ],
    },
  ],
  stags: [
    {
      word: "stags",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "An adult male deer.", synonyms: [], antonyms: [] },
            { definition: "A colt, or filly.", synonyms: [], antonyms: [] },
            {
              definition: "(by extension) A romping girl; a tomboy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An improperly or late castrated bull or ram – also called a bull seg (see note under ox).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An outside irregular dealer in stocks, who is not a member of the exchange.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One who applies for the allotment of shares in new projects, with a view to sell immediately at a premium, and not to hold the stock.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The Eurasian wren, Troglodytes troglodytes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(usually attributive) An unmarried male, a bachelor; a male not accompanying a female at a social event.",
              synonyms: [],
              antonyms: [],
              example: "a stag dance; a stag party",
            },
            {
              definition:
                "A social event for males held in honor of a groom on the eve of his wedding, attended by male friends of the groom, sometimes a fund-raiser.",
              synonyms: [],
              antonyms: [],
              example: "The stag will be held in the hotel's ballroom.",
            },
            {
              definition: "A stag beetle (family Lucanidae).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Guard duty.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["bachelor party", "stag do", "stag lunch", "stag party"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/stag",
        "https://en.wiktionary.org/wiki/stags",
      ],
    },
  ],
  strew: [
    {
      word: "strew",
      phonetic: "/stɹuː/",
      phonetics: [
        {
          text: "/stɹuː/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/strew-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88681585",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/stɹu/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(archaic except strewn) To distribute objects or pieces of something over an area, especially in a random manner.",
              synonyms: [],
              antonyms: [],
              example: "to strew sand over a floor",
            },
            {
              definition:
                "(archaic except strewn) To cover, or lie upon, by having been scattered.",
              synonyms: [],
              antonyms: [],
              example: "Leaves strewed the ground.",
            },
            {
              definition: "To spread abroad; to disseminate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["scatter", "sprinkle"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/strew"],
    },
  ],
  cocci: [
    {
      word: "cocci",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Any approximately spherical bacterium.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One of the carpels or seed-vessels of a dry fruit.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cocci",
        "https://en.wiktionary.org/wiki/coccus",
      ],
    },
    {
      word: "cocci",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Coccidioidomycosis", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cocci"],
    },
  ],
  pacts: [
    {
      word: "pacts",
      phonetic: "/pækts/",
      phonetics: [{ text: "/pækts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An agreement; a compact; a covenant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An agreement between two or more nations",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To form a pact; to agree formally.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pact",
        "https://en.wiktionary.org/wiki/pacts",
      ],
    },
  ],
  scabs: [
    {
      word: "scabs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An incrustation over a sore, wound, vesicle, or pustule, formed during healing.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The scabies.", synonyms: [], antonyms: [] },
            {
              definition: "The mange, especially when it appears on sheep.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of several different diseases of potatoes producing pits and other damage on their surface, caused by streptomyces bacteria (but formerly believed to be caused by a fungus).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Common scab, a relatively harmless variety of scab (potato disease) caused by Streptomyces scabies.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any one of various more or less destructive fungal diseases that attack cultivated plants, forming dark-colored crustlike spots.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(founding) A slight irregular protuberance which defaces the surface of a casting, caused by the breaking away of a part of the mold.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A mean, dirty, paltry fellow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A worker who acts against trade union policies, especially a strikebreaker.",
              synonyms: ["blackleg", "knobstick", "scalie"],
              antonyms: [],
            },
          ],
          synonyms: ["blackleg", "knobstick", "scalie"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To become covered by a scab or scabs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To form into scabs and be shed, as damaged or diseased skin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To remove part of a surface (from).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To act as a strikebreaker.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To beg (for), to cadge or bum.",
              synonyms: [],
              antonyms: [],
              example: "I scabbed some money off a friend.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/scab",
        "https://en.wiktionary.org/wiki/scabs",
      ],
    },
  ],
  silos: [
    {
      word: "silos",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A vertical building, usually cylindrical, used for the production of silage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "From the shape, a building used for the storage of grain.",
              synonyms: ["grain elevator", "granary"],
              antonyms: [],
            },
            {
              definition:
                "An underground bunker used to hold missiles which may be launched.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An organizational unit that has poor interaction with other units, negatively affecting overall performance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(informatics) A structure in the information system that is poorly networked with other structures, with data exchange hampered.",
              synonyms: [],
              antonyms: [],
              example:
                "Our networking is organized in silos, and employees lose time manually transferring data.",
            },
            {
              definition: "A self-enclosed group of like-minded individuals.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["grain elevator", "granary"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/silo",
        "https://en.wiktionary.org/wiki/silos",
      ],
    },
  ],
  dusts: [
    {
      word: "dusts",
      phonetic: "/dʌsts/",
      phonetics: [{ text: "/dʌsts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Fine particles", synonyms: [], antonyms: [] },
            {
              definition: "The act of cleaning by dusting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The earth, as the resting place of the dead.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The earthy remains of bodies once alive; the remains of the human body.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Something worthless.", synonyms: [], antonyms: [] },
            {
              definition: "A low or mean condition.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Cash; money (in reference to gold dust).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A disturbance or uproar.",
              synonyms: [],
              antonyms: [],
              example: "to raise, or kick up, a dust",
            },
            {
              definition:
                "A totally disconnected set of points with a fractal structure.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To remove dust from.",
              synonyms: [],
              antonyms: [],
              example: "The cleaning lady needs a stool to dust the cupboard.",
            },
            {
              definition: "To remove dust; to clean by removing dust.",
              synonyms: [],
              antonyms: [],
              example: "Dusting always makes me cough.",
            },
            {
              definition:
                "Of a bird, to cover itself in sand or dry, dusty earth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To spray or cover something with fine powder or liquid.",
              synonyms: [],
              antonyms: [],
              example: "The mother dusted her baby's bum with talcum powder.",
            },
            {
              definition: "To leave; to rush off.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To reduce to a fine powder; to levigate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To kill or severely disable.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dust",
        "https://en.wiktionary.org/wiki/dusts",
      ],
    },
  ],
  yodel: [
    {
      word: "yodel",
      phonetic: "/ˈjəʊ.dəl/",
      phonetics: [
        { text: "/ˈjəʊ.dəl/", audio: "" },
        {
          text: "/ˈjoʊ.dəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/yodel-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=6386065",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A song incorporating yodelling.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To sing (a song) in such a way that the voice fluctuates rapidly between the normal chest voice and falsetto.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/yodel"],
    },
  ],
  terse: [
    {
      word: "terse",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/terse-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75858712",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/tɝːs/", audio: "" },
        { text: "/tɜːs/", audio: "" },
        { text: "/tɝːs/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(by extension) Of speech or style: brief, concise, to the point.",
              synonyms: ["concise", "succinct"],
              antonyms: ["prolix", "verbose", "wordy"],
            },
            {
              definition:
                "(by extension) Of manner or speech: abruptly or brusquely short; curt.",
              synonyms: ["abrupt", "brusque", "mardy", "short-spoken"],
              antonyms: [],
            },
            {
              definition: "Burnished, polished; fine, smooth; neat, spruce.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "abrupt",
            "brusque",
            "mardy",
            "short-spoken",
            "concise",
            "succinct",
          ],
          antonyms: ["prolix", "verbose", "wordy"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/terse"],
    },
  ],
  jaded: [
    {
      word: "jaded",
      phonetic: "/ˈdʒeɪdɪd/",
      phonetics: [
        {
          text: "/ˈdʒeɪdɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/jaded-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75803859",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈdʒeɪdɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/jaded-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2833350",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To tire, weary or fatigue",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To treat like a jade; to spurn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make ridiculous and contemptible.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Bored or lacking enthusiasm, typically after having been over exposed to, or having consumed too much of something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Worn out, wearied, exhausted or lacking enthusiasm, due to age or experience.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Made callous or cynically insensitive, by experience.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "blasé",
            "cloyed",
            "glutted",
            "gorged",
            "sated",
            "satiated",
            "surfeited",
            "exhausted",
            "fatigued",
            "wearied",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/jade",
        "https://en.wiktionary.org/wiki/jaded",
      ],
    },
  ],
  baser: [
    {
      word: "baser",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Low in height; short.", synonyms: [], antonyms: [] },
            {
              definition: "Low in place or position.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of low value or degree.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of low social standing or rank; vulgar, common.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Morally reprehensible, immoral; cowardly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Inferior; unworthy, of poor quality.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Designating those metals which are not classed as precious or noble.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Alloyed with inferior metal; debased.",
              synonyms: [],
              antonyms: [],
              example: "base bullion",
            },
            {
              definition: "Of illegitimate birth; bastard.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Not classical or correct.",
              synonyms: [],
              antonyms: [],
              example: "base Latin",
            },
            {
              definition: "Not held by honourable service.",
              synonyms: [],
              antonyms: [],
              example:
                "A base estate is one held by services not honourable, or held by villenage. Such a tenure is called base, or low, and the tenant is a base tenant.",
            },
          ],
          synonyms: [
            "little",
            "petite",
            "short",
            "low-lying",
            "lowland",
            "common",
            "low-born",
            "lowly",
            "plebeian",
            "vulgar",
          ],
          antonyms: ["admirable", "desirable", "likeable", "noble"],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Of sound, a voice or an instrument, low in pitch or frequency.",
              synonyms: [],
              antonyms: [],
              example:
                "The giant spoke in a deep, bass, rumbling voice that shook me to my boots.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/base",
        "https://en.wiktionary.org/wiki/baser",
        "https://en.wiktionary.org/wiki/bass",
      ],
    },
  ],
  jibes: [
    {
      word: "jibes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A facetious or insulting remark; a jeer, a taunt.",
              synonyms: [],
              antonyms: [],
              example:
                "He flung subtle jibes at her until she couldn’t bear to work with him any longer.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To reproach with contemptuous words; to deride, to mock, to taunt.",
              synonyms: ["flout"],
              antonyms: [],
            },
            {
              definition: "To say in a mocking or taunting manner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make a mocking remark or remarks; to jeer.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["flout"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To accord or agree.",
              synonyms: [],
              antonyms: [],
              example: "That explanation doesn’t jibe with the facts.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Alternative spelling of gybe",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "Alternative spelling of gybe",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/jibe",
        "https://en.wiktionary.org/wiki/jibes",
      ],
    },
  ],
  foils: [
    {
      word: "foils",
      phonetic: "/fɔɪlz/",
      phonetics: [{ text: "/fɔɪlz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A very thin sheet of metal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Thin aluminium/aluminum (or, formerly, tin) used for wrapping food.",
              synonyms: [],
              antonyms: [],
              example: "wrap the sandwich up in foil",
            },
            {
              definition:
                "A thin layer of metal put between a jewel and its setting to make it seem more brilliant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(authorship) In literature, theatre/theater, etc., a character who helps emphasize the traits of the main character and who usually acts as an opponent or antagonist.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Anything that acts by contrast to emphasise the characteristics of something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A very thin sword with a blunted (or foiled) tip",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A thin, transparent plastic material on which marks are made and projected for the purposes of presentation. See transparency.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A stylized flower or leaf.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A hydrofoil.", synonyms: [], antonyms: [] },
            { definition: "An aerofoil/airfoil.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "aluminium foil",
            "silver foil",
            "silver paper",
            "tin foil",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cover or wrap with foil.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Failure when on the point of attainment; defeat; frustration; miscarriage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the incorrect answers presented in a multiple-choice test.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To prevent (something) from being accomplished.",
              synonyms: [],
              antonyms: [],
              example: "I was foiled by my superior",
            },
            {
              definition: "To prevent (someone) from accomplishing something.",
              synonyms: [],
              antonyms: [],
              example: "They foiled my plans.",
            },
            {
              definition: "To blunt; to dull; to spoil.",
              synonyms: [],
              antonyms: [],
              example: "to foil the scent in hunting",
            },
            {
              definition: "To tread underfoot; to trample.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["put the kibosh on", "scupper", "thwart"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The track of an animal.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["spoor"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To expand a product of two or more algebraic expressions, typically binomials.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To defile; to soil.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/foil",
        "https://en.wiktionary.org/wiki/foils",
      ],
    },
  ],
  sways: [
    {
      word: "sways",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The act of swaying; a swaying motion; a swing or sweep of a weapon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A rocking or swinging motion.",
              synonyms: [],
              antonyms: [],
              example:
                "The old song caused a little sway in everyone in the room.",
            },
            {
              definition:
                "Influence, weight, or authority that inclines to one side",
              synonyms: [],
              antonyms: [],
              example: "I doubt I'll hold much sway with someone so powerful.",
            },
            {
              definition: "Preponderance; turn or cast of balance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Rule; dominion; control; power.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A switch or rod used by thatchers to bind their work.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The maximum amplitude of a vehicle's lateral motion.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To move or swing from side to side; or backward and forward; to rock.",
              synonyms: [],
              antonyms: [],
              example: "sway to the music;  The trees swayed in the breeze.",
            },
            {
              definition: "To move or wield with the hand; to swing; to wield.",
              synonyms: [],
              antonyms: [],
              example: "to sway the sceptre",
            },
            {
              definition:
                "To influence or direct by power, authority, persuasion, or by moral force; to rule; to govern; to guide. Compare persuade.",
              synonyms: [],
              antonyms: [],
              example: "Do you think you can sway their decision?",
            },
            {
              definition:
                "To cause to incline or swing to one side, or backward and forward; to bias; to turn; to bend; warp.",
              synonyms: [],
              antonyms: [],
              example: "reeds swayed by the wind;  judgment swayed by passion",
            },
            {
              definition: "To hoist (a mast or yard) into position.",
              synonyms: [],
              antonyms: [],
              example: "to sway up the yards",
            },
            {
              definition:
                "To be drawn to one side by weight or influence; to lean; to incline.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To have weight or influence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To bear sway; to rule; to govern.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sway",
        "https://en.wiktionary.org/wiki/sways",
      ],
    },
  ],
  forgo: [
    {
      word: "forgo",
      phonetic: "/fɔːˈɡəʊ/",
      phonetics: [
        { text: "/fɔːˈɡəʊ/", audio: "" },
        { text: "/fɔɹˈɡoʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To let pass, to leave alone, to let go.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To do without, to abandon, to renounce.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To refrain from, to abstain from, to pass up, to withgo.",
              synonyms: [],
              antonyms: [],
              example:
                "I wouldn't forgo something, unless I'm convinced that it's detrimental in some way or another.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/forgo"],
    },
  ],
  slays: [
    {
      word: "slays",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To kill, murder.",
              synonyms: [],
              antonyms: [],
              example: "Our foes must all be slain.",
            },
            {
              definition: "To eradicate or stamp out.",
              synonyms: [],
              antonyms: [],
              example: "You must slay these thoughts.",
            },
            {
              definition:
                "(by extension) To defeat, overcome (in a competition or contest).",
              synonyms: [],
              antonyms: [],
              example:
                "1956, “Giants Slay Bears in Pro Title Battle”, in Lodi News-Sentinel, 1956 December 31, page 8.",
            },
            {
              definition: "To delight or overwhelm, especially with laughter.",
              synonyms: [],
              antonyms: [],
              example: "Ha ha! You slay me!",
            },
            {
              definition:
                "To amaze, stun or otherwise incapacitate by awesomeness; to be awesome at something; to kill (slang sense).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To have sex with", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "coitize",
            "go to bed with",
            "sleep with",
            "conquer",
            "defeat",
            "overcome",
            "assassinate",
            "kill",
            "murder",
            "hit it out of the park",
            "kill",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/slay",
        "https://en.wiktionary.org/wiki/slays",
      ],
    },
  ],
  preys: [
    {
      word: "preys",
      phonetic: "/pɹeɪz/",
      phonetics: [{ text: "/pɹeɪz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To act as a predator.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/prey",
        "https://en.wiktionary.org/wiki/preys",
      ],
    },
  ],
  treks: [
    {
      word: "treks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A journey by ox wagon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The Boer migration of 1835-1837.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A slow or difficult journey.",
              synonyms: [],
              antonyms: [],
              example: "We're planning a trek up Kilimanjaro.",
            },
            {
              definition: "A long walk.",
              synonyms: ["slog"],
              antonyms: [],
              example:
                "I would drive to the shops from here; you can walk, but it's quite a trek.",
            },
          ],
          synonyms: ["slog"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make a slow or arduous journey.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To journey on foot, especially to hike through mountainous areas.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To travel by ox wagon.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/trek",
        "https://en.wiktionary.org/wiki/treks",
      ],
    },
  ],
  quell: [
    {
      word: "quell",
      phonetic: "/kwɛl/",
      phonetics: [
        {
          text: "/kwɛl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/quell-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75857522",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/kwɛl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/quell-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=6861376",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A subduing.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To subdue, to put down; to silence or force (someone) to submit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To suppress, to put an end to (something); to extinguish.",
              synonyms: [],
              antonyms: [],
              example: "to quell grief",
            },
            { definition: "To kill.", synonyms: [], antonyms: [] },
            {
              definition: "To be subdued or abated; to diminish.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To die.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/quell"],
    },
    {
      word: "quell",
      phonetic: "/kwɛl/",
      phonetics: [
        {
          text: "/kwɛl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/quell-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75857522",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/kwɛl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/quell-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=6861376",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A source, especially a spring.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An emotion or sensation which rises suddenly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/quell"],
    },
  ],
  peeks: [
    {
      word: "peeks",
      phonetic: "/piːks/",
      phonetics: [{ text: "/piːks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To look slyly, or with the eyes half closed, or through a crevice; to peep.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be only slightly, partially visible, as if peering out from a hiding place.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To retrieve (a value) from a memory address.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/peek",
        "https://en.wiktionary.org/wiki/peeks",
      ],
    },
  ],
  assay: [
    {
      word: "assay",
      phonetic: "/əˈseɪ/",
      phonetics: [
        {
          text: "/əˈseɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/assay-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88987525",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Trial, attempt.", synonyms: [], antonyms: [] },
            {
              definition: "Examination and determination; test.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The qualitative or quantitative chemical analysis of something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Trial by danger or by affliction; adventure; risk; hardship; state of being tried.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Tested purity or value.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The act or process of ascertaining the proportion of a particular metal in an ore or alloy; especially, the determination of the proportion of gold or silver in bullion or coin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The alloy or metal to be assayed.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To attempt (something).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To try, attempt (to do something).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To analyze or estimate the composition or value of (a metal, ore etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To test the abilities of (someone) in combat; to fight.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To affect.", synonyms: [], antonyms: [] },
            {
              definition: "To try tasting, as food or drink.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/assay"],
    },
  ],
  lurks: [
    {
      word: "lurks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To remain concealed in order to ambush.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To remain unobserved.", synonyms: [], antonyms: [] },
            {
              definition:
                "To hang out or wait around a location, preferably without drawing attention to oneself.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To view an internet forum without posting comments.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To saddle (a person) with an undesirable task or duty.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lurk",
        "https://en.wiktionary.org/wiki/lurks",
      ],
    },
  ],
  eject: [
    {
      word: "eject",
      phonetic: "/ɪˈdʒɛkt/",
      phonetics: [
        {
          text: "/ɪˈdʒɛkt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/eject-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50010926",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To compel (a person or persons) to leave.",
              synonyms: [],
              antonyms: [],
              example:
                "Andrew was ejected from his apartment for not paying the rent.",
            },
            {
              definition: "To throw out or remove forcefully.",
              synonyms: [],
              antonyms: [],
              example:
                "In other news, a Montreal man was ejected from his car when he was involved in an accident.",
            },
            {
              definition:
                "To compel (a sports player) to leave the field because of inappropriate behaviour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To project oneself from an aircraft.",
              synonyms: [],
              antonyms: [],
              example: "The pilot lost control of the plane and had to eject.",
            },
            {
              definition: "To cause (something) to come out of a machine.",
              synonyms: [],
              antonyms: [],
              example: "Press that button to eject the video tape.",
            },
            {
              definition: "To come out of a machine.",
              synonyms: [],
              antonyms: [],
              example: "I can't get this cassette to  eject.",
            },
          ],
          synonyms: [
            "remove",
            "come out",
            "kick out",
            "send off",
            "toss",
            "boot out",
            "discharge",
            "dismiss",
            "drive out",
            "evict",
            "expel",
            "kick out",
            "oust",
            "toss",
            "turf out",
            "bail out",
            "throw out",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/eject"],
    },
    {
      word: "eject",
      phonetic: "/ˈiː.dʒɛkt/",
      phonetics: [
        {
          text: "/ˈiː.dʒɛkt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/eject-2-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89261023",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An inferred object of someone else's consciousness",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/eject"],
    },
  ],
  boars: [
    {
      word: "boars",
      phonetic: "/bɔːz/",
      phonetics: [
        { text: "/bɔːz/", audio: "" },
        { text: "/bɔɹz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A wild boar (Sus scrofa), the wild ancestor of the domesticated pig.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A male pig.", synonyms: [], antonyms: [] },
            {
              definition: "A male boar (sense 1).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A male bear.", synonyms: [], antonyms: [] },
            { definition: "A male guinea pig.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/boar",
        "https://en.wiktionary.org/wiki/boars",
      ],
    },
  ],
  trite: [
    {
      word: "trite",
      phonetic: "/tɹaɪt/",
      phonetics: [
        {
          text: "/tɹaɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/trite-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75858942",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/tɹaɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/trite-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762793",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Often in reference to a word or phrase: used so many times that it is commonplace, or no longer interesting or effective; worn out, hackneyed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "So well established as to be beyond debate: trite law.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/trite"],
    },
    {
      word: "trite",
      phonetic: "/tɹaɪt/",
      phonetics: [
        {
          text: "/tɹaɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/trite-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75858942",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/tɹaɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/trite-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762793",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A denomination of coinage in ancient Greece equivalent to one third of a stater.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Trite, a genus of spiders, found in Australia, New Zealand and Oceania, of the family Salticidae.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/trite"],
    },
  ],
  belch: [
    {
      word: "belch",
      phonetic: "/ˈbɛltʃ/",
      phonetics: [
        {
          text: "/ˈbɛltʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/belch-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90572954",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The sound one makes when belching.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Malt liquor.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To expel (gas) loudly from the stomach through the mouth.",
              synonyms: [],
              antonyms: [],
              example: "My father used to belch after having a fine meal.",
            },
            {
              definition:
                "To eject or emit (something) with spasmodic force or noise.",
              synonyms: [],
              antonyms: [],
              example:
                "Yes, we have seen the wrecked cars and the factories belching smoke and the blur of speedy automobiles crowding highways.",
            },
            {
              definition:
                "To be ejected or emitted (from something) with spasmodic force or noise.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["burp"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/belch"],
    },
  ],
  gnash: [
    {
      word: "gnash",
      phonetic: "/næʃ/",
      phonetics: [
        {
          text: "/næʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gnash-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1772909",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A sudden snapping of the teeth.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To grind (one's teeth) in pain or in anger.",
              synonyms: [],
              antonyms: [],
              example: "gnashing your teeth",
            },
            {
              definition: "To grind between the teeth.",
              synonyms: [],
              antonyms: [],
              example: "The dog was gnashing a carpet",
            },
            { definition: "To run away.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gnash"],
    },
  ],
  wanes: [
    {
      word: "wanes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A gradual diminution in power, value, intensity etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The lunar phase during which the sun seems to illuminate less of the moon as its sunlit area becomes progressively smaller as visible from Earth.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The end of a period.", synonyms: [], antonyms: [] },
            {
              definition:
                "A rounded corner caused by lack of wood, often showing bark.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["decline", "decrease"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To progressively lose its splendor, value, ardor, power, intensity etc.; to decline.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Said of light that dims or diminishes in strength.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Said of the Moon as it passes through the phases of its monthly cycle where its surface is less and less visible.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Said of a time period that comes to an end.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To decrease physically in size, amount, numbers or surface.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To cause to decrease.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: ["wax"],
        },
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "A child.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A house or dwelling.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wane",
        "https://en.wiktionary.org/wiki/wanes",
      ],
    },
  ],
  lutes: [
    {
      word: "lutes",
      phonetic: "/l(j)uːts/",
      phonetics: [
        {
          text: "/l(j)uːts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lutes-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80949493",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A fretted stringed instrument of European origin, similar to the guitar, having a bowl-shaped body or soundbox; any of a wide variety of chordophones with a pear-shaped body and a neck whose upper surface is in the same plane as the soundboard, with strings along the neck and parallel to the soundboard.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To play on a lute, or as if on a lute.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Thick sticky clay or cement used to close up a hole or gap, especially to make something air-tight.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A packing ring, as of rubber, for fruit jars, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(brickmaking) A straight-edged piece of wood for striking off superfluous clay from earth.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To fix or fasten something with lute.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lute",
        "https://en.wiktionary.org/wiki/lutes",
      ],
    },
  ],
  whims: [
    {
      word: "whims",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A fanciful impulse, or whimsical idea",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A large capstan or vertical drum turned by horse power or steam power, for raising ore or water, etc., from mines, or for other purposes",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["lark", "on a whim"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A bird, the Eurasian wigeon.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/whim",
        "https://en.wiktionary.org/wiki/whims",
      ],
    },
  ],
  dosed: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  chewy: [
    {
      word: "chewy",
      phonetic: "/ˈt͡ʃuː.i/",
      phonetics: [
        {
          text: "/ˈt͡ʃuː.i/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chewy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=181079",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Chewing gum.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having a pliable or springy texture when chewed.",
              synonyms: [],
              antonyms: [],
              example: "I must have spent an hour gnawing on the chewy taffy.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/chewie",
        "https://en.wiktionary.org/wiki/chewy",
      ],
    },
  ],
  snipe: [
    {
      word: "snipe",
      phonetic: "/snaɪp/",
      phonetics: [
        {
          text: "/snaɪp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/snipe-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241771",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/snaɪp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/snipe-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80067035",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of various limicoline game birds of the genera Gallinago, Lymnocryptes and Coenocorypha in the family Scolopacidae, having a long, slender, nearly straight beak.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A fool; a blockhead.", synonyms: [], antonyms: [] },
            {
              definition: "A shot fired from a concealed place.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(naval slang) A member of the engineering department on a ship.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(ice hockey slang) A goal.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To hunt snipe.", synonyms: [], antonyms: [] },
            {
              definition: "To shoot at individuals from a concealed place.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) To shoot with a sniper rifle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To watch a timed online auction and place a winning bid against (the current high bidder) at the last possible moment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To nose (a log) to make it drag or slip easily in skidding.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(ice hockey slang) To score a goal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To move the ball quickly in a different direction.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/snipe"],
    },
    {
      word: "snipe",
      phonetic: "/snaɪp/",
      phonetics: [
        {
          text: "/snaɪp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/snipe-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241771",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/snaɪp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/snipe-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80067035",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A cigarette butt.", synonyms: [], antonyms: [] },
            {
              definition:
                "An animated promotional logo during a television show.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A strip of copy announcing some late breaking news or item of interest, typically placed in a print advertisement in such a way that it stands out from the ad.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bottle of wine measuring 0.1875 liters, one fourth the volume of a standard bottle; a quarter bottle or piccolo.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/snipe"],
    },
    {
      word: "snipe",
      phonetic: "/snaɪp/",
      phonetics: [
        {
          text: "/snaɪp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/snipe-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241771",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/snaɪp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/snipe-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80067035",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A sharp, clever answer; sarcasm.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A note or sticker attached to an existing poster to provide further information (e.g. an event is sold out), political criticism, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make malicious, underhand remarks or attacks.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To attach a note or sticker to (an existing poster) to provide further information, political criticism, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/snipe"],
    },
  ],
  umbra: [
    {
      word: "umbra",
      phonetic: "/ˈʌmbɹə/",
      phonetics: [{ text: "/ˈʌmbɹə/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The fully shaded inner region of a shadow cast by an opaque object.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The central region of a sunspot.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A shadow.", synonyms: [], antonyms: [] },
            {
              definition:
                "An uninvited guest brought along by one who was invited.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One of the family Umbridae of mudminnows.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sciaenoid fish, the umbrine.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/umbra"],
    },
  ],
  teems: [
    {
      word: "teems",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To be stocked to overflowing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be prolific; to abound; to be rife.",
              synonyms: [],
              antonyms: [],
              example: "Fish teem in this pond.",
            },
            {
              definition:
                "To bring forth young, as an animal; to produce fruit, as a plant; to bear; to be pregnant; to conceive; to multiply.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To empty.", synonyms: [], antonyms: [] },
            {
              definition: "To pour (especially with rain)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To pour, as steel, from a melting pot; to fill, as a mould, with molten metal.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To think fit.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/teem",
        "https://en.wiktionary.org/wiki/teems",
      ],
    },
  ],
  dozes: [
    {
      word: "dozes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A light, short sleep or nap.",
              synonyms: [],
              antonyms: [],
              example: "I felt much better after a short doze.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To sleep lightly or briefly; to nap, snooze.",
              synonyms: [],
              antonyms: [],
              example:
                "I didn’t sleep very well, but I think I may have dozed a bit.",
            },
            {
              definition: "To make dull; to stupefy.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To bulldoze.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["slumber"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/doze",
        "https://en.wiktionary.org/wiki/dozes",
      ],
    },
  ],
  kelps: [
    {
      word: "kelps",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of several large brown seaweeds (order Laminariales).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The calcined ashes of seaweed, formerly used in glass and iodine manufacture.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/kelp",
        "https://en.wiktionary.org/wiki/kelps",
      ],
    },
  ],
  upped: [
    {
      word: "upped",
      phonetic: "/ʌpt/",
      phonetics: [{ text: "/ʌpt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To increase or raise.",
              synonyms: ["turn up"],
              antonyms: [],
              example:
                "If we up the volume, we may be able to hear what he says.",
            },
            {
              definition: "To promote.",
              synonyms: [],
              antonyms: [],
              example:
                "It wasn’t long before they upped him to Vice President.",
            },
            {
              definition:
                "(usually in combination with another verb) To act suddenly.",
              synonyms: [],
              antonyms: [],
              example: "He just upped and quit.",
            },
            {
              definition: "To ascend; to climb up.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To upload.",
              synonyms: [],
              antonyms: [],
              example: "100 new apps and games have just been upped.",
            },
          ],
          synonyms: ["turn up"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/up",
        "https://en.wiktionary.org/wiki/upped",
      ],
    },
  ],
  brawn: [
    {
      word: "brawn",
      phonetic: "/bɹɔːn/",
      phonetics: [
        {
          text: "/bɹɔːn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/brawn-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75729047",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/bɹɔːn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/brawn-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=36785780",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Strong muscles or lean flesh, especially of the arm, leg or thumb.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Physical strength; muscularity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Head cheese; a terrine made from the head of a pig or calf; originally boar's meat.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A boar.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "Make fat, especially of a boar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Become fat, especially of a boar.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/brawn"],
    },
  ],
  doped: [
    {
      word: "doped",
      phonetic: "/dəʊpt/",
      phonetics: [
        { text: "/dəʊpt/", audio: "" },
        { text: "/doʊpt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To affect with drugs.", synonyms: [], antonyms: [] },
            {
              definition: "To treat with dope (lubricant, etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To add a dopant such as arsenic to (a pure semiconductor such as silicon).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To use drugs; especially, to use prohibited performance-enhancing drugs in sporting competitions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To judge or guess; to predict the result of.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Drugged.",
              synonyms: [],
              antonyms: [],
              example:
                "He was so doped after the surgery that it took him 2 hours to remember his name.",
            },
            {
              definition:
                "Describing a semiconductor that has had small amounts of elements added to create charge carriers.",
              synonyms: [],
              antonyms: [],
              example:
                "The silicon was doped with boron to make a p-type semiconductor.",
            },
            {
              definition:
                "Covered with dope, a glue or paint like pore filler.",
              synonyms: [],
              antonyms: [],
              example:
                "The doped fabric covering the Hindenburg was very flammable.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dope",
        "https://en.wiktionary.org/wiki/doped",
      ],
    },
  ],
  shush: [
    {
      word: "shush",
      phonetic: "/ʃʊʃ/",
      phonetics: [
        {
          text: "/ʃʊʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shush-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9818730",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To be quiet; to keep quiet.",
              synonyms: [],
              antonyms: [],
              example: "He wouldn't shush so I kicked him.",
            },
            {
              definition:
                "To ask someone to be quiet, especially by saying shh.",
              synonyms: [],
              antonyms: [],
              example:
                "The boy in front of us was making too much noise, so we shushed him.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shush"],
    },
  ],
  rinds: [
    {
      word: "rinds",
      phonetic: "/ɹaɪndz/",
      phonetics: [
        { text: "/ɹaɪndz/", audio: "" },
        {
          text: "/ɹʌɪndz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rinds-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=31972262",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
        {
          text: "/ɹaɪndz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rinds-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=33137455",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Tree bark", synonyms: [], antonyms: [] },
            {
              definition:
                "A hard, tough outer layer, particularly on food such as fruit, cheese, etc",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                '(usually "the") The gall, the crust, the insolence; often as "the immortal rind"',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To remove the rind from.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An iron support fitting used on the upper millstone of a grist mill.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rind",
        "https://en.wiktionary.org/wiki/rinds",
      ],
    },
  ],
  slush: [
    {
      word: "slush",
      phonetic: "/slʌʃ/",
      phonetics: [{ text: "/slʌʃ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Half-melted snow or ice.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Liquid mud or mire.", synonyms: [], antonyms: [] },
            {
              definition: "Flavored shaved ice served as a drink.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A soft mixture of grease and other materials, used for lubrication.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The refuse grease and fat collected in cooking, especially on shipboard.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A mixture of white lead and lime, used as a paint to prevent oxidation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Unsolicited manuscripts, as in slush pile.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "Slurpee",
            "Slush Puppie",
            "slurpee",
            "slush puppy",
            "slushie",
            "slushy",
            "slush ice",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To smear with slushy liquid or grease.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To slosh or splash; to move as, or through, a slushy or liquid substance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To paint with a mixture of white lead and lime.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/slush"],
    },
  ],
  moron: [
    {
      word: "moron",
      phonetic: "/ˈmɔːɹɒn/",
      phonetics: [
        {
          text: "/ˈmɔːɹɒn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/moron-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88680915",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A stupid person; an idiot; a fool.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person of mild mental subnormality in the former classification of mental retardation, having an intelligence quotient of 50–70.",
              synonyms: ["feeble-minded"],
              antonyms: [],
            },
          ],
          synonyms: ["feeble-minded", "fool", "idiot"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/moron"],
    },
  ],
  voile: [
    {
      word: "voile",
      phonetic: "/vɔɪl/",
      phonetics: [{ text: "/vɔɪl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A light, translucent cotton fabric used for making curtains and dresses.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/voile"],
    },
  ],
  woken: [
    {
      word: "woken",
      phonetic: "/ˈwəʊkən/",
      phonetics: [
        { text: "/ˈwəʊkən/", audio: "" },
        { text: "/ˈwoʊkən/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(often followed by up) To stop sleeping.",
              synonyms: [],
              antonyms: [],
              example: "I woke up at four o'clock this morning.",
            },
            {
              definition:
                "(often followed by up) To make somebody stop sleeping; to rouse from sleep.",
              synonyms: [],
              antonyms: [],
              example:
                "The neighbour's car alarm woke me from a strange dream.",
            },
            {
              definition: "To put in motion or action; to arouse; to excite.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be excited or roused up; to be stirred up from a dormant, torpid, or inactive state; to be active.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To lay out a body prior to burial in order to allow family and friends to pay their last respects.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To watch, or sit up with, at night, as a dead body.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be or remain awake; not to sleep.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be alert; to keep watch",
              synonyms: [],
              antonyms: [],
              example: "Command unto the guards that they diligently wake.",
            },
            {
              definition:
                "To sit up late for festive purposes; to hold a night revel.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wake",
        "https://en.wiktionary.org/wiki/woken",
      ],
    },
  ],
  fjord: [
    {
      word: "fjord",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fjord-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75796815",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/fiˈɔːd/", audio: "" },
        {
          text: "/fiˈɔɹd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fjord-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780082",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A long, narrow, deep inlet between cliffs.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fjord"],
    },
  ],
  sheik: [
    {
      word: "sheik",
      phonetic: "/ʃeɪk/",
      phonetics: [
        {
          text: "/ʃeɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sheik.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=7467968",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The leader of an Arab village, family or small tribe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An Islamic religious cleric; the leader of an Islamic religious order.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(some Arab Gulf countries) An official title for members of the royal family as well as some prominent families.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(1920s) A romantic lover. (from the 1921 film The Sheik)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An Arab, especially one dressed in traditional clothing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An honorific for specialists in spirituality.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sheik"],
    },
  ],
  jests: [
    {
      word: "jests",
      phonetic: "/dʒɛsts/",
      phonetics: [{ text: "/dʒɛsts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An act performed for amusement; a joke.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Someone or something that is ridiculed; the target of a joke.",
              synonyms: [],
              antonyms: [],
              example:
                "Your majesty, stop him before he makes you the jest of the court.",
            },
            {
              definition: "A deed; an action; a gest.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A mask; a pageant; an interlude.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "banter",
            "crack",
            "gag",
            "laughingstock",
            "prank",
            "wisecrack",
            "witticism",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To tell a joke; to talk in a playful manner; to make fun of something or someone.",
              synonyms: [],
              antonyms: [],
              example: "Surely you jest!",
            },
          ],
          synonyms: ["banter", "kid", "mock", "tease"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/jest",
        "https://en.wiktionary.org/wiki/jests",
      ],
    },
  ],
  kayak: [
    {
      word: "kayak",
      phonetic: "/ˈkaɪˌæk/",
      phonetics: [
        { text: "/ˈkaɪˌæk/", audio: "" },
        {
          text: "/ˈkaɪˌæk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/kayak-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=500162",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A type of small boat, covered over by a surface deck, powered by the occupant or occupants using a double-bladed paddle in a sitting position, from a hole in the surface deck",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To use a kayak, to travel or race in a kayak.",
              synonyms: [],
              antonyms: [],
              example: "Kayaking is an Olympic sport.",
            },
            {
              definition: "To traverse (a body of water) by kayak.",
              synonyms: [],
              antonyms: [],
              example:
                "On a dare, he kayaked the Harlem River in New York from Hell's Gate to Spyten Duyvil.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/kayak"],
    },
  ],
  slews: [
    {
      word: "slews",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To rotate or turn something about its axis.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To veer a vehicle.", synonyms: [], antonyms: [] },
            {
              definition:
                "To insert extra ticks or skip some ticks of a clock to slowly correct its time.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To pivot.", synonyms: [], antonyms: [] },
            { definition: "To skid.", synonyms: [], antonyms: [] },
            {
              definition: "To move something (usually a railway line) sideways",
              synonyms: [],
              antonyms: [],
              example:
                "The single line was slewed onto the disused up formation to make way for the future redoubling.",
            },
            {
              definition:
                "To make a public mockery of someone through insult or wit.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/slew",
        "https://en.wiktionary.org/wiki/slews",
      ],
    },
  ],
  toted: [
    {
      word: "toted",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To carry or bear.",
              synonyms: [],
              antonyms: [],
              example: "to tote a child over a stream",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To add up; to calculate a total.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tote",
        "https://en.wiktionary.org/wiki/toted",
      ],
    },
  ],
  saner: [
    {
      word: "saner",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Being in a healthy condition; not deranged; thinking rationally.",
              synonyms: [],
              antonyms: [],
              example: "a sane mind",
            },
            {
              definition:
                "Mentally sound; possessing a rational mind; having the mental faculties in such condition as to be able to anticipate and judge the effect of one's actions in an ordinary manner.",
              synonyms: [],
              antonyms: [],
              example: "a sane person",
            },
            {
              definition: "Rational; reasonable; sensible.",
              synonyms: [],
              antonyms: [],
              example: "Try to go to bed at a sane time before your exams.",
            },
          ],
          synonyms: ["in one's right mind", "play with a full deck"],
          antonyms: ["crazy", "insane", "unbalanced"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sane",
        "https://en.wiktionary.org/wiki/saner",
      ],
    },
  ],
  drape: [
    {
      word: "drape",
      phonetic: "/dɹeɪp/",
      phonetics: [
        {
          text: "/dɹeɪp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/drape-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88676255",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A curtain; a drapery.", synonyms: [], antonyms: [] },
            {
              definition: "The way in which fabric falls or hangs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A member of a youth subculture distinguished by its sharp dress, especially peg-leg pants (1950s: e.g. Baltimore, MD). Antonym: square",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A dress made from an entire piece of cloth, without having pieces cut away as in a fitted garment.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cover or adorn with drapery or folds of cloth, or as with drapery",
              synonyms: [],
              antonyms: [],
              example: "to drape a bust, a building, etc.",
            },
            {
              definition: "To spread over, cover.",
              synonyms: [],
              antonyms: [],
              example: "I draped my towel over the radiator to dry.",
            },
            {
              definition: "To rail at; to banter.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To make cloth.", synonyms: [], antonyms: [] },
            {
              definition:
                "To design drapery, arrange its folds, etc., as for hangings, costumes, statues, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To hang or rest limply",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/drape"],
    },
  ],
  patty: [
    {
      word: "patty",
      phonetic: "/ˈpæti/",
      phonetics: [{ text: "/ˈpæti/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(US, Australia, New Zealand) A flattened portion of ground meat or a vegetarian equivalent, usually round but sometimes square in shape.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A pastry with various fillings and spices baked inside a flaky shell, often tinted golden yellow with an egg yolk mixture or turmeric.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/patty"],
    },
  ],
  raves: [
    {
      word: "raves",
      phonetic: "/ɹeɪvz/",
      phonetics: [{ text: "/ɹeɪvz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An enthusiastic review (such as of a play).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An all-night dance party with electronic dance music (techno, trance, drum and bass etc.) and possibly drug use.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The genres of electronic dance music usually associated with rave parties.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To wander in mind or intellect; to be delirious; to talk or act irrationally; to be wild, furious, or raging.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To speak or write wildly or incoherently.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To talk with unreasonable enthusiasm or excessive passion or excitement; followed by about, of, or (formerly) on.",
              synonyms: [],
              antonyms: [],
              example: "He raved about her beauty.",
            },
            {
              definition: "To rush wildly or furiously.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To attend a rave (dance party).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One of the upper side pieces of the frame of a wagon body or a sleigh.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rave",
        "https://en.wiktionary.org/wiki/raves",
      ],
    },
  ],
  sulfa: [
    {
      word: "sulfa",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A sulfonamide or sulfanilamide.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of or containing sulfonamide or sulfanilamide.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sulfa"],
    },
  ],
  grist: [
    {
      word: "grist",
      phonetic: "/ɡɹɪst/",
      phonetics: [{ text: "/ɡɹɪst/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Grain that is to be ground in a mill.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A group of bees.", synonyms: [], antonyms: [] },
            { definition: "Supply; provision.", synonyms: [], antonyms: [] },
            {
              definition:
                "(ropemaking) A given size of rope, common grist being a rope three inches in circumference, with twenty yarns in each of the three strands.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/grist"],
    },
  ],
  skied: [
    {
      word: "skied",
      phonetic: "/skiːd/",
      phonetics: [
        {
          text: "/skiːd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/skied-1-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88743050",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To move on skis", synonyms: [], antonyms: [] },
            {
              definition:
                "To travel over (a slope etc.) on skis; to travel on skis at (a place), (especially as a sport)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ski",
        "https://en.wiktionary.org/wiki/skied",
      ],
    },
    {
      word: "skied",
      phonetic: "/skaɪd/",
      phonetics: [
        {
          text: "/skaɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/skied-2-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88743051",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To hit, kick or throw (a ball) extremely high.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To clear (a hurdle, high jump bar, etc.) by a large margin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To hang (a picture on exhibition) near the top of a wall, where it cannot be well seen.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To drink something from a container without one's lips touching the container.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/skied",
        "https://en.wiktionary.org/wiki/sky",
      ],
    },
  ],
  vixen: [
    {
      word: "vixen",
      phonetic: "/ˈvɪk.sən/",
      phonetics: [
        {
          text: "/ˈvɪk.sən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/vixen-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1234850",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A female fox.", synonyms: [], antonyms: [] },
            {
              definition: "A malicious, quarrelsome or temperamental woman.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A racy or salacious woman.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A wife who has sex with other men with her husband's consent.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/vixen"],
    },
  ],
  civet: [
    {
      word: "civet",
      phonetic: "/ˈsɪ.vɪt/",
      phonetics: [{ text: "/ˈsɪ.vɪt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A carnivorous catlike animal, Civettictis civetta, that produces a musky secretion. It is two to three feet long, with black bands and spots on the body and tail.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The musky perfume produced by the animal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any animal in the family Viverridae or the similar family Nandiniidae",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of several species of spotted skunk, in the genus Spilogale.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/civet"],
    },
  ],
  vouch: [
    {
      word: "vouch",
      phonetic: "/ˈvaʊtʃ/",
      phonetics: [
        {
          text: "/ˈvaʊtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/vouch-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88988014",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Warrant; attestation.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To take responsibility for; to express confidence in; to witness; to obtest.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To warrant; to maintain by affirmations",
              synonyms: ["affirm", "attest", "avouch"],
              antonyms: [],
              example: "I can vouch that the match took place.",
            },
            {
              definition: "To back; to support; to confirm.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To call into court to warrant and defend, or to make good a warranty of title.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To call; to summon.", synonyms: [], antonyms: [] },
            {
              definition:
                "To bear witness; to give testimony or full attestation.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To call as a witness.", synonyms: [], antonyms: [] },
            {
              definition: "To assert; to aver; to declare.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["affirm", "attest", "avouch"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/vouch"],
    },
  ],
  tiara: [
    {
      word: "tiara",
      phonetic: "/tiˈɑːɹə/",
      phonetics: [{ text: "/tiˈɑːɹə/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The papal crown.", synonyms: [], antonyms: [] },
            {
              definition: "An ornamental coronet.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tiara"],
    },
  ],
  homey: [
    {
      word: "homey",
      phonetic: "/ˈhoʊmi/",
      phonetics: [{ text: "/ˈhoʊmi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Someone, particularly a friend or male acquaintance, from one's hometown.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A close friend or fellow member of a youth gang.",
              synonyms: [],
              antonyms: [],
              example: "Hey there, Francis, my homie!",
            },
            { definition: "An inner-city youth.", synonyms: [], antonyms: [] },
            {
              definition:
                'A boyfriend or partner of a closeted man, used along with "Lil," to mean a bottom and "Big," to mean a top.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["homeboy", "nigga"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Man, bloke", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/homey",
        "https://en.wiktionary.org/wiki/homie",
        "https://en.wiktionary.org/wiki/omi",
      ],
    },
    {
      word: "homey",
      phonetic: "/ˈhoʊmi/",
      phonetics: [{ text: "/ˈhoʊmi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Befitting a home; cozy, intimate.",
              synonyms: [],
              antonyms: [],
              example: "Susan added some homey touches to her office.",
            },
          ],
          synonyms: ["homely"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/homey"],
    },
  ],
  moped: [
    {
      word: "moped",
      phonetic: "/məʊpt/",
      phonetics: [
        {
          text: "/məʊpt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/moped-1-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88743255",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/moʊpt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To carry oneself in a depressed, lackadaisical manner; to give oneself up to low spirits; to pout, sulk.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make spiritless and stupid.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Melancholic, dejected.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mope",
        "https://en.wiktionary.org/wiki/moped",
      ],
    },
    {
      word: "moped",
      phonetic: "/ˈməʊ.pɛd/",
      phonetics: [
        {
          text: "/ˈməʊ.pɛd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/moped-2-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88743257",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈmoʊ.pɛd/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A lightweight, two-wheeled vehicle equipped with a small motor and pedals, designed to go no faster than some specified speed limit.",
              synonyms: [],
              antonyms: [],
              example:
                "He was drunk again and moped, whining at length about his moped.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/moped"],
    },
  ],
  runts: [
    {
      word: "runts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The smallest animal of a litter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) The smallest child in the family.",
              synonyms: [],
              antonyms: [],
              example: "the runt of the family",
            },
            {
              definition: "Undersized or stunted plant, animal or person.",
              synonyms: ["dwarf"],
              antonyms: [],
            },
            {
              definition:
                "An Ethernet packet that does not meet the medium's minimum packet size of 64 bytes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A single word (or portion of a hyphenated word) that appears as the last line of a paragraph.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A breed of pigeon related to the carrier pigeon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A hardened stem or stalk of a plant.",
              synonyms: [],
              antonyms: [],
              example:
                "Neither young poles nor old runts are durable. — Holland.",
            },
            { definition: "A bow.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["dwarf"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/runt",
        "https://en.wiktionary.org/wiki/runts",
      ],
    },
  ],
  serge: [
    {
      word: "serge",
      phonetic: "/sɜːdʒ/",
      phonetics: [
        { text: "/sɜːdʒ/", audio: "" },
        { text: "/sɝdʒ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A type of worsted cloth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by metonymy) A garment made of this fabric.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To overlock.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/serge"],
    },
    {
      word: "serge",
      phonetic: "/sɜːdʒ/",
      phonetics: [
        { text: "/sɜːdʒ/", audio: "" },
        { text: "/sɝdʒ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A large wax candle used in some church ceremonies.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/serge"],
    },
  ],
  kinky: [
    {
      word: "kinky",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/kinky-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241357",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈkɪŋ.ki/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Full of kinks; liable to kink or curl.",
              synonyms: [],
              antonyms: [],
              example: "kinky hair",
            },
            {
              definition:
                "Marked by unconventional sexual preferences or behavior, as fetishism, sadomasochism, and other sexual practices.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Queer; eccentric; crotchety.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["normophilic"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/kinky"],
    },
  ],
  rills: [
    {
      word: "rills",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A very small brook; a streamlet.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A long, narrow depression that resembles a channel, found on the surface of various lunar and planetary bodies.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["rima"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rill",
        "https://en.wiktionary.org/wiki/rille",
        "https://en.wiktionary.org/wiki/rills",
      ],
    },
  ],
  corns: [
    {
      word: "corns",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The main cereal plant grown for its grain in a given region, such as oats in parts of Scotland and Ireland, and wheat or barley in England and Wales.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Maize, a grain crop of the species Zea mays.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A grain or seed, especially of a cereal crop.",
              synonyms: [],
              antonyms: [],
              example: "He paid her the nominal fee of two corns of barley.",
            },
            {
              definition: "A small, hard particle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To granulate; to form a substance into grains",
              synonyms: [],
              antonyms: [],
              example: "to corn gunpowder",
            },
            {
              definition: "To preserve using coarse salt, e.g. corned beef",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To provide with corn (typically maize; or, in Scotland, oats) for feed",
              synonyms: [],
              antonyms: [],
              example: "Corn the horses.",
            },
            {
              definition: "To render intoxicated",
              synonyms: [],
              antonyms: [],
              example: "ale strong enough to corn one",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A type of callus, usually on the feet or hands.",
              synonyms: ["clavus"],
              antonyms: [],
            },
          ],
          synonyms: ["clavus"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/corn",
        "https://en.wiktionary.org/wiki/corns",
      ],
    },
  ],
  brats: [
    {
      word: "brats",
      phonetic: "/bɹæts/",
      phonetics: [{ text: "/bɹæts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A child who is regarded as mischievous, unruly, spoiled, or selfish.",
              synonyms: [],
              antonyms: [],
              example: "Get that little brat away from me!",
            },
            {
              definition:
                "A son or daughter (at any age) of an active military service member.",
              synonyms: [],
              antonyms: [],
              example: "an army brat",
            },
            { definition: "A turbot or flatfish", synonyms: [], antonyms: [] },
            {
              definition: "A rough cloak or ragged garment",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A coarse kind of apron for keeping the clothes clean; a bib.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The young of an animal.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Bratwurst", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A thin bed of coal mixed with pyrites or carbonate of lime.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/brat",
        "https://en.wiktionary.org/wiki/brats",
      ],
    },
  ],
  pries: [
    {
      word: "pries",
      phonetic: "/pɹaɪz/",
      phonetics: [{ text: "/pɹaɪz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To look where one is not welcome; to be nosy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To keep asking about something that does not concern one.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To look closely and curiously at (something closed or not public).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To use leverage to open or widen.",
              synonyms: ["prise (British English)", "prize"],
              antonyms: [],
            },
          ],
          synonyms: ["prise (British English)", "prize"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pries",
        "https://en.wiktionary.org/wiki/pry",
      ],
    },
  ],
  amble: [
    {
      word: "amble",
      phonetic: "/ˈæm.bəl/",
      phonetics: [
        {
          text: "/ˈæm.bəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/amble-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=553808",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An unhurried leisurely walk or stroll.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An easy gait, especially that of a horse.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To stroll or walk slowly and leisurely.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of a quadruped: to move along by using both legs on one side, and then the other.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["saunter"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/amble"],
    },
  ],
  fries: [
    {
      word: "fries",
      phonetic: "/fɹaɪz/",
      phonetics: [{ text: "/fɹaɪz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(usually in the plural, fries) A fried strip of potato.",
              synonyms: ["chip", "french fry"],
              antonyms: [],
            },
            {
              definition: "A meal of fried sausages, bacon, eggs, etc.",
              synonyms: ["fry-up"],
              antonyms: [],
            },
            {
              definition: "A state of excitement.",
              synonyms: [],
              antonyms: [],
              example: "to be in a fry",
            },
          ],
          synonyms: ["chip", "french fry", "fry-up"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "A method of cooking food.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be affected by extreme heat or current.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A kind of sieve.", synonyms: [], antonyms: [] },
            { definition: "A drain.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fries",
        "https://en.wiktionary.org/wiki/fry",
      ],
    },
  ],
  loons: [
    {
      word: "loons",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "An idler, a lout.", synonyms: [], antonyms: [] },
            { definition: "A boy, a lad.", synonyms: [], antonyms: [] },
            { definition: "A harlot; mistress.", synonyms: [], antonyms: [] },
            { definition: "A simpleton.", synonyms: [], antonyms: [] },
            {
              definition: "A crazy or deranged person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An English soldier of an expeditionary army in Ireland.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["lunatic", "psycho", "wacko"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of various birds, of the order Gaviiformes, of North America and Europe that dive for fish and have a short tail, webbed feet and a yodeling cry.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["diver"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/loon",
        "https://en.wiktionary.org/wiki/loons",
      ],
    },
  ],
  tsars: [
    {
      word: "tsars",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An emperor of Russia (1547 to 1917) and of some South Slavic states.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person with great power; an autocrat.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tsar",
        "https://en.wiktionary.org/wiki/tsars",
      ],
    },
  ],
  datum: [
    {
      word: "datum",
      phonetic: "/ˈdeɪtəm/",
      phonetics: [{ text: "/ˈdeɪtəm/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(plural: data) A measurement of something on a scale understood by both the recorder (a person or device) and the reader (another person or device). The scale is arbitrarily defined, such as from 1 to 10 by ones, 1 to 100 by 0.1, or simply true or false, on or off, yes, no, or maybe, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(plural: data) A fact known from direct observation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(plural: data) A premise from which conclusions are drawn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(plural: datums) A fixed reference point, or a coordinate system.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To provide missing data points by using a mathematical model to extrapolate values that are outside the range of a measuring device.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/datum"],
    },
  ],
  musky: [
    {
      word: "musky",
      phonetic: "/mʌs.ki/",
      phonetics: [{ text: "/mʌs.ki/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having the scent of musk",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/musky"],
    },
    {
      word: "musky",
      phonetic: "/mʌs.ki/",
      phonetics: [{ text: "/mʌs.ki/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Muskellunge", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/musky"],
    },
  ],
  pigmy: [
    {
      word: "pigmy",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(often capitalized) A member of one of various Ancient Equatorial African tribal peoples, notable for their very short stature.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A member of a race of dwarfs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any dwarfish person or thing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An insignificant person, at least in some respect.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dwarf", "midget"],
          antonyms: ["ettin", "giant"],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Relating or belonging to the Pygmy people.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Like a pygmy; unusually short or small for its kind.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dwarfish", "pygmyish"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pigmy",
        "https://en.wiktionary.org/wiki/pygmy",
      ],
    },
  ],
  gnome: [
    {
      word: "gnome",
      phonetic: "/nəʊm/",
      phonetics: [
        {
          text: "/nəʊm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gnome-1-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90572955",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A brief reflection or maxim; a pithy saying.",
              synonyms: [
                "adage",
                "apothegm",
                "maxim",
                "paroemia",
                "proverb",
                "sententia",
              ],
              antonyms: [],
            },
          ],
          synonyms: [
            "adage",
            "apothegm",
            "maxim",
            "paroemia",
            "proverb",
            "sententia",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gnome"],
    },
    {
      word: "gnome",
      phonetic: "/nəʊm/",
      phonetics: [
        { text: "/nəʊm/", audio: "" },
        { text: "/noʊm/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(magic, Rosicrucianism) An elemental (spirit or corporeal creature associated with a classical element) associated with earth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(fantasy literature) One of a race of imaginary human-like beings, usually depicted as short and typically bearded males, who inhabit the inner parts of the earth and act as guardians of mines, mineral treasure, etc.; in modern fantasy literature, when distinguished from dwarves, gnomes are usually even smaller than dwarves and more focussed on engineering than mining.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A dwarf; a goblin; a person of small stature or misshapen features, or of strange appearance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The northern pygmy owl, Glaucidium gnoma, a small owl of the western United States.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small statue of a dwarf-like character, often bearded, placed in a garden.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An upper atmospheric optical phenomenon associated with thunderstorms, a compact blue starter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A banker, especially a secretive international one.",
              synonyms: [],
              antonyms: [],
              example: "the gnomes of Zurich",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gnome"],
    },
  ],
  ravel: [
    {
      word: "ravel",
      phonetic: "/ˈɹævəl/",
      phonetics: [
        {
          text: "/ˈɹævəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ravel-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=83944773",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A snarl; a complication.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A ravelled thread.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To tangle; entangle; entwine confusedly, become snarled; thus to involve; perplex; confuse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To undo the intricacies of; to disentangle or clarify.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To pull apart (especially cloth or a seam); unravel.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To become entangled.", synonyms: [], antonyms: [] },
            {
              definition: "To become untwisted or unwoven.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In the APL programming language, to reshape (a variable) into a vector.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ravel"],
    },
  ],
  ovule: [
    {
      word: "ovule",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The structure in a plant that develops into a seed after fertilization; the megasporangium of a seed plant with its enclosing integuments.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An immature ovum in mammals.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ovule"],
    },
  ],
  icily: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  liken: [
    {
      word: "liken",
      phonetic: "/ˈlaɪkən/",
      phonetics: [
        {
          text: "/ˈlaɪkən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/liken-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=95144291",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(followed by to or unto) To compare; to state that (something) is like (something else).",
              synonyms: [],
              antonyms: [],
              example:
                "The physics teacher likened the effect of mass on space to an indentation in a sheet of rubber.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/liken"],
    },
  ],
  lemur: [
    {
      word: "lemur",
      phonetic: "/ˈliːmə(ɹ)/",
      phonetics: [
        {
          text: "/ˈliːmə(ɹ)/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lemur-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88681192",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any strepsirrhine primate of the infraorder Lemuriformes, superfamily Lemuroidea, native only to Madagascar and some surrounding islands.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of the genus Lemur, represented by the ring-tailed lemur (Lemur catta).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A loris (Lemur tardigradus, now Loris tardigradus), predating the 10th edition of Systema Naturæ.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lemur"],
    },
  ],
  frays: [
    {
      word: "frays",
      phonetic: "/fɹeɪz/",
      phonetics: [
        {
          text: "/fɹeɪz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/frays-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1661683",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To (cause to) unravel; used particularly for the edge of something made of cloth, or the end of a rope.",
              synonyms: [],
              antonyms: [],
              example: "The ribbon frayed at the cut end.",
            },
            {
              definition:
                "To cause exhaustion, wear out (a person's mental strength).",
              synonyms: [],
              antonyms: [],
              example:
                "The hectic day ended in frayed nerves. (Metaphorical use; nerves are visualised as strings)",
            },
            { definition: "Frighten; alarm", synonyms: [], antonyms: [] },
            {
              definition: "To bear the expense of; to defray.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To rub.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A fight or argument",
              synonyms: [],
              antonyms: [],
              example:
                "Though they did not know the reason for the dispute, they did not hesitate to leap into the fray.",
            },
            { definition: "Fright.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fray",
        "https://en.wiktionary.org/wiki/frays",
      ],
    },
  ],
  silts: [
    {
      word: "silts",
      phonetic: "/sɪlts/",
      phonetics: [
        {
          text: "/sɪlts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/silts-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=87241264",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To clog or fill with silt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To become clogged with silt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To flow through crevices; to percolate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/silt",
        "https://en.wiktionary.org/wiki/silts",
      ],
    },
  ],
  sifts: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  plods: [
    {
      word: "plods",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To walk or move slowly and heavily or laboriously (+ on, through, over).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To trudge over or through.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To toil; to drudge; especially, to study laboriously and patiently.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/plod",
        "https://en.wiktionary.org/wiki/plods",
      ],
    },
  ],
  ramps: [
    {
      word: "ramps",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An inclined surface that connects two levels; an incline.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A road that connects a freeway to a surface street or another freeway.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A mobile staircase that is attached to the doors of an aircraft at an airport",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A large parking area in an airport for aircraft, for loading and unloading or for storage (see also apron)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A construction used to do skating tricks, usually in the form of part of a pipe.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A speed bump.", synonyms: [], antonyms: [] },
            { definition: "A leap or bound.", synonyms: [], antonyms: [] },
            {
              definition:
                "A concave bend at the top or cap of a railing, wall, or coping; a romp.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To behave violently; to rage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To spring; to leap; to bound, rear, or prance; to move swiftly or violently.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To climb, like a plant; to creep up.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To stand in a rampant position.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To (cause to) change value, often at a steady rate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To adapt a piece of iron to the woodwork of a gate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An American plant, Allium tricoccum, related to the onion; a wild leek.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A promiscuous man or woman; a general insult for a worthless person.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["ramps", "rams", "ramson", "ramsons", "wild leek"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ramp",
        "https://en.wiktionary.org/wiki/ramps",
      ],
    },
  ],
  tress: [
    {
      word: "tress",
      phonetic: "/tɹɛs/",
      phonetics: [{ text: "/tɹɛs/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A braid, knot, or curl, of hair; a ringlet.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A long lock of hair", synonyms: [], antonyms: [] },
            {
              definition: "(by extension) A knot or festoon, as of flowers.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To braid or knot hair.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tress"],
    },
  ],
  earls: [
    {
      word: "earls",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(nobility) A British or Irish nobleman next in rank above a viscount and below a marquess; equivalent to a European count. A female using the style is termed a countess.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various nymphalid butterflies of the genus Tanaecia. Other butterflies in this genus are called counts and viscounts.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/earl",
        "https://en.wiktionary.org/wiki/earls",
      ],
    },
  ],
  dudes: [
    {
      word: "dudes",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dudes-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79293752",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/duːdz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A man, generally a younger man.",
              synonyms: [],
              antonyms: [],
              example:
                'So we were at the mall and these two dudes just walk up to us and say "hi".',
            },
            {
              definition:
                "(used in the vocative) A term of address for someone, typically a man, particularly when cautioning him or offering him advice.",
              synonyms: [],
              antonyms: [],
              example:
                "Dude, I'd be careful around the principal; he's having a bad day.",
            },
            {
              definition: "An inexperienced cowboy.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A tourist.", synonyms: [], antonyms: [] },
            {
              definition:
                "A man who is very concerned about his dress and appearance; a dandy, a fop.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "bloke",
            "chap",
            "cove",
            "guy",
            "dandy",
            "fop",
            "masher",
            "mate",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To address someone as dude.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To take a vacation in a dude ranch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Usually followed by up: to dress up, to wear smart or special clothes.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dude",
        "https://en.wiktionary.org/wiki/dudes",
      ],
    },
  ],
  waive: [
    {
      word: "waive",
      phonetic: "/weɪv/",
      phonetics: [
        {
          text: "/weɪv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/waive-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89013127",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To relinquish (a right etc.); to give up claim to; to forego.",
              synonyms: [],
              antonyms: [],
              example:
                "If you waive the right to be silent, anything you say can be used against you in a court of law.",
            },
            { definition: "To put aside, avoid.", synonyms: [], antonyms: [] },
            { definition: "To outlaw (someone).", synonyms: [], antonyms: [] },
            {
              definition: "To abandon, give up (someone or something).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/waive"],
    },
    {
      word: "waive",
      phonetic: "/weɪv/",
      phonetics: [
        {
          text: "/weɪv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/waive-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89013127",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To move from side to side; to sway.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To stray, wander.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/waive"],
    },
    {
      word: "waive",
      phonetic: "/weɪv/",
      phonetics: [
        {
          text: "/weɪv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/waive-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89013127",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A woman put out of the protection of the law; an outlawed woman.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A waif; a castaway.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/waive"],
    },
  ],
  karat: [
    {
      word: "karat",
      phonetic: "/ˈkæɹ.ət/",
      phonetics: [{ text: "/ˈkæɹ.ət/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A unit of fineness or concentration of gold equalling 1/24 part of gold in an alloy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/karat"],
    },
  ],
  jolts: [
    {
      word: "jolts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "An act of jolting.", synonyms: [], antonyms: [] },
            { definition: "A surprise or shock.", synonyms: [], antonyms: [] },
            {
              definition: "A long prison sentence.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A narcotic injection.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To push or shake abruptly and roughly.",
              synonyms: [],
              antonyms: [],
              example: "The bus jolted its passengers at every turn.",
            },
            { definition: "To knock sharply", synonyms: [], antonyms: [] },
            {
              definition:
                "To shock (someone) into taking action or being alert",
              synonyms: [],
              antonyms: [],
              example: "I jolted her out of complacency.",
            },
            {
              definition: "To shock emotionally.",
              synonyms: [],
              antonyms: [],
              example: "Her untimely death jolted us all.",
            },
            {
              definition: "To shake; to move with a series of jerks.",
              synonyms: [],
              antonyms: [],
              example: "The car jolted along the stony path.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/jolt",
        "https://en.wiktionary.org/wiki/jolts",
      ],
    },
  ],
  peons: [
    {
      word: "peons",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A lowly person; a peasant or serf; a labourer who is obliged to do menial work.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person of low rank or importance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A messenger, foot soldier, or native policeman.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/peon",
        "https://en.wiktionary.org/wiki/peons",
      ],
    },
  ],
  beers: [
    {
      word: "beers",
      phonetic: "/biɹz/",
      phonetics: [{ text: "/biɹz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An alcoholic drink fermented from starch material, commonly barley malt, often with hops or some other substance to impart a bitter flavor.",
              synonyms: [],
              antonyms: [],
              example: "Beer is brewed all over the world.",
            },
            {
              definition:
                "A fermented extract of the roots and other parts of various plants, as spruce, ginger, sassafras, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A solution produced by steeping plant materials in water or another fluid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A glass, bottle, or can of any of the above beverages.",
              synonyms: [],
              antonyms: [],
              example: "Can I buy you a beer?",
            },
            {
              definition: "A variety of the above beverages.",
              synonyms: [],
              antonyms: [],
              example: "I haven't tried this beer before.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "One who is or exists.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/beer",
        "https://en.wiktionary.org/wiki/beers",
      ],
    },
  ],
  horny: [
    {
      word: "horny",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/horny-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88680935",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈhɔɹ.ni/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Hard or bony, like an animal's horn.",
              synonyms: ["callous", "coarse", "hardened", "rough"],
              antonyms: [],
            },
            { definition: "Having horns.", synonyms: ["horned"], antonyms: [] },
            {
              definition: "Sexually aroused.",
              synonyms: ["excited", "in heat", "randy", "toey"],
              antonyms: [],
              example: "That girl makes me feel horny.",
            },
            { definition: "Sexually arousing.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "callous",
            "coarse",
            "hardened",
            "rough",
            "excited",
            "in heat",
            "randy",
            "toey",
            "horned",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/horny"],
    },
  ],
  pales: [
    {
      word: "pales",
      phonetic: "/peɪlz/",
      phonetics: [{ text: "/peɪlz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To turn pale; to lose colour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To become insignificant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make pale; to diminish the brightness of.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To enclose with pales, or as if with pales; to encircle or encompass; to fence off.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pale",
        "https://en.wiktionary.org/wiki/pales",
      ],
    },
    {
      word: "pales",
      phonetic: "/peɪlz/",
      phonetics: [{ text: "/peɪlz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A wooden stake; a picket.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Fence made from wooden stake; palisade.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) Limits, bounds (especially before of).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The bounds of morality, good behaviour or judgment in civilized company, in the phrase beyond the pale.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A vertical band down the middle of a shield.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A territory or defensive area within a specific boundary or under a given jurisdiction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The jurisdiction (territorial or otherwise) of an authority.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A cheese scoop.", synonyms: [], antonyms: [] },
            {
              definition: "A shore for bracing a timber before it is fastened.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pale",
        "https://en.wiktionary.org/wiki/pales",
      ],
    },
  ],
  wreak: [
    {
      word: "wreak",
      phonetic: "/ɹik/",
      phonetics: [
        {
          text: "/ɹik/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wreak-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=360883",
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cause something harmful; to afflict; to inflict; to harm or injury; to let out something harmful; .",
              synonyms: [],
              antonyms: [],
              example: "She wreaked her anger on his car.",
            },
            {
              definition:
                "To chasten, or chastise/chastize, or castigate, or punish, or smite.",
              synonyms: [],
              antonyms: [],
              example:
                "The criminal has been wreaked by the Judge to spend a year in prison.",
            },
            {
              definition: "To inflict or take vengeance on.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To take vengeance for.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/wreak"],
    },
    {
      word: "wreak",
      phonetic: "/ɹik/",
      phonetics: [
        {
          text: "/ɹik/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wreak-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=360883",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Revenge; vengeance; furious passion; resentment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Punishment; retribution; payback.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/wreak"],
    },
  ],
  lairs: [
    {
      word: "lairs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A place inhabited by a wild animal, often a cave or a hole in the ground.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A shed or shelter for domestic animals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A place inhabited by a criminal or criminals, a superhero or a supervillain; a refuge, retreat, haven or hideaway.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A bed or resting place.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A grave; a cemetery plot.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A bog; a mire.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person who dresses in a showy but tasteless manner and behaves in a vulgar and conceited way; a show-off.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lair",
        "https://en.wiktionary.org/wiki/lairs",
      ],
    },
  ],
  lynch: [
    {
      word: "lynch",
      phonetic: "/lɪntʃ/",
      phonetics: [{ text: "/lɪntʃ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To execute (somebody) without a proper legal trial or procedure, especially by hanging and backed by a mob.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["string up"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lynch"],
    },
    {
      word: "lynch",
      phonetic: "/lɪntʃ/",
      phonetics: [{ text: "/lɪntʃ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A ledge, a terrace; a right-angled projection; a lynchet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An acclivity; a small hill or hillock.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/linch",
        "https://en.wiktionary.org/wiki/lynch",
      ],
    },
  ],
  stank: [
    {
      word: "stank",
      phonetic: "/stæŋk/",
      phonetics: [
        {
          text: "/stæŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stank-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79218898",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To have a strong bad smell.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be greatly inferior; to perform badly.",
              synonyms: [],
              antonyms: [],
              example: "That movie stinks. I didn't even stay for the end.",
            },
            {
              definition: "To give an impression of dishonesty or untruth.",
              synonyms: [],
              antonyms: [],
              example: "Something stinks about the politician's excuses.",
            },
            {
              definition: "To cause to stink; to affect by a stink.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["blow", "suck", "fishy", "pong", "reek"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/stank",
        "https://en.wiktionary.org/wiki/stink",
      ],
    },
    {
      word: "stank",
      phonetic: "/stæŋk/",
      phonetics: [
        {
          text: "/stæŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stank-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79218898",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Foul-smelling, stinking, unclean.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stank"],
    },
    {
      word: "stank",
      phonetic: "/stæŋk/",
      phonetics: [
        {
          text: "/stæŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stank-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79218898",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Water retained by an embankment; a pool of water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A dam or mound to stop water.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stank"],
    },
    {
      word: "stank",
      phonetic: "/stæŋk/",
      phonetics: [
        {
          text: "/stæŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stank-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79218898",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Weak; worn out", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stank"],
    },
    {
      word: "stank",
      phonetic: "/stæŋk/",
      phonetics: [
        {
          text: "/stæŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stank-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79218898",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [{ definition: "To sigh.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stank"],
    },
  ],
  swoon: [
    {
      word: "swoon",
      phonetic: "/swuːn/",
      phonetics: [{ text: "/swuːn/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A faint.", synonyms: [], antonyms: [] },
            { definition: "An infatuation.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/swoon"],
    },
    {
      word: "swoon",
      phonetic: "/swuːn/",
      phonetics: [{ text: "/swuːn/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To faint, to lose consciousness.",
              synonyms: ["black out", "faint", "pass out"],
              antonyms: [],
            },
            {
              definition:
                "(by extension) To be overwhelmed by emotion, especially infatuation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make a moan, sigh, or some other sound expressing infatuation or affection.",
              synonyms: [],
              antonyms: [],
              example:
                "The girls swooned at the picture of their favorite actor.",
            },
          ],
          synonyms: ["black out", "faint", "pass out"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/swoon"],
    },
  ],
  idler: [
    {
      word: "idler",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One who idles; one who spends his or her time in inaction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One who idles; a lazy person; a sluggard.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any member of a ship's crew who is not required to keep the night-watch",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A mechanical device such as a pulley or wheel that does not transmit power, but supports a moving belt etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/idler"],
    },
  ],
  abort: [
    {
      word: "abort",
      phonetic: "/əˈbɔːt/",
      phonetics: [
        { text: "/əˈbɔːt/", audio: "" },
        {
          text: "/əˈbɔɹt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/abort-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1755034",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A miscarriage; an untimely birth; an abortion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The product of a miscarriage; an aborted offspring; an abortion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An early termination of a mission, action, or procedure in relation to missiles or spacecraft; the craft making such a mission.",
              synonyms: [],
              antonyms: [],
              example: "We've had aborts on three of our last seven launches.",
            },
            {
              definition: "The function used to abort a process.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An event involving the abort of a process.",
              synonyms: [],
              antonyms: [],
              example: "We've had three aborts over the last two days.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/abort"],
    },
    {
      word: "abort",
      phonetic: "/əˈbɔːt/",
      phonetics: [
        { text: "/əˈbɔːt/", audio: "" },
        {
          text: "/əˈbɔɹt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/abort-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1755034",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(now rare outside medicine) To miscarry; to bring forth (non-living) offspring prematurely.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause a premature termination of (a fetus); to end a pregnancy before term.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To end prematurely; to stop in the preliminary stages; to turn back.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To stop or fail at something in the preliminary stages.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To become checked in normal development, so as either to remain rudimentary or shrink away wholly; to cease organic growth before maturation; to become sterile.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause an organism to develop minimally; to cause rudimentary development to happen; to prevent maturation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To abandon a mission at any point after the beginning of the mission and prior to its completion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To terminate a mission involving a missile or rocket; to destroy a missile or rocket prematurely.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To terminate a process prior to completion.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/abort"],
    },
  ],
  blitz: [
    {
      word: "blitz",
      phonetic: "/blɪts/",
      phonetics: [
        {
          text: "/blɪts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/blitz-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88681013",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sudden attack, especially an air raid; usually with reference to the Blitz.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A swift and overwhelming attack or effort.",
              synonyms: [],
              antonyms: [],
              example:
                "We embarked on a publicity blitz, putting posters and flyers all around town.",
            },
            {
              definition:
                "A play in which additional defenders beyond the defensive linemen rush the passer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Blitz chess, a form of chess with a short time limit for moves.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["speed chess"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To attack quickly or suddenly, as by an air raid or similar action.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To perform a blitz.",
              synonyms: [],
              antonyms: [],
              example:
                "The Washington High defense almost always blitzes on third down.",
            },
            {
              definition:
                "To purée or chop (food products) using a food processor or blender.",
              synonyms: [],
              antonyms: [],
              example:
                "To make nut roast, you have to blitz the nuts in the food processor before adding the parsley and breadcrumbs.",
            },
            {
              definition: "To do something quickly or in one session.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["charge", "hurry", "zoom", "zhoosh"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/blitz"],
    },
  ],
  ensue: [
    {
      word: "ensue",
      phonetic: "/ɛnˈsjʉː/",
      phonetics: [
        { text: "/ɛnˈsjʉː/", audio: "" },
        {
          text: "/ɛnˈsjuː/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ensue-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=95143232",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ɪnˈsuː/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To follow (a leader, inclination etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To follow (in time), to be subsequent to.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To occur afterwards, as a result or effect.",
              synonyms: [],
              antonyms: [],
              example:
                "Give three freshmen six bottles of wine, and hilarity will ensue.",
            },
          ],
          synonyms: [
            "come after",
            "follow",
            "carry out",
            "follow",
            "pursue",
            "arise",
            "follow",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ensue"],
    },
  ],
  atone: [
    {
      word: "atone",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/atone-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75729630",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/əˈtəʊn/", audio: "" },
        {
          text: "/əˈtoʊn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/atone-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=5951871",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make reparation, compensation, amends or satisfaction for an offence, crime, mistake or deficiency.",
              synonyms: ["expiate", "propitiate"],
              antonyms: [],
            },
            {
              definition:
                "To bring at one or at concordance; to reconcile; to suffer appeasement.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To agree or accord; to be in accordance or harmony.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To unite in making.", synonyms: [], antonyms: [] },
            {
              definition:
                "To absolve (someone else) of wrongdoing, especially by standing as an equivalent.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["expiate", "propitiate"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/atone"],
    },
  ],
  bingo: [
    {
      word: "bingo",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bingo-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75729917",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈbɪŋ.ɡəʊ/", audio: "" },
        {
          text: "/ˈbɪŋ.ɡoʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bingo-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=4149890",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                'A game of chance for two or more players, who mark off numbers on a grid as they are announced by the caller; the game is won by the first person to call out "bingo!" or "house!" after crossing off all numbers on the grid or in one line of the grid.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A win in such a game.",
              synonyms: [],
              antonyms: [],
              example:
                "There were two bingos in the last game, so the players split the prize money.",
            },
            {
              definition:
                "(Scrabble) A play where all seven of a player's letter tiles are played.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["housey-housey", "lotto"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To play the game of bingo.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: 'To give the winning cry of "bingo!" in a game.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To play all of one's seven tiles in one move in the game of Scrabble, earning a score bonus.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To return to base.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Just sufficient to return to base (or, alternatively, to divert to an alternative airfield). (also written Bingo or BINGO)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "Used by players of bingo to claim a win.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Used when finding what one has been looking for or trying to recall, or on successful completion of a task.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'Used to declare "You\'ve just made my point!" or "My point exactly!"',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["house", "aha", "eureka", "got it", "that's it", "yes"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bingo"],
    },
    {
      word: "bingo",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bingo-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75729917",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈbɪŋ.ɡəʊ/", audio: "" },
        {
          text: "/ˈbɪŋ.ɡoʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bingo-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=4149890",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "Brandy.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bingo"],
    },
  ],
  roves: [
    {
      word: "roves",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To shoot with arrows (at).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To roam, or wander about at random, especially over a wide area.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To roam or wander through.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To card wool or other fibres.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To twist slightly; to bring together, as slivers of wool or cotton, and twist slightly before spinning.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To draw through an eye or aperture.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To plough into ridges by turning the earth of two furrows together.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To practice robbery on the seas; to voyage about on the seas as a pirate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rove",
        "https://en.wiktionary.org/wiki/roves",
      ],
    },
  ],
  kilts: [
    {
      word: "kilts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A traditional Scottish garment, usually worn by men, having roughly the same morphology as a wrap-around skirt, with overlapping front aprons and pleated around the sides and back, and usually made of twill-woven worsted wool with a tartan pattern.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any Scottish garment from which the above lies in a direct line of descent, such as the philibeg, or the great kilt or belted plaid",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A plaid, pleated school uniform skirt sometimes structured as a wrap around, sometimes pleated throughout the entire circumference; also used as boys' wear in 19th century USA.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A variety of non-bifurcated garments made for men and loosely resembling a Scottish kilt, but most often made from different fabrics and not always with tartan plaid designs.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["filibeg", "philibeg"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/kilt",
        "https://en.wiktionary.org/wiki/kilts",
      ],
    },
  ],
  scald: [
    {
      word: "scald",
      phonetic: "/skɒld/",
      phonetics: [
        { text: "/skɒld/", audio: "" },
        { text: "/skɑld/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A burn, or injury to the skin or flesh, by hot liquid or steam.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To burn with hot liquid.",
              synonyms: [],
              antonyms: [],
              example: "to scald the hand",
            },
            {
              definition: "To heat almost to boiling.",
              synonyms: [],
              antonyms: [],
              example: "Scald the milk until little bubbles form.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scald"],
    },
    {
      word: "scald",
      phonetic: "/skɒld/",
      phonetics: [
        { text: "/skɒld/", audio: "" },
        { text: "/skɑld/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Scaliness; a scabby skin disease.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Affected with the scab; scabby.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Paltry; worthless.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "contemptible",
            "miserable",
            "trashy",
            "roynish",
            "scurvy",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scald"],
    },
    {
      word: "scald",
      phonetic: "/skɒld/",
      phonetics: [
        { text: "/skɒld/", audio: "" },
        { text: "/skɑld/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A Nordic poet of the Viking Age",
              synonyms: [],
              antonyms: [],
              example:
                "1913, Henry Bedford-Jones, Flamehair the Skald: A Tale of the Days of Hardrede, passim.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/scald",
        "https://en.wiktionary.org/wiki/skald",
      ],
    },
  ],
  adios: [
    {
      word: "adios",
      phonetic: "/ˌæ.diˈɒs/",
      phonetics: [
        { text: "/ˌæ.diˈɒs/", audio: "" },
        { text: "/ˌɑ.diˈoʊs/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "(in Spanish contexts) goodbye",
              synonyms: [
                "addio",
                "adieu",
                "aloha",
                "arrivederci",
                "au revoir",
                "auf Wiedersehen",
                "ayubowan",
                "bye",
                "bye-bye",
                "cheerio",
                "cheers",
                "ciao",
                "do svidanya",
                "farewell",
                "good day",
                "good-by",
                "good-bye",
                "goodbye",
                "sayonara",
                "shalom",
                "so long",
              ],
              antonyms: [],
            },
          ],
          synonyms: [
            "addio",
            "adieu",
            "aloha",
            "arrivederci",
            "au revoir",
            "auf Wiedersehen",
            "ayubowan",
            "bye",
            "bye-bye",
            "cheerio",
            "cheers",
            "ciao",
            "do svidanya",
            "farewell",
            "good day",
            "good-by",
            "good-bye",
            "goodbye",
            "sayonara",
            "shalom",
            "so long",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/adios"],
    },
  ],
  cynic: [
    {
      word: "cynic",
      phonetic: "/ˈsɪnɪk/",
      phonetics: [
        {
          text: "/ˈsɪnɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cynic-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780014",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person who believes that all people are motivated by selfishness.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person whose outlook is scornfully negative.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Cynical (in all senses)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Relating to the Dog Star.",
              synonyms: [],
              antonyms: [],
              example: "the cynic, or Sothic, year; cynic cycle",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cynic"],
    },
  ],
  dulls: [
    {
      word: "dulls",
      phonetic: "/dʌlz/",
      phonetics: [{ text: "/dʌlz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To render dull; to remove or blunt an edge or something that was sharp.",
              synonyms: [],
              antonyms: [],
              example: "Years of misuse have dulled the tools.",
            },
            {
              definition:
                "To soften, moderate or blunt; to make dull, stupid, or sluggish; to stupefy.",
              synonyms: [],
              antonyms: [],
              example: "He drinks to dull the pain.",
            },
            {
              definition: "To lose a sharp edge; to become dull.",
              synonyms: [],
              antonyms: [],
              example: "A razor will dull with use.",
            },
            {
              definition: "To render dim or obscure; to sully; to tarnish.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dullen"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dull",
        "https://en.wiktionary.org/wiki/dulls",
      ],
    },
  ],
  memos: [
    {
      word: "memos",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A short note; a memorandum.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A record of partial results that can be reused later without recomputation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To record something; to make a note of something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To send someone a note about something, for the record.",
              synonyms: [],
              antonyms: [],
              example: "I made sure to memo him about the client's complaints.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/memo",
        "https://en.wiktionary.org/wiki/memos",
      ],
    },
  ],
  elfin: [
    {
      word: "elfin",
      phonetic: "/ˈɛlfɪn/",
      phonetics: [{ text: "/ˈɛlfɪn/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An elf; an inhabitant of fairy-land.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A little urchin or child.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of the butterflies in the subgenus Incisalia of the North American lycaenid genus Callophrys.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/elfin"],
    },
    {
      word: "elfin",
      phonetic: "/ˈɛlfɪn/",
      phonetics: [{ text: "/ˈɛlfɪn/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Relating to or resembling an elf or elves, especially in its tiny size or features.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["elven"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/elfin"],
    },
  ],
  dales: [
    {
      word: "dales",
      phonetic: "/deɪlz/",
      phonetics: [{ text: "/deɪlz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A valley, often in an otherwise hilly area.",
              synonyms: ["dell", "dells", "vale"],
              antonyms: [],
            },
          ],
          synonyms: ["dell", "dells", "vale"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A trough or spout to carry off water, as from a pump.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dale",
        "https://en.wiktionary.org/wiki/dales",
      ],
    },
  ],
  peels: [
    {
      word: "peels",
      phonetic: "/piːlz/",
      phonetics: [{ text: "/piːlz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A small salmon; a grilse; a sewin.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(usually uncountable) The skin or outer layer of a fruit, vegetable, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The action of peeling away from a formation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cosmetic preparation designed to remove dead skin or to exfoliate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["rind", "zest"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To remove the skin or outer covering of.",
              synonyms: [],
              antonyms: [],
              example: "I sat by my sister's bed, peeling oranges for her.",
            },
            {
              definition: "To remove something from the outer or top layer of.",
              synonyms: [],
              antonyms: [],
              example: "I peeled (the skin from) a banana and ate it hungrily.",
            },
            {
              definition:
                "To become detached, come away, especially in flakes or strips; to shed skin in such a way.",
              synonyms: [],
              antonyms: [],
              example:
                "I had been out in the sun too long, and my nose was starting to peel.",
            },
            {
              definition: "To remove one's clothing.",
              synonyms: [],
              antonyms: [],
              example:
                "The children peeled by the side of the lake and jumped in.",
            },
            {
              definition: "To move, separate (off or away).",
              synonyms: [],
              antonyms: [],
              example: "The scrum-half peeled off and made for the touchlines.",
            },
          ],
          synonyms: ["disrobe", "strip", "skin", "strip"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A stake.", synonyms: [], antonyms: [] },
            {
              definition: "A fence made of stakes; a stockade.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A small tower, fort, or castle; a keep.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A shovel or similar instrument, now especially a pole with a flat disc at the end used for removing pizza or loaves of bread from a baker's oven.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A T-shaped implement used by printers and bookbinders for hanging wet sheets of paper on lines or poles to dry.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The blade of an oar.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An equal or match; a draw.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A takeout which removes a stone from play as well as the delivered stone.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To play a peel shot.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To send through a hoop (of a ball other than one's own).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To plunder; to pillage, rob.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/peal",
        "https://en.wiktionary.org/wiki/peel",
        "https://en.wiktionary.org/wiki/peels",
      ],
    },
  ],
  peals: [
    {
      word: "peals",
      phonetic: "/piːlz/",
      phonetics: [{ text: "/piːlz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A loud sound, or a succession of loud sounds, as of bells, thunder, cannon, shouts, laughter, of a multitude, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A set of bells tuned to each other according to the diatonic scale.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The changes rung on a set of bells.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To sound with a peal or peals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To utter or sound loudly.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To assail with noise.", synonyms: [], antonyms: [] },
            { definition: "To resound; to echo.", synonyms: [], antonyms: [] },
            { definition: "To pour out.", synonyms: [], antonyms: [] },
            { definition: "To appeal.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A small salmon; a grilse; a sewin.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/peal",
        "https://en.wiktionary.org/wiki/peals",
      ],
    },
  ],
  bares: [
    {
      word: "bares",
      phonetic: "/bɛəz/",
      phonetics: [
        { text: "/bɛəz/", audio: "" },
        {
          text: "/bɛɹz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bares-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100335",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To uncover; to reveal.",
              synonyms: [],
              antonyms: [],
              example: "She bared her teeth at him.",
            },
          ],
          synonyms: ["expose", "lay bare", "reveal", "show", "uncover"],
          antonyms: ["cover", "cover up", "hide"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bare",
        "https://en.wiktionary.org/wiki/bares",
      ],
    },
  ],
  sinus: [
    {
      word: "sinus",
      phonetic: "/ˈsaɪnəs/",
      phonetics: [{ text: "/ˈsaɪnəs/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A pouch or cavity in any organ or tissue, especially the paranasal sinus.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A channel for transmitting venous blood.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A notch or depression between two lobes or teeth in the margin of an organ.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An abnormal cavity or passage such as a fistula, caused by the destruction of tissue.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A bay of the sea; a recess in the shore.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sinus"],
    },
  ],
  crone: [
    {
      word: "crone",
      phonetic: "/kɹəʊn/",
      phonetics: [
        {
          text: "/kɹəʊn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/crone-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=94707855",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/kɹoʊn/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "An old woman.", synonyms: [], antonyms: [] },
            {
              definition: "An archetypal figure, a Wise Woman.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An ugly, evil-looking, or frightening old woman; a hag.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An old ewe.", synonyms: [], antonyms: [] },
            {
              definition:
                "An old man, especially one who talks and acts like an old woman.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/crone"],
    },
  ],
  sable: [
    {
      word: "sable",
      phonetic: "/ˈseɪbəl/",
      phonetics: [
        {
          text: "/ˈseɪbəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sable-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1785252",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small carnivorous mammal of the Old World that resembles a weasel, Martes zibellina, from cold regions in Eurasia and the North Pacific islands, valued for its dark brown fur (Wikipedia).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The marten, especially Martes americana (syn. Mustela americana).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The fur or pelt of the sable or other species of martens; a coat made from this fur.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An artist's brush made from the fur of the sable (Wikipedia).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A black colour on a coat of arms (Wikipedia).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A dark brown colour, resembling the fur of some sables.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in the plural, sables) Black garments, especially worn in mourning.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of the black colour sable.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: ": In blazon, of the colour black.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Made of sable fur.", synonyms: [], antonyms: [] },
            { definition: "Dark, somber.", synonyms: [], antonyms: [] },
            { definition: "Dark-skinned; black.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["black", "dusky", "inky", "sooty", "swarthy"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sable"],
    },
  ],
  hinds: [
    {
      word: "hinds",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A female deer, especially a red deer at least two years old.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A spotted food fish of the genus Epinephelus.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["doe"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A servant, especially an agricultural labourer.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hind",
        "https://en.wiktionary.org/wiki/hinds",
      ],
    },
  ],
  shirk: [
    {
      word: "shirk",
      phonetic: "/ʃɜːk/",
      phonetics: [
        { text: "/ʃɜːk/", audio: "" },
        {
          text: "/ʃɝk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shirk-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=187124",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One who shirks, who avoids a duty or responsibility.",
              synonyms: ["dodger"],
              antonyms: [],
            },
          ],
          synonyms: ["dodger"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To avoid, especially a duty, responsibility, etc.; to stay away from.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To evade an obligation; to avoid the performance of duty, as by running away.",
              synonyms: [],
              antonyms: [],
              example:
                "If you have a job, don't shirk from it by staying off work.",
            },
            {
              definition:
                "To procure by petty fraud and trickery; to obtain by mean solicitation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shirk"],
    },
    {
      word: "shirk",
      phonetic: "/ʃɪɾk/",
      phonetics: [
        {
          text: "/ʃɪɾk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shirk-2-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88896805",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The unforgivable sin of idolatry.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shirk"],
    },
  ],
  enrol: [
    {
      word: "enrol",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/enrol-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75269352",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To enter (a name, etc.) in a register, roll or list",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To enlist (someone) or make (someone) a member of",
              synonyms: [],
              antonyms: [],
              example: "They were eager to enroll new recruits.",
            },
            {
              definition:
                "To enlist oneself (in something) or become a member (of something)",
              synonyms: [],
              antonyms: [],
              example: "Have you enrolled in classes yet for this term?",
            },
            {
              definition: "To envelop; to enwrap.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "enlist",
            "join",
            "join up",
            "sign up",
            "subscribe",
            "enlist",
            "sign up",
            "subscribe",
            "list",
            "note",
            "note down",
            "record",
            "register",
            "add",
            "register",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/enrol",
        "https://en.wiktionary.org/wiki/enroll",
      ],
    },
  ],
  wilts: [
    {
      word: "wilts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act of wilting or the state of being wilted.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various plant diseases characterized by wilting.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To droop or become limp and flaccid (as a dying leaf or flower).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fatigue; to lose strength.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause to droop or become limp and flaccid (as a flower).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause to fatigue; to exhaust.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wilt",
        "https://en.wiktionary.org/wiki/wilts",
      ],
    },
  ],
  roams: [
    {
      word: "roams",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/roams-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1174470",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To wander or travel freely and with no specific destination.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To use a network or service from different locations or devices.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To transmit (resources) between different locations or devices, to allow comparable usage from any of them.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To range or wander over.",
              synonyms: [],
              antonyms: [],
              example: "Gangs of thugs roamed the streets.",
            },
          ],
          synonyms: ["err", "shrithe", "wander"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/roam",
        "https://en.wiktionary.org/wiki/roams",
      ],
    },
  ],
  duped: [
    {
      word: "duped",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To swindle, deceive, or trick.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To duplicate.",
              synonyms: [],
              antonyms: [],
              example: "Can you dupe this photo for me?",
            },
          ],
          synonyms: ["double"],
          antonyms: ["dedupe", "halve"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dupe",
        "https://en.wiktionary.org/wiki/duped",
      ],
    },
  ],
  cysts: [
    {
      word: "cysts",
      phonetic: "/sɪsts/",
      phonetics: [
        {
          text: "/sɪsts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cysts-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=64345833",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A pouch or sac without opening, usually membranous and containing morbid matter, which develops in one of the natural cavities or in the substance of an organ.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of or pertaining to the urinary bladder or gall bladder (in compounds).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cyst",
        "https://en.wiktionary.org/wiki/cysts",
      ],
    },
  ],
  mitts: [
    {
      word: "mitts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A mitten", synonyms: [], antonyms: [] },
            {
              definition:
                "An oversized, protective glove such as an oven mitt or a baseball mitt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(especially in plural) A hand.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mitt",
        "https://en.wiktionary.org/wiki/mitts",
      ],
    },
  ],
  safes: [
    {
      word: "safes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A box, usually made of metal, in which valuables can be locked for safekeeping.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A condom.", synonyms: [], antonyms: [] },
            {
              definition:
                "A ventilated or refrigerated chest or closet for securing provisions from noxious animals or insects.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A safety bicycle.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["coffer", "lockbox", "strongbox"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make something safe.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/safe",
        "https://en.wiktionary.org/wiki/safes",
      ],
    },
  ],
  spats: [
    {
      word: "spats",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(often in the plural) A covering or decorative covering worn over a shoe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of bodywork that covers the upper portions of the rear tyres of a car.",
              synonyms: ["fender skirt"],
              antonyms: [],
            },
          ],
          synonyms: ["fender skirt"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A brief argument, falling out, quarrel.",
              synonyms: [],
              antonyms: [],
              example: "get into a trivial spat over punctuality",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A light blow with something flat.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An obsolete unit of distance in astronomy (symbol S), equal to one billion kilometres.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A stiff legging worn over the instep and ankles of a shoe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A similar device for horses.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Long hair on front of lower legs of heavier horses (over the hooves), especially draft horses, notably the Clydesdale breed.",
              synonyms: [],
              antonyms: ["feather", "feathering", "feathers", "horsefeathers"],
            },
          ],
          synonyms: [],
          antonyms: ["feather", "feathering", "feathers", "horsefeathers"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/spat",
        "https://en.wiktionary.org/wiki/spats",
      ],
    },
  ],
  coops: [
    {
      word: "coops",
      phonetic: "/kuːps/",
      phonetics: [{ text: "/kuːps/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A unit of a housing co-operative; a purchased apartment where the apartment owners collectively are responsible for maintenance of common areas and upkeep.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any co-operative, including housing, retail, utility, agricultural, banking or worker cooperatives.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any shop owned by a co-operative.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A co-operative game, as opposed to a competitive game or deathmatch.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A basket, pen or enclosure for birds or small animals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A wickerwork basket (kipe) or other enclosure for catching fish.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A narrow place of confinement, a cage; a jail, a prison.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A barrel or cask for holding liquids.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To keep in a coop.", synonyms: [], antonyms: [] },
            {
              definition: "To shut up or confine in a narrow space; to cramp.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To unlawfully confine one or more voters to prevent them from casting their ballots in an election.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(law enforcement) Of a police officer: to sleep or relax while on duty.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make or repair barrels, casks and other wooden vessels; to work upon in the manner of a cooper.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A cart with sides and ends made from boards, enabling it to carry manure, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cart which opens at the back to release its load; a tumbril.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A small heap.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/co-op",
        "https://en.wiktionary.org/wiki/coop",
        "https://en.wiktionary.org/wiki/coops",
      ],
    },
  ],
  filet: [
    {
      word: "filet",
      phonetic: "/ˈfɪleɪ/",
      phonetics: [
        { text: "/ˈfɪleɪ/", audio: "" },
        { text: "/fɪˈleɪ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A headband; a ribbon or other band used to tie the hair up, or keep a headdress in place, or for decoration.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A fine strip of any material, in various technical uses.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A heavy bead of waterproofing compound or sealant material generally installed at the point where vertical and horizontal surfaces meet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(drafting, CAD) A rounded relief or cut at an edge, especially an inside edge, added for a finished appearance and to break sharp edges.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A strip or compact piece of meat or fish from which any bones and skin and feathers have been removed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A fine flat moulding/molding used as separation between coarser mouldings.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The space between two flutings in a shaft.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An ordinary equal in breadth to one quarter of the chief, to the lowest portion of which it corresponds in position.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The thread of a screw.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A colored or gilded border.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The raised moulding around the muzzle of a gun.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any scantling smaller than a batten.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A fascia; a band of fibres; applied especially to certain bands of white matter in the brain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The loins of a horse, beginning at the place where the hinder part of the saddle rests.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["filet"],
          antonyms: ["round"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To slice, bone or make into fillets.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To apply, create, or specify a rounded or filled corner to.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bone", "debone"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/filet",
        "https://en.wiktionary.org/wiki/fillet",
      ],
    },
  ],
  knell: [
    {
      word: "knell",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/knell-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75804197",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/nɛl/", audio: "" },
        {
          text: "/nɛl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/knell-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=6502957",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The sound of a bell knelling; a toll (particularly one signalling a death).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sign of the end or demise of something or someone.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To ring a bell slowly, especially for a funeral; to toll.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To signal or proclaim something (especially a death) by ringing a bell.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To summon by, or as if by, ringing a bell.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/knell"],
    },
  ],
  refit: [
    {
      word: "refit",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The process of having something fitted again, repaired or restored.",
              synonyms: [],
              antonyms: [],
              example: "The ship required a refit before setting out again.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To fit again; to put back into its place.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To prepare for use again; to repair or restore.",
              synonyms: [],
              antonyms: [],
              example: "to refit a garment; to refit ships of war",
            },
            {
              definition: "To fit out or supply again (with something).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To prepare a vessel for use again (e.g. by replenishing depleted supplies or doing maintenance or repair work); (of a vessel) to be prepared for use again.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/refit"],
    },
  ],
  covey: [
    {
      word: "covey",
      phonetic: "/ˈkʌvi/",
      phonetics: [
        {
          text: "/ˈkʌvi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/covey-1-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88967365",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A group of 8–12 (or more) quail.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A brood of partridges, grouse, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A party or group (of persons or things).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To brood; to incubate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/covey"],
    },
    {
      word: "covey",
      phonetic: "/ˈkəʊvi/",
      phonetics: [
        {
          text: "/ˈkəʊvi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/covey-2-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88967368",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈkoʊvi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "A man.", synonyms: [], antonyms: [] }],
          synonyms: ["bloke", "chap", "chappie", "cove", "guy"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/covey"],
    },
  ],
  punks: [
    {
      word: "punks",
      phonetic: "/pʌŋks/",
      phonetics: [{ text: "/pʌŋks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A person used for sex, particularly:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A worthless person, particularly:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Short for punk rock, a genre known for short, loud, energetic songs with electric guitars and strong drums.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Short for punk rocker, a musician known for playing punk rock or a fan of the genre.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The larger nonconformist social movement associated with punk rock and its fans.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["gunsel", "hoodlum", "hooligan", "trouble-maker"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To pimp.", synonyms: [], antonyms: [] },
            {
              definition:
                "To forcibly perform anal sex upon an unwilling partner.",
              synonyms: [],
              antonyms: [],
              example: "Ricky punked his new cell-mates.",
            },
            {
              definition: "To prank.",
              synonyms: [],
              antonyms: [],
              example: "I got expelled when I punked the principal.",
            },
            {
              definition:
                '(especially with "out") To give up or concede; to act like a wimp.',
              synonyms: [],
              antonyms: [],
              example:
                "Jimmy was going to help me with the prank, but he punked (out) at the last minute.",
            },
            {
              definition:
                '(often with "out" or "up") To adapt or embellish in the style of the punk movement.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["hustle", "prostitute"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any material used as tinder for lighting fires, such as agaric, dried wood, or touchwood, but especially wood altered by certain fungi.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A utensil for lighting wicks or fuses (such as those of fireworks) resembling stick incense.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/punk",
        "https://en.wiktionary.org/wiki/punks",
      ],
    },
  ],
  kilns: [
    {
      word: "kilns",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An oven or furnace or a heated chamber, for the purpose of hardening, burning, calcining or drying anything; for example, firing ceramics, curing or preserving tobacco, or drying grain.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To bake in a kiln.",
              synonyms: [],
              antonyms: [],
              example:
                "When making pottery we need to allow the bisque to dry before we kiln it.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/kiln",
        "https://en.wiktionary.org/wiki/kilns",
      ],
    },
  ],
  fitly: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  abate: [
    {
      word: "abate",
      phonetic: "/əˈbeɪt/",
      phonetics: [
        { text: "/əˈbeɪt/", audio: "" },
        { text: "/əˈbeɪt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Abatement.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(obsolete outside law) To put an end to; to cause to cease.",
              synonyms: [],
              antonyms: [],
              example: "to abate a nuisance",
            },
            {
              definition: "To become null and void.",
              synonyms: [],
              antonyms: [],
              example: "The writ has abated.",
            },
            {
              definition: "To nullify; make void.",
              synonyms: [],
              antonyms: [],
              example: "to abate a writ",
            },
            {
              definition:
                "To humble; to lower in status; to bring someone down physically or mentally.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be humbled; to be brought down physically or mentally.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To curtail; to deprive.",
              synonyms: [],
              antonyms: [],
              example:
                "Order restrictions and prohibitions to abate an emergency situation.",
            },
            {
              definition: "To reduce in amount, size, or value.",
              synonyms: [],
              antonyms: [],
              example:
                "Legacies are liable to be abated entirely or in proportion, upon a deficiency of assets.",
            },
            {
              definition: "To decrease in size, value, or amount.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To moderate; to lessen in force, intensity, to subside.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To decrease in intensity or force; to subside.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To deduct or omit.",
              synonyms: [],
              antonyms: [],
              example: "We will abate this price from the total.",
            },
            { definition: "To bar or except.", synonyms: [], antonyms: [] },
            {
              definition:
                "To cut away or hammer down, in such a way as to leave a figure in relief, as a sculpture, or in metalwork.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To dull the edge or point of; to blunt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To destroy, or level to the ground.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "contract",
            "cut short",
            "decrease",
            "diminish",
            "lessen",
            "lower",
            "moderate",
            "remit",
            "remove",
            "slacken",
            "suppress",
            "terminate",
            "appease",
            "depress",
            "humble",
            "pacify",
            "soften",
            "soothe",
            "tranquilize",
            "fail",
            "fall through",
            "alleviate",
            "assuage",
            "decline",
            "diminish",
            "ebb",
            "mitigate",
            "relax",
            "subside",
            "temper",
            "wane",
          ],
          antonyms: [
            "accelerate",
            "aggravate",
            "amplify",
            "augment",
            "continue",
            "develop",
            "enhance",
            "enlarge",
            "extend",
            "foment",
            "increase",
            "intensify",
            "magnify",
            "prolong",
            "raise",
            "revive",
            "rise",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/abate"],
    },
    {
      word: "abate",
      phonetic: "/əˈbeɪt/",
      phonetics: [
        { text: "/əˈbeɪt/", audio: "" },
        { text: "/əˈbeɪt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To enter a tenement without permission after the owner has died and before the heir takes possession.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/abate"],
    },
    {
      word: "abate",
      phonetic: "/əˈbeɪt/",
      phonetics: [
        { text: "/əˈbeɪt/", audio: "" },
        { text: "/əˈbeɪt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An Italian abbot, or other member of the clergy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/abate"],
    },
  ],
  talcs: [
    {
      word: "talcs",
      phonetic: "/tælks/",
      phonetics: [{ text: "/tælks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Originally a large range of transparent or glistening foliated minerals. Examples include mica, selenite and the hydrated magnesium silicate that the term talc generally has referred to in modern times (see below). Also an item made of such a mineral and depending for its function on the special nature of the mineral (see next). Mediaeval writers adopted the term from the Arabic.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A microscope slide made of a plate of mica, generally in use from the start of modern microscopy until the early nineteenth century, after which glass slides became the standard medium.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A soft mineral, composed of hydrated magnesium silicate, that has a soapy feel and a greenish, whitish, or grayish color, and usually occurs in foliated masses.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Talcum powder.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To apply talc to.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/talc",
        "https://en.wiktionary.org/wiki/talcs",
      ],
    },
  ],
  heeds: [
    {
      word: "heeds",
      phonetic: "/hiːdz/",
      phonetics: [{ text: "/hiːdz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To guard, protect.", synonyms: [], antonyms: [] },
            {
              definition:
                "To mind; to regard with care; to take notice of; to attend to; to observe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To pay attention, care.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/heed",
        "https://en.wiktionary.org/wiki/heeds",
      ],
    },
  ],
  duels: [
    {
      word: "duels",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Arranged, regular combat between two private persons, often over a matter of honor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Historically, the wager of battle (judicial combat)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any struggle between two contending persons, groups or ideas.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To engage in a battle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/duel",
        "https://en.wiktionary.org/wiki/duels",
      ],
    },
  ],
  wanly: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  ruffs: [
    {
      word: "ruffs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A circular frill or ruffle on a garment, especially a starched, fluted frill at the neck in Elizabethan and Jacobean England (1560s–1620s).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Anything formed with plaits or flutings like a frill.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Senses relating to animals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A collar on a shaft or other piece to prevent endwise motion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An exhibition of haughtiness or pride.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Tumultuous or wanton conduct or procedure.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To shape (fabric, etc.) into a ruff; to adorn (a garment, etc.) with a ruff.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of a falcon, hawk, etc.: to hit (the prey) without fixing or grabbing hold of it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To ruffle; to disorder.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of a bird: to ruffle its feathers.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To boast, to brag.", synonyms: [], antonyms: [] },
            {
              definition:
                "To speak in a loud and domineering manner; to bluster, to swagger.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Arripis georgianus, a fish found in cool waters off the southern coast of Australia; the Australian herring or tommy ruff.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bottom-dwelling carnivorous fish of the family Sparidae found in temperate and tropical waters; a porgy or sea bream.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["roughy"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An instance of ruffing, or an opportunity to ruff, when unable to follow suit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A game similar to whist and its predecessor.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To play a trump card to a trick when unable to follow suit (that is, to play a card of the same suit as the previous or leading card).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Especially in the form ruff out: to defeat (a card, etc.) by ruffing, thus establishing the master card in the suit led.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["trump"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A low, vibrating beat of a drum, quieter than a roll; a ruffle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To beat a ruff or ruffle, as on a drum.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of a drum, etc.: to have a ruff or ruffle beaten on it.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Gymnocephalus cernua, a small Eurasian freshwater fish.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Other species in the same genus.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["pope", "ruff"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ruff",
        "https://en.wiktionary.org/wiki/ruffe",
        "https://en.wiktionary.org/wiki/ruffs",
      ],
    },
  ],
  gauss: [
    {
      word: "gauss",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gauss-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1161558",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The unit of magnetic field strength in CGS systems of units, equal to 0.0001 tesla.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gauss"],
    },
  ],
  lapel: [
    {
      word: "lapel",
      phonetic: "/ləˈpɛl/",
      phonetics: [
        { text: "/ləˈpɛl/", audio: "" },
        { text: "/ləˈpɛl/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Each of the two triangular pieces of cloth on the front of a jacket or coat that are folded back below the throat, leaving a triangular opening between.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lapel"],
    },
  ],
  jaunt: [
    {
      word: "jaunt",
      phonetic: "/ˈdʒɑːnt/",
      phonetics: [
        {
          text: "/ˈdʒɑːnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/jaunt-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88681624",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A wearisome journey.", synonyms: [], antonyms: [] },
            {
              definition:
                "A short excursion for pleasure or refreshment; a ramble; a short journey.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To ramble here and there; to stroll; to make an excursion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To ride on a jaunting car.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To jolt; to jounce.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/jaunt"],
    },
  ],
  whelp: [
    {
      word: "whelp",
      phonetic: "/wɛlp/",
      phonetics: [
        {
          text: "/wɛlp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/whelp-1-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89013012",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A young offspring of a canid (ursid, felid, pinniped), especially of a dog or a wolf, the young of a bear or similar mammal (lion, tiger, seal); a pup, wolf cub.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An insolent youth; a mere child.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A kind of ship.", synonyms: [], antonyms: [] },
            {
              definition:
                "One of several wooden strips to prevent wear on a windlass on a clipper-era ship.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A tooth on a sprocket wheel (compare sprocket and cog).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/whelp"],
    },
    {
      word: "whelp",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(of she-dog, she-wolf, vixen, etc.) To give birth.",
              synonyms: [],
              antonyms: [],
              example: "The bitch whelped.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/whelp"],
    },
    {
      word: "whelp",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "Well, typically used in exasperation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/welp",
        "https://en.wiktionary.org/wiki/whelp",
      ],
    },
  ],
  cleat: [
    {
      word: "cleat",
      phonetic: "/kliːt/",
      phonetics: [{ text: "/kliːt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A strip of wood or iron fastened on transversely to something in order to give strength, prevent warping, hold position, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A continuous metal strip, or angled piece, used to secure metal components.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A device to quickly affix a line or rope, and from which it is also easy to release.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A protrusion on the bottom of a shoe meant for better traction. (See cleats.)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To strengthen with a cleat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To tie off, affix, stopper a line or rope, especially to a cleat.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cleat"],
    },
  ],
  gauzy: [
    {
      word: "gauzy",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Having the qualities of gauze; light, thin, transparent, hazy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Light; giving the effect of haze.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gauzy"],
    },
  ],
  dirge: [
    {
      word: "dirge",
      phonetic: "/dɜːdʒ/",
      phonetics: [
        { text: "/dɜːdʒ/", audio: "" },
        {
          text: "/dɝdʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dirge-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50409591",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A mournful poem or piece of music composed or performed as a memorial to a dead person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A song or piece of music that is considered too slow, bland or boring.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["coronach", "elegy", "lament", "requiem", "threnody"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To sing dirges", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dirge"],
    },
  ],
  edits: [
    {
      word: "edits",
      phonetic: "/ˈɛdɪts/",
      phonetics: [{ text: "/ˈɛdɪts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A change to the text of a document.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A change in the text of a file, a website or the code of software.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An interruption or change to an improvised scene.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An alteration to the DNA sequence of a chromosome; an act of gene splicing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To change a text, or a document.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be the editor of a publication.",
              synonyms: [],
              antonyms: [],
              example: "He edits the Chronicle.",
            },
            {
              definition: "To change the contents of a file, website, etc.",
              synonyms: [],
              antonyms: [],
              example:
                "Wikipedia is an interactive encyclopedia which allows anybody to edit and improve articles.",
            },
            {
              definition:
                "To alter the DNA sequence of a chromosome; to perform gene splicing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To alter a film by cutting and splicing frames.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cut short or otherwise alter an improvised scene.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To lend itself to editing in a certain way.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["splice", "cut", "alter", "fix up", "retouch"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/edit",
        "https://en.wiktionary.org/wiki/edits",
      ],
    },
  ],
  wormy: [
    {
      word: "wormy",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Of or like a worm or worms; shaped like a worm or worms.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Infested with worms.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "worm-eaten",
            "wormed",
            "wormridden",
            "vermian",
            "vermicular",
            "vermiform",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/wormy"],
    },
  ],
  moats: [
    {
      word: "moats",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A deep, wide defensive ditch, normally filled with water, surrounding a fortified habitation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'An aspect of a business which makes it more "defensible" from competitors, either because of the nature of its products, services, franchise or other reason.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A circular lowland between a resurgent dome and the walls of the caldera surrounding it.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A hill or mound.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["fosse"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/moat",
        "https://en.wiktionary.org/wiki/moats",
      ],
    },
  ],
  smear: [
    {
      word: "smear",
      phonetic: "/smɪə/",
      phonetics: [
        {
          text: "/smɪə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/smear-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=5819674",
        },
        { text: "/smiɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A mark made by smearing.",
              synonyms: [],
              antonyms: [],
              example: "This detergent cleans windows without leaving smears.",
            },
            {
              definition:
                "Any of various forms of distortion that make a signal harder to see or hear.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A Pap smear.",
              synonyms: [],
              antonyms: [],
              example: "I'm going to the doctor's this afternoon for a smear.",
            },
            { definition: "A false attack.", synonyms: [], antonyms: [] },
            {
              definition:
                "A maneuver in which the shoe is placed onto the holdless rock, and the friction from the shoe keeps it in contact",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A rough glissando in jazz music.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["Pap smear", "Pap test", "streak"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To spread (a substance, especially one that colours or is dirty) across a surface by rubbing.",
              synonyms: [],
              antonyms: [],
              example:
                "The artist smeared paint over the canvas in broad strokes.",
            },
            {
              definition: "To have a substance smeared on (a surface).",
              synonyms: [],
              antonyms: [],
              example: "She smeared her lips with lipstick.",
            },
            {
              definition:
                "To damage someone's reputation by slandering, misrepresenting, or otherwise making false accusations about an individual, their statements, or their actions.",
              synonyms: [],
              antonyms: [],
              example:
                "The opposition party attempted to smear the candidate by spreading incorrect and unverifiable rumors about their personal behavior.",
            },
            {
              definition: "To become spread by smearing.",
              synonyms: [],
              antonyms: [],
              example:
                "The paint is still wet — don't touch it or it will smear.",
            },
            {
              definition:
                "To climb without using footholds, using the friction from the shoe to stay on the wall.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["coat", "cover", "layer", "spread"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/smear"],
    },
  ],
  prods: [
    {
      word: "prods",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To poke, to push, to touch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To encourage, to prompt.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To prick with a goad.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/prod",
        "https://en.wiktionary.org/wiki/prods",
      ],
    },
  ],
  bowel: [
    {
      word: "bowel",
      phonetic: "/baʊl/",
      phonetics: [
        {
          text: "/baʊl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bowel-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89012458",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A part or division of the intestines, usually the large intestine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in the plural) The entrails or intestines; the internal organs of the stomach.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(in the plural) The (deep) interior of something.",
              synonyms: [],
              antonyms: [],
              example: "The treasures were stored in the bowels of the ship.",
            },
            {
              definition:
                "(in the plural) The seat of pity or the gentler emotions; pity or mercy.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "(in plural) offspring", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To disembowel.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bowel"],
    },
  ],
  frisk: [
    {
      word: "frisk",
      phonetic: "/fɹɪsk/",
      phonetics: [{ text: "/fɹɪsk/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A frolic; a fit of wanton gaiety; a gambol: a little playful skip or leap.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To frolic, gambol, skip, dance, leap.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To search somebody by feeling his or her body and clothing.",
              synonyms: [],
              antonyms: [],
              example:
                "The police frisked the suspiciously-acting individual and found a knife as well as a bag of marijuana.",
            },
          ],
          synonyms: ["pat down"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Lively; brisk",
              synonyms: ["frisky", "frolicsome"],
              antonyms: [],
            },
          ],
          synonyms: ["frisky", "frolicsome"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/frisk"],
    },
  ],
  vests: [
    {
      word: "vests",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A loose robe or outer garment worn historically by men in Arab or Middle Eastern countries.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sleeveless garment that buttons down the front, worn over a shirt, and often as part of a suit; a waistcoat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sleeveless garment, often with a low-cut neck, usually worn under a shirt or blouse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sleeveless top, typically with identifying colours or logos, worn by an athlete or member of a sports team.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any sleeveless outer garment, often for a purpose such as identification, safety, or storage.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A vestment.", synonyms: [], antonyms: [] },
            {
              definition: "Clothing generally; array; garb.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["waistcoat", "singlet", "tank top", "undershirt"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To clothe with, or as with, a vestment, or garment; to dress; to robe; to cover, surround, or encompass closely.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To clothe with authority, power, etc.; to put in possession; to invest; to furnish; to endow; followed by with and the thing conferred.",
              synonyms: [],
              antonyms: [],
              example:
                "to vest a court with power to try cases of life and death",
            },
            {
              definition:
                "To place or give into the possession or discretion of some person or authority; to commit to another; with in before the possessor.",
              synonyms: [],
              antonyms: [],
              example:
                "The power of life and death is vested in the king, or in the courts.",
            },
            {
              definition: "To invest; to put.",
              synonyms: [],
              antonyms: [],
              example: "to vest money in goods, land, or houses",
            },
            {
              definition:
                "To clothe with possession; also, to give a person an immediate fixed right of present or future enjoyment of.",
              synonyms: [],
              antonyms: [],
              example: "an estate is vested in possession",
            },
            {
              definition:
                "(of an inheritance or a trust fund) To devolve upon the person currently entitled when a prior interest has ended.",
              synonyms: [],
              antonyms: [],
              example:
                "Upon the death of the Sovereign the Crown automatically vests in the next heir without the need of coronation or other formality.",
            },
            {
              definition: "(financial) To become vested, to become permanent.",
              synonyms: [],
              antonyms: [],
              example:
                "My pension vests at the end of the month and then I can take it with me when I quit.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/vest",
        "https://en.wiktionary.org/wiki/vests",
      ],
    },
  ],
  bayed: [
    {
      word: "bayed",
      phonetic: "/beɪd/",
      phonetics: [{ text: "/beɪd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To howl.", synonyms: [], antonyms: [] },
            {
              definition:
                "To bark at; hence, to follow with barking; to bring or drive to bay.",
              synonyms: [],
              antonyms: [],
              example: "to bay the bear",
            },
            {
              definition: "To pursue noisily, like a pack of hounds.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Having a bay or bays.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bay",
        "https://en.wiktionary.org/wiki/bayed",
      ],
    },
  ],
  rasps: [
    {
      word: "rasps",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A coarse file, on which the cutting prominences are distinct points raised by the oblique stroke of a sharp punch, instead of lines raised by a chisel, as on the true file.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The sound made by this tool when used, or any similar sound.",
              synonyms: [],
              antonyms: [],
              example: "the rasp of her perpetual cough",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To use a rasp.", synonyms: [], antonyms: [] },
            {
              definition:
                "To make a noise similar to the one a rasp makes in use; to utter rasps.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To work something with a rasp.",
              synonyms: [],
              antonyms: [],
              example:
                "to rasp wood to make it smooth; to rasp bones to powder",
            },
            {
              definition:
                "To grate harshly upon; to offend by coarse or rough treatment or language.",
              synonyms: [],
              antonyms: [],
              example: "His insults rasped my temper.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The raspberry.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rasp",
        "https://en.wiktionary.org/wiki/rasps",
      ],
    },
  ],
  tames: [
    {
      word: "tames",
      phonetic: "/teɪmz/",
      phonetics: [{ text: "/teɪmz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make (an animal) tame; to domesticate.",
              synonyms: [],
              antonyms: [],
              example: "He tamed the wild horse.",
            },
            {
              definition: "To become tame or domesticated.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make gentle or meek.",
              synonyms: [],
              antonyms: [],
              example: "to tame a rebellion",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To broach or enter upon; to taste, as a liquor; to divide; to distribute; to deal out.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tame",
        "https://en.wiktionary.org/wiki/tames",
      ],
    },
  ],
  delve: [
    {
      word: "delve",
      phonetic: "/dɛlv/",
      phonetics: [
        {
          text: "/dɛlv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/delve-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2098011",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To dig the ground, especially with a shovel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To search thoroughly and carefully for information, research, dig into, penetrate, fathom, trace out",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To dig, to excavate.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["dig", "investigate", "research"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/delve"],
    },
    {
      word: "delve",
      phonetic: "/dɛlv/",
      phonetics: [
        {
          text: "/dɛlv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/delve-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2098011",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A pit or den.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/delve"],
    },
  ],
  embed: [
    {
      word: "embed",
      phonetic: "/əmˈbɛd/",
      phonetics: [
        {
          text: "/əmˈbɛd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/embed-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50010916",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An embedded reporter or journalist, such as a war reporter assigned to and travelling with a military unit, or a political reporter assigned to follow and report on the campaign of a candidate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An element of an advertisement, etc. serving as a subliminal message.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An item embedded in another document.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To lay as in a bed; to lay in surrounding matter; to bed.",
              synonyms: [],
              antonyms: [],
              example: "to embed something in clay, mortar, or sand",
            },
            {
              definition: "(by extension) To include in surrounding matter.",
              synonyms: [],
              antonyms: [],
              example:
                "We wanted to embed our reporter with the Fifth Infantry Division, but the Army would have none of it.",
            },
            {
              definition:
                "To encapsulate within another document or data file.",
              synonyms: [],
              antonyms: [],
              example:
                "The instructions showed how to embed a chart from the spreadsheet within the wordprocessor document.",
            },
            {
              definition:
                "To define a one-to-one function from (one set) to another so that certain properties of the domain are preserved when considering the image as a subset of the codomain.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/embed"],
    },
  ],
  befit: [
    {
      word: "befit",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To be fit for",
              synonyms: [],
              antonyms: [],
              example:
                "His conduct was not befitting an officer of his station.",
            },
          ],
          synonyms: ["behoove"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/befit"],
    },
  ],
  wafer: [
    {
      word: "wafer",
      phonetic: "/ˈweɪfə/",
      phonetics: [
        { text: "/ˈweɪfə/", audio: "" },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wafer-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769557",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A light, thin, flat biscuit/cookie.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(christianity) A thin disk of consecrated unleavened bread used in communion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A soft disk originally made of flour, and later of gelatin or a similar substance, used to seal letters, attach papers etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A thin disk of silicon or other semiconductor on which an electronic circuit is produced.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["host"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To seal or fasten with a wafer.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/wafer"],
    },
  ],
  ceded: [
    {
      word: "ceded",
      phonetic: "/ˈsiːdɪd/",
      phonetics: [{ text: "/ˈsiːdɪd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To give up; yield to another.",
              synonyms: [],
              antonyms: [],
              example: "Edward decided to cede the province.",
            },
            { definition: "To give way.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cede",
        "https://en.wiktionary.org/wiki/ceded",
      ],
    },
  ],
  novas: [
    {
      word: "novas",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any sudden brightening of a previously inconspicuous star.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/nova",
        "https://en.wiktionary.org/wiki/novas",
      ],
    },
  ],
  feign: [
    {
      word: "feign",
      phonetic: "/feɪn/",
      phonetics: [
        {
          text: "/feɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/feign-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=61395031",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make a false show or pretence of; to counterfeit or simulate.",
              synonyms: [],
              antonyms: [],
              example: "The pupil feigned sickness on the day of his exam.",
            },
            {
              definition: "To imagine; to invent; to pretend.",
              synonyms: [],
              antonyms: [],
              example:
                "He feigned that he had gone home at the appointed time.",
            },
            {
              definition:
                "To make an action as if doing one thing, but actually doing another, for example to trick an opponent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To hide or conceal.",
              synonyms: [],
              antonyms: [],
              example:
                "Jessica feigned the fact that she had not done her homework.",
            },
          ],
          synonyms: ["front", "put on airs"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/feign"],
    },
  ],
  spews: [
    {
      word: "spews",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To eject forcibly and in a stream",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To speak or write quickly and voluminously, especially words that are not worth listening to or reading.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To vomit", synonyms: [], antonyms: [] },
            { definition: "To ejaculate", synonyms: [], antonyms: [] },
            {
              definition:
                "(leather-working) To develop a white powder or dark crystals on the surface of finished leather, as a result from improper tanning.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/spew",
        "https://en.wiktionary.org/wiki/spews",
      ],
    },
  ],
  larch: [
    {
      word: "larch",
      phonetic: "/ˈlɑːtʃ/",
      phonetics: [
        { text: "/ˈlɑːtʃ/", audio: "" },
        { text: "/ˈlɑɹtʃ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A coniferous tree, of genus Larix, having deciduous leaves, in fascicles.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The wood of the larch.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/larch"],
    },
  ],
  huffs: [
    {
      word: "huffs",
      phonetic: "/hʌfs/",
      phonetics: [{ text: "/hʌfs/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A heavy breath; a grunt or sigh.",
              synonyms: [],
              antonyms: [],
              example:
                "With a huff, he lifted the box onto the back of the truck.",
            },
            {
              definition: "An expression of anger, annoyance, disgust, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One swelled with a false sense of importance or value; a boaster.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To breathe heavily.",
              synonyms: [],
              antonyms: [],
              example: "The run left him huffing and puffing.",
            },
            {
              definition: "To say in a huffy manner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To enlarge; to swell up.",
              synonyms: [],
              antonyms: [],
              example: "Bread huffs.",
            },
            {
              definition:
                "To bluster or swell with anger, arrogance, or pride; to storm; to take offense.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To treat with arrogance and insolence; to chide or rebuke rudely; to bully, to hector.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To inhale psychoactive inhalants.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(draughts) To remove an opponent's piece as a forfeit for deliberately not taking a piece (often signalled by blowing on it).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/huff",
        "https://en.wiktionary.org/wiki/huffs",
      ],
    },
  ],
  doles: [
    {
      word: "doles",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To distribute in small amounts; to share out small portions of a meager resource.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dole",
        "https://en.wiktionary.org/wiki/doles",
      ],
    },
  ],
  mamas: [
    {
      word: "mamas",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(hypocoristic, usually childish) Mother, female parent.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mama",
        "https://en.wiktionary.org/wiki/mamas",
      ],
    },
  ],
  hulks: [
    {
      word: "hulks",
      phonetic: "/hʌlks/",
      phonetics: [{ text: "/hʌlks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A non-functional but floating ship, usually stripped of rigging and equipment, and often put to other uses such as storage or accommodation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any large ship that is difficult to maneuver.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A large structure with a dominating presence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A big (and possibly clumsy) person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An excessively muscled person.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To reduce (a ship) to a (nonfunctional) hulk.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be a hulk, a large (hulking) and often imposing presence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To move (one's large, hulking body).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To remove the entrails of; to disembowel.",
              synonyms: [],
              antonyms: [],
              example: "to hulk a hare",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hulk",
        "https://en.wiktionary.org/wiki/hulks",
      ],
    },
  ],
  pried: [
    {
      word: "pried",
      phonetic: "/pɹɑjd/",
      phonetics: [{ text: "/pɹɑjd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To look where one is not welcome; to be nosy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To keep asking about something that does not concern one.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To look closely and curiously at (something closed or not public).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To use leverage to open or widen.",
              synonyms: ["prise (British English)", "prize"],
              antonyms: [],
            },
          ],
          synonyms: ["prise (British English)", "prize"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pried",
        "https://en.wiktionary.org/wiki/pry",
      ],
    },
  ],
  brims: [
    {
      word: "brims",
      phonetic: "/bɹɪmz/",
      phonetics: [{ text: "/bɹɪmz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The sea; ocean; water; flood.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An edge or border (originally specifically of the sea or a body of water).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The topmost rim or lip of a container.",
              synonyms: [],
              antonyms: [],
              example:
                "The toy box was filled to the brim with stuffed animals.",
            },
            {
              definition: "A projecting rim, especially of a hat.",
              synonyms: [],
              antonyms: [],
              example: "He turned the back of his brim up stylishly.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To be full to overflowing.",
              synonyms: [],
              antonyms: [],
              example: "The room brimmed with people.",
            },
            {
              definition: "To fill to the brim, upper edge, or top.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "Of pigs: to be in heat, to rut.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/brim",
        "https://en.wiktionary.org/wiki/brims",
      ],
    },
  ],
  irked: [
    {
      word: "irked",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To irritate; annoy; bother",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [{ definition: "Annoyed.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/irk",
        "https://en.wiktionary.org/wiki/irked",
      ],
    },
  ],
  aspic: [
    {
      word: "aspic",
      phonetic: "/ˈæspɪk/",
      phonetics: [{ text: "/ˈæspɪk/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A dish in which ingredients are set into a gelatine, jelly-like substance made from a meat stock or consommé.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An asp, a small venomous snake of Egypt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A piece of ordnance carrying a 12-pound shot.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Aspish; relating to an asp, a small venomous snake of Egypt.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/aspic"],
    },
  ],
  swipe: [
    {
      word: "swipe",
      phonetic: "/swaɪp/",
      phonetics: [
        {
          text: "/swaɪp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/swipe-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=380040",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A quick grab, bat, or other motion with the hand or paw; a sweep.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A strong blow given with a sweeping motion, as with a bat or club.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An act of interacting with a touch screen by drawing the finger rapidly across it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An act of passing a swipecard through a card reader.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A rough guess; an estimate or swag.",
              synonyms: [],
              antonyms: [],
              example: "Take a swipe at the answer, even if you're not sure.",
            },
            {
              definition: "An attack, insult or critical remark.",
              synonyms: [],
              antonyms: [],
              example: "The politician took a swipe at his opponents.",
            },
            {
              definition:
                "Poor, weak beer or other inferior alcoholic beverage; rotgut.",
              synonyms: ["swipes"],
              antonyms: [],
            },
          ],
          synonyms: ["swipes"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To grab or bat quickly.",
              synonyms: [],
              antonyms: [],
              example: "The cat swiped at the shoelace.",
            },
            {
              definition: "To strike with a strong blow in a sweeping motion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To scan or register by sliding (a swipecard etc.) through a reader.",
              synonyms: [],
              antonyms: [],
              example: "He swiped his card at the door.",
            },
            {
              definition:
                "To interact with a touch screen by drawing one's finger rapidly across it.",
              synonyms: [],
              antonyms: [],
              example: "Swipe left to hide the toolbar.",
            },
            {
              definition:
                "To draw (one's finger) rapidly across a touch screen.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To steal or snatch.",
              synonyms: [],
              antonyms: [],
              example: "Hey! Who swiped my lunch?",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/swipe"],
    },
  ],
  mealy: [
    {
      word: "mealy",
      phonetic: "/miːli/",
      phonetics: [{ text: "/miːli/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Resembling meal (the foodstuff).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mealy"],
    },
  ],
  skimp: [
    {
      word: "skimp",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/skimp-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75858046",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/skɪmp/", audio: "" },
        { text: "/skɪmp/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To mock, deride, scorn, scold, make fun of.",
              synonyms: [],
              antonyms: [],
              example: "I thought Adie was only skimpin' me.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/skimp"],
    },
    {
      word: "skimp",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/skimp-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75858046",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/skɪmp/", audio: "" },
        { text: "/skɪmp/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A skimpy or insubstantial thing, especially a piece of clothing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(in the plural) Underwear.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To slight; to do carelessly; to scamp.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make insufficient allowance for; to scant; to scrimp.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To save; to be parsimonious or stingy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [{ definition: "Scanty.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/skimp"],
    },
  ],
  bluer: [
    {
      word: "bluer",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of the colour blue.",
              synonyms: [],
              antonyms: [],
              example: "the deep blue sea",
            },
            {
              definition: "Depressed, melancholic, sad.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Pale, without redness or glare; said of a flame.",
              synonyms: [],
              antonyms: [],
              example: "The candle burns blue.",
            },
            {
              definition:
                "Supportive of, run by (a member of), pertaining to, or dominated by a political party represented by the colour blue.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of the higher-frequency region of the part of the electromagnetic spectrum which is relevant in the specific observation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of steak) Extra rare; left very raw and cold.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a dog or cat) Having a coat of fur of a slaty gray shade.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Severe or overly strict in morals; gloomy.",
              synonyms: [],
              antonyms: [],
              example: "blue and sour religionists;  blue laws",
            },
            {
              definition: "(of women) literary; bluestockinged.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Having a color charge of blue.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(entertainment) Risque or obscene",
              synonyms: [],
              antonyms: [],
              example: "His material is too blue for prime-time",
            },
          ],
          synonyms: [],
          antonyms: ["nonblue", "unblue", "antiblue"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/blue",
        "https://en.wiktionary.org/wiki/bluer",
      ],
    },
    {
      word: "bluer",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A blue blazer, part of the school uniform at Harrow School.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bluer"],
    },
    {
      word: "bluer",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The colour of the clear sky or the deep sea, between green and violet in the visible spectrum, and one of the primary additive colours for transmitted light; the colour obtained by subtracting red and green from white light using magenta and cyan filters; or any colour resembling this.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A blue dye or pigment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of several processes to protect metal against rust.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Blue clothing",
              synonyms: [],
              antonyms: [],
              example: "The boys in blue marched to the pipers.",
            },
            {
              definition: "(in the plural) A blue uniform. See blues.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A member of law enforcement",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The sky, literally or figuratively.",
              synonyms: [],
              antonyms: [],
              example: "His request for leave came out of the blue.",
            },
            {
              definition: "The ocean; deep waters.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The far distance; a remote or distant place.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Anything blue, especially to distinguish it from similar objects differing only in color.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A dog or cat with a slaty gray coat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the colour balls used in snooker, with a value of five points.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of the butterflies of the subfamily Polyommatinae in the family Lycaenidae, most of which have blue on their wings.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A bluefish.", synonyms: [], antonyms: [] },
            { definition: "An argument.", synonyms: [], antonyms: [] },
            {
              definition:
                "A liquid with an intense blue colour, added to a laundry wash to prevent yellowing of white clothes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A type of firecracker.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A bluestocking.", synonyms: [], antonyms: [] },
            {
              definition: "One of the three color charges for quarks.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/blue",
        "https://en.wiktionary.org/wiki/bluer",
      ],
    },
  ],
  slake: [
    {
      word: "slake",
      phonetic: "/sleɪk/",
      phonetics: [{ text: "/sleɪk/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To satisfy (thirst, or other desires).",
              synonyms: ["extinguish", "quench"],
              antonyms: [],
            },
            {
              definition: "To cool (something) with water or another liquid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To become mixed with water, so that a true chemical combination takes place.",
              synonyms: [],
              antonyms: [],
              example: "The lime slakes.",
            },
            {
              definition:
                "To mix with water, so that a true chemical combination takes place.",
              synonyms: [],
              antonyms: [],
              example: "to slake lime",
            },
            {
              definition:
                "Of a person: to become less energetic, to slacken in one's efforts.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To slacken; to become relaxed or loose.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To become less intense; to weaken, decrease in force.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To go out; to become extinct.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["extinguish", "quench"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/slake"],
    },
    {
      word: "slake",
      phonetic: "/sleɪk/",
      phonetics: [{ text: "/sleɪk/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A sloppy mess.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To besmear.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/slake"],
    },
  ],
  dowdy: [
    {
      word: "dowdy",
      phonetic: "/ˈdaʊdi/",
      phonetics: [
        { text: "/ˈdaʊdi/", audio: "" },
        { text: "/ˈdaʊdi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A plain or shabby person",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Plain and unfashionable in style or dress.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Lacking stylishness or neatness; shabby.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dowdy"],
    },
  ],
  penis: [
    {
      word: "penis",
      phonetic: "/ˈpiːnɪs/",
      phonetics: [
        {
          text: "/ˈpiːnɪs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/penis-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=55507364",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈpinɪs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/penis-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1602352",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The male reproductive organ used for sexual intercourse that in the human male and some other mammals is also used for urination; the tubular portion of the male genitalia (excluding the scrotum).",
              synonyms: [],
              antonyms: [],
              example: "The female clitoris is homologous to the male penis.",
            },
          ],
          synonyms: ["pintle", "tarse"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/penis"],
    },
  ],
  brays: [
    {
      word: "brays",
      phonetic: "/bɹeɪz/",
      phonetics: [
        {
          text: "/bɹeɪz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/brays-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=60111345",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The cry of an animal, now chiefly that of animals related to the ass or donkey, or the camel.",
              synonyms: ["hee-haw"],
              antonyms: [],
            },
            {
              definition:
                "(by extension) Any discordant, grating, or harsh sound.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["hee-haw"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bray",
        "https://en.wiktionary.org/wiki/brays",
      ],
    },
  ],
  pupas: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  egret: [
    {
      word: "egret",
      phonetic: "/ˈi.ɡɹɪt/",
      phonetics: [{ text: "/ˈi.ɡɹɪt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of various wading birds of the genera Egretta or Ardea that includes herons, many of which are white or buff, and several of which develop fine plumes during the breeding season.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A plume or tuft of feathers worn as a part of a headdress, or anything imitating such an ornament.",
              synonyms: ["aigrette"],
              antonyms: [],
            },
            {
              definition:
                "The flying feathery or hairy crown of seeds or achenes, such as the down of the thistle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The crab-eating macaque (Macaca fascicularis)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["aigrette"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/egret"],
    },
  ],
  flunk: [
    {
      word: "flunk",
      phonetic: "/flʌŋk/",
      phonetics: [{ text: "/flʌŋk/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "Of a student, to fail a class; to not pass.",
              synonyms: [],
              antonyms: [],
              example: "He flunked math, again.",
            },
            {
              definition: "Of a teacher, to deny a student a passing grade.",
              synonyms: [],
              antonyms: [],
              example:
                "Unsatisfied with Fred's progress, the teacher flunked him.",
            },
            {
              definition: "To shirk (a task or duty).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To back out through fear. (Commonly in the phrase 'flunk it', the 'it' referring to a specific task avoided; sometimes without specific reference describing a person's attitude to life in general.)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/flunk"],
    },
  ],
  phlox: [
    {
      word: "phlox",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Any flowering plant of the genus Phlox.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/phlox"],
    },
  ],
  gripe: [
    {
      word: "gripe",
      phonetic: "/ɡɹʌɪp/",
      phonetics: [
        { text: "/ɡɹʌɪp/", audio: "" },
        {
          text: "/ɡɹaɪp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gripe-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=383478",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A complaint, often a petty or trivial one.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A wire rope, often used on davits and other life raft launching systems.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Grasp; clutch; grip", synonyms: [], antonyms: [] },
            {
              definition: "That which is grasped; a handle; a grip.",
              synonyms: [],
              antonyms: [],
              example: "the gripe of a sword",
            },
            {
              definition:
                "A device for grasping or holding anything; a brake to stop a wheel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Oppression; cruel exaction; affliction; pinching distress.",
              synonyms: [],
              antonyms: [],
              example: "the gripe of poverty",
            },
            {
              definition:
                "(chiefly in the plural) Pinching and spasmodic pain in the intestines.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The piece of timber that terminates the keel at the fore end; the forefoot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The compass or sharpness of a ship's stern under the water, having a tendency to make her keep a good wind.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An assemblage of ropes, dead-eyes, and hocks, fastened to ringbolts in the deck, to secure the boats when hoisted.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A vulture, Gyps fulvus; the griffin.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To complain; to whine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To annoy or bother.",
              synonyms: [],
              antonyms: [],
              example: "What's griping you?",
            },
            {
              definition:
                "To tend to come up into the wind, as a ship which, when sailing close-hauled, requires constant labour at the helm.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To pinch; to distress. Specifically, to cause pinching and spasmodic pain to the bowels of, as by the effects of certain purgative or indigestible substances.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To suffer griping pains.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make a grab (to, towards, at or upon something).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To seize or grasp.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["bitch", "complain", "whine", "whinge"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gripe"],
    },
  ],
  peony: [
    {
      word: "peony",
      phonetic: "/ˈpiːəni/",
      phonetics: [
        {
          text: "/ˈpiːəni/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/peony-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1515954",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A flowering plant of the genus Paeonia with large fragrant flowers.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A dark red colour.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/peony"],
    },
  ],
  douse: [
    {
      word: "douse",
      phonetic: "/daʊs/",
      phonetics: [
        {
          text: "/daʊs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/douse-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89844658",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A sudden plunging into water.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To plunge suddenly into water; to duck; to immerse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fall suddenly into water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To put out; to extinguish.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/douse"],
    },
    {
      word: "douse",
      phonetic: "/daʊs/",
      phonetics: [
        {
          text: "/daʊs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/douse-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89844658",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A blow or stroke, especially to the face.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To strike, beat, or thrash.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To strike or lower in haste; to slacken suddenly",
              synonyms: [],
              antonyms: [],
              example: "Douse the topsail!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/douse"],
    },
  ],
  blurs: [
    {
      word: "blurs",
      phonetic: "/blɜːz/",
      phonetics: [
        { text: "/blɜːz/", audio: "" },
        { text: "/blɝz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A smear, smudge or blot",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Something that appears hazy or indistinct",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A moral stain or blot.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make indistinct or hazy, to obscure or dim.",
              synonyms: [],
              antonyms: [],
              example:
                "to blur a photograph by moving the camera while taking it",
            },
            {
              definition: "To smear, stain or smudge.",
              synonyms: [],
              antonyms: [],
              example: "to blur a manuscript by handling it while damp",
            },
            { definition: "To become indistinct.", synonyms: [], antonyms: [] },
            {
              definition:
                "To cause imperfection of vision in; to dim; to darken.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To sully; to stain; to blemish, as reputation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To transfer the input focus away from.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["pixelate", "smooth", "unfocus"],
          antonyms: ["sharpen"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/blur",
        "https://en.wiktionary.org/wiki/blurs",
      ],
    },
  ],
  darns: [
    {
      word: "darns",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "Euphemism of damn.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["damn", "dang"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To repair by stitching with thread or yarn, particularly by using a needle to construct a weave across a damaged area of fabric.",
              synonyms: [],
              antonyms: [],
              example: "I need to darn these socks again.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/darn",
        "https://en.wiktionary.org/wiki/darns",
      ],
    },
  ],
  slunk: [
    {
      word: "slunk",
      phonetic: "/slʌŋk/",
      phonetics: [{ text: "/slʌŋk/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To sneak about furtively.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To give birth to an animal prematurely.",
              synonyms: [],
              antonyms: [],
              example: "a cow that slinks her calf",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An animal, especially a calf, born prematurely or abortively.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/slink",
        "https://en.wiktionary.org/wiki/slunk",
      ],
    },
  ],
  lefts: [
    {
      word: "lefts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The left side or direction.",
              synonyms: ["9 o'clock", "port"],
              antonyms: [],
            },
            {
              definition:
                "The ensemble of left-wing political parties. Those holding left-wing views as a group.",
              synonyms: [],
              antonyms: [],
              example: "The political left is holding too much power.",
            },
            {
              definition: "The left hand or fist.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A punch delivered with the left fist.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A wave breaking from left to right (viewed from the shore).",
              synonyms: [],
              antonyms: ["right"],
            },
          ],
          synonyms: ["9 o'clock", "port"],
          antonyms: ["right"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/left",
        "https://en.wiktionary.org/wiki/lefts",
      ],
    },
  ],
  chats: [
    {
      word: "chats",
      phonetic: "/tʃæts/",
      phonetics: [
        {
          text: "/tʃæts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chats-uk.mp3",
          sourceUrl:
            "https://commons.wikimedia.org/w/index.php?curid=100301936",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any savory snack, sold from a roadside stall in India, or served as a starter in an Indian restaurant",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Informal conversation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A conversation to stop an argument or settle situations.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(totum pro parte, typically with definite article) The entirety of users in a chatroom or a single member thereof.",
              synonyms: [],
              antonyms: [],
              example: "The Chat just made a joke about my skills.",
            },
            {
              definition:
                "An exchange of text or voice messages in real time through a computer network, resembling a face-to-face conversation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various small Old World passerine birds in the muscicapid tribe Saxicolini or subfamily Saxicolinae that feed on insects.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of several small Australian honeyeaters in the genus Epthianura.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To be engaged in informal conversation.",
              synonyms: [],
              antonyms: [],
              example: "I like to chat over a coffee with a friend.",
            },
            {
              definition: "To talk more than a few words.",
              synonyms: [],
              antonyms: [],
              example:
                "I met my old friend in the street, so we chatted for a while.",
            },
            {
              definition: "To talk of; to discuss.",
              synonyms: [],
              antonyms: [],
              example: "They chatted politics for a while.",
            },
            {
              definition:
                "To exchange text or voice messages in real time through a computer network, as if having a face-to-face conversation.",
              synonyms: [],
              antonyms: [],
              example: "Do you want to chat online later?",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(local use) Mining waste from lead and zinc mines.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A louse (small, parasitic insect).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/chaat",
        "https://en.wiktionary.org/wiki/chat",
        "https://en.wiktionary.org/wiki/chats",
      ],
    },
  ],
  inane: [
    {
      word: "inane",
      phonetic: "/ɪˈneɪn/",
      phonetics: [
        {
          text: "/ɪˈneɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/inane-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75803528",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "That which is void or empty.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Lacking sense or meaning (often to the point of boredom or annoyance)",
              synonyms: ["fatuous", "silly", "vapid"],
              antonyms: [],
              example:
                "This supremely gifted kid told me that in the early elementary grades, the songs sung in music class were so inane that he wanted to skip grades already! Eventually he did, so better late than never.",
            },
            {
              definition: "Purposeless; pointless",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["fatuous", "silly", "vapid"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/inane"],
    },
  ],
  vials: [
    {
      word: "vials",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A glass vessel or bottle, especially a small tube-shaped bottle used to store medicine, perfume or other chemical.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["ampoule", "phial", "test tube", "vacutainer"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To put or keep in, or as in, a vial.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/vial",
        "https://en.wiktionary.org/wiki/vials",
      ],
    },
  ],
  stilt: [
    {
      word: "stilt",
      phonetic: "/stɪlt/",
      phonetics: [
        {
          text: "/stɪlt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stilt-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=94709448",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Either of two poles with footrests that allow someone to stand or walk above the ground; used mostly by entertainers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A tall pillar or post used to support some structure; often above water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various wading birds of the genera Himantopus and Cladorhynchus, related to the avocet, that have extremely long legs and long thin bills.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A crutch.", synonyms: [], antonyms: [] },
            {
              definition: "The handle of a plough.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To raise on stilts, or as if on stilts",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stilt"],
    },
  ],
  rinks: [
    {
      word: "rinks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A man, especially a warrior or hero.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A ring; a circle.", synonyms: [], antonyms: [] },
            {
              definition:
                "A sheet of ice prepared for playing certain sports, such as hockey or curling.",
              synonyms: [],
              antonyms: [],
              example: "We played hockey all winter until the rink melted.",
            },
            {
              definition: "A surface for roller skating.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A building housing an ice rink.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A team in a competition.",
              synonyms: [],
              antonyms: [],
              example: "The Schmirler rink won the Silver Broom.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rink",
        "https://en.wiktionary.org/wiki/rinks",
      ],
    },
  ],
  woofs: [
    {
      word: "woofs",
      phonetic: "/wʊfs/",
      phonetics: [{ text: "/wʊfs/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make a woofing sound.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/woof",
        "https://en.wiktionary.org/wiki/woofs",
      ],
    },
  ],
  wowed: [
    {
      word: "wowed",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To amaze or awe.",
              synonyms: [],
              antonyms: [],
              example: "He really wowed the audience.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wow",
        "https://en.wiktionary.org/wiki/wowed",
      ],
    },
  ],
  bongs: [
    {
      word: "bongs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The clang of a large bell.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Doorbell chimes.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To pull a bell.", synonyms: [], antonyms: [] },
            { definition: "To ring a doorbell.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A vessel, usually made of glass or ceramic and filled with water, used in smoking various substances; especially marijuana or pot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An act of smoking one serving of drugs from a bong.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A device for rapidly consuming beer, usually consisting of a funnel or reservoir of beer and a length of tubing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["beer bong", "bucket bong", "water pipe"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A very wide piton.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An Australian Aboriginal person.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(thieves' cant) A purse.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bong",
        "https://en.wiktionary.org/wiki/bongs",
        "https://en.wiktionary.org/wiki/bung",
      ],
    },
  ],
  frond: [
    {
      word: "frond",
      phonetic: "/fɹɒnd/",
      phonetics: [
        { text: "/fɹɒnd/", audio: "" },
        { text: "/fɹɑnd/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The leaf of a fern, especially a compound leaf.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any fern-like leaf or other object resembling a fern leaf.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/frond"],
    },
  ],
  ingot: [
    {
      word: "ingot",
      phonetic: "/ˈɪŋɡət/",
      phonetics: [{ text: "/ˈɪŋɡət/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A solid block of more or less pure metal, often but not necessarily bricklike in shape and trapezoidal in cross-section, the result of pouring out and cooling molten metal, often immediately after smelting from raw ore or alloying from constituents.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To form (scraps of metal) into ingots.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ingot"],
    },
  ],
  evict: [
    {
      word: "evict",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To expel (one or more people) from their property; to force (one or more people) to move out.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/evict"],
    },
  ],
  singe: [
    {
      word: "singe",
      phonetic: "/sɪnd͡ʒ/",
      phonetics: [{ text: "/sɪnd͡ʒ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A burning of the surface; a slight burn.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To burn slightly.", synonyms: [], antonyms: [] },
            {
              definition:
                "To remove the nap of (cloth), by passing it rapidly over a red-hot bar, or over a flame, preliminary to dyeing it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To remove the hair or down from (a plucked chicken, etc.) by passing it over a flame.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["scorch"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/singe"],
    },
  ],
  shyer: [
    {
      word: "shyer",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Easily frightened; timid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Reserved; disinclined to familiar approach.",
              synonyms: [],
              antonyms: [],
              example: "He is very shy with strangers.",
            },
            {
              definition: "Cautious; wary; suspicious.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Short, insufficient or less than.",
              synonyms: [],
              antonyms: [],
              example:
                "By our count your shipment came up two shy of the bill of lading amount.",
            },
            { definition: "Embarrassed.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [
            "audacious",
            "bold",
            "brazen",
            "extroverted",
            "gregarious",
            "outgoing",
          ],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A horse that shies.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/shy",
        "https://en.wiktionary.org/wiki/shyer",
      ],
    },
  ],
  flied: [
    {
      word: "flied",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To hit a fly ball; to hit a fly ball that is caught for an out. Compare ground (verb) and line (verb).",
              synonyms: [],
              antonyms: [],
              example: "Jones flied to right in his last at-bat.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/flied",
        "https://en.wiktionary.org/wiki/fly",
      ],
    },
  ],
  slops: [
    {
      word: "slops",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A loose outer garment; a jacket or overall.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(chiefly in the plural) A rubber thong sandal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(in the plural) See slops.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["flip-flop"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Liquid or semi-solid; goo, paste, mud.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(sometimes in the plural) Scraps used as food for animals, especially pigs or hogs.",
              synonyms: ["hogwash", "swill"],
              antonyms: [],
            },
            {
              definition:
                "(chiefly in the plural) Inferior, weak drink or liquid food.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(sometimes in the plural) Domestic liquid waste; household wastewater.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Water or other liquid carelessly spilled or thrown about, as upon a table or a floor; a puddle; a soiled spot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Human urine or excrement.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["hogwash", "slops", "swill", "hogwash", "swill"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(costers) A policeman.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Loose trousers.", synonyms: [], antonyms: [] },
            {
              definition:
                "Sailors’ breeches ending just below the knees or above the ankles, worn mainly in XVIII century.",
              synonyms: ["open-kneed breeches"],
              antonyms: [],
            },
            {
              definition: "Clothing and bedding issued to sailors.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["flip-flop", "open-kneed breeches"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/slop",
        "https://en.wiktionary.org/wiki/slops",
      ],
    },
    {
      word: "slops",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A loose outer garment; a jacket or overall.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(chiefly in the plural) A rubber thong sandal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(in the plural) See slops.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["flip-flop"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Liquid or semi-solid; goo, paste, mud.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(sometimes in the plural) Scraps used as food for animals, especially pigs or hogs.",
              synonyms: ["hogwash", "swill"],
              antonyms: [],
            },
            {
              definition:
                "(chiefly in the plural) Inferior, weak drink or liquid food.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(sometimes in the plural) Domestic liquid waste; household wastewater.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Water or other liquid carelessly spilled or thrown about, as upon a table or a floor; a puddle; a soiled spot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Human urine or excrement.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["hogwash", "slops", "swill", "hogwash", "swill"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To spill or dump liquid, especially over the rim of a container when it moves.",
              synonyms: [],
              antonyms: [],
              example: "I slopped water all over my shirt.",
            },
            {
              definition:
                "To spill liquid upon; to soil with a spilled liquid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In the game of pool or snooker to pocket a ball by accident; in billiards, to make an ill-considered shot.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To feed pigs", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(costers) A policeman.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/slop",
        "https://en.wiktionary.org/wiki/slops",
      ],
    },
  ],
  dolts: [
    {
      word: "dolts",
      phonetic: "/ˈdoʊlts/",
      phonetics: [
        {
          text: "/ˈdoʊlts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dolts-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80949142",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A stupid person; a blockhead or dullard.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dolt",
        "https://en.wiktionary.org/wiki/dolts",
      ],
    },
  ],
  drool: [
    {
      word: "drool",
      phonetic: "/dɹuːl/",
      phonetics: [{ text: "/dɹuːl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Saliva trickling from the mouth.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Stupid talk.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To secrete saliva, especially in anticipation of food.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To secrete any substance in a similar way.",
              synonyms: [],
              antonyms: [],
              example: "The alien beast drooled slime.",
            },
            {
              definition: "To react to something with uncontrollable desire.",
              synonyms: [],
              antonyms: [],
              example: "That boy is so attractive I drool whenever I see him!",
            },
            {
              definition: "To talk nonsense; drivel.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["drivel", "slaver", "slobber"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/drool"],
    },
  ],
  dells: [
    {
      word: "dells",
      phonetic: "/dɛlz/",
      phonetics: [{ text: "/dɛlz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A valley, especially in the form of a natural hollow, small and deep.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dale", "dingle", "vale", "valley"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A young woman; a wench.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dell",
        "https://en.wiktionary.org/wiki/dells",
      ],
    },
  ],
  whelk: [
    {
      word: "whelk",
      phonetic: "/hwɛlk/",
      phonetics: [{ text: "/hwɛlk/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Certain edible sea snails, especially, any one of numerous species of large marine gastropods belonging to Buccinidae, much used as food in Europe.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["scungilli"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/whelk"],
    },
    {
      word: "whelk",
      phonetic: "/hwɛlk/",
      phonetics: [{ text: "/hwɛlk/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Pimple", synonyms: [], antonyms: [] },
            {
              definition: "A stripe or mark; a ridge; a wale.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/whelk"],
    },
  ],
  hippy: [
    {
      word: "hippy",
      phonetic: "/ˈhɪpi/",
      phonetics: [{ text: "/ˈhɪpi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(1950s slang) A teenager who imitated the beatniks.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(1960s slang; still widely used in reference to that era) One who chooses not to conform to prevailing social norms: especially one who subscribes to values or actions such as acceptance or self-practice of recreational drug use, liberal or radical sexual mores, advocacy of communal living, strong pacifism or anti-war sentiment, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(modern slang) A person who keeps an unkempt or sloppy appearance and wearing unusually long hair (for males), and because of it, often stereotyped as a deadbeat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Someone who dresses in a hippie style.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "One who is hip.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["flower child", "treehugger", "beatnik"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hippie",
        "https://en.wiktionary.org/wiki/hippy",
      ],
    },
    {
      word: "hippy",
      phonetic: "/ˈhɪpi/",
      phonetics: [{ text: "/ˈhɪpi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having prominent or in any other way unusual hips.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hippy"],
    },
  ],
  feted: [
    {
      word: "feted",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(usually in the passive) To celebrate (a person).",
              synonyms: ["celebrate"],
              antonyms: [],
            },
          ],
          synonyms: ["celebrate"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Honoured; celebrated.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fete",
        "https://en.wiktionary.org/wiki/feted",
      ],
    },
  ],
  ether: [
    {
      word: "ether",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ether-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75730532",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈiːθə/", audio: "" },
        {
          text: "/ˈiθɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ether-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=314397",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The substance formerly supposed to fill the upper regions of the atmosphere above the clouds, in particular as a medium breathed by deities.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Often as aether and more fully as luminiferous aether: a substance once thought to fill all unoccupied space that allowed electromagnetic waves to pass through it and interact with matter, without exerting any resistance to matter or energy; its existence was disproved by the 1887 Michelson–Morley experiment and the theory of relativity propounded by Albert Einstein (1879–1955).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The atmosphere or space as a medium for broadcasting radio and television signals; also, a notional space through which Internet and other digital communications take place; cyberspace.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A particular quality created by or surrounding an object, person, or place; an atmosphere, an aura.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Diethyl ether (C4H10O), an organic compound with a sweet odour used in the past as an anaesthetic.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of a class of organic compounds containing an oxygen atom bonded to two hydrocarbon groups.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ether"],
    },
    {
      word: "ether",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ether-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75730532",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈiːθə/", audio: "" },
        {
          text: "/ˈiθɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ether-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=314397",
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To viciously humiliate or insult.",
              synonyms: [],
              antonyms: [],
              example:
                "The battle rapper ethered his opponent and caused him to slink away in shame.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ether"],
    },
  ],
  cocos: [
    {
      word: "cocos",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Coconut palm.", synonyms: [], antonyms: [] },
            {
              definition: "Coconut, the fruit of the coconut palm.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/coco",
        "https://en.wiktionary.org/wiki/cocos",
      ],
    },
  ],
  hives: [
    {
      word: "hives",
      phonetic: "/haɪvz/",
      phonetics: [{ text: "/haɪvz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Itchy, swollen, red areas of the skin which can appear quickly in response to an allergen or due to other conditions.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["nettle-rash", "urticaria"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hives"],
    },
    {
      word: "hives",
      phonetic: "/haɪvz/",
      phonetics: [{ text: "/haɪvz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A structure, whether artificial or natural, for housing a swarm of honeybees.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The bees of one hive; a swarm of bees.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A place swarming with busy occupants; a crowd.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A section of the registry.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To enter or possess a hive.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To form a hive-like entity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To collect into a hive.",
              synonyms: [],
              antonyms: [],
              example: "to hive a swarm of bees",
            },
            {
              definition: "To store in a hive or similarly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To take shelter or lodgings together; to reside in a collective body.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hive",
        "https://en.wiktionary.org/wiki/hives",
      ],
    },
  ],
  jibed: [
    {
      word: "jibed",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To reproach with contemptuous words; to deride, to mock, to taunt.",
              synonyms: ["flout"],
              antonyms: [],
            },
            {
              definition: "To say in a mocking or taunting manner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make a mocking remark or remarks; to jeer.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["flout"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To accord or agree.",
              synonyms: [],
              antonyms: [],
              example: "That explanation doesn’t jibe with the facts.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "Alternative spelling of gybe",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/jibe",
        "https://en.wiktionary.org/wiki/jibed",
      ],
    },
  ],
  mazes: [
    {
      word: "mazes",
      phonetic: "/ˈmeɪzɪz/",
      phonetics: [{ text: "/ˈmeɪzɪz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A labyrinth; a puzzle consisting of a complicated network of paths or passages, the aim of which is to find one's way.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something made up of many confused or conflicting elements; a tangle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Confusion of thought; state of bewilderment.",
              synonyms: ["perplexity", "uncertainty"],
              antonyms: [],
            },
          ],
          synonyms: ["perplexity", "uncertainty"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/maze",
        "https://en.wiktionary.org/wiki/mazes",
      ],
    },
  ],
  trios: [
    {
      word: "trios",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A group of three people or things.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A group of three musicians.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A piece of music written for three musicians.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A passage in the middle of a minuet, frequently in a different key.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any cocktail made with a spirit, a liqueur, and a creamy ingredient.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "threesome",
            "triad",
            "trine",
            "trinity",
            "triumvirate",
            "troika",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/trio",
        "https://en.wiktionary.org/wiki/trios",
      ],
    },
  ],
  sirup: [
    {
      word: "sirup",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any thick liquid that has a high sugar content and which is added to or poured over food as a flavouring.",
              synonyms: [],
              antonyms: [],
              example: "maple syrup",
            },
            {
              definition: "(by extension) Any viscous liquid.",
              synonyms: [],
              antonyms: [],
              example: "cough syrup",
            },
            {
              definition: '(shortened from "syrup of figs") A wig.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sirup",
        "https://en.wiktionary.org/wiki/syrup",
      ],
    },
  ],
  squab: [
    {
      word: "squab",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/squab-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241630",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈskwɒb/", audio: "" },
        { text: "/ˈskwɑb/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(sometimes attributive) A baby pigeon, dove, or chicken.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The meat of such a baby bird used as food.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A baby rook.", synonyms: [], antonyms: [] },
            {
              definition:
                "A thick cushion, especially a flat one covering the seat of a chair or sofa.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person of a short, fat figure.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["piper", "squeaker"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To fall plump; to strike at one dash, or with a heavy stroke.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To furnish with squabs, or cushions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To stuff thickly and sew through, the stitches being concealed by buttons, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Fat; thick; plump; bulky.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Unfledged; unfeathered.",
              synonyms: [],
              antonyms: [],
              example: "a squab pigeon",
            },
            { definition: "Clumsy.", synonyms: [], antonyms: [] },
            { definition: "Curt; abrupt.", synonyms: [], antonyms: [] },
            { definition: "Shy; coy.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "With a heavy fall; plump.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/squab"],
    },
  ],
  laths: [
    {
      word: "laths",
      phonetic: "/læθs/",
      phonetics: [
        {
          text: "/læθs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/laths-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=61043781",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A thin, narrow strip, fastened to the rafters, studs, or floor beams of a building, for the purpose of supporting a covering of tiles, plastering, etc.",
              synonyms: ["lath strap"],
              antonyms: [],
            },
          ],
          synonyms: ["lath strap"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lath",
        "https://en.wiktionary.org/wiki/laths",
      ],
    },
  ],
  leers: [
    {
      word: "leers",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A significant side glance; a glance expressive of some passion, as malignity, amorousness, etc.; a sly or lecherous look.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An arch or affected glance or cast of countenance.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To look sideways or obliquely; now especially with sexual desire or malicious intent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To entice with a leer or leers.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The cheek.", synonyms: [], antonyms: [] },
            { definition: "The face.", synonyms: [], antonyms: [] },
            {
              definition: "One's appearance; countenance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Complexion; hue; colour.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Flesh; skin.", synonyms: [], antonyms: [] },
            { definition: "The flank or loin.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To teach.", synonyms: [], antonyms: [] },
            { definition: "To learn.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A long oven or kiln, often having a moving belt, used for annealing glass.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/leer",
        "https://en.wiktionary.org/wiki/leers",
        "https://en.wiktionary.org/wiki/lehr",
      ],
    },
  ],
  pasta: [
    {
      word: "pasta",
      phonetic: "/ˈpɐːstə/",
      phonetics: [
        { text: "/ˈpɐːstə/", audio: "" },
        { text: "/ˈpæstə/", audio: "" },
        { text: "/ˈpɐːstɘ/", audio: "" },
        { text: "/ˈpæstə/", audio: "" },
        { text: "/ˈpɑstə/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Dough made from wheat and water and sometimes mixed with egg and formed into various shapes; often sold in dried form and typically boiled for eating.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A dish or serving of pasta.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A type of pasta.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pasta"],
    },
  ],
  rifts: [
    {
      word: "rifts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A chasm or fissure.",
              synonyms: [],
              antonyms: [],
              example:
                "My marriage is in trouble: the fight created a rift between us and we can't reconnect.",
            },
            {
              definition:
                "A break in the clouds, fog, mist etc., which allows light through.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A shallow place in a stream; a ford.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To form a rift; to split open.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cleave; to rive; to split.",
              synonyms: [],
              antonyms: [],
              example: "to rift an oak",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(obsolete outside Scotland and northern Britain) To belch.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rift",
        "https://en.wiktionary.org/wiki/rifts",
      ],
    },
  ],
  lopes: [
    {
      word: "lopes",
      phonetic: "/ləʊps/",
      phonetics: [
        { text: "/ləʊps/", audio: "" },
        {
          text: "/loʊps/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lopes-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=93629546",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An easy pace with long strides.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To travel an easy pace with long strides.",
              synonyms: [],
              antonyms: [],
              example:
                "He loped along, hour after hour, not fast but steady and covering much ground.",
            },
            { definition: "To jump, leap.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lope",
        "https://en.wiktionary.org/wiki/lopes",
      ],
    },
  ],
  alias: [
    {
      word: "alias",
      phonetic: "/ˈeɪ.li.əs/",
      phonetics: [{ text: "/ˈeɪ.li.əs/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Another name; an assumed name.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A second or further writ which is issued after a first writ has expired without effect.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An abbreviation that replaces a string of commands and thereby reduces typing when performing routine actions or tasks.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An spurious signal generated as a technological artifact.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["pseudonym"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To assign an additional name to an entity, often a more user-friendly one.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of two signals) to become indistinguishable",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition:
                "Otherwise; at another time; in other circumstances; otherwise called.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Used to connect the different names of a person who has gone by two or more, and whose true name is for any cause doubtful",
              synonyms: [],
              antonyms: [],
              example: "Smith, alias Simpson.",
            },
          ],
          synonyms: ["AKA"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/alias"],
    },
  ],
  whirs: [
    {
      word: "whirs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sibilant buzz or vibration; the sound of something in rapid motion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A bustle of noise and excitement.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "bustle",
            "hustle",
            "buzz",
            "drone",
            "hum",
            "purr",
            "whine",
            "whistle",
            "whizz",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/whir",
        "https://en.wiktionary.org/wiki/whirr",
        "https://en.wiktionary.org/wiki/whirs",
      ],
    },
  ],
  diced: [
    {
      word: "diced",
      phonetic: "/daɪst/",
      phonetics: [{ text: "/daɪst/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To play dice.", synonyms: [], antonyms: [] },
            {
              definition: "To cut into small cubes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To ornament with squares, diamonds, or cubes.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dice",
        "https://en.wiktionary.org/wiki/diced",
      ],
    },
  ],
  slags: [
    {
      word: "slags",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Waste material from a coal mine",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Scum that forms on the surface of molten metal",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Impurities formed and separated out when a metal is smelted from ore; vitrified cinders",
              synonyms: ["dross", "recrement", "scoria"],
              antonyms: [],
            },
            {
              definition:
                "Hard aggregate remaining as a residue from blast furnaces, sometimes used as a surfacing material",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Scoria associated with a volcano",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A coward", synonyms: [], antonyms: [] },
            {
              definition: "(chiefly Cockney) a contemptible person, a scumbag",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A prostitute", synonyms: [], antonyms: [] },
            {
              definition:
                "A woman (sometimes a man) who has loose morals relating to sex; a slut",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dross", "recrement", "scoria"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To produce slag", synonyms: [], antonyms: [] },
            {
              definition:
                "To become slag; to agglomerate when heated below the fusion point",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To reduce to slag", synonyms: [], antonyms: [] },
            {
              definition:
                '(sometimes with "off") to talk badly about; to malign or denigrate (someone)',
              synonyms: [],
              antonyms: [],
            },
            { definition: "To spit", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/slag",
        "https://en.wiktionary.org/wiki/slags",
      ],
    },
  ],
  lodes: [
    {
      word: "lodes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A way or path; a road.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A watercourse.", synonyms: [], antonyms: [] },
            {
              definition:
                "A vein of metallic ore that lies within definite boundaries, or within a fissure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) A rich source of supply.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lode",
        "https://en.wiktionary.org/wiki/lodes",
      ],
    },
  ],
  foxed: [
    {
      word: "foxed",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To trick, fool or outwit (someone) by cunning or ingenuity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To confuse or baffle (someone).",
              synonyms: [],
              antonyms: [],
              example: "This crossword puzzle has completely foxed me.",
            },
            {
              definition: "To act slyly or craftily.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To discolour paper. Fox marks are spots on paper caused by humidity. (See foxing.)",
              synonyms: [],
              antonyms: [],
              example: "The pages of the book show distinct foxing.",
            },
            {
              definition: "To make sour, as beer, by causing it to ferment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To turn sour; said of beer, etc., when it sours in fermenting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To intoxicate; to stupefy with drink.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To repair (boots) with new front upper leather, or to piece the upper fronts of.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of paper, having yellowish-brown stains.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fox",
        "https://en.wiktionary.org/wiki/foxed",
      ],
    },
  ],
  idled: [
    {
      word: "idled",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To spend in idleness; to waste; to consume.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To lose or spend time doing nothing, or without being employed in business.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of an engine: to run at a slow speed, or out of gear; to tick over.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/idle",
        "https://en.wiktionary.org/wiki/idled",
      ],
    },
  ],
  prows: [
    {
      word: "prows",
      phonetic: "/pɹaʊz/",
      phonetics: [{ text: "/pɹaʊz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sailing vessel found in the waters of Micronesia and Indonesia; it has a single, large outrigger and a triangular sail.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The front part of a vessel",
              synonyms: ["beak", "bow", "forestem", "prore", "stem"],
              antonyms: [],
            },
            { definition: "A vessel", synonyms: [], antonyms: [] },
          ],
          synonyms: ["beak", "bow", "forestem", "prore", "stem"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/proa",
        "https://en.wiktionary.org/wiki/prow",
        "https://en.wiktionary.org/wiki/prows",
      ],
    },
  ],
  plait: [
    {
      word: "plait",
      phonetic: "/pleɪt/",
      phonetics: [
        {
          text: "/pleɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/plait-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89013032",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A flat fold; a doubling, as of cloth; a pleat.",
              synonyms: [],
              antonyms: [],
              example: "a box plait",
            },
            {
              definition: "A braid, as of hair or straw; a plat.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To fold; to double in narrow folds; to pleat",
              synonyms: [],
              antonyms: [],
              example: "to plait a ruffle",
            },
            {
              definition: "To interweave the strands or locks of; to braid",
              synonyms: [],
              antonyms: [],
              example: "plaiting rope",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/plait"],
    },
  ],
  malts: [
    {
      word: "malts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Malted grain (sprouted grain) (usually barley), used in brewing and otherwise.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Malt liquor, especially malt whisky.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A milkshake with malted milk powder added for flavor.",
              synonyms: ["malted"],
              antonyms: [],
            },
            {
              definition: "Maltose-rich sugar derived from malted grain.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["malted"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/malt",
        "https://en.wiktionary.org/wiki/malts",
      ],
    },
  ],
  chafe: [
    {
      word: "chafe",
      phonetic: "/tʃeɪf/",
      phonetics: [
        {
          text: "/tʃeɪf/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chafe-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50422604",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Heat excited by friction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Injury or wear caused by friction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Vexation; irritation of mind; rage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An expression of opinionated conflict.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To excite heat in by friction; to rub in order to stimulate and make warm.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To excite passion or anger in; to fret; to irritate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fret and wear by rubbing.",
              synonyms: [],
              antonyms: [],
              example: "to chafe a cable",
            },
            {
              definition:
                "To rub; to come together so as to wear by rubbing; to wear by friction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be worn by rubbing.",
              synonyms: [],
              antonyms: [],
              example: "A cable chafes.",
            },
            {
              definition:
                "To have a feeling of vexation; to be vexed; to fret; to be irritated.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chafe"],
    },
  ],
  cower: [
    {
      word: "cower",
      phonetic: "/ˈkaʊə/",
      phonetics: [
        { text: "/ˈkaʊə/", audio: "" },
        { text: "/ˈkaʊɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To crouch or cringe, or to avoid or shy away from something, in fear.",
              synonyms: [],
              antonyms: [],
              example:
                "He'd be useless in war. He'd just cower in his bunker until the enemy came in and shot him, or until the war was over.",
            },
            { definition: "To crouch in general.", synonyms: [], antonyms: [] },
            {
              definition: "To cause to cower; to frighten into submission.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cower"],
    },
    {
      word: "cower",
      phonetic: "/ˈkaʊə/",
      phonetics: [
        { text: "/ˈkaʊə/", audio: "" },
        { text: "/ˈkaʊɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To cherish with care.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cower"],
    },
  ],
  toyed: [
    {
      word: "toyed",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To play (with) in an idle or desultory way.",
              synonyms: [],
              antonyms: [],
              example: "Figo is toying with the English defence.",
            },
            {
              definition: "To ponder or consider.",
              synonyms: [],
              antonyms: [],
              example:
                "I have been toying with the idea of starting my own business.",
            },
            {
              definition: "To stimulate with a sex toy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/toy",
        "https://en.wiktionary.org/wiki/toyed",
      ],
    },
  ],
  chefs: [
    {
      word: "chefs",
      phonetic: "/ʃɛfs/",
      phonetics: [{ text: "/ʃɛfs/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The presiding cook in the kitchen of a large household.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The head cook of a restaurant or other establishment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any cook.",
              synonyms: [],
              antonyms: [],
              example:
                "Kiss the chef. (slogan on aprons used by home barbecue enthusiasts)",
            },
            {
              definition: "One who manufactures illegal drugs; a cook.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A reliquary in the shape of a head.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["magirist", "magirologist"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/chef",
        "https://en.wiktionary.org/wiki/chefs",
      ],
    },
  ],
  keels: [
    {
      word: "keels",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To mark with ruddle.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To put to death; to extinguish the life of.",
              synonyms: [],
              antonyms: [],
              example:
                "Smoking kills more people each year than alcohol and drugs combined.",
            },
            {
              definition: "To render inoperative.",
              synonyms: [],
              antonyms: [],
              example:
                "He killed the engine and turned off the headlights, but remained in the car, waiting.",
            },
            {
              definition: "To stop, cease or render void; to terminate.",
              synonyms: [],
              antonyms: [],
              example:
                "My computer wouldn't respond until I killed some of the running processes.",
            },
            {
              definition: "To amaze, exceed, stun or otherwise incapacitate.",
              synonyms: [],
              antonyms: [],
              example: "That joke always kills me.",
            },
            {
              definition: "To cause great pain, discomfort or distress to.",
              synonyms: [],
              antonyms: [],
              example: "These tight shoes are killing my feet.",
            },
            {
              definition:
                "To produce feelings of dissatisfaction or revulsion in.",
              synonyms: [],
              antonyms: [],
              example:
                "It kills me to learn how many poor people are practically starving in this country while rich moguls spend such outrageous amounts on useless luxuries.",
            },
            {
              definition: "To use up or to waste.",
              synonyms: [],
              antonyms: [],
              example:
                'He told the bartender, pointing at the bottle of scotch he planned to consume, "Leave it, I\'m going to kill the bottle."',
            },
            {
              definition: "To exert an overwhelming effect on.",
              synonyms: [],
              antonyms: [],
              example:
                "Between the two of us, we killed the rest of the case of beer.",
            },
            {
              definition: "To overpower, overwhelm or defeat.",
              synonyms: [],
              antonyms: [],
              example:
                "The team had absolutely killed their traditional rivals, and the local sports bars were raucous with celebrations.",
            },
            {
              definition: "To force a company out of business.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To produce intense pain.",
              synonyms: [],
              antonyms: [],
              example:
                "You don't ever want to get rabies. The doctor will have to give you multiple shots and they really kill.",
            },
            {
              definition: "To punish severely.",
              synonyms: [],
              antonyms: [],
              example: "My parents are going to kill me!",
            },
            {
              definition:
                "To strike (a ball, etc.) with such force and placement as to make a shot that is impossible to defend against, usually winning a point.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause (a ball, etc.) to be out of play, resulting in a stoppage of gameplay.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To succeed with an audience, especially in comedy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause to assume the value zero.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(IRC) To disconnect (a user) involuntarily from the network.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To deadmelt.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "annihilate",
            "assassinate",
            "bump off",
            "dispatch",
            "ice",
            "knock off",
            "liquidate",
            "murder",
            "rub out",
            "slaughter",
            "slay",
            "top",
            "whack",
            "break",
            "deactivate",
            "disable",
            "turn off",
            "fritter away",
            "while away",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/keel",
        "https://en.wiktionary.org/wiki/keels",
        "https://en.wiktionary.org/wiki/kill",
      ],
    },
  ],
  sties: [
    {
      word: "sties",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A pen or enclosure for swine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A messy, dirty or debauched place.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["pigpen", "pigsty", "hovel", "pigsty"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To place in, or as if in, a sty",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To live in a sty, or any messy or dirty place",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A ladder.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To ascend, rise up, climb.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An inflammation of the eyelid.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sties",
        "https://en.wiktionary.org/wiki/sty",
      ],
    },
  ],
  racer: [
    {
      word: "racer",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/racer-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79293795",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈɹeɪsə/", audio: "" },
        { text: "/ˈɹeɪsəɹ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Someone who takes part in a race.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A racehorse.", synonyms: [], antonyms: [] },
            {
              definition:
                "An animal known for its fast speed, or suitable for racing; applied especially to a number of North American snakes, certain kinds of lake trout, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A vehicle or other device used for racing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any of several genera of colubrid snakes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A video game involving racing of any kind.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/racer"],
    },
  ],
  etude: [
    {
      word: "etude",
      phonetic: "/eɪˈtjuːd/",
      phonetics: [
        { text: "/eɪˈtjuːd/", audio: "" },
        {
          text: "/ˈeɪˌtjud/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/etude-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=892493",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A short piece of music, designed to give a performer practice in a particular area or skill.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["study"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/etude"],
    },
  ],
  sucks: [
    {
      word: "sucks",
      phonetic: "/sʌks/",
      phonetics: [
        { text: "/sʌks/", audio: "" },
        { text: "/sʌks/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An instance of drawing something into one's mouth by inhaling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Milk drawn from the breast.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A weak, self-pitying person; a person who refuses to go along with others, especially out of spite; a crybaby or sore loser.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sycophant, especially a child.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A short drink, especially a dram of spirits.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An act of fellatio.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["sook", "sooky baby"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To use the mouth and lips to pull in (a liquid, especially milk from the breast).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To perform such an action; to feed from a breast or teat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To put the mouth or lips to (a breast, a mother etc.) to draw in milk.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To extract, draw in (a substance) from or out of something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To work the lips and tongue on (an object) to extract moisture or nourishment; to absorb (something) in the mouth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To pull (something) in a given direction, especially without direct contact.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To perform fellatio.", synonyms: [], antonyms: [] },
            {
              definition:
                "To be inferior or objectionable: a general term of disparagement, sometimes used with at to indicate a particular area of deficiency.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["attract", "blow", "draw"],
          antonyms: ["rock", "rule", "blow"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/suck",
        "https://en.wiktionary.org/wiki/sucks",
      ],
    },
  ],
  sulks: [
    {
      word: "sulks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A state of sulking.",
              synonyms: [],
              antonyms: [],
              example: "Leo has been in a sulk all morning.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To express ill humor or offence by remaining sullenly silent or withdrawn.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A furrow.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sulk",
        "https://en.wiktionary.org/wiki/sulks",
      ],
    },
  ],
  micas: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  czars: [
    {
      word: "czars",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An appointed official tasked to regulate or oversee a specific area.",
              synonyms: [],
              antonyms: [],
              example: "drug czar",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An emperor of Russia (1547 to 1917) and of some South Slavic states.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person with great power; an autocrat.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/czar",
        "https://en.wiktionary.org/wiki/czars",
        "https://en.wiktionary.org/wiki/tsar",
      ],
    },
  ],
  copse: [
    {
      word: "copse",
      phonetic: "/kɒps/",
      phonetics: [{ text: "/kɒps/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A thicket of small trees or shrubs.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["coppice"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To trim or cut.", synonyms: [], antonyms: [] },
            {
              definition: "To plant and preserve.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/copse"],
    },
  ],
  ailed: [
    {
      word: "ailed",
      phonetic: "/eɪld/",
      phonetics: [{ text: "/eɪld/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cause to suffer; to trouble, afflict. (Now chiefly in interrogative or indefinite constructions.)",
              synonyms: [],
              antonyms: [],
              example: "Have some chicken soup. It's good for what ails you.",
            },
            {
              definition: "To be ill; to suffer; to be troubled.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ail",
        "https://en.wiktionary.org/wiki/ailed",
      ],
    },
  ],
  abler: [
    {
      word: "abler",
      phonetic: "/eɪ.blɚ/",
      phonetics: [{ text: "/eɪ.blɚ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Easy to use.", synonyms: [], antonyms: [] },
            { definition: "Suitable; competent.", synonyms: [], antonyms: [] },
            { definition: "Liable to.", synonyms: [], antonyms: [] },
            {
              definition:
                "Having the necessary powers or the needed resources to accomplish a task.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Free from constraints preventing completion of task; permitted to; not prevented from.",
              synonyms: [],
              antonyms: [],
              example: "I’ll see you as soon as I’m able.",
            },
            {
              definition: "Having the physical strength; robust; healthy.",
              synonyms: [],
              antonyms: [],
              example:
                "After the past week of forced marches, only half the men are fully able.",
            },
            {
              definition: "Rich; well-to-do.",
              synonyms: [],
              antonyms: [],
              example: "He was born to an able family.",
            },
            {
              definition:
                "Gifted with skill, intelligence, knowledge, or competence.",
              synonyms: [],
              antonyms: [],
              example: "The chairman was also an able sailor.",
            },
            {
              definition: "Legally qualified or competent.",
              synonyms: [],
              antonyms: [],
              example: "He is able to practice law in six states.",
            },
            {
              definition:
                "Capable of performing all the requisite duties; as an able seaman.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/able",
        "https://en.wiktionary.org/wiki/abler",
      ],
    },
  ],
  rabid: [
    {
      word: "rabid",
      phonetic: "/ˈɹeɪbɪd/",
      phonetics: [
        { text: "/ˈɹeɪbɪd/", audio: "" },
        { text: "/ˈɹæbɪd/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A human or animal infected with rabies.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Someone who is fanatical in opinion.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Affected with rabies.", synonyms: [], antonyms: [] },
            {
              definition: "Of or pertaining to rabies, or hydrophobia.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Furious; raging; extremely violent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Very extreme, unreasonable, or fanatical in opinion; excessively zealous.",
              synonyms: [],
              antonyms: [],
              example: "a rabid socialist",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rabid"],
    },
  ],
  golds: [
    {
      word: "golds",
      phonetic: "/ɡɔʊldz/",
      phonetics: [
        {
          text: "/ɡɔʊldz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/golds-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80949292",
        },
        { text: "/ɡoʊldz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A heavy yellow elemental metal of great value, with atomic number 79 and symbol Au.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A coin or coinage made of this material, or supposedly so.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A deep yellow colour, resembling the metal gold.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The bullseye of an archery target.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A gold medal.",
              synonyms: [],
              antonyms: [],
              example: "France has won three golds and five silvers.",
            },
            {
              definition: "Anything or anyone that is very valuable.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in the plural) A grill (jewellery worn on front teeth) made of gold.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["E175", "food colouring"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To pyrolyze or burn food until the color begins to change to a light brown, but not as dark as browning",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gold",
        "https://en.wiktionary.org/wiki/golds",
      ],
    },
  ],
  croup: [
    {
      word: "croup",
      phonetic: "/kɹuːp/",
      phonetics: [{ text: "/kɹuːp/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An infectious illness of the larynx, especially in young children, causing respiratory difficulty.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(obsolete outside dialectal) To croak, make a hoarse noise.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/croup"],
    },
  ],
  snaky: [
    {
      word: "snaky",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Resembling or relating to snakes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Windy; winding; twisty; sinuous, wavy.",
              synonyms: [],
              antonyms: [],
              example:
                "Walking through the snaky passages I was soon completely lost.",
            },
            {
              definition: "Sly; cunning; deceitful.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Covered with serpents; having serpents.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/snaky"],
    },
  ],
  visas: [
    {
      word: "visas",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A permit to enter and leave a country, normally issued by the authorities of the country to be visited.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To endorse (a passport, etc.).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/visa",
        "https://en.wiktionary.org/wiki/visas",
      ],
    },
  ],
  palls: [
    {
      word: "palls",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Senses relating to cloth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Senses relating to clothing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cloak or cover with, or as if with, a pall.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make vapid or insipid; to make lifeless or spiritless; to dull, to weaken.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To become dull, insipid, tasteless, or vapid; to lose life, spirit, strength, or taste.",
              synonyms: [],
              antonyms: [],
              example: "The liquor palls.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A feeling of nausea caused by disgust or overindulgence.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pall",
        "https://en.wiktionary.org/wiki/palls",
      ],
    },
  ],
  mopes: [
    {
      word: "mopes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To carry oneself in a depressed, lackadaisical manner; to give oneself up to low spirits; to pout, sulk.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make spiritless and stupid.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mope",
        "https://en.wiktionary.org/wiki/mopes",
      ],
    },
  ],
  boned: [
    {
      word: "boned",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/boned-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79294183",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/bəʊnd/", audio: "" },
        { text: "/boʊnd/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To prepare (meat, etc) by removing the bone or bones from.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fertilize with bone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To put whalebone into.",
              synonyms: [],
              antonyms: [],
              example: "to bone stays",
            },
            {
              definition:
                "To make level, using a particular procedure; to survey a level line.",
              synonyms: [],
              antonyms: [],
              example: "boning rod",
            },
            {
              definition: "(usually of a man) To have sexual intercourse with.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                '(in Aboriginal culture) To perform "bone pointing", a ritual that is intended to bring illness or even death to the victim.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: '(usually with "up") To study.',
              synonyms: [],
              antonyms: [],
              example: "bone up",
            },
            {
              definition: "To polish boots to a shiny finish.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "debone",
            "unbone",
            "bonk",
            "bury the bone",
            "fuck",
            "screw",
            "shag",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To apprehend, steal.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To sight along an object or set of objects to check whether they are level or in line.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "(in combination) Having some specific type of bone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of computer-generated animations: based on models with simulated bones or joints.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Beset with unfortunate circumstances that seem difficult or impossible to overcome; in imminent danger.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Broken.", synonyms: [], antonyms: [] },
            {
              definition:
                "Of meat or fish, having had the bones removed before cooking.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of a garment such as a corset or basque, fitted with bones.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Having the legs straightened during a trick",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bone",
        "https://en.wiktionary.org/wiki/boned",
      ],
    },
  ],
  wispy: [
    {
      word: "wispy",
      phonetic: "/ˈwɪspi/",
      phonetics: [
        {
          text: "/ˈwɪspi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wispy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=825532",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Consisting of or resembling a wisp; like a slender, flexible strand or bundle.",
              synonyms: [],
              antonyms: [],
              example: "The wispy smoke rose from chimneys all around town.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/wispy"],
    },
  ],
  raved: [
    {
      word: "raved",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To wander in mind or intellect; to be delirious; to talk or act irrationally; to be wild, furious, or raging.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To speak or write wildly or incoherently.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To talk with unreasonable enthusiasm or excessive passion or excitement; followed by about, of, or (formerly) on.",
              synonyms: [],
              antonyms: [],
              example: "He raved about her beauty.",
            },
            {
              definition: "To rush wildly or furiously.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To attend a rave (dance party).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rave",
        "https://en.wiktionary.org/wiki/raved",
      ],
    },
  ],
  swaps: [
    {
      word: "swaps",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To exchange or give (something) in an exchange (for something else).",
              synonyms: ["exchange", "switch", "trade"],
              antonyms: [],
            },
            { definition: "To hit, to strike.", synonyms: [], antonyms: [] },
            {
              definition:
                "To beat the air, or ply the wings, with a sweeping motion or noise; to flap.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To descend or fall; to rush hastily or violently.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "flap",
            "interchange",
            "switch",
            "bang",
            "knock",
            "tap",
            "fly",
            "speed",
            "zoom",
            "exchange",
            "switch",
            "trade",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An exchange of two comparable things.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A financial derivative in which two parties agree to exchange one stream of cashflow against another stream.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Space available in a swap file for use as auxiliary memory.",
              synonyms: [],
              antonyms: [],
              example: "How much swap do you need?",
            },
          ],
          synonyms: ["barter", "quid pro quo", "trade"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A blow; a stroke.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/swap",
        "https://en.wiktionary.org/wiki/swaps",
      ],
    },
  ],
  junky: [
    {
      word: "junky",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/junky-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241565",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A narcotics addict, especially a heroin user.",
              synonyms: ["drug addict"],
              antonyms: [],
            },
            {
              definition: "(by extension) An enthusiast of something.",
              synonyms: ["aficionado", "enthusiast"],
              antonyms: [],
              example:
                "English people are travel junkies, but Americans hardly ever leave their state.",
            },
          ],
          synonyms: ["aficionado", "enthusiast", "drug addict"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Resembling or characteristic of junk; cheap, worthless, or of low quality.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/junkie",
        "https://en.wiktionary.org/wiki/junky",
      ],
    },
  ],
  doily: [
    {
      word: "doily",
      phonetic: "/ˈdɔɪli/",
      phonetics: [{ text: "/ˈdɔɪli/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small ornamental piece of lace or linen or paper used to protect a surface from scratches by hard objects such as vases or bowls; or to decorate a plate of food.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An old kind of woollen material.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/doily"],
    },
  ],
  pawns: [
    {
      word: "pawns",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A psychoactive preparation of betel leaf combined with areca nut and/or cured tobacco, chewed recreationally in Asia; such a preparation served wrapped in the leaf.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The most common chess piece, or a similar piece in a similar game. In chess each side has eight; moves are only forward, and attacks are only forward diagonally or en passant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Someone who is being manipulated or used to some end.",
              synonyms: [],
              antonyms: [],
              example:
                "Though a pawn of the gods, her departure is the precipitating cause of the Trojan War.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The state of being held as security for a loan, or as a pledge.",
              synonyms: [],
              antonyms: [],
              example: "All our jewellery was in pawn by this stage.",
            },
            {
              definition: "An instance of pawning something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An item given as security on a loan, or as a pledge.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A pawnshop; pawnbroker.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To pledge; to stake or wager.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To give as security on a loan of money; especially, to deposit (something) at a pawn shop.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["hock"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A gallery.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(originally leet) To own, to defeat or dominate (someone or something, especially a game or someone playing a game).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/paan",
        "https://en.wiktionary.org/wiki/pawn",
        "https://en.wiktionary.org/wiki/pawns",
        "https://en.wiktionary.org/wiki/pwn",
      ],
    },
  ],
  tamer: [
    {
      word: "tamer",
      phonetic: "/ˈteɪmɚ/",
      phonetics: [{ text: "/ˈteɪmɚ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Not or no longer wild; domesticated",
              synonyms: [],
              antonyms: ["wild"],
              example: "They have a tame wildcat.",
            },
            {
              definition:
                "(chiefly of animals) Mild and well-behaved; accustomed to human contact",
              synonyms: ["gentle"],
              antonyms: [],
              example: "The lion was quite tame.",
            },
            {
              definition: "Not exciting.",
              synonyms: ["dull", "flat", "insipid", "unexciting"],
              antonyms: ["exciting"],
              example: "For a thriller, that film was really tame.",
            },
            {
              definition: "Crushed; subdued; depressed; spiritless.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a knot) Capable of being represented as a finite closed polygonal chain.",
              synonyms: [],
              antonyms: ["wild"],
            },
          ],
          synonyms: ["dull", "flat", "insipid", "unexciting", "gentle"],
          antonyms: ["exciting", "wild", "wild"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One who tames or subdues.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tame",
        "https://en.wiktionary.org/wiki/tamer",
      ],
    },
  ],
  poach: [
    {
      word: "poach",
      phonetic: "/ˈpoʊtʃ/",
      phonetics: [{ text: "/ˈpoʊtʃ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act of cooking in simmering liquid.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cook something in simmering liquid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be cooked in simmering liquid",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To become soft or muddy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make soft or muddy.",
              synonyms: [],
              antonyms: [],
              example:
                "Cattle coming to drink had punched and poached the river bank into a mess of mud.",
            },
            {
              definition: "To stab; to pierce; to spear, as fish.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To force, drive, or plunge into anything.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To begin and not complete.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/poach"],
    },
    {
      word: "poach",
      phonetic: "/ˈpoʊtʃ/",
      phonetics: [{ text: "/ˈpoʊtʃ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The act of taking something unfairly, as in tennis doubles where one player returns a shot that their partner was better placed to return.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To take game or fish illegally.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To take anything illegally or unfairly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To entice (an employee or customer) to switch from a competing company to one's own.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/poach"],
    },
  ],
  baits: [
    {
      word: "baits",
      phonetic: "/beɪts/",
      phonetics: [{ text: "/beɪts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any substance, especially food, used in catching fish, or other animals, by alluring them to a hook, snare, trap, or net.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Food containing poison or a harmful additive to kill animals that are pests.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Anything which allures; something used to lure or entice someone or something into doing something",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A portion of food or drink, as a refreshment taken on a journey; also, a stop for rest and refreshment.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To attract with bait; to entice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To affix bait to a trap or a fishing hook or fishing line.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To set dogs on (an animal etc.) to bite or worry; to attack with dogs, especially for sport.",
              synonyms: [],
              antonyms: [],
              example: "to bait a bear with dogs;  to bait a bull",
            },
            {
              definition:
                "To intentionally annoy, torment, or threaten by constant rebukes or threats; to harass.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To feed and water (a horse or other animal), especially during a journey.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a horse or other animal) To take food, especially during a journey.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a person) To stop to take a portion of food and drink for refreshment during a journey.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To flap the wings; to flutter as if to fly; or to hover, as a hawk when she stoops to her prey.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bait",
        "https://en.wiktionary.org/wiki/baits",
      ],
    },
  ],
  damns: [
    {
      word: "damns",
      phonetic: "/dæmz/",
      phonetics: [{ text: "/dæmz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: 'The use of "damn" as a curse.',
              synonyms: [],
              antonyms: [],
              example: "He said a few damns and left.",
            },
            {
              definition:
                "A small, negligible quantity, being of little value; a whit or jot.",
              synonyms: [],
              antonyms: [],
              example: "The new hires aren't worth a damn.",
            },
            {
              definition: "The smallest amount of concern or consideration.",
              synonyms: [],
              antonyms: [],
              example: "I don't give a damn.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To condemn to hell.",
              synonyms: [],
              antonyms: [],
              example: "I damn you eternally, fiend!",
            },
            {
              definition:
                "To condemn; to declare guilty; to doom; to adjudge to punishment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To put out of favor; to ruin; to label negatively.",
              synonyms: [],
              antonyms: [],
              example:
                "I’m afraid that if I speak out on this, I’ll be damned as a troublemaker.",
            },
            {
              definition:
                "To condemn as unfit, harmful, invalid, immoral or illegal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To curse; put a curse upon.",
              synonyms: [],
              antonyms: [],
              example: "That man stole my wallet. Damn him!",
            },
            {
              definition: "To invoke damnation; to curse.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/damn",
        "https://en.wiktionary.org/wiki/damns",
      ],
    },
  ],
  gumbo: [
    {
      word: "gumbo",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A soup or stew made with okra.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A fine silty soil that when wet becomes very thick and heavy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The edible immature mucilaginous seed pod (properly, capsule) of the Abelmoschus esculentus.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The flowering mallow plant Abelmoschus esculentus itself, now commonly grown in the tropics and warmer parts of the temperate zones.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["ladies' fingers", "bhindi", "gumbo", "ladyfinger"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gumbo",
        "https://en.wiktionary.org/wiki/okra",
      ],
    },
  ],
  daunt: [
    {
      word: "daunt",
      phonetic: "/dɔːnt/",
      phonetics: [
        { text: "/dɔːnt/", audio: "" },
        { text: "/dɔnt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To discourage, intimidate.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To overwhelm.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/daunt"],
    },
  ],
  prank: [
    {
      word: "prank",
      phonetic: "/pɹæŋk/",
      phonetics: [
        {
          text: "/pɹæŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/prank-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241597",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A practical joke or mischievous trick.",
              synonyms: [],
              antonyms: [],
              example: "He pulled a gruesome prank on his sister.",
            },
            {
              definition:
                "An evil deed; a malicious trick, an act of cruel deception.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To perform a practical joke on; to trick.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To call someone's phone and promptly hang up",
              synonyms: [],
              antonyms: [],
              example: "Hey man, prank me when you wanna get picked up.",
            },
            {
              definition:
                "To adorn in a showy manner; to dress or equip ostentatiously.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make ostentatious show.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Full of gambols or tricks.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/prank"],
    },
  ],
  hunks: [
    {
      word: "hunks",
      phonetic: "/hʌŋks/",
      phonetics: [
        {
          text: "/hʌŋks/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hunks-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79294411",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A crotchety or surly person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A stingy man; a miser.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hunks"],
    },
    {
      word: "hunks",
      phonetic: "/hʌŋks/",
      phonetics: [
        {
          text: "/hʌŋks/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hunks-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79294411",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A large or dense piece of something.",
              synonyms: [],
              antonyms: [],
              example: "a hunk of metal",
            },
            {
              definition:
                "A sexually attractive boy or man, especially one who is muscular.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A record of differences between almost contiguous portions of two files (or other sources of information). Differences that are widely separated by areas which are identical in both files would not be part of a single hunk. Differences that are separated by small regions which are identical in both files may comprise a single hunk. Patches are made up of hunks.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A honyock.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["chunk", "lump", "piece", "beefcake"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hunk",
        "https://en.wiktionary.org/wiki/hunks",
      ],
    },
  ],
  buxom: [
    {
      word: "buxom",
      phonetic: "/ˈbʌksəm/",
      phonetics: [
        {
          text: "/ˈbʌksəm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/buxom-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75729197",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈbʌksəm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/buxom-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=11219705",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Pliant, obedient, tractable (to) (i.e. easily moved or bent, morally).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Submissive, humble, meek (as subsense of 4).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Gracious, indulgent, favourable; obliging, amiable, courteous, affable, kindly (as subsense of 1).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "With infinitive: Easily moved, prone, ready (as subsense of 1).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Flexible, pliant (arising from sense 1).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Blithe, gladsome, bright, lively, gay (attested after 1).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a man or woman) Cheerful, happy (possibly as subsense of 6).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(chiefly of women) Full of health, vigour, and good temper; well-favoured, plump and comely, 'jolly', comfortable-looking (in person). (arising from sense 6).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a woman) Having a full, voluptuous figure, especially possessing large breasts (as subsense of 8).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "big-breasted",
            "bosomy",
            "busty",
            "chesty",
            "curvaceous",
            "curvy",
            "full-throated",
            "round",
            "shapely",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/buxom"],
    },
  ],
  heres: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  honks: [
    {
      word: "honks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The sound produced by a typical car horn.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The cry of a goose.", synonyms: [], antonyms: [] },
            { definition: "A bad smell.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To use a car horn.",
              synonyms: [],
              antonyms: [],
              example:
                "They stood and observed how long it took for the other cars to honk.",
            },
            {
              definition: "To make a loud, harsh sound like a car horn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make the vocal sound of a goose.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To vomit: regurgitate the contents of one's stomach.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To have a bad smell.", synonyms: [], antonyms: [] },
            {
              definition: "To squeeze playfully, usually a breast or nose.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(racial slur) A Caucasian person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A factory hand or general unskilled worker.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/honk",
        "https://en.wiktionary.org/wiki/honks",
        "https://en.wiktionary.org/wiki/honky",
      ],
    },
  ],
  stows: [
    {
      word: "stows",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To put something away in a compact and tidy manner, in its proper place, or in a suitable place.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To store or pack something in a space-saving manner and over a long time.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To arrange, pack, or fill something tightly or closely.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To dispose, lodge, or hide somebody somewhere.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/stow",
        "https://en.wiktionary.org/wiki/stows",
      ],
    },
  ],
  unbar: [
    {
      word: "unbar",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To remove an impediment that obstructs the passage of (someone or something).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To remove a prohibition.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To unlock or unbolt a door that had been locked or bolted with a bar.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/unbar"],
    },
  ],
  idles: [
    {
      word: "idles",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To spend in idleness; to waste; to consume.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To lose or spend time doing nothing, or without being employed in business.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of an engine: to run at a slow speed, or out of gear; to tick over.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/idle",
        "https://en.wiktionary.org/wiki/idles",
      ],
    },
  ],
  routs: [
    {
      word: "routs",
      phonetic: "[ɹʌʊts]",
      phonetics: [{ text: "[ɹʌʊts]", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A noise, especially a loud one",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A disturbance; tumult.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Snoring.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make a noise; roar; bellow; snort.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To snore, especially loudly.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To belch.", synonyms: [], antonyms: [] },
            {
              definition: "To howl as the wind; make a roaring noise.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A violent movement; a great or violent stir; a heavy blow; a stunning blow; a stroke.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To beat; strike; assail with blows.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A troop or group, especially of a traveling company or throng.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A disorderly and tumultuous crowd; a mob; hence, the rabble; the herd of common people.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The state of being disorganized and thrown into confusion, especially when retreating from a fight.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The act of defeating and breaking up an army or another opponent.",
              synonyms: [],
              antonyms: [],
              example: "The rout of the enemy was complete.",
            },
            {
              definition:
                "A disturbance of the peace by persons assembled together with the intent to do a thing which, if executed, would make them rioters, and actually making a motion toward the executing thereof.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A fashionable assembly, or large evening party.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To defeat completely, forcing into disorderly retreat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To retreat from a confrontation in disorder.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To assemble in a crowd, whether orderly or disorderly; to collect in company.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To search or root in the ground, like a pig.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To scoop out with a gouge or other tool; to furrow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To use a router in woodworking.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The brent goose.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rout",
        "https://en.wiktionary.org/wiki/routs",
      ],
    },
  ],
  sages: [
    {
      word: "sages",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A wise person or spiritual teacher; someone of gravity and wisdom, especially, a teacher venerable for years, and of sound judgment and prudence; a grave or stoic philosopher.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sage",
        "https://en.wiktionary.org/wiki/sages",
      ],
    },
  ],
  goads: [
    {
      word: "goads",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A long, pointed stick used to prod animals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "That which goads or incites; a stimulus.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To prod with a goad.", synonyms: [], antonyms: [] },
            {
              definition: "To encourage or stimulate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To incite or provoke.",
              synonyms: [],
              antonyms: [],
              example: "goading a boy to fight",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/goad",
        "https://en.wiktionary.org/wiki/goads",
      ],
    },
  ],
  remit: [
    {
      word: "remit",
      phonetic: "/ɹɪˈmɪt/",
      phonetics: [
        { text: "/ɹɪˈmɪt/", audio: "" },
        { text: "/ɹiˈmɪt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Terms of reference; set of responsibilities; scope.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A communication from a superior court to a subordinate court.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["brief", "responsibility"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To transmit or send (e.g. money in payment); to supply.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To forgive, pardon (a wrong, offence, etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To refrain from exacting or enforcing.",
              synonyms: [],
              antonyms: [],
              example: "to remit the performance of an obligation",
            },
            {
              definition: "To give up; omit; cease doing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To allow (something) to slacken, to relax (one's attention etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To show a lessening or abatement (of a specified quality).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To diminish, abate.", synonyms: [], antonyms: [] },
            {
              definition:
                "To refer (something or someone) for deliberation, judgment, etc. (to a particular body or person).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To send back.", synonyms: [], antonyms: [] },
            {
              definition: "To give or deliver up; surrender; resign.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To restore or replace.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To postpone.", synonyms: [], antonyms: [] },
            {
              definition:
                "To refer (someone to something), direct someone's attention to something.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/remit"],
    },
  ],
  copes: [
    {
      word: "copes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To deal effectively with something, especially if difficult.",
              synonyms: [],
              antonyms: [],
              example:
                "I thought I would never be able to cope with life after the amputation, but I have learned how to be happy again.",
            },
            {
              definition: "To cut and form a mitred joint in wood or metal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To clip the beak or talons of a bird.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["contend", "hold it together"],
          antonyms: ["lose one's shit"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A long, loose cloak worn by a priest, deacon, or bishop when presiding over a ceremony other than the Mass.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any covering such as a canopy or a mantle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The vault or canopy of the skies, heavens etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A covering piece on top of a wall exposed to the weather, usually made of metal, masonry, or stone, and sloped to carry off water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(foundry) The top part of a sand casting mold.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An ancient tribute due to the lord of the soil, out of the lead mines in Derbyshire, England.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cover (a joint or structure) with coping.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To form a cope or arch; to bend or arch; to bow.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To bargain for; to buy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To exchange or barter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make return for; to requite; to repay.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To match oneself against; to meet; to encounter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To encounter; to meet; to have to do with.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cope",
        "https://en.wiktionary.org/wiki/copes",
      ],
    },
  ],
  deign: [
    {
      word: "deign",
      phonetic: "/deɪn/",
      phonetics: [{ text: "/deɪn/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To condescend; to do despite a perceived affront to one's dignity.",
              synonyms: [],
              antonyms: [],
              example:
                "He didn't even deign to give us a nod of the head; he thought us that far beneath him.",
            },
            {
              definition: "To condescend to give; to do something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To esteem worthy; to consider worth notice.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/deign"],
    },
  ],
  culls: [
    {
      word: "culls",
      phonetic: "/kʌlz/",
      phonetics: [{ text: "/kʌlz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A selection.", synonyms: [], antonyms: [] },
            {
              definition: "An organised killing of selected animals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(farming) An individual animal selected to be killed, or item of produce to be discarded.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(seafood industry) A lobster having only one claw.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece unfit for inclusion within a larger group; an inferior specimen.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To pick or take someone or something (from a larger group).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To gather, collect.", synonyms: [], antonyms: [] },
            {
              definition:
                "To select animals from a group and then kill them in order to reduce the numbers of the group in a controlled manner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To kill (animals etc).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To lay off in order to reduce the size of, get rid of.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A fool, gullible person; a dupe.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Any refuse material.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cull",
        "https://en.wiktionary.org/wiki/culls",
      ],
    },
  ],
  girds: [
    {
      word: "girds",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To bind with a flexible rope or cord.",
              synonyms: [],
              antonyms: [],
              example:
                "The fasces were girt about with twine in bundles large.",
            },
            {
              definition: "To encircle with, or as if with a belt.",
              synonyms: [],
              antonyms: [],
              example:
                "The lady girt herself with silver chain, from which she hung a golden shear.",
            },
            {
              definition: "To prepare oneself for an action.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A sarcastic remark.", synonyms: [], antonyms: [] },
            {
              definition: "A stroke with a rod or switch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A severe spasm; a twinge; a pang.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To jeer at.", synonyms: [], antonyms: [] },
            { definition: "To jeer.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gird",
        "https://en.wiktionary.org/wiki/girds",
      ],
    },
  ],
  haves: [
    {
      word: "haves",
      phonetic: "/hævz/",
      phonetics: [{ text: "/hævz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The wealthy or privileged, contrasted to those who are poor or deprived: the have nots.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["have nots"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/haves"],
    },
  ],
  lucks: [
    {
      word: "lucks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Something that happens to someone by chance, a chance occurrence, especially a favourable one.",
              synonyms: [],
              antonyms: [],
              example:
                "Gilbert had some bad luck yesterday — he got pick-pocketed and lost fifty dollars.",
            },
            {
              definition:
                "A superstitious feeling that brings fortune or success.",
              synonyms: [],
              antonyms: [],
              example: "He blew on the dice for luck.",
            },
            {
              definition: "Success.",
              synonyms: [],
              antonyms: [],
              example:
                "He has a lot of luck with the ladies, perhaps it is because of his new motorbike.",
            },
            {
              definition: "The results of a random number generator.",
              synonyms: [],
              antonyms: [],
              example:
                "The creators of tool-assisted speedruns often manipulate a lot of luck to get the most favorable results in order to save the most time.",
            },
          ],
          synonyms: ["chance", "fortune", "fortune"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To succeed by chance.",
              synonyms: [],
              antonyms: [],
              example: "His plan lucked out.",
            },
            {
              definition: "To rely on luck.",
              synonyms: [],
              antonyms: [],
              example: "No plan. We're just to going to have to luck through.",
            },
            {
              definition: "To carry out relying on luck.",
              synonyms: [],
              antonyms: [],
              example: "Our plan is to luck it through.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/luck",
        "https://en.wiktionary.org/wiki/lucks",
      ],
    },
  ],
  stunk: [
    {
      word: "stunk",
      phonetic: "/stʌŋk/",
      phonetics: [
        {
          text: "/stʌŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stunk-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88681584",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To have a strong bad smell.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be greatly inferior; to perform badly.",
              synonyms: [],
              antonyms: [],
              example: "That movie stinks. I didn't even stay for the end.",
            },
            {
              definition: "To give an impression of dishonesty or untruth.",
              synonyms: [],
              antonyms: [],
              example: "Something stinks about the politician's excuses.",
            },
            {
              definition: "To cause to stink; to affect by a stink.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["blow", "suck", "fishy", "pong", "reek"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/stink",
        "https://en.wiktionary.org/wiki/stunk",
      ],
    },
  ],
  dodos: [
    {
      word: "dodos",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A large, flightless bird, †Raphus cucullatus, related to the pigeon, that is now extinct (since the 1600s) and was native to Mauritius.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person or organisation which is very old or has very old-fashioned views or is not willing to change and adapt.",
              synonyms: [],
              antonyms: [],
              example: "As dead as a dodo.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Fried plantain.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dodo",
        "https://en.wiktionary.org/wiki/dodos",
      ],
    },
  ],
  shams: [
    {
      word: "shams",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A fake; an imitation that purports to be genuine.",
              synonyms: [],
              antonyms: [],
              example: "The time-share deal was a sham.",
            },
            {
              definition: "Trickery, hoaxing.",
              synonyms: [],
              antonyms: [],
              example:
                "A con-man must be skilled in the arts of sham and deceit.",
            },
            {
              definition: "A false front, or removable ornamental covering.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A decorative cover for a pillow.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sham",
        "https://en.wiktionary.org/wiki/shams",
      ],
    },
  ],
  snubs: [
    {
      word: "snubs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A deliberate affront or slight.",
              synonyms: [],
              antonyms: [],
              example:
                "I hope the people we couldn't invite don't see it as a snub.",
            },
            {
              definition: "A sudden checking of a cable or rope.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A knot; a protuberance; a snag.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To slight, ignore or behave coldly toward someone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To turn down; to dismiss.",
              synonyms: [],
              antonyms: [],
              example: "He snubbed my offer of help.",
            },
            {
              definition: "To check; to reprimand.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To stub out (a cigarette etc).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To halt the movement of a rope etc by turning it about a cleat or bollard etc; to secure a vessel in this manner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To clip or break off the end of; to check or stunt the growth of.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "cut someone cold",
            "cut someone dead",
            "give someone the cold shoulder",
            "turn the cold shoulder on someone",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To sob with convulsions.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/snub",
        "https://en.wiktionary.org/wiki/snubs",
      ],
    },
  ],
  icons: [
    {
      word: "icons",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An image, symbol, picture, or other representation usually as an object of religious devotion.",
              synonyms: ["graven image", "idol"],
              antonyms: [],
            },
            {
              definition:
                "(especially Eastern Christianity) A type of religious painting portraying a saint or scene from Scripture, often done on wooden panels.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) A person or thing that is the best example of a certain profession or some doing.",
              synonyms: [],
              antonyms: [],
              example:
                "That man is an icon in the business; he personifies loyalty and good business sense.",
            },
            {
              definition:
                "A small picture that represents something (such as an icon on a computer screen which when clicked performs some function.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A word, character, or sign whose form reflects and is determined by the referent; onomatopoeic words are necessarily all icons.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["graven image", "idol"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/icon",
        "https://en.wiktionary.org/wiki/icons",
      ],
    },
  ],
  usurp: [
    {
      word: "usurp",
      phonetic: "/juːˈzɜːp/",
      phonetics: [
        { text: "/juːˈzɜːp/", audio: "" },
        {
          text: "/juˈsɝp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/usurp-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=49896869",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To seize power from another, usually by illegitimate means.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To use and assume the coat of arms of another person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To take the place rightfully belonging to someone or something else.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To make use of.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/usurp"],
    },
  ],
  dooms: [
    {
      word: "dooms",
      phonetic: "/duːmz/",
      phonetics: [{ text: "/duːmz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Destiny, especially terrible.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An undesirable fate; an impending severe occurrence or danger that seems inevitable.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A feeling of danger, impending danger, darkness or despair.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A law.", synonyms: [], antonyms: [] },
            {
              definition: "A judgment or decision.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sentence or penalty for illegal behaviour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Death.",
              synonyms: [],
              antonyms: [],
              example: "They met an untimely doom when the mineshaft caved in.",
            },
            {
              definition:
                "(sometimes capitalized) The Last Judgment; or, an artistic representation thereof.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["fortune"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To pronounce sentence or judgment on; to condemn.",
              synonyms: [],
              antonyms: [],
              example: "a criminal doomed to death",
            },
            {
              definition: "To destine; to fix irrevocably the ill fate of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To judge; to estimate or determine as a judge.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To ordain as a penalty; hence, to mulct or fine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To assess a tax upon, by estimate or at discretion.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/doom",
        "https://en.wiktionary.org/wiki/dooms",
      ],
    },
  ],
  hells: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  soled: [
    {
      word: "soled",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To pull by the ears; to pull about; haul; lug.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To put a sole on (a shoe or boot)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "(in combination) Having a specified kind of sole.",
              synonyms: [],
              antonyms: [],
              example: "a rubber-soled shoe",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sole",
        "https://en.wiktionary.org/wiki/soled",
      ],
    },
  ],
  comas: [
    {
      word: "comas",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A state of unconsciousness from which one may not wake up, usually induced by some form of trauma.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/coma",
        "https://en.wiktionary.org/wiki/comas",
      ],
    },
  ],
  paves: [
    {
      word: "paves",
      phonetic: "/peɪvz/",
      phonetics: [{ text: "/peɪvz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cover something with paving slabs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cover with stone, concrete, blacktop or other solid covering, especially to aid travel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To pave the way for; to make easy and smooth.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pave",
        "https://en.wiktionary.org/wiki/paves",
      ],
    },
  ],
  maths: [
    {
      word: "maths",
      phonetic: "/mæθs/",
      phonetics: [
        {
          text: "/mæθs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/maths-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=31972309",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An abstract representational system used in the study of numbers, shapes, structure, change and the relationships between these concepts.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person's ability to count, calculate, and use different systems of mathematics at differing levels.",
              synonyms: [],
              antonyms: [],
              example: "My mathematics is always improving.",
            },
          ],
          synonyms: ["numeracy", "math", "maths"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mathematics",
        "https://en.wiktionary.org/wiki/maths",
      ],
    },
  ],
  perks: [
    {
      word: "perks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Perquisite.",
              synonyms: [],
              antonyms: [],
              example: "Free coffee is one of the perks of the job.",
            },
            {
              definition:
                "A bonus ability that a player character can acquire; a permanent power-up.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A percolator, particularly of coffee.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make (coffee) in a percolator or a drip coffeemaker.",
              synonyms: [],
              antonyms: [],
              example: "I’ll perk some coffee.",
            },
            {
              definition:
                "Of coffee: to be produced by heated water seeping (“percolating”) through coffee grounds.",
              synonyms: [],
              antonyms: [],
              example: "The coffee is perking.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make trim or smart; to straighten up; to erect; to make a jaunty or saucy display of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To appear from below or behind something, emerge, pop up, poke out.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To exalt oneself; to bear oneself loftily.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To peer; to look inquisitively.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To perch.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/perk",
        "https://en.wiktionary.org/wiki/perks",
      ],
    },
  ],
  limps: [
    {
      word: "limps",
      phonetic: "/lɪmps/",
      phonetics: [{ text: "/lɪmps/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An irregular, jerky or awkward gait.",
              synonyms: [],
              antonyms: [],
              example: "She walks with a limp.",
            },
            {
              definition:
                "A scraper for removing poor ore or refuse from the sieve.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To walk lamely, as if favouring one leg.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a vehicle) To travel with a malfunctioning system of propulsion.",
              synonyms: [],
              antonyms: [],
              example: "The bomber limped home on one engine.",
            },
            {
              definition: "To move or proceed irregularly.",
              synonyms: [],
              antonyms: [],
              example: "The business limped through the recession",
            },
            {
              definition: "To call, particularly in an unraised pot pre-flop.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A scraper of board or sheet-iron shaped like half the head of a small cask, used for scraping the ore off the sieve in the operation of hand-jigging.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To be inadequate or unsatisfactory.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To happen; befall; chance.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To come upon; meet.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/limp",
        "https://en.wiktionary.org/wiki/limps",
      ],
    },
  ],
  wombs: [
    {
      word: "wombs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "In female mammals, the organ in which the young are conceived and grow until birth; the uterus.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The abdomen or stomach.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The stomach of a person or creature.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A place where something is made or formed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any cavity containing and enveloping anything.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["belly", "matrix", "uterus"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/womb",
        "https://en.wiktionary.org/wiki/wombs",
      ],
    },
  ],
  blurb: [
    {
      word: "blurb",
      phonetic: "/blɝ(ː)b/",
      phonetics: [{ text: "/blɝ(ː)b/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A short description of a book, film, or other work, written and used for promotional purposes.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To write or quote in a blurb.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To supply with a blurb.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/blurb"],
    },
  ],
  daubs: [
    {
      word: "daubs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Excrement or clay used as a bonding material in construction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A soft coating of mud, plaster etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A crude or amateurish painting.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To apply (something) to a surface in hasty or crude strokes.",
              synonyms: ["apply", "coat", "cover", "plaster", "smear"],
              antonyms: [],
              example:
                "The artist just seemed to daub on paint at random and suddenly there was a painting.",
            },
            {
              definition:
                "To paint (a picture, etc.) in a coarse or unskilful manner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cover with a specious or deceitful exterior; to disguise; to conceal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To flatter excessively or grossly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To put on without taste; to deck gaudily.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["apply", "coat", "cover", "plaster", "smear"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/daub",
        "https://en.wiktionary.org/wiki/daubs",
      ],
    },
  ],
  cokes: [
    {
      word: "cokes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Cola-based soft drink.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A bottle, glass or can of a cola-based soft drink.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any soft drink, regardless of type.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To produce coke from coal.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To turn into coke.", synonyms: [], antonyms: [] },
            {
              definition:
                "To add deleterious carbon deposits as a byproduct of combustion.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/Coke",
        "https://en.wiktionary.org/wiki/coke",
        "https://en.wiktionary.org/wiki/cokes",
      ],
    },
    {
      word: "cokes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A simpleton; a dupe.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cokes"],
    },
  ],
  sours: [
    {
      word: "sours",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The sensation of a sour taste.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A drink made with whiskey, lemon or lime juice and sugar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) Any cocktail containing lemon or lime juice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sour or acid substance; whatever produces a painful effect.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The acidic solution used in souring fabric.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make sour.",
              synonyms: [],
              antonyms: [],
              example: "Too much lemon juice will sour the recipe.",
            },
            { definition: "To become sour.", synonyms: [], antonyms: [] },
            {
              definition: "To spoil or mar; to make disenchanted.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To become disenchanted.",
              synonyms: [],
              antonyms: [],
              example: "We broke up after our relationship soured.",
            },
            {
              definition: "To make (soil) cold and unproductive.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To macerate (lime) and render it fit for plaster or mortar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To process (fabric) after bleaching, using hydrochloric acid or sulphuric acid to wash out the lime.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sour",
        "https://en.wiktionary.org/wiki/sours",
      ],
    },
  ],
  stuns: [
    {
      word: "stuns",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The condition of being stunned.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "That which stuns; a shock; a stupefying blow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person who lacks intelligence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The effect on the cue ball where the ball is hit without topspin, backspin or sidespin.",
              synonyms: [],
              antonyms: [],
              example:
                "Williams will need a lot of stun to avoid going in the middle pocket",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To incapacitate; especially by inducing disorientation or unconsciousness.",
              synonyms: [],
              antonyms: [],
              example:
                "Bill tried to stun the snake by striking it on the head.",
            },
            {
              definition: "To shock or surprise.",
              synonyms: [],
              antonyms: [],
              example:
                "He stood there stunned, looking at the beautiful, breath-taking sunrise.",
            },
            {
              definition:
                "To hit the cue ball so that it slides without topspin or backspin (and with or without sidespin) and continues at a natural angle after contact with the object ball",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/stun",
        "https://en.wiktionary.org/wiki/stuns",
      ],
    },
  ],
  cased: [
    {
      word: "cased",
      phonetic: "/keɪst/",
      phonetics: [{ text: "/keɪst/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To propose hypothetical cases.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To place (an item or items of manufacture) into a box, as in preparation for shipment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cover or protect with, or as if with, a case; to enclose.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To survey (a building or other location) surreptitiously, as in preparation for a robbery.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having a case or covering; encased or clad.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/case",
        "https://en.wiktionary.org/wiki/cased",
      ],
    },
  ],
  musts: [
    {
      word: "musts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Something that is mandatory or required.",
              synonyms: ["imperative"],
              antonyms: ["no-no"],
              example: "If you'll be out all day, a map is a must.",
            },
          ],
          synonyms: ["imperative"],
          antonyms: ["no-no"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The property of being stale or musty.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something that exhibits the property of being stale or musty.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Fruit juice that will ferment or has fermented, usually from grapes.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A time during which male elephants exhibit increased levels of sexual activity and aggressiveness (also spelled musth).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An elephant in this sexual and aggressive state.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/must",
        "https://en.wiktionary.org/wiki/musts",
      ],
    },
  ],
  coeds: [
    {
      word: "coeds",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A young woman who attends college.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A (generally young) woman, especially on the campus of a college or other educational institute.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/co-ed",
        "https://en.wiktionary.org/wiki/coed",
        "https://en.wiktionary.org/wiki/coeds",
      ],
    },
  ],
  cowed: [
    {
      word: "cowed",
      phonetic: "/kaʊd/",
      phonetics: [{ text: "/kaʊd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(chiefly in the passive voice) To intimidate; to daunt the spirits or courage of.",
              synonyms: [],
              antonyms: [],
              example: "Con artists are not cowed by the law.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Frightened into submission.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["intimidated"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cow",
        "https://en.wiktionary.org/wiki/cowed",
      ],
    },
  ],
  aping: [
    {
      word: "aping",
      phonetic: "/ˈeɪ.pɪŋ/",
      phonetics: [{ text: "/ˈeɪ.pɪŋ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To behave like an ape.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To imitate or mimic, particularly to imitate poorly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Foolish imitation or mimicry.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ape",
        "https://en.wiktionary.org/wiki/aping",
      ],
    },
  ],
  zoned: [
    {
      word: "zoned",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To divide into or assign sections or areas.",
              synonyms: [],
              antonyms: [],
              example:
                "Please zone off our staging area, a section for each group.",
            },
            {
              definition:
                "To define the property use classification of an area.",
              synonyms: [],
              antonyms: [],
              example: "This area was zoned for industrial use.",
            },
            {
              definition:
                "To enter a daydream state temporarily, for instance as a result of boredom, fatigue, or intoxication; to doze off.",
              synonyms: [],
              antonyms: [],
              example:
                'Everyone just put their goddamn heads together and zoned. (Byron Coley, liner notes for the album "Piece for Jetsun Dolma" by Thurston Moore)',
            },
            {
              definition: "To girdle or encircle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["doze off", "zone out"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/zone",
        "https://en.wiktionary.org/wiki/zoned",
      ],
    },
  ],
  rummy: [
    {
      word: "rummy",
      phonetic: "/ˈɹʌmɪ/",
      phonetics: [
        { text: "/ˈɹʌmɪ/", audio: "" },
        { text: "/ˈɹʌmi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A card game with many rule variants, conceptually similar to mahjong.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rummy"],
    },
    {
      word: "rummy",
      phonetic: "/ˈɹʌmɪ/",
      phonetics: [
        { text: "/ˈɹʌmɪ/", audio: "" },
        { text: "/ˈɹʌmi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A rum-drinking alcoholic.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Resembling or tasting of rum.",
              synonyms: [],
              antonyms: [],
              example: "a rummy flavour",
            },
            { definition: "Peculiar; odd.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rummy"],
    },
  ],
  fetes: [
    {
      word: "fetes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A festival open to the public, the proceeds from which are often given to charity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A feast, celebration or carnival.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(usually in the passive) To celebrate (a person).",
              synonyms: ["celebrate"],
              antonyms: [],
            },
          ],
          synonyms: ["celebrate"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fete",
        "https://en.wiktionary.org/wiki/fetes",
      ],
    },
  ],
  skulk: [
    {
      word: "skulk",
      phonetic: "/skʌlk/",
      phonetics: [{ text: "/skʌlk/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A group of foxes.", synonyms: [], antonyms: [] },
            {
              definition:
                "A group of people seen as being fox-like (e.g. cunning, dishonest, or having nefarious plans).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The act of skulking.", synonyms: [], antonyms: [] },
            {
              definition: "One who avoids an obligation or responsibility.",
              synonyms: ["shirk", "shirker", "skulker"],
              antonyms: [],
            },
          ],
          synonyms: ["shirk", "shirker", "skulker"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To stay where one cannot be seen, conceal oneself (often in a cowardly way or with the intent of doing harm).",
              synonyms: ["hide"],
              antonyms: [],
            },
            {
              definition:
                "To move in a stealthy or furtive way; to come or go while trying to avoid detection.",
              synonyms: ["sneak", "steal"],
              antonyms: [],
            },
            {
              definition: "To avoid an obligation or responsibility.",
              synonyms: ["shirk"],
              antonyms: [],
            },
          ],
          synonyms: ["hide", "shirk", "sneak", "steal"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/skulk"],
    },
  ],
  quaff: [
    {
      word: "quaff",
      phonetic: "/kwɒf/",
      phonetics: [
        { text: "/kwɒf/", audio: "" },
        { text: "/kwɑf/", audio: "" },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/quaff-1-us-cot-caught%20merger.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=658939",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act of quaffing; a deep draught.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["chug", "gulp", "swig"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To drink or imbibe with vigour or relish; to drink copiously; to swallow in large draughts.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/quaff"],
    },
  ],
  rajah: [
    {
      word: "rajah",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A Hindu prince or ruler in India.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various nymphalid butterflies of the genus Charaxes.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rajah"],
    },
  ],
  deans: [
    {
      word: "deans",
      phonetic: "/diːnz/",
      phonetics: [{ text: "/diːnz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A senior official in a college or university, who may be in charge of a division or faculty (for example, the dean of science) or have some other advisory or disciplinary function (for example, the dean of students).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A dignitary or presiding officer in certain church bodies, especially an ecclesiastical dignitary, subordinate to a bishop, in charge of a chapter of canons.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The senior member of some group of people.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["provost"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To serve as a dean.", synonyms: [], antonyms: [] },
            {
              definition:
                "To send (a student) to see the dean of a university.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(chiefly in place names) A hill.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dean",
        "https://en.wiktionary.org/wiki/deans",
      ],
    },
  ],
  reaps: [
    {
      word: "reaps",
      phonetic: "/ɹiːps/",
      phonetics: [{ text: "/ɹiːps/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cut (for example a grain) with a sickle, scythe, or reaping machine",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To gather (e.g. a harvest) by cutting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To obtain or receive as a reward, in a good or a bad sense.",
              synonyms: [],
              antonyms: [],
              example: "to reap a benefit from exertions",
            },
            {
              definition:
                "To terminate a child process that has previously exited, thereby removing it from the process table.",
              synonyms: [],
              antonyms: [],
              example:
                "Until a child process is reaped, it may be listed in the process table as a zombie or defunct process.",
            },
            {
              definition: "To deprive of the beard; to shave.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/reap",
        "https://en.wiktionary.org/wiki/reaps",
      ],
    },
  ],
  galas: [
    {
      word: "galas",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Pomp, show, or festivity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A showy and festive party.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A member of an androgynous class of priests of the Sumerian goddess Inanna.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gala",
        "https://en.wiktionary.org/wiki/galas",
      ],
    },
  ],
  tills: [
    {
      word: "tills",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A cash register.", synonyms: [], antonyms: [] },
            {
              definition:
                "A removable box within a cash register containing the money.",
              synonyms: [],
              antonyms: [],
              example: "Pull all the tills and lock them in the safe.",
            },
            {
              definition:
                "The contents of a cash register, for example at the beginning or end of the day or of a cashier's shift.",
              synonyms: [],
              antonyms: [],
              example: "My count of my till was 30 dollars short.",
            },
            {
              definition: "A tray or drawer in a chest.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To develop so as to improve or prepare for usage; to cultivate (said of knowledge, virtue, mind etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To work or cultivate or plough (soil); to prepare for growing vegetation and crops.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To cultivate soil.", synonyms: [], antonyms: [] },
            { definition: "To prepare; to get.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A vetch; a tare.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/till",
        "https://en.wiktionary.org/wiki/tills",
      ],
    },
  ],
  roved: [
    {
      word: "roved",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To shoot with arrows (at).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To roam, or wander about at random, especially over a wide area.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To roam or wander through.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To card wool or other fibres.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To twist slightly; to bring together, as slivers of wool or cotton, and twist slightly before spinning.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To draw through an eye or aperture.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To plough into ridges by turning the earth of two furrows together.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To practice robbery on the seas; to voyage about on the seas as a pirate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rove",
        "https://en.wiktionary.org/wiki/roved",
      ],
    },
  ],
  kudos: [
    {
      word: "kudos",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/kudos-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75804244",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈkjuːdɒs/", audio: "" },
        {
          text: "/ˈkuː.doʊs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/kudos-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=3707528",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Praise; accolades.",
              synonyms: [],
              antonyms: [],
              example:
                "The talented, young playwright received much kudos for his new drama.",
            },
            {
              definition: "Credit for one's achievements.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/kudos"],
    },
    {
      word: "kudos",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/kudos-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75804244",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈkjuːdɒs/", audio: "" },
        {
          text: "/ˈkuː.doʊs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/kudos-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=3707528",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A compliment or praise.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/kudo",
        "https://en.wiktionary.org/wiki/kudos",
      ],
    },
  ],
  toned: [
    {
      word: "toned",
      phonetic: "/təʊnd/",
      phonetics: [
        { text: "/təʊnd/", audio: "" },
        { text: "/toʊnd/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To give a particular tone to",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To change the colour of",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make (something) firmer",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To harmonize, especially in colour",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To utter with an affected tone.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "color",
            "colour",
            "dye",
            "paint",
            "tint",
            "harmonise",
            "harmonize",
            "firm",
            "firm up",
            "tone up",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having a (specified kind of) tone.",
              synonyms: [],
              antonyms: [],
              example: "high-toned",
            },
            {
              definition: "Having defined or firm muscles; fit",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tone",
        "https://en.wiktionary.org/wiki/toned",
      ],
    },
  ],
  pared: [
    {
      word: "pared",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To remove the outer covering or skin of something with a cutting device, typically a knife",
              synonyms: [],
              antonyms: [],
              example:
                "Victor pared some apples in preparation to make a tart.",
            },
            {
              definition:
                "(often with down or back) to reduce, diminish or trim gradually something as if by cutting off",
              synonyms: [],
              antonyms: [],
              example:
                "Albert had to pare his options down by disregarding anything beyond his meager budget.",
            },
            {
              definition: "To trim the hoof of a horse",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["peel", "skin"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pare",
        "https://en.wiktionary.org/wiki/pared",
      ],
    },
  ],
  scull: [
    {
      word: "scull",
      phonetic: "/skʌl/",
      phonetics: [
        {
          text: "/skʌl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scull-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75649643",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A single oar mounted at the stern of a boat and moved from side to side to propel the boat forward.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One of a pair of oars handled by a single rower.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A small rowing boat, for one person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A light rowing boat used for racing by one, two, or four rowers, each operating two oars (sculls), one in each hand.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To row a boat using a scull or sculls.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To skate while keeping both feet in contact with the ground or ice.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scull"],
    },
    {
      word: "scull",
      phonetic: "/skʌl/",
      phonetics: [
        {
          text: "/skʌl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scull-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75649643",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A skull cap. A small bowl-shaped helmet, without visor or bever.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To drink the entire contents of (a drinking vessel) without pausing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["chug"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The main bones of the head considered as a unit; including the cranium, facial bones, and mandible.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A symbol for death; death's-head",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The mind or brain.", synonyms: [], antonyms: [] },
            {
              definition:
                "A crust formed on the ladle, etc. by the partial cooling of molten metal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The crown of the headpiece in armour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A shallow bow-handled basket.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["brainpan", "harnpan"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/scull",
        "https://en.wiktionary.org/wiki/skull",
      ],
    },
    {
      word: "scull",
      phonetic: "/skʌl/",
      phonetics: [
        {
          text: "/skʌl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scull-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75649643",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A shoal of fish.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scull"],
    },
    {
      word: "scull",
      phonetic: "/skʌl/",
      phonetics: [
        {
          text: "/skʌl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scull-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75649643",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The skua gull.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scull"],
    },
  ],
  vexes: [
    {
      word: "vexes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To trouble aggressively, to harass.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To annoy, irritate.", synonyms: [], antonyms: [] },
            {
              definition: "To cause (mental) suffering to; to distress.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To twist, to weave.", synonyms: [], antonyms: [] },
            {
              definition: "To be irritated; to fret.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To toss back and forth; to agitate; to disquiet.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "agitate",
            "irk",
            "irritate",
            "afflict",
            "grame",
            "torment",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/vex",
        "https://en.wiktionary.org/wiki/vexes",
      ],
    },
  ],
  punts: [
    {
      word: "punts",
      phonetic: "/pʌnts/",
      phonetics: [{ text: "/pʌnts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A pontoon; a narrow shallow boat propelled by a pole.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To propel a punt or similar craft by means of a pole.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A kick made by a player who drops the ball and kicks it before it hits the ground.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To dropkick; to kick something a considerable distance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To equivocate and delay or put off (answering a question, addressing an issue, etc).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To retreat from one's objective; to abandon an effort one still notionally supports.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make the best choice from a set of non-ideal alternatives.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A point in the game of faro.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The act of playing at basset, baccara, faro, etc.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A bet or wager.", synonyms: [], antonyms: [] },
            {
              definition:
                "Gambling, as a pastime, especially betting on horseraces or the dogs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A highly speculative investment or other commitment.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A wild guess.", synonyms: [], antonyms: [] },
            {
              definition: "An indentation in the base of a wine bottle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A thin glass rod which is temporarily attached to a larger piece in order to better manipulate the larger piece.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To play at basset, baccara, faro, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To stake against the bank, to back a horse, to gamble or take a chance more generally",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make a highly speculative investment or other commitment, or take a wild guess.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The Irish pound, used as the unit of currency of Ireland until it was replaced by the euro in 2002.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/punt",
        "https://en.wiktionary.org/wiki/punts",
      ],
    },
  ],
  snoop: [
    {
      word: "snoop",
      phonetic: "/snuːp/",
      phonetics: [{ text: "/snuːp/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The act of snooping", synonyms: [], antonyms: [] },
            {
              definition: "One who snoops",
              synonyms: [],
              antonyms: [],
              example:
                "Be careful what you say around Gene because he's the bosses' snoop.",
            },
            {
              definition: "A private detective",
              synonyms: [],
              antonyms: [],
              example:
                "She hired a snoop to find out if her husband was having an affair.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To be devious and cunning so as not to be seen.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To secretly spy on or investigate, especially into the private personal life of others.",
              synonyms: [],
              antonyms: [],
              example:
                "If I had not snooped on her, I wouldn't have found out that she lied about her degree.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/snoop"],
    },
  ],
  bails: [
    {
      word: "bails",
      phonetic: "/beɪlz/",
      phonetics: [
        {
          text: "/beɪlz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bails-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=60477539",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Security, usually a sum of money, exchanged for the release of an arrested person as a guarantee of that person's appearance for trial.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Release from imprisonment on payment of such money.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The person providing such payment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bucket or scoop used for removing water from a boat etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person who bails water out of a boat.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Custody; keeping.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To secure the release of an arrested person by providing bail.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To release a person under such guarantee.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To hand over personal property to be held temporarily by another as a bailment.",
              synonyms: [],
              antonyms: [],
              example:
                "to bail cloth to a tailor to be made into a garment; to bail goods to a carrier",
            },
            {
              definition: "To remove (water) from a boat by scooping it out.",
              synonyms: [],
              antonyms: [],
              example: "to bail water out of a boat",
            },
            {
              definition: "To remove water from (a boat) by scooping it out.",
              synonyms: [],
              antonyms: [],
              example: "to bail a boat",
            },
            {
              definition: "To set free; to deliver; to release.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To exit quickly.",
              synonyms: [],
              antonyms: [],
              example:
                "With his engine in flames, the pilot had no choice but to bail.",
            },
            {
              definition: "To fail to meet a commitment.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A hoop, ring or handle (especially of a kettle or bucket).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A stall for a cow (or other animal) (usually tethered with a semi-circular hoop).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hinged bar as a restraint for animals, or on a typewriter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A frame to restrain a cow during milking or feeding.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hoop, ring, or other object used to connect a pendant to a necklace.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the two wooden crosspieces that rest on top of the stumps to form a wicket.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(furniture) Normally curved handle suspended between sockets as a drawer pull. This may also be on a kettle or pail.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To secure the head of a cow during milking.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To confine.", synonyms: [], antonyms: [] },
            {
              definition:
                "To secure (a cow) by placing its head in a bail for milking.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To keep (a traveller) detained in order to rob them; to corner (a wild animal); loosely, to detain, hold up. (Usually with up.)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bail",
        "https://en.wiktionary.org/wiki/bails",
      ],
    },
  ],
  dames: [
    {
      word: "dames",
      phonetic: "/deɪmz/",
      phonetics: [{ text: "/deɪmz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Usually capitalized as Dame: a title equivalent to Sir for a female knight.",
              synonyms: [],
              antonyms: [],
              example: "Dame Edith Sitwell",
            },
            {
              definition: "A matron at a school, especially Eton College.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In traditional pantomime: a melodramatic female often played by a man in drag.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(slightly derogatory) A woman.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A lady, a woman.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dame",
        "https://en.wiktionary.org/wiki/dames",
      ],
    },
  ],
  hazes: [
    {
      word: "hazes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Very fine solid particles (smoke, dust) or liquid droplets (moisture) suspended in the air, slightly limiting visibility.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A reduction of transparency of a clear gas or liquid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An analogous dullness on a surface that is ideally highly reflective or transparent.",
              synonyms: [],
              antonyms: [],
              example:
                "The furniture has a haze, possibly from some kind of wax.",
            },
            {
              definition:
                "Any state suggestive of haze in the atmosphere, such as mental confusion or vagueness of memory.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(packaging) The degree of cloudiness or turbidity in a clear glass or plastic, measured in percent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any substance causing turbidity in beer or wine.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To be or become hazy, or thick with haze.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To perform an unpleasant initiation ritual upon a usually non-consenting individual, especially freshmen to a closed community such as a college or military unit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To oppress or harass by forcing to do hard and unnecessary work.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In a rodeo, to assist the bulldogger by keeping (the steer) running in a straight line.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/haze",
        "https://en.wiktionary.org/wiki/hazes",
      ],
    },
  ],
  lores: [
    {
      word: "lores",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "All the facts and traditions about a particular subject that have been accumulated over time through education or experience.",
              synonyms: [],
              antonyms: [],
              example: "the lore of the Ancient Egyptians",
            },
            {
              definition: "The backstory created around a fictional universe.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Workmanship.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The region between the eyes and nostrils of birds, reptiles, and amphibians.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The anterior portion of the cheeks of insects.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lore",
        "https://en.wiktionary.org/wiki/lores",
      ],
    },
  ],
  marts: [
    {
      word: "marts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A license to pass the limits of a jurisdiction, or boundary of a country, for the purpose of making reprisals; a letter of marque.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A brand or make of a manufactured product, especially of a motor car (in contradistinction to a model).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A ship commissioned for making captures.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A market.", synonyms: [], antonyms: [] },
            { definition: "A bargain.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Battle; contest.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/marque",
        "https://en.wiktionary.org/wiki/mart",
        "https://en.wiktionary.org/wiki/marts",
      ],
    },
  ],
  voids: [
    {
      word: "voids",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An empty space; a vacuum.",
              synonyms: [],
              antonyms: [],
              example:
                "Nobody has crossed the void since one man died trying three hundred years ago; it's high time we had another go.",
            },
            {
              definition: "An extended region of space containing no galaxies",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A collection of adjacent vacancies inside a crystal lattice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A pocket of vapour inside a fluid flow, created by cavitation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An empty space between floors or walls, including false separations and planned gaps between a building and its facade.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["pore", "bubble"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make invalid or worthless.",
              synonyms: [],
              antonyms: [],
              example: "He voided the check and returned it.",
            },
            {
              definition: "To empty.",
              synonyms: [],
              antonyms: [],
              example: "void one’s bowels",
            },
            {
              definition:
                "To throw or send out; to evacuate; to emit; to discharge.",
              synonyms: [],
              antonyms: [],
              example: "to void excrement",
            },
            { definition: "To withdraw, depart.", synonyms: [], antonyms: [] },
            {
              definition:
                "To remove the contents of; to make or leave vacant or empty; to quit; to leave.",
              synonyms: [],
              antonyms: [],
              example: "to void a table",
            },
          ],
          synonyms: ["evacuate", "annul", "cancel"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A voidee.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/void",
        "https://en.wiktionary.org/wiki/voids",
      ],
    },
  ],
  ameba: [
    {
      word: "ameba",
      phonetic: "/əˈmiːbə/",
      phonetics: [{ text: "/əˈmiːbə/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A member of the genus Amoeba of unicellular protozoa that moves by means of temporary projections called pseudopodia.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The graph of the real part of the logarithms of a polynomial equation in complex numbers.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An asexual.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ameba",
        "https://en.wiktionary.org/wiki/amoeba",
      ],
    },
  ],
  rakes: [
    {
      word: "rakes",
      phonetic: "/ɹeɪks/",
      phonetics: [
        {
          text: "/ɹeɪks/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rakes-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80066766",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A walk, or a journey taken (especially on foot); the act of taking a walk or journey.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The movement of animals while grazing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The pastureland over which animals graze; a range, a stray.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A journey to transport something between two places; a run; also, the quantity of items so transported.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To walk; to roam, to wander.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of animals (especially sheep): to graze.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To roam or wander through (somewhere).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A garden tool with a row of pointed teeth fixed to a long handle, used for collecting debris, grass, etc., for flattening the ground, or for loosening soil; also, a similar wheel-mounted tool drawn by a horse or a tractor.",
              synonyms: ["horserake"],
              antonyms: [],
            },
            {
              definition:
                "(by extension) A similarly shaped tool used for other purposes.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["horserake"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The act of raking.", synonyms: [], antonyms: [] },
            {
              definition: "Something that is raked.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To act upon with a rake, or as if with a rake.",
              synonyms: [],
              antonyms: [],
              example: "She is raking the gravel path to keep it even.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A course, a path, especially a narrow and steep path or route up a hillside.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A fissure or mineral vein of ore traversing the strata vertically, or nearly so.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(originally Northern England) A series, a succession; specifically a set of coupled rail vehicles, normally coaches or wagons.",
              synonyms: ["consist"],
              antonyms: [],
              example:
                "The train was formed of a locomotive and a rake of six coaches.",
            },
            {
              definition:
                "(systems theory) In cellular automata: a puffer that emits a stream of spaceships rather than a trail of debris.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["consist"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Rate of progress; pace, speed.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To move swiftly; to proceed rapidly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of a bird of prey: to fly after a quarry; also, to fly away from the falconer, to go wide of the quarry being pursued.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A divergence from the horizontal or perpendicular; a slant, a slope.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(specifically) In full, angle of rake or rake angle: the angle between the edge or face of a tool (especially a cutting tool) and a plane (usually one perpendicular to the object that the tool is being applied to).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The direction of slip during the movement of a fault, measured within the fault plane.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Senses relating to watercraft.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(roofing) The sloped edge of a roof at or adjacent to the first or last rafter.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To incline (something) from a perpendicular direction.",
              synonyms: ["slope"],
              antonyms: [],
              example: "A mast rakes aft.",
            },
            {
              definition: "Senses relating to watercraft.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["slope"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person (usually a man) who is stylish but habituated to hedonistic and immoral conduct.",
              synonyms: ["roué"],
              antonyms: [],
            },
          ],
          synonyms: ["roué"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To behave as a rake; to lead a hedonistic and immoral life.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/raik",
        "https://en.wiktionary.org/wiki/rake",
        "https://en.wiktionary.org/wiki/rakes",
      ],
    },
  ],
  adzes: [
    {
      word: "adzes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A cutting tool that has a curved blade set at a right angle to the handle and is used in shaping wood.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To shape a material using an adze.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/adz",
        "https://en.wiktionary.org/wiki/adze",
        "https://en.wiktionary.org/wiki/adzes",
      ],
    },
  ],
  harms: [
    {
      word: "harms",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Physical injury; hurt; damage",
              synonyms: [],
              antonyms: [],
              example: "No harm came to my possessions.",
            },
            {
              definition: "Emotional or figurative hurt",
              synonyms: [],
              antonyms: [],
              example:
                "Although not physically injured in the car accident, she received some psychological harm.",
            },
            {
              definition: "Detriment; misfortune.",
              synonyms: [],
              antonyms: [],
              example: "I wish him no harm.",
            },
            {
              definition: "That which causes injury, damage, or loss.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cause injury to another; to hurt; to cause damage to something.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/harm",
        "https://en.wiktionary.org/wiki/harms",
      ],
    },
  ],
  rears: [
    {
      word: "rears",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To bring up to maturity, as offspring; to educate; to instruct; to foster.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(said of people towards animals) To breed and raise.",
              synonyms: [],
              antonyms: [],
              example: "The family has been rearing cattle for 200 years.",
            },
            {
              definition: "To rise up on the hind legs",
              synonyms: [],
              antonyms: [],
              example: "The horse was shocked, and thus reared.",
            },
            {
              definition: '(usually with "up") To get angry.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To rise high above, tower above.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To raise physically or metaphorically; to lift up; to cause to rise, to elevate.",
              synonyms: [],
              antonyms: [],
              example: "The monster slowly reared its head.",
            },
            {
              definition: "To construct by building; to set up",
              synonyms: [],
              antonyms: [],
              example: "to rear defenses or houses",
            },
            {
              definition:
                "To raise spiritually; to lift up; to elevate morally.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To lift and take up.", synonyms: [], antonyms: [] },
            {
              definition: "To rouse; to strip up.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["prance"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To move; stir.", synonyms: [], antonyms: [] },
            {
              definition: "(of geese) To carve.",
              synonyms: [],
              antonyms: [],
              example: "Rere that goose!",
            },
            {
              definition:
                "(regional, obsolete) To revive, bring to life, quicken. (only in the phrase, to rear to life)",
              synonyms: [],
              antonyms: [],
              example: "He healeth the blind and he reareth to life the dead.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The back or hindmost part; that which is behind, or last on order; - opposed to front.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Specifically, the part of an army or fleet which comes last, or is stationed behind the rest.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The buttocks, a creature's bottom",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["rear end"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To place in the rear; to secure the rear of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To sodomize (perform anal sex)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rear",
        "https://en.wiktionary.org/wiki/rears",
      ],
    },
  ],
  satyr: [
    {
      word: "satyr",
      phonetic: "/ˈsæt.ə(ɹ)/",
      phonetics: [
        { text: "/ˈsæt.ə(ɹ)/", audio: "" },
        { text: "/ˈseɪt.əɹ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A woodland creature with pointed ears, legs, and short horns of a goat and a fondness for unrestrained revelry.",
              synonyms: ["satyr"],
              antonyms: [],
            },
            {
              definition:
                "Any of various nymphalid butterflies of the genus Faunis.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["satyr"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sylvan deity or demigod, male companion of Pan or Dionysus, represented as part man and part goat, and characterized by riotous merriment and lasciviousness, sometimes pictured with a perpetual erection.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) A lecherous man.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various butterflies of the nymphalid subfamily Satyrinae, having brown wings marked with eyelike spots; a meadow brown.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The orangutan.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/faun",
        "https://en.wiktionary.org/wiki/satyr",
      ],
    },
  ],
  swill: [
    {
      word: "swill",
      phonetic: "/swɪl/",
      phonetics: [{ text: "/swɪl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(collective) A mixture of solid and liquid food scraps fed to pigs etc; especially kitchen waste for this purpose.",
              synonyms: ["hogwash", "slops"],
              antonyms: [],
            },
            {
              definition:
                "(by extension) Any disgusting or distasteful liquid.",
              synonyms: [],
              antonyms: [],
              example: "I cannot believe anyone could drink this swill.",
            },
            {
              definition: "(by extension) Anything disgusting or worthless.",
              synonyms: [],
              antonyms: [],
              example: "This new TV show is a worthless load of swill.",
            },
            {
              definition: "A large quantity of liquid drunk at one swallow.",
              synonyms: ["swig"],
              antonyms: [],
              example:
                "He took a swill of his drink and tried to think of words.",
            },
            {
              definition: "Inexpensive beer or alcohol.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(Ultimate Frisbee) A badly-thrown pass.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["pigswill", "slops", "hogwash", "slops", "swig"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To drink (or, rarely, eat) greedily or to excess.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To wash (something) by flooding with water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To move (a liquid or liquid-filled vessel) in a circular motion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of a liquid) To move around or over a surface.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To inebriate; to fill with drink.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To feed swill to (pigs).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/swill"],
    },
  ],
  hexes: [
    {
      word: "hexes",
      phonetic: "/ˈhɛksɪz/",
      phonetics: [{ text: "/ˈhɛksɪz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An evil spell or curse.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A witch.", synonyms: [], antonyms: [] },
            {
              definition:
                "A spell (now rare but still found in compounds such as hex sign and hexcraft).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cast a spell on (specifically an evil spell), to bewitch.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A hexagonal space on a game board.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hexagon-shaped item of rock climbing equipment intended to be wedged into a crack or other opening in the rock.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hex",
        "https://en.wiktionary.org/wiki/hexes",
      ],
    },
  ],
  colic: [
    {
      word: "colic",
      phonetic: "/ˈkɒl.ɪk/",
      phonetics: [{ text: "/ˈkɒl.ɪk/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Severe pains that grip the abdomen or the disease that causes such pains (due to intestinal or bowel-related problems).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A medicinal plant used to relieve such symptoms.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Relating to the colon; colonic.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/colic"],
    },
  ],
  leeks: [
    {
      word: "leeks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The vegetable Allium ampeloprasum var. porrum, having edible leaves and an onion-like bulb but with a milder flavour than the onion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of several species of Allium, broadly resembling the domesticated plant in appearance in the wild.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["scallion", "the Cambrian symbol"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/leek",
        "https://en.wiktionary.org/wiki/leeks",
      ],
    },
  ],
  hurls: [
    {
      word: "hurls",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To throw (something) with force.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To utter (harsh or derogatory speech), especially at its target.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To participate in the sport of hurling.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To vomit.", synonyms: [], antonyms: [] },
            { definition: "To twist or turn.", synonyms: [], antonyms: [] },
            {
              definition: "To move rapidly with a noise; to whirl.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To convey in a wheeled vehicle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hurl",
        "https://en.wiktionary.org/wiki/hurls",
      ],
    },
  ],
  yowls: [
    {
      word: "yowls",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A prolonged, loud cry, like the sound of an animal; a wail; a howl.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "Utter a yowl.", synonyms: [], antonyms: [] },
            {
              definition: "Express by yowling; utter with a yowl.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/yowl",
        "https://en.wiktionary.org/wiki/yowls",
      ],
    },
  ],
  ivies: [
    {
      word: "ivies",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of several woody, climbing or trailing evergreen plants of the genus Hedera.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any similar plant of any genus.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ivies",
        "https://en.wiktionary.org/wiki/ivy",
      ],
    },
  ],
  plops: [
    {
      word: "plops",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sound or action like liquid hitting a hard surface, or an object falling into a body of water.",
              synonyms: [],
              antonyms: [],
              example: "He heard the plops of rain on the roof.",
            },
            {
              definition:
                'Excrement; derived from the "plop" sound made when it hits water in a toilet.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make the sound of an object dropping into a body of liquid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To land heavily or loosely.",
              synonyms: [],
              antonyms: [],
              example: "He plopped down on the sofa to watch TV.",
            },
            {
              definition:
                'To defecate; derived from the "plop" sound made when excrement hits water in a toilet.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/plop",
        "https://en.wiktionary.org/wiki/plops",
      ],
    },
  ],
  musks: [
    {
      word: "musks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A greasy secretion with a powerful odour, produced in a glandular sac of the male musk deer and used in the manufacture of perfumes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A similar secretion produced by the otter and the civet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A synthetic organic compound used as a substitute for the above.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The odour of musk.", synonyms: [], antonyms: [] },
            {
              definition: "The musk deer (genus Moschus).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The musk plant (Mimulus moschatus).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A plant of the genus Erodium (Erodium moschatum); the musky heronsbill.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A plant of the genus Muscari; grape hyacinth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The scent of human genitalia when aroused or unwashed.",
              synonyms: [],
              antonyms: [],
              example: "I was so excited I could smell my own musk.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/musk",
        "https://en.wiktionary.org/wiki/musks",
      ],
    },
  ],
  papaw: [
    {
      word: "papaw",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A tree, Carica papaya, of tropical America, belonging to the order Brassicales, and producing dull orange-colored, melon-shaped fruit.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/papaw"],
    },
    {
      word: "papaw",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(less commonly) A father.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["pawpaw"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Grandfather.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["peepaw", "pepaw"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/papaw",
        "https://en.wiktionary.org/wiki/pawpaw",
      ],
    },
  ],
  jells: [
    {
      word: "jells",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A jelly or gel", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/jell",
        "https://en.wiktionary.org/wiki/jells",
      ],
    },
  ],
  bused: [
    {
      word: "bused",
      phonetic: "/bʌst/",
      phonetics: [{ text: "/bʌst/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To transport via a motor bus.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To transport students to school, often to a more distant school for the purposes of achieving racial integration.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To travel by bus.", synonyms: [], antonyms: [] },
            {
              definition: "(food service) To clear meal remains from.",
              synonyms: [],
              antonyms: [],
              example: "He bussed tables as the restaurant emptied out.",
            },
            {
              definition:
                "(food service) To work at clearing the remains of meals from tables or counters; to work as a busboy.",
              synonyms: [],
              antonyms: [],
              example: "He’s been bussing for minimum wage.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bus",
        "https://en.wiktionary.org/wiki/bused",
      ],
    },
  ],
  cruet: [
    {
      word: "cruet",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small bottle or container used to hold a condiment, such as salt, pepper, oil, or vinegar, for use at a dining table.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A stand for these containers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small vessel used to hold wine or water for the Eucharist.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cruet"],
    },
  ],
  bided: [
    {
      word: "bided",
      phonetic: "/ˈbaɪdɪd/",
      phonetics: [{ text: "/ˈbaɪdɪd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To bear; to endure; to tolerate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To dwell or reside in a location; to abide.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To wait; to be in expectation; to stay; to remain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To wait for; to await.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["put up with", "live", "stand by", "await"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bide",
        "https://en.wiktionary.org/wiki/bided",
      ],
    },
  ],
  filch: [
    {
      word: "filch",
      phonetic: "/fɪltʃ/",
      phonetics: [
        {
          text: "/fɪltʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/filch-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88675046",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/fɪltʃ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Something which has been filched or stolen.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An act of filching; larceny, theft.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person who filches; a filcher, a pilferer, a thief.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A hooked stick used to filch objects.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["larceny", "theft", "filcher", "pilferer", "thief"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To illegally take possession of (especially items of low value); to pilfer, to steal.",
              synonyms: [],
              antonyms: [],
              example: "Hey, someone filched my wallet!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/filch"],
    },
  ],
  zests: [
    {
      word: "zests",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The outer skin of a citrus fruit, used as a flavouring or garnish.",
              synonyms: [],
              antonyms: [],
              example: "The orange zest gives the strong flavor in this dish.",
            },
            {
              definition: "General vibrance of flavour.",
              synonyms: [],
              antonyms: [],
              example:
                "I add zest to the meat by rubbing it with a spice mixture before grilling.",
            },
            {
              definition:
                "(by extension) Enthusiasm; keen enjoyment; relish; gusto.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The woody, thick skin enclosing the kernel of a walnut.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["gusto", "relish", "punch", "spice", "tang", "zing"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To scrape the zest from a fruit.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To make more zesty.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/zest",
        "https://en.wiktionary.org/wiki/zests",
      ],
    },
  ],
  rooks: [
    {
      word: "rooks",
      phonetic: "/ɹʊks/",
      phonetics: [{ text: "/ɹʊks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A European bird, Corvus frugilegus, of the crow family.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A cheat or swindler; someone who betrays.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A type of firecracker used by farmers to scare birds of the same name.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A trick-taking game, usually played with a specialized deck of cards.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A bad deal, a rip-off.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["cheat", "swindler"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To cheat or swindle.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["cheat", "con", "do", "dupe", "have", "swindle"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A piece shaped like a castle tower, that can be moved only up, down, left or right (but not diagonally) or in castling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A castle or other fortification.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["castle", "fortress", "castle"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A rookie.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To squat; to ruck.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "Pronunciation spelling of look.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rook",
        "https://en.wiktionary.org/wiki/rooks",
      ],
    },
  ],
  laxly: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  rends: [
    {
      word: "rends",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To separate into parts with force or sudden violence; to split; to burst",
              synonyms: [],
              antonyms: [],
              example: "Lightning rends an oak.",
            },
            {
              definition:
                "To part or tear off forcibly; to take away by force; to amputate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be rent or torn; to become parted; to separate; to split.",
              synonyms: [],
              antonyms: [],
              example: "Relationships may rend if tempers flare.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rend",
        "https://en.wiktionary.org/wiki/rends",
      ],
    },
  ],
  loams: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  basks: [
    {
      word: "basks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To bathe in warmth; to be exposed to pleasant heat.",
              synonyms: [],
              antonyms: [],
              example: "to bask in the sun",
            },
            {
              definition:
                'To take great pleasure or satisfaction; to feel warmth or happiness. (This verb is usually followed by "in").',
              synonyms: [],
              antonyms: [],
              example: "I basked in her love.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bask",
        "https://en.wiktionary.org/wiki/basks",
      ],
    },
  ],
  sires: [
    {
      word: "sires",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A lord, master, or other person in authority, most commonly used vocatively: formerly in speaking to elders and superiors, later only when addressing a sovereign.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A male animal; a stud, especially a horse or dog, that has fathered another.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A father; the head of a family; the husband.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A creator; a maker; an author; an originator.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(of a male) to procreate; to father, beget, impregnate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sire",
        "https://en.wiktionary.org/wiki/sires",
      ],
    },
  ],
  carps: [
    {
      word: "carps",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of various freshwater fish of the family Cyprinidae, especially the common carp, Cyprinus carpio.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To complain about a fault; to harp on.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To say; to tell.", synonyms: [], antonyms: [] },
            {
              definition: "To find fault with; to censure.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/carp",
        "https://en.wiktionary.org/wiki/carps",
      ],
    },
  ],
  pokey: [
    {
      word: "pokey",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pokey-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79218824",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈpoʊ.ki/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: '(with "the") prison.', synonyms: [], antonyms: [] },
          ],
          synonyms: ["poke"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "(of a room, house) of small volume, cramped",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Slow", synonyms: [], antonyms: [] },
            { definition: "(of a car) fast", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pokey"],
    },
  ],
  flits: [
    {
      word: "flits",
      phonetic: "/flɪts/",
      phonetics: [{ text: "/flɪts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A fluttering or darting movement.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A particular, unexpected, short lived change of state.",
              synonyms: [],
              antonyms: [],
              example: "My computer just had a flit.",
            },
            { definition: "A homosexual.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To move about rapidly and nimbly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To move quickly from one location to another.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To unpredictably change state for short periods of time.",
              synonyms: [],
              antonyms: [],
              example: "My blender flits because the power cord is damaged.",
            },
            {
              definition:
                "To move house (sometimes a sudden move to avoid debts).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be unstable; to be easily or often moved.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/flit",
        "https://en.wiktionary.org/wiki/flits",
      ],
    },
  ],
  muses: [
    {
      word: "muses",
      phonetic: "/mjuːzɪz/",
      phonetics: [{ text: "/mjuːzɪz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A source of inspiration.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A poet; a bard.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["Pierian spring"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An act of musing; a period of thoughtfulness.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To become lost in thought, to ponder.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To say (something) with due consideration or thought.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To think on; to meditate on.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To wonder at.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A gap or hole in a hedge, fence, etc. through which a wild animal is accustomed to pass; a muset.",
              synonyms: [],
              antonyms: [],
              example: "Find a hare without a muse. (old proverb)",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/muse",
        "https://en.wiktionary.org/wiki/muses",
      ],
    },
  ],
  bawls: [
    {
      word: "bawls",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To shout or utter in a loud and intense manner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To wail; to give out a blaring cry.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bawl",
        "https://en.wiktionary.org/wiki/bawls",
      ],
    },
  ],
  shuck: [
    {
      word: "shuck",
      phonetic: "/ʃʌk/",
      phonetics: [
        {
          text: "/ʃʌk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shuck-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50423058",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The shell or husk, especially of grains (e.g. corn/maize) or nuts (e.g. walnuts).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A fraud; a scam.", synonyms: [], antonyms: [] },
            { definition: "A phony.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To remove the shuck from (walnuts, oysters, etc.).",
              synonyms: [],
              antonyms: [],
              example: "Shall we shuck walnuts?",
            },
            {
              definition: "To remove (any outer covering).",
              synonyms: [],
              antonyms: [],
              example: "I will shuck my clothes and dive naked into the pool.",
            },
            { definition: "To fool; to hoax.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shuck"],
    },
  ],
  viler: [
    {
      word: "viler",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Morally low; base; despicable.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Causing physical or mental repulsion; horrid.",
              synonyms: [],
              antonyms: [],
              example: "I glimpsed a vile squid-like creature in the depths.",
            },
          ],
          synonyms: ["base", "despicable", "ignoble", "mean"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/vile",
        "https://en.wiktionary.org/wiki/viler",
      ],
    },
  ],
  lisps: [
    {
      word: "lisps",
      phonetic: "/lɪsps/",
      phonetics: [
        {
          text: "/lɪsps/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lisps-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=85511918",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The habit or an act of lisping.",
              synonyms: [],
              antonyms: [],
              example:
                "He used to have a terrible lisp before going to a speech therapist.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To pronounce the consonant ‘s’ imperfectly; to give ‘s’ and ‘z’ the sounds of ‘th’ (/θ/). This is a speech impediment common among children.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To speak with imperfect articulation; to mispronounce, such as a child learning to talk.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To speak hesitatingly and with a low voice, as if afraid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To express by the use of simple, childlike language.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To speak with reserve or concealment; to utter timidly or confidentially.",
              synonyms: [],
              antonyms: [],
              example: "to lisp treason",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lisp",
        "https://en.wiktionary.org/wiki/lisps",
      ],
    },
  ],
  peeps: [
    {
      word: "peeps",
      phonetic: "/piːps/",
      phonetics: [
        {
          text: "/piːps/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/peeps-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241842",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A quiet sound, particularly one from a baby bird.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A feeble utterance or complaint.",
              synonyms: [],
              antonyms: [],
              example: "I don't want to hear a peep out of you!",
            },
            {
              definition:
                "The sound of a steam engine's whistle; typically shrill.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A kind of bird; a sandpiper.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make a soft, shrill noise like a baby bird.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To speak briefly with a quiet voice.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A quick look or glimpse, especially a furtive one.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The first partial appearance of something; a beginning to appear.",
              synonyms: [],
              antonyms: [],
              example: "the peep of day",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To look, especially through a narrow opening, or while trying not to be seen or noticed.",
              synonyms: [],
              antonyms: [],
              example: "The man peeped through the small hole.",
            },
            {
              definition:
                "To begin to appear; to look forth from concealment; to make the first appearance.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A spot on a die or domino.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["pip"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "Person.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/peep",
        "https://en.wiktionary.org/wiki/peeps",
      ],
    },
    {
      word: "peeps",
      phonetic: "/piːps/",
      phonetics: [
        {
          text: "/piːps/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/peeps-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241842",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Used as plural of person; a body of human beings considered generally or collectively; a group of two or more persons.",
              synonyms: ["lede", "leod", "peeps"],
              antonyms: [],
              example:
                "There were so many people at the restaurant last night.",
            },
            {
              definition:
                "Persons forming or belonging to a particular group, such as a nation, class, ethnic group, country, family, etc.",
              synonyms: ["collective", "community", "congregation", "folk"],
              antonyms: [],
            },
            {
              definition:
                "A group of persons regarded as being employees, followers, companions or subjects of a ruler.",
              synonyms: ["fans", "groupies", "supporters"],
              antonyms: [],
            },
            {
              definition: "One's colleagues or employees.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person's ancestors, relatives or family.",
              synonyms: ["folks", "kin", "kith"],
              antonyms: [],
              example:
                "My people lived through the Black Plague and the Thirty Years War.",
            },
            {
              definition:
                "The mass of a community as distinguished from a special class (elite); the commonalty; the populace; the vulgar; the common crowd; the citizens.",
              synonyms: ["citizenry", "commoners", "populace"],
              antonyms: [],
            },
          ],
          synonyms: [
            "citizenry",
            "commoners",
            "populace",
            "collective",
            "community",
            "congregation",
            "folk",
            "fans",
            "groupies",
            "supporters",
            "folks",
            "kin",
            "kith",
            "lede",
            "leod",
            "peeps",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/peeps",
        "https://en.wiktionary.org/wiki/people",
      ],
    },
  ],
  sorer: [
    {
      word: "sorer",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Causing pain or discomfort; painfully sensitive.",
              synonyms: [],
              antonyms: [],
              example: "Her feet were sore from walking so far.",
            },
            {
              definition:
                "Sensitive; tender; easily pained, grieved, or vexed; very susceptible of irritation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Dire; distressing.",
              synonyms: [],
              antonyms: [],
              example:
                "The school was in sore need of textbooks, theirs having been ruined in the flood.",
            },
            {
              definition:
                "Feeling animosity towards someone; annoyed or angered.",
              synonyms: [],
              antonyms: [],
              example: "Joe was sore at Bob for beating him at checkers.",
            },
            {
              definition: "Criminal; wrong; evil.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sore",
        "https://en.wiktionary.org/wiki/sorer",
      ],
    },
  ],
  lolls: [
    {
      word: "lolls",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To act lazily or indolently while reclining; to lean; to lie at ease.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To hang extended from the mouth, like the tongue of an animal heated from exertion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To let (the tongue) hang from the mouth in this way.",
              synonyms: [],
              antonyms: [],
              example: "The ox stood lolling in the furrow.",
            },
          ],
          synonyms: ["relax", "slack", "slacken"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/loll",
        "https://en.wiktionary.org/wiki/lolls",
      ],
    },
  ],
  prude: [
    {
      word: "prude",
      phonetic: "/pɹuːd/",
      phonetics: [
        {
          text: "/pɹuːd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/prude-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90572966",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person who is or tries to be excessively proper, especially one who is easily offended by matters of a sexual nature.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "antisexualist",
            "celibacist",
            "erotophobe",
            "goody-goody",
            "prig",
            "puritan",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [{ definition: "Prudish.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/prude"],
    },
  ],
  diked: [
    {
      word: "diked",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "Alternative form of dyke: to dig a ditch; to raise an earthwork; etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To be well dressed.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dike",
        "https://en.wiktionary.org/wiki/diked",
      ],
    },
  ],
  floss: [
    {
      word: "floss",
      phonetic: "/flɑs/",
      phonetics: [
        { text: "/flɑs/", audio: "" },
        { text: "/flɒs/", audio: "" },
        {
          text: "/flɔs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/floss-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=694252",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A thread used to clean the gaps between the teeth.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Raw silk fibres.", synonyms: [], antonyms: [] },
            {
              definition:
                "The fibres covering a corncob etc.; the loose downy or silky material inside the husks of certain plants, such as beans.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any thread-like material having parallel strands that are not spun or wound around each other.",
              synonyms: [],
              antonyms: [],
              example: "embroidery floss",
            },
            {
              definition:
                'Spun sugar or cotton candy, especially in the phrase "candy floss".',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A body feather of an ostrich.",
              synonyms: [],
              antonyms: [],
              example:
                "Flosses are soft, and grey from the female and black from the male.",
            },
            {
              definition:
                "A dance move in which the dancer repeatedly swings their arms, with clenched fists, from the back of their body to the front, on each side.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To clean the area between the teeth using floss.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To show off, especially by exhibiting one's wealth or talent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To perform the floss dance move.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/floss"],
    },
    {
      word: "floss",
      phonetic: "/flɑs/",
      phonetics: [
        { text: "/flɑs/", audio: "" },
        { text: "/flɒs/", audio: "" },
        {
          text: "/flɔs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/floss-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=694252",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A small stream of water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Fluid glass floating on iron in the puddling furnace, produced by the vitrification of oxides and earths which are present.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/floss"],
    },
  ],
  flogs: [
    {
      word: "flogs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A contemptible, often arrogant person.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To whip or scourge someone or something as punishment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To use something to extreme; to abuse.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To sell.", synonyms: [], antonyms: [] },
            { definition: "To steal something.", synonyms: [], antonyms: [] },
            {
              definition: "To defeat easily or convincingly.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To exploit.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A weblog designed to look authentic, but actually developed as part of a commercial marketing strategy to promote some product or service.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/flog",
        "https://en.wiktionary.org/wiki/flogs",
      ],
    },
  ],
  scums: [
    {
      word: "scums",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A layer of impurities that accumulates at the surface of a liquid (especially molten metal or water).",
              synonyms: [],
              antonyms: [],
              example:
                "During smelting, scum rises to the surface and is then removed by the smelter.",
            },
            {
              definition:
                "A greenish water vegetation (such as algae), usually found floating on the surface of ponds",
              synonyms: [],
              antonyms: [],
              example: "These organisms form scum in large quantities.",
            },
            {
              definition:
                "The topmost liquid layer of a cesspool or septic tank.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "(chiefly US) semen", synonyms: [], antonyms: [] },
            {
              definition: "A reprehensible person or persons.",
              synonyms: [],
              antonyms: [],
              example:
                "People who sell used-up pens are scum, just total low-lives.",
            },
          ],
          synonyms: [
            "dross",
            "impurities",
            "cinder",
            "scoriae",
            "slag",
            "bastard",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/scum",
        "https://en.wiktionary.org/wiki/scums",
      ],
    },
  ],
  dopes: [
    {
      word: "dopes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any viscous liquid or paste, such as a lubricant, used in preparing a surface.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An absorbent material used to hold a liquid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any varnish used to coat a part, such as an airplane wing or a hot-air balloon in order to waterproof, strengthen, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any illicit or narcotic drug that produces euphoria or satisfies an addiction; particularly heroin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Information, usually from an inside source, originally in horse racing and other sports.",
              synonyms: [],
              antonyms: [],
              example: "What's the latest dope on the stock market?",
            },
            {
              definition:
                "(fireams) Ballistic data on previously fired rounds, used to calculate the required hold over a target.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A stupid person.", synonyms: [], antonyms: [] },
            { definition: "Dessert topping.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To affect with drugs.", synonyms: [], antonyms: [] },
            {
              definition: "To treat with dope (lubricant, etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To add a dopant such as arsenic to (a pure semiconductor such as silicon).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To use drugs; especially, to use prohibited performance-enhancing drugs in sporting competitions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To judge or guess; to predict the result of.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dope",
        "https://en.wiktionary.org/wiki/dopes",
      ],
    },
  ],
  bogie: [
    {
      word: "bogie",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bogie-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75268180",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈbəʊɡi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bogie-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80175023",
        },
        { text: "/ˈboʊɡi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One who robs others in a lawless area, especially as part of a group.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An outlaw.", synonyms: [], antonyms: [] },
            {
              definition: "One who cheats others.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An enemy aircraft.", synonyms: [], antonyms: [] },
            {
              definition:
                "A runner who covertly joins a race without having registered as a participant.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["cheater", "criminal", "fugitive", "outlaw"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An obnoxious, selfish and overbearing person; an attention hog.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(often capitalized, usually with definite article) The Devil.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A ghost, goblin, or other hostile supernatural creature.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A bugbear: any terrifying thing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A standard of performance set up as a mark to be aimed at in competition.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An unidentified aircraft, especially as observed as a spot on a radar screen and suspected to be hostile.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A score of one over par on a hole.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["Devil", "goblin", "railroad truck"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A bog-standard (representative) specimen taken from the center of production.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A swim or bathe; a bath.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One of two sets of wheels under a locomotive or railcar; the structure with axles and wheels under a locomotive, railcar, or semi.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(Indian English, dated in UK) A railway carriage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A toy similar to a violin bow, consisting of a wooden stick with notches along one or more sides or edges to produce a rattly noise when stroked against a hard edge, eg, the lip of container.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["goblin", "railroad truck"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A piece of solid or semisolid mucus in or removed from a nostril.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Something suggestive of this material.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A thing; especially a problematic or difficult thing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bogey", "bogie", "snot", "bugger"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(mildly derogatory) A bodyboarder.",
              synonyms: [],
              antonyms: [],
              example: "Watch the local boogers charge it!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bandit",
        "https://en.wiktionary.org/wiki/bogart",
        "https://en.wiktionary.org/wiki/bogey",
        "https://en.wiktionary.org/wiki/bogie",
        "https://en.wiktionary.org/wiki/booger",
      ],
    },
    {
      word: "bogie",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bogie-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75268180",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈbəʊɡi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bogie-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80175023",
        },
        { text: "/ˈboʊɡi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One who robs others in a lawless area, especially as part of a group.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An outlaw.", synonyms: [], antonyms: [] },
            {
              definition: "One who cheats others.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An enemy aircraft.", synonyms: [], antonyms: [] },
            {
              definition:
                "A runner who covertly joins a race without having registered as a participant.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["cheater", "criminal", "fugitive", "outlaw"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An obnoxious, selfish and overbearing person; an attention hog.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(often capitalized, usually with definite article) The Devil.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A ghost, goblin, or other hostile supernatural creature.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A bugbear: any terrifying thing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A standard of performance set up as a mark to be aimed at in competition.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An unidentified aircraft, especially as observed as a spot on a radar screen and suspected to be hostile.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A score of one over par on a hole.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["Devil", "goblin", "railroad truck"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A bog-standard (representative) specimen taken from the center of production.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A swim or bathe; a bath.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One of two sets of wheels under a locomotive or railcar; the structure with axles and wheels under a locomotive, railcar, or semi.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(Indian English, dated in UK) A railway carriage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A toy similar to a violin bow, consisting of a wooden stick with notches along one or more sides or edges to produce a rattly noise when stroked against a hard edge, eg, the lip of container.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["goblin", "railroad truck"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A piece of solid or semisolid mucus in or removed from a nostril.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Something suggestive of this material.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A thing; especially a problematic or difficult thing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bogey", "bogie", "snot", "bugger"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(mildly derogatory) A bodyboarder.",
              synonyms: [],
              antonyms: [],
              example: "Watch the local boogers charge it!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bandit",
        "https://en.wiktionary.org/wiki/bogart",
        "https://en.wiktionary.org/wiki/bogey",
        "https://en.wiktionary.org/wiki/bogie",
        "https://en.wiktionary.org/wiki/booger",
      ],
    },
    {
      word: "bogie",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bogie-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75268180",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈbəʊɡi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bogie-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80175023",
        },
        { text: "/ˈboʊɡi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An obnoxious, selfish and overbearing person; an attention hog.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bogart",
        "https://en.wiktionary.org/wiki/bogie",
      ],
    },
  ],
  pinky: [
    {
      word: "pinky",
      phonetic: "/ˈpɪŋki/",
      phonetics: [
        {
          text: "/ˈpɪŋki/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pinky-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75648957",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Methylated spirits mixed with red wine or Condy's crystals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A baby mouse, especially when used as food for a snake, etc.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A white person.", synonyms: [], antonyms: [] },
            {
              definition:
                "The Australasian snapper or pink snapper (Chrysophrys auratus).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [{ definition: "Pinkish.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pinky"],
    },
    {
      word: "pinky",
      phonetic: "/ˈpɪŋki/",
      phonetics: [
        {
          text: "/ˈpɪŋki/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pinky-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75648957",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The smallest finger or toe of a hand or foot.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "digit V",
            "fifth digit",
            "little finger",
            "little piggy",
            "pinky finger",
            "pinky toe",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pinky"],
    },
    {
      word: "pinky",
      phonetic: "/ˈpɪŋki/",
      phonetics: [
        {
          text: "/ˈpɪŋki/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pinky-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75648957",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [{ definition: "Winking", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pinky"],
    },
  ],
  leafs: [
    {
      word: "leafs",
      phonetic: "/liːfs/",
      phonetics: [
        {
          text: "/liːfs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/leafs-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90913507",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To produce leaves; put forth foliage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To divide (a vegetable) into separate leaves.",
              synonyms: [],
              antonyms: [],
              example: "The lettuce in our burgers is 100% hand-leafed.",
            },
          ],
          synonyms: ["leave"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/leaf",
        "https://en.wiktionary.org/wiki/leafs",
      ],
    },
  ],
  tubas: [
    {
      word: "tubas",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A large brass musical instrument, usually in the bass range, played through a vibration of the lips upon the mouthpiece and fingering of the keys.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A type of Roman military trumpet, distinct from the modern tuba.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A large reed stop in organs.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["grunt-horn"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A reddish palm wine made from coconut or nipa sap.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A tube or tubular organ.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tuba",
        "https://en.wiktionary.org/wiki/tubas",
      ],
    },
  ],
  scads: [
    {
      word: "scads",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of several fish, of the family Carangidae, from the western Atlantic.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(in the plural) A large number or quantity.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/scad",
        "https://en.wiktionary.org/wiki/scads",
      ],
    },
  ],
  lowed: [
    {
      word: "lowed",
      phonetic: "/ləʊd/",
      phonetics: [
        { text: "/ləʊd/", audio: "" },
        {
          text: "/loʊd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lowed-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50014391",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To depress; to lower.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To moo.",
              synonyms: [],
              antonyms: [],
              example: "The cattle were lowing.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To burn; to blaze.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/low",
        "https://en.wiktionary.org/wiki/lowed",
      ],
    },
  ],
  yeses: [
    {
      word: "yeses",
      phonetic: "/jɛsɪz/",
      phonetics: [{ text: "/jɛsɪz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An affirmative expression; an answer that shows agreement or acceptance.",
              synonyms: ["aye", "nod", "yea"],
              antonyms: ["nay", "no"],
              example: "Was that a yes?",
            },
            {
              definition: "A vote of support or in favor/favour of something.",
              synonyms: ["aye", "yea"],
              antonyms: ["nay"],
              example:
                'The workers voted on whether to strike, and there were thirty "yeses" and one "no".',
            },
          ],
          synonyms: ["aye", "nod", "yea", "aye", "yea"],
          antonyms: ["nay", "nay", "no"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To agree with, affirm, approve.",
              synonyms: ["agree", "consent", "nod"],
              antonyms: [],
              example: "Did he yes the veto?",
            },
            {
              definition:
                "To attempt to flatter someone by habitually agreeing",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["agree", "consent", "nod"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/yes",
        "https://en.wiktionary.org/wiki/yeses",
      ],
    },
  ],
  biked: [
    {
      word: "biked",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To ride a bike.",
              synonyms: [],
              antonyms: [],
              example: "I biked so much yesterday that I'm very sore today.",
            },
            {
              definition: "To travel by bike.",
              synonyms: [],
              antonyms: [],
              example:
                "It was such a nice day I decided to bike to the store, though it's far enough I usually take my car.",
            },
            {
              definition: "To transport by bicycle",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bike",
        "https://en.wiktionary.org/wiki/biked",
      ],
    },
  ],
  qualm: [
    {
      word: "qualm",
      phonetic: "/kwɑːm/",
      phonetics: [
        {
          text: "/kwɑːm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/qualm-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=87241099",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/kwɑlm/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A feeling of apprehension, doubt, fear etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sudden sickly feeling; queasiness.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A prick of the conscience; a moral scruple, a pang of guilt. (Now often in negative constructions.)",
              synonyms: [],
              antonyms: [],
              example:
                "This lawyer has no qualms about saving people who are on the wrong side of the law.",
            },
            {
              definition: "Mortality; plague; pestilence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A calamity or disaster.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "compunction",
            "misgiving",
            "scruple",
            "unease",
            "uneasiness",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To have a sickly feeling.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/qualm"],
    },
  ],
  evens: [
    {
      word: "evens",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(diminutive) An even number.",
              synonyms: [],
              antonyms: [],
              example: "So let's see. There are two evens here and three odds.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make flat and level.",
              synonyms: [],
              antonyms: [],
              example:
                "We need to even this playing field; the west goal is too low.",
            },
            { definition: "To equal.", synonyms: [], antonyms: [] },
            {
              definition: "To be equal.",
              synonyms: [],
              antonyms: [],
              example: "Thrice nine evens twenty seven.",
            },
            {
              definition:
                "To place in an equal state, as to obligation, or in a state in which nothing is due on either side; to balance, as accounts; to make quits.",
              synonyms: [],
              antonyms: [],
              example: "We need to even the score.",
            },
            {
              definition: "To set right; to complete.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To act up to; to keep pace with.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["match", "flatten", "level", "settle"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "Evening.", synonyms: [], antonyms: [] }],
          synonyms: ["evening", "eventide"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/even",
        "https://en.wiktionary.org/wiki/evens",
      ],
    },
  ],
  caned: [
    {
      word: "caned",
      phonetic: "/keɪnd/",
      phonetics: [
        {
          text: "/keɪnd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/caned-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79325627",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To strike or beat with a cane or similar implement",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To destroy; to comprehensively defeat",
              synonyms: [],
              antonyms: [],
              example: "Mudchester Rovers were caned 10-0.",
            },
            {
              definition: "To do something well, in a competent fashion",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To produce extreme pain",
              synonyms: [],
              antonyms: [],
              example: "Don't hit me with that. It really canes!",
            },
            {
              definition: "To make or furnish with cane or rattan.",
              synonyms: [],
              antonyms: [],
              example: "to cane chairs",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Intoxicated by alcohol or drugs",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cane",
        "https://en.wiktionary.org/wiki/caned",
      ],
    },
    {
      word: "caned",
      phonetic: "/keɪnd/",
      phonetics: [
        {
          text: "/keɪnd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/caned-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79325627",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Filled with white flakes; mothery; said of vinegar when containing mother.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/caned"],
    },
  ],
  gawks: [
    {
      word: "gawks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To stare or gape stupidly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To stare conspicuously.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gawk",
        "https://en.wiktionary.org/wiki/gawks",
      ],
    },
  ],
  whits: [
    {
      word: "whits",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The smallest part or particle imaginable; an iota.",
              synonyms: [],
              antonyms: [],
              example:
                "He worked tirelessly to collect and wind a ball of string eight feet around, and it matters not one whit.",
            },
          ],
          synonyms: ["bit", "iota", "jot", "scrap"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/whit",
        "https://en.wiktionary.org/wiki/whits",
      ],
    },
  ],
  wooly: [
    {
      word: "wooly",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A sweater or similar garment made of wool.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sheep not yet shorn.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A piece of woolwork.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Made of wool.",
              synonyms: [],
              antonyms: [],
              example: "Put on a woolly jumper and turn down the thermostat.",
            },
            {
              definition: "Having a thick, soft texture, as if made of wool.",
              synonyms: [],
              antonyms: [],
              example:
                "There was nothing left in the fruit bowl but a brown banana and a couple of woolly pears.",
            },
            {
              definition:
                "(of thinking, principles, etc.) Based on emotions rather than logic.",
              synonyms: [],
              antonyms: [],
              example:
                "That's the sort of woolly thinking that causes wars to start.",
            },
            {
              definition: "Unclear, fuzzy, hazy, cloudy.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Clothed in wool.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A woolly back; someone from the area around Liverpool, not from Liverpool itself.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/woolly",
        "https://en.wiktionary.org/wiki/wooly",
      ],
    },
  ],
  gluts: [
    {
      word: "gluts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An excess, too much.",
              synonyms: [
                "excess",
                "overabundance",
                "plethora",
                "slew",
                "surfeit",
                "surplus",
              ],
              antonyms: ["lack", "shortage"],
              example: "a glut of the market",
            },
            {
              definition: "That which is swallowed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Something that fills up an opening.",
              synonyms: ["clog"],
              antonyms: [],
            },
            {
              definition: "A wooden wedge used in splitting blocks.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of wood used to fill up behind cribbing or tubbing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(bricklaying) A bat, or small piece of brick, used to fill out a course.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An arched opening to the ashpit of a kiln.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A block used for a fulcrum.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The broad-nosed eel (Anguilla anguilla, syn. Anguilla latirostris), found in Europe, Asia, the West Indies, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "clog",
            "excess",
            "overabundance",
            "plethora",
            "slew",
            "surfeit",
            "surplus",
          ],
          antonyms: ["lack", "shortage"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To fill to capacity; to satisfy all demand or requirement; to sate.",
              synonyms: [],
              antonyms: [],
              example: "to glut one's appetite",
            },
            {
              definition: "To eat gluttonously or to satiety.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/glut",
        "https://en.wiktionary.org/wiki/gluts",
      ],
    },
  ],
  romps: [
    {
      word: "romps",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Someone who romps; especially, a girl or young woman who indulges in boisterous play; a tomboy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A period of boisterous play, a frolic; now especially, a bout of sexual activity, especially when illicit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An enjoyable, fast-paced but essentially inconsequential film, play, or other piece of entertainment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A decisive victory; a game, match etc. which is won easily.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To play about roughly, energetically or boisterously.",
              synonyms: [],
              antonyms: [],
              example:
                "When the kids're allowed to romp in the bedroom, they break something.",
            },
            {
              definition:
                "(Often used with down) To press forcefully, to encourage vehemently, to oppress.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To win easily.",
              synonyms: [],
              antonyms: [],
              example: "England romped to an easy win over Australia.",
            },
            {
              definition: "To engage in playful or boisterous sex.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/romp",
        "https://en.wiktionary.org/wiki/romps",
      ],
    },
  ],
  bests: [
    {
      word: "bests",
      phonetic: "/bɛsts/",
      phonetics: [{ text: "/bɛsts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The supreme effort one can make, or has made.",
              synonyms: [],
              antonyms: [],
              example: "I did my best.",
            },
            {
              definition: "One's best behavior.",
              synonyms: [],
              antonyms: [],
              example:
                "I was somewhat distant lately, and my lady promised me head every Tuesday of the week when I'm nice to her, so I better be on my best.",
            },
            {
              definition:
                "The person (or persons; or thing or things) that is (are) most excellent.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To surpass in skill or achievement.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To beat in a contest", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: ["worst"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/best",
        "https://en.wiktionary.org/wiki/bests",
      ],
    },
  ],
  dunce: [
    {
      word: "dunce",
      phonetic: "/dʌns/",
      phonetics: [{ text: "/dʌns/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An unintelligent person.",
              synonyms: ["idiot"],
              antonyms: [],
            },
          ],
          synonyms: ["idiot"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dunce"],
    },
  ],
  crony: [
    {
      word: "crony",
      phonetic: "/ˈkɹəʊni/",
      phonetics: [
        { text: "/ˈkɹəʊni/", audio: "" },
        { text: "/ˈkɹoʊni/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(originally Cambridge University) Close friend.",
              synonyms: [],
              antonyms: ["noncrony"],
            },
            {
              definition:
                "Trusted companion or partner in a criminal organization.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["noncrony"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/crony"],
    },
    {
      word: "crony",
      phonetic: "/ˈkɹəʊni/",
      phonetics: [
        { text: "/ˈkɹəʊni/", audio: "" },
        { text: "/ˈkɹoʊni/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An old woman; a crone.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/crony"],
    },
  ],
  joist: [
    {
      word: "joist",
      phonetic: "/dʒɔɪst/",
      phonetics: [{ text: "/dʒɔɪst/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A piece of timber laid horizontally, or nearly so, to which the planks of the floor, or the laths or furring strips of a ceiling, are nailed.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To fit or furnish with joists.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/joist"],
    },
  ],
  tunas: [
    {
      word: "tunas",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of several species of fish of the genus Thunnus in the family Scombridae.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The edible flesh of the tuna.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["tuna fish", "tunny"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The prickly pear, a type of cactus native to Mexico in the genus Opuntia.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The fruit of the cactus.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tuna",
        "https://en.wiktionary.org/wiki/tunas",
      ],
    },
  ],
  boner: [
    {
      word: "boner",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/boner-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241064",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈbəʊ.nə(ɹ)/", audio: "" },
        { text: "/ˈboʊ.nɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An erect penis.",
              synonyms: [],
              antonyms: [],
              example:
                "Why do guys get boners when they wake up in the morning?",
            },
            {
              definition: "One who or that which bones (removes bones).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A blunder; a silly mistake.",
              synonyms: [],
              antonyms: [],
              example: "He really pulled a boner that time!",
            },
          ],
          synonyms: [
            "blooper",
            "blunder",
            "deboner",
            "erection",
            "hard-on",
            "stiffy",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/boner"],
    },
  ],
  malls: [
    {
      word: "malls",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A pedestrianised street, especially a shopping precinct.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An enclosed shopping centre.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An alley where the game of pall mall was played.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A public walk; a level shaded walk, a promenade.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A heavy wooden mallet or hammer used in the game of pall mall.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The game of polo.", synonyms: [], antonyms: [] },
            {
              definition:
                "An old game played with malls or mallets and balls; pall mall.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mall",
        "https://en.wiktionary.org/wiki/malls",
      ],
    },
  ],
  parch: [
    {
      word: "parch",
      phonetic: "/pɑːtʃ/",
      phonetics: [
        { text: "/pɑːtʃ/", audio: "" },
        {
          text: "/pɑɹtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/parch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=825471",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The condition of being parched.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To burn the surface of, to scorch.",
              synonyms: [],
              antonyms: [],
              example: "The sun today could parch cement.",
            },
            {
              definition: "To roast, as dry grain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To dry to extremity; to shrivel with heat.",
              synonyms: [],
              antonyms: [],
              example: "The patient's mouth is parched from fever.",
            },
            {
              definition: "To make thirsty.",
              synonyms: [],
              antonyms: [],
              example:
                "We're parched, hon. Could you send up an ale from the cooler?",
            },
            {
              definition:
                "To boil something slowly (Still used in Lancashire in parched peas, a type of mushy peas).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To become superficially burnt; be become sunburned.",
              synonyms: [],
              antonyms: [],
              example:
                "The locals watched, amused, as the tourists parched in the sun, having neglected to apply sunscreen or bring water.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/parch"],
    },
  ],
  avers: [
    {
      word: "avers",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To assert the truth of, to affirm with confidence; to declare in a positive manner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To prove or justify a plea.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To avouch, prove, or verify; to offer to verify.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/aver",
        "https://en.wiktionary.org/wiki/avers",
      ],
    },
  ],
  crams: [
    {
      word: "crams",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The act of cramming (forcing or stuffing something).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Information hastily memorized.",
              synonyms: [],
              antonyms: [],
              example: "a cram from an examination",
            },
            {
              definition:
                "A warp having more than two threads passing through each dent or split of the reed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(British slang) A lie; a falsehood.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A mathematical board game in which players take turns placing dominoes horizontally or vertically until no more can be placed, the loser being the player who cannot continue.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small friendship book with limited space for people to enter their information.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To press, force, or drive, particularly in filling, or in thrusting one thing into another; to stuff; to fill to superfluity.",
              synonyms: [],
              antonyms: [],
              example:
                "to cram fruit into a basket; to cram a room with people",
            },
            {
              definition: "To fill with food to satiety; to stuff.",
              synonyms: [],
              antonyms: [],
              example: "The boy crammed himself with cake",
            },
            {
              definition:
                "To put hastily through an extensive course of memorizing or study, as in preparation for an examination.",
              synonyms: [],
              antonyms: [],
              example: "A pupil is crammed by his tutor.",
            },
            {
              definition: "To study hard; to swot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To eat greedily, and to satiety; to stuff oneself.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(British slang) To lie; to intentionally not tell the truth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(British slang) To make (a person) believe false or exaggerated tales.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cram",
        "https://en.wiktionary.org/wiki/crams",
      ],
    },
  ],
  pares: [
    {
      word: "pares",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To remove the outer covering or skin of something with a cutting device, typically a knife",
              synonyms: [],
              antonyms: [],
              example:
                "Victor pared some apples in preparation to make a tart.",
            },
            {
              definition:
                "(often with down or back) to reduce, diminish or trim gradually something as if by cutting off",
              synonyms: [],
              antonyms: [],
              example:
                "Albert had to pare his options down by disregarding anything beyond his meager budget.",
            },
            {
              definition: "To trim the hoof of a horse",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["peel", "skin"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pare",
        "https://en.wiktionary.org/wiki/pares",
      ],
    },
  ],
  dally: [
    {
      word: "dally",
      phonetic: "/ˈdæli/",
      phonetics: [
        {
          text: "/ˈdæli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dally-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75730090",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈdæli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dally-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=4905306",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To waste time in trivial activities, or in idleness; to trifle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To caress, especially of a sexual nature; to fondle or pet",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To delay unnecessarily; to while away.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "kill time",
            "feel up",
            "grope",
            "touch up",
            "dawdle",
            "dilly-dally",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dally"],
    },
    {
      word: "dally",
      phonetic: "/ˈdæli/",
      phonetics: [
        {
          text: "/ˈdæli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dally-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75730090",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈdæli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dally-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=4905306",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Several wraps of rope around the saddle horn, used to stop animals in roping.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To wind the lasso rope (ie throw-rope) around the saddle horn (the saddle horn is attached to the pommel of a western style saddle) after the roping of an animal",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dally"],
    },
  ],
  bigot: [
    {
      word: "bigot",
      phonetic: "/ˈbɪɡət/",
      phonetics: [{ text: "/ˈbɪɡət/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One who is narrow-mindedly devoted to one's own ideas and groups, and intolerant of (people of) differing ideas, races, genders, religions, politics, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One who is overly pious in matters of religion, often hypocritically or else superstitiously so.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bigot"],
    },
  ],
  kales: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  flays: [
    {
      word: "flays",
      phonetic: "/fleɪz/",
      phonetics: [{ text: "/fleɪz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cause to fly; put to flight; drive off (by frightening).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To frighten; scare; terrify.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To be fear-stricken.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To strip skin off", synonyms: [], antonyms: [] },
            { definition: "To lash", synonyms: [], antonyms: [] },
          ],
          synonyms: ["fleece", "flense", "skin"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/flay",
        "https://en.wiktionary.org/wiki/flays",
      ],
    },
  ],
  leach: [
    {
      word: "leach",
      phonetic: "/liːtʃ/",
      phonetics: [
        { text: "/liːtʃ/", audio: "" },
        {
          text: "/liːtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/leach-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=20943275",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A quantity of wood ashes, through which water passes, and thus imbibes the alkali.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A tub or vat for leaching ashes, bark, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A jelly-like sweetmeat popular in the fifteenth century.",
              synonyms: [],
              antonyms: [],
              example:
                '1670 Hannah Woolley The Queen-like Closet, Or, Rich Cabinet https//en.m.wikipedia.org/wiki/The_Queen-Like_Closet "To make Leach and to colour it"',
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An aquatic blood-sucking annelid of class Hirudinea, especially Hirudo medicinalis.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person who derives profit from others in a parasitic fashion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A glass tube designed for drawing blood from damaged tissue by means of a vacuum.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bloodsucker", "parasite", "sponger", "vampire"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A physician.", synonyms: [], antonyms: [] },
            { definition: "(Heathenry) A healer.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["healer", "barber", "doctor", "physician"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The vertical edge of a square sail.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The aft edge of a triangular sail.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/leach",
        "https://en.wiktionary.org/wiki/leech",
      ],
    },
    {
      word: "leach",
      phonetic: "/liːtʃ/",
      phonetics: [
        { text: "/liːtʃ/", audio: "" },
        {
          text: "/liːtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/leach-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=20943275",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To purge a soluble matter out of something by the action of a percolating fluid.",
              synonyms: [],
              antonyms: [],
              example:
                "Heavy rainfall can leach out minerals important for plant growth from the soil.",
            },
            {
              definition: "To part with soluble constituents by percolation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/leach"],
    },
  ],
  gushy: [
    {
      word: "gushy",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Gushing; effusive and often emotional.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Tending to gush, to produce a large flow of liquid.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gushy"],
    },
  ],
  pooch: [
    {
      word: "pooch",
      phonetic: "/puːt͡ʃ/",
      phonetics: [
        {
          text: "/puːt͡ʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pooch-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241589",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A dog.", synonyms: [], antonyms: [] },
            {
              definition: "A dog of mixed breed; a mongrel.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pooch"],
    },
    {
      word: "pooch",
      phonetic: "/puːt͡ʃ/",
      phonetics: [
        {
          text: "/puːt͡ʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pooch-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241589",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A bulge, an enlarged part",
              synonyms: [],
              antonyms: [],
              example: "There's a pooch in the plastic where it got too hot.",
            },
            {
              definition: "A distended or swelled condition.",
              synonyms: [],
              antonyms: [],
              example:
                "Her left sleeve has more pooch at the shoulder than the right.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To distend, to swell or extend beyond normal limits; usually used with out.",
              synonyms: [],
              antonyms: [],
              example:
                "Inflate that tire too much and the tube may pooch out of the cut in the sidewall.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pooch"],
    },
  ],
  huger: [
    {
      word: "huger",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Very large.",
              synonyms: [],
              antonyms: [],
              example: "The castle was huge.",
            },
            {
              definition:
                "Distinctly interesting, significant, important, likeable, well regarded.",
              synonyms: [],
              antonyms: [],
              example:
                "Our next album is going to be huge!  In our league our coach is huge!",
            },
          ],
          synonyms: [
            "colossal",
            "elephantine",
            "enormous",
            "giant",
            "gigantic",
            "immense",
            "prodigious",
            "vast",
          ],
          antonyms: ["dwarf", "midget", "minuscule", "small", "tiny"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/huge",
        "https://en.wiktionary.org/wiki/huger",
      ],
    },
  ],
  slyer: [
    {
      word: "slyer",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Artfully cunning; secretly mischievous; wily.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(having a positive sense) Dexterous in performing an action, so as to escape notice",
              synonyms: ["cautious", "nimble", "shrewd", "skillful"],
              antonyms: [],
            },
            {
              definition:
                "Done with, and marked by, artful and dexterous secrecy; subtle",
              synonyms: [],
              antonyms: [],
              example: "a sly trick",
            },
            {
              definition: "Light or delicate; slight; thin.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "artful",
            "cautious",
            "nimble",
            "shrewd",
            "skillful",
            "crafty",
            "cunning",
            "knowing",
            "sharp",
            "shifty",
            "shrewd",
            "slim",
            "sly as a fox",
            "wily",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sly",
        "https://en.wiktionary.org/wiki/slyer",
      ],
    },
  ],
  golfs: [
    {
      word: "golfs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To play the game of golf.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To write something in as few characters as possible (e.g. in code golf, regex golf)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/golf",
        "https://en.wiktionary.org/wiki/golfs",
      ],
    },
  ],
  mires: [
    {
      word: "mires",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Deep mud; moist, spongy earth.",
              synonyms: ["peatland", "quag"],
              antonyms: [],
            },
            {
              definition: "An undesirable situation, a predicament.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["peatland", "quag"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cause or permit to become stuck in mud; to plunge or fix in mud.",
              synonyms: ["bemire", "enmire"],
              antonyms: [],
              example: "to mire a horse or wagon",
            },
            { definition: "To sink into mud.", synonyms: [], antonyms: [] },
            { definition: "To weigh down.", synonyms: [], antonyms: [] },
            {
              definition: "To soil with mud or foul matter.",
              synonyms: ["bemire"],
              antonyms: [],
            },
          ],
          synonyms: ["bemire", "bemire", "enmire"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "An ant.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mire",
        "https://en.wiktionary.org/wiki/mires",
      ],
    },
  ],
  flues: [
    {
      word: "flues",
      phonetic: "/fluːz/",
      phonetics: [{ text: "/fluːz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A pipe or duct that carries gaseous combustion products away from the point of combustion (such as a furnace).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An enclosed passageway in which to direct air or other gaseous current along.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A woolly or downy substance; down, nap; a piece of this.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In an organ flue pipe, the opening between the lower lip and the languet.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/flue",
        "https://en.wiktionary.org/wiki/flues",
      ],
    },
  ],
  loafs: [
    {
      word: "loafs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To do nothing, to be idle.",
              synonyms: [],
              antonyms: [],
              example: "loaf about, loaf around.",
            },
            {
              definition:
                "(Cockney rhyming slang) To headbutt, (from loaf of bread)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["idle", "laze", "lounge"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/loaf",
        "https://en.wiktionary.org/wiki/loafs",
      ],
    },
  ],
  arced: [
    {
      word: "arced",
      phonetic: "/ɑːkt/",
      phonetics: [{ text: "/ɑːkt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To move following a curved path.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To shape into an arc; to hold in the form of an arc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To form an electrical arc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having the form of an arc; arched.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/arc",
        "https://en.wiktionary.org/wiki/arced",
      ],
    },
  ],
  acnes: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  neons: [
    {
      word: "neons",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The chemical element (symbol Ne) with an atomic number of 10. The lightest of the noble gases, it is a colourless, odorless inert gas.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A form or sample of the element.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Neon signs or lights, collectively.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A neon tetra fish.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/neon",
        "https://en.wiktionary.org/wiki/neons",
      ],
    },
  ],
  fiefs: [
    {
      word: "fiefs",
      phonetic: "/fːifs/",
      phonetics: [{ text: "/fːifs/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An estate held by a person on condition of providing military service to a superior.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something over which one has rights or exercises control.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An area of dominion, especially in a corporate or governmental bureaucracy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["manor", "seigniory"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fief",
        "https://en.wiktionary.org/wiki/fiefs",
      ],
    },
  ],
  dints: [
    {
      word: "dints",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A blow, stroke, especially dealt in a fight.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Force, power; especially in by dint of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The mark left by a blow; an indentation or impression made by violence; a dent.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dint",
        "https://en.wiktionary.org/wiki/dints",
      ],
    },
  ],
  dazes: [
    {
      word: "dazes",
      phonetic: "/ˈdeɪzɪz/",
      phonetics: [{ text: "/ˈdeɪzɪz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The state of being dazed",
              synonyms: [],
              antonyms: [],
              example: "He was in a daze.",
            },
            { definition: "A glittering stone.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To stun or stupefy, for example with bright light, with a blow, with cold, or with fear",
              synonyms: ["benumb", "confuse"],
              antonyms: [],
            },
          ],
          synonyms: ["benumb", "confuse"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/daze",
        "https://en.wiktionary.org/wiki/dazes",
      ],
    },
  ],
  pouts: [
    {
      word: "pouts",
      phonetic: "/paʊts/",
      phonetics: [{ text: "/paʊts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A young bird, a chick; now especially, a young game bird (turkey, partridge, grouse etc.).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One's facial expression when pouting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A fit of sulking or sullenness.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To push out one's lips.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To thrust itself outward; to be prominent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be or pretend to be ill-tempered; to sulk.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To say while pouting.",
              synonyms: [],
              antonyms: [],
              example: '"Don\'t you love me any more?" she pouted.',
            },
          ],
          synonyms: ["moue"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Shortened name of various fishes such as the hornpout (Ameiurus nebulosus, the brown bullhead), the pouting (Trisopterus luscus) and the eelpouts (Zoarcidae).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To shoot poults.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/poult",
        "https://en.wiktionary.org/wiki/pout",
        "https://en.wiktionary.org/wiki/pouts",
      ],
    },
  ],
  cored: [
    {
      word: "cored",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To remove the core of an apple or other fruit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To extract a sample with a drill.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["crux", "gist"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/core",
        "https://en.wiktionary.org/wiki/cored",
      ],
    },
  ],
  yules: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  lilts: [
    {
      word: "lilts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Animated, brisk motion; spirited rhythm; sprightliness.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A lively song or dance; a cheerful tune.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A cheerful or melodious accent when speaking.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To do something rhythmically, with animation and quickness, usually of music.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To sing cheerfully, especially in Gaelic.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To utter with spirit, animation, or gaiety; to sing with spirit and liveliness.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lilt",
        "https://en.wiktionary.org/wiki/lilts",
      ],
    },
  ],
  beefs: [
    {
      word: "beefs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The meat from a cow, bull or other bovine.",
              synonyms: [],
              antonyms: [],
              example: "I love eating beef.",
            },
            {
              definition:
                "(plural: beefs) A grudge; dislike (of something or someone); lack of faith or trust (in something or someone); a reason for a dislike or grudge. (often + with)",
              synonyms: [],
              antonyms: [],
              example: "He's got a beef with everyone in the room.",
            },
          ],
          synonyms: ["cowflesh", "oxflesh"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To complain.", synonyms: [], antonyms: [] },
            {
              definition: "To add weight or strength to; to beef up.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fart; break wind.",
              synonyms: [],
              antonyms: [],
              example: "Ugh, who just beefed in here?",
            },
            {
              definition: "To feud or hold a grudge against.",
              synonyms: [],
              antonyms: [],
              example:
                "Those two are beefing right now - best you stay out of it for now.",
            },
            {
              definition: "To cry",
              synonyms: [],
              antonyms: [],
              example: "David was beefing last night after Ruth told him off",
            },
            {
              definition: "To fail or mess up.",
              synonyms: [],
              antonyms: [],
              example: "I beefed my presentation hard yesterday.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/beef",
        "https://en.wiktionary.org/wiki/beefs",
      ],
    },
  ],
  mutts: [
    {
      word: "mutts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A monastic or similar religious establishment in Hinduism and Jainism, usually more formal and hierarchical than an ashram.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A mongrel dog (or sometimes cat); an animal of mixed breed or uncertain origin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(sometimes derogatory) A person of diverse ancestry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An idiot, a stupid person.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/matha",
        "https://en.wiktionary.org/wiki/mutt",
        "https://en.wiktionary.org/wiki/mutts",
      ],
    },
  ],
  fells: [
    {
      word: "fells",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A cutting-down of timber.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The stitching down of a fold of cloth; specifically, the portion of a kilt, from the waist to the seat, where the pleats are stitched down.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The end of a web, formed by the last thread of the weft.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make something fall; especially to chop down a tree.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To strike down, kill, destroy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To stitch down a protruding flap of fabric, as a seam allowance, or pleat.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An animal skin, hide, pelt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Human skin (now only as a metaphorical use of previous sense).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(obsolete outside Britain) A rocky ridge or chain of mountains.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(obsolete outside Britain) A wild field or upland moor.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fell",
        "https://en.wiktionary.org/wiki/fells",
      ],
    },
  ],
  cowls: [
    {
      word: "cowls",
      phonetic: "/kaʊlz/",
      phonetics: [{ text: "/kaʊlz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A monk's hood that can be pulled forward to cover the face; a robe with such a hood attached to it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A mask that covers the majority of the head.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A thin protective covering over all or part of an engine; also cowling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A usually hood-shaped covering used to increase the draft of a chimney and prevent backflow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A ship's ventilator with a bell-shaped top which can be swivelled to catch the wind and force it below.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A vertical projection of a ship's funnel that directs the smoke away from the bridge.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "(metonymy) A monk.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cover with, or as if with, a cowl (hood).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To wrap or form (something made of fabric) like a cowl.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make a monk of (a person).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A vessel carried on a pole, a soe.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A caul (the amnion which encloses the foetus before birth, especially that part of it which sometimes shrouds a baby’s head at birth).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cowl",
        "https://en.wiktionary.org/wiki/cowls",
      ],
    },
  ],
  spuds: [
    {
      word: "spuds",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spuds.mp3",
          sourceUrl:
            "https://commons.wikimedia.org/w/index.php?curid=100479931",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A potato.", synonyms: [], antonyms: [] },
            { definition: "A hole in a sock.", synonyms: [], antonyms: [] },
            {
              definition:
                "A type of short nut (fastener) threaded on both ends.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Anything short and thick.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A piece of dough boiled in fat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(usually in the plural) A testicle.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A dagger.", synonyms: [], antonyms: [] },
            {
              definition: "A digging fork with three broad prongs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A tool, similar to a spade, used for digging out weeds etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A barking spud; a long-handled tool for removing bark from logs.",
              synonyms: ["mut"],
              antonyms: [],
            },
          ],
          synonyms: ["mut"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/spud",
        "https://en.wiktionary.org/wiki/spuds",
      ],
    },
  ],
  lames: [
    {
      word: "lames",
      phonetic: "/leɪmz/",
      phonetics: [
        {
          text: "/leɪmz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lames-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80949486",
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cause (a person or animal) to become lame.",
              synonyms: [],
              antonyms: [],
              example:
                "1877, Anna Sewell, Black Beauty: And if you don't want to lame your horse you must look sharp and get them [stones stuck in hooves] out quickly.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To shine.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lame",
        "https://en.wiktionary.org/wiki/lames",
      ],
    },
    {
      word: "lames",
      phonetic: "/leɪmz/",
      phonetics: [
        {
          text: "/leɪmz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lames-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80949486",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Small steel plates combined so as to slide one upon other and form a piece of armour.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lames"],
    },
  ],
  jawed: [
    {
      word: "jawed",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To assail or abuse by scolding.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To scold; to clamor.", synonyms: [], antonyms: [] },
            { definition: "To talk; to converse.", synonyms: [], antonyms: [] },
            {
              definition: "(of a ball) To stick in the jaws of a pocket.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Having jaws.", synonyms: [], antonyms: [] },
            {
              definition: "(in combination) Having a specified type of jaw.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/jaw",
        "https://en.wiktionary.org/wiki/jawed",
      ],
    },
  ],
  dupes: [
    {
      word: "dupes",
      phonetic: "/djuːps/",
      phonetics: [{ text: "/djuːps/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A person who has been deceived.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To swindle, deceive, or trick.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A duplicate of a photographic image.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(restaurant industry) A duplicate of an order receipt printed for kitchen staff.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A duplicate.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To duplicate.",
              synonyms: [],
              antonyms: [],
              example: "Can you dupe this photo for me?",
            },
          ],
          synonyms: ["double"],
          antonyms: ["dedupe", "halve"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dupe",
        "https://en.wiktionary.org/wiki/dupes",
      ],
    },
  ],
  deads: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  bylaw: [
    {
      word: "bylaw",
      phonetic: "/ˈbaɪ.lɔː/",
      phonetics: [
        { text: "/ˈbaɪ.lɔː/", audio: "" },
        { text: "/ˈbaɪ.lɔ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A local custom or law of a settlement or district.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A rule made by a local authority to regulate its own affairs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A law or rule governing the internal affairs of an organization (e.g., corporation or business).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bylaw"],
    },
  ],
  noons: [
    {
      word: "noons",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            { definition: "At noontimes.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/noons"],
    },
    {
      word: "noons",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The ninth hour of the day counted from sunrise; around three o'clock in the afternoon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Time of day when the sun is in its zenith; twelve o'clock in the day, midday.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The corresponding time in the middle of the night; midnight.",
              synonyms: [],
              antonyms: [],
              example:
                "1885, When night was at its noon I heard a voice chanting the Koran in sweetest accents — Sir Richard Burton, The Book of the Thousand Nights and One Night, Night 17:",
            },
            {
              definition: "The highest point; culmination.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "capstone",
            "noon of night",
            "midday",
            "nones",
            "noontide",
            "twelve",
            "nones",
          ],
          antonyms: ["midnight"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To relax or sleep around midday",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The letter ن in the Arabic script.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/noon",
        "https://en.wiktionary.org/wiki/noons",
      ],
    },
  ],
  nifty: [
    {
      word: "nifty",
      phonetic: "/ˈnɪfti/",
      phonetics: [
        {
          text: "/ˈnɪfti/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/nifty-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=94709133",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Good, smart; useful or beneficial.",
              synonyms: [],
              antonyms: [],
              example: "a nifty trick for reducing your household energy bill",
            },
            { definition: "Stylish.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/nifty"],
    },
  ],
  clued: [
    {
      word: "clued",
      phonetic: "/kluːd/",
      phonetics: [{ text: "/kluːd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To provide with a clue.",
              synonyms: [],
              antonyms: [],
              example:
                'The crossword compiler wasn\'t sure how to clue the word "should".',
            },
            {
              definition:
                'To provide someone with information which he or she lacks (often used with "in" or "up").',
              synonyms: [],
              antonyms: [],
              example: "Smith, clue Jones in on what's been happening.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/clue",
        "https://en.wiktionary.org/wiki/clued",
      ],
    },
  ],
  vireo: [
    {
      word: "vireo",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of a number of small insectivorous passerine birds, of the genus Vireo, that have grey-green plumage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any bird of the family Vireonidae, which includes vireos, shrike-vireo, greenlets and peppershrikes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(in plural) The family Vireonidae.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/vireo"],
    },
  ],
  gapes: [
    {
      word: "gapes",
      phonetic: "/ɡeɪps/",
      phonetics: [{ text: "/ɡeɪps/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An act of gaping; a yawn.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A large opening.", synonyms: [], antonyms: [] },
            {
              definition:
                "A disease in poultry caused by gapeworm in the windpipe, a symptom of which is frequent gaping.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The width of an opening.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The maximum opening of the mouth (of a bird, fish, etc.) when it is open.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To open the mouth wide, especially involuntarily, as in a yawn, anger, or surprise.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To stare in wonder.", synonyms: [], antonyms: [] },
            {
              definition: "To open wide; to display a gap.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of a cat: to open the passage to the vomeronasal organ, analogous to the flehming in other animals.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gape",
        "https://en.wiktionary.org/wiki/gapes",
      ],
    },
  ],
  metes: [
    {
      word: "metes",
      phonetic: "/miːts/",
      phonetics: [{ text: "/miːts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To measure.", synonyms: [], antonyms: [] },
            {
              definition:
                "(usually with “out”) To dispense, measure (out), allot (especially punishment, reward etc.).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A boundary or other limit; a boundary-marker; mere.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mete",
        "https://en.wiktionary.org/wiki/metes",
      ],
    },
  ],
  cuter: [
    {
      word: "cuter",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Possessing physical features, behaviors, personality traits or other properties that are mainly attributed to infants and small or cuddly animals; e.g. fair, dainty, round, and soft physical features, disproportionately large eyes and head, playfulness, fragility, helplessness, curiosity or shyness, innocence, affectionate behavior.",
              synonyms: [],
              antonyms: [],
              example:
                "Our reaction to cute attributes is understood as the way nature ensures mammals care for their young.",
            },
            {
              definition:
                "Generally, attractive or pleasing, especially in a youthful, dainty, quaint or fun-spirited way.",
              synonyms: [],
              antonyms: [],
              example: "Let's go to the mall and look for cute girls.",
            },
            {
              definition:
                "Affected or contrived to charm; mincingly clever; precious; cutesy.",
              synonyms: [],
              antonyms: [],
              example: "Don't get cute with me, boy!",
            },
            {
              definition: "Mentally keen or discerning (See also acute)",
              synonyms: ["clever", "shrewd"],
              antonyms: [],
              example: "Cute trick, but can you do it consistently?",
            },
          ],
          synonyms: ["pretty", "endearing", "clever", "shrewd"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cute",
        "https://en.wiktionary.org/wiki/cuter",
      ],
    },
  ],
  maims: [
    {
      word: "maims",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To wound seriously; to cause permanent loss of function of a limb or part of the body.",
              synonyms: [],
              antonyms: [],
              example: "He was maimed by a bear.",
            },
          ],
          synonyms: ["mutilate"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/maim",
        "https://en.wiktionary.org/wiki/maims",
      ],
    },
  ],
  droll: [
    {
      word: "droll",
      phonetic: "/dɹəʊl/",
      phonetics: [
        { text: "/dɹəʊl/", audio: "" },
        { text: "/dɹoʊl/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A funny person; a buffoon, a wag.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To jest, to joke.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Oddly humorous; whimsical, amusing in a quaint way; waggish.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/droll"],
    },
  ],
  cupid: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  mauls: [
    {
      word: "mauls",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A heavy long-handled hammer, used for splitting logs by driving a wedge into them, or in combat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A situation where the player carrying the ball, who must be on his feet, is held by one or more opponents, and one or more of the ball carrier's team mates bind onto the ball carrier.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["club", "mace"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To handle someone or something in a rough way.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To savage; to cause serious physical wounds (usually used of an animal).",
              synonyms: [],
              antonyms: [],
              example: "The bear mauled him in a terrible way.",
            },
            { definition: "To criticise harshly.", synonyms: [], antonyms: [] },
            {
              definition: "To beat with the heavy hammer called a maul.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/maul",
        "https://en.wiktionary.org/wiki/mauls",
      ],
    },
  ],
  sedge: [
    {
      word: "sedge",
      phonetic: "/sɛd͡ʒ/",
      phonetics: [
        {
          text: "/sɛd͡ʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sedge-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80152012",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any plant of the genus Carex, the true sedge, perennial, endogenous herbs, often growing in dense tufts in marshy places. They have triangular jointless stems, a spiked inflorescence, and long grasslike leaves which are usually rough on the margins and midrib. There are several hundred species.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any plant of the family Cyperaceae.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Certain other plants resembling sedges, such as Gentiana rubricaulis and Andropogon virginicus.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sedge"],
    },
    {
      word: "sedge",
      phonetic: "/sɛd͡ʒ/",
      phonetics: [
        {
          text: "/sɛd͡ʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sedge-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80152012",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A dry fly used in fly fishing, designed to resemble a sedge or caddis fly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sedge"],
    },
    {
      word: "sedge",
      phonetic: "/sɛd͡ʒ/",
      phonetics: [
        {
          text: "/sɛd͡ʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sedge-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80152012",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Alternative spelling of segge",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A flock of herons, cranes, or bitterns.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(heading) Military action.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "(heading) A seat.", synonyms: [], antonyms: [] },
            {
              definition:
                "A place with a toilet seat: an outhouse; a lavatory.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sedge",
        "https://en.wiktionary.org/wiki/siege",
      ],
    },
  ],
  papas: [
    {
      word: "papas",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Dad, daddy, father; a familiar or old-fashioned term of address to one’s father.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A pet name for one's grandfather.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A parish priest in the Greek Orthodox Church.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The letter P in the ICAO spelling alphabet.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/papa",
        "https://en.wiktionary.org/wiki/papas",
      ],
    },
  ],
  wheys: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  eking: [
    {
      word: "eking",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act or process of adding.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "That which is added.", synonyms: [], antonyms: [] },
            {
              definition:
                "A supplementary piece of timber used to lengthen another.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/eking"],
    },
    {
      word: "eking",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "Chiefly in the form eke out: to add to, to augment; to increase; to lengthen.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/eke",
        "https://en.wiktionary.org/wiki/eking",
      ],
    },
  ],
  loots: [
    {
      word: "loots",
      phonetic: "/l(j)uːts/",
      phonetics: [
        {
          text: "/l(j)uːts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/loots.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=76490817",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To steal, especially as part of war, riot or other group violence.",
              synonyms: [],
              antonyms: [],
              example:
                '1833 "Gunganarian, the leader of the Chooars, continues his system of looting and murder", The asiatic Journal and monthly register for British India and its Dependencies Black, Parbury & Allen, p. 66.',
            },
            {
              definition: "To steal from.",
              synonyms: [],
              antonyms: [],
              example: "to loot a temple for valuables",
            },
            {
              definition: "To examine the corpse of a fallen enemy for loot.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/loot",
        "https://en.wiktionary.org/wiki/loots",
      ],
    },
  ],
  hilts: [
    {
      word: "hilts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The handle of a sword, consisting of grip, guard, and pommel, designed to facilitate use of the blade and afford protection to the hand.",
              synonyms: ["haft"],
              antonyms: [],
            },
            {
              definition: "The base of the penis.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["haft"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To provide with a hilt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To insert (a bodily extremity) as far as it can go into a sexual orifice so that it is impeded by the wider base to which it is attached (finger until palm, penis until pelvis).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hilt",
        "https://en.wiktionary.org/wiki/hilts",
      ],
    },
  ],
  meows: [
    {
      word: "meows",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The cry of a cat.", synonyms: [], antonyms: [] },
            {
              definition: "The drug mephedrone.",
              synonyms: ["drone", "meow meow"],
              antonyms: [],
            },
          ],
          synonyms: ["drone", "meow meow"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "Of a cat, to make its cry.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["mew"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/meow",
        "https://en.wiktionary.org/wiki/meows",
      ],
    },
  ],
  beaus: [
    {
      word: "beaus",
      phonetic: "/bəʊz/",
      phonetics: [
        { text: "/bəʊz/", audio: "" },
        { text: "/boʊz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A man with a reputation for fine dress and etiquette; a dandy or fop.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A male lover; a boyfriend.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A male escort.", synonyms: [], antonyms: [] },
            { definition: "A suitor of a lady.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/beau",
        "https://en.wiktionary.org/wiki/beaus",
      ],
    },
  ],
  dices: [
    {
      word: "dices",
      phonetic: "/ˈdaɪsɪz/",
      phonetics: [{ text: "/ˈdaɪsɪz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Gaming with one or more dice.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A die.", synonyms: [], antonyms: [] },
            {
              definition: "That which has been diced.",
              synonyms: [],
              antonyms: [],
              example: "Cut onions, carrots and celery into medium dice.",
            },
          ],
          synonyms: ["astragals"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To play dice.", synonyms: [], antonyms: [] },
            {
              definition: "To cut into small cubes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To ornament with squares, diamonds, or cubes.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dice",
        "https://en.wiktionary.org/wiki/dices",
      ],
    },
  ],
  peppy: [
    {
      word: "peppy",
      phonetic: "/ˈpɛpi/",
      phonetics: [{ text: "/ˈpɛpi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Full of pep; energetic, cheerful, and vigorous; bouncy",
              synonyms: [],
              antonyms: [],
              example:
                "Cedric was not feeling peppy when he woke up two hours before dawn.",
            },
          ],
          synonyms: [
            "energetic",
            "lively",
            "perky",
            "sparky",
            "zippy",
            "pep",
            "vim and vigor",
          ],
          antonyms: ["enervated", "exhausted", "lethargic", "solemn"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/peppy"],
    },
  ],
  riper: [
    {
      word: "riper",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(of fruits, vegetables, seeds etc.) Ready for reaping or gathering; having attained perfection; mature",
              synonyms: [],
              antonyms: [],
              example: "ripe apples",
            },
            {
              definition:
                "(of foods) Advanced to the state of fitness for use; mellow",
              synonyms: [],
              antonyms: [],
              example: "ripe cheese",
            },
            {
              definition:
                "Having attained its full development; mature; perfected",
              synonyms: ["consummate"],
              antonyms: [],
            },
            {
              definition:
                "Maturated or suppurated; ready to discharge; -- said of sores, tumors, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Ready for action or effect; prepared.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Like ripened fruit in ruddiness and plumpness.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Intoxicated.", synonyms: [], antonyms: [] },
            {
              definition:
                "Of a conflict between parties, having developed to a stage where the conflict may be reviewed by a court of law.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Smelly: having a disagreeable odor.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["consummate"],
          antonyms: ["unripe"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ripe",
        "https://en.wiktionary.org/wiki/riper",
      ],
    },
  ],
  fogey: [
    {
      word: "fogey",
      phonetic: "/ˈfəʊɡi/",
      phonetics: [
        { text: "/ˈfəʊɡi/", audio: "" },
        { text: "/ˈfoʊɡi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A dull old fellow; a person behind the times, over-conservative, or slow.",
              synonyms: ["old fogey"],
              antonyms: [],
            },
          ],
          synonyms: ["old fogey"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fogey"],
    },
  ],
  gists: [
    {
      word: "gists",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The most essential part; the main idea or substance (of a longer or more complicated matter); the crux of a matter; the pith.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The essential ground for action in a suit, without which there is no cause of action.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Resting place (especially of animals), lodging.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["gravamen", "crux", "quintessence", "lair"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gist",
        "https://en.wiktionary.org/wiki/gists",
      ],
    },
  ],
  yogas: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  gilts: [
    {
      word: "gilts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Gold or other metal in a thin layer; gilding.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Money.", synonyms: [], antonyms: [] },
            {
              definition:
                "A security issued by the Bank of England (see gilt-edged)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A gilded object, an object covered with gold.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A young female pig, at or nearing the age of first breeding.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gilt",
        "https://en.wiktionary.org/wiki/gilts",
      ],
    },
  ],
  skews: [
    {
      word: "skews",
      phonetic: "/skjuːz/",
      phonetics: [
        { text: "/skjuːz/", audio: "" },
        { text: "/skjuz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Something that has an oblique or slanted position.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An oblique or sideways movement.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A bias or distortion in a particular direction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A phenomenon in synchronous digital circuit systems (such as computers) in which the same sourced clock signal arrives at different components at different times.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A state of asymmetry in a distribution; skewness.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To form or shape in an oblique way; to cause to take an oblique position.",
              synonyms: [],
              antonyms: ["unskew"],
            },
            {
              definition: "To bias or distort in a particular direction.",
              synonyms: [],
              antonyms: [],
              example:
                "A disproportionate number of female subjects in the study group skewed the results.",
            },
            { definition: "To hurl or throw.", synonyms: [], antonyms: [] },
            {
              definition:
                "To move obliquely; to move sideways, to sidle; to lie obliquely.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To jump back or sideways in fear or surprise; to shy, as a horse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To look at obliquely; to squint; hence, to look slightingly or suspiciously.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["unskew"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A stone at the foot of the slope of a gable, the offset of a buttress, etc., cut with a sloping surface and with a check to receive the coping stones and retain them in place; a skew-corbel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The coping of a gable.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the stones placed over the end of a gable, or forming the coping of a gable.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/skew",
        "https://en.wiktionary.org/wiki/skews",
      ],
    },
  ],
  cedes: [
    {
      word: "cedes",
      phonetic: "/siːdz/",
      phonetics: [{ text: "/siːdz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To give up; yield to another.",
              synonyms: [],
              antonyms: [],
              example: "Edward decided to cede the province.",
            },
            { definition: "To give way.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cede",
        "https://en.wiktionary.org/wiki/cedes",
      ],
    },
  ],
  zeals: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  alums: [
    {
      word: "alums",
      phonetic: "/ˈa.ləmz/",
      phonetics: [
        { text: "/ˈa.ləmz/", audio: "" },
        { text: "/ˈæ.ləmz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An astringent salt, usually occurring in the form of pale crystals, much used in the dyeing and tanning trade and in certain medicines, and now understood to be a double sulphate of potassium and aluminium (K2SO4·Al2(SO4)3·24H2O).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any similar double sulphate in which either or both of the potassium and aluminium is wholly or partly replaced by other univalent or tervalent cations.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["potash alum"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(shortening) A past attendee or graduate (of either gender) of a college, university or other educational institution.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/alum",
        "https://en.wiktionary.org/wiki/alums",
      ],
    },
  ],
  okays: [
    {
      word: "okays",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Endorsement; approval.",
              synonyms: [],
              antonyms: [],
              example: "We can start as soon as we get the OK.",
            },
          ],
          synonyms: [
            "approval",
            "endorsement",
            "go-ahead",
            "green light",
            "thumbs up",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To approve.",
              synonyms: [],
              antonyms: [],
              example: "I don't want to OK this amount of money.",
            },
            {
              definition: "To confirm by activating a button marked OK.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["approve", "greenlight"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/OK",
        "https://en.wiktionary.org/wiki/okay",
        "https://en.wiktionary.org/wiki/okays",
      ],
    },
  ],
  elope: [
    {
      word: "elope",
      phonetic: "/ɛˈləʊp/",
      phonetics: [
        {
          text: "/ɛˈləʊp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/elope-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89322029",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ɛˈloʊp/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(of a married person) To run away from home with a paramour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of an unmarried person) To run away secretly for the purpose of getting married with one's intended spouse; to marry in a quick or private fashion, especially without a public period of engagement.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To run away from home (for any reason).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/elope"],
    },
  ],
  grump: [
    {
      word: "grump",
      phonetic: "/ɡɹʌmp/",
      phonetics: [
        {
          text: "/ɡɹʌmp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grump-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50240576",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A habitually grumpy or complaining person.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A grumpy mood.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To complain.", synonyms: [], antonyms: [] },
            { definition: "To be grumpy.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/grump"],
    },
  ],
  wafts: [
    {
      word: "wafts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To (cause to) float easily or gently through the air.",
              synonyms: [],
              antonyms: [],
              example:
                "A breeze came in through the open window and wafted her sensuous perfume into my eager nostrils.",
            },
            {
              definition:
                "To be moved, or to pass, on a buoyant medium; to float.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To give notice to by waving something; to wave the hand to; to beckon.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/waft",
        "https://en.wiktionary.org/wiki/wafts",
      ],
    },
  ],
  soots: [
    {
      word: "soots",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Fine black or dull brown particles of amorphous carbon and tar, produced by the incomplete combustion of coal, oil etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["lampblack"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cover or dress with soot.",
              synonyms: [],
              antonyms: [],
              example: "to soot land",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/soot",
        "https://en.wiktionary.org/wiki/soots",
      ],
    },
  ],
  blimp: [
    {
      word: "blimp",
      phonetic: "/blɪmp/",
      phonetics: [
        { text: "/blɪmp/", audio: "" },
        { text: "/blɪmp/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An airship constructed with a non-rigid lifting agent container.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) Any large airborne inflatable.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An obese person.", synonyms: [], antonyms: [] },
            {
              definition:
                "A person similar to the cartoon character Colonel Blimp; a pompous, reactionary British man.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["Gossage", "barrage balloon"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To expand like a blimp or balloon; to become fat.",
              synonyms: [],
              antonyms: [],
              example:
                "After college, she started blimping and could no longer wear her favorite little black dress.",
            },
          ],
          synonyms: ["blimp out", "blimp up"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/blimp"],
    },
  ],
  hefts: [
    {
      word: "hefts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Weight.", synonyms: [], antonyms: [] },
            {
              definition: "Heaviness, the feel of weight.",
              synonyms: [],
              antonyms: [],
              example:
                "A high quality hammer should have good balance and heft.",
            },
            {
              definition:
                "The act or effort of heaving; violent strain or exertion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The greater part or bulk of anything.",
              synonyms: [],
              antonyms: [],
              example: "The heft of the crop was spoiled.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To lift up; especially, to lift something heavy.",
              synonyms: [],
              antonyms: [],
              example: "He hefted the sack of concrete into the truck.",
            },
            {
              definition: "To test the weight of something by lifting it.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["hoist"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A piece of mountain pasture to which a farm animal has become hefted (accustomed).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An animal that has become hefted thus.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(West of Ireland) Poor condition in sheep caused by mineral deficiency.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(Northern England and Scotland) To make (a farm animal, especially a flock of sheep) accustomed and attached to an area of mountain pasture.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A number of sheets of paper fastened together, as for a notebook.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A part of a serial publication.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/heft",
        "https://en.wiktionary.org/wiki/hefts",
      ],
    },
  ],
  mulls: [
    {
      word: "mulls",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A thin, soft muslin.", synonyms: [], antonyms: [] },
            {
              definition:
                "Marijuana that has been chopped to prepare it for smoking.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A stew of meat, broth, milk, butter, vegetables, and seasonings, thickened with soda crackers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The gauze used in bookbinding to adhere a text block to a book's cover.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An inferior kind of madder prepared from the smaller roots or the peelings and refuse of the larger.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(usually with over) To work (over) mentally; to cogitate; to ruminate.",
              synonyms: [],
              antonyms: [],
              example:
                "he paused to mull over his various options before making a decision",
            },
            {
              definition: "To powder; to pulverize.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To chop marijuana so that it becomes a smokable form.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To heat and spice something, such as wine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To join two or more individual windows at mullions.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To dull or stupefy.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A promontory.",
              synonyms: [],
              antonyms: [],
              example: "the Mull of Kintyre",
            },
            {
              definition: "A snuffbox made of the small end of a horn.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mull",
        "https://en.wiktionary.org/wiki/mulls",
      ],
    },
  ],
  hosed: [
    {
      word: "hosed",
      phonetic: "/həʊzd/",
      phonetics: [
        { text: "/həʊzd/", audio: "" },
        { text: "/hoʊzd/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To water or spray with a hose.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To deliver using a hose.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To provide with hose (garment)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To attack and kill somebody, usually using a firearm.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To trick or deceive.", synonyms: [], antonyms: [] },
            {
              definition:
                "To break a computer so everything needs to be reinstalled; to wipe all files.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause an unfair disadvantage to a player or team through poor officiating; especially, to cause a player or team to lose the game with an incorrect call.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Ruined, messed up.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hose",
        "https://en.wiktionary.org/wiki/hosed",
      ],
    },
  ],
  cress: [
    {
      word: "cress",
      phonetic: "/kɹɛs/",
      phonetics: [{ text: "/kɹɛs/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(plants) A plant of various species, chiefly cruciferous. The leaves have a moderately pungent taste, and are used as a salad and antiscorbutic.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cress"],
    },
  ],
  doffs: [
    {
      word: "doffs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(clothing) To remove or take off, especially of clothing.",
              synonyms: ["take off"],
              antonyms: ["don"],
            },
            {
              definition:
                "To remove or tip a hat, as in greeting, salutation or as a mark of respect.",
              synonyms: [],
              antonyms: [],
              example: "The rustics doffed their hats at the clergy.",
            },
            {
              definition: "To get rid of, to throw off.",
              synonyms: [],
              antonyms: [],
              example: "Doff that stupid idea: it would never work.",
            },
            {
              definition: "To strip; to divest; to undress.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["take off"],
          antonyms: ["don"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/doff",
        "https://en.wiktionary.org/wiki/doffs",
      ],
    },
  ],
  ruder: [
    {
      word: "ruder",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Bad-mannered.",
              synonyms: [],
              antonyms: [],
              example:
                "Karen broke up with Fred because he was often rude to her.",
            },
            {
              definition: "Somewhat obscene, pornographic, offensive.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Tough, robust.", synonyms: [], antonyms: [] },
            {
              definition: "Undeveloped, unskilled, basic.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Hearty, vigorous; found particularly in the phrase rude health.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["ill-mannered", "uncouth", "adult", "blue", "primitive"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rude",
        "https://en.wiktionary.org/wiki/ruder",
      ],
    },
  ],
  pixie: [
    {
      word: "pixie",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pixie-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241735",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈpɪksi/", audio: "" },
        { text: "/ˈpɪksi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(fantasy literature, fairy tales) A playful sprite or elflike or fairy-like creature.",
              synonyms: ["brownie", "fair", "gnome", "imp", "sprite"],
              antonyms: [],
            },
            {
              definition: "A cute, petite woman with short hair.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An upper-atmospheric optical phenomenon associated with thunderstorms, a short-lasting pinpoint of light on the surface of convective domes that produces a gnome.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["brownie", "fair", "gnome", "imp", "sprite"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pixie"],
    },
  ],
  waifs: [
    {
      word: "waifs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A castaway; a homeless child.",
              synonyms: ["stray", "wanderer"],
              antonyms: [],
            },
            {
              definition:
                "(of a plant outside its native range) A plant that has been introduced but is not persistently naturalized.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Goods found of which the owner is not known; originally, such goods as a pursued thief threw away to prevent being apprehended, which belonged to the king unless the owner made pursuit of the felon, took him, and brought him to justice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Hence, anything found, or without an owner; that which comes along, as it were, by chance.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["stray", "wanderer"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/waif",
        "https://en.wiktionary.org/wiki/waifs",
      ],
    },
  ],
  ousts: [
    {
      word: "ousts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To expel; to remove.",
              synonyms: [],
              antonyms: [],
              example:
                "The protesters became so noisy that they were finally ousted from the meeting.",
            },
          ],
          synonyms: ["banish", "dismiss", "eject", "exclude"],
          antonyms: ["accept", "harbor", "shelter"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/oust",
        "https://en.wiktionary.org/wiki/ousts",
      ],
    },
  ],
  pucks: [
    {
      word: "pucks",
      phonetic: "/pʌks/",
      phonetics: [{ text: "/pʌks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A mischievous or hostile spirit.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["fairy", "goblin"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A hard rubber disc; any other flat disc meant to be hit across a flat surface in a game.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An object shaped like a puck.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A pointing device with a crosshair.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(camogie) A penalty shot.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "(rural) billy goat", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/puck",
        "https://en.wiktionary.org/wiki/pucks",
      ],
    },
  ],
  biers: [
    {
      word: "biers",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A litter to transport the corpse of a dead person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A platform or stand where a body or coffin is placed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A count of forty threads in the warp or chain of woollen cloth.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bier",
        "https://en.wiktionary.org/wiki/biers",
      ],
    },
  ],
  gulch: [
    {
      word: "gulch",
      phonetic: "/ɡʌltʃ/",
      phonetics: [{ text: "/ɡʌltʃ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A ravine-like or deep V-shaped valley, often eroded by flash floods; shallower than a canyon and deeper than a gully.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An act of gulching or gulping.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A glutton.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To swallow greedily; to gulp down.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To fall heavily.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gulch"],
    },
  ],
  suets: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  hobos: [
    {
      word: "hobos",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A wandering homeless person, especially one illegally travelling by rail or a penniless, unemployed bum.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any migratory laborer, whether homeless or not.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A kind of large handbag.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hobo",
        "https://en.wiktionary.org/wiki/hobos",
      ],
    },
  ],
  lints: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  brans: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  teals: [
    {
      word: "teals",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of various small freshwater ducks of the genus Anas that are brightly coloured and have short necks.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A dark, somewhat bluish-green colour; a dark cyan.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/teal",
        "https://en.wiktionary.org/wiki/teals",
      ],
    },
  ],
  garbs: [
    {
      word: "garbs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Fashion, style of dressing oneself up.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A type of dress or clothing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A guise, external appearance.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A wheat sheaf.", synonyms: [], antonyms: [] },
            {
              definition: "A measure of arrows in the Middle Ages.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/garb",
        "https://en.wiktionary.org/wiki/garbs",
      ],
    },
  ],
  pewee: [
    {
      word: "pewee",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The common American tyrant flycatcher (of the genus Contopus).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A woodcock.", synonyms: [], antonyms: [] },
            {
              definition:
                "An American version of the children's game gilli-danda.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pewee"],
    },
  ],
  helms: [
    {
      word: "helms",
      phonetic: "/hɛlmz/",
      phonetics: [
        {
          text: "/hɛlmz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/helms-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80949381",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The stems of various cultivated plants, left after harvesting the crop to be used as animal litter or for thatching.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An individual plant stem.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Part of a harness; a hame.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["straw", "thatch"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The steering apparatus of a ship, especially the tiller or wheel.",
              synonyms: ["tiller", "wheel"],
              antonyms: [],
            },
            {
              definition:
                "(maritime) The member of the crew in charge of steering the boat.",
              synonyms: ["helmsman"],
              antonyms: [],
            },
            {
              definition: "A position of leadership or control.",
              synonyms: [],
              antonyms: [],
              example: "the helm of the Commonwealth",
            },
            {
              definition:
                "One at the place of direction or control; a guide; a director.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A helve.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["helmsman", "tiller", "wheel"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To be a helmsman or a member of the helm; to be in charge of steering the boat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) To lead (a project, etc.).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A helmet.", synonyms: [], antonyms: [] },
            { definition: "A helmet.", synonyms: [], antonyms: [] },
            {
              definition: "A heavy cloud lying on the brow of a mountain.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/haulm",
        "https://en.wiktionary.org/wiki/helm",
        "https://en.wiktionary.org/wiki/helms",
      ],
    },
  ],
  turfs: [
    {
      word: "turfs",
      phonetic: "/tɜː(ɹ)fs/",
      phonetics: [{ text: "/tɜː(ɹ)fs/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A layer of earth covered with grass; sod.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of such a layer cut from the soil. May be used as sod to make a lawn, dried for peat, stacked to form earthen structures, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sod of peat used as fuel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The territory claimed by a person, gang, etc. as their own.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                '(with "the") A racetrack; or the sport of racing horses.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cover with turf; to create a lawn by laying turfs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Ultimate Frisbee) To throw a frisbee well short of its intended target, usually causing it to hit the ground within 10 yards of its release.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fire from a job or dismiss from a task.",
              synonyms: [],
              antonyms: [],
              example:
                "Eight managers were turfed after the merger of the two companies.",
            },
            {
              definition: "To cancel a project or product.",
              synonyms: [],
              antonyms: [],
              example:
                "The company turfed the concept car because the prototype performed poorly.",
            },
            {
              definition: "To expel, eject, or throw out; to turf out.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To transfer or attempt to transfer (a patient or case); to eschew or avoid responsibility for.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/turf",
        "https://en.wiktionary.org/wiki/turfs",
      ],
    },
  ],
  quips: [
    {
      word: "quips",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A smart, sarcastic turn or jest; a taunt; a severe retort or comeback; a gibe.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To make a quip.", synonyms: [], antonyms: [] },
            {
              definition: "To taunt; to treat with quips.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/quip",
        "https://en.wiktionary.org/wiki/quips",
      ],
    },
  ],
  wends: [
    {
      word: "wends",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To turn; change.", synonyms: [], antonyms: [] },
            {
              definition:
                "To direct (one's way or course); pursue one's way; proceed upon some course or way.",
              synonyms: [],
              antonyms: [],
              example: "We wended our weary way westward.",
            },
            {
              definition: "To turn; make a turn; go round; veer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To pass away; disappear; depart; vanish.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["to betake oneself"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wend",
        "https://en.wiktionary.org/wiki/wends",
      ],
    },
  ],
  banes: [
    {
      word: "banes",
      phonetic: "/beɪnz/",
      phonetics: [{ text: "/beɪnz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A cause of misery or death.",
              synonyms: ["affliction", "curse"],
              antonyms: ["boon"],
              example: "the bane of one's existence",
            },
            {
              definition: "Poison, especially any of several poisonous plants.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A killer, murderer, slayer.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Destruction; death.", synonyms: [], antonyms: [] },
            {
              definition: "A disease of sheep.",
              synonyms: ["rot"],
              antonyms: [],
            },
          ],
          synonyms: ["affliction", "curse", "rot"],
          antonyms: ["boon"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To kill, especially by poison; to be the poison of.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To be the bane of.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "Bone", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bane",
        "https://en.wiktionary.org/wiki/banes",
      ],
    },
  ],
  napes: [
    {
      word: "napes",
      phonetic: "/neɪps/",
      phonetics: [
        {
          text: "/neɪps/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/napes-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=83861420",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The back part of the neck.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The part of a fish or bird immediately behind the head.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "nucha",
            "nuchal",
            "scruff",
            "scruff of the neck",
            "withers",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A tablecloth.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/nape",
        "https://en.wiktionary.org/wiki/napes",
      ],
    },
  ],
  icier: [
    {
      word: "icier",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Pertaining to, resembling, or abounding in ice; cold; frosty.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Covered with ice, wholly or partially.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Characterized by coldness of manner; frigid; cold.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be wearing an excessive amount of jewelry, especially of the high-quality and expensive kind.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/icier",
        "https://en.wiktionary.org/wiki/icy",
      ],
    },
  ],
  swats: [
    {
      word: "swats",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A hard stroke, hit or blow, e.g., as part of a spanking.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Alternate spelling of swot: vigorous study at an educational institution.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To beat off, as insects; to bat, strike, or hit.",
              synonyms: [],
              antonyms: [],
              example:
                "He swatted the mosquito that was buzzing around in his bedroom.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To illegitimately provoke a SWAT assault upon (someone).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/swat",
        "https://en.wiktionary.org/wiki/swats",
      ],
    },
  ],
  bagel: [
    {
      word: "bagel",
      phonetic: "/bæ.ɡɫ̩/",
      phonetics: [
        { text: "/bæ.ɡɫ̩/", audio: "" },
        {
          text: "/ˈbeɪɡəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bagel-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50348380",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A toroidal bread roll that is boiled before it is baked.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A score of 6-0 in a set (after the shape of a bagel, which looks like a zero).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An overly materialistic and selfish young Jewish man.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["JAP", "kugel"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To achieve a score of 6-0 in a tennis set.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To hold an opponent to a score of zero.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bagel"],
    },
  ],
  hexed: [
    {
      word: "hexed",
      phonetic: "/hɛkst/",
      phonetics: [{ text: "/hɛkst/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cast a spell on (specifically an evil spell), to bewitch.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Cursed; afflicted with bad luck.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hex",
        "https://en.wiktionary.org/wiki/hexed",
      ],
    },
  ],
  ogres: [
    {
      word: "ogres",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A type of brutish giant from folk tales that eats human flesh.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A brutish man reminiscent of the mythical ogre.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ogre",
        "https://en.wiktionary.org/wiki/ogres",
      ],
    },
  ],
  goner: [
    {
      word: "goner",
      phonetic: "/ˈɡɒnə(ɹ)/",
      phonetics: [{ text: "/ˈɡɒnə(ɹ)/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Someone (or something) doomed; a hopeless case.",
              synonyms: [],
              antonyms: [],
              example: "I'm a goner if this plan doesn't work.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/goner"],
    },
  ],
  gilds: [
    {
      word: "gilds",
      phonetic: "/ɡɪldz/",
      phonetics: [{ text: "/ɡɪldz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cover with a thin layer of gold; to cover with gold leaf.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To adorn.", synonyms: [], antonyms: [] },
            {
              definition: "To decorate with a golden surface appearance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To give a bright or pleasing aspect to.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To make appear drunk.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gild",
        "https://en.wiktionary.org/wiki/gilds",
      ],
    },
  ],
  pyres: [
    {
      word: "pyres",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A funeral pile; a combustible heap on which corpses are burned.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any heap or pile of combustibles.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pyre",
        "https://en.wiktionary.org/wiki/pyres",
      ],
    },
  ],
  lards: [
    {
      word: "lards",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To stuff (meat) with bacon or pork before cooking.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To smear with fat or lard.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To garnish or strew, especially with reference to words or phrases in speech and writing.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To fatten; to enrich.", synonyms: [], antonyms: [] },
            { definition: "To grow fat.", synonyms: [], antonyms: [] },
            {
              definition:
                "To mix or garnish with something, as by way of improvement; to interlard.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lard",
        "https://en.wiktionary.org/wiki/lards",
      ],
    },
  ],
  bides: [
    {
      word: "bides",
      phonetic: "/baɪdz/",
      phonetics: [{ text: "/baɪdz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To bear; to endure; to tolerate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To dwell or reside in a location; to abide.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To wait; to be in expectation; to stay; to remain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To wait for; to await.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["put up with", "live", "stand by", "await"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bide",
        "https://en.wiktionary.org/wiki/bides",
      ],
    },
  ],
  paged: [
    {
      word: "paged",
      phonetic: "/peɪdʒd/",
      phonetics: [{ text: "/peɪdʒd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To mark or number the pages of, as a book or manuscript.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(often with “through”) To turn several pages of a publication.",
              synonyms: [],
              antonyms: [],
              example:
                "The patient paged through magazines while he waited for the doctor.",
            },
            {
              definition: "To furnish with folios.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To attend (someone) as a page.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To call or summon (someone).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To contact (someone) by means of a pager or other mobile device.",
              synonyms: [],
              antonyms: [],
              example: "I’ll be out all day, so page me if you need me.",
            },
            {
              definition:
                "To call (somebody) using a public address system so as to find them.",
              synonyms: [],
              antonyms: [],
              example: "An SUV parked me in. Could you please page its owner?",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/page",
        "https://en.wiktionary.org/wiki/paged",
      ],
    },
  ],
  talon: [
    {
      word: "talon",
      phonetic: "/ˈtælən/",
      phonetics: [
        {
          text: "/ˈtælən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/talon-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1785296",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sharp, hooked claw of a bird of prey or other predatory animal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of certain small prominences on the hind part of the face of an elephant's tooth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A kind of moulding, concave at the bottom and convex at the top; an ogee. (When the concave part is at the top, it is called an inverted talon.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The shoulder of the bolt of a lock on which the key acts to shoot the bolt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In various card games, the remaining stock of undealt cards.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/talon"],
    },
  ],
  flout: [
    {
      word: "flout",
      phonetic: "/flʌʊt/",
      phonetics: [
        { text: "/flʌʊt/", audio: "" },
        {
          text: "/flaʊt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/flout-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89032943",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The act by which something is flouted; violation of a law.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A mockery or insult.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To express contempt for (laws, rules, etc.) by word or action.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To scorn.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/flout"],
    },
  ],
  medic: [
    {
      word: "medic",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A doctor.", synonyms: [], antonyms: [] },
            {
              definition:
                "A paramedic, someone with special training in first aid, especially in the military.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A medical student.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of or pertaining to medicines; medical.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/medic"],
    },
    {
      word: "medic",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of various European and North African herbs, of the genus Medicago, several of which are grown for fodder etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["burclover"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/medic",
        "https://en.wiktionary.org/wiki/medick",
      ],
    },
  ],
  veals: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  putts: [
    {
      word: "putts",
      phonetic: "/pʌts/",
      phonetics: [{ text: "/pʌts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To place something somewhere.",
              synonyms: [],
              antonyms: [],
              example: "She put her books on the table.",
            },
            {
              definition:
                "To bring or set into a certain relation, state or condition.",
              synonyms: [],
              antonyms: [],
              example: "He is putting all his energy into this one task.",
            },
            {
              definition: "To exercise a put option.",
              synonyms: [],
              antonyms: [],
              example:
                "He got out of his Procter and Gamble bet by putting his shares at 80.",
            },
            {
              definition: "To express something in a certain manner.",
              synonyms: [],
              antonyms: [],
              example:
                "When you put it that way, I guess I can see your point.",
            },
            {
              definition:
                "To throw a heavy iron ball, as a sport. (See shot put. Do not confuse with putt.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To steer; to direct one's course; to go.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To play a card or a hand in the game called put.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To attach or attribute; to assign.",
              synonyms: [],
              antonyms: [],
              example: "to put a wrong construction on an act or expression",
            },
            {
              definition: "To lay down; to give up; to surrender.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To set before one for judgment, acceptance, or rejection; to bring to the attention.",
              synonyms: [],
              antonyms: [],
              example: "to put a question; to put a case",
            },
            {
              definition:
                "To incite; to entice; to urge; to constrain; to oblige.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To convey coal in the mine, as for example from the working to the tramway.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The act of tapping a golf ball lightly on a putting green.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To lightly strike a golf ball with a putter.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                'A regular sound characterized by the sound of "putt putt putt putt...", such as made by some slowly stroking internal combustion engines.',
              synonyms: [],
              antonyms: [],
            },
            { definition: "A motorcycle.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make a putting sound.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To ride one's motorcycle, to go for a motorcycle ride.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To move along slowly.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/put",
        "https://en.wiktionary.org/wiki/putt",
        "https://en.wiktionary.org/wiki/putts",
      ],
    },
  ],
  dirks: [
    {
      word: "dirks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A long Scottish dagger with a straight blade.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(Midwest US) A penis; dork.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Midwest US) A socially unacceptable person; an oddball.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To stab with a dirk.", synonyms: [], antonyms: [] },
            { definition: "To darken.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dirk",
        "https://en.wiktionary.org/wiki/dirks",
      ],
    },
  ],
  dotes: [
    {
      word: "dotes",
      phonetic: "/dəʊts/",
      phonetics: [{ text: "/dəʊts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A darling, a cutie.", synonyms: [], antonyms: [] },
            {
              definition: "An imbecile; a dotard.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dobby", "mimmerkin"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(usually with on) To be weakly or foolishly fond of somebody.",
              synonyms: ["adore", "love"],
              antonyms: [],
              example: "Little Bill's parents just keep doting on him.",
            },
            {
              definition: "To act in a foolish manner; to be senile.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["adore", "love"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dote",
        "https://en.wiktionary.org/wiki/dotes",
      ],
    },
  ],
  tippy: [
    {
      word: "tippy",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tippy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79522031",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "A dandy.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Fashionable, tip-top.", synonyms: [], antonyms: [] },
            {
              definition:
                "(absolute, with the) In the height of fashion, excellent, cool.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Clever, neat, smart.", synonyms: [], antonyms: [] },
            {
              definition:
                "Of tea, having a large amount of tips, or leaf buds.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tippy"],
    },
    {
      word: "tippy",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tippy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79522031",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Tending to tip or tilt over; unstable.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tippy"],
    },
  ],
  blurt: [
    {
      word: "blurt",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/blurt-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75728960",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈblɜːt/", audio: "" },
        {
          text: "/ˈblɝt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/blurt-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=874167",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "An abrupt outburst.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To utter suddenly and unadvisedly; to speak quickly or without thought; to divulge inconsiderately — commonly with out.",
              synonyms: [],
              antonyms: [],
              example:
                "Please think about your reply and don't just blurt out the first thing that comes to mind.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/blurt"],
    },
  ],
  piths: [
    {
      word: "piths",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To extract the pith from (a plant stem or tree).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To kill (especially cattle or laboratory animals) by cutting or piercing the spinal cord.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pith",
        "https://en.wiktionary.org/wiki/piths",
      ],
    },
  ],
  acing: [
    {
      word: "acing",
      phonetic: "/ˈeɪ.sɪŋ/",
      phonetics: [{ text: "/ˈeɪ.sɪŋ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(US) To pass (a test, interviews etc.) perfectly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To win a point by an ace.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make an ace (hole in one).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["pass with flying colours"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ace",
        "https://en.wiktionary.org/wiki/acing",
      ],
    },
  ],
  barer: [
    {
      word: "barer",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Minimal; that is or are just sufficient.",
              synonyms: [],
              antonyms: [],
              example: "a bare majority",
            },
            {
              definition: "Naked, uncovered.",
              synonyms: [],
              antonyms: [],
              example:
                "I do wonder why keeping my little breasts bare can be lewd even as none tells my brother anything for being bare-chested.",
            },
            {
              definition: "Having no supplies.",
              synonyms: [],
              antonyms: [],
              example: "The cupboard was bare.",
            },
            {
              definition: "Having no decoration.",
              synonyms: [],
              antonyms: [],
              example:
                "The walls of this room are bare — why not hang some paintings on them?",
            },
            {
              definition: "Having had what usually covers (something) removed.",
              synonyms: [],
              antonyms: [],
              example:
                "The trees were left bare after the swarm of locusts devoured all the leaves.",
            },
            {
              definition: "(Toronto) A lot or lots of.",
              synonyms: [],
              antonyms: [],
              example: "It's bare money to get in the club each time, man.",
            },
            {
              definition: "With head uncovered; bareheaded.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Without anything to cover up or conceal one's thoughts or actions; open to view; exposed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Mere; without embellishment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Threadbare, very worn.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Not insured.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "empty",
            "plain",
            "unadorned",
            "undecorated",
            "empty",
            "unfurnished",
            "unstocked",
            "unsupplied",
            "mere",
            "minimal",
            "exposed",
            "naked",
            "nude",
            "uncovered",
            "undressed",
            "despoiled",
            "stripped",
            "uncovered",
          ],
          antonyms: [
            "covered",
            "adorn",
            "decorated",
            "ornate",
            "full",
            "furnished",
            "stocked",
            "supplied",
            "well-stocked",
            "ample",
            "plentiful",
            "sufficient",
            "covered",
            "dressed",
            "unexposed",
          ],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One who bares or exposes something.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bare",
        "https://en.wiktionary.org/wiki/barer",
      ],
    },
  ],
  whets: [
    {
      word: "whets",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To hone or rub on with some substance, as a piece of stone, for the purpose of sharpening – see whetstone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To stimulate or make more keen.",
              synonyms: [],
              antonyms: [],
              example: "to whet one's appetite or one's courage",
            },
            { definition: "To preen.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/whet",
        "https://en.wiktionary.org/wiki/whets",
      ],
    },
  ],
  gaits: [
    {
      word: "gaits",
      phonetic: "/ɡeɪts/",
      phonetics: [{ text: "/ɡeɪts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Manner of walking or stepping; bearing or carriage while moving.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the different ways in which a horse can move, either naturally or as a result of training.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To teach a specific gait to a horse.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A sheaf of corn.", synonyms: [], antonyms: [] },
            {
              definition: "A charge for pasturage.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gait",
        "https://en.wiktionary.org/wiki/gaits",
      ],
    },
  ],
  wools: [
    {
      word: "wools",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The hair of the sheep, llama and some other ruminants.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A cloth or yarn made from the wool of sheep.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Anything with a texture like that of wool.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A fine fiber obtained from the leaves of certain trees, such as firs and pines.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Short, thick hair, especially when crisped or curled.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Yarn (including that which is made from synthetic fibers.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Derogatory term for residents of the satellite towns outside Liverpool, such as St Helens or Warrington. See also Yonner.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wool",
        "https://en.wiktionary.org/wiki/wools",
      ],
    },
  ],
  dunks: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  heros: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  swabs: [
    {
      word: "swabs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small piece of soft, absorbent material, such as gauze, used to clean wounds, apply medicine, or take samples of body fluids. Often attached to a stick or wire to aid access.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sample taken with a swab (piece of absorbent material).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of material used for cleaning or sampling other items like musical instruments or guns.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A mop, especially on a ship.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A sailor; a swabby.", synonyms: [], antonyms: [] },
            {
              definition: "A naval officer's epaulet.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["swabby"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To use a swab on something, or clean something with a swab.",
              synonyms: [],
              antonyms: [],
              example: "swab the deck of a ship",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/swab",
        "https://en.wiktionary.org/wiki/swabs",
      ],
    },
  ],
  dirts: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  jutes: [
    {
      word: "jutes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The coarse, strong fiber of the East Indian plants, Corchorus olitorius and Corchorus capsularis, used to make mats, paper, gunny cloth etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The plants from which this fibre is obtained.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/jute",
        "https://en.wiktionary.org/wiki/jutes",
      ],
    },
  ],
  hemps: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  surfs: [
    {
      word: "surfs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Waves that break on an ocean shoreline.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An instance or session of riding a surfboard in the surf.",
              synonyms: [],
              antonyms: [],
              example: "We went of a surf this morning.",
            },
            {
              definition: "The bottom of a drain.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To ride a wave, usually on a surfboard.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To browse the Internet, television, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/surf",
        "https://en.wiktionary.org/wiki/surfs",
      ],
    },
  ],
  okapi: [
    {
      word: "okapi",
      phonetic: "/ə(ʊ)ˈkɑːpi/",
      phonetics: [{ text: "/ə(ʊ)ˈkɑːpi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A large ruminant mammal, Okapia johnstoni, found in the rainforests of the Congo, related to the giraffe, but with a much shorter neck, a reddish brown coat and zebra-like stripes on its hindquarters.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/okapi"],
    },
  ],
  chows: [
    {
      word: "chows",
      phonetic: "/tʃaʊz/",
      phonetics: [{ text: "/tʃaʊz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Food, especially snacks.",
              synonyms: [],
              antonyms: [],
              example: "I'm going to pick up some chow for dinner.",
            },
            { definition: "A Chow Chow.", synonyms: [], antonyms: [] },
            { definition: "A Chinese person.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [{ definition: "To eat.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A prefecture or district of the second rank in China, or the chief city of such a district.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A run of three consecutive tiles of the same suit.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To call a discarded tile to produce a chow.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/chow",
        "https://en.wiktionary.org/wiki/chows",
      ],
    },
  ],
  shoos: [
    {
      word: "shoos",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To induce someone or something to leave.",
              synonyms: [],
              antonyms: [],
              example: "Don't just shoo away mosquitoes, kill them!",
            },
            {
              definition: "To leave under inducement.",
              synonyms: [],
              antonyms: [],
              example:
                "You kids had better shoo before your parents get a call.",
            },
            {
              definition: "To usher someone.",
              synonyms: [],
              antonyms: [],
              example: "Shoo the visitor in.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/shoo",
        "https://en.wiktionary.org/wiki/shoos",
      ],
    },
  ],
  dusks: [
    {
      word: "dusks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A period of time at the end of day when the sun is below the horizon but before the full onset of night, especially the darker part of twilight.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A darkish colour.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["evenfall", "nightfall", "smokefall", "vespers"],
          antonyms: ["dawn", "daybreak"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dusk",
        "https://en.wiktionary.org/wiki/dusks",
      ],
    },
  ],
  parry: [
    {
      word: "parry",
      phonetic: "/ˈpæɹi/",
      phonetics: [{ text: "/ˈpæɹi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A defensive or deflective action; an act of parrying.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A simple defensive action designed to deflect an attack, performed with the forte of the blade.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(combat sports and martial arts) A defensive move intended to change the direction of an incoming strike to make it miss its intended target, rather than block and absorb it; and typically performed with an open hand in a downward or sideways slapping motion.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To avoid, deflect, or ward off (an attack, a blow, an argument, etc.).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/parry"],
    },
  ],
  decal: [
    {
      word: "decal",
      phonetic: "/dɪˈkæl/",
      phonetics: [
        {
          text: "/dɪˈkæl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/decal-ca.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=94251822",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈdiː.kæl/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A design or picture produced in order to be transferred to another surface either permanently or temporarily.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A decorative sticker.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To apply decals to.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/decal"],
    },
  ],
  furls: [
    {
      word: "furls",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To lower, roll up and secure (something, such as a sail or flag)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["unfurl"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/furl",
        "https://en.wiktionary.org/wiki/furls",
      ],
    },
  ],
  cilia: [
    {
      word: "cilia",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Hairs or similar protrusions along the margin of a plant organ.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hairlike organelle projecting from a eukaryotic cell (such as a unicellular organism or one cell of a multicelled organism). These structures serve either for locomotion by moving or as sensors.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One of the fine hairs along an insect's wing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cilia",
        "https://en.wiktionary.org/wiki/cilium",
      ],
    },
  ],
  sears: [
    {
      word: "sears",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To char, scorch, or burn the surface of (something) with a hot instrument.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To wither; to dry up.", synonyms: [], antonyms: [] },
            {
              definition: "To make callous or insensible.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To mark permanently, as if by burning.",
              synonyms: [],
              antonyms: [],
              example: "The events of that day were seared into her memory.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sear",
        "https://en.wiktionary.org/wiki/sears",
      ],
    },
  ],
  novae: [
    {
      word: "novae",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any sudden brightening of a previously inconspicuous star.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/nova",
        "https://en.wiktionary.org/wiki/novae",
      ],
    },
  ],
  murks: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  warps: [
    {
      word: "warps",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To twist or become twisted, physically or mentally:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(ropemaking) To run (yarn) off the reel into hauls to be tarred.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To arrange (strands of thread, etc) so that they run lengthwise in weaving.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To plot; to fabricate or weave (a plot or scheme).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To change or fix (make fixed, for example by freezing).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To move:", synonyms: [], antonyms: [] },
            {
              definition:
                "(obsolete outside dialectal, of an animal) To bring forth (young) prematurely.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To fertilize (low-lying land) by letting the tide, a river, or other water in upon it to deposit silt and alluvial matter.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "(very rare) To throw.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/warp",
        "https://en.wiktionary.org/wiki/warps",
      ],
    },
  ],
  slues: [
    {
      word: "slues",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The act of sluing or the place to which something has slued.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A slough; a run or wet place.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/slue",
        "https://en.wiktionary.org/wiki/slues",
      ],
    },
  ],
  lamer: [
    {
      word: "lamer",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lamer-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79268134",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Unable to walk properly because of a problem with one's feet or legs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Moving with pain or difficulty on account of injury, defect or temporary obstruction of a function.",
              synonyms: [],
              antonyms: [],
              example: "a lame leg, arm or muscle",
            },
            {
              definition:
                "(by extension) Hobbling; limping; inefficient; imperfect.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Unconvincing or unbelievable.",
              synonyms: [],
              antonyms: [],
              example:
                "He had a really lame excuse for missing the birthday party.",
            },
            {
              definition: "Failing to be cool, funny, interesting or relevant.",
              synonyms: [],
              antonyms: [],
              example: "He kept telling these extremely lame jokes all night.",
            },
          ],
          synonyms: [
            "hobbling",
            "imperfect",
            "inefficient",
            "limping",
            "boring",
            "irrelevant",
            "pathetic",
            "uncool",
            "unfunny",
            "uninteresting",
            "unbelievable",
            "weak",
            "crippled",
          ],
          antonyms: [
            "efficient",
            "perfect",
            "cool",
            "funny",
            "interesting",
            "relevant",
            "believable",
            "convincing",
          ],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person lacking in maturity, social skills, technical competence or intelligence",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lame",
        "https://en.wiktionary.org/wiki/lamer",
      ],
    },
  ],
  saris: [
    {
      word: "saris",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The traditional dress of women in the Indian Subcontinent; an outer garment consisting of a single length of cotton or silk, most often with one end wrapped around the waist to form a skirt, the other draped over the shoulder or head.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sari",
        "https://en.wiktionary.org/wiki/saris",
      ],
    },
  ],
  weans: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  purrs: [
    {
      word: "purrs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The vibrating sound made by a cat in its throat when contented.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A throaty, seductive sound of pleasure made by a person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The low consistent rumble made by an engine at slow speed",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "Of a cat, to make a vibrating sound in its throat when contented.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To say (something) in a throaty, seductive manner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make a vibrating throaty sound, as from pleasure.",
              synonyms: [],
              antonyms: [],
              example: "He purred like a kitten when she massaged his neck.",
            },
            {
              definition:
                "(of an engine) To make a low and consistent rumbling sound.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/purr",
        "https://en.wiktionary.org/wiki/purrs",
      ],
    },
  ],
  dills: [
    {
      word: "dills",
      phonetic: "/dɪlz/",
      phonetics: [{ text: "/dɪlz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Anethum graveolens (the type species of the genus Anethum), a herb, the seeds of which are moderately warming, pungent, and aromatic, formerly used as a soothing medicine for children; also known as dillseed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A cucumber pickled with dill flavoring",
              synonyms: ["dill pickle"],
              antonyms: [],
            },
            { definition: "A fool.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["anet", "dillseed", "dill pickle", "dill pickle"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dill",
        "https://en.wiktionary.org/wiki/dills",
      ],
    },
  ],
  togas: [
    {
      word: "togas",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A loose outer garment worn by the citizens of Ancient Rome.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A loose wrap gown.", synonyms: [], antonyms: [] },
            { definition: "An academic gown.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/toga",
        "https://en.wiktionary.org/wiki/togas",
      ],
    },
  ],
  newts: [
    {
      word: "newts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small lizard-like amphibian in the family Salamandridae that lives in the water as an adult.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["ask", "askard", "eft", "salamander"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/newt",
        "https://en.wiktionary.org/wiki/newts",
      ],
    },
  ],
  meany: [
    {
      word: "meany",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A mean (unkind or miserly) person; a killjoy.",
              synonyms: [],
              antonyms: [],
              example: "Teacher kept me in after school again. What a meanie!",
            },
            { definition: "A villain.", synonyms: ["baddie"], antonyms: [] },
          ],
          synonyms: ["baddie"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/meanie",
        "https://en.wiktionary.org/wiki/meany",
      ],
    },
  ],
  bunts: [
    {
      word: "bunts",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bunts-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79519686",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The middle part, cavity, or belly of a sail; the part of a furled sail which is at the center of the yard.",
              synonyms: [],
              antonyms: [],
              example: "The bunt of the sail was green.",
            },
            {
              definition: "A push or shove; a butt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A ball that has been intentionally hit softly so as to be difficult to field, sometimes with a hands-spread batting stance or with a close-hand, choked-up hand position. No swinging action is involved.",
              synonyms: [],
              antonyms: [],
              example: "The bunt was fielded cleanly.",
            },
            {
              definition: "The act of bunting.",
              synonyms: [],
              antonyms: [],
              example: "The manager will likely call for a bunt here.",
            },
            {
              definition:
                "The second half of an outside loop, from level flight to inverted flight.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A fungus (Ustilago foetida) affecting the ear of cereals, filling the grains with a foetid dust; pepperbrand.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To push with the horns; to butt.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To spring or rear up.", synonyms: [], antonyms: [] },
            {
              definition:
                "To intentionally hit softly with a hands-spread batting stance.",
              synonyms: [],
              antonyms: [],
              example: "Jones bunted the ball.",
            },
            {
              definition:
                "To intentionally hit a ball softly with a hands-spread batting stance.",
              synonyms: [],
              antonyms: [],
              example: "Jones bunted.",
            },
            {
              definition: "To perform (the second half of) an outside loop.",
              synonyms: [],
              antonyms: [],
              example:
                'We had heard that there was an elite group of three or four pilots in Jodhpur called the "Bunt Club", who had successfully bunted their aircraft - that is, carried out the second half of an outside loop. In the Bunt, you pushed the nose down, past the vertical and still further, until you were in horizontal inverted flight, and came out on the other side and rolled it out.',
            },
            {
              definition: "To swell out.",
              synonyms: [],
              antonyms: [],
              example: "The sail bunts.",
            },
            {
              definition: "(of a cat) To headbutt affectionately.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bunt",
        "https://en.wiktionary.org/wiki/bunts",
      ],
    },
    {
      word: "bunts",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bunts-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79519686",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "Money", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bunts"],
    },
  ],
  razes: [
    {
      word: "razes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To demolish; to level to the ground.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To scrape as if with a razor.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/raze",
        "https://en.wiktionary.org/wiki/razes",
      ],
    },
  ],
  goons: [
    {
      word: "goons",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A thug; a usually muscular henchman with little intelligence (also known as a 'hired goon').",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A fool; someone considered silly, stupid, awkward, or outlandish.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An enforcer or fighter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(WWII, PoW slang) A German guard in a prisoner-of-war camp.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A member of the comedy web site Something Awful.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/goon",
        "https://en.wiktionary.org/wiki/goons",
      ],
    },
  ],
  wicks: [
    {
      word: "wicks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A bundle, twist, braid, or woven strip of cord, fabric, fibre/fiber, or other porous material in a candle, oil lamp, kerosene heater, or the like, that draws up liquid fuel, such as melted tallow, wax, or the oil, delivering it to the base of the flame for conversion to gases and burning; any other length of material burned for illumination in small successive portions.",
              synonyms: [],
              antonyms: [],
              example:
                "Trim the wick fairly short, so that the flame does not smoke.",
            },
            {
              definition:
                "Any piece of porous material that conveys liquid by capillary action, such as a strip of gauze placed in a wound to serve as a drain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A narrow opening in the field, flanked by other players' stones.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A shot where the played stone touches a stationary stone just enough that the played stone changes direction.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The penis.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To convey or draw off (liquid) by capillary action.",
              synonyms: [],
              antonyms: [],
              example: "The fabric wicks perspiration away from the body.",
            },
            {
              definition:
                "(of a liquid) To traverse (i.e. be conveyed by capillary action) through a wick or other porous material, as water through a sponge. Usually followed by through.",
              synonyms: [],
              antonyms: [],
              example: "The moisture slowly wicked through the wood.",
            },
            {
              definition:
                "To strike (a stone) obliquely; to strike (a stationary stone) just enough that the played stone changes direction.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A village; hamlet; castle; dwelling; street; creek; bay; harbour; a place of work, jurisdiction, or exercise of authority.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A farm, especially a dairy farm.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A corner of the mouth or eye.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wick",
        "https://en.wiktionary.org/wiki/wicks",
      ],
    },
  ],
  ruses: [
    {
      word: "ruses",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A turning or doubling back, especially of animals to get out of the way of hunting dogs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) An action intended to deceive; a trick.",
              synonyms: ["stratagem"],
              antonyms: [],
            },
            {
              definition: "Cunning, guile, trickery.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["stratagem"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ruse",
        "https://en.wiktionary.org/wiki/ruses",
      ],
    },
  ],
  vends: [
    {
      word: "vends",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To hawk or to peddle merchandise.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To sell wares through a vending machine.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/vend",
        "https://en.wiktionary.org/wiki/vends",
      ],
    },
  ],
  geode: [
    {
      word: "geode",
      phonetic: "/ˈdʒiːəʊd/",
      phonetics: [{ text: "/ˈdʒiːəʊd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A nodule of stone having a cavity lined with mineral or crystal matter on the inside wall.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/geode"],
    },
  ],
  drake: [
    {
      word: "drake",
      phonetic: "/dɹeɪk/",
      phonetics: [
        {
          text: "/dɹeɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/drake-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769875",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A male duck.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/drake"],
    },
    {
      word: "drake",
      phonetic: "/dɹeɪk/",
      phonetics: [
        {
          text: "/dɹeɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/drake-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769875",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A mayfly used as fishing bait.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A dragon.", synonyms: [], antonyms: [] },
            {
              definition: "A small piece of artillery.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A fiery meteor.", synonyms: [], antonyms: [] },
            {
              definition: "A beaked galley, or Viking warship.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["drake fly"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/drake"],
    },
  ],
  judos: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  lofts: [
    {
      word: "lofts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(except in derivatives) air, the air; the sky, the heavens.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An attic or similar space (often used for storage) in the roof of a house or other building.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The thickness of a soft object when not under pressure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A gallery or raised apartment in a church, hall, etc.",
              synonyms: [],
              antonyms: [],
              example: "an organ loft",
            },
            {
              definition:
                "The pitch or slope of the face of a golf club (tending to drive the ball upward).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A floor or room placed above another.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To propel high into the air.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To fly or travel through the air, as though propelled",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To throw the ball erroneously through the air instead of releasing it on the lane's surface.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To furnish with a loft space.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/loft",
        "https://en.wiktionary.org/wiki/lofts",
      ],
    },
  ],
  pulps: [
    {
      word: "pulps",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A soft, moist, shapeless mass or matter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A magazine or book containing lurid subject matter and characteristically printed on rough, unfinished paper.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make or be made into pulp.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To beat to a pulp.", synonyms: [], antonyms: [] },
            {
              definition: "To deprive of pulp; to separate the pulp from.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pulp",
        "https://en.wiktionary.org/wiki/pulps",
      ],
    },
  ],
  lauds: [
    {
      word: "lauds",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Praise or glorification.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Hymn of praise.", synonyms: [], antonyms: [] },
            {
              definition:
                "(in the plural, also Lauds) A prayer service following matins.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To praise, to glorify", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/laud",
        "https://en.wiktionary.org/wiki/lauds",
      ],
    },
  ],
  mucks: [
    {
      word: "mucks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(slimy) mud, sludge.",
              synonyms: [],
              antonyms: [],
              example: "I need to clean the muck off my shirt.",
            },
            {
              definition: "Soft (or slimy) manure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Anything filthy or vile. Dirt; something that makes another thing dirty.",
              synonyms: [],
              antonyms: [],
              example: "What's that green muck on the floor?",
            },
            { definition: "Grub, slop, swill", synonyms: [], antonyms: [] },
            { definition: "Money", synonyms: [], antonyms: [] },
            {
              definition: "The pile of discarded cards.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To shovel muck.",
              synonyms: [],
              antonyms: [],
              example: "We need to muck the stable before it gets too thick.",
            },
            { definition: "To manure with muck.", synonyms: [], antonyms: [] },
            { definition: "To do a dirty job.", synonyms: [], antonyms: [] },
            {
              definition:
                "To pass, to fold without showing one's cards, often done when a better hand has already been revealed.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/muck",
        "https://en.wiktionary.org/wiki/mucks",
      ],
    },
  ],
  vises: [
    {
      word: "vises",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An instrument consisting of two jaws, closing by a screw, lever, cam, or the like, for holding work, as in filing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To clamp with or as with a vise.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To examine and endorse (a passport, etc.); to visa.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/vis%C3%A9",
        "https://en.wiktionary.org/wiki/vise",
        "https://en.wiktionary.org/wiki/vises",
      ],
    },
  ],
  mocha: [
    {
      word: "mocha",
      phonetic: "/ˈmɒkə/",
      phonetics: [
        { text: "/ˈmɒkə/", audio: "" },
        { text: "/ˈmɒkə/", audio: "" },
        { text: "/ˈmoʊkə/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A coffee drink with chocolate syrup added, or a serving thereof; a caffè mocha.",
              synonyms: ["caffè mocha", "mochaccino"],
              antonyms: [],
            },
            {
              definition: "A coffee and chocolate mixed flavour.",
              synonyms: [],
              antonyms: [],
              example: "mocha fudge",
            },
            {
              definition: "A dark brown colour, like that of mocha coffee.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A strong Arabian coffee.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An Abyssinian weight, equivalent to a Troy grain.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["caffè mocha", "mochaccino"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of a dark brown colour, like that of mocha coffee.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mocha"],
    },
  ],
  oiled: [
    {
      word: "oiled",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/oiled-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79294055",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To lubricate with oil.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To grease with oil for cooking.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Covered in, or supplied with, oil.",
              synonyms: [],
              antonyms: [],
              example: "an oiled machine; a person's oiled body",
            },
            {
              definition: "Drunk. Usually in conjunction with well.",
              synonyms: [],
              antonyms: [],
              example: "I got well oiled last night.",
            },
          ],
          synonyms: ["oily", "oleose", "inebriated", "soused"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/oil",
        "https://en.wiktionary.org/wiki/oiled",
      ],
    },
  ],
  roman: [
    {
      word: "roman",
      phonetic: "/ˈɹəʊmən/",
      phonetics: [{ text: "/ˈɹəʊmən/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One of the main three types used for the Latin alphabet (the others being italics and blackletter), in which the ascenders are mostly straight.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A novel.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "(of type) upright, as opposed to italic",
              synonyms: [],
              antonyms: [
                "italic",
                "italicised",
                "italicized",
                "oblique",
                "slanted",
                "sloped",
              ],
            },
            {
              definition: "(of text) of or related to the Latin alphabet",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [
            "italic",
            "italicised",
            "italicized",
            "oblique",
            "slanted",
            "sloped",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/roman"],
    },
  ],
  ethyl: [
    {
      word: "ethyl",
      phonetic: "/ˈɛθəl/",
      phonetics: [
        {
          text: "/ˈɛθəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ethyl-ca.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=52317182",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
        { text: "/ˈiːθaɪl/", audio: "" },
        { text: "/ˈɛθəl/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The univalent hydrocarbon radical, C2H5, formally derived from ethane by the loss of a hydrogen atom.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["E", "Et"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ethyl"],
    },
  ],
  gotta: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  fugue: [
    {
      word: "fugue",
      phonetic: "/ˈfjuːɡ/",
      phonetics: [
        {
          text: "/ˈfjuːɡ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fugue-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1316203",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A contrapuntal piece of music wherein a particular melody is played in a number of voices, each voice introduced in turn by playing the melody.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Anything in literature, poetry, film, painting, etc., that resembles a fugue in structure or in its elaborate complexity and formality.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A fugue state.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To improvise, in singing, by introducing vocal ornamentation to fill gaps etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fugue"],
    },
  ],
  smack: [
    {
      word: "smack",
      phonetic: "/smæk/",
      phonetics: [
        {
          text: "/smæk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/smack-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241760",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A distinct flavor, especially if slight.",
              synonyms: [],
              antonyms: [],
              example: "rice pudding with a smack of cinnamon",
            },
            {
              definition: "A slight trace of something; a smattering.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Heroin.", synonyms: [], antonyms: [] },
            {
              definition: "A form of fried potato; a scallop.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To get the flavor of.", synonyms: [], antonyms: [] },
            {
              definition: "To indicate or suggest something; used with of.",
              synonyms: [],
              antonyms: [],
              example: "Her reckless behavior smacks of pride.",
            },
            {
              definition: "To have a particular taste; used with of.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/smack"],
    },
    {
      word: "smack",
      phonetic: "/smæk/",
      phonetics: [
        {
          text: "/smæk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/smack-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241760",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small sailing vessel, commonly rigged as a sloop, used chiefly in the coasting and fishing trade and often called a fishing smack",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A group of jellyfish.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/smack"],
    },
    {
      word: "smack",
      phonetic: "/smæk/",
      phonetics: [
        {
          text: "/smæk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/smack-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241760",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A sharp blow; a slap. See also: spank.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The sound of a loud kiss.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A quick, sharp noise, as of the lips when suddenly separated, or of a whip.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To slap someone.", synonyms: [], antonyms: [] },
            {
              definition: "To make a smacking sound.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To strike a child (usually on the buttocks) as a form of discipline. (US spank)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To wetly separate the lips, making a noise, after tasting something or in expectation of a treat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To kiss with a close compression of the lips, so as to make a sound when they separate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "As if with a smack or slap; smartly; sharply.",
              synonyms: [],
              antonyms: [],
              example: "Right smack bang in the middle.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/smack"],
    },
  ],
  gourd: [
    {
      word: "gourd",
      phonetic: "/ɡɔːd/",
      phonetics: [
        { text: "/ɡɔːd/", audio: "" },
        {
          text: "/ɡɔːɹd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gourd-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=7448241",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of the trailing or climbing vines producing fruit with a hard rind or shell, from the genera Lagenaria and Cucurbita (in Cucurbitaceae).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hard-shelled fruit from a plant in Lagenaria or Cucurbita.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The dried and hardened shell of such fruit, made into a drinking vessel, bowl, spoon, or other objects designed for use or decoration.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of the climbing or trailing plants from the family Cucurbitaceae, which includes watermelon, pumpkins, and cucumbers.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Loaded dice.", synonyms: [], antonyms: [] },
            {
              definition: "Head.",
              synonyms: [],
              antonyms: [],
              example: "I got so stoned last night. I was out of my gourd.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gourd"],
    },
  ],
  bumpy: [
    {
      word: "bumpy",
      phonetic: "/ˈbʌmpi/",
      phonetics: [{ text: "/ˈbʌmpi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Rough; jumpy; causing or characterized by jolts and irregular movements",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Covered with or full of bumps",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bumpy"],
    },
  ],
  radix: [
    {
      word: "radix",
      phonetic: "/ɹeɪ.dɪks/",
      phonetics: [{ text: "/ɹeɪ.dɪks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A root.", synonyms: [], antonyms: [] },
            {
              definition:
                "A primitive word, from which other words may be derived.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The number of distinct symbols used to represent numbers in a particular base, as ten for decimal.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["primitive", "radical"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/radix"],
    },
  ],
  fatty: [
    {
      word: "fatty",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fatty-1-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241178",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈfæt.i/", audio: "" },
        { text: "/ˈfæɾ.i/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Containing, composed of, or consisting of fat.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Like fat; greasy.", synonyms: [], antonyms: [] },
            {
              definition: "Literally or figuratively large.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fatty"],
    },
    {
      word: "fatty",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An obese person.",
              synonyms: [],
              antonyms: [],
              example:
                "It's hardly surprising, when it has to support that enormous gut! Lose some weight, fatty!",
            },
            {
              definition: "A large marijuana cigar; a blunt.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fatty"],
    },
  ],
  borax: [
    {
      word: "borax",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A white or gray/grey crystalline salt, with a slight alkaline taste, used as a flux, in soldering metals, making enamels, fixing colors/colours on porcelain, and as a soap, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The sodium salt of boric acid, Na2B4O7, either anhydrous or with 5 or 10 molecules of water of crystallisation; sodium tetraborate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(sometimes attributive) Cheap or tawdry furniture or other works of industrial design.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["E285", "preservative"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/borax"],
    },
  ],
  cubit: [
    {
      word: "cubit",
      phonetic: "/ˈkjuː.bɪt/",
      phonetics: [
        {
          text: "/ˈkjuː.bɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cubit-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89034009",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈkju.bɪt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Various former units of length notionally based on the distance from a grown man's elbow to his fingertips, standardized in different places and times at values between 35 and 60 cm.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The ulna.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["ulna", "Russian cubit", "arshin", "dira", "ell"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cubit"],
    },
  ],
  cacti: [
    {
      word: "cacti",
      phonetic: "/ˈkæktaɪ/",
      phonetics: [{ text: "/ˈkæktaɪ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any member of the family Cactaceae, a family of flowering New World succulent plants suited to a hot, semi-desert climate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any succulent plant with a thick fleshy stem bearing spines but no leaves, such as euphorbs.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cacti",
        "https://en.wiktionary.org/wiki/cactus",
      ],
    },
  ],
  gamma: [
    {
      word: "gamma",
      phonetic: "/ˈɡæmə/",
      phonetics: [
        {
          text: "/ˈɡæmə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gamma-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=36786592",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The third letter of the Greek alphabet (Γ, γ), preceded by beta (Β, β) and followed by delta, (Δ, δ).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The Gamma function, symbolized by Γ.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A constant approximately equal to 0.55721566, symbolized by γ (also known as the Euler–Mascheroni constant).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A non-SI unit of measure of magnetic flux density, equal to 1 nT.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(industries) slope of log-log plot of video input and luminance output.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A second-order measure of derivative price sensitivity, expressed as the rate of change in delta with respect to changes in the underlying asset price.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A nonlinear operation used to encode and decode luminance or tristimulus values in video or still image systems.",
              synonyms: ["gamma"],
              antonyms: [],
            },
          ],
          synonyms: ["gamma"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gamma",
        "https://en.wiktionary.org/wiki/gamma%20correction",
      ],
    },
  ],
  focal: [
    {
      word: "focal",
      phonetic: "/ˈfəʊ.kl̩/",
      phonetics: [
        { text: "/ˈfəʊ.kl̩/", audio: "" },
        { text: "/ˈfoʊ.kl̩/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Belonging to, concerning, or located at a focus",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Limited to a small area",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/focal"],
    },
  ],
  avail: [
    {
      word: "avail",
      phonetic: "/əˈveɪl/",
      phonetics: [
        {
          text: "/əˈveɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/avail-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75729687",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/əˈveɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/avail-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=474117",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Effect in achieving a goal or aim; purpose, use (now usually in negative constructions).",
              synonyms: [],
              antonyms: [],
              example:
                "I tried fixing it, to no avail.  Labor, without economy, is of little avail.",
            },
            {
              definition: "Proceeds; profits from business transactions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An advertising slot or package.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A press avail.",
              synonyms: [],
              antonyms: [],
              example:
                "While holding an avail yesterday, the candidate lashed out at critics.",
            },
            {
              definition: "Non-binding notice of availability for work.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A readily available stock of oil.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Benefit; value, profit; advantage toward success.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Effort; striving.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To turn to the advantage of.",
              synonyms: [],
              antonyms: [],
              example: "I availed myself of the opportunity.",
            },
            {
              definition: "To be of service to.",
              synonyms: [],
              antonyms: [],
              example:
                "Artifices will not avail the sinner in the day of judgment.",
            },
            {
              definition: "To promote; to assist.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be of use or advantage; to answer or serve the purpose; to have strength, force, or efficacy sufficient to accomplish the object.",
              synonyms: [],
              antonyms: [],
              example: "Medicines will not avail to halt the disease.",
            },
            {
              definition: "To provide; to make available.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["disavail"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/avail"],
    },
  ],
  papal: [
    {
      word: "papal",
      phonetic: "/ˈpeɪpəl/",
      phonetics: [{ text: "/ˈpeɪpəl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having to do with the pope or the papacy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/papal"],
    },
  ],
  golly: [
    {
      word: "golly",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/golly-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75271549",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "interjection",
          definitions: [{ definition: "God!", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/golly"],
    },
    {
      word: "golly",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/golly-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75271549",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A rag doll or mascot in the form of a caricature of a black minstrel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(racist) A black person.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A hairy caterpillar.", synonyms: [], antonyms: [] },
            {
              definition: "A receiver of stolen goods.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Greyhound racing: the jolly dogs.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/golliwog",
        "https://en.wiktionary.org/wiki/golly",
      ],
    },
    {
      word: "golly",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/golly-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75271549",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A galosh.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/golly"],
    },
    {
      word: "golly",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/golly-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75271549",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(juvenile) Chewing gum.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(juvenile) Saliva or phlegm.",
              synonyms: [],
              antonyms: [],
              example: "hack up a golly",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(juvenile) To spit; to force up phlegm from one's throat.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/golly"],
    },
  ],
  elite: [
    {
      word: "elite",
      phonetic: "/eɪˈliːt/",
      phonetics: [{ text: "/eɪˈliːt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A special group or social class of people which have a superior intellectual, social or economic status as, the elite of society.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Someone who is among the best at a certain task.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Of high birth or social position; aristocratic or patrician.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Representing the choicest or most select of a group.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/elite"],
    },
  ],
  versa: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  billy: [
    {
      word: "billy",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/billy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2100338",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A billy club.", synonyms: [], antonyms: [] },
            { definition: "A billy goat.", synonyms: [], antonyms: [] },
            { definition: "A good friend.", synonyms: [], antonyms: [] },
            {
              definition:
                "A tin used by bushmen to boil tea; a billycan; a billypot.",
              synonyms: [],
              antonyms: [],
              example: "Let's get the billy and cook some beans.",
            },
            {
              definition:
                'A condom (from the E-Rotic song "Willy, Use a Billy... Boy")',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A slubbing or roving machine.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/billy"],
    },
  ],
  adieu: [
    {
      word: "adieu",
      phonetic: "/əˈdjuː/",
      phonetics: [
        { text: "/əˈdjuː/", audio: "" },
        {
          text: "/əˈdju/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/adieu-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2453353",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A farewell, a goodbye; especially a fond farewell, or a lasting or permanent farewell.",
              synonyms: [],
              antonyms: [],
              example:
                "We bid our final adieus to our family, then boarded the ship, bound for America.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "Said to wish a final farewell; goodbye.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "addio",
            "adios",
            "aloha",
            "arrivederci",
            "au revoir",
            "auf Wiedersehen",
            "bye",
            "bye-bye",
            "cheerio",
            "cheers",
            "ciao",
            "farewell",
            "good day",
            "good-by",
            "good-bye",
            "goodbye",
            "sayonara",
            "shalom",
            "so long",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/adieu"],
    },
  ],
  annum: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  howdy: [
    {
      word: "howdy",
      phonetic: "/ˈhaʊdi/",
      phonetics: [
        {
          text: "/ˈhaʊdi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/howdy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=49875766",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A wife, a midwife.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: 'To greet informally, especially by saying "howdy"',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "An informal greeting used in the South of the USA.",
              synonyms: [],
              antonyms: [],
              example:
                "Howdy folks, and welcome to our ninth annual chili cookoff!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/howdy"],
    },
  ],
  rhino: [
    {
      word: "rhino",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rhino-1-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79218857",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈɹaɪ.nəʊ/", audio: "" },
        { text: "/ˈɹaɪ.noʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A rhinoceros.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rhino"],
    },
    {
      word: "rhino",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "Money", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rhino"],
    },
  ],
  norms: [
    {
      word: "norms",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "That which is normal or typical.",
              synonyms: [],
              antonyms: [],
              example: "Unemployment is the norm in this part of the country.",
            },
            {
              definition: "A rule that is enforced by members of a community.",
              synonyms: [],
              antonyms: [],
              example:
                "Not eating your children is just one of those societal norms.",
            },
            {
              definition:
                "A sentence with non-descriptive meaning, such as a command, permission or prohibition.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A function, generally denoted v\\mapsto\\left|v\\right| or v\\mapsto\\left\\|v\\right\\|, that maps vectors to non-negative scalars and has the following properties:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A high level of performance in a chess tournament, several of which are required for a player to receive a title.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/norm",
        "https://en.wiktionary.org/wiki/norms",
      ],
    },
  ],
  bobby: [
    {
      word: "bobby",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bobby-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79218564",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈbɒbi/", audio: "" },
        { text: "/ˈbɑbi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A police officer.", synonyms: [], antonyms: [] },
            { definition: "A railway signaller.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bobby"],
    },
    {
      word: "bobby",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bobby-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79218564",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈbɒbi/", audio: "" },
        { text: "/ˈbɑbi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A penis.", synonyms: [], antonyms: [] },
            { definition: "Cocaine.", synonyms: [], antonyms: [] },
            {
              definition:
                "A calf that is slaughtered in its first month, usually because it has no dam or because it is undersized or otherwise defective.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A banana that is extra small in size.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bobby"],
    },
  ],
  setup: [
    {
      word: "setup",
      phonetic: "/ˈsɛtˌʌp/",
      phonetics: [{ text: "/ˈsɛtˌʌp/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Equipment designed for a particular purpose; an apparatus.",
              synonyms: [],
              antonyms: [],
              example:
                "The laboratory included an elaborate setup for measuring the energy.",
            },
            {
              definition:
                "The fashion in which something is organized or arranged.",
              synonyms: [],
              antonyms: [],
              example: "The classroom setup was simple and efficient.",
            },
            {
              definition:
                "A situation orchestrated to frame someone; a covert effort to place the blame on somebody.",
              synonyms: [],
              antonyms: [],
              example: "Trust me, that was a setup!",
            },
            {
              definition: "An installer.",
              synonyms: [],
              antonyms: [],
              example: "After inserting the disk, run the setup.",
            },
            {
              definition:
                "(operations) The process of arranging resources for performing a specific operation, as a run of a particular product.",
              synonyms: [],
              antonyms: [],
              example:
                "A simple setup on the bottling line involves reloading bottles and labels; emptying, cleaning, and reloading the tanks; and a test run.",
            },
            {
              definition:
                "The tendency of persistent wind to produce higher water levels at the downwind shore of a body of water and lower at the upwind shore.",
              synonyms: [],
              antonyms: [],
              example:
                "There was a strong wind setup at the south end of the lake after a day of northerly gales.",
            },
          ],
          synonyms: [
            "apparatus",
            "frameup",
            "stitch-up",
            "configuration",
            "layout",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/setup"],
    },
  ],
  yolks: [
    {
      word: "yolks",
      phonetic: "/jəʊks/",
      phonetics: [
        { text: "/jəʊks/", audio: "" },
        { text: "/joʊks/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The yellow, spherical part of an egg that is surrounded by the white albumen, and serves as nutriment for the growing young.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The grease in a sheep's fleece.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/yolk",
        "https://en.wiktionary.org/wiki/yolks",
      ],
    },
  ],
  terns: [
    {
      word: "terns",
      phonetic: "/ˈtɜː(ɹ)nz/",
      phonetics: [
        {
          text: "/ˈtɜː(ɹ)nz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/terns-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80152024",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of various sea birds of the family Sternidae that are similar to gulls but are smaller and have a forked tail.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "That which consists of, or pertains to, three things or numbers together.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A lottery prize resulting from the favourable combination of three numbers in the draw.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tern",
        "https://en.wiktionary.org/wiki/terns",
      ],
    },
  ],
  mixer: [
    {
      word: "mixer",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One who, or a device that, mixes or merges things together.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One who mixes or socializes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A machine outfitted with (typically blunt) blades with which it mixes or beats ingredients in a bowl below.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A non-alcoholic drink (such as lemonade, Coca-Cola or fruit juice) that is added to spirits to make cocktails.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A mixing console.", synonyms: [], antonyms: [] },
            {
              definition:
                "A dance or other social event meant to foster new acquaintances, as at the beginning of a school year.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various social dances involving frequent changes of partners.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A device for combining hot and cold water before it emerges from a single spout or shower head.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A nonlinear electrical circuit that creates new frequencies from two signals applied to it.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mixer"],
    },
  ],
  genre: [
    {
      word: "genre",
      phonetic: "/(d)ʒɒn.ɹə/",
      phonetics: [
        {
          text: "/(d)ʒɒn.ɹə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/genre-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=501258",
        },
        {
          text: "/(d)ʒɑn.ɹə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/genre-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=453980",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A kind; a stylistic category or sort, especially of literature or other artworks.",
              synonyms: [],
              antonyms: [],
              example:
                "The computer game Half-Life redefined the first-person shooter genre.",
            },
          ],
          synonyms: ["class", "kind", "type"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/genre"],
    },
  ],
  knoll: [
    {
      word: "knoll",
      phonetic: "/nəʊl/",
      phonetics: [
        { text: "/nəʊl/", audio: "" },
        { text: "/noʊl/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A small mound or rounded hill.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/knoll"],
    },
    {
      word: "knoll",
      phonetic: "/nəʊl/",
      phonetics: [
        { text: "/nəʊl/", audio: "" },
        { text: "/noʊl/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "A knell.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To ring (a bell) mournfully; to knell.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To sound, like a bell; to knell.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/knoll"],
    },
    {
      word: "knoll",
      phonetic: "/nəʊl/",
      phonetics: [
        { text: "/nəʊl/", audio: "" },
        { text: "/noʊl/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To arrange related objects in parallel or at 90 degree angles.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/knoll"],
    },
  ],
  abode: [
    {
      word: "abode",
      phonetic: "/əˈbəʊd/",
      phonetics: [
        { text: "/əˈbəʊd/", audio: "" },
        {
          text: "/əˈboʊd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/abode-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1755027",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To endure without yielding; to withstand; await defiantly; to encounter; to persevere.",
              synonyms: [],
              antonyms: [],
              example: "The old oak tree abides the wind endlessly.",
            },
            {
              definition:
                "To bear patiently; to tolerate; to put up with; stand.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To pay for; to stand the consequences of; to answer for; to suffer for; to atone for.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To wait in expectation.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To pause; to delay.", synonyms: [], antonyms: [] },
            {
              definition:
                "To stay; to continue in a place; to remain stable or fixed in some state or condition; to be left.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To have one's abode; to dwell; to reside; to sojourn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To endure; to remain; to last.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To stand ready for; to await for someone; watch for.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To endure or undergo a hard trial or a task; to stand up under.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To await submissively; accept without question; submit to.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "brook",
            "put up with",
            "hold on",
            "resist",
            "dwell",
            "live",
            "await",
            "wait for",
            "hold on",
            "stay",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Act of waiting; delay.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Stay or continuance in a place; sojourn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A residence, dwelling or habitation.",
              synonyms: [],
              antonyms: [],
              example: "of no fixed abode",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/abide",
        "https://en.wiktionary.org/wiki/abode",
      ],
    },
    {
      word: "abode",
      phonetic: "/əˈbəʊd/",
      phonetics: [
        { text: "/əˈbəʊd/", audio: "" },
        {
          text: "/əˈboʊd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/abode-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1755027",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An omen; a foretelling.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To bode; to foreshow; to presage.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To be ominous.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/abode"],
    },
  ],
  junta: [
    {
      word: "junta",
      phonetic: "/ˈdʒʊntə/",
      phonetics: [
        {
          text: "/ˈdʒʊntə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/junta-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88942244",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈhʊntə/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A council, convention, tribunal or assembly; especially, the grand council of state in Spain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The ruling council of a military dictatorship.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/junta"],
    },
  ],
  gorge: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  combo: [
    {
      word: "combo",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/combo-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75270081",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A small musical group.",
              synonyms: [],
              antonyms: [],
              example:
                "The jazz combo played nightly at the little restaurant.",
            },
            {
              definition: "A combination.",
              synonyms: [],
              antonyms: [],
              example: "I need to open the safe but I forgot the combo.",
            },
            {
              definition:
                "An action composed of a sequence of simpler actions, especially a composite attacking move in a fighting game.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A Caucasian man who marries or has a sexual relationship with an Aboriginal woman; a Caucasian man who lives among Aboriginal people and adopts Aboriginal culture",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A combo box.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/combo"],
    },
  ],
  alpha: [
    {
      word: "alpha",
      phonetic: "/ˈælfə/",
      phonetics: [
        {
          text: "/ˈælfə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/alpha-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762861",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The name of the first letter of the Greek alphabet (Α,  α), followed by beta. In the Latin alphabet it is the predecessor to A.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Latin alpha", synonyms: [], antonyms: [] },
            {
              definition:
                "The name of the symbols Α and α used in science and mathematics, often interchangeable with the symbols when used as a prefix.",
              synonyms: [],
              antonyms: [],
              example:
                'I will attempt to make an alpha particle ("α-particle") with the Large Hadron Collider.',
            },
            {
              definition:
                "The return of a given asset or portfolio adjusted for systematic risk.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person, especially a male, who is dominant, successful and attractive; (see alpha male).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(abbreviation) Alphabet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The first versions of a program, usually only available to the developer, and only tested by the developer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The level of translucency of a color, as determined by the alpha channel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The significance level of a statistical test; the alpha level.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In omegaverse fiction, a person of a sexually-dominant (and sometimes secondary) gender/sex that is driven by biology, magic, or other means to bond with an omega, with males of this type often having canine-like genitalia.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Designates the first in an order of precedence.",
              synonyms: [],
              antonyms: [],
              example: "I am the alpha male.",
            },
            {
              definition:
                "(of person, object or action) exhibiting characteristics of the alpha male/female archetype.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Designates some bright star, usually the brightest star, of a constellation.",
              synonyms: [],
              antonyms: [],
              example:
                "When space travel becomes feasible, I plan to visit Alpha Centauri.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/alpha"],
    },
  ],
  overt: [
    {
      word: "overt",
      phonetic: "/ə(ʊ)ˈvɜːt/",
      phonetics: [
        {
          text: "/ə(ʊ)ˈvɜːt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/overt-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88675085",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/oʊˈvɚt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Open and not concealed or secret.",
              synonyms: ["manifest", "open", "patent", "plain", "unconcealed"],
              antonyms: ["covert", "hidden", "nonovert"],
            },
          ],
          synonyms: ["manifest", "open", "patent", "plain", "unconcealed"],
          antonyms: ["covert", "covert", "hidden", "nonovert"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/overt"],
    },
  ],
  kinda: [
    {
      word: "kinda",
      phonetic: "/ˈkaɪndə/",
      phonetics: [
        {
          text: "/ˈkaɪndə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/kinda-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1234301",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Kind of; somewhat",
              synonyms: [],
              antonyms: [],
              example: "I kinda hafta do this right now.",
            },
          ],
          synonyms: ["sorta"],
          antonyms: [],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "Yes in some respects but no in other respects.",
              synonyms: [],
              antonyms: [],
              example:
                '"Are you afraid of a little bit of rain?" "Kinda, yeah."',
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/kinda"],
    },
    {
      word: "kinda",
      phonetic: "/ˈkaɪndə/",
      phonetics: [
        {
          text: "/ˈkaɪndə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/kinda-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1234301",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A subspecies of baboon, Papio cynocephalus kindae, primarily found in Angola, the Democratic Republic of the Congo, Zambia, and possibly western Tanzania.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/kinda"],
    },
  ],
  spelt: [
    {
      word: "spelt",
      phonetic: "/ˈspɛlt/",
      phonetics: [
        {
          text: "/ˈspɛlt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spelt-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90913424",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To read (something) as though letter by letter; to peruse slowly or with effort.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(sometimes with “out”) To write or say the letters that form a word or part of a word.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be able to write or say the letters that form words.",
              synonyms: [],
              antonyms: [],
              example: "I find it difficult to spell because I'm dyslexic.",
            },
            {
              definition: "Of letters: to compose (a word).",
              synonyms: [],
              antonyms: [],
              example: "The letters “a”, “n” and “d” spell “and”.",
            },
            {
              definition: "(with “out”) To clarify; to explain in detail.",
              synonyms: [],
              antonyms: [],
              example: "Please spell it out for me.",
            },
            {
              definition: "To indicate that (some event) will occur.",
              synonyms: [],
              antonyms: [],
              example: "This spells trouble.",
            },
            {
              definition: "To constitute; to measure.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To speak, to declaim.", synonyms: [], antonyms: [] },
            {
              definition: "To tell; to relate; to teach.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["comprise", "forebode", "mean", "signify", "relieve"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To work in place of (someone).",
              synonyms: [],
              antonyms: [],
              example: "to spell the helmsman",
            },
            {
              definition:
                "To rest (someone or something), to give someone or something a rest or break.",
              synonyms: [],
              antonyms: [],
              example:
                "They spelled the horses and rested in the shade of some trees near a brook.",
            },
            {
              definition: "To rest from work for a time.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/spell",
        "https://en.wiktionary.org/wiki/spelt",
      ],
    },
    {
      word: "spelt",
      phonetic: "/ˈspɛlt/",
      phonetics: [
        {
          text: "/ˈspɛlt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spelt-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90913424",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A grain, considered either a subspecies of wheat, Triticum aestivum subsp. spelta, or a separate species Triticum spelta or Triticum dicoccon.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spelt"],
    },
    {
      word: "spelt",
      phonetic: "/ˈspɛlt/",
      phonetics: [
        {
          text: "/ˈspɛlt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spelt-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90913424",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A thin piece of wood or metal; a splinter.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Spelter.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To split; to break; to spalt.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spelt"],
    },
  ],
  prick: [
    {
      word: "prick",
      phonetic: "/pɹɪk/",
      phonetics: [
        {
          text: "/pɹɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/prick-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79218829",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A small hole or perforation, caused by piercing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An indentation or small mark made with a pointed object.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A dot or other diacritical mark used in writing; a point.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A tiny particle; a small amount of something; a jot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A small pointed object.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The experience or feeling of being pierced or punctured by a small, sharp object.",
              synonyms: [],
              antonyms: [],
              example:
                "I felt a sharp prick as the nurse took a sample of blood.",
            },
            { definition: "A feeling of remorse.", synonyms: [], antonyms: [] },
            { definition: "The penis.", synonyms: [], antonyms: [] },
            {
              definition:
                "Someone (especially a man or boy) who is unpleasant, rude or annoying.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A small roll of yarn or tobacco.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The footprint of a hare.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A point or mark on the dial, noting the hour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The point on a target at which an archer aims; the mark; the pin.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/prick"],
    },
    {
      word: "prick",
      phonetic: "/pɹɪk/",
      phonetics: [
        {
          text: "/pɹɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/prick-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79218829",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To pierce or puncture slightly.",
              synonyms: [],
              antonyms: [],
              example:
                "John hardly felt the needle prick his arm when the adept nurse drew blood.",
            },
            {
              definition: "To form by piercing or puncturing.",
              synonyms: [],
              antonyms: [],
              example: "to prick a pattern for embroidery",
            },
            {
              definition:
                "To mark or denote by a puncture; to designate by pricking; to choose; to mark.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To mark the surface of (something) with pricks or dots; especially, to trace a ship’s course on (a chart).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To run a middle seam through the cloth of a sail.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To fix by the point; to attach or hang by puncturing.",
              synonyms: [],
              antonyms: [],
              example: "to prick a knife into a board",
            },
            {
              definition:
                "To be punctured; to suffer or feel a sharp pain, as by puncture.",
              synonyms: [],
              antonyms: [],
              example: "A sore finger pricks.",
            },
            {
              definition:
                "To make or become sharp; to erect into a point; to raise, as something pointed; said especially of the ears of an animal, such as a horse or dog; and usually followed by up.",
              synonyms: [],
              antonyms: [],
              example: "The dog's ears pricked up at the sound of a whistle.",
            },
            {
              definition:
                "Usually in the form prick out: to plant (seeds or seedlings) in holes made in soil at regular intervals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To incite, stimulate, goad.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To urge one's horse on; to ride quickly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To affect with sharp pain; to sting, as with remorse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make acidic or pungent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To become sharp or acid; to turn sour, as wine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To aim at a point or mark.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Usually as prick up: to dress or adorn; to prink.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/prick"],
    },
  ],
  nobly: [
    {
      word: "nobly",
      phonetic: "/ˈnəʊbli/",
      phonetics: [
        { text: "/ˈnəʊbli/", audio: "" },
        {
          text: "/ˈnoʊbli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/nobly-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2454160",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "In a noble manner.",
              synonyms: [],
              antonyms: [],
              example:
                "In the end he died nobly, trying to save the lives of others.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/nobly"],
    },
  ],
  ephod: [
    {
      word: "ephod",
      phonetic: "/ˈiː.fɒd/",
      phonetics: [{ text: "/ˈiː.fɒd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A priestly apron, or breastplate, described in the Bible in Exodus 28: vi - xxx, which only the chief priest of ancient Israel was allowed to wear.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ephod"],
    },
  ],
  audio: [
    {
      word: "audio",
      phonetic: "/ˈɔː.di.əʊ/",
      phonetics: [
        { text: "/ˈɔː.di.əʊ/", audio: "" },
        {
          text: "/ˈɔ.di.oʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/audio-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=3899033",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A sound, or a sound signal",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Focused on audible sound, as opposed to sight.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/audio"],
    },
  ],
  modal: [
    {
      word: "modal",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A modal proposition.", synonyms: [], antonyms: [] },
            {
              definition: "A modal form, notably a modal auxiliary.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(grammar) A modal verb.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A modal window, one that cannot be closed until a decision is made.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of, or relating to a mode or modus",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(grammar) of, relating to, or describing the mood of a clause",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of, relating to, or composed in the musical modi by which an octave is divided, associated with emotional moods in Ancient — and in medieval ecclesiastical music",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of, or relating to the modality between propositions",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Relating to the statistical mode.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Having separate modes in which user input has different effects.",
              synonyms: [],
              antonyms: ["modeless"],
            },
            {
              definition:
                "Requiring immediate user interaction and thus presented so that it cannot be closed or interacted behind until a decision is made.",
              synonyms: [],
              antonyms: ["modeless"],
              example: "a modal dialog; a modal window",
            },
            {
              definition:
                "Relating to the form of a thing rather to any of its attributes",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["conditioning", "forming"],
          antonyms: ["modeless", "modeless"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/modal"],
    },
  ],
  veldt: [
    {
      word: "veldt",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The open pasture land or grassland of South Africa and neighboring countries.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/veld",
        "https://en.wiktionary.org/wiki/veldt",
      ],
    },
  ],
  warty: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  fluke: [
    {
      word: "fluke",
      phonetic: "/fluːk/",
      phonetics: [
        { text: "/fluːk/", audio: "" },
        {
          text: "/fluk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fluke-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50699386",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A lucky or improbable occurrence, with the implication that the occurrence could not be repeated.",
              synonyms: [],
              antonyms: [],
              example: "The first goal was just a fluke.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To obtain a successful outcome by pure chance.",
              synonyms: [],
              antonyms: [],
              example: "I fluked a pass in the multiple-choice exam.",
            },
            {
              definition: "To fortuitously pot a ball in an unintended way.",
              synonyms: [],
              antonyms: [],
              example:
                "He fluked the other red into the middle pocket, despite the double kiss.",
            },
          ],
          synonyms: ["glitch"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fluke"],
    },
    {
      word: "fluke",
      phonetic: "/fluːk/",
      phonetics: [
        { text: "/fluːk/", audio: "" },
        {
          text: "/fluk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fluke-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50699386",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A flounder.", synonyms: [], antonyms: [] },
            {
              definition:
                "A trematode; a parasitic flatworm of the Trematoda class, related to the tapeworm.",
              synonyms: [],
              antonyms: [],
              example:
                "The man had become infected with flukes after eating a meal of raw fish.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fluke"],
    },
    {
      word: "fluke",
      phonetic: "/fluːk/",
      phonetics: [
        { text: "/fluːk/", audio: "" },
        {
          text: "/fluk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fluke-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50699386",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Either of the two lobes of a whale's or similar creature's tail.",
              synonyms: [],
              antonyms: [],
              example:
                "The dolphin had an open wound on the left fluke of its tail where the propeller had injured it.",
            },
            {
              definition:
                "Any of the triangular blades at the end of an anchor, designed to catch the ground.",
              synonyms: [],
              antonyms: [],
              example:
                "The fluke of the anchor was wedged between two outcroppings of rock and could not be dislodged.",
            },
            {
              definition:
                "A metal hook on the head of certain staff weapons (such as a bill), made in various forms depending on function, whether used for grappling or to penetrate armour when swung at an opponent.",
              synonyms: [],
              antonyms: [],
              example:
                "The polearm had a wide, sharpened fluke attached to the central point.",
            },
            {
              definition:
                "In general, a winglike formation on a central piece.",
              synonyms: [],
              antonyms: [],
              example:
                "After casting the bronze statue, we filed down the flukes and spurs from the molding process.",
            },
            { definition: "Waste cotton.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fluke"],
    },
  ],
  bonny: [
    {
      word: "bonny",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Merry; happy.",
              synonyms: ["blithe", "cheerful", "frolicsome", "gay"],
              antonyms: [],
            },
            {
              definition: "Beautiful; pretty; attractive.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Fine, good (often used ironically).",
              synonyms: [],
              antonyms: [],
              example: "My bonnie friend, come over here.",
            },
          ],
          synonyms: ["blithe", "cheerful", "frolicsome", "gay"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bonnie",
        "https://en.wiktionary.org/wiki/bonny",
      ],
    },
    {
      word: "bonny",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A round and compact bed of ore, or a distinct bed, not communicating with a vein.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bonny"],
    },
  ],
  bream: [
    {
      word: "bream",
      phonetic: "/bɹiːm/",
      phonetics: [
        { text: "/bɹiːm/", audio: "" },
        {
          text: "/bɹim/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bream-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=36696467",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A European fresh-water cyprinoid fish of the genus Abramis, little valued as food. Several species are known.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A species in that genus, Abramis brama.",
              synonyms: ["carp bream"],
              antonyms: [],
            },
            {
              definition:
                "An American fresh-water fish, of various species of Lepomis and allied genera, which are also called sunfishes and pondfishes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A marine sparoid fish of the genus Pagellus, and allied genera.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["carp bream"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bream"],
    },
    {
      word: "bream",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To clean (e.g. a ship's bottom of clinging shells, seaweed, etc.) by the application of fire and scraping.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bream"],
    },
  ],
  rosin: [
    {
      word: "rosin",
      phonetic: "/ˈɹɒ.zən/",
      phonetics: [
        { text: "/ˈɹɒ.zən/", audio: "" },
        { text: "/ˈɹɑ.zən/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A solid form of resin, obtained from liquid resin by vaporizing its volatile components.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Resin.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["Greek pitch", "colophony"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To apply rosin to (something); to rub or cover with rosin.",
              synonyms: [],
              antonyms: [],
              example:
                "We waited expectantly as the guest violinist rosined his bow in preparation for playing.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rosin"],
    },
  ],
  bolls: [
    {
      word: "bolls",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The rounded seed-bearing capsule of a cotton or flax plant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An old dry measure equal to six bushels.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/boll",
        "https://en.wiktionary.org/wiki/bolls",
      ],
    },
  ],
  doers: [
    {
      word: "doers",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Someone who does, performs, or executes; an active person, an agent.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/doer",
        "https://en.wiktionary.org/wiki/doers",
      ],
    },
  ],
  downs: [
    {
      word: "downs",
      phonetic: "/daʊnz/",
      phonetics: [{ text: "/daʊnz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A negative aspect; a downer.",
              synonyms: [],
              antonyms: [],
              example:
                "I love almost everything about my job. The only down is that I can't take Saturdays off.",
            },
            {
              definition: "A grudge (on someone).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An act of swallowing an entire drink at once.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A single play, from the time the ball is snapped (the start) to the time the whistle is blown (the end) when the ball is down, or is downed.",
              synonyms: [],
              antonyms: [],
              example:
                "I bet after the third down, the kicker will replace the quarterback on the field.",
            },
            {
              definition:
                "(crosswords) A clue whose solution runs vertically in the grid.",
              synonyms: [],
              antonyms: [],
              example:
                "I haven't solved 12 or 13 across, but I've got most of the downs.",
            },
            {
              definition: "A downstairs room of a two-story house.",
              synonyms: [],
              antonyms: [],
              example: "She lives in a two-up two-down.",
            },
            { definition: "Down payment.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To knock (someone or something) down; to cause to come down, to fell.",
              synonyms: [],
              antonyms: [],
              example: "A single rifle shot downed the mighty beast.",
            },
            {
              definition: "To lower; to put (something) down.",
              synonyms: [],
              antonyms: [],
              example:
                "The bell rang for lunch, and the workers downed their tools.",
            },
            {
              definition: "To defeat; to overpower.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To disparage, to put down.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To go or come down; to descend.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To drink or swallow, especially without stopping before the vessel containing the liquid is empty.",
              synonyms: [],
              antonyms: [],
              example: "He downed an ale and ordered another.",
            },
            {
              definition:
                "To render (the ball) dead, typically by touching the ground while in possession.",
              synonyms: [],
              antonyms: [],
              example: "He downed it at the seven-yard line.",
            },
            {
              definition:
                "(pocket billiards) To sink (a ball) into a hole or pocket.",
              synonyms: [],
              antonyms: [],
              example: "He downed two balls on the break.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(especially southern England) A hill, especially a chalk hill; rolling grassland",
              synonyms: [],
              antonyms: [],
              example:
                "The North Downs are a ridge of chalk hills in south east England.",
            },
            {
              definition:
                "(usually in the plural) A field, especially one used for horse racing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(chiefly in the plural) A tract of poor, sandy, undulating or hilly land near the sea, covered with fine turf which serves chiefly for the grazing of sheep.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Soft, fluffy immature feathers which grow on young birds. Used as insulating material in duvets, sleeping bags and jackets.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The pubescence of plants; the hairy crown or envelope of the seeds of certain plants, such as the thistle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The soft hair of the face when beginning to appear.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That which is made of down, as a bed or pillow; that which affords ease and repose, like a bed of down.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cover, ornament, line, or stuff with down.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/down",
        "https://en.wiktionary.org/wiki/downs",
      ],
    },
  ],
  beady: [
    {
      word: "beady",
      phonetic: "/ˈbiːdi/",
      phonetics: [{ text: "/ˈbiːdi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Resembling beads; small, round, and gleaming.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of eyes or a look) Bright and penetrating.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Covered or ornamented with, or as if with, beads.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Characterized by beads.",
              synonyms: [],
              antonyms: [],
              example: "beady liquor",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/beady"],
    },
  ],
  motif: [
    {
      word: "motif",
      phonetic: "/moʊˈtiːf/",
      phonetics: [
        {
          text: "/moʊˈtiːf/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/motif-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=892550",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A recurring or dominant element; a theme.",
              synonyms: [],
              antonyms: [],
              example:
                "See how the artist repeats the scroll motif throughout the work?",
            },
            {
              definition:
                "A short melodic passage that is repeated in several parts of a work.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A decorative figure that is repeated in a design or pattern.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A decorative appliqué design or figure, as of lace or velvet, used in trimming.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The physical object or objects repeated at each point of a lattice. Usually atoms or molecules.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A basic element of a move in terms of why the piece moves and how it supports the fulfilment of a stipulation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In a nucleotide or amino-acid sequence, pattern that is widespread and has, or is conjectured to have, a biological significance.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/motif"],
    },
  ],
  humph: [
    {
      word: "humph",
      phonetic: "/h(ə)mf/",
      phonetics: [
        { text: "/h(ə)mf/", audio: "" },
        {
          text: "/h(ə)mf/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/humph-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=93629414",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: 'To utter "humph!" in doubt or disapproval.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "Used to express doubt or disapproval",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["humbug"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/humph"],
    },
  ],
  fella: [
    {
      word: "fella",
      phonetic: "/fɛ.lə/",
      phonetics: [{ text: "/fɛ.lə/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(chiefly South US) used to address a male",
              synonyms: [],
              antonyms: [],
              example: "Am I right, fellas?",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A colleague or partner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A companion; a comrade.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A man without good breeding or worth; an ignoble or mean man.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An equal in power, rank, character, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of a pair, or of two things used together or suited to each other; a mate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person with common characteristics, being of the same kind, or in the same group.",
              synonyms: [],
              antonyms: [],
              example: "Roger and his fellow workers are to go on strike.",
            },
            { definition: "A male person; a man.", synonyms: [], antonyms: [] },
            {
              definition: "A person; an individual, male or female.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'A rank or title in the professional world, usually given as "Fellow".',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(Aboriginal English) Used as a general intensifier",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A person who was a fellow attendee at one's school.",
              synonyms: [],
              antonyms: [],
              example:
                "I've lost touch with all my old schoolmates: I only see them at class reunions.",
            },
          ],
          synonyms: ["fellow", "schoolfellow"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fella",
        "https://en.wiktionary.org/wiki/fellow",
        "https://en.wiktionary.org/wiki/schoolmate",
      ],
    },
  ],
  mould: [
    {
      word: "mould",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mould-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75648494",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/məʊld/", audio: "" },
        {
          text: "/moʊld/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mould-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2454154",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A hollow form or matrix for shaping a fluid or plastic substance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A frame or model around or on which something is formed or shaped.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Something that is made in or shaped on a mold.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The shape or pattern of a mold.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "General shape or form.",
              synonyms: [],
              antonyms: [],
              example: "the oval mold of her face",
            },
            {
              definition: "Distinctive character or type.",
              synonyms: [],
              antonyms: [],
              example: "a leader in the mold of her predecessors",
            },
            {
              definition: "A fixed or restrictive pattern or form.",
              synonyms: [],
              antonyms: [],
              example:
                "His method of scientific investigation broke the mold and led to a new discovery.",
            },
            {
              definition: "A group of moldings.",
              synonyms: [],
              antonyms: [],
              example:
                "the arch mold of a porch or doorway;  the pier mold of a Gothic pier, meaning the whole profile, section, or combination of parts",
            },
            { definition: "A fontanelle.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To shape in or on a mold; to form into a particular shape; to give shape to.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To guide or determine the growth or development of; influence",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fit closely by following the contours of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make a mold of or from (molten metal, for example) before casting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To ornament with moldings.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be shaped in or as if in a mold.",
              synonyms: [],
              antonyms: [],
              example: "These shoes gradually molded to my feet.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A natural substance in the form of a woolly or furry growth of tiny fungi that appears when organic material lies for a long time exposed to (usually warm and moist) air.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cause to become moldy; to cause mold to grow upon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To become moldy; to be covered or filled, in whole or in part, with a mold.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Loose friable soil, rich in humus and fit for planting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(chiefly plural) Earth, ground.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cover with mold or soil.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mold",
        "https://en.wiktionary.org/wiki/mould",
      ],
    },
    {
      word: "mould",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mould-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75648494",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/məʊld/", audio: "" },
        {
          text: "/moʊld/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mould-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2454154",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A hollow form or matrix for shaping a fluid or plastic substance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A frame or model around or on which something is formed or shaped.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Something that is made in or shaped on a mold.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The shape or pattern of a mold.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "General shape or form.",
              synonyms: [],
              antonyms: [],
              example: "the oval mold of her face",
            },
            {
              definition: "Distinctive character or type.",
              synonyms: [],
              antonyms: [],
              example: "a leader in the mold of her predecessors",
            },
            {
              definition: "A fixed or restrictive pattern or form.",
              synonyms: [],
              antonyms: [],
              example:
                "His method of scientific investigation broke the mold and led to a new discovery.",
            },
            {
              definition: "A group of moldings.",
              synonyms: [],
              antonyms: [],
              example:
                "the arch mold of a porch or doorway;  the pier mold of a Gothic pier, meaning the whole profile, section, or combination of parts",
            },
            { definition: "A fontanelle.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To shape in or on a mold; to form into a particular shape; to give shape to.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To guide or determine the growth or development of; influence",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fit closely by following the contours of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make a mold of or from (molten metal, for example) before casting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To ornament with moldings.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be shaped in or as if in a mold.",
              synonyms: [],
              antonyms: [],
              example: "These shoes gradually molded to my feet.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A natural substance in the form of a woolly or furry growth of tiny fungi that appears when organic material lies for a long time exposed to (usually warm and moist) air.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cause to become moldy; to cause mold to grow upon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To become moldy; to be covered or filled, in whole or in part, with a mold.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Loose friable soil, rich in humus and fit for planting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(chiefly plural) Earth, ground.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cover with mold or soil.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mold",
        "https://en.wiktionary.org/wiki/mould",
      ],
    },
  ],
  crepe: [
    {
      word: "crepe",
      phonetic: "/kɹɛp/",
      phonetics: [
        { text: "/kɹɛp/", audio: "" },
        {
          text: "/kɹeɪp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/crepe-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=794099",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A flat round pancake-like pastry from Lower Brittany, made with wheat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A soft thin light fabric with a crinkled surface.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Crepe paper; thin, crinkled tissue paper.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Rubber in sheets, used especially for shoe soles.",
              synonyms: [],
              antonyms: [],
              example: "The policeman wore crepe-soled shoes.",
            },
            {
              definition:
                "A death notice printed on white card with a background of black crepe paper or cloth, placed on the door of a residence or business.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["crape", "crepe rubber", "French pancake"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To crease (paper) in such a way to make it look like crepe paper",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To frizz (the hair).", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/crepe"],
    },
  ],
  kerns: [
    {
      word: "kerns",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A corn; grain; kernel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The last handful or sheaf reaped at the harvest.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The harvest home.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any part of a letter which extends into the space used by another letter.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(chiefly proportional font printing) To adjust the horizontal space between selected pairs of letters (characters or glyphs); to perform such adjustments to a portion of text, according to preset rules.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A light-armed foot soldier of the ancient militia of Ireland and Scotland; in archaic contexts often used as a term of contempt.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A boor; a low person.", synonyms: [], antonyms: [] },
            { definition: "An idler; a vagabond.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "A churn.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A mill for grinding corn, especially a hand-mill made of two circular stones.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/kern",
        "https://en.wiktionary.org/wiki/kerns",
        "https://en.wiktionary.org/wiki/quern",
      ],
    },
  ],
  aloha: [
    {
      word: "aloha",
      phonetic: "/əˈləʊ.(h)ə/",
      phonetics: [
        { text: "/əˈləʊ.(h)ə/", audio: "" },
        { text: "/əˈloʊ.ə/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Good wishes, love.", synonyms: [], antonyms: [] },
            {
              definition: "An utterance of aloha (see Interjection, below).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition:
                "Expressing good wishes when greeting or parting from someone; hello; goodbye.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/aloha"],
    },
  ],
  glyph: [
    {
      word: "glyph",
      phonetic: "/ɡlɪf/",
      phonetics: [
        {
          text: "/ɡlɪf/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/glyph-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75797070",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ɡlɪf/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/glyph-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2267181",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A figure carved in relief or incised, especially representing a sound, word, or idea.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any non-verbal symbol that imparts information.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A visual representation of a letter, character, or symbol, in a specific font and style.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A vertical groove.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["sort"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/glyph"],
    },
  ],
  azure: [
    {
      word: "azure",
      phonetic: "/æˈzjʊə/",
      phonetics: [
        { text: "/æˈzjʊə/", audio: "" },
        { text: "/æˈzjʊə/", audio: "" },
        {
          text: "/əˈʒʊɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/azure-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762607",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A blue colour on a coat of arms, represented in engraving by horizontal parallel lines.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The clear blue colour of the sky; also, a pigment or dye of this colour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The unclouded sky; the blue vault above.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various widely distributed lycaenid butterflies of the genus Celastrina.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various Australasian lycaenid butterflies of the genus Ogyris.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Lapis lazuli.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To colour blue.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Sky blue; resembling the clear blue colour of the unclouded sky",
              synonyms: ["cerulean"],
              antonyms: [],
            },
            { definition: "Cloudless", synonyms: [], antonyms: [] },
            {
              definition: "In blazon, of the colour blue.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["cerulean"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/azure"],
    },
  ],
  riser: [
    {
      word: "riser",
      phonetic: "/ˈɹaɪ.zə(ɹ)/",
      phonetics: [
        { text: "/ˈɹaɪ.zə(ɹ)/", audio: "" },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/riser-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=424822",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Someone or something which rises.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A platform or stand used to lift or elevate something.",
              synonyms: [],
              antonyms: [],
              example: "The choir stood on risers for the performance.",
            },
            {
              definition: "The vertical part of a step on a staircase.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The main body of a bow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A vertical utility conduit, pipe or path between floors of a building for placement of cables (e.g. telephone, networking), or to convey fluids (e.g. gas, water).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A pipe connecting an individual exhaust port of an internal combustion engine to the muffler, particularly on aircraft.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A Manx cat with a showable short tail.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A strip of webbing joining a parachute's harness to the rigging lines.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/riser"],
    },
  ],
  blest: [
    {
      word: "blest",
      phonetic: "/blɛst/",
      phonetics: [{ text: "/blɛst/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Having divine aid, or protection, or other blessing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A title indicating the beatification of a person, thus allowing public veneration of those who have lived in sanctity or died as martyrs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Held in veneration; revered.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Worthy of worship; holy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An intensifier; damned.",
              synonyms: [],
              antonyms: [],
              example: "Not one blessed person offered to help me out.",
            },
          ],
          synonyms: [
            "hallowed",
            "holy",
            "sacred",
            "revered",
            "venerated",
            "worshiped",
            "worshipped",
          ],
          antonyms: [
            "condemned",
            "cursed",
            "damned",
            "profane",
            "unhallowed",
            "unholy",
            "contemned",
            "despised",
            "scorned",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/blessed",
        "https://en.wiktionary.org/wiki/blest",
      ],
    },
  ],
  locus: [
    {
      word: "locus",
      phonetic: "/ˈləʊkəs/",
      phonetics: [
        {
          text: "/ˈləʊkəs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/locus-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=86957349",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈloʊkəs/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A place or locality, especially a centre of activity or the scene of a crime.",
              synonyms: [],
              antonyms: [],
              example: "The cafeteria was the locus of activity.",
            },
            {
              definition:
                "The set of all points whose coordinates satisfy a given equation or condition.",
              synonyms: [],
              antonyms: [],
              example:
                "A circle is the locus of points from which the distance to the center is a given value, the radius.",
            },
            {
              definition:
                "A fixed position on a chromosome that may be occupied by one or more genes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(chiefly in the plural) A passage in writing, especially in a collection of ancient sacred writings arranged according to a theme.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/locus"],
    },
  ],
  lumpy: [
    {
      word: "lumpy",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Full of lumps, not smooth.",
              synonyms: [],
              antonyms: [],
              example:
                "I don't know which was worse, the lumpy soup or the lumpy bed.",
            },
            {
              definition:
                "Of a water surface: covered in many small waves as a result of wind; choppy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lumpy"],
    },
  ],
  beryl: [
    {
      word: "beryl",
      phonetic: "/ˈbɛɹəl/",
      phonetics: [{ text: "/ˈbɛɹəl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A mineral of pegmatite deposits, often used as a gemstone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An example (a stone) of the mineral beryl.",
              synonyms: [],
              antonyms: [],
              example:
                "The crown was set with six beryls of excellent size and color.",
            },
            {
              definition: "A dull blueish green colour.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of a dull bluish green colour.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/beryl"],
    },
  ],
  wanna: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  brier: [
    {
      word: "brier",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of many plants with thorny stems growing in dense clusters, such as many in the Rosa, Rubus, and Smilax genera.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Anything sharp or unpleasant to the feelings.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The white heath, Erica arborea, a thorny Mediterranean shrub.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A pipe for smoking, made from the roots of that shrub.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/briar",
        "https://en.wiktionary.org/wiki/brier",
      ],
    },
  ],
  tuner: [
    {
      word: "tuner",
      phonetic: "/ˈtjuː.nɚ/",
      phonetics: [
        {
          text: "/ˈtjuː.nɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tuner-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=61287535",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A person who tunes a piano or organ.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A device, electronic or mechanical, that helps a person tune a musical instrument by showing the deviation of the played pitch from the desired pitch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "On a musical instrument, a peg or mechanical device that changes the tension, and hence pitch, of a string.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The component of an audio system that receives radio broadcasts.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One who sings or makes music.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tuner"],
    },
    {
      word: "tuner",
      phonetic: "/ˈtjuː.nɚ/",
      phonetics: [
        {
          text: "/ˈtjuː.nɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tuner-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=61287535",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(entertainment industry) A musical.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tuner"],
    },
  ],
  rowdy: [
    {
      word: "rowdy",
      phonetic: "/ˈɹaʊdi/",
      phonetics: [{ text: "/ˈɹaʊdi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A boisterous person; a brawler.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["brawler", "ruffian"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Loud and disorderly; riotous; boisterous.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["disorderly", "unruly"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rowdy"],
    },
  ],
  mural: [
    {
      word: "mural",
      phonetic: "/ˈmjʊəɹəl/",
      phonetics: [
        { text: "/ˈmjʊəɹəl/", audio: "" },
        {
          text: "/ˈmjɝəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mural-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=402754",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A large painting, usually drawn on a wall.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To create a mural.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Of or relating to a wall; on, or in, or against a wall.",
              synonyms: [],
              antonyms: [],
              example: "a mural quadrant",
            },
            {
              definition: "Resembling a wall; perpendicular or steep.",
              synonyms: [],
              antonyms: [],
              example: "a mural precipice",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mural"],
    },
  ],
  timer: [
    {
      word: "timer",
      phonetic: "/ˈtaɪmɚ/",
      phonetics: [
        {
          text: "/ˈtaɪmɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/timer-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=385413",
          license: {
            name: "BY 2.5",
            url: "https://creativecommons.org/licenses/by/2.5",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Someone or something which times.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A device used to measure amounts of time.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any electronic function that causes a device to be able to do something automatically after a preset amount of time.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/timer"],
    },
  ],
  canst: [
    {
      word: "canst",
      phonetic: "/kænst/",
      phonetics: [
        {
          text: "/kænst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/canst-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2650729",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(auxiliary verb, defective) To know how to; to be able to.",
              synonyms: ["be able to"],
              antonyms: ["can't", "cannot", "can’t"],
              example:
                "She can speak English, French, and German.   I can play football.   Can you remember your fifth birthday?",
            },
            {
              definition:
                "(modal auxiliary verb, defective) May; to be permitted or enabled to.",
              synonyms: ["may"],
              antonyms: [],
              example:
                "You can go outside and play when you're finished with your homework.   Can I use your pen?",
            },
            {
              definition:
                "(modal auxiliary verb, defective) To have the potential to; be possible.",
              synonyms: [],
              antonyms: [],
              example: "Animals can experience emotions.",
            },
            {
              definition:
                "(auxiliary verb, defective) Used with verbs of perception.",
              synonyms: [],
              antonyms: [],
              example: "Can you hear that?.",
            },
            {
              definition: "To know.",
              synonyms: ["cognize", "grok", "ken"],
              antonyms: [],
            },
          ],
          synonyms: ["be able to", "cognize", "grok", "ken", "may"],
          antonyms: ["can't", "cannot", "can’t"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To seal in a can.",
              synonyms: [],
              antonyms: [],
              example: "They canned air to sell as a novelty to tourists.",
            },
            {
              definition: "To preserve by heating and sealing in a jar or can.",
              synonyms: [],
              antonyms: [],
              example: "They spent August canning fruit and vegetables.",
            },
            {
              definition:
                "To discard, scrap or terminate (an idea, project, etc.).",
              synonyms: [],
              antonyms: [],
              example:
                "He canned the whole project because he thought it would fail.",
            },
            {
              definition: "To shut up.",
              synonyms: [],
              antonyms: [],
              example: "Can your gob.",
            },
            {
              definition: "To fire or dismiss an employee.",
              synonyms: [],
              antonyms: [],
              example: "The boss canned him for speaking out.",
            },
            { definition: "To hole the ball.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/can",
        "https://en.wiktionary.org/wiki/canst",
      ],
    },
  ],
  krill: [
    {
      word: "krill",
      phonetic: "/kɹɪl/",
      phonetics: [
        {
          text: "/kɹɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/krill-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88681524",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of several small marine crustacean species of plankton in the order Euphausiacea in the class Malacostraca.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/krill"],
    },
  ],
  quoth: [
    {
      word: "quoth",
      phonetic: "/kwəʊθ/",
      phonetics: [
        { text: "/kwəʊθ/", audio: "" },
        {
          text: "/kwoʊθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/quoth-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2454239",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To say or declare.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(defective, modal, auxiliary) to say",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/quethe",
        "https://en.wiktionary.org/wiki/quoth",
      ],
    },
  ],
  lemme: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  triad: [
    {
      word: "triad",
      phonetic: "/ˈtɹaɪ.æd/",
      phonetics: [{ text: "/ˈtɹaɪ.æd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A grouping of three.",
              synonyms: [
                "threesome",
                "trine",
                "trinity",
                "trio",
                "triplet",
                "triumvirate",
                "troika",
              ],
              antonyms: [],
            },
            {
              definition: "A word of three syllables.",
              synonyms: ["trisyllable"],
              antonyms: [],
            },
            {
              definition:
                "A branch of a Chinese underground criminal society, mostly based in Hong Kong.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "On a CRT display, a group of three neighbouring phosphor dots, coloured green, red, and blue.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A chord consisting of a root tone, the tone two degrees higher, and the tone four degrees higher in a given scale.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "threesome",
            "trine",
            "trinity",
            "trio",
            "triplet",
            "triumvirate",
            "troika",
            "trisyllable",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/triad"],
    },
  ],
  tenon: [
    {
      word: "tenon",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A projecting member left by cutting away the wood around it, and made to insert into a mortise, and in this way secure together the parts of a frame.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make into a tenon.",
              synonyms: [],
              antonyms: [],
              example:
                "First we'll tenon this part, then we'll make a mortise that will fit it on that part.",
            },
            { definition: "To fit with tenons.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tenon"],
    },
  ],
  amply: [
    {
      word: "amply",
      phonetic: "/ˈæmp.li/",
      phonetics: [{ text: "/ˈæmp.li/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            { definition: "In an ample manner.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/amply"],
    },
  ],
  deeps: [
    {
      word: "deeps",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: '(with "the") The deep part of a lake, sea, etc.',
              synonyms: [],
              antonyms: [],
              example: "creatures of the deep",
            },
            {
              definition: '(with "the") A silent time; quiet isolation.',
              synonyms: [],
              antonyms: [],
              example: "the deep of night",
            },
            {
              definition: "A deep shade of colour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The profound part of a problem.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: '(with "the") The sea, the ocean.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A fielding position near the boundary.",
              synonyms: [],
              antonyms: [],
              example: "Russell is a safe pair of hands in the deep.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/deep",
        "https://en.wiktionary.org/wiki/deeps",
      ],
    },
  ],
  padre: [
    {
      word: "padre",
      phonetic: "[ˈpʰɑdɹeɪ̯]",
      phonetics: [{ text: "[ˈpʰɑdɹeɪ̯]", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A military clergyman", synonyms: [], antonyms: [] },
            {
              definition: "A Roman Catholic or Anglican priest",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/padre"],
    },
  ],
  leant: [
    {
      word: "leant",
      phonetic: "/ˈlɛnt/",
      phonetics: [{ text: "/ˈlɛnt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To incline, deviate, or bend, from a vertical position; to be in a position thus inclining or deviating.",
              synonyms: [],
              antonyms: [],
              example: "She leaned out of the window.",
            },
            {
              definition:
                "To incline in opinion or desire; to conform in conduct; often with to, toward, etc.",
              synonyms: [],
              antonyms: [],
              example:
                "I’m leaning towards voting Conservative in the next election.  The Hispanic vote leans Democratic.",
            },
            {
              definition:
                "Followed by against, on, or upon: to rest or rely, for support, comfort, etc.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To hang outwards.", synonyms: [], antonyms: [] },
            { definition: "To press against.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lean",
        "https://en.wiktionary.org/wiki/leant",
      ],
    },
  ],
  pacer: [
    {
      word: "pacer",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pacer-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75648879",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "One who paces.", synonyms: [], antonyms: [] },
            {
              definition:
                "In harness racing, a horse with a gait in which the front and back legs on one side take a step together alternating with the legs on the other side; as opposed to a trotter.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A pacemaker.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pacer"],
    },
    {
      word: "pacer",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pacer-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75648879",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A mechanical pencil.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pacer"],
    },
  ],
  octal: [
    {
      word: "octal",
      phonetic: "/ˈɒk.təl/",
      phonetics: [{ text: "/ˈɒk.təl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The number system that uses the eight digits 0, 1, 2, 3, 4, 5, 6, 7.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A digit or value in the octal number system.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["base", "octonary"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Concerning numbers expressed in octal or mathematical calculations performed using octal.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["base"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/octal"],
    },
  ],
  dolly: [
    {
      word: "dolly",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dolly-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899486",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈdɒli/", audio: "" },
        { text: "/ˈdɑli/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A doll.", synonyms: [], antonyms: [] },
            {
              definition: "A contrivance for stirring:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A tool with an indented head for shaping the head of a rivet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In pile driving, a block interposed between the head of the pile and the ram of the driver.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small truck with a single wide roller used for moving heavy beams, columns, etc., in bridge building.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small truck without means of steering, to be slipped under a load.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A compact, narrow-gauge locomotive used for moving construction trains, switching, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A specialized piece of film equipment resembling a little cart on which a camera is mounted.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A young woman, especially one who is frivolous or vapid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A fashionable young woman, one who follows the latest music or clothing fashions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A ball hit by a batsman such that it goes gently to a fielder for a simple catch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A marker placed on the winning number by the dealer at roulette.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To hit a dolly.", synonyms: [], antonyms: [] },
            {
              definition: "To move (an object) using a dolly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To wash (laundry) in a tub using the stirring device called a dolly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To beat (red-hot metal) with a hammer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To crush ore with a dolly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dolly"],
    },
    {
      word: "dolly",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dolly-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899486",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈdɒli/", audio: "" },
        { text: "/ˈdɑli/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Pretty; attractive.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dolly"],
    },
    {
      word: "dolly",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dolly-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75899486",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈdɒli/", audio: "" },
        { text: "/ˈdɑli/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An offering of fruit or flowers.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dolly"],
    },
  ],
  trans: [
    {
      word: "trans",
      phonetic: "/tɹænz/",
      phonetics: [{ text: "/tɹænz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cross from one side to another of (gender, sex or something in that vein).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "In (or constituting, forming, or describing) a double bond in which the greater radical on both ends is on the opposite side of the bond.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of the side of the Golgi apparatus farther from the endoplasmic reticulum.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/trans"],
    },
    {
      word: "trans",
      phonetic: "/tɹænz/",
      phonetics: [{ text: "/tɹænz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(sometimes offensive, sometimes humorous) A trans person.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Transgender (or sometimes transsexual).",
              synonyms: [],
              antonyms: [],
              example: "trans rights are human rights",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Non-cisgender; an umbrella term encompassing transgender, transsexual, and often also genderqueer/nonbinary, genderfluid, third-gender, etc — all gender identities other than cisgender.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["non-cisgender"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/trans",
        "https://en.wiktionary.org/wiki/trans*",
      ],
    },
    {
      word: "trans",
      phonetic: "/tɹænz/",
      phonetics: [{ text: "/tɹænz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The act of conducting or carrying out (business, negotiations, plans).",
              synonyms: [],
              antonyms: [],
              example: "The transaction was made on Friday with the supplier.",
            },
            {
              definition: "A deal or business agreement.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An exchange or trade, as of ideas, money, goods, etc.",
              synonyms: [],
              antonyms: [],
              example:
                "I made the transaction with the vendor as soon as she showed me the pearls.",
            },
            {
              definition:
                "The transfer of funds into, out of, or from an account.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An atomic operation; a message, data modification, or other procedure that is guaranteed to perform completely or not at all (e.g. a database transaction).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(especially in plural) A record of the proceedings of a learned society.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(in transactional analysis) A social interaction.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The act of transmitting, e.g. data or electric power.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The fact of being transmitted.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something that is transmitted, such as a message, picture or a disease; the sending of such a thing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The passage of a nerve impulse across synapses.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An assembly of gears through which power is transmitted from the engine to the driveshaft in a motor car / automobile; a gearbox.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The right possessed by an heir or legatee of transmitting to his successor(s) any inheritance, legacy, right, or privilege, to which he is entitled, even if he should die without enjoying or exercising it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(medicine, biology) The passing of a communicable disease from an infected host individual or group to a conspecific individual or group.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["gearbox", "outsending"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/trans",
        "https://en.wiktionary.org/wiki/transaction",
        "https://en.wiktionary.org/wiki/transmission",
      ],
    },
    {
      word: "trans",
      phonetic: "/tɹænz/",
      phonetics: [{ text: "/tɹænz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(sometimes offensive, sometimes humorous) A trans person.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The act of transmitting, e.g. data or electric power.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The fact of being transmitted.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something that is transmitted, such as a message, picture or a disease; the sending of such a thing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The passage of a nerve impulse across synapses.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An assembly of gears through which power is transmitted from the engine to the driveshaft in a motor car / automobile; a gearbox.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The right possessed by an heir or legatee of transmitting to his successor(s) any inheritance, legacy, right, or privilege, to which he is entitled, even if he should die without enjoying or exercising it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(medicine, biology) The passing of a communicable disease from an infected host individual or group to a conspecific individual or group.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["gearbox", "outsending"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tran",
        "https://en.wiktionary.org/wiki/trans",
        "https://en.wiktionary.org/wiki/transmission",
      ],
    },
  ],
  sumac: [
    {
      word: "sumac",
      phonetic: "/ˈs(j)uːmæk/",
      phonetics: [
        {
          text: "/ˈs(j)uːmæk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sumac-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88942247",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈsu-/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of various shrubs or small trees of the genus Rhus and other genera in Anacardiaceae, particularly the elm-leaved sumac, Sicilian sumac, or tanner's sumac (Rhus coriaria).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Dried and chopped-up leaves and stems of a plant of the genus Rhus, particularly the tanner's sumac (see sense 1), used for dyeing and tanning leather or for medicinal purposes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sour spice popular in the Eastern Mediterranean, made from the berries of tanner's sumac.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To apply a preparation of sumac to (an object), for example, to a piece of leather to tan it.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sumac"],
    },
  ],
  foamy: [
    {
      word: "foamy",
      phonetic: "/fəʊmi/",
      phonetics: [
        { text: "/fəʊmi/", audio: "" },
        { text: "/foʊmi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An inexpensive surfboard made of extruded polystyrene foam",
              synonyms: [],
              antonyms: [],
              example:
                "A young surfer learning on a foamie Surfing: Skills - Training - Techniques",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Full of foam.",
              synonyms: [],
              antonyms: [],
              example:
                "1715–1720:Tlepolemus, the sun of Hercules, / Led nine swift vessels through the foamy seas — Alexander Pope, The Iliad",
            },
          ],
          synonyms: ["frothy", "spumescent"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/foamie",
        "https://en.wiktionary.org/wiki/foamy",
      ],
    },
  ],
  lolly: [
    {
      word: "lolly",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lolly-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75622476",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A piece of hard candy on a stick; a lollipop.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Money.", synonyms: [], antonyms: [] },
            {
              definition:
                "Any confection made from sugar, or high in sugar content; a sweet, a piece of candy.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A lump.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["bonbon", "candy", "confection", "sweet"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lolly"],
    },
    {
      word: "lolly",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lolly-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75622476",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Snow or fine ice floating on water.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lolly"],
    },
  ],
  giver: [
    {
      word: "giver",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One who gives; a donor or contributor.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/giver"],
    },
  ],
  quipu: [
    {
      word: "quipu",
      phonetic: "/ˈki.pu/",
      phonetics: [{ text: "/ˈki.pu/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A recording device, used by the Incas, consisting of intricate knotted cords.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/quipu"],
    },
  ],
  codex: [
    {
      word: "codex",
      phonetic: "/ˈkəʊdɛks/",
      phonetics: [
        { text: "/ˈkəʊdɛks/", audio: "" },
        {
          text: "/ˈkoʊ.dɛks/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/codex-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90810916",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An early manuscript book.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A book bound in the modern manner, by joining pages, as opposed to a rolled scroll.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An official list of medicines and medicinal ingredients.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/codex"],
    },
  ],
  manna: [
    {
      word: "manna",
      phonetic: "/ˈmænə/",
      phonetics: [
        {
          text: "/ˈmænə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/manna-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1785193",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Food miraculously produced for the Israelites in the desert in the book of Exodus.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) Any boon which comes into one's hands by good luck.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The sugary sap of the manna gum tree which oozes out from holes drilled by insects and falls to the ground around the tree.http//www.museum.vic.gov.au/forest/plants/gum.html",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/manna"],
    },
  ],
  unwed: [
    {
      word: "unwed",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One who is not married; a bachelor or a spinster.",
              synonyms: [],
              antonyms: [],
              example:
                "Should unweds living together receive the same social benefits as married couples?",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To annul the marriage of.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To separate.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Not married.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/unwed"],
    },
  ],
  vodka: [
    {
      word: "vodka",
      phonetic: "/ˈvɑdkə/",
      phonetics: [
        {
          text: "/ˈvɑdkə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/vodka-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1770094",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A clear distilled alcoholic liquor made from grain mash.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A serving of the above beverage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Neutral spirits distilled (or treated after distillation) so as to have no distinctive character, aroma, taste or color.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/vodka"],
    },
  ],
  ferny: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  salon: [
    {
      word: "salon",
      phonetic: "/səˈlɒn/",
      phonetics: [
        {
          text: "/səˈlɒn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/salon-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1785257",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A large room, especially one used to receive and entertain guests.",
              synonyms: ["guest room", "hall", "lounge", "parlor"],
              antonyms: [],
            },
            {
              definition:
                "A gathering of people for a social or intellectual meeting.",
              synonyms: ["cenacle", "circle"],
              antonyms: [],
            },
            {
              definition:
                "An art gallery or exhibition; especially the Paris salon or autumn salon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A beauty salon or similar establishment.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "cenacle",
            "circle",
            "guest room",
            "hall",
            "lounge",
            "parlor",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/salon"],
    },
  ],
  duple: [
    {
      word: "duple",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Double.", synonyms: [], antonyms: [] },
            {
              definition:
                "Having two beats, or a multiple of two beats, in each measure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Having two beats in each foot.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["duplicate", "twosome"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/duple"],
    },
  ],
  boron: [
    {
      word: "boron",
      phonetic: "/ˈbɔːˌɹɒn/",
      phonetics: [
        {
          text: "/ˈbɔːˌɹɒn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/boron-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=17136188",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The chemical element (symbol B) with an atomic number of 5, which is a metalloid found in its pure form as a dark amorphous powder.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A single atom of this element.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/boron"],
    },
  ],
  revue: [
    {
      word: "revue",
      phonetic: "/ɹəˈvjuː/",
      phonetics: [
        {
          text: "/ɹəˈvjuː/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/revue-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=31972263",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
        {
          text: "/ɹəˈvjuː/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/revue-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9825726",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A form of theatrical entertainment in which recent events, popular fads, etc., are parodied. Any entertainment featuring skits, dances, and songs.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/revue"],
    },
  ],
  crier: [
    {
      word: "crier",
      phonetic: "/ˈkraɪə/",
      phonetics: [
        { text: "/ˈkraɪə/", audio: "" },
        { text: "/ˈkraɪɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "One who cries.", synonyms: [], antonyms: [] },
            {
              definition:
                "An officer who proclaims the orders or directions of a court, or who gives public notice by loud proclamation, such as a town crier.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/crier"],
    },
  ],
  alack: [
    {
      word: "alack",
      phonetic: "/əˈlæk/",
      phonetics: [{ text: "/əˈlæk/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "An expression of sorrow or mourning.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["alackaday", "alas"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/alack"],
    },
  ],
  inter: [
    {
      word: "inter",
      phonetic: "/ɪnˈtɜː(ɹ)/",
      phonetics: [
        { text: "/ɪnˈtɜː(ɹ)/", audio: "" },
        { text: "/ɪnˈtɝ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To bury in a grave.",
              synonyms: ["bury", "entomb", "inearth", "inhume"],
              antonyms: [
                "dig up",
                "disentomb",
                "disinter",
                "exhume",
                "unearth",
              ],
            },
            {
              definition: "To confine, as in a prison.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bury", "entomb", "inearth", "inhume"],
          antonyms: ["dig up", "disentomb", "disinter", "exhume", "unearth"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/inter"],
    },
  ],
  dilly: [
    {
      word: "dilly",
      phonetic: "/ˈdɪli/",
      phonetics: [
        {
          text: "/ˈdɪli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dilly-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75270234",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Redolent of dill (the herb).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dilly"],
    },
    {
      word: "dilly",
      phonetic: "/ˈdɪli/",
      phonetics: [
        {
          text: "/ˈdɪli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dilly-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75270234",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Someone or something that is remarkable or unusual.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dilly"],
    },
    {
      word: "dilly",
      phonetic: "/ˈdɪli/",
      phonetics: [
        {
          text: "/ˈdɪli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dilly-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75270234",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A dilly bag.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dilly"],
    },
    {
      word: "dilly",
      phonetic: "/ˈdɪli/",
      phonetics: [
        {
          text: "/ˈdɪli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dilly-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75270234",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A kind of stagecoach.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dilly"],
    },
  ],
  whist: [
    {
      word: "whist",
      phonetic: "/wɪst/",
      phonetics: [{ text: "/wɪst/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of several four-player card games, similar to bridge.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A session of playing this card game.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/whist"],
    },
    {
      word: "whist",
      phonetic: "/wɪst/",
      phonetics: [{ text: "/wɪst/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "(Irish and British) Shush, silence, be quiet!",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sound often used to calm livestock, cattle, sheep etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To hush, shush, or whisht; to still.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To become silent.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Silent, husht.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/whisht",
        "https://en.wiktionary.org/wiki/whist",
      ],
    },
  ],
  cults: [
    {
      word: "cults",
      phonetic: "/kʌlts/",
      phonetics: [{ text: "/kʌlts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A group or sect of people with a deviant religious, philosophical or cultural identity, often existing on the margins of society or exploitative towards its members.",
              synonyms: [],
              antonyms: [],
              example:
                "Oh my gosh, I'm in a repressive cult! How can I leave it?",
            },
            { definition: "Devotion to a saint.", synonyms: [], antonyms: [] },
            {
              definition:
                "The veneration and religious rites given to a deity, esp. in a historical polytheistic context.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A religion that evolved out of another religion but has become a different religion through developing a radically different theology.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A group of people having an obsession with or intense admiration for a particular activity, idea, person or thing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cult",
        "https://en.wiktionary.org/wiki/cults",
      ],
    },
  ],
  spake: [
    {
      word: "spake",
      phonetic: "/speɪk/",
      phonetics: [{ text: "/speɪk/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Quiet; tame.", synonyms: [], antonyms: [] },
            { definition: "Ready; prompt.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spake"],
    },
    {
      word: "spake",
      phonetic: "/speɪk/",
      phonetics: [{ text: "/speɪk/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To communicate with one's voice, to say words out loud.",
              synonyms: [],
              antonyms: [],
              example: "I was so surprised I couldn't speak.",
            },
            {
              definition: "To have a conversation.",
              synonyms: [],
              antonyms: [],
              example: "It's been ages since we've spoken.",
            },
            {
              definition:
                "(by extension) To communicate or converse by some means other than orally, such as writing or facial expressions.",
              synonyms: [],
              antonyms: [],
              example: "Actions speak louder than words.",
            },
            {
              definition:
                "To deliver a message to a group; to deliver a speech.",
              synonyms: [],
              antonyms: [],
              example:
                "This evening I shall speak on the topic of correct English usage.",
            },
            {
              definition: "To be able to communicate in a language.",
              synonyms: [],
              antonyms: [],
              example: "He speaks Mandarin fluently.",
            },
            {
              definition: "To utter.",
              synonyms: [],
              antonyms: [],
              example: "I was so surprised that I couldn't speak a word.",
            },
            {
              definition:
                "To communicate (some fact or feeling); to bespeak, to indicate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(sometimes humorous) To understand (as though it were a language).",
              synonyms: [],
              antonyms: [],
              example: "So you can program in C. But do you speak C++?",
            },
            {
              definition: "To produce a sound; to sound.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of a bird, to be able to vocally reproduce words or phrases from a human language.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To address; to accost; to speak to.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["articulate", "talk", "verbalize"],
          antonyms: ["be silent"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/spake",
        "https://en.wiktionary.org/wiki/speak",
      ],
    },
  ],
  reset: [
    {
      word: "reset",
      phonetic: "/ɹiːˈsɛt/",
      phonetics: [{ text: "/ɹiːˈsɛt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To set back to the initial state.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To set to zero.", synonyms: [], antonyms: [] },
            {
              definition: "To adjust; to set or position differently.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/reset"],
    },
    {
      word: "reset",
      phonetic: "/ˈɹiː.sɛt/",
      phonetics: [{ text: "/ˈɹiː.sɛt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An act of resetting to the initial state",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Setting to zero", synonyms: [], antonyms: [] },
            {
              definition: "Something that is reset",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A device, such as a button or switch, for resetting something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "That which is reset; printed matter set up again.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/reset"],
    },
    {
      word: "reset",
      phonetic: "/rəˈsɛt/",
      phonetics: [{ text: "/rəˈsɛt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The crime of knowingly and dishonestly receiving stolen goods, or harbouring an outlaw.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To receive and hide (stolen goods, or a criminal, etc.)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/reset"],
    },
  ],
  loess: [
    {
      word: "loess",
      phonetic: "/lɜːs/",
      phonetics: [
        {
          text: "/lɜːs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/loess-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90570342",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/lɛs/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any sediment, dominated by silt, of eolian (wind-blown) origin",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/loess"],
    },
  ],
  decor: [
    {
      word: "decor",
      phonetic: "/ˈdeɪkɔː(ɹ)/",
      phonetics: [
        { text: "/ˈdeɪkɔː(ɹ)/", audio: "" },
        {
          text: "/dɪˈkɔɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/decor-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=892470",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The style of decoration of a room or building.",
              synonyms: [],
              antonyms: [],
              example: "Her living room had a lush Persian-style decor.",
            },
            {
              definition: "A stage setting; scenery; set; backdrop.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/decor"],
    },
  ],
  mover: [
    {
      word: "mover",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Someone who or something that moves.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A dancer.", synonyms: [], antonyms: [] },
            {
              definition:
                "A person employed to help people move their possessions from one residence to another.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Someone who proposes a motion at a meeting.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mover"],
    },
  ],
  verve: [
    {
      word: "verve",
      phonetic: "/vɜː(r)v/",
      phonetics: [
        {
          text: "/vɜː(r)v/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/verve-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=443090",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Excitement of imagination such as that which animates a poet, artist, or musician, in composing or performing",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Artistic energy and enthusiasm",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Vigour, vitality and liveliness",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Rapture, enthusiasm", synonyms: [], antonyms: [] },
            { definition: "Spirit, energy", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/verve"],
    },
  ],
  ethic: [
    {
      word: "ethic",
      phonetic: "/ˈɛθɪk/",
      phonetics: [{ text: "/ˈɛθɪk/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A set of principles of right and wrong behaviour guiding, or representative of, a specific culture, society, group, or individual.",
              synonyms: [],
              antonyms: [],
              example: "I think the golden rule is a great ethic.",
            },
            {
              definition: "The morality of an action.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Moral, relating to morals.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ethic"],
    },
  ],
  gamut: [
    {
      word: "gamut",
      phonetic: "/ˈɡæm.ət/",
      phonetics: [{ text: "/ˈɡæm.ət/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A (normally) complete range.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "All the notes in the musical scale.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "All the colours available to a device such as a monitor or printer.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gamut"],
    },
  ],
  lingo: [
    {
      word: "lingo",
      phonetic: "/ˈlɪŋ.ɡəʊ/",
      phonetics: [
        { text: "/ˈlɪŋ.ɡəʊ/", audio: "" },
        {
          text: "/ˈliŋ.ɡoʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lingo-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=592085",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Language, especially language peculiar to a particular group, field, or region; jargon or a dialect.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lingo"],
    },
  ],
  dunno: [
    {
      word: "dunno",
      phonetic: "/dəˈnəʊ/",
      phonetics: [
        {
          text: "/dəˈnəʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dunno-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80065747",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/dəˈnoʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An utterance of the word dunno.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dunno"],
    },
  ],
  align: [
    {
      word: "align",
      phonetic: "/æˈlaɪn/",
      phonetics: [{ text: "/æˈlaɪn/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To form a line; to fall into line.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To adjust or form to a line; to range or form in line; to bring into line.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To store (data) in a way that is consistent with the memory architecture, i.e. by beginning each item at an offset equal to some multiple of the word size.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To identify with or match the behaviour, thoughts, etc of another person.",
              synonyms: ["coordinate", "synchronize"],
              antonyms: [],
            },
            {
              definition:
                "To organize a linear arrangement of DNA, RNA or protein sequences which have regions of similarity.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["coordinate", "synchronize"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/align"],
    },
  ],
  sissy: [
    {
      word: "sissy",
      phonetic: "/ˈsɪsi/",
      phonetics: [
        {
          text: "/ˈsɪsi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sissy-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=508126",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An effeminate boy or man.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A timid, unassertive or cowardly person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(BDSM) A male crossdresser who adopts feminine behaviours.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Sister.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "cot-quean",
            "janegirl",
            "sis",
            "milquetoast",
            "nancy",
            "quiche-eater",
          ],
          antonyms: ["non-sissy", "unsissy"],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Effeminate.", synonyms: [], antonyms: [] },
            { definition: "Cowardly.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sissy"],
    },
    {
      word: "sissy",
      phonetic: "/ˈsɪsi/",
      phonetics: [
        {
          text: "/ˈsɪsi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sissy-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=508126",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Urination; urine.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To urinate.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sissy"],
    },
  ],
  incur: [
    {
      word: "incur",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/incur-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75803579",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ɪnˈkəː/", audio: "" },
        {
          text: "/ɪnˈkɝ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/incur-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=4478589",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To bring upon oneself or expose oneself to, especially something inconvenient, harmful, or onerous; to become liable or subject to",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To enter or pass into", synonyms: [], antonyms: [] },
            {
              definition:
                "To fall within a period or scope; to occur; to run into danger",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["contract", "encounter", "occasion"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/incur"],
    },
  ],
  reedy: [
    {
      word: "reedy",
      phonetic: "/ˈɹiː.di/",
      phonetics: [{ text: "/ˈɹiː.di/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Full of, or edged with, reeds.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of a sound or voice) High and thin in tone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of a person) Tall and thin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Imperfectly welded together in masses of rods.",
              synonyms: [],
              antonyms: [],
              example: "reedy iron",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/reedy"],
    },
  ],
  avant: [
    {
      word: "avant",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The front of an army; the vanguard.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/avant"],
    },
  ],
  piper: [
    {
      word: "piper",
      phonetic: "/ˈpaɪ.pə/",
      phonetics: [
        { text: "/ˈpaɪ.pə/", audio: "" },
        { text: "/ˈpaɪ.pɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A musician who plays a pipe.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A bagpiper.", synonyms: [], antonyms: [] },
            { definition: "A baby pigeon.", synonyms: [], antonyms: [] },
            {
              definition:
                "A common European gurnard (Trigla lyra), having a large head, with prominent nasal projection, and with large, sharp, opercular spines.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sea urchin (Goniocidaris hystrix) with very long spines, native to the American and European coasts.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["chick", "squab", "bagpiper"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/piper"],
    },
    {
      word: "piper",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A plant of the family Piperaceae.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A spice prepared from the fermented, dried, unripe berries of this plant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bell pepper, a fruit of the capsicum plant: red, green, yellow or white, hollow and containing seeds, and in very spicy and mild varieties.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A game used by baseball players to warm up where fielders standing close to a batter rapidly return the batted ball to be hit again",
              synonyms: [],
              antonyms: [],
              example: 'Some ballparks have signs saying "No pepper games".',
            },
            {
              definition:
                "A randomly-generated value that is added to another value (such as a password) prior to hashing. Unlike a salt, a new one is generated for each value and it is held separately from the value.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pepper",
        "https://en.wiktionary.org/wiki/piper",
      ],
    },
  ],
  waxer: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  basil: [
    {
      word: "basil",
      phonetic: "/ˈbæz.əl/",
      phonetics: [
        { text: "/ˈbæz.əl/", audio: "" },
        { text: "/ˈbeɪz.əl/", audio: "" },
        {
          text: "/ˈbæz.əl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/basil-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=501425",
        },
        { text: "/ˈbeɪz.əl/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A plant (Ocimum basilicum).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The leaves of this plant used as a herb.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any other species in the genus Ocimum.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["St. Joseph's wort", "albahaca", "sweet basil"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/basil"],
    },
    {
      word: "basil",
      phonetic: "/ˈbæz.əl/",
      phonetics: [
        { text: "/ˈbæz.əl/", audio: "" },
        { text: "/ˈbeɪz.əl/", audio: "" },
        {
          text: "/ˈbæz.əl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/basil-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=501425",
        },
        { text: "/ˈbeɪz.əl/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The angle to which a joiner's tool is ground away.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To grind the edge of a tool to an acute angle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bevel", "sharpen"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/basil"],
    },
    {
      word: "basil",
      phonetic: "/ˈbæz.əl/",
      phonetics: [
        { text: "/ˈbæz.əl/", audio: "" },
        { text: "/ˈbeɪz.əl/", audio: "" },
        {
          text: "/ˈbæz.əl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/basil-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=501425",
        },
        { text: "/ˈbeɪz.əl/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The skin of a sheep tanned with bark.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["basan", "bazil"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/basil"],
    },
  ],
  coons: [
    {
      word: "coons",
      phonetic: "/kuːnz/",
      phonetics: [{ text: "/kuːnz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(racial slur) A black person.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A raccoon.", synonyms: [], antonyms: [] },
            {
              definition:
                "A member of a colourfully dressed dance troupe in Cape Town during New Year celebrations.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A coonass; a white Acadian French person who lives in the swamps.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A sly fellow.", synonyms: [], antonyms: [] },
            {
              definition:
                'A black person who "plays the coon"; that is, who plays the dated stereotype of a black fool for an audience, particularly including Caucasians.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To hunt raccoons.", synonyms: [], antonyms: [] },
            {
              definition: "To traverse by crawling, as a ledge.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To crawl while straddling, especially in crossing a creek.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Georgia (US)) To fish by noodling, by feeling for large fish in underwater holes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of an African American) To play the dated stereotype of a black fool for an audience, particularly including Caucasians.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To steal.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/coon",
        "https://en.wiktionary.org/wiki/coons",
      ],
    },
  ],
  seine: [
    {
      word: "seine",
      phonetic: "/seɪn/",
      phonetics: [
        { text: "/seɪn/", audio: "" },
        { text: "/seɪn/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A long net having floats attached at the top and sinkers (weights) at the bottom, used in shallow water for catching fish.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To use a seine, to fish with a seine.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/seine"],
    },
  ],
  piney: [
    {
      word: "piney",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of, pertaining to, or having many pines",
              synonyms: [],
              antonyms: [],
              example: "a piny fragrance",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/piney",
        "https://en.wiktionary.org/wiki/piny",
      ],
    },
  ],
  trams: [
    {
      word: "trams",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A passenger vehicle for public use that runs on tracks in the road (called a streetcar or trolley in North America).",
              synonyms: ["streetcar", "trolley"],
              antonyms: [],
            },
            {
              definition: "A similar vehicle for carrying materials.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A people mover.", synonyms: [], antonyms: [] },
            { definition: "An aerial cable car.", synonyms: [], antonyms: [] },
            {
              definition:
                "A train with wheels that runs on a road; a trackless train.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A car on a horse railway or tramway (horse trams preceded electric trams).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The shaft of a cart.", synonyms: [], antonyms: [] },
            {
              definition: "One of the rails of a tramway.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["streetcar", "trolley"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To operate, or conduct the business of, a tramway.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To travel by tram.", synonyms: [], antonyms: [] },
            {
              definition: "To transport (material) by tram.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To align a component in mechanical engineering or metalworking, particularly the head of a drill press.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A silk thread formed of two or more threads twisted together, used especially for the weft, or cross threads, of the best quality of velvets and silk goods.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tram",
        "https://en.wiktionary.org/wiki/trams",
      ],
    },
  ],
  winch: [
    {
      word: "winch",
      phonetic: "/wɪntʃ/",
      phonetics: [
        {
          text: "/wɪntʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/winch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=49896873",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A machine consisting of a drum on an axle, a friction brake or ratchet and pawl, and a crank handle or prime mover (often an electric or hydraulic motor), with or without gearing, to give increased mechanical advantage when hauling on a rope or cable.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hoisting machine used for loading or discharging cargo, or for hauling in lines. (FM 55-501).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A wince (machine used in dyeing or steeping cloth).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A kick, as of an animal, from impatience or uneasiness.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To use a winch",
              synonyms: [],
              antonyms: [],
              example: "Winch in those sails, lad!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/winch"],
    },
    {
      word: "winch",
      phonetic: "/wɪntʃ/",
      phonetics: [
        {
          text: "/wɪntʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/winch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=49896873",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To wince; to shrink", synonyms: [], antonyms: [] },
            {
              definition: "To kick with impatience or uneasiness.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/winch"],
    },
  ],
  whirr: [
    {
      word: "whirr",
      phonetic: "/wɜː/",
      phonetics: [
        { text: "/wɜː/", audio: "" },
        { text: "/wɝ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sibilant buzz or vibration; the sound of something in rapid motion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A bustle of noise and excitement.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "bustle",
            "hustle",
            "buzz",
            "drone",
            "hum",
            "purr",
            "whine",
            "whistle",
            "whizz",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To move or vibrate (something) with a buzzing sound.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make a sibilant buzzing or droning sound.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause (something) to make such a sound.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "buzz",
            "drone",
            "hum",
            "purr",
            "whine",
            "whistle",
            "whizz",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/whirr"],
    },
  ],
  ionic: [
    {
      word: "ionic",
      phonetic: "/aɪˈɒnɪk/",
      phonetics: [{ text: "/aɪˈɒnɪk/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of, relating to, or containing ions",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ionic"],
    },
  ],
  heady: [
    {
      word: "heady",
      phonetic: "/ˈhɛdi/",
      phonetics: [{ text: "/ˈhɛdi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Intoxicating or stupefying.",
              synonyms: [],
              antonyms: [],
              example: "The cocktail was a heady mixture of spirits.",
            },
            {
              definition: "Tending to upset the mind or senses.",
              synonyms: [],
              antonyms: [],
              example: "We looked out from a heady outcrop of rock.",
            },
            {
              definition: "Exhilarating.",
              synonyms: [],
              antonyms: [],
              example:
                "The rock concert was a heady mixture of their greatest hits.",
            },
            {
              definition: "Intellectual.",
              synonyms: [],
              antonyms: [],
              example:
                "Kierkegaard is rather heady reading for a high school student.",
            },
            {
              definition: "Rash or impetuous.",
              synonyms: [],
              antonyms: [],
              example:
                "He made too heady promises, and when it came time, he was never able to fulfill them.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/heady"],
    },
  ],
  harem: [
    {
      word: "harem",
      phonetic: "/ˈhæɹəm/",
      phonetics: [
        { text: "/ˈhæɹəm/", audio: "" },
        { text: "/(ˌ)hɑːˈɹiːm/", audio: "" },
        {
          text: "/ˈhæɹəm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/harem-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1171224",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The private part of an Arab household, traditionally forbidden to male strangers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A group of someone's girlfriends, wives and/or concubines in a polygamous household.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A group of female animals (cows) herded and controlled by a male animal (bull) of that species for breeding purposes. Such behaviour is exhibited by bovids including cattle and buffalo as well as moose, elephants, seals, sea lions, baboons, and elephant seals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any significant number of women together as a group; a bevy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A genre of anime and manga in which a man is the love interest of three or more women.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/harem"],
    },
  ],
  tummy: [
    {
      word: "tummy",
      phonetic: "/ˈtʌm.i/",
      phonetics: [
        {
          text: "/ˈtʌm.i/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tummy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241909",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(often childish) Stomach or belly.",
              synonyms: ["belly"],
              antonyms: [],
            },
            {
              definition: "Protruding belly, paunch.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["belly"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tummy"],
    },
  ],
  sally: [
    {
      word: "sally",
      phonetic: "/ˈsæli/",
      phonetics: [
        {
          text: "/ˈsæli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sally-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241697",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A willow", synonyms: [], antonyms: [] },
            {
              definition: "Any tree that looks like a willow",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An object made from the above trees' wood",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sally"],
    },
    {
      word: "sally",
      phonetic: "/ˈsæli/",
      phonetics: [
        {
          text: "/ˈsæli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sally-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241697",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sortie of troops from a besieged place against an enemy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sudden rushing forth.",
              synonyms: [],
              antonyms: [],
              example:
                "Flocks of these birds stir up flying insects, which can then be picked off in quick sallies.",
            },
            {
              definition:
                "A witty statement or quip, usually at the expense of one's interlocutor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An excursion or side trip.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A tufted woollen part of a bellrope, used to provide grip when ringing a bell.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make a sudden attack (e.g. on an enemy from a defended position).",
              synonyms: [],
              antonyms: [],
              example:
                "A feeding strategy of some birds is to sally out from a perch to snatch an insect and then returning to the same or a different perch.",
            },
            {
              definition:
                'To set out on an excursion; venture; depart (often followed by "forth.")',
              synonyms: [],
              antonyms: [],
              example:
                "As she sallied forth from her boudoir, you would never have guessed how quickly she could strip for action. - William Manchester",
            },
            {
              definition: "To venture off the beaten path.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sally"],
    },
    {
      word: "sally",
      phonetic: "/ˈsæli/",
      phonetics: [
        {
          text: "/ˈsæli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sally-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241697",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A member of the Salvation Army.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["Salvo"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sally"],
    },
    {
      word: "sally",
      phonetic: "/ˈsæli/",
      phonetics: [
        {
          text: "/ˈsæli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sally-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241697",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A kind of stonefly.", synonyms: [], antonyms: [] },
            { definition: "A wren.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sally"],
    },
  ],
  shied: [
    {
      word: "shied",
      phonetic: "/ʃaɪd/",
      phonetics: [{ text: "/ʃaɪd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To avoid due to timidness or caution.",
              synonyms: [],
              antonyms: [],
              example:
                "I shy away from investment opportunities I don't understand.",
            },
            {
              definition: "To jump back in fear.",
              synonyms: [],
              antonyms: [],
              example:
                "The horse shied away from the rider, which startled him so much he shied away from the horse.",
            },
            {
              definition: "To throw sideways with a jerk; to fling",
              synonyms: [],
              antonyms: [],
              example: "shy a slipper",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/shied",
        "https://en.wiktionary.org/wiki/shy",
      ],
    },
  ],
  dross: [
    {
      word: "dross",
      phonetic: "/dɹɑs/",
      phonetics: [
        { text: "/dɹɑs/", audio: "" },
        { text: "/dɹɒs/", audio: "" },
        { text: "/dɹɔs/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Waste or impure matter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Residue that forms on the surface of a metal from oxidation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The impurities in metal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A waste product from working with metal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Worthless or trivial matter.",
              synonyms: ["junk", "rubbish"],
              antonyms: [],
            },
          ],
          synonyms: ["junk", "rubbish"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To remove dross from.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dross"],
    },
  ],
  farad: [
    {
      word: "farad",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "In the International System of Units, the derived unit of electrical capacitance; the capacitance of a capacitor in which one coulomb of charge causes a potential difference of one volt across the capacitor. Symbol: F",
              synonyms: [],
              antonyms: [],
              example: "The input has 5 pF (picofarads) of capacitance.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/farad"],
    },
  ],
  saver: [
    {
      word: "saver",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/saver-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79294666",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One who saves.",
              synonyms: [],
              antonyms: [],
              example: "a saver of souls",
            },
            {
              definition: "One who keeps savings more than usual.",
              synonyms: [],
              antonyms: [],
              example:
                "He's a saver and she's a spender; you think the marriage would be doomed but he keeps them from going into bankruptcy and she makes sure they have a lot of fun.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/saver"],
    },
  ],
  tilde: [
    {
      word: "tilde",
      phonetic: "/ˈtɪldi/",
      phonetics: [
        {
          text: "/ˈtɪldi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tilde-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2358070",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The grapheme of character ~.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The character used to represent negation, usually ~ or ¬.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["squiggle", "twiddle"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tilde"],
    },
  ],
  jingo: [
    {
      word: "jingo",
      phonetic: "/ˈdʒɪŋ.ɡəʊ/",
      phonetics: [
        { text: "/ˈdʒɪŋ.ɡəʊ/", audio: "" },
        { text: "/ˈdʒɪŋ.ɡoʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One who supports policy favouring war.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/jingo"],
    },
  ],
  bower: [
    {
      word: "bower",
      phonetic: "/baʊ.əɹ/",
      phonetics: [{ text: "/baʊ.əɹ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A bedroom or private apartments, especially for a woman in a medieval castle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A dwelling; a picturesque country cottage, especially one that is used as a retreat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A shady, leafy shelter or recess in a garden or woods.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A large structure made of grass and bright objects, used by the bower bird during courtship displays.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["boudoir"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To embower; to enclose.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To lodge.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bower"],
    },
    {
      word: "bower",
      phonetic: "/baʊ.əɹ/",
      phonetics: [{ text: "/baʊ.əɹ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A peasant; a farmer.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bower"],
    },
    {
      word: "bower",
      phonetic: "/baʊ.əɹ/",
      phonetics: [{ text: "/baʊ.əɹ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Either of the two highest trumps in euchre.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bower"],
    },
    {
      word: "bower",
      phonetic: "/baʊ.əɹ/",
      phonetics: [{ text: "/baʊ.əɹ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A type of ship's anchor, carried at the bow.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bower"],
    },
    {
      word: "bower",
      phonetic: "/baʊ.əɹ/",
      phonetics: [{ text: "/baʊ.əɹ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One who bows or bends.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A muscle that bends a limb, especially the arm.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bower"],
    },
    {
      word: "bower",
      phonetic: "/baʊ.əɹ/",
      phonetics: [{ text: "/baʊ.əɹ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One who plays any of several bow instruments, such as the musical bow or diddley bow.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bower"],
    },
    {
      word: "bower",
      phonetic: "/baʊ.əɹ/",
      phonetics: [{ text: "/baʊ.əɹ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A young hawk, when it begins to leave the nest.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bower"],
    },
  ],
  serif: [
    {
      word: "serif",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A short line added to the end of a stroke in traditional typefaces, such as Times New Roman.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of a typeface, provided with serifs.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["sans serif"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/serif"],
    },
  ],
  facto: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  belle: [
    {
      word: "belle",
      phonetic: "/bɛl/",
      phonetics: [{ text: "/bɛl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An attractive woman.",
              synonyms: [],
              antonyms: [],
              example: "In her new dress she felt like the belle of the ball.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/belle"],
    },
  ],
  inset: [
    {
      word: "inset",
      phonetic: "/ˈɪnsɛt/",
      phonetics: [{ text: "/ˈɪnsɛt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A smaller thing set into a larger thing, such as a small picture inside a larger one.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Anything inserted.", synonyms: [], antonyms: [] },
            {
              definition:
                "A small piece of material used to strengthen a garment.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To set in; infix or implant.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To insert something.", synonyms: [], antonyms: [] },
            {
              definition: "To add an inset to something.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/inset"],
    },
  ],
  bogus: [
    {
      word: "bogus",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bogus-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75728986",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈbəʊ.ɡəs/", audio: "" },
        {
          text: "/ˈboʊ.ɡəs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bogus-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=10235636",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A liquor made of rum and molasses.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Counterfeit or fake; not genuine.",
              synonyms: ["phony"],
              antonyms: [],
            },
            {
              definition: "Undesirable or harmful.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Incorrect, useless, or broken.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of a totally fictitious issue printed for collectors, often issued on behalf of a non-existent territory or country (not to be confused with forgery, which is an illegitimate copy of a genuine stamp).",
              synonyms: ["illegal"],
              antonyms: [],
            },
            {
              definition:
                "Based on false or misleading information or unjustified assumptions.",
              synonyms: [],
              antonyms: [],
              example: "bogus laws",
            },
          ],
          synonyms: ["illegal", "phony"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bogus"],
    },
  ],
  caved: [
    {
      word: "caved",
      phonetic: "/keɪvd/",
      phonetics: [{ text: "/keɪvd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To surrender.",
              synonyms: [],
              antonyms: [],
              example: "He caved under pressure.",
            },
            {
              definition: "To collapse.",
              synonyms: [],
              antonyms: [],
              example:
                "First the braces buckled, then the roof began to cave, then we ran.",
            },
            {
              definition: "To hollow out or undermine.",
              synonyms: [],
              antonyms: [],
              example:
                "The levee has been severely caved by the river current.",
            },
            {
              definition: "To engage in the recreational exploration of caves.",
              synonyms: ["spelunk"],
              antonyms: [],
              example: "I have caved from Yugoslavia to Kentucky.",
            },
            {
              definition:
                "In room-and-pillar mining, to extract a deposit of rock by breaking down a pillar which had been holding it in place.",
              synonyms: [],
              antonyms: [],
              example: "The deposit is caved by knocking out the posts.",
            },
            {
              definition:
                "To work over tailings to dress small pieces of marketable ore.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To dwell in a cave.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["spelunk"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cave",
        "https://en.wiktionary.org/wiki/caved",
      ],
    },
  ],
  forte: [
    {
      word: "forte",
      phonetic: "/fɔːt/",
      phonetics: [
        {
          text: "/fɔːt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/forte-1-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90913989",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/fɔɹt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A strength or talent.",
              synonyms: [],
              antonyms: [],
              example: "He writes respectably, but poetry is not his forte.",
            },
            {
              definition:
                "The strong part of a sword blade, close to the hilt.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/forte"],
    },
    {
      word: "forte",
      phonetic: "/ˈfɔː.teɪ/",
      phonetics: [
        {
          text: "/ˈfɔː.teɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/forte-2-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90913989",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈfɔɹ.teɪ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A passage in music to be played loudly; a loud section of music.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                'Loud. Used as a dynamic directive in sheet music in its abbreviated form, "f.", to indicate raising the volume of the music. (Abbreviated in musical notation with an f, the Unicode character 1D191.)',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Loudly.",
              synonyms: [],
              antonyms: [],
              example: "The musicians played the passage forte.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/forte"],
    },
  ],
  sooty: [
    {
      word: "sooty",
      phonetic: "/ˈsʊti/",
      phonetics: [
        {
          text: "/ˈsʊti/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sooty-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88987699",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To blacken or make dirty with soot.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of, relating to, or producing soot.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Soiled with soot", synonyms: [], antonyms: [] },
            { definition: "Of the color of soot.", synonyms: [], antonyms: [] },
            { definition: "Dark-skinned; black.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["black", "dusky", "inky", "sable", "swarthy"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sooty"],
    },
  ],
  bongo: [
    {
      word: "bongo",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A striped bovine mammal found in Africa, Tragelaphus eurycerus.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bongo"],
    },
    {
      word: "bongo",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Either of a pair of small drums of Cuban origin, played by beating with the hands.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To play the bongo drums.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of the heart, etc.: to beat with an irregular rhythm.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To hit something rhythmically with the hands.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bongo"],
    },
  ],
  toves: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  credo: [
    {
      word: "credo",
      phonetic: "/ˈkɹidoʊ/",
      phonetics: [{ text: "/ˈkɹidoʊ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A belief system.", synonyms: [], antonyms: [] },
            {
              definition:
                "The liturgical creed (usually the Nicene Creed), or a musical arrangement of it for use in church services.",
              synonyms: [],
              antonyms: [],
              example: "Credo III is so beautiful!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/credo"],
    },
  ],
  basal: [
    {
      word: "basal",
      phonetic: "[ˈbeɪsəɫ]",
      phonetics: [
        { text: "[ˈbeɪsəɫ]", audio: "" },
        { text: "[ˈbeɪzəɫ]", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Base, bottom, minimum", synonyms: [], antonyms: [] },
            {
              definition: "Any basal structure or part",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Basic, elementary; relating to, or forming, the base, or point of origin.",
              synonyms: [],
              antonyms: [],
              example:
                "A basal reader is a kind of book that is used to teach reading.",
            },
            {
              definition:
                "Associated with the base of an organism or structure.",
              synonyms: [],
              antonyms: [],
              example:
                "In the spring, basal leaves emerge from a stout taproot.",
            },
            {
              definition:
                "Of a minimal level that is necessary for maintaining the health or life of an organism.",
              synonyms: [],
              antonyms: [],
              example: "a basal diet",
            },
            {
              definition:
                "In a phylogenetic tree, being a group, or member of a group, which diverged earlier. The earliest clade to branch in a larger clade.",
              synonyms: [],
              antonyms: [],
              example: "A magnolia is a basal angiosperm.",
            },
          ],
          synonyms: ["basilar"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/basal"],
    },
  ],
  aglow: [
    {
      word: "aglow",
      phonetic: "/əˈɡloʊ/",
      phonetics: [{ text: "/əˈɡloʊ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "(sometimes figurative) glowing; radiant",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/aglow"],
    },
  ],
  glean: [
    {
      word: "glean",
      phonetic: "/ɡliːn/",
      phonetics: [
        {
          text: "/ɡliːn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/glean-1-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89012331",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A collection made by gleaning.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To collect (grain, grapes, etc.) left behind after the main harvest or gathering.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To gather what is left in (a field or vineyard).",
              synonyms: [],
              antonyms: [],
              example: "to glean a field",
            },
            {
              definition:
                "To gather information in small amounts, with implied difficulty, bit by bit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To frugally accumulate resources from low-yield contexts.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["learn", "lease"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/glean"],
    },
    {
      word: "glean",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Cleaning; afterbirth", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/glean"],
    },
  ],
  gusto: [
    {
      word: "gusto",
      phonetic: "/ˈɡʌstəʊ/",
      phonetics: [
        { text: "/ˈɡʌstəʊ/", audio: "" },
        { text: "/ˈɡʌstoʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Enthusiasm; enjoyment, vigor.",
              synonyms: [],
              antonyms: [],
              example: "He sang with more gusto than talent.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gusto"],
    },
  ],
  hymen: [
    {
      word: "hymen",
      phonetic: "/ˈhaɪmən/",
      phonetics: [{ text: "/ˈhaɪmən/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A membrane which completely or partially occludes the vaginal opening in human females.",
              synonyms: ["maidenhead", "virginal membrane"],
              antonyms: [],
            },
            { definition: "Marriage.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["maidenhead", "virginal membrane"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hymen"],
    },
  ],
  ethos: [
    {
      word: "ethos",
      phonetic: "/ˈiːθɒs/",
      phonetics: [
        { text: "/ˈiːθɒs/", audio: "" },
        { text: "/ˈiːθoʊs/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The character or fundamental values of a person, people, culture, or movement.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A form of rhetoric in which the writer or speaker invokes their authority, competence or expertise in an attempt to persuade others that their view is correct.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(aesthetics) The traits in a work of art which express the ideal or typic character, as influenced by the ethos (character or fundamental values) of a people, rather than realistic or emotional situations or individual character in a narrow sense; opposed to pathos.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ethos"],
    },
  ],
  terra: [
    {
      word: "terra",
      phonetic: "/ˈtɛɹə/",
      phonetics: [{ text: "/ˈtɛɹə/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(astrogeology) A rough upland or mountainous region of the Moon with a relatively high albedo.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/terra"],
    },
  ],
  brash: [
    {
      word: "brash",
      phonetic: "/bɹæʃ/",
      phonetics: [
        {
          text: "/bɹæʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/brash-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88681636",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A rash or eruption; a sudden or transient fit of sickness.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sudden burst of rain.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An attack or assault.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To disturb.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(of people or behaviour) Overly bold or self-assertive to the point of being insensitive, tactless or impudent; shameless.",
              synonyms: [
                "audacious",
                "brassy",
                "brazen",
                "cocky",
                "undiplomatic",
              ],
              antonyms: [],
              example:
                "a brash young businessman; a brash tabloid; a brash sense of humour",
            },
            {
              definition: "(of actions) Overly bold, impetuous or rash.",
              synonyms: ["foolhardy", "reckless"],
              antonyms: [],
            },
            {
              definition:
                "(of things) Bold, bright or showy, often in a tasteless way.",
              synonyms: ["flashy", "garish", "loud", "splashy"],
              antonyms: [],
              example: "brash colours; a brash perfume",
            },
          ],
          synonyms: [
            "audacious",
            "brassy",
            "brazen",
            "cocky",
            "undiplomatic",
            "flashy",
            "garish",
            "loud",
            "splashy",
            "foolhardy",
            "reckless",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/brash"],
    },
    {
      word: "brash",
      phonetic: "/bɹæʃ/",
      phonetics: [
        {
          text: "/bɹæʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/brash-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88681636",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Leaf litter of small leaves and little twigs as found under a hedge.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Broken and angular rock fragments underlying alluvial deposits.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Broken fragments of ice.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Brittle (said e.g. of wood or vegetables).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/brash"],
    },
  ],
  scrip: [
    {
      word: "scrip",
      phonetic: "/skɹɪp/",
      phonetics: [{ text: "/skɹɪp/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small medieval bag used to carry food, money, utensils etc.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Small change.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scrip"],
    },
    {
      word: "scrip",
      phonetic: "/skɹɪp/",
      phonetics: [{ text: "/skɹɪp/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A scrap of paper.", synonyms: [], antonyms: [] },
            {
              definition:
                "A document certifying possession of land, or in lieu of money.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A voucher or token coin used in payrolls under the truck system; chit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any substitute for legal tender that is produced by a natural person or private legal person and is often a form of credit.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scrip"],
    },
    {
      word: "scrip",
      phonetic: "/skɹɪp/",
      phonetics: [{ text: "/skɹɪp/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A share certificate.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scrip"],
    },
    {
      word: "scrip",
      phonetic: "/skɹɪp/",
      phonetics: [{ text: "/skɹɪp/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A medical prescription.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scrip"],
    },
  ],
  swash: [
    {
      word: "swash",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The water that washes up on shore after an incoming wave has broken",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A long, protruding ornamental line or pen stroke found in some typefaces and styles of calligraphy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A narrow sound or channel of water lying within a sand bank, or between a sand bank and the shore, or a bar over which the sea washes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Liquid filth; wash; hog mash.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A blustering noise.", synonyms: [], antonyms: [] },
            { definition: "Swaggering behaviour.", synonyms: [], antonyms: [] },
            {
              definition: "A swaggering fellow; a swasher.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An oval figure, whose mouldings are oblique to the axis of the work.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To swagger; to bluster and brag.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To dash or flow noisily; to splash.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fall violently or noisily.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Soft, like overripe fruit; swashy; squashy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/swash"],
    },
  ],
  aleph: [
    {
      word: "aleph",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The first letter of the Proto-Canaanite alphabet, and its descendants in descended Semitic scripts, such as Phoenician 𐤀, Aramaic 𐡀, Syriac ܐ, Hebrew א and Arabic ا.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/aleph"],
    },
  ],
  tinny: [
    {
      word: "tinny",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of or pertaining to or resembling tin.",
              synonyms: [],
              antonyms: [],
              example: "The object had a tinny appearance.",
            },
            {
              definition:
                "Pertaining to the thinness and cheapness of tin or similar-looking metals, in contrast to a heavier, more valuable metal.",
              synonyms: [],
              antonyms: [],
              example: "The shop was filled with tinny tourist geegaws.",
            },
            {
              definition:
                "Pertaining to a thin, unpleasant sound recalling that of tin being rapped.",
              synonyms: [],
              antonyms: [],
              example:
                "The piano was not simply out of tune, but had become downright tinny.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tinny"],
    },
    {
      word: "tinny",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A can of beer.", synonyms: [], antonyms: [] },
            {
              definition: "A small open aluminium boat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Small package of drugs wrapped in foil.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tinnie",
        "https://en.wiktionary.org/wiki/tinny",
      ],
    },
  ],
  itchy: [
    {
      word: "itchy",
      phonetic: "/ˈɪtʃi/",
      phonetics: [{ text: "/ˈɪtʃi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Having or creating an itch, causing a person or animal to tend to want to scratch.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/itchy"],
    },
  ],
  wanta: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  trice: [
    {
      word: "trice",
      phonetic: "/tɹaɪs/",
      phonetics: [
        {
          text: "/tɹaɪs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/trice-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88671777",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To pull, to pull out or away, to pull sharply.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To drag or haul, especially with a rope; specifically to haul or hoist and tie up by means of a rope.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/trice"],
    },
    {
      word: "trice",
      phonetic: "/tɹaɪs/",
      phonetics: [
        {
          text: "/tɹaɪs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/trice-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88671777",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Now only in the phrase in a trice: a very short time; an instant, a moment.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/trice"],
    },
    {
      word: "trice",
      phonetic: "/tɹaɪs/",
      phonetics: [
        {
          text: "/tɹaɪs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/trice-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88671777",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A pulley, a windlass.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/trice"],
    },
  ],
  jowls: [
    {
      word: "jowls",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The jaw, jawbone; especially one of the lateral parts of the mandible.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A fold of fatty flesh under the chin, around the cheeks, or lower jaw (as a dewlap, wattle, crop, or double chin).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The cheek; especially the cheek meat of a hog.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Cut of fish including the head and adjacent parts",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/jowl",
        "https://en.wiktionary.org/wiki/jowls",
      ],
    },
  ],
  gongs: [
    {
      word: "gongs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A percussion instrument consisting of a metal disk that emits a sonorous sound when struck with a soft hammer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A medal or award, particularly Knight Bachelor.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make the sound of a gong; to ring a gong.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To send a signal to, using a gong or similar device.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To give an award or medal to.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An outhouse: an outbuilding used as a lavatory.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The contents of an outhouse pit: shit.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["gonghouse"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gong",
        "https://en.wiktionary.org/wiki/gongs",
      ],
    },
  ],
  garde: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  boric: [
    {
      word: "boric",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of, pertaining to, or containing the element boron.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/boric"],
    },
  ],
  twill: [
    {
      word: "twill",
      phonetic: "/twɪl/",
      phonetics: [{ text: "/twɪl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A pattern, characterised by diagonal ridges, created by the regular interlacing of threads of the warp and weft during weaving.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cloth or portion of cloth woven in such a pattern.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To weave (cloth, etc.) so as to produce the appearance of diagonal lines or ribs on the surface.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/twill"],
    },
  ],
  sower: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  henry: [
    {
      word: "henry",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/henry-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1239789",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "In the International System of Units, the derived unit of electrical inductance; the inductance induced in a circuit by a rate of change of current of one ampere per second and a resulting electromotive force of one volt. Symbol: H",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/henry"],
    },
    {
      word: "henry",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/henry-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1239789",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "A turd.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/henry"],
    },
    {
      word: "henry",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/henry-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1239789",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A quantity of marijuana weighing one-eighth of an ounce.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["eighth"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/henry"],
    },
  ],
  awash: [
    {
      word: "awash",
      phonetic: "/əˈwɒʃ/",
      phonetics: [{ text: "/əˈwɒʃ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Washed by the waves or tide (of a rock or strip of shore, or of an anchor, etc., when flush with the surface of the water, so that the waves break over it); covered with water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) Covered, overspread (with or in something).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/awash"],
    },
  ],
  libel: [
    {
      word: "libel",
      phonetic: "/ˈlaɪbəl/",
      phonetics: [
        {
          text: "/ˈlaɪbəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/libel-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=7467902",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A written or pictorial false statement which unjustly seeks to damage someone's reputation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The act or crime of displaying such a statement publicly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any defamatory writing; a lampoon; a satire.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A written declaration or statement by the plaintiff of their cause of action, and of the relief they seek.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A brief writing of any kind, especially a declaration, bill, certificate, request, supplication, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To defame someone, especially in a manner that meets the legal definition of libel.",
              synonyms: [],
              antonyms: [],
              example: "He libelled her when he published that.",
            },
            {
              definition:
                "To proceed against (a ship, goods, etc.) by filing a libel.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/libel"],
    },
  ],
  spurn: [
    {
      word: "spurn",
      phonetic: "/spɜːn/",
      phonetics: [
        { text: "/spɜːn/", audio: "" },
        {
          text: "/spɚn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spurn-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=11067823",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An act of spurning; a scornful rejection.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A kick; a blow with the foot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Disdainful rejection; contemptuous treatment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A body of coal left to sustain an overhanging mass.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To reject disdainfully; contemn; scorn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To reject something by pushing it away with the foot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To waste; fail to make the most of (an opportunity)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To kick or toss up the heels.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spurn"],
    },
  ],
  sabre: [
    {
      word: "sabre",
      phonetic: "/ˈseɪ.bɚ/",
      phonetics: [
        { text: "/ˈseɪ.bɚ/", audio: "" },
        { text: "/ˈseɪ.bə(ɹ)/", audio: "" },
        { text: "/ˈseɪ.bɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A light sword, sharp along the front edge, part of the back edge, and at the point.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A modern fencing sword modeled after the sabre.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To strike or kill with a sabre.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sabre"],
    },
  ],
  rebut: [
    {
      word: "rebut",
      phonetic: "/ɹɪ.ˈbʌt/",
      phonetics: [
        {
          text: "/ɹɪ.ˈbʌt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rebut-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90007895",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To drive back or beat back; to repulse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To deny the truth of something, especially by presenting arguments that disprove it.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rebut"],
    },
  ],
  penal: [
    {
      word: "penal",
      phonetic: "/ˈpiːnəl/",
      phonetics: [{ text: "/ˈpiːnəl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of or relating to punishment.",
              synonyms: [],
              antonyms: [],
              example: "penal servitude",
            },
            {
              definition: "Subject to punishment; punishable.",
              synonyms: [],
              antonyms: [],
              example: "a penal offence",
            },
            {
              definition: "Serving as a place of punishment.",
              synonyms: [],
              antonyms: [],
              example: "a penal colony",
            },
            {
              definition: "Exorbitant.",
              synonyms: [],
              antonyms: [],
              example: "a penal rate of interest.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/penal"],
    },
  ],
  obese: [
    {
      word: "obese",
      phonetic: "/əʊˈbiːs/",
      phonetics: [
        { text: "/əʊˈbiːs/", audio: "" },
        { text: "/oʊˈbis/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Extremely overweight, especially: weighing more than 20% (for men) or 25% (for women) over their ideal weight determined by height and build; or, having a body mass index over 30 kg/m2.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/obese"],
    },
  ],
  sonny: [
    {
      word: "sonny",
      phonetic: "/ˈsʌni/",
      phonetics: [
        {
          text: "/ˈsʌni/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sonny-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1229892",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A familiar form of address for a boy",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Form of address to a boy or man, to express contempt, warning etc.",
              synonyms: ["buddy", "buster", "friend", "pal"],
              antonyms: [],
            },
          ],
          synonyms: ["buddy", "buster", "friend", "pal"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sonny"],
    },
  ],
  quirt: [
    {
      word: "quirt",
      phonetic: "/kwɜːt/",
      phonetics: [
        { text: "/kwɜːt/", audio: "" },
        { text: "/kwɚt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A rawhide whip plaited with two thongs of buffalo hide.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To strike with a quirt.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/quirt"],
    },
  ],
  tacit: [
    {
      word: "tacit",
      phonetic: "/ˈtæsɪt/",
      phonetics: [
        {
          text: "/ˈtæsɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tacit-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1664854",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Expressed in silence; implied, but not made explicit; silent.",
              synonyms: [],
              antonyms: [],
              example:
                "tacit consent : consent by silence, or by not raising an objection",
            },
            {
              definition:
                "Not derived from formal principles of reasoning; based on induction rather than deduction.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tacit"],
    },
  ],
  greek: [
    {
      word: "greek",
      phonetic: "/ɡɹiːk/",
      phonetics: [{ text: "/ɡɹiːk/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An inhabitant, resident, or person of descent from Greece.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Unintelligible speech or text, such as foreign speech or text, or regarding subjects the listener is not familiar with, such as mathematics or technical jargon; or statements that the listener does not understand or agree with.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A member of a college fraternity or sorority, which are characterised by being named after Greek letters. (See also Greek system.)",
              synonyms: [],
              antonyms: [],
              example: "Was Joe a Greek in college?",
            },
            {
              definition: "A cunning rogue; a merry fellow.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Anal sex.", synonyms: [], antonyms: [] },
            {
              definition: "Greek cuisine; traditional Greek food.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(chiefly in the plural) One of the Greeks, measures of derivative price sensitivity.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To display a placeholder (instead of text), especially to optimize speed in displaying text that would be too small to read.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To fill a template with nonsense text (particularly the Lorem ipsum), so that form can be focused on instead of content.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/Greek",
        "https://en.wiktionary.org/wiki/greek",
      ],
    },
  ],
  xenon: [
    {
      word: "xenon",
      phonetic: "/zenɔn/",
      phonetics: [
        { text: "/zenɔn/", audio: "" },
        { text: "/ˈziːnɒn/", audio: "" },
        {
          text: "/zinən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/xenon-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1763086",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The chemical element (symbol Xe) with an atomic number of 54. It is a colorless, odorless, unreactive noble gas, used notably in camera flash technology.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/xenon"],
    },
  ],
  hullo: [
    {
      word: "hullo",
      phonetic: "/həˈləʊ/",
      phonetics: [{ text: "/həˈləʊ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: '"Hello!" or an equivalent greeting.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["greeting"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: 'To greet with "hello".',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition:
                "A greeting (salutation) said when meeting someone or acknowledging someone’s arrival or presence.",
              synonyms: [],
              antonyms: [],
              example: "Hello, everyone.",
            },
            {
              definition: "A greeting used when answering the telephone.",
              synonyms: [],
              antonyms: [],
              example: "Hello? How may I help you?",
            },
            {
              definition:
                "A call for response if it is not clear if anyone is present or listening, or if a telephone conversation may have been disconnected.",
              synonyms: [],
              antonyms: [],
              example: "Hello? Is anyone there?",
            },
            {
              definition:
                "Used sarcastically to imply that the person addressed or referred to has done something the speaker or writer considers to be foolish.",
              synonyms: [],
              antonyms: [],
              example:
                "You just tried to start your car with your cell phone. Hello?",
            },
            {
              definition: "An expression of puzzlement or discovery.",
              synonyms: [],
              antonyms: [],
              example: "Hello! What’s going on here?",
            },
          ],
          synonyms: [],
          antonyms: ["bye", "goodbye"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hello",
        "https://en.wiktionary.org/wiki/hullo",
      ],
    },
  ],
  pique: [
    {
      word: "pique",
      phonetic: "/piːk/",
      phonetics: [
        {
          text: "/piːk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pique-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1213490",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A feeling of enmity; ill-feeling, animosity; a transient feeling of wounded pride.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A feeling of irritation or resentment, awakened by a social slight or injury; offence, especially taken in an emotional sense with little thought or consideration.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Keenly felt desire; a longing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To wound the pride of; to excite to anger.",
              synonyms: ["fret", "irritate", "nettle", "sting"],
              antonyms: [],
            },
            {
              definition: "To take pride in; to pride oneself on.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To stimulate (a feeling, emotion); to offend by slighting; to excite (someone) to action by causing resentment or jealousy.",
              synonyms: ["excite", "stimulate"],
              antonyms: [],
              example: "I believe this will pique your interest.",
            },
          ],
          synonyms: [
            "excite",
            "stimulate",
            "fret",
            "irritate",
            "nettle",
            "sting",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pique"],
    },
    {
      word: "pique",
      phonetic: "/piːk/",
      phonetics: [
        {
          text: "/piːk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pique-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1213490",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "In piquet, the right of the elder hand to count thirty in hand, or to play before the adversary counts one.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To score a pique against.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pique"],
    },
    {
      word: "pique",
      phonetic: "/piːk/",
      phonetics: [
        {
          text: "/piːk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pique-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1213490",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A chigger or jigger, Tunga penetrans.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pique"],
    },
    {
      word: "pique",
      phonetic: "/piːk/",
      phonetics: [
        {
          text: "/piːk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pique-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1213490",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A durable ribbed fabric made from cotton, rayon, or silk.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pique"],
    },
  ],
  roger: [
    {
      word: "roger",
      phonetic: "/ˈɹɒdʒə/",
      phonetics: [
        { text: "/ˈɹɒdʒə/", audio: "" },
        {
          text: "/ˈɹɑdʒɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/roger-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2101124",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition:
                "(radio telecommunications) Received (used in radio communications to acknowledge that a message has been received and understood)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["roger that"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/roger"],
    },
    {
      word: "roger",
      phonetic: "/ˈɹɒdʒə/",
      phonetics: [
        { text: "/ˈɹɒdʒə/", audio: "" },
        {
          text: "/ˈɹɑdʒɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/roger-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2101124",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "Of a man, to have sexual intercourse with (someone), especially in a rough manner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To have sexual intercourse.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bang", "do it", "get some", "bone", "dick", "knob"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/roger"],
    },
  ],
  negro: [
    {
      word: "negro",
      phonetic: "/ˈniːɡɹəʊ/",
      phonetics: [
        { text: "/ˈniːɡɹəʊ/", audio: "" },
        { text: "/ˈniɡɹoʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A black person; a person of sub-Saharan African descent.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Relating to the black ethnicity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Black or dark brown in color.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/Negro",
        "https://en.wiktionary.org/wiki/negro",
      ],
    },
  ],
  gecko: [
    {
      word: "gecko",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gecko-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75797018",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈɡɛ.kəʊ/", audio: "" },
        { text: "/ˈɡɛ.koʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any lizard of the family Gekkonidae. They are small, carnivorous, mostly nocturnal animals with large eyes and adhesive toes enabling them to climb on vertical and upside-down surfaces.",
              synonyms: ["geckotian", "geckotid", "gekkonid"],
              antonyms: [],
            },
          ],
          synonyms: ["geckotian", "geckotid", "gekkonid"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To move in the manner of a gecko; to attach to a vertical or upside-down surface.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gecko"],
    },
  ],
  beget: [
    {
      word: "beget",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/beget-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75729868",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/biˈɡɛt/", audio: "" },
        { text: "/biˈɡɛt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To father; to sire; to produce (a child).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To cause; to produce.", synonyms: [], antonyms: [] },
            { definition: "To bring forth.", synonyms: [], antonyms: [] },
            { definition: "To happen to; befall.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/beget"],
    },
  ],
  uncut: [
    {
      word: "uncut",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Not cut.", synonyms: [], antonyms: [] },
            {
              definition:
                "(of a gemstone) Not cut or ground to the desired shape.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of a book) Not having the page edges trimmed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a play, film etc) Not edited or abridged by a censor.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Not circumcised.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/uncut"],
    },
  ],
  aloes: [
    {
      word: "aloes",
      phonetic: "/ˈæ.ləʊz/",
      phonetics: [
        { text: "/ˈæ.ləʊz/", audio: "" },
        { text: "/ˈæ.loʊz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(in the plural) The resins of the tree Aquilaria malaccensis (syn. Aquilaria agallocha), known for their fragrant aroma, produced after infection by the fungus Phialophora parasitica.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A plant of the genus Aloe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A strong, bitter drink made from the juice of such plants, used as a purgative.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The resin of the trees or Aquilaria malaccensis (syn. Aquilaria agallocha), known for their fragrant odour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The wood of the agalloch.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/aloe",
        "https://en.wiktionary.org/wiki/aloes",
      ],
    },
  ],
  louis: [
    {
      word: "louis",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/louis-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79268617",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any gold or silver coin issued by the French kings from Louis XIII to Louis XVI and bearing their image on the obverse side, particularly the gold louis d'ors, originally a French form of the Spanish doubloon but varying in value between 10 and 24 livres.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The louis d'or constitutionnel, a 24-livre gold coin issued by the First French Republic.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The franc germinal or napoleon, a similar gold coin issued by Napoleon and bearing his image on the obverse, worth 20 francs.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["Louis", "lew"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/louis"],
    },
  ],
  quint: [
    {
      word: "quint",
      phonetic: "/kwɛ̃/",
      phonetics: [{ text: "/kwɛ̃/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An interval of one fifth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The E string of a violin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In piquet, a sequence of five playing cards of the same suit; equivalent to a straight flush in poker",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A quin or quintuplet.", synonyms: [], antonyms: [] },
            {
              definition:
                "A vehicle used by firefighters that combines the capabilities of a fire engine and a fire truck, having the ability to provide vertical access as well as pump water to fight a fire.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Quinte; the fifth fencer in parrying or attacking position.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/quint"],
    },
  ],
  clunk: [
    {
      word: "clunk",
      phonetic: "/klʌŋk/",
      phonetics: [
        {
          text: "/klʌŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/clunk-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=86872811",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A dull, metallic sound, especially one made by two bodies coming into contact.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The sound of liquid coming out of a bottle, etc.; a glucking sound.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To make such a sound", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/clunk"],
    },
  ],
  raped: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  salvo: [
    {
      word: "salvo",
      phonetic: "/ˈsælvəʊ/",
      phonetics: [
        { text: "/ˈsælvəʊ/", audio: "" },
        {
          text: "/ˈsælvoʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/salvo-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=338176",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An exception; a reservation; an excuse.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/salvo"],
    },
    {
      word: "salvo",
      phonetic: "/ˈsælvəʊ/",
      phonetics: [
        { text: "/ˈsælvəʊ/", audio: "" },
        {
          text: "/ˈsælvoʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/salvo-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=338176",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A concentrated fire from pieces of artillery, as in endeavoring to make a break in a fortification; a volley.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A salute paid by a simultaneous, or nearly simultaneous, firing of a number of cannon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) Any volley, as in an argument or debate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The combined cheers of a crowd.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To discharge weapons in a salvo.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/salvo"],
    },
  ],
  diode: [
    {
      word: "diode",
      phonetic: "/ˈdaɪəʊd/",
      phonetics: [
        { text: "/ˈdaɪəʊd/", audio: "" },
        {
          text: "/ˈdaɪoʊd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/diode-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50010910",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An electronic device that allows current to flow in one direction only; used chiefly as a rectifier.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/diode"],
    },
  ],
  matey: [
    {
      word: "matey",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/matey-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241791",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The conclusive victory in a game of chess that occurs when an opponent's king is threatened with unavoidable capture.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) Any losing situation with no escape; utter defeat.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A fellow, comrade, colleague, partner or someone with whom something is shared, e.g. shipmate, classmate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(especially of a non-human animal) A breeding partner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A friend, usually of the same sex.",
              synonyms: [],
              antonyms: [],
              example: "He's my best mate.",
            },
            {
              definition:
                "Friendly term of address to a stranger, usually male, of similar age",
              synonyms: [],
              antonyms: [],
              example: "Excuse me, mate, have you got the time?",
            },
            {
              definition:
                "In naval ranks, a non-commissioned officer or his subordinate (e.g. Boatswain's Mate, Gunner's Mate, Sailmaker's Mate, etc).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A ship's officer, subordinate to the master on a commercial ship.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A first mate.", synonyms: [], antonyms: [] },
            {
              definition:
                "A technical assistant in certain trades (e.g. gasfitter's mate, plumber's mate); sometimes an apprentice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The other member of a matched pair of objects.",
              synonyms: [],
              antonyms: [],
              example:
                "I found one of the socks I wanted to wear, but I couldn't find its mate.",
            },
            {
              definition: "A suitable companion; a match; an equal.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["buddy", "fellow", "fere", "friend", "partner", "sir"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The abovementioned plant; the leaves and shoots used for the tea",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A fellow sailor; often used affectedly, especially as a pirate.",
              synonyms: [],
              antonyms: [],
              example: "Ahoy mateys, scrub the deck!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Sociable or friendly.",
              synonyms: [],
              antonyms: [],
              example: "You've been very matey with that new bird.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An evergreen tree, Ilex paraquariensis, native to South America, cultivated for its leaves.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A beverage, resembling tea, made from the dried leaves of this plant.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A cup of this drink.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["maté tea", "yerba mate", "yerba maté"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/checkmate",
        "https://en.wiktionary.org/wiki/mat%C3%A9",
        "https://en.wiktionary.org/wiki/mate",
        "https://en.wiktionary.org/wiki/matey",
      ],
    },
  ],
  hertz: [
    {
      word: "hertz",
      phonetic: "/hɜːs/",
      phonetics: [
        { text: "/hɜːs/", audio: "" },
        {
          text: "/hɝts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hertz-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1217782",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "In the International System of Units, the derived unit of frequency; one (period or cycle of any periodic event) per second. Symbol: Hz",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["cycle", "per", "second"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hertz"],
    },
  ],
  xylem: [
    {
      word: "xylem",
      phonetic: "/ˈzaɪ.ləm/",
      phonetics: [{ text: "/ˈzaɪ.ləm/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A vascular tissue in land plants primarily responsible for the distribution of water and minerals taken up by the roots; also the primary component of wood.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/xylem"],
    },
  ],
  kiosk: [
    {
      word: "kiosk",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/kiosk-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75804163",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈkiːˌɒsk/", audio: "" },
        {
          text: "/ˈkiˌɑsk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/kiosk-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=6861331",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small enclosed structure, often freestanding, open on one side or with a window, used as a booth to sell newspapers, cigarettes, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A similar unattended stand for the automatic dispensing of tickets, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A public telephone booth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A Turkish garden pavilion.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/kiosk"],
    },
  ],
  apace: [
    {
      word: "apace",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/apace-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75729517",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/əˈpeɪs/", audio: "" },
        { text: "/əˈpeɪs/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Quickly, rapidly, with speed.",
              synonyms: [],
              antonyms: [],
              example: "Construction of the new offices is proceeding apace.",
            },
          ],
          synonyms: ["expeditiously", "swiftly"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/apace"],
    },
  ],
  cawed: [
    {
      word: "cawed",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make the harsh cry of a crow, rook, or raven.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/caw",
        "https://en.wiktionary.org/wiki/cawed",
      ],
    },
  ],
  peter: [
    {
      word: "peter",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/peter-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79268709",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈpiːtə/", audio: "" },
        { text: "/ˈpitɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The penis.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/peter"],
    },
    {
      word: "peter",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/peter-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79268709",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈpiːtə/", audio: "" },
        { text: "/ˈpitɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "A safe.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/peter"],
    },
    {
      word: "peter",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/peter-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79268709",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈpiːtə/", audio: "" },
        { text: "/ˈpitɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(most often used in the phrase peter out) To dwindle; to trail off; to diminish to nothing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/peter"],
    },
    {
      word: "peter",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/peter-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79268709",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈpiːtə/", audio: "" },
        { text: "/ˈpitɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "In whist, to play a blue peter.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/blue%20peter",
        "https://en.wiktionary.org/wiki/peter",
      ],
    },
  ],
  wench: [
    {
      word: "wench",
      phonetic: "/wɛntʃ/",
      phonetics: [
        {
          text: "/wɛntʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wench-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241733",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(possibly offensive) A girl or young woman, especially a buxom or lively one.",
              synonyms: [],
              antonyms: [],
              example:
                "Jane played the role of a wench in an Elizabethan comedy.",
            },
            {
              definition:
                "Used as a term of endearment for a female person, especially a wife, daughter, or girlfriend: darling, sweetheart.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A woman servant; a maidservant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A promiscuous woman; a mistress.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A prostitute.", synonyms: [], antonyms: [] },
            {
              definition:
                "A black woman (of any age), especially if in a condition of servitude.",
              synonyms: ["negress"],
              antonyms: [],
            },
          ],
          synonyms: ["negress"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To frequent prostitutes; to whore; also, to womanize.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/wench"],
    },
  ],
  cohos: [
    {
      word: "cohos",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An anadromus and semelparous salmon, Oncorhynchus kisutch, found in the coastal regions of the northern Pacific Ocean, used as a symbol by several Native American tribes.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["blue jack", "coho salmon", "cohoe", "silver salmon"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/coho",
        "https://en.wiktionary.org/wiki/cohos",
      ],
    },
  ],
  sorta: [
    {
      word: "sorta",
      phonetic: "/ˈsɔːtə/",
      phonetics: [
        { text: "/ˈsɔːtə/", audio: "" },
        { text: "/ˈsɔɹtə/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition:
                "Approximately; in a way; partially; not quite; somewhat.",
              synonyms: [],
              antonyms: [],
              example:
                "It sort of makes sense the way he explains it, but I still don't really understand.",
            },
          ],
          synonyms: ["kind of"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sort%20of",
        "https://en.wiktionary.org/wiki/sorta",
      ],
    },
  ],
  gamba: [
    {
      word: "gamba",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A rank of organ pipes, so called for a supposed resemblance of the sound to that of a viola da gamba.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A stringed instrument related to the violin family, but held in the lap between the legs like a cello, usually with C-holes, a flat back, a fretted neck and six strings, played with an underhanded bow hold.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["gamba", "viola d'amore", "viola da gamba"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gamba",
        "https://en.wiktionary.org/wiki/viol",
        "https://en.wiktionary.org/wiki/viola%20da%20gamba",
      ],
    },
    {
      word: "gamba",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The metacarpus or metatarsus of ruminants, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gamba"],
    },
  ],
  bytes: [
    {
      word: "bytes",
      phonetic: "/baɪts/",
      phonetics: [{ text: "/baɪts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A short sequence of bits (binary digits) that can be operated on as a unit by a computer; the smallest usable machine word.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(most commonly) A unit of computing storage equal to eight bits, which can represent any of 256 distinct values.",
              synonyms: ["B", "octet"],
              antonyms: [],
              example: "The word “hello” fits into five bytes of ASCII code.",
            },
          ],
          synonyms: ["B", "octet"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/byte",
        "https://en.wiktionary.org/wiki/bytes",
      ],
    },
  ],
  tango: [
    {
      word: "tango",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tango-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241891",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈtæŋɡəʊ/", audio: "" },
        { text: "/ˈtæŋɡoʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A Standard ballroom dance in 4/4 time; or a social dance, the Argentine tango.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A piece of music suited to such a dance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The letter T in the ICAO spelling alphabet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Enemy, used amongst special police forces, derived from the abbreviation of target using the NATO phonetic alphabet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A dark orange colour shade; deep tangerine",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To dance the tango.", synonyms: [], antonyms: [] },
            {
              definition: "To mingle or interact (with each other).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tango"],
    },
  ],
  nutty: [
    {
      word: "nutty",
      phonetic: "/ˈnʌti/",
      phonetics: [{ text: "/ˈnʌti/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Containing nuts.",
              synonyms: [],
              antonyms: [],
              example: "This is a nutty chocolate bar.",
            },
            {
              definition: "Resembling or characteristic of nuts.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Barmy, crazy, mad.",
              synonyms: [],
              antonyms: [],
              example: "Your wife is as nutty as a fruitcake.",
            },
            {
              definition: "Extravagantly fashionable",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["nuts", "squirrelly"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/nutty"],
    },
  ],
  axial: [
    {
      word: "axial",
      phonetic: "/ˈæksi.əl/",
      phonetics: [{ text: "/ˈæksi.əl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A flight feather that appears between the primaries and secondaries on some birds.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A plane parallel to the surface of a tooth.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Relating to, resembling, or situated on an axis",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Belonging to the axis of the body, or to the axis of any appendage or organ",
              synonyms: [],
              antonyms: [],
              example: "the axial bones",
            },
            {
              definition:
                "In the same direction as the axis, parallel to the axis.",
              synonyms: [],
              antonyms: [],
              example:
                "The secondary xylem usually consists of axial and radial elements.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/axial"],
    },
  ],
  natal: [
    {
      word: "natal",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of or relating to birth.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/natal"],
    },
    {
      word: "natal",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of or relating to the buttocks.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/natal"],
    },
  ],
  clomp: [
    {
      word: "clomp",
      phonetic: "/klɒmp/",
      phonetics: [
        {
          text: "/klɒmp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/clomp-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88902222",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/klɑmp/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The sound of feet hitting the ground loudly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To walk heavily or clumsily, as with clogs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make some object hit something, thereby producing a clomping sound.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/clomp"],
    },
  ],
  gored: [
    {
      word: "gored",
      phonetic: "/ɡɔːd/",
      phonetics: [
        { text: "/ɡɔːd/", audio: "" },
        { text: "/ɡɔɹd/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(of an animal) To pierce with the horn.",
              synonyms: [],
              antonyms: [],
              example: "The bull gored the matador.",
            },
            {
              definition: "To pierce with anything pointed, such as a spear.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cut in a triangular form.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To provide with a gore.",
              synonyms: [],
              antonyms: [],
              example: "to gore an apron",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having a gore or gores.",
              synonyms: [],
              antonyms: [],
              example: "a four-gored skirt",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gore",
        "https://en.wiktionary.org/wiki/gored",
      ],
    },
  ],
  siree: [
    {
      word: "siree",
      phonetic: "/səˈɹiː/",
      phonetics: [{ text: "/səˈɹiː/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(used as an intensifier, emphatically, after yes or no) Sir.",
              synonyms: [],
              antonyms: [],
              example: '"Are you coming?"',
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/siree"],
    },
  ],
  bandy: [
    {
      word: "bandy",
      phonetic: "/ˈbændi/",
      phonetics: [{ text: "/ˈbændi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To give and receive reciprocally; to exchange.",
              synonyms: [],
              antonyms: [],
              example: "to bandy words (with somebody)",
            },
            {
              definition: "To use or pass about casually.",
              synonyms: [],
              antonyms: [],
              example: "to have one's name bandied about (or around)",
            },
            {
              definition:
                "To throw or strike reciprocally, like balls in sports.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fight (with or against someone).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bandy"],
    },
    {
      word: "bandy",
      phonetic: "/ˈbændi/",
      phonetics: [{ text: "/ˈbændi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Bowlegged, or bending outward at the knees; as in bandy-legged.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bandy"],
    },
    {
      word: "bandy",
      phonetic: "/ˈbændi/",
      phonetics: [{ text: "/ˈbændi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A winter sport played on ice, from which ice hockey developed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A club bent at the lower part for striking a ball at play; a hockey stick.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bandy"],
    },
    {
      word: "bandy",
      phonetic: "/ˈbændi/",
      phonetics: [{ text: "/ˈbændi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A carriage or cart used in India, especially one drawn by bullocks.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bandy"],
    },
  ],
  gunny: [
    {
      word: "gunny",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A coarse heavy fabric made of jute or hemp.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A gunny sack.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gunny"],
    },
    {
      word: "gunny",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A gunnery sergeant.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gunny"],
    },
  ],
  runic: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  whizz: [
    {
      word: "whizz",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A whirring or hissing sound (as above).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Someone who is remarkably skilled at something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                '(especially with the verb "take") An act of urination.',
              synonyms: [],
              antonyms: [],
              example: "I have to take a whiz.",
            },
            { definition: "Amphetamine.", synonyms: [], antonyms: [] },
            {
              definition: '(with "the") Pickpocketing.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make a whirring or hissing sound, similar to that of an object speeding through the air.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To rush or move swiftly with such a sound.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To throw or spin rapidly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To urinate.",
              synonyms: [],
              antonyms: [],
              example: "We whizzed in the bushes.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/whiz",
        "https://en.wiktionary.org/wiki/whizz",
      ],
    },
  ],
  rupee: [
    {
      word: "rupee",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rupee-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75857507",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ɹʊ-/", audio: "" },
        { text: "/ɹuˈpi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The common name for the monetary currencies used in modern India, Mauritius, Nepal, Pakistan, the Seychelles, or Sri Lanka, often abbreviated ₨.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A silver coin circulating in India between the 16th and 20th centuries, weighing one tola (formerly 170–180 troy grains; from 1833, 180 troy grains).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rupee"],
    },
  ],
  fated: [
    {
      word: "fated",
      phonetic: "[ˈfeɪ̯ɾɨd]",
      phonetics: [{ text: "[ˈfeɪ̯ɾɨd]", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To foreordain or predetermine, to make inevitable.",
              synonyms: [],
              antonyms: [],
              example:
                "The oracle's prediction fated Oedipus to kill his father; not all his striving could change what would occur.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Foreordained, predetermined, established in advance by fate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fate",
        "https://en.wiktionary.org/wiki/fated",
      ],
    },
  ],
  wiper: [
    {
      word: "wiper",
      phonetic: "/ˈwaɪpɚ/",
      phonetics: [{ text: "/ˈwaɪpɚ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Someone who wipes.", synonyms: [], antonyms: [] },
            {
              definition:
                "Something, such as a towel, that is used for wiping.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something, such as a windscreen wiper, that is designed for wiping.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A movable electric contact in some devices.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A junior role in the engine room of a ship, someone who wipes down machinery and generally keeps it clean.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/wiper"],
    },
  ],
  bards: [
    {
      word: "bards",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A professional poet and singer, like among the ancient Celts, whose occupation was to compose and sing verses in honor of the heroic achievements of princes and brave men.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) A poet.",
              synonyms: [],
              antonyms: [],
              example: "Shakespeare is known as the bard of Avon.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A piece of defensive (or, sometimes, ornamental) armor for a horse's neck, breast, and flanks; a barb. (Often in the plural.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Defensive armor formerly worn by a man at arms.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A thin slice of fat bacon used to cover any meat or game.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The exterior covering of the trunk and branches of a tree; the rind.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Specifically, Peruvian bark.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bard",
        "https://en.wiktionary.org/wiki/bards",
      ],
    },
  ],
  briny: [
    {
      word: "briny",
      phonetic: "/ˈbɹaɪni/",
      phonetics: [
        {
          text: "/ˈbɹaɪni/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/briny-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79323509",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "The sea.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Of, pertaining to, resembling or containing brine; salty.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/briny"],
    },
  ],
  staid: [
    {
      word: "staid",
      phonetic: "/sted/",
      phonetics: [
        { text: "/sted/", audio: "" },
        {
          text: "/steɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/staid-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88902077",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/steɪd/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Not capricious or impulsive; sedate, serious, sober.",
              synonyms: ["composed", "dignified", "regular", "steady"],
              antonyms: ["fanciful", "unpredictable", "volatile", "wild"],
            },
            {
              definition: "Always fixed in the same location; stationary.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["composed", "dignified", "regular", "steady"],
          antonyms: ["fanciful", "unpredictable", "volatile", "wild"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/staid"],
    },
  ],
  hocks: [
    {
      word: "hocks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A Rhenish wine, of a light yellow color, either sparkling or still, from the Hochheim region; often applied to all Rhenish wines.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The tarsal joint of a digitigrade quadruped, such as a horse, pig or dog.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Meat from that part of a food animal.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To disable by cutting the tendons of the hock; to hamstring; to hough.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["hamstring", "hough", "hox"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To leave with a pawnbroker as security for a loan.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To bother; to pester; to annoy incessantly",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "To cough heavily, esp. causing uvular frication.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hock",
        "https://en.wiktionary.org/wiki/hocks",
      ],
    },
  ],
  ochre: [
    {
      word: "ochre",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ochre-1-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75833014",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈəʊkə/", audio: "" },
        {
          text: "/ˈoʊkɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ochre-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=11058421",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An earth pigment containing silica, aluminum and ferric oxide",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A somewhat dark yellowish orange colour",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: 'The stop codon sequence "UAA."',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Money, especially gold.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various brown-coloured hesperiid butterflies of the genus Trapezites.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cover or tint with ochre.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having a yellow-orange colour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Referring to cultures that covered their dead with ochre.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ochre"],
    },
    {
      word: "ochre",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The edible immature mucilaginous seed pod (properly, capsule) of the Abelmoschus esculentus.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The flowering mallow plant Abelmoschus esculentus itself, now commonly grown in the tropics and warmer parts of the temperate zones.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["ladies' fingers", "bhindi", "gumbo", "ladyfinger"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ochre",
        "https://en.wiktionary.org/wiki/okra",
      ],
    },
  ],
  yummy: [
    {
      word: "yummy",
      phonetic: "/ˈjʌm.i/",
      phonetics: [
        {
          text: "/ˈjʌm.i/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/yummy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769806",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "(lighthearted) Delicious.",
              synonyms: [],
              antonyms: ["yucky"],
            },
          ],
          synonyms: [],
          antonyms: ["yucky"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A mother who is sexually attractive.",
              synonyms: ["MILF", "yummy"],
              antonyms: [],
            },
          ],
          synonyms: ["MILF", "yummy"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/yummy",
        "https://en.wiktionary.org/wiki/yummy%20mummy",
      ],
    },
  ],
  gents: [
    {
      word: "gents",
      phonetic: "/ˈdʒɛnts/",
      phonetics: [
        {
          text: "/ˈdʒɛnts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gents-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88675853",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A gentleman.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A men's room: a lavatory intended for use by men.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["gents'", "men's"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Men's: intended for use by men.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gent",
        "https://en.wiktionary.org/wiki/gents",
      ],
    },
  ],
  soupy: [
    {
      word: "soupy",
      phonetic: "/ˈsupi/",
      phonetics: [{ text: "/ˈsupi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Resembling soup; creamy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Extravagant sentimental; slushy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/soupy"],
    },
  ],
  roper: [
    {
      word: "roper",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/roper-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79294636",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An accomplice who locates a mark to be swindled by a confidence trickster.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Agent noun of rope; one who uses a rope, especially one who throws a lariat or lasso.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A maker of ropes.", synonyms: [], antonyms: [] },
            {
              definition: "One who ropes goods; a packer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A crafty fellow; one fit to be hanged.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person hired by a gambling establishment to locate potential customers and bring them in.",
              synonyms: ["lugger", "picker-up", "runner", "steerer"],
              antonyms: [],
            },
          ],
          synonyms: ["lugger", "picker-up", "runner", "steerer"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/outside%20man",
        "https://en.wiktionary.org/wiki/roper",
      ],
    },
  ],
  swath: [
    {
      word: "swath",
      phonetic: "/swɒθ/",
      phonetics: [
        { text: "/swɒθ/", audio: "" },
        {
          text: "/swɑθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/swath-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1088573",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The track cut out by a scythe in mowing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A broad sweep or expanse, such as of land or of people.",
              synonyms: [],
              antonyms: [],
              example:
                "A large swath of the population is opposed to this government policy.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/swath"],
    },
  ],
  cameo: [
    {
      word: "cameo",
      phonetic: "/ˈkæm.iː.əʊ/",
      phonetics: [
        { text: "/ˈkæm.iː.əʊ/", audio: "" },
        { text: "/ˈkæm.i.oʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A piece of jewelry, etc., carved in relief.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A single very brief appearance, especially by a prominent celebrity in a movie or song.",
              synonyms: [],
              antonyms: [],
              example:
                "Famous comic book writer Stan Lee had a cameo in the Spider-Man movie. He was on screen for perhaps ten seconds, but aficionados distinctly remember him.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To appear in a cameo role.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cameo"],
    },
  ],
  edger: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  spate: [
    {
      word: "spate",
      phonetic: "/speɪt/",
      phonetics: [
        {
          text: "/speɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spate-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=341093",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A river flood; an overflow or inundation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) A sudden rush or increase.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spate"],
    },
  ],
  gimme: [
    {
      word: "gimme",
      phonetic: "/ˈɡɪmiː/",
      phonetics: [
        {
          text: "/ˈɡɪmiː/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gimme-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1332231",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "That which is easily obtained, or certain to occur.",
              synonyms: [],
              antonyms: [],
              example: "It's a gimme.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gimme"],
    },
  ],
  ebbed: [
    {
      word: "ebbed",
      phonetic: "/ɛbd/",
      phonetics: [
        {
          text: "/ɛbd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ebbed-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1212383",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To flow back or recede",
              synonyms: [],
              antonyms: [],
              example: "The tides ebbed at noon.",
            },
            {
              definition: "To fall away or decline",
              synonyms: [],
              antonyms: [],
              example: "The dying man's strength ebbed away.",
            },
            {
              definition:
                "To fish with stakes and nets that serve to prevent the fish from getting back into the sea with the ebb",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause to flow back.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ebb",
        "https://en.wiktionary.org/wiki/ebbed",
      ],
    },
  ],
  breve: [
    {
      word: "breve",
      phonetic: "/bɹiːv/",
      phonetics: [{ text: "/bɹiːv/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A semicircular diacritical mark (˘) placed above a vowel, commonly used to mark its quantity as short.",
              synonyms: ["micron"],
              antonyms: ["macron"],
            },
            { definition: "A double whole note.", synonyms: [], antonyms: [] },
            {
              definition:
                "Any writ or precept under seal, issued out of any court.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The great ant thrush of Sumatra (Pitta gigas), which has a very short tail.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["micron"],
          antonyms: ["macron"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/breve"],
    },
  ],
  theta: [
    {
      word: "theta",
      phonetic: "/ˈθiːtə/",
      phonetics: [
        { text: "/ˈθiːtə/", audio: "" },
        {
          text: "/ˈθeɪtə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/theta-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=311281",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The eighth letter of the Modern Greek alphabet, ninth in Old Greek: Θ, θ.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The measure of an angle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'The sensitivity of the value of a derivative with respect to time; the "time decay".',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Designating a level of brain activity as measured by electroencephalography having a frequency of between four and seven cycles per second (associated with drowsiness in adults).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The voiceless dental fricative represented by θ.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/theta"],
    },
  ],
  deems: [
    {
      word: "deems",
      phonetic: "/diːmz/",
      phonetics: [{ text: "/diːmz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An opinion, a judgment, a surmise.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To judge, to pass judgment on; to doom, to sentence.",
              synonyms: ["judge"],
              antonyms: [],
            },
            {
              definition: "To adjudge, to decree.",
              synonyms: ["judge"],
              antonyms: [],
            },
            {
              definition: "To dispense (justice); to administer (law).",
              synonyms: ["judge"],
              antonyms: [],
            },
            {
              definition:
                "(ditransitive) To hold in belief or estimation; to adjudge as a conclusion; to regard as being; to evaluate according to one's beliefs; to account.",
              synonyms: ["consider"],
              antonyms: [],
              example: "She deemed his efforts insufficient.",
            },
            {
              definition:
                "To think, judge, or have or hold as an opinion; to decide or believe on consideration; to suppose.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["consider", "judge", "judge", "judge"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/deem",
        "https://en.wiktionary.org/wiki/deems",
      ],
    },
  ],
  dykes: [
    {
      word: "dykes",
      phonetic: "/daɪks/",
      phonetics: [{ text: "/daɪks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(usually derogatory) A lesbian, particularly one with masculine or butch traits or behavior.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dyke",
        "https://en.wiktionary.org/wiki/dykes",
      ],
    },
  ],
  servo: [
    {
      word: "servo",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/servo-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75649706",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "[ˈsɜː.vəʊ]", audio: "" },
        { text: "[ˈsɝ.voʊ]", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A servomechanism or servomotor.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To control by means of a servocontrol",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/servo"],
    },
    {
      word: "servo",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/servo-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75649706",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "[ˈsɜː.vəʊ]", audio: "" },
        { text: "[ˈsɝ.voʊ]", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A service station, being a place to buy petrol for cars etc., as well as various convenience items, with or without actual car service facilities.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/servo"],
    },
  ],
  telly: [
    {
      word: "telly",
      phonetic: "/ˈtɛli/",
      phonetics: [{ text: "/ˈtɛli/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Television.",
              synonyms: [],
              antonyms: [],
              example: "Not much on telly tonight, as usual!",
            },
            {
              definition: "A television set.",
              synonyms: [],
              antonyms: [],
              example: "We've got a new flat-screen telly.",
            },
          ],
          synonyms: ["TV", "tele", "tube"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/telly"],
    },
    {
      word: "telly",
      phonetic: "/ˈtɛli/",
      phonetics: [{ text: "/ˈtɛli/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A hotel or motel.",
              synonyms: [],
              antonyms: [],
              example: "I'm staying at the telly this weekend.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/telly"],
    },
  ],
  tabby: [
    {
      word: "tabby",
      phonetic: "/ˈtæb.i/",
      phonetics: [{ text: "/ˈtæb.i/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A kind of waved silk, usually called watered silk, manufactured like taffeta, but thicker and stronger. The watering is given to it by calendering.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A mixture of lime with shells, gravel, or stones, in equal proportions, with an equal proportion of water. When dry, this becomes as hard as rock.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A brindled cat.", synonyms: [], antonyms: [] },
            {
              definition: "An old maid or gossip.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To give a wavy or watered appearance to (a textile).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having a wavy or watered appearance",
              synonyms: [],
              antonyms: [],
              example: "a tabby waistcoat",
            },
            {
              definition: "Brindled; diversified in color",
              synonyms: [],
              antonyms: [],
              example: "a tabby cat.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tabby"],
    },
  ],
  tares: [
    {
      word: "tares",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A vetch, or the seed of a vetch (genus Vicia, esp. Vicia sativa)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any of the tufted grasses of genus Lolium; darnel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A damaging weed growing in fields of grain.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The empty weight of a container; the tare weight or unladen weight.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To take into account the weight of the container, wrapping etc. in weighting merchandise.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To set a zero value on an instrument (usually a balance) that discounts the starting point.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["zero"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tare",
        "https://en.wiktionary.org/wiki/tares",
      ],
    },
  ],
  blocs: [
    {
      word: "blocs",
      phonetic: "/blɒks/",
      phonetics: [
        { text: "/blɒks/", audio: "" },
        {
          text: "/blɑks/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/blocs-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2650699",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A group of voters or politicians who share common goals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A group of countries acting together for political or economic goals, an alliance: e.g., the eastern bloc, the western bloc, a trading bloc, the Eurozone, the European Union.",
              synonyms: [],
              antonyms: [],
              example:
                "Climate change a security risk for EU, say bloc's foreign policy chiefs (EUobserver)",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bloc",
        "https://en.wiktionary.org/wiki/blocs",
      ],
    },
  ],
  welch: [
    {
      word: "welch",
      phonetic: "/wɛltʃ/",
      phonetics: [{ text: "/wɛltʃ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person who defaults on an obligation, especially a small one.",
              synonyms: [],
              antonyms: [],
              example:
                "She's a welch. That watering-can isn't hers: I lent it to her three years ago.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To fail to repay a small debt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fail to fulfill an obligation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/welch"],
    },
  ],
  ghoul: [
    {
      word: "ghoul",
      phonetic: "/ɡuːl/",
      phonetics: [
        {
          text: "/ɡuːl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ghoul-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=91208472",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(Muslim demonology) A demon said to feed on corpses.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A graverobber.", synonyms: [], antonyms: [] },
            {
              definition:
                "A person with an undue interest in death and corpses, or more generally in things that are revolting and repulsive.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ghoul"],
    },
  ],
  vitae: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  cumin: [
    {
      word: "cumin",
      phonetic: "/ˈkjuːmɪn/",
      phonetics: [
        { text: "/ˈkjuːmɪn/", audio: "" },
        { text: "/ˈkjumɪn/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The flowering plant Cuminum cyminum, in the family Apiaceae.",
              synonyms: [],
              antonyms: [],
              example:
                "Cumin is native to the region from the eastern Mediterranean to India.",
            },
            {
              definition:
                "Its aromatic long seed, used as a spice, notably in Indian, Middle Eastern, and Mexican cookery.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cumin"],
    },
  ],
  dinky: [
    {
      word: "dinky",
      phonetic: "/ˈdɪŋki/",
      phonetics: [{ text: "/ˈdɪŋki/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Tiny and cute; small and attractive.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Tiny and insignificant; small and undesirable.",
              synonyms: [],
              antonyms: [],
              example:
                "They stayed in a dinky hotel room, but they had a great trip.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dinky"],
    },
    {
      word: "dinky",
      phonetic: "/ˈdɪŋki/",
      phonetics: [{ text: "/ˈdɪŋki/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person in a relationship with double income and no kids",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dinky"],
    },
  ],
  bronc: [
    {
      word: "bronc",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A bronco.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bronc"],
    },
  ],
  tabor: [
    {
      word: "tabor",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A small drum.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make (a sound) with a tabor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To strike lightly and frequently.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tabor"],
    },
    {
      word: "tabor",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A military train of men and wagons; an encampment of such resources.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tabor"],
    },
  ],
  teeny: [
    {
      word: "teeny",
      phonetic: "/ˈtiːni/",
      phonetics: [
        {
          text: "/ˈtiːni/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/teeny-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1785300",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Very small; tiny.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/teeny"],
    },
    {
      word: "teeny",
      phonetic: "/ˈtiːni/",
      phonetics: [
        {
          text: "/ˈtiːni/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/teeny-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1785300",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Fretful; peevish; cross",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/teeny"],
    },
  ],
  comer: [
    {
      word: "comer",
      phonetic: "/ˈkʌmɚ/",
      phonetics: [{ text: "/ˈkʌmɚ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One in a race who is catching up to others and shows promise of winning.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One who is catching up in some contest and has a likelihood of victory.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One who arrives.",
              synonyms: [],
              antonyms: [],
              example: "The champ will face all comers.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/comer"],
    },
  ],
  borer: [
    {
      word: "borer",
      phonetic: "/ˈbɔːɹə/",
      phonetics: [
        { text: "/ˈbɔːɹə/", audio: "" },
        { text: "/ˈbɔːɹɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A tedious person.", synonyms: [], antonyms: [] },
            {
              definition: "A person who bores, who drills.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A tool used for drilling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An insect or insect larva that bores into wood.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the many types of mollusc that bore into soft rock.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The hagfish (Myxine).", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/borer"],
    },
  ],
  sired: [
    {
      word: "sired",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(of a male) to procreate; to father, beget, impregnate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sire",
        "https://en.wiktionary.org/wiki/sired",
      ],
    },
  ],
  privy: [
    {
      word: "privy",
      phonetic: "/ˈpɹɪv.i/",
      phonetics: [{ text: "/ˈpɹɪv.i/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An outdoor facility for urination and defecation, whether open (latrine) or enclosed (outhouse).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A lavatory: a room with a toilet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A toilet: a fixture used for urination and defecation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A partaker; one having an interest in an action, contract, etc. to which he is not himself a party.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Private, exclusive; not public; one's own.",
              synonyms: [],
              antonyms: [],
              example: "The king retreated to his privy chamber.",
            },
            {
              definition: "Secret, hidden, concealed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "With knowledge of; party to; let in on.",
              synonyms: [],
              antonyms: [],
              example: "He was privy to the discussions.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/privy"],
    },
  ],
  mammy: [
    {
      word: "mammy",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Mamma; mother", synonyms: [], antonyms: [] },
            {
              definition:
                "(historical, often pejorative) In the southern United States, a black nanny employed to look after white children; or in the antebellum South, a female slave who was close to the household and looked after the children.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mammy"],
    },
  ],
  deary: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  gyros: [
    {
      word: "gyros",
      phonetic: "/jiːɹoʊs/",
      phonetics: [
        {
          text: "/jiːɹoʊs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gyros-1-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88737422",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A style of Greek sandwich commonly filled with grilled meat, tomato, onions, and tzatziki sauce.",
              synonyms: [],
              antonyms: [],
              example: "I'll have a gyro, please.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gyro",
        "https://en.wiktionary.org/wiki/gyros",
      ],
    },
    {
      word: "gyros",
      phonetic: "/jiːɹoʊz/",
      phonetics: [
        {
          text: "/jiːɹoʊz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gyros-2-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88737427",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A gyroscope", synonyms: [], antonyms: [] },
            { definition: "A gyrocompass", synonyms: [], antonyms: [] },
            { definition: "An autogyro", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A style of Greek sandwich commonly filled with grilled meat, tomato, onions, and tzatziki sauce.",
              synonyms: [],
              antonyms: [],
              example: "I'll have a gyro, please.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gyro",
        "https://en.wiktionary.org/wiki/gyros",
      ],
    },
    {
      word: "gyros",
      phonetic: "[ˈd͡ʒaɪɹoʊz]",
      phonetics: [
        {
          text: "[ˈd͡ʒaɪɹoʊz]",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gyros-3-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88737429",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A gyroscope", synonyms: [], antonyms: [] },
            { definition: "A gyrocompass", synonyms: [], antonyms: [] },
            { definition: "An autogyro", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A style of Greek sandwich commonly filled with grilled meat, tomato, onions, and tzatziki sauce.",
              synonyms: [],
              antonyms: [],
              example: "I'll have a gyro, please.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gyro",
        "https://en.wiktionary.org/wiki/gyros",
      ],
    },
  ],
  sprit: [
    {
      word: "sprit",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A spar between mast and upper outer corner of a spritsail on sailing boats.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A shoot; a sprout.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To sprout; to bud; to germinate, as barley steeped for malt.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sprit"],
    },
    {
      word: "sprit",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To throw out with force from a narrow orifice; to eject; to spurt out.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sprit"],
    },
  ],
  conga: [
    {
      word: "conga",
      phonetic: "/ˈkɒŋɡə/",
      phonetics: [
        { text: "/ˈkɒŋɡə/", audio: "" },
        { text: "/ˈkɑŋɡə/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A tall, narrow, single-headed Cuban hand drum of African origin",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A march of Cuban origin in four-four time in which people form a chain, each holding the hips of the person in front of them; in each bar, dancers take three shuffle steps and then kick alternate legs outwards at the beat; the chain weaves around the place and allows new participants to join the back of the chain",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To dance the conga.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/conga"],
    },
  ],
  quire: [
    {
      word: "quire",
      phonetic: "/ˈkwaɪ.ə(ɹ)/",
      phonetics: [
        { text: "/ˈkwaɪ.ə(ɹ)/", audio: "" },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/quire-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50330720",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One-twentieth of a ream of paper; a collection of twenty-four or twenty-five sheets of paper of the same size and quality, unfolded or having a single fold.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A set of leaves which are stitched together, originally a set of four pieces of paper (eight leaves, sixteen pages). This is most often a single signature (i.e. group of four), but may be several nested signatures.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A book, poem, or pamphlet.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To prepare quires by stitching together leaves of paper.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/quire"],
    },
    {
      word: "quire",
      phonetic: "/ˈkwaɪ.ə(ɹ)/",
      phonetics: [
        { text: "/ˈkwaɪ.ə(ɹ)/", audio: "" },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/quire-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50330720",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A choir.", synonyms: [], antonyms: [] },
            {
              definition:
                "One quarter of a cruciform church, or the architectural area of a church used by the choir, often near the apse.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To sing in concert.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/quire"],
    },
  ],
  thugs: [
    {
      word: "thugs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Someone with an intimidating and unseemly appearance and mannerisms, who treats others violently and roughly, often for hire.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of a band of assassins formerly active in northern India who worshipped Kali and offered their victims to her.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In gardening, an over-vigorous plant that spreads and dominates the flowerbed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A punk; a hoodlum; a hooligan.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/thug",
        "https://en.wiktionary.org/wiki/thugs",
      ],
    },
  ],
  furor: [
    {
      word: "furor",
      phonetic: "/ˈfjɜɹə/",
      phonetics: [
        { text: "/ˈfjɜɹə/", audio: "" },
        { text: "/ˈfjɝɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A general uproar or commotion",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Violent anger or frenzy",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A state of intense excitement",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/furor"],
    },
  ],
  bloke: [
    {
      word: "bloke",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bloke-au.oga",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75267403",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/bləʊk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bloke-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80175001",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A man, a fellow; an ordinary man, a man on the street.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A man who behaves in a particularly laddish or overtly heterosexual manner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(A lower deck term for) the Captain or Executive Officer of a warship, with particular reference to discipline and punishment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An exemplar of a certain masculine, independent male archetype.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An anglophone man.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bloke"],
    },
  ],
  runes: [
    {
      word: "runes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A letter, or character, belonging to the written language of various ancient Germanic peoples, especially the Scandinavians and the Anglo-Saxons.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A Finnish or Scandinavian epic poem, or a division of one, especially a division of the Kalevala.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A letter or mark used as mystical or magic symbol.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A verse or song, especially one with mystical or mysterious overtones; a spell or an incantation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A roun (secret or mystery).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In the Go programming language, a Unicode code point.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rune",
        "https://en.wiktionary.org/wiki/runes",
      ],
    },
  ],
  bawdy: [
    {
      word: "bawdy",
      phonetic: "/ˈbɔːdi/",
      phonetics: [
        { text: "/ˈbɔːdi/", audio: "" },
        { text: "/ˈbɔdi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Soiled, dirty.", synonyms: [], antonyms: [] },
            {
              definition: "Obscene; filthy; unchaste.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of language) Sexual in nature and usually meant to be humorous but considered rude.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bawdy"],
    },
  ],
  cadre: [
    {
      word: "cadre",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cadre-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75729228",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈkɑː.də/", audio: "" },
        { text: "/ˈkæd.ɹeɪ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A frame or framework.", synonyms: [], antonyms: [] },
            {
              definition:
                "The framework or skeleton upon which a new regiment is to be formed; the officers of a regiment forming the staff.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The core of a managing group, or a member of such a group.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small group of people specially trained for a particular purpose or profession.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cadre"],
    },
  ],
  toxin: [
    {
      word: "toxin",
      phonetic: "/ˈtɒksɪn/",
      phonetics: [
        { text: "/ˈtɒksɪn/", audio: "" },
        { text: "/ˈtɑksɪn/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A toxic or poisonous substance produced by the biological processes of biological organisms.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/toxin"],
    },
  ],
  annul: [
    {
      word: "annul",
      phonetic: "/əˈnʌl/",
      phonetics: [{ text: "/əˈnʌl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To formally revoke the validity of.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To dissolve (a marital union) on the grounds that it is not valid.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/annul"],
    },
  ],
  egged: [
    {
      word: "egged",
      phonetic: "/ɛɡd/",
      phonetics: [{ text: "/ɛɡd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To throw eggs at.", synonyms: [], antonyms: [] },
            {
              definition: "To dip in or coat with beaten egg.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To distort a circular cross-section (as in a tube) to an elliptical or oval shape, either inadvertently or intentionally.",
              synonyms: [],
              antonyms: [],
              example:
                "After I cut the tubing, I found that I had slightly egged it in the vise.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To encourage, incite.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/egg",
        "https://en.wiktionary.org/wiki/egged",
      ],
    },
  ],
  anion: [
    {
      word: "anion",
      phonetic: "/ˈæn.aɪ.ɒn/",
      phonetics: [
        { text: "/ˈæn.aɪ.ɒn/", audio: "" },
        { text: "/ˈæn.aɪ.ɑn/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A negatively charged ion.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/anion"],
    },
  ],
  nodes: [
    {
      word: "nodes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A knot, knob, protuberance or swelling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The point where the orbit of a planet, as viewed from the Sun, intersects the ecliptic. The ascending and descending nodes refer respectively to the points where the planet moves from South to North and N to S; their respective symbols are ☊ and ☋.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A leaf node.", synonyms: [], antonyms: [] },
            {
              definition: "A computer or other device attached to a network.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The point at which the lines of a funicular machine meet from different angular directions; — called also knot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The point at which a curve crosses itself, being a double point of the curve. See crunode and acnode.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A similar point on a surface, where there is more than one tangent-plane.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A vertex or a leaf in a graph of a network, or other element in a data structure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hard concretion or incrustation which forms upon bones attacked with rheumatism, gout, or syphilis; sometimes also, a swelling in the neighborhood of a joint.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A point along a standing wave where the wave has minimal amplitude.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The knot, intrigue, or plot of a dramatic work.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hole in the gnomon of a sundial, through which passes the ray of light which marks the hour of the day, the parallels of the Sun's declination, his place in the ecliptic, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The word of interest in a KWIC, surrounded by left and right cotexts.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["host", "vertex"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/node",
        "https://en.wiktionary.org/wiki/nodes",
      ],
    },
  ],
  picky: [
    {
      word: "picky",
      phonetic: "/ˈpɪki/",
      phonetics: [{ text: "/ˈpɪki/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A picture.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["pic"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Fussy; particular; demanding to have things just right.",
              synonyms: [],
              antonyms: [],
              example: "I am very picky about the way my kitchen is laid out.",
            },
          ],
          synonyms: [
            "anal-retentive",
            "choosy",
            "finicky",
            "fussy",
            "particular",
            "pedantic",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/picky"],
    },
  ],
  stein: [
    {
      word: "stein",
      phonetic: "/staɪn/",
      phonetics: [
        {
          text: "/staɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stein-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89013949",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A beer mug, usually made of ceramic or glass.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stein"],
    },
  ],
  jello: [
    {
      word: "jello",
      phonetic: "/ˈdʒɛləʊ/",
      phonetics: [
        { text: "/ˈdʒɛləʊ/", audio: "" },
        { text: "/ˈdʒɛloʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A dessert made by boiling flavoured gelatin in water",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["jelly"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/jello"],
    },
  ],
  audit: [
    {
      word: "audit",
      phonetic: "/ɔː.dɪt/",
      phonetics: [
        {
          text: "/ɔː.dɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/audit-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=8463893",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A judicial examination.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An examination in general.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An independent review and examination of records and activities to assess the adequacy of system controls, to ensure compliance with established policies and operational procedures, and to recommend necessary changes in controls, policies, or procedures",
              synonyms: [],
              antonyms: [],
              example: "National Assembly audit",
            },
            {
              definition:
                "The result of such an examination, or an account as adjusted by auditors; final account.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Spiritual counseling, which forms the core of Dianetics.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A general receptacle or receiver.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An audience; a hearing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To examine and adjust (e.g. an account).",
              synonyms: [],
              antonyms: [],
              example:
                "to audit the accounts of a treasure, or of parties who have a suit depending in court",
            },
            {
              definition:
                "To conduct an independent review and examination of system records and activities in order to test the adequacy and effectiveness of data security and data integrity procedures, to ensure compliance with established policy and operational procedures, and to recommend any necessary changes",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To counsel spiritually.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To attend an academic class on a not-for-academic-credit basis.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/audit"],
    },
  ],
  echos: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  fagot: [
    {
      word: "fagot",
      phonetic: "/ˈfæɡ.ət/",
      phonetics: [{ text: "/ˈfæɡ.ət/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(collective) A bundle of sticks or brushwood intended to be used for fuel tied together for carrying. (Some sources specify that a faggot is tied with two bands or withes, whereas a bavin is tied with just one.)",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Burdensome baggage.", synonyms: [], antonyms: [] },
            {
              definition:
                "A bundle of pieces of iron or steel cut off into suitable lengths for welding.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A burning or smouldering piece of firewood.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A meatball made with offcuts and offal, especially pork. (See Wikipedia.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An annoying or inconsiderate person.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A shrewish woman.", synonyms: [], antonyms: [] },
            {
              definition: "A homosexual man, especially an effeminate one.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A man considered weak, effeminate, timid, pathetic, emotional, non-heteronormative in some way",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A soldier numbered on the muster-roll, but not really existing.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A faggot voter.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A bundle of pieces of wrought iron to be worked over into bars or other shapes by rolling or hammering at a welding heat; a pile.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A fagotto, or bassoon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person hired to take the place of another at the muster of a company.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make a fagot of; to bind together in a fagot or bundle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/faggot",
        "https://en.wiktionary.org/wiki/fagot",
      ],
    },
  ],
  letup: [
    {
      word: "letup",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A pause or period of slackening.",
              synonyms: [],
              antonyms: [],
              example:
                "We waited for a letup in the rain, then we ran home before it started again.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/letup"],
    },
  ],
  eyrie: [
    {
      word: "eyrie",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/eyrie-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75796672",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈaɪɹi/", audio: "" },
        { text: "/ˈaɪɹi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The nest of a bird of prey.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any high and remote but commanding place.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/eyrie"],
    },
  ],
  fount: [
    {
      word: "fount",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Something from which water flows.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A device from which poultry may drink.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That from which something flows or proceeds; a source.",
              synonyms: [],
              antonyms: [],
              example: "He is a real fount of knowledge!",
            },
          ],
          synonyms: ["waterer", "font", "fountain"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fount"],
    },
    {
      word: "fount",
      phonetic: "/fɒnt/",
      phonetics: [{ text: "/fɒnt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A typographic font.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fount"],
    },
  ],
  caped: [
    {
      word: "caped",
      phonetic: "/ˈkeɪpt/",
      phonetics: [{ text: "/ˈkeɪpt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Wearing a cape or capes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(in compounds) Wearing a cape of a specified kind.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Cancelled", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/caped"],
    },
  ],
  axons: [
    {
      word: "axons",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A nerve fibre which is a long slender projection of a nerve cell, and which conducts nerve impulses away from the body of the cell to a synapse.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/axon",
        "https://en.wiktionary.org/wiki/axons",
      ],
    },
  ],
  amuck: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  banal: [
    {
      word: "banal",
      phonetic: "/bəˈnæl/",
      phonetics: [
        {
          text: "/bəˈnæl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/banal-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1869614",
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Common in a boring way, to the point of being predictable; containing nothing new or fresh.",
              synonyms: ["everyday", "prosaic"],
              antonyms: ["new", "original"],
            },
            {
              definition:
                "(history) Relating to a type of feudal jurisdiction or service.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["everyday", "prosaic"],
          antonyms: ["new", "original"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/banal"],
    },
  ],
  riled: [
    {
      word: "riled",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To make angry", synonyms: [], antonyms: [] },
            {
              definition: "To stir or move from a state of calm or order",
              synonyms: [],
              antonyms: [],
              example:
                "It riles me that she never closes the door after she leaves.",
            },
          ],
          synonyms: ["aggravate", "anger", "annoy", "irritate", "vex"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rile",
        "https://en.wiktionary.org/wiki/riled",
      ],
    },
  ],
  petit: [
    {
      word: "petit",
      phonetic: "/pəˈtiː/",
      phonetics: [
        { text: "/pəˈtiː/", audio: "" },
        { text: "/pəˈti/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(usually in the plural) A little schoolboy.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A kind of pigeon.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "(of size) Petite: small, little.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Petty, in its various senses:",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/petit"],
    },
    {
      word: "petit",
      phonetic: "/pəˈtiː/",
      phonetics: [
        { text: "/pəˈtiː/", audio: "" },
        { text: "/pəˈti/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The size of type between minion and bourgeois, standardized as 8-point.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["petit", "small text"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/brevier",
        "https://en.wiktionary.org/wiki/petit",
      ],
    },
  ],
  umber: [
    {
      word: "umber",
      phonetic: "/ˈʌmbə/",
      phonetics: [
        {
          text: "/ˈʌmbə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/umber-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89033126",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈʌmbɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A brown clay, somewhat darker than ochre, which contains iron and manganese oxides.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A grayling.", synonyms: [], antonyms: [] },
            {
              definition:
                "A dusky brown African wading bird (Scopus umbretta) allied to the shoebill and herons; a hamerkop.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To give a reddish-brown colour to.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Of a reddish brown colour, like that of the pigment.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "In ancient armour, a visor, or projection like the peak of a cap, to which a fixed or movable faceguard was sometimes attached.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/umber",
        "https://en.wiktionary.org/wiki/umbrere",
      ],
    },
  ],
  miler: [
    {
      word: "miler",
      phonetic: "/ˈmaɪləɹ/",
      phonetics: [{ text: "/ˈmaɪləɹ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(often in combination) An athlete or a horse who specializes in running races of one mile, or a specified number of miles.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in combination) A race whose length is the specified number of miles.",
              synonyms: [],
              antonyms: [],
              example:
                "I ran an eight-miler on Sunday and I'm still feeling sore.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/miler"],
    },
  ],
  fibre: [
    {
      word: "fibre",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fibre-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75269430",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈfaɪ.bə(ɹ)/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A single piece of a given material, elongated and roughly round in cross-section, often twisted with other fibres to form thread.",
              synonyms: [],
              antonyms: [],
              example:
                "The microscope showed several different fibres stuck to the sole of the shoe.",
            },
            {
              definition: "Material in the form of fibres.",
              synonyms: [],
              antonyms: [],
              example: "The cloth was made from strange, somewhat rough fibre.",
            },
            {
              definition: "Dietary fibre.",
              synonyms: [],
              antonyms: [],
              example: "Fresh vegetables are a good source of fibre.",
            },
            {
              definition: "Moral strength and resolve.",
              synonyms: [],
              antonyms: [],
              example: "The ordeal was a test of everyone’s fibre.",
            },
            {
              definition:
                "The preimage of a given point in the range of a map.",
              synonyms: [],
              antonyms: [],
              example:
                "Under this map, any two values in the fibre of a given point on the circle differ by 2π",
            },
            {
              definition:
                "Said to be of a morphism over a global element: The pullback of the said morphism along the said global element.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A kind of lightweight thread of execution.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A long tubular cell found in muscle tissue; myocyte.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fibre"],
    },
  ],
  agave: [
    {
      word: "agave",
      phonetic: "/əˈɡeɪviː/",
      phonetics: [{ text: "/əˈɡeɪviː/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A plant of the genus Agave, which includes the maguey or century plant (Agave americana), which produces a gigantic flower stem at maturity.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/agave"],
    },
  ],
  bated: [
    {
      word: "bated",
      phonetic: "/ˈbeɪtɪd/",
      phonetics: [{ text: "/ˈbeɪtɪd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To reduce the force of something; to abate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To restrain, usually with the sense of being in anticipation",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(sometimes figurative) To cut off, remove, take away.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To leave out, except, bar.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To waste away.", synonyms: [], antonyms: [] },
            { definition: "To deprive of.", synonyms: [], antonyms: [] },
            {
              definition:
                "To lessen by retrenching, deducting, or reducing; to abate; to beat down; to lower.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To allow by way of abatement or deduction.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To contend or strive with blows or arguments.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of a falcon: To flap the wings vigorously; to bait.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To soak leather so as to remove chemicals used in tanning; to steep in bate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To masturbate.",
              synonyms: [],
              antonyms: [],
              example:
                "I could go out and take a walk in the park, but I'd rather stay home and bate all day.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Reduced; lowered; restrained",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bate",
        "https://en.wiktionary.org/wiki/bated",
      ],
    },
  ],
  bilge: [
    {
      word: "bilge",
      phonetic: "/bɪldʒ/",
      phonetics: [
        {
          text: "/bɪldʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bilge-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769614",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The rounded portion of a ship's hull, forming a transition between the bottom and the sides.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The lowest inner part of a ship's hull, where water accumulates.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The water accumulated in the bilge, the bilge water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Stupid talk or writing; nonsense.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The bulging part of a barrel or cask.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To spring a leak in the bilge.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To bulge or swell.", synonyms: [], antonyms: [] },
            {
              definition: "To break open the bilge(s) of.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bilge"],
    },
  ],
  vitro: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  feint: [
    {
      word: "feint",
      phonetic: "/feɪ̯nt/",
      phonetics: [
        {
          text: "/feɪ̯nt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/feint-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=86873139",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A movement made to confuse the opponent; a dummy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That which is feigned; an assumed or false appearance; a pretense or stratagem.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(war) An offensive movement resembling an attack in all but its continuance",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make a feint, or mock attack.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Feigned; counterfeit.", synonyms: [], antonyms: [] },
            {
              definition:
                "(war) (of an attack) directed toward a different part from the intended strike",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/feint"],
    },
    {
      word: "feint",
      phonetic: "/feɪ̯nt/",
      phonetics: [
        {
          text: "/feɪ̯nt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/feint-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=86873139",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The narrowest rule used in the production of lined writing paper.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/feint"],
    },
  ],
  pudgy: [
    {
      word: "pudgy",
      phonetic: "/ˈpʌdʒi/",
      phonetics: [{ text: "/ˈpʌdʒi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Fat, overweight (pertaining particularly to children), plump; chubby.",
              synonyms: [],
              antonyms: [],
              example: "The pudgy child had a difficult time running the mile.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pudgy"],
    },
  ],
  mater: [
    {
      word: "mater",
      phonetic: "/ˈmeɪtə/",
      phonetics: [
        { text: "/ˈmeɪtə/", audio: "" },
        { text: "/ˈmeɪtɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Mother.", synonyms: [], antonyms: [] },
            {
              definition:
                "A meninx; the dura mater, arachnoid mater, or pia mater of the brain.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mater"],
    },
    {
      word: "mater",
      phonetic: "/ˈmeɪtə/",
      phonetics: [
        { text: "/ˈmeɪtə/", audio: "" },
        { text: "/ˈmeɪtɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Someone or something that mates.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mater"],
    },
    {
      word: "mater",
      phonetic: "/ˈmeɪtɚ/",
      phonetics: [{ text: "/ˈmeɪtɚ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Tomato.",
              synonyms: [],
              antonyms: [],
              example: "'mater sandwich",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/'mater",
        "https://en.wiktionary.org/wiki/mater",
      ],
    },
  ],
  manic: [
    {
      word: "manic",
      phonetic: "/ˈmænɪk/",
      phonetics: [{ text: "/ˈmænɪk/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Of or pertaining to someone who exhibits mania or craziness; wicked.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Suffering from mania, the state of an abnormally elevated or irritable mood, arousal, and/or energy levels.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/manic"],
    },
  ],
  umped: [
    {
      word: "umped",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To act as an umpire.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ump",
        "https://en.wiktionary.org/wiki/umped",
      ],
    },
  ],
  pesky: [
    {
      word: "pesky",
      phonetic: "/ˈpɛski/",
      phonetics: [{ text: "/ˈpɛski/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Annoying, troublesome, irritating (usually of an animal or child).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pesky"],
    },
  ],
  strep: [
    {
      word: "strep",
      phonetic: "/stɹɛp/",
      phonetics: [{ text: "/stɹɛp/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A strep throat.", synonyms: [], antonyms: [] },
            {
              definition: "A streptococcus.",
              synonyms: ["strepto"],
              antonyms: [],
            },
          ],
          synonyms: ["strepto"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/strep"],
    },
    {
      word: "strep",
      phonetic: "/stɹɛp/",
      phonetics: [{ text: "/stɹɛp/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of several plants of the genus Streptocarpus. the Cape primroses.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/strep",
        "https://en.wiktionary.org/wiki/streptocarpus",
      ],
    },
  ],
  slurp: [
    {
      word: "slurp",
      phonetic: "/slɝp/",
      phonetics: [{ text: "/slɝp/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A loud sucking noise made in eating or drinking",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A mouthful of liquid", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To eat or drink noisily.",
              synonyms: [],
              antonyms: [],
              example: "They sat in the kitchen slurping their spaghetti.",
            },
            {
              definition: "To make a loud sucking noise.",
              synonyms: [],
              antonyms: [],
              example: "The mud slurped under our shoes.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/slurp"],
    },
  ],
  pylon: [
    {
      word: "pylon",
      phonetic: "/ˈpaɪ.lɒn/",
      phonetics: [{ text: "/ˈpaɪ.lɒn/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A gateway to the inner part of an Ancient Egyptian temple.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A tower-like structure, usually one of a series, used to support high-voltage electricity cables.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A structure used to mount engines, missiles etc., to the underside of an aircraft wing or fuselage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A starting derrick for an aeroplane.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A post, tower, etc. as on an aerodrome, or flying ground, serving to bound or mark a prescribed course of flight.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An obelisk.", synonyms: [], antonyms: [] },
            { definition: "A traffic cone.", synonyms: [], antonyms: [] },
            {
              definition:
                "An orange marker designating one of the four corners of the end zone in American football.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A rigid prosthesis for the lower leg.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pylon"],
    },
  ],
  puree: [
    {
      word: "puree",
      phonetic: "/pjʊəˈɹeɪ/",
      phonetics: [{ text: "/pjʊəˈɹeɪ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A food that has been ground or crushed into a thick liquid.",
              synonyms: [],
              antonyms: [],
              example: "Tomato sauce is generally a puree.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To crush or grind food into a puree.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/puree"],
    },
    {
      word: "puree",
      phonetic: "/ˈpʊəɹiː/",
      phonetics: [{ text: "/ˈpʊəɹiː/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A type of unleavened bread from India and Pakistan.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/puree"],
    },
  ],
  caret: [
    {
      word: "caret",
      phonetic: "/ˈkæɹ.ət/",
      phonetics: [
        { text: "/ˈkæɹ.ət/", audio: "" },
        { text: "/ˈkæɹ.ət/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A mark ⟨ ‸ ⟩ used by writers and proofreaders to indicate that something is to be inserted at that point.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An indicator, often a blinking line or bar, indicating where the next insertion or other edit will take place. Also called a cursor.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A circumflex, ⟨ ＾ ⟩.", synonyms: [], antonyms: [] },
            { definition: "A háček, ⟨ ˇ ⟩.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/caret"],
    },
    {
      word: "caret",
      phonetic: "/ˈkæɹ.ət/",
      phonetics: [
        { text: "/ˈkæɹ.ət/", audio: "" },
        { text: "/ˈkæɹ.ət/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A kind of turtle, the hawksbill (Eretmochelys imbricata).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/caret"],
    },
  ],
  temps: [
    {
      word: "temps",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A temporary employee, usually in an office.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A temporary storage location.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To work as a temporary employee.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/temp",
        "https://en.wiktionary.org/wiki/temps",
      ],
    },
  ],
  newel: [
    {
      word: "newel",
      phonetic: "/ˈnjuː.əl/",
      phonetics: [
        { text: "/ˈnjuː.əl/", audio: "" },
        {
          text: "/ˈnju.əl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/newel-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=5610569",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A central pillar around which a staircase spirals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sturdy pillar at the top or bottom of a flight of stairs, supporting the handrail.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["spindle"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/newel"],
    },
    {
      word: "newel",
      phonetic: "/ˈnjuː.əl/",
      phonetics: [
        { text: "/ˈnjuː.əl/", audio: "" },
        {
          text: "/ˈnju.əl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/newel-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=5610569",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A novelty; a new thing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/newel"],
    },
  ],
  yawns: [
    {
      word: "yawns",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The action of yawning; opening the mouth widely and taking a long, rather deep breath, often because one is tired or bored.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A particularly boring event.",
              synonyms: [],
              antonyms: [],
              example:
                "The slideshow we sat through was such a yawn. I was glad when it finished.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To open the mouth widely and take a long, rather deep breath, often because one is tired or bored, and sometimes accompanied by pandiculation.",
              synonyms: [],
              antonyms: [],
              example:
                "I could see my students yawning, so I knew the lesson was boring them.",
            },
            { definition: "To say while yawning.", synonyms: [], antonyms: [] },
            {
              definition: "To present a wide opening.",
              synonyms: [],
              antonyms: [],
              example: "Death yawned before us, and I hit the brakes.",
            },
            {
              definition:
                "To open the mouth, or to gape, through surprise or bewilderment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To be eager; to desire to swallow anything; to express desire by yawning.",
              synonyms: [],
              antonyms: [],
              example: "to yawn for fat livings",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/yawn",
        "https://en.wiktionary.org/wiki/yawns",
      ],
    },
  ],
  seedy: [
    {
      word: "seedy",
      phonetic: "/ˈsiːdi/",
      phonetics: [{ text: "/ˈsiːdi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Full of seeds.",
              synonyms: [],
              antonyms: [],
              example:
                "Pomegranates are as seedy as any fruit you are likely to see.",
            },
            {
              definition: "Disreputable, run-down.",
              synonyms: ["sleazy"],
              antonyms: [],
            },
            {
              definition: "Untidy; unkempt.",
              synonyms: [],
              antonyms: [],
              example:
                "His seedy, dirt-smudged visage caused her to look at him askance.",
            },
            {
              definition: "Infirm; unwell; gone to seed.",
              synonyms: [],
              antonyms: [],
              example:
                "With her aching back and pronounced limp, she was feeling particularly seedy today.",
            },
            {
              definition: "Suffering the effects of a hangover.",
              synonyms: [],
              antonyms: [],
              example:
                "After last night’s party we were all feeling pretty seedy.",
            },
            {
              definition: "Having the flavour of seeds.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Having a peculiar flavour supposed to be derived from the weeds growing among the vines; said of certain kinds of French brandy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["sleazy"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/seedy"],
    },
  ],
  treed: [
    {
      word: "treed",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To chase (an animal or person) up a tree.",
              synonyms: [],
              antonyms: [],
              example: "The dog treed the cat.",
            },
            {
              definition: "To place in a tree.",
              synonyms: [],
              antonyms: [],
              example:
                "Black bears can tree their cubs for protection, but grizzly bears cannot.",
            },
            {
              definition:
                "To place upon a tree; to fit with a tree; to stretch upon a tree.",
              synonyms: [],
              antonyms: [],
              example: "to tree a boot",
            },
            {
              definition: "To take refuge in a tree.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Planted or covered with trees.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["untreed"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tree",
        "https://en.wiktionary.org/wiki/treed",
      ],
    },
  ],
  coups: [
    {
      word: "coups",
      phonetic: "/kuːz/",
      phonetics: [{ text: "/kuːz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A quick, brilliant, and highly successful act.",
              synonyms: ["triumph"],
              antonyms: [],
            },
            {
              definition:
                "(of Native Americans) A blow against an enemy delivered in a way that shows bravery.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A coup d'état.",
              synonyms: ["putsch"],
              antonyms: [],
            },
            {
              definition: "(by extension) A takeover of one group by another.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A single roll of the wheel at roulette, or a deal in rouge et noir.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of various named strategies employed by the declarer to win more tricks, such as the Bath coup.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["putsch", "triumph"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To make a coup.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/coup",
        "https://en.wiktionary.org/wiki/coups",
      ],
    },
  ],
  rangy: [
    {
      word: "rangy",
      phonetic: "/ˈɹeɪndʒi/",
      phonetics: [{ text: "/ˈɹeɪndʒi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Slender and long of limb; lanky",
              synonyms: [],
              antonyms: [],
              example: "For an accountant, Robert had a rangy appearance.",
            },
            {
              definition: "Prone to roaming around.",
              synonyms: [],
              antonyms: [],
              example: "rangy cattle",
            },
            {
              definition:
                "Having or permitting range or scope; roomy; commodious.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "gangling",
            "gangly",
            "lanky",
            "leggy",
            "comfortable",
            "commodious",
            "convenient",
            "expansive",
            "roomy",
            "spacious",
            "free-roaming",
            "nomadic",
            "roving",
            "wandersome",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rangy"],
    },
  ],
  brads: [
    {
      word: "brads",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A thin, small nail, with a slight projection at the top on one side instead of a head, or occasionally with a small domed head, similar to that of an escutcheon pin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(elementary school usage) A paper fastener, a fastening device formed of thin, soft metal, such as shim brass, with a round head and a flat, split shank, which is spread after insertion in a hole in a stack of pages, in much the same way as a cotter pin or a split rivet.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/brad",
        "https://en.wiktionary.org/wiki/brads",
      ],
    },
  ],
  mangy: [
    {
      word: "mangy",
      phonetic: "/ˈmeɪndʒi/",
      phonetics: [
        {
          text: "/ˈmeɪndʒi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mangy-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=83944365",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Afflicted with mange.", synonyms: [], antonyms: [] },
            {
              definition:
                "(by extension) Worn and squalid-looking; bedraggled or decrepit.",
              synonyms: ["decrepit", "scruffy", "shabby"],
              antonyms: [],
              example: "We stayed in a really mangy hotel in New York.",
            },
          ],
          synonyms: ["decrepit", "scruffy", "shabby"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mangy"],
    },
  ],
  loner: [
    {
      word: "loner",
      phonetic: "/ˈloʊnɚ/",
      phonetics: [{ text: "/ˈloʊnɚ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One who is alone, lacking or avoiding the company of others.",
              synonyms: ["solitary"],
              antonyms: [],
              example: "I actually enjoy being a loner.",
            },
          ],
          synonyms: ["solitary"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/loner"],
    },
  ],
  circa: [
    {
      word: "circa",
      phonetic: "/ˈsɜːkə/",
      phonetics: [
        { text: "/ˈsɜːkə/", audio: "" },
        { text: "/ˈsɝkə/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "preposition",
          definitions: [
            {
              definition:
                "Approximately, about, around (typically in relation to time)",
              synonyms: [],
              antonyms: [],
              example: "Julius Caesar visited this area circa 50 BC.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/circa"],
    },
  ],
  tibia: [
    {
      word: "tibia",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The inner and usually the larger of the two bones of the leg or hind limb below the knee, the shinbone",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The second segment from the end of an insect's leg, between the femur and tarsus.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The third segment from the end of an arachnid's leg, between the patella and metatarsus.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A musical instrument of the flute kind, originally made of the leg bone of an animal.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bone flute", "shin", "shin bone", "shinbone"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tibia"],
    },
  ],
  afoul: [
    {
      word: "afoul",
      phonetic: "/əˈfaʊl/",
      phonetics: [{ text: "/əˈfaʊl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition:
                "(principally nautical) In a state of collision or entanglement.",
              synonyms: [],
              antonyms: [],
              example: "The ships’ lines and sails were all afoul.",
            },
            {
              definition:
                "(with of) In a state of entanglement or conflict (with).",
              synonyms: [],
              antonyms: [],
              example: "He had a knack for running afoul of the law.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/afoul"],
    },
  ],
  mommy: [
    {
      word: "mommy",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mommy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1235977",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(usually childish) Mother.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["ma", "mama", "mamma", "mom", "momma", "mum", "mummy"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To treat someone like a mother would; to mother someone.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(chiefly in the superlative) Characteristic of a mother; motherly.",
              synonyms: [],
              antonyms: [],
              example: "She is the mommiest of mommies.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mommy"],
    },
  ],
  titer: [
    {
      word: "titer",
      phonetic: "/ˈtaɪ.tɚ/",
      phonetics: [{ text: "/ˈtaɪ.tɚ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The concentration of a substance as determined by titration.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To measure a concentration, especially by means of titration",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["titrate"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/titer"],
    },
  ],
  carne: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  kooky: [
    {
      word: "kooky",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/kooky-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79268127",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈkuːki/", audio: "" },
        { text: "/ˈkuki/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Eccentric, strange, or foolish; crazy or insane; kookish.",
              synonyms: [],
              antonyms: [],
              example:
                "His kooky behavior seemed charming at first, but soon got on our nerves.",
            },
            {
              definition:
                "Behaving like a kook (a person with poor style or skill); kook-like.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bonkers", "kookish", "nuts", "outlandish", "wacky"],
          antonyms: [
            "conventional",
            "mainstream",
            "normal",
            "ordinary",
            "straight",
            "uneccentric",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/kooky"],
    },
  ],
  motes: [
    {
      word: "motes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A small particle; a speck.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A meeting for discussion.",
              synonyms: [],
              antonyms: [],
              example: "a wardmote in the city of London",
            },
            {
              definition:
                "A body of persons who meet for discussion, especially about the management of affairs.",
              synonyms: [],
              antonyms: [],
              example: "a folk mote",
            },
            {
              definition: "A place of meeting for discussion.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A tiny computer for remote sensing; a component element of smartdust.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mote",
        "https://en.wiktionary.org/wiki/motes",
      ],
    },
  ],
  amity: [
    {
      word: "amity",
      phonetic: "/ˈæ.mɪ.ti/",
      phonetics: [{ text: "/ˈæ.mɪ.ti/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Friendship. The cooperative and supportive relationship between people, or animals. In this sense, the term connotes a relationship which involves mutual knowledge, esteem, affection, and respect along with a degree of rendering service to friends in times of need or crisis.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Mutual understanding and a peaceful relationship, especially between nations; peace; accord.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["friendliness", "friendship"],
          antonyms: ["enemyship", "enmity", "hostility"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/amity"],
    },
  ],
  suave: [
    {
      word: "suave",
      phonetic: "/swɑːv/",
      phonetics: [
        {
          text: "/swɑːv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/suave-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=85958009",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/sweɪv/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Sweet talk.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Charming, confident and elegant.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/suave"],
    },
  ],
  hippo: [
    {
      word: "hippo",
      phonetic: "/ˈhɪpəʊ/",
      phonetics: [
        { text: "/ˈhɪpəʊ/", audio: "" },
        { text: "/ˈhɪpoʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A large, semi-aquatic, herbivorous (plant-eating) African mammal (Hippopotamus amphibius)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["hippo", "river horse", "hippo", "river horse"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hippo",
        "https://en.wiktionary.org/wiki/hippopotamus",
      ],
    },
  ],
  curvy: [
    {
      word: "curvy",
      phonetic: "/ˈkɜːvi/",
      phonetics: [
        { text: "/ˈkɜːvi/", audio: "" },
        { text: "/ˈkɝvi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Having curves.", synonyms: [], antonyms: [] },
            {
              definition: "Buxom or curvaceous.",
              synonyms: [],
              antonyms: [],
              example: "I love this woman and her curvy body.",
            },
          ],
          synonyms: [
            "Rubenesque",
            "zaftig",
            "curved",
            "sinuous",
            "tortuous",
            "wavy",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/curvy"],
    },
  ],
  samba: [
    {
      word: "samba",
      phonetic: "/ˈsɑmbə/",
      phonetics: [{ text: "/ˈsɑmbə/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A Brazilian ballroom dance or dance style.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A Brazilian musical genre, to which the aforementioned dance is danced, which has its roots in West Africa via the slave trade.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To dance the samba.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/samba"],
    },
  ],
  newsy: [
    {
      word: "newsy",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A distributor of news; a newsagent.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A journalist.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Containing lots of news; informative.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Chatty, gossipy.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/newsy"],
    },
  ],
  anise: [
    {
      word: "anise",
      phonetic: "/əˈnis/",
      phonetics: [
        { text: "/əˈnis/", audio: "" },
        { text: "/ˈæn.ɪs/", audio: "" },
        { text: "/æˈnis/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An umbelliferous plant (Pimpinella anisum) growing naturally in Egypt, and cultivated in Spain, Malta, etc., for its carminative and aromatic seeds, which are used as a spice. It has a licorice scent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                '(often qualified as "sweet anise" or "wild anise") Fennel, Foeniculum vulgare.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/anise"],
    },
  ],
  imams: [
    {
      word: "imams",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(usually capitalized) A Shi'ite Muslim leader.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One who leads the salat prayers in a mosque.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/imam",
        "https://en.wiktionary.org/wiki/imams",
      ],
    },
  ],
  tulle: [
    {
      word: "tulle",
      phonetic: "/tuːl/",
      phonetics: [{ text: "/tuːl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A kind of silk lace or light netting, used for clothing, veils, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tulle"],
    },
  ],
  aways: [
    {
      word: "aways",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Alternative spelling of a ways",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/aways"],
    },
  ],
  liven: [
    {
      word: "liven",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cause to be more lively, or to become more lively.",
              synonyms: ["liven up"],
              antonyms: [],
              example: "Let's liven up this party!",
            },
          ],
          synonyms: ["liven up"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/liven"],
    },
    {
      word: "liven",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To be alive; to have life.",
              synonyms: [],
              antonyms: [],
              example: "He's not expected to live for more than a few months.",
            },
            {
              definition:
                "To have permanent residence somewhere, to inhabit, to reside.",
              synonyms: [],
              antonyms: [],
              example:
                "I live at 2a Acacia Avenue.  He lives in LA, but he's staying here over the summer.",
            },
            {
              definition: "To survive; to persevere; to continue.",
              synonyms: [],
              antonyms: [],
              example: "Her memory lives in that song.",
            },
            {
              definition: "To cope.",
              synonyms: [],
              antonyms: [],
              example:
                "You'll just have to live with it!  I can't live in a world without you.",
            },
            {
              definition: "To pass life in a specified manner.",
              synonyms: [],
              antonyms: [],
              example:
                "It is difficult to live in poverty.   And they lived happily ever after.",
            },
            {
              definition:
                "To spend, as one's life; to pass; to maintain; to continue in, constantly or habitually.",
              synonyms: [],
              antonyms: [],
              example: "To live an idle or a useful life.",
            },
            {
              definition:
                "To act habitually in conformity with; to practice; to exemplify in one's way of life.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To outlast danger; to float (said of a ship, boat, etc).",
              synonyms: [],
              antonyms: [],
              example: "No ship could live in such a storm.",
            },
            {
              definition:
                '(followed by "on" or "upon") To maintain or support one\'s existence; to provide for oneself; to feed; to subsist.',
              synonyms: [],
              antonyms: [],
              example:
                "It is hard to live on the minimum wage.   They lived on stale bread.",
            },
            {
              definition:
                "To make the most of life; to experience a full, rich life.",
              synonyms: [],
              antonyms: [],
              example:
                "I'm sick of spending every day studying at home: I want to go out there and live!",
            },
          ],
          synonyms: ["dwell", "go on", "last", "remain"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/live",
        "https://en.wiktionary.org/wiki/liven",
      ],
    },
  ],
  hallo: [
    {
      word: "hallo",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: 'The cry "hallo!"', synonyms: [], antonyms: [] },
            {
              definition: "A shout of exultation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To shout, or to call with a loud voice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: 'To chase while shouting "hallo!"',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: 'To cry "hallo" (to someone).',
              synonyms: [],
              antonyms: [],
            },
            { definition: "To shout (something).", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            { definition: "A cry of surprise.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition:
                "A greeting (salutation) said when meeting someone or acknowledging someone’s arrival or presence.",
              synonyms: [],
              antonyms: [],
              example: "Hello, everyone.",
            },
            {
              definition: "A greeting used when answering the telephone.",
              synonyms: [],
              antonyms: [],
              example: "Hello? How may I help you?",
            },
            {
              definition:
                "A call for response if it is not clear if anyone is present or listening, or if a telephone conversation may have been disconnected.",
              synonyms: [],
              antonyms: [],
              example: "Hello? Is anyone there?",
            },
            {
              definition:
                "Used sarcastically to imply that the person addressed or referred to has done something the speaker or writer considers to be foolish.",
              synonyms: [],
              antonyms: [],
              example:
                "You just tried to start your car with your cell phone. Hello?",
            },
            {
              definition: "An expression of puzzlement or discovery.",
              synonyms: [],
              antonyms: [],
              example: "Hello! What’s going on here?",
            },
          ],
          synonyms: [],
          antonyms: ["bye", "goodbye"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hallo",
        "https://en.wiktionary.org/wiki/hello",
      ],
    },
  ],
  wales: [
    {
      word: "wales",
      phonetic: "/weɪlz/",
      phonetics: [
        {
          text: "/weɪlz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wales-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=4439612",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A ridge or low barrier.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A raised rib in knit goods or fabric, especially corduroy. (As opposed to course).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The texture of a piece of fabric.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A horizontal ridge or ledge on the outside planking of a wooden ship. (See gunwale, chainwale)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A horizontal timber used for supporting or retaining earth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A timber bolted to a row of piles to secure them together and in position.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A ridge on the outside of a horse collar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A ridge or streak produced on skin by a cane or whip.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To strike the skin in such a way as to produce a wale or welt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To give a surface a texture of wales or welts.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Something selected as being the best, preference; choice.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To choose, select.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wale",
        "https://en.wiktionary.org/wiki/wales",
      ],
    },
  ],
  opted: [
    {
      word: "opted",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To choose; select.",
              synonyms: [],
              antonyms: [],
              example: "He opted not to go.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/opt",
        "https://en.wiktionary.org/wiki/opted",
      ],
    },
  ],
  canto: [
    {
      word: "canto",
      phonetic: "/ˈkæntəʊ/",
      phonetics: [
        { text: "/ˈkæntəʊ/", audio: "" },
        { text: "/ˈkæntoʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One of the chief divisions of a long poem; a book.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The treble or leading melody.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/canto"],
    },
  ],
  idyll: [
    {
      word: "idyll",
      phonetic: "/ˈɪdəl/",
      phonetics: [
        { text: "/ˈɪdəl/", audio: "" },
        { text: "/ˈaɪdəl/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any poem or short written piece composed in the style of Theocritus' short pastoral poems, the Idylls.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An episode or series of events or circumstances of pastoral or rural simplicity, fit for an idyll; a carefree or lighthearted experience.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A composition, usually instrumental, of a pastoral or sentimental character, e.g. Siegfried Idyll by Richard Wagner.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/idyll"],
    },
  ],
  bodes: [
    {
      word: "bodes",
      phonetic: "/bəʊdz/",
      phonetics: [
        { text: "/bəʊdz/", audio: "" },
        { text: "/boʊdz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An omen; a foreshadowing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To indicate by signs, as future events; to be an omen of; to portend or foretell.",
              synonyms: ["foreshow", "portend", "presage"],
              antonyms: [],
            },
            {
              definition:
                '(followed by "well", "ill", "no good", etc.) To betoken or augur something good or bad that will happen in the future.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["foreshow", "portend", "presage"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A bid; an offer.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A messenger; a herald.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A stop; a halting; delay.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bode",
        "https://en.wiktionary.org/wiki/bodes",
      ],
    },
  ],
  curio: [
    {
      word: "curio",
      phonetic: "/ˈkjɔːɹiˌəʊ̯/",
      phonetics: [{ text: "/ˈkjɔːɹiˌəʊ̯/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A strange and interesting object; something that evokes curiosity.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/curio"],
    },
  ],
  wrack: [
    {
      word: "wrack",
      phonetic: "/ɹæk/",
      phonetics: [{ text: "/ɹæk/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Vengeance; revenge; persecution; punishment; consequence; trouble.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(except in dialects) Ruin; destruction.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The remains; a wreck.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To execute vengeance; avenge.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To worry; tease; torment.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/wrack"],
    },
    {
      word: "wrack",
      phonetic: "/ɹæk/",
      phonetics: [{ text: "/ɹæk/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To place in or hang on a rack.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To torture (someone) on the rack.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause (someone) to suffer pain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To stretch or strain; to harass, or oppress by extortion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To put the balls into the triangular rack and set them in place on the table.",
              synonyms: ["rack up"],
              antonyms: [],
            },
            {
              definition: "To strike a male in the testicles.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To (manually) load (a round of ammunition) from the magazine or belt into firing position in an automatic or semiautomatic firearm.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To move the slide bar on a shotgun in order to chamber the next round.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To wash (metals, ore, etc.) on a rack.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To bind together, as two ropes, with cross turns of yarn, marline, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(structural engineering) Tending to shear a structure (that is, force it to move in different directions at different points).",
              synonyms: ["shear"],
              antonyms: [],
              example: "Post-and-lintel construction racks easily.",
            },
          ],
          synonyms: ["rack up", "shear"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To stretch a person's joints.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To drive; move; go forward rapidly; stir",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fly, as vapour or broken clouds",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To clarify, and thereby deter further fermentation of, beer, wine or cider by draining or siphoning it from the dregs.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(of a horse) To amble fast, causing a rocking or swaying motion of the body; to pace.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Remnant from a shipwreck as washed ashore, or the right to claim such items.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any marine vegetation cast up on shore, especially seaweed of the genus Fucus.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Weeds, vegetation or rubbish floating on a river or pond.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A high flying cloud; a rack.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To wreck, especially a ship (usually in passive).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rack",
        "https://en.wiktionary.org/wiki/wrack",
      ],
    },
  ],
  hiker: [
    {
      word: "hiker",
      phonetic: "/ˈhaɪkɚ/",
      phonetics: [{ text: "/ˈhaɪkɚ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One who hikes, especially frequently.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hiker"],
    },
  ],
  chive: [
    {
      word: "chive",
      phonetic: "/tʃaɪv/",
      phonetics: [
        {
          text: "/tʃaɪv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chive-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1779974",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A perennial plant, Allium schoenoprasum, related to the onion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in the plural) The leaves of this plant used as a herb.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The style and stigma of a flower, especially saffron.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chive"],
    },
    {
      word: "chive",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(thieves' cant) A knife.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(thieves' cant) A file.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(thieves' cant) A saw.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(thieves' cant) To stab.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(thieves' cant) To cut.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chive"],
    },
  ],
  yokel: [
    {
      word: "yokel",
      phonetic: "/ˈjəʊ.kəl/",
      phonetics: [
        { text: "/ˈjəʊ.kəl/", audio: "" },
        { text: "/ˈjoʊ.kəl/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An unsophisticated person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person of rural background.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "booby",
            "boor",
            "bumpkin",
            "hick",
            "hillbilly",
            "joskin",
            "lubber",
            "nincompoop",
            "simpleton",
            "yahoo",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/yokel"],
    },
  ],
  dotty: [
    {
      word: "dotty",
      phonetic: "/ˈdɒti/",
      phonetics: [{ text: "/ˈdɒti/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Mildly insane or eccentric; often, senile.",
              synonyms: [],
              antonyms: [],
              example:
                "My nan has got dottier and dottier since passing the age of eighty.",
            },
            {
              definition: "Having an unsteady gait.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dotty"],
    },
    {
      word: "dotty",
      phonetic: "/ˈdɒti/",
      phonetics: [{ text: "/ˈdɒti/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A shotgun.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having many dots.",
              synonyms: [],
              antonyms: [],
              example: "Look at the dotty pattern on that cheetah's fur.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dotty"],
    },
  ],
  demur: [
    {
      word: "demur",
      phonetic: "/dɪˈmɜː/",
      phonetics: [
        { text: "/dɪˈmɜː/", audio: "" },
        { text: "/dɪˈmɝ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Stop; pause; hesitation as to proceeding; suspense of decision or action; scruple.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To linger; to stay; to tarry",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To delay; to pause; to suspend proceedings or judgment in view of a doubt or difficulty; to hesitate; to put off the determination or conclusion of an affair.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To scruple or object; to take exception; to oppose; to balk",
              synonyms: [],
              antonyms: [],
              example: "I demur to that statement.",
            },
            {
              definition: "To interpose a demurrer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To suspend judgment concerning; to doubt of or hesitate about",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause delay to; to put off",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/demur"],
    },
  ],
  cusps: [
    {
      word: "cusps",
      phonetic: "/kʌsps/",
      phonetics: [{ text: "/kʌsps/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A sharp point or pointed end.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An important moment when a decision is made that will determine future events.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A point of a curve where the curve is continuous but has no derivative, but such that it has a derivative at every nearby point.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A point made by the intersection of two curved lines or curved structures, a common motif in Gothic architecture.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A boundary between zodiacal signs and houses.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of the pointed parts of a canine tooth or molar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A flap of a valve of a heart or blood vessel.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["ord"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cusp",
        "https://en.wiktionary.org/wiki/cusps",
      ],
    },
  ],
  specs: [
    {
      word: "specs",
      phonetic: "/spɛks/",
      phonetics: [{ text: "/spɛks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Two similar or identical things taken together; often followed by of.",
              synonyms: [],
              antonyms: [],
              example:
                "I couldn't decide which of the pair of designer shirts I preferred, so I bought the pair.",
            },
            {
              definition:
                "Two people in a relationship, partnership or friendship.",
              synonyms: [],
              antonyms: [],
              example: "Spouses should make a great pair.",
            },
            {
              definition:
                "Used with binary nouns (often in the plural to indicate multiple instances, since such nouns are plural only, except in some technical contexts)",
              synonyms: [],
              antonyms: [],
              example:
                "a pair of scissors; two pairs of spectacles; several pairs of jeans",
            },
            {
              definition:
                "A couple of working animals attached to work together, as by a yoke.",
              synonyms: [],
              antonyms: [],
              example:
                "A pair is harder to drive than two mounts with separate riders.",
            },
            {
              definition:
                "A poker hand that contains two cards of identical rank, which cannot also count as a better hand.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A score of zero runs (a duck) in both innings of a two-innings match.",
              synonyms: ["pair of spectacles", "spectacles"],
              antonyms: [],
            },
            {
              definition: "A double play, two outs recorded in one play.",
              synonyms: [],
              antonyms: [],
              example: "They turned a pair to end the fifth.",
            },
            {
              definition:
                "A doubleheader, two games played on the same day between the same teams",
              synonyms: [],
              antonyms: [],
              example: "The Pirates took a pair from the Phillies.",
            },
            {
              definition: "A boat for two sweep rowers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A pair of breasts",
              synonyms: [],
              antonyms: [],
              example: "She's got a gorgeous pair.",
            },
            {
              definition:
                "The exclusion of one member of a parliamentary party from a vote, if a member of the other party is absent for important personal reasons.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Two members of opposite parties or opinion, as in a parliamentary body, who mutually agree not to vote on a given question, or on issues of a party nature during a specified time.",
              synonyms: [],
              antonyms: [],
              example: "There were two pairs on the final vote.",
            },
            {
              definition:
                "A number of things resembling one another, or belonging together; a set.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(kinematics) In a mechanism, two elements, or bodies, which are so applied to each other as to mutually constrain relative motion; named in accordance with the motion it permits, as in turning pair, sliding pair, twisting pair.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["pair of spectacles", "spectacles"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A special place (for hiding or viewing)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A spectacular mark (catch) in Australian rules football.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A reduction in consumer cost (usually for a limited time) for items or services rendered.",
              synonyms: [],
              antonyms: [],
              example: "We're running a special on turkey for Thanksgiving.",
            },
            {
              definition:
                "One of a rotation of meals systematically offered for a lower price at a restaurant.",
              synonyms: [],
              antonyms: [],
              example: "Today's special is our tuna melt on rye.",
            },
            {
              definition: "Unusual or exceptional episode of a series.",
              synonyms: [],
              antonyms: [],
              example: "Did you see the Christmas special?",
            },
            { definition: "A special constable.", synonyms: [], antonyms: [] },
            {
              definition:
                "Anything that is not according to normal practice, plan, or schedule, as an unscheduled run of transportation that is normally scheduled.",
              synonyms: [],
              antonyms: [],
              example:
                "Thousands came to see the special that carried the President's coffin.",
            },
            {
              definition:
                "Any unlicensed medicine produced or obtained for a specific individual patient.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A correspondent; a journalist sent to the scene of an event to report back.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A dispatch sent back by a special correspondent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A light that illuminates a specific person or thing on the stage.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Someone who is an expert in, or devoted to, some specific branch of study or research.",
              synonyms: ["aficionado", "connoisseur", "enthusiast"],
              antonyms: [],
            },
            {
              definition:
                "A physician whose practice is limited to a particular branch of medicine or surgery.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of several non-commissioned ranks corresponding to that of corporal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An organism that is specialized for a particular environment.",
              synonyms: [],
              antonyms: [],
              example:
                "The tree Lepidothamnus laxifolius is a high alpine specialist found in high-altitude bog communities and in scrub.",
            },
          ],
          synonyms: ["aficionado", "connoisseur", "enthusiast"],
          antonyms: ["generalist"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act or process of specializing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The area in which someone specializes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The adaptation of an organism to a specific environment, or adaptation of an organ to a particular function.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A proof, axiom, problem, or definition whose cases are completely covered by another, broader concept.",
              synonyms: [],
              antonyms: [],
              example:
                "A triangle is a specialization of a polygon to one with exactly three sides.",
            },
          ],
          synonyms: ["particularization"],
          antonyms: ["abstraction", "generalization"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An explicit set of requirements to be satisfied by a material, product, or service.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An act of specifying.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A person or thing that specifies.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A component of a phrase that is non-recursive and not found as a sister of the head of the phrase, but rather as a daughter of the maximal projection of the phrase.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A pair of lenses set in a frame worn on the nose and ears in order to correct deficiencies in eyesight or to ornament the face.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "eyeglasses",
            "glasses",
            "pair of eyeglasses",
            "pair of glasses",
            "pair of specs",
            "pair of spectacles",
            "specs",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The process of thinking or meditating on a subject.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The act or process of reasoning a priori from premises given or assumed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A conclusion to which the mind comes by speculating; mere theory; notion; conjecture.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An investment involving higher-than-normal risk in order to obtain a higher-than-normal return.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The act or practice of buying land, goods, shares, etc., in expectation of selling at a higher price, or of selling with the expectation of repurchasing at a lower price; a trading on anticipated fluctuations in price, as distinguished from trading in which the profit expected is the difference between the retail and wholesale prices, or the difference of price in different markets.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Examination by the eye; view.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Power of sight.", synonyms: [], antonyms: [] },
            {
              definition:
                "A card game in which the players buy from one another trumps or whole hands, upon a chance of getting the highest trump dealt, which entitles the holder to the pool of stakes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The process of anticipating which branch of code will be chosen and executing it in advance.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pair",
        "https://en.wiktionary.org/wiki/spec",
        "https://en.wiktionary.org/wiki/special",
        "https://en.wiktionary.org/wiki/specialist",
        "https://en.wiktionary.org/wiki/specialization",
        "https://en.wiktionary.org/wiki/specification",
        "https://en.wiktionary.org/wiki/specifier",
        "https://en.wiktionary.org/wiki/specs",
        "https://en.wiktionary.org/wiki/spectacles",
        "https://en.wiktionary.org/wiki/speculation",
      ],
    },
  ],
  quads: [
    {
      word: "quads",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/quads-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79323837",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Four shots of espresso.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A kind of round-robin tournament between four players, where each participant plays every other participant once.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The Bible, Book of Mormon, Doctrine and Covenants, and Pearl of Great Price bound in a single volume.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A poster, measuring forty by thirty inches, advertising a cinematic film release.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A quadrangle (courtyard).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A quadruplet (infant).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A quadrilateral.", synonyms: [], antonyms: [] },
            {
              definition: "The quadriceps muscle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A quadriplegic person.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A quadrupel beer.", synonyms: [], antonyms: [] },
            { definition: "A quadcopter.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A quad bike (from quadricycle)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Abbreviation of quadrillion BTU. (1015 BTU)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A blank metal block used to fill short lines of type.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A joke used to fill long days of setting type.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(phototypesetting and digital typesetting) A keyboard command which aligns text with the left or right margin, or centred between them. In combination, as quad left, quad right or quad centre.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["em space", "quadrat"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(exercise) quadriceps (thigh muscles)",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Four of a kind", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/quad",
        "https://en.wiktionary.org/wiki/quads",
      ],
    },
  ],
  laity: [
    {
      word: "laity",
      phonetic: "/ˈleɪ.ə.ti/",
      phonetics: [
        { text: "/ˈleɪ.ə.ti/", audio: "" },
        { text: "/ˈleɪ.ə.ti/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "People of a church who are not ordained clergy or clerics.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The common man or woman.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The unlearned, untrained or ignorant.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/laity"],
    },
  ],
  toner: [
    {
      word: "toner",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/toner-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=94709458",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈtoʊnɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Powder used in laser printers and photocopiers to form the text and images on the printed paper.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Cosmetic lotion designed to cleanse the skin and shrink pores, usually used on the face.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A musician, particularly one that plays a wind instrument.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hair product used to reduce brassiness and increase shine in bleached and dyed hair.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/toner"],
    },
  ],
  decry: [
    {
      word: "decry",
      phonetic: "/dɪˈkɹaɪ/",
      phonetics: [{ text: "/dɪˈkɹaɪ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To denounce as harmful.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To blame for ills.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/decry"],
    },
  ],
  writs: [
    {
      word: "writs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A written order, issued by a court, ordering someone to do (or stop doing) something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Authority, power to enforce compliance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "That which is written; writing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["claim form"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/writ",
        "https://en.wiktionary.org/wiki/writs",
      ],
    },
  ],
  saute: [
    {
      word: "saute",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cook (food) using a small amount of fat in an open pan over a relatively high heat, allowing the food to brown and form a crust stopping it from sticking to the pan as it cooks.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/saut%C3%A9",
        "https://en.wiktionary.org/wiki/saute",
      ],
    },
  ],
  clack: [
    {
      word: "clack",
      phonetic: "/klæk/",
      phonetics: [{ text: "/klæk/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An abrupt, sharp sound, especially one made by two hard objects colliding repetitively; a sound midway between a click and a clunk.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Anything that causes a clacking noise, such as the clapper of a mill, or a clack valve.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Chatter; prattle.", synonyms: [], antonyms: [] },
            { definition: "The tongue.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make a sudden, sharp noise, or succession of noises; to click.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause to make a sudden, sharp noise, or succession of noises; to click.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To chatter or babble; to utter rapidly without consideration.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cut the sheep's mark off (wool), to make the wool weigh less and thus yield less duty.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/clack"],
    },
  ],
  aught: [
    {
      word: "aught",
      phonetic: "/ɔːt/",
      phonetics: [
        { text: "/ɔːt/", audio: "" },
        {
          text: "/ɔt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/aught-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1840688",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "pronoun",
          definitions: [
            {
              definition: "Anything whatsoever, any part.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/aught"],
    },
    {
      word: "aught",
      phonetic: "/ɔːt/",
      phonetics: [
        { text: "/ɔːt/", audio: "" },
        {
          text: "/ɔt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/aught-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1840688",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Whit, the smallest part, iota.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Zero", synonyms: [], antonyms: [] },
            {
              definition:
                "The digit zero as the decade in years. For example, aught-nine for 1909 or 2009.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "At all, in any degree, in any respect.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/aught"],
    },
    {
      word: "aught",
      phonetic: "/ɔːt/",
      phonetics: [
        { text: "/ɔːt/", audio: "" },
        {
          text: "/ɔt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/aught-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1840688",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Estimation.",
              synonyms: [],
              antonyms: [],
              example: "In my aught.",
            },
            {
              definition:
                'Of importance or consequence (in the phrase "of aught").',
              synonyms: [],
              antonyms: [],
              example: "An event of aught.",
            },
            {
              definition: "Esteem, respect.",
              synonyms: [],
              antonyms: [],
              example:
                "A man of aught (a man of high esteem, an important or well-respected man).",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/aught"],
    },
    {
      word: "aught",
      phonetic: "/ɔːt/",
      phonetics: [
        { text: "/ɔːt/", audio: "" },
        {
          text: "/ɔt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/aught-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1840688",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Property; possession", synonyms: [], antonyms: [] },
            { definition: "Duty; place; office", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To own, possess", synonyms: [], antonyms: [] },
            {
              definition: "To owe, be obliged or obligated to",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Possessed of", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/aught"],
    },
    {
      word: "aught",
      phonetic: "/ɔːt/",
      phonetics: [
        { text: "/ɔːt/", audio: "" },
        {
          text: "/ɔt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/aught-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1840688",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "numeral",
          definitions: [
            {
              definition:
                "A numerical value equal to 8; the number occurring after seven and before nine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Describing a group or set with eight elements.",
              synonyms: [],
              antonyms: [],
              example: "He works eight hours a day.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/aught",
        "https://en.wiktionary.org/wiki/eight",
      ],
    },
  ],
  logos: [
    {
      word: "logos",
      phonetic: "/ˈlɒɡɒs/",
      phonetics: [
        { text: "/ˈlɒɡɒs/", audio: "" },
        { text: "/ˈloʊɡoʊs/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A form of rhetoric in which the writer or speaker uses logic as the main argument.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Alternative letter-case form of Logos",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/logos"],
    },
    {
      word: "logos",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A visual symbol or emblem that acts as a trademark or a means of identification of a company or organization.",
              synonyms: ["logotype"],
              antonyms: [],
            },
            {
              definition:
                "(by extension) An audio recording for the same purpose; a jingle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A single graphic which contains one or more separate elements.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An ensign, a badge of office, rank, or power.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["logotype"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/logo",
        "https://en.wiktionary.org/wiki/logos",
      ],
    },
  ],
  tipsy: [
    {
      word: "tipsy",
      phonetic: "/ˈtɪp.si/",
      phonetics: [
        {
          text: "/ˈtɪp.si/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tipsy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=830186",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Slightly drunk, fuddled, staggering, foolish as a result of drinking alcoholic beverages",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(metonymy) unsteady, askew",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "buzzed",
            "merry",
            "squiffy",
            "off-kilter",
            "precarious",
            "tottering",
            "wobbly",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tipsy"],
    },
  ],
  natty: [
    {
      word: "natty",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/natty-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241824",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈnæti/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Smart and fashionable.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/natty"],
    },
    {
      word: "natty",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/natty-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241824",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈnæti/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Knotty.",
              synonyms: [],
              antonyms: [],
              example: "natty dreadlocks",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/natty"],
    },
    {
      word: "natty",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/natty-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241824",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈnæti/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Someone whose muscle gains are natural and not aided by the use of steroids.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A national championship",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Natural, as opposed to steroid enhanced.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "National", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/natty"],
    },
  ],
  ducal: [
    {
      word: "ducal",
      phonetic: "/djuːkəl/",
      phonetics: [{ text: "/djuːkəl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Of or pertaining to a duke, a duchess, or the duchy or dukedom they hold.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ducal"],
    },
  ],
  bidet: [
    {
      word: "bidet",
      phonetic: "[ˈbiːdeɪ]",
      phonetics: [
        { text: "[ˈbiːdeɪ]", audio: "" },
        {
          text: "[bɨˈdeɪ]",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bidet-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=36696490",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A low-mounted plumbing fixture or type of sink intended for washing the external genitalia and the anus.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small horse formerly allowed to each trooper or dragoon for carrying his baggage.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bidet"],
    },
  ],
  bulgy: [
    {
      word: "bulgy",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having one or more bulges; bulging",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bulgy"],
    },
  ],
  metre: [
    {
      word: "metre",
      phonetic: "/ˈmiːtə/",
      phonetics: [
        {
          text: "/ˈmiːtə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/metre-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=68569011",
          license: {
            name: "BY 4.0",
            url: "https://creativecommons.org/licenses/by/4.0",
          },
        },
        {
          text: "/ˈmiːtəɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/metre-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762994",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To measure with a metering device.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To imprint a postage mark with a postage meter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To regulate the flow of or to deliver in regulated amounts (usually of fluids but sometimes of other things such as anticipation or breath).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The basic unit of length in the International System of Units (SI: Système International d'Unités), equal to the distance travelled by light in a vacuum in 1/299 792 458 seconds. The metre is equal to 39 47/127 (approximately 39.37) imperial inches.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["m"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/meter",
        "https://en.wiktionary.org/wiki/metre",
      ],
    },
    {
      word: "metre",
      phonetic: "/ˈmiːtə/",
      phonetics: [
        {
          text: "/ˈmiːtə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/metre-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=68569011",
          license: {
            name: "BY 4.0",
            url: "https://creativecommons.org/licenses/by/4.0",
          },
        },
        {
          text: "/ˈmiːtəɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/metre-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1762994",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The rhythm or measure in verse and musical composition.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To put into metrical form.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/metre"],
    },
  ],
  lusts: [
    {
      word: "lusts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                '(usually in the phrase "lust after") To look at or watch with a strong desire, especially of a sexual nature.',
              synonyms: [],
              antonyms: [],
              example:
                "He was lusting after the woman in the tight leather miniskirt.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lust",
        "https://en.wiktionary.org/wiki/lusts",
      ],
    },
  ],
  unary: [
    {
      word: "unary",
      phonetic: "/ˈjuːnəɹi/",
      phonetics: [{ text: "/ˈjuːnəɹi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The unary numeral system; the bijective base-1 numeral system.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Unary coding, an entropy encoding for natural numbers.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Consisting of or involving a single element or component.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(computer engineering) Of an operation, function, procedure or logic gate, taking exactly one operand, argument, parameter or input; having domain of dimension 1.",
              synonyms: [],
              antonyms: [],
              example: "Negation is a unary operation.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/unary"],
    },
  ],
  goeth: [
    {
      word: "goeth",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To move:",
              synonyms: [
                "cross",
                "draw",
                "drift",
                "fare",
                "move",
                "tread",
                "wend",
              ],
              antonyms: [
                "freeze",
                "halt",
                "remain",
                "stand still",
                "stay",
                "stop",
              ],
            },
            {
              definition:
                "(chiefly of a machine) To work or function (properly); to move or perform (as required).",
              synonyms: ["function", "operate", "work"],
              antonyms: [],
              example: "The engine just won't go anymore.",
            },
            {
              definition: "To start; to begin (an action or process).",
              synonyms: [],
              antonyms: [],
              example: "Get ready, get set, go!",
            },
            {
              definition: "To take a turn, especially in a game.",
              synonyms: ["make one's move", "move", "take one’s turn"],
              antonyms: [],
              example: "It’s your turn; go.",
            },
            {
              definition: "To attend.",
              synonyms: [],
              antonyms: [],
              example: "I go to school at the schoolhouse.",
            },
            { definition: "To proceed:", synonyms: [], antonyms: [] },
            {
              definition: "To follow or travel along (a path):",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To extend (from one point in time or space to another).",
              synonyms: [],
              antonyms: [],
              example: "This property goes all the way to the state line.",
            },
            {
              definition: "To lead (to a place); to give access to.",
              synonyms: [],
              antonyms: [],
              example: "Does this road go to Fort Smith?",
            },
            {
              definition:
                "To become. (The adjective that follows usually describes a negative state.)",
              synonyms: ["become", "change into", "turn"],
              antonyms: [],
              example:
                "After failing as a criminal, he decided to go straight.",
            },
            {
              definition:
                "To assume the obligation or function of; to be, to serve as.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To continuously or habitually be in a state.",
              synonyms: [],
              antonyms: [],
              example: "I don't want my children to go hungry.",
            },
            {
              definition: "To come to (a certain condition or state).",
              synonyms: [],
              antonyms: [],
              example:
                "They went into debt, she goes to sleep around 10 o'clock.",
            },
            {
              definition:
                "To change (from one value to another) in the meaning of wend.",
              synonyms: [],
              antonyms: [],
              example: "The traffic light went straight from green to red.",
            },
            {
              definition:
                "To turn out, to result; to come to (a certain result).",
              synonyms: [],
              antonyms: [],
              example: "How did your meeting with Smith go?",
            },
            {
              definition: "To tend (toward a result).",
              synonyms: [],
              antonyms: [],
              example: "These experiences go to make us stronger.",
            },
            {
              definition:
                "To contribute to a (specified) end product or result.",
              synonyms: [],
              antonyms: [],
              example:
                "qualities that go to make a lady / lip-reader / sharpshooter",
            },
            {
              definition: "To pass, to be used up:",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To die.", synonyms: [], antonyms: [] },
            {
              definition: "To be discarded.",
              synonyms: [],
              antonyms: [],
              example: "This chair has got to go.",
            },
            { definition: "To be lost or out:", synonyms: [], antonyms: [] },
            {
              definition: "To break down or apart:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be sold.",
              synonyms: [],
              antonyms: [],
              example: "Everything must go.",
            },
            {
              definition: "To be given, especially to be assigned or allotted.",
              synonyms: [],
              antonyms: [],
              example: "The award went to Steven Spielberg.",
            },
            {
              definition:
                "To survive or get by; to last or persist for a stated length of time.",
              synonyms: [],
              antonyms: [],
              example: "Can you two go twenty minutes without arguing?!",
            },
            {
              definition: "To have a certain record.",
              synonyms: [],
              antonyms: [],
              example: "The team is going five in a row.",
            },
            {
              definition: "To be authoritative, accepted, or valid:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To say (something), to make a sound:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be expressed or composed (a certain way).",
              synonyms: [],
              antonyms: [],
              example:
                "As the story goes, he got the idea for the song while sitting in traffic.",
            },
            {
              definition: "To resort (to).",
              synonyms: [],
              antonyms: [],
              example: "I'll go to court if I have to.",
            },
            {
              definition: "To apply or subject oneself to:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fit (in a place, or together with something):",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To date.",
              synonyms: ["date", "go out (with)", "see"],
              antonyms: [],
              example: "He's been going with her for two weeks.",
            },
            { definition: "To attack:", synonyms: [], antonyms: [] },
            {
              definition: "To be in general; to be usually.",
              synonyms: [],
              antonyms: [],
              example: "As sentences go, this one is pretty boring.",
            },
            {
              definition:
                "To take (a particular part or share); to participate in to the extent of.",
              synonyms: [],
              antonyms: [],
              example: "Let's go halves on this.",
            },
            {
              definition: "To yield or weigh.",
              synonyms: [],
              antonyms: [],
              example: "Those babies go five tons apiece.",
            },
            {
              definition: "To offer, bid or bet an amount; to pay.",
              synonyms: [],
              antonyms: [],
              example: "I'll go a ten-spot.",
            },
            {
              definition: "To enjoy. (Compare go for.)",
              synonyms: [],
              antonyms: [],
              example: "I could go a beer right about now.",
            },
            {
              definition: "To urinate or defecate.",
              synonyms: [],
              antonyms: [],
              example: "Have you managed to go today, Mrs. Miggins?",
            },
          ],
          synonyms: [
            "become",
            "change into",
            "turn",
            "cross",
            "draw",
            "drift",
            "fare",
            "move",
            "tread",
            "wend",
            "date",
            "go out (with)",
            "see",
            "function",
            "operate",
            "work",
            "make one's move",
            "move",
            "take one’s turn",
          ],
          antonyms: ["freeze", "halt", "remain", "stand still", "stay", "stop"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/go",
        "https://en.wiktionary.org/wiki/goeth",
      ],
    },
  ],
  baler: [
    {
      word: "baler",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A machine for creating bales, e.g., of hay or cotton.",
              synonyms: [],
              antonyms: [],
              example:
                "Farmer Jones is in straits because his baler is broken down and he's got three fields of hay ready to bale.",
            },
            {
              definition:
                "A person who creates bales, either by operating or feeding such a machine, or by creating the bales by hand.",
              synonyms: [],
              antonyms: [],
              example:
                "Hank worked as a baler for Farmer Jones part time, to make ends meet.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/baler"],
    },
  ],
  sited: [
    {
      word: "sited",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To situate or place a building.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/site",
        "https://en.wiktionary.org/wiki/sited",
      ],
    },
  ],
  shies: [
    {
      word: "shies",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "An act of throwing.", synonyms: [], antonyms: [] },
            {
              definition: "A place for throwing.",
              synonyms: [],
              antonyms: [],
              example: "coconut shy",
            },
            {
              definition: "A sudden start aside, as by a horse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In the Eton College wall game, a point scored by lifting the ball against the wall in the calx.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To avoid due to timidness or caution.",
              synonyms: [],
              antonyms: [],
              example:
                "I shy away from investment opportunities I don't understand.",
            },
            {
              definition: "To jump back in fear.",
              synonyms: [],
              antonyms: [],
              example:
                "The horse shied away from the rider, which startled him so much he shied away from the horse.",
            },
            {
              definition: "To throw sideways with a jerk; to fling",
              synonyms: [],
              antonyms: [],
              example: "shy a slipper",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/shies",
        "https://en.wiktionary.org/wiki/shy",
      ],
    },
  ],
  hasps: [
    {
      word: "hasps",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A clasp, especially a metal strap fastened by a padlock or a pin; also, a hook for fastening a door.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A spindle to wind yarn, thread, or silk on.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An instrument for cutting the surface of grassland; a scarifier.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A measure of two hanks of linen thread.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hasp",
        "https://en.wiktionary.org/wiki/hasps",
        "https://en.wiktionary.org/wiki/hesp",
      ],
    },
  ],
  brung: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  holed: [
    {
      word: "holed",
      phonetic: "/həʊld/",
      phonetics: [
        { text: "/həʊld/", audio: "" },
        { text: "/hoʊld/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make holes in (an object or surface).",
              synonyms: [],
              antonyms: [],
              example: "Shrapnel holed the ship's hull.",
            },
            {
              definition: "(by extension) To destroy.",
              synonyms: [],
              antonyms: [],
              example: "She completely holed the argument.",
            },
            { definition: "To go into a hole.", synonyms: [], antonyms: [] },
            {
              definition:
                "To drive into a hole, as an animal, or a billiard ball or golf ball.",
              synonyms: [],
              antonyms: [],
              example: "Woods holed a standard three foot putt",
            },
            {
              definition: "To cut, dig, or bore a hole or holes in.",
              synonyms: [],
              antonyms: [],
              example: "to hole a post for the insertion of rails or bars",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having one or more holes.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hole",
        "https://en.wiktionary.org/wiki/holed",
      ],
    },
  ],
  swank: [
    {
      word: "swank",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/swank-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75858555",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/swæŋk/", audio: "" },
        { text: "/swæŋk/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A fashionably elegant person.",
              synonyms: [],
              antonyms: [],
              example: "He's such a swank.",
            },
            {
              definition: "Ostentation; bravado.",
              synonyms: [],
              antonyms: [],
              example: "The parvenu was full of swank.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To swagger, to show off.",
              synonyms: [],
              antonyms: [],
              example:
                "Looks like she's going to swank in, flashing her diamonds, then swank out to another party.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Fashionably elegant, posh.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/swank"],
    },
  ],
  looky: [
    {
      word: "looky",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(sometimes humorous) Look.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/looky"],
    },
  ],
  melee: [
    {
      word: "melee",
      phonetic: "/ˈmɛ.li/",
      phonetics: [
        { text: "/ˈmɛ.li/", audio: "" },
        {
          text: "/meɪˈleɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/melee-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=338173",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A battle fought at close range; hand-to-hand combat; brawling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A noisy, confused or tumultuous fight, argument or scrap.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any any confused, disorganised, disordered or chaotic situation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Lively contention or debate, skirmish.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cavalry exercise in which two groups of riders try to cut paper plumes off the helmets of their opponents, the contest continuing until no member of one group retains his plume.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Small cut and polished gemstones sold in lots.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To physically hit in close quarters, as opposed to shooting, blowing up, or other ranged means of damage. Often refers to the usage of a hand-to-hand weapon.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/melee"],
    },
  ],
  huffy: [
    {
      word: "huffy",
      phonetic: "/ˈhʌfi/",
      phonetics: [{ text: "/ˈhʌfi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Angry, annoyed, indignant or irritated.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Easily offended; thin-skinned or touchy.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Haughty, arrogant", synonyms: [], antonyms: [] },
            {
              definition: "Puffed up.",
              synonyms: [],
              antonyms: [],
              example: "huffy bread",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/huffy"],
    },
  ],
  loamy: [
    {
      word: "loamy",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Consisting of loam; partaking of the nature of loam; resembling loam.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/loamy"],
    },
  ],
  pimps: [
    {
      word: "pimps",
      phonetic: "/pɪmps/",
      phonetics: [{ text: "/pɪmps/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Someone who solicits customers for prostitution and acts as manager for a group of prostitutes; a pander.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A man who can easily attract women.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To act as a procurer of prostitutes; to pander.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To prostitute someone.",
              synonyms: [],
              antonyms: [],
              example:
                "The smooth-talking, tall man with heavy gold bracelets claimed he could pimp anyone.",
            },
            {
              definition:
                "To excessively customize something, especially a vehicle, according to ghetto standards (also pimp out).",
              synonyms: [],
              antonyms: [],
              example: "You pimped out that motorcycle f'real, dawg.",
            },
            {
              definition:
                "To ask progressively harder and ultimately unanswerable questions of a resident or medical student (said of a senior member of the medical staff).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To promote, to tout.",
              synonyms: [],
              antonyms: [],
              example:
                "I gotta show you this sweet website where you can pimp your blog and get more readers.",
            },
            {
              definition:
                "To persuade, smooth talk or trick another into doing something for your benefit.",
              synonyms: [],
              antonyms: [],
              example:
                "I pimped her out of $2,000 and she paid for the entire stay at the Bahamas.",
            },
          ],
          synonyms: [
            "pitch",
            "promote",
            "spruik",
            "tout",
            "hustle",
            "whore out",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pimp",
        "https://en.wiktionary.org/wiki/pimps",
      ],
    },
  ],
  titan: [
    {
      word: "titan",
      phonetic: "/ˈtaɪtən/",
      phonetics: [{ text: "/ˈtaɪtən/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Something or someone of very large stature, greatness, or godliness.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/titan"],
    },
  ],
  binge: [
    {
      word: "binge",
      phonetic: "/bɪndʒ/",
      phonetics: [{ text: "/bɪndʒ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A short period of excessive consumption, especially of food, alcohol, narcotics, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) A short period of an activity done in excess, such as watching a television show.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bender", "debauch", "jag", "spree", "toot"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To engage in a short period of excessive consumption, especially of excessive alcohol consumption.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/binge"],
    },
  ],
  shunt: [
    {
      word: "shunt",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shunt-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75857969",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ʃʌnt/", audio: "" },
        { text: "/ʃənt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An act of moving (suddenly), as due to a push or shove.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A connection used as an alternative path between parts of an electrical circuit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The shifting of the studs on a projectile from the deep to the shallow sides of the grooves in its discharge from a shunt gun.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An abnormal passage between body channels.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A passage between body channels constructed surgically as a bypass; a tube inserted into the body to create such a passage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A switch on a railway used to move a train from one track to another.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A minor collision between vehicles.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cause to move (suddenly), as by pushing or shoving; to give a (sudden) start to.",
              synonyms: ["shove"],
              antonyms: [],
            },
            {
              definition:
                "To divert to a less important place, position, or state.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To provide with a shunt.",
              synonyms: [],
              antonyms: [],
              example: "to shunt a galvanometer",
            },
            {
              definition: "To move data in memory to a physical disk.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To divert electric current by providing an alternative path.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To move a train from one track to another, or to move carriages, etc. from one train to another.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To have a minor collision, especially in a motor car.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To divert the flow of a body fluid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To turn aside or away; to divert.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["shove"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shunt"],
    },
  ],
  femur: [
    {
      word: "femur",
      phonetic: "/ˈfiːmə(ɹ)/",
      phonetics: [{ text: "/ˈfiːmə(ɹ)/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A thighbone.", synonyms: [], antonyms: [] },
            {
              definition:
                "The middle segment of the leg of an insect, between the trochanter and the tibia.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A segment of the leg of an arachnid.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/femur"],
    },
  ],
  libra: [
    {
      word: "libra",
      phonetic: "/ˈlaibrə/",
      phonetics: [
        {
          text: "/ˈlaibrə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/libra-ca.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=42700971",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A Roman unit of weight equal to about 327 grams.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various units of weight in Spanish- and Portuguese-speaking countries approximately equal to 460 grams or a little more than a US or UK pound.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Alternative spelling of libbra, an Italian unit of weight.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/libra"],
    },
  ],
  seder: [
    {
      word: "seder",
      phonetic: "/ˈseɪdə/",
      phonetics: [{ text: "/ˈseɪdə/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The ceremonial meal held on the first night or two nights of Passover.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the 54 parts into which the Torah is divided.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/seder"],
    },
  ],
  honed: [
    {
      word: "honed",
      phonetic: "/həʊnd/",
      phonetics: [
        { text: "/həʊnd/", audio: "" },
        { text: "/hoʊnd/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To sharpen with a hone; to whet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To use a hone to produce a precision bore.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To refine or master (a skill).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make more acute, intense, or effective.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To grumble.", synonyms: [], antonyms: [] },
            {
              definition: "To pine, lament, or long.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Made sharp.", synonyms: [], antonyms: [] },
            {
              definition: "Made more accurate or precise.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hone",
        "https://en.wiktionary.org/wiki/honed",
      ],
    },
  ],
  annas: [
    {
      word: "annas",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A unit of currency in former British India equal to 12 pies or 1/16 rupee.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/anna",
        "https://en.wiktionary.org/wiki/annas",
      ],
    },
  ],
  coypu: [
    {
      word: "coypu",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A large, crepuscular, semiaquatic rodent (Myocastor coypus) resembling a large rat, having bright orange-yellow incisors, native to South America and introduced to Europe, Asia and North America, valued for its fur in eastern Europe and central Asia and considered a pest elsewhere.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["nutria"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/coypu"],
    },
  ],
  shims: [
    {
      word: "shims",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A wedge.", synonyms: [], antonyms: [] },
            {
              definition:
                "A thin piece of material, sometimes tapered, used for alignment or support.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small library that transparently intercepts and modifies calls to an API, usually for compatibility purposes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A kind of shallow plow used in tillage to break the ground and clear it of weeds.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A small metal device used to pick open a lock.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To fit one or more shims to a piece of machinery.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To adjust something by using shims.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To intercept and modify calls to (an API), usually for compatibility purposes.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person characterised by both male and female traits, or by ambiguous male-female traits, also called a he-she; transsexual.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Hermaphrodite.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/shim",
        "https://en.wiktionary.org/wiki/shims",
      ],
    },
  ],
  zowie: [
    {
      word: "zowie",
      phonetic: "/ˈzaʊɪ/",
      phonetics: [{ text: "/ˈzaʊɪ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "An indication of astonishment or admiration.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["wow"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/zowie"],
    },
  ],
  jihad: [
    {
      word: "jihad",
      phonetic: "/dʒəˈhɑːd/",
      phonetics: [{ text: "/dʒəˈhɑːd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A holy war undertaken by Muslims.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An aggressive campaign for an idea.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A personal spiritual struggle for self-improvement and/or against evil.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To participate in a jihad.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/jihad"],
    },
  ],
  savvy: [
    {
      word: "savvy",
      phonetic: "/ˈsæ.vi/",
      phonetics: [{ text: "/ˈsæ.vi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Shrewdness.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To understand.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Shrewd, well-informed and perceptive.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["canny"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/savvy"],
    },
  ],
  nadir: [
    {
      word: "nadir",
      phonetic: "/neɪˈdɪə(ɹ)/",
      phonetics: [
        { text: "/neɪˈdɪə(ɹ)/", audio: "" },
        {
          text: "/neɪˈdɪɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/nadir-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=949314",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The point of the celestial sphere, directly opposite the zenith; inferior pole of the horizon; point of the celestial sphere directly under the place of observation.",
              synonyms: [],
              antonyms: ["zenith"],
            },
            {
              definition: "The lowest point; time of greatest depression.",
              synonyms: ["bathos", "lowest ebb", "slough of despond", "trough"],
              antonyms: ["height", "peak"],
            },
            {
              definition:
                "The axis of a projected conical shadow; the direction of the force of gravity at a location; down.",
              synonyms: ["down"],
              antonyms: [],
              example:
                "The nadir of the sun is the axis of the shadow projected by the Earth.",
            },
            {
              definition:
                "An empty box added beneath a full one in a beehive to give the colony more room to expand or store honey.",
              synonyms: [],
              antonyms: ["duplet", "super"],
            },
          ],
          synonyms: [
            "bathos",
            "lowest ebb",
            "slough of despond",
            "trough",
            "down",
          ],
          antonyms: ["duplet", "super", "height", "peak", "zenith"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To extend (a beehive) by adding an empty box at the base.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/nadir"],
    },
  ],
  basso: [
    {
      word: "basso",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A bass singer, especially in opera.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An instrumental part written for a bass instrument.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The double bass, or contrabasso.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/basso"],
    },
  ],
  monic: [
    {
      word: "monic",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A monic polynomial.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of a polynomial whose leading coefficient is one.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Monomorphic.", synonyms: [], antonyms: [] },
            {
              definition: "Of a morphism: that it is a monomorphism.",
              synonyms: [],
              antonyms: [],
              example:
                "If a composition (of morphisms) is monic then, by theorem, the pre-composite of it is monic as well.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/monic"],
    },
  ],
  maned: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  mousy: [
    {
      word: "mousy",
      phonetic: "/ˈmaʊsi/",
      phonetics: [{ text: "/ˈmaʊsi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "A mouse.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Resembling a mouse.", synonyms: [], antonyms: [] },
            {
              definition: "Abounding or infested with mice.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mousy"],
    },
  ],
  omega: [
    {
      word: "omega",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/omega-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75833120",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈəʊmiːɡə/", audio: "" },
        {
          text: "/oʊˈmɛɡə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/omega-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=36747800",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The twenty-fourth letter of the Classical and the Modern Greek alphabet, and the twenty-eighth letter of the Old and the Ancient Greek alphabet, i.e. the last letter of every Greek alphabet. Uppercase version: Ω; lowercase: ω.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(often capitalized) The end; the final, last or ultimate in a sequence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Angular velocity; symbol: ω.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An omega male.", synonyms: [], antonyms: [] },
            {
              definition:
                "The percentage change in an option value divided by the percentage change in the underlying asset's price.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In omegaverse fiction, a person of a sexually-submissive (and sometimes secondary) gender/sex that is driven by biology, magic, or other means to bond with an alpha, with males of this type often being able to get pregnant.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["elasticity", "lambda"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/omega"],
    },
  ],
  laver: [
    {
      word: "laver",
      phonetic: "/ˈlɑːvə/",
      phonetics: [
        { text: "/ˈlɑːvə/", audio: "" },
        { text: "/ˈleɪvɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A red alga/seaweed, Porphyra umbilicalis (syn. Porphyra laciniata), eaten as a vegetable.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Other seaweeds similar in appearance or use, especially:",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/laver"],
    },
    {
      word: "laver",
      phonetic: "/ˈleɪvə/",
      phonetics: [
        { text: "/ˈleɪvə/", audio: "" },
        { text: "/ˈleɪvɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One who laves: a washer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Where one laves, a washroom, particularly a lavatorium, the washing area in a monastery.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "That which laves, particularly a washbasin.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["washbasin"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/laver"],
    },
  ],
  prima: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  picas: [
    {
      word: "picas",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A disorder characterized by craving and appetite for non-edible substances, such as ice, clay, chalk, dirt, or sand.",
              synonyms: [
                "allotriophagy",
                "chthonophagia",
                "cittosis",
                "geophagy",
              ],
              antonyms: [],
            },
          ],
          synonyms: ["allotriophagy", "chthonophagia", "cittosis", "geophagy"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A size of type between small pica and English, standardized as 12-point.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(usually with qualifier) A font of this size.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A unit of length equivalent to 12 points, officially 35/83 cm (0.166 in) after 1886 but now 1/6 in.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A pie or directory: the book directing Roman Catholic observance of saints' days and other feasts under various calendars.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A magpie.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of several small, furry mammals, similar to guinea pigs, but related to rabbits, of the family Ochotonidae, from the mountains of North America and Asia.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pica",
        "https://en.wiktionary.org/wiki/picas",
        "https://en.wiktionary.org/wiki/pika",
      ],
    },
  ],
  folio: [
    {
      word: "folio",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/folio-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75796859",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈfəʊljəʊ/", audio: "" },
        { text: "/ˈfoʊliˌoʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A leaf of a book or manuscript",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A page of a book, that is, one side of a leaf of a book.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A page number. The even folios are on the left-hand pages and the odd folios on the right-hand pages.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sheet of paper folded in half.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(books) A book made of sheets of paper each folded in half (two leaves or four pages to the sheet); hence, a book of the largest kind, exceeding 30 cm in height.",
              synonyms: [],
              antonyms: [],
              example: "A rare copy of Shakespeare’s First Folio.",
            },
            {
              definition:
                "A page in an account book; sometimes, two opposite pages bearing the same serial number.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(19th to early 20th century) A leaf containing a certain number of words; hence, a certain number of words in a writing, as in England, in law proceedings 72, and in chancery, 90; in New York, 100 words.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A wrapper for loose papers.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["2º", "F", "f", "fo"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To put a serial number on each folio or page of (a book); to page",
              synonyms: ["foliate"],
              antonyms: [],
            },
          ],
          synonyms: ["foliate"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/folio"],
    },
  ],
  mecca: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  reals: [
    {
      word: "reals",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A commodity; see realty.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(grammar) One of the three genders that the common gender can be separated into in the Scandinavian languages.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A real number.", synonyms: [], antonyms: [] },
            { definition: "A realist.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A unit of currency used in Portugal and its colonies from 1430 until 1911, and in Brazil from 1790 until 1942.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A coin worth one real.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["rei"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A unit of currency used in Brazil since 1994. Symbol: R$.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A coin worth one real.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["rei"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/real",
        "https://en.wiktionary.org/wiki/reals",
      ],
    },
  ],
  troth: [
    {
      word: "troth",
      phonetic: "/tɹɒθ/",
      phonetics: [
        {
          text: "/tɹɒθ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/troth-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88902228",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/tɹoʊθ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An oath, pledge, or promise.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Truth; something true.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To pledge to marry somebody.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/troth"],
    },
  ],
  testy: [
    {
      word: "testy",
      phonetic: "/ˈtɛsti/",
      phonetics: [{ text: "/ˈtɛsti/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Easily annoyed, irritable.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Marked by impatience or ill humor.",
              synonyms: [],
              antonyms: [],
              example: "He made testy remarks.",
            },
          ],
          synonyms: ["tetchy", "touchy"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/testy"],
    },
  ],
  balky: [
    {
      word: "balky",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Refusing to proceed or cooperate.",
              synonyms: [],
              antonyms: [],
              example: "a balky horse",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/balky"],
    },
  ],
  crimp: [
    {
      word: "crimp",
      phonetic: "/kɹɪmp/",
      phonetics: [
        {
          text: "/kɹɪmp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/crimp-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=827562",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A fastener or a fastening method that secures parts by bending metal around a joint and squeezing it together, often with a tool that adds indentations to capture the parts.",
              synonyms: [],
              antonyms: [],
              example: "The strap was held together by a simple metal crimp.",
            },
            {
              definition: "The natural curliness of wool fibres.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(usually in the plural) Hair that is shaped so it bends back and forth in many short kinks.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A card game.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To press into small ridges or folds, to pleat, to corrugate.",
              synonyms: [],
              antonyms: [],
              example: "Cornish pasties are crimped during preparation.",
            },
            {
              definition:
                "To fasten by bending metal so that it squeezes around the parts to be fastened.",
              synonyms: [],
              antonyms: [],
              example: "He crimped the wire in place.",
            },
            {
              definition: "To pinch and hold; to seize.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To style hair into a crimp, to form hair into tight curls, to make it kinky.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To bend or mold leather into shape.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To gash the flesh, e.g. of a raw fish, to make it crisper when cooked.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Easily crumbled; friable; brittle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Weak; inconsistent; contradictory.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/crimp"],
    },
    {
      word: "crimp",
      phonetic: "/kɹɪmp/",
      phonetics: [
        {
          text: "/kɹɪmp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/crimp-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=827562",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An agent who procures seamen, soldier, etc., especially by decoying, entrapping, impressing, or seducing them.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(specifically) One who infringes sub-section 1 of the Merchant Shipping Act of 1854, applied to a person other than the owner, master, etc., who engages seamen without a license from the Board of Trade.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A keeper of a low lodging house where sailors and emigrants are entrapped and fleeced.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To impress (seamen or soldiers); to entrap, to decoy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/crimp"],
    },
  ],
  chink: [
    {
      word: "chink",
      phonetic: "/tʃɪŋk/",
      phonetics: [
        {
          text: "/tʃɪŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chink-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88681221",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/tʃɪŋk/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A narrow opening such as a fissure or crack.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A chip or dent in something metallic.",
              synonyms: [],
              antonyms: [],
              example:
                "The warrior saw a chink in her enemy's armor, and aimed her spear accordingly.",
            },
            {
              definition:
                "A vulnerability or flaw in a protection system or in any otherwise formidable system.",
              synonyms: [],
              antonyms: [],
              example:
                "The chink in the theory is that the invaders have superior muskets.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To fill an opening such as the space between logs in a log house with chinking; to caulk.",
              synonyms: [],
              antonyms: [],
              example: "to chink a wall",
            },
            { definition: "To crack; to open.", synonyms: [], antonyms: [] },
            {
              definition: "To cause to open in cracks or fissures.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chink"],
    },
    {
      word: "chink",
      phonetic: "/tʃɪŋk/",
      phonetics: [
        {
          text: "/tʃɪŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chink-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88681221",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/tʃɪŋk/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A slight sound as of metal objects touching each other; a clink.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Ready money, especially in the form of coins.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make a slight sound like that of metal objects touching.",
              synonyms: [],
              antonyms: [],
              example: "The coins were chinking in his pocket.",
            },
            {
              definition:
                "To cause to make a sharp metallic sound, as coins, small pieces of metal, etc., by bringing them into collision with each other.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chink"],
    },
    {
      word: "chink",
      phonetic: "/tʃɪŋk/",
      phonetics: [
        {
          text: "/tʃɪŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chink-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88681221",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/tʃɪŋk/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A convulsive fit of coughing or laughter; a sonorous indraft of breath; a whoop; a gasp of breath caused by laughing, coughing, or crying.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To laugh loudly.", synonyms: [], antonyms: [] },
            {
              definition: "To gasp for breath as in a severe fit of coughing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/chink",
        "https://en.wiktionary.org/wiki/kink",
      ],
    },
    {
      word: "chink",
      phonetic: "/tʃɪŋk/",
      phonetics: [
        {
          text: "/tʃɪŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chink-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88681221",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/tʃɪŋk/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A person of perceived Chinese ethnicity.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["Chinaman", "gook"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/Chink",
        "https://en.wiktionary.org/wiki/chink",
      ],
    },
  ],
  abets: [
    {
      word: "abets",
      phonetic: "/əˈbɛts/",
      phonetics: [{ text: "/əˈbɛts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Fraud or cunning.", synonyms: [], antonyms: [] },
            {
              definition: "An act of abetting; of helping; of giving aid.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To urge on, stimulate (a person to do) something desirable.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To incite; to assist or encourage by aid or countenance in crime.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To support, countenance, maintain, uphold, or aid (any good cause, opinion, or action); to maintain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To back up one's forecast of a doubtful issue, by staking money, etc., to bet.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "advocate",
            "aid",
            "assist",
            "back",
            "connive",
            "cooperate",
            "countenance",
            "egg on",
            "embolden",
            "encourage",
            "favor",
            "foment",
            "incite",
            "instigate",
            "promote",
            "sanction",
            "second",
            "set on",
            "support",
            "sustain",
            "uphold",
          ],
          antonyms: [
            "baffle",
            "confound",
            "contradict",
            "counteract",
            "denounce",
            "deter",
            "disapprove",
            "disconcert",
            "discourage",
            "dissuade",
            "expose",
            "frustrate",
            "hinder",
            "impede",
            "obstruct",
            "thwart",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/abet",
        "https://en.wiktionary.org/wiki/abets",
      ],
    },
  ],
  splat: [
    {
      word: "splat",
      phonetic: "/splæt/",
      phonetics: [
        {
          text: "/splæt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/splat-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241782",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The narrow wooden centre piece of a chair back.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/splat"],
    },
    {
      word: "splat",
      phonetic: "/splæt/",
      phonetics: [
        {
          text: "/splæt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/splat-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241782",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The sharp, atonal sound of a liquid or soft solid hitting a solid surface.",
              synonyms: [],
              antonyms: [],
              example:
                "I didn't see the egg fall, but I heard the splat when it hit the floor.",
            },
            {
              definition:
                "The irregular shape of a viscous liquid or soft solid which has hit a solid surface.",
              synonyms: [],
              antonyms: [],
              example:
                "The canvas was covered by seemingly careless splats of paint.",
            },
            {
              definition: "The Command key on an Apple Macintosh.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various characters appearing in computer character sets.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A move in playboating involving stalling in place while positioned vertically against a solid object in the water.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To hit a flat surface and deform into an irregular shape.",
              synonyms: [],
              antonyms: [],
              example: "The egg splatted onto the floor.",
            },
            {
              definition: "To splatter.",
              synonyms: [],
              antonyms: [],
              example: "She splatted the roach with her shoe.",
            },
            {
              definition:
                "To combine different textures by applying an alpha channel map to the higher levels, revealing the layers underneath where the map is partially or completely transparent.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/splat"],
    },
  ],
  abaci: [
    {
      word: "abaci",
      phonetic: "/ˈæbəˌkaɪ/",
      meanings: [{partOfSpeech: "noun", definitions: [{definition: "Plural of abacus, a device used for performing arithmetical calculations."}]}]
    }
  ],
  vaunt: [
    {
      word: "vaunt",
      phonetic: "/vɑːnt/",
      phonetics: [
        {
          text: "/vɑːnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/vaunt-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75857714",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/vɔːnt/", audio: "" },
        {
          text: "/vɔnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/vaunt-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=3631316",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A boast; an instance of vaunting.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To speak boastfully.", synonyms: [], antonyms: [] },
            {
              definition: "To speak boastfully about.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To boast of; to make a vain display of; to display with ostentation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["boast", "brag"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/vaunt"],
    },
    {
      word: "vaunt",
      phonetic: "/vɑːnt/",
      phonetics: [
        {
          text: "/vɑːnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/vaunt-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75857714",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/vɔːnt/", audio: "" },
        {
          text: "/vɔnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/vaunt-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=3631316",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The first part.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/vaunt"],
    },
  ],
  cutie: [
    {
      word: "cutie",
      phonetic: "/ˈkjuːti/",
      phonetics: [{ text: "/ˈkjuːti/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A cute person or animal.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cutie"],
    },
    {
      word: "cutie",
      phonetic: "/ˈkjuːti/",
      phonetics: [{ text: "/ˈkjuːti/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A clementine: a small, waxy-peeled orange hybrid cultivar that is easy to peel by hand.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) Any small mandarin orange variety such as a tangerine or a satsuma.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cutie"],
    },
  ],
  pasty: [
    {
      word: "pasty",
      phonetic: "/ˈpeɪsti/",
      phonetics: [
        {
          text: "/ˈpeɪsti/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pasty-1-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88737581",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈpeɪsti/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(chiefly in the plural) A small item of clothing that conceals little more than the nipple of a woman's breast, primarily worn by female exotic dancers.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Like paste, sticky.",
              synonyms: [],
              antonyms: [],
              example:
                "These mashed potatoes aren’t cooked well; they are very pasty.",
            },
            {
              definition: "Pale, lacking colour, having a pallor",
              synonyms: [],
              antonyms: [],
              example: "Are you feeling OK? You look a bit pasty.",
            },
            { definition: "White-skinned", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pasty"],
    },
    {
      word: "pasty",
      phonetic: "/ˈpɑːsti/",
      phonetics: [
        { text: "/ˈpɑːsti/", audio: "" },
        {
          text: "/ˈpæsti/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pasty-2-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88737582",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A type of seasoned meat and vegetable hand pie, usually of a semicircular shape.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pasty"],
    },
  ],
  moray: [
    {
      word: "moray",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of the large cosmopolitan carnivorous eels of the family Muraenidae.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["moray eel"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/moray"],
    },
  ],
  levis: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  ratty: [
    {
      word: "ratty",
      phonetic: "/ˈɹati/",
      phonetics: [
        { text: "/ˈɹati/", audio: "" },
        { text: "/ˈɹæti/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Similar to a rat; ratlike.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Infested with rats.", synonyms: [], antonyms: [] },
            {
              definition: "In poor condition or repair",
              synonyms: ["battered", "tattered", "torn", "worn out"],
              antonyms: [],
            },
            {
              definition: "Irritable, annoyed, bad-tempered.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["battered", "tattered", "torn", "worn out"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ratty"],
    },
  ],
  islet: [
    {
      word: "islet",
      phonetic: "/ˈaɪ.lət/",
      phonetics: [{ text: "/ˈaɪ.lət/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A small island", synonyms: [], antonyms: [] },
            {
              definition:
                "An isolated piece of tissue that has a specific function",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["eyot"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/islet"],
    },
  ],
  joust: [
    {
      word: "joust",
      phonetic: "/dʒʌʊst/",
      phonetics: [
        { text: "/dʒʌʊst/", audio: "" },
        {
          text: "/dʒaʊst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/joust-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=49897225",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A tilting match: a mock combat between two mounted knights or men-at-arms using lances in the lists or enclosed field.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["tilt"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To engage in mock combat on horseback, as two knights in the lists; to tilt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To engage in verbal sparring over an important issue. (used of two people, both of whom participate more or less equally)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To touch penises while engaging in a sex act, especially oral sex.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/joust"],
    },
  ],
  motet: [
    {
      word: "motet",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A composition adapted to sacred words in the elaborate polyphonic church style; an anthem.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/motet"],
    },
  ],
  viral: [
    {
      word: "viral",
      phonetic: "/ˈvaɪɹəl/",
      phonetics: [{ text: "/ˈvaɪɹəl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                'A video, image or text spread by "word of mouth" on the internet or by e-mail for humorous, political or marketing purposes.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of or relating to a biological virus.",
              synonyms: [],
              antonyms: [],
              example: "viral DNA",
            },
            {
              definition: "Caused by a virus.",
              synonyms: [],
              antonyms: [],
              example: "viral infection",
            },
            {
              definition:
                "Of the nature of an informatic virus; able to spread copies of itself to other computers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Spread by word of mouth, with minimal intervention in order to create buzz and interest.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of an image, video, piece of information, etc.) circulated rapidly and widely from one Internet user to another.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/viral"],
    },
  ],
  nukes: [
    {
      word: "nukes",
      phonetic: "/njuːks/",
      phonetics: [{ text: "/njuːks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A small corner formed by two walls; an alcove.",
              synonyms: ["alcove", "ancone", "recess"],
              antonyms: [],
              example:
                "There was a small broom for sweeping ash kept in the nook between the fireplace bricks and the wall.",
            },
            {
              definition: "A hidden or secluded spot; a secluded retreat.",
              synonyms: [],
              antonyms: [],
              example:
                "The back of the used book shop was one of her favorite nooks; she could read for hours and no one would bother her or pester her to buy.",
            },
            {
              definition: "A recess, cove or hollow.",
              synonyms: ["niche"],
              antonyms: [],
            },
            {
              definition:
                "An English unit of land area, originally 1/4 of a yardland but later 12 1/2 or 20 acres.",
              synonyms: ["fardel"],
              antonyms: [],
            },
            {
              definition:
                "A corner of a piece of land; an angled piece of land, especially one extending into other land.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["alcove", "ancone", "recess", "fardel", "niche"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A nucleus colony; a small bee colony created from a larger colony.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The spinal cord.", synonyms: [], antonyms: [] },
            {
              definition:
                "The back of the neck, the nape; of an animal: the back of the head or the portion of the body behind the head.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A nuclear weapon.", synonyms: [], antonyms: [] },
            {
              definition:
                "(by extension) Something that destroys or negates, especially on a catastrophic scale.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A nuclear power station.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A vessel such as a ship or submarine running on nuclear power.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person (such as a sailor in a navy or a scientist) who works with nuclear weapons or nuclear power.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A microwave oven.",
              synonyms: [],
              antonyms: [],
              example:
                "Just put it in the nuke for two minutes and it will be ready to eat.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To use a nuclear weapon on a target.",
              synonyms: [],
              antonyms: [],
              example:
                "If a nuclear war ever breaks out, military facilities are likely to be nuked first.",
            },
            {
              definition: "To destroy or erase completely.",
              synonyms: ["annihilate", "devastate", "obliterate"],
              antonyms: [],
              example:
                "To try to hide his posting history on Usenet, he had his posts nuked from the Google archives.",
            },
            {
              definition:
                "(by extension) To carry out a denial-of-service attack against (an IRC user).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To expose to some form of radiation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cook in a microwave oven.",
              synonyms: [],
              antonyms: [],
              example: "I’ll nuke some pizza for dinner.",
            },
            {
              definition: "To over-analyze or overly despair over something.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["annihilate", "devastate", "obliterate"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/nook",
        "https://en.wiktionary.org/wiki/nuc",
        "https://en.wiktionary.org/wiki/nucha",
        "https://en.wiktionary.org/wiki/nuke",
        "https://en.wiktionary.org/wiki/nukes",
      ],
    },
  ],
  grads: [
    {
      word: "grads",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A type of Soviet artillery multiple rocket launcher, or a rocket fired by this.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["BM-21", "Katyusha"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A unit of angle equal to 0.9 degrees, so that there are 100 gradians in a right angle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["gon", "grad", "grade"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person who is recognized by a university as having completed the requirements of a degree studied at the institution.",
              synonyms: [],
              antonyms: [],
              example:
                "If the government wants graduates to stay in the country they should offer more incentives.",
            },
            {
              definition:
                "A person who is recognized by a high school as having completed the requirements of a course of study at the school.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person who is recognized as having completed any level of education.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A graduated (marked) cup or other container, thus fit for measuring.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["drop-out", "student"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The action or process of graduating and receiving a diploma for completing a course of study (such as from an educational institution).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A commencement ceremony.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A marking (e.g., on a container) indicating a measurement, usually one of many such markings that are each separated by a constant interval.",
              synonyms: ["scale"],
              antonyms: [],
            },
            {
              definition:
                "The exposure of a liquid in large surfaces to the air, so as to hasten its evaporation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["scale", "🎓"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/Grad",
        "https://en.wiktionary.org/wiki/grad",
        "https://en.wiktionary.org/wiki/gradian",
        "https://en.wiktionary.org/wiki/grads",
        "https://en.wiktionary.org/wiki/graduate",
        "https://en.wiktionary.org/wiki/graduation",
      ],
    },
  ],
  comfy: [
    {
      word: "comfy",
      phonetic: "/ˈkʌm.fi/",
      phonetics: [
        {
          text: "/ˈkʌm.fi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/comfy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1784081",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Comfortable.",
              synonyms: [],
              antonyms: [],
              example:
                "The robe and slippers were so warm and comfy she just fell asleep in her chair.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/comfy"],
    },
  ],
  voila: [
    {
      word: "voila",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "Lo, there it is; see here; ta-da; presto; behold!",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/voil%C3%A0",
        "https://en.wiktionary.org/wiki/voila",
      ],
    },
  ],
  woozy: [
    {
      word: "woozy",
      phonetic: "/ˈwuːzi/",
      phonetics: [
        {
          text: "/ˈwuːzi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/woozy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=951922",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Queasy, dizzy, or disoriented",
              synonyms: [],
              antonyms: [],
              example: "I got off the rollercoaster feeling a bit woozy.",
            },
            {
              definition: "Intoxicated by drink or drugs",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["giddy", "lightheaded", "whifty"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/woozy"],
    },
  ],
  blued: [
    {
      word: "blued",
      phonetic: "/bluːd/",
      phonetics: [{ text: "/bluːd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make or become blue.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To treat the surface of steel so that it is passivated chemically and becomes more resistant to rust.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(laundry) To brighten by treating with blue (laundry aid)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To spend (money) extravagantly; to blow.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/blue",
        "https://en.wiktionary.org/wiki/blued",
      ],
    },
  ],
  whomp: [
    {
      word: "whomp",
      phonetic: "/ʍɒmp/",
      phonetics: [
        {
          text: "/ʍɒmp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/whomp-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241925",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "Hit extremely hard.",
              synonyms: [],
              antonyms: [],
              example:
                "If you don’t cut that out, I’m going to whomp you so hard your head will spin!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/whomp"],
    },
  ],
  sward: [
    {
      word: "sward",
      phonetic: "/swɔː(ɹ)d/",
      phonetics: [
        {
          text: "/swɔː(ɹ)d/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sward-1-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88671737",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A layer of earth into which grass has grown; turf; sod.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An expanse of land covered in grass; a lawn or meadow.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Skin; covering.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "grass",
            "sod",
            "turf",
            "clearing",
            "field",
            "greensward",
            "lawn",
            "meadow",
            "yard",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To cover with sward.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sward"],
    },
    {
      word: "sward",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A homosexual man.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sward"],
    },
  ],
  metro: [
    {
      word: "metro",
      phonetic: "/ˈmɛtɹəʊ/",
      phonetics: [
        { text: "/ˈmɛtɹəʊ/", audio: "" },
        {
          text: "/ˈmɛtɹoʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/metro-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769717",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An underground railway.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A train that runs on such an underground railway.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An urban rapid transit light railway",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A train that runs on such a railway.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["Tube", "railway", "subway", "underground"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/metro"],
    },
    {
      word: "metro",
      phonetic: "/ˈmɛtɹəʊ/",
      phonetics: [
        { text: "/ˈmɛtɹəʊ/", audio: "" },
        {
          text: "/ˈmɛtɹoʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/metro-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769717",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A metropolitan area", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Metropolitan", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/metro"],
    },
  ],
  skeet: [
    {
      word: "skeet",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/skeet-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241738",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A form of trapshooting using clay targets to simulate birds in flight.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hand consisting of a 9, a 5, a 2, and two other cards lower than 9.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The ejaculation of semen.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A scoop with a long handle, used to wash the sides of a vessel and formerly to wet the sails or deck.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A loud, disruptive and poorly educated person.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To shoot or spray (used of fluids).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To ejaculate.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["squirt"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/skeet"],
    },
    {
      word: "skeet",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/skeet-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241738",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "(Manx) news or gossip", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(Manx) to look through the front windows of somebody else's house",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/skeet"],
    },
  ],
  chine: [
    {
      word: "chine",
      phonetic: "/tʃaɪn/",
      phonetics: [
        {
          text: "/tʃaɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chine-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89747604",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The top of a ridge.", synonyms: [], antonyms: [] },
            {
              definition: "The spine of an animal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of the backbone of an animal, with the adjoining parts, cut for cooking.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sharp angle in the cross section of a hull.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A hollowed or bevelled channel in the waterway of a ship's deck.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The edge or rim of a cask, etc., formed by the projecting ends of the staves; the chamfered end of a stave.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The back of the blade on a scythe.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cut through the backbone of; to cut into chine pieces.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To chamfer the ends of a stave and form the chine.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chine"],
    },
    {
      word: "chine",
      phonetic: "/tʃaɪn/",
      phonetics: [
        {
          text: "/tʃaɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chine-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89747604",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(Southern England) A steep-sided ravine leading from the top of a cliff down to the sea.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chine"],
    },
    {
      word: "chine",
      phonetic: "/tʃaɪn/",
      phonetics: [
        {
          text: "/tʃaɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chine-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89747604",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To crack, split, fissure, break.",
              synonyms: [],
              antonyms: [],
              example: "A drought had caused the earth to chine and cranny.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chine"],
    },
  ],
  aerie: [
    {
      word: "aerie",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A local chapter of the Fraternal Order of Eagles.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The nest of a bird of prey.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any high and remote but commanding place.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/aerie",
        "https://en.wiktionary.org/wiki/eyrie",
      ],
    },
  ],
  bowie: [
    {
      word: "bowie",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A Bowie knife", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bowie"],
    },
  ],
  tubby: [
    {
      word: "tubby",
      phonetic: "/ˈtʌbi/",
      phonetics: [
        {
          text: "/ˈtʌbi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tubby-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79268808",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(often used teasingly) An overweight person.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Stout, rotund", synonyms: [], antonyms: [] },
            {
              definition:
                "Resembling a tub; sounding dull and without resonance or freedom of sound.",
              synonyms: [],
              antonyms: [],
              example: "a tubby violin",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tubby"],
    },
  ],
  emirs: [
    {
      word: "emirs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A prince, commander or other leader or ruler in an Islamic nation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A descendant of the prophet Muhammad.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/emir",
        "https://en.wiktionary.org/wiki/emirs",
      ],
    },
  ],
  coati: [
    {
      word: "coati",
      phonetic: "/kəʊˈɑːti/",
      phonetics: [
        { text: "/kəʊˈɑːti/", audio: "" },
        { text: "/koʊˈɑti/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of several omnivorous mammals, of the genus Nasua or Nasuella, in order Carnivora, that live in the range from southern United States to northern Argentina.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/coati"],
    },
  ],
  unzip: [
    {
      word: "unzip",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To open something using a zipper.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To come open by means of a zipper.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To decompress (a zip file).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["zip up"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/unzip"],
    },
  ],
  slobs: [
    {
      word: "slobs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A lazy and slovenly person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A lazy and obese person.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/slob",
        "https://en.wiktionary.org/wiki/slobs",
      ],
    },
  ],
  trike: [
    {
      word: "trike",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A tricycle.", synonyms: [], antonyms: [] },
            {
              definition: "A three-wheeled motorbike.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A kind of railroad maintenance vehicle: a speeder or jigger.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To tricycle; to travel by tricycle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/trike"],
    },
    {
      word: "trike",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A triceratops.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/trike"],
    },
  ],
  funky: [
    {
      word: "funky",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/funky-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79268643",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Offbeat, unconventional or eccentric.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Not quite right; of questionable quality; not appropriate to the context.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Cool; great; excellent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Having or relating to the smell of funk.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Relating to or reminiscent of various genres of African American music, especially funk.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Relating to, or characterized by, great fear, or funking.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/funky"],
    },
  ],
  ducat: [
    {
      word: "ducat",
      phonetic: "/ˈdʌkət/",
      phonetics: [{ text: "/ˈdʌkət/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A gold coin minted by various European nations.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A coin of the major denomination (dollar, euro, etc.); money in general.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A ticket.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ducat"],
    },
  ],
  dewey: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  skoal: [
    {
      word: "skoal",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To make such a toast.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition:
                "A toast when drinking, roughly equivalent to cheers.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/skoal"],
    },
  ],
  wadis: [
    {
      word: "wadis",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A valley, gully, or stream bed in northern Africa and southwest Asia that remains dry except during the rainy season.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wadi",
        "https://en.wiktionary.org/wiki/wadis",
      ],
    },
  ],
  oomph: [
    {
      word: "oomph",
      phonetic: "/umf/",
      phonetics: [{ text: "/umf/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Strength, power, passion or effectiveness; clout.",
              synonyms: [],
              antonyms: [],
              example:
                "Use a mild cleanser, but pick something with enough oomph to do the job.",
            },
            { definition: "Sex appeal.", synonyms: [], antonyms: [] },
            {
              definition: "A bassy grunting or thudding sound.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["welly"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To produce a bassy grunting or thudding sound.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/oomph"],
    },
  ],
  taker: [
    {
      word: "taker",
      phonetic: "/ˈteɪkɚ/",
      phonetics: [{ text: "/ˈteɪkɚ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One who takes something.",
              synonyms: [],
              antonyms: [],
              example: "She is known as quite a risk taker.",
            },
            {
              definition:
                "A person or thing that takes or receives, often more than he or she gives.",
              synonyms: [],
              antonyms: [],
              example:
                "I don't want to be a relationship with you anymore - you are too much of a taker.",
            },
            {
              definition:
                "One who is willing to participate in, or buy, something.",
              synonyms: [],
              antonyms: [],
              example:
                "Are there any takers for helping me clean the garage this weekend?",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/taker"],
    },
  ],
  minim: [
    {
      word: "minim",
      phonetic: "/ˈmɪ.nɪm/",
      phonetics: [{ text: "/ˈmɪ.nɪm/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A half note, drawn as a semibreve with a stem.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A unit of volume, in the Imperial and U.S. customary systems, 1/60 fluid drachm. Approximately equal to 1 drop, 62 μL or 0.9 grain (weight) of water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A short vertical stroke used in handwriting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Anything very minute; applied to animalcula and the like.",
              synonyms: [],
              antonyms: [],
              example: "the minims of existence",
            },
            {
              definition:
                "The smallest kind of worker in a leaf-cutter ant colony.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A little man or being; a dwarf.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A small fish; a minnow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A short poetical encomium.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/minim"],
    },
  ],
  getup: [
    {
      word: "getup",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Enthusiastic and energetic drive or ambition",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["drive", "motivation", "oomph", "pep"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A costume or outfit, especially one that is ostentatious or otherwise unusual.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A fight or altercation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Layout and production style, as of a magazine.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/get-up-and-go",
        "https://en.wiktionary.org/wiki/getup",
      ],
    },
  ],
  stoic: [
    {
      word: "stoic",
      phonetic: "/ˈstəʊɪk/",
      phonetics: [
        { text: "/ˈstəʊɪk/", audio: "" },
        { text: "/ˈstoʊɪk/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Proponent of stoicism, a school of thought, from in 300 B.C.E. up to about the time of Marcus Aurelius, who holds that by cultivating an understanding of the logos, or natural law, one can be free of suffering.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person indifferent to pleasure or pain.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of or relating to the Stoics or their ideas.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Not affected by pain or distress.",
              synonyms: ["apathetic", "impassive", "stoical"],
              antonyms: [],
            },
            {
              definition:
                "Not displaying any external signs of being affected by pain or distress.",
              synonyms: ["expressionless", "impassive"],
              antonyms: [],
            },
          ],
          synonyms: [
            "apathetic",
            "impassive",
            "stoical",
            "expressionless",
            "impassive",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stoic"],
    },
  ],
  synod: [
    {
      word: "synod",
      phonetic: "/ˈsɪn.əd/",
      phonetics: [
        {
          text: "/ˈsɪn.əd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/synod-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=85958007",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An ecclesiastic council or meeting to consult on church matters.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An administrative division of churches, either the entire denomination, as in the Lutheran Church–Missouri Synod, or a mid-level division (middle judicatory, district) as in the Evangelical Lutheran Church in America",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An assembly or council having civil authority; a legislative body.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A conjunction of two or more of the heavenly bodies.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/synod"],
    },
  ],
  runty: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  flyby: [
    {
      word: "flyby",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A flight past a celestial object in order to make observations.",
              synonyms: [],
              antonyms: [],
              example:
                "The upcoming flyby of Halley's comet is expected to provide new information on the nature of the comet.",
            },
            {
              definition:
                "A low-level ceremonial flight, typically in connection with an airshow or a military parade.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A brief visit.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["flyover", "flypast"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/flyby"],
    },
  ],
  braze: [
    {
      word: "braze",
      phonetic: "/bɹeɪz/",
      phonetics: [{ text: "/bɹeɪz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A kind of small charcoal used for roasting ore.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To join two metal pieces, without melting them, using heat and diffusion of a jointing alloy of capillary thickness.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To burn or temper in fire.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/braze"],
    },
  ],
  inlay: [
    {
      word: "inlay",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The material placed within a different material in the form of a decoration.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A filling for a tooth, made of ceramic or gold to fit the cavity and shape of tooth and cemented into place.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The piece of paper or the booklet inside the case of a CD or DVD",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To place (pieces of a foreign material) within another material to form a decorative design.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To place an inlay in a tooth.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/inlay"],
    },
  ],
  venue: [
    {
      word: "venue",
      phonetic: "/ˈvɛnjuː/",
      phonetics: [
        {
          text: "/ˈvɛnjuː/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/venue-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89013131",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A theater, auditorium, arena, or other area designated for sporting or entertainment events.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A neighborhood or near place; the place or county in which anything is alleged to have happened; also, the place where an action is laid, or the district from which a jury comes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A bout; a hit; a turn. See venew.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Sport venue: a stadium or similar building in which a sporting competition is held.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/venue"],
    },
  ],
  louts: [
    {
      word: "louts",
      phonetic: "/laʊts/",
      phonetics: [
        {
          text: "/laʊts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/louts-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=83326652",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A troublemaker, often violent; a rude violent person; a yob.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A clownish, awkward fellow; a bumpkin.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To treat as a lout or fool; to neglect; to disappoint.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To bend, bow, stoop.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lout",
        "https://en.wiktionary.org/wiki/louts",
      ],
    },
  ],
  peaty: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  orlon: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  humpy: [
    {
      word: "humpy",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A hut or temporary shelter made from bark and tree branches, especially for Aborigines.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["gunyah", "mia mia", "wurly"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/humpy"],
    },
  ],
  radon: [
    {
      word: "radon",
      phonetic: "/ˈɹeɪdɒn/",
      phonetics: [{ text: "/ˈɹeɪdɒn/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The chemical element (symbol Rn, formerly Ro) with atomic number 86. It is an odorless, colorless, chemically inert but radioactive noble gas.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["emanation", "exradio", "niton"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/radon"],
    },
  ],
  beaut: [
    {
      word: "beaut",
      phonetic: "/bjuːt/",
      phonetics: [{ text: "/bjuːt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Something or someone that is physically attractive.",
              synonyms: [],
              antonyms: [],
              example: "That new car of yours is a real beaut.",
            },
            {
              definition: "Something that is a remarkable example of its type.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Beautiful, splendid.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/beaut"],
    },
  ],
  raspy: [
    {
      word: "raspy",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/raspy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75857668",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈɹɑː.spɪ/", audio: "" },
        {
          text: "/ˈɹæsp.i/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/raspy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=8154290",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(of sound) Rough, raw, especially used to describe vocal quality.",
              synonyms: [],
              antonyms: [],
              example: "His incessant coughing made his voice sound raspy.",
            },
            { definition: "Irritable.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["irritable", "grating", "harsh", "hoarse", "rough"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/raspy"],
    },
  ],
  unfed: [
    {
      word: "unfed",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A mosquito that has not had a blood meal.",
              synonyms: [],
              antonyms: [],
              example:
                "The captured mosquitos were divided into feds and unfeds.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Not fed.", synonyms: [], antonyms: [] },
            { definition: "Unsupported.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/unfed"],
    },
  ],
  crick: [
    {
      word: "crick",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A painful muscular cramp or spasm of some part of the body, as of the neck or back, making it difficult to move the part affected. (Compare catch.)",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A small jackscrew.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To develop a crick (cramp, spasm).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause to develop a crick; to create a crick in.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To twist, bend, or contort, especially in a way that produces strain.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/crick"],
    },
    {
      word: "crick",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small inlet or bay, often saltwater, narrower and extending farther into the land than a cove; a recess in the shore of the sea, or of a river; the inner part of a port that is used as a dock for small boats.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A stream of water (often freshwater) smaller than a river and larger than a brook.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Any turn or winding.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "bayou",
            "branch",
            "brook",
            "run",
            "beck",
            "brook",
            "burn",
            "stream",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/creek",
        "https://en.wiktionary.org/wiki/crick",
      ],
    },
    {
      word: "crick",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The creaking of a door, or a noise resembling it.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/crick"],
    },
  ],
  nappy: [
    {
      word: "nappy",
      phonetic: "/ˈnæpi/",
      phonetics: [
        {
          text: "/ˈnæpi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/nappy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75648588",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈnæpi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/nappy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769721",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An absorbent garment worn by a baby who does not yet have voluntary control of his or her bladder and bowels or by someone who is incontinent; a diaper.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["diaper", "napkin"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To put a nappy on.",
              synonyms: [],
              antonyms: [],
              example: "The mother nappied the baby.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/nappy"],
    },
    {
      word: "nappy",
      phonetic: "/ˈnæpi/",
      phonetics: [
        {
          text: "/ˈnæpi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/nappy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75648588",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈnæpi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/nappy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769721",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having a nap (of cloth etc.); downy; shaggy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(sometimes offensive) Of hair: tightly curled or twisted; frizzy (often specifically in reference to Afro textured hair)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Inclined to sleep; sleepy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/nappy"],
    },
    {
      word: "nappy",
      phonetic: "/ˈnæpi/",
      phonetics: [
        {
          text: "/ˈnæpi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/nappy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75648588",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈnæpi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/nappy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769721",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A shallow, flat-bottomed earthenware or glass bowl with sloping sides.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/nappy"],
    },
    {
      word: "nappy",
      phonetic: "/ˈnæpi/",
      phonetics: [
        {
          text: "/ˈnæpi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/nappy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75648588",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈnæpi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/nappy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769721",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A kind of strong ale; nappy ale.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "(of a drink) Foamy; having a large head.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of a horse) Nervous, excitable.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/nappy"],
    },
    {
      word: "nappy",
      phonetic: "/ˈnæpi/",
      phonetics: [
        {
          text: "/ˈnæpi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/nappy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75648588",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈnæpi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/nappy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1769721",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [{ definition: "Brittle", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/nappy"],
    },
  ],
  vizor: [
    {
      word: "vizor",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A part of a helmet, arranged so as to lift or open, and so show the face. The openings for seeing and breathing are generally in it.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A mask for the face.", synonyms: [], antonyms: [] },
            {
              definition:
                "The fore piece of a cap, projecting over, and protecting the eyes.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/visor",
        "https://en.wiktionary.org/wiki/vizor",
      ],
    },
  ],
  yipes: [
    {
      word: "yipes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "Expression of shock and alarm.",
              synonyms: [],
              antonyms: [],
              example:
                "Then she told me I was ‘beautiful, for a black girl’. —Yikes!",
            },
            {
              definition:
                "Expression of empathy with unpleasant or undesirable circumstances.",
              synonyms: [],
              antonyms: [],
              example:
                "John has lost his job and can't pay his mortgage; yikes!",
            },
          ],
          synonyms: ["eek", "wow"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/yikes",
        "https://en.wiktionary.org/wiki/yipes",
      ],
    },
  ],
  rebus: [
    {
      word: "rebus",
      phonetic: "/ˈɹiːbəs/",
      phonetics: [
        {
          text: "/ˈɹiːbəs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rebus-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80175108",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A kind of word puzzle which uses pictures to represent words or parts of words.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A pictorial suggestion on a coat of arms of the name of the person to whom it belongs.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dingbat"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To mark or indicate by a rebus.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rebus"],
    },
  ],
  divot: [
    {
      word: "divot",
      phonetic: "/ˈdɪvət/",
      phonetics: [
        {
          text: "/ˈdɪvət/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/divot-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50284442",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A torn-up piece of turf, especially by a golf club in making a stroke or by a horse's hoof.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A disruption in an otherwise smooth contour.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/divot"],
    },
  ],
  kiwis: [
    {
      word: "kiwis",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A New Zealander.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A flightless bird of the genus Apteryx native to New Zealand.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A New Zealand dollar.", synonyms: [], antonyms: [] },
            { definition: "A kiwi fruit.", synonyms: [], antonyms: [] },
            {
              definition:
                "A green-yellow colour, like that of kiwi flesh (also called kiwi green).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A member of the air force who does not fly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["apteryx", "Chinese gooseberry", "kiwi fruit"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/Kiwi",
        "https://en.wiktionary.org/wiki/kiwi",
        "https://en.wiktionary.org/wiki/kiwis",
      ],
    },
  ],
  vetch: [
    {
      word: "vetch",
      phonetic: "/vɛtʃ/",
      phonetics: [{ text: "/vɛtʃ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of several leguminous plants, of the genus Vicia, often grown as green manure and for their edible seeds",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of several similar plants within the subfamily Faboideae",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["tare"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/vetch"],
    },
  ],
  squib: [
    {
      word: "squib",
      phonetic: "/skwɪb/",
      phonetics: [
        {
          text: "/skwɪb/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/squib-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79268859",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small firework that is intended to spew sparks rather than explode.",
              synonyms: [],
              antonyms: [],
              example:
                "English Navy squibs set fire to two dozen enemy ships in a Dutch harbor during the 16th-century battle against the Spanish Armada.",
            },
            {
              definition:
                "A similar device used to ignite an explosive or launch a rocket, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A kind of slow match or safety fuse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any small firecracker sold to the general public, usually in special clusters designed to explode in series after a single master fuse is lit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A malfunction in which the fired projectile does not have enough force behind it to exit the barrel, and thus becomes stuck.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The heating element used to set off the sodium azide pellets in a vehicle's airbag.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In special effects, a small explosive used to replicate a bullet hitting a surface.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A short piece of witty writing; a lampoon.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A writer of lampoons.", synonyms: [], antonyms: [] },
            {
              definition:
                "In a legal casebook, a short summary of a legal action placed between more extensively quoted cases.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A short article, often published in journals, that introduces theoretically problematic empirical data or discusses an overlooked theoretical problem. In contrast to a typical article, a squib need not answer the questions that it poses.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An unimportant, paltry, or mean-spirited person or thing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sketched concept or visual solution, usually very quick and not too detailed.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make a sound like a small explosion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To throw squibs; to utter sarcastic or severe reflections; to contend in petty dispute.",
              synonyms: [],
              antonyms: [],
              example: "to squib a little debate",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/squib"],
    },
  ],
  sitar: [
    {
      word: "sitar",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A Hindustani/Indian classical stringed instrument, typically having a gourd as its resonating chamber.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sitar"],
    },
  ],
  kiddo: [
    {
      word: "kiddo",
      phonetic: "/ˈkɪdoʊ/",
      phonetics: [{ text: "/ˈkɪdoʊ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A close friend; especially used as a form of address.",
              synonyms: [],
              antonyms: [],
              example: "Okay, kiddo, I gotta run.",
            },
            { definition: "A child.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/kiddo"],
    },
  ],
  dyers: [
    {
      word: "dyers",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One who dyes, especially one who dyes cloth etc. as an occupation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["litster"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dyer",
        "https://en.wiktionary.org/wiki/dyers",
      ],
    },
  ],
  cotta: [
    {
      word: "cotta",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A surplice, in England and America usually one shorter and less full than the ordinary surplice and with short sleeves, or sometimes none.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A kind of coarse woollen blanket.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cotta"],
    },
  ],
  matzo: [
    {
      word: "matzo",
      phonetic: "/ˈmætsə/",
      phonetics: [
        { text: "/ˈmætsə/", audio: "" },
        { text: "/ˈmɑːtsoʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Thin, unleavened bread in Jewish cuisine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A piece of the above bread.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/matzo"],
    },
  ],
  lager: [
    {
      word: "lager",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lager-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75804302",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈlɑːɡə/", audio: "" },
        { text: "/ˈlɑːɡɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A type of beer, brewed using a bottom-fermenting yeast.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To store (lager beer) at a low temperature for maturing and clarification.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lager"],
    },
    {
      word: "lager",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lager-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75804302",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈlɑːɡə/", audio: "" },
        { text: "/ˈlɑːɡɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A defensive encampment encircled by wagons, especially by South African Boers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A temporary formation of armoured vehicles for resupply.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To arrange in a circular formation for defence.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To camp in a circular formation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/laager",
        "https://en.wiktionary.org/wiki/lager",
      ],
    },
  ],
  zebus: [
    {
      word: "zebus",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A domesticated ox native to Asia and Africa, having a large fleshy hump on its back and a dewlap (Bos primigenius indicus).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["Indian ox"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/zebu",
        "https://en.wiktionary.org/wiki/zebus",
      ],
    },
  ],
  crass: [
    {
      word: "crass",
      phonetic: "/kɹæs/",
      phonetics: [
        { text: "/kɹæs/", audio: "" },
        { text: "/kɹæs/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Coarse; crude; unrefined or insensitive; lacking discrimination",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Materialistic", synonyms: [], antonyms: [] },
            { definition: "Dense", synonyms: [], antonyms: [] },
            {
              definition: "Lacking finesse; crude and obvious.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["delicate", "refined", "sensitive"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/crass"],
    },
  ],
  dacha: [
    {
      word: "dacha",
      phonetic: "/ˈdætʃə/",
      phonetics: [{ text: "/ˈdætʃə/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A Russian villa or summer house in the countryside.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dacha"],
    },
  ],
  kneed: [
    {
      word: "kneed",
      phonetic: "/niːd/",
      phonetics: [{ text: "/niːd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To kneel to.",
              synonyms: [],
              antonyms: [],
              example:
                "1605: I could as well be brought / To knee his throne and, squire-like, pension beg / To keep base life afoot. — William Shakespeare, King Lear II.ii",
            },
            {
              definition: "To poke or strike with the knee.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To move on the knees; to use the knees to move.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Having a knee or knees, or, in combination, the stated type of knee or knees.",
              synonyms: [],
              antonyms: [],
              example: "a kneed biped, a knobbly-kneed boy",
            },
            {
              definition: "Having angular joints like the knee.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/knee",
        "https://en.wiktionary.org/wiki/kneed",
      ],
    },
  ],
  dicta: [
    {
      word: "dicta",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An authoritative statement; a dogmatic saying; a maxim, an apothegm.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A judicial opinion expressed by judges on points that do not necessarily arise in the case, and are not involved in it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The report of a judgment made by one of the judges who has given it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An arbitrament or award.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dicta",
        "https://en.wiktionary.org/wiki/dictum",
      ],
    },
  ],
  fakir: [
    {
      word: "fakir",
      phonetic: "/fɑˈkiɹ/",
      phonetics: [{ text: "/fɑˈkiɹ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A faqir, owning no personal property and usually living solely off alms.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Hindu) An ascetic mendicant, especially one who performs feats of endurance or apparent magic.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Someone who takes advantage of the gullible through fakery, especially of a spiritual or religious nature.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fakir"],
    },
  ],
  knurl: [
    {
      word: "knurl",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/knurl-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=558932",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A contorted knot in wood.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A crossgrained protuberance; a nodule; a boss or projection.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A lined or crossgrained pattern of ridges or indentations rolled or pressed into a part for grip.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To roll or press a pattern of ridges or indentations into a part for grip.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/knurl"],
    },
  ],
  runny: [
    {
      word: "runny",
      phonetic: "/ˈɹʌni/",
      phonetics: [{ text: "/ˈɹʌni/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Fluid; capable of flowing.",
              synonyms: [],
              antonyms: [],
              example: "runny honey",
            },
            {
              definition: "Liable to run or drip.",
              synonyms: [],
              antonyms: [],
              example: "a runny nose",
            },
          ],
          synonyms: ["fluxive", "liquid"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/runny"],
    },
  ],
  unpin: [
    {
      word: "unpin",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To unfasten by removing a pin.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To detach (an icon, application, etc.) from the place where it was previously pinned.",
              synonyms: [],
              antonyms: [],
              example: "to unpin a program from the Taskbar",
            },
            { definition: "To get out of a pin", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/unpin"],
    },
  ],
  julep: [
    {
      word: "julep",
      phonetic: "/ˈdʒuːlɛp/",
      phonetics: [{ text: "/ˈdʒuːlɛp/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A refreshing drink flavored with aromatic herbs, especially mint, and sometimes alcohol.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A pleasant-tasting liquid medicine in which other nauseous medicines are taken.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/julep"],
    },
  ],
  globs: [
    {
      word: "globs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A round, shapeless or amorphous lump, as of a semisolid substance.",
              synonyms: [],
              antonyms: [],
              example:
                "He put a glob of paint into the cup and went on painting.",
            },
            {
              definition:
                "A limited pattern matching technique using wildcards, less powerful than a regular expression.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A millimeter-sized colour module found beyond the visual area V2 in the brain's parvocellular pathway.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/glob",
        "https://en.wiktionary.org/wiki/globs",
      ],
    },
  ],
  nudes: [
    {
      word: "nudes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A painting, sculpture, photograph or other artwork or mass-media-reproduced image depicting one or more human figure(s) in a state of near or total undress.",
              synonyms: [],
              antonyms: [],
              example: "Lexa sent me a nude last week.",
            },
            {
              definition:
                '(with article, "the nude") The state of total nudity.',
              synonyms: [],
              antonyms: [],
              example: "she caught him in the nude",
            },
            {
              definition:
                "A color that resembles or evokes bare flesh; a paint, dye, etc. of such color.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/nude",
        "https://en.wiktionary.org/wiki/nudes",
      ],
    },
  ],
  sushi: [
    {
      word: "sushi",
      phonetic: "/ˈsuʃi/",
      phonetics: [{ text: "/ˈsuʃi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A Japanese dish made of small portions of sticky white rice flavored with vinegar, usually wrapped in seaweed and filled or topped with fish, vegetables or meat.",
              synonyms: [],
              antonyms: [],
              example: "For the vegetarians, she served cucumber sushi.",
            },
            {
              definition: "Raw fish, especially as a Japanese dish.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sushi"],
    },
  ],
  tacky: [
    {
      word: "tacky",
      phonetic: "/ˈtæki/",
      phonetics: [{ text: "/ˈtæki/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of a substance, slightly sticky.",
              synonyms: [],
              antonyms: [],
              example: "This paint isn't dry yet; it's still a bit tacky.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tacky"],
    },
    {
      word: "tacky",
      phonetic: "/ˈtæki/",
      phonetics: [{ text: "/ˈtæki/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A scrubby, neglected horse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person in a similar condition.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of low quality.",
              synonyms: [],
              antonyms: [],
              example: "That market stall sells all sorts of tacky ornaments.",
            },
            {
              definition: "In poor taste.",
              synonyms: [],
              antonyms: [],
              example: "That was a tacky thing to say.",
            },
            {
              definition: "Gaudy or flashy.",
              synonyms: ["flashy", "garish", "showy"],
              antonyms: [],
            },
            {
              definition: "Shabby, dowdy in one's appearance or dress.",
              synonyms: ["dowdy"],
              antonyms: [],
            },
          ],
          synonyms: ["dowdy", "flashy", "garish", "showy"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tackey",
        "https://en.wiktionary.org/wiki/tacky",
      ],
    },
  ],
  stoke: [
    {
      word: "stoke",
      phonetic: "/stəʊk/",
      phonetics: [
        { text: "/stəʊk/", audio: "" },
        { text: "/stoʊk/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To poke, pierce, thrust.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stoke"],
    },
    {
      word: "stoke",
      phonetic: "/stəʊk/",
      phonetics: [
        { text: "/stəʊk/", audio: "" },
        { text: "/stoʊk/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To feed, stir up, especially, a fire or furnace.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) To encourage a behavior or emotion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To attend to or supply a furnace with fuel; to act as a stoker or fireman.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stoke"],
    },
    {
      word: "stoke",
      phonetic: "/stəʊk/",
      phonetics: [
        { text: "/stəʊk/", audio: "" },
        { text: "/stoʊk/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A unit of kinematic viscosity in the CGS system of units. 1 stokes = 1 cm2/s",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["St"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/stoke",
        "https://en.wiktionary.org/wiki/stokes",
      ],
    },
  ],
  kaput: [
    {
      word: "kaput",
      phonetic: "/kəˈpuːt/",
      phonetics: [
        {
          text: "/kəˈpuːt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/kaput-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79218747",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Out of order; not working.",
              synonyms: ["broken"],
              antonyms: [],
              example: "Her marriage went kaput.",
            },
          ],
          synonyms: ["broken"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/kaput"],
    },
  ],
  butch: [
    {
      word: "butch",
      phonetic: "/bʊtʃ/",
      phonetics: [
        {
          text: "/bʊtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/butch-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241120",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A lesbian who appears masculine or acts in a masculine manner.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bull dyke", "dyke"],
          antonyms: ["femme"],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(originally Polari) Very masculine, with a masculine appearance or attitude.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["macho", "manly", "mannish", "masc", "unfeminine"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/butch"],
    },
  ],
  hulas: [
    {
      word: "hulas",
      phonetic: "/ˈhuːləz/",
      phonetics: [{ text: "/ˈhuːləz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A form of chant and dance, which was developed in the Hawaiian Islands by the Polynesians who originally settled there.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To dance the hula.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hula",
        "https://en.wiktionary.org/wiki/hulas",
      ],
    },
  ],
  croft: [
    {
      word: "croft",
      phonetic: "/kɹɑft/",
      phonetics: [
        { text: "/kɹɑft/", audio: "" },
        {
          text: "/kɹɒft/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/croft-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=81370922",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/kɹɔft/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An enclosed piece of land, usually small and arable and used for small-scale food production, and often with a dwelling next to it; in particular, such a piece of land rented to a farmer (a crofter), especially in Scotland, together with a right to use separate pastureland shared by other crofters.",
              synonyms: ["quillet"],
              antonyms: [],
            },
          ],
          synonyms: ["quillet"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To do agricultural work on one or more crofts.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To place (cloth, etc.) on the ground in the open air in order to sun and bleach it.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/croft"],
    },
    {
      word: "croft",
      phonetic: "/kɹɑft/",
      phonetics: [
        { text: "/kɹɑft/", audio: "" },
        {
          text: "/kɹɒft/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/croft-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=81370922",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/kɹɔft/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An underground chamber; a crypt, an undercroft.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A cave or cavern.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/croft"],
    },
    {
      word: "croft",
      phonetic: "/kɹɑft/",
      phonetics: [
        { text: "/kɹɑft/", audio: "" },
        {
          text: "/kɹɒft/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/croft-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=81370922",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/kɹɔft/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A carafe.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/croft"],
    },
  ],
  achoo: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  genii: [
    {
      word: "genii",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Someone possessing extraordinary intelligence or skill; especially somebody who has demonstrated this by a creative or original work in science, music, art etc.",
              synonyms: [],
              antonyms: ["idiot"],
              example:
                "I think that would qualify as not smart, but genius... and a very stable genius at that!",
            },
            {
              definition: "Extraordinary mental capacity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Inspiration, a mental leap, an extraordinary creative process.",
              synonyms: [],
              antonyms: [],
              example: "a work of genius.",
            },
            {
              definition: "The tutelary deity or spirit of a place or person.",
              synonyms: ["tutelary deity"],
              antonyms: [],
            },
          ],
          synonyms: ["tutelary deity"],
          antonyms: ["idiot"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/genii",
        "https://en.wiktionary.org/wiki/genius",
      ],
    },
    {
      word: "genii",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A jinn, a being descended from the jann, normally invisible to the human eye, but who may also appear in animal or human form.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A fictional magical being that is typically bound to obey the commands of a mortal possessing its container.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/genie",
        "https://en.wiktionary.org/wiki/genii",
      ],
    },
  ],
  nodal: [
    {
      word: "nodal",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of the nature of, or relating to, a node.",
              synonyms: [],
              antonyms: [],
              example: "a nodal point",
            },
            {
              definition: "Of or relating to the nodus.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/nodal"],
    },
  ],
  outgo: [
    {
      word: "outgo",
      phonetic: "/aʊtˈɡəʊ/",
      phonetics: [{ text: "/aʊtˈɡəʊ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act or process of going out.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A quantity of a substance or thing that has flowed out; an outflow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An expenditure, cost or outlay.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["income"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To go out, to set forth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To go further; to exceed or surpass; go beyond.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To overtake; to travel faster than.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To outdo; exceed; surpass.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To come to an end.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/outgo"],
    },
  ],
  spiel: [
    {
      word: "spiel",
      phonetic: "/spiːl/",
      phonetics: [{ text: "/spiːl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A lengthy and extravagant speech or argument usually intended to persuade.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An early form of rap music.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To talk at length.", synonyms: [], antonyms: [] },
            {
              definition: "To give a sales pitch; to promote by speaking.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spiel"],
    },
    {
      word: "spiel",
      phonetic: "/spiːl/",
      phonetics: [{ text: "/spiːl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A game of curling.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spiel"],
    },
  ],
  viols: [
    {
      word: "viols",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A stringed instrument related to the violin family, but held in the lap between the legs like a cello, usually with C-holes, a flat back, a fretted neck and six strings, played with an underhanded bow hold.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["gamba", "viola d'amore", "viola da gamba"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/viol",
        "https://en.wiktionary.org/wiki/viols",
      ],
    },
  ],
  fetid: [
    {
      word: "fetid",
      phonetic: "/ˈfɛtɪd/",
      phonetics: [{ text: "/ˈfɛtɪd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The foul-smelling asafoetida plant, or its extracts.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Foul-smelling, stinking.",
              synonyms: [],
              antonyms: [],
              example: "I caught the fetid odor of dirty socks.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fetid"],
    },
  ],
  cagey: [
    {
      word: "cagey",
      phonetic: "/ˈkeɪdʒi/",
      phonetics: [
        {
          text: "/ˈkeɪdʒi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cagey-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1175488",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Wary, careful, shrewd.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Uncommunicative; unwilling or hesitant to give information.",
              synonyms: ["evasive", "reticent"],
              antonyms: [],
            },
          ],
          synonyms: ["evasive", "reticent"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cagey"],
    },
  ],
  fudgy: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  epoxy: [
    {
      word: "epoxy",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A thermosetting polyepoxide resin used chiefly in strong adhesives, coatings and laminates; epoxy resin.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To glue with epoxy.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Derived from an epoxide.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/epoxy"],
    },
  ],
  leggy: [
    {
      word: "leggy",
      phonetic: "/ˈlɛɡi/",
      phonetics: [
        {
          text: "/ˈlɛɡi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/leggy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79268828",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(chiefly of a woman) Having long, attractive legs; long-legged.",
              synonyms: [],
              antonyms: [],
              example: "Fred preferred leggy blondes.",
            },
            {
              definition:
                "(chiefly of a woman) Exposing the bare or pantyhose-clad legs, especially the thighs.",
              synonyms: [],
              antonyms: [],
              example:
                "Pam was leggy today because she was wearing a miniskirt.",
            },
            {
              definition: "Taller or longer than usual.",
              synonyms: [],
              antonyms: [],
              example: "Plants grow leggy if deprived of light.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/leggy"],
    },
  ],
  hanky: [
    {
      word: "hanky",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A piece of cloth, usually square and often fine and elegant, carried for wiping the face, eyes, nose or hands.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of cloth shaped like a handkerchief to be worn about the neck; a neckerchief or neckcloth.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["hanky", "pocket handkerchief"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/handkerchief",
        "https://en.wiktionary.org/wiki/hanky",
      ],
    },
  ],
  lapis: [
    {
      word: "lapis",
      phonetic: "/ˈlæpɪs/",
      phonetics: [{ text: "/ˈlæpɪs/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Lapis lazuli.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lapis"],
    },
  ],
  felon: [
    {
      word: "felon",
      phonetic: "/ˈfɛlən/",
      phonetics: [
        {
          text: "/ˈfɛlən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/felon-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=95144091",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A person who has committed a felony.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person who has been tried and convicted of a felony.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A wicked person.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["convict", "criminal", "culprit", "malefactor"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Wicked; cruel", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/felon"],
    },
    {
      word: "felon",
      phonetic: "/ˈfɛlən/",
      phonetics: [
        {
          text: "/ˈfɛlən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/felon-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=95144091",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A bacterial infection at the end of a finger or toe.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/felon"],
    },
  ],
  beefy: [
    {
      word: "beefy",
      phonetic: "/ˈbiːfi/",
      phonetics: [{ text: "/ˈbiːfi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Similar to, or tasting like beef.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Containing beef.", synonyms: [], antonyms: [] },
            {
              definition: "Strong or muscular.",
              synonyms: [],
              antonyms: [],
              example:
                "The barman was a big, beefy guy with his sleeves rolled up and tattoos on his arms.",
            },
            {
              definition: "Sturdy; robust.",
              synonyms: [],
              antonyms: [],
              example: "The software slows down even a beefy computer.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/beefy"],
    },
  ],
  coots: [
    {
      word: "coots",
      phonetic: "/kuːts/",
      phonetics: [{ text: "/kuːts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of various aquatic birds of the genus Fulica that are mainly black with a prominent frontal shield on the forehead.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A foolish or eccentric fellow",
              synonyms: [],
              antonyms: [],
              example: "A rich coot",
            },
            {
              definition: "(with the) A success; something excellent.",
              synonyms: [],
              antonyms: [],
              example: "Man that song's the coot.",
            },
            {
              definition: "Body louse (Pediculus humanus).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/coot",
        "https://en.wiktionary.org/wiki/coots",
      ],
    },
  ],
  melba: [
    {
      word: "melba",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A dessert made originally from peach (now also other fruits), ice cream, and raspberry.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/melba"],
    },
  ],
  caddy: [
    {
      word: "caddy",
      phonetic: "/ˈkædi/",
      phonetics: [{ text: "/ˈkædi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One hired to assist another in playing the game of golf.",
              synonyms: [],
              antonyms: [],
              example: '"Caddy, pass me my five iron."',
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To serve as a caddy, carrying golf clubs etc.",
              synonyms: [],
              antonyms: [],
              example:
                "I was honored to caddy for Tiger Woods at a charity golf game.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/caddy"],
    },
    {
      word: "caddy",
      phonetic: "/ˈkædi/",
      phonetics: [{ text: "/ˈkædi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A small box, can, or chest to keep things in.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A movable tray or other mechanism for holding, securing, and transporting a removable component within a piece of machinery or equipment.",
              synonyms: [],
              antonyms: [],
              example: "Place the disc in the DVD caddy.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/caddy"],
    },
  ],
  segue: [
    {
      word: "segue",
      phonetic: "/ˈsɛɡweɪ/",
      phonetics: [
        {
          text: "/ˈsɛɡweɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/segue-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75857799",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈsɛɡweɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/segue-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1412792",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An instance of segueing, a transition.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To move smoothly from one state or subject to another.",
              synonyms: ["transition"],
              antonyms: [],
              example:
                "I can tell she’s going to segue from our conversation about school to the topic of marriage.",
            },
            {
              definition:
                "To make a smooth transition from one theme to another.",
              synonyms: [],
              antonyms: [],
              example:
                "Beethoven’s symphonies effortlessly segue from one theme to the next.",
            },
            {
              definition:
                "(of a disk jockey) To play a sequence of records with no talk between them.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["transition"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/segue"],
    },
  ],
  betel: [
    {
      word: "betel",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Either of two plants often used in combination:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A quid (chewing preparation) containing these and other plant materials; paan.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/betel"],
    },
  ],
  frizz: [
    {
      word: "frizz",
      phonetic: "/fɹɪz/",
      phonetics: [
        {
          text: "/fɹɪz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/frizz-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=502011",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "Of hair, to form into a mass of tight curls.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To curl; to make frizzy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To form into little burs, knobs, or tufts, as the nap of cloth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make (leather) soft and of even thickness by rubbing, as with pumice stone or a blunt instrument.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To fry, cook, or sear with a sizzling noise; to sizzle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/frizz"],
    },
    {
      word: "frizz",
      phonetic: "/fɹɪz/",
      phonetics: [
        {
          text: "/fɹɪz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/frizz-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=502011",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A mass of tightly curled or unruly hair.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/frizz"],
    },
  ],
  drear: [
    {
      word: "drear",
      phonetic: "/dɹɪə/",
      phonetics: [{ text: "/dɹɪə/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [{ definition: "Dreary.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/drear"],
    },
    {
      word: "drear",
      phonetic: "/dɹɪə/",
      phonetics: [{ text: "/dɹɪə/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Gloom; sadness.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/drear"],
    },
  ],
  kooks: [
    {
      word: "kooks",
      phonetic: "/kuːks/",
      phonetics: [{ text: "/kuːks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An eccentric, strange or crazy person.",
              synonyms: ["nutjob", "odd duck", "weirdo"],
              antonyms: [],
            },
            {
              definition:
                "(kiteboarding, wakeboarding) A boardsport participant who lacks style or skill; a newbie who acts as if they are better at the sport than they are.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["nutjob", "odd duck", "weirdo"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/kook",
        "https://en.wiktionary.org/wiki/kooks",
      ],
    },
  ],
  turbo: [
    {
      word: "turbo",
      phonetic: "/ˈtɜːbəʊ/",
      phonetics: [
        { text: "/ˈtɜːbəʊ/", audio: "" },
        {
          text: "/ˈtɝboʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/turbo-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=49896654",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Turbocharger.", synonyms: [], antonyms: [] },
            { definition: "Turbojet.", synonyms: [], antonyms: [] },
            { definition: "Turbomolecular pump.", synonyms: [], antonyms: [] },
            { definition: "Autofire.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To autofire.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "With rapidly-increasing blind levels.",
              synonyms: [],
              antonyms: [],
              example:
                "This is a turbo tournament: the blinds increase every 5 minutes! Level 1 = 150/300; level 2 = 200/400; etc.",
            },
            {
              definition: "Causing or relating to increased speed.",
              synonyms: [],
              antonyms: [],
              example:
                "a turbo button on a controller; a PC equipped with a turbo mode",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/turbo"],
    },
    {
      word: "turbo",
      phonetic: "/ˈtɜːbəʊ/",
      phonetics: [
        { text: "/ˈtɜːbəʊ/", audio: "" },
        {
          text: "/ˈtɝboʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/turbo-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=49896654",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A turban shell.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/turbo"],
    },
  ],
  hoagy: [
    {
      word: "hoagy",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sandwich made on a (usually soft) long Italian roll; a submarine sandwich.",
              synonyms: [],
              antonyms: [],
              example: "I'm going to Wawa to get a hoagie for lunch.",
            },
          ],
          synonyms: ["sub"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hoagie",
        "https://en.wiktionary.org/wiki/hoagy",
      ],
    },
  ],
  moult: [
    {
      word: "moult",
      phonetic: "/mɒlt/",
      phonetics: [
        { text: "/mɒlt/", audio: "" },
        { text: "/moʊlt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The process of shedding or losing a covering of fur, feathers or skin etc.",
              synonyms: [],
              antonyms: [],
              example: "Some birds change colour during their winter moult.",
            },
            {
              definition:
                "The skin or feathers cast off during the process of moulting.",
              synonyms: ["exuvia"],
              antonyms: [],
            },
          ],
          synonyms: ["exuvia"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To shed or lose a covering of hair or fur, feathers, skin, horns, etc, and replace it with a fresh one.",
              synonyms: ["shed", "slough"],
              antonyms: [],
            },
            {
              definition: "To shed in such a manner.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["shed", "slough"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/moult"],
    },
  ],
  helix: [
    {
      word: "helix",
      phonetic: "/ˈhiːlɪks/",
      phonetics: [
        {
          text: "/ˈhiːlɪks/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/helix-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780128",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A curve on the surface of a cylinder or cone such that its angle to a plane perpendicular to the axis is constant; the three-dimensional curve seen in a screw or a spiral staircase.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small volute under the abacus of a Corinthian capital.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The incurved rim of the external ear.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An upside down midair 360° spin in playboating.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["spiral"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To form or cause to form a helix.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/helix"],
    },
  ],
  zonal: [
    {
      word: "zonal",
      phonetic: "/ˈzəʊnəl/",
      phonetics: [{ text: "/ˈzəʊnəl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Divided into zones.", synonyms: [], antonyms: [] },
            {
              definition: "Related to, associated with, or similar to zones.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of air or ocean currents) roughly longitudinal (east to west, or west to east)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/zonal"],
    },
  ],
  arias: [
    {
      word: "arias",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A musical piece written typically for a solo voice with orchestral accompaniment in an opera or cantata.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/aria",
        "https://en.wiktionary.org/wiki/arias",
      ],
    },
  ],
  nosey: [
    {
      word: "nosey",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A look at something to satisfy one's curiosity.",
              synonyms: [],
              antonyms: [],
              example:
                "I might wander down to the construction site for a nosy at what they're building.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Prying, inquisitive or curious in other’s affairs; tending to snoop or meddle.",
              synonyms: [],
              antonyms: [],
              example:
                "They built tall fences, yet the nosy neighbors always seemed to know everything about them.",
            },
            {
              definition: "Having a large or elongated nose.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["interfering", "kibitzing", "meddlesome"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/nosey",
        "https://en.wiktionary.org/wiki/nosy",
      ],
    },
  ],
  paean: [
    {
      word: "paean",
      phonetic: "/ˈpiː.ən/",
      phonetics: [
        {
          text: "/ˈpiː.ən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/paean-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88942238",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈpi.ən/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A chant or song, especially a hymn of thanksgiving for deliverance or victory, to Apollo or sometimes another god or goddess; hence any song sung to solicit victory in battle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) Any loud and joyous song; a song of triumph.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) An enthusiastic expression of praise.",
              synonyms: ["encomium", "hymn", "praise", "tribute"],
              antonyms: [],
            },
          ],
          synonyms: ["encomium", "hymn", "praise", "tribute"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To sing a paean; to praise.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/paean"],
    },
  ],
  lacey: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  banns: [
    {
      word: "banns",
      phonetic: "/bænz/",
      phonetics: [{ text: "/bænz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The announcement of a forthcoming marriage (legally required for a church wedding in England and Wales and read on the three Sundays preceding the marriage).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Historically, any public announcement of a coming event.",
              synonyms: [],
              antonyms: [],
              example:
                "The Banes which are reade beefore the beginninge of the playes of Chester, 4 June, 1600.",
            },
          ],
          synonyms: ["banns of marriage"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/banns"],
    },
  ],
  swain: [
    {
      word: "swain",
      phonetic: "/sweɪn/",
      phonetics: [{ text: "/sweɪn/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A young man or boy in service; a servant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A knight's servant; an attendant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A country labourer; a countryman, a rustic.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A rural lover; a male sweetheart in a pastoral setting.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/swain"],
    },
  ],
  fryer: [
    {
      word: "fryer",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A container for frying food.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A young chicken suitable for frying; a pullet",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fryer"],
    },
  ],
  retch: [
    {
      word: "retch",
      phonetic: "/ɹɛtʃ/",
      phonetics: [
        {
          text: "/ɹɛtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/retch-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89747653",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An unsuccessful effort to vomit.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make an unsuccessful effort to vomit; to strain, as in vomiting.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/retch"],
    },
    {
      word: "retch",
      phonetic: "/ɹɛtʃ/",
      phonetics: [
        {
          text: "/ɹɛtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/retch-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89747653",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [{ definition: "To reck", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/retch"],
    },
    {
      word: "retch",
      phonetic: "/ɹɛtʃ/",
      phonetics: [
        {
          text: "/ɹɛtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/retch-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89747653",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [{ definition: "To reach", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/retch"],
    },
  ],
  tenet: [
    {
      word: "tenet",
      phonetic: "/ˈtɛnət/",
      phonetics: [{ text: "/ˈtɛnət/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An opinion, belief, or principle that is held as absolute truth by someone or especially an organization.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tenet"],
    },
  ],
  gigas: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  whiny: [
    {
      word: "whiny",
      phonetic: "/ˈwaɪni/",
      phonetics: [{ text: "/ˈwaɪni/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Whining; tending to whine or complain.",
              synonyms: [],
              antonyms: [],
              example:
                "Emily is very whiny; she keeps complaining about trivial things.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/whiny"],
    },
  ],
  ogled: [
    {
      word: "ogled",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To stare at (someone or something), especially impertinently, amorously, or covetously.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ogle",
        "https://en.wiktionary.org/wiki/ogled",
      ],
    },
  ],
  rumen: [
    {
      word: "rumen",
      phonetic: "/ˈɹu.mən/",
      phonetics: [{ text: "/ˈɹu.mən/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The first compartment of the stomach of a cow or other ruminants.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["fack", "fardingbag", "paunch"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rumen"],
    },
  ],
  begot: [
    {
      word: "begot",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To father; to sire; to produce (a child).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To cause; to produce.", synonyms: [], antonyms: [] },
            { definition: "To bring forth.", synonyms: [], antonyms: [] },
            { definition: "To happen to; befall.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/beget",
        "https://en.wiktionary.org/wiki/begot",
      ],
    },
  ],
  cruse: [
    {
      word: "cruse",
      phonetic: "/kɹuːs/",
      phonetics: [
        {
          text: "/kɹuːs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cruse-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=91108428",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small jar used to hold liquid, such as oil or water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An oil lamp or similar emblem.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cruse"],
    },
  ],
  abuts: [
    {
      word: "abuts",
      phonetic: "/əˈbʌts/",
      phonetics: [
        { text: "/əˈbʌts/", audio: "" },
        { text: "/əˈbʌts/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To touch by means of a mutual border, edge or end; to border on; to lie adjacent (to); to be contiguous (said of an area of land)",
              synonyms: [],
              antonyms: [],
              example: "His land abuts on the road.",
            },
            {
              definition:
                "To border upon; be next to; abut on; be adjacent to.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To lean against on one end; to end on, of a part of a building or wall.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/abut",
        "https://en.wiktionary.org/wiki/abuts",
      ],
    },
  ],
  riven: [
    {
      word: "riven",
      phonetic: "/ˈɹɪvən/",
      phonetics: [
        {
          text: "/ˈɹɪvən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/riven-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88942995",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈɹɪvən/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To tear apart by force; to rend; to split; to cleave.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To pierce or cleave with a weapon.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["cleave", "rend", "split"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Torn apart.", synonyms: [], antonyms: [] },
            {
              definition: "Broken into pieces; split asunder.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rive",
        "https://en.wiktionary.org/wiki/riven",
      ],
    },
  ],
  balks: [
    {
      word: "balks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An uncultivated ridge formed in the open field system, caused by the action of ploughing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The wall of earth at the edge of an excavation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'Beam, crossbeam; squared timber; a tie beam of a house, stretching from wall to wall, especially when laid so as to form a loft, "the balks".',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A hindrance or disappointment; a check.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sudden and obstinate stop; a failure.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An omission.", synonyms: [], antonyms: [] },
            {
              definition: "A deceptive motion; a feint.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The area of the table lying behind the line from which the cue ball is initially shot, and from which a ball in hand must be played.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The area of the table lying behind the baulk line.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The rope by which fishing nets are fastened together.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To pass over or by.", synonyms: [], antonyms: [] },
            {
              definition: "To omit, miss or overlook by chance.",
              synonyms: ["miss", "overlook"],
              antonyms: [],
            },
            {
              definition: "To miss intentionally; to avoid.",
              synonyms: ["avoid", "refuse", "shirk", "shun"],
              antonyms: [],
            },
            {
              definition: "To stop, check, block.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To stop short and refuse to go on.",
              synonyms: [],
              antonyms: [],
              example: "The horse balked.",
            },
            { definition: "To refuse suddenly.", synonyms: [], antonyms: [] },
            {
              definition: "To disappoint; to frustrate.",
              synonyms: ["baffle", "foil", "frustrate", "thwart"],
              antonyms: [],
              example: "to balk expectation",
            },
            {
              definition: "To engage in contradiction; to be in opposition.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To leave or make balks in.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To leave heaped up; to heap up in piles.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "avoid",
            "refuse",
            "shirk",
            "shun",
            "baffle",
            "foil",
            "frustrate",
            "thwart",
            "miss",
            "overlook",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To indicate to fishermen, by shouts or signals from shore, the direction taken by the shoals of herring.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/balk",
        "https://en.wiktionary.org/wiki/balks",
      ],
    },
  ],
  sines: [
    {
      word: "sines",
      phonetic: "/saɪnz/",
      phonetics: [{ text: "/saɪnz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "In a right triangle, the ratio of the length of the side opposite an angle to the length of the hypotenuse.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["sin"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sine",
        "https://en.wiktionary.org/wiki/sines",
      ],
    },
  ],
  sigma: [
    {
      word: "sigma",
      phonetic: "/ˈsɪɡmə/",
      phonetics: [
        {
          text: "/ˈsɪɡmə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sigma-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=36786769",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The eighteenth letter of the Classical and Modern Greek alphabets, the twentieth letter of Old and Ancient.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The symbol Σ, used to indicate summation of a set or series.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The symbol σ, used to indicate one standard deviation from the mean, particularly in a normal distribution.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sigma"],
    },
  ],
  abase: [
    {
      word: "abase",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/abase-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75729284",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/əˈbeɪs/", audio: "" },
        {
          text: "/əˈbeɪs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/abase-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1755009",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To lower, as in condition in life, office, rank, etc., so as to cause pain or hurt feelings; to degrade, to depress, to humble, to humiliate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To lower physically; to depress; to cast or throw down; to stoop.",
              synonyms: [],
              antonyms: [],
              example: "to abase the eye",
            },
            {
              definition:
                "To lower in value, in particular by altering the content of alloys in coins; to debase.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "downgrade",
            "bring down",
            "lower",
            "reduce",
            "degrade",
            "demean",
            "depress",
            "discredit",
            "disgrace",
            "dishonor",
            "humble",
            "humiliate",
            "sink",
          ],
          antonyms: [
            "aggrandise",
            "dignify",
            "elevate",
            "exalt",
            "extol",
            "honor",
            "promote",
            "raise",
            "uplift",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/abase"],
    },
  ],
  ennui: [
    {
      word: "ennui",
      phonetic: "/ɒnˈwiː/",
      phonetics: [
        { text: "/ɒnˈwiː/", audio: "" },
        {
          text: "/ɑnˈwi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ennui-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1505928",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A gripping listlessness or melancholia caused by boredom; depression.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["acedia", "boredom", "weltschmerz"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make bored or listless; to weary.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ennui"],
    },
  ],
  gores: [
    {
      word: "gores",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(of an animal) To pierce with the horn.",
              synonyms: [],
              antonyms: [],
              example: "The bull gored the matador.",
            },
            {
              definition: "To pierce with anything pointed, such as a spear.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A triangular piece of land where roads meet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small piece of land left unincorporated due to competing surveys or a surveying error.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The curved surface that lies between two close lines of longitude on a globe",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A triangular or rhomboid piece of fabric, especially one forming part of a three-dimensional surface such as a sail, skirt, hot-air balloon, etc.Wp",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An elastic gusset for providing a snug fit in a shoe.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A projecting point.", synonyms: [], antonyms: [] },
            {
              definition:
                "One of the abatements, made of two curved lines, meeting in an acute angle in the fesse point.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cut in a triangular form.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To provide with a gore.",
              synonyms: [],
              antonyms: [],
              example: "to gore an apron",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gore",
        "https://en.wiktionary.org/wiki/gores",
      ],
    },
  ],
  unset: [
    {
      word: "unset",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make not set.",
              synonyms: [],
              antonyms: [],
              example: "to unset a single bit in a binary pattern",
            },
          ],
          synonyms: ["clear"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Not set; not fixed or appointed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Not mounted or placed in a setting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Not set, as a broken limb.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Not planted.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/unset"],
    },
  ],
  augur: [
    {
      word: "augur",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/augur-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75729668",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈɔː.ɡə/", audio: "" },
        { text: "/ˈɔ.ɡɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A diviner who foretells events by the behaviour of birds or other animals, or by signs derived from celestial phenomena, or unusual occurrences.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An official who interpreted omens before the start of public events.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To foretell events; to exhibit signs of future events.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To anticipate, to foretell, or to indicate a favorable or an unfavorable issue.",
              synonyms: [],
              antonyms: [],
              example: "to augur well or ill",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/augur"],
    },
  ],
  sated: [
    {
      word: "sated",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To satisfy the appetite or desire of; to fill up.",
              synonyms: ["fill up", "satiate"],
              antonyms: [],
              example: "At last he stopped, his hunger and thirst sated.",
            },
          ],
          synonyms: ["fill up", "satiate"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "In a state of complete and thorough satisfaction; having ones appetite fully satisfied, by having enough of something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Quelled of thirst or hunger.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["full", "satiate", "satiated", "satisfied"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sate",
        "https://en.wiktionary.org/wiki/sated",
      ],
    },
  ],
  odium: [
    {
      word: "odium",
      phonetic: "/ˈəʊ.di.əm/",
      phonetics: [
        {
          text: "/ˈəʊ.di.əm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/odium-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90007682",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Hatred; dislike.",
              synonyms: [],
              antonyms: [],
              example:
                "His conduct brought him into odium, or, brought odium upon him.",
            },
            {
              definition: "The quality that provokes hatred; offensiveness.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/odium"],
    },
  ],
  latin: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  dings: [
    {
      word: "dings",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Very minor damage, a small dent or chip.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A rejection.",
              synonyms: [],
              antonyms: [],
              example: "I just got my first ding letter.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To hit or strike.", synonyms: [], antonyms: [] },
            {
              definition: "To dash; to throw violently.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To inflict minor damage upon, especially by hitting or striking.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fire or reject.",
              synonyms: [],
              antonyms: [],
              example: "His top school dinged him last week.",
            },
            {
              definition:
                "To deduct, as points, from another, in the manner of a penalty; to penalize.",
              synonyms: [],
              antonyms: [],
              example:
                "My bank dinged me three bucks for using their competitor's ATM.",
            },
            {
              definition: "To mishit (a golf ball).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The high-pitched resonant sound of a bell.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The act of levelling up.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make high-pitched sound like a bell.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To keep repeating; impress by reiteration, with reference to the monotonous striking of a bell.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To level up.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An ancient Chinese vessel with legs and a lid.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ding",
        "https://en.wiktionary.org/wiki/dings",
      ],
    },
  ],
  moire: [
    {
      word: "moire",
      phonetic: "/mwɑː/",
      phonetics: [{ text: "/mwɑː/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Originally, a fine textile fabric made of the hair of an Asiatic goat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any textile fabric to which a watered appearance is given.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/moire"],
    },
  ],
  scion: [
    {
      word: "scion",
      phonetic: "/ˈsaɪən/",
      phonetics: [
        { text: "/ˈsaɪən/", audio: "" },
        {
          text: "/ˈsaɪ.ɑn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scion-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=6626951",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A descendant, especially a first-generation descendant of a distinguished family.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The heir to a throne.", synonyms: [], antonyms: [] },
            { definition: "A guardian.", synonyms: [], antonyms: [] },
            {
              definition:
                "A detached shoot or twig containing buds from a woody plant, used in grafting; a shoot or twig in a general sense.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scion"],
    },
  ],
  henna: [
    {
      word: "henna",
      phonetic: "/hɛnə/",
      phonetics: [{ text: "/hɛnə/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A shrub, Lawsonia inermis, having fragrant reddish flowers",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A reddish plant substance, prepared from the dried leaves of this plant, used for temporary tattoos and hair coloring. Hair colorings range from bright red to earth brown to near black.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A rich reddish-brown colour.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To dye or tattoo with henna.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of a rich reddish-brown colour.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/henna"],
    },
  ],
  kraut: [
    {
      word: "kraut",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A dish made by fermenting finely chopped cabbage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A German person.",
              synonyms: ["Kraut"],
              antonyms: [],
            },
          ],
          synonyms: ["Kraut"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/kraut",
        "https://en.wiktionary.org/wiki/sauerkraut",
      ],
    },
    {
      word: "kraut",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A German.",
              synonyms: ["Boche", "Fritz", "Hun", "jerry", "sauerkraut"],
              antonyms: [],
            },
          ],
          synonyms: ["Boche", "Fritz", "Hun", "jerry", "sauerkraut"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/Kraut",
        "https://en.wiktionary.org/wiki/kraut",
      ],
    },
  ],
  lifer: [
    {
      word: "lifer",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A prisoner sentenced to life in prison.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A prisoner sentenced to transportation for life.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person with a singular career path, especially in the military.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bird species seen for the first time by a birder who is keeping a list of all the species he or she has ever seen.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["life bird"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lifer"],
    },
  ],
  prigs: [
    {
      word: "prigs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A tinker.", synonyms: [], antonyms: [] },
            {
              definition: "A petty thief or pickpocket.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A deliberately superior person; a person who demonstrates an exaggerated conformity or propriety, especially in an irritatingly arrogant or smug manner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A conceited dandy; a fop.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["goody-goody", "prude", "puritan"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/prig",
        "https://en.wiktionary.org/wiki/prigs",
      ],
    },
  ],
  bebop: [
    {
      word: "bebop",
      phonetic: "/ˈbiːbɒp/",
      phonetics: [{ text: "/ˈbiːbɒp/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An early form of modern jazz played by small groups and featuring driving rhythms and complex, often dissonant harmonies.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To participate in bebop jazz, such as by dancing in a way associated with the genre.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(usually with a directional preposition) To walk in an easygoing, carefree manner.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bebop"],
    },
  ],
  gages: [
    {
      word: "gages",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Something, such as a glove or other pledge, thrown down as a challenge to combat (now usually figurative).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something valuable deposited as a guarantee or pledge; security, ransom.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A subspecies of plum, Prunus domestica subsp. italica.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A measure; a standard of measure; an instrument to determine dimensions, distance, or capacity; a standard",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An act of measuring.", synonyms: [], antonyms: [] },
            { definition: "An estimate.", synonyms: [], antonyms: [] },
            {
              definition:
                "Any instrument for ascertaining or regulating the level, state, dimensions or forms of things",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A thickness of sheet metal or wire designated by any of several numbering schemes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The distance between the rails of a railway.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A semi-norm; a function that assigns a non-negative size to all vectors in a vector space.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The number of stitches per inch, centimetre, or other unit of distance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Relative positions of two or more vessels with reference to the wind.",
              synonyms: [],
              antonyms: [],
              example:
                "A vessel has the weather gauge of another when on the windward side of it, and the lee gauge when on the lee side of it.",
            },
            {
              definition: "The depth to which a vessel sinks in the water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(plastering) The quantity of plaster of Paris used with common plaster to make it set more quickly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That part of a shingle, slate, or tile, which is exposed to the weather, when laid; also, one course of such shingles, slates, or tiles.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A unit of measurement which describes how many spheres of bore diameter of a shotgun can be had from one pound of lead; 12 gauge is roughly equivalent to .75 caliber.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) A shotgun (synecdoche for 12 gauge shotgun, the most common chambering for combat and hunting shotguns).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A tunnel-like ear piercing consisting of a hollow ring embedded in the lobe.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gage",
        "https://en.wiktionary.org/wiki/gages",
        "https://en.wiktionary.org/wiki/gauge",
      ],
    },
  ],
  gazer: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  fanny: [
    {
      word: "fanny",
      phonetic: "/ˈfæni/",
      phonetics: [
        {
          text: "/ˈfæni/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fanny-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75269406",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈfæni/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fanny-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=53353089",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The female genitalia.",
              synonyms: [],
              antonyms: [],
              example: "Her dress was so short you could nearly see her fanny.",
            },
            {
              definition:
                "The buttocks; arguably the most nearly polite of several euphemisms.",
              synonyms: [],
              antonyms: [],
              example:
                "Children, sit down on your fannies, and eat your lunch.",
            },
            {
              definition: "Sexual intercourse with a woman.",
              synonyms: [],
              antonyms: [],
              example: "get some fanny tonight",
            },
            {
              definition: "Women viewed as sexual objects.",
              synonyms: [],
              antonyms: [],
              example: "This club is full of fanny.",
            },
          ],
          synonyms: [
            "arse",
            "ass",
            "booty",
            "bum",
            "butt",
            "hiney",
            "keester",
            "tush",
            "tushie",
            "bearded clam",
            "beaver",
            "beddemann shoe",
            "beef curtains",
            "box",
            "bush",
            "clunge",
            "cooch",
            "coochie",
            "cunt",
            "flange",
            "front bottom",
            "front bum",
            "gash",
            "ham wallet",
            "kebab",
            "lips",
            "minge",
            "muff",
            "pork pit",
            "pussy",
            "quim",
            "slit",
            "snatch",
            "the pink",
            "twat",
            "vertical smile",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fanny"],
    },
    {
      word: "fanny",
      phonetic: "/ˈfæni/",
      phonetics: [
        {
          text: "/ˈfæni/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fanny-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75269406",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈfæni/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fanny-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=53353089",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(naval slang) Mess kettle or cooking pot.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fanny"],
    },
  ],
  gibes: [
    {
      word: "gibes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "Alternative spelling of gybe",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Alternative spelling of jibe",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gibe",
        "https://en.wiktionary.org/wiki/gibes",
      ],
    },
  ],
  aural: [
    {
      word: "aural",
      phonetic: "/ˈɔːɹəl/",
      phonetics: [{ text: "/ˈɔːɹəl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of or pertaining to the ear.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of or pertaining to sound.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/aural"],
    },
    {
      word: "aural",
      phonetic: "/ˈɔːɹəl/",
      phonetics: [{ text: "/ˈɔːɹəl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of or pertaining to an aura.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/aural"],
    },
  ],
  tempi: [
    {
      word: "tempi",
      phonetic: "/ˈtɛmpiː/",
      phonetics: [{ text: "/ˈtɛmpiː/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A frequency or rate.", synonyms: [], antonyms: [] },
            {
              definition:
                "A move which is part of one's own plan or strategy and forces, e.g. by means of a check or attacking a piece, the opponent to make a move which is not bad but of no use for him (the player gains a tempo, the opponent loses a tempo), or equivalently a player achieves the same result in fewer moves by one approach rather than another.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The timing advantage of being on lead, thus being first to initiate a strategy to develop tricks for one's side.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The timing of a particular event – earlier or later than in an alternative situation (as in chess example)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The number of beats per minute in a piece of music; also, an indicative term denoting approximate rate of speed in written music (examples: allegro, andante)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The steady pace set by the frontmost riders.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small truck or cargo van with three or four wheels, commonly used for commercial transport and deliveries (particularly in Asian and African countries): a genericized trademark, originally associated with the manufacturer Vidal & Sohn Tempo-Werke GmbH.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A rapid rate of play by the offense resulting from reducing the amount of time which elapses after one play ends and the next starts.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tempi",
        "https://en.wiktionary.org/wiki/tempo",
      ],
    },
  ],
  hooch: [
    {
      word: "hooch",
      phonetic: "/hutʃ/",
      phonetics: [
        {
          text: "/hutʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hooch-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241503",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Alcoholic liquor, especially inferior or illicit whisky.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["moonshine"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hooch"],
    },
    {
      word: "hooch",
      phonetic: "/hutʃ/",
      phonetics: [
        {
          text: "/hutʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hooch-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241503",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A thatched hut, CHU, or any simple dwelling.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hooch"],
    },
  ],
  rapes: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  snuck: [
    {
      word: "snuck",
      phonetic: "/snʌk/",
      phonetics: [{ text: "/snʌk/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To creep or go stealthily; to come or go while trying to avoid detection, as a person who does not wish to be seen.",
              synonyms: ["skulk"],
              antonyms: [],
              example:
                "He decided to sneak into the kitchen for a second cookie while his mom was on the phone.",
            },
            {
              definition: "To take something stealthily without permission.",
              synonyms: [],
              antonyms: [],
              example: "I went to sneak a chocolate but my dad caught me.",
            },
            {
              definition:
                "(ditransitive) To stealthily bring someone something.",
              synonyms: [],
              antonyms: [],
              example: "She asked me to sneak her a phone next month.",
            },
            {
              definition: "To hide, especially in a mean or cowardly manner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(with on) To inform an authority of another's misdemeanours.",
              synonyms: ["grass", "snitch", "tell tales"],
              antonyms: [],
              example: "If you sneak on me I'll bash you!",
            },
          ],
          synonyms: ["grass", "snitch", "tell tales", "skulk"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sneak",
        "https://en.wiktionary.org/wiki/snuck",
      ],
    },
  ],
  harts: [
    {
      word: "harts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A male deer, especially the male of the red deer after his fifth year.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A red deer or one of related species.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A muscular organ that pumps blood through the body, traditionally thought to be the seat of emotion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Emotions, kindness, moral effort, or spirit in general.",
              synonyms: [],
              antonyms: [],
              example: "The team lost, but they showed a lot of heart.",
            },
            {
              definition:
                "The seat of the affections or sensibilities, collectively or separately, as love, hate, joy, grief, courage, etc.; rarely, the seat of the understanding or will; usually in a good sense; personality.",
              synonyms: [],
              antonyms: [],
              example: "a good, tender, loving, bad, hard, or selfish heart",
            },
            {
              definition: "Courage; courageous purpose; spirit.",
              synonyms: ["bravery", "nerve"],
              antonyms: [],
            },
            {
              definition:
                "Vigorous and efficient activity; power of fertile production; condition of the soil, whether good or bad.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A term of affectionate or kindly and familiar address.",
              synonyms: ["honey", "sugar"],
              antonyms: [],
              example: "Listen, dear heart, we must go now.",
            },
            {
              definition: "Personality, disposition.",
              synonyms: [],
              antonyms: [],
              example: "a cold heart",
            },
            { definition: "A wight or being.", synonyms: [], antonyms: [] },
            {
              definition:
                "A conventional shape or symbol used to represent the heart, love, or emotion: ♥ or sometimes <3.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A playing card of the suit hearts featuring one or more heart-shaped symbols.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The twenty-fourth Lenormand card.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The centre, essence, or core.",
              synonyms: ["crux", "gist"],
              antonyms: [],
              example:
                "Buddhists believe that suffering is right at the heart of all life.",
            },
          ],
          synonyms: ["bravery", "nerve", "crux", "gist", "honey", "sugar"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hart",
        "https://en.wiktionary.org/wiki/harts",
        "https://en.wiktionary.org/wiki/heart",
      ],
    },
  ],
  techs: [
    {
      word: "techs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Technology", synonyms: [], antonyms: [] },
            { definition: "Technician", synonyms: [], antonyms: [] },
            { definition: "Technique", synonyms: [], antonyms: [] },
            {
              definition: "(used in titles) technical college",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tech",
        "https://en.wiktionary.org/wiki/techs",
      ],
    },
  ],
  emend: [
    {
      word: "emend",
      phonetic: "/ɪˈmɛnd/",
      phonetics: [
        { text: "/ɪˈmɛnd/", audio: "" },
        { text: "/ɪˈmɛnd/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To correct and revise (text or a document).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/emend"],
    },
  ],
  ninny: [
    {
      word: "ninny",
      phonetic: "/ˈnɪni/",
      phonetics: [{ text: "/ˈnɪni/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A silly or foolish person.",
              synonyms: ["dummkopf"],
              antonyms: [],
            },
          ],
          synonyms: ["dummkopf"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ninny"],
    },
  ],
  guava: [
    {
      word: "guava",
      phonetic: "/ˈɡwɑvə/",
      phonetics: [{ text: "/ˈɡwɑvə/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A tropical tree or shrub of the myrtle family, Psidium guajava.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Its yellowish tropical fruit, 1¼ to 2 inches, globular or pear-shaped with thin, yellow, green or brown skin, is often made into jams and jellies. The meat is yellowish or pale green to pink in color.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A medium reddish-pink colour, like that of guava flesh (also called guava pink).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/guava"],
    },
  ],
  scarp: [
    {
      word: "scarp",
      phonetic: "/skɑːp/",
      phonetics: [
        {
          text: "/skɑːp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scarp-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88902741",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/skɑɹp/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The steep artificial slope below a fort's parapet",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cliff at the edge of a plateau or ridge caused by erosion or faulting; the steeper side of an escarpment",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(earth science) to cut, scrape, erode, or otherwise make into a scarp or escarpment",
              synonyms: [],
              antonyms: [],
              example: "to scarp the face of a ditch or a rock",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scarp"],
    },
  ],
  liege: [
    {
      word: "liege",
      phonetic: "/liːdʒ/",
      phonetics: [
        {
          text: "/liːdʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/liege-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=81997221",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/liːdʒ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A free and independent person; specifically, a lord paramount; a sovereign.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(in full liege lord) A king or lord.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The subject of a sovereign or lord; a liegeman.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Sovereign; independent; having authority or right to allegiance.",
              synonyms: [],
              antonyms: [],
              example: "a liege lord",
            },
            {
              definition:
                "Serving an independent sovereign or master; bound by a feudal tenure; obliged to be faithful and loyal to a superior, such as a vassal to his lord; faithful.",
              synonyms: [],
              antonyms: [],
              example: "a liege man; a liege subject",
            },
            {
              definition: "Full; perfect; complete; pure.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/liege"],
    },
  ],
  tufty: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  sepia: [
    {
      word: "sepia",
      phonetic: "/ˈsiːpiə/",
      phonetics: [{ text: "/ˈsiːpiə/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A dark brown pigment made from the secretions of the cuttlefish.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A dark, slightly reddish, brown colour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) A sepia-coloured drawing or photograph.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The cuttlefish.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "(colour)  Of a dark reddish-brown colour.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sepia"],
    },
  ],
  tomes: [
    {
      word: "tomes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One in a series of volumes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A large or scholarly book.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tome",
        "https://en.wiktionary.org/wiki/tomes",
      ],
    },
  ],
  carob: [
    {
      word: "carob",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An evergreen shrub or tree, Ceratonia siliqua, native to the Mediterranean region.",
              synonyms: ["St. John's bread"],
              antonyms: [],
            },
            {
              definition: "The fruit of that tree.",
              synonyms: ["locust bean"],
              antonyms: [],
            },
            {
              definition:
                "A sweet chocolate-like confection made with the pulp of the fruit.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["St. John's bread", "locust bean"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/carob"],
    },
  ],
  emcee: [
    {
      word: "emcee",
      phonetic: "/ˌemˈsiː/",
      phonetics: [{ text: "/ˌemˈsiː/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A rapper.", synonyms: [], antonyms: [] },
            {
              definition: "Initialism of main colour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Initialism of matching colour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Initialism of movie clip.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Initialism of mail code.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Initialism of main character.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Initialism of military cross.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Initialism of Member of Congress.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Initialism of medical college.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Initialism of Mars-crosser.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Initialism of medium coeli, the midheaven or highest point in the sky. Contrasted to Imum Coeli.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A medical facility, such as a hospital, especially one for the treatment and diagnosis of outpatients.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) A hospital session to diagnose or treat patients.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A school, or a session of a school or class, in which medicine or surgery is taught by the examination and treatment of patients in the presence of the pupils.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A group practice of several physicians.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A meeting for the diagnosis of problems, or training, on a particular subject.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A temporary office arranged on a regular basis to allow politicians to meet their constituents.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A series of workouts used to build skills of practitioners regardless of team affiliation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One confined to bed by sickness.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One who receives baptism on a sickbed.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To act as the master of ceremonies (for).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To rap as part of a hip-hop performance.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["compere", "rap"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A large medical facility, usually in a building with multiple floors, where seriously ill or injured patients are given extensive medical and/or surgical treatment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A building founded for the long-term care of its residents, such as an almshouse. The residents may have no physical ailments, but simply need financial support.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A place of lodging.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["infirmary", "sickhouse"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Someone who acts as a host at a formal event.",
              synonyms: ["MC", "compere", "host", "presenter", "toastmaster"],
              antonyms: [],
            },
            {
              definition:
                "Someone who conducts a programme of varied entertainment, for example by introducing performers to the audience.",
              synonyms: ["MC", "interlocuter"],
              antonyms: [],
            },
            {
              definition: "A rapper.",
              synonyms: ["MC", "emcee"],
              antonyms: [],
            },
          ],
          synonyms: [
            "MC",
            "compere",
            "host",
            "presenter",
            "toastmaster",
            "MC",
            "emcee",
            "MC",
            "interlocuter",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Dichloromethane", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A viral infection of the skin or (occasionally) mucous membranes, caused by Molluscum contagiosum virus (MCV), a DNA poxvirus.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["MC", "water wart"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A group of individuals whose primary interests and activities involve motorcycles.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A place used as a hospital on board a ship, on a spaceship (in science fiction).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A room or area for the treatment of the sick or injured in a school.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["infirmary"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/MC",
        "https://en.wiktionary.org/wiki/clinic",
        "https://en.wiktionary.org/wiki/emcee",
        "https://en.wiktionary.org/wiki/hospital",
        "https://en.wiktionary.org/wiki/master%20of%20ceremonies",
        "https://en.wiktionary.org/wiki/medical%20center",
        "https://en.wiktionary.org/wiki/methylene%20chloride",
        "https://en.wiktionary.org/wiki/molluscum%20contagiosum",
        "https://en.wiktionary.org/wiki/motorcycle%20club",
        "https://en.wiktionary.org/wiki/sickbay",
      ],
    },
  ],
  prams: [
    {
      word: "prams",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small vehicle, usually covered, in which a newborn baby is pushed around in a lying position; a perambulator.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["baby carriage"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A flat-bottomed barge used on shallow shores to convey cargo to and from ships that cannot enter the harbour.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A similar barge used as platform for cannons in shallow waters which seagoing warships cannot enter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A type of dinghy with a flat bow.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pram",
        "https://en.wiktionary.org/wiki/prams",
      ],
    },
  ],
  poser: [
    {
      word: "poser",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/poser-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241751",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈpəʊzə(ɹ)/", audio: "" },
        { text: "/ˈpoʊzɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A particularly difficult question or puzzle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Someone who asks a question or sets a problem.",
              synonyms: [],
              antonyms: [],
              example:
                "Even as a child, she was a habitual poser of difficult questions.",
            },
            {
              definition:
                "Someone who, or something which, poses; a person who sets their body in a fixed position, such as for photography or painting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A poseur; someone who affects some behaviour, style, attitude or other condition, often to impress or influence others.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/poser"],
    },
  ],
  verso: [
    {
      word: "verso",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The back side of a flat object which is to be examined visually, as for reading, such as a sheet, leaf, coin or medal;",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The left-hand page of a book of a script which reads from left to right, usually having an even page number.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["back", "backside", "flipside", "reverse"],
          antonyms: ["obverse", "recto"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/verso"],
    },
  ],
  joule: [
    {
      word: "joule",
      phonetic: "/dʒaʊl/",
      phonetics: [
        {
          text: "/dʒaʊl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/joule-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90913983",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "In the International System of Units, the derived unit of energy, work and heat; the work required to exert a force of one newton for a distance of one metre. Also equal to the energy of one watt of power for a duration of one second. Symbol: J",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/joule"],
    },
  ],
  baize: [
    {
      word: "baize",
      phonetic: "/beɪz/",
      phonetics: [{ text: "/beɪz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A thick, soft, usually woolen cloth resembling felt; often colored green and used for coverings on card tables, billiard and snooker tables, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A coarse woolen material with a long nap; usually dyed in plain colors.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cover or line with baize.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/baize"],
    },
  ],
  blips: [
    {
      word: "blips",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To emit one or more bleeps.",
              synonyms: [],
              antonyms: [],
              example: "The robot bleeped to acknowledge its new instructions.",
            },
            {
              definition:
                "To edit out inappropriate spoken language in a broadcast by replacing offending words with bleeps.",
              synonyms: ["blip"],
              antonyms: [],
            },
          ],
          synonyms: ["blip"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small dot registered on electronic equipment, such as a radar or oscilloscope screen.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A short sound of a single pitch, usually electronically generated.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) A brief and usually minor aberration or deviation from what is expected or normal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An individual message or document in the Google Wave software framework.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To change state abruptly, such as between off and on or dark and light, sometimes implying motion.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bleep",
        "https://en.wiktionary.org/wiki/blip",
        "https://en.wiktionary.org/wiki/blips",
      ],
    },
  ],
  scrim: [
    {
      word: "scrim",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A kind of light cotton or linen fabric, often woven in openwork patterns, -- used for curtains, etc,.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A large military scarf, usually camouflage coloured and used for concealment when not used as a scarf.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A woven, nonwoven or knitted fabric composed of continuous strands of material used for reinforcing or strengthening membranes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A theater drop that appears opaque when a scene in front is lighted and transparent or translucent when a scene in back is lighted.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sheet of gauze etc. used to reduce the intensity of light.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Thin canvas glued on the inside of panels to prevent shrinking, checking, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To use a scrim.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scrim"],
    },
    {
      word: "scrim",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A practice match between one or more organized teams usually in preparation for a more competitive format, such as a tournament.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To participate in a scrim.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scrim"],
    },
  ],
  cubby: [
    {
      word: "cubby",
      phonetic: "/kʌbi/",
      phonetics: [
        { text: "/kʌbi/", audio: "" },
        { text: "/kʌbi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A small, confined space.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In a school classroom, a cell of a shelf for students to place belongings in.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cubby"],
    },
  ],
  clave: [
    {
      word: "clave",
      phonetic: "/kleɪv/",
      phonetics: [
        {
          text: "/kleɪv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/clave-1-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=91208512",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To split or sever something with, or as if with, a sharp instrument.",
              synonyms: [],
              antonyms: [],
              example: "The wings cleaved the foggy air.",
            },
            {
              definition:
                "To break a single crystal (such as a gemstone or semiconductor wafer) along one of its more symmetrical crystallographic planes (often by impact), forming facets on the resulting pieces.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make or accomplish by or as if by cutting.",
              synonyms: [],
              antonyms: [],
              example: "The truck cleaved a path through the ice.",
            },
            {
              definition:
                "To split (a complex molecule) into simpler molecules.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To split.", synonyms: [], antonyms: [] },
            {
              definition:
                "Of a crystal, to split along a natural plane of division.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cling, adhere or stick fast to something; used with to or unto.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/clave",
        "https://en.wiktionary.org/wiki/cleave",
      ],
    },
    {
      word: "clave",
      phonetic: "/ˈklɑːveɪ/",
      phonetics: [
        {
          text: "/ˈklɑːveɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/clave-2-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=91208511",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A characteristic pattern of beats, especially the 3-2 son clave.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Singular of claves", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/clave"],
    },
  ],
  winos: [
    {
      word: "winos",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A chronic or heavy drinker of cheap wine or other alcohol; a drunk or drunkard.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A wine enthusiast; an oenophile.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A hypothetical particle that is the superpartner of the W boson.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wino",
        "https://en.wiktionary.org/wiki/winos",
      ],
    },
  ],
  rearm: [
    {
      word: "rearm",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To replace or restore the weapons or arms of a previously defeated, or disarmed army, country, person or other body.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rearm"],
    },
  ],
  liens: [
    {
      word: "liens",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A tendon.", synonyms: [], antonyms: [] },
            {
              definition:
                "A right to take possession of a debtor’s property as security until a debt or duty is discharged.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lien",
        "https://en.wiktionary.org/wiki/liens",
      ],
    },
  ],
  lumen: [
    {
      word: "lumen",
      phonetic: "/ˈluːmən/",
      phonetics: [
        {
          text: "/ˈluːmən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lumen-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1869892",
        },
        { text: "/ˈlumən/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "In the International System of Units, the derived unit of luminous flux; the light that is emitted in a solid angle of one steradian from a source of one candela. Symbol: lm.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The cavity or channel within a tube or tubular organ.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The cavity bounded by a plant cell wall.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The bore of a tube such as a hollow needle or catheter.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lumen"],
    },
  ],
  chump: [
    {
      word: "chump",
      phonetic: "/tʃʌmp/",
      phonetics: [
        {
          text: "/tʃʌmp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chump-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=694223",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To bite or chew loudly or heavily.",
              synonyms: [],
              antonyms: [],
              example:
                "The dog chomped on the treat and swallowed it in one gulp.",
            },
            {
              definition:
                "(Perl) To remove the final character from (a text string) if it is a newline (or, less commonly, some other programmer-specified character).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An incompetent person, a blockhead; a loser.",
              synonyms: [],
              antonyms: [],
              example:
                "That chump wouldn't know his ass from a hole in the ground.",
            },
            {
              definition:
                "A gullible person; a sucker; someone easily taken advantage of; someone lacking common sense.",
              synonyms: [],
              antonyms: [],
              example: "It shouldn't be hard to put one over on that chump.",
            },
            {
              definition:
                "The thick end, especially of a piece of wood or of a joint of meat.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "dummy",
            "dupe",
            "gull",
            "patsy",
            "pigeon",
            "sap",
            "sucker",
            "blockhead",
            "dolt",
            "dope",
            "dummy",
            "dunce",
            "idiot",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/chomp",
        "https://en.wiktionary.org/wiki/chump",
      ],
    },
  ],
  nanny: [
    {
      word: "nanny",
      phonetic: "/ˈnæni/",
      phonetics: [{ text: "/ˈnæni/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A child's nurse.", synonyms: [], antonyms: [] },
            { definition: "A grandmother.", synonyms: [], antonyms: [] },
            { definition: "A godmother.", synonyms: [], antonyms: [] },
            { definition: "A female goat.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["housemother", "nanny goat"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To serve as a nanny.", synonyms: [], antonyms: [] },
            {
              definition: "To treat like a nanny's charges; to coddle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/nanny"],
    },
  ],
  trump: [
    {
      word: "trump",
      phonetic: "/tɹʌmp/",
      phonetics: [
        {
          text: "/tɹʌmp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/trump-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80152077",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/tɹʌmp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/trump-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=7467984",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The suit, in a game of cards, that outranks all others.",
              synonyms: [],
              antonyms: [],
              example: "Diamonds were declared trump(s).",
            },
            {
              definition: "A playing card of that suit.",
              synonyms: [],
              antonyms: [],
              example: "He played an even higher trump.",
            },
            {
              definition:
                "Something that gives one an advantage, especially one held in reserve.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An excellent person; a fine fellow, a good egg.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An old card game, almost identical to whist; the game of ruff.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A card of the major arcana of the tarot.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To play on (a card of another suit) with a trump.",
              synonyms: [],
              antonyms: [],
              example: "He knew the hand was lost when his ace was trumped.",
            },
            {
              definition: "To play a trump, or to take a trick with a trump.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To get the better of, or finesse, a competitor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To impose unfairly; to palm off.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To supersede.",
              synonyms: [],
              antonyms: [],
              example:
                "In this election, it would seem issues of national security trumped economic issues.",
            },
            {
              definition:
                "To outweigh; be stronger, greater, bigger than or in other way superior to.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["outsmart", "ruff"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/trump"],
    },
    {
      word: "trump",
      phonetic: "/tɹʌmp/",
      phonetics: [
        {
          text: "/tɹʌmp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/trump-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80152077",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/tɹʌmp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/trump-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=7467984",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A trumpet.", synonyms: [], antonyms: [] },
            { definition: "Flatulence.", synonyms: [], antonyms: [] },
            {
              definition: "The noise made by an elephant through its trunk.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To blow a trumpet.", synonyms: [], antonyms: [] },
            {
              definition: "To flatulate.",
              synonyms: [],
              antonyms: [],
              example:
                "And without warning me, as he lay there, he suddenly trumped next to me in bed.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/trump"],
    },
    {
      word: "trump",
      phonetic: "/tɹʌmp/",
      phonetics: [
        {
          text: "/tɹʌmp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/trump-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80152077",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/tɹʌmp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/trump-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=7467984",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A musical instrument consisting of a flexible metal or bamboo “tongue” attached to a frame. This tongue is placed in the performer’s mouth and plucked with the finger to produce a note of constant pitch. Melodies can be created by changing the shape of the mouth and causing different overtones.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "Jew's-trump",
            "trump",
            "Judaic harp",
            "mouth harp",
            "mouth organ",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/Jew's%20harp",
        "https://en.wiktionary.org/wiki/trump",
      ],
    },
  ],
  fichu: [
    {
      word: "fichu",
      phonetic: "/ˈfiː-/",
      phonetics: [
        {
          text: "/ˈfiː-/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fichu-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=91373117",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈfi-/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A woman's lightweight triangular scarf worn over the shoulders and tied in front, or tucked into a bodice to cover the exposed part of the neck and chest.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fichu"],
    },
  ],
  chomp: [
    {
      word: "chomp",
      phonetic: "/tʃɒmp/",
      phonetics: [
        { text: "/tʃɒmp/", audio: "" },
        {
          text: "/t͡ʃɔmp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chomp-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=924927",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act of chomping (see below)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To bite or chew loudly or heavily.",
              synonyms: [],
              antonyms: [],
              example:
                "The dog chomped on the treat and swallowed it in one gulp.",
            },
            {
              definition:
                "(Perl) To remove the final character from (a text string) if it is a newline (or, less commonly, some other programmer-specified character).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chomp"],
    },
  ],
  homos: [
    {
      word: "homos",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "A human.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/homo",
        "https://en.wiktionary.org/wiki/homos",
      ],
    },
  ],
  purty: [
    {
      word: "purty",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Pleasant to the sight or other senses; attractive, especially of women or children, but less strikingly than something beautiful.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of objects or things: nice-looking, appealing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Fine-looking; only superficially attractive; initially appealing but having little substance; see petty.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Cunning; clever, skilful.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Moderately large; considerable.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Excellent, commendable, pleasing; fitting or proper (of actions, thoughts etc.).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Awkward, unpleasant.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: ["ugly"],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition:
                "Somewhat, fairly, quite; sometimes also (by meiosis) very.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Prettily, in a pretty manner.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pretty",
        "https://en.wiktionary.org/wiki/purty",
      ],
    },
  ],
  maser: [
    {
      word: "maser",
      phonetic: "/ˈmeɪzɚ/",
      phonetics: [
        {
          text: "/ˈmeɪzɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/maser-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=81996973",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A device for the coherent amplification or generation of electromagnetic radiation (especially of microwave frequency) by the use of excitation energy in resonant atomic or molecular systems",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any celestial object that generates microwaves using the same method",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/maser"],
    },
    {
      word: "maser",
      phonetic: "/ˈmeɪzɚ/",
      phonetics: [
        {
          text: "/ˈmeɪzɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/maser-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=81996973",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The maple tree, or maple wood.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A large drinking bowl made from such wood; a mazer bowl.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/maser",
        "https://en.wiktionary.org/wiki/mazer",
      ],
    },
  ],
  woosh: [
    {
      word: "woosh",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A breathy sound like that of an object passing at high speed.",
              synonyms: [],
              antonyms: [],
              example:
                '"I love deadlines. I like the whooshing sound they make as they fly by." —Douglas Adams',
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make a breathy sound like a whoosh.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/whoosh",
        "https://en.wiktionary.org/wiki/woosh",
      ],
    },
  ],
  patsy: [
    {
      word: "patsy",
      phonetic: "/ˈpætsi/",
      phonetics: [{ text: "/ˈpætsi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person who is taken advantage of, especially by being cheated or blamed for something.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["scapegoat", "sucker"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/patsy"],
    },
  ],
  shill: [
    {
      word: "shill",
      phonetic: "/ʃɪl/",
      phonetics: [
        {
          text: "/ʃɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shill-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75857926",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ʃɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shill-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=8558313",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person paid to endorse a product favourably, while pretending to be impartial.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An accomplice at a confidence trick during an auction or gambling game.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A house player in a casino.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["shillaber"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To promote or endorse in return for payment, especially dishonestly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To put under cover; to sheal.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To shell.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shill"],
    },
  ],
  rusks: [
    {
      word: "rusks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A rectangular, hard, dry biscuit",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A twice-baked bread, slices of bread baked until they are hard and crisp (also called a zwieback)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A weaning food for children",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A cereal binder used in meat product manufacture",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["Brussels biscuit", "twice-baked bread", "zwieback"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rusk",
        "https://en.wiktionary.org/wiki/rusks",
      ],
    },
  ],
  avast: [
    {
      word: "avast",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/avast-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79240971",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/əˈvɑːst/", audio: "" },
        { text: "/əˈvæst/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "Hold fast!; desist!; stay!.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(in imitation of pirates) listen!; pay attention!",
              synonyms: [],
              antonyms: [],
              example: "Avast, ye landlubbers!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/avast"],
    },
  ],
  swami: [
    {
      word: "swami",
      phonetic: "/ˈswɑː.mi/",
      phonetics: [{ text: "/ˈswɑː.mi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(used as a title) A Hindu ascetic or religious teacher.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/swami"],
    },
  ],
  boded: [
    {
      word: "boded",
      phonetic: "/ˈbəʊdɪd/",
      phonetics: [
        { text: "/ˈbəʊdɪd/", audio: "" },
        { text: "[ˈboʊ̯dɨd]", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To indicate by signs, as future events; to be an omen of; to portend or foretell.",
              synonyms: ["foreshow", "portend", "presage"],
              antonyms: [],
            },
            {
              definition:
                '(followed by "well", "ill", "no good", etc.) To betoken or augur something good or bad that will happen in the future.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["foreshow", "portend", "presage"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bode",
        "https://en.wiktionary.org/wiki/boded",
      ],
    },
  ],
  ahhhh: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  lobed: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  natch: [
    {
      word: "natch",
      phonetic: "/nætʃ/",
      phonetics: [{ text: "/nætʃ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Naturally; of course.",
              synonyms: [],
              antonyms: [],
              example:
                "The Queen was seen wearing a hat when she visited Ascot, natch.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/natch"],
    },
    {
      word: "natch",
      phonetic: "/nætʃ/",
      phonetics: [{ text: "/nætʃ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The rump of beef, especially the lower and back part of the rump.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/natch"],
    },
    {
      word: "natch",
      phonetic: "/nætʃ/",
      phonetics: [{ text: "/nætʃ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "A notch.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/natch"],
    },
  ],
  shish: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  tansy: [
    {
      word: "tansy",
      phonetic: "/ˈtanzi/",
      phonetics: [
        { text: "/ˈtanzi/", audio: "" },
        { text: "/ˈtæn.zi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A herbaceous plant with yellow flowers, of the genus Tanacetum, especially Tanacetum vulgare.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'A dish common in the seventeenth century, made of eggs, sugar, rose water, cream, and the juice of herbs (including tansy), baked with butter in a shallow dish. "Originally flavoured with tansy, but by Pepys\'s time generally having spinach as its predominant flavouring."',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tansy"],
    },
  ],
  snoot: [
    {
      word: "snoot",
      phonetic: "/snuːt/",
      phonetics: [{ text: "/snuːt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An elitist individual; one who looks down upon lower social classes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A language pedant or snob; one who practices linguistic elitism.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A nose or snout, especially in derogatory use.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'Snout; especially of a dog ("doggo") or snake ("snek").',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cylindrical or conical attachment used on a spotlight to restrict spill light.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To behave disdainfully toward.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/snoot"],
    },
  ],
  payer: [
    {
      word: "payer",
      phonetic: "/ˈpeɪ.ɚ/",
      phonetics: [
        {
          text: "/ˈpeɪ.ɚ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/payer-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=61084568",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One who pays; specifically, the person by whom a bill or note has been, or should be, paid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A swaption which gives its holder the option to enter into a swap in which they pay the fixed leg and receive the floating leg.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["payee"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/payer"],
    },
  ],
  altho: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  sappy: [
    {
      word: "sappy",
      phonetic: "/ˈsæpi/",
      phonetics: [
        {
          text: "/ˈsæpi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sappy-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=859599",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Excessively sweet, emotional, nostalgic; cheesy; mushy. (British equivalent: soppy)",
              synonyms: [],
              antonyms: [],
              example:
                "It was a sappy love song, but it reminded them of their first dance.",
            },
            {
              definition: "Having (a particularly large amount of) sap.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Juicy.", synonyms: [], antonyms: [] },
            {
              definition:
                "(of wood) Spongy; Having spaces in which large quantities of sap can flow.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sappy"],
    },
    {
      word: "sappy",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Musty; tainted; rancid.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sappy"],
    },
  ],
  laxer: [
    {
      word: "laxer",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/laxer-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79520265",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Lenient and allowing for deviation; not strict.",
              synonyms: [],
              antonyms: [],
              example:
                "The rules are fairly lax, but you have to know which ones you can bend.",
            },
            {
              definition: "Loose; not tight or taut.",
              synonyms: [],
              antonyms: [],
              example: "The rope fell lax.",
            },
            {
              definition: "Lacking care; neglectful, negligent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Having a looseness of the bowels; diarrheal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Describing an associative monoidal functor.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "blameworthy",
            "lash",
            "negligent",
            "remiss",
            "reprehensible",
            "lenient",
            "permissive",
            "relaxed",
            "loose",
            "slack",
          ],
          antonyms: ["strict", "taut", "tight"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lax",
        "https://en.wiktionary.org/wiki/laxer",
      ],
    },
    {
      word: "laxer",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/laxer-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79520265",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Lacrosse player", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/laxer"],
    },
  ],
  hubby: [
    {
      word: "hubby",
      phonetic: "/ˈhʌbi/",
      phonetics: [{ text: "/ˈhʌbi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(term of endearment) Husband.",
              synonyms: [],
              antonyms: [],
              example:
                "Laura thought Jack was the most wonderful hubby in the world.",
            },
          ],
          synonyms: ["hubbyman"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hubby"],
    },
    {
      word: "hubby",
      phonetic: "/ˈhʌbi/",
      phonetics: [{ text: "/ˈhʌbi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Full of hubs or protuberances.",
              synonyms: [],
              antonyms: [],
              example: "A road that has been frozen while muddy is hubby.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hubby"],
    },
  ],
  aegis: [
    {
      word: "aegis",
      phonetic: "/ˈiːdʒɪs/",
      phonetics: [
        {
          text: "/ˈiːdʒɪs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/aegis-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=72068518",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈiːdʒɪs/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A mythological shield associated with the Greek deities Zeus and Athena (and their Roman counterparts Jupiter and Minerva) shown as a short cloak made of goatskin worn on the shoulders, more as an emblem of power and protection than a military shield. The aegis of Athena or Minerva is usually shown with a border of snakes and with the head of Medusa in the center.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Usually as under the aegis: guidance, protection; endorsement, sponsorship.",
              synonyms: ["auspices", "patronage", "protection"],
              antonyms: [],
            },
          ],
          synonyms: ["auspices", "patronage", "protection"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/aegis"],
    },
  ],
  riles: [
    {
      word: "riles",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To make angry", synonyms: [], antonyms: [] },
            {
              definition: "To stir or move from a state of calm or order",
              synonyms: [],
              antonyms: [],
              example:
                "It riles me that she never closes the door after she leaves.",
            },
          ],
          synonyms: ["aggravate", "anger", "annoy", "irritate", "vex"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rile",
        "https://en.wiktionary.org/wiki/riles",
      ],
    },
  ],
  ditto: [
    {
      word: "ditto",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ditto-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75730250",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈdɪtəʊ/", audio: "" },
        {
          text: "/ˈdɪtoʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ditto-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2191633",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "That which was stated before, the aforesaid, the above, the same, likewise.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A duplicate or copy of a document, particularly one created by a spirit duplicator.",
              synonyms: [],
              antonyms: [],
              example:
                "Please run off twenty-four dittos of this assignment, for my students.",
            },
            { definition: "A copy; an imitation.", synonyms: [], antonyms: [] },
            {
              definition:
                'A symbol, represented by two apostrophes, inverted commas, or quotation marks (" "), when indicating that the item preceding is to be repeated.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in the plural) A suit of clothes of the same colour throughout.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["do."],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To repeat the aforesaid, the earlier action etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["ape", "echo"],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "As said before, likewise.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition:
                "Used as an expression of agreement with what another person has said, or to indicate that what they have said equally applies to the person being addressed.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ditto"],
    },
  ],
  jazzy: [
    {
      word: "jazzy",
      phonetic: "/ˈdʒæzi/",
      phonetics: [{ text: "/ˈdʒæzi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "In the style of jazz.", synonyms: [], antonyms: [] },
            { definition: "Flashy or showy.", synonyms: [], antonyms: [] },
            {
              definition: "Lacking focus; jittery or jangly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["flashy", "showy", "jazzlike", "jangling", "jittery"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/jazzy"],
    },
  ],
  dingo: [
    {
      word: "dingo",
      phonetic: "/ˈdɪŋɡəʊ/",
      phonetics: [{ text: "/ˈdɪŋɡəʊ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Canis lupus dingo, a wild dog native to Australia.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dingo"],
    },
  ],
  quasi: [
    {
      word: "quasi",
      phonetic: "/ˈkweɪ.zaɪ/",
      phonetics: [
        { text: "/ˈkweɪ.zaɪ/", audio: "" },
        { text: "/ˈkwɑ.zaɪ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Resembling or having a likeness to something",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/quasi"],
    },
  ],
  septa: [
    {
      word: "septa",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A wall separating two cavities; a partition",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A partition that separates the cells of a fruit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A partition that separates the cells of a (septated) fungus.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One of the radial calcareous plates of a coral.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the transverse partitions dividing the shell of a mollusk, or of a rhizopod, into several chambers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One of the transverse partitions dividing the body cavity of an annelid.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A nose piercing that goes through the nasal septum.",
              synonyms: ["septum ring"],
              antonyms: [],
            },
          ],
          synonyms: ["septum ring"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/septa",
        "https://en.wiktionary.org/wiki/septum",
        "https://en.wiktionary.org/wiki/septum%20piercing",
        "https://en.wiktionary.org/wiki/septum%20ring",
      ],
    },
  ],
  peaky: [
    {
      word: "peaky",
      phonetic: "/ˈpiːki/",
      phonetics: [
        {
          text: "/ˈpiːki/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/peaky-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89033114",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Sickly; peaked.", synonyms: [], antonyms: [] },
            {
              definition: "Characterised by peaks.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/peaky"],
    },
  ],
  lorry: [
    {
      word: "lorry",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lorry-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75832746",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈlɒɹi/", audio: "" },
        { text: "/ˈlɔɹi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A motor vehicle for transporting goods, and in some cases people; a truck.",
              synonyms: ["hauler", "rig", "tractor trailer", "truck"],
              antonyms: [],
            },
            {
              definition:
                "A barrow or truck for shifting baggage, as at railway stations.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small cart or wagon used on the tramways in mines to carry coal or rubbish.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A large, low, horse-drawn, four-wheeled wagon without sides; also, a similar wagon modified for use on railways.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["hauler", "rig", "tractor trailer", "truck"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To transport by, or as if by, lorry.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lorry"],
    },
  ],
  heerd: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  bitty: [
    {
      word: "bitty",
      phonetic: "/bɪti/",
      phonetics: [{ text: "/bɪti/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(often in the plural) bitch, girl, woman, especially one that is promiscuous",
              synonyms: [],
              antonyms: [],
              example:
                "He's got it made, hanging around with the bitties all the time.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Containing bits; fragmented.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Very small.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bittie",
        "https://en.wiktionary.org/wiki/bitty",
      ],
    },
  ],
  payee: [
    {
      word: "payee",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One to whom money is paid.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["payer"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/payee"],
    },
  ],
  seamy: [
    {
      word: "seamy",
      phonetic: "/ˈsiːmi/",
      phonetics: [{ text: "/ˈsiːmi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Sordid, squalid or corrupt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Having or showing a seam.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/seamy"],
    },
  ],
  apses: [
    {
      word: "apses",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A semicircular projection from a building, especially the rounded east end of a church that contains the altar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The bishop's seat or throne in ancient churches.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A reliquary, or case in which the relics of saints were kept.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The nearest and furthest points to the centre of gravitational attraction for a body in orbit. More usually called an apsis.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "An aspen tree.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/apse",
        "https://en.wiktionary.org/wiki/apses",
      ],
    },
  ],
  imbue: [
    {
      word: "imbue",
      phonetic: "/ɪmˈbjuː/",
      phonetics: [{ text: "/ɪmˈbjuː/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To wet or stain an object completely with some physical quality.",
              synonyms: [],
              antonyms: [],
              example: "The shirt was imbued with his scent.",
            },
            {
              definition:
                "In general, to act in a way which results in an object becoming completely permeated or impregnated by some quality.",
              synonyms: [],
              antonyms: [],
              example:
                "The entire text is imbued with the sense of melancholy and hopelessness.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/imbue"],
    },
  ],
  belie: [
    {
      word: "belie",
      phonetic: "/bɪˈlʌɪ/",
      phonetics: [
        { text: "/bɪˈlʌɪ/", audio: "" },
        {
          text: "/bəˈlaɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/belie-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2358009",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To lie around; encompass.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of an army) To surround; beleaguer.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/belie"],
    },
    {
      word: "belie",
      phonetic: "/bɪˈlʌɪ/",
      phonetics: [
        { text: "/bɪˈlʌɪ/", audio: "" },
        {
          text: "/bəˈlaɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/belie-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2358009",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To tell lies about.",
              synonyms: ["calumniate", "slander"],
              antonyms: [],
            },
            {
              definition: "To give a false representation of.",
              synonyms: ["misrepresent"],
              antonyms: [],
            },
            {
              definition: "To contradict, to show (something) to be false.",
              synonyms: ["contradict", "give lie to", "give the lie to"],
              antonyms: [],
              example: "Her obvious nervousness belied what she said.",
            },
            {
              definition:
                "To conceal the contradictory or ironic presence of (something).",
              synonyms: [],
              antonyms: [],
              example: "His calm demeanor belied his inner sense of guilt.",
            },
            {
              definition:
                "To show, evince, demonstrate: to show (something) to be present, particularly something deemed contradictory or ironic.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To mimic; to counterfeit.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To fill with lies.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "calumniate",
            "slander",
            "contradict",
            "give lie to",
            "give the lie to",
            "misrepresent",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/belie"],
    },
  ],
  chary: [
    {
      word: "chary",
      phonetic: "/ˈtʃɛəɹi/",
      phonetics: [
        {
          text: "/ˈtʃɛəɹi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/chary-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=83859315",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈtʃɛəɹi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            { definition: "In a chary manner.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Careful, cautious, shy, wary.",
              synonyms: [],
              antonyms: ["unchary"],
            },
            {
              definition:
                "Excessively particular or fussy about details; fastidious.",
              synonyms: [],
              antonyms: ["unchary"],
            },
            {
              definition:
                "Not disposed to give freely; not lavish; frugal, sparing.",
              synonyms: [],
              antonyms: ["unchary"],
            },
            {
              definition: "Cared for, regarded as precious; cherished.",
              synonyms: [],
              antonyms: ["unchary"],
            },
          ],
          synonyms: [],
          antonyms: ["unchary", "unchary", "unchary", "unchary"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/charily",
        "https://en.wiktionary.org/wiki/chary",
      ],
    },
  ],
  spoof: [
    {
      word: "spoof",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spoof-1-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75701482",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/spuːf/", audio: "" },
        {
          text: "/spuːf/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spoof-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=361450",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An act of deception; a hoax; a joking prank.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A light parody.", synonyms: [], antonyms: [] },
            {
              definition:
                "A drinking game in which players hold up to three (or another specified number of) coins hidden in a fist and attempt to guess the total number of coins held.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Nonsense.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["parody", "satire", "send-up", "sendup"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To gently satirize.", synonyms: [], antonyms: [] },
            { definition: "To deceive.", synonyms: [], antonyms: [] },
            { definition: "To falsify.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["satirise", "satirize", "send up"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Fake, hoax.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spoof"],
    },
    {
      word: "spoof",
      phonetic: "/spʊf/",
      phonetics: [
        {
          text: "/spʊf/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spoof-2-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88896813",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/spʊf/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "Semen.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To ejaculate, to come.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spoof"],
    },
  ],
  phyla: [
    {
      word: "phyla",
      phonetic: "/ˈfaɪlə/",
      phonetics: [{ text: "/ˈfaɪlə/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "A tribe.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A rank in the classification of organisms, below kingdom and above class; also called a division, especially in describing plants; a taxon at that rank",
              synonyms: [],
              antonyms: [],
              example: "Mammals belong to the phylum Chordata.",
            },
            {
              definition:
                "A large division of possibly related languages, or a major language family which is not subordinate to another.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["superstock"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/phyla",
        "https://en.wiktionary.org/wiki/phylon",
        "https://en.wiktionary.org/wiki/phylum",
      ],
    },
  ],
  clime: [
    {
      word: "clime",
      phonetic: "/ˈklaɪm/",
      phonetics: [
        {
          text: "/ˈklaɪm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/clime-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=9825577",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A particular region defined by its weather or climate.",
              synonyms: [],
              antonyms: [],
              example:
                "After working hard all of his life, Max retired to warmer climes in Florida.",
            },
            {
              definition: "Climate.",
              synonyms: [],
              antonyms: [],
              example: "A change of clime was exactly what the family needed.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/clime"],
    },
  ],
  babel: [
    {
      word: "babel",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A confused mixture of sounds and voices, especially in different languages.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A place or scene of noise and confusion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A tall, looming structure.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/Babel",
        "https://en.wiktionary.org/wiki/babel",
      ],
    },
  ],
  wacky: [
    {
      word: "wacky",
      phonetic: "/ˈwæk.iː/",
      phonetics: [{ text: "/ˈwæk.iː/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Zany; eccentric", synonyms: [], antonyms: [] },
          ],
          synonyms: ["eccentric", "zany"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/wacky"],
    },
    {
      word: "wacky",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A soft, earthy, dark-coloured rock or clay derived from the alteration of basalt.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["wacky"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wacke",
        "https://en.wiktionary.org/wiki/wacky",
      ],
    },
  ],
  sumps: [
    {
      word: "sumps",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sumps-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1212342",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A hollow or pit into which liquid drains, such as a cesspool, cesspit or sink.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The lowest part of a mineshaft into which water drains.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A completely flooded cave passage, sometimes passable by diving.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The crankcase or oil reservoir of an internal combustion engine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The pit at the lowest point in a circulating or drainage system (FM 55-501).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An intentional depression around a drain or scupper that promotes drainage.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "Of a cave passage, to end in a sump, or to fill completely with water on occasion.",
              synonyms: [],
              antonyms: [],
              example:
                "This low passage sumps quickly after moderate rainfall.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sump",
        "https://en.wiktionary.org/wiki/sumps",
      ],
    },
  ],
  skids: [
    {
      word: "skids",
      phonetic: "/skɪdz/",
      phonetics: [{ text: "/skɪdz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An out-of-control sliding motion as would result from applying the brakes too hard in a car.",
              synonyms: [],
              antonyms: [],
              example:
                "Just before hitting the guardrail the driver was able to regain control and pull out of the skid.",
            },
            {
              definition:
                "A shoe or clog, as of iron, attached to a chain, and placed under the wheel of a wagon to prevent its turning when descending a steep hill; a drag; a skidpan.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) A hook attached to a chain, used for the same purpose.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of timber or other material used as a support, or to receive pressure.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To slide in an uncontrolled manner as in a car with the brakes applied too hard.",
              synonyms: [],
              antonyms: [],
              example:
                "They skidded around the corner and accelerated up the street.",
            },
            {
              definition: "To protect or support with a skid or skids.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause to move on skids.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To check or halt (wagon wheels, etc.) with a skid.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A stepchild.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/skid",
        "https://en.wiktionary.org/wiki/skids",
      ],
    },
  ],
  khans: [
    {
      word: "khans",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A ruler over various Turkish, Tatar and Mongol peoples in the Middle Ages.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An Ottoman sultan.", synonyms: [], antonyms: [] },
            {
              definition:
                "A noble or man of rank in various Muslim countries of Central Asia, including Afghanistan.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A caravanserai; a resting-place for a travelling caravan.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/khan",
        "https://en.wiktionary.org/wiki/khans",
      ],
    },
  ],
  crypt: [
    {
      word: "crypt",
      phonetic: "/kɹɪpt/",
      phonetics: [{ text: "/kɹɪpt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A cave or cavern.", synonyms: [], antonyms: [] },
            {
              definition:
                "An underground vault, especially one beneath a church that is used as a burial place.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small pit or cavity in the surface of an organ or other structure.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/crypt"],
    },
  ],
  inure: [
    {
      word: "inure",
      phonetic: "/ɪˈnjɔː/",
      phonetics: [
        {
          text: "/ɪˈnjɔː/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/inure-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=86957358",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ɪˈnjʊɹ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cause someone to become accustomed to something (usually) unpleasant.",
              synonyms: ["habituate", "harden", "toughen"],
              antonyms: [],
            },
            {
              definition: "To take effect, to be operative.",
              synonyms: [],
              antonyms: [],
              example:
                'Jim buys a beach house that includes the right to travel across the neighbor\'s property to get to the water. That right of way is said, cryptically, "to inure to the benefit of Jim".',
            },
            { definition: "To commit.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["habituate", "harden", "toughen"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/inure"],
    },
  ],
  nonce: [
    {
      word: "nonce",
      phonetic: "/nɒns/",
      phonetics: [
        {
          text: "/nɒns/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/nonce-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241512",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "[nɒn(t)s]", audio: "" },
        { text: "[nɑn(t)s]", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The one or single occasion; the present reason or purpose (now only in for the nonce).",
              synonyms: [],
              antonyms: [],
              example:
                "That will do for the nonce, but we'll need a better answer for the long term.",
            },
            {
              definition: "A nonce word.",
              synonyms: [],
              antonyms: [],
              example:
                "I had thought that the term was a nonce, but it seems as if it's been picked up by other authors.",
            },
            {
              definition:
                "A value constructed so as to be unique to a particular message in a stream, in order to prevent replay attacks.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "One-off; produced or created for a single occasion or use. Denoting something occurring once.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/nonce"],
    },
    {
      word: "nonce",
      phonetic: "/nɒns/",
      phonetics: [
        {
          text: "/nɒns/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/nonce-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241512",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "[nɒn(t)s]", audio: "" },
        { text: "[nɑn(t)s]", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(prisons) A sex offender, especially one who is guilty of sexual offences against children.",
              synonyms: [],
              antonyms: [],
              example:
                "1989 \"assorted nonces, ponces and murderers, 'the worst men in the world' [...] on the nonce wing, where the child-killers, molesters and various perverts have to be protected from the other prisoners.\" (New Statesman, New Society, Volume 2, Statesman & Nation Publishing Company Limited)",
            },
            {
              definition:
                "(prisons) A police informer, one who betrays a criminal enterprise",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A stupid or worthless person.",
              synonyms: [],
              antonyms: [],
              example: "Shut it, ya nonce!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/nonce"],
    },
  ],
  outen: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  faire: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  hooey: [
    {
      word: "hooey",
      phonetic: "/ˈhuː.i/",
      phonetics: [
        {
          text: "/ˈhuː.i/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hooey-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=36785801",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Silly talk or writing; nonsense, silliness, or fake assertion(s).",
              synonyms: [],
              antonyms: [],
              example:
                "I heard his speech. It sounded like a whole lot of hooey to me.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hooey"],
    },
    {
      word: "hooey",
      phonetic: "/ˈhuː.i/",
      phonetics: [
        {
          text: "/ˈhuː.i/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hooey-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=36785801",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A hog-tie performed in a rodeo show.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hooey"],
    },
  ],
  anole: [
    {
      word: "anole",
      phonetic: "/əˈnəʊ.li/",
      phonetics: [
        { text: "/əˈnəʊ.li/", audio: "" },
        {
          text: "/əˈnoʊ.li/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/anole-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=65146533",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of the Anolis genus of arboreal American lizards (such as the American chameleon) from the iguana family which feature a brightly colored dewlap and color-changing ability.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/anole"],
    },
  ],
  kazoo: [
    {
      word: "kazoo",
      phonetic: "/kə.ˈzuː/",
      phonetics: [
        {
          text: "/kə.ˈzuː/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/kazoo-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50240579",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A simple musical instrument (membranophone) consisting of a pipe with a hole in it, producing a buzzing sound when the player hums into it.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To play the kazoo.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/kazoo"],
    },
  ],
  calve: [
    {
      word: "calve",
      phonetic: "/kɑːv/",
      phonetics: [
        {
          text: "/kɑːv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/calve-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=91208461",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/kæv/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To give birth to a calf",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To assist in a cow's giving birth to a calf",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To give birth to (a calf)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(especially of an ice shelf, a glacier, an ice sheet, or even an iceberg) to shed a large piece, e.g. an iceberg or a smaller block of ice (coming off an iceberg)",
              synonyms: [],
              antonyms: [],
              example: "The glacier was starting to calve even as we watched.",
            },
            {
              definition: "(especially of an iceberg) to break off",
              synonyms: [],
              antonyms: [],
              example:
                "The sea was dangerous because of icebergs calving off the nearby glacier.",
            },
            {
              definition:
                "(especially of an ice shelf, a glacier, an ice sheet, or even an iceberg) to shed (a large piece, e.g. an iceberg); to set loose (a mass of ice), e.g. a block of ice (coming off an iceberg)",
              synonyms: [],
              antonyms: [],
              example:
                "The glacier was starting to calve an iceberg even as we watched.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/calve"],
    },
  ],
  limbo: [
    {
      word: "limbo",
      phonetic: "/ˈlɪmbəʊ/",
      phonetics: [
        {
          text: "/ˈlɪmbəʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/limbo-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89012724",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈlɪmboʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(Roman Catholic theology, since circa 400 A.D.) The place where innocent souls exist temporarily until they can enter heaven, notably those of the saints who died before the advent of Christ (limbus patruum) and those of unbaptized but innocent children (limbus infantum).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension, since the 16th century) Any in-between place, state or condition of neglect or oblivion which results in an unresolved status, delay or deadlock.",
              synonyms: [],
              antonyms: [],
              example:
                "My application has been stuck in bureaucratic limbo for two weeks.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/limbo"],
    },
    {
      word: "limbo",
      phonetic: "/ˈlɪmbəʊ/",
      phonetics: [
        {
          text: "/ˈlɪmbəʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/limbo-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89012724",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈlɪmboʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A dance played by taking turns crossing under a horizontal bar or stick. The stick is lowered with each round, and the game is won by the player who passes under the bar in the lowest position.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To dance in this way.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/limbo"],
    },
  ],
  argot: [
    {
      word: "argot",
      phonetic: "/ˈɑːɡəʊ/",
      phonetics: [
        { text: "/ˈɑːɡəʊ/", audio: "" },
        { text: "/ˈɑɹɡoʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A secret language or conventional slang peculiar to thieves, tramps and vagabonds.",
              synonyms: ["cant", "jargon", "slang"],
              antonyms: [],
            },
            {
              definition:
                "The specialized informal vocabulary and terminology used between people with special skill in a field, such as between doctors, mathematicians or hackers.",
              synonyms: ["jargon"],
              antonyms: [],
              example:
                "The conversation was in the argot of the trade, full of acronyms and abbreviations that made no sense to the uninitiate.",
            },
          ],
          synonyms: ["cant", "jargon", "slang", "jargon"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/argot"],
    },
  ],
  ducky: [
    {
      word: "ducky",
      phonetic: "/ˈdʌki/",
      phonetics: [
        {
          text: "/ˈdʌki/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ducky-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79293743",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A duck (aquatic bird), especially a toy rubber duck",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An affectionate pet name.",
              synonyms: [],
              antonyms: [],
              example:
                '"Morning, ducky!" said Roderick, as he gave his partner a quick peck on the cheek and sat down to breakfast.',
            },
          ],
          synonyms: ["darling", "hon"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Resembling or characteristic of a duck.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Great; going well; proceeding in an eminently agreeable fashion.",
              synonyms: [],
              antonyms: [],
              example:
                "Farnesworth smiled contentedly as he read the stock ticker; all was ducky on Wall Street.",
            },
          ],
          synonyms: ["fine", "peachy", "swell"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ducky"],
    },
  ],
  faker: [
    {
      word: "faker",
      phonetic: "/ˈfeɪkɚ/",
      phonetics: [{ text: "/ˈfeɪkɚ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One who fakes something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An impostor or impersonator.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A thief.", synonyms: [], antonyms: [] },
            {
              definition: "A peddler of petty things.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A workman who dresses things up.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/faker"],
    },
  ],
  vibes: [
    {
      word: "vibes",
      phonetic: "/vaɪbz/",
      phonetics: [{ text: "/vaɪbz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A vibraphone.", synonyms: [], antonyms: [] },
            { definition: "Vibration.", synonyms: [], antonyms: [] },
            { definition: "A vibrator (sex toy).", synonyms: [], antonyms: [] },
            {
              definition:
                "(originally New Age jargon) An atmosphere or aura felt to belong to a person, place or thing.",
              synonyms: [],
              antonyms: [],
              example:
                "The couple canceled their night out when they got a bad vibe from the new babysitter.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To stimulate with a vibrator.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To relax and enjoy oneself.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To agree.", synonyms: [], antonyms: [] },
            {
              definition: "To get along; to hit it off.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/vibe",
        "https://en.wiktionary.org/wiki/vibes",
      ],
    },
  ],
  gassy: [
    {
      word: "gassy",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gassy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75797010",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈɡæsi/", audio: "" },
        { text: "/ˈɡæsi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having the nature of, or containing, gas.",
              synonyms: ["gaseous", "gasiferous", "gaslike"],
              antonyms: [],
            },
            {
              definition: "Of food or drink: tending to cause flatulence.",
              synonyms: ["farty"],
              antonyms: [],
              example: "Beans are a gassy food.",
            },
            {
              definition:
                "Tending to be long-winded or wordy, especially in a boastful and vain manner.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["farty", "gaseous", "gasiferous", "gaslike"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gassy"],
    },
  ],
  unlit: [
    {
      word: "unlit",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [{ definition: "Not lit", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/unlit"],
    },
  ],
  nervy: [
    {
      word: "nervy",
      phonetic: "/ˈnɜː.vi/",
      phonetics: [
        { text: "/ˈnɜː.vi/", audio: "" },
        { text: "/ˈnɝ.vi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having nerve; bold; brazen.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Feeling nervous, anxious or agitated.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Strong; sinewy.", synonyms: [], antonyms: [] },
            {
              definition: "Jittery; having unwanted signal characteristics.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/nervy"],
    },
  ],
  femme: [
    {
      word: "femme",
      phonetic: "/fɛm/",
      phonetics: [
        {
          text: "/fɛm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/femme-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90913502",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A woman, a wife; a young woman or girl.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A lesbian or other queer woman whose appearance, identity etc. is seen as feminine as opposed to butch.",
              synonyms: ["fem"],
              antonyms: [],
            },
            {
              definition:
                "A person whose gender is feminine-leaning, such as a feminine non-binary person.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["fem"],
          antonyms: ["butch"],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(entertainment) Pertaining to a femme; feminine, female.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Effeminate (of a man).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Characteristic of a feminine lesbian or queer woman.",
              synonyms: [],
              antonyms: [],
              example: "Her style was more femme than butch.",
            },
          ],
          synonyms: [],
          antonyms: ["butch"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/femme"],
    },
  ],
  biter: [
    {
      word: "biter",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/biter-1-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79268339",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈbaɪtə/", audio: "" },
        { text: "/ˈbaɪtɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The act of biting.", synonyms: [], antonyms: [] },
            {
              definition: "The wound left behind after having been bitten.",
              synonyms: [],
              antonyms: [],
              example: "That snake bite really hurts!",
            },
            {
              definition:
                "The swelling of one's skin caused by an insect's mouthparts or sting.",
              synonyms: [],
              antonyms: [],
              example:
                "After just one night in the jungle I was covered with mosquito bites.",
            },
            {
              definition:
                "A piece of food of a size that would be produced by biting; a mouthful.",
              synonyms: [],
              antonyms: [],
              example: "There were only a few bites left on the plate.",
            },
            {
              definition: "Something unpleasant.",
              synonyms: [],
              antonyms: [],
              example: "That's really a bite!",
            },
            {
              definition: "An act of plagiarism.",
              synonyms: [],
              antonyms: [],
              example: "That song is a bite of my song!",
            },
            {
              definition: "A small meal or snack.",
              synonyms: [],
              antonyms: [],
              example:
                "I'll have a quick bite to quiet my stomach until dinner.",
            },
            { definition: "Aggression", synonyms: [], antonyms: [] },
            {
              definition:
                "The hold which the short end of a lever has upon the thing to be lifted, or the hold which one part of a machine has upon another.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A cheat; a trick; a fraud.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sharper; one who cheats.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A blank on the edge or corner of a page, owing to a portion of the frisket, or something else, intervening between the type and paper.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A cut, a proportion of profits; an amount of money.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["mouthful", "snack", "sting"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A fish that tends to take bait.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A stone that barely touches the outside of the house.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One who copies someone else's work, style or techniques, especially in hip-hop.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A cheat or fraudster.", synonyms: [], antonyms: [] },
            { definition: "A zombie.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bite",
        "https://en.wiktionary.org/wiki/biter",
      ],
    },
    {
      word: "biter",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(in combination) Something (a data unit, machine etc.) with a width of a specified amount of bits.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/biter"],
    },
  ],
  fiche: [
    {
      word: "fiche",
      phonetic: "/fiːʃ/",
      phonetics: [{ text: "/fiːʃ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A microfiche", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fiche"],
    },
  ],
  boors: [
    {
      word: "boors",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A peasant.", synonyms: [], antonyms: [] },
            {
              definition:
                "A Boer, white South African of Dutch or Huguenot descent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A yokel, country bumpkin.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An uncultured person.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/boor",
        "https://en.wiktionary.org/wiki/boors",
      ],
    },
  ],
  gaffe: [
    {
      word: "gaffe",
      phonetic: "/ɡæf/",
      phonetics: [
        {
          text: "/ɡæf/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gaffe-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=7467879",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A foolish and embarrassing error, especially one made in public.",
              synonyms: [
                "blooper",
                "blunder",
                "error",
                "mistake",
                "slip",
                "slip-up",
              ],
              antonyms: [],
            },
          ],
          synonyms: [
            "blooper",
            "blunder",
            "error",
            "mistake",
            "slip",
            "slip-up",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gaffe"],
    },
  ],
  saxes: [
    {
      word: "saxes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A slate-cutter's hammer; slate-ax.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A knife or sword; a dagger about 50 cm (20 inches) in length.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A single-reed instrument musical instrument of the woodwind family, usually made of brass and with a distinctive loop bringing the bell upwards.",
              synonyms: ["gobble-pipe", "sax"],
              antonyms: [],
            },
          ],
          synonyms: ["gobble-pipe", "sax"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sax",
        "https://en.wiktionary.org/wiki/saxes",
        "https://en.wiktionary.org/wiki/saxophone",
      ],
    },
  ],
  recap: [
    {
      word: "recap",
      phonetic: "/ɹiːˈkæp/",
      phonetics: [
        {
          text: "/ɹiːˈkæp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/recap-1-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88743049",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A tire that has had new tread glued on.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To seal (something) again with a cap.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To replace the worn tread on a tire by gluing a new outer portion. (US English only - Retread in UK English)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/recap"],
    },
    {
      word: "recap",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/recap-2-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88743049",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A recapitulation.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["summarisation", "summarization", "summary", "summing-up"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To recapitulate.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["sum up", "summarise", "summarize"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/recap"],
    },
    {
      word: "recap",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A leveraged recapitalization accomplished by increasing the debt to equity ratio.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/recap"],
    },
    {
      word: "recap",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To replace capacitors in electronic equipment.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/recap"],
    },
  ],
  synch: [
    {
      word: "synch",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Harmony.", synonyms: [], antonyms: [] },
            {
              definition:
                "A music synchronization license, allowing the music to be synchronized with visual media such as films.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To synchronize, especially in the senses of data synchronization, time synchronization, or synchronizing music with video.",
              synonyms: [],
              antonyms: [],
              example:
                "Our cloud storage automatically syncs your files to all your devices.",
            },
            {
              definition: "To flush all pending I/O operations to disk.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The state or property of being synchronized.",
              synonyms: [],
              antonyms: [],
              example:
                "The generator went off line when it lost synchronization with the power line.",
            },
            {
              definition:
                "The arrangement of military actions in time, space, and purpose to produce maximum relative combat power at a decisive place and time.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In an intelligence context, application of intelligence sources and methods in concert with the operation plan.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["synchroneity", "synchronicity"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sync",
        "https://en.wiktionary.org/wiki/synch",
        "https://en.wiktionary.org/wiki/synchronization",
      ],
    },
  ],
  facie: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  dicey: [
    {
      word: "dicey",
      phonetic: "/ˈdaɪsi/",
      phonetics: [
        {
          text: "/ˈdaɪsi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dicey-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79268536",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Fraught with danger.", synonyms: [], antonyms: [] },
            {
              definition: "Of uncertain, risky outcome.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of doubtful or uncertain efficacy, provenance, etc.; dodgy.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Nauseating, rank.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["chancy", "iffy"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dicey"],
    },
  ],
  ouija: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  hewer: [
    {
      word: "hewer",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "One who hews.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hewer"],
    },
  ],
  legit: [
    {
      word: "legit",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/legit-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79293623",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ləˈdʒɪt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A legitimate; a legitimate actor.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A legitimate child.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Legitimate; legal; allowed by the rules; valid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension, of a thing or person) Genuine, actual, literal or honest.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Genuinely good and possessing all the required or expected qualities; the real deal.",
              synonyms: [],
              antonyms: [],
              example: "I checked out her portfolio. She's legit.",
            },
            {
              definition: "Cool by virtue of being genuine.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Legitimately; within the law.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Honestly; truly; seriously.",
              synonyms: [],
              antonyms: [],
              example: "He legit thinks he can pass the test on zero sleep.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/legit"],
    },
  ],
  gurus: [
    {
      word: "gurus",
      phonetic: "/ˈɡuː.ɹuːz/",
      phonetics: [
        { text: "/ˈɡuː.ɹuːz/", audio: "" },
        { text: "/ɡuːˈɹuːz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A Hindu or Sikh spiritual teacher.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(sometimes humorous) An influential advisor or mentor.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/guru",
        "https://en.wiktionary.org/wiki/gurus",
      ],
    },
  ],
  edify: [
    {
      word: "edify",
      phonetic: "/ˈɛdɪfaɪ/",
      phonetics: [
        {
          text: "/ˈɛdɪfaɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/edify-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=94707885",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To build, construct.", synonyms: [], antonyms: [] },
            {
              definition: "To instruct or improve morally or intellectually.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/edify"],
    },
  ],
  tweak: [
    {
      word: "tweak",
      phonetic: "/twiːk/",
      phonetics: [
        {
          text: "/twiːk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tweak-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241865",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A sharp pinch or jerk; a twist or twitch.",
              synonyms: [],
              antonyms: [],
              example: "a tweak of the nose",
            },
            {
              definition: "A slight adjustment or modification.",
              synonyms: [],
              antonyms: [],
              example:
                "He is running so many tweaks it is hard to remember how it looked originally.",
            },
            {
              definition: "Trouble; distress; tweag.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A prostitute.", synonyms: [], antonyms: [] },
            {
              definition:
                "An additional input to a block cipher, used in conjunction with the key to select the permutation computed by the cipher.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To pinch and pull with a sudden jerk and twist; to twitch.",
              synonyms: [],
              antonyms: [],
              example: "to tweak the nose.",
            },
            {
              definition: "To adjust slightly; to fine-tune.",
              synonyms: [],
              antonyms: [],
              example:
                "If we tweak the colors towards blue, it will look more natural.",
            },
            { definition: "To twit or tease.", synonyms: [], antonyms: [] },
            {
              definition: "To abuse methamphetamines, especially crystal meth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To exhibit symptoms of methamphetamine abuse, such as extreme nervousness, compulsiveness, erratic motion, excitability; possibly a blend of twitch and freak.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To exhibit extreme nervousness, evasiveness when confronted by law enforcement or other authority (e.g., customs agents, border patrol, teacher, etc.), mimicking methamphetamine abuse symptoms.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tweak"],
    },
  ],
  caron: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  typos: [
    {
      word: "typos",
      phonetic: "/ˈtaɪ.pəʊz/",
      phonetics: [
        { text: "/ˈtaɪ.pəʊz/", audio: "" },
        { text: "/ˈtaɪ.poʊz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A typographical error.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A compositor; a typographer.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/typo",
        "https://en.wiktionary.org/wiki/typos",
      ],
    },
  ],
  rerun: [
    {
      word: "rerun",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rerun-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88743027",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈɹiːɹʌn/", audio: "" },
        { text: "/ɹiˈɹʌn/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A television program shown after its initial presentation — particularly many weeks after its initial presentation; a repeat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Another printing run (impression; batch of copies of a given edition) of a book, cartoon, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A political candidate who holds the same political agenda or doctrine as a past or incumbent holder of a given political office.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A second or subsequent run of a program.",
              synonyms: [],
              antonyms: [],
              example: "Each rerun of the algorithm takes several minutes.",
            },
          ],
          synonyms: ["repeat"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To run (a previously broadcast television program) again.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To run (a race) again.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To run (a computer program) again.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["reair"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rerun"],
    },
  ],
  polly: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  surds: [
    {
      word: "surds",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An irrational number, especially one expressed using the √ symbol.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A voiceless consonant.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/surd",
        "https://en.wiktionary.org/wiki/surds",
      ],
    },
  ],
  hamza: [
    {
      word: "hamza",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                'A sign (ء‎ - a stand-alone hamza) used in the written Arabic language representing a glottal stop. Hamza may appear as a stand-alone letter or most commonly diacritically over or under other letters, e.g. أ‎ (over an alif - ا‎), إ‎ (under an alif), ؤ‎ (over a wāw - و‎) or ئ‎ (over a dotless yāʾ - ى‎). The exact seat of hamza is governed by an orthographic rule - "seat of hamza rule".',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hamza"],
    },
  ],
  nulls: [
    {
      word: "nulls",
      phonetic: "/nʌlz/",
      phonetics: [{ text: "/nʌlz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A non-existent or empty value or set of values.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Zero quantity of expressions; nothing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Something that has no force or meaning.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The ASCII or Unicode character (␀), represented by a zero value, that indicates no character and is sometimes used as a string terminator.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The attribute of an entity that has no valid value.",
              synonyms: [],
              antonyms: [],
              example:
                "Since no date of birth was entered for the patient, his age is null.",
            },
            {
              definition: "One of the beads in nulled work.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Null hypothesis.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To nullify; to annul.", synonyms: [], antonyms: [] },
            {
              definition: "To form nulls, or into nulls, as in a lathe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To crack; to remove restrictions or limitations in (software).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/null",
        "https://en.wiktionary.org/wiki/nulls",
      ],
    },
  ],
  hater: [
    {
      word: "hater",
      phonetic: "/ˈheɪtə(ɹ)/",
      phonetics: [
        {
          text: "/ˈheɪtə(ɹ)/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hater-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241289",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "One who hates.", synonyms: [], antonyms: [] },
            {
              definition:
                "One who expresses unfounded or inappropriate hatred or dislike, particularly if motivated by envy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["despiser"],
          antonyms: ["fan", "lover"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hater"],
    },
  ],
  lefty: [
    {
      word: "lefty",
      phonetic: "/ˈlɛfti/",
      phonetics: [
        {
          text: "/ˈlɛfti/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lefty-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79293620",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One who is left-handed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One who has left-wing political views.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One's left testicle.",
              synonyms: [],
              antonyms: [],
              example: "I'll bet my lefty he won't show up for work today.",
            },
          ],
          synonyms: ["left-hander", "southpaw"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Left-handed.", synonyms: [], antonyms: [] },
            {
              definition: "Intended for left-handed use.",
              synonyms: [],
              antonyms: [],
              example:
                "The lefty scissors are the ones with the red plastic handles.",
            },
            { definition: "Left-wing.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lefty"],
    },
  ],
  mogul: [
    {
      word: "mogul",
      phonetic: "/ˈmoʊɡəl/",
      phonetics: [{ text: "/ˈmoʊɡəl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A rich or powerful person; a magnate.",
              synonyms: ["captain of industry", "magnate", "tycoon"],
              antonyms: [],
              example: "a Silicon Valley tech mogul",
            },
          ],
          synonyms: ["captain of industry", "magnate", "tycoon"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mogul"],
    },
    {
      word: "mogul",
      phonetic: "/ˈmoʊɡəl/",
      phonetics: [{ text: "/ˈmoʊɡəl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A hump or bump on a skiing piste.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A larger-sized (39 mm diameter) screw base used for large, high-power light bulbs, known as mogul (screw) base light bulbs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A machine that forms shaped candies from syrups or gels.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mogul"],
    },
  ],
  mafia: [
    {
      word: "mafia",
      phonetic: "/ˈmæfi.ə/",
      phonetics: [
        { text: "/ˈmæfi.ə/", audio: "" },
        { text: "/ˈmɑfi.ə/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A hierarchically structured secret organisation engaged in illegal activities like distribution of narcotics, gambling and extortion.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A crime syndicate.", synonyms: [], antonyms: [] },
            {
              definition:
                "A trusted group of associates, as of a political leader.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                '(in compound terms such as "moral mafia") An entity which attempts to control a specified arena.',
              synonyms: [],
              antonyms: [],
              example: "the digital mafia",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mafia"],
    },
  ],
  debug: [
    {
      word: "debug",
      phonetic: "/diːˈbʌɡ/",
      phonetics: [
        { text: "/diːˈbʌɡ/", audio: "" },
        { text: "/diˈbʌɡ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The action, or a session, of reviewing source code to find and eliminate errors.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To search for and eliminate malfunctioning elements or errors in something, especially a computer program or machinery.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To remove a hidden electronic surveillance device from (somewhere).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To remove insects from (somewhere), especially lice.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["delouse", "diagnose", "troubleshoot"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/debug"],
    },
  ],
  pates: [
    {
      word: "pates",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(somewhat obsolete) The head, particularly the top or crown.",
              synonyms: [],
              antonyms: [],
              example: "He had a shiny, bald pate.",
            },
            {
              definition: "Wit, cleverness, cognitive abilities.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The interior body, or non-rind portion of cheese, described by its texture, density, and color.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A finely-ground paste of meat, fish or vegetables, sometimes with the addition of alcohol.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/p%C3%A2t%C3%A9",
        "https://en.wiktionary.org/wiki/pate",
        "https://en.wiktionary.org/wiki/pates",
      ],
    },
  ],
  blabs: [
    {
      word: "blabs",
      phonetic: "/blæbz/",
      phonetics: [{ text: "/blæbz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To tell tales; to gossip without reserve or discretion.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/blab",
        "https://en.wiktionary.org/wiki/blabs",
      ],
    },
  ],
  splay: [
    {
      word: "splay",
      phonetic: "/spleɪ/",
      phonetics: [
        {
          text: "/spleɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/splay-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88676277",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A slope or bevel, especially of the sides of a door or window, by which the opening is made larger at one face of the wall than at the other, or larger at each of the faces than it is between them.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To spread; spread out.",
              synonyms: ["broaden", "display", "spread", "spread out", "widen"],
              antonyms: [],
            },
            {
              definition: "To dislocate, as a shoulder bone.",
              synonyms: ["dislocate"],
              antonyms: [],
            },
            {
              definition:
                "To turn on one side; to render oblique; to slope or slant, as the side of a door, window etc.",
              synonyms: ["slant", "slope"],
              antonyms: [],
            },
            {
              definition:
                "To rearrange (a splay tree) so that a desired element is placed at the root.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To spay; to castrate.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "broaden",
            "display",
            "spread",
            "spread out",
            "widen",
            "dislocate",
            "slant",
            "slope",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Spread out; turned outward.",
              synonyms: [],
              antonyms: [],
              example: "to sit splay-legged",
            },
            {
              definition: "Flat and ungainly.",
              synonyms: [],
              antonyms: [],
              example: "splay shoulders",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/splay"],
    },
  ],
  talus: [
    {
      word: "talus",
      phonetic: "/ˈteɪləs/",
      phonetics: [{ text: "/ˈteɪləs/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The bone of the ankle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/talus"],
    },
    {
      word: "talus",
      phonetic: "/ˈteɪləs/",
      phonetics: [{ text: "/ˈteɪləs/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sloping heap of fragments of rock lying at the foot of a precipice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The slope of an embankment wall, which is thicker at the bottom than at the top.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/talus"],
    },
  ],
  moola: [
    {
      word: "moola",
      phonetic: "/ˈmuː.lə/",
      phonetics: [{ text: "/ˈmuː.lə/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Money, cash.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/moola"],
    },
  ],
  nixed: [
    {
      word: "nixed",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make something become nothing; to reject or cancel.",
              synonyms: ["cancel", "reject"],
              antonyms: [],
              example: "Nix the last order – the customer walked out.",
            },
            {
              definition: "To destroy or eradicate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["cancel", "reject"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/nix",
        "https://en.wiktionary.org/wiki/nixed",
      ],
    },
  ],
  kilos: [
    {
      word: "kilos",
      phonetic: "/ˈkiːləʊz/",
      phonetics: [
        { text: "/ˈkiːləʊz/", audio: "" },
        { text: "/ˈkiloʊz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "In the International System of Units, the base unit of mass; conceived of as the mass of one litre of water, but now defined by taking the fixed numerical value of the Planck constant h to be 6.626 070 15 × 10-34 when expressed in units of kg⋅m2⋅s−1. Symbol: kg",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The unit of weight such that a one-kilogram mass is also a one-kilogram weight.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["kg", "kilo"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/kilo",
        "https://en.wiktionary.org/wiki/kilogram",
        "https://en.wiktionary.org/wiki/kilos",
      ],
    },
  ],
  snide: [
    {
      word: "snide",
      phonetic: "/snaɪd/",
      phonetics: [
        {
          text: "/snaɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/snide-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=645951",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An underhanded, tricky person given to sharp practise; a sharper; a cheat.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Counterfeit money", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Disparaging or derisive in an insinuative way.",
              synonyms: [],
              antonyms: [],
              example: "Don't make snide remarks to me.",
            },
            {
              definition: "Tricky; deceptive; false; spurious; contemptible.",
              synonyms: [],
              antonyms: [],
              example: "He was a snide lawyer.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/snide"],
    },
  ],
  horsy: [
    {
      word: "horsy",
      phonetic: "/ˈhɔːsi/",
      phonetics: [
        { text: "/ˈhɔːsi/", audio: "" },
        { text: "/ˈhɔɹsi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A child's term or name for a horse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A game where a child rides on the back of another, who is on all fours.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["gee-gee"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of or relating to horses.",
              synonyms: ["horsely"],
              antonyms: [],
            },
            {
              definition:
                "Of a person or people, involved in breeding or riding horses.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of a graphic design or typographical treatment which is clumsy, clunky, or unrefined.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["horsely"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/horsy"],
    },
  ],
  jaggy: [
    {
      word: "jaggy",
      phonetic: "/ˈdʒæɡi/",
      phonetics: [{ text: "/ˈdʒæɡi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Jagged, toothed or serrated",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dentate", "serrate"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/jaggy"],
    },
  ],
  trove: [
    {
      word: "trove",
      phonetic: "/tɹəʊv/",
      phonetics: [
        {
          text: "/tɹəʊv/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/trove-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80152076",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/tɹoʊv/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A treasure trove; a collection of treasure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A collection of things.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/trove"],
    },
  ],
  nixes: [
    {
      word: "nixes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make something become nothing; to reject or cancel.",
              synonyms: ["cancel", "reject"],
              antonyms: [],
              example: "Nix the last order – the customer walked out.",
            },
            {
              definition: "To destroy or eradicate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["cancel", "reject"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "Nothing", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/nix",
        "https://en.wiktionary.org/wiki/nixes",
      ],
    },
  ],
  creel: [
    {
      word: "creel",
      phonetic: "/kɹiːl/",
      phonetics: [{ text: "/kɹiːl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An osier basket, such as anglers use to hold fish.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bar or set of bars with skewers for holding paying-off bobbins, as in the roving machine, throstle, and mule.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To place (fish) in a creel.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/creel"],
    },
  ],
  pater: [
    {
      word: "pater",
      phonetic: "/ˈpeɪtə/",
      phonetics: [
        { text: "/ˈpeɪtə/", audio: "" },
        { text: "/ˈpeɪtɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "Father", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pater"],
    },
  ],
  iotas: [
    {
      word: "iotas",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The ninth letter of the Greek alphabet.",
              synonyms: [],
              antonyms: [],
              example: "As a Greek numeral, iota represents ten.",
            },
            {
              definition: "A jot; a very small, insignificant quantity.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/iota",
        "https://en.wiktionary.org/wiki/iotas",
      ],
    },
  ],
  cadge: [
    {
      word: "cadge",
      phonetic: "/kædʒ/",
      phonetics: [{ text: "/kædʒ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A circular frame on which cadgers carry hawks for sale.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To beg.",
              synonyms: [],
              antonyms: [],
              example: '"Are ye gannin te cadge a lift of yoer fatha?"',
            },
            {
              definition:
                "To obtain something by wit or guile; to convince people to do something they might not normally do.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To carry hawks and other birds of prey.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To carry, as a burden.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To hawk or peddle, as fish, poultry, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To intrude or live on another meanly; to beg.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bum", "scrounge"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cadge"],
    },
  ],
  skyed: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  curie: [
    {
      word: "curie",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "3.7×1010 decays per second, as a unit of radioactivity. Symbol Ci.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/curie"],
    },
  ],
  nutsy: [
    {
      word: "nutsy",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [{ definition: "Crazy", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/nutsy"],
    },
  ],
  remix: [
    {
      word: "remix",
      phonetic: "/ɹiˈmɪks/",
      phonetics: [{ text: "/ɹiˈmɪks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A rearrangement of an older piece of music, possibly including various cosmetic changes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of music formed by combining existing pieces of music together, possibly including various other cosmetic changes",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To mix again.", synonyms: [], antonyms: [] },
            { definition: "To create a remix.", synonyms: [], antonyms: [] },
            {
              definition:
                "To rearrange or radically alter (a particular piece of music).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To modify (a work in any medium).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/remix"],
    },
  ],
  angst: [
    {
      word: "angst",
      phonetic: "/æŋ(k)st/",
      phonetics: [
        {
          text: "/æŋ(k)st/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/angst-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75729475",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "[eɪŋ(k)st]", audio: "" },
        { text: "[eɪŋ(k)st]", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Emotional turmoil; painful sadness.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A feeling of acute but vague anxiety or apprehension often accompanied by depression, especially philosophical anxiety.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To suffer angst; to fret.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/angst"],
    },
  ],
  burls: [
    {
      word: "burls",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A tree growth in which the grain has grown in a deformed manner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Wood of a mottled veneer, usually cut from such a growth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A knot or lump in thread or cloth.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To remove the knots in cloth.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/burl",
        "https://en.wiktionary.org/wiki/burls",
      ],
    },
  ],
  jimmy: [
    {
      word: "jimmy",
      phonetic: "/ˈdʒɪmi/",
      phonetics: [
        {
          text: "/ˈdʒɪmi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/jimmy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2454117",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(especially New England and Philadelphia) Chocolate sprinkles used as a topping for ice cream, cookies, or cupcakes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A marijuana cigarette.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A device used to circumvent a locking mechanism; a slim jim.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(Royal Navy) First Lieutenant (Executive Officer)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A jemmy; a crowbar used by burglars to open windows and doors.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A penis.", synonyms: [], antonyms: [] },
            { definition: "A condom.", synonyms: [], antonyms: [] },
            { definition: "(SE) Men's underwear.", synonyms: [], antonyms: [] },
            { definition: "A male crab; a cock.", synonyms: [], antonyms: [] },
            { definition: "A coal-car.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["hundreds and thousands", "sprinkles"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To pry (something, especially a lock) open with or as if with a crowbar.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/jimmy"],
    },
  ],
  veiny: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  tryst: [
    {
      word: "tryst",
      phonetic: "/tɹaɪst/",
      phonetics: [
        {
          text: "/tɹaɪst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tryst-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75858963",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/tɹaɪst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tryst-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=363321",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A prearranged meeting or assignation, now especially between lovers to meet at a specific place and time.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A mutual agreement, a covenant.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make a tryst; to agree to meet at a place.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To arrange or appoint (a meeting time etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To keep a tryst, to meet at an agreed place and time.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tryst"],
    },
  ],
  codon: [
    {
      word: "codon",
      phonetic: "/ˈkəʊdɒn/",
      phonetics: [{ text: "/ˈkəʊdɒn/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A handbell used for summoning monks.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: 'The "bell" or flaring mouth of a trumpet.',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/codon"],
    },
    {
      word: "codon",
      phonetic: "/ˈkəʊdɒn/",
      phonetics: [{ text: "/ˈkəʊdɒn/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sequence of three adjacent nucleotides, which encode for a specific amino acid during protein synthesis or translation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/codon"],
    },
  ],
  befog: [
    {
      word: "befog",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To envelop in fog or smoke.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To confuse, mystify (a person); to make less acute or perceptive, to cloud (a person’s faculties).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To obscure, make less clear (a subject, issue, etc.).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/befog"],
    },
  ],
  gamed: [
    {
      word: "gamed",
      phonetic: "/ɡeɪmd/",
      phonetics: [{ text: "/ɡeɪmd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To gamble.", synonyms: [], antonyms: [] },
            {
              definition: "To play card games, board games, or video games.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To exploit loopholes in a system or bureaucracy in a way which defeats or nullifies the spirit of the rules in effect, usually to obtain a result which otherwise would be unobtainable.",
              synonyms: [],
              antonyms: [],
              example: "We'll bury them in paperwork, and game the system.",
            },
            {
              definition:
                "(of males) To perform premeditated seduction strategy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/game",
        "https://en.wiktionary.org/wiki/gamed",
      ],
    },
  ],
  flume: [
    {
      word: "flume",
      phonetic: "/fluːm/",
      phonetics: [
        { text: "/fluːm/", audio: "" },
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/flume-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=278947",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A ravine or gorge, usually one with water running through.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An open channel or trough used to direct or divert liquids.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To transport (logs of wood) by floating them along a water-filled channel or trough.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/flume"],
    },
  ],
  axman: [
    {
      word: "axman",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A man who wields an axe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A musician who plays a guitar or saxophone.",
              synonyms: ["saxophonist"],
              antonyms: [],
            },
          ],
          synonyms: ["saxophonist"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/axeman",
        "https://en.wiktionary.org/wiki/axman",
      ],
    },
  ],
  doozy: [
    {
      word: "doozy",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/doozy-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=755161",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Something that is extraordinary: often troublesome, difficult or problematic, but sometimes extraordinary in a positive sense.",
              synonyms: ["lulu"],
              antonyms: [],
              example:
                "Most of the test was easy, but the last question was a doozy.",
            },
          ],
          synonyms: ["lulu"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of high quality; remarkable; excellent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Sporty, ostentatious, flashy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/doozy"],
    },
  ],
  lubes: [
    {
      word: "lubes",
      phonetic: "/luːbz/",
      phonetics: [{ text: "/luːbz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To lubricate", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lube",
        "https://en.wiktionary.org/wiki/lubes",
      ],
    },
  ],
  rheas: [
    {
      word: "rheas",
      phonetic: "/ˈɹiːəz/",
      phonetics: [
        {
          text: "/ˈɹiːəz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rheas-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89844818",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A large flightless bird of the genus Rhea, native to South America.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rhea",
        "https://en.wiktionary.org/wiki/rheas",
      ],
    },
  ],
  bozos: [
    {
      word: "bozos",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A stupid, foolish, or ridiculous person, especially a man.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bozo",
        "https://en.wiktionary.org/wiki/bozos",
      ],
    },
  ],
  butyl: [
    {
      word: "butyl",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of four isomeric univalent hydrocarbon radicals, C4H9, formally derived from butane by the loss of a hydrogen atom.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A synthetic rubber made by the polymerization of isobutylene.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/butyl"],
    },
  ],
  kelly: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  mynah: [
    {
      word: "mynah",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One of the South and East Asian birds of the starling family Sturnidae.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/myna",
        "https://en.wiktionary.org/wiki/mynah",
      ],
    },
  ],
  jocks: [
    {
      word: "jocks",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/jocks-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79294438",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A common man.", synonyms: [], antonyms: [] },
            { definition: "A Scotsman.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The penis.", synonyms: [], antonyms: [] },
            {
              definition:
                "An athletic supporter worn by men to support the genitals especially during sports, a jockstrap.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A young male athlete (through college age).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An enthusiastic athlete or sports fan, especially one with few other interests. A slow-witted person of large size and great physical strength. A pretty boy that shows off in sport.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A disc jockey.", synonyms: [], antonyms: [] },
            {
              definition: "(in combination) A specialist computer programmer.",
              synonyms: [],
              antonyms: [],
              example: "a compiler jock; a systems jock",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/jock",
        "https://en.wiktionary.org/wiki/jocks",
      ],
    },
  ],
  donut: [
    {
      word: "donut",
      phonetic: "/ˈdoʊnət/",
      phonetics: [
        { text: "/ˈdoʊnət/", audio: "" },
        {
          text: "/ˈdoʊnət/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/donut-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50348385",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A deep-fried piece of dough or batter, commonly made in a toroidal or ellipsoidal shape, and mixed with various sweeteners and flavors, sometimes filled with jelly, custard or cream.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Anything in the shape of a torus.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(automobile) A peel-out or skid-mark in the shape of donut; a 360-degree skid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A spare tire, smaller and less durable than a full-sized tire, only intended for temporary use.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A toroidal cushion typically used by hemorrhoid patients.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An idiot.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/donut"],
    },
  ],
  avian: [
    {
      word: "avian",
      phonetic: "/ˈeɪvi.ən/",
      phonetics: [{ text: "/ˈeɪvi.ən/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A bird.", synonyms: [], antonyms: [] },
            {
              definition: "A bird-like or flying creature.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(furry fandom) Someone who roleplays or describes themselves as being a bird or bird-like animal character with human characteristics.",
              synonyms: ["feathery"],
              antonyms: [],
            },
          ],
          synonyms: ["feathery"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Characteristic of or pertaining to birds, or to bird-like or flying creatures.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["birdly", "feathered", "plumed", "winged"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/avian"],
    },
  ],
  wurst: [
    {
      word: "wurst",
      phonetic: "/vɜːst/",
      phonetics: [
        { text: "/vɜːst/", audio: "" },
        { text: "/vɝst/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A German- or Austrian-style sausage.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/wurst"],
    },
  ],
  chock: [
    {
      word: "chock",
      phonetic: "/tʃɒk/",
      phonetics: [
        { text: "/tʃɒk/", audio: "" },
        { text: "/tʃɑk/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any object used as a wedge or filler, especially when placed behind a wheel to prevent it from rolling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any fitting or fixture used to restrict movement, especially movement of a line; traditionally was a fixture near a bulwark with two horns pointing towards each other, with a gap between where the line can be inserted.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To stop or fasten, as with a wedge, or block; to scotch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fill up, as a cavity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To insert a line in a chock.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            { definition: "Entirely; quite.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chock"],
    },
    {
      word: "chock",
      phonetic: "/tʃɒk/",
      phonetics: [
        { text: "/tʃɒk/", audio: "" },
        { text: "/tʃɑk/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "An encounter.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To encounter.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chock"],
    },
    {
      word: "chock",
      phonetic: "/tʃɒk/",
      phonetics: [
        { text: "/tʃɒk/", audio: "" },
        { text: "/tʃɑk/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To make a dull sound.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chock"],
    },
  ],
  quash: [
    {
      word: "quash",
      phonetic: "/kwɒʃ/",
      phonetics: [
        { text: "/kwɒʃ/", audio: "" },
        {
          text: "/kwɑʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/quash-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=42505143",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To defeat decisively.",
              synonyms: [],
              antonyms: [],
              example: "The army quashed the rebellion.",
            },
            {
              definition: "To crush or dash to pieces.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To void or suppress (a subpoena, decision, etc.).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/quash"],
    },
  ],
  quals: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  hayed: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  bombe: [
    {
      word: "bombe",
      phonetic: "/bɒm/",
      phonetics: [{ text: "/bɒm/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A dessert made from ice cream frozen in a (generally spherical or hemispherical) mold.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(chiefly in German cooking) A small, cylindrical or spherical chocolate-covered confection. (Compare truffle.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An electromechanical device used in early cryptanalysis.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bombe"],
    },
  ],
  cushy: [
    {
      word: "cushy",
      phonetic: "/ˈkʊ.ʃi/",
      phonetics: [{ text: "/ˈkʊ.ʃi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Easy, making few demands, comfortable.",
              synonyms: [],
              antonyms: [],
              example:
                "This is a really cushy job. Mostly I just sit around drinking tea.",
            },
            {
              definition:
                "Comfortable; often in a way that will suit a person's body.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cushy"],
    },
  ],
  spacy: [
    {
      word: "spacy",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Spaced-out", synonyms: [], antonyms: [] },
            { definition: "Eccentric", synonyms: [], antonyms: [] },
            {
              definition: "Having much space",
              synonyms: [],
              antonyms: [],
              example: "This car is very spacy.",
            },
            {
              definition:
                "Of, related to or connected with the extraterrestrial",
              synonyms: [],
              antonyms: [],
              example: "His latest CD sounds very spacy.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spacy"],
    },
  ],
  puked: [
    {
      word: "puked",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To vomit; to throw up; to eject from the stomach.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To sell securities or investments at a loss, often under duress or pressure, in order to satisfy liquidity or margin requirements, or out of a desire to exit a deteriorating market.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/puke",
        "https://en.wiktionary.org/wiki/puked",
      ],
    },
  ],
  leery: [
    {
      word: "leery",
      phonetic: "/ˈlɪə.ɹi/",
      phonetics: [{ text: "/ˈlɪə.ɹi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Cautious, suspicious, wary, hesitant, or nervous about something; having reservations or concerns.",
              synonyms: [],
              antonyms: [],
              example:
                "Since he was bitten by a dog when he was young, he has always been leery of animals.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/leery"],
    },
  ],
  thews: [
    {
      word: "thews",
      phonetic: "/θjuːz/",
      phonetics: [{ text: "/θjuːz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A bondman; a slave.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To oppress; enslave.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Muscle or sinew.", synonyms: [], antonyms: [] },
            {
              definition: "A good quality or habit; virtue.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(usually plural) An attractive physical attribute, especially muscle; mental or moral vigour.",
              synonyms: [],
              antonyms: [],
              example:
                "1998: B.A. Roberts, Battle Magic – As I pull two Mercian shafts from my bloodied thews.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "Instruct in morals or values; chastise.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/thew",
        "https://en.wiktionary.org/wiki/thews",
      ],
    },
  ],
  prink: [
    {
      word: "prink",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To give a wink; to wink.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/prink"],
    },
    {
      word: "prink",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The act of adjusting dress or appearance; a sprucing up",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To look, gaze.", synonyms: [], antonyms: [] },
            {
              definition: "To dress finely, primp, preen, spruce up.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To strut, put on pompous airs, be pretentious.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["prank"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/prink"],
    },
  ],
  amens: [
    {
      word: "amens",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An instance of saying ‘amen’.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A title of Christ; the Faithful One (especially with reference to Revelation 3:14)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To say amen.", synonyms: [], antonyms: [] },
            {
              definition: "To say amen to; to ratify solemnly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/amen",
        "https://en.wiktionary.org/wiki/amens",
      ],
    },
  ],
  tesla: [
    {
      word: "tesla",
      phonetic: "/ˈtɛslə/",
      phonetics: [
        {
          text: "/ˈtɛslə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tesla-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1161581",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "In the International System of Units, the derived unit of magnetic flux density or magnetic inductivity. Symbol: T",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tesla"],
    },
  ],
  intro: [
    {
      word: "intro",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/intro-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88680968",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈɪntɹoʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "An introduction.", synonyms: [], antonyms: [] },
            {
              definition:
                "The opening sequence at beginning of a film, television program, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small demo produced to promote one's demogroup or for a competition.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["outro"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To introduce.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/intro"],
    },
  ],
  fiver: [
    {
      word: "fiver",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/fiver-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79268221",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈfaɪvɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A banknote with a value of five units of currency.",
              synonyms: [],
              antonyms: [],
              example:
                "Do you have a fiver I could borrow? I can pay you back tomorrow.",
            },
            {
              definition:
                "(by extension) The value in money that this represents.",
              synonyms: [],
              antonyms: [],
              example: "I bought the chocolates; they were only a fiver.",
            },
            {
              definition: "A clenched fist.",
              synonyms: ["bunch of fives"],
              antonyms: [],
            },
            {
              definition: "A mathematical puzzle played on a 5 × 5 grid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A Zaydi Shiite Muslim, who disagrees with the majority of Shiites on the identity of the Fifth Imam.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person who gives five percent of their income or five hours a week of their time to charity (a reduction of ten percent tithing).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bunch of fives"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fiver"],
    },
  ],
  frump: [
    {
      word: "frump",
      phonetic: "/fɹʌmp/",
      phonetics: [{ text: "/fɹʌmp/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A frumpy person, somebody who is unattractive, drab or dowdy.",
              synonyms: [],
              antonyms: [],
              example: "You look like such a frump today!",
            },
            {
              definition: "The clothes that such a person would wear.",
              synonyms: [],
              antonyms: [],
              example: "Get that frump off – it's horrid!",
            },
            {
              definition: "A bad-tempered person.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A flout or snub.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To insult; to flout; to mock; to snub.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/frump"],
    },
  ],
  capos: [
    {
      word: "capos",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A movable bar placed across the fingerboard of a guitar used to raise the pitch of all strings.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["capotasto"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A leader in the Mafia; a caporegime.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A leader and organizer of supporters at a sporting event, particularly association football matches.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/capo",
        "https://en.wiktionary.org/wiki/capos",
      ],
    },
  ],
  opine: [
    {
      word: "opine",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/opine-1-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75833488",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ə(ʊ)ˈpaɪn/", audio: "" },
        {
          text: "/oʊˈpaɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/opine-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=8376582",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To have or express an opinion; to state as an opinion; to suppose, consider (that).",
              synonyms: [],
              antonyms: [],
              example:
                '"Your decisions," she opined, "have been unfailingly disastrous for this company."',
            },
            {
              definition:
                "To give one's formal opinion (on or upon something).",
              synonyms: [],
              antonyms: [],
              example:
                "I had to opine on the situation because I thought a different perspective was in order.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/opine"],
    },
    {
      word: "opine",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of a class of organic compounds, derived from amino acids, found in some plant tumours",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/opine"],
    },
  ],
  coder: [
    {
      word: "coder",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A device that generates a code, often as a series of pulses.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person who assigns codes or classifications.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A programmer.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["programmer"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/coder"],
    },
  ],
  namer: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  jowly: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  pukes: [
    {
      word: "pukes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Vomit.", synonyms: [], antonyms: [] },
            {
              definition: "A drug that induces vomiting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A worthless, despicable person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person from Missouri.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["rotter"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To vomit; to throw up; to eject from the stomach.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To sell securities or investments at a loss, often under duress or pressure, in order to satisfy liquidity or margin requirements, or out of a desire to exit a deteriorating market.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/puke",
        "https://en.wiktionary.org/wiki/pukes",
      ],
    },
  ],
  haled: [
    {
      word: "haled",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To drag or pull, especially forcibly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hale",
        "https://en.wiktionary.org/wiki/haled",
      ],
    },
  ],
  chard: [
    {
      word: "chard",
      phonetic: "/tʃɑːd/",
      phonetics: [
        { text: "/tʃɑːd/", audio: "" },
        { text: "/tʃɑɹd/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An edible leafy vegetable, Beta vulgaris subsp. cicla, with a slightly bitter taste.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Artichoke leaves and shoots, blanched to eat.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["Swiss chard", "mangold", "silverbeet"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/chard"],
    },
  ],
  duffs: [
    {
      word: "duffs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To disguise something to make it look new.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To alter the branding of stolen cattle; to steal cattle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: '(with "up") To beat up.',
              synonyms: [],
              antonyms: [],
              example:
                "I heard Nick got duffed up behind the shopping centre at the weekend.",
            },
            {
              definition: "To hit the ground behind the ball.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/duff",
        "https://en.wiktionary.org/wiki/duffs",
      ],
    },
  ],
  bruin: [
    {
      word: "bruin",
      phonetic: "/ˈbɹuːɪn/",
      phonetics: [{ text: "/ˈbɹuːɪn/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A folk name for a bear, especially the brown bear, Ursus arctos.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bruin"],
    },
  ],
  reuse: [
    {
      word: "reuse",
      phonetic: "/ɹiːˈjuːs/",
      phonetics: [
        {
          text: "/ɹiːˈjuːs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/reuse-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2454244",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The act of salvaging or in some manner restoring a discarded item to yield something usable.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The act of using again, or in another place.",
              synonyms: [],
              antonyms: [],
              example: "Code reuse can save programmers a lot of typing.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To use again something that is considered past its usefulness (usually for something else).",
              synonyms: [],
              antonyms: [],
              example:
                "The students reused empty plastic bottles in their science experiment.",
            },
            {
              definition: "To use again, or in another place.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/reuse"],
    },
  ],
  whang: [
    {
      word: "whang",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A blow; a whack.", synonyms: [], antonyms: [] },
            {
              definition: "A large piece or slice; a chunk.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A house-cleaning party.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(chiefly of an object) To make a noise like something moving quickly through the air.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To throw with a rapid slamming motion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To whack or beat.",
              synonyms: [],
              antonyms: [],
              example: "I ought to have whanged him one in the eye.",
            },
            {
              definition: "To slice, especially into large pieces; to chop.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/whang"],
    },
  ],
  toons: [
    {
      word: "toons",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A cartoon, especially an animated television show.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A player's avatar or visible character in a massively multiplayer online role-playing game.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A southeast Asian and Australian tree (Toona ciliata or Toona australis) of the mahogany family with fragrant dark red wood and flowers that yield a dye.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The wood of this tree.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["toonwood"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "A town.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A melody.", synonyms: [], antonyms: [] },
            {
              definition: "A song, or short musical composition.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The act of tuning or maintenance.",
              synonyms: [],
              antonyms: [],
              example: "Your engine needs a good tune.",
            },
            {
              definition: "The state or condition of being correctly tuned.",
              synonyms: [],
              antonyms: [],
              example: "This piano is not in tune.",
            },
            {
              definition: "Temper; frame of mind.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sound; a note; a tone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Order; harmony; concord.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/toon",
        "https://en.wiktionary.org/wiki/toons",
        "https://en.wiktionary.org/wiki/tune",
      ],
    },
  ],
  frats: [
    {
      word: "frats",
      phonetic: "/fɹæts/",
      phonetics: [{ text: "/fɹæts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Shortened form for fraternity, college organization. (Often used as a noun modifier.)",
              synonyms: [],
              antonyms: [],
              example: '"All the frats will be there."',
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/frat",
        "https://en.wiktionary.org/wiki/frats",
      ],
    },
  ],
  silty: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  telex: [
    {
      word: "telex",
      phonetic: "/tɛlɛks/",
      phonetics: [{ text: "/tɛlɛks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A communications system consisting of a network of teletypewriters.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A message sent through such a network.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The machine used to send and receive such messages.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To send (a message) by telex.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/telex"],
    },
  ],
  cutup: [
    {
      word: "cutup",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Someone who cuts up; someone who acts boisterously or clownishly, for example, by playing practical jokes.",
              synonyms: ["class clown", "prankster"],
              antonyms: [],
            },
          ],
          synonyms: ["class clown", "prankster"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cutup"],
    },
  ],
  nisei: [
    {
      word: "nisei",
      phonetic: "/ˈniseɪ/",
      phonetics: [{ text: "/ˈniseɪ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An American or Canadian whose parents were Japanese immigrants",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/nisei"],
    },
  ],
  neato: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  decaf: [
    {
      word: "decaf",
      phonetic: "/ˈdiːkæf/",
      phonetics: [{ text: "/ˈdiːkæf/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A decaffeinated coffee, tea, or soft drink.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/decaf"],
    },
  ],
  softy: [
    {
      word: "softy",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/softy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75701437",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A weak or sentimental person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Somebody who finds it difficult to scold or punish.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A software expert who is ignorant of the workings of hardware.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A soft drink containing no alcohol.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["nestle-cock", "sissy", "wuss"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/softy"],
    },
  ],
  bimbo: [
    {
      word: "bimbo",
      phonetic: "/ˈbɪmbəʊ/",
      phonetics: [
        { text: "/ˈbɪmbəʊ/", audio: "" },
        {
          text: "/ˈbɪmboʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bimbo-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=333689",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A physically attractive woman who lacks intelligence.",
              synonyms: ["airhead", "bimbette", "dumb blonde", "floozie"],
              antonyms: [],
            },
            {
              definition: "A stupid or foolish person.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["airhead", "bimbette", "dumb blonde", "floozie"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bimbo"],
    },
  ],
  adlib: [
    {
      word: "adlib",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To perform without script.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To perform without preparation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To perform extemporaneously.",
              synonyms: [],
              antonyms: [],
              example: "She ad libbed the rest of the scene.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Alternative spelling of ad-lib",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ad%20lib",
        "https://en.wiktionary.org/wiki/adlib",
      ],
    },
  ],
  loony: [
    {
      word: "loony",
      phonetic: "/ˈluːni/",
      phonetics: [
        {
          text: "/ˈluːni/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/loony-ca.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=580367",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An insane or very foolish person.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "(of a person) Insane.", synonyms: [], antonyms: [] },
            {
              definition: "(of a thing) Very silly, absurd.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/loony"],
    },
  ],
  shoed: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  agues: [
    {
      word: "agues",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To strike with an ague, or with a cold fit.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ague",
        "https://en.wiktionary.org/wiki/agues",
      ],
    },
  ],
  peeve: [
    {
      word: "peeve",
      phonetic: "/piːv/",
      phonetics: [{ text: "/piːv/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An annoyance or grievance.",
              synonyms: [],
              antonyms: [],
              example: "stylistic peeve",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To annoy; vex.",
              synonyms: [],
              antonyms: [],
              example:
                "He was peeved to note that his hard work had been undone.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/peeve"],
    },
  ],
  noway: [
    {
      word: "noway",
      phonetic: "/ˈnəʊweɪ/",
      phonetics: [{ text: "/ˈnəʊweɪ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "In no manner or degree; not at all; nowise; no way.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/noway"],
    },
  ],
  gamey: [
    {
      word: "gamey",
      phonetic: "/ˈɡeɪmi/",
      phonetics: [{ text: "/ˈɡeɪmi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Having the smell, taste and texture of consumed game (meat).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Plucky, spirited or gritty",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Risque, sordid or sexually suggestive",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gamey"],
    },
  ],
  sarge: [
    {
      word: "sarge",
      phonetic: "/sɑːd͡ʒ/",
      phonetics: [
        { text: "/sɑːd͡ʒ/", audio: "" },
        { text: "/saɹd͡ʒ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "Sergeant", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sarge"],
    },
    {
      word: "sarge",
      phonetic: "/sɑːd͡ʒ/",
      phonetics: [
        { text: "/sɑːd͡ʒ/", audio: "" },
        { text: "/saɹd͡ʒ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(pickup community) to go out and engage women in order to pick them up",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sarge"],
    },
  ],
  reran: [
    {
      word: "reran",
      phonetic: "/ˈɹiːɹæn/",
      phonetics: [{ text: "/ˈɹiːɹæn/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To run (a previously broadcast television program) again.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To run (a race) again.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To run (a computer program) again.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["reair"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/reran",
        "https://en.wiktionary.org/wiki/rerun",
      ],
    },
  ],
  epact: [
    {
      word: "epact",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The time (number of days) by which a solar year exceeds twelve lunar months; it is used in the calculation of the date of Easter",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/epact"],
    },
  ],
  potty: [
    {
      word: "potty",
      phonetic: "/ˈpɒti/",
      phonetics: [{ text: "/ˈpɒti/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To go and use the potty: to leave in order to urinate or defecate.",
              synonyms: [],
              antonyms: [],
              example: "I have to go potty!",
            },
          ],
          synonyms: ["defecate", "use the toilet"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To go crazy: to become insane.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(diminutive) A chamber pot, particularly (children) the pot used when toilet-training children.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(diminutive) Any other device or place for urination or defecation: a toilet; a lavatory; a latrine; an outhouse.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/go%20potty",
        "https://en.wiktionary.org/wiki/potty",
      ],
    },
    {
      word: "potty",
      phonetic: "/ˈpɒti/",
      phonetics: [{ text: "/ˈpɒti/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Insane.",
              synonyms: [],
              antonyms: [],
              example:
                "The noise that the neighbour's kids were making was driving Fred potty.",
            },
            {
              definition: "Easy to pot the ball on.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/potty"],
    },
  ],
  coned: [
    {
      word: "coned",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To fashion into the shape of a cone.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To form a cone shape.", synonyms: [], antonyms: [] },
            {
              definition:
                '(frequently followed by "off") To segregate or delineate an area using traffic cones',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(of an area) segregated or delineated by traffic cones",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cone",
        "https://en.wiktionary.org/wiki/coned",
      ],
    },
  ],
  upend: [
    {
      word: "upend",
      phonetic: "/ʌpˈɛnd/",
      phonetics: [
        {
          text: "/ʌpˈɛnd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/upend-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=332358",
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To end up; to set on end.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To tip or turn over.",
              synonyms: [],
              antonyms: [],
              example:
                "When he upended the bottle of water over his sleeping sister, the lid popped off and surprised them both.",
            },
            {
              definition: "To destroy, invalidate, overthrow, or defeat.",
              synonyms: [],
              antonyms: [],
              example: "The scientific evidence upended the popular myth.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/upend"],
    },
  ],
  narco: [
    {
      word: "narco",
      phonetic: "/ˈnɑːkəʊ/",
      phonetics: [{ text: "/ˈnɑːkəʊ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Narcotics.", synonyms: [], antonyms: [] },
            {
              definition: "A South American drug baron.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A police officer specializing in drug crimes",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/narco"],
    },
    {
      word: "narco",
      phonetic: "/ˈnɑːkəʊ/",
      phonetics: [{ text: "/ˈnɑːkəʊ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Someone suffering from narcolepsy",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/narco"],
    },
  ],
  ikats: [
    {
      word: "ikats",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A style of weaving that uses a process similar to tie-dye to dye the threads.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A work woven in this style.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ikat",
        "https://en.wiktionary.org/wiki/ikats",
      ],
    },
  ],
  whorl: [
    {
      word: "whorl",
      phonetic: "/wɜː(ɹ)l/",
      phonetics: [{ text: "/wɜː(ɹ)l/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A pattern of concentric circles.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A circle of three or more leaves, flowers, or other organs, about the same part or joint of a stem.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A volution, or turn, of the spire of a univalve shell.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any volution, as for example in the human ear or fingerprint.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A flywheel, a weight attached to a spindle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To form a pattern of concentric circles.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/whorl"],
    },
  ],
  jinks: [
    {
      word: "jinks",
      phonetic: "/dʒɪŋks/",
      phonetics: [{ text: "/dʒɪŋks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A quick evasive turn.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make a quick evasive turn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause a vehicle to make a quick evasive turn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In the games of spoilfive and forty-five, to win the game by taking all five tricks; also, to attempt to win all five tricks, losing what has been already won if unsuccessful.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To elude; to cheat.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/jink",
        "https://en.wiktionary.org/wiki/jinks",
      ],
    },
  ],
  tizzy: [
    {
      word: "tizzy",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tizzy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79323956",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A state of nervous excitement, confusion, or distress; a dither.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A sixpence; a tester.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["dither", "upset"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tizzy"],
    },
  ],
  weepy: [
    {
      word: "weepy",
      phonetic: "/ˈwiːpi/",
      phonetics: [{ text: "/ˈwiːpi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sad or sentimental film, often portraying troubled romance, designed to elicit a tearfully emotional response from its audience.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Inclined to weep; tearful or lachrymose.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/weepie",
        "https://en.wiktionary.org/wiki/weepy",
      ],
    },
  ],
  posit: [
    {
      word: "posit",
      phonetic: "/ˈpɒzɪt/",
      phonetics: [{ text: "/ˈpɒzɪt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Something that is posited; a postulate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "Assume the existence of; to postulate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Propose for consideration or study; to suggest.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Put (something somewhere) firmly; to place or position.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A place or location.", synonyms: [], antonyms: [] },
            {
              definition: "A post of employment; a job.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A status or rank.",
              synonyms: [],
              antonyms: [],
              example:
                "Chief of Staff is the second-highest position in the army.",
            },
            {
              definition: "An opinion, stand or stance.",
              synonyms: [],
              antonyms: [],
              example: "My position on this issue is unchanged.",
            },
            {
              definition: "A posture.",
              synonyms: [],
              antonyms: [],
              example: "Stand in this position, with your arms at your side.",
            },
            {
              definition:
                "(team sports) A place on the playing field, together with a set of duties, assigned to a player.",
              synonyms: [],
              antonyms: [],
              example:
                "Stop running all over the field and play your position!",
            },
            {
              definition:
                "An amount of securities, commodities, or other financial instruments held by a person, firm or institution.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A commitment, or a group of commitments, such as options or futures, to buy or sell a given amount of financial instruments, such as securities, currencies or commodities, for a given price.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A method of solving a problem by one or two suppositions; also called the rule of trial and error.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The full state of a chess game at any given turn.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["stead"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/posit",
        "https://en.wiktionary.org/wiki/position",
      ],
    },
  ],
  marge: [
    {
      word: "marge",
      phonetic: "/mɑː(r)dʒ/",
      phonetics: [
        {
          text: "/mɑː(r)dʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/marge-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88676271",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Margin; edge; verge.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/marge"],
    },
    {
      word: "marge",
      phonetic: "/mɑː(r)dʒ/",
      phonetics: [
        {
          text: "/mɑː(r)dʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/marge-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88676271",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Margarine.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/marge"],
    },
  ],
  vegan: [
    {
      word: "vegan",
      phonetic: "/ˈviːɡən/",
      phonetics: [{ text: "/ˈviːɡən/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person who does not eat, drink or otherwise consume any animal products",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person committed to avoiding products and practices that inherently involve animal use, including all foods containing animal products, and to abstaining from direct and intentional harm to animals as far as possible; an adherent to veganism.",
              synonyms: [],
              antonyms: [],
              example:
                "Our vegan-friendly shaving brush is made with synthetic bristles instead of badger hair.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(of a product or practice, especially food) Not containing animal products (meat, eggs, milk, leather, etc) or inherently involving animal use.",
              synonyms: [],
              antonyms: [],
              example: "He eats a completely vegan diet.",
            },
            {
              definition:
                "(of a person) Committed to avoiding any product or practice that inherently involves animal use.",
              synonyms: [],
              antonyms: [],
              example: "She is not vegan as she eats eggs and wears leather.",
            },
            {
              definition: "Relating to vegans or veganism.",
              synonyms: [],
              antonyms: [],
              example: "She is interested in vegan philosophy.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/vegan"],
    },
  ],
  clops: [
    {
      word: "clops",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The sound of a horse's shod hoof striking the ground.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "My Little Pony-themed pornography",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make this sound; to walk so as to make this sound.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/clop",
        "https://en.wiktionary.org/wiki/clops",
      ],
    },
  ],
  numbs: [
    {
      word: "numbs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cause to become numb (physically or emotionally).",
              synonyms: ["benumb"],
              antonyms: [],
              example:
                "The dentist gave me novocaine to numb my tooth before drilling, thank goodness.",
            },
            {
              definition: "To cause (a feeling) to be less intense.",
              synonyms: ["dull"],
              antonyms: [],
              example: "He turned to alcohol to numb his pain.",
            },
            {
              definition:
                "To cause (the mind, faculties, etc.) to be less acute.",
              synonyms: ["dull"],
              antonyms: [],
            },
            {
              definition: "To become numb (especially physically).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["benumb", "dull", "dull"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/numb",
        "https://en.wiktionary.org/wiki/numbs",
      ],
    },
  ],
  reeks: [
    {
      word: "reeks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A strong unpleasant smell.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Vapour; steam; smoke; fume.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To have or give off a strong, unpleasant smell.",
              synonyms: [],
              antonyms: [],
              example: "You reek of perfume.",
            },
            {
              definition:
                "To be evidently associated with something unpleasant.",
              synonyms: [],
              antonyms: [],
              example:
                "The boss appointing his nephew as a director reeks of nepotism.",
            },
            {
              definition:
                "To be emitted or exhaled, emanate, as of vapour or perfume.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To emit smoke or vapour; to steam.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A hill; a mountain.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/reek",
        "https://en.wiktionary.org/wiki/reeks",
      ],
    },
  ],
  rubes: [
    {
      word: "rubes",
      phonetic: "/ɹuːbz/",
      phonetics: [{ text: "/ɹuːbz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A person of rural heritage; a yokel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An uninformed, unsophisticated, or unintelligent person.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rube",
        "https://en.wiktionary.org/wiki/rubes",
      ],
    },
  ],
  rower: [
    {
      word: "rower",
      phonetic: "/ˈɹəʊ.ə(ɹ)/",
      phonetics: [{ text: "/ˈɹəʊ.ə(ɹ)/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "One who rows.", synonyms: [], antonyms: [] },
            { definition: "A rowing machine.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rower"],
    },
  ],
  biped: [
    {
      word: "biped",
      phonetic: "/ˈbaɪpɛd/",
      phonetics: [{ text: "/ˈbaɪpɛd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An animal, being or construction that goes about on two feet (or two legs).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/biped"],
    },
  ],
  tiffs: [
    {
      word: "tiffs",
      phonetic: "/tɪfs/",
      phonetics: [{ text: "/tɪfs/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A small argument; a petty quarrel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Liquor; especially, a small draught of liquor.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To quarrel.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To deck out; to dress.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(British India) To have lunch.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tiff",
        "https://en.wiktionary.org/wiki/tiffs",
      ],
    },
  ],
  hocus: [
    {
      word: "hocus",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A magician, illusionist, one who practises sleight of hand.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One who cheats or deceives.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Trick; trickery.", synonyms: [], antonyms: [] },
            { definition: "Drugged liquor.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To play a trick on, to trick (someone); to hoax; to cheat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To stupefy (someone) with drugged liquor (especially in order to steal from them).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To drug (liquor).", synonyms: [], antonyms: [] },
            { definition: "To adulterate (food).", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hocus"],
    },
  ],
  hammy: [
    {
      word: "hammy",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hammy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75271775",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Resembling or characteristic of ham.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Amateurish; characterized by overacting.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One of the great tendons situated in each side of the ham, or space back of the knee, and connected with the muscles of the back of the thigh.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The biceps femoris, semimembranosus, and semitendinosus muscles.",
              synonyms: ["hams"],
              antonyms: [],
            },
          ],
          synonyms: ["hams"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hammy",
        "https://en.wiktionary.org/wiki/hamstring",
      ],
    },
    {
      word: "hammy",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hammy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75271775",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A hamster.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hammy"],
    },
  ],
  bunco: [
    {
      word: "bunco",
      phonetic: "/ˈbʌŋkəʊ/",
      phonetics: [
        {
          text: "/ˈbʌŋkəʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bunco-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79267929",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A swindle or confidence trick.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A parlour game played in teams with three dice, originating in England but popular among suburban women in the United States at the beginning of the 21st century.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A brigand.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To swindle (someone).", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bunco"],
    },
  ],
  fixit: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  tykes: [
    {
      word: "tykes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A mongrel dog.", synonyms: [], antonyms: [] },
            {
              definition:
                "A small child, especially a cheeky or mischievous one",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A crude uncouth ill-bred person lacking culture or refinement",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person from Yorkshire; a Yorkshireman or Yorkshirewoman",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A Roman Catholic", synonyms: [], antonyms: [] },
          ],
          synonyms: ["mongrel", "mutt", "ankle-biter", "nipper", "tot"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tyke",
        "https://en.wiktionary.org/wiki/tykes",
      ],
    },
  ],
  chaws: [
    {
      word: "chaws",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Chewing tobacco.",
              synonyms: [],
              antonyms: [],
              example:
                "When the doctor told him to quit smoking, Harvey switched to chaw, but then developed cancer of the mouth.",
            },
            {
              definition: "A plug or wad of chewing tobacco.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The jaw.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To chew; to grind with one's teeth; to masticate (food, or the cud)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To ruminate (about) in thought; to ponder; to consider",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To steal.",
              synonyms: [],
              antonyms: [],
              example: "Some pikey's chawed my bike.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/chaw",
        "https://en.wiktionary.org/wiki/chaws",
      ],
    },
  ],
  yucky: [
    {
      word: "yucky",
      phonetic: "/ˈjʌkɪ/",
      phonetics: [
        { text: "/ˈjʌkɪ/", audio: "" },
        { text: "/ˈjʌki/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Of something highly offensive; causing aversion or disgust.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "disgusting",
            "distasteful",
            "foul",
            "gross",
            "loathsome",
            "nasty",
            "offensive",
            "repugnant",
            "revolting",
            "unpleasant",
            "wicked",
          ],
          antonyms: ["yummy"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/yucky"],
    },
  ],
  hokey: [
    {
      word: "hokey",
      phonetic: "/ˈhoʊki/",
      phonetics: [
        {
          text: "/ˈhoʊki/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hokey-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=19231499",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Phony, as if a hoax; noticeably contrived; of obviously flimsy credibility or quality",
              synonyms: [],
              antonyms: [],
              example:
                "I thought the windshield wiper blades were a little hokey when I saw their cheap packaging.",
            },
            {
              definition: "Corny; overly or unbelievably sentimental",
              synonyms: ["cheesy", "kitschy"],
              antonyms: [],
              example:
                "Terry hated going to the cinema with Pat, she always chose hokey romantic comedies that made him want to gag.",
            },
          ],
          synonyms: ["cheesy", "kitschy"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hokey"],
    },
  ],
  resew: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  maven: [
    {
      word: "maven",
      phonetic: "/ˈmeɪvən/",
      phonetics: [
        {
          text: "/ˈmeɪvən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/maven-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=95144427",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An expert in a given field.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/maven"],
    },
  ],
  adman: [
    {
      word: "adman",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person in the business of devising, writing, illustrating or selling advertisements.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/adman"],
    },
  ],
  scuzz: [
    {
      word: "scuzz",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scuzz-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79325993",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An unpleasant or disgusting (scuzzy) person",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scuzz"],
    },
  ],
  slogs: [
    {
      word: "slogs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A long, tedious walk, or session of work.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An aggressive shot played with little skill.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/slog",
        "https://en.wiktionary.org/wiki/slogs",
      ],
    },
  ],
  souse: [
    {
      word: "souse",
      phonetic: "/saʊs/",
      phonetics: [{ text: "/saʊs/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Something kept or steeped in brine",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The act of sousing; a plunging into water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person suffering from alcoholism.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["alcoholic", "sot", "suck-pint"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To immerse in liquid; to steep or drench.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To steep in brine; to pickle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/souse"],
    },
    {
      word: "souse",
      phonetic: "/saʊs/",
      phonetics: [{ text: "/saʊs/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act of sousing, or swooping.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A heavy blow.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To strike, beat.", synonyms: [], antonyms: [] },
            { definition: "To fall heavily.", synonyms: [], antonyms: [] },
            { definition: "To pounce upon.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/souse"],
    },
    {
      word: "souse",
      phonetic: "/saʊs/",
      phonetics: [{ text: "/saʊs/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A sou (the French coin).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A small amount.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/souse"],
    },
  ],
  nacho: [
    {
      word: "nacho",
      phonetic: "/ˈnæt͡ʃəʊ/",
      phonetics: [
        {
          text: "/ˈnæt͡ʃəʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/nacho-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89007877",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈnɑt͡ʃoʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A single tortilla chip from a dish of nachos.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/nacho"],
    },
  ],
  mimed: [
    {
      word: "mimed",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To mimic.", synonyms: [], antonyms: [] },
            { definition: "To act without words.", synonyms: [], antonyms: [] },
            {
              definition:
                "To represent an action or object through gesture, without the use of sound.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mime",
        "https://en.wiktionary.org/wiki/mimed",
      ],
    },
  ],
  melds: [
    {
      word: "melds",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To combine multiple similar objects into one",
              synonyms: [],
              antonyms: [],
              example:
                "Much as America's motto celebrates melding many into one, South Africa's says that it doesn't matter what you look like — we can all be proud of our young country. - The New York Times, 26/02/2007 http//www.nytimes.com/2007/02/27/world/africa/27safrica.html?_r=1&oref=login",
            },
          ],
          synonyms: ["conflate"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "In card games, especially of the rummy family, to announce or display a combination of cards.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/meld",
        "https://en.wiktionary.org/wiki/melds",
      ],
    },
  ],
  boffo: [
    {
      word: "boffo",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/boffo-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79325591",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(chiefly in the world of entertainment) A great success; a hit.",
              synonyms: ["boff", "clicko"],
              antonyms: [],
            },
          ],
          synonyms: ["boff", "clicko"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Outstanding; very good or successful.",
              synonyms: ["boff", "clicko"],
              antonyms: [],
            },
          ],
          synonyms: ["boff", "clicko"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/boffo"],
    },
  ],
  debit: [
    {
      word: "debit",
      phonetic: "/ˈdɛb.ɪt/",
      phonetics: [
        {
          text: "/ˈdɛb.ɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/debit-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=711429",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "In bookkeeping, an entry in the left hand column of an account.",
              synonyms: [],
              antonyms: [],
              example:
                "A cash sale is recorded as debit on the cash account and as credit on the sales account.",
            },
            {
              definition:
                "A sum of money taken out of a bank account. Thus called, because in bank's bookkeeping a cash withdrawal diminishes the amount of money held on the account, i.e. bank's debt to the customer.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make an entry on the debit side of an account.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To record a receivable in the bookkeeping.",
              synonyms: [],
              antonyms: [],
              example:
                "We shall debit the amount of your purchase to your account.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Of or relating to process of taking money from an account",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of or relating to the debit card function of a debit card rather than its often available credit card function",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["credit"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/debit"],
    },
  ],
  pinup: [
    {
      word: "pinup",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A photograph, printed in a magazine or other publication, of a sexually attractive person (often nude or provocatively dressed), and intended to be removed and pinned up on a wall.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The person so depicted.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Figurehead, person who represents an idea, cause etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["centerfold", "centerfold", "pin-up girl"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pin-up",
        "https://en.wiktionary.org/wiki/pinup",
      ],
    },
  ],
  vagus: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  gulag: [
    {
      word: "gulag",
      phonetic: "/ˈɡu.lɑɡ/",
      phonetics: [{ text: "/ˈɡu.lɑɡ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A prison camp.", synonyms: [], antonyms: [] },
            {
              definition:
                "The system of all Soviet prison and/or labor camps in use during the Stalinist period.",
              synonyms: [],
              antonyms: [],
              example:
                "One important difference between the GULAG system and the Nazi concentration camps was that a person sentenced to five years of hard labor in a Soviet labor camp could expect, assuming he or she survived, to be released at the end of the sentence. [Gulag: Soviet Prison Camps and Their Legacy; By David Hosford, Pamela Kachurin and Thomas Lamont. National Resource Center for Russian, East European and Central Asian Studies, Harvard University]",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To force into this prison or a similar system.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gulag"],
    },
  ],
  randy: [
    {
      word: "randy",
      phonetic: "/ˈɹændi/",
      phonetics: [{ text: "/ˈɹændi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Impudent beggar", synonyms: [], antonyms: [] },
            {
              definition: "Boisterous, coarse, loose woman",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Virago", synonyms: [], antonyms: [] },
            { definition: "Random", synonyms: [], antonyms: [] },
            {
              definition:
                "(aerial freestyle skiing) one and a half twist acrobatic maneuver",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Sexually aroused; full of sexual lust.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Rude or coarse in manner.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["horny", "toey", "coarse", "rude"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/randy"],
    },
  ],
  bosun: [
    {
      word: "bosun",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A warrant or petty officer on board a naval ship.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bosun"],
    },
  ],
  educe: [
    {
      word: "educe",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "An inference.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To direct the course of (a flow, journey etc.); to lead in a particular direction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To infer or deduce (a result, theory etc.) from existing data or premises.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To draw out or bring forth from some basic or potential state; to elicit, to develop.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To isolate (a substance) from a compound; to extract.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cause or generate; to bring about.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/educe"],
    },
  ],
  faxes: [
    {
      word: "faxes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The hair of the head.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A fax machine or a document received and printed by one.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To send a document via a fax machine.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fax",
        "https://en.wiktionary.org/wiki/faxes",
      ],
    },
  ],
  auras: [
    {
      word: "auras",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Distinctive atmosphere or quality associated with something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An invisible force surrounding a living creature.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Perceptual disturbance experienced by some migraine sufferers before a migraine headache.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Telltale sensation experienced by some people with epilepsy before a seizure.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["air", "feeling", "mood", "spirit", "vibe"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/aura",
        "https://en.wiktionary.org/wiki/auras",
      ],
    },
  ],
  pesto: [
    {
      word: "pesto",
      phonetic: "/ˈpɛstəʊ/",
      phonetics: [
        { text: "/ˈpɛstəʊ/", audio: "" },
        { text: "/ˈpɛstoʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sauce, especially for pasta, originating from the Genoa region in Italy, made from basil, garlic, pine nuts, olive oil and cheese (usually pecorino)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pesto"],
    },
  ],
  antsy: [
    {
      word: "antsy",
      phonetic: "/ˈæn.tsi/",
      phonetics: [
        {
          text: "/ˈæn.tsi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/antsy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75729515",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Restless, apprehensive and fidgety",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/antsy"],
    },
  ],
  betas: [
    {
      word: "betas",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The second letter of the Greek alphabet (Β,  β), preceded by alpha (Α,  α) and followed by gamma, (Γ,  γ). In modern Greek it represents the voiced labiodental fricative sound of v found in the English words have and vase.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Used in marking scheme: α, β, γ or α+, α, α-, β etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Average sensitivity of a security's price to overall securities market prices.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The phase of development after alpha testing and before launch, in which software, while not complete, has been released to potential users for testing.",
              synonyms: [],
              antonyms: [],
              example:
                "The company is offering a public beta program to test the software.",
            },
            {
              definition: "Software in such a phase; a preliminary version.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Information about a route which may aid someone in climbing it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A beta particle or beta ray.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In omegaverse fiction, a person of a (usually secondary) gender/sex that mirrors the biology of a normal human, being free from the biological drives of alphas and omegas but generally capable of bonding and mating with either.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To preliminarily release computer software for initial testing prior to final release.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To beta-read a text.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(manosphere, pickup community) A man who is less competent or desirable than an alpha male.",
              synonyms: ["beta"],
              antonyms: [],
            },
          ],
          synonyms: ["beta"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any fish of the genus Betta, especially Betta splendens (the Siamese fighting fish).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["Siamese fighting fish"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/beta",
        "https://en.wiktionary.org/wiki/beta%20male",
        "https://en.wiktionary.org/wiki/betas",
        "https://en.wiktionary.org/wiki/betta",
      ],
    },
  ],
  fizzy: [
    {
      word: "fizzy",
      phonetic: "/ˈfɪzi/",
      phonetics: [{ text: "/ˈfɪzi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A non-alcoholic carbonated beverage.",
              synonyms: ["fizzy drink"],
              antonyms: [],
            },
          ],
          synonyms: ["fizzy drink"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "(of a liquid) Containing bubbles.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Lively, vivacious.", synonyms: [], antonyms: [] },
            {
              definition: "Makes a hissing sound.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["carbonated", "sparkling"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fizzy"],
    },
  ],
  dorky: [
    {
      word: "dorky",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Like a dork.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dorky"],
    },
  ],
  snits: [
    {
      word: "snits",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A temper; a lack of patience; a bad mood.",
              synonyms: [],
              antonyms: [],
              example:
                "He's in a snit because he got passed over for promotion.",
            },
            {
              definition:
                "A U.S. unit of volume for liquor equal to 2 jiggers, 3 U.S. fluid ounces, or 88.7 milliliters.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A beer chaser commonly served in three-ounce servings in highball or juice glasses with a Bloody Mary cocktail in the upper midwest states of United States including Minnesota, North Dakota, Wisconsin, Iowa, Michigan, and Illinois.",
              synonyms: [],
              antonyms: [],
              example:
                "The bartender served us each a snit with our Bloody Marys this morning.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/snit",
        "https://en.wiktionary.org/wiki/snits",
      ],
    },
  ],
  moxie: [
    {
      word: "moxie",
      phonetic: "/ˈmɒksi/",
      phonetics: [{ text: "/ˈmɒksi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Nerve, spunk, strength of character.",
              synonyms: ["backbone", "determination", "fortitude", "spirit"],
              antonyms: [],
            },
            {
              definition: "Verve.",
              synonyms: ["energy", "initiative", "pep", "vigor"],
              antonyms: [],
            },
            {
              definition: "Wit, smarts, skill.",
              synonyms: ["know-how"],
              antonyms: [],
            },
          ],
          synonyms: [
            "backbone",
            "determination",
            "fortitude",
            "spirit",
            "energy",
            "initiative",
            "pep",
            "vigor",
            "know-how",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/moxie"],
    },
  ],
  thane: [
    {
      word: "thane",
      phonetic: "/θeɪn/",
      phonetics: [{ text: "/θeɪn/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A rank of nobility in pre-Norman England, roughly equivalent to baron.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/thane"],
    },
  ],
  mylar: [
    {
      word: "mylar",
      phonetic: "/ˈmaɪlɑɹ/",
      phonetics: [{ text: "/ˈmaɪlɑɹ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A polyester film.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["BoPET"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mylar"],
    },
  ],
  nobby: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  gamin: [
    {
      word: "gamin",
      phonetic: "/ˈɡæmɪn/",
      phonetics: [
        {
          text: "/ˈɡæmɪn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gamin-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=81449368",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈɡæmɪn/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A homeless boy; a male street urchin; also (more generally), a cheeky, street-smart boy.",
              synonyms: [],
              antonyms: ["gamine"],
            },
          ],
          synonyms: [],
          antonyms: ["gamine"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gamin"],
    },
  ],
  gouty: [
    {
      word: "gouty",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Suffering from gout.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["gout-ridden"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gouty"],
    },
  ],
  esses: [
    {
      word: "esses",
      phonetic: "/ˈɛsɪz/",
      phonetics: [{ text: "/ˈɛsɪz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The name of the Latin-script letter S.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Something shaped like the letter S.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To move in a changing direction, forming the shape of a letter S.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(usually plural) A sequence of winding turns.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/es",
        "https://en.wiktionary.org/wiki/ess",
        "https://en.wiktionary.org/wiki/esses",
      ],
    },
  ],
  paned: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  druid: [
    {
      word: "druid",
      phonetic: "/ˈdɹuː.ɪd/",
      phonetics: [
        {
          text: "/ˈdɹuː.ɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/druid-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=49875789",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One of an order of priests among certain groups of Celts before the adoption of Abrahamic religions.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/druid"],
    },
  ],
  jades: [
    {
      word: "jades",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A semiprecious stone, either nephrite or jadeite, generally green or white in color, often used for carving figurines.",
              synonyms: ["jade stone", "jadestone", "yu"],
              antonyms: [],
            },
            {
              definition:
                "A bright shade of slightly bluish or greyish green, typical of polished jade stones.",
              synonyms: ["jade green"],
              antonyms: [],
            },
            {
              definition: "A succulent plant, Crassula ovata.",
              synonyms: [
                "jade plant",
                "lucky plant",
                "money plant",
                "money tree",
              ],
              antonyms: [],
            },
          ],
          synonyms: [
            "jade green",
            "jade plant",
            "lucky plant",
            "money plant",
            "money tree",
            "jade stone",
            "jadestone",
            "yu",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A horse too old to be put to work.",
              synonyms: ["nag", "yaud"],
              antonyms: [],
            },
            {
              definition: "A bad-tempered or disreputable woman.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["nag", "yaud"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To tire, weary or fatigue",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To treat like a jade; to spurn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make ridiculous and contemptible.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/jade",
        "https://en.wiktionary.org/wiki/jades",
      ],
    },
  ],
  rehab: [
    {
      word: "rehab",
      phonetic: "/ˈɹiːhæb/",
      phonetics: [
        {
          text: "/ˈɹiːhæb/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rehab-ca.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=676217",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Rehabilitation, especially to treat the use of recreational drugs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An institution for rehabilitation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To rehabilitate.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rehab"],
    },
  ],
  gofer: [
    {
      word: "gofer",
      phonetic: "/ˈɡoʊfɚ/",
      phonetics: [{ text: "/ˈɡoʊfɚ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A worker who runs errands; an errand boy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dogsbody"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gofer"],
    },
  ],
  tzars: [
    {
      word: "tzars",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An emperor of Russia (1547 to 1917) and of some South Slavic states.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person with great power; an autocrat.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tsar",
        "https://en.wiktionary.org/wiki/tzar",
        "https://en.wiktionary.org/wiki/tzars",
      ],
    },
  ],
  octet: [
    {
      word: "octet",
      phonetic: "/ɒkˈtɛt/",
      phonetics: [{ text: "/ɒkˈtɛt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A group or set of eight of something.",
              synonyms: ["nundine", "octad", "octonary", "octuplet", "ogdoad"],
              antonyms: [],
            },
            {
              definition: "A group of eight musicians performing together.",
              synonyms: [],
              antonyms: [],
              example: 'An octet of waiters sang her "Happy Birthday".',
            },
            {
              definition: "A composition for such a group of musicians.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A byte of eight bits. Abbreviation: o",
              synonyms: ["byte"],
              antonyms: [],
            },
            {
              definition:
                "A group of three bits, representing any of eight possible values.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "byte",
            "nundine",
            "octad",
            "octonary",
            "octuplet",
            "ogdoad",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/octet"],
    },
  ],
  homed: [
    {
      word: "homed",
      phonetic: "/ˈhəʊmd/",
      phonetics: [
        { text: "/ˈhəʊmd/", audio: "" },
        { text: "/ˈhoʊmd/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(of animals) To return to its owner.",
              synonyms: [],
              antonyms: [],
              example: "The dog homed.",
            },
            {
              definition: '(always with "in on") To seek or aim for something.',
              synonyms: [],
              antonyms: [],
              example: "The missile was able to home in on the target.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/home",
        "https://en.wiktionary.org/wiki/homed",
      ],
    },
  ],
  socko: [
    {
      word: "socko",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/socko-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79522781",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Superb, excellent, stunning.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/socko"],
    },
  ],
  dorks: [
    {
      word: "dorks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A long Scottish dagger with a straight blade.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(Midwest US) A penis; dork.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Midwest US) A socially unacceptable person; an oddball.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dirk",
        "https://en.wiktionary.org/wiki/dork",
        "https://en.wiktionary.org/wiki/dorks",
      ],
    },
  ],
  eared: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  anted: [
    {
      word: "anted",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/anted-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=7466484",
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To pay the ante in poker. Often used as ante up.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make an investment in money, effort, or time before knowing one's chances.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ante",
        "https://en.wiktionary.org/wiki/anted",
      ],
    },
    {
      word: "anted",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To rub insects, especially ants, on one's body, perhaps to control parasites or clean feathers.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ant",
        "https://en.wiktionary.org/wiki/anted",
      ],
    },
  ],
  elide: [
    {
      word: "elide",
      phonetic: "/ɪˈlaɪd/",
      phonetics: [{ text: "/ɪˈlaɪd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To leave out or omit (something).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cut off, as a vowel or a syllable.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To conflate; to smear together; to blur the distinction between.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/elide"],
    },
  ],
  fazes: [
    {
      word: "fazes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To frighten or cause hesitation; to daunt, put off (usually used in the negative); to disconcert, to perturb.",
              synonyms: [],
              antonyms: [],
              example:
                "Jumping out of an airplane does not faze him, yet he is afraid to ride a roller coaster.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/faze",
        "https://en.wiktionary.org/wiki/fazes",
      ],
    },
  ],
  oxbow: [
    {
      word: "oxbow",
      phonetic: "/ˈɒksbəʊ/",
      phonetics: [{ text: "/ˈɒksbəʊ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A U-shaped piece of wood used as a collar for an ox, the upper parts fastened to its yoke",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A meander in a river; the land enclosed by such a loop",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/oxbow"],
    },
  ],
  dowse: [
    {
      word: "dowse",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To plunge suddenly into water; to duck; to immerse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fall suddenly into water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To put out; to extinguish.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To strike, beat, or thrash.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To strike or lower in haste; to slacken suddenly",
              synonyms: [],
              antonyms: [],
              example: "Douse the topsail!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/douse",
        "https://en.wiktionary.org/wiki/dowse",
      ],
    },
    {
      word: "dowse",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A sudden plunging into water.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To plunge suddenly into water; to duck; to immerse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fall suddenly into water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To put out; to extinguish.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A blow or stroke, especially to the face.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To strike, beat, or thrash.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To strike or lower in haste; to slacken suddenly",
              synonyms: [],
              antonyms: [],
              example: "Douse the topsail!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/douse",
        "https://en.wiktionary.org/wiki/dowse",
      ],
    },
    {
      word: "dowse",
      phonetic: "/daʊs/",
      phonetics: [{ text: "/daʊs/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To use the dipping or divining rod, as in search of water, ore, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dowse"],
    },
  ],
  macaw: [
    {
      word: "macaw",
      phonetic: "/məˈkɔː/",
      phonetics: [
        { text: "/məˈkɔː/", audio: "" },
        { text: "/məˈkɑː/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of various parrots of the genera Ara, Anodorhynchus, Cyanopsitta, Orthopsittaca, Primolius and Diopsittaca of Central and South America, including the largest parrots and characterized by long sabre-shaped tails, curved powerful bills, and usually brilliant plumage.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/macaw"],
    },
  ],
  scone: [
    {
      word: "scone",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scone-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75649625",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/skɒn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/scone-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=568246",
        },
        { text: "/skoʊn/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small, rich, pastry or quick bread, sometimes baked on a griddle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Utah) Frybread served with honey butter spread on it.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The head.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To hit on the head.",
              synonyms: [],
              antonyms: [],
              example:
                '2004 December 3, "Sconed by a space rock - and then the headaches started", The Sydney Morning Herald',
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scone"],
    },
  ],
  drily: [
    {
      word: "drily",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            { definition: "In a dry manner", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/drily"],
    },
  ],
  hyper: [
    {
      word: "hyper",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hyper-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241521",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈhaɪpə/", audio: "" },
        { text: "/ˈhaɪpɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Hyperextension exercise",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Hyperspace", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Hyperactive", synonyms: [], antonyms: [] },
            {
              definition: "Energetic; overly diligent",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hyper"],
    },
    {
      word: "hyper",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hyper-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241521",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈhaɪpə/", audio: "" },
        { text: "/ˈhaɪpɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A wrestler who uses the hipe technique.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hiper",
        "https://en.wiktionary.org/wiki/hyper",
      ],
    },
  ],
  salsa: [
    {
      word: "salsa",
      phonetic: "/ˈsɑl.sə/",
      phonetics: [{ text: "/ˈsɑl.sə/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A spicy tomato sauce, often including onions and hot peppers.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A style of urban music originally from New York heavily influenced by Cuban dance music, jazz and rock.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any of several dances performed to salsa music.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To dance the salsa.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/salsa"],
    },
  ],
  mooch: [
    {
      word: "mooch",
      phonetic: "/muːtʃ/",
      phonetics: [
        {
          text: "/muːtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mooch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=402763",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An aimless stroll.",
              synonyms: [],
              antonyms: [],
              example:
                "Jack wouldn't be arriving for another ten minutes, so I had a mooch around the garden.",
            },
            {
              definition: "One who mooches; a moocher.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A unit of time comprising ten days, used to measure how long someone holds a job.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To wander around aimlessly, often causing irritation to others.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To beg, cadge, or sponge; to exploit or take advantage of others for personal gain.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To steal or filch.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mooch"],
    },
  ],
  gated: [
    {
      word: "gated",
      phonetic: "/ˈɡeɪtɪd/",
      phonetics: [{ text: "/ˈɡeɪtɪd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To keep something inside by means of a closed gate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To punish, especially a child or teenager, by not allowing them to go out.",
              synonyms: ["ground"],
              antonyms: [],
            },
            {
              definition: "To open a closed ion channel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To furnish with a gate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To turn (an image intensifier) on and off selectively as needed, or to avoid damage. See autogating.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["ground"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Capable of being switched on and off (normally by means of a signal).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Have a gate or other restricted access.",
              synonyms: [],
              antonyms: [],
              example:
                "a gated community;  a source control system with gated check-ins",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gate",
        "https://en.wiktionary.org/wiki/gated",
      ],
    },
  ],
  unjam: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  lipid: [
    {
      word: "lipid",
      phonetic: "/ˈlɪpɪd/",
      phonetics: [
        {
          text: "/ˈlɪpɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lipid-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1545256",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of a group of organic compounds including the fats, oils, waxes, sterols, and triglycerides. Lipids are characterized by being insoluble in water, and account for most of the fat present in the human body.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lipid"],
    },
  ],
  mitre: [
    {
      word: "mitre",
      phonetic: "/ˈmaɪtəɹ/",
      phonetics: [{ text: "/ˈmaɪtəɹ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A covering for the head, worn on solemn occasions by church dignitaries, which has been made in many forms, mostly recently a tall cap with two points or peaks.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The surface forming the bevelled end or edge of a piece where a miter joint is made; also, a joint formed or a junction effected by two beveled ends or edges; a miter joint.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A 13th-century coin minted in Europe which circulated in Ireland as a debased counterfeit sterling penny, outlawed under Edward I.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A cap or cowl for a chimney or ventilation pipe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A gusset in sewing, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A square with one triangular quarter missing from the outside.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To adorn with a mitre.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To unite at an angle of 45°.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mitre"],
    },
  ],
  venal: [
    {
      word: "venal",
      phonetic: "/ˈviːnəl/",
      phonetics: [{ text: "/ˈviːnəl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Venous; pertaining to veins.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/venal"],
    },
    {
      word: "venal",
      phonetic: "/ˈviːnəl/",
      phonetics: [{ text: "/ˈviːnəl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "For sale; available for purchase.",
              synonyms: ["purchasable"],
              antonyms: [],
            },
            {
              definition:
                "Of a position, privilege etc.: available for purchase rather than assigned on merit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Capable of being bought (of a person); willing to take bribes.",
              synonyms: ["crooked"],
              antonyms: ["honest", "straight", "uncorrupt"],
            },
            {
              definition: "(of behaviour etc.) Corrupt, mercenary.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["crooked", "purchasable"],
          antonyms: ["honest", "straight", "uncorrupt"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/venal"],
    },
  ],
  knish: [
    {
      word: "knish",
      phonetic: "/knɪʃ/",
      phonetics: [{ text: "/knɪʃ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An Eastern European Jewish, or Yiddish, snack food consisting of a dumpling covered with a shell of baked or fried dough",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/knish"],
    },
  ],
  ritzy: [
    {
      word: "ritzy",
      phonetic: "/ˈɹɪ.t͡si/",
      phonetics: [{ text: "/ˈɹɪ.t͡si/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Elegant and luxurious.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ritzy"],
    },
  ],
  divas: [
    {
      word: "divas",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any female celebrity, usually a well known singer or actress.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person who may be considered or who considers herself (or by extension himself) much more important than others, has high expectations of others and who is extremely demanding and fussy when it comes to personal privileges.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["prima donna"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/diva",
        "https://en.wiktionary.org/wiki/divas",
      ],
    },
  ],
  torus: [
    {
      word: "torus",
      phonetic: "/ˈtɔːɹəs/",
      phonetics: [
        {
          text: "/ˈtɔːɹəs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/torus-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75858872",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/ˈtɔːɹəs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/torus-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1902409",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A topological space which is a product of two circles.",
              synonyms: [],
              antonyms: [],
              example:
                "A 4-variable Karnaugh map can be thought of, topologically, as being a torus.",
            },
            {
              definition:
                "The standard representation of such a space in 3-dimensional Euclidean space: a shape consisting of a ring with a circular cross-section: the shape of an inner tube or hollow doughnut.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in combination, n-torus, 4-torus, etc.) The product of the specified number of circles.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A molding which projects at the base of a column and above the plinth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The end of the peduncle or flower stalk to which the floral parts (or in the Asteraceae, the florets of a flower head) are attached; receptacle, thalamus.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A rounded ridge, especially one on the occipital bone.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/torus"],
    },
  ],
  mange: [
    {
      word: "mange",
      phonetic: "/meɪndʒ/",
      phonetics: [
        {
          text: "/meɪndʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mange-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=83944364",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A skin disease of mammals caused by parasitic mites (Sarcoptes spp., Demodecidae spp.).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mange"],
    },
  ],
  dimer: [
    {
      word: "dimer",
      phonetic: "/ˈdaɪmə(ɹ)/",
      phonetics: [{ text: "/ˈdaɪmə(ɹ)/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A molecule consisting of two identical halves, formed by joining two identical molecules, sometimes with a single atom acting as a bridge.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dimer"],
    },
  ],
  recut: [
    {
      word: "recut",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To cut again", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/recut"],
    },
  ],
  meson: [
    {
      word: "meson",
      phonetic: "/ˈmɛs.ɒn/",
      phonetics: [{ text: "/ˈmɛs.ɒn/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(rare outside entomology) The mesial plane dividing the body into similar right and left halves.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/meson"],
    },
    {
      word: "meson",
      phonetic: "/ˈmiː.sɒn/",
      phonetics: [{ text: "/ˈmiː.sɒn/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A member of a group of subatomic particles having a mass intermediate between electrons and protons. (The most easily detected mesons fit this definition.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(now specifically) An elementary particle that is composed of a quark and an antiquark, such as a kaon or pion. (Mesons composed of rarer quarks are much heavier.)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["mesotron"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/meson"],
    },
  ],
  wined: [
    {
      word: "wined",
      phonetic: "/waɪnd/",
      phonetics: [{ text: "/waɪnd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To entertain with wine.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To drink wine.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wine",
        "https://en.wiktionary.org/wiki/wined",
      ],
    },
  ],
  fends: [
    {
      word: "fends",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To take care of oneself; to take responsibility for one's own well-being.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                '(except as "fend for oneself") To defend, to take care of (typically construed with for); to block or push away (typically construed with off).',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fend",
        "https://en.wiktionary.org/wiki/fends",
      ],
    },
  ],
  phage: [
    {
      word: "phage",
      phonetic: "/feɪdʒ/",
      phonetics: [
        {
          text: "/feɪdʒ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/phage-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=30348077",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A virus that is parasitic on bacteria.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bacteriophage"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/phage"],
    },
  ],
  fiats: [
    {
      word: "fiats",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An arbitrary or authoritative command or order to do something; an effectual decree.",
              synonyms: [],
              antonyms: [],
              example: "A presidential fiat.",
            },
            {
              definition: "Authorization, permission or (official) sanction.",
              synonyms: [],
              antonyms: [],
              example: "A government fiat.",
            },
            {
              definition:
                "(English law) A warrant of a judge for certain processes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(English law) An authority for certain proceedings given by the Lord Chancellor's signature.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fiat",
        "https://en.wiktionary.org/wiki/fiats",
      ],
    },
  ],
  caulk: [
    {
      word: "caulk",
      phonetic: "/kɑk/",
      phonetics: [
        { text: "/kɑk/", audio: "" },
        {
          text: "/kælk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/caulk-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50048967",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A pointed projection on a horseshoe to prevent it slipping.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A spike on the sole of a boot to prevent slipping, particularly used in logging",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Caulking.", synonyms: [], antonyms: [] },
            {
              definition:
                "A composition of vehicle and pigment used at ambient temperatures for filling/sealing joints or junctures, that remains elastic for an extended period of time after application.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To drive oakum into the seams of a ship's wooden deck or hull to make it watertight.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To apply caulking to joints, cracks, or a juncture of different materials.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Fuck", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/calk",
        "https://en.wiktionary.org/wiki/caulk",
      ],
    },
  ],
  cavil: [
    {
      word: "cavil",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cavil-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75729333",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈkæv.əl/", audio: "" },
        { text: "/ˈkæv.əl/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A petty or trivial objection or criticism.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To criticise for petty or frivolous reasons.",
              synonyms: [
                "be hypercritical",
                "nitpick",
                "pettifog",
                "split hairs",
              ],
              antonyms: [],
            },
          ],
          synonyms: ["be hypercritical", "nitpick", "pettifog", "split hairs"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cavil"],
    },
  ],
  panty: [
    {
      word: "panty",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(in the plural) Short trousers for men, or more usually boys.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(usually in the plural, or in compounds) An article of clothing worn as underpants by women.",
              synonyms: [],
              antonyms: [],
              example: "pantyhose",
            },
            { definition: "A helmet cover.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/panty"],
    },
  ],
  roans: [
    {
      word: "roans",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An animal such as a horse that has a coat of a dark base color with individual white hairs mixed in.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The color of such an animal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A kind of leather used for slippers, bookbinding, etc., made from sheepskin, tanned with sumac and colored to imitate ungrained morocco.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Sorbus aucuparia, the European rowan.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various small deciduous trees or shrubs of genus Sorbus, belonging to the rose family, with pinnate leaves, corymbs of white flowers, and usually with orange-red berries.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/roan",
        "https://en.wiktionary.org/wiki/roans",
        "https://en.wiktionary.org/wiki/rowan",
      ],
    },
  ],
  bilks: [
    {
      word: "bilks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To spoil the score of (someone) in cribbage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To do someone out of their due; to deceive or defraud, to cheat (someone).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To evade, elude.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bilk",
        "https://en.wiktionary.org/wiki/bilks",
      ],
    },
  ],
  hones: [
    {
      word: "hones",
      phonetic: "/həʊnz/",
      phonetics: [
        { text: "/həʊnz/", audio: "" },
        { text: "/hoʊnz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sharpening stone composed of extra-fine grit used for removing the burr or curl from the blade of a razor or some other edge tool.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A machine tool used in the manufacture of precision bores.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To sharpen with a hone; to whet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To use a hone to produce a precision bore.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To refine or master (a skill).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make more acute, intense, or effective.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A kind of swelling in the cheek.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To grumble.", synonyms: [], antonyms: [] },
            {
              definition: "To pine, lament, or long.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hone",
        "https://en.wiktionary.org/wiki/hones",
      ],
    },
  ],
  botch: [
    {
      word: "botch",
      phonetic: "/bɒt͡ʃ/",
      phonetics: [
        { text: "/bɒt͡ʃ/", audio: "" },
        {
          text: "/bɑt͡ʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/botch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=402985",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An action, job, or task that has been performed very badly; a ruined, defective, or clumsy piece of work.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A patch put on, or a part of a garment patched or mended in a clumsy manner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A mistake that is very stupid or embarrassing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A messy, disorderly or confusing combination; conglomeration; hodgepodge.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One who makes a mess of something; a bungler.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To perform (a task) in an unacceptable or incompetent manner; to make a mess of something",
              synonyms: ["bungle", "destroy", "ruin", "spoil"],
              antonyms: [],
              example: "A botched haircut seems to take forever to grow out.",
            },
            {
              definition:
                "To do something without skill, without care, or clumsily.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To repair or mend clumsily.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bodge", "bungle", "destroy", "ruin", "spoil"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/botch"],
    },
    {
      word: "botch",
      phonetic: "/bɒt͡ʃ/",
      phonetics: [
        { text: "/bɒt͡ʃ/", audio: "" },
        {
          text: "/bɑt͡ʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/botch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=402985",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A tumour or other malignant swelling.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A case or outbreak of boils or sores.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/botch"],
    },
  ],
  estop: [
    {
      word: "estop",
      phonetic: "/ɪˈstɒp/",
      phonetics: [{ text: "/ɪˈstɒp/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To impede or bar by estoppel.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To stop up, to plug", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/estop"],
    },
  ],
  sully: [
    {
      word: "sully",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sully-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75858494",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈsʌli/", audio: "" },
        {
          text: "/ˈsʌli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sully-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=179715",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A blemish.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To soil or stain; to dirty.",
              synonyms: ["sowl"],
              antonyms: [],
              example: "He did not wish to sully his hands with gardening.",
            },
            {
              definition: "To corrupt or damage.",
              synonyms: [],
              antonyms: [],
              example:
                "She tried to sully her rival’s reputation with a suggestive comment.",
            },
            {
              definition: "(intransitive ) To become soiled or tarnished.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["sowl"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sully"],
    },
  ],
  sooth: [
    {
      word: "sooth",
      phonetic: "/suːθ/",
      phonetics: [{ text: "/suːθ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Truth.", synonyms: [], antonyms: [] },
            {
              definition: "Augury; prognostication.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Blandishment; cajolery.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Reality; fact.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "True.", synonyms: [], antonyms: [] },
            {
              definition: "Pleasing; delightful; sweet.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            { definition: "In truth; indeed.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sooth"],
    },
  ],
  gelds: [
    {
      word: "gelds",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "Money.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A female animal, such as a ewe or cow, that is not pregnant.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To castrate a male (usually an animal).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To deprive of anything essential; to weaken.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/geld",
        "https://en.wiktionary.org/wiki/gelds",
      ],
    },
  ],
  ahold: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  raper: [
    {
      word: "raper",
      phonetic: "/ˈɹeɪpə(ɹ)/",
      phonetics: [{ text: "/ˈɹeɪpə(ɹ)/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A person who has raped someone; a rapist.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/raper"],
    },
  ],
  pager: [
    {
      word: "pager",
      phonetic: "/peɪdʒə(ɹ)/",
      phonetics: [
        {
          text: "/peɪdʒə(ɹ)/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pager-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89186186",
        },
        {
          text: "/peɪdʒə(ɹ)/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pager-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89186187",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A wireless telecommunications device that receives text or voice messages.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A computer program running in a text terminal, used to view (but not modify) the contents of a text file moving down the file one line or one screen at a time.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in combination) Something (a document, book etc.) that has a specified number of pages.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["beeper"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pager"],
    },
  ],
  fixer: [
    {
      word: "fixer",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Agent noun of fix; one who, or that which, fixes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A chemical (sodium thiosulfate) used in photographic development that fixes the image in place, preventing further chemical reactions.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(criminal justice) A person who arranges immunity for defendants by tampering with the justice system via bribery or extortion, especially as a business endeavor for profit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person who assists foreign journalists in volatile countries, often providing interpretation, personal connections, and transportation services.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A fixer-upper.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fixer"],
    },
  ],
  infix: [
    {
      word: "infix",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A morpheme inserted inside an existing word, such as -bloody- in English.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A morpheme that always appears between other morphemes in a word, such as -i- and -o- in English.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To set; to fasten or fix by piercing or thrusting in.",
              synonyms: [],
              antonyms: [],
              example: "to infix a sting, spear, or dart",
            },
            { definition: "To instill.", synonyms: [], antonyms: [] },
            {
              definition: "To insert a morpheme inside an existing word.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/infix"],
    },
  ],
  hicks: [
    {
      word: "hicks",
      phonetic: "/hɪks/",
      phonetics: [{ text: "/hɪks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An awkward, naive, clumsy and/or rude country person.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "boer",
            "boor",
            "churl",
            "country bumpkin",
            "hillbilly",
            "lob",
            "redneck",
            "rustic",
            "yokel",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To hiccup", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hick",
        "https://en.wiktionary.org/wiki/hicks",
      ],
    },
  ],
  tuxes: [
    {
      word: "tuxes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A tuxedo.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tux",
        "https://en.wiktionary.org/wiki/tuxes",
      ],
    },
  ],
  plebe: [
    {
      word: "plebe",
      phonetic: "/pliːb/",
      phonetics: [
        { text: "/pliːb/", audio: "" },
        { text: "/plib/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(usually in the plural) A plebeian, a member of the lower class of Roman citizens.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The plebs, the plebeian class.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The similar lower class of any area.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A freshman cadet at a military academy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/plebe"],
    },
  ],
  twits: [
    {
      word: "twits",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A reproach, gibe or taunt.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A foolish or annoying person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'A euphemism for "twat", a contemptible or stupid person.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person who twitters, i.e. chatters inanely (see usage notes).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To reproach, blame; to ridicule or tease.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To ignore or killfile (a user on a bulletin board system).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/twit",
        "https://en.wiktionary.org/wiki/twits",
      ],
    },
  ],
  abash: [
    {
      word: "abash",
      phonetic: "/əˈbæʃ/",
      phonetics: [
        { text: "/əˈbæʃ/", audio: "" },
        {
          text: "/əˈbæʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/abash-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1755010",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make ashamed; to embarrass; to destroy the self-possession of, as by exciting suddenly a consciousness of guilt, mistake, or inferiority; to disconcert; to discomfit.",
              synonyms: [
                "bewilder",
                "confound",
                "confuse",
                "daunt",
                "discompose",
                "disconcert",
                "discountenance",
                "dishearten",
                "embarrass",
                "faze",
                "fluster",
                "humble",
                "humiliate",
                "mortify",
                "rattle",
                "shake",
                "shame",
                "snub",
              ],
              antonyms: [
                "abet",
                "animate",
                "buoy",
                "cheer",
                "countenance",
                "embolden",
                "encourage",
                "incite",
                "inspirit",
                "rally",
                "reassure",
                "uphold",
              ],
            },
            {
              definition: "To lose self-possession; to become ashamed.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "bewilder",
            "confound",
            "confuse",
            "daunt",
            "discompose",
            "disconcert",
            "discountenance",
            "dishearten",
            "embarrass",
            "faze",
            "fluster",
            "humble",
            "humiliate",
            "mortify",
            "rattle",
            "shake",
            "shame",
            "snub",
          ],
          antonyms: [
            "abet",
            "animate",
            "buoy",
            "cheer",
            "countenance",
            "embolden",
            "encourage",
            "incite",
            "inspirit",
            "rally",
            "reassure",
            "uphold",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/abash"],
    },
  ],
  twixt: [
    {
      word: "twixt",
      phonetic: "/twɪkst/",
      phonetics: [
        {
          text: "/twɪkst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/twixt-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89322285",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "preposition",
          definitions: [
            { definition: "Betwixt, between", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/twixt"],
    },
  ],
  wacko: [
    {
      word: "wacko",
      phonetic: "/ˈwækəʊ/",
      phonetics: [
        {
          text: "/ˈwækəʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wacko.oga",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=26359733",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An amusingly eccentric or irrational person.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["kook", "odd duck", "weirdo"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Amusingly eccentric or irrational.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["kooky", "oddballish", "pixilated"],
          antonyms: [],
        },
        {
          partOfSpeech: "interjection",
          definitions: [{ definition: "Hurrah!", synonyms: [], antonyms: [] }],
          synonyms: ["get in", "huzzah", "woo hoo"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/wacko"],
    },
  ],
  primp: [
    {
      word: "primp",
      phonetic: "/pɹɪmp/",
      phonetics: [{ text: "/pɹɪmp/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To spend time improving one's appearance, often in front of a mirror.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To dress in an affected manner.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/primp"],
    },
  ],
  nabla: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  girts: [
    {
      word: "girts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A horizontal structural member of post and beam architecture, typically attached to bridge two or more vertical members such as corner posts.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To gird.", synonyms: [], antonyms: [] },
            {
              definition: "To bind horizontally, as with a belt or girdle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To measure the girth of.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To capsize because of forces in the cable attaching it to another vessel.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/girt",
        "https://en.wiktionary.org/wiki/girts",
      ],
    },
  ],
  miffs: [
    {
      word: "miffs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A small argument; a quarrel.",
              synonyms: ["tiff"],
              antonyms: [],
            },
            {
              definition: "A state of being offended.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["tiff"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(usually used in the passive) To offend slightly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To become slightly offended.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/miff",
        "https://en.wiktionary.org/wiki/miffs",
      ],
    },
  ],
  emote: [
    {
      word: "emote",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/emote-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80535863",
        },
        { text: "/əˈmoʊt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A virtual action, presented to other users as reported speech, rather than a direct message.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(Twitch-speak) Short for emoticon.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To display emotions openly, especially while acting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To induce an emotion in.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To perform a virtual action, presented to other users as reported speech, rather than sending a direct message.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/emote"],
    },
  ],
  xerox: [
    {
      word: "xerox",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/xerox-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79218948",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈzɛ.ɹɒks/", audio: "" },
        { text: "/ˈziː(ə)ɹ.ɑks/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A photocopy.",
              synonyms: [],
              antonyms: [],
              example: "Hand me that xerox, would you?",
            },
            {
              definition: "A photocopier.",
              synonyms: [],
              antonyms: [],
              example: "The xerox broke down yesterday.",
            },
          ],
          synonyms: ["photocopy"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make a paper copy or copies by means of a photocopier.",
              synonyms: [],
              antonyms: [],
              example:
                "I xeroxed the report for all the people at the meeting.",
            },
          ],
          synonyms: ["copy", "photocopy"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/xerox"],
    },
  ],
  rebid: [
    {
      word: "rebid",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A second or subsequent (normally higher) bid.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To bid again on something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To require a new set of bids for.",
              synonyms: [],
              antonyms: [],
              example:
                "We had to rebid the contract when accusations of fraud by other competitors occurred.",
            },
            {
              definition: "To bid a higher value of the current suit.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rebid"],
    },
  ],
  shahs: [
    {
      word: "shahs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A king of Persia or Iran.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A supreme ruler in some Middle Eastern or South Asian nations.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A Ukrainian monetary unit.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/shah",
        "https://en.wiktionary.org/wiki/shahs",
      ],
    },
  ],
  rutty: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  grout: [
    {
      word: "grout",
      phonetic: "/ɡɹʌut/",
      phonetics: [
        { text: "/ɡɹʌut/", audio: "" },
        { text: "/ɡɹaʊt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A thin mortar used to fill the gaps between tiles and cavities in masonry.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Coarse meal; groats.", synonyms: [], antonyms: [] },
            {
              definition: "(typically used in the plural) Dregs, sediment.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A kind of beer or ale.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To insert mortar between tiles.",
              synonyms: [],
              antonyms: [],
              example:
                "I spent the whole afternoon grouting the kitchen floor.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/grout"],
    },
  ],
  grift: [
    {
      word: "grift",
      phonetic: "/ɡɹɪft/",
      phonetics: [
        {
          text: "/ɡɹɪft/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grift-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79268486",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A confidence game or swindle.",
              synonyms: [],
              antonyms: [],
              example: "Hey, what's the grift? What are you trying to pull?",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To obtain illegally, as by con game.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To obtain money illegally.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To obtain money immorally or through deceitful means.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/grift"],
    },
  ],
  deify: [
    {
      word: "deify",
      phonetic: "/ˈdeɪ.ɪ.faɪ/",
      phonetics: [
        { text: "/ˈdeɪ.ɪ.faɪ/", audio: "" },
        {
          text: "/ˈdeɪ.ə.faɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/deify-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2526677",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make a god of (something or someone).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To treat as worthy of worship; to regard as a deity.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["apotheosize", "exalt", "idealize"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/deify"],
    },
  ],
  biddy: [
    {
      word: "biddy",
      phonetic: "/ˈbɪdi/",
      phonetics: [{ text: "/ˈbɪdi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A woman, especially an old woman; especially one regarded as fussy or mean or a gossipy busybody.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An attractive girl.", synonyms: [], antonyms: [] },
            { definition: "An Irish maidservant.", synonyms: [], antonyms: [] },
            {
              definition: "(by extension) An Irishwoman.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'A name used in calling a hen or chicken, often as "biddy-biddy-biddy".',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/biddy"],
    },
    {
      word: "biddy",
      phonetic: "/ˈbɪdi/",
      phonetics: [{ text: "/ˈbɪdi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(often in the plural) bitch, girl, woman, especially one that is promiscuous",
              synonyms: [],
              antonyms: [],
              example:
                "He's got it made, hanging around with the bitties all the time.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/biddy",
        "https://en.wiktionary.org/wiki/bittie",
        "https://en.wiktionary.org/wiki/bitty",
      ],
    },
  ],
  kopek: [
    {
      word: "kopek",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A Russian monetary unit equal to one hundredth of a ruble.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A kopiyka: a Ukrainian monetary unit equal to one hundredth of a hryvnia.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/kopek"],
    },
  ],
  semis: [
    {
      word: "semis",
      phonetic: "/ˈsɛmiːz/",
      phonetics: [
        {
          text: "/ˈsɛmiːz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/semis-1-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88896690",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A semi-detached house.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A semitrailer; a tractor-trailer; an eighteen-wheeler; an artic.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A semifinal.", synonyms: [], antonyms: [] },
            { definition: "A partial erection.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/semi",
        "https://en.wiktionary.org/wiki/semis",
      ],
    },
    {
      word: "semis",
      phonetic: "/ˈsɛmɪs/",
      phonetics: [
        {
          text: "/ˈsɛmɪs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/semis-2-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88896691",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small bronze coin minted during the Roman Republic, valued at half an as.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/semis"],
    },
  ],
  bries: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  acmes: [
    {
      word: "acmes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The top or highest point; pinnacle; culmination.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The crisis or height of a disease.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Mature age; full bloom of life.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/acme",
        "https://en.wiktionary.org/wiki/acmes",
      ],
    },
  ],
  piton: [
    {
      word: "piton",
      phonetic: "/ˈpiːˌtɒn/",
      phonetics: [{ text: "/ˈpiːˌtɒn/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A spike, wedge, or peg that is driven into a rock or ice surface as a support (as for a mountain climber).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To put pitons into a rock/ice to facilitate climbing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/piton"],
    },
  ],
  hussy: [
    {
      word: "hussy",
      phonetic: "/ˈhʌsi/",
      phonetics: [{ text: "/ˈhʌsi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A housewife or housekeeper.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sexually immoral woman.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cheeky or disrespectful girl; a woman showing inappropriate or improper behavior.",
              synonyms: ["minx"],
              antonyms: [],
            },
            {
              definition: "A case or bag for needles, thread, etc.",
              synonyms: ["housewife"],
              antonyms: [],
            },
          ],
          synonyms: ["housewife", "minx"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hussy"],
    },
  ],
  torts: [
    {
      word: "torts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "An injury or wrong.", synonyms: [], antonyms: [] },
            {
              definition:
                "A wrongful act, whether intentional or negligent, which causes an injury and can be remedied in civil court, usually through the awarding of damages.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(only in the plural torts) Tort law (the area of law dealing with such wrongful acts).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["delict"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A tortoise.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A tortoiseshell (animal with coloured markings on fur).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tort",
        "https://en.wiktionary.org/wiki/torts",
      ],
    },
  ],
  disco: [
    {
      word: "disco",
      phonetic: "/ˈdɪskəʊ/",
      phonetics: [
        { text: "/ˈdɪskəʊ/", audio: "" },
        { text: "/ˈdɪskoʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A genre of dance music that was popular in the 1970s, characterized by elements of soul music with a strong Latin-American beat and often accompanied by pulsating lights.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["club", "nightclub"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To dance disco-style dances.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To go to discotheques.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(slightly obsolete) A nightclub where dancing takes place.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["disco"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/disco",
        "https://en.wiktionary.org/wiki/discotheque",
      ],
    },
  ],
  whore: [
    {
      word: "whore",
      phonetic: "/hɔː/",
      phonetics: [
        { text: "/hɔː/", audio: "" },
        { text: "/hɔɹ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any person (especially a woman) who has sexual intercourse or engages in other sexual activity for payment, especially as a means of livelihood.",
              synonyms: ["sex worker"],
              antonyms: [],
            },
            {
              definition:
                "A person who does, or offers to do, a demeaning or dishonourable activity for money or personal gain; someone who acts in a dishonourable way for personal advantage.",
              synonyms: ["sellout"],
              antonyms: [],
            },
          ],
          synonyms: ["whore", "sellout", "sex worker"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A person who is sexually promiscuous; a slut.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person who is unscrupulous, especially one who compromises their principles for gain.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person who will violate behavioral standards to achieve something desired.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A contemptible person.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To prostitute oneself.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To engage the services of a prostitute.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To pimp; to pander.", synonyms: [], antonyms: [] },
            {
              definition: "To promote shamelessly.",
              synonyms: [],
              antonyms: [],
              example:
                "Did you see him on that chat show, whoring his new book?",
            },
            { definition: "To pursue false gods.", synonyms: [], antonyms: [] },
            {
              definition: "To pursue false goals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To overuse something.",
              synonyms: [],
              antonyms: [],
              example:
                "BTW, that guy whuz still an asshole - camping the BFG and whoring the quad - I usually leave BFG maps but stuck around on that one and suicided quite a bit (3 times I got to about -10 frags, then came back to 0...).",
            },
          ],
          synonyms: [
            "pander",
            "turn out",
            "pimp",
            "tout",
            "sell one's body",
            "turn tricks",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/prostitute",
        "https://en.wiktionary.org/wiki/whore",
      ],
    },
  ],
  boozy: [
    {
      word: "boozy",
      phonetic: "/ˈbuːzi/",
      phonetics: [{ text: "/ˈbuːzi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "(of a person) Intoxicated by alcohol.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a person) Inclined to consume a significant amount of alcohol.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of an event) Involving a large consumption of alcohol.",
              synonyms: [],
              antonyms: [],
              example: "We all had hangovers after a boozy weekend in town.",
            },
            {
              definition: "(of food) Containing or cooked with alcohol.",
              synonyms: [],
              antonyms: [],
              example:
                "For dessert, the hosts treated us to a helping of boozy apple pie.",
            },
          ],
          synonyms: ["drunk", "sottish"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/boozy"],
    },
  ],
  gibed: [
    {
      word: "gibed",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "Alternative spelling of gybe",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Alternative spelling of jibe",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gibe",
        "https://en.wiktionary.org/wiki/gibed",
      ],
    },
  ],
  vamps: [
    {
      word: "vamps",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The top part of a boot or shoe, above the sole and welt and in front of the ankle seam, that covers the instep and toes; the front part of an upper; the analogous part of a stocking.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something added to give an old thing a new appearance.",
              synonyms: ["patch"],
              antonyms: [],
            },
            {
              definition:
                "Something patched up, pieced together, improvised, or refurbished.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A repeated and often improvised accompaniment, usually consisting of one or two measures, often a single chord or simple chord progression, repeated as necessary, for example, to accommodate dialogue or to anticipate the entrance of a soloist.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) An activity or speech intended to fill or stall for time.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["patch"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To patch, repair, or refurbish.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Often as vamp up: to fabricate or put together (something) from existing material, or by adding new material to something existing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cobble together, to extemporize, to improvise.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(shoemaking) To attach a vamp (to footwear).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To travel by foot; to walk.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To delay or stall for time, as for an audience.",
              synonyms: [],
              antonyms: [],
              example: "Keep vamping! Something’s wrong with the mic!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A flirtatious, seductive woman, especially one who exploits men by using their sexual desire for her.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A vampire.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To seduce or exploit someone.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A volunteer firefighter.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/vamp",
        "https://en.wiktionary.org/wiki/vamps",
      ],
    },
  ],
  amour: [
    {
      word: "amour",
      phonetic: "/æˈmʊər/",
      phonetics: [
        { text: "/æˈmʊər/", audio: "" },
        { text: "/ɑːˈmʊr/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Love, affection.", synonyms: [], antonyms: [] },
            {
              definition: "Courtship; flirtation.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A love affair.", synonyms: [], antonyms: [] },
            { definition: "A lover.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/amour"],
    },
  ],
  soppy: [
    {
      word: "soppy",
      phonetic: "/ˈsɒpi/",
      phonetics: [{ text: "/ˈsɒpi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Very wet; sodden, soaked.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Sentimental, maudlin, schmaltzy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/soppy"],
    },
  ],
  gonzo: [
    {
      word: "gonzo",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Gonzo journalism or a journalist who produces such journalism.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A wild or crazy person.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Using an unconventional, exaggerated and highly subjective style, often when the reporter takes part in the events of the story.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Unconventional, bizarre, crazy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gonzo"],
    },
  ],
  durst: [
    {
      word: "durst",
      phonetic: "/dɝst/",
      phonetics: [{ text: "/dɝst/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To have enough courage (to do something).",
              synonyms: [],
              antonyms: [],
              example: "I wouldn't dare argue with my boss.",
            },
            {
              definition: "To defy or challenge (someone to do something)",
              synonyms: [],
              antonyms: [],
              example: "I dare you (to) kiss that girl.",
            },
            {
              definition:
                "To have enough courage to meet or do something, go somewhere, etc.; to face up to",
              synonyms: [],
              antonyms: [],
              example: "Will you dare death to reach your goal?",
            },
            { definition: "To terrify; to daunt.", synonyms: [], antonyms: [] },
            {
              definition:
                "To catch (larks) by producing terror through the use of mirrors, scarlet cloth, a hawk, etc., so that they lie still till a net is thrown over them.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dare",
        "https://en.wiktionary.org/wiki/durst",
      ],
    },
  ],
  wader: [
    {
      word: "wader",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "One who wades.", synonyms: [], antonyms: [] },
            {
              definition:
                "(chiefly in the plural) A waterproof boot that comes up to the hip, used by fishermen, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A long-legged bird associated with wetland or coastal environments.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["shorebird", "wading bird"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/wader"],
    },
  ],
  tutus: [
    {
      word: "tutus",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A ballet skirt made of layered stiff but light netting.",
              synonyms: [],
              antonyms: [],
              example:
                "The ballet dancer representing the swan wore a white tutu.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of the genus Coriaria of shrubs and trees found in New Zealand.",
              synonyms: ["toot plant", "tupakihi"],
              antonyms: [],
            },
          ],
          synonyms: ["toot plant", "tupakihi"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tutu",
        "https://en.wiktionary.org/wiki/tutus",
      ],
    },
  ],
  perms: [
    {
      word: "perms",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A permanent.", synonyms: [], antonyms: [] },
            { definition: "A permanent wave.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To give hair a perm, using heat, chemicals etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A permutation.", synonyms: [], antonyms: [] },
            {
              definition:
                "A combination of outcomes (not a permutation) that a gambler bets on in the football pools.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/perm",
        "https://en.wiktionary.org/wiki/perms",
      ],
    },
  ],
  catty: [
    {
      word: "catty",
      phonetic: "/ˈkæti/",
      phonetics: [{ text: "/ˈkæti/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(of a person or remark) With subtle hostility in an effort to hurt, annoy or upset, particularly among women.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Resembling or characteristic of a cat.",
              synonyms: [],
              antonyms: [],
              example: "a catty smell",
            },
          ],
          synonyms: [
            "bitchy",
            "cattish",
            "malicious",
            "nasty",
            "snide",
            "spiteful",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/catty"],
    },
    {
      word: "catty",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A (unit of) weight used in China, generally standardized as half a kilogram.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/catty"],
    },
  ],
  glitz: [
    {
      word: "glitz",
      phonetic: "/ɡlɪts/",
      phonetics: [
        {
          text: "/ɡlɪts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/glitz-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1879452",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Garish, brilliant showiness.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/glitz"],
    },
  ],
  brigs: [
    {
      word: "brigs",
      phonetic: "/bɹɪɡz/",
      phonetics: [{ text: "/bɹɪɡz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A two-masted vessel, square-rigged on both foremast and mainmast",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A jail or guardhouse, especially in a naval military prison or jail on a ship, navy base, or (in fiction) spacecraft.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "Bridge.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Brigadier.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/brig",
        "https://en.wiktionary.org/wiki/brigs",
      ],
    },
  ],
  nerds: [
    {
      word: "nerds",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(sometimes derogatory) A person who is intellectual but generally introverted",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(sometimes derogatory) One who has an intense, obsessive interest in something.",
              synonyms: ["geek", "otaku"],
              antonyms: [],
              example: "a comic-book nerd",
            },
            {
              definition:
                "An unattractive, socially awkward, annoying, undesirable, and/or boring, person; a dork.",
              synonyms: [
                "dag (Australian)",
                "doofus",
                "dork",
                "dweeb",
                "geek",
                "goober",
                "loser",
                "propeller head",
                "twerp",
              ],
              antonyms: [],
              example:
                "Nerds seem to have fun with each other, but in a way that causes others to laugh at them.",
            },
            {
              definition:
                "(post-1980s) A member of a subculture revolving around video games, fantasy and science fiction, comic books and assorted media.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "dag (Australian)",
            "doofus",
            "dork",
            "dweeb",
            "geek",
            "goober",
            "loser",
            "propeller head",
            "twerp",
            "geek",
            "otaku",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/nerd",
        "https://en.wiktionary.org/wiki/nerds",
      ],
    },
  ],
  barmy: [
    {
      word: "barmy",
      phonetic: "/ˈbɑː.mi/",
      phonetics: [
        {
          text: "/ˈbɑː.mi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/barmy-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88676885",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Odd, strange, or crazy.",
              synonyms: ["balmy", "dotty", "goofy", "wacko"],
              antonyms: [],
            },
          ],
          synonyms: ["balmy", "dotty", "goofy", "wacko"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/barmy"],
    },
    {
      word: "barmy",
      phonetic: "/ˈbɑː.mi/",
      phonetics: [
        {
          text: "/ˈbɑː.mi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/barmy-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88676885",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Containing barm, i.e. froth from fermented yeast.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/barmy"],
    },
  ],
  gizmo: [
    {
      word: "gizmo",
      phonetic: "/ˈɡɪzmoʊ/",
      phonetics: [
        {
          text: "/ˈɡɪzmoʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gizmo-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=49895880",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Something, generally a device, for which one does not know the proper term.",
              synonyms: ["gadget", "thingamajig", "whatchamacallit"],
              antonyms: [],
            },
          ],
          synonyms: ["gadget", "thingamajig", "whatchamacallit"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gizmo"],
    },
  ],
  owlet: [
    {
      word: "owlet",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of various birds of prey of the order Strigiformes that are primarily nocturnal and have forward-looking, binocular vision, limited eye movement, and good hearing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) A person seen as having owl-like characteristics, especially appearing wise or serious, or being nocturnally active.",
              synonyms: [],
              antonyms: ["lark"],
            },
            { definition: "The owl pigeon.", synonyms: [], antonyms: [] },
            {
              definition:
                "A politician with moderate views that are neither hawkish nor dovish.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various nymphalid butterflies having large eyespots on the wings.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["lark"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A young owl; owling.", synonyms: [], antonyms: [] },
            {
              definition:
                "One of a species of small owls, such as Athene noctua.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An owlet moth.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["houlet", "howlet", "owling"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/owl",
        "https://en.wiktionary.org/wiki/owlet",
      ],
    },
  ],
  sayer: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  molls: [
    {
      word: "molls",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A female companion of a gangster, especially a former or current prostitute.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A prostitute or woman with loose sexual morals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Bitch, slut; an insulting epithet applied to a female.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A girlfriend of a bikie.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A girlfriend of a surfie; blends with pejorative sense.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A female fan of extreme metal, grunge or hardcore punk, especially the girlfriend of a musician of those aforementioned genres.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["chick"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/moll",
        "https://en.wiktionary.org/wiki/molls",
      ],
    },
  ],
  shard: [
    {
      word: "shard",
      phonetic: "/ʃaːd/",
      phonetics: [
        { text: "/ʃaːd/", audio: "" },
        { text: "/ʃɑːd/", audio: "" },
        {
          text: "/ʃɑɹd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shard-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=49883310",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A piece of broken glass or pottery, especially one found in an archaeological dig.",
              synonyms: ["potsherd"],
              antonyms: [],
            },
            {
              definition:
                "(by extension) A piece of material, especially rock and similar materials, reminding of a broken piece of glass or pottery.",
              synonyms: ["splinter"],
              antonyms: [],
            },
            {
              definition:
                "A tough scale, sheath, or shell; especially an elytron of a beetle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An instance of an MMORPG that is one of several independent and structurally identical virtual worlds, none of which has so many players as to exhaust a system's resources.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A component of a sharded distributed database.",
              synonyms: ["partition"],
              antonyms: [],
            },
            {
              definition:
                "(singular or plural) A piece of crystal methamphetamine.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["partition", "potsherd", "splinter"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To fall apart into shards, usually as the result of impact or explosion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To break (something) into shards.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To divide (an MMORPG) into several shards, or to establish a shard of one.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shard"],
    },
    {
      word: "shard",
      phonetic: "/ʃaːd/",
      phonetics: [
        { text: "/ʃaːd/", audio: "" },
        { text: "/ʃɑːd/", audio: "" },
        {
          text: "/ʃɑɹd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shard-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=49883310",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The plant chard.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shard"],
    },
  ],
  whops: [
    {
      word: "whops",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A blow or strike.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To throw or move (something) quickly, usually with an impact.",
              synonyms: [],
              antonyms: [],
              example: "He angrily whopped the book onto the table.",
            },
            {
              definition: "To administer corporal punishment",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/whop",
        "https://en.wiktionary.org/wiki/whops",
      ],
    },
  ],
  comps: [
    {
      word: "comps",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Clipping of comprehensive layout, a graphic design showing final proposed layout of text and images.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Clipping of comprehensive examination.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Clipping of complimentary ticket or item.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(in bibliographies) Abbreviation of compiled.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Something suitable for comparison.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(grammar) A construction showing a relative quality, in English usually formed by adding more or appending -er. For example, the comparative of green is greener; of evil, more evil.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(grammar) A word in the comparative form.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(chiefly in the plural) Data used to make a comparison.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An equal; a rival; a compeer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One who makes comparisons; one who affects wit.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["comparative degree"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Comparison.", synonyms: [], antonyms: [] },
            {
              definition: "An instruction or command that compares two values.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Illustration by comparison; simile.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act or principle of compensating.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something which is regarded as an equivalent; something which compensates for loss.",
              synonyms: ["amends", "recompense", "remuneration"],
              antonyms: [],
            },
            {
              definition:
                "The extinction of debts of which two persons are reciprocally debtors by the credits of which they are reciprocally creditors; the payment of a debt by a credit of equal amount.",
              synonyms: ["set-off"],
              antonyms: [],
            },
            {
              definition: "A recompense or reward for service.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An equivalent stipulated for in contracts for the sale of real estate, in which it is customary to provide that errors in description, etc., shall not avoid, but shall be the subject of compensation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The relationship between air temperature outside a building and a calculated target temperature for provision of air or water to contained rooms or spaces for the purpose of efficient heating. In building control systems the compensation curve is defined to a compensator for this purpose.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The ability of one part of the brain to overfunction in order to take over the function of a damaged part (e.g. following a stroke).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "restitution",
            "restitution",
            "amends",
            "recompense",
            "remuneration",
            "set-off",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The action of competing.",
              synonyms: [],
              antonyms: [],
              example: "The competition for this job is strong.",
            },
            {
              definition: "A contest for a prize or award.",
              synonyms: [],
              antonyms: [],
              example: "The newspaper is featuring a competition to win a car.",
            },
            {
              definition: "The competitors in such a contest.",
              synonyms: [],
              antonyms: [],
              example:
                "The new stain remover was ten times more effective than the competition.",
            },
          ],
          synonyms: [],
          antonyms: ["cooperation"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The act or process of compiling or gathering together from various sources.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That which is compiled; especially, a book or document composed of materials gathering from other books or documents.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Translation of source code into object code by a compiler.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["anthology", "collection", "compilement"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One who compiles.",
              synonyms: [],
              antonyms: [],
              example: "a compiler of poetry anthologies",
            },
            {
              definition:
                "A computer program which transforms source code into object code.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A mixture of different components.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A structural material that gains its strength from a combination of complementary materials.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A plant belonging to the family Asteraceae, syn. Compositae.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A function of a function.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(chiefly law enforcement) A drawing, photograph, etc. that combines several separate pictures or images.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A (nonzero) natural number that is expressible as the product of two (or more) natural numbers other than itself and 1.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["prime number"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act of putting together; assembly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A mixture or compound; the result of composing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The proportion of different parts to make a whole.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The general makeup of a thing or person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An agreement or treaty used to settle differences; later especially, an agreement to stop hostilities; a truce.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A payment of money in order to clear a liability or obligation; a settling or fine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An agreement or compromise by which a creditor or group of creditors accepts partial payment from a debtor.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An essay.", synonyms: [], antonyms: [] },
            {
              definition:
                "The formation of compound words from separate words.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A work of music, literature or art.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Typesetting.", synonyms: [], antonyms: [] },
            {
              definition: "Applying a function to the result of another.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The compounding of two velocities or forces into a single equivalent velocity or force.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Consistency; accord; congruity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Synthesis as opposed to analysis.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The arrangement and flow of elements in a picture.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Way to combine simple objects or data types into more complex ones.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["configuration", "constitution", "blend", "melange"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A person who sets type; a typesetter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "One who, or that which, composes or sets in order.",
              synonyms: [],
              antonyms: [],
              example: "I work as an image compositor.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A normal secondary school, accepting pupils of all abilities; replaced the secondary modern schools and grammar schools",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The chief accountant of a company or government.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person employed to perform computations; one who computes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by restriction) A male computer, where the female computer is called a computress.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A programmable electronic device that performs mathematical calculations and logical operations, especially one that can process, store and retrieve large amounts of data very quickly; now especially, a small one for personal or home use employed for manipulating text or graphics, accessing the Internet, or playing games or media.",
              synonyms: ["'puter", "box", "calculator", "machine", "processor"],
              antonyms: [],
            },
          ],
          synonyms: ["'puter", "box", "calculator", "machine", "processor"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The study of computers and their architecture, languages, and applications, in all aspects, as well as the mathematical structures that relate to computers and computation.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["CS", "comp sci", "computing", "datalogy"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/comp",
        "https://en.wiktionary.org/wiki/comp.",
        "https://en.wiktionary.org/wiki/comparable",
        "https://en.wiktionary.org/wiki/comparative",
        "https://en.wiktionary.org/wiki/compare",
        "https://en.wiktionary.org/wiki/compensation",
        "https://en.wiktionary.org/wiki/competition",
        "https://en.wiktionary.org/wiki/compilation",
        "https://en.wiktionary.org/wiki/compiler",
        "https://en.wiktionary.org/wiki/composite",
        "https://en.wiktionary.org/wiki/composite%20number",
        "https://en.wiktionary.org/wiki/composition",
        "https://en.wiktionary.org/wiki/compositor",
        "https://en.wiktionary.org/wiki/comprehensive%20school",
        "https://en.wiktionary.org/wiki/comps",
        "https://en.wiktionary.org/wiki/comptroller",
        "https://en.wiktionary.org/wiki/computer",
        "https://en.wiktionary.org/wiki/computer%20science",
      ],
    },
  ],
  corer: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  colas: [
    {
      word: "colas",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The kola plant, genus Cola, famous for its nut, or one of these nuts.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A beverage or a drink made with kola nut flavoring, caramel and carbonated water.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A cluster of buds on a cannabis plant.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cola",
        "https://en.wiktionary.org/wiki/colas",
      ],
    },
  ],
  matte: [
    {
      word: "matte",
      phonetic: "/mæt/",
      phonetics: [{ text: "/mæt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A decorative border around a picture used to inset and center the contents of a frame.",
              synonyms: ["mat", "matting", "passe-partout"],
              antonyms: [],
              example:
                "The image is a perfect square of 8 cm (with white matte border the total dimensions are 14 cm tall by 11 cm wide).",
            },
            {
              definition:
                "A background, often painted or created with computers",
              synonyms: [],
              antonyms: [],
              example:
                "Matte painting is a tool that filmmakers can use to create a scene that is impossible to achieve with conventional cinematographic means.",
            },
            {
              definition:
                "(pyrometallurgy) The molten metal sulfide phases typically formed during smelting of copper, nickel, and other base metals.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["mat", "matting", "passe-partout"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Dull; not reflective of light.",
              synonyms: ["matt"],
              antonyms: ["glossy"],
              example:
                "Flat or matte paint allows a deep color expression on the walls while also hiding flaws that may be inherent on the painted surface.",
            },
          ],
          synonyms: ["matt"],
          antonyms: ["glossy"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/matte"],
    },
  ],
  droid: [
    {
      word: "droid",
      phonetic: "/ˈdɹɔɪd/",
      phonetics: [{ text: "/ˈdɹɔɪd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A robot, especially one made with some physical resemblance to a human.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/droid"],
    },
  ],
  ploys: [
    {
      word: "ploys",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A tactic, strategy, or gimmick.",
              synonyms: [],
              antonyms: [],
              example:
                "The free T-shirt is really a ploy to get you inside to see their sales pitch.",
            },
            { definition: "Sport; frolic.", synonyms: [], antonyms: [] },
            { definition: "Employment.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ploy",
        "https://en.wiktionary.org/wiki/ploys",
      ],
    },
  ],
  vapid: [
    {
      word: "vapid",
      phonetic: "/ˈveɪp.ɪd/",
      phonetics: [
        { text: "/ˈveɪp.ɪd/", audio: "" },
        {
          text: "/ˈveɪp.ɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/vapid-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50048987",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Offering nothing that is stimulating or challenging.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Lifeless, dull, or banal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Tasteless, bland, or insipid.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/vapid"],
    },
  ],
  cairn: [
    {
      word: "cairn",
      phonetic: "/kɛən/",
      phonetics: [
        { text: "/kɛən/", audio: "" },
        {
          text: "/kɛəɹn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cairn-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90810917",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A rounded or conical heap of stones erected by early inhabitants of the British Isles, apparently as a sepulchral monument.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A pile of stones heaped up as a landmark, to guide travelers on land or at sea, or to arrest attention, as in surveying, or in leaving traces of an exploring party, etc.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A cairn terrier.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["burial mound"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cairn"],
    },
  ],
  deism: [
    {
      word: "deism",
      phonetic: "/ˈdeɪ-/",
      phonetics: [
        {
          text: "/ˈdeɪ-/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/deism-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=12523342",
        },
        { text: "/ˈdeɪ-/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A philosophical belief in the existence of a god (or goddess) knowable through human reason; especially, a belief in a creator god unaccompanied by any belief in supernatural phenomena or specific religious doctrines.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Belief in a god who ceased to intervene with existence after acting as the cause of the cosmos.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/deism"],
    },
  ],
  mixup: [
    {
      word: "mixup",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A case of confusion.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mixup"],
    },
  ],
  yikes: [
    {
      word: "yikes",
      phonetic: "/jaɪks/",
      phonetics: [
        {
          text: "/jaɪks/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/yikes-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=711725",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "Expression of shock and alarm.",
              synonyms: [],
              antonyms: [],
              example:
                "Then she told me I was ‘beautiful, for a black girl’. —Yikes!",
            },
            {
              definition:
                "Expression of empathy with unpleasant or undesirable circumstances.",
              synonyms: [],
              antonyms: [],
              example:
                "John has lost his job and can't pay his mortgage; yikes!",
            },
          ],
          synonyms: ["eek", "wow"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/yikes"],
    },
  ],
  prosy: [
    {
      word: "prosy",
      phonetic: "/ˈpɹəʊzi/",
      phonetics: [{ text: "/ˈpɹəʊzi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Unpoetic (of speech or writing); dull and unimaginative.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Behaving in a dull way (of a person); boring, tedious.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/prosy"],
    },
  ],
  raker: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  flubs: [
    {
      word: "flubs",
      phonetic: "/flʌbz/",
      phonetics: [{ text: "/flʌbz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An error; a mistake in the performance of an action.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To goof, fumble, or err in the performance of an action.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/flub",
        "https://en.wiktionary.org/wiki/flubs",
      ],
    },
  ],
  whish: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  reify: [
    {
      word: "reify",
      phonetic: "/ˈɹeɪ.ə.faɪ/",
      phonetics: [
        { text: "/ˈɹeɪ.ə.faɪ/", audio: "" },
        {
          text: "/ˈɹeɪ.ə.faɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/reify-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2526726",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To regard something abstract as if it were a concrete material thing",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "conceptualize",
            "concretize",
            "objectify",
            "picture",
            "thingify",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/reify"],
    },
  ],
  craps: [
    {
      word: "craps",
      phonetic: "/kɹæps/",
      phonetics: [
        {
          text: "/kɹæps/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/craps-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241480",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A game of gambling, or chance, where the players throw dice to make scores and avoid crap.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/craps"],
    },
    {
      word: "craps",
      phonetic: "/kɹæps/",
      phonetics: [
        {
          text: "/kɹæps/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/craps-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241480",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The husk of grain; chaff.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(mildly) Something worthless or of poor quality; junk.",
              synonyms: [],
              antonyms: [],
              example:
                "The long-running game show went from offering good prizes to crap in no time.",
            },
            {
              definition: "(mildly) Nonsense; something untrue.",
              synonyms: [],
              antonyms: [],
              example:
                "The college student boasted of completing a 10,000-word essay on Shakespeare, but that claim was utter crap.",
            },
            {
              definition: "(mildly) Faeces/feces.",
              synonyms: [],
              antonyms: [],
              example: "I stepped in some dog crap that was on the sidewalk.",
            },
            {
              definition: "(mildly) An act of defecation.",
              synonyms: [],
              antonyms: [],
              example: "I have to take a crap.",
            },
          ],
          synonyms: ["dump", "poo", "poop", "shit"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A losing throw of 2, 3, or 12 in craps.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/crap",
        "https://en.wiktionary.org/wiki/craps",
      ],
    },
    {
      word: "craps",
      phonetic: "/kɹæps/",
      phonetics: [
        {
          text: "/kɹæps/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/craps-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241480",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To defecate.",
              synonyms: [],
              antonyms: [],
              example: "That soup tasted funny, and now I need to crap.",
            },
            {
              definition: "To defecate in or on (clothing etc.).",
              synonyms: [],
              antonyms: [],
              example: "He almost crapped his pants from fright.",
            },
            {
              definition: "To bullshit.",
              synonyms: [],
              antonyms: [],
              example: "Don't try to crap me, I know you're lying.",
            },
          ],
          synonyms: ["bullshit"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/crap",
        "https://en.wiktionary.org/wiki/craps",
      ],
    },
  ],
  shags: [
    {
      word: "shags",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Matted material; rough massed hair, fibres etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Coarse shredded tobacco.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A type of rough carpet pile.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Bacon or fat, especially if with some remaining hair or bristles.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A roughly-cut or torn-off piece of bread or cheese.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Several species of sea birds in the family Phalacrocoracidae (cormorant family), especially the common shag or European shag, Phalacrocorax aristotelis, found on European and African coasts.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A swing dance.", synonyms: [], antonyms: [] },
            {
              definition: "An act of sexual intercourse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A casual sexual partner.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(Northwestern Ontario) A fundraising dance in honour of a couple engaged to be married.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "social",
            "wedding social",
            "stag and doe",
            "stag and doe party",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Friend; mate; buddy.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/shag",
        "https://en.wiktionary.org/wiki/shags",
      ],
    },
  ],
  clone: [
    {
      word: "clone",
      phonetic: "/kləʊn/",
      phonetics: [
        { text: "/kləʊn/", audio: "" },
        {
          text: "/kloʊn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/clone-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50330722",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A living organism (originally a plant) produced asexually from a single ancestor, to which it is genetically identical.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A group of identical cells derived from a single cell.http//www.medterms.com/script/main/art.asp?articlekey=2754",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A copy or imitation of something already existing, especially when designed to simulate it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Two people who are exactly alike, as far as looks or behavior.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To create a clone of.",
              synonyms: [],
              antonyms: [],
              example: "The scientists were able to clone a sheep.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/clone"],
    },
  ],
  hazed: [
    {
      word: "hazed",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hazed-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75271825",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To be or become hazy, or thick with haze.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To perform an unpleasant initiation ritual upon a usually non-consenting individual, especially freshmen to a closed community such as a college or military unit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To oppress or harass by forcing to do hard and unnecessary work.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In a rodeo, to assist the bulldogger by keeping (the steer) running in a straight line.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Affected by haze; hazy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a photograph) Clouded, especially due to accidental exposure to light.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Drunk.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["cloudy", "foggy", "blurry", "overexposed"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/haze",
        "https://en.wiktionary.org/wiki/hazed",
      ],
    },
  ],
  macho: [
    {
      word: "macho",
      phonetic: "/ˈmætʃəʊ/",
      phonetics: [
        { text: "/ˈmætʃəʊ/", audio: "" },
        { text: "/ˈmɑtʃoʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A macho person; a person who tends to display masculine characteristics, such as domineering, fierceness, and bravado.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The striped mullet of California (Mugil cephalus, syn. Mugil mexicanus).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A male llama.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["macho man"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Tending to display masculine characteristics, such as domineering, fierceness, bravado, etc., in ways that are showily and histrionically tough",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/macho"],
    },
  ],
  recto: [
    {
      word: "recto",
      phonetic: "/ˈɹɛk.toʊ/",
      phonetics: [{ text: "/ˈɹɛk.toʊ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The front side of a flat object which is to be examined visually, as for reading, such as a sheet, leaf, coin or medal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The right-hand page of a book of a script which reads from left to right, usually having an odd page number.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A writ of right.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["front"],
          antonyms: ["flipside", "verso", "reverso"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/recto"],
    },
  ],
  refix: [
    {
      word: "refix",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To fix again.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/refix"],
    },
  ],
  drams: [
    {
      word: "drams",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The currency of Greece in ancient times and again from 1832 until 2001, with the symbol ₯, since replaced by the euro.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A coin worth one drachma.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An Ancient Greek weight of about 66.5 grains, or 4.3 grams.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A later Greek weight equal to a gram.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A small unit of weight, variously:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any similarly minute quantity, a small amount of strong alcohol or poison.",
              synonyms: [],
              antonyms: [],
              example: "a dram of brandy",
            },
            {
              definition: "A cart formerly used to haul coal in coal mines.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The currency of Armenia, divided into 100 luma.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/drachma",
        "https://en.wiktionary.org/wiki/dram",
        "https://en.wiktionary.org/wiki/drams",
      ],
    },
  ],
  biker: [
    {
      word: "biker",
      phonetic: "/ˈbaɪkɚ/",
      phonetics: [{ text: "/ˈbaɪkɚ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person whose lifestyle is centered on motorcycles, sometimes a member of a motorcycle club.",
              synonyms: ["bikie", "motorcyclist"],
              antonyms: [],
            },
            { definition: "Cyclist", synonyms: [], antonyms: [] },
          ],
          synonyms: ["bikie", "motorcyclist"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/biker"],
    },
  ],
  aquas: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  porky: [
    {
      word: "porky",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/porky-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241747",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/pɔː(ɹ).ki/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Resembling or characteristic of pork.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Rather fat; chubby.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["chubby", "chunky", "tubby"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/porky"],
    },
    {
      word: "porky",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/porky-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241747",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/pɔː(ɹ).ki/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A lie.", synonyms: [], antonyms: [] },
            {
              definition: "(in the plural) An eye.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/porky"],
    },
  ],
  doyen: [
    {
      word: "doyen",
      phonetic: "/dwɑˈjæ̃/",
      phonetics: [{ text: "/dwɑˈjæ̃/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A commander in charge of ten men.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The senior, or eldest male member of a group.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A leading light, or exemplar of a particular practice or movement.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/doyen"],
    },
  ],
  exude: [
    {
      word: "exude",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/exude-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=53355518",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ɪkˈsud/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To discharge through pores or incisions, as moisture or other liquid matter; to give out.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To flow out through the pores.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/exude"],
    },
  ],
  goofs: [
    {
      word: "goofs",
      phonetic: "/ɡuːfs/",
      phonetics: [{ text: "/ɡuːfs/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make a mistake.",
              synonyms: [],
              antonyms: [],
              example: "It's my fault. I goofed.",
            },
            {
              definition: "To engage in mischief.",
              synonyms: [],
              antonyms: [],
              example:
                "We were just goofing by painting the neighbor's cat green.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/goof",
        "https://en.wiktionary.org/wiki/goofs",
      ],
    },
  ],
  divvy: [
    {
      word: "divvy",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/divvy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79323636",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈdɪvi/", audio: "" },
        { text: "/ˈdɪvi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A dividend; a share or portion.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To divide into portions.",
              synonyms: [],
              antonyms: [],
              example:
                "We divvied the money into equal portions to split between us.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/divvy"],
    },
    {
      word: "divvy",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/divvy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79323636",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈdɪvi/", audio: "" },
        { text: "/ˈdɪvi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A foolish person.",
              synonyms: [],
              antonyms: [],
              example: "Put it down and stop being a divvy!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/divvy"],
    },
    {
      word: "divvy",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/divvy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79323636",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈdɪvi/", audio: "" },
        { text: "/ˈdɪvi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Divine; very pleasant, wonderful.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/divvy"],
    },
  ],
  noels: [
    {
      word: "noels",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Christmas", synonyms: [], antonyms: [] },
            {
              definition:
                "A kind of hymn, or canticle, of mediaeval origin, sung in honor of the birth of Christ; a Christmas carol.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/noel",
        "https://en.wiktionary.org/wiki/noels",
      ],
    },
  ],
  jived: [
    {
      word: "jived",
      phonetic: "/dʒaɪvd/",
      phonetics: [{ text: "/dʒaɪvd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To reproach with contemptuous words; to deride, to mock, to taunt.",
              synonyms: ["flout"],
              antonyms: [],
            },
            {
              definition: "To say in a mocking or taunting manner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make a mocking remark or remarks; to jeer.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["flout"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To accord or agree.",
              synonyms: [],
              antonyms: [],
              example: "That explanation doesn’t jibe with the facts.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "Alternative spelling of gybe",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To deceive; to be deceptive.",
              synonyms: [],
              antonyms: [],
              example:
                "Don’t try to jive me! I know where you were last night!",
            },
            { definition: "To dance.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/jibe",
        "https://en.wiktionary.org/wiki/jive",
        "https://en.wiktionary.org/wiki/jived",
      ],
    },
  ],
  hulky: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  cager: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  harpy: [
    {
      word: "harpy",
      phonetic: "/ˈhɑːpi/",
      phonetics: [
        {
          text: "/ˈhɑːpi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/harpy-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88988012",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈhɑɹpi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A fabulous winged monster, ravenous and filthy, having the face of a woman and the body of a vulture.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A shrewish woman.", synonyms: [], antonyms: [] },
            {
              definition: "One who is rapacious or ravenous; an extortioner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The European moor buzzard or marsh harrier (Circus aeruginosus).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A large and powerful double-crested, short-winged American eagle (Harpia harpyja).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/harpy"],
    },
  ],
  oldie: [
    {
      word: "oldie",
      phonetic: "/ˈəʊldi/",
      phonetics: [
        { text: "/ˈəʊldi/", audio: "" },
        { text: "/ˈoʊldi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Something or someone old.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A song or record from a previous era.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in the plural) The genre of music composed of popular music from previous eras.",
              synonyms: [],
              antonyms: [],
              example: "an oldies radio station",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/oldie"],
    },
  ],
  vivas: [
    {
      word: "vivas",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A shout of applause.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An oral examination, typically for an academic qualification.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/viva",
        "https://en.wiktionary.org/wiki/vivas",
      ],
    },
  ],
  admix: [
    {
      word: "admix",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The act of admixing.", synonyms: [], antonyms: [] },
            {
              definition:
                "The mixture that results from admixing, especially an alloy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To mingle with something else; to mix.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/admix"],
    },
  ],
  codas: [
    {
      word: "codas",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A person born hearing to deaf parents.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A passage that brings a movement or piece to a conclusion through prolongation.",
              synonyms: ["finale"],
              antonyms: [],
            },
            {
              definition:
                "The optional final part of a syllable, placed after its nucleus, and usually composed of one or more consonants.",
              synonyms: ["auslaut"],
              antonyms: ["onset"],
              example:
                "The word “salts” has three consonants — /l/, /t/, and /s/ — in its coda, whereas the word “glee” has no coda at all.",
            },
            {
              definition:
                "In seismograms, the gradual return to baseline after a seismic event. The length of the coda can be used to estimate event magnitude, and the shape sometimes reveals details of subsurface structures.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A conclusion (of a statement or event, for example), final portion, tail end.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["auslaut", "finale"],
          antonyms: ["onset"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/CODA",
        "https://en.wiktionary.org/wiki/coda",
        "https://en.wiktionary.org/wiki/codas",
      ],
    },
  ],
  zilch: [
    {
      word: "zilch",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/zilch-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75858307",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/zɪltʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/zilch-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80152233",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/zɪltʃ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A nobody: a person who is worthless in importance or character.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Nothing, zero.",
              synonyms: [],
              antonyms: [],
              example:
                "Her search for decent home-made winter clothes came up with zilch, so she had to get imported stuff.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cause to score nothing, to thoroughly defeat.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["shutout"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "No, zero, non-existent.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/zilch"],
    },
  ],
  deist: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  orcas: [
    {
      word: "orcas",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sea mammal (Orcinus orca) related to dolphins and porpoises, commonly called the killer whale.",
              synonyms: ["blackfish", "grampus", "killer whale"],
              antonyms: [],
            },
          ],
          synonyms: ["blackfish", "grampus", "killer whale"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/orca",
        "https://en.wiktionary.org/wiki/orcas",
      ],
    },
  ],
  retro: [
    {
      word: "retro",
      phonetic: "/ˈɹɛ.tɹoʊ/",
      phonetics: [{ text: "/ˈɹɛ.tɹoʊ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Past fashions or trends.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Of, or relating to, the past, past times, or the way things were.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Affecting things past; retroactive, ex post facto.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small rocket engine on a larger rocket or spacecraft, designed to slow or reverse its motion.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["retro"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An exhibition of works from an extended period of an artist's activity.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/retro",
        "https://en.wiktionary.org/wiki/retrorocket",
        "https://en.wiktionary.org/wiki/retrospective",
      ],
    },
  ],
  pilaf: [
    {
      word: "pilaf",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A dish made by browning grain, typically rice, in oil and then cooking it with a seasoned broth, to which meat and/or vegetables may be added.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pilaf"],
    },
  ],
  parse: [
    {
      word: "parse",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/parse-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75833767",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/pɑːz/", audio: "" },
        {
          text: "/pɑɹs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/parse-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50241398",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An act of parsing.",
              synonyms: [],
              antonyms: [],
              example:
                "The parse will fail if the program contains an unrecognised keyword.",
            },
            {
              definition: "The result of such an act.",
              synonyms: [],
              antonyms: [],
              example:
                "This parse is incorrect and indicates a fault in the parser.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To resolve (a sentence, etc.) into its elements, pointing out the several parts of speech, and their relation to each other by agreement or government; to analyze and describe grammatically.",
              synonyms: ["construe"],
              antonyms: [],
            },
            {
              definition: "To examine closely; to scrutinize.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To split (a file or other input) into pieces of data that can be easily manipulated or stored.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To resolve (a string of code or text) into its elements to determine if it conforms to a particular grammar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of a string of code or text, sentence, etc.: to conform to rules of grammar, to be syntactically valid.",
              synonyms: [],
              antonyms: [],
              example: "This sentence doesn't parse.",
            },
          ],
          synonyms: ["construe"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/parse"],
    },
  ],
  rants: [
    {
      word: "rants",
      phonetic: "/ˈɹænts/",
      phonetics: [
        {
          text: "/ˈɹænts/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rants-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80151802",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A criticism done by ranting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A wild, emotional, and sometimes incoherent articulation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A type of dance step usually performed in clogs, and particularly (but not exclusively) associated with the English North West Morris tradition. The rant step consists of alternately bringing one foot across and in front of the other and striking the ground, with the other foot making a little hop.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To speak or shout at length in uncontrollable anger.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To criticize by ranting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To speak extravagantly, as in merriment.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To dance rant steps.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rant",
        "https://en.wiktionary.org/wiki/rants",
      ],
    },
  ],
  zingy: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  toddy: [
    {
      word: "toddy",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A drink made from alcoholic liquor mixed with sugar, spice and hot water.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The sweet sap from any of several tropical trees fermented to make an alcoholic drink.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hot%20toddy",
        "https://en.wiktionary.org/wiki/toddy",
      ],
    },
  ],
  chiff: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  micro: [
    {
      word: "micro",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/micro-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88681186",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈmaɪ.kɹoʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(gaming slang) micromanagement",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(gaming slang) to micromanage",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Small, relatively small; used to contrast levels of the noun modified.",
              synonyms: [],
              antonyms: [],
              example:
                "At the micro level he was a good manager. At the macro level he failed.",
            },
          ],
          synonyms: [],
          antonyms: ["macro"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A computer designed around a microprocessor, smaller than a minicomputer or a mainframe.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["PC", "micro", "personal computer"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The field of economics that deals with small-scale economic activities such as those of an individual or company.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/micro",
        "https://en.wiktionary.org/wiki/microcomputer",
        "https://en.wiktionary.org/wiki/microeconomics",
      ],
    },
  ],
  veeps: [
    {
      word: "veeps",
      phonetic: "/viːps/",
      phonetics: [{ text: "/viːps/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(US politics) The Vice President of the United States; the office of Vice President of the United States, especially during an election cycle where several are in the running for the nomination.",
              synonyms: [],
              antonyms: [],
              example: "She's running for veep.",
            },
            {
              definition:
                "Any vice president (in a corporation, organization, etc.)",
              synonyms: [],
              antonyms: [],
              example:
                "I have a meeting this afternoon at Citibank with some veeps.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/veep",
        "https://en.wiktionary.org/wiki/veeps",
      ],
    },
  ],
  girly: [
    {
      word: "girly",
      phonetic: "/ˈɡɜːli/",
      phonetics: [
        { text: "/ˈɡɜːli/", audio: "" },
        { text: "/ˈɡɝli/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "Girl.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Characteristic of a stereotypical girl, very effeminate, sweet; unmasculine.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["effeminate", "unmanly", "feminine", "girlish", "womanly"],
          antonyms: [
            "butch",
            "manly",
            "masculine",
            "butch",
            "hoyden",
            "hoydenish",
            "mannish",
            "masculine",
            "tomboyish",
            "unwomanly",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/girly"],
    },
  ],
  nexus: [
    {
      word: "nexus",
      phonetic: "/ˈnɛk.suːs/",
      phonetics: [
        {
          text: "/ˈnɛk.suːs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/nexus-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80175109",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A form of connection.", synonyms: [], antonyms: [] },
            { definition: "A connected group.", synonyms: [], antonyms: [] },
            {
              definition: "The centre of something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In Ancient Rome, a person who had contracted a nexum or obligation of such a kind that, if he failed to pay, his creditor could compel him to work as a servant until the debt was paid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The relationship between a vendor and a jurisdiction for the purpose of taxation, established for example by the vendor operating a physical store in that jurisdiction.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["bond", "link", "tie", "hub", "junction", "network"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/nexus"],
    },
  ],
  demos: [
    {
      word: "demos",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A demonstration or visual explanation.",
              synonyms: [],
              antonyms: [],
              example:
                "The professor prepared a demo to help her class understand the topic.",
            },
            {
              definition:
                "A recording of a song meant to demonstrate its overall sound for the purpose of getting it published or recorded more fully.",
              synonyms: [],
              antonyms: [],
              example:
                "After hearing the demo the record label approved funding to record the song with a full band.",
            },
            {
              definition:
                "An example of a product used for demonstration and then sold at a discount.",
              synonyms: ["floor model"],
              antonyms: [],
            },
            {
              definition: "A march or gathering to make a political protest.",
              synonyms: ["demonstration", "march"],
              antonyms: [],
            },
            {
              definition:
                "An edition of limited functionality to give the user an example of how the program works.",
              synonyms: [],
              antonyms: [],
              example:
                "The game's developers released a demo version to the public 3 months before the full release.",
            },
            {
              definition:
                "A non-interactive audiovisual computer program developed by enthusiasts to demonstrate the capabilities of the machine. See demoscene.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A democrat.", synonyms: [], antonyms: [] },
            {
              definition: "(collective) A demographic group.",
              synonyms: [],
              antonyms: [],
              example:
                "The target demo of the new show will be children between 6 and 8 years old.",
            },
            { definition: "Demolition.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["demonstration", "march", "floor model"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/demo",
        "https://en.wiktionary.org/wiki/demos",
      ],
    },
  ],
  bibbs: [
    {
      word: "bibbs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A bibcock.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bibb",
        "https://en.wiktionary.org/wiki/bibbs",
      ],
    },
  ],
  antes: [
    {
      word: "antes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A price or cost, as in up the ante.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In poker and other games, the contribution made by all players to the pot before dealing the cards.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To pay the ante in poker. Often used as ante up.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make an investment in money, effort, or time before knowing one's chances.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ante",
        "https://en.wiktionary.org/wiki/antes",
      ],
    },
  ],
  lulus: [
    {
      word: "lulus",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A remarkable person, object or idea.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A very attractive or alluring person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A very bad mistake or error.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A fixed allowance paid to a legislator in lieu of reimbursement for actual expenses.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "ace",
            "champion",
            "comedy",
            "cool",
            "doozie",
            "exception",
            "humdinger",
            "innovation",
            "neato",
            "surprise",
            "unexpected",
            "babe",
            "beauty",
            "cutie",
            "doll",
            "dream",
            "dreamboat",
            "fox",
            "peach",
            "siren",
            "stunna",
            "stunner",
          ],
          antonyms: ["dog", "fright", "monster", "monstrosity", "sight"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lulu",
        "https://en.wiktionary.org/wiki/lulus",
      ],
    },
  ],
  gnarl: [
    {
      word: "gnarl",
      phonetic: "/nɑː(r)l/",
      phonetics: [
        {
          text: "/nɑː(r)l/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gnarl-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=86956862",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A knot in wood; a large or hard knot, or a protuberance with twisted grain, on a tree.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something resembling a knot in wood, such as in stone or limbs.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To knot or twist something.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Gnarled, knotty, twisted.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gnarl"],
    },
    {
      word: "gnarl",
      phonetic: "/nɑː(r)l/",
      phonetics: [
        {
          text: "/nɑː(r)l/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/gnarl-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=86956862",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To snarl or growl; to gnar.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gnarl"],
    },
  ],
  zippy: [
    {
      word: "zippy",
      phonetic: "/ˈzɪpi/",
      phonetics: [
        {
          text: "/ˈzɪpi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/zippy-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80152714",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈzɪpi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Energetic and lively.", synonyms: [], antonyms: [] },
            { definition: "Quick, speedy.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/zippy"],
    },
  ],
  ivied: [
    {
      word: "ivied",
      phonetic: "/ˈaɪviːd/",
      phonetics: [{ text: "/ˈaɪviːd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Overgrown with ivy or another climbing plant.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["univied"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ivied"],
    },
  ],
  wimps: [
    {
      word: "wimps",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Acronym of window, icon, menu, pointer. (a graphical interface paradigm)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Acronym of window-icon-mouse program.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A hypothetical class of particle, proposed to explain the dark matter problem.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["WIMP"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Someone who lacks confidence or courage, is weak, ineffectual, irresolute and wishy-washy",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["sissy", "softy", "wuss"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/WIMP",
        "https://en.wiktionary.org/wiki/weakly%20interacting%20massive%20particle",
        "https://en.wiktionary.org/wiki/wimp",
        "https://en.wiktionary.org/wiki/wimps",
      ],
    },
  ],
  tromp: [
    {
      word: "tromp",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To tread heavily, especially to crush underfoot.",
              synonyms: [],
              antonyms: [],
              example:
                "Mother yelled at my brothers for tromping through her flowerbed.",
            },
            {
              definition: "To utterly defeat an opponent.",
              synonyms: [],
              antonyms: [],
              example:
                "The team had been tromped by their cross-town rivals, and the players were embarrassed to show their faces in school the next day.",
            },
          ],
          synonyms: [
            "march",
            "stamp",
            "stomp",
            "tramp",
            "trample",
            "clobber",
            "decimate",
            "rout",
            "trounce",
            "whip",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tromp"],
    },
    {
      word: "tromp",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A blowing apparatus in which air, drawn into the upper part of a vertical tube through side holes by a stream of water within, is carried down with the water into a box or chamber below which it is led to a furnace.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tromp"],
    },
  ],
  grail: [
    {
      word: "grail",
      phonetic: "/ɡɹeɪɫ/",
      phonetics: [
        {
          text: "/ɡɹeɪɫ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grail-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89033340",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The Holy Grail.", synonyms: [], antonyms: [] },
            {
              definition:
                "The object of an extended or difficult quest.http//www.merriam-webster.com/dictionary/grail",
              synonyms: [],
              antonyms: [],
              example: "Becoming an astronaut was his grail.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/grail"],
    },
    {
      word: "grail",
      phonetic: "/ɡɹeɪɫ/",
      phonetics: [
        {
          text: "/ɡɹeɪɫ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grail-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89033340",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A book of offices in the Roman Catholic Church; a gradual.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/grail"],
    },
    {
      word: "grail",
      phonetic: "/ɡɹeɪɫ/",
      phonetics: [
        {
          text: "/ɡɹeɪɫ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grail-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89033340",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Small particles of earth; gravel.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/grail"],
    },
    {
      word: "grail",
      phonetic: "/ɡɹeɪɫ/",
      phonetics: [
        {
          text: "/ɡɹeɪɫ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/grail-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89033340",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "One of the small feathers of a hawk.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/grail"],
    },
  ],
  yoyos: [
    {
      word: "yoyos",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A toy consisting of a spheroidal or cylindrical spindle having a circular groove in which string is wound; it is used by holding the string in the fingers and reeling the spindle up and down by movements of the wrist.",
              synonyms: [],
              antonyms: [],
              example: "I bought a yo-yo from the toy store yesterday.",
            },
            {
              definition: "A volatile market that moves up and down.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Someone who vacillates.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A cloth rosette formed by gathering the outside edge of a circle of fabric in toward the centre using a running stitch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A foolish, annoying or incompetent person.",
              synonyms: [],
              antonyms: [],
              example:
                "It is hard to watch the management for very long and not conclude that the place is run by a bunch of yo-yos.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/yo-yo",
        "https://en.wiktionary.org/wiki/yoyo",
        "https://en.wiktionary.org/wiki/yoyos",
      ],
    },
  ],
  poufs: [
    {
      word: "poufs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The product of flatulence, or the sound of breaking wind.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A male homosexual, especially one who is effeminate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A headdress for women popular in 18th century France.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A high hair style for women consisting of a roll or pad of hair, worn up.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Part of an item of clothing consisting of gathered fabric in a bunch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A low cushioned seat with no back; a padded footstool.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A short skirt gathered into a rounded puffy shape; a puffball.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A ball of fabric (such as nylon monofilament netting) used for washing (as an alternative to a flannel, washcloth, sponge, etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small saddle cushion worn atop the buttocks (as a fashion trend – similar to a bustle).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "horse's hoof",
            "poof",
            "poofta",
            "pooftah",
            "poofter",
            "footstool",
            "hassock",
            "ottoman",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sharp exhalation of a small amount of breath through the mouth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The ability to breathe easily while exerting oneself.",
              synonyms: ["wind"],
              antonyms: [],
              example: "out of puff",
            },
            {
              definition: "A small quantity of gas or smoke in the air.",
              synonyms: [],
              antonyms: [],
              example: "puff of smoke",
            },
            { definition: "A sudden gust.", synonyms: [], antonyms: [] },
            {
              definition:
                "An act of inhaling smoke from a cigarette, cigar or pipe.",
              synonyms: ["drag"],
              antonyms: [],
            },
            {
              definition: "A flamboyant or alluring statement of praise.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A puffer, one who is employed by the owner or seller of goods sold at auction to bid up the price; an act or scam of that type.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A puffball.", synonyms: [], antonyms: [] },
            { definition: "A powder puff.", synonyms: [], antonyms: [] },
            { definition: "The drug cannabis.", synonyms: [], antonyms: [] },
            {
              definition: "A light cake filled with cream, cream cheese, etc.",
              synonyms: ["cream puff", "pastry"],
              antonyms: [],
              example: "cream puff",
            },
            { definition: "Life.", synonyms: [], antonyms: [] },
            {
              definition:
                "A portion of fabric gathered up so as to be left full in the middle.",
              synonyms: [],
              antonyms: [],
              example: "a sleeve with a puff at the shoulder",
            },
            {
              definition:
                "A region of a chromosome exhibiting a local increase in diameter.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["cream puff", "pastry", "drag", "wind"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/poof",
        "https://en.wiktionary.org/wiki/pouf",
        "https://en.wiktionary.org/wiki/poufs",
        "https://en.wiktionary.org/wiki/puff",
      ],
    },
  ],
  hales: [
    {
      word: "hales",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To drag or pull, especially forcibly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hale",
        "https://en.wiktionary.org/wiki/hales",
      ],
    },
  ],
  roust: [
    {
      word: "roust",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/roust-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79268614",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A strong tide or current, especially in a narrow channel.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["roost", "rost"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To rout out of bed; to rouse",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To harass, to treat in a rough way.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To arrest", synonyms: [], antonyms: [] },
            { definition: "To drive strongly", synonyms: [], antonyms: [] },
          ],
          synonyms: ["collar", "nick", "pinch", "bring round", "knock up"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/roust"],
    },
  ],
  cabal: [
    {
      word: "cabal",
      phonetic: "/kəˈbæl/",
      phonetics: [{ text: "/kəˈbæl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "# A putative, secret organization of individuals gathered for a political purpose.",
              synonyms: [],
              antonyms: [],
              example: "The cabal is plotting to ruin the world.",
            },
            {
              definition: "A secret plot.",
              synonyms: [],
              antonyms: [],
              example:
                "The cabal to destroy the building was foiled by federal agents.",
            },
            {
              definition:
                "An identifiable group within the tradition of Discordianism.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["camarilla", "conspiracy"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To engage in the activities of a cabal.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cabal"],
    },
  ],
  rawer: [
    {
      word: "rawer",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "(of food) Not cooked.", synonyms: [], antonyms: [] },
            {
              definition:
                "(of materials, products, etc.) Not treated or processed; in a natural state, unrefined, unprocessed.",
              synonyms: [],
              antonyms: [],
              example: "raw cane sugar",
            },
            {
              definition:
                "Having had the skin removed or abraded; chafed, tender; exposed, lacerated.",
              synonyms: [],
              antonyms: [],
              example: "a raw wound",
            },
            {
              definition: "New or inexperienced.",
              synonyms: [],
              antonyms: [],
              example: "a raw beginner",
            },
            {
              definition: "Crude in quality; rough, uneven, unsophisticated.",
              synonyms: [],
              antonyms: [],
              example: "a raw voice",
            },
            {
              definition: "(of data) Uncorrected, without analysis.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of weather) Unpleasantly cold or damp.",
              synonyms: [],
              antonyms: [],
              example: "a raw wind",
            },
            {
              definition:
                "(of an emotion, personality, etc.) Unmasked, undisguised, strongly expressed",
              synonyms: [],
              antonyms: [],
              example: "raw emotion",
            },
            {
              definition:
                "Candid in a representation of unpleasant facts, conditions, etc.",
              synonyms: [],
              antonyms: [],
              example: "a raw description of the American political arena",
            },
            {
              definition:
                "(of language) Unrefined, crude, or insensitive, especially with reference to sexual matters",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Not covered; bare; bald.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/raw",
        "https://en.wiktionary.org/wiki/rawer",
      ],
    },
  ],
  pampa: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  mosey: [
    {
      word: "mosey",
      phonetic: "/ˈmoʊ.zi/",
      phonetics: [
        {
          text: "/ˈmoʊ.zi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mosey-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=6073761",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To set off, get going; to start a journey.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To go off quickly: to hurry up.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To amble; to walk or proceed in a leisurely manner.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mosey"],
    },
  ],
  kefir: [
    {
      word: "kefir",
      phonetic: "/kəˈfɪə(ɹ)/",
      phonetics: [{ text: "/kəˈfɪə(ɹ)/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A fermented milk drink from the Caucasus and Eastern Europe, similar to yogurt but more liquidy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/kefir"],
    },
    {
      word: "kefir",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A disbeliever, a denier: someone who denies the truths from Allah; or more broadly any non-Muslim.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/kafir",
        "https://en.wiktionary.org/wiki/kefir",
      ],
    },
  ],
  burgs: [
    {
      word: "burgs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A city or town.", synonyms: [], antonyms: [] },
            {
              definition: "A fortified town in medieval Europe.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "Burger", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/burg",
        "https://en.wiktionary.org/wiki/burgs",
      ],
    },
  ],
  unmet: [
    {
      word: "unmet",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Not met; unfulfilled; not achieved",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/unmet"],
    },
  ],
  cuspy: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  boobs: [
    {
      word: "boobs",
      phonetic: "/buːbz/",
      phonetics: [
        {
          text: "/buːbz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/boobs-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=55507356",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Idiot, fool.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To behave stupidly; to act like a boob.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To make a mistake", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A breast, especially that of an adult or adolescent human female.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A prison; jail.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/boob",
        "https://en.wiktionary.org/wiki/boobs",
      ],
    },
  ],
  boons: [
    {
      word: "boons",
      phonetic: "/buːnz/",
      phonetics: [{ text: "/buːnz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A prayer; petition.", synonyms: [], antonyms: [] },
            {
              definition:
                "That which is asked or granted as a benefit or favor; a gift or benefaction.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A good thing; a blessing or benefit; a thing to be thankful for.",
              synonyms: [],
              antonyms: [],
              example: "Anaesthetics are a great boon to modern surgery.",
            },
            {
              definition: "An unpaid service due by a tenant to his lord.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["benefit", "blessing", "favor", "gift"],
          antonyms: ["bane"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/boon",
        "https://en.wiktionary.org/wiki/boons",
      ],
    },
  ],
  hypes: [
    {
      word: "hypes",
      phonetic: "/haɪps/",
      phonetics: [{ text: "/haɪps/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A throw in which the wrestler lifts his opponent from the ground, swings him to one side, knocks up his nearer thigh from the back with the knee, and throws him on his back.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To throw (an opponent) using this technique.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Promotion or propaganda; especially exaggerated claims.",
              synonyms: [],
              antonyms: [],
              example:
                "After all the hype for the diet plan, only the results ended up slim.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To promote heavily; to advertise or build up.",
              synonyms: [],
              antonyms: [],
              example:
                "They started hyping the new magazine months before its release.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A drug addict.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hipe",
        "https://en.wiktionary.org/wiki/hype",
        "https://en.wiktionary.org/wiki/hypes",
      ],
    },
  ],
  yogis: [
    {
      word: "yogis",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A devotee or adherent of yoga.",
              synonyms: [],
              antonyms: [],
              example:
                "Gurdjieff connects this type of breathing with yogi breathing.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To turn (someone) into a yogi; to lead into practicing yoga.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(thru-hiker slang) To persuade someone to give you food or other favors without actually begging.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/yogi",
        "https://en.wiktionary.org/wiki/yogis",
      ],
    },
  ],
  quark: [
    {
      word: "quark",
      phonetic: "/kwɑːk/",
      phonetics: [
        { text: "/kwɑːk/", audio: "" },
        { text: "/kwɑɹk/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "In the Standard Model, an elementary subatomic particle that forms matter. They combine to form hadrons, such as protons and neutrons.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(X Window System) An integer that uniquely identifies a text string.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/quark"],
    },
    {
      word: "quark",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A soft creamy cheese, eaten throughout northern, central, and eastern Europe, very similar to cottage cheese except that it is usually not made with rennet.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/quark"],
    },
    {
      word: "quark",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(Falkland Islands) The black-crowned night heron, Nycticorax nycticorax.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/quark"],
    },
  ],
  prate: [
    {
      word: "prate",
      phonetic: "/pɹeɪt/",
      phonetics: [{ text: "/pɹeɪt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Talk to little purpose; trifling talk; unmeaningful loquacity.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To talk much and to little purpose; to be loquacious; to speak foolishly.",
              synonyms: ["blabber"],
              antonyms: [],
            },
          ],
          synonyms: ["blabber"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/prate"],
    },
  ],
  swigs: [
    {
      word: "swigs",
      phonetic: "/swɪɡz/",
      phonetics: [{ text: "/swɪɡz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Drink, liquor.", synonyms: [], antonyms: [] },
            {
              definition: "(by extension) A long draught from a drink.",
              synonyms: ["draught", "sip", "swill"],
              antonyms: [],
            },
            {
              definition: "A person who drinks deeply.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A tackle with ropes which are not parallel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Warm beer flavoured with spices, lemon, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["draught", "sip", "swill"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To drink (usually by gulping or in a greedy or unrefined manner); to quaff.",
              synonyms: ["chug", "gulp", "guzzle", "quaff"],
              antonyms: [],
              example: "That sailor can swig whisky with the best of 'em.",
            },
            { definition: "To suck.", synonyms: [], antonyms: [] },
            {
              definition:
                "To take up the last bit of slack in rigging by taking a single turn around a cleat, then hauling on the line above and below the cleat while keeping tension on the line.",
              synonyms: ["sweating"],
              antonyms: [],
            },
          ],
          synonyms: ["chug", "gulp", "guzzle", "quaff", "sweating"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/swig",
        "https://en.wiktionary.org/wiki/swigs",
      ],
    },
  ],
  vitas: [
    {
      word: "vitas",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A curriculum vitae.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/vita",
        "https://en.wiktionary.org/wiki/vitas",
      ],
    },
  ],
  toker: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  doper: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  bossa: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  linty: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  foist: [
    {
      word: "foist",
      phonetic: "/fɔɪst/",
      phonetics: [
        { text: "/fɔɪst/", audio: "" },
        {
          text: "/fɔɪst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/foist-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=4910893",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A thief or pickpocket.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To introduce or insert surreptitiously or without warrant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To force another to accept especially by stealth or deceit.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To pass off as genuine or worthy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["fob off", "palm off", "pass off", "pawn off"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/foist"],
    },
    {
      word: "foist",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A light and fast-sailing ship.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/foist"],
    },
    {
      word: "foist",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A cask for wine.", synonyms: [], antonyms: [] },
            { definition: "Fustiness; mustiness.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/foist"],
    },
  ],
  mondo: [
    {
      word: "mondo",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mondo-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241800",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A dialogue between master and student designed to obtain an intuitive truth.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mondo"],
    },
    {
      word: "mondo",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mondo-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241800",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Big, large; major, significant.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Very, extremely, really.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mondo"],
    },
  ],
  stash: [
    {
      word: "stash",
      phonetic: "/stæʃ/",
      phonetics: [
        {
          text: "/stæʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stash-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=694419",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A collection, sometimes hidden; a reserve.",
              synonyms: [],
              antonyms: [],
              example:
                "They had quite a stash of food saved up for emergencies.",
            },
            {
              definition: "A place where drugs are stored.",
              synonyms: [],
              antonyms: [],
              example:
                "The dealers managed to store the dope in the stash just in time to avoid being caught by the police.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To hide or store away for later use.",
              synonyms: [],
              antonyms: [],
              example: "He stashed his liquor in the cabinet under the bar.",
            },
          ],
          synonyms: ["hive up"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stash"],
    },
    {
      word: "stash",
      phonetic: "/stæʃ/",
      phonetics: [
        {
          text: "/stæʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stash-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=694419",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "Mustache", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stash"],
    },
  ],
  twerp: [
    {
      word: "twerp",
      phonetic: "/twɜːp/",
      phonetics: [
        { text: "/twɜːp/", audio: "" },
        { text: "/twɝp/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A fool, a twit.",
              synonyms: [],
              antonyms: [],
              example: "Now you've broken it, you twerp!",
            },
            {
              definition:
                "A small or puny person; one regarded as insignificant, contemptible.",
              synonyms: [],
              antonyms: [],
              example: "Get out of my way, you little twerp!",
            },
            {
              definition:
                "A person who can be bullied playfully, or easily teased. Sometimes used as a pet-name (often for a younger sibling).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["git", "twit", "twerk"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/twerp"],
    },
  ],
  zesty: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  wimpy: [
    {
      word: "wimpy",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Having the characteristics of a wimp; feeble, indecisive, cowardly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/wimpy"],
    },
  ],
  rewed: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  fungo: [
    {
      word: "fungo",
      phonetic: "/ˈfʌŋɡoʊ/",
      phonetics: [{ text: "/ˈfʌŋɡoʊ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A fielding practice drill where a person hits fly balls intended to be caught.",
              synonyms: [],
              antonyms: [],
              example:
                "The fielding coach played fungo with the outfielders to warm them up.",
            },
            {
              definition: "A fungo bat.",
              synonyms: [],
              antonyms: [],
              example:
                "He is so strong he could hit it out of the park with a fungo.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fungo"],
    },
  ],
  tarot: [
    {
      word: "tarot",
      phonetic: "/ˈtɛɹoʊ/",
      phonetics: [
        { text: "/ˈtɛɹoʊ/", audio: "" },
        { text: "/ˈtæɹəʊ/", audio: "" },
        { text: "/ˈtɛɹoʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(singular or plural) A card game played in various different variations.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of the set of 78 playing cards (divided into five suits, including one of permanent trumps), often used for mystical divination.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tarot"],
    },
  ],
  frosh: [
    {
      word: "frosh",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/frosh-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241237",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/fɹɔʃ/", audio: "" },
        { text: "/fɹɑʃ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "A frog.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/frosh"],
    },
    {
      word: "frosh",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/frosh-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241237",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/fɹɔʃ/", audio: "" },
        { text: "/fɹɑʃ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A first-year student, at certain universities, and a first-or-second-year student at other universities.",
              synonyms: [],
              antonyms: [],
              example: "That frosh is really getting on my nerves!",
            },
          ],
          synonyms: ["fresher", "newbie", "underclassman"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To initiate academic freshmen, notably in a testing way.",
              synonyms: [],
              antonyms: [],
              example: "This campus does not tolerate froshing in any form.",
            },
            {
              definition: "To damage through incompetence.",
              synonyms: [],
              antonyms: [],
              example:
                "Trying to open my car door with a coat hanger, I froshed the mechanism.",
            },
          ],
          synonyms: ["haze"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/frosh"],
    },
  ],
  kabob: [
    {
      word: "kabob",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A dish of pieces of meat, fish, or vegetables roasted on a skewer or spit, especially a doner kebab.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A shish kebab or any other food on a skewer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The outward growing portions of a shish kebab structure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A Muslim, usually of southern European, Middle Eastern, or North African descent.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The vulva.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To roast in the style of a kebab",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To stab or skewer", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/kabob",
        "https://en.wiktionary.org/wiki/kebab",
      ],
    },
  ],
  pinko: [
    {
      word: "pinko",
      phonetic: "/pɪŋkəʊ/",
      phonetics: [
        { text: "/pɪŋkəʊ/", audio: "" },
        { text: "/pɪŋkoʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A socialist who is not wholly communist.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pinko"],
    },
  ],
  redid: [
    {
      word: "redid",
      phonetic: "/ɹi.dɪd/",
      phonetics: [{ text: "/ɹi.dɪd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To do again.",
              synonyms: ["rework"],
              antonyms: ["undo"],
            },
          ],
          synonyms: ["rework"],
          antonyms: ["undo"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/redid",
        "https://en.wiktionary.org/wiki/redo",
      ],
    },
  ],
  mimeo: [
    {
      word: "mimeo",
      phonetic: "/ˈmɪmɪəʊ/",
      phonetics: [{ text: "/ˈmɪmɪəʊ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A mimeograph.",
              synonyms: [],
              antonyms: [],
              example:
                "2005: Underground film and performance art were blossoming; backroom mimeo presses seemed to be everywhere. — Martin Torgoff, Can't Find My Way Home (Simon & Schuster 2005, p. 105)",
            },
            {
              definition: "An unpublished academic paper.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To mimeograph.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mimeo"],
    },
  ],
  heist: [
    {
      word: "heist",
      phonetic: "/ˈhaɪst/",
      phonetics: [
        {
          text: "/ˈhaɪst/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/heist-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79268699",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A robbery or burglary, especially from an institution such as a bank or museum.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A fiction genre in which a heist is central to the plot.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To steal, rob or hold up (something).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/heist"],
    },
  ],
  tarps: [
    {
      word: "tarps",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A tarpaulin.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tarp",
        "https://en.wiktionary.org/wiki/tarps",
      ],
    },
  ],
  lamas: [
    {
      word: "lamas",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A master of Tibetan Buddhism.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A South American mammal of the camel family, Lama glama, used as a domestic beast of burden and a source of wool and meat.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lama",
        "https://en.wiktionary.org/wiki/lamas",
        "https://en.wiktionary.org/wiki/llama",
      ],
    },
  ],
  sutra: [
    {
      word: "sutra",
      phonetic: "/ˈsuːtɹə/",
      phonetics: [{ text: "/ˈsuːtɹə/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A rule or thesis in Sanskrit grammar or Hindu law or philosophy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A scriptural narrative, especially a discourse of the Buddha.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sutra"],
    },
  ],
  dinar: [
    {
      word: "dinar",
      phonetic: "/ˈdiːnɑː(ɹ)/",
      phonetics: [{ text: "/ˈdiːnɑː(ɹ)/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The official currency of several countries, including Algeria, Bahrain, Iraq, Jordan, Kuwait, Libya, Serbia and Tunisia.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An ancient Arab gold coin of 65 grains in weight.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dinar"],
    },
  ],
  whams: [
    {
      word: "whams",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A forceful blow", synonyms: [], antonyms: [] },
            {
              definition: "The sound of such a blow; a thud",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To strike or smash (into) something with great force or impact",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wham",
        "https://en.wiktionary.org/wiki/whams",
      ],
    },
  ],
  busty: [
    {
      word: "busty",
      phonetic: "/ˈbʌsti/",
      phonetics: [{ text: "/ˈbʌsti/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having large breasts.",
              synonyms: [],
              antonyms: [],
              example: "a busty blonde",
            },
          ],
          synonyms: [
            "big-breasted",
            "bosomy",
            "buxom",
            "full-bosomed",
            "well-stacked",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/busty"],
    },
  ],
  spays: [
    {
      word: "spays",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To divine; foretell", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To remove or destroy the ovaries (of an animal) so that it cannot become pregnant.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["castrate", "emasculate", "geld", "neuter", "sterilize"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/spae",
        "https://en.wiktionary.org/wiki/spay",
        "https://en.wiktionary.org/wiki/spays",
      ],
    },
  ],
  mambo: [
    {
      word: "mambo",
      phonetic: "/ˈmæmbəʊ/",
      phonetics: [{ text: "/ˈmæmbəʊ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A voodoo priestess (in Haiti)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A Latin-American musical genre, adapted from rumba, originating from Cuba in the 1940s, or a dance or rhythm of this genre.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To perform this dance.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mambo"],
    },
  ],
  nabob: [
    {
      word: "nabob",
      phonetic: "/ˈneɪbɒb/",
      phonetics: [
        { text: "/ˈneɪbɒb/", audio: "" },
        { text: "/ˈneɪbɑb/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An Indian ruler within the Mogul empire.",
              synonyms: ["nawab"],
              antonyms: [],
            },
            {
              definition:
                "(by extension) Someone of great wealth or importance.",
              synonyms: ["magnate", "tycoon"],
              antonyms: [],
            },
            {
              definition:
                "(by extension) A person with a grandiose style or manner.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["magnate", "tycoon", "nawab"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/nabob"],
    },
  ],
  preps: [
    {
      word: "preps",
      phonetic: "/pɹɛps/",
      phonetics: [{ text: "/pɹɛps/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A preventative medical regiment of HIV medicines used to protect against HIV seroconversion after an exposure.",
              synonyms: ["PrEP"],
              antonyms: [],
              example:
                "Some doctors will prescribe pre-exposure prophylaxis to promiscuous people that have a hard time controlling themselves or their condom usage to prevent them from getting AIDS.",
            },
          ],
          synonyms: ["PrEP"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Preparation.", synonyms: [], antonyms: [] },
            { definition: "A prep school.", synonyms: [], antonyms: [] },
            {
              definition: "A student or graduate of a prep school, a preppy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Homework, work set to do outside class time, used widely in public schools and preparatory schools but not state schools.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Preparatory level; the last two levels or the fourth and fifth years of preschool; the two levels before first grade.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To prepare.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(grammar, strict sense) Any of a class of non-inflecting words typically employed to connect a following noun or a pronoun, in an adjectival or adverbial sense, with some other word: a particle used with a noun or pronoun (in English always in the objective case) to make a phrase limiting some other word.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A proposition; an exposition; a discourse.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/PrEP",
        "https://en.wiktionary.org/wiki/pre-exposure%20prophylaxis",
        "https://en.wiktionary.org/wiki/prep",
        "https://en.wiktionary.org/wiki/preposition",
        "https://en.wiktionary.org/wiki/preps",
      ],
    },
  ],
  odour: [
    {
      word: "odour",
      phonetic: "/ˈəʊdə/",
      phonetics: [
        { text: "/ˈəʊdə/", audio: "" },
        {
          text: "/ˈoʊdəɹ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/odour-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780244",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Any smell, whether fragrant or offensive.",
              synonyms: ["perfume", "scent"],
              antonyms: [],
            },
            {
              definition: "A strong, pervasive quality.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Esteem.",
              synonyms: ["esteem", "repute"],
              antonyms: [],
            },
            {
              definition:
                "Something which produces a scent; incense, a perfume.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["esteem", "repute", "perfume", "scent"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/odor",
        "https://en.wiktionary.org/wiki/odour",
      ],
    },
  ],
  cabby: [
    {
      word: "cabby",
      phonetic: "/ˈkæ.bi/",
      phonetics: [{ text: "/ˈkæ.bi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A cabdriver; someone who drives a taxi.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cabby"],
    },
  ],
  conks: [
    {
      word: "conks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A marine gastropod of the family Strombidae which lives in its own spiral shell.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The shell of this sea animal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A musical instrument made from a large spiral seashell, somewhat like a trumpet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The semidome of an apse, or the apse itself.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A machine (rather like a rotating pestle and mortar) used to refine the flavour and texture of chocolate by warming and grinding.",
              synonyms: ["conch", "conche"],
              antonyms: [],
            },
            {
              definition: "A person who operates such a machine.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["conch", "conche"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The shelf- or bracket-shaped fruiting body of a bracket fungus (also called a shelf fungus), i.e. a mushroom growing off a tree trunk.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A nose, especially a large one.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To hit, especially on the head.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A hairstyle involving the chemical straightening and styling of kinky hair.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To chemically straighten tightly curled hair.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(often with out) To fail or show signs of failing, cease operating, break down, become unconscious.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/conch",
        "https://en.wiktionary.org/wiki/concher",
        "https://en.wiktionary.org/wiki/conk",
        "https://en.wiktionary.org/wiki/conks",
      ],
    },
  ],
  sluff: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  dados: [
    {
      word: "dados",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The section of a pedestal above the base.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The lower portion of an interior wall decorated differently from the upper portion.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The rectangular channel in a board cut across the grain.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dado",
        "https://en.wiktionary.org/wiki/dados",
      ],
    },
  ],
  houri: [
    {
      word: "houri",
      phonetic: "/ˈhʊəɹi/",
      phonetics: [{ text: "/ˈhʊəɹi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A nymph in the form of a beautiful virgin supposed to dwell in Paradise for the enjoyment of the faithful.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) Any voluptuous, beautiful woman.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/houri"],
    },
  ],
  swart: [
    {
      word: "swart",
      phonetic: "/swɔː(ɹ)t/",
      phonetics: [
        { text: "/swɔː(ɹ)t/", audio: "" },
        { text: "/swɔɹt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Black or dark dyestuff; something of a certain swart; something of a certain ocker.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of a dark hue; moderately black; swarthy; tawny.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Black.", synonyms: [], antonyms: [] },
            { definition: "Gloomy; malignant.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/swart"],
    },
    {
      word: "swart",
      phonetic: "/swɔː(ɹ)t/",
      phonetics: [
        { text: "/swɔː(ɹ)t/", audio: "" },
        { text: "/swɔɹt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make swart or tawny; blacken; tan.",
              synonyms: [],
              antonyms: [],
              example: "to swart a living part",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/swart"],
    },
    {
      word: "swart",
      phonetic: "/swɔː(ɹ)t/",
      phonetics: [
        { text: "/swɔː(ɹ)t/", audio: "" },
        { text: "/swɔɹt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A layer of earth into which grass has grown; turf; sod.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An expanse of land covered in grass; a lawn or meadow.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Skin; covering.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "grass",
            "sod",
            "turf",
            "clearing",
            "field",
            "greensward",
            "lawn",
            "meadow",
            "yard",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sward",
        "https://en.wiktionary.org/wiki/swart",
      ],
    },
  ],
  balms: [
    {
      word: "balms",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of various aromatic resins exuded from certain plants, especially trees of the genus Commiphora of Africa, Arabia and India and Myroxylon of South America.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A plant or tree yielding such substance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any soothing oil or lotion, especially an aromatic one.",
              synonyms: [],
              antonyms: [],
              example: "There is a balm in Gilead... (Spiritual)",
            },
            {
              definition: "Something soothing.",
              synonyms: [],
              antonyms: [],
              example: "Classical music is a sweet balm for our sorrows.",
            },
            {
              definition: "The lemon balm, Melissa officinalis",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of a number of other aromatic herbs with a similar citrus-like scent, such as bee balm and horse balm.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["balsam", "balsam", "balsam", "balsam"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/balm",
        "https://en.wiktionary.org/wiki/balms",
      ],
    },
  ],
  gutsy: [
    {
      word: "gutsy",
      phonetic: "/ˈɡʌt.si/",
      phonetics: [{ text: "/ˈɡʌt.si/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Marked by courage and determination in the face of difficulties or danger; having guts",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Not showing due respect",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "audacious",
            "bold",
            "brave",
            "hardy",
            "bold",
            "brave",
            "gutty",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gutsy"],
    },
  ],
  faxed: [
    {
      word: "faxed",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having a head of hair; hairy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/faxed"],
    },
    {
      word: "faxed",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To send a document via a fax machine.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fax",
        "https://en.wiktionary.org/wiki/faxed",
      ],
    },
  ],
  pushy: [
    {
      word: "pushy",
      phonetic: "/ˈpʊ.ʃi/",
      phonetics: [{ text: "/ˈpʊ.ʃi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Aggressively ambitious; overly assertive, bold or determined.",
              synonyms: [],
              antonyms: [],
              example:
                "Parents are ruining school sports days by being pushy and overbearing, a survey suggests. BBC",
            },
          ],
          synonyms: ["sharp-elbowed"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pushy"],
    },
  ],
  retry: [
    {
      word: "retry",
      phonetic: "/riˈtɹaɪ/",
      phonetics: [{ text: "/riˈtɹaɪ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Another attempt.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To try or attempt again.",
              synonyms: [],
              antonyms: [],
              example: "to retry connecting to the Internet after an error",
            },
            {
              definition: "To try judicially a second time.",
              synonyms: [],
              antonyms: [],
              example: "to retry a case",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/retry"],
    },
  ],
  agora: [
    {
      word: "agora",
      phonetic: "/ˈæ.ɡə.ɹə/",
      phonetics: [
        {
          text: "/ˈæ.ɡə.ɹə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/agora-1-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88968094",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A place for gathering.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A marketplace, especially in Classical Greece.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/agora"],
    },
    {
      word: "agora",
      phonetic: "/ˌæɡ.əˈɹɑː/",
      phonetics: [
        {
          text: "/ˌæɡ.əˈɹɑː/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/agora-2-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88968095",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Since 1960, a monetary unit and coin of Israel, the 100th part of a shekel / sheqel.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/agora"],
    },
  ],
  drubs: [
    {
      word: "drubs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To beat (someone or something) with a stick.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To defeat someone soundly; to annihilate or crush.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To forcefully teach something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To criticize harshly; to excoriate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/drub",
        "https://en.wiktionary.org/wiki/drubs",
      ],
    },
  ],
  daffy: [
    {
      word: "daffy",
      phonetic: "/ˈdæfi/",
      phonetics: [{ text: "/ˈdæfi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Somewhat mad or eccentric.",
              synonyms: ["crazy", "nutty", "wacky"],
              antonyms: [],
            },
          ],
          synonyms: ["crazy", "nutty", "wacky"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/daffy"],
    },
    {
      word: "daffy",
      phonetic: "/ˈdæfi/",
      phonetics: [{ text: "/ˈdæfi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A daffodil.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/daffy"],
    },
  ],
  chits: [
    {
      word: "chits",
      phonetic: "/tʃɪts/",
      phonetics: [{ text: "/tʃɪts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A child or babe; a young, small, or insignificant person or animal.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A pert or sassy young person, especially a young woman.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The embryonic growing bud of a plant",
              synonyms: ["seedling", "shoot", "sprout"],
              antonyms: [],
              example: "the chits of Indian corn or of potatoes",
            },
            {
              definition: "An excrescence on the body, as a wart or a pimple.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["seedling", "shoot", "sprout"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To sprout; to shoot, as a seed or plant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To damage the outer layers of a seed such as Lupinus or Sophora to assist germination.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To initiate sprouting of tubers, such as potatoes, by placing them in special environment, before planting into the soil.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small sheet or scrap of paper with a hand-written note as a reminder or personal message.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A voucher or token coin used in payrolls under the truck system; scrip.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A small sheet of paper on which is written a prescription to be filled; a scrip.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A smaller cardboard counter generally used not to directly represent something but for another, more transient, purpose such as tracking or randomization.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A signed voucher or memorandum of a small debt, as for food and drinks at a club.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A debt or favor owed in return for a prior loan or favor granted, especially a political favor.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A small tool used in cleaving laths. Compare: froe.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/chit",
        "https://en.wiktionary.org/wiki/chits",
      ],
    },
  ],
  mufti: [
    {
      word: "mufti",
      phonetic: "/ˈmʊfti/",
      phonetics: [
        {
          text: "/ˈmʊfti/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mufti-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75648532",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A Muslim scholar and interpreter of shari’a law, who can deliver a fatwa.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A civilian dress when worn by a member of the military, or casual dress when worn by a pupil of a school who normally would wear uniform.",
              synonyms: ["civvies"],
              antonyms: [],
            },
          ],
          synonyms: ["civvies"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mufti"],
    },
  ],
  karma: [
    {
      word: "karma",
      phonetic: "[ˈkəɾm(ə)]",
      phonetics: [
        { text: "[ˈkəɾm(ə)]", audio: "" },
        { text: "[ˈkɑː.mə]", audio: "" },
        { text: "[ˈkɑɹ.mə]", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The sum total of a person's actions, which determine the person's next incarnation in samsara, the cycle of death and rebirth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A force or law of nature which causes one to reap what one sows; destiny; fate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A distinctive feeling, aura, or atmosphere.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Reddit) A score assigned to a user of a discussion forum, indicating the popularity of their posts with other users.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/karma"],
    },
  ],
  lotto: [
    {
      word: "lotto",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A game of chance similar to bingo",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A lottery", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lotto"],
    },
  ],
  burps: [
    {
      word: "burps",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "A belch.", synonyms: [], antonyms: [] }],
          synonyms: ["belch"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To emit a burp.", synonyms: [], antonyms: [] },
            {
              definition: "To cause someone (such as a baby) to burp.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/burp",
        "https://en.wiktionary.org/wiki/burps",
      ],
    },
  ],
  deuce: [
    {
      word: "deuce",
      phonetic: "/djuːs/",
      phonetics: [
        { text: "/djuːs/", audio: "" },
        {
          text: "/duːs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/deuce-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2650854",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A card with two pips, one of four in a standard deck of playing cards.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A side of a die with two spots.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A cast of dice totalling two.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The number two.", synonyms: [], antonyms: [] },
            {
              definition:
                "A tied game where either player can win by scoring two consecutive points.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A curveball.", synonyms: [], antonyms: [] },
            { definition: "A '32 Ford.", synonyms: [], antonyms: [] },
            {
              definition:
                "(in the plural) 2-barrel (twin choke) carburetors (in the phrase 3 deuces: an arrangement on a common intake manifold).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(restaurants) A table seating two diners.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A piece of excrement.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/deuce"],
    },
    {
      word: "deuce",
      phonetic: "/duːs/",
      phonetics: [
        { text: "/duːs/", audio: "" },
        { text: "/djuːs/", audio: "" },
        {
          text: "/duːs/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/deuce-2-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2650854",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(epithet) The Devil, used in exclamations of confusion or anger.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/deuce"],
    },
  ],
  zings: [
    {
      word: "zings",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A short high-pitched humming sound, such as that made by a bullet or vibrating string.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A witty insult or derogatory remark.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Zest or vitality.", synonyms: [], antonyms: [] },
            {
              definition: "Pleasant or exciting flavour of food.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To move very quickly, especially while making a high-pitched hum.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/zing",
        "https://en.wiktionary.org/wiki/zings",
      ],
    },
  ],
  kappa: [
    {
      word: "kappa",
      phonetic: "/ˈkæpə/",
      phonetics: [
        {
          text: "/ˈkæpə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/kappa-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=311306",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The tenth letter of the Greek alphabet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A measurement of the sensitivity of the value of an option to changes in the implied volatility of the price of the underlying asset.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/kappa"],
    },
    {
      word: "kappa",
      phonetic: "/ˈkæpə/",
      phonetics: [
        {
          text: "/ˈkæpə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/kappa-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=311306",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A tortoise-like creature in the Japanese mythology.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/kappa"],
    },
  ],
  clads: [
    {
      word: "clads",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(past tense clad) To clothe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(past tense clad or cladded) To cover (with insulation or another material); to surround, envelop.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(figuratively) To imbue (with a specified quality)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/clad",
        "https://en.wiktionary.org/wiki/clads",
      ],
    },
  ],
  doggy: [
    {
      word: "doggy",
      phonetic: "/ˈdɒ.ɡi/",
      phonetics: [
        {
          text: "/ˈdɒ.ɡi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/doggy-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88676988",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈdɑɡ.i/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A dog, especially a small one.",
              synonyms: [],
              antonyms: [],
              example: "That's such a cute little doggy, Keira!",
            },
            {
              definition: "Doggy style",
              synonyms: [],
              antonyms: [],
              example: "Her favourite position is doggy.",
            },
            {
              definition:
                "(armed services) A junior temporarily assigned to do minor duties for a senior; a gofer.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["pooch", "poochie", "pup", "puppy", "puppy dog"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/doggy"],
    },
    {
      word: "doggy",
      phonetic: "/ˈdɒ.ɡi/",
      phonetics: [
        {
          text: "/ˈdɒ.ɡi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/doggy-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88676988",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈdɑɡ.i/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Suggestive of or in the manner of a dog.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Fond of dogs.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/doggy"],
    },
  ],
  duper: [
    {
      word: "duper",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A person who dupes another",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/duper"],
    },
  ],
  scams: [
    {
      word: "scams",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A fraudulent deal.",
              synonyms: [],
              antonyms: [],
              example: "That marketing scheme looks like a scam to me.",
            },
            {
              definition: "Something that is promoted using scams.",
              synonyms: [],
              antonyms: [],
              example: "That car was a scam.",
            },
          ],
          synonyms: ["con game", "confidence trick", "swindle"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/scam",
        "https://en.wiktionary.org/wiki/scams",
      ],
    },
  ],
  ogler: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  mimes: [
    {
      word: "mimes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A form of acting without words; pantomime.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A pantomime actor.", synonyms: [], antonyms: [] },
            {
              definition:
                "A classical theatrical entertainment in the form of farce.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A performer of such a farce.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person who mimics others in a comical manner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various papilionid butterflies of the genus Chilasa or Papilio, that mimic other species in appearance.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To mimic.", synonyms: [], antonyms: [] },
            { definition: "To act without words.", synonyms: [], antonyms: [] },
            {
              definition:
                "To represent an action or object through gesture, without the use of sound.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mime",
        "https://en.wiktionary.org/wiki/mimes",
      ],
    },
  ],
  throe: [
    {
      word: "throe",
      phonetic: "/θɹəʊ/",
      phonetics: [
        {
          text: "/θɹəʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/throe-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89845020",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A pang, spasm.", synonyms: [], antonyms: [] },
            {
              definition: "(usually plural) A hard struggle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A tool for splitting wood into shingles; a frow.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To put in agony.", synonyms: [], antonyms: [] },
            {
              definition:
                "To struggle in extreme pain; to be in agony; to agonize.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/throe"],
    },
  ],
  zetas: [
    {
      word: "zetas",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The sixth letter of the modern Greek alphabet (Ζ, ζ) preceded by epsilon (Ε, ε) and followed by eta, (Η, η); or the seventh letter in the ancient Greek alphabet, in which it is preceded by digamma (Ϝ, ϝ)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A mathematical function formally known as the Riemann zeta function.",
              synonyms: [],
              antonyms: [],
              example: "Zeta of 3 is irrational",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/zeta",
        "https://en.wiktionary.org/wiki/zetas",
      ],
    },
  ],
  waled: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  promo: [
    {
      word: "promo",
      phonetic: "/ˈpɹəʊməʊ/",
      phonetics: [
        {
          text: "/ˈpɹəʊməʊ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/promo-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79218836",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An interview or monologue intended to promote a character or an upcoming match.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To promote; to publicize.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An advancement in rank or position.",
              synonyms: [],
              antonyms: ["demotion"],
              example: "I'll have to give myself a promotion!",
            },
            {
              definition:
                "Dissemination of information in order to increase its popularity.",
              synonyms: [],
              antonyms: [],
              example: "the promotion of the idea of global warming in schools",
            },
            {
              definition:
                "An event intended to increase the reach or image of a product or brand.",
              synonyms: [],
              antonyms: [],
              example:
                "The price cut is serving as a promotion of the manufacturer's new beverage varieties.",
            },
            {
              definition: "Forward motion. (Contrast remotion.)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["demotion"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/promo",
        "https://en.wiktionary.org/wiki/promotion",
      ],
    },
  ],
  blats: [
    {
      word: "blats",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cry, as a calf or sheep; to bleat.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make a senseless noise.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To talk inconsiderately.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To produce an overrich or overblown sound on a brass instrument such as a trumpet, trombone, or tuba.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/blat",
        "https://en.wiktionary.org/wiki/blats",
      ],
    },
  ],
  muffs: [
    {
      word: "muffs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A piece of fur or cloth, usually with open ends, used for keeping the hands warm.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Female pubic hair; female genitals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A blown cylinder of glass which is afterward flattened out to make a sheet.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The feathers sticking out from both sides of the face under the beak of some birds.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A short hollow cylinder surrounding an object such as a pipe.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["beard", "muff and beard", "whiskers"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A fool, a stupid or poor-spirited person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An error, a mistake; a failure to hold a ball when once in the hands.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A bird, the whitethroat.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To drop or mishandle (the ball, a catch etc.); to play badly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To mishandle; to bungle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A muffin.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/muff",
        "https://en.wiktionary.org/wiki/muffs",
      ],
    },
  ],
  oinks: [
    {
      word: "oinks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The sound made by a pig, or an imitation thereof.",
              synonyms: [],
              antonyms: [],
              example:
                "The protesters replied to the police officers' demands with a chorus of oinks.",
            },
          ],
          synonyms: ["grunt"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "Of a pig or in imitation thereof, to make its characteristic sound.",
              synonyms: [],
              antonyms: [],
              example:
                "The hogs oinked happily in their pen as the farmer poured slop in their feeding trough.",
            },
          ],
          synonyms: ["grunt"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/oink",
        "https://en.wiktionary.org/wiki/oinks",
      ],
    },
  ],
  viand: [
    {
      word: "viand",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An item of food eaten with rice.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/viand"],
    },
  ],
  coset: [
    {
      word: "coset",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The set that results from applying a group's binary operation with a given fixed element of the group on each element of a given subgroup.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/coset"],
    },
  ],
  finks: [
    {
      word: "finks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A contemptible person.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An informer.", synonyms: [], antonyms: [] },
            { definition: "A strikebreaker.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To betray a trust; to inform on.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["grass up", "inform", "snitch"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of several birds in the family Ploceidae native to southern Africa.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To ponder, to go over in one's head.",
              synonyms: [],
              antonyms: [],
              example:
                "Idly, the detective thought what his next move should be.",
            },
            {
              definition:
                "To communicate to oneself in one's mind, to try to find a solution to a problem.",
              synonyms: [],
              antonyms: [],
              example:
                "I thought for three hours about the problem and still couldn’t find the solution.",
            },
            {
              definition:
                "To conceive of something or someone (usually followed by of; infrequently, by on).",
              synonyms: [],
              antonyms: [],
              example: "I tend to think of her as rather ugly.",
            },
            {
              definition:
                "To be of opinion (that); to consider, judge, regard, or look upon (something) as.",
              synonyms: [],
              antonyms: [],
              example:
                "At the time I thought his adamant refusal to give in right.",
            },
            {
              definition: "To guess; to reckon.",
              synonyms: [],
              antonyms: [],
              example: "I think she’ll pass the examination.",
            },
            {
              definition:
                "To plan; to be considering; to be of a mind (to do something).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To presume; to venture.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["guess"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To seem, to appear.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/fink",
        "https://en.wiktionary.org/wiki/finks",
        "https://en.wiktionary.org/wiki/think",
      ],
    },
  ],
  faddy: [
    {
      word: "faddy",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having characteristics of a fad.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Fussy, having particular tastes or whims",
              synonyms: [],
              antonyms: [],
              example: "He's a faddy eater.",
            },
          ],
          synonyms: ["faddish"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/faddy"],
    },
  ],
  sauna: [
    {
      word: "sauna",
      phonetic: "/ˈsaʊ.nə/",
      phonetics: [
        {
          text: "/ˈsaʊ.nə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sauna-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=49875273",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A room or a house designed for heat sessions.",
              synonyms: [],
              antonyms: [],
              example: "The hotel has a sauna in the basement.",
            },
            {
              definition: "The act of using a sauna.",
              synonyms: [],
              antonyms: [],
              example: "John had a sauna after his swim.",
            },
            {
              definition:
                "A public sauna; a front for a brothel in some countries.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["firebath"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To use a sauna.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sauna"],
    },
  ],
  craws: [
    {
      word: "craws",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The stomach of an animal.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The crop of a bird.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["crop", "gullet"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/craw",
        "https://en.wiktionary.org/wiki/craws",
      ],
    },
  ],
  stats: [
    {
      word: "stats",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stats-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79268971",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A mathematical science concerned with data collection, presentation, analysis, and interpretation.",
              synonyms: [],
              antonyms: [],
              example:
                "Statistics is the only mathematical field required for many social sciences.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A systematic collection of data on measurements or observations, often related to demographic information such as population counts, incomes, population counts at different ages, etc.",
              synonyms: ["stats"],
              antonyms: [],
              example:
                "The statistics from the Census for apportionment are available.",
            },
          ],
          synonyms: ["stats"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Attributes of a unit in a game (e.g. health, damage output)",
              synonyms: [],
              antonyms: [],
              example: "Those items he bought really boosted his stats.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/statistics",
        "https://en.wiktionary.org/wiki/stats",
      ],
    },
  ],
  condo: [
    {
      word: "condo",
      phonetic: "/ˈkɒndəʊ/",
      phonetics: [
        { text: "/ˈkɒndəʊ/", audio: "" },
        { text: "/ˈkɑndoʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Joint sovereignty over a territory by two or more countries.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A region or territory under such rule.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A building in which each unit is owned by an individual but the grounds, structure etc are owned jointly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The system of ownership by which such condominiums operate",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A unit or apartment in such a complex.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The legal tenure involved.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["condo building", "strata", "condo"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/condo",
        "https://en.wiktionary.org/wiki/condominium",
      ],
    },
  ],
  coxes: [
    {
      word: "coxes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A coxswain of a boat, especially of a racing crew.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cox",
        "https://en.wiktionary.org/wiki/coxes",
      ],
    },
  ],
  loopy: [
    {
      word: "loopy",
      phonetic: "/ˈluːpi/",
      phonetics: [
        {
          text: "/ˈluːpi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/loopy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79268151",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having loops.",
              synonyms: [],
              antonyms: [],
              example: "A loopy rollercoaster.",
            },
            {
              definition: "Idiotic, crazy or drunk.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["looped"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/loopy"],
    },
  ],
  dorms: [
    {
      word: "dorms",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A room containing a number of beds (and often some other furniture and/or utilities) for sleeping, often applied to student and backpacker accommodation of this kind.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A building or part of a building which houses students, soldiers, monks etc. who sleep there and use communal further facilities.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A dormitory town.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["dorm"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dorm",
        "https://en.wiktionary.org/wiki/dormitory",
        "https://en.wiktionary.org/wiki/dorms",
      ],
    },
  ],
  dippy: [
    {
      word: "dippy",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Lacking common sense.", synonyms: [], antonyms: [] },
            {
              definition:
                "Having romantic feelings for; excited or enthusiastic about.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of an egg: cooked so that the yolk remains runny and can be used for dipping.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Involving or suited to dipping.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dotty"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dippy"],
    },
  ],
  execs: [
    {
      word: "execs",
      phonetic: "/ekˈseks/",
      phonetics: [{ text: "/ekˈseks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Executive, executive officer",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/exec",
        "https://en.wiktionary.org/wiki/execs",
      ],
    },
  ],
  dopey: [
    {
      word: "dopey",
      phonetic: "/ˈdəʊpi/",
      phonetics: [{ text: "/ˈdəʊpi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Stupid, silly.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dopey"],
    },
  ],
  envoi: [
    {
      word: "envoi",
      phonetic: "/ˈɛn.vɔɪ/",
      phonetics: [
        { text: "/ˈɛn.vɔɪ/", audio: "" },
        { text: "/ˈɑn.vɔɪ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A short stanza at the end of a poem, used either to address a person or to comment on the preceding body of the poem.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/envoi"],
    },
  ],
  gismo: [
    {
      word: "gismo",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Something, generally a device, for which one does not know the proper term.",
              synonyms: ["gadget", "thingamajig", "whatchamacallit"],
              antonyms: [],
            },
          ],
          synonyms: ["gadget", "thingamajig", "whatchamacallit"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gismo",
        "https://en.wiktionary.org/wiki/gizmo",
      ],
    },
  ],
  fazed: [
    {
      word: "fazed",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To frighten or cause hesitation; to daunt, put off (usually used in the negative); to disconcert, to perturb.",
              synonyms: [],
              antonyms: [],
              example:
                "Jumping out of an airplane does not faze him, yet he is afraid to ride a roller coaster.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Hesitant, frightened; daunted, disconcerted; perturbed, put off (usually used in the negative).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/faze",
        "https://en.wiktionary.org/wiki/fazed",
      ],
    },
  ],
  strop: [
    {
      word: "strop",
      phonetic: "/stɹɒp/",
      phonetics: [
        {
          text: "/stɹɒp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/strop-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241806",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A strap; more specifically a piece of leather or a substitute (notably canvas), or strip of wood covered with a suitable material, for honing a razor, in this sense also called razor strop.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A bad mood or temper (see stroppy.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of rope spliced into a circular wreath, and put round a block for hanging it.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["huff"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To strap.", synonyms: [], antonyms: [] },
            {
              definition:
                "(recorded since 1842; now most used) To hone (a razor) with a strop.",
              synonyms: [],
              antonyms: [],
              example: "One should strop the razor before each shave.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/strop"],
    },
    {
      word: "strop",
      phonetic: "/stɹɒp/",
      phonetics: [
        {
          text: "/stɹɒp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/strop-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241806",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To mark a sequence of letters syntactically as having a special property, such as being a keyword, e.g. by enclosing in apostrophes as in 'foo' or writing in uppercase as in FOO.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/strop"],
    },
    {
      word: "strop",
      phonetic: "/stɹɒp/",
      phonetics: [
        {
          text: "/stɹɒp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/strop-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241806",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A poor-quality or unsaleable diamond.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/strop"],
    },
  ],
  jives: [
    {
      word: "jives",
      phonetic: "/dʒaɪvz/",
      phonetics: [{ text: "/dʒaɪvz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To reproach with contemptuous words; to deride, to mock, to taunt.",
              synonyms: ["flout"],
              antonyms: [],
            },
            {
              definition: "To say in a mocking or taunting manner.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make a mocking remark or remarks; to jeer.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["flout"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To accord or agree.",
              synonyms: [],
              antonyms: [],
              example: "That explanation doesn’t jibe with the facts.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "Alternative spelling of gybe",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A dance style popular in the 1940–50s.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Swing, a style of jazz music.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A slang associated with jazz musicians; hepcat patois or hipster jargon.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Nonsense; transparently deceptive talk.",
              synonyms: [],
              antonyms: [],
              example:
                "Don’t give me that jive. I know where you were last night.",
            },
            {
              definition: "African-American Vernacular English.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To deceive; to be deceptive.",
              synonyms: [],
              antonyms: [],
              example:
                "Don’t try to jive me! I know where you were last night!",
            },
            { definition: "To dance.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/jibe",
        "https://en.wiktionary.org/wiki/jive",
        "https://en.wiktionary.org/wiki/jives",
      ],
    },
  ],
  slims: [
    {
      word: "slims",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A type of cigarette substantially longer and thinner than normal cigarettes.",
              synonyms: [],
              antonyms: [],
              example: "I only smoke slims.",
            },
            { definition: "A potato farl.", synonyms: [], antonyms: [] },
            {
              definition:
                "AIDS, or the chronic wasting associated with its later stages.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Cocaine.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To lose weight in order to achieve slimness.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make slimmer; to reduce in size.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/slim",
        "https://en.wiktionary.org/wiki/slims",
      ],
    },
  ],
  batik: [
    {
      word: "batik",
      phonetic: "/bəˈtiːk/",
      phonetics: [
        {
          text: "/bəˈtiːk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/batik-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1161560",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A wax-resist method of dyeing fabric.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To dye fabric using the wax-resist method.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/batik"],
    },
  ],
  pings: [
    {
      word: "pings",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A high-pitched, short and somewhat sharp sound.",
              synonyms: [],
              antonyms: [],
              example:
                "My car used to make an odd ping, but after the last oil change it went away.",
            },
            {
              definition:
                "(submarine navigation) A pulse of high-pitched or ultrasonic sound whose echoes provide information about nearby objects and vessels.",
              synonyms: [],
              antonyms: [],
              example:
                "The submarine sent out a ping and got an echo from a battleship.",
            },
            {
              definition:
                "A packet which a remote host is expected to echo, thus indicating its presence.",
              synonyms: [],
              antonyms: [],
              example:
                "The network is overloaded from all the pings going out.",
            },
            {
              definition:
                "An email or other message sent requesting acknowledgement.",
              synonyms: [],
              antonyms: [],
              example:
                "I sent a ping to the insurance company to see if they received our claim.",
            },
            { definition: "Latency.", synonyms: [], antonyms: [] },
            {
              definition:
                "A means of highlighting a feature on the game map so that allied players can see it.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(WMF jargon) A notification.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make a high-pitched, short and somewhat sharp sound.",
              synonyms: [],
              antonyms: [],
              example: "My car was pinging until my last oil change.",
            },
            {
              definition:
                "(submarine navigation) To emit a signal and then listen for its echo in order to detect objects.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To send a packet in order to determine whether a host is present, particularly by use of the ping utility.",
              synonyms: [],
              antonyms: [],
              example: "I'm pinging their server.",
            },
            {
              definition:
                "(by extension) To send an email or other message to someone in hopes of eliciting a response.",
              synonyms: [],
              antonyms: [],
              example:
                "I'll ping the insurance company again to see if they've received our claim.",
            },
            {
              definition: "To flick.",
              synonyms: [],
              antonyms: [],
              example: "I pinged the crumb off the table with my finger.",
            },
            {
              definition: "To bounce.",
              synonyms: [],
              antonyms: [],
              example: "The ball pinged off the wall and came hurtling back.",
            },
            {
              definition: "To cause something to bounce.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To call out audibly.", synonyms: [], antonyms: [] },
            {
              definition: "To penalize.",
              synonyms: [],
              antonyms: [],
              example:
                "Gary Ablett was pinged for holding the ball and gave away a free kick.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ping",
        "https://en.wiktionary.org/wiki/pings",
      ],
    },
  ],
  sonly: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  leggo: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  pekoe: [
    {
      word: "pekoe",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A high-quality black tea made using young leaves, grown in Sri Lanka, India, Java and the Azores.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pekoe"],
    },
  ],
  prawn: [
    {
      word: "prawn",
      phonetic: "/pɹoːn/",
      phonetics: [
        {
          text: "/pɹoːn/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/prawn-1-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75649223",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/pɹɒːn/", audio: "" },
        { text: "/pɹoːn/", audio: "" },
        { text: "/pɹɔːn/", audio: "" },
        { text: "/pɹɒːn/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A crustacean of the suborder Dendrobranchiata.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A crustacean sometimes confused with shrimp.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A woman with a very toned body, but an unattractive face.",
              synonyms: ["butterface", "tip drill"],
              antonyms: [],
              example: "She's a prawn!",
            },
            { definition: "A fool, an idiot.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["butterface", "tip drill"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To fish for prawns.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/prawn"],
    },
    {
      word: "prawn",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Pornography.",
              synonyms: ["erotica", "filth", "smut"],
              antonyms: [],
              example:
                "I just delete the porn before someone else uses the computer.",
            },
            {
              definition: "(chiefly in the plural) A pornographic work.",
              synonyms: [
                "X-rated movie",
                "adult movie",
                "blue movie",
                "dirty movie",
                "skin flick",
                "stag movie",
              ],
              antonyms: [],
            },
            {
              definition:
                "Material, usually visual, presenting something desirable in a sensational manner.",
              synonyms: [],
              antonyms: [],
              example: "car porn",
            },
            {
              definition:
                "Material that provides illicit gratification of an obsessive or unhealthy interest in something.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "X-rated movie",
            "adult movie",
            "blue movie",
            "dirty movie",
            "skin flick",
            "stag movie",
            "erotica",
            "filth",
            "smut",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/porn",
        "https://en.wiktionary.org/wiki/prawn",
      ],
    },
  ],
  luaus: [
    {
      word: "luaus",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An elaborate Hawaiian feast featuring traditional foods and entertainment.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/luau",
        "https://en.wiktionary.org/wiki/luaus",
      ],
    },
  ],
  campy: [
    {
      word: "campy",
      phonetic: "/ˈkæmpi/",
      phonetics: [{ text: "/ˈkæmpi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Characterized by camp or kitsch, especially when deliberate or intentional.",
              synonyms: [],
              antonyms: [],
              example:
                "Stu liked to watch campy B-movie horror flicks from the 1950s, not because they scared him, but because he found them funny.",
            },
          ],
          synonyms: ["camp", "corny", "hammy", "silly", "tawdry", "vulgar"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/campy"],
    },
  ],
  oodle: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  prexy: [
    {
      word: "prexy",
      phonetic: "/ˈpɹɛksi/",
      phonetics: [
        {
          text: "/ˈpɹɛksi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/prexy-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=90980331",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A president, especially of a college or university.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/prexy"],
    },
  ],
  proms: [
    {
      word: "proms",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A promenade concert", synonyms: [], antonyms: [] },
            {
              definition: "(abbreviation) promenade",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A formal ball held at a high school or college on special occasions, e.g. near the end of the academic year",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/prom",
        "https://en.wiktionary.org/wiki/proms",
      ],
    },
  ],
  touts: [
    {
      word: "touts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Someone advertising for customers in an aggressive way.",
              synonyms: [],
              antonyms: [],
              example:
                "Be careful of the ticket touts outside the arena, they are famed for selling counterfeits.",
            },
            {
              definition:
                "A person, at a racecourse, who offers supposedly inside information on which horse is likely to win.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A spy for a smuggler, thief, or similar.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An informer in the Irish Republican Army.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["barker", "pitchman", "spruiker"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To flaunt, to publicize/publicise; to boast or brag; to promote.",
              synonyms: [],
              antonyms: [],
              example:
                "Mary has been touted as a potential succesor to the current COE.",
            },
            {
              definition: "To look upon or watch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To spy out information about (a horse, a racing stable, etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To give a tip on (a racehorse) to a person, with the expectation of sharing in any winnings.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To spy out the movements of racehorses at their trials, or to get by stealth or other improper means the secrets of the stable, for betting purposes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To act as a tout; to give a tip on a racehorse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To look for, try to obtain; used with for.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["pimp", "pitch", "promote", "spruik"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tout",
        "https://en.wiktionary.org/wiki/touts",
      ],
    },
  ],
  ogles: [
    {
      word: "ogles",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An impertinent, flirtatious, amorous or covetous stare.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(usually in the plural) An eye.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To stare at (someone or something), especially impertinently, amorously, or covetously.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ogle",
        "https://en.wiktionary.org/wiki/ogles",
      ],
    },
  ],
  tweet: [
    {
      word: "tweet",
      phonetic: "/twiːt/",
      phonetics: [
        {
          text: "/twiːt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tweet-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=33142672",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The sound of a bird; any short high-pitched sound or whistle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An entry posted on the microblogging service Twitter.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make a short high-pitched sound, like that of certain birds.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To post an update to Twitter.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "An onomatopoeic of bird singing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tweet"],
    },
  ],
  toady: [
    {
      word: "toady",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sycophant who flatters others to gain personal advantage or an obsequious lackey or minion",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A coarse, rustic woman.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(construed with to) To behave like a toady (to someone).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/toady"],
    },
    {
      word: "toady",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [{ definition: "Toadlike", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/toady"],
    },
  ],
  hider: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  nuked: [
    {
      word: "nuked",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To use a nuclear weapon on a target.",
              synonyms: [],
              antonyms: [],
              example:
                "If a nuclear war ever breaks out, military facilities are likely to be nuked first.",
            },
            {
              definition: "To destroy or erase completely.",
              synonyms: ["annihilate", "devastate", "obliterate"],
              antonyms: [],
              example:
                "To try to hide his posting history on Usenet, he had his posts nuked from the Google archives.",
            },
            {
              definition:
                "(by extension) To carry out a denial-of-service attack against (an IRC user).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To expose to some form of radiation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cook in a microwave oven.",
              synonyms: [],
              antonyms: [],
              example: "I’ll nuke some pizza for dinner.",
            },
            {
              definition: "To over-analyze or overly despair over something.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["annihilate", "devastate", "obliterate"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/nuke",
        "https://en.wiktionary.org/wiki/nuked",
      ],
    },
  ],
  obits: [
    {
      word: "obits",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Death of a person.", synonyms: [], antonyms: [] },
            {
              definition:
                "A mass or other service held for the soul of a dead person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A record of a person's death.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "An obituary.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/obit",
        "https://en.wiktionary.org/wiki/obits",
      ],
    },
  ],
  narcs: [
    {
      word: "narcs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A narcotics squad police officer.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(underwater diving) To suffer from impaired judgment due to nitrogen narcosis (for example, while scuba diving).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A narcissist.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A police spy or informer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An unpleasant person, especially one who makes things difficult for others.",
              synonyms: ["spoilsport"],
              antonyms: [],
            },
          ],
          synonyms: ["spoilsport"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To watch; to observe.", synonyms: [], antonyms: [] },
            {
              definition: "To serve or behave as a spy or informer.",
              synonyms: ["rat", "tattle"],
              antonyms: [],
              example:
                "“If you nark on me, I’ll rip your arms off,” said Tim to his brother, as he passed him a joint.",
            },
            {
              definition: "To annoy or irritate.",
              synonyms: [],
              antonyms: [],
              example: "It really narks me when people smoke in restaurants.",
            },
            {
              definition: "To complain.",
              synonyms: [],
              antonyms: [],
              example:
                "He narks in my ear all day, moaning about his problems.",
            },
            {
              definition: "(often imperative) To stop.",
              synonyms: [],
              antonyms: [],
              example: "Nark it! I hear someone coming!",
            },
          ],
          synonyms: ["rat", "tattle"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/narc",
        "https://en.wiktionary.org/wiki/narcs",
        "https://en.wiktionary.org/wiki/nark",
      ],
    },
  ],
  tyros: [
    {
      word: "tyros",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A beginner; a novice.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tyro",
        "https://en.wiktionary.org/wiki/tyros",
      ],
    },
  ],
  wooer: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  hyped: [
    {
      word: "hyped",
      phonetic: "/haɪpt/",
      phonetics: [{ text: "/haɪpt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To throw (an opponent) using this technique.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To promote heavily; to advertise or build up.",
              synonyms: [],
              antonyms: [],
              example:
                "They started hyping the new magazine months before its release.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Having been subject to propaganda and promotion; promoted beyond what is reasonable or appropriate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Excited.",
              synonyms: [],
              antonyms: [],
              example:
                "He's hyped about getting to kiss her in the final scene of the play.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hipe",
        "https://en.wiktionary.org/wiki/hype",
        "https://en.wiktionary.org/wiki/hyped",
      ],
    },
  ],
  byway: [
    {
      word: "byway",
      phonetic: "/ˈbaɪweɪ/",
      phonetics: [{ text: "/ˈbaɪweɪ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A road that is not frequently travelled",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) an unpopular or arcane field of study",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/byway"],
    },
  ],
  texas: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  avows: [
    {
      word: "avows",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To declare openly and boldly, as something believed to be right; to own, acknowledge or confess frankly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To bind or devote by a vow.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To acknowledge and justify, as an act done. See avowry.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["disavow"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/avow",
        "https://en.wiktionary.org/wiki/avows",
      ],
    },
  ],
  futon: [
    {
      word: "futon",
      phonetic: "/ˈfuːtɒn/",
      phonetics: [
        { text: "/ˈfuːtɒn/", audio: "" },
        { text: "/ˈfutɑn/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A thin mattress of tufted cotton or similar material, placed on a floor or on a raised, foldable frame as a bed.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A round cushion used for Zen meditation, traditionally made of woven bulrush leaves.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/futon"],
    },
  ],
  torte: [
    {
      word: "torte",
      phonetic: "/ˈtɔː(ɹ)t/",
      phonetics: [
        {
          text: "/ˈtɔː(ɹ)t/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/torte-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=877600",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A rich, dense cake, typically made with many eggs and relatively little flour (as opposed to a sponge cake or gâteau).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/torte"],
    },
  ],
  tuple: [
    {
      word: "tuple",
      phonetic: "/tuːp(ə)l/",
      phonetics: [{ text: "/tuːp(ə)l/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A finite sequence of terms.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A single row in a relational database.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A set of comma-separated values passed to a program or operating system as a parameter to a function call.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In some programming languages, a data type that is similar but distinct from the list data type, whose instances are characterized by having a rather fixed arity, and the elements of which instances can differ from each other by data type. (Note: this definition may overlap with the previous one.)",
              synonyms: [],
              antonyms: [],
              example:
                "Both Python and Haskell have a tuple data type as well as a list data type.",
            },
          ],
          synonyms: ["n-tuple", "ordered pair", "triple", "triplet"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tuple"],
    },
  ],
  carom: [
    {
      word: "carom",
      phonetic: "/ˈkæɹəm/",
      phonetics: [{ text: "/ˈkæɹəm/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(cue sports, especially billiards) A shot in which the ball struck with the cue comes in contact with two or more balls on the table; a hitting of two or more balls with the player's ball.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A billiard-like Indian game in which players take turns flicking checker-like pieces into one of four goals on the corners of a (one meter by one meter square) board.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["cannon"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make a carom (shot in billiards).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To strike and bounce back; to strike (something) and rebound.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/carom"],
    },
    {
      word: "carom",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "(spices) ajwain", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/carom"],
    },
  ],
  kebab: [
    {
      word: "kebab",
      phonetic: "/kəˈbæb/",
      phonetics: [
        {
          text: "/kəˈbæb/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/kebab-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241320",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/kɪˈbæb/", audio: "" },
        { text: "/kɪˈbɑb/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A dish of pieces of meat, fish, or vegetables roasted on a skewer or spit, especially a doner kebab.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A shish kebab or any other food on a skewer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The outward growing portions of a shish kebab structure.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A Muslim, usually of southern European, Middle Eastern, or North African descent.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The vulva.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To roast in the style of a kebab",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To stab or skewer", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/kebab"],
    },
  ],
  tamps: [
    {
      word: "tamps",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(blasting) To plug up with clay, earth, dry sand, sod, or other material, as a hole bored in a rock.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To drive in or pack down by frequent gentle strokes",
              synonyms: [],
              antonyms: [],
              example: "Tamp earth so as to make a smooth place.",
            },
            {
              definition: "To reduce the intensity of.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tamp",
        "https://en.wiktionary.org/wiki/tamps",
      ],
    },
  ],
  jilts: [
    {
      word: "jilts",
      phonetic: "/dʒɪlts/",
      phonetics: [{ text: "/dʒɪlts/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A woman who jilts a lover.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cast off capriciously or unfeelingly, as a lover; to deceive in love.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/jilt",
        "https://en.wiktionary.org/wiki/jilts",
      ],
    },
  ],
  duals: [
    {
      word: "duals",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Of an item that is one of a pair, the other item in the pair.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of a regular polyhedron with V vertices and F faces, the regular polyhedron having F vertices and V faces.",
              synonyms: [],
              antonyms: [],
              example: "The octahedron is the dual of the cube.",
            },
            {
              definition:
                '(grammar) dual number The grammatical number of a noun marking two of something (as in singular, dual, plural), sometimes referring to two of anything (a couple of, exactly two of), or a chirality-marked pair (as in left and right, as with gloves or shoes) or in some languages as a discourse marker, "between you and me". A few languages display trial number.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of a vector in an inner product space, the linear functional corresponding to taking the inner product with that vector. The set of all duals is a vector space called the dual space.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dual",
        "https://en.wiktionary.org/wiki/duals",
      ],
    },
  ],
  artsy: [
    {
      word: "artsy",
      phonetic: "/ˈɑɹtsi/",
      phonetics: [{ text: "/ˈɑɹtsi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(sometimes derogatory) Inclined towards the arts; arty.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/artsy"],
    },
  ],
  repro: [
    {
      word: "repro",
      phonetic: "/ˈɹiːpɹəʊ/",
      phonetics: [
        { text: "/ˈɹiːpɹəʊ/", audio: "" },
        { text: "/ˈɹipɹoʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The proof prepared in offset printing, with all elements positioned on the page.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To reproduce (a bug or problem).",
              synonyms: [],
              antonyms: [],
              example:
                "We cannot repro the bug in the latest version, so we are marking it as fixed.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The act of reproducing new individuals biologically.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The act of making copies.",
              synonyms: [],
              antonyms: [],
              example:
                "Unauthorized reproduction of this article is prohibited.",
            },
            {
              definition:
                "A copy of something, as in a piece of art; a duplicate.",
              synonyms: [],
              antonyms: [],
              example: "Jim was proud of the Rembrandt reproduction he owned.",
            },
          ],
          synonyms: ["procreation", "repro"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The reproduction, reprinting and copying of graphics, especially using electromechanical or photographic methods",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/repro",
        "https://en.wiktionary.org/wiki/reproduction",
        "https://en.wiktionary.org/wiki/reprography",
      ],
    },
  ],
  modem: [
    {
      word: "modem",
      phonetic: "/ˈməʊdəm/",
      phonetics: [
        { text: "/ˈməʊdəm/", audio: "" },
        { text: "/ˈmoʊdəm/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A device that encodes digital computer signals into analog/analogue telephone signals and vice versa and allows computers to communicate over a phone line.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To transmit by modem.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/modem"],
    },
  ],
  toped: [
    {
      word: "toped",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To drink excessively; to get drunk.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tope",
        "https://en.wiktionary.org/wiki/toped",
      ],
    },
  ],
  psych: [
    {
      word: "psych",
      phonetic: "/saɪk/",
      phonetics: [
        {
          text: "/saɪk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/psych-ca.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=53044605",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Psychology or psychiatry.",
              synonyms: [],
              antonyms: [],
              example: "a psych class",
            },
            {
              definition: "A psychologist; a psychiatrist.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To put (someone) into a required psychological frame of mind (also psych up).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To intimidate (someone) emotionally or using psychology (also psych out).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To treat (someone) using psychoanalysis.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Psychedelic.",
              synonyms: [],
              antonyms: [],
              example: "curtains with psych colors",
            },
            { definition: "Psychiatric.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition:
                "Indicating that one's preceding statement was false and that one has successfully fooled one's interlocutor. Also sike.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An interjection of surprised happiness.",
              synonyms: [],
              antonyms: [],
              example: "Psych! I just found my missing bracelet!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/psych"],
    },
  ],
  sicko: [
    {
      word: "sicko",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sicko-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75649854",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈsɪkəʊ/", audio: "" },
        { text: "/ˈsɪkoʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A person with unpleasant tastes, views or habits.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A mentally ill person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A physically ill person.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["sick day", "sickie", "weirdo"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Characterized by depraved tastes or habits; deviant.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sicko"],
    },
  ],
  tarns: [
    {
      word: "tarns",
      phonetic: "/tɑː(ɹ)nz/",
      phonetics: [
        {
          text: "/tɑː(ɹ)nz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tarns-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89747621",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small mountain lake, especially in Northern England.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(chiefly Montana) One of many small mountain lakes or ponds.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tarn",
        "https://en.wiktionary.org/wiki/tarns",
      ],
    },
  ],
  coxed: [
    {
      word: "coxed",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To act as coxswain for.",
              synonyms: [],
              antonyms: [],
              example: "I coxed the lightweight 4+ yesterday.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Having a cox", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cox",
        "https://en.wiktionary.org/wiki/coxed",
      ],
    },
  ],
  drays: [
    {
      word: "drays",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A low horse-drawn cart, often without sides, and used especially for heavy loads.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A kind of sledge or sled.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A squirrel’s nest, built of twigs in a tree.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A possum’s nest, built of twigs and leaves in a tree.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dray",
        "https://en.wiktionary.org/wiki/drays",
        "https://en.wiktionary.org/wiki/drey",
      ],
    },
  ],
  cloys: [
    {
      word: "cloys",
      phonetic: "/klɔɪz/",
      phonetics: [{ text: "/klɔɪz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To fill up or choke up; to stop up.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To clog, to glut, or satisfy, as the appetite; to satiate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fill to loathing; to surfeit.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "block",
            "block up",
            "choke",
            "fill",
            "fill up",
            "stop up",
            "stuff",
            "stuff up",
            "jade",
            "nauseate",
            "pall",
            "sicken",
            "surfeit",
            "fill up",
            "glut",
            "gorge",
            "sate",
            "satiate",
            "satisfy",
            "stodge",
            "stuff",
            "stuff up",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cloy",
        "https://en.wiktionary.org/wiki/cloys",
      ],
    },
  ],
  anded: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  piker: [
    {
      word: "piker",
      phonetic: "/ˈpaɪkə(ɹ)/",
      phonetics: [
        {
          text: "/ˈpaɪkə(ɹ)/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/piker-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75648934",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A soldier armed with a pike, a pikeman.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One who bets or gambles only with small amounts of money.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A stingy person; a cheapskate.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An amateur.", synonyms: [], antonyms: [] },
            {
              definition:
                "One who refuses to go out with friends, or leaves a party early.",
              synonyms: [],
              antonyms: [],
              example:
                "Mate, don't be a piker! Come to Angie′s birthday party tonight!",
            },
            {
              definition: "One who pikes (quits or backs out of a promise).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A male freshman at Cornell University.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A tramp; a vagrant.",
              synonyms: ["pike"],
              antonyms: [],
            },
          ],
          synonyms: ["pike"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/piker"],
    },
  ],
  aimer: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  suras: [
    {
      word: "suras",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Any of the 114 chapters of the Qur'an.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sura",
        "https://en.wiktionary.org/wiki/suras",
      ],
    },
  ],
  limos: [
    {
      word: "limos",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An automobile body with seats and permanent top like a coupe, and with the top projecting over the driver and a projecting front.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An automobile with such a body.",
              synonyms: ["limo (slang)"],
              antonyms: [],
            },
            {
              definition:
                "A luxury sedan or saloon car, especially one with a lengthened wheelbase or driven by a chauffeur.",
              synonyms: ["limo (slang)"],
              antonyms: [],
            },
            {
              definition:
                "An automobile for transportation to or from an airport, including sedans, vans, and buses.",
              synonyms: ["shuttle"],
              antonyms: [],
            },
          ],
          synonyms: ["limo (slang)", "limo (slang)", "shuttle"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/limo",
        "https://en.wiktionary.org/wiki/limos",
        "https://en.wiktionary.org/wiki/limousine",
      ],
    },
  ],
  flack: [
    {
      word: "flack",
      phonetic: "/flæk/",
      phonetics: [
        {
          text: "/flæk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/flack-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75796822",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To flutter; palpitate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To hang loosely; flag.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To beat by flapping.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/flack"],
    },
    {
      word: "flack",
      phonetic: "/flæk/",
      phonetics: [
        {
          text: "/flæk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/flack-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75796822",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A publicist, a publicity agent.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To publicise, to promote.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/flack"],
    },
    {
      word: "flack",
      phonetic: "/flæk/",
      phonetics: [
        {
          text: "/flæk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/flack-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75796822",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Ground-based anti-aircraft guns firing explosive shells.",
              synonyms: ["AAA", "ack-ack", "triple-A"],
              antonyms: [],
            },
            {
              definition: "Anti-aircraft shell fire.",
              synonyms: ["ack-ack"],
              antonyms: [],
            },
            { definition: "Adverse criticism.", synonyms: [], antonyms: [] },
            {
              definition: "A public-relations spokesperson.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["AAA", "ack-ack", "triple-A", "ack-ack"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/flack",
        "https://en.wiktionary.org/wiki/flak",
      ],
    },
  ],
  dutch: [
    {
      word: "dutch",
      phonetic: "/dʌtʃ/",
      phonetics: [
        {
          text: "/dʌtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dutch-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1368338",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
        {
          text: "/dʌtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dutch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=12026",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "Wife", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dutch"],
    },
    {
      word: "dutch",
      phonetic: "/dʌtʃ/",
      phonetics: [
        {
          text: "/dʌtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dutch-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1368338",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
        {
          text: "/dʌtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/dutch-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=12026",
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To treat cocoa beans or powder with an alkali solution to darken the color and lessen the bitterness of the flavor.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/Dutch",
        "https://en.wiktionary.org/wiki/dutch",
      ],
    },
  ],
  mucky: [
    {
      word: "mucky",
      phonetic: "/ˈmʌki/",
      phonetics: [
        {
          text: "/ˈmʌki/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mucky-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88681247",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Covered in muck.", synonyms: [], antonyms: [] },
            {
              definition: "Obscene, pornographic.",
              synonyms: [],
              antonyms: [],
              example: "a mucky magazine",
            },
          ],
          synonyms: ["dirty", "filthy", "muddy", "X-rated", "adult", "blue"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mucky"],
    },
  ],
  shire: [
    {
      word: "shire",
      phonetic: "/ʃaɪə(ɹ)/",
      phonetics: [
        {
          text: "/ʃaɪə(ɹ)/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/shire-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88676980",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ʃaɪəɹ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Physical area administered by a sheriff.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Former administrative area of Britain; a county.",
              synonyms: [],
              antonyms: [],
              example: "Yorkshire is the largest shire in England.",
            },
            {
              definition:
                "The general area in which a person lives or comes from, used in the context of travel within the United Kingdom.",
              synonyms: [],
              antonyms: [],
              example: "When are you coming back to the shire?",
            },
            {
              definition:
                "A rural or outer suburban local government area of Australia.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A shire horse.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To (re)constitute as one or more shires or counties.",
              synonyms: [],
              antonyms: [],
              example: "County Longford was shired in 1586",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/shire"],
    },
  ],
  klieg: [
    {
      word: "klieg",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of several intense arc lamps used in cinematography",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/klieg"],
    },
  ],
  staph: [
    {
      word: "staph",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Staphylococcus bacteria and the infection it causes.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/staph"],
    },
  ],
  layup: [
    {
      word: "layup",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A close-range shot in which the shooter banks the ball off the backboard from a few feet away.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A relatively easy task.",
              synonyms: [],
              antonyms: [],
              example:
                "Meeting the numbers will be a layup, if not a slam dunk.",
            },
            {
              definition: "The state of being laid up.",
              synonyms: [],
              antonyms: [],
              example:
                "The ship endured an interminable layup in the harbor lasting nearly a month.",
            },
            {
              definition: "A track used to store train cars.",
              synonyms: [],
              antonyms: [],
              example:
                "The caboose, long decrepit, rested on a forgotten layup just north of the dry riverbed.",
            },
            {
              definition:
                "A train car sitting in storage (laid up), often overnight.",
              synonyms: [],
              antonyms: [],
              example:
                "Though I knew we shouldn't be there, she pulled me out of the tunnel and into the dark layup, its floors still grubby from the morning commute many hours ago.",
            },
            {
              definition:
                "The process of applying alternate layers of a material and a binding agent to form a composite material.",
              synonyms: [],
              antonyms: [],
              example:
                "Forgetting to clear the sawdust around his workshop, Payton ended up contaminating the resin-ply matrix with wood particles during his hand layup.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/layup"],
    },
  ],
  tokes: [
    {
      word: "tokes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(casinos) A gratuity.",
              synonyms: [],
              antonyms: [],
              example: "I gave the maitre d’ a $10 toke and he just laughed.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A puff of marijuana.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A piece of bread.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/toke",
        "https://en.wiktionary.org/wiki/tokes",
      ],
    },
  ],
  axing: [
    {
      word: "axing",
      phonetic: "/ˈæksɪŋ/",
      phonetics: [{ text: "/ˈæksɪŋ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To request (information, or an answer to a question).",
              synonyms: [],
              antonyms: [],
              example: "I asked her age.",
            },
            {
              definition: "To put forward (a question) to be answered.",
              synonyms: [],
              antonyms: [],
              example: "to ask a question",
            },
            {
              definition: "To interrogate or enquire of (a person).",
              synonyms: [],
              antonyms: [],
              example: "I'm going to ask this lady for directions.",
            },
            {
              definition: "To request or petition; usually with for.",
              synonyms: [],
              antonyms: [],
              example: "to ask for a second helping at dinner",
            },
            {
              definition: "To request permission to do something.",
              synonyms: [],
              antonyms: [],
              example: "Did you ask to use the car?",
            },
            {
              definition:
                "To require, demand, claim, or expect, whether by way of remuneration or return, or as a matter of necessity.",
              synonyms: [],
              antonyms: [],
              example: "What price are you asking for the house?",
            },
            {
              definition: "To invite.",
              synonyms: [],
              antonyms: [],
              example: "Don't ask them to the wedding.",
            },
            {
              definition:
                "To publish in church for marriage; said of both the banns and the persons.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To take (a person's situation) as an example.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To fell or chop with an axe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To lay off, terminate or drastically reduce, especially in a rough or ruthless manner; to cancel.",
              synonyms: ["downsize", "fire", "lay off"],
              antonyms: [],
              example: "He got axed in the last round of firings.",
            },
          ],
          synonyms: ["downsize", "fire", "lay off"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To furnish with an axle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An assault carried out with an axe.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ask",
        "https://en.wiktionary.org/wiki/axe",
        "https://en.wiktionary.org/wiki/axing",
      ],
    },
  ],
  toper: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  duvet: [
    {
      word: "duvet",
      phonetic: "/ˈduːveɪ/",
      phonetics: [
        {
          text: "/ˈduːveɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/duvet-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88675849",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A thick, padded quilt used instead of blankets.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A cover for a quilt or comforter.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["continental quilt", "doona", "quilt"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/duvet"],
    },
  ],
  cowry: [
    {
      word: "cowry",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small gastropod (Monetaria moneta, syn. Cypraea moneta) common in the Indian Ocean; its shell.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) Any gastropod of the genus Cypraea; its shell.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) Any gastropod of the family Cypraeidae; its shell.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["money cowrie", "wampum"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cowrie",
        "https://en.wiktionary.org/wiki/cowry",
      ],
    },
  ],
  profs: [
    {
      word: "profs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                'The most senior rank for an academic at a university or similar institution, informally also known as "full professor." Abbreviated Prof.',
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A teacher or faculty member at a college or university regardless of formal rank.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "One who professes something, such as a religious doctrine.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A pianist in a saloon, brothel, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The puppeteer who performs a Punch and Judy show; a Punchman.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["prof"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Total income or cash flow minus expenditures. The money or other benefit a non-governmental organization or individual receives in exchange for products and services sold at an advertised price.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Benefit, positive result obtained.",
              synonyms: [],
              antonyms: [],
              example:
                "Reading such an enlightening book on the subject was of much profit to his studies.",
            },
            {
              definition:
                "In property law, a nonpossessory interest in land whereby a party is entitled to enter the land of another for the purpose of taking the soil or the substance of the soil (coal, oil, minerals, and in some jurisdictions timber and game).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["gain"],
          antonyms: ["loss"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/prof",
        "https://en.wiktionary.org/wiki/professor",
        "https://en.wiktionary.org/wiki/profit",
        "https://en.wiktionary.org/wiki/profs",
      ],
    },
  ],
  blahs: [
    {
      word: "blahs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Nonsense; drivel; idle, meaningless talk.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(in plural, the blahs) A general or ambiguous feeling of discomfort, dissatisfaction, uneasiness, boredom, mild depression, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "malaise",
            "bombast",
            "bosh",
            "bunkum",
            "claptrap",
            "eyewash",
            "fustian",
            "hooey",
            "humbug",
            "rant",
            "rubbish",
            "twaddle",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/blah",
        "https://en.wiktionary.org/wiki/blahs",
      ],
    },
  ],
  addle: [
    {
      word: "addle",
      phonetic: "/ˈæ.dəl/",
      phonetics: [
        {
          text: "/ˈæ.dəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/addle-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1227564",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(provincial) To earn, earn by labor; earn money or one's living.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(provincial) To thrive or grow; to ripen.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/addle"],
    },
    {
      word: "addle",
      phonetic: "/ˈæ.dəl/",
      phonetics: [
        {
          text: "/ˈæ.dəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/addle-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1227564",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A foolish or dull-witted fellow.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Liquid filth; mire.", synonyms: [], antonyms: [] },
            {
              definition: "(provincial) Lees; dregs.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make addle; to grow addle; to muddle",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To cause fertilised eggs to lose viability, by killing the developing embryo within through shaking, piercing, freezing or oiling, without breaking the shell.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Having lost the power of development, and become rotten; putrid.",
              synonyms: [],
              antonyms: [],
              example: "addle eggs",
            },
            {
              definition: "(by extension) Unfruitful or confused; muddled.",
              synonyms: [],
              antonyms: [],
              example: "addle brains",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/addle"],
    },
  ],
  sudsy: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  batty: [
    {
      word: "batty",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/batty-1-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241021",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈbæti/", audio: "" },
        { text: "/ˈbæti/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Mad, crazy, silly.", synonyms: [], antonyms: [] },
            {
              definition: "Belonging to, or resembling, a bat (mammal).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/batty"],
    },
    {
      word: "batty",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(West Indian slang) The buttocks or anus.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A homosexual man.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/batty"],
    },
  ],
  coifs: [
    {
      word: "coifs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A hairdo.", synonyms: [], antonyms: [] },
            {
              definition:
                "A hood; a close-fitting cap covering much of the head, widespread until the 18th century; after that worn only by small children and country women.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An item of chain mail headgear.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An official headdress, such as that worn by certain judges in England.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To style or arrange hair.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/coif",
        "https://en.wiktionary.org/wiki/coifs",
      ],
    },
  ],
  pitas: [
    {
      word: "pitas",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A flat bread pouch used for making sandwiches such as gyros or falafels.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A fiber obtained from the Agave americana and related species, used for making cordage and paper.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The plant which yields the fiber.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pita",
        "https://en.wiktionary.org/wiki/pitas",
      ],
    },
  ],
  gouda: [
    {
      word: "gouda",
      phonetic: "/ˈxaʊdə/",
      phonetics: [{ text: "/ˈxaʊdə/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A type of semi-hard to hard cheese originating in Netherlands.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gouda"],
    },
  ],
  taupe: [
    {
      word: "taupe",
      phonetic: "/təʊp/",
      phonetics: [
        { text: "/təʊp/", audio: "" },
        {
          text: "/toʊp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/taupe-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=473959",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A dark brownish-grey colour, the colour of moleskin.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of a dark brownish-grey colour.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/taupe"],
    },
  ],
  topes: [
    {
      word: "topes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To drink excessively; to get drunk.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small, grey, European shark, Galeorhinus galeus, that has rough skin and a long snout.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A grove of trees.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A mound-like Buddhist sepulchre, or memorial monument, often erected over a relic; a stupa.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tope",
        "https://en.wiktionary.org/wiki/topes",
      ],
    },
  ],
  duchy: [
    {
      word: "duchy",
      phonetic: "/ˈdʌtʃi/",
      phonetics: [{ text: "/ˈdʌtʃi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A dominion or region ruled by a duke or duchess. (A grand duchy may be a self-governing state. A simple duchy tends to be a part of a larger kingdom or empire.)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/duchy"],
    },
  ],
  nitro: [
    {
      word: "nitro",
      phonetic: "/ˈnaɪtɹəʊ/",
      phonetics: [{ text: "/ˈnaɪtɹəʊ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The univalent NO2 functional group.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Nitroglycerin, especially as medication.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A beer that is nitrogenated to give it a more creamy head.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Nitromethane.", synonyms: [], antonyms: [] },
            {
              definition: "Nitrogen, especially as liquid nitrogen.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Containing the nitro group; such compounds tend to be unstable and often explosive",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/nitro"],
    },
  ],
  carny: [
    {
      word: "carny",
      phonetic: "/ˈkɑː(ɹ)ni/",
      phonetics: [{ text: "/ˈkɑː(ɹ)ni/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person who works in a carnival (often one who uses exaggerated showmanship or fraud).",
              synonyms: ["showie"],
              antonyms: [],
            },
            {
              definition: "The jargon used by carnival workers.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A carnival.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["showie"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/carny"],
    },
    {
      word: "carny",
      phonetic: "/ˈkɑː(ɹ)ni/",
      phonetics: [{ text: "/ˈkɑː(ɹ)ni/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Flattery.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cajole, wheedle, or coax.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/carny"],
    },
  ],
  limey: [
    {
      word: "limey",
      phonetic: "/ˈlaɪmi/",
      phonetics: [
        {
          text: "/ˈlaɪmi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/limey-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241433",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An Englishman or other Briton, or a person of British descent.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["pom", "pommy"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Resembling limes (the fruit); lime-like.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Of, or pertaining to, limes (the fruit).",
              synonyms: [],
              antonyms: [],
              example: "This drink is full of limey goodness.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/limey"],
    },
  ],
  orals: [
    {
      word: "orals",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A spoken test or examination, particularly in a language class.",
              synonyms: [],
              antonyms: [],
              example: "We've got our Spanish oral tomorrow.",
            },
            {
              definition: "A physical examination of the mouth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Oral sex.",
              synonyms: [],
              antonyms: [],
              example:
                "I gave my boyfriend oral for the first time on his birthday.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/oral",
        "https://en.wiktionary.org/wiki/orals",
      ],
    },
  ],
  hirer: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  taxer: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  roils: [
    {
      word: "roils",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To render turbid by stirring up the dregs or sediment of.",
              synonyms: ["agitate"],
              antonyms: [],
              example: "To roil a spring.",
            },
            {
              definition: "To annoy; to make someone angry.",
              synonyms: ["irritate", "rile"],
              antonyms: [],
            },
            { definition: "To bubble, seethe.", synonyms: [], antonyms: [] },
            { definition: "To wander; to roam.", synonyms: [], antonyms: [] },
            { definition: "To romp.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["agitate", "irritate", "rile"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/roil",
        "https://en.wiktionary.org/wiki/roils",
      ],
    },
  ],
  ruble: [
    {
      word: "ruble",
      phonetic: "/ɹuːbəl/",
      phonetics: [{ text: "/ɹuːbəl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The monetary unit of Russia, Belarus and Transnistria equal to 100 kopeks (Russian: копе́йка, Belarusian: капе́йка). The Russian ruble's symbol is ₽.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ruble"],
    },
  ],
  elate: [
    {
      word: "elate",
      phonetic: "/ɪˈleɪt/",
      phonetics: [
        {
          text: "/ɪˈleɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/elate-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89033102",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make joyful or proud.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To lift up; raise; elevate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Elated; exultant", synonyms: [], antonyms: [] },
            {
              definition: "Lifted up; raised; elevated.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/elate"],
    },
  ],
  dolor: [
    {
      word: "dolor",
      phonetic: "/ˈdoʊlə(ɹ)/",
      phonetics: [{ text: "/ˈdoʊlə(ɹ)/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Sorrow, grief, misery or anguish.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A unit of pain used to theoretically weigh people's outcomes.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dol"],
          antonyms: ["hedon"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dolor"],
    },
  ],
  wryer: [
    {
      word: "wryer",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Turned away, contorted (of the face or body).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Dryly humorous; sardonic or bitterly ironic.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Twisted, bent, crooked.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Deviating from the right direction; misdirected; out of place.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wry",
        "https://en.wiktionary.org/wiki/wryer",
      ],
    },
  ],
  snots: [
    {
      word: "snots",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Mucus, especially mucus from the nose.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A contemptible child.", synonyms: [], antonyms: [] },
            { definition: "A mean fellow.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["booger"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/snot",
        "https://en.wiktionary.org/wiki/snots",
      ],
    },
  ],
  quais: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  coked: [
    {
      word: "coked",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To produce coke from coal.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To turn into coke.", synonyms: [], antonyms: [] },
            {
              definition:
                "To add deleterious carbon deposits as a byproduct of combustion.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Intoxicated with cocaine.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/coke",
        "https://en.wiktionary.org/wiki/coked",
      ],
    },
  ],
  gimel: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  gorse: [
    {
      word: "gorse",
      phonetic: "/ɡɔːs/",
      phonetics: [
        { text: "/ɡɔːs/", audio: "" },
        { text: "/ɡɔɹs/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Evergreen shrub, of the genus Ulex, having spiny leaves and yellow flowers.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["furze", "whin"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gorse"],
    },
  ],
  minas: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  goest: [
    {
      word: "goest",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To move:",
              synonyms: [
                "cross",
                "draw",
                "drift",
                "fare",
                "move",
                "tread",
                "wend",
              ],
              antonyms: [
                "freeze",
                "halt",
                "remain",
                "stand still",
                "stay",
                "stop",
              ],
            },
            {
              definition:
                "(chiefly of a machine) To work or function (properly); to move or perform (as required).",
              synonyms: ["function", "operate", "work"],
              antonyms: [],
              example: "The engine just won't go anymore.",
            },
            {
              definition: "To start; to begin (an action or process).",
              synonyms: [],
              antonyms: [],
              example: "Get ready, get set, go!",
            },
            {
              definition: "To take a turn, especially in a game.",
              synonyms: ["make one's move", "move", "take one’s turn"],
              antonyms: [],
              example: "It’s your turn; go.",
            },
            {
              definition: "To attend.",
              synonyms: [],
              antonyms: [],
              example: "I go to school at the schoolhouse.",
            },
            { definition: "To proceed:", synonyms: [], antonyms: [] },
            {
              definition: "To follow or travel along (a path):",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To extend (from one point in time or space to another).",
              synonyms: [],
              antonyms: [],
              example: "This property goes all the way to the state line.",
            },
            {
              definition: "To lead (to a place); to give access to.",
              synonyms: [],
              antonyms: [],
              example: "Does this road go to Fort Smith?",
            },
            {
              definition:
                "To become. (The adjective that follows usually describes a negative state.)",
              synonyms: ["become", "change into", "turn"],
              antonyms: [],
              example:
                "After failing as a criminal, he decided to go straight.",
            },
            {
              definition:
                "To assume the obligation or function of; to be, to serve as.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To continuously or habitually be in a state.",
              synonyms: [],
              antonyms: [],
              example: "I don't want my children to go hungry.",
            },
            {
              definition: "To come to (a certain condition or state).",
              synonyms: [],
              antonyms: [],
              example:
                "They went into debt, she goes to sleep around 10 o'clock.",
            },
            {
              definition:
                "To change (from one value to another) in the meaning of wend.",
              synonyms: [],
              antonyms: [],
              example: "The traffic light went straight from green to red.",
            },
            {
              definition:
                "To turn out, to result; to come to (a certain result).",
              synonyms: [],
              antonyms: [],
              example: "How did your meeting with Smith go?",
            },
            {
              definition: "To tend (toward a result).",
              synonyms: [],
              antonyms: [],
              example: "These experiences go to make us stronger.",
            },
            {
              definition:
                "To contribute to a (specified) end product or result.",
              synonyms: [],
              antonyms: [],
              example:
                "qualities that go to make a lady / lip-reader / sharpshooter",
            },
            {
              definition: "To pass, to be used up:",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To die.", synonyms: [], antonyms: [] },
            {
              definition: "To be discarded.",
              synonyms: [],
              antonyms: [],
              example: "This chair has got to go.",
            },
            { definition: "To be lost or out:", synonyms: [], antonyms: [] },
            {
              definition: "To break down or apart:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be sold.",
              synonyms: [],
              antonyms: [],
              example: "Everything must go.",
            },
            {
              definition: "To be given, especially to be assigned or allotted.",
              synonyms: [],
              antonyms: [],
              example: "The award went to Steven Spielberg.",
            },
            {
              definition:
                "To survive or get by; to last or persist for a stated length of time.",
              synonyms: [],
              antonyms: [],
              example: "Can you two go twenty minutes without arguing?!",
            },
            {
              definition: "To have a certain record.",
              synonyms: [],
              antonyms: [],
              example: "The team is going five in a row.",
            },
            {
              definition: "To be authoritative, accepted, or valid:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To say (something), to make a sound:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To be expressed or composed (a certain way).",
              synonyms: [],
              antonyms: [],
              example:
                "As the story goes, he got the idea for the song while sitting in traffic.",
            },
            {
              definition: "To resort (to).",
              synonyms: [],
              antonyms: [],
              example: "I'll go to court if I have to.",
            },
            {
              definition: "To apply or subject oneself to:",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To fit (in a place, or together with something):",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To date.",
              synonyms: ["date", "go out (with)", "see"],
              antonyms: [],
              example: "He's been going with her for two weeks.",
            },
            { definition: "To attack:", synonyms: [], antonyms: [] },
            {
              definition: "To be in general; to be usually.",
              synonyms: [],
              antonyms: [],
              example: "As sentences go, this one is pretty boring.",
            },
            {
              definition:
                "To take (a particular part or share); to participate in to the extent of.",
              synonyms: [],
              antonyms: [],
              example: "Let's go halves on this.",
            },
            {
              definition: "To yield or weigh.",
              synonyms: [],
              antonyms: [],
              example: "Those babies go five tons apiece.",
            },
            {
              definition: "To offer, bid or bet an amount; to pay.",
              synonyms: [],
              antonyms: [],
              example: "I'll go a ten-spot.",
            },
            {
              definition: "To enjoy. (Compare go for.)",
              synonyms: [],
              antonyms: [],
              example: "I could go a beer right about now.",
            },
            {
              definition: "To urinate or defecate.",
              synonyms: [],
              antonyms: [],
              example: "Have you managed to go today, Mrs. Miggins?",
            },
          ],
          synonyms: [
            "become",
            "change into",
            "turn",
            "cross",
            "draw",
            "drift",
            "fare",
            "move",
            "tread",
            "wend",
            "date",
            "go out (with)",
            "see",
            "function",
            "operate",
            "work",
            "make one's move",
            "move",
            "take one’s turn",
          ],
          antonyms: ["freeze", "halt", "remain", "stand still", "stay", "stop"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/go",
        "https://en.wiktionary.org/wiki/goest",
      ],
    },
  ],
  agape: [
    {
      word: "agape",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/agape-1-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75729374",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/əˈɡeɪp/", audio: "" },
        {
          text: "/əˈɡeɪp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/agape-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=268943",
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "In a state of astonishment, wonder, expectation, or eager attention; as with mouth hanging open.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Wide open.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["dumbstruck", "open-mouthed", "wide-eyed", "agog"],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition:
                "In a state of astonishment, wonder, expectation, or eager attention.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Open wide.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/agape"],
    },
    {
      word: "agape",
      phonetic: "/ɑˈɡɑː.peɪ/",
      phonetics: [
        {
          text: "/ɑˈɡɑː.peɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/agape-2-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88968090",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The love of God for mankind, or the benevolent love of Christians for others.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Spiritual, altruistic, beneficial love which wills good for others.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A love feast, especially one held in the early Christian Church in connection with the Eucharist.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["charity"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/agape"],
    },
  ],
  manta: [
    {
      word: "manta",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A kind of fabric or blanket used in Latin America and southwestern United States.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of several very large pelagic rays of the genus Manta, with winglike pectoral fins, a long tail, and two fins resembling horns that project from the head.",
              synonyms: ["manta"],
              antonyms: [],
            },
          ],
          synonyms: ["manta"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/manta",
        "https://en.wiktionary.org/wiki/manta%20ray",
      ],
    },
  ],
  jings: [
    {
      word: "jings",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "An exclamation of surprise.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/jings"],
    },
  ],
  iliac: [
    {
      word: "iliac",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of or pertaining to the ilium.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/iliac"],
    },
  ],
  admen: [
    {
      word: "admen",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person in the business of devising, writing, illustrating or selling advertisements.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/adman",
        "https://en.wiktionary.org/wiki/admen",
      ],
    },
  ],
  offen: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  cills: [
    {
      word: "cills",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(also window sill) A horizontal slat which forms the base of a window.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A horizontal, structural member of a building near ground level on a foundation or pilings or lying on the ground in earth-fast construction and bearing the upright portion of a frame. Also called a ground plate, groundsill, sole, sole-plate, mudsill. An interrupted sill fits between posts instead of being below and supporting the posts in timber framing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A horizontal layer of igneous rock between older rock beds.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A piece of timber across the bottom of a canal lock for the gates to shut against.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A raised area at the base of the nasal aperture in the skull.",
              synonyms: [],
              antonyms: [],
              example: "the nasal sill",
            },
            {
              definition: "The inner edge of the bottom of an embrasure.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A young herring.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The shaft or thill of a carriage.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cill",
        "https://en.wiktionary.org/wiki/cills",
        "https://en.wiktionary.org/wiki/sill",
      ],
    },
  ],
  offal: [
    {
      word: "offal",
      phonetic: "/ˈɑfl̩/",
      phonetics: [
        { text: "/ˈɑfl̩/", audio: "" },
        {
          text: "/ˈɒfl̩/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/offal-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80066023",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈɔfl̩/", audio: "" },
        { text: "/ˈɑfl̩/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The internal organs of an animal, used as animal food.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A by-product of the grain milling process, which may include bran, husks, etc.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A dead body; carrion.", synonyms: [], antonyms: [] },
            {
              definition:
                "That which is thrown away as worthless or unfit for use; refuse; rubbish.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/offal"],
    },
  ],
  lotta: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  bolas: [
    {
      word: "bolas",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A throwing weapon made of weights on the ends of interconnected cords, designed to capture animals by entangling their legs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A sticky thread whirled around by certain spiders to catch prey.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bolas"],
    },
  ],
  thwap: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  alway: [
    {
      word: "alway",
      phonetic: "/ˈɔːl.weɪ/",
      phonetics: [{ text: "/ˈɔːl.weɪ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition:
                "At all times; throughout all time; since the beginning.",
              synonyms: [
                "all the time",
                "continually",
                "every time",
                "perpetually",
              ],
              antonyms: ["at no time", "never"],
              example:
                "Airplanes did not always exist as a form of transportation.",
            },
            {
              definition:
                "Constantly during a certain period, or regularly at stated intervals (opposed to sometimes or occasionally).",
              synonyms: ["invariably", "uniformly"],
              antonyms: ["manywise", "sundrily", "variously"],
              example:
                "In this street, the shops always close during lunchtime.",
            },
            {
              definition: "In any event.",
              synonyms: ["anyhow", "anyway", "at any rate", "regardless"],
              antonyms: [],
              example: "I thought I could always go back to work.",
            },
          ],
          synonyms: [
            "all the time",
            "continually",
            "every time",
            "perpetually",
            "anyhow",
            "anyway",
            "at any rate",
            "regardless",
            "invariably",
            "uniformly",
          ],
          antonyms: [
            "at no time",
            "never",
            "manywise",
            "sundrily",
            "variously",
          ],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/alway",
        "https://en.wiktionary.org/wiki/always",
      ],
    },
  ],
  boggy: [
    {
      word: "boggy",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Having the qualities of a bog; i.e. dank, squishy, muddy, and full of water and rotting vegetation.",
              synonyms: ["marshy", "swampy"],
              antonyms: [],
              example:
                "The edge of the woods led out onto a noisome, boggy fen, a paradise for mosquitos and small frogs.",
            },
          ],
          synonyms: ["marshy", "swampy"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/boggy"],
    },
  ],
  donna: [
    {
      word: "donna",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/donna-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75730288",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈdɒn.ə/", audio: "" },
        {
          text: "/ˈdɑ.nə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/donna-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=36787441",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A lady, especially a noblewoman; the title given to a lady in Italy.",
              synonyms: ["lady", "madam", "mistress", "noblewoman"],
              antonyms: [],
            },
          ],
          synonyms: ["lady", "madam", "mistress", "noblewoman"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/donna"],
    },
  ],
  locos: [
    {
      word: "locos",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A certain species of Astragalus or Oxytropis, capable of causing locoism.",
              synonyms: ["locoweed"],
              antonyms: [],
            },
          ],
          synonyms: ["locoweed"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To poison with the loco plant; to affect with locoism.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) To render insane.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A locomotive.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/loco",
        "https://en.wiktionary.org/wiki/locos",
      ],
    },
  ],
  belay: [
    {
      word: "belay",
      phonetic: "/bɪˈleɪ/",
      phonetics: [
        {
          text: "/bɪˈleɪ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/belay-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89033306",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/bɪˈleɪ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The securing of a rope to a rock or other projection.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The object to which a rope is secured.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A location at which a climber stops and builds an anchor with which to secure their partner.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To surround; environ; enclose.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To overlay; adorn.", synonyms: [], antonyms: [] },
            {
              definition: "To besiege; invest; surround.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To lie in wait for in order to attack; block up or obstruct.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To make (a rope) fast by turning it around a fastening point such as a cleat or piton.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To secure (a person) to a rope or (a rope) to a person.",
              synonyms: [],
              antonyms: [],
              example:
                "He would need an experienced partner to belay him on the difficult climbs.",
            },
            {
              definition: "To lay aside; stop; cancel.",
              synonyms: [],
              antonyms: [],
              example: "Belay that order!",
            },
            {
              definition: "The general command to stop or cease.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/belay"],
    },
  ],
  gluey: [
    {
      word: "gluey",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Viscous and adhesive, as glue.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["gummy", "sticky", "tacky"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gluey"],
    },
  ],
  bitsy: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  mimsy: [
    {
      word: "mimsy",
      phonetic: "/ˈmɪmzi/",
      phonetics: [
        {
          text: "/ˈmɪmzi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mimsy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79268182",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                'A nonce word in Lewis Carroll\'s Jabberwocky combining the senses of "flimsy" and "miserable".',
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mimsy"],
    },
    {
      word: "mimsy",
      phonetic: "/ˈmɪmzi/",
      phonetics: [
        {
          text: "/ˈmɪmzi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/mimsy-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79268182",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The vagina.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mimsy"],
    },
  ],
  hilar: [
    {
      word: "hilar",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Relating to or near a hilum.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/hilar"],
    },
  ],
  outta: [
    {
      word: "outta",
      phonetic: "/ˈʌʊɾə/",
      phonetics: [
        {
          text: "/ˈʌʊɾə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/outta-ca.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=18596939",
        },
        {
          text: "/ˈaʊtə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/outta-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=31972276",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
        {
          text: "/ˈaʊɾə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/outta-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=93486980",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "preposition",
          definitions: [{ definition: "Out of.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/outta"],
    },
  ],
  vroom: [
    {
      word: "vroom",
      phonetic: "/vɹuːm/",
      phonetics: [
        { text: "/vɹuːm/", audio: "" },
        { text: "/vɹum/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The sound of an engine revving up.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To move with great speed; to zoom.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "The sound of an engine revving up.",
              synonyms: [],
              antonyms: [],
              example:
                "I never saw my uncle’s Ferrari, but I could always hear it going vroom as it flew past by my house.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/vroom"],
    },
  ],
  fetal: [
    {
      word: "fetal",
      phonetic: "/ˈfitl̩/",
      phonetics: [{ text: "/ˈfitl̩/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Pertaining to, or connected with, a fetus.",
              synonyms: [],
              antonyms: [],
              example: "fetal circulation",
            },
          ],
          synonyms: ["zygotal"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fetal"],
    },
  ],
  raths: [
    {
      word: "raths",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A walled enclosure, especially in Ireland; a ringfort built sometime between the Iron Age and the Viking Age.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A Burmese carriage of state.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rath",
        "https://en.wiktionary.org/wiki/raths",
      ],
    },
  ],
  renal: [
    {
      word: "renal",
      phonetic: "/ˈɹiːnəl/",
      phonetics: [{ text: "/ˈɹiːnəl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Pertaining to the kidneys.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["nephric", "nephritic"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/renal"],
    },
  ],
  dyads: [
    {
      word: "dyads",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A set of two elements treated as one; a pair.",
              synonyms: ["couple", "duad"],
              antonyms: [],
            },
            {
              definition: "Any set of two different pitch classes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A pair of things standing in particular relation; dyadic relation.",
              synonyms: [],
              antonyms: [],
              example:
                '"For each individual in a specific dyad (i.e., mother-offspring, offspring-father, sibling-sibling),..." Debra Lieberman, John Tooby, and Leda Cosmides - The evolution of human incest avoidance mechanisms: an evolutionary psychological approach, p. 20',
            },
            {
              definition:
                "An element, atom, or radical having a valence or combining power of two.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A secondary unit of organisation consisting of an aggregate of monads.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A tensor of order two and rank one.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["couple", "duad"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dyad",
        "https://en.wiktionary.org/wiki/dyads",
      ],
    },
  ],
  crocs: [
    {
      word: "crocs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A crocodile.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A plastic slip-on shoe.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/croc",
        "https://en.wiktionary.org/wiki/crocs",
      ],
    },
  ],
  vires: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  culpa: [
    {
      word: "culpa",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Negligence or fault, as distinguishable from dolus (deceit, fraud), which implies intent, culpa being imputable to defect of intellect, dolus to defect of heart.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/culpa"],
    },
  ],
  kivas: [
    {
      word: "kivas",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A ceremonial underground chamber in a Pueblo village.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/kiva",
        "https://en.wiktionary.org/wiki/kivas",
      ],
    },
  ],
  feist: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  teats: [
    {
      word: "teats",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The projection of a mammary gland from which, on female mammals, milk is secreted.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Something resembling a teat, such as a small protuberance or nozzle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An artificial nipple used for bottle-feeding infants.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/teat",
        "https://en.wiktionary.org/wiki/teats",
      ],
    },
  ],
  thats: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  yawls: [
    {
      word: "yawls",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A small ship's boat, usually rowed by four or six oars.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A fore-and-aft rigged sailing vessel with two masts, main and mizzen, the mizzen stepped abaft the rudder post.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/yawl",
        "https://en.wiktionary.org/wiki/yawls",
      ],
    },
  ],
  whens: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  abaca: [
    {
      word: "abaca",
      phonetic: "/ˌæb.əˈkɑ/",
      phonetics: [
        { text: "/ˌæb.əˈkɑ/", audio: "" },
        { text: "/ˌæb.əˈkɑ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Musa textilis, a species of banana tree native to the Philippines grown for its textile, rope- and papermaking fibre.",
              synonyms: ["Manila Hemp", "Manilla hemp", "textile banana"],
              antonyms: [],
            },
            {
              definition: "The fiber of this plant, used in rope.",
              synonyms: ["Manila hemp", "Manilla hemp"],
              antonyms: [],
            },
          ],
          synonyms: [
            "Manila Hemp",
            "Manilla hemp",
            "textile banana",
            "Manila hemp",
            "Manilla hemp",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/abaca"],
    },
  ],
  ohhhh: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  aphis: [
    {
      word: "aphis",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "An aphid.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/aphis"],
    },
  ],
  fusty: [
    {
      word: "fusty",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Moldy or musty.", synonyms: [], antonyms: [] },
            {
              definition: "Stale-smelling or stuffy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) Old-fashioned, refusing to change or update.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of wine) Tasting of the cask.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fusty"],
    },
  ],
  eclat: [
    {
      word: "eclat",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A brilliant or successful effect; brilliance of success or effort; splendor; brilliant show; striking effect; glory; renown.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/%C3%A9clat",
        "https://en.wiktionary.org/wiki/eclat",
      ],
    },
  ],
  perdu: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  mayst: [
    {
      word: "mayst",
      phonetic: "/meɪst/",
      phonetics: [{ text: "/meɪst/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To be strong; to have power (over).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(auxiliary) To be able; can.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To be able to go.", synonyms: [], antonyms: [] },
            {
              definition:
                "(modal auxiliary verb, defective) To have permission to, be allowed. Used in granting permission and in questions to make polite requests.",
              synonyms: ["can", "could", "might"],
              antonyms: [],
              example: "you may smoke outside;  may I sit there?",
            },
            {
              definition:
                "(modal auxiliary verb, defective) Expressing a present possibility; possibly.",
              synonyms: ["could", "might"],
              antonyms: [],
              example:
                "he may be lying;  Schrödinger's cat may or may not be in the box",
            },
            {
              definition:
                "(subjunctive present, defective) Expressing a wish (with present subjunctive effect).",
              synonyms: ["might"],
              antonyms: [],
              example: "may you win;  may the weather be sunny",
            },
            {
              definition:
                "Used in modesty, courtesy, or concession, or to soften a question or remark.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["can", "could", "might", "could", "might", "might"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To gather may, or flowers in general.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To celebrate May Day.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/may",
        "https://en.wiktionary.org/wiki/mayst",
      ],
    },
  ],
  exeat: [
    {
      word: "exeat",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A license or permit for absence from a college or a religious house (such as a monastery).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A permission which a bishop grants to a priest to go out of his diocese.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/exeat"],
    },
  ],
  molly: [
    {
      word: "molly",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/molly-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241469",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈmɒli/", audio: "" },
        { text: "/ˈmɑli/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A woman or girl, especially of low status.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An effeminate male, a male homosexual.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Pure MDMA powder.",
              synonyms: ["mandy"],
              antonyms: [],
            },
            { definition: "A mollemoke.", synonyms: [], antonyms: [] },
            {
              definition: "A female cat, a she-cat (usually spayed)",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A bird, the wagtail.", synonyms: [], antonyms: [] },
            { definition: "A molly bolt.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["mandy"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To engage in (male) homosexual activity with.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/molly"],
    },
    {
      word: "molly",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/molly-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241469",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈmɒli/", audio: "" },
        { text: "/ˈmɑli/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A fish of the genus Poecilia, except for those known as guppies.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/molly"],
    },
  ],
  supra: [
    {
      word: "supra",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition:
                "Used to indicate that the current citation is from the same source as the previous one.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["infra"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/supra"],
    },
    {
      word: "supra",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A traditional Georgian feast.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/supra"],
    },
  ],
  wetly: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  plasm: [
    {
      word: "plasm",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A mold or matrix in which anything is cast or formed to a particular shape.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Protoplasm", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/plasm"],
    },
  ],
  buffa: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  semen: [
    {
      word: "semen",
      phonetic: "/ˈsiːmən/",
      phonetics: [
        {
          text: "/ˈsiːmən/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/semen-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780362",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A sticky, milky fluid produced in male reproductive organs that contains the reproductive cells.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "cum",
            "ejaculate",
            "jism",
            "jissom",
            "jizz",
            "load",
            "seed",
            "skeet",
            "sperm",
            "splooge",
            "spooge",
            "spunk",
            "spurt",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/semen"],
    },
  ],
  pukka: [
    {
      word: "pukka",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pukka-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75834419",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈpʌkə/", audio: "" },
        { text: "/ˈpəkə/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(originally South Asia) Genuine or authentic; hence of behaviour: correct, socially acceptable or proper.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Superior or of high quality; first-class.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Excellent, fantastic, great.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["kutcha"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pukka"],
    },
  ],
  tagua: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  paras: [
    {
      word: "paras",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A former subunit of currency in several countries in the Ottoman/Turkish and Yugoslav regions.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A woman who has had a certain number of pregnancies, indicated by the number prepended to this word.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A paratrooper.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A passage in text that is about a different subject from the preceding text, marked by commencing on a new line, the first line sometimes being indented.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A mark or note set in the margin to call attention to something in the text, such as a change of subject.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An offset of 16 bytes in Intel memory architectures.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/para",
        "https://en.wiktionary.org/wiki/paragraph",
        "https://en.wiktionary.org/wiki/paras",
      ],
    },
  ],
  stoat: [
    {
      word: "stoat",
      phonetic: "/ˈstəʊt/",
      phonetics: [
        {
          text: "/ˈstəʊt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/stoat-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88681590",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Mustela erminea, the ermine or short-tailed weasel, a mustelid native to Eurasia and North America, distinguished from the least weasel by its larger size and longer tail with a prominent black tip.",
              synonyms: ["ermine", "short-tailed weasel"],
              antonyms: [],
            },
          ],
          synonyms: ["ermine", "short-tailed weasel"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/stoat"],
    },
  ],
  secco: [
    {
      word: "secco",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A work painted on dry plaster, as distinguished from a fresco.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Dry",
              synonyms: [],
              antonyms: [],
              example:
                "Secco painting, or painting in secco, is painting on dry plaster, as distinguished from fresco painting, on wet or fresh plaster.",
            },
            {
              definition:
                "Dry – sparse accompaniment, staccato, without resonance",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/secco"],
    },
  ],
  carte: [
    {
      word: "carte",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A bill of fare; a menu.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A visiting card.", synonyms: [], antonyms: [] },
            {
              definition:
                "A carte de visite (small collectible photograph of a famous person).",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A playing card.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/carte"],
    },
    {
      word: "carte",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The fourth defensive position, with the sword hand held at chest height, and the tip of the sword at neck height, the palm of the hand facing upwards.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/carte",
        "https://en.wiktionary.org/wiki/quarte",
      ],
    },
  ],
  haute: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  molal: [
    {
      word: "molal",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Of or designating a solution that contains one mole of solute per 1000g of solvent",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/molal"],
    },
  ],
  shads: [
    {
      word: "shads",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any one of several species of food fishes that make up the genus Alosa in the family Clupeidae, to which the herrings also belong; river herring.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The bluefish (Pomatomus saltatrix).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/shad",
        "https://en.wiktionary.org/wiki/shads",
      ],
    },
  ],
  forma: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  ovoid: [
    {
      word: "ovoid",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Something that is oval in shape.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Shaped like an oval.", synonyms: [], antonyms: [] },
            {
              definition:
                "Egg-shaped; shaped like an oval, but more tapered at one end; ovate.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["egg-shaped", "ovate", "oviform"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ovoid"],
    },
  ],
  pions: [
    {
      word: "pions",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of three semistable mesons, having positive, negative or neutral charge, composed of up and down quarks/antiquarks.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["pi meson"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pion",
        "https://en.wiktionary.org/wiki/pions",
      ],
    },
  ],
  modus: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  bueno: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  rheum: [
    {
      word: "rheum",
      phonetic: "/ɹuːm/",
      phonetics: [
        {
          text: "/ɹuːm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/rheum-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88902096",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ɹum/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Watery or thin discharge of serum or mucus, especially from the eyes or nose, formerly thought to cause disease.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Illness or disease thought to be caused by such secretions; a catarrh, a cold; rheumatism.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Tears.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rheum"],
    },
  ],
  scurf: [
    {
      word: "scurf",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A skin disease.", synonyms: [], antonyms: [] },
            {
              definition:
                "The flakes of skin that fall off as a result of a skin disease.",
              synonyms: ["dandruff"],
              antonyms: [],
            },
            {
              definition:
                "Any crust-like formations on the skin, or in general.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The foul remains of anything adherent.",
              synonyms: ["scum"],
              antonyms: [],
            },
            {
              definition:
                "Minute membranous scales on the surface of some leaves, as in the goosefoot.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dandruff", "scum"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scurf"],
    },
    {
      word: "scurf",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A grey bull trout (Salvelinus confluentus).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scurf"],
    },
  ],
  parer: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  ephah: [
    {
      word: "ephah",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A former Hebrew unit of dry volume (about 23 L).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ephah"],
    },
  ],
  doest: [
    {
      word: "doest",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(auxiliary) A syntactic marker.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To perform; to execute.",
              synonyms: ["accomplish", "carry out", "functionate"],
              antonyms: [],
              example:
                "All you ever do is surf the Internet. What will you do this afternoon?",
            },
            {
              definition: "To cause, make (someone) (do something).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To suffice.",
              synonyms: [],
              antonyms: [],
              example:
                "it’s not the best broom, but it will have to do;  this will do me, thanks.",
            },
            {
              definition: "To be reasonable or acceptable.",
              synonyms: [],
              antonyms: [],
              example:
                "It simply will not do to have dozens of children running around such a quiet event.",
            },
            {
              definition: "(ditransitive) To have (as an effect).",
              synonyms: [],
              antonyms: [],
              example: "The fresh air did him some good.",
            },
            {
              definition: "To fare, perform (well or poorly).",
              synonyms: [],
              antonyms: [],
              example:
                "Our relationship isn't doing very well;  how do you do?",
            },
            {
              definition: "(chiefly in questions) To have as one's job.",
              synonyms: [],
              antonyms: [],
              example: "What does Bob do? — He's a plumber.",
            },
            {
              definition:
                "To perform the tasks or actions associated with (something).",
              synonyms: [],
              antonyms: [],
              example:
                "\"Don't forget to do your report\" means something quite different depending on whether you're a student or a programmer.",
            },
            {
              definition: "To cook.",
              synonyms: [],
              antonyms: [],
              example: "I'll just do some eggs.",
            },
            {
              definition: "To travel in, to tour, to make a circuit of.",
              synonyms: [],
              antonyms: [],
              example: "Let’s do New York also.",
            },
            {
              definition: "To treat in a certain way.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To work for or on, by way of caring for, looking after, preparing, cleaning, keeping in order, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To act or behave in a certain manner; to conduct oneself.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To spend (time) in jail. (See also do time)",
              synonyms: ["serve"],
              antonyms: [],
              example: "I did five years for armed robbery.",
            },
            {
              definition: "To impersonate or depict.",
              synonyms: ["imitate", "personate", "take off"],
              antonyms: [],
              example:
                "They really laughed when he did Clinton, with a perfect accent and a leer.",
            },
            {
              definition:
                "(with 'a' and the name of a person, place, event, etc.) To copy or emulate the actions or behaviour that is associated with the person or thing mentioned.",
              synonyms: [],
              antonyms: [],
              example: "He did a Henry VIII and got married six times.",
            },
            {
              definition: "To kill.",
              synonyms: ["do in", "murder", "off", "rub out"],
              antonyms: [],
            },
            {
              definition:
                "To deal with for good and all; to finish up; to undo; to ruin; to do for.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To punish for a misdemeanor.",
              synonyms: [],
              antonyms: [],
              example: "He got done for speeding.",
            },
            {
              definition: "To have sex with. (See also do it)",
              synonyms: ["go to bed with", "sleep with"],
              antonyms: [],
            },
            {
              definition: "To cheat or swindle.",
              synonyms: ["defraud", "diddle", "mug off", "rip off", "scam"],
              antonyms: [],
              example: "That guy just did me out of two hundred bucks!",
            },
            {
              definition:
                "To convert into a certain form; especially, to translate.",
              synonyms: [],
              antonyms: [],
              example:
                "the novel has just been done into English;  I'm going to do this play into a movie",
            },
            { definition: "To finish.", synonyms: [], antonyms: [] },
            {
              definition: "To work as a domestic servant (with for).",
              synonyms: ["attend", "serve", "wait on"],
              antonyms: [],
            },
            {
              definition:
                "(auxiliary) Used to form the present progressive of verbs.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To cash or to advance money for, as a bill or note.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(ditransitive) To make or provide.",
              synonyms: ["furnish", "give", "supply"],
              antonyms: [],
              example:
                "Could you do me a burger with mayonnaise instead of ketchup?",
            },
            {
              definition: "To injure (one's own body part).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To take drugs.",
              synonyms: [],
              antonyms: [],
              example: "I do cocaine.",
            },
            {
              definition:
                "(in the form be doing [somewhere]) To exist with a purpose or for a reason.",
              synonyms: [],
              antonyms: [],
              example: "What's that car doing in our swimming pool?",
            },
          ],
          synonyms: [
            "accomplish",
            "carry out",
            "functionate",
            "attend",
            "serve",
            "wait on",
            "defraud",
            "diddle",
            "mug off",
            "rip off",
            "scam",
            "do in",
            "murder",
            "off",
            "rub out",
            "furnish",
            "give",
            "supply",
            "go to bed with",
            "sleep with",
            "imitate",
            "personate",
            "take off",
            "serve",
          ],
          antonyms: ["don't"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/do",
        "https://en.wiktionary.org/wiki/doest",
      ],
    },
  ],
  sprue: [
    {
      word: "sprue",
      phonetic: "/spɹuː/",
      phonetics: [
        {
          text: "/spɹuː/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sprue-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=558879",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A tropical disease causing a sore throat and tongue, and disturbed digestion; psilosis.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["tropical sprue"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sprue"],
    },
    {
      word: "sprue",
      phonetic: "/spɹuː/",
      phonetics: [
        {
          text: "/spɹuː/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sprue-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=558879",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(founding) The hole through which melted metal is poured into the gate, and thence into the mold.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(founding) Material that cools in the feed channels to a mold.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To remove the sprues (material left in feed channels) from a cast piece.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sprue"],
    },
  ],
  flams: [
    {
      word: "flams",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To deceive with a falsehood.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "(drumming) To play (notes as) a flam.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/flam",
        "https://en.wiktionary.org/wiki/flams",
      ],
    },
  ],
  molto: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  dieth: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  choos: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  miked: [
    {
      word: "miked",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To microphone; to place one or more microphones (mikes) on.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To measure using a micrometer.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mike",
        "https://en.wiktionary.org/wiki/miked",
      ],
    },
  ],
  bronx: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  goopy: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  bally: [
    {
      word: "bally",
      phonetic: "/ˈbæli/",
      phonetics: [
        {
          text: "/ˈbæli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bally-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89013501",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Bloody (used as a mild intensifier).",
              synonyms: [],
              antonyms: [],
              example: "He's just a bally idiot.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition: "Very.",
              synonyms: [],
              antonyms: [],
              example: "That was a bally foolish thing to do, old chap!",
            },
          ],
          synonyms: ["blasted", "dashed"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bally"],
    },
    {
      word: "bally",
      phonetic: "/ˈbæli/",
      phonetics: [
        {
          text: "/ˈbæli/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/bally-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89013501",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A balaclava.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bally"],
    },
  ],
  plumy: [
    {
      word: "plumy",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Covered or adorned with plumes, or as with plumes; feathery.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/plumy"],
    },
  ],
  moony: [
    {
      word: "moony",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The act of mooning, flashing the buttocks.",
              synonyms: [],
              antonyms: [],
              example: "She was doing a moony.",
            },
            { definition: "A silly person.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Resembling the moon.", synonyms: [], antonyms: [] },
            { definition: "Moonlit.", synonyms: [], antonyms: [] },
            { definition: "Absent-minded.", synonyms: [], antonyms: [] },
            { definition: "Silly.", synonyms: [], antonyms: [] },
            { definition: "Sickly or tipsy.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/moony"],
    },
  ],
  morts: [
    {
      word: "morts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Death; especially, the death of game in hunting.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A note sounded on a horn at the death of a deer.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The skin of a sheep or lamb that has died of disease.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A variety of dummy whist for three players.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The exposed or dummy hand of cards in the game of mort.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A player in a multi-user dungeon who does not have special administrator privileges and whose character can be killed.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["immort"],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A three-year-old salmon.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A woman; a female.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mort",
        "https://en.wiktionary.org/wiki/morts",
      ],
    },
  ],
  yourn: [
    {
      word: "yourn",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "pronoun",
          definitions: [
            {
              definition:
                "(obsolete outside Britain and United States dialectal) Yours.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/yourn"],
    },
  ],
  bipod: [
    {
      word: "bipod",
      phonetic: "/ˈbaɪpɒd/",
      phonetics: [{ text: "/ˈbaɪpɒd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A two-legged stand.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bipod"],
    },
  ],
  spume: [
    {
      word: "spume",
      phonetic: "/spjum/",
      phonetics: [
        {
          text: "/spjum/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/spume-ca.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=47152849",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Foam or froth of liquid, particularly that of seawater.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To froth.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spume"],
    },
  ],
  algal: [
    {
      word: "algal",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "An alga.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Pertaining to, or like, algae",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/algal"],
    },
  ],
  ambit: [
    {
      word: "ambit",
      phonetic: "/ˈæmbɪt/",
      phonetics: [
        {
          text: "/ˈæmbɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ambit-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80175035",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The sphere or area of control and influence of something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A circuit, or a boundary around a property.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The extent of actions, thoughts, or the meaning of words, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ambit"],
    },
  ],
  mucho: [
    {
      word: "mucho",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Much; a great deal of", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mucho"],
    },
  ],
  spued: [
    {
      word: "spued",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To eject forcibly and in a stream",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To speak or write quickly and voluminously, especially words that are not worth listening to or reading.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To vomit", synonyms: [], antonyms: [] },
            { definition: "To ejaculate", synonyms: [], antonyms: [] },
            {
              definition:
                "(leather-working) To develop a white powder or dark crystals on the surface of finished leather, as a result from improper tanning.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/spew",
        "https://en.wiktionary.org/wiki/spue",
        "https://en.wiktionary.org/wiki/spued",
      ],
    },
  ],
  dozer: [
    {
      word: "dozer",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "One who dozes.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dozer"],
    },
    {
      word: "dozer",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A bulldozer.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dozer"],
    },
  ],
  harum: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  groat: [
    {
      word: "groat",
      phonetic: "/ɡɹəʊt/",
      phonetics: [
        { text: "/ɡɹəʊt/", audio: "" },
        { text: "/ɡɹoʊt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(chiefly in the plural) Hulled grain.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/groat"],
    },
    {
      word: "groat",
      phonetic: "/ɡɹəʊt/",
      phonetics: [
        { text: "/ɡɹəʊt/", audio: "" },
        { text: "/ɡɹoʊt/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Any of various old coins of England and Scotland.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A historical English silver coin worth four English pennies, still minted as one of the set of Maundy coins.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A proverbial small sum; a whit or jot.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/groat"],
    },
  ],
  skint: [
    {
      word: "skint",
      phonetic: "/skɪnt/",
      phonetics: [
        {
          text: "/skɪnt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/skint-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75649939",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Penniless, poor, impecunious, broke.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Skinned", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/skint"],
    },
  ],
  laude: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  thrum: [
    {
      word: "thrum",
      phonetic: "/θɹʌm/",
      phonetics: [
        {
          text: "/θɹʌm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/thrum-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89033124",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/θɹʌm/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A thrumming sound; a hum or vibration.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A spicy taste; a tang.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cause a steady rhythmic vibration, usually by plucking.",
              synonyms: [],
              antonyms: [],
              example:
                "She watched as he thrummed the guitar strings absently.",
            },
            {
              definition: "To make a monotonous drumming noise.",
              synonyms: [],
              antonyms: [],
              example: "to thrum on a table",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/thrum"],
    },
    {
      word: "thrum",
      phonetic: "/θɹʌm/",
      phonetics: [
        {
          text: "/θɹʌm/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/thrum-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89033124",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/θɹʌm/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The ends of the warp threads in a loom which remain unwoven attached to the loom when the web is cut.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(chiefly in plural) A fringe made of such threads.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any short piece of leftover thread or yarn; a tuft or tassel.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A threadlike part of a flower; a stamen.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A tuft, bundle, or fringe of any threadlike structures, as hairs on a leaf, fibers of a root.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A bundle of minute blood vessels, a plexus.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(chiefly in plural) Small pieces of rope yarn used for making mats or mops.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A mat made of canvas and tufts of yarn.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A shove out of place; a small displacement or fault along a seam.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To furnish with thrums; to insert tufts in; to fringe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To insert short pieces of rope-yarn or spun yarn in.",
              synonyms: [],
              antonyms: [],
              example:
                "to thrum a piece of canvas, or a mat, thus making a rough or tufted surface",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Made of or woven from thrum.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/thrum"],
    },
  ],
  pappy: [
    {
      word: "pappy",
      phonetic: "/pæpi/",
      phonetics: [{ text: "/pæpi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Like pap; soft; mushy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pappy"],
    },
    {
      word: "pappy",
      phonetic: "/pæpi/",
      phonetics: [{ text: "/pæpi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Father", synonyms: [], antonyms: [] },
            { definition: "Grandfather", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pappy"],
    },
  ],
  oncet: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  rimed: [
    {
      word: "rimed",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To compose or treat in verse; versify.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(followed by with) Of a word, to be pronounced identically with another from the vowel in its stressed syllable to the end.",
              synonyms: [],
              antonyms: [],
              example: '"Creation" rhymes with "integration" and "station".',
            },
            {
              definition:
                "Of two or more words, to be pronounced identically from the vowel in the stressed syllable of each to the end of each.",
              synonyms: [],
              antonyms: [],
              example:
                '"India" and "windier" rhyme with each other in non-rhotic accents.',
            },
            {
              definition: "To number; count; reckon.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To freeze or congeal into hoarfrost.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Covered in frost",
              synonyms: [],
              antonyms: [],
              example:
                "The rimed window permitted only shades of the traffic outside.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/rhyme",
        "https://en.wiktionary.org/wiki/rime",
        "https://en.wiktionary.org/wiki/rimed",
      ],
    },
  ],
  gigue: [
    {
      word: "gigue",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An Irish dance, derived from the jig, used in the Partita form (Baroque Period).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/gigue"],
    },
  ],
  limed: [
    {
      word: "limed",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To treat with calcium hydroxide or calcium oxide (lime).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To smear with birdlime.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To apply limewash.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To hang out/socialize in an informal, relaxed environment, especially with friends, for example at a party or on the beach.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lime",
        "https://en.wiktionary.org/wiki/limed",
      ],
    },
  ],
  plein: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  redly: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  humpf: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  lites: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  seest: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  grebe: [
    {
      word: "grebe",
      phonetic: "/ɡɹiːb/",
      phonetics: [{ text: "/ɡɹiːb/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of several waterbirds in the cosmopolitan family Podicipedidae. They have strong, sharp bills, and lobate toes.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dabchick"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/grebe"],
    },
  ],
  absit: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  thanx: [
    {
      word: "thanx",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/thanx-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79294203",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "Used to express appreciation or gratitude.",
              synonyms: [],
              antonyms: [],
              example:
                "Could you give me a hand, please? — Yes, sure. — Thanks.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/thanks",
        "https://en.wiktionary.org/wiki/thanx",
      ],
    },
  ],
  pshaw: [
    {
      word: "pshaw",
      phonetic: "/pʃɔː/",
      phonetics: [
        {
          text: "/pʃɔː/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/pshaw-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1322013",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To express disgust or contempt.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition:
                "Indicating disapproval, scoffery, irritation, impatience or disbelief.",
              synonyms: [],
              antonyms: [],
              example: "Pshaw! I can't believe it!",
            },
          ],
          synonyms: [
            "bah",
            "feh",
            "phht",
            "pht",
            "pish",
            "poh",
            "pooh",
            "pshh",
            "pshht",
            "psht",
            "pssh",
            "pssht",
            "c'mon",
            "poppycock",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/pshaw"],
    },
  ],
  yawps: [
    {
      word: "yawps",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A yelp or bark", synonyms: [], antonyms: [] },
            {
              definition: "Loud or coarse talk",
              synonyms: [],
              antonyms: [],
              example:
                "I sound my barbaric yawp over the roofs of the world - Walt Whitman",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To yelp, or utter a sharp cry, as in intense pain, or another raucous noise",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To talk loudly and coarsely",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Clamor, utter loud complaints",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/yawp",
        "https://en.wiktionary.org/wiki/yawps",
      ],
    },
  ],
  plats: [
    {
      word: "plats",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A plot of land; a lot.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A map showing the boundaries of real properties (delineating one or more plots of land), especially one that forms part of a legal document.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A plot, a scheme.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To create a plat; to lay out property lots and streets; to map.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A braid; a plait (of hair, straw, etc.).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Material produced by braiding or interweaving, especially a material of interwoven straw from which straw hats are made.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "(obsolete except regional England) To braid, to plait.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/plat",
        "https://en.wiktionary.org/wiki/plats",
      ],
    },
  ],
  payed: [
    {
      word: "payed",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To give money or other compensation to in exchange for goods or services.",
              synonyms: [],
              antonyms: [],
              example: "he paid her off the books and in kind where possible",
            },
            {
              definition:
                "To discharge, as a debt or other obligation, by giving or doing what is due or required.",
              synonyms: [],
              antonyms: [],
              example: "he has paid his debt to society",
            },
            {
              definition: "To be profitable for.",
              synonyms: [],
              antonyms: [],
              example: "It didn't pay him to keep the store open any more.",
            },
            {
              definition: "To give (something else than money).",
              synonyms: [],
              antonyms: [],
              example: "to pay attention",
            },
            {
              definition: "To be profitable or worth the effort.",
              synonyms: [],
              antonyms: [],
              example: "crime doesn’t pay",
            },
            {
              definition: "To discharge an obligation or debt.",
              synonyms: [],
              antonyms: [],
              example: "He was allowed to go as soon as he paid.",
            },
            {
              definition: "To suffer consequences.",
              synonyms: [],
              antonyms: [],
              example:
                "He paid for his fun in the sun with a terrible sunburn.",
            },
            {
              definition: "To admit that a joke, punchline, etc., was funny.",
              synonyms: [],
              antonyms: [],
              example: "I'll pay that.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To cover (the bottom of a vessel, a seam, a spar, etc.) with tar or pitch, or a waterproof composition of tallow, resin, etc.; to smear.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pay",
        "https://en.wiktionary.org/wiki/payed",
      ],
    },
  ],
  areal: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  tilth: [
    {
      word: "tilth",
      phonetic: "/tɪlθ/",
      phonetics: [{ text: "/tɪlθ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Agricultural labour; husbandry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The state of being tilled, or prepared for a crop; culture.",
              synonyms: [],
              antonyms: [],
              example: "The land is in good tilth and ready to plant.",
            },
            { definition: "Rich cultivated soil.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tilth"],
    },
  ],
  youse: [
    {
      word: "youse",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "pronoun",
          definitions: [
            { definition: "You (plural).", synonyms: [], antonyms: [] },
            { definition: "You (singular).", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/yous",
        "https://en.wiktionary.org/wiki/youse",
      ],
    },
  ],
  gwine: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  thees: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  watsa: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  lento: [
    {
      word: "lento",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A tempo mark directing that a passage is to be played very slowly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Very slow.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            { definition: "Very slowly.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lento"],
    },
  ],
  spitz: [
    {
      word: "spitz",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of several Nordic breeds of dog such as the Pomeranian or Samoyed",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/spitz"],
    },
  ],
  yawed: [
    {
      word: "yawed",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To turn about the vertical axis while maintaining course.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To swerve off course to port or starboard.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To steer badly, zigzagging back and forth across the intended course of a boat; to go out of the line of course.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To rise in blisters, breaking in white froth, as cane juice in the clarifiers in sugar works.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/yaw",
        "https://en.wiktionary.org/wiki/yawed",
      ],
    },
  ],
  gipsy: [
    {
      word: "gipsy",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(sometimes offensive) A member of the Romani people, or one of the sub-groups (Roma, Sinti, Romanichal, etc).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["Rom", "Roma", "Romani", "tzigane", "zigeuner"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An itinerant person or any person, not necessarily Romani; a tinker, a traveller or a carny.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(sometimes offensive) A move in contra dancing in which two dancers walk in a circle around each other while maintaining eye contact (but not touching as in a swing). (Compare whole gyp, half gyp, and gypsy meltdown, in which this step precedes a swing.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A member of a Broadway musical chorus line.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A person with a dark complexion.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A sly, roguish woman.", synonyms: [], antonyms: [] },
          ],
          synonyms: ["gip", "gyp", "gyre"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To roam around the country like a gypsy.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To perform the gypsy step in contra dancing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/Gypsy",
        "https://en.wiktionary.org/wiki/gipsy",
        "https://en.wiktionary.org/wiki/gypsy",
      ],
    },
  ],
  sprat: [
    {
      word: "sprat",
      phonetic: "/spɹæt/",
      phonetics: [{ text: "/spɹæt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of various small, herring-like, marine fish in the genus Sprattus, in the family Clupeidae.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sprat"],
    },
  ],
  cornu: [
    {
      word: "cornu",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A horn, or anything shaped like or resembling a horn.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cornu"],
    },
  ],
  amahs: [
    {
      word: "amahs",
      phonetic: "/ˈɑː.məz/",
      phonetics: [
        { text: "/ˈɑː.məz/", audio: "" },
        { text: "/ˈɑː.məz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "In South Asia, a woman employed to look after children; (formerly) a wet nurse.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In China and Southeast Asia, a female domestic helper.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/amah",
        "https://en.wiktionary.org/wiki/amahs",
      ],
    },
  ],
  blowy: [
    {
      word: "blowy",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "(sex) A blow job.", synonyms: [], antonyms: [] },
            {
              definition: "A blowfly, Lucilia cuprina.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The common toadfish, Tetractenos hamiltoni.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Windy or breezy.", synonyms: [], antonyms: [] },
            {
              definition:
                "(of fabric, hair, etc.) Billowy, blowing or waving in the wind.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of soil) Susceptible to drifting.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/blowie",
        "https://en.wiktionary.org/wiki/blowy",
      ],
    },
  ],
  wahoo: [
    {
      word: "wahoo",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Acanthocybium solandri, a tropical and subtropical game fish.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/wahoo"],
    },
    {
      word: "wahoo",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The winged elm, Ulmus alata.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/wahoo"],
    },
    {
      word: "wahoo",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Euonymus atropurpureus, an eastern burning bush.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/wahoo"],
    },
  ],
  lubra: [
    {
      word: "lubra",
      phonetic: "/ˈluːbɹə/",
      phonetics: [{ text: "/ˈluːbɹə/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(now racially offensive) A female Aboriginal Australian.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lubra"],
    },
  ],
  mecum: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  whooo: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  coqui: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  sabra: [
    {
      word: "sabra",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A native-born Israeli.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/Sabra",
        "https://en.wiktionary.org/wiki/sabra",
      ],
    },
  ],
  edema: [
    {
      word: "edema",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An excessive accumulation of serum in tissue spaces or a body cavity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A similar swelling in plants caused by excessive accumulation of water.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dropsy", "hydrops", "hydropsy"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/edema"],
    },
  ],
  mrads: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  dicot: [
    {
      word: "dicot",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A plant whose seedlings have two cotyledons, a dicotyledon.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dicot"],
    },
  ],
  astro: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  kited: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  ouzel: [
    {
      word: "ouzel",
      phonetic: "/ˈuːzl̩/",
      phonetics: [{ text: "/ˈuːzl̩/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The Eurasian blackbird, Turdus merula.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The water ouzel, an aquatic perching bird, Cinclus mexicanus.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["dipper"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ouzel"],
    },
  ],
  didos: [
    {
      word: "didos",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A fuss, a row.", synonyms: [], antonyms: [] },
            {
              definition: "A shrewd trick; an antic; a caper.",
              synonyms: [],
              antonyms: [],
              example: "to cut a dido",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/dido",
        "https://en.wiktionary.org/wiki/didos",
      ],
    },
  ],
  grata: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  bonne: [
    {
      word: "bonne",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A French nursemaid.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/bonne"],
    },
  ],
  axmen: [
    {
      word: "axmen",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A man who wields an axe.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A musician who plays a guitar or saxophone.",
              synonyms: ["saxophonist"],
              antonyms: [],
            },
          ],
          synonyms: ["saxophonist"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/axeman",
        "https://en.wiktionary.org/wiki/axman",
        "https://en.wiktionary.org/wiki/axmen",
      ],
    },
  ],
  klunk: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  summa: [
    {
      word: "summa",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A comprehensive summary of, or treatise on a subject, especially theology or philosophy.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/summa"],
    },
  ],
  laves: [
    {
      word: "laves",
      phonetic: "/leɪvz/",
      phonetics: [{ text: "/leɪvz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To pour or throw out, as water; lade out; bail; bail out.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To draw, as water; drink in.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To give bountifully; lavish.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To run down or gutter, as a candle.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To hang or flap down.", synonyms: [], antonyms: [] },
            { definition: "To wash.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lave",
        "https://en.wiktionary.org/wiki/laves",
      ],
    },
  ],
  purls: [
    {
      word: "purls",
      phonetic: "/pɜːlz/",
      phonetics: [
        { text: "/pɜːlz/", audio: "" },
        { text: "/pɝlz/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A particular stitch in knitting; an inversion of stitches giving the work a ribbed or waved appearance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The edge of lace trimmed with loops.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An embroidered and puckered border; a hem or fringe, often of gold or silver twist; also, a pleat or fold, as of a band.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To decorate with fringe or embroidered edge",
              synonyms: [],
              antonyms: [],
              example: "Needlework purled with gold.",
            },
            {
              definition: "An inverted stitch producing ribbing etc",
              synonyms: [],
              antonyms: [],
              example: "Knit one, purl two.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A heavy or headlong fall; an upset.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To upset, to spin, capsize, fall heavily, fall headlong.",
              synonyms: [],
              antonyms: [],
              example: "The huntsman was purled from his horse.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A circle made by the motion of a fluid; an eddy; a ripple.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A gentle murmuring sound, such as that produced by the running of a liquid among obstructions.",
              synonyms: [],
              antonyms: [],
              example: "the purl of a brook",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To flow with a murmuring sound in swirls and eddies.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To rise in circles, ripples, or undulations; to curl; to mantle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "A tern.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/purl",
        "https://en.wiktionary.org/wiki/purls",
      ],
    },
  ],
  yawny: [
    {
      word: "yawny",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Prone to yawning.", synonyms: [], antonyms: [] },
            { definition: "Sounding like a yawn.", synonyms: [], antonyms: [] },
            {
              definition: "Boring, uninteresting, causing yawns.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/yawny"],
    },
  ],
  teary: [
    {
      word: "teary",
      phonetic: "/ˈtɪəɹi/",
      phonetics: [{ text: "/ˈtɪəɹi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Of a person, having eyes filled with tears; inclined to cry.",
              synonyms: [],
              antonyms: [],
              example: "No wonder Ryan was teary; he missed his parents.",
            },
            {
              definition: "Of eyes, filled with tears.",
              synonyms: [],
              antonyms: [],
              example: "Wade looked into his girlfriend's teary eyes.",
            },
          ],
          synonyms: ["tear-filled", "wet"],
          antonyms: ["dry", "dry-eyed"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/teary"],
    },
  ],
  masse: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  largo: [
    {
      word: "largo",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A very slow tempo", synonyms: [], antonyms: [] },
            {
              definition: "A musical piece or movement in such a tempo",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Strong and stately", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/largo"],
    },
  ],
  bazar: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  sylph: [
    {
      word: "sylph",
      phonetic: "/sɪlf/",
      phonetics: [
        {
          text: "/sɪlf/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sylph-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=2160735",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An invisible being of the air.",
              synonyms: ["sylphid"],
              antonyms: [],
            },
            {
              definition: "The elemental being of air, usually female.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(by extension) A slender woman or girl, usually graceful and sometimes with the implication of sublime station over everyday people.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of the mainly dark green and blue hummingbirds (genus Aglaiocercus), the male of which has a long forked tail.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["sylphid"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sylph"],
    },
  ],
  lulab: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  toque: [
    {
      word: "toque",
      phonetic: "/təʊk/",
      phonetics: [
        { text: "/təʊk/", audio: "" },
        { text: "/toʊk/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A type of hat with no brim.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(specifically) A tall white hat with no brim of the sort worn by chefs",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(by extension) A chef.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A variety of bonnet monkey; toque macaque, Macaca sinica.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An African nominal money of account, equal to 40 cowries.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/toque"],
    },
    {
      word: "toque",
      phonetic: "/tjuːk/",
      phonetics: [
        {
          text: "/tjuːk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/toque-2-ca.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=580372",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A knitted hat, usually conical but of varying shape, often woollen, and sometimes topped by a pom-pom or tassel.",
              synonyms: ["beanie", "knit cap", "stocking cap", "watch cap"],
              antonyms: [],
            },
          ],
          synonyms: ["beanie", "knit cap", "stocking cap", "watch cap"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/toque"],
    },
    {
      word: "toque",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A rhythm used in Latin music, especially Cuban religious rituals.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The guitar part of flamenco music.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/toque"],
    },
  ],
  fugit: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  plunk: [
    {
      word: "plunk",
      phonetic: "/plʌŋk/",
      phonetics: [
        {
          text: "/plʌŋk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/plunk-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79294081",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The dull thud of something landing on a surface.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A large sum of money.", synonyms: [], antonyms: [] },
            { definition: "A dollar.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To drop or throw something heavily onto or into something else, so that it makes a dull sound.",
              synonyms: ["flump", "thud"],
              antonyms: [],
              example:
                "Enrique plunked his money down on the counter with a sigh and bellied up to the bar.",
            },
            {
              definition: "To land suddenly or heavily; to plump down.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To intentionally hit the batter with a pitch.",
              synonyms: [],
              antonyms: [],
              example:
                "The Braves retaliated by plunking Harper in the next inning.",
            },
            {
              definition: "(of a raven) To croak.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To pluck and quickly release (a musical string).",
              synonyms: ["twang"],
              antonyms: [],
            },
            {
              definition: "To be a truant from (school).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["flump", "thud", "twang"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/plunk"],
    },
  ],
  ortho: [
    {
      word: "ortho",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An isomer of a benzene derivative having two substituents adjacent on the ring.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A certain type of flat eyepiece.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An orthochromatic plate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(imaging) An orthophoto.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Orthopedics.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ortho"],
    },
  ],
  lucre: [
    {
      word: "lucre",
      phonetic: "/ˈluːkə/",
      phonetics: [
        {
          text: "/ˈluːkə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/lucre-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88942946",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈluːkəɹ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Money, riches, or wealth, especially when seen as having a corrupting effect or causing greed, or obtained in an underhanded manner.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["mammon"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/lucre"],
    },
  ],
  cooch: [
    {
      word: "cooch",
      phonetic: "/kuːtʃ/",
      phonetics: [
        {
          text: "/kuːtʃ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/cooch-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79325891",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The hootchy-kootchy, a type of erotic dance.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(chiefly US) The vagina or vulva.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cooch"],
    },
  ],
  whipt: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  folky: [
    {
      word: "folky",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having the character of folk music",
              synonyms: [],
              antonyms: [],
              example: "The band opened with a folky little number.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/folky"],
    },
  ],
  tyres: [
    {
      word: "tyres",
      phonetic: "/ˈtaɪə(ɹ)z/",
      phonetics: [
        {
          text: "/ˈtaɪə(ɹ)z/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/tyres-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=80152081",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The ring-shaped protective covering around a wheel which is usually made of rubber or plastic composite and is either pneumatic or solid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The metal rim of a wheel, especially that of a railway vehicle.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To fit tyres to (a vehicle).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To adorn.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tyre",
        "https://en.wiktionary.org/wiki/tyres",
      ],
    },
  ],
  corky: [
    {
      word: "corky",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/corky-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75270128",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of wine, contaminated by a faulty or tainted cork.",
              synonyms: [],
              antonyms: [],
              example: "This wine smells a bit corky; get me another bottle.",
            },
            {
              definition: "Consisting of, or like, cork; dry; shrivelled.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["suberose", "suberous"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/corky"],
    },
    {
      word: "corky",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/corky-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75270128",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A deep bruise, usually on the leg or buttock, caused by a blow; a haematoma.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["contusion", "ecchymosis"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/corky"],
    },
  ],
  injun: [
    {
      word: "injun",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A Native American.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/injun"],
    },
  ],
  solon: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  didot: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  kerfs: [
    {
      word: "kerfs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The act of cutting or carving something; a stroke or slice.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The groove or slit created by cutting or sawing something; an incision.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The distance between diverging saw teeth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The flattened, cut-off end of a branch or tree; a stump or sawn-off cross-section.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The portion or quantity (e.g. of hay, turf, wool, etc.) cut off in a given stroke.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/kerf",
        "https://en.wiktionary.org/wiki/kerfs",
      ],
    },
  ],
  rayed: [
    {
      word: "rayed",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To emit something as if in rays.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To radiate as if in rays.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To arrange.", synonyms: [], antonyms: [] },
            {
              definition: "To dress, array (someone).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To stain or soil; to defile.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ray",
        "https://en.wiktionary.org/wiki/rayed",
      ],
    },
  ],
  chile: [
    {
      word: "chile",
      phonetic: "/ˈt͡ʃɪli/",
      phonetics: [{ text: "/ˈt͡ʃɪli/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The pungent, spicy fresh or dried fruit of any of several cultivated varieties of capsicum peppers, used in cooking.",
              synonyms: ["chili pepper"],
              antonyms: [],
            },
            {
              definition:
                "Powdered chili pepper, used as a spice or flavouring in cooking.",
              synonyms: ["chili powder"],
              antonyms: [],
            },
            {
              definition:
                "(Indian Chinese cuisine) a spicy stew of chicken or paneer, capsicum and onion, eaten as an appetizer.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["chili pepper", "chili powder"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A dish made with chili peppers and other ingredients, such as beans and beef.",
              synonyms: ["chili con carne"],
              antonyms: [],
            },
            {
              definition: "(Cincinnati) Cincinnati chili.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["chili con carne"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/chile",
        "https://en.wiktionary.org/wiki/chili",
      ],
    },
    {
      word: "chile",
      phonetic: "/ˈtʃaɪl/",
      phonetics: [{ text: "/ˈtʃaɪl/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person who has not yet reached adulthood, whether natural (puberty), cultural (initiation), or legal (majority)",
              synonyms: [],
              antonyms: [],
              example: "Go easy on him: he is but a child.",
            },
            {
              definition: "(specifically) A female child, a girl.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(with possessive) One's direct descendant by birth, regardless of age; a son or daughter.",
              synonyms: [],
              antonyms: [],
              example: "My youngest child is forty-three this year.",
            },
            {
              definition: "The thirteenth Lenormand card.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A figurative offspring, particularly:",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A child of noble birth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The cognomen given to the oldest son prior to his taking his father's title.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/child",
        "https://en.wiktionary.org/wiki/childe",
        "https://en.wiktionary.org/wiki/chile",
      ],
    },
  ],
  nippy: [
    {
      word: "nippy",
      phonetic: "/ˈnɪpi/",
      phonetics: [
        {
          text: "/ˈnɪpi/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/nippy-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88676204",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Fast; speedy", synonyms: [], antonyms: [] },
            {
              definition: "Of the weather, rather cold.",
              synonyms: [],
              antonyms: [],
              example:
                "Gosh, it's a bit nippy today: I'd better wear my gloves.",
            },
            {
              definition: "Inclined to nip; bitey.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Annoying; irritating", synonyms: [], antonyms: [] },
            { definition: "Sharp in taste", synonyms: [], antonyms: [] },
            { definition: "Curt", synonyms: [], antonyms: [] },
            { definition: "Parsimonious", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/nippy"],
    },
  ],
  litre: [
    {
      word: "litre",
      phonetic: "/ˈliː.tə/",
      phonetics: [
        {
          text: "/ˈliː.tə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/litre-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=799946",
        },
        { text: "/ˈli.tɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The metric unit of fluid measure, equal to one cubic decimetre. Symbols: l, L, ℓ",
              synonyms: [],
              antonyms: [],
              example:
                "You should be able to fill four cups with one litre of water.",
            },
            {
              definition: "A measure of volume equivalent to a litre.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/litre"],
    },
  ],
  magna: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  rebox: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  hydro: [
    {
      word: "hydro",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/hydro-ca.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=580437",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Hydroelectric power", synonyms: [], antonyms: [] },
            {
              definition:
                "Electrical power supply; specifically, electrical power provided by a utility (as a publicly-owned one); payment or bills for this.",
              synonyms: [],
              antonyms: [],
              example: "He crashed his car against a hydro pole.",
            },
            { definition: "A spa.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Hydroelectric", synonyms: [], antonyms: [] },
            {
              definition: "Hydroponic",
              synonyms: [],
              antonyms: [],
              example: "hydro watercress",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A floatplane, an airplane specialized for operating on water, having pontoon floats instead of skids or wheels",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["floatplane", "hydro", "seaplane"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hydro",
        "https://en.wiktionary.org/wiki/hydroaeroplane",
      ],
    },
  ],
  milch: [
    {
      word: "milch",
      phonetic: "/mɪltʃ/",
      phonetics: [
        { text: "/mɪltʃ/", audio: "" },
        { text: "/mɪltʃ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "(of a cow, animal, etc.) Giving milk; in note",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Tender; pitiful; weeping.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/milch"],
    },
  ],
  brent: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  gyves: [
    {
      word: "gyves",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A shackle or fetter, especially for the leg.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gyve",
        "https://en.wiktionary.org/wiki/gyves",
      ],
    },
  ],
  lazed: [
    {
      word: "lazed",
      phonetic: "/leɪzd/",
      phonetics: [{ text: "/leɪzd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To be lazy, waste time.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To pass time relaxing; to relax, lounge.",
              synonyms: [],
              antonyms: [],
              example: "The cat spent the afternoon lazing in the sun.",
            },
          ],
          synonyms: ["idle", "loaf", "take it easy"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/laze",
        "https://en.wiktionary.org/wiki/lazed",
      ],
    },
  ],
  feued: [
    {
      word: "feued",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To bring (land) under the system of feudal tenure.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/feu",
        "https://en.wiktionary.org/wiki/feued",
      ],
    },
  ],
  mavis: [
    {
      word: "mavis",
      phonetic: "/ˈmeɪvɪs/",
      phonetics: [{ text: "/ˈmeɪvɪs/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Song thrush.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mavis"],
    },
  ],
  inapt: [
    {
      word: "inapt",
      phonetic: "/ɪnæpt/",
      phonetics: [{ text: "/ɪnæpt/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Unapt",
              synonyms: ["inept", "unsuitable"],
              antonyms: [],
            },
          ],
          synonyms: ["inept", "unsuitable"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/inapt"],
    },
  ],
  baulk: [
    {
      word: "baulk",
      phonetic: "[bɔːɫk]",
      phonetics: [
        {
          text: "[bɔːɫk]",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/baulk-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=78201299",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        {
          text: "/bɔk/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/baulk-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=60478525",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An uncultivated ridge formed in the open field system, caused by the action of ploughing.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The wall of earth at the edge of an excavation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                'Beam, crossbeam; squared timber; a tie beam of a house, stretching from wall to wall, especially when laid so as to form a loft, "the balks".',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A hindrance or disappointment; a check.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A sudden and obstinate stop; a failure.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "An omission.", synonyms: [], antonyms: [] },
            {
              definition: "A deceptive motion; a feint.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The area of the table lying behind the line from which the cue ball is initially shot, and from which a ball in hand must be played.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The area of the table lying behind the baulk line.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The rope by which fishing nets are fastened together.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To pass over or by.", synonyms: [], antonyms: [] },
            {
              definition: "To omit, miss or overlook by chance.",
              synonyms: ["miss", "overlook"],
              antonyms: [],
            },
            {
              definition: "To miss intentionally; to avoid.",
              synonyms: ["avoid", "refuse", "shirk", "shun"],
              antonyms: [],
            },
            {
              definition: "To stop, check, block.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To stop short and refuse to go on.",
              synonyms: [],
              antonyms: [],
              example: "The horse balked.",
            },
            { definition: "To refuse suddenly.", synonyms: [], antonyms: [] },
            {
              definition: "To disappoint; to frustrate.",
              synonyms: ["baffle", "foil", "frustrate", "thwart"],
              antonyms: [],
              example: "to balk expectation",
            },
            {
              definition: "To engage in contradiction; to be in opposition.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To leave or make balks in.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To leave heaped up; to heap up in piles.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "avoid",
            "refuse",
            "shirk",
            "shun",
            "baffle",
            "foil",
            "frustrate",
            "thwart",
            "miss",
            "overlook",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/balk",
        "https://en.wiktionary.org/wiki/baulk",
      ],
    },
  ],
  casus: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  scrum: [
    {
      word: "scrum",
      phonetic: "/skɹʌm/",
      phonetics: [{ text: "/skɹʌm/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A tightly-packed and disorderly crowd of people.",
              synonyms: [],
              antonyms: [],
              example:
                "A scrum developed around the bar when free beer was announced.",
            },
            {
              definition:
                "Specifically used in the Canadian media to describe a tightly-packed group of reporters surrounding a member of the Canadian House of Commons while in the Parliament Buildings.",
              synonyms: [],
              antonyms: [],
              example:
                "A scrum formed around Scott Brison shortly after he announced his candidacy for the federal Liberal leadership.",
            },
            {
              definition:
                "In rugby union or rugby league, all the forwards joined together in an organised way. Also known as a scrummage.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "In Agile software development, a daily meeting in which each developer describes what they have been doing, what they plan to do next, and any impediments to progress.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/scrum"],
    },
  ],
  wised: [
    {
      word: "wised",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To become wise.", synonyms: [], antonyms: [] },
            {
              definition: 'Usually with "up", to inform or learn.',
              synonyms: [],
              antonyms: [],
              example: "After Mo had a word with him, he wised up.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To instruct.", synonyms: [], antonyms: [] },
            { definition: "To advise; induce.", synonyms: [], antonyms: [] },
            {
              definition: "To show the way, guide.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To direct the course of, pilot.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To cause to turn.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wise",
        "https://en.wiktionary.org/wiki/wised",
      ],
    },
  ],
  fossa: [
    {
      word: "fossa",
      phonetic: "/ˈfɑsə/",
      phonetics: [{ text: "/ˈfɑsə/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A pit, groove, cavity, or depression.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A long, narrow, shallow depression on the body of an extraterrestrial body, such as a planet or moon.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fossa"],
    },
    {
      word: "fossa",
      phonetic: "/ˈfɑsə/",
      phonetics: [{ text: "/ˈfɑsə/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A carnivorous mammal endemic to Madagascar, Cryptoprocta ferox.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fossa"],
    },
  ],
  dower: [
    {
      word: "dower",
      phonetic: "[ˈdaʊ.ə(ɹ)]",
      phonetics: [
        { text: "[ˈdaʊ.ə(ɹ)]", audio: "" },
        { text: "[ˈdaʊ.ɚ]", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The part of or interest in a deceased husband's property provided to his widow, usually in the form of a life estate.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Property given by a groom directly to his bride at or before their wedding in order to legitimize the marriage; dowry.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "That with which one is gifted or endowed; endowment; gift.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["curtesy"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To give a dower or dowry.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To endow.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dower"],
    },
  ],
  kyrie: [
    {
      word: "kyrie",
      phonetic: "/ˈkɪəɹ.ɪˌeɪ/",
      phonetics: [{ text: "/ˈkɪəɹ.ɪˌeɪ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A short prayer or petition including the phrase kyrie eleison, meaning “Lord, have mercy”.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A setting of the traditional kyrie text to music for a Mass.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/kyrie"],
    },
  ],
  scuse: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  ohmic: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  juste: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  ukase: [
    {
      word: "ukase",
      phonetic: "/juːˈkeɪz/",
      phonetics: [
        {
          text: "/juːˈkeɪz/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ukase-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88942656",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An authoritative proclamation; an edict, especially decreed by a Russian czar or (later) emperor.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Any absolutist order or arrogant proclamation",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/ukase"],
    },
  ],
  beaux: [
    {
      word: "beaux",
      phonetic: "/bəʊ/",
      phonetics: [{ text: "/bəʊ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A man with a reputation for fine dress and etiquette; a dandy or fop.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A male lover; a boyfriend.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A male escort.", synonyms: [], antonyms: [] },
            { definition: "A suitor of a lady.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/beau",
        "https://en.wiktionary.org/wiki/beaux",
      ],
    },
  ],
  tusky: [
    {
      word: "tusky",
      phonetic: "/ˈtʌs.ki/",
      phonetics: [{ text: "/ˈtʌs.ki/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having tusks, especially prominent tusks.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tusky"],
    },
    {
      word: "tusky",
      phonetic: "/ˈtʌs.ki/",
      phonetics: [{ text: "/ˈtʌs.ki/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Rhubarb, sticks from that vegetable",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tusky"],
    },
  ],
  orate: [
    {
      word: "orate",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/orate-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75833496",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ɒɹˈeɪt/", audio: "" },
        {
          text: "/ɔˈɹeɪt/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/orate-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=11778265",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To speak formally; to give a speech.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To speak passionately; to preach for or against something.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["speak", "harangue", "preach"],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Competent in oracy; having good speaking skills.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/orate"],
    },
  ],
  musta: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  lardy: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  intra: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  quiff: [
    {
      word: "quiff",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/quiff-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241765",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/kwɪf/", audio: "" },
        { text: "/kwɪf/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "(British dialect) A puff or whiff, especially of tobacco smoke.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/quiff"],
    },
    {
      word: "quiff",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/quiff-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241765",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/kwɪf/", audio: "" },
        { text: "/kwɪf/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A trick or ploy; a stratagem.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/quiff"],
    },
    {
      word: "quiff",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/quiff-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241765",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/kwɪf/", audio: "" },
        { text: "/kwɪf/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A hairstyle whereby the forelock is brushed and/or gelled upward, often associated with the styles of the 1950s.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To arrange (the hair) in such a manner.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/quiff"],
    },
    {
      word: "quiff",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/quiff-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79241765",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/kwɪf/", audio: "" },
        { text: "/kwɪf/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A young girl, especially as promiscuous; a prostitute.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The vulva or vagina.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/quiff"],
    },
  ],
  epsom: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  neath: [
    {
      word: "neath",
      phonetic: "/niːθ/",
      phonetics: [{ text: "/niːθ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "preposition",
          definitions: [{ definition: "Beneath.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/neath"],
    },
  ],
  ocher: [
    {
      word: "ocher",
      phonetic: "/ˈəʊkə/",
      phonetics: [
        { text: "/ˈəʊkə/", audio: "" },
        { text: "/ˈoʊkɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An earth pigment containing silica, aluminum and ferric oxide",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A somewhat dark yellowish orange colour",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: 'The stop codon sequence "UAA."',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Money, especially gold.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various brown-coloured hesperiid butterflies of the genus Trapezites.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To cover or tint with ochre.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/ocher",
        "https://en.wiktionary.org/wiki/ochre",
      ],
    },
  ],
  tared: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  homme: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  mezzo: [
    {
      word: "mezzo",
      phonetic: "/ˈmɛdzəʊ/",
      phonetics: [
        { text: "/ˈmɛdzəʊ/", audio: "" },
        { text: "/ˈmɛdzoʊ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Mezzo-soprano", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mezzo"],
    },
  ],
  corms: [
    {
      word: "corms",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A short, vertical, swollen underground stem of a plant (usually one of the monocots) that serves as a storage organ to enable the plant to survive winter or other adverse conditions such as drought.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/corm",
        "https://en.wiktionary.org/wiki/corms",
      ],
    },
  ],
  ellen: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  beaky: [
    {
      word: "beaky",
      phonetic: "/ˈbiːki/",
      phonetics: [{ text: "/ˈbiːki/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Beaked: having a beak.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Beak-like: resembling a beak.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Having a nose which resembles a beak.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a gesture) Made using a beak; (of a sound) produced through a beak.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["beaked", "rostellate", "rostrate", "rostrated"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/beaky"],
    },
  ],
  terry: [
    {
      word: "terry",
      phonetic: "/ˈtɛɹi/",
      phonetics: [{ text: "/ˈtɛɹi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A type of coarse cotton fabric covered in many small raised loops that is used to make towels, bathrobes and some types of nappy/diaper.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["terry cloth", "terrycloth"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/terry"],
    },
  ],
  infra: [
    {
      word: "infra",
      phonetic: "/ˈɪn.fɹə/",
      phonetics: [{ text: "/ˈɪn.fɹə/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adverb",
          definitions: [
            { definition: "Discussed later.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: ["supra"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/infra"],
    },
    {
      word: "infra",
      phonetic: "/ˈɪn.fɹə/",
      phonetics: [{ text: "/ˈɪn.fɹə/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Infrastructure.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/infra"],
    },
  ],
  spivs: [
    {
      word: "spivs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A smartly dressed person who trades in illicit, black-market or stolen goods.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A flashy con artist, often homeless, who lives by his wits.",
              synonyms: ["chiseler", "sharper", "wide boy"],
              antonyms: [],
            },
            {
              definition: "In Scotland Yard usage, a low and common thief.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A slacker; one who shirks responsibility.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["chiseler", "sharper", "wide boy"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/spiv",
        "https://en.wiktionary.org/wiki/spivs",
      ],
    },
  ],
  tuans: [
    {
      word: "tuans",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The brush-tailed phascogale, Phascogale tapoatafa.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/tuan",
        "https://en.wiktionary.org/wiki/tuans",
      ],
    },
  ],
  belli: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  bergs: [
    {
      word: "bergs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "An iceberg.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "Mountain", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/berg",
        "https://en.wiktionary.org/wiki/bergs",
      ],
    },
  ],
  anima: [
    {
      word: "anima",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The soul or animating principle of a living thing, especially as contrasted with the animus.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Jungian psychology) The inner self (not the external persona) of a person that is in touch with the unconscious as opposed to the persona.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(Jungian psychology) The unconscious feminine aspect of a person.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/anima"],
    },
  ],
  weirs: [
    {
      word: "weirs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An adjustable dam placed across a river to regulate the flow of water downstream.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A fence placed across a river to catch fish.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/weir",
        "https://en.wiktionary.org/wiki/weirs",
      ],
    },
  ],
  mahua: [
    {
      word: "mahua",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "Madhuca longifolia, an Indian tropical tree.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/mahua"],
    },
  ],
  scops: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  manse: [
    {
      word: "manse",
      phonetic: "/mæns/",
      phonetics: [{ text: "/mæns/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To excommunicate; curse.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/manse"],
    },
    {
      word: "manse",
      phonetic: "/mæns/",
      phonetics: [{ text: "/mæns/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A house inhabited by the minister of a parish.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A family dwelling, an owner-occupied house.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A large house, a mansion.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/manse"],
    },
  ],
  titre: [
    {
      word: "titre",
      phonetic: "/ˈtaɪtə/",
      phonetics: [
        { text: "/ˈtaɪtə/", audio: "" },
        { text: "/ˈtaɪtɚ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The strength or concentration of a solution that has been determined by titration.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["titer"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To determine a titre, especially by titration",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["titer"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/titre"],
    },
  ],
  curia: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  kebob: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  cycad: [
    {
      word: "cycad",
      phonetic: "/ˈsʌɪkad/",
      phonetics: [{ text: "/ˈsʌɪkad/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any plant of the division Cycadophyta, having a stout and woody trunk with a crown of large, hard and stiff, evergreen leaves.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/cycad"],
    },
  ],
  talky: [
    {
      word: "talky",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "(of a person) Talkative or loquacious",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(of a book etc.) Containing a great deal of dialogue or talking in general",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/talky"],
    },
  ],
  tapis: [
    {
      word: "tapis",
      phonetic: "/taˈpiː/",
      phonetics: [{ text: "/taˈpiː/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A tapestry.", synonyms: [], antonyms: [] },
            { definition: "Carpeting.", synonyms: [], antonyms: [] },
            {
              definition: "The cover of a council table.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tapis"],
    },
  ],
  amide: [
    {
      word: "amide",
      phonetic: "/ˈeɪ.maɪd/",
      phonetics: [
        {
          text: "/ˈeɪ.maɪd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/amide-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=4470306",
        },
        { text: "/ˈæ.maɪd/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any derivative of an oxoacid in which the hydroxyl group has been replaced with an amino or substituted amino group; especially such derivatives of a carboxylic acid, the carboxamides or acid amides",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any ionic derivative of ammonia in which a hydrogen atom has been replaced with a metal cation (R-NH- or R2N-)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/amide"],
    },
  ],
  dolce: [
    {
      word: "dolce",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A soft-toned organ stop.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/dolce"],
    },
  ],
  sloes: [
    {
      word: "sloes",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The small, bitter, wild fruit of the blackthorn (Prunus spinosa).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The tree Prunus spinosa.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Any of various other plants of the genus Prunus, as a shrub or small tree, Prunus alleghaniensis, bearing dark-purple fruit.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/sloe",
        "https://en.wiktionary.org/wiki/sloes",
      ],
    },
  ],
  jakes: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  russe: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  blash: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  tutti: [
    {
      word: "tutti",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A passage in which all members of an orchestra are playing",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "All together; with all playing at once.",
              synonyms: [],
              antonyms: [],
              example: "a tutti passage",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adverb",
          definitions: [
            {
              definition:
                "All together. Indicates that the remainder of a group should join in playing after a solo or other passage with a reduced number of voices.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tutti"],
    },
  ],
  blebs: [
    {
      word: "blebs",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A bubble, such as in paint or glass.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A large vesicle or bulla, usually containing a serous fluid.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An irregular bulge in the plasma membrane of a cell undergoing apoptosis.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A bubble-like inclusion of one mineral within another.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["blister", "bubble", "blister"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/bleb",
        "https://en.wiktionary.org/wiki/blebs",
      ],
    },
  ],
  tench: [
    {
      word: "tench",
      phonetic: "/tɛnt͡ʃ/",
      phonetics: [{ text: "/tɛnt͡ʃ/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A species of freshwater game fish, Tinca tinca.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/tench"],
    },
  ],
  swarf: [
    {
      word: "swarf",
      phonetic: "/swɔːf/",
      phonetics: [
        {
          text: "/swɔːf/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/swarf-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88902121",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/swɔɹf/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The waste chips or shavings from an abrasive activity, such as metalworking, a saw cutting wood, or the use of a grindstone or whetstone.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A particular waste chip or shaving.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["turnings"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To grind down.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/swarf"],
    },
    {
      word: "swarf",
      phonetic: "/swɔːf/",
      phonetics: [
        {
          text: "/swɔːf/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/swarf-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88902121",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/swɔɹf/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A faint or swoon.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To grow languid; to faint.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/swarf"],
    },
  ],
  herem: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  missy: [
    {
      word: "missy",
      phonetic: "/ˈmɪs.i/",
      phonetics: [{ text: "/ˈmɪs.i/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A young female, or miss; as a term of mild disparagement, typically used jokingly or rebukingly.",
              synonyms: [],
              antonyms: [],
              example: "That's enough out of you, missy!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Girlish; effeminate; sentimental.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of, or pertaining to, female clothing or clothing sizes.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/missy"],
    },
    {
      word: "missy",
      phonetic: "/ˈmɪs.i/",
      phonetics: [{ text: "/ˈmɪs.i/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An impure yellow sulphate of iron; yellow copperas or copiapite.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/missy",
        "https://en.wiktionary.org/wiki/misy",
      ],
    },
  ],
  merse: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  buzzy: [
    {
      word: "buzzy",
      phonetic: "/ˈbʌzi/",
      phonetics: [{ text: "/ˈbʌzi/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Having a buzzing sound.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Being the subject of cultural buzz.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/buzzy"],
    },
  ],
  meads: [
    {
      word: "meads",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An alcoholic drink fermented from honey and water.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A drink composed of syrup of sarsaparilla or other flavouring extract, and water, and sometimes charged with carbon dioxide.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A meadow.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/mead",
        "https://en.wiktionary.org/wiki/meads",
      ],
    },
  ],
  creme: [
    {
      word: "creme",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A very sugary, fluffy white cream derivative.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "Cream", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/cr%C3%A8me",
        "https://en.wiktionary.org/wiki/creme",
      ],
    },
  ],
  email: [
    {
      word: "email",
      phonetic: "/ˈiːmeɪl/",
      phonetics: [
        { text: "/ˈiːmeɪl/", audio: "" },
        {
          text: "/ˈiˌmeɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/email-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=1780053",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A system for transferring messages from one computer to another, usually through a network.",
              synonyms: [],
              antonyms: ["snail mail"],
              example: "He sent me his details via email.",
            },
            {
              definition:
                "A quantity of messages sent through an email system.",
              synonyms: [],
              antonyms: [],
              example: "I am searching through my old email.",
            },
            {
              definition: "A message sent through an email system.",
              synonyms: [],
              antonyms: [],
              example:
                "He sent me an email last week to remind me about the meeting.",
            },
            {
              definition: "An email address.",
              synonyms: [],
              antonyms: [],
              example: "Don’t send personal messages to my work email.",
            },
          ],
          synonyms: [],
          antonyms: ["snail mail"],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To send an email or emails to.",
              synonyms: [],
              antonyms: [],
              example:
                "She emailed me last week, asking about the status of the project.",
            },
            {
              definition:
                "(may take two objects) To send (something) through email.",
              synonyms: [],
              antonyms: [],
              example: "He emailed the file out to everyone.",
            },
            {
              definition: "To send, or compose and send, an email or emails.",
              synonyms: [],
              antonyms: [],
              example:
                "Most teenagers seem to spend almost the whole day emailing and surfing the Web.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/email"],
    },
    {
      word: "email",
      phonetic: "/ɛˈmeɪl/",
      phonetics: [
        {
          text: "/ɛˈmeɪl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/email-2-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=89260123",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ɛˈmeɪl/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [{ definition: "Enamel.", synonyms: [], antonyms: [] }],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/email"],
    },
  ],
  pyxie: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  hoper: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  wonks: [
    {
      word: "wonks",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An overly studious person, particularly student.",
              synonyms: ["nerd"],
              antonyms: [],
            },
            {
              definition:
                "(by extension) A policy wonk or other intellectual expert.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["nerd"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/wonk",
        "https://en.wiktionary.org/wiki/wonks",
      ],
    },
  ],
  petri: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  fugal: [
    {
      word: "fugal",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Relating to a fugue", synonyms: [], antonyms: [] },
            {
              definition: "Relating to flight (fleeing)",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fugal"],
    },
  ],
  boomy: [
    {
      word: "boomy",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Characterized by heavy bass sounds.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Of or pertaining to a financial boom, resources boom, baby boom, etc.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/boomy"],
    },
  ],
  hived: [
    {
      word: "hived",
      phonetic: "/haɪvd/",
      phonetics: [{ text: "/haɪvd/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To enter or possess a hive.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To form a hive-like entity.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To collect into a hive.",
              synonyms: [],
              antonyms: [],
              example: "to hive a swarm of bees",
            },
            {
              definition: "To store in a hive or similarly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To take shelter or lodgings together; to reside in a collective body.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hive",
        "https://en.wiktionary.org/wiki/hived",
      ],
    },
  ],
  yogic: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  plyer: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  yahoo: [
    {
      word: "yahoo",
      phonetic: "/ˈjɑːhuː/",
      phonetics: [{ text: "/ˈjɑːhuː/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A rough, coarse, loud or uncouth person; yokel; lout.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A humanoid cryptid said to exist in parts of eastern Australia, and also reported in the Bahamas.",
              synonyms: [],
              antonyms: [],
              example:
                "1985, Michael Raynal, Yahoos in the Bahamas, Cryptozoology, volume 4:",
            },
          ],
          synonyms: ["lout", "yokel"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/yahoo"],
    },
    {
      word: "yahoo",
      phonetic: "/jəˈhuː/",
      phonetics: [{ text: "/jəˈhuː/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: 'To give a cry of "yahoo".',
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To search using the Yahoo! search engine.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "interjection",
          definitions: [
            {
              definition: "An exclamation of joy or enjoyment.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A battle cry.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/yahoo"],
    },
  ],
  vulva: [
    {
      word: "vulva",
      phonetic: "/ˈvʌlvə/",
      phonetics: [
        {
          text: "/ˈvʌlvə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/vulva-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=87185493",
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The external female sexual organs, collectively.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/vulva"],
    },
  ],
  gouts: [
    {
      word: "gouts",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "An extremely painful inflammation of joints, especially of the big toe, caused by a metabolic defect resulting in the accumulation of uric acid in the blood and the deposition of urates around the joints.",
              synonyms: [
                "crystalline arthritis",
                "gouty arthritis",
                "urarthritis",
              ],
              antonyms: [],
            },
            {
              definition: "(usually followed by of) A spurt or splotch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A disease of wheat and cornstalks, caused by insect larvae.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["crystalline arthritis", "gouty arthritis", "urarthritis"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To spurt.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "Taste; relish", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/gout",
        "https://en.wiktionary.org/wiki/gouts",
      ],
    },
  ],
  mynas: [
    {
      word: "mynas",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "One of the South and East Asian birds of the starling family Sturnidae.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/myna",
        "https://en.wiktionary.org/wiki/mynas",
      ],
    },
  ],
  pubes: [
    {
      word: "pubes",
      phonetic: "/pjuːbiz/",
      phonetics: [{ text: "/pjuːbiz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The pubic hair.", synonyms: [], antonyms: [] },
            { definition: "The pubic region.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The pubic bone; the part of the hipbone forming the front arch of the pelvis",
              synonyms: ["pubic bone"],
              antonyms: [],
            },
          ],
          synonyms: ["pubic bone"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pubes",
        "https://en.wiktionary.org/wiki/pubis",
      ],
    },
    {
      word: "pubes",
      phonetic: "/ˈpjuːbz/",
      phonetics: [{ text: "/ˈpjuːbz/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A single pubic hair.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/pube",
        "https://en.wiktionary.org/wiki/pubes",
      ],
    },
  ],
  sprog: [
    {
      word: "sprog",
      phonetic: "/spɹɒɡ/",
      phonetics: [
        {
          text: "/spɹɒɡ/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/sprog-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75701486",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/sprɔɡ/", audio: "" },
        { text: "/spɹɒɡ/", audio: "" },
        { text: "/sprɔɡ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "A child.", synonyms: [], antonyms: [] },
            { definition: "(RAF) A new recruit.", synonyms: [], antonyms: [] },
            { definition: "Semen.", synonyms: [], antonyms: [] },
            {
              definition:
                "A deflection-limiting safety device used in high performance hang gliders.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "ankle-biter",
            "bairn",
            "crib lizard",
            "crotch fruit",
            "kid",
            "rug rat",
            "cum",
            "jizz",
            "spoof",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To produce children.", synonyms: [], antonyms: [] },
            {
              definition: "To ejaculate, to come.",
              synonyms: ["spoof"],
              antonyms: [],
            },
          ],
          synonyms: ["spoof"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/sprog"],
    },
  ],
  vaned: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  beamy: [
    {
      word: "beamy",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Resembling a beam in size and weight; massy.",
              synonyms: [],
              antonyms: [],
              example: "a beamy spear",
            },
            {
              definition: "Having horns or antlers.",
              synonyms: [],
              antonyms: [],
              example: "beamy stags",
            },
            {
              definition: "Having much beam or breadth; wide.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Showing or emitting rays of light; beaming; radiant; shining.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Radiant; beamsome; joyous; gladsome.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/beamy"],
    },
  ],
  folic: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  abled: [
    {
      word: "abled",
      phonetic: "/ˈeɪ.bl̩d/",
      phonetics: [{ text: "/ˈeɪ.bl̩d/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "(in combination) Having a range of physical powers as specified.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: ["disabled"],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/abled"],
    },
    {
      word: "abled",
      phonetic: "/ˈeɪ.bl̩d/",
      phonetics: [{ text: "/ˈeɪ.bl̩d/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To make ready.", synonyms: [], antonyms: [] },
            {
              definition: "To make capable; to enable.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "To dress.", synonyms: [], antonyms: [] },
            {
              definition: "To give power to; to reinforce; to confirm.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To vouch for; to guarantee.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/able",
        "https://en.wiktionary.org/wiki/abled",
      ],
    },
  ],
  smurf: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  bahts: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  reeve: [
    {
      word: "reeve",
      phonetic: "/ɹiːv/",
      phonetics: [{ text: "/ɹiːv/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Any of several local officials, with varying responsibilities.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The president of a township or municipal district council.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "The holder of a proposed but unadopted commissioned rank of the Royal Air Force, equivalent to wing commander.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/reeve"],
    },
    {
      word: "reeve",
      phonetic: "/ɹiːv/",
      phonetics: [{ text: "/ɹiːv/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To pass (a rope) through a hole or opening, especially so as to fasten it.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/reeve"],
    },
    {
      word: "reeve",
      phonetic: "/ɹiːv/",
      phonetics: [{ text: "/ɹiːv/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A female of the species Philomachus pugnax, a highly gregarious, medium-sized wading bird of Eurasia; the male is a ruff.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/reeve"],
    },
  ],
  turdy: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  mungy: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  carpy: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  burry: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  sizer: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  corgi: [
    {
      word: "corgi",
      phonetic: "/ˈkɔːɡi/",
      phonetics: [
        { text: "/ˈkɔːɡi/", audio: "" },
        { text: "/ˈkɔɹɡi/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A dog of a breed having a small body, short legs and fox-like features.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["Welsh corgi"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/corgi"],
    },
  ],
  feral: [
    {
      word: "feral",
      phonetic: "/ˈfɛɹəl/",
      phonetics: [
        {
          text: "/ˈfɛɹəl/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/feral-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75269425",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A domesticated animal that has returned to the wild; an animal, particularly a domesticated animal, living independently of humans.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A contemptible young person, a lout, a person who behaves wildly.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person who has isolated themselves from the outside world; one living an alternative lifestyle.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "(furry subculture) A character in furry art or literature which has the physical characteristics (body) of a regular animal (typically quadripedal), that may or may not be able to communicate with humans or anthros (contrasts anthro)",
              synonyms: [],
              antonyms: [],
              example:
                "The story is about a group of ferals which have to explore the ruins of society after the humans die out.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition:
                "Wild, untamed, especially of domesticated animals having returned to the wild.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "(of a person) Contemptible, unruly, misbehaved.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/feral"],
    },
  ],
  lased: [
    {
      word: "lased",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To use a laser beam on, as for cutting.",
              synonyms: [],
              antonyms: [],
              example:
                "The physical chemist lased the atoms as they passed between the electrodes to study their motion.",
            },
            {
              definition:
                "To operate as a laser, to release coherent light due to stimulation.",
              synonyms: [],
              antonyms: [],
              example:
                "Once enough of the gas particles are in a higher energy state, they will begin to lase and give off a coherent beam.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/lase",
        "https://en.wiktionary.org/wiki/lased",
      ],
    },
  ],
  turds: [
    {
      word: "turds",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(mildly) A piece of solid animal or human feces.",
              synonyms: [],
              antonyms: [],
              example:
                "Ugh, there are turds in the toilet that haven’t been flushed away.",
            },
            {
              definition: "A worthless person or thing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/turd",
        "https://en.wiktionary.org/wiki/turds",
      ],
    },
  ],
  swede: [
    {
      word: "swede",
      phonetic: "/swiːd/",
      phonetics: [
        {
          text: "/swiːd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/swede-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79218903",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "The fleshy yellow root of a variety of rape, Brassica napus var. napobrassica, resembling a large turnip, grown as a vegetable.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The plant from which this is obtained.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "The turnip.", synonyms: [], antonyms: [] },
            { definition: "The head.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "rutabaga",
            "neep",
            "rutabaga",
            "winter turnip",
            "yellow turnip",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/swede"],
    },
    {
      word: "swede",
      phonetic: "/swiːd/",
      phonetics: [
        {
          text: "/swiːd/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/swede-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=79218903",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To produce a low-budget remake of a film without the use of professional actors or filming techniques.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/swede"],
    },
  ],
  geeks: [
    {
      word: "geeks",
      phonetic: "/ɡiːks/",
      phonetics: [{ text: "/ɡiːks/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A carnival performer specializing in bizarre and unappetizing behavior.",
              synonyms: [],
              antonyms: [],
              example: "I once saw a geek bite the head off a live chicken.",
            },
            {
              definition:
                "A person who is intensely interested in a particular field or hobby and often having limited or nonstandard social skills. Often used with an attributive noun.",
              synonyms: [],
              antonyms: [],
              example:
                "I was a complete computer geek in high school, but I get out a lot more now.",
            },
            {
              definition:
                "(by extension) An expert in a technical field, particularly one having to do with computers.",
              synonyms: [],
              antonyms: [],
              example: "Do you need a hardware geek or a software geek?",
            },
            {
              definition:
                "The subculture of geeks; an esoteric subject of interest that is marginal to the social mainstream; the philosophy, events, and physical artifacts of geeks; geekness.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An unfashionable or socially undesirable person.",
              synonyms: [],
              antonyms: [],
              example: "Why do you hang around with them? They’re just geeks.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To behave geekishly or in a socially awkward manner, especially when under the influence of drugs or other psycho-active substances, and exhibiting such marked characteristics as hyperactivity, repetitiveness, talkativeness, nervousness, irritability, or paranoia.",
              synonyms: [],
              antonyms: [],
              example:
                "Hey, check out that guy...he's really geeking out pretty bad.",
            },
          ],
          synonyms: [
            "freak",
            "freak",
            "guru",
            "hacker",
            "dork",
            "nerd",
            "propeller head",
            "loser",
            "nonce",
            "waste of space",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A look.",
              synonyms: [],
              antonyms: [],
              example: "Have a geek at this.",
            },
          ],
          synonyms: ["butcher’s", "gander"],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To look; to peep; to stare about intently.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/geek",
        "https://en.wiktionary.org/wiki/geeks",
      ],
    },
  ],
  ninja: [
    {
      word: "ninja",
      phonetic: "/ˈnɪndʒə/",
      phonetics: [
        { text: "/ˈnɪndʒə/", audio: "" },
        {
          text: "/ˈnɪndʒə/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/ninja-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=50279077",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "An adult male human.",
              synonyms: [],
              antonyms: [],
              example: "The show is especially popular with middle-aged men.",
            },
            {
              definition: "(collective) All human males collectively: mankind.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A human, a person of either gender, usually an adult. (See usage notes.)",
              synonyms: [],
              antonyms: [],
              example: "every man for himself",
            },
            {
              definition:
                "(collective) All humans collectively: mankind, humankind, humanity. (Sometimes capitalized as Man.)",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A member of the genus Homo, especially of the species Homo sapiens.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An male person, usually an adult; a (generally adult male) sentient being, whether human, supernatural, elf, alien, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "An adult male who has, to an eminent degree, qualities considered masculine, such as strength, integrity, and devotion to family; a mensch.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Manliness; the quality or state of being manly.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A husband.", synonyms: [], antonyms: [] },
            { definition: "A lover; a boyfriend.", synonyms: [], antonyms: [] },
            {
              definition:
                "A male enthusiast or devotee; a male who is very fond of or devoted to a specified kind of thing. (Used as the last element of a compound.)",
              synonyms: [],
              antonyms: [],
              example:
                "Some people prefer apple pie, but me, I’m a cherry pie man.",
            },
            {
              definition:
                "A person, usually male, who has duties or skills associated with a specified thing. (Used as the last element of a compound.)",
              synonyms: [],
              antonyms: [],
              example:
                "I wanted to be a guitar man on a road tour, but instead I’m a flag man on a road crew.",
            },
            {
              definition:
                "A person, usually male, who can fulfill one's requirements with regard to a specified matter.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A male who belongs to a particular group: an employee, a student or alumnus, a representative, etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "An adult male servant.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A vassal; a subject.",
              synonyms: [],
              antonyms: [],
              example: "Like master, like man.",
            },
            {
              definition: "A piece or token used in board games such as chess.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "Used to refer to oneself or one's group: I, we; construed in the third person.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A term of familiar address often implying on the part of the speaker some degree of authority, impatience, or haste.",
              synonyms: [],
              antonyms: [],
              example: "Come on, man, we've got no time to lose!",
            },
            {
              definition:
                "A friendly term of address usually reserved for other adult males.",
              synonyms: [],
              antonyms: [],
              example: "Hey, man, how's it goin'?",
            },
            {
              definition:
                "A player on whom another is playing, with the intent of limiting their attacking impact.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["male", "omi", "human", "person"],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A person trained in ninjutsu, especially one used for espionage, assassination, and other tasks requiring stealth during Japan's shogunate period.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person considered similarly skillful to the historical ninja, especially in covert or stealthy operation.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "A person considered to look like the historical ninja in some way, including (Mongolia) amateur private miners.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "killer",
            "magician",
            "secret agent",
            "spy",
            "superspy",
            "assassin",
            "shinobi",
            "spy",
          ],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To act or move like a ninja, particularly with regard to a combination of speed, power, and stealth.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To claim an item through abuse of game mechanics.",
              synonyms: [],
              antonyms: [],
              example:
                "That damn warrior ninja'd an epic-quality wand even though he can't even use it!",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Of or related to ninjas in their various senses.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To appropriate something (before someone else does).",
              synonyms: [],
              antonyms: [],
            },
            {
              definition:
                "To displace something, or take precedence over something.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To secure (land, etc.) by the right of preemption.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To make a preemptive bid at bridge.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["ninja"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/man",
        "https://en.wiktionary.org/wiki/ninja",
        "https://en.wiktionary.org/wiki/preempt",
      ],
    },
  ],
  doter: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  besot: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  twats: [
    {
      word: "twats",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            { definition: "The vagina or vulva.", synonyms: [], antonyms: [] },
            {
              definition: "A contemptible and stupid person, idiot.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To hit, slap.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/twat",
        "https://en.wiktionary.org/wiki/twats",
      ],
    },
  ],
  crump: [
    {
      word: "crump",
      phonetic: "/kɹʌmp/",
      phonetics: [
        {
          text: "/kɹʌmp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/crump-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88676220",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The sound of a muffled explosion.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To produce such a sound.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/crump"],
    },
    {
      word: "crump",
      phonetic: "/kɹʌmp/",
      phonetics: [
        {
          text: "/kɹʌmp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/crump-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88676220",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "For one's health to decline rapidly (but not as rapidly as crash).",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/crump"],
    },
    {
      word: "crump",
      phonetic: "/kɹʌmp/",
      phonetics: [
        {
          text: "/kɹʌmp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/crump-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88676220",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Hard or crusty; dry baked",
              synonyms: [],
              antonyms: [],
              example: "a crump loaf",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/crump"],
    },
    {
      word: "crump",
      phonetic: "/kɹʌmp/",
      phonetics: [
        {
          text: "/kɹʌmp/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/crump-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88676220",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            { definition: "Crooked; bent.", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/crump"],
    },
  ],
  unwon: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  peats: [
    {
      word: "peats",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Soil formed of dead but not fully decayed plants found in bog areas, often burned as fuel.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A pet, a darling; a woman.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/peat",
        "https://en.wiktionary.org/wiki/peats",
      ],
    },
  ],
  zombi: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
  apnea: [
    {
      word: "apnea",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "The cessation of breathing.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: ["asphyxia"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/apnea"],
    },
  ],
  prims: [
    {
      word: "prims",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition: "To make affectedly precise or proper.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "To dress or act smartly.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/prim",
        "https://en.wiktionary.org/wiki/prims",
      ],
    },
  ],
  fatwa: [
    {
      word: "fatwa",
      phonetic: "/ˈfatwɑː/",
      phonetics: [
        { text: "/ˈfatwɑː/", audio: "" },
        { text: "/ˈfætwɑ/", audio: "" },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A legal opinion, decree or ruling issued by a mufti or other Islamic lawyer.",
              synonyms: [],
              antonyms: [],
              example:
                "I think the fatwas on that website are totally unreliable.",
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            {
              definition:
                "To make somebody the subject of a fatwa, especially a ban or death sentence.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/fatwa"],
    },
  ],
  taros: [
    {
      word: "taros",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Colocasia esculenta, raised as a food primarily for its corm, which distantly resembles potato.",
              synonyms: ["colocasia", "elephant ears"],
              antonyms: [],
            },
            {
              definition:
                "Any of several other species with similar corms and growth habit in Colocasia, Alocasia etc.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "Food from a taro plant.",
              synonyms: ["dasheen"],
              antonyms: [],
            },
          ],
          synonyms: ["colocasia", "elephant ears", "dasheen"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/taro",
        "https://en.wiktionary.org/wiki/taros",
      ],
    },
  ],
  nerdy: [
    {
      word: "nerdy",
      phonetic: "/nɜː(ɹ)di/",
      phonetics: [
        {
          text: "/nɜː(ɹ)di/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/nerdy-uk.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=88681253",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "(of a person) Being or like a nerd.",
              synonyms: [],
              antonyms: [],
              example: "That guy is nerdy and weird.",
            },
            {
              definition:
                "(of a quality or interest) Of, pertaining to, in the style of, or appealing to nerds.",
              synonyms: [],
              antonyms: [],
              example:
                "I got a pair of nerdy glasses and clothes for Halloween.",
            },
          ],
          synonyms: ["nerdish"],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/nerdy"],
    },
  ],
  baldy: [
    {
      word: "baldy",
      phonetic: "/ˈbɔːl.di/",
      phonetics: [{ text: "/ˈbɔːl.di/", audio: "" }],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "(sometimes derogatory) Someone who is bald.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [
            "baldhead",
            "baldpate",
            "chrome dome",
            "cue ball",
            "follicly challenged",
            "slaphead",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/baldy"],
    },
  ],
  wonky: [
    {
      word: "wonky",
      phonetics: [
        {
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wonky-1-au.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=75701925",
          license: {
            name: "BY-SA 4.0",
            url: "https://creativecommons.org/licenses/by-sa/4.0",
          },
        },
        { text: "/ˈwɔːŋ.kɪ/", audio: "" },
        {
          text: "/ˈwɑŋ.ki/",
          audio:
            "https://api.dictionaryapi.dev/media/pronunciations/en/wonky-1-us.mp3",
          sourceUrl: "https://commons.wikimedia.org/w/index.php?curid=6573551",
          license: {
            name: "BY-SA 3.0",
            url: "https://creativecommons.org/licenses/by-sa/3.0",
          },
        },
      ],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "A subgenre of electronic music employing unstable rhythms, complex time signatures, and mid-range synths.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Lopsided, misaligned or off-centre.",
              synonyms: ["awry", "lonkie", "misaligned", "skew-whiff"],
              antonyms: [],
            },
            {
              definition: "Feeble, shaky or rickety.",
              synonyms: ["rickety"],
              antonyms: [],
            },
            {
              definition:
                "(especially Usenet) Suffering from intermittent bugs.",
              synonyms: ["broken", "buggy"],
              antonyms: [],
            },
            { definition: "Generally incorrect.", synonyms: [], antonyms: [] },
          ],
          synonyms: [
            "awry",
            "lonkie",
            "misaligned",
            "skew-whiff",
            "broken",
            "buggy",
            "rickety",
          ],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/wonky"],
    },
    {
      word: "wonky",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "adjective",
          definitions: [
            {
              definition: "Technically worded, in the style of jargon.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/wonky"],
    },
  ],
  hypos: [
    {
      word: "hypos",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition:
                "Melancholy; a fit of ‘hypochondria’; a morbid depression.",
              synonyms: [],
              antonyms: [],
              example:
                "1837 Abraham Lincoln: Tell your sister I dont want to hear any more about selling out and moving. That gives me the hypo whenever I think of it. (Letter to Mary S. Owens, May 7. 1837, Collected Works of Abraham Lincoln. Volume 1.",
            },
            { definition: "A hypochondriac.", synonyms: [], antonyms: [] },
            {
              definition: "Sodium thiosulphate, a photographic fixing agent.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A hypoglycaemia attack in a person with diabetes.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "The substance sodium hyposulfite.",
              synonyms: [],
              antonyms: [],
            },
            { definition: "A hypodermic syringe.", synonyms: [], antonyms: [] },
            { definition: "Hypothecation.", synonyms: [], antonyms: [] },
            {
              definition: "(in the study of law) A hypothetical case.",
              synonyms: [],
              antonyms: [],
            },
            {
              definition: "A hypomelanistic snake.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: [
        "https://en.wiktionary.org/wiki/hypo",
        "https://en.wiktionary.org/wiki/hypos",
      ],
    },
  ],
  rumba: [
    {
      word: "rumba",
      phonetics: [],
      meanings: [
        {
          partOfSpeech: "noun",
          definitions: [
            {
              definition: "A slow-paced Cuban partner dance in 4:4 time.",
              synonyms: [],
              antonyms: [],
            },
          ],
          synonyms: [],
          antonyms: [],
        },
        {
          partOfSpeech: "verb",
          definitions: [
            { definition: "To dance the rumba", synonyms: [], antonyms: [] },
          ],
          synonyms: [],
          antonyms: [],
        },
      ],
      license: {
        name: "CC BY-SA 3.0",
        url: "https://creativecommons.org/licenses/by-sa/3.0",
      },
      sourceUrls: ["https://en.wiktionary.org/wiki/rumba"],
    },
  ],
  pupal: {
    title: "No Definitions Found",
    message:
      "Sorry pal, we couldn't find definitions for the word you were looking for.",
    resolution:
      "You can try the search again at later time or head to the web instead.",
  },
};
